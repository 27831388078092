import React from 'react';
import { ComplianceEntryStatus } from 'src/modules/compliance/common/models/compliance-entries';
interface ComplianceContentProps {
  complianceTypeName: string;
  status: ComplianceEntryStatus;
}
export function ComplianceBannerContent({
  complianceTypeName,
  status
}: ComplianceContentProps) {
  if (status === 'expired') {
    return (
      <div>
        <strong>{complianceTypeName}</strong> has expired. Please renew this
        item as soon as possible.
      </div>
    );
  }
  if (status === 'expires_soon') {
    return (
      <div>
        <strong>{complianceTypeName}</strong> needs to be renewed for this
        property
      </div>
    );
  }
  return null;
}
