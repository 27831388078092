import Box from '@rexlabs/box';
import { Field } from '@rexlabs/form';
import { TextInput } from '@rexlabs/text-input';
import React, { useEffect } from 'react';
import { BankAccountSelect } from 'src/modules/bank-accounts/components/bank-account-select';
import { Contact } from 'src/modules/contacts/types/contact-types';
import { getRelatedRecords } from 'src/modules/financials/utils/get-related-records';
import { FinancialRecordTypes } from 'utils/financials/financial-objects';
import { Grid } from 'view/components/@luna/form/grid';
import {
  DateInput,
  disableFutureDates
} from 'view/components/@luna/inputs/date-input/date-input';
import { CentAmountInput } from 'view/components/inputs/cent-amount';
import { ContactSelect } from 'view/components/inputs/selects/contact';
import { FinancialEntitySelect } from 'view/components/inputs/selects/financial-entity-select';
import { BlockConfig } from 'view/components/record-screen/types';

export const withdrawalDetailsBlock: BlockConfig = {
  id: 'withdrawalDetails',
  title: 'Details',
  validate: {
    definitions: {
      'source.ledger.object': { name: 'Folio', rules: 'required' },
      contact: { name: 'Paid to', rules: 'required' },
      amount: { name: 'Amount', rules: 'required' },
      description: { name: 'Description', rules: 'required' },
      bank_account: { name: 'Bank account', rules: 'required' },
      date_of: { name: 'Date of', rules: 'required' }
    }
  },
  Edit: ({ values, forms, getHandlers, setFieldValue }) => {
    const folio = values.source?.ledger?.object ?? undefined;
    const contact = values?.contact;

    const handlers = getHandlers?.(forms);

    useEffect(() => {
      folio && handlers?.handleFolioChange?.({ target: { value: folio } });
    }, [folio?.id]);

    useEffect(() => {
      contact &&
        handlers?.handleContactChange?.({ target: { value: contact } });
    }, [contact?.id]);

    return (
      <Box sy='2rem'>
        <Grid columns={3}>
          <Field
            name='source.ledger.object'
            label='Folio to withdraw from'
            Input={FinancialEntitySelect}
            onBlur={() => {
              setFieldValue?.('amount', 0);
            }}
            inputProps={{
              objectTypes: Object.values(FinancialRecordTypes)
            }}
          />
          <Field
            name='contact'
            label='Pay to'
            Input={ContactSelect}
            inputProps={{
              disableFixture: true,
              getSuggestedItems: async () => {
                const contacts: Contact[] = [];
                const object = values?.source?.ledger?.object;
                const typeId = object?.type?.id;
                if (typeId === 'contact') {
                  contacts.push(object.record);
                }
                if (typeId === 'ownership' || typeId === 'tenancy') {
                  contacts.push(
                    ...(object
                      ? (
                          await getRelatedRecords({
                            folioId: object.id,
                            folioType: object.type.id
                          })
                        ).otherContacts
                      : [])
                  );
                }
                return contacts;
              },
              includes: ['default_payment_method']
            }}
          />
          <Field name='amount' label='Amount' Input={CentAmountInput} />
          <Field
            name='bank_account'
            label='Bank account'
            Input={BankAccountSelect}
          />
          <Field
            name='date_of'
            label='Date'
            Input={DateInput}
            inputProps={{
              isOutsideRange: disableFutureDates
            }}
          />
          <Field name='description' label='Description' Input={TextInput} />
        </Grid>
      </Box>
    );
  }
};
