import {
  BaseModelGeneratorModel,
  CustomAction
} from '@rexlabs/model-generator';
import { AxiosResponse } from 'axios';
import { Generator } from 'data/models/generator';
import _ from 'lodash';
import { api } from 'utils/api/api-client';

export interface UserInvite extends BaseModelGeneratorModel {
  invitee: {
    id: string;
    given_name: string;
    family_name: string;
    email: string;
    universal_user_id: string;
    invited_at: string;
    accepted_at: string;
    suspended_at: string;
    created_at: string;
    updated_at: string;
  };
  invited_by: {
    id: string;
    name: string;
    given_name: string;
    family_name: string;
  };
  silo: {
    id: string;
    name: string;
  };
}

const config = {
  entities: {
    api: {
      fetchItem: (type, args, id) =>
        api
          .get(`/${type}/${id}`, args)
          .then(({ data }) => ({ data: { ...data, id } } as AxiosResponse)),
      updateItem: (type, args, id) => api.put(`/${type}/${id}`, args)
    }
  }
};

type CompleteActionPayload = {
  token: string;
  accept: boolean;
};

const actionCreators = {
  complete: {
    request: ({ token, accept }: CompleteActionPayload) =>
      api.post('/user-invites/existing-user/', { token, accept }),
    reduce: {
      initial: _.identity,
      success: _.identity,
      failure: _.identity
    }
  } as CustomAction<CompleteActionPayload, unknown>
};

export const actionsUserInvitesModel = new Generator<
  UserInvite,
  typeof actionCreators
>('user-invites/verify-token', config).createEntityModel({ actionCreators });
