import React from 'react';

import { Body, Semibold } from '@rexlabs/text';
import { Field } from '@rexlabs/form';
import { Checkbox } from '@rexlabs/checkbox';

import { Grid } from 'view/components/@luna/form/grid';
import { Value } from 'view/components/values';
import { BlockConfig } from 'view/components/record-screen/types';
import { EmptyCard } from 'view/components/record-screen/cards/empty-card';
import { Message } from 'view/components/@luna/message';
import { TimePeriodInput } from 'view/components/inputs/time-period-input';
import EmptyRecord from 'src/assets/illustrations/empty-record.svg';
import { pluralize } from 'utils/formatters';
import { Trans, useTranslation } from 'react-i18next';
import { ServicePackageNames } from 'src/modules/tasks/inspections/components/service-package-names';
import { ServicePackageSelect } from 'view/components/inputs/selects/v4-selects/service-package-select';
import { AutomaticTaskCreationRadioButtons } from 'src/modules/tasks/common/components/AutomaticTaskCreationRadioButtons';

const validate = {
  definitions: {
    'first_inspection.count': {
      rules: 'required|min:1'
    },
    'first_inspection.unit': {
      rules: 'required'
    },
    'routine_inspection.count': {
      rules: 'required_if_true:routine_inspection.is_active|min:1'
    },
    'routine_inspection.unit': {
      rules: 'required_if_true:routine_inspection.is_active'
    },
    'notice_period.count': {
      rules: 'required|min:1'
    },
    'notice_period.unit': {
      rules: 'required'
    },
    inspection_service_packages: {
      name: 'service packages',
      rules:
        'required_if:inspection_automatic_task_creation,service_packages|array|min:1'
    }
  },
  messages: {
    required_if: 'The :attribute field is required.'
  }
};

export const inspectionsTriggerBlock: BlockConfig = {
  id: 'inspection-task-interval',
  title: () => (
    <Trans i18nKey='tasks.inspections.type.routine-inspection.label.plural' />
  ),
  validate,

  showEmpty: (data) => {
    return (
      data?.first_inspection?.count == undefined &&
      data?.routine_inspection?.count == undefined &&
      data?.notice_period?.count == undefined
    );
  },

  View: ({ data }) => {
    const { t } = useTranslation();
    return (
      <Grid columns={1}>
        <Semibold>
          {t(
            'tasks.inspections.task-triggers.routine-inspections.frequency.message'
          )}
        </Semibold>

        <Value
          label='Schedule the first inspection'
          value={t(
            'tasks.inspections.task-triggers.lease-agreements.frequency.first-inspection-schedule.field.value',
            {
              frequency: pluralize(
                data?.first_inspection?.unit,
                data?.first_inspection?.count,
                true
              )
            }
          )}
        />

        {data?.routine_inspection?.is_active ? (
          <Value
            label={t(
              'tasks.inspections.task-triggers.routine-inspections.schedule-frequency.schedule-additional.message'
            )}
            value={`${pluralize(
              data?.routine_inspection?.unit,
              data?.routine_inspection?.count,
              true
            )}`}
          />
        ) : null}

        <Semibold>
          {t(
            'tasks.inspections.task-triggers.routine-inspections.when-to-trigger.message'
          )}
        </Semibold>

        <Value
          label='Create an inspection task'
          value={`${pluralize(
            data?.notice_period?.unit,
            data?.notice_period?.count,
            true
          )} before scheduled inspection date`}
        />

        {data?.inspection_automatic_task_creation === 'service_packages' ? (
          <Grid columns={2}>
            <ServicePackageNames
              ids={data?.inspection_service_package_ids ?? []}
            />
          </Grid>
        ) : null}
      </Grid>
    );
  },

  Edit: ({ values, setFieldValue }) => {
    const { t } = useTranslation();
    return (
      <Grid columns={1}>
        <Semibold>
          {t(
            'tasks.inspections.task-triggers.routine-inspections.frequency.message'
          )}
        </Semibold>

        <Field
          label='Schedule the first inspection'
          name='split_first_inspection'
          Input={TimePeriodInput}
          inputProps={{
            testId: 'first_inspection',
            countName: 'first_inspection.count',
            unitName: 'first_inspection.unit',
            Suffix: (
              <Body>
                {t(
                  'tasks.inspections.task-triggers.lease-agreements.frequency.first-inspection-schedule.field.suffix'
                )}
              </Body>
            )
          }}
          optional={false}
        />

        <Checkbox
          label='Schedule additional inspections'
          value={values?.routine_inspection?.is_active}
          onChange={(event) => {
            setFieldValue?.(
              'routine_inspection.is_active',
              event.target.checked
            );
          }}
        />

        {values?.routine_inspection?.is_active ? (
          <Field
            label={
              t(
                'tasks.inspections.task-triggers.routine-inspections.schedule-frequency.schedule-additional.message'
              ) as string
            }
            name='split_routine_inspection'
            Input={TimePeriodInput}
            inputProps={{
              testId: 'routine_inspection',
              countName: 'routine_inspection.count',
              unitName: 'routine_inspection.unit'
            }}
            optional={false}
          />
        ) : null}

        <Semibold>
          {t(
            'tasks.inspections.task-triggers.routine-inspections.when-to-trigger.message'
          )}
        </Semibold>

        <Field
          label='Create an inspection task'
          name='split_notice_period'
          Input={TimePeriodInput}
          inputProps={{
            testId: 'notice_period',
            countName: 'notice_period.count',
            unitName: 'notice_period.unit',
            Suffix: <Body>before scheduled inspection date</Body>
          }}
          optional={false}
        />

        <Grid columns={2}>
          <Field
            Input={AutomaticTaskCreationRadioButtons}
            name='inspection_automatic_task_creation'
            id='inspection_automatic_task_creation'
            optional={false}
          />
        </Grid>

        {values?.inspection_automatic_task_creation === 'service_packages' ? (
          <Field
            name='inspection_service_packages'
            label='For service packages'
            Input={ServicePackageSelect}
            inputProps={{
              deselectable: true,
              multi: true
            }}
          />
        ) : null}
      </Grid>
    );
  },

  Empty: ({ onEditClick }) => {
    const { t } = useTranslation();

    return (
      <EmptyCard>
        <Message
          title={t('tasks.inspections.type.routine-inspection.label.plural')}
          Illustration={EmptyRecord}
          actions={[
            {
              label: t(
                'tasks.inspections.task-triggers.routine-inspections.set-up.message'
              ) as string,
              intent: 'primary',
              handleAction: onEditClick
            }
          ]}
        >
          <div>
            {t(
              'tasks.inspections.task-triggers.lease-agreements.set-up.message'
            )}
          </div>
        </Message>
      </EmptyCard>
    );
  }
};
