import React, { ReactNode } from 'react';
import { Body, Heading } from '@rexlabs/text';
import Box from '@rexlabs/box';
import { useToken } from '@rexlabs/styling';

interface EmptyState {
  title?: ReactNode;
  children?: ReactNode;
  action?: ReactNode;
  noPadding?: boolean;
}

export function EmptyState({
  title,
  children = 'No items found.',
  action,
  noPadding
}: EmptyState) {
  const token = useToken();
  return (
    <Box
      p={!noPadding && token('spacing.xl')}
      width='100%'
      alignItems='center'
      justifyContent='center'
      flexDirection='column'
      style={{ textAlign: 'center' }}
    >
      {title && <Heading level={4}>{title}</Heading>}
      {typeof children === 'string' ? <Body grey>{children}</Body> : children}
      {action}
    </Box>
  );
}
