import config from 'config';

import { DirectionsRouteResponse } from '../types/DirectionsRouteResponse';
import { Coordinates } from '../types/Coordinates';

export const DEPART_AT_DATE_FORMAT = 'YYYY-MM-DDTHH:ss';

export async function fetchRoute(
  coordinates: Coordinates[],
  departAt: string // see DEPART_AT_DATE_FORMAT
): Promise<DirectionsRouteResponse> {
  const query = new URLSearchParams();

  query.set('depart_at', departAt);
  query.set('access_token', config.MAPBOX_API_KEY);

  const route = await fetch(
    `https://api.mapbox.com/directions/v5/mapbox/driving/${coordinates.join(
      ';'
    )}?${query}`
  ).then((res) => res.json());

  return route;
}
