import { StatusTagMap } from 'src/modules/common/components/status-tag';
import { StatementStatus } from 'src/modules/statements/types';

export const statementStatusMap: StatusTagMap<StatementStatus> = {
  [StatementStatus.Issued]: 'good',
  [StatementStatus.Void]: 'bad',
  [StatementStatus.Approved]: 'neutral',
  [StatementStatus.NeedsFurtherReview]: 'neutral',
  [StatementStatus.Excluded]: 'bad'
};
