import { Columns, ListTable, TabbedTable } from 'view/components/table';
import React, { useCallback, useMemo } from 'react';
import { useMediaQuery } from '@rexlabs/breakpoints';
import { auditableColumns } from 'src/modules/common/auditable/auditable-columns';
import ROUTES from 'routes/app';
import { useTableFilters } from 'view/hooks/use-table-filters';
import { useOwnershipActions } from 'src/modules/ownerships/actions/use-ownership-actions';
import { getOwnershipListQuery } from 'src/modules/ownerships/queries/ownership-list-query';
import { transformActionDeclarationsToActionMenuItems } from 'utils/actions/transforms';
import { Tab } from 'view/components/table/tabbed';
import { useGetBulkMessageActions } from 'src/modules/communications/messages/hooks/action-declarations/use-get-bulk-message-actions';
import { ownershipsModel } from 'data/models/entities/ownerships';
import { ActionDeclaration } from 'src/modules/common/actions/types/action-declaration-types';
import { FLAGS } from 'utils/feature-flags';
import { useFeatureFlags } from 'view/components/@luna/feature-flags';
import { useTranslation } from 'react-i18next';
import { TwoTierCell } from 'view/components/cells/two-tier-cell';

const useColumns = (): Columns => {
  const { t } = useTranslation();

  return [
    {
      id: 'display_name',
      Header: 'Name',
      width: 300,
      accessor: (item) => item,
      Cell: ({ value }) => (
        <TwoTierCell
          text={value.display_name}
          subtext={value.record_reference}
        />
      ),
      toCsv: (item) => `${item.display_name} ${item.record_reference}`
    },
    {
      id: 'financial_summary.bills_and_fees_payable_total_amount_owing',
      type: 'currency',
      Header: 'Bills & fees payable',
      cellProps: {
        align: 'right'
      },
      width: 200
    },
    {
      id: 'financial_summary.invoices_receivable_total_amount_owing',
      type: 'currency',
      Header: 'Invoices receivable',
      cellProps: {
        align: 'right'
      },
      width: 180
    },
    {
      id: 'disbursement_withheld_funds.amount',
      Header: 'Withheld funds',
      type: 'currency',
      accessor: (item) =>
        item.disbursement_withheld_funds.amount > 0
          ? item.disbursement_withheld_funds.amount
          : null,
      cellProps: {
        align: 'right'
      },
      width: 160
    },
    {
      Header: t(
        'financials.trust.available-trust-balance.label.singular'
      ) as string,
      accessor: (item) => item.trust_summary?.available_balance_amount,
      type: 'currency',
      cellProps: {
        align: 'right'
      },
      width: 180
    },
    {
      //TODO: replace this with disbursement_due_date
      id: 'disbursement_due_date',
      type: 'date',
      Header: 'Next disbursement',
      width: 180
    },
    ...auditableColumns
  ];
};

const getRowLinkProps = ({ item }) => ({
  to: ROUTES.OWNERSHIP,
  params: { ownershipId: item.id }
});

export function OwnershipTable() {
  const { hasFeature } = useFeatureFlags();
  const isSmallScreen = useMediaQuery({ maxWidth: 'xl' });
  const { getSort, getFilters } = useTableFilters('ownerships');
  const columns = useColumns();
  const ownershipActions = useOwnershipActions();
  const getBulkMessageActions = useGetBulkMessageActions(ownershipsModel);

  const getBulkActions = useCallback(
    (args) => {
      const actions: ActionDeclaration[] = [];
      if (hasFeature(FLAGS.BULK_MESSAGES)) {
        actions.push(...getBulkMessageActions(args.selectedItems));
      }
      return actions;
    },
    [getBulkMessageActions, hasFeature]
  );

  const tabs = useMemo<Tab[]>(() => {
    const commonProps = {
      id: 'ownerships-table',
      getBulkActions,
      Table: ListTable,
      initialHiddenColumns: [
        'created_by',
        'created_at',
        'updated_at',
        'updated_by',
        'disbursement_due_date',
        ...(isSmallScreen
          ? [
              'financial_summary.bills_and_fees_payable_total_amount_owing',
              'financial_summary.invoices_receivable_total_amount_owing',
              'disbursement_withheld_funds.amount'
            ]
          : [])
      ],
      alwaysExpandSearch: true,
      getSort,
      getFilters,
      columns,
      getQuery: getOwnershipListQuery,
      suggestedFilters: [
        'display_name',
        'disbursement_on_frequency_next_disbursement_date',
        'summary_bills_and_fees_payable_total_amount_owing',
        'summary_invoices_receivable_total_amount_owing'
      ],
      getActionMenuItems: ({ item }) =>
        transformActionDeclarationsToActionMenuItems(ownershipActions(item), [
          ['primary-record', 'management-agreement'],
          ['inspectable-entity', 'maintainable-entity']
        ]),
      getRowLinkProps
    };
    return [
      {
        ...commonProps,
        forcedGlobalFilter: [
          {
            field: 'status_id',
            op: 'eq',
            value: 'active'
          }
        ],
        name: 'active',
        label: 'Active'
      },
      {
        ...commonProps,
        forcedGlobalFilter: [
          {
            field: 'status_id',
            op: 'eq',
            value: 'archived'
          }
        ],
        name: 'archived',
        label: 'Archived'
      }
    ];
  }, []);

  return <TabbedTable tabs={tabs} />;
}
