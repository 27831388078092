import React, { forwardRef, PropsWithChildren, CSSProperties } from 'react';

import { useStyles, StyleSheet, margin, border, mq } from '@rexlabs/styling';

const defaultStyles = StyleSheet({
  container: {
    ...border.styles({
      bottom: {
        width: 'thin',
        color: 'container.static.medium'
      }
    }),

    ...mq.styles.combine([
      {
        queries: {
          maxWidth: 's'
        },
        styles: {
          ...margin.styles({
            target: 'card.content',
            x: ({ token }) => `-${token('spacing.m')}`
          })
        }
      },
      {
        queries: {
          minWidth: 's'
        },
        styles: {
          ...margin.styles({
            target: 'card.content',
            x: ({ token }) => `-${token('spacing.xl')}`
          })
        }
      }
    ])
  }
});

export type CardDividerProps = PropsWithChildren<{
  style?: CSSProperties;
  className?: string;
}>;

export const CardDivider = forwardRef<HTMLDivElement, CardDividerProps>(
  function CardDivider({ children, style, className }, ref) {
    const s = useStyles(defaultStyles);

    return (
      <div {...s.with('container')({ style, className })} ref={ref}>
        {children}
      </div>
    );
  }
);
