import React from 'react';

import { ControlledTabs } from '@rexlabs/tabs';
import { useMediaQuery } from '@rexlabs/breakpoints';

import { ColoredCard } from 'view/components/cards/colored-card';
import { Grid } from 'view/components/@luna/form/grid';
import { Card } from 'view/components/card';

import { useInspectionRunTabs } from '../hooks/use-inspection-run-tabs';
import { InspectionRunPlanner } from './inspection-run-planner';
import { InspectionRunMap } from './inspection-run-map';

export function InspectionRunDetailsComponent({ isEditMode }) {
  const { activeTab, onTabChange, tabs } = useInspectionRunTabs(isEditMode);
  const matchesDesktop = useMediaQuery({ minWidth: 'm' });

  return !matchesDesktop ? (
    <Card>
      <ControlledTabs
        activeTab={activeTab}
        onChange={onTabChange}
        items={tabs}
      />
    </Card>
  ) : (
    <ColoredCard intent='neutral'>
      <Grid columns={2}>
        <InspectionRunPlanner isEditMode={isEditMode} />
        <InspectionRunMap />
      </Grid>
    </ColoredCard>
  );
}
