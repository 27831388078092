import { DialogProps } from '@rexlabs/dialog';
import * as React from 'react';
import {
  RecordDialog,
  RecordDialogProps
} from 'view/components/record-screen/dialog/dialog';
import { RecordSubmitHandler } from 'view/components/record-screen/utils';
import { AttachDocumentFromARecordForm } from 'src/modules/communications/messages/types/attach-document-from-a-record';
import { useCallback, useState } from 'react';
import { attachDocumentFromARecordBlock } from 'src/modules/communications/messages/blocks/attach-documents-from-a-record-block';
import { GetHandlers } from 'view/components/record-screen/types';
import { HasFile } from 'src/modules/common/types/file';
import { pluralize } from 'utils/formatters';

interface AttachDocumentsFromARecordProps extends DialogProps {
  initialValues: Partial<AttachDocumentFromARecordForm>;
  onClose?: RecordDialogProps['onClose'];
  onSubmit: (selectedFiles: HasFile[]) => void;
}

const content = [
  {
    id: 'content',
    label: 'content',
    blocks: [attachDocumentFromARecordBlock]
  }
];

const extractSelectedFiles = (values: any): HasFile[] => {
  const selectedDocuments = values.selected_documents ?? [];
  const selectedComplianceRequirements =
    values.selected_compliance_requirements ?? [];

  return [...selectedDocuments, ...selectedComplianceRequirements];
};

export function AttachDocumentsFromARecordDialog({
  onClose,
  initialValues,
  onSubmit
}: AttachDocumentsFromARecordProps) {
  const [numberOfSelectedFiles, setNumberOfSelectedFiles] = useState<number>(0);

  const handleSubmit: RecordSubmitHandler = useCallback(async ({ values }) => {
    const selectedFiles = extractSelectedFiles(values);

    onSubmit(selectedFiles);
    return true;
  }, []);

  const getHandlers: GetHandlers = (form) => ({
    handleSelectedFilesChanged: () => {
      const formValues = form?.['content'].values ?? [];
      const selectedFiles = extractSelectedFiles(formValues);

      setNumberOfSelectedFiles(selectedFiles.length);
    }
  });

  return (
    <RecordDialog
      title='Attach documents from a record'
      submitLabel={`Attach ${numberOfSelectedFiles} selected ${pluralize(
        'document',
        numberOfSelectedFiles
      )}`}
      initialValues={initialValues}
      handleSubmit={handleSubmit}
      getHandlers={getHandlers}
      content={content}
      onClose={onClose}
      autofocus={false}
    />
  );
}
