import React from 'react';
import { BlockConfig } from 'view/components/record-screen/types';
import { Grid } from 'view/components/@luna/form/grid';
import { Value } from 'view/components/values';
import { Field } from '@rexlabs/form';
import InfoCircleIcon from 'view/components/icons/info';
import { InfoBanner } from 'view/components/@luna/notifications/banner';
import { RadioGroupInput } from '@rexlabs/radio-input';
import { RadioOptionValueList } from 'src/modules/settings/account-settings/blocks/rent-arrears-paid-to-date-settings';
import {
  BatchReceiptingExcessMatchValues,
  BatchReceiptingMatchValues,
  Settings
} from '../../hooks/useSettings';

const batchReceiptingMatchOptionsMap: Record<
  BatchReceiptingMatchValues,
  RadioOptionValueList<BatchReceiptingMatchValues>
> = {
  rent_invoices: {
    value: 'rent_invoices',
    label: 'Rent invoices'
  },
  all_invoices: {
    value: 'all_invoices',
    label: 'All invoices'
  }
};

const batchReceiptingMatchOptions = Object.values(
  batchReceiptingMatchOptionsMap
);

const batchReceiptingExcessMatchOptionsMap: Record<
  BatchReceiptingExcessMatchValues,
  RadioOptionValueList<BatchReceiptingExcessMatchValues>
> = {
  manual_allocation: {
    value: 'manual_allocation',
    label: 'Do not receipt and ask for manual allocation'
  },
  receipt_as_deposit: {
    value: 'receipt_as_deposit',
    label: 'Receipt as tenancy deposit'
  }
};

const batchReceiptingExcessMatchOptions = Object.values(
  batchReceiptingExcessMatchOptionsMap
);

function BatchReceiptingSettingsInfoBanner(props) {
  return (
    <InfoBanner
      Icon={InfoCircleIcon}
      description={getBannerText(props.matchValue)}
    />
  );
}

function getBannerText(value: BatchReceiptingMatchValues) {
  if (value === 'rent_invoices') {
    return 'The quick receipt option will be available if the matched folio has got rent invoices only, and for a single property. If any other invoices or receipting instructions exist, funds have to be allocated before receipting.';
  }

  return 'The quick receipt option will be available for all matched transactions, regardless of invoice type or receipting instructions.';
}

interface BatchSettings {
  'batch-receipting-match-setting': BatchReceiptingMatchValues;
  'batch-receipting-excess-match-setting': BatchReceiptingExcessMatchValues;
}

export const batchReceiptingSettingsBlock: BlockConfig<
  Pick<
    Settings,
    'batch-receipting-match-setting' | 'batch-receipting-excess-match-setting'
  >,
  any,
  BatchSettings
> = {
  id: 'batch-receipting-settings',
  title: 'Batch receipting',
  validate: {
    definitions: {
      'batch-receipting-match-setting': { rules: 'required' },
      'batch-receipting-excess-match-setting': { rules: 'required' }
    }
  },
  View: ({ data }) => {
    const matchValue =
      batchReceiptingMatchOptionsMap[
        data?.['batch-receipting-match-setting']?.id || 'rent_invoices'
      ];

    const excessValue =
      batchReceiptingExcessMatchOptionsMap[
        data?.['batch-receipting-excess-match-setting']?.id ||
          'manual_allocation'
      ];

    return (
      <Grid columns={1}>
        <Grid columns={2}>
          <Value
            label='Allow quick receipting for matched folios with'
            value={matchValue.label}
          />
        </Grid>
        <Grid columns={2}>
          <BatchReceiptingSettingsInfoBanner matchValue={matchValue.value} />
        </Grid>
        <Grid columns={2}>
          <Value
            label='If amount exceeds items being matched against'
            value={excessValue.label}
          />
        </Grid>
      </Grid>
    );
  },
  Edit: ({ values }) => {
    const matchValue =
      values?.['batch-receipting-match-setting'] || 'rent_invoices';

    return (
      <Grid columns={1}>
        <Grid columns={2}>
          <Field
            id='batch-receipting-match-setting'
            label='Allow quick receipting for matched folios with'
            name='batch-receipting-match-setting'
            Input={RadioGroupInput}
            inputProps={{
              options: batchReceiptingMatchOptions,
              orientation: 'horizontal'
            }}
            optional={false}
          />
        </Grid>
        <Grid columns={2}>
          <BatchReceiptingSettingsInfoBanner matchValue={matchValue} />
        </Grid>
        <Grid columns={2}>
          <Field
            id='batch-receipting-excess-match-setting'
            label='If amount exceeds items being matched against'
            name='batch-receipting-excess-match-setting'
            Input={RadioGroupInput}
            inputProps={{
              options: batchReceiptingExcessMatchOptions,
              orientation: 'horizontal'
            }}
            optional={false}
          />
        </Grid>
      </Grid>
    );
  }
};
