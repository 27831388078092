import React from 'react';
import { Field } from '@rexlabs/form';
import { RadioGroupInput } from '@rexlabs/radio-input';

import { Grid } from 'view/components/@luna/form/grid';
import { InfoBanner } from 'view/components/@luna/notifications/banner';
import { BlockConfig } from 'view/components/record-screen/types';
import InfoCircleIcon from 'view/components/icons/info';
import { Value } from 'view/components/values';

import { formatCurrency } from 'utils/formatters';
import { ArrearsCalculationValues, Settings } from '../../hooks/useSettings';

export interface RadioOptionValueList<T> {
  value: T;
  label: string;
}

const arrearsCalculationOptionsMap: Record<
  ArrearsCalculationValues,
  RadioOptionValueList<ArrearsCalculationValues>
> = {
  effective_arrears: {
    value: 'effective_arrears',
    label: 'Effective arrears'
  },
  periodic_arrears: {
    value: 'periodic_arrears',
    label: 'Periodic arrears'
  }
};

const rentPaidToDateOptions = Object.values(arrearsCalculationOptionsMap);

function PeriodicArrearsDescription() {
  return (
    <Grid columns={3}>
      <div>
        {`Using this method, arrears is calculated by the rent owed per period for
        the property (week/fortnight/month). In other words, if a tenant pays
        ${formatCurrency(
          42000
        )} weekly, their last paid-to date is 9 June, and they miss their
        payment of ${formatCurrency(
          42000
        )} on 10 June, the tenant will be 1 day in arrears to the
        value of ${formatCurrency(
          42000
        )}. If they haven't paid by 11 June (2 days after their
        last paid-to date), the tenant will then be 2 days in arrears, still to
        the value of ${formatCurrency(
          42000
        )} (where the rent period is per week)`}
      </div>
      <div>
        {`Rex PM ignores part payment amounts when calculating the number of days
        a tenant is in arrears, but accounts for part payments when calculating
        the amount of money owed. In other words, if the same tenant makes a
        part payment of ${formatCurrency(
          12000
        )} on 12 June, the tenant will still be 3 days in
        arrears, but the amount owed will be calculated as ${formatCurrency(
          42000
        )} less the ${formatCurrency(12000)}
        part payment, or ${formatCurrency(42000)} - ${formatCurrency(
          12000
        )} = ${formatCurrency(30000)}.`}
      </div>
      <div>
        {`This means that until the tenant makes a full payment for the current
        rent period, the number of days in arrears will be counted from the last
        paid-to date for a full rent period.`}
      </div>
    </Grid>
  );
}

function EffectiveArrearsDescription() {
  return (
    <Grid columns={3}>
      <div>
        {`Using this method, rent arrears is calculated as per the rent owed per
        day for the property. In other words, if the rent for a property is ${formatCurrency(
          42000
        )}
        per week, the rent owed per day is ${formatCurrency(
          6000
        )} (${formatCurrency(42000)}p.w. ÷ 7 days = ${formatCurrency(
          6000
        )} p/day).
        If a tenant's paid-to date was 9 June, and a payment of ${formatCurrency(
          42000
        )} was
        due on 10 June, and the rent was not paid, then on 11 June, Rex PM will
        calculate the tenant as being 2 days in arrears, to the value of ${formatCurrency(
          12000
        )}:
        ${formatCurrency(6000)} per day x 2 days overdue = ${formatCurrency(
          12000
        )}`}
      </div>
      <div>
        {`When a tenant makes part payments of rent, Rex PM adjusts both the
        amount in arrears and days in arrears. If rent for a property is ${formatCurrency(
          42000
        )}
        p/week, and 9 June is the tenant's current paid-to date, then a
        part payment of ${formatCurrency(12000)} (${formatCurrency(
          6000
        )} per day x 2 days) made on 12 June (when the
        tenant is 3 days and ${formatCurrency(
          18000
        )} in arrears) will reduce arrears by 2 days and
        ${formatCurrency(
          12000
        )}. After the part payment, the tenant is 1 day and ${formatCurrency(
          6000
        )} in arrears.`}
      </div>
      <div>
        {`On the other hand, if the same tenant was 3 days and ${formatCurrency(
          18000
        )} in arrears on
        12 June, and made a part payment that day of ${formatCurrency(
          10000
        )} (i.e. less than 2 full
        days' rent), Rex PM would calculate that the tenant only reduced
        their arrears by 1 day, with ${formatCurrency(8000)} outstanding.`}
      </div>
    </Grid>
  );
}

function RentArrearsCalculationHelper({ value }) {
  switch (value) {
    case 'periodic_arrears':
      return (
        <InfoBanner
          Icon={InfoCircleIcon}
          title={'Periodic arrears'}
          description={PeriodicArrearsDescription()}
        />
      );

    case 'effective_arrears':
    default:
      return (
        <InfoBanner
          Icon={InfoCircleIcon}
          title={'Effective arrears'}
          description={EffectiveArrearsDescription()}
        />
      );
  }
}

export const rentArrearsCalculationSettingsBlock: BlockConfig<
  Pick<Settings, 'rent-arrears-calculation'>
> = {
  id: 'rent-arrears-calculation',
  title: 'Rent arrears calculation',
  View: ({ values }) => {
    const value =
      arrearsCalculationOptionsMap[
        values?.['rent-arrears-calculation'] || 'effective_arrears'
      ];

    return (
      <Grid columns={1}>
        <Grid columns={2}>
          <Value label='Calculation method' value={value.label} />
        </Grid>
        <RentArrearsCalculationHelper value={value.value} />
      </Grid>
    );
  },

  Edit: ({ values }) => {
    return (
      <Grid columns={1}>
        <Grid columns={2}>
          <Field
            Input={RadioGroupInput}
            inputProps={{
              options: rentPaidToDateOptions,
              orientation: 'horizontal'
            }}
            name='rent-arrears-calculation'
            label='Calculation method'
            id='rent-arrears-calculation'
            optional={false}
          />
        </Grid>
        <RentArrearsCalculationHelper
          value={values?.['rent-arrears-calculation']}
        />
      </Grid>
    );
  }
};
