import { AxiosResponse } from 'axios';

import { useQuery } from 'react-query';
import { api } from 'utils/api/api-client';

import { BatchReceiptingStatsSummary } from '../types';

async function fetcher(
  bankAccountId
): Promise<AxiosResponse<BatchReceiptingStatsSummary>> {
  return api.get(
    `financials/bank-statement-transactions-summary?bank_account_id=${bankAccountId}`
  );
}

export function useBatchReceiptingStatsSummary(bankAccountId?: string) {
  return useQuery(['batchReceiptingStatsSummary', bankAccountId], () =>
    fetcher(bankAccountId)
  );
}
