import { useDialog } from '@rexlabs/dialog';
import { ConfirmBulkTransferDialog } from 'src/modules/invoices/dialogs/confirm-bulk-transfer-dialog';
import { Invoice } from 'data/models/entities/financials/invoices';
import { BulkAction } from '@rexlabs/table/lib/types/bulk-actions/simple';
import { OutlineButton } from '@rexlabs/button';

export function useGetConfirmBulkTransferAction() {
  const { open: openConfirmBulkTransferDialog } = useDialog(
    ConfirmBulkTransferDialog
  );

  return (invoices: Invoice[]): BulkAction => {
    return {
      label: `Transfer to pay ${invoices.length} invoices`,
      type: 'secondary',
      handleAction: () => openConfirmBulkTransferDialog({ invoices }),
      ActionTrigger: OutlineButton
    };
  };
}
