import { BankAccount } from 'data/models/entities/financials/bank-accounts';
import { getTrustLedger } from 'utils/api/get-trust-ledger';
import { FinancialRecord } from 'utils/financials/financial-objects';
import { useQuery } from 'react-query';

export function useTrustLedger(
  record?: FinancialRecord,
  bankAccount?: BankAccount
) {
  const { data: trustLedger, isLoading } = useQuery(
    ['trust-ledger', record?.id, bankAccount?.id],
    () => {
      if (!record || !bankAccount) {
        return undefined;
      }
      return getTrustLedger(record, bankAccount.id);
    },
    {
      enabled: !!record?.id && !!bankAccount?.id
    }
  );

  return { isLoading, trustLedger };
}
