import React from 'react';
import dayjs from 'dayjs';

import Box from '@rexlabs/box';
import { useToken } from '@rexlabs/styling';

import { Grid } from 'view/components/@luna/form/grid';
import { BlockConfig } from 'view/components/record-screen/types';
import { ImagePreview } from 'view/components/image-preview';
import { Value } from 'view/components/values';

import { DialogLineItem } from 'src/modules/common/components/dialog-line-item';
import { KeySet } from '../data/key-set-model';
import { useManageKeySetActionButton } from '../hooks/use-manage-key-set-action-button';

export type ManageKeySetBlockValues = Pick<
  KeySet,
  | 'checked_in_date'
  | 'checked_out_date'
  | 'object'
  | 'notes'
  | 'images'
  | 'status'
  | 'return_by_date'
  | 'status_reason'
>;

export const manageKeySetBlock: BlockConfig<
  KeySet,
  any,
  ManageKeySetBlockValues
> = {
  id: 'check-out-key-set-block',
  Edit: ({ data }) => {
    const ActionButton = useManageKeySetActionButton(data);
    const token = useToken();
    const isCheckedOut = data?.status?.id === 'checked_out';
    return (
      <Grid columns={1}>
        <Value value={data?.notes} label='Notes' />
        <Value
          label='Images of keys'
          value={
            <Box
              width='auto'
              flexDirection='row'
              flexWrap='wrap'
              gap={token('spacing.xs')}
            >
              {data?.images?.data?.map((image) => (
                <ImagePreview
                  key={image.id}
                  src={image?.file.url || ''}
                  downloadUrl={image?.file.download_url}
                />
              ))}
            </Box>
          }
        />
        <DialogLineItem
          heading='Check-out details'
          ActionButton={<ActionButton data={data!} />}
        >
          <Grid columns={1}>
            <Grid columns={3}>
              <Value
                label='Key set holder'
                value={isCheckedOut ? data?.object?.display_name : undefined}
              />
              <Value
                label='Date checked-out'
                value={
                  data?.checked_out_date &&
                  dayjs(data?.checked_out_date).format('DD MMM YYYY')
                }
              />
              <Value
                label='Return by'
                value={
                  data?.return_by_date &&
                  dayjs(data?.return_by_date).format('DD MMM YYYY')
                }
              />
            </Grid>
            <Value
              label='Notes'
              value={isCheckedOut ? data?.status_reason : undefined}
            />
          </Grid>
        </DialogLineItem>
      </Grid>
    );
  },
  validate: {
    definitions: {
      checked_in_date: { name: 'date checked-in', rules: 'required' }
    }
  }
};
