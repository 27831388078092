import React from 'react';
import { Column, Grid } from '@rexlabs/grid';
import { Field } from '@rexlabs/form';
import _ from 'lodash';
import { ContactPaymentMethodMethod } from 'data/models/entities/contacts/payment-methods';
import { TextInput } from '@rexlabs/text-input';
import { ValueListValue } from 'data/models/types';
import { ContactPaymentMethodMethodSelect } from 'view/components/inputs/selects/contact-payment-method-method';
import { DisbursementContactPaymentMethodMethodSelect } from 'view/components/inputs/selects/disbursement-contact-payment-method-method';
import Validator from '@rexlabs/validator';
import { useTranslation } from 'react-i18next';
import { ValueListItem } from 'utils/normaliser/value-list-item';

Validator.register(
  'nameMustBeUnique',
  function (this: any, value: string) {
    const matchingPaymentMethods = (
      this.validator?.input?.payment_methods || []
    )
      .map((paymentMethod) => paymentMethod.name)
      .filter((name) => name === value);

    return matchingPaymentMethods.length <= 1;
  },
  'Payment method names must be unique.'
);

Validator.register(
  'onlyOneChequePaymentMethod',
  function (this: any, value: { id: string }) {
    const chequePaymentMethods = (this.validator?.input?.payment_methods || [])
      .map((paymentMethod) => paymentMethod?.payment_method?.id)
      .filter((id) => id === 'cheque');

    return value?.id !== 'cheque' || chequePaymentMethods.length <= 1;
  },
  'Only one cheque payment method is allowed.'
);

Validator.register(
  'onlyOneDirectDebitPaymentMethod',
  function (this: any, value: { id: string }) {
    const directDebitPaymentMethods = (
      this.validator?.input?.payment_methods || []
    )
      .map((paymentMethod) => paymentMethod?.payment_method?.id)
      .filter((id) => id === 'direct_debit');

    return (
      value?.id !== 'direct_debit' || directDebitPaymentMethods.length <= 1
    );
  },
  'Only one direct debit method is allowed.'
);

export function getPaymentMethodFormValidation(fieldPrefix?: string) {
  const prefix = fieldPrefix || '';
  const paymentMethodField = `${prefix}payment_method`;
  const nameField = `${prefix}name`;
  const accountNumberField = `${prefix}account_number`;
  const branchCodeField = `${prefix}branch_code`;

  return {
    [paymentMethodField]: {
      name: 'Payment method',
      rules:
        'required|onlyOneChequePaymentMethod|onlyOneDirectDebitPaymentMethod'
    },
    [nameField]: {
      name: 'name',
      rules: 'required|nameMustBeUnique'
    },
    [accountNumberField]: {
      name: 'account number',
      rules: `required_if:${paymentMethodField}.id,bpay|required_if:${paymentMethodField}.id,eft|required_if:${paymentMethodField}.id,eft_iban|required_if:${paymentMethodField}.id,eft_swift|required_if:${paymentMethodField}.id,eft_bacs|required_if:${paymentMethodField}.id,eft_fps`
    },
    [branchCodeField]: {
      name: 'bank code',
      rules: `required_if:${paymentMethodField}.id,eft|required_if:${paymentMethodField}.id,eft_swift|required_if:${paymentMethodField}.id,eft_bacs|required_if:${paymentMethodField}.id,eft_fps`
    }
  };
}

export function ContactPaymentMethodForm({
  data,
  values,
  setFieldValue,
  blockProps,
  fieldName = ''
}) {
  const { t } = useTranslation();

  function getFieldName(name: string) {
    return !fieldName ? name : `${fieldName}.${name}`;
  }

  const selectedPaymentMethod: ContactPaymentMethodMethod | undefined = _.get(
    values,
    getFieldName('payment_method.id')
  );
  const isEFT =
    selectedPaymentMethod === 'eft' ||
    selectedPaymentMethod === 'eft_bacs' ||
    selectedPaymentMethod === 'eft_fps';
  const isEFT_IBAN = selectedPaymentMethod === 'eft_iban';
  const isEFT_SWIFT = selectedPaymentMethod === 'eft_swift';
  const isBPAY = selectedPaymentMethod === 'bpay';

  return (
    <Grid>
      <Column width={6}>
        <Field
          id={getFieldName('payment_method')}
          name={getFieldName('payment_method')}
          label='Payment method'
          Input={
            data.isForDisbursements
              ? DisbursementContactPaymentMethodMethodSelect
              : ContactPaymentMethodMethodSelect
          }
          onBlur={({ target }) => {
            const {
              label
            } = (target.value as unknown) as ValueListValue<string>;
            setFieldValue?.(getFieldName('name'), label);
          }}
          inputProps={{
            testId: getFieldName('payment_method'),
            shouldReselectClearSelection: false,
            filter: (items: ValueListItem[], _input) => {
              return items.filter((item) => {
                if (
                  blockProps?.hasExistingChequeMethod &&
                  item.id === 'cheque'
                ) {
                  return false;
                }
                return true;
              });
            }
          }}
        />
      </Column>

      {_.get(values, getFieldName('payment_method')) && (
        <Column width={6}>
          <Field
            name={getFieldName('name')}
            label='Payment method display name'
            Input={TextInput}
          />
        </Column>
      )}

      {isBPAY && (
        <Column width={6}>
          <Field
            name={getFieldName('account_number')}
            label='Biller code'
            Input={TextInput}
            optional={false}
          />
        </Column>
      )}

      {(isEFT || isEFT_SWIFT) && (
        <>
          <Column width={6}>
            <Field
              name={getFieldName('branch_code')}
              label={
                isEFT_SWIFT
                  ? 'Swift code'
                  : (t('bank-accounts.branch-code.label.singular') as string)
              }
              Input={TextInput}
              optional={false}
            />
          </Column>
          <Column width={6}>
            <Field
              name={getFieldName('account_number')}
              label='Account number'
              Input={TextInput}
              optional={false}
            />
          </Column>
        </>
      )}

      {isEFT_IBAN && (
        <Column width={6}>
          <Field
            name={getFieldName('account_number')}
            label='Account number'
            Input={TextInput}
            optional={false}
          />
        </Column>
      )}
    </Grid>
  );
}
