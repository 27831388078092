import { Settings } from 'src/modules/settings/hooks/useSettings';
import { useGetServicePackages } from 'src/modules/service-packages/hooks/use-get-service-packages';
import { pick } from 'lodash';

export function useMapLeaseReviewSettings(
  values: Settings['lease-review-task-interval']
) {
  const selectedIds = values?.service_package_ids || [];

  const { data, isLoading } = useGetServicePackages(selectedIds);

  return {
    isLoading,
    leaseReviewSettings: {
      ...pick(values, [
        'periodic_agreement_review',
        'periodic_agreement_notice',
        'fixed_agreement_notice'
      ]),
      lease_review_automatic_task_creation: values?.automatic_task_creation,
      lease_review_service_package_ids: selectedIds,
      lease_review_service_packages: data
    }
  };
}
