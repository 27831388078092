export const MESSAGE_ROUTES = {
  MESSAGE_LIST: {
    config: {
      path: '/messages',
      Component: require('./screens/message-list.tsx').MessageList
    }
  },
  LETTER_LIST: {
    config: {
      path: '/letters',
      Component: require('./screens/letter-list.tsx').LetterList
    }
  },
  MESSAGE_TRIGGER_SETTINGS: {
    config: {
      path: '/settings/message-triggers',
      Component: require('../messages/settings/message-triggers/screens/message-trigger-settings-screen.tsx')
        .MessageTriggerSettings
    }
  }
};
