import { StyleSheet, useStyles } from '@rexlabs/styling';
import {
  StatusAverageTag,
  StatusBadTag,
  StatusGoodTag,
  Tag
} from '@rexlabs/tag';
import { Small } from '@rexlabs/text';
import React, { ComponentType, useCallback } from 'react';
import Box from '@rexlabs/box';
import Tooltip from '@rexlabs/tooltip';
import { ChecklistItem as ChecklistItemType } from '../../common/types/Checklist';
import { ActionStyle, ChecklistActionToggle } from './checklist-action-toggle';

const styles = StyleSheet({
  item: {
    display: 'grid',
    gridTemplateColumns: 'auto 1fr',
    gap: '8px',
    alignItems: 'center',
    fontSize: 14,
    padding: 8,
    borderRadius: 8,
    '& span': {
      color: ({ token }) => token('color.textStyle.body.primary')
    }
  },
  completedText: {
    textDecoration: 'line-through'
  },
  pipeOuter: {
    display: 'block',
    position: 'relative',
    height: '100%'
  },
  decorativePipe: {
    position: 'relative',
    width: 14,
    left: 12,
    height: 'calc(100% - 20px)',
    borderWidth: 0,
    borderStyle: 'solid',
    borderColor: ({ token }) => token('palette.grey.400'),
    borderLeftWidth: 1,
    borderBottomWidth: 1,
    borderBottomLeftRadius: 8
  },
  hiddenPipe: {
    visibility: 'hidden'
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    '& > *': {
      display: 'block'
    }
  },
  subtext: {
    color: ({ token }) => token('color.textStyle.body.subtext'),
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row'
  },
  subtextIcon: {
    marginRight: 8
  }
});

export type ChecklistItemTagType = 'good' | 'average' | 'bad' | 'neutral';

export type ChecklistItemTag = {
  label: string;
  Icon?: React.ComponentType;
  type: ChecklistItemTagType;
};

export type ChecklistItemProps = {
  title: string;
  ActionIcon?: React.ComponentType;
  checked: boolean;
  onChange: (currentItem: ChecklistItemType) => void;
  tags?: ChecklistItemTag[];
  subtext?: React.ReactNode;
  actionStyle?: ActionStyle;
  SubtextIcon?: React.ComponentType<any>;
  hidePipe?: boolean;
  disabled?: boolean;
  item?: ChecklistItemType;
  tooltip?: string;
};

export function ChecklistItem({
  title,
  ActionIcon,
  checked,
  onChange,
  tags,
  subtext,
  SubtextIcon,
  hidePipe,
  actionStyle,
  disabled: itemIsDisabled,
  item,
  tooltip
}: ChecklistItemProps) {
  const s = useStyles(styles);

  const handleChange = useCallback(() => {
    onChange(item!);
  }, [onChange, item]);

  return (
    <div {...s('item')}>
      <Tooltip
        Content={() => tooltip}
        isDisabled={tooltip === undefined || !itemIsDisabled}
        placement='right'
      >
        <div>
          <ChecklistActionToggle
            id={item!.id}
            disabled={itemIsDisabled}
            actionStyle={actionStyle}
            Icon={ActionIcon}
            checked={checked}
            onChange={handleChange}
          />
        </div>
      </Tooltip>
      <span {...(checked ? s('completedText') : {})}>{title}</span>
      <div {...s('pipeOuter', hidePipe ? 'hiddenPipe' : '')}>
        <div {...s('decorativePipe')} />
      </div>
      <div {...s('details')}>
        {(tags?.length || 0) > 0 ? (
          <Box gap={8}>
            {tags?.map((tag, index) => {
              const Tag = getTagForType(tag.type);
              return (
                <Tag key={index} Icon={tag.Icon}>
                  {tag.label}
                </Tag>
              );
            })}
          </Box>
        ) : (
          <div />
        )}
        {subtext && (
          <div>
            <Small {...s('subtext')}>
              {SubtextIcon ? (
                <span {...s('subtextIcon')}>
                  <SubtextIcon size='s' />
                </span>
              ) : null}
              <span>{subtext}</span>
            </Small>
          </div>
        )}
      </div>
    </div>
  );
}

type TagComponent = ComponentType<{ Icon?: React.ComponentType }>;

const getTagForType = (type: ChecklistItemTagType): TagComponent => {
  // Need type assertion here because it doesn't seem aware that these all have the Icon prop
  switch (type) {
    case 'good':
      return StatusGoodTag as TagComponent;
    case 'average':
      return StatusAverageTag as TagComponent;
    case 'bad':
      return StatusBadTag as TagComponent;
    case 'neutral':
      return Tag as TagComponent;
  }
};
