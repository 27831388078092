import React from 'react';
import { Ownership, Owner } from 'data/models/entities/ownerships';
import { PropertyOwnership } from 'src/modules/property-ownerships/types/property-ownership-types';
import { EmptyPropertyOwnership } from '../empty/empty-property-ownership';
import { getOwnershipObjects } from '../utils';
import { OwnershipEntries } from './ownership-entries';

export interface OwnershipSectionProps {
  ownership: Ownership | PropertyOwnership | Owner[] | 'empty';
  maxListLength: number;
}

export function OwnershipSection({
  ownership,
  maxListLength
}: OwnershipSectionProps) {
  if (ownership === 'empty') {
    return <EmptyPropertyOwnership />;
  }
  const objects = getOwnershipObjects(ownership);
  return <OwnershipEntries {...objects} maxListLength={maxListLength} />;
}
