export const MOVE_IN_ROUTES = {
  MOVE_IN_LIST: {
    config: {
      path: '/move-ins',
      Component: require('./screens/move-in-list.tsx').MoveInList
    }
  },
  MOVE_IN_DETAILS: {
    config: {
      path: '/move-in/:moveInId',
      Component: require('./screens/move-in-details.tsx').MoveInDetails
    }
  }
};
