export function getTaxableAccountCheckboxConfig(category?: string) {
  switch (category) {
    case 'income':
      return {
        label: 'This income is taxable for HMRC purposes',
        helperText:
          'This income will be reported in HMRC tax summaries and tax will be withheld for non-resident landlords'
      };
    case 'expense':
      return {
        label: 'This expense is deductible for HMRC purposes',
        helperText:
          'This expense will be reported in HMRC tax summaries and it will be used as a deduction against withheld tax of non-resident landlords'
      };
    default:
      return undefined;
  }
}
