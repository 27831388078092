import { OwnershipFee } from 'data/models/entities/ownerships/fees/ownership-fees';
import { PropertyOwnershipFee } from 'data/models/entities/financials/property-ownerships/property-ownership-fee';
import { AgencyFee } from 'src/modules/agency-fees/types/agency-fee-types';

export function formatTax(
  fee: AgencyFee | OwnershipFee | PropertyOwnershipFee
) {
  if (fee.is_tax_included) {
    return `inc ${fee.tax_type?.label}`;
  }

  return `excl ${fee.tax_type?.label}`;
}
