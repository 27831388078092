import { getDocumentsBlock } from 'src/modules/documents/blocks/documents';
import { Property } from 'src/modules/properties/types/property-types';

export const documentsBlock = getDocumentsBlock(
  ({ data }: { data: Property }) => ({
    attachedTo: [
      {
        id: data.id,
        label: data.display_name,
        type: { id: 'property' }
      }
    ],
    propertyId: data.id
  })
);
