import { ActionDeclaration } from 'src/modules/common/actions/types/action-declaration-types';
import { useModelActions } from '@rexlabs/model-generator';
import { useToast } from 'view/components/@luna/notifications/toast';
import { useErrorDialog } from '@rexlabs/dialog';
import {
  PortfolioMember,
  portfolioMembersModel
} from '../../models/portfolio-members-model';
import { portfoliosModel } from '../../models/portfolios-model';

export function useGetSetPortfolioMemberAsPrimaryAction({
  portfolioId
}: {
  portfolioId: string;
}) {
  const { updateItem } = useModelActions(portfolioMembersModel);
  const { refreshItem } = useModelActions(portfoliosModel);
  const { addToast } = useToast();
  const errorDialog = useErrorDialog();

  return (portfolioMember: PortfolioMember): ActionDeclaration => {
    return {
      id: 'set-portfolio-member-as-primary',
      label: 'Set as primary',
      handleAction: async () => {
        try {
          await updateItem({
            id: portfolioMember.id,
            data: { is_primary: true }
          });
        } catch (error) {
          errorDialog.open(error);
          return;
        }
        addToast({ description: 'Successfully set as primary member' });
        await refreshItem({ id: portfolioId });
      }
    };
  };
}
