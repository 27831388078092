import React, { useCallback } from 'react';
import { useDialog } from '@rexlabs/dialog';

import { RecordTable } from 'view/components/table';

import { SupplierComplianceCategory } from 'data/models/entities/settings/supplier-compliance-categories';
import { complianceCategorySettingsColumns } from 'src/modules/compliance/common/utils/compliance-category-settings-columns';
import { supplierComplianceCategoriesQuery } from '../../common/compliance-entries/data/queries';
import { EditComplianceCategoryDialog } from '../dialogs/edit-compliance-category-dialog';
import { SupplierComplianceCategoryTableEmptyState } from './supplier-compliance-category-table-empty-state';

export function SupplierComplianceCategorySettingsTable() {
  const { open: openEditComplianceCategoryDialog } = useDialog(
    EditComplianceCategoryDialog
  );

  const getQuery = useCallback(() => supplierComplianceCategoriesQuery, []);

  return (
    <RecordTable
      id='supplier-compliance-categories'
      columns={complianceCategorySettingsColumns}
      getQuery={getQuery}
      Empty={SupplierComplianceCategoryTableEmptyState}
      emptyWithoutTable
      withPagination={true}
      getActionMenuItems={({
        item
      }: {
        item: SupplierComplianceCategory;
        entity?: any;
      }) => {
        return [
          {
            label: 'Edit',
            onClick: () =>
              openEditComplianceCategoryDialog({ categoryId: item.id })
          }
        ];
      }}
    />
  );
}
