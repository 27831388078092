import { DialogProps } from '@rexlabs/dialog';
import { useModelActions } from '@rexlabs/model-generator';
import { Tenant, tenancyModel } from 'data/models/entities/tenancies';
import * as React from 'react';
import { Grid } from 'view/components/@luna/form/grid';
import { RecordDialog } from 'view/components/record-screen/dialog/dialog';
import { BlockConfig } from 'view/components/record-screen/types';
import { Value } from 'view/components/values';

export interface UpdateTenantReferenceNumberDialogProps extends DialogProps {
  folioId: string;
  tenantIds: Array<string>;
  tenants: Array<Tenant>;
  initialValues: Partial<{
    tenantName: string;
    tenantReferenceNumbers: string;
    newReferenceNumber: string;
  }>;
  onSave: () => void;
}

const detailsBlock: BlockConfig = {
  id: 'details',
  Edit: ({ data }) => {
    return (
      <Grid columns={2}>
        <Value label='Tenant' value={data?.tenantName} />
        <div />

        <Value
          label='Current tenant reference number'
          value={data?.tenantReferenceNumbers}
        />
        <Value
          label='New tenant reference number'
          value={data?.newReferenceNumber}
        />
      </Grid>
    );
  }
};

const content = [
  {
    id: 'details',
    label: '',
    blocks: [detailsBlock]
  }
];

export function UpdateTenantReferenceNumberDialog({
  folioId,
  tenantIds,
  tenants,
  onSave,
  onClose,
  initialValues
}: UpdateTenantReferenceNumberDialogProps) {
  const { updateItem } = useModelActions(tenancyModel);

  const handleSubmit = async () => {
    await updateItem({
      id: folioId,
      data: {
        tenants: tenants.map((tenant) => {
          if (!tenantIds.includes(tenant.contact.id)) return tenant;

          return {
            ...tenant,
            payment_reference: initialValues.newReferenceNumber
          };
        })
      }
    });

    onSave();

    return true;
  };

  return (
    <RecordDialog
      title='Update tenant reference number'
      data={initialValues}
      content={content}
      onClose={onClose}
      handleSubmit={handleSubmit}
      size='s'
    />
  );
}
