import { StatusTagMap } from 'src/modules/common/components/status-tag';
import { ReconciliationStatus } from 'data/models/entities/financials/reconciliations';

export const reconciliationStatusMap: StatusTagMap<ReconciliationStatus> = {
  active: 'average',
  reconciled: 'good',
  verified: 'good',
  unverified: 'average',
  voided: 'bad'
};
