import { api } from 'utils/api/api-client';
import { BankAccount } from 'data/models/entities/financials/bank-accounts';
import { useCachedItems } from 'view/hooks/api/cached-items';

export interface UseBankAccountsOptions {
  include?: string;
}

export function useBankAccounts(options?: UseBankAccountsOptions) {
  const url = `/financials/bank-accounts`;
  const { isLoading, items: bankAccounts } = useCachedItems(
    url,
    async () => {
      const bankAccountsResponse = await api.get(url, {
        include: options?.include,
        per_page: 30
      });

      return bankAccountsResponse.data as BankAccount[];
    },
    {
      prefetch: true
    }
  );

  const defaultBankAccount = bankAccounts?.find(
    (bankAccount) => bankAccount.is_default
  );

  return { isLoading, bankAccounts, defaultBankAccount };
}
