import React from 'react';

import { BlockConfig } from 'view/components/record-screen/types';
import Box from '@rexlabs/box';
import { Field } from '@rexlabs/form';
import { Grid } from 'view/components/@luna/form/grid';
import { DateInput } from 'view/components/@luna/inputs/date-input/date-input';
import { DateValue } from 'view/components/values/date';
import { Task } from 'src/modules/tasks/common/types/Task';
import { TaskFormData } from 'src/modules/tasks/common/types/TaskFormData';

export const inspectionDateBlock: BlockConfig<
  Task<'task_inspection'>,
  any,
  TaskFormData
> = {
  id: 'dates',
  title: 'Follow up and due date',
  View: ({ data }) => {
    return (
      <Grid columns={2}>
        <DateValue label='Next follow up date' value={data?.follow_up_date} />
        <DateValue label='Due date' value={data?.due_date} />
      </Grid>
    );
  },
  Edit: () => {
    return (
      <Box sy='2rem'>
        <Grid columns={4}>
          <Field
            id='follow_up_date'
            name='follow_up_date'
            label='Next follow up date'
            Input={DateInput}
            inputProps={{
              clearable: true
            }}
          />
          <Field
            id='due_date'
            name='due_date'
            label='Due date'
            Input={DateInput}
            inputProps={{
              clearable: true
            }}
          />
        </Grid>
      </Box>
    );
  }
};
