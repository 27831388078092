import React, { FC } from 'react';
import { isEmpty } from 'lodash/fp';
import dayjs from 'dayjs';
import Box from '@rexlabs/box';
import {
  ButtonGroup,
  GhostButton,
  PrimaryButton,
  SecondaryButton
} from '@rexlabs/button';
import { Checkbox } from '@rexlabs/checkbox';
import { Field, Form, ReactForms } from '@rexlabs/form';
import { Column, Grid } from '@rexlabs/grid';
import { useToken } from '@rexlabs/styling';
import { Body, Heading, Label } from '@rexlabs/text';

import { formatCurrency } from 'utils/formatters';
import { formatDateLong } from 'utils/dates/format';
import { TenancyModel } from 'data/models/entities/tenancies';
import { StepsDialogStepProps } from 'view/components/dialogs/steps-dialog';
import SupportIcon from 'view/components/icons/support';
import { RentAdjustmentDetailsForm } from './details';

export interface RentAdjustmentSummaryProps extends StepsDialogStepProps {
  onClose: (...args: any[]) => void;
  initialValues: any;
  accumulatedStepState: {
    formData: RentAdjustmentDetailsForm;
    tenancy: TenancyModel;
    summaryFormData?: SummaryForm;
  };
  tenancy: TenancyModel;
  handleSubmit: (values: any) => Promise<any> | any;
}

export interface SummaryForm {
  read_warning?: boolean;
}

type ADJUSTMENT_TYPE = 'increase' | 'decrease';

export const RentAdjustmentSummary: FC<RentAdjustmentSummaryProps> = ({
  onClose,
  prevStep,
  accumulatedStepState,
  initialValues,
  tenancy,
  handleSubmit: handleSubmitProp
}) => {
  const token = useToken();
  const { formData } = accumulatedStepState;

  const adjustmentType: ADJUSTMENT_TYPE =
    formData.amount > tenancy?.rent_amount ? 'increase' : 'decrease';

  const difference =
    tenancy?.rent_amount > formData.amount
      ? tenancy?.rent_amount - formData.amount
      : formData.amount - tenancy?.rent_amount;

  const adjustmentString =
    adjustmentType === 'decrease'
      ? `a ${adjustmentType}`
      : `an ${adjustmentType}`;

  const date = formData?.from_date && formatDateLong(formData?.from_date);

  const handleSubmit = (values) =>
    handleSubmitProp({
      ...formData,
      ...values,
      tenancyId: tenancy.id
    });

  const isWithinPaidPeriod = (paidToDate?: string, fromDate?: string) =>
    paidToDate && fromDate && dayjs(fromDate) > dayjs(paidToDate);

  const validation = {
    definitions: {
      read_warning: { rules: 'required' }
    }
  };

  return (
    <ReactForms<SummaryForm, void>
      /**
       * Discuss use of hidden field as an alternative here with Julian.
       */
      handleSubmit={handleSubmit}
      initialValues={
        isEmpty(accumulatedStepState)
          ? initialValues
          : accumulatedStepState.summaryFormData
      }
      validate={validation}
    >
      {({ submitForm, isSubmitting }) => (
        <Form style={{ width: '100%' }}>
          <Heading level={3}>
            You are about to {adjustmentType} the rent of this tenancy
          </Heading>

          <Body>
            Applying this rent adjustment will permanently change the amount due
            each rental period to <b>{formatCurrency(formData?.amount)}</b>,{' '}
            {adjustmentString} of <b>{formatCurrency(difference)}</b>.{' '}
            {date && (
              <>
                This will apply to all unpaid periods commencing from{' '}
                <b>{date}</b>.
              </>
            )}
          </Body>
          <Body>Confirm you would like to make this adjustment.</Body>

          {/**
           * TODO: [AL-476] When rent amount changes, calculate changes to effective
           */}
          {isWithinPaidPeriod(tenancy?.paid_to_date, formData?.from_date) && (
            <Grid>
              <Column width={12}>
                <hr />
              </Column>
              <Column width={2}>
                <SupportIcon />
              </Column>
              <Column width={10}>
                <Body>
                  You have selected to adjust this tenancy’s rent amount within
                  a paid period. This may result in a change to the tenancy’s
                  paid to date
                </Body>
              </Column>
              <Column width={2}>
                <Field name='read_warning' Input={Checkbox} />
              </Column>
              <Column width={10}>
                <Label>I understand and wish to continue</Label>
              </Column>
            </Grid>
          )}

          <Box
            mt={token('spacing.m')}
            flexDirection='row'
            justifyContent='space-between'
            alignItems='center'
          >
            <GhostButton onClick={onClose}>Cancel</GhostButton>
            <ButtonGroup>
              <SecondaryButton onClick={prevStep}>Back</SecondaryButton>
              <PrimaryButton
                isLoading={isSubmitting}
                onClick={() => {
                  submitForm();
                }}
              >
                Yes, confirm rent {adjustmentType}
              </PrimaryButton>
            </ButtonGroup>
          </Box>
        </Form>
      )}
    </ReactForms>
  );
};
