import { useDialog } from '@rexlabs/dialog';
import { ActionDeclaration } from 'src/modules/common/actions/types/action-declaration-types';
import { MessageWithReasonDialog } from '../../../common/dialogs/message-with-reason-dialog';
import { QuoteTask } from '../../types/QuoteTask';
import { useGetMessageWithReasonSubmitHandler } from '../../../common/hooks/use-get-message-with-reason-submit-handler';

export function useGetSendForApprovalAction() {
  const { open: openMessageWithReasonDialog } = useDialog(
    MessageWithReasonDialog
  );

  const getUpdateQuoteTaskSubmitHandler = useGetMessageWithReasonSubmitHandler();

  return (task: QuoteTask): ActionDeclaration => {
    const handleSubmit = getUpdateQuoteTaskSubmitHandler(
      task,
      'sent_for_approval'
    );

    return {
      id: 'send-for-approval',
      group: 'status',
      label: 'Send for approval',
      intent: 'primary',
      handleAction: async () => {
        openMessageWithReasonDialog({
          title: 'Send quotes for approval',
          submitLabel: 'Send quotes',
          handleSubmit,
          messageContextType: 'task_quote'
        });
      }
    };
  };
}
