import React, { useMemo } from 'react';

import { TitleBlock } from 'view/components/cards/title/title-block';

import { RecordObject, RecordType } from 'data/models/types';

import { getRecordTitle } from 'utils/records/get-record-title';
import { TrustLedger } from 'src/modules/trust-ledgers/types/trust-ledger-types';
import { useTranslation } from 'react-i18next';
import { useGetTrustLedgerActions } from '../utils/use-get-trust-ledger-actions';

const type: RecordType = 'trust_ledger';

type TrustLedgerTitleBlockProps = {
  trustLedger: TrustLedger;
};

export function TrustLedgerTitleBlock({
  trustLedger
}: TrustLedgerTitleBlockProps) {
  const { t } = useTranslation();
  const getTrustLedgerActions = useGetTrustLedgerActions();

  const title = getRecordTitle({ type, object: trustLedger } as RecordObject);
  const actions = useMemo(() => getTrustLedgerActions(trustLedger), [
    trustLedger
  ]);

  return (
    <TitleBlock
      title={title}
      type={t('financials.trust.trust-ledgers.label.singular') as string}
      actions={actions}
    />
  );
}
