import Box from '@rexlabs/box';
import { Field } from '@rexlabs/form';
import { RadioGroupInput } from '@rexlabs/radio-input';
import { StyleSheet, useStyles } from '@rexlabs/styling';
import { Body, Bold } from '@rexlabs/text';
import { PendingContactDisbursement } from 'data/models/entities/financials/pending-disbursements/pending-contact-disbursement';
import { RecordType, RecordTypes } from 'data/models/types';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import {
  getRecordTypeNamePluralised,
  PLURALISE
} from 'utils/records/get-record-type-name';
import { WarningBanner } from 'view/components/@luna/notifications/banner';
import { BlockConfig } from 'view/components/record-screen/types';

const defaultStyles = StyleSheet({
  wrapper: {
    paddingTop: ({ token }) => token('spacing.xl'),
    paddingBottom: ({ token }) => token('spacing.xl')
  }
});

export enum DisbursementOptions {
  paid_only = 'paid_only',
  all = 'all'
}

export interface DisbursementBlockData {
  recordType: RecordType;
  contactsWithBills: PendingContactDisbursement[];
  contactsWithoutBills: PendingContactDisbursement[];
}

export const disbursementDialogBlock: BlockConfig<DisbursementBlockData> = {
  id: 'contact-disbursement',
  Edit: ({ data }) => {
    const { t } = useTranslation();
    // specify default because it is technically possible for data to be undefined
    const recordType = data?.recordType || RecordTypes.Contact;
    const countWithBills = data?.contactsWithBills.length || 0;
    const countWithoutBills = data?.contactsWithoutBills.length || 0;

    const s = useStyles(defaultStyles);

    return (
      <Box>
        <WarningBanner
          description={
            <Body>
              <Trans
                i18nKey='disbursements.blocks.disbursement-dialog.warning-banner'
                values={{
                  count: countWithBills,
                  recordType: getRecordTypeNamePluralised(
                    recordType,
                    countWithBills
                  )
                }}
              >
                <Bold>
                  {`${countWithBills} `}
                  {getRecordTypeNamePluralised(recordType, countWithBills)}
                </Bold>{' '}
                has bills &amp; fees yet to be paid. If you continue to
                disburse, you may not have adequate funds in trust to pay the
                amount owed.
              </Trans>
            </Body>
          }
        />
        <Box {...s('wrapper')}>
          <Field
            label={t('disbursements.options.label') as string}
            name='disbursement_options'
            Input={RadioGroupInput}
            inputProps={{
              options: [
                {
                  label: t(
                    'disbursements.only-disburse-bills-paid-toggle.label',
                    {
                      count: countWithoutBills,
                      total: countWithBills + countWithoutBills,
                      recordType: getRecordTypeNamePluralised(
                        recordType,
                        PLURALISE
                      )
                    }
                  ),
                  value: DisbursementOptions.paid_only
                },
                {
                  label: t('disbursements.disburse-all-toggle.label', {
                    recordType: getRecordTypeNamePluralised(
                      recordType,
                      PLURALISE
                    )
                  }),
                  value: DisbursementOptions.all
                }
              ],
              orientation: 'vertical'
            }}
          />
        </Box>
        <Body>{t('disbursements.blocks.individual-bills-paid.message')}</Body>
      </Box>
    );
  },
  validate: {
    definitions: {
      disbursement_options: { rules: 'required' }
    }
  }
};
