import Box from '@rexlabs/box';
import { Body, Bold } from '@rexlabs/text';
import { RecordType } from 'data/models/types';
import React from 'react';
import { formatCurrency } from 'utils/formatters';
import { BlockConfig } from 'view/components/record-screen/types';
import { PendingOwnershipDisbursement } from 'data/models/entities/financials/pending-disbursements/pending-ownership-disbursement';
import { PendingContactDisbursement } from 'data/models/entities/financials/pending-disbursements/pending-contact-disbursement';
import { Trans } from 'react-i18next';

export interface IndividualWithoutBillsBlockData {
  recordType: RecordType;
  pendingDisbursement:
    | PendingOwnershipDisbursement
    | PendingContactDisbursement;
}

export const disbursementIndividualBillsPaidDialogBlock: BlockConfig<IndividualWithoutBillsBlockData> = {
  id: 'individual-disbursement',
  Edit: ({ data }) => {
    // need a default because it could be falsey
    const centsToDisburse =
      data?.pendingDisbursement?.disburse_summary
        ?.expected_disbursement_amount || 0;

    return (
      <Box>
        <Body>
          <Trans
            i18nKey='disbursements.blocks.individual-bills-paid.please-confirm'
            values={{ amount: formatCurrency(centsToDisburse) }}
          >
            Please confirm you wish to disburse a total of
            <Bold>{formatCurrency(centsToDisburse)}</Bold>
          </Trans>
        </Body>
        <Body>
          <Trans i18nKey='disbursements.blocks.individual-bills-paid.message'>
            Once you have disbursed these funds, new journal entries will be
            created for each disbursement, which you will need to add to a bank
            withdrawals to physically pay the supplier the payout amount.
          </Trans>
        </Body>
      </Box>
    );
  }
};
