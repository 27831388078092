import { ActionMenuItem } from '@rexlabs/action-menu';
import { DestructiveButton } from '@rexlabs/button';
import { useStatusChangeAction } from 'src/modules/common/actions/status/use-status-change-action';
import { transformActionDeclarationsToActionMenuItems } from 'utils/actions/transforms';
import { channelMessageRecipientsModel } from '../../models/channel-message-recipients-model';
import {
  ChannelMessageRecipient,
  ChannelMessageRecipientStatus
} from '../../types/ChannelMessageRecipient';
import { useGetResendMessageAction } from '../action-declarations/use-get-resend-message-action';
import { useGetUpdateContactAndResendMessageAction } from '../action-declarations/use-get-update-contact-and-resend-message-action';
import {
  getViewMessageActionArgs,
  useGetViewMessageAction
} from '../action-declarations/use-get-view-message-action';

export function useGetMessageBouncedActions() {
  const getViewMessageAction = useGetViewMessageAction();
  const getUpdateContactAndResendMessageAction = useGetUpdateContactAndResendMessageAction();
  const getResendMessageAction = useGetResendMessageAction();
  const getArchiveMessageAction = useStatusChangeAction(
    channelMessageRecipientsModel
  );

  return (
    channelMessageRecipient?: ChannelMessageRecipient
  ): ActionMenuItem[] => {
    if (!channelMessageRecipient) return [];

    const archiveMessageAction = getArchiveMessageAction<ChannelMessageRecipientStatus>(
      {
        record: channelMessageRecipient,
        status: 'archived',
        dialogOptions: {
          label: 'Archive message',
          SubmitButton: DestructiveButton,
          submitLabel: 'Archive message'
        },
        generalOptions: { avoidItemRefresh: true }
      }
    );

    return [
      ...transformActionDeclarationsToActionMenuItems([
        getViewMessageAction(getViewMessageActionArgs(channelMessageRecipient)),
        ...(channelMessageRecipient.failure_status?.id === 'invalid_email'
          ? [
              getUpdateContactAndResendMessageAction(
                channelMessageRecipient,
                'email'
              )
            ]
          : []),
        ...(channelMessageRecipient.failure_status?.id === 'soft_bounce'
          ? [getResendMessageAction(channelMessageRecipient)]
          : []),
        { ...archiveMessageAction, intent: 'danger' }
      ])
    ];
  };
}
