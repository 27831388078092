import { Generator, GeneratorConfig } from 'data/models/generator';
import { api } from 'utils/api/api-client';
import { BankDeposit } from 'src/modules/banking/bank-deposits/types/bank-deposit-types';
import { bankDepositConfig } from 'src/modules/banking/bank-deposits/models/bank-deposit-model';

const config: GeneratorConfig = {
  ...bankDepositConfig,
  entities: {
    ...bankDepositConfig.entities!,
    api: {
      // 2nd argument is typed as AxiosRequestConfig, which is probably the wrong type
      // i dont know what the correct type should be right now
      // this seems to work so will leave this as is
      //@ts-ignore
      fetchList: (type, { reconciliationId, ...args }) =>
        api.get(
          `/financials/reconciliations/${reconciliationId}/bank-deposits`,
          args
        )
    }
  }
};

export const financialsReconciliationsBankDepositsModel = new Generator<BankDeposit>(
  'financials/reconciliations/bank-deposits',
  config
).createEntityModel();
