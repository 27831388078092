import { Quri } from '@rexlabs/quri';

import { useRecordsQuery } from 'src/lib/react-query/hooks/use-records-query';

interface SettingsItem {
  id: string;
  label: string;
}

export function useSettingsListQuery<T extends SettingsItem>(
  settingType: string,
  filter: Quri[] = [],
  perPage = 30
) {
  return useRecordsQuery<T>([
    `/settings/${settingType}`,
    {
      filter,
      perPage
    }
  ]);
}
