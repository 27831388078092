import { Generator, GeneratorConfig } from 'data/models/generator';
import { BaseModelGeneratorModel } from '@rexlabs/model-generator';
import { TimestampAttributes, ValueListValue } from 'data/models/types';
import { SecurityDeposit } from './security-deposits';
import { financialsInvoicesModel, Invoice } from './invoices';

export type SecurityDepositRequestType = 'deposit' | 'deposit_return';

export type SecurityDepositRequest = BaseModelGeneratorModel &
  TimestampAttributes & {
    type: ValueListValue<SecurityDepositRequestType>;
    amount: number;
    is_top_up: boolean;
    security_deposit: SecurityDeposit;
    description: string;
    lodgement_reference: string;
    payment_reference: string;
    invoice?: Invoice;
  };

const config: GeneratorConfig = {
  entities: {
    related: {
      invoice: {
        include: 'invoice',
        model: financialsInvoicesModel
      }
    }
  }
};

export const financialsSecurityDepositRequestsModel = new Generator<SecurityDepositRequest>(
  'financials/security-deposit-requests',
  config
).createEntityModel();
