import { CreditNote } from 'data/models/entities/financials/credit-notes';
import { Invoice } from 'data/models/entities/financials/invoices';
import React from 'react';
import { TrustJournalEntryLineItem } from 'src/modules/trust-journal-entry-line-items/types/trust-journal-entry-line-item-type';
import { RecordLink } from 'view/components/record-link/record-link';
import { AccountingJournalEntry } from '../types/account-journal-entry-types';
import { getAccountingJournalEntrySourceLabel } from '../utils/get-accounting-journal-entry-source-label';

export function AccountingJournalEntrySourceCell({
  accountingJournalEntry
}: {
  accountingJournalEntry: AccountingJournalEntry;
}) {
  const label = getAccountingJournalEntrySourceLabel(accountingJournalEntry);

  if (accountingJournalEntry?.type.id === 'manual') {
    return <div>{label}</div>;
  }

  const handleClick = (e) => {
    e.stopPropagation();
  };

  const id =
    accountingJournalEntry.source_object_type ===
    'trust_journal_entry_line_item'
      ? (accountingJournalEntry.source_object as TrustJournalEntryLineItem)
          .trust_journal_entry?.id
      : (accountingJournalEntry.source_object as Invoice | CreditNote).id;

  const type =
    accountingJournalEntry.source_object_type ===
    'trust_journal_entry_line_item'
      ? 'trust_journal_entry'
      : accountingJournalEntry.source_object_type;

  return (
    <RecordLink onClick={handleClick} type={type} id={id}>
      {label}
    </RecordLink>
  );
}
