import { useConfirmationDialog } from '@rexlabs/dialog';
import { Bold } from '@rexlabs/text';
import { Ownership } from 'data/models/entities/ownerships';
import { RecordTypes } from 'data/models/types';
import React from 'react';
import { Statement, StatementType } from 'src/modules/statements/types';
import { BannerAction } from 'view/components/@luna/notifications/banner/core';
import { CreateStatementsArgs } from 'src/modules/statements/hooks/use-create-statements';
import { IssueExistingStatementArgs } from 'src/modules/statements/hooks/use-issue-single-statement-action';

interface IssueNewStatementConfirmationDialogProps {
  statementType: StatementType;
  selectedItems: Ownership[];
  statementEndDate: string;
  handleConfirmation: (args: CreateStatementsArgs) => void;
  toastAction?: BannerAction;
  statementStartDate?: string;
  recordType: typeof RecordTypes.Ownership;
}

interface IssueExistingStatementConfirmationDialogProps {
  selectedItems: Statement[];
  handleConfirmation: (args: IssueExistingStatementArgs) => void;
  toastAction?: BannerAction;
  statementType: StatementType;
  recordType: typeof RecordTypes.Statement;
}

export type IssueStatementConfirmationDialogProps =
  | IssueNewStatementConfirmationDialogProps
  | IssueExistingStatementConfirmationDialogProps;

// For correct grammar, depending on the statement type, we need different articles
const getArticle = (statementType: StatementType) => {
  switch (statementType) {
    case StatementType.YearlyOwnership:
      return 'an';
    default:
      return 'a';
  }
};

export function useIssueStatementConfirmationDialog() {
  const { open: openConfirmationDialog } = useConfirmationDialog();

  return (args: IssueStatementConfirmationDialogProps) => {
    if (args.selectedItems.length === 0) {
      throw new Error('Cannot issue statements without selected items');
    }

    const statementTypeFriendly =
      args.statementType === StatementType.PeriodicOwnership
        ? 'Periodic'
        : 'End of Financial Year';

    const issuingMultipleStatements = args.selectedItems.length > 1;
    const singleDisplayName =
      args.recordType === RecordTypes.Statement
        ? args.selectedItems[0]?.object?.display_name
        : args.selectedItems[0]?.display_name;

    // this will be "a" or "an" depending on the statement type
    const article = getArticle(args.statementType);

    openConfirmationDialog({
      title: `Issue ${statementTypeFriendly} ownership statement${
        issuingMultipleStatements ? 's' : ''
      }`,
      message: (
        <div data-testid='ownership-statement-confirmation-message'>
          {`Please confirm you want to issue ${article} ${statementTypeFriendly} statement to `}
          {
            <Bold>
              {issuingMultipleStatements ? (
                `${args.selectedItems.length} ownerships`
              ) : (
                <span data-testid='ownership-statement-confirmation-owner'>
                  {singleDisplayName}
                </span>
              )}
              .
            </Bold>
          }
        </div>
      ),
      confirmText: `Issue statement${issuingMultipleStatements ? 's' : ''}`,
      onConfirm: () => {
        if (args.recordType === RecordTypes.Ownership) {
          return args.handleConfirmation({
            selectedItems: args.selectedItems,
            statementType: args.statementType,
            statementStartDate: args.statementStartDate!,
            statementEndDate: args.statementEndDate,
            recordType: args.recordType
          });
        }
        if (args.recordType === RecordTypes.Statement) {
          return args.handleConfirmation({
            selectedItems: args.selectedItems,
            recordType: args.recordType
          });
        }
      }
    });
  };
}
