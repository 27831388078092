import { ActionDeclaration } from 'src/modules/common/actions/types/action-declaration-types';
import { ChannelMessageRecipient } from '../../types/ChannelMessageRecipient';
import { useGetEditSmsAndResendAction } from './use-get-edit-sms-and-resend-action';
import { useGetEditEmailAndResendAction } from './use-get-edit-email-and-resend-action';

export function useGetEditMessageAndResendAction() {
  const getEditSmsAndResendAction = useGetEditSmsAndResendAction();
  const getEditEmailAndResendAction = useGetEditEmailAndResendAction();

  return (
    channelMessageRecipient: ChannelMessageRecipient
  ): ActionDeclaration => {
    const channelTypeId = channelMessageRecipient.channel_message!.channel_type
      .id;

    return channelTypeId === 'sms'
      ? getEditSmsAndResendAction(channelMessageRecipient)
      : getEditEmailAndResendAction(channelMessageRecipient);
  };
}
