import React from 'react';
import { StyleSheet, useStyles } from '@rexlabs/styling';
import { Tag } from '@rexlabs/tag';

type Intent =
  | 'neutral'
  | 'secondary'
  | 'success'
  | 'danger'
  | 'warning'
  | 'information';

type ColoredTagProps = React.ComponentProps<typeof Tag> & {
  intent: Intent;
};

const defaultStyles = StyleSheet({
  tagNeutral: {
    background: ({ token }) => token('color.container.static.neutral.contrast')
  },
  tagSecondary: {
    background: ({ token }) =>
      token('color.container.static.secondary.contrast')
  },

  tagSuccess: {
    background: ({ token }) => token('color.container.static.success.contrast')
  },

  tagDanger: {
    background: ({ token }) => token('color.container.static.danger.contrast')
  },

  tagWarning: {
    background: ({ token }) => token('color.container.static.warning.contrast')
  },
  tagInformation: {
    background: ({ token }) =>
      token('color.container.static.information.contrast')
  }
});

/**
 * This component is a wrapper of the Vivid tag component. You can pass in the intent of the
 * tag, and it will return a tag with the background color set to the contrast of the intent color.
 * @param {string} intent - The intent of the tag which is used to determine the color of it
 * @returns {JSX.Element} - A colored tag
 */
export function ColoredTag({
  intent,
  style,
  className,
  ...rest
}: ColoredTagProps) {
  const s = useStyles(defaultStyles);

  return (
    <Tag
      {...s.with({
        tagNeutral: intent === 'neutral',
        tagSecondary: intent == 'secondary',
        tagSuccess: intent === 'success',
        tagDanger: intent === 'danger',
        tagWarning: intent === 'warning',
        tagInformation: intent === 'information'
      })({ style, className })}
      {...rest}
    />
  );
}
