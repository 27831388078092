import { CellProps } from '@rexlabs/table';
import { Truncate } from '@rexlabs/text';
import React from 'react';

/*context on this cell value - as per the info tooltip for this column:
      For area types marked ‘If specified when creating the property’,
      the number of instances will be determined by the number of
      ‘Bedrooms’, ‘Ensuites’, and ‘Bathrooms’ specified in the create
      property dialog.

      The is_created_on_new_properties value is therefore irrelevant for these types.
*/
export function IncludedForNewPropertiesCell({ row }: CellProps) {
  if (row.original.is_property_details_type) {
    return <Truncate>If specified when creating the property</Truncate>;
  }

  return (
    <Truncate>
      {row.original.is_created_on_new_properties ? 'Yes' : 'No'}
    </Truncate>
  );
}
