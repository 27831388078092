/*
|-------------------------------------------------------------------------------
| Global Util Setup
|-------------------------------------------------------------------------------
|
| - Log app configuration at start
| - Setup Promises with better DX, using blubird
|
*/

import config from 'config';

if (!global) {
  // eslint-disable-next-line
  global = global || window;
}

if (global) {
  global.__DEV__ =
    typeof global.__DEV__ !== 'undefined'
      ? global.__DEV__
      : process.env.NODE_ENV !== 'production';
}

if (__DEV__) {
  // eslint-disable-next-line
  console.log(
    '\nDevelopment Environment:\n\n\t__DEV__ = true\n' +
      Object.keys(config)
        .map((key) => `\t${key} = ${config[key]}`)
        .join('\n')
        .trim()
  );
}
