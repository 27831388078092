import React from 'react';
import { toQuri } from '@rexlabs/quri';
import { query, useEntityListQuery } from '@rexlabs/model-generator';
import { FinancialRecord } from 'utils/financials/financial-objects';
import { BankAccount } from 'data/models/entities/financials/bank-accounts';
import { trustLedgerModel } from 'src/modules/trust-ledgers/models/trust-ledger-model';
import { TrustLedger } from 'src/modules/trust-ledgers/types/trust-ledger-types';

export const useGetTrustLedgerForFolioAndBankAccount = (
  object: FinancialRecord,
  bankAccount: BankAccount | null
): { trustLedger: TrustLedger | null; isLoading: boolean } => {
  const filter = React.useMemo(() => {
    if (bankAccount) {
      return toQuri([
        {
          field: 'bank_account_id',
          op: '==',
          value: bankAccount.id
        },
        {
          field: 'object_id',
          op: '==',
          value: object.id
        }
      ]);
    }

    return null;
  }, [bankAccount]);

  const trustLedgerQuery = React.useMemo(() => {
    if (filter) {
      return query`{
        ${trustLedgerModel} (q: ${filter}){
          id
        }
      }`;
    }
    return null;
  }, [filter]);

  const { data, status } = useEntityListQuery(trustLedgerQuery) as {
    data: TrustLedger | undefined;
    status: string;
  };

  return {
    trustLedger: data ? data[0] : null,
    isLoading: status === 'loading'
  };
};
