import React from 'react';

import { Field } from '@rexlabs/form';
import Box from '@rexlabs/box';

import { BlockConfig } from 'view/components/record-screen/types';
import { Grid } from 'view/components/@luna/form/grid';
import { ImagePreview } from 'view/components/image-preview';
import { EmptyState } from 'view/components/states/compact/empty';
import { PreviewFileUploadInputWithErrorHandling } from 'view/components/inputs/file-upload-input/preview-file-upload-input-with-error-handling';

import { QuoteTask } from '../types/QuoteTask';
import { CreateQuoteFormDetailsData } from '../types/CreateQuoteFormData';

export type relatedImagesForm = {
  details: Pick<CreateQuoteFormDetailsData, 'images'>;
};

export const relatedImagesBlock: BlockConfig<
  QuoteTask,
  any,
  relatedImagesForm
> = {
  id: 'related-images',
  title: 'Related image(s)',
  View: ({ data }) => {
    const hasNoAttachments =
      !Array.isArray(data?.details?.images) ||
      data?.details?.images?.length === 0;

    if (hasNoAttachments) {
      return <EmptyState />;
    }

    return (
      <>
        <Box spacing='.8rem' flexDirection='row' flexWrap='wrap'>
          {data!.details!.images!.map((image, index) => {
            return (
              <ImagePreview
                src={image?.file.url}
                key={image?.file.id || index}
                downloadUrl={image?.file.download_url}
              />
            );
          })}
        </Box>
      </>
    );
  },
  Edit: () => {
    return (
      <Grid columns={1}>
        <Grid columns={1}>
          <Field<typeof PreviewFileUploadInputWithErrorHandling>
            id='details.images'
            name='details.images'
            label=''
            optionalText=''
            Input={PreviewFileUploadInputWithErrorHandling}
            inputProps={{
              multi: true,
              accept: '.jpeg, .png, .jpg'
            }}
          />
        </Grid>
      </Grid>
    );
  }
};
