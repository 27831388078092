import {
  FreeformDialogProps,
  NormalisedItem,
  RecordOption,
  Select,
  SelectProps,
  useFreeformDialog,
  UseFreeformDialogArgs,
  UseItemsArgs
} from '@rexlabs/select';
import * as React from 'react';
import { Appliance } from 'src/modules/appliances/types/Appliance';
import { CreateApplianceRecordDialog } from 'src/modules/appliances/dialogs/create-appliance-record-dialog';
import { api } from 'utils/api/api-client';
import { Property } from 'src/modules/properties/types/property-types';
import { useQuery } from 'react-query';

interface FreeformApplianceDialogProps extends FreeformDialogProps<Appliance> {
  property: Property;
  onCreate?: (data: Appliance) => void;
}

function FreeformApplianceDialog({
  selectItem,
  inputValue,
  property,
  onCreate: propOnCreate,
  ...props
}: FreeformApplianceDialogProps) {
  const onCreate = React.useCallback(
    (data) => {
      propOnCreate?.(data);
      selectItem(data);
    },
    [propOnCreate, selectItem]
  );

  return (
    <CreateApplianceRecordDialog
      {...props}
      initialValues={{
        type: inputValue,
        property,
        manuals: [],
        warranties: []
      }}
      onCreate={onCreate}
    />
  );
}

const getItemsOuter = async (propertyId: string) => {
  const { data } = await api.get('/appliances', {
    per_page: 50,
    q: `property_id.eq(${propertyId})`
  });
  const appliances = data as Appliance[];

  return appliances;
};

const applianceNormaliser = (item: Appliance): NormalisedItem => {
  return {
    id: item.id,
    label: [item.type, item.make, item.model].filter(Boolean).join(' • '),
    description: [item.make, item.model].filter(Boolean).join(' • ')
  };
};

export type ApplianceSelectProps = SelectProps<Appliance> &
  Pick<UseItemsArgs<Appliance>, 'getSuggestedItems'> & {
    dialogProps?: UseFreeformDialogArgs<Appliance>['props'];
    disableFixture?: boolean;
  } & {
    property: Property;
  };

export function ApplianceSelect({
  dialogProps,
  property,
  ...props
}: ApplianceSelectProps) {
  const itemQuery = useQuery(['appliances', property.id], () =>
    getItemsOuter(property.id)
  );

  const {
    getSelectProps: useFreeformDialogGetSelectProps
  } = useFreeformDialog<Appliance>({
    props: dialogProps,
    Dialog: (props) => (
      <FreeformApplianceDialog {...props} property={property} />
    )
  });

  return (
    <Select
      deselectable={true}
      normaliser={applianceNormaliser}
      {...props}
      {...useFreeformDialogGetSelectProps()}
      Option={(props) => (
        <RecordOption
          {...props}
          // The selected item value (label) contains all details, but for the menu option
          // we show the label on one line, and the other details in the description below
          // so in this case we need to remove the extra details from the label
          item={{
            ...props.item,
            label: props.item.label.split(' • ')[0] || ''
          }}
        />
      )}
      items={itemQuery.data || []}
    />
  );
}
