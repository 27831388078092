import Box from '@rexlabs/box';
import { StyleSheet, useStyles } from '@rexlabs/styling';
import React from 'react';

import { InfoBanner } from 'view/components/@luna/notifications/banner';
import { default as InfoIcon } from 'view/components/icons/info';

const styles = StyleSheet({
  list: {
    listStyle: 'disc',
    listStylePosition: 'inside'
  }
});

export function HelpBanner() {
  const s = useStyles(styles);

  return (
    <InfoBanner
      Icon={InfoIcon}
      description={
        <Box flexDirection='column' gap={12}>
          <div>
            Uploading your transaction file can sometimes be a bit tricky, but
            we&#39;re here to guide you through it! Here are helpful articles to
            assist you:
          </div>

          <ul {...s('list')}>
            <li>
              <a
                target='_blank'
                rel='noreferrer'
                href='https://rexpm.zendesk.com/hc/en-au/articles/15419609793945-Batch-Receipting-'
              >
                Batch receipting guide
              </a>
            </li>
          </ul>

          <div>
            If you still need assistance, don&#39;t hesitate to reach out to our
            support team. We&#39;re here to make this process a breeze for you!
            🚀{' '}
            <a href='mailto:support@rexpm.zendesk.com'>
              support@rexpm.zendesk.com
            </a>
          </div>
        </Box>
      }
    />
  );
}
