import React, { useCallback } from 'react';
import { RecordDialog } from 'view/components/record-screen/dialog/dialog';
import { DialogContentConfig } from 'view/components/record-screen/dialog/types';
import { useToast } from 'view/components/@luna/notifications/toast';
import { RecordSubmitHandler } from 'view/components/record-screen/utils';
import { useModelActions } from '@rexlabs/model-generator';
import {
  PortfolioMember,
  portfolioMembersModel
} from '../models/portfolio-members-model';
import { Portfolio, portfoliosModel } from '../models/portfolios-model';
import { removeAndSetNewPrimaryMemberBlock } from '../blocks/remove-and-set-new-primary-member';

const content: DialogContentConfig = [
  {
    id: 'remove-and-set-new-primary-property-manager',
    label: 'Remove and set new primary member',
    blocks: [removeAndSetNewPrimaryMemberBlock]
  }
];

export interface RemoveAndSetNewPrimaryMemberConfirmationDialogProps {
  onClose?: () => void;
  portfolioMember: PortfolioMember;
  portfolio: Portfolio;
}

export function RemoveAndSetNewPrimaryMemberConfirmationDialog({
  portfolioMember,
  portfolio,
  onClose
}: RemoveAndSetNewPrimaryMemberConfirmationDialogProps) {
  const { addToast } = useToast();
  const { refreshItem: refreshPortfolio } = useModelActions(portfoliosModel);
  const { trashItem, updateItem } = useModelActions(portfolioMembersModel);

  const handleSubmit: RecordSubmitHandler = useCallback(
    async ({ values }) => {
      await updateItem({
        id: values.user.id,
        data: {
          is_primary: true,
          portfolio: { id: portfolio.id }
        }
      });

      await trashItem({ id: portfolioMember.id });

      addToast({
        type: 'success',
        description: 'Member successfully removed'
      });

      await refreshPortfolio({ id: portfolio.id });

      return true;
    },
    [
      addToast,
      portfolio.id,
      portfolioMember.id,
      refreshPortfolio,
      trashItem,
      updateItem
    ]
  );

  return (
    <RecordDialog
      onClose={onClose}
      blockProps={{
        portfolioMember,
        portfolio
      }}
      title={'Remove member'}
      handleSubmit={handleSubmit}
      content={content}
      submitLabel={'Continue'}
      size='s'
    />
  );
}
