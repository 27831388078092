import { OutlineButton } from '@rexlabs/button';
import { useErrorDialog } from '@rexlabs/dialog';
import { useModelActions } from '@rexlabs/model-generator';
import { push } from '@rexlabs/whereabouts';
import {
  financialsInvoicesModel,
  Invoice
} from 'data/models/entities/financials/invoices';
import React, { useCallback } from 'react';
import ROUTES from 'routes/app';
import { BILL_PROCESSING_FLAGS } from 'src/modules/bill-processing/feature-flags';
import { financialsUploadedBillsModel } from 'src/modules/bill-processing/models/uploaded-bills';
import {
  postRequestPipelineRequests,
  RequestPipelineRequestWithId
} from 'utils/api/post-request-pipeline';
import { getToday } from 'utils/dates/dates';
import { useFeatureFlags } from 'view/components/@luna/feature-flags';
import { useToast } from 'view/components/@luna/notifications/toast';
import { CreateRecordDialog } from 'view/components/dialogs/create-record-dialog/create-record-dialog';
import { RecordDialog } from 'view/components/record-screen/dialog/dialog';
import { RecordSubmitHandler } from 'view/components/record-screen/utils';
import { invoiceViewerBlock } from '../blocks/invoice-viewer';
import { invoiceIncludes } from '../data/queries';
import { mapReimbursementInvoiceValuesToPostData } from '../utils/map-invoice-form-values-to-post-data';
import { reimbursementDetailsBlock } from '../blocks/reimbursement-details';
import { reimbursementLineItemsBlock } from '../blocks/reimbursement-line-items';

interface CreateReimbursementInvoiceDialogProps {
  reimbursedInvoice: Invoice;
  onClose?: () => void;
}

const fauxMarginBlock = {
  Edit: () => <div style={{ marginTop: '4.8rem' }} />
};

export function CreateReimbursementInvoiceDialog({
  reimbursedInvoice,
  onClose
}: CreateReimbursementInvoiceDialogProps) {
  const { addToast } = useToast();
  const { createItem, refreshLists, refreshItem } = useModelActions(
    financialsInvoicesModel
  );

  const { hasFeature } = useFeatureFlags();

  const hasBillProcessingFeature = hasFeature(
    BILL_PROCESSING_FLAGS.BILL_PROCESSING
  );

  const { open: openErrorDialog } = useErrorDialog();
  const { importBills } = useModelActions(financialsUploadedBillsModel);

  const activeTab = 'reimbursement';

  const content = [
    {
      id: 'basics',
      label: 'Basics',
      blocks: [
        fauxMarginBlock,
        reimbursementDetailsBlock,
        reimbursementLineItemsBlock
      ],
      ...(hasBillProcessingFeature ? { leftBlocks: [invoiceViewerBlock] } : {})
    }
  ];

  const handleSubmit: RecordSubmitHandler = useCallback(
    async ({ values }) => {
      // If there is an uploaded_bill, create a bill to be processed
      // and set its file uuid as the value for 'uploaded_bill' for the create invoice request
      const uploaded_bill = values.uploaded_bill;

      if (uploaded_bill) {
        if (!uploaded_bill?.data) return;
        const { data: bill } = await Promise.resolve(uploaded_bill?.data);
        try {
          const uploadedBill = await importBills({
            fileIds: [bill.id]
          });
          await refreshLists();
          values.uploaded_bill = { id: uploadedBill.data[0].id };
        } catch (error) {
          openErrorDialog(error);
          return;
        }
      }

      const requests: RequestPipelineRequestWithId[] = [
        {
          id: 'reimbursement_invoice',
          path: '/api/v1/financials/invoices',
          method: 'POST',
          json: mapReimbursementInvoiceValuesToPostData({
            ...values,
            uploaded_bill: values.uploaded_bill,
            reimbursement_for_invoice: {
              id: reimbursedInvoice.id
            }
          })
        }
      ];

      const response = await postRequestPipelineRequests<
        typeof requests,
        Invoice
      >(requests);

      await refreshItem({
        id: reimbursedInvoice.id,
        args: {
          include: invoiceIncludes
        }
      });

      addToast({
        description: `You have successfully created the reimbursement invoice #${response?.data?.reimbursement_invoice.record_reference}`,
        actions: [
          {
            label: 'View',
            Button: OutlineButton,
            onClick: () =>
              push(ROUTES.INVOICE, {
                params: {
                  invoiceId: response.data.reimbursement_invoice.id
                }
              })
          }
        ]
      });

      return response.data.reimbursement_invoice;
    },
    [addToast, createItem, refreshLists]
  );

  const description = `Reimbursement for ${reimbursedInvoice.description}`;

  const initialValues = {
    reimbursed_is_tax_included: true,
    invoice_date: getToday(),
    invoice_tax_preview: {
      amount_inc_tax: reimbursedInvoice.amount_total_inc_tax
    },
    reimbursed_invoice_date: getToday(),
    reimbursed_bank_account: reimbursedInvoice.bank_account,
    reimbursed_description: description,
    reimbursed_to: reimbursedInvoice.payable_by,
    reimbursed_specific_disbursement_payment_method:
      'use_disbursement_preferences',
    reimbursed_bill_priority: reimbursedInvoice.bill_priority,
    reimbursed_bill_reference: reimbursedInvoice.bill_reference,
    reimbursed_line_items: [
      {
        description
      }
    ]
  };

  const title = 'Create reimbursement invoice';

  return hasBillProcessingFeature ? (
    <CreateRecordDialog
      recordType='invoice'
      onClose={onClose}
      title={title}
      data={initialValues}
      content={content}
      size={'xl'}
      blockProps={{
        hasBillProcessingFeature,
        activeTab,
        isReimbursementEmptyStateVisible: false,
        isInvoiceDialog: false
      }}
      onSubmit={handleSubmit}
      buttonGroupProps={{ timeToWaitBeforeRedirect: 800 }}
    />
  ) : (
    <RecordDialog
      onClose={onClose}
      title={title}
      handleSubmit={handleSubmit}
      data={initialValues}
      content={content}
      size={'l'}
      blockProps={{
        hasBillProcessingFeature,
        activeTab,
        isReimbursementEmptyStateVisible: false,
        isInvoiceDialog: false
      }}
    />
  );
}
