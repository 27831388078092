import * as React from 'react';
import { DialogProps, useErrorDialog } from '@rexlabs/dialog';
import { Dialog } from 'view/components/@luna/dialog';
import { RecordObject } from 'data/models/types';
import { OutlineButton } from '@rexlabs/button';
import ChevronRightIcon from 'view/components/icons/chevron-right';
import ChevronLeftIcon from 'view/components/icons/chevron-left';
import Box from '@rexlabs/box';
import { StyleSheet, useStyles } from '@rexlabs/styling';
import { ErrorBanner } from 'view/components/@luna/notifications/banner';
import { EmailPreview } from '../../common/components/email/email-preview';
import { useResolvedMessageTexts } from '../hooks/use-resolved-message-texts';
import { BulkCreateEmailMessageFormData } from '../types/BulkCreateEmailMessageFormData';
import { mapBulkCreateMessageRequestToEmailPreviewProps } from '../mappers/map-bulk-create-message-form-to-email-preview-props';
import { CommunicationContext } from '../types/communication-context';
import { useRecipientGroupContacts } from '../hooks/use-recipient-group-contacts';

const defaultStyles = StyleSheet({
  buttonWrapper: {
    padding: ({ token }) => token('spacing.l')
  }
});

export interface BulkPreviewEmailMessageFormDataDialogProps
  extends DialogProps {
  data: {
    formData: BulkCreateEmailMessageFormData;
    recordObjects: RecordObject[];
  };
}

/**
 * This dialog shows a preview of the message that will be sent, based on the as-yet-unsent form data.
 * The user can cycle through the previewed messages by clicking the "Next" button.
 */
export function BulkPreviewEmailMessageFormDataDialog({
  onClose,
  data
}: BulkPreviewEmailMessageFormDataDialogProps) {
  const s = useStyles(defaultStyles);
  // This will be the index of the currently displayed message/record object.
  const [selectedIndex, setSelectedIndex] = React.useState(0);

  // Determine which record object is currently selected.
  const contextRecord = React.useMemo(() => data.recordObjects[selectedIndex], [
    selectedIndex,
    data.recordObjects
  ]);

  // Construct the context that we will use for the preview message call
  const context: CommunicationContext = React.useMemo(
    () => ({
      record: {
        id: contextRecord.object.id
      },
      type: {
        id: contextRecord.object.__record_type
      }
    }),
    [contextRecord]
  );

  // Need to resolve the recipients of the message, so that we can display them in the preview.
  const { data: recipientData } = useRecipientGroupContacts(
    data.formData.context_type,
    context.record.id,
    data.formData.recipient_group?.name
  );

  const errorDialog = useErrorDialog();
  const {
    resolvedTexts: [subject, content],
    invalidTags,
    isError,
    error
  } = useResolvedMessageTexts(
    [data.formData.subject, data.formData.content],
    context,
    data.formData.context_type,
    recipientData,
    data.formData.sent_from
  );

  const handlePreviousClick = () => {
    setSelectedIndex(Math.max(0, selectedIndex - 1));
  };

  const handleNextClick = () => {
    setSelectedIndex(
      Math.min(data.recordObjects.length - 1, selectedIndex + 1)
    );
  };

  const formData = {
    ...data.formData,
    subject,
    content
  };

  React.useEffect(() => {
    if (!isError) return;

    errorDialog.open(error);
  }, [isError, error]);

  return (
    <Dialog title='Preview message' onClose={onClose}>
      <Box flexDirection='column' gap={12}>
        {invalidTags.length > 0 && (
          <ErrorBanner
            description={`This message contains invalid merge tags: ${invalidTags.join(
              ', '
            )}`}
          />
        )}
        <EmailPreview
          {...mapBulkCreateMessageRequestToEmailPreviewProps(
            formData,
            recipientData
          )}
          actionButtonSlot={
            <Box display='flex' gap={12} {...s('buttonWrapper')}>
              <OutlineButton
                IconLeft={ChevronLeftIcon}
                onClick={handlePreviousClick}
                isDisabled={selectedIndex === 0}
              >
                Previous
              </OutlineButton>
              <OutlineButton
                onClick={handleNextClick}
                IconRight={ChevronRightIcon}
                isDisabled={selectedIndex === data.recordObjects.length - 1}
              >
                Next
              </OutlineButton>
            </Box>
          }
        />
      </Box>
    </Dialog>
  );
}
