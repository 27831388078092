import { PortfolioCreateRequest } from '../types/portfolio-create-request';
import { CreatePortfolioFormData } from '../types/create-portfolio-form-data';

export function mapPortfolioFormDataToPortfolioCreateRequest(
  formData: CreatePortfolioFormData
): PortfolioCreateRequest {
  return {
    label: formData.portfolio_name,
    users: [formData.primary_portfolio_manager]
  };
}
