import { camelCase } from 'lodash';

import config from 'config';

import { AddressItem } from '../types/AddressItem';

export async function fetchAddressFromString(term) {
  const addresses: AddressItem[] = await fetch(
    `https://api.mapbox.com/geocoding/v5/mapbox.places/${term}.json?proximity=ip&access_token=${config.MAPBOX_API_KEY}`
  )
    .then((res) => res.json())
    .then((data) =>
      data.features.map((feature) => ({
        id: camelCase(feature.place_name),
        label: feature.place_name,
        data: {
          lngLat: feature.center
        }
      }))
    );

  return addresses;
}
