import { getTodayTimezoneDate } from 'utils/dates/dates';
import { ListTableProps } from 'view/components/table/list-table';
import { Tab } from 'view/components/table/tabbed';

export const getInvoiceListTabs = (
  commonTabProps: Partial<Tab<ListTableProps>>,
  timezone: string
) => [
  {
    ...commonTabProps,
    name: 'awaiting_payment',
    label: 'Awaiting payment',
    forcedGlobalFilter: [
      ...(commonTabProps?.forcedGlobalFilter || []),
      {
        field: 'status_id',
        op: 'eq',
        value: { value: 'awaiting_payment', label: 'Awaiting payment' }
      },
      {
        field: 'locked',
        op: 'eq',
        value: false
      }
    ]
  },
  {
    ...commonTabProps,
    name: 'fully_paid',
    label: 'Fully paid',
    forcedGlobalFilter: [
      ...(commonTabProps?.forcedGlobalFilter || []),
      {
        field: 'status_id',
        op: 'eq',
        value: { value: 'paid', label: 'Paid' }
      }
    ]
  },
  {
    ...commonTabProps,
    name: 'overdue',
    label: 'Overdue',
    forcedGlobalFilter: [
      ...(commonTabProps?.forcedGlobalFilter || []),
      {
        field: 'due_date',
        op: 'lt',
        value: getTodayTimezoneDate(timezone)
      },
      {
        field: 'status_id',
        op: 'eq',
        value: { value: 'awaiting_payment', label: 'Awaiting payment' }
      },
      {
        field: 'locked',
        op: 'eq',
        value: false
      }
    ]
  },
  {
    ...commonTabProps,
    name: 'all',
    label: 'All',
    forcedGlobalFilter: [...(commonTabProps?.forcedGlobalFilter || [])],
    initialGlobalFilter: [
      {
        field: 'status_id',
        op: 'neq',
        value: 'void'
      }
    ]
  }
];
