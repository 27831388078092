import Box from '@rexlabs/box';
import React from 'react';
import InfoCircleIcon from 'view/components/icons/info';
import { Banner } from 'src/modules/common/components/banner';
import { WorkOrderDetails } from '../types/WorkOrderDetails';

export function OwnerBanner({
  owner
}: {
  owner?: WorkOrderDetails['work_done_by'];
}) {
  return (
    <Banner Icon={InfoCircleIcon} intent={'information'}>
      <Box
        alignItems={'center'}
        justifyContent={'space-between'}
        width={'100%'}
      >
        {`Work will be completed by ${owner?.display_name} (owner)`}
      </Box>
    </Banner>
  );
}
