import React, { useMemo } from 'react';
import { TabbedTable } from 'view/components/table';
import { Tab } from 'view/components/table/tabbed';
import { api } from 'utils/api/api-client';
import LoadingState from 'view/components/states/loading';
import {
  getQueryString,
  ListTable
} from 'view/components/table/list-table-react-query';
import { FinancialEntity } from 'src/modules/financials/utils/financial-entity-action-group/use-get-financial-entity-actions';
import { getFinancialFolioTabbedTableColumns } from '../utils/get-financial-folio-tabbed-table-columns';
import { FinancialFoliosListEmptyState } from './financial-folios-list-empty-state';
import { RecordCountBadge } from './record-count-badge';

const tenanciesTabId = 'tenancies';
const ownershipsTabId = 'ownerships';
const contactsTabId = 'contacts';

export const tableTabIds = [tenanciesTabId, ownershipsTabId, contactsTabId];
export const tableHashParamKey = 'financial-folios';

export function FinancialFolioTabbedTable({
  searchValue,
  setSelectedFolio,
  selectedFolio
}: {
  searchValue: string;
  setSelectedFolio: React.Dispatch<React.SetStateAction<FinancialEntity>>;
  selectedFolio: FinancialEntity;
}) {
  const common = useMemo(() => {
    return {
      Table: ListTable,
      Loading: LoadingState,
      hideToolbar: true,
      hideBulkActions: true,
      withPagination: true,
      emptyWithoutTable: true
    };
  }, []);

  const fetchTenancies = React.useCallback(
    async (query) => {
      const queryString = getQueryString({
        ...query,
        search: searchValue ?? '',
        include: [
          'property_tenancies',
          'property_tenancies.rent_schedule',
          'property_tenancies.property'
        ]
      });

      return await api.get(`/tenancies?${queryString}`);
    },
    [searchValue]
  );

  const fetchContacts = React.useCallback(
    async (query) => {
      const queryString = getQueryString({
        ...query,
        search: searchValue ?? '',
        include: []
      });

      return await api.get(`/contacts?${queryString}`);
    },
    [searchValue]
  );

  const fetchOwnerships = React.useCallback(
    async (query) => {
      const queryString = getQueryString({
        ...query,
        search: searchValue ?? '',
        include: ['property_ownerships', 'property_ownerships.property']
      });

      return await api.get(`/ownerships?${queryString}`);
    },
    [searchValue]
  );

  const tabs = useMemo<Tab[]>(() => {
    return [
      {
        ...common,
        id: tenanciesTabId,
        name: tenanciesTabId,
        label: 'Tenancies',
        IconEnd: (props) => (
          <RecordCountBadge
            search={searchValue}
            queryKey={'tenancies'}
            {...props}
          />
        ),
        primaryQueryKey: `tenancies-${searchValue}`,
        queryFn: (query) => fetchTenancies(query),
        Empty: () => <FinancialFoliosListEmptyState recordType='tenancy' />,
        columns: getFinancialFolioTabbedTableColumns({
          selectedFolio,
          setSelectedFolio,
          recordType: 'tenancy'
        })
      },
      {
        ...common,
        id: ownershipsTabId,
        name: ownershipsTabId,
        label: 'Ownerships',
        IconEnd: (props) => (
          <RecordCountBadge
            search={searchValue}
            queryKey={'ownerships'}
            {...props}
          />
        ),
        primaryQueryKey: `ownerships-${searchValue}`,
        queryFn: (query) => fetchOwnerships(query),
        Empty: () => <FinancialFoliosListEmptyState recordType='ownership' />,
        columns: getFinancialFolioTabbedTableColumns({
          selectedFolio,
          setSelectedFolio,
          recordType: 'ownership'
        })
      },
      {
        ...common,
        id: contactsTabId,
        name: contactsTabId,
        label: 'Contacts',
        IconEnd: (props) => (
          <RecordCountBadge
            search={searchValue}
            queryKey={'contacts'}
            {...props}
          />
        ),
        primaryQueryKey: `contacts-${searchValue}`,
        queryFn: (query) => fetchContacts(query),
        Empty: () => <FinancialFoliosListEmptyState recordType='contact' />,
        columns: getFinancialFolioTabbedTableColumns({
          selectedFolio,
          setSelectedFolio,
          recordType: 'contact'
        })
      }
    ];
  }, [
    common,
    fetchContacts,
    fetchOwnerships,
    fetchTenancies,
    searchValue,
    selectedFolio,
    setSelectedFolio
  ]);

  return (
    <TabbedTable
      tabs={tabs}
      hashParamKey={tableHashParamKey}
      key={searchValue}
    ></TabbedTable>
  );
}
