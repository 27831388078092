import { BaseModelGeneratorModel } from '@rexlabs/model-generator';

import { Generator } from 'data/models/generator';
import { TimestampAttributes } from '../../types';

export interface TaxType extends BaseModelGeneratorModel, TimestampAttributes {
  is_default: boolean;
  label: string;
  rate: number;
}

export const financialsTaxTypesModel = new Generator<TaxType>(
  'financials/tax-types'
).createEntityModel();
