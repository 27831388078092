import React, { useMemo } from 'react';

import { BlockConfig } from 'view/components/record-screen/types';
import { ActionButtons } from 'view/components/@luna/action-buttons';

import { Property } from 'src/modules/properties/types/property-types';
import { Tenancy } from 'data/models/entities/tenancies';
import { Contact } from 'src/modules/contacts/types/contact-types';
import { Trans } from 'react-i18next';
import { PropertyTenanciesList } from '../components/property-tenancies-list';
import { useGetCreatePropertyTenancyAction } from '../actions/use-get-create-property-tenancy-action';

type GetProps<Data = any> = (options: {
  data: Data;
}) => {
  property?: Property;
  tenancy?: Tenancy;
  contact?: Contact;
};

export function getPropertyTenanciesBlock<Data = any>(
  getProps: GetProps<Data>
): BlockConfig {
  return {
    id: 'property-tenancies',
    title: () => <Trans i18nKey='lease-agreements.label.plural' />,
    View: ({ data }) => {
      const { property, tenancy, contact } = useMemo(() => getProps({ data }), [
        data
      ]);
      return (
        <PropertyTenanciesList
          property={property}
          tenancy={tenancy}
          contact={contact}
        />
      );
    },
    Actions: ({ data }) => {
      const { property, tenancy } = useMemo(() => getProps({ data }), [data]);

      const getCreatePropertyTenancyAction = useGetCreatePropertyTenancyAction();

      return (
        <ActionButtons
          actions={[
            {
              ...getCreatePropertyTenancyAction({
                tenancy,
                property
              })
            }
          ]}
        />
      );
    }
  };
}
