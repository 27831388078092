import { Disclosure } from '@headlessui/react';
import React, { useEffect, useMemo } from 'react';

import Box from '@rexlabs/box';
import { LinkButton } from '@rexlabs/button';
import { Field } from '@rexlabs/form';
import { StyleSheet, useStyles, useToken } from '@rexlabs/styling';

import { Grid } from 'view/components/@luna/form/grid';
import { AuthorSelect } from 'view/components/inputs/selects/v4-selects/author-select';
import { EntitySelect } from 'view/components/inputs/selects/v4-selects/entity-select';
import { BlockConfig } from 'view/components/record-screen/types';

import { RecordType } from 'data/models/types';

import { getSearchResultItemFromObjectAndModel } from 'src/modules/common/utils/search-result-items/get-search-result-items-from-object-and-model';
import { contactsModel } from 'src/modules/contacts/models/contacts';
import { Contact } from 'src/modules/contacts/types/contact-types';

import { SearchResultItem } from 'utils/api/get-search-results';

import { RecordOption } from '@rexlabs/select';
import { useFeatureFlags } from 'view/components/@luna/feature-flags';
import { FLAGS } from 'utils/feature-flags';
import { MessageTemplateSelect } from '../../common/components/selects/message-template-select';
import { emptyMessageCreatorItem } from '../../common/data/empty-message-creator-item';
import { ChannelTypeId } from '../../common/types/channel';
import { getRelatedContactsOfCommunicationRecord } from '../../common/utils/get-related-contacts-of-communication-record';
import {
  CoreCommunicationContextObject,
  CreateMessageFormData
} from '../mappers/types/create-message-form-data';
import { getContextTypeFromRecord } from '../utils/get-context-type-from-record';

const validate = {
  definitions: {
    relates_to: { name: 'relates to', rules: 'required' },
    recipients: { name: 'recipients', rules: 'required' }
  }
};

const defaultStyles = StyleSheet({
  // Need to make sure that the grid columns are the same size even if one of the inputs in them tries to be bigger
  grid: {
    gridTemplateColumns: 'repeat(2, minmax(0, 1fr))'
  }
});

export const messageDetailsBlock: BlockConfig<
  CreateMessageFormData,
  { messageType: ChannelTypeId; singleRecipient?: boolean },
  CreateMessageFormData
> = {
  id: 'details',
  title: 'Message Details',
  validate,
  Edit: ({ forms, getHandlers, data, blockProps, values }) => {
    const { hasFeature } = useFeatureFlags();
    const hasGuarantorAndOccupierUpdates = hasFeature(
      FLAGS.GUARANTOR_AND_OCCUPIER_UPDATES
    );

    const [suggestedRecipients, setSuggestedRecipients] = React.useState<
      SearchResultItem<Contact>[]
    >([]);

    const s = useStyles(defaultStyles);
    const token = useToken();
    const handlers = getHandlers?.(forms);

    const isEmail = blockProps?.messageType === 'email';

    const fetchSuggestedRecipients = async (
      record?: CoreCommunicationContextObject
    ) => {
      const contacts = await getRelatedContactsOfCommunicationRecord({
        searchItem: record,
        hasGuarantorAndOccupierUpdates
      });

      const contactSearchResultItems = contacts.map(
        (contact) =>
          getSearchResultItemFromObjectAndModel<Contact>(
            contact,
            contactsModel
          )!
      );

      setSuggestedRecipients(contactSearchResultItems);
    };

    const onRelatesToChange = (event) => {
      handlers?.handleRelatesToChange(event);

      const record = event.target.value?.record;

      if (!record) return;

      fetchSuggestedRecipients(record);
    };

    // Note: set suggested recipients on mount, if relates to is already set
    useEffect(() => {
      const record = data?.relates_to?.record;
      if (!record) return;

      fetchSuggestedRecipients(record);
    }, []);

    const recipientGroupsFilter = useMemo(
      () => getRecipientGroupFilter(values?.relates_to?.record),
      [values?.relates_to?.record]
    );

    return (
      <Box sy='2rem'>
        <Grid columns={2} {...s('grid')}>
          <Box
            sy='2rem'
            gap={token('spacing.l')}
            display='flex'
            flexDirection='column'
          >
            <Field
              id='relates_to'
              name='relates_to'
              label='Message relates to'
              Input={EntitySelect}
              inputProps={{
                objectTypes: [
                  'contact',
                  'property',
                  'ownership',
                  'tenancy',
                  'task',
                  'disbursement',
                  'statement',
                  'trust_journal_entry',
                  'invoice'
                ] as RecordType[]
              }}
              onChange={onRelatesToChange}
            />
            <Field
              id='sent_from'
              name='sent_from'
              label='Sent from'
              Input={AuthorSelect}
              inputProps={{ staticItems: [emptyMessageCreatorItem] }}
            />
          </Box>

          <Box
            sy='2rem'
            gap={token('spacing.l')}
            display='flex'
            flexDirection='column'
          >
            <Field<typeof MessageTemplateSelect>
              id='template'
              name='template'
              label='Template'
              Input={MessageTemplateSelect}
              onChange={handlers?.handleTemplateChange}
              inputProps={{
                contextType: getContextTypeFromRecord(data?.relates_to?.record),
                channelType: blockProps?.messageType,
                recipientGroups: recipientGroupsFilter
              }}
            />
            <Field
              id='recipients'
              name='recipients'
              label='Recipients'
              Input={EntitySelect}
              inputProps={{
                multi: blockProps?.singleRecipient ? false : true,
                Option: RecordOption,
                getSuggestedItems: () => suggestedRecipients,
                objectTypes: ['contact']
              }}
            />
          </Box>
        </Grid>

        {isEmail && (
          <Disclosure as={Box} marginTop={20}>
            <Box display='flex' justifyContent='flex-end'>
              <Disclosure.Button as={LinkButton}>Add cc/bcc</Disclosure.Button>
            </Box>
            <Disclosure.Panel as={Grid} columns={2}>
              <Field
                id='cc_recipients'
                name='cc_recipients'
                label='CC'
                Input={EntitySelect}
                inputProps={{
                  multi: true,
                  objectTypes: ['contact']
                }}
              />
              <Field
                id='bcc_recipients'
                name='bcc_recipients'
                label='BCC'
                Input={EntitySelect}
                inputProps={{
                  multi: true,
                  objectTypes: ['contact']
                }}
              />
            </Disclosure.Panel>
          </Disclosure>
        )}
      </Box>
    );
  }
};

function getRecipientGroupFilter(
  relatesToRecord?: CoreCommunicationContextObject
) {
  const recipientGroupFilter: string[] = [];

  if (!relatesToRecord) return recipientGroupFilter;

  switch (relatesToRecord.__record_type) {
    case 'invoice': {
      ['ownership', 'contact'].includes(
        relatesToRecord.payable_by.object.type.id
      ) && recipientGroupFilter.push('payable_by', 'payable_to');
      break;
    }
    default:
      break;
  }

  return recipientGroupFilter;
}
