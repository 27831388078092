import React from 'react';
import { BlockConfig } from 'view/components/record-screen/types';
import { Grid, Column } from '@rexlabs/grid';
import { Value } from 'view/components/values';
import { Field } from '@rexlabs/form';
import { TextInput } from '@rexlabs/text-input';

export const bankAccountBlock: BlockConfig = {
  id: 'bank-account',
  title: 'Bank Account',
  View: ({ data }) => {
    return (
      <Grid>
        <Column width={6}>
          <Value
            label='Institution'
            value={data?.bank_account.institution_name}
          />
        </Column>

        <Column width={6}>
          <Value label='Bank Code' value={data?.bank_account.bank_code} />
        </Column>

        <Column width={12}>
          <Value
            label='Account Number'
            value={data?.bank_account.account_number}
          />
        </Column>
      </Grid>
    );
  },
  Edit: () => {
    return (
      <Grid>
        <Column width={6}>
          <Field
            label='Institution'
            name='bank_account.institution_name'
            Input={TextInput}
          />
        </Column>

        <Column width={6}>
          <Field
            label='Bank Code'
            name='bank_account.bank_code'
            Input={TextInput}
          />
        </Column>

        <Column width={12}>
          <Field
            label='Account Number'
            name='bank_account.account_number'
            Input={TextInput}
          />
        </Column>
      </Grid>
    );
  }
};
