import {
  BankDeposit,
  BankDepositStatus
} from 'src/modules/banking/bank-deposits/types/bank-deposit-types';
import { useGetPrimaryRecordActionGroup } from 'view/hooks/actions/use-get-primary-record-action-group';
import { ActionDeclaration } from 'src/modules/common/actions/types/action-declaration-types';
import { useModelDownloadAction } from 'src/modules/common/actions/reporting/use-model-download-action';
import { useGetUnreconcileAction } from 'src/modules/banking/bank-deposits/utils/bank-deposit-actions/use-get-unreconcile-action';
import { useGetReconcileAction } from 'src/modules/banking/bank-deposits/utils/bank-deposit-actions/use-get-reconcile-action';
import { useStatusChangeAction } from 'src/modules/common/actions/status/use-status-change-action';
import { bankDepositModel } from 'src/modules/banking/bank-deposits/models/bank-deposit-model';
import { useModelActions } from '@rexlabs/model-generator';

export function useBankDepositActions(): (
  bankDeposit: BankDeposit
) => ActionDeclaration[] {
  const getPrimaryRecordActionGroup = useGetPrimaryRecordActionGroup();
  const getReconcileAction = useGetReconcileAction();
  const getUnreconcileAction = useGetUnreconcileAction();
  const getModelDownloadAction = useModelDownloadAction(bankDepositModel);
  const { downloadItem } = useModelActions(bankDepositModel);
  const statusChangeAction = useStatusChangeAction(bankDepositModel);

  return function (bankDeposit: BankDeposit) {
    const voidAction = statusChangeAction<BankDepositStatus>({
      record: bankDeposit,
      status: 'void',
      dialogOptions: {
        label: 'Void'
      }
    });
    const statusActions: ActionDeclaration[] = [];
    switch (bankDeposit.status.id) {
      case 'deposited':
      case 'pending_deposit':
        statusActions.push(voidAction);
        break;
    }

    const downloadPdf = getModelDownloadAction({
      fetcher: () => downloadItem(bankDeposit.id),
      label: 'Download bank deposit (PDF)',
      record: bankDeposit
    });

    return [
      ...getPrimaryRecordActionGroup('bank_deposit', bankDeposit.id),
      getReconcileAction(),
      getUnreconcileAction(),
      downloadPdf,
      ...statusActions
    ];
  };
}
