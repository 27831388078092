import Box from '@rexlabs/box';
import { Checkbox } from '@rexlabs/checkbox';
import { Field, useReactFormsContext } from '@rexlabs/form';
import { useToken } from '@rexlabs/styling';
import { TextArea, TextInput } from '@rexlabs/text-input';
import { ContactPaymentMethod } from 'data/models/entities/contacts/payment-methods';
import { BankAccount } from 'data/models/entities/financials/bank-accounts';
import { Invoice } from 'data/models/entities/financials/invoices';
import { Ownership } from 'data/models/entities/ownerships';
import { Tenancy } from 'data/models/entities/tenancies';
import { ValueListValue } from 'data/models/types';
import { get } from 'lodash';
import React from 'react';
import { BankAccountSelect } from 'src/modules/bank-accounts/components/bank-account-select';
import { Contact } from 'src/modules/contacts/types/contact-types';
import { api } from 'utils/api/api-client';
import { SearchResultItem } from 'utils/api/get-search-results';
import { FinancialRecordTypes } from 'utils/financials/financial-objects';
import { Accordion } from 'view/components/@luna/accordion/accordion';
import { Column, Grid } from 'view/components/@luna/form/grid';
import { DateInput } from 'view/components/@luna/inputs/date-input/date-input';
import { DisbursementInstructionInput } from 'view/components/inputs/selects/disbursement-instruction';
import { EntityFixtureSelect } from 'view/components/inputs/selects/v4-selects/entity-select-fixture';
import { TaskSelect } from 'view/components/inputs/selects/v4-selects/task-select';
import InfoCircleIcon from 'view/components/icons/info';
import { WarningBanner } from 'view/components/@luna/notifications/banner';
import { Task } from 'src/modules/tasks/common/types/Task';
import { useSendInvoiceCheckboxLabel } from '../hooks/use-send-invoice-checkbox-label';
import { getDefaultDisbursementOption } from '../utils/get-default-disbursement-option';
import { fetchInvoicesRelatedToWorkOrder } from '../utils/fetch-invoices-related-to-work-order';
import { fetchWorkOrderSuggestedItems } from '../utils/fetch-work-order-suggested-items';
import { InvoicePrioritySelect } from './invoice-priority-select';

export type InvoiceDetailsFormValues = {
  send_invoice?: boolean;
  payable_to: {
    object: SearchResultItem<Ownership | Tenancy | Contact>;
  };
  payable_by: {
    object: SearchResultItem<Ownership | Tenancy | Contact>;
  };
  description: string;
  invoice_date?: string;
  due_date: string;
  do_not_pay_before_date: string;
  is_tax_included: boolean;
  bill_reference?: string;
  specific_disbursement_payment_method:
    | UseDisbursementPreferences
    | ContactPaymentMethod
    | null;
  bank_account: BankAccount;
  reimbursement_for_invoice?: Invoice;
  bill_priority?: ValueListValue<'high' | 'normal' | 'low'>;
  notes: string | null;
  task?: Task<'task_maintenance' | 'task_work_order'>;
};

export type UseDisbursementPreferences = 'use_disbursement_preferences';

export interface InvoiceDetailsFormProps {
  columns?: 2 | 4;
  suggestions?: {
    payableTo?: Array<SearchResultItem<Ownership | Tenancy>>;
    payableBy?: Array<SearchResultItem<Ownership | Tenancy>>;
  };
  onDescriptionBlur?: (value?: string) => void;
  onPayableToBlur?: (event, previousItem?: SearchResultItem) => void;
  onPayableByBlur?: (event, previousItem?: SearchResultItem) => void;
  onBillReferenceBlur?: (event, previousItem?: SearchResultItem) => void;
}

export function InvoiceDetailsForm({
  columns = 4,
  suggestions,
  onDescriptionBlur,
  onPayableToBlur,
  onPayableByBlur,
  onBillReferenceBlur
}: InvoiceDetailsFormProps) {
  const { values, setFieldValue } = useReactFormsContext<
    InvoiceDetailsFormValues,
    any
  >();

  const previousPayableTo = usePrevious(values?.payable_to?.object);
  const previousPayableBy = usePrevious(values?.payable_by?.object);

  const [disbursementOptions, setDisbursementOptions] = React.useState<
    ContactPaymentMethod[]
  >([]);

  const [isAccordionOpen, setIsAccordionOpen] = React.useState(false);
  const [
    workOrderHasRelatedInvoice,
    setWorkOrderHasRelatedInvoice
  ] = React.useState(false);
  const [workOrderSuggestedItems, setWorkOrderSuggestedItems] = React.useState(
    []
  );

  const token = useToken();

  const sendInvoiceCheckboxLabel = useSendInvoiceCheckboxLabel(
    values.payable_by?.object?.record
  );

  // Set default values
  React.useEffect(() => {
    if (!values.payable_to?.object) return;

    setFieldValue('payable_to.object', values.payable_to.object);

    const financialRecordId = values?.payable_to?.object?.id;
    const financialRecordType = values?.payable_to?.object?.type?.id;

    const isOwnership = financialRecordType === 'ownership';

    fetchReimbursementOptions(financialRecordId, financialRecordType).then(
      (options) => {
        setDisbursementOptions(options);

        const result = getDefaultDisbursementOption(isOwnership, options);

        setFieldValue('specific_disbursement_payment_method', result.value);
        setIsAccordionOpen(result.isAccordionOpen);
      }
    );
  }, []);

  // If the selected disbursement method is using bpay, we want to change the biller code/bill ref field label
  const isBpay = React.useMemo(() => {
    const { specific_disbursement_payment_method } = values;
    if (specific_disbursement_payment_method) {
      return (
        get(specific_disbursement_payment_method, 'payment_method.id') ===
        'bpay'
      );
    }
    return false;
  }, [values]);

  const handlePayableToBlur = async (event) => {
    onPayableToBlur?.(event, previousPayableTo);

    const financialRecordId = event.target.value?.id;
    const financialRecordType = event.target.value?.type?.id;

    const isOwnership = financialRecordType === 'ownership';

    const paymentMethods = await fetchReimbursementOptions(
      financialRecordId,
      financialRecordType
    );

    setDisbursementOptions(paymentMethods);

    const result = getDefaultDisbursementOption(isOwnership, paymentMethods);

    setFieldValue('specific_disbursement_payment_method', result.value);
    setIsAccordionOpen(result.isAccordionOpen);
  };

  const payableToOnChangeHandler = async (e) => {
    const payableTo: Ownership | Contact | Tenancy = e.target.value;

    if (!payableTo) {
      setWorkOrderSuggestedItems([]);
    } else {
      const workOrderSuggestedItems = await fetchWorkOrderSuggestedItems([
        {
          field: 'closed_at',
          op: 'eq',
          value: 'null'
        },
        {
          field: 'task_work_order_work_done_by_id',
          op: 'eq',
          value: payableTo.id
        }
      ]);
      setWorkOrderSuggestedItems(workOrderSuggestedItems);
    }
  };

  const relatedTaskOnChangeHandler = async (e) => {
    const workOrder: Task<'task_work_order'> = e.target.value;

    if (workOrder) {
      const relatedInvoices = await fetchInvoicesRelatedToWorkOrder(
        workOrder.id
      );
      setWorkOrderHasRelatedInvoice(!!relatedInvoices.length);
    } else {
      setWorkOrderHasRelatedInvoice(false);
    }
  };

  return (
    <Box
      data-testid='invoice_details_form'
      flexDirection='column'
      spacing={token('spacing.xl')}
    >
      <Grid columns={columns}>
        <Field
          id='invoice_from_payable_to'
          name='payable_to.object'
          label='Payable to'
          Input={EntityFixtureSelect}
          onBlur={handlePayableToBlur}
          inputProps={{
            objectTypes: Object.values(FinancialRecordTypes),
            getSuggestedItems: async () => suggestions?.payableTo
          }}
          onChange={payableToOnChangeHandler}
        />
        <Box>
          <Grid columns={1}>
            <Field
              name='payable_by.object'
              id='bill_to_payable_by'
              label='Payable by'
              onBlur={(event) => onPayableByBlur?.(event, previousPayableBy)}
              Input={EntityFixtureSelect}
              inputProps={{
                objectTypes: Object.values(FinancialRecordTypes),
                getSuggestedItems: async () => suggestions?.payableBy
              }}
            />
          </Grid>

          {sendInvoiceCheckboxLabel ? (
            <Field<typeof Checkbox>
              name='send_invoice'
              id='send_invoice'
              optional={false}
              inputProps={{
                label: sendInvoiceCheckboxLabel
              }}
              Input={Checkbox}
            />
          ) : null}
        </Box>

        <Field
          name='description'
          label='Description'
          Input={TextInput}
          onBlur={(e) => onDescriptionBlur?.(e.target.value)}
        />

        <Field name='due_date' label='Due Date' Input={DateInput} />

        <Field
          name='bill_reference'
          label={`${isBpay ? 'BPAY customer' : 'Bill'} reference number`}
          Input={TextInput}
          onBlur={onBillReferenceBlur}
        />

        <Field
          name='task'
          label={'Related task'}
          Input={TaskSelect}
          inputProps={{
            filter: [
              {
                field: 'type_id',
                op: 'in',
                value: ['task_work_order', 'task_maintenance']
              }
            ],
            includes: ['details.invoices'],
            deselectable: true,
            getSuggestedItems: () => workOrderSuggestedItems,
            groupByTaskType: true
          }}
          onChange={relatedTaskOnChangeHandler}
        />
      </Grid>

      {workOrderHasRelatedInvoice && (
        <WarningBanner
          Icon={InfoCircleIcon}
          description={'This task already has a related invoice.'}
        ></WarningBanner>
      )}
      <Accordion
        open={isAccordionOpen}
        onChange={(isOpen) => setIsAccordionOpen(isOpen)}
        headline='More options'
      >
        <Grid columns={columns}>
          <Field
            label='Bank Account'
            name='bank_account'
            Input={BankAccountSelect}
          />

          <Field
            label='Disbursement Instruction'
            name='specific_disbursement_payment_method'
            id='specific_disbursement_payment_method'
            Input={DisbursementInstructionInput}
            inputProps={{
              items: disbursementOptions
            }}
          />

          <Field
            name='bill_priority'
            label='Priority'
            Input={InvoicePrioritySelect}
            optional={false}
          />

          <Field
            name='do_not_pay_before_date'
            label='Do not pay before date via disbursement'
            Input={DateInput}
          />
          <Column width={columns}>
            <Field
              Input={TextArea}
              name='notes'
              label='Invoice notes'
              description='Any notes added here will be displayed on the invoice.'
              inputProps={{
                placeholder: 'Enter notes...'
              }}
            />
          </Column>
        </Grid>
      </Accordion>
    </Box>
  );
}

// Helpers

async function fetchReimbursementOptions(
  financialRecordId,
  financialRecordType
) {
  if (!financialRecordId || !financialRecordType) return [];

  const paymentMethods = await api.get('/financials/contact-payment-methods', {
    per_page: 30,
    q: `${financialRecordType}_id.eq(${financialRecordId})`
  });

  return paymentMethods.data;
}

function usePrevious<T>(value: T): T | undefined {
  const ref = React.useRef<T>();

  React.useEffect(() => {
    ref.current = value;
  });

  return ref.current;
}
