import { Ownership } from 'data/models/entities/ownerships';
import { Generator, GeneratorConfig } from 'data/models/generator';
import {
  BaseFields,
  RecordTypes,
  TimestampAttributes,
  ValueListValue
} from 'data/models/types';

import { Contact } from 'src/modules/contacts/types/contact-types';

const taxSummaryTypes = ['yearly', 'quarterly'] as const;

type TaxSummaryType = typeof taxSummaryTypes[number];

export type TaxSummary = BaseFields<typeof RecordTypes.TaxSummary> &
  TimestampAttributes & {
    type: ValueListValue<TaxSummaryType>;
    tax_due: number;
    tax_currently_withheld: number;
    total_tax_withheld: number;
    tax_paid: number;
    ownership_share: number;
    ownership: Ownership;
    contact: Contact;
    shortfall: boolean;
    end_date: string;
    start_date: string;
    taxable_income: number;
    taxable_income_as_non_resident_landlord: number;
    deductible_expenses: number;
    deductible_expenses_as_non_resident_landlord: number;
    manual_adjustments_to_tax_due: number;
  };

const config: GeneratorConfig = {
  entities: {
    related: {
      ownership: {
        include: 'ownership,ownership.operating_bank_account'
      },
      contact: {
        include: 'contact'
      }
    }
  }
};

export const taxSummariesModel = new Generator<TaxSummary>(
  'financials/tax-summaries',
  config
).createEntityModel();
