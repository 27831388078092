import { NormalisedItem } from '@rexlabs/select';
import { Tenancy } from 'data/models/entities/tenancies';
import { RecordTypes } from 'data/models/types';

export function normaliseTenancy(item: Tenancy): NormalisedItem {
  return {
    id: item.id,
    label: item.display_name,
    type: RecordTypes.Tenancy
  };
}
