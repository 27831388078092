import { CommunicationContextType } from '../../common/types/communication-context-type';
import { CommunicationContext } from '../types/communication-context';
import { getContextTypeFromRecord } from '../utils/get-context-type-from-record';
import { CreateMessageFormData } from './types/create-message-form-data';
import { CreateMessageRequest } from './types/create-message-request';

/**
 * Map the simple form data to the more complex API request structure.
 * @param formData The form values coming from the blocks
 * @returns The shape of data expected by the API
 */
export function mapCreateSMSFormToRequest(
  formData: CreateMessageFormData
): CreateMessageRequest {
  return {
    context: formData.relates_to as CommunicationContext,
    context_type: {
      id: getContextTypeFromRecord(
        formData.relates_to.record
      ) as CommunicationContextType
    },
    // If they did not specify a send_from_author, we don't want to include this at all
    ...(formData.sent_from?.id
      ? { send_from_author: { id: formData.sent_from!.id } }
      : {}),
    // Map the recipients
    selected_recipients:
      formData.recipients?.map((recipient) => recipient.record.id) ?? [],
    // This is hard-coded for now
    forced_channel_type: {
      id: 'sms'
    },
    channels: [
      {
        channel_type: {
          id: 'sms'
        },
        text_body: formData.smsContent ?? ''
      }
    ],
    ...(formData.template?.id
      ? { template: { id: formData.template?.id } }
      : {})
  };
}
