export const INVOICES_ROUTES = {
  INVOICES_LIST: {
    config: {
      path: '/invoices',
      Component: require('./screens/invoices-list').InvoicesList
    },

    CREATE: {
      config: {
        path: '/invoices/create',
        Component: require('./dialogs/create-invoice-dialog')
          .CreateInvoiceDialog
      }
    }
  },

  INVOICE: {
    config: {
      path: '/invoice/:invoiceId',
      Component: require('./screens/invoice-details').InvoiceDetailsScreen
    }
  }
};
