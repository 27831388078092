import React from 'react';
import { BlockConfig } from 'view/components/record-screen/types';
import { Column, Grid } from 'view/components/@luna/form/grid';
import { Small } from '@rexlabs/text';
import Box from '@rexlabs/box';
import { BooleanValue } from 'view/components/values/boolean';
import { Value } from 'view/components/values';
import { useStyles, StyleSheet, useToken } from '@rexlabs/styling';
import { Field } from '@rexlabs/form';
import { Checkbox } from '@rexlabs/checkbox';
import { TextArea } from '@rexlabs/text-input';
import { useTranslation } from 'react-i18next';
import { InspectionFormData } from '../../types/InspectionFormData';
import { InspectionTask } from '../../types/InspectionTask';

export const defaultStyles = StyleSheet({
  subtext: {
    color: ({ token }) => token('color.textStyle.body.subtext')
  }
});

export const entryExitCommunicationsBlock: BlockConfig<
  InspectionTask,
  any,
  InspectionFormData
> = {
  id: 'entry-exit-property-details-communications',
  title: 'Communications',
  isMobileForcedCollapsed: true,
  isEditable: (data) =>
    !['report_completed', 'report_sent'].includes(
      data?.details?.status?.id || ''
    ),
  View: ({ data }) => {
    const s = useStyles(defaultStyles);

    const propertyDetails = data?.details?.property_details;
    const communicationsData = propertyDetails?.communication_data;

    return (
      <>
        <Grid columns={1}>
          <Box marginBottom={'-10px'}>
            <Small semibold {...s('subtext')}>
              Approximate dates when work last done on residential premises
            </Small>
          </Box>
          <Grid columns={2}>
            <BooleanValue
              label='A telephone line is connected to the site'
              value={communicationsData?.is_telephone_line_connected}
              trueLabel='Yes'
              falseLabel='No'
            />
            <BooleanValue
              label='An internet line is connected to the site'
              value={communicationsData?.is_internet_line_connected}
              trueLabel='Yes'
              falseLabel='No'
            />
            <BooleanValue
              label='The site is connected to the national broadband network'
              value={communicationsData?.is_broadband_connected}
              trueLabel='Yes'
              falseLabel='No'
            />
            <Column width={2}>
              <Value
                label={'Describe NBN connection'}
                value={communicationsData?.broadband_description}
              />
            </Column>
            <Column width={2}>
              <Value
                label={'Location of NBN connection'}
                value={communicationsData?.broadband_location}
              />
            </Column>
          </Grid>
        </Grid>
      </>
    );
  },
  Edit: () => {
    const s = useStyles(defaultStyles);
    const token = useToken();
    const { t } = useTranslation();

    const communicationsDataPath = 'property_details.communication_data';

    return (
      <>
        <Box marginBottom={token('spacing.m')}>
          <Small semibold {...s('subtext')}>
            Approximate dates when work last done on residential premises
          </Small>
        </Box>
        <Grid columns={2}>
          <Field
            id={`${communicationsDataPath}.is_telephone_line_connected`}
            name={`${communicationsDataPath}.is_telephone_line_connected`}
            Input={Checkbox}
            optional={false}
            inputProps={{
              label: 'A telephone line is connected to the site'
            }}
          />
          <Field
            id={`${communicationsDataPath}.is_internet_line_connected`}
            name={`${communicationsDataPath}.is_internet_line_connected`}
            Input={Checkbox}
            optional={false}
            inputProps={{
              label: 'An internet line is connected to the site'
            }}
          />
          <Field
            id={`${communicationsDataPath}.is_broadband_connected`}
            name={`${communicationsDataPath}.is_broadband_connected`}
            Input={Checkbox}
            optional={false}
            inputProps={{
              label: 'The site is connected to the national broadband network'
            }}
          />
          <Column width={2}>
            <Field
              id={`${communicationsDataPath}.broadband_description`}
              name={`${communicationsDataPath}.broadband_description`}
              Input={TextArea}
              label={
                t(
                  'tasks.inspections.property-details.communication-data.broadband-description.label.singular'
                ) as string
              }
              optional={false}
              inputProps={{
                placeholder: 'Enter text...'
              }}
            />
          </Column>
          <Column width={2}>
            <Field
              id={`${communicationsDataPath}.broadband_location`}
              name={`${communicationsDataPath}.broadband_location`}
              Input={TextArea}
              label={
                t(
                  'tasks.inspections.property-details.communication-data.broadband-location.label.singular'
                ) as string
              }
              optional={false}
              inputProps={{
                placeholder: 'Enter text...'
              }}
            />
          </Column>
        </Grid>
      </>
    );
  }
};
