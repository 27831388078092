import React, { useEffect } from 'react';
import { isEmpty } from 'lodash';

import { useToken } from '@rexlabs/styling';
import { NumberInput, NumberInputProps } from '@rexlabs/text-input';
import Box from '@rexlabs/box';
import { Select } from '@rexlabs/select';
import { Body } from '@rexlabs/text';

import { ValueListValue } from 'data/models/types';

type FrequencyUnitId = 'days' | 'weeks' | 'months' | 'years';

export const frequencyUnitItem: ValueListValue<FrequencyUnitId>[] = [
  { id: 'days', label: 'Days' },
  { id: 'weeks', label: 'Weeks' },
  { id: 'months', label: 'Months' },
  { id: 'years', label: 'Years' }
];

export type FrequencyValue = {
  count: number | null;
  unit: ValueListValue<FrequencyUnitId> | null;
};

export interface FrequencyInputProps
  extends Omit<NumberInputProps, 'value' | 'onChange' | 'onBlur'> {
  value?: FrequencyValue;
  onChange?: (e: { target: { value: FrequencyValue } }) => void;
  onBlur?: (e: { target: { value: FrequencyValue } }) => void;
  inputLabel?: string;
  minCount?: number;
  defaultUnit?: ValueListValue<FrequencyUnitId>;
}

const defaultFrequencyValue = {
  count: null,
  unit: null
};

export function FrequencySplitInput({
  value,
  onChange,
  onBlur,
  inputLabel,
  minCount = 1,
  defaultUnit = frequencyUnitItem.find((item) => item.id === 'weeks'),
  ...props
}: FrequencyInputProps) {
  const token = useToken();

  const handleChange = (newValue: Partial<FrequencyValue>) => {
    onChange?.({
      target: {
        value: {
          ...(value || defaultFrequencyValue),
          ...newValue
        }
      }
    });
  };

  const handleBlur = (newValue: Partial<FrequencyValue>) => {
    onBlur?.({
      target: {
        value: {
          ...(value || defaultFrequencyValue),
          ...newValue
        }
      }
    });
  };

  const handleCountChange = ({ target }) =>
    handleChange({
      count: target.value
    });

  const handleCountBlur = ({ target }) => {
    // We should never have the count as less than one - the below will happen on blur if an invalid value is entered
    const count =
      target.value === null || target.value === '' || target.value < minCount
        ? minCount
        : target.value;

    handleBlur({
      // We also want to make sure the count is a whole number
      count: Math.floor(count)
    });
  };

  const handleUnitChange = ({ target }) =>
    handleChange({
      unit: target.value
    });

  const handleUnitBlur = ({ target }) =>
    handleBlur({
      unit: target.value
    });

  // On initial render, we want to set the default value
  useEffect(() => {
    if (isEmpty(value)) {
      handleBlur({
        count: minCount,
        unit: defaultUnit
      });
    }
  }, [defaultUnit, minCount, value]);

  return (
    <Box
      width={'auto'}
      flexDirection='row'
      alignItems='center'
      columnGap={token('spacing.m')}
    >
      <Box
        width='100%'
        flexDirection='row'
        alignItems='center'
        columnGap={token('spacing.xs')}
      >
        <NumberInput
          {...props}
          id='count'
          data-testid={'number-input.count'}
          value={
            minCount && (value?.count === null || value?.count === undefined)
              ? minCount
              : value?.count
          }
          onChange={handleCountChange}
          onBlur={handleCountBlur}
        />

        <Select
          {...props}
          id='unit'
          value={
            defaultUnit && (value?.unit === null || value?.unit === undefined)
              ? defaultUnit
              : value?.unit
          }
          items={frequencyUnitItem}
          onChange={handleUnitChange}
          onBlur={handleUnitBlur}
          normaliser={(item) => ({ id: item.id, label: item.label })}
        />
      </Box>

      {inputLabel && (
        <Box width='100%'>
          <Body>{inputLabel}</Body>
        </Box>
      )}
    </Box>
  );
}
