import * as React from 'react';
import { useQueryClient } from 'react-query';

import { DialogProps } from '@rexlabs/dialog';

import {
  RequestPipelineRequest,
  postRequestPipelineRequests
} from 'utils/api/post-request-pipeline';

import { RecordSubmitHandler } from 'view/components/record-screen/utils';
import { RecordDialog } from 'view/components/record-screen/dialog/dialog';
import { useToast } from 'view/components/@luna/notifications/toast';

import {
  ChecklistTemplateSelectBlockFormValues,
  checklistTemplateSelectBlock
} from '../../common/blocks/checklist-template-select-block';
import { Task } from '../../common/types/Task';
import { Checklist } from '../../common/types/Checklist';
import { TaskType } from '../../common/types/TaskType';

interface AddChecklistDialogProps extends DialogProps {
  task: Task<TaskType>;
  onCreate?: (args: any) => any;
}

const content = [
  {
    id: 'add-checklist',
    label: 'Add checklist',
    blocks: [checklistTemplateSelectBlock]
  }
];

export function AddChecklistDialog({
  task,
  onClose,
  onCreate
}: AddChecklistDialogProps) {
  const { addToast } = useToast();
  const queryClient = useQueryClient();

  const handleSubmit: RecordSubmitHandler<ChecklistTemplateSelectBlockFormValues> = React.useCallback(
    async ({ values }) => {
      const checklistRequests: RequestPipelineRequest[] = (
        values?.checklists || []
      ).map((checklist) => ({
        path: `/api/v1/tasks/checklist-templates/${checklist.id}/instantiate`,
        method: 'POST',
        json: {
          task: {
            id: task.id
          }
        }
      }));

      const pipelineRequests = [...checklistRequests];

      const { data } = await postRequestPipelineRequests<
        typeof pipelineRequests,
        Checklist
      >(pipelineRequests);

      addToast({ description: 'Checklist added to task' });

      await Promise.all([
        queryClient.invalidateQueries(['checklists', task.id]),
        queryClient.invalidateQueries(['checklists-widget', task.id])
      ]);

      onCreate?.(data);

      return data;
    },
    []
  );

  const taskTypeId = task.type.id;

  return (
    <RecordDialog
      title='Add checklist'
      initialValues={{
        checklists: []
      }}
      blockProps={{
        taskTypeId,
        ...(taskTypeId === 'task_inspection'
          ? {
              inspectionType: (task as Task<'task_inspection'>).details?.type.id
            }
          : {})
      }}
      content={content}
      onClose={onClose}
      handleSubmit={handleSubmit}
    />
  );
}
