import * as React from 'react';
import { TagConfig } from 'view/components/@luna/title-block';
import { ProgressDropdown } from 'src/modules/tasks/common/components/progress-dropdown';
import { Task } from '../types/Task';

// gotta be memo'd otherwise we get infinite rerenders
export function useTaskStatusTag(
  task?: Task<'task_move_in' | 'task_move_out'>
): TagConfig {
  const Tag = React.useMemo(() => () => <ProgressDropdown value={task} />, [
    task?.status?.id
  ]);

  return {
    label: task?.status?.label || '',
    Tag
  };
}
