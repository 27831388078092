import Box from '@rexlabs/box';
import {
  ButtonGroup,
  GhostButton,
  PrimaryButton,
  GhostIconButton
} from '@rexlabs/button';
import { Heading } from '@rexlabs/text';
import React from 'react';
import EditIcon from 'view/components/icons/edit';

export const ChecklistTemplateItemsBlockCardHeader = ({
  isEditing,
  setIsEditing,
  isSaving
}) => {
  return (
    <Box flexDirection='row' alignItems='center' justifyContent='space-between'>
      <Box flexDirection='row' alignItems='center' sx='1.2rem' flex={1}>
        <Heading level={3}>{'Checklist'}</Heading>

        <Box marginLeft='auto'>
          {isEditing ? (
            <ButtonGroup>
              <GhostButton onClick={() => setIsEditing(false)}>
                Cancel
              </GhostButton>
              <PrimaryButton
                isLoading={isSaving}
                type='submit'
                form={'checklist'}
              >
                Save
              </PrimaryButton>
            </ButtonGroup>
          ) : (
            <span className='edit-button'>
              <GhostIconButton
                aria-label='Edit block'
                Icon={EditIcon}
                onClick={() => {
                  setIsEditing(true);
                }}
              />
            </span>
          )}
        </Box>
      </Box>
    </Box>
  );
};
