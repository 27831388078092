import { USE_DISBURSEMENT_PREFERENCES } from 'view/components/inputs/selects/disbursement-instruction';
import { CreateInvoiceFormValues } from '../dialogs/create-invoice-dialog';
import { CreateReimbursementInvoiceFormValues } from '../dialogs/create-invoice-dialog';

enum DisbursementType {
  General = 'general',
  Specific = 'specific'
}

export function mapInvoiceValuesToPostData(values: CreateInvoiceFormValues) {
  if (
    values.specific_disbursement_payment_method ===
    USE_DISBURSEMENT_PREFERENCES.id
  ) {
    values.specific_disbursement_payment_method = null;
  }

  return {
    send_email: !!values?.send_invoice,
    payable_to: {
      object: {
        type: values.payable_to.object.type,
        id: values.payable_to.object.id
      }
    },
    payable_by: {
      object: {
        type: values.payable_by.object.type,
        id: values.payable_by.object.id
      }
    },
    disbursement_type: {
      id:
        values.specific_disbursement_payment_method === null
          ? DisbursementType.General
          : DisbursementType.Specific
    },
    specific_disbursement_payment_method:
      values.specific_disbursement_payment_method,
    due_date: values.due_date,
    do_not_pay_before_date: values.do_not_pay_before_date,
    invoice_date: values.invoice_date,
    is_tax_included: values.is_tax_included,
    line_items: values.line_items,
    description: values.description,
    bank_account: values.bank_account,
    bill_reference: values.bill_reference,
    reimbursement_for_invoice: values.reimbursement_for_invoice,
    task: values.task,
    uploaded_bill: values.uploaded_bill,
    bill_priority: values.bill_priority,
    notes: values.notes
  };
}

export function mapReimbursementInvoiceValuesToPostData(
  values: CreateReimbursementInvoiceFormValues
) {
  if (
    values.reimbursed_specific_disbursement_payment_method ===
    USE_DISBURSEMENT_PREFERENCES.id
  ) {
    values.reimbursed_specific_disbursement_payment_method = null;
  }

  return {
    send_email: !!values?.send_reimbursement_invoice,
    payable_by: {
      object: {
        type: values.reimbursed_by.object.type,
        id: values.reimbursed_by.object.id
      }
    },
    payable_to: {
      object: {
        type: values.reimbursed_to.object.type,
        id: values.reimbursed_to.object.id
      }
    },
    disbursement_type: {
      id:
        values.reimbursed_specific_disbursement_payment_method === null
          ? DisbursementType.General
          : DisbursementType.Specific
    },
    specific_disbursement_payment_method:
      values.reimbursed_specific_disbursement_payment_method,
    reimbursement_for_invoice: values.reimbursement_for_invoice,
    due_date: values.reimbursed_due_date,
    do_not_pay_before_date: values.reimbursed_do_not_pay_before_date,
    invoice_date: values.reimbursed_invoice_date,
    is_tax_included: values.reimbursed_is_tax_included,
    line_items: values.reimbursed_line_items,
    description: values.reimbursed_description,
    bill_reference: values.reimbursed_bill_reference,
    bank_account: values.reimbursed_bank_account,
    bill_priority: values.reimbursed_bill_priority,
    ...(values.uploaded_bill ? { uploaded_bill: values.uploaded_bill } : {}),
    notes: values.reimbursed_notes
  };
}
