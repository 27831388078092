import { useBulkModelDownloadAction } from 'src/modules/common/actions/reporting/use-bulk-model-download-action';
import { useModelActions } from '@rexlabs/model-generator';
import { appliancesModel } from 'src/modules/appliances/models/appliances-model';
import { invokeActionDeclaration } from 'src/modules/common/actions/utils/invoke-action-declaration';

interface UseGetDownloadApplianceManualsParams {
  applianceId?: string;
  manuals?: any[];
}

export function useGetDownloadApplianceManuals({
  applianceId,
  manuals
}: UseGetDownloadApplianceManualsParams) {
  const modelBulkDownloadAction = useBulkModelDownloadAction();
  const { bulkDownloadManuals } = useModelActions(appliancesModel);

  if (!manuals?.length || !applianceId) {
    return;
  }

  return () =>
    invokeActionDeclaration(modelBulkDownloadAction, {
      fetcher: () =>
        bulkDownloadManuals({
          data: manuals?.map((doc) => {
            return { id: doc.id, type: doc.type };
          }),
          applianceId: applianceId
        }),
      label: 'Download all'
    });
}
