import React from 'react';
import { toLower } from 'lodash';
import { TextArea, TextInput } from '@rexlabs/text-input';
import { Field } from '@rexlabs/form';
import { Box } from '@rexlabs/box';
import { BlockConfig } from 'view/components/record-screen/types';
import { Grid, Column } from 'view/components/@luna/form/grid';
import InfoIcon from 'view/components/icons/info';
import { Banner } from 'src/modules/common/components/banner';
import { Checkbox } from '@rexlabs/checkbox';
import { useTaskTypesWithCustomAddToRecord } from '../hooks/use-task-types-with-custom-add-to-record';

const validate = {
  definitions: {
    description: { rules: 'required' },
    label: { rules: 'required' }
  }
};

export const upsertChecklistDetailsBlock: BlockConfig = {
  id: 'details',
  title: 'Details',
  validate,
  Edit: ({ blockProps }) => {
    const taskTypesWithCustomAddToRecord = useTaskTypesWithCustomAddToRecord();
    const showDefaultCheckbox = taskTypesWithCustomAddToRecord.includes(
      blockProps?.taskType?.id
    );

    return (
      <>
        <Grid columns={2}>
          <Field name='label' label='Checklist name' Input={TextInput} />

          <Column width={2}>
            <Field
              name='description'
              label='Description'
              Input={TextArea}
              inputProps={{
                placeholder: 'Describe when this checklist should be used...'
              }}
            />
          </Column>

          {showDefaultCheckbox && (
            <Field
              name='is_default'
              label=''
              Input={Checkbox}
              inputProps={{
                label: `Use as default for new ${toLower(
                  blockProps.taskType.label
                )} tasks`
              }}
            />
          )}
        </Grid>

        {blockProps.isEditMode && (
          <Box marginTop={20}>
            <Banner intent={'information'} align='start' Icon={InfoIcon}>
              Changes made to this checklist will be applied to new tasks only.
            </Banner>
          </Box>
        )}
      </>
    );
  }
};
