import { useCallback } from 'react';

import { useErrorDialog } from '@rexlabs/dialog';
import { useModelActions } from '@rexlabs/model-generator';

import { useToast } from 'view/components/@luna/notifications/toast';
import { RecordSubmitHandler } from 'view/components/record-screen/utils';

import { useQueryClient } from 'react-query';
import { bankStatementTransactionsModel } from '../models/bank-statement-transaction-model';
import { UploadBankTransactionsFormValues } from '../types';
import { useBatchReceiptingStatsSummary } from './use-batch-receipting-stats-summary';
import { useBankAccountState } from './use-bank-account-state';
import { bankStatementTransactionsQueryKey } from './use-bank-statement-transactions-query';

export const useImportBankTransactions = () => {
  const { importBankTransactions } = useModelActions(
    bankStatementTransactionsModel
  );
  const queryClient = useQueryClient();

  const { open: openErrorDialog } = useErrorDialog();

  const { addToast } = useToast();

  const {
    bankAccount: { id: bankAccountId }
  } = useBankAccountState();

  const { refetch: refetchStats } = useBatchReceiptingStatsSummary(
    bankAccountId
  );

  const onHandleSubmit: RecordSubmitHandler<UploadBankTransactionsFormValues> = useCallback(
    async ({ values }) => {
      const { upload_bank_transactions_file } = values;
      if (!upload_bank_transactions_file?.data) return;

      const { data: bankTransaction } = await Promise.resolve(
        upload_bank_transactions_file?.data
      );

      try {
        const importedData = await importBankTransactions({
          fileId: bankTransaction.id,
          bankAccountId
        });

        await queryClient.refetchQueries({
          queryKey: [bankStatementTransactionsQueryKey],
          refetchPage: () => true
        });

        await refetchStats?.();

        addToast({
          description: `Bank statement file ${upload_bank_transactions_file.file.name} is being processed`
        });

        return importedData;
      } catch (error) {
        console.error(error);
        openErrorDialog(error);
      }
    },
    [
      addToast,
      bankAccountId,
      importBankTransactions,
      openErrorDialog,
      queryClient
    ]
  );

  return { onHandleSubmit };
};
