import React, { useEffect } from 'react';
import { useQueryClient } from 'react-query';

import {
  Invoice,
  InvoiceLineItem
} from 'data/models/entities/financials/invoices';
import { RecordDialog } from 'view/components/record-screen/dialog/dialog';

import { Ownership } from 'data/models/entities/ownerships';
import { Tenancy } from 'data/models/entities/tenancies';
import { BILL_PROCESSING_FLAGS } from 'src/modules/bill-processing/feature-flags';
import {
  chartOfAccountsQueryKey,
  fetchAllChartOfAccountsAccounts
} from 'src/modules/financials/hooks/use-chart-of-accounts-accounts';
import { SearchResultItem } from 'utils/api/get-search-results';
import { getToday } from 'utils/dates/dates';
import { AWAITING_PAYMENT_INVOICE_STATUS } from 'utils/static-value-lists/invoice-status';
import { useFeatureFlags } from 'view/components/@luna/feature-flags';
import { CreateRecordDialog } from 'view/components/dialogs/create-record-dialog/create-record-dialog';
import { useDefaultBankAccount } from 'view/hooks/api/use-default-bank-account';
import {
  InvoiceBillFormValues,
  invoiceViewerBlock
} from '../blocks/invoice-viewer';
import { InvoiceDetailsFormValues } from '../components/invoice-details-form';
import { useInvoiceSubmitHandler } from '../hooks/use-invoice-submit-handler';
import { invoiceTabs } from '../blocks/tab-block';
import { invoiceDetailsBlock } from '../blocks/invoice-details';
import {
  InvoiceLineItemsFormValues,
  invoiceLineItemsBlock
} from '../blocks/invoice-line-items';
import {
  ReimbursementInvoiceDetailsFormValues,
  reimbursementDetailsBlock
} from '../blocks/reimbursement-details';
import {
  ReimbursementInvoiceLineItemsFormValues,
  reimbursementLineItemsBlock
} from '../blocks/reimbursement-line-items';

export type CreateInvoiceFormValues = InvoiceDetailsFormValues &
  InvoiceLineItemsFormValues &
  InvoiceBillFormValues;

export type CreateReimbursementInvoiceFormValues = ReimbursementInvoiceDetailsFormValues &
  ReimbursementInvoiceLineItemsFormValues &
  InvoiceBillFormValues;

export type InitialInvoiceValues = Partial<
  Pick<
    Invoice,
    | 'payable_by'
    | 'payable_to'
    | 'reimbursement_for_invoice'
    | 'description'
    | 'due_date'
    | 'task'
  > & { line_items?: Partial<InvoiceLineItem>[] }
>;

export interface CreateInvoiceDialogProps {
  initialValues?: InitialInvoiceValues;
  suggestions?: {
    payableTo?: Array<SearchResultItem<Ownership | Tenancy>>;
    payableBy?: Array<SearchResultItem<Ownership | Tenancy>>;
  };
  maintenanceJobId?: string;
  onSave?: (data?: Invoice) => any;
  isLoading?: boolean;
  onClose?: () => void;
}

export function CreateInvoiceDialog({
  initialValues: externalInitialValues,
  suggestions = {},
  onSave,
  isLoading,
  onClose
}: CreateInvoiceDialogProps) {
  const queryClient = useQueryClient();

  const { hasFeature } = useFeatureFlags();

  const hasBillProcessingFeature = hasFeature(
    BILL_PROCESSING_FLAGS.BILL_PROCESSING
  );

  const isFirstChangeRef = React.useRef(false);
  const [activeTab, setActiveTab] = React.useState<'invoice' | 'reimbursement'>(
    'invoice'
  );

  const [
    isReimbursementEmptyStateVisible,
    setIsReimbursementEmptyStateVisible
  ] = React.useState(true);

  const content = [
    {
      id: 'basics',
      label: 'Basics',
      blocks: [
        invoiceTabs,
        invoiceDetailsBlock,
        invoiceLineItemsBlock,
        reimbursementDetailsBlock,
        reimbursementLineItemsBlock
      ],
      ...(hasBillProcessingFeature ? { leftBlocks: [invoiceViewerBlock] } : {})
    }
  ];

  // When we open the invoice dialog, we want to prefetch the chart of accounts accounts, in case the user
  // adds a line item to the invoice, that needs these items. As we're using react query here, the
  // data will be cached, so we don't need to worry about making a request to the server again.
  // If the we do end up needing the list of accounts, they will be ready to go in the select.
  // There may be other places where we want to prefetch this data, but for now, this seems like the logical place.
  useEffect(() => {
    queryClient.prefetchQuery({
      queryKey: chartOfAccountsQueryKey,
      queryFn: fetchAllChartOfAccountsAccounts,
      staleTime: Infinity,
      cacheTime: Infinity
    });
  }, []);

  const {
    defaultBankAccount,
    isLoading: isDefaultBankLoading
  } = useDefaultBankAccount();

  const handleFirstChange = React.useCallback(() => {
    isFirstChangeRef.current = true;
  }, []);

  const handleReimbursementEmptyState = React.useCallback((state: boolean) => {
    setIsReimbursementEmptyStateVisible(state);
  }, []);

  const initialValues = {
    status: AWAITING_PAYMENT_INVOICE_STATUS,
    is_tax_included: true,
    reimbursed_is_tax_included: true,
    invoice_date: getToday(),
    reimbursed_invoice_date: getToday(),
    bill_priority: {
      id: 'normal',
      label: 'Normal'
    },
    bank_account: defaultBankAccount,
    reimbursed_bank_account: defaultBankAccount,
    reimbursed_bill_priority: {
      id: 'normal',
      label: 'Normal'
    },
    reimbursed_to: externalInitialValues?.payable_by,
    ...externalInitialValues
  };

  const handleSubmit = useInvoiceSubmitHandler({ onSave });

  const title = initialValues?.reimbursement_for_invoice
    ? 'Create reimbursement invoice'
    : 'Create invoice';

  return hasBillProcessingFeature ? (
    <CreateRecordDialog
      recordType='invoice'
      onClose={onClose}
      title={title}
      data={initialValues}
      isLoading={isLoading || isDefaultBankLoading}
      content={content}
      size={'xl'}
      blockProps={{
        hasBillProcessingFeature,
        suggestions,
        activeTab,
        setActiveTab,
        isReimbursementEmptyStateVisible,
        handleReimbursementEmptyState,
        isFirstChange: isFirstChangeRef.current,
        handleFirstChange: handleFirstChange,
        isInvoiceDialog: true
      }}
      onSubmit={handleSubmit}
      buttonGroupProps={{ timeToWaitBeforeRedirect: 800 }}
    />
  ) : (
    <RecordDialog
      onClose={onClose}
      title={title}
      handleSubmit={handleSubmit}
      data={initialValues}
      isLoading={isLoading || isDefaultBankLoading}
      content={content}
      size={'l'}
      blockProps={{
        hasBillProcessingFeature,
        suggestions,
        activeTab,
        setActiveTab,
        isReimbursementEmptyStateVisible,
        handleReimbursementEmptyState,
        isFirstChange: isFirstChangeRef.current,
        handleFirstChange: handleFirstChange,
        isInvoiceDialog: true
      }}
    />
  );
}
