import * as React from 'react';
import { RecordDialog } from 'view/components/record-screen/dialog/dialog';
import { DialogProps, useDialog } from '@rexlabs/dialog';
import { Message } from '../types/Message';
import { letterPreviewBlock } from '../blocks/letter-preview-block';
import { EditLetterRecordDialog } from './edit-letter-record-dialog';

export interface PreviewLetterRecordDialogProps extends DialogProps {
  data: {
    message: Message;
  };
  onSave?: () => void;
  hasButtonGroupActions?: boolean;
}

const content = [
  {
    id: 'preview-letter-record-dialog',
    label: 'Preview Letter Record Dialog',
    blocks: [letterPreviewBlock]
  }
];

export function PreviewLetterRecordDialog({
  hasButtonGroupActions,
  onClose,
  onSave,
  data
}: PreviewLetterRecordDialogProps) {
  const { open: openEditDialog } = useDialog(EditLetterRecordDialog);
  const handleSubmit = async () => {
    openEditDialog({
      hasButtonGroupActions,
      data: {
        message: data.message
      },
      onSave
    });

    onClose?.();
  };

  return (
    <RecordDialog
      title='Preview letter'
      data={data.message}
      content={content}
      onClose={onClose}
      handleSubmit={handleSubmit}
      submitLabel='Edit letter'
    />
  );
}
