import React from 'react';
import { BlockConfig } from 'view/components/record-screen/types';
import { Grid, Column } from '@rexlabs/grid';
import { Value } from 'view/components/values';
import { Field } from '@rexlabs/form';
import { TimezoneSelect } from 'view/components/inputs/selects/timezone-select';
import { Settings } from '../../hooks/useSettings';

export const localisationSettingsBlock: BlockConfig<Settings> = {
  id: 'localisation-settings',
  title: 'Localisation settings',
  validate: {
    definitions: {
      timezone: { rules: 'required' }
    }
  },
  View: ({ data }) => {
    return (
      <Grid>
        <Column width={4}>
          <Value label='Timezone' value={data?.timezone} />
        </Column>
      </Grid>
    );
  },
  Edit: () => {
    return (
      <Grid>
        <Column width={4}>
          <Field
            id='timezone-select'
            label='Timezone'
            name='timezone'
            Input={TimezoneSelect}
          />
        </Column>
      </Grid>
    );
  }
};
