import { DialogProps } from '@rexlabs/dialog';
import { useModelActions } from '@rexlabs/model-generator';
import * as React from 'react';
import { useToast } from 'view/components/@luna/notifications/toast';
import { RecordDialog } from 'view/components/record-screen/dialog/dialog';
import {
  FixMessageIssuesBlockProps,
  fixMessageIssuesBlock
} from 'src/modules/communications/messages/blocks/fix-message-issues-block';
import { messagesModel } from '../models/messages-model';

export interface FixIssuesRecordDialogProps extends DialogProps {
  data: FixMessageIssuesBlockProps;
  onSubmit?: () => void;
  submitLabel?: string;
  onCancel?: () => void;
}

const content = [
  {
    id: 'fix-message-contact-issues',
    label: 'Fix contact issues',
    blocks: [fixMessageIssuesBlock]
  }
];

export function FixMessageIssuesRecordDialog({
  onClose,
  onSubmit,
  onCancel,
  submitLabel = 'Proceed',
  data
}: FixIssuesRecordDialogProps) {
  const { refreshLists } = useModelActions(messagesModel);
  const { addToast } = useToast();

  const handleSubmit = async () => {
    onSubmit?.();
    refreshLists();
    addToast({
      description: <>Issues have been fixed</>
    });

    onClose?.();
  };

  return (
    <RecordDialog
      title='Fix message issues'
      submitLabel={submitLabel}
      data={data}
      content={content}
      onClose={() => {
        onCancel?.();
        onClose?.();
      }}
      handleSubmit={handleSubmit}
    />
  );
}
