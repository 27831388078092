import { ValueListValue } from 'data/models/types';
import { useMemo } from 'react';
import { ComplianceEntry } from 'src/modules/compliance/common/models/compliance-entries';
import { PropertyOwnership } from 'src/modules/property-ownerships/types/property-ownership-types';
import {
  PropertyTenancy,
  RentScheduleEntry
} from 'src/modules/property-tenancies/types/property-tenancy-types';
import { getRentScheduleWithIds } from 'src/modules/property-tenancies/utils/get-rent-schedule-with-ids';
import { parseUploadedFileMeta } from 'utils/files';
import { useDefaultBankAccount } from 'view/hooks/api/use-default-bank-account';
import { AddressCountry, PropertiesModel } from '../types/property-types';

const getPropertyTenancyInitialValues = (
  propertyTenancy: PropertyTenancy | null
): ({ rent_schedule: RentScheduleEntry[] } & PropertyTenancy) | null => {
  if (!propertyTenancy) {
    return null;
  }

  // HACK: this is ensuring the related rent schedule exists, even if we get `null` from the BE
  // Rn model generator doesn't denormalise `null` for 1:n relations
  return {
    ...propertyTenancy,
    rent_schedule: getRentScheduleWithIds(propertyTenancy?.rent_schedule),
    term_length:
      propertyTenancy?.term_length !== null
        ? propertyTenancy?.term_length
        : { id: 'custom', label: 'Custom' }
  };
};

export function usePropertyDetailsInitialData(
  property: PropertiesModel | undefined,
  selectedPropertyTenancy: PropertyTenancy | null,
  selectedPropertyOwnership: PropertyOwnership | null,
  entryData: ComplianceEntry[] | undefined
) {
  const { defaultBankAccount } = useDefaultBankAccount();

  const address = property?.address;

  const virtualAddressProperties = {};
  (address?.areas || []).forEach((area) => {
    virtualAddressProperties[area.type.id] = area.value;
  });

  const defaultCountry: ValueListValue<AddressCountry> | undefined =
    address?.country || defaultBankAccount?.country;

  return useMemo(() => {
    return {
      ...property,
      address: {
        ...address,
        ...virtualAddressProperties,
        country: defaultCountry
      },
      compliance_categories: property?.compliance_categories,
      document_uploads: [],
      images: property?.images ? parseUploadedFileMeta(property?.images) : [],

      active_property_tenancy: getPropertyTenancyInitialValues(
        property?.active_property_tenancy || null
      ),
      active_property_ownership: property?.active_property_ownership || null,
      selected_property_ownership: selectedPropertyOwnership || null,
      selected_property_tenancy: getPropertyTenancyInitialValues(
        selectedPropertyTenancy
      ),
      incoming_property_ownership:
        property?.incoming_property_ownership || null,
      incoming_property_tenancy: property?.incoming_property_tenancy || null,
      compliance_entries: entryData
    };
  }, [property, selectedPropertyTenancy, selectedPropertyOwnership, entryData]);
}
