import { makeIconComponent } from '@rexlabs/icons';

import MediumReconciliationSvg from 'assets/icons/m/reconciliation.svg';
import LargeReconciliationSvg from 'assets/icons/l/reconciliation.svg';
import ExtraLargeReconciliationSvg from 'assets/icons/xl/reconciliation.svg';

export const ReconciliationIcon = makeIconComponent({
  m: MediumReconciliationSvg,
  l: LargeReconciliationSvg,
  xl: ExtraLargeReconciliationSvg
});

export default ReconciliationIcon;
