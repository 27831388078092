import { query } from '@rexlabs/model-generator';
import { portfoliosModel } from '../models/portfolios-model';

export const getPortfolioDetailsQuery = (portfolioId: string) => {
  return query`{
    ${portfoliosModel} (id: ${portfolioId}) {
      id
      label
      members {
        user {
          contact
        }
      }
      task_type_assignments {
        contact
      }
      summary
    }
  }`;
};
