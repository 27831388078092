import { useSessionState } from 'src/modules/common/hooks/session-state';

import { ContentConfig } from 'view/components/record-screen/types';

import { useSharedPendingContent } from './use-shared-pending-content';

export const usePendingContactDisbursementContent: () => ContentConfig = () => {
  const sessionData = useSessionState();

  const {
    overview,
    billsAndFees,
    trustJournalPreview,
    directDebitInvoices,
    bpayInvoices,
    otherInvoices,
    disbursementMethod
  } = useSharedPendingContent('contact');

  return [
    {
      label: 'General',
      items: [overview, billsAndFees, trustJournalPreview]
    },
    {
      label: 'Specific invoice payouts',
      items: [
        directDebitInvoices,
        ...(sessionData?.activeSilo?.country?.id === 'AUS'
          ? [bpayInvoices]
          : []),
        otherInvoices
      ]
    },
    {
      label: 'Contact',
      items: [disbursementMethod]
    }
  ];
};
