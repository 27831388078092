import React from 'react';

import { TitleBlockProps } from 'view/components/@luna/title-block';

import { TitleBlock } from 'view/components/cards/title/title-block';

import { RecordObject, RecordType } from 'data/models/types';

import { getRecordTitle } from 'utils/records/get-record-title';
import {
  BankDeposit,
  BankDepositStatus
} from 'src/modules/banking/bank-deposits/types/bank-deposit-types';
import { titleBlockStatusTag } from 'src/modules/common/components/status-tag';
import { bankDepositStatusMap } from 'src/modules/banking/bank-deposits/models/bank-deposit-model';
import { useBankDepositActions } from 'src/modules/banking/bank-deposits/actions/use-bank-deposit-actions';

const type: RecordType = 'bank_deposit';

type BankDepositTitleBlockProps = {
  bankDeposit: BankDeposit;
};

export function BankDepositTitleBlock({
  bankDeposit
}: BankDepositTitleBlockProps) {
  const { status } = bankDeposit;
  const actions = useBankDepositActions();

  const tags: TitleBlockProps['tags'] = [];
  tags.push(
    titleBlockStatusTag<BankDepositStatus>(status, bankDepositStatusMap)
  );

  const title = getRecordTitle({ type, object: bankDeposit } as RecordObject);

  return (
    <TitleBlock
      title={title}
      type={type}
      tags={tags}
      actions={actions(bankDeposit)}
      reference={bankDeposit.record_reference}
    />
  );
}
