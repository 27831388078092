import { PendingOwnershipDisbursement } from 'data/models/entities/financials/pending-disbursements/pending-ownership-disbursement';
import React from 'react';
import { PendingContactDisbursement } from 'data/models/entities/financials/pending-disbursements/pending-contact-disbursement';
import { Message } from 'view/components/@luna/message';
import { createStaticTableBlock } from 'view/components/record-screen/cards/create-static-table-block';
import { Invoice } from 'data/models/entities/financials/invoices';
import { disbursementInvoiceColumns } from '../utils/disbursement-invoice-columns';
import { BpayCustomerReferenceNumberCell } from '../components/bpay-reference-number-cell';
import EmptyTrustJournalEntriesTable from '../../../assets/illustrations/empty-trust-journal-entries-table.svg';

export const bpayInvoicesBlock = createStaticTableBlock<
  PendingOwnershipDisbursement | PendingContactDisbursement,
  Invoice
>({
  id: 'bpayDebitInvoices',
  title: 'BPAY invoices',
  getTableProps: ({ data }) => ({
    id: 'bpayDebitInvoices',
    items: data?.disburseable_bpay_invoices?.data ?? [],
    columns: [
      ...disbursementInvoiceColumns,
      {
        id: 'ibpay-customer-reference-number',
        accessor: (item) => item,
        Header: 'BPAY customer ref. no.',
        Cell: BpayCustomerReferenceNumberCell
      }
    ]
  }),
  Empty: () => (
    <Message
      Illustration={EmptyTrustJournalEntriesTable}
      title='There are no BPAY invoices at this moment'
    >
      You have no BPAY invoices that require your attention at this moment.
    </Message>
  )
});
