import React, { useCallback, useMemo } from 'react';
import { SelectProps, Autocomplete } from '@rexlabs/select-old';

import { api } from 'utils/api/api-client';

import { normaliseOwnership } from 'utils/normaliser/ownership';
import { CreateOwnershipDialog } from 'src/modules/ownerships/dialogs/create-ownership-dialog';
import { useDialog } from '@rexlabs/dialog';
import { FieldActions } from '@rexlabs/form';

export type OwnershipSelectProps = Omit<SelectProps, 'items'> & {
  actions?: FieldActions;
};

/**
 * @deprecated Use v4-selects/ownership-select instead
 */
export function OwnershipSelect({
  actions,
  fixtures: overrideFixtures,
  ...props
}: OwnershipSelectProps) {
  const ownershipDialog = useDialog(CreateOwnershipDialog);

  const fixtures = useMemo(
    () => [
      {
        label: 'Add ownership',
        action: () =>
          ownershipDialog.open({
            onCreate: (ownership) => actions?.setValue(ownership)
          })
      }
    ],
    [actions, ownershipDialog]
  );

  const items = useCallback(async (term) => {
    const ownerships = await api.get(`/ownerships`, {
      // TODO: [AL-459] fix the fact that per_page get param breaks includes defined by model generator
      per_page: 30,
      include: 'properties',
      search: term
    });
    return ownerships.data;
  }, []);

  return (
    <Autocomplete
      items={items}
      fixtures={overrideFixtures || fixtures}
      normaliser={normaliseOwnership}
      {...props}
    />
  );
}
