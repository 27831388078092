import { push } from '@rexlabs/whereabouts';
import ROUTES from 'routes/app';
import { SingleActionDeclaration } from 'src/modules/common/actions/types/action-declaration-types';

export function useViewStatementsAction({
  bankAccountId
}: {
  bankAccountId: string;
}): SingleActionDeclaration {
  return {
    id: 'view-statements',
    label: 'View statements',
    handleAction: async () => {
      push(ROUTES.BATCH_RECEIPTING_STATEMENTS, {
        query: { bankAccount: bankAccountId }
      });
    }
  };
}
