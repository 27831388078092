import React, { useMemo } from 'react';
import { useSessionState } from 'src/modules/common/hooks/session-state';
import { useGetAppWideFilterQueryParams } from 'src/modules/app-wide-filters/hooks/use-get-app-wide-filter-params';
import invariant from 'invariant';
import { merge, pick } from 'lodash';
import { columnTypes } from 'view/components/table/columns/column-types';
import { GlobalFilter } from '@rexlabs/table/lib/types/context';
import { useUrlState } from '@rexlabs/table';
import { useListTablePersistFilters } from './use-list-table-persist-filter';

export function useListTableLogic({
  id,
  hashParamKey,
  pageSizes,
  columns,

  shouldPersistTableSettings,
  initialSortBy: propInitialSortBy,
  initialGlobalFilter: propInitialGlobalFilter,
  initialPageSize: propInitialPageSize,
  initialPage: propInitialPage,
  initialHiddenColumns: propInitialHiddenColumns,

  blacklistTableSettingKeys
}) {
  const persistTableSettings = useListTablePersistFilters({
    shouldPersistTableSettings
  });

  const sessionData = useSessionState();
  const userId = sessionData.user!.id;
  const siloId = sessionData.activeSilo!.id;

  const appWideFilterParams = useGetAppWideFilterQueryParams();

  const tabId = `table/${id}/${hashParamKey}/tableSettings/${siloId}/${userId}`;
  const tableId = `table/${id}/tabSettings/${siloId}/${userId}`;

  React.useLayoutEffect(() => {
    if (!Array.isArray(blacklistTableSettingKeys)) return;

    const inBlacklist = blacklistTableSettingKeys.some((key) => key === id);

    if (!inBlacklist) return;

    localStorage.removeItem(tabId);
    localStorage.removeItem(tableId);
  }, [blacklistTableSettingKeys, id, tabId, tableId]);

  const [tableState, tabState] = React.useMemo(() => {
    const tabState = JSON.parse(localStorage.getItem(tabId) ?? '{}');
    const tableState = JSON.parse(localStorage.getItem(tableId) ?? '{}');

    if (tabState.globalFilter) {
      tabState.globalFilter = transformFilter(tabState.globalFilter);
    }

    return [tableState, tabState];
  }, [tabId, tableId]);

  let initialSortBy = propInitialSortBy;
  let initialGlobalFilter = propInitialGlobalFilter;
  let initialHiddenColumns = propInitialHiddenColumns;
  let initialPageSize = propInitialPageSize;

  if (persistTableSettings) {
    initialSortBy = tabState?.sortBy ?? initialSortBy;
    initialGlobalFilter = tabState?.globalFilter ?? initialGlobalFilter;
    initialHiddenColumns = tabState?.hiddenColumns ?? initialHiddenColumns;
    initialPageSize = tableState?.pageSize ?? initialPageSize;
  }

  const { getTableProps: getUrlStateProps } = useUrlState({
    hashParamKey,
    initialSortBy,
    initialGlobalFilter,
    initialPageSize,
    initialPage: propInitialPage,
    pageSizes
  });

  const urlStateProps = getUrlStateProps();

  const useControlledState = (state) => {
    const newState = urlStateProps.useControlledState(state);

    React.useEffect(() => {
      if (!persistTableSettings) return;

      try {
        const newTabState = pick(newState, [
          'sortBy',
          'globalFilter',
          'hiddenColumns'
        ]);
        const newTableState = pick(newState, ['pageSize']);

        localStorage.setItem(tableId, JSON.stringify(newTableState));
        localStorage.setItem(tabId, JSON.stringify(newTabState));
      } catch (err) {
        // report err
      }
    }, [newState]);

    return newState;
  };

  const mergedColumns = useMemo(() => {
    return (
      columns?.map((column) => {
        return merge({}, column, column?.type ? columnTypes[column.type] : {});
      }) ?? []
    );
  }, [columns]);

  return {
    useControlledState,
    columns: mergedColumns,
    appWideFilterParams,
    urlStateProps,
    initialGlobalFilter,
    initialHiddenColumns,
    initialPageSize,
    initialSortBy
  };
}

function transformFilter(state) {
  const result: GlobalFilter[] = [];

  if (!state) return result;

  for (const [key, filters] of Object.entries(state)) {
    invariant(Array.isArray(filters), 'filters should be an array');

    for (const filter of filters) {
      result.push({
        field: key,
        op: filter.op,
        value: filter.value
      });
    }
  }

  return result;
}
