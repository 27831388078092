/**
 * TODO: Refactor these routes so they are encapsulated in their respective modules
 */
export const SETTINGS_ROUTES = {
  ACCOUNT_SETTINGS: {
    config: {
      path: '/settings/account',
      Component: require('./account-settings/screens/account-settings')
        .AccountSettings
    }
  }
};
