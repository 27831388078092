import React, { useMemo } from 'react';

import { TableProvider, TableProviderProps } from '@rexlabs/table';
import { ActionMenuItem } from '@rexlabs/action-menu';
import { Model, ModelTypeFromAnyModel } from '@rexlabs/model-generator';
import {
  AlfredColumnConfig,
  columnTypes
} from 'view/components/table/columns/column-types';
import { merge } from 'lodash';

// TODO: Make this not optional and back-port it across usages of the type.
export type Column<T = any> = AlfredColumnConfig<T>;
export type Columns<T = any> = Column<T>[];
type AlfredTableProviderProps<ItemType> = Partial<
  Omit<TableProviderProps<ItemType>, 'columns' | 'getActionMenuItems' | 'items'>
> & {
  items?: ModelTypeFromAnyModel<Model<any>>[] | Model<any>[];
  columns?: Columns<ItemType>;
  getActionMenuItems?: (args: {
    item: any;
    entity?: any;
  }) => ActionMenuItem[] | undefined;
};

function AlfredTableProvider<ItemType>({
  columns,
  ...props
}: AlfredTableProviderProps<ItemType>) {
  const mergedColumns = useMemo(() => {
    return (
      columns?.map((column) => {
        return merge({}, column, column?.type ? columnTypes[column.type] : {});
      }) ?? []
    );
  }, [columns]);

  return <TableProvider {...props} columns={mergedColumns} />;
}

export { AlfredTableProvider as TableProvider };
export type { AlfredTableProviderProps as TableProviderProps };
