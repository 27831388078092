import React from 'react';

import { formatCurrency } from 'utils/formatters';
import { CentAmountInput } from 'view/components/inputs/cent-amount';

const getFormattedEvent = (e) => {
  return {
    ...e,
    target: {
      ...e.target,
      value: {
        label: formatCurrency(e.target.value),
        value: e.target.value
      }
    }
  };
};

export function CurrencyInput({ value, onChange, onBlur, ...props }) {
  return (
    <CentAmountInput
      {...props}
      value={value?.value}
      onChange={(e) => onChange(getFormattedEvent(e))}
      onBlur={(e) => onBlur(getFormattedEvent(e))}
    />
  );
}
