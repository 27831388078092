import React, { useCallback, useMemo } from 'react';

import { DialogContentConfig } from 'view/components/record-screen/dialog/types';

import { RecordSubmitHandler } from 'view/components/record-screen/utils';
import {
  query,
  useEntityQuery,
  useModelActions
} from '@rexlabs/model-generator';
import { filterNullValues } from 'utils/filter-null-values';
import { financialsSecurityDepositsModel } from 'data/models/entities/financials/security-deposits';
import { DialogProps } from '@rexlabs/dialog';
import { propertyTenancyModel } from 'src/modules/property-tenancies/models/property-tenancy-model';
import { useToast } from 'view/components/@luna/notifications/toast';
import { CreateRecordDialog } from 'view/components/dialogs/create-record-dialog/create-record-dialog';
import { securityDepositBlock } from 'src/modules/security-deposits/blocks/security-deposit';

const content: DialogContentConfig = [
  {
    id: 'basics',
    label: 'Basics',
    blocks: [securityDepositBlock]
  }
];

interface CreateSecurityDepositDialogProps extends DialogProps {
  propertyTenancyId: string;
  isFixture?: boolean;
}

const getPropertyTenancyQuery = (id: string) => query`{
  ${propertyTenancyModel} (id: ${id}, include: "property.active_property_ownership.ownership.owners.contact") {
    id
    property {
      active_property_ownership {
        ownership {
          owners
        }
      }
    }
  }
}`;

export function CreateSecurityDepositDialog({
  propertyTenancyId,
  isFixture,
  onClose
}: CreateSecurityDepositDialogProps) {
  const { addToast } = useToast();
  const { createItem, refreshLists } = useModelActions(
    financialsSecurityDepositsModel
  );

  const query = useMemo(() => getPropertyTenancyQuery(propertyTenancyId), [
    propertyTenancyId
  ]);
  const { data, status } = useEntityQuery(query);

  const initialValues = {
    property_tenancy: { id: propertyTenancyId },
    held_by_ownership: data?.property?.active_property_ownership?.ownership,
    amount_type: {
      id: 'amount',
      label: 'Amount'
    }
  };

  const handleSubmit: RecordSubmitHandler = useCallback(
    async ({ values }) => {
      const data = filterFormValues(values);
      const { data: securityDeposit } = await createItem({ data });

      addToast({
        description: 'Successfully added security deposit'
      });
      refreshLists();

      return securityDeposit;
    },
    [addToast, createItem, refreshLists]
  );

  return (
    <CreateRecordDialog
      recordType='security_deposit'
      data={initialValues}
      isLoading={status !== 'loaded'}
      onSubmit={handleSubmit}
      content={content}
      isFixture={isFixture}
      onClose={onClose}
    />
  );
}

const filterFormValues = (values) => {
  const data = {
    ...filterNullValues(values)
  };

  /**
   * If we select the amount type as amount, remove the weeks of rent key and vice versa
   */
  if (data.amount_type.id === 'weeks_of_rent') {
    delete data.amount_expected;
  } else if (data.amount_type.id === 'amount') {
    delete data.amount_expected_as_weeks_of_rent;
  } else {
    throw new Error(`Unhandled amount type ${data.amount_type.id}`);
  }

  return data;
};
