import { AxiosResponse } from 'axios';
import React from 'react';

import Box from '@rexlabs/box';
import { useErrorDialog } from '@rexlabs/dialog';
import {
  FileSelectEvent,
  PreviewFileUploadInput
} from '@rexlabs/file-upload-input';
import { Field, HiddenField } from '@rexlabs/form';
import { useModelActions } from '@rexlabs/model-generator';

import { api } from 'utils/api/api-client';
import { formatCurrency } from 'utils/formatters';

import { InfoBanner } from 'view/components/@luna/notifications/banner';
import InfoCircleIcon from 'view/components/icons/info';
import { BlockConfig } from 'view/components/record-screen/types';

import { HelpBanner } from '../components/help-banner';
import { bankStatementTransactionsModel } from '../models/bank-statement-transaction-model';
import {
  BankTransactionFilePreview,
  UploadBankTransactionsFormValues,
  UploadFile
} from '../types';

function getInfoBannerDescription({ fileName, count, amount }) {
  const fileNameString = <b>{`'${fileName}'`}</b>;
  const countString = <b>{count} transactions</b>;
  const amountString = <b>{formatCurrency(amount)}</b>;
  return (
    <>
      Uploaded {fileNameString} containing {countString} for a total of{' '}
      {amountString}.
    </>
  );
}

export const uploadBankTransactionsBlock: BlockConfig<
  any,
  any,
  UploadBankTransactionsFormValues
> = {
  id: 'uploadBankTransactions',
  validate: {
    definitions: {
      upload_bank_transactions_file: {
        name: 'bank transaction file',
        rules: 'required'
      }
    },
    messages: {
      required: 'Please upload a bank transaction file'
    }
  },
  Edit: ({ values, setValues, setFieldValue }) => {
    const blockValues = values;

    const { previewBankUpload } = useModelActions(
      bankStatementTransactionsModel
    );
    const { open: openErrorDialog } = useErrorDialog();

    async function onFileSelect({ file }: FileSelectEvent) {
      const formData = new FormData();
      formData.append('file', file);

      try {
        const uploadedFile: AxiosResponse<UploadFile> = await api.post(
          '/files',
          formData
        );

        const filePreview: AxiosResponse<BankTransactionFilePreview> = await previewBankUpload(
          uploadedFile.data.id
        );

        setFieldValue?.('bank_transactions_file_preview', filePreview.data);

        return uploadedFile;
      } catch (error) {
        console.error(error);
        openErrorDialog(error);

        return setValues?.({
          upload_bank_transactions_file: '',
          bank_transactions_file_preview: ''
        });
      }
    }

    return (
      <Box flexDirection='column' gap={25}>
        <HelpBanner />

        <Field
          id='file-input'
          Input={PreviewFileUploadInput}
          name='upload_bank_transactions_file'
          inputProps={{ onFileSelect }}
          optional={false}
        />
        <HiddenField name='bank_transactions_file_preview' />
        <div>Supported file formats: QIF, CSV.</div>
        {blockValues?.bank_transactions_file_preview && (
          <InfoBanner
            Icon={InfoCircleIcon}
            description={getInfoBannerDescription({
              fileName: blockValues?.upload_bank_transactions_file?.file?.name,
              amount: blockValues?.bank_transactions_file_preview?.amount,
              count: blockValues?.bank_transactions_file_preview?.count
            })}
          />
        )}
      </Box>
    );
  }
};
