import React from 'react';

import { ValueListValue } from 'data/models/types';
import { InputProps } from '@rexlabs/form';
import { TaskType } from 'src/modules/tasks/common/types/TaskType';
import { Grid } from 'view/components/@luna/form/grid';
import {
  DateInput,
  disablePastDates
} from 'view/components/@luna/inputs/date-input/date-input';
import { ChecklistItemDueDateType } from '../radios/checklist-item-due-date-type';
import { ChecklistItemRelatedDateSelect } from '../selects/v4-selects/checklist-item-related-date-select';
import {
  ChecklistItemOffsetRelativeDueDateInput,
  OffsetRelativeDueDateValue
} from '../checklist-item-offset-relative-due-date-input/checklist-item-offset-relative-due-date-input';

type FrequencyUnitId = 'days' | 'weeks' | 'months';

export const frequencyUnitItem: ValueListValue<FrequencyUnitId>[] = [
  { id: 'days', label: 'Day(s)' },
  { id: 'weeks', label: 'Week(s)' },
  { id: 'months', label: 'Month(s)' }
];

export type DateValue = {
  count: number | null;
  unit: ValueListValue<FrequencyUnitId> | null;
  offset: ValueListValue<string> | null;
  due_date: string | null;
  due_date_type: string | number | undefined;
  related_date: ValueListValue<string> | null;
  offset_related_date: OffsetRelativeDueDateValue | null;
};

interface DateInputProps
  extends Omit<InputProps, 'value' | 'onChange' | 'onBlur'> {
  value?: DateValue;
  onChange?: (e: { target: { value: DateValue } }) => void;
  onBlur?: (e: { target: { value: DateValue } }) => void;
  taskType: TaskType;
  allowSpecificDate: boolean;
}

const defaultDateValue = {
  count: null,
  unit: null,
  offset: null,
  due_date: null,
  due_date_type: 'none',
  related_date: null,
  offset_related_date: null
};

export function ChecklistItemDueDateInput({
  value,
  onChange,
  onBlur,
  taskType,
  allowSpecificDate,
  ...props
}: DateInputProps) {
  const handleChange = (newValue: Partial<DateValue>) => {
    onChange?.({
      target: {
        value: {
          ...(value || defaultDateValue),
          ...newValue
        }
      }
    });
  };

  const handleBlur = (newValue: Partial<DateValue>) => {
    onBlur?.({
      target: {
        value: {
          ...(value || defaultDateValue),
          ...newValue
        }
      }
    });
  };

  const handleCountChange = ({ target }) =>
    handleChange({
      due_date_type: target.value
    });

  const handleCountBlur = ({ target }) =>
    handleBlur({
      due_date_type: target.value
    });

  const handleRelatedDateChange = ({ target }) =>
    handleChange({
      related_date: target.value
    });

  const handleRelatedDateBlur = ({ target }) =>
    handleBlur({
      related_date: target.value
    });

  const handleOffsetRelatedDateChange = ({ target }) =>
    handleChange({
      offset_related_date: target.value
    });

  const handleOffsetRelatedDateBlur = ({ target }) =>
    handleBlur({
      offset_related_date: target.value
    });

  const handleSpecificDateChange = ({ target }) =>
    handleChange({
      due_date: target.value
    });

  const handleSpecificDateBlur = ({ target }) =>
    handleBlur({
      due_date: target.value
    });

  return (
    <>
      <ChecklistItemDueDateType
        {...props}
        id='due_date_type'
        data-testid={'due_date_type'}
        value={value?.due_date_type}
        onChange={handleCountChange}
        onBlur={handleCountBlur}
        orientation='horizontal'
        allowSpecificDate={allowSpecificDate}
      />

      {value?.due_date_type === 'related_date' && (
        <Grid columns={2}>
          <ChecklistItemRelatedDateSelect
            {...props}
            id='related_date'
            data-testid={'related_date'}
            value={value?.related_date}
            taskType={taskType}
            onChange={handleRelatedDateChange}
            onBlur={handleRelatedDateBlur}
          />
        </Grid>
      )}

      {value?.due_date_type === 'offset_related_date' && (
        <ChecklistItemOffsetRelativeDueDateInput
          {...props}
          id='offset_related_date'
          data-testid={'offset_related_date'}
          value={value?.offset_related_date}
          taskType={taskType}
          onChange={handleOffsetRelatedDateChange}
          onBlur={handleOffsetRelatedDateBlur}
        />
      )}

      {value?.due_date_type === 'specific_date' && (
        <Grid columns={2}>
          <DateInput
            value={value?.due_date}
            isOutsideRange={disablePastDates}
            onChange={handleSpecificDateChange}
            onBlur={handleSpecificDateBlur}
            clearable
          />
        </Grid>
      )}
    </>
  );
}
