import { LinkButton } from '@rexlabs/button';
import React from 'react';
import { SingleActionDeclaration } from 'src/modules/common/actions/types/action-declaration-types';

export function LinkButtonCell({
  action
}: {
  action: SingleActionDeclaration;
}) {
  return (
    <LinkButton
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        action.handleAction?.();
      }}
    >
      {action.label}
    </LinkButton>
  );
}
