import { AxiosRequestConfig, AxiosResponse } from 'axios';
import { useErrorDialog } from '@rexlabs/dialog';
import { api } from 'utils/api/api-client';

export type Method =
  | 'get'
  | 'GET'
  | 'delete'
  | 'DELETE'
  | 'head'
  | 'HEAD'
  | 'options'
  | 'OPTIONS'
  | 'post'
  | 'POST'
  | 'put'
  | 'PUT'
  | 'patch'
  | 'PATCH'
  | 'purge'
  | 'PURGE'
  | 'link'
  | 'LINK'
  | 'unlink'
  | 'UNLINK';
export function useApiClient() {
  const { open: openErrorDialog } = useErrorDialog();

  return async function <Data = any>(
    method: Method,
    url: string,
    // eslint-disable-next-line @typescript-eslint/ban-types
    payload: object | null = null,
    params: Partial<AxiosRequestConfig> | null = null
  ): Promise<AxiosResponse<Data> | false> {
    try {
      const res = await api.request({
        url: url,
        method: method,
        data: payload,
        ...params
      });

      // Rex api client isn't typed with the 'features' they have added.
      // @ts-ignore
      if (res.ok) {
        return res;
      } else {
        /*
         * Errors with a 'message' do not trigger the 'res.problem' as such the api client does not throw...
         * Handle those errors here (i.e. errors from PublicValidationExceptions and not form request errors)
         */
        openErrorDialog({
          message: res.data?.message,
          errors: res.data?.errors
        });
        return false;
      }
    } catch (e) {
      //Handle the 422 errors (errors from form requests)
      openErrorDialog(e);
      return false;
    }
  };
}
