import React from 'react';
import { DialogProps } from '@rexlabs/dialog';

import { RecordDialog } from 'view/components/record-screen/dialog/dialog';
import { DialogContentConfig } from 'view/components/record-screen/dialog/types';

import { RecordSubmitHandler } from 'view/components/record-screen/utils';
import { api } from 'utils/api/api-client';
import { useToast } from 'view/components/@luna/notifications/toast';
import { changeEmailBlock } from '../blocks/change-email';

export const content: DialogContentConfig = [
  {
    id: 'change-email',
    label: 'Change email',
    blocks: [changeEmailBlock]
  }
];

export function ChangeEmailDialog({ onClose }: DialogProps) {
  const { addToast } = useToast();

  const handleSubmit: RecordSubmitHandler = async ({ values }) => {
    try {
      const { data } = await api.post('/me/change-email', {
        email: values.email,
        password: values.password,
        redirect_uri: window.location.origin + '/confirm-email'
      });

      addToast({
        description: 'Email has sent. Please verify your new email address'
      });
      onClose?.();
      return data;
    } catch (e) {
      addToast({
        description: 'Changing email was unsuccessful.',
        type: 'error'
      });
    }
  };

  return (
    <RecordDialog
      onClose={onClose}
      title='Change email'
      content={content}
      handleSubmit={handleSubmit}
    />
  );
}
