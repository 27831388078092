import React from 'react';
import { BlockConfig } from 'view/components/record-screen/types';
import { Field } from '@rexlabs/form';
import { TextArea } from '@rexlabs/text-input';
import { Grid } from 'view/components/@luna/form/grid';
import { MessageTemplateSelectWithEdit } from '../components/message-template-select-with-edit';
import { CreateQuoteFormDetailsData } from '../../quotes/types/CreateQuoteFormData';

export type MessageWithReasonBlockProps = {
  status_reason: string;
  message: CreateQuoteFormDetailsData['message'];
};

export const messageWithReasonBlock: BlockConfig = {
  id: 'message-with-reason',
  validate: {
    definitions: {
      'message.template': { name: 'message template', rules: 'required' }
    }
  },
  Edit: ({ blockProps }) => {
    return (
      <>
        <Grid columns={1}>
          <Field
            name='status_reason'
            label={blockProps.reasonLabel || 'Include notes in message'}
            Input={TextArea}
          />
          <Field
            id='message'
            name='message'
            label='Message template'
            optional={false}
            Input={MessageTemplateSelectWithEdit}
            inputProps={{
              contextType: blockProps.messageContextType
            }}
          />
        </Grid>
      </>
    );
  }
};
