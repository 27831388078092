export const SUPPLIER_COMPLIANCE_TASK_ROUTES = {
  SUPPLIER_COMPLIANCE_TASKS_LIST: {
    config: {
      path: '/supplier-compliance-tasks',
      Component: require('./screens/supplier-compliance-list.tsx')
        .SupplierComplianceList
    }
  },
  SUPPLIER_COMPLIANCE_TASKS_DETAILS: {
    config: {
      path: '/supplier-compliance-task/:taskId',
      Component: require('./screens/supplier-compliance-details.tsx')
        .SupplierComplianceDetails
    }
  }
};
