import Validator from '@rexlabs/validator';

function requiredIfNeedsReason(value: {
  needs_reason?: boolean;
  reason?: string;
}): boolean {
  return !(value.needs_reason && !value.reason);
}

Validator.register(
  'requiredIfNeedsReason',
  requiredIfNeedsReason,
  'The reason field is required.'
);
