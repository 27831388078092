import { SingleActionDeclaration } from '../types/action-declaration-types';

/**
 * A function to invoke the handler of an action declaration without needing to manually unpack it.
 * ```
 * invokeActionDeclaration(getSendMessageAction, message);
 * invokeActionDeclaration(getActionWithManyArgs, 'foo', 'bar', 'baz');
 * ```
 */
export async function invokeActionDeclaration<TGetterArgs extends any[]>(
  getActionDeclaration: (...args: TGetterArgs) => SingleActionDeclaration,
  ...getterArgs: TGetterArgs
) {
  const { handleAction } = getActionDeclaration(...getterArgs);
  await handleAction?.();
}
