import { Columns } from 'view/components/table';

export const disbursementsGeneralPayoutsColumns: Columns = [
  {
    id: 'trust_journal_entry.contact.label',
    type: 'record',
    Header: 'Paid to',
    width: '20%'
  },
  {
    id: 'description',
    Header: 'Description',
    width: 'auto'
  },
  {
    id: 'trust_journal_entry.payment_information.method.label',
    Header: 'Payment method',
    width: '20%'
  },
  {
    id: 'trust_journal_entry.amount',
    type: 'currency',
    Header: 'Transfer amount',
    width: '20%',
    cellProps: {
      align: 'right'
    }
  }
];
