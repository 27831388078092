import Validator from '@rexlabs/validator';

Validator.registerImplicit(
  'currency_min',
  function (this: any, value) {
    const [min] = this.getParameters();

    if (!value) return true;

    return value.value >= parseFloat(min);
  },
  'The :attribute should be greater than 0'
);
