import faker from '@faker-js/faker';
import { ContactPhone } from 'data/models/entities/contacts/contact-phone';
import { ValueListValue } from 'data/models/types';
import { valueListFactory } from 'src/lib/testing/factories/value-list-factory';
import { Author } from 'src/modules/authors/types/author-types';
import { DisbursementPaymentMethod } from 'src/modules/disbursements/types/types';
import { Contact, ContactRole } from '../types/contact-types';

export function contactFactory(state: Partial<Contact> = {}): Contact {
  const firstName = faker.name.firstName();
  const lastName = faker.name.lastName();
  return {
    __record_type: 'contact',
    id: faker.datatype.uuid(),
    display_name: `${firstName} ${lastName}`,
    status: valueListFactory('active'),
    given_name: firstName,
    middle_name: faker.name.middleName(),
    family_name: lastName,
    salutation_name: '',
    primary_phone: {} as ContactPhone,
    disbursement_payment_methods: [] as DisbursementPaymentMethod[],
    is_agency: false,
    is_business: false,
    is_custom_display_name: false,
    is_security_deposit_authority: false,
    is_supplier: false,
    is_tax_authority: false,
    company_name: '',
    company_registration_number: '',
    tax_registration_number: '',
    roles: [] as ValueListValue<ContactRole>[],
    record_reference: '',
    created_by: {} as Author,
    custom_record_reference: null,
    system_record_reference: '',
    created_at: new Date(),
    updated_at: new Date(),
    logo: null,
    tax: null,
    ...state
  };
}
