import { useGetPrimaryRecordActionGroup } from 'view/hooks/actions/use-get-primary-record-action-group';
import {
  Disbursement,
  financialsDisbursementsModel
} from 'data/models/entities/financials/disbursements/disbursements';
import { useTranslation } from 'react-i18next';
import { useDialog } from '@rexlabs/dialog';
import { ViewDocumentDialog } from 'src/modules/documents/dialogs/view-document-dialog';
import { useModelActions } from '@rexlabs/model-generator';
import { getViewDocumentPropsFromPdfLink } from 'src/modules/documents/actions/use-get-view-documents-action';
import { useSendDisbursementAction } from './use-get-send-disbursement-action';
import { useGetVoidDisbursementAction } from './use-get-void-disbursement-action';

export function useGetDisbursementActionsForBlock() {
  const { t } = useTranslation();

  const viewDocumentDialog = useDialog(ViewDocumentDialog);

  const getPrimaryRecordActionGroup = useGetPrimaryRecordActionGroup();
  const getVoidDisbursementAction = useGetVoidDisbursementAction();
  const getSendDisbursementAction = useSendDisbursementAction();

  const { viewItem } = useModelActions(financialsDisbursementsModel);

  return (disbursement?: Disbursement) => {
    if (!disbursement) return [];

    const voidDisbursementAction = getVoidDisbursementAction(disbursement);
    const sendDisbursementAction = getSendDisbursementAction(disbursement);

    return [
      ...getPrimaryRecordActionGroup(
        'disbursement',
        disbursement.id,
        t('disbursements.actions.view.label') as string
      ),
      {
        id: 'view',
        label: 'View statement',
        group: 'disbursement',
        async handleAction() {
          viewDocumentDialog.open({
            isLoading: true
          });

          const response = await viewItem(disbursement.id);

          viewDocumentDialog.update(
            getViewDocumentPropsFromPdfLink({
              link: response.data.link
            })
          );
        }
      },
      ...(sendDisbursementAction ? [sendDisbursementAction] : []),
      ...(voidDisbursementAction ? [voidDisbursementAction] : [])
    ];
  };
}
