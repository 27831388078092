import React, { useCallback } from 'react';

import { RecordDialog } from 'view/components/record-screen/dialog/dialog';
import { DialogContentConfig } from 'view/components/record-screen/dialog/types';

import { RecordSubmitHandler } from 'view/components/record-screen/utils';
import { useModelActions } from '@rexlabs/model-generator';
import { filterNullValues } from 'utils/filter-null-values';
import {
  ContactPaymentMethod,
  contactsPaymentMethodsModel
} from 'data/models/entities/contacts/payment-methods';
import { createContactPaymentMethodBlock } from '../blocks/create-contact-payment-method';

const content: DialogContentConfig = [
  {
    id: 'payment-method',
    label: 'Payment method',
    blocks: [createContactPaymentMethodBlock]
  }
];

interface CreateContactPaymentMethodDialogProps {
  updateListId?: string;
  onClose?: () => void;
  onCreate?: (contactPaymentMethod: ContactPaymentMethod) => void;
  contactId: string;
  isForDisbursements?: boolean;
  hasExistingChequeMethod?: boolean;
  initialValues?: Partial<ContactPaymentMethod>;
}

export function CreateContactPaymentMethodDialog({
  updateListId,
  onClose,
  onCreate,
  contactId,
  isForDisbursements,
  hasExistingChequeMethod = false,
  initialValues = {}
}: CreateContactPaymentMethodDialogProps) {
  const { createItem, refreshList } = useModelActions(
    contactsPaymentMethodsModel
  );
  const handleSubmit: RecordSubmitHandler = useCallback(
    async ({ values }) => {
      const contactPaymentMethod = await createItem({
        data: filterNullValues(values),
        args: { contactId }
      });

      if (updateListId) {
        await refreshList({ id: updateListId });
      }

      onCreate?.(contactPaymentMethod.data);

      return contactPaymentMethod.data;
    },
    [contactId, createItem, onCreate, refreshList, updateListId]
  );

  const initialData = {
    is_default: false,
    isForDisbursements,
    ...initialValues
  };

  return (
    <RecordDialog
      onClose={onClose}
      data={initialData}
      blockProps={{ hasExistingChequeMethod }}
      title='Add payment method'
      handleSubmit={handleSubmit}
      content={content}
      submitLabel='Add payment method'
    />
  );
}
