/* eslint-disable max-lines */
import React, { CSSProperties, forwardRef, useRef } from 'react';
import { get } from 'lodash';
import mergeRefs from 'react-merge-refs';

import {
  StyleSheet,
  StylesProvider,
  useStyles,
  useToken
} from '@rexlabs/styling';
import Box from '@rexlabs/box';
import { Body, Heading } from '@rexlabs/text';
import icons from '@rexlabs/icons';
import { Form, FormPassthroughProps, Forms } from '@rexlabs/form';
import {
  ButtonGroup,
  GhostButton,
  GhostIconButton,
  PrimaryButton
} from '@rexlabs/button';
import { StatusAverageTag, StatusBadTag } from '@rexlabs/tag';
import LoadingSpinner from '@rexlabs/loading-spinner';
import { Breakpoints, useMediaQuery } from '@rexlabs/breakpoints';

import EditIcon from 'view/components/icons/edit';
import { valueGridTokens } from 'view/components/@luna/grid/value';
import { formGridTokens } from 'view/components/@luna/grid/form';
import {
  Card as LunaCard,
  CardContent,
  CardDivider
} from 'view/components/@luna/card';

import { pluralize } from 'utils/formatters';
import { CardProps } from '../core';

const { ChevronRight } = icons;

const defaultStyles = StyleSheet({
  container: {
    position: 'relative',
    width: '100%',
    transition: 'box-shadow .2s'
  },

  containerEdit: {
    boxShadow: ({ token }) =>
      token('recordCard.hover.boxShadow', token('shadow.medium'))
  },

  containerDragging: {
    borderColor: ({ token }) => token('palette.brand.500'),
    position: 'relative',
    overflow: 'hidden',

    '&:before': {
      content: "' '",
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      background: ({ token }) => token('palette.brand.500'),
      opacity: 0.2,
      zIndex: 10
    },

    '& > *': {
      pointerEvents: 'none'
    }
  },

  containerDropping: {
    borderColor: ({ token }) => token('palette.green.500'),

    '&:before': {
      background: ({ token }) => token('palette.green.500')
    }
  },

  form: {
    flex: 1
  },

  collapsibleBtn: {
    '& svg': {
      transition: 'all 0.2s ease',
      height: 20,
      width: 20
    }
  },

  chevronDown: {
    '& svg': {
      transform: 'rotate(90deg)'
    }
  }
});

type InlineContentProps = Pick<
  CardProps,
  | 'data'
  | 'isLoading'
  | 'id'
  | 'title'
  | 'Actions'
  | 'Edit'
  | 'View'
  | 'item'
  | 'index'
  | 'alwaysRenderView'
  | 'tags'
  | 'TitleTooltip'
> &
  Omit<FormPassthroughProps<any, any>, 'resetForm' | 'submitForm'> & {
    style?: CSSProperties;
    className?: string;

    forms?: Forms;
    editMode?: boolean;
    isEditable?: (data: any) => boolean;
    uploading?: boolean;
    isDragging?: boolean;
    isDropping?: boolean;
    isCollapsible?: boolean;
    isMobileForcedCollapsed?: boolean;
    onTitleClick?: any;
    onEditClick: any;
    onCancelClick: any;
    onSaveClick: any;

    Empty?: any;
    showEmpty?: (data: any) => boolean;
    blockProps?: any;
  };

function useCollapsedBlock(
  isMobileForcedCollapsed: boolean,
  isCollapsible: boolean
) {
  const matchesMobile = useMediaQuery({ maxWidth: 's' });
  const isMobileForcedCollapsedAndMatchesMobile =
    isMobileForcedCollapsed && matchesMobile;
  const [isVisible, setIsVisible] = React.useState(!isCollapsible);

  function getIsVisible() {
    if (isMobileForcedCollapsedAndMatchesMobile) {
      return false;
    }

    return isVisible;
  }

  return {
    isMobileForcedCollapsedAndMatchesMobile,
    showContent: getIsVisible(),
    toggleContent: () => setIsVisible(!isVisible)
  };
}

export const InlineContent = forwardRef<HTMLDivElement, InlineContentProps>(
  function InlineContent(
    {
      style,
      className,

      data,
      forms,
      isLoading,
      id,
      title,
      View,
      Edit,
      Empty,
      showEmpty,
      alwaysRenderView,
      Actions,
      item,
      index,
      editMode,
      tags,

      isDragging,
      isDropping,
      uploading,

      names,
      values,
      setValues,
      setFieldValue,
      fieldIsValid,
      isDirty,
      isValid,
      isSubmitting,

      isEditable = (_) => true,
      isCollapsible = false,
      isMobileForcedCollapsed = false,
      onTitleClick,
      onEditClick,
      onCancelClick,
      onSaveClick,
      blockProps,
      TitleTooltip
    },
    ref
  ) {
    const s = useStyles(defaultStyles);
    const token = useToken();

    const {
      showContent,
      toggleContent,
      isMobileForcedCollapsedAndMatchesMobile
    } = useCollapsedBlock(isMobileForcedCollapsed, isCollapsible);

    const containerRef = useRef<HTMLDivElement>(null);

    const showEmptyState = Empty && !editMode && showEmpty?.(data);

    // Currently, if empty-state is active, the title and edit button will be hidden. If you need empty-state with title:
    // - use the View and implement your state there; OR
    // - refactor to allow toggle of title, with default being Off to preserve reverse compatibility
    const showTitle = (title || Edit) && !showEmptyState;

    const numberInvalidFields = names.filter((name) => !get(fieldIsValid, name))
      .length;

    return (
      <LunaCard
        id={`block-${id}`}
        ref={mergeRefs([ref, containerRef])}
        {...s.with('container', {
          containerEdit: editMode,
          containerDragging: isDragging,
          containerDropping: isDropping
        })({ style, className })}
      >
        <CardContent>
          {showTitle && (
            <Box
              flexDirection='row'
              alignItems='center'
              justifyContent='space-between'
            >
              <Box flexDirection='row' alignItems='center' sx='1.2rem' flex={1}>
                {isCollapsible && !isMobileForcedCollapsedAndMatchesMobile && (
                  <GhostIconButton
                    Icon={ChevronRight}
                    onClick={() => {
                      toggleContent();
                    }}
                    {...s.with('collapsibleBtn', {
                      chevronDown: showContent
                    })()}
                  />
                )}
                {isMobileForcedCollapsedAndMatchesMobile &&
                !isEditable(data) ? (
                  <Box width='100%' onClick={onTitleClick}>
                    <Heading level={3}>
                      {typeof title === 'function'
                        ? title({ editMode: editMode, data, values, item })
                        : title}
                    </Heading>
                  </Box>
                ) : (
                  <Heading level={3}>
                    {typeof title === 'function'
                      ? title({ editMode: editMode, data, values, item })
                      : title}
                  </Heading>
                )}

                {TitleTooltip && <TitleTooltip />}

                {tags}

                {!isValid && (
                  <StatusBadTag>
                    {numberInvalidFields}{' '}
                    {pluralize('Error', numberInvalidFields)}
                  </StatusBadTag>
                )}

                {isValid && isDirty && (
                  <StatusAverageTag>Unsaved changes</StatusAverageTag>
                )}
              </Box>
              <Box ml='2.4rem'>
                {uploading && (
                  <Box
                    mr='2.4rem'
                    flexDirection='row'
                    alignItems='center'
                    justifyContent='center'
                  >
                    <LoadingSpinner
                      size={18}
                      strokeWidth={3}
                      colors={[token('palette.neutral.200')]}
                    />
                    <Box ml='.6rem'>
                      <Body grey>Uploading documents...</Body>
                    </Box>
                  </Box>
                )}
                {Actions && (
                  <Actions
                    id={id}
                    values={values}
                    data={data}
                    item={item}
                    index={index}
                    editMode={editMode}
                  />
                )}
                {isEditable(data) && Edit ? (
                  !editMode ? (
                    // Wrapping span here to not override the
                    // buttons default transition property
                    <span className='edit-button'>
                      <GhostIconButton
                        id={`content-card-${id}-edit-button`}
                        aria-label='Edit block'
                        Icon={EditIcon}
                        onClick={() => {
                          onEditClick?.();
                          if (
                            isCollapsible &&
                            !isMobileForcedCollapsedAndMatchesMobile
                          ) {
                            toggleContent();
                          }
                        }}
                      />
                    </span>
                  ) : (
                    <ButtonGroup>
                      <GhostButton
                        onClick={() => {
                          onCancelClick?.();
                        }}
                      >
                        Cancel
                      </GhostButton>
                      <PrimaryButton
                        isLoading={isSubmitting}
                        onClick={() => {
                          onSaveClick?.();
                        }}
                      >
                        Save
                      </PrimaryButton>
                    </ButtonGroup>
                  )
                ) : null}
              </Box>
            </Box>
          )}
          {showContent ? (
            <>
              <Breakpoints queries={{ maxWidth: 's' }}>
                <CardDivider />
                {showEmptyState && (
                  <Empty data={data} onEditClick={onEditClick} />
                )}
                {View && !showEmptyState && (
                  <StylesProvider tokens={valueGridTokens}>
                    <View
                      id={id}
                      values={values}
                      setValues={setValues}
                      data={data}
                      item={item}
                      index={index}
                      isLoading={isLoading}
                      editMode={editMode}
                    />
                  </StylesProvider>
                )}
              </Breakpoints>
              <Breakpoints queries={{ minWidth: 's' }}>
                {showEmptyState && (
                  <Empty data={data} onEditClick={onEditClick} />
                )}
                {View &&
                  (!editMode || !Edit || alwaysRenderView) &&
                  !showEmptyState && (
                    <StylesProvider tokens={valueGridTokens}>
                      <View
                        id={id}
                        values={values}
                        setValues={setValues}
                        data={data}
                        item={item}
                        index={index}
                        isLoading={isLoading}
                        editMode={editMode}
                      />
                    </StylesProvider>
                  )}
                {Edit && editMode && (
                  <StylesProvider tokens={formGridTokens}>
                    <Form {...s('form')}>
                      <Edit
                        id={id}
                        values={values}
                        setValues={setValues}
                        setFieldValue={setFieldValue}
                        data={data}
                        item={item}
                        index={index}
                        isLoading={isLoading}
                        editMode={editMode}
                        forms={forms}
                        blockProps={blockProps}
                      />
                    </Form>
                  </StylesProvider>
                )}
              </Breakpoints>
            </>
          ) : null}
        </CardContent>
      </LunaCard>
    );
  }
);
