import React, { useCallback } from 'react';
import { ListTable, RecordTable, TabbedTable } from 'view/components/table';
import { Ownership } from 'data/models/entities/ownerships';
import { Contact } from 'src/modules/contacts/types/contact-types';
import { query } from '@rexlabs/model-generator';
import { accountingJournalEntryLineItemModel } from 'src/modules/account-journal-entries/model/accounting-journal-entry-line-item-model';
import { Tab } from 'view/components/table/tabbed';
import { useFeatureFlags } from 'view/components/@luna/feature-flags';
import ROUTES from 'routes/app';
import { transformActionDeclarationsToActionMenuItems } from 'utils/actions/transforms';
import { useTableFilters } from 'view/hooks/use-table-filters';
import { getAccountingJournalEntryLineItemColumns } from '../utils/get-accounting-journal-entry-line-item-columns';
import { useAccountingJournalEntryActions } from '../actions/use-accounting-journal-entry-actions';

interface IncomeAndExpenseProps {
  object: Contact | Ownership;
}

function getRowLinkProps({ item }) {
  return {
    to: ROUTES.ACCOUNTING_JOURNAL_ENTRY,
    params: { accountingJournalEntryId: item.accounting_journal_entry.id }
  };
}

export function IncomeAndExpenseTable({ object }: IncomeAndExpenseProps) {
  const { hasFeature } = useFeatureFlags();

  const hasManualAccountingJournalEntriesFeature = hasFeature(
    'manual_accounting_journal_entries'
  );

  const { getSort, getFilters } = useTableFilters(
    'accounting-journal-entry-line-items'
  );

  const accountingJournalEntryActions = useAccountingJournalEntryActions();

  const getQuery = useCallback(() => {
    //TODO: sort by date_of o: "date_of+desc"
    //      needs to be fixed on BE....
    return query`{
        ${accountingJournalEntryLineItemModel} (o: "date_of+desc"){
          id
          accounting_journal_entry
          chart_of_accounts_account
        }
      }`;
  }, []);

  const tabs: Tab[] = React.useMemo(() => {
    const objectFilter = {
      field: 'object_id',
      op: '==',
      value: object.id
    };

    const commonTabProps = {
      id: 'pending-withdrawals',
      Table: hasManualAccountingJournalEntriesFeature ? ListTable : RecordTable,
      columns: getAccountingJournalEntryLineItemColumns(
        hasManualAccountingJournalEntriesFeature
      ),
      withPagination: true,
      getQuery: getQuery,
      initialSortBy: [{ id: 'date_of', label: 'Date of', desc: true }],
      ...(hasManualAccountingJournalEntriesFeature && {
        initialHiddenColumns: ['reference', 'created_by', 'created_on'],
        getRowLinkProps,
        getActionMenuItems: ({ item }) =>
          transformActionDeclarationsToActionMenuItems(
            accountingJournalEntryActions(item.accounting_journal_entry)
          ),
        getSort,
        getFilters,
        suggestedFilters: ['date_of']
      })
    };

    return hasManualAccountingJournalEntriesFeature
      ? [
          {
            ...commonTabProps,
            name: 'all-income-and-expense',
            label: 'All',
            forcedGlobalFilter: [
              objectFilter,
              {
                field: 'accounting_journal_entry_type_id',
                op: 'in',
                value: ['cash', 'manual']
              },
              {
                field: 'account_category_id',
                op: 'in',
                value: ['income', 'expense']
              }
            ]
          },
          {
            ...commonTabProps,
            name: 'manual-income-and-expense',
            label: 'Manual',
            forcedGlobalFilter: [
              objectFilter,
              {
                field: 'accounting_journal_entry_type_id',
                op: 'in',
                value: ['manual']
              },
              {
                field: 'account_category_id',
                op: 'in',
                value: ['income', 'expense']
              }
            ]
          }
        ]
      : [
          {
            ...commonTabProps,
            name: 'cash-income-and-expense',
            label: 'Cash',
            forcedGlobalFilter: [
              objectFilter,
              {
                field: 'accounting_journal_entry_type_id',
                op: 'in',
                value: ['cash', 'manual']
              },
              {
                field: 'account_category_id',
                op: 'in',
                value: ['income', 'expense']
              }
            ]
          },
          {
            ...commonTabProps,
            name: 'accrual-income-and-expense',
            label: 'Accrual',
            forcedGlobalFilter: [
              objectFilter,
              {
                field: 'accounting_journal_entry_type_id',
                op: 'in',
                value: ['accrual', 'manual']
              },
              {
                field: 'account_category_id',
                op: 'in',
                value: ['income', 'expense']
              }
            ]
          }
        ];
  }, [hasManualAccountingJournalEntriesFeature, object.id]);

  return <TabbedTable tabs={tabs} />;
}
