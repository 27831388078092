import { useCallback } from 'react';

import { useDialog } from '@rexlabs/dialog';

import { SingleActionDeclaration } from 'src/modules/common/actions/types/action-declaration-types';
import { CreateEmailRecordDialog } from 'src/modules/communications/messages/dialogs/create-email-record-dialog';
import { useMessageTriggerSettings } from 'src/modules/communications/messages/settings/message-triggers/hooks/use-message-trigger-settings';
import { getSearchResultItemFromObjectAndModel } from 'src/modules/common/utils/search-result-items/get-search-result-items-from-object-and-model';
import { contactsModel } from 'src/modules/contacts/models/contacts';
import { Contact } from 'src/modules/contacts/types/contact-types';

import { fetchTemplate } from 'src/modules/communications/message-templates/utils/fetch-template';

import { SearchResultItem } from 'utils/api/get-search-results';

import {
  Disbursement,
  financialsDisbursementsModel
} from 'data/models/entities/financials/disbursements/disbursements';
import { FLAGS } from 'utils/feature-flags';
import { useFeatureFlags } from 'view/components/@luna/feature-flags';
import { MessageTriggerKey } from 'src/modules/communications/messages/settings/message-triggers/types/MessageTriggerKey';

export function useSendDisbursementAction() {
  const { open: openCreateEmailDialog } = useDialog(CreateEmailRecordDialog);
  const { hasFeature } = useFeatureFlags();
  const { messageTriggerSettings } = useMessageTriggerSettings();

  return useCallback((disbursement?: Disbursement):
    | SingleActionDeclaration
    | undefined => {
    if (disbursement?.status?.id !== 'disbursed') {
      return;
    }

    if (!hasFeature(FLAGS.MESSAGE_ATTACHMENTS)) {
      return;
    }

    return {
      id: 'send-disbursement',
      intent: 'primary',
      group: 'disbursement',
      label: 'Send statement',
      handleAction: async () => {
        // TODO: the label is not great here - we should look at updating the label of the disbursements
        // currently it returns the name of the person who was disbursed to.
        const disbursementSearchItem = getSearchResultItemFromObjectAndModel<Disbursement>(
          disbursement,
          financialsDisbursementsModel
        );

        const contact = disbursement.contact;
        const owners =
          disbursement.ownership?.owners?.map((owner) => owner.contact) || [];

        const contactRecipients = (contact
          ? [contact]
          : owners
        ).map((contact) =>
          getSearchResultItemFromObjectAndModel<Contact>(contact, contactsModel)
        ) as SearchResultItem<Contact>[];

        const recordType = disbursementSearchItem?.record.object?.type?.id;
        const triggerSetting: MessageTriggerKey =
          recordType === 'ownership'
            ? 'ownership-disbursement-template'
            : 'contact-disbursement-template';

        const defaultTemplateId =
          messageTriggerSettings[triggerSetting]?.template_id;

        const template = await fetchTemplate(defaultTemplateId);

        openCreateEmailDialog({
          data: {
            relates_to: disbursementSearchItem || null,
            content: template?.channels?.[0]?.plain_text_body,
            subject: template?.channels?.[0]?.email_subject,
            attachments: [],
            template: template,
            recipients: contactRecipients
          }
        });
      }
    };
  }, []);
}
