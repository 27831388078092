import invariant from 'invariant';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

import { pluralize } from 'utils/formatters';

import { getQuartersForFinancialYear } from './get-quarters-for-financial-year';

dayjs.extend(customParseFormat);

function getInvalidDates(dates: string[]) {
  return dates.filter((date) => !dayjs(date, 'YYYY-MM-DD', true).isValid());
}

function validateDates(dates: string[]) {
  const invalidDates = getInvalidDates(dates);
  invariant(
    invalidDates.length === 0,
    `${invalidDates.map((date, index, arr) =>
      arr.length === 1
        ? date
        : index === arr.length - 1
        ? `and ${date}`
        : `${date}, `
    )} ${pluralize('is', invalidDates.length)} invalid date ${pluralize(
      'format',
      invalidDates.length
    )}`
  );
}

export function getQuarterNumberFromQuarterStart(
  financialYearStartDate: string,
  quarterStart: string
): number {
  const dates = [financialYearStartDate, quarterStart];

  validateDates(dates);

  const financialYearQuarters = getQuartersForFinancialYear(
    financialYearStartDate
  );

  const currentQuarterIndex = financialYearQuarters.findIndex(
    ({ quarterStartDate }) => quarterStart === quarterStartDate
  );

  if (currentQuarterIndex === -1) {
    invariant(
      false,
      `Could not find quarter for start date: ${quarterStart} in financial year starting on: ${financialYearStartDate}`
    );
  }

  return currentQuarterIndex + 1;
}
