import React, { useMemo } from 'react';
import {
  query,
  useEntityQuery,
  useModelActions
} from '@rexlabs/model-generator';

import { BREADCRUMBS } from 'view/components/@luna/breadcrumbs';

import { financialsPendingDisbursementsContactsModel } from 'data/models/entities/financials/pending-disbursements/pending-contact-disbursement';
import { RecordScreen } from 'view/components/record-screen';
import { usePendingContactDisbursementContent } from 'src/modules/disbursements/data/use-pending-contact-disbursement-content';
import { useRecordScreenSubmitHandler } from 'view/hooks/use-record-screen-submit-handler';
import { contactsModel } from 'src/modules/contacts/models/contacts';
import { Contact } from 'src/modules/contacts/types/contact-types';
import { PendingDisbursementTitleBlock } from '../components/pending-disbursement-title-block';

export const getPendingContactDisbursementQuery = (id: string) => query`{
  ${financialsPendingDisbursementsContactsModel} (id: ${id}) {
    object {
      trust_summary
      financial_summary
      disbursement_payment_methods {
        payment_methods
      }
    }
    payment_methods
    last_disbursement
    disburse_summary
    disburseable_direct_debit_invoices
    disburseable_bpay_invoices
    other_disburseable_invoices
    financial_summary
    disburse_summary
    withheld_funds
    trust_journal_entry_preview {
      paid_to
    }
    disbursement_snapshot_summary
  }
}`;

interface PendingContactDisbursementDetailsScreenProps {
  pendingContactDisbursementId: string;
}

export function PendingContactDisbursementDetailsScreen({
  pendingContactDisbursementId
}: PendingContactDisbursementDetailsScreenProps) {
  const breadcrumbs = [{ type: BREADCRUMBS.DISBURSEMENT }];
  const query = useMemo(
    () => getPendingContactDisbursementQuery(pendingContactDisbursementId),
    [pendingContactDisbursementId]
  );

  const { status, data, actions } = useEntityQuery(query);
  const { updateItem } = useModelActions(contactsModel);

  const content = usePendingContactDisbursementContent();

  const handleSubmit = useRecordScreenSubmitHandler(
    async ({ changedValues }) => {
      const dataToSubmit: Partial<Contact> = changedValues;

      if (changedValues?.object?.disbursement_payment_methods) {
        dataToSubmit.disbursement_payment_methods =
          changedValues?.object?.disbursement_payment_methods;
      }

      await updateItem({
        id: pendingContactDisbursementId,
        data: dataToSubmit
      });

      return actions.refreshItem({
        id: pendingContactDisbursementId
      });
    }
  );

  return (
    <RecordScreen
      isLoading={status === 'loading'}
      data={data}
      content={content}
      titleBlock={<PendingDisbursementTitleBlock pendingDisbursement={data!} />}
      breadcrumbs={breadcrumbs}
      handleSubmit={handleSubmit}
    />
  );
}
