import React, { useMemo } from 'react';

import { useModelActions } from '@rexlabs/model-generator';
import { BulkAction } from '@rexlabs/table/lib/types/bulk-actions/simple';
import { push } from '@rexlabs/whereabouts';

import {
  financialsInvoicesModel,
  Invoice
} from 'data/models/entities/financials/invoices';

import ROUTES from 'routes/app';

import { useBulkModelDownloadAction } from 'src/modules/common/actions/reporting/use-bulk-model-download-action';
import { useSettings } from 'src/modules/settings/hooks/useSettings';

import { transformActionDeclarationsToActionMenuItems } from 'utils/actions/transforms';

import { Card } from 'view/components/card';
import { ListScreen } from 'view/components/list-screen/list-screen';
import { ListTable, TabbedTable } from 'view/components/table';
import { ListTableProps } from 'view/components/table/list-table';
import { Tab } from 'view/components/table/tabbed';
import { useTableFilters } from 'view/hooks/use-table-filters';

import { useGetConfirmBulkTransferAction } from 'src/modules/invoices/actions/use-get-confirm-bulk-transfer-action';
import { useInvoiceActions } from '../actions/use-invoice-actions';
import { getInvoicesQuery } from '../data/queries';
import { getInvoiceListTabs } from '../utils/get-invoice-list-tabs';
import { invoiceColumns } from '../utils/invoice-columns';
import { useInvoiceFilters } from '../utils/use-invoice-filters';

function getRowLinkProps({ item }) {
  return {
    to: ROUTES.INVOICE,
    params: { invoiceId: item.id }
  };
}

export const InvoicesList = () => {
  const { getSort, getFilters } = useTableFilters('invoices');
  const getInvoiceActions = useInvoiceActions();
  const getModelBulkDownloadAction = useBulkModelDownloadAction();
  const getConfirmBulkTransferAction = useGetConfirmBulkTransferAction();

  const { bulkDownloadItems } = useModelActions(financialsInvoicesModel);
  const { timezone } = useSettings();

  const bulkActions = ({
    selectedItems
  }: {
    selectedItems: Invoice[];
  }): BulkAction[] => {
    const bulkDownloadAction = getModelBulkDownloadAction({
      fetcher: () => {
        return bulkDownloadItems({
          data: selectedItems.map((item) => {
            return {
              invoice: item
            };
          })
        });
      },
      label: 'Download all'
    });
    const bulkTransferAction = getConfirmBulkTransferAction(selectedItems);

    return [
      {
        label: 'Download invoices',
        handleAction: () => {
          return bulkDownloadAction.handleAction!();
        },
        type: 'primary'
      } as BulkAction,
      bulkTransferAction
    ];
  };

  const { actions, filters } = useInvoiceFilters();

  const commonTabProps = useMemo<Partial<Tab<ListTableProps>>>(() => {
    return {
      columns: invoiceColumns,
      getFilters,
      getSort,
      getRowLinkProps,
      alwaysExpandSearch: true,
      id: 'invoices',
      forcedGlobalFilter: filters,
      getQuery: getInvoicesQuery,
      getActionMenuItems: ({ item }) =>
        transformActionDeclarationsToActionMenuItems(getInvoiceActions(item)),
      initialHiddenColumns: [
        'created_at',
        'updated_at',
        'bill_reference',
        'status'
      ],
      Table: ListTable,
      getBulkActions: bulkActions
    };
  }, [filters]);

  const invoiceListTabs = useMemo<Tab[]>(
    () => getInvoiceListTabs(commonTabProps, timezone),
    [commonTabProps, timezone]
  );

  return (
    <ListScreen
      title='Invoices'
      recordType='invoice'
      actions={[
        ...actions,
        {
          id: 'create-invoice',
          label: 'Create invoice',
          intent: 'primary',
          handleAction() {
            push(ROUTES.INVOICES_LIST.CREATE);
          }
        }
      ]}
    >
      <Card>
        <TabbedTable tabs={invoiceListTabs} />
      </Card>
    </ListScreen>
  );
};
