import { Generator } from 'data/models/generator';
import { auditableConfig } from 'src/modules/common/auditable/auditable-config';
import { propertyAreaTypesModel } from 'src/modules/property-areas/property-area-types/models/property-area-types-model';
import { PropertyArea } from 'src/modules/property-areas/property-areas/types/PropertyArea';

const config = {
  entities: {
    related: {
      summary: {
        include: 'summary'
      },
      items: {
        include: 'items'
      },
      property_area_type: {
        include: 'property_area_type',
        model: propertyAreaTypesModel
      },
      ...auditableConfig
    }
  }
};

export const propertyAreasModel = new Generator<PropertyArea>(
  'properties/property-areas',
  config
).createEntityModel();
