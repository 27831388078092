import { DialogProps } from '@rexlabs/dialog';
import { useModelActions } from '@rexlabs/model-generator';
import React, { useCallback } from 'react';

import { chartOfAccountsAccountModel } from 'src/modules/chart-of-accounts/models/chart-of-accounts';
import { useToast } from 'view/components/@luna/notifications/toast';
import { RecordDialog } from 'view/components/record-screen/dialog/dialog';
import { DialogContentConfig } from 'view/components/record-screen/dialog/types';
import { RecordSubmitHandler } from 'view/components/record-screen/utils';

import { createChartOfAccountsAccountsBlock } from '../blocks/create-chart-of-accounts-account';
import { ChartOfAccountsAccount } from '../types/chart-of-account-types';

const content: DialogContentConfig = [
  {
    id: 'basics',
    label: 'Basics',
    blocks: [createChartOfAccountsAccountsBlock]
  }
];

export interface CreateChartOfAccountsAccountDialogProps extends DialogProps {
  initialValues?: Partial<ChartOfAccountsAccount>;
  disableAccountCategory?: boolean;
  onCreate?: (data: ChartOfAccountsAccount) => void;
}

export function CreateChartOfAccountsAccountDialog({
  onClose,
  disableAccountCategory = false,
  initialValues = {},
  onCreate
}: CreateChartOfAccountsAccountDialogProps) {
  const { addToast } = useToast();
  const { createItem, refreshLists } = useModelActions(
    chartOfAccountsAccountModel
  );
  const handleSubmit: RecordSubmitHandler = useCallback(async ({ values }) => {
    const response = await createItem({
      data: {
        ...values,
        account_subcategory: {
          id: 'current_asset'
        }
      }
    });

    addToast({ description: 'Created new account' });
    await refreshLists();

    onCreate?.(response.data);

    return response.data;
  }, []);

  return (
    <RecordDialog
      onClose={onClose}
      initialValues={initialValues}
      title='Create account'
      handleSubmit={handleSubmit}
      content={content}
      blockProps={{ disableAccountCategory }}
    />
  );
}
