import { getUploadedFileMeta } from 'utils/files';
import { EditApplianceFormData } from './types/edit-appliance-form-data';
import { EditApplianceRequest } from './types/edit-appliance-request';

/**
 * Map the simple form data to the more complex API request structure.
 * @param formData The form values coming from the blocks
 * @returns The shape of data expected by the API
 */
export async function mapEditApplianceFormToRequest(
  formData: Partial<EditApplianceFormData>
): Promise<Partial<EditApplianceRequest>> {
  if ('manuals' in formData) {
    const { manuals = [] } = formData;

    formData.manuals = await getUploadedFileMeta(manuals);
  }

  if ('warranties' in formData) {
    const { warranties = [] } = formData;

    formData.warranties = await getUploadedFileMeta(warranties);
  }

  if (
    formData.location &&
    typeof formData.location !== 'string' &&
    'name' in formData.location
  ) {
    formData.location = formData.location.name;
  }

  return formData as Partial<EditApplianceRequest>;
}
