import { Includes } from '@rexlabs/model-generator';

import { Generator } from 'data/models/generator';
import { User } from 'src/modules/users/types/user-types';
import { Contact } from 'src/modules/contacts/types/contact-types';
import { TaskType } from 'src/modules/tasks/common/types/TaskType';
import {
  BaseFields,
  RecordTypes,
  TimestampAttributes,
  ValueListValue
} from '../../../data/models/types';
import { PortfolioMember } from './portfolio-members-model';

export type PortfolioAssignableTaskType = Extract<
  TaskType,
  | 'task_inspection'
  | 'task_maintenance'
  | 'task_lease_review'
  | 'task_move_out'
  | 'task_move_in'
  | 'task_property_compliance'
  | 'task_arrears'
>;

export type TaskTypeAssignment = BaseFields<
  typeof RecordTypes.TaskTypeAssignment
> & {
  contact?: Contact;
  task_type: ValueListValue<PortfolioAssignableTaskType>;
};

export type Portfolio = BaseFields<typeof RecordTypes.Portfolio> &
  TimestampAttributes & {
    label: string;
    users?: Includes<User[]>;
    summary?: {
      users_count: number;
      properties_count: number;
    };
    primary_member?: PortfolioMember;
    members?: Includes<PortfolioMember[]>;
    task_type_assignments?: Includes<TaskTypeAssignment[]>;
  };

const config = {
  entities: {
    related: {
      members: {
        include: 'members,members.user,members.user.contact'
      },
      users: {
        include: 'users,users.contact'
      },
      primary_member: {
        include: 'primary_member,primary_member.user'
      },
      summary: {
        include: 'summary'
      },
      task_type_assignments: {
        include: 'task_type_assignments,task_type_assignments.contact'
      }
    }
  }
};

export const portfoliosModel = new Generator<Portfolio>(
  'portfolios',
  config
).createEntityModel();
