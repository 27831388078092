import { useStyles, StyleSheet, border } from '@rexlabs/styling';
import React, { ComponentProps } from 'react';

const defaultStyles = StyleSheet({
  hr: {
    width: '100%',
    margin: 0,
    ...border.styles({
      top: {
        width: 'thin',
        color: 'container.static.light'
      },
      bottom: {
        width: 'none'
      },
      left: {
        width: 'none'
      },
      right: {
        width: 'none'
      }
    })
  }
});

export function HorizontalDivider(props: ComponentProps<'hr'>) {
  const s = useStyles(defaultStyles);

  return <hr {...props} {...s('hr')} />;
}
