import React from 'react';

import { CellProps } from '@rexlabs/table';
import { Truncate } from '@rexlabs/text';

function AttachmentCell({ cell }: CellProps) {
  return cell?.value ? (
    <Truncate>
      <a href={cell.value?.url} target='_blank' rel='noopener noreferrer'>
        {cell.value?.id}
      </a>
    </Truncate>
  ) : null;
}

export default AttachmentCell;
