import { snakeCase } from 'lodash';

import { Contact } from 'src/modules/contacts/types/contact-types';

import { Ownership } from 'data/models/entities/ownerships';

import { TaxWithheldByOwner } from '../types/TaxWithheldByOwner';
import { getDisbursementObjectContacts } from './get-disbursement-object-contacts';

export function getTaxWithheldSummaryItem(
  description: string,
  amount: number,
  data: TaxWithheldByOwner[],
  object?: Contact | Ownership
) {
  const nestedData = data.map((withheldTax) => ({
    description:
      getDisbursementObjectContacts(object).find(
        (contact) => contact.id === withheldTax.contact_id
      )?.display_name || '',
    amount: withheldTax.amount,
    id: `${snakeCase(description)}.${withheldTax.contact_id}`
  }));

  return {
    description:
      nestedData.length === 1
        ? description + ` for ${nestedData[0].description}`
        : description,
    amount,
    id: snakeCase(description),
    nested_data: nestedData.length > 1 ? nestedData : []
  };
}
