import React, { useCallback } from 'react';
import { DialogProps } from '@rexlabs/dialog';
import { RecordDialog } from 'view/components/record-screen/dialog/dialog';
import { DialogContentConfig } from 'view/components/record-screen/dialog/types';
import { RecordSubmitHandler } from 'view/components/record-screen/utils';
import Icons from '@rexlabs/icons';
import { Disbursement } from 'data/models/entities/financials/disbursements/disbursements';
import { voidDisbursementsBlock } from '../blocks/void-disbursements';

export const content: DialogContentConfig = [
  {
    id: 'details',
    label: 'Details',
    blocks: [voidDisbursementsBlock]
  }
];

type VoidValues = {
  reason: string;
};

interface VoidDisbursementDialogProps extends DialogProps {
  disbursements: Disbursement[];
  onSubmit: (voidArguments: VoidValues) => Promise<void>;
}

export function VoidDisbursementDialog({
  onClose,
  onSubmit,
  disbursements
}: VoidDisbursementDialogProps) {
  const handleSubmit: RecordSubmitHandler<VoidValues> = useCallback(
    async ({ values }) => {
      await onSubmit(values);
      return true;
    },
    []
  );

  return (
    <RecordDialog
      onClose={onClose}
      size='s'
      TitleIcon={Icons.InfoCircle}
      title='Caution!'
      submitLabel='Void disbursement(s)'
      blockProps={{
        disbursements
      }}
      content={content}
      handleSubmit={handleSubmit}
    />
  );
}
