import { useDialog } from '@rexlabs/dialog';
import { SecurityDeposit } from 'data/models/entities/financials/security-deposits';
import { SingleActionDeclaration } from 'src/modules/common/actions/types/action-declaration-types';
import { CreateSecurityDepositTopupRequestDialog } from '../../dialogs/create-security-deposit-topup-request';

export function useGetCreateSecurityDepositTopupRequestAction() {
  const { open: openCreateSecurityDepositTopupRequestDeposit } = useDialog(
    CreateSecurityDepositTopupRequestDialog
  );

  return (securityDeposit: SecurityDeposit): SingleActionDeclaration => {
    return {
      id: 'create-request',
      group: 'create',
      label: 'Create topup deposit',
      handleAction: () =>
        openCreateSecurityDepositTopupRequestDeposit({
          securityDeposit
        })
    };
  };
}
