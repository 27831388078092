import { FLAGS } from 'utils/feature-flags';

import { noteTableBlock } from 'src/modules/note/blocks/note-table-block';

import { ContentConfig } from 'view/components/record-screen/types';
import { useMessageTableBlock } from 'src/modules/communications/messages/blocks/message-table-block';
import { taskDateBlock } from '../../common/blocks/task-date-block';
import { taskPriorityBlock } from '../../common/blocks/task-priority-block';
import { documentsBlock } from '../../common/blocks/documents';

import { relatedImagesBlock } from '../blocks/related-images-block';
import { detailsBlock } from '../blocks/details';
import { receivedQuoteBlock } from '../blocks/received-quote-block';
import { linkedTasksBlock } from '../../common/blocks/linked-tasks-block';
import { checklistsBlock } from '../../common/blocks/create-checklists-block';
import { tasksModel } from '../../common/models/tasks-model';

export const useContent = (): ContentConfig => {
  const messageTableBlock = useMessageTableBlock(tasksModel);

  return [
    {
      label: 'General',
      items: [
        {
          id: 'overview',
          label: 'Overview',
          blocks: [
            detailsBlock,
            relatedImagesBlock,
            taskPriorityBlock,
            taskDateBlock
          ]
        },
        {
          id: 'notes',
          label: 'Notes',
          blocks: [noteTableBlock]
        },
        {
          id: 'documents',
          label: 'Documents',
          blocks: [documentsBlock]
        },
        { id: 'messages', label: 'Messages', blocks: [messageTableBlock] },
        {
          id: 'checklist',
          label: 'Checklist',
          blocks: [checklistsBlock],
          flag: FLAGS.CHECKLIST_TEMPLATES
        }
      ]
    },
    {
      label: 'Manage',
      items: [
        {
          id: 'received-quote',
          label: 'Received quote',
          blocks: [receivedQuoteBlock]
        },

        {
          id: 'linked-tasks',
          label: 'Linked tasks',
          blocks: [linkedTasksBlock],
          flag: FLAGS.LINKED_TASKS
        }
      ]
    }
  ];
};
