import { pick } from 'lodash';
import { ChecklistTemplatesForm } from '../types/ChecklistTemplatesForm';
import {
  ChecklistTemplate,
  ChecklistTemplateItem,
  ChecklistTemplateItemGroup
} from '../types/ChecklistTemplate';
import { getGroupPlaceholderPrefix } from './inject-groups-for-outcome-item';

export function transformChecklistFormDataToPipelineRequest({
  values,
  checklistTemplateId,
  isSmartChecklist = false
}: {
  values: Partial<ChecklistTemplatesForm>;
  checklistTemplateId: string;
  isSmartChecklist?: boolean;
}) {
  const requests: {
    id: string;
    method: string;
    path: string;
    json?: Record<string, unknown>;
  }[] = [];

  /**
   *  This request is for updating name and description
   */
  if ('label' in values || 'description' in values || 'is_default' in values) {
    requests.push({
      id: 'checklist_templates',
      method: 'PATCH',
      path: `/api/v1/tasks/checklist-templates/${checklistTemplateId}`,
      json: pick(values, ['label', 'description', 'is_default'])
    });
  }

  const items = values.checklist_template_items ?? [];

  if (Array.isArray(values.checklist_template_items)) {
    const itemRequests = values.checklist_template_items.map((item, index) => {
      return item.id && notPlaceholder(item.id)
        ? getItemPatchRequest(item, index, isSmartChecklist)
        : getItemPostRequest(
            item,
            index,
            isSmartChecklist,
            checklistTemplateId
          );
    });

    // maintain a mapping of group ids to the item request ids that triggered them
    // so that we can use pipeline notation to pull the item ids
    const groupIdToItemRequestIdMap: Record<string, string> = {};

    itemRequests.forEach((itemRequest) => {
      // In theory, if done right, everything should be ordered, so if this item depends on a group, the group request mapping should already be done
      const item = itemRequest.json as ChecklistTemplateItem;
      const belongsToGroupId = item.checklist_template_item_group?.id;
      // if it has an id, and its not a placeholder, then it should already be configured previously and we can ignore it.
      if (belongsToGroupId && isPlaceholderItem(belongsToGroupId)) {
        const groupRequestId = groupIdToItemRequestIdMap[belongsToGroupId];
        if (!groupRequestId) {
          /*
            this shouldn't happen. something is out of order.
            If this happens, it is probably that the overall item ordering is wrong. all items should be ordered like so:
            
            - item 1
            - item 2
              - group 1
                - item 3
                - item 4
            - item 5

            order: item 1, (item 2 --inserts own groups--> group 1), item 3, item 4, item 5
          */
          throw new Error(
            `Group request id not found for item ${itemRequest.id}`
          );
        }
        // modify the request json so that it will pull the correct group id from the pipeline
        (itemRequest.json as ChecklistTemplateItem).checklist_template_item_group = {
          id: `{{$.${groupRequestId}.id}}`
        } as ChecklistTemplateItemGroup;
      }

      requests.push(itemRequest);

      if (item.has_outcome && item.outcome_data?.outcome_options) {
        // Keep track of which index each option is in the outcome options array, so we can reference it later
        const optionIndexMap: Record<
          string,
          number
        > = item.outcome_data?.outcome_options.reduce((acc, option, index) => {
          acc[option.id] = index;
          return acc;
        }, {});

        // find the groups that should match this item
        const groupsToAdd: Partial<ChecklistTemplateItemGroup>[] =
          values.checklist_template_item_groups?.filter(
            (group) =>
              isActiveGroup(group, items) &&
              group.id.startsWith(getGroupPlaceholderPrefix(item))
          ) ?? [];

        // construct pipeline requests for these groups, pull the item ids, and push them to the list.
        groupsToAdd.forEach((rawGroup, groupIndex) => {
          const hasRealTriggeredByItemId =
            rawGroup.triggered_by_checklist_template_item?.id &&
            !isPlaceholderItem(
              rawGroup.triggered_by_checklist_template_item?.id
            );

          const group: Partial<ChecklistTemplateItemGroup> = {
            checklist_template: {
              id: checklistTemplateId
            } as ChecklistTemplate,
            ...rawGroup, // todo: should this come last to avoid clobbering things?
            triggered_by_checklist_template_item: {
              id: hasRealTriggeredByItemId
                ? rawGroup.triggered_by_checklist_template_item!.id
                : `{{$.${itemRequest.id}.id}}`
            } as ChecklistTemplateItem,
            // remap the value if the one that we currently have is a placeholder
            triggered_by_value: rawGroup.triggered_by_value?.includes(
              'outcome-option'
            )
              ? `{{$.${itemRequest.id}.outcome_data.outcome_options.${
                  optionIndexMap[rawGroup.triggered_by_value]
                }.id}}`
              : rawGroup.triggered_by_value
          };
          const groupRequest =
            group.id && notPlaceholder(group.id)
              ? getGroupPatchRequest(group, groupIndex)
              : getGroupPostRequest(group, groupIndex);

          requests.push(groupRequest);
          groupIdToItemRequestIdMap[group.id!] = groupRequest.id;
        });
      }
    });
  }

  // Delete groups that are no longer active
  const groupsToDelete = values.checklist_template_item_groups?.filter(
    (group) => {
      return (
        // if the group is not active
        !isActiveGroup(group, items) &&
        // and it isn't a placeholder, as placeholders that are inactive won't get created at all
        !group.id.includes('placeholder')
      );
    }
  );

  // ensure the array is unique by group id
  groupsToDelete?.forEach((group, groupIndex) => {
    requests.push(getGroupDeleteRequest(group.id!, groupIndex));
  });

  if (Array.isArray(values.delete_ids) && values.delete_ids.length > 0) {
    const deleteRequests = values.delete_ids.map((id, index) => {
      return getItemDeleteRequest(id, index);
    });

    deleteRequests.forEach((deleteRequest) => {
      requests.push(deleteRequest);
    });
  }

  /**
   * This request is for updating the order of the items to maintain sort order
   * This should include ALL items, even those that are in nested groups.
   */
  const itemOrderArray =
    values.checklist_template_items?.map((item, index) => {
      return item.id && notPlaceholder(item.id)
        ? item.id
        : `{{$.checklist_template_items_${index}.id}}`;
    }) ?? [];

  requests.push({
    id: 'checklist_templates_item_array',
    method: 'PATCH',
    path: `/api/v1/tasks/checklist-templates/${checklistTemplateId}`,
    json: {
      item_order: itemOrderArray
    }
  });

  return requests.flat();
}

/**
 *  These requests are for any existing items which needs to be updated
 */
function getItemPatchRequest(item, index, isSmartChecklist: boolean) {
  return {
    id: `checklist_template_items_${index}`,
    method: 'PATCH',
    path: `/api/v1/tasks/checklist-template-items/${item.id}`,
    json: isSmartChecklist ? { ...item } : pick(item, ['label'])
  };
}

/**
 *  These requests are for any new items added while editing the checklist
 */
function getItemPostRequest(
  item,
  index,
  isSmartChecklist: boolean,
  checklistTemplateId: string
) {
  return {
    id: `checklist_template_items_${index}`,
    method: 'POST',
    path: `/api/v1/tasks/checklist-template-items`,
    json: {
      label: item.label,
      ...(isSmartChecklist ? item : {}),
      checklist_template: {
        id: checklistTemplateId
      }
    }
  };
}

function getItemDeleteRequest(id, index) {
  return {
    id: `checklist_template_items_delete_${index}`,
    method: 'DELETE',
    path: `/api/v1/tasks/checklist-template-items/${id}`
  };
}

function getGroupPostRequest(group, index) {
  return {
    id: `checklist_template_item_groups_${index}`,
    method: 'POST',
    path: `/api/v1/tasks/checklist-template-item-groups`,
    json: {
      ...group
    }
  };
}

function getGroupPatchRequest(group, index) {
  return {
    id: `checklist_template_item_groups_${index}`,
    method: 'PATCH',
    path: `/api/v1/tasks/checklist-template-item-groups/${group.id}`,
    json: group
  };
}

function getGroupDeleteRequest(id, index) {
  return {
    id: `checklist_template_item_groups_delete_${index}`,
    method: 'DELETE',
    path: `/api/v1/tasks/checklist-template-item-groups/${id}`
  };
}

function notPlaceholder(id: string) {
  return !isPlaceholderItem(id);
}

function isPlaceholderItem(id: string) {
  // it could be at the start, or in the middle depending if the item or group is new (or both)
  return id.includes('placeholder');
}

function isActiveGroup(
  group: ChecklistTemplateItemGroup,
  items: ChecklistTemplateItem[]
) {
  // the group should be triggered by a current value of an item
  const triggeredByItem = items.find(
    (item) => item.id === group.triggered_by_checklist_template_item?.id
  );

  const isActive = triggeredByItem?.outcome_data?.outcome_options?.some(
    (option) => option.id === group.triggered_by_value
  );

  return isActive;
}
