import React from 'react';
import { useQuery } from 'react-query';
import { api } from 'utils/api/api-client';
import { ChannelTypeId } from '../types/channel';
import { MergeTag, MergeTagCategory } from '../types/merge-tags';
import { MessageContext } from './use-message-contexts';
import { useRemappedMergeTags } from './use-remapped-merge-tags';

// A global message context is a context that is available to all messages
// They come with pre-defined prefixes, which are mapped to the `id` key here.
// Global merge tags should be prefixed with the value of the `id` key
type GlobalMessageContext = {
  id: string;
  context_type?: MessageContext;
};

function fetchGlobalContexts(channelType) {
  return api.get<GlobalMessageContext[]>(
    `/communications/global-contexts?include=context_type,context_type.merge_tags&channel_type_id=${channelType}`
  );
}

// These contexts are treated as global contexts, and are available to all messages
export const useGlobalMessageContexts = (channelType: ChannelTypeId) => {
  const [mergeTags, setMergeTags] = React.useState<MergeTagCategory[]>([]);
  const { isLoading, data } = useQuery({
    queryFn: () => fetchGlobalContexts(channelType),
    select: (response) => response.data
  });

  const remappedTags = useRemappedMergeTags(mergeTags);

  // Transform the data into the shape we need
  React.useEffect(() => {
    (async () => {
      if (!isLoading && data) {
        const tags = data.map((globalContext) => {
          const category: MergeTagCategory = {
            id: globalContext.id,
            label: globalContext.id,
            items: prefixMergeTags(
              globalContext.context_type?.merge_tags || [],
              globalContext.id
            )
          };
          return category;
        });
        setMergeTags(tags);
      }
    })();
  }, [data, isLoading]);

  return { globalMergeTags: remappedTags };
};

function prefixMergeTags(mergeTags: MergeTag[], prefix: string) {
  return mergeTags.map((item) => ({
    ...item,
    tag: `${prefix}_${item.tag}`
  }));
}
