import { useDialog } from '@rexlabs/dialog';

import { SecurityDepositType } from 'data/models/entities/financials/security-deposit-types';
import { ActionDeclaration } from 'src/modules/common/actions/types/action-declaration-types';
import { EditSecurityDepositTypeDialog } from '../../dialogs/edit-security-deposit-type';

export function useGetEditAction() {
  const { open: openEditSecurityDepositType } = useDialog(
    EditSecurityDepositTypeDialog
  );

  return (securityDepositType: SecurityDepositType): ActionDeclaration => {
    return {
      id: 'edit',
      group: 'edit',
      label: 'Edit',
      handleAction: () =>
        openEditSecurityDepositType({
          securityDepositType
        })
    };
  };
}
