import React, { useEffect } from 'react';
import mapboxgl, { Map } from 'mapbox-gl';
import { useQuery } from 'react-query';

import { Coordinates } from 'src/lib/mapbox/types/Coordinates';

import { api } from 'utils/api/api-client';

import { useMapComponent } from 'view/hooks/use-map';

type RouteCoordinates = Coordinates[];

// These types below are for the return types of the MapBox API
// they're not complete, but they're enough for our purposes.
type MapBoxRoutes = {
  distance: number;
  duration: number;
  geometry: {
    coordinates: Coordinates[];
    type: string;
  };
  legs: Record<string, any>[];
};

type MapBoxWaypoint = {
  distance: number;
  name: string;
  location: Coordinates;
};

type MapBoxDirections = {
  code: string;
  routes: MapBoxRoutes[];
  uuid: string;
  waypoints: MapBoxWaypoint[];
};

type MapConfig = {
  width?: number | string;
  height?: number | string;
  routes: RouteCoordinates;
  staringZoom?: number;
};

const DESTINATION_NUMBER_PREFIX = 'destination-number';

export function DirectionsMap({
  height = 400,
  width = '100%',
  routes,
  staringZoom = 13
}: MapConfig) {
  const [startingLng, startIngLat] = getStartingCenterCoordinates(routes);

  const { MapComponent: Map, isLoading, mapObject } = useMapComponent({
    startingCenterCoordinates: [startingLng, startIngLat],
    staringZoom
  });

  const { data: directionsData } = useQuery(
    getQueryKey(routes),
    () => fetchRoutes(getQueryKey(routes)),
    {
      staleTime: Infinity,
      cacheTime: Infinity
    }
  );

  // The following effect occurs if the data changes, which inturn is triggered
  // if the routes change. This effect is responsible for drawing the lines
  // between the routes and adding the destination numbers on the map.
  useEffect(() => {
    // If there is no data, styles are still loading, or no map object return
    if (!directionsData || isLoading || !mapObject) return;

    // For each route in the data, add the directions layer to the map
    addRouteToMap(mapObject, directionsData);

    // For each destination in the data, add the destination layer to the map
    // (This is the black circle and number that appears on the map)
    addDestinationsToMap(mapObject, routes);

    // Finally, fit the map to the bounds of the routes
    mapObject.fitBounds(getBounds(routes));
  }, [directionsData, isLoading]);

  return <Map height={height} width={width} />;
}

// The following function helps us get the starting center coordinates. It does this
// by averaging all the coordinates in the routes array.
function getStartingCenterCoordinates(routes: RouteCoordinates): Coordinates {
  const startingLng =
    routes.reduce((acc, curr) => acc + curr[0], 0) / routes.length;
  const startIngLat =
    routes.reduce((acc, curr) => acc + curr[1], 0) / routes.length;
  return [startingLng, startIngLat];
}

// The following function helps us get the bounds of the map. It does this by finding
// the min and max lng and lat in the routes array and then adding/subtracting a bit of padding
function getBounds(routes: RouteCoordinates): [number, number, number, number] {
  const maxLng = Math.max(...routes.map((route) => route[0])) + 0.02;
  const minLng = Math.min(...routes.map((route) => route[0])) - 0.02;
  const maxLat = Math.max(...routes.map((route) => route[1])) + 0.02;
  const minLat = Math.min(...routes.map((route) => route[1])) - 0.02;

  return [minLng, minLat, maxLng, maxLat];
}

// THe query key is the coordinates of the routes joined by a semi-colon.
function getQueryKey(routeCoordinates: Coordinates[]) {
  return routeCoordinates.map((route) => `${route[0]},${route[1]}`).join(';');
}

async function fetchRoutes(queryKey) {
  if (queryKey.length < 2) return;
  const { data } = await api.get<MapBoxDirections>(
    `https://api.mapbox.com/directions/v5/mapbox/driving/${queryKey}?steps=true&geometries=geojson&access_token=${mapboxgl.accessToken}`
  );

  return data;
}

// This function draws a line between two coordinates on the map
function addRouteToMap(map: Map, directions: MapBoxDirections) {
  const data: any = {
    type: 'FeatureCollection',
    features: directions.routes.map((route) => ({
      type: 'Feature',
      properties: {},
      geometry: route.geometry
    }))
  };

  if (map.getSource('route')) {
    return (map.getSource('route') as any).setData(data);
  }

  map.addLayer({
    id: 'route',
    type: 'line',
    source: {
      type: 'geojson',
      data: data
    },
    layout: {
      'line-join': 'round',
      'line-cap': 'round'
    },
    paint: {
      'line-color': '#3887be',
      'line-width': 5,
      'line-opacity': 0.75
    }
  });
}

// This function adds a black circle and a number on the map
function addDestinationsToMap(map: Map, destinations: RouteCoordinates) {
  const pointData: any = {
    type: 'FeatureCollection',
    features: destinations.map((destination) => ({
      type: 'Feature',
      properties: {},
      geometry: {
        type: 'Point',
        coordinates: destination
      }
    }))
  };

  if (map.getSource('destinations')) {
    (map.getSource('destinations') as any).setData(pointData);
  } else {
    map.addLayer({
      id: 'destinations',
      type: 'circle',
      source: {
        type: 'geojson',
        data: pointData
      },
      paint: {
        'circle-radius': 12.5,
        'circle-color': '#000'
      }
    });
  }

  const destinationsExcludingStartAndEnd = destinations.slice(
    1,
    destinations.length - 1
  );

  // We want to get the existing layers that have the destination number prefix so we can
  // determine if we need to remove any layers. We'll need to remove layers if the number of
  // destinations has decreased.
  const destinationNumberLayers = map
    .getStyle()
    .layers.filter((layer) => layer.id.includes(DESTINATION_NUMBER_PREFIX));

  // If the length of the destinationNumberLayers is greater than the length of the destinationsExcludingStartAndEnd
  // we need to remove the excess layers.
  if (
    destinationNumberLayers.length > destinationsExcludingStartAndEnd.length
  ) {
    destinationNumberLayers
      .slice(destinationsExcludingStartAndEnd.length)
      .forEach((layer) => {
        map.removeLayer(layer.id);
        map.removeSource(layer.id);
      });
  }

  destinationsExcludingStartAndEnd.forEach((destination, index) => {
    const destinationNumberData: any = {
      type: 'Feature',
      properties: {},
      geometry: {
        type: 'Point',
        coordinates: destination
      }
    };

    if (map.getSource(`${DESTINATION_NUMBER_PREFIX}-${index}`)) {
      (map.getSource(`${DESTINATION_NUMBER_PREFIX}-${index}`) as any).setData(
        destinationNumberData
      );
    } else {
      map.addLayer({
        id: `${DESTINATION_NUMBER_PREFIX}-${index}`,
        type: 'symbol',
        source: {
          type: 'geojson',
          data: destinationNumberData
        },
        layout: {
          'text-field': (index + 1).toString(),
          'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
          'text-size': 14
        },
        paint: {
          'text-color': '#fff'
        }
      });
    }
  });
}
