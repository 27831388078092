import React from 'react';
import { BlockConfig } from 'view/components/record-screen/types';
import { BankDepositsList } from 'src/modules/banking/bank-deposits/connectors/bank-deposits';
import { useDialog } from '@rexlabs/dialog';
import { CreateBankDepositDialog } from 'src/modules/banking/bank-deposits/dialogs/create-bank-deposit-dialog';
import { ActionButtons } from 'view/components/action-buttons';
import { getFilterByTrustAccountId } from 'src/modules/banking/bank-deposits/data/bank-deposits';

export const bankDepositsBlock: BlockConfig = {
  id: 'bankDeposits',
  title: 'Bank Deposits',
  View: ({ data }) => (
    <BankDepositsList filters={getFilterByTrustAccountId(data.is)} />
  ),
  Actions: ({ data }) => {
    const createBankDepositDialog = useDialog(CreateBankDepositDialog);

    return (
      <ActionButtons
        actions={[
          {
            label: 'Create bank deposit',
            onClick: () =>
              createBankDepositDialog.open({
                trustAccountId: data.id,
                refreshListId: 'bank-deposit-list'
              })
          }
        ]}
      />
    );
  }
};
