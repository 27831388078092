import React from 'react';

import { ValueListItem } from 'utils/normaliser/value-list-item';

import {
  BasicEditLetterForm,
  BasicEditLetterFormProps
} from './basic-forms/basic-edit-letter-form';
import { UploadAttachments } from './parts/upload-attachments';
import { TemplatedAttachments } from './parts/templated-attachments';

interface EditLetterContentFormProps extends BasicEditLetterFormProps {
  templatedAttachments?: ValueListItem[];
}

/**
 * Complex form for editing letter content. It has two columns:
 *   - first column includes the address fields, letter content, attachments and templated attachments fields
 *   - second column includes the merge tag component
 */
export function EditLetterContentForm({
  setFieldValue,
  contextType,
  templatedAttachments,
  textContentFieldName
}: EditLetterContentFormProps) {
  return (
    <BasicEditLetterForm
      textContentFieldName={textContentFieldName}
      contextType={contextType}
      setFieldValue={setFieldValue}
    >
      <UploadAttachments accept='application/pdf' />
      <TemplatedAttachments templatedAttachments={templatedAttachments} />
    </BasicEditLetterForm>
  );
}
