import React from 'react';

import { RecordDialog } from 'view/components/record-screen/dialog/dialog';
import { DialogContentConfig } from 'view/components/record-screen/dialog/types';

import { RecordSubmitHandler } from 'view/components/record-screen/utils';
import { RentScheduleEntry } from 'src/modules/property-tenancies/types/property-tenancy-types';
import { createRentScheduleEntryDetailsBlock } from '../blocks/create-rent-schedule-entry-details';
import { rentScheduleEntryLineItemsBlock } from '../blocks/rent-schedule-entry-line-items';

const content: DialogContentConfig = [
  {
    id: 'basics',
    label: 'Basics',
    blocks: [
      createRentScheduleEntryDetailsBlock,
      rentScheduleEntryLineItemsBlock
    ]
  }
];

interface EditRentScheduleDialogProps {
  isFirstEntry?: boolean;
  rentScheduleEntry: RentScheduleEntry;
  onClose?: () => void;
  onSave?: (item: RentScheduleEntry & { id: string }) => void;
}

export function EditRentScheduleDialog({
  isFirstEntry,
  rentScheduleEntry,
  onClose,
  onSave
}: EditRentScheduleDialogProps) {
  const handleSubmit: RecordSubmitHandler = async ({ values }) => {
    await onSave?.(values);
    return true;
  };

  return (
    <RecordDialog
      onClose={onClose}
      data={rentScheduleEntry}
      title='Update rent schedule entry'
      handleSubmit={handleSubmit}
      content={content}
      blockProps={{ isFirstEntry }}
    />
  );
}
