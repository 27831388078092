import React, { ComponentType } from 'react';
import dayjs from 'dayjs';
import { DialogProps, useErrorDialog } from '@rexlabs/dialog';
import {
  ModelGeneratorLoadedItem,
  ModelGeneratorLoadedList,
  query,
  withQuery
} from '@rexlabs/model-generator';

import { getRentAdjustmentsQuery } from 'src/modules/tenancies/data/queries';
import {
  CreateTenancy,
  TenancyModel,
  tenancyModel
} from 'data/models/entities/tenancies';
import {
  CreateRentAdjustment,
  RentAdjustment
} from 'data/models/entities/financials/rent/adjustments';
import { useToast } from 'view/components/@luna/notifications/toast';
import { DialogTitleWithTag } from 'view/components/dialog-title-with-tag';
import { StepsDialog } from 'view/components/dialogs/steps-dialog';
import { Step } from 'view/components/dialogs/steps-dialog/steps';

import { compose } from 'redux';
import { adjustRent } from '../../data/api';
import { RentAdjustmentDetails } from './details';
import { RentAdjustmentSummary } from './summary';

const getTenancyId = (props): string =>
  props?.tenancyId || props?.match?.params?.tenancyId;

export const tenanciesQuery = query`{
  ${tenancyModel} (id: ${getTenancyId}) {
    id
    name
    paid_to_date
    rent_amount
    frequency
    reference
    last_increase_date
    contacts
  }
}`;

export const adjustmentsQuery = getRentAdjustmentsQuery(getTenancyId);
adjustmentsQuery.uuid = 'rentAdjustments';

export const steps: Step[] = [
  { label: 'Rent details', Content: RentAdjustmentDetails },
  { label: 'Summary', Content: RentAdjustmentSummary }
];

interface InternalProps extends Pick<DialogProps, 'onClose'> {
  tenancies?: ModelGeneratorLoadedItem<TenancyModel, CreateTenancy>;
  financialsRentAdjustments: ModelGeneratorLoadedList<
    RentAdjustment,
    CreateRentAdjustment
  >;
}

export interface RentAdjustmentDialogProps {
  tenancyId: string;
}

const decorate = compose<ComponentType<RentAdjustmentDialogProps>>(
  withQuery<InternalProps>(tenanciesQuery),
  withQuery(adjustmentsQuery)
);

export const RentAdjustmentDialog = decorate(
  ({
    onClose,
    tenancies: tenancy,
    financialsRentAdjustments,
    ...props
  }: RentAdjustmentDialogProps & InternalProps) => {
    const errorDialog = useErrorDialog();
    const { addToast } = useToast();

    function handleSubmit(values) {
      return (
        adjustRent({
          amount: values.amount,
          from_date: dayjs(values.from_date).format('YYYY-MM-DD'),
          reason: values.reason_for_adjustment,
          bond_amount: values.bond_amount,
          notify_tenants: values.notify_tenants,
          tenancy: {
            id: values.tenancyId
          }
        })
          .then(() => financialsRentAdjustments?.refreshList())
          // TODO: Fix this copy
          .then(() => addToast({ description: 'Rent was adjusted' }))
          .then(() => onClose?.())
          .catch(errorDialog.open)
      );
    }

    return (
      <StepsDialog
        width='75rem'
        onClose={onClose}
        stateForInitialStep={{
          tenancy
        }}
        initialFormValues={{
          from_date: new Date(),
          rent_frequency: { value: 'weekly', label: 'Weekly' }
        }}
        isLoading={
          tenancy?.item?.status === 'loading' ||
          financialsRentAdjustments?.list?.status === 'loading'
        }
        title={
          <DialogTitleWithTag
            title='Adjust Rent'
            tag={
              (tenancy?.item?.data as any)?.reference?.toString()
                ? `${(tenancy?.item?.data as any)?.reference}`
                : null
            }
          />
        }
        {...props}
        adjustments={financialsRentAdjustments?.list?.items}
        tenancy={tenancy?.item?.data}
        handleSubmit={handleSubmit}
        steps={steps}
      />
    );
  }
);
