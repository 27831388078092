import React from 'react';

import { Dropdown, SelectProps, SelectNormalised } from '@rexlabs/select-old';

const valueList = [true, false];

export function normaliseBooleanValueListItem(
  trueLabel: string,
  falseLabel: string
) {
  return (item: boolean): SelectNormalised => ({
    value: item ? 'yes' : 'no',
    label: item ? trueLabel : falseLabel
  });
}

export function denormaliseBooleanValueListItem(item: SelectNormalised) {
  return item.value === 'yes';
}

export interface BooleanSelectProps
  extends Omit<SelectProps<boolean>, 'items'> {
  trueLabel?: string;
  falseLabel?: string;
}

export function BooleanSelect({
  trueLabel = 'Yes',
  falseLabel = 'No',
  ...props
}: BooleanSelectProps) {
  return (
    <Dropdown
      items={valueList}
      normaliser={normaliseBooleanValueListItem(trueLabel, falseLabel)}
      denormaliser={denormaliseBooleanValueListItem}
      {...props}
    />
  );
}
