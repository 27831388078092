import React, { useCallback, useMemo } from 'react';
import { SelectProps, Dropdown } from '@rexlabs/select-old';

import { ownershipsFeesInactiveTemplatesModel } from 'data/models/entities/ownerships/fees/inactive-ownership-fees';
import { useModelActions } from '@rexlabs/model-generator';
import { normaliseAgencyFee } from 'utils/normaliser/agency-fee';
import { useDialog } from '@rexlabs/dialog';
import { CreateNewAgencyFeeDialog } from 'src/modules/agency-fees/dialogs/create-new-agency-fee';
import { FieldActions } from '@rexlabs/form';

export interface InactiveOwnershipFeesDropdownProps
  extends Omit<SelectProps, 'items'> {
  actions?: FieldActions;
  ownershipId: string;
}

export function InactiveOwnershipFees({
  ownershipId,
  actions,
  value,
  multi,
  ...dropdownProps
}: InactiveOwnershipFeesDropdownProps) {
  const { fetchList } = useModelActions(ownershipsFeesInactiveTemplatesModel);
  const { open } = useDialog(CreateNewAgencyFeeDialog);

  const fixtures = useMemo(() => {
    return [
      {
        label: 'Create new agency fee',
        action: () =>
          open({
            isFixture: true,
            entityFeeType: 'ownership',
            onCreate: (newFee) => {
              if (!multi) return actions?.setValue(newFee);
              if (value) return actions?.setValue([...value, newFee]);

              actions?.setValue([newFee]);
            }
          })
      }
    ];
  }, [value, multi]);

  const fetchItems = useCallback(async () => {
    const results = await fetchList({
      id: 'inactive-ownership-fees-list',
      args: {
        ownershipId
      }
    });

    return results.data.map((dataEntry) => dataEntry.item);
  }, [ownershipId]);

  return (
    <Dropdown
      {...dropdownProps}
      value={value}
      multi={multi}
      isFilterable={true}
      items={fetchItems}
      fixtures={fixtures}
      normaliser={normaliseAgencyFee}
    />
  );
}
