import { startCase } from 'lodash';
import { useQuery } from 'react-query';
import { fetchAddressFromString } from 'src/lib/mapbox/utils/fetch-address-from-string';
import { useSettings } from 'src/modules/settings/hooks/useSettings';

/**
 * At the time of writing this, we have no fallback for a property that is missing a location.
 * This hook will attempt to find a location for the property based on the timezone.
 */
export function useFallbackLngLat() {
  const { timezone } = useSettings();
  const [continent, region] = timezone.split('/');

  const { data, isFetching } = useQuery(
    'fallbackLngLat',
    async () =>
      fetchAddressFromString(`${startCase(region)}, ${startCase(continent)}`),
    {
      enabled: !!(continent || region),
      cacheTime: Infinity,
      staleTime: Infinity
    }
  );

  const lngLat = data?.[0]?.data?.lngLat;
  return { lngLat, isFetching };
}
