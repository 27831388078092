import * as React from 'react';
import invariant from 'invariant';
import { StyleSheet, useStyles } from '@rexlabs/styling';

const cssVarWidth = '--progress-width';

export interface ProgressBarProps {
  value?: number;
  max: number;
  testId?: string;
}

const styles = StyleSheet({
  root: {
    height: 16,
    width: '100%',
    borderRadius: 32,
    background: ({ token }) => token('palette.grey.200'),
    position: 'relative',
    display: 'block',

    '&::before': {
      content: '""',
      position: 'absolute',
      height: '100%',
      borderRadius: 'inherit',
      width: `calc(var(${cssVarWidth}) * 100%)`
    }
  },

  inProgress: {
    '&:before': {
      background: ({ token }) => token('palette.yellow.400')
    }
  },

  completed: {
    '&:before': {
      background: ({ token }) => token('palette.green.400')
    }
  }
});

export function ProgressBar(props: ProgressBarProps) {
  const { max, value = 0, testId } = props;
  const s = useStyles(styles);

  invariant(value <= max, `[Progressbar] value can't be greater than max`);

  const isCompleted = value === max;

  return (
    <div
      {...s('root', {
        inProgress: !isCompleted,
        completed: isCompleted
      })}
      // avoid div by zero by falling back to 1
      style={{ [cssVarWidth]: value / (max || 1) } as React.CSSProperties}
      role='progressbar'
      aria-valuemax={max}
      aria-valuenow={value}
      data-testid={testId}
    />
  );
}
