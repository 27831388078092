import { useGetPrimaryRecordActionGroup } from 'view/hooks/actions/use-get-primary-record-action-group';
import { Portfolio } from 'src/modules/portfolios/models/portfolios-model';
import { useGetDeletePortfolioAction } from '../action-declarations/use-get-delete-portfolio-action';

export function useGetPortfolioActions() {
  const getPrimaryRecordActionGroup = useGetPrimaryRecordActionGroup();
  const getDeletePortfolioAction = useGetDeletePortfolioAction();

  return (portfolio?: Portfolio) => {
    if (!portfolio) return [];

    return [
      ...getPrimaryRecordActionGroup('portfolio', portfolio.id),
      ...[
        {
          label: 'Actions',
          actions: [getDeletePortfolioAction(portfolio)]
        }
      ]
    ];
  };
}
