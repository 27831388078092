import React from 'react';

import { api } from 'utils/api/api-client';
import {
  Autocomplete,
  AutocompleteProps,
  SelectNormalised
} from '@rexlabs/select-old';
import { Portfolio } from 'src/modules/portfolios/models/portfolios-model';

export function normaliser(item: Portfolio): SelectNormalised {
  return {
    value: item,
    label: item.label,
    type: 'portfolio'
  };
}

interface PortfolioSelectProps extends AutocompleteProps {
  includes?: Array<string>;
  filters?: string;
}

export const PortfolioSelect = (props: PortfolioSelectProps) => {
  const { includes: propIncludes, filters: propFilters } = props;

  const queryParams = new URLSearchParams();

  if (Array.isArray(propIncludes)) {
    queryParams.set('include', propIncludes.join(','));
  }

  if (typeof propFilters === 'string') {
    queryParams.set('q', propFilters);
  }

  const query = queryParams.toString();

  const items = React.useCallback(
    async (term) => {
      const portfolios = await api.get(`/portfolios?${query}`, {
        search: term,
        per_page: 30
      });

      return portfolios.data;
    },
    [query]
  );

  return <Autocomplete {...props} items={items} normaliser={normaliser} />;
};
