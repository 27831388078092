import { RecordObject } from 'data/models/types';

import { getUploadedFileMeta } from 'utils/files';

import { getSelectedTemplatedAttachments } from 'src/modules/communications/messages/utils/get-selected-templated-attachments';

import { BulkCreateLetterMessageFormData } from '../types/BulkCreateLetterMessageFormData';
import { BulkCreateMessageRequest } from './types/bulk-create-message-request';

export async function mapBulkCreateLetterFormToRequest(
  formData: BulkCreateLetterMessageFormData,
  recordObjects: RecordObject[]
): Promise<BulkCreateMessageRequest> {
  const inline_attachments = await getUploadedFileMeta(formData.attachments);

  return {
    data: {
      context: {
        type: {
          id: formData.context_type
        }
      },
      context_type: {
        id: formData.context_type
      },
      recipient_group: formData.recipient_group?.name,
      forced_channel_type: {
        id: 'letter'
      },
      ...(formData.sent_from?.id
        ? { send_from_author: { id: formData.sent_from!.id } }
        : {}),
      channels: [
        {
          channel_type: {
            id: 'letter'
          },
          text_body: formData.content,
          letter_address: formData.letterAddress
        }
      ],
      linked_files: formData.linked_files || [],
      ...(formData.include_generated_attachments
        ? {
            templated_attachments: getSelectedTemplatedAttachments(
              formData.include_generated_attachments
            )
          }
        : {}),
      ...(formData.template
        ? {
            template: { id: formData.template?.id }
          }
        : {}),
      inline_attachments
    },
    ids: recordObjects.map(({ object }) => object.id)
  };
}
