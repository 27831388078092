import { OwnershipTaxTransaction } from '../types/ownership-tax-transaction';

export function getTransactionValueByTransactionType(
  transaction: OwnershipTaxTransaction
) {
  const isTransactionNegative =
    ['tax_paid'].includes(transaction?.type?.id) ||
    ['decrease_tax_owed', 'reimburse_withheld_tax_to_owner'].includes(
      transaction?.manual_transaction_type?.id ?? ''
    );

  const amount = isTransactionNegative
    ? -transaction.amount
    : transaction.amount;

  return !transaction.voided_at ? amount : -amount;
}
