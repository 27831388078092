import React from 'react';

import { Truncate } from '@rexlabs/text';
import { CellProps } from '@rexlabs/table';
import { getRecordLabel } from 'utils/records/get-record-label';
import {
  margin,
  StyleSheet,
  StylesProvider,
  useStyles
} from '@rexlabs/styling';
import WarningCircleIcon from 'view/components/icons/warning-circle';
import Tooltip from '@rexlabs/tooltip';
import { PendingContactDisbursement } from 'data/models/entities/financials/pending-disbursements/pending-contact-disbursement';
import { RecordObject } from 'data/models/types';
import { useTranslation } from 'react-i18next';
import { MissingPaymentWarning } from './missing-payment-warning';

const defaultStyles = StyleSheet({
  paymentMethod: {
    fontSize: '1.2rem',
    color: ({ token }) => token('color.textStyle.body.subtext')
  },
  missingPaymentMethods: {
    color: ({ token }) => token('color.textStyle.danger.idle.default'),
    fontWeight: ({ token }) => token('typography.weight.bold')
  },
  missingPaymentLabel: {
    ...margin.styles({
      left: 'xs'
    })
  },
  secondLine: {
    ...margin.styles({
      top: 'xxs'
    })
  }
});

const tokens = {
  tooltip: {
    maxWidth: '323px'
  }
};

export function DisbursementContactCell({
  value
}: CellProps<unknown, PendingContactDisbursement>) {
  const s = useStyles(defaultStyles);
  const { t } = useTranslation();

  const hasPaymentMethods = (value.payment_methods?.length ?? 0) > 0;

  return (
    <div>
      <Truncate>
        {getRecordLabel({
          type: 'contact',
          object: value.object!
        } as RecordObject)}
      </Truncate>
      <div {...s('secondLine')}>
        {hasPaymentMethods && (
          <Truncate {...s('paymentMethod')}>
            {value.payment_methods
              ?.map((paymentMethod) => paymentMethod.label)
              .join(', ')}
          </Truncate>
        )}
        {!hasPaymentMethods && (
          <div {...s('paymentMethod', 'missingPaymentMethods')}>
            <StylesProvider tokens={tokens}>
              <Tooltip Content={MissingPaymentWarning}>
                <span style={{ display: 'inline-flex', alignItems: 'center' }}>
                  <WarningCircleIcon />
                  <Truncate {...s('missingPaymentLabel')}>
                    {t('disbursements.method.missing.label')}
                  </Truncate>
                </span>
              </Tooltip>
            </StylesProvider>
          </div>
        )}
      </div>
    </div>
  );
}
