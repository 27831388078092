import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import en_AU from './locales/en-AU.json';
import en_GB from './locales/en-GB.json';

export const defaultNS = 'ns1';
export const resources = {
  en_AU: { ns1: en_AU },
  en_GB: { ns1: en_GB }
} as const;

export type LanguageKey = keyof typeof resources;

i18n
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: true,
    lng: 'en_AU',
    fallbackLng: 'en_AU',
    interpolation: {
      escapeValue: false // not needed for react as it escapes by default
    },
    defaultNS,
    resources,
    react: {
      bindI18n: 'loaded languageChanged',
      bindI18nStore: 'added languageChanged',
      useSuspense: false
    }
  });

export default i18n;
