import React, { ComponentType, useMemo } from 'react';

import { useToken } from '@rexlabs/styling';
import Box from '@rexlabs/box';

import { ControlledTabs, ControlledTabsProps } from '@rexlabs/tabs';
import { useTabUrlState } from 'view/hooks/use-tab-url-state';

type Item = ControlledTabsProps['items'][0];

function TabContent({ activeTab, Table, ...props }) {
  const token = useToken();
  return (
    <Box pt={token('spacing.m')}>
      <Table {...props} hashParamKey={activeTab} />
    </Box>
  );
}

export type Tab<TableProps = any> = TableProps & {
  Table: ComponentType<TableProps>;
} & Pick<Item, 'name' | 'label' | 'IconEnd'>;

export type TabbedTableProps<TableProps = any> = {
  tabs: Tab<TableProps>[];
  /**
   * Required if you want to render more than one TabbedTable component on a page.
   * Used to set the prefix for each tabs hash param.
   * Should be unique for each TabbedTable on a page.
   */
  hashParamKey?: string;
};

export function TabbedTable<TableProps>({
  tabs,
  hashParamKey
}: TabbedTableProps<TableProps>) {
  const { activeTab, setTab } = useTabUrlState({ tabs, hashParamKey });

  const items: Item[] = useMemo(() => {
    return tabs.map(({ name, label, IconEnd, ...config }) => {
      return {
        name,
        label,
        IconEnd: IconEnd
          ? () => <IconEnd isActive={activeTab === name} />
          : null,
        Tab: TabContent,
        tabProps: {
          ...config,
          activeTab
        }
      } as Item;
    });
  }, [activeTab, tabs]);

  return (
    <ControlledTabs
      keepRenderedTabs={false}
      allowActiveTabToScrollIntoView={false}
      activeTab={activeTab}
      items={items}
      onChange={setTab}
    />
  );
}
