import { ComponentType, useContext, useEffect, useMemo } from 'react';
import { DrawerContext } from '../components/drawer-target';

export type UseDrawerReturn<T> = {
  open: (props?: Partial<T>) => void;
  update: (props?: Partial<T>) => void;
  close: () => void;
};

let id = 0;
export function useDrawer<TDrawer>(
  Drawer: ComponentType<TDrawer>,
  props: Partial<TDrawer> = {}
): UseDrawerReturn<TDrawer> {
  const { addDrawer, updateDrawer } = useContext(DrawerContext);

  const drawerId = useMemo(() => id++, []);

  useEffect(() => {
    addDrawer({
      id: drawerId,
      open: false,
      Drawer,
      props
    });
  }, []);

  function open(p: Partial<TDrawer> = {}) {
    updateDrawer({
      id: drawerId,
      open: true,
      props: {
        ...props,
        ...p
      }
    });
  }

  function update(p: Partial<TDrawer> = {}) {
    updateDrawer({
      id: drawerId,
      ...p
    });
  }

  function close() {
    updateDrawer({
      id: drawerId,
      open: false
    });
  }

  return {
    open,
    update,
    close
  };
}
