import { useDialog } from '@rexlabs/dialog';

import { SingleActionDeclaration } from 'src/modules/common/actions/types/action-declaration-types';

import { InspectionTask } from '../../types/InspectionTask';
import { ManageAreasDialog } from '../../dialogs/manage-areas-dialog';

export function useGetManageInspectionReportAreasAction(
  inspectionTask: InspectionTask
) {
  const { open } = useDialog(ManageAreasDialog);

  return (): SingleActionDeclaration => {
    return {
      id: 'manage-inspection-report-areas',
      intent: 'primary',
      group: 'inspection',
      label: 'Manage areas',
      handleAction: () => {
        open({
          title: 'Manage areas',
          taskId: inspectionTask.id
        });
      }
    };
  };
}
