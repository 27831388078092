import * as React from 'react';
import { query } from '@rexlabs/model-generator';
import { ListTable, TabbedTable } from 'view/components/table';
import { Card } from 'view/components/card';
import { ListScreen } from 'view/components/list-screen/list-screen';
import { Tab } from 'view/components/table/tabbed';

import { useTableFilters } from 'view/hooks/use-table-filters';
import { tasksModel } from 'src/modules/tasks/common/models/tasks-model';

import { getLeaseReviewTabbedTableTabs } from 'src/modules/tasks/lease-review/utils/get-lease-review-tabbed-table-tabs';
import { useTranslation } from 'react-i18next';
import { useAppWideFilterContext } from 'src/modules/app-wide-filters/contexts/app-wide-filter-context';
import { LEASE_REVIEW_ROUTES } from '../routes';
import { useLeaseReviewColumns } from '../hooks/use-lease-review-columns';
import { useGetLeaseReviewActions } from '../hooks/action-menu-items/use-get-lease-review-actions';
import { useGetCreateLeaseReviewAction } from '../hooks/action-declarations/use-get-create-lease-review-action';

const getLeaseReviewQuery = () => query`{
  ${tasksModel} {
    id
    property {
        active_property_tenancy {
            rent_schedule
        }
    }
    managed_by
    leaseReviewDetails
  }
}`;

/**
 * Tabbed List Table
 * Model Generator Variant
 */
export function LeaseReviewList() {
  const { appWideFilterKey } = useAppWideFilterContext();

  const { t } = useTranslation();
  const getCreateLeaseReviewAction = useGetCreateLeaseReviewAction();
  const getLeaseReviewActions = useGetLeaseReviewActions();

  const { getSort, getFilters } = useTableFilters('tasks', {
    type_id: 'task_lease_review'
  });

  const getRowLinkProps = ({ item }) => {
    return {
      to: LEASE_REVIEW_ROUTES.LEASE_REVIEW_DETAILS,
      params: { leaseReviewId: item.id }
    };
  };

  const getActionMenuItems = React.useCallback(
    ({ item }) => getLeaseReviewActions(item),
    [getLeaseReviewActions]
  );

  const leaseReviewColumns = useLeaseReviewColumns();
  const columnDependency = JSON.stringify(leaseReviewColumns);

  const commonTabProps = React.useMemo(
    () => ({
      id: 'lease_reviews',
      columns: leaseReviewColumns,
      getQuery: getLeaseReviewQuery,
      Table: ListTable,
      getRowLinkProps,
      getActionMenuItems,
      forcedGlobalFilter: [
        {
          field: 'type_id',
          op: 'eq',
          value: {
            value: 'task_lease_review',
            label: t('tasks.lease-review.label.singular')
          }
        }
      ],
      getFilters,
      getSort,
      shouldUseAppWideFilter: true
    }),
    [columnDependency, getFilters]
  );

  const tabs = React.useMemo<Tab[]>(
    () => getLeaseReviewTabbedTableTabs(commonTabProps),
    [commonTabProps]
  );

  return (
    <ListScreen
      title={t('tasks.lease-review.label.plural')}
      actions={[getCreateLeaseReviewAction()]}
      key={appWideFilterKey}
    >
      <Card>
        <TabbedTable tabs={tabs} />
      </Card>
    </ListScreen>
  );
}
