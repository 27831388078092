import * as React from 'react';
import { DialogProps } from '@rexlabs/dialog';
import { RecordDialog } from 'view/components/record-screen/dialog/dialog';
import { useModelActions } from '@rexlabs/model-generator';
import { propertiesModel } from 'data/models/entities/properties';
import { useToast } from 'view/components/@luna/notifications/toast';
import { Appliance } from '../types/Appliance';
import { applianceDetailsBlock } from '../blocks/appliance-details-block';
import { applianceManualsBlock } from '../blocks/appliance-manuals-block';
import { applianceReceiptsAndWarrantiesBlock } from '../blocks/appliance-receipts-and-warranties-block';
import { appliancesModel } from '../models/appliances-model';
import { mapApplianceToEditFormData } from '../mappers/map-appliance-to-edit-form-data';
import { mapEditApplianceFormToRequest } from '../mappers/map-edit-appliance-form-to-request';

export interface EditApplianceRecordDialogProps extends DialogProps {
  appliance: Appliance;
}

const content = [
  {
    id: 'edit-appliance',
    label: 'Save',
    blocks: [
      applianceDetailsBlock,
      applianceReceiptsAndWarrantiesBlock,
      applianceManualsBlock
    ]
  }
];

export function EditApplianceRecordDialog({
  onClose,
  appliance
}: EditApplianceRecordDialogProps) {
  const { updateItem, refreshLists } = useModelActions(appliancesModel);
  const { refreshItem: refreshProperty } = useModelActions(propertiesModel);

  const { addToast } = useToast();

  const initialValues = {
    ...mapApplianceToEditFormData(appliance)
  };

  const handleSubmit = async ({ values, changedValues }) => {
    const data = {
      ...values,
      ...(await mapEditApplianceFormToRequest(changedValues))
    };

    const { data: appliance } = await updateItem({
      id: data.id,
      data: data
    });

    await Promise.all([
      refreshLists(),
      refreshProperty({
        id: data.property?.id
      })
    ]);

    addToast({
      description: `Successfully updated ${appliance.type}`
    });

    return data;
  };

  return (
    <RecordDialog
      title='Edit appliance details'
      content={content}
      onClose={onClose}
      handleSubmit={handleSubmit}
      data={initialValues}
      submitLabel='Save'
    />
  );
}
