import { FieldArrayPassthroughProps } from '@rexlabs/form';
import { useContext } from 'react';
import { uniqueId } from 'lodash';
import { mapChecklistTemplateItemFormDataToChecklistTemplateItem } from '../mappers/map-checklist-template-item-form-data-to-checklist-template-item';
import { fieldArrayContext } from '../components/smart-checklists/field-array-context';
import {
  injectGroupsForOutcomeItem,
  placeholderPrefix
} from '../utils/inject-groups-for-outcome-item';
import { ChecklistTemplateItemGroup } from '../types/ChecklistTemplate';

export function useGetAddChecklistItemSubmitHandler() {
  const { setFieldValue } = useContext(fieldArrayContext);
  return (
    fieldArrayProps: FieldArrayPassthroughProps,
    index?: number,
    checklistTemplateItemGroup?: ChecklistTemplateItemGroup
  ) =>
    async function handleSubmitFromEmptyState({ values: formValues }) {
      const placeholderId = uniqueId(placeholderPrefix);
      const checklistTemplateItem = mapChecklistTemplateItemFormDataToChecklistTemplateItem(
        { checklistTemplateItemFormData: { id: placeholderId, ...formValues } }
      );

      // If group is defined, inject it now
      if (checklistTemplateItemGroup) {
        checklistTemplateItem.checklist_template_item_group = checklistTemplateItemGroup;
      }

      injectGroupsForOutcomeItem(checklistTemplateItem, setFieldValue);

      const { insert, fields } = fieldArrayProps;
      insert(
        index || index === 0 ? index + 1 : fields.length || 0,
        checklistTemplateItem
      );

      return true;
    };
}
