import { Generator } from 'data/models/generator';
import { User } from 'src/modules/users/types/user-types';
import {
  TimestampAttributes,
  ValueListValue
} from '../../../data/models/types';

export type PortfolioRole = 'property_manager' | 'other';

export type PortfolioMember = {
  id: string;
  user?: User;
  role: ValueListValue<PortfolioRole>;
  is_primary: boolean;
} & Pick<TimestampAttributes, 'created_at' | 'updated_at'>;

const config = {
  entities: {
    related: {
      user: {
        include: 'user'
      }
    }
  }
};

export const portfolioMembersModel = new Generator<PortfolioMember>(
  'portfolio-members',
  config
).createEntityModel();
