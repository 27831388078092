import React from 'react';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack5';

import Box from '@rexlabs/box';
import { OutlineButton } from '@rexlabs/button';
import { Field, HiddenField } from '@rexlabs/form';
import { StyleSheet, text, useStyles } from '@rexlabs/styling';

import { BlockConfig } from 'view/components/record-screen/types';
import { SimpleFileUploadInputWithErrorHandling } from 'view/components/inputs/file-upload-input/simple-file-upload-input-with-error-handling';

export type InvoiceBillFormValues = {
  uploaded_bill?: {
    id: string;
  };
};

const fileUploadCSSVariable = '--file-upload-height';

const defaultStyles = StyleSheet({
  button: {
    marginLeft: ({ token }) => token('spacing.xs')
  },
  text: {
    ...text.styles({
      color: ({ token }) => token('color.textStyle.body.subtext')
    })
  },
  fileUpload: {
    '& label[for="uploaded_bill"]': {
      height: `var(${fileUploadCSSVariable})`,
      display: 'flex',
      flexDirection: 'column',
      gap: 8
    }
  }
});

const validate = {
  definitions: {
    uploaded_bill: {
      name: 'bill file',
      rules: 'uploadedBillMustBePdf'
    }
  }
};

export const invoiceViewerBlock: BlockConfig = {
  id: 'invoice-viewer',
  validate,
  Edit: ({ values, setFieldValue }) => {
    const s = useStyles(defaultStyles);

    const [numPages, setNumPages] = React.useState(0);
    const [pageNumber, setPageNumber] = React.useState(1);
    const [hasError, setHasError] = React.useState(false);

    const wrapperRef = useFullSizeFileInput();

    const fileUrl = values?.uploaded_bill?.file?.url ?? values?.preview_url;
    const hasBillUploaded = fileUrl != null;

    function onDocumentLoadSuccess({ numPages }) {
      setNumPages(numPages);
    }

    function onDocumentLoadError() {
      setHasError(true);
    }

    function changePage(offset) {
      setPageNumber((prevPageNumber) => prevPageNumber + offset);
    }

    function previousPage() {
      changePage(-1);
    }

    function nextPage() {
      changePage(1);
    }

    return (
      <div ref={wrapperRef} {...s('fileUpload')}>
        <HiddenField name='preview_url' />

        {!hasBillUploaded || hasError ? (
          <Field<typeof SimpleFileUploadInputWithErrorHandling>
            id='uploaded_bill'
            name='uploaded_bill'
            Input={SimpleFileUploadInputWithErrorHandling}
            inputProps={{
              accept: 'application/pdf',
              multi: false,
              onFileSelectCallback: (file) => {
                setFieldValue!('preview_url', file?.url);
              }
            }}
            optional={false}
          />
        ) : (
          <Box style={{ maxWidth: '40vw' }}>
            <Box
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center'
              }}
            >
              <Box {...s('text')}>
                Page {pageNumber || (numPages ? 1 : '--')} of {numPages || '--'}
              </Box>
              <Box>
                <OutlineButton
                  {...s('button')}
                  isDisabled={pageNumber <= 1}
                  onClick={previousPage}
                >
                  Previous
                </OutlineButton>
                <OutlineButton
                  {...s('button')}
                  isDisabled={pageNumber >= numPages}
                  onClick={nextPage}
                >
                  Next
                </OutlineButton>
              </Box>
            </Box>
            <Box style={{ overflow: 'scroll' }}>
              <Document
                file={fileUrl}
                onLoadSuccess={onDocumentLoadSuccess}
                onLoadError={onDocumentLoadError}
              >
                <Page pageNumber={pageNumber} />
              </Document>
            </Box>
          </Box>
        )}
      </div>
    );
  }
};

/**
 * Context: We need file-upload input component to occupy full height but currently it is 40px.
 *
 * This hook finds the file-upload label element and updates the height of the input with
 * dialog body's height
 */
const useFullSizeFileInput = () => {
  const wrapperRef = React.useRef<HTMLDivElement>(null);

  React.useLayoutEffect(() => {
    if (!wrapperRef.current) return;

    // This is the block wrapper element.
    const blockElement =
      wrapperRef.current.parentElement?.parentElement?.parentElement;
    if (!blockElement) return;

    wrapperRef.current.style.setProperty(
      fileUploadCSSVariable,
      `${blockElement.offsetHeight}px`
    );
  }, []);

  React.useEffect(() => {
    if (!wrapperRef.current) return;

    // This is the block wrapper element.
    const blockElement =
      wrapperRef.current.parentElement?.parentElement?.parentElement;
    if (!blockElement) return;

    // Whenever the height of the dialog changes, get the height and set as CSS variable.
    const resizeObserver = new ResizeObserver(
      (entries: ResizeObserverEntry[]) => {
        window.requestAnimationFrame(() => {
          if (!Array.isArray(entries) || !entries.length) return;

          for (const entry of entries) {
            wrapperRef.current!.style.setProperty(
              fileUploadCSSVariable,
              `${entry.contentRect.height}px`
            );
          }
        });
      }
    );

    resizeObserver.observe(blockElement);
    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  return wrapperRef;
};
