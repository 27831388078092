import React from 'react';
import { RecordFlag } from 'view/components/record-flags/record-flag';

import { PropertyOwnership } from 'src/modules/property-ownerships/types/property-ownership-types';
import { EmptyProperties } from '../empty/empty-properties';
import { EntryList } from '../components/entry-list';
import { EntryLink } from '../components/entry-link';
import { cap, hasMoreThan } from '../utils';
import { PropertyOwnershipEntryLink } from '../components/property-ownership-entry-link';
import { ViewLink } from '../components/view-link';

export interface PropertyOwnershipsSectionProps {
  propertyOwnerships: PropertyOwnership[] | 'empty';
  maxListLength: number;
}

export function PropertyOwnershipsSection({
  propertyOwnerships,
  maxListLength
}: PropertyOwnershipsSectionProps) {
  if (propertyOwnerships === 'empty' || propertyOwnerships.length === 0) {
    return <EmptyProperties />;
  }

  const maxPropertyOwnerships = Math.round(maxListLength / 2);

  return (
    <EntryList>
      {cap(propertyOwnerships, maxPropertyOwnerships).map(
        (propertyOwnership) => (
          <>
            {propertyOwnership.property && (
              <EntryLink
                key={propertyOwnership.property.id}
                type='property'
                id={propertyOwnership.property.id}
              >
                <RecordFlag
                  record={propertyOwnership.property}
                  recordType='property'
                />
              </EntryLink>
            )}
            <PropertyOwnershipEntryLink propertyOwnership={propertyOwnership} />
          </>
        )
      )}
      {hasMoreThan(propertyOwnerships, maxPropertyOwnerships) && (
        <ViewLink query={{ tab: 'overview' }}>
          View all {propertyOwnerships.length} properties
        </ViewLink>
      )}
    </EntryList>
  );
}
