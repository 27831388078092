function formatChequeNumber(chequeNumber: number, length: number) {
  return new String(chequeNumber).padStart(length, '0');
}

export function* getSequentialChequeNumbers(chequeNumberString: string) {
  const chequeNumberLength = chequeNumberString.length;
  const firstChequeNumber = parseInt(chequeNumberString);

  let chequeNumber = firstChequeNumber;

  while (true) {
    yield formatChequeNumber(chequeNumber, chequeNumberLength);
    chequeNumber = chequeNumber + 1;
  }
}
