import { useTranslation } from 'react-i18next';

import { useSessionState } from 'src/modules/common/hooks/session-state';
import { useRecordsQuery } from 'src/lib/react-query/hooks/use-records-query';
import { Task } from 'src/modules/tasks/common/types/Task';
import { Contact } from 'src/modules/contacts/types/contact-types';

import { Ownership } from 'data/models/entities/ownerships';

import { SummaryRow } from '../../../view/components/summary-table';
import { FolioDisbursement } from '../types/FolioDisbursement';
import { useGetFloatSummaryItem } from '../utils/get-float-summary-item';
import { getTaxWithheldSummaryItem } from '../utils/get-tax-withheld-summary-item';

export function useOpeningFiguresSummary(
  disbursement?: FolioDisbursement,
  object?: Contact | Ownership
): SummaryRow[] {
  const session = useSessionState();
  const { t } = useTranslation();
  const getFloatSummaryItem = useGetFloatSummaryItem();

  const tasksWithFloats = (
    disbursement?.amounts_to_withhold_in_the_disbursement || []
  ).filter(
    (amountToWithhold) => amountToWithhold.entity_record_type === 'task'
  );

  const { data: relatedTasks = [] } = useRecordsQuery<Task<any>>(
    [
      'tasks',
      {
        filter: [
          {
            op: 'or',
            value: tasksWithFloats.map((taskWithFloat) => ({
              field: 'id',
              op: 'eq',
              value: taskWithFloat.entity_id,
              logicalOperator: 'or'
            }))
          }
        ],
        perPage: 100
      }
    ],
    {
      enabled: !!tasksWithFloats.length,
      staleTime: Infinity,
      cacheTime: Infinity
    }
  );

  if (disbursement === undefined || object === undefined) return [];

  const showWithheldFunds = object?.__record_type === 'ownership';

  const showTaxWithheld =
    showWithheldFunds && session.activeSilo?.country.id === 'GBR';

  return [
    {
      description: 'Total cleared balance',
      amount: disbursement.trust_balance_amount || 0,
      id: 'total_cleared_balance'
    },
    ...(showTaxWithheld
      ? [
          getTaxWithheldSummaryItem(
            'HMRC tax withheld to date',
            disbursement.total_tax_currently_being_withheld || 0,
            disbursement.tax_currently_being_withheld_by_owner,
            object
          )
        ]
      : []),
    {
      description: 'Funds reserved for specific payments',
      amount: disbursement.funds_reserved_for_specific_payments || 0,
      id: 'funds_reserved_for_specific_payments'
    },
    ...(showWithheldFunds
      ? [
          getFloatSummaryItem(
            t('withheld-funds.disbursement-summary.withheld-funds.label'),
            disbursement.total_amount_to_withhold_in_the_disbursement || 0,
            disbursement.amounts_to_withhold_in_the_disbursement,
            relatedTasks
          )
        ]
      : []),
    {
      description: 'Available folio funds balance',
      amount:
        (disbursement.available_trust_balance_amount || 0) -
        (disbursement?.total_amount_to_withhold_in_the_disbursement || 0),
      id: 'available_folio_funds_balance'
    }
  ];
}
