import React from 'react';

import { StyleSheet, useStyles, mq } from '@rexlabs/styling';

const defaultStyles = StyleSheet({
  container: {
    height: '100%',
    width: '100%',
    // The reason for this is to ensure that the user can see the options of selects at the bottom of the page, especially when the page is large.
    marginBottom: '40rem',
    ...mq.styles.combine([
      {
        queries: {
          maxWidth: 's'
        },
        styles: {
          padding: '0rem'
        }
      },
      {
        queries: {
          minWidth: 's',
          maxWidth: 'l'
        },
        styles: {
          padding: '2rem'
        }
      },
      {
        queries: {
          minWidth: 'l'
        },
        styles: {
          padding: '2.4rem 4rem'
        }
      }
    ])
  },

  inner: {
    height: '100%',
    width: '100%',
    maxWidth: '165.2rem',
    margin: '0 auto',
    position: 'relative'
  }
});

export function PageLayout({
  children,
  ...props
}: React.AllHTMLAttributes<HTMLDivElement>) {
  const s = useStyles(defaultStyles);
  return (
    <div {...props} {...s('container')}>
      <div {...s('inner')}>{children}</div>
    </div>
  );
}
