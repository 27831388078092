import React from 'react';
import { AxiosResponse } from 'axios';

import { useModelActions } from '@rexlabs/model-generator';

import { AlfredFile } from 'src/modules/common/types/file';

import { api } from 'utils/api/api-client';
import {
  RequestPipelineRequest,
  postRequestPipelineRequests
} from 'utils/api/post-request-pipeline';

import { Document, documentsModel } from 'data/models/entities/documents';

import { Attachment, Message } from '../types/Message';
import { AddDocumentTypeToARecordBlockValues } from '../blocks/add-document-type-to-record-block';
import { fetchMessageWithAttachments } from '../utils/fetch-message-with-attachments';
import { MessageIssue } from '../../common/types/message-issue';

export function useDocumentMessageIssueSubmitHandler(issue: MessageIssue) {
  const [currentDocument, setCurrentDocument] = React.useState<Document | null>(
    null
  );

  const { refreshLists } = useModelActions(documentsModel);

  return async (
    updatedValues: AddDocumentTypeToARecordBlockValues,
    values: AddDocumentTypeToARecordBlockValues
  ) => {
    const updateType = getUpdateType({
      changedValues: updatedValues,
      hasExistingFileOfType: Boolean(currentDocument)
    });

    switch (updateType) {
      case 'add': {
        const document = await handleAddDocument({
          values,
          updatedValues,
          issue
        });
        setCurrentDocument(document);
        break;
      }

      case 'rename':
        handleRenameDocument({
          newName: updatedValues.name,
          documentId: currentDocument!.id
        });
        break;

      case 'update': {
        const document = await handleUpdateDocument({
          values,
          updatedValues,
          issue,
          currentDocument: currentDocument!
        });

        setCurrentDocument(document);

        break;
      }
    }

    return refreshLists();
  };
}

const updateTypes = ['add', 'rename', 'update'] as const;
type UpdateType = typeof updateTypes[number];

/**
 * This function is used to return the type of update we're performing
 */
function getUpdateType({
  changedValues,
  hasExistingFileOfType
}: {
  changedValues: AddDocumentTypeToARecordBlockValues;
  hasExistingFileOfType: boolean;
}): UpdateType {
  if (hasExistingFileOfType && changedValues.file) {
    return 'update';
  }

  if (hasExistingFileOfType && changedValues.name) {
    return 'rename';
  }

  return 'add';
}

/**
 * This function is used to handle adding a document to a record and a message and returns the document
 * that was created.
 * @returns Document
 */
async function handleAddDocument({
  values,
  updatedValues,
  issue
}: {
  values: AddDocumentTypeToARecordBlockValues;
  updatedValues: AddDocumentTypeToARecordBlockValues;
  issue: MessageIssue;
}): Promise<Document> {
  const file = await resolveFilePromise(updatedValues);

  const messageWithAttachments = await fetchMessageWithAttachments({
    messageId: issue.message!.id
  });

  const createDocumentRequest = getCreateDocumentPipelineRequest({
    fileId: file.id,
    fileName: values.name,
    issue
  });

  const updateMessageRequest = getUpdateMessagePipelineRequest({
    messageId: issue.message!.id,
    updatedMessage: {
      inline_attachments: [
        ...(messageWithAttachments?.inline_attachments || []),
        {
          file: {
            id: file.id
          }
        } as Attachment
      ]
    }
  });

  const { data } = await postRequestPipelineRequests<
    [typeof createDocumentRequest, typeof updateMessageRequest],
    [Document, Message]
  >([createDocumentRequest, updateMessageRequest]);

  return data[0];
}

async function handleRenameDocument({
  newName,
  documentId
}: {
  newName: string;
  documentId: string;
}) {
  return api.patch<Document>(`/documents/${documentId}`, {
    name: newName
  });
}

/**
 * This function is used to replace the current document on a record and a message with a new document, and returns the document
 * that was created.
 * @returns Document
 */
async function handleUpdateDocument({
  values,
  updatedValues,
  issue,
  currentDocument
}: {
  currentDocument: Document;
  issue: MessageIssue;
  values: AddDocumentTypeToARecordBlockValues;
  updatedValues: AddDocumentTypeToARecordBlockValues;
}): Promise<Document> {
  const file = await resolveFilePromise(updatedValues);

  const { inline_attachments = [] } = await fetchMessageWithAttachments({
    messageId: issue.message!.id
  });

  const filteredInlineAttachments = inline_attachments.filter(
    (attachment) => attachment.file.id !== currentDocument!.file.id
  );

  const deleteDocumentRequest = getDeleteDocumentPipelineRequest({
    documentId: currentDocument!.id
  });

  const createDocumentRequest = getCreateDocumentPipelineRequest({
    fileId: file.id,
    fileName: values.name,
    issue
  });

  const updateMessageRequest = getUpdateMessagePipelineRequest({
    messageId: issue.message!.id,
    updatedMessage: {
      inline_attachments: [
        ...filteredInlineAttachments,
        {
          file: {
            id: file.id
          }
        } as Attachment
      ]
    }
  });

  const { data } = await postRequestPipelineRequests<
    [
      typeof deleteDocumentRequest,
      typeof createDocumentRequest,
      typeof updateMessageRequest
    ],
    [null, Document, Message]
  >([deleteDocumentRequest, createDocumentRequest, updateMessageRequest]);

  return data[1];
}

function getUpdateMessagePipelineRequest({
  messageId,
  updatedMessage
}: {
  messageId: string;
  updatedMessage: Partial<Message>;
}): RequestPipelineRequest {
  return {
    method: 'PATCH',
    path: `/api/v1/communication/messages/${messageId}`,
    json: updatedMessage
  };
}

function getCreateDocumentPipelineRequest({
  fileId,
  fileName,
  issue
}: {
  fileId: string;
  fileName: string;
  issue: MessageIssue;
}): RequestPipelineRequest {
  return {
    method: 'POST',
    path: '/api/v1/documents',
    json: {
      name: fileName,
      file: { id: fileId },
      attached_to: [
        {
          id: issue.message!.context.id,
          type: { id: issue.message?.context.__record_type },
          label: issue.message?.context.__record_type
        }
      ],
      record_type: issue.message?.context.__record_type,
      type: issue.missing_document_type,
      include: 'file'
    }
  };
}

function getDeleteDocumentPipelineRequest({
  documentId
}: {
  documentId: string;
}): RequestPipelineRequest {
  return {
    method: 'DELETE',
    path: `/api/v1/documents/${documentId}`
  };
}

async function resolveFilePromise(updatedValues) {
  const { data: file }: AxiosResponse<AlfredFile> = await updatedValues.file
    .data;

  return file;
}
