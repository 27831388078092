export const DISBURSEMENT_ROUTES = {
  DISBURSEMENT_LIST: {
    config: {
      path: '/disbursements',
      Component: require('./screens/disbursements-list').DisbursementsList
    }
  },
  PENDING_OWNERSHIP_DISBURSEMENT: {
    config: {
      path: '/pending-ownership-disbursement/:pendingOwnershipDisbursementId',
      Component: require('./screens/pending-ownership-disbursement-details')
        .PendingOwnershipDisbursementDetailsScreen
    }
  },
  PENDING_CONTACT_DISBURSEMENT: {
    config: {
      path: '/pending-contacts-disbursement/:pendingContactDisbursementId',
      Component: require('./screens/pending-contact-disbursement-details')
        .PendingContactDisbursementDetailsScreen
    }
  },
  COMPLETED_DISBURSEMENT: {
    config: {
      path: '/completed-disbursement/:completedDisbursementId',
      Component: require('./screens/completed-disbursement-details')
        .CompletedDisbursementDetailsScreen
    }
  }
};
