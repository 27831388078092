import React from 'react';

import { DialogProps } from '@rexlabs/dialog';

import { RecordDialog } from 'view/components/record-screen/dialog/dialog';
import { RecordSubmitHandler } from 'view/components/record-screen/utils';
import { reasonForChangeBlock } from '../blocks/reason-change-block';

const content = [
  {
    id: 'details',
    label: 'Details',
    blocks: [reasonForChangeBlock]
  }
];

interface ReasonForChangeDialoProps extends DialogProps {
  handleSubmit: RecordSubmitHandler;
  handleClose: () => void;
  title: string;
  submitLabel?: string;
}

export function ReasonForChangeDialog({
  onClose,
  handleClose,
  handleSubmit,
  title,
  submitLabel = 'Save changes'
}: ReasonForChangeDialoProps) {
  return (
    <RecordDialog
      title={title}
      onClose={() => {
        handleClose();

        onClose?.();
      }}
      content={content}
      submitLabel={submitLabel}
      handleSubmit={handleSubmit}
    />
  );
}
