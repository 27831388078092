import { ActionDeclaration } from 'src/modules/common/actions/types/action-declaration-types';
import { useDialog } from '@rexlabs/dialog';
import { Ownership } from 'data/models/entities/ownerships';

import { Property } from 'src/modules/properties/types/property-types';
import { useRefreshScreen } from 'view/hooks/use-refresh-screen';
import { useTranslation } from 'react-i18next';
import { CreatePropertyOwnershipDialog } from '../dialogs/create-property-ownership-dialog';

export interface GetCreatePropertyOwnershipActionArgs {
  property?: Property;
  ownership?: Ownership;
}

export function useGetCreatePropertyOwnershipAction() {
  const { open: openCreatePropertyOwnershipDialog } = useDialog(
    CreatePropertyOwnershipDialog
  );
  const { refreshScreen } = useRefreshScreen();
  const { t } = useTranslation();

  return ({
    property,
    ownership
  }: GetCreatePropertyOwnershipActionArgs): ActionDeclaration => ({
    id: 'create-property-ownership',
    group: 'property-ownership',
    label: t(
      'property-ownerships.management-agreement.create.label.singular'
    ) as string,
    handleAction: () => {
      openCreatePropertyOwnershipDialog({
        property,
        ownership,
        onCreate: refreshScreen
      });
    }
  });
}
