import { useDialog } from '@rexlabs/dialog';

import { LinkTasksDialog } from 'src/modules/tasks/common/dialogs/link-tasks-dialog';

export function useGetUpdateTaskLinkAction({ data }) {
  const linkTasksDialog = useDialog(LinkTasksDialog);

  return ({ task }) => ({
    label: 'Update link',
    handleAction: () => linkTasksDialog.open({ task: data!, linkedTask: task })
  });
}
