import React from 'react';

export interface MouseBoundaryProps {
  className: string;
  onEnter: () => void;
  onLeave: () => void;
}

export const useMouseBoundary = ({
  className,
  onEnter,
  onLeave
}: MouseBoundaryProps) => {
  const [
    alreadyInsideBoundary,
    setAlreadyInsideBoundary
  ] = React.useState<boolean>(false);
  React.useEffect(() => {
    const handleMouseMove = (event: MouseEvent) => {
      const divRef = document.getElementsByClassName(className)?.[0];
      if (divRef) {
        const rect = divRef.getBoundingClientRect();
        const isInBoundaries =
          event.clientX >= rect.left &&
          event.clientX <= rect.right &&
          event.clientY >= rect.top &&
          event.clientY <= rect.bottom;

        if (isInBoundaries && !alreadyInsideBoundary) {
          onEnter();
          setAlreadyInsideBoundary(true);
        } else if (!isInBoundaries && alreadyInsideBoundary) {
          onLeave();
          setAlreadyInsideBoundary(false);
        }
      }
    };

    document.addEventListener('mousemove', handleMouseMove);

    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
    };
  }, [alreadyInsideBoundary, className, onEnter, onLeave]);
};
