import { ActionMenuItem } from '@rexlabs/action-menu';
import { transformActionDeclarationsToActionMenuItems } from 'utils/actions/transforms';
import { ChannelMessageRecipient } from '../../types/ChannelMessageRecipient';
import { useGetResendMessageAction } from '../action-declarations/use-get-resend-message-action';
import {
  getViewMessageActionArgs,
  useGetViewMessageAction
} from '../action-declarations/use-get-view-message-action';
import { useGetEditMessageAndResendAction } from '../action-declarations/use-get-edit-message-and-resend-action';

export function useGetMessageSentActions() {
  const getViewMessageAction = useGetViewMessageAction();
  const getResendMessageAction = useGetResendMessageAction();
  const getEditMessageAndResendAction = useGetEditMessageAndResendAction();

  return (
    channelMessageRecipient?: ChannelMessageRecipient
  ): ActionMenuItem[] => {
    if (!channelMessageRecipient) return [];

    const result = [
      getViewMessageAction(getViewMessageActionArgs(channelMessageRecipient)),
      ...(channelMessageRecipient.channel_message
        ? [getEditMessageAndResendAction(channelMessageRecipient)]
        : []),
      getResendMessageAction(channelMessageRecipient)
    ];

    return transformActionDeclarationsToActionMenuItems(result);
  };
}
