import React from 'react';

import Box from '@rexlabs/box';
import { Heading } from '@rexlabs/text';

import { Card, CardContent } from 'view/components/@luna/card';
import { Columns, ListTable } from 'view/components/table';

import { AnnualPayments } from '../types/annual-payments';
import { annualPaymentsFactory } from '../factories/annual-payments-factory';

const columns: Columns<AnnualPayments> = [
  { id: 'contact', Header: 'Owner', type: 'contact' },
  { id: 'property', Header: 'Property', type: 'property' },
  { id: 'ownership', Header: 'Ownership', type: 'percentage' },
  { id: 'income', Header: 'Income', type: 'currency' },
  { id: 'expenses', Header: 'Expenses', type: 'currency' }
];

export function ResidentLandlordsStatements() {
  const mockData = React.useMemo(
    () => Array.from({ length: 10 }, () => annualPaymentsFactory()),
    []
  );
  return (
    <Card>
      <CardContent>
        <Box
          flexDirection='row'
          alignItems='center'
          justifyContent='space-between'
          sx='1.2rem'
          flex={1}
        >
          <Heading level={3}>Resident landlords</Heading>
        </Box>
        <ListTable
          id='resident-landlord'
          items={mockData}
          columns={columns}
          isLoading={false}
        />
      </CardContent>
    </Card>
  );
}
