import React, { ReactNode, PropsWithChildren } from 'react';

import { StylesProvider, text } from '@rexlabs/styling';
import { Breakpoints } from '@rexlabs/breakpoints';

import { Card, CardContent, CardDivider } from 'view/components/@luna/card';

export type RecordSummaryCardProps = PropsWithChildren<{
  titleBlock: ReactNode;
}>;

const overrides = {
  titleBlock: {
    avatar: {
      size: 'm'
    },
    heading: {
      ...text.tokens({
        fallback: 'heading.3'
      })
    },
    identifier: {
      ...text.tokens({
        fallback: 'tiny.semibold'
      })
    }
  }
};

export function RecordSummaryCard({
  children,
  titleBlock
}: RecordSummaryCardProps) {
  return (
    <Card width='100%' Container='header'>
      <CardContent flexDirection='column'>
        <StylesProvider tokens={overrides}>{titleBlock}</StylesProvider>
        {children && titleBlock ? (
          <Breakpoints queries={{ maxWidth: 's' }}>
            <CardDivider />
          </Breakpoints>
        ) : null}
        {children}
      </CardContent>
    </Card>
  );
}
