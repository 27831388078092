import React from 'react';
import { RadioButton } from '@rexlabs/radio-input';
import { FinancialEntity } from 'src/modules/financials/utils/financial-entity-action-group/use-get-financial-entity-actions';
import { Tenancy } from 'data/models/entities/tenancies';
import { pluralize } from 'utils/formatters';
import { getRentAndFrequency } from 'src/modules/rent/utils/get-rent-and-frequency';
import Tooltip from '@rexlabs/tooltip';
import { StyleSheet, useStyles } from '@rexlabs/styling';
import { PropertyTenancy } from 'src/modules/property-tenancies/types/property-tenancy-types';
import { PropertyOwnership } from 'src/modules/property-ownerships/types/property-ownership-types';
import { Ownership } from 'data/models/entities/ownerships';
import { Contact } from 'src/modules/contacts/types/contact-types';
import { getViewRecordRouteDetails } from 'src/modules/common/utils/Records/get-record-routes';
import { getRecordObject } from 'src/modules/common/utils/Records/get-record-object';
import OpenIcon from 'view/components/icons/open';

function RadioCell({
  value,
  selectedFolio,
  setSelectedFolio
}: {
  value: FinancialEntity;
  selectedFolio: FinancialEntity | undefined;
  setSelectedFolio: React.Dispatch<React.SetStateAction<FinancialEntity>>;
}) {
  const currentId = value.id;
  const styles = { paddingTop: 0 };
  const commonProps = {
    id: currentId,
    name: 'selected-folio',
    style: styles
  };
  return currentId === selectedFolio?.id ? (
    <RadioButton {...commonProps} defaultChecked checked={true}></RadioButton>
  ) : (
    <RadioButton
      {...commonProps}
      onClick={() => setSelectedFolio(value)}
      data-testId='folio-radio'
    ></RadioButton>
  );
}

const listStyles = StyleSheet({
  list: {
    listStyleType: 'number',
    paddingLeft: ({ token }) => token('spacing.l')
  }
});

const CustomTooltip = ({
  items,
  label
}: {
  items: string[];
  label: string;
}) => {
  const s = useStyles(listStyles);

  return (
    <Tooltip
      Content={() => {
        return (
          <div
            style={{
              maxHeight: 250,
              height: 'fit-content',
              overflow: 'scroll'
            }}
          >
            <ol {...s('list')}>
              {(items || []).map((item, index) => {
                return <li key={index}>{item}</li>;
              })}
            </ol>
          </div>
        );
      }}
    >
      <div>{label}</div>
    </Tooltip>
  );
};

function getNotArchivedAgreements(
  agreements: Array<PropertyTenancy | PropertyOwnership>
) {
  return agreements.filter((agreement) => agreement.status.id !== 'archived');
}

function PropertiesCell({ value }: { value: Tenancy | Ownership }) {
  const agreements =
    value?.__record_type === 'tenancy'
      ? value.property_tenancies?.data
      : value.property_ownerships?.data;

  const nonArchivedAgreements = getNotArchivedAgreements(agreements ?? []);

  const count = nonArchivedAgreements?.length ?? 0;

  const propertyDisplayNames = nonArchivedAgreements.map(
    (agreement) => agreement.property?.display_name
  );

  return count > 0 ? (
    <CustomTooltip
      items={propertyDisplayNames}
      label={pluralize('properties', count, true)}
    />
  ) : (
    '--'
  );
}

function RentCell({ value }: { value: Tenancy }) {
  const nonArchivedPropertyTenancies = getNotArchivedAgreements(
    value.property_tenancies?.data ?? []
  ) as PropertyTenancy[];

  const rents = nonArchivedPropertyTenancies.reduce<string[]>(
    (rentValues, currentPropertyTenancy) => {
      const rentSchedules = currentPropertyTenancy.rent_schedule || [];

      const currentRentSchedule =
        rentSchedules &&
        rentSchedules.find((schedule) => schedule.status.id === 'current');

      if (currentRentSchedule) {
        return [
          ...rentValues,
          getRentAndFrequency({ rentScheduleEntry: currentRentSchedule })
        ];
      }

      return rentValues;
    },
    []
  );

  const count = rents?.length ?? 0;

  return count > 0 ? (
    <CustomTooltip items={rents} label={pluralize('rent', count, true)} />
  ) : (
    '--'
  );
}

const linkStyles = StyleSheet({
  link: {
    textDecoration: 'unset',
    color: ({ token }) => token('color.textStyle.body.default'),
    '&:hover': {
      color: 'unset'
    }
  }
});

function RecordLinkCell({ value }: { value: Tenancy | Ownership | Contact }) {
  const s = useStyles(linkStyles);

  return (
    <a
      {...s('link')}
      href={
        getViewRecordRouteDetails(getRecordObject(value, value?.__record_type))
          .path
      }
      target='_blank'
      rel='noopener noreferrer nofollow'
    >
      <OpenIcon />
    </a>
  );
}

export function getFinancialFolioTabbedTableColumns({
  selectedFolio,
  setSelectedFolio,
  recordType
}: {
  selectedFolio: FinancialEntity | undefined;
  setSelectedFolio: React.Dispatch<React.SetStateAction<FinancialEntity>>;
  recordType: 'ownership' | 'tenancy' | 'contact';
}) {
  const radioColumn = {
    id: 'id',
    accessor: (item) => item,
    Cell: ({ value }) => (
      <RadioCell
        value={value}
        setSelectedFolio={setSelectedFolio}
        selectedFolio={selectedFolio}
      ></RadioCell>
    ),
    width: 50,
    sticky: 'right'
  };

  const linkColumn = {
    id: 'link',
    accessor: (item) => item,
    Cell: RecordLinkCell,
    width: 50
  };

  const propertiesColumn = {
    id: 'properties',
    accessor: (item) => item,
    Header: 'In property',
    Cell: PropertiesCell,
    width: 200
  };

  switch (recordType) {
    case 'ownership':
      return [
        radioColumn,
        {
          id: 'display_name',
          Header: 'Ownership'
        },
        propertiesColumn,
        linkColumn
      ];
    case 'tenancy':
      return [
        radioColumn,
        {
          id: 'display_name',
          Header: 'Tenancy'
        },
        propertiesColumn,
        {
          id: 'rent',
          accessor: (item) => item,
          Header: 'Rent',
          Cell: RentCell,
          width: 150
        },
        linkColumn
      ];
    case 'contact':
      return [
        radioColumn,
        {
          id: 'display_name',
          Header: 'Contact'
        },
        {
          id: 'is_supplier',
          accessor: (item) => item,
          Header: 'Is supplier',
          Cell: ({ value }) => (value?.is_supplier ? 'Yes' : 'No'),
          width: 200
        },
        linkColumn
      ];
  }
}
