import * as React from 'react';
import { TagConfig } from 'view/components/@luna/title-block';
import { ComplianceProgressDropdown } from 'src/modules/tasks/common/components/compliance-progress-dropdown';
import { PropertyComplianceTask } from 'src/modules/tasks/property-compliance/types/PropertyComplianceTask';
import { SupplierComplianceTask } from 'src/modules/tasks/supplier-compliance/types/SupplierComplianceTask';

// gotta be memo'd otherwise we get infinite rerenders
export function useComplianceStatusTag(
  task?: PropertyComplianceTask | SupplierComplianceTask
): TagConfig {
  const Tag = React.useMemo(
    () => () => <ComplianceProgressDropdown value={task} />,
    [task?.status?.id]
  );

  return {
    label: task?.status?.label || '',
    Tag
  };
}
