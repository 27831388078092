import React from 'react';
import { BlockConfig } from 'view/components/record-screen/types';
import { Column, Grid } from '@rexlabs/grid';
import { Field } from '@rexlabs/form';
import { TextInput } from '@rexlabs/text-input';
import { ValueListSelect } from 'view/components/inputs/selects/value-list';
import { PercentageInput } from 'view/components/inputs/percentage-input';
import { CurrencyInput } from 'view/components/table/toolbar/inputs/currency-input';

const FLAT_FEE = 'flat_fee';
const PERCENTAGE = 'percentage';

const COMMISSION_INPUTS = {
  [FLAT_FEE]: CurrencyInput,
  [PERCENTAGE]: PercentageInput
};

const COMMISSION_INPUTS_NAME = {
  [FLAT_FEE]: 'commission_amount_currency',
  [PERCENTAGE]: 'commission_amount_percent'
};

export const supplierCommissionDetailsBlock: BlockConfig = {
  id: 'supplier-commission-details',
  title: 'Details',
  validate: {
    definitions: {
      name: { rules: 'required' },
      structure_type: { rules: 'required' },
      commission_amount_currency: {
        rules: `required_if:structure_type.id,${FLAT_FEE}|currency_min:0`
      },
      commission_amount_percent: {
        rules: `required_if:structure_type.id,${PERCENTAGE}|min:0`
      }
    }
  },
  Edit: ({ values, setFieldValue }) => {
    const structureType = values.structure_type?.id;
    const hasCommissionAmount = [FLAT_FEE, PERCENTAGE].includes(structureType);

    const handleStructureBlur = React.useCallback(() => {
      setFieldValue?.('commission_amount_currency', undefined);
      setFieldValue?.('commission_amount_percent', undefined);
    }, [setFieldValue]);

    return (
      <Grid columns={4}>
        <Column width={2}>
          <Field
            name='name'
            label='Commission template name'
            Input={TextInput}
          />
        </Column>

        <Column width={2}>
          <Field
            name='structure_type'
            label='Structure'
            Input={ValueListSelect}
            onBlur={handleStructureBlur}
            inputProps={{
              type: 'commission_structure_type'
            }}
          />
        </Column>

        {hasCommissionAmount ? (
          <>
            <Column width={2}>
              <Field
                name={COMMISSION_INPUTS_NAME[structureType]}
                label='Commission'
                Input={COMMISSION_INPUTS[structureType]}
                optional={false}
              />
            </Column>
          </>
        ) : null}
      </Grid>
    );
  }
};
