import { Dropdown } from '@rexlabs/select-old';
import { InputProps } from '@rexlabs/form';
import { useCachedItems } from 'view/hooks/api/cached-items';
import { api } from 'utils/api/api-client';
import React from 'react';
import { toQuri } from '@rexlabs/quri';
import { SecurityDepositType } from 'data/models/entities/financials/security-deposit-types';
import { normaliseSecurityDeposit } from 'utils/normaliser/security-deposit-types';

interface SecurityDepositTypesProps extends InputProps {
  triggerType?: string;
  active?: boolean;
}

export function SecurityDepositTypes({
  active = true,
  ...props
}: SecurityDepositTypesProps) {
  const filter = toQuri([
    {
      field: 'status_id',
      op: '==',
      value: active ? 'active' : 'archived'
    }
  ]);
  const securityDepositTypesUrl = `/financials/security-deposit-types?q=${filter}&include=held_by_bank_account`;

  const { loadItems } = useCachedItems(securityDepositTypesUrl, async () => {
    const securityDepositTypes = await api.get(securityDepositTypesUrl);
    return securityDepositTypes.data as SecurityDepositType[];
  });

  return (
    <Dropdown
      {...props}
      items={loadItems}
      normaliser={normaliseSecurityDeposit}
    />
  );
}
