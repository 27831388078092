import * as React from 'react';

import { DialogProps } from '@rexlabs/dialog';

import { RecordDialog } from 'view/components/record-screen/dialog/dialog';

import { getLngLatFromProperty } from 'src/modules/properties/utils/get-lng-lat-from-property';

import { fixInspectionLocationIssuesBlock } from '../blocks/fix-inspection-location-issues-block';
import { InspectionTask } from '../types/InspectionTask';

export interface FixInspectionLocationIssuesRecordDialogProps
  extends DialogProps {
  data: { inspections: InspectionTask[] };
  onSave?: (props?: any) => any;
}

const content = [
  {
    id: 'fix-inspection-location-issues',
    label: 'Location missing',
    blocks: [fixInspectionLocationIssuesBlock]
  }
];

export function FixInspectionLocationIssuesRecordDialog({
  onClose,
  onSave,
  data
}: FixInspectionLocationIssuesRecordDialogProps) {
  const { inspections } = data;

  const handleSubmit = async ({ values: { inspections } }) => {
    onSave?.(inspections);
    onClose?.();
  };

  return (
    <RecordDialog
      title='Location missing'
      submitLabel={'Save'}
      initialValues={{
        issues: inspections.map(
          (inspection) => !!getLngLatFromProperty(inspection.property)
        ),
        inspections
      }}
      data={inspections}
      content={content}
      onClose={onClose}
      handleSubmit={handleSubmit}
    />
  );
}
