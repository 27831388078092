import React, { useMemo } from 'react';
import {
  ActionsViewProps,
  BlockConfig
} from 'view/components/record-screen/types';
import { Column, ListTable, TabbedTable } from 'view/components/table';
import { financialsReconciliationsAdjustmentsModel } from 'data/models/entities/financials/reconciliations/adjustments';
import { query } from '@rexlabs/model-generator';
import { Reconciliation } from 'data/models/entities/financials/reconciliations';
import { useDialog } from '@rexlabs/dialog';
import { ActionButtons } from 'view/components/action-buttons';
import { useTableFilters } from 'view/hooks/use-table-filters';
import { AdjustmentActionCell } from 'src/modules/reconciliations/components/adjustments/adjustment-action-cell';
import { Tab } from 'view/components/table/tabbed';
import { CreateAdjustmentDialog } from '../dialogs/create-adjustment';
import { getReconciledInThisReconciliationFilters } from '../utils/get-reconciled-in-this-reconciliation-filters';

const adjustmentsColumns = [
  {
    id: 'date_of',
    type: 'date',
    Header: 'Adjustment date'
  },
  {
    id: 'type',
    type: 'value',
    Header: 'Type'
  },
  {
    id: 'reason',
    Header: 'Reason',
    accessor: (item) => `${item.type?.label}: ${item.reason}`
  },
  {
    id: 'source_reconciliation',
    type: 'reconciliation',
    Header: 'Source',
    width: 220
  },
  {
    id: 'effective_adjustment_amount',
    type: 'currency',
    width: 120,
    cellProps: {
      align: 'right'
    },
    Header: 'Amount'
  }
];

const toggleResolvedAction = (reconciliation: Reconciliation): Column => ({
  id: 'action',
  accessor: (item) => ({ ...item, reconciliation }),
  Cell: AdjustmentActionCell,
  width: 120,
  sticky: 'right'
});

const getReconciliationAdjustments = (
  reconciliation: Reconciliation
) => () => ({
  ...query`{
  ${financialsReconciliationsAdjustmentsModel} (reconciliationId: ${reconciliation.id}) {
    id
    source_reconciliation
    reconciliation_status
  }
}`,
  uuid: 'reconciliation-adjustments'
});

function AdjustmentActions({ data }: ActionsViewProps<Reconciliation>) {
  const { open } = useDialog(CreateAdjustmentDialog);

  return (
    <ActionButtons
      actions={[
        {
          label: 'Add new adjustment',
          onClick: () => {
            open({
              reconciliation: data!,
              updateListId: 'reconciliation-adjustments'
            });
          }
        }
      ]}
    />
  );
}

export const adjustmentsBlock: BlockConfig<Reconciliation> = {
  id: 'adjustments',
  title: 'Adjustments',
  View: ({ data }) => {
    const reconciliation = data!;

    const { getFilters, getSort } = useTableFilters(
      'reconciliation-adjustments'
    );

    const isUnfinishedReconciliation =
      reconciliation.status.id !== 'reconciled';

    const tabs = useMemo<Tab[]>(() => {
      const commonProps = {
        id: 'reconciliation-adjustments',
        Table: ListTable,
        columns: [
          ...adjustmentsColumns,
          ...(isUnfinishedReconciliation
            ? [toggleResolvedAction(reconciliation)]
            : [])
        ],
        getQuery: getReconciliationAdjustments(reconciliation),
        getFilters,
        getSort,
        suggestedFilters: ['date_of', 'type_id'],
        initialSortBy: [{ id: 'date_of', desc: true }]
      };

      return [
        {
          ...commonProps,
          forcedGlobalFilter: [
            {
              field: 'reconciled',
              op: 'eq',
              value: false
            },
            {
              field: 'status_id',
              op: 'eq',
              value: 'unresolved'
            }
          ],
          name: 'unresolved-adjustments',
          label: 'Unresolved'
        },
        {
          ...commonProps,
          forcedGlobalFilter: [
            {
              field: 'reconciled',
              op: 'eq',
              value: true
            },
            {
              field: 'status_id',
              op: 'eq',
              value: 'resolved'
            },
            ...getReconciledInThisReconciliationFilters(data!)
          ],
          name: 'resolved-adjustments-in-this-reconciliation',
          label: 'This reconciliation'
        },
        {
          ...commonProps,
          forcedGlobalFilter: [
            {
              field: 'reconciled',
              op: 'eq',
              value: true
            },
            {
              field: 'status_id',
              op: 'eq',
              value: 'resolved'
            }
          ],
          name: 'resolved-adjustments',
          label: 'Resolved'
        }
      ];
    }, [getFilters, getSort, isUnfinishedReconciliation, reconciliation]);

    return <TabbedTable tabs={tabs} />;
  },
  Actions: AdjustmentActions
};
