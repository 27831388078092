import { useDialog } from '@rexlabs/dialog';

import { SingleActionDeclaration } from 'src/modules/common/actions/types/action-declaration-types';
import { Task } from 'src/modules/tasks/common/types/Task';
import { TaskType } from 'src/modules/tasks/common/types/TaskType';

import { AddIcon } from 'view/components/icons/add';

import { AddChecklistDialog } from '../../dialogs/add-checklist-dialog';

export function useGetAddChecklistAction() {
  const { open: openAddChecklistDialog } = useDialog(AddChecklistDialog);

  return (task?: Task<TaskType>): SingleActionDeclaration => {
    if (!task) return {};

    return {
      id: 'add-checklist',
      intent: 'secondary',
      group: 'checklist',
      label: 'Add checklist',
      Icon: AddIcon,
      handleAction: () =>
        openAddChecklistDialog({
          task
        })
    };
  };
}
