import React, { useEffect } from 'react';
import { BlockConfig } from 'view/components/record-screen/types';

import { Message } from '@rexlabs/notifications';
import { EmptyCard } from 'view/components/record-screen/cards/empty-card';
import EmptyTable from 'src/assets/illustrations/empty-table.svg';
import { TrustJournalEntry } from 'src/modules/trust-journal-entries/types/trust-journal-entry-types';
import { Trans, useTranslation } from 'react-i18next';
import { SelectedTrustJournalEntriesTable } from '../components/selected-trust-journal-entries-table';

export const selectedReceiptsBlock: BlockConfig<TrustJournalEntry[]> = {
  id: 'selected-trust-journal-entries',
  title: () => (
    <Trans i18nKey='banking.batch-receipting.selected-trust-journal-entries.title' />
  ),
  Edit: ({ blockProps, setFieldValue }) => {
    const { t } = useTranslation();

    const { selectedReceipts, bankStatementTransactionId } = blockProps;

    // Set the selected receipts into the form values
    // so we can access them on form submit
    useEffect(
      () => setFieldValue?.('selected_receipts', selectedReceipts || []),
      [selectedReceipts, setFieldValue]
    );

    // Set the bank statement transaction id into the form values
    // so we can access it on form submit
    useEffect(
      () =>
        setFieldValue?.(
          'bank_statement_transaction_id',
          bankStatementTransactionId
        ),
      [bankStatementTransactionId, setFieldValue]
    );

    return !selectedReceipts?.length ? (
      <EmptyCard hasGreyBackground={true}>
        <Message
          title={t(
            'banking.batch-receipting.no-trust-journal-entries-selected.label'
          )}
          Illustration={EmptyTable}
        >
          {t(
            'banking.batch-receipting.select-a-receipt-or-create-trust-journal-entry.message'
          )}
        </Message>
      </EmptyCard>
    ) : (
      <SelectedTrustJournalEntriesTable
        data={selectedReceipts}
        blockProps={blockProps}
      />
    );
  }
};
