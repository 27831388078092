import React, { useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { Field, HiddenField } from '@rexlabs/form';
import { Checkbox } from '@rexlabs/checkbox';

import { BlockConfig } from 'view/components/record-screen/types';
import { Grid } from 'view/components/@luna/form/grid';
import { CentAmountInput } from 'view/components/inputs/cent-amount';
import { ContactSelect } from 'view/components/inputs/selects/contact';
import { SupplierSelect } from 'view/components/inputs/selects/v4-selects/supplier-select';
import { FinancialEntitySelect } from 'view/components/inputs/selects/financial-entity-select';
import { useFeatureFlags } from 'view/components/@luna/feature-flags';

import { formatCurrency } from 'utils/formatters';
import { SearchResultItem } from 'utils/api/get-search-results';
import { FLAGS } from 'utils/feature-flags';

import { WorkOrderTask } from '../types/WorkOrderTask';
import { MessageTemplateSelectWithEdit } from '../../common/components/message-template-select-with-edit';
import { getOwnerSuggestedItems } from '../utils/get-owner-suggested-items';
import { getOwnerSelectCustomFilter } from '../utils/get-owner-select-custom-filter';
import { useGetAccessProvidedBySuggestedContacts } from '../../common/hooks/use-get-access-provided-by-suggested-contacts';
import { Task } from '../../common/types/Task';
import { useChildrenTasksOfParentTaskQuery } from '../../common/hooks/use-children-tasks-of-parent-task-query';
import { TaskType } from '../../common/types/TaskType';
import { MaintenanceTask } from '../../maintenance/types/MaintenanceTask';
import { useTaskWithheldFundsInputConfig } from '../hooks/use-task-withheld-funds-input-config';
import { WorkOrderFormData } from '../types/WorkOrderFormData';
import { DetailsBlockValues } from './details-block';

type WorkOrderDetailsBlockDetailsValues = Pick<
  WorkOrderFormData['details'],
  | 'work_done_by_type'
  | 'supplier'
  | 'bill_to'
  | 'amount'
  | 'access_provided_by'
  | 'owner'
  | 'message'
>;

interface WorkOrderDetailsBlockValues {
  details: WorkOrderDetailsBlockDetailsValues;
  withheld_funds: boolean;
}

type OtherForms = {
  details: { values: Partial<DetailsBlockValues> };
};

export const workOrderDetailsBlock: BlockConfig<
  WorkOrderTask,
  any,
  WorkOrderDetailsBlockValues,
  OtherForms
> = {
  id: 'work-order-details',
  title: 'Work order details',
  validate: {
    definitions: {
      'details.work_done_by_type': {
        name: 'work done by',
        rules: 'required'
      },
      // required for supplier work order:
      'details.supplier': {
        name: 'supplier',
        rules: 'required_if:details.work_done_by_type,supplier'
      },
      'details.access_provided_by': {
        name: 'access provided by',
        rules: 'required_if:details.work_done_by_type,supplier'
      },
      // required for owner work order:
      'details.owner': {
        name: 'owner',
        rules: 'required_if:details.work_done_by_type,owner'
      },
      // required for supplier or owner work order:
      'details.message.template': {
        name: 'message template',
        rules: 'required'
      }
    },
    messages: {
      required_if: 'The :attribute field is required.'
    }
  },
  Edit: ({ values, forms, setFieldValue }) => {
    const { hasFeature } = useFeatureFlags();
    const hasTaskWithheldFundsFeature = hasFeature(FLAGS.TASK_WITHHELD_FUNDS);

    const workAssignedToType = values?.details?.work_done_by_type;
    const property = forms?.details?.values?.property;
    const {
      loadItems: loadAccessProvidedBySuggestedContacts
    } = useGetAccessProvidedBySuggestedContacts({
      property
    });

    const { t } = useTranslation();

    const parentMaintenanceTask = hasTaskWithheldFundsFeature
      ? getParentTask(forms?.details?.values?.parent_task)
      : undefined;

    const {
      data: otherWorkOrdersLinkedToParentTask = []
    } = useChildrenTasksOfParentTaskQuery(
      parentMaintenanceTask,
      'task_work_order'
    );

    const spendLimit = values?.details?.amount || 0;
    const parentTaskWithheldFunds =
      parentMaintenanceTask?.details?.disbursement_withheld_funds?.amount || 0;
    const hasOtherWorkOrdersLinkedToParentTask =
      otherWorkOrdersLinkedToParentTask.length > 0;

    const {
      inputLabel,
      description,
      newWithheldFundsAmount
    } = useTaskWithheldFundsInputConfig(
      parentTaskWithheldFunds,
      spendLimit,
      hasOtherWorkOrdersLinkedToParentTask
    );

    // We need to update the hidden field with the withheld funds amount - the
    // simplest way to do this is to update the field whenever the newWithheldFundsAmount changes
    useEffect(() => {
      setFieldValue?.('withheld_funds_amount', newWithheldFundsAmount || 0);
    }, [newWithheldFundsAmount, setFieldValue]);

    return (
      <Grid columns={1}>
        {workAssignedToType === 'supplier' && (
          <Grid columns={2}>
            <Field
              id='supplier'
              name='details.supplier'
              label='Supplier'
              Input={SupplierSelect}
              optional={false}
              inputProps={{
                filterByRoles: ['supplier']
              }}
            />
            <Field
              id='bill_to'
              name='details.bill_to'
              label='Bill to'
              Input={FinancialEntitySelect}
            />
            <Field
              id='amount'
              name='details.amount'
              label={hasTaskWithheldFundsFeature ? 'Spend limit' : 'Price'}
              Input={CentAmountInput}
              description={
                parentMaintenanceTask
                  ? `The spend limit preference for this property is ${formatCurrency(
                      parentMaintenanceTask?.details
                        ?.disbursement_withheld_funds?.amount || 0
                    )}`
                  : ''
              }
            />
            {parentMaintenanceTask && (
              <>
                <Field<typeof Checkbox>
                  id='should_withheld_funds'
                  name='should_withheld_funds'
                  label={
                    t(
                      'withheld-funds.task-withheld-funds.label.singular'
                    ) as string
                  }
                  HelpTooltipContent={() => (
                    <Trans
                      i18nKey={
                        'withheld-funds.related-task-withheld-funds-block.tooltip'
                      }
                    />
                  )}
                  Input={Checkbox}
                  optional={false}
                  description={description}
                  inputProps={{
                    disabled: !values?.details?.amount,
                    label: inputLabel
                  }}
                />
                <HiddenField name='withheld_funds_amount' />
              </>
            )}
            <Field
              id='access_provided_by'
              name='details.access_provided_by'
              label='Access provided by'
              optional={false}
              Input={ContactSelect}
              inputProps={{
                getSuggestedItems: loadAccessProvidedBySuggestedContacts
              }}
            />
          </Grid>
        )}
        {workAssignedToType === 'owner' && (
          <>
            <Field
              id='owner'
              name='details.owner'
              label='Owner'
              Input={ContactSelect}
              optional={false}
              inputProps={{
                filterByRoles: ['owner'],
                customFilter: getOwnerSelectCustomFilter(property),
                getSuggestedItems: getOwnerSuggestedItems(property)
              }}
            />
          </>
        )}
        <Grid columns={1}>
          <Field
            id='details.message'
            name='details.message'
            label='Message template'
            Input={MessageTemplateSelectWithEdit}
            optional={false}
            inputProps={{
              contextType: 'task_work_order',
              editDialogTitle: 'Edit work order request'
            }}
          />
        </Grid>
      </Grid>
    );
  }
};

function getParentTask(
  parentTask?: SearchResultItem<Task<TaskType>> | Task<TaskType> | null
) {
  const parentTaskRecord =
    parentTask && 'record' in parentTask ? parentTask.record : parentTask;

  return parentTaskRecord && parentTaskRecord.type.id === 'task_maintenance'
    ? (parentTaskRecord as MaintenanceTask)
    : undefined;
}
