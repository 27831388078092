import React, { useCallback, useMemo } from 'react';
import { query } from '@rexlabs/model-generator';
import { Quri, toQuri } from '@rexlabs/quri';
import { Message } from '@rexlabs/notifications';
import { Action } from '@rexlabs/notifications/lib/types/message/action-buttons';
import { TagCell } from '@rexlabs/table';

import { Property } from 'src/modules/properties/types/property-types';
import { Tenancy } from 'data/models/entities/tenancies';
import { Contact } from 'src/modules/contacts/types/contact-types';

import { Column, Columns, RecordTable } from 'view/components/table';
import { propertyTenancyModel } from 'src/modules/property-tenancies/models/property-tenancy-model';
import { transformActionDeclarationsToActionMenuItems } from 'utils/actions/transforms';
import { StatusTag } from 'src/modules/common/components/status-tag';
import { propertyTenancyStatusMap } from 'src/modules/property-tenancies/maps/property-tenancy-map';
import { PropertyTenancy } from 'src/modules/property-tenancies/types/property-tenancy-types';
import ROUTES from 'routes/app';
import { Card } from 'view/components/@luna/card';
import TenancyIllustration from 'src/assets/illustrations/tenancy.svg';
import { useTranslation } from 'react-i18next';
import { useSettingsQuery } from 'src/modules/settings/hooks/useSettingsQuery';
import { RentPaidToValues } from 'src/modules/settings/hooks/useSettings';
import { useGetCreatePropertyTenancyAction } from '../actions/use-get-create-property-tenancy-action';
import { usePropertyTenancyActions } from '../actions/use-property-tenancy-actions';
import { RentPaidToCell } from './rent-paid-to-cell';

interface PropertyTenanciesListProps {
  property?: Property;
  tenancy?: Tenancy;
  contact?: Contact;
}
interface PropertyTenanciesQueryProps {
  propertyId?: string;
  tenancyId?: string;
  contactId?: string;
}

const getColumns = ({
  propertyId,
  paidToDateSetting
}: {
  propertyId?: string;
  paidToDateSetting?: RentPaidToValues;
}): Columns<PropertyTenancy> => {
  const propertyOrTenancyCol: Column<PropertyTenancy> = propertyId
    ? {
        id: 'tenancy',
        type: 'tenancy',
        Header: 'Tenancy'
      }
    : {
        id: 'property',
        type: 'property',
        Header: 'Property'
      };

  return [
    {
      id: 'record_reference',
      Header: 'Reference',
      accessor: (item) => item.record_reference,
      width: 120
    },
    {
      id: 'status',
      Header: 'Status',
      Cell: ({ value }) => (
        <TagCell>
          <StatusTag status={value} map={propertyTenancyStatusMap} />
        </TagCell>
      ),
      width: 120
    },
    propertyOrTenancyCol,
    {
      id: 'rent_paid_to',
      Header: 'Rent paid to',
      Cell: RentPaidToCell,
      accessor: (item: PropertyTenancy) => ({
        setting: paidToDateSetting,
        ...item
      }),
      width: 300
    },
    {
      id: 'agreement_start_date',
      accessor: (item) => item.agreement_start_date,
      type: 'date',
      Header: 'Start date'
    },
    {
      id: 'agreement_end_date',
      accessor: (item) => item.agreement_end_date,
      type: 'date',
      Header: 'End date'
    },
    {
      id: 'created_at',
      type: 'date',
      Header: 'Created at',
      accessor: (item) => item.created_at
    },
    {
      id: 'updated_at',
      type: 'date',
      Header: 'Updated at',
      accessor: (item) => item.updated_at
    }
  ];
};

const getRowLinkProps = ({ item }) => {
  return {
    to: ROUTES.PROPERTY,
    query: { tab: 'lease-agreements', leaseAgreement: item.id },
    params: { propertyId: item.property.id }
  };
};

const getQuery = ({
  propertyId,
  tenancyId,
  contactId
}: PropertyTenanciesQueryProps) => {
  const filters: Quri[] = [];
  if (propertyId) {
    filters.push({
      field: 'property_id',
      op: '==',
      value: propertyId
    });
  }

  if (tenancyId) {
    filters.push({
      field: 'tenancy_id',
      op: '==',
      value: tenancyId
    });
  }

  if (contactId) {
    filters.push({
      field: 'contact_id',
      op: '==',
      value: contactId
    });
  }

  return query`{
    ${propertyTenancyModel} (q: ${toQuri(filters)}) {
      id
      created_at
      updated_at
      status
      property
      tenancy
      rent_position
    }
  }`;
};

export function PropertyTenanciesList({
  property,
  tenancy,
  contact
}: PropertyTenanciesListProps) {
  const propertyId = property?.id;
  const tenancyId = tenancy?.id;
  const contactId = contact?.id;

  const { t } = useTranslation();
  const q = useMemo(() => getQuery({ propertyId, tenancyId, contactId }), [
    propertyId,
    tenancyId,
    contactId
  ]);

  const { data: settingsData } = useSettingsQuery();
  const paidToDateSetting =
    settingsData?.['rent-paid-to-date']?.id ||
    'effective_and_periodic_with_surplus';

  const columns = useMemo(() => getColumns({ propertyId, paidToDateSetting }), [
    propertyId,
    paidToDateSetting
  ]);

  const getCreatePropertyTenancyAction = useGetCreatePropertyTenancyAction();

  const getPropertyTenancyActions = usePropertyTenancyActions();

  const getActionMenuItems = useCallback(
    ({ item }) =>
      transformActionDeclarationsToActionMenuItems(
        getPropertyTenancyActions(item as PropertyTenancy)
      ),
    []
  );

  return (
    <RecordTable
      id='property-tenancies'
      columns={columns}
      getQuery={() => q}
      getRowLinkProps={getRowLinkProps}
      alwaysExpandSearch={true}
      getActionMenuItems={getActionMenuItems}
      initialHiddenColumns={['record_reference', 'created_at', 'updated_at']}
      emptyWithoutTable
      Empty={() => (
        <Card>
          <Message
            title={t('lease-agreements.setup-new.title')}
            Illustration={TenancyIllustration}
            actions={[
              {
                ...getCreatePropertyTenancyAction({
                  tenancy,
                  property
                }),
                type: 'primary'
              } as Action
            ]}
          >
            {t('lease-agreements.setup-new.message')}
          </Message>
        </Card>
      )}
    />
  );
}
