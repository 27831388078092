import React from 'react';

import { BlockConfig } from 'view/components/record-screen/types';
import { Column, Grid } from 'view/components/@luna/form/grid';
import { CurrencyValue } from 'view/components/values/currency';
import { Value } from 'view/components/values';
import { MaintenanceTask } from '../types/MaintenanceTask';

export const maintenanceInstructionsBlock: BlockConfig<MaintenanceTask, any> = {
  id: 'maintenance-instructions',
  title: 'Maintenance instructions',
  View: ({ data }) => {
    const activePropertyOwnership = data?.property?.active_property_ownership;

    const isInherited =
      activePropertyOwnership?.maintenance_instructions
        .is_inherited_from_ownership;

    return (
      <Grid columns={2}>
        <CurrencyValue
          label='Spend limit'
          value={
            isInherited
              ? activePropertyOwnership?.ownership.maintenance_instructions
                  .spend_limit
              : activePropertyOwnership?.maintenance_instructions.spend_limit
          }
        />

        <Column width={2}>
          <Value
            label='Maintenance notes'
            value={
              isInherited
                ? data?.property?.active_property_ownership?.ownership
                    .maintenance_instructions.notes
                : data?.property?.active_property_ownership
                    ?.maintenance_instructions.notes
            }
          />
        </Column>
      </Grid>
    );
  }
};
