import React from 'react';
import { BlockConfig } from 'view/components/record-screen/types';
import { Column, Grid } from 'view/components/@luna/form/grid';
import { DateValue } from 'view/components/values/date';
import { Field, HiddenField } from '@rexlabs/form';
import { DateInput } from 'view/components/@luna/inputs/date-input/date-input';
import { Trans, useTranslation } from 'react-i18next';
import { ServicePackageSelect } from 'view/components/inputs/selects/v4-selects/service-package-select';
import { Value } from 'view/components/values';
import { useFeatureFlags } from 'view/components/@luna/feature-flags';
import { FLAGS } from 'utils/feature-flags';
import { InfoBanner } from 'view/components/@luna/notifications/banner';
import InfoCircleIcon from 'view/components/icons/info';
import { FlattenedProperty } from '../../properties/types/property-types';

export const propertyManagementDetailsBlock: BlockConfig<FlattenedProperty> = {
  id: 'property-management-details',
  title: () => (
    <Trans i18nKey='property-ownerships.management-agreement.details.title' />
  ),
  View: ({ data }) => {
    const { hasFeature } = useFeatureFlags();

    const hasServicePackages = hasFeature(FLAGS.SERVICE_PACKAGES);

    return (
      <Grid columns={2}>
        <DateValue
          label='Agreement start date'
          value={data?.selected_property_ownership?.agreement_start_date}
        />
        <DateValue
          label='Agreement end date'
          value={data?.selected_property_ownership?.agreement_end_date}
        />
        {hasServicePackages && (
          <Value
            label='Service package'
            value={data?.selected_property_ownership?.service_package?.name}
          />
        )}
      </Grid>
    );
  },
  Edit: ({ setFieldValue, values }) => {
    const { hasFeature } = useFeatureFlags();
    const { t } = useTranslation();

    const hasServicePackageFeature = hasFeature(FLAGS.SERVICE_PACKAGES);

    const servicePackageName =
      values.selected_property_ownership?.service_package?.name;
    const hasServicePackageValue = servicePackageName != null;

    React.useEffect(() => {
      setFieldValue!('previous_service_package_name', servicePackageName);
    }, []);

    return (
      <Grid columns={2}>
        <Field
          name='selected_property_ownership.agreement_start_date'
          label='Agreement start date'
          Input={DateInput}
        />
        <Field
          name='selected_property_ownership.agreement_end_date'
          label='Agreement end date'
          Input={DateInput}
        />
        {hasServicePackageFeature && (
          <Field
            name='selected_property_ownership.service_package'
            label='Service package'
            Input={ServicePackageSelect}
            inputProps={{
              deselectable: true
            }}
          />
        )}

        <HiddenField name='is_service_package_required' />
        <HiddenField name='previous_service_package_name' />

        {hasServicePackageFeature && hasServicePackageValue && (
          <Column width={2}>
            <InfoBanner
              Icon={InfoCircleIcon}
              description={
                t(
                  'property-ownerships.management-agreement.service-package.change-banner-message'
                ) as string
              }
            />
          </Column>
        )}
      </Grid>
    );
  }
};
