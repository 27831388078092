import * as React from 'react';
import { DialogProps, ConfirmationDialog } from '@rexlabs/dialog';

export interface DeleteChecklistItemDialogProps extends DialogProps {
  onDelete: () => void;
}

export function DeleteChecklistItemDialog({
  onDelete,
  onClose
}: DeleteChecklistItemDialogProps) {
  const handleConfirm = React.useCallback(() => {
    onDelete();

    return true;
  }, [onDelete]);

  return (
    <ConfirmationDialog
      destructive
      onClose={onClose}
      onConfirm={handleConfirm}
      confirmText='Delete subtask'
      title='Delete subtask'
      message='Are you sure you want to delete this subtask? This will not affect any existing tasks that have already added this checklist.'
    />
  );
}
