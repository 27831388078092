import React from 'react';
import { Field } from '@rexlabs/form';
import { RadioGroupInput } from '@rexlabs/radio-input';

import { InfoBanner } from 'view/components/@luna/notifications/banner';
import { BlockConfig } from 'view/components/record-screen/types';
import InfoCircleIcon from 'view/components/icons/info';
import { Value } from 'view/components/values';
import { Grid } from 'view/components/@luna/form/grid';

import { RentArrearsPaidToValues, Settings } from '../../hooks/useSettings';

export interface RadioOptionValueList<T> {
  value: T;
  label: string;
}

const rentArrearsPaidToDateOptionsMap: Record<
  RentArrearsPaidToValues,
  RadioOptionValueList<RentArrearsPaidToValues>
> = {
  end_of_day: {
    value: 'end_of_day',
    label: 'End of day (midnight)'
  },
  start_of_day: {
    value: 'start_of_day',
    label: 'Start of day'
  }
};

const rentArrearsPaidToDateOptions = Object.values(
  rentArrearsPaidToDateOptionsMap
);

function getRentArrearsPaidToDateBannerConfig(value?: RentArrearsPaidToValues) {
  switch (value) {
    case 'start_of_day':
      return {
        title: 'Start of day',
        text:
          'Using this method, we would expect the rent for the 17th to be paid on or before the 16th. This means that if today is the 18th and we have not yet received rent, the tenant is 2 days in arrears (the rent for the 18th was due on the 17th ie yesterday).'
      };
    case 'end_of_day':
    default:
      return {
        title: 'End of day (midnight)',
        text:
          "Using this method, we would expect the rent for the 17th to be paid on or before the 17th. This means that if today is the 18th and we have not yet received rent, the tenant is 1 day in arrears (the rent for the 18th is due today and today hasn't passed yet)."
      };
  }
}

export const rentArrearsPaidToDateSettingsBlock: BlockConfig<
  Pick<Settings, 'rent-arrears-paid-to-date'>
> = {
  id: 'rent-arrears-paid-to-date',
  title: 'Arrears paid to date',
  View: ({ values }) => {
    const rentArrearsPaidToDateBannerConfig = getRentArrearsPaidToDateBannerConfig(
      values?.['rent-arrears-paid-to-date']
    );

    const value =
      rentArrearsPaidToDateOptionsMap[
        values?.['rent-arrears-paid-to-date'] || 'end_of_day'
      ];

    return (
      <Grid columns={1}>
        <Grid columns={2}>
          <Value
            label='Tenant is in arrears if rent is not paid by'
            value={value.label}
          />
        </Grid>
        <Grid columns={2}>
          <InfoBanner
            Icon={InfoCircleIcon}
            title={rentArrearsPaidToDateBannerConfig.title}
            description={rentArrearsPaidToDateBannerConfig.text}
          />
        </Grid>
      </Grid>
    );
  },

  Edit: ({ values }) => {
    const rentArrearsPaidToDateBannerConfig = getRentArrearsPaidToDateBannerConfig(
      values?.['rent-arrears-paid-to-date']
    );

    return (
      <Grid columns={1}>
        <Grid columns={2}>
          <Field
            Input={RadioGroupInput}
            inputProps={{
              options: rentArrearsPaidToDateOptions,
              orientation: 'horizontal'
            }}
            name='rent-arrears-paid-to-date'
            label='Tenant is in arrears if rent is not paid by'
            id='rent-arrears-paid-to-date'
            optional={false}
          />
        </Grid>
        <Grid columns={2}>
          <InfoBanner
            Icon={InfoCircleIcon}
            title={rentArrearsPaidToDateBannerConfig.title}
            description={rentArrearsPaidToDateBannerConfig.text}
          />
        </Grid>
      </Grid>
    );
  }
};
