import React, { ComponentType, useCallback, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';

import Box from '@rexlabs/box';
import {
  border,
  mq,
  padding,
  StyleSheet,
  StylesProvider,
  text,
  useStyles,
  useToken
} from '@rexlabs/styling';
import { Avatar } from '@rexlabs/avatar';
import {
  StatusAverageTag,
  StatusBadTag,
  StatusGoodTag,
  Tag as NeutralTag,
  TagGroup
} from '@rexlabs/tag';
import { useMediaQuery } from '@rexlabs/breakpoints';

import { Identifier, IdentifierProps } from './identifier';
import { SubTitles, SubTitlesProps } from './subtitles';
import { Actions, ActionsProps } from './actions';

export type TagConfig = {
  type?: 'neutral' | 'good' | 'average' | 'bad';
  label: string;
  Tag?: ComponentType<any>;
};

export type TitleBlockProps = {
  title: string;
  imageSrc?: string;
  tags?: TagConfig[];
  Icon?: ComponentType<any>;
  isCompact?: boolean;
  CustomTitleHeader?: ComponentType<any>;
  subText?: React.ReactNode;
} & Partial<ActionsProps> &
  Omit<Partial<IdentifierProps>, 'size'> &
  Omit<Partial<SubTitlesProps>, 'isStacked' | 'size'>;

const tagTypes = {
  neutral: NeutralTag,
  good: StatusGoodTag,
  average: StatusAverageTag,
  bad: StatusBadTag
};

const defaultStyles = StyleSheet({
  container: {
    width: '100%'
  },

  subText: {
    ...text.styles({
      fallback: 'small.default',
      color: ({ token }) => token('color.textStyle.body.subtext')
    })
  },

  content: {
    ...mq.styles.combine([
      {
        queries: {
          maxWidth: 's'
        },
        styles: {
          gap: ({ token }) => token('spacing.xs')
        }
      },
      {
        queries: {
          minWidth: 's'
        },
        styles: {
          gap: ({ token }) => token('spacing.l')
        }
      }
    ])
  },

  image: {
    height: '10.125rem',
    width: '13.5rem',
    objectFit: 'cover',

    ...border.styles({
      target: 'titleCard',
      all: {
        radius: 'l'
      }
    })
  },

  title: {
    wordBreak: 'break-word',

    ...mq.styles.combine([
      {
        queries: {
          maxWidth: 's'
        },
        styles: {
          ...text.styles({
            target: 'titleBlock.heading',
            fallback: 'heading.2'
          })
        }
      },
      {
        queries: {
          minWidth: 's'
        },
        styles: {
          ...text.styles({
            target: 'titleBlock.heading',
            fallback: 'heading.1'
          })
        }
      }
    ])
  }
});

const tagOverrides = {
  tag: {
    height: '2.4rem',

    ...text.tokens({
      target: 'titleCard.tag',
      fallback: 'tiny.semibold'
    }),

    ...padding.tokens({
      target: 'titleCard.tag',
      x: 'xs'
    })
  }
};

export function TitleBlock({
  type,
  reference,
  title,
  imageSrc,
  subTitles,
  dateRange,
  actions,
  tags,
  hideViewAction,
  dropdownActionIntent,
  isCompact,
  Icon,
  CustomTitleHeader,
  subText,
  ...props
}: TitleBlockProps) {
  const s = useStyles(defaultStyles);
  const token = useToken();

  const matchesMobile = useMediaQuery({ maxWidth: 's' });

  const [isValidImage, setIsValidImage] = useState(true);

  const containerRef = useRef<HTMLDivElement>(null);

  const centerTitle =
    !imageSrc &&
    !dateRange &&
    !Icon &&
    !type &&
    !reference &&
    (!subTitles || subTitles.length <= 0) &&
    (!tags || tags.length <= 0);

  const handleImageError = useCallback(() => {
    setIsValidImage(false);
  }, []);

  const avatarSize = token('titleBlock.avatar.size', 'l');

  const renderImage = imageSrc && isValidImage;
  const renderIcon = !!Icon;
  const renderIdentifierCompact =
    isCompact && matchesMobile && (type || reference);

  return (
    <>
      {!isCompact && <Helmet title={title} />}
      <Box
        {...s('container')}
        ref={containerRef}
        flexDirection='column'
        sy={token('spacing.xl')}
      >
        <Box flexDirection='row'>
          <Box
            {...s('content')}
            flex={1}
            flexDirection={matchesMobile ? 'column' : 'row'}
          >
            {(renderImage || renderIcon || renderIdentifierCompact) && (
              <Box
                alignItems={matchesMobile ? 'center' : 'flex-start'}
                sx={token('spacing.s')}
              >
                {renderImage ? (
                  <img
                    {...s('image')}
                    src={imageSrc}
                    onError={handleImageError}
                  />
                ) : (
                  renderIcon && (
                    <Avatar size={avatarSize} rounded='m' Icon={Icon} />
                  )
                )}
                {renderIdentifierCompact && (
                  <Box flexDirection='row'>
                    <Identifier
                      type={type}
                      reference={reference}
                      isCompact={isCompact}
                    />
                  </Box>
                )}
              </Box>
            )}
            <Box flex={1}>
              <Box flex={1} flexDirection='column'>
                <Box
                  flex={1}
                  justifyContent='space-between'
                  alignItems={centerTitle ? 'center' : 'flex-start'}
                  sx={token('spacing.xxxl')}
                >
                  <Box flexDirection='column'>
                    {!renderIdentifierCompact && (
                      <Box mb={token('spacing.xxs')}>
                        <Identifier
                          type={type}
                          reference={reference}
                          isCompact={isCompact}
                        />
                      </Box>
                    )}
                    {CustomTitleHeader ? (
                      <CustomTitleHeader>{title}</CustomTitleHeader>
                    ) : (
                      <span {...s('title')}>{title}</span>
                    )}
                    {subTitles && subTitles.length > 0 && (
                      <Box flexDirection='row' mt={token('spacing.xs')}>
                        <SubTitles
                          dateRange={dateRange}
                          subTitles={subTitles}
                        />
                      </Box>
                    )}
                    {tags && tags.length > 0 && (
                      <Box flexDirection='row' mt={token('spacing.xs')}>
                        <StylesProvider tokens={tagOverrides}>
                          <TagGroup>
                            {tags.map(
                              ({ type = 'neutral', label, Tag }, index) => {
                                const TagComponent = Tag || tagTypes[type];
                                return (
                                  <TagComponent key={index}>
                                    {label}
                                  </TagComponent>
                                );
                              }
                            )}
                          </TagGroup>
                        </StylesProvider>
                      </Box>
                    )}
                    {subText && (
                      <Box
                        flexDirection='row'
                        mt={token('spacing.xs')}
                        {...s('subText')}
                      >
                        {subText}
                      </Box>
                    )}
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
          {!!actions?.length && (
            <Box flexDirection='row'>
              <Actions
                hideViewAction={hideViewAction}
                dropdownActionIntent={dropdownActionIntent}
                actions={actions}
                {...(props.amountDisplayedAsButtons
                  ? { amountDisplayedAsButtons: props.amountDisplayedAsButtons }
                  : {})}
              />
            </Box>
          )}
        </Box>
      </Box>
    </>
  );
}
