import { RegularActionMenuItem } from '@rexlabs/action-menu';
import { useModelActions } from '@rexlabs/model-generator';
import {
  ContactPaymentMethod,
  contactsPaymentMethodsModel
} from 'data/models/entities/contacts/payment-methods';
import { useToast } from 'view/components/@luna/notifications/toast';
import React from 'react';
import {
  useConfirmationDialog,
  useDialog,
  useErrorDialog
} from '@rexlabs/dialog';
import { Contact } from 'src/modules/contacts/types/contact-types';
import { EditContactPaymentMethodDialog } from '../dialogs/edit-payment-method';

export function useGetPaymentMethodsActions() {
  const { open: openErrorDialog } = useErrorDialog();
  const { refreshList, trashItem, updateItem } = useModelActions(
    contactsPaymentMethodsModel
  );
  const { open: openEditDialog } = useDialog(EditContactPaymentMethodDialog);
  const { open: openConfirmationDialog } = useConfirmationDialog();
  const { addToast } = useToast();

  const getActions = (
    contact: Contact,
    contactPaymentMethod: ContactPaymentMethod,
    updateListId?: string
  ): RegularActionMenuItem[] => {
    const setAsDefaultAction = {
      label: 'Set as default',
      onClick: async () => {
        try {
          await updateItem({
            id: contactPaymentMethod.id,
            args: {
              contactId: contact.id
            },
            data: {
              is_default: true
            }
          });
        } catch (e) {
          openErrorDialog(e);
        }

        if (updateListId) {
          await refreshList({ id: updateListId });
        }
      }
    };

    const editAction = {
      label: 'Edit',
      onClick: () =>
        openEditDialog({
          contactId: contact.id,
          contactPaymentMethod
        })
    };

    const deleteAction = {
      label: 'Delete',
      intent: 'danger',
      onClick: () => {
        openConfirmationDialog({
          title: 'Confirm deletion of payment method',
          message: (
            <div>
              Once you have deleted this payment method, you will no longer be
              able to apply it for this contact in invoices, disbursments or
              other transactions. <br />
              <br />
              Please confirm you wish to delete the payment method{' '}
              <b>{contactPaymentMethod.name}</b>.
            </div>
          ),
          onConfirm: async () => {
            try {
              await trashItem({
                id: contactPaymentMethod.id,
                args: {
                  contactId: contact.id
                }
              });
            } catch (e) {
              openErrorDialog(e);
            }

            if (updateListId) {
              await refreshList({ id: updateListId });
            }

            addToast({
              description: (
                <>
                  Deleted payment method <b>{contactPaymentMethod.name}</b>
                </>
              )
            });
          }
        });
      }
    };

    if (contactPaymentMethod.is_default) {
      return [editAction, deleteAction];
    }

    return [setAsDefaultAction, editAction, deleteAction];
  };

  return {
    getActions
  };
}
