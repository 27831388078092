import { BlockConfig } from 'view/components/record-screen/types';
import React from 'react';
import { ActionButtons } from 'view/components/action-buttons';
import { useDialog } from '@rexlabs/dialog';
import { CreateServicePackageDialog } from 'src/modules/service-packages/dialogs/create-service-package-dialog';
import { ServicePackagesTable } from 'src/modules/service-packages/components/service-packages-table';

export const servicePackagesTableBlock: BlockConfig = {
  id: 'service-packages-table',
  title: 'Service packages',
  View: () => <ServicePackagesTable />,
  Actions: () => {
    const { open } = useDialog(CreateServicePackageDialog);
    return (
      <ActionButtons
        actions={[
          {
            label: 'Create service package',
            onClick: open
          }
        ]}
      />
    );
  }
};
