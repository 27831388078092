import { useDialog } from '@rexlabs/dialog';
import { IssueWorkOrderDialog } from '../dialogs/create-work-order-dialog';
import { WorkOrderTask } from '../types/WorkOrderTask';

interface UseSelectWorkOrderTypeSubmitHandlerProps {
  onCreate?: (data: WorkOrderTask) => void;
}

export function useSelectWorkOrderTypeSubmitHandler({
  onCreate
}: UseSelectWorkOrderTypeSubmitHandlerProps) {
  const { open } = useDialog(IssueWorkOrderDialog, {
    target: 'nested-dialogs'
  });

  return ({ initialValues, values }) => {
    open({
      onCreate,
      initialValues: {
        ...initialValues,
        details: {
          ...initialValues?.details,
          work_done_by_type: values.details.work_done_by_type
        }
      }
    });

    return true;
  };
}
