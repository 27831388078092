import * as React from 'react';
import Box from '@rexlabs/box';
import { StyleSheet, useStyles } from '@rexlabs/styling';
import { Body } from '@rexlabs/text';

const defaultStyles = StyleSheet({
  container: {
    backgroundColor: ({ token }) => token('color.container.static.contrast'),
    borderRadius: ({ token }) => token('border.radius.l'),
    padding: ({ token }) => token('spacing.m'),
    textAlign: 'center'
  }
});

export function RightBarEmptyState({
  message,
  actionSlot
}: {
  message: string;
  actionSlot?: React.ReactElement;
}) {
  const s = useStyles(defaultStyles);
  return (
    <Box flexDirection={'column'} justifyContent={'center'} {...s('container')}>
      <Body grey as={'span'}>
        {message}
      </Body>

      {actionSlot ? <Box marginTop={12}>{actionSlot}</Box> : null}
    </Box>
  );
}
