import { ActionDeclaration } from 'src/modules/common/actions/types/action-declaration-types';
import { useDialog } from '@rexlabs/dialog';
import { AddPropertyOwnershipFeeDialog } from 'src/modules/agency-fees/dialogs/add-property-ownership-fee';
import { useModelActions } from '@rexlabs/model-generator';
import { propertyOwnershipsFeesModel } from 'data/models/entities/financials/property-ownerships/property-ownership-fee';
import React from 'react';
import { useToast } from 'view/components/@luna/notifications/toast';
import { PropertyOwnership } from 'src/modules/property-ownerships/types/property-ownership-types';
import { Trans } from 'react-i18next';
import { propertiesModel } from 'data/models/entities/properties';

export function useGetAddPropertyOwnershipFeeAction() {
  const { refreshLists } = useModelActions(propertyOwnershipsFeesModel);
  const { refreshItem } = useModelActions(propertiesModel);
  const { open } = useDialog(AddPropertyOwnershipFeeDialog);
  const { addToast } = useToast();

  return (propertyOwnership: PropertyOwnership): ActionDeclaration => ({
    id: 'add-property-ownership-fee',
    group: 'property-ownership-fee',
    label: 'Add fee(s)',
    intent: 'primary',
    handleAction: () => {
      open({
        propertyOwnershipId: propertyOwnership.id,
        onAdd: async (addedAgencyFees) => {
          await refreshLists({
            args: { propertyOwnershipId: propertyOwnership.id }
          });
          await refreshItem({ id: propertyOwnership.property.id });

          if (addedAgencyFees.length === 1) {
            return addToast({
              description: (
                <Trans
                  i18nKey='property-ownerships.management-agreement-fees.add-fees.fee-added.singular'
                  values={{
                    agencyFeeName: addedAgencyFees[0].name
                  }}
                >
                  <b>{addedAgencyFees[0].name} fee</b> has been <b>added</b> to
                  this management agreement
                </Trans>
              )
            });
          }

          addToast({
            description: (
              <Trans
                i18nKey='property-ownerships.management-agreement-fees.add-fees.fee-added.plural'
                values={{
                  count: addedAgencyFees.length
                }}
              >
                <b>{addedAgencyFees.length} fees</b> has been <b>added</b> to
                this management agreement
              </Trans>
            )
          });
        }
      });
    }
  });
}
