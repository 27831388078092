import React, { ComponentType } from 'react';

import { NumberInput, TextInput } from '@rexlabs/text-input';
import { DateInput } from 'view/components/@luna/inputs/date-input/date-input';
import { BooleanInput } from 'view/components/table/toolbar/inputs/boolean-input';
import { CurrencyInput } from 'view/components/table/toolbar/inputs/currency-input';
import { RecordSelect } from 'view/components/table/toolbar/inputs/record-select';
import { TimestampDateInput } from 'view/components/table/toolbar/inputs/timestamp-date-input';
import { ValueListSelect } from 'view/components/table/toolbar/inputs/value-list-select';
import { LinkButton } from '@rexlabs/button';
import Icons from '@rexlabs/icons';
import { RecordType } from 'data/models/types';
import Box from '@rexlabs/box';

const bulkEditInputMap: Record<BulkEditFieldType, ComponentType<any>> = {
  string: TextInput,
  currency: CurrencyInput,
  integer: NumberInput,
  bool: BooleanInput,
  date: DateInput,
  timestamp: TimestampDateInput,
  value_list: ValueListSelect,
  static_value_list: ValueListSelect,
  resource: RecordSelect
};

export type BulkEditFieldType =
  | 'bool'
  | 'currency'
  | 'date'
  | 'integer'
  | 'resource'
  | 'string'
  | 'timestamp'
  | 'value_list'
  | 'static_value_list';

type SpecificBulkEditFieldProps =
  | ValueListBulkEditFieldProps
  | ResourceBulkEditFieldProps;

type GenericBulkEditFieldProps = {
  type: Exclude<BulkEditFieldType, SpecificBulkEditFieldProps['type']>;
  onChange?: (e: any) => void;
  onBlur?: (e: any) => void;
  value?: any;
  useExistingValue?: boolean;
  id?: string;
};

type ValueListBulkEditFieldProps = Omit<GenericBulkEditFieldProps, 'type'> & {
  type: 'value_list' | 'static_value_list';
  valueListId: string;
};

type ResourceBulkEditFieldProps = Omit<GenericBulkEditFieldProps, 'type'> & {
  type: 'resource';
  recordType: RecordType;
};

export type BulkEditFieldProps<T extends BulkEditFieldType> = T extends
  | 'value_list'
  | 'static_value_list'
  ? ValueListBulkEditFieldProps
  : T extends 'resource'
  ? ResourceBulkEditFieldProps
  : GenericBulkEditFieldProps;

export function BulkEditField<T extends BulkEditFieldType>(
  props: BulkEditFieldProps<T>
) {
  const [useExistingValue, setUseExistingValue] = React.useState(
    props.useExistingValue ?? false
  );
  const InputComponent = bulkEditInputMap[props.type];

  const dontUseExistingValue = () => {
    setUseExistingValue(false);
  };

  if (useExistingValue) {
    return (
      <>
        <TextInput disabled value='Keep existing' />
        {useExistingValue && (
          <Box marginTop={4}>
            <LinkButton
              IconLeft={Icons.CrossSmall}
              onClick={dontUseExistingValue}
            >
              Remove existing value
            </LinkButton>
          </Box>
        )}
      </>
    );
  }

  // this conflicts with our `type` prop, so needs special handling
  const valueListProps = isValueListProps(props)
    ? {
        type: props.valueListId
      }
    : {};

  return (
    <>
      <InputComponent {...props} {...valueListProps} />
    </>
  );
}

function isValueListProps(
  props: Partial<BulkEditFieldProps<any>>
): props is ValueListBulkEditFieldProps {
  return props.type === 'value_list' || props.type === 'static_value_list';
}
