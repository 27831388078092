import dayjs from 'dayjs';

export function getStartAtFromDayAndTime(
  leaveTime: string,
  inspectionDate: string
) {
  const [hours, minutes, seconds] = leaveTime.split(':');

  const startAt = dayjs(inspectionDate)
    .tz()
    .add(parseInt(hours), 'hour')
    .add(parseInt(minutes), 'minute')
    .add(parseInt(seconds), 'second');

  return startAt;
}
