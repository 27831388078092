import { TaskType } from 'src/modules/tasks/common/types/TaskType';
import { CommunicationContextType } from '../../common/types/communication-context-type';
import { CoreCommunicationContextObject } from '../mappers/types/create-message-form-data';

export function getContextTypeFromRecord(
  record: CoreCommunicationContextObject | undefined
): CommunicationContextType | undefined {
  if (
    record?.__record_type === 'task' &&
    taskTypeHasSpecificContext(record.type.id)
  ) {
    return record.type.id;
  }

  if (record?.__record_type === 'disbursement') {
    // get the object type, and use it to refine the context type
    const objectType = record?.object.type.id;
    return `${objectType}_disbursement` as CommunicationContextType;
  }

  if (record?.__record_type === 'statement') {
    // get the object type, and use it to refine the context type
    const objectType = record?.type.id;
    return `${objectType}_statement` as CommunicationContextType;
  }

  if (record?.__record_type === 'trust_journal_entry') {
    // get the object type, and use it to refine the context type
    const objectType = record?.type.id;

    if (objectType === 'receipt') {
      return 'contact_payment_receipt' as CommunicationContextType;
    }
  }

  return record?.__record_type;
}

function taskTypeHasSpecificContext(taskType: TaskType): boolean {
  // TODO: would be good if we could sync this with backend somehow, but for now we just need to make sure they show up in the right place
  const taskTypesWithSpecificContext: TaskType[] = [
    'todo',
    'task_quote',
    'task_work_order',
    'task_inspection',
    'task_arrears',
    'task_maintenance',
    'task_lease_review',
    'task_move_in',
    'task_move_out',
    'task_property_compliance',
    'task_supplier_compliance'
  ];

  return taskTypesWithSpecificContext.includes(taskType);
}
