import React from 'react';
import { useQueries } from 'react-query';
import { api } from 'utils/api/api-client';
import { useWhereabouts } from '@rexlabs/whereabouts';
import dayjs from 'dayjs';

import { RecordScreen } from 'view/components/record-screen';
import { SMSTitleBlock } from '../components/sms-title-block';
import { smsStatBlock } from '../blocks/sms-stat-block';
import { smsActivityBlock } from '../blocks/sms-activity-block';

const content = [
  {
    label: 'SMS',
    items: [
      {
        id: 'activity',
        label: 'Activity',
        blocks: [smsStatBlock, smsActivityBlock]
      }
    ]
  }
];

export function SubscriptionsScreen() {
  const match = useWhereabouts();

  const date = dayjs().subtract(0, 'month');

  const defaultId = date.format('YYYY-MM');

  const id = match.query?.view ?? defaultId;
  const label = dayjs(id).format('MMM YYYY');

  const result = useQueries([
    {
      queryKey: ['sms', 'available-credits'],
      queryFn: fetchAvailableCredits,
      staleTime: Infinity
    },
    {
      queryKey: ['sms', 'stats', id],
      queryFn: fetchStats
    }
  ]);

  const isLoading = result.some((query) => query.isLoading);

  if (isLoading) {
    return <RecordScreen isLoading titleBlock={null} content={[]} />;
  }

  const [availableCreditsResponse, statsResponse] = result;

  const data = {
    availableCredits: availableCreditsResponse.data?.data.available_credits,
    smsSent: statsResponse.data?.data.sms_sent,
    creditUsed: statsResponse.data?.data.credits_used,
    topUpAmount: statsResponse.data?.data.top_up_amount,
    dateLabel: label,
    dateValue: id
  };

  return (
    <RecordScreen
      titleBlock={<SMSTitleBlock />}
      content={content}
      data={data}
    />
  );
}

// Helpers

async function fetchAvailableCredits() {
  return api.get(`/account-credits/available-credits`);
}

async function fetchStats({ queryKey }) {
  const urlParams = new URLSearchParams();
  const range = queryKey[2];

  if (range) {
    urlParams.append('in_month', range);
  }

  return api.get(`/account-credit-logs/stats?${urlParams.toString()}`);
}
