import React, { useMemo } from 'react';

import { StylesProvider, text, border } from '@rexlabs/styling';

import { merge } from 'lodash';

import { Banner, BannerProps } from './core';

const overrides = {
  banner: {
    strong: {
      ...text.tokens({
        target: 'banner.neutral.strong',
        color: ({ token }) => token('color.textStyle.body.contrast')
      }),
      ...border.tokens({
        target: 'banner.neutral.strong',
        all: {
          color: 'primary.idle.default'
        }
      }),

      background: {
        color: ({ token }) =>
          token(
            'banner.neutral.strong.backgroundColor',
            token('color.container.static.primary.default')
          )
      }
    }
  }
};

const strongButtonOverrides = {
  button: {
    outline: {
      ...text.tokens({
        target: 'banner.neutral.strong.button.outline',
        color: ({ token }) => token('color.textStyle.body.contrast')
      }),
      ...border.tokens({
        target: 'banner.neutral.strong.button.outline',
        all: {
          color: 'primary.idle.contrast'
        }
      }),
      hover: {
        ...border.tokens({
          target: 'banner.neutral.strong.button.outline.hover',
          all: {
            color: 'primary.hover.contrast'
          }
        }),
        background: {
          color: ({ token }) =>
            token(
              'banner.neutral.strong.button.outline.hover.background.color',
              token('color.primary.hover.default')
            )
        }
      },
      active: {
        ...border.tokens({
          target: 'banner.neutral.strong.button.outline.active',
          all: {
            color: 'primary.active.contrast'
          }
        }),
        background: {
          color: ({ token }) =>
            token(
              'banner.neutral.strong.button.outline.active.background.color',
              token('color.primary.active.default')
            )
        }
      }
    },
    ghost: {
      ...text.tokens({
        target: 'banner.neutral.strong.button.ghost',
        color: ({ token }) => token('color.textStyle.body.contrast')
      }),
      hover: {
        ...border.tokens({
          target: 'banner.neutral.strong.button.ghost.hover',
          all: {
            color: 'primary.hover.default'
          }
        }),
        background: {
          color: ({ token }) =>
            token(
              'banner.neutral.strong.button.ghost.hover.background.color',
              token('color.primary.hover.default')
            )
        }
      },
      active: {
        ...border.tokens({
          target: 'banner.neutral.strong.button.ghost.active',
          all: {
            color: 'primary.active.default'
          }
        }),
        background: {
          color: ({ token }) =>
            token(
              'banner.neutral.strong.button.ghost.active.background.color',
              token('color.primary.active.default')
            )
        }
      }
    }
  }
};

export function NeutralBanner({ strong, ...props }: BannerProps) {
  // We need button overrides for the strong neutral banner because
  // it's the only one that doesn't work with the default outline button
  const tokens = useMemo(
    () => merge({}, overrides, strong ? strongButtonOverrides : {}),
    [strong]
  );
  return (
    <StylesProvider tokens={tokens}>
      <Banner strong={strong} {...props} />
    </StylesProvider>
  );
}
