import React from 'react';

import {
  StatusAverageTag,
  StatusBadTag,
  StatusGoodTag,
  Tag
} from '@rexlabs/tag';
import { useToken } from '@rexlabs/styling';
import Box from '@rexlabs/box';

import { ValueListValue } from 'data/models/types';

import { RoomCondition } from '../types/InspectionReportRoom';
import {
  InspectionRoomConditionExcludingNone,
  RoomConditionIcon
} from './room-condition-icon';

function getTag({ condition }: { condition: ValueListValue<RoomCondition> }) {
  switch (condition?.id) {
    case 'fair':
      return StatusAverageTag;
    case 'good':
      return StatusGoodTag;
    case 'poor':
      return StatusBadTag;
    default:
      return Tag;
  }
}

export function RoomConditionTag({
  condition
}: {
  condition: ValueListValue<RoomCondition>;
}) {
  const token = useToken();

  const Tag = getTag({ condition });

  return (
    <Tag>
      <Box
        flexDirection='row'
        alignItems='center'
        columnGap={token('spacing.xs')}
      >
        <RoomConditionIcon
          size='s'
          roomCondition={condition.id as InspectionRoomConditionExcludingNone}
        />
        <span>{condition?.label}</span>
      </Box>
    </Tag>
  );
}
