import React from 'react';
import { RecordTable } from 'view/components/table';
import { receiptColumns } from '../utils/receipt-columns';
import { SelectTrustJournalEntryActionCell } from './select-trust-journal-entry-action-cell';

export function AvailableTrustJournalEntriesTable({ data, blockProps }) {
  const {
    selectedReceipts,
    setSelectedReceipts,
    availableReceipts,
    setAvailableReceipts,
    trustJournalEntriesDataIsLoading
  } = blockProps;

  return (
    <RecordTable
      items={data}
      isLoading={trustJournalEntriesDataIsLoading}
      columns={[
        ...receiptColumns,
        {
          id: 'action',
          Cell: SelectTrustJournalEntryActionCell,
          accessor: (item) => {
            return {
              trustJournalEntry: item,
              selectedReceipts,
              setSelectedReceipts,
              availableReceipts,
              setAvailableReceipts
            };
          },
          width: 70,
          sticky: 'right'
        }
      ]}
    />
  );
}
