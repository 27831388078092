import React from 'react';
import { BlockConfig } from 'view/components/record-screen/types';
import { Grid, Column } from '@rexlabs/grid';
import { Value } from 'view/components/values';
import { Field } from '@rexlabs/form';
import { TextInput } from '@rexlabs/text-input';

export const overviewBlock: BlockConfig = {
  id: 'overview',
  title: 'Overview',
  View: ({ data }) => {
    return (
      <Grid>
        <Column width={12}>
          <Value label='Name' value={data?.name} />
        </Column>
      </Grid>
    );
  },
  Edit: () => {
    return (
      <Grid>
        <Column width={12}>
          <Field label='Name' name='name' Input={TextInput} />
        </Column>
      </Grid>
    );
  }
};
