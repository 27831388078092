import dayjs from 'dayjs';
import { InspectionTask } from '../types/InspectionTask';

export function getFormattedStartAndEndTime(value: InspectionTask) {
  const scheduledAt = value?.details?.scheduled_at;
  const startTime = scheduledAt
    ? dayjs(value?.details?.scheduled_at).tz()
    : null;

  const duration = value?.details?.scheduled_duration;

  const endTime =
    startTime && duration ? startTime?.add(duration, 'minute') : null;

  const format = 'HH:mma';

  return startTime && endTime
    ? `${startTime.format(format)} - ${endTime.format(format)}`
    : null;
}
