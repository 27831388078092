import { capitalize, toLower } from 'lodash';
import dayjs from 'dayjs';
import { ChecklistTemplateItem } from '../types/ChecklistTemplate';
import { ChecklistTemplatesItemForm } from '../types/ChecklistTemplatesForm';

export function getTriggerDescription(
  checklistTemplateItem: ChecklistTemplatesItemForm | ChecklistTemplateItem
) {
  const actionString = getActionString(checklistTemplateItem);

  return checklistTemplateItem.trigger_type?.id === 'on_due_date'
    ? createOnDueDateDescription(checklistTemplateItem, toLower(actionString))
    : createOnCompletionDescription(capitalize(actionString));
}

function getActionString(
  checklistTemplateItem: ChecklistTemplatesItemForm | ChecklistTemplateItem
) {
  const messageActionType = checklistTemplateItem.action_type?.label;

  switch (checklistTemplateItem.action_type?.id) {
    case 'send_email':
    case 'send_sms':
    case 'send_message': {
      const templateName = checklistTemplateItem.action_message_template?.name;
      // like: send email "Email Template Name"
      return `${messageActionType} "${templateName}"`;
    }
    case 'set_status': {
      const taskStatus = checklistTemplateItem.action_data?.task_status?.label;
      // like: set status to "Task Status"
      return `${messageActionType} to "${taskStatus}"`;
    }
    case 'close_task':
    default:
      // like: close task
      return messageActionType;
  }
}

function createOnCompletionDescription(actionString: string) {
  // Just return the string for now. Might become more complex when we have more action types
  return `${actionString}`;
}

function createOnDueDateDescription(
  checklistTemplateItem: ChecklistTemplatesItemForm | ChecklistTemplateItem,
  actionString: string
) {
  const timeHour = checklistTemplateItem.trigger_data?.time_hour;
  // here we only want to format the 24hr time to 12hr time but
  // dayjs requires a date so we are passing in a fake date
  const formattedTime = dayjs('1/01/23 ' + `${timeHour}:00`).format('h:mma');

  return formattedTime && actionString
    ? `At ${formattedTime} ${actionString} and then complete this subtask`
    : '';
}
