import React, { useCallback } from 'react';

import { RecordDialog } from 'view/components/record-screen/dialog/dialog';
import { DialogContentConfig } from 'view/components/record-screen/dialog/types';

import { RecordSubmitHandler } from 'view/components/record-screen/utils';
import { useModelActions } from '@rexlabs/model-generator';
import { filterNullValues } from 'utils/filter-null-values';
import { agencyFeeModel } from 'src/modules/agency-fees/models/agency-fee';
import { AgencyFee } from 'src/modules/agency-fees/types/agency-fee-types';
import { agencyFeeBlock } from '../blocks/agency-fee';
import { useHasServicePackagesFeature } from '../hooks/use-has-service-packages-feature';

const content: DialogContentConfig = [
  {
    id: 'agency-fee',
    label: '',
    blocks: [agencyFeeBlock]
  }
];

interface EditAgencyFeeDialogProps {
  agencyFee: AgencyFee;
  onClose?: () => void;
  onSubmit?: (updatedAgencyFee: AgencyFee) => void;
}

export function EditAgencyFeeDialog({
  agencyFee,
  onClose,
  onSubmit
}: EditAgencyFeeDialogProps) {
  const hasServicePackagesFeature = useHasServicePackagesFeature();

  const { updateItem } = useModelActions(agencyFeeModel);

  const handleSubmit: RecordSubmitHandler = useCallback(
    async ({ values }) => {
      const editedAgencyFee = {
        ...filterNullValues(values),
        entity_type: {
          id: values.entity_type
        }
      };
      const updateItemRequest = await updateItem({
        id: agencyFee.id,
        data: editedAgencyFee
      });
      await onSubmit?.(updateItemRequest.data);

      return updateItemRequest.data;
    },
    [updateItem]
  );

  const initialData = {
    ...agencyFee,
    entity_type: agencyFee.entity_type.id
  };

  return (
    <RecordDialog
      onClose={onClose}
      data={initialData}
      title={
        hasServicePackagesFeature ? 'Edit fee template' : 'Update fee template'
      }
      handleSubmit={handleSubmit}
      content={content}
      blockProps={{
        entityTypeIsPredefined: false,
        showInfoBannerForEditingTemplate: true
      }}
    />
  );
}
