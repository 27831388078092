import { Tenancy } from 'data/models/entities/tenancies';
import { RecordTypes } from 'data/models/types';
import { SelectNormalised } from '@rexlabs/select-old';

/**
 * @deprecated Use v4-select-normalisers/tenancy instead
 */
export function normaliseTenancy(item: Tenancy): SelectNormalised {
  return {
    value: item,
    label: item.display_name,
    type: RecordTypes.Tenancy
  };
}
