import { makeIconComponent } from '@rexlabs/icons';

import SmileyFairSmall from 'src/assets/icons/s/smiley-fair.svg';
import SmileyFairMedium from 'src/assets/icons/m/smiley-fair.svg';

export const SmileyFairIcon = makeIconComponent({
  s: SmileyFairSmall,
  m: SmileyFairMedium
});

export default SmileyFairIcon;
