import { Portfolio } from 'src/modules/portfolios/models/portfolios-model';
import { useMemo } from 'react';
import { PropertyOwnership } from 'src/modules/property-ownerships/types/property-ownership-types';
import { PropertyTenancy } from 'src/modules/property-tenancies/types/property-tenancy-types';

type UserPropertyDetailsKeyParams = {
  selected_property_ownership: PropertyOwnership | null | undefined;
  selected_property_tenancy: PropertyTenancy | null | undefined;
  portfolio?: Portfolio | null;
};

export function usePropertyDetailsKey(
  initialValues: UserPropertyDetailsKeyParams
) {
  return useMemo(() => {
    return [
      initialValues.selected_property_ownership?.id,
      initialValues.selected_property_ownership?.updated_at,
      initialValues.selected_property_tenancy?.id,
      initialValues.selected_property_tenancy?.updated_at,
      initialValues.portfolio?.id,
      initialValues.portfolio?.updated_by
    ]
      .filter((item) => item)
      .join(':');
  }, [
    initialValues.selected_property_ownership?.id,
    initialValues.selected_property_ownership?.updated_at,
    initialValues.selected_property_tenancy?.id,
    initialValues.selected_property_tenancy?.updated_at,
    initialValues.portfolio?.id,
    initialValues.portfolio?.updated_by
  ]);
}
