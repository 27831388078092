import React, { ReactElement } from 'react';
import {
  border,
  padding,
  StyleSheet,
  text,
  useStyles,
  useToken
} from '@rexlabs/styling';
import Box from '@rexlabs/box';
import { Body, Label } from '@rexlabs/text';
import Tooltip from '@rexlabs/tooltip';
import Icons from '@rexlabs/icons';
import LoadingSpinner from '@rexlabs/loading-spinner';

export type ValueProps<D = any> = IdValueProps<D> | ValuedValueProps;

type CommonValueProps = {
  testId?: string;
  label?: string | ReactElement | null;
  right?: React.ReactNode;
  emptyValue?: string | ReactElement;
  isLoading?: boolean;
  HelpTooltipContent?: () => ReactElement;
};

export type IdValueProps<Data extends Record<string, any>> = {
  id: keyof Data;
  data: Data;
  value?: never;
} & CommonValueProps;

export type ValuedValueProps = {
  value?: string | number | ReactElement | null;
  id?: never;
  data?: never;
} & CommonValueProps;

const defaultStyles = StyleSheet({
  container: {
    width: '100%'
  },

  valueWrapper: {
    minHeight: '4rem',

    ...padding.styles({
      top: () => '1rem'
    }),
    ...border.styles({
      top: {
        width: 'thin',
        color: 'container.static.light'
      },
      bottom: {
        width: 'thin',
        color: () => 'transparent'
      }
    })
  },

  label: {
    ...text.styles({
      fallback: 'small.semibold',
      color: ({ token }) => token('color.textStyle.body.subtext')
    })
  },

  value: {
    width: '100%',
    padding: 0,
    display: 'flex',
    justifyContent: 'space-between'
  }
});

export function Value<D extends Record<string, any>>({
  label,
  value,
  emptyValue = '--',
  right,
  isLoading,
  HelpTooltipContent,
  testId,
  id,
  data,
  ...props
}: ValueProps<D>) {
  const s = useStyles(defaultStyles);
  const token = useToken();
  return (
    // HACK: Wrapping Box is needed to get around
    // some spacing negative margin shenanigans

    <Box flex={1} data-testid={testId ?? id} {...props}>
      <Box
        {...s('container')}
        flexDirection='column'
        flex='1'
        spacing={token('spacing.xxs')}
      >
        <Box
          flexDirection='row'
          alignItems='center'
          justifyContent='space-between'
        >
          <Box flexDirection='row' alignItems='center' flex={1}>
            <Label
              {...s('label')}
              data-testid={`${testId ?? (id as string)}-label`}
            >
              {label}
            </Label>
          </Box>
          <Box alignItems='center' flexDirection='row'>
            {HelpTooltipContent && (
              <Tooltip Content={HelpTooltipContent}>
                <Box alignItems='center' flex={0}>
                  <Icons.InfoCircle {...s('label')} size='m' />
                </Box>
              </Tooltip>
            )}
          </Box>
        </Box>

        <Box {...s('valueWrapper')} flex={1}>
          <Body
            {...s('value')}
            data-testid={`${testId ?? (id as string)}-value`}
          >
            {isLoading ? (
              <LoadingSpinner size={13} strokeWidth={2} />
            ) : (
              <>
                <span>{value ?? (data?.[id!] || emptyValue)}</span>
                {right}
              </>
            )}
          </Body>
        </Box>
      </Box>
    </Box>
  );
}
