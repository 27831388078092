import { StyleSheet, useStyles } from '@rexlabs/styling';
import { TitleBlock } from 'view/components/cards/title/title-block';
import { RecordObject, RecordTypes } from 'data/models/types';
import { getRecordTitle } from 'utils/records/get-record-title';
import { TagConfig } from 'view/components/@luna/title-block';
import { ActionDeclaration } from 'src/modules/common/actions/types/action-declaration-types';
import { useGetPrimaryRecordActionGroup } from 'view/hooks/actions/use-get-primary-record-action-group';
import { useGetToggleClosedAction } from 'src/modules/tasks/common/hooks/action-declarations/use-get-toggle-closed-action';
import { formatDate } from 'utils/dates/format';
import { lowerCase } from 'lodash';
import React from 'react';
import MaintenanceIcon from 'view/components/icons/maintenance';
import { useGetAddDocumentsAction } from 'src/modules/documents/actions/use-get-add-documents-action';
import { useGetAddNotesAction } from 'src/modules/tasks/common/actions/get-add-notes-action';
import { useMaintenanceStatusTag } from 'src/modules/tasks/maintenance/hooks/use-maintenance-status-tag';
import { useGetCreateEmailAction } from 'src/modules/communications/messages/hooks/action-declarations/use-get-create-email-action';
import { tasksModel } from 'src/modules/tasks/common/models/tasks-model';
import { useGetCreateSMSAction } from 'src/modules/communications/messages/hooks/action-declarations/use-get-create-sms-action';
import { useGetAddBillAction } from '../hooks/action-menu-items/use-get-add-bill-action';
import { MaintenanceTask } from '../types/MaintenanceTask';

const type = RecordTypes.TaskMaintenance;

type MaintenanceRecordObject = Extract<
  RecordObject,
  { type: 'task_maintenance' }
>;

type MaintenanceTitleBlockProps = {
  maintenance?: MaintenanceTask;
};

const styles = StyleSheet({
  subText: {
    color: ({ token }) => token('color.textStyle.body.default'),
    fontWeight: ({ token }) => token('typography.weight.semibold')
  }
});

export function MaintenanceTitleBlock({
  maintenance
}: MaintenanceTitleBlockProps) {
  const s = useStyles(styles);

  const getPrimaryRecordActionGroup = useGetPrimaryRecordActionGroup();
  const getToggleClosedAction = useGetToggleClosedAction();
  const getAddDocumentsAction = useGetAddDocumentsAction();
  const getAddNotesAction = useGetAddNotesAction();
  const getAddBillAction = useGetAddBillAction();
  const getCreateEmailAction = useGetCreateEmailAction(tasksModel);
  const getCreateSMSAction = useGetCreateSMSAction(tasksModel);
  const statusTag: TagConfig = useMaintenanceStatusTag(maintenance);

  const title = getRecordTitle({
    type,
    object: maintenance
  } as MaintenanceRecordObject);

  if (!maintenance) {
    return null;
  }

  const actions = [
    ...getPrimaryRecordActionGroup('task_maintenance', maintenance.id),
    {
      label: 'Actions',
      actions: [
        getCreateEmailAction(maintenance),
        getCreateSMSAction(maintenance),
        getAddDocumentsAction({ data: maintenance! }),
        getAddNotesAction({ data: maintenance! }),
        getToggleClosedAction(maintenance),
        getAddBillAction({ data: maintenance })
      ]
    }
  ];

  const closed_at = maintenance.closed_at;

  return (
    <TitleBlock
      title={title}
      type='Maintenance'
      actions={actions as ActionDeclaration[]}
      tags={closed_at ? undefined : [statusTag]}
      Icon={() => <MaintenanceIcon size='xl' />}
      subText={
        closed_at ? (
          <div>
            <span>This maintenance task has been closed with the status </span>
            <span {...s('subText')}>
              {lowerCase(maintenance.details?.status?.label)}
            </span>{' '}
            on <span {...s('subText')}>{formatDate(closed_at)}</span>
          </div>
        ) : undefined
      }
    />
  );
}
