import { useGetPrimaryRecordActionGroup } from 'view/hooks/actions/use-get-primary-record-action-group';
import { User } from 'src/modules/users/types/user-types';
import { ActionDeclaration } from 'src/modules/common/actions/types/action-declaration-types';
import { useToggleSuspendAction } from 'src/modules/users/actions/use-suspend-action';
import { useResendInviteAction } from './use-resend-invite-action';

export function useGetUserActions() {
  const getPrimaryRecordActionGroup = useGetPrimaryRecordActionGroup();
  const getResendInviteAction = useResendInviteAction();
  const getToggleSuspendAction = useToggleSuspendAction();

  return (user: User): ActionDeclaration[] => {
    const userHasNotAcceptedInvite =
      user.status.id === 'invited' || user.status.id === 'not_invited';
    return [
      ...getPrimaryRecordActionGroup('user', user.id),
      ...(userHasNotAcceptedInvite ? [getResendInviteAction(user)] : []),
      //todo: update this to check for an admin role
      getToggleSuspendAction(user)
    ];
  };
}
