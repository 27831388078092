import React from 'react';
import { Body } from '@rexlabs/text';
import { BlockConfig } from 'view/components/record-screen/types';

export const wipBlock: BlockConfig = {
  id: 'wip',
  title: 'Work in progress',
  View: () => (
    <Body grey as='span'>
      This feature is not yet implemented
    </Body>
  ),
  workInProgress: true
};
