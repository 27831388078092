import { ActionMenuItem } from '@rexlabs/action-menu';

import { useGetTodoActions } from 'src/modules/tasks/todos/hooks/action-menu-items/use-get-todo-actions';
import { useGetMaintenanceActions } from 'src/modules/tasks/maintenance/hooks/action-menu-items/use-get-maintenance-actions';
import { useGetLeaseReviewActions } from 'src/modules/tasks/lease-review/hooks/action-menu-items/use-get-lease-review-actions';
import { Task } from 'src/modules/tasks/common/types/Task';
import { useGetInspectionActions } from 'src/modules/tasks/inspections/hooks/action-menu-items/use-get-inspection-actions';
import { useGetArrearsActions } from 'src/modules/tasks/arrears/hooks/action-menu-items/use-get-arrears-actions';
import { useGetMoveInActions } from 'src/modules/tasks/move-in/hooks/action-menu-items/use-get-move-in-actions';
import { useGetMoveOutActions } from 'src/modules/tasks/move-out/hooks/action-menu-items/use-get-move-out-actions';
import { transformActionDeclarationsToActionMenuItems } from 'utils/actions/transforms';
import { useGetPropertyComplianceActions } from 'src/modules/tasks/property-compliance/hooks/action-menu-items/use-get-property-compliance-actions';
import { useGetSupplierComplianceActions } from 'src/modules/tasks/supplier-compliance/hooks/action-menu-items/use-get-supplier-compliance-actions';
import { useGetQuoteActions } from 'src/modules/tasks/quotes/hooks/action-menu-items/use-get-quote-actions';
import { useGetWorkOrderActions } from 'src/modules/tasks/work-orders/hooks/action-menu-items/use-get-work-order-actions';

export function useGetAnyTaskActions() {
  const getTodoTaskActions = useGetTodoActions();
  const getMaintenanceActions = useGetMaintenanceActions();
  const getLeaseReviewActions = useGetLeaseReviewActions();
  const getInspectionActions = useGetInspectionActions();
  const getArrearsActions = useGetArrearsActions();
  const getMoveInActions = useGetMoveInActions();
  const getMoveOutActions = useGetMoveOutActions();
  const getPropertyComplianceActions = useGetPropertyComplianceActions();
  const getSupplierComplianceActions = useGetSupplierComplianceActions();
  const getQuoteActions = useGetQuoteActions();
  const getWorkOrderActions = useGetWorkOrderActions();

  return (task?: Task<any>): ActionMenuItem[] => {
    if (!task) return [];

    switch (task.type?.id) {
      case 'todo':
        return getTodoTaskActions(task);
      case 'task_maintenance':
        return getMaintenanceActions(task);
      case 'task_lease_review':
        return getLeaseReviewActions(task);
      case 'task_inspection':
        return transformActionDeclarationsToActionMenuItems(
          getInspectionActions(task)
        );
      case 'task_arrears':
        return getArrearsActions(task);
      case 'task_move_in':
        return transformActionDeclarationsToActionMenuItems(
          getMoveInActions(task)
        );
      case 'task_move_out':
        return transformActionDeclarationsToActionMenuItems(
          getMoveOutActions(task)
        );
      case 'task_property_compliance':
        return getPropertyComplianceActions(task);
      case 'task_supplier_compliance':
        return getSupplierComplianceActions(task);
      case 'task_quote':
        return transformActionDeclarationsToActionMenuItems(
          getQuoteActions(task)
        );
      case 'task_work_order':
        return transformActionDeclarationsToActionMenuItems(
          getWorkOrderActions(task)
        );
      default:
        return [];
    }
  };
}
