import React from 'react';
import { Field } from '@rexlabs/form';
import { TextArea } from '@rexlabs/text-input';

import { BlockConfig } from 'view/components/record-screen/types';
import { CentAmountInput } from 'view/components/inputs/cent-amount';
import { CurrencyValue } from 'view/components/values/currency';
import { Value } from 'view/components/values';
import { Grid } from 'view/components/@luna/form/grid';
import { Ownership } from 'data/models/entities/ownerships';
import { Trans, useTranslation } from 'react-i18next';
import Tooltip from '@rexlabs/tooltip';
import Box from '@rexlabs/box';
import InfoCircleIcon from 'view/components/icons/info';
import { useFeatureFlags } from 'view/components/@luna/feature-flags';
import { FLAGS } from 'utils/feature-flags';

export const disbursementWithholdFundsBlock: BlockConfig<Ownership> = {
  id: 'disbursement-withhold-funds',
  title: () => (
    <Trans i18nKey='withheld-funds.ownership-withheld-funds-block.title' />
  ),
  TitleTooltip: () => {
    const { hasFeature } = useFeatureFlags();
    return hasFeature(FLAGS.TASK_WITHHELD_FUNDS) ? (
      <Tooltip
        Content={() => (
          <Trans
            i18nKey={'withheld-funds.ownership-withheld-funds-block.tooltip'}
          />
        )}
      >
        <Box flexDirection='row' alignItems='center' width={24} height={24}>
          <InfoCircleIcon size={'m'} />
        </Box>
      </Tooltip>
    ) : null;
  },
  validate: {
    definitions: {
      'disbursement_withheld_funds.amount': { rules: 'min:0' }
    }
  },
  View: ({ data }) => {
    const { t } = useTranslation();
    const { hasFeature } = useFeatureFlags();
    return (
      <Grid columns={1}>
        <Grid columns={2}>
          <CurrencyValue
            label={t(
              'withheld-funds.ownership-withheld-funds-block.fields.amount'
            )}
            value={data?.disbursement_withheld_funds?.amount}
          />
          {hasFeature(FLAGS.TASK_WITHHELD_FUNDS) ? (
            <CurrencyValue
              label={t(
                'withheld-funds.ownership-withheld-funds-block.fields.total_amount'
              )}
              value={
                data?.disbursement_withheld_funds
                  ?.total_amount_including_related_withheld_funds
              }
            />
          ) : null}
        </Grid>

        <Value
          label='Reason'
          value={data?.disbursement_withheld_funds?.reason}
        />
      </Grid>
    );
  },
  Edit: ({ values }) => {
    const { t } = useTranslation();
    const { hasFeature } = useFeatureFlags();

    return (
      <Grid columns={1}>
        <Grid columns={2}>
          <Field
            name='disbursement_withheld_funds.amount'
            label={
              t(
                'withheld-funds.ownership-withheld-funds-block.fields.amount'
              ) as string
            }
            Input={CentAmountInput}
          />
          {hasFeature(FLAGS.TASK_WITHHELD_FUNDS) ? (
            <CurrencyValue
              label={t(
                'withheld-funds.ownership-withheld-funds-block.fields.total_amount'
              )}
              value={
                values?.disbursement_withheld_funds
                  ?.total_amount_including_related_withheld_funds
              }
            />
          ) : null}
        </Grid>
        <Field
          name='disbursement_withheld_funds.reason'
          label='Reason'
          Input={TextArea}
        />
      </Grid>
    );
  }
};
