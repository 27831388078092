import React from 'react';
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult
} from 'react-beautiful-dnd';

import { TextInput } from '@rexlabs/text-input';
import { OutlineIconButton, GhostIconButton } from '@rexlabs/button';
import Box from '@rexlabs/box';
import { Field, FieldArray, FieldArrayField } from '@rexlabs/form';
import { useStyles, StyleSheet } from '@rexlabs/styling';

import DragIcon from 'view/components/icons/drag';
import { AddIcon } from 'view/components/icons/add';
import { RemoveIcon } from 'view/components/icons/remove';

const styles = StyleSheet({
  item: {
    background: ({ token }) => token('palette.grey.200'),
    borderRadius: 8,
    display: 'flex',
    alignItems: 'center',
    gap: ({ token }) => token('spacing.s'),
    padding: ({ token }) =>
      `${token('spacing.s')} ${token('spacing.l')} ${token(
        'spacing.s'
      )} ${token('spacing.s')}`,
    marginBottom: ({ token }) => token('spacing.s')
  },

  removeIcon: {
    color: ({ token }) => token('palette.red.900')
  },

  dragHandle: {
    lineHeight: 0,
    appearance: 'none',
    padding: 0,
    margin: 0,
    border: 'none',
    background: 'transparent',

    '&:focus': {
      boxShadow: 'none'
    }
  },

  visuallyHidden: {
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: '1px',
    overflow: 'hidden',
    position: 'absolute',
    whiteSpace: 'nowrap',
    width: '1px'
  }
});

interface ManageNameAndSortingFieldArrayProps {
  fieldArrayName: string;
  fieldName?: string;
  textInputPlaceholder: string;
  onDelete?: (actions: FieldArrayField['actions'], index: number) => any;
}

export function ManageNameAndSortingFieldArray({
  fieldArrayName,
  fieldName = 'label',
  onDelete = (actions) => actions.remove(),
  textInputPlaceholder
}: ManageNameAndSortingFieldArrayProps) {
  return (
    <FieldArray name={fieldArrayName}>
      {(fieldArrayProps) => {
        const { fields, push, move } = fieldArrayProps;

        const onDragEnd = (result: DropResult) => {
          if (!result.destination) {
            return;
          }
          move(result.source.index, result.destination.index);
        };

        return (
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable
              direction='vertical'
              ignoreContainerClipping
              droppableId='draggable-list'
            >
              {(provided) => (
                <Box
                  ref={provided.innerRef}
                  marginTop={12}
                  padding={8}
                  {...provided.droppableProps}
                >
                  {fields.map(({ field, actions }, index) => (
                    <ManageNameAndSortingItem
                      onDelete={() => onDelete?.(actions, index)}
                      field={field}
                      fieldName={fieldName}
                      index={index}
                      key={index}
                    />
                  ))}
                  {provided.placeholder}
                </Box>
              )}
            </Droppable>

            <NewRow
              fieldName={fieldName}
              textInputPlaceholder={textInputPlaceholder}
              onNewItem={(arg) => {
                push(arg);
              }}
            />
          </DragDropContext>
        );
      }}
    </FieldArray>
  );
}

function ManageNameAndSortingItem({ field, onDelete, fieldName, index }) {
  const s = useStyles(styles);

  const handleMouseDown = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.currentTarget.focus();
  };

  return (
    <Draggable draggableId={field.key} key={field.key} index={index}>
      {(provided) => (
        <Box
          // @ts-ignore
          ref={provided.innerRef}
          style={provided.draggableProps.style}
          {...s('item')}
          {...provided.draggableProps}
        >
          <button
            type='button'
            aria-label='drag item'
            {...s('dragHandle')}
            {...provided.dragHandleProps}
            onMouseDown={handleMouseDown}
          >
            <DragIcon />
          </button>

          <Field
            {...field}
            id='label'
            name={`${field.name}.${fieldName}`}
            Input={TextInput}
            optional={false}
          />

          <div {...s('visuallyHidden')}>
            <Field
              {...field}
              id='id'
              name={`${field.name}.id`}
              Input={TextInput}
              optional={false}
            />
          </div>

          <GhostIconButton
            {...s('removeIcon')}
            aria-label='delete item'
            Icon={RemoveIcon}
            onClick={onDelete}
          />
        </Box>
      )}
    </Draggable>
  );
}

function NewRow({
  onNewItem,
  fieldName,
  textInputPlaceholder = 'Add new item'
}) {
  const [label, setLabel] = React.useState('');

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLabel(e.target.value);
  };

  const addNewRow = () => {
    onNewItem({
      [fieldName]: label
    });
    setLabel('');
  };

  const handleKeydown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (!(e.key === 'Enter')) return;

    addNewRow();
  };

  return (
    <Box
      alignItems='center'
      gap={12}
      paddingBottom={12}
      paddingLeft={52}
      paddingRight={28}
    >
      <TextInput
        placeholder={textInputPlaceholder}
        value={label}
        onChange={handleChange}
        onKeyDown={handleKeydown}
      />

      <OutlineIconButton
        Icon={AddIcon}
        onClick={addNewRow}
        data-testid={'add-item-button'}
      />
    </Box>
  );
}
