import { BaseModelGeneratorModel, EntityModel } from '@rexlabs/model-generator';
import { RecordObject, RecordType } from 'data/models/types';
import { getRecordTypeFromModel } from 'src/modules/common/utils/Records/get-record-type-from-model';

export type RecordTypeIdentifier = RecordType | EntityModel<any>;

/**
 * Returns a RecordObject from an identifying type and record
 */
export function getRecordObject(
  object: BaseModelGeneratorModel,
  typeIdentifier: RecordTypeIdentifier
): RecordObject {
  let type;
  if (typeof typeIdentifier === 'string') {
    type = typeIdentifier;
  } else {
    type = getRecordTypeFromModel(typeIdentifier as EntityModel<any>);
  }

  return {
    object,
    type
  };
}
