import { Select } from '@rexlabs/select';
import { ValueListValue } from 'data/models/types';
import React from 'react';
import { AddressCountry } from 'src/modules/properties/types/property-types';
import { normaliseAddressState } from 'utils/normaliser/v4-select-normalisers/address-state';

// HACK: We don't have BE for this, so we're adding a temporary select options for the state
const countryOptions: ValueListValue<AddressCountry>[] = [
  {
    id: 'AUS',
    label: 'Australia'
  },
  {
    id: 'NZL',
    label: 'New Zealand'
  },
  {
    id: 'GBR',
    label: 'United Kingdom'
  }
  // TODO: add other countries
];

export function AddressCountrySelect(props: typeof Select) {
  return (
    <Select
      {...props}
      id='address-country-select'
      items={countryOptions}
      normaliser={normaliseAddressState}
      placeholder='Select from list'
    />
  );
}
