import React, { useCallback } from 'react';
import { isNumber } from 'lodash';

export function useInsertToTextInput<
  TInputType extends HTMLInputElement | HTMLTextAreaElement = HTMLInputElement
>(updateValue: (value: string) => void) {
  const [shouldInsertToInput, setShouldInsertToInput] = React.useState(false);
  const inputRef = React.useRef<TInputType>(null);

  const insertToTextInput = useCallback(
    (value: string) => {
      const input = inputRef.current;
      const start = input?.selectionStart;

      if (!isNumber(start)) {
        return;
      }
      const oldValue = input?.value || '';
      const beforeCursor = oldValue.slice(0, start);
      const afterCursor = oldValue.slice(start);

      const newValue = [beforeCursor, value, afterCursor]
        .filter(Boolean)
        .join('');

      updateValue?.(newValue);
    },
    [inputRef, updateValue]
  );

  const shouldInsert =
    shouldInsertToInput && isNumber(inputRef?.current?.selectionStart);

  return {
    inputRef,
    shouldInsertToInput: shouldInsert,
    setShouldInsertToInput,
    insertToTextInput
  };
}
