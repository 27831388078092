import React from 'react';
import { StyleSheet, padding, useStyles } from '@rexlabs/styling';

import { Card } from 'view/components/@luna/card';

const defaultStyles = StyleSheet({
  empty: {
    maxWidth: '720px',
    margin: 'auto',
    ...padding.styles({
      all: 'xl'
    })
  },
  grey: {
    background: ({ token }) => token('color.container.static.contrast')
  }
});

interface EmptyCardProps extends React.PropsWithChildren<unknown> {
  /**
   * Sets the background color to grey when truthy
   */
  hasGreyBackground?: boolean;
}

export function EmptyCard({
  children,
  hasGreyBackground = false
}: EmptyCardProps) {
  const s = useStyles(defaultStyles);

  return (
    <Card {...s({ grey: hasGreyBackground })}>
      <div {...s('empty')}>{children}</div>
    </Card>
  );
}
