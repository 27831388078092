import { Generator, GeneratorConfig } from 'data/models/generator';
import { AddressCountry } from 'src/modules/properties/types/property-types';

import {
  BaseFields,
  RecordReference,
  RecordTypes,
  TimestampAttributes,
  ValueListValue
} from '../../types';

import {
  financialsReconciliationsModel,
  Reconciliation
} from './reconciliations';

type NotYetBankedByType<Types extends string> = {
  [index in Types]: {
    count: number;
    amount: number;
  };
};

type NotYetBanked<Types extends string> = {
  count: number;
  amount: number;
  by_type: NotYetBankedByType<Types>;
};

export interface BankAccountFinancialSummary {
  last_deposit_at: string;
  pending_deposits_amount_total: number;
  pending_deposits_count: number;

  last_withdrawal_at: string;
  pending_withdrawals_amount_total: number;
  pending_withdrawals_count: number;
}

export interface BankAccount
  extends BaseFields<typeof RecordTypes.BankAccount>,
    TimestampAttributes,
    RecordReference {
  name: string;
  is_default: boolean;
  is_trust_account: boolean;
  country: ValueListValue<AddressCountry>;
  currency: ValueListValue<string>;
  bank: ValueListValue<string> | null;
  iban?: {
    account_number: string;
  };
  swift?: {
    account_number: string;
    branch_code: string;
  };
  account?: {
    account_name: string;
    branch_code: string;
    account_number: string;
  };
  custom_bank_name: string | null;
  custom_bank_code: string | null;
  bpay?: {
    is_enabled: boolean;
    biller_code: string;
    biller_short_name: string;
  };
  aba?: {
    is_enabled: boolean;
    direct_entry_user_id: string;
    max_transactions_per_upload: number;
    is_self_balancing_transaction_included: boolean;
  };
  sage?: {
    is_enabled: boolean;
    is_amount_in_pence: boolean;
  };
  bacs?: {
    is_enabled: boolean;
    service_user_number: string;
    customer_name: string;
  };
  trust_withdrawals_not_yet_banked?: NotYetBanked<
    | 'bpay'
    | 'cheque'
    | 'eft'
    | 'eft_iban'
    | 'eft_swift'
    | 'eft_bacs'
    | 'eft_fps'
  >;
  trust_receipts_not_yet_banked?: NotYetBanked<
    | 'bpay'
    | 'cheque'
    | 'eft'
    | 'eft_iban'
    | 'eft_swift'
    | 'bank_cheque'
    | 'card'
    | 'cash'
    | 'money_order'
  >;
  last_reconciliation?: Reconciliation;
  financial_summary?: BankAccountFinancialSummary;
  active_reconciliation?: Reconciliation;

  end_of_month_periods_due?: {
    data: { start_date: string; end_date: string }[];
  };
}

const config: GeneratorConfig = {
  entities: {
    related: {
      chart_of_accounts_account: {
        include: 'chart_of_accounts_account'
      },
      account: {
        include: 'account'
      },
      bpay: {
        include: 'bpay'
      },
      aba: {
        include: 'aba'
      },
      sage: {
        include: 'sage'
      },
      bacs: {
        include: 'bacs'
      },
      trust_withdrawals_not_yet_banked: {
        include: 'trust_withdrawals_not_yet_banked'
      },
      trust_receipts_not_yet_banked: {
        include: 'trust_receipts_not_yet_banked'
      },
      last_reconciliation: {
        include: 'last_reconciliation',
        model: financialsReconciliationsModel
      },
      active_reconciliation: {
        include: 'active_reconciliation',
        model: financialsReconciliationsModel
      },
      financial_summary: {
        include: 'financial_summary'
      },
      end_of_month_periods_due: {
        include: 'end_of_month_periods_due'
      }
    }
  }
};

export const bankAccountModel = new Generator<BankAccount>(
  'financials/bank-accounts',
  config
).createEntityModel();
