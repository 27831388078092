import { useDialog } from '@rexlabs/dialog';
import {
  AttachedTo,
  CreateDocumentDialog
} from 'src/modules/documents/dialogs/create-document-dialog';
import { getRecordObject } from 'src/modules/common/utils/Records/get-record-object';
import { getRecordLabel } from 'utils/records/get-record-label';
import { DocumentRelatedContextObject } from 'src/modules/communications/messages/types/attach-document-from-a-record';
import { DocumentRelatedRecord } from '../components/connectors/document-list';

export function useGetAddDocumentsAction() {
  const { open } = useDialog(CreateDocumentDialog);
  return ({
    data,
    attachedTo,
    label = 'Add document'
  }: {
    data: DocumentRelatedRecord | DocumentRelatedContextObject;
    attachedTo?: AttachedTo[];
    label?: string;
  }) => {
    const recordType =
      data?.__record_type !== 'task'
        ? data?.__record_type
        : data?.details?.__record_type || data?.type.id;

    const recordObject = getRecordObject(data, recordType);

    return {
      label: label,
      id: 'create-document',
      group: 'add-or-send',
      handleAction: () =>
        open({
          initialValues: {
            attached_to: attachedTo
              ? attachedTo
              : [
                  {
                    id: data.id,
                    label: getRecordLabel(recordObject),
                    type: { id: data?.__record_type }
                  }
                ],
            record_type: recordType
          }
        })
    };
  };
}
