import { OutlineSplitButton, PrimarySplitButton } from '@rexlabs/button';
import React from 'react';

import { Intent } from '../components/batch-receipting-card-layout';
import {
  BatchReceiptingItem,
  BatchReceiptingStatusId,
  MatchStatusId
} from '../types';
import {
  GetActions,
  useBatchReceiptingButtonConfig
} from './use-batch-receipting-button-config';

function getButtonType(
  statusId: BatchReceiptingStatusId,
  matchStatusId: MatchStatusId
) {
  const showPrimarySplitButton =
    statusId === 'new' &&
    ['matched_receipt', 'matched_allocate'].includes(matchStatusId);

  if (showPrimarySplitButton) return PrimarySplitButton;
  return OutlineSplitButton;
}

function getStatusIntent(
  statusId: BatchReceiptingStatusId,
  matchStatusId: MatchStatusId
): Intent {
  const isSuccess =
    statusId === 'new' &&
    ['matched_receipt', 'matched_allocate'].includes(matchStatusId);
  const isDanger = statusId === 'removed';
  const isWarning = statusId === 'suspended';

  if (isSuccess) return 'success';
  if (isDanger) return 'danger';
  if (isWarning) return 'warning';
  return 'secondary';
}

export function getBatchReceiptingItem(
  item: BatchReceiptingItem,
  getActions: GetActions
) {
  const statusId = item.status.id;
  const prioritisedMatchStatusId = item.prioritised_match.status.id;

  return {
    data: item,
    intent: getStatusIntent(statusId, prioritisedMatchStatusId),
    SplitActionButton: getButtonType(statusId, prioritisedMatchStatusId),
    // Previously we were running getActions in this function, but it's important that
    // the result of getActions is memoized to avoid crazy line item rerenders,
    // so we're running it within the line item component instead and using useMemo
    getActions
  };
}

export function useMapBatchReceiptingItems(
  batchReceiptingItems: BatchReceiptingItem[]
) {
  const getActions = useBatchReceiptingButtonConfig();

  return React.useMemo(() => {
    return batchReceiptingItems.map((item) =>
      getBatchReceiptingItem(item, getActions)
    );
  }, [batchReceiptingItems, getActions]);
}
