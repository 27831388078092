import React, { useCallback } from 'react';

import { DialogProps } from '@rexlabs/dialog';
import { useModelActions } from '@rexlabs/model-generator';

import {
  Owner,
  Ownership,
  ownershipsModel
} from 'data/models/entities/ownerships';

import { FLAGS } from 'utils/feature-flags';

import { useRecordCreatedToast } from 'src/modules/common/toasts/hooks/use-record-created-toast';
import { createOwnershipDisbursementPaymentMethodsBlock } from 'src/modules/disbursements/blocks/create-ownership-disbursement-payment-methods';
import { useDisbursementsFrequencyBlock } from 'src/modules/disbursements/blocks/disbursement-frequency-block';
import { getEvenSharePercentage } from 'src/modules/ownerships/utils/get-even-share-percentage';
import { mapDefaultDisbursementSettingsToDisbursementFrequencyForm } from 'src/modules/settings/account-settings/mappers/map-default-disbursement-settings-to-disbursement-frequency-form';
import {
  DEFAULT_DISBURSEMENT_FREQUENCY_SETTING_KEY,
  useSettings
} from 'src/modules/settings/hooks/useSettings';

import { useFeatureFlags } from 'view/components/@luna/feature-flags';
import { RecordDialog } from 'view/components/record-screen/dialog/dialog';
import { RecordSubmitHandler } from 'view/components/record-screen/utils';

import { statementPreferencesBlock } from 'src/modules/ownerships/blocks/statement-preferences-block';
import { mapDisbursementFrequencyFormDataToDisbursementFrequency } from '../../disbursements/mappers/map-disbursement-frequency-form-data-to-disbursement-frequency-request';
import { ownersBlock } from '../blocks/owners';

const useContent = () => {
  const disbursementsFrequencyBlock = useDisbursementsFrequencyBlock();
  return [
    {
      id: 'create-ownership',
      label: 'Create ownership',
      blocks: [
        ownersBlock,
        statementPreferencesBlock,
        disbursementsFrequencyBlock,
        createOwnershipDisbursementPaymentMethodsBlock
      ]
    }
  ];
};

export interface CreateOwnershipDialogProps extends DialogProps {
  onCreate?: (ownership: Ownership) => void;
}

export function CreateOwnershipDialog({
  onClose,
  onCreate
}: CreateOwnershipDialogProps) {
  const settings = useSettings();
  const { hasFeature } = useFeatureFlags();

  const { createItem, refreshLists, updateItem } = useModelActions(
    ownershipsModel
  );
  const addToast = useRecordCreatedToast(ownershipsModel);
  const content = useContent();
  const handleSubmit: RecordSubmitHandler = useCallback(
    async ({ values }) => {
      const {
        disbursement_frequency,
        disbursement_payment_methods,
        owners,
        ...rest
      } = values;

      const disbursementFrequency = {
        ...disbursement_frequency,
        on_frequency_count:
          disbursement_frequency?.disbursement_frequency_count_and_unit
            ?.on_frequency_count,
        on_frequency_unit:
          disbursement_frequency?.disbursement_frequency_count_and_unit
            ?.on_frequency_unit
      };

      const evenShare = getEvenSharePercentage(owners.length);

      const { data: ownership } = await createItem({
        data: {
          name: owners?.map?.(({ contact }) => contact?.name)?.join(' & '),
          owners: owners?.map?.((owner: Owner, index: number) => ({
            share: evenShare[index],
            ...owner
          })),
          ...rest
        }
      });
      // TODO: refactor this to either use a pipeline request or a single request T_T
      await Promise.all([
        updateItem({
          id: ownership.id,
          data: {
            disbursement_frequency: {
              ...disbursementFrequency,
              ...mapDisbursementFrequencyFormDataToDisbursementFrequency(
                disbursementFrequency
              )
            }
          }
        }),
        updateItem({
          id: ownership.id,
          data: {
            disbursement_payment_methods
          }
        })
      ]);

      onCreate?.(ownership);

      addToast(ownership);

      await refreshLists();

      return ownership;
    },
    [addToast, createItem, onCreate]
  );

  const initialDisbursementValues = !hasFeature(
    FLAGS.DEFAULT_DISBURSEMENT_FREQUENCY_SETTING
  )
    ? {
        disbursement_frequency: {
          is_disbursed_on_balance_amount: false,
          is_disbursed_on_frequency: false
        }
      }
    : mapDefaultDisbursementSettingsToDisbursementFrequencyForm(
        settings[DEFAULT_DISBURSEMENT_FREQUENCY_SETTING_KEY]
      );

  const initialValues = {
    ...initialDisbursementValues,
    eofy_statement_enabled: true
  };

  return (
    <RecordDialog
      initialValues={initialValues}
      title='Create ownership'
      onClose={onClose}
      content={content}
      handleSubmit={handleSubmit}
      blockProps={{ isCreating: true }}
    />
  );
}
