import React from 'react';
import { GhostButton } from '@rexlabs/button';
import { ButtonGroupProps } from 'view/components/record-screen/dialog/types';
import { StandardDialogFooter } from 'view/components/dialogs/components/standard-dialog-footer';

export function DisabledDialogButtonGroup({ onClose }: ButtonGroupProps) {
  return (
    <StandardDialogFooter>
      <GhostButton onClick={onClose}>Cancel</GhostButton>
    </StandardDialogFooter>
  );
}
