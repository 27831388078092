import { makeIconComponent } from '@rexlabs/icons';

import SmallOwnershipsSvg from 'assets/icons/s/ownerships.svg';
import MediumOwnershipsSvg from 'assets/icons/m/ownerships.svg';
import LargeOwnershipsSvg from 'assets/icons/l/ownerships.svg';
import ExtraLargeOwnershipsSvg from 'assets/icons/xl/ownerships.svg';

export const OwnershipsIcon = makeIconComponent({
  s: SmallOwnershipsSvg,
  m: MediumOwnershipsSvg,
  l: LargeOwnershipsSvg,
  xl: ExtraLargeOwnershipsSvg
});

export default OwnershipsIcon;
