import { Message } from 'src/modules/communications/messages/types/Message';
import { api } from 'utils/api/api-client';
import { useErrorDialog } from '@rexlabs/dialog';
import { useToast } from 'view/components/@luna/notifications/toast';
import React from 'react';
import { pluralize } from 'utils/formatters';
import { useModelActions } from '@rexlabs/model-generator';
import { messagesModel } from 'src/modules/communications/messages/models/messages-model';

export function useBulkSend() {
  const { open: openErrorDialog } = useErrorDialog();
  const { refreshLists } = useModelActions(messagesModel);
  const { addToast } = useToast();
  return async (messages: Message[]) => {
    try {
      const response = await api.post('/communication/outbox/bulk-send', {
        selection: { selected_ids: messages.map((m) => m.id) }
      });

      const messagesText = pluralize(
        'messages',
        response.data.number_of_records_queued,
        true
      );

      addToast({
        type: 'info',
        description: (
          <>
            {messagesText}{' '}
            {pluralize('are', response.data.number_of_records_queued)} being
            processed
          </>
        )
      });

      await refreshLists();

      return true;
    } catch (e) {
      openErrorDialog(e);
    }
  };
}
