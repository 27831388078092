import { CustomAction, touchEtag } from '@rexlabs/model-generator';

import { Generator, GeneratorConfig } from 'data/models/generator';
import { api } from 'utils/api/api-client';
import _ from 'lodash';
import {
  BankDeposit,
  BankDepositStatus
} from 'src/modules/banking/bank-deposits/types/bank-deposit-types';
import { StatusTagMap } from 'src/modules/common/components/status-tag';
import { bankDepositLineItemModel } from 'src/modules/banking/bank-deposits/models/bank-deposit-line-item-model';

export const bankDepositConfig: GeneratorConfig = {
  entities: {
    related: {
      bank_account: {
        include: 'bank_account'
      },
      line_items: {
        include: 'line_items',
        model: [bankDepositLineItemModel]
      },
      totals: {
        include: 'totals'
      },
      reconciliation_status: {
        include: 'reconciliation_status'
      }
    }
  }
};

export const bankDepositStatusMap: StatusTagMap<BankDepositStatus> = {
  pending_deposit: 'average',
  deposited: 'good',
  void: 'bad'
};

export type VoidBankDepositPayload = {
  id: string;
  reason: string;
};

export type ReconcileItem = {
  object: {
    type:
      | 'bank_withdrawal'
      | 'bank_deposit'
      | 'unpresented_cheque'
      | 'adjustment'; // TODO: make sure this value is correct
    id: string;
  };
};

export type ReconcileItemsPayload = {
  items: ReconcileItem[];
};

const actionCreators = {
  voidDeposit: {
    request: (
      payload: VoidBankDepositPayload,
      _actions,
      _dispatch,
      _getState
    ) =>
      api.put(`financials/bank-deposits/${payload.id}/status`, {
        status: {
          id: 'void'
        },
        status_reason: payload.reason
      }),
    reduce: {
      success: (state, action) => {
        const originalId = action.meta.originalPayload.id;

        return {
          ...state,
          items: {
            ...state.items,
            [originalId]: touchEtag({
              ...state.items[originalId],
              data: {
                ...state.items[originalId].data,
                ...action.payload.data
              }
            })
          }
        };
      }
    }
  } as CustomAction<VoidBankDepositPayload, any>,
  reconcileItems: {
    request: (payload: ReconcileItemsPayload, _actions, _dispatch, _getState) =>
      api.post(`financials/reconcile-items`, {
        items: payload.items.map((item) => ({
          ...item,
          is_reconciled: true
        }))
      }),
    reduce: {
      initial: _.identity,
      success: _.identity,
      failure: _.identity
    }
  } as CustomAction<ReconcileItemsPayload, any>,
  unreconcileItems: {
    request: (payload: ReconcileItemsPayload, _actions, _dispatch, _getState) =>
      api.post(`financials/reconcile-items`, {
        items: payload.items.map((item) => ({
          ...item,
          is_reconciled: false
        }))
      }),
    reduce: {
      initial: _.identity,
      success: _.identity,
      failure: _.identity
    }
  } as CustomAction<ReconcileItemsPayload, any>,
  downloadItem: {
    request: (bankDepositId: string) =>
      api.post(`/reporting/bank-deposit`, {
        id: bankDepositId,
        format: 'pdf',
        disposition: 'download_link'
      }),
    reduce: _.identity
  }
};

export const bankDepositModel = new Generator<
  BankDeposit,
  typeof actionCreators
>('financials/bank-deposits', bankDepositConfig).createEntityModel({
  actionCreators
});
