import React from 'react';

import { ValueListItem } from 'utils/normaliser/value-list-item';
import { FLAGS } from 'utils/feature-flags';

import { useFeatureFlags } from 'view/components/@luna/feature-flags';

import { SUPPORTED_RECORD_TYPES_FOR_DOCUMENT_TYPES } from '../../constants/SupportedRecordTypesForDocumentTypeSelect';
import {
  BasicEditEmailForm,
  BasicEditEmailFormProps
} from './basic-forms/basic-edit-email-form';
import { TemplatedAttachments } from './parts/templated-attachments';
import { AttachFilesFromRecord } from './parts/attach-files-from-record';
import { AttachStaticFiles } from './parts/attach-static-files';
import { UploadAttachments } from './parts/upload-attachments';

interface EditEmailTemplateFormProps extends BasicEditEmailFormProps {
  templatedAttachments?: ValueListItem[];
}

/**
 * Complex form for editing email template content. It has two columns:
 *   - first column includes the subject, content, attachments, and templated attachments fields
 *   - second column includes the merge tag component
 */
export function EditEmailTemplateContentForm({
  contextType,
  templatedAttachments,
  setFieldValue
}: EditEmailTemplateFormProps) {
  const { hasFeature } = useFeatureFlags();

  return (
    <BasicEditEmailForm contextType={contextType} setFieldValue={setFieldValue}>
      {hasFeature(FLAGS.ATTACH_FILES_FROM_A_RECORD) &&
      SUPPORTED_RECORD_TYPES_FOR_DOCUMENT_TYPES.find(
        (type) => type === contextType
      ) ? (
        <AttachFilesFromRecord recordType={contextType} />
      ) : null}
      {hasFeature(FLAGS.ATTACH_FILES_FROM_A_RECORD) ? (
        <AttachStaticFiles label='Upload files' />
      ) : (
        <UploadAttachments />
      )}

      <TemplatedAttachments templatedAttachments={templatedAttachments} />
    </BasicEditEmailForm>
  );
}
