import * as React from 'react';
import { GhostIconButton } from '@rexlabs/button';
import { icon, StylesProvider } from '@rexlabs/styling';
import Icons from '@rexlabs/icons';

export interface DeleteActionButtonProps {
  canDelete: boolean;
  onClick: () => Promise<void>;
}

export function DeleteActionButton({
  onClick,
  canDelete
}: DeleteActionButtonProps) {
  const deleteOverrides = {
    button: {
      ...icon.tokens({
        color: ({ token }) =>
          canDelete ? token('palette.red.900') : token('palette.grey.500')
      })
    }
  };

  return (
    <StylesProvider tokens={deleteOverrides}>
      <GhostIconButton
        isDisabled={!canDelete}
        Icon={Icons.DeleteCross}
        size='xs'
        onClick={onClick}
      />
    </StylesProvider>
  );
}
