import React from 'react';
import { uniqBy } from 'lodash';

import { NormalisedItem, Select, SelectProps } from '@rexlabs/select';

import { useRecordsQuery } from 'src/lib/react-query/hooks/use-records-query';
import { useValueListQuery } from 'src/lib/react-query/hooks/use-value-list-query';
import { CommunicationContextType } from 'src/modules/communications/common/types/communication-context-type';

import { ValueListItem } from 'utils/normaliser/value-list-item';

export const normaliser = (
  item: ValueListItem & { type?: string }
): NormalisedItem => {
  return {
    id: item?.id,
    label: item?.label,
    type: item.type,
    heading: item.type === 'suggested' ? 'Suggested' : 'All'
  };
};

type DocumentTypeSelectProps = SelectProps<any> & {
  recordType?: CommunicationContextType;
};

export const DocumentTypeSelect: React.FC<DocumentTypeSelectProps> = ({
  recordType,
  ...rest
}) => {
  const { data: suggestedItems = [] } = useRecordsQuery<ValueListItem>(
    [`documents/suggestedTypes/${recordType ? recordType : ''}`, {}],
    {
      enabled: !!recordType,
      staleTime: Infinity,
      cacheTime: Infinity,
      retry: 0
    }
  );

  const { data: documentTypes = [] } = useValueListQuery('document_type');

  const items = uniqBy(
    [
      ...(suggestedItems || [])?.map(({ id, label }) => ({
        id,
        label,
        type: 'suggested'
      })),
      ...(documentTypes || [])?.map(({ id, label }) => ({
        id,
        label,
        type: suggestedItems?.length > 0 ? 'all' : undefined
      }))
    ],
    'id'
  );

  return <Select normaliser={normaliser} items={items} {...rest} />;
};
