import { Message } from '@rexlabs/notifications';
import React from 'react';

import EmptyBatchReceipting from 'src/assets/illustrations/empty-batch-receipting-list.svg';

import { Card } from 'view/components/@luna/card';

export function RemovedTabEmptyState() {
  return (
    <Card>
      <Message title='Removed transactions' Illustration={EmptyBatchReceipting}>
        Transactions that have been removed will be displayed here.
      </Message>
    </Card>
  );
}
