import React, { useMemo } from 'react';

import {
  TitleBlock,
  TitleBlockProps
} from 'view/components/cards/title/title-block';

import { RecordObject, RecordType } from 'data/models/types';
import { Tenancy } from 'data/models/entities/tenancies';
import TenancyIcon from 'view/components/icons/tenancy';
import { formatCurrency } from 'utils/formatters';

import getPendingTrustJournalEntryStatusColor from 'src/modules/trust-journal-entries/utils/pending-trust-journal-entry-status-color';
import { getRecordTitle } from 'utils/records/get-record-title';
import { useTranslation } from 'react-i18next';
import { useGetTenancyActions } from '../actions/use-get-tenancy-actions';

const type: RecordType = 'tenancy';

type TenancyTitleBlockProps = {
  tenancy: Tenancy;
};

export function TenancyTitleBlock({ tenancy }: TenancyTitleBlockProps) {
  const { t } = useTranslation();
  const { record_reference, tenants, property_tenancies } = tenancy;

  const getTenancyActions = useGetTenancyActions();

  const tags = useMemo(() => {
    const tags: TitleBlockProps['tags'] = [];

    const hasActive = property_tenancies?.data.some(
      (propertyTenancy) => propertyTenancy.status.id === 'active'
    );

    const hasIncoming = property_tenancies?.data.some(
      (propertyTenancy) => propertyTenancy.status.id === 'incoming'
    );

    if (hasActive) {
      tags.push({
        type: 'good',
        label: t('lease-agreements.status.active.singular.label')
      });
    } else if (hasIncoming) {
      tags.push({
        type: 'average',
        label: t('lease-agreements.status.incoming.singular.label')
      });
    }

    return tags;
  }, [tenants]);

  const title = getRecordTitle({ type, object: tenancy } as RecordObject);

  const actions = useMemo(() => getTenancyActions(tenancy), [tenancy]);
  const financialSummary = tenancy.financial_summary;
  const trust_summary = tenancy.trust_summary!;

  const stats = useMemo<TitleBlockProps['stats']>(() => {
    if (!financialSummary) return [];

    return [
      {
        label: 'Bills & fees payable',
        value: formatCurrency(
          financialSummary.bills_and_fees_payable_total_amount_owing
        ),
        intent: getPendingTrustJournalEntryStatusColor(
          financialSummary.bills_and_fees_payable_count
        )
      },
      {
        label: 'Invoices receivable',
        value: formatCurrency(
          financialSummary.invoices_receivable_total_amount_owing
        ),
        intent: getPendingTrustJournalEntryStatusColor(
          financialSummary.invoices_receivable_count
        )
      },
      {
        label: 'Total security deposits held',
        value: formatCurrency(0),
        intent: 'secondary',
        workInProgress: true
      },
      {
        label: t('financials.trust.available-trust-balance.label.singular'),
        value: formatCurrency(trust_summary.available_balance_amount),
        intent: 'secondary'
      }
    ];
  }, [
    financialSummary?.bills_and_fees_payable_count,
    financialSummary?.bills_and_fees_payable_total_amount_owing,
    financialSummary?.invoices_receivable_count,
    financialSummary?.invoices_receivable_total_amount_owing,
    trust_summary?.available_balance_amount
  ]);

  return (
    <TitleBlock
      title={title}
      type={type}
      tags={tags}
      reference={record_reference}
      actions={actions}
      Icon={TenancyIcon}
      stats={stats}
    />
  );
}
