import React from 'react';

import { Card } from 'view/components/card';
import { ListScreen } from 'view/components/list-screen/list-screen';
import ROUTES from 'routes/app';
import { AgencyFeesTabbedTable } from 'src/modules/agency-fees/components/agency-fees-tabbed-table';

// TODO: delete this file when service_packages flag is turned on

export function AgencyFeesListScreen() {
  return (
    <ListScreen
      title='Agency fees'
      createLabel='Create fee template'
      createLinkProps={{ to: ROUTES.AGENCY_FEES_LIST.CREATE }}
    >
      <Card title='Fee templates'>
        <AgencyFeesTabbedTable />
      </Card>
    </ListScreen>
  );
}
