import React from 'react';

import { Message } from '@rexlabs/notifications';

import EmptyTable from 'assets/illustrations/empty-table.svg';

import { EmptyCard } from 'view/components/record-screen/cards/empty-card';

import { QuoteTask } from 'src/modules/tasks/quotes/types/QuoteTask';

import { useGetAddReceivedQuoteAction } from '../hooks/action-declarations/use-get-add-received-quote-action';

export function ReceivedQuoteEmptyState({
  quoteTask
}: {
  quoteTask: QuoteTask;
}) {
  const getAddReceivedQuoteAction = useGetAddReceivedQuoteAction();

  const { handleAction, intent, label } = getAddReceivedQuoteAction(quoteTask);

  return (
    <EmptyCard>
      <Message
        title='Received quote'
        Illustration={EmptyTable}
        actions={[
          {
            type: intent as any,
            label,
            handleAction
          }
        ]}
      >
        Add details for the received quote
      </Message>
    </EmptyCard>
  );
}
