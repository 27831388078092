import { trustLedgerModel } from 'src/modules/trust-ledgers/models/trust-ledger-model';
import { query } from '@rexlabs/model-generator';
import { toQuri } from '@rexlabs/quri';
import { trustJournalEntryLineItemModel } from 'src/modules/trust-journal-entry-line-items/models/trust-journal-entry-line-item-model';

export const getTrustLedgerQuery = (trustLedgerId: string) => query`{
    ${trustLedgerModel} (id: ${trustLedgerId}) {
      id
      name
      sub_name
      is_default
      object {
        id
        label
        type
      }
      trust_account {
        id
        name
      }
      bank_account
    }
  }`;

export const getTrustLedgerEntriesListQuery = (trustLedgerId: string) => {
  const filter = toQuri([
    {
      field: 'trust_ledger_id',
      op: '==',
      value: trustLedgerId
    }
  ]);
  return query`{
            ${trustJournalEntryLineItemModel} (q: ${filter}) {
              id
              description
              amount
              trust_journal_entry
            }
          }`;
};
