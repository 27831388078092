import { StatBlockIntent } from 'view/components/@luna/stat-block/stat-block';

export default function getPendingTrustJournalEntryStatusColor(
  pendingTrustJournalEntryCount = 0
): StatBlockIntent {
  if (pendingTrustJournalEntryCount === 0) {
    return 'success';
  }

  if (pendingTrustJournalEntryCount < 5) {
    return 'warning';
  }

  return 'danger';
}
