import React, { PropsWithChildren } from 'react';
import { StyleSheet, useStyles, margin } from '@rexlabs/styling';

const defaultStyles = StyleSheet({
  container: {
    ...margin.styles({
      top: 'l'
    }),
    height: ({ token }) => token('textInput.height', '4rem'),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
});

export function CenterToInputLine({ children }: PropsWithChildren<unknown>) {
  const s = useStyles(defaultStyles, 'CenterToInputLine');

  return <div {...s('container')}>{children}</div>;
}
