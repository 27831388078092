import { snakeCase } from 'lodash';

import { Ownership } from 'data/models/entities/ownerships';

import { Contact } from 'src/modules/contacts/types/contact-types';

import { PayoutAmountByContact } from '../types/PayoutAmountByContact';
import { getDisbursementObjectContacts } from './get-disbursement-object-contacts';

export function getPayoutAmountToOwnersSummaryItem(
  description: string,
  amount: number,
  data: PayoutAmountByContact[],
  object?: Contact | Ownership
) {
  const contacts = getDisbursementObjectContacts(object);

  const nestedData = (contacts || []).map((contact) => ({
    description: contact.display_name,
    amount:
      data.find((payout) => payout.contact_id === contact.id)?.amount || 0,
    id: `${snakeCase(description)}.${contact.id}`
  }));

  return {
    description:
      nestedData.length === 1
        ? description + ` for ${nestedData[0].description}`
        : description + ' to owners total',
    amount,
    id: snakeCase(description),
    nested_data: nestedData.length > 1 ? nestedData : []
  };
}
