import { Property } from 'src/modules/properties/types/property-types';
import { Portfolio } from '../../models/portfolios-model';
import { useGetRemovePropertyFromPortfolioAction } from '../action-declarations/use-get-remove-property-from-portfolio-action';

export function useGetPropertyActions({ portfolio }: { portfolio: Portfolio }) {
  const getDeletePropertyFromPortfolio = useGetRemovePropertyFromPortfolioAction(
    {
      portfolio
    }
  );

  return (property?: Property) => {
    if (!property) return [];

    return [getDeletePropertyFromPortfolio(property)];
  };
}
