export const QUOTE_ROUTES = {
  QUOTE_LIST: {
    config: {
      path: '/quotes',
      Component: require('./screens/quote-list.tsx').QuoteList
    }
  },
  QUOTE_DETAILS: {
    config: {
      path: '/quote/:quoteId',
      Component: require('./screens/quote-details.tsx').QuoteDetails
    }
  }
};
