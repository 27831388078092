import { ActionDeclaration } from 'src/modules/common/actions/types/action-declaration-types';
import { useDialog } from '@rexlabs/dialog';
import { TrustLedger } from 'src/modules/trust-ledgers/types/trust-ledger-types';
import { EditTrustLedgerDialog } from '../../dialogs/edit-trust-ledger';

export function useGetEditAction() {
  const editTrustLedgerDialog = useDialog(EditTrustLedgerDialog);

  return (trustLedger: TrustLedger): ActionDeclaration => ({
    id: 'edit',
    group: 'trust-ledger',
    label: 'Edit',
    handleAction: () => editTrustLedgerDialog.open({ trustLedger })
  });
}
