import React from 'react';

import { Field } from '@rexlabs/form';
import { TextArea } from '@rexlabs/text-input';

import { Grid } from 'view/components/@luna/form/grid';
import { Value } from 'view/components/values';
import { BlockConfig } from 'view/components/record-screen/types';
import { ImagePreview } from 'view/components/image-preview';
import { PreviewFileUploadInputWithErrorHandling } from 'view/components/inputs/file-upload-input/preview-file-upload-input-with-error-handling';

import { InspectionTask } from '../types/InspectionTask';
import { EntryExitInspectionFormData } from '../entry-exit/types/EntryExitInspectionFormData';

export const inspectionReportCoverDetailsBlock: BlockConfig<
  InspectionTask,
  any,
  EntryExitInspectionFormData
> = {
  id: `inspection-report-cover-details`,
  title: 'Inspection report overview',
  isMobileForcedCollapsed: true,
  View: ({ data }) => {
    return (
      <Grid columns={1}>
        <Value label='Summary' value={data?.details?.cover_summary} />
        <Value
          label='Cover image'
          value={
            data?.details?.cover_image?.file?.url ? (
              <ImagePreview
                src={data.details.cover_image.file.url}
                downloadUrl={
                  data?.details?.cover_image?.file?.download_url || ''
                }
              />
            ) : (
              '--'
            )
          }
        />
      </Grid>
    );
  },
  Edit: () => {
    return (
      <Grid columns={1}>
        <Field
          id='cover_summary'
          name='cover_summary'
          label='Summary'
          Input={TextArea}
          optional={true}
          description='Will be displayed on the report cover page'
        />
        <Field
          id='cover_image'
          name='cover_image'
          label='Cover image'
          Input={PreviewFileUploadInputWithErrorHandling}
          optional={true}
          inputProps={{
            accept: '.jpeg, .png, .jpg'
          }}
        />
      </Grid>
    );
  }
};
