import { Invoice } from 'data/models/entities/financials/invoices';
import { ReceiptingFundsLineItemEntry } from '../blocks/trust-journal-entry-line-items';

export function useAllocateFunds({
  amount,
  lineItems,
  setFieldValue,
  invoice
}: {
  amount: number;
  lineItems: ReceiptingFundsLineItemEntry[] | undefined;
  setFieldValue?: (fieldName: string, value: any) => void;
  invoice?: Invoice;
}) {
  const allocatedAmount =
    lineItems?.reduce((amount, lineItem) => {
      return amount + (lineItem.amount ?? 0);
    }, 0) ?? 0;
  const unallocatedAmount = amount - allocatedAmount;

  const autoAllocateFunds = () => {
    if (!lineItems) return;
    if (!lineItems.length) return;

    // if we are transferring funds from an invoice and one of
    // the line items is for that invoice then allocate funds
    // to the invoice, otherwise use default auto allocation
    if (invoice) {
      const matchingLineItemIndex = lineItems.findIndex(
        (lineItem) =>
          lineItem.category === 'invoice' &&
          lineItem.invoice_payment?.invoice?.id === invoice.id
      );

      if (matchingLineItemIndex !== -1) {
        return setFieldValue?.(
          `line_items.${matchingLineItemIndex}.amount`,
          invoice.amount_owing
        );
      }
    }

    let unallocatedAmount = amount;

    const allocateToOtherLineItems = () => {
      return lineItems.forEach((lineItem, index) => {
        if (lineItem.category === 'rent') return;

        let allocatedAmount = 0;

        if (
          lineItem.category === 'invoice' &&
          lineItem?.invoice_payment?.invoice
        ) {
          allocatedAmount = Math.min(
            lineItem?.invoice_payment?.invoice?.amount_owing ?? 0,
            unallocatedAmount
          );
        }

        if (lineItem.category === 'deposit') {
          allocatedAmount = unallocatedAmount;
        }

        unallocatedAmount -= allocatedAmount;
        const fieldName = `line_items.${index}.amount`;

        return setFieldValue?.(fieldName, allocatedAmount);
      });
    };

    const allocateToRentLineItems = () => {
      return lineItems.forEach((lineItem, index) => {
        let allocatedAmount = 0;

        if (lineItem.category !== 'rent') return;

        if (lineItem.category === 'rent') {
          allocatedAmount = unallocatedAmount;
        }

        unallocatedAmount -= allocatedAmount;
        const fieldName = `line_items.${index}.amount`;

        setFieldValue?.(fieldName, allocatedAmount);
      });
    };

    // NOTE: This is to ensure the invoices are allocated first, as you can just dump all of the
    // funds into the rent line item, so if they're done first the invoices won't be allocated.
    allocateToOtherLineItems();
    allocateToRentLineItems();
  };

  const allocateToLineItem = ({
    lineItemName,
    invoiceAmountOwing = 0,
    allocateRemaining
  }: {
    lineItemName: string;
    invoiceAmountOwing?: number;
    allocateRemaining?: boolean;
  }) => {
    const amountToAllocate = allocateRemaining
      ? unallocatedAmount
      : Math.min(invoiceAmountOwing, unallocatedAmount);
    const fieldName = `${lineItemName}.amount`;

    setFieldValue?.(fieldName, amountToAllocate);
  };

  const unAllocateFromLineItem = (lineItemName: string) => {
    const fieldName = `${lineItemName}.amount`;

    setFieldValue?.(fieldName, 0);
  };

  const showUnallocateFunds = ({
    allocatedToInvoiceAmount
  }: {
    allocatedToInvoiceAmount: number;
  }) => {
    if (!allocatedToInvoiceAmount) return false;

    return true;
  };

  const showAllocateFunds = ({
    invoiceAmount,
    allocatedToInvoiceAmount
  }: {
    invoiceAmount: number;
    allocatedToInvoiceAmount: number;
  }) => {
    if (!unallocatedAmount) return false;
    if (invoiceAmount === allocatedToInvoiceAmount) return false;
    if (allocatedToInvoiceAmount > 0) return false;

    return true;
  };

  return {
    allocatedAmount,
    unallocatedAmount,
    autoAllocateFunds,
    allocateToLineItem,
    unAllocateFromLineItem,
    showUnallocateFunds,
    showAllocateFunds
  };
}
