import { useStatusChangeAction } from 'src/modules/common/actions/status/use-status-change-action';
import { SingleActionDeclaration } from 'src/modules/common/actions/types/action-declaration-types';
import { tasksModel } from 'src/modules/tasks/common/models/tasks-model';
import { getStatusChangeTransformPayload } from 'src/modules/tasks/common/utils/get-status-change-transform-payload';
import { ContinueToIssueWorkOrderDialog } from 'src/modules/tasks/work-orders/dialogs/continue-to-issue-work-order-dialog';
import { useDialog } from '@rexlabs/dialog';
import { QuoteTask } from '../../types/QuoteTask';

interface Props {
  afterAction?: (data: QuoteTask) => void;
}

export function useGetApproveQuoteAction({ afterAction }: Props = {}) {
  const getStatusChangeAction = useStatusChangeAction(tasksModel);
  const { open } = useDialog(ContinueToIssueWorkOrderDialog);

  return (quoteTask: QuoteTask) => {
    const statusChangeTransformPayload = getStatusChangeTransformPayload(
      quoteTask
    );
    return {
      ...getStatusChangeAction({
        record: quoteTask,
        status: 'approved',
        dialogOptions: {
          label: 'Approve quote',
          type: 'none',
          afterAction: () => {
            afterAction?.(quoteTask);
            open({ quote: quoteTask });
          }
        },
        transformPayload: statusChangeTransformPayload
      }),
      intent: 'primary',
      group: 'status'
    } as SingleActionDeclaration;
  };
}
