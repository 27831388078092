import { useMemo } from 'react';
import { useModelActions } from '@rexlabs/model-generator';

import { getInvoicesPayableByBlock } from 'src/modules/invoices/blocks/invoices-payable-by';
import {
  sharedIncludes,
  taskIncludes,
  tasksModel
} from 'src/modules/tasks/common/models/tasks-model';
import { getCreateInvoiceInitialValues } from '../utils/get-create-invoice-initial-values';
import { MaintenanceTask } from '../types/MaintenanceTask';
import { getInvoicePayableBySuggestedItems } from '../../common/utils/get-invoice-payable-by-suggested-items';

export const usePayableByInvoicesBlock = () => {
  const { refreshItem } = useModelActions(tasksModel);

  return useMemo(
    () =>
      getInvoicesPayableByBlock<MaintenanceTask>(({ data }) => {
        return {
          hashParamKey: 'payable_by',
          getForcedGlobalFilter: () => [
            {
              field: 'task_id',
              op: '==',
              value: data?.id
            }
          ],
          refreshRecord: async () =>
            refreshItem({
              id: data?.id,
              args: {
                includes: [
                  taskIncludes?.maintenanceDetails,
                  ...sharedIncludes,
                  'property.active_property_ownership.ownership.owners.contact'
                ].join(',')
              }
            }),
          initialValues: getCreateInvoiceInitialValues(data),
          emptyMessage: 'Add a bill for this maintenance job',
          showEmpty: (data) => !data?.details?.invoices?.data?.length,
          suggestions: {
            payableBy: getInvoicePayableBySuggestedItems({
              property: data?.property
            })
          }
        };
      }),
    []
  );
};
