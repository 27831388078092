import React from 'react';
import { ActionButtons } from 'view/components/@luna/action-buttons';
import { BlockConfig } from 'view/components/record-screen/types';
import { useMessageTableEmptyState } from 'src/modules/communications/messages/components/message-table-empty-state';
import { MessagesTabbedTable } from 'src/modules/communications/messages/components/messages-tabbed-table';
import { useGetCreateEmailAction } from 'src/modules/communications/messages/hooks/action-declarations/use-get-create-email-action';
import { useGetCreateSMSAction } from 'src/modules/communications/messages/hooks/action-declarations/use-get-create-sms-action';
import { GlobalFilter } from '@rexlabs/table/lib/types/context';
import { Task } from 'src/modules/tasks/common/types/Task';
import { tasksModel } from 'src/modules/tasks/common/models/tasks-model';
import { TaskType } from 'src/modules/tasks/common/types/TaskType';

export const maintenanceMessageTableBlock: BlockConfig<
  Task<'task_maintenance'>
> = {
  id: 'messages',
  title: 'Messages',
  View: ({ data }) => {
    const MessageTableEmptyState = useMessageTableEmptyState(tasksModel);

    return (
      <MessagesTabbedTable
        forcedGlobalFilters={
          [
            {
              field: 'context_record_id',
              op: 'in',
              value: [
                data?.id,
                ...(data?.child_tasks?.data?.map(
                  (child: Task<TaskType>) => child.id
                ) || [])
              ]
            },
            {
              field: 'context_record_type_id',
              op: 'eq',
              value: data?.__record_type
            }
          ] as GlobalFilter[]
        }
        messageHiddenColumns={['context', 'message_format']}
        channelMessageRecipientHiddenColumns={[
          'message_relates_to',
          'message_format'
        ]}
        TabEmptyState={({ label }) => (
          <MessageTableEmptyState label={label} record={data} />
        )}
      />
    );
  },
  Actions: ({ data }) => {
    const getCreateEmailAction = useGetCreateEmailAction(tasksModel);
    const getCreateSMSAction = useGetCreateSMSAction(tasksModel);

    if (!data) {
      return null;
    }

    return (
      <ActionButtons
        actions={[
          { ...getCreateSMSAction(data), intent: 'secondary' },
          { ...getCreateEmailAction(data), intent: 'secondary' }
        ]}
      />
    );
  }
};
