import React from 'react';

import { useConfirmationDialog, useDialog } from '@rexlabs/dialog';
import { Body } from '@rexlabs/text';
import { push } from '@rexlabs/whereabouts';
import { toQuri } from '@rexlabs/quri';

import ROUTES from 'routes/app';

import { api } from 'utils/api/api-client';

import WarningCircleIcon from 'view/components/icons/warning-circle';

import { InspectionTask } from 'src/modules/tasks/inspections/types/InspectionTask';

import { EditLocationDialog } from '../../dialogs/edit-location-dialog';
import { Property } from '../../types/property-types';
import { useGetUpdatePropertyLocationSubmitHandler } from '../../hooks/use-get-update-property-location-submit-handler';

export type GetEditLocationProps = {
  property: Property;
  /**
   * Sometimes we don't want to show the confirmation dialog, for example when we're updating from
   * the inspection run record, as we don't want to redirect to the run we're already looking at.
   */
  shouldCheckForInspectionRun?: boolean;
  afterAction?: (property?: Property) => any;
};

export function useGetEditLocationAction() {
  const { open: openEditLocationDialog } = useDialog(EditLocationDialog);
  const { open: openConfirmationDialog } = useConfirmationDialog();
  const getUpdatePropertyLocationSubmitHandler = useGetUpdatePropertyLocationSubmitHandler();

  return ({
    property,
    shouldCheckForInspectionRun,
    afterAction
  }: GetEditLocationProps) => {
    return {
      id: 'edit-location-in-map',
      group: 'edit',
      label: 'Edit location in map',
      handleAction: async () => {
        const inspectionRunId = !shouldCheckForInspectionRun
          ? undefined
          : await fetchInspectionRunIdByPropertyId(property.id);

        const handleSubmit = getUpdatePropertyLocationSubmitHandler(
          property,
          afterAction
        );
        return inspectionRunId
          ? openConfirmationDialog({
              TitleIcon: WarningCircleIcon,
              title: 'Inspection is a part of an inspection run',
              message: (
                <>
                  <Body>
                    Editing the property location will affect the travel time of
                    subsequent inspections.
                  </Body>
                  <Body>
                    Please proceed to the inspection run record to edit the
                    property location.
                  </Body>
                </>
              ),
              confirmText: 'View inspection run',
              onConfirm: async () => {
                return push(ROUTES.INSPECTION_RUN_DETAILS, {
                  params: {
                    inspectionRunId
                  }
                });
              }
            })
          : openEditLocationDialog({
              property,
              handleSubmit
            });
      }
    };
  };
}

async function fetchInspectionRunIdByPropertyId(propertyId: string) {
  const queryParams = new URLSearchParams();

  queryParams.set(
    'q',
    toQuri([
      {
        field: 'property_id',
        op: 'eq',
        value: propertyId
      },
      {
        field: 'type',
        op: 'eq',
        value: 'task_inspection'
      },
      { field: 'closed_at', op: 'eq', value: 'null' },
      {
        field: 'status',
        op: 'neq',
        value: 'completed'
      }
    ])
  );
  queryParams.set('include', 'details');

  const { data } = await api.get<InspectionTask[]>(`/tasks`, queryParams);

  return data.find((task) => task.details?.inspection_run_id)?.details
    ?.inspection_run_id;
}
