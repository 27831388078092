import { PropertyCategoryRequirement } from 'data/models/entities/settings/property-category-requirements';
import { SupplierCategoryRequirement } from 'data/models/entities/settings/supplier-category-requirements';

export function useRequirementsForCategory({
  categoryRequirements
}: {
  categoryRequirements?:
    | PropertyCategoryRequirement[]
    | SupplierCategoryRequirement[];
}) {
  return {
    requirementsList: categoryRequirements?.map((item) => item?.compliance_type)
  };
}
