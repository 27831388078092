import { useQuery } from 'react-query';
import { api } from 'utils/api/api-client';
import { ServicePackage } from 'src/modules/service-packages/types/service-package';
import { toQuri } from '@rexlabs/quri';

async function getItems(ids: string[]) {
  const { data } = await api.get('/service-packages', {
    q: toQuri([{ field: 'id', op: 'in', value: ids }])
  });

  return data as ServicePackage[];
}

export function useGetServicePackages(ids: string[]) {
  const seconds = 60;
  return useQuery(['service-packages', { ids: ids }], () => getItems(ids), {
    staleTime: seconds * 1000,
    enabled: ids.length > 0
  });
}
