import { DialogProps } from '@rexlabs/dialog';
import { useModelActions } from '@rexlabs/model-generator';
import React, { useCallback } from 'react';

import { Ownership } from 'data/models/entities/ownerships';
import { useRecordCreatedToast } from 'src/modules/common/toasts/hooks/use-record-created-toast';
import { Contact } from 'src/modules/contacts/types/contact-types';
import { getToday } from 'utils/dates/dates';
import { CreateRecordDialog } from 'view/components/dialogs/create-record-dialog/create-record-dialog';
import { RecordSubmitHandler } from 'view/components/record-screen/utils';
import { createManualAccountingJournalEntryBlock } from '../blocks/create-manual-accounting-journal-entry';
import { mapManualAccountingJournalEntryFormDataToCreateRequest } from '../mappers/map-manual-accounting-journal-entry-form-data-to-create-request';
import { CreateManualAccountingJournalEntryFormData } from '../mappers/types/create-manual-accounting-journal-entry-form-data';
import { accountingJournalEntryLineItemModel } from '../model/accounting-journal-entry-line-item-model';
import { accountingJournalEntryModel } from '../model/accounting-journal-entry-model';

const content = [
  {
    id: 'create-manual-accounting-journal-entry',
    label: 'Details',
    blocks: [createManualAccountingJournalEntryBlock]
  }
];

interface CreateManualAccountingJournalEntryDialogProps extends DialogProps {
  folio: Ownership | Contact;
}

export function CreateManualAccountingJournalEntryDialog({
  folio,
  ...dialogProps
}: CreateManualAccountingJournalEntryDialogProps) {
  const {
    createItem,
    refreshLists: refreshAccountingJournalEntryLists
  } = useModelActions(accountingJournalEntryModel);
  const {
    refreshLists: refreshAccountingJournalEntryLineItemLists
  } = useModelActions(accountingJournalEntryLineItemModel);

  const addToast = useRecordCreatedToast(accountingJournalEntryModel);

  const handleRecordCreate: RecordSubmitHandler<CreateManualAccountingJournalEntryFormData> = useCallback(
    async ({ values }) => {
      const dataToSubmit = await mapManualAccountingJournalEntryFormDataToCreateRequest(
        values
      );

      const { data: journalEntry } = await createItem({
        data: dataToSubmit
      });

      addToast(journalEntry);

      await Promise.all([
        (refreshAccountingJournalEntryLists(),
        refreshAccountingJournalEntryLineItemLists())
      ]);

      return journalEntry;
    },
    [
      addToast,
      createItem,
      refreshAccountingJournalEntryLineItemLists,
      refreshAccountingJournalEntryLists
    ]
  );

  return (
    <CreateRecordDialog
      {...dialogProps}
      recordType='accounting_journal_entry'
      content={content}
      onSubmit={handleRecordCreate}
      initialValues={{ folio, date_of: getToday(), is_tax_included: true }}
    />
  );
}
