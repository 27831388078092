import React, { useCallback } from 'react';
import dayjs from 'dayjs';

import { Field } from '@rexlabs/form';
import { TextArea } from '@rexlabs/text-input';

import { Grid } from 'view/components/@luna/form/grid';
import { BlockConfig } from 'view/components/record-screen/types';
import { Body } from '@rexlabs/text';
import {
  DateInput,
  disableFutureDates
} from 'view/components/@luna/inputs/date-input/date-input';
import { EntitySelect } from 'view/components/inputs/selects/v4-selects/entity-select';
import { KeySet } from '../data/key-set-model';

export type CheckOutKeySetFormValues = Pick<
  KeySet,
  | 'id'
  | 'checked_out_date'
  | 'return_by_date'
  | 'status_reason'
  | 'name'
  | 'object'
>;

export const checkOutKeySetBlock: BlockConfig<
  KeySet,
  any,
  CheckOutKeySetFormValues
> = {
  id: 'check-out-key-set-block',
  Edit: ({ values }) => {
    const returnByDateIsOutsideRange = useCallback(
      (momentDate: moment.Moment) => {
        const checkedOutDate = dayjs(values?.checked_out_date);

        return (
          dayjs(momentDate.toDate()).isBefore(checkedOutDate) ||
          dayjs(momentDate.toDate()).isBefore(dayjs().add(1, 'day'))
        );
      },
      [values?.checked_out_date]
    );

    return (
      <Grid columns={1}>
        <Body>
          <>
            You are checking out <b>{values?.name} key set.</b>
          </>
        </Body>
        <Grid columns={2}>
          <Field
            id='key-set-holder'
            name='object'
            label='Key set holder'
            optional={false}
            Input={EntitySelect}
            inputProps={{
              objectTypes: ['contact', 'tenancy', 'ownership']
            }}
          />
        </Grid>
        <Grid columns={2}>
          <Field
            name='checked_out_date'
            label='Date checked-out'
            Input={DateInput}
            optional={false}
            inputProps={{ isOutsideRange: disableFutureDates }}
          />
          <Field
            name='return_by_date'
            label='Return by'
            Input={DateInput}
            inputProps={{ isOutsideRange: returnByDateIsOutsideRange }}
          />
        </Grid>

        <Field name='status_reason' label='Notes' Input={TextArea} />
      </Grid>
    );
  },
  validate: {
    definitions: {
      object: { name: 'key set holder', rules: 'required' },
      checked_out_date: {
        name: 'date checked-out',
        rules: 'required|startDateLessThanOrEqualToEndDate:return_by_date'
      }
    },
    messages: {
      startDateLessThanOrEqualToEndDate:
        'The date checked-out must be before the return date'
    }
  }
};
