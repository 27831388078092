import React, { useCallback } from 'react';

import { query } from '@rexlabs/model-generator';

import { Card } from 'view/components/card';
import ROUTES from 'routes/app';
import { Columns, ListTable, TabbedTable } from 'view/components/table';
import { usersModel } from 'src/modules/users/models/users';
import { getRecordLinkProps } from 'view/components/record-link/get-record-link-props';
import { ListScreen } from 'view/components/list-screen/list-screen';
import { User } from 'src/modules/users/types/user-types';
import { useTableFilters } from 'view/hooks/use-table-filters';
import { TagCell } from '@rexlabs/table';
import { StatusTag } from 'src/modules/common/components/status-tag';
import { userStatusMap } from 'src/modules/users/maps/user-status-map';
import { auditableColumns } from 'src/modules/common/auditable/auditable-columns';
import { Tab } from 'view/components/table/tabbed';
import { transformActionDeclarationsToActionMenuItems } from 'utils/actions/transforms';
import { useGetBulkMessageActions } from 'src/modules/communications/messages/hooks/action-declarations/use-get-bulk-message-actions';
import { ActionDeclaration } from 'src/modules/common/actions/types/action-declaration-types';
import { FLAGS } from 'utils/feature-flags';
import { useFeatureFlags } from 'view/components/@luna/feature-flags';
import { useGetUserActions } from '../actions/use-get-user-actions';
import { NameAndEmailCell } from '../components/name-and-email-cell';

const columns: Columns<User> = [
  {
    id: 'name',
    accessor: (item) => item,
    Header: 'Name',
    Cell: NameAndEmailCell,
    toCsv: (item) =>
      `${item?.contact?.display_name} - ${item?.contact?.primary_email?.email_address}` ||
      ''
  },
  {
    id: 'role',
    Header: 'Role',
    accessor: (item) => item.role?.name,
    width: 140
  },
  {
    id: 'status',
    Header: 'Status',
    accessor: (item) => item.status,
    Cell: ({ value }) => {
      return (
        <TagCell>
          <StatusTag status={value} map={userStatusMap} />
        </TagCell>
      );
    },
    width: 140,
    toCsv: (item) => item?.label || ''
  },
  {
    id: 'invited',
    type: 'date',
    accessor: (item) => item.invited_at,
    Header: 'Invited at',
    width: 140
  },
  {
    id: 'accepted',
    type: 'date',
    accessor: (item) => item.accepted_at,
    Header: 'Accepted at',
    width: 140
  },
  ...auditableColumns
];

function getQuery() {
  return {
    ...query`{
    ${usersModel} {
      contact {
        primary_email
      }
      created_by
      updated_by
      role
    }
  }`,
    uuid: 'users'
  };
}

export function UsersList() {
  const getUserActions = useGetUserActions();

  const { getSort, getFilters } = useTableFilters('users');
  const { hasFeature } = useFeatureFlags();

  const getBulkMessageActions = useGetBulkMessageActions(usersModel);

  const getBulkActions = useCallback(
    (args) => {
      const actions: ActionDeclaration[] = [];
      if (hasFeature(FLAGS.BULK_MESSAGES)) {
        actions.push(...getBulkMessageActions(args.selectedItems));
      }
      return actions;
    },
    [getBulkMessageActions, hasFeature]
  );

  const common = {
    columns,
    getQuery,
    getFilters,
    getSort,
    getBulkActions,
    id: 'users-list',
    getRowLinkProps: ({ item }) =>
      getRecordLinkProps({ type: 'user', id: item.id }),
    getActionMenuItems: ({ item }) => [
      ...transformActionDeclarationsToActionMenuItems(getUserActions(item))
    ],
    initialHiddenColumns: [
      'invited',
      'accepted',
      ...auditableColumns.map((c) => c.id)
    ],
    Table: ListTable
  };

  const tabs: Tab[] = [
    {
      ...common,
      name: 'all',
      label: 'All',
      filters: []
    },
    {
      ...common,
      name: 'invited',
      label: 'Invited',
      forcedGlobalFilter: [
        {
          field: 'invited_at',
          op: 'neq',
          value: 'null'
        },
        {
          field: 'accepted_at',
          op: 'eq',
          value: 'null'
        }
      ]
    }
  ];

  return (
    <ListScreen
      title='Users'
      createLabel='Invite user'
      createLinkProps={{ to: ROUTES.USERS_LIST.INVITE_USER }}
    >
      <Card>
        <TabbedTable tabs={tabs} />
      </Card>
    </ListScreen>
  );
}
