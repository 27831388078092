import { useTranslation } from 'react-i18next';
import { PortfolioAssignableTaskType } from '../models/portfolios-model';

export function useTaskAssignmentTypes(): {
  id: PortfolioAssignableTaskType;
  label: string;
}[] {
  const { t } = useTranslation();

  return [
    { id: 'task_arrears', label: 'Rent arrears' },
    { id: 'task_maintenance', label: 'Maintenance' },
    { id: 'task_inspection', label: 'Inspection' },
    { id: 'task_property_compliance', label: 'Property compliance' },
    { id: 'task_move_in', label: t('tasks.move-in.title') },
    { id: 'task_move_out', label: t('tasks.move-out') },
    { id: 'task_lease_review', label: t('tasks.lease-review.label.plural') }
  ];
}
