import { useDialog } from '@rexlabs/dialog';

import { SingleActionDeclaration } from 'src/modules/common/actions/types/action-declaration-types';

import { InspectionTask } from '../../types/InspectionTask';
import { ImportPreviousReportDialog } from '../../dialogs/import-previous-report-dialog';
import { useGetImportReportSubmitHandler } from '../use-get-import-report-submit-handler';

export function useGetImportReportAction(inspectionTask?: InspectionTask) {
  const { open } = useDialog(ImportPreviousReportDialog);

  const getImportReportSubmitHandler = useGetImportReportSubmitHandler(
    inspectionTask
  );

  return (): SingleActionDeclaration => {
    return {
      id: 'import-previous-report',
      intent: 'primary',
      group: 'inspection',
      label: 'Import previous report',
      handleAction: async () => {
        if (!inspectionTask) return;
        open({
          inspectionTask: inspectionTask,
          submitHandler: ({ values }) => getImportReportSubmitHandler(values)
        });
      }
    };
  };
}
