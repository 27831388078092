import * as React from 'react';
import { Text, Truncate } from '@rexlabs/text';
import { Box } from '@rexlabs/box';
import { ProgressBar, ProgressBarProps } from './progress-bar';

export interface ProgressBarFieldProps extends ProgressBarProps {
  label: string;
}

export function ProgressBarField(props: ProgressBarFieldProps) {
  const { label, value = 0, max = 1, testId } = props;

  return (
    <div>
      <Box marginBottom={6}>
        <Truncate>{label}</Truncate>
      </Box>
      <Box flexDirection='row' alignItems='center' gap={12}>
        <ProgressBar
          value={value}
          max={max}
          testId={testId ? `progress-${testId}` : undefined}
        />

        <Box flex='0 0 auto'>
          <Text type='SEMIBOLD' as='span'>
            {value} / {max}
          </Text>
        </Box>
      </Box>
    </div>
  );
}
