export enum ChecklistItemStatus {
  CAPTURES_OUTCOMES = 'captures_outcomes',
  HAS_OUTCOME = 'has_outcome',
  WILL_TRIGGER_AT_TIME = 'will_trigger_at_time',
  WILL_TRIGGER_ON_COMPLETION = 'will_trigger_on_completion',
  WAS_SENT_AT_TIME = 'was_sent_at_time',
  IS_SENDING_NOW = 'is_sending_now',
  ACTION_SKIPPED = 'action_skipped',
  PREVIOUSLY_TRIGGERED = 'previously_triggered', // triggered, but incomplete
  TRIGGERED = 'triggered', // generic triggered, but complete
  DEFAULT = 'default'
}
