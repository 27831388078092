import * as React from 'react';
import { DialogProps, ConfirmationDialog } from '@rexlabs/dialog';
import { Body, Bold } from '@rexlabs/text';
import { useModelActions } from '@rexlabs/model-generator';
import { useToast } from 'view/components/@luna/notifications/toast';
import { push } from '@rexlabs/whereabouts';
import ROUTES from 'routes/app';
import { checklistTemplatesModel } from '../models/checklist-templates-model';
import { ChecklistTemplate } from '../types/ChecklistTemplate';
import { useGetIsSmartChecklist } from '../hooks/use-get-is-smart-checklist';
import { taskTypeTabIdMap } from '../data/content';

export interface DeleteChecklistTemplateDialogProps extends DialogProps {
  checklistTemplate: ChecklistTemplate;
}

export function DeleteChecklistTemplateDialog({
  checklistTemplate,
  onClose
}: DeleteChecklistTemplateDialogProps) {
  const actions = useModelActions(checklistTemplatesModel);
  const { addToast } = useToast();
  const getIsSmartChecklist = useGetIsSmartChecklist();

  const handleConfirm = React.useCallback(async () => {
    await actions.trashItem({ id: checklistTemplate.id });

    addToast({
      description: `${checklistTemplate.label} checklist deleted`,
      type: 'error'
    });

    const taskTypeId = checklistTemplate.task_type.id;
    getIsSmartChecklist(taskTypeId) &&
      push(ROUTES.TASK_SETTINGS_DETAILS, {
        query: { tab: taskTypeTabIdMap[taskTypeId] }
      });

    return true;
  }, [checklistTemplate.id, checklistTemplate.label]);

  return (
    <ConfirmationDialog
      destructive
      onClose={onClose}
      onConfirm={handleConfirm}
      confirmText='Yes, delete this checklist'
      title={`Delete ${checklistTemplate.label} checklist`}
      message={
        <>
          <Body>
            Removing <Bold>{checklistTemplate.label}</Bold> checklist will only
            affect new tasks.
          </Body>
          <Body>Do you want to continue?</Body>
        </>
      }
    />
  );
}
