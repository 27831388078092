import { Grid, Column } from '@rexlabs/grid';
import { CreditNote } from 'data/models/entities/financials/credit-notes';
import React from 'react';
import { RecordLinkValue } from 'view/components/values/record-link';
import { Value } from 'view/components/values';
import { DateValue } from 'view/components/values/date';
import { getCreditNoteTitle } from '../utils/get-credit-note-title';

interface CreditNoteDetails {
  creditNote?: CreditNote;
}

export function CreditNoteDetails({ creditNote }: CreditNoteDetails) {
  const invoice = creditNote?.application?.invoice_payment_entry?.invoice;
  const invoiceRecordReference = invoice?.record_reference;

  const description =
    creditNote &&
    `${getCreditNoteTitle(creditNote)} (${creditNote.description})`;

  return (
    <Grid>
      <Column width={6}>
        <RecordLinkValue
          label='Invoice'
          type='invoice'
          id={invoice?.id}
          value={invoiceRecordReference}
        />
      </Column>
      <Column width={6}>
        <Value label='Description' value={description} />
      </Column>
      <Column width={6}>
        <RecordLinkValue
          label='Issued by'
          type={creditNote?.payable_by.object.type.id}
          id={creditNote?.payable_by.object.id}
          value={creditNote?.payable_by.object.label}
        />
      </Column>
      <Column width={6}>
        <RecordLinkValue
          label='Issued to'
          type={creditNote?.payable_to.object.type.id}
          id={creditNote?.payable_to.object.id}
          value={creditNote?.payable_to.object.label}
        />
      </Column>
      <Column width={6}>
        <DateValue label='Credit Date' value={creditNote?.credit_note_date} />
      </Column>
      <Column width={6}>
        <Value
          label='Impacts rent paid?'
          value={creditNote?.application?.impacts_rent_paid ? 'Yes' : 'No'}
        />
      </Column>
    </Grid>
  );
}
