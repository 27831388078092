import Validator from '@rexlabs/validator';
import { get } from 'lodash';

/**
 * TODO: kill these rules, they error when trying to build (with vite) and are duplicated in the package.
 */

// @ts-ignore
Validator.registerImplicit(
  'required_if_boolean',
  function (value, requirement) {
    const [dependentFieldName, expectedValue] = requirement.split(',');

    //@ts-ignore
    const fieldValue = get(this.validator.input, dependentFieldName);

    if (fieldValue === (expectedValue === 'true')) {
      return !!value;
    }
    return true;
  },
  'The :attribute field is required'
);

// @ts-ignore
Validator.registerImplicit(
  'required_if_true',
  function (value, dependentFieldName) {
    //@ts-ignore
    const fieldValue = get(this.validator.input, dependentFieldName);

    if (fieldValue === true) {
      return !!value;
    }
    return true;
  },
  'The :attribute field is required'
);

// @ts-ignore
Validator.registerImplicit(
  'required_if_false',
  function (value, dependentFieldName) {
    //@ts-ignore
    const fieldValue = get(this.validator.input, dependentFieldName);

    if (fieldValue === false) {
      return !!value;
    }
    return true;
  },
  'The :attribute field is required'
);
