import React from 'react';

import { BatchReceiptingItem } from '../types';
import { useMapBatchReceiptingItems } from '../hooks/use-map-batch-receipting-items';
import { useBankStatementTransactionsQuery } from '../hooks/use-bank-statement-transactions-query';
import { BatchReceiptingTable } from './batch-receipting-table';
import { SuspendedTabEmptyState } from './empty-states/suspended-tab-empty-state';
import { withTableProvider } from './table-provider';

export const SuspendedTab = withTableProvider({
  Component: () => {
    const {
      data,
      isLoading,
      fetchNextPage,
      isFetchingNextPage
    } = useBankStatementTransactionsQuery('SUSPENDED');

    const items: BatchReceiptingItem[] =
      data?.pages.map((page) => page.data).flat() || [];

    const mappedItems = useMapBatchReceiptingItems(items);

    return (
      <BatchReceiptingTable
        isLoading={isLoading}
        items={mappedItems}
        EmptyState={SuspendedTabEmptyState}
        fetchNextPage={fetchNextPage}
        isFetchingNextPage={isFetchingNextPage}
        testId='suspended-card'
      />
    );
  },
  hashParamKey: 'suspended'
});
