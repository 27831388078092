import React from 'react';

import { BooleanSelect, BooleanSelectProps } from './boolean';

export function TaxIncludedSelect(dropdownProps: BooleanSelectProps) {
  return (
    <BooleanSelect
      {...dropdownProps}
      trueLabel='Including'
      falseLabel='Excluding'
    />
  );
}
