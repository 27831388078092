import * as React from 'react';
import { useToast } from 'view/components/@luna/notifications/toast';
import { ComplianceEntry } from 'src/modules/compliance/common/models/compliance-entries';
import dayjs from 'dayjs';
import { formatDate } from 'utils/dates/format';

export function useRenewFromTaskToast() {
  const { addToast } = useToast();
  return (data: ComplianceEntry) => {
    // Format date ensures that the dates are compared based on date rather than date and time -
    // If it expires today, we want to treat it as not yet expired.
    const isExpired = dayjs(data?.expires_at).isBefore(formatDate(dayjs()));
    return addToast({
      description: isExpired ? (
        <>
          The {data?.compliance_type?.category?.label.toLowerCase()} has been
          successfully added
        </>
      ) : (
        <>
          The task has been successfully <strong> completed </strong> and{' '}
          <strong>closed</strong>
        </>
      )
    });
  };
}
