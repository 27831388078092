import { useCallback } from 'react';
import { flatten, isArray } from 'lodash';

import { useErrorDialog } from '@rexlabs/dialog';
import { useModelActions } from '@rexlabs/model-generator';

import {
  postRequestPipelineRequests,
  RequestPipelineMethod,
  RequestPipelineRequestWithId
} from 'utils/api/post-request-pipeline';
import { pluralize } from 'utils/formatters';

import { useToast } from 'view/components/@luna/notifications/toast';
import { RecordSubmitHandler } from 'view/components/record-screen/utils';

import { useRecordCreatedToast } from 'src/modules/common/toasts/hooks/use-record-created-toast';
import { tasksModel } from '../../common/models/tasks-model';
import { mapFormToPayload } from '../mappers/map-form-to-payload';
import { CreateQuoteFormData } from '../types/CreateQuoteFormData';
import { QuoteTask } from '../types/QuoteTask';
import { ChecklistTemplate } from '../../settings/types/ChecklistTemplate';

interface UseAddQuoteSubmitHandlerProps {
  onCreate?: (data) => void;
}

export const useAddQuoteSubmitHandler = ({
  onCreate
}: UseAddQuoteSubmitHandlerProps) => {
  const { refreshLists: refreshQuotes } = useModelActions(tasksModel);

  const { open: openErrorDialog } = useErrorDialog();

  const { addToast } = useToast();
  const addRecordCreatedToast = useRecordCreatedToast('task_quote');

  const onHandleSubmit: RecordSubmitHandler<
    Partial<CreateQuoteFormData>
  > = useCallback(
    async ({ values }) => {
      try {
        const supplierFromValues = values.details!.supplier!;
        const suppliers = isArray(supplierFromValues)
          ? supplierFromValues
          : [supplierFromValues];

        const checklists = values?.checklists || [];

        // TODO: we will need some way to send the individual messages to the suppliers
        const supplierRequests: RequestPipelineRequestWithId[] = await Promise.all(
          suppliers.map(async (supplier) => {
            const { basePayload, detailsPayload } = await mapFormToPayload(
              values
            );
            return {
              id: `supplier-${supplier.id}`,
              method: 'POST',
              path: '/api/v1/tasks?include=parent_task',
              json: {
                ...basePayload,
                details: {
                  ...detailsPayload,
                  supplier
                }
              }
            };
          })
        );

        const checklistRequests: RequestPipelineRequestWithId[] = flatten(
          suppliers.map((supplier) => {
            const quoteChecklists = checklists.map((checklist) => ({
              id: `checklist-${checklist.id}`,
              path: `/api/v1/tasks/checklist-templates/${checklist.id}/instantiate`,
              method: 'POST' as RequestPipelineMethod,
              json: {
                task: {
                  id: `{{$.supplier-${supplier.id}.id}}`
                }
              }
            }));

            return quoteChecklists;
          })
        );

        const { data } = await postRequestPipelineRequests<
          RequestPipelineRequestWithId[],
          QuoteTask | ChecklistTemplate
        >([...supplierRequests, ...checklistRequests]);

        const quoteData = Object.values(data).filter(
          (item) => 'type' in item && item.type.id === 'task_quote'
        ) as QuoteTask[];

        onCreate?.(data);

        quoteData.length === 1
          ? addRecordCreatedToast(quoteData[0])
          : addToast({
              description: `You have successfully added ${pluralize(
                'quote',
                quoteData.length,
                true
              )}`
            });

        refreshQuotes();
        return data;
      } catch (error) {
        console.error(error);
        openErrorDialog(error);
      }
    },
    [addToast, openErrorDialog, refreshQuotes]
  );

  return { onHandleSubmit };
};
