import { NormalisedItem } from '@rexlabs/select';

type GenericRecord = {
  id: string;
};

export function filterNormalisedList<A extends GenericRecord>(
  normalisedItem: NormalisedItem,
  filterItems: A[]
) {
  return filterItems.every((filterItem) => normalisedItem.id !== filterItem.id);
}
