import { useQuery } from 'react-query';
import { api } from 'utils/api/api-client';
import { QuarterlyPayments } from '../types/quarterly-payments';

export function useGetQuarterlySummaryFromFinancialYear(
  financialYear: string
): { data: QuarterlyPayments[]; isLoading: boolean } {
  const { isLoading, data: quarterlySummaryData } = useQuery(
    ['quarterly-summary', financialYear],
    () => api.get(`financials/tax-summaries/${financialYear}/quarterly-summary`)
  );

  return { data: quarterlySummaryData?.data || [], isLoading };
}
