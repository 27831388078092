import {
  CreateStatementArgs,
  UpdateStatementArgs,
  useGenericSetStatementStatus
} from 'src/modules/statements/hooks/use-generic-set-statement-status';
import { invokeActionDeclaration } from 'src/modules/common/actions/utils/invoke-action-declaration';
import {
  ExcludeEofyStatementDialog,
  StatementDisplayData
} from 'src/modules/statements/dialogs/exclude-eofy-statement-dialog';
import { useDialog } from '@rexlabs/dialog';
import { useModelActions } from '@rexlabs/model-generator';
import { ownershipsModel } from 'data/models/entities/ownerships';
import { RecordTypes } from 'data/models/types';
import { StatementStatus } from 'src/modules/statements/types';

type ExcludeStatementArgs =
  | Omit<CreateStatementArgs, 'status'>
  | Omit<UpdateStatementArgs, 'status'>;

export function useConfirmExcludeEofyStatementAction() {
  const getSetStatementAction = useGenericSetStatementStatus();
  const { open: openExcludeStatementDialog } = useDialog(
    ExcludeEofyStatementDialog
  );
  const { updateItem } = useModelActions(ownershipsModel);

  return (args: ExcludeStatementArgs) => ({
    label: 'Exclude',
    handleAction: () => {
      const ownership =
        args.recordType === RecordTypes.Ownership
          ? args.ownership
          : args.statement.object;

      const statementDisplayData: StatementDisplayData =
        args.recordType === RecordTypes.Ownership
          ? {
              startDate: args.statementStartDate,
              endDate: args.statementEndDate,
              ownershipName: ownership.display_name
            }
          : {
              startDate: args.statement.statement_from,
              endDate: args.statement.statement_to,
              ownershipName: ownership.display_name
            };

      return openExcludeStatementDialog({
        statementData: statementDisplayData,
        onSubmit: async ({ reason, should_exclude_on_ownership }) => {
          await invokeActionDeclaration(getSetStatementAction, {
            ...args,
            status: StatementStatus.Excluded,
            label: 'Exclude statement', // unused but required
            status_reason: reason
          });
          if (should_exclude_on_ownership) {
            await updateItem({
              id: ownership.id,
              data: {
                eofy_statement_enabled: false
              }
            });
          }
        }
      });
    }
  });
}
