import React, { PropsWithChildren, CSSProperties } from 'react';

import Box from '@rexlabs/box';
import { useStyles, StyleSheet } from '@rexlabs/styling';

const defaultStyles = StyleSheet({
  container: {
    height: '5.6rem',
    transform: 'translateY(-1.4rem)'
  }
});

type ActionButtonContainerProps = PropsWithChildren<{
  style?: CSSProperties;
  className?: string;
}>;

export function ActionButtonContainer({
  style,
  className,
  ...props
}: ActionButtonContainerProps) {
  const s = useStyles(defaultStyles);
  return (
    <Box
      {...props}
      {...s.with('container')({ style, className })}
      alignItems='center'
      justifyContent='center'
    />
  );
}
