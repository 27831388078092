import React, { ComponentType, useCallback } from 'react';

import { RecordDialog } from 'view/components/record-screen/dialog/dialog';
import { DialogContentConfig } from 'view/components/record-screen/dialog/types';

import { RecordSubmitHandler } from 'view/components/record-screen/utils';
import { CapturedReason, captureReasonBlock } from './capture-reason-block';

const content: DialogContentConfig = [
  {
    id: 'basics',
    label: 'Basics',
    blocks: [captureReasonBlock]
  }
];

export interface CaptureReasonDialogProps {
  onClose?: () => void;
  onSubmit: (capturedReason: CapturedReason) => Promise<void | any>;
  title?: string;
  submitLabel?: React.ReactNode;
  description?: React.ReactNode;
  reasonLabel?: string;
  SubmitButton?: ComponentType<any>;
  helperText?: React.ReactNode;
}

export function CaptureReasonDialog({
  onClose,
  onSubmit,
  title,
  submitLabel,
  SubmitButton,
  description,
  reasonLabel,
  helperText
}: CaptureReasonDialogProps) {
  const handleSubmit: RecordSubmitHandler = useCallback(
    async ({ values }) => {
      await onSubmit(values);
      return true;
    },
    [onSubmit]
  );

  return (
    <RecordDialog
      onClose={onClose}
      blockProps={{
        description,
        reasonLabel,
        helperText
      }}
      title={title}
      handleSubmit={handleSubmit}
      content={content}
      submitLabel={submitLabel}
      SubmitButton={SubmitButton}
    />
  );
}
