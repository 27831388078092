import React, { useContext } from 'react';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import Box from '@rexlabs/box';
import { useGetIncrementingAlphabeticalIdentifierForItem } from '../../hooks/smart-checklists/use-get-incrementing-alphabetical-identifier-for-item';
import { useMouseBoundary } from '../../hooks/smart-checklists/use-mouse-boundary';
import { getItemIndex } from '../../utils/get-item-index';
import { useOnDragEnd } from '../../hooks/smart-checklists/use-on-drag-end';
import { ChecklistTemplateItemIdentifierProvider } from './checklist-template-item-identifier-provider';
import { fieldArrayContext } from './field-array-context';
import { ChecklistTemplateItemWrapper } from './checklist-template-item-wrapper';
import { ChecklistTemplatePath } from './checklist-template-path';

export const outerDroppable = 'outer';

export const ChecklistTemplateItemRows: React.FC<{
  isEditing: boolean;
}> = ({ isEditing }) => {
  const [hoverTarget, setHoverTarget] = React.useState<string | null>(null);
  const { values } = useContext(fieldArrayContext);

  const { checklist_template_items: items } = values;

  const onDragEnd = useOnDragEnd();

  useMouseBoundary({
    className: outerDroppable,
    onEnter: () => setHoverTarget(outerDroppable),
    onLeave: () => setHoverTarget(null)
  });

  const getIncrementingAlphabeticalIdentifierForItem = useGetIncrementingAlphabeticalIdentifierForItem(
    values.checklist_template_items ?? []
  );

  return (
    <ChecklistTemplateItemIdentifierProvider
      getIdentifierForItem={getIncrementingAlphabeticalIdentifierForItem}
    >
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable
          direction='vertical'
          droppableId={outerDroppable}
          isDropDisabled={!isEditing || hoverTarget !== outerDroppable}
          type='items'
        >
          {(provided) => (
            <Box ref={provided.innerRef} className={outerDroppable}>
              {/* NOTE: Items is all items in the fieldArray, in order. Might need special handling for nested items...*/}
              {items
                // Filter out items that are part of a group, as they will be rendered in the group
                ?.filter((item) => !item.checklist_template_item_group)
                .map((item) => {
                  const groups = getGroupsForItem(item, values);
                  const index = getItemIndex(item, items);

                  return (
                    <ChecklistTemplateItemWrapper
                      // can't just use the index of the actual item in the iterator, as we are filtering a bunch of items out if they are in a group
                      // but the indexes need to refer to the global index of the item in the field array
                      globalIndex={index}
                      index={index}
                      key={item.id}
                      item={item}
                    >
                      {item.has_outcome && item.outcome_data?.outcome_options
                        ? item.outcome_data.outcome_options.map((option) => {
                            const group = groups?.find(
                              (group) =>
                                group.triggered_by_checklist_template_item
                                  ?.id === item.id &&
                                group.triggered_by_value === option.id
                            );
                            if (!group) {
                              console.warn('No group found for option', option);
                              return null;
                            }
                            return (
                              <ChecklistTemplatePath
                                // These props handle the stuff needed to enable and disable dropzones depending where the user is hovering
                                checklistTemplateItemGroup={group}
                                checklistTemplateItems={items.filter(
                                  (item) =>
                                    item.checklist_template_item_group?.id ===
                                    group.id
                                )}
                                triggeredByItem={item}
                                setHoverTarget={setHoverTarget}
                                parentHoverTarget={outerDroppable}
                                hoverTarget={hoverTarget}
                                grey={true}
                                outcomeValue={option}
                                key={option.id}
                              ></ChecklistTemplatePath>
                            );
                          })
                        : null}
                    </ChecklistTemplateItemWrapper>
                  );
                })}
              {provided.placeholder}
            </Box>
          )}
        </Droppable>
      </DragDropContext>
    </ChecklistTemplateItemIdentifierProvider>
  );
};

function getGroupsForItem(item, values) {
  return item.has_outcome
    ? values.checklist_template_item_groups?.filter(
        (group) => group.triggered_by_checklist_template_item?.id === item.id
      )
    : [];
}
