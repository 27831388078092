import React, {
  useState,
  ComponentType,
  ReactElement,
  PropsWithChildren
} from 'react';

import Box, { BoxProps } from '@rexlabs/box';
import {
  useStyles,
  useToken,
  StyleSheet,
  text,
  border,
  margin,
  icon
} from '@rexlabs/styling';
import {
  OutlineButton,
  TintIconButton,
  ButtonGroup,
  GhostButton
} from '@rexlabs/button';
import Icons from '@rexlabs/icons';

const { CrossSmall } = Icons;

const defaultStyles = StyleSheet({
  container: {
    ...border.styles({
      target: 'banner',
      all: {
        width: 'thin',
        radius: 'l',
        color: 'container.static.light'
      }
    }),

    width: ({ token }) => token('banner.width', '100%'),
    maxWidth: ({ token }) => token('banner.maxWidth'),
    backgroundColor: ({ token }) =>
      token('banner.background.color', token('color.container.static.default')),
    boxShadow: ({ token }) => token('banner.boxShadow')
  },

  strong: {
    ...text.styles({
      target: 'banner.strong'
    }),
    ...border.styles({
      target: 'banner.strong'
    }),

    backgroundColor: ({ token }) => token('banner.strong.background.color')
  },

  icon: {
    height: ({ token }) =>
      token(
        'banner.description.lineHeight',
        token('textStyle.normal.default.lineHeight')
      ),

    '& > svg': {
      ...icon.styles({
        target: 'banner.icon',
        size: 'm'
      })
    }
  },

  title: {
    wordBreak: 'break-word',

    ...text.styles({
      target: 'banner.title',
      fallback: 'normal.bold'
    }),
    ...margin.styles({
      target: 'banner.title',
      right: 's'
    })
  },

  description: {
    wordBreak: 'break-word',

    ...text.styles({
      target: 'banner.description',
      fallback: 'normal.default'
    }),

    minWidth: ({ token }) => token('banner.description.minWidth', '20rem')
  }
});

export type BannerAction = {
  label: string;
  onClick: (...args) => void;
  Button: ComponentType<any>;
};

export type BannerProps = BoxProps &
  PropsWithChildren<{
    title?: string;
    description: string | ReactElement;
    actions?: BannerAction[];
    strong?: boolean;
    Icon?: ComponentType<any>;
    handleClose?: (...args) => void;
  }>;

export function Banner({
  title,
  strong,
  description,
  actions = [],
  Icon,
  handleClose,
  children,
  ...rest
}: BannerProps) {
  const s = useStyles(defaultStyles);
  const token = useToken();

  const [hasGrown, setHasGrown] = useState(false);
  const containerRef = React.useRef<HTMLElement>(null);

  React.useLayoutEffect(() => {
    const element = containerRef?.current;
    if (!element) {
      return;
    }

    const resizeObserver = new ResizeObserver((entries) => {
      window.requestAnimationFrame(() => {
        if (!Array.isArray(entries) || !entries.length || !element) return;

        // HACK: This feels just a little bit wrong and can fail when
        // some customisation is applied to these banners and the minHeight
        // is not adjusted accordingly, or the minHeight is removed entirely

        const minHeight = parseInt(
          getComputedStyle(element).minHeight.replace('px', '')
        );
        const currentHeight = element.offsetHeight;

        if (currentHeight > minHeight) {
          setHasGrown(true);
        } else {
          setHasGrown(false);
        }
      });
    });

    resizeObserver.observe(element);
    return () => resizeObserver.unobserve(element);
  }, []);

  const leftMargin = `calc(${token(
    'banner.icon.size',
    token('icon.size.m')
  )} + ${token('spacing.s')})`;

  const minHeight = hasGrown
    ? token('banner.compact.minHeight', '7.4rem')
    : token('banner.minHeight', '5.8rem');
  const paddingTop = hasGrown
    ? token('banner.compact.padding.top', token('spacing.m'))
    : token('banner.padding.top', token('spacing.xs'));
  const paddingRight = hasGrown
    ? token('banner.compact.padding.right', token('spacing.m'))
    : token('banner.padding.right', token('spacing.m'));
  const paddingBottom = hasGrown
    ? token('banner.compact.padding.bottom', token('spacing.m'))
    : token('banner.padding.bottom', token('spacing.xs'));
  const paddingLeft = hasGrown
    ? token('banner.compact.padding.left', token('spacing.m'))
    : token('banner.padding.left', token('spacing.m'));

  const hasActions = actions.length > 0;

  return (
    <Box
      {...s('container', { strong })}
      ref={containerRef}
      flexDirection='row'
      justifyContent='space-between'
      pt={paddingTop}
      pr={paddingRight}
      pb={paddingBottom}
      pl={paddingLeft}
      style={{
        minHeight
      }}
      {...rest}
    >
      <Box
        flex={1}
        alignItems='center'
        justifyContent='space-between'
        flexWrap='wrap'
        spacing={token('spacing.s')}
      >
        <Box alignItems='flex-start'>
          {Icon && (
            <Box
              {...s('icon')}
              alignItems='center'
              justifyContent='center'
              mr={token('spacing.s')}
            >
              <Icon />
            </Box>
          )}
          <Box flexDirection='column'>
            {title && <span {...s('title')}>{title}</span>}
            <Box flex={1}>
              <span {...s('description')}>{description || children}</span>
            </Box>
          </Box>
        </Box>
        {hasActions && (
          <Box alignItems='center'>
            <Box alignItems='center' ml={Icon && hasGrown ? leftMargin : 0}>
              <ButtonGroup
                end={!hasGrown}
                sx={token('spacing.s')}
                flexWrap='wrap'
                flexDirection={hasGrown ? 'row' : 'row-reverse'}
              >
                {actions.map(({ label, onClick, Button }, index) => {
                  Button = Button ?? index === 0 ? OutlineButton : GhostButton;
                  return (
                    <Button key={index} onClick={onClick}>
                      {label}
                    </Button>
                  );
                })}
              </ButtonGroup>
            </Box>
          </Box>
        )}
      </Box>
      {handleClose && (
        <Box
          alignItems={hasGrown ? 'flex-start' : 'center'}
          ml={token('spacing.s')}
        >
          <TintIconButton size='xs' onClick={handleClose} Icon={CrossSmall} />
        </Box>
      )}
    </Box>
  );
}

export default Banner;
