import { Forms } from '@rexlabs/form';
import { getRelatedRecords } from 'src/modules/financials/utils/get-related-records';
import { setContactPaymentInformationFormValues } from './set-contact-payment-information-form-values';

export function getCreateWithdrawalChangeHandlers(forms?: Forms) {
  async function handleContactChange({ target }) {
    const withdrawFundsPaymentDetailsForm =
      forms?.['withdraw-funds-payment-details'];

    const contact = target.value;

    if (!contact) return;

    setContactPaymentInformationFormValues(
      contact,
      'payment_information',
      withdrawFundsPaymentDetailsForm?.setFieldValue
    );
  }

  async function handleFolioChange({ target }) {
    const withdrawalDetailsForm = forms?.['withdrawalDetails'];
    const folio = target.value;
    const typeId = folio?.type?.id;

    if (typeId === 'contact') {
      withdrawalDetailsForm?.setFieldValue?.('contact', folio.record);
    }

    if (typeId === 'ownership' || typeId === 'tenancy') {
      (async () => {
        const contacts = (
          await getRelatedRecords({
            folioId: folio.id,
            folioType: folio.type.id
          })
        ).otherContacts;

        contacts?.length === 1 &&
          withdrawalDetailsForm?.setFieldValue?.('contact', contacts[0]);
      })();
    }
  }

  return {
    handleContactChange,
    handleFolioChange
  };
}
