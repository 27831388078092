import { Forms } from '@rexlabs/form';

export function extractValuesFromForms(forms: Forms) {
  // Get the first form so that we can use it as a base for the reducer
  // this means we will get all of the initial values that are on all forms without worrying about them clobbering other values
  const firstForm = forms[Object.keys(forms)[0]];
  return Object.keys(forms)
    .map((formName) => {
      const values = forms[formName].values;

      // We only want values whose names match the fields in the form
      // This is so that we don't accidentally clobber values that are not meant to be in the form
      const names = forms[formName].names;
      return Object.fromEntries(
        Object.entries(values).filter(([name]) => names.includes(name))
      );
    })
    .reduce<any>((curr, acc) => ({ ...curr, ...acc }), firstForm.values);
}
