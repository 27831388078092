export const CREDIT_NOTES_ROUTES = {
  CREDIT_NOTES_LIST: {
    config: {
      path: '/credit-notes',
      Component: require('./screens/credit-notes-list').CreditNotesList
    },
    CREATE: {
      config: {
        path: '/credit-notes/create-credit-note',
        Component: require('./dialogs/create-credit-note-dialog')
          .CreateCreditNoteDialog
      }
    }
  },
  CREDIT_NOTE: {
    config: {
      path: '/credit-note/:creditNoteId',
      Component: require('./screens/credit-note-details')
        .CreditNoteDetailsScreen
    }
  }
};
