import { useQuery } from 'react-query';
import { api } from 'utils/api/api-client';
import { AxiosResponse } from 'axios';
import { Settings } from 'src/modules/settings/hooks/useSettings';

export const settingsQueryKey = 'settings';

export type GetSettingsResponse = Array<{
  key: [keyof Settings];
  value: string | { id: string; label: string };
}>;

export const fetchSettings = async (): Promise<
  AxiosResponse<GetSettingsResponse>
> => {
  return api.get('/settings');
};
export function useSettingsQuery(enabled = true) {
  return useQuery(settingsQueryKey, fetchSettings, {
    staleTime: Infinity,
    enabled,
    select: (res) =>
      Object.fromEntries(res.data.map(({ key, value }) => [key, value]))
  });
}
