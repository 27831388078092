import * as React from 'react';

import { useToken } from '@rexlabs/styling';
import ActionMenu, { ActionMenuItem } from '@rexlabs/action-menu';
import Box from '@rexlabs/box';
import CheckIcon from 'view/components/icons/check';
import { ClickableTag } from 'view/components/@luna/dropdown/clickable-tag';
import { StatusTagType } from 'src/modules/common/components/status-tag';
import { useCallback } from 'react';

type DropdownIntentType = StatusTagType | 'neutral';

export type NormalisedDropdownItem = {
  label: string;
  id: string;
  intent?: DropdownIntentType;
};

export interface DropdownProps<TItem> {
  items: TItem[] | undefined;
  normaliser: (item: TItem) => NormalisedDropdownItem;
  onChange?: (item: TItem) => void;
  description?: string;
  placeholder?: string;
  value?: TItem | null;
  initialValue?: TItem | null;
}

export function Dropdown<TItem>({
  items = [],
  normaliser,
  onChange,
  description = 'Select an item',
  placeholder = 'Nothing selected',
  value,
  initialValue
}: DropdownProps<TItem>) {
  const token = useToken();
  const [selectedItem, setSelectedItem] = React.useState<TItem | null>(
    initialValue || null
  );

  // value prop indicates that the component is controlled by the parent
  const normalSelectedItem = value
    ? normaliser(value)
    : selectedItem
    ? normaliser(selectedItem)
    : null;

  const label = normalSelectedItem?.label || placeholder;

  // Apply the normaliser, but also keep a copy of the original object so we can use it with the change handler
  const normalisedItems = React.useMemo(
    () =>
      items.map((item) => {
        const normalItem = normaliser(item);
        return {
          ...normalItem,
          originalItem: item
        };
      }),
    [items, normaliser]
  );

  const handleClick = useCallback(
    (item: TItem) => {
      setSelectedItem(item);
      onChange?.(item);
    },
    [onChange]
  );

  // Using tokens, establish the color of each intent
  const colorMap: Record<DropdownIntentType, string> = React.useMemo(
    () => ({
      neutral: token('color.neutral.idle.default'),
      average: token('color.warning.idle.default'),
      good: token('color.success.idle.default'),
      bad: token('color.danger.idle.default')
    }),
    [token]
  );

  // Map over the normalised items to create the custom label UI to show in the menu
  const enhancedItems: ActionMenuItem[] = normalisedItems.map((item) => ({
    ...item,
    intent: undefined,
    onClick: () => handleClick(item.originalItem),
    label: (
      <Box display='flex' justifyContent='space-between' gap={16}>
        <Box>{mapIntentToColoredCircle(item.intent, colorMap)}</Box>
        <Box flex={1}>{item.label}</Box>
        {normalSelectedItem?.id === item.id && <CheckIcon />}
      </Box>
    )
  }));

  return (
    <ActionMenu
      placement='bottom-end'
      items={enhancedItems}
      title={description}
      Button={React.forwardRef((props, ref) => (
        <ClickableTag
          label={label}
          intent={normalSelectedItem?.intent}
          {...props}
          ref={ref}
        />
      ))}
    />
  );
}

const mapIntentToColoredCircle = (
  intent: NormalisedDropdownItem['intent'],
  colorMap: Record<DropdownIntentType, string>
): React.ReactNode => {
  return (
    <svg height='8' width='8'>
      <circle cx='4' cy='4' r='4' fill={colorMap[intent || 'neutral']} />
    </svg>
  );
};
