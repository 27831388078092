import { BaseModelGeneratorModel } from '@rexlabs/model-generator';
import { useToast } from 'view/components/@luna/notifications/toast';
import { getRecordLabel } from 'utils/records/get-record-label';
import React, { ReactElement } from 'react';
import {
  RecordTypeIdentifier,
  getRecordObject
} from 'src/modules/common/utils/Records/get-record-object';
import { OutlineButton } from '@rexlabs/button';
import { gotoRecord } from 'src/modules/common/utils/Records/get-record-routes';
import { BannerAction } from 'view/components/@luna/notifications/banner/core';

interface Options {
  actions?: BannerAction[];
}

export function useRecordCreatedToast(
  typeOrModel: RecordTypeIdentifier,
  options: Options = {}
) {
  const { addToast } = useToast();

  return (
    record: BaseModelGeneratorModel,
    description?: string | ReactElement
  ) => {
    const recordObject = getRecordObject(record, typeOrModel);

    addToast({
      description: description ?? (
        <>
          Successfully created <b>{getRecordLabel(recordObject)}</b>
        </>
      ),
      actions: options.actions ?? [
        {
          label: 'View',
          Button: OutlineButton,
          onClick: () => gotoRecord(recordObject)
        }
      ]
    });
  };
}
