import { ValueListValue } from 'data/models/types';

/**
 * Generates a value list value { id: 'foo', label: 'Foo' }
 * The generic can be inferred from consumer.
 * The label will be the capitalised id.
 */
export function valueListFactory<VALUES extends string>(
  value: VALUES
): ValueListValue<VALUES> {
  return {
    id: value,
    label: capitaliseFirstLetter(value.replaceAll('_', ' '))
  };
}

function capitaliseFirstLetter(value: string) {
  return value[0].toUpperCase() + value.slice(1);
}
