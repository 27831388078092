import * as React from 'react';
import { useQuery } from 'react-query';
import { api } from 'utils/api/api-client';
import { ChecklistWithIncludes } from 'src/modules/tasks/common/types/Checklist';
import { toQuri } from '@rexlabs/quri';

export const defaultQueryOptions = {
  keepPreviousData: true,
  refetchOnWindowFocus: false
};

function checklistsQueryParams(id: string) {
  const filter = toQuri([
    {
      field: 'task_id',
      op: 'eq',
      value: id
    }
  ]);
  const includes = [
    'items',
    'items.checklist_item_group',
    'items.outcome_data',
    'groups',
    'groups.triggered_by_checklist_item'
  ];

  const query = new URLSearchParams();
  query.set('include', includes.join(','));
  query.set('q', filter);
  query.set('o', encodeURIComponent(`updated_at+desc`));

  return decodeURIComponent(query.toString());
}

// We could probably just use the same query for both the widget and the tab
export function useChecklistsWidgetQuery(taskId: string) {
  const queryParams = React.useMemo(() => checklistsQueryParams(taskId), [
    taskId
  ]);

  return useQuery(
    ['checklists-widget', taskId],
    async () =>
      api.get<Array<ChecklistWithIncludes>>(`tasks/checklists?${queryParams}`),
    defaultQueryOptions
  );
}
