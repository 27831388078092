import { DialogProps } from '@rexlabs/dialog';

import React from 'react';

import { RecordSubmitHandler } from 'view/components/record-screen/utils';
import { RecordDialog } from 'view/components/record-screen/dialog/dialog';

import {
  AddInspectionsBlockFormValues,
  AddInspectionsBlockProps,
  addInspectionsBlock
} from '../blocks/add-inspections-block';

export interface AddInspectionDialogProps
  extends DialogProps,
    AddInspectionsBlockProps {
  submitHandler: RecordSubmitHandler<AddInspectionsBlockFormValues>;
}

const content = [
  {
    id: 'add-inspections',
    label: '',
    blocks: [addInspectionsBlock]
  }
];

export function AddInspectionDialog({
  onClose,
  submitHandler,
  existingInspectionIds
}: AddInspectionDialogProps) {
  const handleSubmit = async (values) => {
    submitHandler(values);
    onClose?.();
  };

  return (
    <RecordDialog
      size='s'
      title='Add inspection(s)'
      content={content}
      onClose={onClose}
      blockProps={{ existingInspectionIds }}
      handleSubmit={handleSubmit}
    />
  );
}
