import React from 'react';
import {
  SimplePagination,
  Table,
  TableProps,
  ToolbarProps
} from '@rexlabs/table';
import { Toolbar } from './toolbar';

export interface DumbTableProps extends ToolbarProps, TableProps {
  withToolbar?: boolean;
  withPagination?: boolean;
}

export function DumbTable({
  withToolbar,
  withPagination,
  suggestedFilters,
  hiddenFilters,
  ...props
}: DumbTableProps) {
  return (
    <>
      {withToolbar && (
        <Toolbar
          suggestedFilters={suggestedFilters}
          hiddenFilters={hiddenFilters}
        />
      )}
      <Table {...props} />
      {withPagination && <SimplePagination />}
    </>
  );
}
