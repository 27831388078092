import React from 'react';
import { BlockConfig } from 'view/components/record-screen/types';
import { Columns, ListTable } from 'view/components/table';
import { query } from '@rexlabs/model-generator';
import {
  Reconciliation,
  ReconciliationStatus
} from 'data/models/entities/financials/reconciliations';
import { EmptyCard } from 'view/components/record-screen/cards/empty-card';
import { Message } from 'view/components/@luna/message';
import EmptyTable from 'assets/illustrations/empty-table.svg';
import { suspendedTransactionsModel } from 'data/models/entities/financials/suspended-transactions';
import { useTableFilters } from 'view/hooks/use-table-filters';
import { LinkButton, OutlineButton } from '@rexlabs/button';
import { createTableRouteConfig } from 'src/modules/common/utils/create-table-route-config';
import { Link, parseRouteToUrl } from '@rexlabs/whereabouts';
import ROUTES from 'routes/app';
import ChevronRightIcon from 'view/components/icons/chevron-right';

const getColumns = (status: ReconciliationStatus): Columns => {
  const result: Columns = [
    {
      id: 'paid_date',
      type: 'date',
      Header: 'Transaction date',
      width: 150
    },
    {
      id: 'reference',
      Header: 'Reference'
    },
    {
      id: 'suspended_date',
      type: 'date',
      Header: 'Suspended date',
      width: 150
    },
    {
      id: 'amount',
      type: 'currency',
      Header: 'Amount',
      width: 150
    },
    {
      id: 'file_uploaded_date',
      type: 'date',
      Header: 'File uploaded date',
      accessor: (item) => item.bank_statement_transaction_import?.uploaded_date
    }
  ];

  if (status === 'active') {
    result.push({
      id: 'view_link',
      width: 150,
      accessor: (item) => item,
      Cell({ value }) {
        const config = createTableRouteConfig(
          [
            {
              field: 'id',
              op: '==',
              value: value.id
            }
          ],
          'suspended',
          ROUTES.BATCH_RECEIPTING_LIST_SCREEN.config
        );

        const url = parseRouteToUrl(config);

        return (
          <LinkButton as={'a'} target='_blank' href={url}>
            View transaction
          </LinkButton>
        );
      }
    });
  }

  return result;
};

const getSuspendedTransactionsQuery = (reconciliationId: string) => {
  return query`{
    ${suspendedTransactionsModel} (reconciliationId: ${reconciliationId}) {
      id
      bank_statement_transaction_import
    }
  }`;
};

export const suspendedTransactionsBlock: BlockConfig<Reconciliation> = {
  id: 'suspended-transactions',
  title: 'Suspended transactions',
  Actions: ({ data }) => {
    if (data?.status?.id === 'reconciled') return null;

    return (
      <OutlineButton
        as={Link}
        // @ts-ignore - errors when Link component props are passed
        to={{
          config: {
            ...ROUTES.BATCH_RECEIPTING_LIST_SCREEN.config,
            hashQuery: {
              tab: 'suspended'
            }
          }
        }}
        IconRight={ChevronRightIcon}
      >
        View in batch receipting
      </OutlineButton>
    );
  },
  View: ({ data }) => {
    const reconciliation = data!;

    const query = React.useMemo(
      () => getSuspendedTransactionsQuery(reconciliation.id),
      [reconciliation.id]
    );

    const { getSort, getFilters } = useTableFilters(
      'bank-statement-transactions'
    );

    const columns = React.useMemo(
      () => getColumns(reconciliation.status.id),
      []
    );

    return (
      <ListTable
        id='suspended-transactions'
        shouldPersistTableSettings={false}
        columns={columns}
        Empty={Empty}
        getSort={getSort}
        getFilters={getFilters}
        getQuery={() => query}
        hideBulkActions={true}
        initialHiddenColumns={['file_uploaded_date']}
        forcedGlobalFilter={[
          {
            field: 'reconciliation_status_id',
            op: 'eq',
            value: 'unreconciled'
          }
        ]}
      />
    );
  }
};

function Empty() {
  return (
    <EmptyCard>
      <Message
        title={'No suspended transactions'}
        Illustration={EmptyTable}
      ></Message>
    </EmptyCard>
  );
}
