import * as React from 'react';

import { DialogProps } from '@rexlabs/dialog';
import { RecordDialog } from 'view/components/record-screen/dialog/dialog';
import { RecordSubmitHandler } from 'view/components/record-screen/utils';
import { useQuery } from 'react-query';
import { api } from 'utils/api/api-client';
import { inspectionAreasBlock } from '../blocks/inspection-areas-block';
import { taskIncludes } from '../../common/models/tasks-model';

interface ManageAreasDialogProps extends Omit<DialogProps, 'title'> {
  title: Required<DialogProps['title']>;
  taskId: string;
}

const content = [
  {
    id: 'manage-areas',
    label: 'Manage areas',
    blocks: [inspectionAreasBlock]
  }
];

export function ManageAreasDialog({
  onClose,
  title,
  taskId
}: ManageAreasDialogProps) {
  const { isLoading, data: taskData } = useQuery(
    ['task', taskId],
    () => api.get(`/tasks/${taskId}?include=${taskIncludes.inspectionDetails}`),
    {
      refetchOnWindowFocus: false
    }
  );

  const handleSubmit: RecordSubmitHandler = () => {
    return true;
  };

  const dialogData = {
    inspectionTask: taskData?.data,
    id: taskData?.data.id,
    areas: taskData?.data.details.areas.data,
    area_order: getAreaOrder(
      taskData?.data.details.area_order,
      taskData?.data.details.areas.data.map((area) => area.id)
    )
  };

  return (
    <RecordDialog
      isLoading={isLoading}
      title={title}
      data={dialogData}
      content={content}
      onClose={onClose}
      handleSubmit={handleSubmit}
      submitLabel='Done'
    />
  );
}

// Util

function getAreaOrder(
  areaOrder: Array<string> | undefined,
  areaIds: Array<string>
) {
  if (!Array.isArray(areaOrder)) return areaIds;

  const missingAreaIds = areaIds.filter(
    (areaId) => !areaOrder.includes(areaId)
  );

  return [...areaOrder, ...missingAreaIds];
}
