import * as React from 'react';
import { ErrorBanner } from '@rexlabs/notifications';
import { Body, Bold } from '@rexlabs/text';
import WarningCircleIcon from 'view/components/icons/warning-circle';
import { getRecordLabel } from 'utils/records/get-record-label';
import { formatDateTime } from 'utils/dates/format';
import Box from '@rexlabs/box';
import { Invoice } from 'data/models/entities/financials/invoices';
import { capitalize } from 'lodash';
import { CreditNote } from 'data/models/entities/financials/credit-notes';
import { getRecordTypeName } from 'utils/records/get-record-type-name';
import { RecordTypes } from 'data/models/types';
import { Disbursement } from 'data/models/entities/financials/disbursements/disbursements';
import { Reconciliation } from 'data/models/entities/financials/reconciliations';
import { TrustJournalEntry } from 'src/modules/trust-journal-entries/types/trust-journal-entry-types';
import { AccountingJournalEntry } from 'src/modules/account-journal-entries/types/account-journal-entry-types';

type VoidedBannerProps = {
  record:
    | Invoice
    | CreditNote
    | Disbursement
    | Reconciliation
    | TrustJournalEntry
    | AccountingJournalEntry;
};

export function VoidedBanner({ record }: VoidedBannerProps) {
  const voidedBy = record?.voided_by;
  const voidedAt = record.voided_at;
  const statusReason = record?.status_reason;
  const recordType = record.__record_type;
  const recordTypeName = getRecordTypeName(recordType);

  const action =
    recordType === RecordTypes.TrustJournalEntry ? 'reversed' : 'voided';

  return (
    <ErrorBanner
      title={`${capitalize(recordTypeName)} has been ${action}!`}
      description={
        <Body>
          {voidedBy && (
            <div data-testid='voided-banner-details'>
              <>
                This {recordTypeName} was {action} by{' '}
                <Bold>
                  {getRecordLabel({ object: voidedBy, type: 'author' })}
                </Bold>
              </>
              {voidedAt && (
                <>
                  {' '}
                  on <Bold> {formatDateTime(voidedAt!)}.</Bold>
                </>
              )}
            </div>
          )}
          {statusReason && (
            <div data-testid='voided-banner-status-reason'>
              <Box>
                <Bold>Reason: </Bold>
                {statusReason}
              </Box>
            </div>
          )}
        </Body>
      }
      Icon={WarningCircleIcon}
      strong={true}
    />
  );
}
