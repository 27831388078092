import { useDialog } from '@rexlabs/dialog';

import { EditInspectionRunStartDialog } from '../dialogs/edit-inspection-run-start-dialog';
import { mapInspectionRunStartFormToInspectionRun } from '../mappers/map-inspection-run-start-form-to-inspection-run';
import { mapInspectionRunToEditInspectionRunStartForm } from '../mappers/map-inspection-run-to-edit-inspection-run-start-form';
import { useInspectionRunContext } from './use-inspection-run-context';

export function useGetEditInspectionRunStart() {
  const { open: openEditInspectionRunStartDialog } = useDialog(
    EditInspectionRunStartDialog
  );

  const { inspectionRun, editInspectionRunDetails } = useInspectionRunContext();

  return () =>
    openEditInspectionRunStartDialog({
      initialValues: mapInspectionRunToEditInspectionRunStartForm(
        inspectionRun!
      ),
      submitHandler: ({ values }) => {
        const newInspectionRunDetails = mapInspectionRunStartFormToInspectionRun(
          values
        );

        editInspectionRunDetails?.(newInspectionRunDetails);

        return newInspectionRunDetails;
      }
    });
}
