import React from 'react';
import { lowerCase, map, upperFirst } from 'lodash';

import { Field } from '@rexlabs/form';

import { BlockConfig } from 'view/components/record-screen/types';
import { Grid } from 'view/components/@luna/form/grid';
import { Value } from 'view/components/values';

import { MessageTemplateSelect } from 'src/modules/communications/common/components/selects/message-template-select';

import { MessageTriggerData } from '../types/MessageTriggerData';
import { MessageTriggerForm } from '../types/MessageTriggerForm';
import { BlockId } from '../types/MessageTriggerSettingsBlockId';
import { getMessageTriggerSettingsMapForBlockId } from '../utils/field-map';

export function messageTriggerSettingsBlockFactory(
  blockId: BlockId
): BlockConfig<MessageTriggerData, any, MessageTriggerForm> {
  const fieldMap = getMessageTriggerSettingsMapForBlockId(blockId);
  return {
    id: blockId,
    title: upperFirst(lowerCase(blockId)),
    View: ({ data, values }) => {
      return (
        <Grid columns={2}>
          {map(fieldMap, (value, key) => {
            return (
              <Value
                key={key}
                label={value.label}
                // Using both here, to incase the values are not set yet.
                // This can happen if you refresh the triggers page, and settings have not
                // been fetched yet
                value={data![key]?.name || values![key]?.name}
              />
            );
          })}
        </Grid>
      );
    },
    Edit: () => {
      return (
        <Grid columns={2}>
          {map(fieldMap, (value, key) => {
            return (
              <Field<typeof MessageTemplateSelect>
                key={key}
                id={key}
                name={key}
                label={value.label}
                Input={MessageTemplateSelect}
                inputProps={{
                  contextType: value.context_type_id,
                  deselectable: true,
                  recipientGroups: value?.recipient_groups
                }}
              />
            );
          })}
        </Grid>
      );
    }
  };
}
