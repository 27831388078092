import { PrimaryHeading } from 'src/modules/auth/login/components/heading-primary';
import React, { useCallback } from 'react';
import { Field, Form, ReactForms } from '@rexlabs/form';
import { useTranslation } from 'react-i18next';
import { Column } from '@rexlabs/grid';
import { TextInput } from '@rexlabs/text-input';
import { PasswordInput } from '@rexlabs/password-input';
import { ButtonGroup, GhostButton, PrimaryButton } from '@rexlabs/button';
import Box from '@rexlabs/box';
import { push } from '@rexlabs/whereabouts';
import i18n from 'src/modules/i18n/i18n';
import { Body } from '@rexlabs/text';
import { UserInvitation } from 'src/modules/auth/user-invitation/types/user-invitation';
import { getRecordLabel } from 'utils/records/get-record-label';
import { useApiClient } from 'src/lib/axios/hooks/use-api-client';
import { Value } from 'view/components/values';
import { FormGrid } from 'view/components/@luna/grid/form';
import { useModelActions } from '@rexlabs/model-generator';
import { sessionModel } from 'data/models/custom/session';
import ROUTES from 'routes/app';
import config from 'config';
import { redirectToAuthServiceLogin } from 'utils/api/redirect-to';
import { USER_INVITATION_ROUTES } from 'src/modules/auth/user-invitation/routes';
import LOGIN_ROUTES from 'src/modules/auth/login/routes';

export function UserInvitationCreateUserCard({
  token,
  invitation
}: {
  token: string;
  invitation: UserInvitation;
}) {
  const { t } = useTranslation();
  const { login } = useModelActions(sessionModel);

  const validate = {
    definitions: {
      given_name: {
        rules: 'required',
        name: i18n.t('form.general.email.label')
      },
      family_name: {
        rules: 'required',
        name: i18n.t('form.general.email.label')
      },
      email: {
        rules: 'required|email',
        name: i18n.t('form.general.email.label')
      },
      password: {
        rules: 'required',
        name: i18n.t('form.general.password.label')
      }
    }
  };

  const axios = useApiClient();

  const handleSubmit = useCallback(
    (values) =>
      axios(
        'post',
        '/user-invites/complete/new-user',
        {
          password: values.password,
          given_name: values.given_name,
          family_name: values.family_name,
          token,
          accept: true
        },
        { baseURL: invitation.silo?.base_url } // make sure this request is routed to the correct region
      ).then((data) => {
        if (data !== false) {
          //Login our user...
          login({
            email: values.email,
            password: values.password,
            ttl: 3600 * 2
          });
          //Route to the dashboard...
          push(ROUTES.HOME);
        }
      }),
    [axios, login, token]
  );

  return (
    <>
      <ReactForms
        validate={validate}
        handleSubmit={handleSubmit}
        initialValues={{
          given_name: invitation.user.contact?.given_name,
          family_name: invitation.user.contact?.family_name,
          email: invitation.user.contact?.primary_email?.email_address,
          password: ''
        }}
      >
        {({ isSubmitting, values, submitForm }) => {
          return (
            <Box>
              <Box mb='3.2rem'>
                <PrimaryHeading level='display'>
                  Welcome,{' '}
                  {getRecordLabel({
                    type: 'user',
                    object: invitation.user!
                  })}
                </PrimaryHeading>
                <Body grey as='span'>
                  To get started, finish up setting up your account.
                </Body>
              </Box>
              <Form>
                <FormGrid>
                  <Column width={12}>
                    <Value label='Email' value={values?.email} />
                  </Column>
                  <Column width={12}>
                    <Field
                      name='given_name'
                      label='First name'
                      Input={TextInput}
                    />
                  </Column>
                  <Column width={12}>
                    <Field
                      name='family_name'
                      label='Last name'
                      Input={TextInput}
                    />
                  </Column>
                  <Column width={12}>
                    <Field
                      name='password'
                      label={
                        t('form.general.password.label', 'password') as string
                      }
                      Input={PasswordInput}
                    />
                  </Column>
                  <Column width={12} mt='1.6rem'>
                    <Box
                      flexDirection='row'
                      alignItems='end'
                      justifyContent='flex-end'
                    >
                      <ButtonGroup>
                        <GhostButton
                          onClick={() => {
                            if (
                              config.AUTHENTICATION_SERVICE_ENABLE_INTEGRATION
                            ) {
                              redirectToAuthServiceLogin(
                                window.location.origin +
                                  LOGIN_ROUTES.USER_INVITATION.config.path +
                                  '?token=' +
                                  token +
                                  '&account_id=' +
                                  invitation.silo.id
                              );
                            } else {
                              push(
                                USER_INVITATION_ROUTES.USER_INVITATION_LOGIN,
                                {
                                  query: { token }
                                }
                              );
                            }
                          }}
                        >
                          Already have an account?
                        </GhostButton>
                        <PrimaryButton
                          onClick={() => {
                            submitForm();
                          }}
                          isLoading={isSubmitting}
                        >
                          Create & Accept Invitation
                        </PrimaryButton>
                      </ButtonGroup>
                    </Box>
                  </Column>
                </FormGrid>
              </Form>
            </Box>
          );
        }}
      </ReactForms>
    </>
  );
}
