import { makeIconComponent } from '@rexlabs/icons';

import KebabMediumSvg from 'src/assets/icons/m/kebab.svg';
import KebabLargeSvg from 'src/assets/icons/l/kebab.svg';
import KebabSmallSvg from 'src/assets/icons/s/kebab.svg';

export const KebabIcon = makeIconComponent({
  s: KebabSmallSvg,
  m: KebabMediumSvg,
  l: KebabLargeSvg
});

export default KebabIcon;
