import { ContentConfig } from 'view/components/record-screen/types';
import { bankWithdrawalTrustJournalEntriesBlock } from 'src/modules/banking/bank-withdrawals/blocks/bank-withdrawal-trust-journal-entries';
import { detailsBlock } from '../blocks/bank-withdrawal-details';
import { bpayLogoBlock } from '../blocks/bpay-logo-block';
import { BankWithdrawal } from '../types/bank-withdrawal-types';

export function useContent({ data }: { data?: BankWithdrawal }): ContentConfig {
  return [
    {
      label: 'General',
      items: [
        {
          id: 'overview',
          label: 'Overview',
          blocks: [
            detailsBlock,
            bankWithdrawalTrustJournalEntriesBlock,
            ...(data?.type?.id === 'bpay' ? [bpayLogoBlock] : [])
          ]
        }
      ]
    }
  ];
}
