import { DialogProps } from '@rexlabs/dialog';
import { useModelActions } from '@rexlabs/model-generator';
import * as React from 'react';
import { useRecordCreatedToast } from 'src/modules/common/toasts/hooks/use-record-created-toast';
import { getRecordObject } from 'src/modules/common/utils/Records/get-record-object';
import { gotoRecord } from 'src/modules/common/utils/Records/get-record-routes';
import { RecordDialog } from 'view/components/record-screen/dialog/dialog';
import { RecordSubmitHandler } from 'view/components/record-screen/utils';
import { emptyMessageCreatorItem } from '../../common/data/empty-message-creator-item';
import { messageTemplateDetailsBlock } from '../blocks/message-template-details-block';
import { useGetMapCreateTemplateFormDataToCreateTemplateRequest } from '../mappers/map-create-template-form-data-to-create-template-request';
import { messageTemplatesModel } from '../models/message-templates-model';
import { CreateTemplateFormData } from '../types/create-template-form-data';

export interface CreateMessageTemplateRecordDialogProps extends DialogProps {
  data: {
    // TODO: add any desired props here
  };
}

const content = [
  {
    id: 'add-message-template',
    label: 'Add Message-Template',
    blocks: [messageTemplateDetailsBlock]
  }
];

export function CreateMessageTemplateRecordDialog({
  onClose,
  data
}: CreateMessageTemplateRecordDialogProps) {
  const { createItem, refreshLists } = useModelActions(messageTemplatesModel);
  const mapCreateTemplateFormDataToCreateTemplateRequest = useGetMapCreateTemplateFormDataToCreateTemplateRequest();
  const addToast = useRecordCreatedToast(messageTemplatesModel, {
    actions: []
  });

  const handleSubmit: RecordSubmitHandler<CreateTemplateFormData> = async ({
    values
  }) => {
    // TODO: make any modifications to the data here before submitting
    const dataToSubmit = await mapCreateTemplateFormDataToCreateTemplateRequest(
      values
    );

    const { data: messageTemplate } = await createItem({ data: dataToSubmit });

    addToast(messageTemplate);

    gotoRecord(getRecordObject(messageTemplate, messageTemplatesModel));

    return refreshLists();
  };

  const initialValues: Partial<CreateTemplateFormData> = {
    send_from_author: emptyMessageCreatorItem
  };

  return (
    <RecordDialog
      title='Create message template'
      data={data}
      initialValues={initialValues}
      content={content}
      onClose={onClose}
      submitLabel='Create template &amp; view'
      handleSubmit={handleSubmit}
    />
  );
}
