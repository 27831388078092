import React from 'react';
import { isEmpty } from 'lodash';

import { Field } from '@rexlabs/form';
import { Body, Bold } from '@rexlabs/text';

import EmptyTable from 'assets/illustrations/empty-table.svg';

import { pluralize } from 'utils/formatters';

import { Message } from 'view/components/@luna/message';
import { EmptyCard } from 'view/components/record-screen/cards/empty-card';
import { BlockConfig } from 'view/components/record-screen/types';
import { Column, Grid } from 'view/components/@luna/form/grid';
import { Value } from 'view/components/values';
import { TimePeriodInput } from 'view/components/inputs/time-period-input';
import { ServicePackageNames } from 'src/modules/tasks/inspections/components/service-package-names';
import { AutomaticTaskCreationRadioButtons } from 'src/modules/tasks/common/components/AutomaticTaskCreationRadioButtons';
import { ServicePackageSelect } from 'view/components/inputs/selects/v4-selects/service-package-select';

export const createPropertyComplianceTaskSettingsBlock: BlockConfig = {
  id: 'property-compliance-task',
  title: 'Property compliance',
  validate: {
    definitions: {
      'property-compliance-task.unit': {
        name: 'create task unit',
        rules: 'required'
      },
      'property-compliance-task.count': {
        name: 'create task count',
        rules: 'required|min:1'
      },
      'property-compliance-task.service_packages': {
        name: 'service packages',
        rules:
          'required_if:property-compliance-task.automatic_task_creation,service_packages|array|min:1'
      }
    },
    messages: {
      required_if: 'The :attribute field is required.'
    }
  },
  showEmpty: (data) => isEmpty(data?.['property-compliance-task']),
  Empty: ({ onEditClick }) => {
    return (
      <EmptyCard>
        <Message
          title='Property compliance task creation'
          Illustration={EmptyTable}
          actions={[
            {
              label: 'Get started',
              intent: 'primary',
              handleAction: onEditClick
            }
          ]}
        >
          <div>
            How long before a requirement expires should the task be created?
          </div>
        </Message>
      </EmptyCard>
    );
  },
  Edit: ({ values }) => {
    return (
      <Grid columns={1}>
        <Bold>Automatic task creation</Bold>
        <Field
          label='Create a property compliance task'
          name='property-compliance-task'
          Input={TimePeriodInput}
          inputProps={{
            testId: 'property-compliance-task',
            countName: 'property-compliance-task.count',
            unitName: 'property-compliance-task.unit',
            Suffix: <Body>before requirement expires</Body>
          }}
          optional={false}
        />

        <Grid columns={2}>
          <Field
            Input={AutomaticTaskCreationRadioButtons}
            name='property-compliance-task.automatic_task_creation'
            id='property-compliance-task.automatic_task_creation'
            optional={false}
          />
        </Grid>

        {values?.['property-compliance-task']?.automatic_task_creation ===
        'service_packages' ? (
          <Field
            name='property-compliance-task.service_packages'
            label='For service packages'
            Input={ServicePackageSelect}
            inputProps={{
              deselectable: true,
              multi: true
            }}
            optional={false}
          />
        ) : null}
      </Grid>
    );
  },
  View: ({ data }) => {
    return (
      <Grid columns={1}>
        <Bold>Automatic task creation</Bold>
        <Grid columns={2}>
          <Column width={2}>
            <Value
              label='Create a property compliance task'
              value={`${pluralize(
                data?.['property-compliance-task'].unit,
                data?.['property-compliance-task'].count,
                true
              )} before requirement expires`}
            />
          </Column>
        </Grid>

        {data?.['property-compliance-task']?.automatic_task_creation ===
        'service_packages' ? (
          <Grid columns={2}>
            <ServicePackageNames
              ids={
                data?.['property-compliance-task']?.service_package_ids ?? []
              }
            />
          </Grid>
        ) : null}
      </Grid>
    );
  }
};
