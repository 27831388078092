import { parseUploadedFileMeta } from 'utils/files';
import { getSearchResultItemFromObjectAndModel } from 'src/modules/common/utils/search-result-items/get-search-result-items-from-object-and-model';
import { WorkOrderTask } from '../types/WorkOrderTask';
import { WorkOrderFormData } from '../types/WorkOrderFormData';
import { tasksModel } from '../../common/models/tasks-model';

export function mapWorkOrderTaskToWorkOrderFormData(
  task: WorkOrderTask
): Partial<WorkOrderFormData> {
  return {
    ...task,
    parent_task: getSearchResultItemFromObjectAndModel(
      task.parent_task!,
      tasksModel
    ),
    details: {
      ...task.details,
      supplier:
        task.details!.work_done_by_type?.id === 'supplier'
          ? task.details?.work_done_by
          : undefined,
      work_done_by_type: task.details!.work_done_by_type?.id,
      images: parseUploadedFileMeta(task.details?.images),
      access_provided_by: task.details?.access_provided_by
        ? task.details.access_provided_by
        : undefined
    }
  };
}
