import { slugToSentenceCase } from 'src/modules/communications/messages/utils/slug-to-sentence-case';
import { FinancialEntity } from 'src/modules/financials/utils/financial-entity-action-group/use-get-financial-entity-actions';
import { SearchResultItem } from 'utils/api/get-search-results';
import { TodoFormData } from '../types/TodoFormData';
import { TodoTask } from '../types/TodoTask';

const getSearchResultItemFromDetails = (
  details: TodoTask['details']
): SearchResultItem<FinancialEntity> => {
  return {
    id: details?.relates_to?.id || '',
    label: details?.relates_to?.display_name || '',
    type: {
      id: details?.relates_to_type || '',
      label: slugToSentenceCase(details?.relates_to_type || '')
    },
    record: details?.relates_to as any
  };
};

export function mapTodoTaskToTodoFormData(task: TodoTask): TodoFormData {
  return {
    summary: task.summary || null,
    description: task.description || null,
    due_date: task.due_date ? task.due_date : null,
    follow_up_date: task.follow_up_date ? task.follow_up_date : null,
    managed_by: task.managed_by || null,
    priority: task.priority || null,
    property: task.property || null,
    status: task.status || null,
    relates_to: task?.details?.relates_to
      ? getSearchResultItemFromDetails(task!.details!)
      : null
  };
}
