import React from 'react';
import { LineItemsTable } from 'view/components/table';
import { LineItemsTableProps } from '../table/line-items';

export function LineItemsView<T>({
  items,
  summary,
  columns,
  ...props
}: LineItemsTableProps<T>) {
  return (
    <LineItemsTable
      {...props}
      items={items}
      columns={columns}
      summary={summary}
    />
  );
}
