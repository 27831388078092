import React from 'react';

import { Message } from '@rexlabs/notifications';
import { useToken } from '@rexlabs/styling';
import Box from '@rexlabs/box';

import EmptyTable from 'assets/illustrations/empty-table.svg';

import { EmptyCard } from 'view/components/record-screen/cards/empty-card';
import LoadingState from 'view/components/states/loading';
import RenderLoading from 'view/components/@luna/render-loading';

import { useGetHasReportBeenCompletedWithoutRooms } from 'src/modules/tasks/inspections/hooks/use-get-has-report-been-completed-without-rooms';
import { useGetReportName } from 'src/modules/tasks/inspections/entry-exit/hooks/use-get-report-name';
import { InspectionTask } from '../types/InspectionTask';
import { useGetPreviousInspectionReports } from '../hooks/use-get-previous-inspection-reports';
import { useEmptyStateActions } from '../hooks/use-empty-state-actions';

export function InspectionReportEmptyState({
  inspectionTask
}: {
  inspectionTask: InspectionTask;
}) {
  const getInspectionReportName = useGetReportName();

  const actions = useEmptyStateActions(inspectionTask);

  const { isFetched } = useGetPreviousInspectionReports(inspectionTask);

  return (
    // NOTE: We need to wait and see if there is existing completed tasks before we show the button,
    // as the action and label change based on that info.
    <RenderLoading LoadingView={LoadingState} isLoading={!isFetched}>
      <EmptyCard>
        <Message
          title={getInspectionReportName(inspectionTask)}
          Illustration={EmptyTable}
          actions={actions}
        >
          <EmptyStateMessage inspectionTask={inspectionTask} />
        </Message>
      </EmptyCard>
    </RenderLoading>
  );
}

function EmptyStateMessage({
  inspectionTask
}: {
  inspectionTask: InspectionTask;
}) {
  const getIsReportCompleteWithoutRooms = useGetHasReportBeenCompletedWithoutRooms();
  const token = useToken();
  const previousInspectionReports = useGetPreviousInspectionReports(
    inspectionTask
  );

  // NOTE: this is to make sure if we get in a state with no rooms, we tell the user how to get out of it.
  if (getIsReportCompleteWithoutRooms(inspectionTask)) {
    return (
      <Box flexDirection='column' rowGap={token('spacing.l')}>
        <span>
          {`The task progress has been set to ${inspectionTask.details?.status.label}.`}
        </span>
        <span>
          {`Creating a new report will change the status to 'Report in progress'`}
        </span>
      </Box>
    );
  }

  if (previousInspectionReports.data?.length) {
    return <>Import previous report data or create a new report.</>;
  }

  // If there are no previous reports, we can rely on the button label "Create report" to be descriptive enough.
  return null;
}
