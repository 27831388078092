import { SecurityDepositType } from 'data/models/entities/financials/security-deposit-types';
import { useGetEditAction } from './security-deposit-types-actions/use-get-edit-action';
import { useGetUnarchiveAction } from './security-deposit-types-actions/use-get-unarchive-action';
import { useGetSecurityDepositTypeArchivableEntityActionGroup } from './security-deposit-types-actions/use-get-security-deposit-type-archivable-entity-action-group';

export function useGetSecurityDepositTypeActions() {
  const getEditAction = useGetEditAction();
  const getUnarchiveAction = useGetUnarchiveAction();
  const getSecurityDepositTypeArchivableEntityActionGroup = useGetSecurityDepositTypeArchivableEntityActionGroup();

  const getActions = (
    securityDepositType?: SecurityDepositType,
    updateListId?: string
  ) => {
    if (!securityDepositType) return [];

    if (securityDepositType.status.id === 'archived') {
      return [
        getEditAction(securityDepositType),
        getUnarchiveAction(securityDepositType, updateListId),
        ...getSecurityDepositTypeArchivableEntityActionGroup(
          securityDepositType,
          updateListId
        ).filter((action) => action.id != 'archive')
      ];
    }

    return [
      getEditAction(securityDepositType),
      ...getSecurityDepositTypeArchivableEntityActionGroup(
        securityDepositType,
        updateListId
      )
    ];
  };

  return getActions;
}
