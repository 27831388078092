import { useWhereabouts } from '@rexlabs/whereabouts';
import { Columns } from 'src/view/components/table';
import { ProgressDropdown } from 'src/modules/tasks/common/components/progress-dropdown';
import { RelatesToCell } from '../components/relates-to-cell';
import { SummaryCell } from '../../common/components/summary-cell';
import { TodoTask } from '../types/TodoTask';
import { closedDate, managedBy } from '../../common/utils/common-columns';

const summary = {
  id: 'summary',
  Header: 'Summary',
  Cell: SummaryCell,
  accessor: (item) => item,
  width: 380,
  toCsv: (item: TodoTask) => item.summary
};

const relatesTo = {
  id: 'relates_to',
  Header: 'Relates to',
  accessor: (item: TodoTask) => item,
  Cell: RelatesToCell,
  toCsv: (item: TodoTask) => item.details?.relates_to?.display_name || '-'
};

const followUpDate = {
  id: 'follow_up_date',
  Header: 'Follow up date',
  accessor: (item: TodoTask) => item.follow_up_date,
  type: 'date',
  width: 140
} as const;

const dueDate = {
  id: 'due_date',
  Header: 'Due date',
  accessor: (item: TodoTask) => item.due_date,
  type: 'date',
  width: 140
} as const;

const status = {
  id: 'status',
  Header: 'Task progress',
  Cell: ProgressDropdown,
  accessor: (item: TodoTask) => item,
  width: 150,
  toCsv: (item: TodoTask) => item?.status?.label
};

const priority = {
  id: 'priority_id',
  type: 'value',
  Header: 'Priority',
  accessor: (item: TodoTask) => item.priority,
  toCsv: (item: TodoTask) => item?.priority?.label
} as const;

export function useTodoColumns(): Columns {
  const whereabouts = useWhereabouts();
  const tab = whereabouts.hashQuery?.tab;

  if (tab === 'closed') {
    return [summary, relatesTo, closedDate, dueDate, managedBy, priority];
  }

  return [
    summary,
    relatesTo,
    followUpDate,
    dueDate,
    status,
    managedBy,
    priority
  ];
}
