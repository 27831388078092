import React from 'react';
import { ContactFlag } from 'view/components/record-flags/contact-flag';
import { Contact } from 'src/modules/contacts/types/contact-types';
import { EntryList } from '../components/entry-list';
import { EntryLink } from '../components/entry-link';

export interface DepositInsuredByProps {
  insured_by_contact: Contact;
}

export function DepositInsuredBy({
  insured_by_contact
}: DepositInsuredByProps) {
  return (
    <div>
      <EntryList>
        <EntryLink type='contact' id={insured_by_contact.id}>
          <ContactFlag
            contact={insured_by_contact}
            description={
              insured_by_contact.is_security_deposit_authority
                ? 'Deposit Authority'
                : undefined
            }
          />
        </EntryLink>
      </EntryList>
    </div>
  );
}
