import React, { useCallback } from 'react';

import { RecordDialog } from 'view/components/record-screen/dialog/dialog';
import { DialogContentConfig } from 'view/components/record-screen/dialog/types';

import { useModelActions } from '@rexlabs/model-generator';
import { RecordType } from 'data/models/types';
import dayjs from 'dayjs';
import { trustJournalEntryModel } from 'src/modules/trust-journal-entries/models/trust-journal-entries';
import { TrustJournalEntry } from 'src/modules/trust-journal-entries/types/trust-journal-entry-types';
import { getRecordTypeName } from 'utils/records/get-record-type-name';
import { RecordSubmitHandler } from 'view/components/record-screen/utils';
import { bankTransactionDetailsBlock } from '../blocks/bank-transaction-details';

const content: DialogContentConfig = [
  {
    id: 'basics',
    label: 'Basics',
    blocks: [bankTransactionDetailsBlock]
  }
];

interface CreateBankTransactionDialogProps {
  updateListId?: string;
  onClose?: () => void;
  onCreate: (dateOf: Date) => Promise<void>;
  trustJournalEntries: TrustJournalEntry[];
  recordType: RecordType;
}
export function CreateBankTransactionDialog({
  updateListId,
  onCreate,
  onClose,
  trustJournalEntries,
  recordType
}: CreateBankTransactionDialogProps) {
  const { refreshList } = useModelActions(trustJournalEntryModel);
  const handleSubmit: RecordSubmitHandler = useCallback(
    async ({ values }) => {
      await onCreate(values.date_of);

      if (updateListId) {
        await refreshList({ id: updateListId });
      }

      return true;
    },
    [refreshList, updateListId, onCreate]
  );

  const initialData = {
    date_of: dayjs().format('YYYY-MM-DD')
  };

  return (
    <RecordDialog
      onClose={onClose}
      data={initialData}
      blockProps={{ trustJournalEntries, recordType }}
      title={`Create ${getRecordTypeName(recordType)}`}
      handleSubmit={handleSubmit}
      content={content}
      submitLabel={`Create ${getRecordTypeName(recordType)}`}
    />
  );
}
