import { useToast } from 'view/components/@luna/notifications/toast';
import { RecordObject } from 'data/models/types';
import { useErrorDialog } from '@rexlabs/dialog';
import { getRecordTitle } from 'utils/records/get-record-title';
import { api } from 'utils/api/api-client';
import { SingleActionDeclaration } from 'src/modules/common/actions/types/action-declaration-types';

export type ReportFormat = 'CSV';

/**
 * @see useModelDownloadAction
 */
export const useAsyncReportDownloadAction = () => {
  const { addToast } = useToast();
  const { open } = useErrorDialog();

  return function (
    label: string,
    url: string,
    record: RecordObject,
    format: ReportFormat
  ): SingleActionDeclaration {
    return {
      id: format,
      group: 'reporting',
      label,
      handleAction: async () => {
        addToast({
          title: getRecordTitle(record),
          description: `Your ${format} is being generated, and you will receive the download link in a notification`,
          color: 'information'
        });
        try {
          await api.get(url);
        } catch (e) {
          open(e);
        }
      }
    };
  };
};
