import React from 'react';

import { api } from 'utils/api/api-client';
import { RecordSubmitHandler } from 'view/components/record-screen/utils';
import { PropertyAreaForm } from 'src/modules/property-areas/property-areas/types/PropertyAreaForm';
import { useToast } from '@rexlabs/notifications';
import { useQueryClient } from 'react-query';
import { useModelActions } from '@rexlabs/model-generator';
import { tasksModel } from '../../common/models/tasks-model';
import { InspectionTask } from '../types/InspectionTask';

export function useCreateInspectionAreaSubmitHandler(task: InspectionTask) {
  const { addToast } = useToast();
  const queryClient = useQueryClient();
  const { refreshItem } = useModelActions(tasksModel);

  const onSubmit: RecordSubmitHandler<PropertyAreaForm> = async ({
    values
  }) => {
    const requests = getTransformInspectionAreaFormToPipeline({
      values,
      task
    });

    const response = await api.post('request-pipelines', { requests });

    const data = response.data.inspection_area;

    addToast({
      description: (
        <>
          Successfully created <b>{data.name}</b>
        </>
      )
    });

    queryClient.invalidateQueries(['task', task.id]);
    refreshItem({ id: task.id });

    return data;
  };

  return onSubmit;
}

function getTransformInspectionAreaFormToPipeline({
  values,
  task
}: {
  values: PropertyAreaForm;
  task: InspectionTask;
}) {
  const items = values.items?.filter((item) => item.name != null);

  const inspectionAreaItems = items?.map((item, index) => {
    return {
      id: `inspection_area_items_${index}`,
      method: 'POST',
      path: '/api/v1/inspection-area-items',
      json: {
        name: item.name,
        property_area_item: null,
        inspection_area: {
          id: '{{$.inspection_area.id}}'
        }
      }
    };
  });

  const itemOrderArray = items?.map((item, index) => {
    return `{{$.inspection_area_items_${index}.id}}`;
  });

  return [
    {
      id: 'inspection_area',
      method: 'POST',
      path: '/api/v1/inspection-areas',
      json: {
        name: values.name,
        task: {
          id: task.id
        }
      }
    },
    ...(inspectionAreaItems || []),
    {
      id: 'inspection_areas_item_order',
      method: 'PATCH',
      path: '/api/v1/inspection-areas/{{$.inspection_area.id}}',
      json: {
        item_order: itemOrderArray
      }
    },
    {
      id: 'inspection_area_order',
      method: 'PATCH',
      path: `/api/v1/tasks/inspection-reports/${task.id}`,
      json: {
        area_order: Array.isArray(task.details?.area_order)
          ? task.details!.area_order.concat('{{$.inspection_area.id}}')
          : ['{{$.inspection_area.id}}']
      }
    }
  ];
}
