import { useMemo } from 'react';

import { useWorkInProgress } from 'view/components/work-in-progress';

import { SingleActionDeclaration } from 'src/modules/common/actions/types/action-declaration-types';

type UseFilteredActionsArgs = {
  actions: SingleActionDeclaration[];
  hideViewAction?: boolean;
};

export function useFilteredActions({
  actions,
  hideViewAction = true
}: UseFilteredActionsArgs) {
  const [showWorkInProgress] = useWorkInProgress();

  return useMemo(
    () =>
      actions?.filter((action) => {
        // Filter out work in progress actions when necessary
        // + filter out the `view` action if we're on this
        // records main record screen already
        return (
          (showWorkInProgress || !action?.workInProgress) &&
          (action?.id !== 'view' || !hideViewAction)
        );
      }),
    [actions, showWorkInProgress, hideViewAction]
  );
}
