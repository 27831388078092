import { OwnershipFee } from 'data/models/entities/ownerships/fees/ownership-fees';
import { PropertyOwnershipFee } from 'data/models/entities/financials/property-ownerships/property-ownership-fee';
import { AgencyFee } from 'src/modules/agency-fees/types/agency-fee-types';
import { isFeeSame } from './is-rate-same';
import { isSameTax } from './is-same-tax';

export function isEntityValuesSameAsAgencyFee(
  agencyFee: AgencyFee,
  entityFee: OwnershipFee | PropertyOwnershipFee
) {
  const amountIsSame = isFeeSame(agencyFee.fee_amount, entityFee.fee_amount);
  const taxIsSame = isSameTax(agencyFee.tax_type!, entityFee.tax_type!);
  const isTaxIncludedIsSame =
    agencyFee.is_tax_included === entityFee.is_tax_included;

  return amountIsSame && taxIsSame && isTaxIncludedIsSame;
}
