import React from 'react';
import Box from '@rexlabs/box';
import { StyleSheet, useStyles } from '@rexlabs/styling';
import ArrowBranchIcon from 'view/components/icons/arrow-branch';
import { Disclosure } from '@headlessui/react';
import { LinkButton } from '@rexlabs/button';
import ChevronDownIcon from 'view/components/icons/chevron-down';
import { ValueListValue } from 'data/models/types';
import { ChecklistTemplateItem } from '../../types/ChecklistTemplate';
import { Identifier } from './checklist-template-item';

const defaultStyles = StyleSheet({
  container: {
    padding: '12px 16px',
    borderRadius: 8,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#E7E8E9'
  },
  grey: {
    background: '#eee'
  },
  white: {
    background: 'white'
  },
  expandCollapseButton: {
    color: '#4A4A4A',
    '&:hover, &:link': {
      textDecoration: 'none'
    }
  }
});

export interface ChecklistTemplateItemBranchProps {
  triggeredByItem: ChecklistTemplateItem;
  outcomeValue: ValueListValue<string>;
  grey?: boolean;
  renderBranchFooter?: () => React.ReactNode;
}

export const ChecklistTemplateItemBranch: React.FC<ChecklistTemplateItemBranchProps> = ({
  triggeredByItem,
  outcomeValue,
  children,
  grey,
  renderBranchFooter
}) => {
  const s = useStyles(defaultStyles);

  return (
    <Disclosure
      as={Box}
      {...s('container', grey ? 'grey' : 'white')}
      defaultOpen
    >
      {({ open }) => (
        <>
          <Box
            display='flex'
            flexDirection={'row'}
            justifyContent='space-between'
            alignItems='center'
          >
            <Box display='flex' flexDirection='row' alignItems='center' gap={4}>
              <ArrowBranchIcon />
              If outcome from <Identifier item={triggeredByItem} /> is{' '}
              {outcomeValue.label}
            </Box>
            <Disclosure.Button
              as={LinkButton}
              IconLeft={open ? CollapseIcon : ExpandIcon}
              {...s('expandCollapseButton')}
            >
              {`${open ? 'Collapse' : 'Expand'} path`}
            </Disclosure.Button>
          </Box>
          <Disclosure.Panel>
            {children}
            {renderBranchFooter && renderBranchFooter()}
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
};

// There aren't first-class icons for this, so we will just compose them.
const ExpandIcon = () => {
  return (
    <Box display='flex' flexDirection='column'>
      <ChevronDownIcon size='s' style={{ rotate: '180deg' }} />
      <ChevronDownIcon size='s' />
    </Box>
  );
};

const CollapseIcon = () => {
  return (
    <Box display='flex' flexDirection='column'>
      <ChevronDownIcon size='s' />
      <ChevronDownIcon size='s' style={{ rotate: '180deg' }} />
    </Box>
  );
};
