import React from 'react';
import { BlockConfig } from 'view/components/record-screen/types';
import { Grid } from 'view/components/@luna/form/grid';
import { Portfolio } from 'src/modules/portfolios/models/portfolios-model';
import { Field } from '@rexlabs/form';
import { TextInput } from '@rexlabs/text-input';
import { UserSelect } from 'view/components/inputs/selects/v4-selects/user';

export const detailsBlock: BlockConfig<Portfolio> = {
  id: 'details',
  validate: {
    definitions: {
      portfolio_name: { name: 'portfolio name', rules: 'required' },
      primary_portfolio_manager: {
        name: 'primary portfolio manager',
        rules: 'required'
      }
    }
  },
  Edit: () => (
    <Grid columns={2}>
      <Field name='portfolio_name' label='Portfolio name' Input={TextInput} />
      <Field
        name='primary_portfolio_manager'
        label='Primary portfolio manager'
        Input={UserSelect}
        id='user'
      />
    </Grid>
  )
};
