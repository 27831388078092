import { useConfirmationDialog } from '@rexlabs/dialog';
import { ActionDeclaration } from 'src/modules/common/actions/types/action-declaration-types';
import { useModelActions } from '@rexlabs/model-generator';

import { useToast } from 'view/components/@luna/notifications/toast';
import React from 'react';
import { tasksModel } from 'src/modules/tasks/common/models/tasks-model';

import { Task } from 'src/modules/tasks/common/types/Task';
import { getTaskIncludes } from 'src/modules/tasks/common/utils/get-task-includes';

export function useGetRemoveTaskLinkAction({ parentTask }) {
  const { open } = useConfirmationDialog();
  const { updateItem, refreshLists } = useModelActions(tasksModel);
  const { addToast } = useToast();

  return ({ task }: { task: Task<any> }): ActionDeclaration => ({
    id: 'remove-task-link',
    group: 'task-link',
    label: 'Remove link',
    handleAction: () => {
      open({
        title: 'Remove link',
        message: (
          <div>
            Do you want to remove the link to the task{' '}
            <strong>{task?.summary}</strong>?
          </div>
        ),
        confirmText: 'Remove link',
        onConfirm: async () => {
          const newTaskLinks =
            parentTask?.task_links?.data
              ?.filter?.((item) => item?.child_task?.id !== task?.id)
              ?.map((item) => {
                return {
                  child_task: item?.child_task,
                  relation: item?.relation
                };
              }) || [];

          await updateItem({
            id: parentTask?.id,
            data: {
              task_links: newTaskLinks
            },
            args: {
              include: getTaskIncludes(parentTask.type.id)
            }
          });
          await refreshLists();

          addToast({
            description: (
              <>
                Task link <b>successfully</b> removed from {parentTask.summary}
              </>
            )
          });
        }
      });
    }
  });
}
