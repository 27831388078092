import { DisbursementFrequency } from 'src/modules/disbursements/types/frequency';
import { DisbursementFrequencyFormData } from '../../disbursements/types/DisbursementFrequencyFormData';

export function mapDisbursementFrequencyToDisbursementFrequencyFormData(
  disbursementFrequency: DisbursementFrequency
): DisbursementFrequencyFormData {
  const {
    is_disbursed_on_balance_amount,
    on_balance_amount,
    on_frequency_count,
    on_frequency_next_disbursement_date,
    on_frequency_unit,
    is_disbursed_on_frequency,
    preset
  } = disbursementFrequency;
  return {
    is_disbursed_on_balance_amount,
    disbursement_frequency_count_and_unit:
      on_frequency_unit || on_frequency_count
        ? {
            on_frequency_unit: on_frequency_unit || null,
            on_frequency_count: on_frequency_count || null
          }
        : null,
    on_balance_amount: on_balance_amount || null,
    on_frequency_next_disbursement_date:
      on_frequency_next_disbursement_date || null,
    is_disbursed_on_frequency,
    preset
  };
}
