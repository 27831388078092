import React, { useMemo } from 'react';
import { query, useEntityListQuery } from '@rexlabs/model-generator';
import { toQuri } from '@rexlabs/quri';
import { tenancyModel, Tenancy } from 'data/models/entities/tenancies';
import { getRecordLinkProps } from 'view/components/record-link/get-record-link-props';
import { Columns, RecordTable } from 'view/components/table';
import { TagCell } from '@rexlabs/table';
import { Tag } from '@rexlabs/tag';
import { useFeatureFlags } from 'view/components/@luna/feature-flags';
import { FLAGS } from 'utils/feature-flags';

interface TenanciesList {
  items: Tenancy[];
  isLoading: boolean;
  contactId: string;
}

interface TenanciesListProps {
  contactId: string;
}

function useGetColumns() {
  const { hasFeature } = useFeatureFlags();

  const hasGuarantorAndOccupierUpdates = hasFeature(
    FLAGS.GUARANTOR_AND_OCCUPIER_UPDATES
  );

  return ({ contactId }: { contactId }): Columns<Tenancy> => [
    {
      id: 'reference',
      Header: 'Reference',
      width: 140,
      accessor: (item) => item.record_reference
    },
    {
      id: 'name',
      Header: 'Name',
      accessor: (item) => item.display_name
    },
    ...(hasGuarantorAndOccupierUpdates
      ? [
          {
            id: 'role',
            Cell: ({ value }) => {
              return (
                <TagCell>
                  <Tag>
                    {(value.related_contacts?.data || []).find(
                      (tenancyRelatedContact) =>
                        tenancyRelatedContact?.contact?.id === contactId
                    )?.role?.label || 'Tenant'}
                  </Tag>
                </TagCell>
              );
            },
            width: 150,
            accessor: (item) => item
          }
        ]
      : [])
  ];
}

const getRowLinkProps = ({ item }) =>
  getRecordLinkProps({ type: 'tenancy', id: item?.id });

const getTenanciesQuery = ({ contactId }) => {
  const q = toQuri([
    {
      field: 'contact_id',
      op: '==',
      value: contactId
    }
  ]);

  return query`{
    ${tenancyModel} (q: ${q}) {
      id
      name
      reference
      tenants
      related_contacts
    }
  }`;
};

export function TenanciesList({ contactId }: TenanciesListProps) {
  const query = useMemo(() => getTenanciesQuery({ contactId }), [contactId]);
  const { status, data } = useEntityListQuery(query);

  const getColumns = useGetColumns();

  return (
    <RecordTable
      id='tenancies-list'
      columns={getColumns({ contactId })}
      items={data ?? []}
      isLoading={status === 'loading'}
      getRowLinkProps={getRowLinkProps}
      getRowId={(item) => item?.id}
    />
  );
}
