export const TRUST_LEDGERS_ROUTES = {
  TRUST_LEDGERS: {
    config: {
      path: '/trust-ledgers',
      Component: require('./screens/trust-ledgers-list').TrustLedgersList
    },
    CREATE: {
      config: {
        path: '/trust-ledgers/create-new',
        Component: require('./dialogs/create-trust-ledger')
          .CreateTrustLedgerDialog
      }
    }
  },

  TRUST_LEDGER: {
    config: {
      path: '/trust-ledger/:trustLedgerId',
      Component: require('./screens/trust-ledger-details')
        .TrustLedgerDetailsScreen
    }
  }
};
