import React from 'react';
import { GhostButton } from '@rexlabs/button';
import { StylesProvider } from '@rexlabs/styling';

type ButtonIntent = 'allocate' | 'unallocate';

const allocateTokens = {
  button: {
    ghost: {
      color: ({ token }) => token('color.textStyle.success.idle.default')
    }
  }
};

const unallocateTokens = {
  button: {
    ghost: {
      color: ({ token }) => token('color.textStyle.danger.idle.default')
    }
  }
};

const buttonIntentMap: Record<
  ButtonIntent,
  { label: string; tokens: Record<string, any> }
> = {
  allocate: {
    label: 'Allocate',
    tokens: allocateTokens
  },
  unallocate: {
    label: 'Unallocate',
    tokens: unallocateTokens
  }
};

export function AllocateFundsToggleButton({
  intent,
  onClick
}: {
  intent: 'allocate' | 'unallocate';
  onClick: () => void;
}) {
  const { label, tokens } = buttonIntentMap[intent];
  return (
    <StylesProvider tokens={tokens}>
      <GhostButton size='s' onClick={onClick}>
        {label}
      </GhostButton>
    </StylesProvider>
  );
}
