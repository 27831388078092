import React from 'react';
import { Value, ValuedValueProps } from '.';

export type PreformattedValueProps = Omit<ValuedValueProps, 'value'> & {
  value?: string;
};

export function PreformattedValue({ value }: PreformattedValueProps) {
  return (
    <Value
      label='Details'
      value={
        <pre
          style={{
            whiteSpace: 'pre-wrap',
            wordBreak: 'keep-all'
          }}
        >
          {JSON.stringify(JSON.parse(value || '{}'), null, 2)}
        </pre>
      }
    />
  );
}
