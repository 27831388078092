import { ActionDeclaration } from 'src/modules/common/actions/types/action-declaration-types';
import { useGetUpdatePropertyAreaTypeAction } from 'src/modules/property-areas/property-area-types/hooks/action-declarations/use-get-update-property-area-type-action';
import { useGetDeletePropertyAreaTypeAction } from 'src/modules/property-areas/property-area-types/hooks/action-declarations/use-get-delete-property-area-type-action';
import { useGetDuplicatePropertyAreaTypeAction } from 'src/modules/property-areas/property-area-types/hooks/action-declarations/use-get-duplicate-property-area-type-action';
import { PropertyAreaType } from '../../types/PropertyAreaType';

export function useGetPropertyAreaTypeActions() {
  const getUpdatePropertyAreaTypeAction = useGetUpdatePropertyAreaTypeAction();
  const getDeletePropertyAreaTypeAction = useGetDeletePropertyAreaTypeAction();
  const getDuplicatePropertyAreaTypeAction = useGetDuplicatePropertyAreaTypeAction();

  return (propertyAreaType?: PropertyAreaType): ActionDeclaration[] => {
    if (!propertyAreaType) return [];

    const actions = [
      getUpdatePropertyAreaTypeAction(propertyAreaType),
      getDuplicatePropertyAreaTypeAction(propertyAreaType)
    ];

    if (!propertyAreaType.system_purpose) {
      actions.push(getDeletePropertyAreaTypeAction(propertyAreaType));
    }
    return actions;
  };
}
