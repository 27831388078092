import React, { useState } from 'react';
import { get, isEqual, set } from 'lodash';
import flatten from 'flat';

import { useDialog } from '@rexlabs/dialog';
import { Box } from '@rexlabs/box';
import { Heading } from '@rexlabs/text';
import {
  ButtonGroup,
  GhostButton,
  GhostIconButton,
  OutlineButton,
  PrimaryButton
} from '@rexlabs/button';
import { HiddenField, ReactForms, FormPassthroughProps } from '@rexlabs/form';
import { StatusAverageTag } from '@rexlabs/tag';

import { BlockConfig } from 'view/components/record-screen/types';
import { Divider } from 'view/components/@luna/divider/divider';
import { getRentScheduleEntryWithId } from 'src/modules/property-tenancies/utils/get-rent-schedule-with-ids';
import { Card, CardContent } from 'view/components/@luna/card';
import { Message } from 'view/components/@luna/message';
import EditIcon from 'view/components/icons/edit';
import { useHandlePropertyDetailsSubmit } from 'src/modules/properties/hooks/use-property-details-handle-submit';

import { RentScheduleEntry } from 'src/modules/property-tenancies/types/property-tenancy-types';
import { RentScheduleTable } from 'src/modules/rent/components/rent-schedule-table';
import { CreateRentScheduleDialog } from 'src/modules/rent/dialogs/create-rent-schedule-entry';
import { EditRentScheduleDialog } from 'src/modules/rent/dialogs/edit-rent-schedule-entry';
import { augmentRentSchedulePartials } from 'src/modules/rent/utils/augment-rent-schedule-partial';
import { recalculateAmounts } from 'src/modules/rent/utils/recalculate-amounts';

import { RentScheduleDetails } from '../components/rent-schedule-details';
import { RentScheduleDetailsForm } from '../components/rent-schedule-details-form';
import { FlattenedProperty } from '../../properties/types/property-types';
import { propertyRentScheduleContext } from '../../properties/screens/property-details';

type RentScheduleWithId = RentScheduleEntry & {
  id: string;
};

type PropertyTenancyWithRentScheduleIds = Omit<
  FlattenedProperty,
  'selected_property_tenancy.rent_schedule'
> & {
  selected_property_tenancy: {
    rent_schedule: RentScheduleWithId[];
  };
};

type RentScheduleFormProps = Pick<
  FormPassthroughProps<PropertyTenancyWithRentScheduleIds, any>,
  | 'values'
  | 'isValid'
  | 'setFieldValue'
  | 'resetForm'
  | 'isSubmitting'
  | 'submitForm'
> & {
  isEditMode: boolean;
  toggleEditMode: (newState: boolean) => void;
  data: PropertyTenancyWithRentScheduleIds;
};

/**
 * Rather than use the standard block config, we've had to use a custom card to handle the layout. Previously, the view
 * mode didn't show the empty table, until you changed to edit mode. This was a little confusing, so we wanted to show
 * that empty state in view mode as well. The user should be able to click on the add button which would change to edit mode
 * and let them add a rent schedule entry.
 * Unfortunately, the standard block config couldn't handle setting the values in the form from view mode, which had
 * to be changed into edit. Using a custom card, we can handle this ourselves.
 */
export const rentScheduleBlock: BlockConfig<
  PropertyTenancyWithRentScheduleIds,
  any,
  PropertyTenancyWithRentScheduleIds
> = {
  id: 'rent-schedule',
  title: 'Rent details',
  Card: ({ data }) => {
    const [editMode, setEditMode] = useState(false);
    const toggleEditMode = (newState: boolean) => setEditMode(newState);
    const handleSubmit = useHandlePropertyDetailsSubmit(data.id);

    return (
      <ReactForms
        initialValues={data}
        handleSubmit={async (values, helpers) => {
          // NOTE: this is a hack to get around the typing in the form library - this is actually a
          // key in the helpers object. We need to update the vivid library to support this.
          // https://rexsoftware.atlassian.net/browse/ALFIE-2608
          const isChanged = helpers['isChanged'];
          const changedValues = Object.keys(flatten(isChanged)).reduce(
            (acc, name) =>
              // HACK: we want to send the field up as changed if it is changed
              // or if it is part of a field array with any changes is it
              get(isChanged, name) || name.match(/\.[0-9]+\./)
                ? set(acc, name, get(values, name))
                : acc,
            {}
          );

          await handleSubmit({
            values,
            changedValues
          });

          return toggleEditMode(false);
        }}
      >
        {({
          values,
          setFieldValue,
          isValid,
          resetForm,
          submitForm,
          isSubmitting
        }) => (
          <RentScheduleForm
            isEditMode={editMode}
            toggleEditMode={toggleEditMode}
            data={data}
            values={values}
            setFieldValue={setFieldValue}
            isValid={isValid}
            resetForm={resetForm}
            submitForm={submitForm}
            isSubmitting={isSubmitting}
          />
        )}
      </ReactForms>
    );
  }
};

const RentScheduleForm = ({
  values,
  data,
  setFieldValue,
  isValid,
  resetForm,
  submitForm,
  isSubmitting,
  toggleEditMode,
  isEditMode
}: RentScheduleFormProps) => {
  const showRentScheduleTable =
    values?.selected_property_tenancy?.rent_schedule?.length || 0 > 0;

  const [showInitialPaidToInfo, setShowInitialPaidToInfo] = useState(
    !!values?.selected_property_tenancy?.initial_paid_to_date ||
      !!values?.selected_property_tenancy?.initial_paid_to_surplus
  );

  const createDialog = useDialog(CreateRentScheduleDialog);
  const editDialog = useDialog(EditRentScheduleDialog);

  const adjustFirstAgreementStartDate = (
    rentScheduleEntry: RentScheduleEntry
  ) => {
    const entryDate = new Date(
      rentScheduleEntry.effective_from_date!
    ).getTime();
    const firstAgreementDate = values!.selected_property_tenancy
      ?.agreement_first_start_date
      ? new Date(
          values.selected_property_tenancy.agreement_first_start_date
        ).getTime()
      : 0;

    if (entryDate < firstAgreementDate) {
      setFieldValue?.(
        'selected_property_tenancy.agreement_first_start_date',
        rentScheduleEntry.effective_from_date
      );
    }
  };

  const addToRentSchedule = (rentScheduleEntry: RentScheduleEntry) => {
    const fullRentSchedule = augmentRentSchedulePartials([
      ...(values?.selected_property_tenancy?.rent_schedule || []),
      getRentScheduleEntryWithId(rentScheduleEntry)
    ]);

    adjustFirstAgreementStartDate(rentScheduleEntry);
    setFieldValue?.(
      'selected_property_tenancy.rent_schedule',
      fullRentSchedule
    );

    if (
      fullRentSchedule.length === 1 &&
      !values?.selected_property_tenancy?.agreement_first_start_date
    ) {
      setFieldValue?.(
        'selected_property_tenancy.agreement_first_start_date',
        rentScheduleEntry.effective_from_date
      );
    }
  };

  const deleteEntryFromRentSchedule = (entry: any) => {
    const newRentSchedule = values?.selected_property_tenancy?.rent_schedule?.filter(
      (rentScheduleEntry) =>
        (rentScheduleEntry as RentScheduleWithId).id !== entry.id
    );

    setFieldValue?.('selected_property_tenancy.rent_schedule', newRentSchedule);
  };

  const editEntry = (
    editedRentScheduleEntry: RentScheduleEntry & {
      id: string;
    }
  ) => {
    adjustFirstAgreementStartDate(editedRentScheduleEntry);
    setFieldValue?.(
      'selected_property_tenancy.rent_schedule',
      (rent_schedule) =>
        rent_schedule.map((rentScheduleEntry) => {
          return rentScheduleEntry.id === editedRentScheduleEntry.id
            ? recalculateAmounts(editedRentScheduleEntry)
            : rentScheduleEntry;
        })
    );
  };

  const toggleShowInitialPaidToInfo = () => {
    if (showInitialPaidToInfo) {
      setFieldValue?.('selected_property_tenancy.initial_paid_to_date', null);
      setFieldValue?.('selected_property_tenancy.initial_paid_to_surplus', 0);
    }
    setShowInitialPaidToInfo(!showInitialPaidToInfo);
  };

  const rentSchedule = React.useContext(propertyRentScheduleContext);

  const showUnSavedChanges =
    isEditMode &&
    isValid &&
    // While we do have an isDirty prop from the form, it only works for the form fields.
    // As we have a table here that is not a form, we need to check if the rent schedule has changed,
    // so we just check to see if this whole object has changed.
    !isEqual(
      values?.selected_property_tenancy,
      data?.selected_property_tenancy
    );

  return (
    <Card id='block-rent-schedule'>
      <CardContent>
        <Box
          flexDirection='row'
          alignItems='center'
          justifyContent='space-between'
        >
          <Box flexDirection='row' alignItems='center' sx='1.2rem' flex={1}>
            <Heading level={3}>Rent details</Heading>

            {showUnSavedChanges && (
              <StatusAverageTag>Unsaved changes</StatusAverageTag>
            )}
          </Box>
          <Box ml='2.4rem'>
            {isEditMode ? (
              <ButtonGroup>
                <GhostButton
                  onClick={() => {
                    resetForm();
                    toggleEditMode(!isEditMode);
                  }}
                >
                  Cancel
                </GhostButton>
                <PrimaryButton
                  isLoading={isSubmitting}
                  type='submit'
                  onClick={() => submitForm()}
                >
                  Save
                </PrimaryButton>
              </ButtonGroup>
            ) : (
              <span className='edit-button'>
                <GhostIconButton
                  id={`edit-button`}
                  aria-label='Edit block'
                  Icon={EditIcon}
                  onClick={() => toggleEditMode(!isEditMode)}
                />
              </span>
            )}
          </Box>
        </Box>

        <Box sy='4.8rem'>
          {!isEditMode && (
            <>
              <RentScheduleDetails details={data!.selected_property_tenancy} />

              <Divider />

              {showRentScheduleTable && (
                <>
                  <div>
                    <Box height='4rem' alignItems='center'>
                      <Heading level={4}>Rent charges</Heading>
                    </Box>

                    <Box mt='1.2rem'>
                      <RentScheduleTable
                        rentSchedule={
                          data?.selected_property_tenancy?.rent_schedule
                        }
                      />
                    </Box>
                  </div>
                </>
              )}
            </>
          )}

          {isEditMode && (
            <>
              <RentScheduleDetailsForm
                toggleShowInitialPaidToInfo={toggleShowInitialPaidToInfo}
                showInitialPaidToInfo={showInitialPaidToInfo}
              />

              <Divider />

              {showRentScheduleTable && (
                <div>
                  <Box justifyContent='space-between' alignItems='center'>
                    <Heading level={4}>Rent charges</Heading>
                    <OutlineButton
                      onClick={() =>
                        createDialog.open({
                          onCreate: addToRentSchedule,
                          rentSchedule
                        })
                      }
                    >
                      Add rent charge
                    </OutlineButton>
                  </Box>

                  <Box mt='1.2rem'>
                    <RentScheduleTable
                      rentSchedule={
                        values?.selected_property_tenancy?.rent_schedule
                      }
                      onEntryDelete={deleteEntryFromRentSchedule}
                      onEntryEdit={(entry, index) =>
                        editDialog.open({
                          isFirstEntry:
                            index ===
                            (values?.selected_property_tenancy?.rent_schedule
                              ?.length ?? 0) -
                              1,
                          rentScheduleEntry: entry,
                          onSave: editEntry
                        })
                      }
                    />
                  </Box>
                </div>
              )}
            </>
          )}

          {!showRentScheduleTable && (
            <Message
              grey
              title='Rent charges'
              actions={[
                {
                  label: 'Add rent charge',
                  intent: 'primary',
                  handleAction: () => {
                    toggleEditMode(true);
                    createDialog.open({
                      onCreate: addToRentSchedule,
                      isFirstEntry: true,
                      firstAgreementStartDate: values?.selected_property_tenancy
                        ?.agreement_first_start_date
                        ? values?.selected_property_tenancy
                            ?.agreement_first_start_date
                        : undefined
                    });
                  }
                }
              ]}
            >
              Add details of the current, future or past rent charges. You can
              provide the charge frequency, the effective start date and details
              of where the rent will be paid to.
            </Message>
          )}
          <HiddenField name={'selected_property_tenancy.rent_schedule'} />
        </Box>
      </CardContent>
    </Card>
  );
};
