import React, { useCallback } from 'react';
import dayjs from 'dayjs';

import { Field } from '@rexlabs/form';
import { TextInput } from '@rexlabs/text-input';
import { Body } from '@rexlabs/text';
import Box from '@rexlabs/box';
import { useToken } from '@rexlabs/styling';

import { Contact, Tax } from 'src/modules/contacts/types/contact-types';
import { useSettings } from 'src/modules/settings/hooks/useSettings';

import { BlockConfig } from 'view/components/record-screen/types';
import { Grid } from 'view/components/@luna/form/grid';
import { Value } from 'view/components/values';
import { BooleanSelect } from 'view/components/inputs/selects/boolean';
import { DateInput } from 'view/components/@luna/inputs/date-input/date-input';
import { DateValue } from 'view/components/values/date';
import { PercentageInput } from 'view/components/inputs/percentage-input';

type TaxFieldName = keyof Omit<Tax, 'created_at' | 'updated_at'>;

/**
 * NOTE: The reason I have implemented this block this way is because the BE is
 * incomplete at time of writing this, so I'm sure that the field names will change.
 * Doing it this way means that the field names are typed and can be changed in one place.
 */
export const nonResidentField: TaxFieldName = 'non_resident';
export const nonResidentWithholdTaxField: TaxFieldName =
  'non_resident_withhold_tax';
export const nonResidentTaxRateField: TaxFieldName = 'non_resident_tax_rate';
export const nonResidentStartDateField: TaxFieldName =
  'non_resident_start_date';
export const nonResidentEndDateField: TaxFieldName = 'non_resident_end_date';
export const nonResidentExemptionApprovalReferenceField: TaxFieldName =
  'non_resident_exemption_approval_reference';
export const nonResidentExemptionApprovalStartDateField: TaxFieldName =
  'non_resident_exemption_approval_start_date';

export function getFieldId(field: TaxFieldName) {
  return `tax.${field}`;
}

export function getFieldLabel(field: TaxFieldName) {
  switch (field) {
    case nonResidentField:
      return 'Contact is an NRL (non-resident landlord)';
    case nonResidentWithholdTaxField:
      return 'Withhold HMRC tax';
    case nonResidentTaxRateField:
      return 'Non-resident tax rate';
    case nonResidentStartDateField:
      return 'Non-resident start date';
    case nonResidentEndDateField:
      return 'Non-resident end date';
    case nonResidentExemptionApprovalReferenceField:
      return 'HMRC approval reference';
    case nonResidentExemptionApprovalStartDateField:
      return 'HMRC approval start date';
    default:
      return 'Unknown field';
  }
}

const validation = {
  [getFieldId(nonResidentField)]: { rules: 'required' },
  [getFieldId(nonResidentWithholdTaxField)]: { rules: 'required' },
  [getFieldId(nonResidentTaxRateField)]: { rules: 'between:1,100' }
};

export const hmrcTaxBlock: BlockConfig<Contact, any, Contact> = {
  id: 'hmrc-tax',
  title: 'Tax',
  validate: { definitions: { ...validation } },
  View: ({ data }) => {
    const { 'non-resident-tax-rate': defaultTaxRate } = useSettings();

    return (
      <Grid columns={1}>
        <Grid columns={2}>
          <Value
            testId={getFieldId(nonResidentField)}
            label={getFieldLabel(nonResidentField)}
            value={data?.tax?.[nonResidentField] ? 'Yes' : 'No'}
          />
          <Value
            testId={getFieldId(nonResidentWithholdTaxField)}
            label={getFieldLabel(nonResidentWithholdTaxField)}
            value={data?.tax?.[nonResidentWithholdTaxField] ? 'Yes' : 'No'}
          />

          <Value
            testId={getFieldId(nonResidentTaxRateField)}
            label={getFieldLabel(nonResidentTaxRateField)}
            value={`${
              (data?.tax?.[nonResidentTaxRateField] || defaultTaxRate) * 100
            }%`}
          />
        </Grid>
        <Grid columns={2}>
          <DateValue
            testId={getFieldId(nonResidentStartDateField)}
            label={getFieldLabel(nonResidentStartDateField)}
            value={data?.tax?.[nonResidentStartDateField]}
          />
          <DateValue
            testId={getFieldId(nonResidentEndDateField)}
            label={getFieldLabel(nonResidentEndDateField)}
            value={data?.tax?.[nonResidentEndDateField]}
          />

          <Value
            testId={getFieldId(nonResidentExemptionApprovalReferenceField)}
            label={getFieldLabel(nonResidentExemptionApprovalReferenceField)}
            value={data?.tax?.[nonResidentExemptionApprovalReferenceField]}
          />
          <DateValue
            testId={getFieldId(nonResidentExemptionApprovalStartDateField)}
            label={getFieldLabel(nonResidentExemptionApprovalStartDateField)}
            value={data?.tax?.[nonResidentExemptionApprovalStartDateField]}
          />
        </Grid>
      </Grid>
    );
  },
  Edit: ({ values, setFieldValue, setValues }) => {
    const token = useToken();

    const { 'non-resident-tax-rate': defaultTaxRate } = useSettings();

    const isFieldsDisabled =
      !values?.tax?.non_resident_withhold_tax && !values?.tax?.non_resident;

    const handleIsNonResidentChange = useCallback(
      ({ target }) => {
        if (target.value === false) {
          setValues?.({
            tax: {
              [nonResidentField]: false,
              [nonResidentWithholdTaxField]: false,
              [nonResidentStartDateField]: null,
              [nonResidentEndDateField]: null,
              [nonResidentExemptionApprovalReferenceField]: null,
              [nonResidentExemptionApprovalStartDateField]: null,
              [nonResidentTaxRateField]: defaultTaxRate * 100
            }
          });
        } else {
          setFieldValue?.(
            getFieldId(nonResidentStartDateField),
            dayjs().format('YYYY-MM-DD')
          );
          setFieldValue?.(
            getFieldId(nonResidentWithholdTaxField),
            !!target.value
          );
        }
      },
      [values]
    );

    return (
      <Grid columns={1}>
        <Grid columns={2}>
          <Field<typeof BooleanSelect>
            name={getFieldId(nonResidentField)}
            id={getFieldId(nonResidentField)}
            label={getFieldLabel(nonResidentField)}
            Input={BooleanSelect}
            optional={false}
            onChange={handleIsNonResidentChange}
          />

          <Field<typeof BooleanSelect>
            name={getFieldId(nonResidentWithholdTaxField)}
            id={getFieldId(nonResidentWithholdTaxField)}
            label={getFieldLabel(nonResidentWithholdTaxField)}
            Input={BooleanSelect}
            optional={false}
            inputProps={{
              /**
               * HACK: The reason why we have the hack is because when the fom values change, it is
               * changing to disabled before it sets the values of the field.
               */
              ...(isFieldsDisabled
                ? {
                    disabled: true,
                    inputValue: 'No'
                  }
                : {})
            }}
          />

          <Field
            name={getFieldId(nonResidentTaxRateField)}
            id={getFieldId(nonResidentTaxRateField)}
            label={getFieldLabel(nonResidentTaxRateField)}
            Input={PercentageInput}
            inputProps={{ disabled: isFieldsDisabled }}
          />
        </Grid>

        <Grid columns={1}>
          <Box flexDirection='column' rowGap={token('spacing.xxs')}>
            <Body bold as='div'>
              Reference information
            </Body>
            <Body as='div'>
              The below information is for reference only, and does not have any
              impact on whether tax is withheld or not.
            </Body>
          </Box>
        </Grid>

        <Grid columns={2}>
          <Field<typeof DateInput>
            name={getFieldId(nonResidentStartDateField)}
            id={getFieldId(nonResidentStartDateField)}
            label={getFieldLabel(nonResidentStartDateField)}
            Input={DateInput}
            inputProps={{
              clearable: true,
              disabled: isFieldsDisabled
            }}
            optional={true}
          />
          <Field<typeof DateInput>
            name={getFieldId(nonResidentEndDateField)}
            id={getFieldId(nonResidentEndDateField)}
            label={getFieldLabel(nonResidentEndDateField)}
            Input={DateInput}
            inputProps={{
              clearable: true,
              disabled: isFieldsDisabled
            }}
            optional={true}
          />
        </Grid>

        <Grid columns={2}>
          <Field
            name={getFieldId(nonResidentExemptionApprovalReferenceField)}
            id={getFieldId(nonResidentExemptionApprovalReferenceField)}
            label={getFieldLabel(nonResidentExemptionApprovalReferenceField)}
            Input={TextInput}
            inputProps={{
              clearable: true,
              disabled: isFieldsDisabled
            }}
            optional={true}
          />
          <Field<typeof DateInput>
            name={getFieldId(nonResidentExemptionApprovalStartDateField)}
            id={getFieldId(nonResidentExemptionApprovalStartDateField)}
            label={getFieldLabel(nonResidentExemptionApprovalStartDateField)}
            Input={DateInput}
            inputProps={{
              clearable: true,
              disabled: isFieldsDisabled
            }}
            optional={true}
          />
        </Grid>
      </Grid>
    );
  }
};
