import { BaseModelGeneratorModel } from '@rexlabs/model-generator';

import { Generator } from 'data/models/generator';
import { Contact } from 'src/modules/contacts/types/contact-types';
import { TimestampAttributes } from '../../types';
import { Tenancy } from '../tenancies';

interface Ledger {
  object: {
    type: 'tenancy';
    id: string;
  };
}

export type ReceiptFundType = 'rent' | 'held_funds';

export interface ReceiptFundLineItem {
  type: ReceiptFundType;
  description: string;
  amount: number;
  amount_due?: number;
  tenancy?: Tenancy;
  ledger?: Ledger;
}

export type ReceiptFund = BaseModelGeneratorModel &
  TimestampAttributes & {
    tenancy: Tenancy;
    amount: string | number;
    received_by: Contact;
    date_of: Date;
    receipt_method: any;
    payment_reference: string;
    line_items: ReceiptFundLineItem[];
  };

export const financialsReceiptFundsModel = new Generator<ReceiptFund>(
  'financials/receipt-funds'
).createEntityModel();
