import { RecordSubmitHandler } from 'view/components/record-screen/utils';
import { useModelActions } from '@rexlabs/model-generator';
import { api } from 'utils/api/api-client';
import { getTaskIncludes } from 'src/modules/tasks/common/utils/get-task-includes';
import { mapPropertyDetailsFormDataToRequest } from '../mappers/map-property-details-form-data-to-request';
import { tasksModel } from '../../../common/models/tasks-model';

export function useGetPropertyDetailsSubmitHandler(
  inspectionId: string
): RecordSubmitHandler {
  const actions = useModelActions(tasksModel);

  return async ({ changedValues, values }) => {
    const propertyDetailsId = values?.property_details?.id;

    const propertyDetailsValues = changedValues?.property_details;

    const mappedPropertyDetailsValues = mapPropertyDetailsFormDataToRequest(
      propertyDetailsValues
    );

    await api.patch(
      `/property-details/${propertyDetailsId}`,
      mappedPropertyDetailsValues
    );

    await actions.refreshItem({
      id: inspectionId,
      args: {
        include: getTaskIncludes('task_inspection')
      }
    });

    return true;
  };
}
