import React, { useMemo } from 'react';

import { GhostButton, OutlineButton, PrimaryButton } from '@rexlabs/button';

import { FLAGS } from 'utils/feature-flags';

import { useGetCreateNoteAction } from 'src/modules/note/hooks/use-get-create-note-action';

import { ButtonGroupProps } from 'view/components/record-screen/dialog/types';
import { StandardDialogFooter } from 'view/components/dialogs/components/standard-dialog-footer';
import BellIcon from 'view/components/icons/bell';
import { useFeatureFlags } from 'view/components/@luna/feature-flags';
import NoteIcon from 'view/components/icons/note';

import { BatchReceiptingItem } from '../types';

export function SuggestMatchDialogButtonGroup({
  handleSubmit,
  onClose,
  isSubmitting,
  data
}: ButtonGroupProps & { data: BatchReceiptingItem }) {
  const { hasFeature } = useFeatureFlags();

  const getCreateNoteAction = useGetCreateNoteAction(data?.__record_type);

  const createNoteAction = useMemo(
    () =>
      getCreateNoteAction({
        attached: data,
        hideAddNoteToField: true
      }),
    []
  );

  const handleSubmitAndClose = async () => {
    const result = await handleSubmit();

    if (!result) return;

    onClose?.();
  };

  return (
    <StandardDialogFooter>
      <GhostButton onClick={onClose}>Cancel</GhostButton>
      {hasFeature(FLAGS.BATCH_RECEIPTING_SUSPENSION_FLOW) ? (
        <OutlineButton
          IconLeft={NoteIcon}
          onClick={createNoteAction.handleAction}
        >
          Add note
        </OutlineButton>
      ) : null}
      <PrimaryButton
        IconLeft={BellIcon}
        onClick={handleSubmitAndClose}
        isLoading={isSubmitting}
      >
        Submit match request
      </PrimaryButton>
    </StandardDialogFooter>
  );
}
