import { makeIconComponent } from '@rexlabs/icons';

import MediumSecurityDepositSvg from 'assets/icons/m/security-deposit.svg';
import LargeSecurityDepositSvg from 'assets/icons/l/security-deposit.svg';
import ExtraLargeSecurityDepositSvg from 'assets/icons/xl/security-deposit.svg';

export const SecurityDepositIcon = makeIconComponent({
  m: MediumSecurityDepositSvg,
  l: LargeSecurityDepositSvg,
  xl: ExtraLargeSecurityDepositSvg
});

export default SecurityDepositIcon;
