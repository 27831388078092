import React, { useMemo } from 'react';
import { query, useEntityQuery } from '@rexlabs/model-generator';

import { BREADCRUMBS } from 'view/components/@luna/breadcrumbs';
import { RecordScreen } from 'view/components/record-screen';

import {
  financialsRecurringChargeEntriesModel,
  RecurringChargeEntry
} from 'data/models/entities/financials/recurring-charge-entries';
import { useRecordScreenSubmitHandler } from 'view/hooks/use-record-screen-submit-handler';
import { chargeEntryDetailsContent } from '../data/charge-entry-details';
import { RecurringChargeEntryTitleBlock } from '../components/recurring-charge-entry-title-block';

interface RecurringChargeEntryDetailsScreenProps {
  recurringChargeEntryId?: string;
}

const getChargeEntryQuery = (recurringChargeEntryId: string) => query`{
  ${financialsRecurringChargeEntriesModel} (id: ${recurringChargeEntryId}) {
    id
    description
    due_date
    amount
    payable_by
    payable_to
    tax_type
  }
}`;

export function RecurringChargeEntryDetailsScreen({
  recurringChargeEntryId
}: RecurringChargeEntryDetailsScreenProps) {
  const breadcrumbs = [{ type: BREADCRUMBS.RECURRING_CHARGE_ENTRY }];
  const query = useMemo(() => getChargeEntryQuery(recurringChargeEntryId!), [
    recurringChargeEntryId
  ]);
  const { data, status, actions } = useEntityQuery(query);

  const handleSubmit = useRecordScreenSubmitHandler(({ changedValues }) =>
    actions.updateItem({
      id: recurringChargeEntryId,
      data: changedValues
    })
  );

  return (
    <RecordScreen
      isLoading={status === 'loading'}
      data={data}
      handleSubmit={handleSubmit}
      content={chargeEntryDetailsContent}
      titleBlock={
        <RecurringChargeEntryTitleBlock
          recurringChargeEntry={data as RecurringChargeEntry}
        />
      }
      breadcrumbs={breadcrumbs}
    />
  );
}
