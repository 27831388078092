import React from 'react';

import Box from '@rexlabs/box';
import { Heading } from '@rexlabs/text';
import { TextCell } from '@rexlabs/table';

import ROUTES from 'routes/app';

import { Card, CardContent } from 'view/components/@luna/card';
import { Columns, RecordTable } from 'view/components/table';
import { LinkButtonCell } from 'view/components/cells/link-button-cell';

import { push } from '@rexlabs/whereabouts';
import { getFinancialYearId } from '../utils/get-financial-year-id';
import { QuarterlyPayments } from '../types/quarterly-payments';
import { IncompletePeriodTagCell } from './incomplete-period-tag-cell';

function getColumns({ financialStartDate }): Columns {
  return [
    {
      id: 'quarter',
      Header: 'Quarter',
      Cell: (props) => <TextCell {...props} value={`NRLQ ${props.value}`} />
    },
    {
      id: 'status',
      accessor: (item) => item,
      Cell: (props) => {
        const endDate = props.value?.quarter_end_date;

        return <IncompletePeriodTagCell periodEndDate={endDate} />;
      },
      width: 200
    },
    {
      id: 'quarter_start_date',
      Header: 'Quarter start',
      type: 'date',
      width: 180
    },
    { id: 'quarter_end_date', Header: 'Quarter end', type: 'date', width: 180 },
    {
      id: 'payments_not_issued',
      Header: 'Payments not issued',
      type: 'currency'
    },
    { id: 'payments_issued', Header: 'Payments issued', type: 'currency' },
    {
      Cell: ({ value }) => {
        const id = getFinancialYearId(financialStartDate);

        return (
          <LinkButtonCell
            action={{
              label: 'View',
              handleAction: () =>
                push(ROUTES.HMRC_REPORTS_AND_SUBMISSIONS_QUARTER_DETAILS, {
                  params: { id, quarterId: value.quarter }
                })
            }}
          />
        );
      },
      id: 'view',
      width: 80,
      accessor: (item) => item
    }
  ];
}

export function QuarterlyPaymentCard({
  financialStartDate,
  data,
  isLoading
}: {
  financialStartDate: string;
  data: QuarterlyPayments[];
  isLoading: boolean;
}) {
  const quarterlyPaymentsData =
    isLoading === false
      ? data.map((item, index) => {
          return { ...item, quarter: index + 1, id: index + 1 };
        })
      : [];

  function getRowLinkProps({ item }) {
    const id = getFinancialYearId(financialStartDate);

    return {
      to: ROUTES.HMRC_REPORTS_AND_SUBMISSIONS_QUARTER_DETAILS,
      params: {
        id,
        quarterId: item.quarter
      }
    };
  }

  const columns = getColumns({ financialStartDate });

  return (
    <Card>
      <CardContent>
        <Box
          flexDirection='row'
          alignItems='center'
          justifyContent='space-between'
          sx='1.2rem'
          flex={1}
        >
          <Heading level={3}>Quarterly payments</Heading>
        </Box>
        <RecordTable
          id='quarterly-payments'
          items={quarterlyPaymentsData}
          columns={columns}
          isLoading={isLoading}
          getRowLinkProps={getRowLinkProps}
        />
      </CardContent>
    </Card>
  );
}
