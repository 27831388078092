import { useWhereabouts } from '@rexlabs/whereabouts';
import { Columns } from 'src/view/components/table';
import { SummaryCell } from 'src/modules/tasks/common/components/summary-cell';
import { MoveOut } from 'src/modules/tasks/move-out/types/MoveOut';
import { ProgressDropdown } from 'src/modules/tasks/common/components/progress-dropdown';
import { closedDate, managedBy } from '../../common/utils/common-columns';

const priority = {
  id: 'priority_id',
  type: 'value',
  Header: 'Priority',
  accessor: (item: MoveOut) => item.priority,
  toCsv: (item: MoveOut) => item?.priority?.label
} as const;

const taskMoveOutStatusId = {
  id: 'task_move_out_status_id',
  Header: 'Progress',
  Cell: ProgressDropdown,
  accessor: (item: MoveOut) => item,
  width: 200,
  toCsv: (item: MoveOut) => item?.status?.label || ''
};

const moveOutDate = {
  id: 'move_out_date',
  Header: 'Move out date',
  accessor: (item: MoveOut) => item.details?.move_out_date,
  type: 'date',
  width: 140
} as const;

const followUpDate = {
  id: 'follow_up_date',
  Header: 'Follow up date',
  accessor: (item: MoveOut) => item.follow_up_date,
  type: 'date',
  width: 140
} as const;

const summary = {
  id: 'summary',
  Header: 'Summary',
  Cell: SummaryCell,
  accessor: (item: MoveOut) => item,
  width: 380,
  toCsv: (item: MoveOut) => item.summary
};

export function useMoveOutColumns(): Columns {
  const whereabouts = useWhereabouts();
  const tab = whereabouts.hashQuery?.tab;

  if (tab === 'closed') {
    return [summary, closedDate, moveOutDate, managedBy, priority];
  }

  return [
    summary,
    followUpDate,
    moveOutDate,
    taskMoveOutStatusId,
    managedBy,
    priority
  ];
}
