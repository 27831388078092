import { omit } from 'lodash';
import { valueListFactory } from 'src/lib/testing/factories/value-list-factory';
import { DisbursementFrequency } from 'src/modules/disbursements/types/frequency';
import { DisbursementFrequencySettings } from '../../hooks/useSettings';
import { mapDefaultDisbursementSettingsToDisbursementFrequencyForm } from './map-default-disbursement-settings-to-disbursement-frequency-form';

/**
 * Used to map the default disbursement settings to the disbursement frequency view
 */
export function mapDefaultDisbursementSettingsToDisbursementFrequencyView(
  data: DisbursementFrequencySettings
): {
  disbursement_frequency: Partial<DisbursementFrequency>;
} {
  const { on_frequency_unit_id, on_frequency_count } = data;

  const onFrequencyUnit =
    on_frequency_unit_id && valueListFactory(on_frequency_unit_id);

  // We can reuse the this mapper for the disbursement frequency form and just omit the 'on_frequency_next_disbursement_date' field,
  // as this is not used in the view.
  const disbursementFrequency = omit(
    mapDefaultDisbursementSettingsToDisbursementFrequencyForm(data)
      .disbursement_frequency,
    'on_frequency_next_disbursement_date'
  );

  return {
    disbursement_frequency: {
      ...disbursementFrequency,
      on_frequency_unit: onFrequencyUnit,
      on_frequency_count
    }
  };
}
