import React, { ComponentType, ReactNode } from 'react';
import { Helmet } from 'react-helmet';
import { MultiForm } from '@rexlabs/form';
import Box from '@rexlabs/box';
import { StyleSheet, useStyles } from '@rexlabs/styling';

import RenderLoading from 'view/components/@luna/render-loading';
import { AutoFocusDialogProps, Dialog } from 'view/components/@luna/dialog';
import LoadingState from 'view/components/states/compact/loading';
import {
  FormContent,
  FormContentProps
} from 'view/components/record-screen/dialog/content-dialog';
import { DialogContentConfig } from 'view/components/record-screen/dialog/types';
import { RecordSubmitHandler } from 'view/components/record-screen/utils';
import { GetHandlers } from 'view/components/record-screen/types';
import { Bold } from '@rexlabs/text';
import { pluralize } from 'utils/formatters';
import { MultiItemDialogButtonGroup } from './multi-item-dialog-button-group';
import { useMultiItemDialogPageState } from './use-multi-item-dialog-page-state';
import { useHandleDialogSubmit } from './use-handle-dialog-submit';

export interface MultiItemDialogProps
  extends AutoFocusDialogProps,
    Pick<FormContentProps, 'data' | 'initialValues'> {
  title: ReactNode;
  content: DialogContentConfig;
  handleSubmit?: RecordSubmitHandler;
  isLoading?: boolean;
  LoadingView?: ComponentType<any>;
  submitOnAllSteps?: boolean;
  blockProps?: any;
  footerLeft?: React.ReactNode;
  documentTitle?: string;
  prompt?: React.ReactNode;
  getHandlers?: GetHandlers;
  SubmitButton?: ComponentType<any>;
  data: any[];
  nextButtonLabel?: string;
  removeButtonLabel?: string;
  recordTypePluralized: string;
  subTitleSuffix?: string;
}

const defaultStyles = StyleSheet({
  container: {
    position: 'relative',
    height: '100%',
    width: '100%'
  },

  content: {
    height: '100%',
    width: '100%'
  }
});

export function MultiItemDialog({
  content,
  data,
  initialValues,
  handleSubmit,
  isLoading,
  LoadingView = LoadingState,
  title,
  documentTitle,
  SubmitButton,
  onClose,
  nextButtonLabel,
  removeButtonLabel,
  recordTypePluralized,
  subTitleSuffix = '',
  ...props
}: MultiItemDialogProps) {
  const s = useStyles(defaultStyles);

  const {
    itemCount,
    manyFormConfigs,
    currentItemIndex,
    handleNext,
    markPageAsDone,
    completedPageIds
  } = useMultiItemDialogPageState({
    data,
    initialValues,
    content
  });

  const { isSubmitting, handleDialogSubmit } = useHandleDialogSubmit({
    handleSubmit
  });

  return (
    <>
      <Helmet title={documentTitle ?? title?.toString?.()} />
      <Dialog onClose={onClose} title={title} {...props}>
        <Box>
          <Box>
            <Bold>
              {pluralize(
                recordTypePluralized,
                itemCount - completedPageIds.length,
                true
              )}{' '}
              {subTitleSuffix}
            </Bold>
          </Box>
        </Box>
        <RenderLoading LoadingView={LoadingView} isLoading={isLoading}>
          {manyFormConfigs.map(({ id, formConfig }, index) => {
            return (
              // we still render all the MultiForms so that form data gets persisted between page changes
              <MultiForm key={id} formConfigs={formConfig}>
                {({ validateAll, submitAll, resetAll, forms }) => {
                  // If this isn't the current page, don't render
                  if (index !== currentItemIndex) {
                    return <></>;
                  }

                  return (
                    <Box flexDirection='row' {...s('container')} hidden={true}>
                      <Box {...s('content')} flex={1}>
                        <FormContent
                          {...props}
                          onClose={onClose}
                          SubmitButton={SubmitButton}
                          step={content[0]}
                          data={data}
                          initialValues={initialValues}
                          forms={forms}
                          resetAll={resetAll}
                          isSubmitting={isSubmitting}
                          ButtonGroup={MultiItemDialogButtonGroup}
                          buttonGroupProps={{
                            handleNext,
                            nextButtonLabel,
                            removeButtonLabel,
                            itemCount,
                            id,
                            markPageAsDone,
                            completedPageIds
                          }}
                          handleSubmit={() =>
                            handleDialogSubmit({
                              validateAll,
                              submitAll
                            }).then((val) => {
                              return val;
                            })
                          }
                        />
                      </Box>
                    </Box>
                  );
                }}
              </MultiForm>
            );
          })}
        </RenderLoading>
      </Dialog>
    </>
  );
}
