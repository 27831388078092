import { Contact } from 'src/modules/contacts/types/contact-types';

// TODO: any, because it is consumed by components which are still expecting it to be typed like old-select
export const normaliseContact = (contact: Contact): any => {
  return {
    id: contact.id,
    label: contact.display_name,
    type: 'contact'
  };
};
