import React from 'react';
import dayjs from 'dayjs';
import { noop } from 'lodash';

import { useConfirmationDialog, useDialog } from '@rexlabs/dialog';
import { Body } from '@rexlabs/text';

import { getLngLatFromProperty } from 'src/modules/properties/utils/get-lng-lat-from-property';

import WarningCircleIcon from 'view/components/icons/warning-circle';

import { InspectionTask } from '../types/InspectionTask';
import { FixInspectionLocationIssuesRecordDialog } from '../dialogs/fix-inspection-location-issues-record-dialog';

export function useGetValidateAndFixInspectionsForRun() {
  const { open: openFixInspectionLocationIssuesDialog } = useDialog(
    FixInspectionLocationIssuesRecordDialog
  );

  const { open: openConfirmationDialog } = useConfirmationDialog();

  return (
    inspections: InspectionTask[],
    afterValidation: (props?: any) => any
  ) => {
    // When inspections are already scheduled we can't add them to another run.
    // With the below list, we can show a confirmation dialog, to tell the user
    // that these inspections won't be added to the run.
    const inspectionsThatHaveInspectionTime = inspections.filter(
      (inspection) => inspection.details?.scheduled_at
    );

    const inspectionsWithoutInspectionTime = inspections.filter(
      (inspection) => !inspection.details?.scheduled_at
    );

    // TODO: Sometimes there is no valid inspections - when this happens we want to close the dialog, but
    // the confirmation dialog doesn't allow us to do this. We need to add a custom dialog that allows this.
    const hasNoValidInspections =
      inspectionsThatHaveInspectionTime.length === inspections.length;

    // If there are inspections that are missing locations, we want to open the fix location dialog
    const hasInspectionsWithMissingLocation = inspections.some(
      (inspection) => !getLngLatFromProperty(inspection.property)
    );

    const fixLocation = () =>
      openFixInspectionLocationIssuesDialog({
        data: {
          inspections: inspectionsWithoutInspectionTime
        },
        onSave: (inspections) => {
          return afterValidation(inspections);
        }
      });

    const checkForExistingRun = () =>
      openConfirmationDialog({
        TitleIcon: WarningCircleIcon,
        title: 'Some inspections have already been planned',
        message: (
          <>
            <Body>
              {hasNoValidInspections
                ? 'All '
                : `${inspectionsThatHaveInspectionTime.length} out of ${inspections.length} `}
              inspections have already been scheduled in an inspection run.
            </Body>

            <ul style={{ listStyle: 'disc', paddingLeft: '30px' }}>
              {inspectionsThatHaveInspectionTime.map((inspection) => {
                const dateObj = dayjs(inspection.details?.scheduled_at).tz();
                const scheduledDuration = inspection.details!
                  .scheduled_duration!;

                return (
                  <li key={inspection.id}>
                    <Body as='span'>
                      <b>{inspection.property!.display_name}</b> is planned for{' '}
                      <b>
                        {dateObj.format('DD/MM/YYYY')} -{' '}
                        {dateObj.format('HH:mm')} -{' '}
                        {dateObj
                          .add(scheduledDuration, 'minutes')
                          .format('HH:mm')}
                      </b>
                    </Body>
                  </li>
                );
              })}
            </ul>

            <Body>
              If you wish to reschedule an inspection that is already in a run,
              please go to the inspection, and remove the scheduled inspection.
            </Body>

            <Body>
              {hasNoValidInspections
                ? 'Clicking proceed will close this dialog.'
                : 'Clicking proceed will continue, but the previously scheduled inspections will not be included.'}
            </Body>
          </>
        ),
        confirmText: 'Proceed',
        onConfirm: () => {
          return hasNoValidInspections
            ? noop
            : hasInspectionsWithMissingLocation
            ? fixLocation()
            : afterValidation(inspectionsWithoutInspectionTime);
        }
      });

    return inspectionsThatHaveInspectionTime.length > 0
      ? checkForExistingRun
      : hasInspectionsWithMissingLocation
      ? fixLocation
      : () => afterValidation(inspectionsWithoutInspectionTime);
  };
}
