import React from 'react';
import { BlockConfig } from 'view/components/record-screen/types';
import { Value } from 'view/components/values';
import { Grid } from 'view/components/@luna/form/grid';
import { Field } from '@rexlabs/form';
import { useStyles, StyleSheet } from '@rexlabs/styling';
import { TaskTypeAssignmentSelect } from 'view/components/inputs/selects/v4-selects/task-type-assignment-select';
import i18n from 'src/modules/i18n/i18n';
import { useTaskAssignmentTypes } from '../hooks/use-task-assignment-types';
import { PortfolioFormData } from '../types/portfolio-form-data';

const styles = StyleSheet({
  taskAssignmentDescription: {
    color: ({ token }) => token('color.textStyle.body.subtext')
  }
});

const TaskAssignmentDescription = () => {
  const s = useStyles(styles);

  return (
    <div {...s('taskAssignmentDescription')}>
      Defines the default task manager for each task type when a new task is
      created.
    </div>
  );
};

export const taskAssignmentBlock: BlockConfig<PortfolioFormData> = {
  id: 'task-assignment',
  title: 'Task assignment',
  validate: {
    definitions: {
      'task_type_assignments.task_arrears.contact': {
        rules: 'required',
        name: 'rent arrears'
      },
      'task_type_assignments.task_maintenance.contact': {
        rules: 'required',
        name: 'maintenance'
      },
      'task_type_assignments.task_inspection.contact': {
        rules: 'required',
        name: 'inspections'
      },
      'task_type_assignments.task_property_compliance.contact': {
        rules: 'required',
        name: 'property compliance'
      },
      'task_type_assignments.task_move_in.contact': {
        rules: 'required',
        name: i18n.t('tasks.move-in.title').toLowerCase()
      },
      'task_type_assignments.task_move_out.contact': {
        rules: 'required',
        name: i18n.t('tasks.move-out').toLowerCase()
      },
      'task_type_assignments.task_lease_review.contact': {
        rules: 'required',
        name: i18n.t('tasks.lease-review.label.plural').toLowerCase()
      }
    }
  },
  View: ({ data }) => {
    const taskAssignmentTypes = useTaskAssignmentTypes();
    const getFieldValue = (taskAssignmentType) => {
      const contact =
        data?.task_type_assignments?.[taskAssignmentType.id]?.contact;
      if (contact && 'display_name' in contact) {
        return contact?.display_name;
      }
      return 'Do not automatically assign';
    };

    return (
      <>
        <TaskAssignmentDescription />
        <Grid columns={3}>
          {taskAssignmentTypes.map((taskAssignmentType, key) => {
            return (
              <Value
                key={key}
                label={taskAssignmentType.label}
                value={getFieldValue(taskAssignmentType)}
              ></Value>
            );
          })}
        </Grid>
      </>
    );
  },
  Edit: ({ data }) => {
    const taskAssignmentTypes = useTaskAssignmentTypes();

    return (
      <>
        <TaskAssignmentDescription />
        <Grid style={{ marginTop: '2rem' }} columns={3}>
          {taskAssignmentTypes.map((taskAssignmentType, key) => {
            return (
              <Field
                key={key}
                id={`${taskAssignmentType.id}_default_task_manager`}
                name={`task_type_assignments[${taskAssignmentType.id}].contact`}
                label={taskAssignmentType.label}
                Input={TaskTypeAssignmentSelect}
                optional={false}
                inputProps={{
                  getSuggestedItems: () =>
                    data?.members?.data
                      ? data?.members?.data.map(
                          (member) => member.user?.contact
                        )
                      : []
                }}
              ></Field>
            );
          })}
        </Grid>
      </>
    );
  }
};
