import React from 'react';

import { useMediaQuery } from '@rexlabs/breakpoints';
import Box from '@rexlabs/box';
import { Field } from '@rexlabs/form';

import { BlockConfig } from 'view/components/record-screen/types';
import { Value } from 'view/components/values';
import { Grid } from 'view/components/@luna/form/grid';

import { Task } from '../types/Task';
import { TaskFormData } from '../types/TaskFormData';
import { TaskPriorityButtonGroup } from '../components/task-priority-button-group';
import { TaskPrioritySelect } from '../components/task-priority-select';

const validate = {
  definitions: {
    priority: { name: 'priority', rules: 'required' }
  }
};

export const taskPriorityBlock: BlockConfig<Task<any>, any, TaskFormData> = {
  id: 'priority',
  title: 'Priority',
  validate,
  View: ({ data }) => {
    return (
      <Grid columns={2}>
        <Value label='Priority' value={data?.priority.label} />
      </Grid>
    );
  },
  Edit: () => {
    const matchesMobile = useMediaQuery({ maxWidth: 's' });
    return (
      <Box sy='2rem'>
        {matchesMobile ? (
          <Field id='priority' name='priority' Input={TaskPrioritySelect} />
        ) : (
          <Field
            id='priority'
            name='priority'
            Input={TaskPriorityButtonGroup}
          />
        )}
      </Box>
    );
  }
};
