import { Columns } from 'src/view/components/table';
import { channelMessageRecipientColumns } from './channel-message-recipient-columns';

export const messageColumnsBounced: Columns = [
  ...channelMessageRecipientColumns,
  {
    id: 'failure_status_reason',
    Header: 'Issue'
  }
];
