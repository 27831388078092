import React, { useMemo } from 'react';
import { Property } from 'src/modules/properties/types/property-types';

import { TabbedTable } from 'view/components/table';
import { Tab } from 'view/components/table/tabbed';

import { useActiveTabProps } from '../hooks/use-active-tab-props';
import { useArchivedTabProps } from '../hooks/use-archived-tab-props';

export function KeySetTabbedTable({ property }: { property: Property }) {
  const activeTab = useActiveTabProps(property);
  const archivedTab = useArchivedTabProps(property);

  const tabs = useMemo<Tab[]>(() => [activeTab, archivedTab], []);

  return <TabbedTable tabs={tabs} />;
}
