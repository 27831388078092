import React from 'react';
import { OutlineButton, OutlineIconButton } from '@rexlabs/button';
import { StyleSheet, useStyles } from '@rexlabs/styling';
import { InputButtonProps } from 'view/components/@luna/button-group-input';

const defaultStyles = StyleSheet({
  button: {
    width: '100%',
    borderColor: ({ token }) => token('palette.grey.600'),
    '&:focus:enabled': {
      borderColor: ({ token }) => token('palette.grey.600'),
      boxShadow: 'none'
    },
    '&:hover:enabled': {
      borderColor: ({ token }) => token('palette.grey.600')
    },
    '&:active:enabled': {
      borderColor: ({ token }) => token('palette.grey.600')
    }
  },
  buttonFirst: {
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    borderRightWidth: 0
  },
  buttonLast: {
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0
  },
  buttonMiddle: {
    borderRadius: 0,
    borderRightWidth: 0
  },
  active: {
    background: ({ token }) => token('color.secondary.active.default')
  }
});

export function ButtonGroupButton({
  Icon,
  children,
  isFirst,
  isMiddle,
  isLast,
  active,
  item: _item,
  ...props
}: InputButtonProps) {
  const s = useStyles(defaultStyles);

  const commonProps = {
    ...props,
    'aria-pressed': active,
    ...s({
      button: true,
      buttonFirst: isFirst,
      buttonLast: isLast,
      buttonMiddle: isMiddle,
      active: active
    })
  };

  // if there is an icon but no label return a simple icon button, otherwise return a button that can display the label
  return !children && Icon ? (
    <OutlineIconButton Icon={Icon} {...commonProps}></OutlineIconButton>
  ) : (
    <OutlineButton IconLeft={Icon} {...commonProps}>
      {children}
    </OutlineButton>
  );
}
