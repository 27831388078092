import React, { useCallback, useEffect } from 'react';
import { forEach } from 'lodash';

import { Field } from '@rexlabs/form';
import { RadioGroupInput } from '@rexlabs/radio-input';

import { BlockConfig } from 'view/components/record-screen/types';
import { Grid } from 'view/components/@luna/form/grid';
import { createContactBlocksForTypeMap } from '../dialogs/create-contact-dialog';

export const typeBlock: BlockConfig = {
  id: 'type',
  validate: {
    definitions: {
      ['type.id']: { name: 'contact type', rules: 'required' }
    }
  },
  Edit: ({ blockProps, values, setFieldValue, forms }) => {
    // HACK: This is needed because if we pass the type in as an initial value, it breaks required_if validation in other blocks
    // that refer to this value
    useEffect(() => {
      if (!values.type?.id) {
        setFieldValue?.('type.id', blockProps?.type);
      }
    }, [setFieldValue, blockProps.type, values.type?.id]);

    // HACK: this is just to provide access to the currently selected type
    // to components outside the form
    useEffect(() => {
      values?.type?.id && blockProps?.setType?.(values?.type?.id);
    }, [blockProps, values?.type?.id]);

    // This resets the values of any forms that are not currently visible
    const onChangeHandler = useCallback(
      (e) => {
        const newContactType: string = e.target.value;

        const blockIdsForType = createContactBlocksForTypeMap[
          newContactType
        ].map((block: BlockConfig<any, any>) => block.id);

        forEach(forms, (form) => {
          if (!blockIdsForType.includes(form.id)) {
            form.resetForm();
          }
        });
      },
      [forms]
    );

    return (
      <Grid columns={1}>
        <Field
          name='type.id'
          label='Contact type'
          Input={RadioGroupInput}
          onChange={onChangeHandler}
          inputProps={{
            orientation: 'horizontal',
            options: [
              { value: 'individual', label: 'Individual' },
              { value: 'business', label: 'Business' },
              { value: 'deposit_authority', label: 'Deposit authority' }
            ]
          }}
        />
      </Grid>
    );
  }
};
