import React, { useCallback, useMemo } from 'react';
import { GhostButton, PrimaryButton } from '@rexlabs/button';
import { ButtonGroupProps } from 'view/components/record-screen/dialog/types';
import { StandardDialogFooter } from 'view/components/dialogs/components/standard-dialog-footer';

export function CreateCreditNoteDialogButtonGroup({
  handleSubmit,
  onClose,
  isSubmitting,
  forms
}: ButtonGroupProps) {
  const values = forms?.details.values;

  const templateInputs = values?.template?.inputs;

  // This will fail if there is a checkbox or boolean input
  const hasEmptyTemplateInput = useMemo(() => {
    return (
      templateInputs &&
      templateInputs.some((input) => {
        return !values?.[input.id];
      })
    );
  }, [templateInputs, values]);

  const hasValidationError = !forms?.details.isValid;

  const isSubmitDisabled =
    !values?.invoice ||
    !values?.template ||
    hasEmptyTemplateInput ||
    hasValidationError;

  const handleSubmitAndClose = useCallback(async () => {
    const result = await handleSubmit();

    if (!result) return;

    onClose?.();
  }, [handleSubmit, onClose]);

  return (
    <StandardDialogFooter>
      <GhostButton onClick={onClose}>Cancel</GhostButton>
      <PrimaryButton
        onClick={handleSubmitAndClose}
        isDisabled={isSubmitDisabled}
        isLoading={isSubmitting}
      >
        Issue credit note
      </PrimaryButton>
    </StandardDialogFooter>
  );
}
