import { useDialog } from '@rexlabs/dialog';
import { push } from '@rexlabs/whereabouts';
import ROUTES from 'routes/app';
import { ActionDeclaration } from 'src/modules/common/actions/types/action-declaration-types';
import { ChecklistTemplateDialog } from '../../dialogs/checklist-template';
import { DeleteChecklistTemplateDialog } from '../../dialogs/delete-checklist-template';
import { ChecklistTemplate } from '../../types/ChecklistTemplate';
import {
  ChecklistTemplatesForm,
  ChecklistTemplatesItemForm
} from '../../types/ChecklistTemplatesForm';
import { useUpdateChecklistTemplateAction } from '../action-declarations/checklist-template/use-update-checklist-template-action';
import { useGetIsSmartChecklist } from '../use-get-is-smart-checklist';

export function useGetChecklistTemplateActions({
  withEditAction = false
}: {
  withEditAction?: boolean;
} = {}) {
  const getChecklistUpdateSubmit = useUpdateChecklistTemplateAction();
  const checklistTemplateDialog = useDialog(ChecklistTemplateDialog);
  const deleteChecklistTemplateDialog = useDialog(
    DeleteChecklistTemplateDialog
  );

  const getIsSmartChecklist = useGetIsSmartChecklist();

  return (checklistTemplate: ChecklistTemplate): ActionDeclaration[] => {
    if (!checklistTemplate) return [];

    const actions: ActionDeclaration[] = [
      ...(withEditAction
        ? [
            {
              id: 'edit',
              label: 'Edit',
              handleAction: () => {
                if (getIsSmartChecklist(checklistTemplate.task_type.id)) {
                  push(ROUTES.CHECKLIST_DETAILS, {
                    params: {
                      checklistId: checklistTemplate.id
                    }
                  });

                  return;
                }

                checklistTemplateDialog.open({
                  title: 'Edit checklist',
                  isEditMode: true,
                  taskTypeId: checklistTemplate.task_type.id,
                  onSubmit: getChecklistUpdateSubmit(checklistTemplate.id),
                  initialValues: getInitialValues(checklistTemplate)
                });
              }
            }
          ]
        : []),
      ...[
        {
          label: 'Actions',
          actions: [
            {
              id: 'delete',
              label: 'Delete',
              intent: 'danger',
              handleAction: () => {
                deleteChecklistTemplateDialog.open({
                  checklistTemplate
                });
              }
            } as ActionDeclaration
          ]
        }
      ]
    ];

    return actions;
  };
}

// Utils

function getInitialValues(
  checklistTemplate: ChecklistTemplate
): ChecklistTemplatesForm {
  const items: ChecklistTemplatesForm['checklist_template_items'] = getChecklistItems(
    checklistTemplate.items?.data?.map((item) => ({
      ...item,
      assignee_contact_or_role:
        item.assigned_to ?? item.assigned_to_portfolio_role ?? null
    })),
    checklistTemplate.item_order
  );

  return {
    label: checklistTemplate.label ?? '',
    description: checklistTemplate.description ?? '',
    checklist_template_items: items,
    checklist_template_item_groups: checklistTemplate.groups?.data ?? [],
    is_default: checklistTemplate.is_default
  };
}

export function getChecklistItems(
  checklistItems?: Array<ChecklistTemplatesItemForm>,
  itemOrder?: ChecklistTemplate['item_order']
) {
  if (!Array.isArray(checklistItems)) return [];
  if (!Array.isArray(itemOrder)) return checklistItems;

  const items = itemOrder.map((orderId) => {
    return checklistItems.find((item) => item.id === orderId)!;
  });

  return items;
}
