import * as React from 'react';

import { Message } from '@rexlabs/notifications';

import EmptyTable from 'src/assets/illustrations/empty-table.svg';

import { EmptyCard } from 'view/components/record-screen/cards/empty-card';

export function ReadOnlyNotesListEmptyState() {
  return (
    <EmptyCard>
      <Message title='Notes' Illustration={EmptyTable}>
        There are no notes for this record
      </Message>
    </EmptyCard>
  );
}
