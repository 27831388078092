import { Columns } from 'src/view/components/table';
import { slugToSentenceCase } from '../../messages/utils/slug-to-sentence-case';
import { MessageTemplate } from '../types/MessageTemplate';

export const messageTemplateColumns: Columns<MessageTemplate> = [
  { id: 'name', Header: 'Template name' },
  {
    id: 'context_type',
    Header: 'Relates to',
    type: 'value',
    accessor: (item) => item.context_type
  },
  {
    id: 'default_recipient_group',
    Header: 'Recipient group',
    accessor: (item) => slugToSentenceCase(item.default_recipient_group)
  },
  {
    id: 'send_from',
    Header: 'Send from',
    accessor: (item) => item.send_from_author?.name || 'Message Creator'
  },
  {
    id: 'method',
    Header: 'Method',
    type: 'tagValues',
    accessor: (item) =>
      Array.isArray(item.channels)
        ? item.channels.flatMap((channel) => channel.channel_type)
        : []
  }
];
