import { DepositLineItem } from 'src/modules/financials/blocks/trust-journal-entry-line-items';
import { FinancialEntity } from 'src/modules/financials/utils/financial-entity-action-group/use-get-financial-entity-actions';
import { SearchResultItem } from 'utils/api/get-search-results';
import { LineItemActionItemParams } from 'src/modules/financials/types';

const defaultDepositLineItem: DepositLineItem = { category: 'deposit' };

interface UseGetAddDepositActionParams {
  folio: SearchResultItem<FinancialEntity> | null;
  amount: number;
}

export function useGetAddDepositAction({
  folio,
  amount
}: UseGetAddDepositActionParams) {
  return ({ push }: LineItemActionItemParams) => ({
    label: 'Add deposit',
    onClick: () => {
      push({
        ...defaultDepositLineItem,
        description: '',
        deposit: {
          ledger: {
            object: folio
          }
        },
        amount
      });
    }
  });
}
