import { User } from 'src/modules/users/types/user-types';

export interface UserFormData extends User {
  family_name?: string;
  given_name?: string;
  email?: string;
}

export function mapUserToFormData(user: User) {
  return {
    ...user,
    email: user?.contact?.primary_email?.email_address,
    given_name: user?.contact?.given_name,
    family_name: user?.contact?.family_name
  };
}
