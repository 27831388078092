import { Generator } from 'data/models/generator';
import { api } from 'utils/api/api-client';
import { TimestampAttributes, ValueListValue } from 'data/models/types';
import {
  BaseModelGeneratorModel,
  CustomAction
} from '@rexlabs/model-generator';
import { Reconciliation } from '../reconciliations';

type ReconciliationAdjustmentStatus = 'resolved' | 'unresolved';

export type ReconciliationAdjustment = BaseModelGeneratorModel &
  TimestampAttributes & {
    type: ValueListValue<string>;
    status: ValueListValue<ReconciliationAdjustmentStatus>;
    date_of: string;
    reason: string;
    amount: number;

    resolved_at: string;
    resolved_date_of: string;
    resolved_reason: string;

    source_reconciliation?: Reconciliation;
  };

const config = {
  entities: {
    api: {
      createItem: (type, args) =>
        api.post(
          `/financials/reconciliations/${args.reconciliationId}/adjustments`,
          args
        ),
      trashItem: (type, args) =>
        api.delete(
          `/financials/reconciliations/${args.reconciliationId}/adjustments/${args.id}`,
          args
        ),
      deleteItem: (type, args) =>
        api.delete(
          `/financials/reconciliations/${args.reconciliationId}/adjustments/${args.id}`,
          args
        ),
      fetchList: (type, args) =>
        api.get(
          `/financials/reconciliations/${args.reconciliationId}/adjustments`,
          args
        )
    },
    related: {
      source_reconciliation: {
        include: 'source_reconciliation'
      }
    }
  }
};

export type AdjustmentStatusChangeActionPayload = {
  reconciliationId: string;
  adjustmentId: string;
  reason: string;
  date_of: string;
};

const actionCreators = {
  resolve: {
    request: (payload: AdjustmentStatusChangeActionPayload) =>
      api.put(
        `/financials/reconciliations/${payload.reconciliationId}/adjustments/${payload.adjustmentId}/status`,
        {
          status: {
            id: 'resolved'
          },
          resolved_reason: payload.reason,
          resolved_date_of: payload.date_of
        }
      ),
    reduce: {
      success: (state, action) => {
        // returned item is a new transaction to reverse the original transaction
        const updatedItem = action.payload.data;
        const originalId = action.meta.originalPayload.adjustmentId;

        return {
          ...state,
          items: {
            ...state.items,
            [updatedItem.id]: { data: updatedItem },
            [originalId]: {
              ...state.items[originalId],
              data: {
                ...state.items[originalId].data,
                ...updatedItem
              }
            }
          }
        };
      }
    }
  } as CustomAction<AdjustmentStatusChangeActionPayload, any>,
  unresolve: {
    request: (
      payload: AdjustmentStatusChangeActionPayload,
      _actions,
      _dispatch,
      _getState
    ) =>
      api.put(
        `/financials/reconciliations/${payload.reconciliationId}/adjustments/${payload.adjustmentId}/status`,
        {
          status: {
            id: 'unresolved'
          },
          resolved_reason: payload.reason,
          resolved_date_of: payload.date_of
        }
      ),
    reduce: {
      success: (state, action) => {
        // returned item is a new transaction to reverse the original transaction
        const updatedItem = action.payload.data;
        const originalId = action.meta.originalPayload.adjustmentId;

        return {
          ...state,
          items: {
            ...state.items,
            [updatedItem.id]: { data: updatedItem },
            [originalId]: {
              ...state.items[originalId],
              data: {
                ...state.items[originalId].data,
                ...updatedItem
              }
            }
          }
        };
      }
    }
  } as CustomAction<AdjustmentStatusChangeActionPayload, any>
};

export const financialsReconciliationsAdjustmentsModel = new Generator<
  ReconciliationAdjustment,
  typeof actionCreators
>('financials/reconciliations/adjustments', config).createEntityModel({
  actionCreators
});
