import React from 'react';

import { DialogProps } from '@rexlabs/dialog';
import { RecordDialog } from 'view/components/record-screen/dialog/dialog';
import invariant from 'invariant';
import {
  DOCUMENT_FORMATS,
  IMAGE_FORMATS
} from '../actions/use-get-view-documents-action';
import {
  imageDocumentViewBlock,
  pdfDocumentViewBlock
} from '../blocks/document-view';

export type ViewDocumentProps =
  | {
      fileName: string;
      fileUrl: string;
      fileType: string;
      isLoading?: never;
    }
  | {
      fileName?: never;
      fileUrl?: never;
      fileType?: never;
      isLoading?: boolean;
    };

export type ViewDocumentDialogProps = DialogProps & ViewDocumentProps;

export function ViewDocumentDialog(props: ViewDocumentDialogProps) {
  const { onClose, fileType, fileName, fileUrl, isLoading = false } = props;

  const handleSubmit = () => true;

  const content = React.useMemo(() => {
    if (isLoading) return [];

    invariant(fileType, `fileType can't be empty`);

    switch (true) {
      case IMAGE_FORMATS.includes(fileType): {
        return [
          {
            id: 'view-document',
            label: 'View document',
            blocks: [imageDocumentViewBlock]
          }
        ];
      }

      case DOCUMENT_FORMATS.includes(fileType): {
        return [
          {
            id: 'view-document',
            label: 'View document',
            blocks: [pdfDocumentViewBlock]
          }
        ];
      }

      default: {
        throw new Error(`Unhandled view document format ${fileType}`);
      }
    }
  }, [fileType, isLoading]);

  return (
    <RecordDialog
      isLoading={isLoading}
      title='View document'
      data={{
        fileType,
        fileName,
        fileUrl
      }}
      onClose={onClose}
      handleSubmit={handleSubmit}
      content={content}
      SubmitButton={() => null}
    />
  );
}
