import React from 'react';
import { Select } from '@rexlabs/select';

const DEFAULT_MAX_NUMBER = 10;

type NumberSelectProps = {
  maxNumber?: number;
};

export const NumberSelect: React.FC<NumberSelectProps> = ({
  maxNumber = DEFAULT_MAX_NUMBER,
  ...props
}) => {
  const dayItems = Array(maxNumber)
    .fill(0)
    .map((_, i) => i + 1); // need to increment as we want to start from 1, not 0

  return (
    <Select
      items={dayItems}
      normaliser={(item: number) => ({
        id: String(item),
        label: String(item)
      })}
      {...props}
    />
  );
};
