import { Columns } from 'view/components/table';

export const disbursementBillAndFeeTransferColumns: Columns = [
  {
    id: 'invoice.record_reference',
    type: 'text',
    Header: 'Reference',
    width: '10%'
  },
  {
    id: 'invoice.payable_by.object',
    type: 'record',
    Header: 'Payable by',
    width: '15%'
  },
  {
    id: 'description',
    Header: 'Description'
  },
  {
    Header: 'Amount transferred',
    id: 'amount',
    type: 'currency',
    width: '20%',
    accessor: (item) => Math.abs(item.amount)
  }
];
