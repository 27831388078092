import React from 'react';
import { TwoTierCell } from 'view/components/cells/two-tier-cell';
import { getRecordLabel } from 'utils/records/get-record-label';
import { slugToSentenceCase } from 'src/modules/communications/messages/utils/slug-to-sentence-case';

export function RelatesToComplexCell({ value }) {
  if (!value) return <>--</>;

  const relatesToLabel = getRecordLabel({
    type: value?.__record_type,
    object: value
  });

  const reference = [
    slugToSentenceCase(value?.__record_type),
    value?.record_reference || ''
  ]
    .filter(Boolean)
    .join(' • ');

  return relatesToLabel ? (
    <TwoTierCell text={relatesToLabel} subtext={reference} />
  ) : (
    <>--</>
  );
}
