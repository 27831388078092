import React from 'react';

import Box from '@rexlabs/box';
import { StyleSheet, useStyles, mq } from '@rexlabs/styling';

const defaultStyles = StyleSheet({
  buttonContainer: {
    height: '4rem',
    width: '4rem',
    ...mq.styles({
      queries: {
        maxWidth: 's'
      },
      styles: {
        height: '3rem',
        width: '3rem'
      }
    })
  }
});

export function InspectionRunResponsiveIconContainer(
  props: React.ComponentProps<typeof Box>
) {
  const s = useStyles(defaultStyles);

  return <Box {...props} {...s('buttonContainer')} />;
}
