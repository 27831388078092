import React from 'react';
import { useQuery } from 'react-query';
import { api } from 'utils/api/api-client';
import { CommunicationContextType } from '../../common/types/communication-context-type';
import { CommunicationContext } from '../types/communication-context';

/**
 * Used to preview what a message would look like with merge tags resolved. Requires a number of arguments to be passed in so that the backend has the
 * information it needs to resolve the merge tags.
 * @param texts The text with merge tags that you want to resolve
 * @param context the context object that the message relates to
 * @param contextType the type of the context object
 * @param selectedRecipients an array of the contacts that the message will be sent to
 * @param sendFromAuthor the author that the message will be sent from
 * @param channelType can validate if tag is not allowed in this channel type
 * @returns object representing the resolved texts, any invalid tags, and whether the request is still loading
 */
export const useResolvedMessageTexts = (
  texts: string[],
  context?: CommunicationContext,
  contextType?: CommunicationContextType,
  selectedRecipients?: { id: string }[],
  sendFromAuthor?: { id: string } | null,
  channelType?: { id: string }
) => {
  const allTextsAreValid = texts.every(
    (text) => typeof text === 'string' && text.length > 0
  );
  const contextInfoIsValid = context && contextType;
  const { data, isLoading, error, isError } = useQuery(
    [
      'resolved-texts',
      texts,
      context?.type.id,
      context?.record.id,
      selectedRecipients,
      sendFromAuthor,
      channelType
    ],
    async () => {
      const payload = {
        texts,
        context,
        context_type: {
          id: contextType
        },
        send_from_author: sendFromAuthor?.id ? sendFromAuthor : null,
        selected_recipients: selectedRecipients?.map((item) => item.id),
        channel_type: channelType
      };
      return api
        .post('/communication/message-preview', payload)
        .then((res) => res.data);
    },
    {
      enabled: !!(
        contextInfoIsValid &&
        allTextsAreValid &&
        texts.length > 0 &&
        contextType !== undefined &&
        hasValidOrUndefinedRecipients(selectedRecipients)
      ),
      refetchOnWindowFocus: false,
      retry: false
    }
  );

  return React.useMemo(() => {
    return {
      resolvedTexts: data?.resolved_texts ?? texts,
      invalidTags: data?.invalid_tags ?? [],
      isLoading,
      isError,
      error
    };
  }, [
    isError,
    error,
    isLoading,
    data?.invalid_tags,
    data?.resolved_texts,
    texts
  ]);
};

/**
 * Makes sure that the selected recipients array is not empty, or was not provided.
 * we want to block the request if an empty array is provided, as this means the data is still loading
 */
function hasValidOrUndefinedRecipients(selectedRecipients?: { id: string }[]) {
  return !selectedRecipients || selectedRecipients.length > 0;
}
