import React from 'react';
import { RecordDialog } from 'view/components/record-screen/dialog/dialog';
import { servicePackageDetailsBlock } from 'src/modules/service-packages/blocks/service-package-details-block';
import { DialogProps, useErrorDialog } from '@rexlabs/dialog';
import { ServicePackage } from 'src/modules/service-packages/types/service-package';
import { RecordSubmitHandler } from 'view/components/record-screen/utils';
import { useModelActions } from '@rexlabs/model-generator';
import { servicePackagesModel } from 'src/modules/service-packages/models/service-packages-model';
import { ServicePackageForm } from 'src/modules/service-packages/types/service-package-form';
import { useRecordUpdatedToast } from 'src/modules/common/toasts/hooks/use-record-updated-toast';
import { useQueryClient } from 'react-query';

const content = [
  {
    id: 'service-package',
    label: 'Service package',
    blocks: [servicePackageDetailsBlock]
  }
];

interface UpdateServicePackageDialogProps extends DialogProps {
  servicePackage: ServicePackage;
}

export function UpdateServicePackageDialog({
  onClose,
  servicePackage
}: UpdateServicePackageDialogProps) {
  const queryClient = useQueryClient();
  const { updateItem, refreshLists } = useModelActions(servicePackagesModel);
  const addToast = useRecordUpdatedToast(servicePackagesModel, { actions: [] });
  const { open: openErrorDialog } = useErrorDialog();

  const handleSubmit: RecordSubmitHandler<ServicePackage> = async ({
    changedValues
  }) => {
    try {
      const { data } = await updateItem({
        id: servicePackage.id,
        data: changedValues
      });
      addToast(data);
      await refreshLists();
      await queryClient.refetchQueries({
        // AllRecordItemsSelect uses this query key to fetch the list of service packages
        queryKey: ['all-service-packages'],
        refetchPage: () => true
      });
      return data;
    } catch (error) {
      openErrorDialog(error);
    }
  };
  const initialValues: ServicePackageForm = {
    name: servicePackage.name,
    agency_fees: servicePackage?.agency_fees?.data
  };

  return (
    <RecordDialog
      title={'Edit service package'}
      content={content}
      onClose={onClose}
      handleSubmit={handleSubmit}
      initialValues={initialValues}
      submitLabel={'Save'}
      blockProps={{ isCreating: false }}
    />
  );
}
