import React from 'react';

import { useModelActions } from '@rexlabs/model-generator';
import {
  Invoice,
  InvoiceLineItem
} from 'data/models/entities/financials/invoices';

import { BILL_PROCESSING_FLAGS } from 'src/modules/bill-processing/feature-flags';
import { Bill } from 'src/modules/bill-processing/types/Bill';
import {
  InvoiceBillFormValues,
  invoiceViewerBlock
} from 'src/modules/invoices/blocks/invoice-viewer';
import { InvoiceDetailsFormValues } from 'src/modules/invoices/components/invoice-details-form';
import { invoiceDetailsBlock } from 'src/modules/invoices/blocks/invoice-details';
import {
  invoiceLineItemsBlock,
  InvoiceLineItemsFormValues
} from 'src/modules/invoices/blocks/invoice-line-items';
import { reimbursementDetailsBlock } from 'src/modules/invoices/blocks/reimbursement-details';
import { reimbursementLineItemsBlock } from 'src/modules/invoices/blocks/reimbursement-line-items';
import { invoiceTabs } from 'src/modules/invoices/blocks/tab-block';
import { useInvoiceSubmitHandler } from 'src/modules/invoices/hooks/use-invoice-submit-handler';
import { getToday } from 'utils/dates/dates';
import { AWAITING_PAYMENT_INVOICE_STATUS } from 'utils/static-value-lists/invoice-status';
import { useFeatureFlags } from 'view/components/@luna/feature-flags';
import { useDefaultBankAccount } from 'view/hooks/api/use-default-bank-account';
import { MultiItemDialog } from '../../../view/components/dialogs/multi-item-dialog/multi-item-dialog';
import { financialsUploadedBillsModel } from '../models/uploaded-bills';

export type CreateInvoiceFormValues = InvoiceDetailsFormValues &
  InvoiceLineItemsFormValues &
  InvoiceBillFormValues;

export type InitialInvoiceValues = Partial<
  Pick<
    Invoice,
    | 'payable_by'
    | 'payable_to'
    | 'reimbursement_for_invoice'
    | 'description'
    | 'due_date'
    | 'task'
  > & { line_items?: Partial<InvoiceLineItem>[] }
>;

interface ProcessBillsDialogProps {
  initialValues?: InitialInvoiceValues;
  isLoading?: boolean;
  onClose?: () => void;
  billsToBeProcessed: Bill[];
}

export function ProcessBillsDialog({
  initialValues: externalInitialValues,
  isLoading,
  onClose,
  billsToBeProcessed,
  ...props
}: ProcessBillsDialogProps) {
  const { hasFeature } = useFeatureFlags();
  const isFirstChangeRef = React.useRef(false);
  const { refreshLists } = useModelActions(financialsUploadedBillsModel);

  const [activeTab, setActiveTab] = React.useState<'invoice' | 'reimbursement'>(
    'invoice'
  );
  const [
    isReimbursementEmptyStateVisible,
    setIsReimbursementEmptyStateVisible
  ] = React.useState(true);

  const {
    defaultBankAccount,
    isLoading: isDefaultBankLoading
  } = useDefaultBankAccount();

  const hasBillProcessingFeature = hasFeature(
    BILL_PROCESSING_FLAGS.BILL_PROCESSING
  );

  const content = [
    {
      id: 'basics',
      label: 'Basics',
      blocks: [
        invoiceTabs,
        invoiceDetailsBlock,
        invoiceLineItemsBlock,
        reimbursementDetailsBlock,
        reimbursementLineItemsBlock
      ],
      leftBlocks: [invoiceViewerBlock]
    }
  ];

  const initialValues = billsToBeProcessed.map((billToBeProcessed) => {
    return {
      status: AWAITING_PAYMENT_INVOICE_STATUS,
      is_tax_included: true,
      invoice_date: getToday(),
      uploaded_bill: billToBeProcessed,
      bank_account: defaultBankAccount,
      bill_priority: {
        id: 'normal',
        label: 'Normal'
      },
      reimbursed_is_tax_included: true,
      reimbursed_invoice_date: getToday(),
      reimbursed_bank_account: defaultBankAccount,
      reimbursed_bill_priority: {
        id: 'normal',
        label: 'Normal'
      },
      reimbursed_to: externalInitialValues?.payable_by,
      ...externalInitialValues
    };
  });

  const handleSubmit = useInvoiceSubmitHandler({
    isBillProcessingDialog: true,
    async onSave() {
      await refreshLists();
    }
  });

  const handleFirstChange = React.useCallback(() => {
    isFirstChangeRef.current = true;
  }, []);

  const handleReimbursementEmptyState = React.useCallback((state: boolean) => {
    setIsReimbursementEmptyStateVisible(state);
  }, []);

  return (
    <MultiItemDialog
      // This is provided to reset the initialValues when default bank account is available
      key={`${isLoading}-${isDefaultBankLoading}`}
      content={content}
      data={initialValues}
      nextButtonLabel={'Skip this bill'}
      removeButtonLabel={'Remove bill'}
      recordTypePluralized={'bills'}
      subTitleSuffix={'to process'}
      onClose={onClose}
      title={'Create invoice'}
      isLoading={isLoading || isDefaultBankLoading}
      handleSubmit={handleSubmit}
      size={'xl'}
      blockProps={{
        hasBillProcessingFeature,
        activeTab,
        setActiveTab,
        isReimbursementEmptyStateVisible,
        handleReimbursementEmptyState,
        isFirstChange: isFirstChangeRef.current,
        handleFirstChange: handleFirstChange,
        isInvoiceDialog: true
      }}
      {...props}
    />
  );
}
