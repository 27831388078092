import React from 'react';

import { OutlineButton } from '@rexlabs/button';

import { KeySet } from '../data/key-set-model';
import { useGetCheckOutKeySetAction } from '../hooks/use-get-check-out-key-set-action';

export function CheckOutKeySetButton({ data }: { data: KeySet }) {
  const getCheckOutKeyAction = useGetCheckOutKeySetAction();

  const { label, onClick } = getCheckOutKeyAction(data);
  return (
    <OutlineButton role='button' onClick={onClick}>
      {label}
    </OutlineButton>
  );
}
