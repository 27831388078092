import { Generator, GeneratorConfig } from 'data/models/generator';
import {
  ChartOfAccountsAccount,
  ChartOfAccountsAccountStatus
} from 'src/modules/chart-of-accounts/types/chart-of-account-types';
import { StatusTagMap } from 'src/modules/common/components/status-tag';

const config: GeneratorConfig = {
  entities: {
    related: {}
  }
};

export const chartOfAccountsAccountStatusMap: StatusTagMap<ChartOfAccountsAccountStatus> = {
  archived: 'bad',
  active: 'good'
};

export const chartOfAccountsAccountModel = new Generator<ChartOfAccountsAccount>(
  'financials/chart-of-accounts',
  config
).createEntityModel();
