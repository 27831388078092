import Box from '@rexlabs/box';
import { StyleSheet, useStyles } from '@rexlabs/styling';
import { CellProps, DateCell } from '@rexlabs/table';
import { Truncate } from '@rexlabs/text';
import dayjs from 'dayjs';
import * as React from 'react';

const defaultStyles = StyleSheet({
  grey: {
    color: ({ token }) => token('color.textStyle.body.subtext')
  }
});

export function DateTimeCell({ value, cell, column }: CellProps) {
  const s = useStyles(defaultStyles);
  const { cellProps: { format = 'hh:mma', emptyValue = '-' } = {} } = cell;

  return (
    <Box gap={4} flexDirection='column'>
      <DateCell value={value} column={column} />
      <Truncate {...s('grey')}>
        {value ? dayjs(value).format(format) : emptyValue}
      </Truncate>
    </Box>
  );
}
