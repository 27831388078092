import React from 'react';
import { RecordDialog } from 'view/components/record-screen/dialog/dialog';
import { servicePackageDetailsBlock } from 'src/modules/service-packages/blocks/service-package-details-block';
import { useModelActions } from '@rexlabs/model-generator';
import { servicePackagesModel } from 'src/modules/service-packages/models/service-packages-model';
import { RecordSubmitHandler } from 'view/components/record-screen/utils';
import { useRecordCreatedToast } from 'src/modules/common/toasts/hooks/use-record-created-toast';
import { useErrorDialog } from '@rexlabs/dialog';
import { ServicePackage } from 'src/modules/service-packages/types/service-package';
import { useQueryClient } from 'react-query';

const content = [
  {
    id: 'service-package',
    label: 'Service package',
    blocks: [servicePackageDetailsBlock]
  }
];

export function CreateServicePackageDialog({ onClose }) {
  const queryClient = useQueryClient();
  const { createItem, refreshLists } = useModelActions(servicePackagesModel);
  const addToast = useRecordCreatedToast(servicePackagesModel, { actions: [] });
  const { open: openErrorDialog } = useErrorDialog();

  const handleSubmit: RecordSubmitHandler<ServicePackage> = async ({
    changedValues
  }) => {
    try {
      const { data } = await createItem({ data: changedValues });
      addToast(data);
      await refreshLists();
      await queryClient.refetchQueries({
        // AllRecordItemsSelect uses this query key to fetch the list of service packages
        queryKey: ['all-service-packages'],
        refetchPage: () => true
      });
      return data;
    } catch (error) {
      openErrorDialog(error);
    }
  };

  return (
    <RecordDialog
      title={'Create service package'}
      content={content}
      handleSubmit={handleSubmit}
      onClose={onClose}
      blockProps={{ isCreating: true }}
    />
  );
}
