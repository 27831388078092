import { NormalisedItem } from '@rexlabs/select';

import { Invoice } from 'data/models/entities/financials/invoices';

import { priorityMap } from 'src/modules/invoices/components/invoice-priority-select';

export const normaliseInvoice = (item: Invoice): NormalisedItem => {
  const priorityId = item?.bill_priority?.id;
  const Icon = priorityId ? priorityMap[priorityId] : null;

  return {
    id: item.id,
    label: `${item.record_reference}: ${item.description}`,
    Icon
  };
};
