import { Message } from '@rexlabs/notifications';
import React from 'react';
import { Card } from 'view/components/card';
import EmptyBatchReceipting from 'src/assets/illustrations/empty-batch-receipting-list.svg';
import ArrowUpCircle from 'view/components/icons/arrow-up-circle';
import { useGetUploadBankTransactionsAction } from '../../hooks/use-get-upload-bank-transactions-action';

export function AllActiveTabEmptyState() {
  const uploadBankTransactionsAction = useGetUploadBankTransactionsAction();

  return (
    <Card>
      <Message
        title='Add transactions from your bank'
        Illustration={EmptyBatchReceipting}
        actions={[
          {
            ...uploadBankTransactionsAction,
            Icon: ArrowUpCircle
          }
        ]}
      >
        Upload your bank statement to start batch receipting.
      </Message>
    </Card>
  );
}
