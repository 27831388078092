import { Generator, GeneratorConfig } from 'data/models/generator';
import { BaseFields, RecordTypes } from 'data/models/types';
import { FileUpload } from 'src/modules/common/types/file';

export interface EmailLayout
  extends BaseFields<typeof RecordTypes.EmailLayout> {
  name: string;
  template: string;
  images: FileUpload[];
}

const config: GeneratorConfig = {
  entities: {
    related: {
      images: {
        include: 'images'
      }
    }
  }
};

export const communicationEmailLayoutsModel = new Generator<EmailLayout>(
  'communication/email-layouts',
  config
).createEntityModel();
