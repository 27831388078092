import { NormalisedItem } from '@rexlabs/select';
import { FinancialPeriod } from 'src/modules/statements/types';
import { formatDate } from 'utils/dates/format';

export const normaliseFinancialPeriod = (
  item: FinancialPeriod
): NormalisedItem => {
  return {
    id: `${item.startDate}-${item.endDate}`,
    label: `${formatDate(item.startDate)} - ${formatDate(item.endDate)}`
  };
};
