import { PendingContactDisbursement } from 'data/models/entities/financials/pending-disbursements/pending-contact-disbursement';
import React from 'react';
import { BlockConfig } from 'view/components/record-screen/types';
import { RecordTable } from 'view/components/table';

import { EmptyCard } from 'view/components/record-screen/cards/empty-card';
import { Message } from '@rexlabs/notifications';
import { Trans, useTranslation } from 'react-i18next';
import { useBaseDisbursementMethodsColumns } from '../hooks/use-base-disbursement-methods-columns';
import EmptyTrustJournalEntriesTable from '../../../assets/illustrations/empty-trust-journal-entries-table.svg';
import { DisbursementPaymentMethodsEdit } from '../components/disbursement-payment-methods-edit';
import { contactDisbursementPaymentMethodsBlock } from './contact-disbursement-payment-methods';

export const contactDisbursementBlock: BlockConfig<PendingContactDisbursement> = {
  id: 'contactDisbursement',
  title: () => <Trans i18nKey='disbursements.contact-method' />,
  validate: contactDisbursementPaymentMethodsBlock.validate!,
  View: ({ data }) => {
    const disbursementMethodsColumns = useBaseDisbursementMethodsColumns();

    return (
      <RecordTable
        columns={disbursementMethodsColumns}
        items={data?.object?.disbursement_payment_methods}
        isLoading={false}
      />
    );
  },
  Edit: ({ values, data }) =>
    DisbursementPaymentMethodsEdit({
      values,
      data
    }),
  showEmpty: (data) => !data.object.disbursement_payment_methods?.length,
  Empty: ({ onEditClick }) => {
    const { t } = useTranslation();
    return (
      <EmptyCard>
        <Message
          title={t('disbursements.setup-contact-method')}
          Illustration={EmptyTrustJournalEntriesTable}
          actions={[
            {
              label: t('disbursements.add-method') as string,
              type: 'primary',
              handleAction: onEditClick
            }
          ]}
        >
          {t('disbursements.empty-contact-method')}
        </Message>
      </EmptyCard>
    );
  }
};
