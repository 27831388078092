import React, { useCallback } from 'react';

import { Select, SelectProps, useValueList } from '@rexlabs/select';

import {
  normaliseValueListItem,
  ValueListItem
} from 'utils/normaliser/value-list-item';
import { getValueListItems } from 'utils/api/get-value-list-items';

export type ValueListSelectProps = SelectProps<ValueListItem> & {
  type: string;
  filter?: (items: ValueListItem[]) => ValueListItem[];
};

export const ValueListSelect = ({
  type,
  filter,
  ...props
}: ValueListSelectProps) => {
  const getItems = useCallback(() => getValueListItems(type, filter), [
    type,
    filter
  ]);

  const { getSelectProps } = useValueList<ValueListItem>({
    getItems
  });

  return (
    <Select
      {...getSelectProps()}
      normaliser={normaliseValueListItem}
      {...props}
    />
  );
};
