import { useDialog } from '@rexlabs/dialog';

import { KeySet } from '../data/key-set-model';
import { ManageKeySetDialog } from '../dialogs/manage-key-set-dialog';

export function useGetManageKeySetAction() {
  const { open: openManageKeySetDialog } = useDialog(ManageKeySetDialog);

  return (key: KeySet) => {
    return {
      id: 'manage-key-set',
      label: 'Manage key set',
      intent: 'primary',
      onClick: () => openManageKeySetDialog({ keyId: key.id })
    };
  };
}
