import React, {
  ComponentType,
  PropsWithChildren,
  ReactElement,
  useMemo
} from 'react';

import {
  padding,
  StyleSheet,
  StylesProvider,
  useStyles,
  useToken
} from '@rexlabs/styling';
import Box from '@rexlabs/box';
import { CustomCellProps, TableProps } from '@rexlabs/table';
import { Field } from '@rexlabs/form';
import { GhostIconButton, OutlineButton } from '@rexlabs/button';

import { RemoveIcon } from 'view/components/icons/remove';

import { EmptyState } from 'view/components/states/compact/empty';
import { AlfredColumnConfig } from 'view/components/table/columns/column-types';
import { TableProvider, TableProviderProps } from './provider';
import { Table } from './table';

function DefaultEmpty() {
  return <EmptyState>No invoice line items have been added yet.</EmptyState>;
}

function FieldCell({ cell, column }) {
  const { value } = cell;
  const {
    cellProps: { name, inputProps, Input }
  } = column;

  const fieldName = `${value}.${name}`;

  return <Field name={fieldName} inputProps={inputProps} Input={Input} />;
}

function RemoveCell({ cell }) {
  const remove = cell?.value?.remove;
  return (
    <Box mt='.3rem' id='remove-item' data-role='remove-item'>
      <GhostIconButton Icon={RemoveIcon} onClick={remove} />
    </Box>
  );
}

function getViewColumns({ columns }) {
  return [
    ...columns.map((column) => ({
      ...column,
      id: column?.name,
      accessor: column?.name,
      cellProps: {
        ...column?.cellProps,
        align: column?.align
      },
      Header: column?.Header ?? column?.label
    }))
  ];
}

function getEditColumns({ columns }) {
  return [
    ...columns.map((column) => ({
      ...column,
      id: column?.name,
      accessor: (item) => item?.field?.name,
      cellProps: {
        ...column?.cellProps,
        name: column?.name,
        align: column?.align,
        inputProps: column?.inputProps,
        Input: column?.Input
      },
      Header: column?.Header ?? (() => column?.label),
      Cell: column?.Cell ?? FieldCell
    })),
    {
      id: 'remove',
      accessor: (item) => item?.actions,
      width: 56,
      Header: () => null,
      Cell: RemoveCell
    }
  ];
}

interface LineItemsColumnConfig<T = any> extends AlfredColumnConfig<T> {
  label?: string;
  name?: string;
  align?: CustomCellProps<any>['align'];
  inputProps?: { [key: string]: any };
  // TODO: Use input type
  Input?: ComponentType<PropsWithChildren<any>>;
}

export type LineItemsColumns<ItemType> = LineItemsColumnConfig<ItemType>[];

export interface LineItemsTableProps<ItemType>
  extends Omit<TableProviderProps<ItemType>, 'columns' | 'children'>,
    Omit<TableProps, 'summary' | 'items'> {
  columns: LineItemsColumns<ItemType>;
  summary?: string | ReactElement;
  push?: () => void;
  // custom item name, if provided, it will be used in the "add line items" button text.
  itemName?: string;
}

const defaultStyles = StyleSheet({
  tableWrapper: {
    background: ({ token }) => token('palette.grey.200'),
    borderRadius: 8,
    padding: ({ token }) => token('spacing.xl')
  }
});

const baseOverrides = {
  table: {
    row: {
      background: {
        color: ({ token }) => token('palette.grey.200')
      }
    }
  }
};

const editModeOverrides = {
  table: {
    header: {
      ...padding.tokens({
        x: 'xxs'
      })
    },
    cell: {
      ...padding.tokens({
        x: 'xxs'
      })
    }
  }
};

function getRowId(row) {
  return row?.field?.name ?? row?.id;
}

function LineItemsTable<T>({
  items,
  columns: propColumns,
  summary,
  droppableId,
  push,
  itemName = '',
  Empty = DefaultEmpty,
  Loading,
  ...props
}: LineItemsTableProps<T>) {
  const token = useToken();
  const s = useStyles(defaultStyles);

  // TODO: Is there a better way to figure out whether we want
  // an edit mode table? Should we just explicitly send a prop?
  const editMode = useMemo(() => propColumns.some((column) => column.Input), [
    propColumns
  ]);

  const columns = useMemo(() => {
    return editMode
      ? getEditColumns({
          columns: propColumns
        })
      : getViewColumns({
          columns: propColumns
        });
  }, [editMode, propColumns]);

  const overrides = useMemo(() => {
    if (editMode) {
      return { ...baseOverrides, ...editModeOverrides };
    }
    return baseOverrides;
  }, [editMode, baseOverrides, editModeOverrides]);

  return (
    <StylesProvider tokens={overrides}>
      <TableProvider
        {...props}
        items={items}
        columns={columns}
        getRowId={getRowId}
      >
        <Box {...s('tableWrapper')}>
          <Table droppableId={droppableId} Empty={Empty} Loading={Loading} />
        </Box>
        {editMode ? (
          <Box flexWrap='wrap' mt={token('spacing.s')}>
            <OutlineButton
              data-testid='line-items-add'
              onClick={() => push?.()}
              style={{
                width: '100%',
                // TODO: Fix - spacing props are giving me
                // extra scrollbars
                marginBottom: token('spacing.s')
              }}
            >
              {`Add ${itemName}` || 'Add line item'}
            </OutlineButton>
            {summary}
          </Box>
        ) : (
          <Box flexWrap='wrap' mt={token('spacing.s')}>
            {summary}
          </Box>
        )}
      </TableProvider>
    </StylesProvider>
  );
}

export { LineItemsTable };
