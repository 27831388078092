import React from 'react';
import { useTranslation } from 'react-i18next';

import { Field } from '@rexlabs/form';
import { NumberInput, TextArea, TextInput } from '@rexlabs/text-input';

import { Grid } from 'view/components/@luna/form/grid';
import { BlockConfig } from 'view/components/record-screen/types';
import { PreviewFileUploadInputWithErrorHandling } from 'view/components/inputs/file-upload-input/preview-file-upload-input-with-error-handling';

import i18n from 'src/modules/i18n/i18n';

import { KeySet } from '../data/key-set-model';

export type KeySetDetailsFormValues = Pick<
  KeySet,
  'name' | 'number_of_keys' | 'notes'
> &
  Partial<Pick<KeySet, 'id'>> & {
    images?: any[];
  };

export const keySetDetailsBlock: BlockConfig<
  KeySet,
  any,
  KeySetDetailsFormValues
> = {
  id: 'keyDetails',
  validate: {
    definitions: {
      name: {
        rules: 'required',
        name: i18n
          .t('key-sets.key-set-name.label.singular')
          .toLowerCase() as string
      }
    }
  },
  Edit: () => {
    const { t } = useTranslation();

    return (
      <Grid columns={1}>
        <Grid columns={4}>
          <Field
            name='name'
            label={t('key-sets.key-set-name.label.singular') as string}
            Input={TextInput}
            optional={false}
          />
          <Field
            name='number_of_keys'
            label='Number of keys'
            Input={NumberInput}
            optional
          />
        </Grid>
        <Field name='notes' label='Notes' Input={TextArea} />
        <Field<typeof PreviewFileUploadInputWithErrorHandling>
          id='images'
          name='images'
          label='Images of keys'
          Input={PreviewFileUploadInputWithErrorHandling}
          inputProps={{
            multi: true,
            accept: '.jpeg, .png, .jpg'
          }}
        />
        <div>Supported file formats: JPEG, JPG, PNG.</div>
      </Grid>
    );
  }
};
