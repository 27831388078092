import React, { FC } from 'react';
import { InputProps } from '@rexlabs/form';

import { Dropdown } from '@rexlabs/select-old';
import { toQuri } from '@rexlabs/quri';
import { api } from 'utils/api/api-client';
import { normaliseChartOfAccountsAccount } from 'utils/normaliser/chart-of-accounts-account';
import { useCachedItems } from 'view/hooks/api/cached-items';
import { ChartOfAccountsAccount } from 'src/modules/chart-of-accounts/types/chart-of-account-types';

const getChartOfAccountsFilter = () => {
  return toQuri([
    {
      field: 'parent_chart_of_accounts_account',
      op: 'eq',
      value: 'null'
    }
  ]);
};

export const ParentChartOfAccountsAccountSelect: FC<InputProps> = (props) => {
  const { loadItems } = useCachedItems(
    `chart-of-accounts-accounts/parents`,
    async () => {
      const filter = getChartOfAccountsFilter();
      const chartOfAccountsAccounts = await api.get(
        `/financials/chart-of-accounts?q=${filter}`,
        {
          per_page: 30
        }
      );
      return chartOfAccountsAccounts.data as ChartOfAccountsAccount[];
    }
  );

  return (
    <Dropdown
      items={loadItems}
      normaliser={normaliseChartOfAccountsAccount}
      {...props}
    />
  );
};
