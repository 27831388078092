import React, { useCallback } from 'react';

import { query } from '@rexlabs/model-generator';
import { DateCell, RecordCell } from '@rexlabs/table';

import ROUTES from 'routes/app';

import { transformActionDeclarationsToActionMenuItems } from 'utils/actions/transforms';

import { Columns, RecordTable } from 'view/components/table';

import { tasksModel } from '../../common/models/tasks-model';

import { WorkOrderTask } from '../types/WorkOrderTask';
import { useGetWorkOrderActions } from '../hooks/action-menu-items/use-get-work-order-actions';
import { MaintenanceTask } from '../../maintenance/types/MaintenanceTask';
import { ProgressDropdown } from '../components/progress-dropdown';
import { WORK_ORDERS_ROUTES } from '../routes';
import { workOrderQueryIncludesFragment } from '../data/work-order-query-includes-fragment';
import { WorkOrderActionCell } from './work-order-action-cell';
import { WorkOrderListEmptyState } from './work-order-list-empty-state';

const columns: Columns<WorkOrderTask> = [
  {
    id: 'workAssignedTo',
    width: 180,
    Header: 'Work assigned to',
    accessor: (item) => {
      return item?.details?.work_done_by;
    },
    Cell: RecordCell,
    cellProps: {
      labelKey: 'display_name',
      getCellLinkProps: (value) => {
        return {
          to: ROUTES.CONTACT,
          params: {
            contactId: value.id
          }
        };
      }
    }
  },
  {
    id: 'due_date',
    width: 130,
    Header: 'Completion date',
    Cell: DateCell
  },
  {
    id: 'details.amount',
    Header: 'Price',
    type: 'currency',
    width: 100,
    cellProps: {
      align: 'right'
    }
  },
  {
    id: 'status',
    Header: 'Status',
    Cell: ProgressDropdown,
    accessor: (item: WorkOrderTask) => item,
    width: 200,
    toCsv: (item: WorkOrderTask) => item.details?.status?.label ?? ''
  },
  {
    id: 'actionCell',
    Header: '',
    width: 180,
    Cell: WorkOrderActionCell,
    accessor: (item) => item
  }
];

const workOrderQuery = query`{
  ${tasksModel} {
    ${workOrderQueryIncludesFragment}
  }
}`;

export function WorkOrdersList({
  relatedTask
}: {
  relatedTask: MaintenanceTask;
}) {
  const getWorkOrderActions = useGetWorkOrderActions();

  const getActionMenuItems = React.useCallback(
    ({ item }) => {
      const actions = getWorkOrderActions(item);
      return transformActionDeclarationsToActionMenuItems(actions);
    },
    [getWorkOrderActions]
  );

  const EmptyState = useCallback(
    () => <WorkOrderListEmptyState data={relatedTask} />,
    [relatedTask]
  );

  const getRowLinkProps = ({ item }) => {
    return {
      to: WORK_ORDERS_ROUTES.WORK_ORDER_DETAILS,
      params: { workOrderId: item.id }
    };
  };

  return (
    <RecordTable
      id='work-orders-list'
      columns={columns}
      getQuery={() => workOrderQuery}
      Empty={EmptyState}
      getRowLinkProps={getRowLinkProps}
      emptyWithoutTable={true}
      getActionMenuItems={getActionMenuItems}
      forcedGlobalFilter={[
        { field: 'type_id', op: 'eq', value: 'task_work_order' },
        {
          field: 'parent_task_id',
          op: 'eq',
          value: relatedTask.id
        }
      ]}
    />
  );
}
