import React from 'react';

import { Field } from '@rexlabs/form';
import { RadioGroupInput } from '@rexlabs/radio-input';

import { Grid } from 'view/components/@luna/form/grid';
import { InfoBanner } from 'view/components/@luna/notifications/banner';
import { BlockConfig } from 'view/components/record-screen/types';
import InfoCircleIcon from 'view/components/icons/info';
import { Value } from 'view/components/values';

import { formatCurrency } from 'utils/formatters';
import { RentPaidToValues, Settings } from '../../hooks/useSettings';

export interface RadioOptionValueList<T> {
  value: T;
  label: string;
}

const rentPaidToDateOptionsMap: Record<
  RentPaidToValues,
  RadioOptionValueList<RentPaidToValues>
> = {
  effective_with_surplus: {
    value: 'effective_with_surplus',
    label: 'Effective with surplus'
  },
  periodic_with_surplus: {
    value: 'periodic_with_surplus',
    label: 'Periodic with surplus'
  },
  effective_and_periodic_with_surplus: {
    value: 'effective_and_periodic_with_surplus',
    label: 'Effective and periodic with surplus'
  }
};

const getRentPaidToDateConfigMap = () => {
  return {
    effective_with_surplus: {
      title: 'Effective with surplus',
      text: `The Effective Paid to Date, which you will see on the Tenant payment history and on the Disbursement statements, refers to the Paid to Date for rent, plus any Part Paid Amounts. For example: A tenant has a weekly rent for Oct 31 - Nov 6 of ${formatCurrency(
        70000
      )} (the daily rate is ${formatCurrency(
        10000
      )}), given that tenant pays ${formatCurrency(
        82500
      )} for rent, the Effective paid to date is Nov 7 + ${formatCurrency(
        2500
      )} part payment.`
    },
    periodic_with_surplus: {
      title: 'Periodic with surplus',
      text: `The Paid to period, which you will see on the Tenant payment history and on the Disbursement statements, refers to the Paid to period for rent, plus any Part Paid Amounts. For example: A tenant has a weekly rent for Oct 31 - Nov 6 of ${formatCurrency(
        70000
      )}, given that tenant pays ${formatCurrency(
        82500
      )} for rent, the Paid to period is Oct 31 - Nov 6 + ${formatCurrency(
        12500
      )} part payment.`
    }
  };
};

function getRentPaidToDateOption(value: RentPaidToValues) {
  const rentPaidToDateConfigMap = getRentPaidToDateConfigMap();

  return {
    ...rentPaidToDateOptionsMap[value],
    infoBannerConfig: (rentPaidToDateConfigMap[value]
      ? [rentPaidToDateConfigMap[value]]
      : Object.keys(rentPaidToDateConfigMap).map(
          (key) => rentPaidToDateConfigMap[key]
        )) as { title: string; text: string }[]
  };
}

export const rentPaidToDateSettingsBlock: BlockConfig<
  Settings,
  any,
  Pick<Settings, 'rent-paid-to-date'>
> = {
  id: 'rent-paid-to-date',
  title: 'Rent paid to date',
  View: ({ values }) => {
    const option = getRentPaidToDateOption(
      values?.['rent-paid-to-date'] || 'effective_with_surplus'
    );

    return (
      <Grid columns={1}>
        <Grid columns={2}>
          <Value label='Show paid to date as' value={option.label} />
        </Grid>
        <Grid columns={2}>
          {option.infoBannerConfig.map((config, index) => (
            <InfoBanner
              key={index}
              Icon={InfoCircleIcon}
              title={config.title}
              description={config.text}
            />
          ))}
        </Grid>
      </Grid>
    );
  },

  Edit: ({ values }) => {
    const option = getRentPaidToDateOption(
      values?.['rent-paid-to-date'] || 'effective_with_surplus'
    );
    const rentPaidToDateOptions = Object.values(rentPaidToDateOptionsMap);

    return (
      <Grid columns={1}>
        <Grid columns={2}>
          <Field
            Input={RadioGroupInput}
            inputProps={{
              options: rentPaidToDateOptions,
              orientation: 'horizontal'
            }}
            name='rent-paid-to-date'
            label='Show paid to date as'
            id='rent-paid-to-date'
            optional={false}
          />
        </Grid>
        <Grid columns={2}>
          {option.infoBannerConfig.map((config, index) => (
            <InfoBanner
              key={index}
              Icon={InfoCircleIcon}
              title={config.title}
              description={config.text}
            />
          ))}
        </Grid>
      </Grid>
    );
  }
};
