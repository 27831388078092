import { isEmpty, defaultsDeep } from 'lodash';
import { getUploadedFileMeta } from 'utils/files';
import { mapTaskFormDataToTaskCreateRequest } from '../../common/mappers/map-task-form-data-to-task-create-request';
import { TaskCreateRequest } from '../../common/types/TaskCreateRequest';
import { WorkOrderFormData } from '../types/WorkOrderFormData';
import { WorkOrderTask } from '../types/WorkOrderTask';

export async function mapWorkOrderFormDataToTaskEditRequest(
  formData: Partial<WorkOrderFormData>,
  currentData: WorkOrderTask
): Promise<Partial<TaskCreateRequest>> {
  // map the base values for the task
  const base = mapTaskFormDataToTaskCreateRequest(formData);
  const {
    details = {} as Partial<Pick<WorkOrderFormData, 'details'>>
  } = formData;

  if (formData.details?.images) {
    const images = await getUploadedFileMeta(formData.details.images || []);
    delete details['images'];
    details['images'] = images;
  }

  // Remove any null values
  Object.keys(details).forEach((key) => {
    if (details[key] === null) {
      delete details[key];
    }
  });

  return {
    ...base,
    ...(!isEmpty(details)
      ? {
          details: defaultsDeep(details, {
            status: details['status'] || currentData.details?.status,
            work_done_by_type:
              details['work_done_by_type'] ||
              currentData.details?.work_done_by_type
          })
        }
      : {})
  };
}
