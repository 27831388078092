import React from 'react';
import { get } from 'lodash';

import Box from '@rexlabs/box';
import { Field, FieldArray } from '@rexlabs/form';

import { FormLayout } from 'view/components/layouts/form/form-layout';
import { SingleLineFormLayout } from 'view/components/layouts/form/single-line-form-layout';
import { Value } from 'view/components/values';
import { ContactPaymentMethod } from 'view/components/inputs/selects/contact-payment-method';
import { Ownership } from 'data/models/entities/ownerships';

export function PaymentMethodsBasedOnIncomeAttributionForm({
  values
}: {
  values: Ownership;
}) {
  return (
    <FormLayout>
      <FieldArray name='disbursement_payment_methods'>
        {function PaymentMethods({ fields }) {
          return (
            <Box
              data-testid='disbursement-payment-method-row'
              flexDirection='column'
              gap={24}
            >
              {fields?.map?.(({ field }) => {
                const { name } = field;

                const contactFieldName = `${name}.contact`;

                const contactId = get(values, `${contactFieldName}.id`);

                return (
                  <SingleLineFormLayout key={contactId}>
                    <Value
                      testId='owner'
                      label='Owner'
                      value={get(values, contactFieldName)?.display_name}
                    />
                    <Field
                      id={`${name}.contact_payment_method`}
                      name={`${name}.contact_payment_method`}
                      label='Payment method'
                      Input={ContactPaymentMethod}
                      inputProps={{
                        disabled: !contactId,
                        contactId: contactId,
                        forDisbursements: true,
                        shouldReselectClearSelection: false
                      }}
                      optional={false}
                    />
                    <Value
                      label='Amount'
                      value={`${get(values, name)?.split_amount?.amount || 0}%`}
                    />
                  </SingleLineFormLayout>
                );
              })}
            </Box>
          );
        }}
      </FieldArray>
    </FormLayout>
  );
}
