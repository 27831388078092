import { DialogProps } from '@rexlabs/dialog';
import { useModelActions } from '@rexlabs/model-generator';
import React, { useCallback } from 'react';

import { api } from 'utils/api/api-client';

import { ComplianceType } from 'src/modules/compliance/common/models/compliance-types';

import { useToast } from 'view/components/@luna/notifications/toast';
import { RecordDialog } from 'view/components/record-screen/dialog/dialog';

import { settingsSupplierComplianceCategoriesModel } from 'data/models/entities/settings/supplier-compliance-categories';
import { ComplianceSettingsDialogButtonGroup } from 'src/modules/compliance/common/components/compliance-settings-dialog-button-group';
import { supplierComplianceCategoryDetailsBlock } from '../blocks/supplier-compliance-categories-details-block';

const content = [
  {
    id: 'details',
    label: 'Details',
    blocks: [supplierComplianceCategoryDetailsBlock]
  }
];

export function CreateComplianceCategoryDialog({ onClose }: DialogProps) {
  const { addToast } = useToast();
  const { refreshLists } = useModelActions(
    settingsSupplierComplianceCategoriesModel
  );

  const handleSubmit = useCallback(async ({ values }) => {
    const requirements = values.requirements || [];

    const { data } = await api.post('request-pipelines', {
      requests: [
        {
          method: 'POST',
          path: '/api/v1/settings/supplier-compliance-categories',
          json: { label: values.label, description: values.description }
        },
        ...requirements.map((requirement: ComplianceType) => ({
          method: 'POST',
          path: '/api/v1/settings/supplier-category-requirements',
          json: {
            supplier_compliance_category: {
              id: `{{$.0.id}}`
            },
            compliance_type: { id: requirement.id }
          }
        }))
      ]
    });

    addToast({
      description: (
        <>
          Service offered created - <b>{data[0].label}</b>
        </>
      )
    });
    await refreshLists();
    return data;
  }, []);

  return (
    <RecordDialog
      title='Create service offered'
      handleSubmit={handleSubmit}
      onClose={onClose}
      content={content}
      ButtonGroup={ComplianceSettingsDialogButtonGroup}
      size={'s'}
    />
  );
}
