import { api } from 'utils/api/api-client';
import { TaxType } from 'data/models/entities/financials/tax-types';
import { useCachedItems, Options } from './cached-items';

export async function loadTaxTypes() {
  const taxTypesRequest = await api.get('/financials/tax-types', {
    per_page: 250
  });
  return taxTypesRequest.data as TaxType[];
}

export function useTaxTypes(options?: Partial<Options<TaxType>>) {
  return useCachedItems('tax-types', loadTaxTypes, options);
}
