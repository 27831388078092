import { DialogProps } from '@rexlabs/dialog';
import * as React from 'react';
import { RecordDialog } from 'view/components/record-screen/dialog/dialog';
import { RecordSubmitHandler } from 'view/components/record-screen/utils';
import { useModelActions } from '@rexlabs/model-generator';
import { useToast } from 'view/components/@luna/notifications/toast';
import { FolioCommissionTemplate } from '../types/FolioCommissionTemplate';
import { supplierCommissionDetailsBlock } from '../blocks/supplier-commission-details';
import { folioCommissionTemplatesModel } from '../models/folio-commission-templates-model';

export interface UpdateSupplierCommissionTemplateDialogProps
  extends DialogProps {
  supplierCommissionTemplate?: FolioCommissionTemplate;
}

export function UpdateSupplierCommissionTemplateDialog({
  onClose,
  supplierCommissionTemplate
}: UpdateSupplierCommissionTemplateDialogProps) {
  const { addToast } = useToast();
  const { createItem, refreshLists } = useModelActions(
    folioCommissionTemplatesModel
  );

  const isEditMode = supplierCommissionTemplate != null;

  const title = isEditMode
    ? 'Update commission template'
    : 'Create commission template';

  const handleSubmit: RecordSubmitHandler = React.useCallback(
    async ({ values }) => {
      const {
        commission_amount_currency,
        commission_amount_percent,
        ...rest
      } = values;

      const payload = {
        ...rest,
        commission_amount: commission_amount_percent
          ? commission_amount_percent
          : commission_amount_currency
          ? commission_amount_currency
          : null
      };

      await createItem({
        data: payload
      });

      addToast({
        description: (
          <>
            Successfully created - <b>{values.name}</b>
          </>
        )
      });

      refreshLists();

      return true;
    },
    []
  );

  return (
    <RecordDialog
      title={title}
      handleSubmit={handleSubmit}
      content={[
        {
          id: 'details',
          label: 'details',
          blocks: [supplierCommissionDetailsBlock]
        }
      ]}
      onClose={onClose}
    />
  );
}
