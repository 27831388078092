import { useReactFormsContext } from '@rexlabs/form';
import { useEffect, useRef } from 'react';
import { isEqual, get } from 'lodash';

const ARRAY_INDICATOR = '*';

type FormContext = {
  values: any;
  setFieldValue: (field: string, value: any) => void;
};

const byNullish = (item: any) => {
  if (item === undefined) return false;
  if (item === null) return false;

  return true;
};

function getDepsArray(values: any, formFields: string[]) {
  const dependencyArray = formFields.flatMap((formField) => {
    try {
      if (formField.includes(ARRAY_INDICATOR)) {
        const [head, tail] = formField.split(`.${ARRAY_INDICATOR}.`);
        return get(values, head).map((value) => get(value, tail));
      }
      return get(values, formField);
    } catch (e) {
      return undefined;
    }
  });

  return dependencyArray.filter(byNullish);
}

type CallbackArgs = FormContext;

export function useFormValueEffect(
  blockId: string,
  callback: (formContext: CallbackArgs) => void,
  formFields: string[]
) {
  const formContext = useReactFormsContext();
  const oldDeps = useRef<any[]>();

  useEffect(() => {
    const matchBlockForm = blockId && formContext.id === blockId;
    const currentDeps = getDepsArray(formContext.values, formFields);
    const hasChanges = !isEqual(currentDeps, oldDeps.current);

    if (hasChanges && matchBlockForm) {
      callback(formContext);
    }

    oldDeps.current = currentDeps;
  }, [formContext, callback, formFields, blockId]);
}
