import React from 'react';
import { BlockConfig } from 'view/components/record-screen/types';
import { Field } from '@rexlabs/form';
import { TextInput } from '@rexlabs/text-input';
import { Grid } from 'view/components/@luna/form/grid';
import { DateInput } from 'view/components/@luna/inputs/date-input/date-input';
import { PropertyAreaSelect } from 'view/components/inputs/selects/v4-selects/property-area-select';
import { Appliance } from '../types/Appliance';

export const applianceDetailsBlock: BlockConfig<Appliance> = {
  id: 'appliance-details',
  validate: {
    definitions: {
      type: {
        rules: 'required',
        name: 'appliance type'
      }
    }
  },
  Edit: ({ values }) => {
    return (
      <Grid columns={2}>
        <Field name='type' label='Appliance type' Input={TextInput} />
        <div></div>
        {/*
        Note that the area name here is just saved as a string, this is mainly here to be helpful for the user
        (also it should hopefully be easier to migrate later if we want to actually save the area to the appliance)
        */}
        <Field
          name='location'
          id='location'
          label='Located in room'
          Input={PropertyAreaSelect}
          inputProps={{ property: values?.property }}
        />
        <div></div>
        <Field name='make' label='Make' Input={TextInput} />
        <Field name='model' label='Model' Input={TextInput} />
        <Field name='serial_number' label='Serial number' Input={TextInput} />
        <div></div>
        <Field name='installed_date' label='Date installed' Input={DateInput} />
        <Field
          name='warranty_expires_date'
          label='Warranty expires'
          Input={DateInput}
        />
      </Grid>
    );
  }
};
