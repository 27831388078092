import { DialogProps } from '@rexlabs/dialog';
import { useModelActions } from '@rexlabs/model-generator';
import * as React from 'react';
import { RecordDialog } from 'view/components/record-screen/dialog/dialog';
import { useRecordUpdatedToast } from 'src/modules/common/toasts/hooks/use-record-updated-toast';
import { PrepaymentBucket } from 'src/modules/prepayments/types/prepayment-bucket';
import { RecordSubmitHandler } from 'view/components/record-screen/utils';
import { rentPrepaymentBlock } from 'src/modules/prepayments/blocks/rent-prepayment-block';
import { prepaymentBucketsModel } from '../models/prepayment-buckets-model';

const content = [
  {
    id: 'edit-prepayments',
    label: 'Edit Prepayments',
    blocks: [rentPrepaymentBlock]
  }
];

interface UpdateRentPrepaymentBucketDialogProps extends DialogProps {
  prepaymentBucket: PrepaymentBucket;
}

export function UpdateRentPrepaymentBucketDialog({
  onClose,
  prepaymentBucket
}: UpdateRentPrepaymentBucketDialogProps) {
  const { updateItem, refreshLists } = useModelActions(prepaymentBucketsModel);
  const addToast = useRecordUpdatedToast(prepaymentBucketsModel, {
    actions: []
  });

  const handleSubmit: RecordSubmitHandler<{
    auto_allocate_funds: boolean;
  }> = async ({ changedValues }) => {
    if (!('auto_allocate_funds' in changedValues)) {
      return onClose?.();
    }

    const { data: updatedPrepaymentBucket } = await updateItem({
      id: prepaymentBucket.id,
      data: {
        auto_allocate_funds: changedValues.auto_allocate_funds
      }
    });

    addToast(updatedPrepaymentBucket);

    return refreshLists();
  };

  const initialValues = {
    auto_allocate_funds: prepaymentBucket.auto_allocate_funds
  };

  return (
    <RecordDialog
      title='Edit rent prepayment bucket'
      submitLabel={'Save'}
      data={prepaymentBucket}
      initialValues={initialValues}
      content={content}
      onClose={onClose}
      handleSubmit={handleSubmit}
      blockProps={{ isCreating: false }}
    />
  );
}
