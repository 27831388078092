import dayjs from 'dayjs';

import { Quri } from '@rexlabs/quri';

import { getSearchQuery } from 'src/lib/react-query/hooks/use-records-query';
import { Property } from 'src/modules/properties/types/property-types';
import { ComplianceEntry } from 'src/modules/compliance/common/models/compliance-entries';

import { SearchResultItem } from 'utils/api/get-search-results';
import { api } from 'utils/api/api-client';

import { SUPPORTED_RECORD_TYPES_FOR_CERTIFICATES_FROM_RECORD } from '../constants/SupportedRecordTypesForCertificates';

type SupportedRelatesToType = Property;

export async function getComplianceEntryFilesForRelatesTo<
  TRecord extends SupportedRelatesToType
>(relatesTo: SearchResultItem<TRecord>, complianceEntryTypeIds: string[]) {
  if (
    !relatesTo ||
    complianceEntryTypeIds.length === 0 ||
    !SUPPORTED_RECORD_TYPES_FOR_CERTIFICATES_FROM_RECORD.includes(
      relatesTo.type.id
    )
  ) {
    return [];
  }

  const { id } = relatesTo.record;

  const today = dayjs().format('YYYY-MM-DD');
  const query = getSearchQuery({
    filter: [
      { field: 'object_id', op: 'eq', value: id },
      { field: 'expires_at', op: 'gte', value: today },
      ...((complianceEntryTypeIds.length === 0
        ? []
        : [
            {
              op: 'or',
              value: complianceEntryTypeIds.map((type) => ({
                field: 'compliance_type_id',
                op: 'eq',
                value: type
              }))
            }
          ]) as Quri[])
    ],
    includes: ['file']
  });

  return api
    .get<ComplianceEntry[]>(`/compliance-entries?${query}`)
    .then((response) => {
      return response.data;
    });
}
