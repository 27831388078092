import React from 'react';
import { CellProps } from '@rexlabs/table';
import { TwoTierCell } from 'src/view/components/cells/two-tier-cell';

export function ChecklistNameCell({ value }: CellProps) {
  return (
    <TwoTierCell
      text={value?.label}
      subtext={`${value?.item_order?.length || 0} items`}
    />
  );
}
