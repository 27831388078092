import React from 'react';
import { TagCell } from '@rexlabs/table';

import { Columns, RecordTable } from 'view/components/table';
import { formatDate } from 'utils/dates/format';
import { ActionMenuItem } from '@rexlabs/action-menu';
import { RentScheduleEntry } from 'src/modules/property-tenancies/types/property-tenancy-types';
import { RentScheduleStatusTag } from './rent-schedule-status-tag';
import { RentAmountCell } from './rent-amount-cell';

type NeededRentScheduleEntry = Pick<
  RentScheduleEntry,
  | 'status'
  | 'effective_from_date'
  | 'effective_until_date'
  | 'amount_total_inc_tax'
  | 'frequency'
  | 'line_items'
> & { id: string };

const columns: Columns = [
  {
    id: 'status',
    Header: 'Status',
    Cell: (table) => {
      const {
        cell: { value }
      } = table;
      return (
        <TagCell>
          <RentScheduleStatusTag status={value} />
        </TagCell>
      );
    }
  },
  {
    id: 'effective_from_date_formatted',
    Header: 'Effective from date'
  },
  {
    id: 'effective_until_date_formatted',
    Header: 'Effective to date'
  },
  {
    id: 'rent_amount',
    Header: 'Rent amount',
    Cell: RentAmountCell,
    cellProps: {
      align: 'right'
    }
  }
];

function transformToTableItems<T extends NeededRentScheduleEntry>(item: T) {
  let effective_from_date_formatted;
  if (item.effective_from_date === null) {
    effective_from_date_formatted = 'Start of lease';
  } else {
    effective_from_date_formatted = formatDate(item.effective_from_date);
  }

  let effective_until_date_formatted;
  if (item.effective_until_date === null) {
    effective_until_date_formatted = 'End of lease';
  } else {
    effective_until_date_formatted = formatDate(item.effective_until_date);
  }

  return {
    ...item,
    effective_from_date_formatted,
    effective_until_date_formatted
  };
}

interface RentScheduleTableProps<T extends NeededRentScheduleEntry> {
  rentSchedule?: T[];
  onEntryDelete?: (entry: T, index: number) => void;
  onEntryEdit?: (entry: T, index: number) => void;
}

export function RentScheduleTable<T extends NeededRentScheduleEntry>({
  rentSchedule,
  onEntryDelete,
  onEntryEdit
}: RentScheduleTableProps<T>) {
  const items = rentSchedule?.map(transformToTableItems).reverse();

  const getActionMenuItems = ({ item }): ActionMenuItem[] => {
    const index =
      items?.findIndex((allItemsItem) => allItemsItem.id === item.id) ?? 0;

    return [
      {
        label: 'Edit',
        onClick: () => onEntryEdit?.(item, index)
      },
      {
        label: 'Delete',
        intent: 'danger',
        onClick: () => onEntryDelete?.(item, index)
      }
    ];
  };

  return (
    <RecordTable
      id='rent-schedule'
      withPagination={false}
      columns={columns}
      isLoading={false}
      items={items}
      getActionMenuItems={onEntryDelete ? getActionMenuItems : undefined}
    />
  );
}
