import React from 'react';
import { isEmpty } from 'lodash';

import { BlockConfig } from 'view/components/record-screen/types';
import { Field } from '@rexlabs/form';
import { Grid } from 'view/components/@luna/form/grid';
import { Contact } from 'src/modules/contacts/types/contact-types';
import { ContactSelect } from 'view/components/inputs/selects/contact';
import { SearchResultItem } from 'utils/api/get-search-results';
import { getRelatedRecords } from 'src/modules/financials/utils/get-related-records';
import { AdvancedFolioSearchEntitySelect } from '../components/advanced-folio-search-entity-select';
import { BatchReceiptingItem } from '../types';

const validate = {
  definitions: {
    folio: {
      name: 'folio',
      rules: 'required'
    },
    contacts: {
      name: 'received from',
      rules: 'required|required_array_items'
    }
  }
};

export const transactionMatchDetailsBlock: BlockConfig<BatchReceiptingItem> = {
  id: 'transaction-match-details',
  validate,
  Edit: ({ setFieldValue }) => {
    const [suggestedContacts, setSuggestedContacts] = React.useState<
      Contact[] | undefined
    >();

    /**
     * The purpose of this handler is to set the contact field after the folio is
     * selected. If the folio is a tenancy or ownership, we want to set the contact
     * field to the financial contact of the tenancy or ownership. If the folio is
     * a contact, we want to set the contact field to the selected folio.
     */
    async function onFolioChange(event) {
      const value: SearchResultItem = event.target.value;
      if (isEmpty(value)) {
        return;
      }
      if (!['tenancy', 'ownership'].includes(value.type.id)) {
        return setFieldValue?.('contacts', [value.record]);
      }

      const { financial_contact, otherContacts } = await getRelatedRecords({
        folioId: value.id,
        folioType: value.type.id
      });

      setFieldValue?.('contacts', [financial_contact]);
      setSuggestedContacts(otherContacts);
    }

    return (
      <Grid columns={2}>
        <Field<typeof AdvancedFolioSearchEntitySelect>
          id='folio'
          name='folio'
          label='Folio to receipt'
          Input={AdvancedFolioSearchEntitySelect}
          onChange={onFolioChange}
          inputProps={{
            objectTypes: ['contact', 'tenancy', 'ownership']
          }}
        />
        <Field
          id='received_from'
          name='contacts'
          label='Received from'
          Input={ContactSelect}
          optional={false}
          inputProps={{
            multi: true,
            disableFixture: true,
            getSuggestedItems: () => suggestedContacts
          }}
        />
      </Grid>
    );
  }
};
