import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import Box from '@rexlabs/box';

import { PendingDisbursement } from 'data/models/entities/financials/pending-disbursements';

import { BlockConfig } from 'view/components/record-screen/types';

import { SummaryTable } from '../../../view/components/summary-table';

import { useGetDisbursementSummaries } from '../hooks/use-get-disbursement-summaries';

export const pendingDisbursementSummaryBlock: BlockConfig<PendingDisbursement> = {
  id: 'pending_disbursement_summary',
  title: <Trans i18nKey='disbursements.blocks.summary-block.title' />,
  View: ({ data }) => {
    const { t } = useTranslation();
    const { disbursement_snapshot_summary: snapshotSummary, object } =
      data || {};

    const {
      openingFigures,
      thisDisbursement,
      closingFigures
    } = useGetDisbursementSummaries(snapshotSummary, object);

    return (
      <Box flexDirection='column'>
        <SummaryTable header='Opening figures' data={openingFigures} />
        <SummaryTable
          header={t(
            'disbursements.blocks.summary-block.sections.this-disbursement.label'
          )}
          data={thisDisbursement}
        />
        <SummaryTable header='Closing figures' data={closingFigures} />
      </Box>
    );
  }
};
