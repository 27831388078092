import { query } from '@rexlabs/model-generator';
import { bankDepositModel } from 'src/modules/banking/bank-deposits/models/bank-deposit-model';
import { Quri, toQuri } from '@rexlabs/quri';

export const getFilterByTrustAccountId = (trustAccountId: string): Quri[] => {
  return [
    {
      field: 'trust_account_id',
      op: 'eq',
      value: trustAccountId
    }
  ];
};

export const getBankDepositsQuery = (filters?: Quri[]) => ({
  ...query`{
    ${bankDepositModel} ${filters && `(q: ${toQuri(filters)})`} {
          id
          status
          created_at
          line_items {
            created_by
            updated_by
          }
          totals
      }
  }`,
  uuid: 'bank-deposit-list'
});
