import React, { useCallback } from 'react';
import { DialogProps } from '@rexlabs/dialog';
import { useModelActions } from '@rexlabs/model-generator';
import { useToast } from 'view/components/@luna/notifications/toast';
import { RecordDialog } from 'view/components/record-screen/dialog/dialog';
import { RecordSubmitHandler } from 'view/components/record-screen/utils';
import { portfoliosModel } from 'src/modules/portfolios/models/portfolios-model';
import { addMemberBlock } from '../blocks/add-member';
import { portfolioMembersModel } from '../models/portfolio-members-model';

const content = [
  {
    id: 'add-member',
    label: 'Add member',
    blocks: [addMemberBlock]
  }
];

interface AddMemberDialogProps extends DialogProps {
  portfolioId: string;
}

export function AddMemberDialog({
  onClose,
  portfolioId
}: AddMemberDialogProps) {
  const { addToast } = useToast();
  const { refreshItem: refreshPortfolio } = useModelActions(portfoliosModel);
  const { createItem: addPortfolioMember } = useModelActions(
    portfolioMembersModel
  );

  const handleSubmit: RecordSubmitHandler = useCallback(
    async ({ values }) => {
      const { data: member } = await addPortfolioMember({
        data: { ...values, portfolio: { id: portfolioId } }
      });

      addToast({ description: 'Successfully added member to portfolio' });
      await refreshPortfolio({ id: portfolioId });
      return member;
    },
    [addPortfolioMember, portfolioId, addToast, refreshPortfolio]
  );

  return (
    <RecordDialog
      title='Add member'
      onClose={onClose}
      content={content}
      handleSubmit={handleSubmit}
      submitLabel='Add'
      blockProps={{ portfolioId }}
    />
  );
}
