import React from 'react';

import { IncomeAndExpenseTable } from 'src/modules/account-journal-entries/components/income-and-expense-table';
import { BlockConfig } from 'view/components/record-screen/types';
import { ActionButtons } from 'view/components/action-buttons';
import { useFeatureFlags } from 'view/components/@luna/feature-flags';
import { FLAGS } from 'utils/feature-flags';
import { useDialog } from '@rexlabs/dialog';
import { CreateManualAccountingJournalEntryDialog } from '../dialogs/create-manual-accounting-journal-entry';

export const incomeAndExpenseBlock: BlockConfig = {
  id: 'incomeAndExpense',
  title: 'Income and expense',
  View: (props) => {
    return <IncomeAndExpenseTable object={props.data} />;
  },
  Actions: ({ data }) => {
    const { hasFeature } = useFeatureFlags();
    const { open } = useDialog(CreateManualAccountingJournalEntryDialog);

    return hasFeature(FLAGS.MANUAL_ACCOUNTING_JOURNAL_ENTRIES) ? (
      <ActionButtons
        actions={[
          {
            label: 'Create entry',
            onClick: () =>
              open({
                folio: data
              })
          }
        ]}
      />
    ) : null;
  }
};
