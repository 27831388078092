import { useGetPrimaryRecordActionGroup } from 'view/hooks/actions/use-get-primary-record-action-group';
import { propertyTenancyModel } from 'src/modules/property-tenancies/models/property-tenancy-model';
import { ActionDeclaration } from 'src/modules/common/actions/types/action-declaration-types';
import { useStatusChangeAction } from 'src/modules/common/actions/status/use-status-change-action';
import {
  PropertyTenancy,
  PropertyTenancyStatusType
} from 'src/modules/property-tenancies/types/property-tenancy-types';
import { useDeleteAction } from 'src/modules/common/actions/delete/use-delete-action';
import { useModelActions } from '@rexlabs/model-generator';
import { propertiesModel } from 'data/models/entities/properties';
import { useEditRecordDetailsAction } from 'src/modules/common/actions/record-details/hooks/use-edit-record-details-action';
import { useGetOpenRegenerateInvoicesDialogAction } from './use-get-open-regenerate-invoices-dialog-action';

export function usePropertyTenancyActions() {
  const getPrimaryRecordActionGroup = useGetPrimaryRecordActionGroup();
  const setStatusAction = useStatusChangeAction(propertyTenancyModel);
  const deleteAction = useDeleteAction(propertyTenancyModel);
  const { refreshItem: refreshProperty } = useModelActions(propertiesModel);
  const getEditRecordAction = useEditRecordDetailsAction(propertyTenancyModel);
  const getOpenRegenerateInvoicesDialogAction = useGetOpenRegenerateInvoicesDialogAction();

  return (propertyTenancy?: PropertyTenancy) => {
    if (!propertyTenancy) return [];

    const afterStatusChange = () => {
      refreshProperty({
        id: propertyTenancy.property_id,
        args: {
          include:
            'active_property_ownership,active_property_ownership.ownership,active_property_ownership.ownership.owners.contact,active_property_ownership.ownership.financial_summary,active_property_tenancy,active_property_tenancy.tenancy.tenants.contact,compliance_categories,features,images.file,incoming_property_tenancy,incoming_property_tenancy.tenancy.tenants.contact,incoming_property_ownership,incoming_property_ownership.ownership,incoming_property_ownership.ownership.owners.contact,links,views,portfolio.users,portfolio.users.contact,created_by,updated_by'
        }
      });
    };

    const activateAction = setStatusAction<PropertyTenancyStatusType>({
      record: propertyTenancy,
      status: 'active',
      dialogOptions: {
        type: 'none',
        afterAction: afterStatusChange
      }
    });

    const archiveAction = setStatusAction<PropertyTenancyStatusType>({
      record: propertyTenancy,
      status: 'archived',
      dialogOptions: {
        afterAction: afterStatusChange
      }
    });

    const outgoingAction = setStatusAction<PropertyTenancyStatusType>({
      record: propertyTenancy,
      status: 'outgoing',
      dialogOptions: {
        afterAction: afterStatusChange
      }
    });

    const incomingAction = setStatusAction<PropertyTenancyStatusType>({
      record: propertyTenancy,
      status: 'incoming',
      dialogOptions: {
        afterAction: afterStatusChange
      }
    });

    const draftAction = setStatusAction<PropertyTenancyStatusType>({
      record: propertyTenancy,
      status: 'draft',
      dialogOptions: {
        afterAction: afterStatusChange
      }
    });

    const statusActions: ActionDeclaration[] = [];
    switch (propertyTenancy.status.id) {
      case 'active':
        statusActions.push(
          draftAction,
          incomingAction,
          outgoingAction,
          archiveAction
        );
        break;
      case 'draft':
        statusActions.push(
          activateAction,
          incomingAction,
          outgoingAction,
          archiveAction,
          deleteAction(propertyTenancy, {
            afterAction: afterStatusChange
          })
        );
        break;
      case 'incoming':
        statusActions.push(
          draftAction,
          activateAction,
          outgoingAction,
          archiveAction
        );
        break;
      case 'outgoing':
        statusActions.push(
          draftAction,
          activateAction,
          incomingAction,
          archiveAction
        );
        break;
      case 'archived':
        statusActions.push(
          draftAction,
          activateAction,
          incomingAction,
          outgoingAction
        );
        break;
    }

    return [
      ...getPrimaryRecordActionGroup(
        'property_tenancy',
        propertyTenancy.id,
        'View',
        propertyTenancy.property_id
      ),
      getEditRecordAction(propertyTenancy),
      getOpenRegenerateInvoicesDialogAction(propertyTenancy),
      ...statusActions
    ];
  };
}
