import { PendingContactDisbursement } from 'data/models/entities/financials/pending-disbursements/pending-contact-disbursement';
import { PendingOwnershipDisbursement } from 'data/models/entities/financials/pending-disbursements/pending-ownership-disbursement';
import React from 'react';
import { Columns } from 'view/components/table';
import { createStaticTableBlock } from 'view/components/record-screen/cards/create-static-table-block';
import { Message } from 'view/components/@luna/message';
import EmptyTrustJournalEntriesIllustration from 'assets/illustrations/empty-trust-journal-entries-table.svg';
import { TrustJournalEntryPreview } from 'src/modules/trust-journal-entries/types/trust-journal-entry-types';
import { Trans, useTranslation } from 'react-i18next';

const trustJournalPreviewColumns: Columns = [
  {
    id: 'type',
    type: 'value',
    Header: 'Type',
    width: 140
  },
  {
    id: 'paid_to',
    type: 'record',
    Header: 'Paid to',
    width: 200
  },
  {
    id: 'description',
    Header: 'Description',
    width: 300
  },
  {
    id: 'withdrawal_details.method',
    type: 'value',
    Header: 'Payment method',
    width: 140
  },
  {
    id: 'amount',
    type: 'currency',
    Header: 'Amount',
    cellProps: {
      align: 'right'
    },
    width: 140
  }
];

export const trustJournalPreviewBlock = createStaticTableBlock<
  PendingOwnershipDisbursement | PendingContactDisbursement,
  TrustJournalEntryPreview
>({
  id: 'trustJournalPreview',
  title: (
    <Trans i18nKey='financials.trust.trust-journal-preview.label.singular' />
  ),
  getTableProps: ({ data }) => {
    return {
      id: 'trust-journal-preview',
      items:
        data?.trust_journal_entry_preview?.data.map((tje, index) => ({
          ...tje,
          id: index
        })) ?? [],
      columns: trustJournalPreviewColumns
    };
  },
  Empty: () => {
    const { t } = useTranslation();
    return (
      <Message
        Illustration={EmptyTrustJournalEntriesIllustration}
        title={t('trust-journal-entries.label.plural')}
      >
        {t('trust-journal-entries.no-trust-journal-entries-exist.message')}
      </Message>
    );
  }
});
