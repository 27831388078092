import React, { useCallback } from 'react';
import { push, RouteProps } from '@rexlabs/whereabouts';
import { Field, Form, ReactForms } from '@rexlabs/form';
import { ButtonGroup, LinkButton, PrimaryButton } from '@rexlabs/button';
import { PasswordInput } from '@rexlabs/password-input';
import { Column } from '@rexlabs/grid';
import Box from '@rexlabs/box';
import icons from '@rexlabs/icons';
import { FormGrid } from 'view/components/@luna/grid/form';
import ROUTES from 'routes/app';
import { useModelActions } from '@rexlabs/model-generator';
import { sessionModel } from 'data/models/custom/session';
import { useApiClient } from 'src/lib/axios/hooks/use-api-client';
import { UniversalUser } from 'src/modules/universal-users/types/universal-user-type';
import { PrimaryHeading } from '../components/heading-primary';

const { ChevronLeft } = icons;

const validate = {
  definitions: {
    password: { rules: 'required|min:6|same:password_confirmation' },
    password_confirmation: { rules: 'required|min:6' }
  }
};

export function ResetPasswordScreen({ match }: RouteProps) {
  const passwordToken = match?.query?.token as string;
  const { login } = useModelActions(sessionModel);
  const axios = useApiClient();

  const handleSubmit = useCallback(
    async (values) => {
      const res = await axios<UniversalUser>(
        'post',
        `/auth/reset-password-complete`,
        {
          token: passwordToken,
          new_password: values.password
        }
      );
      if (res !== false) {
        await login({
          email: res.data.email,
          password: values.password
        }).then(() => {
          push(ROUTES.HOME);
        });
      }
    },
    [axios, login, passwordToken]
  );

  return (
    <ReactForms validate={validate} handleSubmit={handleSubmit}>
      {({ submitForm, isSubmitting }) => (
        <Form>
          <Box mb='2rem'>
            <LinkButton
              onClick={() => push(ROUTES.LOGIN)}
              IconLeft={ChevronLeft}
            >
              Back to login
            </LinkButton>
          </Box>
          <PrimaryHeading level='display'>
            Create a new password.
          </PrimaryHeading>

          <FormGrid mt='1.6rem'>
            <Column width={12}>
              <Field
                Input={PasswordInput}
                name='password_confirmation'
                label='New Password'
              />
            </Column>
            <Column width={12}>
              <Field
                Input={PasswordInput}
                name='password'
                label='Confirm New Password'
              />
            </Column>

            <Column width={12} mt='1.6rem'>
              <ButtonGroup end>
                <PrimaryButton
                  isLoading={isSubmitting}
                  onClick={() => {
                    submitForm();
                  }}
                >
                  Reset password
                </PrimaryButton>
              </ButtonGroup>
            </Column>
          </FormGrid>
        </Form>
      )}
    </ReactForms>
  );
}
