/**
 * This hook returns a function, that when an array of table ids is passed to it, it will return a hash query object
 * with the page key set to undefined. This can be used with whereabouts push to reset the page number in the tables.
 */
export function getResetTablesPageNumberHashQuery(tableIds: string[]) {
  return tableIds.reduce(
    (acc, id) => ({
      ...acc,
      [`${id}_page`]: undefined
    }),
    {}
  ) as Record<string, string | undefined>;
}
