import _ from 'lodash';
import { RecordTypes } from 'data/models/types';

//TODO: this should probably also handle the list screens as well but we would need to translate the record types
//      to singular
export function getRecordTypeFromUrlPath() {
  const pathName = window.location.pathname;
  const parts = pathName.split('/');

  const part = parts.slice(-2)[0];

  if (!part) {
    throw new Error('could not retrieve the record type from the pathname');
  }

  const snake = _.snakeCase(part);

  const potentialMatch = Object.values(RecordTypes).find((e) => e === snake);

  if (potentialMatch) {
    return potentialMatch;
  }

  switch (part) {
    case 'pending-contacts-disbursement':
      return RecordTypes.PendingContactDisbursement;
    default:
      throw new Error(`could not resolve ${part}`);
  }
}
