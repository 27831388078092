import {
  ChecklistTemplateItem,
  ChecklistTemplateItemGroup
} from '../types/ChecklistTemplate';

/**
 * When determining the previous item, we need to look at all previous groups of the same level and find the last item in the last group.
 */
export function getLastPopulatedGroup(
  allItems: ChecklistTemplateItem[],
  allGroups: ChecklistTemplateItemGroup[],
  checklistTemplateItemGroup: ChecklistTemplateItemGroup
) {
  // if all else fails, the parent will be the previous item
  const parentItem = allItems?.find(
    (item) =>
      item.id ===
      checklistTemplateItemGroup?.triggered_by_checklist_template_item?.id
  );
  const groupsForParent = allGroups.filter(
    (group) => group.triggered_by_checklist_template_item?.id === parentItem?.id
  );
  const precedingGroups = groupsForParent
    .sort((a, b) => {
      // sort by the order the groups triggered by value appears in the parent outcome options
      const options = parentItem?.outcome_data?.outcome_options ?? [];
      const aIndex = options.findIndex(
        (option) => option.id === a.triggered_by_value
      );
      const bIndex = options.findIndex(
        (option) => option.id === b.triggered_by_value
      );
      return aIndex - bIndex;
    })
    // get a slice of the array from the start, up to the group we are looking at, so we only consider the groups that come before it
    .slice(
      0,
      groupsForParent?.findIndex(
        (group) => group.id === checklistTemplateItemGroup?.id
      ) ?? 0
    );

  // now walk back through the groups to find the last item in the last group
  for (let i = precedingGroups.length - 1; i >= 0; i--) {
    const group = precedingGroups[i];
    const itemsForGroup = allItems?.filter(
      (item) => item.checklist_template_item_group?.id === group.id
    );
    if (itemsForGroup?.length) {
      return group;
    }
  }

  return null;
}
