import React, { useCallback } from 'react';
import {
  RecordDialog,
  RecordDialogProps
} from 'view/components/record-screen/dialog/dialog';
import { RecordType } from 'data/models/types';
import { RecordData } from 'utils/types';
import { RecordSubmitHandler } from 'view/components/record-screen/utils';
import { ButtonGroupProps } from 'view/components/record-screen/dialog/types';
import { DialogButtonGroup } from '../buttons/dialog-button-group';
import { CreateRecordDialogFooter } from './create-record-dialog-footer';
import { getTitle } from './get-title';
import { getFixtureSubmitLabel } from './get-submit-label';

/**
 * To avoid rendering the button group more often then we need to, we wrap the component in React.memo
 * This prevents the button from rerendering when an input is blurred, which is the case when someone
 * edits a text field and clicks on the submit button without having blurred the input first
 *
 * To display information based on the form values we pass forms into the button group, which changes when a text field is blurred
 * leading to rerendering the button, leading to swallowing the click on the button.
 *
 * To fix this we need to make sure we dont pass a changed resetAll function or handleSubmit to the ButtonGroup. I dont know how we would fix this in the scenario that we use the forms object to change the label of the submit button
 */
const CreateRecordDialogFooterMemo = React.memo(
  CreateRecordDialogFooter,
  (prevProps, nextProps) => {
    if (prevProps.recordType !== nextProps.recordType) return false;
    // if (prevProps.resetAll !== nextProps.resetAll) return false;
    // if (prevProps.handleSubmit !== nextProps.handleSubmit) return false;
    if (prevProps.onClose !== nextProps.onClose) return false;
    if (prevProps.isSubmitting !== nextProps.isSubmitting) return false;
    if (prevProps.disableRedirect !== nextProps.disableRedirect) return false;

    return true;
  }
);

export type HandleRecordCreate<Record extends RecordData> = (
  ...args: Parameters<RecordSubmitHandler>
) => Promise<Record>;

export type CreateRecordDialogProps<Record extends RecordData> = Omit<
  RecordDialogProps,
  'ButtonGroup' | 'SubmitButton' | 'title' | 'handleSubmit'
> & {
  recordType: RecordType;
  isFixture?: boolean;
  onSubmit: HandleRecordCreate<Record>;
  title?: React.ReactNode;
};

export function CreateRecordDialog<Record extends RecordData>({
  recordType,
  onSubmit,
  isFixture,
  ...props
}: CreateRecordDialogProps<Record>) {
  const ButtonGroup = useCallback(
    (props: ButtonGroupProps) => {
      if (isFixture)
        return (
          <DialogButtonGroup
            {...props}
            submitLabel={getFixtureSubmitLabel(recordType)}
          />
        );

      return (
        <CreateRecordDialogFooterMemo {...props} recordType={recordType} />
      );
    },
    [isFixture]
  );

  return (
    <RecordDialog
      {...props}
      title={props.title || getTitle(recordType)}
      handleSubmit={onSubmit}
      ButtonGroup={ButtonGroup}
    />
  );
}
