import React from 'react';

import { CellProps, TagCell } from '@rexlabs/table';
import { StatusBadTag, StatusGoodTag } from '@rexlabs/tag';
import {
  Disbursement,
  DisbursementStatusType
} from 'data/models/entities/financials/disbursements/disbursements';
import { ValueListValue } from 'data/models/types';

function getTag({ id, label }: ValueListValue<DisbursementStatusType>) {
  switch (id) {
    case 'disbursed':
      return <StatusGoodTag>{label}</StatusGoodTag>;
    case 'voided':
    case 'processing_void':
      return <StatusBadTag>{label}</StatusBadTag>;
  }
}

export function CompletedDisbursementStatusCell({
  value
}: CellProps<unknown, Disbursement['status']>) {
  return <TagCell>{getTag(value)}</TagCell>;
}
