import { Contact } from 'src/modules/contacts/types/contact-types';

import { Ownership } from 'data/models/entities/ownerships';

import { SnapshotSummary } from '../types/SnapshotSummary';
import { useClosingFiguresSummary } from './use-closing-figures-summary';
import { useThisDisbursementSummary } from './use-this-disbursement-summary';
import { useOpeningFiguresSummary } from './use-opening-figures-summary';

export function useGetDisbursementSummaries(
  disbursementSnapshot?: SnapshotSummary | null,
  object?: Contact | Ownership
) {
  const openingFigures = useOpeningFiguresSummary(
    disbursementSnapshot?.folio_pre_disbursement,
    object
  );

  const thisDisbursement = useThisDisbursementSummary(
    disbursementSnapshot?.disbursement,
    object
  );

  const closingFigures = useClosingFiguresSummary(
    disbursementSnapshot?.folio_post_disbursement,
    object
  );

  return {
    openingFigures,
    thisDisbursement,
    closingFigures
  };
}
