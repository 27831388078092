import React from 'react';
import { Field } from '@rexlabs/form';
import { TextArea } from '@rexlabs/text-input';
import { Checkbox } from '@rexlabs/checkbox';
import { MaintenanceInstructions } from 'data/models/entities/ownerships';
import { BlockConfig } from 'view/components/record-screen/types';
import { Column, Grid } from 'view/components/@luna/form/grid';
import { Value } from 'view/components/values';
import { CurrencyValue } from 'view/components/values/currency';
import { CentAmountInput } from 'view/components/inputs/cent-amount';

export function getMaintenanceInstructionsBlock<
  Data = { maintenance_instructions: MaintenanceInstructions }
>(): BlockConfig<Data> {
  return {
    id: 'maintenance-instructions',
    title: 'Maintenance instructions',
    View: ({ data }) => {
      // TODO: fix type
      // eslint-disable-next-line
      // @ts-ignore
      const instructions = data?.maintenance_instructions;
      return (
        <Grid columns={2}>
          {instructions?.is_inherited_from_ownership !== undefined && (
            <Value
              label='Is inherited from Ownership'
              value={instructions?.is_inherited_from_ownership ? 'Yes' : 'No'}
            />
          )}
          <CurrencyValue
            label='Spend limit'
            value={instructions?.spend_limit}
          />
          <Column width={2}>
            <Value label='Notes' value={instructions?.notes} />
          </Column>
        </Grid>
      );
    },
    Edit: ({ values }) => {
      const isPropertyOwnership =
        values?.maintenance_instructions?.is_inherited_from_ownership !==
        undefined;
      const isDisabled =
        isPropertyOwnership &&
        values?.maintenance_instructions?.is_inherited_from_ownership;

      return (
        <Grid columns={2}>
          {isPropertyOwnership && (
            <Field
              label='Inherit'
              name='maintenance_instructions.is_inherited_from_ownership'
              Input={Checkbox}
              inputProps={{ label: 'Inherit from onwership' }}
            />
          )}
          <Field
            name='maintenance_instructions.spend_limit'
            label='Spend limit'
            Input={CentAmountInput}
            inputProps={{ disabled: isDisabled }}
          />
          <Column width={2}>
            <Field
              name='maintenance_instructions.notes'
              label='Notes'
              Input={TextArea}
              inputProps={{ disabled: isDisabled }}
            />
          </Column>
        </Grid>
      );
    }
  };
}
