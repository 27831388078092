import { makeIconComponent } from '@rexlabs/icons';

import SmallRelatedRecordsSvg from 'assets/icons/s/related-records.svg';
import MediumRelatedRecordsSvg from 'assets/icons/m/related-records.svg';
import LargeRelatedRecordsSvg from 'assets/icons/l/related-records.svg';
import ExtraLargeRelatedRecordsSvg from 'assets/icons/xl/related-records.svg';

export const RelatedRecordsIcon = makeIconComponent({
  s: SmallRelatedRecordsSvg,
  m: MediumRelatedRecordsSvg,
  l: LargeRelatedRecordsSvg,
  xl: ExtraLargeRelatedRecordsSvg
});

export default RelatedRecordsIcon;
