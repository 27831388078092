import React from 'react';

import { Field } from '@rexlabs/form';
import Box from '@rexlabs/box';

import { BlockConfig } from 'view/components/record-screen/types';
import { Grid } from 'view/components/@luna/form/grid';
import { EmptyState } from 'view/components/states/compact/empty';
import { ImagePreview } from 'view/components/image-preview';
import { PreviewFileUploadInputWithErrorHandling } from 'view/components/inputs/file-upload-input/preview-file-upload-input-with-error-handling';

import { MaintenanceTask } from '../types/MaintenanceTask';
import { MaintenanceFormData } from '../types/MaintenanceFormData';

export const attachmentsBlock: BlockConfig<
  MaintenanceTask,
  any,
  MaintenanceFormData
> = {
  id: 'attachments',
  title: 'Attachments',
  View: ({ data }) => {
    const hasNoAttachments =
      !Array.isArray(data?.details?.images) ||
      data?.details?.images?.length === 0;

    if (hasNoAttachments) {
      return <EmptyState />;
    }

    return (
      <>
        <Box spacing='.8rem' flexDirection='row' flexWrap='wrap'>
          {data!.details!.images!.map((image, index) => {
            return (
              <ImagePreview
                src={image?.file.url}
                key={image?.file.id || index}
                downloadUrl={image?.file.download_url}
              />
            );
          })}
        </Box>
      </>
    );
  },

  Edit: () => {
    return (
      <Grid columns={1}>
        <Field<typeof PreviewFileUploadInputWithErrorHandling>
          id='details.attachments'
          name='details.attachments'
          Input={PreviewFileUploadInputWithErrorHandling}
          inputProps={{
            sortable: true,
            isDropzone: true,
            multi: true
          }}
        />
      </Grid>
    );
  }
};
