import * as React from 'react';
import { ActionDeclaration } from 'src/modules/common/actions/types/action-declaration-types';
import { getRecordLabel } from 'utils/records/get-record-label';
import { getRecordObject } from 'src/modules/common/utils/Records/get-record-object';
import { useDeleteAction } from 'src/modules/common/actions/delete/use-delete-action';
import { messagesModel } from '../../models/messages-model';
import { Message } from '../../types/Message';

export function useGetDeleteMessageAction() {
  const getDeleteAction = useDeleteAction(messagesModel);

  return (message: Message): ActionDeclaration => {
    const deleteAction = getDeleteAction(message, {
      message: (
        <div>
          Are you sure you wish to delete the message{' '}
          <strong>
            {getRecordLabel(getRecordObject(message, messagesModel))}
          </strong>
        </div>
      )
    });

    return deleteAction;
  };
}
