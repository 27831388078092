import React, { ComponentType, ReactNode, useCallback } from 'react';
import invariant from 'invariant';

import { border, padding, StyleSheet, useStyles } from '@rexlabs/styling';
import Box from '@rexlabs/box';
import { Link, LinkProps } from '@rexlabs/whereabouts';
import Tooltip, { TooltipProps } from '@rexlabs/tooltip';

import { ActionDeclaration } from 'src/modules/common/actions/types/action-declaration-types';
import { WorkInProgress } from 'view/components/work-in-progress';

import { ContentSmall } from './content/small';
import { ContentLarge } from './content/large';

const defaultStyles = StyleSheet({
  link: {
    display: 'flex',
    flex: 1,
    textDecoration: 'none',
    ...border.styles({ all: { radius: 'l' } }),

    '&, &:hover': {
      color: 'inherit'
    }
  },

  container: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    textDecoration: 'none',

    ...border.styles({
      all: {
        width: 'thin',
        radius: 'l',
        color: () => 'transparent'
      }
    }),

    background: ({ token }) => token('palette.white'),

    '&, &:hover': {
      color: 'inherit'
    }
  },

  containerNeutral: {
    ...border.styles({
      all: {
        color: 'container.static.light'
      }
    }),

    background: ({ token }) => token('color.container.static.contrast')
  },

  containerSecondary: {
    ...border.styles({
      all: {
        color: 'secondary.idle.contrast'
      }
    }),

    background: ({ token }) => token('color.container.static.secondary.default')
  },

  containerSuccess: {
    ...border.styles({
      all: {
        color: 'success.idle.contrast'
      }
    }),

    background: ({ token }) => token('color.container.static.success.default')
  },

  containerDanger: {
    ...border.styles({
      all: {
        color: 'danger.idle.contrast'
      }
    }),

    background: ({ token }) => token('color.container.static.danger.default')
  },

  containerWarning: {
    ...border.styles({
      all: {
        color: 'warning.idle.default'
      }
    }),

    background: ({ token }) => token('color.container.static.warning.default')
  },

  containerInformation: {
    ...border.styles({
      all: {
        color: 'information.idle.contrast'
      }
    }),

    background: ({ token }) =>
      token('color.container.static.information.default')
  },

  containerSmall: {
    ...padding.styles({ y: 's', x: 'm' }),
    ...border.styles({
      all: {
        width: 'none'
      }
    })
  },

  containerLarge: {
    ...padding.styles({ all: 'xl' })
  }
});

const DEFAULT_SIZE = 's';

export type StatBlockIntent =
  | 'neutral'
  | 'secondary'
  | 'success'
  | 'danger'
  | 'warning'
  | 'information';

interface SharedStatBlockProps {
  label: ReactNode;
  value: ReactNode;
}

interface SmallStatBlockProps extends SharedStatBlockProps {
  size?: 's';
  valueTag?: ReactNode;
  description?: never;
  actions?: never;
  Icon?: never;
}

interface LargeStatBlockProps extends SharedStatBlockProps {
  size?: 'l';
  valueTag?: never;
  description?: ReactNode;
  actions?: ActionDeclaration[];
  Icon?: ComponentType<any>;
}

export type StatBlockProps = Pick<
  LinkProps,
  'to' | 'query' | 'hash' | 'children'
> &
  (SmallStatBlockProps | LargeStatBlockProps) & {
    label: ReactNode;
    value?: ReactNode;
    intent?: StatBlockIntent;
    workInProgress?: boolean;
    'data-testid'?: string;
    ToolTipContent?: TooltipProps['Content'];
  };

export function StatBlock({
  size = DEFAULT_SIZE,
  label,
  value,
  description,
  valueTag,
  intent,
  actions,
  to,
  query,
  hash,
  Icon,
  workInProgress,
  ToolTipContent,
  'data-testid': dataTestId
}: StatBlockProps) {
  invariant(['s', 'l'].includes(size), 'Invalid size prop');

  const s = useStyles(defaultStyles);

  const isLink = !!(to || query || hash);

  const Container = workInProgress ? WorkInProgress : Box;

  const TooltipWrapper = useCallback(
    ({ children }) =>
      ToolTipContent ? (
        <Tooltip Content={ToolTipContent}>{children}</Tooltip>
      ) : (
        <>{children}</>
      ),
    [ToolTipContent]
  );

  const LinkWrapper = useCallback(
    ({ children }) =>
      isLink ? (
        <Link {...s('link')} {...{ to, hash, query }}>
          {children}
        </Link>
      ) : (
        <>{children}</>
      ),
    [isLink, to, hash, query]
  );

  return (
    <LinkWrapper>
      <TooltipWrapper>
        <Container
          {...s('container', {
            containerNeutral: intent === 'neutral',
            containerSecondary: intent == 'secondary',
            containerSuccess: intent === 'success',
            containerDanger: intent === 'danger',
            containerWarning: intent === 'warning',
            containerInformation: intent === 'information',
            containerSmall: size === 's',
            containerLarge: size === 'l'
          })}
          data-testid={dataTestId || 'stat-block'}
        >
          {size === 's' ? (
            <ContentSmall
              isLink={isLink}
              intent={intent}
              label={label}
              value={value}
              valueTag={valueTag}
            />
          ) : size === 'l' ? (
            <ContentLarge
              intent={intent}
              label={label}
              description={description}
              value={value}
              actions={actions}
              Icon={Icon}
            />
          ) : null}
        </Container>
      </TooltipWrapper>
    </LinkWrapper>
  );
}
