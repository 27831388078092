import { LinkButton } from '@rexlabs/button';
import React from 'react';
import { useGetCreateTaxPaymentAction } from '../hooks/use-get-create-tax-payment-action';
import { TaxSummary } from '../models/tax-summaries-model';

export function IssuePaymentActionCell({ value }: { value: TaxSummary }) {
  const getCreateTaxPaymentAction = useGetCreateTaxPaymentAction();
  const action = getCreateTaxPaymentAction(value);

  return (
    <LinkButton
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        action.handleAction?.();
      }}
    >
      {action.label}
    </LinkButton>
  );
}
