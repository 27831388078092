import ActionMenu from '@rexlabs/action-menu';
import Box from '@rexlabs/box';
import { GhostIconButton } from '@rexlabs/button';
import { StyleSheet, useStyles, text } from '@rexlabs/styling';
import * as React from 'react';
import DragIcon from 'view/components/icons/drag';
import KebabIcon from 'view/components/icons/kebab';
import {
  DraggableProvidedDragHandleProps,
  DraggableProvidedDraggableProps
} from 'react-beautiful-dnd';
import { useGetUpdatePropertyAreaAction } from 'src/modules/property-areas/property-areas/hooks/action-declarations/use-get-update-property-area-action';
import { PropertyArea } from 'src/modules/property-areas/property-areas/types/PropertyArea';
import { pluralize } from 'utils/formatters';
import { useGetDeletePropertyAreaAction } from 'src/modules/property-areas/property-areas/hooks/action-declarations/use-get-delete-property-area-action';

export type PropertyAreaCardProps = {
  propertyArea: PropertyArea;
  dragHandleProps: DraggableProvidedDragHandleProps;
  dragContainerProps: DraggableProvidedDraggableProps;
  children: React.ReactNode;
};

const styles = StyleSheet({
  root: {
    borderRadius: 8,
    background: ({ token }) => token('color.container.static.contrast')
  },

  subtleText: {
    ...text.styles({ fallback: 'small.default' }),
    color: ({ token }) => token('color.textStyle.body.subtext')
  },

  dragHandle: {
    lineHeight: 0,
    appearance: 'none',
    padding: 0,
    margin: 0,
    border: 'none',
    background: 'transparent',

    '&:focus': {
      boxShadow: 'none'
    }
  }
});

export const PropertyAreaCard = React.forwardRef<
  HTMLElement,
  PropertyAreaCardProps
>(function (props, ref) {
  const { propertyArea, dragHandleProps, dragContainerProps, children } = props;
  const title = propertyArea.name;
  const summary = propertyArea.property_area_type?.name ?? 'Custom';
  const count = propertyArea.items!.data.length;

  const s = useStyles(styles);

  return (
    <Box
      ref={ref}
      padding={14}
      gap={12}
      alignItems='center'
      {...dragContainerProps}
      {...s('root')}
    >
      <button
        type='button'
        aria-label='drag item'
        {...s('dragHandle')}
        {...dragHandleProps}
      >
        <DragIcon />
      </button>

      <Box flexDirection='row' gap={12} flex={1} justifyContent='space-between'>
        <div>
          <div>{title}</div>

          <Box flexDirection='row' gap={4} {...s('subtleText')}>
            <div>{summary}</div>
            <div>•</div>
            <div>
              {count} inspection {pluralize('item', count)}
            </div>
          </Box>
        </div>

        {children}
      </Box>
    </Box>
  );
});

interface PropertyAreaActionMenuProps {
  propertyId: string;
  propertyArea: PropertyArea;
  areaOrder: Array<string>;
  updateAreaOrder: (newOrder: Array<string>) => void;
}

export function PropertyAreaActionMenu(props: PropertyAreaActionMenuProps) {
  const { propertyArea, propertyId, areaOrder, updateAreaOrder } = props;

  const getUpdatePropertyAreaAction = useGetUpdatePropertyAreaAction();
  const getDeletePropertyAreaAction = useGetDeletePropertyAreaAction();

  return (
    <ActionMenu
      Button={GhostIconButton}
      Icon={KebabIcon}
      placement='bottom-end'
      items={[
        getUpdatePropertyAreaAction(propertyArea, propertyId),
        getDeletePropertyAreaAction(
          propertyArea,
          propertyId,
          areaOrder,
          updateAreaOrder
        )
      ]}
    />
  );
}
