import React, { useMemo } from 'react';

import Box from '@rexlabs/box';
import {
  useStyles,
  useToken,
  StyleSheet,
  StylesProvider,
  text,
  margin
} from '@rexlabs/styling';
import Icons from '@rexlabs/icons';
import { Tag } from '@rexlabs/tag';

import { StatBlockProps } from '..';

const { ChevronRight } = Icons;

const defaultStyles = StyleSheet({
  label: {
    display: 'flex',
    whiteSpace: 'nowrap',

    ...text.styles({ fallback: 'small.default' })
  },

  labelGrey: {
    color: ({ token }) => token('color.textStyle.body.subtext')
  },

  wrapValue: {
    height: '2.4rem'
  },

  value: {
    display: 'flex',
    whiteSpace: 'nowrap',

    ...text.styles({ fallback: 'normal.bold' }),
    ...margin.styles({ right: 'xs' })
  },

  valueSuccess: {
    color: ({ token }) => token('color.textStyle.success.idle.default')
  },

  valueDanger: {
    color: ({ token }) => token('color.textStyle.danger.idle.default')
  },

  valueInformation: {
    color: ({ token }) => token('color.textStyle.information.idle.default')
  },

  chevron: {
    display: 'flex'
  }
});

const tagColours = {
  success: ({ token }) => token('color.container.static.success.contrast'),
  warning: ({ token }) => token('color.container.static.warning.contrast'),
  danger: ({ token }) => token('color.container.static.danger.contrast'),
  information: ({ token }) =>
    token('color.container.static.information.contrast'),
  neutral: ({ token }) => token('color.neutral.idle.default'),
  secondary: ({ token }) => token('color.container.static.secondary.contrast')
};

function getTagOverrides(intent) {
  return {
    tag: {
      background: {
        color: tagColours[intent]
      }
    }
  };
}

interface ContentSmallProps
  extends Pick<StatBlockProps, 'label' | 'intent' | 'value' | 'valueTag'> {
  isLink?: boolean;
}

export function ContentSmall({
  isLink,
  label,
  intent,
  value,
  valueTag
}: ContentSmallProps) {
  const s = useStyles(defaultStyles);
  const token = useToken();

  const tagOverrides = useMemo(() => getTagOverrides(intent), [intent]);

  return (
    <StylesProvider tokens={tagOverrides}>
      <Box sy={token('spacing.xxxs')} flexDirection='column'>
        <span {...s('label', { labelGrey: !intent || intent === 'neutral' })}>
          {label}
        </span>
        <Box {...s('wrapValue')} alignItems='center'>
          <Box
            data-testid='stat-block-value'
            {...s('value', {
              valueSuccess: intent === 'success',
              valueDanger: intent === 'danger',
              valueInformation: intent === 'information'
            })}
          >
            {value || '—'}
          </Box>
          {valueTag && (
            <Tag size='s' data-testid='stat-block-tag'>
              {valueTag}
            </Tag>
          )}
        </Box>
      </Box>
      {isLink && (
        <Box {...s('chevron')}>
          <ChevronRight size='m' />
        </Box>
      )}
    </StylesProvider>
  );
}
