import { Document, documentsModel } from 'data/models/entities/documents';
import { downloadFile } from 'utils/files';
import { useDeleteAction } from 'src/modules/common/actions/delete/use-delete-action';
import { useDialog } from '@rexlabs/dialog';
import { EditDocumentDialog } from 'src/modules/documents/dialogs/edit-document-dialog';
import { ActionDeclaration } from 'src/modules/common/actions/types/action-declaration-types';
import {
  canPreviewDocument,
  getViewDocumentPropsFromObject,
  useGetViewDocumentAction
} from './use-get-view-documents-action';

export function useDocumentActions() {
  const deleteAction = useDeleteAction(documentsModel);
  const { open: openEditDialog } = useDialog(EditDocumentDialog);
  const getViewDocumentAction = useGetViewDocumentAction();

  return (document: Document) => {
    const downloadAction: ActionDeclaration = {
      label: 'Download',
      group: 'download',
      handleAction: () => downloadFile(document.file.download_url)
    };

    const editAction: ActionDeclaration = {
      label: 'Edit',
      group: 'edit',
      handleAction: () => {
        openEditDialog({ documentId: document.id });
      }
    };

    return [
      ...(document.file && canPreviewDocument(document.file.type)
        ? [getViewDocumentAction(getViewDocumentPropsFromObject(document))]
        : []),
      editAction,
      ...(document.file ? [downloadAction] : []),
      deleteAction(document)
    ];
  };
}
