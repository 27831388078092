import { flatten } from 'lodash';

import { Property } from 'src/modules/properties/types/property-types';
import { PropertyOwnership } from 'src/modules/property-ownerships/types/property-ownership-types';
import { Contact } from 'src/modules/contacts/types/contact-types';
import { PropertyTenancy } from 'src/modules/property-tenancies/types/property-tenancy-types';

import { Ownership } from 'data/models/entities/ownerships';

import { api } from 'utils/api/api-client';

const relatedFields = [
  'property_tenancies',
  'property_tenancies.tenancy',
  'property_tenancies.tenancy.tenants',
  'property_tenancies.tenancy.tenants.contact',
  'property_ownerships',
  'property_ownerships.ownership',
  'property_ownerships.ownership.contact',
  'property_ownerships.ownership.owners.contact'
];

export async function getPropertyRelatedItems(propertySearchItem: Property) {
  const propertyUrl = `/properties/${
    propertySearchItem.id
  }?include=${relatedFields.join(',')}`;

  const completeProperty =
    !propertySearchItem?.property_ownerships ||
    !propertySearchItem?.property_tenancies
      ? await api.get<Property>(propertyUrl).then(({ data }) => data)
      : propertySearchItem;

  const allPropertyOwnerships: PropertyOwnership[] =
    // Note: when we use the api get method, the property_ownerships is returned in the data object
    // as we're not using the relationship through model generator.
    completeProperty?.property_ownerships?.['data'] ||
    completeProperty?.property_ownerships?.items;
  const allOwnerships: Ownership[] = allPropertyOwnerships.map(
    (propertyOwnership) => propertyOwnership.ownership
  );
  const allOwners: Contact[] = flatten(
    allOwnerships.map((ownership) =>
      (ownership?.owners || []).map((owner) => owner.contact)
    )
  );

  const activePropertyOwnership = allPropertyOwnerships.find(
    (propertyOwnership) => propertyOwnership?.status.id === 'active'
  );
  const activeOwnership = activePropertyOwnership?.ownership;
  const activeOwners = (activeOwnership?.owners || []).map(
    (owner) => owner.contact
  );

  const outgoingPropertyOwnership = allPropertyOwnerships.find(
    (propertyOwnership) => propertyOwnership?.status.id === 'outgoing'
  );
  const outgoingOwnership = outgoingPropertyOwnership?.ownership;
  const outgoingOwners = (outgoingOwnership?.owners || []).map(
    (owner) => owner.contact
  );

  const allPropertyTenancies: PropertyTenancy[] =
    // Note: when we use the api get method, the property_tenancies is returned in the data object
    // as we're not using the relationship through model generator.
    completeProperty?.property_tenancies?.['data'] ||
    (completeProperty?.property_tenancies?.items as PropertyTenancy[]);
  const allTenancies = allPropertyTenancies.map(
    (propertyTenancy) => propertyTenancy.tenancy
  );
  const allTenants = flatten(
    allTenancies.map((tenancy) =>
      (tenancy?.tenants || []).map((tenant) => tenant.contact)
    )
  );
  const activePropertyTenancy = allPropertyTenancies.find(
    (propertyTenancy) => propertyTenancy?.status.id === 'active'
  );
  const activeTenancy = activePropertyTenancy?.tenancy;
  const activeTenants = (activeTenancy?.tenants || []).map(
    (tenant) => tenant.contact
  );

  const outgoingPropertyTenancy = allPropertyTenancies.find(
    (propertyTenancy) => propertyTenancy?.status.id === 'outgoing'
  );
  const outgoingTenancy = outgoingPropertyTenancy?.tenancy;
  const outgoingTenants = (outgoingTenancy?.tenants || []).map(
    (tenant) => tenant.contact
  );

  return {
    allPropertyOwnerships,
    allOwnerships,
    allOwners,

    activePropertyOwnership,
    activeOwnership,
    activeOwners,

    outgoingPropertyOwnership,
    outgoingOwnership,
    outgoingOwners,

    allPropertyTenancies,
    allTenancies,
    allTenants,

    activePropertyTenancy,
    activeTenancy,
    activeTenants,

    outgoingPropertyTenancy,
    outgoingTenancy,
    outgoingTenants
  };
}
