import { valueListFactory } from 'src/lib/testing/factories/value-list-factory';
import { capitalize } from 'lodash';
import { ValueListValue } from 'data/models/types';
import {
  ChecklistItemActionType,
  ChecklistItemDueDateOffsetDirection,
  ChecklistItemDueDateType,
  ChecklistTemplateItem,
  ChecklistTemplateItemDueDateData
} from '../types/ChecklistTemplate';
import {
  ChecklistTemplateItemEditForm,
  ChecklistTemplateItemEditFormDueDate
} from '../types/ChecklistTemplatesForm';
import { itemIsContact } from '../../common/components/checklist-assignee-select';
import { mapTriggerFormDataToTriggerItem } from './map-trigger-form-data-to-trigger';

export function getDueDateData({
  dueDate
}: {
  dueDate: ChecklistTemplateItemEditFormDueDate;
}): ChecklistTemplateItemDueDateData {
  const dueDateType = dueDate?.due_date_type;
  const offset = dueDate?.offset_related_date?.offset;
  const offsetUnitId = dueDate?.offset_related_date?.unit?.id;

  switch (dueDateType) {
    case 'none':
      return {
        offset_amount: null,
        offset_direction: null,
        offset_unit: null,
        related_date: null
      };
    case 'offset_related_date':
      return {
        offset_amount: Number(dueDate.offset_related_date.count),
        offset_direction: offset
          ? valueListFactory(
              offset.id.split('_')[0] as ChecklistItemDueDateOffsetDirection
            )
          : null,
        offset_unit: offsetUnitId ? valueListFactory(offsetUnitId) : null,
        related_date: offset
          ? {
              id: offset.id.slice(offset.id.indexOf('_') + 1),
              label: capitalize(
                offset.label.slice(offset.label.indexOf(' ') + 1)
              )
            }
          : null
      };
    case 'related_date':
      return {
        offset_amount: null,
        offset_direction: null,
        offset_unit: null,
        related_date: dueDate.related_date
      };
    default:
      return {
        offset_amount: null,
        offset_direction: null,
        offset_unit: null,
        related_date: null
      };
  }
}

/**
 * Converts an edit checklist template item form data object to a checklist template item.
 */
export function mapChecklistTemplateItemFormDataToChecklistTemplateItem({
  checklistTemplateItemFormData
}: {
  checklistTemplateItemFormData: Partial<ChecklistTemplateItemEditForm>;
}): Partial<ChecklistTemplateItem> {
  const dueDate = checklistTemplateItemFormData.due_date;
  const itemId = checklistTemplateItemFormData.id;

  // Use the trigger data mapper to get the trigger data
  const action: ValueListValue<ChecklistItemActionType> | undefined | null =
    checklistTemplateItemFormData.type?.id === 'send_message'
      ? { id: 'send_message', label: 'Send message' }
      : checklistTemplateItemFormData.action;
  const triggerData = mapTriggerFormDataToTriggerItem({
    checklistTemplateTriggerFormData: {
      ...checklistTemplateItemFormData,
      action
    }
  });

  const item = {
    ...(itemId ? { id: itemId } : {}),
    label: checklistTemplateItemFormData.name,
    type: checklistTemplateItemFormData.type,
    blocks_subsequent_items: !!checklistTemplateItemFormData.blocks_subsequent_items,
    restricted_to_assignee: !!checklistTemplateItemFormData.restricted_to_assignee,
    due_date_type: valueListFactory(
      dueDate?.due_date_type as ChecklistItemDueDateType
    ),
    due_date_data: dueDate ? getDueDateData({ dueDate }) : undefined,
    ...mapAssignment(checklistTemplateItemFormData),
    has_outcome: !!checklistTemplateItemFormData.has_outcome,
    outcome_data: checklistTemplateItemFormData.outcome_data,
    ...triggerData
  };

  // Clean the data before returning, just to make sure stragglers from mismanaged form state don't make it in.
  if (
    !['send_message', 'trigger_action'].includes(
      checklistTemplateItemFormData.type?.id ?? ''
    )
  ) {
    // If not an action, remove the action stuff
    item.action_type = null;
    item.action_data = null;
    item.trigger_type = null;
    item.trigger_data = null;
  }

  if (checklistTemplateItemFormData.type?.id !== 'record_outcome') {
    item.has_outcome = false;
    item.outcome_data = null;
  }

  return item;
}

/**
 * The item from the form request can be a value list item, or a contact (or empty).
 * we need to map it to the appropriate fields on the checklist template item.
 */
function mapAssignment(
  item: Partial<ChecklistTemplateItemEditForm>
): Pick<ChecklistTemplateItem, 'assigned_to' | 'assigned_to_portfolio_role'> {
  if (!item.assignee_contact_or_role) {
    return {
      assigned_to: null,
      assigned_to_portfolio_role: null
    };
  }

  if (itemIsContact(item.assignee_contact_or_role)) {
    return {
      assigned_to: item.assignee_contact_or_role,
      assigned_to_portfolio_role: null
    };
  }

  return {
    assigned_to: null,
    assigned_to_portfolio_role: item.assignee_contact_or_role
  };
}
