import { supplierComplianceCategorySettingsBlock } from '../blocks/supplier-compliance-categories-settings-block';
import { supplierComplianceRequirementsSettingsBlock } from '../blocks/supplier-compliance-requirements-settings-block';

export const content = [
  {
    label: 'General',
    items: [
      {
        id: 'services-offered',
        label: 'Services offered',
        blocks: [supplierComplianceCategorySettingsBlock]
      },
      {
        id: 'requirements',
        label: 'Requirements',
        blocks: [supplierComplianceRequirementsSettingsBlock]
      }
    ]
  }
];
