import { getSelectedTemplatedAttachments } from 'src/modules/communications/messages/utils/get-selected-templated-attachments';
import { CommunicationContextType } from '../../common/types/communication-context-type';
import { CommunicationContext } from '../types/communication-context';
import { getContextTypeFromRecord } from '../utils/get-context-type-from-record';
import { CreateMessageFormData } from './types/create-message-form-data';
import { CreateMessageRequest } from './types/create-message-request';

/**
 * Map the simple form data to the more complex API request structure.
 * @param formData The form values coming from the blocks
 * @returns The shape of data expected by the API
 */
export async function mapCreateLetterFormToRequest(
  formData: CreateMessageFormData
): Promise<CreateMessageRequest> {
  const inline_attachments = await Promise.all(
    (formData.attachments ?? []).map(async (attachment) => {
      // If the file has been uploaded previously, it will already
      // have an id and we can return it directly
      if (attachment.file?.id) {
        return {
          file: {
            id: attachment.file.id
          }
        };
      }

      // Otherwise fetch the id from the attachments data
      const {
        data: { id }
      } = await attachment.data!;
      return {
        file: {
          id
        }
      };
    })
  );

  return {
    context: formData.relates_to as CommunicationContext,
    context_type: {
      // TODO: we need to handle specific types here
      id: getContextTypeFromRecord(
        formData.relates_to.record
      ) as CommunicationContextType
    },
    // If they did not specify a send_from_author, we don't want to include this at all
    ...(formData.sent_from?.id
      ? { send_from_author: { id: formData.sent_from!.id } }
      : {}),

    // Map the recipients
    selected_recipients:
      formData.recipients?.map((recipient) => recipient.record.id) ?? [],
    cc_recipients: formData.cc_recipients?.map(
      (recipient) => recipient.record.id
    ),
    bcc_recipients: formData.bcc_recipients?.map(
      (recipient) => recipient.record.id
    ),

    inline_attachments,
    linked_files: formData.linked_files || [],
    ...(formData.include_generated_attachments
      ? {
          templated_attachments: getSelectedTemplatedAttachments(
            formData.include_generated_attachments
          )
        }
      : {}),

    // This is hard-coded for now
    forced_channel_type: {
      id: 'letter'
    },
    channels: [
      {
        channel_type: {
          id: 'letter'
        },
        text_body: formData.content,
        letter_address: formData.letterAddress
      }
    ],
    ...(formData.template?.id
      ? { template: { id: formData.template?.id } }
      : {})
  };
}
