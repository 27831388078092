import React from 'react';
import { query, useModelActions } from '@rexlabs/model-generator';
import { RecordTable } from 'view/components/table';
import { propertyOwnershipsFeesModel } from 'data/models/entities/financials/property-ownerships/property-ownership-fee';
import { activeFeesColumns } from 'src/modules/agency-fees/utils/fee-columns';
import { createTableBlock } from 'view/components/record-screen/cards/create-table-block';
import { Message } from 'view/components/@luna/message';
import { ActionButtons } from 'view/components/@luna/action-buttons';
import { PropertyOwnership } from 'src/modules/property-ownerships/types/property-ownership-types';
import { useGetEntityFeeActions } from 'src/modules/entity-fees/actions/use-get-entity-fee-actions';
import { transformActionDeclarationsToActionMenuItems } from 'utils/actions/transforms';
import EmptyTable from 'src/assets/illustrations/empty-table.svg';
import { useTranslation } from 'react-i18next';
import { LinkButton } from '@rexlabs/button';
import Box from '@rexlabs/box';
import { StyleSheet, useStyles } from '@rexlabs/styling';
import { useConfirmationDialog } from '@rexlabs/dialog';
import invariant from 'invariant';
import { useFeatureFlags } from 'view/components/@luna/feature-flags';
import { FLAGS } from 'utils/feature-flags';
import { propertiesModel } from 'data/models/entities/properties';
import { FlattenedProperty } from '../../properties/types/property-types';
import { useGetAddPropertyOwnershipFeeAction } from '../hooks/use-get-add-property-ownership-fee-action';
import { propertyOwnershipModel } from '../models/property-ownership-model';

const usePropertyFeeColumns = () => {
  const { t } = useTranslation();

  return [...activeFeesColumns].map((column) => {
    if (column.id === 'fee_amount') {
      return {
        ...column,
        Header: t(
          'property-ownerships.management-agreement-rate.label.singular'
        ),
        width: 300
      };
    }

    return column;
  });
};

export const getPropertyOwnershipFeesQuery = (
  propertyOwnershipId?: string
) => query`{
  ${propertyOwnershipsFeesModel} (propertyOwnershipId: ${propertyOwnershipId}, o: "name+asc") {
    id
    agency_fee
    ownership_fee_parent
    available_rates
    tax_type
  }
}`;

const styles = StyleSheet({
  linkButton: {
    color: ({ token }) => token('color.textStyle.body.subtext'),
    textDecoration: 'underline',
    ':hover': {
      color: ({ token }) => token('color.textStyle.body.default')
    }
  }
});

export const usePropertyOwnershipFeesBlock = (
  propertyOwnership?: PropertyOwnership | null
) => {
  const { t } = useTranslation();
  const s = useStyles(styles);

  const { hasFeature } = useFeatureFlags();

  const { refreshItem } = useModelActions(propertiesModel);
  const { resetFees } = useModelActions(propertyOwnershipModel);
  const { refreshLists } = useModelActions(propertyOwnershipsFeesModel);
  const getAddPropertyOwnershipFeeAction = useGetAddPropertyOwnershipFeeAction();
  const getEntityFeeActions = useGetEntityFeeActions();
  const propertyFeeColumns = usePropertyFeeColumns();

  const hasServicePackages = hasFeature(FLAGS.SERVICE_PACKAGES);

  const propertyOwnershipIdRef = React.useRef<string>();
  React.useEffect(() => {
    propertyOwnershipIdRef.current = propertyOwnership?.id;
  }, [propertyOwnership]);

  const propertyIdRef = React.useRef<string>();
  React.useEffect(() => {
    propertyIdRef.current = propertyOwnership?.property_id;
  }, [propertyOwnership]);

  const handleConfirmResetFees = React.useCallback(async () => {
    invariant(
      propertyOwnershipIdRef.current,
      `propertyOwnershipId can't be null`
    );

    await resetFees({ propertyOwnershipId: propertyOwnershipIdRef.current });

    await Promise.all([
      refreshLists(),
      refreshItem({
        id: propertyIdRef.current
      })
    ]);

    return true;
  }, []);

  const resetFeesDialog = useConfirmationDialog();

  // Need to memoize the query here to stop the network request from be fired repeatedly
  const query = React.useMemo(() => {
    if (!propertyOwnership?.id) {
      return null;
    }
    return getPropertyOwnershipFeesQuery(propertyOwnership?.id);
  }, [propertyOwnership?.id]);

  const handleResetDefault = () => {
    resetFeesDialog.open({
      title: 'Reset fees to default for service package',
      message: t(
        'property-ownerships.management-agreement-fees.remove-fees.description',
        {
          servicePackage: propertyOwnership?.service_package?.name
        }
      ),
      confirmText: 'Reset to default',
      onConfirm: handleConfirmResetFees
    });
  };

  const childrenFragment =
    hasServicePackages && propertyOwnership?.entity_fees_have_changed ? (
      <Box marginTop={24}>
        <LinkButton {...s('linkButton')} onClick={handleResetDefault}>
          Reset to default for service package
        </LinkButton>
      </Box>
    ) : null;

  return createTableBlock<
    FlattenedProperty,
    typeof propertyOwnershipsFeesModel
  >({
    id: 'property-ownership-fees',
    withPagination: true,
    hashParamKey: 'management-agreement-fees',
    title: t('property-ownerships.management-agreement-fees.label.plural'),
    childrenFragment,
    getTableProps: ({ data }) => {
      const actions = ({ item: entityFee }) => {
        // need to make sure the query is definitely loaded before we pass it on
        if (!data?.selected_property_ownership?.id || query === null) {
          return;
        }

        return transformActionDeclarationsToActionMenuItems(
          getEntityFeeActions(entityFee, propertyOwnership!)
        );
      };

      return {
        columns: propertyFeeColumns,
        id: 'property-ownership-fees',
        Table: RecordTable,
        getQuery: () => query!,
        getActionMenuItems: actions
      };
    },
    Empty: ({ data }) => {
      return (
        <Message
          title={t(
            'property-ownerships.management-agreement-fees.no-fees-applied.title'
          )}
          Illustration={EmptyTable}
          actions={
            data.selected_property_ownership
              ? [
                  getAddPropertyOwnershipFeeAction(
                    data.selected_property_ownership
                  )
                ]
              : []
          }
        >
          {t(
            'property-ownerships.management-agreement-fees.no-fees-applied.description'
          )}
        </Message>
      );
    },
    Actions: ({ data }) => {
      if (!data?.selected_property_ownership) {
        return null;
      }

      return (
        <ActionButtons
          actions={[
            getAddPropertyOwnershipFeeAction(data?.selected_property_ownership)
          ]}
        />
      );
    }
  });
};
