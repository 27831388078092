export enum PaymentFrequency {
  weekly = 'weekly',
  fortnightly = 'fortnightly',
  monthly = 'monthly',
  quarterly = 'quarterly',
  annually = 'annually'
}

export const PAYMENT_FREQUENCY_VALUE_LIST = [
  { label: 'Weekly', id: PaymentFrequency.weekly },
  { label: 'Fortnightly', id: PaymentFrequency.fortnightly },
  { label: 'Monthly', id: PaymentFrequency.monthly },
  { label: 'Quarterly', id: PaymentFrequency.quarterly },
  { label: 'Annually', id: PaymentFrequency.annually }
] as const;
