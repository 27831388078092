import { Column, Grid } from 'view/components/@luna/form/grid';
import { Field } from '@rexlabs/form';
import { BankAccountSelect } from 'src/modules/bank-accounts/components/bank-account-select';
import { FinancialRecordTypes } from 'utils/financials/financial-objects';
import {
  DateInput,
  disableFutureDates
} from 'view/components/@luna/inputs/date-input/date-input';
import { PrepaymentBucketSelect } from 'view/components/inputs/selects/v4-selects/prepayment-bucket-select';
import Box from '@rexlabs/box';
import { CurrencyValue } from 'view/components/values/currency';
import { TransferBucketsPopout } from 'src/modules/trust-journal-entries/popouts/transfer-buckets-popout';
import React, { useEffect } from 'react';
import { useToken } from '@rexlabs/styling';
import { Tenancy } from 'data/models/entities/tenancies';
import { BankAccount } from 'data/models/entities/financials/bank-accounts';
import { TrustLedger } from 'src/modules/trust-ledgers/types/trust-ledger-types';
import { SearchResultItem } from 'utils/api/get-search-results';
import { useGetTenancyPrepaymentBuckets } from 'src/modules/tenancies/hooks/use-get-tenancy-prepayment-buckets';
import { EntitySelect } from 'view/components/inputs/selects/v4-selects/entity-select';
import { TenancyPrepaymentBucket } from 'src/modules/prepayments/types/tenancy-prepayment-bucket';
import { formatCurrency } from 'utils/formatters';

interface TransferFundsDetailsPrepaymentsFormProps {
  values: {
    source: {
      ledger: {
        object?: SearchResultItem<Tenancy>;
      };
    };
    bank_account?: BankAccount;
    date_of?: string;
    prepayment_bucket?: TenancyPrepaymentBucket;
  };
  bucketAmount: number;
  trustLedger?: TrustLedger;
  setFieldValue?: (field: string, value: any) => void;
}

export function TransferFundsDetailsPrepaymentsForm({
  values,
  bucketAmount,
  trustLedger,
  setFieldValue
}: TransferFundsDetailsPrepaymentsFormProps) {
  const token = useToken();

  const folio = values.source?.ledger?.object;
  const bankAccount = values.bank_account;

  const { data: bucketListData, isLoading } = useGetTenancyPrepaymentBuckets(
    folio?.id,
    bankAccount?.id
  );
  const currentBucket = bucketListData?.find?.(
    (bucket) => bucket.bucket_id === values.prepayment_bucket?.bucket_id
  );
  const currentBucketAmount = currentBucket?.amount || 0;

  useEffect(() => {
    // when the amount in the current bucket changes, we need to update the bucket form value
    // so that the amount is correctly displayed in the form
    if (
      currentBucket &&
      currentBucket.amount !== values.prepayment_bucket?.amount
    ) {
      setFieldValue?.('prepayment_bucket', currentBucket);
    }
  }, [currentBucket?.bucket_id, currentBucketAmount]);

  // The general funds bucket is the default bucket.
  // I'm setting it here so we don't need to check folio or prepayments flag at the dialog level
  useEffect(() => {
    if (!values.prepayment_bucket && !isLoading) {
      const generalFundsBucket = bucketListData?.find?.(
        (bucket) => bucket.system_purpose?.id === 'general_funds'
      );

      setFieldValue?.('prepayment_bucket', generalFundsBucket);
    }
  }, [values.prepayment_bucket, isLoading, bucketListData]);

  return (
    <Grid columns={6}>
      <Column width={3}>
        <Field
          name='bank_account'
          label='Bank account'
          Input={BankAccountSelect}
        />
      </Column>

      <Column width={3} />
      <Column width={2}>
        <Field
          id='transfer-from-folio'
          name='source.ledger.object'
          label='Folio to transfer from'
          Input={EntitySelect}
          inputProps={{
            objectTypes: Object.values(FinancialRecordTypes),
            deselectable: true
          }}
        />
      </Column>

      <Field
        name='date_of'
        label='Date'
        Input={DateInput}
        inputProps={{
          isOutsideRange: disableFutureDates
        }}
      />
      <Column width={2}>
        <Field<typeof PrepaymentBucketSelect>
          name={'prepayment_bucket'}
          id={'prepayment_bucket'}
          label={'Bucket'}
          Input={PrepaymentBucketSelect}
          optional={false}
          description={
            values.prepayment_bucket &&
            `${formatCurrency(currentBucketAmount)} available`
          }
          inputProps={{
            bankAccountId: bankAccount?.id,
            tenancyId: folio?.id
          }}
        />
      </Column>

      <div>
        <Box
          flexDirection={'row'}
          columnGap={token('spacing.m')}
          alignItems={'end'}
        >
          <CurrencyValue label='Available' value={bucketAmount} />

          <TransferBucketsPopout
            ledger={trustLedger}
            bankAccount={bankAccount}
            tenancy={folio?.record}
            bucketToTransferTo={values.prepayment_bucket}
          />
        </Box>
      </div>
    </Grid>
  );
}
