import React from 'react';

import { Truncate } from '@rexlabs/text';
import { CellProps } from '@rexlabs/table';
import { Reconciliation } from 'data/models/entities/financials/reconciliations';

import { margin, StyleSheet, useStyles } from '@rexlabs/styling';
import { formatDate } from 'utils/dates/format';

const defaultStyles = StyleSheet({
  secondLine: {
    fontSize: ({ token }) => token('typography.size.s'),
    color: ({ token }) => token('color.textStyle.body.subtext'),
    ...margin.styles({
      top: 'xxs'
    })
  }
});

export function PeriodCell({ value }: CellProps<unknown, Reconciliation>) {
  const s = useStyles(defaultStyles);

  const statementDate = value.statement_date;
  const recordReference = value.record_reference;

  return (
    <div>
      {statementDate ? (
        <div>
          {formatDate(statementDate, 'MMMM YYYY')}
          <div {...s('secondLine')}>
            <Truncate>{recordReference || '-'}</Truncate>
          </div>
        </div>
      ) : (
        '-'
      )}
    </div>
  );
}
