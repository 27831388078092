import React from 'react';
import { BlockConfig } from 'view/components/record-screen/types';
import { ListTable, Columns } from 'view/components/table';
import { formatCurrency } from 'utils/formatters';
import {
  ContactPaymentMethodDetailsCell,
  useGetContactPaymentDetailsToCsv
} from 'src/modules/contacts/components/payment-method-details-cell';
import { Contact } from 'src/modules/contacts/types/contact-types';
import { Trans } from 'react-i18next';
import { DisbursementPaymentMethod } from '../types/types';
import { DisbursementPaymentMethodsEdit } from '../components/disbursement-payment-methods-edit';

const useColumns = (): Columns => {
  const getContactPaymentDetailsToCsv = useGetContactPaymentDetailsToCsv();

  return [
    {
      id: 'contact_payment_method.name',
      Header: 'Method name'
    },
    {
      id: 'contact_payment_method',
      Cell: ContactPaymentMethodDetailsCell,
      Header: 'Details',
      toCsv: (item) => {
        return getContactPaymentDetailsToCsv(item);
      }
    },
    {
      id: 'amount',
      accessor: (item: DisbursementPaymentMethod) => {
        if (item.split_amount.type.id === 'percent') {
          return `${item.split_amount.amount}%`;
        }
        return formatCurrency(item.split_amount.amount);
      },
      cellProps: {
        align: 'right'
      },
      Header: 'Split value'
    }
  ];
};

export const contactDisbursementPaymentMethodsBlock: BlockConfig<Contact> = {
  id: 'contact-disbursement-preferences',
  title: () => <Trans i18nKey='disbursements.preferences.block.label' />,
  validate: {
    definitions: {
      'disbursement_payment_methods.*.contact_payment_method': {
        name: 'payment method',
        rules: 'required'
      },
      'disbursement_payment_methods.*.split_amount.amount': {
        name: 'split amount',
        rules: [
          'required',
          {
            key: 'check-100-percent',
            type: 'global',
            rule:
              'disbursementNotMoreThan100Percent|disbursementNotLessThan100Percent'
          }
        ]
      }
    }
  },
  View: ({ data }) => {
    const columns = useColumns();

    return (
      <ListTable
        hidePagination
        id='disbursement-payment-methods'
        items={data?.disbursement_payment_methods}
        isLoading={false}
        columns={columns}
      />
    );
  },
  Edit: ({ values, data }) =>
    DisbursementPaymentMethodsEdit({
      values,
      data
    })
};
