import * as React from 'react';
import { Columns } from 'view/components/table';
import dayjs from 'dayjs';
import Box from '@rexlabs/box';
import { formatDate } from 'utils/dates/format';
import { TwoTierCell } from 'view/components/cells/two-tier-cell';
import { StyleSheet, useStyles } from '@rexlabs/styling';
import { useGetPreviewMessageAction } from 'src/modules/communications/messages/hooks/action-declarations/use-get-preview-message-action';
import { invokeActionDeclaration } from 'src/modules/common/actions/utils/invoke-action-declaration';
import { useGetViewMessageAction } from 'src/modules/communications/messages/hooks/action-declarations/use-get-view-message-action';
import { Contact } from 'src/modules/contacts/types/contact-types';
import { useMutation, useQueryClient } from 'react-query';
import { useDialog, useErrorDialog } from '@rexlabs/dialog';
import { CreateEmailRecordDialog } from 'src/modules/communications/messages/dialogs/create-email-record-dialog';
import { CreateSMSRecordDialog } from 'src/modules/communications/messages/dialogs/create-sms-record-dialog';
import { api } from 'utils/api/api-client';
import { getSearchResultItemFromObjectAndModel } from 'src/modules/common/utils/search-result-items/get-search-result-items-from-object-and-model';
import { Message } from 'src/modules/communications/messages/types/Message';
import { useSettings } from 'src/modules/settings/hooks/useSettings';
import { getRecipients } from 'src/modules/communications/messages/utils/get-recipients';
import { Checklist, ChecklistItem } from '../types/Checklist';
import { CheckboxItemCheckbox } from '../components/checklist-checkbox';
import { TaskType } from '../types/TaskType';
import { Task } from '../types/Task';
import { tasksModel } from '../models/tasks-model';

// TODO: this file can probably be removed once the checklist UI migration is complete.

export const useColumns = (
  isSmartChecklist: boolean
): Columns<ChecklistItem> => [
  {
    id: 'item_status',
    accessor: (item) => item,
    width: 50,
    Cell: CheckboxItemCheckbox
  },
  {
    id: 'label',
    Header: 'Checklist name',
    width: 240,
    accessor: (item) => item,
    Cell: ChecklistItemName
  },
  ...(isSmartChecklist
    ? [
        {
          id: 'due_date',
          Header: 'Due date',
          type: 'date' as const,
          accessor: (item) => item.due_date,
          width: 160
        }
      ]
    : [
        {
          id: 'completed_by',
          Header: 'Completed by',
          accessor: (item) => item,
          width: 160,
          Cell: ChecklistCompletedByAndTime
        }
      ]),
  {
    id: 'assigned_to',
    Header: 'Assigned to',
    type: 'contact',
    width: 160,
    accessor: (item) => item.assigned_to
  }
];

const styles = StyleSheet({
  subtext: {
    color: ({ token }) => token('color.textStyle.body.subtext')
  },

  triggerButton: {
    appearance: 'none',
    color: 'inherit',
    background: 'transparent',
    textDecoration: 'underline',
    padding: 0,
    margin: 0,
    border: 'none'
  }
});

function ChecklistItemName({
  value
}: {
  value: ChecklistItem & { checklist: Checklist } & { task: Task<TaskType> };
}) {
  const s = useStyles(styles);
  const queryClient = useQueryClient();

  const errorDialog = useErrorDialog();
  const createEmailDialog = useDialog(CreateEmailRecordDialog);
  const createSMSDialog = useDialog(CreateSMSRecordDialog);
  const getPreviewMessageAction = useGetPreviewMessageAction(false);
  const getViewMessageAction = useGetViewMessageAction();
  const { timezone: siloTimezone } = useSettings();

  const isCompleted = Boolean(value.completed_at);
  const Tag = isCompleted ? 's' : 'span';

  const showBanner = value.trigger_at
    ? `${value.checklist.created_at}` > value.trigger_at
    : false;

  const hasTriggerAtDate = value.trigger_at != null;

  const actionType = value.action_type?.id;

  const template = value.action_message_template;
  const templateName = template?.name;

  const handleSaveMessage = () => {
    queryClient.invalidateQueries(['checklist', value.checklist.id]);
  };

  const handleActionMessage = useMutation(
    ({ message }: { message: Message }) => {
      return api.patch(`/tasks/checklist-items/${value.id}`, {
        action_data: {
          message_id: message.id,
          message_template_id: message.template?.id
        }
      });
    },
    {
      onSettled: () => {
        queryClient.invalidateQueries(['checklist', value.checklist.id]);
      }
    }
  );

  async function handleCreate(message) {
    try {
      await handleActionMessage.mutateAsync({
        message
      });
    } catch (err) {
      errorDialog.open(err);
    }
  }

  const handlePreview = async () => {
    const message = window.structuredClone(value.action_message);

    // show sent message
    if (message && message.status.id === 'sent') {
      invokeActionDeclaration(getViewMessageAction, {
        channelType: message.channels?.[0].channel_type.id,
        message
      });

      return;
    }

    // has a custom message for this checklist item
    if (message) {
      // transforms response to follow model-generator ListData<PROP>
      const recipients = message.recipients;
      message.recipients = {
        items: (recipients as unknown) as Contact[],
        status: 'loaded'
      };
      const cc_recipients = message.cc_recipients;
      message.cc_recipients = {
        items: (cc_recipients as unknown) as Contact[],
        status: 'loaded'
      };
      const bcc_recipients = message.bcc_recipients;
      message.bcc_recipients = {
        items: (bcc_recipients as unknown) as Contact[],
        status: 'loaded'
      };

      invokeActionDeclaration(
        getPreviewMessageAction,
        message,
        handleSaveMessage
      );

      return;
    }

    const relatesTo = getSearchResultItemFromObjectAndModel(
      value.task,
      tasksModel
    );
    const recipients = await getRecipients(template, relatesTo);

    // go with the message template
    if (actionType === 'send_email') {
      const channel = template?.channels?.find(
        (channel) => channel.channel_type.id === 'email'
      );

      createEmailDialog.open({
        title: 'Save message',
        onCreate: handleCreate,
        hasButtonGroupActions: false,
        data: {
          template,
          recipients,
          relates_to: relatesTo,
          subject: channel?.email_subject,
          content: channel?.plain_text_body
        }
      });
    } else {
      const channel = template?.channels?.find(
        (channel) => channel.channel_type.id === 'sms'
      );

      createSMSDialog.open({
        title: 'Save SMS',
        onCreate: handleCreate,
        hasButtonGroupActions: false,
        data: {
          template,
          recipients,
          relates_to: relatesTo,
          smsContent: channel?.plain_text_body
        }
      });
    }
  };

  return (
    <Box flexDirection='column' gap={8}>
      <Tag>{value.label}</Tag>

      {template && (
        <div>
          <span
            role='button'
            tabIndex={0}
            {...s('triggerButton')}
            onClick={handlePreview}
          >
            {templateName}
          </span>{' '}
          {getTriggerText(value, isCompleted, siloTimezone)}
        </div>
      )}

      <div {...s('subtext')}>
        {showBanner &&
          hasTriggerAtDate &&
          `This was scheduled for ${dayjs(value.trigger_at)
            .tz()
            .format(
              'h:mma'
            )} on the due date, but the task was created after this time`}
      </div>
    </Box>
  );
}

function ChecklistCompletedByAndTime({ value }) {
  const isChecked = Boolean(value.completed_at);

  if (!isChecked) {
    return <div>-</div>;
  }

  return (
    <TwoTierCell
      text={value.completed_by?.name}
      subtext={formatDate(value.completed_at)}
    />
  );
}

function getTriggerText(checklistItem, isCompleted, displayTimezone: string) {
  const messageStatus = checklistItem.action_message?.status?.id;
  if (isCompleted && messageStatus === 'sending') {
    return `is sending now`;
  }

  const triggeredAt = checklistItem.triggered_at;
  if (isCompleted && messageStatus === 'sent' && triggeredAt) {
    return `was sent at ${dayjs(triggeredAt).tz().format('h:mma')} on ${dayjs(
      triggeredAt
    )
      .tz()
      .format('DD MMM YYYY')}`;
  }

  const triggerAt = checklistItem.trigger_at;
  const timeHour = checklistItem.trigger_data?.time_hour;
  const triggerType = checklistItem.trigger_type.id;

  if (triggerType === 'on_completion') {
    return `will automatically send when this subtask is completed`;
  }

  const time = triggerAt
    ? dayjs(triggerAt).tz(displayTimezone).format('h:mma')
    : dayjs('1/01/23 ' + `${timeHour}:00`).format('h:mma');

  return `will automatically send at ${time} on the due date`;
}
