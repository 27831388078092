import React from 'react';
import { ContactFlag } from 'view/components/record-flags/contact-flag';
import { Invoice } from 'data/models/entities/financials/invoices';
import { RecordFlag } from 'view/components/record-flags/record-flag';
import { Ownership } from 'data/models/entities/ownerships';
import { Tenancy } from 'data/models/entities/tenancies';
import { Contact } from 'src/modules/contacts/types/contact-types';
import { EntryList } from '../components/entry-list';
import { EntryLink } from '../components/entry-link';
import { EmptyInvoice } from '../empty/empty-invoice';
import { SecondaryList } from '../components/secondary-list';

interface EntryProps {
  ownership?: Ownership;
  tenancy?: Tenancy;
  contact?: Contact;
}

function Entry({ ownership, tenancy, contact }: EntryProps) {
  if (ownership) {
    return (
      <EntryLink type='ownership' id={ownership.id}>
        <RecordFlag record={ownership} recordType='ownership' />
      </EntryLink>
    );
  }

  if (tenancy) {
    return (
      <EntryLink type='tenancy' id={tenancy.id}>
        <RecordFlag record={tenancy} recordType='tenancy' />
      </EntryLink>
    );
  }

  if (contact) {
    return (
      <EntryLink type='contact' id={contact.id}>
        <ContactFlag contact={contact} />
      </EntryLink>
    );
  }

  return <>?</>;
}

export interface InvoiceSectionProps {
  invoice?: Invoice;
}

export function InvoiceSection({ invoice }: InvoiceSectionProps) {
  if (!invoice) {
    return <EmptyInvoice />;
  }

  return (
    <SecondaryList>
      <EntryList headline='Payable to'>
        <Entry
          ownership={invoice.payable_to_ownership}
          tenancy={invoice.payable_to_tenancy}
          contact={invoice.payable_to_contact}
        />
      </EntryList>
      <EntryList headline='Payable by'>
        <Entry
          ownership={invoice.payable_by_ownership}
          tenancy={invoice.payable_by_tenancy}
          contact={invoice.payable_by_contact}
        />
      </EntryList>
      {invoice.triggered_by_invoice_payment?.invoice && (
        <EntryList headline={'Triggered by'}>
          <EntryLink
            type='invoice'
            id={invoice.triggered_by_invoice_payment.invoice.id}
          >
            <RecordFlag
              record={invoice.triggered_by_invoice_payment.invoice}
              recordType='invoice'
            />
          </EntryLink>
        </EntryList>
      )}
    </SecondaryList>
  );
}
