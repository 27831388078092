import React from 'react';
import Box from '@rexlabs/box';
import { StyleSheet, useStyles, useToken } from '@rexlabs/styling';
import { Body } from '@rexlabs/text';
import { Banner } from 'src/modules/common/components/banner';

const defaultSTyles = StyleSheet({
  list: {
    listStyleType: 'circle',
    paddingLeft: 12
  }
});

export function MaxNumberOfInvoicesWarningBanner({
  maxNumberOfInvoices
}: {
  maxNumberOfInvoices: number;
}) {
  const token = useToken();
  const s = useStyles(defaultSTyles);
  return (
    <Box mb={token('spacing.xl')} data-testid='max-invoices-warning-banner'>
      <Banner intent='warning'>
        <Box flexDirection='column'>
          <Body>
            This folio contains over {maxNumberOfInvoices} invoices, only the
            first {maxNumberOfInvoices} are displayed. Here&apos;s what you can
            do:
          </Body>
          <Body>
            <ul {...s('list')}>
              <li>
                <b>Recommended</b>: To transfer funds for the first batch of
                invoices, complete the transfer and then reopen the Transfer
                dialog to process the next batch (up to 30 invoices).
              </li>
              <li>
                To add more invoices, click &quot;+ Add Line Item,&quot; select
                &quot;Add Invoice&quot;, and then click inside the Invoice
                search box. Search for the invoice you wish to pay then select
                it.
              </li>
            </ul>
          </Body>
          <Body>
            If you attempt to transfer funds for more than 30 invoices at a
            time, the app may slow down and you might not be able to complete
            the transfer.
          </Body>
        </Box>
      </Banner>
    </Box>
  );
}
