import React, { useCallback } from 'react';
import { useDialog } from '@rexlabs/dialog';
import { query } from '@rexlabs/model-generator';

import { BlockConfig } from 'view/components/record-screen/types';
import { Columns, RecordTable } from 'view/components/table';
import ROUTES from 'routes/app';
import { propertiesModel } from 'data/models/entities/properties';
import { useTableFilters } from 'view/hooks/use-table-filters';
import { ActionButtons } from 'view/components/@luna/action-buttons';
import { Property } from 'src/modules/properties/types/property-types';
import AddIcon from 'view/components/icons/add';
import { transformActionDeclarationsToActionMenuItems } from 'utils/actions/transforms';
import { AddPropertiesDialog } from '../dialogs/add-properties-dialog';
import { useGetPropertyActions } from '../hooks/action-menu-items/use-get-property-actions';

const columns: Columns<Property> = [
  {
    id: 'name',
    accessor: (item) => item?.display_name,
    type: 'text',
    Header: 'Name'
  },
  {
    id: 'address_string',
    type: 'text',
    Header: 'Address'
  }
];

function getRowLinkProps({ item }) {
  return {
    to: ROUTES.PROPERTY,
    params: { propertyId: item.id }
  };
}

function getPropertyQuery() {
  return query`{
    ${propertiesModel} {
      id
      name
      address
      access_details
      maintenance_instructions
      portfolio {
        id
      }
    }
  }`;
}

export const propertiesBlock: BlockConfig = {
  id: 'properties',
  title: 'Properties',
  Actions: ({ data }) => {
    const { open } = useDialog(AddPropertiesDialog);
    return (
      <ActionButtons
        actions={[
          {
            label: 'Add properties',
            handleAction: () => open({ portfolioId: data.id }),
            Icon: AddIcon
          }
        ]}
      />
    );
  },
  View: ({ data }) => {
    const portfolioId = data.id;
    const { getSort, getFilters } = useTableFilters('properties');

    const forcedGlobalFilter = React.useMemo(
      () => [
        {
          field: 'portfolio_id',
          op: 'eq',
          value: portfolioId,
          id: 'portfolio_id'
        }
      ],
      [portfolioId]
    );

    const getPropertyActions = useGetPropertyActions({ portfolio: data });

    const getPropertyActionMenuItems = useCallback(
      ({ item }) =>
        transformActionDeclarationsToActionMenuItems(getPropertyActions(item)),
      [getPropertyActions]
    );

    return (
      <RecordTable
        withToolbar
        withPagination
        columns={columns}
        getQuery={getPropertyQuery}
        getSort={getSort}
        getFilters={getFilters}
        getRowLinkProps={getRowLinkProps}
        forcedGlobalFilter={forcedGlobalFilter}
        hiddenFilters={['portfolio_id']}
        getActionMenuItems={getPropertyActionMenuItems}
      />
    );
  }
};
