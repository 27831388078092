import { query } from '@rexlabs/model-generator';
import { financialsTrustAccountsModel } from 'data/models/entities/financials/trust-accounts';

export const getTrustAccountQuery = (id: string) => query`{
    ${financialsTrustAccountsModel} (id: ${id}) {
      id
      name
      created_at
      updated_at
      bank_account
    }
  }`;
