import { ActionMenuItem } from '@rexlabs/action-menu';
import { useGetToggleClosedAction } from 'src/modules/tasks/common/hooks/action-declarations/use-get-toggle-closed-action';
import { transformActionDeclarationsToActionMenuItems } from 'utils/actions/transforms';
import { useGetPrimaryRecordActionGroup } from 'view/hooks/actions/use-get-primary-record-action-group';
import { useGetAddDocumentsAction } from 'src/modules/documents/actions/use-get-add-documents-action';
import { useGetAddNotesAction } from 'src/modules/tasks/common/actions/get-add-notes-action';
import { tasksModel } from 'src/modules/tasks/common/models/tasks-model';
import { useGetCreateSMSAction } from 'src/modules/communications/messages/hooks/action-declarations/use-get-create-sms-action';
import { useGetCreateEmailAction } from 'src/modules/communications/messages/hooks/action-declarations/use-get-create-email-action';
import { TodoTask } from '../../types/TodoTask';

export function useGetTodoActions() {
  const getPrimaryRecordActionGroup = useGetPrimaryRecordActionGroup();
  const getToggleClosedAction = useGetToggleClosedAction();
  const getAddDocumentsAction = useGetAddDocumentsAction();
  const getAddNotesAction = useGetAddNotesAction();
  const getCreateEmailAction = useGetCreateEmailAction(tasksModel);
  const getCreateSMSAction = useGetCreateSMSAction(tasksModel);

  return (todo?: TodoTask): ActionMenuItem[] => {
    if (!todo) return [];

    const actions = [
      ...getPrimaryRecordActionGroup('todo', todo.id),
      getToggleClosedAction(todo),
      getCreateEmailAction(todo),
      getCreateSMSAction(todo),
      getAddDocumentsAction({ data: todo }),
      getAddNotesAction({ data: todo })
    ];

    return [...transformActionDeclarationsToActionMenuItems(actions)];
  };
}
