import { insertCss } from '@rexlabs/styling';
import { init as initLuna, Tokens } from '@rexlabs/theme-luna';
import Inter from '@rexlabs/theme-luna/assets/fonts/Inter.woff2';
import { generateTokenClasses } from './tailwindify-tokens';

export function init(tokens: Tokens) {
  initLuna({
    tokens,
    fontConfig: {
      family: 'Inter',
      weight: '100 900',
      url: Inter
    }
  });
  insertCss(`
    body {
      opacity: 0;
      transition: opacity cubic-bezier(0.18, 0.89, 0.32, 1.28) 2s;
    }
    
    body.ready {
      opacity: 1;
    }

    ${generateTokenClasses().join('')}
  `);
}
