import { useCallback } from 'react';
import { useModelActions } from '@rexlabs/model-generator';

import { api } from 'utils/api/api-client';

import {
  settingsSupplierCategoryRequirementsModel,
  SupplierCategoryRequirement
} from 'data/models/entities/settings/supplier-category-requirements';
import { useRequirementsForCategory } from 'src/modules/compliance/common/hooks/use-requirements-for-category';
import { ComplianceCategoryFormValues } from '../blocks/supplier-compliance-categories-details-block';

export function useUpdateComplianceCategory({
  categoryRequirements,
  categoryId
}: {
  categoryRequirements?: SupplierCategoryRequirement[];
  categoryId: string;
}) {
  const { trashItem } = useModelActions(
    settingsSupplierCategoryRequirementsModel
  );

  const { requirementsList } = useRequirementsForCategory({
    categoryRequirements
  });

  return useCallback(
    async ({ values }: { values: ComplianceCategoryFormValues }) => {
      const { requirements = [], ...restOfValues } = values;

      const newRequirements = requirements.filter(
        (requirement) =>
          !requirementsList?.find((req) => req?.id === requirement.id)
      );

      const removedRequirements = requirementsList?.filter(
        (req) => !requirements.find((r) => r.id === req?.id)
      );

      const removedCategoryRequirements = categoryRequirements?.filter(
        (categoryRequirement) =>
          removedRequirements?.find(
            (removedRequirement) =>
              categoryRequirement?.compliance_type?.id ===
              removedRequirement?.id
          )
      );

      const { data } = await api.post('request-pipelines', {
        requests: [
          {
            method: 'PATCH',
            path: `/api/v1/settings/supplier-compliance-categories/${categoryId}`,
            json: {
              ...restOfValues
            }
          },
          ...newRequirements.map((requirement) => ({
            method: 'POST',
            path: '/api/v1/settings/supplier-category-requirements',
            json: {
              supplier_compliance_category: { id: `{{$.0.id}}` },
              compliance_type: { id: requirement.id }
            }
          }))
        ]
      });

      // TODO: We should be able to do this in the request pipeline
      // Following up with BE team to see if this is possible
      await Promise.all(
        (removedCategoryRequirements || []).map((categoryRequirement) =>
          trashItem({ id: categoryRequirement.id })
        )
      );

      return { data };
    },
    [categoryId, categoryRequirements, requirementsList, trashItem]
  );
}
