export const RECONCILIATIONS_ROUTES = {
  RECONCILIATIONS: {
    config: {
      path: '/reconciliations',
      Component: require('./screens/reconciliations-list')
        .ReconciliationsListScreen
    }
  },
  RECONCILIATION: {
    config: {
      path: '/reconciliation/:reconciliationId',
      Component: require('./screens/reconciliation-details')
        .ReconciliationDetailsScreen
    }
  }
};
