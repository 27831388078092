import {
  PropertyOwnership,
  PropertyOwnershipStatusType
} from 'src/modules/property-ownerships/types/property-ownership-types';
import {
  PropertyTenancy,
  PropertyTenancyStatusType
} from 'src/modules/property-tenancies/types/property-tenancy-types';

type AgreementStatusType =
  | PropertyTenancyStatusType
  | PropertyOwnershipStatusType;

const desiredOrder: Record<AgreementStatusType, number> = {
  active: 0,
  draft: 1,
  outgoing: 2,
  incoming: 3,
  archived: 4
};

export function filterRelevantAgreements(
  agreement: PropertyTenancy | PropertyOwnership
) {
  return (
    agreement?.status?.id &&
    Object.keys(desiredOrder).includes(agreement!.status!.id)
  );
}

export function getMostRelevantAgreement<
  T extends PropertyTenancy | PropertyOwnership
>(data?: T[]): T | null {
  const sortedData = (data || [])
    .filter(filterRelevantAgreements)
    .sort((a, b) => {
      // sort by relevance of status, and relevance of time if necessary
      const aWeight = desiredOrder[a.status!.id];
      const bWeight = desiredOrder[b.status!.id];

      if (aWeight === bWeight && a.updated_at && b.updated_at) {
        return a.updated_at > b.updated_at ? -1 : 1;
      }

      return aWeight - bWeight;
    });

  if (sortedData.length === 0) {
    return null;
  }

  return sortedData[0];
}
