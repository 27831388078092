import { Generator } from 'data/models/generator';
import { auditableConfig } from 'src/modules/common/auditable/auditable-config';
import { Checklist } from '../../common/types/Checklist';
import { tasksModel } from '../../common/models/tasks-model';
import { checklistTemplatesModel } from '../../settings/models/checklist-templates-model';

const config = {
  entities: {
    related: {
      ...auditableConfig,
      template: {
        include: 'template',
        model: checklistTemplatesModel
      },

      task: {
        include: 'task',
        model: tasksModel
      },

      items: {
        include: 'items'
        // Note: including the model here causes a circular dependency
      }
    }
  }
};

export const checklistsModel = new Generator<Checklist>(
  'tasks/checklists',
  config
).createEntityModel();
