import { Dialog } from 'view/components/@luna/dialog';
import * as React from 'react';
import { useState } from 'react';
import { DialogProps } from '@rexlabs/dialog';
import { Ownership } from 'data/models/entities/ownerships';
import { PreviewStatementsHeader } from 'src/modules/ownership-statements/common/components/preview-statements-header';
import { PreviewStatementsIframe } from 'src/modules/ownership-statements/common/components/preview-statements-iframe';
import { PreviewStatementsFooter } from 'src/modules/ownership-statements/common/components/preview-statements-footer';
import {
  RecordLoadingPagedReviewer,
  RecordLoadingPagedReviewerProps,
  RecordLoadingPagedReviewerRecord
} from 'view/components/paged-reviewer/record-loading-paged-reviewer';
import { Statement, StatementType } from 'src/modules/statements/types';
import { useGetStatementHotkeyActions } from 'src/modules/ownership-statements/common/actions/use-get-statement-hotkey-actions';
import { useLoadMoreRecordsInPreviewer } from 'src/modules/ownership-statements/common/hooks/use-load-more-records-in-previewer';
import { PreviewStatementsBanner } from 'src/modules/ownership-statements/common/components/preview-statements-banner';

type PreviewPendingStatementData = {
  ownership: Ownership;
  statementType: StatementType;
  statementEndDate: string;
  statementStartDate?: string;
};

type PreviewExistingStatementData = {
  statement: Statement;
};

type StatementPreviewData =
  | (PreviewPendingStatementData & { html?: string })
  | (PreviewExistingStatementData & { html?: string });

export const statementPreviewerViewModes: RecordLoadingPagedReviewerProps['viewModes'] = [
  { id: 'summary', label: 'Summary' },
  { id: 'detail', label: 'Detail' }
];

export type StatementReviewerRecord = RecordLoadingPagedReviewerRecord<
  typeof statementPreviewerViewModes,
  StatementPreviewData
>;

interface PreviewStatementsDialogProps extends DialogProps {
  statementsData: StatementPreviewData[];
}

export function PreviewStatementsDialog({
  onClose,
  statementsData
}: PreviewStatementsDialogProps) {
  const getStatementHotkeyActions = useGetStatementHotkeyActions();
  const [zoom, setZoom] = useState(1);
  const [viewMode, setViewMode] = useState(statementPreviewerViewModes[0].id);
  const [reviewerRecords, setReviewerRecords] = useState<
    StatementReviewerRecord[]
  >(
    statementsData.map((data: StatementPreviewData) => ({
      id: 'statement' in data ? data.statement.id : data.ownership.id,
      views: {
        summary: { data },
        detail: { data }
      }
    }))
  );
  const [currentRecord, setCurrentRecord] = useState<StatementReviewerRecord>(
    reviewerRecords[0]
  );

  const currentData = currentRecord.views[viewMode]?.data;

  if (!currentData) {
    throw Error(`No data in record ${currentRecord.id}`);
  }

  const currentOwnership =
    'ownership' in currentData
      ? currentData.ownership
      : (currentData.statement.object as Ownership);

  const loadMoreRecords = useLoadMoreRecordsInPreviewer(
    setReviewerRecords,
    reviewerRecords
  );
  const actions = currentData
    ? getStatementHotkeyActions({
        onComplete: () =>
          // remove item from list
          setReviewerRecords(
            reviewerRecords.filter(
              (existingItem) => currentRecord.id !== existingItem.id
            )
          ),
        ...('statement' in currentData
          ? {
              statement: currentData.statement
            }
          : {
              ownership: currentData.ownership,
              statementType: currentData.statementType,
              statementEndDate: currentData.statementEndDate,
              statementStartDate: currentData.statementStartDate
            })
      })
    : [];

  return (
    <Dialog
      title='View statement'
      size={'fullscreen'}
      autofocus={false}
      onClose={onClose}
    >
      <RecordLoadingPagedReviewer
        actions={actions}
        records={reviewerRecords}
        viewModes={statementPreviewerViewModes}
        currentViewMode={viewMode}
        renderBanner={() => (
          <PreviewStatementsBanner ownership={currentOwnership} />
        )}
        renderHeader={() => (
          <PreviewStatementsHeader
            statement={
              'statement' in currentData ? currentData.statement : undefined
            }
            viewModes={statementPreviewerViewModes}
            setViewMode={setViewMode}
            viewMode={viewMode}
            ownership={currentOwnership}
          />
        )}
        currentRecordId={currentRecord.id}
        loadMoreRecords={loadMoreRecords}
        onRecordChange={(record) => setCurrentRecord(record)}
        showToast={false}
        onFinish={onClose}
        renderFooter={() => (
          <PreviewStatementsFooter zoom={Number(zoom)} setZoom={setZoom} />
        )}
        renderRecord={(record) => (
          <PreviewStatementsIframe
            zoom={zoom}
            html={record.views[viewMode]?.data?.html}
          />
        )}
      />
    </Dialog>
  );
}
