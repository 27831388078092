import { MergeTag, MergeTagCategory } from '../types/merge-tags';

/**
 * The data coming from the API is flat, so it is hard to differentiate between tags that are inherited and those that are not.
 * This hook remaps the tags so that the inherited ones are grouped together based on the context they are inherited from, resulting in a less flat structure.
 */
export const useRemappedMergeTags = (mergeTags: MergeTagCategory[]) => {
  const tags: Record<string, MergeTag[]> = {};

  mergeTags.forEach(({ id: parentId, items }) => {
    items.forEach((item) => {
      const id =
        item.inherited_from !== null
          ? `${parentId}_${item.inherited_from.id}`
          : parentId;
      if (!tags[id]) {
        tags[id] = [];
      }
      tags[id].push(item);
    });
  });

  return Object.keys(tags).map((id) => ({
    id,
    label: id,
    items: tags[id]
  }));
};
