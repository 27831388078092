import { Columns } from 'src/view/components/table';
import { RelatesToComplexCell } from 'src/modules/communications/messages/components/relates-to-complex-cell';
import { ChannelMessageRecipient } from '../types/ChannelMessageRecipient';
import { getRecipientText } from './get-recipient-text';

export const channelMessageRecipientColumns: Columns<ChannelMessageRecipient> = [
  {
    id: 'created_at',
    Header: 'Sent',
    type: 'datetime',
    accessor: (item) => item.created_at
  },
  {
    id: 'recipient',
    Header: 'Recipient(s)',
    accessor: (item) => {
      return getRecipientText(
        item.recipient_contact ? [item.recipient_contact] : []
      );
    }
  },
  {
    id: 'context',
    Header: 'Message relates to',
    Cell: RelatesToComplexCell,
    accessor: (item) => item.channel_message?.message?.context
  },
  {
    id: 'subject',
    Header: 'Subject',
    // @ts-ignore
    accessor: (item) => item.channel_message?.resolved_email_subject
  },
  {
    id: 'message_format',
    Header: 'Message format',
    type: 'value',
    accessor: (item) => item.channel_message?.channel_type
  },
  {
    id: 'send_from_author',
    type: 'author',
    Header: 'Sent from',
    accessor: (item) => item.channel_message?.message?.send_from_author
  }
];
