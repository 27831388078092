import { useDialog, useErrorDialog } from '@rexlabs/dialog';
import { useToast } from 'view/components/@luna/notifications/toast';
import React from 'react';
import { ArchiveDialog } from 'view/components/dialogs/archive-dialog/archive-dialog';
import { SingleActionDeclaration } from '../../../modules/common/actions/types/action-declaration-types';

export interface GetGenericArchiveActionArgs<
  ID extends string,
  Group extends string
> {
  id: ID;
  group: Group;
  recordTypeName: string;
  recordName: string;
  message: React.ReactNode;
  isReasonNeeded?: boolean;
  handleArchive: (values: ArchiveFormValues) => Promise<void>;
}

export type ArchiveFormValues = {
  reason?: string;
};

/**
 * replace with useStatusChangeAction()
 * @deprecated
 */
export function useGetGenericArchiveAction() {
  const { open: openArchiveDialog } = useDialog(ArchiveDialog);
  const { addToast } = useToast();
  const { open: openErrorDialog } = useErrorDialog();

  return <ID extends string, Group extends string>({
    id,
    group,
    recordTypeName,
    recordName,
    handleArchive,
    ...dialogProps
  }: GetGenericArchiveActionArgs<ID, Group>): SingleActionDeclaration => {
    return {
      id,
      group,
      label: 'Archive',
      handleAction: () =>
        openArchiveDialog({
          ...dialogProps,
          title: `Archive ${recordTypeName}`,
          submitLabel: `Archive ${recordTypeName}`,
          onSubmit: async (values: ArchiveFormValues) => {
            try {
              await handleArchive(values);
            } catch (e) {
              return openErrorDialog(e);
            }

            addToast({
              description: (
                <>
                  <b>{recordName}</b> has been archived
                </>
              )
            });
          }
        })
    };
  };
}
