import { useGetServicePackages } from 'src/modules/service-packages/hooks/use-get-service-packages';
import { Value } from 'view/components/values';
import React from 'react';

export function ServicePackageNames({ ids }: { ids: string[] }) {
  const servicePackages = useGetServicePackages(ids).data || [];

  const name = servicePackages.map((sp) => sp.name).join(', ');

  return <Value label='For service packages' value={name} />;
}
