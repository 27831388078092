import * as React from 'react';
import { DialogProps, useErrorDialog } from '@rexlabs/dialog';
import { Dialog } from 'view/components/@luna/dialog';
import { ErrorBanner } from 'view/components/@luna/notifications/banner';
import Box from '@rexlabs/box';
import { CreateMessageFormData } from '../mappers/types/create-message-form-data';
import { EmailPreview } from '../../common/components/email/email-preview';
import { mapCreateMessageFormToEmailPreviewProps } from '../mappers/map-create-message-form-to-email-preview-props';
import { useResolvedMessageTexts } from '../hooks/use-resolved-message-texts';
import { CommunicationContext } from '../types/communication-context';
import { getContextTypeFromRecord } from '../utils/get-context-type-from-record';

export interface PreviewMessageFormDataDialogProps extends DialogProps {
  data: {
    formData: CreateMessageFormData;
  };
}

/**
 * This dialog displays the preview of the message that will be sent, based on the as-yet-unsent form data.
 */
export function PreviewMessageFormDataDialog({
  onClose,
  data
}: PreviewMessageFormDataDialogProps) {
  const errorDialog = useErrorDialog();
  const {
    resolvedTexts: [subject, content],
    invalidTags,
    isLoading,
    isError,
    error
  } = useResolvedMessageTexts(
    [data.formData.subject ?? '', data.formData.content ?? ''],
    {
      type: { id: data.formData.relates_to.record.__record_type },
      record: { id: data.formData.relates_to.record.id }
    } as CommunicationContext,
    getContextTypeFromRecord(data?.formData?.relates_to?.record),
    data.formData.recipients?.map((item) => ({ id: item.id })),
    data.formData.sent_from
  );

  React.useEffect(() => {
    if (!isError) return;

    errorDialog.open(error);
  }, [isError, error]);

  return (
    <Dialog title='Preview message' onClose={onClose} isLoading={isLoading}>
      <Box flexDirection='column' gap={12}>
        {invalidTags.length > 0 && (
          <ErrorBanner
            description={`This message contains invalid merge tags: ${invalidTags.join(
              ', '
            )}`}
          />
        )}
        <EmailPreview
          {...mapCreateMessageFormToEmailPreviewProps({
            ...data.formData,
            subject,
            content
          })}
        />
      </Box>
    </Dialog>
  );
}
