import { useGetGenericWipAction } from 'view/hooks/actions/use-get-generic-wip-action';

export function useGetUnreconcileAction() {
  const getGenericWipAction = useGetGenericWipAction();

  return () =>
    getGenericWipAction({
      id: 'unreconcile',
      group: 'trust-journal-entry',
      actionName: 'Unreconcile entry'
    });
}
