import { useSessionState } from 'src/modules/common/hooks/session-state';

import { FLAGS } from 'utils/feature-flags';

import { ContentConfig } from 'view/components/record-screen/types';

import { ownershipTaxTransactionsBlock } from '../blocks/ownership-tax-transactions';
import { useSharedPendingContent } from './use-shared-pending-content';

export const usePendingOwnershipDisbursementContent: () => ContentConfig = () => {
  const sessionData = useSessionState();

  const {
    overview,
    billsAndFees,
    trustJournalPreview,
    directDebitInvoices,
    bpayInvoices,
    otherInvoices,
    disbursementMethod
  } = useSharedPendingContent('ownership');

  return [
    {
      label: 'General',
      items: [
        overview,
        billsAndFees,
        trustJournalPreview,
        ...(sessionData?.activeSilo?.country.id === 'GBR'
          ? [
              {
                id: 'ownership-tax-transactions',
                label: 'Tax transactions',
                flags: FLAGS.OWNERSHIP_TAX_REPORTS,
                blocks: [ownershipTaxTransactionsBlock]
              }
            ]
          : [])
      ]
    },
    {
      label: 'Specific invoice payouts',
      items: [
        directDebitInvoices,
        ...(sessionData?.activeSilo?.country?.id === 'AUS'
          ? [bpayInvoices]
          : []),
        otherInvoices
      ]
    },
    {
      label: 'Ownership',
      items: [disbursementMethod]
    }
  ];
};
