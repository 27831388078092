import {
  Checklist,
  ChecklistItem,
  ChecklistItemGroup,
  ChecklistWithIncludes
} from 'src/modules/tasks/common/types/Checklist';
import invariant from 'invariant';
import { Task } from '../types/Task';
import { TaskType } from '../types/TaskType';

export function getChecklistViewItems(
  items: ChecklistItem[],
  order: Checklist['item_order'],
  checklist: ChecklistWithIncludes,
  task?: Task<TaskType>
) {
  return order
    .map((orderId) => {
      return {
        ...items.find((item) => item.id === orderId)!,
        checklistId: checklist.id,
        checklist,
        task
      } as const;
    })
    .filter((item) => item.status?.id === 'active')
    .filter((item) => filterActiveGroups(item, checklist, items));
}

export function filterActiveGroups(
  item: ChecklistItem,
  checklist: ChecklistWithIncludes,
  items: ChecklistItem[]
) {
  if (item.checklist_item_group) {
    const group = checklist.groups?.data.find(
      (group) => group.id === item.checklist_item_group?.id
    );
    invariant(group, `Group not found for item ${item.id}`);
    return isGroupActive(checklist, group, items);
  }
  return true;
}

export function isGroupActive(
  checklist: ChecklistWithIncludes,
  group: ChecklistItemGroup,
  items: ChecklistItem[]
) {
  const triggerItem = items.find(
    (item) => item.id === group.triggered_by_checklist_item?.id
  );

  const isActive = group.is_active === triggerItem?.is_active;

  const isNested = !!triggerItem?.checklist_item_group;

  if (isNested) {
    // If it is nested, we need to check the active status of all ancestors. if any are inactive, we don't show this item.
    const parentGroup = checklist.groups?.data.find(
      (group) => group.id === triggerItem?.checklist_item_group?.id
    );
    if (parentGroup && !isGroupActive(checklist, parentGroup, items)) {
      return false;
    }
  }
  return isActive;
}
