import { FinancialRecordType } from 'utils/financials/financial-objects';
import { BankDeposit } from 'src/modules/banking/bank-deposits/types/bank-deposit-types';
import { BankWithdrawal } from 'src/modules/banking/bank-withdrawals/types/bank-withdrawal-types';
import { ChartOfAccountsAccount } from 'src/modules/chart-of-accounts/types/chart-of-account-types';
import { Contact } from 'src/modules/contacts/types/contact-types';
import { Author } from 'src/modules/authors/types/author-types';
import { Property } from 'src/modules/properties/types/property-types';
import { PropertyOwnership } from 'src/modules/property-ownerships/types/property-ownership-types';
import { PropertyTenancy } from 'src/modules/property-tenancies/types/property-tenancy-types';
import { UniversalUser } from 'data/models/custom/session';
import { User } from 'src/modules/users/types/user-types';
import { TrustJournalEntry } from 'src/modules/trust-journal-entries/types/trust-journal-entry-types';
import { TrustLedger } from 'src/modules/trust-ledgers/types/trust-ledger-types';
import { Document } from 'data/models/entities/documents';
import { Statement } from 'src/modules/statements/types';
import { AgencyFee } from 'src/modules/agency-fees/types/agency-fee-types';
import { TrustJournalEntryLineItem } from 'src/modules/trust-journal-entry-line-items/types/trust-journal-entry-line-item-type';
import { AccountingJournalEntry } from 'src/modules/account-journal-entries/types/account-journal-entry-types';
import { Note } from 'src/modules/note/types/Note';
import { MessageTemplate } from 'src/modules/communications/message-templates/types/MessageTemplate';
import { Message } from 'src/modules/communications/messages/types/Message';
import { Task } from 'src/modules/tasks/common/types/Task';
import { ChecklistTemplate } from 'src/modules/tasks/settings/types/ChecklistTemplate';
import { TaskType } from 'src/modules/tasks/common/types/TaskType';
import { ChannelMessageRecipient } from 'src/modules/communications/messages/types/ChannelMessageRecipient';
import { BatchReceiptingItem } from 'src/modules/banking/batch-receipting/types';
import { BaseModelGeneratorModel } from '@rexlabs/model-generator';
import { Appliance } from 'src/modules/appliances/types/Appliance';
import { ComplianceType } from 'src/modules/compliance/common/models/compliance-types';
import { Bill } from 'src/modules/bill-processing/types/Bill';
import { Role } from 'src/modules/roles/types/role-types';
import {
  Checklist,
  ChecklistItem
} from 'src/modules/tasks/common/types/Checklist';
import { PortfolioMember } from 'src/modules/portfolios/models/portfolio-members-model';
import { PropertyAreaType } from 'src/modules/property-areas/property-area-types/types/PropertyAreaType';
import { PropertyArea } from 'src/modules/property-areas/property-areas/types/PropertyArea';
import { InspectionArea } from 'src/modules/tasks/inspections/entry-exit/types/InspectionArea';
import { InspectionAreaItem } from 'src/modules/tasks/inspections/entry-exit/types/InspectionAreaItem';
import {
  Portfolio,
  TaskTypeAssignment
} from 'src/modules/portfolios/models/portfolios-model';
import { ServicePackage } from 'src/modules/service-packages/types/service-package';
import { PrepaymentBucket } from 'src/modules/prepayments/types/prepayment-bucket';
import { AuditLog } from 'src/modules/audit-logs/types/AuditLog';
import { Ownership } from './entities/ownerships';
import { Tenancy } from './entities/tenancies';
import { Invoice } from './entities/financials/invoices';
import { CommunicationTemplate } from './entities/communication/templates';
import { ChargeEntry } from './entities/financials/charge-entries';
import { RecurringChargeEntry } from './entities/financials/recurring-charge-entries';
import { BankAccount } from './entities/financials/bank-accounts';
import { Reconciliation } from './entities/financials/reconciliations';
import { TrustAccount } from './entities/financials/trust-accounts';
import { SupplierComplianceCategory } from './entities/settings/supplier-compliance-categories';
import { PropertyComplianceCategory } from './entities/settings/property-compliance-categories';
import { EmailLayout } from './entities/communication/email-layouts';
import { LetterLayout } from './entities/communication/letter-layouts';
import { CreditNote } from './entities/financials/credit-notes';
import { RecurringInvoice } from './entities/financials/recurring-invoices';
import { SecurityDeposit } from './entities/financials/security-deposits';
import { PendingOwnershipDisbursement } from './entities/financials/pending-disbursements/pending-ownership-disbursement';
import { PendingContactDisbursement } from './entities/financials/pending-disbursements/pending-contact-disbursement';
import { Disbursement } from './entities/financials/disbursements/disbursements';
import { OwnershipFee } from './entities/ownerships/fees/ownership-fees';

// INJECT: Add Imports Here

export interface ValueListValue<T> {
  id: T;
  label: string;
}

export interface Attachment {
  id: string;
  url: string;
  description: string;
  width: number;
  height: number;
}

export interface TimestampAttributes {
  created_at: Date; // ISO date string
  updated_at: Date | null; // ISO date string
  created_by: Author;
  updated_by?: Author;
}

export interface RecordReference {
  custom_record_reference: string | null;
  record_reference: string;
  system_record_reference: string;
}

export interface BaseFields<TRecordType> extends BaseModelGeneratorModel {
  __record_type: TRecordType;
}

export type TimeAndBaseFields<TRecordType> = BaseFields<TRecordType> &
  TimestampAttributes;

export interface PayableEntityFinancialSummary {
  invoices_receivable_total_amount_owing: number;
  invoices_receivable_count: number;
  bills_and_fees_payable_total_amount_owing: number;
  bills_and_fees_payable_count: number;
}

export const RecordTypes = {
  AuditLog: 'audit_log',
  PrepaymentBucket: 'prepayment_bucket',
  InspectionAreaItem: 'inspection_area_item',
  InspectionArea: 'inspection_area',
  PropertyArea: 'property_area',
  PropertyAreaType: 'property_area_type',
  PropertyDetails: 'property_details',
  TaskWorkOrder: 'task_work_order',
  TaskQuote: 'task_quote',
  Appliance: 'appliance',
  TaskPropertyCompliance: 'task_property_compliance',
  TaskSupplierCompliance: 'task_supplier_compliance',
  ChecklistTemplate: 'checklist_template',
  Checklist: 'checklist',
  ChecklistItem: 'checklist_item',
  Bill: 'bill',
  TaskArrears: 'task_arrears',
  TaskMoveOut: 'task_move_out',
  TaskMoveIn: 'task_move_in',
  TaskInspection: 'task_inspection',
  TaskMaintenance: 'task_maintenance',
  TaskLeaseReview: 'task_lease_review',
  Todo: 'todo',
  Task: 'task',
  Message: 'message',
  MessageTemplate: 'message_template',
  ChannelMessageRecipient: 'channel_message_recipient',
  Note: 'note',
  AccountingJournalEntry: 'accounting_journal_entry',
  BankAccount: 'bank_account',
  BankDeposit: 'bank_deposit',
  BankWithdrawal: 'bank_withdrawal',
  ChargeEntry: 'charge_entry',
  ChartOfAccountsAccount: 'chart_of_accounts_account',
  CommunicationTemplate: 'communication_template',
  Contact: 'contact',
  CreditNote: 'credit_note',
  EmailLayout: 'email_layout',
  Invoice: 'invoice',
  LetterLayout: 'letter_layout',
  Ownership: 'ownership',
  Portfolio: 'portfolio',
  ProfileSettings: 'profile_settings',
  Property: 'property',
  PropertyComplianceCategory: 'property_compliance_category',
  PropertyComplianceType: 'property_compliance_type',
  PropertyOwnership: 'property_ownership',
  PropertyTenancy: 'property_tenancy',
  Reconciliation: 'reconciliation',
  RecurringChargeEntry: 'recurring_charge_entry',
  RecurringInvoice: 'recurring_invoice',
  SupplierComplianceCategory: 'supplier_compliance_category',
  Tenancy: 'tenancy',
  TrustAccount: 'trust_account',
  TrustJournalEntry: 'trust_journal_entry',
  TrustJournalEntryLineItem: 'trust_journal_entry_line_item',
  TrustLedger: 'trust_ledger',
  User: 'user',
  SecurityDeposit: 'security_deposit',
  Statement: 'statement',
  YearlyOwnershipStatement: 'yearly_ownership_statement',
  YearlyAgencyStatement: 'yearly_agency_statement',
  PeriodicOwnershipStatement: 'periodic_ownership_statement',
  PeriodicAgencyStatement: 'periodic_agency_statement',
  AgencyFee: 'agency_fee',
  OwnershipFee: 'ownership_fee',
  PendingOwnershipDisbursement: 'pending_ownership_disbursement',
  PendingContactDisbursement: 'pending_contact_disbursement',
  Disbursement: 'disbursement',
  Author: 'author',
  UniversalUser: 'universal_user',
  Document: 'document',
  BankStatementTransaction: 'bank_statement_transaction',
  BankStatementTransactionImport: 'bank_statement_transaction_import',
  KeySet: 'key_set',
  Role: 'role',
  PortfolioMember: 'portfolio_member',
  TaskTypeAssignment: 'task_type_assignment',
  UploadedBill: 'uploaded_bill',
  OwnershipTaxTransaction: 'ownership_tax_transaction',
  TaxSummary: 'tax_summary',
  ServicePackage: 'service_package'
} as const;

export type RecordType = typeof RecordTypes[keyof typeof RecordTypes];

export interface RecordTypeObject {
  id: RecordType;
  label?: string;
}

export type RecordObject =
  | {
      type: typeof RecordTypes.AuditLog;
      object: AuditLog;
    }
  | {
      type: typeof RecordTypes.PrepaymentBucket;
      object: PrepaymentBucket;
    }
  | {
      type: typeof RecordTypes.InspectionAreaItem;
      object: InspectionAreaItem;
    }
  | {
      type: typeof RecordTypes.InspectionArea;
      object: InspectionArea;
    }
  | {
      type: typeof RecordTypes.PropertyAreaType;
      object: PropertyAreaType;
    }
  | {
      type: typeof RecordTypes.PropertyArea;
      object: PropertyArea;
    }
  | { type: typeof RecordTypes.TaskWorkOrder; object: Task<'task_work_order'> }
  | { type: typeof RecordTypes.TaskQuote; object: Task<'task_quote'> }
  | { type: typeof RecordTypes.Appliance; object: Appliance }
  | {
      type: typeof RecordTypes.ChecklistTemplate;
      object: ChecklistTemplate;
    }
  | {
      type: typeof RecordTypes.OwnershipFee;
      object: OwnershipFee;
    }
  | {
      type: typeof RecordTypes.Bill;
      object: Bill;
    }
  | { type: typeof RecordTypes.Checklist; object: Checklist }
  | { type: typeof RecordTypes.ChecklistItem; object: ChecklistItem }
  | {
      type: typeof RecordTypes.TaskSupplierCompliance;
      object: Task<'task_supplier_compliance'>;
    }
  | {
      type: typeof RecordTypes.TaskPropertyCompliance;
      object: Task<'task_property_compliance'>;
    }
  | {
      type: typeof RecordTypes.TaskArrears;
      object: Task<'task_arrears'>;
    }
  | {
      type: typeof RecordTypes.TaskMoveOut;
      object: Task<'task_move_out'>;
    }
  | {
      type: typeof RecordTypes.TaskMoveIn;
      object: Task<'task_move_in'>;
    }
  | {
      type: typeof RecordTypes.TaskInspection;
      object: Task<'task_inspection'>;
    }
  | {
      type: typeof RecordTypes.TaskLeaseReview;
      object: Task<'task_lease_review'>;
    }
  | {
      type: typeof RecordTypes.TaskMaintenance;
      object: Task<'task_maintenance'>;
    }
  | {
      type: typeof RecordTypes.Todo;
      object: Task<'todo'>;
    }
  | {
      type: typeof RecordTypes.Task;
      object: Task<TaskType>;
    }
  | {
      type: typeof RecordTypes.Message;
      object: Message;
    }
  | {
      type: typeof RecordTypes.MessageTemplate;
      object: MessageTemplate;
    }
  | {
      type: typeof RecordTypes.ChannelMessageRecipient;
      object: ChannelMessageRecipient;
    }
  | {
      type: typeof RecordTypes.Note;
      object: Note;
    }
  | {
      type: typeof RecordTypes.AccountingJournalEntry;
      object: AccountingJournalEntry;
    }
  | {
      type: typeof RecordTypes.AgencyFee;
      object: AgencyFee;
    }
  | {
      type: typeof RecordTypes.Contact;
      object: Contact;
    }
  | {
      type: typeof RecordTypes.Property;
      object: Property;
    }
  | {
      type: typeof RecordTypes.Ownership;
      object: Ownership;
    }
  | {
      type: typeof RecordTypes.PropertyOwnership;
      object: PropertyOwnership;
    }
  | {
      type: typeof RecordTypes.PropertyDetails;
      object: Property;
    }
  | {
      type: typeof RecordTypes.Tenancy;
      object: Tenancy;
    }
  | {
      type: typeof RecordTypes.PropertyTenancy;
      object: PropertyTenancy;
    }
  | {
      type: typeof RecordTypes.Invoice;
      object: Invoice;
    }
  | {
      type: typeof RecordTypes.BankDeposit;
      object: BankDeposit;
    }
  | {
      type: typeof RecordTypes.BankWithdrawal;
      object: BankWithdrawal;
    }
  | {
      type: typeof RecordTypes.CommunicationTemplate;
      object: CommunicationTemplate;
    }
  | {
      type: typeof RecordTypes.ChargeEntry;
      object: ChargeEntry;
    }
  | {
      type: typeof RecordTypes.RecurringChargeEntry;
      object: RecurringChargeEntry;
    }
  | {
      type: typeof RecordTypes.User;
      object: User;
    }
  | {
      type: typeof RecordTypes.BankAccount;
      object: BankAccount;
    }
  | {
      type: typeof RecordTypes.Reconciliation;
      object: Reconciliation;
    }
  | {
      type: typeof RecordTypes.ChartOfAccountsAccount;
      object: ChartOfAccountsAccount;
    }
  | {
      type: typeof RecordTypes.TrustAccount;
      object: TrustAccount;
    }
  | {
      type: typeof RecordTypes.TrustJournalEntry;
      object: TrustJournalEntry;
    }
  | {
      type: typeof RecordTypes.TrustLedger;
      object: TrustLedger;
    }
  | {
      type: typeof RecordTypes.Portfolio;
      object: Portfolio;
    }
  | {
      type: typeof RecordTypes.SupplierComplianceCategory;
      object: SupplierComplianceCategory;
    }
  | { type: typeof RecordTypes.Statement; object: Statement }
  | { type: typeof RecordTypes.YearlyOwnershipStatement; object: Statement }
  | { type: typeof RecordTypes.YearlyAgencyStatement; object: Statement }
  | { type: typeof RecordTypes.PeriodicOwnershipStatement; object: Statement }
  | { type: typeof RecordTypes.PeriodicAgencyStatement; object: Statement }
  | {
      type: typeof RecordTypes.PropertyComplianceCategory;
      object: PropertyComplianceCategory;
    }
  | {
      type: typeof RecordTypes.PropertyComplianceType;
      object: ComplianceType;
    }
  | {
      type: typeof RecordTypes.EmailLayout;
      object: EmailLayout;
    }
  | {
      type: typeof RecordTypes.LetterLayout;
      object: LetterLayout;
    }
  | {
      type: typeof RecordTypes.CreditNote;
      object: CreditNote;
    }
  | {
      type: typeof RecordTypes.RecurringInvoice;
      object: RecurringInvoice;
    }
  | {
      type: typeof RecordTypes.ProfileSettings;
      // TODO: Figure out profile settings type
      object: any;
    }
  | {
      type: typeof RecordTypes.SecurityDeposit;
      object: SecurityDeposit;
    }
  | {
      type: typeof RecordTypes.PendingOwnershipDisbursement;
      object: PendingOwnershipDisbursement;
    }
  | {
      type: typeof RecordTypes.PendingContactDisbursement;
      object: PendingContactDisbursement;
    }
  | {
      type: typeof RecordTypes.Disbursement;
      object: Disbursement;
    }
  | {
      type: typeof RecordTypes.Author;
      object: Author;
    }
  | {
      type: typeof RecordTypes.UniversalUser;
      object: UniversalUser;
    }
  | {
      type: typeof RecordTypes.Document;
      object: Document;
    }
  | {
      type: typeof RecordTypes.TrustJournalEntryLineItem;
      object: TrustJournalEntryLineItem;
    }
  | {
      type: typeof RecordTypes.BankStatementTransaction;
      object: BatchReceiptingItem;
    }
  | {
      type: typeof RecordTypes.Role;
      object: Role;
    }
  | { type: typeof RecordTypes.PortfolioMember; object: PortfolioMember }
  | { type: typeof RecordTypes.TaskTypeAssignment; object: TaskTypeAssignment }
  | { type: typeof RecordTypes.UploadedBill; object: Bill }
  | {
      type: typeof RecordTypes.ServicePackage;
      object: ServicePackage;
    };

export type Payable = {
  object: {
    type: ValueListValue<FinancialRecordType>;
    id: string;
    label?: string;
    record?: unknown;
  };
  chart_of_accounts_account?: ValueListValue<string> | null;
};
