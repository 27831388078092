import React from 'react';
import { useDialog } from '@rexlabs/dialog';
import _ from 'lodash';

import { BlockConfig } from 'view/components/record-screen/types';
import { ComplianceEntriesList } from 'src/modules/compliance/common/components/compliance-entries-list';

import { AddNewCheckDialog } from 'src/modules/compliance/common/dialogs/add-new-check-dialog';
import { ActionButtons } from 'view/components/@luna/action-buttons';
import AddIcon from 'view/components/icons/add';

import { PropertyComplianceBanner } from 'src/modules/compliance/common/components/property-compliance-banner';
import { PropertyInfoComplianceBanner } from 'src/modules/compliance/common/components/property-info-compliance-banner';

import { FlattenedProperty } from 'src/modules/properties/types/property-types';

import { EmptyCategoryPropertyComplianceBanner } from 'src/modules/compliance/common/components/empty-category-property-compliance-banner';
import { getUnfulfilledComplianceTypes } from 'src/modules/compliance/common/utils/get-unfulfilled-compliance-types';

export const complianceEntriesBlock: BlockConfig<FlattenedProperty> = {
  id: 'compliance-entries',
  title: 'Insurance, Safety Checks and Certificates',
  View: ({ data }) => {
    const entryData = data?.compliance_entries;

    const emptyComplianceCategories = data?.compliance_categories?.filter(
      (category) => category?.category_requirements?.data?.length === 0
    );

    const complianceTypes = _.uniqBy(
      data?.compliance_categories?.flatMap((category) =>
        category?.category_requirements?.data?.map(
          (categoryTypePivot) => categoryTypePivot.compliance_type
        )
      ),
      'id'
    );

    const unfulfilledComplianceTypes = getUnfulfilledComplianceTypes({
      complianceTypes,
      entries: entryData
    });

    const bannerEntries = entryData?.filter((entry) => {
      return (
        entry?.status?.id === 'expired' || entry?.status?.id === 'expires_soon'
      );
    });

    return (
      <>
        {!!emptyComplianceCategories?.length && (
          <EmptyCategoryPropertyComplianceBanner
            emptyCategories={emptyComplianceCategories}
          />
        )}
        {unfulfilledComplianceTypes.map((complianceType) => (
          <PropertyInfoComplianceBanner
            key={complianceType.id}
            complianceType={complianceType}
            propertyId={data?.id}
          />
        ))}
        {bannerEntries?.map((entry) => (
          <PropertyComplianceBanner
            key={entry.id}
            propertyId={data?.id}
            complianceType={entry.compliance_type}
            status={entry.status}
          />
        ))}
        {data && !!entryData?.length && (
          <ComplianceEntriesList objectId={data.id} objectType={'property'} />
        )}
      </>
    );
  },
  Actions: ({ data }) => {
    const addNewCheckDialog = useDialog(AddNewCheckDialog);

    return (
      <ActionButtons
        actions={[
          {
            Icon: AddIcon,
            intent: 'tertiary',
            handleAction: () =>
              addNewCheckDialog.open({
                propertyId: data?.id
              })
          }
        ]}
      />
    );
  }
};
