import React from 'react';

import { CellProps, TagCell } from '@rexlabs/table';
import { StatusAverageTag, StatusBadTag } from '@rexlabs/tag';
import { PendingDisbursement } from 'data/models/entities/financials/pending-disbursements';

export function PendingDisbursementStatusCell({
  value
}: CellProps<unknown, PendingDisbursement['status']>) {
  const renderTag = (value) => {
    const { label, id } = value;
    switch (id) {
      case 'overdue':
        return <StatusBadTag>{label}</StatusBadTag>;
      default:
        return <StatusAverageTag>{label}</StatusAverageTag>;
    }
  };

  return <TagCell>{renderTag(value)}</TagCell>;
}
