import React from 'react';

import { Truncate } from '@rexlabs/text';
import { margin, StyleSheet, useStyles } from '@rexlabs/styling';
import { CellProps } from '@rexlabs/table';
import { slugToSentenceCase } from 'src/modules/communications/messages/utils/slug-to-sentence-case';

const defaultStyles = StyleSheet({
  subText: {
    fontSize: '1.2rem',
    color: ({ token }) => token('color.textStyle.body.subtext')
  },

  secondLine: {
    ...margin.styles({
      top: 'xxs'
    })
  }
});

export function RelatesToCell({ value }: CellProps) {
  const s = useStyles(defaultStyles);

  return (
    <div>
      <Truncate>{value?.details.relates_to?.display_name ?? '--'}</Truncate>
      {value?.details && (
        <div {...s('secondLine')}>
          <Truncate {...s('subText')}>
            {slugToSentenceCase(value?.details.relates_to_type)}
          </Truncate>
        </div>
      )}
    </div>
  );
}
