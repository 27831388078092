import { useDialog } from '@rexlabs/dialog';
import { RegularActionMenuItem } from '@rexlabs/action-menu';
import { Contact } from 'src/modules/contacts/types/contact-types';
import { CreateContactPaymentMethodDialog } from '../dialogs/create-contact-payment-method';

export function useAddPaymentMethod() {
  const { open } = useDialog(CreateContactPaymentMethodDialog);

  const getAddPaymentMethodAction = (
    contact: Contact,
    updateListId?: string
  ): RegularActionMenuItem => ({
    label: 'Add payment method',
    onClick: () =>
      open({
        contactId: contact.id,
        updateListId
      })
  });

  return {
    getAddPaymentMethodAction
  };
}
