import React from 'react';
import { SearchInput } from 'src/modules/communications/common/components/search-input';
import { Column, Grid } from 'view/components/@luna/form/grid';
import { BlockConfig } from 'view/components/record-screen/types';
import { useGetResetTableUrlParams } from 'view/components/table/hooks/use-get-reset-table-url-params';
import {
  FinancialFolioTabbedTable,
  tableTabIds
} from '../components/financial-folio-tabbed-table';

export const searchForFolioBlock: BlockConfig<any> = {
  id: 'search-for-folio',
  Edit: ({ blockProps }) => {
    const {
      searchValue,
      setSearchValue,
      selectedFolio,
      setSelectedFolio
    } = blockProps;
    const resetTableUrlParams = useGetResetTableUrlParams();

    return (
      <>
        <Grid columns={4}>
          <Column width={2}>
            <SearchInput
              onSubmit={(value) => {
                resetTableUrlParams({
                  resetPageTableKeys: tableTabIds
                });
                setSearchValue(value);
              }}
              initialValue={searchValue}
            />
          </Column>
          <Column width={4}>
            <FinancialFolioTabbedTable
              searchValue={searchValue}
              setSelectedFolio={setSelectedFolio}
              selectedFolio={selectedFolio}
            />
          </Column>
        </Grid>
      </>
    );
  }
};
