import { getDocumentsBlock } from 'src/modules/documents/blocks/documents';
import { Task } from 'src/modules/tasks/common/types/Task';

export const documentsBlock = getDocumentsBlock(
  ({ data }: { data: Task<any> }) => ({
    attachedTo: [
      {
        id: data.id,
        label: data.summary || '',
        type: { id: 'task' },
        record: data
      }
    ],
    taskId: data.id
  })
);
