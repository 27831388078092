import React from 'react';
import { PrimaryButton } from '@rexlabs/button';
import Box from '@rexlabs/box';
import { Body, Heading } from '@rexlabs/text';
import { useToken } from '@rexlabs/styling';

import { BlockConfig } from 'view/components/record-screen/types';
import { ListTable } from 'view/components/table';
import { Ownership } from 'data/models/entities/ownerships';

import { Trans, useTranslation } from 'react-i18next';
import { useBaseDisbursementMethodsColumns } from '../hooks/use-base-disbursement-methods-columns';
import { DisbursementPaymentMethodsEdit } from '../components/disbursement-payment-methods-edit';

export const ownershipDisbursementPaymentMethodsBlock: BlockConfig<Ownership> = {
  id: 'ownership-disbursement-preferences',
  title: () => <Trans i18nKey='disbursements.method.label' />,
  validate: {
    definitions: {
      'disbursement_payment_methods.*.contact_payment_method': {
        rules: 'required'
      },
      'disbursement_payment_methods.*.contact': { rules: 'required' },
      'disbursement_payment_methods.*.split_amount.amount': {
        rules: [
          'required',
          {
            key: 'check-100-percent',
            type: 'global',
            rule:
              'disbursementNotMoreThan100Percent|disbursementNotLessThan100Percent'
          }
        ]
      }
    }
  },
  showEmpty: (data) => !data.disbursement_payment_methods?.length,
  Empty: ({ onEditClick }) => {
    const { t } = useTranslation();
    const token = useToken();
    return (
      <Box
        justifyContent='center'
        alignItems='center'
        flexDirection='column'
        margin={token('spacing.xxxl')}
        style={{ textAlign: 'center' }}
      >
        <Box>
          <Heading level={3}>{t('disbursements.ownerships.set-up')}</Heading>
          <Body>{t('disbursements.ownerships.description')}</Body>
        </Box>
        <PrimaryButton onClick={onEditClick}>
          {t('disbursements.add-method')}
        </PrimaryButton>
      </Box>
    );
  },
  View: ({ data }) => {
    const disbursementMethodsColumns = useBaseDisbursementMethodsColumns();

    return (
      <ListTable
        hidePagination
        id='disbursement-payment-methods'
        items={data?.disbursement_payment_methods}
        isLoading={false}
        columns={disbursementMethodsColumns}
      />
    );
  },
  Edit: ({ values, data }) =>
    DisbursementPaymentMethodsEdit({
      values,
      data
    })
};
