import React from 'react';
import { Field } from '@rexlabs/form';
import { TextInput } from '@rexlabs/text-input';

import { BlockConfig } from 'view/components/record-screen/types';
import { HorizontalDivider } from 'view/components/ui/horizontal-divider';
import { Grid, Column } from 'view/components/@luna/form/grid';

import { CentAmountInput } from 'view/components/inputs/cent-amount';
import { SecurityDepositSummary } from '../components/security-deposit-summary';

export const securityDepositDetailsBlock: BlockConfig = {
  id: 'security-deposit-details',
  title: 'Security deposit details',
  validate: {
    definitions: {}
  },
  Edit: function SecurityDepositEdit({ values }) {
    return (
      <Grid columns={2}>
        <Field
          name='registration_number'
          label='Deposit registration number'
          Input={TextInput}
        />

        <Field
          name='amount_expected'
          label='Amount required'
          Input={CentAmountInput}
        />

        <Column width={2}>
          <HorizontalDivider />
          <div className='mt-xl'>
            <SecurityDepositSummary
              amount={{
                type: { id: 'amount', label: 'Amount' },
                amount_expected: values.amount_expected
              }}
              securityDepositTypeHeldBy={'held_by_deposit_authority'}
              amountPaid={values?.amount_paid}
              recordId={values?.property_tenancy?.id}
            />
          </div>
        </Column>
      </Grid>
    );
  }
};
