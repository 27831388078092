import { DialogProps, useDialog, useErrorDialog } from '@rexlabs/dialog';
import { Forms } from '@rexlabs/form';
import { useModelActions } from '@rexlabs/model-generator';
import invariant from 'invariant';
import * as React from 'react';
import { invokeActionDeclaration } from 'src/modules/common/actions/utils/invoke-action-declaration';
import { useRecordUpdatedToast } from 'src/modules/common/toasts/hooks/use-record-updated-toast';
import { SearchResultItem } from 'utils/api/get-search-results';
import { RecordDialog } from 'view/components/record-screen/dialog/dialog';
import { extractValuesFromForms } from 'src/modules/common/utils/extract-values-from-forms';
import {
  getRelatesToSearchResultItem,
  mapMessageToEditFormData
} from '../mappers/map-message-to-edit-form-data';
import {
  CoreCommunicationContextObject,
  CreateMessageFormData
} from '../mappers/types/create-message-form-data';
import { Message } from '../types/Message';

import {
  MessageCreateDialogButtonGroup,
  MessageCreateDialogButtonGroupProps
} from '../components/message-create-dialog-button-group';

import { mapEditMessageFormToRequest } from '../mappers/map-edit-message-form-to-request';
import { CreateMessageRequest } from '../mappers/types/create-message-request';
import { messagesModel } from '../models/messages-model';
import { messageDetailsBlock } from '../blocks/message-details-block';
import { letterContentBlock } from '../blocks/letter-content-block';
import { getChangeHandlers } from '../utils/get-change-handlers';
import { useGetBulkPrintLetterAction } from '../hooks/action-declarations/use-get-bulk-print-letter-action';
import { PreviewLetterFormDataDialog } from './preview-letter-form-data-dialog';

const SEND_NOW = Symbol('SEND_NOW');
const SEND_LATER = Symbol('SEND_LATER');

export interface EditLetterRecordDialogProps extends DialogProps {
  data: {
    message: Message;
  };
  onSave?: () => void;
  hasButtonGroupActions?: boolean;
}

const content = [
  {
    id: 'edit',
    label: 'Edit letter',
    blocks: [messageDetailsBlock, letterContentBlock]
  }
];

export function EditLetterRecordDialog({
  hasButtonGroupActions = true,
  onClose,
  onSave,
  data
}: EditLetterRecordDialogProps) {
  const [relatesTo, setRelatesTo] = React.useState<
    SearchResultItem<CoreCommunicationContextObject>
  >(
    getRelatesToSearchResultItem(
      data.message.context as CoreCommunicationContextObject,
      data.message.context_type
    )
  );

  const errorDialog = useErrorDialog();

  // The API models do not map exactly to what the form data expects, so we need a layer in between
  const mappedValues = mapMessageToEditFormData({
    message: data?.message,
    type: 'letter'
  });

  // convert the recipients into a single item. This is needed to prevent more recipients being added on edit.
  const initialValues = {
    ...mappedValues,
    relates_to: relatesTo,
    recipients: mappedValues?.recipients?.[0]
  };

  const { open: openPreviewFormDataDialog } = useDialog(
    PreviewLetterFormDataDialog
  );

  const handlePreview = React.useCallback(
    (forms) => {
      const formData: CreateMessageFormData = extractValuesFromForms(forms);
      openPreviewFormDataDialog({ data: { formData } });
    },
    [openPreviewFormDataDialog]
  );

  // Define the change handlers here to avoid tightly coupling the blocks together
  const getHandlers = (forms?: Forms) =>
    getChangeHandlers(setRelatesTo, forms, 'letter');

  const message = data?.message;

  const { updateItem, refreshLists } = useModelActions(messagesModel);
  const addToast = useRecordUpdatedToast(messagesModel, { actions: [] });
  const getBulkPrintLetterAction = useGetBulkPrintLetterAction();

  const clickedButtonRef = React.useRef<symbol | null>(null);

  // To reduce duplication, we can extract common behavior into a function
  const editMessage = React.useCallback(
    async (data: Partial<CreateMessageRequest>) => {
      const { data: updatedMessage } = await updateItem({
        id: message.id,
        data: data,
        // we need to include the channels include because it is used in the toast
        // when deriving the record label
        args: {
          include: 'channels,template'
        }
      });

      await refreshLists();

      return updatedMessage;
    },
    [updateItem, refreshLists, message.id]
  );

  // Because the actual api calls are handled by the functions below, the submit handler will just be used for the built-in validation, and transforming the value to the desired DTO
  const handleSubmit = React.useCallback(
    async ({ values, changedValues }) => {
      const type = clickedButtonRef.current;

      const data = await mapEditMessageFormToRequest(
        {
          ...changedValues,
          ...((changedValues.letterAddress || changedValues.content) && {
            letterAddress: values.letterAddress,
            content: values.content
          })
        },
        'letter'
      );

      switch (type) {
        // Send now will use the same logic for message edits, but will then invoke the send now action
        case SEND_NOW: {
          try {
            const message = await editMessage(data);
            await invokeActionDeclaration(getBulkPrintLetterAction, [message]);

            onSave?.();

            return message;
          } catch (error) {
            invariant(error instanceof Error, 'error should be Error');

            errorDialog.open({
              message: error.message
            });
          }

          break;
        }

        // Send later will basically just create the message and that is all
        case SEND_LATER: {
          try {
            const updatedMessage = await editMessage(data);
            addToast(updatedMessage);

            onSave?.();

            return true;
          } catch (error) {
            invariant(error instanceof Error, 'error should be Error');

            errorDialog.open({
              message: error.message
            });
          }

          break;
        }

        default: {
          return;
        }
      }
    },
    [editMessage, getBulkPrintLetterAction, errorDialog, addToast]
  );

  const handleSendLater = React.useCallback(() => {
    clickedButtonRef.current = SEND_LATER;
  }, []);

  const handleSendNow = React.useCallback(() => {
    clickedButtonRef.current = SEND_NOW;
  }, []);

  return (
    <RecordDialog
      getHandlers={getHandlers}
      title='Edit letter'
      size='xl'
      submitLabel={hasButtonGroupActions ? 'Print letter now' : 'Save letter'}
      initialValues={initialValues}
      data={initialValues}
      content={content}
      onClose={onClose}
      handleSubmit={handleSubmit}
      blockProps={{
        messageType: 'letter',
        singleRecipient: true // this prevents the user from modifying the letter and adding more than 1 recipient after multiplexing
      }}
      // need to pass different actions to the button group depending on which part of the split button is clicked
      ButtonGroup={MessageCreateDialogButtonGroup}
      buttonGroupProps={
        {
          hasButtonGroupActions,
          handlePreview,
          handleSendLater,
          handleSendNow
        } as MessageCreateDialogButtonGroupProps
      }
    />
  );
}
