import * as React from 'react';
import { RecordTable, RecordTableProps, Columns } from 'view/components/table';
import { Body } from '@rexlabs/text';
import { Box } from '@rexlabs/box';
import Icons from '@rexlabs/icons';
import CheckIcon from 'view/components/icons/check';
import { useToken } from '@rexlabs/styling';
import {
  BankStatementChildTransaction,
  NewSplitTransactionFormValue
} from '../types';
import { checkTransactionState } from '../utils/check-transaction-state';

export const transactionColumns: Columns<BankStatementChildTransaction> = [
  {
    id: 'status',
    accessor: (item) => item,
    Cell: StatusCell,
    width: 25
  },
  {
    id: 'paid_date',
    Header: 'Date',
    accessor: (item) => item.paid_date,
    type: 'date',
    width: 120
  },
  {
    id: 'description',
    Header: 'Description',
    accessor: (item) => item.description,
    width: 250
  },
  {
    id: 'reference',
    Header: 'Reference',
    accessor: (item) => item.reference,
    width: 120
  },
  {
    id: 'receipted_date',
    Header: 'Receipted date',
    accessor: (item) => item.trust_journal_entries?.data?.[0]?.date_of,
    type: 'date',
    width: 120
  },
  {
    id: 'amount',
    Header: 'Amount',
    accessor: (item) => item.amount,
    type: 'currency',
    width: 120
  }
];

export type ChildTransactionsTableProps = Pick<
  RecordTableProps<any>,
  'items' | 'columns'
>;

export function ChildTransactionsTable(props: ChildTransactionsTableProps) {
  const { columns = transactionColumns, items } = props;
  const token = useToken();
  const green = token('palette.green.900');
  const indigo = token('palette.indigo.200');

  return (
    <>
      <RecordTable
        id='child-transactions'
        isLoading={false}
        columns={columns}
        items={items}
        Empty={Empty}
      />

      <Box gap={32}>
        <Box gap={8} alignItems='center'>
          <Icons.CircleOutline color={indigo} />
          Unmatched
        </Box>
        <Box gap={8} alignItems='center'>
          <Icons.CircleFilled color={green} />
          Matched
        </Box>
        <Box gap={8} alignItems='center'>
          <CheckIcon color={green} />
          Receipted
        </Box>
      </Box>
    </>
  );
}

function Empty() {
  return (
    <Box alignItems='center' justifyContent='center'>
      <Body>There are no child transactions</Body>
    </Box>
  );
}

function StatusCell(props: {
  value: BankStatementChildTransaction | NewSplitTransactionFormValue;
}) {
  const { value } = props;

  const token = useToken();
  const indigo = token('palette.indigo.200');
  const green = token('palette.green.900');

  const state = checkTransactionState(value);

  if (state === 'receipted') {
    return <CheckIcon color={green} />;
  }

  if (state === 'matched') {
    return <Icons.CircleFilled color={green} />;
  }

  return <Icons.CircleOutline color={indigo} />;
}
