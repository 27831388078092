import { api } from 'utils/api/api-client';
import { useDialog, useErrorDialog } from '@rexlabs/dialog';
import { InsufficientSmsCreditDialogSMSAndEmail } from 'src/modules/communications/messages/dialogs/insufficient-sms-credit-dialog-sms-and-email';
import { InsufficientSmsCreditDialogSMSOnly } from 'src/modules/communications/messages/dialogs/insufficient-sms-credit-dialog-sms-only';
import { useBulkSend } from 'src/modules/communications/messages/hooks/use-bulk-send';
import { Message } from '../types/Message';

export function useEstimateMessagesAndSend() {
  const { open: openErrorDialog } = useErrorDialog();
  const bulkSend = useBulkSend();

  const { open: openNoCreditSMSOnlyDialog } = useDialog(
    InsufficientSmsCreditDialogSMSOnly
  );
  const { open: openNoCreditEmailDialog } = useDialog(
    InsufficientSmsCreditDialogSMSAndEmail
  );

  return async (
    messages: Message[],
    handleSend: (messages: Message[]) => any = bulkSend
  ) => {
    const smsMessages = messages.filter(
      (m) => m.forced_channel_type.id === 'sms'
    );
    const emailMessages = messages.filter(
      (m) => m.forced_channel_type.id === 'email'
    );
    try {
      if (smsMessages.length < 1) {
        return handleSend(messages);
      }

      const estimateResponse = await api.post(
        '/communication/estimate-messages',
        {
          selection: { selected_ids: messages.map((m) => m.id) }
        }
      );

      if (estimateResponse.data.enough_credits === true) {
        return handleSend(messages);
      }

      if (emailMessages.length) {
        openNoCreditEmailDialog({ emailMessages });
      } else {
        openNoCreditSMSOnlyDialog();
      }
    } catch (e) {
      openErrorDialog(e);
    }
  };
}
