import { getSearchResultItemFromObjectAndModel } from 'src/modules/common/utils/search-result-items/get-search-result-items-from-object-and-model';
import { SearchResultItem } from 'utils/api/get-search-results';
import { Ownership } from 'data/models/entities/ownerships';
import { Tenancy } from 'data/models/entities/tenancies';
import { Property } from 'src/modules/properties/types/property-types';

export function getInvoicePayableBySuggestedItems({
  property
}: {
  property: Property | undefined;
}): SearchResultItem<Ownership | Tenancy>[] {
  const activeOwnershipSearchResult: SearchResultItem<Ownership> | null = getSearchResultItemFromObjectAndModel(
    property?.active_property_ownership?.ownership,
    'ownership'
  );
  const activeTenancySearchResult: SearchResultItem<Tenancy> | null = getSearchResultItemFromObjectAndModel(
    property?.active_property_tenancy?.tenancy,
    'tenancy'
  );

  const suggestedItems: SearchResultItem<Ownership | Tenancy>[] = [];

  activeOwnershipSearchResult &&
    suggestedItems.push(activeOwnershipSearchResult);
  activeTenancySearchResult && suggestedItems.push(activeTenancySearchResult);

  return suggestedItems;
}
