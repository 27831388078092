import { valueListFactory } from 'src/lib/testing/factories/value-list-factory';
import { getUploadedFileMeta } from 'utils/files';
import { mapTaskFormDataToTaskCreateRequest } from '../../common/mappers/map-task-form-data-to-task-create-request';
import { TaskCreateRequest } from '../../common/types/TaskCreateRequest';
import { MaintenanceFormData } from '../types/MaintenanceFormData';

export async function mapMaintenanceFormDataToTaskCreateRequest(
  formData: Partial<MaintenanceFormData>
): Promise<Partial<TaskCreateRequest>> {
  // map the base values for the task
  const base = mapTaskFormDataToTaskCreateRequest(formData);

  const { attachments, issue_relates_to_appliance, ...formDetails } =
    formData.details ?? {};

  const details: Record<string, any> = {
    ...formDetails
  };
  if (!issue_relates_to_appliance) {
    details.appliance = null;
  }

  if (attachments != null) {
    details.images = await getUploadedFileMeta(attachments!);
  }

  const blacklist = ['reported_date', 'appliance'];

  // Remove any null values
  Object.keys(details).forEach((key) => {
    if (details[key] === null && !blacklist.includes(key)) {
      delete details[key];
    }
  });

  return {
    ...base,
    type: valueListFactory('task_maintenance'),
    details
  };
}
