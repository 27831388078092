import React, { useCallback } from 'react';

import { RecordDialog } from 'view/components/record-screen/dialog/dialog';
import { DialogContentConfig } from 'view/components/record-screen/dialog/types';

import { RecordSubmitHandler } from 'view/components/record-screen/utils';
import { TrustJournalEntry } from 'src/modules/trust-journal-entries/types/trust-journal-entry-types';
import { useTranslation } from 'react-i18next';
import { reverseTrustJournalEntryBlock } from '../blocks/reverse-trust-journal-entry';

const content: DialogContentConfig = [
  {
    id: 'basics',
    label: 'Basics',
    blocks: [reverseTrustJournalEntryBlock]
  }
];

interface ReverseReason {
  date_of: string;
  reason: string;
}

interface ReverseTrustJournalEntryDialogProps {
  updateListId?: string;
  onClose?: () => void;
  onSubmit?: (reverseReason: ReverseReason) => Promise<void>;
  trustJournalEntry: TrustJournalEntry;
}

export function ReverseTrustJournalEntryDialog({
  updateListId,
  onClose,
  onSubmit,
  trustJournalEntry
}: ReverseTrustJournalEntryDialogProps) {
  const { t } = useTranslation();
  const handleSubmit: RecordSubmitHandler = useCallback(
    async ({ values }) => {
      await onSubmit?.(values);
      return true;
    },
    [updateListId]
  );

  const initialData = {
    date_of: trustJournalEntry.date_of
  };

  return (
    <RecordDialog
      autofocusIndex={1}
      onClose={onClose}
      data={initialData}
      title={t(
        'trust-journal-entries.reverse-trust-journal-entry.label.singular'
      )}
      handleSubmit={handleSubmit}
      content={content}
      submitLabel='Reverse'
    />
  );
}
