import React from 'react';

import { Forms } from '@rexlabs/form';
import { useStyles, StyleSheet, text, border } from '@rexlabs/styling';
import Box from '@rexlabs/box';
import { Link } from '@rexlabs/whereabouts';
import Tooltip from '@rexlabs/tooltip';

import { useStickyNavigation } from 'view/components/record-screen/sticky-navigation/context';

import { TabConfig } from '../types';

const defaultStyles = StyleSheet({
  item: {
    width: 'calc(100% + .8rem)',
    padding: '.8rem .4rem',
    margin: '0 -.4rem',
    position: 'relative',
    borderRadius: '.2rem',

    ...text.styles({
      target: 'recordNavigation.item',
      fallback: 'normal.default',
      color: ({ token }) => token('color.textStyle.body.default'),
      textDecoration: () => 'none'
    }),

    '&:hover, &:focus': {
      ...text.styles({
        target: 'recordNavigation.item.hover',
        fallback: 'normal.bold'
      }),
      color: 'unset'
    }
  },

  itemActive: {
    ...text.styles({
      target: 'recordNavigation.item.hover',
      fallback: 'normal.bold'
    })
  },

  itemDirty: {},

  itemInvalid: {},

  indicator: {
    position: 'absolute',
    transform: 'translate3d(calc(-100% - .8rem), -50%, 0)',
    top: '50%',

    ...border.styles({
      target: 'recordNavigation.item.indicator',
      all: {
        width: '.1rem',
        color: 'transparent',
        radius: () => '50%'
      }
    }),

    height: ({ token }) =>
      token('recordNavigation.item.indicator.size', '.6rem'),
    width: ({ token }) => token('recordNavigation.item.indicator.size', '.6rem')
  },

  indicatorDirty: {
    background: ({ token }) =>
      token(
        'recordNavigation.item.indicator.dirty',
        token('palette.yellow.500')
      )
  },

  indicatorInvalid: {
    background: ({ token }) =>
      token('recordNavigation.item.indicator.error', token('palette.red.500'))
  }
});

interface RecordNavigationItemProps {
  data?: any;
  current: TabConfig;
  item: TabConfig;
  forms: Forms;
}

export function RecordNavigationItem({
  data,
  current,
  item,
  forms
}: RecordNavigationItemProps) {
  const s = useStyles(defaultStyles);

  const { setActiveMenu } = useStickyNavigation();

  // Get dirty and valid state from all forms within the tab of this nav item
  const blockIds = (item.blocks?.map((block) => block.id) || []) as string[];
  const isDirty = blockIds?.some((formId) => forms?.[formId]?.isDirty);
  const isValid = blockIds?.every(
    (formId) =>
      forms?.[formId]?.isValid === undefined ||
      forms?.[formId]?.isValid === true
  );

  if (item?.visible && !item.visible({ data })) {
    return null;
  }

  return (
    <Link key={item.id} query={{ tab: item.id }}>
      {({ target, onClick }) => (
        <a
          {...s('item', {
            itemActive: current.id === item.id,
            itemDirty: isDirty,
            itemInvalid: !isValid
          })}
          href={target}
          onClick={(e) => {
            onClick(e);
            setActiveMenu(null);
          }}
          role='menuitem'
          aria-current={current.id === item.id}
          data-id={item.id}
        >
          {item.label}

          {isValid && isDirty && (
            <Tooltip Content={() => 'There are unsaved changes on this tab.'}>
              <Box {...s('indicator', 'indicatorDirty')} />
            </Tooltip>
          )}

          {!isValid && (
            <Tooltip Content={() => 'There are errors on this tab.'}>
              <Box {...s('indicator', 'indicatorInvalid')} />
            </Tooltip>
          )}
        </a>
      )}
    </Link>
  );
}
