import ROUTES from 'routes/app';

import { push } from '@rexlabs/whereabouts';
import { useConfirmationDialog } from '@rexlabs/dialog';

import { SingleActionDeclaration } from 'src/modules/common/actions/types/action-declaration-types';

import InfoCircleIcon from 'view/components/icons/info';

import { InspectionTask } from '../../types/InspectionTask';

export function useGetRemoveInspectionFromInspectionRun() {
  const { open } = useConfirmationDialog();
  return (inspectionTask: InspectionTask): SingleActionDeclaration => {
    const onConfirm = () =>
      push(ROUTES.INSPECTION_RUN_DETAILS, {
        params: {
          inspectionRunId: inspectionTask.details!.inspection_run_id
        }
      });

    return {
      id: 'remove-inspection-from-inspection-run',
      label: 'Remove inspection from run',
      handleAction: () => {
        open({
          TitleIcon: InfoCircleIcon,
          title: 'Inspection is part of an inspection run',
          message:
            'To remove this inspection from the inspection run, please go to the inspection run record',
          confirmText: 'View inspection run',
          onConfirm
        });
      }
    };
  };
}
