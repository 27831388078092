import React, { useCallback } from 'react';
import { useModelActions } from '@rexlabs/model-generator';

import { RecordSubmitHandler } from 'view/components/record-screen/utils';

import { RecordDialog } from 'view/components/record-screen/dialog/dialog';
import { complianceEntriesModel } from 'src/modules/compliance/common/models/compliance-entries';
import { useToast } from 'view/components/@luna/notifications/toast';
import { addNewCheckDetailsBlock } from 'src/modules/compliance/common/blocks/add-new-check-details';

const content = [
  {
    id: 'renew-check',
    label: 'Renew check',
    blocks: [addNewCheckDetailsBlock]
  }
];

export function AddNewCheckDialog({
  onClose,
  propertyId
}: {
  onClose?: any;

  propertyId?: string;
}) {
  const { createItem, refreshLists } = useModelActions(complianceEntriesModel);
  const { addToast } = useToast();
  const handleSubmit: RecordSubmitHandler = useCallback(async ({ values }) => {
    const uploadedFileRequest = await values.file.data;
    const uploadedFileId = uploadedFileRequest.data.id;

    const { data } = await createItem({
      data: {
        issued_at: values.issued_date,
        expires_at: values.expiry_date,
        object_type: 'property',
        object_id: propertyId,
        // BE requires this but it should be optional since it's not shown in the UI
        details: values?.requirement?.label,
        compliance_type_id: values?.requirement?.id,
        file: { id: uploadedFileId }
      }
    });

    addToast({
      description: (
        <>
          The compliance item {values?.requirement?.label} has been{' '}
          <strong>successfully added</strong> for this property
        </>
      )
    });

    await refreshLists();

    return data;
  }, []);

  return (
    <RecordDialog
      title={`Add requirement`}
      submitLabel={'Add item'}
      handleSubmit={handleSubmit}
      onClose={onClose}
      content={content}
    />
  );
}
