import { PrimaryHeading } from 'src/modules/auth/login/components/heading-primary';
import React, { useCallback } from 'react';
import { Field, Form, ReactForms } from '@rexlabs/form';
import { Trans, useTranslation } from 'react-i18next';
import { FormGrid } from 'view/components/@luna/grid/form';
import { Column } from '@rexlabs/grid';
import { EmailInput } from '@rexlabs/text-input';
import { PasswordInput } from '@rexlabs/password-input';
import { ButtonGroup, GhostButton, PrimaryButton } from '@rexlabs/button';
import Box from '@rexlabs/box';
import { Link, push } from '@rexlabs/whereabouts';
import { useModelActions } from '@rexlabs/model-generator';
import { sessionModel } from 'data/models/custom/session';
import { Body } from '@rexlabs/text';
import { getRecordLabel } from 'utils/records/get-record-label';
import { UserInvitation } from 'src/modules/auth/user-invitation/types/user-invitation';
import ROUTES from '../../login/routes';

export function InvitationLoginCard({
  token,
  invitation
}: {
  token: string;
  invitation: UserInvitation;
}) {
  const { login } = useModelActions(sessionModel);
  const { t } = useTranslation();

  const validate = {
    definitions: {
      email: {
        rules: 'required|email',
        name: t('form.general.email.label')
      },
      password: {
        rules: 'required',
        name: t('form.general.password.label')
      }
    }
  };

  const handleSubmit = useCallback(
    (values, { setServerError }) =>
      login({
        email: values.email,
        password: values.password,
        ttl: 3600 * 24 * 7
      })
        .then(() => {
          push(ROUTES.USER_INVITATION, { query: { token } });
        })
        .catch(setServerError),
    [login, token]
  );

  return (
    <>
      <Box mb='3.2rem'>
        <PrimaryHeading level='display'>
          Welcome {getRecordLabel({ type: 'user', object: invitation.user })}
        </PrimaryHeading>
        <Body grey as='span'>
          To get started, finish up setting up your account.
        </Body>
      </Box>

      <ReactForms
        validate={validate}
        handleSubmit={handleSubmit}
        initialValues={{
          email: '',
          password: ''
        }}
      >
        {({ isSubmitting }) => {
          return (
            <Form name='login'>
              <FormGrid>
                <Column width={12}>
                  <Field name='email' label='Email' Input={EmailInput} />
                </Column>
                <Column width={12}>
                  <Field
                    name='password'
                    label={
                      t('form.general.password.label', 'Password') as string
                    }
                    Input={PasswordInput}
                    HelpContent={() => (
                      <Link to={ROUTES.FORGOT_PASSWORD}>
                        <Trans i18nKey='login.form.password.forgot'>
                          Forgot your password?
                        </Trans>
                      </Link>
                    )}
                  />
                </Column>
                <Column width={12} mt='1.6rem'>
                  <Box
                    flexDirection='row'
                    alignItems='end'
                    justifyContent='flex-end'
                  >
                    <ButtonGroup>
                      <GhostButton
                        onClick={() =>
                          push(ROUTES.USER_INVITATION_CREATE, {
                            query: { token }
                          })
                        }
                      >
                        {"Don't have an account?"}
                      </GhostButton>
                      <PrimaryButton type='submit' isLoading={isSubmitting}>
                        <Trans i18nKey='login.form.submit.label'>
                          Log in to your account
                        </Trans>
                      </PrimaryButton>
                    </ButtonGroup>
                  </Box>
                </Column>
              </FormGrid>
            </Form>
          );
        }}
      </ReactForms>
    </>
  );
}
