import { useTableFilters } from 'view/hooks/use-table-filters';
import { usePropertyActions } from 'src/modules/properties/actions/use-property-actions';
import React, { useCallback, useMemo } from 'react';
import { ListTable, TabbedTable } from 'view/components/table';
import { transformActionDeclarationsToActionMenuItems } from 'utils/actions/transforms';
import ROUTES from 'routes/app';
import { useMediaQuery } from '@rexlabs/breakpoints';
import { propertyListQuery } from 'src/modules/properties/queries/property-list-query';
import { Property } from 'src/modules/properties/types/property-types';
import { Tab } from 'view/components/table/tabbed';
import { useGetBulkMessageActions } from 'src/modules/communications/messages/hooks/action-declarations/use-get-bulk-message-actions';
import { propertiesModel } from 'data/models/entities/properties';
import { ActionDeclaration } from 'src/modules/common/actions/types/action-declaration-types';
import { FLAGS } from 'utils/feature-flags';
import { useFeatureFlags } from 'view/components/@luna/feature-flags';
import { useAppWideFilterContext } from 'src/modules/app-wide-filters/contexts/app-wide-filter-context';

import { useTranslation } from 'react-i18next';
import { PropertyFilters } from '../hooks/use-property-filters';
import { leaseAgreementColumns } from '../utils/lease-agreement-columns';
import { usePropertyOverviewColumns } from '../utils/property-overview-columns';
import { propertyListLeaseAgreementQuery } from '../queries/property-list-lease-agreement-query';
import { propertyListManagementAgreementQuery } from '../queries/property-list-management-agreement-query';
import { useManagementAgreementColumns } from '../utils/management-agreement-columns';

function getRowLinkProps({ item }) {
  const property: Property = getProperty(item);

  return {
    to: ROUTES.PROPERTY,
    params: { propertyId: property.id }
  };
}

export function PropertyTable({
  activeFilter,
  shouldUseAppWideFilter = true
}: {
  activeFilter: PropertyFilters;
  shouldUseAppWideFilter?: boolean;
}) {
  const { appWideFilterKey } = useAppWideFilterContext();
  const { hasFeature } = useFeatureFlags();
  const isSmallScreen = useMediaQuery({ maxWidth: 'xl' });

  const propertyFilters = useTableFilters('properties');
  const propertyTenancyFilters = useTableFilters('property-tenancies');
  const propertyOwnershipFilters = useTableFilters('property-ownerships');

  const getPropertyActions = usePropertyActions();
  const getBulkMessageActions = useGetBulkMessageActions(propertiesModel);

  const getBulkActions = useCallback(
    (args) => {
      const actions: ActionDeclaration[] = [];
      if (hasFeature(FLAGS.BULK_MESSAGES)) {
        actions.push(...getBulkMessageActions(args.selectedItems));
      }
      return actions;
    },
    [getBulkMessageActions, hasFeature]
  );

  const onBulkDelete = useCallback(() => {
    alert('Delete');
  }, []);

  const common = useMemo(
    () => ({
      alwaysExpandSearch: true,
      getRowLinkProps,
      id: 'properties',
      Table: ListTable,
      initialHiddenColumns: [
        'record_reference',
        'created_by',
        'created_at',
        'updated_at',
        'updated_by',
        'rent_receipts',
        'task_status_change',
        'date_trigger_fee',
        'manual_fee',
        ...(isSmallScreen ? ['active_property_agreement'] : [])
      ],
      suggestedFilters: [
        'display_name',
        'address',
        'ownership_id',
        'tenancy_id'
      ],
      getActionMenuItems: ({ item }) => {
        const property: Property = getProperty(item);

        return transformActionDeclarationsToActionMenuItems(
          getPropertyActions(property),
          [
            [
              'primary-record',
              'lease-agreement',
              'management-agreement',
              'create-task'
            ]
          ]
        );
      },
      shouldUseAppWideFilter: shouldUseAppWideFilter
    }),
    [onBulkDelete, isSmallScreen]
  );

  const archivedFilter = (status = 'active') => {
    return {
      field: 'status_id',
      op: 'eq',
      value: { value: status }
    };
  };

  const managementAgreementColumns = useManagementAgreementColumns();
  const propertyOverviewColumns = usePropertyOverviewColumns();
  const { t } = useTranslation();

  const tabs: Tab[] = useMemo(() => {
    switch (activeFilter) {
      case PropertyFilters.PROPERTY_OVERVIEW: {
        const shared = {
          ...common,
          ...propertyFilters,
          getBulkActions,
          onBulkDelete,
          columns: propertyOverviewColumns,
          getQuery: propertyListQuery
        };

        return [
          {
            ...shared,
            name: 'under_management',
            label: t(
              'property-ownerships.management-agreement.under-management.label'
            ),
            forcedGlobalFilter: [
              {
                field: 'management_status_id',
                op: 'eq',
                value: { value: 'under_management', label: 'Under management' }
              },
              archivedFilter()
            ]
          },
          {
            ...shared,
            name: 'tenanted',
            label: 'Tenanted',
            forcedGlobalFilter: [
              {
                field: 'management_status_id',
                op: 'eq',
                value: { value: 'under_management', label: 'Under management' }
              },
              {
                field: 'vacancy_status_id',
                op: 'eq',
                value: { value: 'occupied', label: 'Occupied' }
              },
              archivedFilter()
            ]
          },
          {
            ...shared,
            name: 'upcoming_vacancies',
            label: 'Upcoming vacancies',
            forcedGlobalFilter: [
              {
                field: 'property_tenancy_status_id',
                op: 'eq',
                value: { value: 'outgoing', label: 'Outgoing' }
              },
              archivedFilter()
            ]
          },
          {
            ...shared,
            name: 'vacant',
            label: 'Vacant',
            forcedGlobalFilter: [
              {
                field: 'vacancy_status_id',
                op: 'eq',
                value: { value: 'vacant', label: 'Vacant' }
              },
              archivedFilter()
            ]
          },
          {
            ...shared,
            name: 'all',
            label: 'All',
            forcedGlobalFilter: [archivedFilter()]
          },
          {
            ...shared,
            name: 'archived',
            label: 'Archived',
            forcedGlobalFilter: [archivedFilter('archived')]
          }
        ];
      }

      case PropertyFilters.LEASE_AGREEMENTS: {
        const shared = {
          ...common,
          ...propertyTenancyFilters,
          getQuery: propertyListLeaseAgreementQuery,
          columns: leaseAgreementColumns
        };

        return [
          {
            ...shared,
            name: 'all',
            label: 'All',
            forcedGlobalFilter: []
          },
          {
            ...shared,
            name: 'active',
            label: 'Active',
            forcedGlobalFilter: [
              {
                field: 'status_id',
                op: 'eq',
                value: { value: 'active', label: 'Active' }
              }
            ]
          },
          {
            ...shared,
            name: 'incoming',
            label: 'Incoming',
            forcedGlobalFilter: [
              {
                field: 'status_id',
                op: 'eq',
                value: { value: 'incoming', label: 'Incoming' }
              }
            ]
          },
          {
            ...shared,
            name: 'outgoing',
            label: 'Outgoing',
            forcedGlobalFilter: [
              {
                field: 'status_id',
                op: 'eq',
                value: { value: 'outgoing', label: 'outgoing' }
              }
            ]
          },
          {
            ...shared,
            name: 'draft',
            label: 'Draft',
            forcedGlobalFilter: [
              {
                field: 'status_id',
                op: 'eq',
                value: { value: 'draft', label: 'Draft' }
              }
            ]
          }
        ];
      }

      case PropertyFilters.MANAGEMENT_AGREEMENTS: {
        const shared = {
          ...common,
          ...propertyOwnershipFilters,
          getQuery: propertyListManagementAgreementQuery,
          columns: managementAgreementColumns
        };

        return [
          {
            ...shared,
            name: 'all',
            label: 'All',
            forcedGlobalFilter: []
          },
          {
            ...shared,
            name: 'active',
            label: 'Active',
            forcedGlobalFilter: [
              {
                field: 'status_id',
                op: 'eq',
                value: { value: 'active', label: 'Active' }
              }
            ]
          },
          {
            ...shared,
            name: 'incoming',
            label: 'Incoming',
            forcedGlobalFilter: [
              {
                field: 'status_id',
                op: 'eq',
                value: { value: 'incoming', label: 'Incoming' }
              }
            ]
          },
          {
            ...shared,
            name: 'outgoing',
            label: 'Outgoing',
            forcedGlobalFilter: [
              {
                field: 'status_id',
                op: 'eq',
                value: { value: 'outgoing', label: 'outgoing' }
              }
            ]
          },
          {
            ...shared,
            name: 'draft',
            label: 'Draft',
            forcedGlobalFilter: [
              {
                field: 'status_id',
                op: 'eq',
                value: { value: 'draft', label: 'Draft' }
              }
            ]
          }
        ];
      }
    }
  }, [common, activeFilter]);

  return (
    <TabbedTable
      tabs={tabs}
      key={shouldUseAppWideFilter ? appWideFilterKey : undefined}
    />
  );
}

// Helpers

function getProperty(item) {
  if (
    item.__record_type === 'property_tenancy' ||
    item.__record_type === 'property_ownership'
  ) {
    return item.property;
  }

  return item;
}
