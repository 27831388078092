import * as React from 'react';
import { DialogProps, useErrorDialog } from '@rexlabs/dialog';
import { Dialog } from 'view/components/@luna/dialog';
import { ErrorBanner } from 'view/components/@luna/notifications/banner';
import Box from '@rexlabs/box';
import { CreateMessageFormData } from '../mappers/types/create-message-form-data';
import { useResolvedMessageTexts } from '../hooks/use-resolved-message-texts';
import { CommunicationContext } from '../types/communication-context';
import { getContextTypeFromRecord } from '../utils/get-context-type-from-record';
import {
  LetterPreview,
  LetterPreviewProps
} from '../../common/components/letter-preview';

export interface PreviewLetterFormDataDialogProps extends DialogProps {
  data: {
    formData: CreateMessageFormData;
  };
}

/**
 * This dialog displays the preview of the message that will be sent, based on the as-yet-unsent form data.
 */
export function PreviewLetterFormDataDialog({
  onClose,
  data
}: PreviewLetterFormDataDialogProps) {
  const errorDialog = useErrorDialog();
  const recipients = Array.isArray(data.formData.recipients)
    ? data.formData.recipients
    : [data.formData.recipients];
  const {
    resolvedTexts: [address, content],
    invalidTags,
    isLoading,
    isError,
    error
  } = useResolvedMessageTexts(
    [data.formData.letterAddress ?? '', data.formData.content ?? ''],
    {
      type: { id: data.formData.relates_to.record.__record_type },
      record: { id: data.formData.relates_to.record.id }
    } as CommunicationContext,
    getContextTypeFromRecord(data?.formData?.relates_to?.record),
    recipients?.map((item) => ({ id: item.id })),
    data.formData.sent_from
  );

  React.useEffect(() => {
    if (!isError) return;

    errorDialog.open(error);
  }, [isError, error]);

  return (
    <Dialog title='Preview letter' onClose={onClose} isLoading={isLoading}>
      <Box flexDirection='column' gap={12}>
        {invalidTags.length > 0 && (
          <ErrorBanner
            description={`This letter contains invalid merge tags: ${invalidTags.join(
              ', '
            )}`}
          />
        )}
        <LetterPreview
          {...mapCreateMessageFormToLetterPreviewProps({
            ...data.formData,
            letterAddress: address,
            content
          })}
        />
      </Box>
    </Dialog>
  );
}

function mapCreateMessageFormToLetterPreviewProps(
  input: CreateMessageFormData
): LetterPreviewProps {
  const attachedFiles =
    input?.attachments
      ?.filter((attachment) => attachment.file)
      ?.map((attachment) => attachment.file) ?? [];

  return {
    address: input.letterAddress ?? '',
    bodyContent: input.content ?? '',
    attachedFiles: attachedFiles.length ? attachedFiles : undefined
  };
}
