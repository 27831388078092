import { Generator } from 'data/models/generator';
import { AuditLog } from '../types/AuditLog';

const config = {
  entities: {
    related: {
      changeset: {
        include: 'changeset'
      }
    }
  }
};

export const auditLogsModel = new Generator<AuditLog>(
  'audit-logs',
  config
).createEntityModel();
