import { useDialog } from '@rexlabs/dialog';
import { ActionDeclaration } from 'src/modules/common/actions/types/action-declaration-types';
import { PreviewMessageRecordDialog } from '../../dialogs/preview-message-record-dialog';
import { PreviewSMSRecordDialog } from '../../dialogs/preview-sms-record-dialog';
import { Message } from '../../types/Message';
import { PreviewLetterRecordDialog } from '../../dialogs/preview-letter-record-dialog';

export function useGetPreviewMessageAction(hasButtonGroupActions?: boolean) {
  const previewMessageDialog = useDialog(PreviewMessageRecordDialog);
  const previewSMSDialog = useDialog(PreviewSMSRecordDialog);
  const previewLetterDialog = useDialog(PreviewLetterRecordDialog);

  return (message: Message, onSave?: () => void): ActionDeclaration => {
    const channelType = message.channels[0].channel_type.id;

    return {
      intent: 'primary',
      id: 'preview-message',
      group: 'messages',
      label: 'Preview message',
      handleAction: async () => {
        switch (channelType) {
          case 'letter':
            previewLetterDialog.open({
              hasButtonGroupActions,
              data: { message },
              onSave
            });
            break;
          case 'sms':
            previewSMSDialog.open({
              hasButtonGroupActions,
              data: { message },
              onSave
            });
            break;
          case 'email':
          default:
            previewMessageDialog.open({
              hasButtonGroupActions,
              data: { message },
              onSave
            });
            break;
        }
      }
    };
  };
}
