import React, { useEffect, useState } from 'react';

import { SelectProps } from '@rexlabs/select-old';

import { ValueListValue } from 'data/models/types';
import { Select } from '@rexlabs/select';
import { Grid } from 'view/components/@luna/form/grid';
import { valueListFactory } from 'src/lib/testing/factories/value-list-factory';
import { FrequencySplitInput } from '../../frequency-input/frequency-split-input';

export type UnitId = 'days' | 'weeks' | 'months' | 'years';

const rentScheduleEntryFrequencySelectIds = [
  'daily',
  'weekly',
  'fortnightly',
  'monthly',
  'quarterly',
  '6_months',
  '9_months',
  'yearly'
] as const;

type RentScheduleEntryFrequencySelectId = typeof rentScheduleEntryFrequencySelectIds[number];

export type RentScheduleEntryFrequencySelect = {
  count: number | null;
  unit: ValueListValue<UnitId> | null;
};

const items = [...rentScheduleEntryFrequencySelectIds, 'custom'].map(
  valueListFactory
);

const rentScheduleEntryFrequencyMap: Record<
  RentScheduleEntryFrequencySelectId,
  RentScheduleEntryFrequencySelect
> = {
  daily: {
    count: 1,
    unit: {
      id: 'days',
      label: 'Days'
    }
  },
  weekly: {
    count: 1,
    unit: {
      id: 'weeks',
      label: 'Weeks'
    }
  },

  fortnightly: {
    count: 2,
    unit: {
      id: 'weeks',
      label: 'Weeks'
    }
  },

  monthly: {
    count: 1,
    unit: {
      id: 'months',
      label: 'Months'
    }
  },

  quarterly: {
    count: 3,
    unit: {
      id: 'months',
      label: 'Months'
    }
  },

  '6_months': {
    count: 6,
    unit: {
      id: 'months',
      label: 'Months'
    }
  },
  '9_months': {
    count: 9,
    unit: {
      id: 'months',
      label: 'Months'
    }
  },
  yearly: {
    count: 1,
    unit: {
      id: 'years',
      label: 'Years'
    }
  }
};

function getFrequencySelectOption(frequency: RentScheduleEntryFrequencySelect) {
  const { count } = frequency;
  const selectIds = Object.keys(rentScheduleEntryFrequencyMap);
  const options = Object.values(rentScheduleEntryFrequencyMap);

  const optionIndex = options.findIndex(
    (option) =>
      option.count === count && option?.unit?.id === frequency?.unit?.id
  );

  return selectIds[optionIndex]
    ? valueListFactory(selectIds[optionIndex])
    : {
        id: 'custom',
        label: 'Custom'
      };
}

interface RentScheduleEntryFrequencySelectProps
  extends Omit<SelectProps, 'onChange' | 'onBlur' | 'items'> {
  value?: RentScheduleEntryFrequencySelect;
  onChange: (e: {
    target: { value: RentScheduleEntryFrequencySelect };
  }) => void;
  onBlur?: (e: { target: { value: RentScheduleEntryFrequencySelect } }) => void;
}

/**
 * This custom select can be used to select a frequency for a rent schedule entry. It also has the option to select a custom frequency,
 * which will display a FrequencySplitInput component.
 *
 * DESIGN NOTES: Initially this component did not have a custom option, so it was a plain custom select - the options you chose would update the frequency unit
 * and count. However, due to increased demand for different frequencies, we added a custom option which would allow the user to input their own.
 * This adds a layer of complexity to the component as we now have to handle the custom input as well as the select input.
 * The design I have chosen, helps to keep the complexity within this component, so we don't need to worry about mapping the select values to
 * frequency unit and count outside of this component, before sending to BE.
 *
 * List of downsides to this approach:
 *  - The parent Field component should ideally be placed in it's own row within a form, as selecting custom will display a secondary field - breaking the grid layout.
 *  - We can't add a label to the secondary field, as it is within the parent Field component, so it will not align with the parents label.
 *
 * EXTRA NOTES: When building custom selects, that we want to change the value to something else, you must use the onChange and onBlur props to update the value.
 * This caught me out when I first started building this component, and could have saved me a lot of time if I had known this.
 */
export function RentScheduleEntryFrequencySelect({
  name,
  value,
  onChange,
  onBlur
}: RentScheduleEntryFrequencySelectProps) {
  const [selectedOption, setSelectedOption] = useState<
    { id: string; label: string } | undefined
  >();
  const fieldId = name!;

  const handleChange = (newValue) => {
    onChange?.({
      target: {
        value: {
          ...value,
          ...newValue
        }
      }
    });
  };

  const handleBlur = (newValue) => {
    onBlur?.({
      target: {
        value: {
          ...value,
          ...newValue
        }
      }
    });
  };

  const handleSelectChange = ({ target }: { target: any }) => {
    setSelectedOption(target.value);
    handleChange(rentScheduleEntryFrequencyMap[target?.value?.id]);
  };

  const handleSelectBlur = ({ target }: { target: any }) => {
    setSelectedOption(target.value);
    handleBlur(rentScheduleEntryFrequencyMap[target?.value?.id]);
  };

  // On initial render when editing, we want to attempt to set the selected option based on the frequency value
  useEffect(() => {
    value && setSelectedOption(getFrequencySelectOption(value));
  }, []);

  return (
    <Grid columns={2}>
      <Select
        id='frequency-select'
        items={items}
        normaliser={(item) => item}
        onChange={handleSelectChange}
        onBlur={handleSelectBlur}
        value={selectedOption}
      />
      {selectedOption?.id == 'custom' ? (
        <FrequencySplitInput
          id={fieldId}
          minCount={1}
          defaultUnit={valueListFactory('weeks')}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
        />
      ) : null}
    </Grid>
  );
}
