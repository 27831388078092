import { useQuery } from 'react-query';
import { Ownership } from 'data/models/entities/ownerships';
import { api } from 'utils/api/api-client';
import { Contact } from 'src/modules/contacts/types/contact-types';

const getEarliestStartDate = async (record: Ownership | Contact) => {
  const res = await api.get<{ earliest_start_date: string }>(
    `/statements/income-statements/${record.id}`
  );

  return res.data.earliest_start_date;
};

export const useEarliestIncomeStatementStartDate = (
  record: Ownership | Contact
) => {
  return useQuery<string>(
    [
      'earliest-statement-date',
      { id: record.id, updated_at: record.updated_at }
    ],
    () => getEarliestStartDate(record)
  );
};
