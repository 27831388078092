import { useDialog } from '@rexlabs/dialog';
import { ActionDeclaration } from 'src/modules/common/actions/types/action-declaration-types';
import { MessageWithReasonDialog } from '../../../common/dialogs/message-with-reason-dialog';
import { QuoteTask } from '../../types/QuoteTask';
import { useGetMessageWithReasonSubmitHandler } from '../../../common/hooks/use-get-message-with-reason-submit-handler';

export function useGetCancelQuoteRequestAction() {
  const { open: openMessageWithReasonDialog } = useDialog(
    MessageWithReasonDialog
  );

  const getUpdateQuoteTaskSubmitHandler = useGetMessageWithReasonSubmitHandler();

  return (task: QuoteTask): ActionDeclaration => {
    const handleSubmit = getUpdateQuoteTaskSubmitHandler(task, 'cancelled');

    return {
      id: 'cancel-quote-request',
      group: 'status',
      label: 'Cancel quote request',
      handleAction: async () => {
        openMessageWithReasonDialog({
          title: 'Cancel quote request',
          submitButtonType: 'destructive',
          handleSubmit,
          messageContextType: 'task_quote'
        });
      }
    };
  };
}
