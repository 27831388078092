import React from 'react';
import { useConfirmationDialog } from '@rexlabs/dialog';
import { useModelActions } from '@rexlabs/model-generator';

import {
  SecurityDepositType,
  financialsSecurityDepositTypesModel
} from 'data/models/entities/financials/security-deposit-types';
import { useToast } from 'view/components/@luna/notifications/toast';
import { ActionDeclaration } from 'src/modules/common/actions/types/action-declaration-types';

export function useGetUnarchiveAction() {
  const { unarchive, refreshList } = useModelActions(
    financialsSecurityDepositTypesModel
  );
  const { open: openConfirmationDialog } = useConfirmationDialog();
  const { addToast } = useToast();

  return (
    securityDepositType: SecurityDepositType,
    updateListId?: string
  ): ActionDeclaration => {
    return {
      id: 'activate',
      group: 'activate',
      label: 'Activate',
      handleAction: () =>
        openConfirmationDialog({
          title: 'Activate security deposit type',
          message: `Are you sure you wish to activate ${securityDepositType.name}?`,
          onConfirm: async () => {
            await unarchive({ depositTypeId: securityDepositType.id });

            if (updateListId) {
              await refreshList({ id: updateListId });
            }

            addToast({
              description: (
                <>
                  <b>{securityDepositType.name}</b> has been activated
                </>
              )
            });
          }
        })
    };
  };
}
