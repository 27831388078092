import React from 'react';
import { TaskType } from 'src/modules/tasks/common/types/TaskType';

import {
  FieldArray,
  FieldArrayPassthroughProps,
  Form,
  HiddenField,
  ReactForms
} from '@rexlabs/form';
import { invokeActionDeclaration } from 'src/modules/common/actions/utils/invoke-action-declaration';
import {
  ChecklistTemplatesForm,
  ChecklistTemplatesItemForm
} from '../../types/ChecklistTemplatesForm';
import { useCreateChecklistItemAction } from '../../hooks/action-declarations/checklist-item/use-create-checklist-item-action';
import { ChecklistTemplateItemGroup } from '../../types/ChecklistTemplate';
import { FieldArrayContextProvider } from './field-array-context';
import { AddSubtaskButton } from './add-subtask-button';
import { ChecklistTemplateItemRows } from './checklist-template-item-rows';
import { SmartChecklistContextProvider } from './smart-checklist-context';

type ChecklistTemplateItemListProps = {
  isEditing: boolean;
  items: ChecklistTemplatesItemForm[];
  groups: ChecklistTemplateItemGroup[];
  taskType: TaskType;
  handleSubmit: (values: ChecklistTemplatesForm) => Promise<boolean>;
};

export const ChecklistTemplateItemList: React.FC<ChecklistTemplateItemListProps> = ({
  isEditing,
  items,
  groups,
  taskType,
  handleSubmit
}) => {
  const initialValues = {
    checklist_template_items: items,
    checklist_template_item_groups: groups
  };

  return (
    <ReactForms<ChecklistTemplatesForm, any>
      handleSubmit={handleSubmit}
      initialValues={initialValues}
    >
      {({ values, setFieldValue }) => {
        return (
          <Form name='checklist'>
            <FieldArray
              name='checklist_template_items'
              render={function FieldArrayInner(fieldArrayProps) {
                return (
                  <FieldArrayContextProvider
                    value={{
                      fieldArrayProps,
                      setFieldValue,
                      values,
                      taskType
                    }}
                  >
                    <ItemListInner
                      taskType={taskType}
                      isEditing={isEditing}
                      props={fieldArrayProps}
                    />
                  </FieldArrayContextProvider>
                );
              }}
            />
            <HiddenField name='delete_ids' />
            <HiddenField name='checklist_template_item_groups' />
          </Form>
        );
      }}
    </ReactForms>
  );
};

// This is split to its own component so we can use the field array context in these hooks
function ItemListInner({ taskType, isEditing, props }) {
  const createChecklistItemAction = useCreateChecklistItemAction(taskType);

  const handleAddSubtaskClick = (fieldArrayProps: FieldArrayPassthroughProps) =>
    invokeActionDeclaration(createChecklistItemAction, {
      fieldArrayProps
    });
  return (
    <SmartChecklistContextProvider value={{ isEditing }}>
      {/* Pulls items from context */}
      <ChecklistTemplateItemRows isEditing={isEditing} />
      {isEditing && (
        <AddSubtaskButton onClick={() => handleAddSubtaskClick(props)} />
      )}
    </SmartChecklistContextProvider>
  );
}
