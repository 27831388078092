import { FrequencyType } from '../types/frequency';

function getUnitLabel(unit?: FrequencyType) {
  if (unit === 'days') {
    return 'day(s)';
  }

  if (unit === 'weeks') {
    return `week(s)`;
  }

  if (unit === 'months') {
    return `month(s)`;
  }

  return 'year(s)';
}

export function getFrequencyLabel(count?: number, unit?: FrequencyType) {
  return `${count} ${getUnitLabel(unit)}`;
}
