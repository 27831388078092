import React from 'react';
import { DestructiveSplitButton, PrimarySplitButton } from '@rexlabs/button';
import ChevronDownIcon from 'view/components/icons/chevron-down';
import { ButtonGroupProps } from 'view/components/record-screen/dialog/types';
import ActionMenu from '@rexlabs/action-menu';
import { push } from '@rexlabs/whereabouts';
import ROUTES from 'routes/app';

export interface GoToOutboxSplitButtonProps extends ButtonGroupProps {
  buttonLabel?: React.ReactNode;
  submitButtonType?: 'primary' | 'destructive';
}

export function GoToOutboxSplitButton({
  onClose,
  handleSubmit,
  isSubmitting,
  buttonLabel,
  submitButtonType
}: GoToOutboxSplitButtonProps) {
  async function handleSubmitAndClose() {
    const data = await handleSubmit();

    if (!data) return;

    onClose?.();
  }

  async function handleSubmitAndGoToOutbox() {
    const data = await handleSubmit();

    if (!data) return;

    onClose?.();

    // navigate to messages outbox tab
    push(ROUTES.MESSAGE_LIST);
  }

  const SplitButton =
    submitButtonType === 'destructive'
      ? DestructiveSplitButton
      : PrimarySplitButton;

  return (
    <SplitButton
      Left={({ Button }) => (
        <Button
          destructive={true}
          type='submit'
          isLoading={isSubmitting}
          onClick={handleSubmitAndGoToOutbox}
        >
          {`${buttonLabel} and go to outbox`}
        </Button>
      )}
      Right={({ IconButton }) => (
        <ActionMenu
          placement='bottom-end'
          items={[
            {
              label: buttonLabel,
              onClick: handleSubmitAndClose
            }
          ]}
          Button={React.forwardRef((props, ref) => (
            <IconButton {...props} ref={ref} Icon={ChevronDownIcon} />
          ))}
        />
      )}
    />
  );
}
