import React from 'react';

import { RecordScreen } from 'view/components/record-screen';
import { TitleBlock } from 'view/components/@luna/title-block';
import { content } from '../data/property-compliance-settings-content';

export function PropertyComplianceSettings() {
  return (
    <RecordScreen
      titleBlock={<TitleBlock title={'Property compliance'} />}
      content={content}
      data={{}}
    />
  );
}
