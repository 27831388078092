import { Forms, FormActions } from '@rexlabs/form';

import { SearchResultItem } from 'utils/api/get-search-results';
import { parseUploadedFileMeta } from 'utils/files';

import { emptyMessageCreatorItem } from '../../common/data/empty-message-creator-item';
import { MessageTemplate } from '../../message-templates/types/MessageTemplate';
import { ChannelTypeId } from '../../common/types/channel';
import { getRecipients } from './get-recipients';
import { getDocumentsForRelatesTo } from './get-documents-for-relates-to';
import { getComplianceEntryFilesForRelatesTo } from './get-compliance-entry-files-for-relates-to';

export function getChangeHandlers(
  setRelatesTo,
  forms?: Forms,
  channelTypeId: ChannelTypeId = 'email'
) {
  return {
    // When the template changes, we want to update the subject and content in a different block
    handleTemplateChange: async (event) => {
      if (!forms) {
        return;
      }
      // props from other forms
      const {
        setFieldValue: setDetailsFormFieldValue,
        values: detailValues
      } = forms['details'];
      const { setFieldValue: setContentFormFieldValue } = forms['content'];

      // get selected template
      const messageTemplate: MessageTemplate = event.target.value;

      // get the channel to use
      const channelToUse = messageTemplate.channels?.find(
        (channel) => channel.channel_type.id === channelTypeId
      );

      // get the attachments
      const inlineAttachments = parseUploadedFileMeta(
        messageTemplate.inline_attachments || []
      );
      const documentAttachments = parseUploadedFileMeta(
        await getDocumentsForRelatesTo(
          detailValues.relates_to,
          (messageTemplate?.document_types || []).map((type) => type.id)
        )
      );
      const complianceAttachments = parseUploadedFileMeta(
        await getComplianceEntryFilesForRelatesTo(
          detailValues.relates_to,
          (messageTemplate?.compliance_types || []).map((type) => type.id)
        )
      );

      // get default message recipient group
      const defaultRecipientGroup = await getRecipients(
        messageTemplate,
        detailValues.relates_to
      );

      // set the fields
      setDetailsFormFieldValue(
        'sent_from',
        messageTemplate.send_from_author
          ? messageTemplate.send_from_author
          : emptyMessageCreatorItem
      );
      setContentFormFieldValue('subject', channelToUse?.email_subject);
      setContentFormFieldValue('letterAddress', channelToUse?.letter_address);
      setContentFormFieldValue('content', channelToUse?.plain_text_body);
      setContentFormFieldValue('attachments', [
        ...inlineAttachments,
        ...documentAttachments,
        ...complianceAttachments
      ]);
      setDetailsFormFieldValue('recipients', defaultRecipientGroup);
      setIncludesGeneratedAttachments(
        setContentFormFieldValue,
        messageTemplate?.templated_attachments
      );
    },
    // When the user changes the "relates_to" field, we need to reset everything else, as it is the basis of all the other fields
    handleRelatesToChange: (event) => {
      if (!forms) {
        return;
      }
      const newRelatesTo: SearchResultItem = event.target.value;
      const { resetField: resetDetailsFormField } = forms['details'];
      const { resetForm: resetContentForm } = forms['content'];
      // we can reset the whole content form at once
      resetContentForm({
        // Note: resetForm removes all the values, but as we're using a component that manages its own state for the text content,
        // we need to pass in a blank string to reset that component.
        content: '',
        // Note: We always want to reset arrays back to an empty array, as resetting to undefined breaks the field, and you can
        // no longer add items to the array.
        attachments: []
      });
      // we need to do the details form field-by-field, so that we don't clobber the new relates_to value
      const fields = [
        { field: 'template', value: null },
        { field: 'sent_from', value: emptyMessageCreatorItem },
        { field: 'content', value: null },
        { field: 'subject', value: null },
        { field: 'recipients', value: [] }
      ];
      fields.forEach(({ field, value }) => {
        resetDetailsFormField(field, value);
      });
      setRelatesTo(newRelatesTo);
    }
  };
}

function setIncludesGeneratedAttachments(
  setFieldValue: FormActions['setFieldValue'],
  templatedAttachments?: string[]
) {
  if (templatedAttachments?.length) {
    templatedAttachments.map((templatedAttachment) => {
      setFieldValue(
        `include_generated_attachments.${templatedAttachment}`,
        true
      );
    });
  } else {
    setFieldValue(`include_generated_attachments`, null);
  }
}
