import React, { useCallback } from 'react';

import { RecordDialog } from 'view/components/record-screen/dialog/dialog';
import { DialogContentConfig } from 'view/components/record-screen/dialog/types';

import { RecordSubmitHandler } from 'view/components/record-screen/utils';
import { useModelActions } from '@rexlabs/model-generator';
import { chartOfAccountsAccountModel } from 'src/modules/chart-of-accounts/models/chart-of-accounts';
import { ChartOfAccountsAccount } from 'src/modules/chart-of-accounts/types/chart-of-account-types';
import { editChartOfAccountsAccountsBlock } from '../blocks/edit-chart-of-accounts-account';

const content: DialogContentConfig = [
  { id: 'basics', label: 'Basics', blocks: [editChartOfAccountsAccountsBlock] }
];

interface EditChartOfAccountsAccountProps {
  initialData: Partial<ChartOfAccountsAccount>;
  onClose?: () => void;
  onCreate?: (chartOfAccountsAccount: ChartOfAccountsAccount) => void;
}

export function EditChartOfAccountsAccountDialog({
  initialData,
  onClose,
  onCreate
}: EditChartOfAccountsAccountProps) {
  const actions = useModelActions(chartOfAccountsAccountModel);
  const handleSubmit: RecordSubmitHandler = useCallback(
    async ({ changedValues }) => {
      const response = await actions.updateItem({
        id: initialData.id,
        data: changedValues
      });
      await onCreate?.(response.data);
      return response.data;
    },
    [actions, onCreate, initialData]
  );

  return (
    <RecordDialog
      onClose={onClose}
      title='Update account'
      data={initialData}
      handleSubmit={handleSubmit}
      content={content}
    />
  );
}
