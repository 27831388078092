function getFieldId(fieldName: string, fieldPrefix?: string) {
  return fieldPrefix ? `${fieldPrefix}.${fieldName}` : fieldName;
}
export function getWithdrawalDetailsFormValidationRules(
  fieldPrefix: string,
  // This is used to trigger the rest of the rules as they're all based off the method field.
  // By default we require the method field to be filled out, but in some cases, the method field
  // is conditionally required, so we need to pass in the rules for the method field.
  methodRules = 'required'
) {
  return {
    [getFieldId('method', fieldPrefix)]: { rules: methodRules },
    [getFieldId('drawer', fieldPrefix)]: {
      rules: `required_if:${getFieldId('method', fieldPrefix)},cheque`
    },
    [getFieldId('reference', fieldPrefix)]: {
      rules:
        `required_if:${getFieldId('method', fieldPrefix)}.id,cheque|` +
        `required_if:${getFieldId('method', fieldPrefix)}.id,eft|` +
        `required_if:${getFieldId('method', fieldPrefix)}.id,eft_swift|` +
        `required_if:${getFieldId('method', fieldPrefix)}.id,eft_iban|` +
        `required_if:${getFieldId('method', fieldPrefix)}.id,direct_debit|` +
        `required_if:${getFieldId('method', fieldPrefix)}.id,bpay`
    },

    [getFieldId('issuer', fieldPrefix)]: {
      rules: `required_if:${getFieldId('method', fieldPrefix)}.id,cheque`
    },
    [getFieldId('branch_code', fieldPrefix)]: {
      rules:
        `required_if:${getFieldId('method', fieldPrefix)}.id,cheque|` +
        `required_if:${getFieldId('method', fieldPrefix)}.id,eft|` +
        `required_if:${getFieldId('method', fieldPrefix)}.id,eft_swift`
    },
    [getFieldId('account_number', fieldPrefix)]: {
      rules:
        `required_if:${getFieldId('method', fieldPrefix)}.id,cheque|` +
        `required_if:${getFieldId('method', fieldPrefix)}.id,eft|` +
        `required_if:${getFieldId('method', fieldPrefix)}.id,eft_swift|` +
        `required_if:${getFieldId('method', fieldPrefix)}.id,eft_iban|` +
        `required_if:${getFieldId('method', fieldPrefix)}.id,bpay`
    }
  };
}
