export const getStatusChangeTransformPayload = (task) => {
  return (status) => {
    return {
      type: task.type,
      details: {
        status: {
          id: status
        }
      }
    };
  };
};
