import React from 'react';

import { formatCurrency } from 'utils/formatters';

import { Value, ValuedValueProps } from './';

interface CurrencyValueProps {
  value?: number | null;
}

export function CurrencyValue({
  value,
  ...props
}: CurrencyValueProps & ValuedValueProps) {
  return (
    <Value
      {...props}
      value={value || value === 0 ? formatCurrency(value) : null}
    />
  );
}
