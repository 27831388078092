import React from 'react';
import LoadingState from 'view/components/states/compact/loading';
import { InvitationLoggedOutCard } from 'src/modules/auth/user-invitation/components/invitation-logged-out-card';
import { InvitationLoggedInCard } from 'src/modules/auth/user-invitation/components/invitation-logged-in-card';
import { useIsLoggedIn } from 'src/modules/auth/login/hooks/use-is-logged-in';
import { useRecoilValue } from 'recoil';
import { userInviteQuery } from 'src/modules/auth/user-invitation/atoms/userInviteQuery';

export function UserInvitationScreen({ match }) {
  const isLoggedIn = useIsLoggedIn();

  const token = match?.query?.token;
  const invitation = useRecoilValue(userInviteQuery(token));

  if (!invitation) {
    return <LoadingState />;
  }

  if (isLoggedIn) {
    return <InvitationLoggedInCard invitation={invitation} token={token} />;
  }
  return <InvitationLoggedOutCard invitation={invitation} token={token} />;
}
