import React from 'react';
import { BlockConfig } from 'view/components/record-screen/types';
import { Grid, Column } from 'view/components/@luna/form/grid';
import { Field } from '@rexlabs/form';
import { Checkbox } from '@rexlabs/checkbox';
import {
  DateInput,
  disableFutureDates
} from 'view/components/@luna/inputs/date-input/date-input';
import { BankAccountSelect } from 'src/modules/bank-accounts/components/bank-account-select';

export const ledgerActivityBlock: BlockConfig = {
  id: 'ledgerActivityBlock',
  title: 'Detail',
  validate: {
    definitions: {
      bank_account: { rules: 'required' },
      start_date: { rules: 'required' },
      end_date: { rules: 'required' },
      exclude_transactions_dated_after: {
        rules: 'required_with:exclude_ledgers'
      }
    }
  },

  Edit: ({ values }) => {
    return (
      <Grid columns={2}>
        <Column width={2}>
          <Field
            name='bank_account'
            label='Bank account'
            Input={BankAccountSelect}
            inputProps={{
              disabled: !!values.ledger_ids // If we have specified ledgerIds, we should not allow the bank account to be changed, as the ledgerIds will be invalid
            }}
          />
        </Column>

        <Field
          label='Creation start date'
          name='start_date'
          Input={DateInput}
          inputProps={{
            isOutsideRange: disableFutureDates
          }}
        />

        <Field
          label='Creation end date'
          name='end_date'
          Input={DateInput}
          inputProps={{
            isOutsideRange: disableFutureDates
          }}
        />

        <Column width={2}>
          <Field
            name='exclude_ledgers_without_transactions'
            inputProps={{
              label: 'Exclude ledgers with no activity in period'
            }}
            Input={Checkbox}
            optional={false}
          />

          <Field
            name='exclude_ledgers'
            inputProps={{
              label: 'Exclude transactions dated after date'
            }}
            Input={Checkbox}
            optional={false}
          />
        </Column>

        {values.exclude_ledgers && (
          <Field
            label='Select date'
            name='exclude_transactions_dated_after'
            Input={DateInput}
            optional={false}
            inputProps={{
              isOutsideRange: disableFutureDates
            }}
          />
        )}
      </Grid>
    );
  }
};
