import { RegularQuri } from '@rexlabs/quri';

export function mapQuriFiltersToTableFilter(data: RegularQuri[]) {
  return data.reduce((previous, current) => {
    return {
      ...previous,
      [current.field]: [
        ...(previous[current.field] ? previous[current.field] : []),
        { op: current.op, value: current.value }
      ]
    };
  }, {});
}
