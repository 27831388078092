import * as React from 'react';
import { DialogProps } from '@rexlabs/dialog';
import { Dialog } from 'view/components/@luna/dialog';
import { EmailPreview } from '../../common/components/email/email-preview';
import { mapMessageToEmailPreviewProps } from '../mappers/map-message-to-email-preview-props';
import { Message } from '../types/Message';

export interface ViewMessageRecordDialogProps extends DialogProps {
  data: {
    message?: Message;
  };
}

export function ViewMessageRecordDialog({
  onClose,
  data
}: ViewMessageRecordDialogProps) {
  return (
    <Dialog title='View sent message' onClose={onClose}>
      <EmailPreview {...mapMessageToEmailPreviewProps(data.message)} />
    </Dialog>
  );
}
