import { ContentConfig } from 'view/components/record-screen/types';
import { bankDepositsBlock } from '../blocks/bank-deposits';
import { bankWithdrawalsBlock } from '../blocks/bank-withdrawals';
import { unpresentedChequesBlock } from '../blocks/unpresented-cheques';
import { adjustmentsBlock } from '../blocks/adjustments';
import { suspendedTransactionsBlock } from '../blocks/suspended-transactions';

export const reconciliationDetailsContent: ContentConfig = [
  {
    label: 'Review',
    items: [
      {
        id: 'bank-withdrawals',
        label: 'Bank withdrawals',
        blocks: [bankWithdrawalsBlock]
      },
      {
        id: 'bank-deposits',
        label: 'Bank deposits',
        blocks: [bankDepositsBlock]
      },
      {
        id: 'unpresented-cheques',
        label: 'Unpresented cheques',
        blocks: [unpresentedChequesBlock]
      },
      {
        id: 'adjustments',
        label: 'Adjustments',
        blocks: [adjustmentsBlock]
      },
      {
        id: 'suspended-transactions',
        label: 'Suspended transactions',
        blocks: [suspendedTransactionsBlock]
      }
    ]
  }
];
