import { useMemo } from 'react';
import { query } from '@rexlabs/model-generator';
import { financialsCashBookEntriesModel } from 'data/models/entities/financials/cash-book-entries';
import { toQuri, Quri } from '@rexlabs/quri';

const getCashBookEntriesQuery = (filter: string) => ({
  ...query`{
    ${financialsCashBookEntriesModel} (q: ${filter}) {
        id
        date_of
        payment_reference
        payment_method
        amount
    }
}`,
  uuid: 'cash-book-entries-list'
});

const getFilter = (isAvailableForDeposit: boolean, trustAccountId?: string) => {
  const quris: Quri[] = [];

  if (trustAccountId) {
    quris.push({
      field: 'trust_account_id',
      op: '==',
      value: trustAccountId
    });
  }

  return toQuri([
    ...quris,
    {
      field: 'available_for_deposit',
      op: '==',
      value: isAvailableForDeposit ? 'true' : 'false'
    }
  ]);
};

export function useCashBookEntriesQuery(
  isAvailableForDeposit: boolean,
  trustAccountId?: string
) {
  const query = useMemo(
    () =>
      getCashBookEntriesQuery(getFilter(isAvailableForDeposit, trustAccountId)),
    [isAvailableForDeposit, trustAccountId]
  );

  return query;
}
