import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { isArray } from 'lodash';

import { query } from '@rexlabs/model-generator';

import EmptyTable from 'assets/illustrations/empty-table.svg';

import { tasksModel } from 'src/modules/tasks/common/models/tasks-model';
import { useGetWithheldFundsActions } from 'src/modules/withheld-funds/hooks/use-get-withheld-funds-actions';

import { RecordTable, RecordTableProps } from 'view/components/table';
import { Message } from 'view/components/@luna/message';
import { EmptyCard } from 'view/components/record-screen/cards/empty-card';

import { Ownership } from 'data/models/entities/ownerships';

import { useWithheldFundsColumns } from '../utils/use-withheld-funds-columns';

type RelatedRecord = Ownership;
type TaskWithheldFundsTableProps = Pick<
  RecordTableProps<typeof tasksModel>,
  'items'
> & {
  relatedRecord: RelatedRecord;
};

export const taskQuery = query`{
  ${tasksModel} {
    maintenanceDetails
    property {
      active_property_ownership {
        ownership {
          owners
        }
      }
      active_property_tenancy {
        tenancy
      }
    }
    managed_by
  }
}`;

export function TaskWithheldFundsTable({
  relatedRecord,
  items
}: TaskWithheldFundsTableProps) {
  const getWithheldFundsActions = useGetWithheldFundsActions();
  const columns = useWithheldFundsColumns();

  const recordType = relatedRecord.__record_type;

  // If items are provided, use them directly. Otherwise, fetch the data.
  const tableConfig = useMemo(
    () =>
      isArray(items)
        ? {
            items,
            isLoading: false
          }
        : {
            getQuery: () => taskQuery,
            forcedGlobalFilter: [
              {
                field: 'has_withheld_funds',
                op: '==',
                value: 'true'
              },
              { field: `${recordType}_id`, op: '==', value: relatedRecord.id }
            ]
          },
    [items, recordType, relatedRecord.id]
  );

  return (
    <RecordTable<typeof tasksModel>
      columns={columns}
      isLoading={false}
      Empty={() => FloatsTableEmptyState({ relatedRecord })}
      emptyWithoutTable
      getActionMenuItems={({ item }) => getWithheldFundsActions(item)}
      {...tableConfig}
    />
  );
}

function FloatsTableEmptyState({ relatedRecord }: TaskWithheldFundsTableProps) {
  const { t } = useTranslation();

  const recordType = relatedRecord.__record_type;

  const title = t('withheld-funds.task-withheld-funds-table.empty-state.title');
  const message = t(
    'withheld-funds.task-withheld-funds-table.empty-state.message',
    { recordType }
  );
  return (
    <EmptyCard>
      <Message title={title} Illustration={EmptyTable}>
        {message}
      </Message>
    </EmptyCard>
  );
}
