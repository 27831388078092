import React, { useMemo } from 'react';
import { TitleBlock } from 'view/components/cards/title/title-block';
import { RecordObject, RecordType } from 'data/models/types';
import { Invoice } from 'data/models/entities/financials/invoices';
import { getRecordTitle } from 'utils/records/get-record-title';
import { TagConfig, TitleBlockProps } from 'view/components/@luna/title-block';
import { InvoiceStatus } from 'utils/static-value-lists/invoice-status';
import { useInvoiceActions } from '../actions/use-invoice-actions';

const type: RecordType = 'invoice';

type InvoiceTitleBlockProps = {
  invoice: Invoice;
};

export function InvoiceTitleBlock({ invoice }: InvoiceTitleBlockProps) {
  const getInvoiceActions = useInvoiceActions();

  const title = getRecordTitle({ type, object: invoice } as RecordObject);
  const actions = useMemo(() => getInvoiceActions(invoice), [invoice]);

  const statusTagColour = (status: InvoiceStatus): TagConfig['type'] => {
    switch (status) {
      case 'awaiting_approval':
        return 'average';
      case 'awaiting_payment':
        return 'average';
      case 'draft':
        return 'neutral';
      case 'paid':
        return 'good';
      case 'void':
        return 'bad';
      default:
        return 'good';
    }
  };
  const tags: TitleBlockProps['tags'] = [
    { type: statusTagColour(invoice.status.id), label: invoice.status.label! }
  ];

  return (
    <TitleBlock
      title={title}
      type={type}
      actions={actions}
      tags={tags}
      reference={invoice.record_reference}
    />
  );
}
