import { DialogProps, useErrorDialog } from '@rexlabs/dialog';
import { Forms } from '@rexlabs/form';
import { useModelActions } from '@rexlabs/model-generator';
import invariant from 'invariant';
import * as React from 'react';
import { invokeActionDeclaration } from 'src/modules/common/actions/utils/invoke-action-declaration';
import { useRecordCreatedToast } from 'src/modules/common/toasts/hooks/use-record-created-toast';
import { SearchResultItem } from 'utils/api/get-search-results';
import { RecordDialog } from 'view/components/record-screen/dialog/dialog';
import { emptyMessageCreatorItem } from '../../common/data/empty-message-creator-item';
import { smsDetailsBlock } from '../blocks/sms-details-block';
import {
  SMSCreateDialogButtonGroup,
  SMSCreateDialogButtonGroupProps
} from '../components/sms-create-dialog-button-group';
import { useGetSendMessageAction } from '../hooks/action-declarations/use-get-send-message-action';
import { mapCreateSMSFormToRequest } from '../mappers/map-create-sms-form-to-request';
import { CreateMessageRequest } from '../mappers/types/create-message-request';
import { messagesModel } from '../models/messages-model';
import { getChangeHandlers } from '../utils/get-sms-change-handlers';
import { CreateMessageFormData } from '../mappers/types/create-message-form-data';

export interface CreateSMSRecordDialogProps extends DialogProps {
  data: Partial<Omit<CreateMessageFormData, 'relates_to'>> & {
    relates_to: SearchResultItem | null;
  };
  onCreate?: (message: any) => any;
  hasButtonGroupActions?: boolean;
}

const content = [
  {
    id: 'add-message',
    label: 'Add Message',
    blocks: [smsDetailsBlock]
  }
];

const SEND_NOW = Symbol('SEND_NOW');
const SEND_LATER = Symbol('SEND_LATER');

export function CreateSMSRecordDialog({
  title = 'Send SMS now',
  hasButtonGroupActions,
  onCreate,
  onClose,
  data
}: CreateSMSRecordDialogProps) {
  const [relatesTo, setRelatesTo] = React.useState<SearchResultItem | null>(
    data.relates_to
  );

  const { createItem, refreshLists } = useModelActions(messagesModel);
  const addToast = useRecordCreatedToast(messagesModel, { actions: [] });
  const errorDialog = useErrorDialog();
  const getSendMessageAction = useGetSendMessageAction();

  const clickedButtonRef = React.useRef<symbol | null>(null);

  const getHandlers = (forms?: Forms) => getChangeHandlers(setRelatesTo, forms);

  const initialValues = {
    smsContent: '',
    sent_from: emptyMessageCreatorItem,
    ...data,
    relates_to: relatesTo
  };

  const createMessage = React.useCallback(
    async (data: CreateMessageRequest) => {
      const { data: message } = await createItem({
        data,
        args: {
          include: 'channels,template'
        }
      });
      Promise.all([refreshLists(), onCreate?.(message)]);
      return message;
    },
    [createItem, onCreate, refreshLists]
  );

  const handleSubmit = React.useCallback(
    async ({ values }) => {
      const type = clickedButtonRef.current;
      const data = mapCreateSMSFormToRequest(values);

      switch (type) {
        // Send now will use the same logic for message creation, but will then invoke the send now action
        case SEND_NOW: {
          try {
            const message = await createMessage(data);

            await invokeActionDeclaration(getSendMessageAction, message);

            return message;
          } catch (error) {
            invariant(error instanceof Error, 'error should be Error');

            errorDialog.open({
              message: error.message
            });
          }

          break;
        }

        // Send later will basically just create the message and that is all
        case SEND_LATER: {
          try {
            const message = await createMessage(data);

            addToast(message);

            return true;
          } catch (error) {
            invariant(error instanceof Error, 'error should be Error');

            errorDialog.open({
              message: error.message
            });
          }
          break;
        }

        default: {
          return;
        }
      }
    },
    [createMessage, getSendMessageAction, errorDialog, addToast]
  );

  const handleSendLater = React.useCallback(() => {
    clickedButtonRef.current = SEND_LATER;
  }, []);

  const handleSendNow = React.useCallback(() => {
    clickedButtonRef.current = SEND_NOW;
  }, []);

  return (
    <RecordDialog
      size='xl'
      title={title}
      data={initialValues}
      content={content}
      onClose={onClose}
      handleSubmit={handleSubmit}
      getHandlers={getHandlers}
      ButtonGroup={SMSCreateDialogButtonGroup}
      buttonGroupProps={
        {
          hasButtonGroupActions,
          handleSendLater,
          handleSendNow
        } as SMSCreateDialogButtonGroupProps
      }
      blockProps={{
        messageType: 'sms'
      }}
    />
  );
}
