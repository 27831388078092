import React, { useMemo } from 'react';
import { BlockConfig } from 'view/components/record-screen/types';
import { useFeatureFlags } from 'view/components/@luna/feature-flags';
import { FLAGS } from 'utils/feature-flags';
import { TransferFundsDetailsPrepaymentsForm } from 'src/modules/financials/components/transfer-funds-details-prepayments-form';
import { TransferFundsDetailsForm } from 'src/modules/financials/components/transfer-funds-details-form';
import { useSetAvailableAmount } from 'src/modules/financials/hooks/use-set-available-amount';
import { useTrustLedger } from '../utils/use-trust-ledger';
import 'src/modules/financials/utils/validate-bucket.ts';

export const useTransferFundsDetailsBlock = (): BlockConfig => {
  const { hasFeature } = useFeatureFlags();

  const prepaymentBucketRules = {
    prepayment_bucket: {
      rules: 'bucketRequiredIfTenancy'
    }
  };

  const hasPrepaymentsFeature = hasFeature(FLAGS.PREPAYMENTS);

  return useMemo(
    () => ({
      id: 'transfer-funds-details',
      title: 'Transfer from',
      validate: {
        definitions: {
          'source.ledger.object': { rules: 'required', name: 'transfer from' },
          bank_account: { rules: 'required', name: 'bank account' },
          date_of: { rules: 'required', name: 'date' },
          ...(hasPrepaymentsFeature ? prepaymentBucketRules : {})
        }
      },
      Edit: ({ values, setFieldValue, forms }) => {
        const folio = values.source?.ledger?.object ?? undefined;
        const bankAccount = values.bank_account ?? undefined;
        const { trustLedger } = useTrustLedger(folio, bankAccount);
        const { hasFeature } = useFeatureFlags();

        useSetAvailableAmount({ values, setFieldValue });

        const lineItemsAmount = getLineItemsAmount(
          forms?.['trust-journal-entry-line-items']?.values?.line_items
        );

        const runningAmount = values.available_amount - lineItemsAmount;

        return hasFeature(FLAGS.PREPAYMENTS) &&
          values.source?.ledger?.object?.type?.id === 'tenancy' ? (
          <TransferFundsDetailsPrepaymentsForm
            values={values}
            trustLedger={trustLedger}
            bucketAmount={runningAmount}
            setFieldValue={setFieldValue}
          />
        ) : (
          <TransferFundsDetailsForm runningAmount={runningAmount} />
        );
      }
    }),
    [hasPrepaymentsFeature]
  );
};

function getLineItemsAmount(lineItems) {
  if (!Array.isArray(lineItems)) return 0;

  return lineItems.reduce((total, lineItem) => {
    return total + (lineItem?.amount ?? 0);
  }, 0);
}
