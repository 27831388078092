import { CreditNote } from 'data/models/entities/financials/credit-notes';
import { Invoice } from 'data/models/entities/financials/invoices';
import { TrustJournalEntryLineItem } from 'src/modules/trust-journal-entry-line-items/types/trust-journal-entry-line-item-type';
import { AccountingJournalEntry } from '../types/account-journal-entry-types';

export function getAccountingJournalEntrySourceLabel(
  accountingJournalEntry: AccountingJournalEntry
): string {
  if (accountingJournalEntry.type.id === 'manual') {
    return 'Manual';
  }

  if (
    accountingJournalEntry.source_object_type ===
    'trust_journal_entry_line_item'
  ) {
    const source = accountingJournalEntry.source_object as TrustJournalEntryLineItem;
    return source.trust_journal_entry!.record_reference;
  } else {
    const source = accountingJournalEntry.source_object as Invoice | CreditNote;
    return source.record_reference;
  }
}
