import { RecordData } from 'utils/types';
import { RecordObject } from 'data/models/types';
import { getRecordType } from './get-record-type';

export function transformToRecordObject(record: RecordData): RecordObject {
  return {
    object: record,
    type: getRecordType(record) as any
  };
}
