import * as React from 'react';
import { Select, useValueList } from '@rexlabs/select';

import { api } from 'utils/api/api-client';

import { ComplianceType } from 'src/modules/compliance/common/models/compliance-types';
import { normaliseValueListItem } from 'utils/normaliser/value-list-item';
import { toQuri } from '@rexlabs/quri';

export function SupplierComplianceRequirementsSelect(props) {
  const { getSelectProps } = useValueList({
    getItems: React.useCallback(async () => {
      const requirements = await api.get('settings/compliance-types', {
        per_page: 50,
        q: toQuri([
          { field: 'applicable_to_object_type_id', op: '==', value: 'supplier' }
        ])
      });

      return requirements.data as ComplianceType[];
    }, [])
  });

  return (
    <Select
      multi={true}
      {...props}
      {...getSelectProps()}
      normaliser={normaliseValueListItem}
    />
  );
}
