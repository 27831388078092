import React from 'react';
import { OutlineButton } from '@rexlabs/button';
import { KeySet } from '../data/key-set-model';
import { useGetCheckInKeySetAction } from '../hooks/use-get-check-in-key-set-action';

export function CheckInKeySetButton({ data }: { data: KeySet }) {
  const getCheckInKeyAction = useGetCheckInKeySetAction();

  const { label, onClick } = getCheckInKeyAction(data);
  return (
    <OutlineButton role='button' onClick={onClick}>
      {label}
    </OutlineButton>
  );
}
