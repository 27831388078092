import React from 'react';

import Box from '@rexlabs/box';

import { Disbursement } from 'data/models/entities/financials/disbursements/disbursements';

import { BlockConfig } from 'view/components/record-screen/types';

import { SummaryTable } from '../../../view/components/summary-table';

import { useGetDisbursementSummaries } from '../hooks/use-get-disbursement-summaries';

export const completedDisbursementSummaryBlock: BlockConfig<Disbursement> = {
  id: 'pending_disbursement_summary',
  title: 'Disbursement summary',
  View: ({ data }) => {
    const {
      disbursement_snapshot_summary: snapshotSummary,
      contact,
      ownership
    } = data || {};

    const object = contact || ownership;
    const {
      openingFigures,
      thisDisbursement,
      closingFigures
    } = useGetDisbursementSummaries(snapshotSummary, object);

    return (
      <Box flexDirection='column'>
        <SummaryTable header='Opening figures' data={openingFigures} />
        <SummaryTable header='This disbursement' data={thisDisbursement} />
        <SummaryTable header='Closing figures' data={closingFigures} />
      </Box>
    );
  }
};
