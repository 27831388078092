import React, { useCallback } from 'react';
import dayjs from 'dayjs';

import { Body, Heading } from '@rexlabs/text';
import Box from '@rexlabs/box';
import { Field } from '@rexlabs/form';
import { TextArea } from '@rexlabs/text-input';
import { useToken } from '@rexlabs/styling';

import { BankAccountSelect } from 'src/modules/bank-accounts/components/bank-account-select';
import { WithdrawalPaymentDetailsForm } from 'src/modules/trust-journal-entries/components/withdrawal-payments-details-form';
import { setContactPaymentInformationFormValues } from 'src/modules/trust-journal-entries/utils/set-contact-payment-information-form-values';
import { getWithdrawalDetailsFormValidationRules } from 'src/modules/trust-journal-entries/utils/get-withdrawal-details-form-validation-rules';

import { BlockConfig } from 'view/components/record-screen/types';
import { Grid } from 'view/components/@luna/form/grid';
import { ContactSelect } from 'view/components/inputs/selects/contact';
import {
  DateInput,
  disableFutureDates,
  getDisableDatesOutsideOfRange
} from 'view/components/@luna/inputs/date-input/date-input';
import { AdjustmentTypeSelect } from 'view/components/inputs/selects/adjustment-type-select';
import { InfoBanner } from 'view/components/@luna/notifications/banner';
import InfoCircleIcon from 'view/components/icons/info';
import { CentAmountInput } from 'view/components/inputs/cent-amount';
import { useDefaultBankAccount } from 'view/hooks/api/use-default-bank-account';

import { TaxAdjustmentForm } from '../types/tax-adjustment-form';
import { OwnershipTaxTransaction } from '../types/ownership-tax-transaction';
import { ManualOwnershipTransactionType } from '../types/ownership-tax-transaction-manual-transaction-type';
import { useGetFinancialYearStartAndEnd } from '../hooks/use-get-financial-year-start-and-end';

type TaxAdjustmentField = keyof OwnershipTaxTransaction;

const adjustmentTypeField: TaxAdjustmentField = 'manual_transaction_type';
const ownerField: TaxAdjustmentField = 'contact';
const amountField: TaxAdjustmentField = 'amount';
const dateField: TaxAdjustmentField = 'date_of';
const descriptionField: TaxAdjustmentField = 'description';

function getAdjustmentTypeDescription(
  adjustmentType?: ManualOwnershipTransactionType
) {
  switch (adjustmentType) {
    case 'withhold_available_funds_from_ownership':
      return 'Funds will be taken from the ownership and assigned as withheld tax for the selected owner';
    case 'reimburse_withheld_tax_to_owner':
      return 'A withdrawal to this owner will be created from their withheld tax';
    default:
      return undefined;
  }
}

function InfoBannerDescription() {
  const token = useToken();
  return (
    <Box flexDirection='row' alignItems='center'>
      <Box h={14} width={14} mr={token('spacing.s')}>
        <InfoCircleIcon />
      </Box>
      <Body>
        To adjust income or expenses, create a journal entry from the{' '}
        <Body as='span' bold>
          Income & expense{' '}
        </Body>
        section of the ownership
      </Body>
    </Box>
  );
}

export const taxAdjustmentBlock: BlockConfig<
  OwnershipTaxTransaction,
  {
    toggleIsReimbursement: (newValue: boolean) => void;
  },
  TaxAdjustmentForm
> = {
  id: 'tax-adjustment',
  title: 'Tax adjustment',
  validate: {
    definitions: {
      [adjustmentTypeField]: { rules: 'required' },
      [ownerField]: { rules: 'required' },
      [amountField]: { rules: 'required|min:1' },
      [dateField]: { rules: 'required' },
      [descriptionField]: { rules: 'required' },
      bank_account: {
        rules:
          'required_if:manual_transaction_type,reimburse_withheld_tax_to_owner'
      },
      ...getWithdrawalDetailsFormValidationRules(
        'payment_information',
        'required_if:manual_transaction_type,reimburse_withheld_tax_to_owner'
      )
    },
    messages: {
      required_if: 'The :attribute field is required.'
    }
  },
  Edit: ({ values, setFieldValue, blockProps }) => {
    const { defaultBankAccount } = useDefaultBankAccount();
    const getFinancialYearStartAndEnd = useGetFinancialYearStartAndEnd();
    const { startDate, endDate } = getFinancialYearStartAndEnd();

    const { toggleIsReimbursement } = blockProps || {};

    const onAdjustmentTypeChange = useCallback(
      async (event: any) => {
        const incomingTypeId: ManualOwnershipTransactionType =
          event?.target?.value?.id;

        if (incomingTypeId === 'reimburse_withheld_tax_to_owner') {
          setFieldValue?.('bank_account', defaultBankAccount);

          if (values?.contact) {
            await setContactPaymentInformationFormValues(
              values?.contact,
              'payment_information',
              setFieldValue
            );
          }
        } else {
          setFieldValue?.('bank_account', null);
          setFieldValue?.('payment_information', null);
        }

        toggleIsReimbursement?.(
          incomingTypeId === 'reimburse_withheld_tax_to_owner'
        );
      },
      [
        setFieldValue,
        defaultBankAccount,
        toggleIsReimbursement,
        values?.contact
      ]
    );

    const onOwnerChange = useCallback(
      async (event: any) => {
        const newContact = event?.target?.value;

        if (
          newContact &&
          values?.manual_transaction_type?.id ===
            'reimburse_withheld_tax_to_owner'
        ) {
          await setContactPaymentInformationFormValues(
            newContact,
            'payment_information',
            setFieldValue
          );
        }
      },
      [setFieldValue, values?.manual_transaction_type?.id]
    );

    const isOutsideRange = useCallback(
      (date) => {
        const disableDatesOutsideOfRange = getDisableDatesOutsideOfRange(
          dayjs(startDate),
          dayjs(endDate)
        );
        return disableDatesOutsideOfRange(date) || disableFutureDates(date);
      },
      [startDate, endDate]
    );

    return (
      <Grid columns={1}>
        <InfoBanner description={<InfoBannerDescription />} />
        <Grid columns={2}>
          <Field
            id={adjustmentTypeField}
            name={adjustmentTypeField}
            label='Adjustment type'
            Input={AdjustmentTypeSelect}
            description={getAdjustmentTypeDescription(
              values?.[adjustmentTypeField]?.id
            )}
            onChange={onAdjustmentTypeChange}
          />
          <Field
            id={ownerField}
            name={ownerField}
            label='Owner'
            Input={ContactSelect}
            onChange={onOwnerChange}
            // TODO: add inputProps to filter by the contacts in the ownership
          />
          <Field
            id={amountField}
            name={amountField}
            label='Amount'
            Input={CentAmountInput}
          />
          <Field<typeof DateInput>
            id={dateField}
            name={dateField}
            label='Date'
            Input={DateInput}
            inputProps={{
              isOutsideRange
            }}
          />
        </Grid>
        <Field
          id={descriptionField}
          name={descriptionField}
          label='Description'
          Input={TextArea}
        />
        {values?.manual_transaction_type?.id ===
          'reimburse_withheld_tax_to_owner' && (
          <>
            <Heading level={3}>Bank account</Heading>
            <Grid columns={2}>
              <Field
                id='bank_account'
                name='bank_account'
                label='Bank account'
                Input={BankAccountSelect}
              />
            </Grid>
            <Heading level={3}>Payment details</Heading>
            <Grid columns={3}>
              <WithdrawalPaymentDetailsForm
                fieldPrefix={'payment_information'}
                values={values}
              />
            </Grid>
          </>
        )}
      </Grid>
    );
  }
};
