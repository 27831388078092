import { Columns } from 'src/view/components/table';
import { DaysInArrearsCell } from 'src/modules/tasks/arrears/components/days-in-arrears-cell';
import { ArrearsSummaryCell } from 'src/modules/tasks/arrears/components/arrears-summary-cell';
import { ProgressDropdown } from 'src/modules/tasks/arrears/components/progress-dropdown';
import { RentPaidToCell } from 'src/modules/property-tenancies/components/rent-paid-to-cell';
import { RentPaidToValues } from 'src/modules/settings/hooks/useSettings';
import { getCsvValuePaidToDate } from 'src/modules/property-tenancies/utils/get-csv-value-paid-to-date';
import { ArrearsTask } from '../types/ArrearsTask';
import { closedDate, managedBy } from '../../common/utils/common-columns';

interface getArrearsColumnsOptions {
  resolved: boolean;
  paidToDateSetting?: RentPaidToValues;
}

export const getArrearsColumns = ({
  resolved,
  paidToDateSetting = 'effective_and_periodic_with_surplus'
}: getArrearsColumnsOptions): Columns => {
  if (resolved) {
    return [
      {
        // not naming this summary since it doesn't actually show the summary field,
        // FE has no control over what is entered in the summary field so we can't match it up here
        // and if we do name the column summary, it will sort by summary in a way that will seem buggy
        id: 'tenancy',
        Header: 'Summary',
        accessor: (item) => item,
        Cell: ArrearsSummaryCell,
        width: 380,
        toCsv: (item: ArrearsTask) =>
          item?.details?.property_tenancy?.tenancy?.display_name || ''
      },

      {
        id: 'task_arrears_status_id',
        Header: 'Task progress',
        Cell: ProgressDropdown,
        accessor: (item: ArrearsTask) => item,
        width: 150,
        toCsv: (item: ArrearsTask) => item?.details?.status?.label || ''
      },
      closedDate,
      managedBy
    ];
  }

  return [
    {
      id: 'tenancy',
      Header: 'Summary',
      accessor: (item) => item,
      Cell: ArrearsSummaryCell,
      width: 380,
      toCsv: (item: ArrearsTask) =>
        item?.details?.property_tenancy?.tenancy?.display_name || ''
    },
    {
      id: 'days_in_arrears',
      Header: 'In arrears',
      accessor: (item: ArrearsTask) =>
        item?.details?.property_tenancy?.rent_arrears?.days,
      Cell: DaysInArrearsCell,
      width: 140,
      toCsv: (item: ArrearsTask) =>
        item?.details?.property_tenancy?.rent_arrears?.days.toString() || ''
    },

    {
      id: 'rent_paid_to',
      Header: 'Rent paid to',
      Cell: RentPaidToCell,
      accessor: (item: ArrearsTask) => ({
        setting: paidToDateSetting,
        ...item.details?.property_tenancy
      }),
      width: 300,
      toCsv: getCsvValuePaidToDate(paidToDateSetting)
    },
    {
      id: 'follow_up_date',
      Header: 'Follow up date',
      accessor: (item: ArrearsTask) => item.follow_up_date,
      type: 'date',
      width: 140
    },

    {
      id: 'task_arrears_status_id',
      Header: 'Task progress',
      Cell: ProgressDropdown,
      accessor: (item: ArrearsTask) => item,
      width: 150,
      toCsv: (item: ArrearsTask) => item?.details?.status?.label || ''
    },
    managedBy
  ];
};
