import { ActionDeclaration } from 'src/modules/common/actions/types/action-declaration-types';
import { useDialog } from '@rexlabs/dialog';
import { EditEntityDialog } from 'src/modules/agency-fees/dialogs/edit-entity-fee';
import { useModelActions } from '@rexlabs/model-generator';
import { useToast } from 'view/components/@luna/notifications/toast';
import React from 'react';
import { formatDynamicAmount } from 'src/modules/agency-fees/utils/format-dynamic-amount';
import {
  PropertyOwnershipFee,
  propertyOwnershipsFeesModel
} from 'data/models/entities/financials/property-ownerships/property-ownership-fee';
import { propertiesModel } from 'data/models/entities/properties';

export function useGetEditPropertyOwnershipFeeAction() {
  const { updateItem } = useModelActions(propertyOwnershipsFeesModel);
  const { refreshItem } = useModelActions(propertiesModel);
  const { open } = useDialog(EditEntityDialog);
  const { addToast } = useToast();

  return (
    fee: PropertyOwnershipFee,
    propertyOwnershipId: string,
    propertyId?: string
  ): ActionDeclaration => ({
    id: 'edit-property-ownership-fee',
    group: 'property-ownership-fee',
    label: 'Edit',
    handleAction: () => {
      open({
        fee,
        feeType: 'property_ownership',
        onSubmit: async (updateFee) => {
          const updatedFeeRequest = await updateItem({
            id: fee.id,
            args: {
              propertyOwnershipId,
              include: 'agency_fee'
            },
            data: updateFee
          });

          const updatedFee = updatedFeeRequest.data as PropertyOwnershipFee;

          if (propertyId) {
            await refreshItem({ id: propertyId });
          }

          addToast({
            description: (
              <>
                <b>{updatedFee.agency_fee?.name} fee</b> updated to a rate of{' '}
                <b>{formatDynamicAmount(updatedFee.fee_amount)}</b>
              </>
            )
          });
        }
      });
    }
  });
}
