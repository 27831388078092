import * as React from 'react';
import { RecordDialog } from 'view/components/record-screen/dialog/dialog';
import { DialogProps } from '@rexlabs/dialog';
import { FieldArrayPassthroughProps } from '@rexlabs/form';
import { checklistTemplateItemTriggerDetailsBlock } from '../blocks/checklist-template-item-trigger-details-block';
import { mapChecklistTemplateItemToChecklistTemplateItemTriggerFormData } from '../mappers/map-checklist-template-item-to-checklist-template-item-trigger-form-data';
import { ChecklistTemplateItem } from '../types/ChecklistTemplate';
import { mapTriggerFormDataToTriggerItem } from '../mappers/map-trigger-form-data-to-trigger';
import { TaskType } from '../../common/types/TaskType';

function getContent() {
  return [
    {
      id: 'checklist-item',
      label: 'Edit checklist item',
      blocks: [checklistTemplateItemTriggerDetailsBlock]
    }
  ];
}

interface EditChecklistItemTriggerDialogProps extends DialogProps {
  checklistTemplateItem: ChecklistTemplateItem;
  fieldArrayProps: FieldArrayPassthroughProps;
  index: number;
  taskType: TaskType;
}

export function EditChecklistItemTriggerDialog({
  onClose,
  checklistTemplateItem,
  fieldArrayProps,
  index,
  taskType
}: EditChecklistItemTriggerDialogProps) {
  const content = getContent();
  const handleSubmit = async ({ values }) => {
    const { replace } = fieldArrayProps;

    const triggerData = mapTriggerFormDataToTriggerItem({
      checklistTemplateTriggerFormData: values
    });

    replace(index, { ...checklistTemplateItem, ...triggerData });
    return true;
  };

  const initialValues = mapChecklistTemplateItemToChecklistTemplateItemTriggerFormData(
    {
      checklistTemplateItem
    }
  );

  return (
    <RecordDialog
      title='Triggered action'
      content={content}
      onClose={onClose}
      submitLabel='Okay'
      handleSubmit={handleSubmit}
      initialValues={initialValues}
      blockProps={{ taskType }}
    />
  );
}
