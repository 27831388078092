import { TaskCreateRequest } from '../types/TaskCreateRequest';
import { TaskFormData } from '../types/TaskFormData';

type CoreTaskCreateRequest = Omit<TaskCreateRequest, 'type' | 'details'>;

export function mapTaskFormDataToTaskCreateRequest(
  formData: Partial<TaskFormData>
): Partial<CoreTaskCreateRequest> {
  const {
    summary,
    description,
    property,
    due_date,
    follow_up_date,
    managed_by,
    priority,
    status,
    parent_task,
    task_links
  } = formData;

  const data = {
    summary,
    description,
    property,
    due_date,
    follow_up_date,
    managed_by,
    priority,
    status,
    parent_task,
    task_links
  };

  const blacklist = ['follow_up_date', 'due_date'];

  // Remove any null values
  Object.keys(data).forEach((key) => {
    if (data[key] == null && !blacklist.includes(key)) {
      delete data[key];
    }
  });

  return data as Exclude<CoreTaskCreateRequest, null>;
}
