import { useDialog } from '@rexlabs/dialog';
import { ActionDeclaration } from 'src/modules/common/actions/types/action-declaration-types';
import { GetFinancialEntityActionArgs } from 'src/modules/financials/utils/financial-entity-action-group/use-get-financial-entity-actions';
import { WithdrawFundsDialog } from 'src/modules/trust-journal-entries/dialogs/withdraw-funds-dialog';

export function useGetWithdrawFundsAction(
  {
    showBannerMessage
  }: {
    showBannerMessage?: boolean;
  } = { showBannerMessage: false }
) {
  const withdrawFundsDialog = useDialog(WithdrawFundsDialog);

  return (args?: GetFinancialEntityActionArgs): ActionDeclaration => {
    const { entity, onCreate } = args ?? {};

    return {
      id: 'withdraw-funds',
      group: 'financial',
      label: 'Withdraw funds',
      handleAction: async () =>
        withdrawFundsDialog.open({
          initialValues: { folio: entity },
          showBannerMessage,
          onCreate
        })
    };
  };
}
