import Box from '@rexlabs/box';
import { LinkButton } from '@rexlabs/button';
import { useDialog } from '@rexlabs/dialog';
import { CellProps } from '@rexlabs/table';
import React, { useMemo, useState } from 'react';
import { HiddenField } from '@rexlabs/form';
import { getUploadedFileMeta } from 'utils/files';
import { AddImagesDialog } from '../dialogs/add-images-dialog';

interface EditImagesCellProps extends CellProps {
  setFieldValue?: (field: string, value: any) => void;
}

export function EditImagesCell({ value, setFieldValue }: EditImagesCellProps) {
  const images = value.images?.data || [];

  const [imageCount, setImageCount] = useState(images.length);
  const [savedImages, setSavedImages] = useState(images);
  const { open } = useDialog(AddImagesDialog);

  const hiddenImagesFieldName = `area_items.${value.id}.images.data`;

  const handleSubmit = async ({ values }) => {
    const uploadedImages = await getUploadedFileMeta(values?.images || []);
    setFieldValue?.(hiddenImagesFieldName, uploadedImages || []);
    setSavedImages(uploadedImages ? uploadedImages : []);
    setImageCount(uploadedImages?.length ? uploadedImages?.length : 0);

    return true;
  };

  return useMemo(
    () => (
      <Box
        display='flex'
        justifyContent='center'
        width='100%'
        data-testid='area-item-images-button'
      >
        <LinkButton
          onClick={() => {
            open({
              images: savedImages,
              handleSubmit: handleSubmit,
              areaItemName: value.name
            });
          }}
        >
          {imageCount}
        </LinkButton>
        <HiddenField name={hiddenImagesFieldName} />
      </Box>
    ),
    [imageCount, savedImages]
  );
}
