import { useMemo } from 'react';
import { getInvoicesPayableToBlock } from 'src/modules/invoices/blocks/invoices-payable-to';
import { useModelActions } from '@rexlabs/model-generator';
import { useInvoiceFilters } from 'src/modules/invoices/utils/use-invoice-filters';
import { contactsModel } from '../models/contacts';
import { contactIncludes } from '../screens/contact-details';

export const usePayableToInvoicesBlock = () => {
  const { refreshItem } = useModelActions(contactsModel);

  const {
    filters: invoiceFilters,
    actions: invoiceActions
  } = useInvoiceFilters({
    hiddenInvoiceFilterIds: ['rent_invoices', 'agency_fees', 'bond_requests']
  });

  return useMemo(
    () =>
      getInvoicesPayableToBlock(({ data }) => ({
        hashParamKey: 'payable_to',
        getForcedGlobalFilter: (contactId: string) => [
          {
            field: 'payable_to_object_id',
            op: '==',
            value: contactId
          },
          {
            field: 'payable_to_object_type',
            op: '==',
            value: 'contact'
          }
        ],
        initialValues: {
          payable_to: {
            object: {
              id: data.id,
              type: { id: 'contact', label: 'Contact' },
              label: data.display_name,
              record: data
            }
          }
        },
        invoiceFilters,
        invoiceActions,
        refreshRecord: async () =>
          await refreshItem({
            id: data.id,
            args: {
              include: contactIncludes
            }
          })
      })),
    [invoiceFilters]
  );
};
