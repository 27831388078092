import { Generator } from 'data/models/generator';
import { auditableConfig } from 'src/modules/common/auditable/auditable-config';
import { ChecklistTemplate } from '../types/ChecklistTemplate';

export const GROUP_INCLUDES =
  'groups,groups.triggered_by_checklist_template_item';

export const ITEM_INCLUDES =
  'items,items.due_date_data,items.action_data,items.action_message_template,items.assigned_to,items.outcome_data,items.checklist_template_item_group';

const config = {
  entities: {
    related: {
      ...auditableConfig,

      items: {
        include: ITEM_INCLUDES
      },

      groups: {
        include: GROUP_INCLUDES
      },

      checklists: {
        include: 'checklists'
      }
    }
  }
};

export const checklistTemplatesModel = new Generator<ChecklistTemplate>(
  'tasks/checklist-templates',
  config
).createEntityModel();
