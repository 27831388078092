import * as React from 'react';
import { EmptyCard } from 'view/components/record-screen/cards/empty-card';
import { Message } from 'view/components/@luna/message';
import EmptyTable from 'assets/illustrations/empty-table.svg';

export function InspectionRunsTableEmptyState({ status }: { status: string }) {
  return (
    <EmptyCard>
      <Message title={`${status} inspection runs`} Illustration={EmptyTable}>
        There are no {status.toLowerCase()} inspection runs
      </Message>
    </EmptyCard>
  );
}
