import dayjs, { Dayjs } from 'dayjs';
/**
 * DATES & DATETIMES
 */
export function formatDate(
  date: string | Date | Dayjs,
  format = 'D MMM YYYY'
): string {
  return dayjs(date).format(format);
}

export function formatDateTime(date: string | Date | Dayjs): string {
  return dayjs(date).tz().format('D MMM YYYY hh:mma');
}

export function formatDueIn(date: string | Date | Dayjs): string {
  // eslint-disable-next-line
  // @ts-ignore
  return dayjs(date).fromNow();
}

export function formatDueDate(date: string | Date | Dayjs): string {
  return `${dayjs(date).format('D MMM YYYY')} (Due ${formatDueIn(date)})`;
}

export function formatDateIso(date: string | Date | Dayjs): string {
  return formatDate(date, 'YYYY-MM-DD');
}

// TODO: [AL-460] Localize date string formats. We may want customised formats per-region too.
export const formatDateLong = (date: Date | string) => {
  return formatDate(date, 'DD MMM, YYYY');
};

export function formatHumanNumber(number: number, decimals = 2): string {
  const formatter = window.Intl.NumberFormat('en-GB', {
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals
  });
  return formatter.format(number);
}

export function formatHumanReadableFileSize(bytes: number): string {
  if (bytes >= 1024 ** 3) {
    return `${formatHumanNumber(bytes / 1024 ** 3, 2)} GB`;
  }
  if (bytes >= 1024 ** 2) {
    return `${formatHumanNumber(bytes / 1024 ** 2, 2)} MB`;
  }
  if (bytes >= 1024) {
    return `${formatHumanNumber(bytes / 1024, 0)} KB`;
  }
  return `${formatHumanNumber(bytes, 0)} Bytes`;
}
