import { useEntityQuery } from '@rexlabs/model-generator';
import { Tenancy } from 'data/models/entities/tenancies';
import { useMemo } from 'react';
import { getTenancyQuery } from 'src/modules/tenancies/data/queries';
import { SearchResultItem } from 'utils/api/get-search-results';

export function usePropertyTenanciesFromTenancySearchResultItem(
  folio: SearchResultItem<Tenancy> | null
) {
  const query = useMemo(
    () => (folio?.id ? getTenancyQuery(folio?.id) : undefined),
    [folio?.id]
  );
  const { data } = useEntityQuery(query, {
    throwOnError: false
  });

  const propertyTenancies = data?.property_tenancies?.data || [];

  return {
    propertyTenancies
  };
}
