import { OutlineButton } from '@rexlabs/button';
import { useErrorDialog } from '@rexlabs/dialog';
import { useModelActions } from '@rexlabs/model-generator';
import { push } from '@rexlabs/whereabouts';
import {
  financialsInvoicesModel,
  Invoice
} from 'data/models/entities/financials/invoices';
import * as React from 'react';
import ROUTES from 'routes/app';
import { financialsUploadedBillsModel } from 'src/modules/bill-processing/models/uploaded-bills';
import {
  postRequestPipelineRequests,
  RequestPipelineRequestWithId
} from 'utils/api/post-request-pipeline';
import { useToast } from 'view/components/@luna/notifications/toast';
import { RecordSubmitHandler } from 'view/components/record-screen/utils';
import {
  mapInvoiceValuesToPostData,
  mapReimbursementInvoiceValuesToPostData
} from '../utils/map-invoice-form-values-to-post-data';
import { CreateInvoiceDialogProps } from '../dialogs/create-invoice-dialog';

export type UseInvoiceSubmitHandlerProps = Pick<
  CreateInvoiceDialogProps,
  'onSave'
> & {
  isBillProcessingDialog?: boolean;
};

export function useInvoiceSubmitHandler(props: UseInvoiceSubmitHandlerProps) {
  const { onSave, isBillProcessingDialog = false } = props;

  const { importBills } = useModelActions(financialsUploadedBillsModel);
  const { refreshLists } = useModelActions(financialsInvoicesModel);
  const { open: openErrorDialog } = useErrorDialog();
  const { addToast } = useToast();

  const handleSubmit: RecordSubmitHandler = React.useCallback(
    async ({ values }) => {
      // If there is an uploaded_bill, create a bill to be processed
      // and set its file uuid as the value for 'uploaded_bill' for the create invoice request
      const uploaded_bill = values.uploaded_bill;

      if (uploaded_bill && !isBillProcessingDialog) {
        if (!uploaded_bill?.data) return;
        const { data: bill } = await Promise.resolve(uploaded_bill?.data);
        try {
          const uploadedBill = await importBills({
            fileIds: [bill.id]
          });
          await refreshLists();
          values.uploaded_bill = { id: uploadedBill.data[0].id };
        } catch (error) {
          openErrorDialog(error);
          return;
        }
      }

      const requests: RequestPipelineRequestWithId[] = [
        {
          id: 'invoice',
          path: '/api/v1/financials/invoices',
          method: 'POST',
          json: mapInvoiceValuesToPostData(values)
        }
      ];

      if (values.reimbursed_by?.object != null) {
        requests.push({
          id: 'reimbursement_invoice',
          path: '/api/v1/financials/invoices',
          method: 'POST',
          json: mapReimbursementInvoiceValuesToPostData({
            ...values,
            reimbursement_for_invoice: {
              id: '{{$.invoice.id}}'
            }
          })
        });
      }

      const response = await postRequestPipelineRequests<
        typeof requests,
        Invoice
      >(requests);

      await refreshLists();

      await Promise.resolve(onSave?.(response?.data?.invoice));

      addToast({
        description: `You have successfully created invoice ${response?.data.invoice.record_reference}`,
        actions: [
          {
            label: 'View',
            Button: OutlineButton,
            onClick: () =>
              push(ROUTES.INVOICE, {
                params: {
                  invoiceId: response.data.invoice.id
                }
              })
          }
        ]
      });

      if (response.data.reimbursement_invoice) {
        addToast({
          description: `You have successfully created the reimbursement invoice #${response?.data?.reimbursement_invoice.record_reference}`,
          actions: [
            {
              label: 'View',
              Button: OutlineButton,
              onClick: () =>
                push(ROUTES.INVOICE, {
                  params: {
                    invoiceId: response.data.reimbursement_invoice.id
                  }
                })
            }
          ]
        });
      }

      return response.data.invoice;
    },
    [
      isBillProcessingDialog,
      addToast,
      onSave,
      refreshLists,
      importBills,
      openErrorDialog
    ]
  );

  return handleSubmit;
}
