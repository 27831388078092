import React, { useMemo } from 'react';

import { TitleBlock } from 'view/components/cards/title/title-block';

import { getRecordTitle } from 'utils/records/get-record-title';
import { TrustJournalEntry } from 'src/modules/trust-journal-entries/types/trust-journal-entry-types';
import { titleBlockStatusTag } from 'src/modules/common/components/status-tag';
import { trustJournalEntryStatusMap } from 'src/modules/trust-journal-entries/maps/trust-journal-entry-status-map';
import { TagConfig } from 'view/components/@luna/title-block';
import { useTranslation } from 'react-i18next';
import { useTrustJournalEntryActions } from '../actions/use-trust-journal-entry-actions';

type TrustJournalEntryTitleBlockProps = {
  trustJournalEntry: TrustJournalEntry;
};

export function TrustJournalEntryTitleBlock({
  trustJournalEntry
}: TrustJournalEntryTitleBlockProps) {
  const { t } = useTranslation();

  const getTrustJournalEntryActions = useTrustJournalEntryActions();

  const title = getRecordTitle({
    type: 'trust_journal_entry',
    object: trustJournalEntry
  });

  const actions = useMemo(
    () => getTrustJournalEntryActions(trustJournalEntry),
    [trustJournalEntry]
  );

  const tags: TagConfig[] = [];

  tags.push(
    titleBlockStatusTag(trustJournalEntry.status, trustJournalEntryStatusMap)
  );

  return (
    <TitleBlock
      tags={tags}
      title={title}
      type={t('trust-journal-entries.label.singular') as string}
      actions={actions}
      reference={trustJournalEntry.record_reference}
    />
  );
}
