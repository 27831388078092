import { useDialog } from '@rexlabs/dialog';

import { ActionDeclaration } from 'src/modules/common/actions/types/action-declaration-types';

import { CreateTenancyDialog } from '../dialogs/create-tenancy-dialog';

export function useGetCreateTenancyAction() {
  const createTenancyDialog = useDialog(CreateTenancyDialog);
  return (): ActionDeclaration => ({
    id: 'create-tenancy',
    group: 'tenancy',
    label: 'Create tenancy',
    handleAction: () => createTenancyDialog.open()
  });
}
