import React from 'react';
import { Message } from 'view/components/@luna/message';
import { createStaticTableBlock } from 'view/components/record-screen/cards/create-static-table-block';
import { Disbursement } from 'data/models/entities/financials/disbursements/disbursements';
import { transformActionDeclarationsToActionMenuItems } from 'utils/actions/transforms';
import { useGetPrimaryRecordActionGroup } from 'view/hooks/actions/use-get-primary-record-action-group';
import { RecordTypes } from 'data/models/types';
import { TrustJournalEntryLineItem } from 'src/modules/trust-journal-entry-line-items/types/trust-journal-entry-line-item-type';
import EmptyTrustJournalEntriesTable from '../../../assets/illustrations/empty-trust-journal-entries-table.svg';
import { disbursementsGeneralPayoutsColumns } from '../utils/disbursements-general-payouts-columns';

// HACK: There is a bug in model generator where we're not sending the include
// that we're after for the general_payout_line_items.[*}.trust_journal_entry.
// Rather than falling into a rabbit hole of trying to fix it, we're just going to
// use this hack which maps data we already have in another key to the one we want.
function mapGeneralPayoutLineItems(data?: Disbursement): any {
  return (data?.general_payout_line_items?.items || []).map((lineItem) => {
    const matchTje = data?.trust_journal_entries?.items.find(
      (tje) => tje.id === lineItem.trust_journal_entry?.id
    );
    return {
      ...lineItem,
      trust_journal_entry: {
        ...matchTje,
        contact: data?.object
      }
    };
  });
}

export const useDisbursementGeneralPayoutsBlock = () => {
  const getPrimaryRecordActionGroup = useGetPrimaryRecordActionGroup();

  return createStaticTableBlock<Disbursement, TrustJournalEntryLineItem>({
    id: 'disbursements-general-payouts',
    title: 'General payouts',
    getTableProps: ({ data }) => {
      return {
        id: 'disbursements-general-payouts',
        items: mapGeneralPayoutLineItems(data),
        columns: disbursementsGeneralPayoutsColumns,
        getActionMenuItems: ({ item }) => {
          return transformActionDeclarationsToActionMenuItems([
            ...getPrimaryRecordActionGroup(
              RecordTypes.TrustJournalEntry,
              item?.trust_journal_entry?.id
            )
          ]);
        }
      };
    },
    Empty: () => (
      <Message
        Illustration={EmptyTrustJournalEntriesTable}
        title='There are no General payouts at this moment'
      >
        You have no General payouts that require your attention at this moment.
      </Message>
    )
  });
};
