import React from 'react';
import { BlockConfig } from 'view/components/record-screen/types';
import { Grid, Column } from '@rexlabs/grid';
import { Value } from 'view/components/values';
import { Field } from '@rexlabs/form';
import { Body } from '@rexlabs/text';
import { NumberInput } from '@rexlabs/text-input';
import { Settings } from '../../hooks/useSettings';

export const daysInAdvanceBlock: BlockConfig<Settings> = {
  id: 'days-in-advance-to-generate-rent-invoices',
  title: 'Create rent invoices',
  validate: {
    definitions: {
      'days-in-advance-to-generate-rent-invoices': { rules: 'required|min:1' }
    }
  },
  View: ({ data }) => {
    return (
      <Grid>
        <Column width={4}>
          <Value
            label='Post new rent invoices'
            value={data?.['days-in-advance-to-generate-rent-invoices']}
          />
        </Column>
      </Grid>
    );
  },
  Edit: () => {
    return (
      <Grid>
        <Column width={4}>
          <Field
            id='days-in-advance-to-generate-rent-invoices'
            label='Post new rent invoices'
            name='days-in-advance-to-generate-rent-invoices'
            Input={NumberInput}
            inputProps={{
              suffix: <Body>days in advance</Body>
            }}
          />
        </Column>
      </Grid>
    );
  }
};
