import { Ownership } from 'data/models/entities/ownerships';

import { Statement } from 'src/modules/statements/types';
import { Contact } from 'src/modules/contacts/types/contact-types';

import { api } from 'utils/api/api-client';

import { getOwnershipRelatedItems } from './get-ownership-related-items';

const relatedFields = ['object', 'object.owners', 'object.owners.contact'];

export async function getStatementRelatedItems(statementSearchItem: Statement) {
  const statementUrl = `/statements/${
    statementSearchItem.id
  }?include=${relatedFields.join(',')}`;

  const completeStatement = !statementSearchItem?.object
    ? await api.get<Statement>(statementUrl).then(({ data }) => data)
    : statementSearchItem;

  const { object } = completeStatement;

  const owners =
    object.__record_type === 'ownership'
      ? await getOwnershipRelatedItems(
          completeStatement.object as Ownership
        ).then(({ owners }) => owners as Contact[])
      : ([] as Contact[]);
  const contact = object.__record_type === 'contact' ? object : undefined;

  return {
    owners,
    contact
  };
}
