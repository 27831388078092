import { Includes, ListOfItems } from '@rexlabs/model-generator';
import { Generator, GeneratorConfig } from 'data/models/generator';
import { DisbursementFrequency } from 'src/modules/disbursements/types/frequency';
import { ContactDisbursementPaymentMethod } from 'src/modules/disbursements/types/types';
import {
  BankAccount,
  bankAccountModel
} from 'data/models/entities/financials/bank-accounts';
import { auditableConfig } from 'src/modules/common/auditable/auditable-config';
import {
  Contact,
  TrustSummary
} from 'src/modules/contacts/types/contact-types';
import { PropertyOwnership } from 'src/modules/property-ownerships/types/property-ownership-types';
import { contactsModel } from 'src/modules/contacts/models/contacts';
import {
  BaseFields,
  PayableEntityFinancialSummary,
  RecordReference,
  RecordTypes,
  TimestampAttributes,
  ValueListValue
} from '../types';

export type MaintenanceInstructions = {
  is_inherited_from_ownership?: boolean;
  notes: string | null;
  spend_limit: number | null;
};

export type Supplier = {
  contact: Contact;
};

export type OwnershipStatus = 'active' | 'archived';

export type Ownership = BaseFields<typeof RecordTypes.Ownership> &
  RecordReference &
  TimestampAttributes & {
    record_reference: string;
    display_name: string;
    property_ownerships: Includes<PropertyOwnership[]>;
    primary_contact: Contact;
    maintenance_instructions: MaintenanceInstructions;
    preferred_suppliers?: Supplier[];
    eofy_statement_enabled: boolean;
    owners?: Owner[];
    disbursement_frequency?: DisbursementFrequency;
    disbursement_payment_methods: ContactDisbursementPaymentMethod[];
    financial_summary?: PayableEntityFinancialSummary;
    trust_summary?: TrustSummary;
    status: ValueListValue<OwnershipStatus>;
    last_statement_date?: Date;
    disbursement_withheld_funds?: {
      total_amount_including_related_withheld_funds: number;
      amount: number;
      reason: string;
    };
    financial_contact?: Contact;
    operating_bank_account?: BankAccount;
  };

export type Owner = { contact: Contact; share?: number };

export type OwnershipsModel = Ownership & {
  owners: ListOfItems<Owner[]>;
};

export const ownershipModelConfig: GeneratorConfig = {
  entities: {
    related: {
      owners: {
        include: [
          'owners.contact',
          'owners.contact.primary_phone',
          'owners.contact.tax'
        ].join(',')
      },
      property_ownerships: {
        include: [
          'property_ownerships.ownership.owners.contact',
          'property_ownerships.property'
        ].join(',')
      },
      preferred_suppliers: {
        include:
          'preferred_suppliers.contact.emails,preferred_suppliers.contact.service_categories'
      },
      last_disbursement: {
        include: 'last_disbursement'
      },
      disbursement_frequency: {
        include: 'disbursement_frequency'
      },
      disbursement_payment_methods: {
        include:
          /**
           * NOTE: For what ever reason, when we access this model through pending disbursements, we need to have the includes like this.
           * I've attempt to use the existing pattern we use (like owners above), but it never returns the correct data
           */
          'disbursement_payment_methods{contact_payment_method,contact{tax}}'
      },
      bpay: {
        include: 'bpay'
      },
      disbursement_withheld_funds: {
        include: 'disbursement_withheld_funds'
      },
      financial_summary: {
        include: 'financial_summary'
      },
      financial_contact: {
        include: 'financial_contact',
        model: contactsModel
      },
      trust_summary: {
        include: 'trust_summary'
      },
      operating_bank_account: {
        include: 'operating_bank_account',
        model: bankAccountModel
      },
      ...auditableConfig
    }
  }
};

export const ownershipsModel = new Generator<OwnershipsModel>(
  'ownerships',
  ownershipModelConfig
).createEntityModel();
