import 'froala-editor/js/froala_editor.pkgd.min.js';

// Require Editor CSS files.
import 'froala-editor/css/froala_style.min.css';
import 'froala-editor/css/froala_editor.pkgd.min.css';

import 'froala-editor/js/plugins/table.min.js';
import 'froala-editor/js/plugins/quote.min.js';
import 'froala-editor/js/plugins/quick_insert.min.js';
import 'froala-editor/js/plugins/paragraph_format.min.js';
import 'froala-editor/js/plugins/draggable.min.js';
import 'froala-editor/js/plugins/align.min.js';
import 'froala-editor/js/plugins/lists.min.js';
import 'froala-editor/js/plugins/link.min.js';
import 'froala-editor/js/plugins/url.min.js';
import 'froala-editor/js/plugins/image.min.js';
import 'froala-editor/js/plugins/emoticons.min.js';
import 'froala-editor/js/plugins/colors.min.js';
import 'froala-editor/js/plugins/entities.min.js';
import 'froala-editor/js/plugins/inline_style.min.js';
import 'froala-editor/js/plugins/font_family.min.js';
import 'froala-editor/js/plugins/font_size.min.js';
import 'froala-editor/js/plugins/code_view.min.js';
import 'froala-editor/js/plugins/fullscreen.min.js';
import 'froala-editor/js/plugins/special_characters.min.js';
import 'froala-editor/js/plugins/line_height.min.js';

import '../froala-editor.css';

import React from 'react';

import FroalaEditorView from 'react-froala-wysiwyg/FroalaEditorView';

const readOnlyConfig = {
  attribution: false,
  useClasses: false,
  heightMin: 200,
  tableStyles: {
    presentation: 'Remove Outlines'
  },
  pluginsEnabled: [
    'table',
    'quote',
    'quickInsert',
    'paragraphFormat',
    'draggable',
    'align',
    'lists',
    'link',
    'url',
    'image',
    'emoticons',
    'colors',
    'entities',
    'inlineStyle',
    'fontFamily',
    'fontSize',
    'codeView',
    'fullscreen',
    'specialCharacters',
    'lineHeight'
  ]
};

export function FroalaReadOnly({ value }: { value: string }) {
  return (
    <div data-testid={'read-only'}>
      <FroalaEditorView tag='div' model={value} config={readOnlyConfig} />
    </div>
  );
}
