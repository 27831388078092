import { Columns } from 'src/view/components/table';
import { NoteSummaryCell } from 'src/modules/note/components/note-summary-cell';
import { LargeDescriptionCell } from 'view/components/cells/large-description-cell';
import React from 'react';
import { Note } from '../types/Note';
import {
  NoteAuthorCell,
  NoteAuthorCellValue
} from '../components/note-author-cell';

export const columns: Columns<Note> = [
  {
    id: 'title',
    Header: 'Summary',
    Cell: NoteSummaryCell,
    accessor: (item) => item,
    width: 140
  },
  {
    id: 'content',
    Header: 'Note',
    Cell: ({ value }: { value?: string }) => {
      return <LargeDescriptionCell lineClamp={2} text={value || '--'} />;
    }
  },
  {
    width: 160,
    id: 'created_by',
    Header: 'Created By',
    Cell: NoteAuthorCell,
    accessor: (item): NoteAuthorCellValue => ({
      author: item.created_by,
      timestamp: item.created_at
    })
  }
];
