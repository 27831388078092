import React from 'react';

import { StyleSheet, useStyles, border, text } from '@rexlabs/styling';

import { GenericEvent } from 'view/components/inputs/types';

const defaultStyles = StyleSheet({
  container: {
    width: 'fit-content',
    height: '4rem',

    borderRadius: ({ token }) => token('border.radius.m'),
    overflow: 'hidden',
    display: 'flex',
    ...border.styles({
      all: {
        width: 'thin',
        color: 'container.static.medium'
      }
    })
  },
  switch: {
    width: '4rem',

    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    cursor: 'pointer',
    ...text.styles({
      fallback: 'normal.semibold'
    })
  },
  selected: {
    backgroundColor: ({ token }) =>
      token('color.container.interactive.selected')
  }
});

type PercentageTotalSwitchPercent = { id: 'percent'; label: 'Percent' };
type PercentageTotalSwitchAmount = { id: 'amount'; label: 'Amount' };

type PercentageTotalSwitchValue =
  | PercentageTotalSwitchPercent
  | PercentageTotalSwitchAmount;

export interface PercentageTotalSwitchProps {
  value?: PercentageTotalSwitchValue;
  onChange: (e: GenericEvent<PercentageTotalSwitchValue>) => void;
  onBlur: (e: GenericEvent<PercentageTotalSwitchValue>) => void;
}

export function PercentageTotalSwitch({
  value,
  onChange,
  onBlur
}: PercentageTotalSwitchProps) {
  const s = useStyles(defaultStyles);
  return (
    <div {...s('container')}>
      <div
        {...s('switch', {
          selected: value?.id === 'percent'
        })}
        onClick={() => {
          onChange({
            target: { value: { id: 'percent', label: 'Percent' } }
          });
          onBlur({
            target: { value: { id: 'percent', label: 'Percent' } }
          });
        }}
      >
        %
      </div>
      <div
        {...s('switch', {
          selected: value?.id === 'amount'
        })}
        onClick={() => {
          onChange({
            target: { value: { id: 'amount', label: 'Amount' } }
          });
          onBlur({
            target: { value: { id: 'amount', label: 'Amount' } }
          });
        }}
      >
        $
      </div>
    </div>
  );
}
