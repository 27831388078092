import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import getPendingTrustJournalEntryStatusColor from 'src/modules/trust-journal-entries/utils/pending-trust-journal-entry-status-color';
import { getDaysFromNow } from 'utils/dates/dates';
import { useSessionState } from 'src/modules/common/hooks/session-state';

import { formatCurrency, pluralize } from 'utils/formatters';
import { Stat } from 'view/components/@luna/stat-block/stat-blocks';

export function useBankWithdrawalsStats(bankAccount) {
  const { t } = useTranslation();
  const sessionData = useSessionState();

  const stats = bankAccount?.trust_withdrawals_not_yet_banked?.by_type;
  return useMemo<Stat[] | undefined>(() => {
    if (!stats) {
      return;
    }

    return [
      {
        label: 'Last withdrawal',
        value: getDaysFromNow(bankAccount.financial_summary.last_withdrawal_at),
        intent: 'secondary'
      },
      ...(sessionData?.activeSilo?.country?.id === 'GBR'
        ? [
            {
              label: 'Faster Payments',
              value: formatCurrency(stats?.eft_fps?.amount ?? 0),
              valueTag: pluralize('withdrawal', stats?.eft_fps?.count, true),
              intent: getPendingTrustJournalEntryStatusColor(
                stats.eft_fps?.count
              )
            },
            {
              label: 'BACS',
              value: formatCurrency(stats?.eft_bacs?.amount ?? 0),
              valueTag: pluralize('withdrawal', stats?.eft_bacs?.count, true),
              intent: getPendingTrustJournalEntryStatusColor(
                stats.eft_bacs?.count
              )
            }
          ]
        : []),
      {
        label: t('banking.payment-methods.eft.label'),
        value: formatCurrency(stats?.eft?.amount ?? 0),
        valueTag: pluralize('withdrawal', stats?.eft?.count, true),
        intent: getPendingTrustJournalEntryStatusColor(stats.eft?.count)
      },
      {
        label: 'Cheques',
        value: formatCurrency(stats?.cheque?.amount ?? 0),
        valueTag: pluralize('withdrawal', stats?.cheque?.count, true),
        intent: getPendingTrustJournalEntryStatusColor(stats.cheque?.count)
      },
      ...(sessionData?.activeSilo?.country?.id === 'AUS'
        ? [
            {
              label: 'BPAY',
              value: formatCurrency(stats?.bpay?.amount ?? 0),
              valueTag: pluralize('withdrawal', stats?.bpay?.count, true),
              intent: getPendingTrustJournalEntryStatusColor(stats.bpay?.count)
            }
          ]
        : [])
    ];
  }, [stats]);
}
