import React from 'react';
import { RecordDialog } from 'view/components/record-screen/dialog/dialog';
import { DialogContentConfig } from 'view/components/record-screen/dialog/types';
import { RecordSubmitHandler } from 'view/components/record-screen/utils';
import { excludeEofyStatementBlock } from 'src/modules/statements/blocks/exclude-eofy-statement-block';
import WarningCircleIcon from 'view/components/icons/warning-circle';

const content: DialogContentConfig = [
  {
    id: 'exclude-content',
    label: 'Exclude statement',
    blocks: [excludeEofyStatementBlock]
  }
];

export interface ExcludeStatementForm {
  reason: string;
  should_exclude_on_ownership: boolean;
}

export interface StatementDisplayData {
  startDate?: string;
  endDate: string;
  ownershipName: string;
}

export interface ExcludeStatementDialogProps {
  onSubmit: (args: ExcludeStatementForm) => void;
  onClose?: () => void;
  statementData: StatementDisplayData;
}

export function ExcludeEofyStatementDialog({
  onSubmit,
  onClose,
  statementData
}: ExcludeStatementDialogProps) {
  const handleSubmit: RecordSubmitHandler<ExcludeStatementForm> = async ({
    values
  }) => {
    await onSubmit(values);
    onClose?.();
  };

  return (
    <RecordDialog
      onClose={onClose}
      data={statementData}
      TitleIcon={WarningCircleIcon}
      title='Exclude statement'
      handleSubmit={handleSubmit}
      content={content}
      submitLabel='Exclude statement'
      size={'s'}
    />
  );
}
