export const PORTFOLIOS_ROUTES = {
  PORTFOLIOS_LIST: {
    config: {
      path: '/portfolios',
      Component: require('./screens/portfolios-list').PortfoliosList
    },
    CREATE: {
      config: {
        path: '/portfolios/create',
        Component: require('./dialogs/create-portfolio-dialog')
          .CreatePortfolioDialog
      }
    }
  },
  PORTFOLIOS_DETAILS: {
    config: {
      path: '/portfolio/:portfolioId',
      Component: require('./screens/portfolio-details').PortfolioDetailsScreen
    }
  }
};
