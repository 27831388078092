import React from 'react';
import { useStyles, StyleSheet } from '@rexlabs/styling';
import { Semibold } from '@rexlabs/text';
import { lowerCase } from 'lodash';
import {
  TitleBlock,
  TitleBlockProps
} from 'view/components/cards/title/title-block';
import { RecordObject, RecordTypes } from 'data/models/types';
import { getRecordTitle } from 'utils/records/get-record-title';
import { formatDate } from 'utils/dates/format';
import { propertiesModel } from 'data/models/entities/properties';
import { useGetPrimaryRecordActionGroup } from 'view/hooks/actions/use-get-primary-record-action-group';
import { useGetAddDocumentsAction } from 'src/modules/documents/actions/use-get-add-documents-action';
import { useGetAddNotesAction } from 'src/modules/tasks/common/actions/get-add-notes-action';
import { useGetCreateEmailAction } from 'src/modules/communications/messages/hooks/action-declarations/use-get-create-email-action';
import { useGetToggleClosedAction } from 'src/modules/tasks/common/hooks/action-declarations/use-get-toggle-closed-action';
import { useTaskStatusTag } from 'src/modules/tasks/common/hooks/use-task-status-tag';
import MoveOutIcon from 'view/components/icons/move-out';
import { TagConfig } from 'view/components/@luna/title-block';
import { useGetCreateSMSAction } from 'src/modules/communications/messages/hooks/action-declarations/use-get-create-sms-action';
import { MoveOut } from '../types/MoveOut';

const styles = StyleSheet({
  subText: {
    color: ({ token }) => token('color.textStyle.body.subtext')
  },
  body: {
    color: ({ token }) => token('color.textStyle.body.default')
  },
  closedAt: {
    color: ({ token }) => token('color.textStyle.body.default'),
    fontWeight: ({ token }) => token('typography.weight.semibold')
  }
});

const type = RecordTypes.TaskMoveOut;

type MoveOutRecordObject = Extract<RecordObject, { type: 'task_move_out' }>;

type MoveOutTitleBlockProps = {
  moveOut: MoveOut;
};

export function MoveOutTitleBlock({ moveOut }: MoveOutTitleBlockProps) {
  const s = useStyles(styles);
  const getPrimaryRecordActionGroup = useGetPrimaryRecordActionGroup();
  const getToggleClosedAction = useGetToggleClosedAction();
  const getCreateEmailAction = useGetCreateEmailAction(propertiesModel);
  const getCreateSMSAction = useGetCreateSMSAction(propertiesModel);
  const getAddDocumentsAction = useGetAddDocumentsAction();
  const getAddNotesAction = useGetAddNotesAction();

  const title = getRecordTitle({
    type,
    object: moveOut
  } as MoveOutRecordObject);
  const actions = [
    ...getPrimaryRecordActionGroup('task_move_out', moveOut.id),
    {
      label: 'Actions',
      actions: [
        getCreateEmailAction(moveOut!.property),
        getCreateSMSAction(moveOut!.property),
        getAddDocumentsAction({ data: moveOut! }),
        getAddNotesAction({ data: moveOut! }),
        getToggleClosedAction(moveOut!)
      ]
    }
  ];

  const statusTag: TagConfig = useTaskStatusTag(moveOut);
  const tags: TagConfig[] = [statusTag];

  if (!moveOut) {
    return null;
  }

  const subTitles: TitleBlockProps['subTitles'] = moveOut?.details
    ?.move_out_date
    ? [
        {
          Label: () => (
            <div {...s('subText')}>
              moving out from{' '}
              <Semibold {...s('body')}>
                {moveOut?.property?.address_string}
              </Semibold>{' '}
              on {formatDate(moveOut.details!.move_out_date!)}
            </div>
          )
        }
      ]
    : [];

  const closed_at = moveOut.closed_at;

  return (
    <TitleBlock
      title={title}
      subTitles={subTitles}
      type='move out'
      Icon={() => <MoveOutIcon size='xl' />}
      actions={actions}
      tags={closed_at ? [] : tags}
      subText={
        closed_at ? (
          <div>
            <span>This move out task has been closed with the status </span>
            <span {...s('closedAt')}>
              {lowerCase(moveOut.status?.label)}
            </span>{' '}
            on <span {...s('closedAt')}>{formatDate(closed_at)}</span>
          </div>
        ) : undefined
      }
    />
  );
}
