/*
|-------------------------------------------------------------------------------
| Model Generator for Restful API
|-------------------------------------------------------------------------------
|
| Although this has configured a @rexlabs/model-generator for restful entity
| & value list endpoints, it can be used to generate models that *don't* do
| anything with an API.
|
*/

import ReduxModelGenerator, {
  ModelGeneratorOptions,
  EntityModelConfig,
  APIConfig
} from '@rexlabs/model-generator';
import { api } from 'utils/api/api-client';
import { merge, pick } from 'lodash';

const defaultConfig: ModelGeneratorOptions = {
  entities: {
    api: {
      createItem: (type, data) => {
        const config = {
          params: pick(data, ['include'])
        };

        return api.post(`/${type}`, data, config);
      },
      fetchList: (type, params) => api.get(`/${type}`, params),
      fetchItem: (type, params, id) => api.get(`/${type}/${id}`, params),
      updateItem: (type, data, id) => {
        const config = {
          params: pick(data, ['include'])
        };

        return api.patch(`/${type}/${id}`, data, config);
      },
      trashItem: (type, args, id) => api.delete(`/${type}/${id}`, args),
      deleteItem: (type, args, id) => api.delete(`/${type}/${id}`, args),
      bulkUpdateItems: (type, args) => api.put(`/${type}`, args),
      bulkTrashItems: (type, args) => api.delete(`/${type}`, args),
      bulkDeleteItems: (type, args) => api.delete(`/${type}`, args)
    }
  },
  valueLists: {
    api: {
      fetch: (type) => api.get(`lists/${type}`)
    }
  }
};

type PartialEntityModelConfig = Partial<Omit<EntityModelConfig, 'api'>> & {
  api?: Partial<APIConfig>;
};

export type GeneratorConfig = {
  entities?: PartialEntityModelConfig;
  valueLists?: PartialEntityModelConfig;
};

export class Generator<T, A = unknown> extends ReduxModelGenerator<T, A> {
  constructor(type: string, config: GeneratorConfig = {}) {
    super(type, merge({}, defaultConfig, config));
  }
}
