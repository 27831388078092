import { NormalisedItem } from '@rexlabs/select';
import { isString } from 'lodash';

// NOTE: This was created as the existing normaliseValueListItem changes the casing of the label
export function normaliseAddressState(
  item:
    | string
    | {
        id: string;
        label: string;
      }
): NormalisedItem {
  if (isString(item)) {
    return {
      id: item,
      label: item
    };
  }
  return {
    id: item.id,
    label: item.label
  };
}
