import { NormalisedItem } from '@rexlabs/select';
import { PropertyOwnership } from 'src/modules/property-ownerships/types/property-ownership-types';
import Icons from '@rexlabs/icons';
import * as React from 'react';
import { useStyles, StyleSheet } from '@rexlabs/styling';
import { propertyOwnershipStatusMap } from 'src/modules/properties/maps/property-ownership-maps';

export const styles = StyleSheet({
  average: {
    color: ({ token }) => token('palette.yellow.600')
  },
  good: {
    color: ({ token }) => token('palette.green.600')
  },
  bad: {
    color: ({ token }) => token('palette.red.600')
  }
});

const CircleFilledIcon = (status) => {
  const s = useStyles(styles);

  const type = propertyOwnershipStatusMap[status.id];
  return <Icons.CircleFilled {...s(type)} />;
};

export function normalisePropertyOwnership(
  item: PropertyOwnership
): NormalisedItem {
  return {
    id: item?.id,
    label: item?.ownership?.display_name,
    description: item?.record_reference,
    type: item?.status.label,
    Icon: () => CircleFilledIcon(item?.status)
  };
}
