import { Quri, toQuri } from '@rexlabs/quri';
import { api } from 'utils/api/api-client';

export async function fetchWorkOrderSuggestedItems(filters?: Quri[]) {
  const queryParams = new URLSearchParams();
  queryParams.set(
    'q',
    toQuri([
      ...(filters ?? []),
      {
        field: 'type_id',
        op: 'eq',
        value: 'task_work_order'
      }
    ])
  );

  const invoicesResponse = await api.get(`/tasks`, queryParams);

  return invoicesResponse.data ?? [];
}
