import React from 'react';

import { TagCell } from '@rexlabs/table';
import { Columns } from 'view/components/table';
import { StatusTag } from 'src/modules/common/components/status-tag';
import { propertyVacancyStatusMap } from 'src/modules/properties/models/property-model';
import { auditableColumns } from 'src/modules/common/auditable/auditable-columns';
import { useFeatureFlags } from 'view/components/@luna/feature-flags';
import { FLAGS } from 'utils/feature-flags';

export const usePropertyOverviewColumns = (): Columns => {
  const { hasFeature } = useFeatureFlags();

  return [
    {
      id: 'record_reference',
      Header: 'Reference',
      width: 140
    },

    {
      id: 'display_name',
      Header: 'Property'
    },
    ...(hasFeature(FLAGS.SERVICE_PACKAGES)
      ? [
          {
            id: 'service_package',
            Header: 'Service package',
            width: 250,
            accessor: (item) =>
              item.active_property_ownership?.service_package?.name
          }
        ]
      : []),
    {
      id: 'active_property_agreement',
      Header: 'Active ownership',
      accessor: (item) =>
        item.active_property_ownership?.ownership?.display_name,
      width: 300
    },
    {
      id: 'active_property_tenancy',
      Header: 'Active tenancy',
      accessor: (item) => item.active_property_tenancy?.tenancy?.display_name,
      width: 300
    },
    {
      id: 'statuses',
      accessor: (item) => item,
      Header: 'Tenancy Status',
      width: 300,
      toCsv: (item) => item.vacancy_status?.label,
      Cell: (table) => {
        const {
          cell: { value }
        } = table;
        return (
          <TagCell>
            <StatusTag
              status={value.vacancy_status}
              map={propertyVacancyStatusMap}
            />
          </TagCell>
        );
      }
    },
    ...auditableColumns
  ];
};
