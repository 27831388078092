import React from 'react';
import { Field } from '@rexlabs/form';
import { BlockConfig } from 'view/components/record-screen/types';
import { Grid } from 'view/components/@luna/form/grid';
import { ServiceCategorySelect } from 'view/components/inputs/selects/service-category';

export const workDetailsCreateBlock: BlockConfig = {
  id: 'work-details',
  title: 'Work details',
  Edit: () => {
    return (
      <Grid columns={2}>
        <Field
          id='type'
          name='details.type'
          label='Maintenance type'
          Input={ServiceCategorySelect}
        />
      </Grid>
    );
  }
};
