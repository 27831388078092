import { useDialog } from '@rexlabs/dialog';

import { SingleActionDeclaration } from 'src/modules/common/actions/types/action-declaration-types';

import { InspectionReportAddAreasDialog } from '../../dialogs/inspection-report-add-rooms';
import { InspectionTask } from '../../types/InspectionTask';
import { useAddRoomsToInspectionReportSubmitHandler } from '../use-add-rooms-to-inspection-report-submit-handler';
import { mapRoomsToManageRoomsForm } from '../../mappers/map-rooms-to-manage-rooms-form';

export function useGetManageInspectionReportRoomsAction(
  inspectionTask: InspectionTask
) {
  const { open } = useDialog(InspectionReportAddAreasDialog);

  const addRoomsToInspectionReportSubmitHandler = useAddRoomsToInspectionReportSubmitHandler(
    inspectionTask
  );

  return (): SingleActionDeclaration => {
    return {
      id: 'manage-inspection-report-areas',
      intent: 'primary',
      group: 'inspection',
      label: 'Manage areas',
      handleAction: () => {
        open({
          title: 'Manage areas',
          initialValues: {
            inspection_report_rooms: mapRoomsToManageRoomsForm(
              inspectionTask?.details?.rooms?.data
            )
          },
          submitHandler: addRoomsToInspectionReportSubmitHandler
        });
      }
    };
  };
}
