import { api } from 'utils/api/api-client';
import { useQuery } from 'react-query';
import { ReportItem } from '../types/report-item';
import { ReportCategory } from '../types/report-category';

export const useGetReportItems = (category: ReportCategory | null = null) => {
  const { data } = useQuery(['list-reports'], {
    queryFn: () => {
      return api.get<ReportItem[]>('/static-reports');
    },
    select: (data) => data.data,
    staleTime: Infinity
  });

  return (data ?? [])
    .filter((item) => (category ? item.category.id === category : true))
    .map((report) => ({
      id: report.id,
      report_name: report.label
    }));
};
