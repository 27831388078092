import { RecordTable } from 'view/components/table';
import { Tab } from 'view/components/table/tabbed';

import { getKeySetListQuery } from '../queries/key-list-query';
import { useGetTableRowActions } from './use-get-table-row-actions';

export function useCommonTabProps(): Tab {
  const getTableRowActions = useGetTableRowActions();

  return {
    id: 'keys-table',
    Table: RecordTable,
    hideToolbar: true,
    getQuery: getKeySetListQuery,
    getActionMenuItems: ({ item }) => getTableRowActions({ item })
  };
}
