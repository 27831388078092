import React from 'react';
import { uniqBy } from 'lodash';

import { DialogProps, useDialog } from '@rexlabs/dialog';
import { Body, Bold } from '@rexlabs/text';
import { ButtonGroup, OutlineButton, PrimaryButton } from '@rexlabs/button';
import Box from '@rexlabs/box';
import { StyleSheet, useStyles } from '@rexlabs/styling';

import { Message } from 'src/modules/communications/messages/types/Message';
import { FixMessageIssuesRecordDialog } from 'src/modules/communications/messages/dialogs/fix-message-issues-record-dialog';
import { useEstimateMessagesAndSend } from 'src/modules/communications/messages/hooks/use-estimate-messages-and-send';

import { pluralize } from 'utils/formatters';

import InfoCircleIcon from 'view/components/icons/info';
import { Dialog } from 'view/components/@luna/dialog';

import { getMessageCountByChannelType } from '../utils/get-message-count-by-channel-type';
import { MessageIssue } from '../../common/types/message-issue';

export interface ContactDetailsMissingDialogProps extends DialogProps {
  issues: MessageIssue[];
  messages: Message[];
}

const defaultStyles = StyleSheet({
  list: {
    listStyleType: 'disc',
    paddingLeft: ({ token }) => token('spacing.l')
  }
});

export function MessageDetailsMissingDialog({
  onClose,
  issues,
  messages
}: ContactDetailsMissingDialogProps) {
  const s = useStyles(defaultStyles);

  const { open: openFixIssuesDialog } = useDialog(FixMessageIssuesRecordDialog);

  const estimateMessagesAndSend = useEstimateMessagesAndSend();

  const {
    validMessagesCount,
    validEmails,
    validSMSs,
    emailsCount,
    smsCount
  } = getValidMessageInfo({
    messages,
    issues
  });

  const contactIssues = issues.filter((issue) => issue.contact);
  const documentIssues = issues.filter((issue) => issue.missing_document_type);
  const complianceEntryIssues = issues.filter(
    (issue) => issue.missing_compliance_type
  );

  const deduplicatedContactIssues = uniqBy(
    contactIssues,
    (issue) => `${issue?.contact?.id} ${issue.message?.forced_channel_type?.id}`
  );

  const deduplicatedDocumentTypeIssues = uniqBy(
    documentIssues,
    (issue) =>
      `${issue?.message?.context?.id} ${issue?.missing_document_type?.id}`
  );

  const deduplicatedComplianceEntryIssues = uniqBy(
    complianceEntryIssues,
    (issue) =>
      `${issue?.message?.context?.id} ${issue?.missing_compliance_type?.id}`
  );

  return (
    <Dialog
      title='Message details missing'
      size='s'
      TitleIcon={InfoCircleIcon}
      onClose={onClose}
    >
      <Box>
        <>
          <Body>
            There are some issues which are preventing these messages from being
            sent.
          </Body>
          <Body>
            <ul {...s('list')}>
              {emailsCount > 0 && (
                <li>
                  <Bold>
                    {validEmails.length} of{' '}
                    {pluralize('messages', emailsCount, true)}
                  </Bold>{' '}
                  {pluralize('have', emailsCount)} all recipients with a valid
                  email and message content
                </li>
              )}

              {smsCount > 0 && (
                <li>
                  <Bold>
                    {validSMSs.length} of{' '}
                    {pluralize('messages', smsCount, true)}
                  </Bold>{' '}
                  {pluralize('have', smsCount)} all recipients with a valid
                  phone number and message content
                </li>
              )}
            </ul>
          </Body>
        </>
        <ButtonGroup end>
          {validMessagesCount > 0 && (
            <OutlineButton
              onClick={async () => {
                await estimateMessagesAndSend([...validEmails, ...validSMSs]);
                onClose?.();
              }}
            >
              Send valid messages and fix issues later
            </OutlineButton>
          )}
          <PrimaryButton
            onClick={() => {
              return openFixIssuesDialog({
                data: {
                  messageIssues: [
                    ...deduplicatedContactIssues,
                    ...deduplicatedDocumentTypeIssues,
                    ...deduplicatedComplianceEntryIssues
                  ]
                },
                onSubmit: async () => {
                  await estimateMessagesAndSend(messages);
                  onClose?.();
                },
                onCancel: onClose,
                submitLabel: 'Send message now'
              });
            }}
          >
            Fix issues and send
          </PrimaryButton>
        </ButtonGroup>
      </Box>
    </Dialog>
  );
}

function getValidMessageInfo({
  messages,
  issues
}: ContactDetailsMissingDialogProps) {
  const invalidMessagesObj = issues.reduce((messagesObj, issue) => {
    if (issue.message?.id) {
      messagesObj[issue.message.id] = issue.message;
    }
    return messagesObj;
  }, {} as Record<string, Message>);

  const invalidMessages = Object.values(invalidMessagesObj);

  const validMessagesCount = messages.length - invalidMessages.length;

  // emails
  const emailsCount = getMessageCountByChannelType(
    Object.values(messages),
    'email'
  );

  const validEmailMessages = messages.filter(
    (message) =>
      !invalidMessagesObj[message.id] &&
      message.forced_channel_type.id === 'email'
  );

  // sms
  const smsCount = messages.length - emailsCount;

  const validSMSMessages = messages.filter(
    (message) =>
      !invalidMessagesObj[message.id] &&
      message.forced_channel_type.id === 'sms'
  );

  return {
    validMessagesCount,
    emailsCount,
    validEmails: validEmailMessages,
    smsCount,
    validSMSs: validSMSMessages
  };
}
