import { valueListFactory } from 'src/lib/testing/factories/value-list-factory';
import { mapTaskFormDataToTaskCreateRequest } from '../../common/mappers/map-task-form-data-to-task-create-request';
import { TaskCreateRequest } from '../../common/types/TaskCreateRequest';
import { TodoFormData } from '../types/TodoFormData';

export function mapTodoFormDataToTaskCreateRequest(
  formData: Partial<TodoFormData>
): Partial<TaskCreateRequest> {
  // map the base values for the task
  const base = mapTaskFormDataToTaskCreateRequest(formData);

  // do any sub-type specific stuff here
  const { relates_to } = formData;
  const details = {
    relates_to,
    type: { id: 'general' }
  };

  return { ...base, type: valueListFactory('todo'), details };
}
