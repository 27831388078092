import React from 'react';
import { BlockConfig } from 'view/components/record-screen/types';
import { Value } from 'view/components/values';
import { Field } from '@rexlabs/form';
import { Dropdown } from '@rexlabs/select-old';
import { NumberInput } from '@rexlabs/text-input';
import { CentAmountInput } from 'view/components/inputs/cent-amount';
import { get } from 'lodash';
import { useStyles, StyleSheet, padding } from '@rexlabs/styling';
import LoadingState from 'view/components/states/compact/loading';
import { normaliseTaxType } from 'utils/normaliser/tax-type';
import { OutlineButton } from '@rexlabs/button';
import { SimplePercentageInput } from 'view/components/inputs/simple-percentage-input';
import { Grid } from 'view/components/@luna/form/grid';

import { TaxIncludedSelect } from 'view/components/inputs/selects/tax-included-select';
import { TaxTypeSelect } from 'view/components/inputs/selects/tax-type';
import { formatDynamicAmount } from '../utils/format-dynamic-amount';
import { useGetAgencyFeeTriggers } from '../hooks/use-get-agency-fee-triggers';
import { EntityFee } from '../dialogs/edit-entity-fee';
import { isEntityValuesSameAsAgencyFee } from '../utils/is-entity-values-same-as-agency-fee';
import { formatTax } from '../utils/format-tax';

const defaultStyles = StyleSheet({
  valueBlock: {
    ...padding.styles({
      all: 'l'
    }),
    borderRadius: ({ token }) => token('border.radius.l'),
    backgroundColor: ({ token }) =>
      token('color.container.static.information.default')
  }
});

export const entityFeeBlock: BlockConfig<EntityFee> = {
  id: 'entityFee',
  title: 'Entity fee',
  validate: {
    definitions: {
      'fee_amount.type': { name: 'Type', rules: 'required' },
      'fee_amount.amount': { name: 'Amount', rules: 'required' },
      is_tax_included: { name: 'tax included', rules: 'required' }
    }
  },
  Edit: function EntityFeeEdit({ data, values, setFieldValue }) {
    const s = useStyles(defaultStyles);
    const { isLoading, triggers } = useGetAgencyFeeTriggers();

    if (isLoading) {
      return <LoadingState />;
    }

    const triggerType = triggers.find(
      (trigger) => trigger.id === data!.agency_fee!.trigger_type.id
    );
    const availableFeeAmountRateTypes = triggerType?.rate_types;

    return (
      <div>
        <div {...s('valueBlock')}>
          <Grid columns={3}>
            <Value
              label='When is the fee incurred?'
              value={values.agency_fee.trigger_type.label}
            />
            <Value
              label='Agency account'
              value={values.agency_fee.agency_chart_of_accounts_account.name}
            />
            <Value
              label='Ownership account'
              value={
                values.agency_fee.ownership_chart_of_accounts_account?.name
              }
            />
          </Grid>
        </div>
        <div className='mt-xl'>
          <Grid columns={4}>
            <Field
              label='Amount'
              name='fee_amount.type'
              Input={Dropdown}
              inputProps={{
                normaliser: normaliseTaxType,
                items: availableFeeAmountRateTypes
              }}
              onChange={() => {
                setFieldValue?.('fee_amount.amount', null);
              }}
            />
            {get(values, 'fee_amount.type.id') === 'percent' && (
              <Field
                name='fee_amount.amount'
                label='&nbsp;'
                Input={SimplePercentageInput}
              />
            )}
            {get(values, 'fee_amount.type.id') === 'amount' && (
              <Field
                name='fee_amount.amount'
                label='&nbsp;'
                Input={CentAmountInput}
              />
            )}
            {get(values, 'fee_amount.type.id') === 'weeks_of_rent' && (
              <Field
                name='fee_amount.amount'
                label='&nbsp;'
                Input={NumberInput}
              />
            )}

            <Field
              name='is_tax_included'
              label='Tax'
              Input={TaxIncludedSelect}
            />
            <Field
              name='tax_type'
              label='&nbsp;'
              Input={TaxTypeSelect}
              optional={false}
            />
          </Grid>
        </div>

        <div className='mt-xl'>
          <OutlineButton
            isDisabled={isEntityValuesSameAsAgencyFee(
              data!.agency_fee!,
              values
            )}
            onClick={() => {
              setFieldValue?.('fee_amount', data!.agency_fee!.fee_amount);
              setFieldValue?.(
                'is_tax_included',
                data!.agency_fee!.is_tax_included
              );
              setFieldValue?.('tax_type', data!.agency_fee!.tax_type);
            }}
          >
            Reset to default:
            <b className='ml-xxs'>
              {formatDynamicAmount(data!.agency_fee!.fee_amount)}{' '}
              {formatTax(data!.agency_fee!)}
            </b>
          </OutlineButton>
        </div>
      </div>
    );
  }
};
