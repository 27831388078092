import * as React from 'react';

import { downloadFile } from 'utils/files';
import ComplianceIcon from 'view/components/icons/compliance';
import { IconActionCell } from 'src/modules/common/components/cells/icon-action-cell';

export function DownloadCell({ value }: { value: any; column: any }) {
  const url =
    // TODO: get correct structure from BE, this is a very uneducated guess
    value?.file?.download_url;

  return (
    <div data-testid={'download-cell'}>
      <IconActionCell Icon={ComplianceIcon} onClick={() => downloadFile(url)} />
    </div>
  );
}
