import React from 'react';
import { Property } from 'src/modules/properties/types/property-types';
import { RecordFlag } from 'view/components/record-flags/record-flag';
import { EntryLink } from '../components/entry-link';
import { EntryList } from '../components/entry-list';
import { EmptySection } from '../empty/base';

export interface PropertySectionProps {
  property: Property | 'empty';
}

export function PropertySection({ property }: PropertySectionProps) {
  if (property === 'empty') {
    return (
      <EmptySection headline='' buttonLabel=''>
        There is no property related to this record.
      </EmptySection>
    );
  }

  return (
    <EntryList>
      <EntryLink type='property' id={property.id}>
        <RecordFlag record={property} recordType='property' />
      </EntryLink>
    </EntryList>
  );
}
