import React from 'react';

import { TitleBlock } from 'view/components/cards/title/title-block';

import { RecordObject, RecordType } from 'data/models/types';

import { getRecordTitle } from 'utils/records/get-record-title';
import { User } from 'src/modules/users/types/user-types';
import { TagConfig } from 'view/components/@luna/title-block';
import { titleBlockStatusTag } from 'src/modules/common/components/status-tag';
import { userStatusMap } from 'src/modules/users/maps/user-status-map';
import { useGetUserActions } from '../actions/use-get-user-actions';

const type: RecordType = 'user';

type UserTitleBlockProps = {
  user: User;
};

export function UserTitleBlock({ user }: UserTitleBlockProps) {
  const getUserActions = useGetUserActions();

  const title = getRecordTitle({ type, object: user } as RecordObject);
  const actions = getUserActions(user);
  const tags: TagConfig[] = [titleBlockStatusTag(user.status, userStatusMap)];

  return <TitleBlock title={title} type={type} actions={actions} tags={tags} />;
}
