import * as React from 'react';

import { NormalisedItem, Select } from '@rexlabs/select';
import { InputProps } from '@rexlabs/form';
import { ValueListValue } from 'data/models/types';

export type SendImmediatelySelectOption =
  | 'send_to_outbox_for_review'
  | 'send_immediately';

export const sendImmediatelySelectItems: ValueListValue<SendImmediatelySelectOption>[] = [
  { id: 'send_to_outbox_for_review', label: 'Send to outbox for review' },
  { id: 'send_immediately', label: 'Send immediately' }
];

const normaliser = (item: { id: string; label: string }): NormalisedItem =>
  item;

export function SendImmediatelySelect(props: InputProps) {
  return (
    <Select
      {...props}
      items={sendImmediatelySelectItems}
      normaliser={normaliser}
    />
  );
}
