import { ContactPaymentMethodMethod } from 'data/models/entities/contacts/payment-methods';

const paymentTypesWithReference: ContactPaymentMethodMethod[] = [
  'bpay',
  'direct_debit',
  'eft',
  'eft_iban',
  'eft_swift',
  'eft_bacs',
  'eft_fps'
];

const paymentTypesWithBranchCode: ContactPaymentMethodMethod[] = [
  'cheque',
  'eft',
  'eft_swift',
  'eft_bacs',
  'eft_fps'
];

const paymentTypesWithAccountCode: ContactPaymentMethodMethod[] = [
  'bpay',
  'cheque',
  'eft',
  'eft_iban',
  'eft_swift',
  'eft_bacs',
  'eft_fps'
];

/**
 * Use this util to find out what fields are needed for a withdrawal payment method.
 * It takes the payment method id, and then returns an object with boolean values for each field.
 */
export function getWithDrawalDetailsConfigFromMethodId(
  paymentMethodId?: ContactPaymentMethodMethod
) {
  const isReferenceNeeded =
    paymentMethodId && paymentTypesWithReference.includes(paymentMethodId);

  const isBranchCodeNeeded =
    paymentMethodId && paymentTypesWithBranchCode.includes(paymentMethodId);

  const isAccountCodeNeeded =
    paymentMethodId && paymentTypesWithAccountCode.includes(paymentMethodId);

  const isDrawerNeeded = paymentMethodId === 'cheque';
  const isCheckNumberNeeded = paymentMethodId === 'cheque';
  const isBankNeeded = paymentMethodId === 'cheque';

  return {
    isReferenceNeeded,
    isBranchCodeNeeded,
    isAccountCodeNeeded,
    isDrawerNeeded,
    isCheckNumberNeeded,
    isBankNeeded
  };
}
