import React, { useEffect } from 'react';
import { FieldArrayField } from '@rexlabs/form';
import { Bold } from '@rexlabs/text';

import { DialogLineItem } from 'src/modules/common/components/dialog-line-item';

import { DisbursementFrequency } from 'src/modules/disbursements/types/frequency';
import { ContactDisbursementPaymentMethod } from 'src/modules/disbursements/types/types';
import { Contact } from 'src/modules/contacts/types/contact-types';
import { PaymentMethodLineItem } from 'src/modules/disbursements/components/payment-method-line-item';

interface LineItemProps {
  field: FieldArrayField['field'];
  actions: FieldArrayField['actions'];
  index: number;
  values: {
    disbursement_frequency: DisbursementFrequency;
    disbursement_payment_methods: ContactDisbursementPaymentMethod[];
  };
  ownerContacts: Contact[];
}

export function PaymentMethodDialogLineItem({
  field,
  actions,
  index,
  values,
  ownerContacts
}: LineItemProps) {
  // When an owner is removed from the ownership, remove the payment method
  // for that owner. setFieldValue in a change handler didn't properly remove from the field array
  // so this is the best solution as far as I can tell.
  useEffect(() => {
    const currentDisbursementOwner =
      values.disbursement_payment_methods?.[index]?.contact;

    const matchingOwner = currentDisbursementOwner
      ? ownerContacts.find(
          (owner) => owner?.id === currentDisbursementOwner?.id
        )
      : true;

    if (!matchingOwner) {
      actions.remove();
    }
  }, [ownerContacts]);

  return (
    <DialogLineItem
      heading={<Bold>Disbursement method {index + 1}</Bold>}
      onRemove={actions.remove}
      data-testid='disbursement-payment-method'
    >
      <PaymentMethodLineItem
        values={values}
        name={field.name}
        contacts={ownerContacts}
      />
    </DialogLineItem>
  );
}
