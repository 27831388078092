import { useGetGenericWipAction } from 'view/hooks/actions/use-get-generic-wip-action';

export function useGetAddNewBankDepositAction() {
  const getGenericWipAction = useGetGenericWipAction();

  return () => {
    return getGenericWipAction({
      id: 'add-new-bank-deposit',
      group: 'trust-journal-entry',
      actionName: 'Add new bank deposit'
    });
  };
}
