import Box from '@rexlabs/box';
import { Field } from '@rexlabs/form';
import { Heading } from '@rexlabs/text';
import { NumberInput, NumberInputProps } from '@rexlabs/text-input';
import {
  LetterLayout,
  OffsetUnit
} from 'data/models/entities/communication/letter-layouts';
import React from 'react';
import { Grid } from 'view/components/@luna/form/grid';
import { BlockConfig } from 'view/components/record-screen/types';
import { ValueListSelect } from 'view/components/table/toolbar/inputs/value-list-select';
import { Value, ValuedValueProps } from 'view/components/values';

export const letterLayoutMarginsBlock: BlockConfig<LetterLayout> = {
  id: 'margins-and-layout',
  title: 'Margins and layout',
  validate: {
    definitions: {
      page_size: { name: 'page size', rules: 'required' },
      'print_offsets.unit': { name: 'offset unit', rules: 'required' },
      'print_offsets.address_width': {
        name: 'address width',
        rules: [
          'required',
          'min:0',
          'max_if:150,print_offsets.unit.id,mm',
          'max_if:5,print_offsets.unit.id,in'
        ]
      },
      'print_offsets.address_left': {
        name: 'address left',
        rules: [
          'required',
          'min:0',
          'max_if:150,print_offsets.unit.id,mm',
          'max_if:5,print_offsets.unit.id,in'
        ]
      },
      'print_offsets.address_top': {
        name: 'address top',
        rules: [
          'required',
          'min:0',
          'max_if:150,print_offsets.unit.id,mm',
          'max_if:5,print_offsets.unit.id,in'
        ]
      },
      'print_offsets.content_top_first_page': {
        name: 'content top first page',
        rules: [
          'required',
          'min:0',
          'max_if:150,print_offsets.unit.id,mm',
          'max_if:5,print_offsets.unit.id,in'
        ]
      },
      'print_offsets.content_top_subsequent_pages': {
        name: 'content top subsequent pages',
        rules: [
          'required',
          'min:0',
          'max_if:150,print_offsets.unit.id,mm',
          'max_if:5,print_offsets.unit.id,in'
        ]
      },
      'print_offsets.content_left': {
        name: 'content left',
        rules: [
          'required',
          'min:0',
          'max_if:150,print_offsets.unit.id,mm',
          'max_if:5,print_offsets.unit.id,in'
        ]
      },
      'print_offsets.content_right': {
        name: 'content right',
        rules: [
          'required',
          'min:0',
          'max_if:150,print_offsets.unit.id,mm',
          'max_if:5,print_offsets.unit.id,in'
        ]
      },
      'print_offsets.content_bottom': {
        name: 'content bottom',
        rules: [
          'required',
          'min:0',
          'max_if:150,print_offsets.unit.id,mm',
          'max_if:5,print_offsets.unit.id,in'
        ]
      }
    }
  },
  View: ({ data }) => {
    const ValueWithUnit = React.memo((props: ValuedValueProps) => (
      <Value
        {...props}
        value={`${props.value}${data?.print_offsets.unit.id}`}
      />
    ));

    return (
      <>
        <Grid columns={3}>
          <Value label='Page size' value={data?.page_size.label} />
          <Value label='Offset unit' value={data?.print_offsets.unit.label} />
        </Grid>

        <Heading level={3}>Address</Heading>
        <Grid columns={3}>
          <ValueWithUnit
            label='Width'
            value={data?.print_offsets.address_width}
          />
          <ValueWithUnit
            label='Left margin'
            value={data?.print_offsets.address_left}
          />
          <ValueWithUnit
            label='Top margin'
            value={data?.print_offsets.address_top}
          />
        </Grid>

        <Heading level={3}>Content</Heading>
        <Grid columns={3}>
          <ValueWithUnit
            label='Top margin (first page)'
            value={data?.print_offsets.content_top_first_page}
          />
          <ValueWithUnit
            label='Top margin (subsequent pages)'
            value={data?.print_offsets.content_top_first_page}
          />
          <ValueWithUnit
            label='Left margin'
            value={data?.print_offsets.content_left}
          />
          <ValueWithUnit
            label='Right margin'
            value={data?.print_offsets.content_right}
          />
          <ValueWithUnit
            label='Bottom margin'
            value={data?.print_offsets.content_bottom}
          />
        </Grid>
      </>
    );
  },
  Edit: ({ values, data }) => {
    const unitId: OffsetUnit =
      values?.print_offsets?.unit?.id ?? data?.print_offsets.unit.id;
    // use the edited value if it is set, otherwise the existing state
    const sharedInputProps: Partial<NumberInputProps> = {
      suffix: unitId
    };

    return (
      <Box gap={24} flexDirection={'column'}>
        <Grid columns={3}>
          <Field
            label='Page size'
            name='page_size'
            Input={ValueListSelect}
            inputProps={{ type: 'communication_letter_size' }}
          />
          <Field
            label='Offset unit'
            name='print_offsets.unit'
            Input={ValueListSelect}
            inputProps={{ type: 'communication_offset_unit' }}
          />
        </Grid>

        <Box>
          <Heading level={3}>Address</Heading>
        </Box>
        <Grid columns={3}>
          <Field
            label='Width'
            Input={NumberInput}
            name='print_offsets.address_width'
            inputProps={sharedInputProps}
          />
          <Field
            label='Left margin'
            Input={NumberInput}
            name='print_offsets.address_left'
            inputProps={sharedInputProps}
          />
          <Field
            label='Top margin'
            Input={NumberInput}
            name='print_offsets.address_top'
            inputProps={sharedInputProps}
          />
        </Grid>

        <Box>
          <Heading level={3}>Content</Heading>
        </Box>
        <Grid columns={3}>
          <Field
            label='Top margin (first page)'
            Input={NumberInput}
            name='print_offsets.content_top_first_page'
            inputProps={sharedInputProps}
          />
          <Field
            label='Top margin (subsequent pages)'
            Input={NumberInput}
            name='print_offsets.content_top_subsequent_pages'
            inputProps={sharedInputProps}
          />
          <Field
            label='Left margin'
            Input={NumberInput}
            name='print_offsets.content_left'
            inputProps={sharedInputProps}
          />
          <Field
            label='Right margin'
            Input={NumberInput}
            name='print_offsets.content_right'
            inputProps={sharedInputProps}
          />
          <Field
            label='Bottom margin'
            Input={NumberInput}
            name='print_offsets.content_bottom'
            inputProps={sharedInputProps}
          />
        </Grid>
      </Box>
    );
  }
};
