import { SelectNormalised } from '@rexlabs/select-old';
import { Property } from 'src/modules/properties/types/property-types';

export function normaliseProperty(item: Property): SelectNormalised {
  return {
    value: item,
    label: item.address_string,
    type: 'property'
  };
}
