import React from 'react';
import { BlockConfig } from 'view/components/record-screen/types';
import { Column, Grid } from 'view/components/@luna/form/grid';
import { Value } from 'view/components/values';
import { DateValue } from 'view/components/values/date';
import { AccountingJournalEntry } from 'src/modules/account-journal-entries/types/account-journal-entry-types';
import { RecordLinkValue } from 'view/components/values/record-link';
import { RecordObject } from 'data/models/types';
import { useFeatureFlags } from 'view/components/@luna/feature-flags';
import { AccountingJournalEntrySourceCell } from '../components/accounting-journal-entry-source-cell';

export function useAccountingJournalEntryDetailsBlock(): BlockConfig<AccountingJournalEntry> {
  const { hasFeature } = useFeatureFlags();

  const hasManualAccountingJournalEntriesFeature = hasFeature(
    'manual_accounting_journal_entries'
  );

  return {
    id: 'details',
    title: 'Details',
    View: ({ data }) => {
      const aje = data!;

      const DescriptionField = (
        <Value label='Description' value={data?.description} />
      );

      const SourceField = (
        <Value
          label='Source'
          value={AccountingJournalEntrySourceCell({
            accountingJournalEntry: aje
          })}
        />
      );

      return hasManualAccountingJournalEntriesFeature ? (
        <Grid columns={2}>
          <RecordLinkValue
            label='Folio'
            record={
              {
                type: aje.object_type,
                object: aje.object
              } as RecordObject
            }
          />
          {SourceField}
          <DateValue label='Date' value={data?.date_of} />
          <Value
            label='Is tax included?'
            value={data?.is_tax_included ? 'Yes' : 'No'}
          />
          <Column width={2}>{DescriptionField}</Column>
          <Value label='Created by' value={data?.created_by?.name} />
          <DateValue label='Created on' value={data?.created_at} />
        </Grid>
      ) : (
        <Grid columns={2}>
          {DescriptionField}
          <DateValue label='Date of' value={data?.date_of} />
          <RecordLinkValue
            label='Relates to'
            record={
              {
                type: aje.object_type,
                object: aje.object
              } as RecordObject
            }
          />
          {SourceField}
          <Value label='Type' value={data?.type.label} />
          <Value
            label='Is tax included'
            value={data?.is_tax_included ? 'Yes' : 'No'}
          />
        </Grid>
      );
    }
  };
}
