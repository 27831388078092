import React from 'react';

import { Field } from '@rexlabs/form';

import { Contact } from 'src/modules/contacts/types/contact-types';

import EmptyRecord from 'src/assets/illustrations/empty-record.svg';

import { BlockConfig } from 'view/components/record-screen/types';
import { EmptyCard } from 'view/components/record-screen/cards/empty-card';
import { Message } from 'view/components/@luna/message';
import { ImagePreview } from 'view/components/image-preview';
import { PreviewFileUploadInputWithErrorHandling } from 'view/components/inputs/file-upload-input/preview-file-upload-input-with-error-handling';

export const agencyLogo: BlockConfig<Contact> = {
  id: 'agency-logo',
  title: 'Agency logo',
  View: ({ data }) => <ImagePreview src={data?.logo?.inline_url || ''} />,
  Empty: ({ onEditClick }) => (
    <EmptyCard>
      <Message
        title='No Agency logo added'
        Illustration={EmptyRecord}
        actions={[
          {
            label: 'Upload logo',
            intent: 'primary',
            handleAction: onEditClick
          }
        ]}
      >
        <div>
          Upload to display your agency logotype on receipt and statements.
        </div>
        <div>
          Minimum 300x300 px. PNG with transparent background is highly
          recommended
        </div>
      </Message>
    </EmptyCard>
  ),

  showEmpty: (contact) => !contact.logo,
  Edit: () => (
    <Field<typeof PreviewFileUploadInputWithErrorHandling>
      id='logo'
      name='logo'
      Input={PreviewFileUploadInputWithErrorHandling}
      optional={false}
    />
  )
};
