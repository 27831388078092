import React, { ComponentType } from 'react';
import Box from '@rexlabs/box';
import { StyleSheet, useStyles } from '@rexlabs/styling';
import Tooltip from '@rexlabs/tooltip';

const defaultStyles = StyleSheet({
  iconContainer: {
    width: 40,
    height: 40,
    display: 'flex',
    justifyContent: 'center'
  }
});

interface IconCellParams {
  onClick: () => void;
  Icon: ComponentType;
  tooltipLabel?: string;
}

export function IconActionCell({
  Icon,
  onClick,
  tooltipLabel
}: IconCellParams) {
  const s = useStyles(defaultStyles);

  return (
    <Box display='flex' justifyContent='center' onClick={onClick}>
      {tooltipLabel ? (
        <Tooltip Content={() => tooltipLabel}>
          <div {...s('iconContainer')}>
            <Icon />
          </div>
        </Tooltip>
      ) : (
        <div {...s('iconContainer')}>
          <Icon />
        </div>
      )}
    </Box>
  );
}
