import * as React from 'react';
import { RecordDialog } from 'view/components/record-screen/dialog/dialog';
import { DialogProps } from '@rexlabs/dialog';
import { FieldArrayPassthroughProps } from '@rexlabs/form';
import { useChecklistItemRelatedDatesQuery } from '../../hooks/use-checklist-item-related-dates-query';
import { getOffsetRelatedDatesFromRelatedDatesData } from '../../utils/get-offset-related-dates-from-related-dates-data';
import { TaskType } from '../../../common/types/TaskType';
import { mapChecklistTemplateItemToChecklistTemplateItemFormData } from '../../mappers/map-checklist-template-item-to-checklist-template-item-form-data';
import { ChecklistTemplateItem } from '../../types/ChecklistTemplate';
import { mapChecklistTemplateItemFormDataToChecklistTemplateItem } from '../../mappers/map-checklist-template-item-form-data-to-checklist-template-item';
import { mapTriggerFormDataToTriggerItem } from '../../mappers/map-trigger-form-data-to-trigger';
import { injectGroupsForOutcomeItem } from '../../utils/inject-groups-for-outcome-item';
import { ChecklistTemplatesForm } from '../../types/ChecklistTemplatesForm';
import { mapChecklistTemplateItemToChecklistTemplateItemTriggerFormData } from '../../mappers/map-checklist-template-item-to-checklist-template-item-trigger-form-data';
import { useCombinedChecklistTemplateItemContent } from './create-checklist-item';

interface EditChecklistItemDialogProps extends DialogProps {
  taskType: TaskType;
  checklistTemplateItem: ChecklistTemplateItem;
  fieldArrayProps: FieldArrayPassthroughProps;
  index: number;
  mainValues: Partial<ChecklistTemplatesForm>;
  setFieldValue?: (field: string, value: any) => void;
}

export function EditChecklistItemDialog({
  onClose,
  taskType,
  checklistTemplateItem,
  fieldArrayProps,
  index,
  mainValues,
  setFieldValue
}: EditChecklistItemDialogProps) {
  const content = useCombinedChecklistTemplateItemContent(
    checklistTemplateItem.type.id,
    true
  );
  const handleSubmit = async ({ values }) => {
    const { replace } = fieldArrayProps;

    const mappedValues = mapChecklistTemplateItemFormDataToChecklistTemplateItem(
      { checklistTemplateItemFormData: values }
    );

    const mappedTriggerValues = mapTriggerFormDataToTriggerItem({
      checklistTemplateTriggerFormData: values
    });

    const mappedChecklistItem = { ...mappedValues, mappedTriggerValues };

    replace(index, { ...checklistTemplateItem, ...mappedChecklistItem });

    injectGroupsForOutcomeItem(mappedChecklistItem, setFieldValue);

    return true;
  };

  const {
    data: relatedDatesData,
    isLoading
  } = useChecklistItemRelatedDatesQuery(taskType);

  const relatedDateOptions = relatedDatesData?.data?.related_dates;

  const offsetRelatedDates =
    relatedDatesData &&
    getOffsetRelatedDatesFromRelatedDatesData(relatedDatesData.data);

  const baseData = mapChecklistTemplateItemToChecklistTemplateItemFormData({
    checklistTemplateItem: checklistTemplateItem,
    defaultRelatedDate: relatedDateOptions?.[0] || null,
    defaultRelatedDateOffset: offsetRelatedDates?.[0] || null
  });

  const mappedTriggerValues = mapChecklistTemplateItemToChecklistTemplateItemTriggerFormData(
    {
      checklistTemplateItem: checklistTemplateItem
    }
  );

  return (
    <RecordDialog
      title='Subtask'
      content={content}
      data={checklistTemplateItem}
      onClose={onClose}
      submitLabel='Okay'
      handleSubmit={handleSubmit}
      initialValues={{ ...baseData, ...mappedTriggerValues }}
      isLoading={isLoading}
      blockProps={{ taskType, mainValues }}
    />
  );
}
