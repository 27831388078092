import { useDialog } from '@rexlabs/dialog';
import { TaskType } from 'src/modules/tasks/common/types/TaskType';
import { FieldArrayPassthroughProps } from '@rexlabs/form';
import { useFeatureFlags } from 'view/components/@luna/feature-flags';
import { FLAGS } from 'utils/feature-flags';
import { CreateChecklistItemDialog } from '../../../dialogs/create-checklist-item';
import { CreateChecklistItemDialog as CombinedCreateChecklistItemDialog } from '../../../dialogs/smart-checklists/create-checklist-item';
import { useGetAddChecklistItemSubmitHandler } from '../../use-get-add-checklist-item-submit-handler';
import { ChecklistTemplateItemGroup } from '../../../types/ChecklistTemplate';

export function useCreateChecklistItemAction(taskType: TaskType) {
  const { hasFeature } = useFeatureFlags();
  const { open: openCreateChecklistItemDialog } = useDialog(
    CreateChecklistItemDialog
  );

  // New feature flagged version
  const combinedCreateChecklistItemDialog = useDialog(
    CombinedCreateChecklistItemDialog
  );
  const hasCombinedDialog = hasFeature(FLAGS.SMART_CHECKLISTS_COMBINED_DIALOG);

  const getAddChecklistItemSubmitHandler = useGetAddChecklistItemSubmitHandler();

  return ({
    fieldArrayProps,
    index,
    checklistTemplateItemGroup
  }: {
    fieldArrayProps: FieldArrayPassthroughProps;
    index?: number;
    checklistTemplateItemGroup?: ChecklistTemplateItemGroup;
  }) => {
    return {
      label: 'Add subtask after',
      id: 'create-checklist-item',
      handleAction: () => {
        // TODO: remove this once feature flag is removed
        if (hasCombinedDialog) {
          combinedCreateChecklistItemDialog.open({
            taskType,
            handleSubmit: getAddChecklistItemSubmitHandler(
              fieldArrayProps,
              index,
              checklistTemplateItemGroup
            )
          });
        } else {
          openCreateChecklistItemDialog({
            taskType,
            handleSubmit: getAddChecklistItemSubmitHandler(
              fieldArrayProps,
              index,
              checklistTemplateItemGroup
            )
          });
        }
      }
    };
  };
}
