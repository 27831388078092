import { query } from '@rexlabs/model-generator';
import { ownershipsModel } from 'data/models/entities/ownerships';

export const ownershipQuery = (ownershipId: string) => query`{
  ${ownershipsModel} (id: ${ownershipId}) {
    id
    reference
    name
    owners {
      contact {
        id
        name
      }
      contact_preferences
      share
    }
    maintenance_instructions
    preferred_suppliers
    property_ownerships
    disbursement_frequency
    disbursement_payment_methods
    bpay
    disbursement_withheld_funds
    financial_summary
    trust_summary
    operating_bank_account
    created_by {
      contact
    }
    updated_by {
      contact
    }
  } 
}`;
