import { makeIconComponent } from '@rexlabs/icons';

import SmallRecordNavigationSvg from 'assets/icons/s/record-navigation.svg';
import MediumRecordNavigationSvg from 'assets/icons/m/record-navigation.svg';
import LargeRecordNavigationSvg from 'assets/icons/l/record-navigation.svg';
import ExtraLargeRecordNavigationSvg from 'assets/icons/xl/record-navigation.svg';

export const RecordNavigationIcon = makeIconComponent({
  s: SmallRecordNavigationSvg,
  m: MediumRecordNavigationSvg,
  l: LargeRecordNavigationSvg,
  xl: ExtraLargeRecordNavigationSvg
});

export default RecordNavigationIcon;
