import { Generator, GeneratorConfig } from 'data/models/generator';
import { AccountingJournalEntry } from 'src/modules/account-journal-entries/types/account-journal-entry-types';
import { auditableConfig } from 'src/modules/common/auditable/auditable-config';
import { usersModel } from 'src/modules/users/models/users';

const config: GeneratorConfig = {
  entities: {
    related: {
      ...auditableConfig,
      line_items: {
        include: 'line_items{chart_of_accounts_account,tax_type,property}'
      },
      source_object: {
        include: 'source_object{trust_journal_entry}'
      },
      object: {
        include:
          'object,object.owners.primary_phone,object.owners.contact.primary_phone'
      },
      voided_by: {
        include: 'voided_by',
        model: usersModel
      }
    }
  }
};

const actionCreators = {};

export const accountingJournalEntryModel = new Generator<AccountingJournalEntry>(
  'financials/accounting-journal-entries',
  config
).createEntityModel({
  actionCreators
});
