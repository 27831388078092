import React from 'react';

import { BaseModelGeneratorModel } from '@rexlabs/model-generator';
import { RecordType } from 'data/models/types';
import { RecordDrawer } from 'src/modules/drawer/components/record-drawer';
import { DrawerProps } from 'src/modules/drawer/components/drawer';
import { RecordSubmitHandler } from 'view/components/record-screen/utils';
import { bulkEditBlock } from '../blocks/bulk-edit-block';
import { useBulkEdit } from '../hooks/use-bulk-edit';

type BulkEditDrawerProps = DrawerProps & {
  selectedItems: BaseModelGeneratorModel[];
  onSuccess?: () => void;
  defaultFields?: string[];
};

export function BulkEditDrawer({
  recordType,
  selectedItems,
  onSuccess,
  defaultFields,
  ...props
}: BulkEditDrawerProps & { recordType: RecordType }) {
  const { mutateAsync } = useBulkEdit(recordType);

  const content = [
    {
      id: 'bulk-edit',
      label: 'Bulk edit',
      blocks: [bulkEditBlock]
    }
  ];

  const handleSubmit: RecordSubmitHandler = async ({ changedValues }) => {
    const result = await mutateAsync({
      selectedIds: selectedItems.map((item) => item.id),
      data: changedValues
    });

    onSuccess?.();

    return result;
  };

  // TODO: need to make it so we can pass other stuff to the dialog if needed
  return (
    <RecordDrawer
      content={content}
      {...props}
      blockProps={{ recordType, defaultFields }}
      handleSubmit={handleSubmit}
    />
  );
}
