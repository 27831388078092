import React from 'react';
import { Field } from '@rexlabs/form';
import { TextInput } from '@rexlabs/text-input';
import { Checkbox } from '@rexlabs/checkbox';

import { BlockConfig } from 'view/components/record-screen/types';
import { Grid } from 'view/components/@luna/form/grid';
import { Value } from 'view/components/values';
import { Contact } from 'src/modules/contacts/types/contact-types';
import { useTranslation } from 'react-i18next';

export const businessDetailsBlock: BlockConfig<Contact> = {
  id: 'business-details',
  title: '[BUSINESS] Business details',
  validate: {
    definitions: {
      company_name: {
        name: 'business name',
        rules: 'required_if:type.id,business'
      }
    },
    messages: {
      required_if: 'The :attribute field is required.'
    }
  },
  View: ({ data }) => {
    const { t } = useTranslation();
    return (
      <Grid columns={3}>
        <Value label='Business name' value={data?.company_name} />
        <Value
          label={t(
            'contacts.australian-business-number.label.full-form.singular'
          )}
          data={data!}
          id={'tax_registration_number'}
        />
        <Value
          label={t(
            'contacts.australian-company-number.label.full-form.singular'
          )}
          data={data!}
          id={'company_registration_number'}
        />
      </Grid>
    );
  },
  Edit: ({ blockProps }) => {
    const { t } = useTranslation();
    return (
      <Grid columns={3}>
        <Field
          name='company_name'
          label='Business name'
          Input={TextInput}
          optional={false}
        />
        <Field
          name='tax_registration_number'
          label={
            t(
              'contacts.australian-business-number.label.full-form.singular'
            ) as string
          }
          Input={TextInput}
        />
        <Field
          name='company_registration_number'
          label={
            t(
              'contacts.australian-company-number.label.full-form.singular'
            ) as string
          }
          Input={TextInput}
        />

        {!blockProps?.hideSupplierCheckbox ? (
          <Field
            name='is_supplier'
            Input={Checkbox}
            inputProps={{
              label: 'Contact is a supplier'
            }}
            optional={false}
          />
        ) : null}
      </Grid>
    );
  }
};
