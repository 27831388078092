import React from 'react';

import { SearchResultItem } from 'utils/api/get-search-results';

import { BlockConfig } from 'view/components/record-screen/types';

import {
  CoreCommunicationContextObject,
  CreateMessageFormData
} from '../mappers/types/create-message-form-data';
import { Message } from '../types/Message';

import { getContextTypeFromRecord } from '../utils/get-context-type-from-record';
import { BasicEditSMSForm } from '../components/message-content-forms/basic-forms/basic-edit-sms-form';

export const smsContentBlock: BlockConfig<
  Message & { relates_to: SearchResultItem<CoreCommunicationContextObject> },
  any,
  CreateMessageFormData
> = {
  id: 'content',
  title: 'SMS Content',
  validate: {
    definitions: {
      smsContent: { name: 'content', rules: 'required' }
    }
  },
  Edit: ({ data, setFieldValue }) => {
    const contextType = getContextTypeFromRecord(data?.relates_to?.record);

    return (
      <BasicEditSMSForm
        contextType={contextType}
        setFieldValue={setFieldValue}
      />
    );
  }
};
