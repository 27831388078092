import React from 'react';

import { BlockConfig } from 'view/components/record-screen/types';
import { Grid } from 'view/components/@luna/form/grid';

import { WithdrawalPaymentDetailsForm } from '../components/withdrawal-payments-details-form';

export const withdrawFundsPaymentDetailsBlock: BlockConfig = {
  id: 'withdraw-funds-payment-details',
  title: 'Payment details',
  validate: {
    definitions: {
      'payment_information.method': { rules: 'required' }
    }
  },
  Edit: ({ values }) => {
    return (
      <Grid columns={3}>
        <WithdrawalPaymentDetailsForm
          fieldPrefix={'payment_information'}
          values={values}
        />
      </Grid>
    );
  }
};
