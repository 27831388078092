import { RecordType, RecordTypes } from 'data/models/types';
import { lowerCase, startCase } from 'lodash';
import i18n from 'src/modules/i18n/i18n';

export function getRecordTypeName(recordType: RecordType) {
  switch (recordType) {
    case 'property':
    case 'security_deposit':
      return startCase(recordType).toLowerCase();

    case RecordTypes.PropertyTenancy:
      return lowerCase(
        (i18n.t('lease-agreements.label.singular'), 'lease agreement') as string
      );

    case RecordTypes.PropertyOwnership:
      return lowerCase(
        (i18n.t('property-ownerships.management-agreement.label.singular'),
        'management agreement')
      ) as string;

    case RecordTypes.Contact:
      return 'contact';

    case RecordTypes.Ownership:
      return 'ownership';

    case RecordTypes.Tenancy:
      return 'tenancy';

    case RecordTypes.BankDeposit:
      return 'bank deposit';

    case RecordTypes.BankWithdrawal:
      return 'bank withdrawal';

    case RecordTypes.CreditNote:
      return 'credit note';

    case RecordTypes.Invoice:
      return 'invoice';

    case RecordTypes.Disbursement:
      return lowerCase(
        (i18n.t('disbursements.label.singular'), 'disbursement') as string
      );

    case RecordTypes.Reconciliation:
      return 'reconciliation';

    case RecordTypes.TrustJournalEntry:
      return lowerCase(
        i18n.t(
          'trust-journal-entries.label.singular',
          'trust journal entry'
        ) as string
      );

    case RecordTypes.TaskMaintenance:
      return 'maintenance task';

    case RecordTypes.TaskLeaseReview:
      return lowerCase(
        i18n.t('tasks.lease-review.label.singular', 'lease review') as string
      );

    case RecordTypes.TaskInspection:
      return 'inspection';

    case RecordTypes.TaskMoveIn:
      return i18n.t('tasks.move-in.move-in-task', 'move in task');

    case RecordTypes.TaskMoveOut:
      return 'move out task';

    case RecordTypes.Todo:
      return 'to-do';

    case RecordTypes.AccountingJournalEntry:
      return 'journal entry';

    default:
      return 'unknown record';
  }
}

// in some cases we might want to just get the plural label regardless
export const PLURALISE = 2;

export function getRecordTypeNamePluralised(recordType: RecordType, count = 1) {
  const typeName = getRecordTypeName(recordType);

  if (count !== 1) {
    switch (recordType) {
      // most of the time we can just append an 's', but for some words we might need special rules, so add them here if you do
      default:
        return `${typeName}s`;
    }
  }

  return typeName;
}
