import { StyleSheet, useStyles } from '@rexlabs/styling';
import { noop } from 'lodash';
import React, { createContext, useContext, useState } from 'react';

const defaultStyles = StyleSheet({
  wrapper: {
    display: 'contents'
  }
});

type RefreshScreenWrapperContextProps = {
  refreshScreen: () => void;
};

const RefreshScreenWrapperContext = createContext<RefreshScreenWrapperContextProps>(
  {
    refreshScreen: noop
  }
);

// HACK: See readme file for more details
export function RefreshScreenWrapper({ children }) {
  const [screenKey, setScreenKey] = useState(1);
  const s = useStyles(defaultStyles);

  const value = {
    screenKey,
    refreshScreen: () => setScreenKey((state) => state + 1)
  };

  return (
    <RefreshScreenWrapperContext.Provider value={value}>
      <div {...s('wrapper')} key={screenKey}>
        {children}
      </div>
    </RefreshScreenWrapperContext.Provider>
  );
}

export function useRefreshScreen() {
  return useContext(RefreshScreenWrapperContext);
}
