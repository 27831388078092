import * as React from 'react';
import Box from '@rexlabs/box';
import {
  StatusChangeActionArgs,
  useStatusChangeAction
} from 'src/modules/common/actions/status/use-status-change-action';
import { invokeActionDeclaration } from 'src/modules/common/actions/utils/invoke-action-declaration';
import { Task } from 'src/modules/tasks/common/types/Task';
import { useStatusDropdownRefresh } from 'src/modules/tasks/common/hooks/use-get-status-dropdown-refresh';
import { useModelActions } from '@rexlabs/model-generator';
import { TaskStatusMenu } from '../components/task-status-menu';
import { tasksModel } from '../../common/models/tasks-model';
import { getWorkOrderStatusChangeTransformPayload } from '../utils/get-work-order-status-change-transform-payload';

export function ProgressDropdown({
  value: task
}: {
  value: Task<'task_work_order'>;
}) {
  const refreshListsOnComplete = useStatusDropdownRefresh();
  const getStatusChangeAction = useStatusChangeAction(tasksModel);
  const { refreshItem } = useModelActions(tasksModel);
  const statusChangeTransformPayload = getWorkOrderStatusChangeTransformPayload(
    task
  );

  const handleStatusChange = (newStatus) => {
    invokeActionDeclaration<StatusChangeActionArgs<typeof newStatus.id>[]>(
      getStatusChangeAction,
      {
        record: task,
        status: newStatus.id,
        dialogOptions: {
          type: 'none',
          afterAction: () => {
            refreshListsOnComplete();
            task.parent_task && refreshItem({ id: task.parent_task?.id });
          }
        },
        transformPayload: statusChangeTransformPayload
      }
    );
  };

  return (
    <Box
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
      data-testid='work-order-status-tag'
    >
      <TaskStatusMenu
        task={task}
        initialValue={task.details?.status}
        onChange={handleStatusChange}
      />
    </Box>
  );
}
