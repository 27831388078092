import React from 'react';

import { BlockConfig } from 'view/components/record-screen/types';
import { Field } from '@rexlabs/form';
import { TextInput } from '@rexlabs/text-input';
import { PrepaymentBucket } from 'src/modules/prepayments/types/prepayment-bucket';
import { Column, Grid } from 'view/components/@luna/form/grid';

import { TextArea } from 'view/components/inputs/text-area/text-area';

const validate = {
  definitions: {
    name: { rules: 'required' }
  }
};

export const prepaymentDetailsBlock: BlockConfig<PrepaymentBucket> = {
  id: 'details',
  validate,
  Edit: () => {
    return (
      <Grid columns={2}>
        <Field name='name' label='Name' Input={TextInput} />
        <div />
        <Column width={2}>
          <Field name='description' label='Description' Input={TextArea} />
        </Column>
      </Grid>
    );
  }
};
