import React, { useEffect } from 'react';

import { Field } from '@rexlabs/form';
import { TextInput } from '@rexlabs/text-input';

import { FLAGS } from 'utils/feature-flags';

import { BlockConfig } from 'view/components/record-screen/types';
import { Value } from 'view/components/values';
import { Column, Grid } from 'view/components/@luna/form/grid';
import { AuthorSelect } from 'view/components/inputs/selects/v4-selects/author-select';
import { useFeatureFlags } from 'view/components/@luna/feature-flags';

import { MessageTemplate } from '../types/MessageTemplate';
import { slugToSentenceCase } from '../../messages/utils/slug-to-sentence-case';
import { emptyMessageCreatorItem } from '../../common/data/empty-message-creator-item';
import { CreateTemplateFormData } from '../types/create-template-form-data';
import { RecipientGroupSelect } from '../../common/components/selects/recipient-group-select';
import { MessageContextSelect } from '../../common/components/selects/message-context-select';
import {
  SendImmediatelySelect,
  sendImmediatelySelectItems
} from '../../common/components/selects/send-immediately-select';
import { TemplateBanner } from '../components/message-template-trigger-banner';
import { canTemplateBeUsedForTrigger } from '../utils/can-template-be-used-for-trigger';

export type MessageTemplateDetailsFormValues = Pick<
  CreateTemplateFormData,
  | 'context_type'
  | 'name'
  | 'recipient_group'
  | 'send_from_author'
  | 'send_immediately'
>;

const validate = {
  definitions: {
    name: { name: 'template name', rules: 'required' },
    send_from_author: { name: 'sent from', rules: 'required' },
    context_type: { name: 'relates to', rules: 'required' },
    recipient_group: { name: 'recipient group', rules: 'required' },
    send_immediately: {
      name: 'How should this message be sent',
      rules: 'required'
    }
  }
};

export const messageTemplateDetailsBlock: BlockConfig<
  MessageTemplate,
  any,
  | MessageTemplateDetailsFormValues // NOTE: When we view this block as part of the record screen, we get all the data
  | CreateTemplateFormData // NOTE: When we view this block in the create template flow, we only see the values in the form
> = {
  id: 'details',
  title: 'Template Details',
  validate,
  isEditable: (template) => !template?.system_purpose?.id,
  View: ({ data, values }) => {
    const { hasFeature } = useFeatureFlags();
    const author = data?.send_from_author || emptyMessageCreatorItem;

    return (
      <Grid columns={2}>
        <Value label='Template name' value={data?.name} />
        <Value label='Sent from' value={author.name} />

        <Value label='Message relates to' value={data?.context_type.label} />
        <Value
          label='Recipient group'
          // TODO: would be good to get this value from the backend instead of doing it here
          value={
            data?.default_recipient_group
              ? slugToSentenceCase(data.default_recipient_group)
              : undefined
          }
        />
        {hasFeature(FLAGS.MESSAGE_ATTACHMENTS) && (
          <Value
            label='How should this message be sent?'
            value={values?.send_immediately?.label}
          />
        )}
        {/* NOTE: Pending BE
         https://rexsoftware.atlassian.net/browse/ALFIE-2269 */}
        {!!(
          hasFeature(FLAGS.MESSAGE_ATTACHMENTS) &&
          canTemplateBeUsedForTrigger(data)
        ) && (
          <Column width={2}>
            <TemplateBanner values={values} data={data} />
          </Column>
        )}
      </Grid>
    );
  },
  Edit: ({ values, data, setFieldValue }) => {
    const { hasFeature } = useFeatureFlags();

    // When the context changes, we need to clear the recipient group, as it is dependant on the context
    const handleContextTypeChange = () => {
      setFieldValue?.('recipient_group', null);
    };

    // TODO: remove this when we are able to set this value in from the backend
    useEffect(() => {
      if (values?.['send_immediately'] === undefined) {
        setFieldValue?.('send_immediately', sendImmediatelySelectItems[0]);
      }
    }, [values]);

    return (
      <Grid columns={2}>
        <Field name='name' label='Template name' Input={TextInput} />
        <Field
          name='send_from_author'
          label='Sent from'
          Input={AuthorSelect}
          inputProps={{ staticItems: [emptyMessageCreatorItem] }}
        />

        <Field
          id='context_type'
          name='context_type'
          label='Message relates to'
          Input={MessageContextSelect}
          onChange={handleContextTypeChange}
          HelpTooltipContent={
            data?.id
              ? () => (
                  <>
                    <p>{'Context type is locked once template is created.'}</p>
                    <p>
                      {
                        'You will need to create a new template if you need to change this.'
                      }
                    </p>
                  </>
                )
              : undefined
          }
          inputProps={{ disabled: data?.id }}
        />
        <Field
          id='recipient_group'
          name='recipient_group'
          label='Recipient group'
          Input={RecipientGroupSelect}
          inputProps={{
            contextType: values?.context_type?.id
          }}
        />

        {hasFeature(FLAGS.MESSAGE_ATTACHMENTS) && (
          <Field
            id='send_immediately'
            name='send_immediately'
            label='How should this message be sent?'
            Input={SendImmediatelySelect}
          />
        )}

        {!!(
          hasFeature(FLAGS.MESSAGE_ATTACHMENTS) &&
          canTemplateBeUsedForTrigger(data)
        ) && (
          <Column width={2}>
            <TemplateBanner values={values} data={data} />
          </Column>
        )}
      </Grid>
    );
  }
};
