import { InspectionRunStartFormValues } from '../components/inspection-run-start-form';
import { INSPECTION_SAVE_REQUEST_DATE_FORMAT } from '../constants/InspectionSaveRequestDateFormat';
import { InspectionRun } from '../types/InspectionRun';
import { getStartAtFromDayAndTime } from '../utils/get-start-at-from-day-and-time';

export function mapInspectionRunStartFormToInspectionRun(
  values: InspectionRunStartFormValues
): Pick<
  InspectionRun,
  'start_at' | 'start_address' | 'start_latitude' | 'start_longitude'
> {
  return {
    start_at: getStartAtFromDayAndTime(
      values.leave_from_start_time!,
      values.inspection_date!
    ).format(INSPECTION_SAVE_REQUEST_DATE_FORMAT),
    start_address: values.start_address!.label,
    start_latitude: values.start_address!.data.lngLat[1].toString(),
    start_longitude: values.start_address!.data.lngLat[0].toString()
  };
}
