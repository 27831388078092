import * as React from 'react';
import { capitalize } from 'lodash';
import { RecordOption, Select } from '@rexlabs/select';
import { useApiValueList } from 'view/hooks/api/use-api-value-list';

import NormalIcon from 'view/components/icons/priority-normal';
import HighIcon from 'view/components/icons/priority-urgent';
import LowIcon from 'view/components/icons/priority-preventative';

export const priorityMap = {
  low: LowIcon,
  normal: NormalIcon,
  high: HighIcon
};

export function InvoicePrioritySelect(props) {
  const { data } = useApiValueList('invoice_bill_priority');

  const prefixIconId = props.value?.id;
  const PrefixIcon = prefixIconId ? priorityMap[prefixIconId] : null;

  return (
    <Select
      items={data}
      Option={RecordOption}
      ValuePrefix={PrefixIcon}
      normaliser={({ id }) => {
        return {
          id,
          // TODO: make backend return label
          label: capitalize(id),
          Icon: priorityMap[id]
        };
      }}
      {...props}
    />
  );
}
