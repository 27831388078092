import { useWhereabouts } from '@rexlabs/whereabouts';
import { Columns } from 'src/view/components/table';
import { ProgressDropdown } from 'src/modules/tasks/maintenance/components/progress-dropdown';
import { SummaryCell } from '../components/summary-cell';
import { MaintenanceTask } from '../types/MaintenanceTask';
import { closedDate, managedBy } from '../../common/utils/common-columns';

const priority = {
  id: 'priority',
  type: 'value',
  Header: 'Priority',
  accessor: (item: MaintenanceTask) => item.priority,
  toCsv: (item: MaintenanceTask) => item?.priority?.label,
  width: 140
} as const;

const status = {
  id: 'status',
  Header: 'Task progress',
  Cell: ProgressDropdown,
  accessor: (item: MaintenanceTask) => item,
  width: 200,
  toCsv: (item: MaintenanceTask) => item.details?.status?.label ?? ''
};

const dueDate = {
  id: 'due_date',
  Header: 'Expected completion date',
  type: 'date',
  width: 200
} as const;

const followUpDate = {
  id: 'follow_up_date',
  Header: 'Follow up date',
  accessor: (item: MaintenanceTask) => item.follow_up_date,
  type: 'date',
  width: 140
} as const;

const summary = {
  id: 'summary',
  Header: 'Summary',
  Cell: SummaryCell,
  accessor: (item) => item,
  width: 380,
  toCsv: (item: MaintenanceTask) => item.summary
};

export function useMaintenanceColumns(): Columns {
  const whereabouts = useWhereabouts();
  const tab = whereabouts.hashQuery?.tab;

  if (tab === 'closed') {
    return [summary, closedDate, dueDate, managedBy, priority];
  }

  return [summary, followUpDate, dueDate, status, managedBy, priority];
}
