import { useQuery } from 'react-query';

import { api } from 'utils/api/api-client';
import { PropertyAreaType } from 'src/modules/property-areas/property-area-types/types/PropertyAreaType';
import { AxiosResponse } from 'axios';

export const queryKey = 'all-property-area-types';

const areaTypesUrl = '/properties/property-area-types';

type Pagination = { pagination: { total_pages: number } };

export async function fetchAllPropertyAreaTypes(): Promise<PropertyAreaType[]> {
  const query = new URLSearchParams();
  query.set('per_page', '100');

  query.set('include', 'items');

  const queryString = decodeURIComponent(query.toString());

  const res: AxiosResponse & Pagination = await api.get(
    `${areaTypesUrl}?${queryString}`
  );

  const pagination = res.pagination;

  const data = res.data;

  if (pagination.total_pages > 1) {
    const remainingPages = await Promise.all(
      Array.from({
        length: pagination.total_pages - 1
      }).map((_, index) =>
        api.get(`${areaTypesUrl}?${queryString}&page=${index + 2}`)
      )
    );

    remainingPages.forEach((page) => data.push(...page.data));
  }

  return data;
}

/**
 * Gets all property area types so we can show all of them as options in a select without needing to search
 * note they are very unlikely to create many.
 * @returns {PropertyAreaType[]}
 */
export function useAllPropertyAreaTypes() {
  const { data } = useQuery([queryKey], fetchAllPropertyAreaTypes, {
    staleTime: 60 * 1000
  });

  return data;
}
