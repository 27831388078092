import { StatusTagMap } from 'src/modules/common/components/status-tag';
import {
  PropertyManagementStatus,
  PropertyVacancyStatus
} from 'src/modules/properties/types/property-types';

export const propertyVacancyStatusMap: StatusTagMap<PropertyVacancyStatus> = {
  occupied: 'good',
  vacant: 'average'
};

export const propertyManagementStatusMap: StatusTagMap<PropertyManagementStatus> = {
  not_under_management: 'average',
  under_management: 'good'
};
