import React from 'react';

import { Link } from '@rexlabs/whereabouts';

import ROUTES from 'routes/app';

import { Banner } from 'src/modules/common/components/banner';

import { BlockConfig } from 'view/components/record-screen/types';
import { InfoCircleIcon } from 'view/components/icons/info';

export const messageTriggersWarning: BlockConfig = {
  id: 'message-triggers-warning',
  Card: () => {
    return (
      <Banner intent='information' align='start' Icon={InfoCircleIcon}>
        <span>
          The message triggers will send <b>emails only</b>. You can create and
          edit existing templates in{' '}
          <Link to={ROUTES.MESSAGE_TEMPLATE_LIST}>message templates</Link>.
        </span>
      </Banner>
    );
  }
};
