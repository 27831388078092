import React from 'react';
import Box from '@rexlabs/box';
import { FieldArray } from '@rexlabs/form';
import { BlockConfig } from 'view/components/record-screen/types';
import { RecordTable } from 'view/components/table';
import { Contact } from 'src/modules/contacts/types/contact-types';
import { Tenancy, TenantsForm } from 'data/models/entities/tenancies';
import { Includes } from '@rexlabs/model-generator';
import { LargeDescriptionCell } from 'view/components/cells/large-description-cell';
import { FLAGS } from 'utils/feature-flags';
import { useFeatureFlags } from 'view/components/@luna/feature-flags';
import { TenantFieldArrayContent } from 'src/modules/tenancies/components/tenant-field-array-content';
import { TenantFieldArrayContentOld } from '../components/tenant-field-array-content-old';

function getGuarantorsDescription(guarantors?: Contact[]) {
  return (
    guarantors?.map((guarantor) => guarantor?.display_name).join(', ') || '--'
  );
}

const useColumns = () => {
  const { hasFeature } = useFeatureFlags();

  const hasGuarantorAndOccupierUpdates = hasFeature(
    FLAGS.GUARANTOR_AND_OCCUPIER_UPDATES
  );

  return [
    {
      id: 'contact.display_name',
      Header: 'Name'
    },
    {
      id: 'role.label',
      Header: 'Role',
      ...(hasGuarantorAndOccupierUpdates ? { width: 100 } : {})
    },
    {
      id: 'payment_reference',
      Header: 'Tenant reference',
      ...(hasGuarantorAndOccupierUpdates ? { width: 200 } : {})
    },
    ...(hasGuarantorAndOccupierUpdates
      ? [
          {
            id: 'guarantors',
            Header: 'Guarantor',
            Cell: ({ value }: { value?: Includes<Contact[]> }) => {
              return (
                <LargeDescriptionCell
                  lineClamp={2}
                  text={getGuarantorsDescription(value?.data)}
                />
              );
            }
          }
        ]
      : [])
  ];
};

export function getTenantBlock(args?: {
  infoBanner?: React.ReactNode;
}): BlockConfig<Tenancy, any, TenantsForm> {
  return {
    id: 'tenants',
    title: 'Tenants',
    validate: {
      definitions: {
        'tenants.*.contact': { rules: 'required', name: 'contact' },
        'tenants.*.role': { rules: 'required', name: 'role' }
      }
    },
    View: ({ data }) => {
      const columns = useColumns();

      return (
        <Box flexDirection='column' gap={25}>
          {args?.infoBanner}
          <RecordTable
            id='tenants'
            items={data?.tenants}
            columns={columns}
            getRowId={(item) => item?.contact?.id}
            isLoading={false}
          />
        </Box>
      );
    },
    Edit: ({ values }) => {
      const { hasFeature } = useFeatureFlags();

      const hasGuarantorAndOccupierUpdates = hasFeature(
        FLAGS.GUARANTOR_AND_OCCUPIER_UPDATES
      );

      const FieldArrayContent = hasGuarantorAndOccupierUpdates
        ? TenantFieldArrayContent
        : TenantFieldArrayContentOld;

      return (
        <Box flexDirection='column' gap={25}>
          {args?.infoBanner}
          <FieldArray name='tenants'>
            {({ fields, push }) => (
              <FieldArrayContent fields={fields} push={push} values={values} />
            )}
          </FieldArray>
        </Box>
      );
    }
  };
}
