import React from 'react';

import { BatchReceiptingItem } from '../types';
import { useMapBatchReceiptingItems } from '../hooks/use-map-batch-receipting-items';
import { useBankStatementTransactionsQuery } from '../hooks/use-bank-statement-transactions-query';
import { BatchReceiptingTable } from './batch-receipting-table';
import { RemovedTabEmptyState } from './empty-states/removed-tab-empty-state';
import { withTableProvider } from './table-provider';

export const RemovedTab = withTableProvider({
  Component: () => {
    const {
      data,
      isLoading,
      fetchNextPage,
      isFetchingNextPage
    } = useBankStatementTransactionsQuery('REMOVED');

    const items: BatchReceiptingItem[] =
      data?.pages.map((page) => page.data).flat() || [];

    const mappedItems = useMapBatchReceiptingItems(items);

    return (
      <BatchReceiptingTable
        isLoading={isLoading}
        items={mappedItems}
        EmptyState={RemovedTabEmptyState}
        fetchNextPage={fetchNextPage}
        isFetchingNextPage={isFetchingNextPage}
        testId='removed-card'
      />
    );
  },
  hashParamKey: 'removed'
});
