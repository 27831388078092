import { makeIconComponent } from '@rexlabs/icons';

import StartMediumSvg from 'src/assets/icons/m/start.svg';
import StartLargeSvg from 'src/assets/icons/l/start.svg';

export const StartIcon = makeIconComponent({
  m: StartMediumSvg,
  l: StartLargeSvg
});

export default StartIcon;
