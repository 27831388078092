import { RecordObject } from 'data/models/types';
import { parseRouteToUrl, push, RouteDefinition } from '@rexlabs/whereabouts';
import ROUTES from 'routes/app';
import { RegularQuri } from '@rexlabs/quri';
import { mapQuriFiltersToTableFilter } from 'src/modules/common/utils/map-quri-filters-to-table-filter';

export interface RecordRoutes {
  record?: RouteConfig;
  list?: RouteConfig;
}

interface RouteConfig {
  route: RouteDefinition;
  routeParam: string;
}

interface RouteStub {
  record?: RouteDefinition;
  list?: RouteDefinition;
}

/**
 * We really want to be inverting the responsibility as these functions will grow with our application and become
 * un-manageable
 * @see https://github.com/rexlabsio/alfred/pull/1573
 */
export function getRecordRoutes(recordObject: RecordObject): RecordRoutes {
  const routes: RouteStub = {};
  /**
   * Hello future developer, add the routes in the switch statement 👇👇👇
   */
  switch (recordObject.type) {
    case 'contact':
      routes.list = ROUTES.CONTACTS_LIST;
      routes.record = ROUTES.CONTACT;
      break;

    case 'property':
      routes.list = ROUTES.PROPERTIES_LIST;
      routes.record = ROUTES.PROPERTY;
      break;

    case 'tenancy':
      routes.list = ROUTES.TENANCIES_LIST;
      routes.record = ROUTES.TENANCY;
      break;

    case 'ownership':
      routes.list = ROUTES.OWNERSHIPS_LIST;
      routes.record = ROUTES.OWNERSHIP;
      break;

    case 'property_tenancy':
      routes.record = ROUTES.PROPERTY;
      break;

    case 'property_ownership':
      routes.record = ROUTES.PROPERTY;
      break;

    case 'invoice':
      routes.list = ROUTES.INVOICES_LIST;
      routes.record = ROUTES.INVOICE;
      break;

    case 'trust_journal_entry':
      routes.list = ROUTES.TRUST_JOURNAL_ENTRIES;
      routes.record = ROUTES.TRUST_JOURNAL_ENTRY;
      break;

    case 'message_template':
      routes.list = ROUTES.MESSAGE_TEMPLATE_LIST;
      routes.record = ROUTES.MESSAGE_TEMPLATE_DETAILS;
      break;

    case 'message':
      routes.list = ROUTES.MESSAGE_LIST;
      break;

    case 'task_maintenance':
      routes.list = ROUTES.MAINTENANCE_TASKS_LIST;
      routes.record = ROUTES.MAINTENANCE_TASKS_DETAILS;
      break;

    case 'todo':
      routes.list = ROUTES.TODO_LIST;
      routes.record = ROUTES.TODO_DETAILS;
      break;

    case 'task_lease_review':
      routes.list = ROUTES.LEASE_REVIEW_LIST;
      routes.record = ROUTES.LEASE_REVIEW_DETAILS;
      break;

    case 'task_move_in':
      routes.list = ROUTES.MOVE_IN_LIST;
      routes.record = ROUTES.MOVE_IN_DETAILS;
      break;

    case 'task_move_out':
      routes.list = ROUTES.MOVE_OUT_LIST;
      routes.record = ROUTES.MOVE_OUT_DETAILS;
      break;

    case 'task_inspection':
      routes.list = ROUTES.INSPECTION_TASK_LIST;
      routes.record = ROUTES.INSPECTION_TASK_DETAILS;
      break;

    case 'accounting_journal_entry':
      routes.record = ROUTES.ACCOUNTING_JOURNAL_ENTRY;
      break;

    case 'task_property_compliance':
      routes.list = ROUTES.PROPERTY_COMPLIANCE_TASKS_LIST;
      routes.record = ROUTES.PROPERTY_COMPLIANCE_TASKS_DETAILS;
      break;

    case 'task_supplier_compliance':
      routes.list = ROUTES.SUPPLIER_COMPLIANCE_TASKS_LIST;
      routes.record = ROUTES.SUPPLIER_COMPLIANCE_TASKS_DETAILS;
      break;

    case 'task_arrears':
      routes.list = ROUTES.ARREARS_LIST;
      routes.record = ROUTES.ARREARS_DETAILS;
      break;

    case 'task_work_order':
      routes.list = ROUTES.WORK_ORDERS_LIST;
      routes.record = ROUTES.WORK_ORDER_DETAILS;
      break;

    case 'task_quote':
      routes.list = ROUTES.QUOTE_LIST;
      routes.record = ROUTES.QUOTE_DETAILS;
      break;

    case 'portfolio':
      routes.list = ROUTES.PORTFOLIOS_LIST;
      routes.record = ROUTES.PORTFOLIOS_DETAILS;
      break;

    case 'disbursement':
      routes.list = ROUTES.DISBURSEMENT_LIST;
      routes.record = ROUTES.COMPLETED_DISBURSEMENT;
      break;

    case 'pending_contact_disbursement':
      routes.list = ROUTES.DISBURSEMENT_LIST;
      routes.record = ROUTES.PENDING_CONTACT_DISBURSEMENT;
      break;

    case 'pending_ownership_disbursement':
      routes.list = ROUTES.DISBURSEMENT_LIST;
      routes.record = ROUTES.PENDING_OWNERSHIP_DISBURSEMENT;
      break;

    case 'bank_statement_transaction':
      routes.list = ROUTES.BATCH_RECEIPTING_LIST_SCREEN;
      break;

    case 'task':
      routes.list = ROUTES.TASK_LIST;
      break;

    case 'user':
      routes.list = ROUTES.USERS_LIST;
      routes.record = ROUTES.USER;
      break;

    case 'author':
      routes.list = ROUTES.USERS_LIST;
      routes.record = ROUTES.USER;
      break;

    case 'bank_deposit':
      routes.list = ROUTES.BANK_DEPOSITS_LIST;
      routes.record = ROUTES.BANK_DEPOSIT_DETAILS;
      break;

    case 'bank_withdrawal':
      routes.list = ROUTES.BANK_WITHDRAWALS_LIST;
      routes.record = ROUTES.BANK_WITHDRAWAL;
      break;

    case 'charge_entry':
      routes.record = ROUTES.CHARGE_ENTRY_DETAILS;
      break;

    case 'recurring_charge_entry':
      routes.record = ROUTES.RECURRING_CHARGE_ENTRY_DETAILS;
      break;

    case 'bank_account':
      routes.list = ROUTES.BANK_ACCOUNTS;
      routes.record = ROUTES.BANK_ACCOUNT_DETAILS;
      break;

    case 'reconciliation':
      routes.list = ROUTES.RECONCILIATIONS;
      routes.record = ROUTES.RECONCILIATION;
      break;

    case 'credit_note':
      routes.list = ROUTES.CREDIT_NOTES_LIST;
      routes.record = ROUTES.CREDIT_NOTE;
      break;

    case 'security_deposit':
      routes.record = ROUTES.SECURITY_DEPOSIT;
      break;

    case 'trust_ledger':
      routes.list = ROUTES.TRUST_LEDGERS;
      routes.record = ROUTES.TRUST_LEDGER;
      break;

    // NOTE: this is incorrect. This should be for checklist_template, not checklist
    case 'checklist':
      routes.record = ROUTES.CHECKLIST_DETAILS;
      break;

    case 'periodic_ownership_statement':
      routes.list = ROUTES.PERIODIC_STATEMENTS_LIST;
      break;

    case 'yearly_ownership_statement':
      routes.list = ROUTES.EOY_STATEMENTS_LIST;
      break;

    default:
      throw new Error(`No routes have been defined for ${recordObject.type}`);
  }

  return Object.fromEntries(
    Object.entries(routes).map(([routeName, route]) => [
      routeName,
      { route, routeParam: getRouteParam(route) }
    ])
  );
}

interface RouteParamsData {
  query?: Record<string, string>;
  params?: Record<string, string>;
}

// Since the Flattening, some records live on tabs on the record page of the property record
export function getDataForRouteParams(
  recordObject: RecordObject
): RouteParamsData {
  switch (recordObject.type) {
    case 'property_ownership':
      return {
        params: {
          propertyId: recordObject.object.property_id
        },
        query: {
          managementAgreement: recordObject.object.id,
          tab: 'management-agreements'
        }
      };

    case 'property_tenancy':
      return {
        params: {
          propertyId: recordObject.object.property_id
        },
        query: {
          leaseAgreement: recordObject.object.id,
          tab: 'lease-agreements'
        }
      };

    default:
      return {};
  }
}

function getRouteParam(route: RouteDefinition) {
  return route?.config?.path?.split(':').slice(-1)[0];
}

interface ViewRecordRouteDetails {
  path?: string;
  query?: Record<string, string>;
}

export function getViewRecordRouteDetails(
  recordObject: RecordObject
): ViewRecordRouteDetails {
  const routeParams = getDataForRouteParams(recordObject);
  let routes: RecordRoutes | undefined;
  try {
    routes = getRecordRoutes(recordObject);
  } catch (e) {
    // no routes for this record.
  }

  const singleParam = routes?.record?.routeParam || '';

  const params = routeParams.params
    ? routeParams.params
    : { [singleParam]: recordObject.object.id };

  return routes?.record?.route?.config
    ? {
        path: parseRouteToUrl({
          ...routes.record.route.config,
          params
        }),
        query: routeParams.query
      }
    : {};
}

export function gotoRecord(
  recordObject: RecordObject,
  tab?: string,
  filters?: RegularQuri[]
): void {
  const routes = getRecordRoutes(recordObject);

  if (!routes.record?.route) {
    throw new Error(`There is no record route for ${recordObject.type}`);
  }

  const routeParams = getDataForRouteParams(recordObject);

  const params = routeParams.params
    ? routeParams.params
    : { [routes.record.routeParam]: recordObject.object.id };

  const mappedFilters =
    filters?.length && JSON.stringify(mapQuriFiltersToTableFilter(filters));

  push(routes.record?.route, {
    params,
    query: {
      ...(filters?.length ? { filters: mappedFilters } : {}),
      ...(tab ? { tab } : {}),
      ...routeParams.query
    }
  });
}
