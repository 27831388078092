import React from 'react';
import { BlockConfig } from 'view/components/record-screen/types';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack5';
import Box from '@rexlabs/box';
import { OutlineButton } from '@rexlabs/button';
import { StyleSheet, text, useStyles } from '@rexlabs/styling';
import { ViewDocumentProps } from '../dialogs/view-document-dialog';

type DocumentBlock = BlockConfig<ViewDocumentProps>;

export const imageDocumentViewBlock: DocumentBlock = {
  id: 'document-image',
  Edit: ({ data }) => {
    return <img src={data?.fileUrl} style={{ maxWidth: '100%' }} />;
  }
};

const defaultStyles = StyleSheet({
  button: {
    marginLeft: ({ token }) => token('spacing.xs')
  },
  text: {
    ...text.styles({
      color: ({ token }) => token('color.textStyle.body.subtext')
    })
  }
});

export const pdfDocumentViewBlock: DocumentBlock = {
  id: 'document-pdf',
  Edit: ({ data }) => {
    const s = useStyles(defaultStyles);

    const [pageNumber, setPageNumber] = React.useState(1);
    const [numPages, setNumPages] = React.useState(0);
    const [, setHasError] = React.useState(false);

    function onDocumentLoadSuccess({ numPages }) {
      setNumPages(numPages);
    }

    function onDocumentLoadError() {
      setHasError(true);
    }

    function changePage(offset) {
      setPageNumber((prevPageNumber) => prevPageNumber + offset);
    }

    function previousPage() {
      changePage(-1);
    }

    function nextPage() {
      changePage(1);
    }

    return (
      <Box style={{ maxWidth: '100%' }}>
        <Box
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}
        >
          <Box {...s('text')}>
            Page {pageNumber || (numPages ? 1 : '--')} of {numPages || '--'}
          </Box>
          <Box>
            <OutlineButton
              {...s('button')}
              isDisabled={pageNumber <= 1}
              onClick={previousPage}
            >
              Previous
            </OutlineButton>
            <OutlineButton
              {...s('button')}
              isDisabled={pageNumber >= numPages}
              onClick={nextPage}
            >
              Next
            </OutlineButton>
          </Box>
        </Box>
        <Box style={{ overflow: 'scroll' }}>
          <Document
            file={data?.fileUrl}
            onLoadSuccess={onDocumentLoadSuccess}
            onLoadError={onDocumentLoadError}
          >
            <Page pageNumber={pageNumber} />
          </Document>
        </Box>
      </Box>
    );
  }
};
