import Box from '@rexlabs/box';
import { Bold } from '@rexlabs/text';
import React from 'react';
import { useToken } from '@rexlabs/styling';

export function ConditionLabelKey() {
  const token = useToken();
  return (
    <Box gap={token('spacing.s')} style={{ display: 'flex' }}>
      <div>
        <Bold>C: </Bold>
        {'Clean'}
      </div>
      <div>
        <Bold>W: </Bold>
        {'Working  '}
      </div>
      <div>
        <Bold>U: </Bold>
        {'Undamaged'}
      </div>
    </Box>
  );
}
