import {
  ActionIntent,
  SingleActionDeclaration
} from 'src/modules/common/actions/types/action-declaration-types';
import {
  BaseModelGeneratorModel,
  EntityModel,
  useModelActions
} from '@rexlabs/model-generator';
import { useToast } from 'view/components/@luna/notifications/toast';
import { getRecordLabel } from 'utils/records/get-record-label';
import { getRecordObject } from 'src/modules/common/utils/Records/get-record-object';
import { modelTypeToUrl } from 'src/modules/common/utils/ModelGenerator/model-type-to-url';
import { useConfirmationDialog, useDialog } from '@rexlabs/dialog';
import { ConfirmationDialogProps } from '@rexlabs/dialog/lib/types/dialogs/confirmation';
import { CapturedReason } from 'src/modules/common/actions/status/components/capture-reason-block';
import React, { ComponentType } from 'react';
import { ToastType } from 'view/components/@luna/notifications/toast/core';
import { useApiClient } from 'src/lib/axios/hooks/use-api-client';
import { slugToSentenceCase } from 'src/modules/communications/messages/utils/slug-to-sentence-case';
import { RecordType } from 'data/models/types';
import { CaptureReasonDialog } from './components/capture-reason';

type StatusChangeDialogType = 'confirmation' | 'reason' | 'none';

interface CommonDialogOptions<Status> {
  type?: StatusChangeDialogType;
  afterAction?: (status?: Status) => any;
  onDialogOpen?: () => void;
  label?: string;
  title?: string;
  intent?: ActionIntent;
}

export interface ConfirmationDialogOptions<Status>
  extends CommonDialogOptions<Status>,
    Omit<ConfirmationDialogProps, 'intent' | 'title'> {
  type?: 'confirmation';
  SubmitButton?: ComponentType<any>;
  onCancel?: () => void;
}

interface ReasonDialogOptions<Status> extends CommonDialogOptions<Status> {
  type?: 'reason';
  /**
   * The label text for the submit button
   */
  submitLabel?: string;
  /**
   * The text to display in the body of the
   * dialog above the reason field
   */
  description?: React.ReactNode;
  /**
   * The label for the reason field
   */
  reasonLabel?: string;
  /**
   * The text to display below the reason field
   */
  helperText?: React.ReactNode;
  SubmitButton?: ComponentType<any>;
}

interface NoDialogOptions<Status> extends CommonDialogOptions<Status> {
  type?: 'none';
}

type DialogOptions<Status> =
  | ReasonDialogOptions<Status>
  | ConfirmationDialogOptions<Status>
  | NoDialogOptions<Status>;

interface ToastOptions {
  type?: ToastType;
  description?: React.ReactElement;
  recordLabel?: string;
}

interface GeneralOptions {
  avoidListRefresh?: boolean;
  avoidItemRefresh?: boolean;
}

type TransformPayload<Status> = (
  status?: Status,
  reason?: string
) => Record<any, any>;

export type StatusChangeActionArgs<TStatus extends string> = {
  record: BaseModelGeneratorModel;
  status: TStatus;
  dialogOptions?: DialogOptions<TStatus>;
  toastOptions?: ToastOptions;
  generalOptions?: GeneralOptions;
  transformPayload?: TransformPayload<TStatus>;
};

export const useStatusChangeAction = (
  model: EntityModel<any>,
  typeIdentifier?: RecordType
) => {
  const apiClient = useApiClient();
  const { refreshLists, refreshItem } = useModelActions(model);
  const { addToast } = useToast();
  const { open: openConfirmationDialog } = useConfirmationDialog();
  const { open: openCaptureReasonDialog } = useDialog(CaptureReasonDialog);

  return function <TStatus extends string>({
    record,
    status,
    dialogOptions,
    toastOptions,
    generalOptions,
    transformPayload = defaultStatusChangeTransformPayload
  }: StatusChangeActionArgs<TStatus>): SingleActionDeclaration {
    const recordObject = getRecordObject(record, typeIdentifier || model);
    return {
      id: `${status}`,
      group: 'status',
      label: dialogOptions?.label ?? generateLabel(status),
      ...(dialogOptions?.intent && { intent: dialogOptions.intent }),
      handleAction: async () => {
        const dialogTitle =
          dialogOptions?.title ??
          `Set ${getRecordLabel(recordObject)} as ${status}`;

        async function handleSubmitEvent(
          capturedReason: CapturedReason | null = null
        ) {
          const payload = transformPayload(status, capturedReason?.reason);

          const res = await apiClient(
            'patch',
            `${modelTypeToUrl(model.modelName)}/${record.id}/status`,
            payload
          );

          if (res !== false) {
            addToast({
              description:
                toastOptions?.description ??
                `${
                  toastOptions?.recordLabel ?? getRecordLabel(recordObject)
                } status changed to ${slugToSentenceCase(status)}`,
              type: toastOptions?.type ?? 'info'
            });
            !generalOptions?.avoidItemRefresh && refreshItem({ id: record.id });
            !generalOptions?.avoidListRefresh && refreshLists();
            if (dialogOptions?.afterAction) {
              await dialogOptions.afterAction(status);
            }
            return true;
          }
        }

        if (dialogOptions?.type === 'none') {
          await handleSubmitEvent();
          return;
        }

        dialogOptions?.onDialogOpen?.();

        if (dialogOptions?.type === 'confirmation') {
          openConfirmationDialog({
            ...dialogOptions,
            title: dialogTitle,
            //@ts-ignore
            onConfirm: handleSubmitEvent
          });
        } else {
          //TODO: We could probably use JS Doc for this 🧐
          dialogOptions = dialogOptions as ReasonDialogOptions<TStatus>;
          openCaptureReasonDialog({
            title: dialogTitle,
            submitLabel: dialogOptions?.submitLabel,
            reasonLabel: dialogOptions?.reasonLabel,
            description: dialogOptions?.description,
            helperText: dialogOptions?.helperText,
            SubmitButton: dialogOptions?.SubmitButton,
            onSubmit: handleSubmitEvent
          });
        }
      }
    };
  };
};

/**
 * For statuses that are verbs we want to return labels that make sense contextually with the verb
 */
function generateLabel(status: string): string {
  switch (status) {
    case 'void':
      return 'Void';
    case 'archived':
      return 'Archive';
    default:
      return `Set as ${status.replaceAll('_', ' ')}`;
  }
}

export function defaultStatusChangeTransformPayload(status, reason) {
  return {
    status: {
      id: status
    },
    status_reason: reason
  };
}
