import React from 'react';
import { StyleSheet, border, useStyles } from '@rexlabs/styling';

import { cellStyles } from './cell';

const styles = StyleSheet({
  thickBorder: {
    ...border.styles({
      target: 'table.row',
      bottom: {
        width: 'thick',
        color: 'container.static.dark'
      }
    })
  }
});

export function HeaderCell({ children }: { children?: React.ReactNode }) {
  const s = useStyles({ ...styles, ...cellStyles });

  return <th {...s('cell', 'thickBorder')}>{children}</th>;
}
