import React, { FC, ComponentType } from 'react';

interface RenderLoadingProps {
  isLoading?: boolean;
  LoadingView?: ComponentType<any>;
}

const RenderLoading: FC<RenderLoadingProps> = ({
  isLoading,
  LoadingView,
  children
}) => {
  return isLoading ? LoadingView ? <LoadingView /> : null : <>{children}</>;
};

export default RenderLoading;
