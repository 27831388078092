import React from 'react';

import { Select } from '@rexlabs/select';

import { ValueListValue } from 'data/models/types';

import { valueListFactory } from 'src/lib/testing/factories/value-list-factory';

const timeDuration = [5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60];

export type TimeDuration = number;

const items = timeDuration.map((duration) =>
  valueListFactory(duration.toString())
);

export function TimeDurationSelect(props) {
  return (
    <Select
      {...props}
      id='inspection-duration-select'
      items={items}
      normaliser={({ id, label }: ValueListValue<typeof timeDuration>) => ({
        id,
        label: `${label} min`
      })}
      placeholder='Select from list'
    />
  );
}
