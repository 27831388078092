import { Field } from '@rexlabs/form';
import dayjs from 'dayjs';
import { capitalize } from 'lodash';
import moment from 'moment';
import React from 'react';
import { BankDeposit } from 'src/modules/banking/bank-deposits/types/bank-deposit-types';
import { BankWithdrawal } from 'src/modules/banking/bank-withdrawals/types/bank-withdrawal-types';
import { getToday } from 'utils/dates/dates';
import { getRecordTypeName } from 'utils/records/get-record-type-name';
import { Grid } from 'view/components/@luna/form/grid';
import { DateInput } from 'view/components/@luna/inputs/date-input/date-input';
import { BlockConfig } from 'view/components/record-screen/types';
import { useSettings } from 'src/modules/settings/hooks/useSettings';
import { ConsolidateWithdrawalsInfoBanner } from 'src/modules/settings/account-settings/components/consolidated-withdrawals-info-banner';

export const bankTransactionDetailsBlock: BlockConfig<
  BankWithdrawal | BankDeposit
> = {
  id: 'bankTransactionDetails',
  validate: {
    definitions: {
      date_of: { name: 'date', rules: 'required' }
    }
  },
  Edit: function EditTransactionDetails({
    blockProps: { trustJournalEntries, recordType }
  }) {
    const settings = useSettings();
    const isOutsideRange = (momentDate: moment.Moment) => {
      const trustJournalEntryDates = trustJournalEntries.map(
        (trustJournalEntry) => dayjs(trustJournalEntry.date_of)
      );

      const latestTrustJournalEntryDate = dayjs.max(trustJournalEntryDates);

      const date = dayjs(momentDate.toDate());

      return (
        date.isAfter(dayjs(getToday()).add(1, 'day')) ||
        date.isBefore(latestTrustJournalEntryDate)
      );
    };

    return (
      <Grid columns={1}>
        <Grid columns={2}>
          <Field
            label={`${capitalize(getRecordTypeName(recordType))} date`}
            name='date_of'
            Input={DateInput}
            inputProps={{ isOutsideRange, defaultValue: getToday() }}
          />
        </Grid>
        <ConsolidateWithdrawalsInfoBanner
          enabled={settings['consolidate-withdrawals-on-payment-files']}
          showExampleByDefault={false}
          maxColumns={1}
        />
      </Grid>
    );
  }
};
