import { BaseModelGeneratorModel } from '@rexlabs/model-generator';

import { Generator } from 'data/models/generator';
import { api } from 'utils/api/api-client';
import { ValueListValue } from 'data/models/types';
import {
  AgencyFee,
  FeeAmount
} from 'src/modules/agency-fees/types/agency-fee-types';

export type OwnershipFee = BaseModelGeneratorModel & {
  agency_fee?: AgencyFee;
  tax_type?: ValueListValue<string>;
  fee_amount: FeeAmount;
  fee_amount_matches_agency_fee: boolean;
  is_tax_included: boolean;
};

const config = {
  entities: {
    related: {
      agency_fee: {
        include:
          'agency_fee,agency_fee.agency_chart_of_accounts_account,agency_fee.ownership_chart_of_accounts_account,agency_fee.tax_type'
      },
      tax_type: {
        include: 'tax_type'
      }
    },
    api: {
      fetchList: (type, { ownershipId, ...args }) => {
        return api.get(`/ownerships/${ownershipId}/fees`, args);
      },
      updateItem: (type, { ownershipId, ...args }, id) =>
        api.patch(`/ownerships/${ownershipId}/fees/${id}`, args),
      trashItem: (type, { ownershipId }, id) => {
        return api.delete(`/ownerships/${ownershipId}/fees/${id}`);
      }
    }
  }
};

export const ownershipsFeesModel = new Generator<OwnershipFee>(
  'ownerships/fees',
  //@ts-ignore
  config
).createEntityModel();
