import {
  BlockConfig,
  ContentConfig
} from 'view/components/record-screen/types';
import { useSessionState } from 'src/modules/common/hooks/session-state';

import { BankAccount } from 'data/models/entities/financials/bank-accounts';
import { sageSettingsBlock } from 'src/modules/bank-accounts/blocks/sage';
import { bacsSettingsBlock } from 'src/modules/bank-accounts/blocks/bacs';
import { detailsBlock } from '../blocks/details';
import { bpaySettingsBlock } from '../blocks/bpay';
import { abaSettingsBlock } from '../blocks/aba';

export const useBankAccountDetailsContent = (): ContentConfig => {
  const sessionData = useSessionState();

  const settingsBlocks: BlockConfig<BankAccount>[] = [];

  if (sessionData?.activeSilo?.country?.id === 'AUS') {
    settingsBlocks.push(abaSettingsBlock, bpaySettingsBlock);
  }
  if (sessionData?.activeSilo?.country?.id === 'GBR') {
    settingsBlocks.push(sageSettingsBlock, bacsSettingsBlock);
  }

  return [
    {
      label: 'Overview',
      items: [
        {
          id: 'details',
          label: 'Account details',
          blocks: [detailsBlock, ...settingsBlocks]
        }
      ]
    }
  ];
};
