import React from 'react';
import { useDialog } from '@rexlabs/dialog';

import EmptyTable from 'src/assets/illustrations/empty-table.svg';

import { EmptyCard } from 'view/components/record-screen/cards/empty-card';
import { Message } from 'view/components/@luna/message';

import { CreateComplianceCategoryDialog } from '../dialogs/create-compliance-category-dialog';

export function PropertyComplianceCategoryTableEmptyState() {
  const { open: openCreateComplianceCategoryDialog } = useDialog(
    CreateComplianceCategoryDialog
  );
  return (
    <EmptyCard hasGreyBackground={false}>
      <Message
        title='Compliance categories'
        Illustration={EmptyTable}
        actions={[
          {
            label: 'Create compliance category',
            intent: 'primary',
            handleAction: openCreateComplianceCategoryDialog
          }
        ]}
      >
        Easily manage compliance requirements by adding them to a category.
        These categories can then be applied to properties.
      </Message>
    </EmptyCard>
  );
}
