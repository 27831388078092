import React, { ComponentType, useCallback, useMemo } from 'react';
import { isEqual } from 'lodash';
import { RadioGroup } from '@headlessui/react';
import { ButtonProps } from '@rexlabs/button';
import Box from '@rexlabs/box';
import { StyleSheet, useStyles } from '@rexlabs/styling';
import { GenericEventHandler } from 'view/components/inputs/types';
import { ButtonGroupButton } from './button-group-button';

export interface InputButtonProps<TValue = any> extends ButtonProps {
  Icon?: ComponentType;
  isFirst: boolean;
  isMiddle: boolean;
  isLast: boolean;
  active: boolean;
  item: ButtonGroupItem<TValue>;
}
export interface ButtonGroupItem<TValue> {
  value: TValue;
  label?: string;
  Icon?: ComponentType;
  Button?: React.FC<InputButtonProps<TValue>>;
}

type ItemNormaliser<ExternalItem, TValue> = (
  item: ExternalItem
) => ButtonGroupItem<TValue>;

export interface ButtonGroupProps<TItem, TValue> {
  items?: TItem[];
  onChange: GenericEventHandler<TValue | null>;
  onBlur?: GenericEventHandler<TValue | null>;
  value?: TValue;
  name?: string;
  normaliser?: ItemNormaliser<TItem, TValue>;
}

const defaultStyles = StyleSheet({
  radio: {
    '&:focus': {
      boxShadow: 'none'
    },
    // Give equal space to each button
    // Note that any provided Button component should have width 100%, or it will look cut-off
    flex: 1
  }
});

export function ButtonGroupInput<TItem, TValue>({
  items = [],
  normaliser,
  onChange,
  onBlur,
  value: valueProp,
  name
}: ButtonGroupProps<TItem, TValue>) {
  const s = useStyles(defaultStyles);
  // there seem to be some perf issues on the form this is used in
  // I'm brute forcing the perf of this component so I don't make it worse
  const normalisedItems = useMemo(
    () =>
      (normaliser ? items.map(normaliser) : items) as ButtonGroupItem<TValue>[],
    [items, normaliser]
  );
  // when passing a complex object as value to the headless-ui radio group
  // it needs to have the exact same ref as one of the `item.value`s
  const value = normalisedItems.find((item) => isEqual(item?.value, valueProp))
    ?.value;

  const [selectedValue, setSelectedValue] = React.useState<TValue | null>(
    value || null
  );

  const handleClick = useCallback(
    (value: TValue) => {
      setSelectedValue(value);

      onChange?.({ target: { value } });
      onBlur?.({ target: { value } });
    },
    [onChange]
  );

  React.useEffect(() => {
    value && setSelectedValue(value);
  }, [value]);

  return (
    <RadioGroup value={selectedValue} onChange={handleClick} name={name}>
      <Box flexDirection={'row'}>
        {normalisedItems.map((item, index) => (
          <RadioGroup.Option
            value={item.value}
            key={item.label}
            {...s('radio')}
          >
            {({ checked }) => {
              const { Icon, label, value, Button = ButtonGroupButton } = item;
              const isButtonMiddle = index > 0 && index < items.length - 1;

              return (
                <Button
                  item={item}
                  Icon={Icon}
                  isFirst={index === 0}
                  isMiddle={isButtonMiddle}
                  isLast={index === items.length - 1}
                  active={checked}
                  aria-label={getAriaLabel(label, value)}
                >
                  {label}
                </Button>
              );
            }}
          </RadioGroup.Option>
        ))}
      </Box>
    </RadioGroup>
  );
}

function getAriaLabel(label, value): string | undefined {
  if (label) return label;
  if (typeof value === 'string') return value;

  return;
}
