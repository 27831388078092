import ActionMenu, { ActionMenuItem } from '@rexlabs/action-menu';
import { GhostIconButton } from '@rexlabs/button';
import { StyleSheet, useStyles } from '@rexlabs/styling';
import { Semibold } from '@rexlabs/text';
import * as React from 'react';
import KebabIcon from 'view/components/icons/kebab';

const styles = StyleSheet({
  title: {
    fontSize: 12,
    fontWeight: 600,
    textTransform: 'uppercase',
    color: ({ token }) => token('color.textStyle.body.subtext'),
    marginBottom: 8
  },

  wrapper: {
    background: ({ token }) => token('palette.grey.200'),
    borderRadius: 8,
    display: 'grid',
    gridTemplateColumns: 'auto 1fr auto',
    gap: 8,
    alignItems: 'center',
    fontSize: ({ token }) => token('typography.size.s')
  },

  triggerIcon: {
    color: ({ token }) => token('color.textStyle.body.subtext'),
    alignItems: 'center',
    display: 'grid'
  },

  isEditing: {
    padding: '10px 16px'
  },

  isViewing: {
    padding: '20px 16px'
  }
});

function Title(props: { children?: React.ReactNode }) {
  const { children } = props;
  const s = useStyles(styles);

  return <div {...s('title')}>{children}</div>;
}

function Trigger(props: {
  Icon: React.ComponentType;
  children?: React.ReactNode;
  endSlot?: React.ReactNode;
}) {
  const { Icon, children, endSlot } = props;
  const s = useStyles(styles);

  const hasEndSlot = endSlot != null;

  return (
    <div
      {...s.with({
        wrapper: true,
        isEditing: hasEndSlot,
        isViewing: !hasEndSlot
      })()}
    >
      <div {...s('triggerIcon')}>
        <Icon />
      </div>
      <Semibold>{children}</Semibold>

      {endSlot}
    </div>
  );
}

function EditActionMenu(props: { actionMenuItems?: ActionMenuItem[] }) {
  const { actionMenuItems = [] } = props;

  return (
    <ActionMenu
      Button={GhostIconButton}
      Icon={KebabIcon}
      placement='bottom-end'
      items={actionMenuItems}
    />
  );
}

interface ChecklistTriggerProps {
  children?: React.ReactNode;
}

export function ChecklistTrigger(props: ChecklistTriggerProps) {
  const { children } = props;

  return <div>{children}</div>;
}

ChecklistTrigger.Title = Title;
ChecklistTrigger.Trigger = Trigger;
ChecklistTrigger.ActionMenu = EditActionMenu;
