import React from 'react';

import { BlockConfig } from 'view/components/record-screen/types';
import Box from '@rexlabs/box';
import { Value } from 'view/components/values';
import { Field } from '@rexlabs/form';
import { useToken } from '@rexlabs/styling';
import { Grid } from 'view/components/@luna/form/grid';
import { ContactSelect } from 'view/components/inputs/selects/contact';

import { ArrearsTask } from 'src/modules/tasks/arrears/types/ArrearsTask';

const validate = {
  definitions: {
    managed_by: { name: 'managed by', rules: 'required' }
  }
};

export const arrearsDetailsBlock: BlockConfig<ArrearsTask> = {
  id: 'details',
  title: 'Details',
  validate,
  View: ({ data }) => {
    return (
      <Grid columns={2}>
        <Value
          label='Property'
          value={data?.details?.property_tenancy?.property?.address_string}
        />
        <Value
          label='Tenancy'
          value={data?.details?.property_tenancy?.tenancy?.display_name}
        />
        <Value label='Managed by' value={data?.managed_by?.display_name} />
      </Grid>
    );
  },
  Edit: ({ values, editMode }) => {
    const token = useToken();

    return (
      <Box sy={token('spacing.xxl')}>
        <Grid columns={2}>
          <Value
            label='Property'
            value={values?.details?.property_tenancy?.property?.address_string}
          />
          <Value
            label='Tenancy'
            value={values?.details?.property_tenancy?.tenancy?.display_name}
          />

          <Box sy={token('spacing.xxl')} pt={token('spacing.m')}>
            <Grid columns={2}>
              <Field
                id='managed_by'
                name='managed_by'
                label='Managed by'
                Input={ContactSelect}
                inputProps={{
                  // NOTE: we need to disable the fixture when editing the block, as it causes the app to
                  // become unresponsive. https://rexsoftware.atlassian.net/browse/ALFIE-2066
                  disableFixture: !!editMode
                }}
              />
            </Grid>
          </Box>
        </Grid>
      </Box>
    );
  }
};
