import React, { useCallback } from 'react';

import { RecordSubmitHandler } from 'view/components/record-screen/utils';
import { useModelActions } from '@rexlabs/model-generator';
import { ownershipsFeesInactiveTemplatesModel } from 'data/models/entities/ownerships/fees/inactive-ownership-fees';
import { DialogContentConfig } from 'view/components/record-screen/dialog/types';
import { RecordDialog } from 'view/components/record-screen/dialog/dialog';
import { AgencyFee } from 'src/modules/agency-fees/types/agency-fee-types';
import { inactiveOwnershipFeesBlock } from '../blocks/inactive-ownership-fees';

const content: DialogContentConfig = [
  {
    id: 'ownership-fee',
    label: '',
    blocks: [inactiveOwnershipFeesBlock]
  }
];

interface AddOwnershipFeeDialogProps {
  onClose?: () => void;
  onAdd?: (addedAgencyFees: AgencyFee[]) => Promise<void>;
  ownershipId: string;
  refreshListId?: string;
}

export function AddOwnershipFeeDialog({
  onClose,
  onAdd,
  ownershipId
}: AddOwnershipFeeDialogProps) {
  const { createNewFee } = useModelActions(
    ownershipsFeesInactiveTemplatesModel
  );

  const handleSubmit: RecordSubmitHandler = useCallback(
    async ({ values }) => {
      const inactiveOwnershipFees = values.inactive_ownership_fees as AgencyFee[];

      const feesPromiseArray = inactiveOwnershipFees.map((fee) =>
        createNewFee({
          agencyFeeId: fee.id,
          ownershipId
        })
      );

      const results = await Promise.all(feesPromiseArray);

      if (onAdd) await onAdd(inactiveOwnershipFees);

      return results;
    },
    [createNewFee, ownershipId]
  );

  return (
    <RecordDialog
      onClose={onClose}
      title='Add ownership fee(s)'
      handleSubmit={handleSubmit}
      content={content}
      blockProps={{
        ownershipId
      }}
    />
  );
}
