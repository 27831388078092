import React from 'react';
import { Message } from 'view/components/@luna/message';
import { createStaticTableBlock } from 'view/components/record-screen/cards/create-static-table-block';
import { Disbursement } from 'data/models/entities/financials/disbursements/disbursements';
import { transformActionDeclarationsToActionMenuItems } from 'utils/actions/transforms';
import { RecordTypes } from 'data/models/types';
import { TrustJournalEntryLineItem } from 'src/modules/trust-journal-entry-line-items/types/trust-journal-entry-line-item-type';
import { useGetPrimaryRecordActionGroup } from 'view/hooks/actions/use-get-primary-record-action-group';
import EmptyTrustJournalEntriesTable from '../../../assets/illustrations/empty-trust-journal-entries-table.svg';
import { disbursementBillAndFeeTransferColumns } from '../utils/disbursement-bill-and-fee-transfers-columns';

export const useDisbursementBillAndFeeTransfersBlock = () => {
  const getTrustJournalEntryViewAction = useGetPrimaryRecordActionGroup();
  const getInvoiceViewAction = useGetPrimaryRecordActionGroup();

  return createStaticTableBlock<Disbursement, TrustJournalEntryLineItem>({
    id: 'disbursementBillAndFeeTransfers',
    title: 'Bills & fees paid',
    getTableProps: ({ data }) => {
      return {
        id: 'disbursementBillAndFeeTransfers',
        items: data?.bill_payment_line_items?.items ?? [],
        columns: disbursementBillAndFeeTransferColumns,
        getActionMenuItems: ({ item }) =>
          transformActionDeclarationsToActionMenuItems([
            ...getTrustJournalEntryViewAction(
              RecordTypes.Invoice,
              item.invoice.id,
              'View bill'
            ),
            ...getInvoiceViewAction(
              RecordTypes.TrustJournalEntry,
              item.trust_journal_entry.id,
              'View transfer'
            )
          ])
      };
    },
    Empty: () => (
      <Message
        Illustration={EmptyTrustJournalEntriesTable}
        title='There are no Bill &amp; fee transfers at this moment'
      >
        You have no Bill &amp; fee transfers that require your attention at this
        moment.
      </Message>
    )
  });
};
