import { Includes } from '@rexlabs/model-generator';

import { Generator, GeneratorConfig } from 'data/models/generator';
import {
  ValueListValue,
  Payable,
  BaseFields,
  RecordTypes
} from 'data/models/types';
import { Author } from 'src/modules/authors/types/author-types';
import { TrustJournalEntry } from 'src/modules/trust-journal-entries/types/trust-journal-entry-types';
import { usersModel } from 'src/modules/users/models/users';
import { CreditNoteStatus } from 'utils/static-value-lists/credit-note-status';
import { Invoice, InvoiceLineItem, TaxSummaryItem } from './invoices';

type CreditNoteType = 'credit' | 'refund';

type CreditNoteTemplate =
  | 'bad_debt'
  | 'funds_already_held'
  | 'funds_paid_direct_to_biller'
  | 'reverse'
  | 'discount'
  | 'custom'
  | 'custom_does_not_impact_rent';

type InvoicePaymentEntry = {
  id: string;
  type: ValueListValue<CreditNoteType>;
  amount: number;
  created_at: Date;
  updated_at: Date;

  invoice?: Invoice;
  journal_entry?: TrustJournalEntry;
};

export type CreditNoteApplication = {
  id: string;
  amount: number;
  type: ValueListValue<CreditNoteType>;
  reverses: CreditNoteApplication | null;
  reversed_by: CreditNoteApplication | null;
  impacts_rent_paid: boolean;
  invoice_payment_entry?: InvoicePaymentEntry;
};

export interface CreditNote extends BaseFields<typeof RecordTypes.CreditNote> {
  name: string;
  description: string;
  credit_note_date: string;
  is_tax_included: boolean;
  record_reference: string;

  payable_to: Payable;
  payable_by: Payable;

  template: ValueListValue<CreditNoteTemplate>;

  line_items?: InvoiceLineItem[];

  amount_total_inc_tax: number;
  amount_total_exc_tax: number;
  amount_total_tax: number;
  amount_due: number;
  amount_paid: number | null;
  amount_owing: number;

  tax_summary?: TaxSummaryItem[];

  applications?: Includes<CreditNoteApplication[]>;
  application?: CreditNoteApplication;

  status: ValueListValue<CreditNoteStatus>;
  status_reason?: string;

  voided_at: Date | null;
  voided_by: Author;
}

const config: GeneratorConfig = {
  entities: {
    related: {
      line_items: {
        include: [
          'line_items.payable_to_property',
          'line_items.payable_to_chart_of_accounts_account',
          'line_items.payable_by_property',
          'line_items.payable_by_chart_of_accounts_account'
        ].join(',')
      },
      tax_summary: {
        include: 'tax_summary'
      },
      applications: {
        include: [
          'applications.reverses',
          'applications.reversed_by',
          'applications.invoice_payment_entry.invoice'
        ].join(',')
      },
      application: {
        include: 'application.invoice_payment_entry.invoice'
      },
      voided_by: {
        include: 'voided_by',
        model: usersModel
      }
    }
  }
};

export const financialsCreditNotesModel = new Generator<CreditNote>(
  'financials/credit-notes',
  config
).createEntityModel();
