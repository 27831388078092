import React, { useEffect } from 'react';
import { BlockConfig } from 'view/components/record-screen/types';
import { AttachDocumentFromARecordForm } from 'src/modules/communications/messages/types/attach-document-from-a-record';
import { Grid } from 'view/components/@luna/form/grid';
import { SelectRelatesTo } from 'src/modules/communications/messages/components/attach-document-from-a-record/select-relates-to';
import { DocumentsFromRelatedRecord } from 'src/modules/communications/messages/components/attach-document-from-a-record/documents-from-related-record';
import { ComplianceRequirementsFromRelatedRecord } from 'src/modules/communications/messages/components/attach-document-from-a-record/compliance-requrirements-from-related-record';
import { isContactASupplier } from 'src/modules/contacts/utils/is-contact-a-supplier';
import LoadingState from 'view/components/states/compact/loading';
import { useGetRelatedRecordWhenAttachingDocuments } from 'src/modules/communications/messages/hooks/use-get-related-record-when-attaching-documents';
import Box from '@rexlabs/box';
import { HiddenField } from '@rexlabs/form';
import { HasFile } from 'src/modules/common/types/file';

export const attachDocumentFromARecordBlock: BlockConfig<AttachDocumentFromARecordForm> = {
  id: 'content',
  Edit: ({ values, setFieldValue, forms, getHandlers }) => {
    const {
      isLoading,
      relatedRecord
    } = useGetRelatedRecordWhenAttachingDocuments(values);
    const handlers = getHandlers?.(forms);

    useEffect(() => {
      handlers?.handleSelectedFilesChanged?.();
    }, [handlers, values]);

    if (isLoading) {
      return <LoadingState />;
    }

    return (
      <Grid columns={1}>
        <HiddenField name={'selected_documents'} />
        <HiddenField name={'selected_compliance_requirements'} />

        <SelectRelatesTo
          onChange={() => {
            setFieldValue?.('selected_documents', []);
            setFieldValue?.('selected_compliance_requirements', []);
          }}
        />
        {relatedRecord && (
          <Box flexDirection='column' gap={40}>
            <Box>
              <DocumentsFromRelatedRecord
                // hack to force refresh onchange. In the component the related
                // record changed, and that even changed the RecordTable query,
                // but that did not trigger it to re-fetch and re-render the table.
                key={relatedRecord.id || 0}
                relatedRecord={relatedRecord}
                onSelectionChange={(selectedItems: HasFile[]) => {
                  setFieldValue?.('selected_documents', selectedItems);
                }}
              />
            </Box>
            {(relatedRecord.type.id === 'property' ||
              (relatedRecord.type.id === 'contact' &&
                isContactASupplier(relatedRecord.record))) && (
              <Box>
                <ComplianceRequirementsFromRelatedRecord
                  relatedRecord={relatedRecord}
                  onSelectionChange={(selectedItems: HasFile[]) => {
                    setFieldValue?.(
                      'selected_compliance_requirements',
                      selectedItems
                    );
                  }}
                />
              </Box>
            )}
          </Box>
        )}
      </Grid>
    );
  }
};
