import { NormalisedItem, Select } from '@rexlabs/select';
import * as React from 'react';
import { PortfolioMember } from 'src/modules/portfolios/models/portfolio-members-model';

const normaliser = (item: PortfolioMember): NormalisedItem => ({
  id: item.id,
  label: item.user!.display_name || ''
});

export function PortfolioMemberSelect(props) {
  return (
    <Select normaliser={normaliser} {...props} items={props.items || []} />
  );
}
