import * as React from 'react';
import Box from '@rexlabs/box';
import { FieldArray } from '@rexlabs/form';
import {
  BlockConfig,
  BlockViewProps
} from 'view/components/record-screen/types';
import {
  ChildTransactionsTable,
  transactionColumns
} from '../components/child-transaction-table';
import { NewSplitTransactionForm } from '../components/new-split-transaction-form';
import { RemoveCell } from '../components/remove-cell';
import {
  BankStatementChildTransaction,
  NewSplitTransactionFormValue
} from '../types';
import { checkTransactionState } from '../utils/check-transaction-state';

type BlockProps = BlockViewProps<
  any,
  {
    childTransactions: BankStatementChildTransaction[];
  }
>;

function View({ values }: BlockProps) {
  return (
    <Box flexDirection='column' gap={24}>
      <ChildTransactionsTable items={values!.childTransactions} />
    </Box>
  );
}

function Edit({ values }: BlockProps) {
  const handleNewLineItem = React.useCallback((values, form, push) => {
    push({
      ...values,
      id: `new:${Date.now()}`
    });

    form.resetForm();
  }, []);

  const handleRemove = React.useCallback(
    (item, remove) => {
      const index = values!.childTransactions.findIndex(
        (transaction) => transaction.id === item.id
      );

      remove(index);
    },
    [values!.childTransactions]
  );

  return (
    <FieldArray name='childTransactions'>
      {({ push, remove }) => (
        <Box flexDirection='column' gap={24}>
          <NewSplitTransactionForm
            handleSubmit={(values, form) =>
              handleNewLineItem(values, form, push)
            }
          />

          <ChildTransactionsTable
            columns={[
              ...transactionColumns,
              {
                id: 'action',
                Cell: withReceiptCheck(RemoveCell),
                accessor: (item) => {
                  return {
                    item,
                    remove: () => handleRemove(item, remove)
                  };
                },
                width: 70,
                sticky: 'right'
              }
            ]}
            items={values!.childTransactions}
          />
        </Box>
      )}
    </FieldArray>
  );
}

export const childTransactionWithStatusBlock: BlockConfig = {
  id: 'split-child-transaction-with-status',
  title: 'Child transactions',

  Edit: (props) => {
    const isEditing = props.blockProps.mode === 'edit';

    return isEditing ? <Edit {...props} /> : <View {...props} />;
  }
};

function withReceiptCheck(Component) {
  return (props: {
    value: {
      item: BankStatementChildTransaction | NewSplitTransactionFormValue;
      remove: () => void;
    };
  }) => {
    const { value } = props;
    const state = checkTransactionState(value.item);

    // This transaction is already receipted
    if (state === 'receipted') {
      return null;
    }

    return <Component {...props} />;
  };
}
