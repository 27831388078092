import React, { useCallback } from 'react';

import { RecordDialog } from 'view/components/record-screen/dialog/dialog';
import { DialogContentConfig } from 'view/components/record-screen/dialog/types';

import { RecordSubmitHandler } from 'view/components/record-screen/utils';
import { useModelActions } from '@rexlabs/model-generator';
import { trustJournalEntryModel } from 'src/modules/trust-journal-entries/models/trust-journal-entries';
import { TrustJournalEntry } from 'src/modules/trust-journal-entries/types/trust-journal-entry-types';
import { RecordType } from 'data/models/types';
import { bankTransactionDetailsBlock } from 'src/modules/banking/common/blocks/bank-transaction-details';
import {
  chequeBankWithdrawalDetailsBlock,
  TrustJournalEntryWithChequeNumbers
} from '../blocks/cheque-bank-withdrawal-details';

const content: DialogContentConfig = [
  {
    id: 'basics',
    label: 'Basics',
    blocks: [bankTransactionDetailsBlock, chequeBankWithdrawalDetailsBlock]
  }
];

interface CreateChequeBankWithdrawalDialogProps {
  updateListId?: string;
  onClose?: () => void;
  onCreate: (
    lineItems: TrustJournalEntryWithChequeNumbers[],
    dateOf: Date
  ) => Promise<void>;
  trustJournalEntries: TrustJournalEntry[];
  recordType: RecordType;
}

export function CreateChequeBankWithdrawalDialog({
  updateListId,
  onCreate,
  onClose,
  trustJournalEntries,
  recordType
}: CreateChequeBankWithdrawalDialogProps) {
  const { refreshList } = useModelActions(trustJournalEntryModel);
  const handleSubmit: RecordSubmitHandler = useCallback(
    async ({ values }) => {
      await onCreate(values.line_items, values.date_of);

      if (updateListId) {
        await refreshList({ id: updateListId });
      }

      return true;
    },
    [refreshList, updateListId, onCreate]
  );

  const initialData = {
    automatic_cheque_numbering: true,
    line_items: trustJournalEntries
  };

  return (
    <RecordDialog
      onClose={onClose}
      data={initialData}
      blockProps={{
        trustJournalEntries,
        recordType
      }}
      title='Create cheque bank withdrawal'
      handleSubmit={handleSubmit}
      content={content}
      submitLabel='Create cheque bank withdrawal'
    />
  );
}
