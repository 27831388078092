import { ComplianceType } from 'src/modules/compliance/common/models/compliance-types';
import { useDialog } from '@rexlabs/dialog';
import { useRenewRequirementToast } from 'src/modules/compliance/common/hooks/use-renew-requirement-toast';
import { Banner } from 'src/modules/common/components/banner';
import InfoIcon from 'view/components/icons/info';
import Box from '@rexlabs/box';
import { ActionButtons } from 'view/components/action-buttons';
import React from 'react';
import { SupplierRenewEntryDialog } from 'src/modules/compliance/common/dialogs/supplier-renew-entry-dialog';

export function SupplierInfoComplianceBanner({
  complianceType,
  contactId
}: {
  complianceType: ComplianceType;
  contactId?: string;
}) {
  const complianceTypeName = complianceType?.label;
  const renewEntryDialog = useDialog(SupplierRenewEntryDialog);
  const addToast = useRenewRequirementToast({ complianceTypeName });
  return (
    <Banner intent={'information'} Icon={InfoIcon} align={'start'}>
      <Box
        alignItems={'center'}
        justifyContent={'space-between'}
        width={'100%'}
      >
        <div>
          <strong>{complianceTypeName}</strong> needs to be added to this
          supplier
        </div>
        <ActionButtons
          actions={[
            {
              label: 'Add',
              onClick: () =>
                renewEntryDialog.open({
                  onSave: addToast,
                  complianceType: complianceType,
                  contactId
                })
            }
          ]}
        />
      </Box>
    </Banner>
  );
}
