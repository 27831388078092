export const USER_ROUTES = {
  PROFILE_SETTINGS: {
    config: {
      path: '/profile-settings',
      Component: require('./screens/profile-settings').ProfileSettingsScreen
    }
  },
  USERS_LIST: {
    config: {
      path: '/users',
      Component: require('./screens/users-list').UsersList
    },
    INVITE_USER: {
      config: {
        path: '/users/invite-user',
        Component: require('./dialogs/invite-user-dialog').InviteUserDialog
      }
    }
  },
  USER: {
    config: {
      path: '/user/:userId',
      Component: require('./screens/user-details').UserDetailsScreen
    }
  },
  CONFIRM_EMAIL: {
    config: {
      path: '/confirm-email',
      Component: require('./screens/confirm-email').ConfirmEmailScreen
    }
  }
};
