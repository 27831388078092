import React from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import dayjs from 'dayjs';

import Box from '@rexlabs/box';
import { OutlineButton } from '@rexlabs/button';
import { useDialog } from '@rexlabs/dialog';
import { StyleSheet, mq, useStyles } from '@rexlabs/styling';
import { useMediaQuery } from '@rexlabs/breakpoints';

import { SingleActionDeclaration } from 'src/modules/common/actions/types/action-declaration-types';
import { useGetEditLocationAction } from 'src/modules/properties/utils/property-actions/use-get-edit-location-action';
import { invokeActionDeclaration } from 'src/modules/common/actions/utils/invoke-action-declaration';

import AddIcon from 'view/components/icons/add';
import { useGetPrimaryRecordActionGroup } from 'view/hooks/actions/use-get-primary-record-action-group';
import RenderLoading from 'view/components/@luna/render-loading';

import { transformActionDeclarationsToActionMenuItems } from 'utils/actions/transforms';

import { useGetAddDocumentsAction } from 'src/modules/documents/actions/use-get-add-documents-action';
import { useGetEditInspectionRunStart } from '../hooks/use-get-edit-inspection-run-start';
import { useGetEditInspectionRunFinish } from '../hooks/use-get-edit-inspection-run-finish';
import { AddInspectionDialog } from '../dialogs/add-inspections-to-inspection-run-dialog';
import { useInspectionRunContext } from '../hooks/use-inspection-run-context';
import { InspectionTask } from '../types/InspectionTask';
import { useGetAddNotesAction } from '../../common/actions/get-add-notes-action';
import { useGetValidateAndFixInspectionsForRun } from '../hooks/use-get-validate-and-fix-inspections-for-run';
import { InspectionRunTimeAndDistance } from './inspection-run-time-and-distance';
import { InspectionRunBookEndCard } from './inspection-run-book-end-card';
import { InspectionRunInspectionCard } from './inspection-run-inspection-card';

const defaultStyles = StyleSheet({
  outerContainer: {
    ...mq.styles({
      queries: {
        maxWidth: 'm'
      },
      styles: {
        paddingTop: ({ token }) => token('spacing.xs')
      }
    })
  },
  withResponsiveRowGap: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    rowGap: 40,
    ...mq.styles({
      queries: {
        maxWidth: 's'
      },
      styles: {
        rowGap: ({ token }) => token('spacing.xs')
      }
    })
  }
});

export function InspectionRunPlanner({ isEditMode }) {
  const matchesMobile = useMediaQuery({ maxWidth: 's' });

  const s = useStyles(defaultStyles);

  const getEditInspectionRunStart = useGetEditInspectionRunStart();
  const getEditInspectionRunFinish = useGetEditInspectionRunFinish();
  const getValidateAndFixInspectionsForRun = useGetValidateAndFixInspectionsForRun();

  const { open: openAddInspectionToInspectionRunDialog } = useDialog(
    AddInspectionDialog
  );

  const {
    inspectionRunInspections,
    removeInspectionRunInspection,
    refreshInspectionRunInspections,
    addInspectionsToInspectionRun,
    inspectionRun,
    setDurationForInspectionRunInspection,
    getDistanceAndDurationArray,
    updateInspectionRun
  } = useInspectionRunContext();

  const getPrimaryRecordActionGroup = useGetPrimaryRecordActionGroup();
  const getAddDocumentsAction = useGetAddDocumentsAction();
  const getAddNotesAction = useGetAddNotesAction();
  const getEditLocationAction = useGetEditLocationAction();

  const onDragEnd = (result) => {
    // NOTE: If dropped outside the list, bounce back to original position
    if (!result.destination) {
      return;
    }

    const items = reorderInspectionRunInspections(
      inspectionRunInspections!,
      result.source.index,
      result.destination.index
    );
    refreshInspectionRunInspections?.(items);
  };

  const handleMouseDown = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.currentTarget.focus();
  };

  const isLoading = !inspectionRunInspections && !inspectionRun;

  return (
    <RenderLoading isLoading={isLoading}>
      <Box flexDirection='column' {...s('outerContainer')}>
        <Box flexDirection='row'>
          {!matchesMobile && (
            <InspectionRunTimeAndDistance
              timeAndDistance={getDistanceAndDurationArray!()}
            />
          )}
          <DragDropContext onDragEnd={onDragEnd}>
            <Box {...s('withResponsiveRowGap')}>
              <InspectionRunBookEndCard
                address={inspectionRun!.start_address!}
                time={dayjs(inspectionRun!.start_at!).tz().format('HH:mm a')}
                handleEdit={() => getEditInspectionRunStart()}
                isEditMode={isEditMode}
                isStart
              />
              {!!inspectionRunInspections!.length && (
                <Droppable
                  direction='vertical'
                  ignoreContainerClipping
                  droppableId='draggable-list'
                >
                  {(provided) => (
                    <Box
                      ref={provided.innerRef}
                      {...s('withResponsiveRowGap')}
                      {...provided.droppableProps}
                    >
                      {inspectionRunInspections!.map((inspection, index) => {
                        const { id, details } = inspection;

                        const {
                          scheduled_duration: duration = 0,
                          scheduled_at: start_time
                        } = details!;

                        const actions: SingleActionDeclaration[] = [
                          ...getPrimaryRecordActionGroup(
                            'task_inspection',
                            inspection.id
                          ),

                          getAddDocumentsAction({ data: inspection }),
                          getAddNotesAction({ data: inspection }),
                          {
                            label: 'Edit location',
                            handleAction: () =>
                              invokeActionDeclaration(getEditLocationAction, {
                                property: inspection.property!,
                                afterAction: async (property) => {
                                  const updatedInspection: InspectionTask = {
                                    ...inspection,
                                    property
                                  };
                                  const updatedInspectionRunInspections = [
                                    ...inspectionRunInspections!
                                  ];
                                  updatedInspectionRunInspections[
                                    index
                                  ] = updatedInspection;
                                  await refreshInspectionRunInspections!(
                                    updatedInspectionRunInspections
                                  );

                                  await updateInspectionRun!();
                                }
                              })
                          }
                        ];
                        return (
                          <Draggable draggableId={id} key={id} index={index}>
                            {(provided) => (
                              <Box
                                // @ts-ignore
                                ref={provided.innerRef}
                                style={provided.draggableProps.style}
                                {...provided.draggableProps}
                              >
                                <InspectionRunInspectionCard
                                  address={inspection.property!.display_name}
                                  duration={duration}
                                  handleRemove={() =>
                                    removeInspectionRunInspection?.(index)
                                  }
                                  setDuration={(duration) =>
                                    setDurationForInspectionRunInspection?.(
                                      duration,
                                      inspection.id
                                    )
                                  }
                                  inspectionType={inspection.details!.type}
                                  handleDrag={handleMouseDown}
                                  time={dayjs(start_time!)
                                    .tz()
                                    .format('HH:mm a')}
                                  dragProps={provided.dragHandleProps}
                                  isEditMode={isEditMode}
                                  actions={transformActionDeclarationsToActionMenuItems(
                                    actions
                                  )}
                                />
                              </Box>
                            )}
                          </Draggable>
                        );
                      })}
                      {provided.placeholder}
                    </Box>
                  )}
                </Droppable>
              )}
              <InspectionRunBookEndCard
                address={inspectionRun!.finish_address!}
                time={dayjs(inspectionRun!.finish_at).tz().format('HH:mm a')}
                isEditMode={isEditMode}
                handleEdit={() => getEditInspectionRunFinish()}
              />
              {isEditMode && (
                <div>
                  <OutlineButton
                    IconLeft={AddIcon}
                    onClick={() =>
                      openAddInspectionToInspectionRunDialog({
                        existingInspectionIds: inspectionRunInspections!.map(
                          (inspectionRunInspection) =>
                            inspectionRunInspection.id
                        ),
                        submitHandler: ({ values }) => {
                          const validateAndFixInspectionsForRun = getValidateAndFixInspectionsForRun(
                            values.inspections!,
                            (inspections) => {
                              return addInspectionsToInspectionRun?.(
                                inspections
                              );
                            }
                          );
                          return validateAndFixInspectionsForRun();
                        }
                      })
                    }
                  >
                    Add inspection(s)
                  </OutlineButton>
                </div>
              )}
            </Box>
          </DragDropContext>
        </Box>
      </Box>
    </RenderLoading>
  );
}

export function reorderInspectionRunInspections(
  list: InspectionTask[],
  startIndex: number,
  endIndex: number
) {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
}
