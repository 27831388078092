import { CommunicationContextType } from 'src/modules/communications/common/types/communication-context-type';
import {
  ContactMessageTriggerKey,
  OwnershipMessageTriggerKey,
  TenancyMessageTriggerKey,
  MessageTriggerKey
} from '../types/MessageTriggerKey';
import { BlockId, blockIds } from '../types/MessageTriggerSettingsBlockId';

type MessageTriggerKeyFromBlockId<
  T extends BlockId
> = T extends 'contact-message-triggers'
  ? ContactMessageTriggerKey
  : T extends 'ownership-message-triggers'
  ? OwnershipMessageTriggerKey
  : T extends 'tenancy-message-triggers'
  ? TenancyMessageTriggerKey
  : never;

type FieldMapItem = {
  label: string;
  context_type_id: CommunicationContextType;
  recipient_groups?: string[];
};

type FieldLabelMap<T extends BlockId> = Record<
  MessageTriggerKeyFromBlockId<T>,
  FieldMapItem
>;

const contactMessageTriggerMap: FieldLabelMap<'contact-message-triggers'> = {
  'contact-disbursement-template': {
    label: 'On disbursement',
    context_type_id: 'contact_disbursement'
  },
  'contact-payment-receipt-template': {
    label: 'Payment receipt created',
    context_type_id: 'contact_payment_receipt'
  }
};

const ownershipMessageTriggerMap: FieldLabelMap<'ownership-message-triggers'> = {
  'ownership-disbursement-template': {
    label: 'On disbursement',
    context_type_id: 'ownership_disbursement'
  },
  'periodic-ownership-statement-template': {
    label: 'Periodic income statement issued',
    context_type_id: 'periodic_ownership_statement'
  },
  'yearly-ownership-statement-template': {
    label: 'Income financial year statement issued',
    context_type_id: 'yearly_ownership_statement'
  },
  'inspection-template': {
    label: 'Inspection report created',
    context_type_id: 'task_inspection'
  },
  'ownership-invoice-template': {
    label: 'Invoice created (and email included)',
    context_type_id: 'invoice',
    recipient_groups: ['payable_by']
  }
};

const tenancyMessageTriggerMap: FieldLabelMap<'tenancy-message-triggers'> = {
  'tenancy-invoice-template': {
    label: 'Invoice created (and email included)',
    context_type_id: 'invoice',
    recipient_groups: ['payable_by', 'payable_by_tenancy_tenants']
  }
};

export function getMessageTriggerSettingsMapForBlockId<T extends BlockId>(
  blockId: T
): FieldLabelMap<T> {
  const messageTriggerMap: Record<BlockId, FieldLabelMap<BlockId>> = {
    'contact-message-triggers': contactMessageTriggerMap,
    'ownership-message-triggers': ownershipMessageTriggerMap,
    'tenancy-message-triggers': tenancyMessageTriggerMap
  };
  return messageTriggerMap[blockId];
}

export const messageTriggerSettingsMap = blockIds.reduce<
  Record<MessageTriggerKey, FieldMapItem>
>((acc, blockId) => {
  const fieldMap = getMessageTriggerSettingsMapForBlockId(blockId);

  return { ...acc, ...fieldMap };
}, {} as Record<MessageTriggerKey, FieldMapItem>);
