import { useDialog } from '@rexlabs/dialog';
import { SingleActionDeclaration } from 'src/modules/common/actions/types/action-declaration-types';
import UploadIcon from 'view/components/icons/upload';
import { UploadBillsDialog } from '../../dialogs/upload-bills-dialog';

export function useGetUploadBillsAction(): SingleActionDeclaration {
  const { open } = useDialog(UploadBillsDialog);

  return {
    id: 'upload-bills',
    intent: 'primary',
    Icon: UploadIcon,
    group: 'upload-bills',
    label: 'Upload bills',
    handleAction: async () => {
      open();
    }
  };
}
