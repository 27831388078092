import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';
import { cloneDeep } from 'lodash';
import dayjs from 'dayjs';

import ROUTES from 'routes/app';

import { query, useEntityQuery } from '@rexlabs/model-generator';
import { useConfirmationDialog, useErrorDialog } from '@rexlabs/dialog';
import { push } from '@rexlabs/whereabouts';
import { Body } from '@rexlabs/text';

import {
  fetchRoute,
  DEPART_AT_DATE_FORMAT
} from 'src/lib/mapbox/utils/fetch-route';
import { Leg } from 'src/lib/mapbox/types/Leg';

import { useToast } from 'view/components/@luna/notifications/toast';
import InfoCircleIcon from 'view/components/icons/info';
import WarningCircleIcon from 'view/components/icons/warning-circle';

import { inspectionRunsModel } from '../models/inspection-run-model';
import { InspectionTask } from '../types/InspectionTask';
import { InspectionRun, InspectionRunDetails } from '../types/InspectionRun';
import { InspectionDuration } from '../components/inspection-duration';
import { getRouteCoordinates } from '../utils/get-route-coordinates';
import { mapRouteToInspectionTask } from '../mappers/map-route-to-inspection-task';
import { getEndTimeFromRoute } from '../utils/get-end-time-from-route';
import { getDuration } from '../constants/DefaultInspectionDuration';
import { mapInspectionToInspectionRunDetails } from '../mappers/map-inspection-to-inspection-run-details';
import { INSPECTION_SAVE_REQUEST_DATE_FORMAT } from '../constants/InspectionSaveRequestDateFormat';

const getInspectionRunQuery = (id: string) => query`{
    ${inspectionRunsModel} (id: ${id}) {
      id,
      inspection_tasks {
        property
        details
      }
    } 
  }`;

type InspectionRunContext = {
  isLoading: boolean;
  /**
   * Current state of inspection run.
   */
  inspectionRun?: InspectionRun;
  /**
   * Current state of inspection run inspections.
   */
  inspectionRunInspections?: InspectionTask[];
  /**
   * Used to add inspections to the inspection run.
   */
  addInspectionsToInspectionRun?: (
    inspectionsToAdd: InspectionTask[]
  ) => InspectionTask[];
  /**
   * Refresh the inspection run inspections to make adjustments for the data that has been added/modified.
   */
  refreshInspectionRunInspections?: (
    newInspectionRunInspections: InspectionTask[],
    newInspectionRun?: InspectionRun
  ) => Promise<void>;
  /**
   * Remove an inspection from the inspection run.
   */
  removeInspectionRunInspection?: (
    indexOfInspectionRunInspection: number
  ) => void;
  /**
   * Save the inspection run.
   */
  updateInspectionRun?: () => Promise<InspectionRun | void>;
  /**
   * Edit the inspection run details eg. start time, finish time, start location, finish location.
   */
  editInspectionRunDetails?: (values: Partial<InspectionRunDetails>) => void;
  /**
   * Reset the inspection run to the state it was in when it was fetched from the API.
   */
  resetInspectionRun?: () => Promise<InspectionRun>;
  /**
   * Set the duration for an inspection in the inspection run.
   */
  setDurationForInspectionRunInspection?: (
    newDuration: InspectionDuration,
    inspectionId: string
  ) => void;
  /**
   * Get the distance and duration for each inspection in the inspection run.
   */
  getDistanceAndDurationArray?: () => {
    distance: number;
    time: number;
  }[];
};

const InspectionRunContext = createContext<InspectionRunContext>({
  isLoading: true,
  inspectionRun: undefined,
  addInspectionsToInspectionRun: undefined,
  inspectionRunInspections: undefined,
  refreshInspectionRunInspections: undefined,
  removeInspectionRunInspection: undefined,
  updateInspectionRun: undefined,
  editInspectionRunDetails: undefined,
  resetInspectionRun: undefined,
  setDurationForInspectionRunInspection: undefined,
  getDistanceAndDurationArray: undefined
});

export function InspectionRunContextProvider({ inspectionRunId, children }) {
  const [route, setRoute] = useState<Leg[] | undefined>();
  const [inspectionRun, setInspectionRun] = useState<
    InspectionRun | undefined
  >();
  const [inspectionRunInspections, setInspectionRunInspections] = useState<
    InspectionTask[] | undefined
  >();

  const { addToast } = useToast();

  const query = useMemo(() => getInspectionRunQuery(inspectionRunId!), [
    inspectionRunId
  ]);
  const {
    data: inspectionRunData,
    actions: { updateItem, trashItem }
  } = useEntityQuery(query, {
    throwOnError: false
  });
  const { open: openConfirmationDialog } = useConfirmationDialog();
  const { open: openErrorDialog } = useErrorDialog();

  const refreshInspectionRunInspections = async (
    newInspectionRunInspections: InspectionTask[],
    newInspectionRun?: InspectionRun
  ) => {
    const run = newInspectionRun || inspectionRun;
    const route = await fetchRoute(
      getRouteCoordinates(
        [parseFloat(run!.start_longitude), parseFloat(run!.start_latitude)],
        newInspectionRunInspections!,
        [parseFloat(run!.finish_longitude), parseFloat(run!.finish_latitude)]
      ),
      dayjs(run!.start_at).tz().format(DEPART_AT_DATE_FORMAT)
    );

    const mappedInspections = mapRouteToInspectionTask(
      route.routes[0].legs,
      newInspectionRunInspections!,
      getDuration,
      dayjs(run!.start_at).tz()
    );

    const newEndTime = getEndTimeFromRoute(
      route.routes[0].legs,
      mappedInspections,
      dayjs(run!.start_at).tz()
    );

    setInspectionRun({
      ...run!,
      finish_at: newEndTime.format(INSPECTION_SAVE_REQUEST_DATE_FORMAT)
    });

    setInspectionRunInspections(mappedInspections);
    setRoute(route.routes[0].legs);
  };

  const removeInspectionRunInspection = (
    indexOfInspectionRunInspection: number
  ) => {
    const updatedInspectionRunInspections = Array.from(
      inspectionRunInspections!
    );
    updatedInspectionRunInspections.splice(indexOfInspectionRunInspection, 1);

    refreshInspectionRunInspections(updatedInspectionRunInspections);
  };

  const addInspectionsToInspectionRun = (
    inspectionsToAdd: InspectionTask[]
  ) => {
    const newInspectionRunInspections = [
      ...inspectionRunInspections!,
      ...inspectionsToAdd
    ];

    refreshInspectionRunInspections(newInspectionRunInspections);
    return newInspectionRunInspections;
  };

  const editInspectionRunDetails = (values: Partial<InspectionRunDetails>) => {
    const updatedRun = { ...inspectionRun, ...values } as InspectionRun;

    setInspectionRun(updatedRun);

    refreshInspectionRunInspections(inspectionRunInspections!, updatedRun);
  };

  const updateInspectionRun = async () => {
    if (inspectionRunInspections!.length === 0) {
      return openConfirmationDialog({
        TitleIcon: WarningCircleIcon,
        title: 'Cancel inspection run',
        message: (
          <>
            <Body>
              There are no inspections in the current inspection run. If you
              proceed, this inspection run will be discarded. Any inspections
              that were included in this run will have the inspection date and
              time reset and status changed to created.
            </Body>
            <Body>Do you want to continue?</Body>
          </>
        ),
        confirmText: 'Yes, cancel inspection run',
        cancelText: 'No, keep this inspection run',
        onConfirm: async () => {
          await trashItem({ id: inspectionRunId });
          return push(ROUTES.INSPECTION_TASK_LIST);
        }
      });
    } else {
      try {
        const { data } = await updateItem({
          id: inspectionRunId,
          data: {
            start_address: inspectionRun?.start_address,
            finish_address: inspectionRun?.finish_address,
            start_latitude: inspectionRun?.start_latitude,
            start_longitude: inspectionRun?.start_longitude,
            finish_latitude: inspectionRun?.finish_latitude,
            finish_longitude: inspectionRun?.finish_longitude,
            start_at: dayjs(inspectionRun?.start_at)
              .tz()
              .format(INSPECTION_SAVE_REQUEST_DATE_FORMAT),
            finish_at: dayjs(inspectionRun?.finish_at)
              .tz()
              .format(INSPECTION_SAVE_REQUEST_DATE_FORMAT),
            inspection_tasks: inspectionRunInspections
          },
          args: {
            include: [
              'inspection_tasks',
              'inspection_tasks.property',
              'inspection_tasks.property.address',
              'inspection_tasks.details'
            ].join(',')
          }
        });

        addToast({
          Icon: InfoCircleIcon,
          description: (
            <>
              <b>{inspectionRunInspections!.length}</b> inspections have been
              scheduled for{' '}
              <b>{dayjs(inspectionRun?.start_at).tz().format('DD MMM YYYY')}</b>
            </>
          )
        });

        return data;
      } catch (error) {
        openErrorDialog(error);
      }
    }
  };

  const resetInspectionRun = async () => {
    // This is to make sure we preserve the original inspection run data
    const cloneOfOriginalInspectionRun = cloneDeep(inspectionRunData!);

    const inspectionsInOrder = Array.from(
      cloneOfOriginalInspectionRun!.inspection_tasks.data.sort((a, b) =>
        a.details!.scheduled_order! > b.details!.scheduled_order! ? 1 : -1
      )
    ).map(mapInspectionToInspectionRunDetails);

    const route = await fetchRoute(
      getRouteCoordinates(
        [
          parseFloat(cloneOfOriginalInspectionRun!.start_longitude),
          parseFloat(cloneOfOriginalInspectionRun!.start_latitude)
        ],
        inspectionsInOrder,
        [
          parseFloat(cloneOfOriginalInspectionRun!.finish_longitude),
          parseFloat(cloneOfOriginalInspectionRun!.finish_latitude)
        ]
      ),
      dayjs(cloneOfOriginalInspectionRun!.start_at)
        .tz()
        .format(DEPART_AT_DATE_FORMAT)
    );

    setInspectionRun(cloneOfOriginalInspectionRun);
    setInspectionRunInspections(inspectionsInOrder);
    setRoute(route.routes[0].legs);

    return inspectionRunData!;
  };

  const setDurationForInspectionRunInspection = (
    newDuration: InspectionDuration,
    inspectionId
  ) => {
    const updatedInspectionRunInspections = Array.from(
      inspectionRunInspections!
    );
    const indexOfInspectionToEdit = updatedInspectionRunInspections.findIndex(
      (inspectionRunInspection) => inspectionRunInspection.id === inspectionId
    );
    updatedInspectionRunInspections[
      indexOfInspectionToEdit
    ].details!.scheduled_duration = newDuration;

    // We don't need to reset the inspection run, just update the start times for any
    // inspections that come after the inspection that was updated.
    const mappedInspections = mapRouteToInspectionTask(
      route!,
      updatedInspectionRunInspections,
      getDuration,
      dayjs(inspectionRun!.start_at).tz()
    );

    const newEndTime = getEndTimeFromRoute(
      route!,
      inspectionRunInspections!,
      dayjs(inspectionRun!.start_at).tz()
    );

    setInspectionRunInspections(mappedInspections);
    setInspectionRun({
      ...inspectionRun!,
      finish_at: newEndTime.format(INSPECTION_SAVE_REQUEST_DATE_FORMAT)
    });
  };

  const getDistanceAndDurationArray = () => {
    // NOTE: If there is a point when there is no inspections, or the route is reduced
    // to 1, then that means we only have the start and finish locations, so we can use
    // the distance and duration of the route to calculate the distance and duration.
    // Normally the start and end will be the same, but if the user has changed the start
    // it should work fine.
    if (inspectionRunInspections?.length === 0 || route?.length === 1) {
      return [
        {
          // NOTE: Need to convert these distance from meters to kilometers, rounded to the nearest 1 decimal place
          distance: parseFloat((route![0].distance / 1000).toFixed(1)),
          // NOTE: Need to convert these time from seconds to minutes, rounded to the nearest whole number
          time: parseInt((route![0].duration / 60).toFixed(0))
        }
      ];
    }

    return route!.reduce<{ distance: number; time: number }[]>(
      (acc, r, index) => {
        // The last item is the
        const isLastItem = index === route!.length - 1;
        const inspection = !isLastItem
          ? inspectionRunInspections![index]
          : null;

        let prevLeaveTime;
        let currStartTime;

        if (index === 0) {
          prevLeaveTime = dayjs(inspectionRun!.start_at).tz();
          currStartTime = dayjs(
            inspection?.details?.scheduled_at || inspectionRun!.start_at
          ).tz();
        } else if (isLastItem) {
          const lastInspection = inspectionRunInspections![
            inspectionRunInspections!.length - 1
          ];
          prevLeaveTime = dayjs(lastInspection!.details!.scheduled_at)
            .tz()
            .add(lastInspection!.details!.scheduled_duration!, 'minutes');
          currStartTime = dayjs(inspectionRun!.finish_at).tz();
        } else {
          const prevInspection = inspectionRunInspections![index - 1];
          const currentInspection = inspectionRunInspections![index];
          if (!currentInspection) {
            // NOTE: This is a safety check, if for some reason the current inspection is undefined
            // This can happen when the we remove an inspection, and the route is not updated
            return acc;
          }
          prevLeaveTime = dayjs(prevInspection.details!.scheduled_at)
            .tz()
            .add(prevInspection.details!.scheduled_duration!, 'minutes');
          currStartTime = dayjs(currentInspection!.details!.scheduled_at).tz();
        }

        return [
          ...acc,
          {
            distance: parseFloat(
              (route![index].distance / 1000).toExponential(1)
            ),
            time: currStartTime.diff(prevLeaveTime, 'minute')
          }
        ];
      },
      []
    );
  };

  // NOTE: Once we have fetched the data from BE, set the inspection run and inspection run inspections
  useEffect(() => {
    if (!inspectionRun && inspectionRunData) {
      resetInspectionRun();
    }
  }, [inspectionRun, inspectionRunData]);

  return (
    <InspectionRunContext.Provider
      value={{
        inspectionRun,
        isLoading: !inspectionRun || !route,
        addInspectionsToInspectionRun,
        inspectionRunInspections,
        refreshInspectionRunInspections,
        removeInspectionRunInspection,
        updateInspectionRun,
        editInspectionRunDetails,
        resetInspectionRun,
        setDurationForInspectionRunInspection,
        getDistanceAndDurationArray
      }}
    >
      {children}
    </InspectionRunContext.Provider>
  );
}

export function useInspectionRunContext() {
  return useContext(InspectionRunContext);
}
