import React, { useCallback, useMemo } from 'react';
import { query } from '@rexlabs/model-generator';

import { ListTable, TabbedTable } from 'view/components/table';
import { Card } from 'view/components/card';
import { ListScreen } from 'view/components/list-screen/list-screen';
import {
  financialsPendingDisbursementsOwnershipsModel,
  PendingOwnershipDisbursement
} from 'data/models/entities/financials/pending-disbursements/pending-ownership-disbursement';
import {
  financialsPendingDisbursementsContactsModel,
  PendingContactDisbursement
} from 'data/models/entities/financials/pending-disbursements/pending-contact-disbursement';
import { transformActionDeclarationsToActionMenuItems } from 'utils/actions/transforms';
import { useTableFilters } from 'view/hooks/use-table-filters';
import ROUTES from 'routes/app';
import { RecordTypes } from 'data/models/types';
import {
  Disbursement,
  financialsDisbursementsModel
} from 'data/models/entities/financials/disbursements/disbursements';
import { Tab } from 'view/components/table/tabbed';
import { useTranslation } from 'react-i18next';
import { useGetPendingOwnershipDisbursementActions } from '../hooks/use-get-pending-ownership-disbursement-actions';
import { useDisbursementStats } from '../hooks/use-disbursement-stats';
import { useGetPendingContactDisbursementActions } from '../hooks/use-get-pending-contact-disbursement-actions';
import { useDisburseSelected } from '../hooks/use-disburse-selected';
import { completedDisbursementColumnsForList } from '../utils/completed-disbursement-columns';
import { useGetDisbursementActions } from '../hooks/use-get-disbursement-actions';
import { getPendingDisbursementColumns } from '../utils/get-pending-disbursement-columns';

const getPendingOwnershipDisbursementsQuery = () => query`{
  ${financialsPendingDisbursementsOwnershipsModel} {
    id
    object{
      trust_summary
    }
    payment_methods
    last_disbursement
    disburse_summary
    financial_summary
    withheld_funds
    trust_summary
    ownership_tax_transactions {
        contact
    }
  }
}`;

const getPendingContactDisbursementsQuery = () => query`{
  ${financialsPendingDisbursementsContactsModel} {
    id
    object{
      trust_summary
    }
    payment_methods
    last_disbursement
    disburse_summary
    financial_summary
  }
}`;

const getCompletedDisbursementsQuery = () => query`{
  ${financialsDisbursementsModel} {
    id
    object
    payment_methods
    ownership {
      owners
    }
    contact
    disbursement_snapshot_summary
  }
}`;

export function DisbursementsList() {
  const { t } = useTranslation();
  const getPendingOwnershipDisbursementActions = useGetPendingOwnershipDisbursementActions();
  const getPendingContactDisbursementActions = useGetPendingContactDisbursementActions();
  const getDisbursementActions = useGetDisbursementActions();

  const {
    getSort: getSortContacts,
    getFilters: getFiltersContacts
  } = useTableFilters('pending-disbursement-contacts');
  const {
    getSort: getSortOwnerships,
    getFilters: getFiltersOwnerships
  } = useTableFilters('pending-disbursement-ownerships');
  const {
    getSort: getSortDisbursements,
    getFilters: getFiltersDisbursements
  } = useTableFilters('disbursements');

  const stats = useDisbursementStats();

  const disburseSelectedItems = useDisburseSelected();

  const getBulkActions = useCallback(
    ({
      selectedItems
    }: {
      selectedItems: any[];
      recordType: 'contact' | 'ownership';
    }) => {
      return [
        {
          label: t('disbursements.disburse-funds'),
          type: 'primary',
          handleAction: () => disburseSelectedItems(selectedItems)
        }
      ];
    },
    []
  );

  function getContactRowLinkProps({ item }) {
    return {
      to: ROUTES.PENDING_CONTACT_DISBURSEMENT,
      params: { pendingContactDisbursementId: item.id }
    };
  }

  function getOwnershipRowLinkProps({ item }) {
    return {
      to: ROUTES.PENDING_OWNERSHIP_DISBURSEMENT,
      params: { pendingOwnershipDisbursementId: item.id }
    };
  }

  function getCompletedDisbursementRowLinkProps({ item }) {
    return {
      to: ROUTES.COMPLETED_DISBURSEMENT,
      params: { completedDisbursementId: item.id }
    };
  }

  const tabs = useMemo<Tab[]>(() => {
    const commonProps = {
      id: 'pending-bank-deposits',
      Table: ListTable,
      shouldPersistTableSettings: false
    };

    const getPendingDisbursementsInitialGlobalFilter = (isAgency?: boolean) => {
      const filters = [
        {
          field: 'disbursement_status',
          op: 'in',
          value: [
            { value: 'due', label: 'Due' },
            { value: 'overdue', label: 'Overdue' }
          ]
        },
        {
          field: 'locked',
          op: 'eq',
          value: false
        },
        {
          field: 'status_id',
          op: 'neq',
          value: 'archived'
        }
      ];

      if (isAgency != null) {
        filters.push({
          field: 'is_agency',
          op: 'eq',
          value: isAgency
        });
      }

      return filters;
    };

    return [
      {
        ...commonProps,
        getBulkActions: (defaultArgs) =>
          getBulkActions({ ...defaultArgs, recordType: RecordTypes.Ownership }),
        columns: getPendingDisbursementColumns('ownership'),
        name: 'ownerships-pending-disbursements',
        label: 'Ownerships',
        getQuery: getPendingOwnershipDisbursementsQuery,
        getActionMenuItems: ({ item }) =>
          transformActionDeclarationsToActionMenuItems(
            getPendingOwnershipDisbursementActions(
              item as PendingOwnershipDisbursement
            )
          ),
        getFilters: getFiltersOwnerships,
        getSort: getSortOwnerships,
        getRowLinkProps: getOwnershipRowLinkProps,
        initialGlobalFilter: getPendingDisbursementsInitialGlobalFilter(),
        initialSortBy: [
          {
            id: 'disbursement_due_date',
            label: t('disbursements.due-date.label')
          }
        ]
      },
      {
        ...commonProps,
        getBulkActions: (defaultArgs) =>
          getBulkActions({ ...defaultArgs, recordType: RecordTypes.Contact }),
        columns: getPendingDisbursementColumns('contact'),
        name: 'contacts-pending-disbursements',
        label: 'Contacts',
        getQuery: getPendingContactDisbursementsQuery,
        getActionMenuItems: ({ item }) =>
          transformActionDeclarationsToActionMenuItems(
            getPendingContactDisbursementActions(
              item as PendingContactDisbursement
            )
          ),
        getFilters: getFiltersContacts,
        getSort: getSortContacts,
        getRowLinkProps: getContactRowLinkProps,
        initialGlobalFilter: getPendingDisbursementsInitialGlobalFilter(false)
      },
      {
        ...commonProps,
        getBulkActions: (defaultArgs) =>
          getBulkActions({ ...defaultArgs, recordType: RecordTypes.Contact }),
        columns: getPendingDisbursementColumns('contact'),
        name: 'agency-pending-disbursements',
        label: 'Agency',
        getQuery: getPendingContactDisbursementsQuery,
        getActionMenuItems: ({ item }) =>
          transformActionDeclarationsToActionMenuItems(
            getPendingContactDisbursementActions(
              item as PendingContactDisbursement
            )
          ),
        getFilters: getFiltersContacts,
        getSort: getSortContacts,
        getRowLinkProps: getContactRowLinkProps,
        initialGlobalFilter: getPendingDisbursementsInitialGlobalFilter(true)
      },
      {
        ...commonProps,
        columns: completedDisbursementColumnsForList,
        name: 'completed-disbursements',
        label: t(
          'disbursements.list-screen.tabs.completed-disbursements.label'
        ),
        initialSortBy: [{ id: 'created_at', label: 'Created at', desc: true }],
        getQuery: getCompletedDisbursementsQuery,
        getActionMenuItems: ({ item }) =>
          transformActionDeclarationsToActionMenuItems(
            getDisbursementActions(item as Disbursement)
          ),
        getFilters: getFiltersDisbursements,
        getSort: getSortDisbursements,
        getRowLinkProps: getCompletedDisbursementRowLinkProps
      }
    ];
  }, []);

  return (
    <ListScreen title={t('disbursements.list-screen.title')} stats={stats}>
      <Card>
        <TabbedTable tabs={tabs} />
      </Card>
    </ListScreen>
  );
}
