import { toQuri } from '@rexlabs/quri';
import * as React from 'react';
import { PropertySelect } from 'src/view/components/inputs/selects/v4-selects/property-select';
import { useGetSuggestedProperties } from '../hooks/use-get-suggested-properties';

export function AccountingJournalEntryPropertySelect(props) {
  const filters = toQuri([
    {
      field: 'ownership_id',
      op: 'eq',
      value: props.folioId
    }
  ]);

  const { loadItems: loadSuggestedItems } = useGetSuggestedProperties({
    filters
  });

  return (
    <PropertySelect
      {...props}
      filters={filters}
      getSuggestedItems={loadSuggestedItems}
    ></PropertySelect>
  );
}
