import React, { useMemo } from 'react';
import { BlockConfig } from 'view/components/record-screen/types';
import { EmptyState } from 'view/components/states/compact/empty';
import { query } from '@rexlabs/model-generator';
import {
  ContactPaymentMethod,
  contactsPaymentMethodsModel
} from 'data/models/entities/contacts/payment-methods';
import { Columns, RecordTable } from 'view/components/table';
import { ActionButtons } from 'view/components/action-buttons';
import { Contact } from 'src/modules/contacts/types/contact-types';
import { ContactPaymentMethodDetailsCell } from '../components/payment-method-details-cell';
import { useAddPaymentMethod } from '../hooks/get-add-payment-method';
import { useGetPaymentMethodsActions } from '../hooks/use-get-payment-methods-actions';
import { ContactPaymentMethodNameCell } from '../components/payment-method-name-cell';

const getContactPaymentMethodsQuery = (contactId: string) => query`{
  ${contactsPaymentMethodsModel} (contactId: ${contactId}) {
    id
    name
    payment_method
    account_number
    branch_code
  }
}`;

const columns: Columns<ContactPaymentMethod> = [
  {
    id: 'name',
    accessor: (item) => item,
    Cell: ContactPaymentMethodNameCell,
    Header: 'Name'
  },
  {
    id: 'payment_method',
    type: 'value',
    Header: 'Payment method'
  },
  {
    id: 'details',
    accessor: (item) => item,
    Cell: ContactPaymentMethodDetailsCell,
    Header: 'Details'
  }
];

export const paymentMethodListId = 'contact-payment-methods-list';

type GetContact<Data> = (data?: Data) => Contact | undefined;

export function getPaymentMethodsBlock<Data>(
  getContact: GetContact<Data>
): BlockConfig<Data> {
  return {
    id: 'payment-methods',
    title: 'Payment methods',
    Actions: ({ data }) => {
      const { getAddPaymentMethodAction } = useAddPaymentMethod();
      const contact = getContact(data);

      return (
        <ActionButtons
          actions={[getAddPaymentMethodAction(contact!, paymentMethodListId)]}
        />
      );
    },
    View: ({ data }) => {
      const contact = getContact(data);
      const query = useMemo(
        () => ({
          ...getContactPaymentMethodsQuery(contact!.id),
          uuid: paymentMethodListId
        }),
        [contact!.id]
      );
      const { getActions } = useGetPaymentMethodsActions();
      const { getAddPaymentMethodAction } = useAddPaymentMethod();

      const TableEmptyState = () => (
        <EmptyState
          title='Payment method required'
          action={
            <ActionButtons
              actions={[
                getAddPaymentMethodAction(contact!, paymentMethodListId)
              ]}
            />
          }
        />
      );

      return (
        <RecordTable
          id={paymentMethodListId}
          getQuery={() => query}
          columns={columns}
          Empty={TableEmptyState}
          getActionMenuItems={({ item }) =>
            getActions(contact!, item, paymentMethodListId)
          }
        />
      );
    }
  };
}
