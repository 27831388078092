import React from 'react';

import { BlockConfig } from 'view/components/record-screen/types';
import { useGetCreatePropertyAreaAction } from 'src/modules/property-areas/property-areas/hooks/action-declarations/use-get-create-property-area-action';
import { Property } from 'src/modules/properties/types/property-types';
import { Card } from 'view/components/card';

import EmptyTable from 'src/assets/illustrations/empty-table.svg';
import { Message } from 'view/components/@luna/message';
import { isEmpty } from 'lodash';
import Box from '@rexlabs/box';
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult
} from 'react-beautiful-dnd';
import { ActionButtons } from 'view/components/@luna/action-buttons';
import { useModelActions } from '@rexlabs/model-generator';
import { propertiesModel } from 'data/models/entities/properties';
import {
  PropertyAreaActionMenu,
  PropertyAreaCard
} from '../components/property-area';

export const inspectionAreasBlock: BlockConfig<Property> = {
  id: 'inspection-areas',
  title: 'Inspection Areas',
  Empty: EmptyState,
  showEmpty: (data) => isEmpty(data?.area_order),
  View: ({ data }) => {
    const propertyAreas = data?.areas?.data || [];

    const [itemOrder, setItemOrder] = React.useState(data?.area_order ?? []);
    const { updateItem } = useModelActions(propertiesModel);

    const handleDragEnd = async (result: DropResult) => {
      if (!result.destination) return;

      const newItemOrder = Array.from(itemOrder);

      newItemOrder.splice(result.source.index, 1);
      newItemOrder.splice(result.destination.index, 0, result.draggableId);

      setItemOrder(newItemOrder);

      try {
        await updateItem({
          id: data!.id,
          data: {
            area_order: newItemOrder
          }
        });
      } catch (err) {
        // err
      }
    };

    React.useLayoutEffect(() => {
      setItemOrder(data?.area_order ?? []);
    }, [data?.area_order]);

    return (
      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable direction='vertical' droppableId='propertyAreas'>
          {(provided) => {
            return (
              <Box
                flexDirection='column'
                gap={12}
                ref={provided.innerRef}
                {...provided.droppableProps}
              >
                {propertyAreas.map((area, index) => {
                  if (!area) return null;
                  return (
                    <Draggable
                      draggableId={area.id}
                      key={area.id}
                      index={index}
                    >
                      {(provided) => {
                        return (
                          <PropertyAreaCard
                            key={area.id}
                            ref={provided.innerRef}
                            propertyArea={area}
                            dragContainerProps={provided.draggableProps}
                            dragHandleProps={provided.dragHandleProps!}
                          >
                            <PropertyAreaActionMenu
                              propertyId={data!.id}
                              propertyArea={area}
                              areaOrder={itemOrder}
                              updateAreaOrder={setItemOrder}
                            />
                          </PropertyAreaCard>
                        );
                      }}
                    </Draggable>
                  );
                })}

                {provided.placeholder}
              </Box>
            );
          }}
        </Droppable>
      </DragDropContext>
    );
  },
  Actions: ({ data }) => {
    const getCreatePropertyAreaAction = useGetCreatePropertyAreaAction();

    return (
      <ActionButtons
        actions={data ? [getCreatePropertyAreaAction({ property: data })] : []}
      />
    );
  }
};

function EmptyState({ data }) {
  const getCreatePropertyAreaAction = useGetCreatePropertyAreaAction();

  return (
    <Card>
      <Message
        title='Inspection areas'
        Illustration={EmptyTable}
        actions={
          data
            ? [
                getCreatePropertyAreaAction({
                  property: data
                })
              ]
            : []
        }
      >
        Provide details about rooms and other living spaces for this property.
      </Message>
    </Card>
  );
}
