import React from 'react';

import { DialogProps } from '@rexlabs/dialog';

import { RecordDialog } from 'view/components/record-screen/dialog/dialog';
import { RecordSubmitHandler } from 'view/components/record-screen/utils';

import { Property } from '../types/property-types';
import {
  EditLocationBlockValues,
  editLocationBlock
} from '../blocks/edit-location-block';
import { getLngLatFromProperty } from '../utils/get-lng-lat-from-property';
import { EditLocationDialogButtonGroup } from '../components/edit-location-dialog-button-group';

const content = [
  {
    id: 'edit-location',
    label: 'Edit location',
    blocks: [editLocationBlock]
  }
];

interface EditLocationDialogProps extends DialogProps {
  property?: Property;
  handleSubmit: RecordSubmitHandler<EditLocationBlockValues>;
}

export function EditLocationDialog({
  property,
  onClose,
  handleSubmit
}: EditLocationDialogProps) {
  const propertyLngLat = getLngLatFromProperty(property);

  const initialValues = {
    property_lng_lat: propertyLngLat
  } as EditLocationBlockValues;

  return (
    <RecordDialog
      title='Edit location'
      onClose={onClose}
      data={initialValues}
      initialValues={initialValues}
      content={content}
      submitLabel='Save'
      handleSubmit={handleSubmit}
      ButtonGroup={EditLocationDialogButtonGroup}
    />
  );
}
