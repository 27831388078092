import React from 'react';
import { Columns } from 'view/components/table';
import { LargeDescriptionCell } from 'view/components/cells/large-description-cell';
import Box from '@rexlabs/box';
import { Field } from '@rexlabs/form';
import { Bold } from '@rexlabs/text';
import { EditCommentCell } from '../components/edit-comment-cell';
import { ImageColumnHeader } from '../components/image-column-header';
import { EditImagesCell } from '../components/edit-images-cell';
import { ConditionInput } from '../../components/condition-button-input';

export function getInspectionAreaEditColumns({
  setFieldValue
}: {
  setFieldValue?: (field: string, value: any) => void;
}): Columns {
  return [
    {
      id: 'name',
      Header: 'Item',
      width: 220,
      Cell: ({ value }) => <LargeDescriptionCell lineClamp={2} text={value} />
    },
    {
      id: 'conditions',
      width: 210,
      accessor: (item) => item,
      Header: () => {
        return (
          <Box flexDirection='row' gap={16}>
            <Box width={40} flexDirection='row' justifyContent='center'>
              <Bold>C</Bold>
            </Box>
            <Box width={40} flexDirection='row' justifyContent='center'>
              <Bold>W</Bold>
            </Box>
            <Box width={40} flexDirection='row' justifyContent='center'>
              <Bold>U</Bold>
            </Box>
          </Box>
        );
      },
      Cell: ({ value }) => {
        return (
          <Box flexDirection='row' gap={16}>
            <div>
              <Field
                Input={ConditionInput}
                name={`area_items.${value.id}.conditions.clean`}
                optional={false}
              />
            </div>
            <div>
              <Field
                Input={ConditionInput}
                name={`area_items.${value.id}.conditions.working`}
                optional={false}
              />
            </div>
            <div>
              <Field
                Input={ConditionInput}
                name={`area_items.${value.id}.conditions.undamaged`}
                optional={false}
              />
            </div>
          </Box>
        );
      }
    },
    {
      id: 'notes',
      Header: 'Comments',
      Cell: (props) => EditCommentCell({ value: props.value }),
      accessor: (item) => item
    },
    {
      id: 'images',
      Header: () => <ImageColumnHeader />,
      accessor: (item) => item,
      Cell: (props) =>
        EditImagesCell({ ...props, value: props.value, setFieldValue }),
      width: 60
    }
  ];
}
