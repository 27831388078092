import { Generator } from 'data/models/generator';
import { auditableConfig } from 'src/modules/common/auditable/auditable-config';
import { ChannelMessage } from '../types/ChannelMessage';
import { messagesModel } from './messages-model';

const config = {
  entities: {
    related: {
      // TODO: configure includes here
      ...auditableConfig,
      message: {
        include: 'message',
        model: messagesModel
      }
    }
  }
};

export const channelMessagesModel = new Generator<ChannelMessage>(
  'communication/channel-messages',
  config
).createEntityModel();
