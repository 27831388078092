import React from 'react';

import { Message } from '@rexlabs/notifications';

import EmptyTable from 'assets/illustrations/empty-table.svg';

import { EmptyCard } from 'view/components/record-screen/cards/empty-card';

import { useModelActions } from '@rexlabs/model-generator';
import { useGetCreateQuoteAction } from '../hooks/action-declarations/use-get-create-quote-action';
import { MaintenanceTask } from '../../maintenance/types/MaintenanceTask';
import { QuoteTask } from '../types/QuoteTask';
import { tasksModel } from '../../common/models/tasks-model';

export function QuoteListEmptyState({
  data: maintenanceTask
}: {
  data: MaintenanceTask;
}) {
  const { refreshItem } = useModelActions(tasksModel);
  const getCreateQuoteAction = useGetCreateQuoteAction({
    // refresh the parent to show changes to its status
    onCreate: (data: QuoteTask[]) => {
      // they should all have the same parent task, so only need to refresh one
      data[0]?.parent_task && refreshItem({ id: data[0].parent_task.id });
    }
  });

  const { handleAction, intent, label } = getCreateQuoteAction(maintenanceTask);

  return (
    <EmptyCard>
      <Message
        title='No quotes'
        Illustration={EmptyTable}
        actions={[
          {
            type: intent as any,
            label,
            handleAction
          }
        ]}
      >
        Request quotes for your maintenance job or add existing ones
      </Message>
    </EmptyCard>
  );
}
