import React from 'react';

import { StyleSheet, border, mq, padding, useStyles } from '@rexlabs/styling';

import {
  MergeTags,
  MergeTagsProps
} from 'src/modules/communications/common/components/merge-tags';
import { Grid } from 'view/components/@luna/form/grid';

export const MERGE_TAG_WIDTH = 280;

const defaultStyles = StyleSheet({
  // Custom grid to allow for the width of the merge-tags section
  // mostly copy-paste from frontend/src/view/components/@luna/form/grid/grid.tsx
  messageContainer: {
    width: '100%',
    display: 'grid',
    columnGap: ({ token }) => token('spacing.xl'),
    rowGap: ({ token }) => token('spacing.xl'),
    gridTemplateColumns: `1fr ${MERGE_TAG_WIDTH}px`,
    '@media (max-width: 1250px)': {
      gridTemplateColumns: `1fr`
    }
  },
  messageContentBorder: {
    background: ({ token }) => token('palette.indigo.50'),
    ...border.styles({
      all: {
        radius: 'm'
      }
    }),
    ...padding.styles({
      y: 'xl',
      x: 'xxxl'
    }),

    ...mq.styles({
      queries: {
        maxWidth: 's'
      },
      styles: {
        padding: 0
      }
    })
  },
  messageContentFields: {
    background: ({ token }) => token('palette.white'),
    ...border.styles({
      all: {
        radius: 'm'
      }
    }),

    ...padding.styles({
      all: 'xl'
    }),
    ...mq.styles({
      queries: {
        maxWidth: 's'
      },
      styles: {
        padding: 0
      }
    })
  },
  mergeTagContainer: {
    borderRadius: ({ token }) => token('border.radius.l'),
    overflow: 'hidden'
  }
});

interface BaseEditMessageFormProps {
  children: React.ReactNode;
  mergeTagsConfig: MergeTagsProps;
}
/**
 * Because the merge tags and the text inputs are tightly coupled, we're wrapping them in a single container component.
 * This component houses all of the styling and layout for the message form.
 *
 * The idea here is to pass in the children (the actual form fields) and the merge tags configuration. But
 * you could also include any other fields that you wanted to sit within this form.
 *
 * For example, the email form has the subject and message input fields which link to the merge tag component,
 * but it also has an attachments section which is separate from the input and the merge tags.
 */
export function EditMessageContentContainer({
  children,
  mergeTagsConfig
}: BaseEditMessageFormProps) {
  const s = useStyles(defaultStyles);

  return (
    <div {...s('messageContainer')}>
      <div {...s('messageContentBorder')}>
        <div {...s('messageContentFields')}>
          <Grid columns={1}>{children}</Grid>
        </div>
      </div>

      <MergeTags {...mergeTagsConfig} {...s('mergeTagContainer')} />
    </div>
  );
}
