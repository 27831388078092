import React from 'react';

import { DateValue } from 'view/components/values/date';
import { CurrencyValue } from 'view/components/values/currency';
import { Grid } from 'view/components/@luna/form/grid';

type RentScheduleDetails = {
  agreement_first_start_date: string;
  initial_paid_to_date: string | null;
  initial_paid_to_surplus: number;
  agreement_last_expected_paid_to_date: string;
};

interface RentScheduleDetailsProps<T extends RentScheduleDetails> {
  details?: T;
}

export function RentScheduleDetails<T extends RentScheduleDetails>({
  details
}: RentScheduleDetailsProps<T>) {
  return (
    <>
      <Grid columns={2}>
        <DateValue
          label='First agreement start date'
          value={details?.agreement_first_start_date}
        />
        <DateValue
          label='Charge to date '
          value={details?.agreement_last_expected_paid_to_date}
        />
      </Grid>
      {(details?.initial_paid_to_date ||
        !!details?.initial_paid_to_surplus) && (
        <Grid columns={2}>
          <DateValue
            label='Initial paid to date'
            value={details?.initial_paid_to_date}
          />
          <CurrencyValue
            label='Initial paid to surplus'
            value={details?.initial_paid_to_surplus}
          />
        </Grid>
      )}
    </>
  );
}
