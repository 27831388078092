import React from 'react';
import { Statement, StatementStatus } from 'src/modules/statements/types';
import { ColoredTag } from 'view/components/tags/colored-tag';

export const determineColoredTagFromStatement = (
  statement: Statement | undefined
) => {
  if (!statement) {
    return <ColoredTag intent={'information'}>Not issued</ColoredTag>;
  }

  if (statement.status.id === StatementStatus.Excluded) {
    return <ColoredTag intent={'danger'}>{statement.status.label}</ColoredTag>;
  }

  if (statement.status.id === StatementStatus.Issued) {
    return <ColoredTag intent={'success'}>{statement.status.label}</ColoredTag>;
  }

  return null;
};
