import { Generator } from 'data/models/generator';
import { TimestampAttributes } from '../../types';
import { BankAccount } from './bank-accounts';

/**
 * @deprecated - Seems to be no backend for this
 */
export type TrustAccount = TimestampAttributes & {
  id: string;
  name: string;
  bank_account?: BankAccount;
};

const config = {
  entities: {
    related: {
      bank_account: {
        include: 'bank_account'
      }
    }
  }
};

export const financialsTrustAccountsModel = new Generator<TrustAccount>(
  'financials/trust-accounts',
  config
).createEntityModel();
