import { get } from 'lodash';

function getAvailableBalance(forms: unknown) {
  const availableBalance = get(
    forms,
    'transfer-funds-details.values.available_amount'
  ) as unknown;

  if (typeof availableBalance === 'number') {
    return availableBalance;
  }
  return undefined;
}

export function getAvailableAmount(forms: unknown) {
  const amount = get(forms, 'receipt-funds-details.values.amount') as unknown;

  if (typeof amount === 'number') return amount;

  const availableAmount = getAvailableBalance(forms);

  return availableAmount;
}
