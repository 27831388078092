import { ChecklistTemplate } from '../types/ChecklistTemplate';
import { ChecklistTemplatesForm } from '../types/ChecklistTemplatesForm';

export function mapEditChecklistTemplateFormToRequest(
  formData: Partial<ChecklistTemplatesForm>
): Partial<ChecklistTemplate> {
  return {
    ...formData,
    item_order: [],
    ...(formData.inspection_type
      ? {
          discriminator_field: 'details.type_id',
          discriminator_value: formData.inspection_type
        }
      : {})
  };
}
