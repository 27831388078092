import { EmailPreviewProps } from '../../common/components/email/email-preview';
import { getRecipientText } from '../utils/get-recipient-text';
import { CreateMessageFormData } from './types/create-message-form-data';

/**
 * Converts message form data to an email preview props object.
 */
export function mapCreateMessageFormToEmailPreviewProps(
  formData?: CreateMessageFormData
): EmailPreviewProps {
  const attachedFiles =
    formData?.attachments
      ?.filter((attachment) => attachment.data != null)
      ?.map((attachment) => attachment.data!) ?? [];

  const recipients =
    formData?.recipients?.map?.((recipient) => recipient.record) ?? [];
  const ccRecipients =
    formData?.cc_recipients?.map?.((recipient) => recipient.record) ?? [];
  const bccRecipients =
    formData?.bcc_recipients?.map?.((recipient) => recipient.record) ?? [];

  return {
    subject: formData?.subject ?? '',
    bodyContent: formData?.content ?? '',
    recipients: getRecipientText(recipients ?? []),
    cc: getRecipientText(ccRecipients ?? []),
    bcc: getRecipientText(bccRecipients ?? []),
    attachedFiles: attachedFiles?.length ? attachedFiles : undefined
  };
}
