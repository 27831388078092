/*
|-------------------------------------------------------------------------------
| App Config Loader
|-------------------------------------------------------------------------------
|
| Exposes app environment variables, resolving dev variables when available.
|
| ```js
| import config from 'config'
| config.API_URL
| ```
|
*/
import config from '../env.js';

export const isDev = process.env.NODE_ENV !== 'production';

export default config;
