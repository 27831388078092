import { ContactPaymentMethodMethod } from 'data/models/entities/contacts/payment-methods';
import { get } from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Value } from 'view/components/values';
import { getWithDrawalDetailsConfigFromMethodId } from '../utils/get-withdrawal-details-config';

function getFieldId(field: string, prefix?: string) {
  return `${prefix ? `${prefix}.` : ''}${field}`;
}

/**
 * Use this component to generate the values for the withdrawal payment details view state.
 * If the fields are nested, you can pass a prefix to the fieldPrefix prop.
 */
export function WithdrawalPaymentDetailsValues({ fieldPrefix, values }) {
  const { t } = useTranslation();

  const paymentId = get(values, `${getFieldId('method.id', fieldPrefix)}`) as
    | ContactPaymentMethodMethod
    | undefined;

  const {
    isReferenceNeeded,
    isBranchCodeNeeded,
    isAccountCodeNeeded,
    isDrawerNeeded,
    isCheckNumberNeeded,
    isBankNeeded
  } = getWithDrawalDetailsConfigFromMethodId(paymentId);

  return (
    <>
      <Value
        label='Payment method'
        value={get(values, `${getFieldId('method', fieldPrefix)}.label`, null)}
      />
      {isDrawerNeeded && (
        <Value
          label='Drawer'
          value={get(
            values,
            `${getFieldId('drawer', fieldPrefix)}.label`,
            null
          )}
        />
      )}
      {isCheckNumberNeeded && (
        <Value
          label='Check number'
          value={get(values, `${getFieldId('reference', fieldPrefix)}`, null)}
        />
      )}
      {isBankNeeded && (
        <Value
          value={get(values, `${getFieldId('issuer', fieldPrefix)}`, null)}
          label='Bank'
        />
      )}
      {isBranchCodeNeeded && (
        <Value
          value={get(values, `${getFieldId('branch_code', fieldPrefix)}`, null)}
          label={t('bank-accounts.branch-code.label.singular') as string}
        />
      )}
      {isAccountCodeNeeded && (
        <Value
          value={get(
            values,
            `${getFieldId('account_number', fieldPrefix)}`,
            null
          )}
          label='Account number'
        />
      )}
      {isReferenceNeeded && (
        <Value
          value={get(values, `${getFieldId('reference', fieldPrefix)}`, null)}
          label='Reference'
        />
      )}
    </>
  );
}
