import * as React from 'react';

import { useDialog } from '@rexlabs/dialog';
import { useModelActions } from '@rexlabs/model-generator';

import { SingleActionDeclaration } from 'src/modules/common/actions/types/action-declaration-types';

import { useToast } from 'view/components/@luna/notifications/toast';

import { FixMessageIssuesRecordDialog } from '../../dialogs/fix-message-issues-record-dialog';
import { channelMessageRecipientsModel } from '../../models/channel-message-recipients-model';
import { messagesModel } from '../../models/messages-model';
import { Message } from '../../types/Message';

export function useGetSendMessageAction() {
  const { addToast } = useToast();
  const {
    sendItem,
    validateItems,
    refreshLists: refreshMessagesLists
  } = useModelActions(messagesModel);
  const {
    refreshLists: refreshChannelMessageRecipientsLists
  } = useModelActions(channelMessageRecipientsModel);

  const { open: openFixIssuesRecordDialog } = useDialog(
    FixMessageIssuesRecordDialog
  );

  return (message: Message): SingleActionDeclaration => {
    return {
      id: 'send-message',
      group: 'messages',
      label: 'Send message',
      handleAction: async () => {
        const validateMessageResponse = await validateItems({
          messageIds: [message.id],
          args: {
            include: [
              'message',
              'message.context',
              'message.inline_attachments'
            ].join(',')
          }
        });

        const isMessageValid =
          validateMessageResponse !== false &&
          validateMessageResponse.data.length === 0;

        if (!isMessageValid) {
          return openFixIssuesRecordDialog({
            data: {
              messageIssues: validateMessageResponse.data
            }
          });
        }

        const sendMessageResponse = await sendItem({
          message: message
        });

        if (sendMessageResponse !== false) {
          addToast({
            type: 'success',
            description: (
              <>
                Message has been <b>successfully</b> sent
              </>
            )
          });
          refreshMessagesLists();
          refreshChannelMessageRecipientsLists();
        }
      }
    };
  };
}
