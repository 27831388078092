import React from 'react';
import { BlockConfig } from 'view/components/record-screen/types';
import { TrustJournalEntryLineItemTable } from 'src/modules/trust-journal-entry-line-items/components/trust-journal-entry-line-item-table';
import { trustJournalEntryLineItemQuery } from 'src/modules/trust-journal-entry-line-items/queries/trust-journal-entry-line-item-query';

export const transactionsBlock: BlockConfig = {
  id: 'transactions',
  title: 'Transactions',
  View: ({ data }) => {
    const query = trustJournalEntryLineItemQuery([
      {
        field: 'trust_ledger_id',
        op: '==',
        value: data.id
      }
    ]);

    return (
      <TrustJournalEntryLineItemTable
        withToolbar={true}
        getQuery={() => query}
      />
    );
  }
};
