import React from 'react';

import { TitleBlock } from 'view/components/cards/title/title-block';

import { RecordObject, RecordType } from 'data/models/types';
import {
  Reconciliation,
  ReconciliationStatus
} from 'data/models/entities/financials/reconciliations';

import { getRecordTitle } from 'utils/records/get-record-title';
import { Stat } from 'view/components/@luna/stat-block/stat-blocks';
import { formatCurrency, pluralize } from 'utils/formatters';
import dayjs from 'dayjs';
import { TagConfig } from 'view/components/@luna/title-block';
import ReconciliationIcon from 'view/components/icons/reconciliation';
import { useReconciliationActions } from 'src/modules/reconciliations/actions/use-reconciliation-actions';

const type: RecordType = 'reconciliation';

type ReconciliationTitleBlockProps = {
  reconciliation: Reconciliation;
};

export function ReconciliationTitleBlock({
  reconciliation
}: ReconciliationTitleBlockProps) {
  const getReconciliationActions = useReconciliationActions('details');

  const title = getRecordTitle({
    type,
    object: reconciliation
  } as RecordObject);
  const actions = getReconciliationActions(reconciliation);

  const {
    balance_variance_amount: variance,
    bank_adjustments_unreconciled_amount: adjustments,
    bank_adjustments_unreconciled_count: adjustmentsCount,
    bank_deposits_unreconciled_amount: deposits,
    suspended_transaction_amount: suspendedAmount,
    bank_deposits_unreconciled_count: depositsCount,
    bank_withdrawals_exc_cheques_unreconciled_amount: withdrawals,
    bank_withdrawals_exc_cheques_unreconciled_count: withdrawalsCount,
    trust_cheque_withdrawals_unreconciled_amount: cheques,
    trust_cheque_withdrawals_unreconciled_count: chequesCount
  } = reconciliation.reconciliation_summary;

  const { start_date, statement_date, bank_account, status } = reconciliation;

  const stats: Stat[] = React.useMemo(
    () => [
      {
        label: 'Variance',
        intent: variance === 0 ? 'success' : 'danger',
        value: formatCurrency(variance)
      },
      {
        label: 'Active adjustments',
        intent: adjustments === 0 ? 'information' : 'warning',
        value: formatCurrency(adjustments),
        valueTag: `${pluralize('adjustments', adjustmentsCount, true)}`
      },
      {
        label: 'Suspended transactions',
        intent: 'warning',
        value: formatCurrency(suspendedAmount)
      },
      {
        label: 'Unreconciled deposits',
        intent: 'information',
        value: formatCurrency(deposits),
        valueTag: `${pluralize('deposits', depositsCount, true)}`
      },
      {
        label: 'Unreconciled withdrawals',
        intent: 'information',
        value: formatCurrency(withdrawals),
        valueTag: `${pluralize('withdrawals', withdrawalsCount, true)}`
      },
      {
        label: 'Unpresented cheques',
        intent: 'information',
        value: formatCurrency(cheques),
        valueTag: `${pluralize('cheques', chequesCount, true)}`
      }
    ],
    [
      variance,
      adjustments,
      adjustmentsCount,
      deposits,
      depositsCount,
      withdrawals,
      withdrawalsCount,
      cheques,
      chequesCount
    ]
  );

  const statusTagColour = (status: ReconciliationStatus): TagConfig['type'] => {
    switch (status) {
      case 'active':
        return 'neutral';
      case 'reconciled':
      case 'verified':
        return 'good';
      case 'unverified':
        return 'average';
      case 'voided':
        return 'bad';
      default:
        return 'neutral';
    }
  };

  const tags = [{ type: statusTagColour(status.id), label: status.label }];

  return (
    <TitleBlock
      Icon={ReconciliationIcon}
      title={title}
      type={type}
      stats={stats}
      actions={actions}
      subTitles={[
        { label: `Bank account: ${bank_account?.name || '-'}` },
        {
          label: `Closing balance: ${formatCurrency(
            reconciliation.statement_balance
          )}`
        }
      ]}
      dateRange={{ from: dayjs(start_date), to: dayjs(statement_date) }}
      reference={reconciliation.record_reference}
      tags={tags}
    />
  );
}
