import { BatchReceiptingItem, NewSplitTransactionFormValue } from '../types';

export function checkTransactionState(
  transaction: BatchReceiptingItem | NewSplitTransactionFormValue
) {
  const isMatched =
    'prioritised_match' in transaction &&
    ['matched_allocate', 'matched_receipt'].includes(
      transaction.prioritised_match.status.id
    );

  if (isMatched) return 'matched';

  const isReceipted =
    'status' in transaction &&
    transaction.status.id === 'matched' &&
    'trust_journal_entries' in transaction &&
    transaction.trust_journal_entries?.data?.[0].status.id != 'reversed';

  if (isReceipted) return 'receipted';

  return 'unmatched';
}
