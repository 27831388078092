import * as React from 'react';

import { DialogProps } from '@rexlabs/dialog';
import { useModelActions } from '@rexlabs/model-generator';

import {
  CreateRecordDialog,
  HandleRecordCreate
} from 'view/components/dialogs/create-record-dialog/create-record-dialog';

import { useRecordCreatedToast } from 'src/modules/common/toasts/hooks/use-record-created-toast';
import { tasksModel } from 'src/modules/tasks/common/models/tasks-model';
import i18n from 'src/modules/i18n/i18n';

import { taskDateBlock } from '../blocks/task-date-block';
import { taskPriorityBlock } from '../../common/blocks/task-priority-block';
import { moveInDetailsBlock } from '../blocks/move-in-details-block';
import { MoveInFormData } from '../types/MoveInFormData';
import { mapMoveInFormDataToTaskCreateRequest } from '../mappers/map-move-in-form-data-to-task-create-request';
import { createTaskAndLinkChecklists } from '../../common/utils/create-task-and-link-checklists';
import { checklistTemplateSelectBlock } from '../../common/blocks/checklist-template-select-block';
import { useTaskTypesWithCustomAddToRecord } from '../../settings/hooks/use-task-types-with-custom-add-to-record';

export interface CreateMoveInRecordDialogProps extends DialogProps {
  initialValues: Partial<MoveInFormData>;
  onCreate?: (data) => void;
  isFixture?: boolean;
}

const useContent = () => {
  const taskTypesWithCustomAddToRecord = useTaskTypesWithCustomAddToRecord();
  return [
    {
      id: 'add-move-in',
      label: `Add ${i18n.t('tasks.move-in.title')}`,
      blocks: [
        moveInDetailsBlock,
        ...(taskTypesWithCustomAddToRecord.includes('task_move_in')
          ? [checklistTemplateSelectBlock]
          : []),
        taskPriorityBlock,
        taskDateBlock
      ]
    }
  ];
};

export function CreateMoveInRecordDialog({
  onClose,
  isFixture,
  initialValues,
  onCreate,
  target
}: CreateMoveInRecordDialogProps) {
  const { refreshLists } = useModelActions(tasksModel);
  const addToast = useRecordCreatedToast('task_move_in');
  const content = useContent();

  const handleSubmit: HandleRecordCreate<any> = async ({ values }) => {
    const moveInTask = await createTaskAndLinkChecklists<'task_move_in'>(
      values,
      mapMoveInFormDataToTaskCreateRequest
    );

    onCreate?.(moveInTask);

    addToast(moveInTask);

    await refreshLists();

    return moveInTask;
  };

  return (
    <CreateRecordDialog
      recordType='task_move_in'
      data={initialValues}
      content={content}
      onClose={onClose}
      blockProps={{ taskTypeId: 'task_move_in' }}
      onSubmit={handleSubmit}
      target={target}
      isFixture={isFixture}
    />
  );
}
