import { DialogProps } from '@rexlabs/dialog';
import * as React from 'react';
import { Dialog } from 'view/components/@luna/dialog';
import { BatchReceiptingItem } from '../types';

export interface ViewNoteDialogProps extends DialogProps {
  batchReceiptingItem: BatchReceiptingItem;
}

export function ViewNoteDialog({
  onClose,
  batchReceiptingItem
}: ViewNoteDialogProps) {
  return (
    <Dialog title='View note' onClose={onClose}>
      {batchReceiptingItem.status_reason}
    </Dialog>
  );
}
