import { push } from '@rexlabs/whereabouts';
import ROUTES from 'routes/app';
import { ActionDeclaration } from 'src/modules/common/actions/types/action-declaration-types';
import { track } from '@rexlabs/analytics';
import { EVENTS } from 'utils/analytics';
import { ReconciliationType } from 'data/models/entities/financials/reconciliations';

export type Props = {
  type: ReconciliationType;
};

export function useGetFinishLaterAction() {
  return ({ type }: Props): ActionDeclaration => ({
    id: 'finish-later',
    group: 'reconciliation',
    label: 'Finish later',
    handleAction: () => {
      track({
        eventName: EVENTS.RECONCILIATIONS.FINISH_LATER_CLICKED,
        data: { reconciliationType: type }
      });

      push(ROUTES.RECONCILIATIONS);
    }
  });
}
