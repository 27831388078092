import { useDialog } from '@rexlabs/dialog';
import { TaskType } from 'src/modules/tasks/common/types/TaskType';
import { FieldArrayPassthroughProps } from '@rexlabs/form';
import { FLAGS } from 'utils/feature-flags';
import { useFeatureFlags } from 'view/components/@luna/feature-flags';
import { EditChecklistItemDialog } from '../../../dialogs/edit-checklist-item';
import { EditChecklistItemDialog as CombinedEditChecklistItemDialog } from '../../../dialogs/smart-checklists/edit-checklist-item';
import { ChecklistTemplateItem } from '../../../types/ChecklistTemplate';
import { ChecklistTemplatesForm } from '../../../types/ChecklistTemplatesForm';

export function useEditChecklistItemAction(taskType: TaskType) {
  const { hasFeature } = useFeatureFlags();
  const { open: openEditChecklistItemDialog } = useDialog(
    EditChecklistItemDialog
  );

  // New feature flagged version
  const combinedEditChecklistItemDialog = useDialog(
    CombinedEditChecklistItemDialog
  );
  const hasCombinedDialog = hasFeature(FLAGS.SMART_CHECKLISTS_COMBINED_DIALOG);

  return ({
    checklistTemplateItem,
    index,
    fieldArrayProps,
    values,
    setFieldValue
  }: {
    checklistTemplateItem: ChecklistTemplateItem;
    index: number;
    fieldArrayProps: FieldArrayPassthroughProps;
    values: Partial<ChecklistTemplatesForm>;
    setFieldValue?: (field: string, value: any) => void;
  }) => {
    return {
      label: 'Edit',
      id: 'edit-checklist-item',
      handleAction: () => {
        if (hasCombinedDialog) {
          combinedEditChecklistItemDialog.open({
            taskType,
            checklistTemplateItem,
            index,
            fieldArrayProps,
            mainValues: values,
            setFieldValue
          });
        } else {
          openEditChecklistItemDialog({
            taskType,
            checklistTemplateItem,
            index,
            fieldArrayProps,
            mainValues: values,
            setFieldValue
          });
        }
      }
    };
  };
}
