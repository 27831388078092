import * as React from 'react';
import { ActionDeclaration } from 'src/modules/common/actions/types/action-declaration-types';
import { RecordTypes } from 'data/models/types';
import { getRecordTitle } from 'utils/records/get-record-title';
import { useToast } from 'view/components/@luna/notifications/toast';
import { OutlineButton } from '@rexlabs/button';
import { useModelActions } from '@rexlabs/model-generator';
import { gotoRecord } from 'src/modules/common/utils/Records/get-record-routes';
import { getRecordObject } from 'src/modules/common/utils/Records/get-record-object';
import { MessageTemplate } from '../../types/MessageTemplate';
import { messageTemplatesModel } from '../../models/message-templates-model';

export function useGetDuplicateMessageTemplateAction() {
  const { addToast } = useToast();
  const { refreshLists, duplicateItem } = useModelActions(
    messageTemplatesModel
  );

  return (messageTemplate: MessageTemplate): ActionDeclaration => {
    return {
      id: 'duplicate-message-template',
      group: 'message-templates',
      label: 'Duplicate message template',
      handleAction: async () => {
        const messageTemplateDuplicateResponse = await duplicateItem({
          messageTemplate: messageTemplate
        });

        if (messageTemplateDuplicateResponse !== false) {
          addToast({
            type: 'success',
            description: (
              <>
                <b>
                  {getRecordTitle({
                    type: RecordTypes.MessageTemplate,
                    object: messageTemplate
                  })}
                </b>{' '}
                has been <b>successfully</b> duplicated
              </>
            ),
            actions: [
              {
                Button: OutlineButton,
                label: 'View',
                onClick: () => {
                  const recordObject = getRecordObject(
                    messageTemplateDuplicateResponse.data,
                    messageTemplatesModel
                  );
                  gotoRecord(recordObject);
                }
              }
            ]
          });

          refreshLists();
        }
      }
    };
  };
}
