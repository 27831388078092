import React, { useMemo } from 'react';

import Box from '@rexlabs/box';
import { useToken } from '@rexlabs/styling';
import { Body, Small, Truncate } from '@rexlabs/text';

export function TwoTierHeader({
  header,
  subheader,
  align = 'left'
}: {
  header: string;
  subheader: string;
  align?: 'left' | 'right';
}) {
  const token = useToken();

  const alignment = useMemo(() => getAlignment(align), [align]);

  return (
    <Box flexDirection='column' alignItems={alignment}>
      <Body bold as='span'>
        <Truncate>{header}</Truncate>
      </Body>
      <Box marginTop={token('spacing.xxs')}>
        <Truncate>
          <Small as='span' grey>
            {subheader}
          </Small>
        </Truncate>
      </Box>
    </Box>
  );
}

function getAlignment(align: 'left' | 'right') {
  switch (align) {
    case 'left':
      return 'flex-start';
    case 'right':
      return 'flex-end';
    default:
      return 'none';
  }
}
