import { useDialog } from '@rexlabs/dialog';
import { Document } from 'data/models/entities/documents';
import { Bill } from 'src/modules/bill-processing/types/Bill';
import {
  ViewDocumentDialog,
  ViewDocumentProps
} from '../dialogs/view-document-dialog';

export function useGetViewDocumentAction() {
  const viewDocumentDialog = useDialog(ViewDocumentDialog);

  return (viewDocumentProps: ViewDocumentProps) => {
    return {
      label: 'View',
      id: 'view-document',
      handleAction: () => {
        viewDocumentDialog.open(viewDocumentProps);
      }
    };
  };
}

// Images
export const IMAGE_FORMATS = [
  'image/bmp',
  'image/png',
  'image/gif',
  'image/jpeg'
];
// Documents
export const DOCUMENT_FORMATS = ['application/pdf'];

export function canPreviewDocument(fileType: string) {
  return (
    IMAGE_FORMATS.includes(fileType) || DOCUMENT_FORMATS.includes(fileType)
  );
}

export function getViewDocumentPropsFromObject(
  object: Document | Required<Bill>
): ViewDocumentProps {
  return {
    fileName: object.file.name,
    fileType: object.file.type,
    fileUrl: object.file.url
  };
}

export function getViewDocumentPropsFromPdfLink({
  link,
  type = 'application/pdf',
  name = 'System PDF'
}: {
  link: string;
  type?: string;
  name?: string;
}): ViewDocumentProps {
  return {
    fileName: name,
    fileType: type,
    fileUrl: link
  };
}
