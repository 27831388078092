import React from 'react';
import { GhostButton, OutlineButton, PrimaryButton } from '@rexlabs/button';

import { ButtonGroupProps } from 'view/components/record-screen/dialog/types';
import { StandardDialogFooter } from 'view/components/dialogs/components/standard-dialog-footer';
import ReceiptIcon from 'view/components/icons/receipt';
import { formatCurrency, pluralize } from 'utils/formatters';
import InfoCircleIcon from 'view/components/icons/info';
import { TrustJournalEntry } from 'src/modules/trust-journal-entries/types/trust-journal-entry-types';

export interface MatchToExistingReceiptButtonGroupProps
  extends ButtonGroupProps {
  closeParentDialog?: () => void;
  selectedReceipts: TrustJournalEntry[];
  amount: number;
}

function getFundsLeftToMatch({
  selectedReceipts,
  amount
}: {
  selectedReceipts: TrustJournalEntry[];
  amount: number;
}) {
  const fundsMatched: number = selectedReceipts?.reduce(
    (previousTotal, currentReceipt) => previousTotal + currentReceipt.amount,
    0
  );

  return amount - fundsMatched;
}

function getSubmitButtonLabel({
  fundsLeftToMatch,
  selectedReceiptCount
}: {
  fundsLeftToMatch: number;
  selectedReceiptCount: number;
}) {
  if (fundsLeftToMatch === 0) {
    return `Match ${pluralize('receipts', selectedReceiptCount, true)}`;
  }

  return `${formatCurrency(fundsLeftToMatch)} funds left to match`;
}

export function MatchToExistingReceiptButtonGroup({
  handleSubmit,
  onClose,
  closeParentDialog,
  isSubmitting,
  selectedReceipts,
  amount
}: MatchToExistingReceiptButtonGroupProps) {
  const handleSubmitAndClose = async () => {
    const result = await handleSubmit();

    if (!result) return;

    onClose?.();

    closeParentDialog?.();
  };

  const fundsLeftToMatch = getFundsLeftToMatch({ selectedReceipts, amount });

  return (
    <StandardDialogFooter
      left={
        <OutlineButton IconLeft={ReceiptIcon} onClick={() => onClose?.()}>
          Receipt funds
        </OutlineButton>
      }
    >
      <GhostButton onClick={onClose}>Cancel</GhostButton>
      <PrimaryButton
        IconLeft={InfoCircleIcon}
        onClick={handleSubmitAndClose}
        isLoading={isSubmitting}
        isDisabled={fundsLeftToMatch != 0}
      >
        {getSubmitButtonLabel({
          fundsLeftToMatch,
          selectedReceiptCount: selectedReceipts.length
        })}
      </PrimaryButton>
    </StandardDialogFooter>
  );
}
