import React, { useCallback } from 'react';
import {
  SelectProps,
  Select,
  useItems,
  useFreeformDialog,
  UseFreeformDialogArgs,
  FreeformDialogProps,
  RecordOption
} from '@rexlabs/select';

import { PropertyOwnership } from 'src/modules/property-ownerships/types/property-ownership-types';
import { normalisePropertyOwnership } from 'utils/normaliser/v4-select-normalisers/property-ownership';
import { CreatePropertyOwnershipDialog } from 'src/modules/property-ownerships/dialogs/create-property-ownership-dialog';

export type PropertyOwnershipSelectProps = SelectProps<PropertyOwnership> & {
  dialogProps?: UseFreeformDialogArgs<PropertyOwnership>['props'];
  getItems: () => PropertyOwnership[];
};

function FreeformOwnershipDialog({
  selectItem,
  onCreate: propOnCreate,
  ...props
}: any & FreeformDialogProps<Partial<PropertyOwnership>>) {
  const onCreate = useCallback(
    (data: PropertyOwnership) => {
      propOnCreate?.(data);
      selectItem(data);
    },
    [propOnCreate, selectItem]
  );

  return <CreatePropertyOwnershipDialog {...props} onCreate={onCreate} />;
}

export const PropertyOwnershipSelect: React.FC<PropertyOwnershipSelectProps> = ({
  dialogProps,
  getItems,
  ...props
}) => {
  const {
    getSelectProps: useItemsGetSelectProps
  } = useItems<PropertyOwnership>({
    getItems,
    getSuggestedItems: getItems
  });
  const {
    getSelectProps: useFreeformDialogGetSelectProps
  } = useFreeformDialog<PropertyOwnership>({
    props: dialogProps,
    Dialog: FreeformOwnershipDialog
  });

  const selectPropsWithFixture = useItemsGetSelectProps(
    useFreeformDialogGetSelectProps()
  );

  return (
    <Select
      id='property-ownership-select'
      {...props}
      {...selectPropsWithFixture}
      normaliser={normalisePropertyOwnership}
      Option={RecordOption}
    />
  );
};
