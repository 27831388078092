import { useSessionState } from 'src/modules/common/hooks/session-state';
import { InspectionTask } from '../types/InspectionTask';

export function useUserInspections(inspections: InspectionTask[] = []) {
  const { contact } = useSessionState();

  const inspectionsNotManagedByUser = inspections.filter(
    (inspection) => inspection.managed_by?.id !== contact?.id
  );
  const inspectionsManagedByUser = inspections.filter(
    (inspection) => inspection.managed_by?.id === contact?.id
  );

  return {
    inspectionsNotManagedByUser,
    inspectionsManagedByUser
  };
}
