import React, { useCallback, useMemo } from 'react';

import { Query } from '@rexlabs/model-generator';
import { GlobalFilter } from '@rexlabs/table/lib/types/context';

import ROUTES from 'routes/app';

import { useSettings } from 'src/modules/settings/hooks/useSettings';

import { BREADCRUMBS } from 'view/components/@luna/breadcrumbs';
import { Columns, ListTable, TabbedTable } from 'view/components/table';

import {
  financialsInvoicesModel,
  Invoice
} from 'data/models/entities/financials/invoices';

import { transformActionDeclarationsToActionMenuItems } from 'utils/actions/transforms';

import { Tab } from 'view/components/table/tabbed';
import { ListTableProps } from 'view/components/table/list-table';
import { useTableFilters } from 'view/hooks/use-table-filters';

import { getInvoiceListTabs } from '../utils/get-invoice-list-tabs';
import {
  RefreshParentEntityType,
  useInvoiceActions
} from '../actions/use-invoice-actions';
import { invoiceColumns } from '../utils/invoice-columns';

const BASIC_TABS = ['awaiting_payment', 'fully_paid', 'overdue', 'all'];

const columns: Columns<Invoice> = [
  'bill_priority',
  'invoice',
  'payable_to.object',
  'payable_by.object',
  'due_date',
  'status',
  'amount'
].map((columnId) => invoiceColumns.find((column) => column.id === columnId)!);

const getRowLinkProps = ({ item }) => ({
  to: ROUTES.INVOICE,
  params: { invoiceId: item.id },
  addBreadcrumb: {
    type: BREADCRUMBS.INVOICE,
    id: item.id,
    relation: 'Related Invoice'
  }
});

export interface InvoiceListProps {
  getQuery: () => Query<typeof financialsInvoicesModel>;
  /**
   * Applied as the forcedGlobalFilter for all tabs in the table
   */
  forcedGlobalFilter?: GlobalFilter[];
  initialGlobalFilter?: GlobalFilter[];
  shouldPersistTableSettings?: boolean;
  hashParamKey?: string;
  refreshParentEntityAction?: RefreshParentEntityType;
}

export function InvoicesList({
  shouldPersistTableSettings = true,
  getQuery,
  forcedGlobalFilter,
  initialGlobalFilter,
  hashParamKey,
  refreshParentEntityAction
}: InvoiceListProps) {
  const { getSort, getFilters } = useTableFilters('invoices');
  const { timezone } = useSettings();
  const getInvoiceActions = useInvoiceActions(refreshParentEntityAction);

  const getActionMenuItems = useCallback(({ item }) => {
    return transformActionDeclarationsToActionMenuItems(
      getInvoiceActions(item)
    );
  }, []);

  const commonTabProps = useMemo<Partial<Tab<ListTableProps>>>(() => {
    return {
      Table: ListTable,
      columns,
      getFilters,
      shouldPersistTableSettings,
      getSort,
      withPagination: true,
      getQuery: getQuery,
      initialSortBy: [
        { id: 'status_id', desc: false },
        { id: 'due_date', desc: false }
      ],
      getRowLinkProps: getRowLinkProps,
      getActionMenuItems: getActionMenuItems,
      forcedGlobalFilter,
      initialGlobalFilter,
      initialHiddenColumns: ['payable_to.object', 'payable_by.object', 'status']
    };
  }, [shouldPersistTableSettings]);

  const invoiceListTabs = useMemo<Tab[]>(
    () =>
      getInvoiceListTabs(commonTabProps, timezone).filter((tab) =>
        BASIC_TABS.includes(tab.name)
      ),
    [commonTabProps, timezone]
  );

  return <TabbedTable tabs={invoiceListTabs} hashParamKey={hashParamKey} />;
}
