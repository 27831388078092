import { NormalisedItem } from '@rexlabs/select';
import { BankAccount } from 'data/models/entities/financials/bank-accounts';

export type BankAccountSubset = Pick<BankAccount, 'name'> & {
  type: 'bankAccount';
};

export const normaliseBankAccount = (
  bankAccount: BankAccountSubset | BankAccount
): NormalisedItem => {
  return {
    id: bankAccount.name,
    label: bankAccount.name,
    type: 'bank'
  };
};
