import { makeIconComponent } from '@rexlabs/icons';

import MediumDisbursementsSvg from 'assets/icons/m/disbursements.svg';
import LargeDisbursementsSvg from 'assets/icons/l/disbursements.svg';
import ExtraLargeDisbursementsSvg from 'assets/icons/xl/disbursements.svg';

export const DisbursementsIcon = makeIconComponent({
  m: MediumDisbursementsSvg,
  l: LargeDisbursementsSvg,
  xl: ExtraLargeDisbursementsSvg
});

export default DisbursementsIcon;
