/**
 * This component is mostly a copy of the MessageIssueDisclosure component.
 */
import * as React from 'react';
import { Disclosure } from '@headlessui/react';

import Box from '@rexlabs/box';
import { GhostButton, PrimaryButton } from '@rexlabs/button';
import { ReactForms } from '@rexlabs/form';
import { StyleSheet, useStyles, useToken } from '@rexlabs/styling';
import { Heading, Small } from '@rexlabs/text';

import { editLocationBlock } from 'src/modules/properties/blocks/edit-location-block';
import { useGetUpdatePropertyLocationSubmitHandler } from 'src/modules/properties/hooks/use-get-update-property-location-submit-handler';
import { getLngLatFromProperty } from 'src/modules/properties/utils/get-lng-lat-from-property';

import ChevronDownIcon from 'view/components/icons/chevron-down';
import ChevronRightIcon from 'view/components/icons/chevron-right';

import { InspectionTask } from '../types/InspectionTask';

const defaultStyles = StyleSheet({
  container: {
    padding: ({ token }) => token('spacing.xl'),
    borderRadius: ({ token }) => token('border.radius.l'),
    overflow: 'hidden'
  },
  flex1: {
    flex: 1
  },
  cursorPointer: {
    cursor: 'pointer'
  }
});

export interface InspectionLocationIssueDisclosureProps {
  inspection: InspectionTask;
  inspectionIssueText?: string;
  setValue?: (boolean) => void;
}

export function InspectionLocationIssueDisclosure({
  inspection,
  inspectionIssueText,
  setValue,
  ...props
}: InspectionLocationIssueDisclosureProps) {
  const [statusText, setStatusText] = React.useState(
    inspectionIssueText || 'No issue reported'
  );
  const [locationHasIssue, setLocationHasIssue] = React.useState(
    inspectionIssueText ? true : false
  );

  const getUpdatePropertyLocationSubmitHandler = useGetUpdatePropertyLocationSubmitHandler();

  const token = useToken();

  const s = useStyles(defaultStyles);

  const Edit = editLocationBlock.Edit!;

  return (
    <Disclosure {...props}>
      {({ open, close }) => {
        /**
         * NOTE: The typing of ReactForms is incorrect - if I use the correct types of this submit handler
         * the initial values types are incorrect. It then thinks initial values should be nested in a
         * values object.
         */
        const handleSubmit: any = getUpdatePropertyLocationSubmitHandler(
          inspection.property!,
          (property) => {
            setStatusText('Location updated');
            setLocationHasIssue(false);
            close();
            setValue &&
              setValue({
                hasIssues: locationHasIssue,
                inspection: { ...inspection, property: { ...property } }
              });
          }
        );

        return (
          <ReactForms
            initialValues={{
              property_lng_lat:
                getLngLatFromProperty(inspection.property) || undefined
            }}
            handleSubmit={(values) => handleSubmit({ values })}
          >
            {({ submitForm, values, setFieldValue }) => (
              <Box
                {...s('container')}
                style={open ? { background: token('palette.grey.200') } : {}}
                gap={token('spacing.s')}
                display='flex'
                flexDirection='row'
              >
                <Box>{open ? <ChevronDownIcon /> : <ChevronRightIcon />}</Box>
                <Box {...s('flex1')}>
                  <Box
                    display='flex'
                    flexDirection='row'
                    justifyContent='space-between'
                  >
                    <Disclosure.Button
                      as={Box}
                      display='flex'
                      flexDirection='row'
                      {...s('cursorPointer')}
                    >
                      <Box display='flex' flexDirection='column'>
                        <Heading level={4} as='span'>
                          {inspection?.details?.type.label}
                        </Heading>
                        <Small grey>{inspection.property?.display_name}</Small>
                      </Box>
                    </Disclosure.Button>
                    <Box display='flex' alignItems='center'>
                      {open ? (
                        <div>
                          <GhostButton onClick={() => close()}>
                            Cancel
                          </GhostButton>
                          <PrimaryButton
                            data-testid='save-location'
                            onClick={() => submitForm() as any}
                          >
                            Save
                          </PrimaryButton>
                        </div>
                      ) : (
                        <div
                          style={{
                            color: locationHasIssue
                              ? token('palette.red.900')
                              : token('palette.green.900')
                          }}
                        >
                          {statusText}
                        </div>
                      )}
                    </Box>
                  </Box>
                  <Disclosure.Panel>
                    <Edit
                      {...props}
                      values={values}
                      setFieldValue={setFieldValue}
                    />
                  </Disclosure.Panel>
                </Box>
              </Box>
            )}
          </ReactForms>
        );
      }}
    </Disclosure>
  );
}
