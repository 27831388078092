import React, { useCallback } from 'react';

import { RecordDialog } from 'view/components/record-screen/dialog/dialog';
import { DialogContentConfig } from 'view/components/record-screen/dialog/types';

import { RecordSubmitHandler } from 'view/components/record-screen/utils';
import { useModelActions } from '@rexlabs/model-generator';
import { filterNullValues } from 'utils/filter-null-values';
import {
  contactsPaymentMethodsModel,
  ContactPaymentMethod
} from 'data/models/entities/contacts/payment-methods';
import { useToast } from 'view/components/@luna/notifications/toast';
import { createContactPaymentMethodBlock } from '../blocks/create-contact-payment-method';

const content: DialogContentConfig = [
  {
    id: 'payment-method',
    label: 'Payment method',
    blocks: [createContactPaymentMethodBlock]
  }
];

interface EditContactPaymentMethodDialogProps {
  onClose?: () => void;
  contactId: string;
  contactPaymentMethod: ContactPaymentMethod;
}

export function EditContactPaymentMethodDialog({
  onClose,
  contactId,
  contactPaymentMethod
}: EditContactPaymentMethodDialogProps) {
  const { addToast } = useToast();
  const { updateItem, refreshLists } = useModelActions(
    contactsPaymentMethodsModel
  );
  const handleSubmit: RecordSubmitHandler = useCallback(
    async ({ changedValues }) => {
      const response = await updateItem({
        id: contactPaymentMethod.id,
        args: { contactId: contactId },
        data: { ...filterNullValues(changedValues) }
      });

      addToast({ description: 'Successfully updated payment method' });

      return response.data;
    },
    [updateItem, refreshLists]
  );

  const initialData = contactPaymentMethod;

  return (
    <RecordDialog
      onClose={onClose}
      data={initialData}
      title='Update payment method'
      handleSubmit={handleSubmit}
      content={content}
      submitLabel='Save'
    />
  );
}
