import * as React from 'react';
import Box from '@rexlabs/box';
import {
  StatusChangeActionArgs,
  useStatusChangeAction
} from 'src/modules/common/actions/status/use-status-change-action';
import { invokeActionDeclaration } from 'src/modules/common/actions/utils/invoke-action-declaration';
import { Task } from 'src/modules/tasks/common/types/Task';
import { useStatusDropdownRefresh } from 'src/modules/tasks/common/hooks/use-get-status-dropdown-refresh';
import { useTranslation } from 'react-i18next';
import { startCase } from 'lodash';
import { tasksModel } from '../../common/models/tasks-model';
import { TaskStatusMenu } from './task-status-menu';

export function ProgressDropdown({
  value: task
}: {
  value: Task<'task_lease_review'>;
}) {
  const { t } = useTranslation();

  const refreshListOnComplete = useStatusDropdownRefresh();
  const getStatusChangeAction = useStatusChangeAction(tasksModel);

  const handleStatusChange = (newStatus) => {
    invokeActionDeclaration<StatusChangeActionArgs<typeof newStatus>[]>(
      getStatusChangeAction,
      {
        record: task,
        status: newStatus.id,
        dialogOptions: {
          type: 'none',
          afterAction: refreshListOnComplete
        },
        generalOptions: { avoidListRefresh: true },
        transformPayload: (status) => {
          return {
            type: {
              id: 'task_lease_review',
              label: startCase(t('tasks.lease-review.label.singular') as string)
            },
            details: {
              status: {
                id: status
              }
            }
          };
        }
      }
    );
  };

  return (
    <Box
      data-testid='lease-review-status-tag'
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      <TaskStatusMenu
        initialValue={task.details?.status}
        onChange={handleStatusChange}
      />
    </Box>
  );
}
