import React from 'react';

import EmptyTable from 'src/assets/illustrations/empty-table.svg';

import { EmptyCard } from 'view/components/record-screen/cards/empty-card';
import { Message } from 'view/components/@luna/message';

export function SmsActivityEmpty() {
  return (
    <EmptyCard hasGreyBackground={false}>
      <Message title='Activity' Illustration={EmptyTable}>
        All your sent messages and top-ups will be displayed here.
      </Message>
    </EmptyCard>
  );
}
