import React from 'react';
import Box from '@rexlabs/box';
import { useToken } from '@rexlabs/styling';
import Tooltip from '@rexlabs/tooltip';
import { Truncate } from '@rexlabs/text';
import InfoCircleIcon from 'view/components/icons/info';
import { PrepaymentBucket } from 'src/modules/prepayments/types/prepayment-bucket';
import { useTranslation } from 'react-i18next';

interface PrepaymentBucketNameCellProps {
  text: string;
  systemPurpose: PrepaymentBucket['system_purpose'];
}

export function PrepaymentBucketNameCell({
  text,
  systemPurpose
}: PrepaymentBucketNameCellProps) {
  const token = useToken();
  const { t } = useTranslation();

  // not clear on how we might want to abstract this in the future
  // so I'm extracting some variables here but not abstracting yet
  const tooltipLabel = t('settings.prepayments.rent-prepayment-tooltip-label');

  const tooltipTestId = 'rent-prepayment-tooltip';
  const showIcon = systemPurpose?.id === 'rent_prepayment';

  return (
    <Box columnGap={token('spacing.xs')} alignItems={'center'}>
      <Truncate>{text || '--'}</Truncate>
      <Box display='flex' justifyContent='center'>
        {showIcon && (
          <Tooltip Content={() => tooltipLabel}>
            <Box height={'16px'} data-testid={tooltipTestId}>
              <InfoCircleIcon />
            </Box>
          </Tooltip>
        )}
      </Box>
    </Box>
  );
}
