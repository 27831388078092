import { ActionDeclaration } from 'src/modules/common/actions/types/action-declaration-types';
import { useDeleteAction } from 'src/modules/common/actions/delete/use-delete-action';
import { push } from '@rexlabs/whereabouts';
import ROUTES from 'routes/app';
import React from 'react';
import { pluralize } from 'utils/formatters';
import { Portfolio, portfoliosModel } from '../../models/portfolios-model';

export function useGetDeletePortfolioAction() {
  const getDeleteAction = useDeleteAction(portfoliosModel);

  return (portfolio: Portfolio): ActionDeclaration => {
    const propertiesCount = portfolio?.summary?.properties_count;

    const deleteAction = getDeleteAction(portfolio, {
      message:
        !!propertiesCount && propertiesCount > 0 ? (
          <>
            <div>
              {`This portfolio contains ${pluralize(
                'property',
                propertiesCount,
                true
              )}.`}{' '}
              {propertiesCount === 1
                ? ` The property
          will be unassigned.`
                : `These properties will be unassigned.`}
            </div>
            <br />
            <div>Do you want to continue?</div>
          </>
        ) : (
          `Are you sure you want to remove this portfolio?`
        ),
      label: 'Delete portfolio',
      title: 'Remove portfolio',
      confirmText: 'Yes, remove portfolio',
      size: 's',
      afterAction: () => push(ROUTES.PORTFOLIOS_LIST)
    });

    return deleteAction;
  };
}
