import { Ownership } from 'data/models/entities/ownerships';
import { Contact } from 'src/modules/contacts/types/contact-types';

export function getDisbursementObjectContacts(
  object?: Contact | Ownership
): Contact[] {
  if (!object) {
    return [];
  }
  return 'owners' in object
    ? (object.owners || []).map((owner) => owner.contact)
    : [object as Contact];
}
