import React, { useMemo } from 'react';
import { query, useEntityQuery } from '@rexlabs/model-generator';
import { RecordScreen } from 'view/components/record-screen';
import {
  BankAccount,
  bankAccountModel
} from 'data/models/entities/financials/bank-accounts';
import { useRecordScreenSubmitHandler } from 'view/hooks/use-record-screen-submit-handler';
import { BREADCRUMBS } from 'view/components/@luna/breadcrumbs';
import { useBankAccountDetailsContent } from '../data/content';
import { BankAccountTitleBlock } from '../components/bank-account-title-block';

const getBankAccountQuery = (bankAccountId: string) => query`{
  ${bankAccountModel} (id: ${bankAccountId}) {
    id
    account
    bank
    bpay
    aba
    sage
    bacs
    trust_withdrawals_not_yet_banked
    trust_receipts_not_yet_banked
    last_reconciliation
  }
}`;

interface BankAccountDetailsScreenProps {
  bankAccountId?: string;
}

export function BankAccountDetailsScreen({
  bankAccountId
}: BankAccountDetailsScreenProps) {
  const breadcrumbs = [{ type: BREADCRUMBS.BANK_ACCOUNT }];
  const query = useMemo(() => getBankAccountQuery(bankAccountId!), [
    bankAccountId
  ]);
  const { data, status, actions } = useEntityQuery(query, {
    throwOnError: false
  });

  const bankAccountDetailsContent = useBankAccountDetailsContent();

  const handleSubmit = useRecordScreenSubmitHandler(
    async ({ values, changedValues }) => {
      if (changedValues.aba) {
        changedValues.aba = {
          ...values.aba,
          ...changedValues.aba
        };
      }

      if (changedValues.bpay) {
        changedValues.bpay = {
          ...values.bpay,
          ...changedValues.bpay
        };
      }

      if (changedValues.sage) {
        changedValues.sage = {
          ...values.sage,
          ...changedValues.sage
        };
      }

      if (changedValues.bacs) {
        changedValues.bacs = {
          ...values.bacs,
          ...changedValues.bacs
        };
      }

      if (changedValues.account) {
        changedValues.account = {
          ...values.account,
          ...changedValues.account
        };
      }

      return actions.updateItem({
        id: bankAccountId,
        data: changedValues,
        args: {
          include:
            'account,bpay,aba,sage,bacs,trust_withdrawals_not_yet_banked,trust_receipts_not_yet_banked,last_reconciliation'
        }
      });
    }
  );

  return (
    <RecordScreen
      isLoading={status === 'loading'}
      data={data}
      handleSubmit={handleSubmit}
      content={bankAccountDetailsContent}
      titleBlock={<BankAccountTitleBlock bankAccount={data as BankAccount} />}
      breadcrumbs={breadcrumbs}
    />
  );
}
