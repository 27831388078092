import { BaseModelGeneratorModel } from '@rexlabs/model-generator';

import { Generator } from 'data/models/generator';
import { TimestampAttributes } from 'data/models/types';

export type RentAdjustment = BaseModelGeneratorModel &
  TimestampAttributes & {
    amount: number;
    from_date: Date;
    to_date: Date;
    tenancy_id: string;
  };

export type CreateRentAdjustment = any;

export const financialsRentAdjustmentsModel = new Generator<RentAdjustment>(
  'financials/rent/adjustments'
).createEntityModel();
