import { Ownership } from 'data/models/entities/ownerships';

import { getTrustLedger } from 'utils/api/get-trust-ledger';

import { BankAccount } from 'data/models/entities/financials/bank-accounts';
import { ValueListValue } from 'data/models/types';

import { TrustJournalEntryPaymentInformation } from 'src/modules/trust-journal-entries/types/trust-journal-entry-types';
import { Contact } from 'src/modules/contacts/types/contact-types';
import { valueListFactory } from 'src/lib/testing/factories/value-list-factory';

import { TaxAdjustmentForm } from '../types/tax-adjustment-form';

type WithdrawalRequest = {
  amount: number;
  available_amount: number;
  bank_account: BankAccount;
  contact: Contact;
  date_of: string;
  description: string;
  payment_information: TrustJournalEntryPaymentInformation;
  source: {
    ledger: {
      object: { id: string; type: ValueListValue<string> };
    };
  };
};

export async function createWithdrawFundsPayloadData(
  formData: TaxAdjustmentForm,
  ownership?: Ownership
): Promise<WithdrawalRequest> {
  if (!formData?.bank_account?.id) {
    throw new Error('Bank account is required');
  }
  if (!formData?.payment_information) {
    throw new Error('Payment information is required');
  }

  const trustLedger = await getTrustLedger(
    ownership!,
    formData.bank_account?.id
  );

  return {
    amount: formData.amount,
    available_amount:
      trustLedger?.financial_summary.available_balance_amount || 0,
    bank_account: formData.bank_account,
    contact: formData.contact,
    date_of: formData.date_of,
    description: formData.description,
    payment_information: formData.payment_information,
    source: {
      ledger: {
        object: {
          id: ownership!.id,
          type: valueListFactory(ownership!.__record_type)
        }
      }
    }
  };
}
