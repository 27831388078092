import React from 'react';
import { BlockConfig } from 'view/components/record-screen/types';
import { FieldArray } from '@rexlabs/form';
import Box from '@rexlabs/box';
import { OutlineButton } from '@rexlabs/button';
import { Bold } from '@rexlabs/text';
import AddIcon from 'view/components/icons/add';
import { DialogLineItem } from 'src/modules/common/components/dialog-line-item';
import {
  ContactPaymentMethodForm,
  getPaymentMethodFormValidation
} from '../components/contact-payment-method-form';

function AddPaymentMethodButton({ onClick, children }) {
  return (
    <OutlineButton onClick={onClick} IconLeft={AddIcon}>
      {children}
    </OutlineButton>
  );
}

export const createContactPaymentMethodsBlock: BlockConfig = {
  id: 'create-contact-payment-methods',
  title: 'Payment method',
  validate: {
    definitions: {
      ...getPaymentMethodFormValidation('payment_methods.*.')
    },
    messages: {
      required_if: 'The :attribute field is required.'
    }
  },
  Edit: ({ data, values, setFieldValue, blockProps }) => (
    <FieldArray name='payment_methods'>
      {function LineItem({ fields, push }) {
        return !fields.length ? (
          <AddPaymentMethodButton onClick={() => push({})}>
            Add payment method
          </AddPaymentMethodButton>
        ) : (
          <Box>
            {fields.map(({ field, actions }, index) => {
              return (
                <DialogLineItem
                  key={field.name}
                  heading={<Bold>Payment method {index + 1}</Bold>}
                  onRemove={actions.remove}
                >
                  <ContactPaymentMethodForm
                    data={data}
                    values={values}
                    setFieldValue={setFieldValue}
                    blockProps={blockProps}
                    fieldName={field.name}
                  />
                </DialogLineItem>
              );
            })}

            <AddPaymentMethodButton onClick={() => push({})}>
              Add another payment method
            </AddPaymentMethodButton>
          </Box>
        );
      }}
    </FieldArray>
  )
};
