import { ActionMenuItem } from '@rexlabs/action-menu';
import { transformActionDeclarationsToActionMenuItems } from 'utils/actions/transforms';
import { Message } from '../../types/Message';
import { useGetDeleteMessageAction } from '../action-declarations/use-get-delete-message-action';
import { useGetEditMessageAction } from '../action-declarations/use-get-edit-message-action';
import { useGetPreviewMessageAction } from '../action-declarations/use-get-preview-message-action';
import { useGetBulkPrintLetterAction } from '../action-declarations/use-get-bulk-print-letter-action';

export function useGetLetterOutboxActions() {
  const getPreviewMessageAction = useGetPreviewMessageAction();
  const getEditMessageAction = useGetEditMessageAction();
  const getBulkPrintLetterAction = useGetBulkPrintLetterAction();
  const getDeleteMessageAction = useGetDeleteMessageAction();

  return (message?: Message): ActionMenuItem[] => {
    if (!message) return [];

    return [
      ...transformActionDeclarationsToActionMenuItems([
        { ...getEditMessageAction(message), label: 'Edit letter' },
        { ...getPreviewMessageAction(message), label: 'Preview letter' },
        getBulkPrintLetterAction([message]),
        { ...getDeleteMessageAction(message), label: 'Delete letter' }
      ])
    ];
  };
}
