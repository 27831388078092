import { BaseModelGeneratorModel } from '@rexlabs/model-generator';

import { Generator } from 'data/models/generator';
import { ComplianceType } from 'src/modules/compliance/common/models/compliance-types';
import { TimestampAttributes } from '../../types';
import { PropertyComplianceCategory } from './property-compliance-categories';

export type PropertyCategoryRequirement = BaseModelGeneratorModel &
  TimestampAttributes & {
    compliance_type: ComplianceType;
    category: PropertyComplianceCategory;
  };

export type CreatePropertyCategoryRequirement = {
  property_compliance_category_id: string;
  compliance_type_id: string;
};

const config = {
  entities: {
    related: {
      category: {
        include: 'category'
      },
      compliance_type: {
        include: 'compliance_type'
      }
    }
  }
};

export const settingsPropertyCategoryRequirementsModel = new Generator<PropertyCategoryRequirement>(
  'settings/property-category-requirements',
  config
).createEntityModel();
