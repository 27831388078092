import React from 'react';
import { BlockConfig } from 'view/components/record-screen/types';
import { Column, Grid } from '@rexlabs/grid';
import { Value } from 'view/components/values';

export const emailBlock: BlockConfig = {
  id: 'changeEmail',
  title: 'Email',
  View: ({ data }) => (
    <Grid>
      <Column width={12}>
        <Value label='Email' value={data.email} />
      </Column>
    </Grid>
  )
};
