import React from 'react';

import { Query } from '@rexlabs/model-generator';
import {
  CashBookEntry,
  financialsCashBookEntriesModel
} from 'data/models/entities/financials/cash-book-entries';
import { Columns, RecordTable } from 'view/components/table';

const columns: Columns<CashBookEntry> = [
  {
    id: 'date_of',
    type: 'date',
    Header: 'Date of'
  },
  {
    id: 'payment_reference',
    width: 10 * 16,
    Header: 'Payment reference'
  },
  {
    id: 'payment_method',
    width: 8 * 16,
    Header: 'Payment method'
  },
  {
    id: 'amount',
    width: 6 * 16,
    type: 'currency',
    cellProps: {
      align: 'right'
    },
    Header: 'Amount'
  }
];

interface SelectableCashBookEntriesListUIProps {
  query: Query<typeof financialsCashBookEntriesModel>;
  onSelectionChange: (cashBookEntries: CashBookEntry[]) => void;
}

export function SelectableCashBookEntriesListUI({
  query,
  onSelectionChange
}: SelectableCashBookEntriesListUIProps) {
  return (
    <RecordTable
      id='selectable-cash-book-entries'
      columns={columns}
      onSelectionChange={onSelectionChange}
      getQuery={() => query}
    />
  );
}
