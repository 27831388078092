import React from 'react';
import { BlockConfig } from 'view/components/record-screen/types';
import { ChecklistTemplatesForm } from '../types/ChecklistTemplatesForm';
import { ChecklistTemplate } from '../types/ChecklistTemplate';
import { ChecklistTemplateItemsBlockEmptyState } from '../components/checklist-template-items-block-empty-state';
import { ChecklistTemplateItemsBlockCard } from '../components/checklist-template-items-block-card';

export const checklistTemplateItemsBlock: BlockConfig<
  ChecklistTemplate,
  any,
  ChecklistTemplatesForm
> = {
  id: 'checklist-template-items',
  title: 'Checklist',
  Card: (props) => {
    const { data } = props;

    const isEmpty = !data || !data?.items?.data || data.items.data.length === 0;

    return isEmpty ? (
      <ChecklistTemplateItemsBlockEmptyState data={data} />
    ) : (
      <ChecklistTemplateItemsBlockCard {...props} />
    );
  }
};
