import React, { ReactElement, useCallback } from 'react';
import { useModelActions } from '@rexlabs/model-generator';
import { api } from 'utils/api/api-client';
import { documentsModel } from 'data/models/entities/documents';
import { useToast } from 'view/components/@luna/notifications/toast';
import { Ownership } from 'data/models/entities/ownerships';
import { Tenancy } from 'data/models/entities/tenancies';
import { Contact } from 'src/modules/contacts/types/contact-types';
import { Property } from 'src/modules/properties/types/property-types';
import { Task } from 'src/modules/tasks/common/types/Task';
import { useDocumentListQuery } from 'src/modules/documents/hooks/use-get-document-list-query';
import { AttachedTo } from '../../dialogs/create-document-dialog';
import { DocumentsTable } from '../ui/documents-table';

export type DocumentRelatedRecord =
  | Property
  | Contact
  | Ownership
  | Tenancy
  | Task<any>;

export interface DocumentListProps {
  propertyId?: string;
  contactId?: string;
  ownershipId?: string;
  tenancyId?: string;
  taskId?: string;
  record: DocumentRelatedRecord;
  attachedTo: AttachedTo[];
  overrideEmptyState?: ReactElement;
}

export function DocumentList({
  propertyId,
  contactId,
  ownershipId,
  tenancyId,
  taskId,
  record,
  attachedTo
}: DocumentListProps) {
  const query = useDocumentListQuery({
    propertyId,
    contactId,
    ownershipId,
    tenancyId,
    taskId
  });

  const { createItem, refreshLists } = useModelActions(documentsModel);

  const { addToast } = useToast();

  const handleDrop = useCallback(async (files: FileList) => {
    const promises = Array.from(files).map(async (file) => {
      try {
        const formData = new FormData();
        formData.append('file', file);
        const apiFile = await api.post('/files', formData);

        const { data } = await createItem({
          data: {
            file: { id: apiFile?.data?.id },
            attached_to: [
              { id: propertyId, type: { id: 'property' } },
              { id: contactId, type: { id: 'contact' } },
              { id: ownershipId, type: { id: 'ownership' } },
              { id: tenancyId, type: { id: 'tenancy' } },
              { id: taskId, type: { id: 'task' } }
            ].filter(({ id }) => !!id)
          }
        });
        addToast({ description: `Added Document - ${data.name}` });
      } catch (e) {
        console.error(e);
        addToast({ type: 'error', description: `Document upload failed` });
      }
    });

    await Promise.all(promises);
    refreshLists();
  }, []);

  return (
    <DocumentsTable
      query={query}
      onDrop={handleDrop}
      record={record}
      attachedTo={attachedTo}
    />
  );
}
