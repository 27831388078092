import { ChecklistTemplate } from '../types/ChecklistTemplate';
import { ChecklistTemplatesForm } from '../types/ChecklistTemplatesForm';

export function mapChecklistTemplateToChecklistTemplateFormData(
  checklistTemplate: Partial<ChecklistTemplate>
): Partial<ChecklistTemplatesForm> {
  return {
    ...checklistTemplate,
    ...(checklistTemplate.task_type?.id === 'task_inspection' &&
    checklistTemplate.discriminator_value
      ? {
          inspection_type: checklistTemplate.discriminator_value
        }
      : {}),
    checklist_template_items: checklistTemplate.items?.data || []
  };
}
