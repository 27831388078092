import React, { forwardRef, ComponentType } from 'react';

import Box from '@rexlabs/box';
import { useStyles, StyleSheet } from '@rexlabs/styling';

const defaultStyles = StyleSheet({
  container: {
    cursor: 'pointer',
    height: '100%'
  },

  disabled: {
    opacity: 0.5,
    pointerEvents: 'none'
  }
});

type MenuButtonProps = {
  disabled?: boolean;
  onClick?: () => void;
  Icon: ComponentType<any>;
};

export const MenuButton = forwardRef<HTMLElement, MenuButtonProps>(
  function MenuButton({ disabled = false, Icon, ...props }, ref) {
    const s = useStyles(defaultStyles);

    return (
      <Box
        {...props}
        {...s('container', { disabled })}
        ref={ref}
        flex={1}
        alignItems='center'
        justifyContent='center'
      >
        <Icon size='l' />
      </Box>
    );
  }
);
