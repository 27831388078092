import { transformActionDeclarationsToActionMenuItems } from 'utils/actions/transforms';
import { MessageTemplate } from '../../types/MessageTemplate';
import { useGetViewMessageTemplateAction } from '../action-declarations/use-get-view-message-template-action';
import { useGetDeleteMessageTemplateAction } from '../action-declarations/use-get-delete-message-template-action';
import { useGetDuplicateMessageTemplateAction } from '../action-declarations/use-get-duplicate-message-template-action';
import { useGetToggleArchivedMessageTemplateAction } from '../action-declarations/use-get-toggle-archived-message-template-action';

export function useGetMessageTemplateActions() {
  const getViewMessageTemplateAction = useGetViewMessageTemplateAction();
  const getDeleteMessageTemplateAction = useGetDeleteMessageTemplateAction();
  const getDuplicateMessageTemplateAction = useGetDuplicateMessageTemplateAction();
  const getToggleArchivedMessageTemplateAction = useGetToggleArchivedMessageTemplateAction();
  return (messageTemplate?: MessageTemplate) => {
    if (!messageTemplate) return [];

    return [
      ...transformActionDeclarationsToActionMenuItems([
        getViewMessageTemplateAction(messageTemplate),
        getDuplicateMessageTemplateAction(messageTemplate),
        getToggleArchivedMessageTemplateAction(messageTemplate),
        getDeleteMessageTemplateAction(messageTemplate)
      ])
    ];
  };
}
