import { Message } from '../types/Message';
import { LetterPreviewProps } from '../../common/components/letter-preview';

/**
 * Converts a message to an letter preview props object.
 */
export function mapMessageToLetterPreviewProps(
  message?: Message
): LetterPreviewProps {
  const letterChannel = message?.channels.find(
    (channel) => channel.channel_type.id === 'letter'
  );

  const attachedFiles =
    message?.inline_attachments
      .filter((attachment) => attachment.file)
      .map((attachment) => attachment.file) ?? [];

  return {
    bodyContent: letterChannel?.resolved_text_body ?? '',
    address: letterChannel?.resolved_letter_address ?? '',
    attachedFiles: attachedFiles.length > 0 ? attachedFiles : undefined
  };
}
