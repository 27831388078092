import { useGetToggleClosedAction } from 'src/modules/tasks/common/hooks/action-declarations/use-get-toggle-closed-action';
import { useGetPrimaryRecordActionGroup } from 'view/hooks/actions/use-get-primary-record-action-group';
import { useGetAddDocumentsAction } from 'src/modules/documents/actions/use-get-add-documents-action';
import { useGetAddNotesAction } from 'src/modules/tasks/common/actions/get-add-notes-action';
import { SingleActionDeclaration } from 'src/modules/common/actions/types/action-declaration-types';
import { useGetEditLocationAction } from 'src/modules/properties/utils/property-actions/use-get-edit-location-action';
import { useFeatureFlags } from 'view/components/@luna/feature-flags';
import { FLAGS } from 'utils/feature-flags';
import { tasksModel } from 'src/modules/tasks/common/models/tasks-model';
import { useGetCreateEmailAction } from 'src/modules/communications/messages/hooks/action-declarations/use-get-create-email-action';
import { useGetCreateSMSAction } from 'src/modules/communications/messages/hooks/action-declarations/use-get-create-sms-action';
import { useSettings } from 'src/modules/settings/hooks/useSettings';
import { useQuery } from 'react-query';
import { api } from 'utils/api/api-client';
import { InspectionTask } from '../../types/InspectionTask';
import { useGetSendInspectionReportAction } from '../action-declarations/use-get-send-inspection-report-action';
import { useGetRemoveInspectionFromInspectionRun } from '../action-declarations/use-get-remove-inspection-from-inspection-run-action';

export function useGetInspectionActions() {
  const { hasFeature } = useFeatureFlags();
  const getPrimaryRecordActionGroup = useGetPrimaryRecordActionGroup();
  const getToggleClosedAction = useGetToggleClosedAction();
  const getAddDocumentsAction = useGetAddDocumentsAction();
  const getAddNotesAction = useGetAddNotesAction();
  const getSendInspectionReportAction = useGetSendInspectionReportAction();
  const getRemoveInspectionFromInspectionRun = useGetRemoveInspectionFromInspectionRun();
  const getEditLocationAction = useGetEditLocationAction();
  const getCreateEmailAction = useGetCreateEmailAction(tasksModel);
  const getCreateSMSAction = useGetCreateSMSAction(tasksModel);

  const settingsData = useSettings();
  const inspectionTemplateId = settingsData['inspection-template'].template_id;

  const inspectionTemplateData = useQuery(
    ['inspection-template', inspectionTemplateId],
    async () => {
      const messageTemplate = await api.get(
        `/communication/message-templates/${inspectionTemplateId}`
      );

      return messageTemplate.data;
    },
    {
      refetchOnWindowFocus: false,
      enabled: inspectionTemplateId != null
    }
  );

  return (inspection?: InspectionTask): SingleActionDeclaration[] => {
    if (!inspection) return [];

    const sendInspectionReportAction = getSendInspectionReportAction(
      inspection
    );

    const initialMessageValue = {
      template: inspectionTemplateData.data
    };

    return [
      ...getPrimaryRecordActionGroup('task_inspection', inspection.id),
      getToggleClosedAction(inspection),
      getCreateEmailAction(inspection, initialMessageValue),
      getCreateSMSAction(inspection, initialMessageValue),
      getAddDocumentsAction({ data: inspection }),
      getAddNotesAction({ data: inspection }),
      ...(sendInspectionReportAction ? [sendInspectionReportAction] : []),
      ...(inspection?.details?.inspection_run_id
        ? [getRemoveInspectionFromInspectionRun(inspection)]
        : []),
      ...(hasFeature(FLAGS.INSPECTION_SCHEDULE)
        ? [
            getEditLocationAction({
              property: inspection.property!,
              shouldCheckForInspectionRun: true
            })
          ]
        : [])
    ];
  };
}
