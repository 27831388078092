import React from 'react';
import { isArray } from 'lodash';
import { GhostButton, PrimaryButton } from '@rexlabs/button';
import { StandardDialogFooter } from 'view/components/dialogs/components/standard-dialog-footer';
import { ButtonGroupProps } from 'view/components/record-screen/dialog/types';
import { pluralize } from 'utils/formatters';
import { quoteDetailsBlock } from '../blocks/quote-details-block';
import { GoToOutboxSplitButton } from '../../common/components/go-to-outbox-split-button';

export function AddQuoteDialogButtonGroup({
  onClose,
  handleSubmit,
  isSubmitting,
  forms,
  ...props
}: ButtonGroupProps) {
  async function handleSubmitAndClose() {
    const data = await handleSubmit();

    if (!data) return;

    onClose?.();
  }

  const hasReceivedQuote =
    forms?.[quoteDetailsBlock.id!]?.values?.details?.has_received_quote;

  const suppliersFromForm: Record<string, any>[] =
    forms?.[quoteDetailsBlock.id!]?.values?.details?.supplier;
  const suppliers = isArray(suppliersFromForm)
    ? suppliersFromForm
    : [suppliersFromForm];

  const buttonLabel = hasReceivedQuote
    ? 'Save quote'
    : `Request ${pluralize('quote', suppliers.length, suppliers.length > 1)}`;

  return (
    <StandardDialogFooter>
      <GhostButton onClick={onClose}>Cancel</GhostButton>
      {hasReceivedQuote ? (
        <PrimaryButton type='submit' onClick={handleSubmitAndClose}>
          {buttonLabel}
        </PrimaryButton>
      ) : (
        <GoToOutboxSplitButton
          onClose={onClose}
          handleSubmit={handleSubmit}
          isSubmitting={isSubmitting}
          buttonLabel={buttonLabel}
          {...props}
        />
      )}
    </StandardDialogFooter>
  );
}
