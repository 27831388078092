import React from 'react';
import { StylesProvider, StyleSheet } from '@rexlabs/styling';
import { NumberInput, NumberInputProps } from '@rexlabs/text-input';

const overrides = {
  TextInput: StyleSheet({
    input: {
      textAlign: 'right'
    }
  })
};

const Suffix = () => <span>week(s)</span>;

export function WeeksInput(props: NumberInputProps) {
  return (
    <StylesProvider components={overrides}>
      <NumberInput {...props} Suffix={Suffix} />
    </StylesProvider>
  );
}
