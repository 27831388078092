import { Columns, ListTable, TabbedTable } from 'view/components/table';
import React, { useMemo } from 'react';
import { StyleSheet, useStyles } from '@rexlabs/styling';
import { query } from '@rexlabs/model-generator';
import { useMediaQuery } from '@rexlabs/breakpoints';
import { Small } from '@rexlabs/text';
import ROUTES from 'routes/app';
import { useTrustJournalEntryActions } from 'src/modules/trust-journal-entries/actions/use-trust-journal-entry-actions';
import { useTableFilters } from 'view/hooks/use-table-filters';
import { TrustJournalEntry } from 'src/modules/trust-journal-entries/types/trust-journal-entry-types';
import { trustJournalEntryModel } from 'src/modules/trust-journal-entries/models/trust-journal-entries';
import { auditableColumns } from 'src/modules/common/auditable/auditable-columns';
import { StatusTag } from 'src/modules/common/components/status-tag';
import { trustJournalEntryStatusMap } from 'src/modules/trust-journal-entries/maps/trust-journal-entry-status-map';
import { TagCell } from '@rexlabs/table';
import { transformActionDeclarationsToActionMenuItems } from 'utils/actions/transforms';
import { Tab } from 'view/components/table/tabbed';
import { TwoTierCell } from 'view/components/cells/two-tier-cell';
import { LargeDescriptionCell } from 'view/components/cells/large-description-cell';
import { Contact } from 'src/modules/contacts/types/contact-types';

export const getTrustJournalEntriesQuery = () => query`{
    ${trustJournalEntryModel} {
      id
      payment_information
      created_by
      updated_by
      contacts
      transfer_source {
        trust_ledger
      }
    }
  }`;

const styles = StyleSheet({
  subText: {
    color: ({ token }) => token('color.textStyle.body.subtext')
  }
});

export const trustJournalEntryColumns: Columns<TrustJournalEntry> = [
  {
    id: 'contact',
    accessor: (item) => item,
    width: 280,
    Header: () => {
      const s = useStyles(styles);

      return (
        <div>
          <div>Received from / Paid to / Transferred from</div>
          <div {...s('subText')}>
            <Small semibold as='div'>
              Reference
            </Small>
          </div>
        </div>
      );
    },
    toCsv: (item) => {
      const recordText = getRecordText(item);

      return `${recordText} ${item.record_reference}`;
    },
    Cell: ({ value }) => {
      const recordText = getRecordText(value);

      return (
        <TwoTierCell text={recordText} subtext={value?.record_reference} />
      );
    }
  },
  {
    id: 'status_id',
    Header: 'Status',
    toCsv: (item) => {
      return item.label;
    },
    accessor: (item) => item.status,
    Cell: (props) => {
      return (
        <TagCell>
          <StatusTag status={props.value} map={trustJournalEntryStatusMap} />
        </TagCell>
      );
    },
    width: 140
  },
  {
    id: 'description',
    Header: 'Description',
    accessor: (item) => item.description,
    Cell: ({ value }) => {
      return <LargeDescriptionCell text={value} lineClamp={2} />;
    },
    width: 350
  },
  {
    id: 'date_of',
    type: 'date',
    Header: 'Date',
    width: 120,
    accessor: (item) => item.date_of
  },
  {
    id: 'type_id',
    type: 'value',
    Header: 'Type',
    width: 120,
    accessor: (item) => item.type
  },
  {
    id: 'amount',
    type: 'currency',
    cellProps: {
      align: 'right'
    },
    width: 120,
    Header: 'Amount',
    accessor: (item) => item.amount
  },

  {
    id: 'payment_method_id',
    type: 'value',
    Header: 'Method',
    accessor: (item) => item.payment_information?.method,
    width: 120
  },
  ...auditableColumns
];

function getRowLinkProps({ item }) {
  return {
    to: ROUTES.TRUST_JOURNAL_ENTRY,
    params: { trustJournalEntryId: item.id }
  };
}

export interface TrustJournalEntriesTableProps {
  bankAccountId: string;
}

export function TrustJournalEntriesTable({
  bankAccountId
}: TrustJournalEntriesTableProps) {
  const isSmallScreen = useMediaQuery({ maxWidth: 'xl' });
  const getQuery = useMemo(() => () => getTrustJournalEntriesQuery(), []);
  const getActions = useTrustJournalEntryActions();
  const { getSort, getFilters } = useTableFilters('trust-journal-entries');

  const tabs = useMemo<Tab[]>(() => {
    const defaultForcedFilters = [
      {
        field: 'bank_account_id',
        op: 'eq',
        value: bankAccountId
      }
    ];

    const id = 'trust-journal-entries';

    const commonTableProps = {
      id,
      Table: ListTable,
      getQuery: getQuery,
      columns: trustJournalEntryColumns,
      initialHiddenColumns: [
        'created_by',
        'created_at',
        'updated_at',
        'updated_by',
        'payment_method_id',
        ...(isSmallScreen ? ['description'] : [])
      ],
      alwaysExpandSearch: true,

      /**
       *  Note: This table persists filters and sorting to localstorage and now we have changed the
       *  default sorting from `created_at` to `date_of` which needs to be updated for the users, so for
       *  now we will ignore the previous cached table settings and after 1-2 weeks we will need to remove
       *  this blacklist to take advantage of persistance.
       */
      blacklistTableSettingKeys: [id],
      shouldPersistTableSettings: false,

      initialSortBy: [{ id: 'date_of', label: 'Date of', desc: true }],
      getRowLinkProps: getRowLinkProps,
      suggestedFilters: ['status_id', 'date_of'],
      getSort,
      getFilters,
      getActionMenuItems: ({ item }: { item: TrustJournalEntry }) =>
        transformActionDeclarationsToActionMenuItems(getActions(item))
    };

    return [
      {
        ...commonTableProps,
        name: 'all',
        label: 'All',
        forcedGlobalFilter: defaultForcedFilters
      },
      {
        ...commonTableProps,
        name: 'receipts',
        label: 'Receipts',
        forcedGlobalFilter: [
          ...defaultForcedFilters,
          {
            field: 'type_id',
            op: 'in',
            value: ['receipt', 'reverse_receipt']
          }
        ]
      },
      {
        ...commonTableProps,
        name: 'withdrawals',
        label: 'Withdrawals',
        forcedGlobalFilter: [
          ...defaultForcedFilters,
          {
            field: 'type_id',
            op: 'in',
            value: ['withdrawal', 'reverse_withdrawal']
          }
        ]
      },
      {
        ...commonTableProps,
        name: 'transfers',
        label: 'Transfers',
        forcedGlobalFilter: [
          ...defaultForcedFilters,
          {
            field: 'type_id',
            op: 'in',
            value: ['transfer', 'reverse_transfer']
          }
        ]
      }
    ];
  }, [getQuery, bankAccountId]);

  return <TabbedTable tabs={tabs} />;
}

function getRecordText(item: TrustJournalEntry) {
  let result = item.contact?.display_name;

  if (item.contacts?.data?.length) {
    result = item.contacts.data
      .map((contact: Contact) => contact.display_name)
      .join(', ');
  }

  if (item.transfer_source?.trust_ledger) {
    result = item.transfer_source.trust_ledger.name;
  }

  return result;
}
