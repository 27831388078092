import React from 'react';

import { DateTimeValue } from 'view/components/values/date';
import { Value } from 'view/components/values';
import { PreformattedValue } from 'view/components/values/preformatted';
import { Grid } from 'view/components/@luna/form/grid';

import { AuditLog } from '../types/AuditLog';

export function AuditLogDetailsView({ auditLog }: { auditLog?: AuditLog }) {
  return (
    <Grid columns={1}>
      <Grid columns={3}>
        <DateTimeValue label={'Date'} value={auditLog?.date} />
        <Value label={'Change ID'} value={auditLog?.id} />
        <Value label={'User'} value={auditLog?.author_name} />

        <Value label={'Record Type'} value={auditLog?.record_type.label} />
        <Value label={'Record'} value={auditLog?.record_name} />
        <Value label={'Change Type'} value={auditLog?.change_type.label} />
      </Grid>
      <PreformattedValue label='Details' value={auditLog?.changeset} />
    </Grid>
  );
}
