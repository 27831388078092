import { query } from '@rexlabs/model-generator';
import EmptyTable from 'assets/illustrations/empty-table.svg';
import * as React from 'react';
import { TaskType } from 'src/modules/tasks/common/types/TaskType';
import { Message } from 'view/components/@luna/message';
import { ActionButtons } from 'view/components/action-buttons';
import { EmptyCard } from 'view/components/record-screen/cards/empty-card';
import { BlockConfig } from 'view/components/record-screen/types';
import { LoadingState } from 'view/components/states/loading';
import { RecordTable } from 'view/components/table';
import { getRecordLinkProps } from 'view/components/record-link/get-record-link-props';
import { transformActionDeclarationsToActionMenuItems } from 'utils/actions/transforms';
import { useGetChecklistTemplateActions } from '../hooks/action-menu-items/use-get-checklist-template-actions';
import { checklistTemplatesModel } from '../models/checklist-templates-model';
import { useChecklistTemplateColumns } from '../utils/use-checklist-template-columns';
import { useOpenCreateChecklistTemplateDialog } from '../hooks/use-open-create-checklist-template-dialog';
import { useGetIsSmartChecklist } from '../hooks/use-get-is-smart-checklist';
import { ChecklistTemplate } from '../types/ChecklistTemplate';

interface CreateChecklistTemplateBlockProps {
  type: TaskType;
  label: string;
}

const getQuery = () => query`{
  ${checklistTemplatesModel} {
    id
    updated_by,
    items
  }
}`;

export function createChecklistTemplateBlock(
  args: CreateChecklistTemplateBlockProps
): BlockConfig {
  const { type, label } = args;
  const EmptyState = withEmptyState({ type, label });

  return {
    id: `${type}-checklist-template`,
    title: `${label} checklists`,

    View: () => {
      const getChecklistTemplateActions = useGetChecklistTemplateActions({
        withEditAction: true
      });

      const forcedGlobalFilter = React.useMemo(
        () => [{ field: 'task_type_id', op: 'eq', value: type }],
        []
      );

      const forcedSortBy = React.useMemo(
        () => [
          {
            id: 'updated_at',
            label: 'Updated at',
            desc: true
          }
        ],
        []
      );

      const getIsSmartChecklist = useGetIsSmartChecklist();

      const getActionMenuItems = React.useCallback(
        ({ item }) =>
          transformActionDeclarationsToActionMenuItems(
            getChecklistTemplateActions(item as ChecklistTemplate)
          ),
        []
      );

      const columns = useChecklistTemplateColumns({ taskType: type });

      return (
        <RecordTable
          id='checklist-templates'
          withPagination
          emptyWithoutTable
          columns={columns}
          getQuery={getQuery}
          forcedGlobalFilter={forcedGlobalFilter}
          forcedSortBy={forcedSortBy}
          Empty={EmptyState}
          Loading={Loading}
          getActionMenuItems={getActionMenuItems}
          getRowLinkProps={
            getIsSmartChecklist(type)
              ? ({ item }) =>
                  getRecordLinkProps({ type: 'checklist', id: item.id })
              : undefined
          }
        />
      );
    },

    Actions: () => {
      const openCreateInspectionDialog = useOpenCreateChecklistTemplateDialog({
        type,
        label
      });

      return (
        <ActionButtons
          actions={[
            {
              label: 'Create checklist',
              onClick: () => openCreateInspectionDialog()
            }
          ]}
        />
      );
    }
  };
}

// UI States

const Loading = () => <LoadingState>Loading items...</LoadingState>;

const withEmptyState = ({ type, label }: CreateChecklistTemplateBlockProps) => {
  function EmptyState() {
    const openCreateInspectionDialog = useOpenCreateChecklistTemplateDialog({
      type,
      label
    });

    return (
      <EmptyCard>
        <Message
          title='Create a checklist for this task'
          Illustration={EmptyTable}
          actions={[
            {
              label: 'Get started',
              intent: 'primary',
              handleAction: () => openCreateInspectionDialog()
            }
          ]}
        >
          <div>
            By creating a checklist for this task type, all future tasks of this
            type will display this checklist. It&apos;s a great way to drive
            process in your agency
          </div>
        </Message>
      </EmptyCard>
    );
  }

  return EmptyState;
};
