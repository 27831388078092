import React from 'react';

import { Quri, toQuri } from '@rexlabs/quri';
import { query } from '@rexlabs/model-generator';

import { getNoteQueryFilterId } from '../utils/get-note-query-filter-id';
import { ParentRecordDataType } from '../types/ParentRecordDataType';
import { notesModel } from '../models/notes-model';
import { getNoteQueryFilterTypeId } from '../utils/get-note-query-filter-type-id';

const getFilteredQuery = (filter: string) => query`{
    ${notesModel} (q: ${filter}) {
        title
        content
        created_by
        updated_by
        attached_to
    }
  }`;

export function getNotesQuery(filterKey: string, id?: string | null) {
  if (!id) {
    return null;
  }
  const quris: Quri[] = [
    {
      field: filterKey,
      op: '==',
      value: id
    }
  ];

  return getFilteredQuery(toQuri(quris));
}

export function useNoteQuery(parentRecord: ParentRecordDataType) {
  const filterKey = getNoteQueryFilterTypeId(parentRecord);

  const filterId = getNoteQueryFilterId(parentRecord);

  return React.useMemo(() => getNotesQuery(filterKey, filterId), [
    filterKey,
    filterId
  ]);
}
