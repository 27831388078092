import { CreditNote } from 'data/models/entities/financials/credit-notes';

export function getCreditNoteTitle(creditNote: CreditNote) {
  const invoice = creditNote?.application?.invoice_payment_entry?.invoice;
  const invoiceRecordReference = invoice?.record_reference;

  return `${creditNote?.template.label}${
    invoiceRecordReference ? ` for ${invoiceRecordReference}` : ''
  }`;
}
