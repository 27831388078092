import {
  financialsSecurityDepositsModel,
  SecurityDeposit,
  SecurityDepositStatus
} from 'data/models/entities/financials/security-deposits';
import { useGetGenericWipAction } from 'view/hooks/actions/use-get-generic-wip-action';
import { useGetPrimaryRecordActionGroup } from 'view/hooks/actions/use-get-primary-record-action-group';
import { ActionDeclaration } from 'src/modules/common/actions/types/action-declaration-types';
import { useStatusChangeAction } from 'src/modules/common/actions/status/use-status-change-action';
import { useDeleteAction } from 'src/modules/common/actions/delete/use-delete-action';
import { useGetEditSecurityDepositDetailsAction } from '../utils/security-deposit-actions/use-get-edit-security-deposit-details-action';

export function useGetSecurityDepositActions() {
  const getPrimaryRecordActionGroup = useGetPrimaryRecordActionGroup();
  const getEditSecurityDepositDetailsAction = useGetEditSecurityDepositDetailsAction();
  const getGenericWipAction = useGetGenericWipAction();
  const statusChangeAction = useStatusChangeAction(
    financialsSecurityDepositsModel
  );
  const deleteAction = useDeleteAction(financialsSecurityDepositsModel);

  return (securityDeposit?: SecurityDeposit): ActionDeclaration[] => {
    if (!securityDeposit) return [];

    const statusChangeActions: ActionDeclaration[] = [];

    const archiveAction = statusChangeAction<SecurityDepositStatus>({
      record: securityDeposit,
      status: 'archived'
    });

    const activeAction = statusChangeAction<SecurityDepositStatus>({
      record: securityDeposit,
      status: 'active'
    });

    switch (securityDeposit.status.id) {
      case 'active':
        statusChangeActions.push(archiveAction);
        break;
      case 'archived':
        statusChangeActions.push(activeAction);
        break;
    }

    return [
      ...getPrimaryRecordActionGroup('security_deposit', securityDeposit.id),
      getEditSecurityDepositDetailsAction(securityDeposit),
      getGenericWipAction({
        id: 'refund-request',
        group: 'change-request',
        actionName: 'Create refund request'
      }),
      getGenericWipAction({
        id: 'topup-request',
        group: 'change-request',
        actionName: 'Create topup request'
      }),
      ...statusChangeActions,
      deleteAction(securityDeposit)
    ];
  };
}
