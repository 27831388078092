import { ActionDeclaration } from 'src/modules/common/actions/types/action-declaration-types';
import { useGetToggleClosedAction } from 'src/modules/tasks/common/hooks/action-declarations/use-get-toggle-closed-action';
import { useGetPrimaryRecordActionGroup } from 'view/hooks/actions/use-get-primary-record-action-group';
import { useGetAddDocumentsAction } from 'src/modules/documents/actions/use-get-add-documents-action';
import { useGetAddNotesAction } from 'src/modules/tasks/common/actions/get-add-notes-action';
import { useGetCreateEmailAction } from 'src/modules/communications/messages/hooks/action-declarations/use-get-create-email-action';
import { tasksModel } from 'src/modules/tasks/common/models/tasks-model';
import { useGetCreateSMSAction } from 'src/modules/communications/messages/hooks/action-declarations/use-get-create-sms-action';
import { useGetActionsFromStatus } from '../use-get-actions-from-status';
import { QuoteTask } from '../../types/QuoteTask';

export function useGetQuoteActions() {
  const getPrimaryRecordActionGroup = useGetPrimaryRecordActionGroup();
  const getToggleClosedAction = useGetToggleClosedAction();
  const getGetActionsFromStatus = useGetActionsFromStatus();
  const getAddDocumentsAction = useGetAddDocumentsAction();
  const getAddNotesAction = useGetAddNotesAction();
  const getCreateEmailAction = useGetCreateEmailAction(tasksModel);
  const getCreateSMSAction = useGetCreateSMSAction(tasksModel);

  return (quoteTask?: QuoteTask): ActionDeclaration[] => {
    if (!quoteTask) return [];

    const { primaryActions, secondaryActions } = getGetActionsFromStatus(
      quoteTask
    );

    secondaryActions.push(getToggleClosedAction(quoteTask));

    const actions = [
      ...getPrimaryRecordActionGroup('task_quote', quoteTask.id),
      getCreateEmailAction(quoteTask),
      getCreateSMSAction(quoteTask),
      getAddDocumentsAction({ data: quoteTask }),
      getAddNotesAction({ data: quoteTask }),
      ...primaryActions,
      {
        label: 'Actions',
        actions: [...secondaryActions]
      }
    ];

    return actions;
  };
}
