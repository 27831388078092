import React, { useCallback, useMemo, useRef } from 'react';
import { Dropdown, SelectProps } from '@rexlabs/select-old';
import { FieldActions } from '@rexlabs/form';
import { Quri, toQuri } from '@rexlabs/quri';
import { useModelActions } from '@rexlabs/model-generator';
import { useDialog } from '@rexlabs/dialog';

import { api } from 'utils/api/api-client';
import { CreateContactPaymentMethodDialog } from 'src/modules/contacts/dialogs/create-contact-payment-method';
import { normaliseContactPaymentMethod } from 'utils/normaliser/contact-payment-methods';
import { contactsPaymentMethodsModel } from 'data/models/entities/contacts/payment-methods';
import { useToast } from 'view/components/@luna/notifications/toast';

type getContactPaymentMethodsArgs = {
  contactId?: string;
  ownershipId?: string;
  forDisbursements?: boolean;
};

function applyFilters(url: string, filters: Quri[]) {
  if (filters.length === 0) {
    return url;
  }

  const filterString = toQuri(filters);
  return `${url}?q=${filterString}`;
}

function getContactPaymentMethodsUrl({
  contactId,
  ownershipId,
  forDisbursements
}: getContactPaymentMethodsArgs) {
  const filters: Quri[] = [];

  if (forDisbursements) {
    filters.push({
      field: 'valid_for_disbursement',
      op: '==',
      value: 'true'
    });
  }

  if (ownershipId) {
    filters.push({
      field: 'ownership_id',
      op: '==',
      value: ownershipId
    });
    return applyFilters(`financials/contact-payment-methods`, filters);
  }

  return applyFilters(`contacts/${contactId}/payment-methods`, filters);
}

export type ContactPaymentMethodSelectProps = Omit<SelectProps, 'items'> & {
  actions?: FieldActions;
  contactId?: string;
  ownershipId?: string;
  forDisbursements?: boolean;
};

export function ContactPaymentMethod({
  contactId,
  ownershipId,
  forDisbursements,
  actions,
  ...props
}: ContactPaymentMethodSelectProps) {
  const { open } = useDialog(CreateContactPaymentMethodDialog);
  const { refreshLists } = useModelActions(contactsPaymentMethodsModel);
  const { addToast } = useToast();

  const [currentMethods, setCurrentMethods] = React.useState<any[]>([]);

  // HACK: remove once Select passes current search value through to action
  const searchValue = useRef('');

  const getItems = useCallback(
    async (searchTerm) => {
      searchValue.current = searchTerm;

      if (!contactId && !ownershipId) {
        return [];
      }

      const contactPaymentMethods = await api.get(
        getContactPaymentMethodsUrl({
          contactId,
          ownershipId,
          forDisbursements
        }),
        {
          per_page: 30
        }
      );
      return contactPaymentMethods.data;
    },
    [contactId, forDisbursements, ownershipId]
  );

  React.useEffect(() => {
    (async () => {
      const items = await getItems('');
      setCurrentMethods(items);
    })();
  }, [getItems]);

  const fixtures = useMemo(() => {
    if (contactId) {
      return [
        {
          label: 'Add new payment method',
          action: () =>
            open({
              contactId,
              isForDisbursements: forDisbursements ?? true,
              hasExistingChequeMethod: currentMethods.some(
                (method) => method.payment_method.id === 'cheque'
              ),
              initialValues: { name: searchValue.current },
              onCreate: async (contactPaymentMethod) => {
                actions?.setValue(contactPaymentMethod);
                await refreshLists();

                addToast({
                  description: (
                    <>
                      Payment method <b>{contactPaymentMethod.name}</b> created
                      successfully
                    </>
                  )
                });
              }
            })
        }
      ];
    }

    return [];
  }, [
    contactId,
    open,
    forDisbursements,
    currentMethods,
    actions,
    refreshLists,
    addToast
  ]);

  return (
    <Dropdown
      items={getItems}
      normaliser={normaliseContactPaymentMethod}
      fixtures={fixtures}
      {...props}
    />
  );
}
