import React from 'react';
import { BlockConfig } from 'view/components/record-screen/types';
import { Grid } from 'view/components/@luna/form/grid';
import { Field } from '@rexlabs/form';
import invariant from 'invariant';
import { RadioGroupInput } from '@rexlabs/radio-input';
import { ChecklistTemplatesItemForm } from '../../types/ChecklistTemplatesForm';
import { ChecklistTemplateItem } from '../../types/ChecklistTemplate';

export const checklistTemplateItemSubtaskTypeBlock: BlockConfig<
  ChecklistTemplateItem,
  {
    setSubtypeId?: (subtypeId: string) => void;
  },
  ChecklistTemplatesItemForm & { new_outcome_option: string }
> = {
  // NOTE: for some reason, if this id is not the same as the id of the block in the checklist-template-item-details-block.tsx file,
  // There is unexpected behavior where the other block does not receive the latest version of the `type` field... Unclear why this is the case.
  id: 'checklist-template-item-details',
  title: null,
  Edit({ blockProps, forms, getHandlers }) {
    invariant(blockProps, 'blockProps is required');

    const handlers = getHandlers?.(forms);

    const options = [
      { value: 'default', label: 'Default checklist item' },
      { value: 'send_message', label: 'Send message' },
      { value: 'record_outcome', label: 'Record outcome' },
      { value: 'trigger_action', label: 'Trigger action' },
      { value: 'approval_request', label: 'Approval request' }
    ];

    const handleChange = (e) => {
      handlers?.handleTypeChange?.(e);
      blockProps.setSubtypeId?.(e.target.value);
    };

    return (
      <Grid columns={1}>
        <Field
          name='type.id'
          Input={RadioGroupInput}
          optional={false}
          onChange={handleChange}
          inputProps={{
            options,
            orientation: 'horizontal'
          }}
        />
      </Grid>
    );
  }
};
