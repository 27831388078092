import { ChecklistTemplateItem } from '../types/ChecklistTemplate';

/**
 * Returns the global index of the item relative to all ordered items, regardless of group.
 */
export function getItemIndex(
  item: ChecklistTemplateItem,
  items: ChecklistTemplateItem[]
) {
  return items.findIndex((i) => i.id === item.id);
}
