import { parseUploadedFileMeta } from 'utils/files';
import { getConditionValue } from 'src/modules/tasks/inspections/entry-exit/utils/get-condition-value';
import { InspectionTask } from '../../types/InspectionTask';
import { EntryExitInspectionFormData } from '../types/EntryExitInspectionFormData';
import { mapInspectionTaskToInspectionFormData } from '../../mappers/map-inspection-task-to-inspection-form-data';
import { InspectionArea } from '../types/InspectionArea';

export function mapEntryExitTaskToFormData(
  task: InspectionTask
): EntryExitInspectionFormData {
  const areaItems = getAreaItems(task.details?.areas?.data);

  return {
    ...mapInspectionTaskToInspectionFormData(task),
    property_details: task?.details?.property_details,
    area_items: areaItems,
    cover_image: task?.details?.cover_image
      ? parseUploadedFileMeta([task.details.cover_image])[0]
      : null,
    cover_summary: task?.details?.cover_summary || null,
    supporting_documents: parseUploadedFileMeta(
      task?.details?.supporting_documents?.data || []
    )
  };
}

function getAreaItems(
  areaData?: Array<InspectionArea>
): EntryExitInspectionFormData['area_items'] {
  const result: EntryExitInspectionFormData['area_items'] = {};

  if (!areaData) return result;

  const areaItems = areaData
    .flatMap((area) => area.items?.data)
    .map((areaItem) => {
      return [
        areaItem!.id,
        {
          ...areaItem,
          images: {
            data: parseUploadedFileMeta(areaItem?.images.data)
          },
          conditions: {
            clean: getConditionValue(
              areaItem?.inspection_conditions?.data,
              'Clean'
            ),
            working: getConditionValue(
              areaItem?.inspection_conditions?.data,
              'Working'
            ),
            undamaged: getConditionValue(
              areaItem?.inspection_conditions?.data,
              'Undamaged'
            )
          }
        }
      ];
    });

  return Object.fromEntries(areaItems);
}
