import React, { forwardRef, CSSProperties, PropsWithChildren } from 'react';

import Box, { BoxProps } from '@rexlabs/box';
import { useStyles, StyleSheet, border, mq } from '@rexlabs/styling';

const defaultStyles = StyleSheet({
  container: {
    width: '100%',
    overflow: 'hidden',

    ...border.styles({
      target: 'card',
      all: {
        width: 'none',
        radius: 'none'
      }
    }),

    background: ({ token }) =>
      token('card.background.color', token('color.container.static.default')),

    ...mq.styles({
      queries: {
        minWidth: 's'
      },
      styles: {
        ...border.styles({
          target: 'card',
          all: {
            width: 'none',
            radius: 'l'
          }
        })
      }
    })
  }
});

export type CardProps = PropsWithChildren<
  BoxProps & {
    style?: CSSProperties;
    className?: string;
  }
>;

export const Card = forwardRef<HTMLDivElement, CardProps>(function Card(
  { children, style, className, ...props },
  ref
) {
  const s = useStyles(defaultStyles);
  return (
    <Box
      {...s.with('container')({ style, className })}
      flex={1}
      flexDirection='column'
      {...props}
      ref={ref}
    >
      {children}
    </Box>
  );
});
