import * as React from 'react';

import { BlockConfig } from 'view/components/record-screen/types';

import { ParentRecordDataType } from '../types/ParentRecordDataType';
import { useNoteQuery } from '../hooks/use-note-query';
import { NotesList } from '../components/notes-list';
import { ReadOnlyNotesListEmptyState } from '../components/read-only-notes-list-empty-state';
import { useGetNoteListLinkProps } from '../hooks/use-get-note-list-link-props';
import { useGetReadOnlyNoteActions } from '../hooks/use-get-read-only-note-actions';

export const readOnlyNoteTableBlock: BlockConfig<ParentRecordDataType> = {
  id: 'notes',
  title: 'Notes',
  View: ({ data }) => {
    const noteQuery = useNoteQuery(data!);

    const getNoteListLinkProps = useGetNoteListLinkProps(false);
    const getReadOnlyNoteActions = useGetReadOnlyNoteActions();

    return (
      <NotesList
        Empty={ReadOnlyNotesListEmptyState}
        getActionMenuItems={({ item }) => getReadOnlyNoteActions(item)}
        getQuery={noteQuery ? () => noteQuery : undefined}
        getRowLinkProps={getNoteListLinkProps}
      />
    );
  }
};
