import React from 'react';

import AttachmentIcon from 'view/components/icons/attachment';

interface DocumentTypeIndicatorProps {
  type: string;
}

export function DocumentTypeIndicator({ type }: DocumentTypeIndicatorProps) {
  if (type.includes('image')) {
    return <>Image</>;
  }

  if (type.includes('pdf')) {
    return <>PDF</>;
  }

  if (type === 'text/plain') {
    return <>Text</>;
  }

  return <AttachmentIcon />;
}
