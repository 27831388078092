import { useCallback } from 'react';

import { useErrorDialog } from '@rexlabs/dialog';
import { useModelActions } from '@rexlabs/model-generator';

import { useToast } from 'view/components/@luna/notifications/toast';
import { RecordSubmitHandler } from 'view/components/record-screen/utils';

import { keySetsModel } from '../data/key-set-model';
import { CheckInKeySetFormValues } from '../blocks/check-in-key-set-block';

export const useCheckInKeySetSubmitHandler = () => {
  const { checkInKey, refreshLists: refreshKeySetLists } = useModelActions(
    keySetsModel
  );

  const { open: openErrorDialog } = useErrorDialog();

  const { addToast } = useToast();

  const onHandleSubmit: RecordSubmitHandler<CheckInKeySetFormValues> = useCallback(
    async ({ values, changedValues }) => {
      try {
        const data = { ...changedValues };

        const { data: checkedOutKey } = await checkInKey({
          ...data,
          id: values.id!,
          checked_in_date: data?.checked_in_date || values.checked_in_date!
        });

        addToast({
          description: `${checkedOutKey.name} has been checked-in`
        });

        refreshKeySetLists();

        return checkedOutKey;
      } catch (error) {
        console.error(error);
        openErrorDialog(error);
      }
    },
    [addToast, checkInKey, openErrorDialog, refreshKeySetLists]
  );

  return { onHandleSubmit };
};
