import { useDialog } from '@rexlabs/dialog';

import {
  Invoice,
  financialsInvoicesModel
} from 'data/models/entities/financials/invoices';

import { ActionDeclaration } from 'src/modules/common/actions/types/action-declaration-types';
import { CreateEmailRecordDialog } from 'src/modules/communications/messages/dialogs/create-email-record-dialog';
import { getSearchResultItemFromObjectAndModel } from 'src/modules/common/utils/search-result-items/get-search-result-items-from-object-and-model';

export function useGetSendInvoiceAction() {
  const { open: openCreateEmailDialog } = useDialog(CreateEmailRecordDialog);
  return (invoice: Invoice): ActionDeclaration => ({
    id: 'send-invoice',
    group: 'messages',
    label: 'Send invoice',
    handleAction: () => {
      const invoiceSearchItem = getSearchResultItemFromObjectAndModel<Invoice>(
        invoice,
        financialsInvoicesModel
      );

      openCreateEmailDialog({
        data: {
          relates_to: invoiceSearchItem
        }
      });
    }
  });
}
