/**
 * At this stage this file is purely to generate fake data for the frontend.
 * I have now idea of the shape of the data that will come from the backend, but
 * I want to see the FE in action as I'm building it out.
 *
 * Once BE is available, delete this file, or clean it up to use the real fields.
 */
import faker from '@faker-js/faker';

import { contactFactory } from 'src/modules/contacts/factories/contact-factory';
import { propertyFactory } from 'src/modules/properties/factories/property-factory';

import { AnnualPayments } from '../types/annual-payments';

export function annualPaymentsFactory(
  state?: Partial<AnnualPayments>
): AnnualPayments {
  return {
    id: faker.datatype.uuid(),
    contact: contactFactory(),
    property: propertyFactory(),
    ownership: 1,
    income: faker.datatype.number({ min: 1000, max: 100000 }),
    expenses: faker.datatype.number({ min: 1000, max: 100000 }),
    tax_paid: faker.datatype.number({ min: 1000, max: 100000 }),
    ...state
  };
}
