import React from 'react';
import { CellProps } from '@rexlabs/table';
import Box from '@rexlabs/box';
import { StyleSheet, useStyles } from '@rexlabs/styling';

import { formatCurrency } from 'utils/formatters';

import { TwoTierCell } from 'view/components/cells/two-tier-cell';

import { QuoteTask } from '../types/QuoteTask';
import { isQuotePriceLessThanSpendLimit } from '../utils/is-quote-price-less-than-spend-limit';

const defaultStyles = StyleSheet({
  text: {
    textAlign: 'right',
    width: '100%'
  },
  subText: {
    color: ({ token }) => token('color.textStyle.body.error')
  }
});

function Subtext({ value }) {
  const s = useStyles(defaultStyles);
  return <Box {...s('text', 'subText')}>{value}</Box>;
}

function Text({ value }) {
  const s = useStyles(defaultStyles);
  return <Box {...s('text')}>{value}</Box>;
}

export function PriceTwoTierCell({ value }: CellProps<any, QuoteTask>) {
  const price = value.details?.amount
    ? formatCurrency(value.details?.amount)
    : '--';

  const subtext = !isQuotePriceLessThanSpendLimit(value)
    ? 'Exceeding limit'
    : '';

  return (
    <TwoTierCell
      subtext={<Subtext value={subtext} />}
      text={<Text value={price} />}
    />
  );
}
