import { ActionDeclaration } from 'src/modules/common/actions/types/action-declaration-types';
import { ComplianceEntry } from '../models/compliance-entries';
import { useGetDeleteComplianceEntryAction } from './use-get-delete-compliance-entry-action';

export function useComplianceEntryActions() {
  const getDeleteComplianceEntry = useGetDeleteComplianceEntryAction();

  return (data: ComplianceEntry): ActionDeclaration[] => {
    return [getDeleteComplianceEntry({ data })];
  };
}
