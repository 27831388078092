import { useDialog } from '@rexlabs/dialog';

import { KeySet } from '../data/key-set-model';
import { CheckInKeySetDialog } from '../dialogs/check-in-key-set-dialog';
import { useCheckInKeySetSubmitHandler } from './use-check-in-key-set-submit-handler';

export function useGetCheckInKeySetAction() {
  const { open: openCheckOutKeyDialog } = useDialog(CheckInKeySetDialog);

  const { onHandleSubmit } = useCheckInKeySetSubmitHandler();

  return (key: KeySet) => {
    return {
      id: 'check-in',
      label: 'Check-in',
      intent: 'primary',
      onClick: () =>
        openCheckOutKeyDialog({ keyId: key.id, handleSubmit: onHandleSubmit })
    };
  };
}
