import { useConfirmationDialog, useErrorDialog } from '@rexlabs/dialog';
import { useToast } from 'view/components/@luna/notifications/toast';
import React from 'react';
import { SingleActionDeclaration } from 'src/modules/common/actions/types/action-declaration-types';

export type GetDeleteActionArgs<ID extends string, Group extends string> = {
  id: ID;
  group: Group;
  recordTypeName: string;
  recordName: string;
  message?: React.ReactNode;
  label?: string;
  title?: React.ReactNode;
  confirmText?: React.ReactNode;
  actionConducted?: string;
  handleDelete: (values: ArchiveFormValues) => Promise<void>;
};

export type ArchiveFormValues = {
  reason?: string;
};

/**
 * @deprecated you should use useDeleteAction()
 */
export function useGetGenericDeleteAction() {
  const { open: openConfirmationDialog } = useConfirmationDialog();
  const { addToast } = useToast();
  const { open: openErrorDialog } = useErrorDialog();

  return <ID extends string, Group extends string>({
    id,
    group,
    recordTypeName,
    recordName,
    message,
    label,
    title,
    confirmText,
    actionConducted = 'deleted',
    handleDelete
  }: GetDeleteActionArgs<ID, Group>): SingleActionDeclaration => {
    return {
      id,
      group,
      label: label || 'Delete',
      intent: 'danger',
      handleAction: () =>
        openConfirmationDialog({
          title: title || `Delete ${recordTypeName}`,
          destructive: true,
          confirmText: confirmText || `Delete ${recordTypeName}`,
          message: message || 'Are you sure you want to delete this?',
          //@ts-ignore
          onConfirm: async (values: ArchiveFormValues) => {
            try {
              await handleDelete(values);
            } catch (e) {
              return openErrorDialog(e);
            }

            addToast({
              description: (
                <>
                  <b>{recordName}</b> has been <b>{actionConducted}</b>
                </>
              ),
              type: 'error'
            });
          }
        })
    };
  };
}
