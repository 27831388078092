import React from 'react';
import { CellProps } from '@rexlabs/table';
import { TwoTierCell } from 'view/components/cells/two-tier-cell';
import { ListTableProps } from 'view/components/table/list-table';

export function ComplianceRequirementNameCell({
  value
}: CellProps & Omit<ListTableProps<any>, 'columns'>) {
  return <TwoTierCell text={value.label} subtext={value?.category?.label} />;
}
