import React from 'react';

import { emailsBlock } from 'src/modules/contacts/blocks/emails';
import { phoneNumbersBlock } from 'src/modules/contacts/blocks/phone-numbers';

import { ValueListValue } from 'data/models/types';

import { BlockConfig } from 'view/components/record-screen/types';

import { ValidationType } from '../models/messages-model';
import { ChannelMessageRecipient } from '../types/ChannelMessageRecipient';
import { MessageIssueType } from '../../common/types/message-issue-type';
import { ContactMessageIssueDisclosureField } from '../components/contact-message-issue-disclosure-field';

export interface FixChannelMessageRecipientIssuesBlockProps {
  channelMessageRecipient: ChannelMessageRecipient;
}

export const fixChannelMessageRecipientIssuesBlock: BlockConfig<
  FixChannelMessageRecipientIssuesBlockProps,
  { type: ValidationType }
> = {
  id: 'fix-contact-issues',
  validate: {
    definitions: {
      issue: { rules: 'required' }
    },
    messages: {
      required: 'This contact has an issue that needs fixing.'
    }
  },
  Edit: ({ data, setFieldValue, blockProps }) => {
    const contact = data?.channelMessageRecipient.recipient_contact;

    const isPhone = blockProps?.type === 'phone';

    return (
      <ContactMessageIssueDisclosureField
        block={isPhone ? phoneNumbersBlock : emailsBlock}
        fieldKey='issue'
        issue={{
          issue: data!.channelMessageRecipient!
            .failure_status! as ValueListValue<MessageIssueType>,
          message: data!.channelMessageRecipient.channel_message?.message,
          contact: contact!
        }}
        updateText={
          isPhone
            ? "Updating the phone number here will also update the contact's record"
            : "Updating the email here will also update the contact's record"
        }
        setFieldValue={setFieldValue}
      />
    );
  }
};
