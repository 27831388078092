import { DropResult } from 'react-beautiful-dnd';
import { useContext } from 'react';
import invariant from 'invariant';
import {
  ChecklistTemplateItem,
  ChecklistTemplateItemGroup
} from '../../types/ChecklistTemplate';
import { getItemIndex } from '../../utils/get-item-index';
import { fieldArrayContext } from '../../components/smart-checklists/field-array-context';

export function useOnDragEnd() {
  const { fieldArrayProps, setFieldValue, values } = useContext(
    fieldArrayContext
  );
  const {
    checklist_template_item_groups: groups,
    checklist_template_items: items
  } = values;

  return function onDragEnd(result: DropResult) {
    invariant(result.destination, 'Destination must be defined');
    invariant(items, 'Items must be defined');
    invariant(groups, 'Groups must be defined');

    const sourceItem = items.find((item) => item.id === result.draggableId);

    invariant(sourceItem, 'Item must be found in items');

    const sourceIndex = getItemIndex(sourceItem, items);

    // If the destination group is undefined, destination is the outermost droppable
    const destinationGroup = groups.find(
      (groups) => groups.id === result.destination?.droppableId
    );

    // --
    // Get the index of the previous item in the list of all ordered items, whether that is the previous item in the group, or the last item in the last group, or the parent item of the group
    const previousItemGlobalIndex = getPreviousItemGlobalIndex(
      result.destination.index,
      destinationGroup,
      sourceIndex
    );

    // determine what the insertion index should be. Typically, after the previous item, or if we are inserting after a group, then after the last item in that group.
    const destinationIndex = calculateDestinationIndex(
      previousItemGlobalIndex,
      sourceItem,
      destinationGroup
    );
    // --

    // If moving into a new group, set the group on the form state before doing the move, just to make sure things happen in the right order
    const originalGroupId = sourceItem.checklist_template_item_group?.id;
    const newGroupId = destinationGroup?.id;

    if (originalGroupId !== newGroupId) {
      setFieldValue(`checklist_template_items.${sourceIndex}`, {
        ...sourceItem,
        checklist_template_item_group: destinationGroup ?? null
      });
    }

    fieldArrayProps.move(sourceIndex, destinationIndex);
  };

  function calculateDestinationIndex(
    previousItemGlobalIndex: number,
    sourceItem: ChecklistTemplateItem,
    destinationGroup: ChecklistTemplateItemGroup | undefined
  ) {
    invariant(items, 'Items must be defined');
    invariant(groups, 'Groups must be defined');

    let previousItem = items[previousItemGlobalIndex];
    if (previousItem?.id === sourceItem.id) {
      // it's looking at itself, because it is moving after its old position, so increase the index by 1
      previousItem = items[previousItemGlobalIndex + 1];
    }

    const previousItemIsNewParent =
      destinationGroup &&
      previousItem?.id ===
        destinationGroup?.triggered_by_checklist_template_item?.id;

    const descendantsOfPreviousItem =
      previousItemGlobalIndex > -1 && !previousItemIsNewParent
        ? getDescendantsOfItem(previousItem, items, groups)
        : [];

    const isSourceItemDescendantOfPreviousItem =
      descendantsOfPreviousItem.length > 0 && previousItemGlobalIndex > -1
        ? isItemDescendantOfItem(sourceItem, previousItem, items, groups)
        : false;

    const sourceItemWasBeforePreviousItem =
      previousItemGlobalIndex > -1
        ? getItemIndex(sourceItem, items) < previousItemGlobalIndex
        : false;

    return (
      previousItemGlobalIndex +
      1 -
      (sourceItemWasBeforePreviousItem && previousItemIsNewParent ? 1 : 0) +
      // TODO: these next two lines are relevant for the r2r - with items in between test case
      (descendantsOfPreviousItem.length -
        (isSourceItemDescendantOfPreviousItem ? 1 : 0))
    );
  }

  /**
   * This is the index of the "previous item" in the list of all ordered items.
   */
  function getPreviousItemGlobalIndex(
    destinationIndex: number,
    destinationGroup: ChecklistTemplateItemGroup | undefined,
    sourceIndex: number
  ) {
    invariant(items, 'Items must be defined');
    invariant(groups, 'Groups must be defined');

    let previousItemGlobalIndex: number | null = null;

    // if the destination index is not 0 (i.e. we aren't inserting at the top of the group)
    // then we need to find the index of the previous item in the group
    // and then add 1 to that index to get the new index
    if (destinationIndex !== 0) {
      const previousItemIndexInGroup = destinationIndex - 1;
      // if no destination group, it is the top level items, but the index will already be a global index.
      const itemsForGroup = destinationGroup
        ? items.filter(
            (item) =>
              item.checklist_template_item_group?.id === destinationGroup.id
          )
        : items;
      const previousItem = itemsForGroup[previousItemIndexInGroup];

      // previous item in same group?
      previousItemGlobalIndex = getItemIndex(previousItem, items);
    } else {
      // first, check if there are any other groups before this one triggered by the same item
      const precedingGroups = groups.filter(
        (group) =>
          group.triggered_by_checklist_template_item?.id ===
          destinationGroup?.triggered_by_checklist_template_item?.id
      );

      if (precedingGroups.length > 0 && destinationGroup) {
        // if there are preceding groups, then the previous item is the last item in the last preceding group
        // TODO: should this be preceding groups?
        const groupsBeforeDestination = groups.slice(
          0,
          groups.indexOf(destinationGroup)
        );

        const lastPrecedingGroupWithItems = groupsBeforeDestination.find(
          (group) => {
            const itemsForGroup = items.filter(
              (item) => item.checklist_template_item_group?.id === group.id
            );
            return itemsForGroup.length > 0;
          }
        );

        if (lastPrecedingGroupWithItems) {
          const itemsForGroup = items.filter(
            (item) =>
              item.checklist_template_item_group?.id ===
              lastPrecedingGroupWithItems?.id
          );
          const previousItem = itemsForGroup[itemsForGroup.length - 1];
          if (previousItem) {
            previousItemGlobalIndex = getItemIndex(previousItem, items);
          }
        }
      }

      // If we haven't found it other ways, its probably the parent item
      if (previousItemGlobalIndex === null) {
        // if the index is 0, then we are inserting at the start of the group, so the global previous index is parent index
        let destinationGroupTriggerItemIndex: number | null = null;
        if (destinationGroup !== undefined) {
          // destination is a group, so find the item that triggered the group, and then find the index of that item in the list of items
          destinationGroupTriggerItemIndex = items.findIndex(
            (item) =>
              item.id ===
              destinationGroup.triggered_by_checklist_template_item?.id
          );
          if (sourceIndex < destinationGroupTriggerItemIndex) {
            destinationGroupTriggerItemIndex -= 1;
          }
        } else {
          destinationGroupTriggerItemIndex = -1;
        }
        previousItemGlobalIndex = destinationGroupTriggerItemIndex;
      }
    }
    return previousItemGlobalIndex;
  }
}

function getDescendantsOfItem(
  item: ChecklistTemplateItem,
  items: ChecklistTemplateItem[],
  groups: ChecklistTemplateItemGroup[]
) {
  const descendants: ChecklistTemplateItem[] = [];

  const groupsForItem = groups.filter(
    (group) => group.triggered_by_checklist_template_item?.id === item?.id
  );

  for (const group of groupsForItem) {
    const groupItems = items.filter(
      (item) => item.checklist_template_item_group?.id === group.id
    );

    // Do this recursively, to get deep descendants
    groupItems.forEach((groupItem) => {
      descendants.push(
        groupItem,
        ...getDescendantsOfItem(groupItem, items, groups)
      );
    });
  }

  return descendants;
}

function isItemDescendantOfItem(
  item: ChecklistTemplateItem,
  parent: ChecklistTemplateItem,
  items: ChecklistTemplateItem[],
  groups: ChecklistTemplateItemGroup[]
) {
  const descendants = getDescendantsOfItem(parent, items, groups);
  return descendants.some((descendant) => descendant.id === item.id);
}
