export const MAINTENANCE_TASKS_ROUTES = {
  MAINTENANCE_TASKS_LIST: {
    config: {
      path: '/maintenance-tasks',
      Component: require('./screens/maintenance-list.tsx').MaintenanceList
    }
  },
  MAINTENANCE_TASKS_DETAILS: {
    config: {
      path: '/maintenance-task/:maintenanceId',
      Component: require('./screens/maintenance-details.tsx').MaintenanceDetails
    }
  }
};
