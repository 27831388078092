import React, { PropsWithChildren } from 'react';
import { Body } from '@rexlabs/text';
import Box from '@rexlabs/box';
import { useToken, StyleSheet, styled, StylesProps } from '@rexlabs/styling';
import LoadingSpinner from '@rexlabs/loading-spinner';

const defaultStyles = StyleSheet({
  container: {
    width: '100%',
    height: '40rem',
    alignSelf: 'flex-start',
    textAlign: 'center'
  }
});

export const LoadingStateComponent = ({
  children = 'Loading content...',
  styles: s
}: PropsWithChildren<unknown> & StylesProps<typeof defaultStyles>) => {
  const token = useToken();
  return (
    <Box
      p={token('spacing.xl')}
      flexDirection='column'
      alignItems='center'
      justifyContent='center'
      {...s('container')}
    >
      <LoadingSpinner size={30} colors={[token('palette.neutral.200')]} />
      {typeof children === 'string' ? <Body grey>{children}</Body> : children}
    </Box>
  );
};

export const LoadingState = styled(defaultStyles)(LoadingStateComponent);
export default LoadingState;
