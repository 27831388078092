import React from 'react';
import { BlockConfig } from 'view/components/record-screen/types';
import { Grid, Column } from 'view/components/@luna/form/grid';
import { Field } from '@rexlabs/form';
import { CentAmountInput } from 'view/components/inputs/cent-amount';
import { SecurityDepositPaymentTypeRadioGroup } from 'view/components/inputs/radios/security-deposit-payment-type';
import { TextInput } from '@rexlabs/text-input';

export const securityDepositPaymentBlock: BlockConfig = {
  id: 'security-deposit-payment',
  title: 'Security deposit payment',
  validate: {
    definitions: {
      type: {
        rules: 'required'
      },
      amount: {
        rules: 'required'
      }
    }
  },
  Edit: () => (
    <Grid columns={2}>
      <Column width={2}>
        <Field
          name='type.id'
          label='Payment type'
          Input={SecurityDepositPaymentTypeRadioGroup}
          inputProps={{
            orientation: 'horizontal'
          }}
        />
      </Column>

      <Field name='amount' label='Amount requested' Input={CentAmountInput} />

      <Field
        name='description'
        label='Description'
        Input={TextInput}
        description='Leave blank and we’ll create this for you'
      />
    </Grid>
  )
};
