import { Generator, GeneratorConfig } from 'data/models/generator';
import { ServicePackage } from 'src/modules/service-packages/types/service-package';
import { api } from 'utils/api/api-client';
import _ from 'lodash';

const config: GeneratorConfig = {
  entities: {
    related: {
      agency_fees: {
        include: 'agency_fees'
      }
    }
  }
};

const actionCreators = {
  duplicateItem: {
    request: ({ servicePackage }: { servicePackage: ServicePackage }) =>
      api.post(`/service-packages/${servicePackage.id}/duplicate`),
    reduce: _.identity
  }
};

export const servicePackagesModel = new Generator<
  ServicePackage,
  typeof actionCreators
>('service-packages', config).createEntityModel({ actionCreators });
