import { useSessionState } from 'src/modules/common/hooks/session-state';
import { Contact } from 'src/modules/contacts/types/contact-types';

import { Ownership } from 'data/models/entities/ownerships';

import { SummaryRow } from '../../../view/components/summary-table';
import { FolioDisbursement } from '../types/FolioDisbursement';
import { getTaxWithheldSummaryItem } from '../utils/get-tax-withheld-summary-item';

export function useClosingFiguresSummary(
  data?: FolioDisbursement,
  object?: Contact | Ownership
): SummaryRow[] {
  const session = useSessionState();

  if (data === undefined || object === undefined) return [];

  const showTaxWithheld =
    session.activeSilo?.country.id === 'GBR' &&
    object?.__record_type === 'ownership';

  return [
    {
      description: 'Total cleared balance',
      amount: data.trust_balance_amount || 0,
      id: 'total_cleared_balance'
    },
    ...(showTaxWithheld
      ? [
          getTaxWithheldSummaryItem(
            'HMRC tax withheld to date',
            data.total_tax_currently_being_withheld || 0,
            data.tax_currently_being_withheld_by_owner,
            object
          )
        ]
      : []),
    {
      description: 'Funds reserved for specific payments',
      amount: data.funds_reserved_for_specific_payments || 0,
      id: 'funds_reserved_for_specific_payments'
    },
    {
      description: 'Available folio funds balance',
      amount: data.available_trust_balance_amount || 0,
      id: 'available_folio_funds_balance'
    }
  ];
}
