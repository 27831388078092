import React from 'react';

import { ListScreen } from 'view/components/list-screen/list-screen';
import { Card } from 'view/components/card';

import ArrowUpCircle from 'view/components/icons/arrow-up-circle';
import RefreshIcon from 'view/components/icons/refresh';
import { ButtonGroupInput } from 'view/components/@luna/button-group-input';
import ColumnsIcon from 'view/components/icons/columns';
import BulletedListIcon from 'view/components/icons/bulleted-list';
import { useFeatureFlags } from 'view/components/@luna/feature-flags';
import { FLAGS } from 'utils/feature-flags';
import BankingStatementIcon from 'view/components/icons/banking-statement';
import { useBatchReceiptingStatBlocksConfig } from '../hooks/use-batch-receipting-stat-blocks-config';
import { useGetUploadBankTransactionsAction } from '../hooks/use-get-upload-bank-transactions-action';
import {
  BankAccountProvider,
  useBankAccountState
} from '../hooks/use-bank-account-state';
import { useGetBulkReceiptToRentAction } from '../hooks/actions/use-get-bulk-receipt-to-rent-action';
import { useRematchTransactionsAction } from '../hooks/actions/use-rematch-transactions-action';
import { LabelWithIcon } from '../hooks/use-batch-receipting-button-config';
import { BatchReceiptingListTableView } from '../components/batch-receipting-list-table-view';
import { BatchReceiptingListCardView } from '../components/batch-receipting-list-card-view';
import { usePersistView, View } from '../hooks/use-persist-view';
import { useViewStatementsAction } from '../hooks/actions/use-view-statements-action';

export function BatchReceiptingListScreenInner() {
  const { bankAccount, actions: bankAccountAction } = useBankAccountState();

  const bankAccountId = bankAccount.id;

  const { stats, matchedReceiptCount } = useBatchReceiptingStatBlocksConfig(
    bankAccountId
  );

  const uploadBankTransactionsAction = useGetUploadBankTransactionsAction();
  const getBulkReceiptToRentAction = useGetBulkReceiptToRentAction();
  const rematchTransactionsAction = useRematchTransactionsAction();
  const viewStatementsAction = useViewStatementsAction({
    bankAccountId
  });

  const [viewType, setViewType] = usePersistView();

  const { hasFeature } = useFeatureFlags();

  const hasBatchReceiptingTableToggleFeature = hasFeature(
    FLAGS.BATCH_RECEIPTING_TABLE_TOGGLE
  );

  const hasBatchReceiptingStatementsFeature = hasFeature(
    FLAGS.BATCH_RECEIPTING_STATEMENTS
  );

  return (
    <ListScreen
      title='Batch receipting'
      actions={[
        {
          label: 'Actions',
          actions: [
            {
              ...uploadBankTransactionsAction,
              label: LabelWithIcon(
                uploadBankTransactionsAction.label!,
                <ArrowUpCircle />
              )
            },
            {
              ...rematchTransactionsAction,
              label: LabelWithIcon(
                rematchTransactionsAction.label!,
                <RefreshIcon />
              )
            },
            ...(hasBatchReceiptingStatementsFeature
              ? [
                  {
                    ...viewStatementsAction,
                    label: LabelWithIcon(
                      viewStatementsAction.label!,
                      <BankingStatementIcon />
                    )
                  }
                ]
              : [])
          ]
        },
        ...(Array.isArray(bankAccountAction) ? bankAccountAction : [])
      ]}
      stats={stats}
    >
      <Card
        title='Transactions'
        actions={[
          ...(hasBatchReceiptingTableToggleFeature
            ? [
                {
                  id: 'view_toggle',
                  ActionTrigger: () => (
                    <ButtonGroupInput
                      value={viewType}
                      onChange={(item) => {
                        setViewType(
                          item?.target?.value === View.Card
                            ? View.Card
                            : View.Table
                        );
                      }}
                      items={[
                        {
                          id: View.Card,
                          value: View.Card,
                          Icon: ColumnsIcon
                        },
                        {
                          id: View.Table,
                          value: View.Table,
                          Icon: BulletedListIcon
                        }
                      ]}
                    ></ButtonGroupInput>
                  )
                }
              ]
            : []),
          ...(matchedReceiptCount > 0
            ? [getBulkReceiptToRentAction(matchedReceiptCount)]
            : [])
        ]}
      >
        {hasBatchReceiptingTableToggleFeature && viewType === View.Table ? (
          <BatchReceiptingListTableView />
        ) : (
          <BatchReceiptingListCardView />
        )}
      </Card>
    </ListScreen>
  );
}

export function BatchReceiptingListScreen() {
  return (
    <BankAccountProvider>
      <BatchReceiptingListScreenInner />
    </BankAccountProvider>
  );
}
