import 'src/modules/financials/utils/validate-needs-reason';
import React, {
  forwardRef,
  Fragment,
  ReactNode,
  useCallback,
  useEffect,
  useMemo
} from 'react';
import { get } from 'lodash';

import { FieldArray } from '@rexlabs/form';
import Box from '@rexlabs/box';
import { OutlineButton } from '@rexlabs/button';
import ActionMenu from '@rexlabs/action-menu';
import { useModelActions } from '@rexlabs/model-generator';

import { Invoice } from 'data/models/entities/financials/invoices';
import { Tenancy } from 'data/models/entities/tenancies';

import { RecordData } from 'utils/types';
import { FLAGS } from 'utils/feature-flags';
import { SearchResultItem } from 'utils/api/get-search-results';

import { transformToReceiptingFundsLineItemEntries } from 'src/modules/trust-journal-entries/utils/transform-to-receipting-funds-line-item-entries';
import { propertyTenancyModel } from 'src/modules/property-tenancies/models/property-tenancy-model';

import { BlockConfig } from 'view/components/record-screen/types';
import { AddIcon } from 'view/components/icons/add';
import LoadingState from 'view/components/states/compact/loading';
import { useFeatureFlags } from 'view/components/@luna/feature-flags';

import { TrustJournalLineItemEmptyState } from 'src/modules/financials/components/trust-journal-line-item-empty-state';
import { useGetAddDepositAction } from 'src/modules/financials/hooks/use-get-add-deposit-action';
import { getAvailableAmount } from 'src/modules/financials/utils/get-available-amount';
import { useGetAddInvoiceAction } from 'src/modules/financials/hooks/use-get-add-invoice-action';
import { useGetAddRentLineItemAction } from 'src/modules/financials/hooks/use-get-add-rent-line-item-action';
import { BankAccount } from 'data/models/entities/financials/bank-accounts';
import { TenancyPrepaymentBucket } from 'src/modules/prepayments/types/tenancy-prepayment-bucket';
import { usePayablesByFolio } from '../utils/use-payables-by-folio';
import { useAllocateFunds } from '../hooks/use-allocate-funds';
import { getFolio } from '../utils/get-folio';
import { DepositLineItem } from '../components/deposit-line-item';
import { BillLineItem } from '../components/bill-line-item';
import { useGetSuggestedInvoices } from '../hooks/use-get-suggested-invoices';
import { usePropertyTenanciesFromTenancySearchResultItem } from '../hooks/use-property-tenancies-from-tenancy-search-results';
import {
  RentLineItem as RentLineItemType,
  useRentLineItems
} from '../hooks/use-rent-line-items';
import { RentLineItem } from '../components/rent-line-item';
import { useUnusedRentLineItems } from '../hooks/use-unused-rent-line-items';
import { getBucket } from '../utils/get-bucket';
import { getBankAccount } from '../utils/get-bank-account';
import { MaxNumberOfInvoicesWarningBanner } from '../components/max-number-of-invoices-warning-banner';
import { ReceiptFundsDetailsBlockProps } from './receipt-funds-details';
import { ReceiptFundsPaymentDetailsBlockProps } from './receipt-funds-payment-details';

export type DepositLineItem = {
  category: 'deposit';
  description?: string;
  deposit?: {
    ledger: RecordData;
  };
  deposit_into?: TenancyPrepaymentBucket;
  amount?: number;
};

export type InvoiceLineItem = {
  category: 'invoice';
  invoice_payment?: {
    invoice?: Invoice;
  };
  cross_bucket_payment_reason?: {
    needs_reason?: boolean;
    reason?: string;
  };
  amount?: number;
};

export type ReceiptingFundsLineItemEntry =
  | DepositLineItem
  | InvoiceLineItem
  | RentLineItemType;

export interface BaseLineItemComponentProps {
  fieldName: string;
  onRemove: () => void;
  values: Record<string, any>;
}

export interface BaseLineItemWithBankAccountProps
  extends BaseLineItemComponentProps {
  bankAccount: BankAccount | null;
}

export interface BaseLineItemWithAllocateProps
  extends BaseLineItemComponentProps,
    Pick<
      ReturnType<typeof useAllocateFunds>,
      | 'unAllocateFromLineItem'
      | 'allocateToLineItem'
      | 'showUnallocateFunds'
      | 'showAllocateFunds'
    > {}

const MAX_NUMBER_OF_INVOICES = 30;

const validate = {
  definitions: {
    'line_items.*.amount': {
      name: 'amount',
      rules: 'min:0|greaterThanZero_if:line_items.*.category,deposit'
    },
    'line_items.*.description': {
      name: 'description',
      rules: 'required_if:line_items.*.category,deposit'
    },
    'line_items.*.cross_bucket_payment_reason': {
      name: 'reason',
      rules: 'requiredIfNeedsReason'
    }
  },
  messages: {
    required_if: 'The :attribute field is required.'
  }
};

export type TrustJournalEntryLineItemsBlockProps = {
  // This only exists when we are transferring funds
  invoice?: Invoice;
  line_items: ReceiptingFundsLineItemEntry[];
};

type OtherForms = {
  'receipt-funds-details': { values: ReceiptFundsDetailsBlockProps };
  'receipt-funds-payment-details': {
    values: ReceiptFundsPaymentDetailsBlockProps;
  };
  'trust-journal-entry-line-items': {
    values: TrustJournalEntryLineItemsBlockProps;
  };
  'transfer-funds-details': any;
};

export const createTrustJournalEntryLineItemBlock: BlockConfig<
  any,
  any,
  TrustJournalEntryLineItemsBlockProps,
  OtherForms
> = {
  id: 'trust-journal-entry-line-items',
  title: 'Transfer to',
  validate,
  Edit: ({ setFieldValue, forms, values }) => {
    const { hasFeature } = useFeatureFlags();
    const { fetchRentPosition } = useModelActions(propertyTenancyModel);

    const prepaymentBucket = getBucket(forms);
    const bankAccount = getBankAccount(forms);
    const folio = getFolio(forms);
    const hasTransferDetails = !!forms?.['transfer-funds-details'];
    const amount = getAvailableAmount(forms) ?? 0;

    const { isPayablesLoading, payables } = usePayablesByFolio({
      folio,
      maxNumberOfPayables: MAX_NUMBER_OF_INVOICES,
      prepaymentBucket
    });

    const tenancyFolio =
      folio?.record.__record_type === 'tenancy'
        ? (folio as SearchResultItem<Tenancy>)
        : null;

    const {
      propertyTenancies
    } = usePropertyTenanciesFromTenancySearchResultItem(tenancyFolio);

    const canShowRentLineItem = useCallback(
      (lineItem) => {
        if (!hasFeature(FLAGS.PREPAYMENTS) || !prepaymentBucket) return true;

        // Show line items if the bucket is general funds
        if (prepaymentBucket.system_purpose?.id === 'general_funds')
          return true;

        // If rent bucket, check if property matches with the line item
        return (
          prepaymentBucket.system_purpose?.id === 'rent_prepayment' &&
          prepaymentBucket.property?.id === lineItem.rent?.property?.id
        );
      },
      [prepaymentBucket]
    );

    const { rentLineItems } = useRentLineItems({
      propertyTenancies,
      filterLineItems: canShowRentLineItem
    });

    const {
      unallocatedAmount,
      autoAllocateFunds,
      allocateToLineItem,
      unAllocateFromLineItem,
      showAllocateFunds,
      showUnallocateFunds
    } = useAllocateFunds({
      amount,
      lineItems: values?.line_items ?? [],
      setFieldValue,
      invoice: values?.invoice
    });

    // NOTE: This is used in the invoice select - it shows the user invoices that have been removed
    // from the line items
    const { getSuggestedInvoices } = useGetSuggestedInvoices({
      lineItems: values?.line_items ?? [],
      payables
    });

    // NOTE: This is used in the add line item button - it will show the user any rent line items
    // that have been removed from the line items
    const { unusedRentLineItems } = useUnusedRentLineItems({
      lineItems: values?.line_items ?? [],
      rentLineItems
    });

    // NOTE: This is to add some stability to the line items before we use them in the
    // useEffect below. This was added because the dialog tests were failing because of
    // the number of renders

    const lineItemsCombined = useMemo(
      () =>
        [
          ...transformToReceiptingFundsLineItemEntries(payables || []),
          ...(hasFeature(FLAGS.RENT_LINE_ITEM) && rentLineItems?.length
            ? rentLineItems
            : [])
        ].sort((a, b) => b.category.localeCompare(a.category)),
      [rentLineItems, payables]
    );

    // Creating a key to trigger the useEffect when line items change. Previously we used length but this had some issues.
    const lineItemKey = lineItemsCombined
      .map((item: ReceiptingFundsLineItemEntry) => {
        switch (item.category) {
          case 'invoice':
            return `invoice,${item.invoice_payment?.invoice?.id}`;
          case 'rent':
            return `rent,${item.rent?.property_tenancy?.id}`;
          // deposits for completeness although note that the lineItemsCombined doesn't contain any deposits
          case 'deposit':
            return `deposit,${item.deposit?.ledger?.id}`;
        }
      })
      .join(',');

    useEffect(() => {
      waitBeforeActing(
        () => setFieldValue?.('line_items', lineItemsCombined),
        100
      );
    }, [lineItemKey]);

    useEffect(() => {
      if (
        isFinite(amount) &&
        values?.line_items &&
        values.line_items.length > 0 &&
        lineItemsCombined &&
        lineItemsCombined.length > 0
      ) {
        waitBeforeActing(() => autoAllocateFunds(), 200);
      }
    }, [amount, values?.line_items?.length, lineItemKey]);

    const onInvoiceSelect = useCallback(
      (selectState, fieldName) => {
        const selectedInvoice = (selectState.target
          .value as unknown) as Invoice | null;
        if (selectedInvoice === null) {
          setFieldValue?.(`${fieldName}.amount`, 0);
          return;
        }

        if (unallocatedAmount && selectedInvoice.amount_owing > 0) {
          const allocateableAmount = Math.min(
            unallocatedAmount,
            selectedInvoice.amount_owing
          );
          setFieldValue?.(`${fieldName}.amount`, allocateableAmount);
        }
      },
      [setFieldValue, unallocatedAmount]
    );

    const getAddDepositAction = useGetAddDepositAction({
      folio,
      amount: hasTransferDetails ? 0 : unallocatedAmount
    });
    const getAddInvoiceAction = useGetAddInvoiceAction();

    const getAddRentLineItemAction = useGetAddRentLineItemAction({
      unusedRentLineItems
    });

    // Temporary fix:
    // moving loading and empty state inside the FieldArray to prevent mounting and unmounting the FieldArray component
    return (
      <FieldArray name='line_items'>
        {function LineItem({ fields, push }) {
          if (!folio) {
            return <TrustJournalLineItemEmptyState />;
          }

          if (isPayablesLoading) {
            return (
              <LoadingState>Fetching outstanding invoices...</LoadingState>
            );
          }

          return (
            <>
              {/*
              NOTE: This has been added as we had a user who had a large number of invoices for a ownership. When they were 
              attempting to transfer the invoices before a disbursement, we were fetching over 200 payables, which made the UI unstable.
              Because the endpoint is custom, it needs some extra work to paginate the results - but we can limit the number of items returned.
              This banner is making an assumption that we can't quite guarantee - that if there is 30 invoices, there is likely more.
              Because we can't paginate the results, this is the best we can do for now.
              */}
              {payables && payables.length === MAX_NUMBER_OF_INVOICES && (
                <MaxNumberOfInvoicesWarningBanner
                  maxNumberOfInvoices={MAX_NUMBER_OF_INVOICES}
                />
              )}

              {fields.map(({ field, actions }) => (
                <Fragment key={field.name}>
                  {hasFeature(FLAGS.RENT_LINE_ITEM) &&
                    get(values, `${field.name}.category`) === 'rent' && (
                      <RentLineItem
                        values={values!}
                        fieldName={field.name}
                        onRemove={actions.remove}
                        setFieldValue={setFieldValue}
                        allocateToLineItem={allocateToLineItem}
                        showAllocateFunds={showAllocateFunds}
                        showUnallocateFunds={showUnallocateFunds}
                        unAllocateFromLineItem={unAllocateFromLineItem}
                        fetchRentPosition={fetchRentPosition}
                      />
                    )}

                  {get(values, `${field.name}.category`) === 'deposit' && (
                    <DepositLineItem
                      fieldName={field.name}
                      onRemove={actions.remove}
                      values={values!}
                      bankAccount={bankAccount}
                    />
                  )}

                  {get(values, `${field.name}.category`) === 'invoice' && (
                    <BillLineItem
                      values={values!}
                      getSuggestedInvoices={getSuggestedInvoices}
                      fieldName={field.name}
                      onRemove={actions.remove}
                      onInvoiceSelect={onInvoiceSelect}
                      allocateToLineItem={allocateToLineItem}
                      showAllocateFunds={showAllocateFunds}
                      showUnallocateFunds={showUnallocateFunds}
                      unAllocateFromLineItem={unAllocateFromLineItem}
                      prepaymentBucket={prepaymentBucket}
                      setFieldValue={setFieldValue}
                      originalPayables={payables}
                    />
                  )}
                </Fragment>
              ))}

              <Box mt='1rem'>
                <ActionMenu
                  Button={forwardRef<
                    HTMLButtonElement,
                    { children: ReactNode }
                  >((props, ref) => (
                    <OutlineButton {...props} ref={ref} IconLeft={AddIcon}>
                      Add line item
                    </OutlineButton>
                  ))}
                  items={[
                    getAddDepositAction({ push }),
                    getAddInvoiceAction({ push }),
                    ...(hasFeature(FLAGS.RENT_LINE_ITEM)
                      ? getAddRentLineItemAction({ push })
                      : [])
                  ]}
                />
              </Box>
            </>
          );
        }}
      </FieldArray>
    );
  }
};

/**
 * HACK: This component is in need of a number of improvements - this hack below is specifically to ensure that the line items are stable.
 * Before adding this hack, when performing a transfer if the user changes the folio, two useEffect calls are triggered and the line items are clobbered.
 *
 *  - we set the line items to the new folios line items
 *  - while that is happening we attempt to auto allocate the funds
 *
 * Note: this only happens when using the transfer funds dialog, as the folio select in the receipt funds dialog is a different component, and actually forces
 * you to clear the folio first. Rather than forcing the transfer to use the same component, I've added this hack because that folio select will be replaced at some point
 * anyways as it is V3. I've attempted various ways to make this more stable, but unfortunately the easiest way to do this is to add a setTimeout. I've wrapped the
 * set timeout, so it was easier to write this comment.
 *
 * Going forward, we should look at the following:
 *
 *  - we should look at removing the useEffect calls to auto allocate the funds - there is so much rendering happening the performance of this component will be taking a hit
 *  - we should use getHandlers and set the values as needed, not using hooks
 *
 * I have not done the above, because a) I've already spent a lot of time working on this and b) its quite a big change to a high traffic area of the app.
 * https://rexsoftware.atlassian.net/browse/ALFIE-4191
 */
function waitBeforeActing(action: () => any, time: number) {
  return setTimeout(() => {
    action();
  }, time);
}
