import { Box } from '@rexlabs/box';
import React from 'react';
import { filterNormalisedList } from 'utils/form/filter-normalised-list';
import { NormalisedItem } from '@rexlabs/select';
import { ContactSelect } from 'view/components/inputs/selects/contact';
import { TenantRoleSelect } from 'view/components/inputs/selects/tenant-role';
import { TextInput } from '@rexlabs/text-input';

import { Field } from '@rexlabs/form';
import { DestructiveButton, OutlineButton } from '@rexlabs/button';
import AddIcon from 'view/components/icons/add';
import { Contact } from 'src/modules/contacts/types/contact-types';
import { TenantFieldArrayContentProps } from 'src/modules/tenancies/components/tenant-field-array-content';
import RemoveIcon from 'view/components/icons/remove';
import { Grid } from 'view/components/@luna/form/grid';

// TODO: remove when the guarantor and occupier updates feature flag is turned on in prod

export function TenantFieldArrayContentOld({
  fields,
  push,
  values
}: TenantFieldArrayContentProps) {
  React.useEffect(() => {
    if (fields.length === 0) {
      push({ role: { id: 'tenant', label: 'Tenant' } });
    }
  }, [fields.length, push]);

  const selectedTenants: Contact[] =
    values?.tenants?.map((tenantRow) => tenantRow.contact).filter(Boolean) ||
    [];

  return (
    <Box sy='2.4rem'>
      {fields.map(({ field, actions }) => {
        return (
          <Box
            key={field.name}
            flexDirection='row'
            alignItems='flex-start'
            width='100%'
            sx='2.4rem'
            data-testid='tenant'
          >
            <Grid columns={3} style={{ flex: 1 }}>
              <Field
                id={`${field.name}.contact`}
                name={`${field.name}.contact`}
                label='Tenant'
                Input={ContactSelect}
                inputProps={{
                  filter: (normalisedItem: NormalisedItem) => {
                    return filterNormalisedList(
                      normalisedItem,
                      selectedTenants
                    );
                  }
                }}
              />
              <Field
                id={`${field.name}.role`}
                name={`${field.name}.role`}
                label='Role'
                Input={TenantRoleSelect}
              />
              <Field
                id={`${field.name}.payment_reference`}
                name={`${field.name}.payment_reference`}
                label='Tenant reference'
                Input={TextInput}
              />
            </Grid>
            <Box mt='2rem'>
              <DestructiveButton
                onClick={() => actions.remove()}
                isDisabled={fields.length === 1}
              >
                <RemoveIcon />
              </DestructiveButton>
            </Box>
          </Box>
        );
      })}
      <OutlineButton
        onClick={() =>
          push({
            is_primary: false,
            role: { id: 'tenant', label: 'Tenant' }
          })
        }
        IconLeft={AddIcon}
      >
        Add another tenant
      </OutlineButton>
    </Box>
  );
}
