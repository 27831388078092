import React from 'react';

import { Body } from '@rexlabs/text';

import { BlockConfig } from 'view/components/record-screen/types';
import { ManageNameAndSortingFieldArray } from 'view/components/manage-name-and-sorting-field-array';

import { InspectionTask } from '../types/InspectionTask';

export type InspectionReportManageRoomObj = {
  id?: string;
  label: string;
};

export type InspectionReportManageRoomsFormProps = {
  inspection_report_rooms: InspectionReportManageRoomObj[];
};

export const manageAreasBlock: BlockConfig<
  InspectionTask,
  any,
  InspectionReportManageRoomsFormProps
> = {
  id: 'manage-areas',
  title: 'Manage areas',
  Edit: ({ blockProps }) => {
    return (
      <>
        {blockProps?.banner && <Body grey>{blockProps.banner}</Body>}

        <ManageNameAndSortingFieldArray
          fieldArrayName='inspection_report_rooms'
          textInputPlaceholder='Enter room name'
        />
      </>
    );
  }
};
