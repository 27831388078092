import React from 'react';

import { padding, StyleSheet, useStyles } from '@rexlabs/styling';
import { NumberInputProps } from '@rexlabs/text-input';
import { CreditNoteAmountTypeSelect } from '../selects/credit-note-amount-type';
import { PercentageInput } from '../percentage-input';
import { CentAmountInput } from '../cent-amount';

export type AmountValue = {
  type?: {
    id: string;
    label: string;
  } | null;
  value?: number | null;
};

interface AmountInputProps
  extends Omit<NumberInputProps, 'value' | 'onChange' | 'onBlur'> {
  value?: AmountValue;
  onChange?: (e: { target: { value: AmountValue } }) => void;
  onBlur?: (e: { target: { value: AmountValue } }) => void;
  testId?: string;
}

const defaultAmountValue: AmountValue = {
  type: null,
  value: null
};

const defaultStyles = StyleSheet({
  divider: {
    ...padding.styles({
      x: 'xs'
    })
  },
  splitGrid: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridGap: '12px'
  }
});

export function CreditAmountSplitInput({
  value,
  onChange,
  onBlur,
  ...props
}: AmountInputProps) {
  const s = useStyles(defaultStyles);

  const isFixedAmount = value?.type?.id === 'fixed_amount';

  const handleChange = (newValue: Partial<AmountValue>) => {
    onChange?.({
      target: {
        value: {
          ...(value || defaultAmountValue),
          ...newValue
        }
      }
    });
  };

  const handleBlur = (newValue: Partial<AmountValue>) => {
    onBlur?.({
      target: {
        value: {
          ...(value || defaultAmountValue),
          ...newValue
        }
      }
    });
  };

  const handleTypeChange = ({ target }) =>
    handleChange({
      type: target.value
    });

  const handleTypeBlur = ({ target }) =>
    handleBlur({
      type: target.value
    });

  const handleValueChange = ({ target }) =>
    handleChange({
      value: target.value
    });

  const handleValueBlur = ({ target }) =>
    handleBlur({
      value: target.value
    });

  return (
    <div {...s('splitGrid')}>
      <CreditNoteAmountTypeSelect
        {...props}
        value={value?.type}
        onChange={handleTypeChange}
        onBlur={handleTypeBlur}
      />
      {isFixedAmount ? (
        <CentAmountInput
          value={value?.value}
          onChange={handleValueChange}
          onBlur={handleValueBlur}
        />
      ) : (
        <PercentageInput
          {...props}
          value={value?.value}
          onChange={handleValueChange}
          onBlur={handleValueBlur}
          data-testid='percentage-input'
        />
      )}
    </div>
  );
}
