import React from 'react';
import { Box } from '@rexlabs/box';
import { useDialog } from '@rexlabs/dialog';
import { StyleSheet, useStyles, useToken } from '@rexlabs/styling';
import { LinkButton } from '@rexlabs/button';
import { Link, useWhereabouts, push } from '@rexlabs/whereabouts';
import AddIcon from 'view/components/icons/add';
import { ProgressBarField } from 'view/components/progress-bar/progress-bar-field';
import LoadingState from 'view/components/states/compact/loading';
import { useChecklistsWidgetQuery } from 'src/modules/tasks/common/hooks/data/use-checklists-query';
import { getChecklistViewItems } from 'src/modules/tasks/common/utils/get-checklist-view-items';
import { getCompletedCount } from 'src/modules/tasks/common/utils/get-completed-count';
import { RightBarEmptyState } from 'view/components/record-screen/right-bar-empty-state';
import { ChecklistRightBarCard } from 'src/modules/tasks/common/components/checklist-right-bar-card';
import { Breakpoints } from '@rexlabs/breakpoints';
import { EntryList } from 'view/components/related-records/components/entry-list';
import { ChecklistWithIncludes } from '../types/Checklist';
import { TaskType } from '../types/TaskType';
import { Task } from '../types/Task';
import { AddChecklistDialog } from '../../todos/dialogs/add-checklist-dialog';

const defaultStyles = StyleSheet({
  link: {
    textDecoration: 'none',
    marginLeft: '0px !important', // need to override the margin from the EntryList component
    color: 'inherit',
    ':hover': {
      color: 'inherit'
    }
  }
});

function ChecklistProgress({
  checklist
}: {
  checklist: ChecklistWithIncludes;
}) {
  const { path } = useWhereabouts();
  const s = useStyles(defaultStyles);

  if (!checklist) {
    return null;
  }

  const completedCount = getCompletedCount(
    checklist?.items?.data || [],
    checklist?.item_order || [],
    checklist
  );
  const totalCount = getChecklistViewItems(
    checklist?.items?.data || [],
    checklist?.item_order || [],
    checklist
  ).length;

  return (
    <Link
      {...s('link')}
      to={path}
      query={{ tab: 'checklist' }}
      hash={checklist.id}
    >
      <ProgressBarField
        label={checklist?.label || ''}
        max={totalCount}
        value={completedCount}
      />
    </Link>
  );
}

export function ChecklistProgressWidget({ task }: { task: Task<TaskType> }) {
  const { data, isLoading } = useChecklistsWidgetQuery(task.id);
  const token = useToken();
  const checklists = data?.data || [];

  if (isLoading) {
    return (
      <ChecklistRightBarCard includePadding headline='Checklist'>
        <LoadingState />
      </ChecklistRightBarCard>
    );
  }

  if (!checklists.length) {
    return (
      <ChecklistRightBarCard includePadding headline='Checklist'>
        <RightBarEmptyState
          message='There are no checklists added for this task'
          actionSlot={
            <>
              <Breakpoints queries={{ maxWidth: 'm' }}>
                <ChecklistEmptyLinkButton task={task} />
              </Breakpoints>

              <Breakpoints queries={{ minWidth: 'm' }}>
                <ChecklistEmptyCreateButton task={task} />
              </Breakpoints>
            </>
          }
        />
      </ChecklistRightBarCard>
    );
  }

  return (
    <ChecklistRightBarCard includePadding headline='Checklist'>
      <Box flexDirection={'column'} rowGap={token('spacing.m')}>
        <EntryList>
          {checklists.map((checklist) => (
            <ChecklistProgress
              checklist={checklist}
              key={`checklist-progress-${checklist?.id}`}
            />
          ))}
        </EntryList>
      </Box>
    </ChecklistRightBarCard>
  );
}

function ChecklistEmptyLinkButton({ task }) {
  const props = {
    to: `/task-settings?tab=${getTabFromTaskType(task.type.id)}`
  };

  return (
    <LinkButton IconLeft={AddIcon} as={Link} {...props}>
      Add checklist
    </LinkButton>
  );
}

function ChecklistEmptyCreateButton({ task }) {
  const { open: openAddChecklistDialog } = useDialog(AddChecklistDialog);
  const whereabouts = useWhereabouts();

  const handleClick = () => {
    openAddChecklistDialog({
      task,
      onCreate: () => {
        push({
          config: {
            ...whereabouts,
            query: {
              tab: 'checklist'
            }
          }
        });
      }
    });
  };

  return (
    <LinkButton IconLeft={AddIcon} onClick={handleClick}>
      Add checklist
    </LinkButton>
  );
}

const taskTypeMap: Record<TaskType, string> = {
  todo: 'to-do',
  task_maintenance: 'maintenance',
  task_inspection: 'inspections',
  task_lease_review: 'lease-reviews',
  task_property_compliance: 'property-compliance',
  task_supplier_compliance: 'supplier-compliance',
  task_move_in: 'move-in',
  task_move_out: 'move-out',
  task_quote: 'quotes',
  task_work_order: 'work-orders',
  task_arrears: 'rent-arrears'
};

function getTabFromTaskType(taskType: string) {
  return taskTypeMap[taskType] ?? '';
}
