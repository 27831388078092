import React from 'react';

import { Forms } from '@rexlabs/form';
import Box from '@rexlabs/box';
import { useToken } from '@rexlabs/styling';

import { Banner } from 'src/modules/common/components/banner';

import { pluralize } from 'utils/formatters';

import InfoCircleIcon from 'view/components/icons/info';
import { FakeTabs } from 'view/components/fake-tabs';

import { FormContent, FormContentProps } from './content-dialog';
import { DialogContentConfig } from './types';

export function TabbedFormContent({
  data,
  content,
  initialValues,
  forms,
  resetAll,
  isSubmitting,
  submitLabel,
  onClose,
  SubmitButton,
  handleSubmit,
  ...props
}: Omit<FormContentProps, 'step'> & {
  forms: Forms;
  content: DialogContentConfig;
}) {
  // find which forms have errors
  const hasError = Object.values(forms).filter(
    (form) => form.isValid === false
  );

  // find the tabs that house those forms
  const tabsWithErrors = hasError.reduce((acc, form) => {
    const step = content.find((step) =>
      step.blocks.find((block) => block.id === form.id)
    );
    if (step && !acc.find((item) => item.id === step.id)) {
      acc.push({ label: step.label, id: step.id });
    }
    return acc;
  }, [] as { id: string; label: string }[]);

  return (
    <>
      {!!hasError.length && (
        <Banner intent='danger' align='start' Icon={InfoCircleIcon}>
          <div>
            {/* List the tabs that have the errors */}
            {`There are errors in the ${formatter.format(
              tabsWithErrors.map((tab) => tab.label)
            )} ${pluralize('tab', tabsWithErrors.length)}`}
          </div>
        </Banner>
      )}
      <FakeTabs
        items={content.map((step) => ({
          name: step.id,
          label: step.label,
          IconStart: tabsWithErrors.find((tab) => tab.id === step.id)
            ? ErrorIcon
            : undefined,
          IconEnd: step?.IconEnd,
          Tab: (
            <Box height='100%' width='100%' paddingTop={20} flex={1}>
              <FormContent
                {...props}
                submitLabel={submitLabel}
                onClose={onClose}
                SubmitButton={SubmitButton}
                step={step}
                data={data}
                initialValues={initialValues}
                forms={forms}
                resetAll={resetAll}
                isSubmitting={isSubmitting}
                handleSubmit={handleSubmit}
              />
            </Box>
          )
        }))}
      />
    </>
  );
}

// Note: we need to upgrade our typescript to 5.4 to have the correct types for this :/
// @ts-ignore
const formatter = new Intl.ListFormat('en', {
  style: 'long',
  type: 'conjunction'
});

function ErrorIcon() {
  const token = useToken();

  return <InfoCircleIcon color={token('palette.red.900')} />;
}
