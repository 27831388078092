import { api } from 'utils/api/api-client';
import { Message } from '../types/Message';

export async function fetchMessageWithAttachments({
  messageId
}: {
  messageId: string;
}): Promise<Message> {
  const { data } = await api.get(`/communication/messages/${messageId}`, {
    include: 'inline_attachments'
  });

  return data;
}
