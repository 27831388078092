import React from 'react';

import ROUTES from 'routes/app';
import { BREADCRUMBS } from 'view/components/@luna/breadcrumbs';
import { Query } from '@rexlabs/model-generator';
import { RecordTable, Columns } from 'view/components/table';
import { trustLedgerModel } from '../models/trust-ledger-model';

const columns: Columns = [
  {
    id: 'name',
    Header: 'Name'
  },
  {
    id: 'object',
    type: 'record',
    Header: 'Attached to'
  }
];

const getRowLinkProps = ({ item }) => ({
  to: ROUTES.TRUST_LEDGER,
  params: {
    trustLedgerId: item.id
  },
  addBreadcrumb: {
    type: BREADCRUMBS.TRUST_LEDGER,
    id: item.id,
    relation: 'Trust Ledger'
  }
});

interface TrustLedgerListProps {
  query: Query<typeof trustLedgerModel>;
}

export function TrustLedgerList({ query }: TrustLedgerListProps) {
  return (
    <RecordTable
      id='trust-ledgers'
      columns={columns}
      getQuery={() => query}
      getRowLinkProps={getRowLinkProps}
    />
  );
}
