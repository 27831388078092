import React, { forwardRef, PropsWithChildren, CSSProperties } from 'react';

import Box, { BoxProps } from '@rexlabs/box';
import { useStyles, StyleSheet, padding, mq } from '@rexlabs/styling';

const defaultStyles = StyleSheet({
  container: {
    ...mq.styles.combine([
      {
        queries: {
          maxWidth: 's'
        },
        styles: {
          ...padding.styles({
            target: 'card.content',
            all: 'm'
          }),

          gap: ({ token }) => token('spacing.m')
        }
      },
      {
        queries: {
          minWidth: 's'
        },
        styles: {
          ...padding.styles({
            target: 'card.content',
            all: 'xl'
          }),

          gap: ({ token }) => token('spacing.xl')
        }
      }
    ])
  }
});

export type CardContentProps = PropsWithChildren<
  BoxProps & {
    style?: CSSProperties;
    className?: string;
  }
>;

export const CardContent = forwardRef<HTMLDivElement, CardContentProps>(
  function CardContent({ children, style, className, ...props }, ref) {
    const s = useStyles(defaultStyles);

    return (
      <Box
        {...s.with('container')({ style, className })}
        flexDirection='column'
        {...props}
        ref={ref}
      >
        {children}
      </Box>
    );
  }
);
