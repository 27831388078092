import React from 'react';

import { StyleSheet, useStyles } from '@rexlabs/styling';
import { TagCell } from '@rexlabs/table';
import { Small } from '@rexlabs/text';

import { Invoice } from 'data/models/entities/financials/invoices';

import { formatCurrency } from 'utils/formatters';

import { TwoTierCell } from 'view/components/cells/two-tier-cell';
import { Columns } from 'view/components/table';
import { columnTypes } from 'view/components/table/columns/column-types';
import { InvoiceStatusTag } from 'view/components/tags/invoice-status';
import { LargeDescriptionCell } from 'view/components/cells/large-description-cell';
import { TwoTierLargeDescriptionCell } from 'view/components/cells/two-tier-large-description-cell';

import { priorityMap } from '../components/invoice-priority-select';

export const styles = StyleSheet({
  subText: {
    color: ({ token }) => token('color.textStyle.body.subtext')
  },

  rightAlign: {
    textAlign: 'right'
  }
});

export const invoiceColumns: Columns<Invoice> = [
  {
    id: 'bill_priority',
    headerString: 'Bill priority',
    width: 40,
    Cell: ({ value }) => {
      const priority = value?.id ?? 'low';
      const PriorityIcon = priorityMap[priority];

      return <PriorityIcon height={20} width={20} />;
    },
    toCsv: (item) => item?.label
  },
  {
    id: 'invoice',
    accessor: (item) => item,
    Header: 'Invoice',
    width: 360,
    Cell: ({ value }) => (
      <TwoTierLargeDescriptionCell
        text={value.description}
        subtext={value.record_reference}
        lineClamp={3}
      />
    ),
    toCsv: (item) => `${item.description} ${item.record_reference}`
  },
  {
    id: 'payable_to.object',
    Cell: ({ value }) => (
      <LargeDescriptionCell lineClamp={2} text={value.label} />
    ),
    Header: 'Payable to',
    width: 200,
    toCsv: (item) => item?.label
  },
  {
    id: 'payable_by.object',
    Cell: ({ value }) => (
      <LargeDescriptionCell lineClamp={2} text={value.label} />
    ),
    width: 200,
    Header: 'Payable by',
    toCsv: (item) => item?.label
  },
  {
    id: 'due_date',
    type: 'date',
    width: 120,
    Header: 'Due date'
  },
  {
    id: 'amount',
    width: 160,
    accessor: (item) => item,
    Header: () => {
      const s = useStyles(styles);

      return (
        <div {...s('rightAlign')}>
          <div>Invoice amount</div>
          <div {...s('subText')}>
            <Small semibold as='div'>
              Balance
            </Small>
          </div>
        </div>
      );
    },
    headerString: 'Amount',
    cellProps: {
      align: 'right'
    },
    Cell: ({ value }) => (
      <TwoTierCell
        text={formatCurrency(value.amount_total_inc_tax)}
        subtext={formatCurrency(value.amount_owing)}
      />
    ),
    toCsv: (item) =>
      `${columnTypes.currency.toCsv(
        item.amount_total_inc_tax
      )} ${columnTypes.currency.toCsv(item.amount_owing)}`
  },
  {
    id: 'status',
    Header: 'Status',
    width: 160,
    Cell: (table) => {
      const {
        cell: { value }
      } = table;
      return (
        <TagCell>
          <InvoiceStatusTag status={value} />
        </TagCell>
      );
    },
    toCsv: (item) => item?.label
  },
  {
    id: 'bill_reference',
    Header: 'Bill reference'
  },
  {
    id: 'created_at',
    type: 'date',
    Header: 'Created at'
  },
  {
    id: 'updated_at',
    type: 'date',
    Header: 'Updated at'
  }
];
