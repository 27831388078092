import { reduce } from 'lodash';

export function getMessageCountByChannelType(
  messages,
  channelType: 'sms' | 'email'
) {
  return reduce(
    messages,
    (count, message) =>
      count + (message.forced_channel_type.id === channelType ? 1 : 0),
    0
  );
}
