import { useState, useCallback } from 'react';
import axios, { CancelToken } from 'axios';
import {
  TaxPreviewResponse,
  TaxPreviewLineItem
} from 'src/modules/financials/types';
import { getInvoiceTaxPreview } from 'src/modules/financials/data/api';

const isValidLineItem = (lineItem: TaxPreviewLineItem) => {
  if (!lineItem.tax_type) return false;
  if (!lineItem.amount) return false;

  return true;
};

async function fetchJournalEntryLineItemsPreview(
  is_tax_included: boolean,
  line_items?: TaxPreviewLineItem[],
  cancelToken?: CancelToken
): Promise<TaxPreviewResponse> {
  if (!line_items) throw new Error('line_items are not provided');
  if (line_items.length === 0) throw new Error('line_items are empty');

  const isAnyLineItemValid = line_items.some(isValidLineItem);

  if (!isAnyLineItemValid) throw new Error('none of the line items are valid');

  const request = await getInvoiceTaxPreview(
    {
      is_tax_included,
      line_items: line_items.map((lineItem) => ({
        amount: lineItem.amount || 0,
        tax_type: lineItem.tax_type
      }))
    },
    { cancelToken }
  );

  return request;
}

export function useTaxPreview() {
  const [taxPreview, setTaxPreview] = useState<TaxPreviewResponse | undefined>(
    undefined
  );
  const [isLoading, setIsLoading] = useState(false);

  const updateTaxPreviews = useCallback(
    (isTaxIncluded: boolean, lineItems: TaxPreviewLineItem[]) => {
      setIsLoading(true);

      let isSettled = false;
      const cancelToken = axios.CancelToken.source();
      fetchJournalEntryLineItemsPreview(
        isTaxIncluded,
        lineItems,
        cancelToken.token
      )
        .then(
          (taxPreviews) => {
            setTaxPreview(taxPreviews);
            setIsLoading(false);
          },
          () => {
            if (!cancelToken.token.reason) {
              setIsLoading(false);
            }
          }
        )
        .finally(() => {
          isSettled = true;
        });

      return () => {
        if (!isSettled) {
          cancelToken.cancel();
        }
        setTaxPreview(undefined);
      };
    },
    [setTaxPreview, setIsLoading]
  );

  return {
    taxPreview,
    isLoading,
    updateTaxPreviews
  };
}
