import React from 'react';

import { Message } from '@rexlabs/notifications';

import EmptyBatchReceipting from 'src/assets/illustrations/empty-batch-receipting-list.svg';

import { Card } from 'view/components/@luna/card';

export function MatchedTabEmptyState() {
  return (
    <Card>
      <Message title='Matched transactions' Illustration={EmptyBatchReceipting}>
        Transactions that have been matched to a folio will be displayed here.
      </Message>
    </Card>
  );
}
