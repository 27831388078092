import React from 'react';
import { Field } from '@rexlabs/form';
import { PhoneInput, EmailInput, TextArea } from '@rexlabs/text-input';

import { BlockConfig } from 'view/components/record-screen/types';
import { Column, Grid } from 'view/components/@luna/form/grid';

export const contactInformationBlock: BlockConfig = {
  id: 'contact-information',
  title: 'Contact information',
  validate: {
    definitions: {
      email: {
        name: 'email address',
        rules: 'email'
      }
    }
  },
  Edit: () => (
    <Grid columns={4}>
      <Column width={2}>
        <Field name='address' label='Address' Input={TextArea} />
      </Column>
      <Field name='email' label='Email address' Input={EmailInput} />
      <Field name='phone_number' label='Phone number' Input={PhoneInput} />
    </Grid>
  )
};
