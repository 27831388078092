import React from 'react';
import { CellProps } from '@rexlabs/table';
import { TwoTierCell } from 'view/components/cells/two-tier-cell';
import { ListTableProps } from 'view/components/table/list-table';
import { getRecordObject } from 'src/modules/common/utils/Records/get-record-object';
import { getRecordLabel } from 'utils/records/get-record-label';

export function AnyTaskSummaryCell({
  value
}: CellProps & Omit<ListTableProps<any>, 'columns'>) {
  if (!value) {
    return <>--</>;
  }
  const recordObject = getRecordObject(value, value?.type?.id);
  return (
    <TwoTierCell
      text={getRecordLabel(recordObject)}
      subtext={value?.type?.label}
    />
  );
}
