import React from 'react';
import { BlockConfig } from 'view/components/record-screen/types';
import { Column, Grid } from '@rexlabs/grid';
import { Value } from 'view/components/values';
import { Portfolio } from 'src/modules/portfolios/models/portfolios-model';
import { Field } from '@rexlabs/form';
import { TextInput } from '@rexlabs/text-input';

export const overviewBlock: BlockConfig<Portfolio> = {
  id: 'overview',
  title: 'Overview',
  validate: {
    definitions: {
      label: { rules: 'required' }
    }
  },
  View: ({ data }) => {
    return (
      <Grid>
        <Column width={12}>
          <Value label={'Portfolio name'} value={data?.label} />
        </Column>
      </Grid>
    );
  },
  Edit: () => {
    return (
      <Grid>
        <Column width={12}>
          <Field name='label' label={'Portfolio name'} Input={TextInput} />
        </Column>
      </Grid>
    );
  }
};
