import { useStatusChangeAction } from 'src/modules/common/actions/status/use-status-change-action';
import { SingleActionDeclaration } from 'src/modules/common/actions/types/action-declaration-types';
import { tasksModel } from 'src/modules/tasks/common/models/tasks-model';
import { WorkOrderTask } from '../../types/WorkOrderTask';
import { getWorkOrderStatusChangeTransformPayload } from '../../utils/get-work-order-status-change-transform-payload';

export function useGetAcceptWorkOrderAction() {
  const getStatusChangeAction = useStatusChangeAction(tasksModel);

  return (workOrder: WorkOrderTask): SingleActionDeclaration => {
    const statusChangeTransformPayload = getWorkOrderStatusChangeTransformPayload(
      workOrder
    );
    return {
      ...getStatusChangeAction({
        record: workOrder,
        status: 'accepted',
        dialogOptions: {
          label: 'Mark as accepted',
          type: 'none'
        },
        transformPayload: statusChangeTransformPayload
      }),
      intent: 'primary',
      group: 'status'
    } as SingleActionDeclaration;
  };
}
