import Box from '@rexlabs/box';
import { useToken } from '@rexlabs/styling';
import React from 'react';
import ImagesIcon from 'view/components/icons/images';

export function ImageColumnHeader() {
  const token = useToken();

  return (
    <Box justifyContent='center' marginLeft={token('spacing.xxs')}>
      <ImagesIcon></ImagesIcon>
    </Box>
  );
}
