import {
  ChartOfAccountCategory,
  ChartOfAccountsAccount
} from 'src/modules/chart-of-accounts/types/chart-of-account-types';

function convertToIndex<T>(value: T, arr: T[]) {
  const actualIndex = arr.indexOf(value);

  if (actualIndex < 0) return arr.length;
  return actualIndex;
}

function alphabetically(nameA: string, nameB: string) {
  if (nameA < nameB) return -1;
  if (nameA > nameB) return 1;
  return 0;
}

const byCategory = (sortingOrder: ChartOfAccountCategory[]) => {
  return (a: ChartOfAccountsAccount, b: ChartOfAccountsAccount) => {
    if (a.account_category.id === b.account_category.id) {
      return alphabetically(a.name, b.name);
    }

    const aIndex = convertToIndex(a.account_category.id, sortingOrder);
    const bIndex = convertToIndex(b.account_category.id, sortingOrder);

    return aIndex - bIndex;
  };
};

export function sortByCategory(
  accounts: ChartOfAccountsAccount[],
  sortingOrder: ChartOfAccountCategory[]
) {
  if (sortingOrder.length === 0) return accounts;
  return [...(accounts ?? [])].sort(byCategory(sortingOrder));
}
