import * as React from 'react';
import {
  FieldArrayActions,
  FieldArrayField,
  FieldArrayPassthroughProps
} from '@rexlabs/form';
import { DragDropContext, DropResult, Droppable } from 'react-beautiful-dnd';
import Box from '@rexlabs/box';
import { ChecklistTemplatesForm } from '../types/ChecklistTemplatesForm';
import { TaskType } from '../../common/types/TaskType';
import { ChecklistTemplateItemRow } from './checklist-template-item-row';

interface ChecklistTemplateItemRowsProps extends FieldArrayActions {
  fields: FieldArrayField[];
  values: ChecklistTemplatesForm;
  taskType: TaskType;
  fieldArrayProps: FieldArrayPassthroughProps;
  setFieldValue: (field: string, value: any) => void;
}

export function ChecklistTemplateItemRows(
  props: ChecklistTemplateItemRowsProps
) {
  const {
    fields,
    values,
    taskType,
    move,
    fieldArrayProps,
    setFieldValue
  } = props;

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) return;

    move(result.source.index, result.destination.index);
  };

  return (
    <>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable direction='vertical' droppableId='checklist'>
          {(provided) => (
            <Box ref={provided.innerRef} {...provided.droppableProps}>
              {fields.map(({ field }, index) => {
                return (
                  <ChecklistTemplateItemRow
                    key={field.key}
                    index={index}
                    field={field}
                    values={values}
                    taskType={taskType}
                    fieldArrayProps={fieldArrayProps}
                    setFieldValue={setFieldValue}
                  ></ChecklistTemplateItemRow>
                );
              })}

              {provided.placeholder}
            </Box>
          )}
        </Droppable>
      </DragDropContext>
    </>
  );
}
