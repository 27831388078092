import { push } from '@rexlabs/whereabouts';
import React from 'react';
import ROUTES from 'routes/app';
import Box from '@rexlabs/box';
import { LinkButton } from '@rexlabs/button';
import { Message } from 'view/components/@luna/message';
import PasswordErrorSvg from 'assets/illustrations/password-error.svg';
import icons from '@rexlabs/icons';

const { ChevronLeft } = icons;

export function UserInvitationErrorScreen() {
  return (
    <>
      <Box mb='2rem' data-testid='forgot-password-success'>
        <LinkButton onClick={() => push(ROUTES.LOGIN)} IconLeft={ChevronLeft}>
          Back to login
        </LinkButton>
      </Box>
      <Message
        grey
        title='Invitation is invalid'
        Illustration={PasswordErrorSvg}
      >
        Something went wrong when trying to load the invitation details.
      </Message>
    </>
  );
}
