import { TaxSummaryItem } from 'data/models/entities/financials/invoices';

export function getTaxSummaryRows(taxSummary: TaxSummaryItem[]) {
  return taxSummary
    .filter((tax) => tax.amount_tax !== 0)
    .map((tax) => ({
      label: `Total ${tax.tax_type.label}`,
      value: tax.amount_tax
    }));
}
