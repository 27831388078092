import { useCallback, useMemo } from 'react';

import { push, useWhereabouts } from '@rexlabs/whereabouts';

/**
 * Use this hook in a tabbed component, to update the URL when the active tab changes.
 */
export function useTabUrlState({
  tabs,
  hashParamKey
}: {
  tabs: { name: string }[];
  hashParamKey?: string;
}) {
  const whereabouts = useWhereabouts();
  const { hashQuery } = whereabouts;

  const hashTabPrefix = hashParamKey ? `${hashParamKey}_` : '';
  const tabKey = `${hashTabPrefix}tab`;
  const hashTab = hashQuery?.[tabKey];

  const activeTab = useMemo(() => {
    return hashTab ?? tabs?.[0]?.name;
  }, [hashTab, tabs]);

  const setTab = useCallback(
    ({ name }) => {
      push({
        config: {
          ...whereabouts,
          hash: undefined,
          hashQuery: {
            ...hashQuery,
            [tabKey]: name !== tabs?.[0]?.name ? name : undefined
          }
        }
      });
    },
    [tabs, hashQuery, tabKey, whereabouts]
  );

  return {
    activeTab,
    setTab
  };
}
