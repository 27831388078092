import React from 'react';

import { Body } from '@rexlabs/text';
import { ClampedDescriptionCell } from 'view/components/cells/clamped-description-cell';

/**
 * You can use this component to display a large description in a cell for example, you may have
 * a large string that would normally be truncated, but you want it to run over multiple lines. You can
 * add extra lines by passing a number to the lineClamp prop.
 */
export function LargeDescriptionCell({
  text,
  lineClamp = 2
}: {
  text: string;
  lineClamp?: number;
}) {
  return (
    <Body as='div'>
      <ClampedDescriptionCell lineClamp={lineClamp} text={text} />
    </Body>
  );
}
