import { RentLineItem } from './use-rent-line-items';

export function useUnusedRentLineItems({
  lineItems = [],
  rentLineItems = []
}: {
  lineItems?: Record<string, any>[];
  rentLineItems?: RentLineItem[];
}) {
  const lineItemRent = lineItems?.filter(
    (lineItem) => lineItem.category === 'rent'
  );

  const unusedRentLineItems = rentLineItems?.filter((rentLineItem) => {
    return !lineItemRent?.find(
      (lineItemInvoice) =>
        lineItemInvoice.rent.property_tenancy.id ===
        rentLineItem.rent.property_tenancy.id
    );
  });

  return { unusedRentLineItems };
}
