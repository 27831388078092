import React from 'react';
import { GhostButton, OutlineButton, PrimaryButton } from '@rexlabs/button';

import { ButtonGroupProps } from 'view/components/record-screen/dialog/types';
import { StandardDialogFooter } from 'view/components/dialogs/components/standard-dialog-footer';

const formName = 'edit-location-block';

export function EditLocationDialogButtonGroup({
  handleSubmit,
  onClose,
  isSubmitting,
  submitLabel,
  forms
}: ButtonGroupProps) {
  const { initialValues, resetForm } = forms?.[formName] || {};

  const handleReset = () => {
    resetForm?.(initialValues);
  };

  const handleSubmitAndClose = async () => {
    const result = await handleSubmit();

    if (!result) return;

    onClose?.();
  };

  return (
    <StandardDialogFooter
      left={
        <OutlineButton onClick={handleReset}>
          Reset to default location
        </OutlineButton>
      }
    >
      <GhostButton onClick={onClose}>Cancel</GhostButton>
      <PrimaryButton onClick={handleSubmitAndClose} isLoading={isSubmitting}>
        {submitLabel}
      </PrimaryButton>
    </StandardDialogFooter>
  );
}
