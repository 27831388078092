import { BlockViewProps } from 'view/components/record-screen/types';
import { TaskType } from '../types/TaskType';
import { getDefaultTaskManagerContact } from './get-default-task-manager-contact';

export async function onPropertyChangeHandler({
  values,
  setFieldValue,
  taskType
}: Pick<BlockViewProps, 'values' | 'setFieldValue'> & {
  taskType: TaskType;
}) {
  const property = values?.property;

  if (property) {
    const defaultTaskManagerContact = await getDefaultTaskManagerContact({
      propertyId: property?.id,
      taskType: taskType
    });

    if (!values?.managed_by && defaultTaskManagerContact) {
      setFieldValue?.('managed_by', defaultTaskManagerContact);
    }
  }
}
