import React, { useCallback } from 'react';
import {
  SelectProps,
  Select,
  useItems,
  useFreeformDialog,
  UseItemsArgs,
  UseFreeformDialogArgs,
  FreeformDialogProps
} from '@rexlabs/select';

import { Ownership } from 'data/models/entities/ownerships';

import { api } from 'utils/api/api-client';
import { normaliseOwnership } from 'utils/normaliser/v4-select-normalisers/ownership';
import { CreateOwnershipDialog } from 'src/modules/ownerships/dialogs/create-ownership-dialog';

export type OwnershipSelectProps = SelectProps<Ownership> &
  Pick<UseItemsArgs<Ownership>, 'getSuggestedItems'> & {
    dialogProps?: UseFreeformDialogArgs<Ownership>['props'];
    disableFixture?: boolean;
  };

function FreeformOwnershipDialog({
  selectItem,
  onCreate: propOnCreate,
  ...props
}: any & FreeformDialogProps<Partial<Ownership>>) {
  const onCreate = useCallback(
    (data) => {
      propOnCreate?.(data);
      selectItem(data);
    },
    [propOnCreate, selectItem]
  );

  return <CreateOwnershipDialog {...props} onCreate={onCreate} />;
}

export const OwnershipSelect: React.FC<OwnershipSelectProps> = ({
  dialogProps,
  disableFixture = false,
  ...props
}) => {
  const getItems = useCallback(async (term) => {
    const ownerships = await api.get(`/ownerships`, {
      // TODO: [AL-459] fix the fact that per_page get param breaks includes defined by model generator
      per_page: 30,
      include: 'properties,owners,financial_summary',
      search: term
    });
    return ownerships.data;
  }, []);

  const { getSelectProps: useItemsGetSelectProps } = useItems<Ownership>({
    getItems
  });
  const {
    getSelectProps: useFreeformDialogGetSelectProps
  } = useFreeformDialog<Ownership>({
    props: dialogProps,
    Dialog: FreeformOwnershipDialog
  });

  const selectProps = useItemsGetSelectProps();
  const selectPropsWithFixture = useItemsGetSelectProps(
    useFreeformDialogGetSelectProps()
  );

  return (
    <Select
      {...props}
      id='ownership-select'
      {...(disableFixture ? selectProps : selectPropsWithFixture)}
      normaliser={normaliseOwnership}
    />
  );
};
