import React from 'react';
import { query } from '@rexlabs/model-generator';
import { Message, MessageProps } from '@rexlabs/notifications';
import { useDialog } from '@rexlabs/dialog';

import { Columns, ListTable, TabbedTable } from 'view/components/table';
import {
  financialsSecurityDepositsModel,
  SecurityDeposit
} from 'data/models/entities/financials/security-deposits';
import { transformActionDeclarationsToActionMenuItems } from 'utils/actions/transforms';
import { LoadingState } from 'view/components/states/loading';
import { Card } from 'view/components/@luna/card';
import { getRecordLinkProps } from 'view/components/record-link/get-record-link-props';
import { useTableFilters } from 'view/hooks/use-table-filters';
import { useGetSecurityDepositActions } from 'src/modules/security-deposits/actions/use-get-security-deposit-actions';
import { Tab } from 'view/components/table/tabbed';
import EmptyTable from 'src/assets/illustrations/empty-table.svg';

import { Trans, useTranslation } from 'react-i18next';
import { CreateSecurityDepositDialog } from '../dialogs/create-security-deposit';

const getSecurityDepositQuery = () => {
  return query`{
    ${financialsSecurityDepositsModel}  {
      security_deposit_type
      transaction_summary
    }
  }`;
};

function useColumns(): Columns<SecurityDeposit> {
  const { t } = useTranslation();

  return [
    {
      id: 'security_deposit_type.name',
      Header: t('bonds-and-deposits.bond-or-deposit.label.singular') as string
    },
    {
      id: 'registration_number',
      Header: 'Deposit registration number'
    },
    {
      id: 'amount_expected',
      type: 'currency',
      cellProps: {
        align: 'right'
      },
      Header: 'Deposit required'
    },
    {
      id: 'amount_due',
      type: 'currency',
      cellProps: {
        align: 'right'
      },
      Header: 'Deposit amount due'
    }
  ];
}

const getRowLinkProps = ({ item }) =>
  getRecordLinkProps({ type: 'security_deposit', id: item.id });

interface SecurityDepositsListProps {
  propertyTenancyId?: string;
}

type Actions = Required<MessageProps>['actions'];

export const SecurityDepositsList = React.memo(function SecurityDepositsList({
  propertyTenancyId
}: SecurityDepositsListProps) {
  const actions = useGetSecurityDepositActions();
  const { getSort, getFilters } = useTableFilters('security-deposits');

  const { open } = useDialog(CreateSecurityDepositDialog);

  const onEmptyStateAction = React.useCallback(() => {
    if (propertyTenancyId) {
      return open({ propertyTenancyId });
    }
  }, [propertyTenancyId]);

  const Empty = React.useMemo(() => {
    return () => <EmptyState onAction={onEmptyStateAction} />;
  }, [onEmptyStateAction]);

  const columns = useColumns();

  if (!propertyTenancyId) {
    return <></>;
  }

  const commonProps = {
    id: 'security-deposit-table',
    getSort,
    getFilters,
    Table: ListTable,
    emptyWithoutTable: true,
    Empty,
    Loading: LoadingState,
    columns,
    getQuery: getSecurityDepositQuery,
    getActionMenuItems: ({ item }) =>
      transformActionDeclarationsToActionMenuItems(actions(item)),
    getRowLinkProps
  };

  const tabs: Tab[] = [
    {
      ...commonProps,
      forcedGlobalFilter: [
        { field: 'property_tenancy_id', op: 'eq', value: propertyTenancyId },
        {
          field: 'status_id',
          op: 'eq',
          value: 'active'
        }
      ],
      name: 'active',
      label: 'Active'
    },
    {
      ...commonProps,
      forcedGlobalFilter: [
        { field: 'property_tenancy_id', op: 'eq', value: propertyTenancyId },
        {
          field: 'status_id',
          op: 'eq',
          value: 'archived'
        }
      ],
      name: 'archived',
      label: 'Archived'
    }
  ];

  return <TabbedTable tabs={tabs} />;
});

const EmptyState = React.memo(({ onAction }: { onAction: () => void }) => {
  const { t } = useTranslation();
  const actions = React.useMemo<Actions>(() => {
    return [
      {
        type: 'primary',
        label: 'Add security deposit',
        handleAction: onAction
      }
    ];
  }, [onAction]);

  return (
    <Card>
      <Message
        title={t('bonds-and-deposits.no-active')}
        Illustration={EmptyTable}
        actions={actions}
      >
        <Trans i18nKey='bonds-and-deposits.empty-message'>
          Lease agreements usually require a bond or security deposit. Please
          provide the details of the amount required and who will hold the bond
          on behalf of the tenants.
        </Trans>
      </Message>
    </Card>
  );
});
