export const PROPERTY_COMPLIANCE_TASK_ROUTES = {
  PROPERTY_COMPLIANCE_TASKS_LIST: {
    config: {
      path: '/property-compliance-tasks',
      Component: require('./screens/property-compliance-list.tsx')
        .PropertyComplianceList
    }
  },
  PROPERTY_COMPLIANCE_TASKS_DETAILS: {
    config: {
      path: '/property-compliance-task/:taskId',
      Component: require('./screens/property-compliance-details.tsx')
        .PropertyComplianceDetails
    }
  }
};
