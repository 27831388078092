import React from 'react';

import { CellProps } from '@rexlabs/table';
import { Truncate } from '@rexlabs/text';

function PercentageCell({ cell }: CellProps) {
  return (
    <Truncate>
      {cell?.value ? `${(100 * cell.value).toFixed(2)}%` : null}
    </Truncate>
  );
}

export default PercentageCell;
