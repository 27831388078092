import { FinancialRecordType } from 'utils/financials/financial-objects';
import { RecordData } from 'utils/types';
import { Contact } from 'src/modules/contacts/types/contact-types';

export function isCOANeeded(
  type: FinancialRecordType,
  item: Contact | RecordData
) {
  if (type === 'ownership') return true;

  if (type === 'contact') {
    const contact = item as Contact;
    if (contact?.is_agency) {
      return true;
    }
  }

  return false;
}

export function isPropertyNeeded(type: FinancialRecordType) {
  if (type === 'ownership') return true;

  return false;
}
