import { useGetPrimaryRecordActionGroup } from 'view/hooks/actions/use-get-primary-record-action-group';
import { TrustLedger } from 'src/modules/trust-ledgers/types/trust-ledger-types';
import { useGetCreateLedgerSummaryDialogAction } from '../hooks/use-get-create-ledger-summary-dialog-action';
import { useGetEditAction } from './trust-ledger-actions/use-get-edit-action';

export function useGetTrustLedgerActions() {
  const getPrimaryRecordActionGroup = useGetPrimaryRecordActionGroup();
  const getEditAction = useGetEditAction();
  const getCreateLedgerSummaryDialogAction = useGetCreateLedgerSummaryDialogAction();

  return (trustLedger?: TrustLedger) => {
    if (!trustLedger) return [];
    return [
      ...getPrimaryRecordActionGroup('trust_ledger', trustLedger.id),
      getEditAction(trustLedger),
      getCreateLedgerSummaryDialogAction(trustLedger)
    ];
  };
}
