import React, { ReactNode } from 'react';
import { padding, StyleSheet, useStyles } from '@rexlabs/styling';
import Box, { BoxProps } from '@rexlabs/box';
import { RemoveIconButton } from 'src/modules/common/components/remove-icon-button';

const defaultStyles = StyleSheet({
  lineItem: {
    borderRadius: ({ token }) => token('border.radius.l'),
    background: ({ token }) => token('palette.grey.200'),
    ...padding.styles({
      x: 'xl',
      y: 'xl'
    })
  }
});

interface DialogLineItemProps extends BoxProps {
  heading?: ReactNode;
  children: ReactNode;
  ActionButton?: ReactNode;
  onRemove?: () => void;
}

export function DialogLineItem({
  heading,
  children,
  onRemove,
  ActionButton,
  ...props
}: DialogLineItemProps) {
  const s = useStyles(defaultStyles);
  return (
    <Box mb={'2rem'} {...s('lineItem')} {...props}>
      <Box
        flexDirection='row'
        mb='1.2rem'
        alignItems='center'
        justifyContent='space-between'
      >
        {heading}
        <Box ml='0.8rem' flexDirection='row' alignItems='center'>
          {ActionButton && ActionButton}
          {onRemove && <RemoveIconButton onClick={onRemove} />}
        </Box>
      </Box>
      {children}
    </Box>
  );
}
