import * as React from 'react';

import { DialogProps } from '@rexlabs/dialog';
import { useModelActions } from '@rexlabs/model-generator';
import { useRecordCreatedToast } from 'src/modules/common/toasts/hooks/use-record-created-toast';

import { RecordSubmitHandler } from 'view/components/record-screen/utils';
import { CreateRecordDialog } from 'view/components/dialogs/create-record-dialog/create-record-dialog';

import { todoDetailsBlock } from '../blocks/todo-details-block';
import { tasksModel } from '../../common/models/tasks-model';
import { taskPriorityBlock } from '../../common/blocks/task-priority-block';
import { taskDateBlock } from '../../common/blocks/task-date-block';
import { mapTodoFormDataToTaskCreateRequest } from '../mappers/map-todo-form-data-to-task-create-request';
import { TodoFormData } from '../types/TodoFormData';
import { checklistTemplateSelectBlock } from '../../common/blocks/checklist-template-select-block';
import { createTaskAndLinkChecklists } from '../../common/utils/create-task-and-link-checklists';
import { useTaskTypesWithCustomAddToRecord } from '../../settings/hooks/use-task-types-with-custom-add-to-record';

export interface CreateTodoRecordDialogProps extends DialogProps {
  initialValues: Partial<TodoFormData>;
  onCreate?: (data) => void;
  isFixture?: boolean;
}

function useContent() {
  const taskTypesWithCustomAddToRecord = useTaskTypesWithCustomAddToRecord();

  return React.useMemo(
    () => [
      {
        id: 'add-todo',
        label: 'Add To-do',
        blocks: [
          todoDetailsBlock,
          ...(taskTypesWithCustomAddToRecord.includes('todo')
            ? [checklistTemplateSelectBlock]
            : []),
          taskPriorityBlock,
          taskDateBlock
        ]
      }
    ],
    []
  );
}

export function CreateTodoRecordDialog({
  onClose,
  isFixture,
  initialValues,
  onCreate,
  target
}: CreateTodoRecordDialogProps) {
  const { refreshLists } = useModelActions(tasksModel);
  const addToast = useRecordCreatedToast('todo');
  const content = useContent();

  const handleSubmit: RecordSubmitHandler<TodoFormData> = async ({
    values
  }) => {
    const todo = await createTaskAndLinkChecklists<'todo'>(
      values,
      mapTodoFormDataToTaskCreateRequest
    );

    onCreate?.(todo);

    addToast(todo);
    await refreshLists();

    return todo;
  };
  return (
    <CreateRecordDialog
      recordType='todo'
      data={initialValues}
      blockProps={{ taskTypeId: 'todo' }}
      content={content}
      onClose={onClose}
      onSubmit={handleSubmit}
      target={target}
      isFixture={isFixture}
    />
  );
}
