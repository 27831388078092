import * as React from 'react';
import { StyleSheet, useStyles } from '@rexlabs/styling';
import { Box } from '@rexlabs/box';
import { Heading, Small, Bold, Body } from '@rexlabs/text';
import { OutlineButton } from '@rexlabs/button';

const styles = StyleSheet({
  root: {
    background: ({ token }) => token('palette.white'),
    borderRadius: 8,
    padding: '16px 24px',
    display: 'grid',
    gridTemplateColumns: '40px 1fr auto',
    gridColumnGap: 12,
    gridTemplateAreas: `
    "icon title action"
    "summary summary summary"
    "stats stats stats"
    `
  },

  iconContainer: {
    height: 40,
    width: 40,
    borderRadius: 4,
    background: ({ token }) => token('palette.grey.200'),
    gridArea: 'icon'
  },

  subtleText: {
    color: ({ token }) => token('color.textStyle.body.subtext')
  },

  bodyText: {
    color: ({ token }) => token('color.textStyle.body.default')
  },

  summarySection: {
    gridArea: 'summary'
  },

  summaryPercentage: {
    fontSize: 16
  },

  summary: {
    position: 'relative',

    '&:not(:last-child):after': {
      content: '""',
      position: 'relative',
      width: '5px',
      height: '5px',
      right: '-7px',
      top: 'calc(50% - 2.5px)',
      borderRadius: '50%',
      background: ({ token }) => token('palette.grey.700')
    }
  },

  statsSection: {
    gridArea: 'stats',
    borderTop: ({ token }) => `1px solid ${token('palette.grey.300')}`
  },

  stat: {
    textDecoration: 'none',
    color: 'inherit !important',
    borderRadius: 8,
    padding: '12px 16px',

    ':hover': {
      cursor: 'pointer'
    }
  },

  normal: {
    background: ({ token }) => token('palette.grey.200')
  },

  warning: {
    background: ({ token }) => token('palette.yellow.100')
  },

  danger: {
    background: ({ token }) => token('palette.red.100'),
    color: ({ token }) => token('color.textStyle.danger.idle.default')
  }
});

export interface DashboardCardProps {
  IconSlot: React.ReactElement;
  title: string;
  actionLabel?: string;
  onAction: () => void;
  children?: React.ReactNode;
}

export const DashboardCard = (props: DashboardCardProps) => {
  const {
    IconSlot,
    title,
    onAction,
    children,
    actionLabel = 'View all'
  } = props;
  const s = useStyles(styles);

  return (
    <Box {...s('root')}>
      <Box alignItems='center' justifyContent='center' {...s('iconContainer')}>
        {IconSlot}
      </Box>

      <Box alignItems='center' style={{ gridArea: 'title' }}>
        <Heading level={4}>{title}</Heading>
      </Box>

      <Box style={{ gridArea: 'action' }}>
        <OutlineButton onClick={onAction}>{actionLabel}</OutlineButton>
      </Box>

      {children}
    </Box>
  );
};

// Compound components

const SummarySection: React.FC = ({ children }) => {
  const s = useStyles(styles);

  return (
    <Box alignItems='center' gap={16} {...s('summarySection')}>
      {children}
    </Box>
  );
};

const Summary: React.FC<{ label: string }> = ({ label, children }) => {
  const s = useStyles(styles);

  return (
    <Box gap={4} alignItems='center' marginTop={12} {...s('summary')}>
      <Small as='div' {...s('subtleText')}>
        {label}
      </Small>
      <Body as='div' semibold {...s('summaryPercentage')}>
        {children}
      </Body>
    </Box>
  );
};

const StatsSection: React.FC = ({ children }) => {
  const s = useStyles(styles);

  return (
    <Box gap={12} marginTop={12} paddingTop={12} {...s('statsSection')}>
      {children}
    </Box>
  );
};

interface StatProps {
  label: string;
  variant?: 'normal' | 'warn' | 'danger';
  onClick: (event: React.MouseEvent) => void;
}

const Stat: React.FC<StatProps> = ({
  variant = 'normal',
  label,
  onClick,
  children
}) => {
  const s = useStyles(styles);

  return (
    <Box
      role='link'
      tabIndex={0}
      onClick={onClick}
      flex='1'
      flexDirection='column'
      gap={4}
      {...s.with({
        stat: true,
        normal: variant === 'normal',
        warning: variant === 'warn',
        danger: variant === 'danger'
      })()}
    >
      <Small as='div' {...s('bodyText')}>
        {label}
      </Small>
      <Bold as='div'>{children}</Bold>
    </Box>
  );
};

DashboardCard.SummarySection = SummarySection;
DashboardCard.Summary = Summary;

DashboardCard.StatsSection = StatsSection;
DashboardCard.Stat = Stat;
