import { Generator } from 'data/models/generator';
import { TrustJournalEntryLineItem } from 'src/modules/trust-journal-entry-line-items/types/trust-journal-entry-line-item-type';
import { financialsInvoicesModel } from 'data/models/entities/financials/invoices';

const config = {
  entities: {
    related: {
      trust_journal_entry: {
        include: 'trust_journal_entry'
      },
      invoice: {
        include: 'invoice',
        model: financialsInvoicesModel
      },

      trust_ledger: {
        include: 'trust_ledger'
        // TODO:  I have so much disdain that this doesn't work..
        //        You can not define the inverse relations otherwise it will error
        // model: trustLedgerModel
      }
    }
  }
};

export const trustJournalEntryLineItemModel = new Generator<TrustJournalEntryLineItem>(
  'financials/trust-journal-entry-line-items',
  config
).createEntityModel();
