import React from 'react';
import { BlockConfig } from 'view/components/record-screen/types';
import { Value } from 'view/components/values';
import { Field } from '@rexlabs/form';
import { BooleanSelect } from 'view/components/inputs/selects/boolean';
import { Grid } from 'view/components/@luna/form/grid';
import InfoCircleIcon from 'view/components/icons/info';
import { InfoBanner } from 'view/components/@luna/notifications/banner';
import { Settings } from '../../hooks/useSettings';

const isSettingEnabled = (settingValue: boolean | undefined): boolean => {
  if (settingValue === undefined) {
    return false;
  }

  return settingValue;
};

const CONSOLIDATE_RENT_PAYMENTS_SETTING =
  'consolidate-rent-payments-on-statements';

const label =
  'Consolidate rent payments on receipts and disbursement statements';
const infoBannerDescription =
  'Trust Account Receipt documents and Disbursement Statements will show a line item for ' +
  'each time rent is receipted. With this disabled, a line item will be shown for each Rent Invoice.';

export const rentPaymentSettingsBlock: BlockConfig<Settings> = {
  id: 'rent-payment-settings',
  title: 'Rent Payments',
  validate: {
    definitions: {
      timezone: { rules: 'required' }
    }
  },
  View: ({ data }) => {
    const isEnabled = isSettingEnabled(
      data?.[CONSOLIDATE_RENT_PAYMENTS_SETTING]
    );

    return (
      <Grid columns={1}>
        <Grid columns={2}>
          <Value label={label} value={isEnabled ? 'Enabled' : 'Disabled'} />
        </Grid>
        <Grid columns={2}>
          <InfoBanner
            Icon={InfoCircleIcon}
            description={infoBannerDescription}
          />
        </Grid>
      </Grid>
    );
  },
  Edit: () => {
    return (
      <Grid columns={1}>
        <Grid columns={2}>
          <Field<typeof BooleanSelect>
            id={`${CONSOLIDATE_RENT_PAYMENTS_SETTING}-select`}
            label={label}
            name={CONSOLIDATE_RENT_PAYMENTS_SETTING}
            Input={BooleanSelect}
            inputProps={{
              trueLabel: 'Enabled',
              falseLabel: 'Disabled'
            }}
          />
        </Grid>
        <Grid columns={2}>
          <InfoBanner
            Icon={InfoCircleIcon}
            description={infoBannerDescription}
          />
        </Grid>
      </Grid>
    );
  }
};
