import { ActionDeclaration } from 'src/modules/common/actions/types/action-declaration-types';
import { useDialog } from '@rexlabs/dialog';
import { useModelActions } from '@rexlabs/model-generator';
import { trustJournalEntryModel } from 'src/modules/trust-journal-entries/models/trust-journal-entries';
import { useToast } from 'view/components/@luna/notifications/toast';
import { TrustJournalEntry } from 'src/modules/trust-journal-entries/types/trust-journal-entry-types';
import { ReverseTrustJournalEntryDialog } from '../../dialogs/reverse-trust-journal-entry';

export function useGetReverseAction() {
  const { reverse, refreshLists, refreshItem } = useModelActions(
    trustJournalEntryModel
  );
  const { open: openReverseTrustJournalEntryDialog } = useDialog(
    ReverseTrustJournalEntryDialog
  );
  const { addToast } = useToast();

  return (trustJournalEntry: TrustJournalEntry): ActionDeclaration => ({
    id: 'reverse',
    group: 'trust-journal-entry',
    label: 'Reverse entry',
    handleAction: () => {
      openReverseTrustJournalEntryDialog({
        trustJournalEntry,
        onSubmit: async (reverseReason) => {
          await reverse({
            id: trustJournalEntry!.id,
            ...reverseReason
          });

          await Promise.all([
            refreshItem({
              id: trustJournalEntry.id
            }),
            refreshLists()
          ]);

          addToast({
            description: `Successfully reversed ${trustJournalEntry.type.id}`
          });
        }
      });
    }
  });
}
