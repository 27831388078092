import * as React from 'react';
import { useCallback } from 'react';
import { fragment, query } from '@rexlabs/model-generator';
import { ListTable, TabbedTable } from 'view/components/table';
import { LinkProps, useWhereabouts } from '@rexlabs/whereabouts';
import { useDialog } from '@rexlabs/dialog';
import { useTableFilters } from 'view/hooks/use-table-filters';
import { GlobalFilter } from '@rexlabs/table/lib/types/context';
import { ViewSMSRecordDialog } from 'src/modules/communications/messages/dialogs/view-sms-record-dialog';
import { BulkAction } from '@rexlabs/table/lib/types/bulk-actions/simple';
import { useFeatureFlags } from 'view/components/@luna/feature-flags';
import { FLAGS } from 'utils/feature-flags';
import { useGetBulkSendMessageAction } from 'src/modules/communications/messages/hooks/action-declarations/use-get-bulk-send-message-action';
import { messagesModel } from '../models/messages-model';
import { messageColumns } from '../utils/message-columns';
import { useGetMessageOutboxActions } from '../hooks/action-menu-items/use-get-message-outbox-actions';
import { useGetMessageBouncedActions } from '../hooks/action-menu-items/use-get-message-bounced-actions';
import { useGetMessageSentActions } from '../hooks/action-menu-items/use-get-message-sent-actions';
import { ViewMessageRecordDialog } from '../dialogs/view-message-record-dialog';
import { PreviewMessageRecordDialog } from '../dialogs/preview-message-record-dialog';
import { messageColumnsBounced } from '../utils/message-columns-bounced';
import { channelMessageRecipientsModel } from '../models/channel-message-recipients-model';
import { channelMessageRecipientColumns } from '../utils/channel-message-recipient-columns';
import { ChannelMessageRecipient } from '../types/ChannelMessageRecipient';
import { PreviewSMSRecordDialog } from '../dialogs/preview-sms-record-dialog';
import { useMessageTableEmptyState } from './message-table-empty-state';

export const messageFragment = fragment`
  id,
  context,
  channels,
  inline_attachments,
  linked_files,
  recipients {
    emails
  }
  cc_recipients {
    emails
  }
  bcc_recipients {
    emails
  }
  send_from_author {
    contact
  }
  template
`;

export const messageQuery = query`{
  ${messagesModel} {
    ${messageFragment}
  }
}`;

const channelMessageRecipientQuery = query`{
  ${channelMessageRecipientsModel} {
    id,
    recipient_contact,
    channel_message {
      message {
        ${messageFragment}
      }
    }
  }
}`;

const extractMessageFromItemForRowLinkProps = (fn) => {
  return ({ item }: { item: ChannelMessageRecipient }) =>
    fn({ item: item.channel_message?.message });
};

export function MessagesTabbedTable({
  forcedGlobalFilters,
  messageHiddenColumns,
  channelMessageRecipientHiddenColumns,
  TabEmptyState
}: {
  forcedGlobalFilters?: GlobalFilter[];
  messageHiddenColumns?: Array<string>;
  channelMessageRecipientHiddenColumns?: Array<string>;
  TabEmptyState?: ({
    label
  }: {
    label: 'Outbox' | 'Processing' | 'Sent' | 'Bounced';
  }) => React.ReactElement;
}) {
  const { hasFeature } = useFeatureFlags();
  const whereabouts = useWhereabouts();
  const getMessageOutboxActions = useGetMessageOutboxActions();
  const getMessageBouncedActions = useGetMessageBouncedActions();
  const getMessageSentActions = useGetMessageSentActions();

  const { open: openViewDialog } = useDialog(ViewMessageRecordDialog);
  const { open: openViewSMSRecordDialog } = useDialog(ViewSMSRecordDialog);
  const { open: openPreviewDialog } = useDialog(PreviewMessageRecordDialog);
  const { open: openPreviewSMSDialog } = useDialog(PreviewSMSRecordDialog);

  const getBulkSendAction = useGetBulkSendMessageAction();

  const messagesFilters = useTableFilters('messages');
  const channelMessageRecipientsFilters = useTableFilters(
    'channel-message-recipients'
  );

  const getViewRowLinkProps = React.useCallback(
    ({ item }): LinkProps => {
      const hash = whereabouts.hash;
      const query = whereabouts.query;

      return {
        hash,
        query,
        onClick: () => {
          const channelType = item?.channels?.[0]?.channel_type?.id;

          if (channelType === 'email') {
            openViewDialog({ data: { message: item } });
          }

          if (channelType === 'sms') {
            openViewSMSRecordDialog({ data: { message: item } });
          }
        }
      };
    },
    [openViewDialog, whereabouts.hash]
  );

  const getPreviewRowLinkProps = React.useCallback(
    ({ item }): LinkProps => {
      const hash = whereabouts.hash;
      const query = whereabouts.query;

      return {
        hash,
        query,
        onClick: () => {
          const channelType = item?.channels?.[0]?.channel_type?.id;

          if (channelType === 'email') {
            openPreviewDialog({ data: { message: item } });
          }

          if (channelType === 'sms') {
            openPreviewSMSDialog({ data: { message: item } });
          }
        }
      };
    },
    [openPreviewDialog, whereabouts.hash]
  );

  const getMessagesQuery = React.useCallback(() => messageQuery, []);
  const getChannelMessageRecipientsQuery = React.useCallback(
    () => channelMessageRecipientQuery,
    []
  );

  const DefaultMessageTableEmptyState = useMessageTableEmptyState();

  const getOutboxBulkActions = useCallback(
    (args) => {
      if (!hasFeature(FLAGS.BULK_MESSAGE_ACTION)) {
        return [];
      }
      return [getBulkSendAction(args.selectedItems)] as Array<BulkAction>;
    },
    [getBulkSendAction]
  );

  const EmptyState = ({ label }) =>
    TabEmptyState ? (
      <TabEmptyState label={label} />
    ) : (
      <DefaultMessageTableEmptyState label={label} />
    );

  const tabs = React.useMemo(() => {
    const commonProps = {
      id: 'message',
      Table: ListTable,
      columns: channelMessageRecipientColumns,
      forcedGlobalFilters: [
        {
          field: 'channel_type_id',
          op: 'in',
          value: ['sms', 'email']
        }
      ],
      getFilters: channelMessageRecipientsFilters.getFilters,
      getSort: channelMessageRecipientsFilters.getSort,
      // Most of the tabs are actual message recipients, so we will set this on the common props
      getQuery: getChannelMessageRecipientsQuery,
      initialSortBy: [{ id: 'created_at', desc: true }],
      initialHiddenColumns: channelMessageRecipientHiddenColumns
        ? channelMessageRecipientHiddenColumns
        : []
    };

    return [
      {
        ...commonProps,
        name: 'outbox',
        label: 'Outbox',
        getQuery: getMessagesQuery,
        columns: messageColumns,
        getActionMenuItems: ({ item }) => getMessageOutboxActions(item),
        getBulkActions: getOutboxBulkActions,
        getRowLinkProps: getPreviewRowLinkProps,
        getFilters: messagesFilters.getFilters,
        getSort: messagesFilters.getSort,
        initialSortBy: [{ id: 'updated_at', desc: true }],
        forcedGlobalFilter: [
          ...commonProps.forcedGlobalFilters,
          {
            field: 'status_id',
            op: 'eq',
            value: { value: 'draft', label: 'Draft' }
          },
          {
            field: 'locked',
            op: 'eq',
            value: false
          },
          ...(forcedGlobalFilters ? forcedGlobalFilters : [])
        ],
        Empty: () => <EmptyState label={'Outbox'} />,
        initialHiddenColumns: messageHiddenColumns ? messageHiddenColumns : []
      },
      {
        ...commonProps,
        name: 'processing',
        label: 'Processing',
        getActionMenuItems: undefined,
        forcedGlobalFilter: [
          ...commonProps.forcedGlobalFilters,
          {
            field: 'delivery_status_id',
            op: 'eq',
            value: { value: 'pending', label: 'Pending' }
          },
          ...(forcedGlobalFilters ? forcedGlobalFilters : [])
        ],
        Empty: () => <EmptyState label={'Processing'} />
      },
      {
        ...commonProps,
        columns: messageColumnsBounced,
        name: 'bounced-messages',
        label: 'Bounced messages',
        forcedGlobalFilter: [
          ...commonProps.forcedGlobalFilters,
          {
            field: 'delivery_status_id',
            op: 'eq',
            value: { value: 'failed', label: 'Failed' }
          },
          {
            field: 'status_id',
            op: 'neq',
            value: { value: 'archived', label: 'Archived' }
          },
          ...(forcedGlobalFilters ? forcedGlobalFilters : [])
        ],
        getRowLinkProps: extractMessageFromItemForRowLinkProps(
          getViewRowLinkProps
        ),
        getActionMenuItems: ({ item }) => getMessageBouncedActions(item),
        Empty: () => <EmptyState label={'Bounced'} />
      },
      {
        ...commonProps,
        name: 'sent-all',
        label: 'Sent',
        getRowLinkProps: extractMessageFromItemForRowLinkProps(
          getViewRowLinkProps
        ),
        getActionMenuItems: ({ item }) => getMessageSentActions(item),
        forcedGlobalFilter: [
          ...commonProps.forcedGlobalFilters,
          {
            field: 'delivery_status_id',
            op: 'eq',
            value: { value: 'delivered', label: 'Delivered' }
          },
          ...(forcedGlobalFilters ? forcedGlobalFilters : [])
        ],
        Empty: () => <EmptyState label={'Sent'} />
      }
    ];
  }, [
    channelMessageRecipientsFilters.getFilters,
    channelMessageRecipientsFilters.getSort,
    getChannelMessageRecipientsQuery,
    getMessageBouncedActions,
    getMessageOutboxActions,
    getMessageSentActions,
    getMessagesQuery,
    getPreviewRowLinkProps,
    getViewRowLinkProps,
    messagesFilters.getFilters,
    messagesFilters.getSort
  ]);

  return <TabbedTable tabs={tabs} />;
}
