import React from 'react';

import { BlockConfig } from 'view/components/record-screen/types';
import { Field } from '@rexlabs/form';
import { PrepaymentBucket } from 'src/modules/prepayments/types/prepayment-bucket';
import { Grid } from 'view/components/@luna/form/grid';
import { ChartOfAccountsAccountSelect } from 'view/components/inputs/selects/v4-selects/chart-of-accounts-account';
import { Checkbox } from '@rexlabs/checkbox';
import InfoCircleIcon from 'view/components/icons/info';
import { PrepaymentBucketForm } from 'src/modules/prepayments/types/prepayment-bucket-form';
import WarningCircleIcon from 'view/components/icons/warning-circle';
import {
  InfoBanner,
  WarningBanner
} from 'view/components/@luna/notifications/banner';
import { Body, Bold } from '@rexlabs/text';

const HELP_ARTICLE_URL = 'https://rexpm.zendesk.com/hc/en-au';

const validate = {
  definitions: {
    chart_of_accounts_accounts: { rules: 'required', name: 'chart of accounts' }
  }
};

export const prepaymentChartOfAccountsBlock: BlockConfig<
  PrepaymentBucket,
  { isCreating: boolean },
  PrepaymentBucketForm
> = {
  id: 'prepayment-chart-of-accounts',
  title: 'Chart of accounts payable from this bucket',
  validate,
  Edit: ({ blockProps }) => {
    return (
      <Grid columns={1}>
        <Field
          id='chart_of_accounts'
          name='chart_of_accounts_accounts'
          label='Select chart of accounts'
          Input={ChartOfAccountsAccountSelect}
          inputProps={{ multi: true }}
        />

        <Field
          name='auto_allocate_funds'
          data-testid='auto-allocate-funds'
          optional={false}
          Input={Checkbox}
          inputProps={{
            label:
              'Automatically allocate funds to invoices using the specified chart of account(s)'
          }}
        />

        {!blockProps?.isCreating && (
          <WarningBanner
            data-testid='prepayment-edit-dialog-warning-banner'
            Icon={WarningCircleIcon}
            description={
              <>
                <Body>
                  Any modifications made to this prepayment bucket, including
                  adjustments to Chart of Accounts and preferences regarding
                  automatic fund allocation to invoices, will be applied to
                  invoices generated after the updates are implemented. Please
                  refer to{' '}
                  <a href={HELP_ARTICLE_URL} target='_blank' rel='noreferrer'>
                    the help centre
                  </a>{' '}
                  or reach out to{' '}
                  <a href={'mailto:support@rexpm.zendesk.com'}>
                    support@rexpm.zendesk.com
                  </a>{' '}
                  for additional assistance.
                </Body>
              </>
            }
          />
        )}

        <InfoBanner
          data-testid='prepayment-info-banner'
          Icon={InfoCircleIcon}
          description={
            <>
              <Body>
                Invoices will be automatically paid only if they exclusively
                match the Chart of Accounts payable defined in the prepayment
                bucket. For example, if you have set up a prepayment bucket
                specifically for Water Rates and have linked it to the Water
                Rates Chart of Accounts, any bill categorized solely under Water
                Rates will be automatically paid from this prepayment bucket by
                Rex PM. However, if a bill includes any additional Chart of
                Accounts, such as Utilities Expense along with Water Rates, Rex
                PM will not automatically allocate funds to pay the invoice.
              </Body>
              <Body>To summarise:</Body>
              <Body>
                <Bold>Automatic Payment Condition:</Bold> The invoice must only
                include items from the predefined Chart of Accounts in the
                prepayment bucket.
              </Body>
              <Body>
                <Bold>Example:</Bold> If you have a prepayment bucket for Water
                Rates and receive a bill solely for Water Rates, Rex PM will use
                the funds in the Water Rates prepayment bucket to pay it
                automatically.
              </Body>
              <Body>
                <Bold>Exclusion Scenario:</Bold> If the bill includes Water
                Rates and any other expense category, like Utilities Expense,
                the system will not automatically use the prepayment funds.
              </Body>
            </>
          }
        />
      </Grid>
    );
  }
};
