import { Contact } from 'src/modules/contacts/types/contact-types';
import { api } from './api-client';

type GetContactArgs = {
  id: number;
  includes?: Array<string>;
};

export const getContact = async ({ id, includes }: GetContactArgs) => {
  const includesString = includes?.length
    ? `include=${includes.join(',')}`
    : ``;

  const contact = await api.get<Contact>(`/contacts/${id}?${includesString}`);

  return contact.data;
};
