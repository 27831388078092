import { useConfirmationDialog } from '@rexlabs/dialog';
import { PropertyArea } from 'src/modules/property-areas/property-areas/types/PropertyArea';
import { ActionMenuItem } from '@rexlabs/action-menu';
import Box from '@rexlabs/box';
import React from 'react';
import { api } from 'utils/api/api-client';
import { useModelActions } from '@rexlabs/model-generator';
import { propertiesModel } from 'data/models/entities/properties';
import { useToast } from 'view/components/@luna/notifications/toast';

export function useGetDeletePropertyAreaAction() {
  const { open } = useConfirmationDialog();
  const { refreshItem } = useModelActions(propertiesModel);
  const { addToast } = useToast();

  return (
    propertyArea: PropertyArea,
    propertyId: string,
    areaOrder: Array<string>,
    updateAreaOrder: (newOrder: Array<string>) => void
  ): ActionMenuItem => {
    return {
      label: 'Delete',
      intent: 'danger',
      onClick: async () => {
        return open({
          title: 'Remove area',
          cancelText: 'No, keep this area',
          confirmText: 'Yes, remove area from property',
          message: (
            <Box flexDirection='column' gap={16}>
              <div>
                Please note that by proceeding, any report data created for this
                area can no longer be imported from previous inspection reports.
              </div>
              <div>Do you want to continue?</div>
            </Box>
          ),
          async onConfirm() {
            try {
              const newAreaOrder = areaOrder.filter(
                (areaId) => areaId != propertyArea.id
              );

              const response = await api.post('request-pipelines', {
                requests: [
                  {
                    id: `property_areas_delete`,
                    method: 'DELETE',
                    path: `/api/v1/properties/property-areas/${propertyArea.id}`
                  },
                  {
                    id: 'property_area_order',
                    method: 'PATCH',
                    path: `/api/v1/properties/${propertyId}`,
                    json: {
                      area_order: newAreaOrder
                    }
                  }
                ]
              });

              updateAreaOrder(newAreaOrder);

              addToast({
                description: `${propertyArea.name} deleted successfully.`,
                type: 'error'
              });

              await refreshItem({ id: propertyId });

              return response;
            } catch (err) {
              //
            }
          }
        });
      }
    };
  };
}
