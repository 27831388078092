import { query } from '@rexlabs/model-generator';
import { tenancyModel } from 'data/models/entities/tenancies';
import { financialsRentAdjustmentsModel } from 'data/models/entities/financials/rent/adjustments';
import { memoize } from 'lodash';
import { financialsRentDiscountsModel } from 'data/models/entities/financials/rent/discounts';

export const getTenancyQuery = (tenancyId: string) => query`{
  ${tenancyModel} (id: ${tenancyId}) {
    id
    name
    rent_amount
    frequency {
      id
      label
    }
    rent_arrears {
      amount
    }
    available_funds
    agreement_start_date
    agreement_end_date
    agreement_vacate_date
    agreement_signed_date
    paid_to_date
    surplus_amount
    tenants {
      guarantors
    }
    property_tenancies
    financial_summary
    trust_summary
    related_contacts
    created_by {
      contact
    }
    updated_by {
      contact
    }
  }
}`;

export const getRentAdjustmentsQuery = memoize(
  (tenancyId: (props: any) => string | string) => query`{
  ${financialsRentAdjustmentsModel} (tenancy_id: ${tenancyId}) {
    id
    amount
    from_date
    to_date
    tenancy_id
  }
}`
);

export const getRentDiscountsQuery = memoize(
  (tenancyId: (props: any) => string | string) => query`{
  ${financialsRentDiscountsModel} (tenancy_id: ${tenancyId}) {
    id
    amount
    from_date
    to_date
    tenancy_id
  }
}`
);
