import { Ownership } from 'data/models/entities/ownerships';
import { Tenancy } from 'data/models/entities/tenancies';
import { Contact } from 'src/modules/contacts/types/contact-types';
import { api } from 'utils/api/api-client';

export const FOLIO_CONFIG_MAP = {
  tenancy: {
    endpoint: 'tenancies',
    includes: 'tenants.contact'
  },
  ownership: {
    endpoint: 'ownerships',
    includes: 'owners.contact'
  }
};

type ContactPaymentReference = Contact & { payment_reference?: string };

export async function getRelatedRecords({
  folioId,
  folioType
}: {
  folioId: string;
  folioType: keyof typeof FOLIO_CONFIG_MAP;
}) {
  const config = FOLIO_CONFIG_MAP[folioType];
  const { data } = await api.get<Tenancy | Ownership>(
    `/${config.endpoint}/${folioId}?include=${config.includes},financial_contact`
  );

  const owners =
    'owners' in data ? (data.owners || []).map((owner) => owner.contact) : [];
  const tenants =
    'tenants' in data
      ? (data.tenants || []).map((tenant) => {
          return {
            ...tenant.contact,
            payment_reference: tenant.payment_reference
          };
        })
      : [];

  const otherContacts = [...owners, ...tenants];

  const financialContactPaymentReference = otherContacts.find(
    (contact) => contact.id === data.financial_contact?.id
  ) as ContactPaymentReference;

  const financialContact = {
    ...data.financial_contact,
    payment_reference: financialContactPaymentReference?.payment_reference
  };

  return {
    otherContacts,
    tenants: 'tenants' in data ? data.tenants || [] : [],
    financial_contact: financialContact
  };
}
