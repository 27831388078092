import React from 'react';

import { CellProps } from '@rexlabs/table';

import ReceiptIcon from 'view/components/icons/receipt';
import { IconActionCell } from 'src/modules/common/components/cells/icon-action-cell';
import { useBulkModelDownloadAction } from 'src/modules/common/actions/reporting/use-bulk-model-download-action';
import { appliancesModel } from 'src/modules/appliances/models/appliances-model';
import { useModelActions } from '@rexlabs/model-generator';

export function ReceiptsCell({ value }: CellProps) {
  const modelBulkDownloadAction = useBulkModelDownloadAction();
  const { bulkDownloadWarranties } = useModelActions(appliancesModel);

  const bulkDownloadAction = modelBulkDownloadAction({
    fetcher: () => {
      return bulkDownloadWarranties({
        data: value?.warranties?.data.map((warranty) => {
          return { id: warranty.id, type: warranty.type };
        }),
        applianceId: value.id
      });
    },
    label: 'Download all'
  });

  return value?.warranties?.data?.length ? (
    <IconActionCell
      Icon={ReceiptIcon}
      onClick={() => bulkDownloadAction.handleAction!()}
      tooltipLabel='Download warranty and receipt details'
    ></IconActionCell>
  ) : (
    <div style={{ display: 'flex', justifyContent: 'center' }}>--</div>
  );
}
