import * as React from 'react';

import { DialogProps } from '@rexlabs/dialog';

import { RecordDialog } from 'view/components/record-screen/dialog/dialog';
import { RecordSubmitHandler } from 'view/components/record-screen/utils';

import { AddQuoteDialogButtonGroup } from '../components/add-quote-dialog-button-group';
import { taskDateBlock } from '../../common/blocks/task-date-block';
import { taskPriorityBlock } from '../../common/blocks/task-priority-block';
import { detailsBlock } from '../blocks/details';
import { relatedImagesBlock } from '../blocks/related-images-block';
import { quoteDetailsBlock } from '../blocks/quote-details-block';
import { CreateQuoteFormData } from '../types/CreateQuoteFormData';
import { checklistTemplateSelectBlock } from '../../common/blocks/checklist-template-select-block';
import { useTaskTypesWithCustomAddToRecord } from '../../settings/hooks/use-task-types-with-custom-add-to-record';

export interface CreateQuoteDialogProps extends DialogProps {
  initialValues?: Partial<CreateQuoteFormData>;
  handleSubmit: RecordSubmitHandler<Partial<CreateQuoteFormData>>;
}

export function CreateQuoteDialog({
  onClose,
  initialValues,
  handleSubmit,
  target
}: CreateQuoteDialogProps) {
  const taskTypesWithCustomAddToRecord = useTaskTypesWithCustomAddToRecord();

  const content = React.useMemo(
    () => [
      {
        id: 'add-quote',
        label: 'Add Quote',
        blocks: [
          { ...detailsBlock, title: '' },
          { ...relatedImagesBlock, title: 'Add image(s)' },
          quoteDetailsBlock,
          ...(taskTypesWithCustomAddToRecord.includes('task_quote')
            ? [checklistTemplateSelectBlock]
            : []),
          ...(!initialValues?.parent_task ? [taskPriorityBlock] : []),
          taskDateBlock
        ]
      }
    ],
    []
  );

  return (
    <RecordDialog
      title='Add quote'
      data={initialValues}
      initialValues={{
        ...initialValues,
        details: {
          images: [],
          quote_attachments: [],
          has_received_quote: false,
          ...initialValues?.details
        },
        priority: initialValues?.priority || { id: 'normal', label: 'Normal' }
      }}
      blockProps={{ taskTypeId: 'task_quote' }}
      content={content}
      onClose={onClose}
      handleSubmit={handleSubmit}
      ButtonGroup={AddQuoteDialogButtonGroup}
      target={target}
    />
  );
}
