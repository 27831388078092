import React from 'react';
import { Card } from 'view/components/card';
import ROUTES from 'routes/app';
import { ListScreen } from 'view/components/list-screen/list-screen';
import { ContactsTable } from 'src/modules/contacts/components/contacts-table';

export function ContactsListScreen() {
  return (
    <ListScreen
      title='Contacts'
      createLabel='Create contact'
      createLinkProps={{ to: ROUTES.CONTACTS_LIST.CREATE }}
      recordType='contact'
    >
      <Card>
        <ContactsTable />
      </Card>
    </ListScreen>
  );
}
