import React, { useCallback } from 'react';
import { DialogProps } from '@rexlabs/dialog';
import { useModelActions } from '@rexlabs/model-generator';

import {
  Tenancy,
  tenancyModel,
  TenantsForm
} from 'data/models/entities/tenancies';
import { RecordDialog } from 'view/components/record-screen/dialog/dialog';
import { RecordSubmitHandler } from 'view/components/record-screen/utils';

import { useRecordCreatedToast } from 'src/modules/common/toasts/hooks/use-record-created-toast';
import { useMapTenancyFormToApi } from 'src/modules/tenancies/mappers/use-map-tenancy-form-to-api';
import { tenantEditBanner } from 'src/modules/tenancies/components/tenant-edit-banner';
import { getTenantBlock } from '../blocks/tenant-block';

const tenantBlock = getTenantBlock(tenantEditBanner());

const content = [
  {
    id: 'create-tenancy',
    label: 'Create Tenancy',
    blocks: [tenantBlock]
  }
];

export interface CreateTenancyDialogProps extends DialogProps {
  onCreate?: (tenancy: Tenancy) => void;
}

export function CreateTenancyDialog({
  onClose,
  onCreate
}: CreateTenancyDialogProps) {
  const { createItem, refreshLists } = useModelActions(tenancyModel);
  const addToast = useRecordCreatedToast(tenancyModel);

  const mapTenancyFormToApi = useMapTenancyFormToApi();
  const handleSubmit: RecordSubmitHandler<TenantsForm> = useCallback(
    async ({ values }) => {
      const { data: tenancy } = await createItem({
        data: mapTenancyFormToApi(values)
      });

      onCreate?.(tenancy);

      addToast(tenancy);

      await refreshLists();

      return tenancy;
    },
    [addToast, createItem, onCreate]
  );

  return (
    <RecordDialog
      title='Create tenancy'
      onClose={onClose}
      content={content}
      handleSubmit={handleSubmit}
    />
  );
}
