import React from 'react';
import { StyleSheet, useStyles } from '@rexlabs/styling';

const styles = StyleSheet({
  truncated: {
    '-webkit-line-clamp': 'var(--line-clamp, 2)',
    display: '-webkit-box',
    '-webkit-box-orient': 'vertical',
    overflow: 'hidden',
    whiteSpace: 'pre-line'
  }
});

/**
 * You can use this component to display a large description in a cell for example, you may have
 * a large string that would normally be truncated, but you want it to run over multiple lines. You can
 * add extra lines by passing a number to the lineClamp prop.
 */
export function ClampedDescriptionCell({
  text,
  lineClamp = 2
}: {
  text: string;
  lineClamp?: number;
}) {
  const s = useStyles(styles);

  return (
    <span
      {...s('truncated')}
      title={text}
      style={{ '--line-clamp': lineClamp } as React.CSSProperties}
    >
      {text ?? '--'}
    </span>
  );
}
