import React from 'react';
import { Contact } from 'src/modules/contacts/types/contact-types';
import { ContactFlag } from 'view/components/record-flags/contact-flag';
import { EntryLink } from '../components/entry-link';
import { EntryList } from '../components/entry-list';
import { EmptySection } from '../empty/base';

export interface ContactSectionProps {
  contact: Contact | 'empty';
}

export function ContactSection({ contact }: ContactSectionProps) {
  if (contact === 'empty') {
    return (
      <EmptySection headline='' buttonLabel=''>
        There is no contact related to this record.
      </EmptySection>
    );
  }
  return (
    <EntryList>
      <EntryLink type='contact' id={contact.id}>
        <ContactFlag contact={contact} />
      </EntryLink>
    </EntryList>
  );
}
