import { RentPaidToValues } from 'src/modules/settings/hooks/useSettings';
import { RentPosition } from '../types/property-tenancy-types';
import {
  getPaidToDateDescription,
  getPaidToPeriodDescription
} from './get-rent-position-description';

export function getFormattedRentPaidToDescription({
  rentPosition,
  paidToDateSetting,
  dateFormat = 'D MMM YYYY'
}: {
  rentPosition: Partial<RentPosition> | undefined;
  paidToDateSetting: RentPaidToValues;
  dateFormat?: string;
}) {
  const paidToDateDescription =
    getPaidToDateDescription({ rentPosition, dateFormat }) || '';
  const paidToPeriodDescription =
    getPaidToPeriodDescription({ rentPosition, dateFormat }) || '';

  if (paidToDateSetting === 'effective_and_periodic_with_surplus') {
    return `${paidToDateDescription} ${paidToPeriodDescription}`;
  }
  if (paidToDateSetting === 'effective_with_surplus') {
    return paidToDateDescription;
  }

  return paidToPeriodDescription;
}
