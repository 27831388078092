import * as React from 'react';
import { InputProps } from '@rexlabs/form';
import { BaseFields, TimestampAttributes } from 'data/models/types';
import { AllRecordItemsSelect } from 'view/components/inputs/selects/v4-selects/all-record-items-select';

export interface ChangeReason
  extends BaseFields<'change_reason'>,
    TimestampAttributes {
  id: string;
  name: string;
  change_reason_type: {
    id: string;
    label: string;
  };
}

export function ChangeReasonSelect(props: InputProps) {
  const normaliser = React.useCallback(
    (item) => ({
      id: item.id,
      label: item.name,
      type: item.change_reason_type.id,
      heading: item.change_reason_type.label
    }),
    []
  );

  return (
    <AllRecordItemsSelect<ChangeReason>
      {...props}
      endpoint='change-reasons'
      normaliser={normaliser}
      id='change-reasons-select'
    />
  );
}
