import React from 'react';
import { Toolbar, ToolbarProps } from '@rexlabs/table';
import { TextInput } from '@rexlabs/text-input';

import { DateInput } from 'view/components/@luna/inputs/date-input/date-input';

import { ValueListSelect } from './inputs/value-list-select';
import { RecordSelect } from './inputs/record-select';
import { BooleanInput } from './inputs/boolean-input';
import { CurrencyInput } from './inputs/currency-input';
import { Dropdown } from './inputs/dropdown';
import { TimestampDateInput } from './inputs/timestamp-date-input';

const filterInputs = {
  text: TextInput,
  currency: CurrencyInput,
  boolean: BooleanInput,
  date: DateInput,
  timestamp: TimestampDateInput,
  dropdown: Dropdown,
  valueListSelect: ValueListSelect,
  recordSelect: RecordSelect
};

function AlfredToolbar(props: ToolbarProps) {
  return <Toolbar {...props} filterInputs={filterInputs} />;
}

export { AlfredToolbar as Toolbar };
