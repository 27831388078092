import { ActionMenuItem } from '@rexlabs/action-menu';
import { transformActionDeclarationsToActionMenuItems } from 'utils/actions/transforms';
import { propertiesModel } from 'data/models/entities/properties';

import { useGetPrimaryRecordActionGroup } from 'view/hooks/actions/use-get-primary-record-action-group';

import { ActionDeclaration } from 'src/modules/common/actions/types/action-declaration-types';
import { useGetAddDocumentsAction } from 'src/modules/documents/actions/use-get-add-documents-action';
import { useGetAddNotesAction } from 'src/modules/tasks/common/actions/get-add-notes-action';
import { useGetCreateEmailAction } from 'src/modules/communications/messages/hooks/action-declarations/use-get-create-email-action';
import { useGoToLeaseAgreementAction } from 'src/modules/tasks/lease-review/actions/use-go-to-lease-agreement-action';
import { useGetToggleClosedAction } from 'src/modules/tasks/common/hooks/action-declarations/use-get-toggle-closed-action';
import { useGetCreateSMSAction } from 'src/modules/communications/messages/hooks/action-declarations/use-get-create-sms-action';
import { LeaseReview } from '../../types/LeaseReview';

export function useGetLeaseReviewActions() {
  const getPrimaryRecordActionGroup = useGetPrimaryRecordActionGroup();
  const getCreateEmailAction = useGetCreateEmailAction(propertiesModel);
  const getCreateSMSAction = useGetCreateSMSAction(propertiesModel);
  const getAddDocumentsAction = useGetAddDocumentsAction();
  const getAddNotesAction = useGetAddNotesAction();
  const getToggleClosedAction = useGetToggleClosedAction();
  const getLeaseAgreementAction = useGoToLeaseAgreementAction();

  return (task?: LeaseReview): ActionMenuItem[] => {
    if (!task) return [];

    const goToLeaseAgreementActionGroup: ActionDeclaration[] = [];
    const leaseAgreementAction = getLeaseAgreementAction({ data: task });
    if (task?.property?.active_property_tenancy?.id && leaseAgreementAction) {
      goToLeaseAgreementActionGroup.push(leaseAgreementAction);
    }

    const actions = [
      ...getPrimaryRecordActionGroup('task_lease_review', task.id),
      getToggleClosedAction(task),
      getCreateEmailAction(task.property),
      getCreateSMSAction(task.property),
      getAddDocumentsAction({ data: task }),
      getAddNotesAction({ data: task }),
      ...goToLeaseAgreementActionGroup
    ];

    return transformActionDeclarationsToActionMenuItems(actions);
  };
}
