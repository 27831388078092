import React from 'react';
import { EmptySection } from './base';

export function EmptyPortfolios() {
  return (
    <EmptySection headline='' buttonLabel=''>
      This user is not a member of any portfolios
    </EmptySection>
  );
}
