import { Checkbox } from '@rexlabs/checkbox';
import { Field } from '@rexlabs/form';
import { TextInput } from '@rexlabs/text-input';
import React, { useEffect } from 'react';

import { ChartOfAccountsAccount } from 'src/modules/chart-of-accounts/types/chart-of-account-types';
import { useSessionState } from 'src/modules/common/hooks/session-state';
import { Grid } from 'view/components/@luna/form/grid';
import { FinancialsChartOfAccountsCategory } from 'view/components/inputs/selects/financials-chart-of-accounts-category';
import { TaxTypeSelect } from 'view/components/inputs/selects/tax-type';
import { BlockConfig } from 'view/components/record-screen/types';
import { FLAGS } from 'utils/feature-flags';
import { useFeatureFlags } from 'view/components/@luna/feature-flags';
import { getTaxableAccountCheckboxConfig } from '../utils/get-taxable-account-checkbox-config';

export const createChartOfAccountsAccountsBlock: BlockConfig<
  ChartOfAccountsAccount,
  { disableAccountCategory: boolean }
> = {
  id: 'createChartOfAccountsAccount',
  title: 'Chart of accounts account',
  validate: {
    definitions: {
      name: { rules: 'required' },
      account_category: { rules: 'required' },
      default_tax_type: { rules: 'required' }
    }
  },
  Edit: ({ values, blockProps, setFieldValue }) => {
    const { activeSilo } = useSessionState();
    const { hasFeature } = useFeatureFlags();

    const taxableCheckboxConfig = getTaxableAccountCheckboxConfig(
      values?.account_category?.id
    );

    // If the account category is income or expense, set the income taxable to false
    useEffect(() => {
      ['income', 'expense'].includes(values.account_category?.id) &&
        [undefined, null].includes(values.is_income_taxable) &&
        setFieldValue?.('is_income_taxable', false);
    }, [setFieldValue, values.account_category?.id, values.is_income_taxable]);

    return (
      <>
        <Grid columns={1}>
          <Grid columns={2}>
            <Field name='name' label='Name' Input={TextInput} />
            <Field name='description' label='Description' Input={TextInput} />
            <Field
              name='account_category'
              id='account_category'
              label='Category'
              Input={FinancialsChartOfAccountsCategory}
              inputProps={{
                disabled:
                  !!values?.parent_chart_of_accounts_account ||
                  blockProps?.disableAccountCategory
              }}
              HelpTooltipContent={
                values?.parent_chart_of_accounts_account
                  ? () => 'Taken from parent'
                  : undefined
              }
              optional={false}
            />
            <Field
              name='default_tax_type'
              label='Tax type'
              Input={TaxTypeSelect}
              optional={false}
            />
          </Grid>
          {!!(
            hasFeature(FLAGS.WITHHOLDING_TAX) &&
            activeSilo?.country?.id === 'GBR' &&
            taxableCheckboxConfig
          ) && (
            <Grid columns={1}>
              <Field<typeof Checkbox>
                name='is_income_taxable'
                Input={Checkbox}
                description={taxableCheckboxConfig.helperText}
                optional={false}
                inputProps={{
                  label: taxableCheckboxConfig.label
                }}
              />
            </Grid>
          )}
        </Grid>
      </>
    );
  }
};
