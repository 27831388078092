import { UserFormData } from 'src/modules/users/mappers/map-user-to-form-data';

export function mapUserFormDataToUpdateRequest(
  changedValues: Partial<UserFormData>,
  initialData?: UserFormData
) {
  return {
    // the values are required by the API
    given_name: initialData?.given_name,
    family_name: initialData?.family_name,
    email: initialData?.email,
    ...changedValues
  };
}
