import Box from '@rexlabs/box';
import React, { useEffect } from 'react';
import { Banner } from 'src/modules/common/components/banner';
import { useTrustLedger } from 'src/modules/financials/utils/use-trust-ledger';
import { Grid } from 'view/components/@luna/form/grid';
import InfoCircleIcon from 'view/components/icons/info';
import { BlockConfig } from 'view/components/record-screen/types';
import { CurrencyValue } from 'view/components/values/currency';
import { t } from 'i18next';

export const withdrawalInfoBlock: BlockConfig<
  any,
  { showBannerMessage: boolean }
> = {
  id: 'withdrawalInfo',
  Edit: ({ values, forms, setFieldValue, blockProps }) => {
    const detailValues = forms?.['withdrawalDetails']?.values;

    const folio = detailValues?.source?.ledger?.object ?? undefined;
    const bankAccount = detailValues?.bank_account ?? undefined;
    const amount = detailValues?.amount ?? 0;

    forms?.['withdrawalDetails'];
    const { trustLedger } = useTrustLedger(folio, bankAccount);

    useEffect(() => {
      setFieldValue?.(
        'available_amount',
        trustLedger?.financial_summary?.available_balance_amount || 0
      );
    }, [trustLedger]);

    const runningAmount = values.available_amount - amount;

    return (
      <Box sy='2rem'>
        <Grid columns={3}>
          <CurrencyValue
            label='Available Balance'
            value={values.available_amount}
          />

          <CurrencyValue label='Running Balance' value={runningAmount} />
        </Grid>

        {blockProps?.showBannerMessage ? (
          <Banner intent='warning' Icon={InfoCircleIcon}>
            <div>
              {t('trust-journal-entries.blocks.withdrawal-info')} For more
              information, please see this{' '}
              <a
                href='https://rexpm.zendesk.com/hc/en-au/articles/18429024603801-Processing-Disbursements'
                target='_blank'
                rel='noreferrer'
              >
                help article
              </a>
              .
            </div>
          </Banner>
        ) : null}
      </Box>
    );
  }
};
