import React, { useCallback } from 'react';

import { RecordSubmitHandler } from 'view/components/record-screen/utils';
import { useModelActions } from '@rexlabs/model-generator';
import { DialogContentConfig } from 'view/components/record-screen/dialog/types';
import { RecordDialog } from 'view/components/record-screen/dialog/dialog';
import { propertyOwnershipsFeesInactiveTemplatesModel } from 'data/models/entities/financials/property-ownerships/inactive-property-ownership-fees';
import { AgencyFee } from 'src/modules/agency-fees/types/agency-fee-types';
import { useTranslation } from 'react-i18next';
import { inactivePropertyOwnershipFeesBlock } from '../blocks/inactive-property-ownership-fees';

const content: DialogContentConfig = [
  {
    id: 'property-ownership-fee',
    label: '',
    blocks: [inactivePropertyOwnershipFeesBlock]
  }
];

interface AddPropertyOwnershipFeeDialogProps {
  onClose?: () => void;
  onAdd?: (addedAgencyFees: AgencyFee[]) => Promise<void>;
  propertyOwnershipId: string;
  refreshListId?: string;
}

export function AddPropertyOwnershipFeeDialog({
  onClose,
  onAdd,
  propertyOwnershipId
}: AddPropertyOwnershipFeeDialogProps) {
  const { t } = useTranslation();

  const { createNewFee } = useModelActions(
    propertyOwnershipsFeesInactiveTemplatesModel
  );

  const handleSubmit: RecordSubmitHandler = useCallback(
    async ({ values }) => {
      const inactivePropertyOwnershipFees = values.inactive_property_ownership_fees as AgencyFee[];
      const feesPromiseArray = inactivePropertyOwnershipFees.map((fee) =>
        createNewFee({
          agencyFeeId: fee.id,
          propertyOwnershipId
        })
      );

      const results = await Promise.all(feesPromiseArray);

      onAdd && (await onAdd(inactivePropertyOwnershipFees));

      return results;
    },
    [createNewFee, propertyOwnershipId]
  );

  return (
    <RecordDialog
      onClose={onClose}
      title={t(
        'property-ownerships.management-agreement-fees.add-fees.label.plural'
      )}
      handleSubmit={handleSubmit}
      content={content}
      blockProps={{
        propertyOwnershipId
      }}
    />
  );
}
