import React from 'react';
import { Ownership } from 'data/models/entities/ownerships';
import { RecordFlag } from 'view/components/record-flags/record-flag';
import { EntryList } from '../components/entry-list';
import { EntryLink } from '../components/entry-link';
import { EmptyOwnership } from '../empty/empty-ownership';

export interface OwnershipsSectionProps {
  ownerships: Ownership[] | 'empty';
  maxListLength: number;
}

export function OwnershipsSection({ ownerships }: OwnershipsSectionProps) {
  if (ownerships === 'empty' || ownerships.length === 0) {
    return <EmptyOwnership />;
  }

  return (
    <EntryList>
      {ownerships.map((ownership) => (
        <EntryLink key={ownership.id} type='ownership' id={ownership.id}>
          <RecordFlag record={ownership} recordType='ownership' />
        </EntryLink>
      ))}
    </EntryList>
  );
}
