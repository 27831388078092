import React, { useCallback, useMemo } from 'react';
import dayjs from 'dayjs';
import { InputProps } from '@rexlabs/form';
import Box from '@rexlabs/box';
import { TextInput } from '@rexlabs/text-input';

interface TimeInputProps extends InputProps {
  min?: string;
  max?: string;
  steps?: number;
}

function formatTime(date) {
  // Always normalise value into ISO8061
  // HACK: the time input includes the time zone, dayjs messes things
  // up in the format method with that, so we remove the time zone offset
  // here
  return date
    ? dayjs(date.getTime() + date.getTimezoneOffset() * 60000).format(
        'HH:mm:ss'
      )
    : null;
}

export function TimeInput({
  min = '00:00',
  max = '23:59',
  steps = 60,
  onChange,
  onBlur,
  value,
  ...props
}: TimeInputProps) {
  const onTimeChange = useCallback(
    ({ target }) => {
      onChange?.({
        target: {
          value: formatTime(target.valueAsDate)
        }
      });
    },
    [onChange]
  );

  const onTimeBlur = useCallback(
    ({ target }) => {
      onBlur?.({
        target: {
          value: formatTime(target.valueAsDate)
        }
      });
    },
    [onChange]
  );

  const timeValue = useMemo(() => {
    const [hours, minutes, seconds] = value?.split?.(':') || [];
    return dayjs()
      .set('hour', hours)
      .set('minute', minutes)
      .set('second', seconds)
      .format(steps < 60 ? 'HH:mm:ss' : 'HH:mm');
  }, [steps, value]);

  return (
    <Box flex={0}>
      <TextInput
        type='time'
        min={min}
        max={max}
        steps={steps}
        onChange={onTimeChange}
        onBlur={onTimeBlur}
        value={timeValue}
        {...props}
      />
    </Box>
  );
}
