import {
  ChecklistTemplateItem,
  ChecklistTemplateItemGroup
} from '../types/ChecklistTemplate';

export const placeholderPrefix = 'placeholder-item-';

export function getGroupPlaceholderPrefix(
  checklistTemplateItem: Partial<ChecklistTemplateItem>
) {
  return `${checklistTemplateItem.id}-placeholder-group`;
}

export function injectGroupsForOutcomeItem(
  checklistTemplateItem: Partial<ChecklistTemplateItem>,
  setFieldValue?: (field: string, value: unknown) => void
): Partial<ChecklistTemplateItemGroup>[] | null {
  if (checklistTemplateItem.has_outcome && checklistTemplateItem.outcome_data) {
    const groupsToAdd: Partial<ChecklistTemplateItemGroup>[] =
      checklistTemplateItem.outcome_data?.outcome_options?.map((outcome) => {
        return {
          // fake an id here that will be replaced in the request pipeline
          id: `${getGroupPlaceholderPrefix(checklistTemplateItem)}-${
            outcome.id
          }`,
          triggered_by_value: outcome.id,
          triggered_by_checklist_template_item: checklistTemplateItem
        } as Partial<ChecklistTemplateItemGroup>;
      }) ?? [];

    let fullGroups: ChecklistTemplateItemGroup[] = [];
    setFieldValue?.('checklist_template_item_groups', (oldGroups) => {
      const newGroups = groupsToAdd.filter(
        (group) =>
          !oldGroups.some(
            (oldGroup) =>
              oldGroup.triggered_by_value === group.triggered_by_value
          )
      );

      // We keep the old groups around, so they can be deleted in the pipeline request
      fullGroups = [...oldGroups, ...newGroups];
      return fullGroups;
    });

    // if setFieldValue is not provided, return the new groups, as this is probably an empty-state create op.
    return fullGroups.length > 0 ? fullGroups : groupsToAdd;
  }
  return null;
}
