import { transformActionDeclarationsToActionMenuItems } from 'utils/actions/transforms';

import { Note } from '../types/Note';

import { useGetViewNoteAction } from './action-declarations/use-get-view-note-action';

export function useGetReadOnlyNoteActions() {
  const getViewNoteAction = useGetViewNoteAction();

  return (note?: Note) => {
    if (!note) return [];

    const viewNoteAction = getViewNoteAction(note, false, false);

    return [...transformActionDeclarationsToActionMenuItems([viewNoteAction])];
  };
}
