import React from 'react';
import Box from '@rexlabs/box';
import { Checkbox } from '@rexlabs/checkbox';

const items = [
  { id: 'email', label: 'Email' },
  { id: 'sms', label: 'SMS' },
  { id: 'letter', label: 'Letter' }
];

export function ContactPreferencesCheckboxGroup({
  value = [],
  onChange,
  onBlur
}: {
  value: any[];
  onChange: (event?: any) => void;
  onBlur: (event?: any) => void;
}) {
  return (
    <Box flexDirection='row' alignItems='center' sx='1.6rem'>
      {items.map((item) => (
        <Checkbox
          value={value?.find?.((v) => v.id === item.id)}
          name={item.id}
          label={item.label}
          key={item.id}
          onChange={(e) => {
            const newValue = e.target.value
              ? [...(value || []), item]
              : value?.filter?.((v) => v.id !== item.id);
            onChange({ target: { value: newValue } });
            onBlur({ target: { value: newValue } });
          }}
        />
      ))}
    </Box>
  );
}
