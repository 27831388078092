import React, { useMemo } from 'react';
import { query } from '@rexlabs/model-generator';
import { useWhereabouts } from '@rexlabs/whereabouts';

import { BlockConfig } from 'view/components/record-screen/types';

import { RecordTaskTabbedTable } from 'src/modules/tasks/common/components/record-task-tabbed-table';
import { tasksModel } from 'src/modules/tasks/common/models/tasks-model';

import { Property } from 'src/modules/properties/types/property-types';
import { useTaskColumns } from 'src/modules/tasks/common/hooks/use-task-list-columns';
import { ActionButtons } from 'view/components/action-buttons';
import { useDialog } from '@rexlabs/dialog';
import { CreateTaskDialog } from 'src/modules/tasks/common/dialogs/create-task-dialog';
import { PropertyTaskTableEmptyState } from '../components/property-task-table-empty-state';

export const getFilterByPropertyId = (propertyId: string) => {
  return [
    {
      field: 'property_id',
      op: 'eq',
      value: propertyId
    }
  ];
};

const getPropertyTasksQuery = () => query`{
  ${tasksModel} {
    id
    property {
      active_property_tenancy
    }
    managed_by
    details
    leaseReviewDetails
    maintenanceDetails
    inspectionDetails
    moveInDetails
    moveOutDetails
    arrearsDetails
    propertyComplianceDetails
    task_links
  }
}`;

export const propertyTasksBlock: BlockConfig<Property> = {
  id: 'tasks',
  title: 'Tasks',

  View: ({ data }) => {
    const whereabouts = useWhereabouts();
    const tab = whereabouts.hashQuery?.tab;

    const filter = useMemo(() => getFilterByPropertyId(data!.id), [data!.id]);
    const columns = useTaskColumns(tab);

    return (
      <RecordTaskTabbedTable
        key={tab}
        getQuery={getPropertyTasksQuery}
        columns={columns}
        filters={filter}
        EmptyState={PropertyTaskTableEmptyState}
      />
    );
  },
  Actions: ({ data }) => {
    const { open: openCreateTaskDialog } = useDialog(CreateTaskDialog);

    if (!data) {
      return null;
    }

    return (
      <ActionButtons
        actions={[
          {
            label: 'Create task',
            onClick: () => {
              openCreateTaskDialog({
                initialPropertyValue: data
              });
            }
          }
        ]}
      />
    );
  }
};
