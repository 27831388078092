import * as React from 'react';
import { useWhereabouts } from '@rexlabs/whereabouts';
import { RecordCell } from '@rexlabs/table';
import { Columns } from 'src/view/components/table';
import { Invoice } from 'data/models/entities/financials/invoices';
import ROUTES from 'routes/app';
import { LinkButton } from '@rexlabs/button';
import { StyleSheet, useStyles } from '@rexlabs/styling';
import { useDialog } from '@rexlabs/dialog';
import { ViewDocumentDialog } from 'src/modules/documents/dialogs/view-document-dialog';

const styles = StyleSheet({
  button: {
    color: ({ token }) => token('color.textStyle.body.default')
  }
});

export const useBillProcessingColumns = (): Columns => {
  const whereabouts = useWhereabouts();
  const tab = whereabouts.hashQuery?.tab;
  const isProcessed = tab === 'processed';

  return [
    {
      id: 'file',
      Header: 'File name',
      accessor: (item) => item.file,
      Cell({ value }) {
        const s = useStyles(styles);

        const viewDocumentDialog = useDialog(ViewDocumentDialog);

        return (
          <LinkButton
            {...s('button')}
            onClick={() => {
              viewDocumentDialog.open({
                fileName: value.name,
                fileType: value.type,
                fileUrl: value.url
              });
            }}
          >
            {value.name}
          </LinkButton>
        );
      }
    },
    ...(isProcessed
      ? [
          {
            id: 'bill_processing_invoice',
            Header: 'Linked invoice',
            width: 400,
            accessor: (item) => item.invoice,
            Cell: InvoiceCell
          }
        ]
      : []),
    {
      id: 'file.size',
      Header: 'File size',
      type: 'fileSize',
      width: 110
    },
    {
      id: 'file.created_at',
      Header: 'Date uploaded',
      type: 'date',
      width: 140
    }
  ];
};

function InvoiceCell(props) {
  const { value } = props;

  return (
    <RecordCell
      value={`${value.record_reference} ${value.description ?? ''}`}
      cell={{ value }}
      column={{
        cellProps: {
          getCellLinkProps: (value: Invoice) => {
            return {
              to: ROUTES.INVOICE,
              params: {
                invoiceId: value.id
              }
            };
          }
        }
      }}
    />
  );
}
