import { Forms, ReactForms, Form } from '@rexlabs/form';
import React, { ComponentType, useState, useEffect, useMemo } from 'react';

import { Box } from '@rexlabs/box';
import RenderLoading from 'view/components/@luna/render-loading';
import { LoadingView } from '@rexlabs/table';
import {
  StyleSheet,
  StylesProvider,
  useStyles,
  margin,
  padding
} from '@rexlabs/styling';
import { Heading } from '@rexlabs/text';
import { formGridTokens } from 'view/components/@luna/grid/form';
import { DialogButtonGroup } from 'view/components/dialogs/buttons/dialog-button-group';
import { getMergedMultiFormValues } from '../get-merged-multi-form-values';
import { GetHandlers } from '../types';
import { ButtonGroupProps, StepConfig } from './types';

export interface FormContentProps<BtnProps = any>
  extends Pick<
    ButtonGroupProps,
    'isSubmitting' | 'submitLabel' | 'SubmitButton'
  > {
  step: StepConfig;
  handleSubmit: () => Promise<any> | any;
  resetAll: (newValues?: any) => void;
  blockProps?: any;
  data?: any;
  initialValues?: any;
  forms?: Forms;
  getHandlers?: GetHandlers;
  onClose?: () => void;
  footerLeft?: React.ReactNode;
  ButtonGroup?: ComponentType<ButtonGroupProps & BtnProps>;
  buttonGroupProps?: BtnProps;
}

const defaultStyles = StyleSheet({
  stepGreyBackground: {
    ...margin.styles({
      x: ({ token }) => `-${token('spacing.xxxl')}`
    }),
    ...padding.styles({
      x: 'xxxl',
      top: 'xl'
    }),

    background: ({ token }) => token('palette.grey.200'),

    '&:first-child': {
      ...padding.styles({
        top: 'xl'
      })
    },

    '&:last-child': {
      marginBottom: '-2rem',

      ...padding.styles({
        bottom: 'xl'
      })
    }
  },
  block: {
    paddingBottom: '2.4rem',

    '&:first-child': {
      paddingTop: 0
    },

    '&:last-child': {
      paddingBottom: 0
    }
  },
  title: {
    ...margin.styles({
      bottom: 'xl'
    })
  }
});

function Block({
  hasGreyBackground,
  validate,
  initialValues,
  title,
  data,
  blockProps,
  showTitle,
  id,
  Edit,
  forms,
  getHandlers
}) {
  const s = useStyles(defaultStyles);

  return (
    <Box
      key={id}
      data-testid={`${id}-block`}
      {...s('block', {
        stepGreyBackground: hasGreyBackground
      })}
    >
      <ReactForms id={id} validate={validate} initialValues={initialValues}>
        {({ values, setValues, setFieldValue }) => (
          <Form>
            {showTitle && (
              <Box {...s('title')}>
                <Heading level={3}>
                  {typeof title === 'function'
                    ? title({ data, values, blockProps })
                    : title}
                </Heading>
              </Box>
            )}
            <StylesProvider tokens={formGridTokens}>
              <Edit
                data={data}
                values={values}
                setValues={setValues}
                setFieldValue={setFieldValue}
                forms={forms}
                blockProps={blockProps}
                getHandlers={getHandlers}
              />
            </StylesProvider>
          </Form>
        )}
      </ReactForms>
    </Box>
  );
}

export function FormContent({
  onClose,
  isSubmitting,
  data,
  initialValues,
  forms,
  handleSubmit,
  submitLabel,
  resetAll,
  SubmitButton,
  step,
  blockProps,
  getHandlers: getHandlers,
  ButtonGroup = DialogButtonGroup,
  buttonGroupProps,
  footerLeft
}: FormContentProps) {
  const [isPreloading, setIsPreloading] = useState(false);

  useEffect(() => {
    if (step?.preload) {
      setIsPreloading(true);

      const mergedFormValues = getMergedMultiFormValues(forms!);
      step.preload(mergedFormValues).then((newValues) => {
        setIsPreloading(false);
        forms &&
          Object.values(forms).forEach(({ setValues }) => {
            setValues(newValues);
          });
      });
    }
  }, [step?.preload]);

  const buttonGroup = useMemo(
    () => (
      <ButtonGroup
        forms={forms}
        handleSubmit={handleSubmit}
        resetAll={resetAll}
        onClose={onClose}
        isSubmitting={isSubmitting}
        submitLabel={submitLabel}
        SubmitButton={SubmitButton}
        left={footerLeft}
        {...buttonGroupProps}
      />
    ),
    // leaving out handleSubmit and resetAll because they change every render
    [
      SubmitButton,
      forms,
      isSubmitting,
      submitLabel,
      footerLeft,
      onClose,
      buttonGroupProps
    ]
  );

  const leftColumn = useMemo(() => {
    return (
      step?.leftBlocks || []
    ).map(({ id, title, validate, Edit, hasGreyBackground }, index) =>
      Edit ? (
        <Block
          Edit={Edit}
          id={id}
          title={title}
          hasGreyBackground={hasGreyBackground}
          validate={validate}
          blockProps={blockProps}
          data={data}
          forms={forms}
          getHandlers={getHandlers}
          initialValues={initialValues}
          showTitle={step.blocks.length > 1 && title}
          key={id || index}
        />
      ) : null
    );
  }, [
    blockProps,
    data,
    forms,
    getHandlers,
    initialValues,
    step.blocks.length,
    step.leftBlocks
  ]);

  const rightColumn = useMemo(() => {
    return step.blocks.map(
      ({ id, title, validate, Edit, hasGreyBackground }, index) =>
        Edit ? (
          <Block
            Edit={Edit}
            id={id}
            title={title}
            hasGreyBackground={hasGreyBackground}
            validate={validate}
            blockProps={blockProps}
            data={data}
            forms={forms}
            getHandlers={getHandlers}
            initialValues={initialValues}
            showTitle={step.blocks.length > 1 && title}
            key={id || index}
          />
        ) : null
    );
  }, [blockProps, data, forms, getHandlers, initialValues, step.blocks]);

  return (
    <Box flexDirection='column' height='100%' width='100%'>
      <Box flex={1} flexDirection='column' style={{ zIndex: 0 }}>
        <RenderLoading isLoading={isPreloading} LoadingView={LoadingView}>
          <Box flexDirection={'row'} gap={40}>
            {!!leftColumn.length && <Box width='100%'>{leftColumn}</Box>}
            <Box width='100%'>{rightColumn}</Box>
          </Box>
        </RenderLoading>
      </Box>
      {buttonGroup}
    </Box>
  );
}
