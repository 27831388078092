import { query } from '@rexlabs/model-generator';
import { propertiesModel } from 'data/models/entities/properties';

export function propertyListQuery() {
  return query`{
    ${propertiesModel} {
      id
      name
      address
      active_property_ownership {
        service_package
      }
      property_tenancies {
        tenancy
      }
      property_ownerships {
        ownership
        service_package
      }
      active_property_tenancy {
        tenancy
        rent_schedule
        rent_position
        security_deposit
      }
      active_property_ownership {
        ownership
      }
      created_by
      updated_by
    }
  }`;
}
