import * as React from 'react';
import { SelectProps } from '@rexlabs/select';
import { ServicePackage } from 'src/modules/service-packages/types/service-package';
import { AllRecordItemsSelect } from 'view/components/inputs/selects/v4-selects/all-record-items-select';

export function ServicePackageSelect(props: SelectProps<ServicePackage>) {
  return (
    <AllRecordItemsSelect<ServicePackage>
      {...props}
      id='service-package-select'
      endpoint='service-packages'
    />
  );
}
