import {
  useGetGenericArchiveAction,
  GetGenericArchiveActionArgs
} from '../use-get-generic-archive-action';
import {
  useGetGenericDeleteAction,
  GetDeleteActionArgs
} from '../use-get-generic-delete-action';

type Common<A, B> = {
  [P in keyof A & keyof B]: A[P] | B[P];
};

type IntersectionType = Omit<
  Common<GetGenericArchiveActionArgs<any, any>, GetDeleteActionArgs<any, any>>,
  'message' | 'id' | 'group'
>;

type CleanActionType<Type> = Omit<
  Type,
  keyof IntersectionType | 'id' | 'group'
>;

export interface GetArchivableEntityActionGroupArgs extends IntersectionType {
  archiveArgs: CleanActionType<GetGenericArchiveActionArgs<any, any>>;
  deleteArgs: CleanActionType<GetDeleteActionArgs<any, any>>;
}

export function useGetArchivableEntityActionGroup() {
  const getGenericArchiveAction = useGetGenericArchiveAction();
  const getGenericDeleteAction = useGetGenericDeleteAction();

  return ({
    recordTypeName,
    recordName,
    archiveArgs,
    deleteArgs
  }: GetArchivableEntityActionGroupArgs) => [
    getGenericArchiveAction({
      id: 'archive',
      group: 'archivable',
      recordTypeName,
      recordName,
      ...archiveArgs
    }),
    getGenericDeleteAction({
      id: 'delete',
      group: 'archivable',
      recordName,
      recordTypeName,
      ...deleteArgs
    })
  ];
}
