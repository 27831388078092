import ActionMenu from '@rexlabs/action-menu';
import { GhostButton, PrimarySplitButton } from '@rexlabs/button';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { StandardDialogFooter } from 'view/components/dialogs/components/standard-dialog-footer';
import ChevronDownIcon from 'view/components/icons/chevron-down';
import { ButtonGroupProps } from 'view/components/record-screen/dialog/types';

export interface DisburseFundsConfirmationDialogButtonGroupProps
  extends ButtonGroupProps {
  redirectToWithdrawals?: () => void;
}

export function DisburseFundsConfirmationDialogButtonGroup({
  onClose,
  handleSubmit,
  isSubmitting,
  redirectToWithdrawals
}: DisburseFundsConfirmationDialogButtonGroupProps) {
  const { t } = useTranslation();
  const handleSubmitAndClose = async () => {
    await handleSubmit();
    onClose?.();
  };

  const handleSubmitAndGoToWithdrawals = async () => {
    await handleSubmit();
    onClose?.();
    redirectToWithdrawals?.();
  };

  return (
    <StandardDialogFooter>
      <GhostButton onClick={onClose}>Cancel</GhostButton>
      <PrimarySplitButton
        Left={({ Button }) => (
          <Button
            type='submit'
            isLoading={isSubmitting}
            onClick={handleSubmitAndClose}
          >
            {t('disbursements.disburse-now')}
          </Button>
        )}
        Right={({ IconButton }) => (
          <ActionMenu
            placement='bottom-end'
            items={[
              {
                label: t('disbursements.disburse-and-create-withdrawal'),
                onClick: handleSubmitAndGoToWithdrawals
              }
            ]}
            Button={React.forwardRef((props, ref) => (
              <IconButton {...props} ref={ref} Icon={ChevronDownIcon} />
            ))}
          />
        )}
      />
    </StandardDialogFooter>
  );
}
