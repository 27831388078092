import { Columns } from 'view/components/table';
import { formatCurrency } from 'utils/formatters';
import {
  ContactPaymentMethodDetailsCell,
  useGetContactPaymentDetailsToCsv
} from 'src/modules/contacts/components/payment-method-details-cell';
import { useFeatureFlags } from 'view/components/@luna/feature-flags';
import { FLAGS } from 'utils/feature-flags';
import { DisbursementPaymentMethod } from '../types/types';

export const useBaseDisbursementMethodsColumns = (): Columns => {
  const getContactPaymentDetailsToCsv = useGetContactPaymentDetailsToCsv();
  const { hasFeature } = useFeatureFlags();

  return !hasFeature(FLAGS.OWNERSHIP_INCOME_ATTRIBUTION)
    ? [
        {
          id: 'contact',
          type: 'contact',
          Header: 'Owner'
        },
        {
          id: 'contact_payment_method.name',
          Header: 'Method name'
        },
        {
          id: 'contact_payment_method',
          Cell: ContactPaymentMethodDetailsCell,
          Header: 'Details',
          toCsv: (item) => {
            return getContactPaymentDetailsToCsv(item);
          }
        },
        {
          id: 'amount',
          accessor: (item: DisbursementPaymentMethod) => {
            if (item.split_amount.type.id === 'percent') {
              return `${item.split_amount.amount}%`;
            }
            return formatCurrency(item.split_amount.amount);
          },
          cellProps: {
            align: 'right'
          },
          Header: 'Split value'
        }
      ]
    : [
        {
          id: 'contact',
          type: 'contact',
          Header: 'Owner'
        },
        {
          id: 'contact_payment_method.name',
          Header: 'Payment method'
        },
        {
          id: 'contact_payment_method',
          Cell: ContactPaymentMethodDetailsCell,
          Header: 'Details',
          toCsv: (item) => {
            return getContactPaymentDetailsToCsv(item);
          }
        },
        {
          id: 'amount',
          accessor: (item: DisbursementPaymentMethod) => {
            if (item.split_amount.type.id === 'percent') {
              return `${item.split_amount.amount}%`;
            }
            return formatCurrency(item.split_amount.amount);
          },
          cellProps: {
            align: 'right'
          },
          Header: 'Amount'
        }
      ];
};
