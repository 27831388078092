import { reduce } from 'lodash';

const features = ['reconciliations'] as const;

export type FeatureTypes = typeof features[number];

export const FEATURES = reduce(
  features,
  (acc, feature) => {
    acc[feature.toUpperCase()] = feature;
    return acc;
  },
  {} as Record<Uppercase<FeatureTypes>, FeatureTypes>
);
