import { useTranslation } from 'react-i18next';

import { contactBillsAndFeesBlock } from '../blocks/contact-bills-and-fees';
import { useDirectDebitInvoicesBlock } from '../blocks/direct-debit-invoices';
import { useOtherInvoicesBlock } from '../blocks/other-invoices';
import { ownershipBillsAndFeesBlock } from '../blocks/ownership-bills-and-fees';
import { contactDisbursementBlock } from '../blocks/contact-disbursement';
import { ownershipDisbursementBlock } from '../blocks/ownership-disbursement';
import { trustJournalPreviewBlock } from '../blocks/trust-journal-preview';
import { bpayInvoicesBlock } from '../blocks/bpay-invoices';
import { pendingDisbursementSummaryBlock } from '../blocks/pending-disbursement-summary-block';

/**
 * This function returns content that is shared between pending contact and ownership disbursements.
 * Previously, the pending disbursement content was not split into separate files, and we were
 * conditionally showing/hiding content based on the type of disbursement.
 *
 * It was becoming a bit a mess, so I decided to split the content into separate files and added this
 * hook to manage the shared content.
 */
export function useSharedPendingContent(type: 'contact' | 'ownership') {
  const directDebitInvoiceBlock = useDirectDebitInvoicesBlock();
  const otherInvoicesBlock = useOtherInvoicesBlock();
  const { t } = useTranslation();

  const overview = {
    id: 'overview',
    label: 'Overview',
    blocks: [pendingDisbursementSummaryBlock]
  };

  const billsAndFees = {
    id: 'bills-and-fees',
    label: 'Bills & fees payable',
    blocks: [
      type == 'contact' ? contactBillsAndFeesBlock : ownershipBillsAndFeesBlock
    ]
  };

  const trustJournalPreview = {
    id: 'trust-journal-preview',
    label: t('financials.trust.trust-journal-preview.label.singular'),
    blocks: [trustJournalPreviewBlock]
  };

  const directDebitInvoices = {
    id: 'direct-debit-invoices',
    label: 'Direct Debit invoices',
    blocks: [directDebitInvoiceBlock]
  };

  const bpayInvoices = {
    id: 'bpay-invoices',
    label: 'BPAY invoices',
    blocks: [bpayInvoicesBlock]
  };

  const otherInvoices = {
    id: 'other-invoices',
    label: t('disbursements.eft-and-cheque-invoices.label'),
    blocks: [otherInvoicesBlock]
  };

  const disbursementMethod = {
    id: 'disbursement-method',
    label: 'Disbursement method',
    blocks: [
      type === 'contact' ? contactDisbursementBlock : ownershipDisbursementBlock
    ]
  };

  return {
    overview,
    billsAndFees,
    trustJournalPreview,
    directDebitInvoices,
    bpayInvoices,
    otherInvoices,
    disbursementMethod
  };
}
