import {
  ChartOfAccountsAccount,
  ChartOfAccountsAccountStatus
} from 'src/modules/chart-of-accounts/types/chart-of-account-types';
import { useDeleteAction } from 'src/modules/common/actions/delete/use-delete-action';
import { chartOfAccountsAccountModel } from 'src/modules/chart-of-accounts/models/chart-of-accounts';
import { useStatusChangeAction } from 'src/modules/common/actions/status/use-status-change-action';
import { ActionDeclaration } from 'src/modules/common/actions/types/action-declaration-types';
import { useGetEditAction } from './use-get-edit-action';

export function useGetChartOfAccountAccountsAction() {
  const getEditAction = useGetEditAction();
  const getDeleteAction = useDeleteAction(chartOfAccountsAccountModel);
  const getStatusChangeAction = useStatusChangeAction(
    chartOfAccountsAccountModel
  );

  return (
    chartOfAccountsAccount?: ChartOfAccountsAccount
  ): ActionDeclaration[] => {
    if (!chartOfAccountsAccount) return [];

    const statusActions: ActionDeclaration[] = [];

    switch (chartOfAccountsAccount.status.id) {
      case 'active':
        statusActions.push(
          getStatusChangeAction<ChartOfAccountsAccountStatus>({
            record: chartOfAccountsAccount,
            status: 'archived'
          })
        );
        break;

      case 'archived':
        statusActions.push(
          getStatusChangeAction<ChartOfAccountsAccountStatus>({
            record: chartOfAccountsAccount,
            status: 'active'
          })
        );
        break;
    }

    return [
      getEditAction(chartOfAccountsAccount),
      ...statusActions,
      ...(chartOfAccountsAccount.is_system_generated
        ? []
        : [getDeleteAction(chartOfAccountsAccount)])
    ];
  };
}
