import React, {
  ComponentType,
  ReactNode,
  useCallback,
  useMemo,
  useState
} from 'react';
import flatten from 'flat';
import { get, set } from 'lodash';

import Box from '@rexlabs/box';
import { mq, padding, StyleSheet, useStyles } from '@rexlabs/styling';
import { MultiForm } from '@rexlabs/form';
import { useErrorDialog } from '@rexlabs/dialog';
import { Breakpoints } from '@rexlabs/breakpoints';
import { OutlineIconButton } from '@rexlabs/button';

import RenderLoading from 'view/components/@luna/render-loading';
import { Breadcrumbs } from 'view/components/@luna/breadcrumbs';

import { BreadcrumbsProps } from 'view/components/@luna/breadcrumbs/breadcrumbs';

import { useGetRelatedRecordsSections } from 'utils/use-get-related-records-sections';
import { RecordTitleCard } from 'view/components/cards/title';
import { RecordLayout } from 'view/components/layouts/record/record-layout';
import LoadingState from 'view/components/states/loading';
import { RecordNavigationIcon } from 'view/components/icons/record-navigation';
import { RelatedRecordsIcon } from 'view/components/icons/related-records';

import { ContentConfig } from './types';
import { RecordNavigation } from './record-navigation';
import { Content } from './content';
import { RecordSubmitHandler } from './utils';
import { FormConfig } from './cards/core';
import { hasNavigation } from './record-navigation/has-navigation';
import { RightBar, RightBarProps } from './right-bar';
import { StickyNavButtons } from './sticky-navigation/buttons';
import { useStickyNavigation } from './sticky-navigation/context';
import { SideMenu } from './sticky-navigation/side-menu';

const defaultStyles = StyleSheet({
  breadcrumbs: {
    ...padding.styles({
      all: 'm',
      bottom: 'none'
    }),

    ...mq.styles({
      queries: {
        minWidth: 's'
      },
      styles: {
        ...padding.styles({
          all: 'none'
        })
      }
    })
  },

  rightBarButtonContainer: {
    display: 'none',

    ...mq.styles({
      queries: {
        minWidth: 'm',
        maxWidth: 'l'
      },
      styles: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }
    })
  },

  rightBarContainer: {
    display: 'none',

    ...mq.styles({
      queries: {
        minWidth: 'l'
      },
      styles: {
        display: 'flex',
        flexDirection: 'column',
        flex: '0 1 25%',
        paddingBottom: 40
      }
    })
  },

  navigationContent: {
    ...padding.styles({
      y: 'm'
    })
  }
});

export interface RecordScreenProps extends BreadcrumbsProps {
  /**
   * e.g. for state persistence
   */
  id?: string;
  data?: any;
  initialValues?: any;
  isLoading?: boolean;
  LoadingView?: ComponentType<any>;
  handleSubmit?: RecordSubmitHandler;
  content: ContentConfig;
  leftBarTitle?: string;
  rightBarTitle?: string;
  RightBarOverride?: ComponentType<Partial<RightBarProps>>;
  titleBlock: ReactNode;
  RightBarIcon?: ComponentType<any>;
  banner?: ReactNode;
}

export function RecordScreen({
  data,
  initialValues,
  isLoading,
  LoadingView = LoadingState,
  handleSubmit,
  content,
  breadcrumbs,
  leftBarTitle = 'Record navigation',
  rightBarTitle = 'Related records',
  RightBarOverride,
  titleBlock,
  RightBarIcon = RelatedRecordsIcon,
  banner
}: RecordScreenProps) {
  const s = useStyles(defaultStyles);

  const errorDialog = useErrorDialog();

  const { activeMenu, setActiveMenu } = useStickyNavigation();

  const [blockEditModes, setBlockEditModes] = useState({});
  const setEditMode = useCallback(
    (id, value) => setBlockEditModes((state) => ({ ...state, [id]: value })),
    []
  );

  // Submit handler for each of the sub forms in the cards
  // If we're in global edit mode, we want to just forward the values
  // to send the collected data to the API, if we're in contextual edit
  // mode we want to send the data to the API directly
  const handleBlockSubmit = useCallback(
    async (values, { id, isChanged, resetForm }) => {
      const changedValues = Object.keys(flatten(isChanged)).reduce(
        (acc, name) =>
          // HACK: we want to send the field up as changed if it is changed
          // or if it is part of a field array with any changes is it
          get(isChanged, name) || name.match(/\.[0-9]+\./)
            ? set(acc, name, get(values, name))
            : acc,
        {}
      );

      try {
        await handleSubmit!({ changedValues, values, changedBlockIds: [id] });
        resetForm(values);
        setEditMode(id, false);
      } catch (e) {
        errorDialog.open(e);
      }
    },
    [handleSubmit, errorDialog.open, setEditMode]
  );

  // Config for multi form setup
  // Loops over the config to initiate all forms that we know we will have and
  // their validation, initial values and submit handlers
  const [forms, setForms] = useState<FormConfig[]>([]);
  const formConfigs = useMemo(
    () =>
      forms.reduce((acc, form) => {
        if (form.id) {
          acc[form.id] = {
            names: form.fieldNames,
            initialValues: initialValues || data,
            validate: form.validate ?? {},
            handleSubmit: handleBlockSubmit
          };
        }
        return acc;
      }, {}),
    [forms, initialValues, data, handleBlockSubmit]
  );

  const getRelatedRecordsSections = useGetRelatedRecordsSections();

  const relatedRecordsSections =
    data && !isLoading ? getRelatedRecordsSections(data) : undefined;

  const rightBar = RightBarOverride ? (
    <RightBarOverride
      relatedRecordsSections={relatedRecordsSections}
      record={data}
    />
  ) : (
    relatedRecordsSections && (
      <RightBar relatedRecordsSections={relatedRecordsSections} record={data} />
    )
  );

  return (
    <RenderLoading isLoading={isLoading} LoadingView={LoadingView}>
      <MultiForm formConfigs={formConfigs}>
        {({ forms }) => {
          const navigation = hasNavigation(content) && (
            <RecordNavigation
              data={data}
              isLoading={isLoading}
              content={content}
              forms={forms}
            />
          );
          return (
            <>
              <RecordLayout
                breadcrumbs={
                  (rightBar || breadcrumbs) && (
                    <Box
                      {...s('breadcrumbs')}
                      alignItems='center'
                      justifyContent='space-between'
                    >
                      <Box flex={1}>
                        <Breadcrumbs breadcrumbs={breadcrumbs} />
                      </Box>
                      <div {...s('rightBarButtonContainer')}>
                        <OutlineIconButton
                          onClick={() => {
                            setActiveMenu('right');
                          }}
                          Icon={RelatedRecordsIcon}
                        />
                      </div>
                    </Box>
                  )
                }
                header={
                  <Box
                    flexDirection='column'
                    alignItems='flex-start'
                    Container='header'
                  >
                    <RecordTitleCard titleBlock={titleBlock} />
                  </Box>
                }
                navigation={
                  navigation && (
                    <Breakpoints queries={{ minWidth: 'm' }}>
                      <Box flex='0 1 25%' Container='nav'>
                        {navigation}
                      </Box>
                    </Breakpoints>
                  )
                }
                right={
                  rightBar && (
                    <aside {...s('rightBarContainer')}>{rightBar}</aside>
                  )
                }
              >
                {banner}
                <Content
                  data={data}
                  initialValues={initialValues}
                  content={content}
                  isLoading={isLoading}
                  blockEditModes={blockEditModes}
                  setBlockEditModes={setBlockEditModes}
                  setForms={setForms}
                  forms={forms}
                />
                <Breakpoints queries={{ maxWidth: 'm' }}>
                  <StickyNavButtons
                    leftBarTitle={leftBarTitle}
                    rightBarTitle={rightBarTitle}
                    leftBar={navigation}
                    rightBar={rightBar}
                    RightBarIcon={RightBarIcon}
                  />
                </Breakpoints>
              </RecordLayout>
              <SideMenu
                title={leftBarTitle}
                side='left'
                active={activeMenu === 'left'}
                setActive={setActiveMenu}
                Icon={RecordNavigationIcon}
              >
                <Box {...s('navigationContent')}>{navigation}</Box>
              </SideMenu>
              <SideMenu
                title={rightBarTitle}
                side='right'
                active={activeMenu === 'right'}
                setActive={setActiveMenu}
                Icon={RightBarIcon}
              >
                {rightBar}
              </SideMenu>
            </>
          );
        }}
      </MultiForm>
    </RenderLoading>
  );
}
