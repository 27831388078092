import React from 'react';
import { ValueListSelect } from './value-list';

export const DisbursementContactPaymentMethodMethodSelect = (props) => {
  return (
    <ValueListSelect
      type='disbursement_contact_payment_method_types'
      {...props}
    />
  );
};
