import * as React from 'react';
import dayjs from 'dayjs';

import { useConfirmationDialog } from '@rexlabs/dialog';
import { Body } from '@rexlabs/text';

import { getWithDrawalDetailsConfigFromMethodId } from 'src/modules/trust-journal-entries/utils/get-withdrawal-details-config';
import { mapDisbursementFrequencyFormDataToDisbursementFrequency } from 'src/modules/disbursements/mappers/map-disbursement-frequency-form-data-to-disbursement-frequency-request';

import { RecordSubmitHandler } from 'view/components/record-screen/utils';
import { TitleBlock } from 'view/components/@luna/title-block';
import { RecordScreen } from 'view/components/record-screen';
import { useToast } from 'view/components/@luna/notifications/toast';

import { createDefered } from 'utils/create-defered';
import { useContent } from '../../hooks/use-content';
import { useSettingsQuery } from '../../hooks/useSettingsQuery';
import { usePutSettingsMutation } from '../../hooks/usePutSettingsMutation';
import { useMapAccountSettingsDataToForm } from '../../hooks/use-map-account-settings-data-to-form';
import {
  DEFAULT_DISBURSEMENT_FREQUENCY_SETTING_KEY,
  TAX_PAYMENT_ACCOUNT_DETAILS_SETTING_KEY
} from '../../hooks/useSettings';
import { mapDisbursementToSettingsPayload } from '../mappers/map-disbursement-frequency-to-settings-payload';
import { normaliseSettingsData } from '../utils/normalise-settings-data';

export function AccountSettings() {
  const { addToast } = useToast();
  const confirmationDialog = useConfirmationDialog();
  const {
    data: rawSettingsData,
    isLoading: isRawSettingsDataLoading
  } = useSettingsQuery();

  const putSettingsMutation = usePutSettingsMutation();
  const [forceUpdateKey, setForceUpdateKey] = React.useReducer((x) => x + 1, 0);
  const content = useContent();

  const initialValues = useMapAccountSettingsDataToForm(rawSettingsData);

  const normalisedData = React.useMemo(
    () => normaliseSettingsData(rawSettingsData),
    [rawSettingsData]
  );

  const handleSubmit: RecordSubmitHandler = async ({
    changedValues,
    values
  }) => {
    const deferred = createDefered();

    const mappedValues = getMappedValues(changedValues, values);
    const showConfirmationDialog = shouldAskForConfirmation(changedValues);

    if (showConfirmationDialog) {
      const changedField = getChangedField(changedValues);
      const dialogProps = getDialogProps(changedField);

      confirmationDialog.open({
        ...dialogProps,
        onConfirm: () => {
          // Resolve if user clicks yes in confirmation dialog
          deferred.resolve();
        },
        onCancel: () => {
          // Reject if user clicks no/cancel in confirmation dialog
          deferred.reject();
          confirmationDialog.close();
        }
      });
    } else {
      deferred.resolve();
    }

    try {
      await deferred.promise;

      await putSettingsMutation.mutateAsync(mappedValues);

      const toastMessage = getToastMessage(
        mappedValues.map((value) => value.key)
      );

      if (toastMessage) {
        addToast({
          description: toastMessage.description,
          color: 'success'
        });
      }
    } catch (e) {
      // We need to reset the form values
      setForceUpdateKey();
    }
  };
  const isLoading = initialValues?.[TAX_PAYMENT_ACCOUNT_DETAILS_SETTING_KEY]
    ?.payee_id
    ? !initialValues?.[TAX_PAYMENT_ACCOUNT_DETAILS_SETTING_KEY]?.payee
    : isRawSettingsDataLoading;

  return (
    <RecordScreen
      key={forceUpdateKey}
      isLoading={isLoading}
      data={normalisedData}
      initialValues={initialValues}
      handleSubmit={handleSubmit}
      content={content}
      titleBlock={<TitleBlock title='Account settings'></TitleBlock>}
    />
  );
}

// Utils

function getToastMessage(mappedKeys: Array<string>) {
  if (mappedKeys.some((key) => key === 'timezone')) {
    return {
      description: 'Localisation settings updated successfully'
    } as const;
  }

  if (mappedKeys.some((key) => key === 'negative-currency-format')) {
    return {
      description: 'Accounting settings updated successfully'
    } as const;
  }

  if (mappedKeys.some((key) => key === 'rent-paid-to-date')) {
    return {
      description: 'Rent paid to date settings updated successfully'
    } as const;
  }

  if (mappedKeys.some((key) => key === 'rent-arrears-paid-to-date')) {
    return {
      description: 'Arrears paid to date settings updated successfully'
    } as const;
  }

  if (mappedKeys.some((key) => key === 'rent-arrears-calculation')) {
    return {
      description: 'Rent arrears calculation settings updated successfully'
    } as const;
  }

  if (
    mappedKeys.some(
      (key) =>
        key === 'batch-receipting-match-setting' ||
        key === 'batch-receipting-excess-match-setting'
    )
  ) {
    return {
      description: 'Batch receipting settings updated successfully'
    } as const;
  }
}

function getChangedField(changedValues) {
  return Object.keys(changedValues)[0] as RentArrearConfirmationTypes;
}

function shouldAskForConfirmation(changedValues) {
  return Object.keys(changedValues).some(
    (key) =>
      key === 'rent-arrears-paid-to-date' || key === 'rent-arrears-calculation'
  );
}

function getMappedValues(changedValues, values) {
  return Object.keys(changedValues).map((key) => {
    switch (key) {
      case 'batch-receipting-match-setting':
      case 'batch-receipting-excess-match-setting':
      case 'rent-arrears-calculation':
      case 'rent-arrears-paid-to-date':
      case 'rent-paid-to-date': {
        return {
          key,
          value: {
            id: changedValues[key]
          }
        };
      }
      case 'timezone': {
        return {
          key,
          value: changedValues[key].id
        };
      }
      case TAX_PAYMENT_ACCOUNT_DETAILS_SETTING_KEY: {
        const payee = changedValues[key]['payee'];

        // Here we're making sure that we have a value as its the easiest wat
        // to make sure we clear any payment fields we don't need.
        const paymentMethod =
          changedValues[key]['method'] || values[key]['method'];
        const branchCode =
          changedValues[key]['branch_code'] || values[key]['branch_code'];
        const accountNumber =
          changedValues[key]['account_number'] || values[key]['account_number'];
        const reference =
          changedValues[key]['reference'] || values[key]['reference'];

        const {
          isReferenceNeeded,
          isBranchCodeNeeded,
          isAccountCodeNeeded
        } = getWithDrawalDetailsConfigFromMethodId(paymentMethod?.id);

        return {
          key,
          value: {
            branch_code: isBranchCodeNeeded ? branchCode : '',
            account_number: isAccountCodeNeeded ? accountNumber : '',
            reference: isReferenceNeeded ? reference : '',
            ...(paymentMethod ? { payment_method_id: paymentMethod.id } : {}),
            ...(payee ? { payee_id: payee.id } : {})
          }
        };
      }
      case 'disbursement_frequency': {
        // First we're mapping the form data to the disbursement frequency
        const mappedFormData = mapDisbursementFrequencyFormDataToDisbursementFrequency(
          values[key]
        );

        // Then we're normalising the disbursement frequency to the correct shape for settings
        const normalisedData = mapDisbursementToSettingsPayload(mappedFormData);

        return {
          key: DEFAULT_DISBURSEMENT_FREQUENCY_SETTING_KEY,
          value: normalisedData
        };
      }
      default: {
        return {
          key,
          value: changedValues[key]
        };
      }
    }
  });
}

type RentArrearConfirmationTypes =
  | 'rent-arrears-paid-to-date'
  | 'rent-arrears-calculation';

function getDialogProps(changedField: RentArrearConfirmationTypes) {
  const commonProps = {
    cancelText: 'No, keep current settings',
    destructive: true
  };

  const day = dayjs().add(1, 'day').format('DD MMM YYYY');

  if (changedField === 'rent-arrears-calculation') {
    return {
      ...commonProps,
      title: `Change arrears calculation`,
      confirmText: 'Yes, change arrears calculation',
      message: (
        <Body>
          Changing the arrears calculation will take effect at 00.00am on {day}.
          Any new arrears created as a result of this change will not be visible
          before then. Do you want to continue?
        </Body>
      )
    };
  }

  return {
    ...commonProps,
    title: `Change arrears paid to date`,
    confirmText: 'Yes, change arrears paid to date',
    message: (
      <Body>
        Changing the arrears paid to date will take effect at 00.00am on {day}.
        Any new arrears created as a result of this change will not be visible
        before then. Do you want to continue?
      </Body>
    )
  };
}
