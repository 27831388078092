import React, { useEffect } from 'react';
import { BlockConfig } from 'view/components/record-screen/types';
import { Message } from '@rexlabs/notifications';
import { EmptyCard } from 'view/components/record-screen/cards/empty-card';
import EmptyTable from 'src/assets/illustrations/empty-table.svg';
import { TrustJournalEntry } from 'src/modules/trust-journal-entries/types/trust-journal-entry-types';
import { Trans, useTranslation } from 'react-i18next';
import { AvailableTrustJournalEntriesTable } from '../components/available-trust-journal-entries-table';

export const availableReceiptsBlock: BlockConfig<TrustJournalEntry[]> = {
  id: 'available-trust-journal-entries',
  title: () => (
    <Trans i18nKey='banking.batch-receipting.available-trust-journal-entries.title' />
  ),
  Edit: ({ data, blockProps }) => {
    const { t } = useTranslation();

    const {
      availableReceipts,
      setAvailableReceipts,
      selectedReceipts,
      trustJournalEntriesDataIsLoading
    } = blockProps;

    useEffect(
      () =>
        setAvailableReceipts(
          data?.filter(
            (receipt) =>
              !selectedReceipts.find(
                (selectedReceipt) => selectedReceipt.id === receipt.id
              )
          )
        ),
      [data, setAvailableReceipts]
    );

    return !trustJournalEntriesDataIsLoading && !availableReceipts?.length ? (
      <EmptyCard hasGreyBackground={true}>
        <Message
          title={t('banking.batch-receipting.no-trust-journal-entries.label')}
          Illustration={EmptyTable}
        >
          {t(
            'banking.batch-receipting.search-to-find-trust-journal-entries.message'
          )}
        </Message>
      </EmptyCard>
    ) : (
      <AvailableTrustJournalEntriesTable
        data={availableReceipts}
        blockProps={blockProps}
      />
    );
  }
};
