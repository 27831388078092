import { Task } from 'src/modules/tasks/common/types/Task';

import { api } from 'utils/api/api-client';

const relatedFields = [
  'managed_by',
  'details',
  'details.work_done_by',
  'details.access_provided_by'
];

export async function getWorkOrderTaskRelatedItems(
  workOrderTaskSearchItem: Task<'task_work_order'>
) {
  const workOrderTaskUrl = `/tasks/${
    workOrderTaskSearchItem.id
  }?include=${relatedFields.join(',')}`;

  const completeWorkOrderTask =
    !workOrderTaskSearchItem?.details?.work_done_by ||
    !workOrderTaskSearchItem?.details?.access_provided_by
      ? await api
          .get<Task<'task_work_order'>>(workOrderTaskUrl)
          .then(({ data }) => data)
      : workOrderTaskSearchItem;

  const workDoneBy = completeWorkOrderTask?.details?.work_done_by;
  const accessProvidedBy = completeWorkOrderTask?.details?.access_provided_by;
  const managedBy = completeWorkOrderTask?.managed_by;

  return {
    workDoneBy,
    accessProvidedBy,
    managedBy
  };
}
