import React from 'react';

import { CellProps } from '@rexlabs/table';

import { ActionButtonCell } from 'view/components/cells/action-button-cell';

import { WorkOrderTask } from '../types/WorkOrderTask';
import { useGetWorkOrderActionsFromStatus } from '../hooks/use-get-work-order-actions-from-status';

export function WorkOrderActionCell({ value }: CellProps<any, WorkOrderTask>) {
  const getWorkOrderActions = useGetWorkOrderActionsFromStatus();

  const { primaryActions } = getWorkOrderActions(value);

  const primaryAction = primaryActions?.[0];

  return primaryAction?.label ? (
    <ActionButtonCell action={primaryAction} />
  ) : (
    <></>
  );
}
