import * as React from 'react';
import { query } from '@rexlabs/model-generator';
import dayjs from 'dayjs';
import { ListTable, TabbedTable } from 'view/components/table';
import { Card } from 'view/components/card';
import { transformActionDeclarationsToActionMenuItems } from 'utils/actions/transforms';
import { ListScreen } from 'view/components/list-screen/list-screen';
import { useTableFilters } from 'view/hooks/use-table-filters';
import { tasksModel } from 'src/modules/tasks/common/models/tasks-model';
import { useTranslation } from 'react-i18next';
import { useAppWideFilterContext } from 'src/modules/app-wide-filters/contexts/app-wide-filter-context';
import { MOVE_IN_ROUTES } from '../routes';
import { useMoveInColumns } from '../hooks/use-move-in-columns';
import { useGetCreateMoveInAction } from '../hooks/action-declarations/use-get-create-move-in-action';
import { useGetMoveInActions } from '../hooks/action-menu-items/use-get-move-in-actions';
import { TaskTableEmptyState } from '../../common/components/task-table-empty-state';

const now = dayjs().format('YYYY-MM-DD');

const filterOpen = {
  field: 'closed_at',
  op: 'eq',
  value: 'null'
};

const filterClosed = {
  field: 'closed_at',
  op: 'neq',
  value: 'null'
};

const filterFollowUp = {
  field: 'follow_up_date',
  op: 'lte',
  value: now
};

const filterDue = {
  field: 'task_move_in_move_in_date',
  op: 'eq',
  value: now
};

const filterOverdue = {
  field: 'task_move_in_move_in_date',
  op: 'lt',
  value: now
};

const moveInQuery = () => query`{
  ${tasksModel} {
    id
    property
    managed_by
    moveInDetails
  }
}`;

/**
 * Tabbed List Table
 * Model Generator Variant
 */
export function MoveInList() {
  const { appWideFilterKey } = useAppWideFilterContext();
  const { t } = useTranslation();
  const getCreateMoveInAction = useGetCreateMoveInAction();
  const getMoveInActions = useGetMoveInActions();

  const { getSort, getFilters } = useTableFilters('tasks', {
    type_id: 'task_move_in'
  });

  const getRowLinkProps = ({ item }) => {
    return {
      to: MOVE_IN_ROUTES.MOVE_IN_DETAILS,
      params: { moveInId: item.id }
    };
  };

  const getActionMenuItems = React.useCallback(
    ({ item }) => {
      const actions = getMoveInActions(item);
      return transformActionDeclarationsToActionMenuItems(actions);
    },
    [getMoveInActions]
  );

  const moveInColumns = useMoveInColumns();
  const columnDependency = JSON.stringify(moveInColumns);

  const tabs = React.useMemo(() => {
    const commonProps = {
      id: 'move_ins',
      columns: moveInColumns,
      getQuery: moveInQuery,
      Table: ListTable,
      getRowLinkProps,
      getFilters,
      getSort,
      getActionMenuItems,
      forcedGlobalFilter: [
        {
          field: 'type_id',
          op: 'eq',
          value: { value: 'task_move_in', label: 'Move in' }
        }
      ],
      shouldUseAppWideFilter: true
    };

    return [
      {
        ...commonProps,
        name: 'open',
        label: 'Open',
        Empty: () => <TaskTableEmptyState label='Open' />,
        forcedGlobalFilter: [...commonProps.forcedGlobalFilter, filterOpen]
      },
      {
        ...commonProps,
        name: 'follow_up',
        label: 'Follow up',
        Empty: () => <TaskTableEmptyState label='Follow up' />,
        forcedGlobalFilter: [
          ...commonProps.forcedGlobalFilter,
          filterOpen,
          filterFollowUp
        ]
      },
      {
        ...commonProps,
        name: 'due',
        label: 'Due',
        Empty: () => <TaskTableEmptyState label='Due' />,
        forcedGlobalFilter: [
          ...commonProps.forcedGlobalFilter,
          filterOpen,
          filterDue
        ]
      },
      {
        ...commonProps,
        name: 'overdue',
        label: 'Overdue',
        Empty: () => <TaskTableEmptyState label='Overdue' />,
        forcedGlobalFilter: [
          ...commonProps.forcedGlobalFilter,
          filterOpen,
          filterOverdue
        ]
      },
      {
        ...commonProps,
        name: 'closed',
        label: 'Closed',
        Empty: () => <TaskTableEmptyState label='Closed' />,
        forcedGlobalFilter: [...commonProps.forcedGlobalFilter, filterClosed]
      }
    ];
  }, [columnDependency, getFilters]);

  return (
    <ListScreen
      title={t('tasks.move-in.title')}
      actions={[getCreateMoveInAction()]}
      key={appWideFilterKey}
    >
      <Card>
        <TabbedTable tabs={tabs} />
      </Card>
    </ListScreen>
  );
}
