import React, { useCallback, useMemo } from 'react';
import { query } from '@rexlabs/model-generator';
import { Message } from '@rexlabs/notifications';
import { useWhereabouts } from '@rexlabs/whereabouts';
import { useDialog } from '@rexlabs/dialog';

import EmptyTable from 'assets/illustrations/empty-table.svg';
import { ActionButtons } from 'view/components/@luna/action-buttons';

import { BlockConfig } from 'view/components/record-screen/types';
import { EmptyCard } from 'view/components/record-screen/cards/empty-card';
import TextLink from 'view/components/icons/text-link';

import { RecordTaskTabbedTable } from 'src/modules/tasks/common/components/record-task-tabbed-table';
import { tasksModel } from 'src/modules/tasks/common/models/tasks-model';
import { LinkTasksDialog } from 'src/modules/tasks/common/dialogs/link-tasks-dialog';
import { useLinkedTaskListColumns } from 'src/modules/tasks/common/hooks/use-linked-task-list-columns';
import { useGetRemoveTaskLinkAction } from 'src/modules/tasks/common/actions/use-get-remove-task-link-action';
import { transformActionDeclarationsToActionMenuItems } from 'utils/actions/transforms';
import { useGetUpdateTaskLinkAction } from 'src/modules/tasks/common/actions/use-get-update-task-link-action';
import { Task } from '../types/Task';

export const getFilterByParentTaskId = (taskId: string) => {
  return [
    {
      field: 'task_link_child_task_id',
      op: 'eq',
      value: taskId
    }
  ];
};

const getLinkedTasksQuery = () => query`{
  ${tasksModel} {
    id
    property {
      active_property_tenancy
    }
    managed_by
    details
    todoDetails
    leaseReviewDetails
    maintenanceDetails
    moveOutDetails
    moveInDetails
    arrearsDetails
    inspectionDetails
    propertyComplianceDetails
    quoteDetails
    workOrderDetails
    task_links
  }
}`;

export const linkedTasksBlock: BlockConfig<Task<any>> = {
  id: 'linked-tasks',
  title: 'Linked Tasks',
  showEmpty: (data) => !data?.task_links?.data?.length,
  Empty: ({ data }) => {
    const linkTasksDialog = useDialog(LinkTasksDialog);
    return (
      <EmptyCard>
        <Message
          title='No linked tasks'
          Illustration={EmptyTable}
          actions={[
            {
              Icon: TextLink,
              label: 'Link task',
              type: 'primary',
              handleAction: () => linkTasksDialog.open({ task: data })
            }
          ]}
        >
          Tasks relating to this one will be displayed here.
        </Message>
      </EmptyCard>
    );
  },
  View: ({ data }) => {
    const whereabouts = useWhereabouts();
    const tab = whereabouts.hashQuery?.tab;

    const filter = useMemo(() => getFilterByParentTaskId(data!.id), [data!.id]);
    const getRemoveLinkedTaskAction = useGetRemoveTaskLinkAction({
      parentTask: data
    });

    const getUpdateLinkedTaskAction = useGetUpdateTaskLinkAction({ data });

    const taskLinksKey = data?.task_links?.data
      ?.map((taskLink) =>
        [taskLink.child_task?.id, taskLink.relation?.id].join('-')
      )
      .join(',');

    const getActionItems = React.useMemo(() => {
      return ({ task }) =>
        transformActionDeclarationsToActionMenuItems([
          getRemoveLinkedTaskAction({ task }),
          getUpdateLinkedTaskAction({ task })
        ]);
      // We need to make sure this updates for parentTask changes
      // but data isn't stable and will cause the table to rerender repeatedly
    }, [taskLinksKey]);

    const columns = useLinkedTaskListColumns({ parentRecordId: data!.id, tab });

    return (
      <RecordTaskTabbedTable
        key={tab}
        getQuery={getLinkedTasksQuery}
        columns={columns}
        filters={filter}
        getActionItems={getActionItems}
      />
    );
  },
  Actions: ({ data }) => {
    const linkTasksDialog = useDialog(LinkTasksDialog);
    const onLinkTaskClick = useCallback(
      () => linkTasksDialog.open({ task: data! }),
      [data]
    );

    return (
      <ActionButtons
        actions={[
          {
            label: 'Link task',
            handleAction: onLinkTaskClick
          }
        ]}
      />
    );
  }
};
