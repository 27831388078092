import { noop } from 'lodash';

import { useDialog } from '@rexlabs/dialog';

import { SingleActionDeclaration } from 'src/modules/common/actions/types/action-declaration-types';

import { FLAGS } from 'utils/feature-flags';

import { AddIcon } from 'view/components/icons/add';
import { InspectionsIcon } from 'view/components/icons/inspections';
import { useFeatureFlags } from 'view/components/@luna/feature-flags';

import { CreateInspectionRecordDialog } from '../../dialogs/create-inspection-record-dialog';
import { InspectionFormData } from '../../types/InspectionFormData';

interface UseGetCreateInspectionActionProps {
  onCreate?: (data) => void;
  isNested?: boolean;
  isFixture?: boolean;
  initialValues: Partial<InspectionFormData>;
}

export function useGetCreateInspectionAction(
  {
    onCreate,
    isNested,
    initialValues,
    isFixture
  }: UseGetCreateInspectionActionProps = {
    onCreate: noop,
    isNested: false,
    isFixture: false,
    initialValues: {}
  }
) {
  const { hasFeature } = useFeatureFlags();

  const { open } = useDialog(CreateInspectionRecordDialog, {
    target: isNested ? 'nested-dialogs' : 'dialogs'
  });

  return (): SingleActionDeclaration => {
    return {
      id: 'create-inspection',
      intent: 'primary',
      group: 'inspection',
      label: !hasFeature(FLAGS.INSPECTION_SCHEDULE)
        ? 'Create inspection'
        : 'Create inspection task',
      Icon: !hasFeature(FLAGS.INSPECTION_SCHEDULE) ? AddIcon : InspectionsIcon,
      handleAction: async () => {
        open({
          isFixture,
          onCreate,
          initialValues: {
            ...initialValues,
            status: {
              id: 'not_started',
              label: 'Not started'
            },
            priority: {
              id: 'normal',
              label: 'Normal'
            },
            inspection_type: 'routine_inspection'
          }
        });
      }
    };
  };
}
