import { useConfirmationDialog, useErrorDialog } from '@rexlabs/dialog';
import {
  Invoice,
  financialsInvoicesModel
} from 'data/models/entities/financials/invoices';
import React from 'react';
import { ActionDeclaration } from 'src/modules/common/actions/types/action-declaration-types';
import { useToast } from 'view/components/@luna/notifications/toast';

import { useModelActions } from '@rexlabs/model-generator';

export function useGetRemoveOriginalBillAction() {
  const { open } = useConfirmationDialog();
  const { refreshItem, refreshLists, updateItem } = useModelActions(
    financialsInvoicesModel
  );
  const { open: openErrorDialog } = useErrorDialog();
  const toast = useToast();

  return (invoice: Invoice): ActionDeclaration => ({
    id: 'remove-original-bill',
    group: 'bill',
    label: 'Remove original bill',
    handleAction: () => {
      const fileName = invoice.uploaded_bill?.file?.name;
      const message = (
        <div>
          Do you want to remove <b>{fileName}</b> from this invoice?
        </div>
      );

      open({
        title: 'Remove original bill',
        message,
        destructive: true,
        async onConfirm() {
          try {
            const response = await updateItem({
              id: invoice.id,
              data: {
                uploaded_bill: null
              }
            });

            await Promise.all([
              refreshLists(),
              refreshItem({
                id: invoice.id
              })
            ]);

            toast.addToast({
              description: 'File has been removed successfully'
            });

            return response;
          } catch (error) {
            openErrorDialog(error);
          }
        }
      });
    }
  });
}
