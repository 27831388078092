import React from 'react';
import dayjs from 'dayjs';

import { CellProps } from '@rexlabs/table';

import { pluralize } from 'utils/formatters';
import { formatDate } from 'utils/dates/format';

import { TwoTierCell } from 'view/components/cells/two-tier-cell';
import { InspectionRun } from '../types/InspectionRun';

export function InspectionRunSummaryCell({
  row
}: CellProps<any, InspectionRun>) {
  const text = formatDate(dayjs(row?.original?.start_at).tz());
  const subtext = row?.original?.inspection_tasks?.data?.length || 0;

  return (
    <TwoTierCell text={text} subtext={pluralize('inspection', subtext, true)} />
  );
}
