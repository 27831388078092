import { SingleActionDeclaration } from 'src/modules/common/actions/types/action-declaration-types';
import { useDialog } from '@rexlabs/dialog';
import { CreateInvoiceDialog } from 'src/modules/invoices/dialogs/create-invoice-dialog';
import { Property } from 'src/modules/properties/types/property-types';
import { SearchResultItem } from 'utils/api/get-search-results';
import { Tenancy } from 'data/models/entities/tenancies';
import { Ownership } from 'data/models/entities/ownerships';
import { PropertyOwnership } from 'src/modules/property-ownerships/types/property-ownership-types';
import { PropertyTenancyModel } from 'src/modules/property-tenancies/types/property-tenancy-types';

export function useGetCreateInvoiceFromProperty() {
  const { open } = useDialog(CreateInvoiceDialog);

  return (property: Property): SingleActionDeclaration => {
    const suggestedItems = getOwnershipAndTenancySearchResults(
      property.property_ownerships?.items,
      property.property_tenancies?.items
    );

    return {
      id: 'create-invoice-from-property',
      label: 'Create invoice',
      handleAction: () => {
        open({
          initialValues: {},
          suggestions: {
            payableBy: suggestedItems,
            payableTo: suggestedItems
          }
        });
      },
      group: 'create-invoice'
    };
  };
}

function getOwnershipAndTenancySearchResults(
  propertyOwnerships: Array<PropertyOwnership> = [],
  propertyTenancies: Array<PropertyTenancyModel> = []
) {
  const results: Array<SearchResultItem<Ownership | Tenancy>> = [];

  for (const item of propertyOwnerships) {
    const record = item.ownership;

    results.push({
      id: record.id,
      label: record.display_name,
      record,
      type: {
        id: 'ownership',
        label: 'Ownership'
      }
    });
  }

  for (const item of propertyTenancies) {
    const record = item.tenancy;

    results.push({
      id: record.id,
      label: record.display_name,
      record,
      type: {
        id: 'tenancy',
        label: 'Tenancy'
      }
    });
  }

  return results;
}
