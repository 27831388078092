import dayjs from 'dayjs';
import { StatBlockIntent } from 'view/components/@luna/stat-block/stat-block';

export default function getReconciledAtStatusColor(
  last_reconciled_at: string | Date
): StatBlockIntent {
  const daysBetween = dayjs().diff(last_reconciled_at, 'day');

  if (daysBetween < 1) {
    return 'success';
  }
  if (daysBetween < 3) {
    return 'warning';
  }

  return 'danger';
}
