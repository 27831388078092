import React from 'react';

import { StylesProvider, border } from '@rexlabs/styling';

import { Banner, BannerProps } from './core';

const overrides = {
  banner: {
    ...border.tokens({
      target: 'banner.success',
      all: {
        color: () => 'transparent'
      }
    }),
    background: {
      color: ({ token }) =>
        token(
          'banner.success.background.color',
          token('color.container.static.success.default')
        )
    },
    strong: {
      ...border.tokens({
        target: 'banner.success.strong',
        all: {
          color: () => 'transparent'
        }
      }),
      background: {
        color: ({ token }) =>
          token(
            'banner.success.strong.background.color',
            token('color.container.static.success.contrast')
          )
      }
    }
  }
};

export function SuccessBanner(props: BannerProps) {
  return (
    <StylesProvider tokens={overrides}>
      <Banner {...props} />
    </StylesProvider>
  );
}

export default SuccessBanner;
