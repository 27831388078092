import React from 'react';

import { useToken } from '@rexlabs/styling';
import { NumberInput } from '@rexlabs/text-input';
import { Select } from '@rexlabs/select';
import { ValueListValue } from 'data/models/types';
import { InputProps } from '@rexlabs/form';
import Box from '@rexlabs/box';
import { TaskType } from 'src/modules/tasks/common/types/TaskType';
import { ChecklistItemOffsetRelatedDateSelect } from '../selects/v4-selects/checklist-item-offset-related-date-select';

export type FrequencyUnitId = 'days' | 'weeks' | 'months';

export const frequencyUnitItem: ValueListValue<FrequencyUnitId>[] = [
  { id: 'days', label: 'Day(s)' },
  { id: 'weeks', label: 'Week(s)' },
  { id: 'months', label: 'Month(s)' }
];

export type OffsetRelativeDueDateValue = {
  count: number | null;
  unit: ValueListValue<FrequencyUnitId> | null;
  offset: ValueListValue<string> | null;
};

interface OffsetRelativeDueDateInputProps
  extends Omit<InputProps, 'value' | 'onChange' | 'onBlur'> {
  value: OffsetRelativeDueDateValue | null;
  onChange?: (e: { target: { value: OffsetRelativeDueDateValue } }) => void;
  onBlur?: (e: { target: { value: OffsetRelativeDueDateValue } }) => void;
  taskType: TaskType;
}

const defaultOffsetRelativeDueDateValue = {
  count: null,
  unit: null,
  offset: null
};

export function ChecklistItemOffsetRelativeDueDateInput({
  value,
  onChange,
  onBlur,
  taskType,
  ...props
}: OffsetRelativeDueDateInputProps) {
  const token = useToken();

  const handleChange = (newValue: Partial<OffsetRelativeDueDateValue>) => {
    onChange?.({
      target: {
        value: {
          ...(value || defaultOffsetRelativeDueDateValue),
          ...newValue
        }
      }
    });
  };

  const handleBlur = (newValue: Partial<OffsetRelativeDueDateValue>) => {
    onBlur?.({
      target: {
        value: {
          ...(value || defaultOffsetRelativeDueDateValue),
          ...newValue
        }
      }
    });
  };

  const handleCountChange = ({ target }) =>
    handleChange({
      count: target.value
    });

  const handleCountBlur = ({ target }) =>
    handleBlur({
      count: target.value
    });

  const handleUnitChange = ({ target }) =>
    handleChange({
      unit: target.value
    });

  const handleUnitBlur = ({ target }) =>
    handleBlur({
      unit: target.value
    });

  const handleOffsetChange = ({ target }) =>
    handleChange({
      offset: target.value
    });

  const handleOffsetBlur = ({ target }) =>
    handleBlur({
      offset: target.value
    });

  return (
    <Box
      width={'auto'}
      flexDirection='row'
      alignItems='center'
      columnGap={token('spacing.xs')}
      flex={'1 1 0'}
    >
      <Box flexDirection='row' columnGap={token('spacing.xs')} flex='1 1 0'>
        <NumberInput
          {...props}
          id='count'
          data-testid={'number-input.count'}
          value={value?.count}
          onChange={handleCountChange}
          onBlur={handleCountBlur}
        />
        <Select
          {...props}
          id='unit'
          value={value?.unit}
          items={frequencyUnitItem}
          onChange={handleUnitChange}
          onBlur={handleUnitBlur}
          normaliser={(item) => ({ id: item.id, label: item.label })}
          searchable={false}
        />
      </Box>
      <Box flex='1 1 0'>
        <ChecklistItemOffsetRelatedDateSelect
          {...props}
          id='offset'
          taskType={taskType}
          onChange={handleOffsetChange}
          onBlur={handleOffsetBlur}
          value={value?.offset}
        ></ChecklistItemOffsetRelatedDateSelect>
      </Box>
    </Box>
  );
}
