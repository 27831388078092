import { BulkCreateLetterMessageFormData } from '../types/BulkCreateLetterMessageFormData';
import { LetterPreviewProps } from '../../common/components/letter-preview';

export function mapBulkCreateMessageRequestToLetterPreviewProps(
  formData: BulkCreateLetterMessageFormData
): LetterPreviewProps {
  return {
    address: formData?.letterAddress ?? '',
    bodyContent: formData?.content ?? ''
  };
}
