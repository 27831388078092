import React from 'react';
import { useQueryClient } from 'react-query';
import { useToast } from 'view/components/@luna/notifications/toast';
import { api } from 'utils/api/api-client';
import { pick } from 'lodash';
import { ChecklistFormData } from '../types/ChecklistFormData';
import { itemIsContact } from '../components/checklist-assignee-select';

function setAssigneeValues(json) {
  if (!json.assignee_contact_or_role) {
    json.assigned_to = null;
    json.assigned_to_portfolio_role = null;
  } else if (itemIsContact(json.assignee_contact_or_role)) {
    json.assigned_to = json.assignee_contact_or_role;
    json.assigned_to_portfolio_role = null;
  } else {
    json.assigned_to = null;
    json.assigned_to_portfolio_role = json.assignee_contact_or_role;
  }
}

export const useChecklistSubmit = () => {
  const { addToast } = useToast();
  const queryClient = useQueryClient();

  return React.useCallback(
    async (values: ChecklistFormData, checklist, setIsSaving, setIsEditing) => {
      const deleteRequests = values.removeIds.map((id) => {
        return api.delete(`/tasks/checklist-items/${id}`);
      });

      const upsertRequests = values.items.map((item, index) => {
        if (item.id != null) {
          const json = pick(item, [
            'label',
            'assignee_contact_or_role',
            'assigned_to',
            'assigned_to_portfolio_role',
            'status',
            'status_reason',
            'due_date_type',
            'due_date_data'
          ]);

          setAssigneeValues(json);

          if (json.due_date_type === null) {
            delete json.due_date_type;
            delete json.due_date_data;
          }

          return {
            id: `checklist_items_${index}`,
            method: 'PATCH',
            path: `/api/v1/tasks/checklist-items/${item.id}`,
            json
          };
        } else {
          const json = {
            ...item,
            checklist
          };

          setAssigneeValues(json);

          return {
            id: `checklist_items_${index}`,
            method: 'POST',
            path: `/api/v1/tasks/checklist-items`,
            json
          };
        }
      });

      const itemOrderArray = values.items.map((item, index) => {
        return item.id || `{{$.checklist_items_${index}.id}}`;
      });

      const itemOrderRequest = {
        id: 'checklist_items_array',
        method: 'PATCH',
        path: `/api/v1/tasks/checklists/${checklist.id}`,
        json: {
          item_order: itemOrderArray
        }
      };

      try {
        setIsSaving(true);

        // Here we make two requests in serial
        // 1 - One pipeline request to update checklist and checklist items data
        // 2 - we fetch all checklist data to refresh the view.

        // 1 - Pipeline request
        await Promise.all([
          api.post('request-pipelines', {
            requests: [...upsertRequests, itemOrderRequest]
          }),
          ...deleteRequests
        ]);

        // 2 - Refetch checklist data request
        await queryClient.refetchQueries(['checklist', checklist.id]);

        queryClient.invalidateQueries('checklists-widget');

        setIsSaving(false);
        setIsEditing(false);
      } catch (err) {
        setIsSaving(false);

        addToast({
          type: 'error',
          description: 'There was some error in saving the checklist.'
        });

        console.error(err);
      }
    },
    []
  );
};
