import { useModelActions } from '@rexlabs/model-generator';
import { tenancyModel } from 'data/models/entities/tenancies';
import { useMemo } from 'react';
import { getInvoicesPayableToBlock } from 'src/modules/invoices/blocks/invoices-payable-to';
import { useInvoiceFilters } from 'src/modules/invoices/utils/use-invoice-filters';
import { tenancyIncludes } from '../screens/tenancy-details';

export const usePayableToInvoicesBlock = () => {
  const { refreshItem } = useModelActions(tenancyModel);

  const {
    filters: invoiceFilters,
    actions: invoiceActions
  } = useInvoiceFilters({
    hiddenInvoiceFilterIds: [
      'rent_invoices',
      'agency_fees',
      'bond_requests',
      'tenant_bills'
    ]
  });

  return useMemo(
    () =>
      getInvoicesPayableToBlock(({ data }) => ({
        hashParamKey: 'payable_to',
        getForcedGlobalFilter: (ownershipId) => [
          {
            field: 'payable_to_object_id',
            op: '==',
            value: ownershipId
          },
          {
            field: 'payable_to_object_type',
            op: '==',
            value: 'tenancy'
          }
        ],
        initialValues: {
          payable_to: {
            object: {
              id: data.id,
              type: { id: 'tenancy', label: 'Tenancy' },
              label: data.display_name,
              record: data
            }
          }
        },
        invoiceFilters,
        invoiceActions,
        refreshRecord: async () =>
          await refreshItem({
            id: data.id,
            args: {
              include: tenancyIncludes
            }
          })
      })),
    [invoiceFilters]
  );
};
