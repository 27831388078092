import { DialogProps, useDialog, useErrorDialog } from '@rexlabs/dialog';
import { Forms } from '@rexlabs/form';
import { useModelActions } from '@rexlabs/model-generator';
import invariant from 'invariant';
import * as React from 'react';
import { invokeActionDeclaration } from 'src/modules/common/actions/utils/invoke-action-declaration';
import { useRecordCreatedToast } from 'src/modules/common/toasts/hooks/use-record-created-toast';
import { extractValuesFromForms } from 'src/modules/common/utils/extract-values-from-forms';
import { SearchResultItem } from 'utils/api/get-search-results';
import { RecordDialog } from 'view/components/record-screen/dialog/dialog';
import { emptyMessageCreatorItem } from '../../common/data/empty-message-creator-item';
import { messageDetailsBlock } from '../blocks/message-details-block';
import {
  MessageCreateDialogButtonGroup,
  MessageCreateDialogButtonGroupProps
} from '../components/message-create-dialog-button-group';
import { CreateMessageFormData } from '../mappers/types/create-message-form-data';
import { CreateMessageRequest } from '../mappers/types/create-message-request';
import { messagesModel } from '../models/messages-model';
import { getChangeHandlers } from '../utils/get-change-handlers';
import { mapCreateLetterFormToRequest } from '../mappers/map-create-letter-form-to-request';
import { letterContentBlock } from '../blocks/letter-content-block';
import { useGetBulkPrintLetterAction } from '../hooks/action-declarations/use-get-bulk-print-letter-action';
import { PreviewLetterFormDataDialog } from './preview-letter-form-data-dialog';

export interface CreateLetterRecordDialogProps extends DialogProps {
  // NOTE: If you intend to pass in a template, you will need to pass in the content
  // and subject from the template, as well as the template itself.
  data: Partial<Omit<CreateMessageFormData, 'relates_to'>> & {
    relates_to: SearchResultItem | null;
  };
  onCreate?: (message: any) => any;
  hasButtonGroupActions?: boolean;
}

const content = [
  {
    id: 'add-message',
    label: 'Add Message',
    blocks: [messageDetailsBlock, letterContentBlock]
  }
];

const SEND_NOW = Symbol('SEND_NOW');
const SEND_LATER = Symbol('SEND_LATER');

export function CreateLetterRecordDialog({
  title = 'Create letter',
  hasButtonGroupActions,
  onClose,
  onCreate,
  data
}: CreateLetterRecordDialogProps) {
  const errorDialog = useErrorDialog();
  const { open: openPreviewFormDataDialog } = useDialog(
    PreviewLetterFormDataDialog
  );
  const [relatesTo, setRelatesTo] = React.useState<SearchResultItem | null>(
    data.relates_to
  );
  const { createMultiplexItem, refreshLists } = useModelActions(messagesModel);
  const getBulkPrintLetterAction = useGetBulkPrintLetterAction(false);
  const addToast = useRecordCreatedToast(messagesModel, { actions: [] });

  // Define the change handlers here to avoid tightly coupling the blocks together
  const getHandlers = (forms?: Forms) =>
    getChangeHandlers(setRelatesTo, forms, 'letter');

  const clickedButtonRef = React.useRef<symbol | null>(null);

  const initialValues = {
    sent_from: emptyMessageCreatorItem,
    attachments: [],
    ...data,
    relates_to: relatesTo
  };

  // To reduce duplication, we can extract common behavior into a function
  const createMessage = React.useCallback(
    async (data: CreateMessageRequest) => {
      const { data: messages } = await createMultiplexItem({
        data,
        args: {
          include: 'channels,template'
        }
      });
      Promise.all([refreshLists(), onCreate?.(messages)]);
      return messages;
    },
    [createMultiplexItem, onCreate, refreshLists]
  );
  // Because the actual api calls are handled by the functions below, the submit handler will just be used for the built-in validation, and transforming the value to the desired shapes
  const handleSubmit = React.useCallback(
    async ({ values }) => {
      const type = clickedButtonRef.current;
      const data = await mapCreateLetterFormToRequest(values);

      switch (type) {
        // Send now will use the same logic for message creation, but will then invoke the send now action
        case SEND_NOW: {
          try {
            const messages = await createMessage(data);
            await invokeActionDeclaration(getBulkPrintLetterAction, messages);

            return messages;
          } catch (error) {
            invariant(error instanceof Error, 'err should be Error');

            errorDialog.open({
              message: error.message
            });
          }

          break;
        }

        // Send later will basically just create the message and that is all
        case SEND_LATER: {
          try {
            const message = await createMessage(data);
            addToast(message);

            return true;
          } catch (error) {
            invariant(error instanceof Error, 'err should be Error');

            errorDialog.open({
              message: error.message
            });
          }

          break;
        }

        default: {
          return;
        }
      }
    },
    [createMessage, getBulkPrintLetterAction, errorDialog, addToast]
  );

  const handlePreview = React.useCallback(
    (forms) => {
      const formData: CreateMessageFormData = extractValuesFromForms(forms);
      openPreviewFormDataDialog({ data: { formData } });
    },
    [openPreviewFormDataDialog]
  );

  const handleSendLater = React.useCallback(() => {
    clickedButtonRef.current = SEND_LATER;
  }, []);

  const handleSendNow = React.useCallback(() => {
    clickedButtonRef.current = SEND_NOW;
  }, []);

  const getIsPreviewDisabled = React.useCallback((forms?: Forms) => {
    return !forms?.details?.values?.relates_to;
  }, []);

  return (
    <RecordDialog
      autofocus={data.relates_to === null}
      getHandlers={getHandlers}
      size='xl'
      title={title}
      data={initialValues}
      content={content}
      handleSubmit={handleSubmit}
      onClose={onClose}
      // need to pass different actions to the button group depending on which part of the split button is clicked
      ButtonGroup={MessageCreateDialogButtonGroup}
      blockProps={{
        messageType: 'letter'
      }}
      buttonGroupProps={
        {
          hasButtonGroupActions,
          handlePreview,
          handleSendLater,
          handleSendNow,
          getIsPreviewDisabled
        } as MessageCreateDialogButtonGroupProps
      }
    />
  );
}
