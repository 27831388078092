import { useGetAgencyContacts } from './use-get-agency-contacts';

export const useGetAgencyContact = () => {
  const agencyContacts = useGetAgencyContacts();

  //Assume that there is only one agency contact and return it.
  if (agencyContacts && agencyContacts.length > 0) {
    return agencyContacts[0];
  }

  return null;
};
