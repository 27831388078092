import React, { forwardRef } from 'react';
import { Grid, GridProps } from '@rexlabs/grid';
import { StylesProvider } from '@rexlabs/styling';

export const valueGridTokens = {
  grid: {
    spacing: {
      y: '2.8rem'
    }
  }
};

export const ValueGrid = forwardRef<HTMLElement, GridProps>((props, ref) => {
  return (
    <StylesProvider tokens={valueGridTokens}>
      <Grid ref={ref} {...props} />
    </StylesProvider>
  );
});
