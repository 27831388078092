export type AnyObject = {
  [index: string]: any;
};

export function filterNullValues<T extends AnyObject>(object: T): Required<T> {
  return Object.fromEntries(
    Object.entries(object)
      .filter(([, value]) => value !== null)
      .map(([key, value]) => {
        return [
          key,
          value && Array.isArray(value)
            ? value
            : typeof value === 'object'
            ? filterNullValues(value)
            : value
        ];
      })
  ) as Required<T>;
}
