import { PropertyTenancy } from '../types/property-tenancy-types';
import { getFormattedRentPaidToDescription } from './get-formatted-rent-paid-to-description';

export function getCsvValuePaidToDate(paidToDateSetting) {
  return (item: PropertyTenancy) => {
    return getFormattedRentPaidToDescription({
      paidToDateSetting,
      rentPosition: item?.rent_position
    });
  };
}
