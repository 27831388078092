import React from 'react';
import { BlockConfig } from 'view/components/record-screen/types';
import { FormLayout } from 'view/components/layouts/form/form-layout';
import { Field } from '@rexlabs/form';
import { TextArea } from '@rexlabs/text-input';
import { Body } from '@rexlabs/text';

import { StyleSheet, useStyles } from '@rexlabs/styling';

const defaultStyles = StyleSheet({
  helperText: {
    color: ({ token }) => token('color.textStyle.body.subtext')
  }
});

export type CapturedReason = {
  reason: string;
};

export const captureReasonBlock: BlockConfig = {
  id: 'capture-reason',
  validate: {
    definitions: {
      reason: { rules: 'required' }
    }
  },
  Edit: ({ blockProps }) => {
    const s = useStyles(defaultStyles);

    return (
      <>
        {!!blockProps.description && (
          <div className='mb-xl' data-testId='capture-reason-description'>
            {blockProps.description}
          </div>
        )}
        <FormLayout>
          <Field
            name='reason'
            label={blockProps.reasonLabel || 'Reason'}
            Input={TextArea}
          />
        </FormLayout>
        {!!blockProps.helperText && (
          <Body {...s('helperText')}>{blockProps.helperText}</Body>
        )}
      </>
    );
  }
};
