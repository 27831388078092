import React, { FC, useCallback } from 'react';
import { InputProps } from '@rexlabs/form';
import { Autocomplete } from '@rexlabs/select-old';

import { api } from 'utils/api/api-client';
import { normalisePropertyTenancy } from 'utils/normaliser/property-tenancy';
import { PropertyTenancy } from 'src/modules/property-tenancies/types/property-tenancy-types';

export const PropertyTenancySelect: FC<InputProps> = (props) => {
  const items = useCallback(async (term) => {
    const propertyTenancies = await api.get<PropertyTenancy[]>(
      `/property-tenancies`,
      {
        per_page: 30,
        include: 'property,tenancy',
        search: term
      }
    );
    // HACK: Manually normalise this the first time with a different label because once a value
    // is set, the normalise function will only have access to `id` and `name` properties.
    // referencing the other data here would fail.
    return propertyTenancies.data;
  }, []);

  return (
    <Autocomplete
      items={items}
      normaliser={normalisePropertyTenancy}
      {...props}
    />
  );
};
