import React, { useMemo, useState, ComponentType } from 'react';
import invariant from 'invariant';
import { DialogProps } from '@rexlabs/dialog';
import Box from '@rexlabs/box';
import { StyleSheet, styled, StylesProps } from '@rexlabs/styling';

import LoadingState from 'view/components/states/compact/loading';
import RenderLoading from 'view/components/@luna/render-loading';
import { Dialog } from 'view/components/@luna/dialog';

import { Steps, Step } from './steps';

export interface StepsDialogProps {
  steps: Step[];
  initialValues?: any;
  isLoading?: boolean;
  LoadingView?: ComponentType<any>;
  handleSubmit?: (values: any, meta: any) => Promise<any> | any;
  [key: string]: any;
}

export interface StepsDialogStepProps {
  prevStep?: () => void;
  nextStep: () => void;
  accumulatedStepState: any;
  setStateForNextStep: (state: any) => void;
}

const defaultStyles = StyleSheet({
  container: {
    position: 'relative',
    width: '100%'
  },

  content: {}
});

/** @deprecated */
export const StepsDialog = styled<StepsDialogProps>(defaultStyles)(
  ({
    steps,
    handleSubmit,
    initialValues = {},
    isLoading,
    LoadingView = LoadingState,
    styles: s,
    ...props
  }: StepsDialogProps & DialogProps & StylesProps<typeof defaultStyles>) => {
    const [currentStep, setCurrentStep] = useState<number>(0);
    const [accumulatedStepState, setStateForNextStep] = useState<any>(
      initialValues
    );

    const prevStep = useMemo(
      () => () => {
        setCurrentStep((step) => {
          invariant(step > 0, 'Cannot find previous step.');
          return step - 1;
        });
      },
      []
    );

    const nextStep = useMemo(
      () => () => {
        setCurrentStep((step) => {
          invariant(step < steps.length - 1, 'Cannot find next step.');
          return step + 1;
        });
      },
      [steps.length]
    );

    const Content = steps[currentStep]?.Content;
    invariant(Content, 'No content found for step.');

    return (
      <Dialog {...props}>
        <RenderLoading LoadingView={LoadingView} isLoading={isLoading}>
          <Box flexDirection='row' {...s('container')}>
            <Steps steps={steps} currentStep={currentStep} />
            <Box {...s('content')} flex={1}>
              <Content
                {...props}
                prevStep={prevStep}
                nextStep={nextStep}
                accumulatedStepState={accumulatedStepState}
                setStateForNextStep={setStateForNextStep}
                initialValues={initialValues}
                handleSubmit={handleSubmit}
              />
            </Box>
          </Box>
        </RenderLoading>
      </Dialog>
    );
  }
);
