import { query } from '@rexlabs/model-generator';
import {
  Portfolio,
  portfoliosModel
} from 'src/modules/portfolios/models/portfolios-model';
import React, { useCallback } from 'react';
import { transformActionDeclarationsToActionMenuItems } from 'utils/actions/transforms';
import { getRecordLinkProps } from 'view/components/record-link/get-record-link-props';
import { Columns, ListTable } from 'view/components/table';
import { useTableFilters } from 'view/hooks/use-table-filters';
import { useGetPortfolioActions } from '../hooks/action-menu-items/use-get-portfolio-actions';

function getColumns(): Columns<Portfolio> {
  return [
    {
      id: 'label',
      Header: 'Name'
    },
    {
      id: 'primary-property-manager',
      type: 'text',
      accessor: (item) => item.primary_member?.user?.display_name,
      Header: 'Property manager (primary)'
    },
    {
      id: 'members',
      type: 'text',
      accessor: (item) => item.summary?.users_count,
      Header: 'Members',
      width: 110
    },
    {
      id: 'properties',
      type: 'text',
      accessor: (item) => item.summary?.properties_count,
      Header: 'Properties',
      width: 110
    }
  ];
}

function getQuery() {
  return query`{
      ${portfoliosModel} {
        id
        primary_member {
          user
        }
        summary
      }
    }`;
}

export function PortfoliosListTable() {
  const getPortfolioActions = useGetPortfolioActions();

  const { getSort, getFilters } = useTableFilters('portfolios');
  const getActionMenuItems = useCallback(
    ({ item }) =>
      transformActionDeclarationsToActionMenuItems(
        getPortfolioActions(item as Portfolio)
      ),
    []
  );

  return (
    <ListTable
      id='properties'
      columns={getColumns()}
      getRowLinkProps={({ item }) =>
        getRecordLinkProps({ type: 'portfolio', id: item.id })
      }
      getQuery={() => getQuery()}
      getSort={getSort}
      getFilters={getFilters}
      getActionMenuItems={getActionMenuItems}
    />
  );
}
