import React from 'react';

import { Contact } from 'src/modules/contacts/types/contact-types';

import RenderLoading from 'view/components/@luna/render-loading';
import LoadingState from 'view/components/states/loading';
import { Grid } from 'view/components/@luna/form/grid';

import { useGetOwnersTaxSummaryForFinancialYear } from 'src/modules/tax/hooks/use-get-ownership-tax-summaries-for-financial-year';
import { Ownership } from 'data/models/entities/ownerships';
import { FinancialPeriod } from 'src/modules/statements/types';
import { TaxSummary } from 'src/modules/tax/models/tax-summaries-model';
import { TaxIncomeSummary } from 'src/modules/tax/components/tax-income-summary';
import { TaxOwedSummary } from 'src/modules/tax/components/tax-owed-summary';
import { TaxIncomeForWithheldPeriodSummary } from './tax-income-for-withheld-period-summary';

type TaxSummaryProps = {
  owner: Contact;
  ownership: Ownership;
  financialYear: FinancialPeriod;
};

const shouldShowNrlPeriodSummary = (owner: Contact, taxSummary: TaxSummary) =>
  owner.tax?.non_resident_withhold_tax === true &&
  (taxSummary.taxable_income !==
    taxSummary.taxable_income_as_non_resident_landlord ||
    taxSummary.deductible_expenses !==
      taxSummary.deductible_expenses_as_non_resident_landlord);

export function TaxSummariesCard({
  owner,
  ownership,
  financialYear
}: TaxSummaryProps) {
  const {
    data: taxSummary,
    isLoading
  } = useGetOwnersTaxSummaryForFinancialYear(owner, ownership, financialYear);

  return (
    <Grid columns={1} data-testid='tax-summaries'>
      <RenderLoading LoadingView={LoadingState} isLoading={isLoading}>
        <TaxIncomeSummary
          taxableIncome={taxSummary?.taxable_income ?? 0}
          deductibleExpenses={taxSummary?.deductible_expenses ?? 0}
        />

        {taxSummary && shouldShowNrlPeriodSummary(owner, taxSummary) && (
          <TaxIncomeForWithheldPeriodSummary
            taxableIncome={taxSummary.taxable_income_as_non_resident_landlord}
            deductibleExpenses={
              taxSummary.deductible_expenses_as_non_resident_landlord
            }
          />
        )}

        <TaxOwedSummary
          adjustments={taxSummary?.manual_adjustments_to_tax_due ?? 0}
          totalTaxWithheld={taxSummary?.total_tax_withheld ?? 0}
          taxOwedAsANonResidentLandlord={
            taxSummary
              ? taxSummary.tax_due - taxSummary.manual_adjustments_to_tax_due
              : 0
          }
          taxPaidToHmrc={taxSummary?.tax_paid ?? 0}
        />
      </RenderLoading>
    </Grid>
  );
}
