import React, { useMemo } from 'react';

import { BlockConfig } from 'view/components/record-screen/types';
import { ActionButtons } from 'view/components/action-buttons';

import { transformSingleActionsToRegularActionMenuItems } from 'utils/actions/transforms';
import {
  DocumentList,
  DocumentListProps
} from '../components/connectors/document-list';
import { useGetAddDocumentsAction } from '../actions/use-get-add-documents-action';

type GetProps<Data = any> = (options: {
  data: Data;
}) => Omit<DocumentListProps, 'record'>;

export function getDocumentsBlock<Data = any>(
  getProps: GetProps<Data>
): BlockConfig {
  return {
    id: 'documents',
    title: 'Documents',
    View: ({ data }) => {
      const {
        propertyId,
        contactId,
        ownershipId,
        tenancyId,
        taskId,
        attachedTo
      } = useMemo(() => getProps({ data }), [data]);
      return (
        <DocumentList
          propertyId={propertyId}
          contactId={contactId}
          ownershipId={ownershipId}
          tenancyId={tenancyId}
          taskId={taskId}
          record={data}
          attachedTo={attachedTo}
        />
      );
    },
    Actions: ({ data }) => {
      const getAddDocumentAction = useGetAddDocumentsAction();
      const { attachedTo } = useMemo(() => getProps({ data }), [data]);

      return (
        <ActionButtons
          actions={transformSingleActionsToRegularActionMenuItems([
            getAddDocumentAction({ data, attachedTo })
          ])}
        />
      );
    }
  };
}
