import { useDialog } from '@rexlabs/dialog';
import { noop } from 'lodash';

import { SingleActionDeclaration } from 'src/modules/common/actions/types/action-declaration-types';
import { Task } from 'src/modules/tasks/common/types/Task';

import AddIcon from 'view/components/icons/add';

import { CreateQuoteDialog } from '../../dialogs/create-quote-dialog';
import { mapMaintenanceTaskToCreateQuoteForm } from '../../mappers/map-related-task-to-create-quote-form';
import { useAddQuoteSubmitHandler } from '../use-add-quote-submit-handler';
import { CreateQuoteFormData } from '../../types/CreateQuoteFormData';

interface UseGetCreateQuoteActionProps {
  onCreate?: (data) => void;
  isNested?: boolean;
  initialValues?: Partial<CreateQuoteFormData>;
}

export function useGetCreateQuoteAction(
  { isNested, onCreate, initialValues }: UseGetCreateQuoteActionProps = {
    isNested: false,
    onCreate: noop,
    initialValues: {}
  }
) {
  const { open } = useDialog(CreateQuoteDialog, {
    target: isNested ? 'nested-dialogs' : 'dialogs'
  });
  const { onHandleSubmit } = useAddQuoteSubmitHandler({ onCreate });

  return (relatedTask?: Task<any>): SingleActionDeclaration => {
    return {
      id: 'create-task-quote',
      intent: 'primary',
      group: 'task-quote',
      label: 'Request quote(s)',
      Icon: AddIcon,
      handleAction: async () => {
        open({
          handleSubmit: onHandleSubmit,
          initialValues: relatedTask
            ? mapMaintenanceTaskToCreateQuoteForm(relatedTask)
            : initialValues
        });
      }
    };
  };
}
