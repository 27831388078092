import React from 'react';
import {
  Autocomplete,
  SelectNormalised,
  SelectProps
} from '@rexlabs/select-old';

import { RecordType } from 'data/models/types';
import {
  getSearchResults,
  SearchResultItem
} from 'utils/api/get-search-results';
import { RecordIcon } from 'view/components/record-icon/record-icon';
import ContactsIcon from 'view/components/icons/contacts';

export const normaliseItems = (item: SearchResultItem): SelectNormalised => {
  return {
    value: item,
    label: item.label,
    type: item.type.id
  };
};

export interface RecordSelectProps extends Omit<SelectProps, 'items'> {
  objectTypes: RecordType[];
}

const defaultObjectTypes: RecordType[] = ['contact', 'tenancy', 'ownership'];

const desiredOrder: Partial<Record<RecordType, number>> = {
  ownership: 0,
  tenancy: 1,
  contact: 2
};

export function FinancialEntitySelect({
  objectTypes = defaultObjectTypes,
  fixtures: overrideFixtures,
  ...props
}: RecordSelectProps) {
  const items = React.useCallback(
    async (term) => {
      const searchResults = await getSearchResults(term, { objectTypes });

      searchResults.sort(function (a, b) {
        return desiredOrder[a.type.id] - desiredOrder[b.type.id];
      });

      return searchResults;
    },
    [objectTypes]
  );

  return (
    <Autocomplete
      items={items}
      normaliser={normaliseItems}
      fixtures={overrideFixtures}
      headings={{
        contact: {
          label: 'Contacts',
          Icon: ContactsIcon
        },
        tenancy: {
          label: 'Tenancies',
          Icon: (props) => <RecordIcon {...props} recordType='tenancy' />
        },
        ownership: {
          label: 'Ownerships',
          Icon: (props) => <RecordIcon {...props} recordType='ownership' />
        }
      }}
      {...props}
    />
  );
}
