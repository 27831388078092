import React, { useCallback } from 'react';

import { RecordDialog } from 'view/components/record-screen/dialog/dialog';

import { RecordSubmitHandler } from 'view/components/record-screen/utils';
import { archiveBlock, archiveWithReasonBlock } from './archive-block';

export type ArchiveRecordBlockProps = {
  message: React.ReactNode;
  isReasonNeeded?: boolean;
};

interface ArchiveDialogProps extends ArchiveRecordBlockProps {
  title: React.ReactNode;
  submitLabel: React.ReactNode;
  onClose?: () => void;
  onSubmit: (values: any) => Promise<void>;
}

export function ArchiveDialog({
  title,
  message,
  submitLabel,
  isReasonNeeded = false,
  onClose,
  onSubmit
}: ArchiveDialogProps) {
  const handleSubmit: RecordSubmitHandler = useCallback(async ({ values }) => {
    await onSubmit(values);
    return true;
  }, []);

  return (
    <RecordDialog
      onClose={onClose}
      blockProps={{
        message,
        isReasonNeeded
      }}
      title={title}
      handleSubmit={handleSubmit}
      content={[
        {
          id: 'archive',
          label: 'Archive',
          blocks: [isReasonNeeded ? archiveWithReasonBlock : archiveBlock]
        }
      ]}
      submitLabel={submitLabel}
    />
  );
}
