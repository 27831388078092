import React from 'react';

import { TagCell } from '@rexlabs/table';
import { Tag } from '@rexlabs/tag';

import { Columns } from 'view/components/table';

import { FLAGS } from 'utils/feature-flags';
import { useFeatureFlags } from 'view/components/@luna/feature-flags';
import { capitalize, lowerCase } from 'lodash';
import { ModifiedByCell } from '../components/modified-by-cell';
import { ChecklistNameCell } from '../components/checklist-name-cell';
import { useTaskTypesWithCustomAddToRecord } from '../hooks/use-task-types-with-custom-add-to-record';
import { TaskType } from '../../common/types/TaskType';
import { ChecklistTemplate } from '../types/ChecklistTemplate';

export function useChecklistTemplateColumns({
  taskType
}: {
  taskType: TaskType;
}): Columns {
  const { hasFeature } = useFeatureFlags();
  const hasSmartChecklistSettingsFeature = hasFeature(
    FLAGS.SMART_CHECKLISTS_SETTINGS
  );

  return [
    {
      id: 'label',
      Header: 'Checklist name',
      Cell: ChecklistNameCell,
      accessor: (item) => item
    },
    {
      id: 'description',
      Header: 'Description'
    },
    {
      id: 'is_default',
      width: 100,
      Cell: ({ value, row }) => {
        const taskTypesWithCustomAddToRecord = useTaskTypesWithCustomAddToRecord();

        const showDefaultTag = !!(
          value &&
          taskTypesWithCustomAddToRecord.includes(row?.original?.task_type?.id)
        );

        if (!showDefaultTag) {
          return <></>;
        }
        return (
          <TagCell>
            <Tag>{value}</Tag>
          </TagCell>
        );
      },
      accessor: (item) => (item.is_default ? 'Default' : '')
    },
    ...(hasSmartChecklistSettingsFeature && taskType === 'task_inspection'
      ? [
          {
            id: 'inspection_type',
            Header: 'Inspection type',
            width: 180,
            accessor: (item: ChecklistTemplate) =>
              item.discriminator_value
                ? capitalize(lowerCase(item.discriminator_value))
                : '--'
          }
        ]
      : []),
    {
      id: 'modified_by',
      Header: 'Modified by',
      Cell: ModifiedByCell,
      width: 200,
      accessor: (item) => item
    }
  ];
}
