import React, { ComponentType, useEffect } from 'react';
import { Field } from '@rexlabs/form';

import { BlockConfig } from 'view/components/record-screen/types';
import { Column, Grid } from 'view/components/@luna/form/grid';
import { ApplianceSelect } from 'view/components/inputs/selects/v4-selects/appliance-fixture-select';
import { RadioGroupInput } from '@rexlabs/radio-input';
import { Property } from 'src/modules/properties/types/property-types';
import { WarrantyBanner } from 'src/modules/appliances/components/warranty-banner';
import { Value } from 'view/components/values';
import ReceiptIcon from 'view/components/icons/receipt';
import FileIcon from 'view/components/icons/file';
import { Message } from '@rexlabs/notifications';
import { EmptyCard } from 'view/components/record-screen/cards/empty-card';
import { LinkButton } from '@rexlabs/button';
import Box from '@rexlabs/box';
import { StyleSheet, useStyles, useToken } from '@rexlabs/styling';
import { Body } from '@rexlabs/text';
import { IconProps } from '@rexlabs/icons';
import { useGetDownloadApplianceManuals } from 'src/modules/appliances/hooks/use-get-download-appliance-manuals';
import { useGetDownloadApplianceWarranties } from 'src/modules/appliances/hooks/use-get-download-appliance-warranties';
import { MaintenanceTask } from '../types/MaintenanceTask';
import { MaintenanceFormData } from '../types/MaintenanceFormData';

export const applianceBlock: BlockConfig<
  MaintenanceTask,
  any,
  MaintenanceFormData,
  // Incredibly partial type - this is all we need here.
  // I'm explicitly typing it so that I can provide it in my tests.
  { details?: { values?: { property?: Property } } }
> = {
  id: 'appliances',
  title: 'Relates to appliance',
  validate: {
    definitions: {
      'details.issue_relates_to_appliance': { rules: 'required' },
      'details.appliance': {
        rules: 'required_if_true:details.issue_relates_to_appliance',
        name: 'appliance'
      }
    }
  },
  showEmpty: (data) => !data.details?.appliance,
  Empty: ({ onEditClick }) => {
    return (
      <EmptyCard>
        <Message
          title='Issue does not relate to an appliance'
          actions={[
            {
              type: 'secondary',
              label: 'Select appliance',
              handleAction: onEditClick
            }
          ]}
        >
          If this issue relates to an appliance, select an appliance below.
        </Message>
      </EmptyCard>
    );
  },
  View: ({ data }) => {
    const token = useToken();

    const appliance = data?.details?.appliance;
    const warranties = appliance?.warranties?.data;
    const manuals = appliance?.manuals?.data;

    const downloadWarranties = useGetDownloadApplianceWarranties({
      applianceId: appliance?.id,
      warranties
    });
    const downloadManuals = useGetDownloadApplianceManuals({
      applianceId: appliance?.id,
      manuals
    });
    const applianceLabel = [appliance?.type, appliance?.make, appliance?.model]
      .filter(Boolean)
      .join(' • ');

    return (
      <Grid columns={1}>
        <Value label={'Appliance'} value={applianceLabel} />

        <Box gap={token('spacing.xl')} flexWrap={'wrap'}>
          {warranties?.length ? (
            <LinkButton
              onClick={() => downloadWarranties?.()}
              IconLeft={ReceiptIcon}
            >
              Download receipt(s)
            </LinkButton>
          ) : (
            <DownloadEmptyState
              Icon={ReceiptIcon}
              text={'No receipts uploaded'}
            />
          )}

          {manuals?.length ? (
            <LinkButton onClick={() => downloadManuals?.()} IconLeft={FileIcon}>
              Download manual(s)
            </LinkButton>
          ) : (
            <DownloadEmptyState Icon={FileIcon} text={'No manuals uploaded'} />
          )}
        </Box>

        <WarrantyBanner
          warrantyExpiryDate={appliance?.warranty_expires_date ?? null}
        />
      </Grid>
    );
  },

  Edit: ({ forms, values, blockProps, setFieldValue, data }) => {
    const property = data?.property || forms?.details?.values?.property;

    const isCreating = blockProps?.isCreating;

    // If we're in edit mode on the record screen we want to set the value to true
    useEffect(() => {
      if (!isCreating) {
        setFieldValue?.('details.issue_relates_to_appliance', true);
      }
    }, []);
    return (
      <>
        <Grid columns={2}>
          <Field
            name={'details.issue_relates_to_appliance'}
            Input={RadioGroupInput}
            inputProps={{
              options: [
                { label: 'Yes', value: true },
                { label: 'No', value: false }
              ],
              disabled: !property?.id,
              orientation: 'horizontal'
            }}
          />
          <div />

          {values?.details?.issue_relates_to_appliance && (
            <>
              <ApplianceColumnWrapper isFullWidth={!isCreating}>
                <Field
                  name='details.appliance'
                  id={'appliance'}
                  label='Select appliance'
                  optional={false}
                  Input={ApplianceSelect}
                  inputProps={{ property }}
                />
              </ApplianceColumnWrapper>
              {isCreating ? <div /> : null}
              <ApplianceColumnWrapper isFullWidth={!isCreating}>
                {values?.details.appliance && (
                  <WarrantyBanner
                    warrantyExpiryDate={
                      values?.details.appliance.warranty_expires_date
                    }
                  />
                )}
              </ApplianceColumnWrapper>
            </>
          )}
        </Grid>
      </>
    );
  }
};

function ApplianceColumnWrapper({
  isFullWidth,
  children
}: {
  isFullWidth: boolean;
  children: React.ReactNode;
}) {
  return isFullWidth ? <Column width={2}>{children}</Column> : <>{children}</>;
}

const downloadEmptyStateStyles = StyleSheet({
  container: {
    color: ({ token }) => token('palette.grey.600')
  }
});

function DownloadEmptyState({
  text,
  Icon
}: {
  text: string;
  Icon: ComponentType<IconProps>;
}) {
  const token = useToken();
  const s = useStyles(downloadEmptyStateStyles);

  return (
    <Box
      flexDirection={'row'}
      gap={token('spacing.s')}
      alignItems={'center'}
      {...s('container')}
    >
      <Icon />
      <Body semibold as={'span'}>
        {text}
      </Body>
    </Box>
  );
}
