import React, { useMemo } from 'react';

import { RecordScreen } from 'view/components/record-screen';

import { query, useEntityQuery } from '@rexlabs/model-generator';
import { bankDepositModel } from 'src/modules/banking/bank-deposits/models/bank-deposit-model';
import { BREADCRUMBS } from 'view/components/@luna/breadcrumbs';
import { useRecordScreenSubmitHandler } from 'view/hooks/use-record-screen-submit-handler';
import { content } from '../data/content';
import { BankDepositTitleBlock } from '../components/bank-deposit-title-block';

const getBankDepositQuery = (id: string) => query`{
  ${bankDepositModel} (id: ${id}) {
    id
    status
    bank_account
    line_items {
        trust_journal_entry {
            payment_information
            contact
            created_by
            updated_by
        }
    }
    totals
  }
}`;

interface BankDepositDetailsProps {
  bankDepositId: string;
}

export function BankDepositDetails({ bankDepositId }: BankDepositDetailsProps) {
  const breadcrumbs = [{ type: BREADCRUMBS.BANK_DEPOSIT }];
  const query = useMemo(() => getBankDepositQuery(bankDepositId), [
    bankDepositId
  ]);

  const { data, status, actions } = useEntityQuery(query);
  const handleSubmit = useRecordScreenSubmitHandler(({ changedValues }) =>
    actions.updateItem({
      id: bankDepositId,
      data: changedValues
    })
  );

  return (
    <RecordScreen
      isLoading={status === 'loading'}
      data={data}
      handleSubmit={handleSubmit}
      content={content}
      titleBlock={<BankDepositTitleBlock bankDeposit={data!} />}
      breadcrumbs={breadcrumbs}
    />
  );
}
