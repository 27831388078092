import { Columns } from 'view/components/table';
import { createStaticTableBlock } from 'view/components/record-screen/cards/create-static-table-block';
import EmptyTrustJournalEntriesIllustration from 'assets/illustrations/empty-trust-journal-entries-table.svg';
import { Disbursement } from 'data/models/entities/financials/disbursements/disbursements';
import React from 'react';
import { Message } from 'view/components/@luna/message';
import { TrustJournalEntryLineItem } from 'src/modules/trust-journal-entry-line-items/types/trust-journal-entry-line-item-type';
import {
  ReceiptPaymentMethods,
  TrustJournalEntry,
  WithdrawalPaymentMethods
} from 'src/modules/trust-journal-entries/types/trust-journal-entry-types';
import { ValueListValue } from 'data/models/types';

const trustTransfersColumns: Columns = [
  {
    id: 'type',
    type: 'value',
    Header: 'Type',
    width: 180
  },
  {
    id: 'trust_ledger.object',
    type: 'record',
    Header: 'Paid to',
    width: 200
  },
  {
    id: 'description',
    Header: 'Description',
    width: 400
  },
  {
    id: 'payment_method',
    Header: 'Payment method',
    type: 'value',
    width: 180
  },
  {
    id: 'line_item.amount',
    type: 'currency',
    Header: 'Amount',
    cellProps: {
      align: 'right'
    },
    accessor: (item: DisbursementTrustTransaction) => Math.abs(item.amount),
    width: 200
  }
];

interface DisbursementTrustTransaction extends TrustJournalEntryLineItem {
  payment_method: ValueListValue<
    ReceiptPaymentMethods | WithdrawalPaymentMethods
  > | null;
}

/**
 * In the trust transactions table we want to show the trust transactions made from the respect of the folio that is
 * being disbursed. Descriptions for transfers have the description we want on the transfer_source but the data we want
 * on the invoice payment item.
 */
function mapTrustTransactionsFromTrustJournalEntry(
  trustJournalEntry: TrustJournalEntry
): DisbursementTrustTransaction {
  let trustTransaction = trustJournalEntry.line_items!.data[0];

  if (trustJournalEntry.line_items!.data.length > 1) {
    let invoicePaymentTrustTransaction = trustJournalEntry.line_items?.data.find(
      (line_item) => line_item.type.id === 'invoice_payment'
    );

    const transferSourceTransaction = trustJournalEntry.line_items?.data.find(
      (line_item) => line_item.type.id === 'transfer_source'
    );

    if (!invoicePaymentTrustTransaction) {
      console.warn('No invoice payment was found');
    } else if (!transferSourceTransaction) {
      console.warn('No trust source was found');
    } else {
      //Override the description of the invoice payment with that of the transfer source
      invoicePaymentTrustTransaction = {
        ...invoicePaymentTrustTransaction,
        description: transferSourceTransaction.description
      };

      trustTransaction = invoicePaymentTrustTransaction;
    }
  }
  return {
    running_balance_amount: 0,
    ...trustTransaction,
    payment_method: trustJournalEntry.payment_information?.method ?? null
  };
}

export const trustTransfersBlock = createStaticTableBlock<Disbursement, any>({
  id: 'trustTransfers',
  title: 'Trust transfers',
  getTableProps: ({ data }) => {
    return {
      id: 'trust-transfers',
      items:
        data?.trust_journal_entries?.items.map(
          mapTrustTransactionsFromTrustJournalEntry
        ) ?? [],
      columns: trustTransfersColumns
    };
  },
  Empty: () => (
    <Message
      Illustration={EmptyTrustJournalEntriesIllustration}
      title='Trust transfers'
    >
      No trust transfers exist at this moment
    </Message>
  )
});
