import React, { forwardRef } from 'react';
import { CurrencyInput, CurrencyInputProps } from '@rexlabs/text-input';
import { fromCents, toCents } from 'utils/formatters';
import { useSettings } from 'src/modules/settings/hooks/useSettings';

export interface CentAmountInputProps
  extends Omit<CurrencyInputProps, 'onChange' | 'onBlur'> {
  onChange: (...args: any[]) => void;
  onBlur?: (...args: any[]) => void;
}

export const CentAmountInput = forwardRef<
  HTMLInputElement,
  CentAmountInputProps
>(function CentAmountInput({ value, onChange, onBlur, ...props }, ref) {
  const { currency } = useSettings();

  const numberFormatPart = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: currency.id
  })
    .formatToParts()
    .find((part) => part.type === 'currency');

  const Prefix = () => <span>{numberFormatPart!.value}</span>;

  return (
    <CurrencyInput
      ref={ref}
      value={fromCents(value)}
      onChange={(e) => {
        onChange({
          target: {
            value: toCents(e.target?.value)
          }
        });
      }}
      onBlur={(e) => {
        onBlur?.({
          target: {
            value: toCents(e.target?.value)
          }
        });
      }}
      Prefix={Prefix}
      {...props}
    />
  );
});
