import React from 'react';

import {
  RecordDialog,
  RecordDialogProps
} from 'view/components/record-screen/dialog/dialog';
import { RecordSubmitHandler } from 'view/components/record-screen/utils';

import { taxAdjustmentBlock } from '../blocks/tax-adjustment-block';
import { TaxAdjustmentForm } from '../types/tax-adjustment-form';

type EditTaxAdjustmentDialogProps = {
  initialValues: Partial<TaxAdjustmentForm>;
  onClose?: RecordDialogProps['onClose'];
  submitHandler: RecordSubmitHandler<TaxAdjustmentForm>;
};

const content = [
  {
    id: 'edit-tax-adjustment',
    label: 'Edit tax adjustment',
    blocks: [taxAdjustmentBlock]
  }
];

export function EditTaxAdjustmentDialog({
  initialValues,
  onClose,
  submitHandler
}: EditTaxAdjustmentDialogProps) {
  const [isReimbursement, setIsReimbursement] = React.useState(
    !!initialValues?.bank_account
  );

  const toggleIsReimbursement = (newValue: boolean) =>
    setIsReimbursement(newValue);

  return (
    <RecordDialog
      initialValues={initialValues}
      blockProps={{ toggleIsReimbursement, isReimbursement }}
      title='Edit tax adjustment'
      submitLabel={
        isReimbursement ? 'Edit adjustment & withdrawal' : 'Edit adjustment'
      }
      onClose={onClose}
      content={content}
      handleSubmit={submitHandler}
      autofocus={false}
    />
  );
}
