import { useCallback } from 'react';
import { useModelActions } from '@rexlabs/model-generator';
import { RecordSubmitHandler } from 'view/components/record-screen/utils';
import { useRecordCreatedToast } from 'src/modules/common/toasts/hooks/use-record-created-toast';
import { taskIncludes, tasksModel } from '../../common/models/tasks-model';
import { mapWorkOrderFormDataToTaskCreateRequest } from '../mappers/map-work-order-form-data-to-task-create-request';
import { WorkOrderFormData } from '../types/WorkOrderFormData';
import { createTaskAndLinkChecklists } from '../../common/utils/create-task-and-link-checklists';
import { MaintenanceTask } from '../../maintenance/types/MaintenanceTask';

interface UseAddWorkOrderSubmitHandlerProps {
  onCreate?: (data) => void;
}

export const useAddWorkOrderSubmitHandler = ({
  onCreate
}: UseAddWorkOrderSubmitHandlerProps) => {
  const addToast = useRecordCreatedToast('task_work_order');
  const { refreshLists, refreshItem, updateItem } = useModelActions(tasksModel);

  const onHandleSubmit: RecordSubmitHandler<WorkOrderFormData> = useCallback(
    async ({ values }) => {
      const {
        should_withheld_funds: shouldWithholdFunds = false,
        withheld_funds_amount: withheldFundsAmount = 0,
        ...rest
      } = values;

      const workOrderTask = await createTaskAndLinkChecklists<'task_work_order'>(
        rest,
        mapWorkOrderFormDataToTaskCreateRequest
      );

      const { data: workOrderTaskWithParentTask } = await refreshItem({
        id: workOrderTask.id,
        args: {
          include: [
            taskIncludes.workOrderDetails,
            // include the parent task here so we can refresh it after the work order is created
            'parent_task',
            'parent_task.details',
            'parent_task.details.disbursement_withheld_funds'
          ].join(',')
        }
      });

      // Once we have created the work order, we can update the maintenance task with the withheld funds amount if necessary
      if (
        shouldWithholdFunds &&
        workOrderTaskWithParentTask?.parent_task?.type.id === 'task_maintenance'
      ) {
        const parentTask = workOrderTaskWithParentTask.parent_task as MaintenanceTask;

        await updateItem({
          id: parentTask.id,
          data: {
            details: {
              disbursement_withheld_funds: {
                amount: withheldFundsAmount,
                reason:
                  parentTask?.details?.disbursement_withheld_funds?.reason || ''
              }
            }
          }
        });
      }

      onCreate?.(workOrderTaskWithParentTask);

      addToast(workOrderTaskWithParentTask);

      refreshLists();

      return workOrderTaskWithParentTask;
    },
    [addToast, refreshLists]
  );

  return { onHandleSubmit };
};
