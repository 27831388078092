import React, { useCallback } from 'react';
import { DialogProps } from '@rexlabs/dialog';
import { useModelActions } from '@rexlabs/model-generator';

import { RecordDialog } from 'view/components/record-screen/dialog/dialog';
import { DialogContentConfig } from 'view/components/record-screen/dialog/types';
import { RecordSubmitHandler } from 'view/components/record-screen/utils';
import { filterNullValues } from 'utils/filter-null-values';
import { financialsSecurityDepositTypesModel } from 'data/models/entities/financials/security-deposit-types';
import { useToast } from 'view/components/@luna/notifications/toast';

import { useTranslation } from 'react-i18next';
import { securityDepositTypeBlock } from '../blocks/security-deposit-type';

const content: DialogContentConfig = [
  {
    id: 'basics',
    label: 'Basics',
    blocks: [securityDepositTypeBlock]
  }
];

export function CreateSecurityDepositTypeDialog({ onClose }: DialogProps) {
  const { t } = useTranslation();
  const { addToast } = useToast();
  const { createItem, refreshLists } = useModelActions(
    financialsSecurityDepositTypesModel
  );
  const handleSubmit: RecordSubmitHandler = useCallback(async ({ values }) => {
    const { data } = await createItem({ data: filterNullValues(values) });

    addToast({
      description: (
        <>
          <b>{data.name}</b> deposit successfully created
        </>
      )
    });
    await refreshLists();
    return data;
  }, []);

  const initialData = {
    default_amount: {
      type: {
        id: 'weeks_of_rent',
        label: 'Weeks of rent'
      }
    }
  };

  return (
    <RecordDialog
      onClose={onClose}
      data={initialData}
      title={t('bonds-and-deposits.create')}
      handleSubmit={handleSubmit}
      content={content}
      submitLabel='Create'
    />
  );
}
