import dayjs from 'dayjs';
import { ValueListItem } from 'utils/normaliser/value-list-item';

interface GetEndDateArgs {
  startDate: string;
  termLength: ValueListItem;
}

export function getLeaseAgreementEndDate({
  startDate,
  termLength
}: GetEndDateArgs) {
  const termLengthNum = parseInt(termLength?.label, 10);

  if (!startDate || !termLengthNum) {
    return;
  }

  return dayjs(startDate)
    .add(termLengthNum, 'month')
    .subtract(1, 'day')
    .format('YYYY-MM-DD');
}
