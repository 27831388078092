import * as React from 'react';
import dayjs from 'dayjs';
import { ActionMenuItem } from '@rexlabs/action-menu';

import { TabbedTable, Columns, ListTable } from 'view/components/table';
import { Tab } from 'view/components/table/tabbed';
import { GlobalFilter } from '@rexlabs/table/lib/types/context';

import { useGetAnyTaskActions } from 'src/modules/tasks/common/hooks/action-menu-items/use-get-any-task-actions';
import { getRecordLinkProps } from 'view/components/record-link/get-record-link-props';
import { Task } from 'src/modules/tasks/common/types/Task';
import { RecordTableProps } from 'view/components/table/record';
import { tasksModel } from 'src/modules/tasks/common/models/tasks-model';
import { TaskTableEmptyState } from 'src/modules/tasks/common/components/task-table-empty-state';
import { useTableFilters } from 'view/hooks/use-table-filters';

const filterOpen = {
  field: 'closed_at',
  op: 'eq',
  value: 'null'
};

const filterClosed = {
  field: 'closed_at',
  op: 'neq',
  value: 'null'
};

export function RecordTaskTabbedTable({
  filters = [],
  columns = [],
  getQuery,
  getActionItems,
  EmptyState = TaskTableEmptyState,
  normaliseGlobalFilter
}: {
  filters?: GlobalFilter[];
  columns?: Columns;
  getQuery?: () => any;
  getActionItems?: ({ task }: { task: Task<any> }) => ActionMenuItem[];
  EmptyState?: ({ label }: { label: string }) => JSX.Element;
  normaliseGlobalFilter?: (filters: any) => string;
}) {
  const now = dayjs().format('YYYY-MM-DD');
  const { getFilters, getSort } = useTableFilters('tasks');

  const getTaskActions = useGetAnyTaskActions();

  const getRowLinkProps = ({ item }) => {
    return getRecordLinkProps({ type: item.type.id, id: item.id });
  };

  // When the table filter changes reference, it triggers a re-request without a filter :C
  // so we're memoising them separately to ensure they don't change
  const { open, followUp, due, overdue, closed } = React.useMemo(
    () => ({
      open: [...filters, filterOpen],
      followUp: [
        ...filters,

        filterOpen,
        {
          field: 'follow_up_date',
          op: 'lte',
          value: now
        }
      ],
      due: [
        ...filters,
        filterOpen,
        {
          field: 'due_date',
          op: 'eq',
          value: now
        }
      ],
      overdue: [
        ...filters,
        filterOpen,
        { field: 'due_date', op: 'lt', value: now }
      ],
      closed: [...filters, filterClosed]
    }),
    []
  );

  const getActionMenuItems = React.useCallback(
    ({ item }) => [
      ...getTaskActions(item),
      ...(getActionItems?.({ task: item }) || [])
    ],
    [getActionItems]
  );

  const commonProps: RecordTableProps<typeof tasksModel> = React.useMemo(
    () => ({
      id: 'tasks',
      columns,
      getQuery,
      Table: ListTable,
      getFilters,
      getSort,
      normaliseGlobalFilter,
      withPagination: true,
      getRowLinkProps,
      getActionMenuItems,
      forcedGlobalFilter: [...filters]
    }),
    [getActionMenuItems]
  );

  const tabs = React.useMemo<Tab[]>(() => {
    return [
      {
        ...commonProps,
        name: 'open',
        label: 'Open',
        Empty: () => <EmptyState label='Open' />,
        forcedGlobalFilter: open
      },
      {
        ...commonProps,
        name: 'follow-up',
        label: 'Follow up',
        Empty: () => <EmptyState label='Follow up' />,
        forcedGlobalFilter: followUp
      },
      {
        ...commonProps,
        name: 'due',
        label: 'Due',
        Empty: () => <EmptyState label='Due' />,
        forcedGlobalFilter: due
      },
      {
        ...commonProps,
        name: 'overdue',
        label: 'Overdue',
        Empty: () => <EmptyState label='Overdue' />,
        forcedGlobalFilter: overdue
      },
      {
        ...commonProps,
        name: 'closed',
        label: 'Closed',
        Empty: () => <EmptyState label='Closed' />,
        forcedGlobalFilter: closed
      }
    ];
  }, [commonProps]);

  return <TabbedTable tabs={tabs} />;
}
