import { RadioGroupInput, RadioGroupInputProps } from '@rexlabs/radio-input';
import React from 'react';

export const automaticTaskCreationOptions: {
  value: string;
  label: string;
}[] = [
  {
    value: 'all_properties',
    label: 'For all properties'
  },
  {
    value: 'service_packages',
    label: 'Only for selected service packages'
  }
];

export const AutomaticTaskCreationRadioButtons = (
  props: RadioGroupInputProps
) => {
  return (
    <RadioGroupInput
      options={automaticTaskCreationOptions}
      orientation={'horizontal'}
      {...props}
    />
  );
};
