import React from 'react';

import { CellProps, TextCell } from '@rexlabs/table';

import { TwoTierLargeDescriptionCell } from 'view/components/cells/two-tier-large-description-cell';
import { OwnershipTaxTransaction } from '../types/ownership-tax-transaction';

function generateUserGeneratedDescription(
  transaction: OwnershipTaxTransaction
) {
  let description = 'Adjustment –– ';
  if (!transaction.manual_transaction_type?.id) {
    return description;
  }

  switch (transaction.manual_transaction_type.id) {
    case 'withhold_available_funds_from_ownership':
      description += 'withhold funds from this ownership';
      break;
    case 'reimburse_withheld_tax_to_owner':
      description += 'reimbursement to owner';
      break;
    case 'increase_tax_owed':
      description += 'increase tax owed';
      break;
    case 'decrease_tax_owed':
      description += 'decrease tax owed';
      break;
    default:
      break;
  }
  return description;
}

export function TaxTransactionDescriptionCell(
  props: CellProps<any, OwnershipTaxTransaction>
) {
  const { value } = props;

  const isSystemGenerated = value.manual_transaction_type === null;

  if (isSystemGenerated) {
    return <TextCell {...props} value={value.description} />;
  } else {
    return (
      <TwoTierLargeDescriptionCell
        text={generateUserGeneratedDescription(value)}
        subtext={value.description}
        lineClamp={2}
      />
    );
  }
}
