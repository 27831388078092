import React from 'react';
import { BlockConfig } from 'view/components/record-screen/types';
import { Column, Grid } from '@rexlabs/grid';
import { BooleanValue } from 'view/components/values/boolean';
import { BankAccount } from 'data/models/entities/financials/bank-accounts';
import { Value } from 'view/components/values';
import { Field } from '@rexlabs/form';
import { BooleanRadios } from 'view/components/inputs/boolean-radios';
import { TextInput } from '@rexlabs/text-input';
import { get } from 'lodash';

export const bpaySettingsBlock: BlockConfig<BankAccount> = {
  id: 'bankAccountBPaySettings',
  title: 'BPAY upload details',
  View: ({ data }) => (
    <Grid>
      <Column width={4}>
        <BooleanValue label='BPAY payments' value={data?.bpay?.is_enabled} />
      </Column>
      <Column width={4}>
        <Value label='Customer ID' value={data?.bpay?.biller_code} />
      </Column>
      <Column width={4}>
        <Value label='Customer name' value={data?.bpay?.biller_short_name} />
      </Column>
    </Grid>
  ),
  Edit: ({ values }) => {
    return (
      <Grid>
        <Column width={4}>
          <Field
            name='bpay.is_enabled'
            label='BPAY payments'
            Input={BooleanRadios}
            optional={false}
          />
        </Column>
        <Column width={4}>
          <Field
            name='bpay.biller_code'
            label='Customer ID'
            Input={TextInput}
            inputProps={{
              disabled: get(values, 'bpay.is_enabled') === false
            }}
            optional={false}
          />
        </Column>
        <Column width={4}>
          <Field
            name='bpay.biller_short_name'
            label='Customer name'
            Input={TextInput}
            inputProps={{
              disabled: get(values, 'bpay.is_enabled') === false
            }}
            optional={false}
          />
        </Column>
      </Grid>
    );
  }
};
