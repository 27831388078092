import { useDialog } from '@rexlabs/dialog';
import { SingleActionDeclaration } from 'src/modules/common/actions/types/action-declaration-types';
import { AgencyFee } from 'src/modules/agency-fees/types/agency-fee-types';
import { CreateNewAgencyFeeDialog } from '../dialogs/create-new-agency-fee';

export function useGetDuplicateAction() {
  const { open: openCreateNewAgencyFeeDialog } = useDialog(
    CreateNewAgencyFeeDialog
  );

  return (agencyFee: AgencyFee): SingleActionDeclaration => {
    return {
      id: 'agency_fee-duplicate',
      group: 'duplicate',
      label: 'Duplicate',
      handleAction: () => {
        openCreateNewAgencyFeeDialog({
          agencyFee
        });
      }
    };
  };
}
