import * as React from 'react';
import { StyleSheet, useStyles } from '@rexlabs/styling';

const styles = StyleSheet({
  visuallyHidden: {
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: '1px',
    overflow: 'hidden',
    position: 'absolute',
    whiteSpace: 'nowrap',
    width: '1px'
  },

  checkbox: {
    '&:checked + label': {
      color: 'white',
      background: ({ token }) => token('palette.green.900'),
      borderColor: ({ token }) => token('palette.green.900'),

      '&:hover': {
        borderColor: ({ token }) => token('palette.green.900')
      },

      '&:focus': {
        borderColor: ({ token }) => token('palette.green.900')
      }
    },

    '&:disabled + label': {
      opacity: 0.5,
      background: ({ token }) => token('palette.grey.400'),
      cursor: 'not-allowed',
      borderColor: ({ token }) => token('palette.grey.600')
    }
  },
  defaultCheck: {
    '&:before': {
      content: "''",
      width: 12,
      height: 7,
      position: 'absolute',
      top: 6,
      left: 5,
      border: '2px solid #fff',
      borderTop: 'none',
      borderRight: 'none',
      background: 'transparent',
      transform: 'rotate(-45deg)',
      zIndex: 1
    }
  },
  label: {
    position: 'relative',
    height: 24,
    width: 24,
    borderRadius: 8,
    borderStyle: 'solid',
    borderWidth: 1,
    // borderColor: ({ token }) => token('color.border.input.idle'),
    borderColor: '#C3E8D4',

    '&:hover': {
      borderColor: ({ token }) => token('color.border.input.hover')
    },

    '&:focus': {
      borderColor: ({ token }) => token('color.border.input.focus')
    },

    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    top: 0,
    left: 0,
    cursor: 'pointer'
  },
  primaryCheck: {
    background: ({ token }) => token('color.container.static.primary.default'),
    borderColor: ({ token }) => token('color.container.static.primary.default'),
    color: 'white'
  },
  dottedCheck: {
    background: 'transparent',
    borderColor: ({ token }) => token('palette.green.400'),
    borderStyle: 'dashed',
    color: ({ token }) => token('palette.green.900')
  }
});

export type ActionStyle = 'primary' | 'dotted' | 'solid';

export type ChecklistActionToggleProps = {
  Icon?: React.ComponentType<{ width?: number | string }>;
  checked?: boolean;
  onChange?: (e: any) => void;
  actionStyle?: ActionStyle;
  disabled?: boolean;
  id: string;
};

export function ChecklistActionToggle({
  Icon,
  checked,
  onChange,
  actionStyle,
  disabled,
  id
}: ChecklistActionToggleProps) {
  const s = useStyles(styles);

  return (
    <label>
      <input
        id={`checklist-item-${id}`}
        name={`checklist-item-${id}`}
        type='checkbox'
        disabled={disabled}
        onChange={onChange}
        checked={checked}
        aria-label='Checklist item checkbox'
        {...s('visuallyHidden', 'checkbox')}
      />
      <label
        htmlFor={`checklist-item-${id}`}
        {...s(
          'label',
          checked ? 'defaultCheck' : '',
          mapActionStyleToClass(actionStyle)
        )}
      >
        {Icon && !checked ? <Icon width={12} /> : null}
      </label>
    </label>
  );
}

function mapActionStyleToClass(actionStyle: ActionStyle | undefined) {
  switch (actionStyle) {
    case 'primary':
      return 'primaryCheck';
    case 'dotted':
      return 'dottedCheck';
    default:
      return '';
  }
}
