import React from 'react';

import {
  ListTableInner,
  PAGE_SIZES,
  ListTableCommonProps
} from './list-table-common';
import {
  QuernFnArg,
  useTableQuery,
  UseTableQueryProps
} from './use-table-query';
import { useListTableLogic } from './hooks/use-list-table-logic';

interface ListTableReactQueryProps<T>
  extends ListTableCommonProps<T>,
    UseTableQueryProps {
  //
}

/**
 * This list table uses `react-query` to fetch data from backend.
 * The prop `primaryQueryKey` is one of the keys passed for the useQuery hook and
 * `quernFn` is used to fire the api request to the backend. This is just a basic
 * async function which should a response on a successful request.
 */
export function ListTable<T extends any>({
  hashParamKey,

  pageSizes = PAGE_SIZES,

  initialSortBy: propInitialSortBy,
  initialGlobalFilter: propInitialGlobalFilter,
  initialPageSize: propInitialPageSize,
  initialPage: propInitialPage,
  initialHiddenColumns: propInitialHiddenColumns,

  primaryQueryKey,
  queryFn,
  queryOptions,

  droppableId,
  striped,
  suggestedFilters,
  getBulkActions,
  getActionMenuItems,

  emptyWithoutTable = false,
  Empty,
  Loading,

  hideToolbar,
  hidePagination,

  alwaysExpandSearch = false,
  columns: propColumns,

  shouldPersistTableSettings = true,
  blacklistTableSettingKeys = [],

  ...props
}: ListTableReactQueryProps<T>) {
  const {
    useControlledState,
    columns,
    urlStateProps,
    initialHiddenColumns
  } = useListTableLogic({
    id: props.id,
    pageSizes,
    hashParamKey,
    columns: propColumns,
    shouldPersistTableSettings,
    blacklistTableSettingKeys,
    initialSortBy: propInitialSortBy,
    initialGlobalFilter: propInitialGlobalFilter,
    initialPageSize: propInitialPageSize,
    initialPage: propInitialPage,
    initialHiddenColumns: propInitialHiddenColumns
  });

  const { entity, getTableProps: getTableQueryProps } = useTableQuery({
    primaryQueryKey,
    queryFn,
    queryOptions
  });

  const tableStateProps = getTableQueryProps();

  return (
    <ListTableInner
      {...props}
      entity={entity}
      hashParamKey={hashParamKey}
      columns={columns}
      droppableId={droppableId}
      Loading={Loading}
      Empty={Empty}
      striped={striped}
      hidePagination={hidePagination}
      hideToolbar={hideToolbar}
      getActionMenuItems={getActionMenuItems}
      getBulkActions={getBulkActions}
      emptyWithoutTable={emptyWithoutTable}
      suggestedFilters={suggestedFilters}
      alwaysExpandSearch={alwaysExpandSearch}
      tableStateProps={tableStateProps}
      initialHiddenColumns={initialHiddenColumns}
      urlStateProps={urlStateProps}
      useControlledState={useControlledState}
    />
  );
}

/**
 *  This function is used to build the query string from table state.
 *  List Table handles different things like pagination, sorting, filtering and
 *  these details are required by `queryFn` function to pass it to the backend.
 *
 *  Usage:
 *   <ListTable
 *      queryFn={(queries) => {
 *        const includes = `....`;
 *
 *        const qs = getQueryString({ ...queries, include });
 *
 *        return api.get(`/properties?${qs}`);
 *      }}
 *   />
 */
export function getQueryString(queries: QuernFnArg) {
  const query = new URLSearchParams();
  const actualKeys = {
    filter: 'q',
    sort: 'o',
    perPage: 'per_page'
  };

  for (const [key, value] of Object.entries(queries)) {
    if (value && value !== 'undefined') {
      query.set(key in actualKeys ? actualKeys[key] : key, `${value}`);
    }
  }

  const queryString = decodeURIComponent(query.toString());

  return queryString;
}
