import React from 'react';

import { Checkbox } from '@rexlabs/checkbox';
import { Field } from '@rexlabs/form';
import { Body } from '@rexlabs/text';
import { BlockConfig } from 'view/components/record-screen/types';
import { useFeatureFlags } from 'view/components/@luna/feature-flags';
import { CompleteInspectionFormData } from 'src/modules/tasks/inspections/dialogs/complete-inspection-report-dialog';
import { FLAGS } from 'utils/feature-flags';
import { InfoBanner } from 'view/components/@luna/notifications/banner';
import InfoCircleIcon from 'view/components/icons/info';

export const completeAndSyncInspectionReportBlock: BlockConfig<
  any,
  any,
  CompleteInspectionFormData
> = {
  id: 'complete-and-sync-inspection-report',
  title: 'Complete report',
  Edit: ({ blockProps }) => {
    const { hasFeature } = useFeatureFlags();

    const templateCopy = blockProps?.isSendImmediately
      ? 'and will be sent to the owners.'
      : 'and will be saved to the outbox.';

    return (
      <>
        <Body>
          By selecting &quot;Complete report&quot;, a PDF will be generated and
          stored in the documents tab for this inspection task
          {blockProps?.hasTemplatedAttachment ? `, ${templateCopy} ` : '.'}
        </Body>
        {hasFeature(FLAGS.ENTRY_EXIT_INSPECTION_REPORT) && (
          <>
            <Field<typeof Checkbox>
              optional={false}
              name='should_sync_to_property'
              label=''
              Input={Checkbox}
              inputProps={{
                label:
                  'Sync changes made from property areas in inspection report to the property record'
              }}
            />
            <InfoBanner
              Icon={InfoCircleIcon}
              description={
                'Maintain consistency between the inspection report and the property record by checking this box to apply any changes made to property areas in the report.'
              }
            />
          </>
        )}
      </>
    );
  }
};
