import {
  ChecklistTemplateItem,
  ChecklistTemplateItemGroup
} from '../types/ChecklistTemplate';
import { getLastPopulatedGroup } from './get-last-populated-group';

export function getGlobalIndexOfPreviousItem(
  checklistTemplateItemGroup: ChecklistTemplateItemGroup | undefined,
  allItems: ChecklistTemplateItem[] | undefined,
  allGroups: ChecklistTemplateItemGroup[] | undefined
) {
  const itemsForGroup = allItems?.filter(
    (item) =>
      item.checklist_template_item_group?.id === checklistTemplateItemGroup?.id
  );

  let previousItem = itemsForGroup?.[itemsForGroup.length - 1];

  if (!previousItem && checklistTemplateItemGroup) {
    const lastPopulatedGroup = getLastPopulatedGroup(
      allItems ?? [],
      allGroups ?? [],
      checklistTemplateItemGroup
    );

    if (lastPopulatedGroup) {
      const itemsForGroup = allItems?.filter(
        (item) =>
          item.checklist_template_item_group?.id === lastPopulatedGroup.id
      );
      previousItem = itemsForGroup?.[itemsForGroup.length - 1];
    } else {
      previousItem = allItems?.find(
        (item) =>
          item.id ===
          checklistTemplateItemGroup.triggered_by_checklist_template_item?.id
      );
    }
  }

  if (previousItem) {
    const previousItemIndex =
      allItems?.findIndex(
        (item: ChecklistTemplateItem) => previousItem?.id === item.id
      ) ?? null;
    return previousItemIndex !== null ? previousItemIndex : 0;
  }
  return 0;
}
