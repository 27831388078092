import React from 'react';

import { Truncate } from '@rexlabs/text';
import { CellProps } from '@rexlabs/table';

import { PropertyOwnershipFee } from 'data/models/entities/financials/property-ownerships/property-ownership-fee';
import { formatDynamicAmount } from '../utils/format-dynamic-amount';

export function FeeRateTableCell({
  value
}: CellProps<unknown, PropertyOwnershipFee>) {
  const taxInclusiveString = value.is_tax_included ? 'inc.' : 'excl.';
  return (
    <Truncate>
      {formatDynamicAmount(value.fee_amount)} {taxInclusiveString}{' '}
      {value.tax_type?.label}
    </Truncate>
  );
}
