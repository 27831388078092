import React from 'react';
import { CellProps } from '@rexlabs/table';
import { TwoTierCell } from 'src/view/components/cells/two-tier-cell';
import { PropertyTenancy } from 'src/modules/property-tenancies/types/property-tenancy-types';
import { formatDate } from 'utils/dates/format';

export function LeaseAgreementCell({ value }: CellProps) {
  const leaseAgreement: PropertyTenancy = value;

  const endDate = leaseAgreement?.agreement_end_date
    ? formatDate(leaseAgreement?.agreement_end_date)
    : null;

  return (
    <TwoTierCell
      text={leaseAgreement?.lease_type?.label}
      subtext={endDate && `expires ${endDate}`}
    />
  );
}
