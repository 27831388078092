import { useTranslation } from 'react-i18next';
import { propertyTenancyOverviewBlock } from '../blocks/property-tenancy-overview';
import { useGetAgreementDetailsBlock } from '../blocks/property-tenancy-agreement-details';

export const useCreatePropertyTenancyDialogContent = () => {
  const { t } = useTranslation();
  const agreementDetailsBlock = useGetAgreementDetailsBlock();

  return [
    {
      id: 'add-property-tenancy',
      label: t('lease-agreements.create.singular.label') as string,
      blocks: [propertyTenancyOverviewBlock, agreementDetailsBlock]
    }
  ];
};
