import { BaseModelGeneratorModel } from '@rexlabs/model-generator';
import {
  RecordTypeIdentifier,
  getRecordObject
} from 'src/modules/common/utils/Records/get-record-object';
import { getRecordLabel } from 'utils/records/get-record-label';

export const getAttachedTo = (
  attached?: BaseModelGeneratorModel,
  model?: RecordTypeIdentifier
) => {
  if (!attached || !model) {
    return [];
  }

  const recordObject = getRecordObject(attached, model);

  return [
    {
      id: attached.id,
      label: getRecordLabel(recordObject),
      record: attached,
      type: {
        id: recordObject.type
      }
    }
  ];
};
