import Box from '@rexlabs/box';
import * as React from 'react';
import { Bold } from '@rexlabs/text';

export function ConditionHeader() {
  return (
    <Box flexDirection='row' gap={12}>
      <Box width={32} flexDirection='row' justifyContent='center'>
        <Bold>C</Bold>
      </Box>
      <Box width={32} flexDirection='row' justifyContent='center'>
        <Bold>W</Bold>
      </Box>
      <Box width={32} flexDirection='row' justifyContent='center'>
        <Bold>U</Bold>
      </Box>
    </Box>
  );
}
