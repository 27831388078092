import { get, isEmpty } from 'lodash';

import Validator from '@rexlabs/validator';

import { ValueListValue } from 'data/models/types';

import { getLineItemName } from 'src/modules/disbursements/utils/get-line-item-name';
import {
  getDoAllDisbursementPaymentMethodsHaveValues,
  getDisbursementPaymentMethodsPercentageTotal
} from 'src/modules/disbursements/utils/get-percentage-total';
import { AmountType } from 'src/modules/disbursements/types/types';

function isLineItemTotalAmountSplitType(values: any, fieldName: string) {
  const lineItemName = getLineItemName(fieldName);
  const paymentMethod: ValueListValue<AmountType> = get(
    values,
    lineItemName + '.split_amount.type'
  );

  return paymentMethod.id === 'amount';
}

function getFriendlyName(name, attribute) {
  const attributeSplit = attribute.split('.');
  return [name, ...attributeSplit.slice(1)].join('.');
}

const disbursementNotMoreThan100PercentMessage =
  'Percentage fields add up to more than 100%';
Validator.register(
  'disbursementNotMoreThan100Percent',
  function (this: any) {
    const triggeredFromSubmit = this.validator.input
      .__SECRET_VALIDATOR_INTERNALS.helpers.triggeredFromSubmit;

    const friendlyName = getFriendlyName(
      'disbursement_payment_methods',
      this.attribute
    );

    const currentError = get(
      this.validator.input.__SECRET_VALIDATOR_INTERNALS.helpers.errors,
      friendlyName
    );
    const errorMessage = currentError?.message;

    // If we already have an error we want to return it
    // if we aren't running this validation on submit
    if (
      !triggeredFromSubmit &&
      errorMessage !== disbursementNotMoreThan100PercentMessage
    ) {
      return true;
    }

    if (isLineItemTotalAmountSplitType(this.validator.input, this.attribute)) {
      return true;
    }

    if (
      !getDoAllDisbursementPaymentMethodsHaveValues(
        this.validator.input.disbursement_payment_methods
      )
    ) {
      return true;
    }

    const percentageTotal = getDisbursementPaymentMethodsPercentageTotal(
      this.validator.input.disbursement_payment_methods
    );
    return percentageTotal <= 100;
  },
  disbursementNotMoreThan100PercentMessage
);

const disbursementNotLessThan100PercentMessage =
  "Percentage fields don't add up to 100%";
Validator.register(
  'disbursementNotLessThan100Percent',
  function (this: any) {
    const triggeredFromSubmit = this.validator.input
      .__SECRET_VALIDATOR_INTERNALS.helpers.triggeredFromSubmit;

    const friendlyName = getFriendlyName(
      'disbursement_payment_methods',
      this.attribute
    );

    const currentError = get(
      this.validator.input.__SECRET_VALIDATOR_INTERNALS.helpers.errors,
      friendlyName
    );
    const errorMessage = currentError?.message;

    // If we already have an error we want to return it
    // if we aren't running this validation on submit
    if (
      !triggeredFromSubmit &&
      errorMessage !== disbursementNotLessThan100PercentMessage
    ) {
      return true;
    }

    if (isLineItemTotalAmountSplitType(this.validator.input, this.attribute)) {
      return true;
    }

    if (
      !getDoAllDisbursementPaymentMethodsHaveValues(
        this.validator.input.disbursement_payment_methods
      )
    ) {
      return true;
    }

    const percentageTotal = getDisbursementPaymentMethodsPercentageTotal(
      this.validator.input.disbursement_payment_methods
    );

    return percentageTotal >= 100;
  },
  disbursementNotLessThan100PercentMessage
);

/**
 * TODO: The old logic above is very specific to disbursements. The below logic is an attempt to make it more generic.
 * While this is working for the most part, in order to remove the above logic we need to add the type checking of the
 * amount fields. This shouldn't be a lot of work, but it is a bit of a rabbit hole. I'm going to leave this for now.
 * Things to consider:
 *  - pass in the path to the type check in the ruleValue
 *  - if there is a type, check the type of the field we're checking like isLineItemTotalAmountSplitType
 *  - then when checking the total, make sure we type check the other fields too
 */

type ValidatorObject = {
  /**
   * Use this to target a specific field within an array.
   * eg. we want to target the 'split_amount.amount' field within the 'disbursement_payment_methods' array
   * we would pass in the following ruleValue: 'disbursement_payment_methods,split_amount.amount'
   */
  ruleValue: string;
  /**
   * The name of the field we are validating
   */
  attribute: string;
  validator: any;
};

function getFieldTotals(
  fieldArray: Record<string, any>[],
  fieldName: string
): number {
  return fieldArray.reduce((acc, field) => acc + get(field, fieldName), 0);
}

const notMoreThan100PercentMessage =
  'Percentage fields add up to more than 100%';
Validator.register(
  'notMoreThan100Percent',
  function (this: ValidatorObject) {
    const { ruleValue = '', attribute } = this;

    const [arrayPath, targetFieldName] = ruleValue.split(',');

    const fieldArray = get(this.validator.input, arrayPath) || [];

    const triggeredFromSubmit = this.validator.input
      .__SECRET_VALIDATOR_INTERNALS.helpers.triggeredFromSubmit;

    const currentError = get(
      this.validator.input.__SECRET_VALIDATOR_INTERNALS.helpers.errors,
      attribute
    );
    const errorMessage = currentError?.message;

    const doAllFieldsHaveValues = fieldArray.every(
      (field) => !isEmpty(get(field, targetFieldName))
    );

    if (!arrayPath || !targetFieldName) {
      return true;
    }

    // If we already have an error we want to return it
    // if we aren't running this validation on submit
    if (!triggeredFromSubmit && errorMessage !== notMoreThan100PercentMessage) {
      return true;
    }

    if (doAllFieldsHaveValues) {
      return true;
    }

    const fieldTotals = getFieldTotals(fieldArray, targetFieldName);

    return fieldTotals <= 100;
  },
  notMoreThan100PercentMessage
);

const notLessThan100PercentMessage = "Percentage fields don't add up to 100%";
Validator.register(
  'notLessThan100Percent',
  function (this: ValidatorObject) {
    const { ruleValue = '', attribute } = this;

    const [arrayPath, targetFieldName] = ruleValue.split(',');

    const fieldArray = get(this.validator.input, arrayPath) || [];

    const triggeredFromSubmit = this.validator.input
      .__SECRET_VALIDATOR_INTERNALS.helpers.triggeredFromSubmit;

    const currentError = get(
      this.validator.input.__SECRET_VALIDATOR_INTERNALS.helpers.errors,
      attribute
    );
    const errorMessage = currentError?.message;

    const doAllFieldsHaveValues = fieldArray.every(
      (field) => !isEmpty(get(field, targetFieldName))
    );

    if (!arrayPath || !targetFieldName) {
      return true;
    }

    // If we already have an error we want to return it
    // if we aren't running this validation on submit
    if (!triggeredFromSubmit && errorMessage !== notLessThan100PercentMessage) {
      return true;
    }

    if (doAllFieldsHaveValues) {
      return true;
    }

    const fieldTotals = getFieldTotals(fieldArray, targetFieldName);

    return fieldTotals >= 100;
  },
  notLessThan100PercentMessage
);
