import React from 'react';
import { InputProps } from '@rexlabs/form';

import { Dropdown } from '@rexlabs/select-old';

const items = ['Personal', 'Work'];

export function EmailTypeSelect(props: InputProps) {
  return (
    <Dropdown
      {...props}
      items={items}
      shouldAddFreeformValue
      denormaliser={(val) => val?.value}
      normaliser={(val) => ({ value: val, label: val })}
      freeformValueNormaliser={(val) => ({ value: val, label: val })}
    />
  );
}
