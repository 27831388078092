import React, { useMemo } from 'react';

import { TitleBlockProps } from 'view/components/@luna/title-block';

import { TitleBlock } from 'view/components/cards/title/title-block';

import { RecordObject, RecordType } from 'data/models/types';
import PinIcon from 'view/components/icons/pin';
import PropertyIcon from 'view/components/icons/property';

import { getRecordTitle } from 'utils/records/get-record-title';
import { titleBlockStatusTag } from 'src/modules/common/components/status-tag';
import {
  propertyManagementStatusMap,
  propertyVacancyStatusMap
} from 'src/modules/properties/models/property-model';
import { usePropertyActions } from 'src/modules/properties/actions/use-property-actions';
import { PropertiesModel } from 'src/modules/properties/types/property-types';
import { propertyStatusMap } from 'src/modules/properties/maps/property-status-map';

const type: RecordType = 'property';

type PropertyTitleBlockProps = {
  property: PropertiesModel;
};

export function PropertyTitleBlock({ property }: PropertyTitleBlockProps) {
  const {
    record_reference,
    address,
    address_string, // This is to handle legacy data
    images,
    vacancy_status,
    management_status,
    active_property_ownership
  } = property;

  const addressLabel = address?.formatted_strings?.default || address_string;

  const getPropertyActions = usePropertyActions();

  const imageSrc = useMemo(() => images?.[0]?.file?.url, [images]);

  const ViewMapLabel = useMemo(
    () =>
      function ViewMapLabel({ label }: { label?: string }) {
        return (
          <a
            target='_blank'
            rel='noopener noreferrer'
            href={`https://maps.google.com/?q=${addressLabel}`}
          >
            {label}
          </a>
        );
      },
    [addressLabel]
  );

  const subTitles: TitleBlockProps['subTitles'] = [];

  subTitles.push({
    label: addressLabel
  });

  subTitles.push({
    label: 'View on map',
    Icon: PinIcon,
    Label: ViewMapLabel
  });

  const servicePackageName = active_property_ownership?.service_package?.name;

  const tags: TitleBlockProps['tags'] = [
    titleBlockStatusTag(vacancy_status, propertyVacancyStatusMap),
    titleBlockStatusTag(management_status, propertyManagementStatusMap),
    ...(servicePackageName != null
      ? [
          {
            type: 'neutral',
            label: `Package: ${servicePackageName}`
          }
        ]
      : [])
  ];

  if (property.status.id === 'archived') {
    tags.push(titleBlockStatusTag(property.status, propertyStatusMap));
  }

  const actions = useMemo(() => getPropertyActions(property), [
    getPropertyActions,
    property
  ]);

  const title = getRecordTitle({ type, object: property } as RecordObject);

  return (
    <TitleBlock
      title={title}
      type={type}
      reference={record_reference}
      imageSrc={imageSrc}
      subTitles={subTitles}
      tags={tags}
      actions={actions}
      Icon={PropertyIcon}
    />
  );
}
