import * as React from 'react';

import { DialogProps } from '@rexlabs/dialog';

import { RecordDialog } from 'view/components/record-screen/dialog/dialog';

import { RecordSubmitHandler } from 'view/components/record-screen/utils';
import {
  modifiedTemplateContentBlock,
  ModifiedTemplateContentBlock
} from '../blocks/modified-template-content-block';

export interface EditTemplateMessageRecordDialog extends DialogProps {
  handleSubmit: RecordSubmitHandler;
  initialValues: ModifiedTemplateContentBlock;
}

const content = [
  {
    id: 'edit',
    label: 'Edit message',
    blocks: [modifiedTemplateContentBlock]
  }
];

export function EditTemplateMessageRecordDialog({
  handleSubmit,
  title,
  onClose,
  initialValues
}: EditTemplateMessageRecordDialog) {
  const handleSubmitAndClose: RecordSubmitHandler = async (formValues) => {
    await handleSubmit(formValues);
    onClose?.();
  };

  return (
    <RecordDialog
      title={title || 'Edit Message'}
      size='xl'
      submitLabel='Save'
      initialValues={initialValues}
      data={initialValues}
      content={content}
      onClose={onClose}
      handleSubmit={handleSubmitAndClose}
    />
  );
}
