import * as React from 'react';
import { RecordDialog } from 'view/components/record-screen/dialog/dialog';
import { DialogProps } from '@rexlabs/dialog';
import { RecordSubmitHandler } from 'view/components/record-screen/utils';
import { checklistTemplateItemDetailsBlock } from '../blocks/checklist-template-item-details-block';
import { TaskType } from '../../common/types/TaskType';
import { getOffsetRelatedDatesFromRelatedDatesData } from '../utils/get-offset-related-dates-from-related-dates-data';
import { useChecklistItemRelatedDatesQuery } from '../hooks/use-checklist-item-related-dates-query';

function getContent() {
  return [
    {
      id: 'checklist-item',
      label: 'Add checklist item',
      blocks: [checklistTemplateItemDetailsBlock]
    }
  ];
}

interface CreateChecklistItemDialogProps extends DialogProps {
  taskType: TaskType;
  handleSubmit: RecordSubmitHandler;
}

export function CreateChecklistItemDialog({
  onClose,
  taskType,
  handleSubmit
}: CreateChecklistItemDialogProps) {
  const content = getContent();

  const {
    data: relatedDatesData,
    isLoading
  } = useChecklistItemRelatedDatesQuery(taskType);

  const relatedDateOptions = relatedDatesData?.data?.related_dates;

  const offsetRelatedDates =
    relatedDatesData &&
    getOffsetRelatedDatesFromRelatedDatesData(relatedDatesData.data);

  return (
    <RecordDialog
      title='Subtask'
      content={content}
      onClose={onClose}
      submitLabel='Okay'
      handleSubmit={handleSubmit}
      isLoading={isLoading}
      blockProps={{
        taskType
      }}
      initialValues={{
        due_date: {
          due_date_type: 'none',
          related_date: relatedDateOptions?.[0] || null,
          offset_related_date: {
            count: 1,
            unit: {
              id: 'days',
              label: 'Day(s)'
            },
            offset: offsetRelatedDates?.[0] || null
          }
        }
      }}
    />
  );
}
