import { useDialog, useErrorDialog } from '@rexlabs/dialog';
import { ActionDeclaration } from 'src/modules/common/actions/types/action-declaration-types';
import { EditRecordDetailsDialog } from 'src/modules/common/actions/record-details/components/edit-record-details-dialog';
import { EntityModel, useModelActions } from '@rexlabs/model-generator';
import { useRecordUpdatedToast } from 'src/modules/common/toasts/hooks/use-record-updated-toast';

export function useEditRecordDetailsAction(
  model: EntityModel<any>,
  hasCustomDisplayName = true,
  hasCustomRecordReference = true
) {
  const { updateItem } = useModelActions(model);
  const { open } = useDialog(EditRecordDetailsDialog);
  const toast = useRecordUpdatedToast(model);
  const { open: openErrorDialog } = useErrorDialog();

  return (record): ActionDeclaration => ({
    id: 'edit-record-details',
    group: 'edit',
    label: 'Edit record details',
    handleAction: () =>
      open({
        hasCustomDisplayName,
        hasCustomRecordReference,
        onEdit: async (values) => {
          await updateItem({
            id: record.id,
            data: values
          }).catch((e) => {
            openErrorDialog(e);
          });

          toast(record);
        },
        record
      })
  });
}
