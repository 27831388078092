import React from 'react';
import { get } from 'lodash';
import { Field } from '@rexlabs/form';
import { TextInput } from '@rexlabs/text-input';

import { BlockConfig } from 'view/components/record-screen/types';
import { Value } from 'view/components/values';
import { BankAccount } from 'data/models/entities/financials/bank-accounts';
import { CountrySelect } from 'view/components/inputs/selects/country';
import { CurrencySelect } from 'view/components/inputs/selects/currency';
import { BankSelect } from 'view/components/inputs/selects/bank';
import { BooleanRadios } from 'view/components/inputs/boolean-radios';
import { Grid } from 'view/components/@luna/form/grid';
import { useTranslation } from 'react-i18next';
import i18n from 'src/modules/i18n/i18n';

export const detailsBlock: BlockConfig<BankAccount> = {
  id: 'bank-account-details-block',
  title: 'Account details',
  validate: {
    definitions: {
      country: { rules: 'required' },
      currency: { rules: 'required' },
      name: { rules: 'required' },
      'account.account_name': { name: 'Account name', rules: 'required' },
      'account.account_number': { name: 'Account number', rules: 'required' },
      'account.branch_code': {
        name: i18n.t(
          'bank-accounts.bank-state-branch.label.singular'
        ) as string,
        rules: 'required'
      },
      bank: { rules: 'required' },
      is_trust_account: {
        name: i18n.t('bank-accounts.is-trust-account.label') as string,
        rules: 'required'
      },
      custom_bank_code: {
        name: 'Custom bank code',
        rules: 'required_if:bank.id,other'
      },
      custom_bank_name: {
        name: 'Custom bank name',
        rules: 'required_if:bank.id,other'
      }
    }
  },
  View: ({ data }) => {
    const { t } = useTranslation();

    return (
      <Grid columns={3}>
        <Value label='Country of registration' value={data?.country.label} />
        <Value label='Currency' value={data?.currency.label} />
        <Value label='Account display name' value={data?.name} />
        <Value label='Account name' value={data?.account?.account_name} />
        <Value label='Account number' value={data?.account?.account_number} />
        <Value
          label={t('bank-accounts.bank-state-branch.label.singular')}
          value={data?.account?.branch_code}
        />
        <Value label='Bank' value={data?.bank?.label} />
        {data?.bank?.id === 'other' && (
          <Value label='Other bank code' value={data?.custom_bank_code} />
        )}
        {data?.bank?.id === 'other' && (
          <Value label='Other bank name' value={data?.custom_bank_name} />
        )}
        <Value
          label={t('bank-accounts.trust-account.label.singular') as string}
          value={data?.is_trust_account ? 'Yes' : 'No'}
        />
      </Grid>
    );
  },
  Edit: ({ values }) => {
    const { t } = useTranslation();

    return (
      <Grid columns={3}>
        <Field
          label='Country of registration'
          name='country'
          Input={CountrySelect}
        />
        <Field
          label='Currency'
          name='currency'
          Input={CurrencySelect}
          inputProps={{
            disabled: true
          }}
        />
        <Field
          label='Account display name'
          name='name'
          Input={TextInput}
          optional={false}
        />
        <Field
          label='Account name'
          name='account.account_name'
          Input={TextInput}
        />
        <Field
          label='Account number'
          name='account.account_number'
          Input={TextInput}
        />
        <Field
          label={t('bank-accounts.bank-state-branch.label.singular') as string}
          name='account.branch_code'
          Input={TextInput}
        />
        <Field label='Bank' name='bank' Input={BankSelect} />
        {get(values, 'bank.id') === 'other' && (
          <>
            <Field
              optional={false}
              label='Other bank code'
              name='custom_bank_code'
              Input={TextInput}
            />
            <Field
              optional={false}
              label='Other bank name'
              name='custom_bank_name'
              Input={TextInput}
            />
          </>
        )}
        <Field
          name='is_trust_account'
          label={t('bank-accounts.trust-account.label.singular') as string}
          Input={BooleanRadios}
          inputProps={{
            trueLabel: 'Yes',
            falseLabel: 'No'
          }}
        />
      </Grid>
    );
  }
};
