import { ActionDeclaration } from 'src/modules/common/actions/types/action-declaration-types';
import { useDialog } from '@rexlabs/dialog';
import { useCreatePropertyAreaSubmitHandler } from 'src/modules/property-areas/property-areas/hooks/use-create-property-area-submit-handler';
import { PropertyAreaRecordDialog } from 'src/modules/property-areas/property-areas/dialogs/property-area-dialog';
import { Property } from 'src/modules/properties/types/property-types';

export function useGetCreatePropertyAreaAction() {
  const { open } = useDialog(PropertyAreaRecordDialog);
  const getPropertyAreaCreateSubmit = useCreatePropertyAreaSubmitHandler();

  return ({ property }: { property: Property }): ActionDeclaration => {
    return {
      id: 'property-area',
      group: 'property-area',
      label: 'Add area',
      handleAction: async () => {
        return open({
          title: 'Add new area',
          onSubmit: getPropertyAreaCreateSubmit({ property }),
          initialValues: {},
          submitLabel: 'Add area'
        });
      }
    };
  };
}
