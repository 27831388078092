import { BlockConfig } from 'view/components/record-screen/types';

import { useGetImportReportAction } from 'src/modules/tasks/inspections/hooks/action-declarations/use-get-import-report-action';
import { useGetCompleteInspectionReportAction } from 'src/modules/tasks/inspections/hooks/action-declarations/use-get-complete-inspection-report-action';
import { useGetSendInspectionReportAction } from 'src/modules/tasks/inspections/hooks/action-declarations/use-get-send-inspection-report-action';
import { useGetDownloadReportAction } from 'src/modules/tasks/inspections/hooks/action-declarations/use-get-download-report-action';
import { useGetPreviousInspectionReports } from 'src/modules/tasks/inspections/hooks/use-get-previous-inspection-reports';
import { ActionButtons } from 'view/components/@luna/action-buttons';
import React from 'react';
import i18n from 'src/modules/i18n/i18n';
import { InspectionTask } from '../../types/InspectionTask';
import { InspectionFormData } from '../../types/InspectionFormData';
import { useGetManageInspectionReportAreasAction } from '../../hooks/action-declarations/use-get-modify-inspection-report-areas-action';

export const getEntryExitReportHeaderBlock = (
  inspection?: InspectionTask
): BlockConfig<InspectionTask, any, InspectionFormData> => ({
  id: 'entry-exit-report-header',
  title:
    inspection?.details?.type.id === 'entry_inspection'
      ? i18n.t('tasks.inspections.report.entry-inspection-report.label')
      : i18n.t('tasks.inspections.report.exit-inspection-report.label'),
  isMobileForcedCollapsed: true,
  Actions: ({ data }) => {
    const getImportReportAction = useGetImportReportAction(data);
    const getCompleteInspectionReportAction = useGetCompleteInspectionReportAction();
    const getSendInspectionReportAction = useGetSendInspectionReportAction();
    const getDownloadReportAction = useGetDownloadReportAction(data!);
    const getManageInspectionReportAreasAction = useGetManageInspectionReportAreasAction(
      data!
    );

    const isCompleteAndSentWithReportFile =
      ['report_completed', 'report_sent'].includes(
        data?.details?.status?.id || ''
      ) && data?.details?.inspection_report?.file?.download_url;

    const {
      data: previousInspectionReports,
      isFetched
    } = useGetPreviousInspectionReports(data);

    if (!data || !isFetched) {
      return null;
    }

    const sendInspectionReportAction = getSendInspectionReportAction(data!);

    return (
      <ActionButtons
        data-testid='inspection-report-actions'
        actions={[
          {
            label: 'Actions',
            actions: [
              ...(isCompleteAndSentWithReportFile
                ? [
                    getDownloadReportAction(),
                    ...(sendInspectionReportAction
                      ? [sendInspectionReportAction]
                      : [])
                  ]
                : [
                    ...(previousInspectionReports &&
                    previousInspectionReports?.length
                      ? [getImportReportAction()]
                      : []),
                    getManageInspectionReportAreasAction(),
                    getCompleteInspectionReportAction(data!)
                  ])
            ]
          }
        ]}
      />
    );
  }
});
