import React from 'react';
import { useToken } from '@rexlabs/styling';
import { BoxProps } from '@rexlabs/box';
import { ColoredCard } from 'view/components/cards/colored-card';
import { Grid } from 'view/components/@luna/form/grid';
import { Value } from 'view/components/values';
import { CurrencyValue } from 'view/components/values/currency';
import { formatCurrency } from 'utils/formatters';
import {
  FolioCommissionTemplateStructure,
  FolioCommissionTemplateTier
} from '../types/FolioCommissionTemplate';

interface ViewTemplateTierLineItemProps extends BoxProps {
  tier: FolioCommissionTemplateTier;
  templateStructureType: Extract<
    FolioCommissionTemplateStructure,
    'progressive' | 'tiered'
  >;
}

export function ViewTemplateTierLineItem({
  tier,
  templateStructureType
}: ViewTemplateTierLineItemProps) {
  const token = useToken();
  const commissionLabel = 'Apply commission';

  return (
    <ColoredCard
      intent={'neutral'}
      p={token('spacing.s')}
      pt={token('spacing.m')}
      mb={token('spacing.xl')}
      sy='1rem'
    >
      <Grid columns={2}>
        <Value
          label={
            templateStructureType === 'tiered'
              ? 'For invoices'
              : 'For invoice amount'
          }
          value={
            tier.to === null
              ? `${formatCurrency(tier.from)} and over`
              : `${formatCurrency(tier.from)} - ${formatCurrency(tier.to)}`
          }
        />
        {tier?.type?.id === 'fixed_amount' ? (
          <CurrencyValue label={commissionLabel} value={tier?.amount_integer} />
        ) : (
          <Value
            label={commissionLabel}
            value={`${tier?.amount_percentage}%`}
          />
        )}
      </Grid>
    </ColoredCard>
  );
}
