import { Includes, ListOfItems } from '@rexlabs/model-generator';

import { Generator } from 'data/models/generator';
import _ from 'lodash';
import { auditableConfig } from 'src/modules/common/auditable/auditable-config';
import { contactsModel } from 'src/modules/contacts/models/contacts';
import {
  Contact,
  TrustSummary
} from 'src/modules/contacts/types/contact-types';
import { Property } from 'src/modules/properties/types/property-types';
import { PropertyTenancy } from 'src/modules/property-tenancies/types/property-tenancy-types';
import { api } from 'utils/api/api-client';
import {
  BaseFields,
  PayableEntityFinancialSummary,
  RecordReference,
  RecordTypes,
  TimestampAttributes,
  ValueListValue
} from '../types';

export interface RentArrears {
  amount: string;
}

export interface RentCharges {
  from_date: string;
  to_date: string;
  amount: number;
}

export interface AvailableFunds {
  amount: string;
}

export type PaymentFrequency =
  | 'weekly'
  | 'fortnightly'
  | 'monthly'
  | 'quarterly'
  | 'annually';

export type Tenancy = BaseFields<typeof RecordTypes.Tenancy> &
  RecordReference &
  TimestampAttributes & {
    display_name: string;
    rent_amount: number;
    last_increase_date?: string;
    rent_arrears: RentArrears;
    available_funds: AvailableFunds;
    frequency: ValueListValue<PaymentFrequency>;
    agreement_start_date: string;
    agreement_end_date: string;
    agreement_vacate_date: string;
    agreement_signed_date: string;
    paid_to_date: string;
    surplus_amount: number;
    contacts: Contact[];
    tenants?: Tenant[];
    property_tenancies?: Includes<PropertyTenancy[]>;
    active_property_tenancy?: PropertyTenancy;
    financial_summary?: PayableEntityFinancialSummary;
    trust_summary?: TrustSummary;
    financial_contact?: Contact;
    receipting_instruction: string | null;
    payment_reference: string | null;
    related_contacts?: Includes<TenancyRelatedContact[]>;
  };

export type CreateTenancy = {
  tenants: {
    contact: {
      id: string;
    };
    role: {
      id: TenancyContactRole;
    };
    guarantors?: { id: string }[];
  }[];
};

export interface TenantsForm {
  tenants: {
    role: TenancyContactRole;
    contact: Contact;
    payment_reference?: string;
    has_guarantor?: boolean;
    guarantors?: Contact[];
    // is_primary is added via the old code in the tenancy form
    // but is not currently sent to BE
    is_primary?: boolean;
  }[];
}

export type TenancyRelatedContact = Tenant | Guarantor;

export type Guarantor = {
  role: ValueListValue<string>;
  contact: Contact;
  payment_reference?: string;
};

export type TenancyContactRole = 'tenant' | 'occupier';

export type Tenant = {
  role: ValueListValue<TenancyContactRole>;
  contact: Contact;
  payment_reference?: string;
  guarantors?: Includes<Contact[]>;
};

export type TenancyModel = Tenancy & {
  properties: { data: Property[] };
  tenants: ListOfItems<Tenant[]>;
  rent_charges: RentCharges[];
};

const config = {
  entities: {
    related: {
      tenants: {
        include: 'tenants.contact.primary_phone,tenants.guarantors'
      },
      rent_arrears: {
        include: 'rent_arrears'
      },
      rent_charges: {
        include: 'rent_charges'
      },
      available_funds: {
        include: 'available_funds'
      },
      property_tenancies: {
        include: 'property_tenancies.property,property_tenancies.rent_schedule'
      },
      active_property_tenancy: {
        include: 'active_property_tenancy'
      },
      financial_summary: {
        include: 'financial_summary'
      },
      financial_contact: {
        include: 'financial_contact',
        model: contactsModel
      },
      trust_summary: {
        include: 'trust_summary'
      },
      related_contacts: {
        include: 'related_contacts.contact.primary_phone'
      },
      ...auditableConfig
    }
  }
};

const actionCreators = {
  downloadPaymentHistory: {
    request: ({ id }: { id: string }) => {
      return api.post(`/reporting/payment-history`, {
        id,
        format: 'pdf',
        disposition: 'download_link'
      });
    },
    reduce: _.identity
  }
};

export const tenancyModel = new Generator<TenancyModel, typeof actionCreators>(
  'tenancies',
  config
).createEntityModel({ actionCreators });
