import React, { useCallback, useMemo } from 'react';
import { SearchResultItem } from 'utils/api/get-search-results';
import { ComplianceRequirementRelatedContextObject } from 'src/modules/communications/messages/types/attach-document-from-a-record';
import Box from '@rexlabs/box';
import { Heading } from '@rexlabs/text';
import {
  entryColumns,
  itemNameColumn
} from 'src/modules/compliance/utils/entry-columns';
import { ListTable } from 'view/components/table';
import { getTableComplianceEntriesQuery } from 'src/modules/compliance/common/data/compliance-entries-query';
import { GlobalFilter } from '@rexlabs/table/lib/types/context';
import { ActionButtons } from 'view/components/action-buttons';
import { transformSingleActionsToRegularActionMenuItems } from 'utils/actions/transforms';
import { useGetAddComplianceAction } from 'src/modules/compliance/common/hooks/use-get-add-compliance-action';
import { ComplianceEntry } from 'src/modules/compliance/common/models/compliance-entries';

type Props = {
  relatedRecord: SearchResultItem<ComplianceRequirementRelatedContextObject>;
  onSelectionChange?: (selectedItems: ComplianceEntry[]) => void;
};

const columns = [itemNameColumn, ...entryColumns];

const getFilters = (relatedRecord) => {
  return [
    {
      field: 'object_id',
      op: '==',
      value: relatedRecord.id
    },
    {
      field: 'object_type',
      op: '==',
      value:
        relatedRecord.type.id === 'contact' ? 'supplier' : relatedRecord.type.id
    },
    {
      field: 'status_id',
      op: '!=',
      value: 'archived'
    }
  ] as GlobalFilter[];
};

export const ComplianceRequirementsFromRelatedRecord = ({
  relatedRecord,
  onSelectionChange
}: Props) => {
  const getQuery = useCallback(() => getTableComplianceEntriesQuery(), []);
  const filter = useMemo(() => getFilters(relatedRecord), [relatedRecord]);
  const getAddComplianceAction = useGetAddComplianceAction();

  return (
    <>
      <Box
        flexDirection='row'
        alignItems='center'
        justifyContent='space-between'
        sx='1.2rem'
        flex={1}
      >
        <Heading level={3}>Insurance, Safety Checks and Certificates</Heading>
        <Box ml='2.4rem'>
          <ActionButtons
            actions={transformSingleActionsToRegularActionMenuItems([
              getAddComplianceAction({
                data: relatedRecord.record,
                label: 'Add requirement to this record'
              })
            ])}
          />
        </Box>
      </Box>
      <ListTable
        id='compliance-requirements-list'
        hideToolbar={true}
        hasColumnSelection={true}
        onSelectionChange={onSelectionChange}
        hidePagination={true}
        columns={columns}
        getQuery={getQuery}
        forcedGlobalFilter={filter}
        Empty={() => (
          <span>
            No insurance, safety checks, or certificates found on this record.
          </span>
        )}
        emptyWithoutTable={true}
      />
    </>
  );
};
