export const TENANCIES_ROUTES = {
  TENANCIES_LIST: {
    config: {
      path: '/tenancies',
      Component: require('./screens/tenancies-list').TenanciesListScreen
    },

    CREATE: {
      config: {
        path: '/tenancies/new',
        Component: require('./dialogs/create-tenancy-dialog')
          .CreateTenancyDialog
      }
    }
  },

  TENANCY: {
    config: {
      path: '/tenancy/:tenancyId',
      Component: require('./screens/tenancy-details').TenancyDetailsScreen
    },

    ADJUST_RENT: {
      config: {
        path: '/tenancy/:tenancyId/adjust-rent',
        Component: require('../financials/dialogs/rent-adjustment/dialog')
          .default
      }
    }
  }
};
