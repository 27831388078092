import React from 'react';
import { Select } from '@rexlabs/select';
import dayjs from 'dayjs';

function getTimes() {
  const times: { label: string; id: number }[] = [];

  for (let hour = 1; hour < 25; hour++) {
    const formattedTime = dayjs().hour(hour).minute(0).format('h:mma');

    times.push({
      label: formattedTime,
      id: hour
    });
  }

  return times;
}

function normaliser(item) {
  return {
    id: item.id,
    label: item.label
  };
}

export function HourSelect(props) {
  return (
    <Select
      {...props}
      placeholder='9:00am'
      items={getTimes()}
      normaliser={normaliser}
    />
  );
}
