import { Settings } from 'src/modules/settings/hooks/useSettings';
import { useGetServicePackages } from 'src/modules/service-packages/hooks/use-get-service-packages';

export function useMapPropertyComplianceSettings(
  values: Settings['property-compliance-task']
) {
  const selectedIds = values?.service_package_ids || [];

  const { data, isLoading } = useGetServicePackages(selectedIds);

  return {
    isLoading,
    propertyComplianceSettings: {
      ...values,
      service_packages: data
    }
  };
}
