export const CONTACT_ROUTES = {
  CONTACTS_LIST: {
    config: {
      path: '/contacts',
      Component: require('./screens/contacts-list').ContactsListScreen
    },

    CREATE: {
      config: {
        path: '/contacts/new',
        Component: require('./dialogs/create-contact-dialog')
          .CreateContactDialog
      }
    }
  },

  CONTACT: {
    config: {
      path: '/contact/:contactId',
      Component: require('./screens/contact-details').ContactDetailsScreen
    }
  }
};
