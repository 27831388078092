import { valueListFactory } from 'src/lib/testing/factories/value-list-factory';
import { Task } from 'src/modules/tasks/common/types/Task';
import { mapTaskFormDataToTaskCreateRequest } from '../../common/mappers/map-task-form-data-to-task-create-request';
import { TaskCreateRequest } from '../../common/types/TaskCreateRequest';

export function mapPropertyComplianceFormDataToTaskCreateRequest(
  formData: Partial<Task<'task_property_compliance'>>
): Partial<TaskCreateRequest> {
  // map the base values for the task
  const base = mapTaskFormDataToTaskCreateRequest(formData);

  return {
    ...base,
    type: valueListFactory('task_property_compliance')
  };
}
