import { useGetPrimaryRecordActionGroup } from 'view/hooks/actions/use-get-primary-record-action-group';
import { propertyOwnershipModel } from 'src/modules/property-ownerships/models/property-ownership-model';
import { useStatusChangeAction } from 'src/modules/common/actions/status/use-status-change-action';
import { ActionDeclaration } from 'src/modules/common/actions/types/action-declaration-types';
import { useDeleteAction } from 'src/modules/common/actions/delete/use-delete-action';
import {
  PropertyOwnership,
  PropertyOwnershipStatusType
} from 'src/modules/property-ownerships/types/property-ownership-types';
import { useModelActions } from '@rexlabs/model-generator';
import { propertiesModel } from 'data/models/entities/properties';
import { useEditRecordDetailsAction } from 'src/modules/common/actions/record-details/hooks/use-edit-record-details-action';
import { usePropertyOwnershipStatusAction } from 'src/modules/properties/hooks/use-property-ownership-status-action';

export function usePropertyOwnershipActions() {
  const getPrimaryRecordActionGroup = useGetPrimaryRecordActionGroup();
  const setStatusAction = useStatusChangeAction(propertyOwnershipModel);
  const getPropertyOwnershipStatusAction = usePropertyOwnershipStatusAction();
  const deleteAction = useDeleteAction(propertyOwnershipModel);
  const getEditRecordAction = useEditRecordDetailsAction(
    propertyOwnershipModel
  );
  const { refreshItem: refreshProperty } = useModelActions(propertiesModel);

  return (propertyOwnership?: PropertyOwnership) => {
    if (!propertyOwnership) return [];

    const afterStatusChange = () => {
      refreshProperty({
        id: propertyOwnership.property_id,
        args: {
          include: [
            'property_ownerships',
            'active_property_ownership',
            'active_property_ownership.ownership',
            'active_property_ownership.ownership.owners.contact.primary_phone',
            'active_property_ownership.ownership.financial_summary',
            'active_property_tenancy',
            'active_property_tenancy.tenancy.tenants.contact.primary_phone',
            'compliance_categories',
            'compliance_categories.category_requirements',
            'features',
            'images.file',
            'incoming_property_tenancy',
            'incoming_property_tenancy.tenancy.tenants.contact.primary_phone',
            'incoming_property_ownership',
            'incoming_property_ownership.ownership',
            'incoming_property_ownership.ownership.owners.contact.primary_phone',
            'links',
            'views',
            'portfolio.users',
            'portfolio.users.contact.primary_phone',
            'created_by',
            'updated_by'
          ].join(',')
        }
      });
    };

    const activateAction = setStatusAction<PropertyOwnershipStatusType>({
      record: propertyOwnership,
      status: 'active',
      dialogOptions: {
        type: 'none',
        afterAction: afterStatusChange
      }
    });

    const archiveAction = getPropertyOwnershipStatusAction({
      record: propertyOwnership,
      status: 'archived',
      label: 'Archive',
      dialogOptions: {
        afterAction: afterStatusChange
      }
    });

    const outgoingAction = getPropertyOwnershipStatusAction({
      record: propertyOwnership,
      status: 'outgoing',
      label: 'Set as outgoing',
      dialogOptions: {
        afterAction: afterStatusChange
      }
    });

    const incomingAction = setStatusAction<PropertyOwnershipStatusType>({
      record: propertyOwnership,
      status: 'incoming',
      dialogOptions: {
        afterAction: afterStatusChange
      }
    });

    const draftAction = setStatusAction<PropertyOwnershipStatusType>({
      record: propertyOwnership,
      status: 'draft',
      dialogOptions: {
        afterAction: afterStatusChange
      }
    });

    const statusActions: ActionDeclaration[] = [];
    switch (propertyOwnership.status.id) {
      case 'active':
        statusActions.push(
          draftAction,
          incomingAction,
          outgoingAction,
          archiveAction
        );
        break;
      case 'draft':
        statusActions.push(
          activateAction,
          incomingAction,
          outgoingAction,
          archiveAction,
          deleteAction(propertyOwnership, {
            afterAction: afterStatusChange
          })
        );
        break;
      case 'incoming':
        statusActions.push(
          draftAction,
          activateAction,
          outgoingAction,
          archiveAction
        );
        break;
      case 'outgoing':
        statusActions.push(
          draftAction,
          activateAction,
          incomingAction,
          archiveAction
        );
        break;
      case 'archived':
        statusActions.push(
          draftAction,
          activateAction,
          incomingAction,
          outgoingAction
        );
        break;
    }

    return [
      ...getPrimaryRecordActionGroup(
        'property_ownership',
        propertyOwnership.id,
        'View',
        propertyOwnership.property_id
      ),
      getEditRecordAction(propertyOwnership),
      ...statusActions
    ];
  };
}
