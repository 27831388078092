import libPluralize from 'pluralize';

/**
 * NUMBERS & CURRENCY
 */
import { queryClient } from 'src/lib/react-query/query-client';
import { Settings } from 'src/modules/settings/hooks/useSettings';

export function formatCurrency(
  amount: string | number | null | undefined,
  decimals = 2
): string {
  const settingStore = queryClient.getQueryData('settings') as any;
  const {
    currency,
    ['negative-currency-format']: negativeCurrencyFormat
  } = Object.fromEntries(
    (settingStore?.data ?? []).map(({ key, value }) => [key, value])
  ) as Settings;

  if (amount === null || amount === undefined) {
    return '-';
  }

  const formattedAmount =
    (typeof amount === 'number' ? amount : Number.parseFloat(amount)) / 100;

  const intlFormat = new window.Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: currency?.id ?? 'AUD',
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals
  });

  const type = negativeCurrencyFormat?.id;

  if (type === 'dash_prefixed') {
    return intlFormat.format(formattedAmount);
  }

  if (Math.sign(formattedAmount) === -1) {
    return `(${intlFormat.format(Math.abs(formattedAmount))})`;
  }

  return intlFormat.format(formattedAmount);
}

export const toCents = (amount: string | number = 0): number | null => {
  if (typeof amount === 'string') {
    if (!amount || !amount.trim()) {
      return null;
    }

    return Math.round(
      Number.parseFloat(amount.replace(/[^0-9.,-]/g, '')) * 100
    );
  }

  return Math.round(amount * 100);
};

export const fromCents = (amount: number | null = 0): number | null => {
  if (amount === null) {
    return null;
  }

  return amount / 100;
};

/**
 *
 * @param word the word you want to pluralize or singularize
 * @param length the number of "things" you want to pluralize or singularize for
 * @param showPrefix whether you want to show the prefix or not e.g. "1 item" or "2 items"
 * @returns the pluralized or singularized word
 */
export function pluralize(
  word: string,
  length: number,
  showPrefix = false
): string {
  return libPluralize(word, length, showPrefix);
}
