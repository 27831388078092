import React from 'react';
import { BlockConfig } from 'view/components/record-screen/types';
import { Column, Grid } from '@rexlabs/grid';
import { BooleanValue } from 'view/components/values/boolean';
import { BankAccount } from 'data/models/entities/financials/bank-accounts';
import { Value } from 'view/components/values';
import { Field } from '@rexlabs/form';
import { BooleanRadios } from 'view/components/inputs/boolean-radios';
import { TextInput } from '@rexlabs/text-input';
import { get } from 'lodash';

export const abaSettingsBlock: BlockConfig<BankAccount> = {
  id: 'abaSettingsBlock',
  title: 'ABA bank file upload details',
  View: ({ data }) => (
    <Grid>
      <Column width={3}>
        <BooleanValue label='ABA bank files' value={data?.aba?.is_enabled} />
      </Column>
      <Column width={3}>
        <Value
          label='Direct entry user ID'
          value={data?.aba?.direct_entry_user_id}
        />
      </Column>
      <Column width={3}>
        <Value
          label='Max transactions per upload'
          value={data?.aba?.max_transactions_per_upload}
        />
      </Column>
      <Column width={3}>
        <BooleanValue
          label='Self-balancing transactions?'
          value={data?.aba?.is_self_balancing_transaction_included}
        />
      </Column>
    </Grid>
  ),
  Edit: ({ values }) => (
    <Grid>
      <Column width={3}>
        <Field
          name='aba.is_enabled'
          label='ABA bank files'
          Input={BooleanRadios}
          optional={false}
        />
      </Column>
      <Column width={3}>
        <Field
          name='aba.direct_entry_user_id'
          label='Direct entry user ID'
          Input={TextInput}
          inputProps={{
            disabled: get(values, 'aba.is_enabled') === false
          }}
          optional={false}
        />
      </Column>
      <Column width={3}>
        <Field
          name='aba.max_transactions_per_upload'
          label='Max transactions per upload'
          Input={TextInput}
          inputProps={{
            disabled: get(values, 'aba.is_enabled') === false
          }}
          optional={false}
        />
      </Column>
      <Column width={3}>
        <Field
          name='aba.is_self_balancing_transaction_included'
          label='Self-balancing transactions?'
          Input={BooleanRadios}
          inputProps={{
            disabled: get(values, 'aba.is_enabled') === false
          }}
          optional={false}
        />
      </Column>
    </Grid>
  )
};
