import { Options, useCachedItems } from 'view/hooks/api/cached-items';
import { api } from 'utils/api/api-client';
import { SearchResultItem } from 'utils/api/get-search-results';
import { RecordData } from 'utils/types';

export interface UseGetSuggestedPropertiesParams
  extends Partial<Options<SearchResultItem<RecordData<any>>>> {
  filters: string;
}

export function useGetSuggestedProperties({
  filters
}: UseGetSuggestedPropertiesParams) {
  const propertyUrl = `/properties`;

  const queryParams = new URLSearchParams();
  queryParams.set('q', filters);
  const query = queryParams.toString();

  return useCachedItems([propertyUrl, filters].join('-'), async () => {
    const propertyRequest = await api.get(`${propertyUrl}?${query}`);

    return propertyRequest.data as SearchResultItem[];
  });
}
