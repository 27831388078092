import { SecurityDeposit } from 'data/models/entities/financials/security-deposits';
import { SingleActionDeclaration } from 'src/modules/common/actions/types/action-declaration-types';
import { useGetCreateRequest } from './use-get-create-request';
import { useGetCreateSecurityDepositTopupRequestAction } from './use-get-create-security-deposit-topup-request-action';
import { useGetCreateSecurityDepositReturnRequestAction } from './use-get-create-security-deposit-return-request-action';

export function useGetSecurityDepositRequestActionGroup() {
  const getCreateSecurityDepositRequestAction = useGetCreateRequest();
  const getCreateSecurityDepositTopupRequestAction = useGetCreateSecurityDepositTopupRequestAction();
  const getCreateSecurityDepositReturnRequestAction = useGetCreateSecurityDepositReturnRequestAction();

  return (securityDeposit: SecurityDeposit): SingleActionDeclaration[] => [
    getCreateSecurityDepositRequestAction(securityDeposit),
    getCreateSecurityDepositTopupRequestAction(securityDeposit),
    getCreateSecurityDepositReturnRequestAction(securityDeposit)
  ];
}
