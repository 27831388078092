import * as React from 'react';
import { StatusTagMap } from 'src/modules/common/components/status-tag';
import { MaintenanceTaskStatus } from '../types/MaintenanceStatus';
import { TaskStatusSelect } from '../../common/components/task-status-select';

export const taskStatusMap: StatusTagMap<MaintenanceTaskStatus> = {
  job_logged: 'neutral',
  quotes_requested: 'average',
  quotes_received: 'average',
  quotes_approved: 'average',
  work_order_issued: 'average',
  work_order_assigned: 'average',
  work_finished: 'good'
};

export function MaintenanceStatusSelect(props) {
  return (
    <TaskStatusSelect
      taskStatusMap={taskStatusMap}
      valueListId={'maintenance_job_status'}
      {...props}
    />
  );
}
