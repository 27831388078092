import React from 'react';
import { Body } from '@rexlabs/text';
import { Dialog } from 'view/components/@luna/dialog';
import { ButtonGroup, PrimaryButton } from '@rexlabs/button';
import { DialogProps, useErrorDialog } from '@rexlabs/dialog';
import { api } from 'utils/api/api-client';
import { useToast } from 'view/components/@luna/notifications/toast';
import { Checkbox } from '@rexlabs/checkbox';

export interface RegenerateInvoicesForPropertyTenancyDialogProps
  extends DialogProps {
  propertyTenancyId: string;
}

export function RegenerateInvoicesForPropertyTenancyDialog({
  propertyTenancyId,
  onClose
}: RegenerateInvoicesForPropertyTenancyDialogProps) {
  const [enableRegen, setEnableRegen] = React.useState(false);

  const { addToast } = useToast();
  const errorDialog = useErrorDialog();

  const handleConfirm = React.useCallback(async () => {
    if (enableRegen) {
      try {
        await api.post(
          `/property-tenancies/${propertyTenancyId}/regenerate-invoices`
        );

        addToast({
          description: 'Invoices have been successfully regenerated.'
        });
      } catch (error) {
        errorDialog.open(error);
      }
    }
    return onClose?.();
  }, [addToast, enableRegen, onClose, propertyTenancyId]);

  return (
    <Dialog title='Regenerate invoices' onClose={onClose}>
      <Body>
        The changes to this rent schedule may have an impact on rent invoices
        that have already been generated.
      </Body>
      <Body>
        In some cases, rent invoices don&apos;t align to the rent position.
      </Body>
      <Checkbox
        label='Regenerate rent invoices'
        description='Invoices that have already had the complete balance paid will not be regenerated.'
        onChange={() => setEnableRegen((old) => !old)}
        checked={enableRegen}
      />
      <ButtonGroup end>
        <PrimaryButton onClick={handleConfirm}>Confirm</PrimaryButton>
      </ButtonGroup>
    </Dialog>
  );
}
