import { useWhereabouts } from '@rexlabs/whereabouts';
import { Columns } from 'src/view/components/table';
import { WorkOrderTask } from '../types/WorkOrderTask';
import { SummaryCell } from '../../common/components/summary-cell';
import { closedDate, managedBy } from '../../common/utils/common-columns';
import { AnyTaskSummaryCell } from '../../common/components/any-task-summary-cell';
import { Task } from '../../common/types/Task';
import { ProgressDropdown } from '../components/progress-dropdown';

const summary = {
  id: 'summary',
  Header: 'Summary',
  Cell: SummaryCell,
  accessor: (item: WorkOrderTask) => item,
  width: 380,
  toCsv: (item: WorkOrderTask) => item.summary
};

const relatesToTask = {
  id: 'parent_task',
  Header: 'Relates to task',
  Cell: AnyTaskSummaryCell,
  accessor: (item: WorkOrderTask) => item.parent_task,
  width: 200,
  toCsv: (item: Task<any>) => item?.summary || '-'
};

const priority = {
  id: 'priority_id',
  type: 'value',
  Header: 'Priority',
  accessor: (item: WorkOrderTask) => item.priority,
  toCsv: (item: WorkOrderTask) => item?.priority?.label
} as const;

const status = {
  id: 'status',
  Header: 'Task progress',
  Cell: ProgressDropdown,
  accessor: (item: WorkOrderTask) => item,
  width: 200,
  toCsv: (item: WorkOrderTask) => item.details?.status?.label ?? ''
};
const followUpDate = {
  id: 'follow_up_date',
  Header: 'Follow up date',
  accessor: (item: WorkOrderTask) => item.follow_up_date,
  type: 'date',
  width: 140
} as const;

const price = {
  id: 'price',
  type: 'currency',
  Header: 'Price',
  accessor: (item: WorkOrderTask) => item.details?.amount || null,
  width: 140
} as const;

const dueDate = {
  id: 'due_date',
  Header: 'Expected completion date',
  type: 'date',
  width: 200
} as const;

export function useWorkOrderColumns(): Columns<WorkOrderTask> {
  const whereabouts = useWhereabouts();
  const tab = whereabouts.hashQuery?.tab;

  if (tab === 'closed') {
    return [
      summary,
      relatesToTask,
      closedDate,
      dueDate,
      price,
      managedBy,
      priority
    ];
  }

  return [
    summary,
    relatesToTask,
    followUpDate,
    dueDate,
    price,
    status,
    managedBy,
    priority
  ];
}
