import { RentLineItem } from 'src/modules/financials/hooks/use-rent-line-items';
import { LineItemActionItemParams } from 'src/modules/financials/types';

interface UseGetAddRentLineItemActionParams {
  unusedRentLineItems: RentLineItem[];
}

export function useGetAddRentLineItemAction({
  unusedRentLineItems
}: UseGetAddRentLineItemActionParams) {
  return ({ push }: LineItemActionItemParams) =>
    unusedRentLineItems.map((rentLineItem) => ({
      label: `Add rent: ${rentLineItem.rent.property.display_name}`,
      onClick: () => {
        push(rentLineItem);
      }
    }));
}
