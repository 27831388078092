import React, { FC } from 'react';

import { Select, SelectProps } from '@rexlabs/select';

import { useSettingsListQuery } from 'src/modules/settings/hooks/use-settings-list-query';

import { ValueListItem } from 'utils/normaliser/value-list-item';

export const SupplierComplianceCategorySelect: FC<
  SelectProps<ValueListItem>
> = (props) => {
  const { data = [] } = useSettingsListQuery('supplier-compliance-categories');

  return <Select normaliser={(item) => item} items={data} {...props} />;
};
