import React from 'react';

import { BlockConfig } from 'view/components/record-screen/types';
import Box from '@rexlabs/box';
import { Message } from '../types/Message';
import { LetterPreview } from '../../common/components/letter-preview';
import { mapMessageToLetterPreviewProps } from '../mappers/map-message-to-letter-preview-props';

// TODO: it seems like a bit of a hack to use the edit property here, but it is the only way to display "view" data in a record dialog
// TODO: might be worth creating a dedicated "view" dialog in the future if this becomes a common use case
export const letterPreviewBlock: BlockConfig<Message> = {
  id: 'preview',
  title: 'Preview letter',
  Edit: ({ data }) => {
    return (
      <Box sy='6rem'>
        {/* TODO: this is basically just the email preview. Should invoke the backend to get the HTML of the actual letter here, maybe. */}
        <LetterPreview {...mapMessageToLetterPreviewProps(data)} />
      </Box>
    );
  }
};
