import React from 'react';
import { StyleSheet, border, padding, text, useStyles } from '@rexlabs/styling';

export const cellStyles = StyleSheet({
  cell: {
    position: 'relative',
    display: 'table-cell',
    overflow: 'hidden',
    textAlign: 'left',

    ...text.styles({
      target: 'table.cell',
      fallback: 'normal.default'
    }),
    ...padding.styles({
      target: 'table.cell',
      x: 'm',
      y: () => '1.4rem'
    }),

    height: ({ token }) => token('table.row.minHeight', '4.8rem'),
    verticalAlign: ({ token }) => token('table.row.verticalAlign', 'top'),
    backgroundColor: ({ token }) =>
      token(
        'table.row.background.color',
        token('color.container.interactive.idle')
      ),

    '&:first-child': {
      paddingLeft: ({ token }) => token('table.row.firstCell.padding.left')
    },

    '&:last-child': {
      paddingRight: ({ token }) => token('table.row.lastCell.padding.right')
    }
  }
});
// Styles pulled straight from vivid
const styles = StyleSheet({
  textAlignRight: {
    textAlign: 'right'
  },

  paddingLeft: {
    paddingLeft: '20px'
  },

  bottomBorder: {
    ...border.styles({
      target: 'table.cell',
      bottom: {
        width: 'thin',
        color: 'container.static.light'
      }
    })
  }
});

export function Cell({
  children,
  withCellStyles = true,
  withBottomBorder = true,
  withPaddingLeft = false,
  withTextAlignRight = false,
  ...rest
}: {
  children?: React.ReactNode;
  withCellStyles?: boolean;
  withBottomBorder?: boolean;
  withPaddingLeft?: boolean;
  withTextAlignRight?: boolean;
} & React.TdHTMLAttributes<HTMLTableCellElement>) {
  const s = useStyles({ ...styles, ...cellStyles });

  return (
    <td
      {...s.with({
        cell: withCellStyles,
        bottomBorder: withBottomBorder,
        paddingLeft: withPaddingLeft,
        textAlignRight: withTextAlignRight
      })()}
      {...rest}
    >
      {children}
    </td>
  );
}
