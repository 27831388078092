import * as React from 'react';
import { DialogProps, useDialog, useErrorDialog } from '@rexlabs/dialog';
import { RecordDialog } from 'view/components/record-screen/dialog/dialog';
import { RecordObject } from 'data/models/types';
import { Forms } from '@rexlabs/form';
import invariant from 'invariant';
import { RecordSubmitHandler } from 'view/components/record-screen/utils';
import { useModelActions } from '@rexlabs/model-generator';
import { extractValuesFromForms } from 'src/modules/common/utils/extract-values-from-forms';
import { useToast } from 'view/components/@luna/notifications/toast';
import { bulkMessageDetailsBlock } from '../blocks/bulk-message-details-block';
import { MessageTemplate } from '../../message-templates/types/MessageTemplate';
import { CommunicationContextType } from '../../common/types/communication-context-type';
import { bulkEmailMessageContentBlock } from '../blocks/bulk-email-message-content-block';
import { mapBulkCreateEmailFormToRequest } from '../mappers/map-bulk-create-email-form-to-request';
import { messagesModel } from '../models/messages-model';
import {
  MessageCreateDialogButtonGroup,
  MessageCreateDialogButtonGroupProps
} from '../components/message-create-dialog-button-group';
import { BulkCreateMessageRequest } from '../mappers/types/bulk-create-message-request';
import { emptyMessageCreatorItem } from '../../common/data/empty-message-creator-item';
import { BulkCreateEmailMessageFormData } from '../types/BulkCreateEmailMessageFormData';
import { BulkPreviewEmailMessageFormDataDialog } from './bulk-preview-email-message-form-data-dialog';

export interface BulkCreateEmailRecordDialogProps extends DialogProps {
  data: {
    recordObjects: RecordObject[];
    contextType: CommunicationContextType;
  };
}

const content = [
  {
    id: 'bulk-create-message-record-dialog',
    label: 'Create email message',
    blocks: [bulkMessageDetailsBlock, bulkEmailMessageContentBlock]
  }
];

const SEND_NOW = Symbol('SEND_NOW');
const SEND_LATER = Symbol('SEND_LATER');

export function BulkCreateEmailRecordDialog({
  onClose,
  data
}: BulkCreateEmailRecordDialogProps) {
  const { bulkCreateItems, refreshLists } = useModelActions(messagesModel);
  const errorDialog = useErrorDialog();
  const { open: openPreviewBulkMessageDialog } = useDialog(
    BulkPreviewEmailMessageFormDataDialog
  );
  const { addToast } = useToast();
  const { recordObjects, contextType } = data;

  const getHandlers = (forms?: Forms) => ({
    // When the template changes, we want to update the subject and content in a different block
    handleTemplateChange: (event) => {
      if (!forms) {
        return;
      }
      const messageTemplate: MessageTemplate = event.target.value;
      const channel = (messageTemplate.channels || []).find(
        (channel) => channel.channel_type.id === 'email'
      );
      const { setFieldValue: setDetailsFormFieldValue } = forms['details'];
      const { setFieldValue: setContentFormFieldValue } = forms['content'];
      setDetailsFormFieldValue('sent_from', messageTemplate.send_from_author);
      setDetailsFormFieldValue('recipient_group', {
        name: messageTemplate.default_recipient_group
      });
      setContentFormFieldValue('subject', channel?.email_subject);
      setContentFormFieldValue('content', channel?.plain_text_body);

      if (messageTemplate.templated_attachments?.length) {
        messageTemplate.templated_attachments.map((templatedAttachment) => {
          setContentFormFieldValue(
            `include_generated_attachments.${templatedAttachment}`,
            true
          );
        });
      } else {
        setContentFormFieldValue(`include_generated_attachments`, null);
      }
    }
  });

  const initialValues = {
    context_type: contextType,
    sent_from: emptyMessageCreatorItem,
    attachments: []
  };

  const blockProps = {
    recordObjects,
    contextType,
    messageType: 'email'
  };

  const clickedButtonRef = React.useRef<symbol | null>(null);

  // To reduce duplication, handle the actual creation flow here, and let the handlers invoke this with the necessary data
  const createBulkMessages = React.useCallback(
    async (data: BulkCreateMessageRequest) => {
      await bulkCreateItems({ data });
      return refreshLists();
    },
    [bulkCreateItems, refreshLists]
  );

  // Because of the split button, we handle the actual api call in the actions below. To leverage the built-in validation though, we can do the transformation here
  const handleSubmit: RecordSubmitHandler<BulkCreateEmailMessageFormData> = React.useCallback(
    async ({ values }) => {
      const type = clickedButtonRef.current;
      const data = await mapBulkCreateEmailFormToRequest(values, recordObjects);

      switch (type) {
        // If we are sending now, we need to take the response from the submission handler, and inject a value into it to tell the backend to send now
        case SEND_NOW: {
          try {
            const modifiedData: BulkCreateMessageRequest = {
              ...data,
              send_immediately: true
            };

            addToast({
              description: (
                <>
                  Your <b>email message</b> is being created and sent now
                </>
              )
            });

            return createBulkMessages(modifiedData);
          } catch (error) {
            invariant(error instanceof Error, 'err should be Error');

            errorDialog.open({
              message: error.message
            });
          }

          break;
        }

        // If we are sending later we can just take the result from the submission handler, and send it to the api
        case SEND_LATER: {
          try {
            addToast({
              description: (
                <>
                  Your <b>email message</b> is being created and will be waiting
                  for you to send
                </>
              )
            });

            return createBulkMessages(data);
          } catch (error) {
            invariant(error instanceof Error, 'err should be Error');

            errorDialog.open({
              message: error.message
            });
          }

          break;
        }

        default: {
          return;
        }
      }
    },
    [recordObjects, createBulkMessages, addToast, errorDialog]
  );

  const handlePreview = React.useCallback(
    (forms) => {
      const formData: BulkCreateEmailMessageFormData = extractValuesFromForms(
        forms
      );

      openPreviewBulkMessageDialog({ data: { formData, recordObjects } });
    },
    [openPreviewBulkMessageDialog, recordObjects]
  );

  const handleSendNow = React.useCallback(() => {
    clickedButtonRef.current = SEND_NOW;
  }, []);

  const handleSendLater = React.useCallback(() => {
    clickedButtonRef.current = SEND_LATER;
  }, []);

  return (
    <RecordDialog
      title='Create email message'
      size='xl'
      data={initialValues}
      blockProps={blockProps}
      content={content}
      onClose={onClose}
      handleSubmit={handleSubmit}
      getHandlers={getHandlers}
      submitLabel='Send email now'
      ButtonGroup={MessageCreateDialogButtonGroup}
      buttonGroupProps={
        {
          handlePreview,
          handleSendLater,
          handleSendNow
        } as MessageCreateDialogButtonGroupProps
      }
    />
  );
}
