import React, { useCallback } from 'react';

import { RecordDialog } from 'view/components/record-screen/dialog/dialog';

import { RecordSubmitHandler } from 'view/components/record-screen/utils';
import { BaseModelGeneratorModel } from '@rexlabs/model-generator';
import {
  recordDetailsBlock,
  RecordDetailsFormValues
} from './record-details-block';

export type EditedRecordDetails = Pick<
  RecordDetailsFormValues,
  'custom_record_reference'
>;

export interface RecordWithCustomDisplayNameAndReference
  extends BaseModelGeneratorModel {
  custom_record_reference: string;
  is_custom_display_name: boolean;
  custom_display_name: string;
}

interface EditRecordDetailsDialogProps {
  onClose?: () => void;
  onEdit: (values: EditedRecordDetails) => void;
  hasCustomDisplayName: boolean;
  hasCustomRecordReference: boolean;
  record: RecordWithCustomDisplayNameAndReference;
}

export function EditRecordDetailsDialog({
  onEdit,
  onClose,
  hasCustomDisplayName,
  hasCustomRecordReference,
  record
}: EditRecordDetailsDialogProps) {
  const handleSubmit: RecordSubmitHandler = useCallback(
    async ({ values }) => {
      onEdit({
        ...values,
        custom_record_reference: values.is_custom_record_reference
          ? values.custom_record_reference
          : null
      });
      return true;
    },
    [onEdit]
  );

  const initialValues = {
    is_custom_record_reference: !!record.custom_record_reference,
    is_custom_display_name: record.is_custom_display_name,
    custom_display_name: record.custom_display_name,
    custom_record_reference: record.custom_record_reference
  };

  return (
    <RecordDialog
      onClose={onClose}
      title='Edit record details'
      data={initialValues}
      handleSubmit={handleSubmit}
      content={[
        {
          id: 'basics',
          label: 'Basics',
          blocks: [
            recordDetailsBlock(hasCustomDisplayName, hasCustomRecordReference)
          ]
        }
      ]}
      submitLabel='Update record'
    />
  );
}
