import * as React from 'react';
import { StyleSheet, useStyles } from '@rexlabs/styling';
import Info from 'view/components/icons/info';
import Tooltip from '@rexlabs/tooltip';
import Box from '@rexlabs/box';

const defaultStyles = StyleSheet({
  iconContainer: {
    display: 'flex',
    marginLeft: ({ token }) => token('spacing.xs')
  }
});

// TODO: if the info header is used more than once, extract to a generic component
export function IncludeForNewPropertiesHeader() {
  const s = useStyles(defaultStyles);

  return (
    <Box alignItems={'end'}>
      Included for new properties{' '}
      <Tooltip
        Content={() => (
          <>
            <p>
              When ‘Yes’ is selected, one instance of this room type will be
              created for each new property. You can later add additional
              instances in the property record.
            </p>
            <p>
              For area types marked ‘If specified when creating the property’,
              the number of instances will be determined by the number of
              ‘Bedrooms’, ‘Ensuites’, and ‘Bathrooms’ specified in the create
              property dialog.
            </p>
          </>
        )}
      >
        <div {...s('iconContainer')}>
          <Info />
        </div>
      </Tooltip>
    </Box>
  );
}
