import { Columns } from 'view/components/table';
import { FeeRateTableCell } from '../components/fee-rate-table-cell';

export const activeFeesColumns: Columns = [
  {
    id: 'agency_fee.name',
    Header: 'Fee template name'
  },
  {
    id: 'agency_fee.trigger_type.label',
    Header: 'Incurred when'
  },
  {
    id: 'fee_amount',
    accessor: (item) => item,
    Cell: FeeRateTableCell,
    cellProps: {
      align: 'right'
    },
    Header: 'Ownership rate'
  }
];
