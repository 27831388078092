import Box from '@rexlabs/box';
import { push } from '@rexlabs/whereabouts';
import { PropertyComplianceCategory } from 'data/models/entities/settings/property-compliance-categories';
import { SupplierComplianceCategory } from 'data/models/entities/settings/supplier-compliance-categories';
import React from 'react';
import ROUTES from 'routes/app';
import { Banner } from 'src/modules/common/components/banner';
import { pluralize } from 'utils/formatters';
import { ActionButtons } from 'view/components/action-buttons';
import InfoIcon from 'view/components/icons/info';

export function EmptyCategorySupplierComplianceBanner({
  emptyCategories
}: {
  emptyCategories: PropertyComplianceCategory[] | SupplierComplianceCategory[];
}) {
  return (
    <Banner intent={'information'} Icon={InfoIcon} align={'start'}>
      <Box
        alignItems={'center'}
        justifyContent={'space-between'}
        width={'100%'}
      >
        <div>
          There are currently no requirements in the following{' '}
          {pluralize('service', emptyCategories.length)} offered:{' '}
          {emptyCategories.map((category) => category.label).join(', ')}.
        </div>
        <ActionButtons
          actions={[
            {
              label: 'Add requirement',
              onClick: () => push(ROUTES.SUPPLIER_COMPLIANCE_SETTINGS)
            }
          ]}
        />
      </Box>
    </Banner>
  );
}
