import { ActionMenuItem } from '@rexlabs/action-menu';
import { transformActionDeclarationsToActionMenuItems } from 'utils/actions/transforms';
import { useGetPrimaryRecordActionGroup } from 'view/hooks/actions/use-get-primary-record-action-group';
import { useGetAddNotesAction } from 'src/modules/tasks/common/actions/get-add-notes-action';
import { useGetReceiptFundsAction } from 'src/modules/financials/utils/financial-entity-action-group/use-get-receipt-funds-action';
import { useGetPaymentHistoryDownloadAction } from 'src/modules/tenancies/actions/use-get-payment-history-download-action';
import { useGetCreateEmailAction } from 'src/modules/communications/messages/hooks/action-declarations/use-get-create-email-action';
import { tasksModel } from 'src/modules/tasks/common/models/tasks-model';
import { useGetCreateSMSAction } from 'src/modules/communications/messages/hooks/action-declarations/use-get-create-sms-action';
import { useGetAddDocumentsAction } from 'src/modules/documents/actions/use-get-add-documents-action';
import { ArrearsTask } from '../../types/ArrearsTask';

export function useGetArrearsActions() {
  const getPrimaryRecordActionGroup = useGetPrimaryRecordActionGroup();
  const getAddDocumentsAction = useGetAddDocumentsAction();
  const getAddNotesAction = useGetAddNotesAction();
  const getReceiptFundsAction = useGetReceiptFundsAction();
  const getPaymentHistoryDownloadAction = useGetPaymentHistoryDownloadAction();
  const getCreateEmailAction = useGetCreateEmailAction(tasksModel);
  const getCreateSMSAction = useGetCreateSMSAction(tasksModel);

  return (arrears?: ArrearsTask): ActionMenuItem[] => {
    if (!arrears) return [];

    const tenancy = arrears?.details?.property_tenancy?.tenancy;

    const receiptFundsAction = tenancy
      ? [
          getReceiptFundsAction({
            entity: tenancy,
            recordTypeName: 'Tenancy',
            receiptFrom: tenancy?.tenants?.map((tenant) => tenant.contact)
          })
        ]
      : [];

    const enableDownload =
      (arrears?.details?.property_tenancy?.rent_position
        ?.partially_paid_period_start_date ||
        arrears?.details?.property_tenancy?.rent_position
          ?.fully_paid_period_start_date) &&
      arrears?.details?.property_tenancy?.tenancy;

    const actions = [
      ...getPrimaryRecordActionGroup('task_arrears', arrears.id),
      getAddDocumentsAction({ data: arrears }),
      getAddNotesAction({ data: arrears }),
      getCreateEmailAction(arrears),
      getCreateSMSAction(arrears),
      ...receiptFundsAction,
      getPaymentHistoryDownloadAction({
        tenancy: arrears?.details?.property_tenancy?.tenancy,
        propertyTenancy: arrears?.details?.property_tenancy,
        isDisabled: !enableDownload
      })
    ];

    return [...transformActionDeclarationsToActionMenuItems(actions)];
  };
}
