import { Field, HiddenField } from '@rexlabs/form';
import { Checkbox } from '@rexlabs/checkbox';
import EmptyTable from 'assets/illustrations/empty-table.svg';
import { ContactPaymentMethod } from 'data/models/entities/contacts/payment-methods';
import { BankAccount } from 'data/models/entities/financials/bank-accounts';
import { Invoice } from 'data/models/entities/financials/invoices';
import { Ownership } from 'data/models/entities/ownerships';
import { Tenancy } from 'data/models/entities/tenancies';
import { ValueListValue } from 'data/models/types';
import React from 'react';
import { BankAccountSelect } from 'src/modules/bank-accounts/components/bank-account-select';
import { api } from 'utils/api/api-client';
import { SearchResultItem } from 'utils/api/get-search-results';
import { FinancialRecordTypes } from 'utils/financials/financial-objects';
import { Accordion } from 'view/components/@luna/accordion/accordion';
import { Column, Grid } from 'view/components/@luna/form/grid';
import { DateInput } from 'view/components/@luna/inputs/date-input/date-input';
import { Message } from 'view/components/@luna/message';
import AddIcon from 'view/components/icons/add';
import { DisbursementInstructionInput } from 'view/components/inputs/selects/disbursement-instruction';
import { EntitySelect } from 'view/components/inputs/selects/v4-selects/entity-select';
import { BlockConfig } from 'view/components/record-screen/types';
import { TextArea } from '@rexlabs/text-input';
import Box from '@rexlabs/box';
import { Contact } from 'src/modules/contacts/types/contact-types';
import { InvoicePrioritySelect } from '../components/invoice-priority-select';
import { getDefaultDisbursementOption } from '../utils/get-default-disbursement-option';
import { useSendInvoiceCheckboxLabel } from '../hooks/use-send-invoice-checkbox-label';

export type UseDisbursementPreferences = 'use_disbursement_preferences';

export type ReimbursementInvoiceDetailsFormValues = {
  send_reimbursement_invoice?: boolean;
  reimbursed_by: {
    object: SearchResultItem<Contact | Ownership | Tenancy>;
  };
  reimbursed_to: {
    object: SearchResultItem<Contact | Ownership | Tenancy>;
  };
  reimbursed_description: string;
  reimbursed_due_date: string;
  reimbursed_do_not_pay_before_date: string;
  reimbursed_bill_reference: string;
  reimbursed_invoice_date?: string;
  reimbursed_is_tax_included: boolean;
  reimbursed_specific_disbursement_payment_method:
    | UseDisbursementPreferences
    | ContactPaymentMethod
    | null;
  reimbursed_bank_account: BankAccount;
  reimbursed_bill_priority?: ValueListValue<'high' | 'normal' | 'low'>;
  reimbursement_for_invoice?: Invoice;
  reimbursed_notes: string | null;
};

export const reimbursementDetailsBlock: BlockConfig<
  any,
  {
    activeTab?: 'invoice' | 'reimbursement';
    isReimbursementEmptyStateVisible?: boolean;
    hasBillProcessingFeature?: number;
    handleReimbursementEmptyState?: (state: boolean) => void;
    suggestions?: {
      payableTo?: Array<SearchResultItem<Ownership | Tenancy>>;
      payableBy?: Array<SearchResultItem<Ownership | Tenancy>>;
    };
  },
  ReimbursementInvoiceDetailsFormValues
> = {
  id: 'reimbursement-details',
  validate: {
    definitions: {
      'reimbursed_by.object': {
        rules: 'required_if_true:has_reimbursed_line_items'
      },
      reimbursed_due_date: {
        rules: 'required_if_true:has_reimbursed_line_items'
      },
      reimbursed_is_tax_included: {
        rules: 'required_if_true:has_reimbursed_line_items'
      },
      reimbursed_bank_account: {
        name: 'bank account',
        rules: 'required_if_true:has_reimbursed_line_items'
      },
      reimbursed_specific_disbursement_payment_method: {
        rules: 'required_if_true:has_reimbursed_line_items'
      }
    }
  },
  Edit: ({ blockProps, forms, values, setFieldValue }) => {
    const sendInvoiceCheckboxLabel = useSendInvoiceCheckboxLabel(
      values?.reimbursed_by?.object?.record,
      true
    );

    const isReimbursementTab = blockProps?.activeTab === 'reimbursement';
    const reimbursedTo = values?.reimbursed_to?.object;
    const hasLineItems = checkLineItemsCount(
      forms?.['reimbursement-line-items']?.values.reimbursed_line_items
    );

    const [isAccordionOpen, setIsAccordionOpen] = React.useState(false);

    const financialRecordId = reimbursedTo?.id;
    const financialRecordType = reimbursedTo?.type?.id;
    const isOwnership = financialRecordType === 'ownership';

    const hasSpecificDisbursementMethodValue =
      values?.reimbursed_specific_disbursement_payment_method != null;

    const [disbursementOptions, setDisbursementOptions] = React.useState<
      ContactPaymentMethod[]
    >([]);

    React.useEffect(() => {
      fetchReimbursementOptions(financialRecordId, financialRecordType).then(
        (options) => {
          setDisbursementOptions(options);

          if (hasSpecificDisbursementMethodValue) return;

          const result = getDefaultDisbursementOption(isOwnership, options);

          setFieldValue?.(
            'reimbursed_specific_disbursement_payment_method',
            result.value
          );
          setIsAccordionOpen(result.isAccordionOpen);
        }
      );
    }, [financialRecordId, financialRecordType]);

    React.useEffect(() => {
      setFieldValue!('has_reimbursed_line_items', hasLineItems);
    }, [hasLineItems, setFieldValue]);

    if (isReimbursementTab && blockProps?.isReimbursementEmptyStateVisible) {
      return (
        <div style={{ marginTop: '-4.8rem' }}>
          <Message
            Illustration={EmptyTable}
            title='No reimbursement invoices'
            grey={true}
            actions={[
              {
                label: 'Add reimbursement invoice',
                Icon: AddIcon,
                handleAction() {
                  blockProps?.handleReimbursementEmptyState?.(false);
                }
              }
            ]}
          >
            Add a reimbursement invoice for this invoice.
          </Message>
        </div>
      );
    }

    const handleReimbursedByBlur = async () => {
      const lineItemForm = forms?.['reimbursement-line-items'];

      if (!lineItemForm) return;

      const { setFieldValue, values } = lineItemForm;
      const hasLineItems = values?.reimbursed_line_items?.length > 0;

      if (hasLineItems) return;

      setFieldValue!('reimbursed_line_items', [{}]);
    };

    return (
      <div
        style={{
          display: isReimbursementTab ? 'block' : 'none',
          marginTop: '-4.8rem'
        }}
      >
        <Grid columns={2}>
          <HiddenField name='reimbursed_description' />
          <HiddenField name='has_reimbursed_line_items' />
          <HiddenField name='reimbursed_to.object' />

          <Box>
            <Grid columns={1}>
              <Field
                id='reimbursed_by'
                name='reimbursed_by.object'
                label='Reimbursed by'
                Input={EntitySelect}
                onBlur={handleReimbursedByBlur}
                optional={false}
                inputProps={{
                  objectTypes: Object.values(FinancialRecordTypes),
                  deselectable: true,
                  onSelectedItemChange(changes) {
                    if (changes?.type === '__function_reset__') {
                      const { setFieldValue } = forms![
                        'reimbursement-line-items'
                      ];
                      setFieldValue('reimbursed_line_items', []);
                    }
                  }
                }}
              />
            </Grid>

            {sendInvoiceCheckboxLabel ? (
              <Field<typeof Checkbox>
                name='send_reimbursement_invoice'
                id='send_reimbursement_invoice'
                optional={false}
                inputProps={{
                  label: sendInvoiceCheckboxLabel
                }}
                Input={Checkbox}
              />
            ) : null}
          </Box>

          <Field
            name='reimbursed_due_date'
            label='Due Date'
            optional={false}
            Input={DateInput}
          />
        </Grid>

        <Accordion
          open={isAccordionOpen}
          onChange={(isOpen) => setIsAccordionOpen(isOpen)}
          headline='More options'
        >
          <Grid columns={2}>
            <Field
              label='Bank Account'
              name='reimbursed_bank_account'
              optional={false}
              Input={BankAccountSelect}
            />

            <Field
              label='Disbursement Instruction'
              name='reimbursed_specific_disbursement_payment_method'
              Input={DisbursementInstructionInput}
              optional={false}
              inputProps={{
                items: disbursementOptions
              }}
            />

            <Field
              name='reimbursed_bill_priority'
              label='Priority'
              Input={InvoicePrioritySelect}
              optional={false}
            />

            <Field
              name='reimbursed_do_not_pay_before_date'
              label='Do not pay before date via disbursement'
              Input={DateInput}
            />

            <Column width={2}>
              <Field
                Input={TextArea}
                name='reimbursed_notes'
                label='Invoice notes'
                description='Any notes added here will be displayed on the invoice.'
                inputProps={{
                  placeholder: 'Enter notes...'
                }}
              />
            </Column>
          </Grid>
        </Accordion>
      </div>
    );
  }
};

function checkLineItemsCount(lineItems) {
  if (!Array.isArray(lineItems)) return false;
  if (lineItems.length === 0) return false;

  return lineItems.some((lineItem) => {
    return Object.keys(lineItem).length > 0;
  });
}

async function fetchReimbursementOptions(
  financialRecordId,
  financialRecordType
) {
  if (!financialRecordId || !financialRecordType) return [];

  const paymentMethods = await api.get('/financials/contact-payment-methods', {
    per_page: 30,
    q: `${financialRecordType}_id.eq(${financialRecordId})`
  });

  return paymentMethods.data;
}
