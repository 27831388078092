import { query } from '@rexlabs/model-generator';
import { bankAccountModel } from 'data/models/entities/financials/bank-accounts';

export const getBankAccountsQuery = () => {
  return query`{
    ${bankAccountModel} {
      id
    }
  }`;
};
