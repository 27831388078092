import React, { useMemo } from 'react';

import Box from '@rexlabs/box';
import {
  useStyles,
  useToken,
  StyleSheet,
  text,
  icon,
  StylesProvider
} from '@rexlabs/styling';
import Avatar from '@rexlabs/avatar';

import { ActionButtons } from 'view/components/@luna/action-buttons';

import { StatBlockProps } from '..';

const defaultStyles = StyleSheet({
  container: {
    width: '100%'
  },

  label: {
    display: 'flex',
    flex: 1,
    whiteSpace: 'nowrap',

    ...text.styles({ fallback: 'heading.3' })
  },

  value: {
    display: 'flex',
    whiteSpace: 'nowrap',

    ...text.styles({ fallback: 'heading.3' })
  },

  valueSuccess: {
    color: ({ token }) => token('color.textStyle.success.idle.default')
  },

  valueDanger: {
    color: ({ token }) => token('color.textStyle.danger.idle.default')
  },

  valueInformation: {
    color: ({ token }) => token('color.textStyle.information.idle.default')
  },

  description: {
    ...text.styles({
      fallback: 'normal.medium',
      color: ({ token }) => token('color.textStyle.body.subtext')
    })
  },

  header: {
    marginBottom: 0
  },

  content: {
    marginTop: 0
  }
});

const avatarColours = {
  neutral: ({ token }) => token('color.neutral.idle.default'),
  secondary: ({ token }) => token('color.container.static.secondary.contrast'),
  success: ({ token }) => token('color.container.static.success.contrast'),
  warning: ({ token }) => token('color.container.static.warning.contrast'),
  danger: ({ token }) => token('color.container.static.danger.contrast'),
  information: ({ token }) =>
    token('color.container.static.information.contrast')
};

const avatarIconColours = {
  secondary: ({ token }) => token('color.textStyle.secondary.idle.default')
};

function getAvatarOverrides(intent) {
  return {
    avatar: {
      m: {
        icon: {
          ...icon.tokens({
            color: avatarIconColours[intent]
          })
        }
      },
      background: {
        color: avatarColours[intent]
      }
    }
  };
}

type ContentLargeProps = Pick<
  StatBlockProps,
  'label' | 'intent' | 'value' | 'description' | 'actions' | 'Icon'
>;

export function ContentLarge({
  label,
  intent,
  value,
  description,
  actions,
  Icon
}: ContentLargeProps) {
  const s = useStyles(defaultStyles);
  const token = useToken();

  const avatarOverrides = useMemo(() => getAvatarOverrides(intent), [intent]);

  return (
    <Box {...s('container')} sy={token('spacing.s')} flexDirection='column'>
      <Box
        {...s('header')}
        flexDirection='row'
        flex={1}
        sx={token('spacing.m')}
        alignItems='center'
      >
        {Icon && (
          <StylesProvider tokens={avatarOverrides}>
            <Avatar rounded='m' Icon={Icon} />
          </StylesProvider>
        )}
        <span {...s('label')}>{label}</span>
        {value && (
          <Box
            data-testid='stat-block-value'
            {...s('value', {
              valueSuccess: intent === 'success',
              valueDanger: intent === 'danger',
              valueInformation: intent === 'information'
            })}
          >
            {value || '—'}
          </Box>
        )}
      </Box>
      <Box {...s('content')}>
        {description && <span {...s('description')}>{description}</span>}
        {actions && (
          <Box alignItems='center'>
            <ActionButtons actions={actions} />
          </Box>
        )}
      </Box>
    </Box>
  );
}
