import React, { createContext, useMemo, useState } from 'react';
import { css, Global } from '@emotion/core';

import { StylesProvider } from '@rexlabs/styling';
import { createTokenFn } from '@rexlabs/element-styles';

type TokenCustomiserContextValue = {
  tokens?: any;
  setTokens?: any;
};

export const TokenCustomiserContext = createContext<TokenCustomiserContextValue>(
  {}
);
const { Provider } = TokenCustomiserContext;

export function TokenCustomiserProvider({
  children,
  tokens: propTokens,
  ...props
}) {
  const [tokens, setTokens] = useState(propTokens);

  const token = useMemo(() => createTokenFn(tokens), [tokens]);

  const value = useMemo(
    () => ({
      tokens,
      setTokens
    }),
    [tokens]
  );

  const styles = useMemo(
    () => css`
      html {
        font-size: 62.5%;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
      }

      body {
        font-size: ${token('textStyle.body.default.font.size')};
        font-weight: ${token('textStyle.body.default.font.weight')};
        font-family: ${token('textStyle.body.default.font.family')};
        line-height: ${token('textStyle.body.default.lineHeight')};
        letter-spacing: ${token('textStyle.body.default.letterSpacing')};
        color: ${token('textStyle.body.default.color')};
      }

      html,
      body,
      #app {
        height: 100%;
      }

      *,
      *:before,
      *:after {
        box-sizing: border-box;
      }

      *:focus {
        outline: none;
        box-shadow: ${token('shadow.focus')};
      }

      button,
      input,
      textarea,
      label {
        font: inherit;
      }

      p {
        padding: ${token('textStyle.paragraph.default.padding.top', 0)}
          ${token('textStyle.paragraph.default.padding.right', 0)}
          ${token('textStyle.paragraph.default.padding.bottom', 0)}
          ${token('textStyle.paragraph.default.padding.left', 0)};
        font-size: ${token('textStyle.paragraph.default.font.size')};
        font-weight: ${token('textStyle.paragraph.default.font.weight')};
        line-height: ${token('textStyle.paragraph.default.lineHeight')};
        letter-spacing: ${token('textStyle.paragraph.default.letterSpacing')};
        color: ${token('textStyle.paragraph.default.color')};
        padding-top: ${token('textStyle.paragraph.default.padding.top')};
        padding-bottom: ${token('textStyle.paragraph.default.padding.bottom')};
      }

      a {
        color: ${token('textStyle.link.default.idle.color')};
        textdecoration: ${token('textStyle.link.default.textDecoration')};
      }

      a:hover {
        color: ${token('textStyle.link.default.hover.color')};
      }

      a:active {
        color: ${token('textStyle.link.default.active.color')};
      }

      a:focus {
        color: ${token('textStyle.link.default.focused.color')};
      }

      b,
      strong {
        font-weight: ${token('typography.weight.bold')};
      }

      b.semibold,
      strong.semibold {
        font-weight: ${token('typography.weight.semibold')};
      }
    `,
    [token]
  );

  return (
    <Provider value={value}>
      <Global styles={styles} />
      <StylesProvider {...props} tokens={value?.tokens}>
        {children}
      </StylesProvider>
    </Provider>
  );
}
