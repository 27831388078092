import { Message } from 'view/components/@luna/message';
import ReceiptsTransfersWithdrawalsIllustration from 'assets/illustrations/receipts-transfers-withdrawals.svg';
import { ColoredCard } from 'view/components/cards/colored-card';
import React from 'react';

export function TrustJournalLineItemEmptyState() {
  return (
    <ColoredCard intent={'neutral'}>
      <Message
        Illustration={ReceiptsTransfersWithdrawalsIllustration}
        title='Choose a folio to receipt'
      >
        Before adding in line items, please select the folio you wish to receipt
        against. Once selected, any existing invoices with amounts due will be
        automatically populated for you to receipt against.
      </Message>
    </ColoredCard>
  );
}
