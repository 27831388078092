import { useModelActions } from '@rexlabs/model-generator';
import { api } from 'utils/api/api-client';
import { useRecordCreatedToast } from 'src/modules/common/toasts/hooks/use-record-created-toast';
import { RecordSubmitHandler } from 'view/components/record-screen/utils';
import { propertyAreaTypesModel } from 'src/modules/property-areas/property-area-types/models/property-area-types-model';
import { PropertyAreaTypeForm } from 'src/modules/property-areas/property-area-types/types/PropertyAreaTypeForm';
import { PropertyAreaType } from 'src/modules/property-areas/property-area-types/types/PropertyAreaType';

export function useCreatePropertyAreaTypeSubmitHandler() {
  const { refreshLists } = useModelActions(propertyAreaTypesModel);

  const addCreatedToast = useRecordCreatedToast(propertyAreaTypesModel, {
    actions: []
  });

  const onSubmit: RecordSubmitHandler<PropertyAreaTypeForm> = async ({
    values
  }) => {
    const requests = getTransformPropertyAreaTypeFormToPipeline({ values });
    const response = await api.post('request-pipelines', { requests });

    const data: PropertyAreaType = response.data.property_area_types_item_order;

    addCreatedToast(data);
    await refreshLists();
    return data;
  };
  return onSubmit;
}

function getTransformPropertyAreaTypeFormToPipeline({
  values
}: {
  values: PropertyAreaTypeForm;
}) {
  const propertyAreaTypeItems = values.items?.map((item, index) => {
    return {
      id: `property_area_type_items${index}`,
      method: 'POST',
      path: '/api/v1/properties/property-area-type-items',
      json: {
        name: item.name,
        property_area_type: {
          id: '{{$.property_area_type.id}}'
        }
      }
    };
  });

  const itemOrderArray = values.items?.map((item, index) => {
    return `{{$.property_area_type_items${index}.id}}`;
  });

  return [
    {
      id: 'property_area_type',
      method: 'POST',
      path: '/api/v1/properties/property-area-types',
      json: {
        name: values.name,
        is_created_on_new_properties:
          values.is_created_on_new_properties || false
      }
    },
    ...(propertyAreaTypeItems || []),

    {
      id: 'property_area_types_item_order',
      method: 'PATCH',
      path:
        '/api/v1/properties/property-area-types/{{$.property_area_type.id}}',
      json: {
        item_order: itemOrderArray
      }
    }
  ];
}
