import {
  taskIncludes,
  sharedIncludes
} from 'src/modules/tasks/common/models/tasks-model';
import { TaskType } from '../types/TaskType';

export function getTaskIncludes(taskType: TaskType): string {
  switch (taskType) {
    case 'todo':
      return [...sharedIncludes, taskIncludes.todoDetails].join(',');

    case 'task_maintenance':
      return [
        ...sharedIncludes,
        taskIncludes.maintenanceDetails,
        'property.active_property_ownership.ownership.owners.contact'
      ].join(',');

    case 'task_lease_review':
      return [...sharedIncludes, taskIncludes.leaseReviewDetails].join(',');

    case 'task_inspection':
      return [
        ...sharedIncludes,
        taskIncludes.inspectionDetails,
        'property.active_property_ownership.ownership.owners.contact'
      ].join(',');

    case 'task_arrears':
      return [...sharedIncludes, taskIncludes.arrearsDetails].join(',');

    case 'task_move_in':
      return [...sharedIncludes, taskIncludes.moveInDetails].join(',');

    case 'task_move_out':
      return [...sharedIncludes, taskIncludes.moveOutDetails].join(',');

    case 'task_supplier_compliance':
      return [...sharedIncludes, taskIncludes.supplierComplianceDetails].join(
        ','
      );

    case 'task_property_compliance':
      return [...sharedIncludes, taskIncludes.propertyComplianceDetails].join(
        ','
      );

    case 'task_quote':
      return [...sharedIncludes, taskIncludes.quoteDetails].join(',');

    case 'task_work_order':
      return [...sharedIncludes, taskIncludes.workOrderDetails].join(',');

    default:
      return [...sharedIncludes].join(',');
  }
}
