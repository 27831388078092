import React, { useCallback } from 'react';

import { useErrorDialog } from '@rexlabs/dialog';
import { useModelActions } from '@rexlabs/model-generator';
import { useToast } from 'view/components/@luna/notifications/toast';

import {
  financialsInvoicesModel,
  Invoice
} from 'data/models/entities/financials/invoices';
import { financialsUploadedBillsModel } from 'src/modules/bill-processing/models/uploaded-bills';
import { RecordDialog } from 'view/components/record-screen/dialog/dialog';
import { DialogContentConfig } from 'view/components/record-screen/dialog/types';
import { RecordSubmitHandler } from 'view/components/record-screen/utils';
import { uploadBillBlock } from '../blocks/upload-bill';

const content: DialogContentConfig = [
  {
    id: 'basics',
    label: 'Basics',
    blocks: [uploadBillBlock]
  }
];

interface UploadOriginalBillDialogProps {
  invoice: Invoice;
  onClose?: () => void;
}

export function UploadOriginalBillDialog({
  invoice,
  onClose
}: UploadOriginalBillDialogProps) {
  const { importBills } = useModelActions(financialsUploadedBillsModel);
  const { refreshItem, refreshLists, updateItem } = useModelActions(
    financialsInvoicesModel
  );
  const { open: openErrorDialog } = useErrorDialog();
  const toast = useToast();

  const handleSubmit: RecordSubmitHandler = useCallback(async ({ values }) => {
    const uploadBill = values.bill;

    const { data: bill } = await Promise.resolve(uploadBill?.data);

    try {
      const uploadedBill = await importBills({
        fileIds: [bill.id]
      });

      values.uploaded_bill = { id: uploadedBill.data[0].id };

      const response = await updateItem({
        id: invoice.id,
        data: values
      });

      await Promise.all([
        refreshItem({
          id: invoice.id
        }),
        refreshLists()
      ]);

      toast.addToast({
        description: 'File has been added successfully'
      });

      return response;
    } catch (error) {
      openErrorDialog(error);
      return;
    }
  }, []);

  return (
    <RecordDialog
      onClose={onClose}
      title={'Upload original bill'}
      handleSubmit={handleSubmit}
      content={content}
      submitLabel='Upload bill'
    />
  );
}
