import React, { useCallback } from 'react';

import { RecordDialog } from 'view/components/record-screen/dialog/dialog';
import { DialogContentConfig } from 'view/components/record-screen/dialog/types';

import { RecordSubmitHandler } from 'view/components/record-screen/utils';
import { useModelActions } from '@rexlabs/model-generator';
import { filterNullValues } from 'utils/filter-null-values';
import {
  financialsReconciliationsAdjustmentsModel,
  ReconciliationAdjustment
} from 'data/models/entities/financials/reconciliations/adjustments';
import {
  financialsReconciliationsModel,
  Reconciliation
} from 'data/models/entities/financials/reconciliations';

import { reconciliationAdjustmentBlock } from '../blocks/reconciliation-adjustment';

const content: DialogContentConfig = [
  {
    id: 'basics',
    label: 'Basics',
    blocks: [reconciliationAdjustmentBlock]
  }
];

interface CreateAdjustmentDialogProps {
  reconciliation: Reconciliation;
  updateListId?: string;
  onClose?: () => void;
  onCreate?: (item: ReconciliationAdjustment) => void;
}

export function CreateAdjustmentDialog({
  reconciliation,
  updateListId,
  onClose
}: CreateAdjustmentDialogProps) {
  const reconciliationId = reconciliation.id;
  const { createItem, refreshList } = useModelActions(
    financialsReconciliationsAdjustmentsModel
  );
  const { refreshItem } = useModelActions(financialsReconciliationsModel);

  const handleSubmit: RecordSubmitHandler = useCallback(
    async ({ values }) => {
      const response = await createItem({
        data: { ...filterNullValues(values), reconciliationId }
      });

      const promises: Promise<any>[] = [
        refreshItem({
          id: reconciliationId,
          args: {
            include: 'reconciliation_summary'
          }
        })
      ];

      if (updateListId) {
        promises.push(
          refreshList({
            id: updateListId,
            data: {
              reconciliationId
            },
            args: {
              reconciliationId
            }
          })
        );
      }

      await Promise.all(promises);

      return response.data;
    },
    [createItem, refreshList, refreshItem, updateListId, reconciliationId]
  );

  const initialData = {
    date_of: reconciliation.statement_date,
    type: {
      id: 'other',
      label: 'Other'
    }
  };

  return (
    <RecordDialog
      onClose={onClose}
      data={initialData}
      blockProps={{
        statementDate: reconciliation.statement_date
      }}
      title='Add adjustment'
      handleSubmit={handleSubmit}
      content={content}
      submitLabel='Add adjustment'
    />
  );
}
