import React from 'react';
import { GhostButton } from '@rexlabs/button';
import { StandardDialogFooter } from 'view/components/dialogs/components/standard-dialog-footer';
import { ButtonGroupProps } from 'view/components/record-screen/dialog/types';
import { GoToOutboxSplitButton } from '../../common/components/go-to-outbox-split-button';

export function AddWorkOrderDialogButtonGroup({
  onClose,
  handleSubmit,
  isSubmitting,
  ...props
}: ButtonGroupProps) {
  const buttonLabel = 'Issue work order';

  return (
    <StandardDialogFooter>
      <GhostButton onClick={onClose}>Cancel</GhostButton>
      <GoToOutboxSplitButton
        onClose={onClose}
        handleSubmit={handleSubmit}
        isSubmitting={isSubmitting}
        buttonLabel={buttonLabel}
        {...props}
      />
    </StandardDialogFooter>
  );
}
