import React from 'react';
import { BlockConfig } from 'view/components/record-screen/types';

import { Grid } from 'view/components/@luna/form/grid';
import Box from '@rexlabs/box';
import { ImagePreview } from 'view/components/image-preview';
import { Field } from '@rexlabs/form';
import { PreviewFileUploadInputWithErrorHandling } from 'view/components/inputs/file-upload-input/preview-file-upload-input-with-error-handling';
import { Value } from 'view/components/values';
import { InspectionTask } from '../../types/InspectionTask';
import { InspectionFormData } from '../../types/InspectionFormData';

export const entryExitSupportingDocumentsBlock: BlockConfig<
  InspectionTask,
  any,
  InspectionFormData
> = {
  id: 'entry-exit-supporting-documents',
  title: 'Supporting documents',
  isMobileForcedCollapsed: true,
  isEditable: (data) =>
    !['report_completed', 'report_sent'].includes(
      data?.details?.status?.id || ''
    ),
  View: ({ data }) => {
    const supportingDocuments = data?.details?.supporting_documents?.data;

    return (
      <Grid columns={1}>
        <Value
          label='Supporting documents'
          value={
            supportingDocuments?.length ? (
              <Box spacing='.8rem' flexDirection='row' flexWrap='wrap'>
                {supportingDocuments!.map((image, index) => (
                  <ImagePreview
                    src={image?.file.url}
                    key={image?.file.id || index}
                    downloadUrl={image?.file.download_url}
                  />
                ))}
              </Box>
            ) : null
          }
        />
      </Grid>
    );
  },
  Edit: () => {
    return (
      <Grid columns={1}>
        <Field<typeof PreviewFileUploadInputWithErrorHandling>
          id='supporting_documents'
          name='supporting_documents'
          label='Supporting documents'
          Input={PreviewFileUploadInputWithErrorHandling}
          inputProps={{
            multi: true
          }}
        />
      </Grid>
    );
  }
};
