import { api } from 'utils/api/api-client';

import { Disbursement } from 'data/models/entities/financials/disbursements/disbursements';

const relatedFields = [
  'contact',
  'ownership',
  'ownership.owners',
  'ownership.owners.contact'
];

export async function getDisbursementRelatedItems(
  disbursementSearchItem: Disbursement
) {
  const statementUrl = `/financials/disbursements/${
    disbursementSearchItem.id
  }?include=${relatedFields.join(',')}`;

  const completeDisbursement =
    (disbursementSearchItem?.object.type.id === 'contact' &&
      !disbursementSearchItem?.contact) ||
    (disbursementSearchItem?.object.type.id === 'ownership' &&
      !disbursementSearchItem?.ownership)
      ? await api.get<Disbursement>(statementUrl).then(({ data }) => data)
      : disbursementSearchItem;

  const { ownership, contact } = completeDisbursement;

  const owners = (ownership?.owners || []).map((owner) => owner.contact);

  return {
    owners,
    contact
  };
}
