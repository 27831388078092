import { ActionMenuItem } from '@rexlabs/action-menu';
import { transformActionDeclarationsToActionMenuItems } from 'utils/actions/transforms';
import { useGetDownloadLetterAction } from '../action-declarations/use-get-download-letter-action';
import { MessageBatch } from '../../types/MessageBatch';
import { useGetMarkAsNotDownloadedLetterAction } from '../action-declarations/use-get-mark-as-not-downloaded-letter-action';

export function useGetLetterPrintedActions() {
  const getDownloadLetterAction = useGetDownloadLetterAction();
  const getMarkAsNotDownloadedLetterAction = useGetMarkAsNotDownloadedLetterAction();

  return (messageBatch?: MessageBatch): ActionMenuItem[] => {
    if (!messageBatch) return [];

    const result = [
      getDownloadLetterAction(messageBatch),
      getMarkAsNotDownloadedLetterAction(messageBatch)
    ];

    return transformActionDeclarationsToActionMenuItems(result);
  };
}
