import React from 'react';
import { ConfirmationDialog, DialogProps } from '@rexlabs/dialog';
import { Body } from '@rexlabs/text';
import { invokeActionDeclaration } from 'src/modules/common/actions/utils/invoke-action-declaration';
import InfoCircleIcon from 'view/components/icons/info';
import { useModelActions } from '@rexlabs/model-generator';
import { useGetCreateWorkOrderAction } from '../hooks/action-declarations/use-get-create-work-order-action';
import { QuoteTask } from '../../quotes/types/QuoteTask';
import { WorkOrderTask } from '../types/WorkOrderTask';
import { tasksModel } from '../../common/models/tasks-model';
export interface ContinueToIssueWorkOrderDialog extends DialogProps {
  quote: QuoteTask;
}

export function ContinueToIssueWorkOrderDialog({
  onClose,
  quote
}: ContinueToIssueWorkOrderDialog) {
  // refresh the parent to show changes to its status
  const { refreshItem } = useModelActions(tasksModel);
  const createWorkOrderAction = useGetCreateWorkOrderAction({
    onCreate: (data: WorkOrderTask) => {
      data.parent_task && refreshItem({ id: data.parent_task.id });
    }
  });

  return (
    <ConfirmationDialog
      title='Do you want to issue a work order?'
      size='s'
      onClose={onClose}
      message={
        <>
          <Body>
            By accepting a quote you can use the details to pre-fill and issue a
            new work order.
          </Body>
          <Body>Do you want to continue?</Body>
        </>
      }
      confirmText='Yes, issue work order'
      cancelText='Do not issue'
      TitleIcon={InfoCircleIcon}
      onConfirm={async () => {
        await invokeActionDeclaration(createWorkOrderAction, quote);
      }}
    />
  );
}
