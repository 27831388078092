import { makeIconComponent } from '@rexlabs/icons';

import TodoMediumSvg from 'src/assets/icons/m/todo.svg';
import TodoLargeSvg from 'src/assets/icons/l/todo.svg';
import TodoExtraLargeSvg from 'src/assets/icons/xl/todo.svg';

export const TaskIcon = makeIconComponent({
  m: TodoMediumSvg,
  l: TodoLargeSvg,
  xl: TodoExtraLargeSvg
});

export default TaskIcon;
