import React, { forwardRef, ForwardRefExoticComponent } from 'react';

import { StylesProvider } from '@rexlabs/styling';
import { BoxProps } from '@rexlabs/box';
import { ActionMenu, ActionMenuProps } from '@rexlabs/action-menu';
import {
  ActionDeclaration,
  ActionIntent
} from 'src/modules/common/actions/types/action-declaration-types';
import {
  ButtonGroup,
  DestructiveButton,
  GhostButton,
  GhostIconButton,
  OutlineButton,
  OutlineIconButton,
  PrimaryButton,
  PrimaryIconButton,
  ButtonProps,
  IconButtonProps
} from '@rexlabs/button';
import Icons from '@rexlabs/icons';
import { Breakpoints } from '@rexlabs/breakpoints';
import { transformActionDeclarationsToActionMenuItems } from 'utils/actions/transforms';

const { ArrowDownFilled, Kebab } = Icons as any;

const overrides = {
  actionMenu: {
    button: {
      icon: {
        svg: () => Kebab
      }
    }
  }
};

export type ActionButtonsProps = BoxProps & {
  actions: ActionDeclaration[];
  placement?: ActionMenuProps['placement'];
};

type VividButtonProps = Partial<ButtonProps> & Partial<IconButtonProps>;

type ActionIntentButtons = {
  [index in ActionIntent]: ForwardRefExoticComponent<any>;
};

const triggersWithLabel: ActionIntentButtons = {
  primary: PrimaryButton,
  secondary: OutlineButton,
  tertiary: GhostButton,
  danger: DestructiveButton
};

const triggerWithoutLabel: ActionIntentButtons = {
  primary: PrimaryIconButton,
  secondary: OutlineIconButton,
  tertiary: GhostIconButton,
  danger: OutlineIconButton
};

function flattenActions(actions) {
  return actions.flatMap((action) => {
    if (action.actions || action.items) {
      return flattenActions(action.actions ?? action.items);
    }
    return action;
  });
}

export function ActionButtons({
  actions,
  placement,
  ...props
}: ActionButtonsProps) {
  const mobileMenuItems = transformActionDeclarationsToActionMenuItems(
    flattenActions(actions)
  );

  const isVisible = mobileMenuItems.length > 0;

  return (
    <>
      <Breakpoints queries={{ maxWidth: 's' }}>
        <StylesProvider tokens={overrides}>
          {isVisible ? (
            <ActionMenu items={mobileMenuItems} Button={GhostIconButton} />
          ) : null}
        </StylesProvider>
      </Breakpoints>
      <Breakpoints queries={{ minWidth: 's' }}>
        <ButtonGroup flexWrap='wrap' {...props}>
          {actions.map((action, index) => {
            let Button;
            let buttonProps: VividButtonProps = {};

            if ('disabled' in action) {
              buttonProps = { isDisabled: action.disabled };
            }

            const intent: ActionIntent =
              'intent' in action ? action.intent ?? 'secondary' : 'secondary';

            if ('ActionTrigger' in action) {
              Button = action.ActionTrigger;
              return <Button>{action.label}</Button>;
            }

            if (
              'Icon' in action &&
              action.Icon &&
              'label' in action &&
              action.label
            ) {
              Button = triggersWithLabel[intent];
              buttonProps = {
                ...buttonProps,
                IconLeft: action.Icon,
                children: action.label
              };
            } else if ('Icon' in action && action.Icon) {
              Button = triggerWithoutLabel[intent];
              buttonProps = { ...buttonProps, Icon: action.Icon };
            } else if ('label' in action && action.label) {
              Button = triggersWithLabel[intent];
            }

            if ('actions' in action) {
              return (
                <ActionMenu
                  items={transformActionDeclarationsToActionMenuItems(
                    action.actions
                  )}
                  placement={placement}
                  Button={forwardRef<HTMLButtonElement>(
                    function ActionMenuButton(props, ref) {
                      return (
                        <Button
                          {...props}
                          {...buttonProps}
                          ref={ref}
                          IconRight={action.label && ArrowDownFilled}
                        >
                          {action.label}
                        </Button>
                      );
                    }
                  )}
                />
              );
            }

            return (
              <Button
                {...buttonProps}
                key={index}
                onClick={action.handleAction}
              >
                {action.label}
              </Button>
            );
          })}
        </ButtonGroup>
      </Breakpoints>
    </>
  );
}
