import { RentPosition } from 'src/modules/property-tenancies/types/property-tenancy-types';
import { formatDate } from 'utils/dates/format';
import { formatCurrency } from 'utils/formatters';

export function getPaidToDateDescription({
  rentPosition,
  dateFormat = 'D MMM YYYY'
}: {
  rentPosition?: Partial<RentPosition>;
  dateFormat?: string;
}) {
  const tenantPaidToDateFormatted = rentPosition?.paid_to_date
    ? formatDate(rentPosition?.paid_to_date, dateFormat)
    : '';

  if (!rentPosition || !tenantPaidToDateFormatted) {
    return;
  }

  const tenantPaidToDateSurplusFormatted = rentPosition?.paid_to_date_surplus
    ? ` (+${formatCurrency(rentPosition?.paid_to_date_surplus)})`
    : '';

  return `${tenantPaidToDateFormatted}${tenantPaidToDateSurplusFormatted}`;
}

export function getPaidToPeriodDescription({
  rentPosition,
  dateFormat = 'D MMM YYYY'
}: {
  rentPosition?: Partial<RentPosition>;
  dateFormat?: string;
}) {
  const tenantPaidToPeriodStartFormatted = rentPosition?.fully_paid_period_start_date
    ? formatDate(rentPosition?.fully_paid_period_start_date, dateFormat)
    : '';

  if (!rentPosition || !tenantPaidToPeriodStartFormatted) {
    return;
  }

  const tenantPaidToPeriodEndFormatted = rentPosition?.fully_paid_period_end_date
    ? formatDate(rentPosition?.fully_paid_period_end_date, dateFormat)
    : '';
  const tenantPaidToPeriodSurplusFormatted = rentPosition?.fully_paid_period_surplus
    ? ` (+${formatCurrency(rentPosition?.fully_paid_period_surplus)})`
    : '';

  return (
    tenantPaidToPeriodStartFormatted &&
    tenantPaidToPeriodEndFormatted &&
    `${tenantPaidToPeriodStartFormatted} - ${tenantPaidToPeriodEndFormatted}${tenantPaidToPeriodSurplusFormatted}`
  );
}
