import * as React from 'react';
import { TagConfig } from 'view/components/@luna/title-block';
import { ProgressDropdown } from '../components/progress-dropdown';

// gotta be memo'd otherwise we get infinite rerenders
export function useWorkOrderTaskStatusTag(task): TagConfig {
  const Tag = React.useMemo(() => () => <ProgressDropdown value={task} />, [
    task?.details?.status?.id
  ]);

  return {
    label: task?.details?.status?.label,
    Tag
  };
}
