import * as React from 'react';
import { BlockConfig } from 'view/components/record-screen/types';
import { Value } from 'view/components/values';
import { CurrencyValue } from 'view/components/values/currency';
import { Grid } from 'view/components/@luna/form/grid';
import { DateValue } from 'view/components/values/date';
import Box from '@rexlabs/box';
import { StyleSheet, useStyles, useToken } from '@rexlabs/styling';
import FileIcon from 'view/components/icons/file';
import { BatchReceiptingItem } from '../types';

const styles = StyleSheet({
  root: {
    borderRadius: ({ token }) => token('border.radius.l'),
    padding: ({ token }) => token('spacing.xl'),
    background: ({ token }) => token('color.container.static.contrast')
  }
});

export const transactionDetailsBlock: BlockConfig<
  any,
  { batchReceiptingItem: BatchReceiptingItem }
> = {
  id: 'transaction-details',
  Edit: ({ blockProps }) => {
    const s = useStyles(styles);
    const token = useToken();

    const { batchReceiptingItem } = blockProps!;

    const fileName =
      batchReceiptingItem?.bank_statement_transaction_import?.name;

    return (
      <Box {...s('root')}>
        <Grid columns={3}>
          <Value
            label='Bank account'
            value={batchReceiptingItem?.bank_account?.name}
          />
          <DateValue label='Date' value={batchReceiptingItem?.paid_date} />
          <CurrencyValue
            label='Amount received'
            testId='split-transaction-amount'
            value={batchReceiptingItem?.amount}
          />
          <Value label='Description' value={batchReceiptingItem?.description} />
          <Value label='Reference' value={batchReceiptingItem?.reference} />
          <Value
            label='Included in transaction file'
            value={
              fileName ? (
                <Box
                  flexDirection='row'
                  alignItems='center'
                  gap={token('spacing.xs')}
                >
                  <FileIcon />
                  <Box>{fileName}</Box>
                </Box>
              ) : null
            }
          />
        </Grid>
      </Box>
    );
  }
};
