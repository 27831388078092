import React, { useEffect } from 'react';
import dayjs from 'dayjs';
import { isEmpty } from 'lodash';

import { HiddenField } from '@rexlabs/form';

import { Grid } from 'view/components/@luna/form/grid';
import { BlockConfig } from 'view/components/record-screen/types';

import {
  InspectionRunStartFormValues,
  InspectionRunStartForm,
  inspectionRunStartFormValidationConfig
} from '../components/inspection-run-start-form';
import {
  InspectionRunFinishForm,
  inspectionRunFinishFormValidationConfig,
  InspectionRunFinishFormValues
} from '../components/inspection-run-finish-form';
import { InspectionTask } from '../types/InspectionTask';

import { ChangeManagedByWarningBanner } from '../components/change-managed-by-warning-banner';
import { useUserInspections } from '../hooks/use-user-inspections';

export type CreateInspectionRunBlockFormValues = InspectionRunStartFormValues &
  InspectionRunFinishFormValues & { inspections: InspectionTask[] };

const defaultDate = dayjs().format('YYYY-MM-DD');
const defaultStartTime = '09:00:00';

export const createInspectionRunBlock: BlockConfig<
  any,
  any,
  CreateInspectionRunBlockFormValues
> = {
  id: 'create-inspection-run',
  title: 'Create inspection run',
  validate: {
    definitions: {
      ...inspectionRunStartFormValidationConfig,
      ...inspectionRunFinishFormValidationConfig
    }
  },
  Edit: ({ setFieldValue, values }) => {
    const { inspectionsNotManagedByUser } = useUserInspections(
      values?.inspections || []
    );

    /**
     * On init, we'll set the default values for the inspection date and leave from start time,
     * if those values have not been provided.
     */
    useEffect(() => {
      if (!values?.inspection_date) {
        setFieldValue?.('inspection_date', defaultDate);
      }
      if (!values?.leave_from_start_time) {
        setFieldValue?.('leave_from_start_time', defaultStartTime);
      }
    }, []);

    /**
     * We're making an assumption here - if the finish address is not set, we'll
     * assume that it's the same as the start address, as they're likely leaving from
     * their workplace.
     */
    useEffect(() => {
      if (isEmpty(values?.finish_address) && values?.start_address) {
        setFieldValue?.('finish_address', values?.start_address);
      }
    }, [values?.start_address]);

    return (
      <Grid columns={1}>
        {!!inspectionsNotManagedByUser.length && (
          <ChangeManagedByWarningBanner
            onCreate={(updatedInspections) =>
              setFieldValue?.('inspections', updatedInspections)
            }
            inspections={values?.inspections}
          />
        )}
        <InspectionRunStartForm values={values} />
        <InspectionRunFinishForm />
        <HiddenField name='inspections' />
      </Grid>
    );
  }
};
