import React from 'react';
import { CellProps } from '@rexlabs/table';

import { formatCurrency } from 'utils/formatters';
import { PropertyTenancy } from 'src/modules/property-tenancies/types/property-tenancy-types';
import { TwoTierCell } from 'view/components/cells/two-tier-cell';

export function RentCell({
  value
}: CellProps<unknown, PropertyTenancy['rent_schedule']>) {
  if (!value) return <>--</>;

  const currentRentScheduleEntry = value.find(
    (rentScheduleEntry) => rentScheduleEntry.status.id === 'current'
  );

  if (!currentRentScheduleEntry) return <>--</>;

  const duration = getDuration(
    currentRentScheduleEntry.frequency.count,
    currentRentScheduleEntry.frequency.unit.label
  );

  return (
    <TwoTierCell
      text={formatCurrency(currentRentScheduleEntry.amount_total_inc_tax)}
      subtext={duration}
    />
  );
}

function getDuration(unit, frequency) {
  switch (true) {
    case frequency === 'Days':
      return 'Daily';

    case frequency === 'Weeks' && unit === 2:
      return 'Fortnightly';

    case frequency === 'Weeks':
      return 'Weekly';

    case frequency === 'Months' && unit > 3:
      return `${unit} months`;

    case frequency === 'Months' && unit === 3:
      return `Quarterly`;

    case frequency === 'Months':
      return `Monthly`;

    case frequency === 'Years':
      return 'Yearly';
  }
}

export function rentToCsv(value) {
  if (!value) return '--';

  const currentRentScheduleEntry = value.find(
    (rentScheduleEntry) => rentScheduleEntry.status.id === 'current'
  );

  if (!currentRentScheduleEntry) return '--';

  const duration = getDuration(
    currentRentScheduleEntry.frequency.count,
    currentRentScheduleEntry.frequency.unit.label
  );

  return `${formatCurrency(
    currentRentScheduleEntry.amount_total_inc_tax
  )} ${duration}`;
}
