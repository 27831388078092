import { TableProviderProps } from '@rexlabs/table';
import { useDialog } from '@rexlabs/dialog';

import CalendarIcon from 'view/components/icons/calendar';

import { pluralize } from 'utils/formatters';

import { InspectionTask } from '../../types/InspectionTask';
import { CreateInspectionRunDialog } from '../../dialogs/create-inspection-run-dialog';
import { useCreateInspectionRunSubmitHandler } from '../use-get-create-inspection-run-submit-handler';
import { useGetValidateAndFixInspectionsForRun } from '../use-get-validate-and-fix-inspections-for-run';

type GetBulkActions = TableProviderProps<InspectionTask>['getBulkActions'];

export const useGetCreateInspectionRunBulkAction: () => GetBulkActions = () => {
  const { open: openCreateInspectionRunDialog } = useDialog(
    CreateInspectionRunDialog
  );

  const createInspectionRunSubmitHandler = useCreateInspectionRunSubmitHandler();

  const getValidateAndFixInspectionsForRun = useGetValidateAndFixInspectionsForRun();

  return ({ selectedItems: inspections }) => {
    const validateAndFixInspectionsForRun = getValidateAndFixInspectionsForRun(
      inspections,
      (inspections) =>
        openCreateInspectionRunDialog({
          inspections,
          submitHandler: createInspectionRunSubmitHandler
        })
    );

    return [
      {
        Icon: CalendarIcon,
        label: `Plan ${pluralize('inspection', inspections.length, true)}`,
        handleAction: validateAndFixInspectionsForRun
      }
    ];
  };
};
