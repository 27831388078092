import { FreeformDialogProps } from '@rexlabs/select';
import React from 'react';
import { getSearchResultItemFromObjectAndModel } from 'src/modules/common/utils/search-result-items/get-search-result-items-from-object-and-model';
import { FinancialEntity } from 'src/modules/financials/utils/financial-entity-action-group/use-get-financial-entity-actions';
import { SearchResultItem } from 'utils/api/get-search-results';
import { RecordData } from 'utils/types';
import { AdvancedFolioSearchDialog } from './advanced-folio-search-dialog';

interface FreeformAdvancedFolioSearchDialogProps
  extends FreeformDialogProps<SearchResultItem<RecordData>> {
  onCreate?: (data: FinancialEntity) => void;
}

export function FreeformAdvancedFolioSearchDialog({
  selectItem,
  inputValue,
  onCreate: propOnCreate,
  ...props
}: FreeformAdvancedFolioSearchDialogProps) {
  const onCreate = React.useCallback(
    (data) => {
      propOnCreate?.(data);
      const folioResult = getSearchResultItemFromObjectAndModel(
        data,
        data?.__record_type
      );
      folioResult && selectItem(folioResult);
    },
    [propOnCreate, selectItem]
  );

  return (
    <AdvancedFolioSearchDialog
      {...props}
      initialSearchValue={inputValue}
      onCreate={onCreate}
    />
  );
}
