import * as React from 'react';
import { useQuery } from 'react-query';
import { api } from 'utils/api/api-client';

export function useDefaultBankAccountQuery() {
  const bankAccounts = useQuery(
    ['bank-accounts'],
    () => {
      return api.get('/financials/bank-accounts');
    },
    {
      refetchOnWindowFocus: false
    }
  );

  const defaultAccount = React.useMemo(() => {
    if (!Array.isArray(bankAccounts.data?.data)) return null;

    const defaultAccount = bankAccounts.data?.data.find(
      (account) => account.is_default
    );

    return defaultAccount;
  }, [bankAccounts]);

  return [bankAccounts, defaultAccount] as const;
}
