import { Generator } from 'data/models/generator';
import { PropertyCategoryRequirement } from 'data/models/entities/settings/property-category-requirements';
import { BaseFields, RecordTypes, TimestampAttributes } from '../../types';

export type PropertyComplianceCategory = BaseFields<
  typeof RecordTypes.PropertyComplianceCategory
> &
  TimestampAttributes & {
    label: string;
    description: string;
    category_requirements: { data: PropertyCategoryRequirement[] };
  };

export type CreatePropertyComplianceCategory = {
  label: string;
  description: string;
};

const config = {
  entities: {
    related: {
      category_requirements: {
        include: 'category_requirements'
      }
    }
  }
};

export const settingsPropertyComplianceCategoriesModel = new Generator<PropertyComplianceCategory>(
  'settings/property-compliance-categories',
  config
).createEntityModel();
