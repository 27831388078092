import { useCallback, useEffect, useState } from 'react';

import { flatten, get, set, uniqueId } from 'lodash';
import { MultiItemDialogProps } from './multi-item-dialog';
import { getNextPage } from './get-next-page';

type FormConfigItem = {
  names: string[] | undefined;
  initialValues: any;
  validate: any;
  handleSubmit: any;
};

type FormConfig = {
  id: string;
  formConfig: Record<string, FormConfigItem>;
};

type useMultiItemDialogPageStateProps = Pick<
  MultiItemDialogProps,
  'content' | 'data' | 'initialValues'
>;

export function useMultiItemDialogPageState({
  data,
  initialValues,
  content
}: useMultiItemDialogPageStateProps) {
  const [itemCount, setItemCount] = useState(0);
  const [currentItemIndex, setCurrentItemIndex] = useState(0);
  const [manyFormConfigs, setManyFormConfigs] = useState<FormConfig[]>([]);
  const [completedPageIds, setCompletedPageIds] = useState<string[]>([]);

  // Submit individual blocks in the dialog, which will just pass the changed
  // values up to the dialogs submit handler, this way we can validate all
  // forms at the same time and handle errors appropriately
  const handleBlockSubmit = useCallback((values, { isChanged }) => {
    const changedValues = Object.keys(flatten(isChanged)).reduce(
      (acc, name) =>
        // HACK: we want to send the field up as changed if it is changed
        // or if it is part of a field array with any changes is it
        get(isChanged, name) || name.match(/\.[0-9]+\./)
          ? set(acc, name, get(values, name))
          : acc,
      {}
    );
    return { values, changedValues };
  }, []);

  // Initialise all forms so we can validate and submit them, without them
  // even needing to be rendered
  // each item here is a page, so we need to enumerate this
  useEffect(() => {
    if (manyFormConfigs.length > 0) {
      return;
    }
    const configs = data.map((datum) => {
      const formConfig = content.reduce<Record<string, FormConfigItem>>(
        (acc, { blocks, leftBlocks }) => {
          const allBlocks = [...blocks, ...(leftBlocks ? leftBlocks : [])];

          allBlocks.forEach((block) => {
            if (block.id) {
              acc[block.id] = {
                names: block.fieldNames,
                initialValues: initialValues || datum,
                validate: block.validate ?? {},
                handleSubmit: handleBlockSubmit
              };
            }
          });
          return acc;
        },
        {}
      );
      return { id: uniqueId(), formConfig };
    });
    setManyFormConfigs(configs);
    setCompletedPageIds([]);
  }, [content, initialValues, data, handleBlockSubmit, manyFormConfigs.length]);

  useEffect(() => {
    setItemCount(manyFormConfigs.length);
  }, [manyFormConfigs]);

  const markPageAsDone = useCallback((idOfCurrentPage: string) => {
    setCompletedPageIds((prev) => [...prev, idOfCurrentPage]);
  }, []);

  const handleNext = useCallback(
    () =>
      getNextPage({
        currentItemIndex,
        itemCount,
        manyFormConfigs,
        completedPageIds,
        setCurrentItemIndex
      }),
    [completedPageIds, currentItemIndex, itemCount, manyFormConfigs]
  );

  return {
    manyFormConfigs,
    itemCount,
    currentItemIndex,
    completedPageIds,
    handleNext,
    markPageAsDone
  };
}
