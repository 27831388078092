import { StatusTagMap } from 'src/modules/common/components/status-tag';
import { PropertyOwnershipStatusType } from 'src/modules/property-ownerships/types/property-ownership-types';

export const propertyOwnershipStatusMap: StatusTagMap<PropertyOwnershipStatusType> = {
  archived: 'bad',
  active: 'good',
  draft: 'average',
  outgoing: 'average',
  incoming: 'average'
};
