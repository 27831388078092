import React from 'react';
import { Field } from '@rexlabs/form';
import { Grid } from 'view/components/@luna/form/grid';

import { BlockConfig } from 'view/components/record-screen/types';
import { TaskEntitySelect } from 'view/components/inputs/selects/v4-selects/entity-task-fixture-select';
import { ValueListSelect } from 'view/components/inputs/selects/value-list';

import { Task } from 'src/modules/tasks/common/types/Task';

const validate = {
  definitions: {
    linked_task: {
      name: 'task',
      rules: 'required'
    },
    relation: {
      name: 'relation',
      rules: 'required'
    }
  }
};

export const AddLinkBlock: BlockConfig<Task<any>> = {
  id: 'link-task',
  title: 'Link task',
  validate,
  Edit: () => {
    return (
      <Grid columns={3}>
        <Field
          id={'this_task'}
          name='this_task'
          label='This task'
          Input={TaskEntitySelect}
          optional={false}
          inputProps={{
            disabled: true,
            disableFixture: true
          }}
        />

        <Field
          id={'relation'}
          name='relation'
          label='Relation'
          Input={ValueListSelect}
          inputProps={{ type: 'task_link_relation' }}
        />

        <Field
          id={'linked_task'}
          name='linked_task'
          label='Task search'
          Input={TaskEntitySelect}
        />
      </Grid>
    );
  }
};
