import * as React from 'react';
import { ValueListItem } from 'utils/normaliser/value-list-item';
import { Dropdown, DropdownProps } from 'view/components/@luna/dropdown';
import { useApiValueList } from 'view/hooks/api/use-api-value-list';

type TaskStatusMenuProps = Omit<
  DropdownProps<ValueListItem>,
  'normaliser' | 'items'
>;

export function TaskStatusMenu(props: TaskStatusMenuProps) {
  const { data } = useApiValueList('quote_status');

  const intentMap = {
    requested: 'neutral',
    received: 'average',
    sent_for_approval: 'average',
    approved: 'good',
    rejected: 'bad'
  };

  return (
    <Dropdown<ValueListItem>
      items={data}
      normaliser={({ id, label }) => ({ id, label, intent: intentMap[id] })}
      {...props}
    />
  );
}
