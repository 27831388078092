import { Columns } from 'view/components/table';
import { useTranslation } from 'react-i18next';
import { KeySetLargeDescriptionCell } from '../components/key-set-large-description-cell';

import { NameAndNumberOfKeySetCell } from '../components/name-and-number-of-key-set-cell';
import { StatusCell } from '../components/status-cell';
import { KeySet } from './key-set-model';

const useCommonColumns = (): Columns<KeySet> => {
  const { t } = useTranslation();

  return [
    {
      id: 'name',
      Header: t('key-sets.key-set-name.label.singular') as string,
      Cell: NameAndNumberOfKeySetCell,
      width: '25%'
    },
    {
      id: 'notes',
      Header: 'Notes',
      Cell: KeySetLargeDescriptionCell
    }
  ];
};

export const useActiveColumns = (): Columns<KeySet> => {
  const commonColumns = useCommonColumns();

  return [
    ...commonColumns,
    {
      id: 'status',
      Header: 'Status',
      Cell: StatusCell,
      width: '17%'
    }
  ];
};

export const useArchivedColumns = (): Columns<KeySet> => {
  const commonColumns = useCommonColumns();

  return [
    ...commonColumns,
    {
      id: 'status_reason',
      Header: 'Reason for archiving',
      Cell: KeySetLargeDescriptionCell
    }
  ];
};
