import * as React from 'react';

import { StyleSheet, useStyles } from '@rexlabs/styling';
import Box from '@rexlabs/box';
import { Body, Large } from '@rexlabs/text';
import { PrimaryHeading } from 'src/modules/auth/login/components/heading-primary';

const styles = StyleSheet({
  maintenance: {
    fontSize: 18
  }
});

export const ScheduledMaintenance = () => {
  const s = useStyles(styles);

  return (
    <Box mb='2.4rem' flexDirection='column' alignItems='center'>
      <Body grey>Welcome to Rex Property Management</Body>

      <Box mb='3.2rem'>
        <PrimaryHeading level='display'>We&#39;ll be back soon!</PrimaryHeading>
      </Box>

      <Large {...s('maintenance')}>
        🚧 Rex PM is currently down for maintenance 🚧
      </Large>
    </Box>
  );
};
