import React from 'react';
import { Field } from '@rexlabs/form';
import { NumberInput } from '@rexlabs/text-input';
import { BlockConfig } from 'view/components/record-screen/types';
import { Grid } from 'view/components/@luna/form/grid';
import { Property } from '../types/property-types';

const validate = {
  definitions: {
    bedrooms: { rules: 'min:0' },
    ensuites: { rules: 'min:0' },
    bathrooms: { rules: 'min:0' }
  }
};

export const propertyDetailsBlock: BlockConfig<Property> = {
  id: 'propertyDetails',
  title: 'Property details',
  validate,
  Edit() {
    return (
      <Grid columns={4}>
        <Field
          name='bedrooms_count'
          label='Bedrooms'
          Input={NumberInput}
          optional={false}
        />
        <Field
          name='ensuites_count'
          label='Ensuites'
          Input={NumberInput}
          optional={false}
        />
        <Field
          name='bathrooms_count'
          label='Bathrooms'
          Input={NumberInput}
          optional={false}
        />
      </Grid>
    );
  }
};
