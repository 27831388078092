import React, { useMemo } from 'react';
import { Columns, ListTable, TabbedTable } from 'view/components/table';
import { query, useModelActions } from '@rexlabs/model-generator';
import { useDialog } from '@rexlabs/dialog';
import { push } from '@rexlabs/whereabouts';
import ROUTES from 'routes/app';
import { getRecordLinkProps } from 'view/components/record-link/get-record-link-props';
import { transformActionDeclarationsToActionMenuItems } from 'utils/actions/transforms';
import { useTableFilters } from 'view/hooks/use-table-filters';
import { trustJournalEntryColumns } from 'src/modules/trust-ledgers/components/trust-journal-entries-table';
import { TagCell } from '@rexlabs/table';
import { StatusTag } from 'src/modules/common/components/status-tag';
import { BankWithdrawal } from 'src/modules/banking/bank-withdrawals/types/bank-withdrawal-types';
import {
  bankWithdrawalModel,
  bankWithdrawalStatusMap
} from 'src/modules/banking/bank-withdrawals/models/bank-withdrawal-model';
import { useSessionState } from 'src/modules/common/hooks/session-state';
import { trustJournalEntryModel } from 'src/modules/trust-journal-entries/models/trust-journal-entries';
import { useBankWithdrawalActions } from 'src/modules/banking/bank-withdrawals/actions/use-bank-withdrawal-actions';
import { RecordType, RecordTypes } from 'data/models/types';
import { CreateBankTransactionDialog } from 'src/modules/banking/common/dialogs/create-bank-transaction';
import { Tab } from 'view/components/table/tabbed';
import { useTranslation } from 'react-i18next';
import { CreateChequeBankWithdrawalDialog } from '../dialogs/create-cheque-bank-withdrawal';
import { TrustJournalEntryWithChequeNumbers } from '../blocks/cheque-bank-withdrawal-details';

const getTrustJournalEntriesQuery = () => query`{
  ${trustJournalEntryModel} {
    id
    payment_information
    contact
    created_by
    updated_by
  }
}`;

const getBankWithdrawalsQuery = () => query`{
  ${bankWithdrawalModel} {
    id
    totals
    bank_account
  }
}`;

const previousBankWithdrawalsEntriesColumns: Columns<BankWithdrawal> = [
  {
    width: 160,
    Header: 'Reference',
    accessor: (item) => item.record_reference
  },
  {
    id: 'totals.amount',
    type: 'currency',
    cellProps: {
      align: 'right'
    },
    Header: 'Amount',
    accessor: (item) => item.totals?.amount
  },
  {
    id: 'totals.count',
    Header: 'Count',
    accessor: (item) => item.totals?.count
  },
  {
    id: 'bank_account.name',
    Header: 'Withdrawn from',
    accessor: (item) => item.bank_account?.name
  },
  {
    id: 'date_of',
    type: 'date',
    Header: 'Date of withdrawal',
    accessor: (item) => item.date_of
  },
  {
    id: 'status',
    Header: 'Status',
    Cell: ({ value }) => (
      <TagCell>
        <StatusTag status={value} map={bankWithdrawalStatusMap} />
      </TagCell>
    )
  },
  {
    id: 'type',
    type: 'value',
    Header: 'Type',
    accessor: (item) => item.type
  },
  {
    id: 'created_at',
    type: 'date',
    Header: 'Created at',
    accessor: (item) => item.created_at
  },
  {
    id: 'updated_at',
    type: 'date',
    Header: 'Updated at',
    accessor: (item) => item.updated_at
  }
];

export interface BankWithdrawalTableProps {
  bankAccountId: string;
}

export function BankWithdrawalTable({
  bankAccountId
}: BankWithdrawalTableProps) {
  const { t } = useTranslation();
  const sessionData = useSessionState();

  const bankWithdrawalModelActions = useModelActions(bankWithdrawalModel);
  const { open: openCreateChequeBankWithdrawal } = useDialog(
    CreateChequeBankWithdrawalDialog
  );

  const { open: openCreateBankWithdrawal } = useDialog(
    CreateBankTransactionDialog
  );
  const { getSort, getFilters } = useTableFilters('bank-withdrawals');
  const actions = useBankWithdrawalActions();

  const tabs = useMemo<Tab[]>(() => {
    const bankAccountFilter = {
      field: 'bank_account_id',
      op: 'eq',
      value: bankAccountId
    };

    const pendingBankWithdrawalFilter = {
      field: 'pending_bank_withdrawal',
      op: 'eq',
      value: true
    };

    const pendingBankWithdrawalsBulkActions = ({
      typeId,
      recordType
    }: {
      typeId: string;
      recordType: RecordType;
    }) => ({ selectedItems }) => {
      const createBankWithdrawalAndGoto = async (lineItems, dateOf) => {
        const bankWithdrawal = await bankWithdrawalModelActions.createItem({
          data: {
            type: {
              id: typeId
            },
            date_of: dateOf,
            line_items: lineItems,
            bank_account: {
              id: bankAccountId
            }
          }
        });

        push(ROUTES.BANK_WITHDRAWAL, {
          params: {
            bankWithdrawalId: bankWithdrawal.data.id
          }
        });
      };

      return [
        {
          label: 'Create bank withdrawal',
          type: 'primary',
          handleAction: async () => {
            if (typeId === 'cheque') {
              openCreateChequeBankWithdrawal({
                recordType,
                trustJournalEntries: selectedItems,
                onCreate: async (
                  lineItemsWithChequeNumbers: TrustJournalEntryWithChequeNumbers[],
                  dateOf
                ) => {
                  const lineItems = lineItemsWithChequeNumbers.map(
                    (lineItem) => ({
                      trust_journal_entry_id: lineItem.id,
                      cheque_number: lineItem.cheque_number
                    })
                  );

                  return createBankWithdrawalAndGoto(lineItems, dateOf);
                }
              });
            } else {
              const lineItems = selectedItems.map((selectedItem) => ({
                trust_journal_entry_id: selectedItem.id
              }));

              openCreateBankWithdrawal({
                recordType,
                trustJournalEntries: selectedItems,
                onCreate: async (dateOf) => {
                  return createBankWithdrawalAndGoto(lineItems, dateOf);
                }
              });
            }
          }
        }
      ];
    };

    const commonTabProps = {
      id: 'pending-withdrawals',
      Table: ListTable,
      columns: trustJournalEntryColumns,
      getQuery: getTrustJournalEntriesQuery,
      getRowLinkProps: ({ item }) =>
        getRecordLinkProps({ type: 'trust_journal_entry', id: item.id }),
      getFilters,
      getSort,
      initialSortBy: [{ id: 'created_at', label: 'Created at', desc: true }]
    };

    return [
      {
        ...commonTabProps,
        name: 'eft',
        label: t('banking.payment-methods.eft.label'),
        getBulkActions: pendingBankWithdrawalsBulkActions({
          typeId: 'eft',
          recordType: RecordTypes.BankWithdrawal
        }),
        forcedGlobalFilter: [
          bankAccountFilter,
          pendingBankWithdrawalFilter,
          {
            field: 'payment_method_id',
            op: 'eq',
            value: 'eft'
          }
        ]
      },
      ...(sessionData?.activeSilo?.country?.id === 'AUS'
        ? [
            {
              ...commonTabProps,
              name: 'bpay',
              label: 'BPAY',
              getBulkActions: pendingBankWithdrawalsBulkActions({
                typeId: 'bpay',
                recordType: RecordTypes.BankWithdrawal
              }),
              forcedGlobalFilter: [
                bankAccountFilter,
                pendingBankWithdrawalFilter,
                {
                  field: 'payment_method_id',
                  op: 'eq',
                  value: 'bpay'
                }
              ]
            }
          ]
        : []),
      // Add BACS and FPS as tabs for UK silos
      ...(sessionData?.activeSilo?.country?.id === 'GBR'
        ? [
            {
              ...commonTabProps,
              name: 'eft_bacs',
              label: 'BACS',
              getBulkActions: pendingBankWithdrawalsBulkActions({
                typeId: 'eft_bacs',
                recordType: RecordTypes.BankWithdrawal
              }),
              forcedGlobalFilter: [
                bankAccountFilter,
                pendingBankWithdrawalFilter,
                {
                  field: 'payment_method_id',
                  op: 'eq',
                  value: 'eft_bacs'
                }
              ]
            }
          ]
        : []),
      ...(sessionData?.activeSilo?.country?.id === 'GBR'
        ? [
            {
              ...commonTabProps,
              name: 'eft_fps',
              label: 'Faster Payments',
              getBulkActions: pendingBankWithdrawalsBulkActions({
                typeId: 'eft_fps',
                recordType: RecordTypes.BankWithdrawal
              }),
              forcedGlobalFilter: [
                bankAccountFilter,
                pendingBankWithdrawalFilter,
                {
                  field: 'payment_method_id',
                  op: 'eq',
                  value: 'eft_fps'
                }
              ]
            }
          ]
        : []),
      {
        ...commonTabProps,
        name: 'cheques',
        label: 'Cheques',
        getBulkActions: pendingBankWithdrawalsBulkActions({
          typeId: 'cheque',
          recordType: RecordTypes.BankWithdrawal
        }),
        forcedGlobalFilter: [
          bankAccountFilter,
          pendingBankWithdrawalFilter,
          {
            field: 'payment_method_id',
            op: 'in',
            value: ['bank_cheque', 'cheque']
          }
        ]
      },
      {
        id: 'previous-withdrawals',
        columns: previousBankWithdrawalsEntriesColumns,
        Table: ListTable,
        name: 'previous-withdrawals',
        label: 'Previous withdrawals',
        getQuery: getBankWithdrawalsQuery,
        getRowLinkProps: ({ item }) =>
          getRecordLinkProps({ type: 'bank_withdrawal', id: item.id }),
        getActionMenuItems: ({ item }) =>
          transformActionDeclarationsToActionMenuItems(actions(item)),
        initialSortBy: [{ id: 'created_at', label: 'Created at', desc: true }],
        forcedGlobalFilter: [bankAccountFilter]
      }
    ];
  }, [bankAccountId]);

  return <TabbedTable tabs={tabs} />;
}
