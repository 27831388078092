import React from 'react';

import { CellProps, TextCell } from '@rexlabs/table';

import { taskTypes } from 'src/modules/tasks/common/types/TaskType';

import { RecordLink } from 'view/components/record-link/record-link';
import { getRecordLinkProps } from 'view/components/record-link/get-record-link-props';

import { AuditLog } from '../types/AuditLog';

export function RecordCell(props: CellProps<unknown, AuditLog>) {
  const record = props.row.original as AuditLog;

  const {
    record_type: recordType,
    record_name: recordName,
    routable_id: routableId
  } = record;

  let route;

  try {
    route = getRecordLinkProps({
      type: recordType.id as any,
      id: getRecordId(record),
      parentId: routableId
    });
  } catch (e) {
    route = null;
  }

  if (route) {
    return (
      <RecordLink
        type={recordType.id}
        id={getRecordId(record)}
        parentId={routableId}
      >
        {recordName}
      </RecordLink>
    );
  }

  return <TextCell {...props} value={recordName} />;
}
/**
 * So we have a bit of a problem. The audit log returns the id of the item that has been changed. This is fine, but we have some edge cases:
 *   - tasks: Tasks have a parent, that has shared fields with all tasks, and subtasks - these would be a quote, work order, etc and these have their own id, and are not used in the UI as such.
 *     BE now supplies a child task with the parents id in the routable_id. So this is what we use when linking to the task
 *   - property ownerships and property tenancies use the correct id, but we need a routable id to be the property that is their parent. This is passed through to the RecordLink component as the parent ID.
 */
function getRecordId(data: AuditLog) {
  if (taskTypes.includes(data.record_type.id as any)) {
    return data.routable_id;
  }
  return data.record_id;
}
