import React from 'react';

import { ValueListSelect } from './value-list';

export function AdjustmentTypeSelect(props) {
  return (
    <ValueListSelect
      {...props}
      type='ownership_tax_transactions_manual_transaction_types'
    />
  );
}
