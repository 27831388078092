import { BaseModelGeneratorModel } from '@rexlabs/model-generator';

import { Generator } from 'data/models/generator';
import { TimestampAttributes } from 'data/models/types';

export type RentDiscount = BaseModelGeneratorModel &
  TimestampAttributes & {
    amount: number;
    from_date: Date;
    to_date: Date;
    tenancy_id: string;
  };

export type CreateRentDiscount = any;

export const financialsRentDiscountsModel = new Generator<RentDiscount>(
  'financials/rent/discounts'
).createEntityModel();
