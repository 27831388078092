import React, { ComponentProps } from 'react';
import { StyleSheet, useStyles } from '@rexlabs/styling';

const MIN_COLUMN = '220px';

const defaultStyles = StyleSheet({
  layout: {
    display: 'grid',
    gridGap: '20px',
    '@media (max-width: 768px)': {
      gridTemplateColumns: `1fr`
    }
  }
});

interface FormLayoutProps extends ComponentProps<'div'> {
  minColumnWidth?: string;
}

export function FormLayout({
  children,
  minColumnWidth = MIN_COLUMN,
  ...props
}: FormLayoutProps) {
  const s = useStyles(defaultStyles);
  return (
    <div
      {...props}
      {...s.with('layout')({
        gridTemplateColumns: `repeat(auto-fit, minmax(${minColumnWidth}, 1fr))`
      })}
    >
      {children}
    </div>
  );
}
