import { USER_INVITATION_ROUTES } from 'src/modules/auth/user-invitation/routes';
export default {
  LOGIN: {
    config: {
      path: '/login',
      Component: require('./screens/login').LoginScreen
    }
  },

  FORGOT_PASSWORD: {
    config: {
      path: '/forgot-password',
      Component: require('./screens/forgot-password').ForgotPasswordScreen
    }
  },

  RESET_PASSWORD: {
    config: {
      path: '/reset-password',
      Component: require('./screens/reset-password').ResetPasswordScreen
    }
  },
  ...USER_INVITATION_ROUTES
};
