import React from 'react';

import { Truncate } from '@rexlabs/text';
import { CellProps } from '@rexlabs/table';
import { Reconciliation } from 'data/models/entities/financials/reconciliations';

import { margin, StyleSheet, useStyles } from '@rexlabs/styling';
import { formatDate } from 'utils/dates/format';
import { getRecordLabel } from 'utils/records/get-record-label';

const defaultStyles = StyleSheet({
  secondLine: {
    fontSize: ({ token }) => token('typography.size.s'),
    color: ({ token }) => token('color.textStyle.body.subtext'),
    ...margin.styles({
      top: 'xxs'
    })
  }
});

export function CompletedByCell({ value }: CellProps<unknown, Reconciliation>) {
  const s = useStyles(defaultStyles);

  const reconciledBy = value.reconciled_by;
  const reconciledAt = value.reconciled_at;

  return (
    <div>
      {reconciledBy ? (
        <div>
          <Truncate>
            {getRecordLabel({
              type: 'author',
              object: reconciledBy
            })}
          </Truncate>
          <div {...s('secondLine')}>
            {reconciledAt ? formatDate(reconciledAt) : '-'}
          </div>
        </div>
      ) : (
        '-'
      )}
    </div>
  );
}
