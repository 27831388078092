import { useDialog } from '@rexlabs/dialog';
import { BaseModelGeneratorModel, EntityModel } from '@rexlabs/model-generator';
import { ActionDeclaration } from 'src/modules/common/actions/types/action-declaration-types';
import { getSearchResultItemFromObjectAndModel } from 'src/modules/common/utils/search-result-items/get-search-result-items-from-object-and-model';
import { FLAGS } from 'utils/feature-flags';
import { SearchResultItem } from 'utils/api/get-search-results';
import { CoreCommunicationContextObject } from '../../mappers/types/create-message-form-data';
import { CreateLetterRecordDialog } from '../../dialogs/create-letter-record-dialog';

export function useGetCreateLetterAction(model?: EntityModel<any>) {
  const { open } = useDialog(CreateLetterRecordDialog);

  return (relatedObject?: BaseModelGeneratorModel): ActionDeclaration => {
    // If we are invoking this action from another model (contact, ownership, etc)
    // we want to pre-fill the relates_to and other relevant fields, so we need to create a SearchResultItem
    const relates_to: SearchResultItem<CoreCommunicationContextObject> | null = getSearchResultItemFromObjectAndModel(
      relatedObject,
      model
    );

    return {
      flag: FLAGS.LETTERS,
      intent: 'primary',
      id: 'create-letter',
      group: 'messages',
      label: 'Create letter',
      handleAction: async () => {
        open({
          data: {
            relates_to
          }
        });
      }
    };
  };
}
