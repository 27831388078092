import { useDialog } from '@rexlabs/dialog';
import { FieldArrayPassthroughProps } from '@rexlabs/form';
import { TaskType } from 'src/modules/tasks/common/types/TaskType';
import { FLAGS } from 'utils/feature-flags';
import { useFeatureFlags } from 'view/components/@luna/feature-flags';
import { EditChecklistItemTriggerDialog } from '../../../dialogs/edit-checklist-item-trigger';
import { ChecklistTemplateItem } from '../../../types/ChecklistTemplate';

export function useEditTriggerAction({
  checklistTemplateItem,
  fieldArrayProps,
  index,
  taskType
}: {
  checklistTemplateItem: ChecklistTemplateItem;
  fieldArrayProps: FieldArrayPassthroughProps;
  index: number;
  taskType: TaskType;
}) {
  const { open: openEditChecklistItemTriggerDialog } = useDialog(
    EditChecklistItemTriggerDialog
  );

  const { hasFeature } = useFeatureFlags();

  return {
    label: hasFeature(FLAGS.SMART_CHECKLISTS_UI_OVERHAUL)
      ? 'Edit automation'
      : 'Edit',
    id: 'edit-trigger',
    handleAction: () => {
      openEditChecklistItemTriggerDialog({
        checklistTemplateItem,
        fieldArrayProps,
        index,
        taskType
      });
    }
  };
}
