import React from 'react';
import { useStyles, StyleSheet } from '@rexlabs/styling';
import {
  TitleBlock,
  TitleBlockProps
} from 'view/components/cards/title/title-block';
import { RecordObject, RecordTypes } from 'data/models/types';
import { getRecordTitle } from 'utils/records/get-record-title';
import { TagConfig } from 'view/components/@luna/title-block';
import { ActionDeclaration } from 'src/modules/common/actions/types/action-declaration-types';
import { useGetPrimaryRecordActionGroup } from 'view/hooks/actions/use-get-primary-record-action-group';
import { formatDate } from 'utils/dates/format';
import { lowerCase } from 'lodash';
import { useComplianceStatusTag } from 'src/modules/tasks/common/hooks/use-compliance-status-tag';
import ComplianceIcon from 'view/components/icons/compliance';
import PropertyIcon from 'view/components/icons/property';
import { useGetAddDocumentsAction } from 'src/modules/documents/actions/use-get-add-documents-action';
import { useGetAddNotesAction } from 'src/modules/tasks/common/actions/get-add-notes-action';
import { useGetCreateEmailAction } from 'src/modules/communications/messages/hooks/action-declarations/use-get-create-email-action';
import { propertiesModel } from 'data/models/entities/properties';
import { useGetRenewCheckAction } from 'src/modules/tasks/property-compliance/hooks/action-menu-items/use-get-renew-check-action';
import { useGetCreateSMSAction } from 'src/modules/communications/messages/hooks/action-declarations/use-get-create-sms-action';
import { PropertyComplianceTask } from '../types/PropertyComplianceTask';

const type = RecordTypes.TaskPropertyCompliance;

type PropertyComplianceRecordObject = Extract<
  RecordObject,
  { type: 'task_property_compliance' }
>;

type PropertyComplianceTitleBlockProps = {
  task?: PropertyComplianceTask;
  onStatusChange?: () => void;
};

const styles = StyleSheet({
  subText: {
    color: ({ token }) => token('color.textStyle.body.default'),
    fontWeight: ({ token }) => token('typography.weight.semibold')
  }
});

export function PropertyComplianceTitleBlock({
  task
}: PropertyComplianceTitleBlockProps) {
  const s = useStyles(styles);

  const getPrimaryRecordActionGroup = useGetPrimaryRecordActionGroup();
  const getAddDocumentsAction = useGetAddDocumentsAction();
  const getAddNotesAction = useGetAddNotesAction();
  const getCreateEmailAction = useGetCreateEmailAction(propertiesModel);
  const getCreateSMSAction = useGetCreateSMSAction(propertiesModel);
  const getRenewCheckAction = useGetRenewCheckAction();

  // TODO: add correct closed action if required
  //  const getToggleClosedAction = useGetToggleClosedAction();

  const statusTag: TagConfig = useComplianceStatusTag(task);

  const title = getRecordTitle({
    type,
    object: task
  } as PropertyComplianceRecordObject);

  if (!task) {
    return null;
  }

  const actions = [
    ...getPrimaryRecordActionGroup('task', task.id),

    {
      label: 'Actions',
      actions: [
        getAddDocumentsAction({ data: task }),
        getAddNotesAction({ data: task }),
        getCreateEmailAction(task.property),
        getCreateSMSAction(task.property)
      ]
    },
    { ...getRenewCheckAction(task), intent: 'primary' }
  ];

  const closed_at = task.closed_at;

  const subTitles: TitleBlockProps['subTitles'] = [
    {
      Icon: PropertyIcon,
      label: task?.property?.address_string
    }
  ];

  return (
    <TitleBlock
      title={title}
      subTitles={subTitles}
      type={'Property Compliance'}
      actions={actions as ActionDeclaration[]}
      tags={closed_at ? undefined : [statusTag]}
      Icon={() => <ComplianceIcon size='xl' />}
      subText={
        closed_at ? (
          <div>
            <span>This task was closed with the status </span>
            <span {...s('subText')}>
              {lowerCase(task.status.label)}
            </span> on <span {...s('subText')}>{formatDate(closed_at)}</span>
          </div>
        ) : undefined
      }
    />
  );
}
