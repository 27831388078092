import React from 'react';
import Box from '@rexlabs/box';
import { useToken } from '@rexlabs/styling';
import { useMediaQuery } from '@rexlabs/breakpoints';
import ImagesIcon from 'view/components/icons/images';
import CommentIcon from 'view/components/icons/comment';
import { InspectionArea } from 'src/modules/tasks/inspections/entry-exit/types/InspectionArea';
import { StatusBadTag } from '@rexlabs/tag';
import { pluralize } from 'utils/formatters';

export function EntryExitInspectionReportAreaTitle({
  area,
  editMode = false
}: {
  area: InspectionArea;
  editMode?: boolean;
}) {
  const matchesMobile = useMediaQuery({ maxWidth: 's' });

  const token = useToken();

  const areaItems = area?.items?.data;

  if (!matchesMobile || !areaItems || editMode) {
    return <span>{area.name}</span>;
  }

  const atLeastOneAreaItemHasComment = areaItems?.find(
    (areaItem) => !!areaItem.notes
  );

  const atLeastOneAreaItemHasAnImage = areaItems?.find(
    (areaItem) => !!areaItem.images?.data?.length
  );

  const issueCount = areaItems?.reduce((count, areaItem) => {
    return (
      count +
      (areaItem.inspection_conditions?.data.filter(
        (inspectionConditionItem) => inspectionConditionItem.value === false
      ).length || 0)
    );
  }, 0);

  return (
    <Box flexDirection='row' alignItems='center' columnGap={token('spacing.s')}>
      <span>{area.name}</span>

      {!!issueCount && (
        <Box data-testid={'issues.tag'}>
          <StatusBadTag>{pluralize('issues', issueCount, true)}</StatusBadTag>
        </Box>
      )}

      {atLeastOneAreaItemHasComment && (
        <Box
          flexDirection='row'
          alignItems='center'
          data-testid={'comments.icon'}
        >
          <CommentIcon color={token('color.textStyle.link.idle.default')} />
        </Box>
      )}

      {atLeastOneAreaItemHasAnImage && (
        <Box
          flexDirection='row'
          alignItems='center'
          data-testid={'images.icon'}
        >
          <ImagesIcon color={token('color.textStyle.link.idle.default')} />
        </Box>
      )}
    </Box>
  );
}
