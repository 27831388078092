import React from 'react';

import { RecordScreen } from 'view/components/record-screen';
import { TitleBlock } from 'view/components/@luna/title-block';
import { content } from 'src/modules/service-packages/data/content';
import { useSettingsQuery } from 'src/modules/settings/hooks/useSettingsQuery';
import { usePutSettingsMutation } from 'src/modules/settings/hooks/usePutSettingsMutation';
import { useErrorDialog } from '@rexlabs/dialog';
import { RecordSubmitHandler } from 'view/components/record-screen/utils';

function getMappedValues(changedValues) {
  const value = changedValues?.['default_service_package'];

  return [
    {
      key: 'default-service-package',
      value: {
        default_service_package: {
          id: value?.id || null,
          label: value?.name || null
        }
      }
    }
  ];
}

export function FeesAndServicePackagesList() {
  const { data, isLoading } = useSettingsQuery();

  const putSettings = usePutSettingsMutation();
  const errorDialog = useErrorDialog();

  const handleSubmit: RecordSubmitHandler = async ({ changedValues }) => {
    if (!Object.keys(changedValues)?.length) {
      return;
    }

    const mappedValues = getMappedValues(changedValues);

    try {
      await putSettings.mutateAsync(mappedValues);
    } catch (e) {
      errorDialog.open(e);
    }
  };

  const defaultServicePackage =
    data?.['default-service-package']?.default_service_package;

  const initialData =
    defaultServicePackage && defaultServicePackage?.id !== null
      ? {
          default_service_package: {
            id: defaultServicePackage?.id,
            name: defaultServicePackage?.label
          }
        }
      : { default_service_package: null };

  return (
    <RecordScreen
      titleBlock={<TitleBlock title={'Fees & Service Packages'} />}
      content={content}
      data={data}
      isLoading={isLoading}
      handleSubmit={handleSubmit}
      initialValues={initialData}
    />
  );
}
