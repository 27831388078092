import React, { ComponentProps } from 'react';
import { StyleSheet, useStyles } from '@rexlabs/styling';

const defaultStyles = StyleSheet({});

type SecondaryListProps = ComponentProps<'div'>;

export function SecondaryList(props: SecondaryListProps) {
  const s = useStyles(defaultStyles);
  return <div {...props} {...s('container')} />;
}
