import { useSettingsQuery } from 'src/modules/settings/hooks/useSettingsQuery';
import { ValueListValue } from 'data/models/types';
import { MessageTriggerSettings } from 'src/modules/communications/messages/settings/message-triggers/types/MessageTriggerSettings';
import { WithdrawalPaymentMethods } from 'src/modules/trust-journal-entries/types/trust-journal-entry-types';
import {
  DisbursementFrequency,
  FrequencyType
} from 'src/modules/disbursements/types/frequency';
import { DisbursementFrequencyPreset } from 'src/modules/disbursements/types/types';

export type RentPaidToValues =
  | 'effective_with_surplus'
  | 'periodic_with_surplus'
  | 'effective_and_periodic_with_surplus';

export type RentArrearsPaidToValues = 'end_of_day' | 'start_of_day';

export type ArrearsCalculationValues = 'effective_arrears' | 'periodic_arrears';

export type AutomaticTaskCreationValues = 'all_properties' | 'service_packages';

export type BaseTaskSetting = {
  count: number;
  unit: string;
};

export type BatchReceiptingMatchValues = 'all_invoices' | 'rent_invoices';
export type BatchReceiptingExcessMatchValues =
  | 'manual_allocation'
  | 'receipt_as_deposit';

export const TAX_PAYMENT_ACCOUNT_DETAILS_SETTING_KEY =
  'tax-payment-account-details-setting';
export type TaxPaymentSettings = {
  payment_method_id: WithdrawalPaymentMethods;
  branch_code: string;
  account_number: string;
  payee_id: string;
  reference: string;
};

export const DEFAULT_DISBURSEMENT_FREQUENCY_SETTING_KEY =
  'default-disbursement-setting';
export type DisbursementFrequencySettings = Pick<
  DisbursementFrequency,
  | 'on_frequency_count'
  | 'on_balance_amount'
  | 'is_disbursed_on_balance_amount'
  | 'is_disbursed_on_frequency'
> & {
  on_frequency_unit_id: FrequencyType;
  preset_type_id?: DisbursementFrequencyPreset;
  preset_data?: {
    first_day: number;
    second_day: number;
  };
};

export type Settings = {
  currency: ValueListValue<string>;
  country: ValueListValue<string>;
  timezone: string;
  'financial-year-start': string;
  'accounting-type': ValueListValue<'cash' | 'accrual'>;
  'negative-currency-format': ValueListValue<'dash_prefixed' | 'bracketed'>;
  locale: ValueListValue<'en_US'>;
  'rent-paid-to-date': RentPaidToValues;
  'rent-arrears-paid-to-date': RentArrearsPaidToValues;
  'rent-arrears-calculation': ArrearsCalculationValues;
  'property-compliance-task': BaseTaskSetting & {
    automatic_task_creation: AutomaticTaskCreationValues;
    service_package_ids: string[];
  };
  'supplier-compliance-task': BaseTaskSetting;
  'days-in-advance-to-generate-rent-invoices': number;
  'lease-review-task-interval': {
    automatic_task_creation: AutomaticTaskCreationValues;
    periodic_agreement_review: BaseTaskSetting;
    periodic_agreement_notice: BaseTaskSetting;
    fixed_agreement_notice: BaseTaskSetting;
    service_package_ids: string[];
  };
  'inspection-task-interval': {
    automatic_task_creation: AutomaticTaskCreationValues;
    first_inspection: BaseTaskSetting;
    routine_inspection: {
      count?: number;
      unit?: string;
      is_active?: boolean;
    };
    notice_period: BaseTaskSetting;
    service_package_ids: string[];
  };
  'non-resident-tax-rate': number;
  'batch-receipting-match-setting': ValueListValue<BatchReceiptingMatchValues>;
  'batch-receipting-excess-match-setting': ValueListValue<BatchReceiptingExcessMatchValues>;
  [TAX_PAYMENT_ACCOUNT_DETAILS_SETTING_KEY]: TaxPaymentSettings;
  'remove-task-withheld-funds': ValueListValue<
    'task_is_closed' | 'last_bill_is_uploaded_or_the_task_is_closed'
  >;
  default_service_package: ValueListValue<string>;
  [DEFAULT_DISBURSEMENT_FREQUENCY_SETTING_KEY]: DisbursementFrequencySettings;
  'audit-log-access-limit': number;
  'consolidate-withdrawals-on-payment-files': boolean;
} & MessageTriggerSettings;

export function useSettings(): Settings {
  const { data } = useSettingsQuery();
  if (!data) {
    throw new Error(
      'Settings should be loaded before the application is loaded'
    );
  }

  return data;
}
