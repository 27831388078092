import React from 'react';
import { Columns, RecordTable } from 'view/components/table';
import { transformActionDeclarationsToActionMenuItems } from 'utils/actions/transforms';
import { query } from '@rexlabs/model-generator';
import { FolioCommissionTemplate } from '../types/FolioCommissionTemplate';
import { useGetSupplierCommissionTemplateActions } from '../hooks/action-menu-items/use-get-supplier-commission-template-actions';
import { useGetTemplateListLinkProps } from '../hooks/use-get-template-list-link-props';
import { folioCommissionTemplatesModel } from '../models/folio-commission-templates-model';
import { SupplierCommissionTemplatesEmptyState } from './supplier-commission-templates-empty-state';

const columns: Columns<FolioCommissionTemplate> = [
  {
    id: 'name',
    Header: 'Commission template name'
  },
  {
    id: 'structure_type',
    Header: 'Structure',
    type: 'value'
  }
];

const supplierCommissionTemplatesQuery = query`{
  ${folioCommissionTemplatesModel} {
    id
  }
}`;

export function SupplierCommissionTemplatesTable() {
  const getSupplierCommissionTemplateActions = useGetSupplierCommissionTemplateActions();
  const getTemplateListLinkProps = useGetTemplateListLinkProps();

  return (
    <RecordTable
      id='supplier-commission-templates-table'
      columns={columns}
      getQuery={() => supplierCommissionTemplatesQuery}
      emptyWithoutTable={true}
      Empty={SupplierCommissionTemplatesEmptyState}
      getActionMenuItems={({ item }) =>
        transformActionDeclarationsToActionMenuItems(
          getSupplierCommissionTemplateActions(item)
        )
      }
      getRowLinkProps={getTemplateListLinkProps}
      // TODO: add table sorting/filtering once available from BE
    />
  );
}
