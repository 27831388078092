import React, { useEffect } from 'react';
import Box from '@rexlabs/box';
import { DestructiveButton, OutlineButton } from '@rexlabs/button';
import { Field, FieldArray } from '@rexlabs/form';
import { BlockConfig } from 'view/components/record-screen/types';
import { Grid } from 'view/components/@luna/form/grid';
import { ContactSelect } from 'view/components/inputs/selects/contact';
import { AddIcon } from 'view/components/icons/add';
import { RecordTable } from 'view/components/table';
import { ContactPreferencesCheckboxGroup } from 'view/components/inputs/contact-preferences-checkbox-group';
import { filterNormalisedList } from 'utils/form/filter-normalised-list';
import { NormalisedItem } from '@rexlabs/select';
import { WorkInProgress } from 'view/components/work-in-progress';
import { Contact } from 'src/modules/contacts/types/contact-types';
import { getOwnerContacts } from 'src/modules/ownerships/utils/get-owner-contacts';
import { getNewDisbursements } from 'src/modules/disbursements/utils/get-new-disbursements';

//TODO: add contact preference back to column config once work is complete
const columns = [
  {
    id: 'contact.display_name',
    Header: 'Name'
  }
];

export const ownersBlock: BlockConfig = {
  id: 'owners',
  title: 'Owners',
  validate: {
    definitions: {
      'owners.*.contact': { rules: 'required', name: 'contact' }
    }
  },
  View: ({ data }) => (
    <RecordTable
      id='owners'
      items={data?.owners || []}
      columns={columns}
      getRowId={(item) => item?.contact?.id}
      isLoading={false}
    />
  ),
  Edit: ({ values, forms }) => {
    const selectedOwners: Contact[] = getOwnerContacts(values?.owners);

    // Add a disbursement method for each owner as we add them
    function handleChangeOwner(
      e: React.ChangeEvent<HTMLInputElement>,
      index: number
    ) {
      const disbursementForm = forms?.['ownership-disbursement-preferences'];
      if (!disbursementForm) {
        return;
      }

      const newContact = e?.target?.value;
      const prevContact = values.owners[index].contact;
      const prevDisbursements =
        disbursementForm.values?.disbursement_payment_methods || [];

      const newDisbursements = getNewDisbursements({
        newContact,
        prevContact,
        prevDisbursements
      });

      disbursementForm.setFieldValue(
        'disbursement_payment_methods',
        newDisbursements
      );
    }

    return (
      <FieldArray name='owners'>
        {function LineItem({ fields, push }) {
          useEffect(() => {
            if (fields.length === 0) {
              push({ is_primary_owner: true });
            }
          }, []);

          return (
            <Box sy='2.4rem'>
              {fields?.map?.(({ field, actions }, index) => {
                return (
                  <Box
                    key={field.name}
                    flexDirection='row'
                    alignItems='flex-start'
                    sx='2.4rem'
                    data-testid='owners'
                  >
                    <Grid columns={2} style={{ flex: 1 }}>
                      <Field
                        id='owner'
                        name={`${field.name}.contact`}
                        label='Owner'
                        Input={ContactSelect}
                        onChange={(e) => handleChangeOwner(e, index)}
                        inputProps={{
                          filter: (normalisedItem: NormalisedItem) => {
                            return filterNormalisedList(
                              normalisedItem,
                              selectedOwners
                            );
                          }
                        }}
                      />
                      <WorkInProgress>
                        <Field
                          name={`${field.name}.contact_preferences`}
                          label='Statement delivery preferences'
                          Input={ContactPreferencesCheckboxGroup}
                          optional={false}
                        />
                      </WorkInProgress>
                    </Grid>

                    <Box mt='1.6rem'>
                      <DestructiveButton
                        onClick={() => actions.remove()}
                        isDisabled={fields.length === 1}
                      >
                        Delete
                      </DestructiveButton>
                    </Box>
                  </Box>
                );
              })}

              <OutlineButton
                onClick={() => push({ is_primary_owner: false })}
                IconLeft={AddIcon}
              >
                Add another owner
              </OutlineButton>
            </Box>
          );
        }}
      </FieldArray>
    );
  }
};
