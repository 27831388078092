import React, { useMemo } from 'react';

import { Message } from '@rexlabs/notifications';

import { Card } from 'view/components/@luna/card';
import { Grid } from 'view/components/@luna/form/grid';
import { OutlineButton } from '@rexlabs/button';

import { BlockConfig } from 'view/components/record-screen/types';
import { Value } from 'view/components/values';
import { WorkInProgress } from 'view/components/work-in-progress';
import { formatDate } from 'utils/dates/format';

import { push, useWhereabouts } from '@rexlabs/whereabouts';
import Box from '@rexlabs/box';
import { FlattenedProperty } from 'src/modules/properties/types/property-types';
import { Divider } from 'view/components/@luna/divider/divider';
import { StatBlocks } from 'view/components/@luna/stat-block';
import { formatCurrency } from 'utils/formatters';
import { TitleBlockProps } from 'view/components/cards/title/title-block';
import TenancyIllustration from 'src/assets/illustrations/tenancy.svg';
import { Action } from '@rexlabs/notifications/lib/types/message/action-buttons';
import { PropertyOwnershipSelect } from 'view/components/inputs/selects/v4-selects/property-ownership-select';
import { StyleSheet, useStyles } from '@rexlabs/styling';
import ROUTES from 'routes/app';
import { PropertyOwnership } from 'src/modules/property-ownerships/types/property-ownership-types';
import { useRefreshScreen } from 'view/hooks/use-refresh-screen';
import { useTranslation } from 'react-i18next';
import { startCase } from 'lodash';
import { CustomTitleHeader } from '../../properties/components/custom-title-header';
import { PropertyOwnershipTitleBlock } from '../components/property-ownership-title-block';
import { useGetCreatePropertyOwnershipAction } from '../actions/use-get-create-property-ownership-action';
import { propertyDetailContext } from '../../properties/screens/property-details';

const defaultStyles = StyleSheet({
  propertyOwnershipSelect: {
    width: '300px'
  },
  propertyOwnershipHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexWrap: 'wrap'
  }
});

// TODO: Finish hooking up record summary data
export const propertyOwnershipSummaryBlock: BlockConfig<FlattenedProperty> = {
  id: 'property-ownership-summary',
  View: ({ data }) => {
    const { t } = useTranslation();
    const s = useStyles(defaultStyles);

    const getCreatePropertyOwnershipAction = useGetCreatePropertyOwnershipAction();

    const { query } = useWhereabouts();
    const isOverviewTab = query?.tab === 'overview' || query?.tab == null;

    const { setSelectedPropertyOwnership } = React.useContext(
      propertyDetailContext
    );

    const { refreshScreen } = useRefreshScreen();

    const stats: TitleBlockProps['stats'] = useMemo(() => {
      const financialSummary =
        data?.selected_property_ownership?.ownership?.financial_summary;

      const managementFeesPayable = 0;
      const incomeInLastPeriod = 0;
      const expensesInLastPeriod = 0;

      if (!financialSummary) return [];

      return [
        {
          label: t(
            'property-ownerships.management-agreement-fees.fees-payable.label.plural'
          ),
          value: formatCurrency(managementFeesPayable),
          intent: 'neutral',
          workInProgress: true
        },
        {
          label: 'Income in last period',
          value: formatCurrency(incomeInLastPeriod),
          intent: 'neutral',
          workInProgress: true
        },
        {
          label: 'Expenses in last period',
          value: formatCurrency(expensesInLastPeriod),
          intent: 'neutral',
          workInProgress: true
        }
      ];
    }, [data?.selected_property_ownership?.ownership?.financial_summary]);

    if (data?.selected_property_ownership?.id) {
      const { selected_property_ownership } = data;
      const hasStats = stats.length > 0;

      const titleBlock = (
        <PropertyOwnershipTitleBlock
          isCompact={true}
          hideViewAction={false}
          dropdownActionIntent='tertiary'
          propertyOwnership={selected_property_ownership}
          CustomTitleHeader={CustomTitleHeader}
          stats={[]}
        />
      );

      return (
        <Box>
          <Box {...s('propertyOwnershipHeader')}>
            <CustomTitleHeader>
              {startCase(
                t(
                  'property-ownerships.management-agreement.label.plural'
                ) as string
              )}
            </CustomTitleHeader>
            {isOverviewTab ? (
              <OutlineButton
                onClick={() => {
                  push(ROUTES.PROPERTY, {
                    query: {
                      tab: 'management-agreements'
                    },
                    params: { propertyId: data.id }
                  });
                }}
              >
                View agreement
              </OutlineButton>
            ) : (
              <Box {...s('propertyOwnershipSelect')}>
                <PropertyOwnershipSelect
                  value={data.selected_property_ownership}
                  getItems={() => data.property_ownerships!.items}
                  onChange={(e) => {
                    const propertyOwnership: PropertyOwnership = e.target.value;
                    if (propertyOwnership) {
                      setSelectedPropertyOwnership?.(propertyOwnership);

                      push(ROUTES.PROPERTY, {
                        query: {
                          tab: 'management-agreements',
                          managementAgreement: propertyOwnership.id
                        },
                        params: { propertyId: propertyOwnership.property_id }
                      });
                    }
                  }}
                  dialogProps={{ property: data, onCreate: refreshScreen }}
                />
              </Box>
            )}
          </Box>
          <Divider />
          {titleBlock}

          {hasStats ? (
            <Box marginBottom={'1.2rem'}>
              <StatBlocks stats={stats}></StatBlocks>
            </Box>
          ) : null}

          {isOverviewTab && (
            <Box marginTop={'1.2rem'}>
              <Grid columns={2}>
                <WorkInProgress>
                  <Value
                    label={t(
                      'property-ownerships.management-agreement-fees.label.plural'
                    )}
                  />
                </WorkInProgress>
                <Value
                  label='Agreement start date'
                  value={
                    selected_property_ownership.agreement_start_date
                      ? formatDate(
                          selected_property_ownership.agreement_start_date
                        )
                      : null
                  }
                />
                <Value
                  label='Agreement end date'
                  value={
                    selected_property_ownership.agreement_end_date
                      ? formatDate(
                          selected_property_ownership.agreement_end_date
                        )
                      : null
                  }
                />
                <WorkInProgress>
                  <Value label='Maintenance preferences' />
                </WorkInProgress>
              </Grid>
            </Box>
          )}
        </Box>
      );
    }

    return (
      <Card>
        <Message
          title={t('property-ownerships.management-agreement.setup-new.title')}
          Illustration={TenancyIllustration}
          actions={[
            {
              ...getCreatePropertyOwnershipAction({ property: data! }),
              type: 'primary'
            } as Action
          ]}
        >
          {t('property-ownerships.management-agreement.setup-new.description')}
        </Message>
      </Card>
    );
  }
};
