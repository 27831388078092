import React, { useCallback } from 'react';

import {
  SelectProps,
  Select,
  useItems,
  useFreeformDialog,
  UseItemsArgs,
  UseFreeformDialogArgs,
  FreeformDialogProps
} from '@rexlabs/select';

import { Tenancy } from 'data/models/entities/tenancies';
import { api } from 'utils/api/api-client';
import { normaliseTenancy } from 'utils/normaliser/v4-select-normalisers/tenancy';
import { CreateTenancyDialog } from 'src/modules/tenancies/dialogs/create-tenancy-dialog';

function FreeformTenancyDialog({
  selectItem,
  onCreate: propOnCreate,
  ...props
}: any & FreeformDialogProps<Partial<Tenancy>>) {
  const onCreate = useCallback(
    (data) => {
      propOnCreate?.(data);
      selectItem(data);
    },
    [propOnCreate, selectItem]
  );

  return <CreateTenancyDialog {...props} onCreate={onCreate} />;
}

export type TenancySelectProps = SelectProps<Tenancy> &
  Pick<UseItemsArgs<Tenancy>, 'getSuggestedItems'> & {
    dialogProps?: UseFreeformDialogArgs<Tenancy>['props'];
    disableFixture?: boolean;
  };

export const TenancySelect: React.FC<TenancySelectProps> = ({
  dialogProps,
  disableFixture = false,
  ...props
}) => {
  const getItems = useCallback(async (term) => {
    const tenancies = await api.get(`/tenancies`, {
      // TODO: [AL-459] fix the fact that per_page get param breaks includes defined by model generator
      per_page: 30,
      include: 'tenants',
      search: term
    });
    return tenancies.data;
  }, []);

  const { getSelectProps: useItemsGetSelectProps } = useItems<Tenancy>({
    getItems
  });
  const {
    getSelectProps: useFreeformDialogGetSelectProps
  } = useFreeformDialog<Tenancy>({
    props: dialogProps,
    Dialog: FreeformTenancyDialog
  });

  const selectProps = useItemsGetSelectProps();
  const selectPropsWithFixture = useItemsGetSelectProps(
    useFreeformDialogGetSelectProps()
  );

  return (
    <Select
      {...props}
      id='tenancy-select'
      {...(disableFixture ? selectProps : selectPropsWithFixture)}
      normaliser={normaliseTenancy}
    />
  );
};
