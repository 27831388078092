import React from 'react';
import { BlockConfig } from 'view/components/record-screen/types';
import { ValueListValue } from 'data/models/types';
import { TaskType } from 'src/modules/tasks/common/types/TaskType';
import { Field } from '@rexlabs/form';
import { Checkbox } from '@rexlabs/checkbox';
import { ChecklistTemplateItem } from '../../types/ChecklistTemplate';
import {
  ChecklistTemplatesForm,
  ChecklistTemplatesItemForm
} from '../../types/ChecklistTemplatesForm';

export const checklistTemplateItemAdditionalDetailsBlock: BlockConfig<
  ChecklistTemplateItem,
  {
    taskType: ValueListValue<TaskType>;
    mainValues: Partial<ChecklistTemplatesForm>;
  },
  ChecklistTemplatesItemForm & { new_outcome_option: string }
> = {
  id: 'checklist-template-item-additional-details',
  title: 'Additional details',
  Edit() {
    return (
      <Field
        name='blocks_subsequent_items'
        Input={Checkbox}
        optional={false}
        inputProps={{
          label:
            'Require this subtask to be completed before subsequent subtasks can be completed'
        }}
      />
    );
  }
};
