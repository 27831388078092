import { ValueListValue } from 'data/models/types';
import { isString, capitalize, lowerCase } from 'lodash';

export type ValueListItem = ValueListValue<string>;

// TODO: any, because it is consumed by things that still think it should be typed the old way
export function normaliseValueListItem(item: ValueListItem): any {
  // Because initial filters can be passed to the
  // table with only a value but the select needs
  // to be able to display a label, we can make
  // one here based on the id
  if (isString(item)) {
    return {
      id: item,
      label: capitalize(lowerCase(item))
    };
  }
  return {
    id: item.id,
    label: item.label
  };
}

export function denormaliseValueListItem(item): ValueListItem {
  return item;
}
