import React, { useMemo } from 'react';
import { TrustLedgerList } from 'src/modules/trust-ledgers/components/trust-ledger-list';
import {
  getTrustLedgersListQuery,
  getFilterByTrustAccountId
} from '../data/trust-ledgers';

interface TrustLedgersListConnectorProps {
  trustAccountId: string;
}

export function TrustLedgersListConnector({
  trustAccountId
}: TrustLedgersListConnectorProps) {
  const query = useMemo(
    () => getTrustLedgersListQuery(getFilterByTrustAccountId(trustAccountId)),
    [trustAccountId]
  );
  return <TrustLedgerList query={query} />;
}
