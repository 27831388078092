import { Ownership } from 'data/models/entities/ownerships';
import {
  BaseFields,
  RecordReference,
  RecordTypes,
  TimestampAttributes,
  ValueListValue
} from 'data/models/types';
import { Author } from 'src/modules/authors/types/author-types';
import { Contact } from 'src/modules/contacts/types/contact-types';

export enum StatementType {
  PeriodicOwnership = 'periodic_ownership',
  YearlyOwnership = 'yearly_ownership',
  PeriodicAgency = 'periodic_agency',
  YearlyAgency = 'yearly_agency'
}

export enum StatementStatus {
  Issued = 'issued',
  Void = 'void',
  Approved = 'approved',
  NeedsFurtherReview = 'needs_further_review',
  Excluded = 'excluded'
}

export interface Statement
  extends BaseFields<typeof RecordTypes.Statement>,
    TimestampAttributes,
    RecordReference {
  statement_from: string;
  statement_to: string;

  type: ValueListValue<StatementType>;

  recipients: {
    items: Contact[];
  };

  display_name: string; // TODO: change this to label

  object: Ownership | Contact;

  status: ValueListValue<StatementStatus>;
  status_reason?: string;

  voided_at?: Date;
  voided_by?: Author;
}
