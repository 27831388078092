import { useDialog } from '@rexlabs/dialog';

import { SingleActionDeclaration } from 'src/modules/common/actions/types/action-declaration-types';
import { CompleteInspectionReportDialog } from 'src/modules/tasks/inspections/dialogs/complete-inspection-report-dialog';
import { InspectionTask } from '../../types/InspectionTask';

export function useGetCompleteInspectionReportAction() {
  const { open } = useDialog(CompleteInspectionReportDialog);

  return (inspectionTask: InspectionTask): SingleActionDeclaration => {
    return {
      id: 'complete-inspection-report',
      intent: 'primary',
      group: 'inspection',
      label: 'Complete report',
      handleAction: () => open({ inspectionTask })
    };
  };
}
