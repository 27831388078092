import React from 'react';
import { Columns } from 'src/view/components/table';
import { ConditionalActionMenuCell } from 'view/components/cells/conditional-action-menu';
import { transformActionDeclarationsToActionMenuItems } from 'utils/actions/transforms';
import {
  getViewMessageActionArgs,
  useGetViewMessageAction
} from 'src/modules/communications/messages/hooks/action-declarations/use-get-view-message-action';

const eventCell = {
  id: 'event',
  Header: 'Event',
  accessor: (item) => item.credit_event_type.id,
  Cell({ value }) {
    return <>{getEventLabel(value)}</>;
  }
};

export const smsActivityColumns: Columns = [
  eventCell,
  {
    id: 'recipient',
    Header: 'Recipient',
    accessor: (item) => {
      return item?.recipient_contact?.display_name;
    }
  },
  {
    Header: 'Created by',
    id: 'created_by',
    type: 'author'
  },
  {
    id: 'created_at',
    Header: 'Date',
    type: 'date'
  },

  {
    id: 'credits_amount',
    Header: 'Amount',
    type: 'currency'
  },
  {
    id: 'action_menu',
    accessor: (item) => item,
    Cell(props) {
      const getViewMessageAction = useGetViewMessageAction();

      return (
        <ConditionalActionMenuCell
          {...props}
          getActionMenuItems={({ item }) => {
            if (item.credit_event_type.id === 'sms_outgoing') {
              return transformActionDeclarationsToActionMenuItems([
                getViewMessageAction(
                  getViewMessageActionArgs(item.channel_message_recipient)
                )
              ]);
            }

            return [];
          }}
        />
      );
    }
  }
];

export const smsTopUpColumns: Columns = [
  eventCell,
  {
    Header: 'Created by',
    id: 'created_by',
    type: 'author'
  },
  {
    id: 'created_at',
    Header: 'Date',
    type: 'date'
  },
  {
    id: 'credits_amount',
    Header: 'Amount',
    type: 'currency'
  }
];

// Helper

type CreditEventType = 'deduct_manual' | 'top_up_manual' | 'sms_outgoing';

function getEventLabel(value: CreditEventType) {
  switch (value) {
    case 'deduct_manual':
      return 'Deduct';
    case 'top_up_manual':
      return 'Top-up';
    case 'sms_outgoing':
      return 'SMS sent';
  }
}
