import * as React from 'react';
import Icons from '@rexlabs/icons';
import { StyleSheet, useStyles } from '@rexlabs/styling';
import { LinkButton } from '@rexlabs/button';

const styles = StyleSheet({
  removeIcon: {
    color: ({ token }) => token('palette.red.900')
  }
});

export function RemoveCell({ value }) {
  const s = useStyles(styles);
  const { remove } = value;

  const handleClick = () => remove();

  return (
    <LinkButton
      IconLeft={Icons.CrossSmall}
      {...s('removeIcon')}
      onClick={handleClick}
    />
  );
}
