import { useDialog } from '@rexlabs/dialog';

import { ActionDeclaration } from 'src/modules/common/actions/types/action-declaration-types';

import { EditTaxAdjustmentDialog } from '../dialogs/edit-tax-adjustment-dialog';
import { OwnershipTaxTransaction } from '../types/ownership-tax-transaction';
import { mapTaxAdjustmentToFormValues } from '../mappers/map-tax-adjustment-to-form';
import { useGetEditTaxAdjustmentSubmitHandler } from './use-get-edit-tax-adjustment-submit-handler';

export function useGetEditTaxAdjustmentAction() {
  const { open: openEditTaxAdjustmentDialog } = useDialog(
    EditTaxAdjustmentDialog
  );
  const getEditTaxAdjustmentSubmitHandler = useGetEditTaxAdjustmentSubmitHandler();

  return (taxAdjustment: OwnershipTaxTransaction): ActionDeclaration => {
    const submitHandler = getEditTaxAdjustmentSubmitHandler(taxAdjustment);

    return {
      id: `edit-tax-adjustment-${taxAdjustment.id}`,
      label: 'Edit adjustment',
      handleAction: () =>
        openEditTaxAdjustmentDialog({
          submitHandler,
          initialValues: mapTaxAdjustmentToFormValues(taxAdjustment)
        })
    };
  };
}
