import config from 'config';

import { OptimisedRouteResponse } from '../types/OptimisedRouteResponse';
import { Coordinates } from '../types/Coordinates';

export async function fetchOptimisedRoute(
  coordinates: Coordinates[]
): Promise<OptimisedRouteResponse> {
  const isRoundTrip =
    coordinates.length > 1 &&
    coordinates[0] === coordinates[coordinates.length - 1];

  const query = new URLSearchParams();

  query.set('source', 'first');
  if (isRoundTrip) {
    query.set('roundtrip', 'true');
  } else {
    query.set('roundtrip', 'false');
    query.set('destination', 'last');
  }
  query.set('access_token', config.MAPBOX_API_KEY);

  const route = await fetch(
    `https://api.mapbox.com/optimized-trips/v1/mapbox/driving/${coordinates.join(
      ';'
    )}?${query}`
  ).then((res) => res.json());

  return route;
}
