import React from 'react';
import Box from '@rexlabs/box';
import { ValueListValue } from 'data/models/types';
import { ActionButtons } from 'view/components/action-buttons';

import WarningCircleIcon from 'view/components/icons/warning-circle';
import { Banner } from 'src/modules/common/components/banner';
import { useDialog } from '@rexlabs/dialog';
import { RenewCheckDialog } from 'src/modules/compliance/common/dialogs/renew-check-dialog';
import { useRenewRequirementToast } from 'src/modules/compliance/common/hooks/use-renew-requirement-toast';
import { ComplianceEntryStatus } from 'src/modules/compliance/common/models/compliance-entries';
import { ComplianceType } from 'src/modules/compliance/common/models/compliance-types';
import { ComplianceBannerContent } from 'src/modules/compliance/common/components/compliance-banner-content';

const statusMap = {
  expires_soon: 'warning',
  expired: 'danger'
} as const;

interface ComplianceBannerProps {
  complianceType: ComplianceType;
  propertyId?: string;
  status: ValueListValue<ComplianceEntryStatus>;
}

export function PropertyComplianceBanner({
  complianceType,
  propertyId,
  status
}: ComplianceBannerProps) {
  const complianceTypeName = complianceType?.label;
  const renewCheckDialog = useDialog(RenewCheckDialog);
  const addToast = useRenewRequirementToast({ complianceTypeName });

  const intent = statusMap[status.id];
  return (
    <Banner intent={intent} Icon={WarningCircleIcon} align={'start'}>
      <Box
        alignItems={'center'}
        justifyContent={'space-between'}
        width={'100%'}
      >
        <ComplianceBannerContent
          complianceTypeName={complianceTypeName}
          status={status.id}
        />
        <ActionButtons
          actions={[
            {
              label: 'Renew',
              onClick: () =>
                renewCheckDialog.open({
                  onSave: addToast,
                  complianceType,
                  propertyId
                })
            }
          ]}
        />
      </Box>
    </Banner>
  );
}
