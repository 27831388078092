import { AlfredColumnConfig } from 'view/components/table/columns/column-types';

export const auditableColumns: AlfredColumnConfig<any>[] = [
  {
    Header: 'Created at',
    id: 'created_at',
    type: 'date',
    width: 140
  },
  {
    Header: 'Created by',
    id: 'created_by',
    type: 'author',
    width: 140
  },
  {
    Header: 'Updated at',
    id: 'updated_at',
    type: 'date',
    width: 140
  },
  {
    Header: 'Updated by',
    id: 'updated_by',
    type: 'author',
    width: 140
  }
];
