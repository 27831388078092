import { query } from '@rexlabs/model-generator';
import { trustJournalEntryLineItemModel } from 'src/modules/trust-journal-entry-line-items/models/trust-journal-entry-line-item-model';
import { RegularQuri, toQuri } from '@rexlabs/quri';

export const trustJournalEntryLineItemQuery = (filters: RegularQuri[]) => {
  const filter = toQuri(filters);
  return query`{
        ${trustJournalEntryLineItemModel} (q: ${filter}, o: "created_at+desc"){
          id
          trust_journal_entry
        }
      }`;
};
