import React, { useMemo } from 'react';

import { BlockConfig } from 'view/components/record-screen/types';

import { ActionButtons } from 'view/components/@luna/action-buttons';

import { Task } from 'src/modules/tasks/common/types/Task';
import { PropertyComplianceEntriesList } from 'src/modules/compliance/common/components/property-compliance-task-entries-list';
import { RenewCheckDialog } from 'src/modules/compliance/common/dialogs/renew-check-dialog';
import { useDialog } from '@rexlabs/dialog';
import { useRenewFromTaskToast } from 'src/modules/compliance/common/hooks/use-renew-from-task-toast';
import { Quri } from '@rexlabs/quri';
import { useModelActions } from '@rexlabs/model-generator';
import {
  sharedIncludes,
  taskIncludes,
  tasksModel
} from 'src/modules/tasks/common/models/tasks-model';

export function getTitle(task: Task<'task_property_compliance'>) {
  const complianceTypeId =
    task.details?.compliance_entry?.compliance_type?.category?.id;
  if (complianceTypeId === 'certificate') {
    return 'Certificates';
  }
  if (complianceTypeId === 'insurance') {
    return 'Insurance details';
  }
  return 'Compliance checks';
}

type GetRelatedComplianceEntriesTableBlock = (
  task: Task<'task_property_compliance'>
) => BlockConfig<Task<'task_property_compliance'>>;

export const getRelatedComplianceEntriesTableBlock: GetRelatedComplianceEntriesTableBlock = (
  task
) => ({
  id: 'related-requirements',
  title: getTitle(task),

  View: ({ data }) => {
    const complianceTypeId =
      data?.details?.compliance_type?.id ||
      data?.details?.compliance_entry?.compliance_type?.id;
    const propertyId = data?.property?.id;

    const filters = React.useMemo(
      () =>
        propertyId
          ? ([
              {
                field: 'object_id',
                op: 'eq',
                value: propertyId
              },
              { field: 'object_type', op: 'eq', value: 'property' },
              {
                field: 'compliance_type_id',
                op: 'eq',
                value: complianceTypeId
              },
              {
                field: 'status_id',
                op: 'in',
                value: ['valid', 'expired', 'expires_soon', 'archived']
              }
            ] as Quri[])
          : [],
      [complianceTypeId, propertyId]
    );

    return <PropertyComplianceEntriesList filters={filters} task={data} />;
  },
  Actions: ({ data }) => {
    const { refreshItem } = useModelActions(tasksModel);
    const renewCheckDialog = useDialog(RenewCheckDialog);
    const addToast = useRenewFromTaskToast();
    const handleSave = (updatedData) => {
      addToast(updatedData);
      return refreshItem({
        id: data?.id,
        args: {
          include: [
            taskIncludes.propertyComplianceDetails,
            ...sharedIncludes
          ].join(',')
        }
      });
    };

    const actions = useMemo(
      () => [
        {
          label: 'Add new',
          handleAction: () =>
            renewCheckDialog.open({
              onSave: handleSave,
              propertyId: data?.property?.id,
              complianceType:
                data?.details?.compliance_type ||
                data?.details?.compliance_entry?.compliance_type
            })
        }
      ],
      []
    );
    return <ActionButtons actions={actions} />;
  }
});
