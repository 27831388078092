import { Checkbox } from '@rexlabs/checkbox';
import { Field } from '@rexlabs/form';
import { NumberInput, TextInput } from '@rexlabs/text-input';
import { get } from 'lodash';
import React from 'react';
import { Column, Grid } from 'view/components/@luna/form/grid';
import { CentAmountInput } from 'view/components/inputs/cent-amount';
import { AgencyFeeEntityTypeRadioGroup } from 'view/components/inputs/radios/agency-fee-entity-type';
import { FeeTrigger } from 'view/components/inputs/selects/fee-trigger';
import { RateType } from 'view/components/inputs/selects/rate-type';
import { TaxIncludedSelect } from 'view/components/inputs/selects/tax-included-select';
import { TaxTypeSelect } from 'view/components/inputs/selects/tax-type';
import { ChartOfAccountsAccountSelect } from 'view/components/inputs/selects/v4-selects/chart-of-accounts-account';
import { SimplePercentageInput } from 'view/components/inputs/simple-percentage-input';
import { BlockConfig } from 'view/components/record-screen/types';
import { useChangeEffect } from 'view/hooks/@luna/use-change-effect';
import { InfoBanner } from 'view/components/@luna/notifications/banner';
import InfoCircleIcon from 'view/components/icons/info';
import { AgencyFeeTaskType } from 'view/components/inputs/agency-fee-task-type';
import { MaintenanceStatusSelect } from 'src/modules/tasks/maintenance/components/maintenance-status-select';
import { LeaseReviewProgressSelect } from 'src/modules/tasks/lease-review/components/progress-select';
import { InspectionProgressSelect } from 'src/modules/tasks/inspections/components/progress-select';
import { TaskStatusSelect } from 'src/modules/tasks/common/components/task-status-select';
import { ArrearsProgressSelect } from 'src/modules/tasks/arrears/components/progress-select';
import { AgencyFeeDayOfMonth } from 'view/components/inputs/selects/agency-fee-day-of-month';
import { useHasServicePackagesFeature } from '../hooks/use-has-service-packages-feature';

function getTaskStatusSelect({
  taskTypeTriggerId
}: {
  taskTypeTriggerId: string;
}) {
  switch (taskTypeTriggerId) {
    case 'task_maintenance':
      return MaintenanceStatusSelect;
    case 'task_arrears':
      return ArrearsProgressSelect;
    case 'routine_inspection':
      return InspectionProgressSelect;
    case 'entry_inspection':
      return InspectionProgressSelect;
    case 'exit_inspection':
      return InspectionProgressSelect;
    case 'task_move_out':
      return TaskStatusSelect;
    case 'task_move_in':
      return TaskStatusSelect;
    case 'task_lease_review':
      return LeaseReviewProgressSelect;

    // The task status select will be disabled if a task type
    // is not selected so here we don't really care what status
    // select is returned we just need to return one to show
    // in a disabled state
    default:
      return MaintenanceStatusSelect;
  }
}

export const agencyFeeBlock: BlockConfig = {
  id: 'agencyFee',
  title: 'Agency fee',
  validate: {
    definitions: {
      entity_type: { name: 'entity type', rules: 'required' },
      trigger_type: { name: 'trigger type', rules: 'required' },
      name: { rules: 'required' },
      agency_chart_of_accounts_account: { rules: 'required' },
      ownership_chart_of_accounts_account: { rules: 'required' },
      tax_type: { rules: 'required' },
      'fee_amount.type': { rules: 'required' },
      'fee_amount.amount': { rules: 'required' },
      is_tax_included: { rules: 'required' },
      trigger_task_type: {
        rules: 'required_if:trigger_type.id,task_status_changed',
        name: 'task type'
      },
      trigger_task_status: {
        rules: 'required_if:trigger_type.id,task_status_changed',
        name: 'when status changes to'
      },
      trigger_day_of_month: {
        rules: 'required_if:trigger_type.id,day_of_month',
        name: 'trigger fee on day'
      }
    },
    messages: {
      required_if: 'The :attribute field is required.'
    }
  },
  Edit: function EditAgencyFee({ values, setFieldValue, blockProps }) {
    const hasServicePackagesFeature = useHasServicePackagesFeature();

    const entityType = get(values, 'entity_type');
    const triggerTypeId = get(values, 'trigger_type.id');
    const taskTypeTriggerId = get(values, 'trigger_task_type.id');

    const rentTriggerIds = [
      'rent_receipted',
      'rent_receipted_excluding_first_time',
      'rent_receipted_including_first_time',
      'first_rent_receipted',
      'rent_invoice_fully_paid'
    ];

    useChangeEffect(() => {
      setFieldValue?.('trigger_type', null);
      setFieldValue?.('is_default_for_new', false);
    }, [entityType]);

    useChangeEffect(() => {
      setFieldValue?.('fee_amount.type', {
        label: 'Fixed amount',
        id: 'amount'
      });
      setFieldValue?.('trigger_task_type', null);
      setFieldValue?.('trigger_task_status', null);
      setFieldValue?.('trigger_day_of_month', null);
    }, [triggerTypeId]);

    useChangeEffect(() => {
      setFieldValue?.('fee_amount.amount', null);
    }, [get(values, 'fee_amount.type.id')]);

    useChangeEffect(() => {
      setFieldValue?.('trigger_task_status', null);
    }, [taskTypeTriggerId]);

    return (
      <Grid columns={4}>
        {blockProps?.showInfoBannerForEditingTemplate &&
          hasServicePackagesFeature && (
            <Column width={4}>
              <InfoBanner
                Icon={InfoCircleIcon}
                description='Existing fees using this template will not be modified, changes will only apply to new fees.'
              ></InfoBanner>
            </Column>
          )}

        {!blockProps?.entityTypeIsPredefined && (
          <Column width={4}>
            <Field
              label={
                hasServicePackagesFeature
                  ? 'This fee applies to:'
                  : 'What does the fee apply to'
              }
              name='entity_type'
              Input={AgencyFeeEntityTypeRadioGroup}
              inputProps={{
                orientation: hasServicePackagesFeature
                  ? 'vertical'
                  : 'horizontal'
              }}
            />
          </Column>
        )}
        <Column width={2}>
          <Field name='name' label='Fee template name' Input={TextInput} />
        </Column>

        <Column width={2}>
          <Field
            id='trigger_type'
            name='trigger_type'
            label='When is the fee incurred?'
            Input={FeeTrigger}
            inputProps={{
              disabled: !entityType,
              feeEntity: entityType
            }}
          />
        </Column>

        {entityType === 'property_ownership' &&
          triggerTypeId &&
          rentTriggerIds.includes(triggerTypeId) && (
            <Column width={4}>
              <InfoBanner
                Icon={InfoCircleIcon}
                description='This fee will be applied to the rent invoice total (incl. tax)'
              ></InfoBanner>
            </Column>
          )}

        {triggerTypeId === 'task_status_changed' && (
          <>
            <Column width={2}>
              <Field
                id='trigger_task_type'
                name='trigger_task_type'
                label='Task Type'
                Input={AgencyFeeTaskType}
                optional={false}
              />
            </Column>
            <Column width={2}>
              <Field
                id='trigger_task_status'
                name='trigger_task_status'
                label='When status changes to'
                Input={getTaskStatusSelect({
                  taskTypeTriggerId: taskTypeTriggerId
                })}
                inputProps={{ disabled: !taskTypeTriggerId }}
                optional={false}
              />
            </Column>
          </>
        )}

        {triggerTypeId === 'day_of_month' && (
          <>
            <Column width={2}>
              <Field
                id='trigger_day_of_month'
                name='trigger_day_of_month'
                label='Trigger fee on day'
                Input={AgencyFeeDayOfMonth}
                optional={false}
              />
            </Column>
            <div></div>
          </>
        )}

        <Column width={2}>
          <Field
            id='agency_chart_of_accounts_account'
            name='agency_chart_of_accounts_account'
            label={
              hasServicePackagesFeature
                ? 'Agency account code'
                : 'Agency account'
            }
            Input={ChartOfAccountsAccountSelect}
            inputProps={{
              disableFixture: false,
              filterOnChartOfAccountCategories: ['income'],
              dialogProps: {
                disableAccountCategory: true,
                initialValues: {
                  account_category: {
                    id: 'income',
                    label: 'Income'
                  }
                }
              }
            }}
          />
        </Column>

        <Column width={2}>
          <Field
            id='ownership_chart_of_accounts_account'
            name='ownership_chart_of_accounts_account'
            label={
              hasServicePackagesFeature
                ? 'Ownership account code'
                : 'Ownership account'
            }
            Input={ChartOfAccountsAccountSelect}
            inputProps={{
              disableFixture: false,
              filterOnChartOfAccountCategories: ['expense'],
              dialogProps: {
                disableAccountCategory: true,
                initialValues: {
                  account_category: {
                    id: 'expense',
                    label: 'Expense'
                  }
                }
              }
            }}
          />
        </Column>

        <Field
          id='amount-type'
          name='fee_amount.type'
          label='Amount'
          Input={RateType}
          inputProps={{
            triggerType: triggerTypeId
          }}
        />

        {get(values, 'fee_amount.type.id') === 'percent' && (
          <Field
            name='fee_amount.amount'
            label='&nbsp;'
            Input={SimplePercentageInput}
          />
        )}
        {get(values, 'fee_amount.type.id') === 'amount' && (
          <Field
            name='fee_amount.amount'
            label='&nbsp;'
            Input={CentAmountInput}
          />
        )}
        {get(values, 'fee_amount.type.id') === 'weeks_of_rent' && (
          <Field name='fee_amount.amount' label='&nbsp;' Input={NumberInput} />
        )}

        <Field name='is_tax_included' label='Tax' Input={TaxIncludedSelect} />
        <Field name='tax_type' label='&nbsp;' Input={TaxTypeSelect} />

        {(!hasServicePackagesFeature ||
          get(values, 'entity_type') === 'ownership') && (
          <Column width={2}>
            <Field
              name='is_default_for_new'
              Input={Checkbox}
              optional={false}
              inputProps={{
                label: hasServicePackagesFeature
                  ? 'Apply this fee by default for new ownerships'
                  : 'Default fee for new agreements'
              }}
            />
          </Column>
        )}
      </Grid>
    );
  }
};
