import React from 'react';
import { BlockConfig } from 'view/components/record-screen/types';
import { RecordTable } from 'view/components/table';
import { AccountingJournalEntry } from 'src/modules/account-journal-entries/types/account-journal-entry-types';
import { useAccountingJournalEntryLineItemColumns } from '../hooks/use-accounting-journal-entry-line-item-columns';

export function useAccountingJournalEntryLineItemBlock(): BlockConfig<AccountingJournalEntry> {
  const accountingJournalEntryLineItemColumns = useAccountingJournalEntryLineItemColumns();

  return {
    id: 'accounting-journal-entry-line-item-block',
    title: 'Line items',
    View: ({ data }) => {
      return (
        <RecordTable
          isLoading={false}
          withToolbar={false}
          withPagination={false}
          items={data!.line_items.data}
          id='accounting-journal-entry-line-item-table'
          columns={accountingJournalEntryLineItemColumns}
        />
      );
    }
  };
}
