import { BaseModelGeneratorModel } from '@rexlabs/model-generator';

import { Generator } from 'data/models/generator';
import { TimestampAttributes, ValueListValue } from 'data/models/types';
import { ComplianceType } from 'src/modules/compliance/common/models/compliance-types';
import { Contact } from 'src/modules/contacts/types/contact-types';
import { AlfredFile, HasFile } from 'src/modules/common/types/file';

export type ComplianceEntryStatus =
  | 'archived'
  | 'expires_soon'
  | 'valid'
  | 'expired';

export interface ComplianceEntry
  extends BaseModelGeneratorModel,
    TimestampAttributes,
    HasFile {
  details: string;
  issued_at: string;
  expires_at: string;
  object_type: string;
  object_id: string;
  setting_compliance_type_id: string;
  compliance_type: ComplianceType;
  status: ValueListValue<ComplianceEntryStatus>;
  supplier?: Contact;
  file: AlfredFile;
}

export interface CreateComplianceEntry {
  object_type?: string;
  object_id?: string;
  details: string;
  issued_at: string;
  expires_at?: string;
  compliance_type_id: string;
}

export const complianceEntriesModel = new Generator<ComplianceEntry>(
  'compliance-entries'
).createEntityModel();
