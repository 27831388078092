import React from 'react';
import { TenancyRelatedContact } from 'data/models/entities/tenancies';
import { EmptyTenant } from '../empty/empty-tenants';
import { TenancyEntries } from './tenancy-entries';

export interface TenantsSectionProps {
  tenancyRelatedContacts: TenancyRelatedContact[] | 'empty';
  maxListLength: number;
}

export function TenantsSection({
  tenancyRelatedContacts,
  maxListLength
}: TenantsSectionProps) {
  if (
    tenancyRelatedContacts === 'empty' ||
    tenancyRelatedContacts.length === 0
  ) {
    return <EmptyTenant />;
  }

  return (
    <TenancyEntries
      tenancyRelatedContacts={tenancyRelatedContacts}
      maxListLength={maxListLength}
    />
  );
}
