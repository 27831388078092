import { SelectNormalised } from '@rexlabs/select-old';

import { SecurityDepositType } from 'data/models/entities/financials/security-deposit-types';

export const normaliseSecurityDeposit = (
  item: SecurityDepositType
): SelectNormalised => {
  return {
    value: item,
    label: item.name,
    type: 'security-deposit-type'
  };
};
