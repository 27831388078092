import { ActionDeclaration } from 'src/modules/common/actions/types/action-declaration-types';
import { useErrorDialog } from '@rexlabs/dialog';
import { downloadFile } from 'utils/files';
import { useModelActions } from '@rexlabs/model-generator';
import { Message } from '../../types/Message';
import { MessageBatch } from '../../types/MessageBatch';
import { messageBatchesModel } from '../../models/message-batches-model';

type DownloadType = 'download' | 'open_in_browser';

/**
 * @param downloadType use this option to determine if you want to prompt immediate download, or open in a new tab
 */
export function useGetDownloadLetterAction(
  downloadType: DownloadType = 'open_in_browser'
) {
  const { open } = useErrorDialog();
  const { markItemAsDownloaded, refreshLists } = useModelActions(
    messageBatchesModel
  );

  return (messageOrBatch: Message | MessageBatch): ActionDeclaration => {
    return {
      id: `download`,
      group: 'download',
      label: 'Download',
      handleAction: async () => {
        try {
          // there should only be one, but do this anyway
          messageOrBatch.documents?.items.forEach(async (document) => {
            if (downloadType === 'download') {
              await downloadFile(document.file.download_url);
            } else if (downloadType === 'open_in_browser') {
              // open the link in a new tab
              window.open(document.file.url, '_blank');
            }
          });

          if (
            'downloaded_at' in messageOrBatch &&
            !messageOrBatch.downloaded_at
          ) {
            await markItemAsDownloaded({
              messageBatch: messageOrBatch
            });
            await refreshLists();
          }
        } catch (e) {
          open(e);
        }
      }
    };
  };
}
