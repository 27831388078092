import { api } from 'utils/api/api-client';
import { useEffect, useState } from 'react';
import axios from 'axios';

export function usePostAPI<LoadedData, RequestBody = any>(
  url?: string,
  body?: RequestBody
) {
  const [loadedData, setLoadedData] = useState<LoadedData | undefined>(
    undefined
  );
  const [error, setError] = useState<any | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!url || !body) {
      return;
    }

    const source = axios.CancelToken.source();
    const cancelToken = source.token;

    setIsLoading(true);

    api
      .post(url, body, {
        cancelToken
      })
      .then((res) => {
        setLoadedData(res.data);
        setIsLoading(false);
        setError(undefined);
      })
      .catch((e) => {
        //fix there are errors before the request has even come back, to prevent false positives we filter the error by message
        if (e.message) {
          setError(e);
          setIsLoading(false);
        }
      });

    return () => {
      source.cancel();
    };
  }, [url, body]);

  return {
    loadedData,
    error,
    isLoading
  };
}
