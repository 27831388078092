import faker from '@faker-js/faker';
import { Ownership } from 'data/models/entities/ownerships';
import { valueListFactory } from 'src/lib/testing/factories/value-list-factory';
import { authorFactory } from 'src/modules/authors/factories/author-factory';
import { contactFactory } from 'src/modules/contacts/factories/contact-factory';

export function ownershipFactory(state?: Partial<Ownership>): Ownership {
  return {
    __record_type: 'ownership',
    id: faker.datatype.uuid(),
    custom_record_reference: faker.lorem.word(),
    record_reference: faker.lorem.word(),
    system_record_reference: faker.lorem.word(),
    display_name: faker.name.findName(),
    created_by: authorFactory(),
    status: valueListFactory('active'),
    disbursement_payment_methods: [],
    maintenance_instructions: {
      notes: faker.lorem.sentence(),
      spend_limit: 0
    },
    eofy_statement_enabled: true,
    primary_contact: contactFactory(),
    owners: [
      {
        share: 100,
        contact: contactFactory()
      }
    ],
    created_at: faker.date.past(),
    updated_at: faker.date.past(),
    property_ownerships: {
      data: []
    },
    ...state
  };
}
