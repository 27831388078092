import React, { useCallback } from 'react';
import { isArray } from 'lodash';

import { Field, HiddenField } from '@rexlabs/form';
import { Checkbox } from '@rexlabs/checkbox';

import { BlockConfig } from 'view/components/record-screen/types';
import { Grid } from 'view/components/@luna/form/grid';

import { ContactSelect } from 'view/components/inputs/selects/contact';
import { SupplierSelect } from 'view/components/inputs/selects/v4-selects/supplier-select';
import { QuoteTask } from '../types/QuoteTask';
import { ReceivedQuoteDetailsForm } from '../components/received-quote-details-form';
import { MessageTemplateSelectWithEdit } from '../../common/components/message-template-select-with-edit';
import { CreateQuoteFormDetailsData } from '../types/CreateQuoteFormData';
import { useGetAccessProvidedBySuggestedContacts } from '../../common/hooks/use-get-access-provided-by-suggested-contacts';

export type QuoteDetailsForm = {
  details: Pick<
    CreateQuoteFormDetailsData,
    | 'has_received_quote'
    | 'number'
    | 'notes'
    | 'amount'
    | 'available_start_date'
    | 'quote_attachments'
    | 'message'
    | 'supplier'
    | 'access_provided_by'
  >;
};

export const quoteDetailsBlock: BlockConfig<
  QuoteTask,
  any,
  QuoteDetailsForm
> = {
  id: 'quote-details',
  title: 'Quote details',
  validate: {
    definitions: {
      'details.supplier': { name: 'supplier', rules: 'required' },
      'details.access_provided_by': {
        name: 'access provided by',
        rules: 'required_without:details.has_received_quote'
      },
      'details.amount': {
        name: 'quoted price',
        rules: 'required_with:details.has_received_quote'
      },
      'details.message.template': {
        name: 'message template',
        rules: 'required_without:details.has_received_quote'
      }
    },
    messages: {
      required_with: 'The :attribute field is required.',
      required_without: 'The :attribute field is required.'
    }
  },
  Edit: ({ values, setValues, data, forms }) => {
    const {
      loadItems: loadAccessProvidedBySuggestedContacts
    } = useGetAccessProvidedBySuggestedContacts({
      property: forms?.details?.values?.property
    });

    const isCreateMode = !data?.id;

    const onReceivedQuoteChange = useCallback(
      (e) => {
        const hasReceivedQuote = !!e.target.checked;

        const suppliers = !values?.details?.supplier
          ? []
          : isArray(values?.details?.supplier)
          ? values?.details?.supplier
          : [values?.details?.supplier];

        function getSupplierFieldValue() {
          if (hasReceivedQuote) {
            return suppliers.length ? suppliers[0] : '';
          } else {
            return suppliers;
          }
        }

        const newValues = {
          ...values,
          ...(hasReceivedQuote
            ? {
                details: {
                  ...values?.details,
                  access_provided_by: null,
                  supplier: getSupplierFieldValue()
                }
              }
            : {
                details: {
                  ...values?.details,
                  supplier: getSupplierFieldValue(),
                  amount: null,
                  id: null,
                  notes: null,
                  available_start_date: null,
                  quote_attachments: null
                }
              })
        };
        return setValues?.(newValues);
      },
      [values]
    );

    return (
      <Grid columns={1}>
        {isCreateMode && (
          <>
            <Grid columns={1}>
              <Field
                name='details.has_received_quote'
                Input={Checkbox}
                optional={false}
                onChange={onReceivedQuoteChange}
                inputProps={{
                  label: 'I have already received a quote'
                }}
              />
            </Grid>
            <Grid columns={1}>
              <Field
                id='supplier'
                name='details.supplier'
                label='Supplier'
                Input={SupplierSelect}
                optional={false}
                description={
                  !values?.details?.has_received_quote
                    ? 'To request multiple quotes, add more than one supplier'
                    : ''
                }
                inputProps={{
                  multi: !values?.details?.has_received_quote
                }}
              />
            </Grid>
          </>
        )}

        {values?.details?.has_received_quote || !isCreateMode ? (
          <ReceivedQuoteDetailsForm />
        ) : (
          <>
            <Grid columns={2}>
              <Field
                id='access_provided_by'
                name='details.access_provided_by'
                label='Access provided by'
                Input={ContactSelect}
                inputProps={{
                  getSuggestedItems: loadAccessProvidedBySuggestedContacts
                }}
                optional={false}
              />
            </Grid>
            <Grid columns={1}>
              <Field
                id='details.message'
                name='details.message'
                label='Message template'
                Input={MessageTemplateSelectWithEdit}
                inputProps={{
                  contextType: 'task_quote',
                  editDialogTitle: 'Edit quote request'
                }}
                optional={false}
              />
            </Grid>
          </>
        )}

        <HiddenField name='details.access_provided_by' />
        <HiddenField name='details.message' />
        <HiddenField name='details.amount' />
        <HiddenField name='details.number' />
        <HiddenField name='details.notes' />
        <HiddenField name='details.available_start_date' />
        <HiddenField name='details.quote_attachments' />
      </Grid>
    );
  }
};
