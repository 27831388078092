import { ContentConfig } from 'view/components/record-screen/types';
import { useFeatureFlags } from 'view/components/@luna/feature-flags';
import { FLAGS } from 'utils/feature-flags';
import { emailSignatureBlock } from '../blocks/email-signature-block';
import { userDetailsBlock } from '../blocks/user-details';
import { userPermissionsBlock } from '../blocks/user-permissions-block';

export const useContent = (): ContentConfig => {
  const { hasFeature } = useFeatureFlags();
  return [
    {
      label: 'General',
      items: [
        {
          id: 'overview',
          label: 'Overview',
          blocks: [
            userDetailsBlock,
            userPermissionsBlock,
            ...(hasFeature(FLAGS.EMAIL_SIGNATURE) ? [emailSignatureBlock] : [])
          ]
        }
      ]
    }
  ];
};
