import React from 'react';
import { Field } from '@rexlabs/form';
import { Bold, Body } from '@rexlabs/text';
import { Box } from '@rexlabs/box';

import { DateInput } from 'view/components/@luna/inputs/date-input/date-input';
import { CentAmountInput } from 'view/components/inputs/cent-amount';
import { Grid } from 'view/components/@luna/form/grid';
import { Checkbox } from '@rexlabs/checkbox';
import { InfoBanner } from 'view/components/@luna/notifications/banner';
import InfoCircleIcon from 'view/components/icons/info';

export function RentScheduleDetailsForm({
  showInitialPaidToInfo,
  toggleShowInitialPaidToInfo
}: {
  showInitialPaidToInfo: boolean;
  toggleShowInitialPaidToInfo?: (event: any) => void;
}) {
  return (
    <>
      <Grid columns={2}>
        <Field
          name='selected_property_tenancy.agreement_first_start_date'
          label='First agreement start date'
          Input={DateInput}
          optional={false}
          HelpTooltipContent={() => (
            <>
              <Bold>First agreement start date</Bold>
              <Box mt='1.2rem'>
                <Body>
                  Represents the start date of your very first lease. Rent will
                  commence charging from this date.
                </Body>
              </Box>
            </>
          )}
        />

        <Field
          name='selected_property_tenancy.agreement_last_expected_paid_to_date'
          label='Charge to date'
          Input={DateInput}
          HelpTooltipContent={() => (
            <>
              <Bold>Charge to date (optional)</Bold>
              <Box mt='1.2rem'>
                <Body>
                  The last day that the tenant should be charged rent to - the
                  rent schedule will stop here. This is normally the last day of
                  a lease when the lease is ending or potentially the last day
                  before a new tenant starts in the case of a break lease.
                </Body>
              </Box>
            </>
          )}
        />
      </Grid>

      <Checkbox
        label='Initial rent paid'
        description='If rent has already been paid past agreement start date'
        value={showInitialPaidToInfo}
        onChange={toggleShowInitialPaidToInfo}
      />
      {showInitialPaidToInfo && (
        <>
          <InfoBanner
            Icon={InfoCircleIcon}
            description={
              <>
                The active management agreement for this property includes a
                First Receipt fee. If you enter either an Initial Paid to Date
                or Initial Paid to Surplus, Rex PM will not trigger the first
                rent receipt fee, as it assumes this payment may have already
                been collected. If you still wish to charge the fee or have any
                questions, please contact Customer Support.
              </>
            }
          />
          <Grid columns={2}>
            <Field
              name='selected_property_tenancy.initial_paid_to_date'
              label='Initial paid to date'
              Input={DateInput}
            />

            <Field
              name='selected_property_tenancy.initial_paid_to_surplus'
              label='Initial paid to surplus'
              Input={CentAmountInput}
            />
          </Grid>
          <InfoBanner
            Icon={InfoCircleIcon}
            description={
              <>
                <b>Initial paid to date</b> Setting this will ensure the system
                does not invoice / charge for any rent prior to this date. Only
                add the initial paid to date if you are transferring from
                another system and in some other cases. This field is not meant
                to be used if you have just added this property to your rent
                roll and you have received a first rent payment towards rent.
              </>
            }
          />
          <InfoBanner
            Icon={InfoCircleIcon}
            description={
              <>
                <b>Initial paid to surplus</b> Setting the initial paid to
                surplus means that the tenant has made a part payment past the
                paid to date of the tenant.
              </>
            }
          />
        </>
      )}
    </>
  );
}
