import React from 'react';
import { CellProps } from '@rexlabs/table';
import { Truncate } from '@rexlabs/text';
import { RentPosition } from 'src/modules/property-tenancies/types/property-tenancy-types';
import {
  getPaidToDateDescription,
  getPaidToPeriodDescription
} from '../utils/get-rent-position-description';

export function RentPaidToCell({ value }: CellProps) {
  const { setting = 'effective_and_periodic_with_surplus' } = value;

  const rentPosition: RentPosition = value?.rent_position;

  if (!rentPosition) {
    return <Truncate>--</Truncate>;
  }

  return (
    <>
      {[
        'effective_with_surplus',
        'effective_and_periodic_with_surplus'
      ].includes(setting) && (
        <Truncate>
          {getPaidToDateDescription({ rentPosition }) || '--'}
        </Truncate>
      )}
      {[
        'periodic_with_surplus',
        'effective_and_periodic_with_surplus'
      ].includes(setting) && (
        <Truncate>
          {getPaidToPeriodDescription({ rentPosition }) || '--'}
        </Truncate>
      )}
    </>
  );
}
