import React from 'react';

import { RecordDialog } from 'view/components/record-screen/dialog/dialog';
import { DialogContentConfig } from 'view/components/record-screen/dialog/types';
import { DialogProps } from '@rexlabs/dialog';
import { useModelActions } from '@rexlabs/model-generator';
import { notesModel } from 'src/modules/note/models/notes-model';
import { getValueListItems } from 'utils/api/get-value-list-items';
import { api } from 'utils/api/api-client';
import { queryClient } from 'src/lib/react-query/query-client';
import { useToast } from 'view/components/@luna/notifications/toast';
import { BatchReceiptingItem } from '../types';
import { suspendTransactionDetailsBlock } from '../blocks/suspend-transaction-details';
import { bankStatementTransactionsQueryKey } from '../hooks/use-bank-statement-transactions-query';
import { getTransactionLabel } from '../hooks/use-batch-receipting-button-config';

const content: DialogContentConfig = [
  {
    id: 'suspend-transaction',
    label: 'Suspend Transaction',
    blocks: [suspendTransactionDetailsBlock]
  }
];

interface SuspendTransactionDialogProps extends DialogProps {
  onClose?: () => void;
  batchReceiptingItem: BatchReceiptingItem;
}

export function SuspendTransactionDialog({
  onClose,
  batchReceiptingItem
}: SuspendTransactionDialogProps) {
  const { refreshLists: refreshNoteLists } = useModelActions(notesModel);
  const { addToast } = useToast();

  const handleSubmit = async ({ values }) => {
    const noteTypes = await getValueListItems('note_type');

    const hasNote = values?.content?.length > 0;
    const branch = values?.branch;

    const batchReceiptingItemId = batchReceiptingItem.id;

    await api.post('request-pipelines', {
      requests: [
        {
          id: 'patch-status',
          method: 'PATCH',
          path: `/api/v1/financials/bank-statement-transactions/${batchReceiptingItemId}/status`,
          json: {
            status: {
              id: 'suspended'
            },
            ...(branch ? { assigned_to_branch: { id: branch.id } } : {})
          }
        },
        ...(hasNote
          ? [
              {
                id: 'create-note',
                method: 'POST',
                path: '/api/v1/notes',
                json: {
                  attached_to: [
                    {
                      id: batchReceiptingItemId,
                      type: {
                        id: 'bank_statement_transaction'
                      }
                    }
                  ],
                  note_type: {
                    id: noteTypes.find((noteType) => noteType.label === 'Note')
                      ?.id
                  },
                  content: values?.content
                }
              }
            ]
          : [])
      ]
    });

    const transactionLabel = getTransactionLabel(batchReceiptingItem);

    addToast({
      description: (
        <>Transaction for {transactionLabel} successfully suspended</>
      )
    });

    await Promise.all([
      queryClient.refetchQueries({
        queryKey: [bankStatementTransactionsQueryKey],
        refetchPage: () => true
      }),
      refreshNoteLists()
    ]);

    return true;
  };

  return (
    <RecordDialog
      title='Suspend transaction'
      onClose={onClose}
      content={content}
      submitLabel='Continue'
      handleSubmit={handleSubmit}
      blockProps={{ batchReceiptingItem }}
    />
  );
}
