import { useModelActions } from '@rexlabs/model-generator';

import { api } from 'utils/api/api-client';
import { getUploadedFileMeta } from 'utils/files';

import { RecordSubmitHandler } from 'view/components/record-screen/utils';
import { RoutineInspectionAreaFormData } from 'src/modules/tasks/inspections/types/RoutineInspectionAreaFormData';
import {
  sharedIncludes,
  taskIncludes,
  tasksModel
} from '../../common/models/tasks-model';

export function useGetUpdateRoutineAreaSubmitHandler(
  inspectionId: string
): RecordSubmitHandler {
  const actions = useModelActions(tasksModel);

  const endpoint = 'inspection-areas';

  return async ({ changedValues, values }) => {
    const areaId = Object.keys(changedValues.routine_areas)[0];
    const area: RoutineInspectionAreaFormData = {
      ...values.routine_areas[areaId],
      ...changedValues.routine_areas[areaId]
    };

    if ('images' in area) {
      const { images = [] } = area;

      area.images = await getUploadedFileMeta(images);
    }

    const payload = {
      condition: {
        id: area.condition.id
      },
      notes: area.comments,
      images: area.images
    };

    await api.patch(`/${endpoint}/${areaId}`, payload, {
      params: {
        include: ['images'].join(',')
      }
    });

    await actions.refreshItem({
      id: inspectionId,
      args: {
        include: [
          ...sharedIncludes,
          taskIncludes.inspectionDetails,
          'property.active_property_ownership.ownership.owners.contact'
        ].join(',')
      }
    });
  };
}
