import * as React from 'react';
import { QueryOptions, useQuery, UseQueryResult } from 'react-query';
import {
  mergeWithArray,
  sortByToQuri,
  filtersToQuri
} from '@rexlabs/table/module/utils';
import type { PaginationConfig } from '@rexlabs/table/lib/types/context';
import { AxiosResponse } from 'axios';

export interface QuernFnArg {
  page: number;
  perPage?: number;
  sort?: string;
  filter?: string;
  search?: string;
  include?: string;
}

export interface AxiosResponsePagination extends AxiosResponse {
  pagination: {
    count: number;
    current_page: number;
    per_page: number;
    total: number;
    total_pages: number;
  };
}

export interface UseTableQueryProps {
  primaryQueryKey: string;
  queryFn: (query: QuernFnArg) => Promise<any>;
  queryOptions?: QueryOptions<any>;
}

function useTableQuery(props: UseTableQueryProps) {
  const { primaryQueryKey, queryFn, queryOptions = {} } = props;

  const [
    entity,
    setEntity
  ] = React.useState<UseQueryResult<AxiosResponsePagination> | null>(null);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  function useCustomHook(table) {
    const {
      forcedSortBy,
      forcedGlobalFilter,
      state: { search, pageIndex, pageSize, sortBy, globalFilter }
    } = table;

    const currentPage = pageIndex + 1;

    const query = React.useMemo(() => {
      const sortByWithDefault = mergeWithArray([], sortBy, forcedSortBy);
      const globalFilterWithDefault = mergeWithArray(
        {},
        globalFilter,
        forcedGlobalFilter
      );

      const sort = encodeURIComponent(sortByToQuri(sortByWithDefault));
      const filter = filtersToQuri(globalFilterWithDefault);

      const query = {
        ...(search ? { search } : {}),
        ...(pageSize ? { perPage: pageSize } : {}),
        ...(sort ? { sort } : {}),
        ...(filter ? { filter } : {}),
        page: currentPage
      };

      return query;
    }, [
      search,
      sortBy,
      globalFilter,
      pageSize,
      forcedSortBy,
      forcedGlobalFilter,
      currentPage
    ]);

    const entity = useQuery(
      [primaryQueryKey, query],
      async () => {
        const response = await queryFn(query);

        return response;
      },
      {
        refetchOnMount: false,
        refetchOnWindowFocus: false,
        ...queryOptions
      }
    );

    React.useEffect(() => {
      setEntity(entity);
    }, [entity.dataUpdatedAt, entity.status]);

    const hasError = entity.isError;
    const entityError = entity.error;

    // Set error state
    React.useEffect(() => {
      if (hasError) {
        // We just pass errors up, so the app can handle them via ErrorBoundary
        // TODO: we might wanna throw the error somewhere lower, e.g. `Body`,
        // to allow for other table related components to stay rendered while showing
        // the error state...
        const error = entityError || new Error('Something went wrong!');
        // eslint-disable-next-line
        throw error;
      }
    }, [hasError, entityError]);
  }

  const getTableProps = React.useCallback(() => {
    if (!entity) {
      return {
        items: [],
        allItems: [],
        isLoading: true,
        useCustomHook
      };
    }

    return {
      items: entity.data?.data ?? [],
      isLoading: entity.isLoading,
      pagination: transformPagination(entity.data?.pagination),
      useCustomHook
    };
  }, [entity, useCustomHook]);

  return {
    entity,
    getTableProps
  };
}

export { useTableQuery };

function transformPagination(
  pagination?: AxiosResponsePagination['pagination']
): PaginationConfig {
  if (!pagination)
    return {
      currentPage: 1,
      endReached: false
    };

  return {
    endReached: pagination.current_page >= pagination.total_pages,
    itemsPerPage: pagination.per_page,
    currentPage: pagination.current_page,
    totalItems: pagination.total
  };
}
