import React, { useCallback, useMemo } from 'react';

import { query } from '@rexlabs/model-generator';
import { TagCell } from '@rexlabs/table';
import { StatusBadTag } from '@rexlabs/tag';

import { ActionDeclaration } from 'src/modules/common/actions/types/action-declaration-types';

import { ListTable, TabbedTable, Columns, Column } from 'view/components/table';
import { Tab } from 'view/components/table/tabbed';

import { transformActionDeclarationsToActionMenuItems } from 'utils/actions/transforms';
import { getViewAction } from 'utils/actions/get-view-action';

import { TaxSummary, taxSummariesModel } from '../models/tax-summaries-model';
import { useGetCreateTaxPaymentBulkAction } from '../hooks/use-get-create-tax-payment-bulk-action';
import { IssuePaymentActionCell } from './issue-payment-action-cell';

const taxSummaryQuery = query`{
    ${taxSummariesModel} {
        id
        type
        tax_due
        tax_currently_withheld
        tax_paid
        ownership_share
        ownership {
            operating_bank_account
        }
        contact
    }
}`;

const contactColumn: Column<TaxSummary> = {
  id: 'contact',
  Header: 'Owner',
  type: 'contact',
  width: 200
};

const shortfallColumn: Column<TaxSummary> = {
  id: 'shortfall',
  accessor: (item) => item,
  Cell: (props) => {
    const isShortFall = props.value?.shortfall;

    return isShortFall ? (
      <TagCell>
        <StatusBadTag>Shortfall</StatusBadTag>
      </TagCell>
    ) : null;
  },
  width: 120
};

const ownershipColumn: Column<TaxSummary> = {
  id: 'ownership',
  Header: 'Ownership',
  type: 'ownership',
  width: 200
};

const ownershipShareColumn: Column<TaxSummary> = {
  id: 'ownership_share',
  Header: 'Ownership %',
  type: 'percentage',
  accessor: (item) => item.ownership_share / 100,
  width: 150
};

const taxDueColumn: Column<TaxSummary> = {
  id: 'tax_due',
  Header: 'Tax due',
  type: 'currency',
  width: 120
};

const taxWithheldColumn: Column<TaxSummary> = {
  id: 'tax_currently_withheld',
  Header: 'Tax withheld',
  type: 'currency',
  width: 120
};
const taxPaidColumn: Column<TaxSummary> = {
  id: 'tax_paid',
  Header: 'Tax paid',
  type: 'currency',
  width: 120
};

const issuePaymentColumn: Column<TaxSummary> = {
  id: 'issue_payment',
  Cell: (props) =>
    // If there is no money withheld, don't show the issue payment button, as there is no money to issue the payment.
    props.value.tax_withheld > 0 ? (
      <IssuePaymentActionCell value={props.value} />
    ) : null,
  width: 120,
  accessor: (item) => item
};

const tabNames = ['not_issued', 'shortfall', 'issued'] as const;

function getColumns(tabId: typeof tabNames[number]): Columns {
  switch (tabId) {
    case 'not_issued':
    case 'shortfall':
      return [
        contactColumn,
        shortfallColumn,
        ownershipColumn,
        ownershipShareColumn,
        taxDueColumn,
        taxWithheldColumn,
        issuePaymentColumn
      ];
    case 'issued':
      return [
        contactColumn,
        ownershipColumn,
        ownershipShareColumn,
        taxPaidColumn
      ];
  }
}

export function PaymentsToHmrcTabbedTable({
  quarterStartDate,
  quarterEndDate
}) {
  const getTaxSummariesQuery = React.useCallback(() => taxSummaryQuery, []);

  const getActionMenuItems = useCallback(({ item }) => {
    return transformActionDeclarationsToActionMenuItems([
      getViewAction(
        'view',
        'primary-record',
        { id: item.ownership.id },
        'ownership',
        'Go to ownership'
      )
    ]);
  }, []);

  const getCreateTaxPaymentBulkAction = useGetCreateTaxPaymentBulkAction();

  const getBulkActions = useCallback(
    (args) => {
      const actions: ActionDeclaration[] = [];

      const taxSummariesWithTaxWithHeldAndTaxDue = args.selectedItems.filter(
        (taxSummary: TaxSummary) =>
          !!(taxSummary.tax_currently_withheld && taxSummary.tax_due)
      );

      // NOTE: We only want to show this action if there are any tax summaries with tax withheld greater or equal to tax due.
      // This is because if we don't have any tax withheld, we can't issue a payment.
      if (taxSummariesWithTaxWithHeldAndTaxDue.length) {
        actions.push(
          getCreateTaxPaymentBulkAction({
            ...args,
            selectedItems: taxSummariesWithTaxWithHeldAndTaxDue
          })
        );
      }
      return actions;
    },

    [getCreateTaxPaymentBulkAction]
  );

  const tabs = useMemo<Tab[]>(() => {
    const globalFilters = [
      {
        field: 'type',
        op: 'eq',
        value: 'quarterly'
      },
      {
        field: 'start_date',
        op: 'gte',
        value: quarterStartDate
      },
      {
        field: 'end_date',
        op: 'lte',
        value: quarterEndDate
      }
    ];

    const commonTabProps: Tab = {
      Table: ListTable,
      getQuery: getTaxSummariesQuery,
      getActionMenuItems,
      getBulkActions
    };

    return [
      {
        ...commonTabProps,
        columns: getColumns('not_issued'),
        id: 'payments-to-hmrc-not-issued',
        name: 'payments-to-hmrc-not-issued',
        label: 'Not issued',
        forcedGlobalFilter: [
          ...globalFilters,
          {
            field: 'tax_due',
            op: 'gte',
            value: 1
          }
        ]
      },
      {
        ...commonTabProps,
        columns: getColumns('shortfall'),
        id: 'payments-to-hmrc-shortfall',
        name: 'payments-to-hmrc-shortfall',
        label: 'Shortfall',
        forcedGlobalFilter: [
          ...globalFilters,
          {
            field: 'shortfall',
            op: 'eq',
            value: 'true'
          }
        ]
      },
      {
        ...commonTabProps,
        columns: getColumns('issued'),
        id: 'payments-to-hmrc-issued',
        name: 'payments-to-hmrc-issued',
        label: 'Issued',
        forcedGlobalFilter: [
          ...globalFilters,
          {
            field: 'tax_paid',
            op: 'gte',
            value: 1
          }
        ]
      }
    ];
  }, [
    quarterStartDate,
    quarterEndDate,
    getTaxSummariesQuery,
    getActionMenuItems,
    getBulkActions
  ]);

  return <TabbedTable tabs={tabs} />;
}
