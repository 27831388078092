import { makeIconComponent } from '@rexlabs/icons';

import SmileyGoodSmall from 'src/assets/icons/s/smiley-good.svg';
import SmileyGoodMedium from 'src/assets/icons/m/smiley-good.svg';

export const SmileyGoodIcon = makeIconComponent({
  s: SmileyGoodSmall,
  m: SmileyGoodMedium
});

export default SmileyGoodIcon;
