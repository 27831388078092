import { useSettings } from 'src/modules/settings/hooks/useSettings';
import { AlfredFile } from 'src/modules/common/types/file';

import { Contact, Tax } from '../types/contact-types';

export function useGetMapContactFormToData() {
  const getTaxRate = useGetTaxRate();

  return async (values: Contact, changedValues: Partial<Contact>) => {
    const data = { ...changedValues };

    if ('disbursement_payment_methods' in changedValues) {
      data.disbursement_payment_methods = values.disbursement_payment_methods;
    }

    if ('emails' in changedValues) {
      data.emails = values.emails;
    }

    if ('phones' in changedValues) {
      data.phones = values.phones;
    }

    if ('addresses' in changedValues) {
      data.addresses = values?.addresses?.map((address) => {
        return { ...address, is_primary: address.is_primary ? true : false };
      });
    }

    if ('tax' in changedValues) {
      data.tax = {
        // We need to include these fields anytime we change the tax object.
        non_resident: values?.tax?.non_resident,
        non_resident_withhold_tax: values?.tax?.non_resident_withhold_tax,
        ...(changedValues.tax || {})
      };

      if ('non_resident_tax_rate' in data.tax) {
        const newTaxRate = data.tax.non_resident_tax_rate;

        data.tax = {
          ...values.tax,
          non_resident_tax_rate: getTaxRate(newTaxRate)
        };
      }
    }

    if ('logo' in changedValues) {
      const logoData: { data: AlfredFile } = await Promise.resolve(
        values?.logo?.['data']
      );
      data.logo = {
        file: {
          id: logoData.data.id
        }
      } as any;
    }
    return data;
  };
}

function useGetTaxRate() {
  const { 'non-resident-tax-rate': defaultTaxRate } = useSettings();

  return (taxRate: Tax['non_resident_tax_rate']) => {
    if (!taxRate) {
      return taxRate;
    }

    const newTaxRate = taxRate / 100;

    if (newTaxRate === defaultTaxRate) {
      return null;
    }

    return newTaxRate;
  };
}
