import { useRecordsQuery } from 'src/lib/react-query/hooks/use-records-query';
import { WorkOrderTask } from '../../work-orders/types/WorkOrderTask';
import { Task } from '../types/Task';
import { TaskType } from '../types/TaskType';

export function useChildrenTasksOfParentTaskQuery(
  parentTask?: Task<any> | null,
  childTaskType?: TaskType
) {
  return useRecordsQuery<WorkOrderTask>(
    [
      'tasks',
      parentTask && childTaskType
        ? {
            filter: [
              { field: 'type_id', op: 'eq', value: childTaskType },
              {
                field: 'parent_task_id',
                op: 'eq',
                value: parentTask?.id
              }
            ]
          }
        : {}
    ],
    { enabled: !!parentTask && !!childTaskType }
  );
}
