import { OutlineIconButton } from '@rexlabs/button';

import React, { useCallback } from 'react';

import Popout from '@rexlabs/popout';
import { StylesProvider } from '@rexlabs/styling';

import { MoveFundsIcon } from 'view/components/icons/move-funds';

import {
  TransferBucketsPopoutForm,
  TransferBucketsPopoutFormValues
} from 'src/modules/trust-journal-entries/popouts/transfer-buckets-popout-form';
import { TrustLedger } from 'src/modules/trust-ledgers/types/trust-ledger-types';
import { prepaymentBucketsModel } from 'src/modules/prepayments/models/prepayment-buckets-model';
import { useModelActions } from '@rexlabs/model-generator';
import { useErrorDialog } from '@rexlabs/dialog';
import { mapMoveBucketsFormToApi } from 'src/modules/trust-journal-entries/mappers/move-buckets-form-to-api';
import { Tenancy } from 'data/models/entities/tenancies';
import { BankAccount } from 'data/models/entities/financials/bank-accounts';
import { useQueryClient } from 'react-query';
import { TenancyPrepaymentBucket } from 'src/modules/prepayments/types/tenancy-prepayment-bucket';

const overrideTokens = {
  popout: {
    content: {
      overflow: 'visible'
    }
  }
};

interface TransferBucketsPopoutProps {
  bucketToTransferTo?: TenancyPrepaymentBucket;
  bucketToTransferFrom?: TenancyPrepaymentBucket;
  bankAccount?: BankAccount;
  tenancy?: Tenancy;
  ledger?: TrustLedger;
}

export function TransferBucketsPopout({
  bucketToTransferTo,
  bucketToTransferFrom,
  ledger,
  bankAccount,
  tenancy
}: TransferBucketsPopoutProps) {
  const { open: openErrorDialog } = useErrorDialog();
  const queryClient = useQueryClient();
  const actions = useModelActions(prepaymentBucketsModel);

  const handleSubmit = useCallback(
    async (values: TransferBucketsPopoutFormValues) => {
      if (!ledger) {
        return;
      }
      const payload = mapMoveBucketsFormToApi({
        values,
        ledger
      });

      try {
        await actions.moveFunds(payload);
        await queryClient.invalidateQueries({
          queryKey: ['prepayment-bucket']
        });
        return true;
      } catch (e) {
        openErrorDialog(e);
      }
    },
    [ledger]
  );

  const PopoutContent = useCallback(
    ({ toggle }) => (
      <TransferBucketsPopoutForm
        onSubmit={handleSubmit}
        onClose={toggle}
        bankAccount={bankAccount}
        tenancy={tenancy}
        bucketToTransferTo={bucketToTransferTo}
        bucketToTransferFrom={bucketToTransferFrom}
      />
    ),
    [
      handleSubmit,
      bankAccount,
      tenancy,
      bucketToTransferTo,
      bucketToTransferFrom
    ]
  );

  return (
    // Content overflows the space given to it, so I'm ensuring it doesn't scroll
    <StylesProvider tokens={overrideTokens}>
      <div>
        <Popout
          Content={PopoutContent}
          placement='auto'
          // note distance is a number, whereas our spacing tokens are strings (i.e. '1.6rem')
          distance={16}
        >
          <OutlineIconButton
            Icon={MoveFundsIcon}
            aria-label={'Transfer funds from buckets'}
            isDisabled={
              (!bucketToTransferTo && !bucketToTransferFrom) || !ledger
            }
          />
        </Popout>
      </div>
    </StylesProvider>
  );
}
