import React from 'react';
import { useDialog } from '@rexlabs/dialog';

import EmptyTable from 'assets/illustrations/empty-table.svg';

import { EmptyCard } from 'view/components/record-screen/cards/empty-card';
import { Message } from 'view/components/@luna/message';

import { SupplierRenewEntryDialog } from 'src/modules/compliance/common/dialogs/supplier-renew-entry-dialog';
import { SupplierComplianceTask } from 'src/modules/tasks/supplier-compliance/types/SupplierComplianceTask';
import { useRenewFromTaskToast } from 'src/modules/compliance/common/hooks/use-renew-from-task-toast';
import { useModelActions } from '@rexlabs/model-generator';
import {
  sharedIncludes,
  taskIncludes,
  tasksModel
} from 'src/modules/tasks/common/models/tasks-model';

interface SupplierComplianceEntryTableEmptyStateProps {
  task?: SupplierComplianceTask;
}

export function SupplierComplianceEntryTableEmptyState({
  task
}: SupplierComplianceEntryTableEmptyStateProps) {
  const { open: openSupplierRenewEntryDialog } = useDialog(
    SupplierRenewEntryDialog
  );

  const complianceType =
    task?.details?.compliance_type ||
    task?.details?.compliance_entry?.compliance_type;
  const complianceTypeLabel = complianceType?.label;
  const complianceTypeCategoryLabel = complianceType?.category?.label;
  const addToast = useRenewFromTaskToast();
  const actions = useModelActions(tasksModel);
  return (
    <EmptyCard hasGreyBackground={false}>
      <Message
        title={complianceTypeLabel}
        Illustration={EmptyTable}
        actions={[
          {
            label: `Add ${complianceTypeCategoryLabel?.toLowerCase()}`,
            intent: 'primary',
            handleAction: () =>
              openSupplierRenewEntryDialog({
                onSave: (data) => {
                  addToast(data);
                  actions.refreshItem({
                    id: task?.id,
                    args: {
                      include: [
                        taskIncludes.supplierComplianceDetails,
                        ...sharedIncludes
                      ].join(',')
                    }
                  });
                },
                contactId:
                  task?.details?.supplier?.id ||
                  task?.details?.compliance_entry?.supplier?.id,
                complianceType
              })
          }
        ]}
      >
        No {complianceTypeLabel} added for this supplier.
      </Message>
    </EmptyCard>
  );
}
