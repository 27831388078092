import { useConfirmationDialog, useErrorDialog } from '@rexlabs/dialog';
import { PropertyArea } from 'src/modules/property-areas/property-areas/types/PropertyArea';
import { ActionMenuItem } from '@rexlabs/action-menu';
import Box from '@rexlabs/box';
import React from 'react';
import { api } from 'utils/api/api-client';
import { useToast } from 'view/components/@luna/notifications/toast';
import { useQueryClient } from 'react-query';
import { useModelActions } from '@rexlabs/model-generator';
import { tasksModel } from '../../common/models/tasks-model';

export function useGetDeleteInspectionAreaAction() {
  const { open } = useConfirmationDialog();
  const { addToast } = useToast();
  const queryClient = useQueryClient();
  const errorDialog = useErrorDialog();
  const { refreshItem } = useModelActions(tasksModel);

  return (
    propertyArea: PropertyArea,
    taskId: string,
    areaOrder: Array<string>,
    updateAreaOrder: (newOrder: Array<string>) => void
  ): ActionMenuItem => {
    return {
      label: 'Delete',
      intent: 'danger',
      onClick: async () => {
        return open({
          title: 'Remove area',
          cancelText: 'No, keep this area',
          confirmText: 'Yes, remove area from property',
          message: (
            <Box flexDirection='column' gap={16}>
              <div>
                Please note that by proceeding, any report data created for this
                area can no longer be imported from previous inspection reports.
              </div>
              <div>Do you want to continue?</div>
            </Box>
          ),
          async onConfirm() {
            try {
              const newAreaOrder = areaOrder.filter(
                (areaId) => areaId != propertyArea.id
              );

              const response = await api.post('request-pipelines', {
                requests: [
                  {
                    id: `areas_delete`,
                    method: 'DELETE',
                    path: `/api/v1/inspection-areas/${propertyArea.id}`
                  },
                  {
                    id: 'area_order',
                    method: 'PATCH',
                    path: `/api/v1/tasks/inspection-reports/${taskId}`,
                    json: {
                      area_order: newAreaOrder
                    }
                  }
                ]
              });

              updateAreaOrder(newAreaOrder);

              queryClient.invalidateQueries(['task', taskId]);
              refreshItem({ id: taskId });

              addToast({
                description: `${propertyArea.name} deleted successfully.`,
                type: 'error'
              });

              return response;
            } catch (err) {
              errorDialog.open(err);
            }
          }
        });
      }
    };
  };
}
