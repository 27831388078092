import React from 'react';
import dayjs from 'dayjs';
import { min } from 'lodash';

import { useModelActions } from '@rexlabs/model-generator';
import { useErrorDialog } from '@rexlabs/dialog';
import { OutlineButton } from '@rexlabs/button';
import { push } from '@rexlabs/whereabouts';

import ROUTES from 'routes/app';

import { useToast } from 'view/components/@luna/notifications/toast';

import { valueListFactory } from 'src/lib/testing/factories/value-list-factory';
import {
  TAX_PAYMENT_ACCOUNT_DETAILS_SETTING_KEY,
  useSettings
} from 'src/modules/settings/hooks/useSettings';

import { getTrustLedger } from 'utils/api/get-trust-ledger';
import { pluralize } from 'utils/formatters';

import { ownershipTaxTransactionsModel } from '../models/ownership-tax-transactions-model';
import { TaxSummary, taxSummariesModel } from '../models/tax-summaries-model';
import { OwnershipTaxTransactionCreate } from '../types/ownership-tax-transaction-create';
import { getFinancialYearAbbreviationString } from '../utils/get-financial-year-abbreviation-string';
import { getQuarterNumberFromQuarterStart } from '../utils/get-quarter-number-from-quarter-start';
import { useGetFinancialYearStartAndEnd } from './use-get-financial-year-start-and-end';

export function useGetCreateTaxPaymentSubmitHandler() {
  const { createItem } = useModelActions(ownershipTaxTransactionsModel);
  const { refreshLists } = useModelActions(taxSummariesModel);
  const { open } = useErrorDialog();
  const { addToast } = useToast();
  const settings = useSettings();
  const getFinancialYearStartAndEnd = useGetFinancialYearStartAndEnd();

  return (taxSummaries: TaxSummary[]) => async () => {
    const {
      startDate: financialYearStartDate,
      endDate: financialYearEndDate
    } = getFinancialYearStartAndEnd(taxSummaries[0].start_date);

    const currentQuarter = getQuarterNumberFromQuarterStart(
      financialYearStartDate,
      taxSummaries[0].start_date
    );

    try {
      await Promise.all(
        taxSummaries.map(async (taxSummary) => {
          const trustAccount = await getTrustLedger(
            taxSummary.ownership,
            taxSummary.ownership.operating_bank_account!.id
          );

          const withDrawalAccountDetails =
            settings[TAX_PAYMENT_ACCOUNT_DETAILS_SETTING_KEY];

          if (!withDrawalAccountDetails.payee_id) {
            throw new Error(
              'You currently have no payee set up for tax payments. Please update your account settings to proceed.'
            );
          }

          const description = `Tax payment for NRLQ ${currentQuarter} ${getFinancialYearAbbreviationString(
            financialYearStartDate,
            financialYearEndDate
          )}`;

          // We should only pay the lesser of the two amounts, as paying more than withheld will throw an error,
          // and paying more than due will result in an overpayment.
          const amount = min([
            taxSummary.tax_currently_withheld,
            taxSummary.tax_due
          ])!;

          const payloadData: OwnershipTaxTransactionCreate = {
            amount,
            contact: taxSummary.contact,
            type: valueListFactory('tax_paid'),
            date_of: dayjs().format('YYYY-MM-DD'),
            description,
            ownership: taxSummary.ownership,
            withdrawal: {
              contact: { id: withDrawalAccountDetails.payee_id },
              amount,
              description,
              bank_account: taxSummary.ownership.operating_bank_account!,
              available_amount:
                trustAccount?.financial_summary?.available_balance_amount || 0,
              date_of: dayjs().format('YYYY-MM-DD'),
              payment_information: {
                account_number: withDrawalAccountDetails.account_number,
                branch_code: withDrawalAccountDetails.branch_code,
                method: valueListFactory(
                  withDrawalAccountDetails.payment_method_id
                ),
                reference: withDrawalAccountDetails.reference,
                drawer: '',
                issuer: null
              },
              source: {
                ledger: {
                  object: {
                    id: taxSummary.ownership!.id,
                    type: valueListFactory('ownership')
                  }
                }
              }
            }
          };

          await createItem({
            data: payloadData
          });
        })
      ).then(() => {
        const toastDescription =
          taxSummaries.length === 1 ? (
            `Issued tax payment for ${taxSummaries[0].contact.display_name}`
          ) : (
            <>
              <b>Payments</b> for{' '}
              <b>{pluralize('contact', taxSummaries.length, true)}</b> issued
              successfully.
            </>
          );

        addToast({
          description: toastDescription,
          type: 'success',
          actions: [
            {
              label: 'View issued payments',
              Button: OutlineButton,
              onClick: () => {
                push(ROUTES.HMRC_REPORTS_AND_SUBMISSIONS_QUARTER_DETAILS, {
                  params: {
                    id: financialYearStartDate,
                    quarterId: currentQuarter
                  },
                  hash: 'tab=payments-to-hmrc-issued'
                });
              }
            }
          ]
        });

        refreshLists();
      });
    } catch (error) {
      open(error);
    }
  };
}
