import { selectorFamily } from 'recoil';
import { push } from '@rexlabs/whereabouts';
import { USER_INVITATION_ROUTES } from 'src/modules/auth/user-invitation/routes';
import { UserInvitation } from 'src/modules/auth/user-invitation/types/user-invitation';
import { api, setBaseUrl } from 'utils/api/api-client';
import ROUTES from 'routes/app';
import { Silo } from 'data/models/custom/session';

const handleTokenErrors = (e: Error, token: string): void => {
  if (e.message === 'Token has already been used') {
    push(ROUTES.LOGIN);
    return;
  }

  if (e.message === 'Token has expired') {
    push(USER_INVITATION_ROUTES.USER_INVITATION_EXPIRED);
    return;
  }

  push(USER_INVITATION_ROUTES.USER_INVITATION_ERROR, {
    query: { token }
  });
};

export const userInviteQuery = selectorFamily({
  key: 'UserName',
  get: (token: string) => async (): Promise<UserInvitation | undefined> => {
    const { data: siloData } =
      (await api
        .get<Silo>(`user-invites/read-token-silo/${token}`)
        .catch((e: Error) => {
          handleTokenErrors(e, token);
        })) || {};

    setBaseUrl(siloData?.base_url);

    const { data } =
      (await api
        .get<UserInvitation>(
          `user-invites/verify-token/${token}?include=silo,user{contact.primary_email},invited_by`
        )
        .catch((e: Error) => {
          handleTokenErrors(e, token);
        })) || {};
    return data;
  }
});
