import React, { useMemo } from 'react';
import { useDialog } from '@rexlabs/dialog';

import { BlockConfig } from 'view/components/record-screen/types';
import { ActionButtons } from 'view/components/@luna/action-buttons';

import { SingleActionDeclaration } from 'src/modules/common/actions/types/action-declaration-types';
import { Property } from 'src/modules/properties/types/property-types';
import { Ownership } from 'data/models/entities/ownerships';
import { Contact } from 'src/modules/contacts/types/contact-types';
import { useRefreshScreen } from 'view/hooks/use-refresh-screen';
import { Trans, useTranslation } from 'react-i18next';
import { PropertyOwnershipsList } from '../connectors/property-ownerships-list';
import { CreatePropertyOwnershipDialog } from '../dialogs/create-property-ownership-dialog';

type GetProps<Data = any> = (options: {
  data: Data;
}) => {
  property?: Property;
  ownership?: Ownership;
  contact?: Contact;
};

export function getPropertyOwnershipsBlock<Data = any>(
  getProps: GetProps<Data>
): BlockConfig {
  return {
    id: 'management-agreements',
    title: () => (
      <Trans i18nKey='property-ownerships.management-agreement.label.plural' />
    ),
    View: ({ data }) => {
      const { property, ownership, contact } = useMemo(
        () => getProps({ data }),
        [data]
      );
      return (
        <PropertyOwnershipsList
          property={property}
          ownership={ownership}
          contact={contact}
        />
      );
    },
    Actions: ({ data }) => {
      const { t } = useTranslation();
      const props = useMemo(() => getProps({ data }), [data]);
      const createPropertyOwnershipDialog = useDialog(
        CreatePropertyOwnershipDialog
      );
      const { refreshScreen } = useRefreshScreen();
      return (
        <ActionButtons
          actions={
            [
              {
                label: t(
                  'property-ownerships.management-agreement.create.label.singular'
                ),
                handleAction: () =>
                  createPropertyOwnershipDialog.open({
                    ...props,
                    onCreate: refreshScreen
                  })
              }
            ] as SingleActionDeclaration[]
          }
        />
      );
    }
  };
}
