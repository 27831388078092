import React, { useLayoutEffect } from 'react';
import { get } from 'lodash';
import Box from '@rexlabs/box';
import { Body } from '@rexlabs/text';
import { Field, FieldArray } from '@rexlabs/form';
import { EmailInput } from '@rexlabs/text-input';
import { OutlineButton } from '@rexlabs/button';
import { Tag, TagGroup } from '@rexlabs/tag';

import { BlockConfig } from 'view/components/record-screen/types';
import { Grid } from 'view/components/@luna/form/grid';
import { Value } from 'view/components/values';
import { ActionButtons } from 'view/components/@luna/action-buttons';
import { AddIcon } from 'view/components/icons/add';
import { EmailTypeSelect } from 'view/components/inputs/selects/email-type';
import { Contact } from 'src/modules/contacts/types/contact-types';
import { ProfileSettingsTooltip } from 'src/modules/contacts/blocks/details';
import { isContactAUser } from 'src/modules/contacts/utils/is-contact-a-user';
import { SingleActionDeclaration } from 'src/modules/common/actions/types/action-declaration-types';

export const emailsBlock: BlockConfig<Contact> = {
  id: 'emails',
  title: 'Email addresses',
  validate: {
    definitions: {
      'emails.*.description': { name: 'email type', rules: 'required' },
      'emails.*.email_address': {
        name: 'email address',
        rules: 'required|email'
      }
    }
  },
  View: ({ data }) => {
    if (!data?.emails?.length) {
      // TODO: replace with empty state
      return (
        <Body grey>
          No email address. You need to provide an email address in order for
          this recipient to receive email messages.
        </Body>
      );
    }

    return (
      <Grid columns={2}>
        {data.emails.map((email, index) => {
          const value = (
            <Box flexDirection='row' sx='1.6rem' alignItems='center'>
              <Box>{email.email_address || '--'}</Box>
              <TagGroup>
                {!!email.is_primary_email && <Tag>Primary</Tag>}
              </TagGroup>
            </Box>
          );
          return <Value key={index} label={email.description} value={value} />;
        })}
      </Grid>
    );
  },
  Edit: ({ data, values, setFieldValue }) => {
    if (isContactAUser(data!)) {
      return (
        <>
          {data?.emails!.map((email) => {
            return (
              <Value
                key={email.id}
                label='Email'
                value={email.email_address}
                HelpTooltipContent={ProfileSettingsTooltip}
              />
            );
          })}
        </>
      );
    }

    return (
      <FieldArray name='emails'>
        {function LineItem({ fields, push }) {
          // We only want to prefill the line item when the user switches
          // from view to edit mode (= on initial render of the edit mode),
          // not when the user manually deletes the last item
          useLayoutEffect(() => {
            if (!fields.length) {
              push({});
            }
          }, []);

          return (
            <Box sy='2.4rem'>
              {!fields?.length && (
                <Body grey>
                  No email address. You need to provide an email address in
                  order for this recipient to receive email messages.
                </Body>
              )}
              {fields.map(({ field, actions }, fieldIndex) => {
                const isPrimary = get(values, `${field.name}.is_primary_email`);

                let rowActions: SingleActionDeclaration[] = [];
                if (!isPrimary) {
                  rowActions = rowActions.concat([
                    {
                      label: 'Set as primary email',
                      handleAction: () => {
                        const newEmails = values.emails.map((item, index) => ({
                          ...item,
                          is_primary_email: index === fieldIndex
                        }));
                        setFieldValue?.('emails', newEmails);
                      }
                    }
                  ]);
                }
                rowActions = rowActions.concat([
                  {
                    label: 'Delete',
                    intent: 'danger',
                    handleAction: () => actions.remove()
                  }
                ]);

                return (
                  <Box
                    key={field.name}
                    flexDirection='row'
                    alignItems='flex-start'
                    sx='2.4rem'
                    data-testid='email'
                  >
                    <Box width='17rem' flexShrink={0}>
                      <Field
                        name={`${field.name}.description`}
                        label='Email type'
                        Input={EmailTypeSelect}
                      />
                    </Box>

                    <Field
                      name={`${field.name}.email_address`}
                      label='Email address'
                      Input={EmailInput}
                      inputProps={{
                        suffix: (
                          <Box sx='.4rem' mr='-.8rem' flexDirection='row'>
                            {isPrimary && <Tag>Primary</Tag>}
                          </Box>
                        )
                      }}
                    />

                    <div>
                      <ActionButtons
                        mt='1.3rem'
                        actions={[{ label: 'Actions', actions: rowActions }]}
                        placement='bottom-end'
                      />
                    </div>
                  </Box>
                );
              })}

              <OutlineButton onClick={() => push({})} IconLeft={AddIcon}>
                Add another email address
              </OutlineButton>
            </Box>
          );
        }}
      </FieldArray>
    );
  }
};
