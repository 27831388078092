import React from 'react';

import { Field } from '@rexlabs/form';
import { TextInput } from '@rexlabs/text-input';

import { BlockConfig } from 'view/components/record-screen/types';
import { AttachableEntitySelect } from 'view/components/inputs/selects/v4-selects/attachable-entity-select';
import { Grid } from 'view/components/@luna/form/grid';
import { DocumentTypeSelect } from 'view/components/inputs/selects/document-type';

import { PreviewFileUploadInputWithErrorHandling } from 'view/components/inputs/file-upload-input/preview-file-upload-input-with-error-handling';

const validate = {
  definitions: {
    file: { rules: 'required' },
    name: { rules: 'required' },
    attached_to: { rules: 'required' }
  }
};

export const documentDetailsBlock: BlockConfig = {
  id: 'document-details',
  validate,
  Edit: ({ setFieldValue, values }) => {
    return (
      <Grid columns={1}>
        <Field<typeof PreviewFileUploadInputWithErrorHandling>
          id='file'
          name='file'
          Input={PreviewFileUploadInputWithErrorHandling}
          inputProps={{
            onFileSelectCallback: (file) => {
              if (!values.name) {
                setFieldValue?.('name', file?.name || '');
              }
            }
          }}
          optional={false}
        />
        <Grid columns={2}>
          <Field name='name' label='Name' Input={TextInput} optional={false} />
          <Field
            name='type'
            label='Document type'
            Input={DocumentTypeSelect}
            inputProps={{
              recordType: values?.record_type
            }}
          />
        </Grid>
        <Field
          name='attached_to'
          label='Attached to'
          Input={AttachableEntitySelect}
          optional={false}
        />
      </Grid>
    );
  }
};
