import {
  ErrorBanner,
  SuccessBanner,
  WarningBanner
} from '@rexlabs/notifications';
import WarningCircleIcon from 'view/components/icons/warning-circle';
import dayjs from 'dayjs';
import { formatDate } from 'utils/dates/format';
import CheckCircleIcon from 'view/components/icons/check-circle';
import React from 'react';

export function WarrantyBanner({
  warrantyExpiryDate
}: {
  warrantyExpiryDate: string | null;
}) {
  if (!warrantyExpiryDate) {
    return (
      <WarningBanner
        Icon={WarningCircleIcon}
        data-testid={'appliance-warranty-warning-banner'}
      >
        There is no information regarding warranty details for this appliance.
      </WarningBanner>
    );
  }
  if (dayjs(warrantyExpiryDate).isBefore(dayjs())) {
    return (
      <ErrorBanner
        Icon={WarningCircleIcon}
        data-testid={'appliance-warranty-error-banner'}
      >
        This appliance is not under warranty. Expired{' '}
        <strong>{formatDate(warrantyExpiryDate)}</strong>
      </ErrorBanner>
    );
  }

  return (
    <SuccessBanner
      Icon={CheckCircleIcon}
      data-testid={'appliance-warranty-success-banner'}
    >
      This appliance is under warranty. Expires{' '}
      <strong>{formatDate(warrantyExpiryDate)}</strong>
    </SuccessBanner>
  );
}
