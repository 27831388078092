import { PrepaymentBucketForm } from 'src/modules/prepayments/types/prepayment-bucket-form';
import { PrepaymentBucketRequest } from 'src/modules/prepayments/types/prepayment-bucket-request';

export function mapCreatePrepaymentBucketFormToApi(
  values: PrepaymentBucketForm
): PrepaymentBucketRequest {
  return {
    ...values,
    auto_allocate_funds: values.auto_allocate_funds ?? false,
    chart_of_accounts_accounts: values.chart_of_accounts_accounts.map(
      (chart_of_accounts_account) => ({ id: chart_of_accounts_account.id })
    )
  };
}
