import { ContentConfig } from 'view/components/record-screen/types';
import { FLAGS } from 'utils/feature-flags';
import { noteTableBlock } from 'src/modules/note/blocks/note-table-block';
import { documentsBlock } from 'src/modules/tasks/common/blocks/documents';
import { linkedTasksBlock } from 'src/modules/tasks/common/blocks/linked-tasks-block';
import { taskPriorityBlock } from 'src/modules/tasks/common/blocks/task-priority-block';
import { arrearsDetailsBlock } from 'src/modules/tasks/arrears/blocks/arrears-details-block';
import { arrearsDateBlock } from 'src/modules/tasks/arrears/blocks/arrears-date-block';
import { tenantPaymentHistoryBlock } from 'src/modules/tasks/arrears/blocks/tenant-payment-history-block';
import { checklistsBlock } from '../../common/blocks/create-checklists-block';

export const content: ContentConfig = [
  {
    label: 'General',
    items: [
      {
        id: 'overview',
        label: 'Overview',
        blocks: [arrearsDetailsBlock, taskPriorityBlock, arrearsDateBlock]
      },
      {
        id: 'notes',
        label: 'Notes',
        blocks: [noteTableBlock]
      },
      {
        id: 'documents',
        label: 'Documents',
        blocks: [documentsBlock]
      },
      {
        id: 'checklist',
        label: 'Checklist',
        blocks: [checklistsBlock],
        flag: FLAGS.CHECKLIST_TEMPLATES
      }
    ]
  },
  {
    label: 'Manage',
    items: [
      {
        id: 'payment-history',
        label: 'Tenant payment history',
        blocks: [tenantPaymentHistoryBlock],
        flag: FLAGS.PAYMENT_HISTORY
      },
      {
        id: 'linked-tasks',
        label: 'Linked tasks',
        blocks: [linkedTasksBlock],
        flag: FLAGS.LINKED_TASKS
      }
    ]
  }
];
