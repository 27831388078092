import { Columns } from 'view/components/table';
import { SecurityDepositRequest } from 'data/models/entities/financials/security-deposit-requests';
import { TagCell } from '@rexlabs/table';
import { InvoiceStatusTag } from 'view/components/tags/invoice-status';
import React from 'react';

export const securityDepositRequestColumns: Columns<SecurityDepositRequest> = [
  {
    id: 'created_at',
    Header: 'Date requested',
    type: 'date'
  },
  {
    id: 'type',
    Header: 'Type',
    type: 'value'
  },
  {
    id: 'lodgement_reference',
    Header: 'Lodgement number'
  },
  {
    id: 'invoice.record_reference',
    Header: 'Invoice number'
  },
  {
    id: 'invoice.amount_total_inc_tax',
    Header: 'Amount requested',
    type: 'currency'
  },
  {
    id: 'invoice.status',
    Header: 'Status',
    Cell: (table) => {
      const {
        cell: { value }
      } = table;
      return (
        <TagCell>
          <InvoiceStatusTag status={value} />
        </TagCell>
      );
    }
  }
];
