import * as React from 'react';

import { Select, useItems } from '@rexlabs/select';

import LocationMarkerIcon from 'view/components/icons/location-marker';
import { fetchAddressFromString } from 'src/lib/mapbox/utils/fetch-address-from-string';

// NOTE: As we're using an external API, we need to throttle the requests to avoid
// being billed for excessive requests. Default debounce is 200ms, which triggers
// way too much unless you're a super fast typist.
const DELAY_BEFORE_SEARCH = 1000;

export function AddressSelect(props) {
  const { getSelectProps } = useItems({
    debounce: DELAY_BEFORE_SEARCH,
    getItems: React.useCallback(fetchAddressFromString, []),
    getSuggestedItems: React.useCallback(async () => {
      return props.staticItems ?? [];
    }, [props.staticItems])
  });

  return (
    <Select
      ValuePrefix={LocationMarkerIcon}
      {...props}
      {...getSelectProps()}
      normaliser={(item) => item}
    />
  );
}
