import React from 'react';

import { TagGroup, Tag } from '@rexlabs/tag';
import { Body } from '@rexlabs/text';
import { Field } from '@rexlabs/form';

import { BlockConfig } from 'view/components/record-screen/types';
import { PropertyFeaturesSelect } from 'view/components/inputs/selects/property-features';
import { Grid } from 'view/components/@luna/form/grid';

export const marketingFeaturesBlock: BlockConfig = {
  id: 'marketing-features',
  title: 'Features',
  workInProgress: true,
  View: ({ data }) => {
    return data?.features?.length ? (
      <TagGroup>
        {data?.features?.map(({ id, label }) => (
          <Tag key={id}>{label}</Tag>
        ))}
      </TagGroup>
    ) : (
      <Body>No features added</Body>
    );
  },
  Edit: () => (
    <Grid columns={1}>
      <Field
        name='features'
        Input={PropertyFeaturesSelect}
        inputProps={{ multi: true }}
      />
    </Grid>
  )
};
