import React, { useCallback } from 'react';
import { useDialog } from '@rexlabs/dialog';

import { ComplianceType } from 'src/modules/compliance/common/models/compliance-types';

import { RecordTable } from 'view/components/table';

import { complianceRequirementSettingsColumns } from 'src/modules/compliance/common/utils/compliance-requirement-settings-columns';
import { getComplianceTypesQuery } from '../../common/compliance-entries/data/queries';
import { EditPropertyComplianceRequirementDialog } from '../dialogs/edit-property-compliance-requirement-dialog';

import { PropertyComplianceRequirementTableEmptyState } from './property-compliance-requirement-table-empty-state';

const filter = [
  {
    field: 'applicable_to_object_type_id',
    op: '==',
    value: 'property'
  }
];

export function PropertyComplianceRequirementsSettingsTable() {
  const { open: openEditComplianceRequirementDialog } = useDialog(
    EditPropertyComplianceRequirementDialog
  );

  const getQuery = useCallback(() => getComplianceTypesQuery(filter), []);

  return (
    <RecordTable
      id='property-compliance-requirements'
      columns={complianceRequirementSettingsColumns}
      getQuery={getQuery}
      Empty={PropertyComplianceRequirementTableEmptyState}
      emptyWithoutTable
      withPagination={true}
      getActionMenuItems={({
        item
      }: {
        item: ComplianceType;
        entity?: any;
      }) => {
        return [
          {
            label: 'Edit',
            onClick: () =>
              openEditComplianceRequirementDialog({
                complianceRequirementId: item.id
              })
          }
          // The delete action has been removed since the requirement is generally connected to categories and compliance entries
          // If the user requires it we can add an archive or soft delete but in the interests of
          // not solving problems that don't exist, we'll leave it out for now.
          // note that I'm removing the related code, check the git/PR history if you need it
        ];
      }}
    />
  );
}
