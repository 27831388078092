import React from 'react';

import { ListSummaryCard } from 'view/components/cards/list-summary-card';

type TaxOwedSummaryProps = {
  taxOwedAsANonResidentLandlord: number;
  taxPaidToHmrc: number;
  totalTaxWithheld: number;
  adjustments: number;
};

export const TaxOwedSummary = ({
  taxOwedAsANonResidentLandlord,
  taxPaidToHmrc,
  totalTaxWithheld,
  adjustments
}: TaxOwedSummaryProps) => (
  <ListSummaryCard
    title='tax owed'
    cardColor='neutral'
    data-testid='tax-owed-summary'
    items={[
      {
        label: 'Tax owed as a non-resident landlord',
        amount: taxOwedAsANonResidentLandlord,
        type: 'inbound',
        testId: 'tax-owed-as-a-non-resident-landlord'
      },
      {
        label: 'Tax paid to HMRC',
        amount: taxPaidToHmrc,
        type: 'outbound',
        testId: 'tax-paid-to-hmrc'
      },
      {
        label: 'Tax currently being withheld',
        amount: totalTaxWithheld,
        type: 'outbound',
        testId: 'total-tax-withheld'
      },
      {
        label: 'Adjustments',
        amount: adjustments,
        type: adjustments >= 0 ? 'inbound' : 'outbound',
        testId: 'adjustments'
      },
      {
        label: 'Balance',
        amount:
          taxOwedAsANonResidentLandlord -
          taxPaidToHmrc -
          totalTaxWithheld +
          adjustments,
        type: 'total',
        testId: 'balance'
      }
    ]}
  />
);
