import React, { useCallback } from 'react';

import { useModelActions } from '@rexlabs/model-generator';
import { DialogProps } from '@rexlabs/dialog';

import {
  AttachedToType,
  CreateDocument,
  documentsModel
} from 'data/models/entities/documents';
import { RecordType } from 'data/models/types';

import { RecordSubmitHandler } from 'view/components/record-screen/utils';
import { useToast } from 'view/components/@luna/notifications/toast';
import { RecordDialog } from 'view/components/record-screen/dialog/dialog';

import { filterNullValues } from 'utils/filter-null-values';

import { documentDetailsBlock } from '../blocks/document-details';

export interface CreateDocumentDialogProps extends DialogProps {
  initialValues: InitialValues;
}

const content = [
  {
    id: 'add-document',
    label: 'Add document',
    blocks: [documentDetailsBlock]
  }
];

export type AttachedTo = {
  id: string;
  type: {
    id: AttachedToType;
  };
  label: string;
};

type InitialValues = {
  name?: string;
  attached_to: AttachedTo[];
  record_type: RecordType;
};

export function CreateDocumentDialog(dialogProps: CreateDocumentDialogProps) {
  const { onClose, initialValues } = dialogProps;

  const { addToast } = useToast();

  const { createItem, refreshLists } = useModelActions(documentsModel);

  const handleSubmit: RecordSubmitHandler = useCallback(async ({ values }) => {
    const uploadedFileRequest = await values.file.data;
    const uploadedFileId = uploadedFileRequest.data.id;

    let attached_to: CreateDocument['attached_to'] = [];
    if (values.attached_to && values.attached_to.length) {
      attached_to = values.attached_to.map((attachedTo) => ({
        id: attachedTo.id,
        type: attachedTo.type
      }));
    }

    const { data } = await createItem({
      data: filterNullValues({
        ...values,
        file: { id: uploadedFileId },
        attached_to
      })
    });

    addToast({ description: `Added Document - ${data.name}` });
    await refreshLists();
    return data;
  }, []);

  return (
    <RecordDialog
      title='Add document'
      onClose={onClose}
      data={initialValues}
      handleSubmit={handleSubmit}
      content={content}
    />
  );
}
