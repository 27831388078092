import { valueListFactory } from 'src/lib/testing/factories/value-list-factory';
import { mapTaskFormDataToTaskCreateRequest } from '../../common/mappers/map-task-form-data-to-task-create-request';
import { TaskCreateRequest } from '../../common/types/TaskCreateRequest';
import { MoveOutFormData } from '../types/MoveOutFormData';

export function mapMoveOutFormDataToTaskCreateRequest(
  formData: Partial<MoveOutFormData>
): Partial<TaskCreateRequest> {
  // map the base values for the task
  const base = mapTaskFormDataToTaskCreateRequest(formData);

  const details = formData.details ?? {};

  const blacklist = ['move_out_date'];

  // Remove any null values
  Object.keys(details).forEach((key) => {
    if (details[key] === null && !blacklist.includes(key)) {
      delete details[key];
    }
  });

  return {
    ...base,
    type: valueListFactory('task_move_out'),
    details
  };
}
