import React from 'react';

import { TitleBlock } from 'view/components/cards/title/title-block';

import { RecordObject, RecordType } from 'data/models/types';

import { getRecordTitle } from 'utils/records/get-record-title';
import {
  BankWithdrawal,
  BankWithdrawalStatus
} from 'src/modules/banking/bank-withdrawals/types/bank-withdrawal-types';
import { TitleBlockProps } from 'view/components/@luna/title-block';
import { titleBlockStatusTag } from 'src/modules/common/components/status-tag';
import { bankWithdrawalStatusMap } from 'src/modules/banking/bank-withdrawals/models/bank-withdrawal-model';
import { useBankWithdrawalActions } from 'src/modules/banking/bank-withdrawals/actions/use-bank-withdrawal-actions';
import { useSettings } from 'src/modules/settings/hooks/useSettings';
import { ConsolidateWithdrawalsInfoBanner } from 'src/modules/settings/account-settings/components/consolidated-withdrawals-info-banner';

const type: RecordType = 'bank_withdrawal';

type BankWithdrawalTitleBlockProps = {
  bankWithdrawal: BankWithdrawal;
};

export function BankWithdrawalTitleBlock({
  bankWithdrawal
}: BankWithdrawalTitleBlockProps) {
  const { status } = bankWithdrawal;
  const actions = useBankWithdrawalActions();
  const settings = useSettings();

  const title = getRecordTitle({
    type,
    object: bankWithdrawal
  } as RecordObject);

  const tags: TitleBlockProps['tags'] = [];
  tags.push(
    titleBlockStatusTag<BankWithdrawalStatus>(status, bankWithdrawalStatusMap)
  );

  return (
    <TitleBlock
      title={title}
      type={type}
      actions={actions(bankWithdrawal)}
      reference={bankWithdrawal.record_reference}
      tags={tags}
      banner={
        <ConsolidateWithdrawalsInfoBanner
          enabled={settings['consolidate-withdrawals-on-payment-files']}
          showExampleByDefault={false}
        />
      }
    />
  );
}
