import { useWhereabouts } from '@rexlabs/whereabouts';

export const PERSIST_FILTERS_QUERY = 'persist-filters';

export function useListTablePersistFilters({
  shouldPersistTableSettings
}: {
  shouldPersistTableSettings: boolean;
}) {
  const whereabouts = useWhereabouts();

  let persistTableSettings = shouldPersistTableSettings;

  try {
    const persistFilter = JSON.parse(
      whereabouts.query?.[PERSIST_FILTERS_QUERY]
    );

    if (persistFilter != null) {
      persistTableSettings = persistFilter;
    }
  } catch (err) {
    // ignore err
  }

  return persistTableSettings;
}
