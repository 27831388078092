import { Field, FormActions, HiddenField } from '@rexlabs/form';
import { get } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';

import { Grid } from 'view/components/@luna/form/grid';
import { CentAmountInput } from 'view/components/inputs/cent-amount';
import { Value } from 'view/components/values';

import { Property } from 'src/modules/properties/types/property-types';
import {
  RentPosition,
  RentScheduleEntry
} from 'src/modules/property-tenancies/types/property-tenancy-types';
import { useSettingsQuery } from 'src/modules/settings/hooks/useSettingsQuery';
import { getFormattedRentPaidToDescription } from 'src/modules/property-tenancies/utils/get-formatted-rent-paid-to-description';
import { BaseLineItemWithAllocateProps } from '../blocks/trust-journal-entry-line-items';
import { getRentPerPeriodString } from '../utils/get-rent-per-period-string';
import { AllocateFundsToggleButton } from './allocate-funds-toggle-button';
import { ReceiptFundsLineItemContainer } from './receipt-funds-line-item-container';

export interface PropertyTenancyRentPosition {
  // calculated
  calculated_paid_to_date: null | string;
  calculated_paid_to_date_surplus: null | number;
  calculated_fully_paid_period_start_date: null | string;
  calculated_fully_paid_period_end_date: null | string;
  calculated_fully_paid_period_surplus: null | number;
  // current
  current_paid_to_date: null | string;
  current_paid_to_date_surplus: null | number;
  current_fully_paid_period_start_date: null | string;
  current_fully_paid_period_end_date: null | string;
  current_fully_paid_period_surplus: null | number;
}

interface RentLineItemProps extends BaseLineItemWithAllocateProps {
  setFieldValue?: FormActions['setFieldValue'];
  fetchRentPosition: (args: {
    id: string;
    supposedPaymentAmount: number;
  }) => Promise<{ data: PropertyTenancyRentPosition }>;
}

export function RentLineItem({
  fieldName,
  onRemove,
  values,
  fetchRentPosition,
  allocateToLineItem,
  unAllocateFromLineItem,
  showUnallocateFunds: canShowUnallocateFunds,
  showAllocateFunds: canShowAllocateFunds
}: RentLineItemProps) {
  const { data: settingsData } = useSettingsQuery();

  const [currentRentPosition, setCurrentRentPosition] = useState<
    Partial<RentPosition>
  >({
    paid_to_date: null,
    paid_to_date_surplus: null,
    fully_paid_period_start_date: null,
    fully_paid_period_end_date: null,
    fully_paid_period_surplus: null
  });

  const [calculatedRentPosition, setCalculatedRentPosition] = useState<
    Partial<RentPosition>
  >({
    paid_to_date: null,
    paid_to_date_surplus: null,
    fully_paid_period_start_date: null,
    fully_paid_period_end_date: null,
    fully_paid_period_surplus: null
  });

  const paidToDateSetting =
    settingsData?.['rent-paid-to-date']?.id ||
    'effective_and_periodic_with_surplus';

  const amount = get(values, `${fieldName}.amount`);
  const propertyTenancyId = get(
    values,
    `${fieldName}.rent.property_tenancy.id`
  );

  const dateFormat = 'DD/MM/YYYY';
  const currentPaidToValue = getFormattedRentPaidToDescription({
    rentPosition: currentRentPosition,
    paidToDateSetting,
    dateFormat
  });
  const calculatedPaidToValue = getFormattedRentPaidToDescription({
    rentPosition: calculatedRentPosition,
    paidToDateSetting,
    dateFormat
  });

  const property = get(values, `${fieldName}.rent.property`) as Property;

  const rent = get(values, `${fieldName}.rent.rent_amount`);

  const rentSchedules: RentScheduleEntry[] | undefined = get(
    values,
    `${fieldName}.rent.property_tenancy.rent_schedule`
  );

  const currentRentSchedule =
    rentSchedules &&
    rentSchedules.find((schedule) => schedule.status.id === 'current');

  const currentRent = currentRentSchedule?.amount_total_inc_tax;
  const currentFrequency = currentRentSchedule?.frequency;

  const showAllocateFunds = canShowAllocateFunds({
    allocatedToInvoiceAmount: amount,
    invoiceAmount: rent
  });
  const showUnallocateFunds = canShowUnallocateFunds({
    allocatedToInvoiceAmount: amount
  });

  const stablePropertyTenancyId = useMemo(() => propertyTenancyId, [
    propertyTenancyId
  ]);
  const stableAmount = useMemo(() => (amount ? amount : 0), [amount]);

  useEffect(() => {
    if (stablePropertyTenancyId) {
      fetchRentPosition({
        id: stablePropertyTenancyId,
        supposedPaymentAmount: stableAmount
      }).then(({ data }) => {
        const {
          // calculated
          calculated_paid_to_date,
          calculated_paid_to_date_surplus,
          calculated_fully_paid_period_start_date,
          calculated_fully_paid_period_end_date,
          calculated_fully_paid_period_surplus,
          // current
          current_paid_to_date,
          current_paid_to_date_surplus,
          current_fully_paid_period_start_date,
          current_fully_paid_period_end_date,
          current_fully_paid_period_surplus
        } = data;

        setCurrentRentPosition({
          paid_to_date: current_paid_to_date,
          paid_to_date_surplus: current_paid_to_date_surplus,
          fully_paid_period_start_date: current_fully_paid_period_start_date,
          fully_paid_period_end_date: current_fully_paid_period_end_date,
          fully_paid_period_surplus: current_fully_paid_period_surplus
        });
        setCalculatedRentPosition({
          paid_to_date: calculated_paid_to_date,
          paid_to_date_surplus: calculated_paid_to_date_surplus,
          fully_paid_period_start_date: calculated_fully_paid_period_start_date,
          fully_paid_period_end_date: calculated_fully_paid_period_end_date,
          fully_paid_period_surplus: calculated_fully_paid_period_surplus
        });
      });
    }
  }, [stablePropertyTenancyId, stableAmount]);

  return (
    <ReceiptFundsLineItemContainer
      key={fieldName}
      heading='Rent'
      onRemove={onRemove}
    >
      <Grid columns={4}>
        <Value
          label='Rent for property'
          value={
            property?.address?.formatted_strings?.default ||
            property?.address_string
          }
        />
        <Value label='Current paid to date' value={currentPaidToValue} />
        <Value
          label='Calculated paid to date'
          value={calculatedPaidToValue}
          HelpTooltipContent={() => (
            <>Calculated paid to date is based on the amount allocated</>
          )}
          right={
            <div style={{ marginTop: '-.6rem' }}>
              {showUnallocateFunds && (
                <AllocateFundsToggleButton
                  intent='unallocate'
                  onClick={() => {
                    unAllocateFromLineItem(fieldName);
                  }}
                />
              )}
              {showAllocateFunds && (
                <AllocateFundsToggleButton
                  intent='allocate'
                  onClick={() => {
                    allocateToLineItem({
                      lineItemName: fieldName,
                      allocateRemaining: true
                    });
                  }}
                />
              )}
            </div>
          }
        />

        <div style={{ display: 'none' }}>
          <Field
            name={`${fieldName}.category`}
            Input={HiddenField}
            optional={false}
          />
        </div>

        <Field
          id={`${fieldName}.amount`}
          name={`${fieldName}.amount`}
          label='Amount allocated'
          Input={CentAmountInput}
          description={`Rent: ${
            currentRent && currentFrequency
              ? getRentPerPeriodString({
                  rent: currentRent,
                  frequency: currentFrequency
                })
              : '--'
          }`}
          optional={false}
        />
      </Grid>
    </ReceiptFundsLineItemContainer>
  );
}
