import React from 'react';
import { get, pick } from 'lodash';

import { Field, ReactForms } from '@rexlabs/form';
import Box from '@rexlabs/box';
import { Heading } from '@rexlabs/text';
import { StatusAverageTag, StatusBadTag } from '@rexlabs/tag';
import {
  ButtonGroup,
  GhostButton,
  GhostIconButton,
  PrimaryButton
} from '@rexlabs/button';
import { useModelActions } from '@rexlabs/model-generator';
import { useConfirmationDialog } from '@rexlabs/dialog';
import { push } from '@rexlabs/whereabouts';

import { Grid } from 'view/components/@luna/form/grid';
import { BlockConfig } from 'view/components/record-screen/types';
import { DateInput } from 'view/components/@luna/inputs/date-input/date-input';
import { TimeInput } from 'view/components/@luna/inputs/time-input';
import { Value } from 'view/components/values';
import { TimeDurationSelect } from 'view/components/inputs/selects/v4-selects/time-duration-select';
import { DateValue } from 'view/components/values/date';
import { Card, CardContent } from 'view/components/@luna/card';
import EditIcon from 'view/components/icons/edit';
import WarningCircleIcon from 'view/components/icons/warning-circle';

import { pluralize } from 'utils/formatters';

import ROUTES from 'routes/app';

import { tasksModel } from '../../common/models/tasks-model';
import { InspectionTask } from '../types/InspectionTask';
import { mapInspectionFormDataToInspectionCreateRequest } from '../mappers/map-inspection-form-data-to-inspection-create-request';
import { mapInspectionTaskToInspectionFormData } from '../mappers/map-inspection-task-to-inspection-form-data';
import { InspectionFormData } from '../types/InspectionFormData';

export type InspectionDateTimeAndDurationBlockFormValues = Pick<
  InspectionFormData,
  'inspection_date' | 'start_time' | 'duration'
>;

export const inspectionDateTimeAndDurationBlock: BlockConfig<
  InspectionTask,
  InspectionFormData,
  InspectionDateTimeAndDurationBlockFormValues
> = {
  id: 'inspection-date-time-and-duration',
  title: 'Inspection time and duration',
  Card: ({ data }) => {
    const initialValues = pick(
      mapInspectionTaskToInspectionFormData(data),
      'start_time',
      'inspection_date',
      'duration'
    );
    const [isEditMode, setIsEditMode] = React.useState(false);
    const [hours, minutes] = (initialValues?.start_time || '').split(':');
    const { updateItem } = useModelActions(tasksModel);
    const { open } = useConfirmationDialog();

    const handleEditClick = () => {
      if (data.details?.inspection_run_id) {
        open({
          TitleIcon: WarningCircleIcon,
          onConfirm: () =>
            push(ROUTES.INSPECTION_RUN_DETAILS, {
              params: {
                inspectionRunId: data.details!.inspection_run_id
              }
            }),
          confirmText: 'View inspection run',
          message:
            'To edit the inspection date, time or duration, please go to the inspection run record.'
        });
      } else {
        setIsEditMode(true);
      }
    };

    return (
      <ReactForms
        initialValues={initialValues}
        validate={{
          definitions: {
            inspection_date: {
              rules: 'required_with:duration|required_with:start_time'
            },
            start_time: {
              rules: 'required_with:duration|required_with:inspection_date'
            },
            duration: {
              rules: 'required_with:inspection_date|required_with:start_time'
            }
          }
        }}
        handleSubmit={async (values) => {
          const dataToSubmit = mapInspectionFormDataToInspectionCreateRequest(
            values
          );

          const { data: updatedItem } = await updateItem({
            id: data.id,
            data: dataToSubmit
          });

          setIsEditMode(false);
          return updatedItem;
        }}
      >
        {({
          names,
          values,
          resetForm,
          submitForm,
          isDirty,
          isSubmitting,
          isValid,
          fieldIsValid
        }) => {
          const numberInvalidFields = names.filter(
            (name) => !get(fieldIsValid, name)
          ).length;

          return (
            <Card id='inspection-date-time-and-duration'>
              <CardContent>
                <Box
                  flexDirection='row'
                  alignItems='center'
                  justifyContent='space-between'
                >
                  <Box
                    flexDirection='row'
                    alignItems='center'
                    sx='1.2rem'
                    flex={1}
                  >
                    <Heading level={3}>Inspection time and duration</Heading>

                    {!isValid && (
                      <StatusBadTag>
                        {numberInvalidFields}{' '}
                        {pluralize('Error', numberInvalidFields)}
                      </StatusBadTag>
                    )}

                    {isEditMode && isValid && isDirty && (
                      <StatusAverageTag>Unsaved changes</StatusAverageTag>
                    )}
                  </Box>
                  <Box ml='2.4rem'>
                    {isEditMode ? (
                      <ButtonGroup>
                        <GhostButton
                          onClick={() => {
                            resetForm();
                            setIsEditMode(false);
                          }}
                        >
                          Cancel
                        </GhostButton>
                        <PrimaryButton
                          isLoading={isSubmitting}
                          type='submit'
                          onClick={submitForm as any}
                        >
                          Save
                        </PrimaryButton>
                      </ButtonGroup>
                    ) : (
                      <span className='edit-button'>
                        <GhostIconButton
                          id={`edit-button`}
                          aria-label='Edit block'
                          Icon={EditIcon}
                          onClick={handleEditClick}
                        />
                      </span>
                    )}
                  </Box>
                </Box>

                {isEditMode ? (
                  <Grid columns={3}>
                    <Field
                      optional={false}
                      id='inspection-date'
                      name='inspection_date'
                      label='Inspection date'
                      Input={DateInput}
                      inputProps={{
                        deselectable: true
                      }}
                    />
                    <Field
                      optional={false}
                      id='start-time'
                      name='start_time'
                      label='Start time'
                      Input={TimeInput}
                      inputProps={{
                        deselectable: true
                      }}
                    />
                    <Field
                      optional={false}
                      id='duration'
                      name='duration'
                      label='Duration'
                      Input={TimeDurationSelect}
                      inputProps={{
                        deselectable: true
                      }}
                    />
                  </Grid>
                ) : (
                  <Grid columns={3}>
                    <DateValue
                      label='Inspection date'
                      value={values?.inspection_date}
                    />
                    <Value
                      label='Start time'
                      value={values?.start_time ? `${hours}:${minutes}` : null}
                    />
                    <Value
                      label='Duration'
                      value={
                        values?.duration ? `${values?.duration?.id} min` : null
                      }
                    />
                  </Grid>
                )}
              </CardContent>
            </Card>
          );
        }}
      </ReactForms>
    );
  }
};
