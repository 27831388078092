import React from 'react';
import { capitalize, lowerCase, upperFirst } from 'lodash';

import { TagCell } from '@rexlabs/table';

import {
  StatusTag,
  StatusTagMap
} from 'src/modules/common/components/status-tag';

import { Columns, ListTable, TabbedTable } from 'view/components/table';
import { ListTableProps } from 'view/components/table/list-table';
import { Tab } from 'view/components/table/tabbed';

import { SupplierCommissionLedger } from '../types/SupplierCommissionLedger';
import { SupplierCommissionStatus } from '../types/SupplierCommissionStatus';

const NOT_BILLED_ID = 'not_billed';
const BILLED_ID = 'billed';
const ALL_ID = 'all';

const columns: Columns<SupplierCommissionLedger> = [
  {
    id: 'creation',
    Header: 'Creation',
    accessor: (item) => item.created_at,
    type: 'date'
  },
  {
    id: 'ref_invoice',
    Header: 'Ref invoice',
    accessor: (item) => item.ref_invoice
  },
  {
    id: 'commission',
    Header: 'Commission',
    accessor: (item) => item.amount,
    type: 'currency'
  },
  {
    id: 'billed_on',
    Header: 'Billed on',
    accessor: (item) => item.billed_on,
    type: 'date'
  },
  {
    id: 'status',
    Header: 'Status',
    accessor: (item) => item.status,
    Cell: (table) => {
      const {
        cell: { value }
      } = table;
      return (
        <TagCell>
          <StatusTag status={value.vacancy_status} map={statusMap} />
        </TagCell>
      );
    }
  }
];

const statusMap: StatusTagMap<SupplierCommissionStatus> = {
  active: 'good',
  reversed: 'bad',
  reversing_entry: 'average'
};

export function SupplierCommissionLedgerTabbedTable(_props: {
  supplierId?: string;
}) {
  const sharedTabProps: Pick<Tab<ListTableProps>, 'Table' | 'columns'> &
    Partial<Omit<Tab<ListTableProps>, 'Table' | 'columns'>> = {
    Table: ListTable,
    hideBulkActions: true,
    columns
  };

  const tabs: Tab<ListTableProps>[] = [
    {
      ...sharedTabProps,
      label: upperFirst(lowerCase(NOT_BILLED_ID)),
      id: NOT_BILLED_ID,
      name: NOT_BILLED_ID
    },
    {
      ...sharedTabProps,
      label: capitalize(BILLED_ID),
      id: BILLED_ID,
      name: BILLED_ID
    },
    {
      ...sharedTabProps,
      label: capitalize(ALL_ID),
      id: ALL_ID,
      name: ALL_ID
    }
  ];

  return <TabbedTable hashParamKey='supplier-commission-ledger' tabs={tabs} />;
}
