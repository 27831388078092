import * as React from 'react';
import { BlockConfig } from 'view/components/record-screen/types';
import { Value } from 'view/components/values';
import { CurrencyValue } from 'view/components/values/currency';
import { Grid } from 'view/components/@luna/form/grid';
import { BatchReceiptingItem } from '../types';

export const parentTransactionBlock: BlockConfig<
  any,
  { batchReceiptingItem: BatchReceiptingItem }
> = {
  id: 'split-parent-transaction',
  title: 'Parent transaction',
  Edit: ({ blockProps }) => {
    const { batchReceiptingItem } = blockProps!;

    return (
      <Grid columns={4}>
        <Value
          label='Bank account'
          value={batchReceiptingItem?.bank_account?.name}
        />
        <Value label='Received from' value={batchReceiptingItem?.paid_by} />
        <CurrencyValue
          label='Amount'
          testId='split-transaction-amount'
          value={batchReceiptingItem?.amount}
        />
        <Value label='Reference' value={batchReceiptingItem?.reference} />
      </Grid>
    );
  }
};
