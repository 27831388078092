import React from 'react';
import { PropertyOwnership } from 'src/modules/property-ownerships/types/property-ownership-types';
import { SecondaryList } from '../components/secondary-list';
import { EmptyPropertyOwnership } from '../empty/empty-property-ownership';
import { getOwnershipObjects } from '../utils';
import { OwnershipEntries } from './ownership-entries';

export interface PropertyOwnershipSectionProps {
  activePropertyOwnership: PropertyOwnership | 'empty';
  incomingPropertyOwnership: PropertyOwnership | 'empty';
  maxListLength: number;
}

export function PropertyOwnershipSection({
  activePropertyOwnership: active,
  incomingPropertyOwnership: incoming,
  maxListLength
}: PropertyOwnershipSectionProps) {
  const noActiveOwnership: boolean = !active || active === 'empty';
  const noIncomingOwnership: boolean = !incoming || incoming === 'empty';

  if (noActiveOwnership && noIncomingOwnership) {
    return <EmptyPropertyOwnership />;
  }

  return (
    <SecondaryList>
      {active !== 'empty' && active && (
        <OwnershipEntries
          {...getOwnershipObjects(active as PropertyOwnership)}
          headline='Active'
          maxListLength={maxListLength}
          status='active'
        />
      )}

      {incoming !== 'empty' && incoming && (
        <OwnershipEntries
          {...getOwnershipObjects(incoming as PropertyOwnership)}
          headline='Pending'
          maxListLength={maxListLength}
          status='pending'
        />
      )}
    </SecondaryList>
  );
}
