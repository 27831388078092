import React from 'react';
import { startCase } from 'lodash';
import { Trans } from 'react-i18next';

import { formatCurrency } from 'utils/formatters';

import { PendingDisbursement } from 'data/models/entities/financials/pending-disbursements';

import { Columns } from 'view/components/table';
import { TwoTierCell } from 'view/components/cells/two-tier-cell';
import { TwoTierHeader } from 'view/components/column-headers/two-tier-column-header';

import { DisbursementOwnershipCell } from '../components/ownership-cell';
import { PendingDisbursementStatusCell } from '../components/status-cell';
import { DisbursementContactCell } from '../components/contact-cell';

export function getPendingDisbursementColumns(
  typeOfDisbursement: 'contact' | 'ownership'
): Columns<PendingDisbursement> {
  return [
    {
      id: 'status',
      Header: 'Status',
      Cell: PendingDisbursementStatusCell,
      width: 120,
      toCsv: (item) => item?.label
    },
    {
      id: typeOfDisbursement,
      Header:
        typeOfDisbursement === 'ownership'
          ? startCase(typeOfDisbursement)
          : 'Creditor',
      accessor: (item) => item,
      Cell:
        typeOfDisbursement === 'contact'
          ? DisbursementContactCell
          : DisbursementOwnershipCell,
      toCsv: (item) => item?.object.display_name
    },
    {
      id: 'due_date',
      type: 'date',
      Header: 'Due date',
      width: 120
    },
    {
      id: 'last_disbursement.created_at',
      type: 'date',
      Header: () => <Trans i18nKey='disbursements.last-disbursement.label' />,
      width: 165,
      headerString: 'Last disbursement',
      toCsv: (item) => item?.last_disbursement?.created_at || ''
    },
    {
      id: 'bill_payments',
      headerString: 'Bill payments',
      accessor: (item) => item,
      Header: () => (
        <TwoTierHeader
          align='right'
          header='Bill payments'
          subheader='Outstanding'
        />
      ),
      cellProps: {
        align: 'right'
      },
      Cell: ({ value }) => {
        const billsPaidInDisbursement =
          value.disburse_summary.recommended_bill_payment_allocation_amount;
        const totalBillsPayableNow =
          value.financial_summary.bills_payable_total_amount_owing -
          value.financial_summary.bills_not_yet_payable_total_amount_owing;

        return (
          <TwoTierCell
            text={formatCurrency(billsPaidInDisbursement)}
            subtext={formatCurrency(
              totalBillsPayableNow - billsPaidInDisbursement
            )}
          />
        );
      },
      width: 180,
      toCsv: (item) =>
        item?.financial_summary?.bills_payable_total_amount_owing.toString()
    },
    ...(typeOfDisbursement === 'ownership'
      ? [
          {
            id: 'fees_payments',
            headerString: 'Fees payments',
            accessor: (item) => item,
            Header: () => (
              <TwoTierHeader
                align='right'
                header='Fees payments'
                subheader='Outstanding'
              />
            ),
            cellProps: {
              align: 'right'
            },
            Cell: ({ value }) => {
              const feesPaidInDisbursement =
                value.disburse_summary
                  .recommended_fee_payment_allocation_amount;
              const totalFeesPayableNow =
                value.financial_summary.fees_payable_total_amount_owing -
                value.financial_summary.fees_not_yet_payable_total_amount_owing;

              return (
                <TwoTierCell
                  text={formatCurrency(feesPaidInDisbursement)}
                  subtext={formatCurrency(
                    totalFeesPayableNow - feesPaidInDisbursement
                  )}
                />
              );
            },
            width: 180,
            toCsv: (item) =>
              item?.financial_summary?.fees_payable_total_amount_owing.toString()
          }
        ]
      : []),
    {
      id: 'specific_payments',
      headerString: 'Specific payments',
      accessor: (item) => item,
      Header: () => (
        <TwoTierHeader
          align='right'
          header='Specific payments'
          subheader='Outstanding'
        />
      ),
      cellProps: {
        align: 'right'
      },
      Cell: ({ value }) => (
        <TwoTierCell
          text={formatCurrency(
            value.disburse_summary.allocated_for_specific_payout_ready_amount
          )}
          subtext={formatCurrency(
            value.disburse_summary.allocated_for_specific_payout_pending_amount
          )}
        />
      ),
      width: 180,
      toCsv: (item) =>
        item?.disburse_summary?.allocated_for_specific_payout_ready_amount.toString()
    },
    {
      id: 'disburse_summary.recommended_payout_allocation_amount',
      type: 'currency',
      Header: `Payout to ${typeOfDisbursement}`,
      cellProps: {
        align: 'right'
      },
      width: 180
    }
  ];
}
