import React from 'react';

import Box from '@rexlabs/box';
import { StyleSheet, useStyles, useToken } from '@rexlabs/styling';
import { Small } from '@rexlabs/text';
import { useMediaQuery } from '@rexlabs/breakpoints';
import { GhostIconButton } from '@rexlabs/button';
import ActionMenu, { ActionMenuItem } from '@rexlabs/action-menu';

import { valueListFactory } from 'src/lib/testing/factories/value-list-factory';

import DragIcon from 'view/components/icons/drag';
import { ValueListValue } from 'data/models/types';
import RemoveIcon from 'view/components/icons/remove';
import InspectionsIcon from 'view/components/icons/inspections';
import KebabIcon from 'view/components/icons/kebab';

import { InspectionType } from '../types/InspectionType';
import {
  InspectionDuration,
  InspectionDurationMenu
} from './inspection-duration';
import { InspectionRunResponsiveCard } from './inspection-run-responsive-card';
import { InspectionRunResponsiveIconContainer } from './inspection-run-responsive-icon-container';
import { InspectionRunResponsiveTitle } from './inspection-run-responsive-title';

const defaultStyles = StyleSheet({
  button: {
    lineHeight: 0,
    appearance: 'none',
    padding: 0,
    margin: 0,
    border: 'none',
    background: 'transparent'
  },
  dragHandle: {
    '&:focus': {
      boxShadow: 'none'
    }
  }
});

interface InspectionRunInspectionCardProps {
  address: string;
  time: string;
  duration: InspectionDuration;
  inspectionType: ValueListValue<InspectionType>;
  setDuration: (value: any) => void;
  handleDrag: (args: any) => any;
  handleRemove: (args: any) => any;
  dragProps?: any;
  isEditMode: boolean;
  actions?: ActionMenuItem[];
}

export function InspectionRunInspectionCard({
  address,
  time,
  duration,
  setDuration,
  handleDrag,
  handleRemove,
  dragProps,
  isEditMode,
  inspectionType,
  actions = []
}: InspectionRunInspectionCardProps) {
  const token = useToken();
  const s = useStyles(defaultStyles);
  const matchesMobile = useMediaQuery({ maxWidth: 's' });

  return (
    <InspectionRunResponsiveCard>
      <Box
        flexDirection='row'
        alignItems='center'
        columnGap={token('spacing.xs')}
        data-testid={'inspection-run-inspection-card'}
      >
        <InspectionRunResponsiveIconContainer
          flexDirection='row'
          alignItems='center'
          justifyContent='center'
        >
          {isEditMode ? (
            <button
              type='button'
              aria-label='drag item'
              {...s('button', 'dragHandle')}
              {...dragProps}
              onMouseDown={handleDrag}
            >
              <DragIcon data-testid={'drag-icon'} />
            </button>
          ) : (
            <InspectionsIcon data-testid={'inspection-icon'} />
          )}
        </InspectionRunResponsiveIconContainer>
        <Box flexDirection='column' rowGap={token('spacing.xxs')}>
          <InspectionRunResponsiveTitle>{address}</InspectionRunResponsiveTitle>
          <Box
            flexDirection='row'
            alignItems='center'
            columnGap={token('spacing.xxs')}
          >
            {!matchesMobile && (
              <>
                <Small grey as='span'>
                  {inspectionType.label.split(' ')[0]}
                </Small>
                <Small grey as='span'>
                  •
                </Small>
              </>
            )}
            <Small grey as='span'>
              Start:
            </Small>
            <Small semibold as='span'>
              {time}
            </Small>
            <Small grey as='span'>
              •
            </Small>
            <Small grey as='span'>
              Duration:
            </Small>
            {isEditMode ? (
              // This action menu is not the final design - need to build a new one
              // https://rexsoftware.atlassian.net/browse/ALFIE-2647 */
              <Box marginTop={'-6px'} marginBottom={'-6px'}>
                <InspectionDurationMenu
                  value={valueListFactory(duration.toString())}
                  onChange={(item) => setDuration(item.id)}
                />
              </Box>
            ) : (
              <Small semibold as='span'>
                {duration} min
              </Small>
            )}
          </Box>
        </Box>
        <InspectionRunResponsiveIconContainer
          flexDirection='row'
          alignItems='center'
          justifyContent='center'
          marginLeft='auto'
        >
          {isEditMode ? (
            <GhostIconButton
              data-testid={'remove-icon'}
              aria-label='delete inspection item'
              Icon={() => (
                <RemoveIcon
                  size={matchesMobile ? 'l' : 'm'}
                  color={token('color.textStyle.body.error')}
                />
              )}
              onClick={handleRemove}
            />
          ) : (
            <ActionMenu
              Icon={KebabIcon}
              Button={GhostIconButton}
              css
              items={actions}
            />
          )}
        </InspectionRunResponsiveIconContainer>
      </Box>
    </InspectionRunResponsiveCard>
  );
}
