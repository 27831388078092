import { useCallback } from 'react';
import { omit } from 'lodash';

import { useModelActions } from '@rexlabs/model-generator';

import {
  postRequestPipelineRequests,
  RequestPipelineMethod,
  RequestPipeLineRequests
} from 'utils/api/post-request-pipeline';

import { PropertyComplianceCategory } from 'data/models/entities/settings/property-compliance-categories';
import {
  PropertyCategoryRequirement,
  settingsPropertyCategoryRequirementsModel
} from 'data/models/entities/settings/property-category-requirements';
import { ComplianceType } from 'src/modules/compliance/common/models/compliance-types';

import { PropertyComplianceRequirementDetailsBlockFormValues } from '../blocks/property-compliance-requirement-details-block';

/**
 * This compares the values from the form to the existing categories and returns
 * the new ones.
 * @param {object} values
 * @param {object[]}existingCategories
 * @returns  {object[]}
 */
function getNewCategoriesFromValues(
  values: PropertyComplianceRequirementDetailsBlockFormValues,
  existingCategories?: PropertyComplianceCategory[]
) {
  return values.include_in_categories.filter(
    (category) => !existingCategories?.find((req) => req.id === category.id)
  );
}

/**
 * This compares the values from the form to the existing categories, to see what
 * has been removed. From there, we return a list of categories that use the requirement
 * that need that relationship destroyed.
 * @param  {object} values
 * @param  {object[]} existingCategories
 * @param  {object[]} categoriesContainingRequirement
 * @returns {object[]}
 */
function getRemovedCategoriesFromValues(
  values: PropertyComplianceRequirementDetailsBlockFormValues,
  existingCategories?: PropertyComplianceCategory[],
  categoriesContainingRequirement?: PropertyCategoryRequirement[]
) {
  const removedCategories = existingCategories?.filter(
    (req) => !values.include_in_categories.find((r) => r.id === req.id)
  );

  const categoriesThatNoLongerIncludeRequirement = (
    categoriesContainingRequirement || []
  ).filter((categoryRequirement) =>
    removedCategories?.find(
      (removedCategory) =>
        categoryRequirement.category.id === removedCategory.id
    )
  );

  return categoriesThatNoLongerIncludeRequirement;
}

export function useUpdateComplianceRequirement() {
  const { trashItem } = useModelActions(
    settingsPropertyCategoryRequirementsModel
  );

  return {
    updateComplianceRequirement: useCallback(
      async ({
        values,
        existingCategories,
        categoriesContainingRequirement
      }: {
        values: PropertyComplianceRequirementDetailsBlockFormValues;
        existingCategories?: PropertyComplianceCategory[];
        categoriesContainingRequirement?: PropertyCategoryRequirement[];
      }) => {
        const requests: RequestPipeLineRequests = [
          // Update the compliance requirement
          {
            method: 'PATCH',
            path: `/api/v1/settings/compliance-types/${values.id}`,
            json: omit(values, 'include_in_categories')
          },
          // then update the new categories that are linked to the requirement
          ...getNewCategoriesFromValues(values, existingCategories).map(
            (category: PropertyComplianceCategory) => ({
              method: 'POST' as RequestPipelineMethod,
              path: '/api/v1/settings/property-category-requirements',
              json: {
                property_compliance_category_id: category.id,
                compliance_type_id: '{{$.0.id}}'
              }
            })
          )
        ];

        const { data } = await postRequestPipelineRequests<
          typeof requests,
          [ComplianceType, ...PropertyCategoryRequirement[]]
        >(requests);

        // Lastly, remove the link between the categories that no longer use the requirement
        // TODO: We should be able to do this in the request pipeline
        // Following up with BE team to see if this is possible
        await Promise.all(
          getRemovedCategoriesFromValues(
            values,
            existingCategories,
            categoriesContainingRequirement
          ).map((categoryRequirement) =>
            trashItem({ id: categoryRequirement.id })
          )
        );

        return { data };
      },
      [trashItem]
    )
  };
}
