import { getDocumentsBlock } from 'src/modules/documents/blocks/documents';
import { Contact } from 'src/modules/contacts/types/contact-types';

export const documentsBlock = getDocumentsBlock(
  ({ data }: { data: Contact }) => ({
    attachedTo: [
      {
        id: data.id,
        label: data.display_name,
        type: { id: 'contact' }
      }
    ],
    contactId: data.id
  })
);
