import React from 'react';

import { Contact } from 'src/modules/contacts/types/contact-types';

import { BlockConfig } from 'view/components/record-screen/types';
import { ActionButtons } from 'view/components/@luna/action-buttons';

import { SupplierCommissionLedgerTabbedTable } from '../components/supplier-commission-ledger-tabbed-table';
import { useGetCreateManualSupplierCommissionEntryAction } from '../actions/create-manual-supplier-commission-entry-action';

export const supplierCommissionLedgerBlock: BlockConfig<Contact> = {
  id: 'supplier_commission_ledger',
  title: 'Supplier Commission Ledger',
  Actions: ({ data }) => {
    const getCreateManualSupplierCommissionEntryAction = useGetCreateManualSupplierCommissionEntryAction();

    if (!data) {
      return null;
    }

    return (
      <ActionButtons
        actions={[getCreateManualSupplierCommissionEntryAction(data)]}
      />
    );
  },
  View: ({ data }) => {
    return <SupplierCommissionLedgerTabbedTable supplierId={data?.id} />;
  }
};
