import { useDialog } from '@rexlabs/dialog';
import { useModelActions } from '@rexlabs/model-generator';
import { api } from 'utils/api/api-client';
import { TaskType } from 'src/modules/tasks/common/types/TaskType';
import { useRecordCreatedToast } from 'src/modules/common/toasts/hooks/use-record-created-toast';
import { RecordSubmitHandler } from 'view/components/record-screen/utils';
import { ChecklistTemplateDialog } from '../../../dialogs/checklist-template';
import { ChecklistTemplatesForm } from '../../../types/ChecklistTemplatesForm';
import { checklistTemplatesModel } from '../../../models/checklist-templates-model';
import { useTaskTypesWithCustomAddToRecord } from '../../use-task-types-with-custom-add-to-record';

export function useCreateChecklistTemplateAction(type: TaskType) {
  const { open } = useDialog(ChecklistTemplateDialog);
  const getTransformChecklistFormDataToPipelineRequest = useGetTransformChecklistFormDataToPipelineRequest();

  const { refreshLists } = useModelActions(checklistTemplatesModel);

  const addCreatedToast = useRecordCreatedToast(checklistTemplatesModel, {
    actions: []
  });

  const onSubmit: RecordSubmitHandler<ChecklistTemplatesForm> = async ({
    values
  }) => {
    const requests = getTransformChecklistFormDataToPipelineRequest({
      values,
      type
    });
    const response = await api.post('request-pipelines', {
      requests
    });

    addCreatedToast(response.data.checklist_templates);
    await refreshLists();

    return response.data.checklist_templates;
  };

  return { open, onSubmit } as const;
}

function useGetTransformChecklistFormDataToPipelineRequest() {
  const taskTypesWithCustomAddToRecord = useTaskTypesWithCustomAddToRecord();
  return ({
    values,
    type
  }: {
    values: ChecklistTemplatesForm;
    type: TaskType;
  }) => {
    const checklistItems = values.checklist_template_items.map(
      (item, index) => {
        return {
          id: `checklist_template_items_${index}`,
          method: 'POST',
          path: '/api/v1/tasks/checklist-template-items',
          json: {
            label: item.label,
            checklist_template: {
              id: '{{$.checklist_templates.id}}'
            }
          }
        };
      }
    );

    const itemOrderArray = values.checklist_template_items.map(
      (item, index) => {
        return `{{$.checklist_template_items_${index}.id}}`;
      }
    );

    return [
      {
        id: 'checklist_templates',
        method: 'POST',
        path: '/api/v1/tasks/checklist-templates',
        json: {
          label: values.label,
          description: values.description,
          is_default: !taskTypesWithCustomAddToRecord.includes(type)
            ? true
            : !!values.is_default,
          task_type: {
            id: type
          }
        }
      },
      ...checklistItems,
      {
        id: 'checklist_templates_item_array',
        method: 'PATCH',
        path: `/api/v1/tasks/checklist-templates/{{$.checklist_templates.id}}`,
        json: {
          item_order: itemOrderArray
        }
      }
    ];
  };
}
