import { OutlineButton } from '@rexlabs/button';
import { push } from '@rexlabs/whereabouts';
import { StatementType } from 'src/modules/statements/types';
import { BannerAction } from 'view/components/@luna/notifications/banner/core';
import { EOY_STATEMENTS_ROUTES } from '../../eoy-statements/routes';
import { PERIODIC_STATEMENTS_ROUTES } from '../../periodic-statements/routes';

function handleToastClick(statementType: StatementType) {
  return push({
    config: {
      path:
        statementType === StatementType.PeriodicOwnership
          ? PERIODIC_STATEMENTS_ROUTES.PERIODIC_STATEMENTS_LIST.config.path
          : EOY_STATEMENTS_ROUTES.EOY_STATEMENTS_LIST.config.path,
      hash: 'tab=issued'
    }
  });
}

export function issueStatementToastAction(
  statementType: StatementType
): BannerAction {
  return {
    label: 'View issued statements',
    onClick: () => handleToastClick(statementType),
    Button: OutlineButton
  };
}
