import React, { useCallback, useMemo } from 'react';
import { useDialog } from '@rexlabs/dialog';

import { BlockConfig } from 'view/components/record-screen/types';
import { ActionButtons } from 'view/components/@luna/action-buttons';
import { FinancialRecordType } from 'utils/financials/financial-objects';

import { ValueListValue } from 'data/models/types';
import { GlobalFilter } from '@rexlabs/table/lib/types/context';
import { InvoicesList } from '../components/invoices-list';
import { CreateInvoiceDialog } from '../dialogs/create-invoice-dialog';
import { getInvoicesQuery } from '../data/queries';
import { InvoiceFilterAction } from '../utils/use-invoice-filters';

type PayableObject = {
  id: string;
  type: ValueListValue<FinancialRecordType>;
  label: string;
};

type GetProps<Data = any> = (options: {
  data: Data;
}) => {
  getForcedGlobalFilter: (id: string) => GlobalFilter[];
  getInitialGlobalFilter?: () => GlobalFilter[];
  initialValues?: {
    payable_to?: { object: PayableObject };
  };
  hashParamKey?: string;
  refreshRecord?: () => Promise<any>;
  invoiceActions?: InvoiceFilterAction[];
  invoiceFilters?: GlobalFilter[];
};

export function getInvoicesPayableToBlock<Data = any>(
  getProps: GetProps<Data>
): BlockConfig {
  return {
    id: 'payable-invoices',
    title: 'Invoices receivable',
    Actions: ({ data }) => {
      const { initialValues, refreshRecord, invoiceActions } = useMemo(
        () => getProps({ data }),
        [data]
      );
      const { open } = useDialog(CreateInvoiceDialog);
      return (
        <ActionButtons
          actions={[
            ...(invoiceActions || []),
            {
              label: 'Create invoice',
              handleAction: () => {
                open({ initialValues, onSave: refreshRecord });
              }
            }
          ]}
        />
      );
    },
    View: ({ data }) => {
      const {
        getForcedGlobalFilter,
        getInitialGlobalFilter,
        hashParamKey,
        refreshRecord,
        invoiceFilters
      } = useMemo(() => getProps({ data }), [data]);

      const getQuery = useCallback(() => getInvoicesQuery(), []);

      const combinedFilter = [
        ...getForcedGlobalFilter(data?.id),
        ...(invoiceFilters || [])
      ];

      return (
        <InvoicesList
          shouldPersistTableSettings={false}
          refreshParentEntityAction={refreshRecord}
          getQuery={getQuery}
          forcedGlobalFilter={combinedFilter}
          initialGlobalFilter={getInitialGlobalFilter?.()}
          hashParamKey={hashParamKey}
        />
      );
    }
  };
}
