import { Box } from '@rexlabs/box';
import { OutlineButton } from '@rexlabs/button';
import { Checkbox } from '@rexlabs/checkbox';
import { Field } from '@rexlabs/form';
import { InfoBanner } from '@rexlabs/notifications';
import { Body } from '@rexlabs/text';
import { Link } from '@rexlabs/whereabouts';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import ROUTES from 'routes/app';
import { useSettings } from 'src/modules/settings/hooks/useSettings';
import { pluralize } from 'utils/formatters';
import { Column, Grid } from 'view/components/@luna/form/grid';
import {
  DateInput,
  disablePastDates
} from 'view/components/@luna/inputs/date-input/date-input';
import InfoCircleIcon from 'view/components/icons/info';
import { FrequencySplitInput } from 'view/components/inputs/frequency-input/frequency-split-input';
import { BlockConfig } from 'view/components/record-screen/types';
import { Value } from 'view/components/values';
import { DateValue } from 'view/components/values/date';

export const routineInspectionsBlock: BlockConfig = {
  id: 'routine-inspections',
  title: (
    <Trans i18nKey='tasks.inspections.type.routine-inspection.label.plural' />
  ),
  validate: {
    definitions: {
      'selected_property_tenancy.routine_inspection_frequency': {
        rules:
          'required_if_true:selected_property_tenancy.routine_inspection_enabled'
      }
    }
  },
  View: ({ data }) => {
    const { t } = useTranslation();

    const settingsData = useSettings();
    const inspectionData = settingsData['inspection-task-interval'];
    const settingsCount = inspectionData?.notice_period?.count;
    const settingsUnit = inspectionData?.notice_period?.unit;

    const status = data?.selected_property_tenancy?.status?.id;

    const isDraft = status === 'draft';
    const isActive = status === 'active';

    const nextInspectionDueDate =
      data?.selected_property_tenancy?.next_routine_inspection_due_date;

    return (
      <Grid columns={2}>
        <DateValue
          testId='next-inspection-is-due'
          label='Next inspection is due'
          value={nextInspectionDueDate}
        />

        {data?.selected_property_tenancy?.routine_inspection_enabled ? (
          <Value
            testId='schedule-routine-inspection'
            label={t(
              'tasks.inspections.task-triggers.routine-inspections.schedule-frequency.schedule-new.message'
            )}
            value={
              data?.selected_property_tenancy?.routine_inspection_frequency
                ?.count
                ? pluralize(
                    data?.selected_property_tenancy
                      ?.routine_inspection_frequency?.unit?.id,
                    data?.selected_property_tenancy
                      ?.routine_inspection_frequency?.count,
                    true
                  )
                : undefined
            }
          />
        ) : null}

        {isDraft && !nextInspectionDueDate ? (
          <Column width={2}>
            <InfoBanner
              Icon={InfoCircleIcon}
              description={
                <>
                  This lease agreement is in draft state. When the agreement is
                  set to active, the <b>Next inspection is due</b> will be
                  displayed. Alternatively, you can manually add a date for the
                  next inspection. Read more about lease agreements states{' '}
                  <a
                    href='https://rexpm.zendesk.com/hc/en-au/articles/14924178825625-Create-and-Manage-a-Lease-Agreement'
                    target='_blank'
                    rel='noreferrer'
                  >
                    here
                  </a>
                  . You can also change default inspection schedule for new
                  properties in{' '}
                  <Link
                    to={ROUTES.TASK_SETTINGS_DETAILS}
                    query={{ tab: 'inspections' }}
                  >
                    Settings
                  </Link>
                  .
                </>
              }
            />
          </Column>
        ) : null}

        {isActive || nextInspectionDueDate != null ? (
          <Column width={2}>
            <Body>
              A routine inspection task will be automatically created{' '}
              <b>
                {settingsCount} {settingsUnit}
              </b>{' '}
              before the <b>Next inspection is due</b>. You can change this in{' '}
              <Link
                to={ROUTES.TASK_SETTINGS_DETAILS}
                query={{ tab: 'inspections' }}
              >
                Settings
              </Link>
              .
            </Body>
          </Column>
        ) : null}
      </Grid>
    );
  },
  Edit: ({ values, setFieldValue }) => {
    const { t } = useTranslation();
    const settingsData = useSettings();
    const inspectionData = settingsData['inspection-task-interval'];

    const settingsCount = inspectionData?.routine_inspection?.count;
    const settingsUnit = inspectionData?.routine_inspection?.unit;

    const isSameAsSettings = React.useMemo(() => {
      const hasSameCount =
        settingsCount ===
        values?.selected_property_tenancy?.routine_inspection_frequency?.count;

      const hasSameUnit =
        settingsUnit ===
        values?.selected_property_tenancy?.routine_inspection_frequency?.unit
          ?.id;

      return hasSameCount && hasSameUnit;
    }, [values, settingsCount, settingsUnit]);

    const handleReset = () => {
      setFieldValue?.(
        'selected_property_tenancy.routine_inspection_frequency.count',
        inspectionData?.routine_inspection?.count
      );

      setFieldValue?.(
        'selected_property_tenancy.routine_inspection_frequency.unit.id',
        inspectionData?.routine_inspection?.unit
      );
    };

    const routineResetText =
      settingsUnit && settingsCount
        ? `: Recurring every 
                ${pluralize(settingsUnit, settingsCount, true)}`
        : '';

    return (
      <Grid columns={2} data-testid={'next-routine-inspection-edit-mode'}>
        <Field
          name='selected_property_tenancy.next_routine_inspection_due_date'
          label='Next inspection is due'
          optional={false}
          Input={DateInput}
          inputProps={{
            isOutsideRange: disablePastDates,
            clearable: true
          }}
        />

        <Box marginTop={20}>
          <Field
            name='selected_property_tenancy.routine_inspection_enabled'
            Input={Checkbox}
            optional={false}
            inputProps={{
              label: 'Recurring inspections'
            }}
          />
        </Box>

        {values.selected_property_tenancy?.routine_inspection_enabled ? (
          <>
            <Field
              name='selected_property_tenancy.routine_inspection_frequency'
              label={
                t(
                  'tasks.inspections.task-triggers.routine-inspections.schedule-frequency.schedule-new.message'
                ) as string
              }
              Input={FrequencySplitInput}
              optional={false}
            />

            <div />

            <div>
              <OutlineButton
                isDisabled={isSameAsSettings}
                onClick={handleReset}
              >
                Reset to default frequency{routineResetText}
              </OutlineButton>
            </div>
          </>
        ) : null}

        {/* This is there to prevent form auto submit on pressing enter */}
        <input type='text' aria-label='_hack_' style={{ display: 'none' }} />
      </Grid>
    );
  }
};
