import { Owner } from 'data/models/entities/ownerships';
import { Contact } from 'src/modules/contacts/types/contact-types';
import { Property } from 'src/modules/properties/types/property-types';

export function getOwnerSelectCustomFilter(property?: Property | null) {
  return (item: Contact): boolean => {
    // if there isn't a property selected we want to return all results
    if (!property) {
      return true;
    }
    // if there isn't an active property ownership with owners we don't want to return any results
    if (!property?.active_property_ownership?.ownership?.owners) {
      return false;
    }
    // if there is an active property ownership we want to return only
    // owners that are part of the active management agreement
    return !!property.active_property_ownership.ownership.owners.find(
      (owner: Owner) => owner.contact.id === item.id
    );
  };
}
