import React from 'react';
import { BlockConfig } from 'view/components/record-screen/types';
import { Column, Grid } from '@rexlabs/grid';
import { Field } from '@rexlabs/form';
import { TextInput } from '@rexlabs/text-input';
import { Checkbox } from '@rexlabs/checkbox';
import { FinancialsChartOfAccountsCategory } from 'view/components/inputs/selects/financials-chart-of-accounts-category';
import { InfoBanner } from 'view/components/@luna/notifications/banner';
import { TaxTypeSelect } from 'view/components/inputs/selects/tax-type';
import { ChartOfAccountsAccount } from 'src/modules/chart-of-accounts/types/chart-of-account-types';
import { useSessionState } from 'src/modules/common/hooks/session-state';
import { useFeatureFlags } from 'view/components/@luna/feature-flags';
import { FLAGS } from 'utils/feature-flags';
import { getTaxableAccountCheckboxConfig } from '../utils/get-taxable-account-checkbox-config';

export const editChartOfAccountsAccountsBlock: BlockConfig<ChartOfAccountsAccount> = {
  id: 'editChartOfAccountsAccounts',
  title: 'Chart of accounts account',
  validate: {
    definitions: {
      name: { rules: 'required' },
      default_tax_type: { rules: 'required' }
    }
  },
  Edit: ({ values, data }) => {
    const { activeSilo } = useSessionState();
    const { hasFeature } = useFeatureFlags();

    const taxableCheckboxConfig = getTaxableAccountCheckboxConfig(
      values?.account_category?.id
    );

    return (
      <Grid>
        {data?.is_system_generated && (
          <Column width={12}>
            <InfoBanner description='This is a system managed account. You cannot either remove or edit the category of a system managed account' />
          </Column>
        )}
        <Column width={12}>
          <Field
            name='name'
            label='Name'
            Input={TextInput}
            inputProps={{
              disabled: values.is_system_generated
            }}
          />
        </Column>
        <Column width={12}>
          <Field name='description' label='Description' Input={TextInput} />
        </Column>
        <Column width={12}>
          <Field
            name='account_category'
            id='account_category'
            label='Category'
            Input={FinancialsChartOfAccountsCategory}
            inputProps={{
              disabled: values.is_system_generated
            }}
          />
        </Column>
        <Column width={12}>
          <Field
            name='default_tax_type'
            label='Tax type'
            Input={TaxTypeSelect}
          />
        </Column>
        {!!(
          hasFeature(FLAGS.WITHHOLDING_TAX) &&
          activeSilo?.country?.id === 'GBR' &&
          taxableCheckboxConfig
        ) && (
          <Column width={12}>
            <Field<typeof Checkbox>
              name='is_income_taxable'
              Input={Checkbox}
              description={taxableCheckboxConfig.helperText}
              optional={false}
              inputProps={{
                label: taxableCheckboxConfig.label
              }}
            />
          </Column>
        )}
      </Grid>
    );
  }
};
