import { Columns } from 'view/components/table';
import { SecurityDeposit } from 'data/models/entities/financials/security-deposits';

export const securityDepositPaymentColumns: Columns<SecurityDeposit> = [
  {
    id: 'created_at',
    Header: 'Date',
    type: 'date'
  },
  {
    id: 'type',
    Header: 'Type',
    type: 'value'
  },
  {
    id: 'description',
    Header: 'Description'
  },
  {
    id: 'amount',
    Header: 'Amount',
    type: 'currency'
  }
];
