import { Ownership } from 'data/models/entities/ownerships';
import React from 'react';
import { useCreateStatements } from 'src/modules/statements/hooks/use-create-statements';
import { StatementType } from 'src/modules/statements/types';
import { RecordDialog } from 'view/components/record-screen/dialog/dialog';
import { DialogContentConfig } from 'view/components/record-screen/dialog/types';
import { RecordSubmitHandler } from 'view/components/record-screen/utils';
import { DisabledDialogButtonGroup } from 'src/modules/common/dialogs/ButtonsGroups/DisabledDialogButtonGroup';
import { useEarliestIncomeStatementStartDate } from 'src/modules/statements/hooks/use-earliest-income-statement-start-date';
import { Contact } from 'src/modules/contacts/types/contact-types';
import { RecordTypes } from 'data/models/types';
import { statementDetailsBlock } from '../blocks/statement-details';

const content: DialogContentConfig = [
  {
    id: 'statement-dates',
    label: 'Statement dates',
    blocks: [statementDetailsBlock]
  }
];

export interface IssueStatementDialogProps {
  record: Ownership | Contact;
  recordType: typeof RecordTypes.Contact | typeof RecordTypes.Ownership;
  onClose?: () => void;
}

export function IssueStatementDialog({
  record,
  recordType,
  onClose
}: IssueStatementDialogProps) {
  const issueStatements = useCreateStatements();

  const {
    data: earliestStatementDate,
    isLoading
  } = useEarliestIncomeStatementStartDate(record);

  const initialData = {
    selectedItems: [record]
  };

  const handleSubmit: RecordSubmitHandler = async ({ values }) => {
    const statementDates =
      values.statement_type === StatementType.PeriodicOwnership ||
      values.statement_type === StatementType.PeriodicAgency
        ? {
            statementEndDate: values.statement_end_date,
            ...(values.statement_start_date && {
              statementStartDate: values.statement_start_date
            })
          }
        : {
            statementEndDate: values.financial_year.endDate,
            statementStartDate: values.financial_year.startDate
          };

    await issueStatements({
      selectedItems: values.selectedItems,
      statementType: values.statement_type,
      recordType,
      ...statementDates
    });
    return true;
  };

  return (
    <RecordDialog
      onClose={onClose}
      data={initialData}
      title='Issue statement'
      handleSubmit={handleSubmit}
      content={content}
      submitLabel='Issue statement'
      isLoading={isLoading}
      blockProps={{
        earliestStatementDate,
        recordType
      }}
      ButtonGroup={
        earliestStatementDate ? undefined : DisabledDialogButtonGroup
      }
    />
  );
}
