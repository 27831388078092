import { activityBlock } from 'view/components/blocks/activity';
import { wipBlock } from 'view/components/blocks/wip';
import { ownershipDisbursementPaymentMethodsBlock } from 'src/modules/disbursements/blocks/ownership-disbursement-payment-methods';
import { ownershipDisbursementPaymentMethodsBlockV2 } from 'src/modules/disbursements/blocks/ownership-disbursement-payment-methods-v2';
import { disbursementWithholdFundsBlock } from 'src/modules/disbursements/blocks/withhold-funds';
import { getMaintenanceInstructionsBlock } from 'src/modules/ownerships/blocks/maintenance-instructions-block';
import { useDisbursementsFrequencyBlock } from 'src/modules/disbursements/blocks/disbursement-frequency-block';
import { incomeAndExpenseBlock } from 'src/modules/account-journal-entries/blocks/income-and-expense-block';
import { FLAGS } from 'utils/feature-flags';
import { noteTableBlock } from 'src/modules/note/blocks/note-table-block';
import { getDisbursementTableBlock } from 'src/modules/disbursements/blocks/disbursement-table-block';
import { RecordTypes } from 'data/models/types';
import { Ownership, ownershipsModel } from 'data/models/entities/ownerships';
import { useMessageTableBlock } from 'src/modules/communications/messages/blocks/message-table-block';
import { useTranslation } from 'react-i18next';
import { useFeatureFlags } from 'view/components/@luna/feature-flags';
import { useSessionState } from 'src/modules/common/hooks/session-state';
import { relatedTaskWithheldFundsBlock } from 'src/modules/withheld-funds/blocks/related-task-withheld-funds-block';
import { statementPreferencesBlock } from 'src/modules/ownerships/blocks/statement-preferences-block';
import { usePayableInvoicesBlock } from '../blocks/invoices-payable-by';
import { useReceivableInvoicesBlock } from '../blocks/invoices-payable-to';
import { documentsBlock } from '../blocks/documents';
import { trustTransactionsWithBankAccountSelectBlock } from '../../trust-journal-entry-line-items/blocks/trust-transactions-table-with-bank-account-select';
import { preferredSuppliersBlock } from '../blocks/preferred-suppliers';
import { useFeesBlock } from '../blocks/fees';
import { propertyOwnershipsBlock } from '../blocks/property-ownerships';
import { ownersBlock } from '../blocks/owners';
import { ownershipStatementSummaryBlock } from '../blocks/ownership-statement-summary-block';
import { ownershipStatementsBlock } from '../blocks/ownership-statements-block';
import { ownersAndIncomeAttributionBlock } from '../blocks/owners-and-income-attribution';
import { taxSummaryBlock } from '../../tax/blocks/tax-summary-block';

const disbursementTableBlock = getDisbursementTableBlock<Ownership>({
  type: RecordTypes.Ownership
});

const maintenanceInstructionsBlock = getMaintenanceInstructionsBlock<Ownership>();

export const useOwnershipContent = () => {
  const { t } = useTranslation();
  const feesBlock = useFeesBlock();
  const payableInvoicesBlock = usePayableInvoicesBlock();
  const receivableInvoicesBlock = useReceivableInvoicesBlock();
  const messageTableBlock = useMessageTableBlock(ownershipsModel);
  const { hasFeature } = useFeatureFlags();
  const { activeSilo } = useSessionState();
  const disbursementsFrequencyBlock = useDisbursementsFrequencyBlock();

  return [
    {
      label: 'General',
      items: [
        {
          id: 'overview',
          label: 'Overview',
          blocks: [
            ownershipStatementSummaryBlock,
            propertyOwnershipsBlock,
            ...(hasFeature(FLAGS.OWNERSHIP_INCOME_ATTRIBUTION)
              ? [ownersAndIncomeAttributionBlock]
              : [ownersBlock])
          ]
        },
        {
          id: 'activities',
          label: 'Activities',
          blocks: [activityBlock],
          workInProgress: true
        },
        {
          id: 'documents',
          label: 'Documents',
          blocks: [documentsBlock]
        },
        {
          id: 'notes',
          label: 'Notes',
          blocks: [noteTableBlock]
        },
        { id: 'messages', label: 'Messages', blocks: [messageTableBlock] }
      ]
    },
    {
      label: 'Management',
      items: [
        {
          id: 'management-agreements',
          label: t('property-ownerships.management-agreement.label.plural'),
          blocks: [propertyOwnershipsBlock]
        },
        {
          id: 'ownership-fees',
          label: 'Ownership fees',
          blocks: [feesBlock]
        },
        {
          id: 'maintenance-preferences',
          label: 'Maintenance preferences',
          blocks: [maintenanceInstructionsBlock, preferredSuppliersBlock]
        },
        {
          id: 'ownership-statements',
          label: 'Statements',
          blocks: [statementPreferencesBlock, ownershipStatementsBlock]
        }
      ]
    },
    {
      label: 'Inspections & Maintenance',
      items: [
        {
          id: 'inspections',
          label: 'Inspections',
          blocks: [wipBlock],
          workInProgress: true
        },
        {
          id: 'maintenance',
          label: 'Maintenance',
          blocks: [wipBlock],
          workInProgress: true
        }
      ],
      workInProgress: true
    },
    {
      label: 'Financials',
      items: [
        {
          id: 'invoices',
          label: 'Invoices & bills',
          blocks: [payableInvoicesBlock, receivableInvoicesBlock]
        },
        {
          id: 'trust',
          label: t('financials.trust.label'),
          blocks: [trustTransactionsWithBankAccountSelectBlock]
        },
        {
          id: 'accounting',
          label: 'Income & expense',
          blocks: [incomeAndExpenseBlock]
        },
        ...(activeSilo?.country.id === 'GBR'
          ? [
              {
                id: 'tax',
                label: 'Tax',
                flags: FLAGS.OWNERSHIP_TAX_REPORTS,
                blocks: [taxSummaryBlock]
              }
            ]
          : [])
      ]
    },
    {
      label: t('disbursements.list-screen.title'),
      items: [
        {
          id: 'disbursement-preferences',
          label: t('disbursements.preferences.block.label'),

          blocks: [
            disbursementsFrequencyBlock,
            ...(hasFeature(FLAGS.OWNERSHIP_INCOME_ATTRIBUTION)
              ? [ownershipDisbursementPaymentMethodsBlockV2]
              : [ownershipDisbursementPaymentMethodsBlock]),
            disbursementWithholdFundsBlock,
            ...(hasFeature(FLAGS.TASK_WITHHELD_FUNDS)
              ? [relatedTaskWithheldFundsBlock]
              : [])
          ]
        },
        {
          id: 'completed-disbursements',
          label: t(
            'disbursements.list-screen.tabs.completed-disbursements.label'
          ),
          blocks: [disbursementTableBlock]
        }
      ]
    }
  ];
};
