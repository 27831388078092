import { query, useEntityQuery } from '@rexlabs/model-generator';
import React from 'react';

import { parseUploadedFileMeta } from 'utils/files';

import { RecordDialog } from 'view/components/record-screen/dialog/dialog';
import { DialogContentConfig } from 'view/components/record-screen/dialog/types';

import { keySetDetailsBlock } from '../blocks/key-set-details-block';
import { keySetsModel } from '../data/key-set-model';

const content: DialogContentConfig = [
  {
    id: 'key-details',
    label: 'Key Details',
    blocks: [keySetDetailsBlock]
  }
];

function getKeySetQuery(id) {
  return query`{
    ${keySetsModel} (id: ${id}) {
      name
      number_of_keys
      notes
      images
      property
      object
    }
  }`;
}

export function EditKeySetDialog({
  keyId,
  onClose,
  handleSubmit
}: { keyId: string } & Pick<
  React.ComponentProps<typeof RecordDialog>,
  'onClose' | 'handleSubmit'
>) {
  const { data: keyData } = useEntityQuery(getKeySetQuery(keyId));

  return (
    <RecordDialog
      initialValues={{
        ...keyData,
        images: parseUploadedFileMeta(keyData?.images?.data)
      }}
      title='Edit key set'
      onClose={onClose}
      content={content}
      handleSubmit={handleSubmit}
      submitLabel='Save'
    />
  );
}
