import React, { useCallback, useMemo } from 'react';

import { Field, HiddenField } from '@rexlabs/form';

import { FlattenedProperty } from 'src/modules/properties/types/property-types';
import { TermLengthSelect } from 'src/modules/property-tenancies/components/term-length-select';

import { Value } from 'view/components/values';
import { DateValue } from 'view/components/values/date';
import { LeaseTypeSelect } from 'view/components/inputs/selects/lease-type';
import { BlockConfig } from 'view/components/record-screen/types';
import { DateInput } from 'view/components/@luna/inputs/date-input/date-input';
import { Column, Grid } from 'view/components/@luna/form/grid';
import { useTranslation } from 'react-i18next';
import { getLeaseAgreementEndDate } from 'src/modules/property-tenancies/utils/get-lease-agreement-end-date';
import { TextArea } from 'view/components/inputs/text-area/text-area';

const fixedTermTypes = ['fixed_term', 'fixed_term_then_periodic'];

export const useGetAgreementDetailsBlock = (): BlockConfig<FlattenedProperty> => {
  const { t } = useTranslation();

  const agreementDetailsBlock: BlockConfig<FlattenedProperty> = useMemo(
    () => ({
      id: 'agreement-details',
      title: 'Agreement details',
      validate: {
        definitions: {
          'selected_property_tenancy.lease_type': {
            name: (t(
              'lease-agreements.details.lease-type.label'
            ) as string).toLowerCase(),
            rules: 'required'
          },

          'selected_property_tenancy.agreement_start_date': {
            name: 'Start date',
            rules:
              'startDateLessThanEndDate:selected_property_tenancy.agreement_end_date'
          },
          'selected_property_tenancy.agreement_end_date': {
            name: 'End date',
            rules: [
              'endDateGreaterThanStartDate:selected_property_tenancy.agreement_start_date',
              'required_if:selected_property_tenancy.lease_type.id,fixed_term,fixed_term_then_periodic'
            ]
          }
        }
      },
      View: ({ data }) => {
        const { t } = useTranslation();

        const isFixed =
          data?.selected_property_tenancy?.lease_type?.id &&
          fixedTermTypes.includes(data.selected_property_tenancy.lease_type.id);

        const agreementLength = data?.selected_property_tenancy?.term_length;

        return (
          <Grid columns={2}>
            <Value
              label={t('lease-agreements.details.lease-type.label')}
              value={data?.selected_property_tenancy?.lease_type?.label}
            />
            <DateValue
              label='Start date'
              value={data?.selected_property_tenancy?.agreement_start_date}
            />
            {isFixed && (
              <Value
                label='Fixed agreement length'
                value={
                  agreementLength === null ? 'Custom' : agreementLength?.label
                }
              />
            )}
            <DateValue
              label='End date'
              value={data?.selected_property_tenancy?.agreement_end_date}
            />
            <DateValue
              label='Signed on'
              value={data?.selected_property_tenancy?.agreement_signed_date}
            />
            <Column width={2}>
              <Value
                label='Additional clauses'
                value={data?.selected_property_tenancy?.additional_clauses}
              />
            </Column>
          </Grid>
        );
      },

      Edit: ({ values, setFieldValue }) => {
        const { t } = useTranslation();

        const isFixed = fixedTermTypes.includes(
          values?.selected_property_tenancy?.lease_type?.id
        );

        const handleLeaseTypeChange = useCallback(
          (e) => {
            const { value } = e.target;
            const customLengthAgreement = ['custom', null].includes(
              values?.selected_property_tenancy?.term_length?.id
            );
            // BE doesn't save the term_length if the value is custom,
            // so we use custom as our initial value if nothing is set.
            // But when initially setting the lease type to fixed (or fixed then periodic),
            // we want it to be 6 months.
            if (
              fixedTermTypes.includes(value?.id) &&
              !fixedTermTypes.includes(
                values?.selected_property_tenancy?.lease_type?.id
              ) &&
              customLengthAgreement
            ) {
              const initialTermLength = { id: 'six_months', label: '6 months' };
              setFieldValue?.(
                'selected_property_tenancy.term_length',
                initialTermLength
              );

              const agreementEndDate = getLeaseAgreementEndDate({
                startDate:
                  values?.selected_property_tenancy?.agreement_start_date,
                termLength: initialTermLength
              });

              setFieldValue?.(
                'selected_property_tenancy.agreement_end_date',
                agreementEndDate
              );
            }
          },
          [
            setFieldValue,
            values?.selected_property_tenancy?.lease_type?.id,
            values?.selected_property_tenancy?.agreement_start_date,
            values?.selected_property_tenancy?.term_length?.id
          ]
        );

        const handleTermLengthChange = useCallback(
          (e) => {
            const { value } = e.target;

            if (
              !fixedTermTypes.includes(
                values?.selected_property_tenancy?.lease_type?.id
              )
            ) {
              return;
            }

            const agreementEndDate = getLeaseAgreementEndDate({
              startDate:
                values?.selected_property_tenancy?.agreement_start_date,
              termLength: value
            });

            setFieldValue?.(
              'selected_property_tenancy.agreement_end_date',
              agreementEndDate
            );
          },
          [
            setFieldValue,
            values?.selected_property_tenancy?.agreement_start_date,
            values?.selected_property_tenancy?.lease_type?.id
          ]
        );

        const handleStartDateChange = useCallback(
          (e) => {
            const { value } = e.target;
            if (
              !fixedTermTypes.includes(
                values.selected_property_tenancy?.lease_type?.id
              ) ||
              values?.selected_property_tenancy?.agreement_end_date
            ) {
              return;
            }
            const agreementEndDate = getLeaseAgreementEndDate({
              startDate: value,
              termLength: values?.selected_property_tenancy?.term_length
            });

            setFieldValue?.(
              'selected_property_tenancy.agreement_end_date',
              agreementEndDate
            );
          },
          [
            setFieldValue,
            values?.selected_property_tenancy?.term_length,
            values.selected_property_tenancy?.lease_type?.id
          ]
        );

        return (
          <Grid columns={2}>
            <Field
              id='lease_type'
              label={t('lease-agreements.details.lease-type.label') as string}
              name='selected_property_tenancy.lease_type'
              Input={LeaseTypeSelect}
              onChange={handleLeaseTypeChange}
              inputProps={{
                shouldReselectClearSelection: false
              }}
            />
            <Field
              label='Start date'
              name='selected_property_tenancy.agreement_start_date'
              Input={DateInput}
              onChange={handleStartDateChange}
            />
            {isFixed ? (
              <Field
                id='term_length'
                label='Fixed agreement length'
                name='selected_property_tenancy.term_length'
                Input={TermLengthSelect}
                inputProps={{
                  shouldReselectClearSelection: false
                }}
                onChange={handleTermLengthChange}
              />
            ) : (
              <HiddenField name={'selected_property_tenancy.term_length'} />
            )}

            <Field
              optional={!isFixed}
              label='End date'
              name='selected_property_tenancy.agreement_end_date'
              Input={DateInput}
            />
            <Field
              label='Signed on'
              name='selected_property_tenancy.agreement_signed_date'
              Input={DateInput}
            />
            <Column width={2}>
              <Field
                label='Additional clauses'
                name='selected_property_tenancy.additional_clauses'
                Input={TextArea}
              />
            </Column>
          </Grid>
        );
      }
    }),
    [t]
  );
  return agreementDetailsBlock;
};
