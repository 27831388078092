import React from 'react';

import CalendarIcon from 'view/components/icons/calendar';
import {
  TitleBlock,
  TitleBlockProps
} from 'view/components/cards/title/title-block';

export function InspectionRunTitleBlock(props: TitleBlockProps) {
  return (
    <TitleBlock
      {...props}
      Icon={() => <CalendarIcon size='xl' data-testid='calendar-icon' />}
    />
  );
}
