import { TOKENS, Tokens } from '@rexlabs/theme-luna';
import { createTokenFn } from '@rexlabs/element-styles';

type TokenValue = number | string;
type GetTokenFunction = (token: any) => TokenValue;

type CssAttributeDescription = {
  cssAttributeNames: string[];
  value: TokenValue;
  baseClassName: string;
  name?: string;
  classSpecifier?: string;
  cssAttributeShort?: string;
};

type BareCssAttributeDescription = Pick<
  CssAttributeDescription,
  'cssAttributeShort' | 'cssAttributeNames' | 'classSpecifier'
>;

function createClassDefinition({
  baseClassName,
  cssAttributeShort,
  name,
  cssAttributeNames,
  value,
  classSpecifier = ''
}: CssAttributeDescription) {
  return `
        .${baseClassName}${cssAttributeShort ?? ''}${
    name ? `-${name}` : ''
  } ${classSpecifier} {${cssAttributeNames
    .map((cssAttributeName) => `${cssAttributeName}: ${value};`)
    .join('')}}
    `;
}

function createTokenClassDefinitions(descriptions: CssAttributeDescription[]) {
  return descriptions.flatMap(createClassDefinition);
}

function createSpacingClassDefinitions(
  baseClassName: string,
  baseCssAttribute: string,
  spacings: Tokens['spacing'],
  getToken: GetTokenFunction
) {
  const cssDescriptions: BareCssAttributeDescription[] = [
    {
      cssAttributeShort: '',
      cssAttributeNames: [baseCssAttribute]
    },
    {
      cssAttributeShort: 't',
      cssAttributeNames: [`${baseCssAttribute}-top`]
    },
    {
      cssAttributeShort: 'r',
      cssAttributeNames: [`${baseCssAttribute}-right`]
    },
    {
      cssAttributeShort: 'b',
      cssAttributeNames: [`${baseCssAttribute}-bottom`]
    },
    {
      cssAttributeShort: 'l',
      cssAttributeNames: [`${baseCssAttribute}-left`]
    },
    {
      cssAttributeShort: 'y',
      cssAttributeNames: [
        `${baseCssAttribute}-top`,
        `${baseCssAttribute}-bottom`
      ]
    },
    {
      cssAttributeShort: 'x',
      cssAttributeNames: [
        `${baseCssAttribute}-left`,
        `${baseCssAttribute}-right`
      ]
    }
  ];

  return cssDescriptions.flatMap((cssAttributeDescription) => {
    return createTokenClassDefinitions(
      Object.keys(spacings).flatMap((name) => ({
        ...cssAttributeDescription,
        baseClassName: baseClassName,
        name,
        value: getToken(`spacing.${name}`)
      }))
    );
  });
}

function createSpaceBetweenClassDefinitions(
  baseClassName: string,
  baseCssAttribute: string,
  spacings: Tokens['spacing'],
  getToken: (token: any) => TokenValue
) {
  const cssDescriptions: BareCssAttributeDescription[] = [
    {
      cssAttributeShort: '-y',
      classSpecifier: '> * + *',
      cssAttributeNames: [
        `${baseCssAttribute}-top`,
        `${baseCssAttribute}-bottom`
      ]
    },
    {
      cssAttributeShort: '-x',
      classSpecifier: '> * + *',
      cssAttributeNames: [
        `${baseCssAttribute}-left`,
        `${baseCssAttribute}-right`
      ]
    }
  ];

  return cssDescriptions.flatMap((cssAttributeDescription) => {
    return createTokenClassDefinitions(
      Object.keys(spacings).flatMap((name) => ({
        ...cssAttributeDescription,
        baseClassName: baseClassName,
        name,
        value: getToken(`spacing.${name}`)
      }))
    );
  });
}

function createFlexClassDefinitions() {
  const descriptions: CssAttributeDescription[] = [
    {
      baseClassName: 'flex',
      cssAttributeNames: ['display'],
      value: 'flex'
    },
    {
      baseClassName: 'justify-start',
      cssAttributeNames: ['justify-content'],
      value: 'flex-start'
    },
    {
      baseClassName: 'justify-end',
      cssAttributeNames: ['justify-content'],
      value: 'flex-end'
    },
    {
      baseClassName: 'justify-center',
      cssAttributeNames: ['justify-content'],
      value: 'flex-center'
    },
    {
      baseClassName: 'justify-between',
      cssAttributeNames: ['justify-content'],
      value: 'space-between'
    },
    {
      baseClassName: 'justify-around',
      cssAttributeNames: ['justify-content'],
      value: 'space-around'
    },
    {
      baseClassName: 'justify-evenly',
      cssAttributeNames: ['justify-content'],
      value: 'flex-evenly'
    },
    {
      baseClassName: 'items-start',
      cssAttributeNames: ['align-items'],
      value: 'flex-start'
    },
    {
      baseClassName: 'items-end',
      cssAttributeNames: ['align-items'],
      value: 'flex-end'
    },
    {
      baseClassName: 'items-center',
      cssAttributeNames: ['align-items'],
      value: 'center'
    },
    {
      baseClassName: 'items-baseline',
      cssAttributeNames: ['align-items'],
      value: 'baseline'
    },
    {
      baseClassName: 'items-stretch',
      cssAttributeNames: ['align-items'],
      value: 'stretch'
    }
  ];

  return createTokenClassDefinitions(descriptions);
}

function createFontWeightClassDefinitions(
  weights: Tokens['typography']['weight'],
  getToken: GetTokenFunction
) {
  return Object.keys(weights).map((weight) =>
    createClassDefinition({
      baseClassName: 'font',
      cssAttributeNames: ['font-weight'],
      value: getToken(`typography.weight.${weight}`),
      name: weight
    })
  );
}

const token = createTokenFn(TOKENS);

export function generateTokenClasses() {
  const marginClasses = createSpacingClassDefinitions(
    'm',
    'margin',
    TOKENS.spacing,
    token
  );

  const paddingClasses = createSpacingClassDefinitions(
    'p',
    'padding',
    TOKENS.spacing,
    token
  );

  const spaceBetweenClasses = createSpaceBetweenClassDefinitions(
    'space',
    'margin',
    TOKENS.spacing,
    token
  );

  const fontWeightClasses = createFontWeightClassDefinitions(
    TOKENS.typography.weight,
    token
  );

  const flexClasses = createFlexClassDefinitions();
  return [
    ...marginClasses,
    ...paddingClasses,
    ...spaceBetweenClasses,
    ...flexClasses,
    ...fontWeightClasses
  ];
}
