import { StyleSheet, useStyles } from '@rexlabs/styling';
import React from 'react';

const defaultStyles = StyleSheet({
  column: {
    gridColumn: 'span 2',
    '@media (max-width: 768px)': {
      gridColumn: 'span 1'
    }
  }
});

export function DoubleWidth(props: React.ComponentProps<'div'>) {
  const s = useStyles(defaultStyles);

  return <div {...props} {...s('column')} />;
}
