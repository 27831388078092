import { ContentConfig } from 'view/components/record-screen/types';
import { securityDepositRequestsBlock } from '../blocks/security-deposit-requests';
import { securityDepositPaymentsBlock } from '../blocks/security-deposit-payments';
import { registrationNumberWarningBlock } from '../blocks/registration-number-warning';

export const content: ContentConfig = [
  {
    label: 'General',
    items: [
      {
        id: 'overview',
        label: 'Overview',
        blocks: [
          registrationNumberWarningBlock,
          securityDepositRequestsBlock,
          securityDepositPaymentsBlock
        ]
      }
    ]
  }
];
