import React, { useMemo } from 'react';
import {
  TitleBlock,
  TitleBlockProps
} from 'view/components/cards/title/title-block';
import { RecordObject, RecordTypes } from 'data/models/types';
import { getRecordTitle } from 'utils/records/get-record-title';
import { formatDate } from 'utils/dates/format';
import QuoteIcon from 'view/components/icons/quote';
import { useStyles, StyleSheet } from '@rexlabs/styling';
import { lowerCase } from 'lodash';
import PropertyIcon from 'view/components/icons/property';
import ContactIcon from 'view/components/icons/contact';
import { TagConfig } from 'view/components/@luna/title-block';
import { useGetQuoteActions } from '../hooks/action-menu-items/use-get-quote-actions';
import { QuoteTask } from '../types/QuoteTask';
import { useQuoteTaskStatusTag } from '../hooks/use-quote-status-tag';

const type = RecordTypes.TaskQuote;

type QuoteTitleBlockProps = {
  quote?: QuoteTask;
};

const styles = StyleSheet({
  subText: {
    color: ({ token }) => token('color.textStyle.body.default'),
    fontWeight: ({ token }) => token('typography.weight.semibold')
  }
});

export function QuoteTitleBlock({ quote }: QuoteTitleBlockProps) {
  const s = useStyles(styles);

  const getQuoteActions = useGetQuoteActions();
  const statusTag: TagConfig = useQuoteTaskStatusTag(quote);

  const title = getRecordTitle({ type, object: quote } as RecordObject);
  const actions = useMemo(() => getQuoteActions(quote), [
    getQuoteActions,
    quote
  ]);

  if (!quote) {
    return null;
  }

  const closed_at = quote.closed_at;

  const subTitles: TitleBlockProps['subTitles'] = [
    {
      Icon: PropertyIcon,
      label: quote.property?.display_name
    },
    {
      Icon: ContactIcon,
      label: quote.details?.supplier.display_name
    }
  ];

  return (
    <TitleBlock
      title={title}
      subTitles={subTitles}
      type={'quote'}
      actions={actions}
      tags={closed_at ? undefined : [statusTag]}
      Icon={() => <QuoteIcon size='xl' />}
      subText={
        closed_at ? (
          <div>
            <span>This quote task has been closed with the status </span>
            <span {...s('subText')}>
              {lowerCase(quote.status?.label)}
            </span> on <span {...s('subText')}>{formatDate(closed_at)}</span>
          </div>
        ) : undefined
      }
    />
  );
}
