import React from 'react';
import { Body } from '@rexlabs/text';

import { BlockConfig } from 'view/components/record-screen/types';

export const activityBlock: BlockConfig = {
  id: 'activity',
  title: 'Activity',
  workInProgress: true,
  View: () => (
    <Body grey as='span'>
      Not implemented yet
    </Body>
  )
};
