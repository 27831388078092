import { Box } from '@rexlabs/box';
import { LinkButton } from '@rexlabs/button';
import { Invoice } from 'data/models/entities/financials/invoices';
import { Ownership } from 'data/models/entities/ownerships';
import { Tenancy } from 'data/models/entities/tenancies';
import React from 'react';
import { SearchResultItem } from 'utils/api/get-search-results';
import AddIcon from 'view/components/icons/add';
import { BlockConfig } from 'view/components/record-screen/types';
import { InvoiceLineItemFormValues } from '../components/invoice-line-item';
import { InvoiceLineItems } from '../components/invoice-line-items';
import { InvoiceLineItemsForm } from '../components/invoice-line-items-form';
import '../utils/invoice-validation-rules';

export type InvoiceLineItemsFormValues = {
  line_items: InvoiceLineItemFormValues[];
};

export const validate = {
  definitions: {
    'line_items.*.description': { name: 'description', rules: 'required' },
    'line_items.*.tax_type': { name: 'tax type', rules: 'required' },
    'line_items.*.amount': { name: 'amount', rules: 'required' },
    'line_items.*.payable_to_chart_of_accounts_account': {
      rules: 'required_if_true:is_payable_to_COA_needed',
      name: 'invoice from account code'
    },
    'line_items.*.payable_to_property': {
      rules: 'required_if_true:is_payable_to_property_needed',
      name: 'invoice from property'
    },
    'line_items.*.payable_by_chart_of_accounts_account': {
      rules: 'required_if_true:is_payable_by_COA_needed',
      name: 'bill to account code'
    },
    'line_items.*.payable_by_property': {
      rules: 'required_if_true:is_payable_by_property_needed',
      name: 'bill to property'
    }
  }
};

export const invoiceLineItemsBlock: BlockConfig<
  Invoice,
  {
    activeTab?: 'invoice' | 'reimbursement';
    setActiveTab: (activeTab: 'invoice' | 'reimbursement') => void;
    hasBillProcessingFeature?: number;
    isFirstChange: boolean;
    handleFirstChange: () => void;
    suggestions?: {
      payableTo?: Array<SearchResultItem<Ownership | Tenancy>>;
      payableBy?: Array<SearchResultItem<Ownership | Tenancy>>;
    };
  }
> = {
  id: 'line-items',
  validate,
  View: ({ data }) => {
    return <InvoiceLineItems data={data} />;
  },
  Edit: function EditLineItems({ forms, blockProps, values, ...props }) {
    const hasLineItems = values.line_items?.length > 0;

    const handleReimbursementInvoice = () => {
      if (!blockProps?.isFirstChange) {
        forms!['reimbursement-details'].setFieldValue(
          'reimbursed_bill_priority',
          forms!['invoice-details'].values.bill_priority
        );

        forms!['reimbursement-details'].setFieldValue(
          'reimbursed_bank_account',
          forms!['invoice-details'].values.bank_account
        );
      }

      blockProps?.handleFirstChange();

      blockProps?.setActiveTab('reimbursement');

      const tab = document.querySelector('a[data-tab-name="reimbursement"]');

      if (!tab) return;

      tab.scrollIntoView({
        behavior: 'smooth',
        block: 'start'
      });
    };

    return (
      <div
        style={{
          display: blockProps?.activeTab === 'invoice' ? 'block' : 'none'
        }}
      >
        <InvoiceLineItemsForm
          {...props}
          blockId='line-items'
          values={values}
          payableBy={forms?.['invoice-details']?.values.payable_by?.object}
          payableTo={forms?.['invoice-details']?.values.payable_to?.object}
          invoiceLineItemColumns={
            blockProps?.hasBillProcessingFeature ? 2 : undefined
          }
        />

        {hasLineItems && (
          <Box justifyContent='flex-end' marginTop={12}>
            <LinkButton IconLeft={AddIcon} onClick={handleReimbursementInvoice}>
              Add reimbursement invoice
            </LinkButton>
          </Box>
        )}
      </div>
    );
  }
};
