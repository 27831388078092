import React from 'react';

import { Field } from '@rexlabs/form';

import { TenancySelect } from 'view/components/inputs/selects/v4-selects/tenancy-select';
import { BlockConfig } from 'view/components/record-screen/types';
import { RecordLinkValue } from 'view/components/values/record-link';
import { Grid } from 'view/components/@luna/form/grid';
import { PropertySelect } from 'view/components/inputs/selects/property-select';

const validate = {
  definitions: {
    property: { rules: 'required' },
    tenancy: { rules: 'required' }
  }
};

export const propertyTenancyOverviewBlock: BlockConfig = {
  id: 'property-tenancy-overview',
  title: 'Overview',
  validate,
  View: ({ data }) => (
    <Grid columns={2}>
      <RecordLinkValue
        label='Property'
        type='property'
        value={data?.property?.name}
        id={data?.property?.id}
      />
      <RecordLinkValue
        label='Tenancy'
        type='tenancy'
        value={data?.tenancy?.name}
        id={data?.tenancy?.id}
      />
    </Grid>
  ),
  Edit: () => (
    <Grid columns={2}>
      <Field label='Property' name='property' Input={PropertySelect} />
      <Field label='Tenancy' name='tenancy' Input={TenancySelect} />
    </Grid>
  )
};
