import React from 'react';

import { BlockConfig } from 'view/components/record-screen/types';
import { useGetPaymentHistoryDownloadAction } from 'src/modules/tenancies/actions/use-get-payment-history-download-action';
import { ActionButtons } from 'view/components/@luna/action-buttons';
import { PaymentHistoryRecordTable } from 'src/modules/payment-history/components/payment-history-record-table';
import { Card } from 'view/components/card';
import { Message } from '@rexlabs/notifications';
import EmptyTable from 'src/assets/illustrations/empty-table.svg';
import { FlattenedProperty } from '../types/property-types';

export const tenantPaymentHistoryBlock: BlockConfig<FlattenedProperty, any> = {
  id: 'tenant-payment-history',
  title: 'Tenant payment history',

  View: ({ data }) => {
    const propertyTenancy = data?.selected_property_tenancy;

    return propertyTenancy?.tenancy ? (
      <PaymentHistoryRecordTable
        Empty={Empty}
        key={propertyTenancy?.property_id}
        object={propertyTenancy?.tenancy}
        filters={[
          {
            field: 'property_id',
            op: 'eq',
            value: data!.id
          }
        ]}
      />
    ) : null;
  },

  Actions: ({ data }) => {
    const getPaymentHistoryDownloadAction = useGetPaymentHistoryDownloadAction();
    const propertyTenancy = data?.selected_property_tenancy;

    return (
      <>
        {propertyTenancy ? (
          <ActionButtons
            actions={[
              getPaymentHistoryDownloadAction({
                tenancy: propertyTenancy?.tenancy,
                propertyTenancy
              })
            ]}
          />
        ) : null}
      </>
    );
  }
};

const Empty = () => {
  return (
    <Card>
      <Message title='Tenant payment history' Illustration={EmptyTable}>
        Any tenant payments related to this lease agreement will be displayed
        here.
      </Message>
    </Card>
  );
};
