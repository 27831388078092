import { inspectionsTableBlock } from '../blocks/inspection-tasks-table-block';
import { inspectionRunsTableBlock } from '../blocks/inspection-runs-table-block';

export const inspectionListContent = [
  {
    label: '',
    items: [
      {
        id: 'all-inspections',
        label: 'All inspections',
        blocks: [inspectionsTableBlock]
      },
      {
        id: 'inspection-runs',
        label: 'Inspection runs',
        blocks: [inspectionRunsTableBlock]
      }
    ]
  }
];
