import React from 'react';
import { InvitationLoginCard } from 'src/modules/auth/user-invitation/components/invitation-login-card';
import LoadingState from 'view/components/states/compact/loading';
import { useRecoilValue } from 'recoil';
import { userInviteQuery } from 'src/modules/auth/user-invitation/atoms/userInviteQuery';

export function UserInvitationLoginScreen({ match }) {
  const token = match?.query?.token;
  const invitation = useRecoilValue(userInviteQuery(token));

  if (!invitation) {
    return <LoadingState />;
  }

  return (
    <>
      <InvitationLoginCard token={token} invitation={invitation} />
    </>
  );
}
