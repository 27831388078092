import React, { FC } from 'react';
import { InputProps } from '@rexlabs/form';

import { useSettings } from 'src/modules/settings/hooks/useSettings';
import { ValueListSelect } from './value-list';

export const BankSelect: FC<InputProps> = (props) => {
  const settingsData = useSettings();

  return (
    <ValueListSelect
      type='known_banks'
      filter={(items) =>
        items.filter((item) => item?.country?.includes(settingsData.country.id))
      }
      {...props}
    />
  );
};
