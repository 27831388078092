import React from 'react';

import {
  ButtonGroupInput,
  ButtonGroupItem,
  ButtonGroupProps
} from 'view/components/@luna/button-group-input';

import { InspectionRoomCondition } from '../types/InspectionRoomCondition';
import {
  InspectionRoomConditionExcludingNone,
  RoomConditionIcon
} from './room-condition-icon';
import { RoomConditionButton } from './room-condition-button';

type RoomConditionItem = { id: InspectionRoomCondition; label: string };

const items: RoomConditionItem[] = [
  { id: 'none', label: 'n/a' },
  { id: 'poor', label: 'Poor' },
  { id: 'fair', label: 'Fair' },
  { id: 'good', label: 'Good' }
];

const normaliser = (
  item: RoomConditionItem
): ButtonGroupItem<RoomConditionItem> => ({
  value: item,
  label: item.label,
  Icon:
    item.id !== 'none'
      ? () => (
          <RoomConditionIcon
            size='m'
            roomCondition={item.id as InspectionRoomConditionExcludingNone}
          />
        )
      : undefined,
  Button: RoomConditionButton
});

export function RoomConditionButtonGroup(
  props: ButtonGroupProps<RoomConditionItem, RoomConditionItem>
) {
  return <ButtonGroupInput items={items} normaliser={normaliser} {...props} />;
}
