import dayjs, { Dayjs } from 'dayjs';

import { Leg } from 'src/lib/mapbox/types/Leg';

import { roundNumberUpToNearestInterval } from 'utils/round-number-up-to-nearest-interval';

import { InspectionTask } from '../types/InspectionTask';
import { InspectionDuration } from '../components/inspection-duration';
import { INSPECTION_SAVE_REQUEST_DATE_FORMAT } from '../constants/InspectionSaveRequestDateFormat';

export function mapRouteToInspectionTask(
  route: Leg[],
  inspections: InspectionTask[],
  getDuration: (inspectionTask: InspectionTask) => InspectionDuration,
  startAt: Dayjs
): InspectionTask[] {
  return inspections.reduce<InspectionTask[]>((acc, inspection, index) => {
    const prevInspection = acc[index - 1] ?? null;

    const start = !prevInspection
      ? startAt
      : dayjs(prevInspection.details!.scheduled_at)
          .tz()
          .add(prevInspection.details!.scheduled_duration!, 'minute');

    return [
      ...acc,
      {
        ...inspection,
        details: {
          ...inspection.details!,
          scheduled_duration:
            inspection.details?.scheduled_duration || getDuration(inspection),
          scheduled_order: index + 1,
          scheduled_at: start
            .add(
              roundNumberUpToNearestInterval(route[index].duration / 60, 5),
              'minutes'
            )
            .format(INSPECTION_SAVE_REQUEST_DATE_FORMAT)
        }
      }
    ];
  }, []);
}
