import { ChartOfAccountsAccount } from 'src/modules/chart-of-accounts/types/chart-of-account-types';

export const normaliseChartOfAccountsAccount = (
  item: ChartOfAccountsAccount
) => {
  return {
    value: item,
    label: item.name,
    type: item.account_category.id
  };
};
