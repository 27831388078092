import { RentScheduleEntry } from 'src/modules/property-tenancies/types/property-tenancy-types';

export function getLineItemsTotal(lineItems: RentScheduleEntry['line_items']) {
  return lineItems!.reduce((total, item) => total + (item.amount ?? 0), 0);
}

export function recalculateAmounts(rentScheduleEntry: RentScheduleEntry) {
  const totalAmount = getLineItemsTotal(rentScheduleEntry.line_items);

  return {
    ...rentScheduleEntry,
    amount_total_inc_tax: totalAmount
  };
}
