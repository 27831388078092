import React, { useCallback } from 'react';

import { RecordDialog } from 'view/components/record-screen/dialog/dialog';
import { DialogContentConfig } from 'view/components/record-screen/dialog/types';

import { RecordSubmitHandler } from 'view/components/record-screen/utils';
import { useModelActions } from '@rexlabs/model-generator';
import {
  AdjustmentStatusChangeActionPayload,
  financialsReconciliationsAdjustmentsModel
} from 'data/models/entities/financials/reconciliations/adjustments';
import { useErrorDialog } from '@rexlabs/dialog';
import { Reconciliation } from 'data/models/entities/financials/reconciliations';
import { resolveAdjustmentBlock } from '../blocks/resolve-adjustment';

const content: DialogContentConfig = [
  {
    id: 'basics',
    label: 'Basics',
    blocks: [resolveAdjustmentBlock]
  }
];

interface CaptureAdjustmentStatusChangeReasonProps {
  title: React.ReactNode;
  submitLabel: React.ReactNode;
  reconciliation: Reconciliation;
  adjustmentId: string;
  updateListId?: string;
  onClose?: () => void;
  handleSubmit: (payload: AdjustmentStatusChangeActionPayload) => Promise<void>;
}

export function CaptureAdjustmentStatusChangeReason({
  reconciliation,
  adjustmentId,
  updateListId,
  onClose,
  handleSubmit,
  title,
  submitLabel
}: CaptureAdjustmentStatusChangeReasonProps) {
  const { refreshList } = useModelActions(
    financialsReconciliationsAdjustmentsModel
  );
  const { open: openErrorDialog } = useErrorDialog();

  const onSubmit: RecordSubmitHandler = useCallback(
    async ({ values }) => {
      try {
        await handleSubmit({
          ...values,
          reconciliationId: reconciliation.id,
          adjustmentId
        });
      } catch (e) {
        openErrorDialog(e);
      }

      if (updateListId) {
        await refreshList({ id: updateListId });
      }

      return true;
    },
    [
      adjustmentId,
      handleSubmit,
      openErrorDialog,
      reconciliation.id,
      refreshList,
      updateListId
    ]
  );

  const initialData = {
    date_of: reconciliation.statement_date
  };

  return (
    <RecordDialog
      autofocusIndex={1}
      data={initialData}
      onClose={onClose}
      title={title}
      handleSubmit={onSubmit}
      content={content}
      submitLabel={submitLabel}
    />
  );
}
