import React, { useCallback, useMemo } from 'react';

import { toQuri } from '@rexlabs/quri';
import { query, useEntityListQuery } from '@rexlabs/model-generator';

import { RecordDialog } from 'view/components/record-screen/dialog/dialog';
import { DialogContentConfig } from 'view/components/record-screen/dialog/types';
import { RecordSubmitHandler } from 'view/components/record-screen/utils';

import { useSettings } from 'src/modules/settings/hooks/useSettings';
import {
  PropertyTenancy,
  RentScheduleEntry
} from 'src/modules/property-tenancies/types/property-tenancy-types';
import { chartOfAccountsAccountModel } from 'src/modules/chart-of-accounts/models/chart-of-accounts';

import { createRentScheduleEntryDetailsBlock } from '../blocks/create-rent-schedule-entry-details';
import { rentScheduleEntryLineItemsBlock } from '../blocks/rent-schedule-entry-line-items';

const content: DialogContentConfig = [
  {
    id: 'basics',
    label: 'Basics',
    blocks: [
      createRentScheduleEntryDetailsBlock,
      rentScheduleEntryLineItemsBlock
    ]
  }
];

interface CreateRentScheduleDialogProps {
  isFirstEntry?: boolean;
  firstAgreementStartDate?: string;
  onClose?: () => void;
  onCreate?: (item: RentScheduleEntry) => void;
  rentSchedule?: PropertyTenancy['rent_schedule'];
}

const queryFilter = (name: string) =>
  toQuri([
    {
      field: 'name',
      op: '==',
      value: name
    }
  ]);

function getChartOfAccountsAccountsByNameQuery(name: string) {
  return query`{
    ${chartOfAccountsAccountModel} (q: ${queryFilter(name)}) {
      id
      name
    }
  }`;
}

export function CreateRentScheduleDialog({
  isFirstEntry,
  firstAgreementStartDate,
  onClose,
  onCreate,
  rentSchedule
}: CreateRentScheduleDialogProps) {
  const settingsData = useSettings();
  const daysInAdvance =
    settingsData['days-in-advance-to-generate-rent-invoices'];

  const rentIncomeAccountQuery = useMemo(
    () => getChartOfAccountsAccountsByNameQuery('Rent Income'),
    []
  );

  const {
    data: chartOfAccountsAccounts,
    status: chartOfAccountsAccountsListLoading
  } = useEntityListQuery(rentIncomeAccountQuery);

  const handleSubmit: RecordSubmitHandler = useCallback(
    async ({ values }) => {
      onCreate?.(values);
      return true;
    },
    [onCreate]
  );

  const initialChartOfAccountsAccount = chartOfAccountsAccounts?.find(
    (coaAccount) => coaAccount.name === 'Rent Income'
  );

  const rentScheduleItem = rentSchedule?.find(
    (item) => item.status.id === 'current'
  );

  const initialData = {
    effective_from_date: firstAgreementStartDate,
    is_tax_included: true,
    frequency: rentScheduleItem?.frequency,
    days_in_advance_to_post:
      rentScheduleItem?.days_in_advance_to_post ?? daysInAdvance,
    line_items: [
      {
        description: 'Rent',
        tax_type: initialChartOfAccountsAccount?.default_tax_type,
        payable_to_chart_of_accounts_account: initialChartOfAccountsAccount
      }
    ]
  };

  return (
    <RecordDialog
      onClose={onClose}
      isLoading={chartOfAccountsAccountsListLoading === 'loading'}
      data={initialData}
      title='Add rent charge'
      handleSubmit={handleSubmit}
      content={content}
      submitLabel='Add rent charge'
      blockProps={{ isFirstEntry }}
    />
  );
}
