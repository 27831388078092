import React from 'react';
import { InputProps } from '@rexlabs/form';
import { ValueListRadios } from '.';

export function SecurityDepositPaymentTypeRadioGroup(
  props: Omit<InputProps, 'meta'>
) {
  return (
    <ValueListRadios {...props} valueListId='security_deposit_payment_type' />
  );
}
