import React from 'react';
import { useTranslation } from 'react-i18next';
import { SummaryCell } from 'src/modules/tasks/maintenance/components/summary-cell';
import { MaintenanceTask } from 'src/modules/tasks/maintenance/types/MaintenanceTask';
import { Columns } from 'src/view/components/table';
import { getViewAction } from 'utils/actions/get-view-action';
import { LinkButtonCell } from 'view/components/cells/link-button-cell';

export function useWithheldFundsColumns(): Columns<MaintenanceTask> {
  const { t } = useTranslation();
  return [
    { id: 'task', Header: 'Task', accessor: (item) => item, Cell: SummaryCell },
    {
      id: 'details.disbursement_withheld_funds.amount',
      Header: t(
        'withheld-funds.task-withheld-funds-table.columns.amount-header'
      ) as string,
      type: 'currency',
      cellProps: { align: 'right' }
    },
    {
      id: 'view',
      width: 100,
      accessor: (item) => item,
      Cell: ({ value }) => {
        const viewAction = getViewAction(
          'view',
          'primary-record',
          { id: value.id },
          'task_maintenance',
          'View task'
        );

        return <LinkButtonCell action={viewAction} />;
      }
    }
  ];
}
