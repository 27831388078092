import { Columns, ListTable, TabbedTable } from 'view/components/table';
import { Contact } from 'src/modules/contacts/types/contact-types';
import { TagCell } from '@rexlabs/table';
import { StatusGoodTag } from '@rexlabs/tag';
import React, { useCallback, useMemo } from 'react';
import { useContactActions } from 'src/modules/contacts/actions/use-contact-actions';
import { useTableFilters } from 'view/hooks/use-table-filters';
import { getContactListQuery } from 'src/modules/contacts/queries/contact-list-query';
import { transformActionDeclarationsToActionMenuItems } from 'utils/actions/transforms';
import ROUTES from 'routes/app';
import { auditableColumns } from 'src/modules/common/auditable/auditable-columns';
import { Tab } from 'view/components/table/tabbed';
import { useGetBulkMessageActions } from 'src/modules/communications/messages/hooks/action-declarations/use-get-bulk-message-actions';
import { ActionDeclaration } from 'src/modules/common/actions/types/action-declaration-types';
import { useFeatureFlags } from 'view/components/@luna/feature-flags';
import { FLAGS } from 'utils/feature-flags';
import { useMediaQuery } from '@rexlabs/breakpoints';
import { useTranslation } from 'react-i18next';
import { TwoTierCell } from 'view/components/cells/two-tier-cell';
import { contactsModel } from '../models/contacts';
import { ContactIconCell } from './contact-icon-cell';

const useColumns = (): Columns<Contact> => {
  const { t } = useTranslation();

  return [
    {
      id: 'icon',
      width: 50,
      accessor: (item) => item,
      Cell: ContactIconCell
    },
    {
      id: 'display_name',
      Header: 'Name',
      width: 350,
      accessor: (item) => item,
      Cell: ({ value }) => (
        <TwoTierCell
          text={value.display_name}
          subtext={value.record_reference}
        />
      ),
      toCsv: (item) => `${item.display_name} ${item.record_reference}`
    },
    {
      Header: 'Phone number',
      accessor: (item) => item.primary_phone?.phone_number,
      Cell: ({ value }) => {
        return (
          <a
            href={`tel:${value}`}
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            {value}
          </a>
        );
      },
      width: 260
    },
    {
      id: 'email_address',
      Header: 'Email address',
      accessor: (item) => item.primary_email?.email_address,
      width: 260
    },
    {
      Header: t(
        'financials.trust.available-trust-balance.label.singular'
      ) as string,
      accessor: (item) => item.trust_summary?.available_balance_amount,
      type: 'currency',
      cellProps: {
        align: 'right'
      },
      width: 260
    },
    {
      id: 'roles',
      Header: 'Role(s)',
      Cell: (table) => {
        const {
          cell: { value }
        } = table;
        return (
          <TagCell>
            {value.map((role) => (
              <StatusGoodTag key={role.id}>{role.label}</StatusGoodTag>
            ))}
          </TagCell>
        );
      }
    },

    {
      id: 'primary_address',
      Header: 'Primary Address',
      accessor: (item) => item.primary_address?.address,
      width: 300
    },
    ...auditableColumns
  ];
};

const getRowLinkProps = ({ item }) => ({
  to: ROUTES.CONTACT,
  params: { contactId: item?.id }
});

export function ContactsTable() {
  const isSmallScreen = useMediaQuery({ maxWidth: 'xl' });
  const { hasFeature } = useFeatureFlags();
  const contactActions = useContactActions();
  const getBulkMessageActions = useGetBulkMessageActions(contactsModel);
  const columns = useColumns();

  const { getSort, getFilters } = useTableFilters('contacts');

  const getBulkActions = useCallback(
    (args) => {
      const actions: ActionDeclaration[] = [];
      if (hasFeature(FLAGS.BULK_MESSAGES)) {
        actions.push(...getBulkMessageActions(args.selectedItems));
      }
      return actions;
    },
    [getBulkMessageActions, hasFeature]
  );

  const tabs = useMemo<Tab[]>(() => {
    const commonProps = {
      id: 'contacts-table',
      getBulkActions,
      Table: ListTable,
      alwaysExpandSearch: true,
      getSort,
      getFilters,
      initialHiddenColumns: [
        'created_by',
        'created_at',
        'updated_at',
        'updated_by',
        'roles',
        'primary_address',
        ...(isSmallScreen ? ['email_address'] : [])
      ],
      columns,
      getQuery: getContactListQuery,
      getActionMenuItems: ({ item }) =>
        transformActionDeclarationsToActionMenuItems(contactActions(item)),
      getRowLinkProps,
      suggestedFilters: ['roles', 'status_id']
    };

    return [
      {
        ...commonProps,
        forcedGlobalFilter: [
          {
            field: 'status_id',
            op: 'eq',
            value: 'active'
          }
        ],
        name: 'all',
        label: 'All'
      },
      {
        ...commonProps,
        forcedGlobalFilter: [
          {
            field: 'roles',
            op: 'in',
            value: ['supplier']
          }
        ],
        name: 'supplier',
        label: 'Supplier'
      },
      {
        ...commonProps,
        forcedGlobalFilter: [
          {
            field: 'roles',
            op: 'in',
            value: ['tenant']
          }
        ],
        name: 'tenant',
        label: 'Tenant'
      },
      {
        ...commonProps,
        forcedGlobalFilter: [
          {
            field: 'roles',
            op: 'in',
            value: ['owner']
          }
        ],
        name: 'owner',
        label: 'Owner'
      },
      {
        ...commonProps,
        forcedGlobalFilter: [
          {
            field: 'roles',
            op: 'in',
            value: ['security_deposit_authority', 'tax_authority']
          }
        ],
        name: 'authority',
        label: 'Authority'
      },
      {
        ...commonProps,
        forcedGlobalFilter: [
          {
            field: 'status_id',
            op: 'eq',
            value: 'archived'
          }
        ],
        name: 'archived',
        label: 'Archived'
      }
    ];
  }, []);

  return <TabbedTable tabs={tabs} />;
}
