import { Select } from '@rexlabs/select';
import { ContactPaymentMethod } from 'data/models/entities/contacts/payment-methods';
import * as React from 'react';

export interface DisbursementInstructionInputProps {
  items: ContactPaymentMethod[];
}

export const USE_DISBURSEMENT_PREFERENCES = {
  id: 'use_disbursement_preferences' as const,
  label: 'Use disbursement preference'
} as const;

type UseDisbursementPreferences = typeof USE_DISBURSEMENT_PREFERENCES;

// we want to inject our own value here, so just wrap the existing normaliser
const normalisePaymentMethod = (
  item: ContactPaymentMethod | UseDisbursementPreferences['id']
) => {
  if (item === 'use_disbursement_preferences') {
    return USE_DISBURSEMENT_PREFERENCES;
  }

  return {
    id: item.id,
    label: item.name
  };
};

export function DisbursementInstructionInput({
  items = [],
  ...props
}: DisbursementInstructionInputProps) {
  const options: Array<
    ContactPaymentMethod | UseDisbursementPreferences['id']
  > = [...items, USE_DISBURSEMENT_PREFERENCES.id];

  return (
    <Select {...props} items={options} normaliser={normalisePaymentMethod} />
  );
}
