import { makeIconComponent } from '@rexlabs/icons';

import ComplianceMediumSvg from 'src/assets/icons/m/compliance.svg';
import ComplianceLargeSvg from 'src/assets/icons/l/compliance.svg';
import ComplianceExtraLargeSvg from 'src/assets/icons/xl/compliance.svg';

export const ComplianceIcon = makeIconComponent({
  m: ComplianceMediumSvg,
  l: ComplianceLargeSvg,
  xl: ComplianceExtraLargeSvg
});

export default ComplianceIcon;
