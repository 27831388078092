import { StatusTagMap } from 'src/modules/common/components/status-tag';
import { PropertyTenancyStatusType } from 'src/modules/property-tenancies/types/property-tenancy-types';

export const propertyTenancyStatusMap: StatusTagMap<PropertyTenancyStatusType> = {
  active: 'good',
  incoming: 'average',
  draft: 'average',
  outgoing: 'average',
  archived: 'bad'
};
