import { DialogProps } from '@rexlabs/dialog';
import * as React from 'react';
import { invokeActionDeclaration } from 'src/modules/common/actions/utils/invoke-action-declaration';
import { RecordDialog } from 'view/components/record-screen/dialog/dialog';
import { fixChannelMessageRecipientIssuesBlock } from '../blocks/fix-channel-message-recipient-issues-block';
import { useGetResendMessageAction } from '../hooks/action-declarations/use-get-resend-message-action';
import { ValidationType } from '../models/messages-model';
import { ChannelMessageRecipient } from '../types/ChannelMessageRecipient';

export interface FixChannelMessageRecipientIssuesRecordDialogProps
  extends DialogProps {
  type: ValidationType;
  data: {
    channelMessageRecipient: ChannelMessageRecipient;
  };
}

const content = [
  {
    id: 'fix-channel-message-recipient-issues',
    label: 'Fix contact issues',
    blocks: [fixChannelMessageRecipientIssuesBlock]
  }
];

export function FixChannelMessageRecipientIssuesRecordDialog({
  type,
  onClose,
  data
}: FixChannelMessageRecipientIssuesRecordDialogProps) {
  const { channelMessageRecipient } = data;
  const getResendMessageAction = useGetResendMessageAction();

  const handleSubmit = async () => {
    // resend message
    invokeActionDeclaration(getResendMessageAction, channelMessageRecipient);

    onClose?.();
  };

  return (
    <RecordDialog
      title='Fix message issues'
      submitLabel={'Resend message'}
      data={{
        channelMessageRecipient
      }}
      blockProps={{ type }}
      content={content}
      onClose={onClose}
      handleSubmit={handleSubmit}
    />
  );
}
