import { useEffect } from 'react';

export function usePagedReviewerKeybindings({
  handlePrevious,
  handleNext
}: {
  handlePrevious: () => void;
  handleNext: () => void;
}) {
  useEffect(() => {
    const keyHandlers: { [key: string]: () => void } = {
      arrowleft: handlePrevious,
      arrowright: handleNext
    };

    const handleKeyDown = async (event: KeyboardEvent) => {
      const key = event.key.toLowerCase();
      const handler = keyHandlers[key];

      if (handler) {
        handler();
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [handlePrevious, handleNext]);
}
