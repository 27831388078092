import { useModelActions } from '@rexlabs/model-generator';
import {
  Ownership,
  ownershipsModel,
  OwnershipStatus
} from 'data/models/entities/ownerships';
import { useDeleteAction } from 'src/modules/common/actions/delete/use-delete-action';
import { useEditRecordDetailsAction } from 'src/modules/common/actions/record-details/hooks/use-edit-record-details-action';
import { useStatusChangeAction } from 'src/modules/common/actions/status/use-status-change-action';
import { ActionDeclaration } from 'src/modules/common/actions/types/action-declaration-types';
import { useGetCreateEmailAction } from 'src/modules/communications/messages/hooks/action-declarations/use-get-create-email-action';
import { useGetDisbursableEntityActionGroup } from 'src/modules/disbursements/hooks/use-get-disbursable-entity-action-group';
import { useGetFinancialEntityActionGroup } from 'src/modules/financials/utils/financial-entity-action-group/use-get-financial-entity-actions';
import { useGetCreateNoteAction } from 'src/modules/note/hooks/use-get-create-note-action';
import { useGetCreatePropertyOwnershipAction } from 'src/modules/property-ownerships/actions/use-get-create-property-ownership-action';
import { useGetAccountingEntityActionGroup } from 'src/modules/trust-journal-entries/utils/use-get-accounting-entity-action-group';
import { useGetPrimaryRecordActionGroup } from 'view/hooks/actions/use-get-primary-record-action-group';
import { getAllIncludesStringFromModel } from 'utils/api/get-all-includes-string-from-model';
import { useGetCreateSMSAction } from 'src/modules/communications/messages/hooks/action-declarations/use-get-create-sms-action';

export function useOwnershipActions() {
  const { refreshItem } = useModelActions(ownershipsModel);

  const getPrimaryRecordActionGroup = useGetPrimaryRecordActionGroup();
  const getCreatePropertyOwnershipAction = useGetCreatePropertyOwnershipAction();
  const getDisbursableEntityActionGroup = useGetDisbursableEntityActionGroup();
  const getFinancialEntityActionGroup = useGetFinancialEntityActionGroup();
  const getAccountingEntityActionGroup = useGetAccountingEntityActionGroup();
  const statusChangeAction = useStatusChangeAction(ownershipsModel);
  const deleteAction = useDeleteAction(ownershipsModel);
  const editRecordDetails = useEditRecordDetailsAction(ownershipsModel);
  const getCreateNoteAction = useGetCreateNoteAction(ownershipsModel);
  const getCreateEmailAction = useGetCreateEmailAction(ownershipsModel);
  const getCreateSMSAction = useGetCreateSMSAction(ownershipsModel);

  const getActions = (ownership?: Ownership) => {
    if (!ownership) return [];

    const statusChangeActions: ActionDeclaration[] = [];

    const setAsActiveAction = statusChangeAction<OwnershipStatus>({
      record: ownership,
      status: 'active'
    });

    const setAsArchivedAction = statusChangeAction<OwnershipStatus>({
      record: ownership,
      status: 'archived'
    });

    switch (ownership.status.id) {
      case 'active':
        statusChangeActions.push(setAsArchivedAction);
        break;
      case 'archived':
        statusChangeActions.push(setAsActiveAction);
        break;
    }

    return [
      ...getPrimaryRecordActionGroup('ownership', ownership.id),
      ...getFinancialEntityActionGroup({
        actionArgs: {
          recordTypeName: 'ownership',
          entity: ownership,
          onCreate: async () =>
            refreshItem({
              id: ownership.id,
              args: { include: getAllIncludesStringFromModel(ownershipsModel) }
            })
        }
      }),
      editRecordDetails(ownership),
      getCreateNoteAction({ attached: ownership }),
      getCreateEmailAction(ownership),
      getCreateSMSAction(ownership),
      getCreatePropertyOwnershipAction({ ownership }),
      ...getDisbursableEntityActionGroup(),
      ...getAccountingEntityActionGroup(),
      ...statusChangeActions,
      deleteAction(ownership)
    ];
  };

  return getActions;
}
