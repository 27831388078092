import { useQuery } from 'react-query';
import { AxiosResponse } from 'axios';

import { api } from 'utils/api/api-client';
import { ValueListItem } from 'utils/normaliser/value-list-item';

type ValueListResponse<TRecord> = {
  items: TRecord[];
};

type ValueListKey = string;

export function fetchRecords(uri: string) {
  return api
    .get(uri)
    .then(
      (res: AxiosResponse<ValueListResponse<ValueListItem>>) => res.data.items
    );
}

export function useValueListQuery(valueListKey: ValueListKey) {
  const queryKey = `value-lists/${valueListKey}`;

  const query = useQuery(queryKey, () => fetchRecords(queryKey), {
    cacheTime: Infinity,
    staleTime: Infinity,
    enabled: !!valueListKey
  });

  return query;
}
