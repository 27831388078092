import { ActionDeclaration } from 'src/modules/common/actions/types/action-declaration-types';
import { push } from '@rexlabs/whereabouts';
import ROUTES from 'routes/app';
import { TrustJournalEntry } from 'src/modules/trust-journal-entries/types/trust-journal-entry-types';

export function useGetViewReversalEntryAction() {
  return (trustJournalEntry: TrustJournalEntry): ActionDeclaration => {
    return {
      id: 'view-reversal-entry',
      group: 'trust-journal-entry',
      label: 'View receipt reversal',
      handleAction: () => {
        push(ROUTES.TRUST_JOURNAL_ENTRY, {
          params: {
            trustJournalEntryId: trustJournalEntry.reversed_by?.id
          }
        });
      }
    };
  };
}
