import React, { ComponentProps, ComponentType } from 'react';

import PriorityEmergencyIcon from 'view/components/icons/priority-emergency';
import PriorityNormalIcon from 'view/components/icons/priority-normal';
import PriorityUrgentIcon from 'view/components/icons/priority-urgent';
import PriorityPreventativeIcon from 'view/components/icons/priority-preventative';

import { TaskPriority } from 'src/modules/tasks/common/types/TaskPriority';

type PriorityMap = Record<TaskPriority, ComponentType<ComponentProps<'svg'>>>;

const priorityMap: PriorityMap = {
  emergency: PriorityEmergencyIcon,
  urgent: PriorityUrgentIcon,
  normal: PriorityNormalIcon,
  preventative: PriorityPreventativeIcon
};

interface TaskPriorityIconProps {
  priority: TaskPriority;
}

export function TaskPriorityIcon({ priority }: TaskPriorityIconProps) {
  const Icon = priorityMap[priority];
  return <Icon />;
}
