import React from 'react';

import { useConfirmationDialog } from '@rexlabs/dialog';
import { useToken } from '@rexlabs/styling';
import Box from '@rexlabs/box';

import { SingleActionDeclaration } from 'src/modules/common/actions/types/action-declaration-types';
import { pluralize } from 'utils/formatters';

import InfoCircleIcon from 'view/components/icons/info';
import { ErrorBanner } from 'view/components/@luna/notifications/banner';

import { getFinancialYearAbbreviationString } from '../utils/get-financial-year-abbreviation-string';
import { getQuartersForFinancialYear } from '../utils/get-quarters-for-financial-year';
import { TaxSummary } from '../models/tax-summaries-model';
import { useGetFinancialYearStartAndEnd } from './use-get-financial-year-start-and-end';
import { useGetCreateTaxPaymentSubmitHandler } from './use-get-create-tax-payment-submit-handler';

export function useGetCreateTaxPaymentBulkAction() {
  const { open } = useConfirmationDialog();
  const getFinancialYearStartAndEnd = useGetFinancialYearStartAndEnd();
  const token = useToken();
  const getSubmitHandler = useGetCreateTaxPaymentSubmitHandler();
  return ({
    selectedItems
  }: {
    selectedItems: TaxSummary[];
  }): SingleActionDeclaration => {
    const taxPaymentsWithShortfall = selectedItems.filter(
      (taxPaymentsSummary) => taxPaymentsSummary.shortfall
    );

    const { startDate, endDate } = getFinancialYearStartAndEnd(
      selectedItems[0].start_date
    );
    const financialYearQuarters = getQuartersForFinancialYear(startDate);

    const currentQuarter =
      financialYearQuarters.findIndex(
        ({ quarterStartDate, quarterEndDate }) =>
          selectedItems[0].start_date === quarterStartDate &&
          selectedItems[0].end_date === quarterEndDate
      ) + 1;

    const message = (
      <Box gap={token('spacing.xl')} flexDirection='column'>
        {taxPaymentsWithShortfall.length ? (
          <ErrorBanner
            Icon={InfoCircleIcon}
            description={`${pluralize(
              'owner',
              taxPaymentsWithShortfall.length,
              true
            )} ${pluralize(
              'has',
              taxPaymentsWithShortfall.length
            )} a shortfall of tax withheld. Payment can still be issued, but it will be less than the amount owed.`}
          />
        ) : null}
        <p>
          Please confirm that you want to issue payments to HMRC for NRLQ{' '}
          {currentQuarter}{' '}
          {getFinancialYearAbbreviationString(startDate, endDate)} for{' '}
          <b>{pluralize('contact', selectedItems.length, true)}</b>. This will
          create a withdrawal trust journal entry for the tax amount due, from
          the funds that are being withheld for this ownership.
        </p>
      </Box>
    );

    const label = `Issue ${pluralize('payment', selectedItems.length)}`;
    const submitHandler = getSubmitHandler(selectedItems);
    return {
      label,
      handleAction: () =>
        open({
          confirmText: label,
          title: 'Issue payment to HMRC',
          onConfirm: submitHandler,
          message
        })
    };
  };
}
