import React from 'react';
import { Select } from '@rexlabs/select';

type Item = {
  id: string;
  label: string;
  value: boolean;
};

const items: Item[] = [
  { label: 'True', value: true, id: 'true' },
  { label: 'False', value: false, id: 'false' }
];

export function BooleanInput(props) {
  return (
    <Select
      items={items}
      {...props}
      searchable={false}
      normaliser={(item: Item) => {
        return {
          id: item.id,
          label: item.label
        };
      }}
    />
  );
}
