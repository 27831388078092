import * as React from 'react';
import { fragment, query } from '@rexlabs/model-generator';
import { Columns, ListTable, TabbedTable } from 'view/components/table';
import { LinkProps, useWhereabouts } from '@rexlabs/whereabouts';
import { useDialog } from '@rexlabs/dialog';
import { useTableFilters } from 'view/hooks/use-table-filters';
import { GlobalFilter } from '@rexlabs/table/lib/types/context';
import { BulkAction } from '@rexlabs/table/lib/types/bulk-actions/simple';
import { messagesModel } from '../../models/messages-model';
import { messageColumns } from '../../utils/message-columns';
import { useGetLetterPrintedActions } from '../../hooks/action-menu-items/use-get-letter-printed-actions';
import { useGetBulkPrintLetterAction } from '../../hooks/action-declarations/use-get-bulk-print-letter-action';
import { messageBatchesModel } from '../../models/message-batches-model';
import { messageBatchColumns } from '../../utils/message-batch-columns';
import { useGetLetterReadyActions } from '../../hooks/action-menu-items/use-get-letter-ready-actions';
import { PreviewLetterRecordDialog } from '../../dialogs/preview-letter-record-dialog';
import { Message } from '../../types/Message';
import { useGetLetterOutboxActions } from '../../hooks/action-menu-items/use-get-letter-outbox-actions';
import { useLetterTableEmptyState } from './letter-table-empty-state';

export const messageFragment = fragment`
  id,
  context,
  channels,
  inline_attachments,
  linked_files,
  recipients
  send_from_author {
    contact
  }
  template
  documents {
    file
  }
`;

export const messageQuery = query`{
  ${messagesModel} {
    ${messageFragment}
  }
}`;

const messageBatchesQuery = query`{
  ${messageBatchesModel} {
    id,
    messages {
      ${messageFragment}
    }
    documents {
      file
    }
  }
}`;

export function LettersTabbedTable({
  forcedGlobalFilters,
  messageHiddenColumns,
  channelMessageRecipientHiddenColumns,
  TabEmptyState
}: {
  forcedGlobalFilters?: GlobalFilter[];
  messageHiddenColumns?: Array<string>;
  channelMessageRecipientHiddenColumns?: Array<string>;
  TabEmptyState?: ({
    label
  }: {
    label: 'Outbox' | 'Processing' | 'Sent' | 'Bounced';
  }) => React.ReactElement;
}) {
  const whereabouts = useWhereabouts();
  const getMessageOutboxActions = useGetLetterOutboxActions();
  const getLetterPrintedActions = useGetLetterPrintedActions();
  const getLetterReadyActions = useGetLetterReadyActions();
  const getBulkPrintLetterAction = useGetBulkPrintLetterAction();

  const { open: openLetterPreviewDialog } = useDialog(
    PreviewLetterRecordDialog
  );

  const messagesFilters = useTableFilters('messages');
  const messageBatchesFilters = useTableFilters('message-batches');

  const getViewRowLinkProps = React.useCallback(
    ({ item }): LinkProps => {
      const hash = whereabouts.hash;
      const query = whereabouts.query;

      return {
        hash,
        query,
        onClick: () => {
          openLetterPreviewDialog({ data: { message: item } });
        }
      };
    },
    [openLetterPreviewDialog, whereabouts.hash, whereabouts.query]
  );

  const getPreviewRowLinkProps = React.useCallback(
    ({ item }): LinkProps => {
      const hash = whereabouts.hash;
      const query = whereabouts.query;

      return {
        hash,
        query,
        onClick: () => {
          openLetterPreviewDialog({ data: { message: item } });
        }
      };
    },
    [openLetterPreviewDialog, whereabouts.hash, whereabouts.query]
  );

  const getMessagesQuery = React.useCallback(() => messageQuery, []);
  const getMessageBatchesQuery = React.useCallback(
    () => messageBatchesQuery,
    []
  );

  const DefaultLetterTableEmptyState = useLetterTableEmptyState();

  const EmptyState = ({ label }) =>
    TabEmptyState ? (
      <TabEmptyState label={label} />
    ) : (
      <DefaultLetterTableEmptyState label={label} />
    );

  const getBulkOutboxActions = React.useCallback(
    (args) => {
      return [getBulkPrintLetterAction(args.selectedItems) as BulkAction];
    },
    [getBulkPrintLetterAction]
  );

  const tabs = React.useMemo(() => {
    const commonProps = {
      id: 'message',
      Table: ListTable,
      columns: messageBatchColumns,
      forcedGlobalFilters: [
        {
          field: 'channel_type_id',
          op: 'in',
          value: ['letter']
        }
      ],
      getFilters: messageBatchesFilters.getFilters,
      getSort: messageBatchesFilters.getSort,
      // Most of the tabs are actual message recipients, so we will set this on the common props
      getQuery: getMessageBatchesQuery,
      initialSortBy: [{ id: 'created_at', desc: true }],
      initialHiddenColumns: channelMessageRecipientHiddenColumns
        ? channelMessageRecipientHiddenColumns
        : []
    };

    return [
      {
        ...commonProps,
        name: 'outbox',
        label: 'Outbox',
        getQuery: getMessagesQuery,
        columns: removeNonLetterColumns(messageColumns),
        getActionMenuItems: ({ item }) => getMessageOutboxActions(item),
        getBulkActions: getBulkOutboxActions,
        getRowLinkProps: getPreviewRowLinkProps,
        getFilters: messagesFilters.getFilters,
        getSort: messagesFilters.getSort,
        initialSortBy: [{ id: 'updated_at', desc: true }],
        forcedGlobalFilter: [
          ...commonProps.forcedGlobalFilters,
          {
            field: 'status_id',
            op: 'eq',
            value: { value: 'draft', label: 'Draft' }
          },
          ...(forcedGlobalFilters ? forcedGlobalFilters : [])
        ],
        Empty: () => <EmptyState label={'Outbox'} />,
        initialHiddenColumns: messageHiddenColumns ? messageHiddenColumns : []
      },
      {
        ...commonProps,
        name: 'processing',
        label: 'Processing',
        getActionMenuItems: undefined,
        forcedGlobalFilter: [
          ...commonProps.forcedGlobalFilters,
          {
            field: 'ready_at',
            op: 'eq',
            value: 'null'
          },
          ...(forcedGlobalFilters ? forcedGlobalFilters : [])
        ],
        Empty: () => <EmptyState label={'Processing'} />
      },
      {
        ...commonProps,
        name: 'ready',
        label: 'Ready',
        getActionMenuItems: ({ item }) => getLetterReadyActions(item),
        forcedGlobalFilter: [
          ...commonProps.forcedGlobalFilters,
          {
            field: 'ready_at',
            op: 'neq',
            value: 'null'
          },
          {
            field: 'downloaded_at',
            op: 'eq',
            value: 'null'
          },
          ...(forcedGlobalFilters ? forcedGlobalFilters : [])
        ],
        Empty: () => <EmptyState label={'Ready'} />
      },
      {
        ...commonProps,
        name: 'sent-all',
        label: 'Printed',
        getActionMenuItems: ({ item }) => getLetterPrintedActions(item),
        forcedGlobalFilter: [
          ...commonProps.forcedGlobalFilters,
          {
            field: 'downloaded_at',
            op: 'neq',
            value: 'null'
          },
          ...(forcedGlobalFilters ? forcedGlobalFilters : [])
        ],
        Empty: () => <EmptyState label={'Printed'} />
      }
    ];
  }, [
    messageBatchesFilters.getFilters,
    messageBatchesFilters.getSort,
    getMessageBatchesQuery,
    getMessageOutboxActions,
    getLetterPrintedActions,
    getMessagesQuery,
    getPreviewRowLinkProps,
    getViewRowLinkProps,
    messagesFilters.getFilters,
    messagesFilters.getSort
  ]);

  return <TabbedTable tabs={tabs} />;
}

function removeNonLetterColumns(columns: Columns<Message>) {
  const idsToRemove = ['message_format', 'subject'];
  return columns.filter((column) => !idsToRemove.includes(column.id ?? ''));
}
