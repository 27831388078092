// This is hopefully we can set somewhere in the admin screen

import { InspectionTask } from '../types/InspectionTask';

/**
 * This is the default duration of an inspection in minutes
 */
export const DEFAULT_ROUTNE_DURATION = 15;
export const DEFAULT_ENTRY_EXIT_DURATION = 60;

export function getDuration(inspectionTask: InspectionTask) {
  return ['entry_inspection', 'exit_inspection'].includes(
    inspectionTask.details!.type.id
  )
    ? DEFAULT_ENTRY_EXIT_DURATION
    : DEFAULT_ROUTNE_DURATION;
}
