import { Contact } from 'src/modules/contacts/types/contact-types';

export function getRecipientText(contacts: Contact[]) {
  const names = contacts
    .filter((contact) => contact)
    .map((contact) => contact.display_name);

  if (names.length < 3) {
    return names.join(' & ');
  }

  return `${names[0]} + ${names.length - 1} more`;
}
