import * as React from 'react';
import { isFunction, startCase } from 'lodash';
import { Disclosure } from '@headlessui/react';

import Box from '@rexlabs/box';
import { GhostButton, PrimaryButton } from '@rexlabs/button';
import { ReactForms } from '@rexlabs/form';
import { FormHelpers } from '@rexlabs/form/lib/types/core/types';
import { useErrorDialog } from '@rexlabs/dialog';
import { StyleSheet, useStyles, useToken } from '@rexlabs/styling';
import { Heading, Small } from '@rexlabs/text';

import { Contact } from 'src/modules/contacts/types/contact-types';
import { Property } from 'src/modules/properties/types/property-types';

import ChevronDownIcon from 'view/components/icons/chevron-down';
import ChevronRightIcon from 'view/components/icons/chevron-right';
import { BlockConfig } from 'view/components/record-screen/types';

const defaultStyles = StyleSheet({
  container: {
    padding: ({ token }) => token('spacing.xl'),
    borderRadius: ({ token }) => token('border.radius.l'),
    overflow: 'hidden'
  },
  flex1: {
    flex: 1
  },
  cursorPointer: {
    cursor: 'pointer'
  }
});

export interface MessageIssueDisclosureProps {
  issueRelatesTo: Property | Contact;
  issueRelatesToType: string;
  block: BlockConfig;
  blockData?: any;
  issueText: string;
  updateText: React.ReactNode;
  onSubmit: (changedValues: any, values: any) => any;
  setValue?: (boolean) => void;
}

export function MessageIssueDisclosure({
  updateText,
  issueRelatesTo,
  issueRelatesToType,
  block,
  blockData,
  issueText,
  setValue,
  onSubmit,
  ...props
}: MessageIssueDisclosureProps) {
  const errorDialog = useErrorDialog();

  const [statusText, setStatusText] = React.useState(
    issueText || 'No issue reported'
  );
  const [hasIssue, setHasIssue] = React.useState(issueText ? true : false);

  const token = useToken();

  const s = useStyles(defaultStyles);

  const Edit = block.Edit!;

  const handleSubmit = async (
    values: Partial<Contact>,
    helpers: FormHelpers<Contact, any>,
    afterSubmit
  ) => {
    try {
      const { touched } = helpers;
      const dirtyFields = Object.keys(values).filter((key) => touched[key]);
      const dirtyValues = dirtyFields.reduce((acc, key) => {
        acc[key] = values[key];
        return acc;
      }, {});

      await onSubmit(dirtyValues, values);

      setStatusText(`${startCase(issueRelatesToType)} updated`);
      setHasIssue(false);
      afterSubmit();
      setValue && setValue(hasIssue);
    } catch (e) {
      errorDialog.open(e);
    }
  };

  return (
    <Disclosure {...props}>
      {({ open, close }) => (
        <ReactForms
          initialValues={blockData || issueRelatesTo}
          validate={block.validate}
          handleSubmit={(values, helpers) =>
            handleSubmit(values, helpers, close)
          }
        >
          {({ submitForm, values, setFieldValue }) => (
            <Box
              {...s('container')}
              style={open ? { background: token('palette.grey.200') } : {}}
              gap={token('spacing.s')}
              display='flex'
              flexDirection='row'
            >
              <Box>{open ? <ChevronDownIcon /> : <ChevronRightIcon />}</Box>
              <Box {...s('flex1')}>
                <Box
                  display='flex'
                  flexDirection='row'
                  justifyContent='space-between'
                >
                  <Disclosure.Button
                    as={Box}
                    display='flex'
                    flexDirection='row'
                    {...s('cursorPointer')}
                  >
                    <Box display='flex' flexDirection='column'>
                      <Heading level={4} as='span'>
                        {issueRelatesTo.display_name}
                      </Heading>
                      <Small grey>
                        <span>{startCase(issueRelatesToType)} • </span>{' '}
                        {issueRelatesTo.record_reference}
                      </Small>
                    </Box>
                  </Disclosure.Button>
                  <Box display='flex' alignItems='center'>
                    {open ? (
                      <div>
                        <GhostButton onClick={() => close()}>
                          Cancel
                        </GhostButton>
                        <PrimaryButton onClick={() => submitForm()}>
                          Save
                        </PrimaryButton>
                      </div>
                    ) : (
                      <div
                        style={{
                          color: hasIssue
                            ? token('palette.red.900')
                            : token('palette.green.900')
                        }}
                      >
                        {statusText}
                      </div>
                    )}
                  </Box>
                </Box>
                <Disclosure.Panel>
                  <Box flexDirection='column' gap={'2rem'}>
                    {isFunction(updateText) ? updateText() : updateText}
                    <Edit
                      {...props}
                      values={values}
                      data={blockData || issueRelatesTo}
                      setFieldValue={setFieldValue}
                    />
                  </Box>
                </Disclosure.Panel>
              </Box>
            </Box>
          )}
        </ReactForms>
      )}
    </Disclosure>
  );
}
