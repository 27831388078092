export interface GetDefaultLineItemArgs {
  amount?: number;
}

export const getDefaultLineItem = ({
  amount
}: GetDefaultLineItemArgs = {}) => ({
  split_amount: {
    type: {
      id: 'percent',
      label: 'Percent'
    },
    amount
  }
});
