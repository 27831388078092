import { CustomAction } from '@rexlabs/model-generator';

import { Generator } from 'data/models/generator';
import { api } from 'utils/api/api-client';
import _ from 'lodash';
import { AgencyFee } from 'src/modules/agency-fees/types/agency-fee-types';

const config = {
  entities: {
    related: {
      agency_chart_of_accounts_account: {
        include: 'agency_chart_of_accounts_account'
      },
      ownership_chart_of_accounts_account: {
        include: 'ownership_chart_of_accounts_account'
      },
      tax_type: {
        include: 'tax_type'
      },
      service_packages: {
        include: 'service_packages'
      }
    }
  }
};

type ArchivePayload = {
  id: string;
  status_reason: string;
};

type ActivatePayload = {
  id: string;
};

const actionCreators = {
  archive: {
    request: (payload: ArchivePayload, _actions, _dispatch, _getState) =>
      api.put(`/financials/agency-fees/${payload.id}/status`, {
        status: {
          id: 'archived'
        },
        status_reason: payload.status_reason
      }),
    reduce: {
      initial: _.identity,
      success: _.identity,
      failure: _.identity
    }
  } as CustomAction<ArchivePayload, unknown>,
  activate: {
    request: (payload: ActivatePayload, _actions, _dispatch, _getState) =>
      api.put(`/financials/agency-fees/${payload.id}/status`, {
        status: {
          id: 'active'
        }
      }),
    reduce: {
      initial: _.identity,
      success: _.identity,
      failure: _.identity
    }
  } as CustomAction<ActivatePayload, unknown>
};

export const agencyFeeModel = new Generator<AgencyFee, typeof actionCreators>(
  'financials/agency-fees',
  config
).createEntityModel({ actionCreators });
