import React, { forwardRef } from 'react';
import { query } from '@rexlabs/model-generator';
import { toQuri } from '@rexlabs/quri';

import { createTableBlock } from 'view/components/record-screen/cards/create-table-block';
import {
  financialsSecurityDepositRequestsModel,
  SecurityDepositRequest
} from 'data/models/entities/financials/security-deposit-requests';
import { Message } from 'view/components/@luna/message';
import { SecurityDeposit } from 'data/models/entities/financials/security-deposits';
import { OutlineSplitButton } from '@rexlabs/button';
import ActionMenu from '@rexlabs/action-menu';
import ChevronDownIcon from 'view/components/icons/chevron-down';
import { transformActionDeclarationsToActionMenuItems } from 'utils/actions/transforms';
import { getRecordLinkProps } from 'view/components/record-link/get-record-link-props';
import { useDialog } from '@rexlabs/dialog';
import { SecurityDepositTypeHeldBy } from 'data/models/entities/financials/security-deposit-types';
import { useGetSecurityDepositRequestActionGroup } from '../utils/security-deposit-request/use-get-security-deposit-request-action-group';
import { securityDepositRequestColumns } from '../utils/security-deposit-request-columns';
import { CreateSecurityDepositRequestDialog } from '../dialogs/create-security-deposit-request';

const getSecurityDepositRequestsQuery = (securityDepositId: string) => {
  const q = toQuri([
    {
      field: 'security_deposit_id',
      op: '==',
      value: securityDepositId
    }
  ]);
  return query`{
    ${financialsSecurityDepositRequestsModel} (q: ${q}) {
      id
      amount
      invoice
      lodgement_reference
      payment_reference
    }
  }`;
};

export const securityDepositRequestsBlock = createTableBlock<
  SecurityDeposit,
  typeof financialsSecurityDepositRequestsModel
>({
  id: 'security-deposit-requests',
  title: 'Security deposit invoices',
  getTableProps: ({ data }) => ({
    id: 'security-deposit-requests',
    getQuery: () => getSecurityDepositRequestsQuery(data.id),
    columns: securityDepositRequestColumns,
    getRowLinkProps: ({ item }: { item: SecurityDepositRequest }) => {
      return item?.invoice?.id
        ? getRecordLinkProps({
            type: 'invoice',
            id: item?.invoice?.id
          })
        : null;
    }
  }),
  Empty: ({ data: securityDeposit }: { data: SecurityDeposit }) => {
    const { open: openCreateSecurityDepositRequestDeposit } = useDialog(
      CreateSecurityDepositRequestDialog
    );

    const typesToShowButton = [
      'held_by_deposit_authority',
      'held_by_ownership'
    ] as SecurityDepositTypeHeldBy[];
    const showAction =
      securityDeposit.security_deposit_type?.held_by.id &&
      typesToShowButton.includes(
        securityDeposit.security_deposit_type?.held_by.id
      );
    return (
      <Message
        title='Deposit requests'
        actions={
          showAction
            ? [
                {
                  label: 'Add deposit request',
                  intent: 'secondary',
                  handleAction: () =>
                    openCreateSecurityDepositRequestDeposit({
                      securityDeposit
                    })
                }
              ]
            : []
        }
      >
        To get started, add a deposit request to create an invoice for payment
        of some or all of the amount required.
      </Message>
    );
  },
  Actions: ({ data }) => {
    const getSecurityDepositRequestActionGroup = useGetSecurityDepositRequestActionGroup();
    const [firstAction, ...actions] = getSecurityDepositRequestActionGroup(
      data!
    );

    return (
      <OutlineSplitButton
        Left={({ Button }) => (
          <Button onClick={firstAction.handleAction}>
            {firstAction.label}
          </Button>
        )}
        Right={({ IconButton }) => (
          <ActionMenu
            placement='bottom-end'
            items={transformActionDeclarationsToActionMenuItems(actions)}
            Button={forwardRef((props, ref) => (
              <IconButton {...props} ref={ref} Icon={ChevronDownIcon} />
            ))}
          />
        )}
      />
    );
  }
});
