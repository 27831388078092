import React from 'react';

import { RecordDialog } from 'view/components/record-screen/dialog/dialog';
import { DialogContentConfig } from 'view/components/record-screen/dialog/types';

import { uploadBankTransactionsBlock } from '../blocks/upload-bank-transactions';

const content: DialogContentConfig = [
  {
    id: 'upload-bank-transaction',
    label: 'Upload Bank Transaction',
    blocks: [uploadBankTransactionsBlock]
  }
];

export function UploadBankTransactionDialog({
  onClose,
  handleSubmit
}: Pick<
  React.ComponentProps<typeof RecordDialog>,
  'onClose' | 'handleSubmit'
>) {
  return (
    <RecordDialog
      title='Upload bank transactions'
      onClose={onClose}
      content={content}
      submitLabel='Continue'
      handleSubmit={handleSubmit}
    />
  );
}
