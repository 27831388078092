import React from 'react';
import { BlockConfig } from 'view/components/record-screen/types';
import { Grid } from 'view/components/@luna/form/grid';
import { Field } from '@rexlabs/form';
import { DateValue } from 'view/components/values/date';
import { DateInput } from 'view/components/@luna/inputs/date-input/date-input';
import { Heading, Small } from '@rexlabs/text';
import { Checkbox } from '@rexlabs/checkbox';
import { useStyles, StyleSheet, useToken } from '@rexlabs/styling';
import Box from '@rexlabs/box';
import { BooleanValue } from 'view/components/values/boolean';
import { InspectionTask } from '../../types/InspectionTask';
import { InspectionFormData } from '../../types/InspectionFormData';

export const defaultStyles = StyleSheet({
  subtext: {
    color: ({ token }) => token('color.textStyle.body.subtext')
  }
});

export const entryExitMaintenanceAndHealthBlock: BlockConfig<
  InspectionTask,
  any,
  InspectionFormData
> = {
  id: 'entry-exit-property-details-maintenance-and-health',
  title: 'Maintenance and health issues',
  isMobileForcedCollapsed: true,
  isEditable: (data) =>
    !['report_completed', 'report_sent'].includes(
      data?.details?.status?.id || ''
    ),
  View: ({ data }) => {
    const s = useStyles(defaultStyles);

    const propertyDetails = data?.details?.property_details;
    const maintenanceData = propertyDetails?.maintenance_data;
    const healthIssuesData = propertyDetails?.health_issues_data;

    return (
      <>
        <Box marginBottom={'-10px'}>
          <Heading level={4}>Maintenance</Heading>
          <Small semibold {...s('subtext')}>
            Approximate dates when work last done on residential premises
          </Small>
        </Box>
        <Grid columns={2}>
          <DateValue
            label='Carpets professionally cleaned'
            value={maintenanceData?.carpets_clean_date}
          />
          {data?.details?.type?.id === 'entry_inspection' && (
            <>
              <DateValue
                label='Painting of premises (external)'
                value={maintenanceData?.external_painting_date}
              />
              <DateValue
                label='Painting of premises (internal)'
                value={maintenanceData?.internal_painting_date}
              />
              <DateValue
                label='Flooring laid/replaced/cleaned'
                value={maintenanceData?.flooring_work_date}
              />
            </>
          )}
        </Grid>
        <Heading level={4}>Health issues</Heading>
        <Grid columns={2}>
          <BooleanValue
            label='Signs of mould and dampness'
            value={healthIssuesData?.signs_of_mould_and_dampness}
            trueLabel='Yes'
            falseLabel='No'
          />
          <BooleanValue
            label='Signs of pest and vermin'
            value={healthIssuesData?.signs_of_pests_and_vermin}
            trueLabel='Yes'
            falseLabel='No'
          />
        </Grid>
      </>
    );
  },
  Edit: ({ data }) => {
    const s = useStyles(defaultStyles);
    const token = useToken();

    const maintenanceData = 'property_details.maintenance_data';
    const healthIssuesData = 'property_details.health_issues_data';

    return (
      <>
        <Box marginBottom={token('spacing.m')}>
          <Heading level={4}>Maintenance</Heading>
          <Small semibold {...s('subtext')}>
            Approximate dates when work last done on residential premises
          </Small>
        </Box>
        <Grid columns={2}>
          <Field
            id={`${maintenanceData}.carpets_clean_date`}
            name={`${maintenanceData}.carpets_clean_date`}
            label='Carpets professionally cleaned'
            Input={DateInput}
            optional={false}
            inputProps={{
              clearable: true
            }}
          />
          {data?.details?.type?.id === 'entry_inspection' && (
            <>
              <Field
                id={`${maintenanceData}.external_painting_date`}
                name={`${maintenanceData}.external_painting_date`}
                label='Painting of premises (external)'
                Input={DateInput}
                optional={false}
                inputProps={{
                  clearable: true
                }}
              />
              <Field
                id={`${maintenanceData}.internal_painting_date`}
                name={`${maintenanceData}.internal_painting_date`}
                label='Painting of premises (internal)'
                Input={DateInput}
                optional={false}
                inputProps={{
                  clearable: true
                }}
              />
              <Field
                id={`${maintenanceData}.flooring_work_date`}
                name={`${maintenanceData}.flooring_work_date`}
                label='Flooring laid/replaced/cleaned'
                Input={DateInput}
                optional={false}
                inputProps={{
                  clearable: true
                }}
              />
            </>
          )}
        </Grid>
        <Box marginTop={token('spacing.xl')} marginBottom={token('spacing.m')}>
          <Heading level={4}>Health issues</Heading>
        </Box>
        <Grid columns={2}>
          <Field
            id={`${healthIssuesData}.signs_of_mould_and_dampness`}
            name={`${healthIssuesData}.signs_of_mould_and_dampness`}
            Input={Checkbox}
            optional={false}
            inputProps={{
              label: 'Signs of mould and dampness'
            }}
          />
          <Field
            id={`${healthIssuesData}.signs_of_pests_and_vermin`}
            name={`${healthIssuesData}.signs_of_pests_and_vermin`}
            Input={Checkbox}
            optional={false}
            inputProps={{
              label: 'Signs of pest and vermin'
            }}
          />
        </Grid>
      </>
    );
  }
};
