import { Columns, ListTable, TabbedTable } from 'view/components/table';
import React, { useMemo } from 'react';
import { query } from '@rexlabs/model-generator';
import { useTableFilters } from 'view/hooks/use-table-filters';
import { chartOfAccountsAccountModel } from 'src/modules/chart-of-accounts/models/chart-of-accounts';
import { ChartOfAccountsAccount } from 'src/modules/chart-of-accounts/types/chart-of-account-types';
import { useGetChartOfAccountAccountsAction } from 'src/modules/chart-of-accounts/actions/use-get-chart-of-account-accounts-action';
import { transformActionDeclarationsToActionMenuItems } from 'utils/actions/transforms';
import { Tab } from 'view/components/table/tabbed';

const getChartOfAccountsQuery = () => query`{
    ${chartOfAccountsAccountModel} {
        id
        name
        description
        account_category
        account_subcategory
        parent
        default_tax_type
    }
}`;

const columns: Columns<ChartOfAccountsAccount> = [
  {
    id: 'name',
    Header: 'Name'
  },
  {
    id: 'description',
    Header: 'Description'
  },
  {
    id: 'account_category',
    type: 'tagValue',
    Header: 'Category',
    width: 140
  },
  {
    id: 'default_tax_type',
    Header: ' Tax type',
    accessor: (item) => item.default_tax_type.label,
    width: 140
  }
];

export function ChartOfAccountsAccountTable() {
  const getChartOfAccountsAccountActions = useGetChartOfAccountAccountsAction();
  const { getSort, getFilters } = useTableFilters('chart-of-accounts-accounts');

  const tabs: Tab[] = useMemo(() => {
    const commonProps = {
      id: 'chart-of-accounts-account-table',
      Table: ListTable,
      getSort,
      getFilters,
      columns,
      getQuery: getChartOfAccountsQuery,
      getActionMenuItems: ({ item }) =>
        transformActionDeclarationsToActionMenuItems(
          getChartOfAccountsAccountActions(item)
        )
    };
    return [
      {
        ...commonProps,
        forcedGlobalFilter: [
          {
            field: 'status_id',
            op: 'eq',
            value: 'active'
          }
        ],
        name: 'active',
        label: 'Active'
      },
      {
        ...commonProps,
        forcedGlobalFilter: [
          {
            field: 'status_id',
            op: 'eq',
            value: 'archived'
          }
        ],
        name: 'archived',
        label: 'Archived'
      }
    ];
  }, []);

  return <TabbedTable tabs={tabs} />;
}
