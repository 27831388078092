import { useDialog } from '@rexlabs/dialog';

import { SingleActionDeclaration } from 'src/modules/common/actions/types/action-declaration-types';

import { EditNoteRecordDialog } from '../../dialogs/edit-note-record-dialog';
import { ViewNoteRecordDialog } from '../../dialogs/view-note-record-dialog';
import { Note } from '../../types/Note';

export function useGetViewNoteAction() {
  const { open: openEditDialog } = useDialog(EditNoteRecordDialog);
  const { open: openViewDialog } = useDialog(ViewNoteRecordDialog);

  return (
    note: Note,
    hideAddNoteToField = false,
    showEdit = true
  ): SingleActionDeclaration => ({
    id: 'view-note',
    label: 'View note',
    handleAction: () => {
      openViewDialog({
        note,
        ...(showEdit
          ? {
              onConfirm: () => openEditDialog({ note, hideAddNoteToField })
            }
          : { onConfirm: undefined })
      });
    }
  });
}
