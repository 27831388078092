import React from 'react';

import Box from '@rexlabs/box';
import { Heading } from '@rexlabs/text';
import { Field, ReactForms } from '@rexlabs/form';
import { Select } from '@rexlabs/select';

import { Ownership } from 'data/models/entities/ownerships';

import { Contact } from 'src/modules/contacts/types/contact-types';
import { FinancialPeriod } from 'src/modules/statements/types';

import { normaliseContact } from 'utils/normaliser/contact';

import { BlockConfig } from 'view/components/record-screen/types';
import { Card, CardContent } from 'view/components/@luna/card';
import { Grid } from 'view/components/@luna/form/grid';

import { FinancialPeriodSelect } from 'view/components/inputs/selects/v4-selects/financial-period-select';

import { TaxSummariesCard } from 'src/modules/tax/components/tax-summaries-card';
import { TaxPaymentsAndWithholdingsCard } from '../components/tax-payments-and-withholdings-card';
import { getOwnerContacts } from '../../ownerships/utils/get-owner-contacts';

type TaxSummaryForm = {
  financial_year: FinancialPeriod;
  owner: Contact;
};

export const taxSummaryBlock: BlockConfig<Ownership> = {
  id: 'tax-summary',
  title: 'Tax summary',
  Card: ({ data: ownership }) => {
    return (
      <ReactForms<TaxSummaryForm, any>>
        {({ values }) => {
          const { financial_year: financialYear, owner } = values;
          const { owners } = ownership;
          const financialYearAndOwnerSelected = owner && financialYear;

          return (
            <>
              <Card>
                <CardContent>
                  <Box
                    flexDirection='row'
                    alignItems='center'
                    sx='1.2rem'
                    flex={1}
                  >
                    <Heading level={3}>Tax summary</Heading>
                  </Box>
                  <Grid columns={2}>
                    <Field
                      name='financial_year'
                      id='financial_year'
                      Input={FinancialPeriodSelect}
                      label='Financial year'
                      optional={false}
                      inputProps={{
                        includeCurrent: true
                      }}
                    />
                    <Field
                      id='owner'
                      name='owner'
                      label='Owner'
                      Input={Select}
                      inputProps={{
                        items: getOwnerContacts(owners),
                        normaliser: normaliseContact,
                        searchable: false
                      }}
                      optional={false}
                    />
                  </Grid>
                  {owner && financialYear && (
                    <TaxSummariesCard
                      owner={owner}
                      ownership={ownership}
                      financialYear={financialYear}
                    />
                  )}
                </CardContent>
              </Card>
              {financialYearAndOwnerSelected && (
                <TaxPaymentsAndWithholdingsCard
                  financialYear={financialYear}
                  owner={owner}
                  ownership={ownership}
                />
              )}
            </>
          );
        }}
      </ReactForms>
    );
  }
};
