import { useErrorDialog } from '@rexlabs/dialog';
import { useModelActions } from '@rexlabs/model-generator';
import { useToast } from 'view/components/@luna/notifications/toast';

import { getRecordObject } from 'src/modules/common/utils/Records/get-record-object';
import { slugToSentenceCase } from 'src/modules/communications/messages/utils/slug-to-sentence-case';

import { getRecordLabel } from 'utils/records/get-record-label';
import { api } from 'utils/api/api-client';

import { RecordSubmitHandler } from 'view/components/record-screen/utils';

import { tasksModel } from '../models/tasks-model';
import { Task } from '../types/Task';
import { MessageWithReasonBlockProps } from '../blocks/message-with-reason-block';

export function useGetMessageWithReasonSubmitHandler() {
  const { addToast } = useToast();
  const { refreshItem, refreshLists } = useModelActions(tasksModel);
  const { open: openErrorDialog } = useErrorDialog();

  return (
    task: Task<any>,
    status: string
  ): RecordSubmitHandler<MessageWithReasonBlockProps> => async ({ values }) => {
    try {
      const payload = {
        type: task.type,
        details: {
          status: {
            id: status
          },
          ...(task.type.id === 'task_work_order'
            ? { work_done_by_type: task.details.work_done_by_type }
            : {}),
          status_reason: values.status_reason,
          message: {
            template: values?.message?.template,
            subject:
              values?.message?.subject ||
              values?.message?.template?.channels?.[0].email_subject,
            body:
              values?.message?.body ||
              values?.message?.template?.channels?.[0].plain_text_body,
            inline_attachments: values?.message?.inline_attachments || [],
            send_immediately: false
          }
        }
      };
      const updatedTask = await api.patch(`tasks/${task.id}`, payload);

      refreshItem({ id: task.id });
      refreshLists();

      addToast({
        type: 'info',
        description: `${getRecordLabel(
          getRecordObject(task, tasksModel)
        )} status changed to ${slugToSentenceCase(status)}`
      });

      return updatedTask;
    } catch (error) {
      openErrorDialog(error);
    }
  };
}
