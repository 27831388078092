import React, { useMemo, Fragment } from 'react';

import Box from '@rexlabs/box';
import { useStyles, useToken, StyleSheet, text } from '@rexlabs/styling';

import BulletIcon from 'view/components/icons/bullet-separator';
import { useMediaQuery } from '@rexlabs/breakpoints';

const defaultStyles = StyleSheet({
  container: {
    ...text.styles({
      color: ({ token }) => token('color.textStyle.body.subtext')
    })
  },
  identifier: {
    textTransform: 'uppercase',

    ...text.styles({
      target: 'titleBlock.identifier',
      fallback: 'small.semibold',
      color: ({ token }) => token('color.textStyle.body.subtext')
    })
  }
});

function sanitiseLabel(label?: string) {
  return label?.replace(/_/g, ' ');
}

export type IdentifierProps = {
  type?: string;
  reference?: string | null;
  isCompact?: boolean;
};

export function Identifier({
  type,
  reference,
  isCompact = false
}: IdentifierProps) {
  const s = useStyles(defaultStyles);
  const token = useToken();

  const matchesMobile = useMediaQuery({ maxWidth: 's' });

  const identifiers = useMemo(
    () => [sanitiseLabel(type), reference].filter(Boolean),
    [type, reference]
  );

  return (
    <Box
      {...s('container')}
      alignItems={!(isCompact && matchesMobile) ? 'center' : 'flex-start'}
      sx={token('spacing.xs')}
      flexDirection={!(isCompact && matchesMobile) ? 'row' : 'column'}
    >
      {identifiers.map((identifier, index) => (
        <Fragment key={index}>
          <span {...s('identifier')}>{identifier}</span>
          {index !== identifiers.length - 1 &&
            !(isCompact && matchesMobile) && <BulletIcon size='s' />}
        </Fragment>
      ))}
    </Box>
  );
}
