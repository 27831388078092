import { Invoice } from 'data/models/entities/financials/invoices';

import { api } from 'utils/api/api-client';

const relatedFields = [
  'payable_by_contact',
  'payable_to_contact',
  'payable_by_ownership',
  'payable_by_ownership.owners',
  'payable_by_ownership.owners.contact',
  'payable_by_ownership.financial_contact',
  'payable_to_ownership',
  'payable_to_ownership.owners',
  'payable_to_ownership.owners.contact',
  'payable_to_ownership.financial_contact',
  'payable_by_tenancy',
  'payable_by_tenancy.tenants',
  'payable_by_tenancy.tenants.contact',
  'payable_by_tenancy.financial_contact',
  'payable_to_tenancy',
  'payable_to_tenancy.tenants',
  'payable_to_tenancy.tenants.contact',
  'payable_to_tenancy.financial_contact'
];

export async function getInvoiceRelatedItems(invoiceSearchItem: Invoice) {
  const invoiceUrl = `/financials/invoices/${
    invoiceSearchItem.id
  }?include=${relatedFields.join(',')}`;

  const shouldFetchRecord =
    !!hasRelatedEntity(invoiceSearchItem, 'payable_by') ||
    !!hasRelatedEntity(invoiceSearchItem, 'payable_to');

  const completeInvoice = shouldFetchRecord
    ? await api.get<Invoice>(invoiceUrl).then(({ data }) => data)
    : invoiceSearchItem;

  const payableBy = getRelatedEntity(completeInvoice, 'payable_by');
  const payableTo = getRelatedEntity(completeInvoice, 'payable_to');

  return {
    payableBy,
    payableTo
  };
}

function hasRelatedEntity(
  invoiceSearchItem: Invoice,
  entityType: 'payable_by' | 'payable_to'
) {
  switch (invoiceSearchItem?.[entityType]?.object?.type?.id) {
    case 'ownership':
      return !invoiceSearchItem?.[`${entityType}_ownership`]?.owners?.[0]
        ?.contact;
    case 'tenancy':
      return !invoiceSearchItem?.[`${entityType}_tenancy`]?.tenants?.[0]
        ?.contact;
    case 'contact':
      return !invoiceSearchItem?.[`${entityType}_contact`];
    default:
      return true;
  }
}

function getRelatedEntity(invoiceSearchItem: Invoice, entityType: string) {
  switch (invoiceSearchItem?.[entityType]?.object?.type?.id) {
    case 'ownership':
      return invoiceSearchItem?.[`${entityType}_ownership`]?.owners?.map(
        (owner) => owner.contact
      );
    case 'tenancy':
      return invoiceSearchItem?.[`${entityType}_tenancy`]?.tenants?.map(
        (tenant) => tenant.contact
      );
    case 'contact':
      return [invoiceSearchItem?.[`${entityType}_contact`]];
    default:
      return [];
  }
}
