import React, { useMemo } from 'react';
import { query, useEntityQuery } from '@rexlabs/model-generator';

import { BREADCRUMBS } from 'view/components/@luna/breadcrumbs';
import { RecordScreen } from 'view/components/record-screen';

import { useContent } from '../data/content';
import { QuoteTitleBlock } from '../components/quote-title-block';
import { tasksModel } from '../../common/models/tasks-model';
import { mapQuoteTaskToQuoteFormData } from '../mappers/map-quote-task-quote-form-data';
import { TaskRightBarOverride } from '../../common/components/right-bar-override';
import { useGetUpdateQuoteTaskSubmitHandler } from '../hooks/use-update-quote-task-submit-handler';

const getQuoteQuery = (id: string) => query`{
  ${tasksModel} (id: ${id}) {
    id
    property {
      active_property_ownership {
        ownership {
          owners
        }
      }
      active_property_tenancy {
          tenancy
      }
    }
    managed_by
    parent_task
    quoteDetails
  }
}`;

interface QuoteDetailsProps {
  quoteId?: string;
}

export function QuoteDetails({ quoteId }: QuoteDetailsProps) {
  const breadcrumbs = [{ type: BREADCRUMBS.QUOTE }];
  const query = useMemo(() => getQuoteQuery(quoteId!), [quoteId]);

  const { status, data } = useEntityQuery(query, {
    throwOnError: false
  });

  const content = useContent();

  const getUpdateQuoteTaskSubmitHandler = useGetUpdateQuoteTaskSubmitHandler();
  const handleSubmit = getUpdateQuoteTaskSubmitHandler(quoteId!);

  const titleBlock = <QuoteTitleBlock quote={data} />;

  return (
    <RecordScreen
      isLoading={status === 'loading'}
      data={data}
      initialValues={data && mapQuoteTaskToQuoteFormData(data)}
      handleSubmit={handleSubmit}
      content={content}
      titleBlock={titleBlock}
      breadcrumbs={breadcrumbs}
      RightBarOverride={TaskRightBarOverride}
    />
  );
}
