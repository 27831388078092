import React from 'react';

import Box from '@rexlabs/box';
import { Heading } from '@rexlabs/text';

import { Card, CardContent } from 'view/components/@luna/card';

import { PaymentsToHmrcTabbedTable } from './payments-to-hmrc-tabbed-table';

export function PaymentsToHmrcCard({ quarterStartDate, quarterEndDate }) {
  return (
    <Card>
      <CardContent>
        <Box
          flexDirection='row'
          alignItems='center'
          justifyContent='space-between'
          sx='1.2rem'
          flex={1}
        >
          <Heading level={3}>Payments to HMRC</Heading>
        </Box>
        <PaymentsToHmrcTabbedTable
          quarterStartDate={quarterStartDate}
          quarterEndDate={quarterEndDate}
        />
      </CardContent>
    </Card>
  );
}
