import React from 'react';
import { Helmet } from 'react-helmet';

import Box from '@rexlabs/box';

import { TitleBlock } from 'view/components/cards/title/title-block';
import { BREADCRUMBS, Breadcrumbs } from 'view/components/@luna/breadcrumbs';
import { RecordTitleCard } from 'view/components/cards/title';
import { PageLayout } from 'view/components/layouts/page-layout/page-layout';

import dayjs from 'dayjs';
import { InfoBanner } from 'view/components/@luna/notifications/banner';
import InfoCircleIcon from 'view/components/icons/info';
import { FinancialYearStatementCard } from '../components/financial-year-statement-card';
import { QuarterlyPaymentCard } from '../components/quarterly-payments-card';
import { getFinancialYearAbbreviationString } from '../utils/get-financial-year-abbreviation-string';
import { useGetQuarterlySummaryFromFinancialYear } from '../hooks/use-get-quarterly-summary-from-financial-year';
import { useGetFinancialYearStartAndEnd } from '../hooks/use-get-financial-year-start-and-end';

export function HmrcReportsAndSubmissionsDetails({ id }) {
  const breadcrumbs = [{ type: BREADCRUMBS.HMRC_REPORTS_AND_SUBMISSIONS }];
  const getFinancialYearStartAndEnd = useGetFinancialYearStartAndEnd();
  const { startDate, endDate } = getFinancialYearStartAndEnd(id);

  const title = `FY ${getFinancialYearAbbreviationString(startDate, endDate)}`;

  const subText = 'HMRC Reports and Submissions';

  const { data, isLoading } = useGetQuarterlySummaryFromFinancialYear(
    dayjs(startDate).format('YYYY')
  );

  return (
    <>
      <Helmet title={title} />
      <PageLayout>
        <Box flexDirection={'column'} rowGap={'2rem'}>
          <Breadcrumbs breadcrumbs={breadcrumbs} />
          <RecordTitleCard
            titleBlock={<TitleBlock title={title} type={subText} />}
          >
            <InfoBanner
              Icon={InfoCircleIcon}
              description='The annual and quarterly reports for HMRC are currently being investigated by the team and will be available in the near future'
            />
          </RecordTitleCard>

          <QuarterlyPaymentCard
            financialStartDate={startDate}
            data={data}
            isLoading={isLoading}
          />
          <FinancialYearStatementCard
            financialStartDate={startDate}
            financialEndDate={endDate}
            data={data}
            isLoading={isLoading}
          />
        </Box>
      </PageLayout>
    </>
  );
}
