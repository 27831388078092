import { ChecklistTemplateItem } from '../../types/ChecklistTemplate';

/**
 * Returns an incrementing alphabetical identifier for a given item in a list of items.
 * The first item is A, the second is B, the 27th is AA, the 28th is AB, etc.
 */
export function useGetIncrementingAlphabeticalIdentifierForItem(
  orderedItems: ChecklistTemplateItem[]
) {
  return (item: ChecklistTemplateItem) => {
    if (!item) {
      return '?';
    }

    const index = orderedItems.findIndex((i) => i.id === item.id);

    if (index === -1) {
      console.error('Item not found in list', item, orderedItems);
      throw new Error('Item not found in list');
    }

    const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';

    if (index < 26) {
      return alphabet[index];
    }

    const firstLetter = alphabet[Math.floor(index / 26) - 1];
    const secondLetter = alphabet[index % 26];

    return `${firstLetter}${secondLetter}`;
  };
}
