import React, { useCallback } from 'react';

import { RecordDialog } from 'view/components/record-screen/dialog/dialog';
import { DialogContentConfig } from 'view/components/record-screen/dialog/types';

import { RecordSubmitHandler } from 'view/components/record-screen/utils';
import { useModelActions } from '@rexlabs/model-generator';
import { filterNullValues } from 'utils/filter-null-values';
import {
  financialsReconciliationsModel,
  Reconciliation
} from 'data/models/entities/financials/reconciliations';
import { track } from '@rexlabs/analytics';
import { EVENTS } from 'utils/analytics';
import { startDailyReconciliationBlock } from '../blocks/start-daily-reconciliation';

interface EditReconciliationDialogProps {
  reconciliation: Reconciliation;
  onClose?: () => void;
}

export function EditReconciliationDialog({
  reconciliation,
  onClose
}: EditReconciliationDialogProps) {
  const content: DialogContentConfig = [
    {
      id: 'basics',
      label: 'Basics',
      blocks: [startDailyReconciliationBlock]
    }
  ];

  const { updateItem } = useModelActions(financialsReconciliationsModel);
  const handleSubmit: RecordSubmitHandler = useCallback(
    async ({ values, changedValues }) => {
      const data = filterNullValues(values);

      /**
       * The backend requires use to send both the start date and statement date. For a daily reconciliation these dates
       * equal, for a monthly reconciliation the start date is the beginning of the month
       */
      if (reconciliation.type.id === 'daily') {
        data.start_date = data.statement_date;
      }

      const response = await updateItem({
        id: reconciliation.id,
        data,
        args: {
          include: 'reconciliation_summary'
        }
      });

      track({
        eventName: EVENTS.RECONCILIATIONS.DETAILS_UPDATED,
        data: {
          reconciliationType: reconciliation.type.id,
          closingBalancedChanged: 'statement_balance' in changedValues
        }
      });

      return response.data;
    },
    [reconciliation.id, reconciliation.type.id, updateItem]
  );

  const data = {
    bank_account: {
      id: reconciliation.bank_account?.id
    },
    start_date: reconciliation.start_date,
    statement_date: reconciliation.statement_date,
    statement_balance: reconciliation.statement_balance
  };

  return (
    <RecordDialog
      onClose={onClose}
      data={data}
      title='Update reconciliation'
      handleSubmit={handleSubmit}
      content={content}
      blockProps={{ reconciliation }}
    />
  );
}
