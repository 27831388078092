import { Settings } from 'src/modules/settings/hooks/useSettings';
import { useGetServicePackages } from 'src/modules/service-packages/hooks/use-get-service-packages';
import { pick } from 'lodash';

export function useMapInspectionSettings(
  values: Settings['inspection-task-interval']
) {
  const selectedIds = values?.service_package_ids || [];

  const { data, isLoading } = useGetServicePackages(selectedIds);

  return {
    isLoading,
    inspectionSettings: {
      ...pick(values, [
        'first_inspection',
        'routine_inspection',
        'notice_period'
      ]),
      inspection_automatic_task_creation: values?.automatic_task_creation,
      inspection_service_package_ids: selectedIds,
      inspection_service_packages: data
    }
  };
}
