import { useDialog } from '@rexlabs/dialog';
import { useModelActions } from '@rexlabs/model-generator';

import { useToast } from 'view/components/@luna/notifications/toast';
import { ActionDeclaration } from 'src/modules/common/actions/types/action-declaration-types';
import {
  Disbursement,
  financialsDisbursementsModel
} from 'data/models/entities/financials/disbursements/disbursements';
import React from 'react';
import { getRecordTitle } from 'utils/records/get-record-title';
import { useTranslation } from 'react-i18next';
import { VoidDisbursementDialog } from '../dialogs/void-disbursement-dialog';

export function useGetVoidDisbursementAction() {
  const { t } = useTranslation();
  const { addToast } = useToast();
  const { voidDisbursement } = useModelActions(financialsDisbursementsModel);
  const { open } = useDialog(VoidDisbursementDialog);

  return (disbursement?: Disbursement): ActionDeclaration | undefined => {
    if (!disbursement) {
      return;
    }

    if (
      disbursement.status.id === 'voided' ||
      disbursement.status.id === 'processing_void'
    ) {
      return;
    }

    return {
      id: 'void-disbursement',
      group: 'disbursement',
      label: t('disbursements.void.singular.label') as string,
      handleAction: () =>
        open({
          disbursements: [disbursement],
          title: t('disbursements.void.singular.label'),
          onSubmit: async (voidReason) => {
            await voidDisbursement({
              ...voidReason,
              id: disbursement.id
            });

            addToast({
              type: 'error',
              description: (
                <>
                  1 <b>void</b> in progress for{' '}
                  <b>
                    {getRecordTitle({
                      type: 'disbursement',
                      object: disbursement
                    })}
                  </b>
                  .
                </>
              )
            });
          }
        })
    };
  };
}
