import { Generator, GeneratorConfig } from 'data/models/generator';
import { TimestampAttributes } from 'data/models/types';
import { PropertiesModel } from 'src/modules/properties/types/property-types';
import { auditableConfig } from 'src/modules/common/auditable/auditable-config';
import { propertyOwnershipModel } from 'src/modules/property-ownerships/models/property-ownership-model';
import { propertyTenancyModel } from 'src/modules/property-tenancies/models/property-tenancy-model';

export interface AccessDetails {
  key_number: string;
  key_location: string;
  alarm_code: string;
  notes: string;
  pets: string;
}

type File = TimestampAttributes & {
  id: string;
  name: string;
  size: number;
  type: string;
  updated_at: string;
  url: string;
  download_url: string;
};

export type Image = TimestampAttributes & {
  id: string;
  file: File;
};

const config: GeneratorConfig = {
  entities: {
    related: {
      active_property_ownership: {
        include: 'active_property_ownership',
        model: propertyOwnershipModel
      },
      active_property_tenancy: {
        include: 'active_property_tenancy',
        model: propertyTenancyModel
      },
      property_tenancies: {
        include: 'property_tenancies',
        model: [propertyTenancyModel]
      },
      property_ownerships: {
        include: 'property_ownerships',
        model: [propertyOwnershipModel]
      },
      adverts: {
        include: 'adverts'
      },
      compliance_categories: {
        include:
          'compliance_categories,compliance_categories.category_requirements'
      },
      features: {
        include: 'features'
      },
      images: {
        include: 'images.file'
      },
      incoming_property_tenancy: {
        include: 'incoming_property_tenancy',
        model: propertyTenancyModel
      },
      incoming_property_ownership: {
        include: [
          'incoming_property_ownership',
          'incoming_property_ownership.ownership',
          'incoming_property_ownership.ownership.owners',
          'incoming_property_ownership.ownership.owners.contact',
          'incoming_property_ownership.ownership.owners.contact.primary_phone'
        ].join(',')
      },
      links: {
        include: 'links'
      },
      views: {
        include: 'views'
      },
      portfolio: {
        include: 'portfolio.users,portfolio.users.contact.primary_phone'
      },
      appliances: {
        include:
          'appliances,appliances.warranties,appliances.manuals,appliances.property'
      },
      address: {
        include: 'address'
      },
      areas: {
        include: 'areas.items,areas.summary,areas.property_area_type'
      },
      ...auditableConfig
    }
  }
};

export const propertiesModel = new Generator<PropertiesModel>(
  'properties',
  config
).createEntityModel();
