import { Generator } from 'data/models/generator';
import { api } from 'utils/api/api-client';
import _ from 'lodash';
import { BankWithdrawal } from 'src/modules/banking/bank-withdrawals/types/bank-withdrawal-types';
import { ReconcileItemsPayload } from 'src/modules/banking/bank-deposits/models/bank-deposit-model';
import { CustomAction } from '@rexlabs/model-generator';
import { config as bankWithdrawalsConfig } from 'src/modules/banking/bank-withdrawals/models/bank-withdrawal-model';

const config = {
  ...bankWithdrawalsConfig,
  entities: {
    ...bankWithdrawalsConfig.entities,
    api: {
      fetchList: (type, args) =>
        api.get(
          `/financials/reconciliations/${args.reconciliationId}/bank-withdrawals`,
          args
        )
    }
  }
};

const actionCreators = {
  reconcileItems: {
    request: (payload: ReconcileItemsPayload, _actions, _dispatch, _getState) =>
      api.post(`financials/reconcile-items`, {
        items: payload.items.map((item) => ({
          ...item,
          is_reconciled: true
        }))
      }),
    reduce: {
      initial: _.identity,
      success: _.identity,
      failure: _.identity
    }
  } as CustomAction<ReconcileItemsPayload, unknown>,
  unreconcileItems: {
    request: (payload: ReconcileItemsPayload, _actions, _dispatch, _getState) =>
      api.post(`financials/reconcile-items`, {
        items: payload.items.map((item) => ({
          ...item,
          is_reconciled: false
        }))
      }),
    reduce: {
      initial: _.identity,
      success: _.identity,
      failure: _.identity
    }
  } as CustomAction<ReconcileItemsPayload, unknown>
};

export const financialsReconciliationsBankWithdrawalsModel = new Generator<
  BankWithdrawal,
  typeof actionCreators
>('financials/reconciliations/bank-withdrawals', config).createEntityModel({
  actionCreators
});
