import React from 'react';

import { Truncate } from '@rexlabs/text';
import { CellProps } from '@rexlabs/table';

import { FeeAmount } from 'src/modules/agency-fees/types/agency-fee-types';
import { formatDynamicAmount } from '../utils/format-dynamic-amount';

export function DynamicAmountTableCell({
  value
}: CellProps<unknown, FeeAmount>) {
  return <Truncate>{formatDynamicAmount(value)}</Truncate>;
}
