import React from 'react';
import { BlockConfig } from 'view/components/record-screen/types';
import { Grid } from 'view/components/@luna/form/grid';
import { Field } from '@rexlabs/form';
import '../utils/checklist-item-trigger-validation-rules';
import { ValueListSelect } from 'view/components/table/toolbar/inputs/value-list-select';
import { ChecklistItemTriggerInput } from 'view/components/inputs/checklist-item-trigger-input/checklist-item-trigger-input';
import Box from '@rexlabs/box';
import invariant from 'invariant';
import {
  ChecklistItemActionType,
  ChecklistTemplateItem
} from '../types/ChecklistTemplate';
import { ChecklistTemplateItemTriggerEditFrom } from '../types/ChecklistTemplatesForm';
import { MessageTemplateContent } from '../components/message-template-content';
import { GenericTaskStatusSelect } from '../../common/components/generic-task-status-select';
import { TaskType } from '../../common/types/TaskType';

export const checklistTemplateItemTriggerDetailsBlock: BlockConfig<
  ChecklistTemplateItem,
  { taskType: TaskType },
  ChecklistTemplateItemTriggerEditFrom
> = {
  id: 'checklist-template-item-trigger-details',
  validate: {
    definitions: {
      trigger: {
        rules:
          'required|fullTriggerIsRequired|mustUseOnCompletionForFrontendDrivenActions'
      },
      action: { rules: 'required' },
      template: {
        rules: 'required_if:action.id,send_email|required_if:action.id,send_sms'
      },
      task_status: { rules: 'required_if:action.id,set_status' }
    },
    messages: {
      required_if: 'The :attribute field is required.'
    }
  },
  Edit({ values, setFieldValue, blockProps }) {
    const actionId = values?.action?.id;
    const triggerValue = values?.trigger;

    invariant(blockProps, 'blockProps is required');

    return (
      <>
        <Grid columns={2}>
          <Field
            name='trigger'
            label='Trigger'
            Input={ChecklistItemTriggerInput}
            optional={false}
            HelpTooltipContent={
              triggerValue?.trigger_type?.id === 'on_due_date'
                ? () =>
                    'If the subtask due date and time has already passed when the task is created, then the action will trigger immediately.'
                : undefined
            }
          />
          <Field
            id='action'
            name='action'
            label='Action'
            Input={ValueListSelect}
            onChange={() => {
              setFieldValue?.('template', null);
            }}
            inputProps={{
              type: 'checklist_item_action_type',
              searchable: false,
              placeholder: 'Select a action',
              disabled: !(
                (triggerValue?.trigger_type?.id === 'on_due_date' &&
                  triggerValue?.time) ||
                triggerValue?.trigger_type?.id === 'on_completion'
              )
            }}
          />
        </Grid>
        {actionId === 'set_status' && (
          <>
            <Box>
              <Grid columns={1}>
                <Field
                  optional={false}
                  id='task_status'
                  name='task_status'
                  label='Task status'
                  Input={GenericTaskStatusSelect}
                  inputProps={{
                    taskType: blockProps?.taskType
                  }}
                />
              </Grid>
            </Box>
          </>
        )}
        {(actionId === 'send_email' || actionId === 'send_sms') && (
          <MessageTemplateContent
            checklistItemActionType={actionId as ChecklistItemActionType}
            taskType={blockProps?.taskType}
          />
        )}
      </>
    );
  }
};
