import { FieldArrayPassthroughProps } from '@rexlabs/form';
import { createContext } from 'react';
import { TaskType } from 'src/modules/tasks/common/types/TaskType';
import {
  ChecklistTemplatesForm,
  ChecklistTemplatesItemForm
} from '../../types/ChecklistTemplatesForm';

export type FieldArrayContext = {
  fieldArrayProps: FieldArrayPassthroughProps;
  setFieldValue: (field: string, value: unknown) => void;
  values: Partial<ChecklistTemplatesForm>;
  taskType: TaskType;
};

export const fieldArrayContext = createContext({
  fieldArrayProps: {} as FieldArrayPassthroughProps,
  setFieldValue: (_field: string, _value: ChecklistTemplatesItemForm) => {
    // noop
    console.warn('setFieldValue not implemented');
  },
  values: {},
  taskType: 'todo' // for lack of a better default
} as FieldArrayContext);

export const FieldArrayContextProvider = fieldArrayContext.Provider;
