import { CreateTenancy, TenantsForm } from 'data/models/entities/tenancies';
import { useFeatureFlags } from 'view/components/@luna/feature-flags';
import { FLAGS } from 'utils/feature-flags'; // TODO: remove when guarantor flag is turned on in prod

// TODO: remove when guarantor flag is turned on in prod
// HACK: Strictly, the old values has a different shape for role here compared to TenantsForm
// but since this is temporary I'm omitting the type for now.
const mapToApiOld = (values: any): CreateTenancy => {
  return {
    ...values,
    name: values?.tenants?.map?.(({ contact }) => contact?.name)?.join(' & '),
    tenants: values?.tenants.map((tenant) => ({
      role: { id: tenant.role.id },
      contact: { id: tenant.contact.id },
      payment_reference: tenant.payment_reference
      // removing guarantors from the payload here
    }))
  };
};

type mapTenancyFormToApiFunction = (values: TenantsForm) => CreateTenancy;

export function useMapTenancyFormToApi(): mapTenancyFormToApiFunction {
  const { hasFeature } = useFeatureFlags();

  const hasGuarantorAndOccupierUpdates = hasFeature(
    FLAGS.GUARANTOR_AND_OCCUPIER_UPDATES
  );

  return (values: TenantsForm) => {
    if (!hasGuarantorAndOccupierUpdates) {
      return mapToApiOld(values);
    }

    return {
      ...values,
      tenants: values?.tenants.map((tenant) => ({
        role: { id: tenant.role },
        contact: { id: tenant.contact.id },
        payment_reference: tenant.payment_reference,
        ...(tenant.has_guarantor && tenant.role === 'tenant'
          ? {
              guarantors: tenant.guarantors?.map?.((guarantor) => ({
                id: guarantor.id
              }))
            }
          : {})
      }))
    };
  };
}
