import React from 'react';

import { CellProps } from '@rexlabs/table';

import { RecordIcon } from 'view/components/record-icon/record-icon';

import { IconCell } from 'src/modules/common/components/cells/icon-cell';

export function TaskTypeIconCell({ value }: CellProps) {
  return (
    <IconCell
      label={value.label}
      Icon={() => <RecordIcon recordType={value.id} />}
    />
  );
}
