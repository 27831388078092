import { Field } from '@rexlabs/form';
import { TextArea } from '@rexlabs/text-input';
import { Disbursement } from 'data/models/entities/financials/disbursements/disbursements';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { formatCurrency } from 'utils/formatters';
import { Grid } from 'view/components/@luna/form/grid';
import { BlockConfig } from 'view/components/record-screen/types';

function getDisbursementTotal(disbursements: Disbursement[]) {
  return disbursements.reduce((total, disbursement) => {
    return total + disbursement.summary_total_disbursed;
  }, 0);
}

export const voidDisbursementsBlock: BlockConfig = {
  id: 'void-disbursements',
  title: () => <Trans i18nKey='disbursements.void.block.title' />,
  validate: {
    definitions: {
      reason: { rules: 'required' }
    }
  },
  Edit: ({ blockProps }) => {
    const { t } = useTranslation();
    return (
      <Grid columns={1}>
        <div>
          {t('disbursements.void.confirmation.label')}{' '}
          <b>
            {formatCurrency(getDisbursementTotal(blockProps.disbursements))}
          </b>
          .
          <br />
          <br />
          {t('disbursements.void.confirmation.description')}
        </div>
        <Field
          name='reason'
          label={t('disbursements.void.reason.label') as string}
          Input={TextArea}
        />
      </Grid>
    );
  }
};
