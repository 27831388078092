import { useTranslation } from 'react-i18next';
import { useGetGenericWipAction } from 'view/hooks/actions/use-get-generic-wip-action';

export function useGetDisbursableEntityActionGroup() {
  const { t } = useTranslation();
  const getGenericWipAction = useGetGenericWipAction();

  return () => [
    getGenericWipAction({
      id: 'disburse',
      group: 'disburse',
      actionName: t('disbursements.disburse'),
      label: t('disbursements.disburse-now') as string
    })
  ];
}
