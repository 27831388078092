import { StyleSheet, useStyles } from '@rexlabs/styling';
import { Body, Bold } from '@rexlabs/text';
import { InfoBanner } from 'view/components/@luna/notifications/banner';
import InfoCircleIcon from 'view/components/icons/info';
import React from 'react';
import { formatCurrency } from 'utils/formatters';

const styles = StyleSheet({
  list: {
    listStyleType: 'disc',
    paddingLeft: 12,
    marginLeft: 12
  }
});

export function ProgressiveTemplateBanner() {
  const s = useStyles(styles);

  return (
    <InfoBanner
      Icon={InfoCircleIcon}
      description={
        <>
          <Body as='div'>
            <Bold>Example: </Bold>
            <ul {...s('list')}>
              <li>
                The first {formatCurrency(0, 0)} to {formatCurrency(1000_00, 0)}{' '}
                will have 20% commission applied.
              </li>
              <li>
                The next {formatCurrency(1000_01)} to{' '}
                {formatCurrency(2000_00, 0)} will have 15% commission applied.
              </li>
            </ul>
          </Body>
          <Body>
            <Bold>If the invoice total is: </Bold>
            <ul {...s('list')}>
              <li>
                {formatCurrency(1000_00, 0)}: The commission is calculated at{' '}
                {formatCurrency(200_00, 0)}, as the entire amount falls within
                the first tier.
              </li>
              <li>
                {formatCurrency(2000_00, 0)}: The first{' '}
                {formatCurrency(1000_00, 0)} incurs a{' '}
                {formatCurrency(200_00, 0)} commission, and the next{' '}
                {formatCurrency(1000_00, 0)} incurs a {formatCurrency(149_99)}{' '}
                commission, totalling {formatCurrency(349_99)}.
              </li>
            </ul>
          </Body>
        </>
      }
    />
  );
}
