import React from 'react';
import { PropertyOwnership } from 'src/modules/property-ownerships/types/property-ownership-types';
import { RecordFlag } from 'view/components/record-flags/record-flag';
import { EntryLink } from './entry-link';

interface PropertyOwnershipEntryLinkProps {
  propertyOwnership: PropertyOwnership;
}

export function PropertyOwnershipEntryLink({
  propertyOwnership
}: PropertyOwnershipEntryLinkProps) {
  return (
    <EntryLink
      type='property_ownership'
      id={propertyOwnership.id}
      parentId={propertyOwnership.property_id}
    >
      <RecordFlag record={propertyOwnership} recordType='property_ownership' />
    </EntryLink>
  );
}
