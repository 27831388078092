import { api } from 'utils/api/api-client';
import { RecordSubmitHandler } from 'view/components/record-screen/utils';
import { PropertyAreaForm } from 'src/modules/property-areas/property-areas/types/PropertyAreaForm';
import { PropertyArea } from 'src/modules/property-areas/property-areas/types/PropertyArea';
import { useModelActions } from '@rexlabs/model-generator';
import { propertyAreasModel } from 'src/modules/property-areas/property-areas/models/property-areas-model';
import { useRecordCreatedToast } from 'src/modules/common/toasts/hooks/use-record-created-toast';
import { Property } from 'src/modules/properties/types/property-types';
import { propertiesModel } from 'data/models/entities/properties';

interface SubmitPropertyAreaProps {
  property: Property;
  onCreate?: (area?: PropertyArea) => void;
}

export function useCreatePropertyAreaSubmitHandler() {
  const { refreshItem } = useModelActions(propertiesModel);

  const addCreatedToast = useRecordCreatedToast(propertyAreasModel, {
    actions: []
  });
  return ({ property, onCreate }: SubmitPropertyAreaProps) => {
    const onSubmit: RecordSubmitHandler<PropertyAreaForm> = async ({
      values
    }) => {
      const requests = getTransformPropertyAreaFormToPipeline({
        values,
        property
      });

      const response = await api.post('request-pipelines', { requests });

      const data: PropertyArea = response.data.property_areas_item_order;

      addCreatedToast(data);
      await Promise.all([refreshItem({ id: property.id })]);
      onCreate?.(data);
      return data;
    };
    return onSubmit;
  };
}

function getTransformPropertyAreaFormToPipeline({
  values,
  property
}: {
  values: PropertyAreaForm;
  property: Property;
}) {
  const items = values.items?.filter((item) => item.name != null);

  const propertyAreaItems = items?.map((item, index) => {
    return {
      id: `property_area_items_${index}`,
      method: 'POST',
      path: '/api/v1/properties/property-area-items',
      json: {
        name: item.name,
        property_area: {
          id: '{{$.property_area.id}}'
        }
      }
    };
  });

  const itemOrderArray = items?.map((item, index) => {
    return `{{$.property_area_items_${index}.id}}`;
  });

  return [
    {
      id: 'property_area',
      method: 'POST',
      path: '/api/v1/properties/property-areas',
      json: {
        name: values.name,
        property: {
          id: property.id
        },
        ...(values.property_area_type
          ? {
              property_area_type: {
                id: values.property_area_type.id
              }
            }
          : {})
      }
    },
    ...(propertyAreaItems || []),

    {
      id: 'property_areas_item_order',
      method: 'PATCH',
      path: '/api/v1/properties/property-areas/{{$.property_area.id}}',
      json: {
        item_order: itemOrderArray
      }
    },
    {
      id: 'property_area_order',
      method: 'PATCH',
      path: `/api/v1/properties/${property.id}`,
      json: {
        area_order: Array.isArray(property.area_order)
          ? property.area_order.concat('{{$.property_area.id}}')
          : ['{{$.property_area.id}}']
      }
    }
  ];
}
