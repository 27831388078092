import React from 'react';
import { MobileCellWrapper } from 'src/modules/common/components/cells/mobile-cell-wrapper';
import { Small } from '@rexlabs/text';
import { ClampedDescriptionCell } from 'view/components/cells/clamped-description-cell';

export function MobileDescriptionCell({
  text,
  lineClamp = 2
}: {
  text: string;
  lineClamp?: number;
}) {
  return (
    <MobileCellWrapper>
      <Small as='div'>
        <ClampedDescriptionCell lineClamp={lineClamp} text={text} />
      </Small>
    </MobileCellWrapper>
  );
}
