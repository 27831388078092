import React from 'react';
import { CellProps } from '@rexlabs/table';
import { ContactPaymentMethod } from 'data/models/entities/contacts/payment-methods';
import { useTranslation } from 'react-i18next';

export type ContactPaymentMethodDetailsCellProps = CellProps<
  any,
  ContactPaymentMethod
>;

export function ContactPaymentMethodDetailsCell({
  value
}: ContactPaymentMethodDetailsCellProps) {
  const { t } = useTranslation();

  const isBPAY = value.payment_method.id === 'bpay';
  const isEFT_IBAN = value.payment_method.id === 'eft_iban';
  const isEFT_SWIFT = value.payment_method.id === 'eft_swift';
  const isEFT =
    value.payment_method.id === 'eft' ||
    value.payment_method.id === 'eft_bacs' ||
    value.payment_method.id === 'eft_fps';

  if (isBPAY) {
    return <>Biller code: {value.account_number}</>;
  }

  if (isEFT_IBAN) {
    return <>Account number: {value.account_number}</>;
  }

  if (isEFT || isEFT_SWIFT) {
    return (
      <>
        <div>
          {t('bank-accounts.branch-code.label.singular')} {value.branch_code}
        </div>
        <div>Account number: {value.account_number}</div>
      </>
    );
  }

  return <>n/a</>;
}

export function useGetContactPaymentDetailsToCsv() {
  const { t } = useTranslation();

  return (value) => {
    const isBPAY = value.payment_method.id === 'bpay';
    const isEFT_IBAN = value.payment_method.id === 'eft_iban';
    const isEFT_SWIFT = value.payment_method.id === 'eft_swift';
    const isEFT = value.payment_method.id === 'eft';

    if (isBPAY) {
      return `Biller code: ${value.account_number}`;
    }

    if (isEFT_IBAN) {
      return `Account number: ${value.account_number}`;
    }

    if (isEFT || isEFT_SWIFT) {
      return `${t('bank-accounts.branch-code.label.singular')}: ${
        value.branch_code
      } Account number: ${value.account_number}`;
    }

    return 'n/a';
  };
}
