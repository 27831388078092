import React from 'react';
import { Field } from '@rexlabs/form';
import { DateInput } from 'view/components/@luna/inputs/date-input/date-input';
import { EntitySelect } from 'view/components/inputs/selects/v4-selects/entity-select';
import { BlockConfig } from 'view/components/record-screen/types';
import { Grid } from 'view/components/@luna/form/grid';
import { Value } from 'view/components/values';
import { DateValue } from 'view/components/values/date';
import { MaintenanceTask } from '../types/MaintenanceTask';
import { MaintenanceFormData } from '../types/MaintenanceFormData';
import { usePropertyRelatedItems } from '../../common/hooks/use-property-related-items';

export const issuesReportedBlock: BlockConfig<
  MaintenanceTask,
  any,
  MaintenanceFormData
> = {
  id: 'issues-reported',
  title: 'Issues reported',
  View: ({ data }) => {
    return (
      <Grid columns={2}>
        <Value
          label='Reported by'
          value={data?.details?.reported_by?.display_name}
        />
        <DateValue label='Reported date' value={data?.details?.reported_date} />
      </Grid>
    );
  },
  Edit: ({ forms }) => {
    const propertyId = forms?.details?.values?.property?.id;

    const { loadItems: loadSuggestedReportedBy } = usePropertyRelatedItems({
      propertyId: propertyId,
      tenancyTypes: ['active', 'incoming', 'outgoing'],
      objectTypes: ['tenancy', 'ownership', 'contact'],
      includePortfolioMemberContacts: true
    });

    return (
      <Grid columns={2}>
        <Field
          id='reported_by'
          name='details.reported_by'
          label='Reported by'
          Input={EntitySelect}
          inputProps={{
            getSuggestedItems: loadSuggestedReportedBy,
            objectTypes: ['contact', 'tenancy', 'ownership']
          }}
        />

        <Field
          id='reported_date'
          name='details.reported_date'
          label='Reported date'
          Input={DateInput}
          inputProps={{
            clearable: true
          }}
        />

        {/* This is there to prevent form auto submit on pressing enter */}
        <input type='text' aria-label='_hack_' style={{ display: 'none' }} />
      </Grid>
    );
  }
};
