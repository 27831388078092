import React, { useCallback, useMemo } from 'react';
import { SelectProps, Autocomplete } from '@rexlabs/select-old';

import { api } from 'utils/api/api-client';

import { normaliseTenancy } from 'utils/normaliser/tenancy';
import { CreateTenancyDialog } from 'src/modules/tenancies/dialogs/create-tenancy-dialog';
import { useDialog } from '@rexlabs/dialog';
import { FieldActions } from '@rexlabs/form';

export type TenancySelectProps = Omit<SelectProps, 'items'> & {
  actions?: FieldActions;
};

/**
 * @deprecated Use v4-selects/tenancy-select instead
 */
export function TenancySelect({
  actions,
  fixtures: overrideFixtures,
  ...props
}: TenancySelectProps) {
  const createTenancyDialog = useDialog(CreateTenancyDialog);

  const fixtures = useMemo(
    () => [
      {
        label: 'Add tenancy',
        action: () =>
          createTenancyDialog.open({
            onCreate: (tenancy) => actions?.setValue(tenancy)
          })
      }
    ],
    [actions, createTenancyDialog]
  );

  const items = useCallback(async (term) => {
    const tenancies = await api.get(`/tenancies`, {
      // TODO: [AL-459] fix the fact that per_page get param breaks includes defined by model generator
      per_page: 30,
      include: 'properties',
      search: term
    });
    return tenancies.data;
  }, []);

  return (
    <Autocomplete
      {...props}
      fixtures={overrideFixtures || fixtures}
      items={items}
      normaliser={normaliseTenancy}
    />
  );
}
