/**
 * At the time of creating this, this was a copy src/modules/trust-journal-entries/blocks/bank-transaction-notes-table.tsx
 * Because of where this is being used, we can't rely on the passed in data to work out the query, so this has been hardcoded
 * to be used in the create receipt dialog.
 */
import * as React from 'react';

import { transformSingleActionsToRegularActionMenuItems } from 'utils/actions/transforms';

import { ReceiptFundsDialogProps } from 'src/modules/trust-journal-entries/dialogs/receipt-funds-dialog';

import { NotesListEmptyState } from 'src/modules/note/components/notes-list-empty-state';
import { useGetNoteActions } from 'src/modules/note/hooks/use-get-note-actions';
import { useGetNoteListLinkProps } from 'src/modules/note/hooks/use-get-note-list-link-props';
import { getNotesQuery } from 'src/modules/note/hooks/use-note-query';
import { NotesList } from 'src/modules/note/components/notes-list';
import { useGetCreateNoteAction } from 'src/modules/note/hooks/use-get-create-note-action';

import {
  BlockConfig,
  BlockViewProps
} from 'view/components/record-screen/types';
import { ActionButtons } from 'view/components/action-buttons';

function BankTransactionNotesTable({
  data,
  blockProps
}: BlockViewProps<
  ReceiptFundsDialogProps,
  ReceiptFundsDialogProps,
  { hideAddNoteToField: boolean }
>) {
  const noteQuery = React.useMemo(
    () =>
      getNotesQuery(
        'bank_statement_transaction_id',
        data!.bankStatementTransactionId!
      ),
    []
  );

  const getNoteActions = useGetNoteActions();
  const getNoteListLinkProps = useGetNoteListLinkProps();

  const EmptyState = React.useCallback(
    () => (
      <NotesListEmptyState
        recordType={'bank_statement_transaction'}
        record={{ id: data!.bankStatementTransactionId! }}
        hideAddNoteToField={blockProps?.hideAddNoteToField}
      />
    ),
    []
  );

  return (
    <NotesList
      getQuery={noteQuery ? () => noteQuery : undefined}
      Empty={EmptyState}
      getActionMenuItems={({ item }) =>
        getNoteActions(item, blockProps?.hideAddNoteToField)
      }
      getRowLinkProps={getNoteListLinkProps}
    />
  );
}

export const bankTransactionNotesTableBlock: BlockConfig<
  ReceiptFundsDialogProps,
  any,
  ReceiptFundsDialogProps,
  { hideAddNoteToField: boolean }
> = {
  id: 'notes',
  title: 'Notes',
  // NOTE: isEditable is only used when rendering the block in the record screens, and is used to show the edit button.
  // We don't want to show the edit button for the notes block, so we return false.
  // If the render screen changes, we may need to update this, but for now, its making sure we have the one block for both the dialog and record screen.
  isEditable: () => false,
  View: BankTransactionNotesTable,
  Edit: BankTransactionNotesTable,
  Actions: ({ data }) => {
    const getCreateNoteAction = useGetCreateNoteAction(
      'bank_statement_transaction'
    );

    return (
      <ActionButtons
        actions={transformSingleActionsToRegularActionMenuItems([
          getCreateNoteAction({
            attached: { id: data!.bankStatementTransactionId! },
            hideAddNoteToField: true
          })
        ])}
      />
    );
  }
};
