import dayjs, { Dayjs } from 'dayjs';
import invariant from 'invariant';

/**
 *
 *  ⚠️ IMPORTANT NOTE ⚠️
 *
 *  When using these utility functions in other modules, avoid calling them at the top level.
 *  The default timezone is set based on user settings in the `AuthenticationLayer` component.
 *  If called at the top level, these functions may not use the correct timezone, as the settings data
 *  might not be loaded by then. Ensure these functions are called inside components or other functions
 *  that will be lazily executed and have access to the correct timezone settings.
 *
 *  Eg:
 *  ./component.tsx
 *   ❌ - Bad example
 *   const today = getToday()
 *
 *   ✅ - Check example
 *   ./component.tsx
 *
 *   function Component() {
 *      const today = getToday();
 *   }
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
function doc() {
  //
}

export type DateSpecifier = string | Date | Dayjs;

const DEFAULT_FORMAT = 'YYYY-MM-DD';

/**
 * Get any durations that overlap date.
 * @param date
 * @param durations
 */
export const getDurationsDateOverlaps = (
  date: DateSpecifier,
  durations: [DateSpecifier, DateSpecifier][]
) => {
  const cmpDurations = durations.map(([start, end]) => [
    dayjs(start),
    dayjs(end)
  ]);
  const comparisonDate = dayjs(date);
  const overlappedDurations: [Date, Date][] = [];

  cmpDurations.forEach(([start, end]) => {
    if (comparisonDate <= end && comparisonDate >= start) {
      overlappedDurations.push([start.toDate(), end.toDate()]);
    }
  });

  return overlappedDurations;
};

/**
 * Sort durations
 * @param durations
 */
const sortDurations = (durations: [DateSpecifier, DateSpecifier][]) =>
  durations.sort(([startA], [startB]) => {
    if (dayjs(startA).isSame(dayjs(startB))) return 0;
    return dayjs(startA).isBefore(dayjs(startB)) ? -1 : 1;
  });

/**
 * Find the durations nearest to the given date (future/past)
 * @param date
 * @param durations
 */
export const durationNearestTo = (
  date: DateSpecifier,
  durations: [DateSpecifier, DateSpecifier][]
) => {
  const sortedDurations = sortDurations([...durations, [date, date]]);
  const idx = sortedDurations.findIndex(([start, _end]) => start === date);

  return {
    past: sortedDurations[idx - 1]
      ? sortedDurations[idx - 1].map((x) => dayjs(x).tz().toDate())
      : null,
    future: sortedDurations[idx + 1]
      ? sortedDurations[idx + 1].map((x) => dayjs(x).tz().toDate())
      : null
  };
};

/**
 * Returns the current date in UTC, in ISO8601 date format
 * @see {@link doc} for usage and examples
 */
export function getToday() {
  const now = new Date();

  return dayjs(now).tz().format(DEFAULT_FORMAT);
}

/**
 * @see {@link doc} for usage and examples
 */
export function getWithDeltaDays(deltaDays: number) {
  const now = new Date();
  const date = new Date(now.getTime() + deltaDays * 1000 * 60 * 60 * 24);

  return dayjs(date).tz().format(DEFAULT_FORMAT);
}

/**
 * @see {@link doc} for usage and examples
 */
export function getDaysFromNow(date: string | Date): string {
  return `${dayjs().tz().diff(date, 'day')} day(s) ago`;
}

/**
 * Format timestamp for BE
 * @see {@link doc} for usage and examples
 */
export function getUTCTimestamp(data: Date | string | Dayjs): string {
  // ISO will format the date in UTC
  return data && dayjs(data).tz().toISOString();
}

/**
 * @see {@link doc} for usage and examples
 */
export function getTodayTimezoneDate(timezone: string) {
  invariant(timezone, 'Timezone is a required arg');

  const now = new Date();
  return dayjs.tz(now, timezone).format('YYYY-MM-DD');
}

/**
 * Returns the current date in active timezone in `YYYY-MM-DD`
 * @see {@link doc} for usage and examples
 */
export function getTodayDateStringForActiveTz() {
  return dayjs().tz().format('YYYY-MM-DD');
}
