import { useModelActions } from '@rexlabs/model-generator';
import { RecordTypes } from 'data/models/types';
import React from 'react';
import { ActionDeclaration } from 'src/modules/common/actions/types/action-declaration-types';
import { statementsModel } from 'src/modules/statements/models/statements';
import { Statement } from 'src/modules/statements/types';
import { getRecordLabel } from 'utils/records/get-record-label';
import { ActionMenuSubtitle } from 'view/components/action-menu/action-menu-subtitle';
import EyeOpenIcon from 'view/components/icons/eye-open';
import { useDialog } from '@rexlabs/dialog';
import { ViewDocumentDialog } from 'src/modules/documents/dialogs/view-document-dialog';
import { getViewDocumentPropsFromPdfLink } from 'src/modules/documents/actions/use-get-view-documents-action';

type GetStatementViewActions = (
  statement: Statement
) => {
  summaryViewActions: ActionDeclaration[];
  detailedViewActions: ActionDeclaration[];
};

export function useGetStatementViewActions(): GetStatementViewActions {
  const { viewItem } = useModelActions(statementsModel);

  const viewDocumentDialog = useDialog(ViewDocumentDialog);

  return function (statement: Statement) {
    const summaryViewActions =
      statement.recipients.items.length > 0
        ? statement.recipients.items.map((contact) => {
            return {
              id: `view-${contact.display_name}`,
              label: `${getRecordLabel({
                type: RecordTypes.Contact,
                object: contact
              })}`,
              group: 'view-summary-income-statements',
              groupSubtitle: (
                <ActionMenuSubtitle
                  subtitle={'Summary income statement'}
                  Icon={EyeOpenIcon}
                />
              ),
              async handleAction() {
                viewDocumentDialog.open({
                  isLoading: true
                });

                const response = await viewItem({
                  statementData: { statementId: statement.id },
                  recipientId: contact.id
                });

                viewDocumentDialog.update(
                  getViewDocumentPropsFromPdfLink({
                    link: response.data.link
                  })
                );
              }
            };
          })
        : [];

    const detailedViewActions = statement.recipients.items.map((contact) => {
      return {
        id: `view-${contact.display_name}`,
        label: `${getRecordLabel({
          type: RecordTypes.Contact,
          object: contact
        })}`,
        group: 'view-detailed-income-statements',
        groupSubtitle: (
          <ActionMenuSubtitle
            subtitle={'Detailed income statement'}
            Icon={EyeOpenIcon}
          />
        ),
        async handleAction() {
          viewDocumentDialog.open({
            isLoading: true
          });

          const response = await viewItem({
            reportId: 'income-detailed',
            statementData: { statementId: statement.id },
            recipientId: contact.id
          });

          viewDocumentDialog.update(
            getViewDocumentPropsFromPdfLink({
              link: response.data.link
            })
          );
        }
      };
    });

    return { summaryViewActions, detailedViewActions };
  };
}
