import React from 'react';

import { StyleSheet, useStyles } from '@rexlabs/styling';

// Styles pulled straight from vivid
const styles = StyleSheet({
  table: {
    height: '100%',
    width: '100%',
    tableLayout: 'fixed',
    borderCollapse: 'separate',

    '& tbody': {
      position: 'relative'
    },

    '& thead tr, & tbody tr': {
      display: 'table-row'
    },

    // Sticky columns
    '& [data-sticky-td]': {
      position: 'sticky'
    },
    // End sticky columns
    '& thead > *': {
      boxShadow: 'none'
    }
  }
});

export function Table({ children }: { children: React.ReactNode }) {
  const s = useStyles(styles);

  return <table {...s('table')}>{children}</table>;
}
