import { useGetPrimaryRecordActionGroup } from 'view/hooks/actions/use-get-primary-record-action-group';
import { ActionDeclaration } from 'src/modules/common/actions/types/action-declaration-types';
import { useStatusChangeAction } from 'src/modules/common/actions/status/use-status-change-action';
import { DestructiveButton } from '@rexlabs/button';
import { useModelActions } from '@rexlabs/model-generator';
import {
  AccountingJournalEntry,
  AccountingJournalEntryStatus
} from '../types/account-journal-entry-types';
import { accountingJournalEntryModel } from '../model/accounting-journal-entry-model';
import { accountingJournalEntryLineItemModel } from '../model/accounting-journal-entry-line-item-model';

export function useAccountingJournalEntryActions(): (
  accountingJournalEntry: AccountingJournalEntry
) => ActionDeclaration[] {
  const getPrimaryRecordActionGroup = useGetPrimaryRecordActionGroup();
  const statusChangeAction = useStatusChangeAction(accountingJournalEntryModel);

  const {
    refreshLists: refreshAccountingJournalEntryLineItemLists
  } = useModelActions(accountingJournalEntryLineItemModel);

  return function (accountingJournalEntry: AccountingJournalEntry) {
    const afterStatusChange = () => {
      refreshAccountingJournalEntryLineItemLists();
    };

    const voidAction = statusChangeAction<AccountingJournalEntryStatus>({
      record: accountingJournalEntry,
      status: 'void',
      dialogOptions: {
        label: 'Void entry',
        submitLabel: 'Void entry',
        helperText:
          'Please provide a reason why this journal entry is being voided.',
        title: 'Void manual entry',
        SubmitButton: DestructiveButton,
        afterAction: afterStatusChange
      }
    });

    return [
      ...getPrimaryRecordActionGroup(
        'accounting_journal_entry',
        accountingJournalEntry.id
      ),
      ...(accountingJournalEntry?.type.id === 'manual' &&
      !accountingJournalEntry?.voided_at
        ? [voidAction]
        : [])
    ];
  };
}
