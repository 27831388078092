import React from 'react';

import { Field } from '@rexlabs/form';

import { Contact } from 'src/modules/contacts/types/contact-types';

import { ValueListItem } from 'utils/normaliser/value-list-item';

import { WithdrawalPaymentDetailsForm } from 'src/modules/trust-journal-entries/components/withdrawal-payments-details-form';
import { WithdrawalPaymentDetailsValues } from 'src/modules/trust-journal-entries/components/withdrawal-payments-details-values';
import { getWithdrawalDetailsFormValidationRules } from 'src/modules/trust-journal-entries/utils/get-withdrawal-details-form-validation-rules';

import { BlockConfig } from 'view/components/record-screen/types';
import { Column, Grid } from 'view/components/@luna/form/grid';
import { Value } from 'view/components/values';
import { InfoBanner } from 'view/components/@luna/notifications/banner';
import InfoCircleIcon from 'view/components/icons/info';
import { ContactSelect } from 'view/components/inputs/selects/contact';

import {
  Settings,
  TAX_PAYMENT_ACCOUNT_DETAILS_SETTING_KEY,
  TaxPaymentSettings
} from '../../hooks/useSettings';

type HMRCTaxFields = Omit<TaxPaymentSettings, 'payee' | 'payment_method_id'> & {
  payee: Contact;
  method: ValueListItem;
};

type HMRCTaxPaymentSettingsValues = {
  [TAX_PAYMENT_ACCOUNT_DETAILS_SETTING_KEY]: HMRCTaxFields;
};
type HMRCTaxField = keyof HMRCTaxFields;

const payeeField: HMRCTaxField = 'payee';

const payeeLabel = 'Payee';

const Banner = () => (
  <InfoBanner
    Icon={InfoCircleIcon}
    description='Quarterly payments issued to HMRC will be sent to these payment details.'
  ></InfoBanner>
);

function getFieldPath(field: HMRCTaxField) {
  return `${TAX_PAYMENT_ACCOUNT_DETAILS_SETTING_KEY}.${field}`;
}

const rules = {
  [getFieldPath(payeeField)]: { rules: 'required', name: 'tax payee' },
  ...getWithdrawalDetailsFormValidationRules(
    TAX_PAYMENT_ACCOUNT_DETAILS_SETTING_KEY
  )
};

export const hmrcTaxPaymentSettingsBlock: BlockConfig<
  Settings,
  any,
  HMRCTaxPaymentSettingsValues
> = {
  id: 'hmrc-tax-payment-settings',
  title: 'HMRC tax payment settings',
  validate: {
    definitions: rules,
    messages: {
      required_if: 'The field is required.'
    }
  },
  View: ({ values }) => {
    const hmrcTaxPaymentSettings =
      values?.[TAX_PAYMENT_ACCOUNT_DETAILS_SETTING_KEY];

    return (
      <Grid columns={2}>
        <Value
          label={payeeLabel}
          value={hmrcTaxPaymentSettings?.[payeeField]?.display_name}
        />
        <WithdrawalPaymentDetailsValues
          fieldPrefix={TAX_PAYMENT_ACCOUNT_DETAILS_SETTING_KEY}
          values={values}
        />
        <Column width={2}>
          <Banner />
        </Column>
      </Grid>
    );
  },
  Edit: ({ values }) => {
    return (
      <Grid columns={2}>
        <Field<typeof ContactSelect>
          id={getFieldPath(payeeField)}
          label={payeeLabel}
          name={getFieldPath(payeeField)}
          Input={ContactSelect}
        />
        <WithdrawalPaymentDetailsForm
          fieldPrefix={TAX_PAYMENT_ACCOUNT_DETAILS_SETTING_KEY}
          values={values}
          excludePaymentMethodIds={['cheque']}
        />
        <Column width={2}>
          <Banner />
        </Column>
      </Grid>
    );
  }
};
