import { BankAccount } from 'data/models/entities/financials/bank-accounts';
import { api } from 'utils/api/api-client';
import { useCachedItems } from './cached-items';

export const useDefaultBankAccount = () => {
  const { items, isLoading } = useCachedItems<BankAccount>(
    'default-bank-account',
    async () => {
      const bankAccounts = await api.get(`/financials/bank-accounts`, {
        q: 'is_default.eq(true)'
      });
      return bankAccounts.data;
    },
    { prefetch: true }
  );

  let item: BankAccount | null = null;

  if (items && items.length > 0) {
    item = items[0];
  }

  return {
    isLoading,
    defaultBankAccount: item
  };
};
