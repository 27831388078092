import { ButtonProps, GhostIconButton } from '@rexlabs/button';
import React from 'react';
import RemoveIcon from 'view/components/icons/remove';
import { StyleSheet, useStyles } from '@rexlabs/styling';

const defaultStyles = StyleSheet({
  removeIcon: {
    color: ({ token }) => token('color.textStyle.danger.idle.default')
  }
});

export function RemoveIconButton({ onClick, ...rest }: ButtonProps) {
  const s = useStyles(defaultStyles);
  return (
    <GhostIconButton
      Icon={RemoveIcon}
      onClick={onClick}
      {...s('removeIcon')}
      aria-label={`Remove`}
      {...rest}
    />
  );
}
