import { useQuery } from 'react-query';
import { api } from 'utils/api/api-client';

export type TenancyPrepaymentStats = {
  general_funds_amount: number;
  other_prepayments_amount: number;
  rent_prepayments_amount: number;
  summary_balance_amount: number;
};

export function useGetTenancyPrepaymentStats(
  tenancyId: string | undefined,
  bankAccountId: string | undefined
): { data: TenancyPrepaymentStats } {
  const { data } = useQuery(
    ['prepayment-bucket-stats', tenancyId, bankAccountId],
    async () => {
      const response = await api.get(
        `/financials/tenancy-prepayment-buckets/${bankAccountId}/${tenancyId}/stats`
      );

      return response.data;
    },
    {
      enabled: !!tenancyId && !!bankAccountId
    }
  );

  return { data };
}
