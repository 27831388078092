import React from 'react';

import {
  TitleBlock,
  TitleBlockProps
} from 'view/components/cards/title/title-block';

import { AccountingJournalEntry } from 'src/modules/account-journal-entries/types/account-journal-entry-types';
import { useFeatureFlags } from 'view/components/@luna/feature-flags';
import InvoicesIcon from 'view/components/icons/invoices';
import { titleBlockStatusTag } from 'src/modules/common/components/status-tag';
import { getRecordTitle } from 'utils/records/get-record-title';
import { accountingJournalEntryStatusMap } from '../maps/accounting-journal-entry-status-map';
import { getAccountingJournalEntrySourceLabel } from '../utils/get-accounting-journal-entry-source-label';
import { useAccountingJournalEntryActions } from '../actions/use-accounting-journal-entry-actions';

type AccountingJournalEntryTitleBlockProps = {
  accountingJournalEntry: AccountingJournalEntry;
};

export function AccountingJournalEntryTitleBlock({
  accountingJournalEntry
}: AccountingJournalEntryTitleBlockProps) {
  const { hasFeature } = useFeatureFlags();

  const hasManualAccountingJournalEntriesFeature = hasFeature(
    'manual_accounting_journal_entries'
  );

  const title = hasManualAccountingJournalEntriesFeature
    ? getAccountingJournalEntrySourceLabel(accountingJournalEntry)
    : getRecordTitle({
        type: 'accounting_journal_entry',
        object: accountingJournalEntry
      });

  const tags: TitleBlockProps['tags'] = hasManualAccountingJournalEntriesFeature
    ? [
        titleBlockStatusTag(
          accountingJournalEntry.status,
          accountingJournalEntryStatusMap
        )
      ]
    : [];

  const actions = useAccountingJournalEntryActions();

  return (
    <TitleBlock
      title={title}
      Icon={hasManualAccountingJournalEntriesFeature ? InvoicesIcon : undefined}
      type={'accounting_journal_entry'}
      reference={accountingJournalEntry.record_reference}
      tags={tags}
      actions={
        hasManualAccountingJournalEntriesFeature &&
        accountingJournalEntry.type.id === 'manual'
          ? actions(accountingJournalEntry)
          : undefined
      }
    />
  );
}
