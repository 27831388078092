import { useCallback } from 'react';
import { api } from 'utils/api/api-client';
import { useModelActions } from '@rexlabs/model-generator';
import { uniqueId } from 'lodash';
import { mapChecklistTemplateItemFormDataToChecklistTemplateItem } from '../mappers/map-checklist-template-item-form-data-to-checklist-template-item';
import { ChecklistTemplatesForm } from '../types/ChecklistTemplatesForm';
import {
  checklistTemplatesModel,
  GROUP_INCLUDES,
  ITEM_INCLUDES
} from '../models/checklist-templates-model';
import { TaskType } from '../../common/types/TaskType';
import {
  injectGroupsForOutcomeItem,
  placeholderPrefix
} from '../utils/inject-groups-for-outcome-item';
import { transformChecklistFormDataToPipelineRequest } from '../utils/transform-checklist-form-data-to-pipeline-request';
import { useGetIsSmartChecklist } from './use-get-is-smart-checklist';

export const useCreateChecklistItemSubmitHandler = (
  checklistTemplateId: string,
  taskType: TaskType
) => {
  const { refreshItem } = useModelActions(checklistTemplatesModel);
  const getIsSmartChecklist = useGetIsSmartChecklist();

  const onHandleSubmit = useCallback(
    async function handleSubmit({ values }) {
      const placeholderId = uniqueId(placeholderPrefix);
      const checklistTemplateItem = mapChecklistTemplateItemFormDataToChecklistTemplateItem(
        { checklistTemplateItemFormData: { id: placeholderId, ...values } }
      );

      const groupsToAdd = injectGroupsForOutcomeItem(checklistTemplateItem);

      const requests = transformChecklistFormDataToPipelineRequest({
        values: {
          checklist_template_items: [checklistTemplateItem],
          checklist_template_item_groups: groupsToAdd
        } as Partial<ChecklistTemplatesForm>,
        checklistTemplateId: checklistTemplateId,
        isSmartChecklist: getIsSmartChecklist(taskType)
      });

      await api.post('request-pipelines', {
        requests
      });

      await refreshItem({
        id: checklistTemplateId,
        args: {
          include: `${ITEM_INCLUDES},${GROUP_INCLUDES}`
        }
      });

      return true;
    },
    [checklistTemplateId, getIsSmartChecklist, refreshItem]
  );

  return { onHandleSubmit };
};
