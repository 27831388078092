import React from 'react';
import { Column, Grid } from '@rexlabs/grid';
import { Field } from '@rexlabs/form';
import { ContactPaymentMethod } from 'view/components/inputs/selects/contact-payment-method';
import { SecurityDepositAuthorityContactSelect } from 'view/components/inputs/selects/security-deposit-authority-contact';

export interface HeldByBondAuthorityFormProps {
  values: any;
}

export function HeldByBondAuthorityForm({
  values
}: HeldByBondAuthorityFormProps) {
  const heldByContactId = values?.held_by_contact?.id;
  return (
    <Grid>
      <Column width={12}>
        <Field
          id='held_by_contact'
          name='held_by_contact'
          label='Held by'
          Input={SecurityDepositAuthorityContactSelect}
          optional={false}
        />
      </Column>
      <Column width={12}>
        <Field
          id='initial_payment_method'
          name='initial_payment_method'
          label='Initial lodgement default payment method'
          Input={ContactPaymentMethod}
          inputProps={{
            forDisbursements: false,
            disabled: !heldByContactId,
            contactId: heldByContactId
          }}
          optional={false}
        />
      </Column>
      <Column width={12}>
        <Field
          id='top_up_payment_method'
          name='top_up_payment_method'
          label='Top up lodgement default payment account '
          Input={ContactPaymentMethod}
          inputProps={{
            forDisbursements: false,
            disabled: !heldByContactId,
            contactId: heldByContactId
          }}
          optional={false}
        />
      </Column>
    </Grid>
  );
}
