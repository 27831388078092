import { useModelActions } from '@rexlabs/model-generator';
import { Tenancy, tenancyModel } from 'data/models/entities/tenancies';
import { useModelDownloadAction } from 'src/modules/common/actions/reporting/use-model-download-action';
import { PropertyTenancy } from 'src/modules/property-tenancies/types/property-tenancy-types';

interface getPaymentHistoryDownloadParams {
  tenancy?: Tenancy;
  propertyTenancy?: PropertyTenancy;
  isDisabled?: boolean;
}

export function useGetPaymentHistoryDownloadAction() {
  const { downloadPaymentHistory } = useModelActions(tenancyModel);
  const getModelDownloadAction = useModelDownloadAction(tenancyModel);

  return ({
    tenancy,
    propertyTenancy,
    isDisabled
  }: getPaymentHistoryDownloadParams) => {
    const downloadPdf = getModelDownloadAction({
      fetcher: () =>
        downloadPaymentHistory({
          id: propertyTenancy!.id
        }),
      label: 'Download bank deposit (PDF)',
      record: tenancy!
    });

    return {
      label: 'Download tenant payment history',
      handleAction: () => downloadPdf.handleAction?.(),
      disabled: !tenancy || !propertyTenancy || isDisabled
    };
  };
}
