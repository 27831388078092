import React from 'react';
import { RecordObject, RecordType } from 'data/models/types';

import { RecordLink } from '../record-link/record-link';
import { FullRecordLink } from '../record-link/full-record-link';

import { Value, ValuedValueProps } from './';

interface RecordLinkValueProps extends Omit<ValuedValueProps, 'id'> {
  type?: RecordType | 'unknown';
  id?: string;
  record?: Partial<RecordObject>;
}

export function RecordLinkValue({
  value,
  label,
  emptyValue,
  type,
  record,
  ...linkProps
}: RecordLinkValueProps) {
  if (record) {
    return (
      <Value
        label={label}
        emptyValue={emptyValue}
        value={
          record.object ? (
            <FullRecordLink recordObject={record as RecordObject} />
          ) : undefined
        }
      />
    );
  }

  return (
    <Value
      label={label}
      emptyValue={emptyValue}
      value={
        type !== 'unknown' && value ? (
          <RecordLink {...linkProps} type={type!}>
            {value}
          </RecordLink>
        ) : (
          value || undefined
        )
      }
    />
  );
}
