import { useCallback } from 'react';
import { Payable } from 'utils/api/get-payables';

export function useGetSuggestedInvoices({
  lineItems = [],
  payables = []
}: {
  lineItems?: Record<string, any>[];
  payables?: Payable[];
}) {
  const lineItemInvoices = lineItems
    ?.filter((lineItem) => !!lineItem.invoice_payment?.invoice)
    .map((lineItem) => lineItem.invoice_payment.invoice);

  const unusedPayables = payables?.filter((payable) => {
    return !lineItemInvoices?.find(
      (lineItemInvoice) => lineItemInvoice.id === payable.invoice.id
    );
  });

  const getSuggestedInvoices = useCallback(
    () => unusedPayables?.map((unusedPayable) => unusedPayable.invoice),
    [unusedPayables]
  );

  return { getSuggestedInvoices };
}
