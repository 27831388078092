import { BulkAction } from '@rexlabs/table/lib/types/bulk-actions/simple';
import { Column, Columns } from 'view/components/table';
import _ from 'lodash';

export const copyToClipboardBulkAction = ({
  selectedItems,
  columns
}: {
  selectedItems: any[];
  columns: Columns;
}): BulkAction => {
  return {
    label: 'Copy to clipboard',
    handleAction: () => {
      const columnsToExport = columns.filter(
        (column) => !column?.excludeFromExport
      );

      const headerCellsTextContent: string[] = columnsToExport.map((column) => {
        if (column.headerString) {
          return column.headerString;
        }

        if (typeof column.Header === 'string') {
          return column.Header as string;
        }

        return 'NYI';
      });

      const bodyRowsTextContent: string[][] = selectedItems
        .map((item) => {
          return columnsToExport.map((column) => {
            const cell = resolveCell(item, column);

            if (column.toCsv) {
              return column.toCsv(cell);
            }

            //typeof null === 'object' :/
            if (cell === null) {
              return 'NULL';
            }

            switch (typeof cell) {
              case 'number':
                return (cell as number).toString();

              case 'boolean':
                return cell ? 'TRUE' : 'FALSE';

              case 'string':
                return cell.trim();

              default:
                return 'NYI';
            }
          });
        })
        .map((row) => {
          return row?.map((cell) => {
            return cell?.replace('+', "'+").replace(/(\r\n|\n|\r|\n\r)/gm, ' ');
          });
        });

      const tableTextContent = [headerCellsTextContent, ...bodyRowsTextContent];

      const tableTextContentTsv = tableTextContent
        .map((array) => array.join('\t'))
        .join('\n');

      navigator.clipboard.writeText(tableTextContentTsv);
    }
  };
};

function resolveCell(row: any, column: Column) {
  if (column.accessor) {
    if (typeof column.accessor === 'string') {
      return _.get(row, column.accessor);
    }

    return column.accessor(row);
  }

  return _.get(row, column.id!);
}
