import React, { useMemo } from 'react';

import { BlockConfig } from 'view/components/record-screen/types';
import { ActionButtons } from 'view/components/@luna/action-buttons';
import { TenanciesList } from 'src/modules/tenancies/components/tenancies-list';
import { useGetCreateTenancyAction } from 'src/modules/tenancies/hooks/use-get-create-tenancy-action';

export const tenanciesBlock: BlockConfig = {
  id: 'tenancies',
  title: 'Tenancies',
  View: ({ data }) => {
    return <TenanciesList contactId={data.id} />;
  },
  Actions: () => {
    const getCreateTenancy = useGetCreateTenancyAction();
    const createTenancy = useMemo(() => getCreateTenancy(), []);
    return <ActionButtons actions={[createTenancy]} />;
  }
};
