import React, { useCallback } from 'react';
import { query, useModelActions } from '@rexlabs/model-generator';
import { useDialog } from '@rexlabs/dialog';
import { ActionMenuItem } from '@rexlabs/action-menu';
import { useMediaQuery } from '@rexlabs/breakpoints';

import { ActionButtons } from 'view/components/@luna/action-buttons';

import { BlockConfig } from 'view/components/record-screen/types';
import AddIcon from 'view/components/icons/add';

import {
  sharedIncludes,
  tasksModel
} from 'src/modules/tasks/common/models/tasks-model';
import { useGetRemoveTaskLinkAction } from 'src/modules/tasks/common/actions/use-get-remove-task-link-action';
import { transformActionDeclarationsToActionMenuItems } from 'utils/actions/transforms';
import { useGetUpdateTaskLinkAction } from 'src/modules/tasks/common/actions/use-get-update-task-link-action';
import { RecordTable } from 'view/components/table';
import { AnyTaskProgressCell } from '../../common/components/any-task-progress-cell';
import { Task } from '../../common/types/Task';
import { SummaryCell } from '../../common/components/summary-cell';
import { useGetAnyTaskActions } from '../../common/hooks/action-menu-items/use-get-any-task-actions';
import { CreateTaskDialog } from '../../common/dialogs/create-task-dialog';

const columns = [
  {
    id: 'summary',
    Header: 'Summary',
    Cell: SummaryCell,
    accessor: (item: Task<any>) => item,
    width: 380,
    toCsv: (item: Task<any>) => item.summary
  },
  {
    id: 'status',
    Header: 'Task progress',
    Cell: AnyTaskProgressCell,
    accessor: (item: Task<any>) => item,
    width: 200,
    toCsv: (item: Task<any>) =>
      item?.details?.status?.label || item?.status?.label
  }
];

const getFilterByParentTaskId = (taskId?: string) => {
  return taskId
    ? [
        {
          field: 'task_link_child_task_id',
          op: 'eq',
          value: taskId
        },
        {
          field: 'closed_at',
          op: 'eq',
          value: 'null'
        }
      ]
    : [];
};

const taskQuery = query`{
    ${tasksModel} {
      id
      property {
        active_property_tenancy
      }
      managed_by
      details
      todoDetails
      leaseReviewDetails
      maintenanceDetails
      moveOutDetails
      moveInDetails
      arrearsDetails
      inspectionDetails
      propertyComplianceDetails
      quoteDetails
      workOrderDetails
      task_links
    }
  }`;

// NOTE: This has been mostly copy pasted from src/modules/tasks/common/blocks/linked-tasks-block.tsx
export const inspectionReportLinkedTasksBlock: BlockConfig<Task<any>> = {
  id: 'tasks',
  title: 'Tasks',
  View: ({ data }) => {
    const getRemoveLinkedTaskAction = useGetRemoveTaskLinkAction({
      parentTask: data
    });

    const getUpdateLinkedTaskAction = useGetUpdateTaskLinkAction({ data });

    const getTaskActions = useGetAnyTaskActions();

    const taskLinksKey = data?.task_links?.data
      ?.map((taskLink) =>
        [taskLink.child_task?.id, taskLink.relation?.id].join('-')
      )
      .join(',');

    const getActionItems = React.useMemo(() => {
      return ({ item }) =>
        [
          ...getTaskActions(item),
          ...transformActionDeclarationsToActionMenuItems([
            getRemoveLinkedTaskAction({ task: item }),
            getUpdateLinkedTaskAction({ task: item })
          ])
        ] as ActionMenuItem[] | undefined;
      // We need to make sure this updates for parentTask changes
      // but data isn't stable and will cause the table to rerender repeatedly
    }, [taskLinksKey]);

    if (!data?.task_links?.data?.length) {
      return null;
    }

    return (
      <RecordTable
        pagination={{ itemsPerPage: 10 }}
        getQuery={() => taskQuery}
        columns={columns}
        getActionMenuItems={getActionItems}
        // HACK: This is a hack to prevent the table from showing the empty state.
        // We just want to show the action buttons. While we have the condition above to
        // return null, below covers the case where we only have closed linked tasks
        emptyWithoutTable={true}
        Empty={() => null}
        forcedGlobalFilter={getFilterByParentTaskId(data?.id)}
      />
    );
  },
  Actions: ({ data }) => {
    const { refreshItem, refreshLists } = useModelActions(tasksModel);
    const { open: openCreateTaskDialog } = useDialog(CreateTaskDialog);

    const onLinkTaskClick = useCallback(() => {
      return openCreateTaskDialog({
        onCreate: () => {
          refreshItem({
            id: data?.id,
            args: { include: sharedIncludes.join(',') }
          });
          refreshLists();
        },
        ...(data?.property
          ? {
              initialPropertyValue: data?.property
            }
          : {}),
        ...(data
          ? {
              initialTaskRelationshipsValue: [data]
            }
          : {}),
        ...(data?.details?.reported_by
          ? {
              initialReportedByValue: data?.details?.reported_by
            }
          : {}),
        ...(data?.managed_by
          ? {
              initialManagedByValue: data?.managed_by
            }
          : {})
      });
    }, [data]);

    const matchesMobile = useMediaQuery({ maxWidth: 's' });
    return (
      <ActionButtons
        actions={[
          {
            ...(matchesMobile ? { label: 'Link task' } : {}),
            Icon: AddIcon,
            handleAction: onLinkTaskClick
          }
        ]}
      />
    );
  }
};
