import { useDialog } from '@rexlabs/dialog';
import { ActionDeclaration } from 'src/modules/common/actions/types/action-declaration-types';
import { CreateMessageTemplateRecordDialog } from '../../dialogs/create-message-template-record-dialog';

export function useGetCreateMessageTemplateAction() {
  const { open: openCreateMessageTemplateDialog } = useDialog(
    CreateMessageTemplateRecordDialog
  );

  return (): ActionDeclaration => {
    return {
      intent: 'primary',
      id: 'create-message-template',
      group: 'message-templates',
      label: 'Create message template',
      handleAction: async () => {
        openCreateMessageTemplateDialog({
          data: {}
        });
      }
    };
  };
}
