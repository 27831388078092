import React, { PropsWithChildren } from 'react';
import { Body } from '@rexlabs/text';
import Box from '@rexlabs/box';
import { useToken } from '@rexlabs/styling';
import LoadingSpinner from '@rexlabs/loading-spinner';

export function LoadingState({
  children = 'Data loading...'
}: PropsWithChildren<any>) {
  const token = useToken();
  return (
    <Box
      p={token('spacing.xl')}
      width='100%'
      flexDirection='row'
      alignItems='center'
      justifyContent='center'
    >
      <Box mr={token('spacing.xs')} alignItems='center' justifyContent='center'>
        <LoadingSpinner
          size={18}
          strokeWidth={3}
          colors={[token('palette.neutral.200')]}
        />
      </Box>
      {typeof children === 'string' ? <Body grey>{children}</Body> : children}
    </Box>
  );
}

export default LoadingState;
