import { NormalisedItem, Select } from '@rexlabs/select';
import * as React from 'react';
import { slugToSentenceCase } from 'src/modules/communications/messages/utils/slug-to-sentence-case';
import { useMessageContext } from '../../hooks/use-message-context';
import { CommunicationContextType } from '../../types/communication-context-type';
import { RecipientGroup } from '../../types/recipient-group';

const normaliser = (item: RecipientGroup): NormalisedItem => ({
  id: item.name,
  label: slugToSentenceCase(item.name)
});

export interface RecipientGroupSelectProps {
  contextType?: CommunicationContextType;
}

export function RecipientGroupSelect({
  contextType,
  ...props
}: RecipientGroupSelectProps) {
  const { recipientGroups } = useMessageContext('email', contextType);

  return <Select {...props} items={recipientGroups} normaliser={normaliser} />;
}
