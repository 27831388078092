import { useDialog } from '@rexlabs/dialog';
import { PropertyAreaRecordDialog } from 'src/modules/property-areas/property-areas/dialogs/property-area-dialog';
import { PropertyArea } from 'src/modules/property-areas/property-areas/types/PropertyArea';
import { mapAreaDataToForm } from 'src/modules/property-areas/property-areas/mappers/mapAreaDataToForm';
import { ActionMenuItem } from '@rexlabs/action-menu';
import { useUpdateInspectionAreaSubmitHandler } from './use-update-inspection-area-submit-handler';

export function useGetUpdateInspectionAreaAction() {
  const { open } = useDialog(PropertyAreaRecordDialog);
  const getUpdateInspectionAreaSubmit = useUpdateInspectionAreaSubmitHandler();

  return (propertyArea: PropertyArea, taskId: string): ActionMenuItem => {
    return {
      label: 'Edit',
      onClick: async () => {
        return open({
          title: 'Edit',
          isEditMode: true,
          onSubmit: getUpdateInspectionAreaSubmit(propertyArea, taskId),
          initialValues: mapAreaDataToForm(propertyArea),
          submitLabel: 'Save'
        });
      }
    };
  };
}
