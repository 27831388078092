import * as React from 'react';
import { DialogProps } from '@rexlabs/dialog';
import { RecordDialog } from 'view/components/record-screen/dialog/dialog';
import { DialogContentConfig } from 'view/components/record-screen/dialog/types';
import { RecordSubmitHandler } from 'view/components/record-screen/utils';
import { PrimaryButton } from '@rexlabs/button';
import { FinancialEntity } from 'src/modules/financials/utils/financial-entity-action-group/use-get-financial-entity-actions';
import { useGetResetTableUrlParams } from 'view/components/table/hooks/use-get-reset-table-url-params';
import { searchForFolioBlock } from '../blocks/search-for-folio';
import {
  tableHashParamKey,
  tableTabIds
} from '../components/financial-folio-tabbed-table';

const content: DialogContentConfig = [
  {
    id: 'advanced-folio-search',
    label: 'Advanced folio search',
    blocks: [searchForFolioBlock]
  }
];

export interface AdvancedFolioSearchDialogProps extends DialogProps {
  initialSearchValue: string;
  onCreate?: (FinancialEntity) => any;
}

export function AdvancedFolioSearchDialog({
  onClose,
  initialSearchValue,
  onCreate
}: AdvancedFolioSearchDialogProps) {
  const [searchValue, setSearchValue] = React.useState<string>(
    initialSearchValue ?? ''
  );
  const [selectedFolio, setSelectedFolio] = React.useState<FinancialEntity>();

  const resetTableUrlParams = useGetResetTableUrlParams();

  const resetTable = React.useCallback(() => {
    resetTableUrlParams({
      resetActiveTabTableKeys: [tableHashParamKey],
      resetPageTableKeys: tableTabIds
    });
  }, [resetTableUrlParams]);

  const handleSubmit: RecordSubmitHandler = React.useCallback(async () => {
    onCreate?.(selectedFolio);
    resetTable();
    return true;
  }, [onCreate, resetTable, selectedFolio]);

  return (
    <RecordDialog
      title='Advanced folio search'
      content={content}
      onClose={() => {
        resetTable();
        onClose?.();
      }}
      handleSubmit={handleSubmit}
      SubmitButton={(props) => (
        <PrimaryButton {...props} isDisabled={!selectedFolio}>
          Select folio
        </PrimaryButton>
      )}
      blockProps={{
        searchValue,
        setSearchValue,
        selectedFolio,
        setSelectedFolio
      }}
    />
  );
}
