import { useDialog } from '@rexlabs/dialog';
import React from 'react';
import ArrowBackIcon from 'view/components/icons/arrow-back';
import NoteIcon from 'view/components/icons/note';
import { ViewNoteDialog } from '../../dialogs/view-note-dialog';
import { BatchReceiptingItem } from '../../types';
import { LabelWithIcon } from '../use-batch-receipting-button-config';

export function useGetViewNoteAction() {
  const { open: openViewNoteDialog } = useDialog(ViewNoteDialog);

  return (item: BatchReceiptingItem) => {
    return {
      label: LabelWithIcon('View note', <NoteIcon />),
      Icon: ArrowBackIcon,
      onClick: () => openViewNoteDialog({ batchReceiptingItem: item })
    };
  };
}
