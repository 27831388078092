import { ActionDeclaration } from 'src/modules/common/actions/types/action-declaration-types';
import { useDialog } from '@rexlabs/dialog';
import { PropertyAreaTypeRecordDialog } from 'src/modules/property-areas/property-area-types/dialogs/property-area-type-dialog';
import { useUpdatePropertyAreaTypeSubmitHandler } from 'src/modules/property-areas/property-area-types/hooks/use-update-property-area-type-submit-handler';
import { PropertyAreaType } from 'src/modules/property-areas/property-area-types/types/PropertyAreaType';
import { mapAreaTypeDataToForm } from 'src/modules/property-areas/property-area-types/mappers/mapAreaTypeDataToForm';

export function useGetUpdatePropertyAreaTypeAction() {
  const { open } = useDialog(PropertyAreaTypeRecordDialog);
  const getUpdatePropertyAreaTypeSubmit = useUpdatePropertyAreaTypeSubmitHandler();

  return (propertyAreaType: PropertyAreaType): ActionDeclaration => {
    return {
      id: 'edit-property-area-type',
      group: 'property-area-type',
      label: 'Edit',
      handleAction: async () => {
        return open({
          title: 'Edit area type',
          isEditMode: true,
          onSubmit: getUpdatePropertyAreaTypeSubmit(propertyAreaType),
          initialValues: mapAreaTypeDataToForm(propertyAreaType),
          submitLabel: 'Save'
        });
      }
    };
  };
}
