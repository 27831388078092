import { makeIconComponent } from '@rexlabs/icons';

import SmileyPoorSmall from 'src/assets/icons/s/smiley-poor.svg';
import SmileyPoorMedium from 'src/assets/icons/m/smiley-poor.svg';

export const SmileyPoorIcon = makeIconComponent({
  s: SmileyPoorSmall,
  m: SmileyPoorMedium
});

export default SmileyPoorIcon;
