import * as React from 'react';
import { useCallback } from 'react';
import { NormalisedItem, Select, SelectProps, useItems } from '@rexlabs/select';
import { BankStatementTransactionImport } from 'src/modules/banking/batch-receipting/types/bank-statement-transaction-import';
import { api } from 'utils/api/api-client';

const normaliser = (item: BankStatementTransactionImport): NormalisedItem => ({
  id: item.id,
  label: item.name
});

export function BankStatementTransactionImportSelect(
  props: SelectProps<BankStatementTransactionImport>
) {
  const getItems = useCallback(async (term) => {
    const imports = await api.get(
      `/financials/bank-statement-transaction-imports`,
      {
        per_page: 30,
        search: term
      }
    );
    return (imports.data || []).map((item) => {
      return {
        ...item,
        label: item.name,
        value: item.id
      };
    });
  }, []);

  const { getSelectProps } = useItems<BankStatementTransactionImport>({
    getItems: getItems
  });

  return (
    <Select
      id='bank-statement-transaction-import-select'
      {...props}
      normaliser={normaliser}
      {...getSelectProps()}
    />
  );
}
