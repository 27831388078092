import { filterNullValues } from 'utils/filter-null-values';
import { getUploadedFileMeta } from 'utils/files';
import { valueListFactory } from 'src/lib/testing/factories/value-list-factory';
import { QuoteTask } from '../types/QuoteTask';
import { CreateQuoteFormData } from '../types/CreateQuoteFormData';

export async function mapFormToPayload(values: Partial<CreateQuoteFormData>) {
  const cleanValues = filterNullValues(values);

  const { details, ...baseValues } = cleanValues;
  const amount = details?.amount;
  const property = baseValues?.property;
  const images = details?.images;
  const quoteAttachments = details?.quote_attachments;
  const hasReceivedQuote = details?.has_received_quote;
  const message =
    !hasReceivedQuote && details?.message?.template
      ? {
          template: details?.message?.template,
          subject:
            details?.message?.subject ||
            details?.message?.template?.channels?.[0].email_subject,
          body:
            details?.message?.body ||
            details?.message?.template?.channels?.[0].plain_text_body,
          inline_attachments: details?.message?.inline_attachments || [],
          templated_attachments: details?.message?.templated_attachments || [],
          send_immediately: false
        }
      : null;

  const basePayload: Partial<Omit<CreateQuoteFormData, 'details'>> &
    Pick<QuoteTask, 'type'> = {
    ...baseValues,
    type: valueListFactory('task_quote')
  };

  const detailsPayload = {
    ...details,
    ...(message ? { message } : {}),
    ...(amount ? { amount } : property ? { amount: 0 } : {}),
    ...(images?.length
      ? {
          images: await getUploadedFileMeta(images)
        }
      : {}),
    ...(quoteAttachments?.length
      ? {
          quote_attachments: await getUploadedFileMeta(quoteAttachments)
        }
      : {}),

    ...(hasReceivedQuote !== undefined
      ? {
          has_received_quote: !!hasReceivedQuote,
          status: hasReceivedQuote
            ? valueListFactory('received')
            : valueListFactory('requested')
        }
      : {})
  };

  return {
    basePayload,
    detailsPayload
  };
}
