import React from 'react';

import { useConfirmationDialog } from '@rexlabs/dialog';
import { useModelActions } from '@rexlabs/model-generator';
import Box from '@rexlabs/box';
import { Body } from '@rexlabs/text';

import {
  sharedIncludes,
  taskIncludes,
  tasksModel
} from 'src/modules/tasks/common/models/tasks-model';

import { api } from 'utils/api/api-client';

import WarningCircleIcon from 'view/components/icons/warning-circle';

import { useFeatureFlags } from 'view/components/@luna/feature-flags';
import { FLAGS } from 'utils/feature-flags';
import { InspectionTask } from 'src/modules/tasks/inspections/types/InspectionTask';
import { ImportPreviousReportBlockFormValues } from '../blocks/import-previous-report-block';
import { createNewInspectionReport } from '../utils/create-new-inspection-report';
import { getDeleteInspectionRoomRequests } from './get-delete-inspection-room-requests';

export function useGetImportReportSubmitHandler(
  inspectionTask?: InspectionTask
) {
  const { refreshItem } = useModelActions(tasksModel);
  const { hasFeature } = useFeatureFlags();

  const { open: openConfirmationDialog } = useConfirmationDialog();

  return (values: ImportPreviousReportBlockFormValues) => {
    openConfirmationDialog({
      size: 's',
      TitleIcon: WarningCircleIcon,
      destructive: false,
      title: 'Import report data',
      confirmText: 'Continue',
      message: (
        <Box flexDirection='column'>
          <Body>
            Any existing data in the current report will be overwritten.
          </Body>
          <Body> Do you want to continue?</Body>
        </Box>
      ),

      onConfirm: async () => {
        if (!inspectionTask) return;

        // First thing we want to do is delete any existing rooms from the inspection report
        // then reset the room order
        if (!hasFeature(FLAGS.ENTRY_EXIT_INSPECTION_REPORT)) {
          // We should no longer need to do this for areas, BE is taking care of it.
          // placing behind a flag so it's clear when we remove the flag, we should remove this also.
          if (inspectionTask.details?.rooms?.data?.length) {
            await Promise.all(
              getDeleteInspectionRoomRequests(inspectionTask, [])
            );
          }
        }
        // Then we need reset the status of the report to not started in order to import a new one
        if (inspectionTask.details?.status?.id !== 'not_started') {
          await api.patch(`/tasks/${inspectionTask.id}/status`, {
            type: { id: inspectionTask?.type.id },
            details: {
              status: {
                id: 'not_started'
              }
            }
          });
        }

        const { data } = await createNewInspectionReport(
          inspectionTask,
          values.previous_inspection
        );

        await refreshItem({
          id: data?.id,
          args: {
            include: [
              ...sharedIncludes,
              taskIncludes.inspectionDetails,
              'property.active_property_ownership.ownership.owners.contact'
            ].join(',')
          }
        });
      }
    });
  };
}
