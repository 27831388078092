import { RegularQuri } from '@rexlabs/quri';
import { BatchReceiptingStatusId } from '../types';
import { useBankAccountState } from './use-bank-account-state';
import { BankStatementTransactionTab } from './use-bank-statement-transactions-query';

function getStatusId(
  bankStatementTransactionTab: BankStatementTransactionTab
): BatchReceiptingStatusId {
  switch (bankStatementTransactionTab) {
    case 'ACTIVE':
    case 'NOT_MATCHED':
    case 'MATCHED':
      return 'new';
    case 'SUSPENDED':
      return 'suspended';
    case 'REMOVED':
      return 'removed';
  }
}

export function useGetBankStatementTransactionFilter() {
  const { bankAccount } = useBankAccountState();

  return (
    bankStatementTransactionTab: BankStatementTransactionTab
  ): RegularQuri[] => [
    {
      field: 'status_id',
      op: 'eq',
      value: getStatusId(bankStatementTransactionTab)
    },
    {
      field: 'locked',
      op: 'eq',
      value: 'false'
    },
    {
      field: 'is_parent',
      op: 'neq',
      value: 'true'
    },
    {
      field: 'bank_account_id',
      op: 'eq',
      value: bankAccount!.id
    },
    ...(bankStatementTransactionTab === 'MATCHED'
      ? ([
          {
            field: 'auto_matched',
            op: 'eq',
            value: 'true'
          }
        ] as RegularQuri[])
      : []),
    ...(bankStatementTransactionTab === 'NOT_MATCHED'
      ? ([
          {
            field: 'auto_matched',
            op: 'neq',
            value: 'true'
          }
        ] as RegularQuri[])
      : [])
  ];
}
