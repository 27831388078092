import React, { useCallback, useMemo } from 'react';
import { DialogProps, useDialog } from '@rexlabs/dialog';
import { RecordDialog } from 'view/components/record-screen/dialog/dialog';
import {
  ButtonGroupProps,
  DialogContentConfig
} from 'view/components/record-screen/dialog/types';
import { RecordSubmitHandler } from 'view/components/record-screen/utils';
import {
  financialsPendingDisbursementsContactsModel,
  PendingContactDisbursement
} from 'data/models/entities/financials/pending-disbursements/pending-contact-disbursement';
import Icons from '@rexlabs/icons';
import { RecordType, RecordTypes } from 'data/models/types';
import { StandardDialogFooter } from 'view/components/dialogs/components/standard-dialog-footer';
import { GhostButton, OutlineButton, PrimaryButton } from '@rexlabs/button';
import { PendingDisbursement } from 'data/models/entities/financials/pending-disbursements';
import { TransferFundsDialog } from 'src/modules/trust-journal-entries/dialogs/transfer-funds-dialog';
import { useModelActions } from '@rexlabs/model-generator';
import {
  financialsPendingDisbursementsOwnershipsModel,
  PendingOwnershipDisbursement
} from 'data/models/entities/financials/pending-disbursements/pending-ownership-disbursement';
import { getRecordTypeName } from 'utils/records/get-record-type-name';
import { financialsInvoicesModel } from 'data/models/entities/financials/invoices';
import { useTranslation } from 'react-i18next';
import {
  disbursementIndividualBillsDialogBlock,
  IndividualWithBillsBlockData
} from '../blocks/disbursement-individual-bills-dialog-block';
import { disbursementIndividualBillsPaidDialogBlock } from '../blocks/disbursement-individual-bills-paid-dialog-block';
import { BILLS_AND_FEES_LIST_ID } from '../blocks/contact-bills-and-fees';

export const contentWithBills: DialogContentConfig = [
  {
    id: 'details',
    label: 'Details',
    blocks: [disbursementIndividualBillsDialogBlock]
  }
];

export const contentWithoutBills: DialogContentConfig = [
  {
    id: 'details',
    label: 'Details',
    blocks: [disbursementIndividualBillsPaidDialogBlock]
  }
];

interface DisburseFundsDialogProps extends DialogProps {
  recordType: RecordType;
  pendingDisbursement: PendingDisbursement;
  handleConfirmation: (
    entitiesToDisburse: PendingDisbursement[]
  ) => Promise<void>;
  refreshBillsAndFees?: boolean;
}

export function DisburseIndividualWithFeesDialog({
  onClose: onCancel,
  recordType,
  pendingDisbursement: propPendingDisbursement,
  handleConfirmation,
  refreshBillsAndFees
}: DisburseFundsDialogProps) {
  const { t } = useTranslation();
  const [
    pendingDisbursement,
    setPendingDisbursement
  ] = React.useState<PendingDisbursement>(propPendingDisbursement);

  const handleSubmit: RecordSubmitHandler = useCallback(async () => {
    await handleConfirmation([pendingDisbursement]);
    return true;
  }, []);

  const {
    refreshItem: refreshContact,
    refreshLists: refreshContacts
  } = useModelActions(financialsPendingDisbursementsContactsModel);

  const {
    refreshItem: refreshOwnership,
    refreshLists: refreshOwnerships
  } = useModelActions(financialsPendingDisbursementsOwnershipsModel);

  const { refreshList } = useModelActions(financialsInvoicesModel);

  const { open: openPayBillsAndFees } = useDialog(TransferFundsDialog);

  const initialValues: IndividualWithBillsBlockData = {
    recordType,
    pendingDisbursement
  };

  const sourceObject = useMemo(() => pendingDisbursement.object, [
    pendingDisbursement
  ]);

  const basePendingDisbursementIncludes = [
    'financial_summary',
    'disburse_summary',
    'withheld_funds',
    'last_disbursement',
    'payment_methods'
  ];

  const handleBillSubmit = async () => {
    const refreshFn =
      recordType === RecordTypes.Contact ? refreshContact : refreshOwnership;

    const include = [
      ...basePendingDisbursementIncludes,
      recordType === RecordTypes.Contact ? 'contact' : 'ownership'
    ].join(',');

    const response = await refreshFn({
      id: sourceObject?.id,
      args: {
        include
      }
    });

    refreshBillsAndFees &&
      (await refreshList({
        id: BILLS_AND_FEES_LIST_ID
      }));

    // the refresh function does not return the relations in the format we want, so just spread the new values over the old ones to update where needed
    const nextPendingDisbursement:
      | PendingContactDisbursement
      | PendingOwnershipDisbursement = {
      ...pendingDisbursement,
      ...response.data
    };

    // keep the table up to date
    await (recordType === RecordTypes.Contact
      ? refreshContacts()
      : refreshOwnerships());

    // update the dialog to reflect paid bills
    setPendingDisbursement(nextPendingDisbursement);
  };

  const handlePayBills = async () => {
    // pass to bills and fees, do all that
    // on bills and fees close, refresh the pending disbursement
    // et voila

    await openPayBillsAndFees({
      sourceObject,
      onSubmit: handleBillSubmit,
      title: 'Pay bills & fees'
    });
  };

  const ButtonGroup = ({
    onClose: handleCancel,
    handleSubmit: handleDisburseNow
  }: ButtonGroupProps) => {
    const handleDisburseWithoutPayingBills = async () => {
      await handleDisburseNow();
      handleCancel?.();
    };

    return (
      <StandardDialogFooter>
        <GhostButton onClick={handleCancel}>Cancel</GhostButton>
        <OutlineButton onClick={handleDisburseWithoutPayingBills}>
          {t('disbursements.disburse-without-paying-bills')}
        </OutlineButton>
        <PrimaryButton onClick={handlePayBills}>
          Pay bills &amp; fees first
        </PrimaryButton>
      </StandardDialogFooter>
    );
  };

  const hasBills =
    pendingDisbursement.financial_summary
      ?.bills_and_fees_payable_total_amount_owing !== 0;

  return (
    <RecordDialog
      // only show custom buttons if there are bills, this way if they pay their bills in the nested modal,
      // they can then use this one to disburse without having to load a different modal
      ButtonGroup={hasBills ? ButtonGroup : undefined}
      onClose={onCancel}
      TitleIcon={Icons.InfoCircle}
      title={t('disbursements.confirm-for-record-type', {
        recordType: getRecordTypeName(recordType)
      })}
      submitLabel={t('disbursements.disburse-now')}
      content={hasBills ? contentWithBills : contentWithoutBills}
      handleSubmit={handleSubmit}
      data={initialValues}
    />
  );
}
