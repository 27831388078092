import React from 'react';
import ROUTES from 'routes/app';
import { EmptySection } from './base';

export function EmptyPortfolioManager() {
  return (
    <EmptySection
      headline='Relate a portfolio manager to this record'
      buttonLabel='View portfolio managers'
      linkProps={{ to: ROUTES.USERS_LIST }}
    >
      Choose from an existing portfolio manager or create a new portfolio
      manager for this record.
    </EmptySection>
  );
}
