import React from 'react';
import bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import { initWithSegmentHandler } from '@rexlabs/analytics';
import config from 'config';

if (config.BUGSNAG_API_KEY) {
  bugsnag.start({
    apiKey: config.BUGSNAG_API_KEY,
    releaseStage: config.RELEASE?.STAGE,
    appVersion: config.RELEASE?.VERSION,
    plugins: [new BugsnagPluginReact(React)],
    onError: function (event) {
      try {
        if (window.FS) {
          const timestampedURL =
            `${window.FS.getCurrentSessionURL?.(true)}` + `/devtools/console`;

          const url = encodeURIComponent(timestampedURL);
          const msg = event.errors?.[0]?.errorMessage;
          window.FS.event?.(`Bugsnag Event - ${msg}`, {
            errorMessage: msg,
            bugsnagEvent:
              `https://app.bugsnag.com/rex-software/rex-frontend/timeline` +
              `?filters[metaData.fullStoryDetails.url][0][type]=eq` +
              `&filters[metaData.fullStoryDetails.url][0][value]=${url}` +
              `&sort=last_seen`
          });

          event.addMetadata('fullStoryDetails', 'url', timestampedURL);
        }
        event.addMetadata('app', 'name', 'Alfred - App');
      } catch (e) {
        // Just in a try catch so we don't get stuck in a reporting error loop
      }
    }
  });
}

initWithSegmentHandler({ id: config?.SEGMENT_ID, appName: 'alfred' });
