import { RentScheduleEntry } from 'src/modules/property-tenancies/types/property-tenancy-types';

export function getRentScheduleEntryWithId(
  rentScheduleEntry: RentScheduleEntry
) {
  return {
    ...rentScheduleEntry,
    id: rentScheduleEntry.effective_from_date + Math.random().toString()
  };
}

export function getRentScheduleWithIds(
  rentScheduleEntries?: RentScheduleEntry[]
) {
  if (!rentScheduleEntries) return [];

  return rentScheduleEntries.map(getRentScheduleEntryWithId);
}
