import React from 'react';
import { StatusGoodTag, StatusAverageTag, Tag } from '@rexlabs/tag';
import { RentScheduleEntry } from 'src/modules/property-tenancies/types/property-tenancy-types';

export class InvalidStatusError extends Error {
  constructor(statusID: never) {
    super(`Invalid rent schedule status: ${statusID}`);
  }
}

export interface RentScheduleStatusTagProps {
  status: RentScheduleEntry['status'];
}

export function RentScheduleStatusTag({
  status: { id, label }
}: RentScheduleStatusTagProps) {
  if (id === 'current') {
    return <StatusGoodTag>{label}</StatusGoodTag>;
  }

  if (id === 'future') {
    return <StatusAverageTag>{label}</StatusAverageTag>;
  }

  if (id === 'past') {
    return <Tag>{label}</Tag>;
  }

  throw new InvalidStatusError(id);
}
