import React from 'react';

import { BlockConfig } from 'view/components/record-screen/types';

import {
  InspectionRunStartFormValues,
  InspectionRunStartForm,
  inspectionRunStartFormValidationConfig
} from '../components/inspection-run-start-form';

export type InspectionRunStartBlockFormValues = InspectionRunStartFormValues;
export type InspectionRunStartData = Required<InspectionRunStartFormValues>;

export const inspectionRunStartBlock: BlockConfig<
  InspectionRunStartData,
  any,
  InspectionRunStartBlockFormValues
> = {
  id: 'inspection-run-start',
  title: 'Inspection run start',
  validate: {
    definitions: {
      ...inspectionRunStartFormValidationConfig
    }
  },
  Edit: ({ values }) => {
    return <InspectionRunStartForm values={values} />;
  }
};
