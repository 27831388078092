import { Invoice } from 'data/models/entities/financials/invoices';
import { api } from './api-client';

export type Payable = {
  invoice: Invoice;
  can_be_paid_from_bucket?: boolean;
};

interface GetPayablesArgs {
  objectType: 'contact' | 'ownership' | 'tenancy';
  objectID: string;
  perPage?: number;
  prepaymentBucketId?: string | null;
  prepaymentBucketPropertyId?: string;
}

const defaultGetInvoicesArgs: Required<
  Omit<GetPayablesArgs, 'prepaymentBucketId' | 'prepaymentBucketPropertyId'>
> = {
  objectType: 'contact',
  objectID: '',
  perPage: 30
};

/**
 * Get payables for a given object
 *
 * NOTE: this is a custom endpoint that performs a search of the invoices model. As a result, the response is not paginated. This would require some additional work to implement.
 * The per_page parameter is used to limit the number of results returned.
 */
export async function getPayables(args: GetPayablesArgs) {
  const {
    objectType,
    objectID,
    perPage,
    prepaymentBucketId,
    prepaymentBucketPropertyId
  } = {
    ...defaultGetInvoicesArgs,
    ...args
  };

  const params = new URLSearchParams({
    object_type: objectType,
    object_id: objectID,
    include:
      'invoice,invoice.line_items,invoice.line_items.payable_to_property,invoice.line_items.payable_to_chart_of_accounts_account',
    per_page: perPage.toString()
  });

  if (prepaymentBucketId) {
    params.set('prepayment_bucket_id', prepaymentBucketId);
  }
  if (prepaymentBucketPropertyId) {
    params.set('prepayment_bucket_property_id', prepaymentBucketPropertyId);
  }

  const paramString = params.toString();

  const request = await api.get(`/financials/payables?${paramString}`);

  return request.data as Payable[];
}
