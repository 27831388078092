import { makeIconComponent } from '@rexlabs/icons';

import HouseMoveInMediumSvg from 'src/assets/icons/m/house-move-in.svg';
import HouseMoveInLargeSvg from 'src/assets/icons/l/house-move-in.svg';
import HouseMoveInExtraLargeSvg from 'src/assets/icons/xl/house-move-in.svg';

export const MoveInIcon = makeIconComponent({
  m: HouseMoveInMediumSvg,
  l: HouseMoveInLargeSvg,
  xl: HouseMoveInExtraLargeSvg
});

export default MoveInIcon;
