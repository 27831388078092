import React from 'react';
import { BlockConfig } from 'view/components/record-screen/types';
import { RentScheduleEntryDetailsForm } from '../components/rent-schedule-entry-details-form';

export const createRentScheduleEntryDetailsBlock: BlockConfig = {
  id: 'createRentScheduleEntryDetails',
  title: 'Details',
  validate: {
    definitions: {
      frequency: { rules: 'required' },
      effective_from_date: { rules: 'required' },
      days_in_advance_to_post: { rules: 'required|min:1' }
    }
  },
  Edit: ({ data, values, blockProps }) => (
    <RentScheduleEntryDetailsForm
      isFirstEntry={blockProps.isFirstEntry}
      values={values}
      initialValues={data}
    />
  )
};
