import { pick } from 'lodash';
import { api } from 'utils/api/api-client';
import { RecordSubmitHandler } from 'view/components/record-screen/utils';
import { PropertyAreaForm } from 'src/modules/property-areas/property-areas/types/PropertyAreaForm';
import { PropertyArea } from 'src/modules/property-areas/property-areas/types/PropertyArea';
import { useQueryClient } from 'react-query';
import { useModelActions } from '@rexlabs/model-generator';
import { tasksModel } from '../../common/models/tasks-model';

export function useUpdateInspectionAreaSubmitHandler() {
  const queryClient = useQueryClient();
  const { refreshItem } = useModelActions(tasksModel);

  return (area: PropertyArea, taskId: string) => {
    const onSubmit: RecordSubmitHandler<PropertyAreaForm> = async ({
      changedValues
    }) => {
      const deleteIds = changedValues.delete_ids || [];

      const requests = transformInspectionAreaFormDataToPipelineRequest({
        values: changedValues,
        inspectionAreaId: area.id,
        deleteIds
      });

      await api.post('request-pipelines', {
        requests
      });

      queryClient.invalidateQueries(['task', taskId]);
      refreshItem({ id: taskId });

      return true;
    };

    return onSubmit;
  };
}

function transformInspectionAreaFormDataToPipelineRequest({
  values,
  inspectionAreaId,
  deleteIds
}: {
  values: Partial<PropertyAreaForm>;
  inspectionAreaId: string;
  deleteIds: string[];
}) {
  const requests: Array<any> = [];

  /**
   *  This request is for updating name and included for new inspection areas
   */
  if ('name' in values) {
    requests.push({
      id: 'inspection_areas',
      method: 'PATCH',
      path: `/api/v1/inspection-areas/${inspectionAreaId}`,
      json: pick(values, ['name'])
    });
  }

  if (Array.isArray(values.items)) {
    const itemRequests = values.items.map((item, index) => {
      return item.id
        ? getPatchRequest(item, index)
        : getPostRequest(item, index);
    });

    requests.push(itemRequests);
  }

  if (deleteIds.length) {
    const deleteRequests = deleteIds.map((id, index) => {
      return getDeleteRequest(id, index);
    });

    requests.push(deleteRequests);
  }

  /**
   *  This request is for updating the order of the items to maintain sort order
   */
  const itemOrderArray = values.items!.map((item, index) => {
    return item.id || `{{$.inspection_area_items_${index}.id}}`;
  });

  requests.push({
    id: 'inspection_areas_item_array',
    method: 'PATCH',
    path: `/api/v1/inspection-areas/${inspectionAreaId}`,
    json: {
      item_order: itemOrderArray
    }
  });

  return requests.flat();

  /**
   *  These requests are for any existing items which need to be updated
   */
  function getPatchRequest(item, index) {
    return {
      id: `inspection_area_items_${index}`,
      method: 'PATCH',
      path: `/api/v1/inspection-area-items/${item.id}`,
      json: { name: item.name }
    };
  }

  /**
   *  These requests are for any new items added while editing the inspection area
   */
  function getPostRequest(item, index) {
    return {
      id: `inspection_area_items_${index}`,
      method: 'POST',
      path: `/api/v1/inspection-area-items`,
      json: {
        name: item.name,
        inspection_area: {
          id: inspectionAreaId
        }
      }
    };
  }

  /**
   *  These requests are for any items removed while editing the inspection area
   */
  function getDeleteRequest(id, index) {
    return {
      id: `inspection_area_items_delete_${index}`,
      method: 'DELETE',
      path: `/api/v1/inspection-area-items/${id}`
    };
  }
}
