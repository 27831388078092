import * as React from 'react';
import { RecordDialog } from 'view/components/record-screen/dialog/dialog';
import { DialogProps, useDialog } from '@rexlabs/dialog';
import { Message } from '../types/Message';
import { messagePreviewBlock } from '../blocks/message-preview-block';
import { EditMessageRecordDialog } from './edit-message-record-dialog';

export interface PreviewMessageRecordDialogProps extends DialogProps {
  data: {
    message: Message;
  };
  onSave?: () => void;
  hasButtonGroupActions?: boolean;
}

const content = [
  {
    id: 'preview-message-record-dialog',
    label: 'Preview Message Record Dialog',
    blocks: [messagePreviewBlock]
  }
];

export function PreviewMessageRecordDialog({
  hasButtonGroupActions,
  onClose,
  onSave,
  data
}: PreviewMessageRecordDialogProps) {
  const { open: openEditDialog } = useDialog(EditMessageRecordDialog);
  const handleSubmit = async () => {
    openEditDialog({
      hasButtonGroupActions,
      data: {
        message: data.message
      },
      onSave
    });

    onClose?.();
  };

  return (
    <RecordDialog
      title='Preview message'
      data={data.message}
      content={content}
      onClose={onClose}
      handleSubmit={handleSubmit}
      submitLabel='Edit message'
    />
  );
}
