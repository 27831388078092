import React, { useRef } from 'react';
import { useResponsiveContainerRules } from 'view/hooks/responsive-container-rules';
import { Column, Grid } from '@rexlabs/grid';
import { RecordLinkValue } from 'view/components/values/record-link';
import { Value } from 'view/components/values';
import { DateValue } from 'view/components/values/date';
import { Invoice } from 'data/models/entities/financials/invoices';

interface InvoiceDetailsProps {
  invoice?: Invoice;
}

export function InvoiceDetails({ invoice }: InvoiceDetailsProps) {
  const container = useRef<HTMLDivElement | null>(null);
  const sizes = useResponsiveContainerRules(container?.current, {
    small: 500,
    large: 900
  });
  const fullWidth = 12;
  const colWidth = sizes?.large ? 3 : sizes?.small ? 6 : fullWidth;
  const isReimbursement = invoice?.reimbursement_for_invoice;

  const disbursementInstruction = React.useMemo(() => {
    if (invoice?.specific_disbursement_payment_method) {
      return invoice.specific_disbursement_payment_method.name;
    }
    return 'Use disbursement preference';
  }, [invoice]);

  // If the selected disbursement method is using bpay, we want to change the biller code/bill ref field label
  const isBpay = React.useMemo(() => {
    if (invoice) {
      const { specific_disbursement_payment_method } = invoice;
      if (specific_disbursement_payment_method) {
        return (
          specific_disbursement_payment_method.payment_method.id === 'bpay'
        );
      }
    }
    return false;
  }, [invoice?.specific_disbursement_payment_method]);

  return (
    <Grid ref={container}>
      <Column width={colWidth}>
        <Value label='Description' value={invoice?.description} />
      </Column>
      <Column width={colWidth}>
        <DateValue label='Due Date' value={invoice?.due_date} />
      </Column>
      <Column width={colWidth}>
        <Value label='Payable to' value={invoice?.payable_to.object.label} />
      </Column>
      <Column width={colWidth}>
        <Value label='Payable by' value={invoice?.payable_by.object.label} />
      </Column>

      <Column width={colWidth}>
        <Value
          label='Bank Account'
          value={(invoice as any)?.bank_account?.name}
        />
      </Column>
      <Column width={colWidth}>
        <Value
          label='Disbursement Instruction'
          value={disbursementInstruction}
        />
      </Column>
      {!isReimbursement && (
        <>
          <Column width={colWidth}>
            <Value
              label={`${isBpay ? 'BPAY customer' : 'Bill'} reference number`}
              value={invoice?.bill_reference}
            />
          </Column>
        </>
      )}
      {isReimbursement && (
        <Column width={colWidth}>
          <RecordLinkValue
            label='This invoice reimburses'
            record={{
              type: 'invoice',
              object: invoice?.reimbursement_for_invoice
            }}
          />
        </Column>
      )}
      <Column width={colWidth}>
        <Value label='Related task' value={invoice?.task?.summary} />
      </Column>
      <Column width={fullWidth}>
        <Value label='Invoice Notes' value={invoice?.notes} />
      </Column>
    </Grid>
  );
}
