import React, { useEffect, useRef } from 'react';
import { Dialog as VividDialog, DialogProps } from '@rexlabs/dialog';

export interface AutoFocusDialogProps extends DialogProps {
  autofocus?: boolean;
  autofocusIndex?: number;
}

export function Dialog({
  children,
  autofocus = true,
  autofocusIndex = 0,
  ...props
}: AutoFocusDialogProps) {
  const contentRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    // Timeout is needed because of the way the dialog renders/mounts
    // within the PortalTarget
    // Also setting timeout to 100ms to match the dialog animation timing
    setTimeout(() => {
      const core = contentRef?.current;
      const node = core?.parentElement?.parentElement?.parentElement;

      if (node) {
        // Focus the second focusable element, since the first one will
        // always be the dialog close button
        if (autofocus) {
          const selector =
            'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])';
          const focusable = node.querySelectorAll<HTMLElement>(selector);
          focusable?.[autofocusIndex + 1]?.focus?.();
        }
      }
    }, 100);
  }, []);

  return (
    <VividDialog {...props}>
      <div
        style={{
          height: '100%',
          width: '100%'
        }}
        ref={contentRef}
      >
        {children}
      </div>
    </VividDialog>
  );
}
