import React, { FC } from 'react';
import { isEmpty } from 'lodash/fp';
import dayjs from 'dayjs';

import Box from '@rexlabs/box';
import {
  ButtonGroup,
  GhostButton,
  PrimaryButton,
  SecondaryButton
} from '@rexlabs/button';
import { Checkbox } from '@rexlabs/checkbox';
import { DateInput } from 'view/components/@luna/inputs/date-input/date-input';
import { Field, Form, ReactForms } from '@rexlabs/form';
import { useToken } from '@rexlabs/styling';
import { TextArea } from '@rexlabs/text-input';
import { SelectNormalised } from '@rexlabs/select-old';
import { Grid, Column } from '@rexlabs/grid';

import { formatCurrency } from 'utils/formatters';
import { formatDateLong } from 'utils/dates/format';
import { TenancyModel } from 'data/models/entities/tenancies';

import {
  ErrorBanner,
  InfoBanner
} from 'view/components/@luna/notifications/banner';
import { PaymentFrequencySelect } from 'view/components/inputs/selects/payment-frequency-select';
import { StepsDialogStepProps } from 'view/components/dialogs/steps-dialog';
import { CentAmountInput } from 'view/components/inputs/cent-amount';
import { durationNearestTo, DateSpecifier } from 'utils/dates/dates';
import { RentAdjustment } from 'data/models/entities/financials/rent/adjustments';
import { PaymentFrequency } from 'utils/static-value-lists/payment-frequency';

export interface RentAdjustmentDetailsProps extends StepsDialogStepProps {
  onClose: (...args: any[]) => void;
  initialValues: any;
  tenancy: TenancyModel;
  adjustments: RentAdjustment[];
}

export interface RentAdjustmentDetailsForm {
  from_date: string;
  amount: number;
  rent_frequency: SelectNormalised<PaymentFrequency>;
  increase_bond: boolean;
  bond_amount?: number;
  notify_tenants: boolean;
  reason_for_adjustment?: string;
}

const validations = {
  definitions: {
    amount: { rules: 'required|min:0' }
  }
};

export const RentAdjustmentDetails: FC<RentAdjustmentDetailsProps> = ({
  onClose,
  nextStep,
  setStateForNextStep,
  accumulatedStepState,
  initialValues,
  adjustments,
  tenancy
}) => {
  const token = useToken();

  function handleSubmit(values) {
    setStateForNextStep((state) => ({
      ...state,
      formData: values
    }));
    nextStep();
  }

  const adjustmentDurations: [
    DateSpecifier,
    DateSpecifier
  ][] = adjustments.map(({ from_date, to_date }) => [from_date, to_date]);
  const nearestAdjustmentDurations = durationNearestTo(
    new Date(),
    adjustmentDurations
  );

  const defaults = {
    // TODO: [AL-621] fix initial value issue with DatePicker in vivid
    from_date: dayjs(),
    increase_bond: false,
    notify_tenants: true,
    rent_frequency: { value: 'weekly', label: 'Weekly' }
  };

  return (
    <ReactForms
      handleSubmit={handleSubmit}
      validate={validations}
      initialValues={
        isEmpty(accumulatedStepState.formData)
          ? { ...defaults, ...initialValues }
          : accumulatedStepState.formData
      }
    >
      {({ submitForm, isSubmitting, values }) => (
        <Form style={{ width: '100%' }}>
          {nearestAdjustmentDurations && (
            <InfoBanner
              mb={token('spacing.s')}
              description={
                <span>
                  {nearestAdjustmentDurations?.past &&
                    `This property’s rent was last adjusted on ${formatDateLong(
                      nearestAdjustmentDurations?.past?.[0]
                    )}.`}
                  <br />
                  {nearestAdjustmentDurations?.future &&
                    `The next adjustment is scheduled for ${formatDateLong(
                      nearestAdjustmentDurations?.future?.[0]
                    )}`}
                </span>
              }
            />
          )}
          <Field
            name='from_date'
            label='Charge the new rent from'
            Input={DateInput}
            inputProps={{
              placeholder: 'Select a date'
            }}
            description={
              tenancy?.paid_to_date
                ? `This tenancy has paid to ` +
                  `${formatDateLong(tenancy?.paid_to_date)}`
                : ''
            }
          />

          <Grid mb={token('spacing.s')}>
            <Column width={7}>
              <Field
                name='amount'
                label='Set the new rent amount'
                Input={CentAmountInput}
                inputProps={{ min: 0 }}
                description={
                  tenancy?.rent_amount && tenancy?.frequency.label
                    ? `The current rent is ` +
                      `${formatCurrency(tenancy?.rent_amount)} / ` +
                      `${tenancy?.frequency.label}`
                    : ''
                }
              />
            </Column>
            <Column width={5}>
              <Field
                // HACK: we don't want to show a label here, but we do want to align
                // the input with the amount field, so we pass in an empty space character
                // TODO: [AL-620] think about combo fields in vivid
                label={'\u00A0'}
                name='rent_frequency'
                Input={PaymentFrequencySelect}
                inputProps={{ disabled: true }}
              />
            </Column>
          </Grid>

          <Field
            name='increase_bond'
            Input={Checkbox}
            inputProps={{ label: 'Adjust bond amount' }}
          />

          {values.increase_bond && (
            <Field
              name='bond_amount'
              label='The new bond amount will be'
              Input={CentAmountInput}
              inputProps={{ min: 0 }}
            />
          )}

          <Field
            name='notify_tenants'
            Input={Checkbox}
            inputProps={{ label: 'Notify all tenants.tsx' }}
            // TODO: [AL-618] add inline tooltips to checkbox
            // TODO: [AL-619] fix tooltip zindex in vivid
            // HelpTooltipContent={() => (
            //   <p>All tenants.tsx will be notified via their preferred method</p>
            // )}
          />

          <Field
            name='reason_for_adjustment'
            label='Reason for adjustment'
            Input={TextArea}
            inputProps={{
              placeholder: 'Add your description here'
            }}
          />

          {tenancy?.paid_to_date &&
            values?.from_date &&
            dayjs(values.from_date) > dayjs(tenancy.paid_to_date) && (
              <ErrorBanner description='You have selected to adjust this tenancy’s rent amount within a paid period. This may result in a change to the tenancy’s paid to date.' />
            )}

          <Box
            mt={token('spacing.m')}
            flexDirection='row'
            justifyContent='space-between'
            alignItems='center'
          >
            <GhostButton onClick={onClose}>Cancel</GhostButton>
            <ButtonGroup>
              <SecondaryButton isDisabled={true}>Back</SecondaryButton>
              <PrimaryButton
                isLoading={isSubmitting}
                onClick={() => {
                  submitForm();
                }}
              >
                Next
              </PrimaryButton>
            </ButtonGroup>
          </Box>
        </Form>
      )}
    </ReactForms>
  );
};
