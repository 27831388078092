import { FinancialPeriod } from 'src/modules/statements/types';
import { useWhereabouts } from '@rexlabs/whereabouts';

export const useExtractFinancialPeriodFromUrl = (): FinancialPeriod | null => {
  const { query: urlQueryParams } = useWhereabouts();

  if (
    urlQueryParams?.financial_period_start_date &&
    urlQueryParams.financial_period_end_date
  ) {
    return {
      startDate: urlQueryParams.financial_period_start_date,
      endDate: urlQueryParams.financial_period_end_date
    };
  }

  return null;
};
