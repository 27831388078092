import { TenancyModel, TenantsForm } from 'data/models/entities/tenancies';
import { useFeatureFlags } from 'view/components/@luna/feature-flags';
import { FLAGS } from 'utils/feature-flags';

// TODO: remove when guarantor flag is turned on in prod
const mapApiToFormOld = (data) => {
  return {
    ...data,
    // removing guarantors from the form here
    tenants:
      data?.tenants?.map(({ guarantors: _guarantors, ...tenant }) => tenant) ||
      []
  };
};

export function useMapApiToTenancyForm() {
  const { hasFeature } = useFeatureFlags();

  const hasGuarantorAndOccupierUpdates = hasFeature(
    FLAGS.GUARANTOR_AND_OCCUPIER_UPDATES
  );

  return (data?: TenancyModel): TenantsForm | undefined => {
    if (!data) {
      return undefined;
    }
    if (!hasGuarantorAndOccupierUpdates) {
      return mapApiToFormOld(data);
    }

    return {
      ...data,
      tenants:
        data?.tenants?.map((tenant) => ({
          ...tenant,
          role: tenant.role.id,
          has_guarantor: !!tenant.guarantors?.data?.length,
          guarantors: tenant.guarantors?.data
        })) || []
    };
  };
}
