import { useGetAppWideFilter } from 'src/modules/app-wide-filters/hooks/use-get-app-wide-filter';

export function useGetAppWideFilterQueryParams() {
  const [filter] = useGetAppWideFilter();
  const filterValueString = filter?.value?.map?.((v) => v.id).join(',');

  return {
    ...filter,
    value: filterValueString ?? null
  };
}
