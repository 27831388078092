import { LeaseReview } from 'src/modules/tasks/lease-review/types/LeaseReview';
import ROUTES from 'routes/app';
import { push } from '@rexlabs/whereabouts';
import { useTranslation } from 'react-i18next';

interface getGoToLeaseAgreementActionArgs {
  data: LeaseReview;
}
export function useGoToLeaseAgreementAction() {
  const { t } = useTranslation();

  return ({ data }: getGoToLeaseAgreementActionArgs) => {
    return {
      id: 'go-to-lease-agreement',
      label: t('lease-agreements.update.singular.label'),
      intent: 'primary',
      handleAction: () => {
        push(ROUTES.PROPERTY, {
          query: {
            tab: 'lease-agreements'
          },
          params: {
            propertyId: data.property?.id
          }
        });
      }
    } as const;
  };
}
