import Validator from '@rexlabs/validator';

function uploadedBillMustBePdf(this: any): boolean {
  return !(
    this.validator.input['uploaded_bill']?.file &&
    this.validator.input['uploaded_bill']?.file?.type != 'application/pdf'
  );
}

Validator.registerImplicit(
  'uploadedBillMustBePdf',
  uploadedBillMustBePdf,
  'Invalid file type. Supported file types are: PDF'
);
