import React from 'react';
import { Field } from '@rexlabs/form';
import { NumberInput } from '@rexlabs/text-input';
import { DateInput } from 'view/components/@luna/inputs/date-input/date-input';
import { Value } from 'view/components/values';
import { RentScheduleEntryFrequencySelect } from 'view/components/inputs/selects/v4-selects/rent-schedule-entry-frequency-select';
import { formatDate } from 'utils/dates/format';
import { Grid } from 'view/components/@luna/form/grid';

type DetailsValues = {
  effective_from_date: string;
};

type DetailsInitialValues = {
  effective_from_date: string;
};

interface RentScheduleEntryDetailsFormProps<
  Values extends DetailsValues,
  InitialValues extends DetailsInitialValues
> {
  values: Values;
  initialValues?: InitialValues;
  isFirstEntry: boolean;
}

export function RentScheduleEntryDetailsForm<
  Values extends DetailsValues,
  InitialValues extends DetailsInitialValues
>({
  initialValues,
  values,
  isFirstEntry
}: RentScheduleEntryDetailsFormProps<Values, InitialValues>) {
  return (
    <Grid columns={1}>
      <Grid columns={1}>
        <Field
          id='frequency'
          name='frequency'
          label='Frequency'
          Input={RentScheduleEntryFrequencySelect}
        />
      </Grid>
      <Grid columns={2}>
        {initialValues?.effective_from_date && isFirstEntry && (
          <Value
            label='Effective from date'
            value={`Beginning of lease - ${formatDate(
              values.effective_from_date
            )}`}
          />
        )}
        {!initialValues?.effective_from_date && isFirstEntry && (
          <Field
            name='effective_from_date'
            label='Effective from date'
            Input={DateInput}
            description='The first agreement start date will also be set to this date'
          />
        )}
        {!isFirstEntry && (
          <Field
            name='effective_from_date'
            label='Effective from date'
            Input={DateInput}
          />
        )}
        <Field
          name='days_in_advance_to_post'
          label='Days in advance to post'
          Input={NumberInput}
        />
      </Grid>
    </Grid>
  );
}
