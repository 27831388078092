import {
  postRequestPipelineRequests,
  RequestPipeLineRequests,
  RequestPipelineRequestWithId
} from 'utils/api/post-request-pipeline';
import { getUploadedFileMeta } from 'utils/files';
import { pick } from 'lodash';
import { useModelActions } from '@rexlabs/model-generator';
import {
  sharedIncludes,
  taskIncludes,
  tasksModel
} from 'src/modules/tasks/common/models/tasks-model';
import { InspectionTask } from 'src/modules/tasks/inspections/types/InspectionTask';
import {
  CWUConditionKey,
  InspectionConditionItem
} from 'src/modules/tasks/inspections/entry-exit/types/InspectionCondition';
import {
  CONDITION_VALUES,
  ConditionValues
} from 'src/modules/tasks/inspections/components/condition-button-input';
import { EntryExitInspectionFormData } from '../entry-exit/types/EntryExitInspectionFormData';

type EntryExitSubmitHandler = (
  args: {
    values: EntryExitInspectionFormData;
    changedValues: Partial<EntryExitInspectionFormData>;
    changedBlockIds: Array<string>;
  },
  originalData?: InspectionTask
) => Promise<boolean>;

export function useGetUpdateEntryExitAreasSubmitHandler(
  inspectionId: string
): EntryExitSubmitHandler {
  const actions = useModelActions(tasksModel);

  return async ({ values, changedValues }, originalData) => {
    if (!changedValues.area_items) {
      return false;
    }

    const requests: RequestPipeLineRequests = [];
    // we need to map over the existing conditions since BE wants the original ids [the id for the pivot connection, not just the condition itself]
    const originalItems = originalData?.details?.areas?.data?.flatMap(
      (area) => area?.items?.data || []
    );

    for (const [id, areaItem] of Object.entries(changedValues.area_items)) {
      const originalItem = originalItems?.find((item) => item.id === id);

      const payload: any = pick(areaItem, ['notes']);

      if (Array.isArray(areaItem.images?.data)) {
        payload.images = await getUploadedFileMeta(areaItem.images!.data);
      }

      if (areaItem.conditions != null) {
        payload.inspection_conditions = mapInspectionConditions(
          values.area_items[id].conditions,
          originalItem?.inspection_conditions?.data || []
        );
      }

      const request: RequestPipelineRequestWithId = {
        id: `inspection_area_item_${id}`,
        method: 'PATCH',
        path: `/api/v1/inspection-area-items/${id}`,
        json: payload
      };

      requests.push(request);
    }

    await postRequestPipelineRequests(requests);

    await actions.refreshItem({
      id: inspectionId,
      args: {
        include: [
          ...sharedIncludes,
          taskIncludes.inspectionDetails,
          'property.active_property_ownership.ownership.owners.contact'
        ].join(',')
      }
    });

    return true;
  };
}

const areaRegex = /^entry-exit-area-\w{8}-(?:\w{4}-){3}\w{12}$/;

export function hasEntryExitAreaBlockChanges(blockId) {
  return areaRegex.test(blockId);
}

function mapInspectionConditions(
  conditions: Record<CWUConditionKey, ConditionValues>,
  inspectionConditionItems: InspectionConditionItem[]
) {
  return inspectionConditionItems.map((item) => {
    const key = item.inspection_condition.name.toLowerCase() as CWUConditionKey;
    const conditionFormValue = conditions[key];
    return {
      id: item.id,
      value:
        conditionFormValue === CONDITION_VALUES.NO_VALUE
          ? null
          : conditionFormValue,
      condition: item.inspection_condition
    };
  });
}
