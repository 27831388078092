import React, { useMemo } from 'react';
import { BlockConfig } from 'view/components/record-screen/types';
import { Column, Columns, ListTable, TabbedTable } from 'view/components/table';
import { query, useModelActions } from '@rexlabs/model-generator';
import {
  financialsReconciliationsModel,
  Reconciliation
} from 'data/models/entities/financials/reconciliations';
import { financialsReconciliationsChequesModel } from 'data/models/entities/financials/reconciliations/cheques';
import { useTableFilters } from 'view/hooks/use-table-filters';
import ROUTES from 'routes/app';
import { RecordCell } from '@rexlabs/table';
import { TrustJournalEntry } from 'src/modules/trust-journal-entries/types/trust-journal-entry-types';
import { Tab } from 'view/components/table/tabbed';
import { useTranslation } from 'react-i18next';
import { getReconcileItemsPayload } from '../utils/get-reconcile-payload';
import { ResolutionActionButton } from '../components/resolution-action-button';
import { getReconciledInThisReconciliationFilters } from '../utils/get-reconciled-in-this-reconciliation-filters';

const CustomActionCell = ({ cell }) => {
  const { refreshList, reconcileItems, unreconcileItems } = useModelActions(
    financialsReconciliationsChequesModel
  );
  const { refreshItem: refreshReconciliation } = useModelActions(
    financialsReconciliationsModel
  );

  const { value } = cell;
  const unpresentedCheque = value as TrustJournalEntry & {
    reconciliationId: string;
  };

  const isReconciled =
    unpresentedCheque.reconciliation_status?.status.id === 'reconciled';

  const handleClick = async () => {
    if (isReconciled) {
      await unreconcileItems(
        getReconcileItemsPayload([unpresentedCheque], 'unpresented_cheque')
      );
    } else {
      await reconcileItems(
        getReconcileItemsPayload([unpresentedCheque], 'unpresented_cheque')
      );
    }

    await refreshList({ id: 'reconciliation-unpresented-cheques' });
    await refreshReconciliation({ id: unpresentedCheque.reconciliationId });
  };

  return (
    <ResolutionActionButton isResolved={isReconciled} onClick={handleClick} />
  );
};

const useUnpresentedChequesColumns = (): Columns => {
  const { t } = useTranslation();

  return [
    {
      id: 'date_of',
      type: 'date',
      Header: 'Date'
    },
    {
      id: 'record_reference',
      Header: t('trust-journal-entries.label.singular') as string,
      accessor: (item) => item,
      Cell: RecordCell,
      cellProps: {
        labelKey: 'record_reference',
        getCellLinkProps: (value) => {
          return {
            to: ROUTES.TRUST_JOURNAL_ENTRY,
            params: {
              trustJournalEntryId: value.id
            }
          };
        }
      },
      width: 250
    },
    {
      id: 'type',
      type: 'value',
      Header: 'Type'
    },
    {
      id: 'amount',
      type: 'currency',
      cellProps: {
        align: 'right'
      },
      Header: 'Amount',
      width: 100
    }
  ];
};

const toggleReconcileAction = (reconciliationId: string): Column => ({
  id: 'action',
  accessor: (item) => ({ ...item, reconciliationId }),
  Cell: CustomActionCell,
  width: 60,
  sticky: 'right'
});

const getReconciliationCheques = (reconciliationId: string) => () => ({
  ...query`{
  ${financialsReconciliationsChequesModel} (reconciliationId: ${reconciliationId}) {
    id,
    reconciliation_status
  }
}`,
  uuid: 'reconciliation-unpresented-cheques'
});

export const unpresentedChequesBlock: BlockConfig<Reconciliation> = {
  id: 'unpresented-cheques',
  title: 'Unpresented cheques',
  View: ({ data }) => {
    const unpresentedChequesColumns = useUnpresentedChequesColumns();
    const { reconcileItems, unreconcileItems, refreshList } = useModelActions(
      financialsReconciliationsChequesModel
    );

    const { refreshItem: refreshReconciliation } = useModelActions(
      financialsReconciliationsModel
    );

    const { getFilters, getSort } = useTableFilters('trust-journal-entries');

    const isUnfinishedReconciliation = data?.status?.id !== 'reconciled';

    const tabs = useMemo<Tab[]>(() => {
      const commonProps = {
        id: 'reconciliation-unpresented-cheques',
        Table: ListTable,
        columns: [
          ...unpresentedChequesColumns,
          ...(isUnfinishedReconciliation
            ? [toggleReconcileAction(data!.id)]
            : [])
        ],
        getQuery: getReconciliationCheques(data!.id),
        getFilters,
        getSort,
        suggestedFilters: ['date_of', 'type_id'],
        initialSortBy: [{ id: 'date_of', desc: true }]
      };

      return [
        {
          ...commonProps,
          forcedGlobalFilter: [
            {
              field: 'reconciliation_status_id',
              op: 'eq',
              value: 'unreconciled'
            }
          ],
          name: 'unreconciled-unpresented-cheques',
          label: 'Unreconciled',
          getBulkActions: isUnfinishedReconciliation
            ? ({ selectedItems, entity }) => [
                {
                  label: 'Reconcile cheques',
                  type: 'primary',
                  handleAction: async () => {
                    await reconcileItems(
                      getReconcileItemsPayload(
                        selectedItems,
                        'unpresented_cheque'
                      )
                    );
                    await refreshList({ id: entity.uuid });
                    await refreshReconciliation({ id: data!.id });
                  }
                }
              ]
            : undefined
        },
        {
          ...commonProps,
          forcedGlobalFilter: [
            {
              field: 'reconciliation_status_id',
              op: 'eq',
              value: 'reconciled'
            },
            ...getReconciledInThisReconciliationFilters(data!)
          ],
          name: 'reconciled-unpresented-cheques-in-this-reconciliation',
          label: 'This reconciliation',
          getBulkActions: isUnfinishedReconciliation
            ? ({ selectedItems, entity }) => [
                {
                  label: 'Unreconcile cheques',
                  type: 'primary',
                  handleAction: async () => {
                    await unreconcileItems(
                      getReconcileItemsPayload(
                        selectedItems,
                        'unpresented_cheque'
                      )
                    );
                    await refreshList({ id: entity.uuid });
                    await refreshReconciliation({ id: data!.id });
                  }
                }
              ]
            : undefined
        },
        {
          ...commonProps,
          forcedGlobalFilter: [
            {
              field: 'reconciliation_status_id',
              op: 'eq',
              value: 'reconciled'
            }
          ],
          name: 'reconciled-unpresented-cheques',
          label: 'Reconciled to date',
          getBulkActions: isUnfinishedReconciliation
            ? ({ selectedItems, entity }) => [
                {
                  label: 'Unreconcile cheques',
                  type: 'primary',
                  handleAction: async () => {
                    await unreconcileItems(
                      getReconcileItemsPayload(
                        selectedItems,
                        'unpresented_cheque'
                      )
                    );
                    await refreshList({ id: entity.uuid });
                    await refreshReconciliation({ id: data!.id });
                  }
                }
              ]
            : undefined
        }
      ];
    }, [
      data,
      getFilters,
      getSort,
      isUnfinishedReconciliation,
      reconcileItems,
      refreshList,
      refreshReconciliation,
      unreconcileItems
    ]);

    return <TabbedTable tabs={tabs} />;
  }
};
