import React from 'react';
import Box from '@rexlabs/box';
import { StyleSheet, useStyles, useToken } from '@rexlabs/styling';
import { ButtonGroup } from '@rexlabs/button';
import { StickyFooter } from '@rexlabs/dialog';

interface StandardDialogFooterProps {
  left?: React.ReactNode;
  children: React.ReactNode;
}

// HACK: Some funky interactions with our spacers
// and the negative margin + sticky button bar containers
const defaultStyles = StyleSheet({
  // container: {
  //   '& > button:first-child': {
  //     marginLeft: '0px'
  //   },
  //   '& > button:last-child': {
  //     marginRight: '0px'
  //   }
  // }
});

export function StandardDialogFooter({
  left,
  children
}: StandardDialogFooterProps) {
  const s = useStyles(defaultStyles);
  const token = useToken();

  return (
    <StickyFooter>
      <Box>{left}</Box>

      <ButtonGroup
        {...s('container')}
        end
        spacing={undefined}
        flex={1}
        ml={0}
        mr={0}
        mt={0}
        sx={token('button.group.spacing', token('spacing.s'))}
      >
        {children}
      </ButtonGroup>
    </StickyFooter>
  );
}
