import * as React from 'react';
import {
  NormalisedItem,
  RecordOption,
  Select,
  SelectProps
} from '@rexlabs/select';
import { formatCurrency } from 'utils/formatters';
import { TenancyPrepaymentBucket } from 'src/modules/prepayments/types/tenancy-prepayment-bucket';
import { useGetTenancyPrepaymentBuckets } from 'src/modules/tenancies/hooks/use-get-tenancy-prepayment-buckets';

interface PrepaymentBucketSelectProps {
  tenancyId?: string;
  bankAccountId?: string;
}

export function PrepaymentBucketSelect(
  props: PrepaymentBucketSelectProps & SelectProps<TenancyPrepaymentBucket>
) {
  const { tenancyId, bankAccountId, ...selectProps } = props;

  const { data } = useGetTenancyPrepaymentBuckets(tenancyId, bankAccountId);

  return (
    <Select
      {...selectProps}
      Option={RecordOption}
      searchable={false}
      items={data ?? []}
      normaliser={normaliser}
    />
  );
}

function normaliser(item: TenancyPrepaymentBucket): NormalisedItem {
  return {
    label: item.property
      ? `${item.name} - ${item.property?.display_name}`
      : item.name,
    id: `${item?.bucket_id},${item.property?.id}`,
    description: formatCurrency(item.amount)
    // HACK: item.id can be null here, but even though NormalisedItem wants it to be a string, it doesn't actually break if it isn't.
  } as NormalisedItem;
}
