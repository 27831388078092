import { SelectNormalised } from '@rexlabs/select-old';

import { RecordTypes } from 'data/models/types';
import { PropertyOwnership } from 'src/modules/property-ownerships/types/property-ownership-types';

export function normalisePropertyOwnership(
  item: PropertyOwnership
): SelectNormalised {
  return {
    value: item,
    label: `${item?.ownership?.display_name} - ${item?.property?.address_string}`,
    type: RecordTypes.PropertyOwnership
  };
}
