import React, { FC, useCallback, useMemo } from 'react';
import { getContacts } from 'utils/api/get-contacts';
import { ContactRole } from 'src/modules/contacts/types/contact-types';
import { ContactSelect, ContactSelectProps } from '../contact';

export const SupplierSelect: FC<ContactSelectProps> = ({
  filterByRoles,
  dialogProps,
  ...props
}) => {
  const roles: ContactRole[] = useMemo(
    () => ['supplier', ...(filterByRoles ? filterByRoles : [])],
    [filterByRoles]
  );
  const getSuggestedItems = useCallback(async () => {
    return getContacts({ filterByRoles: roles });
  }, [roles]);

  return (
    <ContactSelect
      filterByRoles={roles}
      dialogProps={{
        contact: {
          is_business: true,
          is_supplier: true
        },
        showTypeBlock: false,
        ...dialogProps
      }}
      getSuggestedItems={getSuggestedItems}
      {...props}
    />
  );
};
