import React from 'react';

import { ListSummaryCard } from 'view/components/cards/list-summary-card';

type TaxIncomeSummaryProps = {
  taxableIncome: number;
  deductibleExpenses: number;
};

export const TaxIncomeForWithheldPeriodSummary = ({
  taxableIncome,
  deductibleExpenses
}: TaxIncomeSummaryProps) => (
  <ListSummaryCard
    title='income for period where tax was being withheld'
    cardColor='neutral'
    titleTooltip='This owner only had tax withheld for a portion of this financial year'
    items={[
      {
        label: 'Taxable income',
        amount: taxableIncome,
        type: 'inbound',
        testId: 'nrl-taxable-income'
      },
      {
        label: 'Deductible expenses',
        amount: deductibleExpenses,
        type: 'outbound',
        testId: 'nrl-deductible-expenses'
      },
      {
        label: 'Net taxable income',
        amount: taxableIncome - deductibleExpenses,
        type: 'total',
        testId: 'nrl-net-taxable-income'
      }
    ]}
  />
);
