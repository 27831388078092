import React, { useMemo } from 'react';
import { DialogProps } from '@rexlabs/dialog';
import { RecordDialog } from 'view/components/record-screen/dialog/dialog';
import { DialogContentConfig } from 'view/components/record-screen/dialog/types';
import { Ownership } from 'data/models/entities/ownerships';
import {
  financialsInvoicesModel,
  Invoice
} from 'data/models/entities/financials/invoices';
import { useModelActions } from '@rexlabs/model-generator';
import { trustJournalEntryModel } from 'src/modules/trust-journal-entries/models/trust-journal-entries';
import { useTransferFundsDetailsBlock } from 'src/modules/financials/blocks/transfer-funds-details';
import {
  createTrustJournalEntryLineItemBlock,
  ReceiptingFundsLineItemEntry
} from 'src/modules/financials/blocks/trust-journal-entry-line-items';
import { useBankAccounts } from 'src/modules/bank-accounts/hooks/use-bank-accounts';
import { transformToRecordObject } from 'utils/records/transform-to-record-data';
import { transformToSearchEntry } from 'utils/transform-to-search-entry';
import { FinancialEntity } from 'src/modules/financials/utils/financial-entity-action-group/use-get-financial-entity-actions';
import { Contact } from 'src/modules/contacts/types/contact-types';
import { BankAccount } from 'data/models/entities/financials/bank-accounts';
import { useRecordCreatedToast } from 'src/modules/common/toasts/hooks/use-record-created-toast';
import { AxiosResponse } from 'axios';
import { SearchResultItem } from 'utils/api/get-search-results';
import { TenancyPrepaymentBucket } from 'src/modules/prepayments/types/tenancy-prepayment-bucket';
import { TransferFundsDialogButtonGroup } from '../components/transfer-funds-dialog-button-group';
import { TrustJournalEntry } from '../types/trust-journal-entry-types';

const transformOwnership = (ownership: Ownership) => ({
  category: 'deposit',
  folio: { ...ownership, label: ownership.display_name, type: 'ownership' }
});

const transformInvoice = (invoice: Invoice) => ({
  category: 'invoice',
  invoice
});

interface TransferFundsDialogProps extends DialogProps {
  receivedFrom?: Pick<Contact, 'id' | 'display_name'>;
  ownership?: Ownership;
  invoice?: Invoice;
  sourceObject?: FinancialEntity;
  title?: React.ReactNode;
  submitLabel?: React.ReactNode;
  onSubmit?: () => Promise<void>;
  onCreate?: (args?: TrustJournalEntry) => any;
  bankAccount?: BankAccount;
  invoiceId?: string;
}

interface TransferFundsFormValues {
  source: {
    ledger: {
      object: SearchResultItem<FinancialEntity>;
    };
  };
  bank_account: BankAccount;
  send_receipt: boolean;
  line_items: ReceiptingFundsLineItemEntry[];
  prepayment_bucket?: TenancyPrepaymentBucket;
  available_amount?: number;
}

export function TransferFundsDialog({
  bankAccount,
  ownership,
  invoice,
  sourceObject,
  title = 'Transfer funds',
  submitLabel = 'Transfer funds',
  onSubmit,
  onCreate,
  ...props
}: TransferFundsDialogProps) {
  const { defaultBankAccount, isLoading } = useBankAccounts();
  const {
    createItem,
    refreshLists: refreshTrustJournalEntryLists
  } = useModelActions(trustJournalEntryModel);
  const {
    refreshItem: refreshInvoice,
    refreshLists: refreshInvoiceLists
  } = useModelActions(financialsInvoicesModel);
  const addToast = useRecordCreatedToast(trustJournalEntryModel);

  const transferFundsDetailsBlock = useTransferFundsDetailsBlock();

  const dialogContent: DialogContentConfig = useMemo(
    () => [
      {
        id: 'basics',
        label: 'Receipt funds',
        blocks: [
          transferFundsDetailsBlock,
          createTrustJournalEntryLineItemBlock
        ]
      }
    ],
    [transferFundsDetailsBlock]
  );

  const handleSubmit = async ({
    values
  }: {
    values: TransferFundsFormValues;
  }) => {
    const lineItems = values.line_items
      .map((lineItem) => {
        if (!lineItem.amount) return undefined;

        if (lineItem.category === 'deposit') {
          return {
            ...lineItem,
            deposit: {
              ...lineItem.deposit,
              description: lineItem.description,
              prepayment_bucket: lineItem.deposit_into?.bucket_id
                ? { id: lineItem.deposit_into?.bucket_id }
                : undefined,
              // supply property for rent prepayment bucket (if relevant)
              property: lineItem.deposit_into?.property?.id
                ? { id: lineItem.deposit_into?.property?.id }
                : undefined
            },
            // remove the bucket field so we're not sending extra data to BE
            deposit_into: undefined
          };
        }

        if (lineItem.category === 'invoice') {
          return {
            amount: lineItem.amount,
            bucket_mismatch_reason:
              lineItem?.cross_bucket_payment_reason?.reason,
            invoice_payment: {
              invoice: lineItem.invoice_payment?.invoice
            }
          };
        }

        return lineItem;
      })
      .filter(Boolean);

    const transferData = {
      ...values,
      source: {
        ledger: values.source?.ledger,
        prepayment_bucket:
          values.source?.ledger?.object?.type?.id === 'tenancy' &&
          values.prepayment_bucket?.bucket_id
            ? { id: values.prepayment_bucket.bucket_id }
            : undefined,
        prepayment_bucket_property:
          values.source?.ledger?.object?.type?.id === 'tenancy' &&
          values.prepayment_bucket?.property
            ? { id: values.prepayment_bucket.property?.id }
            : undefined
      },
      amount: lineItems.reduce(
        (total, lineItem) => total + (lineItem?.amount || 0),
        0
      ),
      line_items: lineItems,
      prepayment_bucket: undefined
    };

    const res = await createItem({
      data: {
        transfer: transferData
      }
    });

    const refreshRequests: Array<Promise<void | AxiosResponse>> = [
      refreshTrustJournalEntryLists(),
      refreshInvoiceLists()
    ];

    if (invoice?.id) {
      refreshRequests.push(
        refreshInvoice({
          id: invoice.id
        })
      );
    }

    await Promise.all([
      ...refreshRequests,
      [
        ...(onSubmit ? [onSubmit()] : []),
        ...(onCreate ? [onCreate(res.data)] : [])
      ]
    ]);

    addToast(res.data);

    return res.data;
  };

  const initialData = {
    source: {
      ledger: {
        object: sourceObject
          ? transformToSearchEntry(transformToRecordObject(sourceObject))
          : undefined
      }
    },
    bank_account: bankAccount ?? defaultBankAccount,
    send_receipt: true,
    line_items: [
      ownership ? transformOwnership(ownership) : undefined,
      invoice ? transformInvoice(invoice) : undefined
    ].filter(Boolean),
    invoice
  };

  return (
    <RecordDialog
      {...props}
      size='l'
      title={title}
      submitLabel={submitLabel}
      isLoading={isLoading}
      data={initialData}
      handleSubmit={handleSubmit}
      content={dialogContent}
      ButtonGroup={TransferFundsDialogButtonGroup}
      autofocusIndex={2}
    />
  );
}
