import { useQuery, UseQueryOptions } from 'react-query';
import { AxiosResponse } from 'axios';

import { Quri, RegularQuri, toQuri } from '@rexlabs/quri';

import { api } from 'utils/api/api-client';
import { RecordData } from 'utils/types';
import { isArray } from 'lodash';

type SortField = string;
type SortDirection = 'asc' | 'desc';

type Path = string;
type Filter = Quri[];
/**
 * NOTE: Use this when the BE request is expecting a specific field/value pair.
 * For example, when requesting a type of credit note, the BE expects invoice_id={invoice.id} which
 * in the uri Would look like:
 *   api/v1/financials/credit-note-templates?invoice_id=1329aed8-6ec9-11ee-95dd-0242ac14000
 */
type CustomQuery = RegularQuri;
type Includes = string[];
type Sort = [SortField, SortDirection];
/**
 * NOTE: Use this when the BE request has a flag to return different a data set. for example, when
 * requesting financial periods, the BE has a flag to include the current period, as this is turned
 * off by default.
 */
type CustomParams = { key: string; value: string | number | boolean }[];

type RecordsQueryObject = {
  id?: string;
  filter?: Filter;
  sort?: Sort;
  customQuery?: CustomQuery;
  includes?: Includes;
  perPage?: number;
  customParams?: CustomParams;
};

export type RecordsQueryKey = [Path, RecordsQueryObject?];

export function getSearchQuery({
  filter = [],
  customQuery,
  includes = [],
  sort,
  perPage,
  customParams = [],
  search
}: {
  filter?: Filter;
  customQuery?: CustomQuery;
  includes?: Includes;
  sort?: Sort;
  perPage?: number;
  customParams?: CustomParams;
  search?: string;
}) {
  const filterQuri = toQuri(filter);
  const includesCombined = includes.join(',');
  const sortCombined = isArray(sort) && sort.join('+');

  const query = new URLSearchParams();

  if (perPage) {
    query.set('per_page', perPage.toString());
  }

  if (customQuery) {
    query.set(customQuery.field, customQuery.value.toString());
  }

  if (sortCombined) {
    query.set('o', sortCombined);
  }
  if (filterQuri) {
    query.set('q', filterQuri);
  }

  if (includesCombined) {
    query.set('include', includesCombined);
  }

  if (customParams.length) {
    customParams.forEach(({ key, value }) => {
      query.append(key, value.toString());
    });
  }

  if (search) {
    query.set('search', search);
  }

  return query.size ? query : undefined;
}

export async function fetchRecords<TRecord = RecordData>({
  queryKey
}: {
  queryKey: RecordsQueryKey;
}) {
  const [
    path,
    {
      id = undefined,
      sort = undefined,
      filter = [],
      customQuery = undefined,
      includes = [],
      perPage = undefined,
      customParams = []
    } = {}
  ] = queryKey;

  const query = getSearchQuery({
    filter: !id ? filter : undefined,
    customQuery,
    includes,
    sort,
    perPage,
    customParams
  });

  return api
    .get(`${path}${id ? `/${id}` : ''}${query ? `?${query}` : ''}`)
    .then((res: AxiosResponse<TRecord[]>) => res.data);
}

export function useRecordsQuery<TRecord = RecordData>(
  queryKey: RecordsQueryKey,
  options?: Pick<
    UseQueryOptions,
    'cacheTime' | 'enabled' | 'staleTime' | 'retry'
  >
) {
  const query = useQuery(
    queryKey,
    (queryContext) => fetchRecords<TRecord>(queryContext),
    {
      ...options
    }
  );

  return query;
}
