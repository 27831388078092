import Box from '@rexlabs/box';
import React, { ElementType } from 'react';
import { StyleSheet, useStyles } from '@rexlabs/styling';

type ActionMenuSubtitleProps = {
  subtitle: string;
  Icon?: ElementType;
};

const defaultStyles = StyleSheet({
  wrapper: {
    display: 'flex'
  },

  icon: {
    marginRight: '1rem',
    marginLeft: '.7rem'
  }
});

export function ActionMenuSubtitle({
  subtitle,
  Icon
}: ActionMenuSubtitleProps) {
  const s = useStyles(defaultStyles);

  return (
    <Box {...s('wrapper')}>
      {Icon && <Icon {...s('icon')} />}
      {subtitle}
    </Box>
  );
}
