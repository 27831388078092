import React from 'react';
import { BlockConfig } from 'view/components/record-screen/types';
import { TagGroup, Tag } from '@rexlabs/tag';
import { Body } from '@rexlabs/text';
import { Field } from '@rexlabs/form';
import { PropertyViewsSelect } from 'view/components/inputs/selects/property-views';
import { Grid } from 'view/components/@luna/form/grid';

export const marketingViewsBlock: BlockConfig = {
  id: 'marketing-views',
  title: 'Views',
  workInProgress: true,
  View: ({ data }) => {
    return data?.views?.length ? (
      <TagGroup>
        {data?.views?.map(({ id, label }) => (
          <Tag key={id}>{label}</Tag>
        ))}
      </TagGroup>
    ) : (
      <Body>No views added</Body>
    );
  },
  Edit: () => {
    return (
      <Grid columns={1}>
        <Field
          name='views'
          Input={PropertyViewsSelect}
          inputProps={{ multi: true }}
        />
      </Grid>
    );
  }
};
