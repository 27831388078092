import React from 'react';
import { BlockConfig } from 'view/components/record-screen/types';

import { Grid } from 'view/components/@luna/form/grid';
import { Field } from '@rexlabs/form';
import { Heading } from '@rexlabs/text';
import { Checkbox } from '@rexlabs/checkbox';
import { BooleanValue } from 'view/components/values/boolean';
import { Value } from 'view/components/values';
import { NumberInput } from '@rexlabs/text-input';
import { GasMeterUnitRadioGroup } from 'view/components/inputs/radios/gas-meter-unit';
import { InspectionFormData } from '../../types/InspectionFormData';
import { InspectionTask } from '../../types/InspectionTask';

export const entryExitWaterAndGasBlock: BlockConfig<
  InspectionTask,
  any,
  InspectionFormData
> = {
  id: `entry-exit-property-details-water-and-gas`,
  title: 'Water and gas',
  isMobileForcedCollapsed: true,
  isEditable: (data) =>
    !['report_completed', 'report_sent'].includes(
      data?.details?.status?.id || ''
    ),
  View: ({ data }) => {
    const propertyDetails = data?.details?.property_details;
    const waterData = propertyDetails?.water_data;
    const gasData = propertyDetails?.gas_data;

    const isEntryInspection = data?.details?.type.id === 'entry_inspection';

    return (
      <>
        <Grid columns={1}>
          <Heading level={4}>Water metering and charging</Heading>
          <Grid columns={2}>
            <BooleanValue
              label='Premises is individually metered'
              value={waterData?.is_individually_metered}
              trueLabel='Yes'
              falseLabel='No'
            />
            {waterData?.is_individually_metered && (
              <>
                <BooleanValue
                  label='Premises is water efficient'
                  value={waterData?.is_water_efficient}
                  trueLabel='Yes'
                  falseLabel='No'
                />
                <Value
                  label={`Water meter reading at ${
                    isEntryInspection ? 'start' : 'end'
                  } of tenancy`}
                  value={
                    waterData?.meter_readings
                      ? `${waterData?.meter_readings} kL`
                      : '--'
                  }
                />
              </>
            )}
          </Grid>
          <Heading level={4}>Gas metering</Heading>
          <Grid columns={2}>
            <BooleanValue
              label='Gas is supplied to the premises'
              value={gasData?.gas_is_supplied}
              trueLabel='Yes'
              falseLabel='No'
            />
            {gasData?.gas_is_supplied && (
              <>
                <Value
                  label='Unit of measure (on the gas meter)'
                  value={gasData?.meter_unit.label}
                ></Value>
                <Value
                  label={`Gas reading at ${
                    isEntryInspection ? 'start' : 'end'
                  } of tenancy`}
                  value={
                    gasData?.meter_readings
                      ? `${gasData?.meter_readings} ${gasData?.meter_unit.label}`
                      : '--'
                  }
                ></Value>
              </>
            )}
          </Grid>
        </Grid>
      </>
    );
  },
  Edit: ({ values }) => {
    const waterDataValues = values?.property_details?.water_data;
    const gasDataValues = values?.property_details?.gas_data;

    const waterDataPath = 'property_details.water_data';
    const gasDataPath = 'property_details.gas_data';

    const isEntryInspection = values?.inspection_type === 'entry_inspection';

    return (
      <>
        <Grid columns={1}>
          <Heading level={4}>Water metering and charging</Heading>
          <Grid columns={2}>
            <Field
              id={`${waterDataPath}.is_individually_metered`}
              name={`${waterDataPath}.is_individually_metered`}
              Input={Checkbox}
              optional={false}
              inputProps={{
                label: 'Premises is individually metered'
              }}
            />
            {waterDataValues?.is_individually_metered && (
              <>
                <Field
                  id={`${waterDataPath}.is_water_efficient`}
                  name={`${waterDataPath}.is_water_efficient`}
                  Input={Checkbox}
                  optional={false}
                  inputProps={{
                    label: 'Premises is water efficient'
                  }}
                />
                <Field
                  id={`${waterDataPath}.meter_readings`}
                  name={`${waterDataPath}.meter_readings`}
                  label={`Water meter reading at ${
                    isEntryInspection ? 'start' : 'end'
                  } of tenancy`}
                  Input={NumberInput}
                  optional={false}
                  inputProps={{
                    suffix: 'kL',
                    placeholder: '0.000'
                  }}
                />
              </>
            )}
          </Grid>
          <Heading level={4}>Gas metering</Heading>
          <Grid columns={2}>
            <Grid columns={1}>
              <Field
                id={`${gasDataPath}.gas_is_supplied`}
                name={`${gasDataPath}.gas_is_supplied`}
                Input={Checkbox}
                optional={false}
                inputProps={{
                  label: 'Gas is supplied to the premises'
                }}
              />
              {gasDataValues?.gas_is_supplied && (
                <>
                  <Field
                    id={`${gasDataPath}.meter_unit.id`}
                    name={`${gasDataPath}.meter_unit.id`}
                    label='Unit of measure (on the gas meter)'
                    Input={GasMeterUnitRadioGroup}
                    optional={false}
                    inputProps={{
                      orientation: 'horizontal'
                    }}
                  />
                  <Field
                    id={`${gasDataPath}.meter_readings`}
                    name={`${gasDataPath}.meter_readings`}
                    label={`Gas reading at ${
                      isEntryInspection ? 'start' : 'end'
                    } of tenancy`}
                    Input={NumberInput}
                    optional={false}
                    inputProps={{
                      suffix:
                        gasDataValues?.meter_unit.id === 'cubic_meter'
                          ? 'm3'
                          : 'ft3',
                      placeholder: '0.00'
                    }}
                  />
                </>
              )}
            </Grid>
          </Grid>
        </Grid>
      </>
    );
  }
};
