import React, { useMemo } from 'react';

import {
  TitleBlock,
  TitleBlockProps
} from 'view/components/cards/title/title-block';

import { RecordObject, RecordType } from 'data/models/types';
import { LeaseAgreementIcon } from 'view/components/icons/lease-agreement';

import { getRecordTitle } from 'utils/records/get-record-title';
import { titleBlockStatusTag } from 'src/modules/common/components/status-tag';
import { propertyTenancyStatusMap } from 'src/modules/property-tenancies/maps/property-tenancy-map';
import { PropertyTenancy } from 'src/modules/property-tenancies/types/property-tenancy-types';
import { useTranslation } from 'react-i18next';
import { usePropertyTenancyActions } from '../actions/use-property-tenancy-actions';

const type: RecordType = 'property_tenancy';

type PropertyTenancyTitleBlockProps = Partial<TitleBlockProps> & {
  propertyTenancy: PropertyTenancy;
};

export function PropertyTenancyTitleBlock({
  propertyTenancy,
  ...props
}: PropertyTenancyTitleBlockProps) {
  const { t } = useTranslation();
  const { record_reference, status } = propertyTenancy;

  const getPropertyTenancyActions = usePropertyTenancyActions();

  const tags: TitleBlockProps['tags'] = [
    titleBlockStatusTag(status, propertyTenancyStatusMap)
  ];

  const title = getRecordTitle({
    type,
    object: propertyTenancy
  } as RecordObject);

  const actions = useMemo(() => getPropertyTenancyActions(propertyTenancy), [
    propertyTenancy
  ]);

  return (
    <TitleBlock
      title={title}
      // Custom type just for the property-tenancy screen
      type={t('lease-agreements.label.singular') as string}
      tags={tags}
      reference={record_reference}
      actions={actions}
      Icon={LeaseAgreementIcon}
      {...props}
    />
  );
}
