import React from 'react';
import { useSessionState } from 'src/modules/common/hooks/session-state';

export enum View {
  Table = 'table',
  Card = 'card'
}

export function usePersistView() {
  const sessionData = useSessionState();
  const userId = sessionData.user!.id;
  const siloId = sessionData.activeSilo!.id;

  const id = `batch-receipting/view/${userId}/${siloId}`;

  const [view, setView] = React.useState<View>(() => {
    const currentView = localStorage.getItem(id);

    if (!currentView) return View.Card;

    return currentView as View;
  });

  const handleView = React.useCallback(
    (view: View) => {
      localStorage.setItem(id, view);

      setView(view);
    },
    [id]
  );

  return [view, handleView] as const;
}
