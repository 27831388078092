import * as React from 'react';
import { StyleSheet, useStyles } from '@rexlabs/styling';
import { useMediaQuery } from '@rexlabs/breakpoints';

export const CONDITION_VALUES = {
  YES: true,
  NO: false,
  NO_VALUE: 'N/A' // we can't use null here since the form library treats null as the same value as false
} as const;

export type ConditionValues = typeof CONDITION_VALUES[keyof typeof CONDITION_VALUES];

interface FauxEvent {
  target: {
    value: ConditionValues;
  };
}

export interface ConditionInputProps {
  value?: ConditionValues;
  onChange?: (value: FauxEvent) => void;
  onBlur?: (value: FauxEvent) => void;
  testId?: string;
}

const styles = StyleSheet({
  root: {
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontWeight: 600
  },

  btn: {
    borderRadius: 4,
    border: 'none'
  },

  viewMode: {
    width: 50
  },

  largeScreen: {
    width: 40,
    height: 40
  },

  smallScreen: {
    width: 32,
    height: 32
  },

  yes: {
    background: ({ token }) => token('palette.green.300')
  },

  no: {
    background: ({ token }) => token('palette.red.300')
  },

  nil: {
    background: ({ token }) => token('palette.grey.200')
  }
});

export const ConditionInput = React.forwardRef<
  HTMLButtonElement,
  ConditionInputProps
>((props, ref) => {
  const { value, onChange, onBlur, testId = 'condition_input' } = props;
  const s = useStyles(styles);

  const matchesMobile = useMediaQuery({ maxWidth: 's' });
  const matchesLargeScreen = useMediaQuery({ minWidth: 's' });

  const hasEventHandlers =
    typeof onChange === 'function' || typeof onBlur === 'function';

  const [displayLabel, ariaLabel] = React.useMemo(() => {
    if (value === CONDITION_VALUES.YES) return ['Y', 'yes'];
    if (value === CONDITION_VALUES.NO) return ['N', 'no'];

    return ['-', 'no value'];
  }, [value]);

  const handleClick = () => {
    let newValue;

    if (value === CONDITION_VALUES.YES) {
      newValue = CONDITION_VALUES.NO;
    } else if (value === CONDITION_VALUES.NO) {
      newValue = CONDITION_VALUES.NO_VALUE;
    } else {
      newValue = CONDITION_VALUES.YES;
    }

    onChange?.({ target: { value: newValue } });
    onBlur?.({ target: { value: newValue } });
  };

  if (hasEventHandlers) {
    return (
      <button
        {...s.with('root', {
          btn: true,
          yes: value === CONDITION_VALUES.YES,
          no: value === CONDITION_VALUES.NO,
          nil: value == CONDITION_VALUES.NO_VALUE,
          largeScreen: matchesLargeScreen,
          smallScreen: matchesMobile
        })()}
        ref={ref}
        type='button'
        aria-label={ariaLabel}
        onClick={handleClick}
        data-testid={testId}
      >
        {displayLabel}
      </button>
    );
  }

  return (
    <div
      {...s.with('root', {
        viewMode: true,
        yes: value === CONDITION_VALUES.YES,
        no: value === CONDITION_VALUES.NO,
        nil: value == CONDITION_VALUES.NO_VALUE
      })()}
      aria-label={ariaLabel}
      data-testid={testId}
    >
      {displayLabel}
    </div>
  );
});
