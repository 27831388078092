import * as React from 'react';
import { useToast } from 'view/components/@luna/notifications/toast';

export function useRenewRequirementToast({
  complianceTypeName
}: {
  complianceTypeName: string;
}) {
  const { addToast } = useToast();
  return () =>
    addToast({
      description: (
        <>
          {complianceTypeName} has been <strong>successfully updated</strong>
        </>
      )
    });
}
