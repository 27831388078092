import React from 'react';

import { Ownership } from 'data/models/entities/ownerships';
import { Property } from 'src/modules/properties/types/property-types';
import { Contact } from 'src/modules/contacts/types/contact-types';
import { usePropertyOwnershipQuery } from '../hooks/use-property-ownership-query';
import { PropertyOwnershipsListUI } from '../components/property-ownerships-list';

interface PropertyOwnershipsListProps {
  ownership?: Ownership;
  property?: Property;
  contact?: Contact;
}
export function PropertyOwnershipsList({
  ownership,
  property,
  contact
}: PropertyOwnershipsListProps) {
  const ownershipId = ownership?.id;
  const propertyId = property?.id;
  const contactId = contact?.id;

  const query = usePropertyOwnershipQuery({
    propertyId,
    ownershipId,
    contactId
  });

  return (
    <PropertyOwnershipsListUI
      query={query}
      ownership={ownership}
      property={property}
    />
  );
}
