import { formatCurrency } from 'utils/formatters';
import { RentScheduleEntry } from 'src/modules/property-tenancies/types/property-tenancy-types';

const frequencyMap = {
  days: 'daily',
  weeks: 'weekly',
  months: 'monthly',
  years: 'yearly'
};

export function getRentAmountDescriptor(
  frequency: RentScheduleEntry['frequency']
) {
  if (frequency.count === 1) {
    return frequencyMap[frequency.unit.id];
  }

  return 'every ' + frequency.count + ' ' + frequency.unit.id;
}

export function getRentPeriodShortForm(
  frequency: RentScheduleEntry['frequency']
) {
  if (frequency.count === 1) {
    // chop the 's' off the end
    return frequency.unit.id.slice(0, -1);
  }
  // note that currently there will only be a count greater than 1 if we choose 'fortnightly' in the UI
  // (options are 'daily', 'weekly', 'fortnightly', 'monthly', 'yearly')
  if (frequency.count === 2 && frequency.unit.id === 'weeks') {
    return 'fortnight';
  }
  // (but the API is less restricted so I've left this in)
  return frequency.count + ' ' + frequency.unit.id;
}

interface GetRentAndFrequencyArgs {
  rentScheduleEntry: RentScheduleEntry;
  divider?: string;
}

export function getRentAndFrequency({
  rentScheduleEntry,
  divider = ' / '
}: GetRentAndFrequencyArgs): string {
  if (!rentScheduleEntry) {
    return '';
  }

  const getLineItemsTotal = (lineItems: RentScheduleEntry['line_items']) =>
    lineItems!.reduce((total, item) => total + (item.amount ?? 0), 0);

  const formattedAmount = formatCurrency(
    rentScheduleEntry.amount_total_inc_tax ??
      getLineItemsTotal(rentScheduleEntry.line_items)
  );

  return `${formattedAmount}${divider}${getRentAmountDescriptor(
    rentScheduleEntry.frequency
  )}`;
}
