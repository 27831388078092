export const TAX_ROUTES = {
  HMRC_REPORTS_AND_SUBMISSIONS_LIST: {
    config: {
      path: '/financials/hmrc-reports',
      Component: require('./screens/hmrc-reports-and-submissions-list')
        .HmrcReportsAndSubmissionsList
    }
  },
  HMRC_REPORTS_AND_SUBMISSIONS_DETAILS: {
    config: {
      path: '/financials/hmrc-report/:id/',
      Component: require('./screens/hmrc-reports-and-submissions-details')
        .HmrcReportsAndSubmissionsDetails
    }
  },
  HMRC_REPORTS_AND_SUBMISSIONS_QUARTER_DETAILS: {
    config: {
      path: '/financials/hmrc-report-quarterly-payments/:id/:quarterId/',
      Component: require('./screens/hmrc-reports-and-submissions-quarter-details')
        .HmrcReportsAndSubmissionsQuarterDetails
    }
  },
  HMRC_REPORTS_AND_SUBMISSIONS_FINANCIAL_YEAR_STATEMENTS_DETAILS: {
    config: {
      path: '/financials/hmrc-report-year-statement/:id',
      Component: require('./screens/hmrc-reports-and-submissions-financial-year-statements-details')
        .HmrcReportsAndSubmissionsFinancialYearStatementsDetails
    }
  }
};
