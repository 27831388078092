import { ContentConfig } from 'view/components/record-screen/types';
import { useAccountingJournalEntryDetailsBlock } from '../blocks/accounting-journal-entry-details-block';
import { useAccountingJournalEntryLineItemBlock } from '../blocks/accounting-journal-entry-line-item-block';

export const useAccountingJournalEntryRecordScreenContent = (): ContentConfig => {
  const accountingJournalEntryDetailsBlock = useAccountingJournalEntryDetailsBlock();
  const accountingJournalEntryLineItemBlock = useAccountingJournalEntryLineItemBlock();

  return [
    {
      label: 'General',
      items: [
        {
          id: 'overview',
          label: 'Overview',
          blocks: [
            accountingJournalEntryDetailsBlock,
            accountingJournalEntryLineItemBlock
          ]
        }
      ]
    }
  ];
};
