import React from 'react';
import { lowerCase } from 'lodash';
import { useStyles, StyleSheet } from '@rexlabs/styling';
import {
  TitleBlock,
  TitleBlockProps
} from 'view/components/cards/title/title-block';
import { RecordObject, RecordTypes } from 'data/models/types';
import { useGetPrimaryRecordActionGroup } from 'view/hooks/actions/use-get-primary-record-action-group';
import { getRecordTitle } from 'utils/records/get-record-title';
import PropertyIcon from 'view/components/icons/property';
import { TagConfig } from 'view/components/@luna/title-block';
import { propertiesModel } from 'data/models/entities/properties';
import LeaseReviewIcon from 'view/components/icons/lease-review';
import { formatDate } from 'utils/dates/format';
import { useGetAddDocumentsAction } from 'src/modules/documents/actions/use-get-add-documents-action';
import { useGetAddNotesAction } from 'src/modules/tasks/common/actions/get-add-notes-action';
import { useGetToggleClosedAction } from 'src/modules/tasks/common/hooks/action-declarations/use-get-toggle-closed-action';
import { useGetCreateEmailAction } from 'src/modules/communications/messages/hooks/action-declarations/use-get-create-email-action';
import { useGoToLeaseAgreementAction } from 'src/modules/tasks/lease-review/actions/use-go-to-lease-agreement-action';
import { useTranslation } from 'react-i18next';
import { useGetCreateSMSAction } from 'src/modules/communications/messages/hooks/action-declarations/use-get-create-sms-action';
import { LeaseReview } from '../types/LeaseReview';
import { useLeaseReviewStatusTag } from '../hooks/use-lease-review-status-tag';

const type = RecordTypes.TaskLeaseReview;

type LeaseReviewRecordObject = Extract<
  RecordObject,
  { type: 'task_lease_review' }
>;

type LeaseReviewTitleBlockProps = {
  leaseReview?: LeaseReview;
};

const styles = StyleSheet({
  subText: {
    color: ({ token }) => token('color.textStyle.body.default'),
    fontWeight: ({ token }) => token('typography.weight.semibold')
  }
});

export function LeaseReviewTitleBlock({
  leaseReview
}: LeaseReviewTitleBlockProps) {
  const s = useStyles(styles);
  const { t } = useTranslation();

  const getPrimaryRecordActionGroup = useGetPrimaryRecordActionGroup();
  const getCreateEmailAction = useGetCreateEmailAction(propertiesModel);
  const getCreateSMSAction = useGetCreateSMSAction(propertiesModel);
  const getAddDocumentsAction = useGetAddDocumentsAction();
  const getAddNotesAction = useGetAddNotesAction();
  const getToggleClosedAction = useGetToggleClosedAction();
  const statusTag: TagConfig = useLeaseReviewStatusTag(leaseReview);
  const getLeaseAgreementAction = useGoToLeaseAgreementAction();

  const title = getRecordTitle({
    type,
    object: leaseReview
  } as LeaseReviewRecordObject);

  const actions = [
    ...getPrimaryRecordActionGroup('task_lease_review', leaseReview!.id),
    {
      label: 'Actions',
      actions: [
        getCreateEmailAction(leaseReview!.property),
        getCreateSMSAction(leaseReview!.property),
        getAddDocumentsAction({ data: leaseReview! }),
        getAddNotesAction({ data: leaseReview! }),
        getToggleClosedAction(leaseReview!)
      ]
    },
    getLeaseAgreementAction({ data: leaseReview! })
  ];

  if (!leaseReview) {
    return null;
  }

  const subTitles: TitleBlockProps['subTitles'] = [
    {
      Icon: PropertyIcon,
      label: leaseReview?.property?.address_string
    }
  ];

  const closed_at = leaseReview.closed_at;

  return (
    <TitleBlock
      title={title}
      subTitles={subTitles}
      type={t('tasks.lease-review.label.singular') as string}
      actions={actions}
      Icon={() => <LeaseReviewIcon size='xl' />}
      tags={closed_at ? [] : [statusTag]}
      subText={
        closed_at ? (
          <div>
            <span>{t('tasks.lease-review.closed-with-status.label')} </span>
            <span {...s('subText')}>
              {lowerCase(leaseReview.details?.status?.label)}
            </span>{' '}
            on <span {...s('subText')}>{formatDate(closed_at)}</span>
          </div>
        ) : undefined
      }
    />
  );
}
