import React from 'react';

import { Link } from '@rexlabs/whereabouts';

import ROUTES from 'routes/app';

import { Banner } from 'src/modules/common/components/banner';

import { InfoCircleIcon } from 'view/components/icons/info';

import { MessageTemplateDetailsFormValues } from '../blocks/message-template-details-block';
import { useMessageTriggerSettings } from '../../messages/settings/message-triggers/hooks/use-message-trigger-settings';
import { MessageTemplate } from '../types/MessageTemplate';

export function TemplateBanner({
  values,
  data
}: {
  values?: MessageTemplateDetailsFormValues;
  data?: MessageTemplate;
}) {
  const { messageTriggerSettings } = useMessageTriggerSettings();

  // TODO: Looking at the designs, that we will have new context types for each new trigger.
  // Not 100% but need to confirm as we build out the BE. For now, I'm faking it, but hopefully just
  // need to uncomment this when we have the new context types.
  // https://rexsoftware.atlassian.net/browse/ALFIE-2269
  const contextType = `${values?.context_type.label}`;

  const isDefaultTemplate = Object.values(messageTriggerSettings).some(
    (item) => item.template_id === data?.id
  );

  const baseString = isDefaultTemplate
    ? `This is the default template used when actions trigger sending a message, relating to ${contextType}. Go to `
    : `This template can be used when actions trigger sending a message, relating to ${contextType}. Go to `;

  return (
    <Banner intent='information' align='start' Icon={InfoCircleIcon}>
      <span>
        {baseString}
        <Link to={ROUTES.MESSAGE_TRIGGER_SETTINGS}>Message triggers</Link>
        {` to change these settings.`}
      </span>
    </Banner>
  );
}
