import { ActionDeclaration } from 'src/modules/common/actions/types/action-declaration-types';
import { useDialog } from '@rexlabs/dialog';
import { EditEntityDialog } from 'src/modules/agency-fees/dialogs/edit-entity-fee';
import {
  OwnershipFee,
  ownershipsFeesModel
} from 'data/models/entities/ownerships/fees/ownership-fees';
import { useModelActions } from '@rexlabs/model-generator';
import { useToast } from 'view/components/@luna/notifications/toast';
import React from 'react';
import { formatDynamicAmount } from 'src/modules/agency-fees/utils/format-dynamic-amount';

export function useGetEditOwnershipFee() {
  const { updateItem } = useModelActions(ownershipsFeesModel);
  const { open } = useDialog(EditEntityDialog);
  const { addToast } = useToast();

  return (fee: OwnershipFee, ownershipId: string): ActionDeclaration => ({
    id: 'edit-ownership-fee',
    group: 'ownership-fee',
    label: 'Edit',
    handleAction: () => {
      open({
        fee,
        feeType: 'ownership',
        onSubmit: async (updateFee) => {
          const updatedFeeRequest = await updateItem({
            id: fee.id,
            args: {
              ownershipId,
              include: 'agency_fee'
            },
            data: updateFee
          });
          const updatedFee = updatedFeeRequest.data as OwnershipFee;

          addToast({
            description: (
              <>
                <b>{updatedFee.agency_fee?.name} fee</b> updated to a rate of{' '}
                <b>{formatDynamicAmount(updatedFee.fee_amount)}</b>
              </>
            )
          });

          //Disbursement fee updated to a rate of $5.00 inc. GST
        }
      });
    }
  });
}
