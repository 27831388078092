import { EntityModel } from '@rexlabs/model-generator';
import React, { useMemo } from 'react';
import { ActionButtons } from 'view/components/@luna/action-buttons';
import { BlockConfig } from 'view/components/record-screen/types';

import { useMessageTableEmptyState } from '../components/message-table-empty-state';
import { MessagesTabbedTable } from '../components/messages-tabbed-table';
import { useGetCreateEmailAction } from '../hooks/action-declarations/use-get-create-email-action';
import { useGetCreateSMSAction } from '../hooks/action-declarations/use-get-create-sms-action';

export const useMessageTableBlock = (
  recordModel: EntityModel<any>
): BlockConfig =>
  useMemo(() => {
    return {
      id: 'messages',
      title: 'Messages',
      View: ({ data }) => {
        const MessageTableEmptyState = useMessageTableEmptyState(recordModel);

        return (
          <MessagesTabbedTable
            forcedGlobalFilters={[
              {
                field: 'context_record_id',
                op: 'eq',
                value: data.id
              },
              {
                field: 'context_record_type_id',
                op: 'eq',
                value: data.__record_type
              }
            ]}
            messageHiddenColumns={['context', 'message_format']}
            channelMessageRecipientHiddenColumns={[
              'message_relates_to',
              'message_format'
            ]}
            TabEmptyState={({ label }) => (
              <MessageTableEmptyState label={label} record={data} />
            )}
          />
        );
      },
      Actions: ({ data }) => {
        const getCreateEmailAction = useGetCreateEmailAction(recordModel);
        const getCreateSMSAction = useGetCreateSMSAction(recordModel);

        if (!data) {
          return null;
        }

        return (
          <ActionButtons
            actions={[
              { ...getCreateSMSAction(data), intent: 'secondary' },
              { ...getCreateEmailAction(data), intent: 'secondary' }
            ]}
          />
        );
      }
    };
  }, []);
