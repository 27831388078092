import React from 'react';
import { BlockConfig } from 'view/components/record-screen/types';
import { Column, Grid } from '@rexlabs/grid';
import { Field } from '@rexlabs/form';
import { CentAmountInput } from 'view/components/inputs/cent-amount';
import { Value } from 'view/components/values';
import { DateValue } from 'view/components/values/date';

export interface StartMonthlyReconciliationData {
  reconciliation_period: string;
  statement_date: Date;
}

export const startMonthlyReconciliation: BlockConfig<StartMonthlyReconciliationData> = {
  id: 'start-monthly-reconciliation',
  validate: {
    definitions: {
      statement_balance: { name: 'statement balance', rules: 'required' }
    }
  },
  Edit: ({ data }) => (
    <Grid>
      <Column width={6}>
        <Value
          label='Reconciliation period'
          value={data!.reconciliation_period}
        />
      </Column>
      <Column width={6} />
      <Column width={6}>
        <DateValue label='Bank statement date' value={data!.statement_date} />
      </Column>
      <Column width={6}>
        <Field
          name='statement_balance'
          label='Closing balance'
          Input={CentAmountInput}
        />
      </Column>
    </Grid>
  )
};
