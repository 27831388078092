import React, { useMemo } from 'react';
import { query, useEntityQuery } from '@rexlabs/model-generator';
import { Box } from '@rexlabs/box';

import { BREADCRUMBS } from 'view/components/@luna/breadcrumbs';
import { RecordScreen } from 'view/components/record-screen';
import {
  financialsReconciliationsModel,
  Reconciliation
} from 'data/models/entities/financials/reconciliations';
import { RightBarCard } from 'view/components/right-bar-card/right-bar-card';
import { formatCurrency } from 'utils/formatters';
import { formatDate } from 'utils/dates/format';
import { useRecordScreenSubmitHandler } from 'view/hooks/use-record-screen-submit-handler';

import { Summary } from 'view/components/statement';
import { RecordChangeLog } from 'view/components/record-change-log/record-change-log';
import dayjs from 'dayjs';
import { VoidedBanner } from 'src/modules/common/components/voided-banner';
import { reconciliationDetailsContent } from '../data/reconciliation-details';
import {
  useGetBankStatementSummaryEntries,
  getCashbookSummaryEntries,
  useGetTrialBalanceSummaryEntries
} from '../utils/get-reconciliation-summary-entries';
import { ReconciliationTitleBlock } from '../components/reconciliation-title-block';

const getReconciliationQuery = (reconciliationId: string) => query`{
  ${financialsReconciliationsModel} (id: ${reconciliationId}) {
    id
    reconciliation_summary
    created_by {
      user
    }
    updated_by {
      user
    }
    voided_by {
      user
    }
    verified_by {
      user
    }
    reconciled_by {
      user
    }
    bank_account
    voided_by
    previous_reconciliation
  }
}`;

interface ReconciliationDetailsScreenProps {
  reconciliationId?: string;
}

export function ReconciliationDetailsScreen({
  reconciliationId
}: ReconciliationDetailsScreenProps) {
  const breadcrumbs = [{ type: BREADCRUMBS.RECONCILIATION }];
  const query = useMemo(() => getReconciliationQuery(reconciliationId!), [
    reconciliationId
  ]);
  const result = useEntityQuery(query, {
    throwOnError: false
  });

  const handleSubmit = useRecordScreenSubmitHandler(({ changedValues }) =>
    result.actions.updateItem({
      id: reconciliationId,
      data: changedValues
    })
  );

  const getBankStatementSummaryEntries = useGetBankStatementSummaryEntries();
  const getTrialBalanceSummaryEntries = useGetTrialBalanceSummaryEntries();

  return (
    <RecordScreen
      isLoading={result.status === 'loading'}
      data={result.data}
      handleSubmit={handleSubmit}
      content={reconciliationDetailsContent}
      rightBarTitle='Reconciliation details'
      RightBarOverride={() =>
        result.data ? (
          <Box sy='4.8rem'>
            <RightBarCard
              includePadding
              headline='Bank statement'
              headlineRight={formatCurrency(result.data.statement_balance)}
              subtitle={`As at ${formatDate(result.data.statement_date)}`}
            >
              <Summary items={getBankStatementSummaryEntries(result.data)} />
            </RightBarCard>
            <RightBarCard
              includePadding
              headline='Cashbook'
              headlineRight={formatCurrency(
                result.data.reconciliation_summary
                  ?.cashbook_opening_balance_amount
              )}
              subtitle={`As at ${formatDate(
                dayjs(result.data.start_date).subtract(1, 'day')
              )}`}
            >
              <Summary items={getCashbookSummaryEntries(result.data)} />
            </RightBarCard>
            <RightBarCard
              includePadding
              headline='Trial Balance'
              headlineRight={formatCurrency(
                result.data.reconciliation_summary?.trust_trial_balance_amount
              )}
              subtitle={`As at ${formatDate(result.data.statement_date)}`}
            >
              <Summary items={getTrialBalanceSummaryEntries(result.data)} />
            </RightBarCard>
            <RecordChangeLog
              record={result.data}
              items={[
                {
                  label: 'Completed by',
                  author: result.data?.reconciled_by ?? null,
                  timestamp: result.data?.reconciled_at
                },
                {
                  label: 'Validated by',
                  author: result.data?.verified_by ?? null,
                  timestamp: result.data?.verified_at
                },
                {
                  label: 'Voided by',
                  author: result.data?.voided_by ?? null,
                  timestamp: result.data?.voided_at
                }
              ]}
            />
          </Box>
        ) : null
      }
      titleBlock={
        <ReconciliationTitleBlock
          reconciliation={result.data as Reconciliation}
        />
      }
      breadcrumbs={breadcrumbs}
      banner={
        result.data?.status.id === 'voided' && (
          <VoidedBanner record={result.data} />
        )
      }
    />
  );
}
