export const LEASE_REVIEW_ROUTES = {
  LEASE_REVIEW_LIST: {
    config: {
      path: '/lease-reviews',
      Component: require('./screens/lease-review-list.tsx').LeaseReviewList
    }
  },
  LEASE_REVIEW_DETAILS: {
    config: {
      path: '/lease-review/:leaseReviewId',
      Component: require('./screens/lease-review-details.tsx')
        .LeaseReviewDetails
    }
  }
};
