import { identity } from 'lodash';

import {
  BaseModelGeneratorModel,
  CustomAction
} from '@rexlabs/model-generator';

import { api } from 'utils/api/api-client';

import { Generator, GeneratorConfig } from 'data/models/generator';
import { TimestampAttributes } from 'data/models/types';
import { Tenancy, tenancyModel } from 'data/models/entities/tenancies';
import { Ownership, ownershipsModel } from 'data/models/entities/ownerships';
import { propertiesModel } from 'data/models/entities/properties';

import { Contact } from 'src/modules/contacts/types/contact-types';
import { contactsModel } from 'src/modules/contacts/models/contacts';
import { Property } from 'src/modules/properties/types/property-types';
import { FileUpload } from 'src/modules/common/types/file';

export interface KeySet extends BaseModelGeneratorModel, TimestampAttributes {
  name: string;
  object?: Contact | Ownership | Tenancy;
  number_of_keys: number;
  notes?: string;
  images?: { data: FileUpload[] };
  status?: { id: string; label: string };
  status_reason?: string;
  checked_out_date?: string;
  return_by_date?: string;
  checked_in_date?: string;
  property: Property;
}

type CheckOutPayload = {
  id: string;
  status_reason?: string;
  object: Contact | Ownership | Tenancy;
  checked_out_date: string;
  return_by_date?: string;
};

type CheckInPayload = {
  id: string;
  status_reason?: string;
  checked_in_date: string;
};

const config: GeneratorConfig = {
  entities: {
    related: {
      property: {
        include: 'property',
        model: propertiesModel
      },
      object: {
        include: 'object',
        model: [contactsModel, ownershipsModel, tenancyModel]
      },
      images: {
        include: 'images'
      }
    }
  }
};

const actionCreators = {
  checkOutKey: {
    request: (payload: CheckOutPayload) =>
      api.patch(
        `/key-sets/${payload.id}/check-out`,
        {
          status: {
            id: 'checked_out'
          },
          status_reason: payload?.status_reason,
          checked_out_date: payload.checked_out_date,
          object: payload?.object,
          return_by_date: payload?.return_by_date
        },
        { params: { include: 'property,object,images' } }
      ),
    reduce: {
      initial: identity,
      success: identity,
      failure: identity
    }
  } as CustomAction<CheckOutPayload, unknown>,
  checkInKey: {
    request: (payload: CheckInPayload) =>
      api.patch(
        `/key-sets/${payload.id}/check-in`,
        {
          status: {
            id: 'checked_in'
          },
          status_reason: payload?.status_reason,
          object: null,
          checked_out_date: null,
          checked_in_date: payload?.checked_in_date
        },
        { params: { include: 'property,object,images' } }
      ),
    reduce: {
      initial: identity,
      success: identity,
      failure: identity
    }
  } as CustomAction<CheckInPayload, unknown>
};

export const keySetsModel = new Generator<KeySet, typeof actionCreators>(
  'key-sets',
  config
).createEntityModel({ actionCreators });
