import React from 'react';

import { Query } from '@rexlabs/model-generator';
import { Columns, RecordTable } from 'view/components/table';
import { getRecordLinkProps } from 'view/components/record-link/get-record-link-props';
import { Quri } from '@rexlabs/quri';
import { GlobalFilter } from '@rexlabs/table/lib/types/context';
import { BankDeposit } from 'src/modules/banking/bank-deposits/types/bank-deposit-types';
import { bankDepositModel } from '../models/bank-deposit-model';

const columns: Columns<BankDeposit> = [
  {
    id: 'status',
    type: 'value',
    width: 140,
    Header: 'Status'
  },
  {
    id: 'created_at',
    type: 'date',
    Header: 'Created at',
    width: 140
  },
  {
    id: 'type',
    type: 'value',
    Header: 'Type'
  },
  {
    id: 'total.amount',
    type: 'currency',
    cellProps: {
      align: 'right'
    },
    Header: 'Amount'
  }
];

const getRowLinkProps = ({ item }) =>
  getRecordLinkProps({ type: 'bank_deposit', id: item.id });

interface BankDepositsListUIProps {
  query: Query<typeof bankDepositModel>;
  filters?: Quri[];
}

export function BankDepositsListUI({
  query,
  filters
}: BankDepositsListUIProps) {
  return (
    <RecordTable
      id='bank-deposits'
      columns={columns}
      getQuery={() => query}
      getRowLinkProps={getRowLinkProps}
      forcedGlobalFilter={filters as GlobalFilter[]}
    />
  );
}
