import React from 'react';
import { BlockConfig } from 'view/components/record-screen/types';
import { Field } from '@rexlabs/form';
import { CashBookEntriesListSelectInput } from '../connectors/cash-book-entries-list-select-input';

export const editableCashBookEntriesBlock: BlockConfig = {
  id: 'cashBookEntries',
  title: 'Cash book entries',
  Edit: ({ data }) => (
    <Field
      name='cashBookEntries'
      Input={CashBookEntriesListSelectInput}
      inputProps={{ trustAccountId: data.trustAccountId }}
    />
  )
};
