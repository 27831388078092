import { toQuri, Quri } from '@rexlabs/quri';
import { Contact, ContactRole } from 'src/modules/contacts/types/contact-types';
import { api } from './api-client';

type GetContactArgs = {
  searchTerm?: string;
  filterByRoles?: ContactRole[];
  per_page?: number;
  includes?: Array<string>;
};

export async function getContacts({
  searchTerm,
  filterByRoles = [],
  includes,
  per_page = 30
}: GetContactArgs) {
  const filter: Quri[] = [];

  if (filterByRoles.length) {
    filter.push({
      field: 'roles',
      op: 'in',
      value: filterByRoles
    });
  }

  const filterString = filter.length ? `q=${toQuri(filter)}` : '';
  const includesString = includes?.length
    ? `include=${includes.join(',')}`
    : ``;

  const contacts = await api.get<Contact[]>(
    `/contacts?${filterString}${includesString}`,
    {
      per_page,
      search: searchTerm
    }
  );

  return contacts.data;
}
