import { ContentConfig } from 'view/components/record-screen/types';
import { invoiceDetailsBlock } from '../blocks/invoice-details-block';
import { reimbursementInvoicesBlock } from '../blocks/reimbursement-invoices';

export const content: ContentConfig = [
  {
    label: 'General',
    items: [
      {
        id: 'overview',
        label: 'Overview',
        blocks: [invoiceDetailsBlock, reimbursementInvoicesBlock]
      }
    ]
  }
];
