import { useState, useEffect } from 'react';
import { api } from 'utils/api/api-client';
import { ValueListValue } from 'data/models/types';

type Request<T> = {
  data: T;
};

type AmountType = {
  label: string;
  amount_type: ValueListValue<string>;
};

type AgencyTrigger = {
  id: string;
  label: string;
  rate_types: AmountType[];
  available_for: string[];
};

export function useGetAgencyFeeTriggers() {
  const [isLoading, setIsLoading] = useState(true);
  const [triggers, setTriggers] = useState<AgencyTrigger[]>([]);

  useEffect(() => {
    setIsLoading(true);
    api
      .get('/financials/agency-fees/meta/triggers')
      .then(({ data }: Request<AgencyTrigger[]>) => {
        setIsLoading(false);
        setTriggers(data);
      });
  }, []);

  return {
    isLoading,
    triggers
  };
}
