import * as React from 'react';
import invariant from 'invariant';
import { useQuery } from 'react-query';

import { toQuri } from '@rexlabs/quri';
import {
  ButtonGroup,
  GhostButton,
  GhostIconButton,
  PrimaryButton
} from '@rexlabs/button';
import { Box } from '@rexlabs/box';
import { Heading } from '@rexlabs/text';
import { StatusAverageTag, StatusGoodTag, Tag } from '@rexlabs/tag';

import { api } from 'utils/api/api-client';
import { getRecordLabel } from 'utils/records/get-record-label';

import { ActionButtons } from 'view/components/@luna/action-buttons';
import { RecordTable } from 'view/components/table';
import { BlockConfig } from 'view/components/record-screen/types';
import { EmptyCard } from 'view/components/record-screen/cards/empty-card';
import { LoadingState } from 'view/components/states/loading';
import { Message } from 'view/components/@luna/message';
import { EditIcon } from 'view/components/icons/edit';
import EmptyTable from 'assets/illustrations/empty-table.svg';
import { Card, CardContent } from 'view/components/@luna/card';
import { ChevronRightIcon } from 'view/components/icons/chevron-right';
import { ChevronDownIcon } from 'view/components/icons/chevron-down';

import { useWhereabouts } from '@rexlabs/whereabouts';
import { useFeatureFlags } from 'view/components/@luna/feature-flags';
import { ChecklistEdit } from '../components/checklist-edit';
import {
  Checklist,
  ChecklistItem as ChecklistItemType,
  ChecklistWithIncludes
} from '../types/Checklist';
import { useColumns } from '../utils/checklists-columns';
import { getCompletedCount } from '../utils/get-completed-count';
import { useGetAddChecklistAction } from '../../todos/hooks/action-declarations/use-get-add-checklist-action';
import { useTaskTypesWithCustomAddToRecord } from '../../settings/hooks/use-task-types-with-custom-add-to-record';
import { Task } from '../types/Task';
import { TaskType } from '../types/TaskType';
import { useGetIsSmartChecklist } from '../../settings/hooks/use-get-is-smart-checklist';
import { useDeleteChecklist } from '../hooks/use-delete-checklist';
import { useChecklistSubmit } from '../hooks/use-submit-checklist';
import { ChecklistItemList } from '../../checklists/components/checklist-item-list';
import { SMART_CHECKLISTS_FLAGS } from '../../settings/feature-flags';
import { getChecklistViewItems } from '../utils/get-checklist-view-items';

const defaultQueryOptions = {
  keepPreviousData: true,
  refetchOnWindowFocus: false
};

export const checklistsBlock: BlockConfig<Task<TaskType>> = {
  id: `checklists`,
  title: `Checklists`,
  Card: (props) => {
    const w = useWhereabouts();
    const taskTypesWithCustomAddToRecord = useTaskTypesWithCustomAddToRecord();
    const hasCustomChecklistFlag = taskTypesWithCustomAddToRecord.includes(
      props.data.type.id
    );
    const { data: cardData } = props;

    const queryParams = React.useMemo(
      () => checklistsQueryParams(cardData.id),
      [cardData.id]
    );

    const checklistsQuery = useQuery(
      ['checklists', cardData.id],
      async () => api.get<Array<Checklist>>(`tasks/checklists?${queryParams}`),
      defaultQueryOptions
    );

    const isEmpty =
      checklistsQuery.isSuccess && checklistsQuery.data?.data?.length === 0;

    const getAddChecklistAction = useGetAddChecklistAction();
    const addChecklistAction = getAddChecklistAction(cardData);

    // If we came here with a hash, scroll into view
    React.useEffect(() => {
      if (
        w.query?.tab === 'checklist' &&
        w.hash &&
        checklistsQuery.data?.data
      ) {
        // Slightly convoluted, but because the elements load in slowly,
        // the one we want to scroll to may not be present when the block first loads,
        // so retrying until success or timeout is probably the most reliable way to do this.
        const startTime = Date.now();

        const tryScrolling = () => {
          const element = document.getElementById(w.hash!);
          if (element) {
            element.scrollIntoView({ behavior: 'smooth', block: 'start' });
            // If the element was not found, and we've been trying for less than 2.5s
          } else if (Date.now() - startTime < 2500) {
            // Try again after 50ms
            setTimeout(tryScrolling, 50);
          }
        };

        tryScrolling();
      }
    }, [w.hash, w.query?.tab, checklistsQuery.data?.data]);

    return (
      <>
        {!isEmpty && !checklistsQuery.isLoading && hasCustomChecklistFlag && (
          <Card>
            <CardContent>
              <Box
                flexDirection='row'
                alignItems='center'
                justifyContent='space-between'
              >
                <Box
                  flexDirection='row'
                  alignItems='center'
                  sx='1.2rem'
                  flex={1}
                >
                  <Box width='100%'>
                    <Heading level={3}>Checklist</Heading>
                  </Box>
                </Box>
                <Box ml='2.4rem'>
                  <ActionButtons actions={[addChecklistAction]} />
                </Box>
              </Box>
            </CardContent>
          </Card>
        )}
        {checklistsQuery.isLoading && <Loading />}
        {isEmpty && <EmptyState data={cardData} />}
        {(checklistsQuery?.data?.data || []).map((checklist) => {
          return (
            <ChecklistCard
              {...props}
              key={checklist.id}
              checklistId={checklist.id}
            />
          );
        })}
      </>
    );
  }
};

interface ChecklistCardProps {
  checklistId: Checklist['id'];
  data: Task<TaskType>;
}

export type ChecklistItemWithId = ChecklistItemType & {
  checklistId: string;
  checklist: Checklist;
  task: Task<TaskType>;
};

const ChecklistCard = (props: ChecklistCardProps) => {
  const taskTypesWithCustomAddToRecord = useTaskTypesWithCustomAddToRecord();
  const task = props.data;
  const taskType = props.data.type.id;
  const hasCustomChecklistFlag = taskTypesWithCustomAddToRecord.includes(
    taskType
  );
  const [isVisible, setIsVisible] = React.useState(true);

  const { checklistId } = props;

  const handleSubmitChecklist = useChecklistSubmit();
  const handleDeleteChecklist = useDeleteChecklist();

  const queryParams = checklistQueryParams();
  const cardId = `checklists-${checklistId}`;
  const formId = `checklists-form-${checklistId}`;

  const checklistQuery = useQuery(
    ['checklist', checklistId],
    () => {
      return api.get<ChecklistWithIncludes>(
        `tasks/checklists/${checklistId}?${queryParams}`
      );
    },
    defaultQueryOptions
  );

  const [isEditing, setIsEditing] = React.useState(false);
  const [isSaving, setIsSaving] = React.useState(false);

  if (checklistQuery.isLoading) {
    return (
      <Card id={cardId}>
        <CardContent>
          <LoadingState />
        </CardContent>
      </Card>
    );
  }

  invariant(checklistQuery.data?.data, `data can't be empty`);

  const checklist = checklistQuery.data.data;
  const totalCount = getChecklistViewItems(
    checklist.items.data,
    checklist.item_order,
    checklist
  ).length;
  const completedCount = getCompletedCount(
    checklist.items.data,
    checklist.item_order,
    checklist
  );

  const allowDeletion = !!(
    checklist.template?.is_default === false &&
    taskTypesWithCustomAddToRecord.includes(checklist.template.task_type.id) &&
    hasCustomChecklistFlag
  );

  const hiddenCount = getHiddenCount(
    checklist.items.data,
    checklist.item_order
  );
  const hiddenText = hiddenCount === 1 ? `item` : `items`;
  const CompletionTag = getTagComponent(completedCount, totalCount);

  const viewItems = getChecklistViewItems(
    checklist.items.data,
    checklist.item_order,
    checklist,
    task
  ) as ChecklistItemWithId[];

  const editItems = getEditItems(
    checklist.items.data,
    checklist.item_order,
    checklist.id
  );

  const deleteChecklist = () =>
    handleDeleteChecklist(
      checklistId,
      getRecordLabel({
        object: checklist,
        type: 'checklist'
      })
    );

  const handleSubmit = (values) => {
    handleSubmitChecklist(values, checklist, setIsSaving, setIsEditing);
  };

  return (
    <Card id={cardId}>
      <CardContent>
        <Box
          flexDirection='row'
          alignItems='center'
          justifyContent='space-between'
        >
          <Box flexDirection='row' alignItems='center' sx='1.2rem' flex={1}>
            {/* The below button triggers the collapsible content - this has been repurposed from the inline content component */}
            {hasCustomChecklistFlag && (
              <GhostIconButton
                aria-label='Expand block'
                Icon={isVisible ? ChevronDownIcon : ChevronRightIcon}
                onClick={() => {
                  setIsVisible((state) => !state);
                }}
              />
            )}
            <Heading level={3}>
              <span id={checklistId}>{checklist.label}</span>
            </Heading>

            <CompletionTag>
              {completedCount} / {totalCount} completed
            </CompletionTag>

            {hiddenCount > 0 ? (
              <Tag>
                {hiddenCount} hidden {hiddenText}
              </Tag>
            ) : null}

            <Box marginLeft='auto'>
              {isEditing ? (
                <ButtonGroup>
                  <GhostButton onClick={() => setIsEditing(false)}>
                    Cancel
                  </GhostButton>
                  <PrimaryButton
                    isLoading={isSaving}
                    type='submit'
                    form={formId}
                  >
                    Save
                  </PrimaryButton>
                </ButtonGroup>
              ) : (
                <span className='edit-button'>
                  <GhostIconButton
                    aria-label='Edit block'
                    Icon={EditIcon}
                    onClick={() => {
                      setIsEditing(true);
                      if (hasCustomChecklistFlag) {
                        setIsVisible(true);
                      }
                    }}
                  />
                </span>
              )}
            </Box>
          </Box>
        </Box>
        {(hasCustomChecklistFlag && isVisible) || !hasCustomChecklistFlag ? (
          <>
            {isEditing ? (
              <ChecklistEdit
                data={editItems}
                formId={formId}
                handleSubmit={handleSubmit}
                taskType={taskType}
                onDelete={allowDeletion ? deleteChecklist : undefined}
              />
            ) : (
              <ChecklistView data={viewItems} taskType={taskType} />
            )}
          </>
        ) : null}
      </CardContent>
    </Card>
  );
};

const ChecklistView = ({
  data,
  taskType
}: {
  data: Array<ChecklistItemWithId>;
  taskType: TaskType;
}) => {
  const getIsSmartChecklist = useGetIsSmartChecklist();
  const isSmartChecklist = getIsSmartChecklist(taskType);
  const columns = useColumns(isSmartChecklist);
  const { hasFeature } = useFeatureFlags();
  const newUi = hasFeature(SMART_CHECKLISTS_FLAGS.SMART_CHECKLISTS_UI_OVERHAUL);

  return (
    <>
      {newUi ? (
        <ChecklistItemList data={data} />
      ) : (
        <RecordTable isLoading={false} items={data} columns={columns} />
      )}
    </>
  );
};

// UI States

const Loading = () => <LoadingState>Loading items...</LoadingState>;

const EmptyState = ({ data }) => {
  const taskTypesWithCustomAddToRecord = useTaskTypesWithCustomAddToRecord();
  const hasCustomChecklistFlag = taskTypesWithCustomAddToRecord.includes(
    data.type.id
  );
  const getAddChecklistAction = useGetAddChecklistAction();
  const addChecklistAction = getAddChecklistAction(data);
  return (
    <EmptyCard>
      <Message
        title='Checklist'
        Illustration={EmptyTable}
        actions={[...(hasCustomChecklistFlag ? [addChecklistAction] : [])]}
      >
        <div>There are no checklists for this task.</div>
      </Message>
    </EmptyCard>
  );
};

function getTagComponent(count, total) {
  if (count === 0) return Tag;
  if (count === total) return StatusGoodTag;

  return StatusAverageTag;
}

function getEditItems(
  items: ChecklistItemType[],
  order: Checklist['item_order'],
  checklistId: string
) {
  return order.map((orderId) => {
    return {
      ...items.find((item) => item.id === orderId)!,
      checklistId
    };
  });
}

function getHiddenCount(
  items: ChecklistItemType[],
  itemOrder: string[]
): number {
  return items.filter(
    (item) => item.status?.id === 'inactive' && itemOrder.includes(item.id)
  ).length;
}

function checklistsQueryParams(id: string) {
  const filter = toQuri([
    {
      field: 'task_id',
      op: 'eq',
      value: id
    }
  ]);

  const query = new URLSearchParams();
  query.set('q', filter);
  query.set('o', encodeURIComponent(`updated_at+desc`));

  return decodeURIComponent(query.toString());
}

function checklistQueryParams() {
  const includes = [
    'items',
    'items.completed_by',
    'items.assigned_to',
    'items.due_date_data',
    'items.action_data',
    'items.action_message_template',
    'items.action_message_template.channels',
    'items.action_message_template.inline_attachments',
    'items.action_message.channels',
    'items.action_message.context',
    'items.action_message.inline_attachments',
    'items.action_message.send_from_author',
    'items.action_message.recipients',
    'items.action_message.inline_attachments',
    'items.action_message.cc_recipients',
    'items.action_message.bcc_recipients',
    'items.outcome_data',
    'items.checklist_item_group',
    'groups',
    'groups.triggered_by_checklist_item',
    'template'
  ];

  const query = new URLSearchParams();
  query.set('include', includes.join(','));

  return decodeURIComponent(query.toString());
}
