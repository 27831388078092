import * as React from 'react';
import { useQuery } from 'react-query';
import { api } from 'utils/api/api-client';
import { ChannelTypeId } from '../types/channel';
import { CommunicationContextType } from '../types/communication-context-type';
import { MergeTagCategory } from '../types/merge-tags';
import { RecipientGroup } from '../types/recipient-group';
import { useRemappedMergeTags } from './use-remapped-merge-tags';

/**
 * Returns the Merge Tags and Recipient Groups for a given Communications Message Context
 * NOTE: this is not a React Context - just an overloaded term
 */
export function useMessageContext(
  channelType: ChannelTypeId,
  contextType?: CommunicationContextType
) {
  const [mergeTags, setMergeTags] = React.useState<MergeTagCategory[]>([]);
  const [recipientGroups, setRecipientGroups] = React.useState<
    RecipientGroup[]
  >([]);
  // Use the hook to remap merge tags.
  const remappedMergeTags = useRemappedMergeTags(mergeTags);

  const { data } = useQuery(
    ['mergeTags', contextType],
    async () => {
      return api.get(
        `/communications/contexts/${contextType}?include=merge_tags,recipient_groups&channel_type_id=${channelType}`
      );
    },
    { enabled: contextType !== undefined, select: ({ data }) => data }
  );

  // Transform the data into the shape we need
  React.useEffect(() => {
    (async () => {
      if (contextType && data) {
        const tags: MergeTagCategory = {
          id: contextType,
          label: contextType,
          items: data.merge_tags
        };
        setMergeTags([tags]);
        setRecipientGroups(data.recipient_groups);
      }
    })();
  }, [contextType, data]);

  return { mergeTags: remappedMergeTags, recipientGroups };
}
