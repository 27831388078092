import * as React from 'react';
import { StatusTagMap } from 'src/modules/common/components/status-tag';
import { InspectionStatus } from '../types/InspectionStatus';
import { TaskStatusSelect } from '../../common/components/task-status-select';

const taskStatusMap: StatusTagMap<InspectionStatus> = {
  not_started: 'neutral',
  notice_issued: 'average',
  inspection_completed: 'average',
  report_in_progress: 'average',
  report_completed: 'average',
  report_sent: 'good'
};

export function InspectionProgressSelect(props) {
  return (
    <TaskStatusSelect
      taskStatusMap={taskStatusMap}
      valueListId={'task_inspection_status'}
      {...props}
    />
  );
}
