import React, { ComponentType, ReactNode } from 'react';

import { useStyles, StyleSheet } from '@rexlabs/styling';
import { Heading } from '@rexlabs/text';
import { Dialog } from '@headlessui/react';
import { GhostIconButton } from '@rexlabs/button';
import Icons from '@rexlabs/icons';
import Box from '@rexlabs/box';

export type DrawerProps = {
  title: ReactNode;
  Icon?: ComponentType<any>;
  onClose?: () => void;
};

const styles = StyleSheet({
  header: {
    display: 'inline-grid',
    gridTemplateColumns: '40px 1fr 40px',
    gridTemplateAreas: '"icon heading close"',
    width: '100%',
    alignItems: 'center',
    padding: 24,
    gap: 12
  },

  headerIcon: {
    background: 'rgb(246, 246, 246)',
    borderRadius: ({ token }) => token('border.radius.m'),
    height: '40px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },

  closeIcon: {
    gridArea: 'close'
  }
});

export const Drawer: React.FC<DrawerProps> = ({
  title,
  onClose,
  children,
  Icon = null
}) => {
  const s = useStyles(styles);

  return (
    <Box flexDirection={'column'} height={'100%'}>
      <header {...s('header')}>
        <div {...s('headerIcon')}>{Icon ? <Icon /> : null}</div>

        <Dialog.Title as={Heading} level={2}>
          {title}
        </Dialog.Title>

        <GhostIconButton
          onClick={onClose}
          Icon={Icons.CrossLarge}
          {...s('closeIcon')}
        />
      </header>
      <Box flex={1} flexDirection={'column'} margin={12}>
        {children}
      </Box>
    </Box>
  );
};
