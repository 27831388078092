import * as React from 'react';
import { query } from '@rexlabs/model-generator';
import { RecordTable, TabbedTable } from 'view/components/table';
import { SmsActivityEmpty } from 'src/modules/settings/subscriptions/components/sms-activity-empty';
import { useTableFilters } from 'view/hooks/use-table-filters';
import {
  smsActivityColumns,
  smsTopUpColumns
} from '../utils/sms-activity-columns';
import { accountCreditLogsModel } from '../model/account-credit-logs-model';

const getSMSEventsQuery = () => query`{
  ${accountCreditLogsModel} {
    id
    recipient_contact
    created_by
    channel_message_recipient {
      channel_message {
        message {
          channels
        }
      }
    }
 }
}`;

export function SMSActivityTable() {
  const { getSort } = useTableFilters('account-credit-logs');

  const tabs = React.useMemo(() => {
    const commonProps = {
      id: 'activity',
      Table: RecordTable,
      columns: smsActivityColumns,
      getQuery: getSMSEventsQuery,
      Empty: SmsActivityEmpty,
      getSort,
      initialSortBy: [{ id: 'created_at', desc: true }]
    };

    return [
      {
        ...commonProps,
        name: 'all',
        label: 'All'
      },
      {
        ...commonProps,
        name: 'sms-sent',
        label: 'SMS sent',
        forcedGlobalFilter: [
          {
            field: 'credit_event_type_id',
            op: 'eq',
            value: { value: 'sms_outgoing', label: 'Outgoing' }
          }
        ]
      },
      {
        ...commonProps,
        name: 'top-ups',
        label: 'Top-ups',
        columns: smsTopUpColumns,
        getActionMenuItems: undefined,
        forcedGlobalFilter: [
          {
            field: 'credit_event_type_id',
            op: 'eq',
            value: { value: 'top_up_manual', label: 'Top-up (manual)' }
          }
        ]
      }
    ];
  }, []);

  return <TabbedTable tabs={tabs} />;
}
