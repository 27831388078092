export const PROPERTIES_ROUTES = {
  PROPERTIES_LIST: {
    config: {
      path: '/properties',
      Component: require('./screens/properties-list').PropertiesList
    },

    CREATE: {
      config: {
        path: '/properties/create',
        Component: require('./dialogs/create-property-dialog')
          .CreatePropertyDialog
      }
    }
  },

  PROPERTY: {
    config: {
      path: '/property/:propertyId',
      Component: require('./screens/property-details').PropertyDetailsScreen
    }
  }
};
