import { Message } from '../types/Message';
import { getRecipientText } from '../utils/get-recipient-text';

/**
 * Converts a message to an sms preview props object.
 */
export function mapMessageToSMSPreviewProps(message?: Message) {
  const smsChannel = message?.channels.find(
    (channel) => channel.channel_type.id === 'sms'
  );

  return {
    bodyContent: smsChannel?.resolved_text_body ?? '',
    recipients: getRecipientText(message?.recipients?.items ?? [])
  } as const;
}
