import { InspectionTask } from '../types/InspectionTask';

export function hasRooms(inspectionTask: InspectionTask) {
  return inspectionTask?.details?.rooms?.data.length;
}

export function hasAreas(inspectionTask: InspectionTask) {
  return inspectionTask?.details?.areas?.data.length;
}

export function hasReportBeenStarted(inspectionTask: InspectionTask) {
  return inspectionTask?.status?.id !== 'not_started';
}

export function isReportInProgressOrLater(inspectionTask: InspectionTask) {
  return ['report_in_progress', 'report_completed', 'report_sent'].includes(
    inspectionTask?.details?.status?.id || ''
  );
}

export function hasReportBeenCompleted(inspectionTask: InspectionTask) {
  return (
    inspectionTask?.status?.id === 'completed' ||
    ['report_completed', 'report_sent'].includes(
      inspectionTask?.details?.status.id || ''
    )
  );
}

export function showEntryExitInspectionReportEmptyState(
  inspectionTask: InspectionTask
) {
  return !['report_in_progress', 'report_completed', 'report_sent'].includes(
    inspectionTask?.details?.status?.id || ''
  );
}
