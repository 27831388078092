import React, { ReactElement } from 'react';
import Box from '@rexlabs/box';
import { Tag } from '@rexlabs/tag';
import { useToken } from '@rexlabs/styling';

interface DialogTitleWithTagProps {
  title: string;
  tag?: string | ReactElement | null;
}

export function DialogTitleWithTag({ title, tag }: DialogTitleWithTagProps) {
  const token = useToken();
  return (
    <Box flexDirection='row' alignItems='center'>
      <span>{title}</span>
      {tag && (
        <Box ml={token('spacing.s')}>
          <Tag>{tag}</Tag>
        </Box>
      )}
    </Box>
  );
}
