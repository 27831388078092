import React, { forwardRef } from 'react';
import { noop } from 'lodash';
import { StyleSheet, useStyles } from '@rexlabs/styling';
import Box, { BoxProps } from '@rexlabs/box';

import { useTrait } from 'view/components/@luna/feature-flags';

import { useIsDeveloper } from '../feature-flags/developer';

const WIP_TRAIT_NAME = 'show_work_in_progress';
const WIP_INDICATOR_TRAIT_NAME = 'show_work_in_progress_indicators';

interface WorkInProgressProps {
  chId?: string;
}

export function useWorkInProgress() {
  const isDeveloper = useIsDeveloper();
  const [state, set] = useTrait<boolean>(WIP_TRAIT_NAME);
  if (isDeveloper) return [state, set] as const;

  return [false, noop] as const;
}

const defaultStyles = StyleSheet({
  container: {
    position: 'relative'
  },

  indicator: {
    '&:after': {
      content: '" "',
      position: 'absolute',
      top: '-.3rem',
      left: '-.3rem',
      right: '-.3rem',
      bottom: '-.3rem',
      background: ({ token }) => token('palette.orange.500'),
      opacity: 0.1,
      pointerEvents: 'none'
    }
  }
});

export const WorkInProgress = forwardRef<
  any,
  WorkInProgressProps & Omit<BoxProps, 'ref'>
>(function WorkInProgress(
  { chId, children, style, className, ...props },
  forwardedRef
) {
  const [show] = useWorkInProgress();
  const [showIndicators] = useTrait(WIP_INDICATOR_TRAIT_NAME);

  const s = useStyles(defaultStyles);

  if (!show) {
    return null;
  }

  return (
    <Box
      {...props}
      {...s.with('container', { indicator: !!showIndicators })({
        style,
        className
      })}
      ref={forwardedRef}
    >
      {children}
      {/* TODO: implement CH ID indicator + quick preview */}
      {!!showIndicators && !!chId && <span />}
    </Box>
  );
});
