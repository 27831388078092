import { CommunicationContextType } from '../../common/types/communication-context-type';
import { CommunicationContext } from '../types/communication-context';
import { CreateMessageFormData } from './types/create-message-form-data';
import { CreateMessageRequest } from './types/create-message-request';

/**
 * Map the simple form data to the more complex API request structure.
 * @param formData The form values coming from the blocks
 * @returns The shape of data expected by the API
 */
export async function mapEditMessageFormToRequest(
  formData: Partial<CreateMessageFormData>,
  channelType: 'sms' | 'email' | 'letter'
): Promise<Partial<CreateMessageRequest>> {
  const common = createCommonData(formData);

  if (channelType === 'sms') {
    return {
      ...common,
      ...(formData.smsContent && {
        channels: [
          {
            channel_type: {
              id: 'sms'
            },
            text_body: formData.smsContent
          }
        ]
      })
    };
  }

  const inline_attachments = await mapAttachmentsToFiles(formData);

  return {
    ...common,
    ...(formData.cc_recipients && {
      cc_recipients: formData.cc_recipients?.map(
        (recipient) => recipient.record.id
      )
    }),
    ...(formData.bcc_recipients && {
      bcc_recipients: formData.bcc_recipients?.map(
        (recipient) => recipient.record.id
      )
    }),
    ...(formData.attachments && { inline_attachments: inline_attachments }),
    ...(formData.content && {
      channels: [
        {
          channel_type: {
            id: channelType
          },
          text_body: formData.content,
          // only include the email subject if we are dealing with an email
          ...(channelType === 'email'
            ? { email_subject: formData.subject }
            : {}),
          ...(channelType === 'letter'
            ? { letter_address: formData.letterAddress }
            : {})
        }
      ]
    })
  };
}

const createCommonData = (formData: Partial<CreateMessageFormData>) => {
  // This makes it so we can handle single or multiselect recipients
  const formRecipients = Array.isArray(formData.recipients)
    ? formData.recipients
    : [formData.recipients];
  return {
    ...(formData.relates_to && {
      context: formData.relates_to as CommunicationContext
    }),
    ...(formData.relates_to?.type.id && {
      context_type: {
        // TODO: we need to handle specific types here
        id: formData.relates_to?.type.id as CommunicationContextType
      }
    }),
    ...(formData.sent_from?.id
      ? { send_from_author: { id: formData.sent_from!.id } }
      : {}),
    ...(formData.recipients && {
      selected_recipients: formRecipients?.map(
        (recipient) => recipient.record.id
      )
    }),
    ...(formData.template?.id
      ? { template: { id: formData.template?.id } }
      : {}),
    linked_files: formData.linked_files || []
  };
};

async function mapAttachmentsToFiles(formData: Partial<CreateMessageFormData>) {
  return await Promise.all(
    (formData.attachments ?? []).map(async (attachment) => {
      // If the file has been uploaded previously, it will already
      // have an id and we can return it directly
      if (attachment.file?.id) {
        return {
          file: {
            id: attachment.file.id
          }
        };
      }

      // Otherwise fetch the id from the attachments data
      const {
        data: { id }
      } = await attachment.data!;
      return {
        file: {
          id
        }
      };
    })
  );
}
