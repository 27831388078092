import { useCallback } from 'react';

import { useErrorDialog } from '@rexlabs/dialog';
import { useModelActions } from '@rexlabs/model-generator';

import { useToast } from 'view/components/@luna/notifications/toast';
import { RecordSubmitHandler } from 'view/components/record-screen/utils';

import { useQueryClient } from 'react-query';
import { bankStatementTransactionsModel } from '../models/bank-statement-transaction-model';
import { UploadBankSubTransactionsFormValues } from '../types';
import { bankStatementTransactionsQueryKey } from './use-bank-statement-transactions-query';

export const useImportSubBankTransactions = (
  bankStatementTransactionId: string
) => {
  const { importSubBankTransactions } = useModelActions(
    bankStatementTransactionsModel
  );
  const queryClient = useQueryClient();

  const { open: openErrorDialog } = useErrorDialog();

  const { addToast } = useToast();

  const onHandleSubmit: RecordSubmitHandler<UploadBankSubTransactionsFormValues> = useCallback(
    async ({ values }) => {
      const { sub_bank_transactions_file } = values;
      if (!sub_bank_transactions_file?.data) return;

      const { data: bankTransaction } = await Promise.resolve(
        sub_bank_transactions_file?.data
      );

      try {
        const importedData = await importSubBankTransactions({
          fileId: bankTransaction.id,
          bankStatementTransactionId
        });

        await queryClient.refetchQueries({
          queryKey: [bankStatementTransactionsQueryKey],
          refetchPage: () => true
        });

        addToast({
          description: `Bank statement file ${sub_bank_transactions_file.file.name} is being processed`
        });

        return importedData;
      } catch (error) {
        console.error(error);
        openErrorDialog(error);
      }
    },
    [bankStatementTransactionId]
  );

  return { onHandleSubmit };
};
