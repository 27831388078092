import React from 'react';
import { Tenancy } from 'data/models/entities/tenancies';
import { RecordFlag } from 'view/components/record-flags/record-flag';
import { EmptyTenancy } from '../empty/empty-tenancy';
import { EntryList } from '../components/entry-list';
import { EntryLink } from '../components/entry-link';

export interface TenanciesSectionProps {
  tenancies: Tenancy[] | 'empty';
  maxListLength: number;
}

export function TenanciesSection({ tenancies }: TenanciesSectionProps) {
  if (tenancies === 'empty' || tenancies.length === 0) {
    return <EmptyTenancy />;
  }

  return (
    <EntryList>
      {tenancies.map((tenancy) => (
        <EntryLink key={tenancy.id} type='tenancy' id={tenancy.id}>
          <RecordFlag record={tenancy} recordType='tenancy' />
        </EntryLink>
      ))}
    </EntryList>
  );
}
