import { Ownership } from 'data/models/entities/ownerships';
import { Tenancy } from 'data/models/entities/tenancies';
import { Contact } from 'src/modules/contacts/types/contact-types';
import { useMessageTriggerSettings } from 'src/modules/communications/messages/settings/message-triggers/hooks/use-message-trigger-settings';
import { pluralize } from 'utils/formatters';

/**
 * This hook is used to determine the label for the "Send invoice to" checkbox, which at this stage,
 * is found in the invoice details form and the reimbursement details form.
 */
export function useSendInvoiceCheckboxLabel(
  record?: Tenancy | Ownership | Contact,
  isReimbursement?: boolean
) {
  const { messageTriggerSettings } = useMessageTriggerSettings();

  switch (record?.__record_type) {
    case 'tenancy':
      return messageTriggerSettings['tenancy-invoice-template'].template_id
        ? `Send ${
            isReimbursement ? 'reimbursement' : ''
          } invoice to ${pluralize(
            'tenant',
            record.display_name.includes('&') ? 2 : 1
          )}`
        : null;
    case 'ownership':
      return messageTriggerSettings['ownership-invoice-template'].template_id
        ? `Send ${
            isReimbursement ? 'reimbursement' : ''
          } invoice to ${pluralize(
            'owner',
            record.display_name.includes('&') ? 2 : 1
          )}`
        : null;
    default:
      return null;
  }
}
