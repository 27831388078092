import { useDialog } from '@rexlabs/dialog';
import { Invoice } from 'data/models/entities/financials/invoices';
import { ActionDeclaration } from 'src/modules/common/actions/types/action-declaration-types';
import { FinancialEntity } from 'src/modules/financials/utils/financial-entity-action-group/use-get-financial-entity-actions';
import { ReceiptFundsDialog } from 'src/modules/trust-journal-entries/dialogs/receipt-funds-dialog';
import { tryGetReceiptFromForPayableBy } from 'src/modules/invoices/utils/try-get-receipt-from-for-payable-by';

export function useGetReceiptAction() {
  const { open } = useDialog(ReceiptFundsDialog);
  return (
    invoice: Invoice,
    afterAction?: (args: any) => any
  ): ActionDeclaration => ({
    id: 'receipt',
    group: 'payment',
    label: 'Pay via receipt',
    handleAction: () => {
      const payableBy = (invoice.payable_by_tenancy ??
        invoice.payable_by_ownership ??
        invoice.payable_by_contact) as FinancialEntity;

      open({
        folio: payableBy,
        receiptFrom: tryGetReceiptFromForPayableBy(payableBy),
        invoiceId: invoice.id,
        onCreate: afterAction,
        allowUpdateTenantReference: false
      });
    }
  });
}
