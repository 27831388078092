import React from 'react';
import { useStyles, StyleSheet } from '@rexlabs/styling';
import { Semibold } from '@rexlabs/text';
import { lowerCase } from 'lodash';
import {
  TitleBlock,
  TitleBlockProps
} from 'view/components/cards/title/title-block';
import { RecordObject, RecordTypes } from 'data/models/types';
import { getRecordTitle } from 'utils/records/get-record-title';
import { formatDate } from 'utils/dates/format';
import { propertiesModel } from 'data/models/entities/properties';
import { useGetPrimaryRecordActionGroup } from 'view/hooks/actions/use-get-primary-record-action-group';
import { useGetAddDocumentsAction } from 'src/modules/documents/actions/use-get-add-documents-action';
import { useGetAddNotesAction } from 'src/modules/tasks/common/actions/get-add-notes-action';
import { useGetCreateEmailAction } from 'src/modules/communications/messages/hooks/action-declarations/use-get-create-email-action';
import { useGetToggleClosedAction } from 'src/modules/tasks/common/hooks/action-declarations/use-get-toggle-closed-action';
import { useTaskStatusTag } from 'src/modules/tasks/common/hooks/use-task-status-tag';
import MoveInIcon from 'view/components/icons/move-in';
import { TagConfig } from 'view/components/@luna/title-block';
import { Trans, useTranslation } from 'react-i18next';
import { useGetCreateSMSAction } from 'src/modules/communications/messages/hooks/action-declarations/use-get-create-sms-action';

import { MoveIn } from '../types/MoveIn';

const styles = StyleSheet({
  subText: {
    color: ({ token }) => token('color.textStyle.body.subtext')
  },
  body: {
    color: ({ token }) => token('color.textStyle.body.default')
  },
  closedAt: {
    color: ({ token }) => token('color.textStyle.body.default'),
    fontWeight: ({ token }) => token('typography.weight.semibold')
  }
});

const type = RecordTypes.TaskMoveIn;

type MoveInRecordObject = Extract<RecordObject, { type: 'task_move_in' }>;

type MoveInTitleBlockProps = {
  moveIn: MoveIn;
};

export function MoveInTitleBlock({ moveIn }: MoveInTitleBlockProps) {
  const { t } = useTranslation();
  const s = useStyles(styles);
  const getPrimaryRecordActionGroup = useGetPrimaryRecordActionGroup();
  const getToggleClosedAction = useGetToggleClosedAction();
  const getCreateEmailAction = useGetCreateEmailAction(propertiesModel);
  const getCreateSMSAction = useGetCreateSMSAction(propertiesModel);
  const getAddDocumentsAction = useGetAddDocumentsAction();
  const getAddNotesAction = useGetAddNotesAction();

  const title = getRecordTitle({ type, object: moveIn } as MoveInRecordObject);
  const actions = [
    ...getPrimaryRecordActionGroup('task_move_in', moveIn.id),
    {
      label: 'Actions',
      actions: [
        getCreateEmailAction(moveIn!.property),
        getCreateSMSAction(moveIn!.property),
        getAddDocumentsAction({ data: moveIn! }),
        getAddNotesAction({ data: moveIn! }),
        getToggleClosedAction(moveIn!)
      ]
    }
  ];

  const statusTag: TagConfig = useTaskStatusTag(moveIn);
  const tags: TagConfig[] = [statusTag];

  if (!moveIn) {
    return null;
  }

  const subTitles: TitleBlockProps['subTitles'] = moveIn?.details?.move_in_date
    ? [
        {
          Label: () => (
            <div {...s('subText')}>
              moving in to{' '}
              <Semibold {...s('body')}>
                {moveIn?.property?.address_string}
              </Semibold>{' '}
              on {formatDate(moveIn.details!.move_in_date!)}
            </div>
          )
        }
      ]
    : [];

  const closed_at = moveIn.closed_at;

  return (
    <TitleBlock
      title={title}
      subTitles={subTitles}
      type={t('tasks.move-in.singular') as string}
      Icon={() => <MoveInIcon size='xl' />}
      actions={actions}
      tags={closed_at ? [] : tags}
      subText={
        closed_at ? (
          <div>
            <Trans i18nKey={'tasks.move-in.closed-with-status'}>
              This move in task has been closed with the status
            </Trans>
            <span {...s('closedAt')}> {lowerCase(moveIn.status?.label)}</span>{' '}
            on <span {...s('closedAt')}>{formatDate(closed_at)}</span>
          </div>
        ) : undefined
      }
    />
  );
}
