import { ContentConfig } from 'view/components/record-screen/types';
import { overviewBlock } from '../blocks/overview';
import { detailsBlock } from '../blocks/details';
import { bankAccountBlock } from '../blocks/bank-account';
import { trustLedgersBlock } from '../blocks/trust-ledgers';
import { bankDepositsBlock } from '../blocks/bank-deposits';

export const content: ContentConfig = [
  {
    label: 'General',
    items: [
      {
        id: 'overview',
        label: 'Overview',
        blocks: [overviewBlock, bankAccountBlock]
      },
      {
        id: 'details',
        label: 'Details',
        blocks: [detailsBlock]
      },
      {
        id: 'trust-ledgers',
        label: 'Ledgers',
        blocks: [trustLedgersBlock]
      },
      {
        id: 'bank-deposits',
        label: 'Bank deposits',
        blocks: [bankDepositsBlock]
      }
    ]
  }
];
