import { EntityFee } from 'src/modules/agency-fees/dialogs/edit-entity-fee';
import { useGetEditPropertyOwnershipFeeAction } from 'src/modules/property-ownerships/hooks/use-get-edit-property-ownership-fee-action';
import { useGetRemovePropertyOwnershipFeeAction } from 'src/modules/property-ownerships/hooks/use-get-remove-property-ownership-fee-action';
import { PropertyOwnership } from 'src/modules/property-ownerships/types/property-ownership-types';
import { useGetCreateInvoiceFromEntityFee } from 'src/modules/entity-fees/actions/use-get-create-invoice-from-entity-fee';
import { Ownership } from 'data/models/entities/ownerships';
import { useGetEditOwnershipFee } from 'src/modules/ownerships/hooks/use-get-edit-ownership-fee-action';
import { useGetRemoveOwnershipFeeAction } from 'src/modules/ownerships/hooks/use-get-remove-ownership-fee-action';

export function useGetEntityFeeActions() {
  const getEditPropertyOwnershipFeeAction = useGetEditPropertyOwnershipFeeAction();
  const getRemovePropertyOwnershipFeeAction = useGetRemovePropertyOwnershipFeeAction();
  const getEditOwnershipFeeAction = useGetEditOwnershipFee();
  const getRemoveOwnershipFeeAction = useGetRemoveOwnershipFeeAction();
  const getCreateInvoiceAction = useGetCreateInvoiceFromEntityFee();

  return (entityFee: EntityFee, entity: PropertyOwnership | Ownership) => {
    const isPropertyOwnership = 'ownership' in entity;

    return [
      ...(isPropertyOwnership
        ? [
            getEditPropertyOwnershipFeeAction(
              entityFee,
              entity.id,
              entity.property_id
            ),
            getRemovePropertyOwnershipFeeAction(
              entityFee,
              entity.id,
              entity.property_id
            )
          ]
        : [
            getEditOwnershipFeeAction(entityFee, entity.id),
            getRemoveOwnershipFeeAction(entityFee, entity.id)
          ]),

      ...(entityFee.agency_fee?.trigger_type.id === 'manual'
        ? [getCreateInvoiceAction(entityFee, entity)]
        : [])
    ];
  };
}
