import { StatBlockIntent } from 'view/components/@luna/stat-block/stat-block';

export default function getOverdueInvoiceStatusColour(
  overdueInvoiceCount: number
): StatBlockIntent {
  if (overdueInvoiceCount === 0) {
    return 'success';
  }

  if (overdueInvoiceCount < 5) {
    return 'warning';
  }

  return 'danger';
}
