import React from 'react';
import { BlockConfig } from 'view/components/record-screen/types';
import { Grid, Column } from '@rexlabs/grid';
import { Value } from 'view/components/values';
import { Field } from '@rexlabs/form';
import { ChartOfAccountsAccountSelect } from 'view/components/inputs/selects/v4-selects/chart-of-accounts-account';

// TODO: wire up
export const supplierCommissionSettingsBlock: BlockConfig = {
  id: 'supplier-commission-settings',
  title: 'Supplier commission settings',
  validate: {
    definitions: {
      account_code: { rules: 'required' }
    }
  },
  View: ({ data }) => {
    return (
      <Grid>
        <Column width={4}>
          <Value
            label='Default account code for billing supplier commission'
            value={data?.account_code}
          />
        </Column>
      </Grid>
    );
  },
  Edit: () => {
    return (
      <Grid>
        <Column width={4}>
          <Field
            id='account_code'
            label='Default account code for billing supplier commission'
            name='account_code'
            Input={ChartOfAccountsAccountSelect}
            inputProps={{
              disableFixture: false,
              filterOnChartOfAccountCategories: ['income'],
              dialogProps: {
                disableAccountCategory: true,
                initialValues: {
                  account_category: {
                    id: 'income',
                    label: 'Income'
                  }
                }
              }
            }}
          />
        </Column>
      </Grid>
    );
  }
};
