import React from 'react';

import Box from '@rexlabs/box';
import { Grid } from 'view/components/@luna/form/grid';
import { Disclosure } from '@headlessui/react';
import EmailIcon from 'view/components/icons/email';
import { StyleSheet, useStyles, useToken } from '@rexlabs/styling';
import { Body, Heading } from '@rexlabs/text';
import { Tag } from '@rexlabs/tag';
import AttachmentIcon from 'view/components/icons/attachment';
import { FroalaReadOnly } from 'view/components/@luna/inputs/froala-editor/components/froala-read-only';
import { AlfredFile } from 'src/modules/common/types/file';
import { canPreviewDocument } from 'src/modules/documents/actions/use-get-view-documents-action';
import { useDialog } from '@rexlabs/dialog';
import { ViewDocumentDialog } from 'src/modules/documents/dialogs/view-document-dialog';
import { createAttachmentFileName } from '../../utils/create-attachment-file-name';
import { useResolveAttachments } from '../../hooks/use-resolve-attachments';

const defaultStyles = StyleSheet({
  icon: {
    width: '4rem',
    height: '4rem',
    borderRadius: ({ token }) => token('border.radius.m'),
    background: ({ token }) => token('color.container.static.contrast'),
    marginRight: ({ token }) => token('spacing.s')
  },

  flex1: {
    flex: 1
  },

  container: {
    border: '1px solid #e7e8e9',
    borderRadius: ({ token }) => token('border.radius.l')
  },

  contentSection: {
    borderTop: '1px solid #e7e8e9',
    padding: ({ token }) => token('spacing.xl'),
    whitespace: 'pre-line'
  },

  mergeTagSidebar: {
    borderRadius: ({ token }) => token('border.radius.l'),
    overflow: 'hidden'
  }
});

export interface EmailPreviewProps {
  heading?: string;

  subject: string;
  /**
   * A string representation of the recipient names. e.g. "John Doe & Jane Doe"
   */
  recipients: string;
  /**
   * A string representation of the BCC recipients
   */
  bcc?: string;
  /**
   * A string representation of the CC recipients
   */
  cc?: string;

  attachedFiles?: AlfredFile[] | Array<Promise<{ data: AlfredFile }>>;

  bodyContent: string;

  actionButtonSlot?: React.ReactNode;
}

export function EmailPreview({
  heading,
  subject,
  recipients,
  bcc,
  cc,
  attachedFiles,
  bodyContent,
  actionButtonSlot
}: EmailPreviewProps) {
  const s = useStyles(defaultStyles);
  const token = useToken();

  // TODO: derive this from the actual data
  const headerData = [
    { label: 'Recipients', value: recipients },
    {
      label: 'Subject',
      value: subject
    }
  ];

  const resolveAttachedFiles = useResolveAttachments(attachedFiles);

  return (
    <Box {...s('container')}>
      <Grid columns={1}>
        <Box display='flex' flexDirection='row' justifyContent='space-between'>
          <Box display='flex' flexDirection='row' padding={token('spacing.xl')}>
            <Box>
              <Box {...s('icon')} alignItems='center' justifyContent='center'>
                <EmailIcon size='l' />
              </Box>
            </Box>
            <Box {...s('flex1')}>
              {heading && (
                <Heading level={4} as='div'>
                  {heading}
                </Heading>
              )}
              {headerData.map(({ label, value }) => (
                <Box key={label}>
                  <Body grey as='span'>
                    {label}:{' '}
                  </Body>
                  <Body as='span'>{value}</Body>
                </Box>
              ))}

              <Box
                paddingTop={token('spacing.m')}
                paddingBottom={token('spacing.m')}
              >
                {(cc || bcc) && (
                  <Disclosure>
                    {({ open }) => (
                      <>
                        <Disclosure.Button as='a'>
                          <div>Show {open ? 'less' : 'more'}</div>
                        </Disclosure.Button>
                        <Disclosure.Panel>
                          {cc && (
                            <Box data-testId='cc-recipients'>
                              <Body grey as='span'>
                                cc:{' '}
                              </Body>
                              <Body as='span'>{cc}</Body>
                            </Box>
                          )}

                          {bcc && (
                            <Box data-testId='bcc-recipients'>
                              <Body grey as='span'>
                                bcc:{' '}
                              </Body>
                              <Body as='span'>{bcc}</Body>
                            </Box>
                          )}
                        </Disclosure.Panel>
                      </>
                    )}
                  </Disclosure>
                )}
              </Box>

              {attachedFiles && (
                <Box
                  data-testId='attached-files'
                  display='flex'
                  flexDirection='row'
                  gap={token('spacing.xs')}
                  style={{ borderTop: '1px solid #E7E8E9' }}
                  paddingTop={token('spacing.m')}
                  flexWrap='wrap'
                >
                  {resolveAttachedFiles.map((attachment, index) => (
                    <AttachmentTag
                      key={index}
                      type={attachment.type}
                      name={attachment.name}
                      size={attachment.size}
                      url={attachment.url}
                    ></AttachmentTag>
                  ))}
                </Box>
              )}
            </Box>
          </Box>
          <Box>{actionButtonSlot}</Box>
        </Box>

        <Box {...s('contentSection')}>
          <FroalaReadOnly value={bodyContent} />
        </Box>
      </Grid>
    </Box>
  );
}

function AttachmentTag({ type, name, size, url }) {
  const viewDocumentDialog = useDialog(ViewDocumentDialog);

  return (
    <Tag
      Icon={() => <AttachmentIcon size='s' />}
      {...(canPreviewDocument(type)
        ? {
            isInteractive: true,
            onClick() {
              viewDocumentDialog.open({
                fileName: name,
                fileUrl: url,
                fileType: type
              });
            }
          }
        : {})}
    >
      <span>
        {createAttachmentFileName({
          fileName: name,
          fileSize: size
        })}
      </span>
    </Tag>
  );
}
