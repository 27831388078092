import * as React from 'react';
import { SelectProps } from '@rexlabs/select';
import { AgencyFee } from 'src/modules/agency-fees/types/agency-fee-types';
import { AllRecordItemsSelect } from 'view/components/inputs/selects/v4-selects/all-record-items-select';
import { useTranslation } from 'react-i18next';

export function ManagementAgencyFeeSelect(props: SelectProps<AgencyFee>) {
  const { t } = useTranslation();

  return (
    <AllRecordItemsSelect<AgencyFee>
      queryFilters={[
        {
          field: 'entity_type_id',
          op: 'eq',
          value: 'property_ownership'
        },
        {
          field: 'status_id',
          op: 'neq',
          value: 'archived'
        }
      ]}
      endpoint='financials/agency-fees'
      helperText={t(
        'property-ownerships.management-agreement.service-package.select-message'
      )}
      {...props}
    />
  );
}
