import { PendingOwnershipDisbursement } from 'data/models/entities/financials/pending-disbursements/pending-ownership-disbursement';
import React, { useMemo } from 'react';
import { Message } from 'view/components/@luna/message';
import { BlockConfig } from 'view/components/record-screen/types';
import { Columns, RecordTable } from 'view/components/table';
import { EmptyCard } from 'view/components/record-screen/cards/empty-card';
import { Trans, useTranslation } from 'react-i18next';
import { useSessionState } from 'src/modules/common/hooks/session-state';
import { FLAGS } from 'utils/feature-flags';
import { useFeatureFlags } from 'view/components/@luna/feature-flags';
import { useBaseDisbursementMethodsColumns } from '../hooks/use-base-disbursement-methods-columns';
import EmptyTrustJournalEntriesTable from '../../../assets/illustrations/empty-trust-journal-entries-table.svg';
import { DisbursementPaymentMethodsEdit } from '../components/disbursement-payment-methods-edit';
import { ownershipDisbursementPaymentMethodsBlock } from './ownership-disbursement-payment-methods';

export const ownershipDisbursementBlock: BlockConfig<PendingOwnershipDisbursement> = {
  id: 'ownershipDisbursement',
  title: () => <Trans i18nKey='disbursements.ownerships.method.label' />,
  validate: ownershipDisbursementPaymentMethodsBlock.validate!,
  View: ({ data }) => {
    const { activeSilo } = useSessionState();
    const { hasFeature } = useFeatureFlags();

    const disbursementMethodsColumns = useBaseDisbursementMethodsColumns();

    const columns = useMemo(
      () => [
        ...disbursementMethodsColumns,
        ...(activeSilo?.country?.id === 'GBR' &&
        hasFeature(FLAGS.WITHHOLDING_TAX)
          ? ([
              {
                id: 'contact.tax.non_resident_withhold_tax',
                Header: 'Withholding HMRC tax',
                type: 'boolean'
              }
            ] as Columns)
          : [])
      ],
      [activeSilo?.country?.id, disbursementMethodsColumns, hasFeature]
    );
    return (
      <RecordTable
        columns={columns}
        items={data?.object?.disbursement_payment_methods}
        isLoading={false}
      />
    );
  },
  Edit: ({ values, data }) =>
    DisbursementPaymentMethodsEdit({
      values,
      data
    }),
  showEmpty: (data) => !data.object.disbursement_payment_methods?.length,
  Empty: ({ onEditClick }) => {
    const { t } = useTranslation();
    return (
      <EmptyCard>
        <Message
          title={t('disbursements.ownerships.set-up')}
          Illustration={EmptyTrustJournalEntriesTable}
          actions={[
            {
              label: t('disbursements.add-method') as string,
              intent: 'primary',
              handleAction: onEditClick
            }
          ]}
        >
          {t('disbursements.ownerships.description')}
        </Message>
      </EmptyCard>
    );
  }
};
