import React from 'react';

import { BlockConfig } from 'view/components/record-screen/types';

import { emailsBlock } from 'src/modules/contacts/blocks/emails';
import { addressesBlock } from 'src/modules/contacts/blocks/addresses';
import { phoneNumbersBlock } from 'src/modules/contacts/blocks/phone-numbers';

import { MessageIssue } from '../../common/types/message-issue';
import { ValidationType } from '../models/messages-model';
import { MessageIssueType } from '../../common/types/message-issue-type';
import { DocumentMessageIssueDisclosureField } from '../components/document-message-issue-disclosure-field';
import { ComplianceEntryMessageIssueDisclosureField } from '../components/compliance-entry-message-issue-disclosure-field';
import { ContactMessageIssueDisclosureField } from '../components/contact-message-issue-disclosure-field';

export interface FixMessageIssuesBlockProps {
  messageIssues: MessageIssue[];
}

const issueToTypeMap: Record<ValidationType, MessageIssueType[]> = {
  phone: ['invalid_phone_number', 'missing_phone_number'],
  email: ['invalid_email', 'missing_email'],
  address: ['invalid_address', 'missing_address'],
  document: ['missing_document'],
  compliance_entry: ['missing_compliance_entry']
};

export const fixMessageIssuesBlock: BlockConfig<FixMessageIssuesBlockProps> = {
  id: 'fix-message-issues',
  validate: {
    definitions: {
      'issues.*': { rules: 'required' }
    },
    messages: {
      required: 'This record has issues that need fixing'
    }
  },
  Edit: ({ data, setFieldValue }) => {
    return (
      <>
        {(data?.messageIssues || []).map((messageRecipientIssue, index) => {
          const key = `issues.${index}`;

          const issueId = messageRecipientIssue.issue.id;

          const issueType = Object.keys(issueToTypeMap).find((type) =>
            issueToTypeMap[type].includes(issueId)
          ) as ValidationType;

          const sharedProps = {
            fieldKey: key,
            issue: messageRecipientIssue,
            setFieldValue: setFieldValue
          };

          switch (issueType) {
            case 'document':
              return <DocumentMessageIssueDisclosureField {...sharedProps} />;
            case 'compliance_entry':
              return (
                <ComplianceEntryMessageIssueDisclosureField {...sharedProps} />
              );
            case 'phone':
              return (
                <ContactMessageIssueDisclosureField
                  block={phoneNumbersBlock}
                  updateText="Updating the phone number here will also update the contact's record"
                  {...sharedProps}
                />
              );
            case 'email':
              return (
                <ContactMessageIssueDisclosureField
                  block={emailsBlock}
                  updateText="Updating the email here will also update the contact's record"
                  {...sharedProps}
                />
              );
            case 'address':
              return (
                <ContactMessageIssueDisclosureField
                  block={addressesBlock}
                  updateText="Updating the address here will also update the contact's record"
                  {...sharedProps}
                />
              );
            default:
              return null;
          }
        })}
      </>
    );
  }
};
