import Box from '@rexlabs/box';
import { Field } from '@rexlabs/form';
import { TextArea } from '@rexlabs/text-input';
import React from 'react';
import { Column, Grid } from 'view/components/@luna/form/grid';
import { DateInput } from 'view/components/@luna/inputs/date-input/date-input';
import { BlockConfig } from 'view/components/record-screen/types';

import { Checkbox } from '@rexlabs/checkbox';
import { RecordTypes } from 'data/models/types';
import { CentAmountInput } from 'view/components/inputs/cent-amount';
import { TaxTypeSelect } from 'view/components/inputs/selects/tax-type';
import { ChartOfAccountsAccountSelect } from 'view/components/inputs/selects/v4-selects/chart-of-accounts-account';
import { Value } from 'view/components/values';
import { AccountingJournalEntryPropertySelect } from '../components/accounting-journal-entry-property-select';

export const createManualAccountingJournalEntryBlock: BlockConfig = {
  id: 'create-manual-accounting-journal-entry',
  validate: {
    definitions: {
      date_of: {
        rules: 'required',
        name: 'date'
      },
      description: {
        rules: 'required'
      },
      account: {
        rules: 'required'
      },
      tax_type: {
        rules: 'required',
        name: 'tax type'
      },
      amount: {
        rules: 'required'
      }
    }
  },
  Edit: ({ values, setFieldValue }) => (
    <Box>
      <Grid columns={2}>
        <Value label='Folio' value={values.folio.display_name} />
        <Field label='Date' name='date_of' Input={DateInput} />
        {values.folio.__record_type === RecordTypes.Ownership && (
          <Field
            label='Property'
            name='property'
            Input={AccountingJournalEntryPropertySelect}
            inputProps={{
              folioId: values.folio.id
            }}
          />
        )}
        <Column width={2}>
          <Field label='Description' name='description' Input={TextArea} />
        </Column>
        <Field
          id={'chart-of-accounts-account'}
          label='Account'
          name='account'
          Input={ChartOfAccountsAccountSelect}
          inputProps={{
            filterOnChartOfAccountCategories: ['income', 'expense'],
            disableFixture: false
          }}
          onChange={(e: any) =>
            setFieldValue?.('tax_type', e.target.value.default_tax_type)
          }
        />
        <div />
        <Field label='Tax type' name='tax_type' Input={TaxTypeSelect} />
        <Field label='Amount' name='amount' Input={CentAmountInput} />
        <Field
          name='is_tax_included'
          Input={Checkbox}
          optional={false}
          inputProps={{
            label: 'Tax is included in the amount'
          }}
        />
      </Grid>
    </Box>
  )
};
