import React, { useCallback } from 'react';
import { useDialog } from '@rexlabs/dialog';

import { ComplianceType } from 'src/modules/compliance/common/models/compliance-types';

import { RecordTable } from 'view/components/table';
import { complianceRequirementSettingsColumns } from 'src/modules/compliance/common/utils/compliance-requirement-settings-columns';
import { getComplianceTypesQuery } from '../../common/compliance-entries/data/queries';
import { EditSupplierComplianceRequirementDialog } from '../dialogs/edit-supplier-compliance-requirement-dialog';
import { SupplierComplianceRequirementTableEmptyState } from './supplier-compliance-requirement-table-empty-state';

const filter = [
  {
    field: 'applicable_to_object_type_id',
    op: '==',
    value: 'supplier'
  }
];

export function SupplierComplianceRequirementsSettingsTable() {
  const { open: openEditComplianceRequirementDialog } = useDialog(
    EditSupplierComplianceRequirementDialog
  );

  const getQuery = useCallback(() => getComplianceTypesQuery(filter), []);

  return (
    <RecordTable
      id='supplier-compliance-requirements'
      columns={complianceRequirementSettingsColumns}
      getQuery={getQuery}
      Empty={SupplierComplianceRequirementTableEmptyState}
      emptyWithoutTable
      withPagination={true}
      getActionMenuItems={({
        item
      }: {
        item: ComplianceType;
        entity?: any;
      }) => {
        return [
          {
            label: 'Edit',
            onClick: () =>
              openEditComplianceRequirementDialog({
                complianceRequirementId: item.id
              })
          }
        ];
      }}
    />
  );
}
