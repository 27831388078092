import { ContentConfig } from 'view/components/record-screen/types';

import { messageTriggersWarning } from '../blocks/message-triggers-warning-block';
import { blockIds } from '../types/MessageTriggerSettingsBlockId';
import { messageTriggerSettingsBlockFactory } from '../factories/message-trigger-settings-block-factory';

const blocks = blockIds.map((blockId) => {
  return messageTriggerSettingsBlockFactory(blockId);
});

export const content: ContentConfig = [
  {
    label: 'Message trigger settings',
    items: [
      {
        id: 'overview',
        label: 'Overview',

        blocks: [messageTriggersWarning, ...blocks]
      }
    ]
  }
];
