import { query, fragment } from '@rexlabs/model-generator';
import { toQuri } from '@rexlabs/quri';
import { TagCell } from '@rexlabs/table';
import React, { useMemo } from 'react';
import { StatusTag } from 'src/modules/common/components/status-tag';
import { useStatementActions } from 'src/modules/ownership-statements/common/actions/use-statement-actions';
import { statementStatusMap } from 'src/modules/ownership-statements/common/maps/statementStatusMap';
import { statementsModel } from 'src/modules/statements/models/statements';
import {
  Statement,
  StatementStatus,
  StatementType
} from 'src/modules/statements/types';
import { transformActionDeclarationsToActionMenuItems } from 'utils/actions/transforms';
import { Columns, ListTable } from 'view/components/table';
import { useTableFilters } from 'view/hooks/use-table-filters';
import { RecordTypes } from 'data/models/types';

export const getFilterByRecordId = (recordId: string) => {
  return toQuri([
    {
      field: 'object_id',
      op: 'eq',
      value: recordId
    }
  ]);
};

const getStatementsQuery = (
  recordId: string,
  recordType: typeof RecordTypes.Contact | typeof RecordTypes.Ownership
) => {
  const ownershipIncludesFragment = fragment`
    ownership {
      owners
    }
  `;

  const agencyContactIncludesFragment = fragment`
    contact
  `;

  return query`{
  ${statementsModel} (q: ${getFilterByRecordId(recordId)}){
    id
    ${
      recordType === RecordTypes.Ownership
        ? ownershipIncludesFragment
        : agencyContactIncludesFragment
    }
    recipients
  }
}`;
};

const issuedStatementColumns: Columns<Statement> = [
  {
    Header: 'Type',
    width: 160,
    accessor: (item) =>
      item.type.id === StatementType.PeriodicOwnership ||
      item.type.id === StatementType.PeriodicAgency
        ? 'Periodic'
        : 'Financial year'
  },
  {
    Header: 'Statement period',
    type: 'date_range',
    accessor: (item) => {
      return { from: item.statement_from, to: item.statement_to };
    }
  },
  {
    accessor: (item) => item.status,
    Header: 'Status',
    Cell: (table) => {
      const {
        cell: { value }
      } = table;
      return (
        <TagCell>
          <StatusTag status={value} map={statementStatusMap} />
        </TagCell>
      );
    },
    toCsv: (item) => item.label,
    width: 100
  }
];

export function StatementsTable({
  recordId,
  recordType
}: {
  recordId: string;
  recordType: typeof RecordTypes.Contact | typeof RecordTypes.Ownership;
}) {
  const statementsQuery = useMemo(
    () => getStatementsQuery(recordId, recordType),
    [recordId, recordType]
  );

  const { getSort, getFilters } = useTableFilters('statements');
  const actions = useStatementActions();

  return (
    <ListTable
      id='statements'
      columns={issuedStatementColumns}
      getQuery={() => statementsQuery}
      suggestedFilters={['statement_period', 'statement_from', 'statement_to']}
      getSort={getSort}
      getFilters={getFilters}
      initialGlobalFilter={[
        {
          field: 'status_id',
          op: 'eq',
          value: StatementStatus.Issued
        }
      ]}
      getActionMenuItems={({ item }) =>
        transformActionDeclarationsToActionMenuItems(actions(item))
      }
    />
  );
}
