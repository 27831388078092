import React from 'react';
import { BlockConfig } from 'view/components/record-screen/types';
import { FormLayout } from 'view/components/layouts/form/form-layout';
import { Field } from '@rexlabs/form';
import { TextArea } from '@rexlabs/text-input';
import { DateInput } from 'view/components/@luna/inputs/date-input/date-input';
import { DoubleWidth } from 'view/components/layouts/form/column';

export const resolveAdjustmentBlock: BlockConfig = {
  id: 'resolve-adjustment',
  validate: {
    definitions: {
      date_of: { rules: 'required' },
      reason: { rules: 'required' }
    }
  },
  Edit: () => {
    return (
      <FormLayout>
        <Field name='date_of' label='Resolution date' Input={DateInput} />
        <DoubleWidth>
          <Field name='reason' label='Reason' Input={TextArea} />
        </DoubleWidth>
      </FormLayout>
    );
  }
};
