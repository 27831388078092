import { formatDate } from 'utils/dates/format';
import { InspectionTask } from '../types/InspectionTask';
import { getFormattedStartAndEndTime } from './get-formatted-state-and-end-time';

export function getInspectionDateToCsv(value: InspectionTask) {
  const scheduledAt = value?.details?.scheduled_at;

  const formattedStartAndEndTime = getFormattedStartAndEndTime(value);

  return scheduledAt && formattedStartAndEndTime
    ? `${formatDate(scheduledAt)} ${getFormattedStartAndEndTime(value)}`
    : '-';
}
