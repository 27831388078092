import { atomFamily, useRecoilState } from 'recoil';
import { useEffect, useState, useMemo } from 'react';
import { useSessionState } from 'src/modules/common/hooks/session-state';

export const itemsCacheState = atomFamily<any[] | undefined, string>({
  key: 'cached-items',
  default: undefined
});

type FetchItems<Item> = () => Promise<Item[]>;

export type Options<Item> = {
  prefetch: boolean;
  disabled: boolean;
  manipulateItems: (items: Item[]) => Item[];
};

export function useCachedItems<Item>(
  id: string,
  fetchItems: FetchItems<Item>,
  userOptions: Partial<Options<Item>> = {}
) {
  const { activeSilo } = useSessionState();

  const defaultOptions: Options<Item> = {
    prefetch: false,
    disabled: false,
    manipulateItems: (items) => items
  };

  const { prefetch, disabled, manipulateItems } = {
    ...defaultOptions,
    ...userOptions
  };
  const [isLoading, setIsLoading] = useState(false);
  const [items, setItems] = useRecoilState<Item[] | undefined>(
    // We need to add the active silo id to the key to avoid returning the wrong cached items.
    // While we should always have a silo id, I can't be 100% sure, so I'm adding a fallback to leave it off if it's not there.
    itemsCacheState((activeSilo?.id ? activeSilo.id + '-' : '') + id)
  );

  const loadItems = useMemo(
    () => async () => {
      if (disabled) return [] as Item[];
      if (items) return manipulateItems(items);

      setIsLoading(true);
      const loadedItems = await fetchItems();
      setItems(manipulateItems?.(loadedItems));
      setIsLoading(false);

      return manipulateItems(loadedItems);
    },
    [items, id]
  );

  useEffect(() => {
    if (prefetch) loadItems();
  }, []);

  return {
    items,
    loadItems,
    isLoading
  };
}
