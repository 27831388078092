import { useDialog } from '@rexlabs/dialog';
import { useModelActions } from '@rexlabs/model-generator';

import { SingleActionDeclaration } from 'src/modules/common/actions/types/action-declaration-types';
import { CreateEmailRecordDialog } from 'src/modules/communications/messages/dialogs/create-email-record-dialog';
import { useMessageTriggerSettings } from 'src/modules/communications/messages/settings/message-triggers/hooks/use-message-trigger-settings';
import { getSearchResultItemFromObjectAndModel } from 'src/modules/common/utils/search-result-items/get-search-result-items-from-object-and-model';
import { contactsModel } from 'src/modules/contacts/models/contacts';
import { Contact } from 'src/modules/contacts/types/contact-types';
import { fetchTemplate } from 'src/modules/communications/message-templates/utils/fetch-template';
import { tasksModel } from 'src/modules/tasks/common/models/tasks-model';
import { useStatusChangeAction } from 'src/modules/common/actions/status/use-status-change-action';
import { invokeActionDeclaration } from 'src/modules/common/actions/utils/invoke-action-declaration';

import { useFeatureFlags } from 'view/components/@luna/feature-flags';

import { SearchResultItem } from 'utils/api/get-search-results';
import { FLAGS } from 'utils/feature-flags';

import { InspectionTask } from '../../types/InspectionTask';

export function useGetSendInspectionReportAction() {
  const { open: openCreateEmailDialog } = useDialog(CreateEmailRecordDialog);
  const { messageTriggerSettings } = useMessageTriggerSettings();
  const { hasFeature } = useFeatureFlags();
  const getStatusChangeAction = useStatusChangeAction(tasksModel);
  const { refreshLists } = useModelActions(tasksModel);

  return (
    inspectionTask: InspectionTask
  ): SingleActionDeclaration | undefined => {
    const isReportComplete = inspectionTask?.details?.inspection_report?.id;

    if (!isReportComplete) return;

    if (!hasFeature(FLAGS.MESSAGE_ATTACHMENTS)) return;

    return {
      id: 'send-inspection-report',
      intent: 'primary',
      group: 'inspection',
      label: 'Send report',
      handleAction: async () => {
        const owners = (inspectionTask?.property?.active_property_ownership?.ownership?.owners?.map(
          (owner) =>
            getSearchResultItemFromObjectAndModel<Contact>(
              owner.contact,
              contactsModel
            )
        ) || []) as SearchResultItem<Contact>[];

        const template = await fetchTemplate(
          messageTriggerSettings['inspection-template']?.template_id
        );

        openCreateEmailDialog({
          onCreate: () =>
            inspectionTask?.details?.status.id === 'report_completed' &&
            invokeActionDeclaration(getStatusChangeAction, {
              record: inspectionTask,
              status: 'report_sent',
              transformPayload: () => {
                return {
                  type: { id: 'task_inspection' },
                  details: {
                    status: {
                      id: 'report_sent'
                    }
                  }
                };
              },
              dialogOptions: {
                type: 'none',
                afterAction: () => refreshLists()
              }
            }),
          data: {
            relates_to: getSearchResultItemFromObjectAndModel(
              inspectionTask,
              'task'
            ),
            content: template?.channels?.[0]?.plain_text_body,
            subject: template?.channels?.[0]?.email_subject,
            attachments: [] as any[],
            template: template,

            recipients: owners
          }
        });
      }
    };
  };
}
