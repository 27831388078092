import { FinancialPeriodData } from 'src/modules/statements/types';
import { useRecordsQuery } from 'src/lib/react-query/hooks/use-records-query';

export function useFinancialPeriods(includeCurrent?: boolean) {
  const financialQuery = useRecordsQuery<FinancialPeriodData>(
    [
      'financial-years',
      {
        customParams: [
          {
            key: 'includeCurrent',
            value: !!includeCurrent
          }
        ]
      }
    ],
    { staleTime: Infinity }
  );

  return financialQuery;
}
