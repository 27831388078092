import { useCallback, useState } from 'react';

import { submitAllForms } from 'view/components/record-screen/utils';
import { MultiItemDialogProps } from './multi-item-dialog';

type UseHandleDialogSubmitProps = Pick<MultiItemDialogProps, 'handleSubmit'>;

export function useHandleDialogSubmit({
  handleSubmit
}: UseHandleDialogSubmitProps) {
  const [isSubmitting, setSubmitting] = useState(false);

  // Submit all forms, on validation error stay on the current step if it
  // contains an error, otherwise switch to the first step that does
  const handleDialogSubmit = useCallback(
    ({ validateAll, submitAll }) =>
      submitAllForms({
        validateAll,
        submitAll,
        handleSubmit,
        setSubmitting
      }),
    [handleSubmit]
  );

  return {
    isSubmitting,
    handleDialogSubmit
  };
}
