import React from 'react';
import { Box } from '@rexlabs/box';
import { push } from '@rexlabs/whereabouts';

import { ListScreen } from 'view/components/list-screen/list-screen';
import RenderLoading from 'view/components/@luna/render-loading';
import LoadingState from 'view/components/states/loading';
import ROUTES from 'routes/app';
import { Card } from 'view/components/card';

import { useBankAccountSelectActions } from 'src/modules/bank-accounts/hooks/use-bank-account-select-actions';
import { useDialog } from '@rexlabs/dialog';
import { Reconciliation } from 'data/models/entities/financials/reconciliations';
import { RecordSummaryCard } from 'view/components/cards/record-summary';
import { track } from '@rexlabs/analytics';
import { EVENTS } from 'utils/analytics';
import { ReconciliationsTable } from '../components/reconciliations-table';
import { ActiveReconciliation } from '../components/active-reconciliation';
import { StartDailyReconciliationDialog } from '../dialogs/start-daily-reconciliation';
import { DraftReconciliationTitleBlock } from '../components/draft-reconciliation-title-block';
import { ReconciliationBanner } from '../components/reconciliation-banner';

export function ReconciliationsListScreen() {
  const {
    isLoading: isLoadingBankAccounts,
    bankAccount,
    actions
  } = useBankAccountSelectActions(ROUTES.RECONCILIATIONS);

  const { open } = useDialog(StartDailyReconciliationDialog);

  return (
    <RenderLoading
      isLoading={!bankAccount || isLoadingBankAccounts}
      LoadingView={LoadingState}
    >
      <ListScreen title='Reconciliations' actions={actions}>
        {bankAccount && (
          <Box sy='2.4rem'>
            {bankAccount && <ReconciliationBanner bankAccount={bankAccount} />}
            {bankAccount.active_reconciliation && (
              <RecordSummaryCard
                titleBlock={
                  <DraftReconciliationTitleBlock
                    reconciliation={bankAccount.active_reconciliation}
                  />
                }
              >
                <ActiveReconciliation
                  bankAccountId={bankAccount.id}
                  isLoading={isLoadingBankAccounts}
                  reconciliation={bankAccount.active_reconciliation}
                />
              </RecordSummaryCard>
            )}
            <Card
              title='Previous reconciliations'
              data-testid='previous-reconciliations'
              actions={[
                {
                  label: 'Start daily reconciliation',
                  handleAction: () => {
                    track({
                      eventName: EVENTS.RECONCILIATIONS.MODAL_OPENED,
                      data: {
                        reconciliationType: 'daily'
                      }
                    });

                    open({
                      bankAccountId: bankAccount?.id,
                      onCreate: (reconciliation: Reconciliation) =>
                        push(ROUTES.RECONCILIATION, {
                          params: {
                            reconciliationId: reconciliation.id
                          }
                        })
                    });
                  }
                }
              ]}
            >
              <ReconciliationsTable bankAccountId={bankAccount.id} />
            </Card>
          </Box>
        )}
      </ListScreen>
    </RenderLoading>
  );
}
