import React from 'react';
import { TitleBlock } from 'view/components/cards/title/title-block';
import { RecordObject, RecordTypes } from 'data/models/types';
import { getRecordTitle } from 'utils/records/get-record-title';
import { TagConfig } from 'view/components/@luna/title-block';
import { titleBlockStatusTag } from 'src/modules/common/components/status-tag';
import { MessageTemplate } from '../types/MessageTemplate';
import { useGetDeleteMessageTemplateAction } from '../hooks/action-declarations/use-get-delete-message-template-action';
import { useGetDuplicateMessageTemplateAction } from '../hooks/action-declarations/use-get-duplicate-message-template-action';
import { useGetToggleArchivedMessageTemplateAction } from '../hooks/action-declarations/use-get-toggle-archived-message-template-action';
import { messageTemplateStatusMap } from '../maps/message-template-status-map';

const type = RecordTypes.MessageTemplate;

type MessageTemplateTitleBlockProps = {
  messageTemplate?: MessageTemplate;
};

export function MessageTemplateTitleBlock({
  messageTemplate
}: MessageTemplateTitleBlockProps) {
  const getDeleteMessageTemplateAction = useGetDeleteMessageTemplateAction({
    redirectToListScreen: true
  });
  const getDuplicateMessageTemplateAction = useGetDuplicateMessageTemplateAction();
  const getToggleArchivedMessageTemplateAction = useGetToggleArchivedMessageTemplateAction();

  const title = getRecordTitle({
    type,
    object: messageTemplate
  } as RecordObject);

  const actions = messageTemplate
    ? [
        getDuplicateMessageTemplateAction(messageTemplate),
        getToggleArchivedMessageTemplateAction(messageTemplate),
        getDeleteMessageTemplateAction(messageTemplate)
      ]
    : [];

  if (!messageTemplate) {
    return null;
  }

  const tags: TagConfig[] = [
    titleBlockStatusTag(messageTemplate.status, messageTemplateStatusMap)
  ];

  return (
    <TitleBlock
      title={title}
      type={type}
      actions={actions}
      tags={tags}
      reference={messageTemplate.record_reference}
    />
  );
}
