import * as React from 'react';
import { Box } from '@rexlabs/box';
import { StyleSheet, useStyles } from '@rexlabs/styling';

const styles = StyleSheet({
  wrapper: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    overflow: 'auto',
    padding: 12,
    whiteSpace: 'pre-wrap',
    lineHeight: 1.5,
    fontSize: 16,

    '& span': {
      color: ({ token }) => token('color.textStyle.link.idle.default')
    }
  },

  smsPreviewWrapper: {
    background: ({ token }) => token('palette.grey.200'),
    borderColor: ({ token }) => token('palette.grey.300'),
    borderStyle: 'solid',
    borderWidth: ({ token }) => token('border.width.thin'),
    borderRadius: 8
  },

  smsPreview: {
    maxWidth: 435,
    margin: '0 auto'
  }
});

export interface SMSPhonePreviewProps {
  children?: React.ReactNode;
}

export function SMSPhonePreview(props: SMSPhonePreviewProps) {
  const { children } = props;

  const s = useStyles(styles);

  return (
    <svg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 436 596'>
      <path
        fill='#DEDEDE'
        fillRule='evenodd'
        d='M63.834 0h307.999c33.138 0 60 26.863 60 60v747c0 33.137-26.862 60-60 60h-308c-33.137 0-60-26.863-60-60V60c0-33.137 26.863-60 60-60z'
        clipRule='evenodd'
      ></path>
      <path
        fill='#fff'
        fillRule='evenodd'
        d='M64.834 5h305.999c30.928 0 56 25.072 56 56v745c0 30.928-25.072 56-56 56h-306c-30.927 0-56-25.072-56-56V61c0-30.928 25.073-56 56-56z'
        clipRule='evenodd'
      ></path>
      <path
        fill='#fff'
        d='M424.833 83.334h7.333v6.667h-7.333zm-421 0h7.333v6.667H3.833z'
      ></path>
      <path
        fill='#DEDEDE'
        fillRule='evenodd'
        d='M173.833 40a4 4 0 10.001-7.999 4 4 0 00-.001 7.999zm92.667 2.667a6.667 6.667 0 100-13.333 6.667 6.667 0 000 13.333zm-70.667-9.333H240.5a2.667 2.667 0 110 5.333h-44.667a2.667 2.667 0 010-5.333M.5 180.664c0-.735.715-1.33 1.604-1.33H4.5v63.333H2.104c-.886 0-1.604-.596-1.604-1.33v-60.673zm0 79c0-.735.715-1.33 1.604-1.33H4.5v63.333H2.104c-.886 0-1.604-.596-1.604-1.33v-60.673zm0-140.998c0-.735.715-1.332 1.604-1.332H4.5v34.667H2.104c-.886 0-1.604-.595-1.604-1.333v-32.002zm431 80.668h2c1.105 0 2 .743 2 1.665v100.003c0 .92-.898 1.665-2 1.665h-2V199.334z'
        clipRule='evenodd'
      ></path>
      <path
        fill='#F6F6F6'
        fillRule='evenodd'
        d='M67.166 27C46.915 27 30.5 43.396 30.5 63.622v737.756C30.5 821.605 46.916 838 67.166 838h301.668c20.251 0 36.666-16.396 36.666-36.622V63.622C405.5 43.395 389.084 27 368.834 27H328.5v.014a3.998 3.998 0 00-4.333 3.984v1.995c0 13.238-10.762 23.97-24.035 23.97H135.868c-13.274 0-24.035-10.729-24.035-23.97V30.99a3.998 3.998 0 00-4.333-3.976V27H67.166z'
        clipRule='evenodd'
      ></path>
      <rect width='345' height='440' x='46' y='94' fill='#fff' rx='8'></rect>
      <path
        fill='#fff'
        d='M66.707 541.293a1 1 0 01-1.414 0L58 534h16l-7.293 7.293z'
      ></path>
      <path
        fill='#F9A9A9'
        d='M72.25 588.941c9.39-2.82 15.766-11.953 13.165-23.649-1.935-8.764-14.53-17.849-24.285-14.628-27.394 9.725-13.88 45.952 11.12 38.277z'
      ></path>
      <path
        fill='#fff'
        d='M66.02 580.241h-7.461c-.502 0-.994-.263-1.349-.618a1.889 1.889 0 01-.554-1.337v-7.354a1.886 1.886 0 011.164-1.761c.23-.096.477-.146.727-.146H65.9c.267 0 .53.007.782.092a1.909 1.909 0 011.137 1.037 1.91 1.91 0 01-1.137 2.578c-.252.084-.491.091-.82.091h-5.41v3.621h5.769a1.904 1.904 0 011.234 3.183c-.32.352-.76.57-1.234.614h-.202z'
      ></path>
      <path
        fill='#fff'
        d='M73.908 580.241a1.894 1.894 0 01-1.761-1.163 1.892 1.892 0 01-.146-.727v-12.514l-6.216-3.749-5.704 3.669a1.905 1.905 0 01-2.067-3.204l6.713-4.326a1.906 1.906 0 012.019 0l8.107 4.918a1.931 1.931 0 01.914 1.603v13.587a1.893 1.893 0 01-1.859 1.906z'
      ></path>
      <foreignObject width='345' height='440' x='46' y='94'>
        <div {...s('wrapper')}>{children}</div>
      </foreignObject>
    </svg>
  );
}

export const SMSPhonePreviewContainer: React.FC = ({ children }) => {
  const s = useStyles(styles);

  return (
    <Box marginTop={24} {...s('smsPreviewWrapper')}>
      <Box marginTop={24} {...s('smsPreview')}>
        {children}
      </Box>
    </Box>
  );
};
