import { Columns } from 'src/view/components/table';

import { AuditLog } from '../types/AuditLog';
import { ViewDetailsCell } from '../components/view-details-cell';
import { RecordCell } from '../components/record-cell';

export const auditLogColumns: Columns<AuditLog> = [
  {
    id: 'date',
    Header: 'Date',
    type: 'datetime',
    toCsv: (value) => new Date(value).toISOString()
  },
  {
    id: 'id',
    Header: 'Change ID'
  },
  { id: 'author_id', Header: 'User ID' },
  {
    id: 'author_name',
    Header: 'User name'
  },
  {
    id: 'record_type',
    Header: 'Record Type',
    type: 'value'
  },
  {
    id: 'record',
    Header: 'Record',
    accessor: (item) => item,
    Cell: RecordCell,
    toCsv: (value) => value.record_name
  },
  {
    id: 'record_id',
    Header: 'Record ID'
  },
  {
    id: 'change_type',
    Header: 'Change Type',
    type: 'value'
  },
  {
    id: 'view-details',
    accessor: (item) => item.id,
    Cell: ViewDetailsCell,
    excludeFromExport: true
  }
];

export const initialHiddenColumns = ['record_id'];
