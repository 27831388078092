import { DialogProps } from '@rexlabs/dialog';
import {
  query,
  useEntityQuery,
  useModelActions
} from '@rexlabs/model-generator';
import React, { useCallback } from 'react';

import { useToast } from 'view/components/@luna/notifications/toast';
import { DialogButtonGroup } from 'view/components/dialogs/buttons/dialog-button-group';
import { RecordDialog } from 'view/components/record-screen/dialog/dialog';

import { settingsSupplierComplianceCategoriesModel } from 'data/models/entities/settings/supplier-compliance-categories';

import { useRequirementsForCategory } from 'src/modules/compliance/common/hooks/use-requirements-for-category';
import { supplierComplianceCategoryDetailsBlock } from '../blocks/supplier-compliance-categories-details-block';
import { useUpdateComplianceCategory } from '../hooks/use-update-compliance-category';

interface EditComplianceCategoryDialogProps extends DialogProps {
  categoryId: string;
}

const content = [
  {
    id: 'details',
    label: 'Details',
    blocks: [supplierComplianceCategoryDetailsBlock]
  }
];

function getComplianceCategoryQuery(id: string) {
  return query`{
    ${settingsSupplierComplianceCategoriesModel} (id: ${id}) {
      id
      label
      description
      category
      applicable_to_object_type
      category_requirements
    }
  }`;
}

export function EditComplianceCategoryDialog({
  onClose,
  categoryId
}: EditComplianceCategoryDialogProps) {
  const { addToast } = useToast();

  const { data: category, status: categoryLoadingStatus } = useEntityQuery(
    getComplianceCategoryQuery(categoryId)
  );

  const { refreshLists: refreshCategoryLists } = useModelActions(
    settingsSupplierComplianceCategoriesModel
  );

  const updateComplianceCategory = useUpdateComplianceCategory({
    categoryId,
    categoryRequirements: category?.category_requirements?.data
  });

  const { requirementsList } = useRequirementsForCategory({
    categoryRequirements: category?.category_requirements?.data
  });

  const handleSubmit = useCallback(
    async ({ values }) => {
      const { data } = await updateComplianceCategory({
        values
      });

      addToast({
        description: (
          <>
            Service offered updated - <b>{data[0]?.label}</b>
          </>
        )
      });
      await refreshCategoryLists();
      return data;
    },
    [addToast, refreshCategoryLists, updateComplianceCategory]
  );

  return (
    <RecordDialog
      title='Edit category'
      data={{ ...category, requirements: requirementsList }}
      isLoading={categoryLoadingStatus !== 'loaded'}
      handleSubmit={handleSubmit}
      onClose={onClose}
      content={content}
      size={'s'}
      submitLabel='Save'
      ButtonGroup={DialogButtonGroup}
    />
  );
}
