import { push, RouteDefinition } from '@rexlabs/whereabouts';
import { RecordObject, RecordType, ValueListValue } from 'data/models/types';
import { gotoRecord } from 'src/modules/common/utils/Records/get-record-routes';
import { downloadFile } from 'src/modules/reporting/utils/download-file';
import { downloadFile as downloadFileFromUrl } from 'utils/files';

import { api } from 'utils/api/api-client';
import { RegularQuri } from '@rexlabs/quri';
import ROUTES from 'routes/app';
import { PERSIST_FILTERS_QUERY } from 'view/components/table/hooks/use-list-table-persist-filter';
import { getRecordRoutes } from '../utils/Records/get-record-routes';
import { createTableRouteConfig } from '../utils/create-table-route-config';

export type CommandType =
  | 'navigate'
  | 'download'
  | 'download_link'
  | 'imperative_navigate';

type CommandPayload = {
  navigate: {
    record_type: ValueListValue<RecordType>;
    record_id?: string;
    tab?: string;
    filters?: RegularQuri[];
    query?: {
      [key: string]: boolean | string | number | string[];
    };
  };
  download: {
    record_type: ValueListValue<RecordType>;
    record_id: string;
    file_name: string;
  };
  download_link: {
    url: string;
  };
  imperative_navigate: {
    path: string;
    tab?: string;
    filters?: RegularQuri[];
  };
};

export type Command<T extends CommandType> = {
  type: ValueListValue<T>;
  data: CommandPayload[T];
};

/**
 * Determine type of command, and delegate to appropriate handler
 */
export function handleCommand<T extends Command<CommandType>>(command: T) {
  switch (command.type.id) {
    case 'navigate':
      return handleNavigateCommand(command as Command<'navigate'>);
    case 'download':
      return handleDownloadCommand(command as Command<'download'>);
    case 'download_link':
      return handleDownloadLinkCommand(command as Command<'download_link'>);
    case 'imperative_navigate':
      return handleImperativeNavigateCommand(
        command as Command<'imperative_navigate'>
      );
    default:
      break;
  }
}

function checkIfRouteMatchesPath(
  route: RouteDefinition,
  path: string
): boolean {
  const routePath = route.config.path;

  if (!routePath) {
    return false;
  }

  // if the routePath has a colon, it is a dynamic route. replace this with a regex
  const regex = new RegExp(routePath.replace(/:\w+/g, '(.*)'));
  return regex.test(path);
}

function handleImperativeNavigateCommand(
  command: Command<'imperative_navigate'>
) {
  const payload = command.data;

  // find the route whose path matches this payload
  // TODO: this might not work well with nested routes.
  const matchingRoute = Object.values(ROUTES).find((route) =>
    checkIfRouteMatchesPath(route, payload.path)
  );

  if (!matchingRoute) {
    // throw error, and let it bubble up to bugsnag
    throw new Error(`No route found for path: ${payload.path}.`);
  }

  // otherwise, lets go to the route, with the tab and filters
  push(matchingRoute, getRouteConfig(payload));
}

function handleNavigateCommand(command: Command<'navigate'>) {
  const payload = command.data;

  const isIndividualRecord = payload.record_id;

  if (isIndividualRecord) {
    // todo: load actual record as RecordObject is type to full Model
    const recordObject = {
      type: payload.record_type.id,
      object: {
        id: payload.record_id
      }
    } as RecordObject;
    return gotoRecord(recordObject, payload.tab, payload.filters);
  }

  const routes = getRecordRoutes({
    type: payload.record_type.id
  } as RecordObject);

  push(routes.list!.route, getRouteConfig(payload));
}

async function handleDownloadLinkCommand(command: Command<'download_link'>) {
  downloadFileFromUrl(command.data.url);
}

async function handleDownloadCommand(command: Command<'download'>) {
  const response = await api.get(
    `/files/${command.data.record_id}`,
    {},
    {
      responseType: 'blob'
    }
  );

  downloadFile(response, command.data.file_name);
}

function getRouteConfig(
  data: CommandPayload['navigate' | 'imperative_navigate']
) {
  const defaultQuery = { [PERSIST_FILTERS_QUERY]: false };
  const routeConfig =
    'query' in data
      ? { query: { ...defaultQuery, ...data.query } }
      : { query: defaultQuery };

  return createTableRouteConfig(data.filters, data.tab, routeConfig);
}
