import React, { useMemo } from 'react';
import { query, useEntityQuery } from '@rexlabs/model-generator';

import { BREADCRUMBS } from 'view/components/@luna/breadcrumbs';
import { RecordScreen } from 'view/components/record-screen';
import {
  financialsInvoicesModel,
  Invoice
} from 'data/models/entities/financials/invoices';

import { VoidedBanner } from 'src/modules/common/components/voided-banner';
import { content } from '../data/content';
import { InvoiceTitleBlock } from '../components/invoice-title-block';

const getInvoiceQuery = (id: string) => query`{
  ${financialsInvoicesModel} (id: ${id}) {
    id
    status
    bill_reference
    reference
    description
    amount_total_inc_tax
    amount_total_exc_tax
    amount_total_tax
    amount_due
    amount_paid
    line_items
    reimbursed_by_invoices
    reimbursement_for_invoice
    maintenance_job
    is_tax_included
    tax_summary
    due_date
    invoice_date
    payable_by
    payable_to
    property
    payments
    task
    payable_by_ownership{
      financial_contact
    }
    payable_to_ownership
    payable_by_contact{
      primary_phone
      financial_contact
    }
    payable_to_contact {
      primary_phone
    }
    payable_by_tenancy {
      financial_contact
    }
    payable_to_tenancy
    bank_account
    specific_disbursement_payment_method
    voided_by
    triggered_by_invoice_payment{
      invoice
    }
    created_by {
      contact
    }
    updated_by {
      contact
    }
    uploaded_bill {
      file
    }
    task
  }
}`;

interface InvoiceDetailsScreenProps {
  invoiceId?: string;
}

export function InvoiceDetailsScreen({ invoiceId }: InvoiceDetailsScreenProps) {
  const breadcrumbs = [{ type: BREADCRUMBS.INVOICE }];
  const query = useMemo(() => getInvoiceQuery(invoiceId!), [invoiceId]);
  const { status, data } = useEntityQuery(query, {
    throwOnError: false
  });

  const initialValues = {
    ...data,
    specific_disbursement_payment_method:
      data?.specific_disbursement_payment_method ||
      'use_disbursement_preferences'
  };

  return (
    <RecordScreen
      isLoading={status === 'loading'}
      data={data}
      initialValues={initialValues}
      content={content}
      titleBlock={<InvoiceTitleBlock invoice={data as Invoice} />}
      breadcrumbs={breadcrumbs}
      banner={data?.status.id === 'void' && <VoidedBanner record={data} />}
    />
  );
}
