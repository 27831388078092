import React from 'react';
import { InputProps } from '@rexlabs/form';
import { RadioGroupInput } from '@rexlabs/radio-input';
import { useHasServicePackagesFeature } from 'src/modules/agency-fees/hooks/use-has-service-packages-feature';
import { useTranslation } from 'react-i18next';
import { ValueListRadios } from '.';

export function AgencyFeeEntityTypeRadioGroup(props: Omit<InputProps, 'meta'>) {
  const hasServicePackagesFeature = useHasServicePackagesFeature();
  const { t } = useTranslation();

  return hasServicePackagesFeature ? (
    <RadioGroupInput
      {...props}
      options={[
        {
          label: t('property-ownerships.management-agreement.label.singular'),
          value: 'property_ownership',
          // @ts-ignore
          props: {
            description: 'For fees that will apply per property under agreement'
          }
        },
        {
          label: 'Ownership',
          value: 'ownership',
          // @ts-ignore
          props: {
            description:
              'For fees that will apply to the ownership, regardless of the number of properties under agreement'
          }
        }
      ]}
    ></RadioGroupInput>
  ) : (
    <ValueListRadios {...props} valueListId='agency_fee_entity_type' />
  );
}
