import React from 'react';
import { BlockConfig } from 'view/components/record-screen/types';
import { CreditNote } from 'data/models/entities/financials/credit-notes';
import { InvoiceLineItems } from 'src/modules/invoices/components/invoice-line-items';
import Box from '@rexlabs/box';
import { CreditNoteDetails } from '../components/details';

export const detailsBlock: BlockConfig<CreditNote> = {
  id: 'details',
  title: 'Credit note details',
  View: ({ data }) => (
    <Box mt='1rem' sy='6rem'>
      <CreditNoteDetails creditNote={data} />
      <Box sy='6rem'>
        <InvoiceLineItems data={data} />
      </Box>
    </Box>
  )
};
