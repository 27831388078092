import React from 'react';
import { Field } from '@rexlabs/form';
import { Tag } from '@rexlabs/tag';
import { TagCell } from '@rexlabs/table';

import { BlockConfig } from 'view/components/record-screen/types';
import { Ownership } from 'data/models/entities/ownerships';
import { Grid } from 'view/components/@luna/form/grid';
import { RecordTable } from 'view/components/table';
import { SupplierSelect } from 'view/components/inputs/selects/supplier';

const columns = [
  {
    id: 'contact.display_name',
    Header: 'Name'
  },
  {
    id: 'contact.emails',
    Header: 'Email',
    Cell: ({ value }) => {
      return (
        <>
          {value?.find?.((email) => email?.is_primary_email)?.email_address ||
            '--'}
        </>
      );
    }
  },
  {
    id: 'contact.service_categories',
    Header: 'Services offered',
    Cell: ({ value }) => {
      if (!value?.length) {
        return <>--</>;
      }
      return (
        <TagCell>
          {value?.map?.(({ id, label }) => (
            <Tag key={id}>{label}</Tag>
          ))}
        </TagCell>
      );
    }
  }
];

export const preferredSuppliersBlock: BlockConfig<Ownership> = {
  id: 'preferred-suppliers',
  title: 'Preferred suppliers',
  View: ({ values }) => {
    return (
      <RecordTable
        id='suppliers'
        withPagination={true}
        items={values?.preferred_suppliers?.data || []}
        columns={columns}
        getRowId={(item) => item?.contact?.id}
        isLoading={false}
      />
    );
  },
  Edit: () => (
    <Grid columns={1}>
      <Field
        label='Preferred suppliers'
        name='preferred_suppliers.data'
        Input={SupplierSelect}
        inputProps={{ multi: true }}
      />
    </Grid>
  ),
  workInProgress: true
};
