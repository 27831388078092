import { RecordTypes } from 'data/models/types';
import { useModelActions } from '@rexlabs/model-generator';
import { statementsModel } from 'src/modules/statements/models/statements';
import {
  StatementReviewerRecord,
  statementPreviewerViewModes
} from 'src/modules/ownership-statements/common/dialogs/preview-statements-dialog';
import { AxiosResponse } from 'axios';

export const useLoadMoreRecordsInPreviewer = (
  setUpdatedRecords: (records: StatementReviewerRecord[]) => void,
  existingRecords: StatementReviewerRecord[]
) => {
  const { viewItem } = useModelActions(statementsModel);

  return async (
    records: StatementReviewerRecord[],
    viewMode: typeof statementPreviewerViewModes
  ) => {
    if (!records.length) return;

    const statementsHtml: { id: string; html: string }[] = await Promise.all(
      records.map(async (record) => {
        const data = record.views?.[viewMode]?.data;

        if (!data) {
          throw Error(`No data in record ${record.id}`);
        }

        const isExistingStatement = 'statement' in data;

        return viewItem({
          statementData: {
            ...(isExistingStatement
              ? {
                  statementId: data.statement.id
                }
              : {
                  statementTo: data.statementEndDate,
                  ...(data.statementStartDate && {
                    statementFrom: data.statementStartDate
                  }),
                  objectId: data.ownership.id,
                  objectType: RecordTypes.Ownership
                })
          },
          reportId:
            viewMode === 'summary' ? 'income-summary' : 'income-detailed',
          format: 'html',
          disposition: 'inline'
        }).then((res: AxiosResponse) => {
          return {
            id: isExistingStatement ? data.statement.id : data.ownership.id,
            html: res.data
          };
        });
      })
    );

    const updatedListOfRecords = existingRecords.map((r) => {
      const statementHtml = statementsHtml.find((s) => s.id === r.id);
      if (!statementHtml) return r;

      return {
        ...r,
        views: {
          ...r.views,
          [viewMode]: {
            status: 'loaded',
            data: {
              ...r.views?.[viewMode]?.data,
              html: statementHtml.html
            }
          }
        }
      };
    });

    setUpdatedRecords(updatedListOfRecords);
  };
};
