import { useDialog } from '@rexlabs/dialog';
import { TransferFundsDialog } from 'src/modules/trust-journal-entries/dialogs/transfer-funds-dialog';
import { ActionDeclaration } from 'src/modules/common/actions/types/action-declaration-types';
import { GetFinancialEntityActionArgs } from './use-get-financial-entity-actions';

export function useGetTransferFundsAction() {
  const transferFundsDialog = useDialog(TransferFundsDialog);

  return (args?: GetFinancialEntityActionArgs): ActionDeclaration => {
    const { entity, onCreate } = args ?? {};

    return {
      id: 'transfer-funds',
      group: 'financial',
      label: 'Transfer funds',
      handleAction: async () =>
        transferFundsDialog.open({
          sourceObject: entity,
          onCreate
        })
    };
  };
}
