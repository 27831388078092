import { Generator, GeneratorConfig } from 'data/models/generator';
import { ownershipsModel } from 'data/models/entities/ownerships';
import { PropertyOwnership } from 'src/modules/property-ownerships/types/property-ownership-types';
import { auditableConfig } from 'src/modules/common/auditable/auditable-config';
import { api } from 'utils/api/api-client';
import { identity } from 'lodash';

const config: GeneratorConfig = {
  entities: {
    related: {
      ownership: {
        include: 'ownership',
        model: ownershipsModel
      },
      property: {
        include: 'property'
      },
      maintenance_instructions: {
        include: 'maintenance_instructions'
      },
      preferred_suppliers: {
        include: 'preferred_suppliers'
      },
      service_package: {
        include: 'service_package'
      },
      entity_fees_have_changed: {
        include: 'entity_fees_have_changed'
      },
      entity_fees: {
        include: [
          'entity_fees',
          'entity_fees.agency_fee',
          'entity_fees.agency_fee.agency_chart_of_accounts_account.tax_type',
          'entity_fees.agency_fee.tax_type'
        ].join(',')
      },
      ...auditableConfig
    }
  }
};

const actionCreators = {
  resetFees: {
    request: ({ propertyOwnershipId }: { propertyOwnershipId: string }) =>
      api.post(`/property-ownerships/${propertyOwnershipId}/reset`),
    reduce: identity
  }
};

export const propertyOwnershipModel = new Generator<
  PropertyOwnership,
  typeof actionCreators
>('property-ownerships', config).createEntityModel({
  actionCreators
});
