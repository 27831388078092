import React from 'react';

import Box from '@rexlabs/box';
import { StyleSheet, useStyles, mq } from '@rexlabs/styling';

import { PageLayout } from '../page-layout/page-layout';

const defaultStyles = StyleSheet({
  wrapper: {
    height: '100%',
    width: '100%'
  },

  main: {
    // Used to force flex children to respect this containers width
    minWidth: 0
  },

  content: {
    // Used to force flex children to respect this containers width
    minWidth: 0,
    maxWidth: '100%'
  },

  gap: {
    gap: ({ token }) => token('spacing.m'),

    ...mq.styles({
      queries: {
        minWidth: 's'
      },
      styles: {
        gap: ({ token }) => token('spacing.l')
      }
    })
  }
});

interface RecordLayoutProps extends React.AllHTMLAttributes<HTMLDivElement> {
  breadcrumbs?: React.ReactNode;
  header: React.ReactNode;
  navigation: React.ReactNode;
  right?: React.ReactNode;
}

export function RecordLayout({
  breadcrumbs,
  header,
  children,
  navigation,
  right
}: RecordLayoutProps) {
  const s = useStyles(defaultStyles);
  return (
    <PageLayout>
      <Box {...s('wrapper', 'gap')} flexDirection='column'>
        {breadcrumbs}
        <Box {...s('gap')} flex={1} flexDirection='row'>
          <Box {...s('main', 'gap')} flex={1} flexDirection='column'>
            {header}
            <Box {...s('gap')} flex={1} flexDirection='row'>
              {navigation}
              <Box
                {...s('content', 'gap')}
                flex={1}
                flexDirection='column'
                Container='section'
              >
                {children}
              </Box>
            </Box>
          </Box>
          {right}
        </Box>
      </Box>
    </PageLayout>
  );
}
