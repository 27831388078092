import React from 'react';

import { Body, Semibold } from '@rexlabs/text';
import { Field } from '@rexlabs/form';
import { Grid } from 'view/components/@luna/form/grid';
import { Value } from 'view/components/values';
import EmptyRecord from 'src/assets/illustrations/empty-record.svg';

import { BlockConfig } from 'view/components/record-screen/types';
import { EmptyCard } from 'view/components/record-screen/cards/empty-card';
import { Message } from 'view/components/@luna/message';
import { TimePeriodInput } from 'view/components/inputs/time-period-input';
import { pluralize } from 'utils/formatters';
import { Trans, useTranslation } from 'react-i18next';
import { AutomaticTaskCreationRadioButtons } from 'src/modules/tasks/common/components/AutomaticTaskCreationRadioButtons';
import { ServicePackageNames } from 'src/modules/tasks/inspections/components/service-package-names';
import { ServicePackageSelect } from 'view/components/inputs/selects/v4-selects/service-package-select';

const validate = {
  definitions: {
    'periodic_agreement_review.count': {
      rules: 'required|min:1'
    },
    'periodic_agreement_review.unit': {
      rules: 'required'
    },
    'periodic_agreement_notice.count': {
      rules: 'required|min:1'
    },
    'periodic_agreement_notice.unit': {
      rules: 'required'
    },
    'fixed_agreement_notice.count': {
      rules: 'required|min:1'
    },
    'fixed_agreement_notice.unit': {
      rules: 'required'
    },
    lease_review_service_packages: {
      name: 'service packages',
      rules:
        'required_if:lease_review_automatic_task_creation,service_packages|array|min:1'
    }
  },
  messages: {
    required_if: 'The :attribute field is required.'
  }
};

export const leaseReviewTriggerBlock: BlockConfig = {
  id: 'lease-review-task-interval',
  title: () => <Trans i18nKey='tasks.lease-review.label.plural' />,
  validate,

  showEmpty: (data) => {
    return (
      data?.periodic_agreement_review?.count == undefined &&
      data?.periodic_agreement_notice?.count == undefined &&
      data?.fixed_agreement_notice?.count == undefined
    );
  },

  View: ({ data }) => {
    const { t } = useTranslation();
    return (
      <Grid columns={1}>
        <Semibold>
          {t(
            'tasks.lease-review.task-triggers.lease-agreements.frequency.message'
          )}
        </Semibold>

        <Value
          label={t('tasks.lease-review.setup.schedule-frequency.label')}
          value={`${pluralize(
            data?.periodic_agreement_review?.unit,
            data?.periodic_agreement_review?.count,
            true
          )} after start of lease`}
        />

        <Semibold>
          {t('tasks.lease-review.task-triggers.when-to-trigger.message')}
        </Semibold>

        <Value
          label={t(
            'tasks.lease-review.task-triggers.when-to-trigger.label-periodic'
          )}
          value={`${pluralize(
            data?.periodic_agreement_notice?.unit,
            data?.periodic_agreement_notice?.count,
            true
          )} ${t(
            'tasks.lease-review.task-triggers.when-to-trigger.options.before-lease-review'
          )}`}
        />

        <Value
          label={t(
            'tasks.lease-review.task-triggers.when-to-trigger.label-fixed'
          )}
          value={`${pluralize(
            data?.fixed_agreement_notice?.unit,
            data?.fixed_agreement_notice?.count,
            true
          )} before lease expires`}
        />

        {data?.lease_review_automatic_task_creation === 'service_packages' ? (
          <Grid columns={2}>
            <ServicePackageNames
              ids={data?.lease_review_service_package_ids ?? []}
            />
          </Grid>
        ) : null}
      </Grid>
    );
  },

  Edit: ({ values }) => {
    const { t } = useTranslation();
    return (
      <Grid columns={1}>
        <Semibold>
          {t(
            'tasks.lease-review.task-triggers.lease-agreements.frequency.message'
          )}
        </Semibold>

        <Field
          label={
            t('tasks.lease-review.setup.schedule-frequency.label') as string
          }
          name='split_periodic_agreement_review'
          Input={TimePeriodInput}
          inputProps={{
            testId: 'periodic_agreement_review',
            countName: 'periodic_agreement_review.count',
            unitName: 'periodic_agreement_review.unit',
            Suffix: <Body>after start of lease</Body>
          }}
          optional={false}
        />

        <Semibold>
          {t('tasks.lease-review.task-triggers.when-to-trigger.message')}
        </Semibold>

        <Field
          label={
            t(
              'tasks.lease-review.task-triggers.when-to-trigger.label-periodic'
            ) as string
          }
          name='split_periodic_agreement_notice'
          Input={TimePeriodInput}
          inputProps={{
            testId: 'periodic_agreement_notice',
            countName: 'periodic_agreement_notice.count',
            unitName: 'periodic_agreement_notice.unit',
            Suffix: (
              <Body>
                {t(
                  'tasks.lease-review.task-triggers.when-to-trigger.options.before-lease-review'
                )}
              </Body>
            )
          }}
          optional={false}
        />

        <Field
          label={
            t(
              'tasks.lease-review.task-triggers.when-to-trigger.label-fixed'
            ) as string
          }
          name='split_fixed_agreement_notice'
          Input={TimePeriodInput}
          inputProps={{
            testId: 'fixed_agreement_notice',
            countName: 'fixed_agreement_notice.count',
            unitName: 'fixed_agreement_notice.unit',
            Suffix: <Body>before lease expires</Body>
          }}
          optional={false}
        />

        <Grid columns={2}>
          <Field
            Input={AutomaticTaskCreationRadioButtons}
            name='lease_review_automatic_task_creation'
            id='lease_review_automatic_task_creation'
            optional={false}
          />
        </Grid>

        {values?.lease_review_automatic_task_creation === 'service_packages' ? (
          <Field
            name='lease_review_service_packages'
            label='For service packages'
            Input={ServicePackageSelect}
            inputProps={{
              deselectable: true,
              multi: true
            }}
            optional={false}
          />
        ) : null}
      </Grid>
    );
  },

  Empty: ({ onEditClick }) => {
    const { t } = useTranslation();
    return (
      <EmptyCard>
        <Message
          title={t('tasks.lease-review.label.plural')}
          Illustration={EmptyRecord}
          actions={[
            {
              label: t(
                'tasks.lease-review.task-triggers.set-up.message'
              ) as string,
              intent: 'primary',
              handleAction: onEditClick
            }
          ]}
        >
          <div>
            {t(
              'tasks.lease-review.task-triggers.lease-agreements.set-up.message'
            )}
          </div>
        </Message>
      </EmptyCard>
    );
  }
};
