import React from 'react';

import { Card } from 'view/components/card';
import ROUTES from 'routes/app';
import { ListScreen } from 'view/components/list-screen/list-screen';
import { OwnershipTable } from 'src/modules/ownerships/components/ownership-table';

export const OwnershipsList = () => {
  return (
    <ListScreen
      title='Ownerships'
      recordType='ownership'
      createLabel='Create ownership'
      createLinkProps={{ to: ROUTES.OWNERSHIPS_LIST.CREATE }}
    >
      <Card>
        <OwnershipTable />
      </Card>
    </ListScreen>
  );
};
