import React, { useRef, useEffect } from 'react';
import { OutlineButton, PrimaryButton } from '@rexlabs/button';
import { HotkeyedActionDeclaration } from 'src/modules/common/actions/types/action-declaration-types';
import { invokeActionDeclaration } from 'src/modules/common/actions/utils/invoke-action-declaration';

type Props = {
  action: HotkeyedActionDeclaration;
  onAction: (action: HotkeyedActionDeclaration) => void;
};

export function PagedReviewerActionButton({ action, onAction }: Props) {
  const buttonRef = useRef<HTMLButtonElement | null>(null);

  useEffect(() => {
    const handleKeydown = async (e: KeyboardEvent) => {
      if (!action.hotkey || e.key !== action.hotkey.toLowerCase()) return;

      if (buttonRef.current) {
        buttonRef.current.click();
      }
    };

    // Add event listener
    window.addEventListener('keydown', handleKeydown);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener('keydown', handleKeydown);
    };
  }, [action, buttonRef]);

  const ButtonToUse =
    action.intent === 'primary' ? PrimaryButton : OutlineButton;

  return (
    <ButtonToUse
      onClick={async () => {
        await invokeActionDeclaration(() => action).then(() => {
          onAction(action);
        });
      }}
      ref={buttonRef}
    >
      {action.label} {action.hotkey && ` (${action.hotkey.toUpperCase()})`}
    </ButtonToUse>
  );
}
