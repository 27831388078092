import React, {
  useRef,
  useEffect,
  PropsWithChildren,
  ComponentType
} from 'react';
import ReactDOM from 'react-dom';

import Box from '@rexlabs/box';
import {
  useStyles,
  useToken,
  StyleSheet,
  text,
  padding,
  border
} from '@rexlabs/styling';
import { Overlay } from '@rexlabs/overlay';

const defaultStyles = StyleSheet({
  menu: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    width: '30.4rem',
    overflow: 'auto',
    transition: 'transform 350ms cubic-bezier(0.25, 1, 0.25, 1)',

    backgroundColor: ({ token }) => token('color.pageBackground.alternate')
  },

  menuActive: {
    transform: 'translateX(0)'
  },

  left: {
    left: 0,
    transform: 'translateX(-30.4rem)',

    ...border.styles({
      right: {
        width: 'none',
        radius: 'l'
      }
    })
  },

  right: {
    right: 0,
    transform: 'translateX(30.4rem)',

    ...border.styles({
      left: {
        width: 'none',
        radius: 'l'
      }
    })
  },

  heading: {
    ...text.styles({
      fallback: 'normal.bold'
    }),
    ...padding.styles({
      x: 'l',
      y: 'm'
    }),
    ...border.styles({
      bottom: {
        width: 'thin',
        color: 'container.static.light'
      }
    })
  }
});

type SideMenuProps = PropsWithChildren<{
  title?: string;
  side?: 'left' | 'right';
  active: boolean;
  setActive: (args: any) => void;
  Icon?: ComponentType<any>;
}>;

export function SideMenu({
  children,
  title,
  side,
  active,
  setActive,
  Icon
}: SideMenuProps) {
  const s = useStyles(defaultStyles);
  const token = useToken();

  const menuRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    function onOutsideClick(e) {
      if (!menuRef.current?.contains(e.target)) {
        setActive(null);
      }
    }

    if (active) {
      document.addEventListener('click', onOutsideClick);

      return () => {
        document.removeEventListener('click', onOutsideClick);
      };
    }
  }, [active]);

  return ReactDOM.createPortal(
    <Overlay show={active}>
      <Box
        {...s('menu', {
          left: side === 'left',
          right: side === 'right',
          menuActive: active
        })}
        ref={menuRef}
      >
        <Box {...s('heading')} alignItems='center'>
          {Icon && (
            <Box
              alignItems='center'
              justifyContent='center'
              mr={token('spacing.s')}
            >
              <Icon />
            </Box>
          )}
          {title}
        </Box>
        {children}
      </Box>
    </Overlay>,
    document.body
  );
}
