import { useGetGenericWipAction } from 'view/hooks/actions/use-get-generic-wip-action';

export function useGetCreateTrustLedgerAction() {
  const getGenericWipAction = useGetGenericWipAction();

  return () =>
    getGenericWipAction({
      id: 'create-trust-ledger',
      group: 'financial',
      actionName: 'Create trust ledger'
    });
}
