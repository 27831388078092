import React from 'react';

import { useModelActions } from '@rexlabs/model-generator';
import { useErrorDialog } from '@rexlabs/dialog';

import { Ownership, ownershipsModel } from 'data/models/entities/ownerships';

import { getAllIncludesStringFromModel } from 'utils/api/get-all-includes-string-from-model';

import { useToast } from 'view/components/@luna/notifications/toast';
import { RecordSubmitHandler } from 'view/components/record-screen/utils';

import { useQueryClient } from 'react-query';
import { taxSummaryQueryKey } from 'src/modules/tax/hooks/use-get-ownership-tax-summaries-for-financial-year';
import { ownershipTaxTransactionsModel } from '../models/ownership-tax-transactions-model';
import { createTaxAdjustmentPayloadData } from '../utils/create-tax-adjustment-payload-data';
import { createWithdrawFundsPayloadData } from '../utils/create-withdraw-funds-payload-data';
import { TaxAdjustmentForm } from '../types/tax-adjustment-form';

export function useGetCreateTaxAdjustmentSubmitHandler(): (
  ownership: Ownership
) => RecordSubmitHandler<TaxAdjustmentForm> {
  const { refreshLists, createItem } = useModelActions(
    ownershipTaxTransactionsModel
  );
  const { refreshItem } = useModelActions(ownershipsModel);
  const queryClient = useQueryClient();

  const { open } = useErrorDialog();
  const { addToast } = useToast();

  return (ownership) => async ({ values }) => {
    try {
      const withdrawalRequest =
        values?.manual_transaction_type?.id ===
        'reimburse_withheld_tax_to_owner'
          ? await createWithdrawFundsPayloadData(values, ownership)
          : undefined;

      const taxTransactionRequest = createTaxAdjustmentPayloadData(
        values,
        ownership
      );

      const taxAdjustment = await createItem({
        data: {
          ...taxTransactionRequest,
          ...(withdrawalRequest ? { withdrawal: withdrawalRequest } : {})
        }
      });

      addToast({
        description: (
          <>
            Successfully created <b>tax adjustment</b> for{' '}
            <b>{values.contact.display_name}</b>
          </>
        )
      });

      await refreshLists();
      await refreshItem({
        id: ownership.id,
        args: { include: getAllIncludesStringFromModel(ownershipsModel) }
      });
      queryClient.invalidateQueries([taxSummaryQueryKey]);

      return taxAdjustment;
    } catch (error) {
      open(error);
    }
  };
}
