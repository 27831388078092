import React from 'react';

import { LinkProps, useWhereabouts } from '@rexlabs/whereabouts';
import { useGetViewSupplierCommissionTemplateAction } from './action-declarations/use-get-view-supplier-commission-template-action';

export function useGetTemplateListLinkProps() {
  const getViewSupplierCommissionTemplateAction = useGetViewSupplierCommissionTemplateAction();
  const whereabouts = useWhereabouts();
  const getRowLinkProps = React.useCallback(
    ({ item }): LinkProps => {
      const vieSupplierCommissionTemplateAction = getViewSupplierCommissionTemplateAction(
        item
      );

      return {
        ...(whereabouts.query ? { query: whereabouts.query } : {}),
        onClick: () => vieSupplierCommissionTemplateAction.handleAction?.()
      };
    },
    [getViewSupplierCommissionTemplateAction, whereabouts]
  );

  return getRowLinkProps;
}
