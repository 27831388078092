import React, { useMemo } from 'react';
import { Select, SelectProps } from '@rexlabs/select';
import { normaliseBankAccount } from 'utils/normaliser/bank-account';
import { useEntityListQuery } from '@rexlabs/model-generator';
import { getBankAccountsQuery } from 'src/modules/bank-accounts/queries/bank-account-query';
import { BankAccount } from 'data/models/entities/financials/bank-accounts';

export function BankAccountSelect(props: SelectProps<BankAccount>) {
  const query = useMemo(() => getBankAccountsQuery(), []);
  const { data } = useEntityListQuery(query);
  return (
    <Select {...props} items={data ?? []} normaliser={normaliseBankAccount} />
  );
}
