import React, { useCallback, useEffect, useMemo } from 'react';

import { Field } from '@rexlabs/form';
import { Body } from '@rexlabs/text';

import { Coordinates } from 'src/lib/mapbox/types/Coordinates';
import { useFallbackLngLat } from 'src/modules/tasks/inspections/hooks/use-fallback-lng-lat';

import { BlockConfig } from 'view/components/record-screen/types';
import RenderLoading from 'view/components/@luna/render-loading';

import { EditLocation, EditLocationProps } from '../components/edit-location';

const propertyLngLatFieldName = 'property_lng_lat' as const;

export type EditLocationBlockValues = {
  [propertyLngLatFieldName]?: Coordinates;
};

export const editLocationBlock: BlockConfig<
  EditLocationBlockValues,
  any,
  EditLocationBlockValues
> = {
  id: 'edit-location-block',
  title: 'Edit location',
  Edit: ({ setFieldValue, values }) => {
    const onLngLatChange = useCallback(
      (newLngLat: Coordinates) => {
        setFieldValue?.(propertyLngLatFieldName, newLngLat);
      },
      [setFieldValue]
    );

    const { isFetching, lngLat } = useFallbackLngLat();

    const propertyLngLat = useMemo(
      () => values![propertyLngLatFieldName] || lngLat,
      [values, lngLat]
    );

    useEffect(() => {
      if (!values?.[propertyLngLatFieldName] && propertyLngLat) {
        onLngLatChange(propertyLngLat!);
      }
    }, [propertyLngLat, values]);
    return (
      <RenderLoading isLoading={isFetching}>
        <Body grey>
          Click and hold the map pin to drag and relocate it. When you are happy
          with the location, release the mouse button and click save.
        </Body>
        <Field
          name={propertyLngLatFieldName}
          id={propertyLngLatFieldName}
          label=''
          optional={false}
          Input={EditLocation}
          inputProps={
            {
              lngLat: propertyLngLat,
              onLngLatChange
            } as EditLocationProps
          }
        />
      </RenderLoading>
    );
  }
};
