// This code is a copy of this file https://github.com/rexlabsio/vivid/blob/92c7a1d6b257a32e3c8212e21832066ad9c8ce1f/components/table/src/hooks/plugins/use-action-menu-column.js
import React, { memo, useMemo, useCallback, forwardRef } from 'react';
import Box from '@rexlabs/box';
import { StyleSheet, StylesProvider, useStyles } from '@rexlabs/styling';
import ActionMenu from '@rexlabs/action-menu';
import { GhostIconButton } from '@rexlabs/button';
import Icons from '@rexlabs/icons';
import { noop } from 'lodash';

const { Kebab } = Icons;

const MemoActionMenu = memo(ActionMenu);

const defaultStyles = StyleSheet({
  container: {
    height: '2rem'
  }
});

const overrides = {
  actionMenu: {
    button: {
      icon: {
        svg: ({ token }) => token('table.cell.actionMenu.icon.svg', Kebab)
      }
    }
  }
};

const ActionMenuButton = forwardRef(function ActionMenuButton(props, ref) {
  return <GhostIconButton {...props} ref={ref} size='s' Icon={Icons.Kebab} />;
});

export function ConditionalActionMenuCell({
  cell,
  column,
  getActionMenuItems = noop
}) {
  const s = useStyles(defaultStyles);
  const { value } = cell;
  const { cellProps: { onClick = noop } = {} } = column;

  const items = useMemo(() => {
    return getActionMenuItems({ item: value });
  }, [value, getActionMenuItems]);

  const handleCellClick = useCallback(
    (e) => {
      e.stopPropagation();
      e.preventDefault();
      onClick(e);
    },
    [onClick]
  );

  // Make sure we render action menu only if it has items
  const hasItems = Array.isArray(items) && items.length > 0;

  if (!hasItems) return null;

  return (
    <Box
      {...s('container')}
      alignItems='center'
      justifyContent='flex-end'
      onClick={handleCellClick}
    >
      <StylesProvider tokens={overrides}>
        <MemoActionMenu
          placement='bottom-end'
          items={items}
          Button={ActionMenuButton}
        />
      </StylesProvider>
    </Box>
  );
}
