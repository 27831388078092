import React from 'react';
import { Helmet } from 'react-helmet';
import dayjs from 'dayjs';

import Box from '@rexlabs/box';

import { TitleBlock } from 'view/components/@luna/title-block';
import { PageLayout } from 'view/components/layouts/page-layout/page-layout';
import { RecordTitleCard } from 'view/components/cards/title';
import { BREADCRUMBS, Breadcrumbs } from 'view/components/@luna/breadcrumbs';

import { getFinancialYearId } from '../utils/get-financial-year-id';
import { getFinancialYearAbbreviationString } from '../utils/get-financial-year-abbreviation-string';
import { NonResidentLandlordsStatements } from '../components/non-resident-landlord-statements-card';
import { ResidentLandlordsStatements } from '../components/resident-landlord-statements-card';
import { useGetFinancialYearStartAndEnd } from '../hooks/use-get-financial-year-start-and-end';

const dateFormat = 'D MMM YYYY';

export function HmrcReportsAndSubmissionsFinancialYearStatementsDetails({
  id
}) {
  const getFinancialYearStartAndEnd = useGetFinancialYearStartAndEnd();
  const { startDate, endDate } = getFinancialYearStartAndEnd(id);

  const breadcrumbs = [
    { type: BREADCRUMBS.HMRC_REPORTS_AND_SUBMISSIONS },
    {
      type: BREADCRUMBS.HMRC_REPORTS_AND_SUBMISSIONS_DETAILS,
      args: {
        id: getFinancialYearId(startDate),
        label: `FY ${getFinancialYearAbbreviationString(startDate, endDate)}`
      }
    }
  ];

  const title = `FY ${dayjs(startDate).format('YYYY')}-${dayjs(endDate).format(
    'YY'
  )} statements`;

  const subTitle = `${dayjs(startDate).format(dateFormat)} - ${dayjs(
    endDate
  ).format(dateFormat)}`;

  return (
    <>
      <Helmet title={title} />
      <PageLayout>
        <Box flexDirection={'column'} rowGap={'2rem'}>
          <Breadcrumbs breadcrumbs={breadcrumbs} />
          <RecordTitleCard
            titleBlock={<TitleBlock title={title} subText={subTitle} />}
          />
          <NonResidentLandlordsStatements />
          <ResidentLandlordsStatements />
        </Box>
      </PageLayout>
    </>
  );
}
