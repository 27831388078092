import React from 'react';
import { ReactForms, Form, FieldArray, HiddenField } from '@rexlabs/form';
import { OutlineButton } from '@rexlabs/button';
import Box from '@rexlabs/box';
import { invokeActionDeclaration } from 'src/modules/common/actions/utils/invoke-action-declaration';
import CheckCircleIcon from 'view/components/icons/check-circle';
import { StyleSheet, useStyles } from '@rexlabs/styling';
import { Submission } from '@rexlabs/form/lib/types/core/types';
import {
  ChecklistTemplatesForm,
  ChecklistTemplatesItemForm
} from '../types/ChecklistTemplatesForm';
import { useCreateChecklistItemAction } from '../hooks/action-declarations/checklist-item/use-create-checklist-item-action';
import { TaskType } from '../../common/types/TaskType';
import { ChecklistTemplateItemRows } from './checklist-template-item-rows';

interface ChecklistTemplateItemsEditProps {
  data: Array<ChecklistTemplatesItemForm>;
  taskType: TaskType;
  handleSubmit: Submission<ChecklistTemplatesForm, any>;
}

const defaultStyles = StyleSheet({
  container: {
    display: 'flex',
    width: '100%'
  },
  button: {
    display: 'flex',
    flexGrow: 1,
    marginLeft: '40px'
  }
});

export const ChecklistTemplateItemsEdit = ({
  data,
  taskType,
  handleSubmit
}: ChecklistTemplateItemsEditProps) => {
  const s = useStyles(defaultStyles);

  const formId = 'checklist';

  const values = { checklist_template_items: data };

  const createChecklistItemAction = useCreateChecklistItemAction(taskType);

  const fieldArrayName = 'checklist_template_items';

  return (
    <ReactForms<ChecklistTemplatesForm, any>
      handleSubmit={handleSubmit}
      initialValues={values}
    >
      {({ values, setFieldValue }) => {
        return (
          <Form name={formId}>
            <FieldArray
              name={fieldArrayName}
              render={(props) => {
                return (
                  <>
                    <ChecklistTemplateItemRows
                      {...props}
                      values={values as ChecklistTemplatesForm}
                      taskType={taskType}
                      fieldArrayProps={props}
                      setFieldValue={setFieldValue}
                    />
                    <Box {...s('container')}>
                      <OutlineButton
                        {...s('button')}
                        onClick={() =>
                          invokeActionDeclaration(createChecklistItemAction, {
                            fieldArrayProps: props
                          })
                        }
                        IconLeft={CheckCircleIcon}
                      >
                        Add subtask
                      </OutlineButton>
                    </Box>
                  </>
                );
              }}
            />
            <HiddenField name='delete_ids' />
          </Form>
        );
      }}
    </ReactForms>
  );
};
