import { DialogProps, useDialog } from '@rexlabs/dialog';
import * as React from 'react';
import { RecordDialog } from 'view/components/record-screen/dialog/dialog';
import { smsPreviewBlock } from '../blocks/sms-preview-block';
import { mapMessageToEditFormData } from '../mappers/map-message-to-edit-form-data';
import { Message } from '../types/Message';
import { EditSMSRecordDialog } from './edit-sms-record-dialog';

export interface PreviewSMSRecordDialogProps extends DialogProps {
  data: {
    message: Message;
  };
  onSave?: () => void;
  hasButtonGroupActions?: boolean;
}

const content = [
  {
    id: 'preview-sms-record',
    label: 'Preview SMS',
    blocks: [smsPreviewBlock]
  }
];

export function PreviewSMSRecordDialog({
  hasButtonGroupActions,
  onClose,
  onSave,
  data
}: PreviewSMSRecordDialogProps) {
  const { open: openEditDialog } = useDialog(EditSMSRecordDialog);
  const handleSubmit = async () => {
    openEditDialog({
      hasButtonGroupActions,
      data: {
        message: data.message
      },
      onSave
    });

    onClose?.();
  };

  const initialValues = mapMessageToEditFormData({
    message: data.message,
    type: 'sms'
  });

  return (
    <RecordDialog
      title='Preview message'
      initialValues={initialValues}
      content={content}
      onClose={onClose}
      handleSubmit={handleSubmit}
      submitLabel='Edit SMS'
    />
  );
}
