import React from 'react';

import { noteTableBlock } from 'src/modules/note/blocks/note-table-block';

import { FLAGS } from 'utils/feature-flags';

import { NoteCountBadge } from 'src/modules/note/components/note-count-badge';

import { useFeatureFlags } from 'view/components/@luna/feature-flags';
import { DialogContentConfig } from 'view/components/record-screen/dialog/types';

import { transactionDetailsBlock } from '../blocks/transaction-details';
import { transactionMatchDetailsBlock } from '../blocks/transaction-match-details-block';
import { BatchReceiptingItem } from '../types';

export function useMatchDialogContent(
  batchReceiptingItem: BatchReceiptingItem,
  isEdit = false
): DialogContentConfig {
  const { hasFeature } = useFeatureFlags();

  return [
    {
      id: isEdit ? 'edit-match' : 'suggest-match',
      label: isEdit ? 'Edit match' : 'Suggest match',
      blocks: [transactionMatchDetailsBlock, transactionDetailsBlock]
    },
    ...(hasFeature(FLAGS.BATCH_RECEIPTING_SUSPENSION_FLOW)
      ? [
          {
            id: 'notes',
            label: 'Notes',
            IconEnd: (props) => (
              <NoteCountBadge
                filter={[
                  {
                    field: 'bank_statement_transaction_id',
                    op: 'eq',
                    value: batchReceiptingItem.id
                  }
                ]}
                {...props}
              />
            ),
            blocks: [noteTableBlock]
          }
        ]
      : [])
  ];
}
