import React from 'react';

import Box from '@rexlabs/box';
import { Grid } from 'view/components/@luna/form/grid';
import { StyleSheet, useStyles, useToken } from '@rexlabs/styling';
import { Tag } from '@rexlabs/tag';
import AttachmentIcon from 'view/components/icons/attachment';
import { FroalaReadOnly } from 'view/components/@luna/inputs/froala-editor/components/froala-read-only';
import { AlfredFile } from 'src/modules/common/types/file';
import { useResolveAttachments } from '../hooks/use-resolve-attachments';
import { createAttachmentFileName } from '../utils/create-attachment-file-name';

const defaultStyles = StyleSheet({
  icon: {
    width: '4rem',
    height: '4rem',
    borderRadius: ({ token }) => token('border.radius.m'),
    background: ({ token }) => token('color.container.static.contrast'),
    marginRight: ({ token }) => token('spacing.s')
  },

  flex1: {
    flex: 1
  },

  container: {
    border: '1px solid #e7e8e9',
    borderRadius: ({ token }) => token('border.radius.l')
  },

  contentSection: {
    borderTop: '1px solid #e7e8e9',
    padding: ({ token }) => token('spacing.xl'),
    whitespace: 'pre-line'
  },

  addressSection: {
    padding: ({ token }) => token('spacing.xl'),
    marginLeft: ({ token }) => token('spacing.xl'),
    whitespace: 'pre-line'
  },

  mergeTagSidebar: {
    borderRadius: ({ token }) => token('border.radius.l'),
    overflow: 'hidden'
  }
});

export interface LetterPreviewProps {
  heading?: string;

  attachedFiles?: AlfredFile[] | Array<Promise<{ data: AlfredFile }>>;

  bodyContent: string;

  address: string;

  actionButtonSlot?: React.ReactNode;
}

export function LetterPreview({
  attachedFiles,
  bodyContent,
  address,
  actionButtonSlot
}: LetterPreviewProps) {
  const s = useStyles(defaultStyles);
  const token = useToken();

  const resolveAttachedFiles = useResolveAttachments(attachedFiles);

  return (
    <Box {...s('container')}>
      <Grid columns={1}>
        <Box display='flex' flexDirection='row' justifyContent='space-between'>
          <Box>{actionButtonSlot}</Box>
        </Box>

        <Box {...s('addressSection')}>
          {address.split('\n').map((line, index) => (
            <Box key={index}>{line}</Box>
          ))}
        </Box>

        <Box {...s('contentSection')}>
          <FroalaReadOnly value={bodyContent} />
        </Box>

        {attachedFiles && (
          <Box
            data-testId='attached-files'
            display='flex'
            flexDirection='row'
            gap={token('spacing.xs')}
            style={{ borderTop: '1px solid #E7E8E9' }}
            padding={token('spacing.m')}
            flexWrap='wrap'
          >
            {resolveAttachedFiles.map((attachment, index) => (
              <Tag Icon={() => <AttachmentIcon size='s' />} key={index}>
                <span>
                  {createAttachmentFileName({ fileName: attachment.name })}
                </span>
              </Tag>
            ))}
          </Box>
        )}
      </Grid>
    </Box>
  );
}
