import { ActionDeclaration } from 'src/modules/common/actions/types/action-declaration-types';
import { useErrorDialog } from '@rexlabs/dialog';
import { useModelActions } from '@rexlabs/model-generator';
import { MessageBatch } from '../../types/MessageBatch';
import { messageBatchesModel } from '../../models/message-batches-model';

export function useGetMarkAsNotDownloadedLetterAction() {
  const { open } = useErrorDialog();
  const { markItemAsNotDownloaded, refreshLists } = useModelActions(
    messageBatchesModel
  );

  return (messageBatch: MessageBatch): ActionDeclaration => {
    return {
      id: `unmark-as-downloaded`,
      group: 'download',
      label: 'Return to ready',
      handleAction: async () => {
        try {
          await markItemAsNotDownloaded({ messageBatch });
          return refreshLists();
        } catch (e) {
          open(e);
        }
      }
    };
  };
}
