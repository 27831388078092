export const ARREARS_ROUTES = {
  ARREARS_LIST: {
    config: {
      path: '/rent-arrears-tasks',
      Component: require('./screens/arrears-list.tsx').ArrearsList
    }
  },
  ARREARS_DETAILS: {
    config: {
      path: '/rent-arrears-task/:arrearsId',
      Component: require('./screens/arrears-details.tsx').ArrearsDetails
    }
  }
};
