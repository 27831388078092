import React from 'react';
import Box from '@rexlabs/box';
import { useStyles, StyleSheet } from '@rexlabs/styling';

import { StatBlock, StatBlockProps } from './stat-block';

const defaultStyles = StyleSheet({
  container: {
    overflow: 'auto',
    padding: '24px',
    margin: '-24px',

    gap: ({ token }) => token('spacing.xl')
  }
});

export type Stat = StatBlockProps & {
  id?: string;
};

export interface StatBlocksProps {
  stats: Stat[];
  shouldWrap?: boolean;
}

export function StatBlocks({ stats, shouldWrap = false }: StatBlocksProps) {
  const s = useStyles(defaultStyles);
  return (
    <Box
      {...s('container')}
      flexDirection='row'
      flexWrap={shouldWrap ? 'wrap' : 'nowrap'}
    >
      {stats.map(({ id, ...stat }, index) => (
        <StatBlock key={id || index} {...stat} />
      ))}
    </Box>
  );
}
