import React from 'react';
import CalendarIcon from 'view/components/icons/calendar';
import {
  ChecklistTemplateItemProps,
  ChecklistTemplateItemTag
} from '../../components/smart-checklists/checklist-template-item';
import { ChecklistTemplateItem } from '../../types/ChecklistTemplate';
import { getChecklistTemplateItemSubtext as getDueDateTagLabel } from '../../utils/get-checklist-template-item-subtext';
import { getTriggerDescription } from '../../utils/get-trigger-description';
import { getTriggerHeader } from '../../utils/get-trigger-header';

/**
 * This hook should resolve a ChecklistTemplateItem to the props needed to render a ChecklistTemplateItem component.
 */
export function useChecklistTemplateItemState(
  item: ChecklistTemplateItem,
  // currently unused, but will be needed soon
  _otherItems: ChecklistTemplateItem[]
): Omit<ChecklistTemplateItemProps, 'editMode' | 'renderDragHandle'> {
  const triggerDescription = getTriggerDescription(item);
  const subtext = `${getTriggerHeader(
    item
  )} ${triggerDescription}`.toLowerCase();
  const sentenceCaseSubtext =
    subtext.charAt(0).toUpperCase() + subtext.slice(1);
  const hasMetadata = triggerDescription !== '';
  // Todo: this should probably be a real icon eventually, but text works fine for now.
  const SubtextIcon = hasMetadata ? () => <span>✨</span> : undefined;

  return {
    title: item.label,
    item: item,
    hidePipe: !hasMetadata,
    subtext: hasMetadata ? sentenceCaseSubtext : undefined,
    SubtextIcon,
    tags: getTagsForItem(item)
  };
}

function getTagsForItem(
  item: ChecklistTemplateItem
): ChecklistTemplateItemTag[] {
  const dueDateTag: ChecklistTemplateItemTag | null = [
    'related_date',
    'offset_related_date'
  ].includes(item.due_date_type?.id ?? '')
    ? {
        label: getDueDateTagLabel(item) ?? '',
        Icon: CalendarIcon
      }
    : null;

  return [dueDateTag].filter(Boolean) as ChecklistTemplateItemTag[];
}
