import React, { ComponentType } from 'react';
import { StyleSheet, useStyles } from '@rexlabs/styling';
import Tooltip from '@rexlabs/tooltip';
import { MobileCellWrapper } from 'src/modules/common/components/cells/mobile-cell-wrapper';

const defaultStyles = StyleSheet({
  iconContainer: {
    width: 20,
    height: 20,
    display: 'flex',
    justifyContent: 'center'
  }
});

interface IconCellParams {
  onClick: () => void;
  Icon: ComponentType;
  tooltipLabel?: string;
  'data-testid'?: string;
}

export function MobileIconActionCell({
  Icon,
  onClick,
  tooltipLabel,
  ...props
}: IconCellParams) {
  const s = useStyles(defaultStyles);

  return (
    <MobileCellWrapper justifyContent='center'>
      {tooltipLabel ? (
        <Tooltip Content={() => tooltipLabel}>
          <div
            {...s('iconContainer')}
            onClick={onClick}
            data-testid={props['data-testid']}
          >
            <Icon />
          </div>
        </Tooltip>
      ) : (
        <div
          {...s('iconContainer')}
          onClick={onClick}
          data-testid={props['data-testid']}
        >
          <Icon />
        </div>
      )}
    </MobileCellWrapper>
  );
}
