import { NormalisedItem, Select, SelectProps, useItems } from '@rexlabs/select';
import { RecordType } from 'data/models/types';
import * as React from 'react';
import {
  getSearchResults,
  SearchResultItem
} from 'utils/api/get-search-results';

import {
  usePropertyRelatedItems,
  UsePropertyRelatedItemsParams,
  ObjectType
} from 'src/modules/tasks/common/hooks/use-property-related-items';

const defaultObjectTypes: ObjectType[] = [
  'contact',
  'property',
  'tenancy',
  'ownership'
];

const getItemsOuter = async (
  term,
  objectTypes: RecordType[] = defaultObjectTypes
) => {
  const searchResults = await getSearchResults(term, {
    objectTypes
  });

  return searchResults;
};

export const normaliser = (item): NormalisedItem => {
  return {
    id: item.id,
    label: item.label,
    type: item.type.id,
    heading: item.type.label
  };
};

export type RelatesToSelectProps = SelectProps<SearchResultItem> &
  Pick<
    UsePropertyRelatedItemsParams,
    'propertyId' | 'objectTypes' | 'tenancyTypes'
  >;

function getExistingValue(value) {
  if (Array.isArray(value)) {
    return value;
  }

  if (value != null) {
    return [value];
  }
}

export function RelatesToSelect(props: RelatesToSelectProps) {
  const {
    propertyId,
    value,
    objectTypes = [],
    tenancyTypes = ['active']
  } = props;

  // Supplying value as an array for our suggested items
  // so that it the current value shows immediately in the edit state
  const existingItems = getExistingValue(value);

  const { loadItems } = usePropertyRelatedItems({
    propertyId,
    tenancyTypes,
    objectTypes,
    existingItems
  });

  const getItems = React.useCallback(
    (term) => getItemsOuter(term, objectTypes),
    [objectTypes]
  );

  const { getSelectProps } = useItems({
    getItems: getItems,
    getSuggestedItems: loadItems
  });

  return <Select {...props} {...getSelectProps()} normaliser={normaliser} />;
}
