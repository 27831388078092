import { Generator } from 'data/models/generator';
import { FileUpload, HasFile } from 'src/modules/common/types/file';
import { ValueListItem } from 'utils/normaliser/value-list-item';
import { ValueListValue } from '../types';

export type AttachedToType =
  | 'contact'
  | 'property'
  | 'ownership'
  | 'tenancy'
  | 'task';

type AttachedTo = {
  type: ValueListValue<AttachedToType>;
  id: string;
  label: string;
};

export type Document = FileUpload &
  HasFile & {
    name: string;
    attached_to: AttachedTo[];
    type: ValueListItem;
  };

export type CreateDocument = {
  name?: string;
  file: {
    id: string;
  };
  attached_to: Omit<AttachedTo, 'label'>[];
};

const config = {
  entities: {
    related: {
      file: {
        include: 'file'
      },
      attached_to: {
        include: 'attached_to'
      }
    }
  }
};

export const documentsModel = new Generator<Document>(
  'documents',
  config
).createEntityModel();
