import { Generator, GeneratorConfig } from 'data/models/generator';
import { AccountingJournalEntryLineItem } from 'src/modules/account-journal-entries/types/account-journal-entry-types';

const config: GeneratorConfig = {
  entities: {
    related: {
      accounting_journal_entry: {
        include:
          'accounting_journal_entry.source_object{trust_journal_entry},accounting_journal_entry.created_by,accounting_journal_entry.object'
      },
      chart_of_accounts_account: {
        include: 'chart_of_accounts_account'
      }
    }
  }
};

const actionCreators = {};

export const accountingJournalEntryLineItemModel = new Generator<AccountingJournalEntryLineItem>(
  'financials/accounting-journal-entry-line-items',
  config
).createEntityModel({
  actionCreators
});
