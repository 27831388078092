import React from 'react';
import DragIcon from 'view/components/icons/drag';

export function DragHandle() {
  return (
    <div
      style={{
        width: 20,
        height: 20,
        lineHeight: 0,
        appearance: 'none',
        padding: 0,
        margin: 0,
        border: 'none',
        background: 'transparent'
      }}
    >
      <DragIcon />
    </div>
  );
}
