import React from 'react';
import { ConfirmationDialog } from '@rexlabs/dialog';

interface PaidToDateChangeWarningProps {
  onConfirm: () => void;
  onClose?: () => void;
  onReject?: () => void;
}

export function PaidToDateChangeWarning({
  onConfirm,
  onClose,
  onReject
}: PaidToDateChangeWarningProps) {
  return (
    <ConfirmationDialog
      onClose={() => {
        onReject?.();
        onClose?.();
      }}
      onConfirm={onConfirm}
      confirmText="Yes, I'm sure"
      title='Are you sure?'
      message={
        <>
          After these changes, the tenancy’s new paid to date will change. A
          rent ledger entry will be added to show this change.
        </>
      }
    />
  );
}
