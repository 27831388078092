import { useCallback } from 'react';

import { useDialog } from '@rexlabs/dialog';

import { SingleActionDeclaration } from 'src/modules/common/actions/types/action-declaration-types';
import { CreateEmailRecordDialog } from 'src/modules/communications/messages/dialogs/create-email-record-dialog';
import { useMessageTriggerSettings } from 'src/modules/communications/messages/settings/message-triggers/hooks/use-message-trigger-settings';
import { getSearchResultItemFromObjectAndModel } from 'src/modules/common/utils/search-result-items/get-search-result-items-from-object-and-model';
import { fetchTemplate } from 'src/modules/communications/message-templates/utils/fetch-template';

import { useFeatureFlags } from 'view/components/@luna/feature-flags';

import { FLAGS } from 'utils/feature-flags';

import { getRecipientsFromTrustJournalEntry } from 'src/modules/trust-journal-entries/utils/trust-journal-entry-action/get-recipients-from-trust-journal-entry';
import { trustJournalEntryModel } from '../../models/trust-journal-entries';
import { TrustJournalEntry } from '../../types/trust-journal-entry-types';

export function useGetSendTrustJournalEntryReceiptAction() {
  const { open: openCreateEmailDialog } = useDialog(CreateEmailRecordDialog);
  const { messageTriggerSettings } = useMessageTriggerSettings();
  const { hasFeature } = useFeatureFlags();

  return useCallback(
    (
      trustJournalEntry?: TrustJournalEntry
    ): SingleActionDeclaration | undefined => {
      if (trustJournalEntry?.type.id !== 'receipt') {
        return;
      }

      if (!hasFeature(FLAGS.MESSAGE_ATTACHMENTS)) {
        return;
      }

      return {
        id: 'send-tje-payment-receipt',
        intent: 'primary',
        group: 'report',
        label: 'Send payment receipt',
        handleAction: async () => {
          const trustJournalEntrySearchItem = getSearchResultItemFromObjectAndModel<TrustJournalEntry>(
            trustJournalEntry,
            trustJournalEntryModel
          );

          const defaultTemplateId =
            messageTriggerSettings['contact-payment-receipt-template']
              ?.template_id;

          const template = await fetchTemplate(defaultTemplateId);

          openCreateEmailDialog({
            data: {
              relates_to: trustJournalEntrySearchItem,
              content: template?.channels?.[0]?.plain_text_body,
              subject: template?.channels?.[0]?.email_subject,
              attachments: [],
              template: template,
              recipients: getRecipientsFromTrustJournalEntry(trustJournalEntry)
            }
          });
        }
      };
    },
    [messageTriggerSettings, openCreateEmailDialog]
  );
}
