import React from 'react';

import { BlockConfig } from 'view/components/record-screen/types';
import { Field } from '@rexlabs/form';
import { Checkbox } from '@rexlabs/checkbox';
import { Bold } from '@rexlabs/text';
import { BulkTransferEstimation } from '../types/bulk-transfer-estimation';

export const bulkTransferInvoicesBlock: BlockConfig<BulkTransferEstimation> = {
  id: 'details',
  Edit: ({ data, values }) => {
    return (
      <>
        <p>
          Please confirm that you want to transfer & pay{' '}
          <Bold>
            {values.allow_partial_payment
              ? (data?.paid_in_full || 0) + (data?.paid_partially || 0)
              : data?.paid_in_full}{' '}
            invoices
          </Bold>{' '}
          as per below.
        </p>

        <p>
          <div>
            Paid in full: <Bold>{data?.paid_in_full} invoices</Bold>
          </div>
          {values.allow_partial_payment ? (
            <div>
              Partially paid: <Bold>{data?.paid_partially} invoices</Bold>
            </div>
          ) : null}
          <div>
            Unpaid: <Bold>{data?.unpaid} invoices</Bold>
          </div>
        </p>
        <Field
          optional={false}
          Input={Checkbox}
          name={'allow_partial_payment'}
          inputProps={{ label: 'Allow partial payment of invoices' }}
        />

        <p>
          To understand how invoices/bills are prioritised when paying from a
          folio, read the help article{' '}
          <a
            href='https://rexpm.zendesk.com/hc/en-au/articles/29236267118745-Invoice-Detials'
            target='_blank'
            rel='noopener noreferrer nofollow'
          >
            here
          </a>
          .
        </p>
      </>
    );
  }
};
