import { Coordinates } from 'src/lib/mapbox/types/Coordinates';
import { Property } from 'src/modules/properties/types/property-types';

export function getLngLatFromProperty(property?: Property): Coordinates | null {
  const { address } = property || {};
  const { latitude, longitude } = address || {};
  return !latitude || !longitude
    ? null
    : ([parseFloat(longitude), parseFloat(latitude)] as Coordinates);
}
