import * as React from 'react';

import { DialogProps } from '@rexlabs/dialog';
import { useModelActions } from '@rexlabs/model-generator';

import { useRecordCreatedToast } from 'src/modules/common/toasts/hooks/use-record-created-toast';
import { inspectionDateBlock } from 'src/modules/tasks/inspections/blocks/inspection-date-block';

import { CreateRecordDialog } from 'view/components/dialogs/create-record-dialog/create-record-dialog';
import { RecordSubmitHandler } from 'view/components/record-screen/utils';

import { Forms } from '@rexlabs/form';
import { getTaskIncludes } from 'src/modules/tasks/common/utils/get-task-includes';
import { inspectionDetailsBlock } from '../blocks/inspection-details-block';
import { tasksModel } from '../../common/models/tasks-model';
import { taskPriorityBlock } from '../../common/blocks/task-priority-block';
import { checklistTemplateSelectBlock } from '../../common/blocks/checklist-template-select-block';
import { mapInspectionFormDataToInspectionCreateRequest } from '../mappers/map-inspection-form-data-to-inspection-create-request';
import { InspectionFormData } from '../types/InspectionFormData';
import { createTaskAndLinkChecklists } from '../../common/utils/create-task-and-link-checklists';
import { useTaskTypesWithCustomAddToRecord } from '../../settings/hooks/use-task-types-with-custom-add-to-record';

export interface CreateInspectionRecordDialogProps extends DialogProps {
  initialValues: Partial<InspectionFormData>;
  onCreate?: (data) => void;
  isFixture?: boolean;
}

const useContent = () => {
  const taskTypesWithCustomAddToRecord = useTaskTypesWithCustomAddToRecord();
  return [
    {
      id: 'add-inspection',
      label: 'Add Inspection',
      blocks: [
        inspectionDetailsBlock,
        ...(taskTypesWithCustomAddToRecord.includes('task_inspection')
          ? [checklistTemplateSelectBlock]
          : []),
        taskPriorityBlock,
        inspectionDateBlock
      ]
    }
  ];
};

export function CreateInspectionRecordDialog({
  onClose,
  isFixture,
  initialValues,
  onCreate,
  target
}: CreateInspectionRecordDialogProps) {
  const { refreshLists, refreshItem } = useModelActions(tasksModel);
  const addToast = useRecordCreatedToast('task_inspection');
  const content = useContent();

  const handleSubmit: RecordSubmitHandler<InspectionFormData> = async ({
    values
  }) => {
    const inspection = await createTaskAndLinkChecklists<'task_inspection'>(
      values,
      mapInspectionFormDataToInspectionCreateRequest
    );

    // need this specifically for the inspection type to show the label in the toast
    const { data: inspectionWithDetails } = await refreshItem({
      id: inspection.id,
      args: {
        include: getTaskIncludes('task_inspection')
      }
    });

    onCreate?.(inspectionWithDetails);

    addToast(inspectionWithDetails);

    await refreshLists();

    return inspectionWithDetails;
  };

  return (
    <CreateRecordDialog
      recordType='task_inspection'
      data={initialValues}
      blockProps={{ taskTypeId: 'task_inspection' }}
      content={content}
      onClose={onClose}
      onSubmit={handleSubmit}
      target={target}
      isFixture={isFixture}
      getHandlers={getHandlers}
    />
  );
}

function getHandlers(forms?: Forms) {
  return {
    handleInspectionTypeBlur: () => {
      if (!forms) {
        return;
      }

      const checklistForm = forms['checklist'];
      if (!checklistForm) return;

      checklistForm.setFieldValue?.('checklists', []);
    }
  };
}
