import React from 'react';
import { ArrearsProgressSelect } from '../../arrears/components/progress-select';
import { InspectionProgressSelect } from '../../inspections/components/progress-select';
import { LeaseReviewProgressSelect } from '../../lease-review/components/progress-select';
import { MaintenanceStatusSelect } from '../../maintenance/components/maintenance-status-select';
import { TaskType } from '../types/TaskType';
import { QuoteStatusSelect } from '../../quotes/components/quote-status-select';
import { WorkOrderStatusSelect } from '../../work-orders/components/work-order-status-select';
import { TaskStatusSelect } from './task-status-select';

type Props = {
  taskType: TaskType;
};

/**
 * should return the correct status selected based on the supplied task type.
 */
export function GenericTaskStatusSelect({ taskType: type, ...props }: Props) {
  switch (type) {
    case 'task_maintenance':
      return <MaintenanceStatusSelect {...props} />;
    case 'task_arrears':
      return <ArrearsProgressSelect {...props} />;
    case 'task_inspection':
      return <InspectionProgressSelect {...props} />;
    case 'task_lease_review':
      return <LeaseReviewProgressSelect {...props} />;
    case 'task_work_order':
      return <WorkOrderStatusSelect {...props} />;
    case 'task_quote':
      return <QuoteStatusSelect {...props} />;
    default:
      return <TaskStatusSelect {...props} />;
  }
}
