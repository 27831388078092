import React from 'react';
import { DialogProps } from '@rexlabs/dialog';
import { RecordDialog } from 'view/components/record-screen/dialog/dialog';
import { WorkOrderFormData } from '../types/WorkOrderFormData';
import { workOrderTypeBlock } from '../blocks/work-order-type-block';
import { useSelectWorkOrderTypeSubmitHandler } from '../hooks/use-select-work-order-type-submit-handler';
import { WorkOrderTask } from '../types/WorkOrderTask';
export interface SelectWorkOrderTypeDialogProps extends DialogProps {
  initialValues?: Partial<WorkOrderFormData>;
  onCreate?: (data: WorkOrderTask) => void;
}

export function SelectWorkOrderTypeDialog({
  onClose,
  initialValues,
  onCreate
}: SelectWorkOrderTypeDialogProps) {
  const selectWorkOrderTypeSubmitHandler = useSelectWorkOrderTypeSubmitHandler({
    onCreate
  });

  const content = [
    {
      id: 'issue-work-order',
      label: 'Issue work order',
      blocks: [workOrderTypeBlock]
    }
  ];

  return (
    <RecordDialog
      title='Issue work order'
      size='s'
      content={content}
      onClose={onClose}
      handleSubmit={({ values }) =>
        selectWorkOrderTypeSubmitHandler({ values, initialValues })
      }
      submitLabel={'Continue'}
      initialValues={initialValues}
    />
  );
}
