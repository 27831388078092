import React from 'react';

import { StyleSheet, useStyles, mq } from '@rexlabs/styling';

import { Card } from 'view/components/@luna/card';

const defaultStyles = StyleSheet({
  card: {
    ...mq.styles.combine([
      {
        queries: {
          maxWidth: 'm'
        },
        styles: {
          background: ({ token }) => token('palette.grey.200'),

          paddingTop: ({ token }) => token('spacing.xl'),
          paddingBottom: ({ token }) => token('spacing.xl'),
          paddingLeft: ({ token }) => token('spacing.xs'),
          paddingRight: ({ token }) => token('spacing.xs')
        }
      },
      {
        queries: {
          minWidth: 's'
        },
        styles: {
          padding: ({ token }) => token('spacing.xl')
        }
      }
    ])
  }
});

interface InspectionRunResponsiveCardProps {
  children: React.ReactNode;
}

export function InspectionRunResponsiveCard({
  children
}: InspectionRunResponsiveCardProps) {
  const s = useStyles(defaultStyles);

  return <Card {...s('card')}>{children}</Card>;
}
