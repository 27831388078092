import React from 'react';
import { Reconciliation } from 'data/models/entities/financials/reconciliations';
import LoadingState from 'view/components/states/compact/loading';
import { Summary } from 'view/components/statement';
import { formatDate } from 'utils/dates/format';

import { Column, Grid } from 'view/components/@luna/form/grid';
import { StatBlock } from 'view/components/@luna/stat-block';
import Box from '@rexlabs/box';
import { formatCurrency } from 'utils/formatters';
import {
  useGetBankStatementSummaryEntries,
  getCashbookSummaryEntries
} from '../utils/get-reconciliation-summary-entries';

interface ActiveReconciliationProps {
  isLoading: boolean;
  bankAccountId: string;
  reconciliation?: Reconciliation;
}

export function ActiveReconciliation({
  reconciliation,
  isLoading
}: ActiveReconciliationProps) {
  const getBankStatementSummaryEntries = useGetBankStatementSummaryEntries();

  if (isLoading) {
    return <LoadingState />;
  }

  return (
    <Grid columns={4}>
      <Column width={2}>
        <Summary
          title='Bank statement'
          subTitle={`As at ${formatDate(reconciliation!.statement_date)}`}
          startAmount={reconciliation!.statement_balance}
          items={getBankStatementSummaryEntries(reconciliation!)}
          includeDividingLines
          includePadding
        />
      </Column>
      <Column width={2}>
        <Summary
          title='Cashbook'
          subTitle={`Starting at ${formatDate(reconciliation!.start_date)}`}
          startAmount={
            reconciliation!.reconciliation_summary
              ?.cashbook_opening_balance_amount
          }
          items={getCashbookSummaryEntries(reconciliation!)}
          includeDividingLines
          includePadding
        />
        <Box paddingTop={20}>
          <StatBlock
            label='Trial Balance'
            description={`As at ${formatDate(reconciliation!.statement_date)}`}
            value={formatCurrency(
              reconciliation!.reconciliation_summary
                ?.trust_trial_balance_amount || 0
            )}
            intent='neutral'
            size='l'
          ></StatBlock>
        </Box>
      </Column>
    </Grid>
  );
}
