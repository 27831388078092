import { PrimaryButton } from '@rexlabs/button';
import { useConfirmationDialog, useDialog } from '@rexlabs/dialog';
import Icons from '@rexlabs/icons';
import { useModelActions } from '@rexlabs/model-generator';
import { push } from '@rexlabs/whereabouts';
import { BankAccount } from 'data/models/entities/financials/bank-accounts';
import {
  financialsReconciliationsModel,
  Reconciliation
} from 'data/models/entities/financials/reconciliations';
import { RecordTypes } from 'data/models/types';
import * as React from 'react';
import ROUTES from 'routes/app';
import { formatDate } from 'utils/dates/format';
import { getRecordTitle } from 'utils/records/get-record-title';
import { BannerProps } from '@rexlabs/notifications';

import { useToast } from 'view/components/@luna/notifications/toast';
import { track } from '@rexlabs/analytics';
import { EVENTS } from 'utils/analytics';
import { StartMonthlyReconciliationDialog } from '../../dialogs/start-monthly-reconciliation';

const PERIOD_DATE_FORMAT = 'MMM YYYY';

export const useGetBannerActions = () => {
  const { open: openMonthlyReconciliationDialog } = useDialog(
    StartMonthlyReconciliationDialog
  );

  const { open: openConfirmationDialog } = useConfirmationDialog();

  const { trashItem: abandonReconciliation } = useModelActions(
    financialsReconciliationsModel
  );

  const { addToast } = useToast();

  return (
    activeReconciliation?: Reconciliation,
    bankAccount?: BankAccount
  ): BannerProps['actions'] => {
    const earliestPeriod = bankAccount?.end_of_month_periods_due!.data[0];

    if (!bankAccount || !earliestPeriod) {
      return [];
    }

    // we will have different logic based on whether there is a daily or monthly in progress.
    const typeInProgress = activeReconciliation?.type.id || false;

    const startNewMonthlyReconciliation = () => {
      track({
        eventName: EVENTS.RECONCILIATIONS.MODAL_OPENED,
        data: { reconciliationType: 'end_of_month' }
      });

      openMonthlyReconciliationDialog({
        bankAccountId: bankAccount!.id,
        period: earliestPeriod!,
        onCreate: (reconciliation: Reconciliation) => {
          push(ROUTES.RECONCILIATION, {
            params: { reconciliationId: reconciliation.id }
          });
        }
      });
    };

    const startLabel = `Start ${formatDate(
      earliestPeriod.start_date,
      PERIOD_DATE_FORMAT
    )} reconciliation`;

    if (typeInProgress === false) {
      return [
        {
          label: startLabel,
          onClick: async () => {
            startNewMonthlyReconciliation();
          },
          Button: PrimaryButton
        }
      ];
    }

    if (typeInProgress === 'end_of_month' && activeReconciliation) {
      return [
        {
          label: `Continue ${formatDate(
            activeReconciliation.start_date,
            PERIOD_DATE_FORMAT
          )} reconciliation`,
          onClick: () => {
            track({
              eventName: EVENTS.RECONCILIATIONS.CONTINUED,
              data: { reconciliationType: typeInProgress }
            });

            push(ROUTES.RECONCILIATION, {
              params: { reconciliationId: activeReconciliation.id }
            });
          },
          Button: PrimaryButton
        }
      ];
    }

    // TODO: swap this default action for the typeInProgress === false one?
    if (typeInProgress === 'daily' && activeReconciliation) {
      return [
        {
          label: startLabel,
          onClick: () => {
            openConfirmationDialog({
              title: 'Abandon active reconciliation?',
              TitleIcon: Icons.WarningCircle,
              message:
                'To start a new reconciliation, please confirm you wish to abandon your active daily reconciliation. Any movements and adjustments created during this reconciliation will be deleted.',
              destructive: true,
              confirmText: `Abandon & ${startLabel.toLowerCase()}`,
              onConfirm: async () => {
                await abandonReconciliation({ id: activeReconciliation!.id });

                // TODO: might be nice if we could abstract this and the delete action to share a toast (at least the description, to keep sync)
                await addToast({
                  description: (
                    <>
                      <b>
                        {getRecordTitle({
                          type: RecordTypes.Reconciliation,
                          object: activeReconciliation!
                        })}
                      </b>{' '}
                      has been <b>abandoned</b>
                    </>
                  ),
                  type: 'error'
                });

                startNewMonthlyReconciliation();
              }
            });
          },
          Button: PrimaryButton
        }
      ];
    }
  };
};
