import { Generator } from 'data/models/generator';
import { AlfredFile } from 'src/modules/common/types/file';
import { LetterLayout } from './letter-layouts';

type AvailableChannel = {
  id: 'sms' | 'letter' | 'email';
  label: string;
};

type RichTextBody = {
  type: {
    id: string;
    label: string;
  };
  content: string;
};

/**
 * @deprecated
 */
export type CommunicationTemplate = {
  id: string;
  name: string;
  available_channels: AvailableChannel[];
  context?: {
    id: string;
    label: string;
  };
  email?: {
    subject: string;
    plain_text_body?: string;
    rich_text_body?: RichTextBody;
    layout?: {
      id: string;
      label: string;
    };
  };
  letter?: {
    rich_text_body?: RichTextBody;
    layout?: {
      id: string;
      name: string;
    };
  };
  letter_layout?: LetterLayout;
  sms?: {
    plain_text_body?: string;
  };
  linked_files?: AlfredFile[];
};

const config = {
  entities: {
    related: {
      email: {
        include: 'email'
      },
      letter: {
        include: 'letter' // .print_offsets,letter.letterhead_first_page_file,letter.letterhead_subsequent_pages_file'
      },
      letter_layout: {
        include:
          'letter_layout.print_offsets,letter_layout.print_offsets.unit,letter_layout.letterhead_first_page_file,letter_layout.letterhead_subsequent_pages_file'
      },
      sms: {
        include: 'sms'
      },
      linked_files: {
        include: 'linked_files'
      }
    }
  }
};

export const communicationTemplatesModel = new Generator<CommunicationTemplate>(
  'communication/templates',
  config
).createEntityModel();
