import { Columns } from 'view/components/table';
import { ChecklistItem } from '../types/Checklist';
import { TaskTypeIconCell } from '../components/task-type-icon-cell';
import { SummaryCell } from '../components/summary-cell';
import { AnyTaskProgressCell } from '../components/any-task-progress-cell';

export const subtaskDueColumns: Columns<ChecklistItem> = [
  {
    id: 'type_icon',
    Header: '',
    accessor: (item) => item.checklist?.task?.type,
    Cell: TaskTypeIconCell,
    width: 76,
    toCsv: (item) => item.checklist?.task?.type?.label
  },
  {
    id: 'subtask',
    Header: 'Subtask',
    accessor: (item) => item.label
  },
  {
    id: 'due_date',
    Header: 'Due date',
    type: 'date',
    accessor: (item) => item.due_date,
    width: 200
  },
  {
    id: 'parent-task',
    Header: 'Parent task',
    accessor: (item) => item.checklist?.task,
    Cell: SummaryCell,
    toCsv: (item) => item.checklist?.task?.summary,
    width: 380
  },
  {
    id: 'parent-task-due-date',
    Header: 'Parent task due date',
    type: 'date',
    accessor: (item) => item.checklist?.task?.due_date,
    width: 200
  },
  {
    id: 'parent-task-progress',
    Header: 'Parent task progress',
    Cell: AnyTaskProgressCell,
    width: 200,
    accessor: (item) => item.checklist?.task,
    toCsv: (item) => item.checklist?.task?.status?.label
  }
];
