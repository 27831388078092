import React from 'react';

import { Property } from 'src/modules/properties/types/property-types';

import { MessageIssue } from '../../common/types/message-issue';

import { useComplianceEntryMessageIssueSubmitHandler } from '../hooks/use-compliance-entry-message-issue-submit-handler';
import { addComplianceEntryToARecordBlock } from '../blocks/add-compliance-entry-to-record-block';
import { MessageIssueDisclosureField } from './message-issue-disclosure-field';

export function ComplianceEntryMessageIssueDisclosureField({
  issue,
  fieldKey,
  setFieldValue
}: {
  fieldKey: string;
  issue: MessageIssue;
  setFieldValue?: (key, value) => void;
}) {
  const complianceEntryMessageIssueSubmitHandler = useComplianceEntryMessageIssueSubmitHandler(
    issue
  );

  return (
    <MessageIssueDisclosureField
      block={addComplianceEntryToARecordBlock}
      issueRelatesTo={issue.message!.context as Property}
      issueRelatesToType={issue.message?.context.__record_type || ''}
      issueText={issue.issue.label}
      fieldKey={fieldKey}
      updateText={() => (
        <div>
          Upload a compliance entry of type{' '}
          <b>{issue!.missing_compliance_type!.label}</b> to this record.
        </div>
      )}
      setValue={(value) => {
        setFieldValue?.(fieldKey, value);
      }}
      onSubmit={complianceEntryMessageIssueSubmitHandler}
    />
  );
}
