import { ActionDeclaration } from 'src/modules/common/actions/types/action-declaration-types';
import { Property } from 'src/modules/properties/types/property-types';
import { useDialog } from '@rexlabs/dialog';
import { Portfolio } from '../../models/portfolios-model';
import { RemovePropertyFromPortfolioDialog } from '../../dialogs/remove-property-from-portfolio-dialog';

export function useGetRemovePropertyFromPortfolioAction({
  portfolio
}: {
  portfolio: Portfolio;
}) {
  const { open } = useDialog(RemovePropertyFromPortfolioDialog);

  return (property: Property): ActionDeclaration => {
    return {
      id: 'remove-property-from-portfolio',
      label: 'Remove from portfolio',
      intent: 'danger',
      handleAction: () => {
        open({ property, portfolioId: portfolio.id });
      }
    };
  };
}
