import { useEffect, useState } from 'react';

type GetData<DataType> = () => Promise<DataType>;

export function useApi<DataType>(
  getData: GetData<DataType>,
  dependencies: any[]
) {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState<DataType | undefined>(undefined);

  useEffect(() => {
    setIsLoading(true);
    getData().then((data) => {
      setData(data);
      setIsLoading(false);
    });
  }, dependencies);

  return {
    isLoading,
    data
  };
}
