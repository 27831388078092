import { Columns, ListTable, TabbedTable } from 'view/components/table';
import React from 'react';
import { query } from '@rexlabs/model-generator';
import {
  financialsReconciliationsModel,
  Reconciliation
} from 'data/models/entities/financials/reconciliations';
import ROUTES from 'routes/app';
import { useTableFilters } from 'view/hooks/use-table-filters';
import { TagCell } from '@rexlabs/table';
import { StatusTag } from 'src/modules/common/components/status-tag';
import { useReconciliationActions } from 'src/modules/reconciliations/actions/use-reconciliation-actions';
import { transformActionDeclarationsToActionMenuItems } from 'utils/actions/transforms';
import { Tab } from 'view/components/table/tabbed';
import { reconciliationStatusMap } from '../model/reconciliation-model';
import { CompletedByCell } from './completed-by-cell';
import { PeriodCell } from './period-cell';
import { VerifiedByCell } from './verified-by-cell';

const getReconciliationsQuery = () => query`{
  ${financialsReconciliationsModel} {
    id
    status
    reconciled_at
    start_date
    statement_date
    created_by {
      contact
    }
    verified_by {
      contact
    }
    reconciled_by {
      contact
    }
    reconciliation_summary
  }
}`;

const reconciliationColumns: Columns<Reconciliation> = [
  {
    id: 'statement_balance',
    type: 'currency',
    cellProps: {
      align: 'right'
    },
    Header: 'Closing balance'
  },
  {
    id: 'reconciliation_summary.adjusted_bank_balance_amount',
    accessor: (item) =>
      item.reconciliation_summary.adjusted_bank_balance_amount,
    Header: 'Adjustments',
    type: 'currency',
    cellProps: {
      align: 'right'
    }
  },
  {
    id: 'status_id',
    accessor: (item) => item.status,
    Header: 'Status',
    Cell: (table) => {
      const {
        cell: { value }
      } = table;
      return (
        <TagCell>
          <StatusTag status={value} map={reconciliationStatusMap} />
        </TagCell>
      );
    }
  }
];

const dailyReconciliationsColumns: Columns = [
  {
    id: 'record_reference',
    Header: 'Reference'
  },
  {
    id: 'reconciled_at',
    type: 'date',
    Header: 'Reconciliation date'
  },
  {
    id: 'start_date',
    type: 'date',
    Header: 'Statement start date'
  },
  ...reconciliationColumns,
  {
    id: 'statement_date',
    type: 'date',
    Header: 'Statement end date'
  },
  {
    id: 'created_by',
    type: 'user',
    Header: 'Created by'
  },
  {
    id: 'created_at',
    type: 'date',
    Header: 'Created at'
  },
  {
    id: 'updated_at',
    type: 'date',
    Header: 'Updated at'
  }
];

const monthlyReconciliationColumns: Columns = [
  { Header: 'Period', Cell: PeriodCell, accessor: (item) => item },
  { Header: 'Completed by', Cell: CompletedByCell, accessor: (item) => item },
  { Header: 'Verified by', Cell: VerifiedByCell, accessor: (item) => item },
  ...reconciliationColumns
];

export interface ReconciliationsTableProps {
  bankAccountId: string;
}

function getRowLinkProps({ item }) {
  return {
    to: ROUTES.RECONCILIATION,
    params: { reconciliationId: item.id }
  };
}

function getGlobalFilter(bankAccountId, reconciliationType) {
  return [
    {
      field: 'bank_account_id',
      op: 'eq',
      value: bankAccountId
    },
    {
      field: 'type_id',
      op: 'eq',
      value: reconciliationType
    }
  ];
}

const initialGlobalFilter = [
  {
    field: 'status_id',
    op: 'in',
    value: ['reconciled', 'verified']
  }
];

export function ReconciliationsTable({
  bankAccountId
}: ReconciliationsTableProps) {
  const getReconciliationActions = useReconciliationActions('list');
  const getActionMenuItems = ({ item }) =>
    transformActionDeclarationsToActionMenuItems(
      getReconciliationActions(item)
    );

  const { getSort, getFilters } = useTableFilters('reconciliations');

  const common = React.useMemo(
    () => ({
      initialGlobalFilter,
      getFilters,
      getSort,

      initialSortBy: [{ id: 'created_at', desc: true }],
      getRowLinkProps,
      id: 'reconciliations',
      getQuery: getReconciliationsQuery,
      getActionMenuItems: getActionMenuItems,
      Table: ListTable
    }),
    // specifically excluding getActionMenuItems from the dependency array as it seems to cause an infinite loop of rerenders currently
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [getFilters, getSort]
  );

  const tabs: Tab[] = React.useMemo(
    () => [
      {
        ...common,
        columns: dailyReconciliationsColumns,
        name: 'daily_reconciliations',
        label: 'Daily reconciliations',
        forcedGlobalFilter: getGlobalFilter(bankAccountId, 'daily'),
        initialHiddenColumns: [
          'status',
          'statement_date',
          'created_by',
          'created_at',
          'updated_at'
        ]
      },
      {
        ...common,
        columns: monthlyReconciliationColumns,
        name: 'monthly_reconciliations',
        label: 'Monthly reconciliations',
        forcedGlobalFilter: getGlobalFilter(bankAccountId, 'end_of_month'),
        suggestedFilters: ['statement_date']
      }
    ],
    [bankAccountId, common]
  );

  return <TabbedTable tabs={tabs} />;
}
