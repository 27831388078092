import * as React from 'react';
import { Card } from 'view/components/card';
import { ListScreen } from 'view/components/list-screen/list-screen';

import { useGetCreatePrepaymentBucketAction } from 'src/modules/prepayments/hooks/action-declarations/use-get-create-prepayment-bucket-action';
import { InfoBanner } from 'view/components/@luna/notifications/banner';
import InfoCircleIcon from 'view/components/icons/info';
import Box from '@rexlabs/box';
import { useToken } from '@rexlabs/styling';
import { PrepaymentBucketsTable } from 'src/modules/prepayments/components/prepayment-buckets-table';

export function PrepaymentsList() {
  const token = useToken();

  const getCreatePrepaymentBucketAction = useGetCreatePrepaymentBucketAction();

  return (
    <ListScreen
      title='Prepayments'
      actions={[getCreatePrepaymentBucketAction()]}
    >
      <Box mb={token('spacing.xl')}>
        <InfoBanner
          description={'Prepayments is available only for tenancies.'}
          Icon={InfoCircleIcon}
        />
      </Box>
      <Card>
        <PrepaymentBucketsTable />
      </Card>
    </ListScreen>
  );
}
