import React, { createContext, useContext, useMemo, useState } from 'react';

import { noop, uniqueId } from 'lodash';

import { ToastProps } from './core';

type Toast = ToastProps & {
  target?: string;
};

type AddToastArgs = Omit<Toast, 'id' | 'remove'>;

export interface ToastContext {
  addToast: (args: AddToastArgs) => void;
  clearToasts: () => void;
}

export const ToastHandlerContext = createContext<ToastContext>({
  addToast: noop,
  clearToasts: noop
});

export const ToastDataContext = createContext<any>({
  toasts: []
});

export function ToastProvider({ children }) {
  const [toasts, setToasts] = useState<Toast[]>([]);

  const toastHandlers = useMemo<ToastContext>(
    () => ({
      clearToasts: () => setToasts([]),
      addToast: ({ type, duration, target = 'main', clearable, ...props }) => {
        const uuid = uniqueId();

        const newToast = {
          ...props,
          id: uuid,
          type,
          target,
          duration,
          remove: () =>
            setToasts((toasts) => toasts.filter((toast) => toast.id !== uuid)),
          clearable
        };

        setToasts((toasts) => [...toasts, newToast]);
      }
    }),
    []
  );

  return (
    <ToastHandlerContext.Provider value={toastHandlers}>
      <ToastDataContext.Provider value={{ toasts }}>
        {children}
      </ToastDataContext.Provider>
    </ToastHandlerContext.Provider>
  );
}

export function useToast() {
  return useContext(ToastHandlerContext);
}
