import { useQuery } from 'react-query';
import { getValueListItems } from 'utils/api/get-value-list-items';

export function useApiValueList(valueListId: string) {
  return useQuery(
    ['value-list', valueListId],
    () => getValueListItems(valueListId),
    {
      staleTime: Infinity // Values lists are not expected to change at runtime
    }
  );
}
