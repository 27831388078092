import React from 'react';
import { BlockConfig } from 'view/components/record-screen/types';
import { DateValue } from 'view/components/values/date';
import { Value } from 'view/components/values';
import { RecordLinkValue } from 'view/components/values/record-link';
import { BankDeposit } from 'src/modules/banking/bank-deposits/types/bank-deposit-types';
import { Grid } from 'view/components/@luna/form/grid';

export const detailsBlock: BlockConfig<BankDeposit> = {
  id: 'details',
  title: 'Details',
  View: ({ data }) => (
    <Grid columns={3}>
      <DateValue label='Date' value={data?.date_of} />
      <Value label='Type' value={data?.type.label} />
      <RecordLinkValue
        label='Bank account'
        value={data?.bank_account?.name}
        type='bank_account'
        id={data?.bank_account?.id}
      />
      <Value label='Status' value={data?.status.label} />
      {data?.status_reason && (
        <Value label='Reason' value={data?.status_reason} />
      )}
    </Grid>
  )
};
