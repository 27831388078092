import { ActionMenuItem } from '@rexlabs/action-menu';
import { useGetToggleClosedAction } from 'src/modules/tasks/common/hooks/action-declarations/use-get-toggle-closed-action';
import { transformActionDeclarationsToActionMenuItems } from 'utils/actions/transforms';
import { useGetPrimaryRecordActionGroup } from 'view/hooks/actions/use-get-primary-record-action-group';
import { useGetAddDocumentsAction } from 'src/modules/documents/actions/use-get-add-documents-action';
import { useGetAddNotesAction } from 'src/modules/tasks/common/actions/get-add-notes-action';
import { useGetCreateEmailAction } from 'src/modules/communications/messages/hooks/action-declarations/use-get-create-email-action';
import { propertiesModel } from 'data/models/entities/properties';
import { useGetRenewCheckAction } from 'src/modules/tasks/property-compliance/hooks/action-menu-items/use-get-renew-check-action';
import { useGetCreateSMSAction } from 'src/modules/communications/messages/hooks/action-declarations/use-get-create-sms-action';
import { PropertyComplianceTask } from '../../types/PropertyComplianceTask';

export function useGetPropertyComplianceActions() {
  const getPrimaryRecordActionGroup = useGetPrimaryRecordActionGroup();
  const getToggleClosedAction = useGetToggleClosedAction();
  const getAddDocumentsAction = useGetAddDocumentsAction();
  const getAddNotesAction = useGetAddNotesAction();
  const getCreateEmailAction = useGetCreateEmailAction(propertiesModel);
  const getCreateSMSAction = useGetCreateSMSAction(propertiesModel);
  const getRenewCheckAction = useGetRenewCheckAction();

  return (task?: PropertyComplianceTask): ActionMenuItem[] => {
    if (!task) return [];

    const actions = [
      ...getPrimaryRecordActionGroup('task_property_compliance', task.id),
      getToggleClosedAction(task),
      getCreateEmailAction(task.property),
      getCreateSMSAction(task.property),
      getAddDocumentsAction({ data: task }),
      getAddNotesAction({ data: task }),
      getRenewCheckAction(task)
    ];

    return [...transformActionDeclarationsToActionMenuItems(actions)];
  };
}
