import React from 'react';
import Box from '@rexlabs/box';
import { StyleSheet, useStyles, padding, useToken } from '@rexlabs/styling';
import { Bold } from '@rexlabs/text';
import { IconProps } from '@rexlabs/icons';
import ActionMenu, { ActionMenuItem } from '@rexlabs/action-menu';
import { GhostIconButton } from '@rexlabs/button';
import KebabIcon from 'view/components/icons/kebab';

export interface ChecklistTemplateItemProps {
  title: string;
  subtext?: string;
  Icon: React.ComponentType<IconProps>;
  Trigger?: React.ReactElement;
  editMode?: boolean;
  actionMenuItems?: ActionMenuItem[];
}

const defaultStyles = StyleSheet({
  container: {
    borderStyle: 'solid',
    borderWidth: ({ token }) => token('border.width.thin'),
    borderRadius: ({ token }) => token('border.radius.l'),
    borderColor: ({ token }) => token('palette.grey.500'),
    flexDirection: 'column',
    backgroundColor: ({ token }) => token('color.container.static.default')
  },
  subtext: {
    fontSize: ({ token }) => token('typography.size.s'),
    color: ({ token }) => token('color.textStyle.body.subtext'),
    display: 'flex',
    alignItems: 'center',
    columnGap: ({ token }) => token('spacing.xxs')
  },
  title: {
    fontSize: ({ token }) => token('typography.size.l')
  },
  actionMenu: {
    ...padding.styles({
      top: 's',
      right: 's'
    })
  },
  trigger: {
    ...padding.styles({
      right: 'l',
      bottom: 'l',
      left: 'l'
    })
  },
  description: {
    ...padding.styles({
      right: 'l',
      left: 'l',
      top: 'l'
    })
  }
});

export function ChecklistTemplateItem({
  title,
  subtext,
  Icon,
  Trigger,
  editMode = false,
  actionMenuItems
}: ChecklistTemplateItemProps) {
  const s = useStyles(defaultStyles);
  const token = useToken();

  return (
    <Box {...s('container')}>
      <Box flexDirection='row' justifyContent='space-between'>
        <Box
          {...s('description')}
          style={{
            paddingBottom: token(Trigger ? 'spacing.s' : 'spacing.l')
          }}
        >
          <Bold {...s('title')}>{title}</Bold>
          {subtext && (
            <div {...s('subtext')}>
              <Icon width={12} />
              {subtext}
            </div>
          )}
        </Box>
        {editMode && actionMenuItems && (
          <Box {...s('actionMenu')}>
            <ActionMenu
              Button={GhostIconButton}
              Icon={KebabIcon}
              placement='bottom-end'
              items={actionMenuItems}
            />
          </Box>
        )}
      </Box>
      {Trigger && <Box {...s('trigger')}>{Trigger}</Box>}
    </Box>
  );
}
