import React, { useState } from 'react';

import Box from '@rexlabs/box';
import Icons from '@rexlabs/icons';
import {
  StyleSheet,
  useStyles,
  useToken,
  text,
  padding
} from '@rexlabs/styling';
import { Body } from '@rexlabs/text';

const { ChevronRight } = Icons;

const defaultStyles = StyleSheet({
  headline: {
    appearance: 'none',
    border: 'none',
    background: 'none',
    width: '100%',
    outline: 'none',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',

    ...text.styles({
      target: 'accordion.header',
      fallback: 'normal.semibold'
    }),
    ...padding.styles({
      target: 'accordion.header',
      x: 'none',
      y: 'm'
    }),

    '&:focus,&:active': {
      boxShadow: 'none'
    }
  },

  secondary: {
    textTransform: 'uppercase',

    ...text.styles({
      target: 'accordion.secondary',
      fallback: 'small.semibold',
      color: ({ token }) => token('color.textStyle.body.subtext')
    })
  },

  icon: {
    transition: 'transform 100ms ease-out'
  },

  rotate: {
    transform: 'rotate(90deg)'
  }
});

interface AccordionProps {
  headline: React.ReactNode;
  initialOpen?: boolean;
  open?: boolean;
  onChange?: (value: boolean) => void;
  variant?: 'primary' | 'secondary';
  children?: React.ReactNode;
}

export function Accordion(props: AccordionProps) {
  const {
    headline,
    children,
    variant,
    initialOpen = true,
    open,
    onChange
  } = props;

  const s = useStyles(defaultStyles);
  const token = useToken();

  const [isOpen, setIsOpen] = useControlledState(open, onChange, initialOpen);

  const toggleOpen = () => {
    setIsOpen((isOpen) => !isOpen);
  };

  return (
    <div>
      <button
        type='button'
        {...s('headline', { secondary: variant === 'secondary' })}
        onClick={toggleOpen}
      >
        <Box alignItems='center' sx={token('spacing.xs')}>
          <ChevronRight {...s('icon', { rotate: isOpen })} size='m' />
          <Body semibold as='h4'>
            {headline}
          </Body>
        </Box>
      </button>
      {isOpen && (
        <section
          {...s('content', { secondaryContent: variant === 'secondary' })}
        >
          {children}
        </section>
      )}
    </div>
  );
}

//Helpers

function isDefined(variable: any) {
  return typeof variable !== 'undefined';
}

function useControlledState<T>(
  value?: T,
  onChange?: (value: T) => void,
  defaultValue?: T
): [T | undefined, React.Dispatch<React.SetStateAction<T | undefined>>] {
  const [localValue, setLocalValue] = useState(() =>
    isDefined(value) ? value : defaultValue
  );

  React.useEffect(() => {
    if (typeof onChange === 'function' && localValue != undefined) {
      onChange(localValue);
    }
  }, [localValue]);

  return [isDefined(value) ? value : localValue, setLocalValue];
}
