import { useConfirmationDialog, useErrorDialog } from '@rexlabs/dialog';
import { ActionDeclaration } from 'src/modules/common/actions/types/action-declaration-types';

import React from 'react';
import { Body } from '@rexlabs/text';
import { pluralize } from 'utils/formatters';
import { api } from 'utils/api/api-client';
import { useToast } from 'view/components/@luna/notifications/toast';
import { PreviewData } from 'src/modules/properties/types/preview-data';
import { StyleSheet, useStyles } from '@rexlabs/styling';
import { PropertyTenancy } from '../types/property-tenancy-types';

const defaultSTyles = StyleSheet({
  list: {
    listStyleType: 'circle',
    paddingLeft: 12
  }
});

export function useGetOpenRegenerateInvoicesDialogAction() {
  const s = useStyles(defaultSTyles);
  const { addToast } = useToast();
  const errorDialog = useErrorDialog();

  const handleConfirm = React.useCallback(
    async (propertyTenancyId) => {
      try {
        await api.post(
          `/property-tenancies/${propertyTenancyId}/regenerate-invoices`
        );
        return addToast({
          description: 'Invoices have been successfully regenerated.'
        });
      } catch (error) {
        errorDialog.open(error);
      }
    },
    [addToast]
  );

  const { open: openRegenerateInvoicesDialog } = useConfirmationDialog({
    title: 'Regenerate invoices',
    confirmText: 'Yes, regenerate invoices'
  });

  return (propertyTenancy: PropertyTenancy): ActionDeclaration => ({
    id: 'regenerate-invoices',
    group: 'invoices',
    label: 'Regenerate invoices',
    handleAction: async () => {
      try {
        // First, check the preview endpoint to see what operations need to be done.
        const { data: previewData } = await api.get<PreviewData>(
          `/property-tenancies/${propertyTenancy.id}/regenerate-invoices`
        );

        const { invoicesToCancel, invoicesToCreate } = previewData;

        // If the preview endpoint doesn't return any operations, there is no point regenerating invoices
        if (invoicesToCancel === 0 && invoicesToCreate === 0) {
          return addToast({
            description: 'No invoices need regeneration.'
          });
        }

        // Otherwise, give them the confirmation dialog to make sure they want to regenerate invoices
        return openRegenerateInvoicesDialog({
          message: (
            <>
              <Body>
                The following changes will be made to the rent invoices for{' '}
                {propertyTenancy.display_name}
              </Body>
              <Body>
                <ul {...s('list')}>
                  <li>
                    {invoicesToCancel} {pluralize('invoice', invoicesToCancel)}{' '}
                    will be cancelled
                  </li>
                  <li>
                    {invoicesToCreate} {pluralize('invoice', invoicesToCreate)}{' '}
                    will be created
                  </li>
                </ul>
              </Body>
              <Body>
                Invoices that have already had the complete balance paid will
                not be regenerated.
              </Body>
              <Body>Do you want to continue?</Body>
            </>
          ),
          onConfirm: () => handleConfirm(propertyTenancy.id)
        });
      } catch (error) {
        errorDialog.open(error);
      }
    }
  });
}
