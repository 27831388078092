import React, { useEffect } from 'react';
import { Field } from '@rexlabs/form';
import { TextInput } from '@rexlabs/text-input';

import { BlockConfig } from 'view/components/record-screen/types';
import { SecurityDepositTypes } from 'view/components/inputs/selects/security-deposit-types';
import { SecurityDepositTypeHeldBy } from 'data/models/entities/financials/security-deposit-types';
import { CentAmountInput } from 'view/components/inputs/cent-amount';
import { BankAccountSelect } from 'src/modules/bank-accounts/components/bank-account-select';
import { ChartOfAccountsAccountSelect } from 'view/components/inputs/selects/v4-selects/chart-of-accounts-account';
import { ContactPaymentMethod } from 'view/components/inputs/selects/contact-payment-method';
import { HorizontalDivider } from 'view/components/ui/horizontal-divider';
import { Column, Grid } from 'view/components/@luna/form/grid';

import { ContactSelect } from 'view/components/inputs/selects/contact';
import { SecurityDepositTypeAmountType } from 'view/components/inputs/selects/security-deposit-type-amount-type';
import Box from '@rexlabs/box';
import { WeeksInput } from 'view/components/inputs/weeks-input';
import { CenterField } from 'view/components/@luna/center-field/center-field';
import { Ownership } from 'data/models/entities/ownerships';
import { useTranslation } from 'react-i18next';
import { SecurityDepositSummary } from '../components/security-deposit-summary';

export const securityDepositBlock: BlockConfig = {
  id: 'securityDeposit',
  title: 'Security deposit',
  validate: {
    definitions: {
      security_deposit_type: {
        rules: 'required',
        name: 'security deposit type'
      },
      amount_type: {
        rules: 'required',
        name: 'amount type'
      },
      amount_expected: {
        name: 'amount',
        rules: 'required_if:amount_type.id,amount'
      },
      amount_expected_as_weeks_of_rent: {
        name: 'weeks of rent',
        rules: 'required_if:amount_type.id,weeks_of_rent'
      },
      held_by_ownership_chart_of_accounts_account: {
        rules: 'required_if:security_deposit_type.held_by.id,held_by_ownership',
        name: 'Ownership account code'
      },
      held_by_ownership: {
        rules: 'required_if:security_deposit_type.held_by.id,held_by_ownership',
        name: 'Ownership'
      },
      held_by_ownership_contact_payment_method: {
        rules: 'required_if:security_deposit_type.held_by.id,held_by_ownership',
        name: 'Payment method'
      }
    },
    messages: {
      required_if: 'The :attribute field is required.'
    }
  },
  Edit: function SecurityDepositEdit({ values, setFieldValue }) {
    const { t } = useTranslation();
    const securityDepositTypeHeldBy: SecurityDepositTypeHeldBy =
      values?.security_deposit_type?.held_by.id;

    const ownership = values?.held_by_ownership as Ownership | undefined;
    const suggestedContactsForDisbursement = ownership?.owners?.map(
      (data) => data.contact
    );

    const heldByDepositAuthority =
      securityDepositTypeHeldBy === 'held_by_deposit_authority';
    const heldByAgency = securityDepositTypeHeldBy === 'held_by_agency';
    const heldByOwnership = securityDepositTypeHeldBy === 'held_by_ownership';

    useEffect(() => {
      if (heldByAgency) {
        setFieldValue?.(
          'held_by_bank_account',
          values.security_deposit_type.held_by_bank_account
        );
      } else {
        setFieldValue?.('held_by_bank_account', null);
      }
    }, [heldByAgency]);

    useEffect(() => {
      if (securityDepositTypeHeldBy === 'held_by_ownership' && ownership) {
        // if there is exactly 1 owner, prepopulate them into the disbursed_to_contact field
        if (ownership.owners?.length === 1) {
          setFieldValue?.('disbursed_to_contact', ownership.owners[0].contact);
        }
      }

      if (values?.security_deposit_type?.default_amount) {
        const { type, amount } = values.security_deposit_type.default_amount;
        setFieldValue?.('amount_type', type);

        if (type.id === 'weeks_of_rent') {
          setFieldValue?.('amount_expected_as_weeks_of_rent', amount);
        }

        if (type.id === 'amount') {
          setFieldValue?.('amount_expected', amount);
        }
      }
    }, [securityDepositTypeHeldBy]);

    return (
      <Grid columns={2}>
        <Field
          name='security_deposit_type'
          label='Deposit type'
          Input={SecurityDepositTypes}
        />
        <Field
          name='registration_number'
          label='Deposit registration number'
          Input={TextInput}
        />

        {securityDepositTypeHeldBy !== undefined && (
          <Box flexDirection='row' sx='2.4rem'>
            <Box flex={1}>
              <Field
                id='amount_type'
                name='amount_type'
                label='Amount required'
                Input={SecurityDepositTypeAmountType}
                inputProps={{
                  shouldReselectClearSelection: false
                }}
              />
            </Box>
            <Box flex={1}>
              {values.amount_type.id === 'amount' && (
                <CenterField name='amount_expected' Input={CentAmountInput} />
              )}
              {values.amount_type.id === 'weeks_of_rent' && (
                <CenterField
                  name='amount_expected_as_weeks_of_rent'
                  Input={WeeksInput}
                />
              )}
            </Box>
          </Box>
        )}

        {heldByDepositAuthority && (
          <>
            <Field
              label='Amount paid direct'
              name='amount_paid'
              Input={CentAmountInput}
            />
          </>
        )}

        {heldByAgency && (
          <>
            <Field
              label='Amount paid'
              name='amount_paid'
              Input={CentAmountInput}
            />
            <Field
              id={'held_by_bank_account'}
              label='Bank account to hold deposit'
              name='held_by_bank_account'
              Input={BankAccountSelect}
            />
            <Field<typeof ContactSelect>
              label='Insured by'
              name='insured_by_contact'
              Input={ContactSelect}
              inputProps={{
                filterByRoles: ['security_deposit_authority']
              }}
            />
          </>
        )}

        {heldByOwnership && (
          <>
            <Field
              label='Amount paid direct'
              name='amount_paid'
              Input={CentAmountInput}
            />
            <Field
              id={'held_by_ownership'}
              label='Ownership account code'
              name='held_by_ownership_chart_of_accounts_account'
              Input={ChartOfAccountsAccountSelect}
              optional={false}
            />
            <Field<typeof ContactSelect>
              label='Insured by'
              name='insured_by_contact'
              Input={ContactSelect}
              inputProps={{
                filterByRoles: ['security_deposit_authority']
              }}
            />
            <Field
              id='disbursed_to'
              label={t('disbursements.disbursed-to.label') as string}
              name='disbursed_to_contact'
              Input={ContactSelect}
              optional={false}
              inputProps={{
                getSuggestedItems: () => suggestedContactsForDisbursement
              }}
            />
            <Field
              label='Disbursement method'
              name='held_by_ownership_contact_payment_method'
              Input={ContactPaymentMethod}
              optional={false}
              inputProps={{
                disabled: !values?.disbursed_to_contact?.id,
                contactId: values?.disbursed_to_contact?.id
              }}
            />
          </>
        )}

        {securityDepositTypeHeldBy !== undefined && (
          <Column width={2}>
            <HorizontalDivider />
            <div className='mt-xl'>
              <SecurityDepositSummary
                amount={{
                  type: values.amount_type,
                  amount_expected: values.amount_expected,
                  amount_expected_as_weeks_of_rent:
                    values.amount_expected_as_weeks_of_rent
                }}
                securityDepositTypeHeldBy={securityDepositTypeHeldBy}
                amountPaid={values?.amount_paid}
                recordId={values?.property_tenancy?.id}
              />
            </div>
          </Column>
        )}
      </Grid>
    );
  }
};
