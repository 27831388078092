import { Columns } from 'view/components/table';
import { SupplierComplianceCategory } from 'data/models/entities/settings/supplier-compliance-categories';
import { PropertyComplianceCategory } from 'data/models/entities/settings/property-compliance-categories';

export const complianceCategorySettingsColumns: Columns<
  SupplierComplianceCategory | PropertyComplianceCategory
> = [
  {
    id: 'label',
    Header: 'Name'
  },
  {
    id: 'description',
    Header: 'Description'
  },
  {
    id: 'requirements',
    Header: 'Requirements',
    accessor: (item) => item.category_requirements?.data.length,
    cellProps: {
      align: 'right'
    }
  }
];
