import { useCachedItems } from 'view/hooks/api/cached-items';
import { api } from 'utils/api/api-client';
import { Contact } from '../types/contact-types';

export const useGetAgencyContacts = () => {
  const { items } = useCachedItems<Contact>(
    'agency-contact',
    async () => {
      const contacts = await api.get(`/contacts`, {
        q: 'is_agency.eq(true)'
      });
      return contacts.data;
    },
    { prefetch: true }
  );

  return items;
};
