import { useDialog } from '@rexlabs/dialog';
import { BankAccount } from 'data/models/entities/financials/bank-accounts';
import { SingleActionDeclaration } from 'src/modules/common/actions/types/action-declaration-types';
import { CreateLedgerActivitySummary } from 'src/modules/reporting/dialogs/create-ledger-activity-summary';
import { TrustLedger } from '../types/trust-ledger-types';

export function useGetCreateLedgerSummaryDialogAction() {
  const { open } = useDialog(CreateLedgerActivitySummary);

  return (
    trustLedger: TrustLedger | null,
    bankAccount?: BankAccount | null
  ): SingleActionDeclaration => ({
    id: 'download-report',
    group: 'trust-ledger',
    label: 'Download activity summary',
    handleAction: () => open({ trustLedger, bankAccount })
  });
}
