import { Property } from 'src/modules/properties/types/property-types';
import { Tab } from 'view/components/table/tabbed';
import { useArchivedColumns } from '../data/key-set-table-columns';

import { useCommonTabProps } from './use-common-tab-props';

export function useArchivedTabProps(property: Property): Tab {
  const commonTabProps = useCommonTabProps();
  const archivedColumns = useArchivedColumns();

  return {
    ...commonTabProps,
    columns: archivedColumns,
    forcedGlobalFilter: [
      {
        field: 'status_id',
        op: 'eq',
        value: 'archived'
      },
      {
        field: 'property_id',
        op: 'eq',
        value: property.id
      }
    ],
    name: 'archived',
    label: 'Archived'
  };
}
