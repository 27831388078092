import { RecordType } from 'data/models/types';
import { LinkProps } from '@rexlabs/whereabouts';
import { getRecordRoutes } from 'src/modules/common/utils/Records/get-record-routes';

interface RecordLinkProps {
  id: string;
  type: RecordType;
  /**
   * parentId - at the moment, this refers to the property id when accessing "flattened" sub-records on the property record.
   * currently this applies just to lease agreement and management agreement.
   */
  parentId?: string;
}

export function getRecordLinkProps({
  type,
  id,
  parentId
}: RecordLinkProps): LinkProps | null {
  // This getter will throw an error if the record type is not found, so we don't need to handle that here.
  const routeConfig = getRecordRoutes({ object: { id }, type: type as any });

  // If we do get a route config back, but there isn't a route for a record, we want to throw an error.
  if (!routeConfig.record) {
    throw new Error(`${type} is not supported`);
  }

  // In most cases, we can just return the default record link props.
  const defaultRecordLinkProps = {
    to: routeConfig.record.route,
    params: {
      [routeConfig.record.routeParam]: id
    }
  };

  // But in some cases, we need to add additional query params or params.
  if (type === 'property_ownership') {
    return {
      ...defaultRecordLinkProps,
      query: {
        tab: 'management-agreements',
        managementAgreement: id
      },
      params: { propertyId: parentId }
    };
  }

  if (type === 'property_tenancy') {
    return {
      ...defaultRecordLinkProps,
      query: {
        tab: 'lease-agreements',
        leaseAgreement: id
      },
      params: { propertyId: parentId }
    };
  }

  return defaultRecordLinkProps;
}
