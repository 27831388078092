import {
  CreditNote,
  financialsCreditNotesModel
} from 'data/models/entities/financials/credit-notes';
import { ActionDeclaration } from 'src/modules/common/actions/types/action-declaration-types';
import { useStatusChangeAction } from 'src/modules/common/actions/status/use-status-change-action';
import { CreditNoteStatus } from 'utils/static-value-lists/credit-note-status';
import { DestructiveButton } from '@rexlabs/button';
import { getRecordLabel } from 'utils/records/get-record-label';
import { Invoice } from 'data/models/entities/financials/invoices';

export function useCreditNotesActions() {
  const getVoidAction = useStatusChangeAction(financialsCreditNotesModel);

  return (creditNote: CreditNote): ActionDeclaration[] => {
    const invoice = creditNote?.application?.invoice_payment_entry?.invoice;

    const voidAction = getVoidAction<CreditNoteStatus>({
      record: creditNote,
      status: 'void',
      dialogOptions: {
        SubmitButton: DestructiveButton,
        label: 'Void',
        submitLabel: 'Void credit note',
        title: 'Void credit note',
        description: `You are about to void ${getRecordLabel({
          object: creditNote,
          type: 'credit_note'
        })} ${
          invoice &&
          `applied to ${getRecordLabel({
            object: invoice as Invoice,
            type: 'invoice'
          })}`
        }`,
        helperText:
          'Please provide a reason why this credit note is being voided.'
      }
    });

    return creditNote.status.id === 'active' ? [voidAction] : [];
  };
}
