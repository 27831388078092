import { Invoice } from 'data/models/entities/financials/invoices';
import { Columns } from 'view/components/table';

export const disbursementInvoiceColumns: Columns<Invoice> = [
  {
    type: 'invoice',
    width: 120,
    Header: 'Reference',
    accessor: (item) => item
  },
  {
    type: 'record',
    Header: 'Payable by',
    accessor: (item) => item.payable_by.object,
    width: 200
  },
  {
    accessor: (item) => item.description,
    Header: 'Description',
    width: 360
  },
  {
    accessor: (item) => item.amount_pending_disbursement,
    type: 'currency',
    Header: 'Payout amount',
    width: 180
  }
];
