import { trustJournalEntryLineItemBlock } from 'src/modules/trust-journal-entries/blocks/trust-journal-entry-line-item-block';
import { readOnlyNoteTableBlock } from 'src/modules/note/blocks/read-only-note-table-block';

import { FLAGS } from 'utils/feature-flags';

import { ContentConfig } from 'view/components/record-screen/types';

import { detailsBlock } from '../blocks/details';

export const trustJournalEntryDetailsContent: ContentConfig = [
  {
    label: 'General',
    items: [
      {
        id: 'overview',
        label: 'Overview',
        blocks: [detailsBlock, trustJournalEntryLineItemBlock]
      },
      {
        id: 'notes',
        label: 'Notes',
        flag: FLAGS.BATCH_RECEIPTING_SUSPENSION_FLOW,
        blocks: [readOnlyNoteTableBlock]
      }
    ]
  }
];
