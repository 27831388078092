import React from 'react';
import { BlockConfig } from 'view/components/record-screen/types';

import { InspectionTask } from '../types/InspectionTask';
import { InspectionReportEmptyState } from '../components/inspection-report-empty-state';

export const inspectionReportEmptyBlock: BlockConfig<InspectionTask> = {
  id: 'inspection-report',
  title: 'Inspection report',
  showEmpty: () => true,
  Empty: ({ data }) => <InspectionReportEmptyState inspectionTask={data!} />
};
