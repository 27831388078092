import React, { forwardRef, useCallback } from 'react';
import { GhostButton, PrimarySplitButton } from '@rexlabs/button';
import ActionMenu from '@rexlabs/action-menu';
import ChevronDownIcon from 'view/components/icons/chevron-down';
import { RecordType } from 'data/models/types';
import { getRecordLinkProps } from 'view/components/record-link/get-record-link-props';
import { RecordData } from 'utils/types';
import { pushLinkProps } from 'utils/router/push-link-props';
import { ButtonGroupProps } from 'view/components/record-screen/dialog/types';
import { getRecordTypeName } from 'utils/records/get-record-type-name';
import { StandardDialogFooter } from '../components/standard-dialog-footer';

export interface CreateRecordDialogFooter extends ButtonGroupProps {
  recordType: RecordType;
  disableRedirect?: boolean;
  timeToWaitBeforeRedirect?: number;
}

export function CreateRecordDialogFooter({
  recordType,
  handleSubmit,
  onClose,
  resetAll,
  isSubmitting,
  disableRedirect,
  timeToWaitBeforeRedirect
}: CreateRecordDialogFooter) {
  const handleSubmitAndView = useCallback(async () => {
    const newRecord = await handleSubmit();

    if (!newRecord) return;

    onClose?.();
    // HACK: this will wait one JS exec tick for the onClose to resolve first, which ensures
    // that things like redirects happen in the intended order
    await new Promise((resolve) =>
      setTimeout(resolve, timeToWaitBeforeRedirect || 100)
    );

    if (disableRedirect) return;

    const linkProps = getRecordLinkProps({
      type: recordType,
      id: newRecord?.id
    })!;

    pushLinkProps(linkProps);
  }, [handleSubmit, onClose]);

  const handleSubmitAndClose = useCallback(async () => {
    const newRecord = ((await handleSubmit()) as unknown) as RecordData | false;

    if (!newRecord) return;

    onClose?.();
  }, [handleSubmit, onClose]);

  const handleSubmitAndCreateAnother = useCallback(async () => {
    const newRecord = ((await handleSubmit()) as unknown) as RecordData | false;

    if (!newRecord) return;

    resetAll?.();
  }, [handleSubmit, resetAll]);

  const Left = useCallback(
    ({ Button }) => {
      return (
        <Button
          isLoading={isSubmitting}
          onClick={handleSubmitAndView}
          type='submit'
        >
          Create &amp; view {getRecordTypeName(recordType)}
        </Button>
      );
    },
    [isSubmitting, handleSubmitAndView]
  );

  return (
    <StandardDialogFooter>
      <GhostButton onClick={onClose}>Cancel</GhostButton>
      <PrimarySplitButton
        Left={Left}
        Right={({ IconButton }) => (
          <ActionMenu
            placement='bottom-end'
            items={[
              {
                label: 'Create & close dialog',
                onClick: handleSubmitAndClose
              },
              {
                label: 'Save & create another',
                onClick: handleSubmitAndCreateAnother
              }
            ]}
            Button={forwardRef((props, ref) => (
              <IconButton
                {...props}
                data-testid='create-action-menu-button'
                ref={ref}
                Icon={ChevronDownIcon}
              />
            ))}
          />
        )}
      />
    </StandardDialogFooter>
  );
}
