import React from 'react';
import { Columns } from 'view/components/table';
import { LargeDescriptionCell } from 'view/components/cells/large-description-cell';
import Box from '@rexlabs/box';
import { Bold } from '@rexlabs/text';
import { StyleSheet, useStyles } from '@rexlabs/styling';
import { getConditionValue } from 'src/modules/tasks/inspections/entry-exit/utils/get-condition-value';
import { PreviewImagesCell } from '../components/preview-images-cell';
import { ImageColumnHeader } from '../components/image-column-header';
import { ConditionInput } from '../../components/condition-button-input';

const styles = StyleSheet({
  displayCell: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0
  },

  headerCell: {
    marginLeft: -16
  }
});

export const inspectionAreaViewColumns: Columns = [
  {
    id: 'name',
    Header: 'Item',
    width: 220,
    Cell: ({ value }) => <LargeDescriptionCell lineClamp={2} text={value} />
  },
  {
    id: 'conditions',
    width: 190,
    accessor: (item) => item.inspection_conditions.data,
    Header: () => {
      const s = useStyles(styles);

      return (
        <Box flexDirection='row' {...s('headerCell')}>
          <Box justifyContent='center' alignItems='center' width={50}>
            <Bold>C</Bold>
          </Box>
          <Box justifyContent='center' alignItems='center' width={50}>
            <Bold>W</Bold>
          </Box>
          <Box justifyContent='center' alignItems='center' width={50}>
            <Bold>U</Bold>
          </Box>
        </Box>
      );
    },
    Cell: ({ value }) => {
      const s = useStyles(styles);

      const cleanValue = getConditionValue(value, 'Clean');
      const workingValue = getConditionValue(value, 'Working');
      const undamagedValue = getConditionValue(value, 'Undamaged');

      return (
        <Box flexDirection='row' {...s('displayCell')}>
          <ConditionInput value={cleanValue} />
          <ConditionInput value={workingValue} />
          <ConditionInput value={undamagedValue} />
        </Box>
      );
    }
  },
  {
    id: 'notes',
    Header: 'Comments',
    accessor: (item) => item.notes || '--',
    Cell: ({ value }) => <LargeDescriptionCell lineClamp={2} text={value} />
  },
  {
    id: 'images',
    Header: () => <ImageColumnHeader />,
    accessor: (item) => item,
    Cell: (props) => PreviewImagesCell({ ...props, value: props.value }),
    width: 60
  }
];
