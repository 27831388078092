import React from 'react';
import { BlockConfig } from 'view/components/record-screen/types';
import { CashBookEntry } from 'data/models/entities/financials/cash-book-entries';
import { CurrencySummary } from 'view/components/currency-summary';

const toTotal = (total: number, cashBookEntry: CashBookEntry) =>
  total + cashBookEntry.amount;
const byCheque = (cashBookEntry: CashBookEntry) =>
  cashBookEntry.payment_method === 'cheque';
const byNoneCheque = (cashBookEntry: CashBookEntry) =>
  cashBookEntry.payment_method !== 'cheque';

const calculateCashTotal = (cashBookEntries: CashBookEntry[]) =>
  cashBookEntries.filter(byNoneCheque).reduce(toTotal, 0);
const calculateChequeTotal = (cashBookEntries: CashBookEntry[]) =>
  cashBookEntries.filter(byCheque).reduce(toTotal, 0);
const calculateTotal = (cashBookEntries: CashBookEntry[]) =>
  cashBookEntries.reduce(toTotal, 0);

export const summaryBlock: BlockConfig = {
  id: 'summary',
  title: 'Summary',
  Edit: ({ forms }) => {
    const cbes = forms?.cashBookEntries.values?.cashBookEntries;
    return (
      <CurrencySummary
        rows={[
          {
            label: 'Cash total',
            value: cbes ? calculateCashTotal(cbes) : 0
          },
          {
            label: 'Cheque total',
            value: cbes ? calculateChequeTotal(cbes) : 0
          },
          {
            label: 'Total',
            bold: true,
            value: cbes ? calculateTotal(cbes) : 0
          }
        ]}
      />
    );
  }
};
