import { useRef, useState, useEffect } from 'react';

type UseScrollDirectionArgs = {
  root: HTMLElement | null;
  disabled?: boolean;
};

export function useScrollDirection({
  root,
  disabled = false
}: UseScrollDirectionArgs) {
  const oldScrollPos = useRef(0);

  const [scrollDirection, setScrollDirection] = useState<string | null>(null);

  useEffect(() => {
    if (disabled || !root) {
      return;
    }

    function scrollListener(e) {
      if (oldScrollPos.current > e.target.scrollTop) {
        setScrollDirection('up');
      } else {
        setScrollDirection('down');
      }
      oldScrollPos.current = e.target.scrollTop;
    }

    // The root element must be the scrolling
    // container in this case
    root.addEventListener('scroll', scrollListener);

    return () => {
      root.removeEventListener('scroll', scrollListener);
    };
  }, [root]);

  return scrollDirection;
}
