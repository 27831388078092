import { Generator } from 'data/models/generator';
import { auditableConfig } from 'src/modules/common/auditable/auditable-config';
import { api } from 'utils/api/api-client';
import _ from 'lodash';
import { Bill } from '../types/Bill';

const config = {
  entities: {
    related: {
      file: {
        include: 'file'
      },
      invoice: {
        include: 'invoice'
      },
      ...auditableConfig
    }
  }
};

const actionCreators = {
  importBills: {
    request: ({ fileIds }: { fileIds: string[] }) =>
      api.post('financials/uploaded-bills?include=file', {
        files: fileIds.map((fileId) => {
          return { id: fileId };
        })
      }),
    reduce: _.identity
  }
};

export const financialsUploadedBillsModel = new Generator<
  Bill,
  typeof actionCreators
>('financials/uploaded-bills', config).createEntityModel({ actionCreators });
