import React, { ComponentProps } from 'react';
import { useQuery } from 'react-query';

import { Quri } from '@rexlabs/quri';

import { getSearchQuery } from 'src/lib/react-query/hooks/use-records-query';
import { Note } from 'src/modules/note/types/Note';

import { api } from 'utils/api/api-client';

import { NumberBadge } from 'view/components/badge';

async function fetchNotes({ queryKey }) {
  const [path, filter] = queryKey;

  const htmlSearchParams = getSearchQuery({
    filter
  });

  return api.get<Note[]>(`${path}?${htmlSearchParams}`);
}

export function NoteCountBadge({
  filter,
  isActive
}: Omit<ComponentProps<typeof NumberBadge>, 'number'> & {
  filter: Quri[];
}) {
  const { data } = useQuery(['notes', filter], fetchNotes, {
    cacheTime: Infinity,
    staleTime: Infinity
  });

  // This doesn't exist in the types for AxiosResponse, but is there in the API response
  const total = data?.['pagination']?.total;

  return total ? <NumberBadge number={total} isActive={isActive} /> : null;
}
