import { reduce } from 'lodash';

// Developers add to this list
const commonActions = [
  'modal_opened',
  'started',
  'continued',
  'finish_later_clicked',
  'details_edited',
  'details_updated',
  'details_canceled',
  'report_downloaded',
  'completed',
  'verified',
  'void_modal_opened',
  'voided'
] as const;

// Developers this is for Typescript and doesn't need to be touched
export type CommonActionTypes = typeof commonActions[number];

export const COMMON_ACTIONS = reduce(
  commonActions,
  (acc, action) => {
    acc[action.toUpperCase()] = action;
    return acc;
  },
  {} as Record<Uppercase<CommonActionTypes>, CommonActionTypes>
);
