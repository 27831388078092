import { query } from '@rexlabs/model-generator';
import { propertiesModel } from 'data/models/entities/properties';

export const getPropertyDetailsQuery = (id: string) => query`{
    ${propertiesModel} (id: ${id}) {
      id
      name
      images
      compliance_categories
      features
      views
      links
      portfolio
      address
      active_property_ownership {
        ownership {
          owners
          financial_summary
        }
        maintenance_instructions
        service_package
        entity_fees_have_changed
      }
      property_ownerships {
        ownership {
          owners
          financial_summary
        }
        maintenance_instructions
        property
        service_package
        entity_fees_have_changed
      }
      property_tenancies {
        tenancy {
          tenants
        }
        rent_schedule {
          line_items {
            payable_to_chart_of_accounts_account 
          }
        }
        rent_position
        summary
      }
      active_property_tenancy {
        tenancy {
          tenants {
            contact {
              primary_phone
            }
          }
          related_contacts {
            contact {
              primary_phone
            }
          }
        }
        rent_schedule {
          line_items {
            payable_to_chart_of_accounts_account 
          }
        }
        rent_position
        summary
      }
      incoming_property_ownership
      incoming_property_tenancy {
        tenancy {
          tenants {
            contact {
              primary_phone
            }
          }
        }
        rent_schedule {
          line_items {
            payable_to_chart_of_accounts_account 
          }
        }
        rent_position
        summary
      }
      appliances {
        warranties
        manuals
        property
      }
      created_by {
        contact
      }
      updated_by {
        contact
      }
      areas
    }
  }`;
