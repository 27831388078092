import { FeeAmount } from 'src/modules/agency-fees/types/agency-fee-types';

type FormFeeAmount = Partial<FeeAmount>;

export function isFeeSame(a?: FormFeeAmount, b?: FormFeeAmount) {
  if (!a || !b) return false;

  const amountIsSame = a.amount === b.amount;
  const typeIsSame = a.type?.id === b.type?.id;

  return amountIsSame && typeIsSame;
}
