import { DialogProps, useDialog, useErrorDialog } from '@rexlabs/dialog';
import * as React from 'react';
import { useCallback, useRef } from 'react';
import { RecordDialog } from 'view/components/record-screen/dialog/dialog';
import { useToast } from 'view/components/@luna/notifications/toast';
import { useModelActions } from '@rexlabs/model-generator';
import { pluralize } from 'utils/formatters';
import { uploadBillsBlock } from '../blocks/upload-bills-block';
import { financialsUploadedBillsModel } from '../models/uploaded-bills';
import { UploadBillsDialogButtonGroup } from '../components/upload-bills-dialog-button-group';
import { ProcessBillsDialog } from './process-bills-dialog';

const content = [
  {
    id: 'upload-bills',
    label: 'Upload bills',
    blocks: [uploadBillsBlock]
  }
];

const PROCESS_NOW = Symbol('PROCESS_NOW');
const PROCESS_LATER = Symbol('PROCESS_LATER');

export function UploadBillsDialog({ onClose }: DialogProps) {
  const { addToast } = useToast();
  const { open: openErrorDialog } = useErrorDialog();
  const { importBills, refreshLists } = useModelActions(
    financialsUploadedBillsModel
  );
  const { open: openProcessBillsDialog } = useDialog(ProcessBillsDialog);

  const clickedButtonRef = useRef<symbol | null>(null);

  const handleSubmit = useCallback(
    async ({ values }) => {
      const type = clickedButtonRef.current;

      const { bills } = values;
      if (bills.some((bill) => !bill?.data)) return;

      const billData = await Promise.all(
        bills.map(async (bill) => {
          const { data } = await Promise.resolve(bill.data);
          return data;
        })
      );

      switch (type) {
        case PROCESS_NOW: {
          try {
            const uploadedBill = await importBills({
              fileIds: billData.map((bill) => bill.id)
            });

            onClose?.();

            const { data: uploadedBillsData } = uploadedBill;

            // Open the process bills dialog
            await openProcessBillsDialog?.({
              billsToBeProcessed: uploadedBillsData
            });
          } catch (error) {
            openErrorDialog(error);
          }
          break;
        }
        case PROCESS_LATER: {
          try {
            const uploadedBill = await importBills({
              fileIds: billData.map((bill) => bill.id)
            });

            await refreshLists();

            addToast({
              description: `You have successfully uploaded ${pluralize(
                'bills',
                bills.length,
                true
              )}`
            });

            return uploadedBill;
          } catch (error) {
            openErrorDialog(error);
          }
          break;
        }
        default: {
          return;
        }
      }
    },
    [addToast, importBills, openErrorDialog, refreshLists]
  );

  const handleProcessLater = React.useCallback(() => {
    clickedButtonRef.current = PROCESS_LATER;
  }, []);

  const handleProcessNow = React.useCallback(() => {
    clickedButtonRef.current = PROCESS_NOW;
  }, []);

  return (
    <RecordDialog
      title='Upload bills'
      content={content}
      onClose={onClose}
      submitLabel='Process later'
      handleSubmit={handleSubmit}
      data={{ bills: [] }}
      ButtonGroup={UploadBillsDialogButtonGroup}
      buttonGroupProps={{ handleProcessLater, handleProcessNow }}
    />
  );
}
