import { GlobalFilter } from '@rexlabs/table/lib/types/context';
import React from 'react';

enum InvoiceFilters {
  ALL_INVOICES = 'all_invoices',
  GENERAL_INVOICES = 'general_invoices',
  AGENCY_FEES = 'agency_fees',
  RENT_INVOICES = 'rent_invoices',
  BOND_REQUESTS = 'bond_requests',
  TENANT_BILLS = 'tenant_bills',
  OWNER_BILLS = 'owner_bills',
  CREDITOR = 'creditor'
}

export type InvoiceFilterAction = {
  label: string;
  actions: {
    label: string;
    id: InvoiceFilters;
    handleAction(): void;
    filters: GlobalFilter[];
  }[];
};

export function useInvoiceFilters({
  hiddenInvoiceFilterIds
}: {
  hiddenInvoiceFilterIds?: string[];
} = {}): {
  actions: InvoiceFilterAction[];
  filters: GlobalFilter[];
} {
  const [selectedOption, setSelectedOption] = React.useState(
    InvoiceFilters.ALL_INVOICES
  );

  const options = React.useMemo(
    () => [
      {
        label: 'All Invoices',
        id: InvoiceFilters.ALL_INVOICES,
        handleAction() {
          setSelectedOption(InvoiceFilters.ALL_INVOICES);
        },
        filters: []
      },
      {
        label: 'Agency Fees',
        id: InvoiceFilters.AGENCY_FEES,
        handleAction() {
          setSelectedOption(InvoiceFilters.AGENCY_FEES);
        },
        filters: [
          {
            field: 'type_id',
            op: 'eq',
            value: { value: 'agency_fee', label: 'Agency fee' }
          }
        ]
      },
      {
        label: 'Rent Invoices',
        id: InvoiceFilters.RENT_INVOICES,
        handleAction() {
          setSelectedOption(InvoiceFilters.RENT_INVOICES);
        },
        filters: [
          {
            field: 'type_id',
            op: 'eq',
            value: { value: 'managed_rent', label: 'Managed rent' }
          }
        ]
      },
      {
        label: 'General Invoices',
        id: InvoiceFilters.GENERAL_INVOICES,
        handleAction() {
          setSelectedOption(InvoiceFilters.GENERAL_INVOICES);
        },
        filters: [
          {
            field: 'type_id',
            op: 'eq',
            value: { value: 'default', label: 'Default' }
          }
        ]
      },
      {
        label: 'Creditor',
        id: InvoiceFilters.CREDITOR,
        handleAction() {
          setSelectedOption(InvoiceFilters.CREDITOR);
        },
        filters: [
          {
            field: 'payable_to_object_type',
            op: 'eq',
            value: { value: 'contact', label: 'Contact' }
          },
          {
            field: 'type_id',
            op: 'neq',
            value: { value: 'agency_fee', label: 'Agency fee' }
          }
        ]
      },
      {
        label: 'Bond Requests',
        id: InvoiceFilters.BOND_REQUESTS,
        handleAction() {
          setSelectedOption(InvoiceFilters.BOND_REQUESTS);
        },
        filters: [
          {
            field: 'type_id',
            op: 'eq',
            value: { value: 'security_deposit', label: 'Security deposit' }
          }
        ]
      },
      {
        label: 'Tenant Bills',
        id: InvoiceFilters.TENANT_BILLS,
        handleAction() {
          setSelectedOption(InvoiceFilters.TENANT_BILLS);
        },
        filters: [
          {
            field: 'payable_by_object_type',
            op: 'eq',
            value: { value: 'tenancy', label: 'Tenancy' }
          },
          {
            field: 'type_id',
            op: 'neq',
            value: { value: 'managed_rent', label: 'Managed rent' }
          }
        ]
      },
      {
        label: 'Owner Bills',
        id: InvoiceFilters.OWNER_BILLS,
        handleAction() {
          setSelectedOption(InvoiceFilters.OWNER_BILLS);
        },
        filters: [
          {
            field: 'payable_by_object_type',
            op: 'eq',
            value: { value: 'ownership', label: 'Ownership' }
          },
          {
            field: 'type_id',
            op: 'neq',
            value: { value: 'agency_fee', label: 'Agency fee' }
          }
        ]
      }
    ],
    []
  );

  return React.useMemo(() => {
    const result = options.find((option) => option.id === selectedOption)!;

    return {
      actions: [
        {
          label: result.label,
          actions: options.filter(
            (option) => !hiddenInvoiceFilterIds?.includes(option.id)
          )
        }
      ],
      filters: result.filters
    };
  }, [selectedOption]);
}
