import { useDialog } from '@rexlabs/dialog';
import { useModelActions } from '@rexlabs/model-generator';
import { financialsInvoicesModel } from 'data/models/entities/financials/invoices';
import { FinancialEntity } from 'src/modules/financials/utils/financial-entity-action-group/use-get-financial-entity-actions';
import { ActionDeclaration } from 'src/modules/common/actions/types/action-declaration-types';
import { TransferFundsDialog } from 'src/modules/trust-journal-entries/dialogs/transfer-funds-dialog';
import { financialsPendingDisbursementsContactsModel } from 'data/models/entities/financials/pending-disbursements/pending-contact-disbursement';
import { financialsPendingDisbursementsOwnershipsModel } from 'data/models/entities/financials/pending-disbursements/pending-ownership-disbursement';

export function useGetPayBillsAction() {
  const { open } = useDialog(TransferFundsDialog);
  const { refreshList } = useModelActions(financialsInvoicesModel);
  const { refreshItem: refreshPendingContactDisbursement } = useModelActions(
    financialsPendingDisbursementsContactsModel
  );
  const { refreshItem: refreshPendingOwnershipDisbursement } = useModelActions(
    financialsPendingDisbursementsOwnershipsModel
  );

  return ({
    financialFolio,
    updateListId,
    pendingDisbursementId
  }: {
    financialFolio: FinancialEntity;
    updateListId?: string;
    pendingDisbursementId: string;
  }): ActionDeclaration => ({
    id: 'pay-fees-and-bills',
    group: 'invoices',
    label: 'Pay bills & fees',
    handleAction: () =>
      open({
        sourceObject: financialFolio,
        title: 'Pay bills & fees',
        submitLabel: 'Pay bills & fees',
        onSubmit: async () => {
          await refreshList({
            id: updateListId
          });

          financialFolio.__record_type === 'contact' &&
            (await refreshPendingContactDisbursement({
              id: pendingDisbursementId
            }));

          financialFolio.__record_type === 'ownership' &&
            (await refreshPendingOwnershipDisbursement({
              id: pendingDisbursementId
            }));
        }
      })
  });
}
