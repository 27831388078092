import * as React from 'react';
import { TagConfig } from 'view/components/@luna/title-block';
import { ProgressDropdown } from 'src/modules/tasks/inspections/components/progress-dropdown';
import { InspectionTask } from 'src/modules/tasks/inspections/types/InspectionTask';

// gotta be memo'd otherwise we get infinite rerenders
export function useInspectionStatusTag(task?: InspectionTask): TagConfig {
  const Tag = React.useMemo(() => () => <ProgressDropdown value={task} />, [
    task?.details?.status?.id
  ]);

  return {
    label: task?.details?.status?.label || '',
    Tag
  };
}
