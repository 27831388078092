import {
  hasAreas,
  hasRooms
} from 'src/modules/tasks/inspections/utils/inspection-report-helpers';
import { InspectionTask } from 'src/modules/tasks/inspections/types/InspectionTask';
import { FLAGS } from 'utils/feature-flags';
import { useFeatureFlags } from 'view/components/@luna/feature-flags';

type hasRoomsOrAreasReturnType = (
  inspection: InspectionTask
) => number | undefined;

export function useGetHasRoomsOrAreas(): hasRoomsOrAreasReturnType {
  const { hasFeature } = useFeatureFlags();

  if (hasFeature(FLAGS.ENTRY_EXIT_INSPECTION_REPORT)) {
    return hasAreas;
  }

  return hasRooms;
}
