import { useWhereabouts } from '@rexlabs/whereabouts';
import { Columns } from 'src/view/components/table';
import { ComplianceProgressDropdown } from 'src/modules/tasks/common/components/compliance-progress-dropdown';
import { SupplierComplianceSummaryCell } from 'src/modules/tasks/supplier-compliance/components/supplier-compliance-summary-cell';
import { ExpiryCell } from 'src/modules/tasks/common/components/expiry-cell';
import {
  getLinkedTaskText,
  LinkedTasksCell
} from 'src/modules/tasks/common/components/linked-tasks-cell';
import { SupplierComplianceTask } from '../types/SupplierComplianceTask';
import { closedDate, managedBy } from '../../common/utils/common-columns';

const linkedTasks = {
  id: 'linked_tasks',
  Header: 'Linked tasks',
  Cell: LinkedTasksCell,
  accessor: (item: SupplierComplianceTask) => item,
  toCsv: (item: SupplierComplianceTask) =>
    getLinkedTaskText(item.task_links?.data)
};

const status = {
  id: 'status',
  Header: 'Status',
  Cell: ComplianceProgressDropdown,
  accessor: (item: SupplierComplianceTask) => item,
  width: 150,
  toCsv: (item: SupplierComplianceTask) => item?.status?.label
};

const followUpDate = {
  id: 'follow_up_date',
  Header: 'Follow up',
  accessor: (item: SupplierComplianceTask) => item.follow_up_date,
  type: 'date',
  width: 140
} as const;

const dueDate = {
  id: 'due_date',
  Header: 'Expires',
  Cell: ExpiryCell,
  accessor: (item: SupplierComplianceTask) => item,
  width: 160,
  toCsv: (item: SupplierComplianceTask) => item.due_date || 'NULL'
};

const summary = {
  id: 'summary',
  Header: 'Summary',
  Cell: SupplierComplianceSummaryCell,
  accessor: (item) => item,
  width: 380,
  toCsv: (item: SupplierComplianceTask) => item.summary
};

export function useSupplierComplianceColumns(): Columns {
  const whereabouts = useWhereabouts();
  const tab = whereabouts.hashQuery?.tab;

  if (tab === 'closed') {
    return [summary, closedDate, dueDate, managedBy, linkedTasks];
  }

  return [summary, dueDate, followUpDate, status, managedBy, linkedTasks];
}
