import React from 'react';

import { BlockConfig } from 'view/components/record-screen/types';

import { EditBulkEmailContentForm } from 'src/modules/communications/messages/components/message-content-forms/edit-bulk-email-content-form';
import { BulkCreateEmailMessageFormData } from '../types/BulkCreateEmailMessageFormData';
import { useMessageTriggerSettings } from '../settings/message-triggers/hooks/use-message-trigger-settings';
import { validateEditEmailForm } from '../components/message-content-forms/basic-forms/basic-edit-email-form';

export const bulkEmailMessageContentBlock: BlockConfig<BulkCreateEmailMessageFormData> = {
  id: 'content',
  title: 'Message Content',
  validate: validateEditEmailForm,
  Edit: ({ data, setFieldValue }) => {
    const {
      getTemplatedAttachmentForContextType
    } = useMessageTriggerSettings();
    return (
      <EditBulkEmailContentForm
        contextType={data?.context_type}
        setFieldValue={setFieldValue}
        templatedAttachments={getTemplatedAttachmentForContextType(
          data?.context_type
        )}
      />
    );
  }
};
