import { Box } from '@rexlabs/box';
import { Tabs } from '@rexlabs/tabs';
import * as React from 'react';
import {
  BlockConfig,
  BlockViewProps
} from 'view/components/record-screen/types';
import { messageDetailsBlock } from '../blocks/message-details-block';
import { BasicEditSMSForm } from '../components/message-content-forms/basic-forms/basic-edit-sms-form';
import { getContextTypeFromRecord } from '../utils/get-context-type-from-record';
import { smsPreviewBlock } from './sms-preview-block';

const DetailsBlock = React.memo(messageDetailsBlock.Edit!);
const PreviewBlock = React.memo(smsPreviewBlock.Edit!);

function ComposeTab({
  id,
  blockProps,
  getHandlers,
  data,
  forms,
  children
}: BlockViewProps & { children: React.ReactNode }) {
  return (
    <Box marginTop={48}>
      <DetailsBlock
        id={id}
        blockProps={blockProps}
        getHandlers={getHandlers}
        forms={forms}
        data={data}
      />

      {children}
    </Box>
  );
}

export const smsDetailsBlock: BlockConfig = {
  id: 'sms-details',
  validate: {
    definitions: {
      relates_to: { rules: 'required' },
      sent_from: { rules: 'required' },
      recipients: { rules: 'required' },
      smsContent: { rules: 'required', name: 'content' }
    }
  },
  Edit: (props) => {
    const {
      id,
      blockProps,
      getHandlers,
      data,
      forms,
      values,
      setFieldValue
    } = props;
    const contextType = getContextTypeFromRecord(data?.relates_to?.record);

    const composeTab = React.useCallback(() => {
      return (
        <ComposeTab
          id={id}
          data={data}
          forms={forms}
          blockProps={blockProps}
          getHandlers={getHandlers}
        >
          <Box marginTop={48}>
            <BasicEditSMSForm
              contextType={contextType}
              setFieldValue={setFieldValue}
            />
          </Box>
        </ComposeTab>
      );
    }, [contextType, id, data, blockProps, getHandlers, setFieldValue]);

    const previewTab = React.useCallback(() => {
      return <PreviewBlock values={values} />;
    }, [values]);

    return (
      <Tabs
        items={[
          {
            label: 'Compose',
            name: 'compose',
            Tab: composeTab
          },
          {
            label: 'Preview',
            name: 'preview',
            Tab: previewTab,
            isDisabled: values.relates_to == undefined
          }
        ]}
      />
    );
  }
};
