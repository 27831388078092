import { NormalisedItem, Select } from '@rexlabs/select';
import * as React from 'react';
import { slugToSentenceCase } from 'src/modules/communications/messages/utils/slug-to-sentence-case';
import {
  MessageContext,
  useMessageContexts
} from '../../hooks/use-message-contexts';
import { CommunicationContextType } from '../../types/communication-context-type';

const normaliser = (item: MessageContext): NormalisedItem => ({
  id: item.id,
  label: slugToSentenceCase(item.id)
});

export function MessageContextSelect({ ...props }) {
  let { data } = useMessageContexts();

  // filter out any contexts that shouldn't be shown
  const ContextTypesToHide: CommunicationContextType[] = [
    // These ones don't have recipient groups
    'date',
    'author',
    'recipients',
    'supplier',
    'property_tenancy',
    'bank_account',
    // Theses ones are generic, and don't need to be shown
    'disbursement',
    'statement',
    'trust_journal_entry',
    'compliance_entry'
  ];
  data = data?.filter((context) => !ContextTypesToHide.includes(context.id));

  return <Select {...props} items={data} normaliser={normaliser} />;
}
