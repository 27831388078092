import * as React from 'react';
import { DialogProps } from '@rexlabs/dialog';
import { RecordDialog } from 'view/components/record-screen/dialog/dialog';
import { transformToRecordObject } from 'utils/records/transform-to-record-data';
import { transformToSearchEntry } from 'utils/transform-to-search-entry';
import { useModelActions } from '@rexlabs/model-generator';
import { useQueryClient } from 'react-query';
import { RecordSubmitHandler } from 'view/components/record-screen/utils';
import { useToast } from 'view/components/@luna/notifications/toast';
import { useGetResetTableUrlParams } from 'view/components/table/hooks/use-get-reset-table-url-params';
import { defaultNotesRecordTableHashParamKey } from 'src/modules/note/components/notes-list';
import { BatchReceiptingItem } from '../types';
import { SuggestMatchDialogButtonGroup } from '../components/suggest-match-dialog-button-group';
import { bankStatementTransactionsModel } from '../models/bank-statement-transaction-model';
import { bankStatementTransactionsQueryKey } from '../hooks/use-bank-statement-transactions-query';
import { useMatchDialogContent } from '../hooks/use-match-dialog-content';

interface EditMatchDialogProps extends DialogProps {
  batchReceiptingItem: BatchReceiptingItem;
  onClose?: () => void;
}

export function EditMatchDialog({
  onClose,
  batchReceiptingItem
}: EditMatchDialogProps) {
  const content = useMatchDialogContent(batchReceiptingItem, true);
  const { addToast } = useToast();
  const { manualMatch } = useModelActions(bankStatementTransactionsModel);
  const queryClient = useQueryClient();
  const resetTableUrlParams = useGetResetTableUrlParams();

  const handleSubmit: RecordSubmitHandler = React.useCallback(
    async ({ values, changedValues }) => {
      const payload = {
        ...values,
        ...changedValues
      };

      if (!Array.isArray(payload.contacts) || payload.contacts.length === 0)
        return false;

      await manualMatch({
        bankStatementTransactionId: batchReceiptingItem.id,
        data: {
          manual_match: payload
        }
      });

      await queryClient.refetchQueries({
        queryKey: [bankStatementTransactionsQueryKey],
        refetchPage: () => true
      });

      addToast({
        type: 'success',
        description: <>The match request has been successfully updated.</>
      });

      return true;
    },
    [batchReceiptingItem.id, manualMatch]
  );

  const handleOnClose = React.useCallback(() => {
    resetTableUrlParams({
      resetPageTableKeys: [defaultNotesRecordTableHashParamKey]
    });
    onClose?.();
  }, [onClose, resetTableUrlParams]);

  const folio = batchReceiptingItem.prioritised_match?.folio;

  const initialValues = {
    folio: folio
      ? transformToSearchEntry(transformToRecordObject(folio))
      : undefined,
    contacts: batchReceiptingItem.prioritised_match?.contacts
  };

  return (
    <RecordDialog
      title='Edit match'
      content={content}
      data={batchReceiptingItem}
      initialValues={initialValues}
      onClose={handleOnClose}
      handleSubmit={handleSubmit}
      blockProps={{ batchReceiptingItem, hideAddNoteToField: true }}
      buttonGroupProps={{ data: batchReceiptingItem }}
      ButtonGroup={SuggestMatchDialogButtonGroup}
    />
  );
}
