import { DialogProps, useDialog } from '@rexlabs/dialog';
import { useModelActions } from '@rexlabs/model-generator';
import { AxiosResponse } from 'axios';
import React, { useCallback } from 'react';

import { financialsInvoicesModel } from 'data/models/entities/financials/invoices';
import { useBankAccounts } from 'src/modules/bank-accounts/hooks/use-bank-accounts';
import { trustJournalEntryModel } from 'src/modules/trust-journal-entries/models/trust-journal-entries';
import { RecordDialog } from 'view/components/record-screen/dialog/dialog';
import { DialogContentConfig } from 'view/components/record-screen/dialog/types';

import { BankAccount } from 'data/models/entities/financials/bank-accounts';
import { ValueListValue } from 'data/models/types';
import { useQueryClient } from 'react-query';
import { MatchToExistingReceiptDialog } from 'src/modules/banking/batch-receipting/dialogs/match-to-existing-receipt-dialog';
import { bankStatementTransactionsQueryKey } from 'src/modules/banking/batch-receipting/hooks/use-bank-statement-transactions-query';
import { MatchStatusId } from 'src/modules/banking/batch-receipting/types';
import { useRecordCreatedToast } from 'src/modules/common/toasts/hooks/use-record-created-toast';
import { useCreateTrustJournalEntryAndLinkTransaction } from 'src/modules/banking/batch-receipting/hooks/use-create-trust-journal-entry-and-link-transaction';
import { Contact } from 'src/modules/contacts/types/contact-types';
import { receiptFundsPaymentDetailsBlock } from 'src/modules/financials/blocks/receipt-funds-payment-details';
import { FinancialEntity } from 'src/modules/financials/utils/financial-entity-action-group/use-get-financial-entity-actions';
import { tasksModel } from 'src/modules/tasks/common/models/tasks-model';
import { transformToRecordObject } from 'utils/records/transform-to-record-data';
import { transformToSearchEntry } from 'utils/transform-to-search-entry';
import { useToast } from 'view/components/@luna/notifications/toast';
import { api } from 'utils/api/api-client';
import { AlfredFile } from 'src/modules/common/types/file';
import { FLAGS } from 'utils/feature-flags';
import { useFeatureFlags } from 'view/components/@luna/feature-flags';
import { NoteCountBadge } from 'src/modules/note/components/note-count-badge';
import { useGetResetTableUrlParams } from 'view/components/table/hooks/use-get-reset-table-url-params';
import { defaultNotesRecordTableHashParamKey } from 'src/modules/note/components/notes-list';
import { receiptFundsDetailsBlock } from '../../financials/blocks/receipt-funds-details';
import {
  createTrustJournalEntryLineItemBlock,
  ReceiptingFundsLineItemEntry
} from '../../financials/blocks/trust-journal-entry-line-items';
import { ReceiptFundsDialogButtonGroup } from '../components/receipt-funds-dialog-button-group';
import {
  ReceiptPaymentMethods,
  TrustJournalEntry
} from '../types/trust-journal-entry-types';
import { bankTransactionNotesTableBlock } from '../blocks/bank-transaction-notes-table';

function useReceiptFundsDialogContent(
  bankStatementTransactionId?: string
): DialogContentConfig {
  const { hasFeature } = useFeatureFlags();

  return [
    {
      id: 'basics',
      label: 'Receipt funds',
      blocks: [
        receiptFundsDetailsBlock,
        receiptFundsPaymentDetailsBlock,
        createTrustJournalEntryLineItemBlock
      ]
    },
    ...(hasFeature(FLAGS.BATCH_RECEIPTING_SUSPENSION_FLOW) &&
    bankStatementTransactionId
      ? [
          {
            id: 'notes',
            label: 'Notes',
            IconEnd: (props) => (
              <NoteCountBadge
                filter={[
                  {
                    field: 'bank_statement_transaction_id',
                    op: 'eq',
                    value: bankStatementTransactionId
                  }
                ]}
                {...props}
              />
            ),
            blocks: [bankTransactionNotesTableBlock]
          }
        ]
      : [])
  ];
}

export interface ReceiptFundsDialogProps extends DialogProps {
  folio?: FinancialEntity;
  receiptFrom?: Contact[];
  amount?: number;
  bankAccount?: BankAccount;
  reference?: string;
  description?: string;
  bankTransactionFile?: AlfredFile;
  dateOf?: string;
  paidBy?: string;
  paymentMethod?: ValueListValue<ReceiptPaymentMethods>;
  bankStatementTransactionId?: string;
  invoiceId?: string;
  onCreate?: (args?: TrustJournalEntry) => any;
  showCreateAnotherButton?: boolean;
  matchStatus?: MatchStatusId;
  allowUpdateTenantReference: boolean;
  showTransactionFileDetails?: boolean;
}

export function ReceiptFundsDialog({
  folio,
  bankAccount,
  amount,
  receiptFrom,
  reference,
  description,
  bankTransactionFile,
  dateOf,
  paidBy,
  paymentMethod,
  invoiceId,
  bankStatementTransactionId,
  onClose,
  onCreate,
  matchStatus,
  showCreateAnotherButton,
  allowUpdateTenantReference,
  showTransactionFileDetails = false,
  ...props
}: ReceiptFundsDialogProps) {
  const dialogContent = useReceiptFundsDialogContent(
    bankStatementTransactionId
  );
  const { defaultBankAccount, isLoading } = useBankAccounts();
  const { refreshLists } = useModelActions(trustJournalEntryModel);
  const {
    refreshItem: refreshInvoice,
    refreshLists: refreshInvoiceLists
  } = useModelActions(financialsInvoicesModel);
  const createTrustJournalEntry = useGetSubmitTrustJournalEntry({
    shouldUseSyncEndpoint: !!bankStatementTransactionId
  });
  const {
    createTjeAndLinkToTransactionInPipeline
  } = useCreateTrustJournalEntryAndLinkTransaction();
  const addRecordCreatedToast = useRecordCreatedToast(trustJournalEntryModel);
  const { addToast } = useToast();
  const { refreshLists: refreshTaskLists } = useModelActions(tasksModel);

  const { open: openMatchToExistingReceiptDialog } = useDialog(
    MatchToExistingReceiptDialog
  );

  const queryClient = useQueryClient();

  const resetTableUrlParams = useGetResetTableUrlParams();

  const handleSubmit = useCallback(async ({ values, changedValues }) => {
    const formValues = {
      ...values,
      // todo: Remove this as this is a patch for the multi select not updating form values properly when updated
      contacts: changedValues.contacts
        ? changedValues.contacts
        : values.contacts
    };

    //TODO: We really should be typing our `formValues` as this is a pain to debug
    const lineItems = formValues.line_items
      .filter((lineItem: ReceiptingFundsLineItemEntry) => {
        return typeof lineItem.amount === 'number' && lineItem.amount > 0;
      })
      .map((lineItem: ReceiptingFundsLineItemEntry) => {
        if (lineItem.category === 'deposit') {
          return {
            amount: lineItem.amount,
            deposit: {
              description: lineItem.description,
              ledger: lineItem.deposit?.ledger,
              prepayment_bucket: lineItem.deposit_into?.bucket_id
                ? { id: lineItem.deposit_into?.bucket_id }
                : undefined,
              // supply property for rent prepayment bucket (if relevant)
              property: lineItem.deposit_into?.property?.id
                ? { id: lineItem.deposit_into?.property?.id }
                : undefined,
              deposit_into: undefined
            }
          };
        }

        if (lineItem.category === 'invoice') {
          return lineItem;
        }

        if (lineItem.category === 'rent') {
          return {
            amount: lineItem.amount,
            rent: {
              property: {
                id: lineItem.rent.property.id
              }
            }
          };
        }
      });

    const receiptData = {
      ...formValues,
      line_items: lineItems,
      // This flag isn't used on BE.
      send_receipt: false
    };

    // pipeline for batch receipting, as match action run privilege validation
    // if privilege validation fails - fail all
    const { data } = bankStatementTransactionId
      ? await createTjeAndLinkToTransactionInPipeline(
          bankStatementTransactionId,
          receiptData
        )
      : await createTrustJournalEntry(receiptData);

    const refreshRequests: Array<Promise<void | AxiosResponse>> = [
      refreshLists(),
      refreshInvoiceLists(),
      refreshTaskLists()
    ];

    if (invoiceId) {
      refreshRequests.push(
        refreshInvoice({
          id: invoiceId
        })
      );
    }

    await Promise.all(refreshRequests);

    lineItems.forEach((lineItem) => {
      if (lineItem.category === 'invoice') {
        refreshInvoice({
          id: lineItem.invoice_payment.invoice.id
        });
      }
    });

    // If we have a bank statement transaction ID, we're opening this from batch receipting.
    // Batch receipting is still synchronous(see useGetSubmitTrustJournalEntry below)
    // so we have the newly created trust journal entry ID for the toast
    // and for matching the bank statement transaction to the trust journal entry.
    if (bankStatementTransactionId) {
      addRecordCreatedToast(
        data[`trust_journal_entry-${bankStatementTransactionId}`],
        <>
          You have successfully receipted funds for the{' '}
          <b>{formValues.folio.label}</b> folio
        </>
      );

      await queryClient.refetchQueries({
        queryKey: [bankStatementTransactionsQueryKey],
        refetchPage: () => true
      });
    } else {
      addToast({
        description: 'The receipt is being processed.',
        type: 'info'
      });
    }

    await onCreate?.();

    return true;
  }, []);

  const initialData = {
    folio: folio
      ? transformToSearchEntry(transformToRecordObject(folio))
      : undefined,
    contacts: receiptFrom,
    ...(dateOf ? { date_of: dateOf } : {}),
    bank_account: bankAccount ?? defaultBankAccount,
    send_receipt: true,
    paid_by: paidBy,
    matchStatus: matchStatus,
    amount,
    description,
    bankTransactionFile,
    bankStatementTransactionId,
    payment_information: {
      method: paymentMethod || {
        id: 'eft',
        label: 'EFT'
      },
      reference: reference || null
    }
  };

  const handleClose = useCallback(() => {
    onClose?.();
    resetTableUrlParams({
      resetPageTableKeys: [defaultNotesRecordTableHashParamKey]
    });
  }, [onClose, resetTableUrlParams]);

  const handleMatchToExistingReceipt = useCallback(() => {
    amount &&
      openMatchToExistingReceiptDialog({
        data: {
          amount,
          reference,
          dateOf,
          bankAccount,
          bankStatementTransactionId
        },
        closeParentDialog: handleClose
      });
  }, [openMatchToExistingReceiptDialog, amount]);

  return (
    <RecordDialog
      {...props}
      size='l'
      title='Receipt funds'
      isLoading={isLoading}
      onClose={handleClose}
      data={initialData}
      handleSubmit={handleSubmit}
      content={dialogContent}
      ButtonGroup={ReceiptFundsDialogButtonGroup}
      buttonGroupProps={{
        bankStatementTransactionId,
        showCreateAnotherButton,
        handleMatchToExistingReceipt: bankStatementTransactionId
          ? handleMatchToExistingReceipt
          : undefined
      }}
      blockProps={{
        renderBatchReceiptingDialog: !!bankStatementTransactionId,
        allowUpdateTenantReference,
        showTransactionFileDetails,
        hideAddNoteToField: true
      }}
      autofocusIndex={4}
    />
  );
}

// Temporary until we update batch receipting on BE to be async
const useGetSubmitTrustJournalEntry = ({ shouldUseSyncEndpoint }) => {
  const { createItem } = useModelActions(trustJournalEntryModel);

  if (shouldUseSyncEndpoint) {
    return (receiptData) =>
      createItem({
        data: {
          receipt: receiptData
        },
        args: {
          include: 'line_items,line_items.trust_ledger'
        }
      });
  }

  return (receiptData) =>
    api
      .post('financials/bulk-receipt', {
        actions: { for_objects: [{ receipt: receiptData }] }
      })
      .then((response) => response.data);
};
