import React, { useMemo } from 'react';
import {
  query,
  useEntityListQuery,
  useEntityQuery
} from '@rexlabs/model-generator';

import { contactsModel } from 'src/modules/contacts/models/contacts';
import { getComplianceEntriesByIdAndTypeQuery } from 'src/modules/compliance/common/data/compliance-entries-query';
import { Contact } from 'src/modules/contacts/types/contact-types';

import { filterContentBy } from 'utils/record-screen/filter-content';

import { RecordScreen } from 'view/components/record-screen';
import { useRecordScreenSubmitHandler } from 'view/hooks/use-record-screen-submit-handler';
import { BREADCRUMBS } from 'view/components/@luna/breadcrumbs';
import { ContentConfig } from 'view/components/record-screen/types';

import { useContent } from '../data/content';
import { ContactTitleBlock } from '../components/contact-title-block';
import { useGetMapContactDataToForm } from '../mappers/map-contact-data-to-form';
import { useGetMapContactFormToData } from '../mappers/use-get-map-contact-form-to-data';

export const contactIncludes = [
  'compliance_categories',
  'compliance_categories.category_requirements',
  'addresses',
  'emails',
  'phones',
  'ownerships',
  'tenancies',
  'bpay',
  'disbursement_withheld_funds',
  'disbursement_payment_methods',
  'disbursement_payment_methods.contact_payment_method',
  'disbursement_payment_methods.contact',
  'roles',
  'logo',
  'created_by.contact',
  'updated_by.contact',
  'financial_summary',
  'trust_summary',
  'tax'
].join(',');

function filterContactContent(content: ContentConfig, contact?: Contact) {
  if (!contact) return content;

  let whiteLabelList: string[] = [];

  if (contact.is_supplier) {
    whiteLabelList = [...whiteLabelList, '[supplier]', '[SUPPLIER]'];
  }

  if (contact.is_business) {
    whiteLabelList = [...whiteLabelList, '[BUSINESS]'];
  }

  if (contact.is_security_deposit_authority || contact.is_tax_authority) {
    whiteLabelList = [...whiteLabelList, '[DEPOSIT AUTHORITY]'];
  }

  if (contact.ownerships?.data.length) {
    whiteLabelList = [...whiteLabelList, '[owner]', '[OWNER]'];
  }

  if (contact.tenancies?.data.length) {
    whiteLabelList = [...whiteLabelList, '[tenant]', '[TENANT]'];
  }

  return filterContentBy(content, whiteLabelList, ['[']);
}

interface ContactDetailsScreenProps {
  contactId: string;
}

const getContactQuery = (contactId: string) => query`{
  ${contactsModel} (id: ${contactId}) {
    id
    name
    addresses
    compliance_categories
    emails
    phones
    ownerships
    tenancies
    disbursement_payment_methods
    bpay
    disbursement_withheld_funds
    financial_summary
    trust_summary
    created_by {
      contact
    }
    updated_by {
      contact
    }
    roles
    logo
    tax
  }
}`;

export function ContactDetailsScreen({ contactId }: ContactDetailsScreenProps) {
  const breadcrumbs = [{ type: BREADCRUMBS.CONTACT }];
  const contactQuery = useMemo(() => getContactQuery(contactId), [contactId]);
  const { data, status, actions } = useEntityQuery(contactQuery, {
    throwOnError: false
  });

  const complianceEntriesQuery = useMemo(
    () =>
      getComplianceEntriesByIdAndTypeQuery({
        objectId: contactId,
        objectType: 'supplier'
      }),
    [contactId]
  );
  const {
    data: complianceEntryData,
    status: complianceEntryStatus
  } = useEntityListQuery(complianceEntriesQuery);

  const content = useContent(data, complianceEntryData);

  const getMapContactFormToData = useGetMapContactFormToData();
  const getMapContactDataToForm = useGetMapContactDataToForm();

  const handleSubmit = useRecordScreenSubmitHandler<Contact>(
    async ({ values, changedValues }) => {
      const mappedData = await getMapContactFormToData(values, changedValues);
      await actions.updateItem({
        id: contactId,
        data: mappedData,
        args: {
          include: contactIncludes
        }
      });
    }
  );

  return (
    <RecordScreen
      initialValues={getMapContactDataToForm(data)}
      isLoading={status === 'loading' || complianceEntryStatus === 'loading'}
      data={data}
      handleSubmit={handleSubmit}
      content={filterContactContent(content, data)}
      titleBlock={<ContactTitleBlock contact={data as Contact} />}
      breadcrumbs={breadcrumbs}
    />
  );
}
