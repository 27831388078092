import { getUploadedFileMeta } from 'utils/files';
import { EntryExitInspectionFormData } from '../types/EntryExitInspectionFormData';

export async function mapCoverPageFormDataToRequest(
  formData: Partial<EntryExitInspectionFormData>
) {
  return {
    details: {
      ...(formData?.cover_summary || formData?.cover_summary === ''
        ? { cover_summary: formData?.cover_summary }
        : {}),
      ...(formData?.cover_image || formData?.cover_image === null
        ? {
            cover_image: formData?.cover_image
              ? (await getUploadedFileMeta([formData?.cover_image]))[0]
              : null
          }
        : {})
    }
  };
}
