import React, { useCallback } from 'react';
import { GhostButton, PrimaryButton } from '@rexlabs/button';
import { ButtonGroupProps } from 'view/components/record-screen/dialog/types';
import { StandardDialogFooter } from 'view/components/dialogs/components/standard-dialog-footer';
import { ReceiptingFundsLineItemEntry } from 'src/modules/financials/blocks/trust-journal-entry-line-items';
import { formatCurrency } from 'utils/formatters';

function getAllocatedAmount(items: ReceiptingFundsLineItemEntry[]) {
  return items.reduce((total, item) => total + (item.amount ?? 0), 0);
}

function getSubmitButtonLabel(
  submitLabel: React.ReactNode,
  allocatedAmount: number,
  availableBalance: number
) {
  if (availableBalance === 0) {
    return `Not enough available balance`;
  }

  if (allocatedAmount > availableBalance) {
    const overAllocatedAmount = allocatedAmount - availableBalance;
    return `${formatCurrency(overAllocatedAmount)} over allocated`;
  }

  if (allocatedAmount > 0) {
    return `Transfer ${formatCurrency(allocatedAmount)}`;
  }

  return submitLabel;
}

export function TransferFundsDialogButtonGroup({
  handleSubmit,
  onClose,
  isSubmitting,
  forms,
  submitLabel
}: ButtonGroupProps) {
  const availableBalance =
    forms?.['transfer-funds-details'].values?.available_amount ?? 0;
  const allocatedAmount = getAllocatedAmount(
    forms?.['trust-journal-entry-line-items'].values.line_items ?? []
  );

  const isSubmitDisabled =
    availableBalance === 0 ||
    allocatedAmount === 0 ||
    allocatedAmount > availableBalance;

  const handleSubmitAndClose = useCallback(async () => {
    const result = await handleSubmit();

    if (!result) return;

    onClose?.();
  }, [handleSubmit, onClose]);

  return (
    <StandardDialogFooter>
      <GhostButton onClick={onClose}>Cancel</GhostButton>
      <PrimaryButton
        onClick={handleSubmitAndClose}
        isDisabled={isSubmitDisabled}
        isLoading={isSubmitting}
      >
        {getSubmitButtonLabel(submitLabel, allocatedAmount, availableBalance)}
      </PrimaryButton>
    </StandardDialogFooter>
  );
}
