import React from 'react';
import { EmptySection } from './base';

export function EmptyPropertyOwnership() {
  return (
    <EmptySection
      headline='Relate an ownership to this record'
      buttonLabel='View ownerships'
      linkProps={{ query: { tab: 'management-agreements' } }}
    >
      Choose from an existing ownership or create a new ownership for this
      record.
    </EmptySection>
  );
}
