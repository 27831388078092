import { isEmpty, isObject, omit } from 'lodash';
import { api } from 'utils/api/api-client';
import { PreviewData } from 'src/modules/properties/types/preview-data';
import { useDialog, useErrorDialog } from '@rexlabs/dialog';
import { RegenerateInvoicesForPropertyTenancyDialog } from 'src/modules/property-tenancies/dialogs/regenerate-invoices-for-property-tenancy-dialog';
import { PropertyTenancy } from 'src/modules/property-tenancies/types/property-tenancy-types';

const keyPartsToOmitForInvoiceRegen = ['rent_review', 'routine_inspection'];

interface RegenLeaseAgreementInvoicesParams {
  propertyTenancyId?: string;
  changedPropertyTenancyValues?: Partial<PropertyTenancy> | null;
}

export function useRegenLeaseAgreementInvoices() {
  const { open: openInvoiceRegenDialog } = useDialog(
    RegenerateInvoicesForPropertyTenancyDialog
  );

  const errorDialog = useErrorDialog();

  return async ({
    propertyTenancyId,
    changedPropertyTenancyValues
  }: RegenLeaseAgreementInvoicesParams) => {
    if (!propertyTenancyId || !isObject(changedPropertyTenancyValues)) return;

    const taskKeysIrrelevantToInvoiceRegen = Object.keys(
      changedPropertyTenancyValues
    ).filter((key) =>
      keyPartsToOmitForInvoiceRegen.some((part) => key.includes(part))
    );

    // Handle cases where we need to refresh the invoices
    const shouldRegenInvoices = !isEmpty(
      omit(changedPropertyTenancyValues, [
        'additional_clauses',
        ...taskKeysIrrelevantToInvoiceRegen
      ])
    );
    if (shouldRegenInvoices) {
      try {
        // make a request to the preview endpoint to see if the rent schedule needs to be regenerated
        const { data: previewData } = await api.get<PreviewData>(
          `/property-tenancies/${propertyTenancyId}/regenerate-invoices`
        );

        // check the preview data, see if we have any invoices that need to be regenerated
        const { invoicesToCreate, invoicesToCancel } = previewData;

        if (invoicesToCreate > 0 || invoicesToCancel > 0) {
          openInvoiceRegenDialog({ propertyTenancyId });
        }
      } catch (error) {
        errorDialog.open(error);
      }
    }
  };
}
