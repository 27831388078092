import * as React from 'react';
import { ValueListItem } from 'utils/normaliser/value-list-item';
import { Dropdown, DropdownProps } from 'view/components/@luna/dropdown';
import { StatusTagMap } from 'src/modules/common/components/status-tag';
import { StatusGoodTag } from '@rexlabs/tag';
import { Task } from 'src/modules/tasks/common/types/Task';

const intentMap: StatusTagMap<'not_started' | 'in_progress'> = {
  not_started: 'neutral',
  in_progress: 'average'
};

interface TaskStatusMenuProps
  extends Omit<DropdownProps<ValueListItem>, 'normaliser' | 'items'> {
  task?: Task<'task_arrears'>;
}

export function TaskStatusMenu({ task, ...props }: TaskStatusMenuProps) {
  const data = [
    { id: 'not_started', label: 'Not Started' },
    { id: 'in_progress', label: 'In Progress' }
  ];

  return task?.details?.status?.id === 'resolved' ? (
    <StatusGoodTag>Resolved</StatusGoodTag>
  ) : (
    <Dropdown<ValueListItem>
      items={data}
      normaliser={({ id, label }) => ({ id, label, intent: intentMap[id] })}
      {...props}
    />
  );
}
