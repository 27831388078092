import React, { useMemo } from 'react';

import { Field } from '@rexlabs/form';
import { TextArea, TextInput } from '@rexlabs/text-input';
import { BlockConfig } from 'view/components/record-screen/types';
import { Value } from 'view/components/values';
import { Grid, Column } from 'view/components/@luna/form/grid';
import { TaskSelect } from 'view/components/inputs/selects/v4-selects/task-select';
import { PropertySelect } from 'view/components/inputs/selects/v4-selects/property-select';
import { ContactSelect } from 'view/components/inputs/selects/contact';
import { WorkOrderTask } from '../types/WorkOrderTask';
import { WorkOrderFormData } from '../types/WorkOrderFormData';
import { QuoteTask } from '../../quotes/types/QuoteTask';
import { WorkDoneByField } from '../components/work-done-by-field';
import { MaintenanceTask } from '../../maintenance/types/MaintenanceTask';

type DetailsBlockDetailsValues = Pick<
  WorkOrderFormData['details'],
  'work_done_by_type' | 'quote'
>;

export type DetailsBlockValues = Pick<
  WorkOrderFormData,
  'property' | 'parent_task' | 'managed_by' | 'summary' | 'description'
> & { details: DetailsBlockDetailsValues };

function getRelatesToTaskFilters({ values }: { values?: DetailsBlockValues }) {
  return [
    {
      field: 'type_id',
      op: 'in',
      value: ['task_maintenance']
    },
    {
      field: 'closed_at',
      op: 'eq',
      value: 'null'
    },
    ...(values?.property?.id
      ? [
          {
            field: 'property_id',
            op: 'eq',
            value: values.property.id
          }
        ]
      : [])
  ];
}

function getAssociatedQuoteFilters({
  parentTask
}: {
  parentTask: QuoteTask | MaintenanceTask;
}) {
  return [
    {
      field: 'type_id',
      op: 'in',
      value: ['task_quote']
    },
    ...(parentTask?.id
      ? [
          {
            field: 'parent_task_id',
            op: 'eq',
            value: parentTask.id
          }
        ]
      : [])
  ];
}

export const detailsBlock: BlockConfig<
  WorkOrderTask,
  any,
  DetailsBlockValues
> = {
  id: 'details',
  title: 'Details',
  validate: {
    definitions: {
      summary: { rules: 'required' },
      property: { rules: 'required' },
      managed_by: { rules: 'required' }
    }
  },
  View: ({ data }) => {
    return (
      <Grid columns={2}>
        <Column width={2}>
          <WorkDoneByField data={data} />
        </Column>
        <Column width={2}>
          <Value label='Description' value={data?.description} />
        </Column>
        <Value label='Property' value={data?.property?.address_string} />
        <Value label='Relates to task' value={data?.parent_task?.summary} />
        <Value label='Managed by' value={data?.managed_by?.display_name} />
      </Grid>
    );
  },
  Edit: ({ data, values, forms, getHandlers }) => {
    const handlers = getHandlers?.(forms);

    // if there is no id then we're in create mode
    const isCreateMode = !data?.id;

    // if we are in create mode and there is a parent_task then
    // we're in create mode from another task
    const isCreateFromTask = useMemo(
      () => isCreateMode && values?.parent_task,
      []
    );

    return (
      <Grid columns={1}>
        {!isCreateMode ? (
          <Grid columns={1}>
            <WorkDoneByField data={data} />
          </Grid>
        ) : null}
        {values?.details?.work_done_by_type === 'supplier' && isCreateMode && (
          <Grid columns={2}>
            <Field
              id='quote'
              name='details.quote'
              label='Associated quote'
              Input={TaskSelect}
              inputProps={{
                filter: getAssociatedQuoteFilters({
                  parentTask: forms?.details?.values?.parent_task
                }),
                includes: [
                  'parent_task',
                  'parent_task.property',
                  'parent_task.managed_by',
                  'parent_task.details.access_provided_by',
                  'parent_task.details.images',
                  'parent_task.details.associated_quote',
                  'details',
                  'details.supplier',
                  'details.access_provided_by'
                ],
                deselectable: true
              }}
              onChange={handlers?.handleQuoteChange}
            />
          </Grid>
        )}
        <Grid columns={1}>
          <Field
            id='summary'
            name='summary'
            label='Summary'
            Input={TextInput}
          />
        </Grid>
        <Grid columns={1}>
          <Field
            id='description'
            name='description'
            label='Description'
            Input={TextArea}
          />
        </Grid>
        {!isCreateFromTask && (
          <>
            <Grid columns={2}>
              {!isCreateMode ? (
                <>
                  <Value
                    label='Property'
                    value={data?.property?.display_name}
                  />
                  <Value
                    label='Relates to task'
                    value={data?.parent_task?.summary}
                  />
                </>
              ) : (
                <>
                  <Field
                    id='property'
                    name='property'
                    label='Property'
                    Input={PropertySelect}
                    onChange={handlers?.handlePropertyChange}
                    inputProps={{
                      includes: [
                        'active_property_ownership.ownership.owners.contact.roles',
                        'active_property_tenancy.tenancy.tenants.contact'
                      ]
                    }}
                  />
                  <Field
                    id='parent_task'
                    name='parent_task'
                    label='Relates to task'
                    Input={TaskSelect}
                    onChange={handlers?.handleRelatedTaskChange}
                    inputProps={{
                      filter: getRelatesToTaskFilters({ values }),
                      includes: [
                        'property',
                        'property.active_property_ownership.ownership.owners.contact.roles',
                        'managed_by',
                        'details.access_provided_by',
                        'details.images',
                        'details.associated_quote',
                        'details.disbursement_withheld_funds'
                      ],
                      deselectable: true
                    }}
                  />
                </>
              )}
            </Grid>
            <Grid columns={2}>
              <Field
                id='managed_by'
                name='managed_by'
                label='Managed by'
                Input={ContactSelect}
              />
            </Grid>
          </>
        )}
      </Grid>
    );
  }
};
