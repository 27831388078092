import { getSearchResultItemFromObjectAndModel } from 'src/modules/common/utils/search-result-items/get-search-result-items-from-object-and-model';
import { contactsModel } from 'src/modules/contacts/models/contacts';
import { Contact } from 'src/modules/contacts/types/contact-types';
import { api } from 'utils/api/api-client';

export async function getRecipients(messageTemplate, relatesTo) {
  const contextTypeId = messageTemplate.context_type.id;
  const recipientGroupName = messageTemplate.default_recipient_group;

  if (!relatesTo?.id) return;

  const response = await api.get<Contact[]>(
    `/communications/contexts/${contextTypeId}/${relatesTo.id}/${recipientGroupName}`
  );

  const contacts = response.data;

  // need to transform them to search result items
  const searchResultItems = contacts.map(
    (contact) =>
      getSearchResultItemFromObjectAndModel<Contact>(contact, contactsModel)!
  );

  return searchResultItems;
}
