import { AxiosResponse } from 'axios';
import { api } from './api-client';

export type RequestPipelineMethod = 'POST' | 'GET' | 'PUT' | 'DELETE' | 'PATCH';

export type RequestPipelineRequest = {
  method: RequestPipelineMethod;
  path: string;
  json?: Record<string, any>;
};

export type RequestPipelineRequestWithId = RequestPipelineRequest & {
  id: string;
};

// backend/app/RequestPipelines/README.md
export type RequestPipeLineRequests =
  | RequestPipelineRequestWithId[]
  | RequestPipelineRequest[];

type RequestPipelineResponse<
  Requests,
  ResponseDataType
> = Requests extends Array<{ id: string }>
  ? Record<string, ResponseDataType>
  : ResponseDataType;

export async function postRequestPipelineRequests<
  Requests extends RequestPipeLineRequests,
  ResponseDataType
>(
  pipelineRequests: Requests
): Promise<AxiosResponse<RequestPipelineResponse<Requests, ResponseDataType>>> {
  return api.post('request-pipelines', {
    requests: pipelineRequests
  });
}
