import React from 'react';
import { BlockConfig } from 'view/components/record-screen/types';
import { Grid } from 'view/components/@luna/form/grid';
import { Field } from '@rexlabs/form';
import { TextInput } from '@rexlabs/text-input';
import { CentAmountInput } from 'view/components/inputs/cent-amount';
import { SecurityDepositTypeHeldBy } from 'data/models/entities/financials/security-deposit-types';

export const securityDepositRequestBlock: BlockConfig = {
  id: 'security-deposit-request',
  title: 'Security deposit request',
  validate: {
    definitions: {
      lodgement_reference: {
        rules: 'required'
      },
      payment_reference: {
        rules: 'required'
      },
      amount: {
        rules: 'required'
      }
    }
  },
  Edit: ({ values }) => {
    const heldBy = values.security_deposit.security_deposit_type.held_by
      .id as SecurityDepositTypeHeldBy;

    return (
      <Grid columns={2}>
        <Field
          name='lodgement_reference'
          label='Lodgement number'
          Input={TextInput}
        />
        {heldBy !== 'held_by_agency' && (
          <Field
            name='payment_reference'
            label='BPAY customer reference number'
            Input={TextInput}
          />
        )}

        <Field name='amount' label='Amount requested' Input={CentAmountInput} />
      </Grid>
    );
  }
};
