import React from 'react';

import { BlockConfig } from 'view/components/record-screen/types';

import { useMessageTriggerSettings } from '../settings/message-triggers/hooks/use-message-trigger-settings';
import { CreateMessageFormData } from '../mappers/types/create-message-form-data';
import { CommunicationContextType } from '../../common/types/communication-context-type';
import { EditEmailTemplateContentForm } from '../components/message-content-forms/edit-email-template-content-form';
import { validateEditEmailForm } from '../components/message-content-forms/basic-forms/basic-edit-email-form';

export interface ModifiedTemplateContentBlock
  extends Pick<
    CreateMessageFormData,
    | 'attachments'
    | 'subject'
    | 'content'
    | 'linked_files'
    | 'include_generated_attachments'
  > {
  context_type?: CommunicationContextType;
}

// TODO: There are now 3 blocks that pretty much do the same thing - we need to make this more reusable.
// Only difference is how we get the context type for the merge tag select.
export const modifiedTemplateContentBlock: BlockConfig<
  ModifiedTemplateContentBlock,
  any,
  ModifiedTemplateContentBlock
> = {
  id: 'content',
  title: 'Message Content',
  validate: validateEditEmailForm,
  Edit: ({ data, setFieldValue }) => {
    const {
      getTemplatedAttachmentForContextType
    } = useMessageTriggerSettings();
    return (
      <EditEmailTemplateContentForm
        contextType={data?.context_type}
        setFieldValue={setFieldValue}
        templatedAttachments={getTemplatedAttachmentForContextType(
          data?.context_type
        )}
      />
    );
  }
};
