import React from 'react';

import { RecordFlag } from 'view/components/record-flags/record-flag';
import { Task } from 'src/modules/tasks/common/types/Task';
import { TaskType } from 'src/modules/tasks/common/types/TaskType';

import { EntryList } from '../components/entry-list';
import { EntryLink } from '../components/entry-link';
import { EmptySection } from '../empty/base';

export interface TaskSectionProps {
  task: Task<TaskType> | 'empty';
}

export function TaskSection({ task }: TaskSectionProps) {
  if (task === 'empty') {
    return (
      <EmptySection headline='' buttonLabel=''>
        There is no task related to this record.
      </EmptySection>
    );
  }

  return (
    <EntryList>
      <EntryLink type={task.type.id} id={task.id}>
        <RecordFlag
          record={task}
          recordType={task.type.id}
          description={`${task.type.label} job`}
        />
      </EntryLink>
    </EntryList>
  );
}
