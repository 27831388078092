import { Generator, GeneratorConfig } from 'data/models/generator';
import { BankWithdrawalLineItem } from 'src/modules/banking/bank-withdrawals/types/bank-withdrawal-types';
import { trustJournalEntryModel } from 'src/modules/trust-journal-entries/models/trust-journal-entries';

export const bankWithdrawalLineItemConfig: GeneratorConfig = {
  entities: {
    related: {
      trust_journal_entry: {
        include: 'trust_journal_entry',
        model: trustJournalEntryModel
      }
    }
  }
};

export const bankWithdrawalLineItemModel = new Generator<BankWithdrawalLineItem>(
  'financials/bank-withdrawal-line-items',
  bankWithdrawalLineItemConfig
).createEntityModel();
