import React from 'react';
import { Heading } from '@rexlabs/text';
import { StyleSheet, useStyles, border, padding } from '@rexlabs/styling';

import { Card } from 'view/components/@luna/card';

const defaultStyles = StyleSheet({
  header: {
    ...padding.styles({
      x: 'l',
      y: 'l'
    })
  },
  heading: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  subtitle: {
    color: ({ token }) => token('color.textStyle.body.subtext')
  },
  content: {
    minHeight: '50px',
    ...border.styles({
      top: {
        width: 'thin',
        color: ({ token }) => token('color.border.container.static.light')
      }
    })
  },
  withPadding: {
    ...padding.styles({
      top: 'xs',
      right: 'xl',
      left: 'xl',
      bottom: 'xl'
    })
  }
});

type RightBarCardProps = {
  headline?: React.ReactNode;
  headlineRight?: React.ReactNode;
  subtitle?: React.ReactNode;
  children?: React.ReactNode;
  includePadding?: boolean;
};

export function RightBarCard({
  headline,
  headlineRight,
  subtitle,
  children,
  includePadding
}: RightBarCardProps) {
  const s = useStyles(defaultStyles);

  const childSection = children ? (
    <section
      {...s('content', {
        withPadding: includePadding
      })}
    >
      {children}
    </section>
  ) : null;

  return (
    <Card>
      {(headline || subtitle) && (
        <header {...s('header')}>
          <Heading level={3} {...s('heading')}>
            <div>{headline}</div>
            <div>{headlineRight}</div>
          </Heading>
          {subtitle && <div {...s('subtitle')}>{subtitle}</div>}
        </header>
      )}
      {childSection}
    </Card>
  );
}
