import React from 'react';
import { TextInput } from '@rexlabs/text-input';
import { Field } from '@rexlabs/form';
import { BlockConfig } from 'view/components/record-screen/types';
import { Column, Grid } from 'view/components/@luna/form/grid';
import { Value } from 'view/components/values';
import { PropertyAreaType } from 'src/modules/property-areas/property-area-types/types/PropertyAreaType';
import { Checkbox } from '@rexlabs/checkbox';
import { PropertyDetailsBanner } from 'src/modules/property-areas/property-area-types/components/property-details-banner';

const validate = {
  definitions: {
    name: { rules: 'required' }
  }
};

export const propertyAreaTypeDetailsBlock: BlockConfig<PropertyAreaType> = {
  id: 'details',
  validate,
  Edit: ({ data }) => {
    const systemPurpose = data?.system_purpose;
    return (
      <>
        <Grid columns={2}>
          {data?.system_purpose ? (
            <Value label={'Area name'} value={data?.name} />
          ) : (
            <Field name='name' label='Area name' Input={TextInput} />
          )}

          {!data?.is_property_details_type && (
            <Column width={2}>
              <Field
                name='is_created_on_new_properties'
                label=''
                optional={false}
                Input={Checkbox}
                inputProps={{
                  label: 'Include for new properties',
                  description:
                    "You can always remove a room that's added to a property by default if it's not needed."
                }}
              />
            </Column>
          )}
        </Grid>
        <div />
        {data?.is_property_details_type && systemPurpose && (
          <PropertyDetailsBanner systemPurpose={systemPurpose} />
        )}
      </>
    );
  }
};
