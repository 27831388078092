import { PendingContactDisbursement } from 'data/models/entities/financials/pending-disbursements/pending-contact-disbursement';
import { PendingOwnershipDisbursement } from 'data/models/entities/financials/pending-disbursements/pending-ownership-disbursement';
import React, { useCallback } from 'react';
import { Message } from 'view/components/@luna/message';
import { createStaticTableBlock } from 'view/components/record-screen/cards/create-static-table-block';
import { Invoice } from 'data/models/entities/financials/invoices';
import { useInvoiceActions } from 'src/modules/invoices/actions/use-invoice-actions';
import { transformActionDeclarationsToActionMenuItems } from 'utils/actions/transforms';
import { useTranslation } from 'react-i18next';
import { disbursementInvoiceColumns } from '../utils/disbursement-invoice-columns';
import EmptyTrustJournalEntriesTable from '../../../assets/illustrations/empty-trust-journal-entries-table.svg';

export const useOtherInvoicesBlock = () => {
  const { t } = useTranslation();
  const getInvoiceActions = useInvoiceActions();

  const getActionMenuItems = useCallback(({ item }) => {
    return transformActionDeclarationsToActionMenuItems(
      getInvoiceActions(item)
    );
  }, []);

  return createStaticTableBlock<
    PendingOwnershipDisbursement | PendingContactDisbursement,
    Invoice
  >({
    id: 'other-invoices',
    title: t('disbursements.eft-and-cheque-invoices.label'),
    getTableProps: ({ data }) => {
      return {
        id: 'other-invoices',
        columns: disbursementInvoiceColumns,
        items: data?.other_disburseable_invoices?.data ?? [],
        getActionMenuItems: getActionMenuItems
      };
    },
    Empty: () => (
      <Message
        Illustration={EmptyTrustJournalEntriesTable}
        title={t('disbursements.eft-and-cheque-invoices.no-invoices.label')}
      >
        {t('disbursements.eft-and-cheque-invoices.no-invoices.message')}
      </Message>
    )
  });
};
