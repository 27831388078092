export const TASK_SETTINGS_ROUTES = {
  TASK_SETTINGS_DETAILS: {
    config: {
      path: '/task-settings',
      Component: require('./screens/task-settings-detail.tsx')
        .TaskSettingsDetail
    }
  },
  CHECKLIST_DETAILS: {
    config: {
      path: '/checklist/:checklistId',
      Component: require('./screens/checklist-template-detail.tsx')
        .ChecklistTemplateDetail
    }
  }
};
