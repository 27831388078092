import { get } from 'lodash';

import { FileSelectEvent } from '@rexlabs/file-upload-input';
import { useErrorDialog } from '@rexlabs/dialog';
import { useReactFormsContext } from '@rexlabs/form';

import { api } from './api/api-client';

export async function onFileSelect({ file }: FileSelectEvent) {
  // When a file is added to the input, we upload it to the server so that we can use the ID in our upload request
  const formData = new FormData();
  formData.append('file', file);
  // This is poorly typed, but by returning the promise, we can use the ID of the file in the upload request
  return await api.post('/files', formData);
}

// HACK: This should be very temporary - we should be getting this error from BE, but this will do for now.
const fileTooLargeError = {
  message: 'File exceeds maximum file size limit'
};
// For some reason the error code is null when testing locally, but 413 when testing in pr env
const fileTooLargeErrorCodes = [null, 413];

export function useOnFileUploadWithErrorHandling() {
  const { open } = useErrorDialog();
  const { setFieldValue, values } = useReactFormsContext();

  return async ({
    file,
    fieldName
  }: FileSelectEvent & { fieldName: string }) => {
    try {
      return await onFileSelect({ file });
    } catch (err: any) {
      // NOTE: Incase the select is an array of images, we want to use this to set the
      // field back to the original value, if the upload fails.
      const field = get(values, fieldName);
      // NOTE: See comment above fileTooLargeError
      const error = fileTooLargeErrorCodes.includes(err.statusCode)
        ? fileTooLargeError
        : err;
      setFieldValue(fieldName, field);
      open(error);
    }
  };
}
