import * as React from 'react';
import { CellProps } from '@rexlabs/table';
import Box from '@rexlabs/box';
import {
  useStatusChangeAction,
  defaultStatusChangeTransformPayload,
  StatusChangeActionArgs
} from 'src/modules/common/actions/status/use-status-change-action';
import { tasksModel } from 'src/modules/tasks/common/models/tasks-model';
import { invokeActionDeclaration } from 'src/modules/common/actions/utils/invoke-action-declaration';

import { TaskStatusMenu } from 'src/modules/tasks/common/components/task-status-menu';
import { TaskStatusMenu as LeaseReviewStatusMenu } from 'src/modules/tasks/lease-review/components/task-status-menu';
import { TaskStatusMenu as MaintenanceStatusMenu } from 'src/modules/tasks/maintenance/components/task-status-menu';
import { TaskStatusMenu as InspectionsStatusMenu } from 'src/modules/tasks/inspections/components/task-status-menu';
import { TaskStatusMenu as ArrearsStatusMenu } from 'src/modules/tasks/arrears/components/task-status-menu';
import { TaskStatusMenu as QuoteStatusMenu } from 'src/modules/tasks/quotes/components/task-status-menu';
import { TaskStatusMenu as WorkOrdersStatusMenu } from 'src/modules/tasks/work-orders/components/task-status-menu';
import { ComplianceProgressDropdown } from 'src/modules/tasks/common/components/compliance-progress-dropdown';
import { useStatusDropdownRefresh } from 'src/modules/tasks/common/hooks/use-get-status-dropdown-refresh';

const statusMenuMap = {
  task_lease_review: LeaseReviewStatusMenu,
  task_maintenance: MaintenanceStatusMenu,
  task_inspection: InspectionsStatusMenu,
  task_arrears: ArrearsStatusMenu,
  task_quote: QuoteStatusMenu,
  task_work_order: WorkOrdersStatusMenu
};

export function AnyTaskProgressCell({ value: task }: CellProps) {
  const refreshListsOnComplete = useStatusDropdownRefresh();
  const getStatusChangeAction = useStatusChangeAction(tasksModel);

  const StatusMenu = React.useMemo(
    () => statusMenuMap[task?.type?.id] || TaskStatusMenu,
    [task?.type]
  );

  if (
    task?.type?.id === 'task_property_compliance' ||
    task?.type?.id === 'task_supplier_compliance'
  ) {
    return <ComplianceProgressDropdown value={task} />;
  }

  const value =
    task?.type?.id in statusMenuMap ? task.details?.status : task.status;

  const handleStatusChange = task
    ? (newStatus) => {
        invokeActionDeclaration<StatusChangeActionArgs<typeof newStatus.id>[]>(
          getStatusChangeAction,
          {
            record: task,
            status: newStatus.id,
            dialogOptions: {
              type: 'none',
              afterAction: refreshListsOnComplete
            },
            generalOptions: { avoidListRefresh: true },
            transformPayload:
              task?.type?.id in statusMenuMap
                ? (status) => {
                    return {
                      type: task.type,
                      details: {
                        status: {
                          id: status
                        }
                      }
                    };
                  }
                : defaultStatusChangeTransformPayload
          }
        );
      }
    : undefined;

  return (
    <Box
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
      data-testid='status-tag'
    >
      <StatusMenu
        task={task}
        initialValue={value}
        onChange={handleStatusChange}
      />
    </Box>
  );
}
