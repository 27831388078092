import React, { useCallback } from 'react';
import {
  TableProps,
  ToolbarProps,
  useTableQuery,
  UseTableQueryArgs,
  useUrlState,
  UseUrlStateArgs
} from '@rexlabs/table';

import { AnyModel, ModelTypeFromAnyModel } from '@rexlabs/model-generator';
import { TableProvider, TableProviderProps, Columns } from './provider';
import { DumbTable } from './dumb-table';

const PAGE_SIZES = [10, 20, 50, 100];

export type RecordTableProps<Model extends AnyModel<any, any>> = ToolbarProps &
  TableProps &
  UseTableQueryArgs<Model> &
  UseUrlStateArgs &
  Partial<Omit<TableProviderProps<ModelTypeFromAnyModel<Model>>, 'columns'>> & {
    columns?: Columns;
    withToolbar?: boolean;
    withPagination?: boolean;
    emptyWithoutTable?: boolean;
  };

export function RecordTable<Model extends AnyModel<any, any>>({
  withPagination,
  withToolbar,

  hiddenFilters,
  suggestedFilters,

  droppableId,
  Empty,
  Loading,
  striped,
  emptyWithoutTable,
  hashParamKey,
  pageSizes = PAGE_SIZES,
  initialSortBy,
  initialGlobalFilter,
  initialPageSize,
  initialPage,
  getQuery,
  getActionMenuItems: propGetActionMenuItems,
  ...props
}: RecordTableProps<Model>) {
  const { getTableProps: getUrlStateProps } = useUrlState({
    hashParamKey,
    initialSortBy,
    initialGlobalFilter,
    pageSizes,
    initialPageSize,
    initialPage
  });
  const { entity, getTableProps: getTableQueryProps } = useTableQuery({
    getQuery
  });

  const getActionMenuItems = useCallback(
    ({ item }) => {
      return propGetActionMenuItems?.({ entity, item });
    },
    [entity, propGetActionMenuItems]
  );

  const {
    items,
    isLoading,
    pagination: { totalItems = 0 } = {}
  } = getTableQueryProps();

  const showEmptyState =
    !isLoading && !items.length && Empty && totalItems === 0;

  let content = (
    <DumbTable
      withToolbar={withToolbar}
      withPagination={withPagination}
      suggestedFilters={suggestedFilters}
      hiddenFilters={hiddenFilters}
      droppableId={droppableId}
      Empty={showEmptyState ? Empty : undefined}
      Loading={Loading}
      striped={striped}
    />
  );

  if (isLoading && Loading && emptyWithoutTable) {
    content = <Loading />;
  }

  if (showEmptyState && emptyWithoutTable) {
    content = <Empty />;
  }

  return (
    <TableProvider
      {...getUrlStateProps()}
      {...getTableQueryProps()}
      {...props}
      getActionMenuItems={
        propGetActionMenuItems ? getActionMenuItems : undefined
      }
    >
      {content}
    </TableProvider>
  );
}
