import { EmptyCard } from 'view/components/record-screen/cards/empty-card';
import EmptyTable from 'src/assets/illustrations/empty-table.svg';
import { Message } from 'view/components/@luna/message';
import * as React from 'react';
import { useDialog } from '@rexlabs/dialog';
import { UpdateSupplierCommissionTemplateDialog } from '../dialogs/update-supplier-commission-template-dialog';

export function SupplierCommissionTemplatesEmptyState() {
  const updateSupplierCommissionTemplateDialog = useDialog(
    UpdateSupplierCommissionTemplateDialog
  );
  return (
    <EmptyCard>
      <Message
        title='Supplier commission templates'
        Illustration={EmptyTable}
        actions={[
          {
            label: 'Add template',
            intent: 'primary',
            handleAction: () => {
              updateSupplierCommissionTemplateDialog.open();
            }
          }
        ]}
      >
        There are currently no templates
      </Message>
    </EmptyCard>
  );
}
