import { Property } from 'src/modules/properties/types/property-types';
import { api } from 'utils/api/api-client';

export async function getOwnershipFromProperty(property: Property) {
  const includes = [
    'active_property_ownership',
    'active_property_ownership.ownership'
  ];

  const propertyUrl = `/properties/${property.id}?include=${includes.join(
    ','
  )}`;

  return await api
    .get(propertyUrl)
    .then(({ data }) => data?.active_property_ownership?.ownership);
}
