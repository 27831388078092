import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { AgGridReact } from 'ag-grid-react'; // the AG Grid React Component

import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
import 'ag-grid-community/styles/ag-theme-material.css'; // Optional theme CSS
import '../styles/rex-pm-theme.css';
import { StyleSheet, useStyles } from '@rexlabs/styling';
import { useQuery } from 'react-query';
import { api } from 'utils/api/api-client';
import Box from '@rexlabs/box';
import { BlockConfig } from 'view/components/record-screen/types';
import { ReactTableContext } from '@rexlabs/table';
import { filtersToQuri } from '@rexlabs/table/module/utils';
import { Toolbar } from 'view/components/table/toolbar';
import { OutlineButton } from '@rexlabs/button';
import { formatCurrency } from 'utils/formatters';
import { isArray } from 'lodash';
import { useToast } from 'view/components/@luna/notifications/toast';
import { ColDef, ColGroupDef } from 'ag-grid-community';
import { FieldType } from '../types/field-type';
import { GridRefContext } from '../screens/table-report-record';

const defaultStyles = StyleSheet({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    width: '100%'
  }
});

export const tableReportBlock: BlockConfig = {
  id: 'table-report',
  View: ({ data: { reportId } }) => {
    const { addToast } = useToast();
    const gridRef = React.useContext(GridRefContext);
    const [rowData, setRowData] = useState<unknown[]>([]); // Set rowData to Array of Objects, one Object per Row
    const reactContext = React.useContext(ReactTableContext);

    const { data: reportData } = useQuery(
      [
        'show-report',
        reportId,
        reactContext.state.globalFilter,
        reactContext.state.sortBy,
        reactContext.state.search
      ],
      {
        queryFn: () => {
          const urlQuery = urlQueryFromTableState(reactContext.state);
          const queryString = urlQuery.toString();

          return api.get<{
            id: string;
            label: string;
            columns: {
              field: string;
              label: string;
              type: FieldType;
            }[];
            rows: Record<string, unknown>[];
          }>(
            `/static-reports/${reportId}${queryString ? `?${queryString}` : ''}`
          );
        },
        select: (data) => data.data,
        enabled: !!reportId
      }
    );

    // Each Column Definition results in one Column.
    const [columnDefs, setColumnDefs] = useState<(ColDef | ColGroupDef)[]>([]);

    // DefaultColDef sets props common to all Columns
    const defaultColDef = useMemo<ColDef>(
      () => ({
        sortable: true
      }),
      []
    );

    useEffect(() => {
      if (reportData && reportData.columns && reportData.rows) {
        setColumnDefs(
          reportData.columns.map<ColDef>((column) => ({
            field: column.field,
            headerName: column.label,
            valueFormatter: mapFieldTypeToFormatter(column.type),
            filter:
              column.type === 'dateString'
                ? 'agDateColumnFilter'
                : 'agTextColumnFilter',
            // todo: should probably make this a proper renderer
            cellRenderer: mapFieldTypeToRenderer(column.type),
            type: column.type === 'currency' ? 'rightAligned' : undefined
          }))
        );
        setRowData(reportData.rows);
      }
    }, [reportData]);

    const s = useStyles(defaultStyles);

    const handleResetTable = useCallback(() => {
      gridRef?.current?.api?.resetColumnState();
      addToast({
        type: 'success',
        description: (
          <>
            The <strong>{reportData?.label}</strong> table has been reset.
          </>
        )
      });
    }, [addToast, gridRef, reportData?.label]);

    return (
      <div {...s('container')}>
        <Box
          alignSelf='start'
          justifyContent='space-between'
          style={{
            flex: 1,
            width: '100%'
          }}
        >
          <Toolbar hasColumnSelection={false} />
          <Box>
            <OutlineButton onClick={handleResetTable}>
              Reset table
            </OutlineButton>
          </Box>
        </Box>
        {/* On div wrapping Grid a) specify theme CSS Class Class and b) sets Grid size */}
        <div
          className='ag-theme-material'
          style={{ width: '100%', height: '100%' }}
        >
          <AgGridReact
            // @ts-ignore
            ref={gridRef} // Ref for accessing Grid's API
            rowData={rowData} // Row Data for Rows
            columnDefs={columnDefs} // Column Defs for Columns
            defaultColDef={defaultColDef} // Default Column Properties
            animateRows={true} // Optional - set to 'true' to have rows animate when sorted
            rowSelection='multiple' // Options - allows click selection of rows
            containerStyle={{ height: '900px' }}
          />
        </div>
      </div>
    );
  }
};

function mapFieldTypeToFormatter(fieldType: FieldType) {
  switch (fieldType) {
    case 'dateString':
      return (params) =>
        params.value
          ? new Date(params.value).toLocaleDateString()
          : params.value;
    case 'enum':
      return (params) => params.value?.label;
    default:
      return (params) => params.value;
  }
}

function mapFieldTypeToRenderer(fieldType: FieldType) {
  switch (fieldType) {
    case 'enum':
      return (params) => {
        return <span>{params.value?.label}</span>;
      };
    case 'currency':
      return (params) => <span>{formatCurrency(params.value)}</span>;
    default:
      return undefined;
  }
}

function urlQueryFromTableState(state: {
  globalFilter: unknown;
  sortBy: unknown;
  search: string;
}) {
  const query = state.globalFilter ? filtersToQuri(state.globalFilter) : null;

  // this is like [{ id: 'foo', desc: true }, { id: 'bar', desc: false }]
  const sort = state.sortBy;

  // we need it like `?o=foo+desc,bar+asc`
  const sortCombined = isArray(sort)
    ? sort.map((s) => `${s.id}+${s.desc ? 'desc' : 'asc'}`).join(',')
    : null;

  const search = state?.search;

  const urlQuery = new URLSearchParams();

  if (query) {
    urlQuery.set('q', query);
  }

  if (sortCombined) {
    urlQuery.set('o', sortCombined);
  }

  if (search) {
    urlQuery.set('search', search);
  }

  return urlQuery;
}
