import { ComplianceRequirementDescriptionCell } from 'src/modules/compliance/common/components/compliance-requirement-description-cell';
import { ComplianceRequirementNameCell } from 'src/modules/compliance/common/components/compliance-requirement-name-cell';
import { ComplianceType } from 'src/modules/compliance/common/models/compliance-types';
import { pluralize } from 'utils/formatters';
import { Columns } from 'view/components/table';

export const complianceRequirementSettingsColumns: Columns<ComplianceType> = [
  {
    id: 'name',
    accessor: (item) => item,
    Header: 'Name',
    Cell: ComplianceRequirementNameCell
  },
  {
    id: 'description',
    Header: 'Description',
    Cell: ComplianceRequirementDescriptionCell
  },
  {
    id: 'create_task',
    Header: 'Create Task',
    accessor: (item) =>
      item?.task_expiration_frequency?.unit?.label &&
      `${pluralize(
        item?.task_expiration_frequency?.unit?.label.toLowerCase(),
        item?.task_expiration_frequency?.count,
        true
      )} before expiry date`
  },
  {
    id: 'categories',
    Header: 'Services offered',
    accessor: (item) =>
      item!.applicable_to_object_type!.id == 'property'
        ? item.property_compliance_categories?.data.length
        : item.supplier_compliance_categories?.data.length,
    cellProps: {
      align: 'right'
    }
  }
];
