import React from 'react';

import { Select } from '@rexlabs/select';
import { normaliseValueListItem } from 'utils/normaliser/value-list-item';
import { SUPPLIER_COMPLIANCE_OBJECT_VALUE_VALUE_LIST } from 'utils/static-value-lists/supplier-compliance-object-values';

export const SupplierComplianceObjectValueSelect = (props) => (
  <Select
    items={SUPPLIER_COMPLIANCE_OBJECT_VALUE_VALUE_LIST}
    normaliser={normaliseValueListItem}
    {...props}
  />
);
