import { useDialog } from '@rexlabs/dialog';
import { CreateNoteRecordDialog } from 'src/modules/note/dialogs/create-note-record-dialog';
import { getAttachedTo } from 'src/modules/note/utils/get-attached-to';
import { Task } from 'src/modules/tasks/common/types/Task';

export type GetNotesActionArgs = { data: Task<any> };

export function useGetAddNotesAction() {
  const { open: openCreateDialog } = useDialog(CreateNoteRecordDialog);
  return ({ data }: GetNotesActionArgs) => ({
    label: 'Create note',
    id: 'create-note',
    group: 'add-or-send',
    handleAction: () => {
      openCreateDialog({
        initialValues: {
          attached_to: getAttachedTo(data, 'task')
        }
      });
    }
  });
}
