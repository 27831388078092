import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { PrimaryButton } from '@rexlabs/button';
import Box from '@rexlabs/box';
import { Body, Heading } from '@rexlabs/text';
import { Field } from '@rexlabs/form';
import { useToken } from '@rexlabs/styling';
import { RadioGroupInput } from '@rexlabs/radio-input';
import Tooltip from '@rexlabs/tooltip';

import { Ownership } from 'data/models/entities/ownerships';

import { BlockConfig } from 'view/components/record-screen/types';
import { RecordTable } from 'view/components/table';
import InfoCircleIcon from 'view/components/icons/info';

import { useBaseDisbursementMethodsColumns } from '../hooks/use-base-disbursement-methods-columns';
import { DisbursementPaymentMethodsEdit } from '../components/disbursement-payment-methods-edit';
import { PaymentMethodsBasedOnIncomeAttributionForm } from '../components/payment-methods-based-on-income-attribution-form';
import { DisburseBasedOnOwnerRadioLabel } from '../components/disburse-based-on-owner-radio-label';

export const ownershipDisbursementPaymentMethodsBlockV2: BlockConfig<
  Ownership,
  any,
  Ownership & {
    set_default_or_custom: 'default' | 'custom';
  }
> = {
  id: 'ownership-disbursement-preferences',
  title: () => (
    <Trans i18nKey='disbursements.blocks.ownership-disbursement-method.title' />
  ),
  TitleTooltip: () => (
    <Tooltip
      Content={() => (
        <Trans
          i18nKey={
            'disbursements.blocks.ownership-disbursement-method.title-tooltip'
          }
        />
      )}
    >
      <Box flexDirection='row' alignItems='center' width={24} height={24}>
        <InfoCircleIcon size={'m'} />
      </Box>
    </Tooltip>
  ),
  validate: {
    definitions: {
      'disbursement_payment_methods.*.contact_payment_method': {
        rules: 'required'
      },
      'disbursement_payment_methods.*.contact': { rules: 'required' },
      'disbursement_payment_methods.*.split_amount.amount': {
        rules: [
          'required',
          {
            key: 'check-100-percent',
            type: 'global',
            rule:
              'disbursementNotMoreThan100Percent|disbursementNotLessThan100Percent'
          }
        ]
      }
    }
  },
  showEmpty: (data) => !data.disbursement_payment_methods?.length,
  Empty: ({ onEditClick }) => {
    const { t } = useTranslation();
    const token = useToken();

    return (
      <Box
        justifyContent='center'
        alignItems='center'
        flexDirection='column'
        margin={token('spacing.xxxl')}
        style={{ textAlign: 'center' }}
      >
        <Box>
          <Heading level={3}>{t('disbursements.ownerships.set-up')}</Heading>
          <Body>{t('disbursements.ownerships.description')}</Body>
        </Box>
        <PrimaryButton onClick={onEditClick}>
          {t('disbursements.add-method')}
        </PrimaryButton>
      </Box>
    );
  },
  View: ({ data }) => {
    const disbursementMethodsColumns = useBaseDisbursementMethodsColumns();

    return (
      <RecordTable
        id='disbursement-payment-methods'
        items={data?.disbursement_payment_methods}
        isLoading={false}
        columns={disbursementMethodsColumns}
      />
    );
  },
  Edit: ({ values, data, setFieldValue, setValues }) => {
    const token = useToken();

    const [
      customDisbursementsPaymentMethods,
      setCustomDisbursementsPaymentMethods
    ] = useState(values?.disbursement_payment_methods || []);

    // This on change handler is triggered when the user changes the radio option to 'default' or 'custom'
    const handleSetDefaultOrCustomChange = ({ target: { value } }) => {
      // if the user selects 'default', we save the custom values and then set the split amounts to the owners shares
      if (value === 'default') {
        setCustomDisbursementsPaymentMethods(
          values?.disbursement_payment_methods || []
        );

        setValues?.({
          ...values,
          disbursement_payment_methods: (values?.owners || []).map(
            (owner, index) => {
              const ownerPaymentMethod: Ownership['disbursement_payment_methods'][0] = {
                contact: owner.contact,
                contact_payment_method: data!.disbursement_payment_methods[
                  index
                ]?.contact_payment_method,
                split_amount: {
                  amount: owner?.share || 0,
                  type: {
                    id: 'percent',
                    label: 'Percent'
                  }
                }
              };
              return ownerPaymentMethod;
            }
          )
        });
      }

      // if the user selects custom, we reset the values to the last set custom values
      if (value === 'custom') {
        setValues?.({
          ...values,
          disbursement_payment_methods: customDisbursementsPaymentMethods
        });
      }
    };

    const isDisburseBasedOnOwnerIncomeAttribution =
      values?.set_default_or_custom === 'default';

    // On init, set the default value of the radio group to 'default' if the split amounts are the same as the owners shares
    useEffect(() => {
      if (
        isSplitAmountTheSameAsOwnerShare(
          values?.owners,
          values?.disbursement_payment_methods
        )
      ) {
        setFieldValue?.('set_default_or_custom', 'default');
      } else {
        setFieldValue?.('set_default_or_custom', 'custom');
      }
    }, []);

    return (
      <Box flexDirection='column' rowGap={token('spacing.l')}>
        <Field<typeof RadioGroupInput>
          name='set_default_or_custom'
          Input={RadioGroupInput}
          onChange={handleSetDefaultOrCustomChange}
          inputProps={{
            options: [
              {
                // HACK! The label should be a string, but we want to render a tooltip, so I'm doing this to pass in a react node
                label: (<DisburseBasedOnOwnerRadioLabel />) as any,
                value: 'default'
              },
              { label: 'Set custom disbursements', value: 'custom' }
            ]
          }}
          optional={false}
        />

        <Heading level={3}>Owners</Heading>
        {isDisburseBasedOnOwnerIncomeAttribution ? (
          <PaymentMethodsBasedOnIncomeAttributionForm values={values} />
        ) : (
          <DisbursementPaymentMethodsEdit values={values} data={data} />
        )}
      </Box>
    );
  }
};

// This function compares the split amounts with the owners shares.
// If any of the split amount types are not percentage, or are different from the owners shares, it returns false.
function isSplitAmountTheSameAsOwnerShare(
  owners: Ownership['owners'] = [],
  paymentMethods: Ownership['disbursement_payment_methods'] = []
) {
  // Return early if the lengths are different
  if (owners.length !== paymentMethods.length) {
    return false;
  }

  return owners.every((owner, index) => {
    const splitAmount = paymentMethods[index]?.split_amount.amount;
    const ownerShare = owner?.share;

    return (
      paymentMethods[index]?.split_amount.type.id === 'percent' &&
      splitAmount === ownerShare
    );
  });
}
