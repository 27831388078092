import { ValueListValue } from 'data/models/types';
import { Task } from 'src/modules/tasks/common/types/Task';

interface mapLinkTaskFormToTaskApiParams {
  values: {
    relation: ValueListValue<'relates_to' | 'blocks' | 'is_blocked_by'>;
    linked_task: Task<any>;
  };
  parentTask: Task<any>;
}

export function mapCreateLinkTaskFormToTaskApi({
  values,
  parentTask
}: mapLinkTaskFormToTaskApiParams) {
  const prevTasks =
    parentTask?.task_links?.data?.map((taskLink) => ({
      child_task: taskLink.child_task,
      relation: taskLink.relation
    })) || [];

  return {
    task_links: [
      ...prevTasks,
      { relation: values.relation, child_task: values.linked_task }
    ]
  };
}
