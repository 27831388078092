import { RecordSubmitHandler } from 'view/components/record-screen/utils';
import { useModelActions } from '@rexlabs/model-generator';
import { tasksModel } from 'src/modules/tasks/common/models/tasks-model';
import { mapCoverPageFormDataToRequest } from '../mappers/map-cover-page-form-data-to-request';

export function useGetCoverPageSubmitHandler(
  inspectionId: string
): RecordSubmitHandler {
  const actions = useModelActions(tasksModel);

  return async ({ changedValues }) => {
    const dataToSubmit = await mapCoverPageFormDataToRequest(changedValues);

    await actions.updateItem({
      id: inspectionId,
      data: dataToSubmit
    });

    return true;
  };
}
