import React from 'react';
import { Field } from '@rexlabs/form';

import { BlockConfig } from 'view/components/record-screen/types';
import { Value } from 'view/components/values';
import { Grid } from 'view/components/@luna/form/grid';

import { CentAmountInput } from 'view/components/inputs/cent-amount';
import { CurrencyValue } from 'view/components/values/currency';
import { ContactSelect } from 'view/components/inputs/selects/contact';
import { WorkOrderTask } from '../types/WorkOrderTask';
import { useGetAccessProvidedBySuggestedContacts } from '../../common/hooks/use-get-access-provided-by-suggested-contacts';

export const supplierWorkOrderDetailsBlock: BlockConfig<WorkOrderTask> = {
  id: 'supplier-work-order-details',
  title: 'Work order details',
  validate: {
    definitions: {
      'details.access_provided_by': {
        name: 'access provided by',
        rules: 'required'
      }
    }
  },
  View: ({ data }) => {
    return (
      <Grid columns={2}>
        <CurrencyValue label='Price' value={data?.details?.amount} />
        <Value
          label='Access provided by'
          value={data?.details?.access_provided_by?.display_name}
        />
      </Grid>
    );
  },
  Edit: ({ forms }) => {
    const {
      loadItems: loadAccessProvidedBySuggestedContacts
    } = useGetAccessProvidedBySuggestedContacts({
      property: forms?.details?.values?.property
    });

    return (
      <Grid columns={2}>
        <Field
          id='amount'
          name='details.amount'
          label='Price'
          Input={CentAmountInput}
        />
        <Field
          id='access_provided_by'
          name='details.access_provided_by'
          label='Access provided by'
          optional={false}
          Input={ContactSelect}
          inputProps={{
            getSuggestedItems: loadAccessProvidedBySuggestedContacts
          }}
        />
      </Grid>
    );
  }
};
