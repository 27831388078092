import { NormalisedItem } from '@rexlabs/select';
import { Ownership } from 'data/models/entities/ownerships';
import { RecordTypes } from 'data/models/types';

export function normaliseOwnership(item: Ownership): NormalisedItem {
  return {
    id: item.id,
    label: item.display_name,
    type: RecordTypes.Ownership
  };
}
