import { useDialog } from '@rexlabs/dialog';
import { Invoice } from 'data/models/entities/financials/invoices';
import { ActionDeclaration } from 'src/modules/common/actions/types/action-declaration-types';
import { CreateCreditNoteDialog } from 'src/modules/credit-notes/dialogs/create-credit-note-dialog';

export function useCreditAction() {
  const createCreditNoteDialog = useDialog(CreateCreditNoteDialog);

  return (
    invoice: Invoice,
    onCreate?: (args?: any) => any
  ): ActionDeclaration => ({
    id: 'credit',
    group: 'payment',
    label: 'Apply credit',
    handleAction: () => {
      createCreditNoteDialog.open({
        invoice,
        onCreate
      });
    }
  });
}
