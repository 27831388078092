import { Invoice } from 'data/models/entities/financials/invoices';
import { useDialog } from '@rexlabs/dialog';
import { ActionDeclaration } from 'src/modules/common/actions/types/action-declaration-types';
import { FinancialEntity } from 'src/modules/financials/utils/financial-entity-action-group/use-get-financial-entity-actions';
import { TransferFundsDialog } from 'src/modules/trust-journal-entries/dialogs/transfer-funds-dialog';

export function useGetTransferFundsInvoiceAction() {
  const { open } = useDialog(TransferFundsDialog);
  return (
    invoice: Invoice,
    afterAction?: (args: any) => any
  ): ActionDeclaration => ({
    id: 'transfer',
    group: 'payment',
    label: 'Pay via transfer',
    handleAction: () => {
      const payableBy = (invoice.payable_by_tenancy ??
        invoice.payable_by_ownership ??
        invoice.payable_by_contact) as FinancialEntity;

      open({
        sourceObject: payableBy,
        invoice: invoice,
        onCreate: afterAction
      });
    }
  });
}
