import { Columns, RecordTable, RecordTableProps } from 'view/components/table';
import React, { useCallback, useMemo } from 'react';
import invariant from 'invariant';
import {
  PaymentHistory,
  PaymentHistoryType
} from 'src/modules/payment-history/types/payment-history';
import { Tenancy } from 'data/models/entities/tenancies';
import { Ownership } from 'data/models/entities/ownerships';
import { Contact } from 'src/modules/contacts/types/contact-types';
import { paymentHistoryQuery } from 'src/modules/payment-history/queries/payment-history-query';
import { Quri, Operator, toQuri } from '@rexlabs/quri';
import { useTableFilters } from 'view/hooks/use-table-filters';

const columns: Columns<PaymentHistory> = [
  {
    id: 'date',
    Header: 'Date',
    type: 'date',
    width: 140
  },
  {
    id: 'description',
    Header: 'Details',
    accessor: (item) => item?.description || item?.sub_description,
    width: 350
  },
  {
    id: 'payment_method',
    Header: 'Payment method',
    type: 'value',
    width: 150
  },
  {
    id: 'amount',
    Header: 'Amount',
    type: 'currency',
    width: 150
  }
];

const initialSortBy = [{ id: 'date', desc: true }];

interface PaymentHistoryRecordTableProps {
  object: Tenancy | Ownership | Contact;
  filters?: Quri[];
  Empty?: RecordTableProps<any>['Empty'];
}
export function PaymentHistoryRecordTable(
  props: PaymentHistoryRecordTableProps
) {
  const { filters, object, Empty } = props;
  const { getSort, getFilters } = useTableFilters('payment-histories');

  invariant(Array.isArray(filters), `Filters can't be null`);
  // Shouldn't use same filters when switching to a different property
  const tableId = `payment-history-record-table-${filters[0].value}`;

  const commonFilters = useMemo<Quri[]>(() => {
    const typeIds: PaymentHistoryType[] = [
      'invoice_payment',
      'transfer_deposit',
      'trust_journal_entry'
    ];

    return [
      {
        field: 'type_id',
        op: 'in',
        value: typeIds
      },
      {
        field: 'object_id',
        op: 'eq',
        value: object.id
      },
      ...(filters || [])
    ];
  }, [object.id, filters]);

  const query = useCallback(
    (payload) => {
      const { globalFilter } = payload;
      let combinedFilters = commonFilters;

      if (globalFilter != null) {
        const tableFilter = convertObjectToQuriFilter(globalFilter);
        combinedFilters = [...combinedFilters, ...tableFilter];
      }

      const filterString = toQuri(combinedFilters);

      return paymentHistoryQuery({ filterString });
    },
    [commonFilters]
  );

  return (
    <RecordTable
      emptyWithoutTable={Empty != null}
      id={tableId}
      columns={columns}
      getQuery={query}
      withPagination={true}
      withToolbar={true}
      getFilters={getFilters}
      getSort={getSort}
      initialSortBy={initialSortBy}
      Empty={Empty}
    />
  );
}

// Utils

type FilterObject = {
  [key: string]: Array<{
    op: {
      value: Operator;
      label: string;
    };
    value: any;
  }>;
};

function convertObjectToQuriFilter(filterObject: FilterObject) {
  const filters: Quri[] = [];

  for (const key of Object.keys(filterObject)) {
    const filter = filterObject[key];

    for (const conditions of filter) {
      filters.push({
        field: key,
        op: conditions.op.value,
        value: conditions.value
      });
    }
  }

  return filters;
}
