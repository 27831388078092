import { query, useEntityQuery } from '@rexlabs/model-generator';
import React from 'react';

import { RecordDialog } from 'view/components/record-screen/dialog/dialog';
import { DialogContentConfig } from 'view/components/record-screen/dialog/types';

import { manageKeySetBlock } from '../blocks/manage-key-set-block';
import { ManageKeySetDialogButtonGroup } from '../components/manage-key-set-dialog-button-group';
import { keySetsModel } from '../data/key-set-model';

const content: DialogContentConfig = [
  {
    id: 'manage-key-set',
    label: 'Manage key set',
    blocks: [manageKeySetBlock]
  }
];

function getKeySetQuery(id) {
  return query`{
    ${keySetsModel} (id: ${id}) {
      name
      number_of_keys
      notes
      images
      object
    }
  }`;
}

export function ManageKeySetDialog({
  keyId,
  onClose
}: { keyId: string } & Pick<
  React.ComponentProps<typeof RecordDialog>,
  'onClose'
>) {
  const { data: keyData, status } = useEntityQuery(getKeySetQuery(keyId));

  return (
    <RecordDialog
      data={keyData}
      isLoading={status === 'loading'}
      title={`${keyData?.name} (${keyData?.number_of_keys} keys)`}
      onClose={onClose}
      content={content}
      ButtonGroup={ManageKeySetDialogButtonGroup}
    />
  );
}
