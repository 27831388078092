import * as React from 'react';

import { DialogProps } from '@rexlabs/dialog';

import { RecordDialog } from 'view/components/record-screen/dialog/dialog';
import { RecordSubmitHandler } from 'view/components/record-screen/utils';

import {
  InspectionReportManageRoomsFormProps,
  manageAreasBlock
} from '../blocks/manage-areas-block';

interface InspectionReportAddAreasDialog extends Omit<DialogProps, 'title'> {
  title: Required<DialogProps['title']>;
  initialValues?: InspectionReportManageRoomsFormProps;
  onCreate?: (data) => void;
  isFixture?: boolean;
  submitHandler: RecordSubmitHandler<InspectionReportManageRoomsFormProps>;
}

const content = [
  {
    id: 'manage-areas',
    label: 'Manage areas',
    blocks: [manageAreasBlock]
  }
];

export function InspectionReportAddAreasDialog({
  onClose,
  initialValues,
  submitHandler,
  title
}: InspectionReportAddAreasDialog) {
  const isEditing = (initialValues?.inspection_report_rooms || []).length > 0;

  return (
    <RecordDialog
      blockProps={{
        banner: isEditing
          ? ''
          : 'To create an inspection report, please add the rooms for this property.'
      }}
      title={title}
      data={initialValues}
      content={content}
      onClose={onClose}
      handleSubmit={submitHandler}
      submitLabel='Save'
    />
  );
}
