import React from 'react';
import { Field } from '@rexlabs/form';

import { BlockConfig } from 'view/components/record-screen/types';
import { Grid } from 'view/components/@luna/form/grid';
import { UserSelect } from 'view/components/inputs/selects/v4-selects/user';
import Box from '@rexlabs/box';
import { toQuri } from '@rexlabs/quri';

export const removeAndAddNewPrimaryMemberBlock: BlockConfig = {
  id: 'remove-and-add-new-primary-property-manager',
  validate: {
    definitions: {
      user: { rules: 'required', name: 'primary property manager' }
    }
  },
  Edit: ({ blockProps }) => (
    <Grid columns={1}>
      <Box>
        {`Do you want to remove ${blockProps.portfolioMember.user.display_name} from this portfolio? They will remain assigned to any tasks that were previously assigned to them.`}
      </Box>
      <Box>
        {
          'Please select a new primary property manager below to add to this portfolio before removing this member.'
        }
      </Box>
      <Field
        name='user'
        id='user'
        label='Add new primary property manager'
        Input={UserSelect}
        inputProps={{
          filter: toQuri([
            {
              field: 'id',
              op: 'neq',
              value: blockProps.portfolioMember.user.id
            }
          ])
        }}
      />
    </Grid>
  )
};
