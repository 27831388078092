import React, { ReactNode } from 'react';
import Box from '@rexlabs/box';
import {
  StyleSheet,
  margin,
  border,
  padding,
  useStyles
} from '@rexlabs/styling';
import { Body, Large } from '@rexlabs/text';
import LoadingSpinner from '@rexlabs/loading-spinner';

import { TextProps } from '@rexlabs/text/lib/types/text';
import { LinkButton } from '@rexlabs/button';

export interface SummaryDividerRow {
  isDivider: true;
}

export interface SummaryValueRow<Value> {
  id?: string;
  label: ReactNode;
  bold?: boolean;
  large?: boolean;
  value?: Value;
  isLoading?: boolean;
  onClick?: () => void;
}

export type SummaryRow<Value> = SummaryDividerRow | SummaryValueRow<Value>;

interface SummaryProps {
  rows: SummaryRow<React.ReactNode>[];
}

const defaultStyles = StyleSheet({
  container: {},

  row: {
    ...margin.styles({
      y: 'xxs'
    }),
    ...padding.styles({
      right: 's'
    }),
    '& p': {
      padding: 0
    }
  },

  label: {
    textAlign: 'right'
  },

  amount: {
    width: '14rem',
    fontVariantNumeric: 'tabular-nums',
    textAlign: 'right'
  },

  hr: {
    width: '100%',
    ...border.styles({
      top: {
        width: 'thin',
        color: 'container.static.light'
      },
      bottom: {
        width: 'none'
      },
      left: {
        width: 'none'
      },
      right: {
        width: 'none'
      }
    }),
    ...margin.styles({
      y: 's'
    })
  }
});

interface RowProps extends TextProps {
  bold: boolean;
  large: boolean;
}

function Row({ large, ...props }: Omit<RowProps, 'type'>) {
  if (large) {
    return <Large {...props} />;
  }

  return <Body {...props} />;
}

export function Summary({ rows }: SummaryProps) {
  const s = useStyles(defaultStyles);

  return (
    <Box flexDirection='column' width='100%' {...s('container')}>
      {rows?.map((row, index) => (
        <>
          {'isDivider' in row && <hr {...s('hr')} />}
          {'label' in row && (
            <Box
              data-testid={row.id}
              key={index}
              flexDirection='row'
              width='100%'
              {...s('row')}
            >
              <Box
                {...s('label')}
                flex={1}
                justifyContent='flex-end'
                onClick={row?.onClick}
              >
                <Box
                  flexDirection='row'
                  alignItems='center'
                  justifyContent='flex-end'
                  sx='.6rem'
                >
                  <Row bold={!!row.bold} large={!!row.large}>
                    {row?.onClick ? (
                      <LinkButton onClick={row?.onClick}>
                        {row?.label}
                      </LinkButton>
                    ) : (
                      row?.label
                    )}
                  </Row>
                </Box>
              </Box>
              <Box
                {...s('amount')}
                justifyContent='flex-end'
                alignItems='center'
              >
                {row?.isLoading ? (
                  <LoadingSpinner size={14} strokeWidth={2} />
                ) : (
                  <Row bold={!!row.bold} large={!!row.large}>
                    {row?.value || '-'}
                  </Row>
                )}
              </Box>
            </Box>
          )}
        </>
      ))}
    </Box>
  );
}
