import { useMemo } from 'react';
import { SettingsGearIcon } from 'view/components/icons/settings-gear';
import ROUTES from 'routes/app';
import { push, useWhereabouts } from '@rexlabs/whereabouts';
import { query, useEntityListQuery } from '@rexlabs/model-generator';
import { bankAccountModel } from 'data/models/entities/financials/bank-accounts';
import { ActionDeclaration } from 'src/modules/common/actions/types/action-declaration-types';

const bankAccountQuery = {
  ...query`{
  ${bankAccountModel} {
    active_reconciliation {
      reconciliation_summary
    }
    trust_receipts_not_yet_banked
    trust_withdrawals_not_yet_banked
    financial_summary
  }
}`,
  uuid: 'bank-accounts-list'
};

type GenericConfig = {
  config: {
    path: string;
    Component: any;
  };
};

export function useBankAccountSelectActions(route: GenericConfig) {
  const { query } = useWhereabouts();

  // This makes sure we don't refresh the bank account list on every render
  const stableQuery = useMemo(() => bankAccountQuery, []);

  const { status, data: bankAccounts } = useEntityListQuery(stableQuery);

  const defaultBankAccount = bankAccounts?.find(
    (bankAccount) => bankAccount.is_default
  );

  const bankAccountId = query?.bankAccount || defaultBankAccount?.id;

  const bankAccount = useMemo(
    () => bankAccounts?.find(({ id }) => id === bankAccountId),
    [bankAccounts, bankAccountId]
  );

  const actions: ActionDeclaration[] = useMemo(() => {
    if (!bankAccount) return [];

    return [
      {
        label: bankAccount.name || 'Select a bank account',
        actions: [
          ...(bankAccounts?.map((bankAccount) => ({
            label: bankAccount.name,
            handleAction: () =>
              push(route, {
                query: { ...query, bankAccount: bankAccount?.id }
              })
          })) ?? []),
          {
            label: 'Manage bank accounts',
            handleAction: () => push(ROUTES.BANK_ACCOUNTS),
            Icon: SettingsGearIcon
          }
        ]
      }
    ];
  }, [bankAccounts, bankAccount]);

  return {
    isLoading: status === 'loading',
    bankAccount,
    actions
  };
}
