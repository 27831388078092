export const BATCH_RECEIPTING_ROUTES = {
  BATCH_RECEIPTING_LIST_SCREEN: {
    config: {
      path: '/batch-receipting',
      Component: require('./screens/batch-receipting').BatchReceiptingListScreen
    },

    BATCH_RECEIPTING_SUB_TRANSACTION_DIALOG: {
      config: {
        path: '/batch-receipting/:parentTransactionId/sub-transactions',
        Component: require('./dialogs/split-bank-transaction-view-edit-dialog')
          .SplitBankTransactionViewEditDialog
      }
    }
  },
  BATCH_RECEIPTING_STATEMENTS: {
    config: {
      path: '/uploaded-bank-statements',
      Component: require('./screens/uploaded-bank-statements-list')
        .UploadedBankStatementsList
    }
  }
};
