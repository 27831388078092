import { SingleActionDeclaration } from 'src/modules/common/actions/types/action-declaration-types';
import { useToast } from 'view/components/@luna/notifications/toast';
import { useModelActions } from '@rexlabs/model-generator';
import { bankStatementTransactionsModel } from '../../models/bank-statement-transaction-model';

export function useRematchTransactionsAction(): SingleActionDeclaration {
  const { addToast } = useToast();
  const { rematchBankStatementTransactions } = useModelActions(
    bankStatementTransactionsModel
  );

  return {
    id: 'rematch-transactions',
    label: 'Re-match transactions',
    handleAction: async () => {
      await rematchBankStatementTransactions();

      addToast({
        description: 'Re-match in progress'
      });
    }
  };
}
