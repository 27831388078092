import Box from '@rexlabs/box';
import { StyleSheet, useStyles } from '@rexlabs/styling';
import {
  StatusAverageTag,
  StatusBadTag,
  StatusGoodTag,
  Tag
} from '@rexlabs/tag';
import * as React from 'react';
import ChevronDownIcon from 'view/components/icons/chevron-down';
import { NormalisedDropdownItem } from './dropdown';

type Intent = NormalisedDropdownItem['intent'];

const defaultStyles = StyleSheet({
  item: {
    cursor: 'pointer',
    fontWeight: 500
  },
  active: {
    background: ({ token }) =>
      token(
        'actionMenu.item.hover.background.color',
        token('color.container.interactive.hover')
      )
  },
  chevronWrapper: {
    background: ({ token }) => token('palette.tint.light.400'),
    width: 20,
    height: 20,
    borderRadius: 4
  },
  tag: { paddingRight: '0.6rem' }
});

const getTagComponentForIntent = (intent: Intent) => {
  switch (intent) {
    case 'good':
      return StatusGoodTag;
    case 'average':
      return StatusAverageTag;
    case 'bad':
      return StatusBadTag;
    case 'neutral':
    default:
      return Tag;
  }
};

export interface ClickableTagProps {
  intent?: Intent;
  label: string;
}

export const ClickableTag = React.forwardRef<HTMLDivElement, ClickableTagProps>(
  ({ intent, label, ...props }, ref) => {
    const s = useStyles(defaultStyles);
    const TagComponent = getTagComponentForIntent(intent);

    return (
      <TagComponent
        ref={ref}
        aria-label={label}
        {...s('tag')}
        {...props}
        Icon={undefined} // Disable the default action menu icon
      >
        <Box
          display='flex'
          flexDirection='row'
          justifyContent='space-between'
          alignItems='center'
          gap={8}
        >
          <Box>{label}</Box>
          <Box
            display='flex'
            justifyContent='center'
            alignItems='center'
            {...s('chevronWrapper')}
          >
            <ChevronDownIcon />
          </Box>
        </Box>
      </TagComponent>
    );
  }
);
