import { BlockConfig } from 'view/components/record-screen/types';
import { SecurityDeposit } from 'data/models/entities/financials/security-deposits';
import React from 'react';
import { ErrorBanner } from 'view/components/@luna/notifications/banner';
import WarningCircleIcon from 'view/components/icons/warning-circle';
import { OutlineButton } from '@rexlabs/button';
import { isRegistrationNumberRequired } from '../utils/is-registration-number-required';
import { useGetEditSecurityDepositDetailsAction } from '../utils/security-deposit-actions/use-get-edit-security-deposit-details-action';

export const registrationNumberWarningBlock: BlockConfig<SecurityDeposit> = {
  id: 'registration-number-warning',
  Card: ({ data }) => {
    const getEditSecurityDepositDetailsAction = useGetEditSecurityDepositDetailsAction();
    if (!isRegistrationNumberRequired(data)) return null;
    if (data.registration_number) return null;

    return (
      <ErrorBanner
        Icon={WarningCircleIcon}
        title='Deposit registration number required'
        description='You will be issued a deposit registration number by the deposit authority once the payment has been made by the tenant(s). One you have this, please add this number to the security deposit'
        actions={[
          {
            label: 'Add deposit registration number',
            onClick: getEditSecurityDepositDetailsAction(data!).handleAction!,
            Button: OutlineButton
          }
        ]}
      />
    );
  }
};
