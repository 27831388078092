import React from 'react';
import { ContactFlag } from 'view/components/record-flags/contact-flag';
import { Ownership } from 'data/models/entities/ownerships';
import { RecordFlag } from 'view/components/record-flags/record-flag';
import { Contact } from 'src/modules/contacts/types/contact-types';
import { EntryList } from '../components/entry-list';
import { EntryLink } from '../components/entry-link';

export interface DepositHeldByProps {
  held_by_contact?: Contact;
  held_by_ownership?: Ownership;
}

export function DepositHeldBySection({
  held_by_contact,
  held_by_ownership
}: DepositHeldByProps) {
  return (
    <div>
      <EntryList>
        {held_by_ownership && (
          <EntryLink type='ownership' id={held_by_ownership.id}>
            <RecordFlag recordType='ownership' record={held_by_ownership} />
          </EntryLink>
        )}

        {held_by_contact && (
          <EntryLink type='contact' id={held_by_contact.id}>
            <ContactFlag
              contact={held_by_contact}
              description={
                held_by_contact.is_security_deposit_authority
                  ? 'Deposit Authority'
                  : undefined
              }
            />
          </EntryLink>
        )}
      </EntryList>
    </div>
  );
}
