import { useDialog } from '@rexlabs/dialog';
import { AttachedTo } from 'src/modules/documents/dialogs/create-document-dialog';
import { SupplierAddNewEntryDialog } from 'src/modules/compliance/common/dialogs/supplier-add-new-entry-dialog';
import { Property } from 'src/modules/properties/types/property-types';
import { Contact } from 'src/modules/contacts/types/contact-types';
import { AddNewCheckDialog } from 'src/modules/compliance/common/dialogs/add-new-check-dialog';

type ComplianceEntryRelatedRecord = Property | Contact;

export function useGetAddComplianceAction() {
  const { open: openSupplierDialog } = useDialog(SupplierAddNewEntryDialog);
  const { open: openPropertyDialog } = useDialog(AddNewCheckDialog);

  return ({
    data,
    label = 'Add requirement'
  }: {
    data: ComplianceEntryRelatedRecord;
    attachedTo?: AttachedTo[];
    label?: string;
  }) => {
    return {
      label: label,
      id: 'create-document',
      group: 'add-or-send',
      handleAction: () => {
        switch (data.__record_type) {
          case 'property':
            openPropertyDialog({ propertyId: data?.id });
            break;
          case 'contact':
            openSupplierDialog({ contactId: data?.id });
            break;
        }
      }
    };
  };
}
