import * as React from 'react';
import { ActionDeclaration } from 'src/modules/common/actions/types/action-declaration-types';
import { getRecordObject } from 'src/modules/common/utils/Records/get-record-object';
import { getRecordLabel } from 'utils/records/get-record-label';
import { useDeleteAction } from 'src/modules/common/actions/delete/use-delete-action';
import { push } from '@rexlabs/whereabouts';
import ROUTES from 'routes/app';
import { messageTemplatesModel } from '../../models/message-templates-model';
import { MessageTemplate } from '../../types/MessageTemplate';

type useGetDeleteMessageTemplateActionArgs = {
  redirectToListScreen?: boolean;
};

export function useGetDeleteMessageTemplateAction({
  redirectToListScreen
}: useGetDeleteMessageTemplateActionArgs = {}) {
  const getDeleteAction = useDeleteAction(messageTemplatesModel);

  return (messageTemplate: MessageTemplate): ActionDeclaration => {
    const deleteAction = getDeleteAction(messageTemplate, {
      message: (
        <div>
          Are you sure you wish to delete the template{' '}
          <strong>
            {getRecordLabel(
              getRecordObject(messageTemplate, messageTemplatesModel)
            )}
          </strong>
        </div>
      ),
      ...(redirectToListScreen
        ? {
            afterAction: () => push(ROUTES.MESSAGE_TEMPLATE_LIST)
          }
        : {})
    });

    return deleteAction;
  };
}
