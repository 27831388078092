import { useMemo } from 'react';
import { Property } from 'src/modules/properties/types/property-types';
import {
  PropertyTenancy,
  RentScheduleEntry
} from 'src/modules/property-tenancies/types/property-tenancy-types';

export interface RentLineItem {
  category: 'rent';
  rent: {
    property: Property;
    rent_amount: number;
    rent_frequency: RentScheduleEntry['frequency'];
    property_tenancy: PropertyTenancy;
  };
  amount: number;
}

const invalidManagementStatusIds = ['draft', 'archived'];
const validRentScheduleStatusIds = ['future', 'current', 'past'];

const rentScheduleIncludesValidStatus = (rentSchedule) =>
  validRentScheduleStatusIds.includes(rentSchedule.status.id);

function getRentLineItem({
  propertyTenancy
}: {
  propertyTenancy: PropertyTenancy;
}): RentLineItem {
  const rent_schedule = propertyTenancy.rent_schedule?.find(
    rentScheduleIncludesValidStatus
  ) as RentScheduleEntry;
  const property = propertyTenancy.property;
  const rent_amount = rent_schedule?.amount_total_inc_tax;
  const rent_frequency = rent_schedule?.frequency;

  return {
    category: 'rent',
    rent: {
      property,
      rent_amount,
      rent_frequency,
      property_tenancy: propertyTenancy
    },
    amount: 0
  };
}

interface UseRentLineItemProps {
  propertyTenancies?: PropertyTenancy[];
  filterLineItems: (item: RentLineItem) => boolean;
}

export function useRentLineItems({
  propertyTenancies = [],
  filterLineItems = (_rentLineItem) => true
}: UseRentLineItemProps): { rentLineItems?: RentLineItem[] } {
  const rentLineItems = useMemo(() => {
    return propertyTenancies
      .filter(
        (propertyTenancy) =>
          !invalidManagementStatusIds.includes(propertyTenancy.status.id)
      )
      .filter((propertyTenancy) =>
        propertyTenancy.rent_schedule?.find(rentScheduleIncludesValidStatus)
      )
      .map((propertyTenancy) => getRentLineItem({ propertyTenancy }))
      .filter(filterLineItems);
  }, [propertyTenancies, filterLineItems]);

  return { rentLineItems };
}
