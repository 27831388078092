import { useDialog } from '@rexlabs/dialog';
import { ActionDeclaration } from 'src/modules/common/actions/types/action-declaration-types';
import { Tenancy } from 'data/models/entities/tenancies';

import { Property } from 'src/modules/properties/types/property-types';
import { useTranslation } from 'react-i18next';
import { CreatePropertyTenancyDialog } from '../dialogs/create-property-tenancy-dialog';

export interface GetCreatePropertyTenancyActionProps {
  property?: Property;
  tenancy?: Tenancy;
}

export function useGetCreatePropertyTenancyAction() {
  const { t } = useTranslation();
  const { open: openCreatePropertyTenancyDialog } = useDialog(
    CreatePropertyTenancyDialog
  );

  return ({
    property,
    tenancy
  }: GetCreatePropertyTenancyActionProps): ActionDeclaration => ({
    id: 'create-property-tenancy',
    group: 'property-tenancy',
    label: t('lease-agreements.create.singular.label') as string,
    handleAction: () =>
      openCreatePropertyTenancyDialog({
        property,
        tenancy
      })
  });
}
