import * as React from 'react';
import Box from '@rexlabs/box';
import { StyleSheet, useStyles } from '@rexlabs/styling';
import DragIcon from 'view/components/icons/drag';
import { Draggable } from 'react-beautiful-dnd';
import { FieldArrayField, FieldArrayPassthroughProps } from '@rexlabs/form';
import { transformActionDeclarationsToActionMenuItems } from 'utils/actions/transforms';
import { ChecklistTemplatesForm } from '../types/ChecklistTemplatesForm';
import { TaskType } from '../../common/types/TaskType';
import { useGetChecklistTemplateItemActions } from '../hooks/action-menu-items/use-get-checklist-template-item-actions';
import { useTriggerActions } from '../hooks/action-menu-items/use-trigger-actions';
import { ChecklistTemplateSubtask } from './checklist-template-subtask';

const styles = StyleSheet({
  rowContainer: {
    display: 'flex',
    flexDirection: 'row',
    paddingBottom: ({ token }) => token('spacing.xl')
  },
  dragHandleContainer: {
    width: 40,
    display: 'flex',
    paddingTop: ({ token }) => token('spacing.l'),
    justifyContent: 'center',
    alignItems: 'flex-start'
  },
  dragHandle: {
    border: 'none',
    background: 'transparent',
    '&:focus': {
      boxShadow: 'none'
    }
  }
});

const handleMouseDown = (e: React.MouseEvent<HTMLButtonElement>) => {
  e.currentTarget.focus();
};

export function ChecklistTemplateItemRow({
  field,
  index,
  values,
  key,
  taskType,
  fieldArrayProps,
  setFieldValue
}: {
  field: FieldArrayField['field'];
  index: number;
  values: ChecklistTemplatesForm;
  key: string;
  taskType: TaskType;
  fieldArrayProps: FieldArrayPassthroughProps;
  setFieldValue: (field: string, value: any) => void;
}) {
  const s = useStyles(styles);

  const getChecklistTemplateItemActions = useGetChecklistTemplateItemActions({
    taskType,
    fieldArrayProps,
    index,
    values,
    setFieldValue
  });

  const checklistTemplateItem = values.checklist_template_items[index];

  const checklistTemplateItemActionMenuItems = transformActionDeclarationsToActionMenuItems(
    getChecklistTemplateItemActions(checklistTemplateItem)
  );

  const triggerActionMenuItems = transformActionDeclarationsToActionMenuItems(
    useTriggerActions({
      index,
      fieldArrayProps,
      checklistTemplateItem,
      taskType
    })
  );

  return (
    <>
      <Draggable draggableId={field.key} key={field.key} index={index}>
        {(provided) => (
          <Box
            ref={provided.innerRef as React.Ref<HTMLElement>}
            key={key}
            {...provided.draggableProps}
          >
            <Box {...s('rowContainer')}>
              <Box {...s('dragHandleContainer')}>
                <button
                  type='button'
                  aria-label='drag item'
                  {...s('dragHandle')}
                  {...provided.dragHandleProps}
                  onMouseDown={handleMouseDown}
                >
                  <DragIcon />
                </button>
              </Box>
              <Box style={{ width: '100%' }}>
                <ChecklistTemplateSubtask
                  index={index}
                  checklistTemplateItem={checklistTemplateItem}
                  editMode={true}
                  actionMenuItems={checklistTemplateItemActionMenuItems}
                  triggerActionMenuItems={triggerActionMenuItems}
                />
              </Box>
            </Box>
          </Box>
        )}
      </Draggable>
    </>
  );
}
