import * as React from 'react';
import { DateCell } from '@rexlabs/table';
import { TwoTierCell } from 'view/components/cells/two-tier-cell';
import { Task } from 'src/modules/tasks/common/types/Task';
import { formatDate } from 'utils/dates/format';

export function ExpiryCell({
  value,
  column
}: {
  value: Task<'task_property_compliance'>;
  column: any;
}) {
  const issuedDate = value?.details?.compliance_entry?.issued_at;

  return (
    <TwoTierCell
      text={<DateCell value={value.due_date} column={column} />}
      subtext={issuedDate ? `issued ${formatDate(issuedDate)}` : null}
    />
  );
}
