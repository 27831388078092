import Validator from '@rexlabs/validator';

// Modified version of https://github.com/mikeerickson/validatorjs/blob/3.14.2/src/rules.js#LL46C2-L51C17
Validator.registerImplicit(
  'greaterThanZero_if',
  function (this: any, value) {
    const req = this.getParameters();

    if (this.validator._objectPath(this.validator.input, req[0]) === req[1]) {
      return value > 0;
    }

    return true;
  },
  'The :attribute should be greater than 0'
);

Validator.registerImplicit('less_than_field', function (this: any, value) {
  const req = this.getParameters();

  // If the value is undefined, we don't need to validate it
  // guard against this with the required rule if needed
  if (!value) {
    return true;
  }

  return value < this.validator._objectPath(this.validator.input, req[0]);
});

Validator.registerImplicit(
  'less_than_or_equal_to_field',
  function (this: any, value) {
    const req = this.getParameters();

    // If the value is undefined, we don't need to validate it
    // guard against this with the required rule if needed
    if (!value) {
      return true;
    }

    return value <= this.validator._objectPath(this.validator.input, req[0]);
  }
);

Validator.registerImplicit('greater_than_field', function (this: any, value) {
  const req = this.getParameters();

  // If the value is undefined, we don't need to validate it
  if (!value) {
    return true;
  }

  return value > this.validator._objectPath(this.validator.input, req[0]);
});

/**
 * usage: max_if:max,other_field,other_value
 */
Validator.registerImplicit(
  'max_if',
  function (this: any, value: any) {
    const [max, field, other_value] = this.getParameters();
    if (
      this.validator._objectPath(this.validator.input, field) == other_value
    ) {
      return this.validator.getRule('max').validate(value, [max]);
    }
    return true;
  }
  // TODO: cannot really do custom message that shows the max value here, as the underlying library is wonky
);
