import React, { useMemo } from 'react';

import { useDialog } from '@rexlabs/dialog';

import { TitleBlock } from 'view/components/cards/title/title-block';

import { RecordObject, RecordType } from 'data/models/types';
import { UniversalUser } from 'data/models/custom/session';

import { getRecordTitle } from 'utils/records/get-record-title';
import {
  redirectToAuthServiceProfileInfo,
  redirectToAuthServiceProfileSecurity
} from 'utils/api/redirect-to';
import config from 'src/config';
import { ChangePasswordDialog } from '../dialogs/change-password-dialog';
import { ChangeEmailDialog } from '../dialogs/change-email-dialog';

const type: RecordType = 'universal_user';

type ProfileSettingsTitleBlockProps = {
  user: UniversalUser;
};

export function ProfileSettingsTitleBlock({
  user
}: ProfileSettingsTitleBlockProps) {
  const changePasswordDialog = useDialog(ChangePasswordDialog);
  const changeEmailDialog = useDialog(ChangeEmailDialog);
  const shouldRedirectToAuthServiceProfile =
    config.AUTHENTICATION_SERVICE_ENABLE_INTEGRATION;

  const actions = useMemo(
    () => [
      {
        label: 'Change Email',
        handleAction: () =>
          shouldRedirectToAuthServiceProfile
            ? redirectToAuthServiceProfileInfo()
            : changeEmailDialog.open()
      },
      {
        label: 'Change Password',
        handleAction: () =>
          shouldRedirectToAuthServiceProfile
            ? redirectToAuthServiceProfileSecurity()
            : changePasswordDialog.open()
      }
    ],
    [changeEmailDialog]
  );

  const title = getRecordTitle({ type, object: user } as RecordObject);

  return <TitleBlock title={title} type={type} actions={actions} />;
}
