import { query } from '@rexlabs/model-generator';
import { complianceEntriesModel } from 'src/modules/compliance/common/models/compliance-entries';
import { toQuri } from '@rexlabs/quri';

export const getFiltersForComplianceEntries = ({ objectId, objectType }) => {
  return toQuri([
    {
      field: 'object_id',
      op: '==',
      value: objectId
    },
    {
      field: 'object_type',
      op: '==',
      value: objectType
    },
    {
      field: 'status_id',
      op: 'in',
      value: ['archived', 'valid', 'expired', 'expires_soon']
    }
  ]);
};

export const getTableComplianceEntriesQuery = () => {
  const q = query`{
      ${complianceEntriesModel} {
        id
        details
        issued_at
        expires_at
        object_type
        object_id
        setting_compliance_type_id
        created_at
        updated_at
        compliance_type
      }
    }`;

  q.uuid = 'compliance-entries-table';
  return q;
};

export const getComplianceEntriesByIdAndTypeQuery = ({
  objectId,
  objectType
}) => {
  const f = () => getFiltersForComplianceEntries({ objectId, objectType });

  const q = query`{
      ${complianceEntriesModel} (q: ${f()}) {
        id
        details
        issued_at
        expires_at
        object_type
        object_id
        setting_compliance_type_id
        created_at
        updated_at
        compliance_type
      }
    }`;

  q.uuid = 'compliance-entries';

  return q;
};
