import React from 'react';
import { CellProps } from '@rexlabs/table';

import { ListTableProps } from 'view/components/table/list-table';
import { LargeDescriptionCell } from 'view/components/cells/large-description-cell';

export function ComplianceRequirementDescriptionCell({
  value
}: CellProps & Omit<ListTableProps<any>, 'columns'>) {
  return <LargeDescriptionCell text={value} lineClamp={2} />;
}
