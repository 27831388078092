import { useMutation, useQueryClient } from 'react-query';
import { api } from 'utils/api/api-client';
import { AxiosResponse } from 'axios';
import { Settings } from 'src/modules/settings/hooks/useSettings';
import { settingsQueryKey, GetSettingsResponse } from './useSettingsQuery';

const putSettings = async (
  settings: { key: string; value: any }[]
): Promise<
  AxiosResponse<
    {
      key: [keyof Settings];
      value:
        | string
        | { id: string; label: string }
        | { count: number; unit: string; is_active?: boolean };
    }[]
  >
> => {
  return api.put('/settings', { settings: settings });
};

export function usePutSettingsMutation() {
  const queryClient = useQueryClient();

  return useMutation(putSettings, {
    onSuccess: (changedSettings) => {
      /**
       *  We don't want flickering of old values in UI while refetch happens. So, we optimistically
       *  update the query cache with the "put" response
       */
      const previousSettings = queryClient.getQueryData<{
        data: GetSettingsResponse;
      }>([settingsQueryKey]);

      const optimisticData = previousSettings!.data.map((setting) => {
        const changed = changedSettings.data.find((changed) => {
          return changed.key === setting.key;
        });

        if (changed) {
          return changed;
        }

        return setting;
      });
      queryClient.setQueryData([settingsQueryKey], { data: optimisticData });

      queryClient.invalidateQueries([settingsQueryKey]);
    }
  });
}
