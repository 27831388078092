import React from 'react';
import { HiddenField } from '@rexlabs/form';
import { TaxIncludedInlineSelect } from 'view/components/inputs/selects/tax-included';
import { TaxPreview as TaxPreviewType } from 'src/modules/financials/data/api';
import { CurrencySummary } from 'view/components/currency-summary';

interface TaxPreviewProps {
  isLoading: boolean;
  prefix?: string;
  isTaxIncluded: boolean;
  isTaxIncludedFieldName: string;
  taxPreview?: TaxPreviewType;
  setFieldValue?: (field: string, value: any) => void;
}

export function TaxPreview({
  isTaxIncludedFieldName,
  isTaxIncluded,
  taxPreview,
  isLoading,
  prefix,
  setFieldValue
}: TaxPreviewProps) {
  const taxSummaryItems = taxPreview?.tax_summary?.filter(
    ({ amount_tax }) => amount_tax !== 0
  );
  return (
    <>
      <HiddenField name={isTaxIncludedFieldName} />

      <CurrencySummary
        rows={[
          {
            label: (
              <TaxIncludedInlineSelect
                prefix={prefix}
                value={isTaxIncluded}
                onChange={(e) =>
                  setFieldValue?.(isTaxIncludedFieldName, e.target.value)
                }
              />
            ),
            value: isLoading
              ? undefined
              : isTaxIncluded
              ? taxPreview?.amount_inc_tax
              : taxPreview?.amount_exc_tax
          },
          ...(taxSummaryItems?.map((item) => ({
            label: `Total ${item?.tax_type?.label}`,
            value: item?.amount_tax
          })) || []),
          {
            isLoading,
            label: prefix ? `${prefix} Total` : 'Total',
            bold: true,
            value: taxPreview?.amount_inc_tax
          }
        ]}
      />
    </>
  );
}
