import { Generator } from 'data/models/generator';
import { auditableConfig } from 'src/modules/common/auditable/auditable-config';
import { api } from 'utils/api/api-client';
import _ from 'lodash';
import { PrepaymentBucket } from '../types/prepayment-bucket';

const config = {
  entities: {
    related: {
      chart_of_accounts_accounts: {
        include: 'chart_of_accounts_accounts'
      },
      ...auditableConfig
    }
  }
};

export interface MoveFundsBucketPayload {
  prepayment_bucket: { id: string } | null;
  property?: { id: string };
}

export interface MoveFundsPayload {
  from: MoveFundsBucketPayload;
  to: MoveFundsBucketPayload;
  amount_to_move: number;
  reason: string;
  ledger: { id: string };
}

const actionCreators = {
  moveFunds: {
    request: (payload: MoveFundsPayload) =>
      api.post(`financials/tenancy-prepayment-buckets/move-funds`, payload),
    reduce: _.identity
  }
};

export const prepaymentBucketsModel = new Generator<
  PrepaymentBucket,
  typeof actionCreators
>('financials/prepayment-buckets', config).createEntityModel({
  actionCreators
});
