import { useDialog } from '@rexlabs/dialog';
import React from 'react';

import EmptyTable from 'src/assets/illustrations/empty-table.svg';

import { Message } from 'view/components/@luna/message';
import { EmptyCard } from 'view/components/record-screen/cards/empty-card';

import { CreateComplianceCategoryDialog } from '../dialogs/create-compliance-category-dialog';

export function SupplierComplianceCategoryTableEmptyState() {
  const { open: openCreateComplianceCategoryDialog } = useDialog(
    CreateComplianceCategoryDialog
  );
  return (
    <EmptyCard hasGreyBackground={false}>
      <Message
        title='Compliance services offered'
        Illustration={EmptyTable}
        actions={[
          {
            label: 'Create service offered',
            intent: 'primary',
            handleAction: openCreateComplianceCategoryDialog
          }
        ]}
      >
        Easily manage compliance requirements by adding them to a category.
        These categories can then be applied to supplier contacts.
      </Message>
    </EmptyCard>
  );
}
