import React, { useCallback } from 'react';
import { Select, useValueList } from '@rexlabs/select';
import { normaliseValueListItem } from 'utils/normaliser/value-list-item';
import { getValueListItems } from 'utils/api/get-value-list-items';

export function TermLengthSelect(props) {
  const getItems = useCallback(async () => {
    const items = await getValueListItems('term_length');
    return [...items, { id: 'custom', label: 'Custom' }];
  }, []);

  const { getSelectProps } = useValueList({
    getItems
  });

  return (
    <Select
      searchable={true}
      {...getSelectProps()}
      normaliser={normaliseValueListItem}
      {...props}
    />
  );
}
