import React, { ComponentProps } from 'react';

import Box from '@rexlabs/box';
import { useToken } from '@rexlabs/styling';
import { Body, Heading } from '@rexlabs/text';

import { UploadAttachments } from './upload-attachments';

export function AttachStaticFiles(
  props: ComponentProps<typeof UploadAttachments>
) {
  const token = useToken();
  return (
    <Box flexDirection='column' rowGap={token('spacing.xs')}>
      <div>
        <Heading level={3}>Attach static files</Heading>
        <Body grey>
          Attach files directly to this template if they are the same for all
          recipients (for example, a How To Rent guide).
        </Body>
      </div>
      <UploadAttachments {...props} />
    </Box>
  );
}
