import React from 'react';

import { RadioGroupInputProps } from '@rexlabs/radio-input';
import { RecordOption, Select } from '@rexlabs/select';

import { useRecordsQuery } from 'src/lib/react-query/hooks/use-records-query';

import { GenericEvent } from 'view/components/inputs/types';

import { Invoice } from 'data/models/entities/financials/invoices';

import RenderLoading from '../@luna/render-loading';
import { LoadingState } from '../states/compact/loading';

type Template = {
  id: string;
};

// TODO: put inside function, use callback
function denormaliseCreditNoteTemplateAction(value: string, actions): Template {
  return actions.find((action) => action.id === value);
}

// TODO: rename this?
type TemplateAction = {
  description: string;
  label: string;
  id: string;
};

type CreditInvoiceTemplateActionsProps = Omit<
  RadioGroupInputProps,
  'value' | 'onChange' | 'onBlur'
> & {
  onChange: (e: GenericEvent<Template>) => void;
  onBlur: (e: GenericEvent<Template>) => void;
  value?: TemplateAction;
  invoice?: Invoice;
};

export function CreditInvoiceTemplateActions({
  onChange,
  onBlur,
  invoice,
  ...props
}: CreditInvoiceTemplateActionsProps) {
  const { data = [], isFetched } = useRecordsQuery<TemplateAction>(
    [
      'financials/credit-note-templates',
      {
        customQuery: {
          field: 'invoice_id',
          op: '==',
          value: invoice!.id
        },
        includes: ['inputs']
      }
    ],
    { enabled: !!invoice?.id }
  );

  return (
    <RenderLoading isLoading={!isFetched} LoadingView={LoadingState}>
      <Select
        items={data}
        Option={RecordOption}
        normaliser={(action) => ({
          id: action.id,
          description: action.description,
          label: action.label
        })}
        helperText={'Choose the type of credit to issue'}
        onChange={(e) => {
          onChange?.({
            ...e,
            target: {
              ...e.target,
              value: denormaliseCreditNoteTemplateAction(
                e.target.value.id,
                data
              )
            }
          });
        }}
        onBlur={(e) => {
          onBlur?.({
            ...e,
            target: {
              ...e.target,
              value: denormaliseCreditNoteTemplateAction(
                e.target.value.id,
                data
              )
            }
          });
        }}
        {...props}
      />
    </RenderLoading>
  );
}
