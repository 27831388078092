import * as React from 'react';
import { Select, SelectProps } from '@rexlabs/select';
import { useQuery } from 'react-query';
import { api } from 'utils/api/api-client';
import { getSearchResultItemFromObjectAndModel } from 'src/modules/common/utils/search-result-items/get-search-result-items-from-object-and-model';
import { propertyTenancyModel } from 'src/modules/property-tenancies/models/property-tenancy-model';
import { SearchResultItem } from 'utils/api/get-search-results';
import {
  PropertyTenancyModel,
  PropertyTenancyStatusType
} from 'src/modules/property-tenancies/types/property-tenancy-types';
import { normaliseSearchResultItems } from 'utils/normaliser/v4-select-normalisers/search-result-item';

const includes = ['property_tenancies', 'property_tenancies.tenancy'].join(',');

export function getData(propertyId: string) {
  return api.get(`/properties/${propertyId}?include=${includes}`);
}

export type RelatedPropertyTenancySelectProps = SelectProps<SearchResultItem> & {
  propertyId: string | null;
  statusesToShow?: PropertyTenancyStatusType[];
};

export const RelatedPropertyTenancySelect = ({
  propertyId,
  statusesToShow,
  ...props
}: RelatedPropertyTenancySelectProps) => {
  const { data } = useQuery(
    ['property-related-property-tenancies', propertyId],
    () => getData(propertyId!),
    {
      enabled: propertyId != null,
      select: (response) => {
        return response.data.property_tenancies?.data as
          | PropertyTenancyModel[]
          | undefined;
      }
    }
  );

  // make items, using the tenancy name as the label
  const items = React.useMemo<SearchResultItem[]>(() => {
    if (data == null) {
      return [];
    }

    return data
      .filter((propertyTenancy) => {
        if (statusesToShow == null) {
          return true;
        }

        return statusesToShow.includes(propertyTenancy.status.id);
      })
      .map((propertyTenancy) => {
        const baseItem = getSearchResultItemFromObjectAndModel(
          propertyTenancy,
          propertyTenancyModel
        ) as SearchResultItem;

        return { ...baseItem, label: propertyTenancy.tenancy.display_name };
      });
  }, [data, statusesToShow]);

  return (
    <Select {...props} items={items} normaliser={normaliseSearchResultItems} />
  );
};
