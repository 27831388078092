import React from 'react';
import { BlockConfig } from 'view/components/record-screen/types';
import { RecordTable, Columns } from 'view/components/table';
import { AddIcon } from 'view/components/icons/add';
import ROUTES from 'routes/app';
import { RouteConfig } from '@rexlabs/whereabouts';
import { useGetReportItems } from '../../hooks/use-get-static-report-items';

const staticReportsColumns: Columns = [
  {
    id: 'report_name',
    Header: 'Report Name'
  },
  {
    id: 'icon',
    width: 50,
    Cell: () => {
      return (
        <div>
          <AddIcon />
        </div>
      );
    }
  }
];

export const propertyReportsBlock: BlockConfig = {
  id: 'property-static-reports',
  title: () => 'Property reports',
  View: () => {
    const reportItems = useGetReportItems('property');

    const getRowLinkProps = ({ item }) => {
      return {
        to: ROUTES.TABLE_REPORT,
        params: { reportId: item.id },
        query: { resourceId: item.resource_id }
      } as RouteConfig;
    };

    return (
      <RecordTable
        withToolbar={false}
        withPagination={false}
        id='property-static-reports'
        items={reportItems}
        isLoading={false}
        columns={staticReportsColumns}
        getRowLinkProps={getRowLinkProps}
      />
    );
  }
};
