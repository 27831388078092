import { useDialog } from '@rexlabs/dialog';
import { Invoice } from 'data/models/entities/financials/invoices';
import { ActionDeclaration } from 'src/modules/common/actions/types/action-declaration-types';
import { UploadOriginalBillDialog } from '../dialogs/upload-original-bill-dialog';

export function useGetUploadOriginalBillAction() {
  const { open } = useDialog(UploadOriginalBillDialog);

  return (invoice: Invoice): ActionDeclaration => ({
    id: 'upload-original-bill',
    group: 'bill',
    label: 'Upload original bill',
    handleAction() {
      open({
        invoice
      });
    }
  });
}
