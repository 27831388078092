import React, { useRef, useMemo, MutableRefObject } from 'react';
import { AgGridReact } from 'ag-grid-react'; // the AG Grid React Component

import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
import 'ag-grid-community/styles/ag-theme-material.css'; // Optional theme CSS
import '../styles/rex-pm-theme.css';
import { useQuery } from 'react-query';
import { api } from 'utils/api/api-client';
import { RecordScreen } from 'view/components/record-screen';
import { ContentConfig } from 'view/components/record-screen/types';
import { TitleBlock } from 'view/components/@luna/title-block';
import { TableProvider, useUrlState } from '@rexlabs/table';
import { useTableFilters } from 'view/hooks/use-table-filters';
import { ValueListValue } from 'data/models/types';
import { BREADCRUMBS } from 'view/components/@luna/breadcrumbs';
import { ActionDeclaration } from 'src/modules/common/actions/types/action-declaration-types';
import LoadingState from 'view/components/states/compact/loading';
import dayjs from 'dayjs';
import { tableReportBlock } from '../blocks/table-report-block';

function withReport(Component) {
  return (props) => {
    // on the backend, these are called "static reports"
    const { data, isLoading } = useQuery(['static-reports'], {
      queryFn: () => {
        return api.get<ValueListValue<string>[]>('/static-reports');
      },
      select: (data) => data.data
    });

    // find the report that matches the id
    const report = data?.find((report) => report.id === props.reportId);

    if (isLoading) {
      return <LoadingState />;
    }

    if (!report && !isLoading) {
      // TODO: better error state
      return <div>something went wrong</div>;
    }

    return <Component {...props} report={report} />;
  };
}

export const TableReportRecord = withReport(
  withTableProviders(({ reportId, report }) => {
    // pull the gridRef from the context
    const gridRef = React.useContext(GridRefContext);
    const content: ContentConfig = [
      {
        label: 'Report',
        items: [
          {
            id: 'table-reports',
            label: 'Table reports',
            blocks: [tableReportBlock]
          }
        ]
      }
    ];

    const fileName = `${report.label} - ${dayjs().format(
      'YYYY-MM-DD'
    )}T${dayjs().format('HH_mm_ss')}`;

    const actions: ActionDeclaration[] = [
      {
        id: 'export-csv',
        label: 'Export CSV',
        group: 'export',
        handleAction: () =>
          gridRef?.current?.api.exportDataAsCsv({
            fileName
          })
      },
      {
        id: 'export-excel',
        label: 'Export Excel',
        group: 'export',
        handleAction: () =>
          gridRef?.current?.api.exportDataAsExcel({
            fileName
          })
      }
      // TODO: PDF exports are super squashed, so disabling for now. Need to figure out how to make them look good
      // {
      //   id: 'export-pdf',
      //   label: 'Export PDF',
      //   group: 'export',
      //   handleAction: () =>
      //     gridRef?.current && exportToPDF(gridRef.current?.api)
      // }
    ];

    return (
      <RecordScreen
        isLoading={!report}
        titleBlock={
          <TitleBlock
            title={report.label}
            actions={actions}
            amountDisplayedAsButtons={3}
          />
        }
        data={{ reportId, report }}
        content={content}
        breadcrumbs={[{ type: BREADCRUMBS.STATIC_REPORTS }]}
      />
    );
  })
);

// create a context to hold the ag grid ref, so we can access it from the actions
export const GridRefContext = React.createContext<MutableRefObject<
  AgGridReact<unknown> | undefined | null
> | null>(null);

function withTableProviders(Component) {
  return (props) => {
    const { getSort, getFilters } = useTableFilters(props.report.resource_id);
    const gridRef = useRef<AgGridReact<unknown> | undefined | null>(); // Optional - for accessing Grid's API

    const initialGlobalFilter = useMemo(() => {
      // get the filters hash param
      const hash = window.location.hash;
      const hashParams = new URLSearchParams(hash.slice(1));
      const filters = hashParams.get('filters');
      if (filters) {
        const parsed = JSON.parse(filters);
        return parsed;
      }
    }, []);

    const initialSortBy = useMemo(() => {
      // get the filters hash param
      const hash = window.location.hash;
      const hashParams = new URLSearchParams(hash.slice(1));
      const sort = hashParams.get('sort');
      if (sort) {
        const parsed = JSON.parse(sort);
        return parsed;
      }
    }, []);

    const { getTableProps: getUrlStateProps } = useUrlState({
      initialSortBy,
      initialGlobalFilter
    });

    return (
      <GridRefContext.Provider value={gridRef}>
        <TableProvider
          // this is what sets the url state filters into the table context
          {...getUrlStateProps()}
          getFilters={getFilters}
          getSort={getSort}
        >
          <Component {...props} />
        </TableProvider>
      </GridRefContext.Provider>
    );
  };
}
