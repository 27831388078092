import * as React from 'react';
import { TagConfig } from 'view/components/@luna/title-block';
import { ProgressDropdown } from 'src/modules/tasks/arrears/components/progress-dropdown';
import { ArrearsTask } from 'src/modules/tasks/arrears/types/ArrearsTask';

// gotta be memo'd otherwise we get infinite rerenders
export function useArrearsStatusTag(task?: ArrearsTask): TagConfig {
  const Tag = React.useMemo(() => () => <ProgressDropdown value={task} />, [
    task?.details?.status?.id
  ]);

  return {
    label: task?.details?.status?.label || '',
    Tag
  };
}
