import * as React from 'react';
import { ListTableProps } from 'view/components/table/list-table';
import { Tab } from 'view/components/table/tabbed';
import { getArrearsColumns } from 'src/modules/tasks/arrears/utils/arrears-columns';
import { GlobalFilter } from '@rexlabs/table/lib/types/context';
import { TaskTableEmptyState } from 'src/modules/tasks/common/components/task-table-empty-state';
import dayjs from 'dayjs';

const filterOpen = {
  field: 'closed_at',
  op: 'eq',
  value: 'null'
};

const filterClosed = {
  field: 'closed_at',
  op: 'neq',
  value: 'null'
};

const now = dayjs().format('YYYY-MM-DD');

const getArrearsTableTabsBaseConfigMap = () => {
  return {
    open: {
      name: 'open',
      label: 'Open',
      Empty: () => <TaskTableEmptyState label='Open' />,
      forcedGlobalFilter: [filterOpen]
    },
    followUp: {
      name: 'follow-up',
      label: 'Follow up',
      Empty: () => <TaskTableEmptyState label='Follow up' />,
      forcedGlobalFilter: [
        filterOpen,
        {
          field: 'follow_up_date',
          op: 'lte',
          value: now
        } as GlobalFilter
      ]
    },
    smallArrears: {
      name: '1-7-days',
      label: '1-7 days',
      Empty: () => <TaskTableEmptyState label='1-7 days rent arrears' />,
      forcedGlobalFilter: [
        filterOpen,
        {
          // if you're filtering by 2 of the same field, it will automatically switch to OR
          // so we need to enforce AND here
          op: 'and',
          value: [
            {
              value: {
                field: 'task_arrears_rent_arrears_days',
                op: 'lt',
                value: 8
              }
            },
            {
              value: {
                field: 'task_arrears_rent_arrears_days',
                op: 'gte',
                value: 1
              }
            }
          ]
        } as GlobalFilter
      ]
    },
    largeArrears: {
      name: '8-plus',
      label: '8+ days',
      Empty: () => <TaskTableEmptyState label='8+ days rent arrears' />,
      forcedGlobalFilter: [
        filterOpen,
        {
          field: 'task_arrears_rent_arrears_days',
          op: 'gte',
          value: 8
        }
      ]
    },
    resolved: {
      name: 'resolved',
      label: 'Resolved',
      Empty: () => <TaskTableEmptyState label='Resolved' />,
      forcedGlobalFilter: [filterClosed],
      columns: getArrearsColumns({ resolved: true })
    }
  };
};

function getTabWithCommonTabProps(
  commonTabProps: ListTableProps,
  tabConfig: ListTableProps
): Tab {
  return {
    ...commonTabProps,
    ...tabConfig,
    forcedGlobalFilter: [
      ...(commonTabProps.forcedGlobalFilter || []),
      ...(tabConfig.forcedGlobalFilter || [])
    ]
  };
}

type TabName = keyof ReturnType<typeof getArrearsTableTabsBaseConfigMap>;

export function getArrearsTabbedTableTabs(
  commonTabProps: Partial<Tab<ListTableProps>>,
  tabs: TabName[] = [
    'open',
    'followUp',
    'smallArrears',
    'largeArrears',
    'resolved'
  ]
) {
  return tabs.map((tabName) =>
    getTabWithCommonTabProps(
      commonTabProps,
      getArrearsTableTabsBaseConfigMap()[tabName]
    )
  );
}
