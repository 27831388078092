import React from 'react';

import { Field, HiddenField } from '@rexlabs/form';
import { TextArea, TextInput } from '@rexlabs/text-input';

import { BlockConfig } from 'view/components/record-screen/types';
import { Grid } from 'view/components/@luna/form/grid';
import { TaskSelect } from 'view/components/inputs/selects/v4-selects/task-select';
import { PropertySelect } from 'view/components/inputs/selects/v4-selects/property-select';
import { ContactSelect } from 'view/components/inputs/selects/contact';
import { Value } from 'view/components/values';

import { parseUploadedFileMeta } from 'utils/files';
import { QuoteTask } from '../types/QuoteTask';
import { CreateQuoteFormData } from '../types/CreateQuoteFormData';
import { useGetAccessProvidedBySuggestedContacts } from '../../common/hooks/use-get-access-provided-by-suggested-contacts';
import { MaintenanceTask } from '../../maintenance/types/MaintenanceTask';

// We're doing this as we use the data props to pass in related tasks on create
type PartialQuoteTask = Partial<Omit<QuoteTask, 'details'>> & {
  details?: Partial<QuoteTask['details']>;
};

export const detailsBlock: BlockConfig<
  QuoteTask | PartialQuoteTask,
  any,
  Partial<CreateQuoteFormData>
> = {
  id: 'details',
  title: 'Details',
  validate: {
    definitions: {
      summary: { rules: 'required' },
      property: { rules: 'required' },
      managed_by: { rules: 'required' }
    }
  },
  View: ({ data }) => {
    return (
      <Grid columns={1}>
        <Grid columns={2}>
          <Value
            label='Supplier'
            value={data?.details?.supplier?.display_name}
          />
        </Grid>
        <Grid columns={1}>
          <Value label='Description' value={data?.description} />
        </Grid>
        <Grid columns={2}>
          <Value label='Property' value={data?.property?.display_name} />
          <Value label='Relates to task' value={data?.parent_task?.summary} />
        </Grid>
        <Grid columns={2}>
          <Value label='Managed by' value={data?.managed_by?.display_name} />
          <Value label='Progress' value={data?.status?.label} />
        </Grid>
        <Grid columns={1}>
          <Value
            label='Access provided by'
            value={data?.details?.access_provided_by?.display_name}
          />
        </Grid>
      </Grid>
    );
  },
  /* 
    NOTE: This edit block has 3 different view states:
      - Edit mode: this is when viewing the record in the record screen
      - Create mode from the maintenance task: this is when creating a quote from a maintenance task
      - Create mode from the quote list: this is when creating a quote from the quote list 
    */
  Edit: ({ values, data, setFieldValue, forms }) => {
    const {
      loadItems: loadAccessProvidedBySuggestedItems
    } = useGetAccessProvidedBySuggestedContacts({
      property: data?.property
    });

    // NOTE: we're assuming that if there is no id, then we're in create mode
    const isCreateMode = !data?.id;

    // NOTE: we're assuming that if we're in create mode and there is a parent_task,
    // then we're in create mode from the maintenance task
    const isCreateFromMaintenanceTask = isCreateMode && data?.parent_task;

    function onPropertyChange() {
      if (values?.managed_by?.id) {
        setFieldValue?.('managed_by', null);
      }
      if (values?.parent_task?.id) {
        setFieldValue?.('parent_task', null);
        onRelatedTaskChange({ target: { value: null } });
      }
    }

    function onRelatedTaskChange({ target }) {
      const parentTask: MaintenanceTask = target.value;

      const detailsBlockId = 'details';
      const imagesBlockId = 'related-images';
      const quoteDetailsBlockId = 'quote-details';
      const priorityBlockId = 'priority';

      const mapper = [
        {
          blockId: detailsBlockId,
          field: 'property',
          value: parentTask?.property || null
        },
        {
          blockId: detailsBlockId,
          field: 'managed_by',
          value: parentTask?.managed_by || null
        },
        {
          blockId: detailsBlockId,
          field: 'summary',
          value: parentTask?.summary ? `Quote for ${parentTask?.summary}` : null
        },
        {
          blockId: detailsBlockId,
          field: 'description',
          value: parentTask?.description || null
        },
        {
          blockId: imagesBlockId,
          field: 'details.images',
          value: parseUploadedFileMeta(parentTask?.details?.images || [])
        },
        // only set the associated quote field if the 'I have already received a quote' checkbox is selected
        ...(!forms?.[quoteDetailsBlockId]?.values?.details?.has_received_quote
          ? [
              {
                blockId: quoteDetailsBlockId,
                field: 'details.access_provided_by',
                value: parentTask?.details?.access_provided_by || null
              }
            ]
          : []),
        {
          blockId: priorityBlockId,
          field: 'priority',
          value: parentTask?.priority || { id: 'normal', label: 'Normal' }
        }
      ];

      mapper.forEach((item) => {
        forms?.[item.blockId].setFieldValue(item.field, item.value);
      });
    }

    return (
      <Grid columns={1}>
        {/* If we're not in create mode, we show the supplier, but can not edit it */}
        {!isCreateMode && (
          <Grid columns={2}>
            <Value
              label='Supplier'
              value={data?.details?.supplier?.display_name}
            />
          </Grid>
        )}
        <Grid columns={1}>
          <Field
            id='summary'
            name='summary'
            label='Summary'
            optional={false}
            Input={TextInput}
          />
        </Grid>
        <Grid columns={1}>
          <Field
            id='description'
            name='description'
            label='Description'
            Input={TextArea}
          />
        </Grid>
        {/* If we're in create mode from the maintenance task, we hide all of these fields */}
        {!isCreateFromMaintenanceTask && (
          <>
            <Grid columns={2}>
              {!isCreateMode ? (
                <>
                  <Value
                    label='Property'
                    value={data?.property?.display_name}
                  />
                  <Value
                    label='Relates to task'
                    value={data?.parent_task?.summary}
                  />
                </>
              ) : (
                <>
                  <Field
                    id='property'
                    name='property'
                    label='Property'
                    optional={false}
                    Input={PropertySelect}
                    onChange={onPropertyChange}
                    inputProps={{
                      deselectable: true,
                      includes: [
                        'active_property_ownership.ownership.owners.contact',
                        'active_property_tenancy.tenancy.tenants.contact'
                      ]
                    }}
                  />
                  <Field
                    id='parent_task'
                    name='parent_task'
                    label='Relates to task'
                    Input={TaskSelect}
                    onChange={onRelatedTaskChange}
                    inputProps={{
                      filter: [
                        {
                          field: 'type_id',
                          op: 'in',
                          value: ['task_maintenance']
                        },
                        ...(values?.property?.id
                          ? [
                              {
                                field: 'property_id',
                                op: 'eq',
                                value: values?.property?.id
                              }
                            ]
                          : []),
                        {
                          field: 'closed_at',
                          op: 'eq',
                          value: 'null'
                        }
                      ],
                      includes: [
                        'property',
                        'managed_by',
                        'details.access_provided_by',
                        'details.images'
                      ],
                      deselectable: true
                    }}
                  />
                </>
              )}
            </Grid>
            <Grid columns={2}>
              <Field
                id='managed_by'
                name='managed_by'
                label='Managed by'
                optional={false}
                Input={ContactSelect}
              />
            </Grid>
          </>
        )}
        {!isCreateMode && (
          <Grid columns={2}>
            <Field
              id='access_provided_by'
              name='details.access_provided_by'
              label='Access provided by'
              optional={false}
              Input={ContactSelect}
              inputProps={{
                getSuggestedItems: loadAccessProvidedBySuggestedItems
              }}
            />
          </Grid>
        )}
        <HiddenField name='parent_task' />
        <HiddenField name='property' />
        <HiddenField name='managed_by' />
      </Grid>
    );
  }
};
