import React from 'react';

import Box from '@rexlabs/box';
import { StyleSheet, useStyles, useToken } from '@rexlabs/styling';
import { Body, Heading } from '@rexlabs/text';

import { Grid } from 'view/components/@luna/form/grid';
import PhoneIcon from 'view/components/icons/phone';

import { SMSPhonePreview, SMSPhonePreviewContainer } from './sms-phone-preview';

const defaultStyles = StyleSheet({
  icon: {
    width: '4rem',
    height: '4rem',
    borderRadius: ({ token }) => token('border.radius.m'),
    background: ({ token }) => token('color.container.static.contrast'),
    marginRight: ({ token }) => token('spacing.s')
  },

  flex1: {
    flex: 1
  },

  container: {
    border: '1px solid #e7e8e9',
    borderRadius: ({ token }) => token('border.radius.l')
  },

  contentSection: {
    borderTop: '1px solid #e7e8e9',
    padding: ({ token }) => token('spacing.xl'),
    whitespace: 'pre-line'
  }
});

export interface SmsPreviewProps {
  heading?: string;

  /**
   * A string representation of the recipient names. e.g. "John Doe & Jane Doe"
   */
  recipients: string;

  bodyContent: string;

  actionButtonSlot?: React.ReactNode;
}

export function SmsPreview({
  heading,
  recipients,
  bodyContent,
  actionButtonSlot
}: SmsPreviewProps) {
  const s = useStyles(defaultStyles);
  const token = useToken();

  // TODO: derive this from the actual data
  const headerData = [{ label: 'Recipients', value: recipients }];

  return (
    <Box {...s('container')}>
      <Grid columns={1}>
        <Box display='flex' flexDirection='row' justifyContent='space-between'>
          <Box display='flex' flexDirection='row' padding={token('spacing.xl')}>
            <Box>
              <Box {...s('icon')} alignItems='center' justifyContent='center'>
                <PhoneIcon size='l' />
              </Box>
            </Box>
            <Box {...s('flex1')}>
              {heading && (
                <Heading level={4} as='div'>
                  {heading}
                </Heading>
              )}
              {headerData.map(({ label, value }) => (
                <Box key={label}>
                  <Body grey as='span'>
                    {label}:{' '}
                  </Body>
                  <Body as='span'>{value}</Body>
                </Box>
              ))}
            </Box>
          </Box>
          <Box>{actionButtonSlot}</Box>
        </Box>

        <Box {...s('contentSection')}>
          <SMSPhonePreviewContainer>
            <SMSPhonePreview>{bodyContent}</SMSPhonePreview>
          </SMSPhonePreviewContainer>
        </Box>
      </Grid>
    </Box>
  );
}
