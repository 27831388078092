import { useCallback } from 'react';

import { useErrorDialog } from '@rexlabs/dialog';
import { useModelActions } from '@rexlabs/model-generator';

import { getUploadedFileMeta } from 'utils/files';

import { useToast } from 'view/components/@luna/notifications/toast';
import { RecordSubmitHandler } from 'view/components/record-screen/utils';

import { KeySetDetailsFormValues } from '../blocks/key-set-details-block';
import { keySetsModel } from '../data/key-set-model';

export const useEditKeySetSubmitHandler = () => {
  const {
    updateItem: updateKeySetItem,
    refreshLists: refreshKeySetLists
  } = useModelActions(keySetsModel);

  const { open: openErrorDialog } = useErrorDialog();

  const { addToast } = useToast();

  const onHandleSubmit: RecordSubmitHandler<KeySetDetailsFormValues> = useCallback(
    async ({ changedValues, values }) => {
      try {
        const data = { ...changedValues };

        if ('images' in changedValues) {
          const { images = [] } = changedValues;

          data.images = await getUploadedFileMeta(images);
        }

        const { data: keySet } = await updateKeySetItem({
          id: values.id,
          data
        });

        addToast({
          description: `You have updated key set ${keySet.name}`
        });

        refreshKeySetLists();
        return keySet;
      } catch (error) {
        console.error(error);
        openErrorDialog(error);
      }
    },
    [updateKeySetItem, addToast, openErrorDialog]
  );

  return { onHandleSubmit };
};
