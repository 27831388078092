import { RentScheduleEntry } from 'src/modules/property-tenancies/types/property-tenancy-types';
import { getPreviousDay } from '../../../utils/dates/get-previous-date';
import { getRentScheduleEntryStatus } from './get-rent-schedule-entry-status';

export type RentScheduleEntryPartial = Pick<
  RentScheduleEntry,
  'effective_from_date'
>;

export type ResultEntry<Entry extends RentScheduleEntryPartial> = Omit<
  Entry,
  'effective_until_date' | 'status'
> &
  Pick<RentScheduleEntry, 'effective_until_date' | 'status'>;

const sortByEffectiveFrom = <Entry extends RentScheduleEntryPartial>(
  a: Entry,
  b: Entry
) => {
  return (
    new Date(a.effective_from_date || 0).getTime() -
    new Date(b.effective_from_date || 0).getTime()
  );
};

export function augmentRentSchedulePartials<
  RentScheduleEntry extends RentScheduleEntryPartial
>(rentSchedule: RentScheduleEntry[]): ResultEntry<RentScheduleEntry>[] {
  const sorted = [...rentSchedule].sort(sortByEffectiveFrom);
  const withEffectiveUntilDate = sorted.map((entry, index, allEntries) => {
    if (allEntries[index + 1]) {
      return {
        ...entry,
        effective_until_date: getPreviousDay(
          allEntries[index + 1].effective_from_date!
        )
      };
    }

    return {
      ...entry,
      effective_until_date: null
    };
  });

  const withStatus = withEffectiveUntilDate.map((entry) => {
    return {
      ...entry,
      status: getRentScheduleEntryStatus(
        entry.effective_from_date,
        entry.effective_until_date
      )
    };
  });

  return withStatus;
}
