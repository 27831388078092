import { useDialog } from '@rexlabs/dialog';
import { Reconciliation } from 'data/models/entities/financials/reconciliations';
import { SingleActionDeclaration } from 'src/modules/common/actions/types/action-declaration-types';
import { track } from '@rexlabs/analytics';
import { EVENTS } from 'utils/analytics';
import { EditReconciliationDialog } from '../../dialogs/edit-reconciliation';

export function useGetEditReconciliationAction() {
  const { open: openEditDialog } = useDialog(EditReconciliationDialog);

  return (reconciliation: Reconciliation): SingleActionDeclaration => {
    return {
      id: 'edit',
      group: 'reconciliation',
      label: 'Edit reconciliation details',
      handleAction: () => {
        track({
          eventName: EVENTS.RECONCILIATIONS.DETAILS_EDITED,
          data: {
            reconciliationType: reconciliation.type.id
          }
        });

        openEditDialog({
          reconciliation,
          onClose: () =>
            track({ eventName: EVENTS.RECONCILIATIONS.DETAILS_CANCELED })
        });
      }
    };
  };
}
