import React from 'react';

import { BlockConfig } from 'view/components/record-screen/types';
import { Field } from '@rexlabs/form';

import { PreviewFileUploadInputWithErrorHandling } from 'view/components/inputs/file-upload-input/preview-file-upload-input-with-error-handling';
import { Grid } from 'view/components/@luna/form/grid';

import { Appliance } from '../types/Appliance';

export const applianceReceiptsAndWarrantiesBlock: BlockConfig<Appliance> = {
  id: 'appliance-receipts-and-warranties',
  title: 'Receipts and warranties',
  Edit: () => {
    return (
      <Grid columns={1}>
        <Field<typeof PreviewFileUploadInputWithErrorHandling>
          id='warranties'
          name='warranties'
          Input={PreviewFileUploadInputWithErrorHandling}
          optional={false}
          inputProps={{
            multi: true,
            accept: 'application/pdf,.jpeg, .png, .jpg'
          }}
        />
      </Grid>
    );
  }
};
