import { Generator } from 'data/models/generator';
import _ from 'lodash';
import { auditableConfig } from 'src/modules/common/auditable/auditable-config';
import { contactsModel } from 'src/modules/contacts/models/contacts';
import { usersModel } from 'src/modules/users/models/users';
import { api } from 'utils/api/api-client';
import { documentsModel } from 'data/models/entities/documents';
import { BulkCreateMessageRequest } from '../mappers/types/bulk-create-message-request';
import { Message } from '../types/Message';
import { CreateMessageRequest } from '../mappers/types/create-message-request';

export type ValidationType =
  | 'email'
  | 'phone'
  | 'address'
  | 'document'
  | 'compliance_entry';

const config = {
  entities: {
    related: {
      // TODO: configure includes here
      ...auditableConfig,
      context: {
        include: 'context'
      },
      template: {
        include: 'template'
      },
      inline_attachments: {
        include: 'inline_attachments'
      },
      linked_files: {
        include: 'linked_files'
      },
      channels: {
        include: 'channels'
      },
      recipients: {
        include: 'recipients',
        model: [contactsModel]
      },
      cc_recipients: {
        include: 'cc_recipients',
        model: [contactsModel]
      },
      bcc_recipients: {
        include: 'bcc_recipients',
        model: [contactsModel]
      },
      send_from_author: {
        include: 'send_from_author',
        model: usersModel
      },
      documents: {
        include: 'documents',
        model: [documentsModel]
      }
    }
  }
};

const actionCreators = {
  sendItem: {
    request: ({ message }: { message: Message }) =>
      api.post(`/communication/outbox/send`, {
        message: { id: message.id }
      }),
    reduce: _.identity
  },

  bulkCreateItems: {
    request: ({ data }: { data: BulkCreateMessageRequest }) =>
      api.post(`/communication/messages/bulk`, data),
    reduce: _.identity
  },

  createMultiplexItem: {
    request: ({
      data,
      args
    }: {
      data: CreateMessageRequest;
      args?: { include?: string };
    }) =>
      api.post(
        `/communication/messages/multiplex${
          args?.include ? '?include=' + args.include : ''
        }`,
        data
      ),
    reduce: _.identity
  },

  validateItem: {
    request: ({ message, type }: { message: Message; type: ValidationType }) =>
      api.get(`/communication/messages/${message.id}/validate/${type}`),
    reduce: _.identity
  },

  validateItems: {
    request: ({
      messageIds,
      args
    }: {
      messageIds: string[];
      args?: { include?: string };
    }) =>
      api.post(
        `/communication/messages/validate${
          args?.include ? '?include=' + args.include : ''
        }`,
        {
          selection: {
            selected_ids: messageIds
          }
        }
      ),
    reduce: _.identity
  }
};

export const messagesModel = new Generator<Message, typeof actionCreators>(
  'communication/messages',
  config
).createEntityModel({ actionCreators });
