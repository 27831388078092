import React, { useCallback } from 'react';
import {
  query,
  useEntityListQuery,
  useModelActions
} from '@rexlabs/model-generator';
import { DialogProps, Dialog } from '@rexlabs/dialog';

import { RecordSubmitHandler } from 'view/components/record-screen/utils';
import { useSettings } from 'src/modules/settings/hooks/useSettings';
import { filterNullValues } from 'utils/filter-null-values';
import {
  BankAccount,
  bankAccountModel
} from 'data/models/entities/financials/bank-accounts';
import { useToast } from 'view/components/@luna/notifications/toast';
import { RecordDialog } from 'view/components/record-screen/dialog/dialog';
import { DialogContentConfig } from 'view/components/record-screen/dialog/types';
import { Checkbox } from '@rexlabs/checkbox';
import Box from '@rexlabs/box';
import WarningCircleIcon from 'view/components/icons/warning-circle';
import { GhostButton, PrimaryButton } from '@rexlabs/button';
import { push } from '@rexlabs/whereabouts';
import ROUTES from 'routes/app';
import { detailsBlock } from '../blocks/details';

const content: DialogContentConfig = [
  {
    id: 'basics',
    label: 'Basics',
    blocks: [detailsBlock]
  }
];

const bankAccountsQuery = query`{
  ${bankAccountModel}  {
    id
    name
  }
}`;

export function CreateBankAccountDialog({ onClose }: DialogProps) {
  const settingsData = useSettings();
  const [hasConfirmed, setHasConfirmed] = React.useState(false);
  const [showRecordDialog, setShowRecordDialog] = React.useState(false);

  const bankAccounts = useEntityListQuery(bankAccountsQuery);

  const hasMultipleBankAccounts = checkMultipleBankAccounts(bankAccounts.data);

  const { addToast } = useToast();
  const { createItem, refreshLists } = useModelActions(bankAccountModel);

  const handleSubmit: RecordSubmitHandler = useCallback(
    async ({ values }) => {
      setShowRecordDialog(true);

      const { data } = await createItem({
        data: { ...filterNullValues(values), is_default: false }
      });

      addToast({
        description: (
          <>
            Successfully created bank account -{' '}
            <b>{data?.name || data?.account?.account_name}</b>
          </>
        )
      });
      await refreshLists();

      return data;
    },
    [createItem, refreshLists, addToast]
  );

  const handleCancel = React.useCallback(() => {
    push(ROUTES.BANK_ACCOUNTS);
  }, []);

  const handleConfirmation = React.useCallback(() => {
    setShowRecordDialog(true);
  }, []);

  const initialValues = {
    currency: settingsData.currency,
    country: settingsData.country
  };

  if (bankAccounts.status === 'loading') {
    return <Dialog isLoading={true} />;
  }

  if (hasMultipleBankAccounts && !showRecordDialog) {
    return (
      <Dialog title='Use multiple bank accounts' TitleIcon={WarningCircleIcon}>
        <Box flexDirection='column' gap={12}>
          <p>
            Creating multiple bank accounts may have serious financial
            implications on your Rex PM account.
          </p>

          <p>
            To ensure these implications are minimised, please contact the
            support team to help you add any additional bank accounts.
          </p>

          <Checkbox
            label='I still want to continue adding a bank account without the help of the support team.'
            value={hasConfirmed}
            onChange={(e) => {
              setHasConfirmed(e.target.checked);
            }}
          />

          <Box flexDirection='row' justifyContent='flex-end' gap={16}>
            <GhostButton onClick={handleCancel}>Cancel</GhostButton>

            <PrimaryButton
              isDisabled={!hasConfirmed}
              onClick={handleConfirmation}
            >
              Continue adding a bank account
            </PrimaryButton>
          </Box>
        </Box>
      </Dialog>
    );
  }

  return (
    <RecordDialog
      onClose={onClose}
      title='Create bank account'
      initialValues={initialValues}
      handleSubmit={handleSubmit}
      content={content}
    />
  );
}

// Helpers

function checkMultipleBankAccounts(bankAccounts?: Array<BankAccount>) {
  return Array.isArray(bankAccounts) && bankAccounts.length > 0;
}
