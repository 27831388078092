import React, { useEffect } from 'react';
import { BlockConfig } from 'view/components/record-screen/types';
import { FieldArray } from '@rexlabs/form';
import Box from '@rexlabs/box';
import { OutlineButton } from '@rexlabs/button';
import { AddIcon } from 'view/components/icons/add';
import { useToken } from '@rexlabs/styling';
import { useFormValueEffect } from 'view/hooks/@luna/use-form-value-effect';
import { TaxPreview } from 'src/modules/invoices/components/tax-preview';
import { useTaxPreview } from 'utils/tax-preview/use-tax-preview';
import InfoIcon from 'view/components/icons/info';
import { Banner } from 'src/modules/common/components/banner';
import { ReceiptFundsLineItemContainer } from 'src/modules/financials/components/receipt-funds-line-item-container';
import { RentScheduleEntryLineItemsForm } from '../components/rent-schedule-entry-line-item-form';

export const rentScheduleEntryLineItemsBlock: BlockConfig = {
  id: 'rentScheduleEntryLineItemsBlock',
  validate: {
    definitions: {
      'line_items.*.description': { rules: 'required', name: 'Description' },
      'line_items.*.tax_type': { rules: 'required', name: 'Tax type' },
      'line_items.*.payable_to_chart_of_accounts_account': {
        rules: 'required',
        name: 'Chart of accounts account'
      },
      'line_items.*.amount': { rules: 'required', name: 'Amount' }
    }
  },
  Edit: function RentScheduleLineItemsForm({ values, setFieldValue }) {
    const token = useToken();

    const { isLoading, taxPreview, updateTaxPreviews } = useTaxPreview();

    useFormValueEffect(
      'rentScheduleEntryLineItemsBlock',
      ({ values }) => {
        const isTaxIncluded = !!values?.is_tax_included;

        if (values.line_items?.length) {
          const validItems = values.line_items?.filter(
            (item) => item?.amount && item?.tax_type?.id
          );

          if (!validItems.length) return;

          updateTaxPreviews(isTaxIncluded, validItems);
        }
      },
      ['line_items.*.tax_type', 'line_items.*.amount', 'is_tax_included']
    );

    useEffect(() => {
      if (!values.line_items || values.line_items.length <= 0) {
        setFieldValue?.('line_items', [{}]);
      }
    }, []);

    return (
      <>
        {values.line_items.length >= 2 && (
          <Box mb={token('spacing.xl')}>
            <Banner intent={'information'} Icon={InfoIcon} align={'start'}>
              All line items included in the rent charges will contribute to the
              rent position.
            </Banner>
          </Box>
        )}

        <FieldArray name='line_items'>
          {function LineItem({ fields, push }) {
            return (
              <>
                <Box>
                  {fields.map(({ field, actions }, index) => (
                    <div
                      key={field.name}
                      data-testid='rent-schedule-entry-line-item'
                    >
                      <ReceiptFundsLineItemContainer
                        onRemove={actions.remove}
                        heading={`Line Item ${index + 1}`}
                      >
                        <RentScheduleEntryLineItemsForm
                          name={field.name}
                          amountInclTax={
                            taxPreview?.line_items?.[index]?.amount_inc_tax
                          }
                          amountInclTaxLoading={isLoading}
                          setFieldValue={setFieldValue}
                        />
                      </ReceiptFundsLineItemContainer>
                    </div>
                  ))}
                </Box>
                <Box mt='2rem' flexDirection='row'>
                  <OutlineButton
                    onClick={() => {
                      push({});
                    }}
                    IconLeft={AddIcon}
                  >
                    Split rent charge
                  </OutlineButton>

                  <TaxPreview
                    isTaxIncludedFieldName='is_tax_included'
                    isLoading={isLoading}
                    taxPreview={taxPreview}
                    isTaxIncluded={values?.is_tax_included}
                    setFieldValue={setFieldValue}
                  />
                </Box>
              </>
            );
          }}
        </FieldArray>
      </>
    );
  }
};
