import * as React from 'react';
import { StatusTagMap } from 'src/modules/common/components/status-tag';
import { LeaseReviewStatus } from '../types/LeaseReviewStatus';
import { TaskStatusSelect } from '../../common/components/task-status-select';

const taskStatusMap: StatusTagMap<LeaseReviewStatus> = {
  not_started: 'neutral',
  review_in_progress: 'average',
  agreement_updated: 'good'
};

export function LeaseReviewProgressSelect(props) {
  return (
    <TaskStatusSelect
      taskStatusMap={taskStatusMap}
      valueListId={'lease_review_status'}
      {...props}
    />
  );
}
