import React, { forwardRef } from 'react';
import { Grid, GridProps } from '@rexlabs/grid';
import { StylesProvider } from '@rexlabs/styling';

export const formGridTokens = {
  grid: {
    spacing: {
      y: '2.4rem'
    }
  }
};

export const FormGrid = forwardRef<any, any>((props: GridProps, ref) => {
  return (
    <StylesProvider tokens={formGridTokens}>
      <Grid ref={ref} {...props} />
    </StylesProvider>
  );
});
