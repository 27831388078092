import React from 'react';
import { BlockConfig } from 'view/components/record-screen/types';
import { Grid, Column } from '@rexlabs/grid';
import { Value } from 'view/components/values';
import { Field } from '@rexlabs/form';
import { RemoveTaskWithheldFundsSelect } from 'src/modules/settings/account-settings/components/selects/remove-task-withheld-funds';
import InfoIcon from 'view/components/icons/info';
import { Banner } from 'src/modules/common/components/banner';
import { Trans, useTranslation } from 'react-i18next';
import { Settings } from '../../hooks/useSettings';

const defaultLabel = 'Automatically remove task withheld funds';

export const removeTaskWithheldFundsSettingsBlock: BlockConfig<Settings> = {
  id: 'remove-task-withheld-funds-settings',
  title: () => <Trans i18nKey='settings.withheld-funds.title' />,
  validate: {
    definitions: {
      'remove-task-withheld-funds': { rules: 'required' }
    }
  },
  View: ({ data }) => {
    const { t } = useTranslation();

    return (
      <Grid>
        <Column width={4}>
          <Value
            label={t('settings.withheld-funds.field-label') ?? defaultLabel}
            value={data?.['remove-task-withheld-funds']?.label}
          />
        </Column>
      </Grid>
    );
  },
  Edit: ({ data, values }) => {
    const { t } = useTranslation();
    const fieldName = 'remove-task-withheld-funds';
    const hasChanged = data && data[fieldName].id !== values[fieldName].id;

    const Tooltip =
      values[fieldName].id === 'last_bill_is_uploaded_or_the_task_is_closed'
        ? {
            HelpTooltipContent: () => (
              <>
                If there are multiple work orders, the task float will be
                removed when the number of bills uploaded equals the number of
                work orders
              </>
            )
          }
        : {};

    return (
      <Grid>
        <Column width={6}>
          <Field
            id='remove-task-withheld-funds'
            label={t('settings.withheld-funds.field-label') ?? defaultLabel}
            name='remove-task-withheld-funds'
            Input={RemoveTaskWithheldFundsSelect}
            {...Tooltip}
          />
        </Column>
        {hasChanged && (
          <Column width={12}>
            <Banner intent={'information'} Icon={InfoIcon} align={'start'}>
              Editing this setting will only apply to new task floats. Any
              existing floats will remain until manually removed or the task is
              closed.
            </Banner>
          </Column>
        )}
      </Grid>
    );
  }
};
