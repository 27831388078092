import React from 'react';
import { EmptySection } from './base';

export function EmptyOwners() {
  return (
    <EmptySection
      headline='To be defined'
      buttonLabel='View owners'
      linkProps={{ query: { tab: 'owners' } }}
    >
      Choose from an existing owner or create a new owner for this record.
    </EmptySection>
  );
}
