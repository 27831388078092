import { Generator } from 'data/models/generator';
import { ValueListValue } from 'data/models/types';
import {
  BaseModelGeneratorModel,
  CustomAction
} from '@rexlabs/model-generator';
import { api } from 'utils/api/api-client';
import _ from 'lodash';
import { Contact } from 'src/modules/contacts/types/contact-types';
import { BankAccount } from './bank-accounts';

export type SecurityDepositTypeStatus = 'active' | 'archived';
export type SecurityDepositAmount = {
  type: ValueListValue<'amount' | 'weeks_of_rent'>;
  amount_expected: number;
  amount_expected_as_weeks_of_rent: number;
};

export type SecurityDepositTypeHeldBy =
  | 'held_by_deposit_authority'
  | 'held_by_agency'
  | 'held_by_ownership';

export type SecurityDepositType = BaseModelGeneratorModel & {
  status: ValueListValue<SecurityDepositTypeStatus>;
  name: string;
  default_amount: SecurityDepositAmount;
  held_by: ValueListValue<SecurityDepositTypeHeldBy>;
  initial_payment_method?: {
    payment_method: ValueListValue<string>;
  };
  held_by_contact?: Contact;
  held_by_bank_account?: BankAccount;
};

export const config = {
  entities: {
    related: {
      initial_payment_method: {
        include: 'initial_payment_method'
      },
      held_by_contact: {
        include: 'held_by_contact'
      },
      top_up_payment_method: {
        include: 'top_up_payment_method'
      },
      held_by_bank_account: {
        include: 'held_by_bank_account'
      }
    }
  }
};

type ArchiveActionPayload = {
  depositTypeId: string;
};

type UnarchiveActionPayload = {
  depositTypeId: string;
};

const actionCreators = {
  archive: {
    request: ({ depositTypeId }, _actions, _dispatch, _getState) =>
      api.put(`/financials/security-deposit-types/${depositTypeId}/status`, {
        status: {
          id: 'archived'
        }
      }),
    reduce: _.identity
  } as CustomAction<ArchiveActionPayload, unknown>,
  unarchive: {
    request: ({ depositTypeId }, _actions, _dispatch, _getState) =>
      api.put(`/financials/security-deposit-types/${depositTypeId}/status`, {
        status: {
          id: 'active'
        }
      }),
    reduce: _.identity
  } as CustomAction<UnarchiveActionPayload, unknown>
};

export const financialsSecurityDepositTypesModel = new Generator<
  SecurityDepositType,
  typeof actionCreators
>('financials/security-deposit-types', config).createEntityModel({
  actionCreators
});
