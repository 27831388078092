import React, { ComponentType } from 'react';

import Box from '@rexlabs/box';
import {
  useStyles,
  StyleSheet,
  text,
  padding,
  margin,
  mq
} from '@rexlabs/styling';
import { Breakpoints } from '@rexlabs/breakpoints';
import { Truncate } from '@rexlabs/text';

const defaultStyles = StyleSheet({
  banner: {
    position: 'sticky',
    top: 0,
    width: '100%',
    zIndex: 1,
    transition: 'transform 300ms ease-out',

    ...text.styles({
      fallback: 'normal.semibold'
    }),

    boxShadow: ({ token }) => token('shadow.medium'),
    backgroundColor: ({ token }) => token('color.pageBackground.alternate'),

    ...mq.styles.combine([
      {
        queries: {
          maxWidth: 's'
        },
        styles: {
          height: '4.4rem',
          marginTop: '-4.4rem',
          transform: 'translateY(-4.4rem)',

          ...padding.styles({
            all: 's'
          })
        }
      },
      {
        queries: {
          minWidth: 's',
          maxWidth: 'm'
        },
        styles: {
          height: '5.6rem',
          marginTop: '-5.6rem',
          transform: 'translateY(-5.6rem)',

          ...text.styles({
            fallback: 'large.semibold'
          }),
          ...padding.styles({
            x: 'l',
            y: 'm'
          })
        }
      }
    ])
  },

  bannerActive: {
    ...mq.styles({
      queries: {
        maxWidth: 'm'
      },
      styles: {
        transform: 'translateY(0rem)'
      }
    })
  },

  icon: {
    ...mq.styles.combine([
      {
        queries: {
          maxWidth: 's'
        },
        styles: {
          ...margin.styles({
            right: 's'
          })
        }
      },
      {
        queries: {
          minWidth: 's',
          maxWidth: 'm'
        },
        styles: {
          ...margin.styles({
            right: 'l'
          })
        }
      }
    ])
  }
});

type StickyBannerProps = {
  title: string;
  active: boolean;
  Icon?: ComponentType<any>;
};

export function StickyBanner({ title, active, Icon }: StickyBannerProps) {
  const s = useStyles(defaultStyles);

  return (
    <Box
      {...s('banner', {
        bannerActive: active
      })}
      alignItems='center'
    >
      {Icon && (
        <Box {...s('icon')} alignItems='center'>
          <Breakpoints noRender={false} queries={{ maxWidth: 's' }}>
            <Icon size='m' />
          </Breakpoints>
          <Breakpoints noRender={false} queries={{ minWidth: 's' }}>
            <Icon size='l' />
          </Breakpoints>
        </Box>
      )}
      <Truncate>{title}</Truncate>
    </Box>
  );
}
