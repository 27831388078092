import React from 'react';
import { BlockConfig } from 'view/components/record-screen/types';
import { Grid } from 'view/components/@luna/form/grid';
import { Field, HiddenField } from '@rexlabs/form';
import { Checkbox } from '@rexlabs/checkbox';
import { TextInput } from '@rexlabs/text-input';
import { ChecklistItemDueDateInput } from 'view/components/inputs/checklist-item-due-date-input/checklist-item-due-date-input';
import { useFeatureFlags } from 'view/components/@luna/feature-flags';
import { FLAGS } from 'utils/feature-flags';
import { ValueListValue } from 'data/models/types';
import invariant from 'invariant';
import Box from '@rexlabs/box';
// import '../../utils/checklist-item-validation-rules';
import { ChecklistItemType } from 'src/modules/tasks/common/types/ChecklistItemType';
import {
  ChecklistTemplatesForm,
  ChecklistTemplatesItemForm
} from '../../types/ChecklistTemplatesForm';
import { ChecklistAssigneeSelect } from '../../../common/components/checklist-assignee-select';
import { TaskType } from '../../../common/types/TaskType';
import { ChecklistTemplateItem } from '../../types/ChecklistTemplate';

export const checklistTemplateItemSubtaskDetailsBlock: BlockConfig<
  ChecklistTemplateItem,
  {
    taskType: ValueListValue<TaskType>;
    mainValues: Partial<ChecklistTemplatesForm>;
    subtypeId: ChecklistItemType;
  },
  ChecklistTemplatesItemForm & { new_outcome_option: string }
> = {
  id: 'checklist-template-item-details',
  title: 'Subtask details',
  validate: {
    definitions: {
      name: { rules: 'required', name: 'subtask name' },
      due_date: { rules: 'required|fullDueDateIsRequired' },
      assignee_contact_or_role: {
        rules: 'required_if:type.id,approval_request'
      }
    },
    messages: {
      required_if: 'The :attribute field is required.'
    }
  },
  Edit({ blockProps, values }) {
    invariant(blockProps, 'blockProps is required');
    const { taskType } = blockProps;
    const { hasFeature } = useFeatureFlags();

    return (
      <Grid columns={1}>
        <HiddenField name='has_outcome' />
        <Field
          name='name'
          label='Subtask name'
          Input={TextInput}
          inputProps={{
            placeholder: 'eg "Do a thing"'
          }}
        />
        <Grid columns={2} style={{ alignItems: 'flex-start' }}>
          <Field
            name='due_date'
            label='Subtask due date'
            Input={ChecklistItemDueDateInput}
            inputProps={{ taskType, allowSpecificDate: false }}
            optional={false}
          />
          {hasFeature(FLAGS.SMART_CHECKLISTS_ASSIGNEE_ROLES) && (
            <Box>
              <Field
                id='assignee_contact_or_role'
                name='assignee_contact_or_role'
                label='Assigned to'
                optional={false}
                Input={ChecklistAssigneeSelect}
                inputProps={{
                  getSuggestedItems: () =>
                    [values?.assignee_contact_or_role].filter(Boolean)
                }}
              />
              {(values?.type.id === 'approval_request' ||
                values?.assignee_contact_or_role) && (
                <Field
                  name='restricted_to_assignee'
                  Input={Checkbox}
                  optional={false}
                  inputProps={{
                    label: 'Only the assignee can complete this subtask',
                    disabled: values.type.id === 'approval_request'
                  }}
                />
              )}
            </Box>
          )}
        </Grid>
      </Grid>
    );
  }
};
