import { FreeformConfig, useFreeformDialog } from '@rexlabs/select';
import * as React from 'react';
import {
  EntitySelect,
  EntitySelectProps
} from 'view/components/inputs/selects/v4-selects/entity-select';
import { SearchResultItem } from 'utils/api/get-search-results';
import { RecordData } from 'utils/types';
import { FreeformAdvancedFolioSearchDialog } from '../dialogs/freeform-advanced-folio-search-dialog';

export function AdvancedFolioSearchEntitySelect(props: EntitySelectProps) {
  const { getSelectProps: useFreeformDialogGetSelectProps } = useFreeformDialog<
    SearchResultItem<RecordData>
  >({
    props: {},
    Dialog: FreeformAdvancedFolioSearchDialog
  });

  const selectFixtureProps = useFreeformDialogGetSelectProps();

  return (
    <EntitySelect
      {...props}
      {...{
        ...selectFixtureProps,
        freeform: {
          ...selectFixtureProps.freeform,
          label: 'Advanced folio search'
        } as FreeformConfig<any>
      }}
    />
  );
}
