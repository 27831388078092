import { AnyModel, Query } from '@rexlabs/model-generator';
import React from 'react';
import LoadingState from 'view/components/states/compact/loading';
import { Card } from 'view/components/@luna/card';
import { DumbTable } from 'view/components/table/dumb-table';
import { Columns, TableProvider } from 'view/components/table/provider';
import { useTableQuery, useUrlState } from '@rexlabs/table';

import { BlockConfig, CardProps } from '../types';
import { BlockLayout } from './block-layout';
import { EmptyCard } from './empty-card';

export type BasicTableProps<Model extends AnyModel<any, any>> = {
  id: string;
  getQuery: () => Query<Model>;
  columns: Columns;
  hashParamKey?: string;
};

type PaginationProps =
  | { withPagination?: false; hashParamKey?: never }
  | { withPagination: true; hashParamKey: string };

export type TableBlockArgs<Data, TableModel extends AnyModel<any, any>> = Omit<
  BlockConfig<Data>,
  'View' | 'Edit' | 'Card' | 'validate'
> & {
  Loading?: (props: any) => JSX.Element;
  Empty: (props: CardProps<Data>) => JSX.Element;
  getTableProps: (args: CardProps<Data>) => BasicTableProps<TableModel>;
  withToolbar?: boolean;
  childrenFragment?: React.ReactNode;
} & PaginationProps;

export function createTableBlock<Data, TableModel extends AnyModel<any, any>>({
  Empty,
  Loading = LoadingState,
  getTableProps,
  Actions,
  withToolbar = false,
  withPagination = false,
  hashParamKey,
  childrenFragment,
  ...args
}: TableBlockArgs<Data, TableModel>): BlockConfig {
  return {
    ...args,
    Card: ({ id, title, ...args }) => {
      const { getQuery, ...tableProps } = getTableProps(args);
      const { getTableProps: getUrlStateProps } = useUrlState({
        pageSizes: [10, 20, 50, 100],
        hashParamKey
      });
      const { getTableProps: getTableStateProps } = useTableQuery({
        getQuery
      });

      const tableUrlStateProps = getUrlStateProps();
      const tableStateProps = getTableStateProps();

      const tableIsLoading = tableStateProps?.isLoading;
      const tableIsEmpty = tableStateProps?.items.length === 0;

      return (
        <TableProvider
          {...tableUrlStateProps}
          {...tableStateProps}
          {...tableProps}
        >
          {tableIsLoading ? (
            <Card>
              <Loading />
            </Card>
          ) : tableIsEmpty ? (
            <EmptyCard>
              <Empty {...args} />
            </EmptyCard>
          ) : (
            <BlockLayout
              id={`block-${id}`}
              headline={title as string}
              actions={Actions ? <Actions {...args} /> : undefined}
            >
              <DumbTable
                withToolbar={withToolbar}
                withPagination={withPagination}
              />

              {childrenFragment}
            </BlockLayout>
          )}
        </TableProvider>
      );
    }
  };
}
