import { useDialog } from '@rexlabs/dialog';
import { ActionDeclaration } from 'src/modules/common/actions/types/action-declaration-types';
import { EditMessageRecordDialog } from '../../dialogs/edit-message-record-dialog';
import { EditSMSRecordDialog } from '../../dialogs/edit-sms-record-dialog';
import { Message } from '../../types/Message';
import { EditLetterRecordDialog } from '../../dialogs/edit-letter-record-dialog';

export function useGetEditMessageAction() {
  const editMessageRecordDialog = useDialog(EditMessageRecordDialog);
  const editSMSRecordDialog = useDialog(EditSMSRecordDialog);
  const editLetterRecordDialog = useDialog(EditLetterRecordDialog);

  return (message: Message): ActionDeclaration => {
    const channelType = message.channels[0].channel_type.id;

    return {
      id: 'edit-message',
      group: 'messages',
      label: 'Edit message',
      handleAction: async () => {
        switch (channelType) {
          case 'letter':
            editLetterRecordDialog.open({
              data: { message }
            });
            break;
          case 'sms':
            editSMSRecordDialog.open({
              data: { message }
            });
            break;
          default:
          case 'email':
            editMessageRecordDialog.open({
              data: { message }
            });
            break;
        }
      }
    };
  };
}
