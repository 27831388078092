import * as React from 'react';
import { Card } from 'view/components/@luna/card';
import { Field, Form, ReactForms, ReactFormsProps } from '@rexlabs/form';
import { StyleSheet, useStyles, padding } from '@rexlabs/styling';
import { TextInput } from '@rexlabs/text-input';
import { OutlineButton } from '@rexlabs/button';
import { Grid } from 'view/components/@luna/form/grid';
import { CentAmountInput } from 'view/components/inputs/cent-amount';
import { DateInput } from 'view/components/@luna/inputs/date-input/date-input';
import AddIcon from 'view/components/icons/add';
import { NewSplitTransactionFormValue } from '../types';

const styles = StyleSheet({
  grey: {
    background: ({ token }) => token('color.container.static.contrast'),
    ...padding.styles({
      all: 'l'
    })
  }
});

const validate = {
  definitions: {
    amount: { rules: 'required|min:0' },
    reference: { rules: 'required' },
    paid_date: { rules: 'required' }
  }
};

export function NewSplitTransactionForm(
  props: Pick<
    ReactFormsProps<NewSplitTransactionFormValue, any>,
    'handleSubmit'
  >
) {
  const { handleSubmit } = props;
  const s = useStyles(styles);

  return (
    <Card {...s('grey')} data-testid='new-split-transaction-form'>
      <ReactForms<NewSplitTransactionFormValue, void>
        validate={validate}
        handleSubmit={handleSubmit}
      >
        {({ submitForm }) => {
          return (
            <Form>
              <Grid columns={4}>
                <Field
                  label='Transaction date'
                  name='paid_date'
                  Input={DateInput}
                />
                <Field
                  label='Description'
                  name='description'
                  Input={TextInput}
                />
                <Field label='Reference' name='reference' Input={TextInput} />
                <Field
                  label='Transaction amount'
                  name='amount'
                  Input={CentAmountInput}
                  inputProps={{ min: 0 }}
                />

                <OutlineButton
                  onClick={() => {
                    submitForm();
                  }}
                  IconLeft={AddIcon}
                >
                  Add transaction
                </OutlineButton>
              </Grid>
            </Form>
          );
        }}
      </ReactForms>
    </Card>
  );
}
