import { RecordTable } from 'view/components/table';
import React, { useCallback } from 'react';

import { Quri, toQuri } from '@rexlabs/quri';

import { query } from '@rexlabs/model-generator';

import { entryColumns } from 'src/modules/compliance/utils/entry-columns';
import { complianceEntriesModel } from 'src/modules/compliance/common/models/compliance-entries';
import { SupplierComplianceEntryTableEmptyState } from 'src/modules/compliance/common/components/supplier-compliance-entry-table-empty-state';
import { Task } from 'src/modules/tasks/common/types/Task';

export function getAllEntriesForComplianceTableQuery({
  filters
}: {
  filters: Quri[];
}) {
  const filterString = filters?.length ? toQuri(filters) : undefined;
  return query`{
    ${complianceEntriesModel} (q: ${filterString}){
      id
      issued_at
      expires_at
      compliance_type
      status
    }
  }`;
}

interface TaskComplianceEntriesListProps {
  filters: Quri[];
  task?: Task<any>;
}
export function SupplierComplianceTaskEntriesList(
  props: TaskComplianceEntriesListProps
) {
  const { filters, task } = props;
  const EmptyState = React.useCallback(
    () => <SupplierComplianceEntryTableEmptyState task={task} />,
    [task]
  );

  const getQuery = useCallback(() => {
    return getAllEntriesForComplianceTableQuery({ filters });
  }, [filters]);

  return (
    <RecordTable
      Empty={EmptyState}
      emptyWithoutTable
      columns={entryColumns}
      getQuery={getQuery}
      withPagination={true}
    />
  );
}
