import React from 'react';

import { CellProps } from '@rexlabs/table';

import { ActionButtonCell } from 'view/components/cells/action-button-cell';

import { QuoteTask } from '../types/QuoteTask';
import { useGetActionsFromStatus } from '../hooks/use-get-actions-from-status';

export function QuoteActionCell({ value }: CellProps<any, QuoteTask>) {
  const getQuoteActions = useGetActionsFromStatus();

  const { primaryActions } = getQuoteActions(value);

  const primaryAction = primaryActions?.[0];
  const quoteHasBeenUsedToIssueWorkOrder =
    value.details?.task_work_orders &&
    value.details?.task_work_orders.data.length > 0;

  const shouldHideCreateWorkOrderAction =
    primaryAction?.id === 'create-task-work-order' &&
    quoteHasBeenUsedToIssueWorkOrder;

  return primaryAction?.label && !shouldHideCreateWorkOrderAction ? (
    <ActionButtonCell action={primaryAction} />
  ) : (
    <></>
  );
}
