import React, { useMemo } from 'react';
import { query, useEntityQuery } from '@rexlabs/model-generator';

import { RecordScreen } from 'view/components/record-screen';
import { useRecordScreenSubmitHandler } from 'view/hooks/use-record-screen-submit-handler';

import { BREADCRUMBS } from 'view/components/@luna/breadcrumbs';
import {
  financialsSecurityDepositsModel,
  SecurityDeposit
} from 'data/models/entities/financials/security-deposits';
import { getRecordLabel } from 'utils/records/get-record-label';
import { content } from '../data/content';
import { SecurityDepositTitleBlock } from '../components/security-deposit-title-block';

interface SecurityDepositDetailsScreenProps {
  securityDepositId: string;
}

const getSecurityDepositQuery = (securityDepositId: string) => query`{
  ${financialsSecurityDepositsModel} (id: ${securityDepositId}) {
    id
    insured_by_contact
    held_by_ownership
    security_deposit_type {
      held_by_contact
    }
    property_tenancy {
      property
      tenancy {
        tenants
        related_contacts
      }
    }
  }
}`;

export function SecurityDepositDetailsScreen({
  securityDepositId
}: SecurityDepositDetailsScreenProps) {
  const securityDepositQuery = useMemo(
    () => getSecurityDepositQuery(securityDepositId),
    [securityDepositId]
  );
  const { data, status } = useEntityQuery(securityDepositQuery);

  const breadcrumbs = useMemo(() => {
    if (!data?.property_tenancy) return undefined;

    return [
      {
        type: BREADCRUMBS.PROPERTY
      },
      {
        type: BREADCRUMBS.PROPERTY_TENANCY_DETAIL,
        args: {
          id: data.property_tenancy.id,
          parentId: data.property_tenancy.property_id,
          label: getRecordLabel({
            type: 'property_tenancy',
            object: data.property_tenancy
          })
        }
      }
    ];
  }, [data]);

  const handleSubmit = useRecordScreenSubmitHandler(async ({ values }) => {
    // eslint-disable-next-line no-console
    console.log(values);
  });

  return (
    <RecordScreen
      isLoading={status === 'loading'}
      data={data}
      handleSubmit={handleSubmit}
      content={content}
      titleBlock={
        <SecurityDepositTitleBlock securityDeposit={data as SecurityDeposit} />
      }
      breadcrumbs={breadcrumbs}
    />
  );
}
