import React from 'react';
import { ownershipsFeesModel } from 'data/models/entities/ownerships/fees/ownership-fees';
import { query } from '@rexlabs/model-generator';
import { Ownership } from 'data/models/entities/ownerships';
import { activeFeesColumns } from 'src/modules/agency-fees/utils/fee-columns';
import { ActionButtons } from 'view/components/@luna/action-buttons';
import { createTableBlock } from 'view/components/record-screen/cards/create-table-block';
import { Message } from 'view/components/@luna/message';
import { makeActionPrimary } from 'utils/actions/make-primary';
import { transformActionDeclarationsToActionMenuItems } from 'utils/actions/transforms';
import { useGetEntityFeeActions } from 'src/modules/entity-fees/actions/use-get-entity-fee-actions';
import { useGetAddOwnershipFee } from '../hooks/use-get-add-ownership-fee-action';

export const getOwnershipFeesQuery = (ownershipId: string) => query`{
  ${ownershipsFeesModel} (ownershipId: ${ownershipId}, o: "name+asc") {
    id
    agency_fee
    tax_type
  }
}`;

export const useFeesBlock = () => {
  const getAddOwnershipFeeAction = useGetAddOwnershipFee();

  const getEntityFeeActions = useGetEntityFeeActions();

  return createTableBlock<Ownership, typeof ownershipsFeesModel>({
    id: 'ownership-fees',
    title: 'Ownership fees',
    withPagination: true,
    hashParamKey: 'ownership-fees',
    getTableProps: ({ data }) => {
      const actions = ({ item: entityFee }) => {
        return transformActionDeclarationsToActionMenuItems(
          getEntityFeeActions(entityFee, data)
        );
      };

      return {
        id: 'ownership-fees',
        getQuery: () => getOwnershipFeesQuery(data.id),
        columns: activeFeesColumns,
        getActionMenuItems: actions
      };
    },
    Empty: ({ data }) => {
      return (
        <Message
          title='No ownership fees applied'
          actions={[getAddOwnershipFeeAction(data!)].map(makeActionPrimary)}
        >
          Currently you are not charging any fees on this ownership.
        </Message>
      );
    },
    Actions: ({ data }) => {
      return <ActionButtons actions={[getAddOwnershipFeeAction(data!)]} />;
    }
  });
};
