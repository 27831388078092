import React from 'react';

import { Columns, RecordTable } from 'view/components/table';
import { TrustJournalEntryLineItem } from 'src/modules/trust-journal-entry-line-items/types/trust-journal-entry-line-item-type';
import { getRecordLinkProps } from 'view/components/record-link/get-record-link-props';
import { RecordTableProps } from 'view/components/table/record';
import { trustJournalEntryLineItemModel } from '../models/trust-journal-entry-line-item-model';

const columns: Columns<TrustJournalEntryLineItem> = [
  {
    Header: 'Entry Id',
    id: 'trust_journal_entry',
    type: 'trust_journal_entry',
    accessor: (item) => item.trust_journal_entry,
    width: 120
  },
  {
    Header: 'Date of',
    type: 'date',
    accessor: (item) => item.trust_journal_entry!.date_of,
    width: 120
  },
  {
    Header: 'Description',
    accessor: (item) => item.description
  },
  {
    Header: 'Amount',
    type: 'currency',
    accessor: (item) => item.amount ?? 0,
    cellProps: {
      align: 'right'
    },
    width: 120
  }
];

const balanceColumns: Columns<TrustJournalEntryLineItem> = [
  {
    Header: 'Balance',
    type: 'currency',
    accessor: (item) => item.running_balance_amount ?? 0,
    cellProps: {
      align: 'right'
    },
    width: 120
  }
];

export function TrustJournalEntryLineItemTable(
  props: RecordTableProps<typeof trustJournalEntryLineItemModel> & {
    showBalance?: boolean;
  }
) {
  const { showBalance } = props;

  const newColumns = React.useMemo(() => {
    if (showBalance) {
      return [...columns, ...balanceColumns];
    }
    return columns;
  }, [showBalance]);

  return (
    <RecordTable
      {...props}
      withPagination={true}
      columns={newColumns}
      getRowLinkProps={({ item }) =>
        getRecordLinkProps({
          type: 'trust_journal_entry',
          id: item.trust_journal_entry.id
        })
      }
    />
  );
}
