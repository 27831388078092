import React from 'react';
import { useTranslation } from 'react-i18next';

export function MissingPaymentWarning() {
  const { t } = useTranslation();
  return (
    <div>
      <b>{t('disbursements.preferences.missing.label')}</b>
      <br />
      <br />
      {t('disbursements.preferences.missing.description')}
    </div>
  );
}
