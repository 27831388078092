import React, { useMemo } from 'react';
import { InputProps } from '@rexlabs/form';
import { SelectableCashBookEntriesListUI } from '../components/selectable-cash-book-entries-list';
import { useCashBookEntriesQuery } from '../hooks/use-cash-book-entries-query';

interface CashBookEntriesListProps extends InputProps {
  isAvailableForDeposit?: boolean;
  trustAccountId?: string;
}

export function CashBookEntriesListSelectInput({
  isAvailableForDeposit = true,
  trustAccountId,
  onBlur,
  onChange
}: CashBookEntriesListProps) {
  const query = useCashBookEntriesQuery(isAvailableForDeposit, trustAccountId);

  const handleOnChange = React.useCallback(
    (cashBookEntries) => {
      onChange?.({
        target: {
          name,
          type: 'select',
          value: cashBookEntries
        }
      });
      onBlur?.({});
    },
    [onBlur, onChange]
  );

  return useMemo(
    () => (
      <SelectableCashBookEntriesListUI
        query={query}
        onSelectionChange={handleOnChange}
      />
    ),
    [query]
  );
}
