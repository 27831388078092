import React from 'react';

import { BlockConfig } from 'view/components/record-screen/types';
import { Card } from 'view/components/card';

import EmptyTable from 'src/assets/illustrations/empty-table.svg';
import { Message } from 'view/components/@luna/message';
import { isEmpty } from 'lodash';
import Box from '@rexlabs/box';
import {
  DragDropContext,
  DropResult,
  Droppable,
  Draggable
} from 'react-beautiful-dnd';
import { PropertyAreaCard } from 'src/modules/properties/components/property-area';
import { api } from 'utils/api/api-client';
import { GhostIconButton, OutlineButton } from '@rexlabs/button';
import AddIcon from 'view/components/icons/add';
import { useDialog } from '@rexlabs/dialog';
import { PropertyAreaRecordDialog } from 'src/modules/property-areas/property-areas/dialogs/property-area-dialog';
import ActionMenu from '@rexlabs/action-menu';
import KebabIcon from 'view/components/icons/kebab';
import { useModelActions } from '@rexlabs/model-generator';
import { useQueryClient } from 'react-query';
import { InspectionArea } from '../entry-exit/types/InspectionArea';
import { useCreateInspectionAreaSubmitHandler } from '../hooks/use-create-inspection-area-submit-handler';
import { useGetDeleteInspectionAreaAction } from '../hooks/use-get-delete-inspection-area-action';
import { useGetUpdateInspectionAreaAction } from '../hooks/use-get-update-inspection-area-action';
import { tasksModel } from '../../common/models/tasks-model';
import { InspectionTask } from '../types/InspectionTask';

type InspectionBlockProps = {
  inspectionTask: InspectionTask;
  id: string;
  area_order: Array<string>;
  areas: Array<InspectionArea>;
};

export const inspectionAreasBlock: BlockConfig<InspectionBlockProps> = {
  id: 'inspection-areas',
  title: 'Inspection Areas',
  Empty: EmptyState,
  showEmpty: (data) => isEmpty(data?.area_order),
  Edit: ({ data }) => {
    const { refreshItem } = useModelActions(tasksModel);
    const queryClient = useQueryClient();
    const areaRecordDialog = useDialog(PropertyAreaRecordDialog);
    const createInspectionAreaSubmitHandler = useCreateInspectionAreaSubmitHandler(
      data!.inspectionTask!
    );

    const propertyAreas = data?.areas;

    const [itemOrder, setItemOrder] = React.useState(data?.area_order ?? []);

    const handleDragEnd = async (result: DropResult) => {
      if (!result.destination) return;

      const newItemOrder = Array.from(itemOrder);

      newItemOrder.splice(result.source.index, 1);
      newItemOrder.splice(result.destination.index, 0, result.draggableId);

      setItemOrder(newItemOrder);

      try {
        await api.patch(`/tasks/inspection-reports/${data?.id}`, {
          area_order: newItemOrder
        });

        await Promise.all([
          refreshItem({ id: data?.id }),
          queryClient.invalidateQueries(['task', data?.id])
        ]);
      } catch (err) {
        // err
      }
    };

    React.useLayoutEffect(() => {
      setItemOrder(data?.area_order ?? []);
    }, [data?.area_order]);

    return (
      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable direction='vertical' droppableId='inspectionAreas'>
          {(provided) => (
            <Box
              flexDirection='column'
              gap={12}
              marginBottom={24}
              ref={provided.innerRef}
              {...provided.droppableProps}
            >
              {itemOrder.map((itemId, index) => {
                return (
                  <Draggable draggableId={itemId} key={itemId} index={index}>
                    {(provided) => {
                      const propertyArea = propertyAreas!.find(
                        (area) => area.id === itemId
                      )!;

                      if (!propertyArea) return <div />;

                      return (
                        <PropertyAreaCard
                          key={itemId}
                          ref={provided.innerRef}
                          propertyArea={propertyArea}
                          dragContainerProps={provided.draggableProps}
                          dragHandleProps={provided.dragHandleProps!}
                        >
                          <AreaActionMenu
                            taskId={data!.id}
                            propertyArea={propertyArea}
                            areaOrder={itemOrder}
                            updateAreaOrder={setItemOrder}
                          />
                        </PropertyAreaCard>
                      );
                    }}
                  </Draggable>
                );
              })}

              {provided.placeholder}
            </Box>
          )}
        </Droppable>

        <OutlineButton
          IconLeft={AddIcon}
          onClick={() => {
            areaRecordDialog.open({
              title: 'Add area',
              initialValues: {},
              onSubmit: createInspectionAreaSubmitHandler
            });
          }}
        >
          Add area
        </OutlineButton>
      </DragDropContext>
    );
  }
};

function EmptyState({ data }) {
  const { open } = useDialog(PropertyAreaRecordDialog);
  const createInspectionAreaSubmitHandler = useCreateInspectionAreaSubmitHandler(
    data.inspectionTask
  );

  return (
    <Card>
      <Message
        title='No areas for this property'
        Illustration={EmptyTable}
        actions={
          data
            ? [
                {
                  id: 'inspection-area',
                  group: 'inspection-area',
                  label: 'Add area',
                  handleAction: async () => {
                    return open({
                      title: 'Add new area',
                      onSubmit: createInspectionAreaSubmitHandler,
                      initialValues: {},
                      submitLabel: 'Add area'
                    });
                  }
                }
              ]
            : []
        }
      >
        Please add areas to report on the condition for this property.
      </Message>
    </Card>
  );
}

function AreaActionMenu(props) {
  const { propertyArea, taskId, areaOrder, updateAreaOrder } = props;

  const getUpdateInspectionAreaAction = useGetUpdateInspectionAreaAction();
  const getDeleteInspectionAreaAction = useGetDeleteInspectionAreaAction();

  return (
    <ActionMenu
      Button={GhostIconButton}
      Icon={KebabIcon}
      placement='bottom-end'
      items={[
        getUpdateInspectionAreaAction(propertyArea, taskId),
        getDeleteInspectionAreaAction(
          propertyArea,
          taskId,
          areaOrder,
          updateAreaOrder
        )
      ]}
    />
  );
}
