import * as React from 'react';
import { useStyles, StyleSheet } from '@rexlabs/styling';
import { Truncate } from '@rexlabs/text';
import { TwoTierCell } from 'view/components/cells/two-tier-cell';
import { RecordLink } from 'view/components/record-link/record-link';
import { FullRecordLink } from 'view/components/record-link/full-record-link';
import { getRecordObject } from 'src/modules/common/utils/Records/get-record-object';
import { Task } from 'src/modules/tasks/common/types/Task';
import { getRecordLabel } from 'utils/records/get-record-label';
import { TaskLink } from 'src/modules/tasks/common/types/TaskLink';

const defaultStyles = StyleSheet({
  link: {
    textDecoration: 'none'
  }
});

export function getLinkedTaskText(taskLinks?: TaskLink[]) {
  const linkedTask = taskLinks?.[0]?.child_task;

  if (!taskLinks || taskLinks.length === 0 || !linkedTask) {
    return '';
  }

  if (taskLinks.length > 1) {
    return `${taskLinks.length} linked tasks`;
  }

  const linkedTaskObject = getRecordObject(linkedTask, linkedTask.type.id);
  return getRecordLabel(linkedTaskObject);
}

export function LinkedTasksCell({
  value
}: {
  value: Task<'task_property_compliance'>;
}) {
  const s = useStyles(defaultStyles);
  const taskLinks = value?.task_links?.data;
  const linkedTask = taskLinks?.[0]?.child_task;
  if (!taskLinks || taskLinks.length === 0 || !linkedTask) {
    return <Truncate>--</Truncate>;
  }

  if (taskLinks.length > 1) {
    return (
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <RecordLink
          type={value.type?.id}
          id={value.id}
          tab={'linked-tasks'}
          {...s('link')}
        >
          {taskLinks.length} linked tasks
        </RecordLink>
      </div>
    );
  }

  const status =
    (linkedTask.details &&
      'status' in linkedTask.details &&
      linkedTask.details.status?.label) ||
    linkedTask.status?.label;

  const recordObject = getRecordObject(linkedTask, linkedTask.type?.id);

  return (
    <div
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <TwoTierCell
        text={<FullRecordLink recordObject={recordObject} {...s('link')} />}
        subtext={status}
      />
    </div>
  );
}
