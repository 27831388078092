import React, { useMemo } from 'react';
import { IconProps } from '@rexlabs/icons';
import { LinkButton } from '@rexlabs/button';
import { StyleSheet, text, useStyles } from '@rexlabs/styling';
import Box from '@rexlabs/box';
import SplitIcon from 'view/components/icons/split';
import { formatDate } from 'utils/dates/format';
import {
  BatchReceiptingCardLayout,
  Intent
} from './batch-receipting-card-layout';
import { IconValue } from './batch-receipting-card-icon-value';
import { Summary } from './batch-receipting-card-summary';

interface BankTransactionCardProps {
  intent: Intent;
  date: string;
  summary?: string;
  amount: number;
  bankReferenceId?: string;
  isChild?: boolean;
  RecordIcon: React.ComponentType<IconProps>;
  onSplitManualTransactionAction?: () => void;
}

const defaultStyles = StyleSheet({
  label: {
    ...text.styles({ fallback: 'normal.bold' })
  }
});

const emptyValue = '--';

export function BankTransactionCard({
  date,
  intent,
  summary,
  amount,
  bankReferenceId,
  isChild,
  RecordIcon,
  onSplitManualTransactionAction
}: BankTransactionCardProps) {
  const s = useStyles(defaultStyles);

  const BankTransactionLabel = useMemo(
    () => (
      <Box
        flexDirection='row'
        alignItems='center'
        justifyContent='space-between'
        height='100%'
      >
        <div {...s('label')}>{formatDate(date)}</div>

        {isChild && (
          <LinkButton
            IconLeft={SplitIcon}
            onClick={onSplitManualTransactionAction}
          />
        )}
      </Box>
    ),
    [date, s, isChild, onSplitManualTransactionAction]
  );

  const BankTransactionValue = useMemo(
    () => (
      <>
        <Summary summary={summary || emptyValue} amount={amount} />
        <IconValue Icon={RecordIcon} value={bankReferenceId || emptyValue} />
      </>
    ),
    [RecordIcon, amount, bankReferenceId, summary]
  );

  return (
    <BatchReceiptingCardLayout
      intent={intent}
      label={BankTransactionLabel}
      value={BankTransactionValue}
    />
  );
}
