import React, { useCallback, useMemo } from 'react';

import { InputProps } from '@rexlabs/form';
import {
  FreeformDialogProps,
  Select,
  useFreeformDialog
} from '@rexlabs/select';

import { sortByCategory } from 'utils/chart-of-accounts/sort-chart-of-account-accounts';

import {
  ChartOfAccountCategory,
  ChartOfAccountsAccount
} from 'src/modules/chart-of-accounts/types/chart-of-account-types';

import {
  CreateChartOfAccountsAccountDialog,
  CreateChartOfAccountsAccountDialogProps
} from 'src/modules/chart-of-accounts/dialogs/create-chart-of-accounts-account-dialog';
import { useChartOfAccountsAccounts } from 'src/modules/financials/hooks/use-chart-of-accounts-accounts';

function FreeformChartOfAccounts({
  selectItem,
  inputValue,
  onCreate: propOnCreate,
  ...props
}: any & FreeformDialogProps<ChartOfAccountsAccount>) {
  const onCreate = React.useCallback(
    (data) => {
      propOnCreate?.(data);
      selectItem(data);
    },
    [propOnCreate, selectItem]
  );
  const propInitialValues = props.initialValues ?? {};

  return (
    <CreateChartOfAccountsAccountDialog
      {...props}
      initialValues={{
        ...propInitialValues,
        name: inputValue
      }}
      onCreate={onCreate}
    />
  );
}

export interface ChartOfAccountsAccountSelectProps extends InputProps {
  parentsOnly?: boolean;
  filterOnChartOfAccountCategories?: ChartOfAccountCategory[];
  sortingOrder?: ChartOfAccountCategory[];
  disableFixture?: boolean;
  dialogProps?: Partial<CreateChartOfAccountsAccountDialogProps>;
}

function filterByCategory(
  accounts: ChartOfAccountsAccount[] = [],
  categoriesToFilterBy: ChartOfAccountCategory[]
) {
  if (categoriesToFilterBy.length === 0) return accounts;
  return [...(accounts ?? [])].filter((account) =>
    categoriesToFilterBy.includes(account?.account_category?.id)
  );
}

export function ChartOfAccountsAccountSelect({
  filterOnChartOfAccountCategories = [
    'income',
    'expense',
    'equity',
    'asset',
    'liability'
  ],
  sortingOrder = ['income', 'expense', 'equity', 'asset', 'liability'],
  disableFixture = true,
  dialogProps,
  ...props
}: ChartOfAccountsAccountSelectProps) {
  const items = useChartOfAccountsAccounts();

  const filteredAndSortedItems = useMemo(
    () =>
      sortByCategory(
        filterByCategory(items, filterOnChartOfAccountCategories),
        sortingOrder
      ),
    [filterOnChartOfAccountCategories, items, sortingOrder]
  );

  const normaliser = useCallback(
    (item) => ({
      id: item.id,
      label: item.name,
      type: item.account_category.id,
      heading: item.account_category.label
    }),
    []
  );

  const { getSelectProps } = useFreeformDialog({
    props: dialogProps,
    Dialog: FreeformChartOfAccounts
  });

  // The helper text is a string shown beneath the search input. The default behavior when using search changes the
  // the helper to 'Loading...' when performing the search, 'No results found' when an empty array is
  // returned, and 'Select results', when we have some data in the results. Because not relying on the default behavior
  // add we're manually passing in the items, we need to mimic that behavior
  const helperText =
    items === undefined
      ? 'Loading...'
      : items?.length === 0
      ? 'No results found'
      : 'Select result';

  return (
    <Select
      {...props}
      {...(disableFixture ? {} : getSelectProps())}
      helperText={helperText}
      searchable={true}
      items={filteredAndSortedItems}
      normaliser={normaliser}
    />
  );
}
