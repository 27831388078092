import { useModelActions } from '@rexlabs/model-generator';
import * as React from 'react';
import { ActionDeclaration } from 'src/modules/common/actions/types/action-declaration-types';
import { getRecordObject } from 'src/modules/common/utils/Records/get-record-object';
import { api } from 'utils/api/api-client';
import { getRecordLabel } from 'utils/records/get-record-label';
import { useToast } from 'view/components/@luna/notifications/toast';
import { tasksModel } from '../../models/tasks-model';
import { Task } from '../../types/Task';

export function useGetToggleClosedAction() {
  const { addToast } = useToast();
  const { refreshItem, refreshLists } = useModelActions(tasksModel);
  return (task: Task<any>): ActionDeclaration => {
    const label = task.closed_at ? 'Open' : 'Close';
    return {
      id: 'toggle-close-state',
      group: 'tasks',
      label,
      handleAction: async () => {
        const updatedTask = await api.patch(
          `/tasks/${task.id}/${task.closed_at ? 'open' : 'close'}`
        );

        refreshItem({ id: task.id });

        refreshLists();

        addToast({
          type: 'success',
          description: (
            <>
              Successfully {task.closed_at ? 'opened' : 'closed'}{' '}
              <b>{getRecordLabel(getRecordObject(task, tasksModel))}</b>
            </>
          )
        });

        return updatedTask;
      }
    };
  };
}
