import React, { useEffect } from 'react';

import Box from '@rexlabs/box';
import { Field } from '@rexlabs/form';
import { useToken } from '@rexlabs/styling';
import { TextArea } from '@rexlabs/text-input';
import { InspectionType } from 'src/modules/tasks/inspections/types/InspectionType';
import { Column, Grid } from 'view/components/@luna/form/grid';
import { ContactSelect } from 'view/components/inputs/selects/contact';
import { PropertySelect } from 'view/components/inputs/selects/v4-selects/property-select';
import { BlockConfig } from 'view/components/record-screen/types';
import { Value } from 'view/components/values';
import { useFeatureFlags } from 'view/components/@luna/feature-flags';
import { FLAGS } from 'utils/feature-flags';
import { InspectionTypeInput } from '../../common/components/inspection-type-input';
import { RelatedPropertyTenancySelect } from '../../common/components/related-property-tenancy-select';
import { onPropertyChangeHandler } from '../../common/utils/on-property-change-handler';
import { InspectionProgressSelect } from '../components/progress-select';
import { InspectionFormData } from '../types/InspectionFormData';
import { InspectionTask } from '../types/InspectionTask';

function getTenancyTypes(inspectionType: InspectionType) {
  switch (inspectionType) {
    case 'routine_inspection':
      return ['active'];
    case 'entry_inspection':
      return ['active', 'incoming'];
    case 'exit_inspection':
      return ['active', 'outgoing'];
  }
}

const validate = {
  definitions: {
    property: { rules: 'required' },
    property_tenancy: { rules: 'required', name: 'Tenancy' },
    status: { name: 'progress', rules: 'required' },
    managed_by: { name: 'managed by', rules: 'required' },
    inspection_type: { name: 'task type', rules: 'required' }
  }
};

export const inspectionDetailsBlock: BlockConfig<
  InspectionTask,
  any,
  InspectionFormData
> = {
  id: 'details',
  title: 'Details',
  validate,
  View: ({ data }) => {
    return (
      <Grid columns={2}>
        <Value label='Task type' value={data?.details?.type.label} />
        <Column width={2}>
          <Value label='Description' value={data?.description} />
        </Column>
        <Value label='Property' value={data?.property?.address_string} />
        <Value
          label='Tenancy'
          value={data?.details?.property_tenancy?.tenancy?.display_name}
        />
        <Value label='Managed by' value={data?.managed_by?.display_name} />
      </Grid>
    );
  },
  Edit: ({ values, data, editMode, setFieldValue, forms, getHandlers }) => {
    const { hasFeature } = useFeatureFlags();
    const isSmartChecklist = hasFeature(FLAGS.SMART_CHECKLISTS_SETTINGS);

    const handlers = getHandlers?.(forms);

    useEffect(() => {
      onPropertyChangeHandler({
        values,
        setFieldValue,
        taskType: 'task_inspection'
      });
    }, [values?.property]);

    const token = useToken();

    return (
      <Box sy={token('spacing.xxl')}>
        {editMode && isSmartChecklist ? (
          <Value label='Task type' value={data?.details?.type.label} />
        ) : (
          <InspectionTypeInput onBlur={handlers?.handleInspectionTypeBlur} />
        )}

        <Box sy={token('spacing.xxl')}>
          <Field
            id='description'
            name='description'
            label='Description'
            Input={TextArea}
          />
        </Box>
        <Grid columns={2}>
          <Field
            id='property'
            name='property'
            label='Property'
            Input={PropertySelect}
          />
          <Field
            id='property_tenancy'
            name='property_tenancy'
            label='Tenancy'
            Input={RelatedPropertyTenancySelect}
            inputProps={{
              propertyId: values?.property?.id,
              statusesToShow:
                values?.inspection_type &&
                getTenancyTypes(values?.inspection_type)
            }}
          />

          <Box sy={token('spacing.xxl')} pt={token('spacing.m')}>
            <Grid columns={2}>
              <Field
                id='managed_by'
                name='managed_by'
                label='Managed by'
                Input={ContactSelect}
                inputProps={{
                  // NOTE: we need to disable the fixture when editing the block, as it causes the app to
                  // become unresponsive. https://rexsoftware.atlassian.net/browse/ALFIE-2066
                  disableFixture: !!editMode
                }}
              />
              {!editMode && (
                <Field
                  id='status'
                  name='status'
                  label='Progress'
                  Input={InspectionProgressSelect}
                />
              )}
            </Grid>
          </Box>
        </Grid>
      </Box>
    );
  }
};
