import React from 'react';
import { DumbTable } from 'view/components/table/dumb-table';
import { Columns, TableProvider } from 'view/components/table/provider';
import { useUrlState } from '@rexlabs/table';

import { ActionMenuItem } from '@rexlabs/action-menu';
import { BlockConfig, CardProps } from '../types';
import { BlockLayout } from './block-layout';
import { EmptyCard } from './empty-card';

export type BasicStaticTableProps<Model> = {
  id: string;
  items: Model[];
  columns: Columns;
  getActionMenuItems?: (args: {
    item: any;
    entity?: any;
  }) => ActionMenuItem[] | undefined;
};

export interface StaticTableBlockArgs<Data, TableModel>
  extends Omit<BlockConfig<Data>, 'View' | 'Edit' | 'Card' | 'validate'> {
  Empty: (props: CardProps<Data>) => JSX.Element;
  getTableProps: (args: CardProps<Data>) => BasicStaticTableProps<TableModel>;
}

export function createStaticTableBlock<Data, TableModel>({
  Empty,
  getTableProps,
  Actions,
  ...args
}: StaticTableBlockArgs<Data, TableModel>): BlockConfig<any> {
  return {
    ...args,
    Card: ({ id, title, ...args }) => {
      const { items, ...tableProps } = getTableProps(args);
      const { getTableProps: getUrlStateProps } = useUrlState({
        pageSizes: [10, 20, 50, 100]
      });

      const tableUrlStateProps = getUrlStateProps();

      const tableIsEmpty = !items.length;

      return (
        <TableProvider
          {...tableUrlStateProps}
          {...tableProps}
          items={items}
          isLoading={false}
        >
          {tableIsEmpty ? (
            <EmptyCard>
              <Empty {...args} />
            </EmptyCard>
          ) : (
            <BlockLayout
              id={`block-${id}`}
              headline={title as string}
              actions={Actions ? <Actions {...args} /> : undefined}
            >
              <DumbTable withToolbar={false} />
            </BlockLayout>
          )}
        </TableProvider>
      );
    }
  };
}
