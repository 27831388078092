import React from 'react';

import { api } from 'utils/api/api-client';
import { Autocomplete, AutocompleteProps } from '@rexlabs/select-old';
import { normaliseProperty } from 'utils/normaliser/property';

interface PropertySelectProps extends AutocompleteProps {
  includes?: Array<string>;
  filters?: string;
}

export const PropertySelect = (props: PropertySelectProps) => {
  const { includes: propIncludes, filters: propFilters } = props;

  const queryParams = new URLSearchParams();

  if (Array.isArray(propIncludes)) {
    queryParams.set('include', propIncludes.join(','));
  }

  if (typeof propFilters === 'string') {
    queryParams.set('q', propFilters);
  }

  const query = queryParams.toString();

  const items = React.useCallback(
    async (term) => {
      const properties = await api.get(`/properties?${query}`, {
        search: term,
        per_page: 30
      });

      return properties.data;
    },
    [query]
  );

  return (
    <Autocomplete {...props} items={items} normaliser={normaliseProperty} />
  );
};
