import * as React from 'react';
import { api } from 'utils/api/api-client';
import { RecordDialog } from 'view/components/record-screen/dialog/dialog';
import { RecordSubmitHandler } from 'view/components/record-screen/utils';
import { useToast } from 'view/components/@luna/notifications/toast';
import { TrustLedger } from 'src/modules/trust-ledgers/types/trust-ledger-types';
import { BankAccount } from 'data/models/entities/financials/bank-accounts';
import { useDefaultBankAccountQuery } from '../hooks/use-default-bank-account-query';
import { ledgerActivityBlock } from '../blocks/ledger-activity-block';
import { downloadFile } from '../utils/download-file';

const content = [
  {
    id: 'create-ledger-activity',
    label: 'Detail',
    blocks: [ledgerActivityBlock]
  }
];

export interface CreateLedgerActivitySummaryProps {
  onClose?: (e?: React.MouseEvent | React.KeyboardEvent) => void;
  trustLedger?: TrustLedger | null;
  bankAccount?: BankAccount | null;
}

export function CreateLedgerActivitySummary({
  onClose,
  trustLedger,
  bankAccount: selectedBankAccount
}: CreateLedgerActivitySummaryProps) {
  const { addToast } = useToast();
  const [bankAccounts, defaultAccount] = useDefaultBankAccountQuery();

  const onSubmit: RecordSubmitHandler = React.useCallback(
    async ({ values }) => {
      const payload = {
        format: 'pdf',
        ...values
      };

      try {
        const response = await api.post(
          `/reporting/ledger-activity-summary`,
          payload,
          {
            responseType: 'blob'
          }
        );

        downloadFile(
          response,
          `ledger_activity_${values.start_date}_${values.end_date}`
        );

        addToast({
          description: (
            <>
              The <b>ledger activity summary report</b> has been generated and
              downloaded
            </>
          )
        });

        return true;
      } catch (error) {
        addToast({
          description: (
            <>
              There was some error in generating the report. Please try again
              later
            </>
          ),
          type: 'error'
        });
      }
    },
    [addToast]
  );

  const initialBankAccount = React.useMemo(() => {
    if (selectedBankAccount) {
      return selectedBankAccount;
    }

    if (trustLedger) {
      return trustLedger.bank_account;
    }

    return defaultAccount;
  }, [defaultAccount, selectedBankAccount, trustLedger]);

  const initialValues = React.useMemo(() => {
    return {
      exclude_ledgers_without_transactions: trustLedger ? false : true, // No point filtering out ledgers without transactions if we're only showing one ledger
      bank_account: initialBankAccount,
      ledger_ids: trustLedger ? [trustLedger.id] : undefined
    };
  }, [initialBankAccount, trustLedger]);

  return (
    <RecordDialog
      isLoading={bankAccounts.isLoading}
      title='Generate activity ledger report'
      initialValues={initialValues}
      content={content}
      onClose={onClose}
      submitLabel='Generate & download'
      handleSubmit={onSubmit}
    />
  );
}
