export const TODO_ROUTES = {
  TODO_LIST: {
    config: {
      path: '/todos',
      Component: require('./screens/todo-list.tsx').TodoList
    }
  },
  TODO_DETAILS: {
    config: {
      path: '/todo/:todoId',
      Component: require('./screens/todo-details.tsx').TodoDetails
    }
  }
};
