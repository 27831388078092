import { BatchReceiptingItem } from '../types';

export function getTrustJournalEntryPayload(
  item: BatchReceiptingItem,
  bank_statement_transaction_id?: string
) {
  return {
    receipt: {
      amount: item.amount,
      bank_account: item.bank_account,
      contacts: item.prioritised_match.contacts,
      date_of: item.paid_date,
      folio: item?.prioritised_match.folio,
      payment_information: {
        method: {
          id: 'eft'
        },
        reference: item.reference
      },
      line_items: [
        {
          amount: item.amount,
          rent: {
            property: {
              id:
                item.prioritised_match.folio?.active_property_tenancy
                  ?.property_id
            }
          }
        }
      ],
      send_receipt: false,
      bank_statement_transaction_id: bank_statement_transaction_id
        ? bank_statement_transaction_id
        : null
    }
  };
}
