import { ActionDeclaration } from 'src/modules/common/actions/types/action-declaration-types';
import { useDeleteAction } from 'src/modules/common/actions/delete/use-delete-action';
import { propertyAreaTypesModel } from 'src/modules/property-areas/property-area-types/models/property-area-types-model';
import React from 'react';
import { PropertyAreaType } from 'src/modules/property-areas/property-area-types/types/PropertyAreaType';

export function useGetDeletePropertyAreaTypeAction() {
  const deleteAction = useDeleteAction(propertyAreaTypesModel);

  return (propertyAreaType: PropertyAreaType): ActionDeclaration => {
    const deleteActionDefinition = deleteAction(propertyAreaType, {
      title: 'Remove area',
      message: (
        <div data-testid={'delete-area-type-dialog'}>
          <p>
            By performing this action, <strong>{propertyAreaType.name}</strong>{' '}
            will no longer be available for new properties.
          </p>
          <p>Do you want to continue?</p>
        </div>
      ),
      confirmText: 'Yes, remove',
      size: 's'
    });

    return {
      ...deleteActionDefinition,
      id: 'delete-property-area-type'
    };
  };
}
