import { Generator } from 'data/models/generator';
import _ from 'lodash';
import { auditableConfig } from 'src/modules/common/auditable/auditable-config';
import { api } from 'utils/api/api-client';
import { documentsModel } from 'data/models/entities/documents';
import { MessageBatch } from '../types/MessageBatch';
import { Message } from '../types/Message';
import { messagesModel } from './messages-model';

const config = {
  entities: {
    related: {
      // TODO: configure includes here
      ...auditableConfig,
      messages: {
        include: 'messages',
        model: [messagesModel]
      },
      documents: {
        include: 'documents',
        model: [documentsModel]
      }
    }
  }
};

const actionCreators = {
  markItemAsDownloaded: {
    request: ({ messageBatch }: { messageBatch: MessageBatch }) =>
      api.post(
        `/communication/message-batches/${messageBatch.id}/mark-as-downloaded/`
      ),
    reduce: _.identity
  },
  markItemAsNotDownloaded: {
    request: ({ messageBatch }: { messageBatch: MessageBatch }) =>
      api.post(
        `/communication/message-batches/${messageBatch.id}/mark-as-not-downloaded/`
      ),
    reduce: _.identity
  },
  sendBatch: {
    request: ({ messages }: { messages: Message[] }) =>
      api.post(`/communication/message-batches/send/`, {
        messages: messages.map((message) => ({ id: message.id }))
      }),
    reduce: _.identity
  }
};

export const messageBatchesModel = new Generator<
  MessageBatch,
  typeof actionCreators
>('communication/message-batches', config).createEntityModel({
  actionCreators
});
