import { DialogProps } from '@rexlabs/dialog';
import { useModelActions } from '@rexlabs/model-generator';
import * as React from 'react';
import { RecordDialog } from 'view/components/record-screen/dialog/dialog';
import { useRecordUpdatedToast } from 'src/modules/common/toasts/hooks/use-record-updated-toast';
import { PrepaymentBucket } from 'src/modules/prepayments/types/prepayment-bucket';
import { prepaymentChartOfAccountsBlock } from 'src/modules/prepayments/blocks/prepayment-chart-of-accounts-block';
import { mapUpdatePrepaymentBucketFormToApi } from 'src/modules/prepayments/mappers/map-update-prepayment-bucket-form-to-api';
import { RecordSubmitHandler } from 'view/components/record-screen/utils';
import { PrepaymentBucketForm } from 'src/modules/prepayments/types/prepayment-bucket-form';
import { mapPrepaymentBucketToForm } from 'src/modules/prepayments/mappers/map-prepayment-bucket-to-form';
import { prepaymentBucketsModel } from '../models/prepayment-buckets-model';
import { prepaymentDetailsBlock } from '../blocks/prepayment-details-block';

const content = [
  {
    id: 'edit-prepayments',
    label: 'Edit Prepayments',
    blocks: [prepaymentDetailsBlock, prepaymentChartOfAccountsBlock]
  }
];

interface UpdatePrepaymentBucketDialogProps extends DialogProps {
  prepaymentBucket: PrepaymentBucket;
}

export function UpdatePrepaymentBucketDialog({
  onClose,
  prepaymentBucket
}: UpdatePrepaymentBucketDialogProps) {
  const { updateItem, refreshLists } = useModelActions(prepaymentBucketsModel);
  const addToast = useRecordUpdatedToast(prepaymentBucketsModel, {
    actions: []
  });

  const handleSubmit: RecordSubmitHandler<PrepaymentBucketForm> = async ({
    changedValues
  }) => {
    const dataToSubmit = mapUpdatePrepaymentBucketFormToApi(changedValues);

    const { data: updatedPrepaymentBucket } = await updateItem({
      id: prepaymentBucket.id,
      data: dataToSubmit
    });

    addToast(updatedPrepaymentBucket);

    return refreshLists();
  };

  const initialValues = mapPrepaymentBucketToForm(prepaymentBucket);

  return (
    <RecordDialog
      title='Edit prepayment bucket'
      submitLabel={'Save'}
      data={prepaymentBucket}
      initialValues={initialValues}
      content={content}
      onClose={onClose}
      handleSubmit={handleSubmit}
      blockProps={{ isCreating: false }}
    />
  );
}
