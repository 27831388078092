import * as React from 'react';
import Icons from '@rexlabs/icons';
import ContactDepositAuthorityIcon from 'assets/icons/m/contact-deposit-authority.svg';
import ContactSupplierIcon from 'assets/icons/m/contact-supplier.svg';
import ContactBusinessIcon from 'assets/icons/m/contact-business.svg';
import RexLogo from 'assets/icons/m/rexpm.svg';
import { Contact } from 'src/modules/contacts/types/contact-types';
import { StyleSheet, useStyles } from '@rexlabs/styling';

const styles = StyleSheet({
  contactIcon: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 32,
    width: 32,
    borderRadius: '50%',
    backgroundColor: ({ token }) => token('palette.grey.200'),
    backgroundSize: '100%'
  }
});

export const ContactIconCell = ({ value }: { value: Contact }) => {
  const s = useStyles(styles);

  if (value.is_security_deposit_authority) {
    return (
      <div {...s('contactIcon')}>
        <ContactDepositAuthorityIcon />
      </div>
    );
  }

  if (value.is_supplier) {
    return (
      <div {...s('contactIcon')}>
        <ContactSupplierIcon />
      </div>
    );
  }

  if (value.is_business) {
    return (
      <div {...s('contactIcon')}>
        <ContactBusinessIcon />
      </div>
    );
  }

  const isAgency = value.is_agency;
  const hasLogo = value.logo?.inline_url != null;

  if (isAgency) {
    if (hasLogo) {
      return (
        <div
          {...s('contactIcon')}
          style={{
            backgroundImage: `url(${value.logo!.inline_url})`
          }}
        />
      );
    }

    return (
      <div {...s('contactIcon')}>
        <RexLogo />
      </div>
    );
  }

  return (
    <div {...s('contactIcon')}>
      <Icons.Person />
    </div>
  );
};
