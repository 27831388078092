import React from 'react';
import { ConfirmationDialog, DialogProps } from '@rexlabs/dialog';
import { Body } from '@rexlabs/text';
import InfoCircleIcon from 'view/components/icons/info';

export function InsufficientSmsCreditDialogSMSOnly({ onClose }: DialogProps) {
  return (
    <ConfirmationDialog
      title='Insufficient SMS credit'
      size='s'
      onClose={onClose}
      message={
        <>
          <Body>
            You do not have enough available credit to send these SMS messages.
            Please{' '}
            <a href='mailto:support@rexpm.zendesk.com'>contact support</a> to
            add credit to your account.
          </Body>
        </>
      }
      confirmText='OK, I understand'
      TitleIcon={InfoCircleIcon}
      onConfirm={() => onClose}
    />
  );
}
