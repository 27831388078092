import React from 'react';
import { Message } from '@rexlabs/notifications';
import EmptyTable from 'assets/illustrations/empty-table.svg';
import { EmptyCard } from 'view/components/record-screen/cards/empty-card';
import { useModelActions } from '@rexlabs/model-generator';
import { MaintenanceTask } from '../../maintenance/types/MaintenanceTask';
import { useGetCreateWorkOrderAction } from '../hooks/action-declarations/use-get-create-work-order-action';
import { WorkOrderTask } from '../types/WorkOrderTask';
import { tasksModel } from '../../common/models/tasks-model';

export function WorkOrderListEmptyState({
  data: maintenanceTask
}: {
  data: MaintenanceTask;
}) {
  // refresh the parent to show changes to its status
  const { refreshItem } = useModelActions(tasksModel);
  const getCreateWorkOrderAction = useGetCreateWorkOrderAction({
    onCreate: (data: WorkOrderTask) => {
      data.parent_task && refreshItem({ id: data.parent_task.id });
    }
  });
  const { handleAction, label } = getCreateWorkOrderAction(maintenanceTask);

  return (
    <EmptyCard>
      <Message
        title='No work orders'
        Illustration={EmptyTable}
        actions={[
          {
            label: label,
            type: 'primary',
            handleAction
          }
        ]}
      >
        Issue work orders for your maintenance job
      </Message>
    </EmptyCard>
  );
}
