import React from 'react';

import { ControlledTabs } from '@rexlabs/tabs';

import { useTabUrlState } from 'view/hooks/use-tab-url-state';

import { AllActiveTab } from './all-active-tab';
import { MatchedTab } from './matched-tab';
import { NotMatchedTab } from './not-matched-tab';
import { RemovedTab } from './removed-tab';
import { SuspendedTab } from './suspended-tab';

const useBatchReceiptingTabs = () => {
  return [
    {
      name: 'all_active',
      label: 'Unreceipted',
      Tab: AllActiveTab
    },
    {
      name: 'not_match',
      label: 'Not matched',
      Tab: NotMatchedTab
    },
    {
      name: 'matched',
      label: 'Matched',
      Tab: MatchedTab
    },
    {
      name: 'suspended',
      label: 'Suspended',
      Tab: SuspendedTab
    },
    {
      name: 'removed',
      label: 'Removed',
      Tab: RemovedTab
    }
  ];
};

export function BatchReceiptingListCardView() {
  const tabs = useBatchReceiptingTabs();

  const { activeTab, setTab } = useTabUrlState({
    tabs
  });

  return (
    <ControlledTabs
      keepRenderedTabs={false}
      allowActiveTabToScrollIntoView={false}
      activeTab={activeTab}
      onChange={setTab}
      items={tabs}
    />
  );
}
