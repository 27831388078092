import React, { ComponentType } from 'react';
import { ButtonGroupProps } from 'view/components/record-screen/dialog/types';
import { RecordSubmitHandler } from 'view/components/record-screen/utils';
import { GhostButton } from '@rexlabs/button';
import { StandardDialogFooter } from 'view/components/dialogs/components/standard-dialog-footer';
import { GoToOutboxSplitButton } from './go-to-outbox-split-button';

interface SplitSubmitButtonProps extends ButtonGroupProps {
  onClose?: () => void;
  submitLabel?: React.ReactNode;
  SubmitButton?: ComponentType<any>;
  handleSubmit: RecordSubmitHandler;
  isSubmitting?: boolean;
  submitButtonType?: 'destructive';
}

export function SplitSubmitButton({
  onClose,
  handleSubmit,
  isSubmitting,
  submitLabel,
  SubmitButton,
  submitButtonType,
  ...props
}: SplitSubmitButtonProps) {
  return (
    <StandardDialogFooter>
      <GhostButton onClick={onClose}>Cancel</GhostButton>
      <GoToOutboxSplitButton
        onClose={onClose}
        handleSubmit={handleSubmit}
        isSubmitting={isSubmitting}
        SubmitButton={SubmitButton}
        buttonLabel={submitLabel}
        submitButtonType={submitButtonType}
        {...props}
      />
    </StandardDialogFooter>
  );
}
