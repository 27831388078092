import { RelatedRecordsProps } from 'view/components/related-records/related-records';

import { RecordData } from 'utils/types';
import { Property } from 'src/modules/properties/types/property-types';
import { Tenancy } from 'data/models/entities/tenancies';
import _ from 'lodash';
import { Contact } from 'src/modules/contacts/types/contact-types';
import { Ownership } from 'data/models/entities/ownerships';
import { useFeatureFlags } from 'view/components/@luna/feature-flags';
import { FLAGS } from './feature-flags';

export function useGetRelatedRecordsSections() {
  const { hasFeature } = useFeatureFlags();

  const hasGuarantorAndOccupierUpdates = hasFeature(
    FLAGS.GUARANTOR_AND_OCCUPIER_UPDATES
  );

  return (data: RecordData): RelatedRecordsProps['sections'] | undefined => {
    if (!('__record_type' in data)) {
      return undefined;
    }

    switch (data.__record_type) {
      case 'property':
        return [
          {
            type: 'property-ownership',
            activePropertyOwnership: data.active_property_ownership ?? 'empty',
            incomingPropertyOwnership:
              data.incoming_property_ownership ?? 'empty'
          },
          {
            type: 'property-tenancy',
            activePropertyTenancy: data.active_property_tenancy ?? 'empty',
            incomingPropertyTenancy: data.incoming_property_tenancy ?? 'empty'
          },
          {
            type: 'portfolio',
            portfolioManagers: data.portfolio?.users?.data ?? 'empty'
          }
        ];
      case 'contact':
        return [
          { type: 'ownerships', ownerships: data.ownerships?.data ?? 'empty' },
          { type: 'tenancies', tenancies: data.tenancies?.data ?? 'empty' }
        ];
      case 'property_ownership':
        return [
          {
            type: 'properties',
            properties: data.property ? [data.property] : 'empty'
          },
          {
            type: 'ownership',
            ownership: data.ownership ?? 'empty'
          }
        ];
      case 'ownership':
        return [
          {
            type: 'property-ownerships',
            propertyOwnerships: data.property_ownerships?.data ?? 'empty'
          },
          { type: 'owners', owners: data.owners ?? 'empty' }
        ];
      case 'property_tenancy':
        return [
          {
            type: 'properties',
            properties: data.property ? [data.property] : 'empty'
          },
          {
            type: 'tenancy',
            tenancy: data.tenancy ?? 'empty'
          }
        ];
      case 'tenancy':
        return [
          {
            type: 'property-tenancies',
            propertyTenancies: data.property_tenancies?.data ?? 'empty'
          },
          {
            type: 'tenants',
            tenancyRelatedContacts:
              (hasGuarantorAndOccupierUpdates
                ? data.related_contacts?.data
                : data.tenants) ?? 'empty'
          }
        ];
      case 'security_deposit': {
        const sections: RelatedRecordsProps['sections'] = [];

        if (data.security_deposit_type?.held_by_contact) {
          sections.push({
            type: 'deposit-held-by',
            held_by_contact: data.security_deposit_type.held_by_contact
          });
        }

        if (data.held_by_ownership) {
          sections.push({
            type: 'deposit-held-by',
            held_by_ownership: data.held_by_ownership
          });
        }

        if (data.insured_by_contact) {
          sections.push({
            type: 'deposit-insured-by',
            insured_by_contact: data.insured_by_contact
          });
        }

        return [
          ...sections,
          {
            type: 'properties',
            properties: data.property_tenancy
              ? [data.property_tenancy.property]
              : 'empty'
          },
          {
            type: 'property-tenancy',
            activePropertyTenancy: data.property_tenancy ?? 'empty',
            incomingPropertyTenancy: 'empty'
          }
        ];
      }
      case 'invoice': {
        const relatedProperties = data.line_items
          ?.flatMap((lineItem) => [
            lineItem.payable_by_property,
            lineItem.payable_to_property
          ])
          .filter(Boolean) as Property[];

        const uniqueProperties = relatedProperties?.filter(
          (property, index, array) =>
            array.findIndex(
              (propertyToMatch) => property.id === propertyToMatch.id
            ) === index
        );

        const task = data.task;

        return [
          {
            type: 'invoice',
            invoice: data
          },
          {
            type: 'properties',
            properties: uniqueProperties
          },
          {
            type: 'task',
            task: task ?? 'empty'
          }
        ];
      }
      case 'task': {
        const sections: RelatedRecordsProps['sections'] = [];

        const property =
          data?.details?.property_tenancy?.property ??
          data?.property ??
          'empty';

        const relatesTo = data?.details?.relates_to;
        const relatesToType = data?.details?.relates_to_type;
        const taskTypeId = data.type.id;

        // If the task has a tenancy in details, then it's always required
        // otherwise, just show the active tenancy
        const tenancy =
          (data?.details?.tenancy as Tenancy) ||
          data?.details?.property_tenancy?.tenancy ||
          data?.property?.active_property_tenancy?.tenancy;

        const ownership =
          data?.property?.active_property_ownership?.ownership ??
          data?.details?.property_tenancy?.property?.active_property_ownership
            ?.ownership;

        const linkedTasks = (data.task_links?.data || []).map(
          (task) => task.child_task
        );

        switch (taskTypeId) {
          case 'task_supplier_compliance':
            return [
              {
                type: 'supplier',
                contact:
                  data?.details?.supplier ??
                  data?.details?.compliance_entry?.supplier ??
                  'empty'
              },
              {
                type: 'managed-by',
                contact: data.managed_by ?? 'empty'
              }
            ];
          case 'task_maintenance':
            sections.push({
              type: 'job-details',
              task: data
            });
            break;
          case 'task_property_compliance': {
            const ownership = data?.details?.ownership;

            if (ownership != null) {
              sections.push({
                type: 'ownership',
                ownership
              });
            }
            break;
          }
          case 'task_work_order': {
            const workDoneBy = data?.details[data?.details?.work_done_by.id];

            if (workDoneBy != null) {
              sections.push({
                type: 'work-assigned-to',
                contact: workDoneBy
              });
            }
          }
        }

        if (tenancy != null) {
          sections.push({
            type: 'tenancy',
            tenancy
          });
        }

        if (ownership != null && taskTypeId !== 'task_property_compliance') {
          sections.push({
            type: 'ownership',
            ownership
          });
        }

        switch (relatesToType) {
          case 'ownership':
            sections.push({
              type: 'ownership',
              ownership: relatesTo ?? 'empty'
            });
            break;
          case 'tenancy':
            sections.push({
              type: 'tenancy',
              tenancy: relatesTo ?? 'empty'
            });
            break;
          case 'contact':
            sections.push({
              type: 'contact',
              contact: relatesTo ?? 'empty'
            });
            break;
        }

        if (taskTypeId !== 'task_maintenance') {
          sections.push({
            type: 'managed-by',
            contact: data.managed_by ?? 'empty'
          });
        }

        if (linkedTasks.length) {
          sections.push({
            type: 'linked-tasks',
            linkedTasks: linkedTasks
          });
        }

        return [
          {
            type: 'property',
            property
          },
          ...sections
        ];
      }
      case 'accounting_journal_entry': {
        const sections: RelatedRecordsProps['sections'] = [];

        // Properties
        const properties = data?.line_items?.data
          .filter((line_item) => !!line_item.property)
          .map((line_item) => line_item.property) as Property[];

        const uniqueProperties = _.uniqBy(properties, 'id');

        uniqueProperties.length &&
          sections.push({
            type: 'properties',
            properties: uniqueProperties
          });

        // Folio (ownership or agency contact)
        const folio = data?.object;
        const folioRecordType = data?.object?.__record_type;

        if (folioRecordType === 'contact') {
          sections.push({
            type: 'contact',
            contact: folio as Contact
          });
        }
        if (folioRecordType === 'ownership') {
          sections.push({
            type: 'ownership',
            ownership: folio as Ownership
          });
        }

        // Source - if system created (i.e. not manual)
        if (data?.type.id !== 'manual') {
          sections.push({
            type: 'accounting-journal-entry-source',
            accountingJournalEntrySource: data.source_object,
            accountingJournalEntrySourceObjectType: data.source_object_type
          });
        }

        return sections;
      }
      case 'user': {
        const portfolios = data.portfolios?.data;

        return [
          {
            type: 'portfolios',
            portfolios:
              portfolios && portfolios.length > 0 ? portfolios : 'empty'
          }
        ];
      }
      case 'pending_ownership_disbursement': {
        return [
          {
            type: 'ownership',
            ownership: (data.object as Ownership) ?? 'empty'
          }
        ];
      }
      case 'pending_contact_disbursement': {
        return [
          {
            type: 'contact',
            contact: (data.object as Contact) ?? 'empty'
          }
        ];
      }
      case 'disbursement': {
        const type =
          data.contact?.__record_type ||
          data.ownership?.__record_type ||
          'ownership';

        return [
          ...(type === 'contact' ? [{ type, contact: data.contact! }] : []),
          ...(type === 'ownership'
            ? [{ type, ownership: data.ownership! }]
            : [])
        ];
      }
    }
  };
}
