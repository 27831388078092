import { useModelActions } from '@rexlabs/model-generator';
import { api } from 'utils/api/api-client';
import { RecordSubmitHandler } from 'view/components/record-screen/utils';
import { ChecklistTemplatesForm } from '../../../types/ChecklistTemplatesForm';
import {
  checklistTemplatesModel,
  GROUP_INCLUDES,
  ITEM_INCLUDES
} from '../../../models/checklist-templates-model';
import { transformChecklistFormDataToPipelineRequest } from '../../../utils/transform-checklist-form-data-to-pipeline-request';

export function useUpdateChecklistTemplateAction(
  {
    shouldRefreshChecklistTemplate
  }: {
    shouldRefreshChecklistTemplate?: boolean;
  } = { shouldRefreshChecklistTemplate: false }
) {
  const { refreshLists, refreshItem } = useModelActions(
    checklistTemplatesModel
  );

  return (checklistTemplateId: string, isSmartChecklist?: boolean) => {
    const onSubmit: RecordSubmitHandler<ChecklistTemplatesForm> = async ({
      changedValues
    }) => {
      const requests = transformChecklistFormDataToPipelineRequest({
        checklistTemplateId,
        values: changedValues,
        isSmartChecklist: isSmartChecklist || false
      });

      await api.post('request-pipelines', {
        requests
      });

      await refreshLists();

      shouldRefreshChecklistTemplate &&
        (await refreshItem({
          id: checklistTemplateId,
          args: {
            include: `${ITEM_INCLUDES},${GROUP_INCLUDES}`
          }
        }));

      return true;
    };

    return onSubmit;
  };
}
