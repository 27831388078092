import { Columns, ListTable, TabbedTable } from 'view/components/table';
import React, { useMemo } from 'react';
import { query, useModelActions } from '@rexlabs/model-generator';
import {
  bankDepositModel,
  bankDepositStatusMap
} from 'src/modules/banking/bank-deposits/models/bank-deposit-model';
import { getRecordLinkProps } from 'view/components/record-link/get-record-link-props';
import { useTableFilters } from 'view/hooks/use-table-filters';
import { TagCell } from '@rexlabs/table';
import { StatusTag } from 'src/modules/common/components/status-tag';
import { BankDeposit } from 'src/modules/banking/bank-deposits/types/bank-deposit-types';
import { useBankDepositActions } from 'src/modules/banking/bank-deposits/actions/use-bank-deposit-actions';
import { transformActionDeclarationsToActionMenuItems } from 'utils/actions/transforms';
import { trustJournalEntryColumns } from 'src/modules/trust-ledgers/components/trust-journal-entries-table';
import ROUTES from 'routes/app';
import { push } from '@rexlabs/whereabouts';
import { trustJournalEntryModel } from 'src/modules/trust-journal-entries/models/trust-journal-entries';
import { useDialog } from '@rexlabs/dialog';
import { RecordType, RecordTypes } from 'data/models/types';
import { CreateBankTransactionDialog } from 'src/modules/banking/common/dialogs/create-bank-transaction';
import { Tab } from 'view/components/table/tabbed';

const getTrustJournalEntriesQuery = () => query`{
  ${trustJournalEntryModel} {
    id
    payment_information
    bank_account
    contact
    created_by
    updated_by
  }
}`;

const getBankDepositsQuery = () => query`{
  ${bankDepositModel} {
    id
    totals
    bank_account
  }
}`;

export const bankDepositsColumns: Columns<BankDeposit> = [
  {
    width: 160,
    Header: 'Reference',
    accessor: (item) => item.record_reference
  },
  {
    type: 'currency',
    cellProps: {
      align: 'right'
    },
    Header: 'Amount',
    accessor: (item) => item.totals?.amount
  },
  {
    Header: 'Count',
    accessor: (item) => item.totals?.count
  },
  {
    Header: 'Deposited into',
    accessor: (item) => item.bank_account?.name
  },
  {
    type: 'date',
    Header: 'Date of deposit',
    accessor: (item) => item.date_of
  },
  {
    accessor: (item) => item.status,
    Header: 'Status',
    Cell: (table) => {
      const {
        cell: { value }
      } = table;
      return (
        <TagCell>
          <StatusTag status={value} map={bankDepositStatusMap} />
        </TagCell>
      );
    }
  },
  {
    type: 'value',
    Header: 'Type',
    accessor: (item) => item.type
  },
  {
    type: 'date',
    Header: 'Created at',
    accessor: (item) => item.created_at
  },
  {
    type: 'date',
    Header: 'Updated at',
    accessor: (item) => item.updated_at
  }
];

export interface BankDepositsTableProps {
  bankAccountId: string;
}

export function BankDepositsTable({ bankAccountId }: BankDepositsTableProps) {
  const bankDepositModelActions = useModelActions(bankDepositModel);
  const { getSort, getFilters } = useTableFilters('bank-deposits');
  const actions = useBankDepositActions();

  const { open: openCreateBankDeposit } = useDialog(
    CreateBankTransactionDialog
  );

  const tabs = useMemo<Tab[]>(() => {
    const bankAccountFilter = {
      field: 'bank_account_id',
      op: 'eq',
      value: bankAccountId
    };

    const pendingDepositsFilter = {
      field: 'pending_bank_deposit',
      op: 'eq',
      value: true
    };

    const getPendingBankDepositsFilter = (methods: string[]) => [
      bankAccountFilter,
      pendingDepositsFilter,
      {
        field: 'payment_method_id',
        op: 'in',
        value: methods
      }
    ];

    const commonProps = {
      id: 'pending-bank-deposits',
      Table: ListTable,
      getSort,
      getFilters,
      initialSortBy: [{ id: 'created_at', label: 'Created at', desc: true }]
    };

    const pendingBankDepositsBulkActions = ({
      typeId,
      recordType
    }: {
      typeId: string;
      recordType: RecordType;
    }) => ({ selectedItems }) => [
      {
        label: 'Create bank deposit',
        type: 'primary',
        handleAction: async () => {
          const createBankDepositAndGoto = async (dateOf) => {
            const bankDeposit = await bankDepositModelActions.createItem({
              data: {
                type: {
                  id: typeId
                },
                date_of: dateOf,
                trust_journal_entries: selectedItems,
                bank_account: {
                  id: bankAccountId
                }
              }
            });

            push(ROUTES.BANK_DEPOSIT_DETAILS, {
              params: {
                bankDepositId: bankDeposit.data.id
              }
            });
          };

          openCreateBankDeposit({
            recordType,
            trustJournalEntries: selectedItems,
            onCreate: async (dateOf) => {
              return createBankDepositAndGoto(dateOf);
            }
          });
        }
      }
    ];

    const pendingBankDepositsTabCommon = {
      ...commonProps,
      columns: trustJournalEntryColumns,
      getQuery: getTrustJournalEntriesQuery
    };

    return [
      {
        ...pendingBankDepositsTabCommon,
        forcedGlobalFilter: getPendingBankDepositsFilter(['cash', 'cheque']),
        getBulkActions: pendingBankDepositsBulkActions({
          typeId: 'cash_cheque',
          recordType: RecordTypes.BankDeposit
        }),
        name: 'cash-and-cheques',
        label: 'Cash/Cheques'
      },
      {
        ...pendingBankDepositsTabCommon,
        forcedGlobalFilter: getPendingBankDepositsFilter(['card']),
        getBulkActions: pendingBankDepositsBulkActions({
          typeId: 'card',
          recordType: RecordTypes.BankDeposit
        }),
        name: 'cards',
        label: 'Cards'
      },
      {
        ...commonProps,
        getQuery: getBankDepositsQuery,
        columns: bankDepositsColumns,
        getRowLinkProps: ({ item }) =>
          getRecordLinkProps({ type: 'bank_deposit', id: item.id }),
        name: 'previous-deposits',
        label: 'Previous deposits',
        getActionMenuItems: ({ item }) =>
          transformActionDeclarationsToActionMenuItems(actions(item)),
        forcedGlobalFilter: [bankAccountFilter]
      }
    ];
  }, [bankAccountId]);

  return <TabbedTable tabs={tabs} />;
}
