import { toLower } from 'lodash';
import { pluralize } from 'utils/formatters';
import { ChecklistTemplatesItemForm } from '../types/ChecklistTemplatesForm';
import { ChecklistTemplateItem } from '../types/ChecklistTemplate';

export function getChecklistTemplateItemSubtext(
  checklistTemplateItem: ChecklistTemplateItem | ChecklistTemplatesItemForm
): string | undefined {
  const dueDateType = checklistTemplateItem.due_date_type;
  const dueDateData = checklistTemplateItem.due_date_data;
  if (!dueDateType || !dueDateData) {
    return undefined;
  }

  const {
    offset_amount,
    offset_direction,
    offset_unit,
    related_date
  } = dueDateData;

  switch (dueDateType.id) {
    case 'none':
      return undefined;
    case 'related_date':
      return related_date ? `On ${toLower(related_date.label)}` : '';
    case 'offset_related_date':
      return offset_amount && offset_unit && related_date && offset_direction
        ? `${toLower(
            `${pluralize(offset_unit.label, offset_amount, true)} ${
              offset_direction.label
            } ${related_date.label}`
          )}`
        : '';
  }
}
