import { ContentConfig } from 'view/components/record-screen/types';
import { FLAGS } from 'utils/feature-flags';
import { useTranslation } from 'react-i18next';
import { trustBlock as auditReportsBlock } from '../blocks/trust-block';
import { financialReportsBlock } from '../blocks/static-reports/financial-reports-block';
import { tenancyReportsBlock } from '../blocks/static-reports/tenancy-reports-block';
import { ownershipReportsBlock } from '../blocks/static-reports/ownership-reports-block';
import { propertyReportsBlock } from '../blocks/static-reports/property-reports-block';
import { taskReportsBlock } from '../blocks/static-reports/task-reports-block';
import { otherReportsBlock } from '../blocks/static-reports/other-reports-block';

export const useContent = (): ContentConfig => {
  const { t } = useTranslation();
  return [
    {
      label: 'Report types',
      items: [
        {
          id: 'audit-reports',
          label: t('reporting.trust-reports.label.plural'),
          blocks: [auditReportsBlock]
        },
        {
          id: 'table-reports',
          label: 'Table reports',
          flag: FLAGS.TABLE_REPORTS,
          blocks: [
            financialReportsBlock,
            tenancyReportsBlock,
            ownershipReportsBlock,
            propertyReportsBlock,
            taskReportsBlock,
            otherReportsBlock
          ]
        }
      ]
    }
  ];
};
