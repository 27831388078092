import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import invariant from 'invariant';

import { useSettings } from 'src/modules/settings/hooks/useSettings';

dayjs.extend(customParseFormat);

/**
 * Use this hook to return a getter, which accepts a year or date within a financial year and returns the start and end date of that financial year.
 * If a year is passed in, it will return the start date within that year.
 * @returns
 */
export function useGetFinancialYearStartAndEnd() {
  const settings = useSettings();
  return (incomingDateOrYearStart: string = dayjs().format('YYYY-MM-DD')) => {
    invariant(
      dayjs(incomingDateOrYearStart, 'YYYY', true).isValid() ||
        dayjs(incomingDateOrYearStart, 'YYYY-MM-DD', true).isValid(),
      `"${incomingDateOrYearStart}" is not a valid date. Your date should be in either YYYY or YYYY-MM-DD format`
    );
    const [month, day] = settings['financial-year-start'].split('-');

    const date: string = dayjs(incomingDateOrYearStart, 'YYYY', true).isValid()
      ? `${incomingDateOrYearStart}-${month}-${day}`
      : incomingDateOrYearStart;

    // first we want to get the start of the financial year from the given year.
    const startOfFinancialYearFromGivenYear = dayjs(date, 'YYYY')
      .set('month', parseInt(month) - 1)
      .set('date', parseInt(day));

    // Then we want to check the date we've passed in, and make sure it's after the start of the financial year.
    // If it is, we want to subtract 1 year to get the correct financial year.
    const startOfFinancialYearFromGivenDate = startOfFinancialYearFromGivenYear.isAfter(
      dayjs(date)
    )
      ? startOfFinancialYearFromGivenYear.subtract(1, 'year')
      : startOfFinancialYearFromGivenYear;

    return {
      startDate: startOfFinancialYearFromGivenDate.format('YYYY-MM-DD'),
      endDate: startOfFinancialYearFromGivenDate
        .add(1, 'year')
        .subtract(1, 'day')
        .format('YYYY-MM-DD')
    };
  };
}
