import { RecordType } from 'data/models/types';
import { getViewAction } from 'utils/actions/get-view-action';

export function useGetPrimaryRecordActionGroup() {
  return (
    recordType: RecordType,
    id: string,
    label = 'View',
    parentId?: string
  ) => {
    return [
      getViewAction(
        'view',
        'primary-record',
        { id },
        recordType,
        label,
        parentId
      )
    ];
  };
}
