import React from 'react';
import { Field } from '@rexlabs/form';
import { TextInput } from '@rexlabs/text-input';
import { useToken } from '@rexlabs/styling';
import Box from '@rexlabs/box';

import { FLAGS } from 'utils/feature-flags';

import { Value } from 'view/components/values';
import { BlockConfig } from 'view/components/record-screen/types';
import { Grid } from 'view/components/@luna/form/grid';
import { useFeatureFlags } from 'view/components/@luna/feature-flags';
import { SupplierComplianceRequirementsSelect } from 'view/components/inputs/selects/v4-selects/supplier-compliance-requirements-select';

const validate = {
  definitions: {
    label: { name: 'name', rules: 'required' }
  }
};

export type ComplianceCategoryFormValues = {
  label: string;
  description?: string;
  requirements?: { label: string; id: string }[];
};

export const supplierComplianceCategoryDetailsBlock: BlockConfig<
  any,
  any,
  ComplianceCategoryFormValues
> = {
  id: 'complianceCategoryDetails',
  title: 'Category details',
  validate,
  View: ({ values }) => {
    const { hasFeature } = useFeatureFlags();

    return (
      <Grid columns={1}>
        <Value testId='label' label='Name' value={values?.label} />
        <Value
          testId='description'
          label='Description'
          value={values?.description}
        />
        {/* TODO: Remove flag once feature is released */}
        {hasFeature(FLAGS.SUPPLIER_COMPLIANCE_SETTINGS) && (
          <Value
            testId='requirements'
            label='Requirements'
            value={values?.requirements?.map((r) => r.label).join(', ')}
          />
        )}
      </Grid>
    );
  },
  Edit: () => {
    const token = useToken();
    const { hasFeature } = useFeatureFlags();

    return (
      <Box flexDirection='column' rowGap={token('spacing.xl')}>
        <Grid columns={2}>
          <Field name='label' label='Name' Input={TextInput} optional={false} />
        </Grid>
        <Grid columns={1}>
          <Field name='description' label='Description' Input={TextInput} />
          {/* TODO: Remove flag once feature is released */}
          {hasFeature(FLAGS.SUPPLIER_COMPLIANCE_SETTINGS) && (
            <Field
              id='requirements'
              name='requirements'
              label='Requirements'
              Input={SupplierComplianceRequirementsSelect}
            />
          )}
        </Grid>
      </Box>
    );
  }
};
