import { SingleActionDeclaration } from 'src/modules/common/actions/types/action-declaration-types';

import { useModelActions } from '@rexlabs/model-generator';
import { useGetCreateWorkOrderAction } from '../../work-orders/hooks/action-declarations/use-get-create-work-order-action';

import { QuoteTask } from '../types/QuoteTask';
import { isQuotePriceLessThanSpendLimit } from '../utils/is-quote-price-less-than-spend-limit';
import { WorkOrderTask } from '../../work-orders/types/WorkOrderTask';
import { tasksModel } from '../../common/models/tasks-model';
import { useGetAddReceivedQuoteAction } from './action-declarations/use-get-add-received-quote-action';
import { useGetApproveQuoteAction } from './action-declarations/use-get-approve-quote-action';
import { useGetCancelQuoteRequestAction } from './action-declarations/use-get-cancel-quote-request-action';
import { useGetRejectQuoteAction } from './action-declarations/use-get-reject-quote-action';
import { useGetSendForApprovalAction } from './action-declarations/use-get-send-for-approval-action';

export function useGetActionsFromStatus() {
  // Add a handler to refresh the parent task. This will ensure it shows the latest status
  // if the backend listener changes the parent's status in response to a child status change
  const { refreshItem } = useModelActions(tasksModel);
  const onCreate = (data: WorkOrderTask | QuoteTask) => {
    data.parent_task && refreshItem({ id: data.parent_task.id });
  };

  const getReceivedQuoteAction = useGetAddReceivedQuoteAction({
    onCreate
  });
  const getCreateWorkOrderAction = useGetCreateWorkOrderAction({
    onCreate
  });
  const getSendForApprovalAction = useGetSendForApprovalAction();
  const getCancelQuoteRequestAction = useGetCancelQuoteRequestAction();
  const getRejectQuoteAction = useGetRejectQuoteAction();
  const getApproveQuoteAction = useGetApproveQuoteAction({
    afterAction: onCreate
  });

  return (quoteTask: QuoteTask) => {
    const statusId = quoteTask?.details?.status?.id;

    const sendForApprovalAction = getSendForApprovalAction(quoteTask);
    const createWorkOrderAction = getCreateWorkOrderAction(quoteTask);
    const receivedQuoteAction = getReceivedQuoteAction(quoteTask);
    const cancelQuoteRequestAction = getCancelQuoteRequestAction(quoteTask);
    const rejectQuoteAction = getRejectQuoteAction(quoteTask);
    const approveQuoteAction = getApproveQuoteAction(quoteTask);

    const isTaskClosed = quoteTask.closed_at;
    // the primary action, to be displayed outside
    // of the actions dropdown if displayed in a title block
    const primaryActions: SingleActionDeclaration[] = [];
    // the secondary actions, to be displayed in
    // the actions dropdown if displayed in a title block
    const secondaryActions: SingleActionDeclaration[] = [];

    switch (statusId) {
      case 'approved':
        primaryActions.push(createWorkOrderAction);
        break;
      case 'received':
        !isTaskClosed && secondaryActions.push(rejectQuoteAction);
        if (isQuotePriceLessThanSpendLimit(quoteTask)) {
          !isTaskClosed && primaryActions.push(approveQuoteAction);
          !isTaskClosed && secondaryActions.push(sendForApprovalAction);
        } else {
          !isTaskClosed && primaryActions.push(sendForApprovalAction);
        }
        break;
      case 'requested':
        !isTaskClosed && primaryActions.push(receivedQuoteAction);
        !isTaskClosed && secondaryActions.push(cancelQuoteRequestAction);
        break;
      case 'sent_for_approval':
        !isTaskClosed && primaryActions.push(approveQuoteAction);
        !isTaskClosed && secondaryActions.push(rejectQuoteAction);
    }

    return { primaryActions, secondaryActions };
  };
}
