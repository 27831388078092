import { useQuery } from 'react-query';

import { ContactPaymentMethodMethod } from 'data/models/entities/contacts/payment-methods';
import { ValueListValue } from 'data/models/types';

import { api } from 'utils/api/api-client';

export async function fetchTrustWithdrawalPaymentMethods() {
  return api
    .get('value-lists/trust_withdrawal_payment_method')
    .then((res) => res.data.items);
}
export function useTrustWithdrawalPaymentMethods(
  excludeIds: ContactPaymentMethodMethod[] = []
): ValueListValue<ContactPaymentMethodMethod>[] {
  const { data = [] } = useQuery(
    ['trust_withdrawal_payment_method'],
    fetchTrustWithdrawalPaymentMethods,
    {
      staleTime: Infinity,
      cacheTime: Infinity
    }
  );
  return data?.filter((item) => !excludeIds.includes(item.id));
}
