import { RecordType, ValueListValue } from 'data/models/types';
import { RecordData } from 'utils/types';
import { api } from './api-client';

export interface SearchResultItem<TRecord = RecordData> {
  id: string;
  label: string;
  record: TRecord;
  type: ValueListValue<any>;
}

type SearchOptions = {
  objectTypes: RecordType[];
  perPage: number;
};

const defaultSearchOptions: SearchOptions = {
  objectTypes: ['contact', 'property', 'tenancy', 'ownership', 'invoice'],
  perPage: 15
};

export async function getSearchResults(
  searchTerm: string,
  searchOptions: Partial<SearchOptions> = defaultSearchOptions
) {
  const { objectTypes, perPage } = {
    ...defaultSearchOptions,
    ...searchOptions
  };

  const response = await api.get(`/search`, {
    search: searchTerm,
    per_page: perPage,
    object_types: objectTypes.join(',')
  });

  return response.data as SearchResultItem[];
}
