import { BaseModelGeneratorModel } from '@rexlabs/model-generator';

import { Generator, GeneratorConfig } from 'data/models/generator';
import { TimestampAttributes, ValueListValue } from 'data/models/types';
import { PropertyComplianceCategory } from 'data/models/entities/settings/property-compliance-categories';
import { SupplierComplianceCategory } from 'data/models/entities/settings/supplier-compliance-categories';

export type ComplianceType = BaseModelGeneratorModel &
  TimestampAttributes & {
    label: string;
    description: string;
    category?: ValueListValue<string>;
    applicable_to_object_type?: ValueListValue<string>;
    property_compliance_categories?: { data: PropertyComplianceCategory[] };
    supplier_compliance_categories?: { data: SupplierComplianceCategory[] };
    is_default_task_creation: boolean;
    task_expiration_frequency: {
      count: number;
      unit: {
        id: string;
        label: string;
      };
    };
  };

const config: GeneratorConfig = {
  entities: {
    related: {
      property_compliance_categories: {
        include: 'property_compliance_categories'
      },
      supplier_compliance_categories: {
        include: 'supplier_compliance_categories'
      }
    }
  }
};

export const settingsComplianceTypesModel = new Generator<ComplianceType>(
  'settings/compliance-types',
  config
).createEntityModel();
