import * as React from 'react';

import { DialogProps } from '@rexlabs/dialog';
import { useModelActions } from '@rexlabs/model-generator';

import { useRecordCreatedToast } from 'src/modules/common/toasts/hooks/use-record-created-toast';
import { tasksModel } from 'src/modules/tasks/common/models/tasks-model';

import { CreateRecordDialog } from 'view/components/dialogs/create-record-dialog/create-record-dialog';
import { RecordSubmitHandler } from 'view/components/record-screen/utils';

import { taskDateBlock } from '../../common/blocks/task-date-block';
import { taskPriorityBlock } from '../../common/blocks/task-priority-block';
import { leaseReviewDetailsBlock } from '../blocks/lease-review-details-block';
import { LeaseReviewFormData } from '../types/LeaseReviewFormData';
import { mapLeaseReviewFormDataToTaskCreateRequest } from '../mappers/map-lease-review-form-data-to-task-create-request';
import { checklistTemplateSelectBlock } from '../../common/blocks/checklist-template-select-block';
import { createTaskAndLinkChecklists } from '../../common/utils/create-task-and-link-checklists';
import { useTaskTypesWithCustomAddToRecord } from '../../settings/hooks/use-task-types-with-custom-add-to-record';

export interface CreateLeaseReviewRecordDialogProps extends DialogProps {
  initialValues: Partial<LeaseReviewFormData>;
  onCreate?: (data) => void;
  isFixture?: boolean;
}

const useContent = () => {
  const taskTypesWithCustomAddToRecord = useTaskTypesWithCustomAddToRecord();

  return [
    {
      id: 'add-lease-review',
      label: 'Add Lease-Review',
      blocks: [
        leaseReviewDetailsBlock,
        ...(taskTypesWithCustomAddToRecord.includes('task_lease_review')
          ? [checklistTemplateSelectBlock]
          : []),
        taskPriorityBlock,
        taskDateBlock
      ]
    }
  ];
};

export function CreateLeaseReviewRecordDialog({
  onClose,
  isFixture,
  onCreate,
  target,
  initialValues
}: CreateLeaseReviewRecordDialogProps) {
  const { refreshLists } = useModelActions(tasksModel);
  const addToast = useRecordCreatedToast('task_lease_review');
  const content = useContent();

  const handleSubmit: RecordSubmitHandler<LeaseReviewFormData> = async ({
    values
  }) => {
    const leaseReview = await createTaskAndLinkChecklists<'task_lease_review'>(
      values,
      mapLeaseReviewFormDataToTaskCreateRequest
    );

    onCreate?.(leaseReview);

    addToast(leaseReview);

    await refreshLists();

    return leaseReview;
  };

  return (
    <CreateRecordDialog
      recordType='task_lease_review'
      data={initialValues}
      content={content}
      onClose={onClose}
      blockProps={{ taskTypeId: 'task_lease_review' }}
      onSubmit={handleSubmit}
      target={target}
      isFixture={isFixture}
    />
  );
}
