import React from 'react';

import { CellProps } from '@rexlabs/table';

import { pluralize } from 'utils/formatters';

import { TwoTierCell } from 'view/components/cells/two-tier-cell';

import { KeySet } from '../data/key-set-model';

export function NameAndNumberOfKeySetCell({ row }: CellProps<any, KeySet>) {
  const text = row?.original?.name;
  const subtext = row?.original.number_of_keys || 0;

  return <TwoTierCell text={text} subtext={pluralize('key', subtext, true)} />;
}
