import { getDocumentsBlock } from 'src/modules/documents/blocks/documents';
import { Ownership } from 'data/models/entities/ownerships';

export const documentsBlock = getDocumentsBlock(
  ({ data }: { data: Ownership }) => ({
    attachedTo: [
      {
        id: data.id,
        label: data.display_name,
        type: { id: 'ownership' }
      }
    ],
    ownershipId: data.id
  })
);
