import { validate } from 'uuid';

export function getRecordIdFromUrlPath(): string {
  const pathName = window.location.pathname;
  const parts = pathName.split('/');
  const uuid = parts.pop();

  if (!uuid) {
    throw new Error('Could not get the uuid from the pathname');
  }

  if (!validate(uuid)) {
    throw new Error(`${uuid} is not a valid uuid`);
  }

  return uuid;
}
