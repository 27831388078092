// TODO: check if this hack/workaround is still needed

import React from 'react';
import {
  DatePicker as VividDatePicker,
  DatePickerProps as VividDatePickerProps
} from '@rexlabs/date-input';

export type DatePickerOnChangeEvent = {
  target: {
    value: string | null;
  };
};

export interface BaseDateInputProps
  extends Omit<VividDatePickerProps, 'onChange' | 'onBlur'> {
  format?: string;
  onChange?: (e: DatePickerOnChangeEvent) => void;
  onBlur?: (e: DatePickerOnChangeEvent) => void;
}

export function BaseDateInput({
  format,
  value,
  onChange,
  onBlur,
  ...props
}: BaseDateInputProps) {
  return (
    <VividDatePicker
      value={value}
      onChange={(e) => {
        onChange?.({
          target: {
            value: e.target.value?.format?.(format) || null
          }
        });
      }}
      onBlur={(e) => {
        onBlur?.({
          target: {
            value: e.target.value?.format?.(format) || null
          }
        });
      }}
      {...props}
    />
  );
}
