import React from 'react';

import { Truncate } from '@rexlabs/text';
import { CellProps } from '@rexlabs/table';

import { RentScheduleEntry } from 'src/modules/property-tenancies/types/property-tenancy-types';
import { getRentAndFrequency } from 'src/modules/rent/utils/get-rent-and-frequency';

export function RentAmountCell({ cell }: CellProps<unknown, unknown>) {
  const rentScheduleEntry = cell.row.original as RentScheduleEntry;

  const rentAndFrequency = getRentAndFrequency({ rentScheduleEntry });
  return <Truncate>{rentAndFrequency}</Truncate>;
}
