import React from 'react';
import { Column, Grid } from '@rexlabs/grid';
import { Field } from '@rexlabs/form';
import { BankAccountSelect } from 'src/modules/bank-accounts/components/bank-account-select';
import { SecurityDepositAuthorityContactSelect } from 'view/components/inputs/selects/security-deposit-authority-contact';

export function HeldInTrustForm() {
  return (
    <Grid>
      <Column width={12}>
        <Field
          id='held_by_contact'
          name='held_by_contact'
          label='Held by'
          Input={SecurityDepositAuthorityContactSelect}
          optional={false}
        />
      </Column>
      <Column width={12}>
        <Field
          id='held_by_bank_account'
          name='held_by_bank_account'
          label='Initial lodgement default bank account'
          Input={BankAccountSelect}
          optional={false}
        />
      </Column>
    </Grid>
  );
}
