import React, { useCallback, useMemo, useState } from 'react';
import { BlockConfig } from 'view/components/record-screen/types';
import { Grid } from '@rexlabs/grid';
import { useQuery } from 'react-query';
import { api } from 'utils/api/api-client';
import { DestructiveButton } from '@rexlabs/button';
import { Select } from '@rexlabs/select';
import { Field } from '@rexlabs/form';
import { useConfirmationDialog } from '@rexlabs/dialog';
import { push } from '@rexlabs/whereabouts';
import ROUTES from 'routes/app';
import { useToast } from 'view/components/@luna/notifications/toast';
import { InfoBanner } from 'view/components/@luna/notifications/banner';
import InfoCircleIcon from 'view/components/icons/info';
import Box from '@rexlabs/box';
import { Settings } from '../../hooks/useSettings';

type Scenario = { id: string; label: string; description: string };

export const scenariosBlock: BlockConfig<Settings> = {
  id: 'scenario-settings',
  title: 'Scenarios',
  View: () => {
    const { data: scenarioData } = useQuery({
      queryKey: ['scenarios'],
      queryFn: () => api.get<Scenario[]>('/scenarios'),
      select: (data) => data.data
    });

    const [selectedScenario, setSelectedScenario] = useState<Scenario | null>(
      null
    );

    const { addToast } = useToast();

    const dialogMessage = useMemo(
      () =>
        `Are you sure you want to invoke this scenario '${selectedScenario?.id}'? This will reset the database to the state of the scenario. This action cannot be undone.`,
      [selectedScenario]
    );

    const handleInvoke = useCallback(async () => {
      if (!selectedScenario) {
        return;
      }

      try {
        await api.post(`/scenarios/${selectedScenario.id}`);
      } catch (e) {
        let message = 'Unknown error';
        if (typeof e === 'string') {
          e.toUpperCase();
        } else if (e instanceof Error) {
          message = e.message;
        }
        console.error(e);
        return addToast({
          type: 'error',
          title: 'Something went wrong',
          description: 'error: ' + message
        });
      }

      push(ROUTES.DASHBOARD);
      return window.location.reload();
    }, [addToast, selectedScenario]);

    const handleChange = useCallback(
      (e: React.ChangeEvent<HTMLInputElement>) => {
        setSelectedScenario((e.target.value as unknown) as Scenario);
      },
      []
    );

    const { open } = useConfirmationDialog({
      title: 'Invoke scenario',
      destructive: true
    });

    return (
      <Grid columns={1}>
        <InfoBanner
          Icon={InfoCircleIcon}
          description='Invoking a scenario will remove all of your existing data and give you a set of sample data to experiment with. This operation cannot be reversed, and can only be performed on sample silos'
        />
        <Box display='flex' flexDirection='row' alignItems='flex-end' gap={12}>
          <Field
            label='Choose a scenario'
            name='scenario'
            Input={Select}
            onChange={handleChange}
            optional={false}
            inputProps={{
              items: scenarioData || [],
              normaliser: (scenario) => ({
                id: scenario.id,
                label: scenario.label
              })
            }}
          />
          <DestructiveButton
            disabled={!selectedScenario}
            onClick={() =>
              // These need to be passed in at call-time because they use dynamic values
              {
                selectedScenario &&
                  open({
                    message: dialogMessage,
                    onConfirm: handleInvoke
                  });
              }
            }
          >
            Invoke Scenario
          </DestructiveButton>
        </Box>
      </Grid>
    );
  }
};
