import { Reconciliation } from 'data/models/entities/financials/reconciliations';
import invariant from 'invariant';

export function getReconciledInThisReconciliationFilters(data: Reconciliation) {
  // previous_reconciliation is needed for this, even if it is null. If it is undefined and being used in this function, it was likely forgotten as an include
  invariant(
    data.previous_reconciliation !== undefined,
    'previous_reconciliation is undefined, ensure it is included.'
  );

  return [
    // If there is a previous reconciliation, this tab should only show things that were reconciled after that date
    data!.previous_reconciliation
      ? {
          field: 'reconciliation_status_effective_from',
          op: 'gt',
          value: data!.previous_reconciliation?.reconciled_at
        }
      : null,
    // also only show things reconciled until this reconciliation's reconciled_at date
    data!.previous_reconciliation && data!.reconciled_at
      ? {
          field: 'reconciliation_status_effective_from',
          op: 'lte',
          value: data!.reconciled_at
        }
      : null
  ].filter(Boolean);
}
