import React, { ComponentType, PropsWithChildren, ReactNode } from 'react';
import { StyleSheet, useStyles } from '@rexlabs/styling';
import Box from '@rexlabs/box';
import { Body, Heading } from '@rexlabs/text';
import { ActionButtons } from 'view/components/@luna/action-buttons';
import { ActionDeclaration } from 'src/modules/common/actions/types/action-declaration-types';

const defaultStyles = StyleSheet({
  container: {
    padding: ({ token }) => token('spacing.xl'),
    borderRadius: ({ token }) => token('border.radius.l'),
    width: '100%',
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },

  containerSmall: {
    padding: ({ token }) => token('spacing.l')
  },

  containerLarge: {
    padding: ({ token }) => token('spacing.xxxl')
  },

  containerGrey: {
    background: ({ token }) => token('color.container.static.contrast')
  },

  illustration: {
    height: '10rem',
    width: 'auto',
    marginBottom: ({ token }) => token('spacing.s')
  }
});

interface MessageProps {
  title?: ReactNode;
  actions?: ActionDeclaration[];
  Illustration?: ComponentType<any>;
  Icon?: ComponentType<any>;
  grey?: boolean;
  size?: 'large' | 'medium' | 'small';
}

export function Message({
  title,
  actions,
  Illustration,
  Icon,
  grey,
  size = 'medium',
  children
}: PropsWithChildren<MessageProps>) {
  const s = useStyles(defaultStyles);

  const iconSize = size === 'large' ? 'xl' : 'l';

  return (
    <Box
      {...s('container', {
        containerGrey: grey,
        containerLarge: size === 'large',
        containerMedium: size === 'medium',
        containerSmall: size === 'small'
      })}
    >
      {Illustration && <Illustration {...s('illustration')} />}
      {Icon && <Icon size={iconSize} />}
      {title && <Heading level={4}>{title}</Heading>}
      {children && <Body grey>{children}</Body>}
      {actions && <ActionButtons actions={actions} alignItems='center' />}
    </Box>
  );
}
