import React from 'react';
import { BlockConfig } from 'view/components/record-screen/types';
import { Grid } from 'view/components/@luna/form/grid';
import { Field, FieldArray } from '@rexlabs/form';
import { Checkbox } from '@rexlabs/checkbox';
import { TextInput } from '@rexlabs/text-input';
import { ChecklistItemDueDateInput } from 'view/components/inputs/checklist-item-due-date-input/checklist-item-due-date-input';
import { useFeatureFlags } from 'view/components/@luna/feature-flags';
import { FLAGS } from 'utils/feature-flags';
import { ValueListValue } from 'data/models/types';
import invariant from 'invariant';
import Box from '@rexlabs/box';
import { get, uniqueId } from 'lodash';
import '../utils/checklist-item-validation-rules';
import { GhostIconButton, OutlineIconButton } from '@rexlabs/button';
import Icons from '@rexlabs/icons';
import {
  DragDropContext,
  Draggable,
  DropResult,
  Droppable
} from 'react-beautiful-dnd';
import AddIcon from 'view/components/icons/add';
import Tooltip from '@rexlabs/tooltip';
import {
  ChecklistTemplatesForm,
  ChecklistTemplatesItemForm
} from '../types/ChecklistTemplatesForm';
import { ChecklistAssigneeSelect } from '../../common/components/checklist-assignee-select';
import { TaskType } from '../../common/types/TaskType';
import { DragHandle } from '../components/smart-checklists/drag-handle';
import { ChecklistTemplateItem } from '../types/ChecklistTemplate';

export const checklistTemplateItemDetailsBlock: BlockConfig<
  ChecklistTemplateItem,
  {
    taskType: ValueListValue<TaskType>;
    mainValues: Partial<ChecklistTemplatesForm>;
  },
  ChecklistTemplatesItemForm & { new_outcome_option: string }
> = {
  id: 'checklist-template-item-details',
  title: 'Details',
  validate: {
    definitions: {
      name: { rules: 'required', name: 'subtask name' },
      due_date: { rules: 'required|fullDueDateIsRequired' }
    },
    messages: {
      required_if: 'The :attribute field is required.'
    }
  },
  Edit({ data, blockProps, values, setFieldValue }) {
    invariant(blockProps, 'blockProps is required');

    const { taskType, mainValues } = blockProps;
    const { hasFeature } = useFeatureFlags();

    return (
      <Grid columns={1}>
        <Field
          name='name'
          label='Subtask name'
          Input={TextInput}
          inputProps={{
            placeholder: 'eg "Do a thing"'
          }}
        />
        <Grid columns={2} style={{ alignItems: 'flex-start' }}>
          <Field
            name='due_date'
            label='Subtask due date'
            Input={ChecklistItemDueDateInput}
            inputProps={{ taskType, allowSpecificDate: false }}
            optional={false}
          />
          {hasFeature(FLAGS.SMART_CHECKLISTS_ASSIGNEE_ROLES) && (
            <Box>
              <Field
                id='assignee_contact_or_role'
                name='assignee_contact_or_role'
                label='Assigned to'
                Input={ChecklistAssigneeSelect}
                inputProps={{
                  getSuggestedItems: () =>
                    [values?.assignee_contact_or_role].filter(Boolean)
                }}
              />
              {values?.assignee_contact_or_role && (
                <Field
                  name='restricted_to_assignee'
                  Input={Checkbox}
                  optional={false}
                  inputProps={{
                    label: 'Only the assignee can complete this subtask'
                  }}
                />
              )}
            </Box>
          )}
        </Grid>
        {hasFeature(FLAGS.SMART_CHECKLISTS_BLOCKS_SUBSEQUENT_ITEMS) && (
          <Field
            name='blocks_subsequent_items'
            Input={Checkbox}
            optional={false}
            inputProps={{
              label:
                'Require this subtask to be completed before subsequent subtasks can be completed'
            }}
          />
        )}
        {hasFeature(FLAGS.SMART_CHECKLISTS_OUTCOMES_AND_BRANCHING) && (
          <>
            <Field
              name='has_outcome'
              Input={Checkbox}
              optional={false}
              inputProps={{
                label: 'Collect an outcome when completing this subtask',
                // If there is an action trigger on this item, we should disable the outcome option
                disabled: !!data?.trigger_type?.id
              }}
            />
            {values?.has_outcome && (
              <FieldArray name='outcome_data.outcome_options'>
                {function LineItems({ fields, push, move }) {
                  const handleNewOutcome = () => {
                    push({
                      label: values.new_outcome_option,
                      id: generateItemId(values.new_outcome_option)
                    });
                    setFieldValue?.('new_outcome_option', '');
                  };

                  const handleDragEnd = (result: DropResult) => {
                    if (!result.destination) {
                      return;
                    }

                    move(result.source.index, result.destination.index);
                  };

                  return (
                    <DragDropContext onDragEnd={handleDragEnd}>
                      <Box>
                        <Droppable droppableId='outcomes'>
                          {(provided) => (
                            <Box
                              ref={provided.innerRef}
                              display='flex'
                              flexDirection='column'
                              gap={12}
                              {...provided.droppableProps}
                            >
                              {fields.map(({ field, actions }, index) => (
                                <Draggable
                                  key={field.name}
                                  draggableId={field.name}
                                  index={index}
                                >
                                  {(provided) => {
                                    let isLocked = false;
                                    if (mainValues) {
                                      const groupIds = mainValues.checklist_template_item_groups
                                        ?.filter(
                                          (group) =>
                                            group
                                              .triggered_by_checklist_template_item
                                              ?.id === data?.id &&
                                            group.triggered_by_value ===
                                              get(values, field.name)?.id
                                        )
                                        .map((group) => group.id);

                                      const items = mainValues.checklist_template_items?.filter(
                                        (item) =>
                                          item.checklist_template_item_group
                                            ?.id &&
                                          groupIds?.includes(
                                            item.checklist_template_item_group
                                              ?.id
                                          )
                                      );
                                      isLocked = !!items?.length;
                                    }
                                    return (
                                      <div
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                      >
                                        <Box
                                          display='flex'
                                          flexDirection='row'
                                          alignItems='center'
                                          gap={12}
                                          padding={12}
                                          style={{
                                            background: '#f6f6f6',
                                            borderRadius: 8
                                          }}
                                        >
                                          <Box
                                            width={20}
                                            height={20}
                                            display='flex'
                                            justifyContent='center'
                                            alignItems='center'
                                            {...provided.dragHandleProps}
                                          >
                                            <DragHandle />
                                          </Box>
                                          <Field
                                            optional={false}
                                            name={`${field.name}.label`}
                                            Input={TextInput}
                                          />
                                          <Tooltip
                                            isDisabled={!isLocked}
                                            Content={() =>
                                              'Cannot delete outcome while path contains subtasks'
                                            }
                                            placement='left'
                                          >
                                            <GhostIconButton
                                              isDisabled={isLocked}
                                              Icon={Icons.DeleteCross}
                                              onClick={actions.remove}
                                            />
                                          </Tooltip>
                                        </Box>
                                      </div>
                                    );
                                  }}
                                </Draggable>
                              ))}
                              {provided.placeholder}
                            </Box>
                          )}
                        </Droppable>
                        <AddNewOutcomePrompt onClick={handleNewOutcome} />
                      </Box>
                    </DragDropContext>
                  );
                }}
              </FieldArray>
            )}
          </>
        )}
      </Grid>
    );
  }
};

function generateItemId(option: string): string {
  // We generate an incrementing ID here, and the backend will replace them with unique UUIDs when we save.
  const optionSlug = option.toLowerCase().replace(/\s/g, '-');
  return uniqueId(`placeholder-outcome-option-${optionSlug}-`);
}

function AddNewOutcomePrompt({ onClick }) {
  return (
    <Box>
      <Box
        display='flex'
        flexDirection='row'
        alignItems='center'
        gap={12}
        padding={12}
      >
        {/* placeholder to align with above items */}
        <Box width={20} />
        <Field
          optional={false}
          name={`new_outcome_option`}
          Input={TextInput}
          inputProps={{
            placeholder: 'Add another option'
          }}
        />
        <OutlineIconButton Icon={AddIcon} onClick={onClick} />
      </Box>
    </Box>
  );
}
