import React from 'react';
import { HiddenField } from '@rexlabs/form';

import { BlockViewProps } from 'view/components/record-screen/types';
import { ManageNameAndSortingFieldArray } from 'view/components/manage-name-and-sorting-field-array';

export function EditItemsForm({
  values,
  setFieldValue,
  blockProps
}: BlockViewProps) {
  const onDelete = (actions, index) => {
    const itemId = values.items[index].id;
    if (!blockProps.isEditMode || !itemId) {
      return actions.remove();
    }

    actions.remove();

    const ids = Array.isArray(values.delete_ids)
      ? values.delete_ids.concat(itemId)
      : [itemId];

    setFieldValue?.('delete_ids', ids);
  };

  return (
    <>
      <ManageNameAndSortingFieldArray
        fieldArrayName='items'
        fieldName='name'
        textInputPlaceholder='Enter item name'
        onDelete={onDelete}
      />

      <HiddenField name='delete_ids' />
    </>
  );
}
