import { useTranslation } from 'react-i18next';
import { ContentConfig } from 'view/components/record-screen/types';
import { FLAGS } from 'utils/feature-flags';
import { useModelState } from '@rexlabs/model-generator';
import { sessionModel } from 'data/models/custom/session';
import { batchReceiptingSettingsBlock } from 'src/modules/settings/account-settings/blocks/batch-receipting-settings';
import { useDisbursementsFrequencyBlock } from 'src/modules/disbursements/blocks/disbursement-frequency-block';
import { removeTaskWithheldFundsSettingsBlock } from 'src/modules/settings/account-settings/blocks/remove-task-withheld-funds-settings';
import { useFeatureFlags } from 'view/components/@luna/feature-flags';
import { withdrawalsSettingsBlock } from 'src/modules/settings/account-settings/blocks/withdrawals-settings';
import { localisationSettingsBlock } from '../account-settings/blocks/localisation-settings';
import { accountingSettingsBlock } from '../account-settings/blocks/accounting-settings';
import { rentPaidToDateSettingsBlock } from '../account-settings/blocks/rent-paid-to-date-settings';
import { rentArrearsPaidToDateSettingsBlock } from '../account-settings/blocks/rent-arrears-paid-to-date-settings';
import { rentArrearsCalculationSettingsBlock } from '../account-settings/blocks/rent-arrears-calculation-settings';
import { scenariosBlock } from '../account-settings/blocks/scenarios-block';
import { daysInAdvanceBlock } from '../account-settings/blocks/days-in-advance';
import { rentPaymentSettingsBlock } from '../account-settings/blocks/rent-payment-settings';
import { hmrcTaxPaymentSettingsBlock } from '../account-settings/blocks/hmrc-tax-payment-settings';

export const useContent = (): ContentConfig => {
  const { activeSilo } = useModelState(sessionModel);
  const { hasFeature } = useFeatureFlags();
  const disbursementFrequencyBlock = useDisbursementsFrequencyBlock(false);
  const { t } = useTranslation();
  return [
    {
      label: 'Account Settings',
      items: [
        {
          id: 'account-settings',
          label: 'Account Settings',
          blocks: [
            ...(activeSilo?.is_sample ? [scenariosBlock] : []),
            localisationSettingsBlock,
            accountingSettingsBlock,
            removeTaskWithheldFundsSettingsBlock,
            daysInAdvanceBlock,
            ...(FLAGS.RENT_PAID_TO_DATE
              ? [
                  rentPaidToDateSettingsBlock,
                  rentArrearsPaidToDateSettingsBlock,
                  rentArrearsCalculationSettingsBlock
                ]
              : []),
            batchReceiptingSettingsBlock,
            ...(hasFeature(FLAGS.HMRC_REPORTS_AND_SUBMISSIONS) &&
            activeSilo?.country.id === 'GBR'
              ? [hmrcTaxPaymentSettingsBlock]
              : []),
            ...(hasFeature(FLAGS.DEFAULT_DISBURSEMENT_FREQUENCY_SETTING)
              ? [
                  {
                    ...disbursementFrequencyBlock,
                    // Add translation key for the title
                    title: t(
                      'disbursements.blocks.default-ownership-disbursement-frequency.title'
                    )
                  }
                ]
              : []),
            ...(hasFeature(FLAGS.WITHDRAWALS_SETTING)
              ? [withdrawalsSettingsBlock]
              : []),
            rentPaymentSettingsBlock
          ]
        }
      ]
    }
  ];
};
