import { useStyles, StyleSheet } from '@rexlabs/styling';
import Icons from '@rexlabs/icons';
import * as React from 'react';

export const styles = StyleSheet({
  average: {
    color: ({ token }) => token('color.warning.idle.default')
  },
  good: {
    color: ({ token }) => token('color.success.idle.default')
  },
  neutral: {
    color: ({ token }) => token('color.neutral.idle.default')
  },
  spacing: {
    margin: ({ token }) => token('spacing.xs')
  }
});

export function CircleFilledIcon({ id, taskStatusMap }) {
  const s = useStyles(styles);

  const type = taskStatusMap[id];
  return <Icons.CircleFilled {...s(type, 'spacing')} />;
}
