import { InvoiceLineItem } from 'data/models/entities/financials/invoices';
import {
  getPayableToProperties,
  getPayableByProperties
} from './get-properties';

export function hasPayableByProperties(lineItems: InvoiceLineItem[]) {
  return getPayableByProperties(lineItems).length > 0;
}

export function hasPayableToProperties(lineItems: InvoiceLineItem[]) {
  return getPayableToProperties(lineItems).length > 0;
}
