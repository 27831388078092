import React from 'react';
import { Column } from 'view/components/table';
import {
  AccountingJournalEntry,
  AccountingJournalEntryLineItem
} from 'src/modules/account-journal-entries/types/account-journal-entry-types';
import { Invoice } from 'data/models/entities/financials/invoices';
import { CreditNote } from 'data/models/entities/financials/credit-notes';
import { TrustJournalEntryLineItem } from 'src/modules/trust-journal-entry-line-items/types/trust-journal-entry-line-item-type';
import { TwoTierCell } from 'view/components/cells/two-tier-cell';
import { CurrencyCell } from '@rexlabs/table';
import { AccountingJournalEntrySourceCell } from '../components/accounting-journal-entry-source-cell';

export function ChartOfAccountAccount({
  value
}: {
  value: AccountingJournalEntryLineItem;
}) {
  return (
    <TwoTierCell
      text={value.chart_of_accounts_account.name}
      subtext={value.chart_of_accounts_account.account_category.label}
    />
  );
}

const sourceColumnToCsv = (item: AccountingJournalEntryLineItem) => {
  const accountingJournalEntry = item.accounting_journal_entry as AccountingJournalEntry;

  if (accountingJournalEntry?.type.id === 'manual') {
    return 'Manual';
  }

  if (
    accountingJournalEntry.source_object_type ===
    'trust_journal_entry_line_item'
  ) {
    const source = accountingJournalEntry.source_object as TrustJournalEntryLineItem;

    return source.trust_journal_entry?.record_reference || '';
  } else {
    const source = accountingJournalEntry.source_object as Invoice | CreditNote;

    return source?.record_reference;
  }
};

const referenceColumn: Column<AccountingJournalEntryLineItem> = {
  id: 'reference',
  Header: 'Reference',
  type: 'accounting_journal_entry',
  accessor: (item) => item.accounting_journal_entry,
  width: 140,
  toCsv: (item) => item.record_reference
};

const dateColumn: Column<AccountingJournalEntryLineItem> = {
  id: 'date_of',
  type: 'date',
  Header: 'Date',
  accessor: (item) => item.accounting_journal_entry?.date_of,
  width: 140
};

const descriptionColumn: Column<AccountingJournalEntryLineItem> = {
  id: 'description',
  Header: 'Description',
  accessor: (item) => item.description,
  width: 300
};

const amountColumn: Column<AccountingJournalEntryLineItem> = {
  id: 'amount',
  accessor: (item) => item.amount,
  Header: 'Amount',
  Cell: (props) => {
    return (
      <TwoTierCell
        text={<CurrencyCell {...props} />}
        subtext={props.row.original?.debit_or_credit?.label ?? ''}
      />
    );
  },
  width: 140
};

const createdByColumn: Column<AccountingJournalEntryLineItem> = {
  id: 'created_by',
  Header: 'Created by',
  type: 'author',
  accessor: (item) => item.accounting_journal_entry?.created_by,
  width: 140
};

const createdOnColumn: Column<AccountingJournalEntryLineItem> = {
  id: 'created_on',
  Header: 'Created on',
  type: 'date',
  accessor: (item) => item.accounting_journal_entry?.created_at,
  width: 140
};

const sourceColumn: Column<AccountingJournalEntryLineItem> = {
  Header: 'Source',
  accessor: (item) => item,
  Cell: (props) =>
    AccountingJournalEntrySourceCell({
      accountingJournalEntry: props.value?.accounting_journal_entry
    }),
  width: 140,
  toCsv: (item) => sourceColumnToCsv(item)
};

export const getAccountingJournalEntryLineItemColumns = (
  hasManualAccountingJournalEntriesFeature: boolean
) => {
  return hasManualAccountingJournalEntriesFeature
    ? [
        referenceColumn,
        dateColumn,
        sourceColumn,
        descriptionColumn,
        {
          id: 'account',
          Header: 'Account',
          accessor: (item) => item,
          width: 200,
          Cell: ChartOfAccountAccount,
          toCsv: (item) => item.chart_of_accounts_account.name
        },
        amountColumn,
        createdByColumn,
        createdOnColumn
      ]
    : [
        referenceColumn,
        descriptionColumn,
        dateColumn,
        {
          id: 'type',
          Header: 'Type',
          accessor: (item) =>
            item.chart_of_accounts_account.account_category.label,
          width: 140
        },
        amountColumn,
        {
          id: 'Account',
          accessor: (item) => item.chart_of_accounts_account.name,
          Header: 'Chart of Account',
          width: 200
        },
        sourceColumn
      ];
};
