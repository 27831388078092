import React from 'react';
import { useDialog } from '@rexlabs/dialog';
import _ from 'lodash';

import { BlockConfig } from 'view/components/record-screen/types';
import { ComplianceEntriesList } from 'src/modules/compliance/common/components/compliance-entries-list';

import { ActionButtons } from 'view/components/@luna/action-buttons';
import AddIcon from 'view/components/icons/add';

import { getUnfulfilledComplianceTypes } from 'src/modules/compliance/common/utils/get-unfulfilled-compliance-types';
import { ComplianceEntry } from 'src/modules/compliance/common/models/compliance-entries';
import { Contact } from 'src/modules/contacts/types/contact-types';
import { SupplierAddNewEntryDialog } from 'src/modules/compliance/common/dialogs/supplier-add-new-entry-dialog';
import { SupplierInfoComplianceBanner } from 'src/modules/compliance/common/components/supplier-info-compliance-banner';
import { SupplierComplianceBanner } from 'src/modules/compliance/common/components/supplier-compliance-banner';
import { EmptyCategorySupplierComplianceBanner } from 'src/modules/compliance/common/components/empty-category-supplier-compliance-banner';
import { SupplierComplianceCategory } from 'data/models/entities/settings/supplier-compliance-categories';
import { ComplianceType } from 'src/modules/compliance/common/models/compliance-types';

type GetRelatedComplianceTypes = (
  complianceCategories?: SupplierComplianceCategory[]
) => ComplianceType[];

const getRelatedComplianceTypes: GetRelatedComplianceTypes = (
  complianceCategories
) => {
  if (!complianceCategories) {
    return [];
  }

  // Retrieve unique compliance types from all categories
  const uniqueComplianceCategories = _.uniqBy(
    _.flatMap(complianceCategories, (category) =>
      // Get an array of compliance types from each category requirement
      category?.category_requirements?.data?.map(
        (categoryTypePivot) => categoryTypePivot.compliance_type
      )
    ),
    'id'
  );

  // compact removes empty values and also is typed correctly.
  // ts doesn't infer other solutions to removing empty values sadly
  return _.compact(uniqueComplianceCategories);
};

type ComplianceEntriesBlock = ({
  complianceEntries
}: {
  complianceEntries?: ComplianceEntry[];
}) => BlockConfig<Contact>;

export const getComplianceEntriesBlock: ComplianceEntriesBlock = ({
  complianceEntries
}) => ({
  id: 'compliance-entries',
  title: 'Insurance, Licenses and Certificates',
  View: ({ data }) => {
    const emptyComplianceCategories = data?.compliance_categories?.filter(
      (category) => category?.category_requirements?.data?.length === 0
    );

    const complianceTypes = getRelatedComplianceTypes(
      data?.compliance_categories
    );

    const unfulfilledComplianceTypes = getUnfulfilledComplianceTypes({
      complianceTypes,
      entries: complianceEntries
    });

    const bannerEntries = complianceEntries?.filter((entry) => {
      return (
        entry?.status?.id === 'expired' || entry?.status?.id === 'expires_soon'
      );
    });

    return (
      <>
        {!!emptyComplianceCategories?.length && (
          <EmptyCategorySupplierComplianceBanner
            emptyCategories={emptyComplianceCategories}
          />
        )}
        {unfulfilledComplianceTypes.map((complianceType) => (
          <SupplierInfoComplianceBanner
            key={complianceType.id}
            complianceType={complianceType}
            contactId={data?.id}
          />
        ))}
        {bannerEntries?.map((entry) => (
          <SupplierComplianceBanner
            key={entry.id}
            contactId={data?.id}
            complianceType={entry.compliance_type}
            status={entry.status}
          />
        ))}
        {data && !!complianceEntries?.length && (
          <ComplianceEntriesList objectId={data.id} objectType={'supplier'} />
        )}
      </>
    );
  },
  Actions: ({ data }) => {
    const addNewEntryDialog = useDialog(SupplierAddNewEntryDialog);

    return (
      <ActionButtons
        actions={[
          {
            Icon: AddIcon,
            intent: 'tertiary',
            handleAction: () =>
              addNewEntryDialog.open({
                contactId: data?.id
              })
          }
        ]}
      />
    );
  }
});
