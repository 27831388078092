import { useGetGenericWipAction } from 'view/hooks/actions/use-get-generic-wip-action';

export function useGetReconcileAction() {
  const getGenericWipAction = useGetGenericWipAction();

  return () =>
    getGenericWipAction({
      id: 'reconcile',
      group: 'bank-deposit',
      actionName: 'Reconcile now'
    });
}
