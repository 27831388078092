import { Columns } from 'view/components/table';

export const disbursementInvoiceWithdrawalsColumns: Columns = [
  {
    id: 'invoice.record_reference',
    type: 'text',
    Header: 'Invoice #',
    width: '10%'
  },
  {
    id: 'invoice.payable_by.object',
    type: 'record',
    Header: 'Payable by',
    width: '15%'
  },
  {
    id: 'description',
    Header: 'Description'
  },
  {
    id: 'invoice.specific_disbursement_payment_method.payment_method',
    Header: 'Payment method',
    type: 'value'
  },
  {
    Header: 'Amount withdrawn',
    id: 'amount',
    type: 'currency',
    width: '20%',
    accessor: (item) => Math.abs(item.amount)
  }
];
