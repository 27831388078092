import { BulkEditPayload } from 'src/modules/bulk-edit/types/bulk-edit-payload';

export function mapBulkEditDataToPayload<TPayload extends Record<string, any>>(
  selectedIds: string[],
  data: TPayload
): BulkEditPayload<TPayload> {
  return {
    selection: {
      selected_ids: selectedIds
    },
    actions: {
      for_collection: data
    }
  };
}
