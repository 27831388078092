import { KeySet } from '../data/key-set-model';
import { CheckOutKeySetButton } from '../components/check-out-key-set-button';
import { CheckInKeySetButton } from '../components/check-in-key-set-button';

export function useManageKeySetActionButton(data: Partial<KeySet> | undefined) {
  const ActionButton =
    data?.status?.id === 'checked_out'
      ? CheckInKeySetButton
      : CheckOutKeySetButton;

  return ActionButton;
}
