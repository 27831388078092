import React from 'react';

import { SearchResultItem } from 'utils/api/get-search-results';

import { BlockConfig } from 'view/components/record-screen/types';

import {
  CoreCommunicationContextObject,
  CreateMessageFormData
} from '../mappers/types/create-message-form-data';
import { Message } from '../types/Message';

import { getContextTypeFromRecord } from '../utils/get-context-type-from-record';
import { useMessageTriggerSettings } from '../settings/message-triggers/hooks/use-message-trigger-settings';
import { EditLetterContentForm } from '../components/message-content-forms/edit-letter-content-form';

export const letterContentBlock: BlockConfig<
  Message & { relates_to: SearchResultItem<CoreCommunicationContextObject> },
  any,
  CreateMessageFormData
> = {
  id: 'content',
  title: 'Letter Content',
  validate: {
    definitions: {
      content: { name: 'content', rules: 'required' },
      letterAddress: { name: 'letter address', rules: 'required' }
    }
  },
  Edit: ({ data, setFieldValue }) => {
    const {
      getTemplatedAttachmentForContextType
    } = useMessageTriggerSettings();

    return (
      <EditLetterContentForm
        textContentFieldName='content'
        templatedAttachments={getTemplatedAttachmentForContextType(
          getContextTypeFromRecord(data?.relates_to?.record)
        )}
        contextType={getContextTypeFromRecord(data?.relates_to?.record)}
        setFieldValue={setFieldValue}
      />
    );
  }
};
