import { useFeatureFlags } from 'view/components/@luna/feature-flags';
import { FLAGS } from 'utils/feature-flags';
import { TaskType } from '../../common/types/TaskType';

export function useGetIsSmartChecklist() {
  const { hasFeature } = useFeatureFlags();

  return (taskType: TaskType) => {
    const taskTypesForSmartChecklists: TaskType[] = hasFeature(
      FLAGS.SMART_CHECKLISTS_SETTINGS
    )
      ? // NOTE: This is a list of task types that are currently supported by Smart Checklists. New task types need to be opted in once pre-requisite work is done.
        [
          'todo',
          'task_arrears',
          'task_inspection',
          'task_maintenance',
          'task_lease_review',
          'task_move_in',
          'task_move_out',
          'task_property_compliance',
          'task_quote',
          'task_supplier_compliance',
          'task_work_order'
        ]
      : [];

    return taskTypesForSmartChecklists.includes(taskType);
  };
}
