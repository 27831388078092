import Validator from '@rexlabs/validator';
import { TriggerValue } from 'view/components/inputs/checklist-item-trigger-input/checklist-item-trigger-input';
import { ChecklistItemActionType } from '../types/ChecklistTemplate';

Validator.register(
  'fullTriggerIsRequired',
  function (this: any, value: TriggerValue) {
    if (value?.trigger_type?.id === 'on_completion') return true;
    if (value?.trigger_type?.id === 'on_due_date' && value?.time) return true;

    return false;
  },
  'The subtask due date field is required.'
);

const frontendDrivenActions: ChecklistItemActionType[] = [
  'create_note',
  'upload_document',
  'add_linked_task'
];

Validator.register(
  'mustUseOnCompletionForFrontendDrivenActions',
  function (this: any, value: TriggerValue) {
    if (
      value?.trigger_type?.id === 'on_due_date' &&
      frontendDrivenActions.includes(this.validator?.input?.action?.id ?? '')
    )
      return false;

    return true;
  },
  `The trigger type must be "On completion" for this action.`
);
