import React, { useMemo } from 'react';
import { Card } from 'view/components/card';
import { ErrorBoundary } from '@rexlabs/error-boundary';

const getErrorCard = (title: React.ReactNode) => () => {
  return (
    <Card title={title} intent='error'>
      This card has crashed.
    </Card>
  );
};

interface CardErrorBoundaryProps {
  title?: React.ReactNode;
  children: React.ReactNode;
}

export function CardErrorBoundary({ title, children }: CardErrorBoundaryProps) {
  const fallbackComponent = useMemo(() => getErrorCard(title ?? 'Error'), [
    title
  ]);

  return (
    <ErrorBoundary FallbackComponent={fallbackComponent}>
      {children}
    </ErrorBoundary>
  );
}
