import * as React from 'react';
import { useModelActions } from '@rexlabs/model-generator';
import { SingleActionDeclaration } from 'src/modules/common/actions/types/action-declaration-types';
import { useToast } from 'view/components/@luna/notifications/toast';
import { channelMessageRecipientsModel } from '../../models/channel-message-recipients-model';
import { ChannelMessageRecipient } from '../../types/ChannelMessageRecipient';

export function useGetResendMessageAction() {
  const { addToast } = useToast();
  const { refreshLists, resendItem } = useModelActions(
    channelMessageRecipientsModel
  );

  return (
    channelMessageRecipient: ChannelMessageRecipient
  ): SingleActionDeclaration => {
    return {
      id: 'resend-message',
      group: 'messages',
      label: 'Resend message',
      handleAction: async () => {
        const resendMessageResponse = await resendItem({
          channelMessageRecipient: channelMessageRecipient
        });

        if (resendMessageResponse !== false) {
          addToast({
            type: 'success',
            description: (
              <>
                Message has been <b>successfully</b> resent
              </>
            )
          });

          refreshLists();
        }
      }
    };
  };
}
