import { Field, HiddenField } from '@rexlabs/form';
import { BankAccount } from 'data/models/entities/financials/bank-accounts';
import { isEmpty } from 'lodash';
import React, { useEffect } from 'react';
import { BankAccountSelect } from 'src/modules/bank-accounts/components/bank-account-select';
import { Contact } from 'src/modules/contacts/types/contact-types';
import { SearchResultItem } from 'utils/api/get-search-results';
import { Grid } from 'view/components/@luna/form/grid';
import {
  DateInput,
  disableFutureDates
} from 'view/components/@luna/inputs/date-input/date-input';
import { CentAmountInput } from 'view/components/inputs/cent-amount';
import { ContactSelect } from 'view/components/inputs/selects/contact';
import { AdvancedFolioSearchEntitySelect } from 'src/modules/banking/batch-receipting/components/advanced-folio-search-entity-select';
import { BlockConfig } from 'view/components/record-screen/types';
import { Value } from 'view/components/values';
import { CurrencyValue } from 'view/components/values/currency';
import { DateValue } from 'view/components/values/date';

import Box from '@rexlabs/box';
import { AlfredFile } from 'src/modules/common/types/file';
import { FinancialEntity } from '../utils/financial-entity-action-group/use-get-financial-entity-actions';
import { getRelatedRecords } from '../utils/get-related-records';
import { TransactionFileDetails } from '../components/transaction-file-details';

export type ReceiptFundsDetailsBlockProps = {
  folio?: SearchResultItem<FinancialEntity>;
  contact?: Contact;
  amount?: number;
  paid_by?: string;
  bank_account?: BankAccount;
  date_of?: Date;
  description?: string;
  bankTransactionFile?: AlfredFile;
  payment_information: {
    reference?: string;
  };
};

export const receiptFundsDetailsBlock: BlockConfig<
  any,
  any,
  ReceiptFundsDetailsBlockProps
> = {
  id: 'receipt-funds-details',
  title: 'Details',
  validate: {
    definitions: {
      folio: { rules: 'required' },
      contacts: { rules: 'required' },
      amount: { rules: 'required' },
      bank_account: { rules: 'required' },
      date_of: { rules: 'required' }
    }
  },
  Edit: ({ values, blockProps, setFieldValue }) => {
    const [suggestedContacts, setSuggestedContacts] = React.useState<
      Contact[] | undefined
    >();

    /**
     * The purpose of this handler is to set the contact field after the folio is
     * selected. If the folio is a tenancy or ownership, we want to set the contact
     * field to the financial contact of the tenancy or ownership. If the folio is
     * a contact, we want to set the contact field to the selected folio.
     */
    async function onFolioChange(event) {
      const value: SearchResultItem = event.target.value;
      if (isEmpty(value)) {
        return;
      }
      if (!['tenancy', 'ownership'].includes(value.type.id)) {
        return setFieldValue?.('contacts', [value.record]);
      }

      const {
        financial_contact,
        otherContacts,
        tenants
      } = await getRelatedRecords({
        folioId: value.id,
        folioType: value.type.id
      });

      setFieldValue?.('contacts', [financial_contact]);
      setFieldValue?.('tenants', tenants);
      setSuggestedContacts(otherContacts);
    }

    /**
     * This is to make sure that we fetch the suggested contacts when we pass in
     * initial values to the dialog. This only happens once on render if there is
     * no suggested contacts and there is a folio.
     */
    useEffect(() => {
      async function getRelatedRecordsAndSetSuggested(folioId, folioType) {
        const { otherContacts } = await getRelatedRecords({
          folioId,
          folioType
        });
        setSuggestedContacts(otherContacts);
      }
      if (
        !suggestedContacts &&
        values?.folio &&
        values?.folio.type.id !== 'contact'
      ) {
        getRelatedRecordsAndSetSuggested(
          values?.folio.id,
          values?.folio.type.id
        );
      }
    }, []);

    return (
      <Box flexDirection='column' gap={24}>
        <Grid columns={3}>
          <HiddenField name='tenants' />

          <Field
            id='folio'
            name='folio'
            label='Folio to receipt to'
            Input={AdvancedFolioSearchEntitySelect}
            inputProps={{
              objectType: ['contact', 'tenancy', 'ownership']
            }}
            onChange={onFolioChange}
          />
          <Field
            id='receipt-from'
            name='contacts'
            label='Receipt from'
            Input={ContactSelect}
            inputProps={{
              multi: true,
              disableFixture: true,
              getSuggestedItems: () => suggestedContacts
            }}
          />
          {blockProps?.renderBatchReceiptingDialog ? (
            <>
              <CurrencyValue label='Amount received' value={values?.amount} />
              <Value label='Bank account' value={values?.bank_account?.name} />
              <DateValue label='Date' value={values?.date_of} />
              <Value label='Received from' value={values?.paid_by} />
            </>
          ) : (
            <>
              <Field
                name='amount'
                label='Amount received'
                Input={CentAmountInput}
              />
              <Field
                name='bank_account'
                label='Bank account'
                Input={BankAccountSelect}
              />
              <Field
                name='date_of'
                label='Date'
                Input={DateInput}
                inputProps={{
                  isOutsideRange: disableFutureDates
                }}
              />
            </>
          )}
        </Grid>

        {blockProps?.showTransactionFileDetails ? (
          <TransactionFileDetails
            description={values?.description}
            reference={values?.payment_information.reference}
            fileName={values?.bankTransactionFile?.name}
            fileDownloadUrl={values?.bankTransactionFile?.download_url}
          />
        ) : null}
      </Box>
    );
  }
};
