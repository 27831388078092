import React from 'react';
import { BlockConfig } from 'view/components/record-screen/types';
import { Grid, Column } from 'view/components/@luna/form/grid';
import { Field } from '@rexlabs/form';
import { Checkbox } from '@rexlabs/checkbox';
import {
  DateInput,
  disableFutureDates
} from 'view/components/@luna/inputs/date-input/date-input';
import { RadioGroupInput } from '@rexlabs/radio-input';
import { BankAccountSelect } from 'src/modules/bank-accounts/components/bank-account-select';

export const trustJournalBlock: BlockConfig = {
  id: 'trustJournalBlock',
  title: 'Detail',
  validate: {
    definitions: {
      bank_account: { rules: 'required' },
      start_date: { rules: 'required' },
      end_date: { rules: 'required' },
      exclude_transactions_dated_after: {
        rules: 'required_with:exclude_transactions'
      }
    }
  },

  Edit: ({ values }) => {
    return (
      <Grid columns={2}>
        <Column width={2}>
          <Field
            name='bank_account'
            label='Bank account'
            Input={BankAccountSelect}
          />
        </Column>

        <Column width={2}>
          <Field
            label='Date type'
            name='date_type.id'
            Input={RadioGroupInput}
            optional={false}
            inputProps={{
              options: [
                { label: 'Creation date', value: 'creation_date' },
                { label: 'Transaction date', value: 'transaction_date' }
              ],
              orientation: 'horizontal'
            }}
          />
        </Column>

        <Field
          label='Start date'
          name='start_date'
          Input={DateInput}
          inputProps={{
            isOutsideRange: disableFutureDates
          }}
        />

        <Field
          label='End date'
          name='end_date'
          Input={DateInput}
          inputProps={{
            isOutsideRange: disableFutureDates
          }}
        />

        <Column width={2}>
          {values.date_type?.id === 'creation_date' && (
            <Field
              name='exclude_transactions'
              inputProps={{
                label: 'Exclude transactions dated after date'
              }}
              Input={Checkbox}
              optional={false}
            />
          )}
        </Column>

        <Column width={2}>
          {values.exclude_transactions && (
            <Field
              label='Select date'
              name='exclude_transactions_dated_after'
              Input={DateInput}
              optional={false}
              inputProps={{
                isOutsideRange: disableFutureDates
              }}
            />
          )}
        </Column>
      </Grid>
    );
  }
};
