import * as React from 'react';
import { DialogProps, useDialog, useErrorDialog } from '@rexlabs/dialog';
import { RecordDialog } from 'view/components/record-screen/dialog/dialog';
import { RecordObject } from 'data/models/types';
import { Forms } from '@rexlabs/form';
import invariant from 'invariant';
import { RecordSubmitHandler } from 'view/components/record-screen/utils';
import { useModelActions } from '@rexlabs/model-generator';
import { extractValuesFromForms } from 'src/modules/common/utils/extract-values-from-forms';
import { useToast } from 'view/components/@luna/notifications/toast';
import { bulkMessageDetailsBlock } from '../blocks/bulk-message-details-block';
import { MessageTemplate } from '../../message-templates/types/MessageTemplate';
import { CommunicationContextType } from '../../common/types/communication-context-type';
import { letterContentBlock } from '../blocks/letter-content-block';
import { mapBulkCreateLetterFormToRequest } from '../mappers/map-bulk-create-letter-form-to-request';
import { messagesModel } from '../models/messages-model';
import {
  MessageCreateDialogButtonGroup,
  MessageCreateDialogButtonGroupProps
} from '../components/message-create-dialog-button-group';
import { BulkCreateMessageRequest } from '../mappers/types/bulk-create-message-request';
import { emptyMessageCreatorItem } from '../../common/data/empty-message-creator-item';
import { BulkCreateLetterMessageFormData } from '../types/BulkCreateLetterMessageFormData';
import { BulkPreviewLetterMessageFormDataDialog } from './bulk-preview-letter-message-form-data-dialog';

export interface BulkCreateLetterRecordDialogProps extends DialogProps {
  data: {
    recordObjects: RecordObject[];
    contextType: CommunicationContextType;
  };
}

const content = [
  {
    id: 'bulk-create-message-record-dialog',
    label: 'Create letters',
    blocks: [bulkMessageDetailsBlock, letterContentBlock]
  }
];

export function BulkCreateLetterRecordDialog({
  onClose,
  data
}: BulkCreateLetterRecordDialogProps) {
  const { bulkCreateItems, refreshLists } = useModelActions(messagesModel);
  const errorDialog = useErrorDialog();
  const { open: openPreviewBulkLettersDialog } = useDialog(
    BulkPreviewLetterMessageFormDataDialog
  );
  const { addToast } = useToast();
  const { recordObjects, contextType } = data;

  const getHandlers = (forms?: Forms) => ({
    // When the template changes, we want to update the content in a different block
    handleTemplateChange: (event) => {
      if (!forms) {
        return;
      }
      const messageTemplate: MessageTemplate = event.target.value;
      const channel = (messageTemplate.channels || []).find(
        (channel) => channel.channel_type.id === 'letter'
      );
      const { setFieldValue: setDetailsFormFieldValue } = forms['details'];
      const { setFieldValue: setContentFormFieldValue } = forms['content'];
      setDetailsFormFieldValue('sent_from', messageTemplate.send_from_author);
      setDetailsFormFieldValue('recipient_group', {
        name: messageTemplate.default_recipient_group
      });
      setContentFormFieldValue('letterAddress', channel?.letter_address);
      setContentFormFieldValue('content', channel?.plain_text_body);
      setContentFormFieldValue(
        'attachments',
        messageTemplate.inline_attachments || []
      );

      if (messageTemplate.templated_attachments?.length) {
        messageTemplate.templated_attachments.map((templatedAttachment) => {
          setContentFormFieldValue(
            `include_generated_attachments.${templatedAttachment}`,
            true
          );
        });
      } else {
        setContentFormFieldValue(`include_generated_attachments`, null);
      }
    }
  });

  const initialValues = {
    context_type: contextType,
    sent_from: emptyMessageCreatorItem,
    attachments: []
  };

  const blockProps = {
    recordObjects,
    contextType,
    messageType: 'letter'
  };

  // To reduce duplication, handle the actual creation flow here, and let the handlers invoke this with the necessary data
  const createBulkMessages = React.useCallback(
    async (data: BulkCreateMessageRequest) => {
      await bulkCreateItems({ data });
      return refreshLists();
    },
    [bulkCreateItems, refreshLists]
  );

  const handleSubmit: RecordSubmitHandler<BulkCreateLetterMessageFormData> = React.useCallback(
    async ({ values }) => {
      const data = await mapBulkCreateLetterFormToRequest(
        values,
        recordObjects
      );

      try {
        const modifiedData: BulkCreateMessageRequest = {
          ...data
        };

        addToast({
          description: (
            <>
              Your <b>letter</b> drafts are being created and will be saved to
              the outbox
            </>
          )
        });

        return createBulkMessages(modifiedData);
      } catch (error) {
        invariant(error instanceof Error, 'err should be Error');

        errorDialog.open({
          message: error.message
        });
      }
    },
    [recordObjects, createBulkMessages, addToast, errorDialog]
  );

  const handlePreview = React.useCallback(
    (forms) => {
      const formData: BulkCreateLetterMessageFormData = extractValuesFromForms(
        forms
      );

      openPreviewBulkLettersDialog({ data: { formData, recordObjects } });
    },
    [openPreviewBulkLettersDialog, recordObjects]
  );

  return (
    <RecordDialog
      title='Create letters'
      size='xl'
      data={initialValues}
      blockProps={blockProps}
      content={content}
      onClose={onClose}
      handleSubmit={handleSubmit}
      getHandlers={getHandlers}
      submitLabel='Create letters'
      ButtonGroup={MessageCreateDialogButtonGroup}
      buttonGroupProps={
        {
          handlePreview,
          hasButtonGroupActions: false
        } as MessageCreateDialogButtonGroupProps
      }
    />
  );
}
