import { useMutation } from 'react-query';
import { api } from 'utils/api/api-client';
import { mapBulkEditDataToPayload } from 'src/modules/bulk-edit/mappers/map-bulk-edit-data-to-payload';
import { Statement } from '../types';

// based on the useBulkEdit hook
export function useBulkEditStatement() {
  return useMutation(
    async ({
      selectedIds,
      data
    }: {
      selectedIds: string[];
      data: Partial<Statement>;
    }) => {
      const response = await api.post(
        'statement/bulk-update',
        mapBulkEditDataToPayload(selectedIds, data)
      );

      return response.data;
    },
    {
      mutationKey: ['bulk-update-statement']
    }
  );
}
