import React from 'react';
import { OutlineButton } from '@rexlabs/button';
import { StyleSheet, useStyles } from '@rexlabs/styling';
import { InputButtonProps } from 'view/components/@luna/button-group-input';

export type Intent = 'secondary' | 'success' | 'danger' | 'warning';

const defaultStyles = StyleSheet({
  button: {
    borderColor: ({ token }) => token('palette.grey.600'),
    width: '100%',
    '&:focus:enabled': {
      borderColor: ({ token }) => token('palette.grey.600'),
      boxShadow: 'none'
    },
    '&:hover:enabled': {
      borderColor: ({ token }) => token('palette.grey.600')
    },
    '&:active:enabled': {
      borderColor: ({ token }) => token('palette.grey.600')
    }
  },
  buttonFirst: {
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    borderRightWidth: 0
  },
  buttonLast: {
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0
  },
  buttonMiddle: {
    borderRadius: 0,
    borderRightWidth: 0
  },
  secondary: {
    background: ({ token }) => token('color.secondary.idle.contrast')
  },
  success: {
    background: ({ token }) => token('color.success.idle.contrast')
  },
  danger: {
    background: ({ token }) => token('color.danger.idle.contrast')
  },
  warning: {
    background: ({ token }) => token('color.warning.idle.contrast')
  }
});

export function RoomConditionButton({
  Icon,
  children,
  isFirst,
  isMiddle,
  isLast,
  active,
  item
}: InputButtonProps) {
  const s = useStyles(defaultStyles);

  return (
    <OutlineButton
      IconLeft={Icon}
      {...s({
        button: true,
        buttonFirst: isFirst,
        buttonLast: isLast,
        buttonMiddle: isMiddle,
        secondary: active && item.value.id === 'none',
        success: active && item.value.id === 'good',
        danger: active && item.value.id === 'poor',
        warning: active && item.value.id === 'fair'
      })}
      aria-pressed={active}
    >
      {children}
    </OutlineButton>
  );
}
