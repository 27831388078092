import { StyleSheet } from '@rexlabs/styling';

export const COMPONENTS = {
  // HACK: these should be fixed in vivid, just temporary fixes in Alfred
  // to play around with solutions!
  TextInput: StyleSheet({
    prefix: {
      color: ({ token }) => token('palette.grey.600')
    }
  }),

  RenderLoading: StyleSheet({
    topLevelContainer: {
      height: 'auto !important'
    },

    wrapLoadingView: {
      height: 'auto !important'
    },

    container: {
      position: 'relative'
    }
  }),

  // Fix table styles within cards and dialogs
  Table: StyleSheet({
    container: {
      '& tbody > tr:last-child': {
        border: '0 none',
        boxShadow: 'none'
      }
    }
  })
};
