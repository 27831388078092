import * as React from 'react';
import { useQueryClient } from 'react-query';

import { useConfirmationDialog } from '@rexlabs/dialog';
import { useModelActions } from '@rexlabs/model-generator';

import { getRecordLabel } from 'utils/records/get-record-label';

import { getRecordObject } from 'src/modules/common/utils/Records/get-record-object';
import { SingleActionDeclaration } from 'src/modules/common/actions/types/action-declaration-types';
import { useRecordDeletedToast } from 'src/modules/common/toasts/hooks/use-record-deleted-toast';

import { notesModel } from '../../models/notes-model';
import { Note } from '../../types/Note';

export function useGetDeleteNoteAction() {
  const { open: openDeleteDialog } = useConfirmationDialog();
  const { trashItem, refreshLists } = useModelActions(notesModel);
  const addToast = useRecordDeletedToast(notesModel);
  const queryClient = useQueryClient();

  return (note: Note): SingleActionDeclaration => ({
    label: 'Delete note',
    intent: 'danger',
    handleAction: () => {
      openDeleteDialog({
        onConfirm: async () => {
          await trashItem({ id: note.id });
          queryClient.invalidateQueries(['notes']);
          addToast(note);
          return refreshLists();
        },
        destructive: true,
        title: 'Delete note',
        confirmText: 'Delete note',
        message: (
          <div>
            Are you sure you wish to delete the note{' '}
            <strong>{getRecordLabel(getRecordObject(note, notesModel))}</strong>
          </div>
        )
      });
    }
  });
}
