import { Generator } from 'data/models/generator';
import { auditableConfig } from 'src/modules/common/auditable/auditable-config';
import { api } from 'utils/api/api-client';
import _ from 'lodash';
import { CustomAction } from '@rexlabs/model-generator';
import { PropertyAreaType } from '../types/PropertyAreaType';

const config = {
  entities: {
    related: {
      summary: {
        include: 'summary'
      },
      items: {
        include: 'items'
      },
      ...auditableConfig
    }
  }
};

const actionCreators = {
  resetItems: {
    request: ({ propertyAreaTypeId, args }) =>
      api.post(
        `/properties/property-area-types/${propertyAreaTypeId}/reset`,
        args
      ),

    reduce: {
      initial: _.identity,
      success: _.identity,
      failure: _.identity
    }
  } as CustomAction<
    { propertyAreaTypeId: string; args: { include: string } },
    any
  >,
  duplicateItem: {
    request: ({ propertyAreaTypeId }) =>
      api.post(
        `/properties/property-area-types/${propertyAreaTypeId}/duplicate`
      ),

    reduce: {
      initial: _.identity,
      success: _.identity,
      failure: _.identity
    }
  } as CustomAction<{ propertyAreaTypeId: string }, any>
};

export const propertyAreaTypesModel = new Generator<
  PropertyAreaType,
  typeof actionCreators
>('properties/property-area-types', config).createEntityModel({
  actionCreators
});
