import React, { useMemo } from 'react';

import {
  TitleBlock,
  TitleBlockProps
} from 'view/components/cards/title/title-block';

import { RecordObject, RecordType } from 'data/models/types';
import { ManagementAgreementIcon } from 'view/components/icons/management-agreement';

import { getRecordTitle } from 'utils/records/get-record-title';
import { titleBlockStatusTag } from 'src/modules/common/components/status-tag';
import { propertyOwnershipStatusMap } from 'src/modules/property-ownerships/maps/property-ownership-maps';
import { usePropertyOwnershipActions } from 'src/modules/property-ownerships/actions/use-property-ownership-actions';
import { PropertyOwnership } from 'src/modules/property-ownerships/types/property-ownership-types';
import { useTranslation } from 'react-i18next';

const type: RecordType = 'property_ownership';

type PropertyOwnershipTitleBlockProps = Partial<TitleBlockProps> & {
  propertyOwnership: PropertyOwnership;
};

export function PropertyOwnershipTitleBlock({
  propertyOwnership,
  ...props
}: PropertyOwnershipTitleBlockProps) {
  const { record_reference, status } = propertyOwnership;

  const getPropertyOwnershipActions = usePropertyOwnershipActions();

  const tags: TitleBlockProps['tags'] = [
    titleBlockStatusTag(status, propertyOwnershipStatusMap)
  ];

  const title = getRecordTitle({
    type,
    object: propertyOwnership
  } as RecordObject);

  const actions = useMemo(
    () => getPropertyOwnershipActions(propertyOwnership),
    [propertyOwnership]
  );

  const { t } = useTranslation();

  return (
    <TitleBlock
      title={title}
      // Custom type just for the property-ownership screen
      type={
        t('property-ownerships.management-agreement.label.singular') as string
      }
      tags={tags}
      reference={record_reference}
      actions={actions}
      Icon={ManagementAgreementIcon}
      {...props}
    />
  );
}
