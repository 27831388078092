import React from 'react';
import { BlockConfig } from 'view/components/record-screen/types';
import { Column, Grid } from '@rexlabs/grid';
import { Field } from '@rexlabs/form';
import { TextInput } from '@rexlabs/text-input';
import { PasswordInput } from '@rexlabs/password-input';

export const changeEmailBlock: BlockConfig = {
  id: 'changeEmail',
  title: 'Change Email',
  validate: {
    definitions: {
      email: { rules: 'required|email' },
      password: { rules: 'required' }
    }
  },
  Edit: () => (
    <Grid>
      <Column width={12}>
        Please enter your new email address and your current password. A
        confirmation link will be sent to the new email address you enter. When
        you receive the email, click the link to confirm the change.
      </Column>
      <Column width={12}>
        <Field name='email' label='Email' Input={TextInput} />
      </Column>
      <Column width={12}>
        <Field name='password' label='Password' Input={PasswordInput} />
      </Column>
    </Grid>
  )
};
