import React from 'react';
import { EmptySection } from './base';

export function EmptyProperties() {
  return (
    <EmptySection
      headline='Relate a property to this record'
      buttonLabel='View properties'
      linkProps={{ query: { tab: 'properties' } }}
    >
      Choose from an existing property or create a new property for this record.
    </EmptySection>
  );
}
