import React from 'react';

import {
  SimpleFileUploadInput,
  SimpleFileUploadInputProps
} from '@rexlabs/file-upload-input';
import { SelectProps } from '@rexlabs/select';

import { AlfredFile } from 'src/modules/common/types/file';

import { useOnFileUploadWithErrorHandling } from 'utils/on-file-select';

export type SimpleFileUploadInputWithErrorHandlingProps = Omit<
  SimpleFileUploadInputProps,
  'onFileSelect'
> & {
  onFileSelectCallback?: (file?: AlfredFile) => void;
};

export const SimpleFileUploadInputWithErrorHandling = (
  props: SelectProps<any> & SimpleFileUploadInputWithErrorHandlingProps
) => {
  // NOTE: Unfortunately, the vivid field component passes the id as the name to the input component.
  // this means that you must name the id the same as the name of the field.
  const { name, onFileSelectCallback } = props;

  const onFileSelectWithErrorHandling = useOnFileUploadWithErrorHandling();

  return (
    <SimpleFileUploadInput
      {...props}
      onFileSelect={async ({ file }) => {
        const res = await onFileSelectWithErrorHandling({
          fieldName: name!,
          file
        });

        onFileSelectCallback?.(res?.data);

        return res;
      }}
    />
  );
};
