import { LineItemActionItemParams } from 'src/modules/financials/types';
import { InvoiceLineItem } from 'src/modules/financials/blocks/trust-journal-entry-line-items';

const defaultInvoiceLineItem: InvoiceLineItem = { category: 'invoice' };

export function useGetAddInvoiceAction() {
  return ({ push }: LineItemActionItemParams) => ({
    label: 'Add invoice',
    onClick: () => push(defaultInvoiceLineItem)
  });
}
