import React from 'react';
import Box from '@rexlabs/box';
import { StyleSheet, keyframes, useStyles } from '@rexlabs/styling';

import LoadingState from 'view/components/states/loading';

const headAnimation = keyframes({
  '0%': { transform: 'translate3d(0, 0, 0)' },
  '10%': { transform: 'translate3d(-.4px, 0, 0)' },
  '30%': { transform: 'translate3d(.4px, 0, 0)' },
  '40%': { transform: 'translate3d(0, 0, 0)' }
});

const eyesAnimation = keyframes({
  '0%': { transform: 'scale(1,1)' },
  '70%': { transform: 'scale(1,1)' },
  '75%': { transform: 'scale(1,0)' },
  '80%': { transform: 'scale(1,1)' },
  '100%': { transform: 'scale(1,1)' }
});

const defaultStyles = StyleSheet({
  container: {
    width: '100%',
    height: '100%',
    fontWeight: ({ token }) => token('typography.weight.bold'),
    fontSize: '2rem',
    background: ({ token }) => token('palette.neutral.50')
  },

  logo: {
    height: '16rem',
    width: 'auto',

    '& #Head': {
      animation: `${headAnimation} 3s infinite ease-in-out`
    },

    '& #Eyes path': {
      transformOrigin: '0 1px',
      animation: `${eyesAnimation} 3s infinite ease-in-out`
    }
  }
});

export function LoadingLayout() {
  const s = useStyles(defaultStyles);
  return (
    <Box
      {...s('container')}
      flexDirection='column'
      alignItems='center'
      justifyContent='center'
    >
      <LoadingState>Rex Property Management is getting ready...</LoadingState>
    </Box>
  );
}
