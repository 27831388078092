import { RecordData } from 'utils/types';
import { TrustLedger } from 'src/modules/trust-ledgers/types/trust-ledger-types';
import { api } from './api-client';

export async function getTrustLedger(
  record: RecordData,
  bankAccountId: string
): Promise<TrustLedger | undefined> {
  const request = await api.get(
    `/financials/trust-ledgers?q=object_id.eq(${record.id}),bank_account_id.eq(${bankAccountId})&include=financial_summary`
  );

  return request?.data ? request?.data?.[0] : undefined;
}
