import React from 'react';
import { PropertyTenancy } from 'src/modules/property-tenancies/types/property-tenancy-types';
import { SecondaryList } from '../components/secondary-list';
import { EmptyPropertyTenancy } from '../empty/empty-property-tenancy';
import { useGetTenancyObjects } from '../utils';
import { TenancyEntries } from './tenancy-entries';

export interface PropertyTenancySectionProps {
  activePropertyTenancy: PropertyTenancy | 'empty';
  incomingPropertyTenancy: PropertyTenancy | 'empty';
  maxListLength: number;
}

export function PropertyTenancySection({
  activePropertyTenancy: active,
  incomingPropertyTenancy: incoming,
  maxListLength
}: PropertyTenancySectionProps) {
  const getTenancyObjects = useGetTenancyObjects();

  const noActiveTenancy: boolean = !active || active === 'empty';
  const noIncomingTenancy: boolean = !incoming || incoming === 'empty';

  if (noActiveTenancy && noIncomingTenancy) {
    return <EmptyPropertyTenancy />;
  }

  return (
    <SecondaryList>
      {active !== 'empty' && active && (
        <TenancyEntries
          {...getTenancyObjects(active as PropertyTenancy)}
          headline='Active'
          maxListLength={maxListLength}
          status='active'
        />
      )}

      {incoming !== 'empty' && incoming && (
        <TenancyEntries
          {...getTenancyObjects(incoming as PropertyTenancy)}
          headline='Pending'
          maxListLength={maxListLength}
          status='pending'
        />
      )}
    </SecondaryList>
  );
}
