import {
  NormalisedItem,
  Select,
  SelectProps,
  useFreeformDialog
} from '@rexlabs/select';
import * as React from 'react';
import { PropertyArea } from 'src/modules/property-areas/property-areas/types/PropertyArea';
import { Property } from 'src/modules/properties/types/property-types';
import { useQuery } from 'react-query';
import { api } from 'utils/api/api-client';
import { PropertyAreaRecordDialog } from 'src/modules/property-areas/property-areas/dialogs/property-area-dialog';
import { useCreatePropertyAreaSubmitHandler } from 'src/modules/property-areas/property-areas/hooks/use-create-property-area-submit-handler';
import { FreeformDialogProps } from '@rexlabs/select/lib/types/hooks/external/use-freeform-dialog';

const normaliser = (item: PropertyArea | string): NormalisedItem => {
  if (typeof item === 'string') {
    return {
      id: item,
      label: item
    };
  }

  return {
    id: item.id,
    label: item.name
  };
};

function FreeformPropertyAreaDialog({
  selectItem,
  inputValue,
  property,
  ...props
}: FreeformDialogProps<PropertyArea> & { property: Property }) {
  const onCreateDialog = React.useCallback(
    (data) => {
      selectItem(data);
    },
    [selectItem]
  );
  const getPropertyAreaCreateSubmit = useCreatePropertyAreaSubmitHandler();

  return (
    <PropertyAreaRecordDialog
      title={'Add area'}
      {...props}
      initialValues={{
        name: inputValue
      }}
      onSubmit={getPropertyAreaCreateSubmit({
        property,
        onCreate: onCreateDialog
      })}
    />
  );
}

export function PropertyAreaSelect({
  property,
  ...props
}: SelectProps<PropertyArea> & { property: Property }) {
  const { data } = useQuery(
    ['property-areas', property.id],
    () =>
      api.get('/properties/property-areas', {
        per_page: 50,
        q: `property_id.eq(${property.id})`
      }),
    {
      staleTime: 60 * 1000,
      select: (data) => data.data as PropertyArea[]
    }
  );

  const {
    getSelectProps: useFreeformDialogGetSelectProps
  } = useFreeformDialog<PropertyArea>({
    Dialog: (props) => (
      <FreeformPropertyAreaDialog {...props} property={property} />
    )
  });

  return (
    <Select
      normaliser={normaliser}
      {...useFreeformDialogGetSelectProps()}
      items={data || []}
      {...props}
    />
  );
}
