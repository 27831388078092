import * as React from 'react';

import { Field, HiddenField } from '@rexlabs/form';
import { OutlineIconButton } from '@rexlabs/button';
import { StyleSheet, useStyles } from '@rexlabs/styling';
import { useDialog } from '@rexlabs/dialog';

import {
  MessageTemplateSelect,
  MessageTemplateSelectProps
} from 'src/modules/communications/common/components/selects/message-template-select';
import { EditTemplateMessageRecordDialog } from 'src/modules/communications/messages/dialogs/edit-template-message-record-dialog';
import { MessageTemplate } from 'src/modules/communications/message-templates/types/MessageTemplate';

import { getUploadedFileMeta, parseUploadedFileMeta } from 'utils/files';

import EditIcon from 'view/components/icons/edit';
import { RecordSubmitHandler } from 'view/components/record-screen/utils';
import { InfoBanner } from 'view/components/@luna/notifications/banner';
import InfoCircleIcon from 'view/components/icons/info';
import { FileUpload } from 'src/modules/common/types/file';
import { getSelectedTemplatedAttachments } from 'src/modules/communications/messages/utils/get-selected-templated-attachments';

const defaultStyles = StyleSheet({
  container: {
    display: 'flex'
  },
  button: {
    marginLeft: ({ token }) => token('spacing.xs')
  },
  banner: {
    marginTop: ({ token }) => token('spacing.xs')
  }
});

type ButtonProps = Omit<React.ComponentProps<typeof OutlineIconButton>, 'Icon'>;

function EditButton(props: ButtonProps) {
  const s = useStyles(defaultStyles);

  return <OutlineIconButton {...s('button')} {...props} Icon={EditIcon} />;
}

interface MessageTemplateSelectWithEditProps
  extends Omit<MessageTemplateSelectProps, 'value'> {
  editDialogTitle: string;
  value: {
    template?: MessageTemplate;
    body?: string;
    subject?: string;
    inline_attachments?: any[];
    linked_files?: FileUpload[];
    templated_attachments: [];
  };
}

export function MessageTemplateSelectWithEdit(
  props: MessageTemplateSelectWithEditProps
) {
  const [selectedTemplate, setSelectedTemplate] = React.useState(
    props?.value?.template
  );

  const s = useStyles(defaultStyles);

  const { value, contextType, editDialogTitle, actions } = props;

  const { open } = useDialog(EditTemplateMessageRecordDialog);

  const content =
    value?.body || value?.template?.channels?.[0]?.plain_text_body || '';
  const subject =
    value?.subject || value?.template?.channels?.[0]?.email_subject || '';
  const attachments = value?.inline_attachments || [];

  const templated_attachments = value?.templated_attachments || [];

  const include_generated_attachments: Record<
    string,
    boolean
  > = templated_attachments.reduce((acc, curr: string) => {
    acc[curr] = true;
    return acc;
  }, {});

  const editTemplateSubmitHandler: RecordSubmitHandler = async ({ values }) => {
    const attachments = await getUploadedFileMeta(values.attachments);

    actions?.setValue?.({
      template: selectedTemplate,
      body: values.content,
      subject: values.subject,
      inline_attachments: attachments || [],
      linked_files: values.linked_files || [],
      templated_attachments: getSelectedTemplatedAttachments(
        values.include_generated_attachments
      )
    });
  };

  return (
    <>
      <div {...s('container')}>
        <Field
          id={`${props.id}.template`}
          name={`${props.id}.template`}
          onChange={({ target }) => {
            const template: MessageTemplate = target['value'] as any;
            actions?.setValue?.({
              template: target.value,
              inlineAttachments: [],
              templated_attachments: template.templated_attachments?.length
                ? template.templated_attachments
                : [],
              body: '',
              subject: ''
            });
            setSelectedTemplate(template);
          }}
          Input={MessageTemplateSelect}
          inputProps={{
            contextType
          }}
          optional={false}
        />
        <EditButton
          isDisabled={!selectedTemplate}
          onClick={() =>
            open({
              title: editDialogTitle,
              handleSubmit: editTemplateSubmitHandler,
              initialValues: {
                content,
                subject,
                attachments: parseUploadedFileMeta(attachments),
                context_type: contextType,
                linked_files: value?.template?.linked_files || [],
                include_generated_attachments: include_generated_attachments
              }
            })
          }
        />
        <HiddenField name={props.id} />
      </div>
      {selectedTemplate && (
        <div {...s('banner')}>
          <InfoBanner
            Icon={InfoCircleIcon}
            description={
              'The message(s) will be saved to the Outbox. Please navigate to the Outbox to send the message(s).'
            }
          />
        </div>
      )}
    </>
  );
}
