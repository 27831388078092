import { SingleActionDeclaration } from 'src/modules/common/actions/types/action-declaration-types';
import { useDialog } from '@rexlabs/dialog';
import { noop } from 'lodash';
import AddIcon from 'view/components/icons/add';
import { useTranslation } from 'react-i18next';
import { CreateLeaseReviewRecordDialog } from '../../dialogs/create-lease-review-record-dialog';
import { LeaseReviewFormData } from '../../types/LeaseReviewFormData';

interface UseGetCreateLeaseReviewActionProps {
  onCreate?: (data) => void;
  isNested?: boolean;
  isFixture?: boolean;
  initialValues?: Partial<LeaseReviewFormData>;
}

export function useGetCreateLeaseReviewAction(
  {
    onCreate,
    isNested,
    initialValues,
    isFixture
  }: UseGetCreateLeaseReviewActionProps = {
    isNested: false,
    isFixture: false,
    onCreate: noop,
    initialValues: {}
  }
) {
  const { open } = useDialog(CreateLeaseReviewRecordDialog, {
    target: isNested ? 'nested-dialogs' : 'dialogs'
  });

  const { t } = useTranslation();

  return (): SingleActionDeclaration => {
    return {
      id: 'create-lease-review',
      intent: 'primary',
      group: 'todo',
      label: t('tasks.lease-review.create.singular.label') as string,
      Icon: AddIcon,
      handleAction: async () => {
        open({
          isFixture,
          onCreate,
          initialValues: {
            ...initialValues,
            priority: {
              id: 'normal',
              label: 'Normal'
            },
            details: {
              status: {
                id: 'not_started',
                label: 'Not started'
              }
            }
          }
        });
      }
    };
  };
}
