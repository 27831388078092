import * as React from 'react';

import { transformSingleActionsToRegularActionMenuItems } from 'utils/actions/transforms';

import {
  BlockConfig,
  BlockViewProps
} from 'view/components/record-screen/types';
import { ActionButtons } from 'view/components/action-buttons';

import { NotesList } from '../components/notes-list';
import { useGetCreateNoteAction } from '../hooks/use-get-create-note-action';
import { useNoteQuery } from '../hooks/use-note-query';
import { ParentRecordDataType } from '../types/ParentRecordDataType';
import { useGetNoteActions } from '../hooks/use-get-note-actions';
import { useGetNoteListLinkProps } from '../hooks/use-get-note-list-link-props';
import { NotesListEmptyState } from '../components/notes-list-empty-state';

function NotesTableBlock({
  data,
  blockProps
}: BlockViewProps<ParentRecordDataType>) {
  const noteQuery = useNoteQuery(data!);

  const getNoteActions = useGetNoteActions();
  const getNoteListLinkProps = useGetNoteListLinkProps();

  const EmptyState = React.useCallback(
    () => (
      <NotesListEmptyState
        recordType={data?.__record_type}
        record={data}
        hideAddNoteToField={blockProps?.hideAddNoteToField}
      />
    ),
    []
  );
  return (
    <NotesList
      getQuery={noteQuery ? () => noteQuery : undefined}
      Empty={EmptyState}
      getActionMenuItems={({ item }) =>
        getNoteActions(item, blockProps?.hideAddNoteToField)
      }
      getRowLinkProps={getNoteListLinkProps}
    />
  );
}

export const noteTableBlock: BlockConfig<ParentRecordDataType> = {
  id: 'notes',
  title: 'Notes',
  // NOTE: isEditable is only used when rendering the block in the record screens, and is used to show the edit button.
  // We don't want to show the edit button for the notes block, so we return false.
  // If the render screen changes, we may need to update this, but for now, its making sure we have the one block for both the dialog and record screen.
  isEditable: () => false,
  View: NotesTableBlock,
  Edit: NotesTableBlock,
  Actions: ({ data }) => {
    const getCreateNoteAction = useGetCreateNoteAction(data?.__record_type);

    return (
      <ActionButtons
        actions={transformSingleActionsToRegularActionMenuItems([
          getCreateNoteAction({ attached: data })
        ])}
      />
    );
  }
};
