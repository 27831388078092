export function createDefered() {
  const deferred: Record<'resolve' | 'reject' | 'promise', any> = {
    reject: null,
    resolve: null,
    promise: null
  };

  const promise = new Promise((resolve, reject) => {
    deferred.resolve = resolve;
    deferred.reject = reject;
  });

  deferred.promise = promise;

  return deferred;
}
