import React from 'react';
import { CellProps } from '@rexlabs/table';
import { TwoTierCell } from 'view/components/cells/two-tier-cell';

export function SupplierComplianceSummaryCell({ value }: CellProps) {
  return (
    <TwoTierCell
      text={value?.summary ?? '--'}
      subtext={
        value?.details?.supplier?.display_name ||
        value?.details?.compliance_entry?.supplier?.display_name
      }
    />
  );
}
