import React, { useCallback } from 'react';
import { useDialog } from '@rexlabs/dialog';

import { PropertyComplianceCategory } from 'data/models/entities/settings/property-compliance-categories';

import { RecordTable } from 'view/components/table';

import { complianceCategorySettingsColumns } from 'src/modules/compliance/common/utils/compliance-category-settings-columns';
import { propertyComplianceCategoriesQuery } from '../../common/compliance-entries/data/queries';
import { EditComplianceCategoryDialog } from '../dialogs/edit-compliance-category-dialog';
import { PropertyComplianceCategoryTableEmptyState } from './property-compliance-category-table-empty-state';

export function PropertyComplianceCategorySettingsTable() {
  const { open: openEditComplianceCategoryDialog } = useDialog(
    EditComplianceCategoryDialog
  );

  const getQuery = useCallback(() => propertyComplianceCategoriesQuery, []);

  return (
    <RecordTable
      id='property-compliance-categories'
      columns={complianceCategorySettingsColumns}
      getQuery={getQuery}
      Empty={PropertyComplianceCategoryTableEmptyState}
      emptyWithoutTable
      withPagination={true}
      getActionMenuItems={({
        item
      }: {
        item: PropertyComplianceCategory;
        entity?: any;
      }) => {
        return [
          {
            label: 'Edit',
            onClick: () =>
              openEditComplianceCategoryDialog({ categoryId: item.id })
          }
          // The delete action has been removed since the category is generally connected to requirements and properties
          // If the user requires it we can add an archive or soft delete but in the interests of
          // not solving problems that don't exist, we'll leave it out for now.
          // note that I'm removing the related code, check the git/PR history if you need it
        ];
      }}
    />
  );
}
