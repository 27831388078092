import React from 'react';
import Box from '@rexlabs/box';
import { useToken, useStyles, StyleSheet, text } from '@rexlabs/styling';
import { pluralize } from 'utils/formatters';
import {
  TenancyRelatedContact,
  Tenancy as TenancyType
} from 'data/models/entities/tenancies';
import { PropertyTenancy as PropertyTenancyType } from 'src/modules/property-tenancies/types/property-tenancy-types';
import { useFeatureFlags } from 'view/components/@luna/feature-flags';
import { FLAGS } from 'utils/feature-flags';
import { RecordFlag } from '../../record-flags/record-flag';
import { ContactFlag } from '../../record-flags/contact-flag';

import { cap, hasMoreThan } from '../utils';
import { EntryList, RecordStatus } from '../components/entry-list';
import { EntryLink } from '../components/entry-link';
import { PropertyTenancyEntryLink } from '../components/property-tenancy-entry-link';
import { ViewLink, ViewRecordLink } from '../components/view-link';

interface TenancyEntriesProps {
  headline?: React.ReactNode;
  status?: RecordStatus;
  maxListLength: number;
  propertyTenancy?: PropertyTenancyType;
  tenancy?: TenancyType;
  tenancyRelatedContacts: TenancyRelatedContact[];
}

const defaultStyles = StyleSheet({
  link: {
    ...text.styles({
      fallback: 'small.semibold'
    })
  }
});

export function TenancyEntries({
  headline,
  status,
  maxListLength,
  propertyTenancy,
  tenancy,
  tenancyRelatedContacts
}: TenancyEntriesProps) {
  const s = useStyles(defaultStyles);
  const token = useToken();
  const { hasFeature } = useFeatureFlags();

  const tenancyRelatedContactsListLength =
    maxListLength - Number(!!tenancy) - Number(!!propertyTenancy);

  const hasGuarantorAndOccupierUpdates = hasFeature(
    FLAGS.GUARANTOR_AND_OCCUPIER_UPDATES
  );

  return (
    <EntryList status={status} headline={headline}>
      {tenancy && (
        <EntryLink type='tenancy' id={tenancy.id}>
          <RecordFlag record={tenancy} recordType='tenancy' />
        </EntryLink>
      )}

      {propertyTenancy && (
        <PropertyTenancyEntryLink propertyTenancy={propertyTenancy} />
      )}

      {cap(tenancyRelatedContacts, tenancyRelatedContactsListLength).map(
        (tenancyRelatedContact) => (
          <EntryLink
            key={tenancyRelatedContact.contact.id}
            type='contact'
            id={tenancyRelatedContact.contact.id}
          >
            <ContactFlag
              contact={tenancyRelatedContact.contact}
              description={
                hasGuarantorAndOccupierUpdates
                  ? tenancyRelatedContact.role.label
                  : 'Tenant'
              }
            />
          </EntryLink>
        )
      )}

      {hasMoreThan(
        tenancyRelatedContacts,
        tenancyRelatedContactsListLength
      ) && (
        <Box mt={token('spacing.xs')}>
          {tenancy ? (
            <ViewRecordLink
              {...s('link')}
              type='tenancy'
              id={tenancy?.id}
              tab='tenants'
            >
              View {tenancyRelatedContacts.length} {status}{' '}
              {pluralize('tenant', tenancyRelatedContacts.length)}
            </ViewRecordLink>
          ) : (
            <div style={{ margin: '12px 0 0 0', fontSize: '1.2rem' }}>
              <ViewLink query={{ tab: 'overview' }}>
                View {tenancyRelatedContacts.length} {status}{' '}
                {pluralize('tenant', tenancyRelatedContacts.length)}
              </ViewLink>
            </div>
          )}
        </Box>
      )}
    </EntryList>
  );
}
