import { api } from 'utils/api/api-client';
import { BatchReceiptingItem } from '../types';
import { getTrustJournalEntryPayload } from '../utils/get-trust-journal-entry-from-bank-transaction';

function getTrustJournalEntryRequest({ item }: { item: BatchReceiptingItem }) {
  return {
    id: `trust_journal_entry-${item.id}`,
    method: 'POST',
    path: `/api/v1/financials/trust-journal-entries`,
    json: getTrustJournalEntryPayload(item)
  };
}

function createTrustJournalEntryInPipeline(id: string, receiptData) {
  return {
    id: `trust_journal_entry-${id}`,
    method: 'POST',
    path: `/api/v1/financials/trust-journal-entries`,
    json: { receipt: receiptData }
  };
}

function getMatchBankStatementRequest({
  parentId,
  bankStatementTransactionId
}: {
  parentId: string;
  bankStatementTransactionId: string;
}) {
  return {
    id: `match_bank_statement-${parentId}`,
    method: 'PUT',
    path: `/api/v1/financials/bank-statement-transactions/${bankStatementTransactionId}/matched-trust-transactions`,
    json: {
      trust_journal_entries: [
        { id: `{{$.trust_journal_entry-${parentId}.id}}` }
      ]
    }
  };
}

export function useCreateTrustJournalEntryAndLinkTransaction() {
  const createTrustJournalEntryAndLinkTransaction = async (
    item: BatchReceiptingItem
  ) => {
    return api.post('request-pipelines', {
      requests: [
        getTrustJournalEntryRequest({ item }),
        getMatchBankStatementRequest({
          parentId: item.id,
          bankStatementTransactionId: item.id
        })
      ]
    });
  };

  const createTjeAndLinkToTransactionInPipeline = async (
    bankStatementTransactionId: string,
    receiptData
  ) => {
    return api.post('request-pipelines', {
      requests: [
        createTrustJournalEntryInPipeline(
          bankStatementTransactionId,
          receiptData
        ),
        getMatchBankStatementRequest({
          parentId: bankStatementTransactionId,
          bankStatementTransactionId
        })
      ]
    });
  };

  const batchCreateTrustJournalEntriesAndLinkTransactions = async () => {
    return api.post('financials/bank-statement-transactions/receipt-matched', {
      selection: {
        q: 'matched_receipt.eq(true)'
      }
    });
  };

  return {
    createTrustJournalEntryAndLinkTransaction,
    createTjeAndLinkToTransactionInPipeline,
    batchCreateTrustJournalEntriesAndLinkTransactions
  };
}
