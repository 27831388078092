import React from 'react';
import dayjs from 'dayjs';
import { DateInput } from 'view/components/@luna/inputs/date-input/date-input';
import { getUTCTimestamp } from 'utils/dates/dates';
import { formatDate } from 'utils/dates/format';
import { Operator as QuriOperator } from '@rexlabs/quri';
import { DatePickerOnChangeEvent } from 'view/components/@luna/inputs/date-input';

type Operator = { value: QuriOperator };
interface FakeEvent {
  target: {
    value: {
      value: string | null;
      label: string | null;
    };
  };
}

interface TimestampDateInputProps {
  op: Operator;
  value: { value: string };
  onChange: (e: FakeEvent) => void;
  onBlur: (e: FakeEvent) => void;
}

function formatValue(op: Operator, value: string | null) {
  if (!value) {
    return null;
  }

  const date = dayjs(value);
  // Workaround:
  // BE expects a datetime, so we need to set this to the end of the day for greater than
  // to correctly omit records that are created on that day. Note that otherwise it defaults to
  // the very start of the day.
  const dateEnd = dayjs(value)
    .set('hour', 23)
    .set('minute', 59)
    .set('second', 59)
    .set('millisecond', 59);

  if (op.value === 'gt') {
    return getUTCTimestamp(dateEnd);
  }
  return getUTCTimestamp(date);
}

const getUTCFormattedEvent = ({
  event,
  op
}: {
  event: DatePickerOnChangeEvent;
  op: Operator;
}) => {
  return {
    ...event,
    target: {
      ...event.target,
      value: {
        value: formatValue(op, event.target.value),
        // note that the label isn't used by the date input, but it's shown in the table toolbar
        label: event.target.value ? formatDate(event.target.value) : null
      }
    }
  };
};

export function TimestampDateInput({
  op,
  value,
  onChange,
  onBlur,
  ...props
}: TimestampDateInputProps) {
  return (
    <DateInput
      // this takes the UTC timestamp and magically converts it to the user's timezone to be displayed in the input.
      // if something goes wrong with displayed date, this may be why
      value={value?.value ? dayjs(value.value).tz().format('YYYY-MM-DD') : null}
      onChange={(e) => {
        onChange(getUTCFormattedEvent({ event: e, op }));
      }}
      onBlur={(e) => {
        onBlur(getUTCFormattedEvent({ event: e, op }));
      }}
      {...props}
    />
  );
}
