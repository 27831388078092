import React from 'react';
import {
  FolioCommissionTemplateStructure,
  FolioCommissionTemplateTier
} from '../types/FolioCommissionTemplate';
import { ViewTemplateTierLineItem } from './view-template-tier-line-item';

interface ViewSupplierCommissionTierParams {
  tiers: FolioCommissionTemplateTier[];
  templateStructureType: Extract<
    FolioCommissionTemplateStructure,
    'progressive' | 'tiered'
  >;
}

export function ViewSupplierCommissionTiers({
  tiers,
  templateStructureType
}: ViewSupplierCommissionTierParams) {
  return (
    <>
      {tiers.map((tier, index) => (
        <ViewTemplateTierLineItem
          tier={tier}
          templateStructureType={templateStructureType}
          key={index}
        />
      ))}
    </>
  );
}
