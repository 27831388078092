import React, {
  createContext,
  ReactElement,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';
import { useQuery } from 'react-query';
import flagsmith from 'flagsmith';

type FeatureFlagsState = {
  uuid: number;
  hasFeature: typeof flagsmith.hasFeature;
  isFeatureDisabled: (key: string) => boolean;
  getTrait: typeof flagsmith.getTrait;
  setTrait: typeof flagsmith.setTrait;
  setTraits: typeof flagsmith.setTraits;
};

const counter = 0;
export const FeatureFlagsContext = createContext<FeatureFlagsState>({
  uuid: counter,
  hasFeature: flagsmith.hasFeature,
  isFeatureDisabled: (key: string) => !flagsmith.hasFeature(key),
  getTrait: flagsmith.getTrait,
  setTrait: flagsmith.setTrait,
  setTraits: flagsmith.setTraits
});

interface FeatureFlagsProviderProps {
  environmentId?: string;
  cacheFlags?: boolean;
  children?: ReactElement;
}

export function FeatureFlagsProvider({
  environmentId,
  cacheFlags = true,
  ...props
}: FeatureFlagsProviderProps) {
  const [uuid, setUuid] = useState(counter);

  useEffect(() => {
    if (environmentId) {
      flagsmith.init({
        defaultFlags: {},
        preventFetch: true,
        cacheFlags,
        environmentID: environmentId,
        onChange: (_, params) => {
          if (params.flagsChanged || params.traitsChanged) {
            setUuid(new Date().getTime());
          }
        }
      });
    }
  }, [environmentId, cacheFlags]);

  useQuery(['flagsmith'], () => flagsmith.getFlags(), {
    // refetch every 5 mins
    refetchInterval: 5 * 1000 * 60
  });

  return (
    <FeatureFlagsContext.Provider
      {...props}
      value={{
        uuid,
        hasFeature: flagsmith.hasFeature,
        isFeatureDisabled: (key: string) => !flagsmith.hasFeature(key),
        getTrait: flagsmith.getTrait,
        setTrait: flagsmith.setTrait,
        setTraits: flagsmith.setTraits
      }}
    />
  );
}

export function useFeatureFlags(): FeatureFlagsState {
  return useContext(FeatureFlagsContext);
}

export function useTrait<Value extends string | number | boolean>(
  key: string
): [Value, (value: Value) => Promise<any>] {
  const { uuid, getTrait, setTrait } = useFeatureFlags();

  return useMemo(() => {
    const value = getTrait(key) as Value;
    const setValue = (value) => setTrait(key, value);
    return [value, setValue];
  }, [key, uuid]);
}

export const identify = flagsmith.identify;
