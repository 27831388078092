import * as React from 'react';
import { ValueListItem } from 'utils/normaliser/value-list-item';
import { Dropdown, DropdownProps } from 'view/components/@luna/dropdown';
import { useApiValueList } from 'view/hooks/api/use-api-value-list';
import { StatusTagMap } from 'src/modules/common/components/status-tag';
import { InspectionStatus } from 'src/modules/tasks/inspections/types/InspectionStatus';

const intentMap: StatusTagMap<InspectionStatus> = {
  not_started: 'neutral',
  notice_issued: 'average',
  inspection_completed: 'average',
  report_in_progress: 'average',
  report_completed: 'average',
  report_sent: 'good'
};

type TaskStatusMenuProps = Omit<
  DropdownProps<ValueListItem>,
  'normaliser' | 'items'
>;

export function TaskStatusMenu(props: TaskStatusMenuProps) {
  const { data } = useApiValueList('task_inspection_status');

  return (
    <Dropdown<ValueListItem>
      items={data}
      normaliser={({ id, label }) => ({ id, label, intent: intentMap[id] })}
      {...props}
    />
  );
}
