import { Task } from 'src/modules/tasks/common/types/Task';
import { SearchResultItem } from 'utils/api/get-search-results';

interface MapTaskApiToLinkTaskFormParams {
  linkedTask: Task<any>;
  parentTask: Task<any>;
  thisTask?: SearchResultItem | null;
}

export function mapTaskApiToLinkTaskForm({
  linkedTask,
  parentTask,
  thisTask
}: MapTaskApiToLinkTaskFormParams) {
  const relation = parentTask?.task_links?.data?.find(
    (taskLink) => taskLink?.child_task?.id === linkedTask.id
  )?.relation;

  return {
    relation: relation,
    this_task: thisTask,
    linked_task: {
      label: linkedTask.summary,
      id: linkedTask.id,
      type: 'task',
      record: linkedTask
    }
  };
}
