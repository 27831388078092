import React from 'react';

import { GlobalFilter } from '@rexlabs/table/lib/types/context';
import { query } from '@rexlabs/model-generator';

import { FinancialPeriod } from 'src/modules/statements/types';
import { Contact } from 'src/modules/contacts/types/contact-types';

import { Ownership } from 'data/models/entities/ownerships';

import { Columns, RecordTable } from 'view/components/table';

import { transformActionDeclarationsToActionMenuItems } from 'utils/actions/transforms';

import { OwnershipTaxTransaction } from '../types/ownership-tax-transaction';
import { ownershipTaxTransactionsModel } from '../models/ownership-tax-transactions-model';
import { useGetVoidTaxAdjustmentAction } from '../hooks/use-get-void-tax-adjustment-action';
import { useGetEditTaxAdjustmentAction } from '../hooks/use-get-edit-tax-adjustment-action';
import { getTransactionValueByTransactionType } from '../utils/get-transaction-value-by-transaction-type';
import { isDateWithinRange } from '../utils/is-date-within-range';
import { TaxTransactionDescriptionCell } from './tax-transaction-description-cell';

const columns: Columns<OwnershipTaxTransaction> = [
  {
    id: 'date_of',
    Header: 'Date',
    type: 'date',
    width: 120
  },
  {
    id: 'amount',
    Header: 'Amount',
    width: 120,
    cellProps: {
      align: 'right'
    },
    accessor: (item) => getTransactionValueByTransactionType(item),
    type: 'currency'
  },
  {
    id: 'description',
    Header: 'Description',
    accessor: (row) => row,
    Cell: TaxTransactionDescriptionCell
  }
];

const ownershipTaxTransactionQuery = query`{
    ${ownershipTaxTransactionsModel} {
        id
        date_of
        amount
        description
        contact
        ownership
        source {
            payment_information
        }
    } 
}`;

interface TaxPaymentsAndWithholdingsTable {
  financialYear: FinancialPeriod;
  ownership: Ownership;
  contact: Contact;
}

export function TaxPaymentsAndWithholdingsTable({
  financialYear,
  ownership,
  contact
}: TaxPaymentsAndWithholdingsTable) {
  const getEditTaxAdjustmentAction = useGetEditTaxAdjustmentAction();
  const getVoidTaxAdjustmentAction = useGetVoidTaxAdjustmentAction();

  const getActionMenuItems = ({ item }: { item: OwnershipTaxTransaction }) => {
    const isWithinCurrentFinancialYear = isDateWithinRange({
      startDate: financialYear.startDate,
      endDate: financialYear.endDate
    });

    if (
      isWithinCurrentFinancialYear &&
      item?.type.id === 'manual_tax_adjustment' &&
      item.voided_at === null
    ) {
      return transformActionDeclarationsToActionMenuItems([
        getEditTaxAdjustmentAction(item),
        getVoidTaxAdjustmentAction(item)
      ]);
    } else {
      return [];
    }
  };

  return (
    <RecordTable
      id='ownership-tax-transactions-table'
      columns={columns}
      getActionMenuItems={getActionMenuItems}
      getQuery={() => ownershipTaxTransactionQuery}
      forcedGlobalFilter={[
        { field: 'ownership_id', value: ownership.id, op: 'eq' },
        { field: 'contact_id', value: contact.id, op: 'eq' },
        { field: 'voided_at', value: 'null', op: 'eq' },
        {
          op: 'and',
          value: [
            {
              value: {
                field: 'date_of',
                value: financialYear.startDate,
                op: 'gte'
              }
            },
            {
              value: {
                field: 'date_of',
                value: financialYear.endDate,
                op: 'lte'
              }
            }
          ]
        } as GlobalFilter
      ]}
    />
  );
}
