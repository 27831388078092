import React from 'react';
import { CellProps } from '@rexlabs/table';
import { TwoTierCell } from 'view/components/cells/two-tier-cell';
import { ListTableProps } from 'view/components/table/list-table';
import { getRecordObject } from 'src/modules/common/utils/Records/get-record-object';
import { getRecordLabel } from 'utils/records/get-record-label';

export function LinkSummaryCell({
  value
}: CellProps & Omit<ListTableProps<any>, 'columns'>) {
  const { cellProps, task } = value;

  const recordObject = getRecordObject(task, task?.type?.id);

  const relation =
    task?.task_links?.data?.find?.(
      (taskLink) => taskLink.child_task_id === cellProps?.parentRecordId
    )?.inverse_task_link?.relation?.label || null;

  return (
    <TwoTierCell
      text={getRecordLabel(recordObject) || task?.summary}
      subtext={relation}
    />
  );
}
