import React, { ComponentProps } from 'react';

import { StyleSheet, useStyles } from '@rexlabs/styling';
import { Avatar } from '@rexlabs/avatar';
import { Box } from '@rexlabs/box';
import icons from '@rexlabs/icons';

import { Contact } from 'src/modules/contacts/types/contact-types';
import { Flag } from '../@luna/flag/flag';

export interface ContactFlag extends ComponentProps<'div'> {
  contact: Contact;
  description?: string;
}

const styles = StyleSheet({
  wrapper: {
    position: 'relative'
  },

  wrapperItem: {
    display: 'inline-flex',

    '& + &:before': {
      content: '""',
      position: 'absolute',
      display: 'inline-flex',
      height: 6,
      width: 6,
      borderRadius: '50%',
      background: ({ token }) => token('palette.grey.400'),
      top: '50%',
      transform: 'translateY(-50%)'
    }
  }
});

const { Mobile } = icons;

export function ContactFlag({ contact, description }: ContactFlag) {
  const s = useStyles(styles);
  const phoneNumber = contact?.primary_phone?.phone_number;
  const hasPhoneNumber = phoneNumber != null;

  let headline: string = contact?.display_name ?? '';
  if (headline === '' && 'family_name' in contact) {
    headline = `${contact.given_name} ${contact.family_name}`;
  }

  return (
    <Flag signifier={<Avatar name={headline[0]} />} headline={headline}>
      <Box flexDirection='row' alignItems='center' gap={8} {...s('wrapper')}>
        <Box {...s('wrapperItem')}>
          {description ?? contact.record_reference}
        </Box>

        {hasPhoneNumber && (
          <Box {...s('wrapperItem')}>
            <Box alignItems='center' gap={4} marginLeft={12}>
              <Mobile size='s' />
              <span>{phoneNumber}</span>
            </Box>
          </Box>
        )}
      </Box>
    </Flag>
  );
}
