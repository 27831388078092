import { NormalisedItem, Select, SelectProps, useItems } from '@rexlabs/select';
import * as React from 'react';
import {
  getSearchResults,
  SearchResultItem
} from 'utils/api/get-search-results';

const getItems = async (term) => {
  const searchResults = await getSearchResults(term, {
    objectTypes: ['contact', 'property', 'tenancy', 'ownership', 'task']
  });

  return searchResults;
};

export const normaliser = (item): NormalisedItem => {
  let itemProperties = {
    type: item.type.id,
    heading: item.type.label
  };

  if (item.type.id === 'task' && item.record != null) {
    itemProperties = {
      type: item.record.type.id,
      heading: item.record.type.label
    };
  }

  return {
    id: item.id,
    label: item.label,
    ...itemProperties
  };
};

export type AttachableEntitySelectProps = SelectProps<SearchResultItem>;

export function AttachableEntitySelect(props: AttachableEntitySelectProps) {
  // We need to include the default value as a suggested item, otherwise it gets clobbered when the user selects an additional item
  const getSuggestedItems = React.useCallback(() => {
    return Array.isArray(props.value)
      ? props.value
      : [(props.value as SearchResultItem) ?? null];
  }, [props.value]);

  const { getSelectProps } = useItems({
    getItems,
    getSuggestedItems
  });

  return (
    <Select
      multi={true as any}
      normaliser={normaliser}
      {...props}
      {...getSelectProps()}
    />
  );
}
