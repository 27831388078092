import React from 'react';
import Box from '@rexlabs/box';
import { ButtonGroup, PrimaryButton } from '@rexlabs/button';
import { StyleSheet, useStyles, useToken } from '@rexlabs/styling';

import { Dialog } from 'view/components/@luna/dialog';
import InfoCircleIcon from 'view/components/icons/info';
import { ColoredTag } from 'view/components/tags/colored-tag';
import { HorizontalDivider } from 'view/components/ui/horizontal-divider';

const defaultStyles = StyleSheet({
  statusExplanation: {
    display: 'flex',
    flexDirection: 'column',
    gap: ({ token }) => token('spacing.xs')
  },
  statusTag: {
    width: 'fit-content'
  },
  outerContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: ({ token }) => token('spacing.xs')
  }
});

export function StatusHelperDialog({
  onClose
}: Pick<React.ComponentProps<typeof Dialog>, 'onClose'>) {
  const s = useStyles(defaultStyles);
  const token = useToken();

  return (
    <Dialog
      TitleIcon={InfoCircleIcon}
      title='Matching states explained'
      size='m'
    >
      <Box flexDirection='column' sy={token('spacing.xl')}>
        <Box {...s('statusExplanation')}>
          <ColoredTag intent='secondary' {...s('statusTag')}>
            Not matched
          </ColoredTag>
          <>
            These transactions have not been able to be matched against a folio.
            To avoid this issue for future payments, make sure the tenants use
            their unique payment reference when paying rent, or tenant specific
            invoices. You can find the payment reference numbers in the tenancy
            record.
          </>
          <HorizontalDivider />
        </Box>

        <Box {...s('statusExplanation')}>
          <ColoredTag intent='success' {...s('statusTag')}>
            Matched - Ready to receipt
          </ColoredTag>
          <>
            These transactions have been matched against an existing folio. If
            the folio only contains rent invoices, and no receipting
            instructions have been added to the tenancy, they can be instantly
            receipted without further allocating any funds.
          </>
          <HorizontalDivider />
        </Box>

        <Box {...s('statusExplanation')}>
          <ColoredTag intent='success' {...s('statusTag')}>
            Matched - Allocate funds
          </ColoredTag>
          <>
            These transaction have been matched against an existing folio. If
            the folio has an invoice (other than a rent invoice) and/or
            receipting instructions have been added to the tenancy folio, you
            must choose how to allocate the funds on behalf of the tenancy.
          </>
          <HorizontalDivider />
        </Box>

        <Box {...s('statusExplanation')}>
          <ColoredTag intent='warning' {...s('statusTag')}>
            Suspended
          </ColoredTag>
          <>
            By suspending a transaction, you are setting the transaction apart
            for a given reason, such as some investigation might need to be done
            to understand which folio to receipt, and/or which contact the funds
            were received from. Once you have the information to receipt the
            funds appropriately, you can take a number of actions.
          </>
          <HorizontalDivider />
        </Box>

        <Box {...s('statusExplanation')}>
          <ColoredTag intent='danger' {...s('statusTag')}>
            Removed
          </ColoredTag>
          <>
            By removing a transaction, you are setting the transaction apart for
            a given reason such as you accidentally uploaded a bank transaction
            pdf to the wrong bank account.
          </>
          <HorizontalDivider />
        </Box>

        <ButtonGroup end>
          <PrimaryButton onClick={() => onClose?.()}>
            Ok, I understand
          </PrimaryButton>
        </ButtonGroup>
      </Box>
    </Dialog>
  );
}
