import { BlockConfig } from 'view/components/record-screen/types';
import { Tenancy } from 'data/models/entities/tenancies';
import { BankAccountSelect } from 'src/modules/bank-accounts/components/bank-account-select';
import { useSelectedBankAccountContext } from 'src/modules/tenancies/contexts/selected-bank-account-context';
import React from 'react';
import Box from '@rexlabs/box';
import { Trans } from 'react-i18next';

import { StatBlocks } from 'view/components/@luna/stat-block';
import { formatCurrency } from 'utils/formatters';
import { Stat } from 'view/components/@luna/stat-block/stat-blocks';
import { PrepaymentTrustAccountList } from '../components/prepayment-trust-account-list';
import { useGetTenancyPrepaymentStats } from '../hooks/use-get-tenancy-prepayment-stats';

interface GetPrepaymentStatParams {
  label: string;
  value?: number;
}

function getPrepaymentStat({ label, value }: GetPrepaymentStatParams): Stat {
  return {
    label,
    value: formatCurrency(value),
    intent: value ? 'information' : 'neutral'
  };
}

export const prepaymentTrustAccountBlock: BlockConfig<Tenancy> = {
  id: 'prepayment-trust-account-block',
  title: () => <Trans i18nKey='bank-accounts.trust-account.label.singular' />,
  View: ({ data }) => {
    const tenancyId = data?.id;

    const { bankAccount } = useSelectedBankAccountContext();

    const { data: statData } = useGetTenancyPrepaymentStats(
      tenancyId,
      bankAccount?.id
    );

    return (
      <>
        <StatBlocks
          stats={[
            getPrepaymentStat({
              label: 'General funds',
              value: statData?.general_funds_amount
            }),
            getPrepaymentStat({
              label: 'Rent prepayments',
              value: statData?.rent_prepayments_amount
            }),
            getPrepaymentStat({
              label: 'All other prepayments',
              value: statData?.other_prepayments_amount
            })
          ]}
          shouldWrap={true}
        ></StatBlocks>
        <PrepaymentTrustAccountList tenancy={data} />
      </>
    );
  },
  Actions: () => {
    const { bankAccount, setBankAccount } = useSelectedBankAccountContext();

    return (
      <Box width={'250px'}>
        <BankAccountSelect
          onChange={(e) => setBankAccount(e.target.value)}
          value={bankAccount}
        />
      </Box>
    );
  }
};
