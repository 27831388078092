import { parseUploadedFileMeta } from 'utils/files';

import { getSearchResultItemFromObjectAndModel } from 'src/modules/common/utils/search-result-items/get-search-result-items-from-object-and-model';

import { QuoteTask } from '../types/QuoteTask';
import { tasksModel } from '../../common/models/tasks-model';
import { CreateQuoteFormData } from '../types/CreateQuoteFormData';

export function mapQuoteTaskToQuoteFormData(
  task: Partial<QuoteTask>
): Partial<CreateQuoteFormData> {
  const quoteAttachments = task?.details?.quote_attachments || [];

  return {
    ...task,
    parent_task: task?.parent_task
      ? getSearchResultItemFromObjectAndModel(task!.parent_task, tasksModel)
      : undefined,
    details: {
      ...(task?.details || {}),
      images: parseUploadedFileMeta(task?.details?.images),
      quote_attachments: parseUploadedFileMeta(quoteAttachments)
    }
  };
}
