import React from 'react';
import dayjs from 'dayjs';

import { query, useEntityQuery } from '@rexlabs/model-generator';

import { RecordDialog } from 'view/components/record-screen/dialog/dialog';
import { DialogContentConfig } from 'view/components/record-screen/dialog/types';

import { checkOutKeySetBlock } from '../blocks/check-out-key-set-block';
import { keySetsModel } from '../data/key-set-model';

const content: DialogContentConfig = [
  {
    id: 'key-details',
    label: 'Key Details',
    blocks: [checkOutKeySetBlock]
  }
];

function getKeySetQuery(id: string) {
  return query`{
    ${keySetsModel} (id: ${id}) {
      name
    }
  }`;
}

export function CheckOutKeySetDialog({
  onClose,
  handleSubmit,
  keyId
}: Pick<
  React.ComponentProps<typeof RecordDialog>,
  'onClose' | 'handleSubmit'
> & { keyId: string }) {
  const { data: keyData, status } = useEntityQuery(getKeySetQuery(keyId));

  return (
    <RecordDialog
      title='Check-out key set'
      onClose={onClose}
      isLoading={status === 'loading'}
      initialValues={{
        name: keyData?.name || '',
        id: keyId,
        checked_out_date: dayjs().format('YYYY-MM-DD')
      }}
      submitLabel='Check-out'
      content={content}
      handleSubmit={handleSubmit}
      size='s'
    />
  );
}
