import React, { useEffect, useMemo, useState } from 'react';
import mapboxgl from 'mapbox-gl';
import { isEqual } from 'lodash';

import { useToken } from '@rexlabs/styling';

import { Coordinates } from 'src/lib/mapbox/types/Coordinates';

import { useMapComponent } from 'view/hooks/use-map';

export type EditLocationProps = {
  lngLat: Coordinates;
  onLngLatChange: (lngLat: Coordinates) => void;
};

export function EditLocation({ lngLat, onLngLatChange }: EditLocationProps) {
  const token = useToken();

  // On initial render, we set the current coordinates to the lngLat prop.
  // We can then use this to determine if we need to update the map center, say
  // if the user resets the location.
  const [currCoordinates, setCurrentCoordinates] = useState<Coordinates>(
    lngLat
  );

  const { MapComponent: Map, mapObject: map, isLoading } = useMapComponent({
    startingCenterCoordinates: lngLat
  });

  const getNewCoordinates = () => {
    return map?.getCenter().toArray() as Coordinates;
  };

  const marker = useMemo(
    () =>
      !isLoading && map
        ? new mapboxgl.Marker({ color: token('color.primary.idle.default') })
            .setLngLat(lngLat)
            .addTo(map)
        : undefined,
    [!isLoading]
  );

  // This effect should only trigger once - which is when the marker is created.
  // We then add event listeners to the map, so that when the map is moved, we update the marker,
  // and update the current coordinates.
  useEffect(() => {
    if (marker && !!map) {
      map.on('movestart', () => {
        marker.setLngLat(getNewCoordinates());
      });

      map.on('move', () => {
        marker.setLngLat(getNewCoordinates());
      });

      map.on('moveend', () => {
        const newCoordinates = getNewCoordinates();

        marker.setLngLat(newCoordinates);
        setCurrentCoordinates(newCoordinates);
        onLngLatChange(newCoordinates);
      });
    }
  }, [!!marker]);

  useEffect(() => {
    // This effect listens to see if the lngLat prop has changed, and if so, does it match the
    // current coordinates. If not, we update the map center, with the lngLat prop.
    if (!isEqual(lngLat, currCoordinates)) {
      map?.setCenter(lngLat);
      setCurrentCoordinates(lngLat);
    }
  }, [lngLat]);

  return <Map />;
}
