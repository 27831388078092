import React, { useLayoutEffect } from 'react';
import { get } from 'lodash';
import Box from '@rexlabs/box';
import { Body } from '@rexlabs/text';
import { Field, FieldArray } from '@rexlabs/form';
import { PhoneInput } from '@rexlabs/text-input';
import { OutlineButton } from '@rexlabs/button';
import { Tag, TagGroup } from '@rexlabs/tag';

import { BlockConfig } from 'view/components/record-screen/types';
import { Grid } from 'view/components/@luna/form/grid';
import { Value } from 'view/components/values';
import { ActionButtons } from 'view/components/@luna/action-buttons';
import { AddIcon } from 'view/components/icons/add';
import { PhoneTypeSelect } from 'view/components/inputs/selects/phone-type';
import { Contact } from 'src/modules/contacts/types/contact-types';
import { SingleActionDeclaration } from 'src/modules/common/actions/types/action-declaration-types';

export const phoneNumbersBlock: BlockConfig<Contact> = {
  id: 'phone-numbers',
  title: 'Phone numbers',
  validate: {
    definitions: {
      'phones.*.description': { name: 'phone type', rules: 'required' },
      'phones.*.phone_number': { name: 'phone number', rules: 'required' }
    }
  },
  View: ({ data }) => {
    if (!data?.phones?.length) {
      // TODO: replace with empty state
      return (
        <Body grey>
          No phone numbers. You need to provide a phone number in order for this
          recipient to receive sms messages.
        </Body>
      );
    }

    return (
      <Grid columns={2}>
        {data.phones.map((phone, index) => {
          const value = (
            <Box flexDirection='row' sx='1.6rem' alignItems='center'>
              <Box>{phone.phone_number || '--'}</Box>
              <TagGroup>
                {phone.is_primary_phone ? <Tag>Call</Tag> : <></>}
                {phone.is_primary_sms ? <Tag>SMS</Tag> : <></>}
              </TagGroup>
            </Box>
          );
          return <Value key={index} label={phone.description} value={value} />;
        })}
      </Grid>
    );
  },
  Edit: ({ values, setFieldValue }) => (
    <FieldArray name='phones'>
      {function LineItem({ fields, push }) {
        // We only want to prefill the line item when the user switches
        // from view to edit mode (= on initial render of the edit mode),
        // not when the user manually deletes the last item
        useLayoutEffect(() => {
          if (!fields.length) {
            push({});
          }
        }, []);
        return (
          <Box sy='2.4rem'>
            {!fields?.length && (
              <Body grey>
                No phone numbers. You need to provide a phone number in order
                for this recipient to receive sms messages.
              </Body>
            )}
            {fields.map(({ field, actions }, fieldIndex) => {
              const rowActions: SingleActionDeclaration[] = [];

              const isPrimaryPhone = get(
                values,
                `${field.name}.is_primary_phone`
              );
              if (!isPrimaryPhone) {
                rowActions.push({
                  label: 'Set as phone number to call',
                  handleAction: () => {
                    const newPhones = values.phones.map((item, index) => ({
                      ...item,
                      is_primary_phone: index === fieldIndex
                    }));
                    setFieldValue?.('phones', newPhones);
                  }
                });
              }

              const isPrimarySms = get(values, `${field.name}.is_primary_sms`);
              if (!isPrimarySms) {
                rowActions.push({
                  label: 'Set SMS messages to this phone',
                  handleAction: () => {
                    const newPhones = values.phones.map((item, index) => ({
                      ...item,
                      is_primary_sms: index === fieldIndex
                    }));
                    setFieldValue?.('phones', newPhones);
                  }
                });
              }

              rowActions.push({
                label: 'Delete',
                intent: 'danger',
                handleAction: () => actions.remove()
              });

              return (
                <Box
                  key={field.name}
                  flexDirection='row'
                  alignItems='flex-start'
                  sx='2.4rem'
                  data-testid='phone_number'
                >
                  <Box width='17rem' flexShrink={0}>
                    <Field
                      name={`${field.name}.description`}
                      label='Phone type'
                      Input={PhoneTypeSelect}
                      inputProps
                    />
                  </Box>

                  <Field
                    name={`${field.name}.phone_number`}
                    label='Phone number'
                    Input={PhoneInput}
                    inputProps={{
                      suffix: (
                        <Box sx='.4rem' mr='-.8rem' flexDirection='row'>
                          {isPrimaryPhone && <Tag>Call</Tag>}
                          {isPrimarySms && <Tag>SMS</Tag>}
                        </Box>
                      )
                    }}
                  />

                  <div>
                    <ActionButtons
                      mt='1.3rem'
                      actions={[{ label: 'Actions', actions: rowActions }]}
                      placement='bottom-end'
                      aria-label='Phone actions'
                    />
                  </div>
                </Box>
              );
            })}

            <OutlineButton onClick={() => push({})} IconLeft={AddIcon}>
              Add another phone number
            </OutlineButton>
          </Box>
        );
      }}
    </FieldArray>
  )
};
