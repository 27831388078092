import { useTranslation } from 'react-i18next';
import { ContentConfig } from 'view/components/record-screen/types';
import { FLAGS } from 'utils/feature-flags';
import { noteTableBlock } from 'src/modules/note/blocks/note-table-block';
import { workDetailsMaintenanceTypeBlock } from 'src/modules/tasks/maintenance/blocks/work-details-maintenance-type-block';
import { linkedTasksBlock } from 'src/modules/tasks/common/blocks/linked-tasks-block';
import { usePayableByInvoicesBlock } from 'src/modules/tasks/maintenance/blocks/invoices-payable-by';
import { maintenanceMessageTableBlock } from 'src/modules/tasks/maintenance/blocks/maintenance-message-table-block';
import { applianceBlock } from 'src/modules/tasks/maintenance/blocks/maintenance-appliance-block';
import { taskWithheldFundsBlock } from 'src/modules/withheld-funds/blocks/task-withheld-funds-block';
import { useFeatureFlags } from 'view/components/@luna/feature-flags';
import { detailsBlock } from '../blocks/maintenance-details-block';
import { issuesReportedBlock } from '../blocks/issues-reported-block';
import { attachmentsBlock } from '../blocks/attachments-block';
import { taskDateBlock } from '../blocks/task-date-block';
import { taskPriorityBlock } from '../../common/blocks/task-priority-block';
import { documentsBlock } from '../../common/blocks/documents';
import { checklistsBlock } from '../../common/blocks/create-checklists-block';
import { quotesListBlock } from '../../quotes/blocks/quotes-list-block';
import { workOrdersListBlock } from '../../work-orders/blocks/work-orders-list-block';
import { MaintenanceTask } from '../types/MaintenanceTask';
import { maintenanceInstructionsBlock } from '../blocks/maintenance-instructions-block';

export const useContent = (
  data: MaintenanceTask | undefined
): ContentConfig => {
  const payableByInvoicesBlock = usePayableByInvoicesBlock();

  const hasActiveOwnership = data?.property?.active_property_ownership != null;

  const { t } = useTranslation();

  const { hasFeature } = useFeatureFlags();
  return [
    {
      label: 'General',
      items: [
        {
          id: 'overview',
          label: 'Overview',
          blocks: [
            detailsBlock,
            applianceBlock,
            issuesReportedBlock,
            attachmentsBlock,
            taskPriorityBlock,
            taskDateBlock,
            workDetailsMaintenanceTypeBlock,
            ...(hasActiveOwnership ? [maintenanceInstructionsBlock] : [])
          ]
        },
        {
          id: 'notes',
          label: 'Notes',
          blocks: [noteTableBlock]
        },
        {
          id: 'documents',
          label: 'Documents',
          blocks: [documentsBlock]
        },
        {
          id: 'messages',
          label: 'Messages',
          blocks: [maintenanceMessageTableBlock]
        },
        {
          id: 'checklist',
          label: 'Checklist',
          blocks: [checklistsBlock],
          flag: FLAGS.CHECKLIST_TEMPLATES
        }
      ]
    },
    {
      label: 'Manage',
      items: [
        {
          id: 'quotes-work-orders-and-withheld-funds',
          label: hasFeature(FLAGS.TASK_WITHHELD_FUNDS)
            ? t('withheld-funds.quotes-work-orders-and-withheld-funds.label')
            : 'Quotes and Work Orders',
          flag: FLAGS.WORK_ORDERS,
          blocks: [
            quotesListBlock,
            workOrdersListBlock,
            ...(hasFeature(FLAGS.TASK_WITHHELD_FUNDS)
              ? [taskWithheldFundsBlock]
              : [])
          ]
        },

        {
          id: 'linked-tasks',
          label: 'Linked tasks',
          blocks: [linkedTasksBlock],
          flag: FLAGS.LINKED_TASKS
        },
        {
          id: 'bills',
          label: 'Bills',
          blocks: [payableByInvoicesBlock]
        }
      ]
    }
  ];
};
