import React from 'react';
import { UserInvitationCreateUserCard } from 'src/modules/auth/user-invitation/components/user-invitation-create-user-card';
import LoadingState from 'view/components/states/compact/loading';
import { useRecoilValue } from 'recoil';
import { userInviteQuery } from 'src/modules/auth/user-invitation/atoms/userInviteQuery';

export function UserInvitationCreateUserScreen({ match }) {
  const token = match?.query?.token;
  const invitation = useRecoilValue(userInviteQuery(token));

  if (!invitation) {
    return <LoadingState />;
  }

  return (
    <>
      <UserInvitationCreateUserCard invitation={invitation} token={token} />
    </>
  );
}
