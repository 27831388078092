import React, { useEffect } from 'react';
import { FormLayout } from 'view/components/layouts/form/form-layout';
import { FieldArray } from '@rexlabs/form';
import { OutlineButton } from '@rexlabs/button';
import AddIcon from 'view/components/icons/add';
import { HorizontalDivider } from 'view/components/ui/horizontal-divider';
import { RecordTypes } from 'data/models/types';
import { getOwnerContacts } from 'src/modules/ownerships/utils/get-owner-contacts';
import { get } from 'lodash';
import { getDefaultLineItem } from '../utils/get-default-line-item';
import { PaymentMethodLineItem } from './payment-method-line-item';

export const DisbursementPaymentMethodsEdit = ({ values, data }) => {
  const recordType = data.__record_type;
  const fieldName =
    recordType === RecordTypes.Contact || recordType === RecordTypes.Ownership
      ? 'disbursement_payment_methods'
      : 'object.disbursement_payment_methods';

  return (
    <FormLayout>
      <FieldArray name={fieldName}>
        {function PaymentMethods({ fields, push, pop }) {
          useEffect(() => {
            if (!get(values, fieldName).length) {
              // there aren't any saved payment methods so remove the placeholder payment method field if there is one
              pop();
              // add a new placeholder payment method field
              push(getDefaultLineItem({ amount: 100 }));
            }
          }, [pop, push]);

          return (
            <div>
              <div className='space-y-xl'>
                {fields.map(({ field, actions }) => (
                  <div
                    key={field.name}
                    data-testid='disbursement-payment-method'
                  >
                    <PaymentMethodLineItem
                      values={values}
                      name={field.name}
                      {...(recordType === RecordTypes.Contact && {
                        fixedContact: data!
                      })}
                      {...(recordType === RecordTypes.Ownership && {
                        contacts: getOwnerContacts(data?.owners)
                      })}
                      {...(recordType ===
                        RecordTypes.PendingContactDisbursement && {
                        fixedContact: data.object!
                      })}
                      {...(recordType ===
                        RecordTypes.PendingOwnershipDisbursement && {
                        contacts: getOwnerContacts(data?.object?.owners)
                      })}
                      onRemove={() => actions.remove()}
                    />
                  </div>
                ))}
                <div>
                  <HorizontalDivider />
                </div>
              </div>
              <div className='mt-xl'>
                <OutlineButton
                  IconLeft={AddIcon}
                  onClick={() => push(getDefaultLineItem())}
                >
                  Add another payment method
                </OutlineButton>
              </div>
            </div>
          );
        }}
      </FieldArray>
    </FormLayout>
  );
};
