import { ActionMenuItem } from '@rexlabs/action-menu';
import { transformActionDeclarationsToActionMenuItems } from 'utils/actions/transforms';
import { InspectionRun } from '../../types/InspectionRun';
import { useGetCancelInspectionRunAction } from '../action-declarations/use-get-cancel-inspection-run-action';

export function useGetInspectionRunActions() {
  const getCancelInspectionRunAction = useGetCancelInspectionRunAction();

  return (inspectionRun?: InspectionRun): ActionMenuItem[] => {
    if (!inspectionRun) return [];

    const actions = [getCancelInspectionRunAction(inspectionRun)];

    return [...transformActionDeclarationsToActionMenuItems(actions)];
  };
}
