import React from 'react';
import { ActionButtons } from 'view/components/@luna/action-buttons';
import { BlockConfig } from 'view/components/record-screen/types';
import { useModelActions } from '@rexlabs/model-generator';
import { MaintenanceTask } from '../../maintenance/types/MaintenanceTask';
import { QuotesList } from '../components/quotes-list';
import { useGetCreateQuoteAction } from '../hooks/action-declarations/use-get-create-quote-action';
import { QuoteTask } from '../types/QuoteTask';
import { tasksModel } from '../../common/models/tasks-model';

export const quotesListBlock: BlockConfig<MaintenanceTask> = {
  id: 'quotes',
  title: 'Quotes',
  View: ({ data }) => {
    return <QuotesList relatedTask={data!} />;
  },
  Actions: ({ data }) => {
    const { refreshItem } = useModelActions(tasksModel);
    const getCreateQuoteAction = useGetCreateQuoteAction({
      // refresh the parent to show changes to its status
      onCreate: (data: QuoteTask[]) => {
        // they should all have the same parent task, so only need to refresh one
        data[0]?.parent_task && refreshItem({ id: data[0].parent_task.id });
      }
    });

    const {
      handleAction: handleCreate,
      label: createLabel
    } = getCreateQuoteAction(data);

    return (
      <ActionButtons
        actions={[
          {
            label: createLabel,
            handleAction: handleCreate
          }
        ]}
      />
    );
  }
};
