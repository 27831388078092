import React, { useCallback } from 'react';

import { RecordDialog } from 'view/components/record-screen/dialog/dialog';
import { DialogContentConfig } from 'view/components/record-screen/dialog/types';

import { useModelActions } from '@rexlabs/model-generator';
import { bankAccountModel } from 'data/models/entities/financials/bank-accounts';
import {
  financialsReconciliationsModel,
  Reconciliation
} from 'data/models/entities/financials/reconciliations';
import { getWithDeltaDays } from 'utils/dates/dates';
import { filterNullValues } from 'utils/filter-null-values';
import { RecordSubmitHandler } from 'view/components/record-screen/utils';
import { trackStartReconciliation } from 'src/modules/reconciliations/utils/analytics';
import { startDailyReconciliationBlock } from '../blocks/start-daily-reconciliation';

const content: DialogContentConfig = [
  {
    id: 'basics',
    label: 'Basics',
    blocks: [startDailyReconciliationBlock]
  }
];

interface StartDailyReconciliationDialogProps {
  bankAccountId: string;
  updateListId?: string;
  onClose?: () => void;
  onCreate?: (item: Reconciliation) => void;
}

export function StartDailyReconciliationDialog({
  bankAccountId,
  updateListId,
  onClose,
  onCreate
}: StartDailyReconciliationDialogProps) {
  const { createItem, refreshList } = useModelActions(
    financialsReconciliationsModel
  );

  const { refreshList: refreshBankAccountList } = useModelActions(
    bankAccountModel
  );

  const handleSubmit: RecordSubmitHandler = useCallback(
    async ({ values }) => {
      const { data } = await createItem({
        data: filterNullValues({
          ...values,
          start_date: values.statement_date
        }),
        args: {
          include: 'reconciliation_summary'
        }
      });

      if (updateListId) {
        await refreshList({ id: updateListId });
      }

      await refreshBankAccountList({
        id: 'bank-accounts-list',
        args: {
          include: 'active_reconciliation.reconciliation_summary'
        }
      });

      onCreate?.(data);

      trackStartReconciliation(data);

      return data;
    },
    [createItem, refreshList, updateListId]
  );

  const data = {
    bank_account: {
      id: bankAccountId
    },
    statement_date: getWithDeltaDays(-1)
  };

  return (
    <RecordDialog
      onClose={onClose}
      data={data}
      title='Start daily reconciliation'
      handleSubmit={handleSubmit}
      content={content}
      submitLabel='Start reconciling'
    />
  );
}
