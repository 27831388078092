import React, { useMemo } from 'react';

import {
  TitleBlock,
  TitleBlockProps
} from 'view/components/cards/title/title-block';

import { RecordObject, RecordType } from 'data/models/types';
import { SecurityDeposit } from 'data/models/entities/financials/security-deposits';
import { formatCurrency } from 'utils/formatters';
import SecurityDepositIcon from 'view/components/icons/security-deposit';
import { getRecordTitle } from 'utils/records/get-record-title';
import { isRegistrationNumberRequired } from '../utils/is-registration-number-required';
import { useGetSecurityDepositActions } from '../actions/use-get-security-deposit-actions';

const type: RecordType = 'security_deposit';

type SecurityDepositTitleBlockProps = {
  securityDeposit: SecurityDeposit;
};

export function SecurityDepositTitleBlock({
  securityDeposit
}: SecurityDepositTitleBlockProps) {
  const {
    record_reference,
    registration_number,
    amount_due,
    amount_owing_on_invoices_issued,
    amount_due_and_not_invoiced_or_promised,
    amount_paid,
    amount_expected,
    insured_by_contact,
    security_deposit_type
  } = securityDeposit;

  const title = getRecordTitle({
    type,
    object: securityDeposit
  } as RecordObject);

  const getActions = useGetSecurityDepositActions();

  const tags = useMemo(() => {
    const tags: TitleBlockProps['tags'] = [];

    if (isRegistrationNumberRequired(securityDeposit) && !registration_number) {
      tags.push({
        type: 'bad',
        label: 'Deposit registration number required'
      });
    }

    if (registration_number) {
      tags.push({
        type: 'neutral',
        label: `Registration number: ${registration_number}`
      });
    }

    if (security_deposit_type?.held_by.id === 'held_by_deposit_authority') {
      tags.push({
        type: 'neutral',
        label: 'Held by deposit authority'
      });
    }

    if (security_deposit_type?.held_by.id === 'held_by_agency') {
      tags.push({
        type: 'neutral',
        label: 'Held by agency'
      });
    }

    if (security_deposit_type?.held_by.id === 'held_by_ownership') {
      tags.push({
        type: 'neutral',
        label: 'Held by ownership'
      });
    }

    if (insured_by_contact) {
      tags.push({
        type: 'neutral',
        label: 'Insured'
      });
    }

    return tags;
  }, [registration_number, security_deposit_type, insured_by_contact]);

  const actions = useMemo(() => getActions(securityDeposit), [securityDeposit]);

  const stats = useMemo<TitleBlockProps['stats']>(
    () => [
      {
        label: 'Amount due',
        intent: 'secondary',
        value: formatCurrency(amount_due)
      },
      {
        label: 'Requested balance',
        intent: 'secondary',
        value: formatCurrency(amount_owing_on_invoices_issued)
      },
      {
        label: 'Not invoiced or paid',
        intent: 'secondary',
        value: formatCurrency(amount_due_and_not_invoiced_or_promised)
      },
      {
        label: 'Amount paid',
        intent: 'secondary',
        value: formatCurrency(amount_paid)
      },
      {
        label: 'Total deposit amount',
        intent: 'secondary',
        value: formatCurrency(amount_expected)
      }
    ],
    [
      amount_due,
      amount_owing_on_invoices_issued,
      amount_due_and_not_invoiced_or_promised,
      amount_paid,
      amount_expected
    ]
  );

  return (
    <TitleBlock
      title={title}
      type={type}
      reference={record_reference}
      tags={tags}
      actions={actions}
      stats={stats}
      Icon={SecurityDepositIcon}
    />
  );
}
