import { useDialog } from '@rexlabs/dialog';
import { ActionDeclaration } from 'src/modules/common/actions/types/action-declaration-types';
import InvoicesIcon from 'view/components/icons/invoices';
import { ProcessBillsDialog } from '../../dialogs/process-bills-dialog';
import { Bill } from '../../types/Bill';

export function useGetProcessBillAction() {
  const { open } = useDialog(ProcessBillsDialog);

  return (billsToBeProcessed: Bill[]): ActionDeclaration => {
    return {
      id: 'process-bill',
      group: 'process-bill',
      label: 'Process',
      Icon: InvoicesIcon,
      handleAction: async () => {
        open({ billsToBeProcessed });
      }
    };
  };
}
