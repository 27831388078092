import { useSettings } from 'src/modules/settings/hooks/useSettings';

import { Contact } from '../types/contact-types';

export function useGetMapContactDataToForm() {
  const { 'non-resident-tax-rate': defaultTaxRate } = useSettings();

  return (data?: Contact): Contact | undefined => {
    return !data
      ? data
      : {
          ...data,
          tax: {
            ...data.tax,
            non_resident: !!data?.tax?.non_resident,
            non_resident_withhold_tax: !!data?.tax?.non_resident_withhold_tax,
            non_resident_tax_rate:
              (data?.tax?.non_resident_tax_rate || defaultTaxRate) * 100
          }
        };
  };
}
