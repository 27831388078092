import { LinkButton } from '@rexlabs/button';
import { StyleSheet, useStyles } from '@rexlabs/styling';
import React from 'react';
import AddIcon from 'view/components/icons/add';

export const styles = StyleSheet({
  actionLink: {
    color: ({ token }) => token('color.textStyle.primary.idle.default')
  }
});

export const SelectTrustJournalEntryActionCell = (props) => {
  const s = useStyles(styles);

  const {
    setSelectedReceipts,
    selectedReceipts,
    setAvailableReceipts,
    availableReceipts,
    trustJournalEntry
  } = props.value;

  const handleClick = () => {
    setSelectedReceipts(selectedReceipts.concat(trustJournalEntry));
    setAvailableReceipts(
      availableReceipts.filter(
        (availableReceipt) => availableReceipt.id != trustJournalEntry.id
      )
    );
  };

  return (
    <LinkButton
      data-testId={'select-trust-journal-entry-action-button'}
      IconLeft={AddIcon}
      {...s('actionLink')}
      onClick={handleClick}
    />
  );
};
