import * as React from 'react';
import { DialogProps, useErrorDialog } from '@rexlabs/dialog';
import { RecordDialog } from 'view/components/record-screen/dialog/dialog';
import { RecordSubmitHandler } from 'view/components/record-screen/utils';
import { propertyAreaTypeDetailsBlock } from 'src/modules/property-areas/property-area-types/blocks/property-area-type-details-block';
import { propertyAreaTypeItemsBlock } from 'src/modules/property-areas/property-area-types/blocks/property-area-type-items-block';
import { PropertyAreaTypeForm } from 'src/modules/property-areas/property-area-types/types/PropertyAreaTypeForm';
import { useModelActions } from '@rexlabs/model-generator';
import { propertyAreaTypesModel } from 'src/modules/property-areas/property-area-types/models/property-area-types-model';
import { PropertyAreaTypeDialogButtonGroup } from 'src/modules/property-areas/property-area-types/components/property-area-type-dialog-button-group';
import { mapAreaTypeDataToForm } from 'src/modules/property-areas/property-area-types/mappers/mapAreaTypeDataToForm';

export interface PropertyAreaTypeDialogProps extends DialogProps {
  title: string;
  initialValues: Partial<PropertyAreaTypeForm>;
  onSubmit: RecordSubmitHandler<PropertyAreaTypeForm>;
  isEditMode?: boolean;
  submitLabel?: string;
}

const content = [
  {
    id: 'property-area-type',
    label: 'Add property area type',
    blocks: [propertyAreaTypeDetailsBlock, propertyAreaTypeItemsBlock]
  }
];

export function PropertyAreaTypeRecordDialog({
  onClose,
  initialValues,
  onSubmit,
  isEditMode,
  title,
  submitLabel
}: PropertyAreaTypeDialogProps) {
  const { resetItems, refreshLists } = useModelActions(propertyAreaTypesModel);
  const { open: openErrorDialog } = useErrorDialog();

  const handleReset = async ({ formReset }) => {
    if (initialValues.id) {
      try {
        const resetAreaType = await resetItems({
          propertyAreaTypeId: initialValues.id,
          args: { include: 'items' }
        });

        formReset(mapAreaTypeDataToForm(resetAreaType.data));
        refreshLists();
      } catch (e) {
        openErrorDialog(e);
      }
    }
  };

  return (
    <RecordDialog
      title={title}
      data={initialValues}
      blockProps={{
        isEditMode
      }}
      handleSubmit={onSubmit}
      submitLabel={submitLabel}
      onClose={onClose}
      content={content}
      ButtonGroup={PropertyAreaTypeDialogButtonGroup}
      buttonGroupProps={{
        handleReset:
          initialValues.id && initialValues?.system_purpose
            ? handleReset
            : undefined
      }}
    />
  );
}
