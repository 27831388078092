import React from 'react';
import { HotkeyedActionDeclaration } from 'src/modules/common/actions/types/action-declaration-types';
import { GhostButton } from '@rexlabs/button';
import ChevronLeft from 'view/components/icons/chevron-left';
import ChevronRight from 'view/components/icons/chevron-right';
import Box from '@rexlabs/box';
import { StyleSheet, useStyles, useToken } from '@rexlabs/styling';
import { StandardDialogFooter } from 'view/components/dialogs/components/standard-dialog-footer';
import { usePagedReviewerKeybindings } from 'view/components/paged-reviewer/hooks/use-paged-reviewer-keybindings';
import { useMediaQuery } from '@rexlabs/breakpoints';
import { PagedReviewerActionButton } from 'view/components/paged-reviewer/paged-reviewer-action-button';

export interface PagedReviewerProps {
  actions: HotkeyedActionDeclaration[];
  onNextClick: () => void;
  onPreviousClick: () => void;
  recordIndex: number;
  recordCount: number;
  renderRecord: (index: number) => React.ReactNode;
  renderHeader?: () => React.ReactNode;
  renderFooter?: () => React.ReactNode;
  renderBanner?: () => React.ReactNode;
  onAction: (action: HotkeyedActionDeclaration) => void;
}

const defaultStyles = StyleSheet({
  container: {
    width: '100%',
    flex: 1,
    borderTopLeftRadius: ({ token }) => token('border.radius.l'),
    borderTopRightRadius: ({ token }) => token('border.radius.l'),
    display: 'flex',
    flexDirection: 'column',
    borderColor: ({ token }) => token('palette.grey.300'),
    borderStyle: 'solid',
    borderWidth: ({ token }) => token('border.width.thin'),
    marginBottom: ({ token }) => '-' + token('spacing.xl'),
    borderBottomWidth: 0
  },
  recordRenderer: {
    position: 'relative',
    display: 'flex',
    flex: 1
  },
  loading: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: ({ token }) => token('palette.grey.200')
  }
});

/**
 * A stateless component that renders a fullscreen "review document" experience
 * that supports paging through a set of records with a configurable set of actions
 * that can be triggered on each record.
 *
 * The component is designed to be used in a fullscreen dialog
 *
 * @param actions An array of actions with optional hotkeys that display as buttons
 * @param onRecordChange When the user changes pages, this callback is called with the new record id
 * @param renderRecord A function that renders a single record
 * @param renderHeader A function that renders the header
 * @param renderFooter A function that renders the footer (bottom left)
 * @param onNextClick A function that is called when the user clicks the next button
 * @param onPreviousClick A function that is called when the user clicks the previous button
 * @param recordIndex The index of the current record
 * @param recordCount The total number of records
 * @param onAction A function that is called when the user triggers an action button
 */
export function PagedReviewer({
  actions,
  renderRecord,
  renderBanner,
  renderHeader,
  renderFooter,
  onNextClick,
  onPreviousClick,
  recordIndex,
  recordCount,
  onAction
}: PagedReviewerProps) {
  const token = useToken();
  const s = useStyles(defaultStyles);
  const matchesMobile = useMediaQuery({ maxWidth: 'm' });

  usePagedReviewerKeybindings({
    handlePrevious: onPreviousClick,
    handleNext: onNextClick
  });

  return (
    <Box flexDirection={'column'} width='100%' height='100%' display='flex'>
      <Box flexDirection='column' width='100%' {...s('container')}>
        <Box
          flexDirection={matchesMobile ? 'column' : 'row'}
          justifyContent='space-between'
          alignItems='center'
          spacing={matchesMobile ? token('spacing.s') : undefined}
          pl={token('spacing.l')}
          pr={token('spacing.l')}
          pb={renderBanner ? 0 : token('spacing.xl')}
          pt={token('spacing.xl')}
        >
          {renderHeader ? renderHeader() : null}
          <Box flexDirection='row' alignItems='center'>
            <GhostButton
              data-testid={'previous-record-button'}
              onClick={onPreviousClick}
              isDisabled={recordIndex === 0}
            >
              <ChevronLeft />
            </GhostButton>
            <span>{`${recordIndex + 1} of ${recordCount}`}</span>
            <GhostButton
              data-testid={'next-record-button'}
              onClick={onNextClick}
              isDisabled={recordIndex === recordCount - 1}
            >
              <ChevronRight />
            </GhostButton>
          </Box>
        </Box>
        {renderBanner && <Box p={token('spacing.s')}>{renderBanner()}</Box>}
        <div {...s('recordRenderer')}>{renderRecord(recordIndex)}</div>
      </Box>
      <div>
        <StandardDialogFooter>
          <Box
            flexDirection={matchesMobile ? 'column' : 'row'}
            spacing={token('spacing.s')}
            flex={1}
          >
            {renderFooter ? renderFooter() : null}

            {actions.map((action, index) => (
              <PagedReviewerActionButton
                key={index}
                action={action}
                onAction={onAction}
              />
            ))}
          </Box>
        </StandardDialogFooter>
      </div>
    </Box>
  );
}
