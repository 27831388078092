import { useDialog } from '@rexlabs/dialog';
import { ActionDeclaration } from 'src/modules/common/actions/types/action-declaration-types';
import { ChartOfAccountsAccount } from 'src/modules/chart-of-accounts/types/chart-of-account-types';
import { EditChartOfAccountsAccountDialog } from '../dialogs/edit-chart-of-accounts-account-dialog';

export function useGetEditAction() {
  const { open } = useDialog(EditChartOfAccountsAccountDialog);

  return (
    chartOfAccountsAccount: ChartOfAccountsAccount
  ): ActionDeclaration => ({
    id: 'edit',
    group: 'chart-of-accounts-account',
    label: 'Edit',
    handleAction: () =>
      open({
        initialData: chartOfAccountsAccount
      })
  });
}
