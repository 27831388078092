import { ActionDeclaration } from 'src/modules/common/actions/types/action-declaration-types';
import { Ownership } from 'data/models/entities/ownerships';
import { useDialog } from '@rexlabs/dialog';
import { AddOwnershipFeeDialog } from 'src/modules/agency-fees/dialogs/add-ownership-fee';
import { ownershipsFeesModel } from 'data/models/entities/ownerships/fees/ownership-fees';
import { useModelActions } from '@rexlabs/model-generator';
import { useToast } from 'view/components/@luna/notifications/toast';
import React from 'react';

export function useGetAddOwnershipFee() {
  const { refreshLists } = useModelActions(ownershipsFeesModel);
  const { open } = useDialog(AddOwnershipFeeDialog);
  const { addToast } = useToast();

  return (ownership: Ownership): ActionDeclaration => ({
    id: 'add-ownership-fee',
    group: 'ownership-fee',
    label: 'Add ownership fees',
    handleAction: () => {
      open({
        ownershipId: ownership.id,
        onAdd: async (addedAgencyFees) => {
          await refreshLists({
            args: { ownershipId: ownership.id }
          });

          if (addedAgencyFees.length === 1) {
            return addToast({
              description: (
                <>
                  <b>{addedAgencyFees[0].name} fee</b> has been <b>added</b> to
                  this ownership
                </>
              )
            });
          }

          addToast({
            description: (
              <>
                <b>{addedAgencyFees.length} fees</b> has been <b>added</b> to
                this ownership
              </>
            )
          });
        }
      });
    }
  });
}
