import { Columns } from 'view/components/table';
import { Invoice } from 'data/models/entities/financials/invoices';

export const billsAndFeesColumns: Columns<Invoice> = [
  {
    width: 120,
    type: 'text',
    Header: 'Reference',
    accessor: (item) => item.record_reference
  },
  {
    id: 'payable_to.object',
    type: 'record',
    Header: 'Payable to',
    width: 200
  },
  {
    id: 'description',
    Header: 'Description',
    width: 300
  },
  {
    id: 'amount_owing',
    type: 'currency',
    Header: 'Amount owing',
    cellProps: {
      align: 'right'
    },
    width: 180
  },
  {
    id: 'amount_pending_disbursement',
    type: 'currency',
    Header: 'Transfer amount',
    cellProps: {
      align: 'right'
    },
    width: 180
  }
];
