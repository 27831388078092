import * as React from 'react';
import { Card } from 'view/components/card';
import { ListScreen } from 'view/components/list-screen/list-screen';

import { useGetCreateQuoteAction } from '../hooks/action-declarations/use-get-create-quote-action';
import { QuotesTabbedTable } from '../components/quotes-tabbed-table';

export function QuoteList() {
  const getCreateQuoteAction = useGetCreateQuoteAction();

  return (
    <ListScreen title='Quotes' actions={[getCreateQuoteAction()]}>
      <Card>
        <QuotesTabbedTable />
      </Card>
    </ListScreen>
  );
}
