import { useEffect, useRef } from 'react';

export function useChangeEffect(callback: () => void, deps: any[]) {
  const hasRenderedOnce = useRef(false);

  useEffect(() => {
    if (hasRenderedOnce.current === false) {
      hasRenderedOnce.current = true;
      return;
    }

    callback();
  }, deps);
}
