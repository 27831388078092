import { Coordinates } from 'src/lib/mapbox/types/Coordinates';
import { getLngLatFromProperty } from 'src/modules/properties/utils/get-lng-lat-from-property';

import { InspectionTask } from '../types/InspectionTask';

export function getRouteCoordinates(
  startAtLngLat: Coordinates,
  inspections: InspectionTask[],
  finishAtLngLat: Coordinates
) {
  const inspectionCoordinates = inspections
    .filter((inspection) => !!getLngLatFromProperty(inspection.property))
    .map((inspection) => {
      return getLngLatFromProperty(inspection.property)!;
    });

  return [startAtLngLat, ...inspectionCoordinates, finishAtLngLat];
}
