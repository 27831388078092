import React from 'react';

import { Body, Small, Truncate } from '@rexlabs/text';
import { StyleSheet, useStyles, useToken } from '@rexlabs/styling';
import Box from '@rexlabs/box';

const styles = StyleSheet({
  truncated: {
    '-webkit-line-clamp': 'var(--line-clamp, 2)',
    display: '-webkit-box',
    '-webkit-box-orient': 'vertical',
    overflow: 'hidden',
    whiteSpace: 'pre-line'
  }
});

/**
 * You can use this component to display a large description in a cell and have a subtext, for example,
 * you maybe have a large string that would normally be truncated, but you want it to run over multiple lines.
 * You can add extra lines by passing a number to the lineClamp prop, and you can add a subtext by passing a string
 * to the subtext prop.
 */
export function TwoTierLargeDescriptionCell({
  text,
  subtext,
  lineClamp
}: {
  text: string;
  subtext: string;
  lineClamp: number;
}) {
  const s = useStyles(styles);
  const token = useToken();
  return (
    <Body as='div'>
      <span
        {...s('truncated')}
        style={{ '--line-clamp': lineClamp } as React.CSSProperties}
      >
        {text ?? '--'}
      </span>
      {subtext && (
        <Box marginTop={token('spacing.xxs')}>
          <Truncate {...s('secondLine')}>
            <Small as='span' grey>
              {subtext}
            </Small>
          </Truncate>
        </Box>
      )}
    </Body>
  );
}
