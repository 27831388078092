import React from 'react';

import { TitleBlock } from 'view/components/cards/title/title-block';

import ReconciliationIcon from 'view/components/icons/reconciliation';
import ROUTES from 'routes/app';
import { push } from '@rexlabs/whereabouts';
import EditIcon from 'view/components/icons/edit';
import { getRecordTitle } from 'utils/records/get-record-title';
import { RecordTypes } from 'data/models/types';
import { SingleActionDeclaration } from 'src/modules/common/actions/types/action-declaration-types';

export function DraftReconciliationTitleBlock({ reconciliation }) {
  const { record_reference } = reconciliation;

  const continueReconciliation: SingleActionDeclaration[] = reconciliation
    ? [
        {
          intent: 'tertiary',
          handleAction: () =>
            push(ROUTES.RECONCILIATION, {
              params: {
                reconciliationId: reconciliation?.id
              }
            }),
          Icon: EditIcon
        }
      ]
    : [];

  const title = getRecordTitle({
    type: RecordTypes.Reconciliation,
    object: reconciliation
  });

  return (
    <TitleBlock
      title={title}
      type={'Draft Reconciliation'}
      actions={continueReconciliation}
      reference={record_reference}
      Icon={ReconciliationIcon}
    />
  );
}
