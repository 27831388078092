import React, { ComponentProps } from 'react';

import { StyleSheet, useStyles, border, padding } from '@rexlabs/styling';
import { Body } from '@rexlabs/text';

import { AVAILABLE_SECTIONS } from './sections/config';

const MAX_SECONDARY_LIST_LENGTH = 4;

type Section = typeof AVAILABLE_SECTIONS[number];

type Merge<A, B> = {
  [K in keyof A]: K extends keyof B ? B[K] : A[K];
} &
  B;

type MakeSectionConfig<U> = U extends Section
  ? Merge<
      { type: U['type'] },
      Omit<ComponentProps<U['Component']>, 'maxListLength'>
    >
  : never;

const defaultStyles = StyleSheet({
  container: {
    ...padding.styles({
      x: 'xl'
    }),

    '> *:not(:last-child)': {
      ...border.styles({
        bottom: {
          width: 'thin',
          color: ({ token }) => token('color.border.container.static.light')
        }
      })
    }
  },

  headline: {
    ...padding.styles({
      target: 'accordion.header',
      x: 'none',
      y: 'm'
    })
  }
});

export type RelatedRecordsProps = {
  sections: MakeSectionConfig<Section>[];
};

export function RelatedRecords({ sections }: RelatedRecordsProps) {
  const s = useStyles(defaultStyles);
  return (
    <div {...s('container')}>
      {sections.map(({ type, ...props }) => {
        const { headline, Component } = AVAILABLE_SECTIONS.find(
          (config) => config.type === type
        )!;

        return (
          <div key={headline}>
            <Body {...s('headline')} semibold as='h4'>
              {headline}
            </Body>
            <Component
              {...(props as any)}
              maxListLength={MAX_SECONDARY_LIST_LENGTH}
            />
          </div>
        );
      })}
    </div>
  );
}
