import ActionMenu from '@rexlabs/action-menu';
import {
  GhostButton,
  PrimaryButton,
  PrimarySplitButton
} from '@rexlabs/button';
import { useConfirmationDialog } from '@rexlabs/dialog';
import * as React from 'react';
import { StandardDialogFooter } from 'view/components/dialogs/components/standard-dialog-footer';
import ChevronDownIcon from 'view/components/icons/chevron-down';
import { ButtonGroupProps } from 'view/components/record-screen/dialog/types';

export interface SMSCreateDialogButtonGroupProps extends ButtonGroupProps {
  handleSendNow: (...args: any[]) => Promise<any> | any;
  handleSendLater: (...args: any[]) => Promise<any> | any;
  hasButtonGroupActions?: boolean;
}

export function SMSCreateDialogButtonGroup({
  hasButtonGroupActions = true,
  submitLabel,
  onClose,
  handleSubmit,
  handleSendNow,
  handleSendLater,
  isSubmitting
}: SMSCreateDialogButtonGroupProps) {
  const { open: openDiscardDialog } = useConfirmationDialog();

  const handleCancel = () => {
    openDiscardDialog({
      destructive: true,
      title: 'Are you sure you wish to discard this message?',
      message: 'Any unsaved changes will be lost',
      onConfirm: () => onClose?.()
    });
  };

  // Take the data from the submission transformation and create the bulk messages
  const handleSubmitAndClose = async () => {
    handleSendLater?.();

    const data = await handleSubmit();
    // If validation fails, don't proceed
    if (!data) {
      return;
    }

    onClose?.();
  };

  // takes the data from the submission transformation and sends the messages immediately
  const handleSubmitAndSendNow = async () => {
    handleSendNow?.();

    const data = await handleSubmit();
    // If validation fails, don't proceed
    if (!data) {
      return;
    }

    onClose?.();
  };

  return (
    <StandardDialogFooter>
      <GhostButton onClick={handleCancel}>Cancel</GhostButton>
      {hasButtonGroupActions ? (
        <PrimarySplitButton
          Left={({ Button }) => (
            <Button
              type='submit'
              isLoading={isSubmitting}
              onClick={handleSubmitAndSendNow}
            >
              {submitLabel}
            </Button>
          )}
          Right={({ IconButton }) => (
            <ActionMenu
              placement='bottom-end'
              items={[
                {
                  label: 'Send later from Outbox',
                  onClick: handleSubmitAndClose
                }
              ]}
              Button={React.forwardRef((props, ref) => (
                <IconButton {...props} ref={ref} Icon={ChevronDownIcon} />
              ))}
            />
          )}
        />
      ) : (
        <PrimaryButton
          type='submit'
          isLoading={isSubmitting}
          onClick={handleSubmitAndClose}
        >
          {submitLabel}
        </PrimaryButton>
      )}
    </StandardDialogFooter>
  );
}
