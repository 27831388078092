import { useDialog } from '@rexlabs/dialog';

import { KeySet } from '../data/key-set-model';
import { EditKeySetDialog } from '../dialogs/edit-key-set-dialog';
import { useEditKeySetSubmitHandler } from './use-edit-key-set-submit-handler';

export function useGetEditKeySetAction() {
  const { open: openEditKeySetDialog } = useDialog(EditKeySetDialog);

  const { onHandleSubmit } = useEditKeySetSubmitHandler();

  return (key: KeySet) => ({
    id: 'edit-key-set',
    label: 'Edit key set',
    intent: 'primary',
    onClick: () =>
      openEditKeySetDialog({ keyId: key.id, handleSubmit: onHandleSubmit })
  });
}
