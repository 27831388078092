import { CustomAction } from '@rexlabs/model-generator';
import { Generator } from 'data/models/generator';

export interface Connection {
  isOnline: boolean;
}

const initialState: Connection = {
  isOnline: navigator ? navigator.onLine : false
};

const selectors = {
  isOnline: (state) => state.connection.isOnline
};

const actionCreators = {
  changeStatus: {
    reduce: (state, action) => {
      return { ...state, isOnline: action.payload };
    }
  } as CustomAction<void, any>
};

export const connectionModel = new Generator<Connection, typeof actionCreators>(
  'connection'
).createModel({
  initialState,
  selectors,
  actionCreators
});

export const middleware = ({ dispatch }) => {
  if (navigator) {
    const changeStatus = (...args) =>
      dispatch(connectionModel.actionCreators.changeStatus(...args));

    window.addEventListener('online', () => changeStatus(true));
    window.addEventListener('offline', () => changeStatus(false));
  }

  return (next) => (action) => next(action);
};
