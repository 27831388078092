import { ownershipsModel } from 'data/models/entities/ownerships';
import { Generator } from 'data/models/generator';
import _ from 'lodash';
import { auditableConfig } from 'src/modules/common/auditable/auditable-config';
import { ReportFormat } from 'src/modules/common/types/reporting';
import { contactsModel } from 'src/modules/contacts/models/contacts';
import { usersModel } from 'src/modules/users/models/users';
import { api } from 'utils/api/api-client';
import { RecordTypes } from 'data/models/types';
import { Statement } from '../types';

const config = {
  entities: {
    related: {
      // TODO: if we support other object types in the future, we will need to add there here too
      ownership: {
        include: 'object',
        model: ownershipsModel
      },
      contact: {
        include: 'object',
        model: contactsModel
      },
      recipients: {
        include: 'recipients',
        model: [contactsModel]
      },
      voided_by: {
        include: 'voided_by',
        model: usersModel
      },
      ...auditableConfig
    }
  }
};

type StatementPreviewData = {
  statementId: string;
};

type PendingStatementPreviewData = {
  statementFrom?: string;
  statementTo: string;
  objectId: string;
  objectType: typeof RecordTypes.Ownership | typeof RecordTypes.Contact;
};

interface PreviewItemConfig {
  statementData: StatementPreviewData | PendingStatementPreviewData;
  recipientId?: string;
  format?: ReportFormat;
  disposition?: 'download_link' | 'inline_link' | 'inline';
  reportId?: 'income-summary' | 'income-detailed';
  mergeFiles?: boolean; // specifies whether you want related bills to be merged into the statement
}

interface DownloadItemConfig {
  statementId: string;
  recipientId: string;
  format?: ReportFormat;
  disposition?: 'download_link' | 'inline_link' | 'inline';
  reportId?: 'income-summary' | 'income-detailed';
  mergeFiles?: boolean; // specifies whether you want related bills to be merged into the statement
}

interface DownloadBulkItemConfig {
  data: {
    statement: {
      id: string;
    };
    recipient: {
      id: string;
    };
  }[];
  disposition?: 'download_link' | 'inline_link';
  mergeFiles?: boolean; // specifies whether you want related bills to be merged into the statement
  format?: ReportFormat;
  reportId?: 'income-summary' | 'income-detailed';
}

const actionCreators = {
  downloadItem: {
    request: ({
      statementId,
      recipientId,
      format = 'pdf',
      reportId = 'income-summary',
      mergeFiles = true,
      disposition = 'download_link'
    }: DownloadItemConfig) =>
      api.post(
        `/reporting/${reportId}`,
        {
          statement: {
            id: statementId
          },
          recipient: {
            id: recipientId
          },
          format,
          merge_files: mergeFiles,
          disposition
        },
        disposition === 'inline'
          ? {
              responseType:
                format === 'html' ? 'text' : format === 'pdf' ? 'blob' : 'json'
            }
          : undefined
      ),
    reduce: _.identity
  },
  viewItem: {
    request: ({
      statementData,
      recipientId,
      format = 'pdf',
      reportId = 'income-summary',
      mergeFiles = true,
      disposition = 'inline_link'
    }: PreviewItemConfig) =>
      api.post(
        `/reporting/${reportId}`,
        {
          ...('statementId' in statementData
            ? // statement exist, just use id
              {
                statement: {
                  id: statementData.statementId
                }
              }
            : // otherwise use pending details
              {
                statement_from: statementData.statementFrom,
                statement_to: statementData.statementTo,
                object_id: statementData.objectId,
                object_type: statementData.objectType
              }),
          ...(recipientId ? { recipient: { id: recipientId } } : {}),
          format,
          merge_files: mergeFiles,
          disposition
        },
        format === 'html' ? { responseType: 'text' } : {}
      ),
    reduce: _.identity
  },
  bulkDownloadItems: {
    request: ({
      data,
      format = 'pdf',
      reportId = 'income-summary',
      mergeFiles = true
    }: DownloadBulkItemConfig) =>
      api.post(`/reporting/${reportId}/bulk`, {
        data: data.map((item) => ({ ...item, merge_files: mergeFiles })),
        format,
        disposition: 'download_link' // this is hardcoded because the hook that downloads the report needs this to be set
      }),
    reduce: _.identity
  }
};

export const statementsModel = new Generator<Statement, typeof actionCreators>(
  'statements',
  config
).createEntityModel({ actionCreators });
