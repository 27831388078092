import { ActionDeclaration } from 'src/modules/common/actions/types/action-declaration-types';
import AddIcon from 'view/components/icons/add';
import { CreatePrepaymentBucketDialog } from 'src/modules/prepayments/dialogs/create-prepayment-bucket-dialog';
import { useDialog } from '@rexlabs/dialog';

export function useGetCreatePrepaymentBucketAction() {
  const { open } = useDialog(CreatePrepaymentBucketDialog);
  return (): ActionDeclaration => {
    return {
      id: 'prepayments',
      group: 'prepayments',
      Icon: AddIcon,
      intent: 'primary',
      label: 'Add prepayment bucket',
      handleAction: () => open()
    };
  };
}
