import { useMutation } from 'react-query';
import { api } from 'utils/api/api-client';
import { queryClient } from 'src/lib/react-query/query-client';
import { ChecklistItemWithId } from '../../common/blocks/create-checklists-block';

export const useChecklistItemMutation = () => {
  return useMutation(
    (payload: {
      skip_triggered_actions?: boolean;
      item: ChecklistItemWithId;
      outcome_value_id?: string;
    }) => {
      const isChecked = Boolean(payload.item.completed_at);
      const endpoint = isChecked ? 'incomplete' : 'complete';
      return api.patch(
        `/tasks/checklist-items/${payload.item.id}/${endpoint}`,
        payload
      );
    },
    {
      onSettled: (_data, _error, variables) => {
        queryClient.invalidateQueries([
          'checklist',
          variables.item.checklistId
        ]);
        queryClient.invalidateQueries('checklists-widget');
      }
    }
  );
};
