import * as React from 'react';
import dayjs from 'dayjs';

import { push, useWhereabouts } from '@rexlabs/whereabouts';
import { SingleActionDeclaration } from 'src/modules/common/actions/types/action-declaration-types';
import ROUTES from 'routes/app';

export function useSMSFilter() {
  const match = useWhereabouts();

  const options = React.useMemo(() => {
    const options: SingleActionDeclaration[] = [];

    for (let i = 0; i < 12; i++) {
      const date = dayjs().subtract(i, 'month');

      const label = date.format('MMM YYYY');
      const id = date.format('YYYY-MM');

      const handleAction = () => {
        push(ROUTES.SUBSCRIPTION_SETTINGS, {
          query: {
            view: id
          }
        });
      };

      options.push({ id, label, handleAction });
    }

    return options;
  }, []);

  const selectedOption = match.query?.view ?? options[0].id;
  const displayLabel = options.find((option) => option.id === selectedOption)!
    .label;

  return React.useMemo(() => {
    return {
      actions: [
        {
          label: displayLabel,
          actions: options
        }
      ]
    };
  }, [options, displayLabel]);
}
