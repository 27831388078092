import { ownershipsModel } from 'data/models/entities/ownerships';
import { Generator, GeneratorConfig } from 'data/models/generator';

import { contactsModel } from 'src/modules/contacts/models/contacts';
import { trustJournalEntryModel } from 'src/modules/trust-journal-entries/models/trust-journal-entries';

import { OwnershipTaxTransaction } from '../types/ownership-tax-transaction';

const config: GeneratorConfig = {
  entities: {
    related: {
      ownership: {
        include: 'ownership',
        model: ownershipsModel
      },
      contact: {
        include: 'contact',
        model: contactsModel
      },
      source: {
        include: 'source,source.bank_account,source.payment_information',
        model: trustJournalEntryModel
      }
    }
  }
};

export const ownershipTaxTransactionsModel = new Generator<OwnershipTaxTransaction>(
  'ownership-tax-transactions',
  config
).createEntityModel();
