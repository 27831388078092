import React, { useMemo } from 'react';

import { BlockConfig } from 'view/components/record-screen/types';
import { ActionButtons } from 'view/components/@luna/action-buttons';
import { OwnershipsList } from 'src/modules/ownerships/components/connectors/ownerships-list';
import { useGetCreateOwnershipAction } from 'src/modules/ownerships/hooks/use-get-create-ownership-action';

export const ownershipsBlock: BlockConfig = {
  id: 'ownerships',
  title: 'Ownerships',
  View: ({ data }) => {
    return <OwnershipsList contactId={data.id} />;
  },
  Actions: () => {
    const getCreateOwnership = useGetCreateOwnershipAction();
    const createOwnership = useMemo(() => getCreateOwnership(), []);
    return <ActionButtons actions={[createOwnership]} />;
  }
};
