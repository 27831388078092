import React from 'react';

import { useDialog } from '@rexlabs/dialog';
import { HiddenField } from '@rexlabs/form';

import { Banner } from 'src/modules/common/components/banner';

import { BlockConfig } from 'view/components/record-screen/types';
import { ManageNameAndSortingFieldArray } from 'view/components/manage-name-and-sorting-field-array';

import { DeleteChecklistItemDialog } from '../dialogs/delete-checklist-item';

const validate = {
  definitions: {
    'checklist_template_items.*.label': {
      name: 'label',
      rules: 'required'
    }
  }
};

export const upsertChecklistItemsBlock: BlockConfig = {
  id: 'checklist-template-items',
  title: 'Checklist items',
  validate,

  Edit: ({ values, setFieldValue, blockProps }) => {
    const showBanner = values?.checklist_template_items?.length === 0;
    const deleteChecklistItemDialog = useDialog(DeleteChecklistItemDialog);

    const onDelete = (actions, index) => {
      if (!blockProps.isEditMode) {
        return actions.remove();
      }

      deleteChecklistItemDialog.open({
        checklistItem: values.checklist_template_items[index],
        onDelete: () => {
          actions.remove();

          const checklistItemId = values.checklist_template_items[index].id;
          const ids = Array.isArray(values.delete_ids)
            ? values.delete_ids.concat(checklistItemId)
            : [checklistItemId];

          setFieldValue?.('delete_ids', ids);
        }
      });
    };

    return (
      <>
        {showBanner && (
          <Banner color='subtle'>Start by adding a checklist item below</Banner>
        )}

        <ManageNameAndSortingFieldArray
          fieldArrayName='checklist_template_items'
          textInputPlaceholder='Enter checklist item name'
          onDelete={onDelete}
        />

        <HiddenField name='delete_ids' />
      </>
    );
  }
};
