import { GhostIconButton } from '@rexlabs/button';
import { icon, StylesProvider } from '@rexlabs/styling';
import * as React from 'react';
import Icons from '@rexlabs/icons';

export interface ResolutionActionButtonProps {
  isResolved: boolean;
  onClick: () => Promise<void>;
}

export function ResolutionActionButton({
  isResolved,
  onClick
}: ResolutionActionButtonProps) {
  const resolutionOverrides = {
    button: {
      ...icon.tokens({
        color: ({ token }) =>
          token(isResolved ? 'palette.red.900' : 'palette.green.900')
      })
    }
  };

  return (
    <StylesProvider tokens={resolutionOverrides}>
      <GhostIconButton
        Icon={isResolved ? Icons.ArrowDownCircle : Icons.ArrowUpCircle}
        size='xs'
        onClick={onClick}
      />
    </StylesProvider>
  );
}
