import { useState, useLayoutEffect } from 'react';
import { isEqual } from 'lodash';

interface Rules {
  [key: string]: number;
}

interface Resolutions {
  [key: string]: boolean;
}

function matchRules(minWidth: number | undefined, rules: Rules) {
  return Object.keys(rules).reduce(
    (all, key) => ({
      ...all,
      [key]: rules[key] && minWidth && minWidth >= rules[key]
    }),
    {}
  );
}

export function useResponsiveContainerRules(
  container: HTMLElement | null,
  rules: Rules
): Resolutions {
  const [state, setState] = useState(matchRules(container?.clientWidth, rules));

  // HACK: we want to run the layout effect every time the rules change, but
  // we want people to be able to pass in inline objects (which would break
  // referencial equality), so to work around it we stringify the rules here for
  // the hook dependencies. We're always dealing with small objects here,
  // so the performance penalty should be neglectable
  const stringifiedRules = JSON.stringify(rules);

  useLayoutEffect(
    () => {
      if (container) {
        const observer = new ResizeObserver((entries) => {
          window.requestAnimationFrame(() => {
            if (!Array.isArray(entries) || !entries.length) return;

            const newState = matchRules(
              entries?.[0]?.contentRect?.width,
              rules
            );
            setState((state) => (isEqual(newState, state) ? state : newState));
          });
        });

        observer.observe(container);
        return () => observer.unobserve(container);
      }
    },
    // eslint-disable-next-line
    [container, stringifiedRules]
  );

  return state;
}
