import React from 'react';

import { Select } from '@rexlabs/select';
import { normaliseValueListItem } from 'utils/normaliser/value-list-item';
import { TaskType } from 'src/modules/tasks/common/types/TaskType';
import { InputProps } from '@rexlabs/form';
import { useChecklistItemRelatedDatesQuery } from 'src/modules/tasks/settings/hooks/use-checklist-item-related-dates-query';

export interface ChecklistItemRelatedDateSelectProps extends InputProps {
  taskType: TaskType;
}

export function ChecklistItemRelatedDateSelect(
  props: ChecklistItemRelatedDateSelectProps
) {
  const { taskType } = props;

  const { data: relatedDatesData } = useChecklistItemRelatedDatesQuery(
    taskType
  );

  return (
    <Select
      {...props}
      items={relatedDatesData?.data?.related_dates}
      id='checklist-item-related-date-select'
      normaliser={normaliseValueListItem}
      searchable={false}
    />
  );
}
