import React from 'react';
import { RecordDialog } from 'view/components/record-screen/dialog/dialog';
import { parseUploadedFileMeta } from 'utils/files';
import { FileUpload } from 'src/modules/common/types/file';
import { imagesBlock } from '../blocks/images-block';

interface AddImagesDialogProps
  extends Pick<
    React.ComponentProps<typeof RecordDialog>,
    'onClose' | 'handleSubmit'
  > {
  images: FileUpload[];
  areaItemName: string;
}

const content = [
  {
    id: 'images',
    label: '',
    blocks: [imagesBlock]
  }
];

export function AddImagesDialog({
  onClose,
  images,
  areaItemName,
  handleSubmit
}: AddImagesDialogProps) {
  const updatedImageData = images ? parseUploadedFileMeta(images) : [];

  return (
    <RecordDialog
      title={`Images - ${areaItemName}`}
      onClose={onClose}
      content={content}
      submitLabel='Done'
      data={{ images: updatedImageData }}
      initialValues={{ images: updatedImageData }}
      handleSubmit={handleSubmit}
    />
  );
}
