import React from 'react';
import { BlockConfig } from 'view/components/record-screen/types';
import { Grid } from 'view/components/@luna/form/grid';
import { Field } from '@rexlabs/form';
import { TextArea, TextInput } from '@rexlabs/text-input';
import { Checkbox } from '@rexlabs/checkbox';
import { Banner } from 'src/modules/common/components/banner';
import InfoIcon from 'view/components/icons/info';
import { Value } from 'view/components/values';
import { BooleanValue } from 'view/components/values/boolean';
import { capitalize, lowerCase } from 'lodash';
import { ChecklistTemplate } from '../types/ChecklistTemplate';
import { InspectionTypeInput } from '../../common/components/inspection-type-input';
import { ChecklistTemplatesForm } from '../types/ChecklistTemplatesForm';

export const checklistTemplateDetailsBlock: BlockConfig<
  ChecklistTemplate,
  any,
  ChecklistTemplatesForm
> = {
  id: 'details',
  title: 'Details',
  validate: {
    definitions: {
      label: { rules: 'required', name: 'checklist name' },
      description: { rules: 'required' },
      inspection_type: { rules: 'required_if:task_type.id,task_inspection' }
    },
    messages: {
      required_if: 'The :attribute field is required.'
    }
  },
  Edit({ data }) {
    return (
      <Grid columns={2}>
        <Banner intent={'information'} align='start' Icon={InfoIcon}>
          Changes made to this checklist will be applied to new tasks only.
        </Banner>
        <Field name='label' label='Checklist name' Input={TextInput} />
        <Field
          name='description'
          label='Description'
          Input={TextArea}
          inputProps={{
            placeholder: 'Describe when this checklist should be used...'
          }}
        />
        <Field
          name='is_default'
          label=''
          Input={Checkbox}
          inputProps={{
            label: 'Add this checklist to new tasks by default'
          }}
        />
        {data?.task_type?.id === 'task_inspection' && (
          <InspectionTypeInput hideOptionalLabel={true} />
        )}
      </Grid>
    );
  },
  View({ data }) {
    return (
      <Grid columns={2}>
        <Value label='Description' value={data?.description} />
        <BooleanValue
          label={'Add to tasks by default'}
          value={data?.is_default}
          trueLabel='Yes'
          falseLabel='No'
        />
        {data?.task_type?.id === 'task_inspection' &&
          data?.discriminator_value && (
            <Value
              label='Inspection type'
              value={capitalize(lowerCase(data.discriminator_value))}
            />
          )}
      </Grid>
    );
  }
};
