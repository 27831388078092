import { valueListFactory } from 'src/lib/testing/factories/value-list-factory';
import { getUploadedFileMeta } from 'utils/files';
import { mapTaskFormDataToTaskCreateRequest } from '../../common/mappers/map-task-form-data-to-task-create-request';
import { TaskCreateRequest } from '../../common/types/TaskCreateRequest';
import { WorkOrderFormData } from '../types/WorkOrderFormData';

export async function mapWorkOrderFormDataToTaskCreateRequest(
  formData: Partial<WorkOrderFormData>
): Promise<Partial<TaskCreateRequest>> {
  // map the base values for the task
  const base = mapTaskFormDataToTaskCreateRequest(formData);

  const formDataDetails = formData.details;
  const workDoneByType = formDataDetails?.work_done_by_type;

  const message = {
    template: formDataDetails?.message?.template,
    subject:
      formDataDetails?.message?.subject ||
      formDataDetails?.message?.template?.channels?.[0].email_subject,
    body:
      formDataDetails?.message?.body ||
      formDataDetails?.message?.template?.channels?.[0].plain_text_body,
    inline_attachments: formDataDetails?.message?.inline_attachments || [],
    templated_attachments:
      formDataDetails?.message?.templated_attachments || [],
    send_immediately: false
  };

  const supplierWorkOrderDetails = {
    work_done_by: formDataDetails?.supplier,
    task_quote: formDataDetails?.quote,
    access_provided_by: formDataDetails?.access_provided_by,
    bill_to: formDataDetails?.bill_to || null
  };

  const ownerWorkOrderDetails = {
    work_done_by: formDataDetails?.owner
  };

  const details: Record<string, any> = {
    ...(workDoneByType === 'supplier'
      ? supplierWorkOrderDetails
      : ownerWorkOrderDetails),
    amount: formDataDetails?.amount || null,
    message,
    work_done_by_type: workDoneByType && valueListFactory(workDoneByType),
    status: valueListFactory('issued')
  };

  const images = formDataDetails?.images;
  if (images != null) {
    details.images = await getUploadedFileMeta(images!);
  }

  // Remove any null values
  Object.keys(details).forEach((key) => {
    if (details[key] === null) {
      delete details[key];
    }
  });

  return {
    ...base,
    type: valueListFactory('task_work_order'),
    details
  };
}
