import { DialogProps } from '@rexlabs/dialog';
import * as React from 'react';
import { Dialog } from 'view/components/@luna/dialog';
import { Value } from 'view/components/values';
import { Column, Grid } from 'view/components/@luna/form/grid';

import { CurrencyValue } from 'view/components/values/currency';
import { StandardDialogFooter } from 'view/components/dialogs/components/standard-dialog-footer';
import { PrimaryButton } from '@rexlabs/button';
import { FolioCommissionTemplate } from '../types/FolioCommissionTemplate';
import { ViewSupplierCommissionTiers } from '../components/view-supplier-commission-tiers';
import { ProgressiveTemplateBanner } from '../components/progressive-template-banner';
import { TieredTemplateBanner } from '../components/tiered-template-banner';

export interface ViewSupplierCommissionTemplateDialogProps extends DialogProps {
  supplierCommissionTemplate: FolioCommissionTemplate;
}

function TemplateContent({
  supplierCommissionTemplate
}: {
  supplierCommissionTemplate: FolioCommissionTemplate;
}) {
  const structureTypeId = supplierCommissionTemplate?.structure_type.id;
  const commissionLabel = 'Commission';

  switch (structureTypeId) {
    case 'flat_fee':
      return (
        <CurrencyValue
          label={commissionLabel}
          value={supplierCommissionTemplate?.commission_amount}
        />
      );
    case 'percentage':
      return (
        <Value
          label={commissionLabel}
          value={`${supplierCommissionTemplate?.commission_amount}%`}
        />
      );
    case 'tiered':
      return (
        <Column width={2}>
          <ViewSupplierCommissionTiers
            tiers={supplierCommissionTemplate.tiers}
            templateStructureType={structureTypeId}
          />
          <TieredTemplateBanner />
        </Column>
      );
    case 'progressive':
      return (
        <Column width={2}>
          <ViewSupplierCommissionTiers
            tiers={supplierCommissionTemplate.tiers}
            templateStructureType={structureTypeId}
          />
          <ProgressiveTemplateBanner />
        </Column>
      );
  }
}

export function ViewSupplierCommissionTemplateDialog({
  onClose,
  supplierCommissionTemplate
}: ViewSupplierCommissionTemplateDialogProps) {
  const structureType = supplierCommissionTemplate?.structure_type;

  return (
    <Dialog title='View commission template' onClose={onClose}>
      <Grid columns={2}>
        <Value
          label='Commission template name'
          value={supplierCommissionTemplate?.name}
        />
        <Value label='Structure' value={structureType.label} />
        <TemplateContent
          supplierCommissionTemplate={supplierCommissionTemplate}
        />
      </Grid>
      <StandardDialogFooter>
        <PrimaryButton onClick={onClose}>Close</PrimaryButton>
      </StandardDialogFooter>
    </Dialog>
  );
}
