import { useQuery } from 'react-query';
import { api } from 'utils/api/api-client';
import { AddressCountry, AddressSpecData } from '../types/property-types';

export function useAddressSpecificationQuery(
  countryId: AddressCountry = 'AUS'
) {
  const addressSpecQuery = useQuery(['addressSpecification', countryId], {
    queryFn: () => {
      return api.get<AddressSpecData>(`/addresses/specification/${countryId}`);
    },
    select: (data) => data.data,
    staleTime: Infinity // This shouldn't need to refetch during a session
  });

  return addressSpecQuery;
}
