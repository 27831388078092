/*
|-------------------------------------------------------------------------------
| Singleton App Store (Redux)
|-------------------------------------------------------------------------------
|
| It's inclusion in other modules in the App should ideally come from dependency
| injection techniques to keep those files testable.
|
*/

import { combineReducers } from 'redux';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import {
  whereaboutsMiddleware,
  whereaboutsPersistor,
  whereaboutsReducer as whereabouts
} from '@rexlabs/whereabouts';
import { combineModels } from '@rexlabs/model-generator';

import { configureStore } from 'utils/redux';

/*
| Core Models
|------------------------
*/
import { sessionModel } from 'data/models/custom/session';
import {
  connectionModel,
  middleware as connectionMiddleware
} from 'data/models/custom/connection';

/*
 | Entity Models (Model Generator)
 |------------------------
 */
// INJECT: Import models here
import { auditLogsModel } from 'src/modules/audit-logs/models/audit-logs-model';
import { ownershipsModel } from 'data/models/entities/ownerships';
import { tenancyModel } from 'data/models/entities/tenancies';
import { contactsModel } from 'src/modules/contacts/models/contacts';
import { complianceEntriesModel } from 'src/modules/compliance/common/models/compliance-entries';
import { financialsRentDiscountsModel } from 'data/models/entities/financials/rent/discounts';
import { financialsRentAdjustmentsModel } from 'data/models/entities/financials/rent/adjustments';
import { financialsInvoicesModel } from 'data/models/entities/financials/invoices';
import { financialsTaxTypesModel } from 'data/models/entities/financials/tax-types';
import { financialsCashBookEntriesModel } from 'data/models/entities/financials/cash-book-entries';
import { trustJournalEntryModel } from 'src/modules/trust-journal-entries/models/trust-journal-entries';
import { financialsReceiptFundsModel } from 'data/models/entities/financials/receipt-funds';
import { settingsSupplierComplianceCategoriesModel } from 'data/models/entities/settings/supplier-compliance-categories';
import { settingsComplianceTypesModel } from 'src/modules/compliance/common/models/compliance-types';
import { settingsSupplierCategoryRequirementsModel } from 'data/models/entities/settings/supplier-category-requirements';
import { settingsPropertyComplianceCategoriesModel } from 'data/models/entities/settings/property-compliance-categories';
import { settingsPropertyCategoryRequirementsModel } from 'data/models/entities/settings/property-category-requirements';
import { documentsModel } from 'data/models/entities/documents';
import { propertyTenancyModel } from 'src/modules/property-tenancies/models/property-tenancy-model';
import { financialsTrustAccountsModel } from 'data/models/entities/financials/trust-accounts';
import { trustLedgerModel } from 'src/modules/trust-ledgers/models/trust-ledger-model';
import { bankDepositModel } from 'src/modules/banking/bank-deposits/models/bank-deposit-model';
import { propertyOwnershipModel } from 'src/modules/property-ownerships/models/property-ownership-model';
import { communicationTemplatesModel } from 'data/models/entities/communication/templates';
import { communicationLetterLayoutsModel } from 'data/models/entities/communication/letter-layouts';
import { communicationEmailLayoutsModel } from 'data/models/entities/communication/email-layouts';
import { usersModel } from 'src/modules/users/models/users';
import { rolesModel } from 'src/modules/roles/models/roles';
import { actionsUserInvitesModel } from 'data/models/entities/user-invites';
import { chartOfAccountsAccountModel } from 'src/modules/chart-of-accounts/models/chart-of-accounts';
import { portfoliosModel } from 'src/modules/portfolios/models/portfolios-model';
import { financialsChargeEntriesModel } from 'data/models/entities/financials/charge-entries';
import { accountingJournalEntryModel } from 'src/modules/account-journal-entries/model/accounting-journal-entry-model';
import { financialsRecurringChargeEntriesModel } from 'data/models/entities/financials/recurring-charge-entries';
import { financialsRecurringInvoicesModel } from 'data/models/entities/financials/recurring-invoices';
import { financialsSecurityDepositRequestsModel } from 'data/models/entities/financials/security-deposit-requests';
import { financialsSecurityDepositPaymentsModel } from 'data/models/entities/financials/security-deposit-payments';
import { bankDepositLineItemModel } from 'src/modules/banking/bank-deposits/models/bank-deposit-line-item-model';
import { bankWithdrawalLineItemModel } from 'src/modules/banking/bank-withdrawals/models/bank-withdrawal-line-item-model';
import { financialsReconciliationsModel } from 'data/models/entities/financials/reconciliations';
import { trustJournalEntryLineItemModel } from 'src/modules/trust-journal-entry-line-items/models/trust-journal-entry-line-item-model';
import { propertiesModel } from 'data/models/entities/properties';
import { bankAccountModel } from 'data/models/entities/financials/bank-accounts';
import { financialsCreditNotesModel } from 'data/models/entities/financials/credit-notes';
import { financialsReconciliationsBankDepositsModel } from 'data/models/entities/financials/reconciliations/bank-deposits';
import { financialsReconciliationsBankWithdrawalsModel } from 'data/models/entities/financials/reconciliations/bank-withdrawals';
import { financialsReconciliationsChequesModel } from 'data/models/entities/financials/reconciliations/cheques';
import { financialsReconciliationsAdjustmentsModel } from 'data/models/entities/financials/reconciliations/adjustments';
import { agencyFeeModel } from 'src/modules/agency-fees/models/agency-fee';
import { financialsPendingDisbursementsOwnershipsModel } from 'data/models/entities/financials/pending-disbursements/pending-ownership-disbursement';
import { financialsPendingDisbursementsContactsModel } from 'data/models/entities/financials/pending-disbursements/pending-contact-disbursement';
import { financialsDisbursementsModel } from 'data/models/entities/financials/disbursements/disbursements';
import { ownershipsFeesModel } from 'data/models/entities/ownerships/fees/ownership-fees';
import { ownershipsFeesInactiveTemplatesModel } from 'data/models/entities/ownerships/fees/inactive-ownership-fees';
import { propertyOwnershipsFeesModel } from 'data/models/entities/financials/property-ownerships/property-ownership-fee';
import { propertyOwnershipsFeesInactiveTemplatesModel } from 'data/models/entities/financials/property-ownerships/inactive-property-ownership-fees';
import { contactsPaymentMethodsModel } from 'data/models/entities/contacts/payment-methods';
import { financialsSecurityDepositTypesModel } from 'data/models/entities/financials/security-deposit-types';
import { financialsSecurityDepositsModel } from 'data/models/entities/financials/security-deposits';
import { accountingJournalEntryLineItemModel } from 'src/modules/account-journal-entries/model/accounting-journal-entry-line-item-model';
import { suspendedTransactionsModel } from 'data/models/entities/financials/suspended-transactions';
import { prepaymentBucketsModel } from './modules/prepayments/models/prepayment-buckets-model';
import { propertyAreaTypesModel } from './modules/property-areas/property-area-types/models/property-area-types-model';
import { propertyAreasModel } from './modules/property-areas/property-areas/models/property-areas-model';
import { appliancesModel } from './modules/appliances/models/appliances-model';
import { financialsUploadedBillsModel } from './modules/bill-processing/models/uploaded-bills';
import { messagesModel } from './modules/communications/messages/models/messages-model';
import { messageTemplatesModel } from './modules/communications/message-templates/models/message-templates-model';
import { notesModel } from './modules/note/models/notes-model';
import { bankWithdrawalModel } from './modules/banking/bank-withdrawals/models/bank-withdrawal-model';
import { statementsModel } from './modules/statements/models/statements';
import { channelMessageRecipientsModel } from './modules/communications/messages/models/channel-message-recipients-model';
import { channelMessagesModel } from './modules/communications/messages/models/channel-messages-model';
import { paymentHistoryModel } from './modules/payment-history/models/payment-history-model';
import { tasksModel } from './modules/tasks/common/models/tasks-model';
import { checklistTemplatesModel } from './modules/tasks/settings/models/checklist-templates-model';
import { bankStatementTransactionsModel } from './modules/banking/batch-receipting/models/bank-statement-transaction-model';
import { keySetsModel } from './modules/key-sets/data/key-set-model';
import { portfolioMembersModel } from './modules/portfolios/models/portfolio-members-model';
import { inspectionRunsModel } from './modules/tasks/inspections/models/inspection-run-model';
import { accountCreditLogsModel } from './modules/settings/subscriptions/model/account-credit-logs-model';
import { ownershipTaxTransactionsModel } from './modules/tax/models/ownership-tax-transactions-model';
import { taxSummariesModel } from './modules/tax/models/tax-summaries-model';
import { servicePackagesModel } from './modules/service-packages/models/service-packages-model';
import { messageBatchesModel } from './modules/communications/messages/models/message-batches-model';
import { bankStatementTransactionImportsModel } from './modules/banking/batch-receipting/models/bank-statement-transaction-imports-model';
import { checklistsModel } from './modules/tasks/checklists/models/checklists-model';
import { checklistItemsModel } from './modules/tasks/checklists/models/checklist-items-model';
import { folioCommissionTemplatesModel } from './modules/supplier-commission/models/folio-commission-templates-model';
/*
| Setup for Store (incl. persistance)
|------------------------
*/
const persistSession = persistReducer(
  {
    key: '@alfred/session',
    storage,
    blacklist: ['ready']
  },
  sessionModel
);

const persistWhereabouts = persistReducer(
  {
    key: '@alfred/whereabouts',
    storage: whereaboutsPersistor
  },
  whereabouts
);
/**
 * NOTE: the key must match the path of the the end point.
 * For example, the end point for agency fees is "financials/agency-fees"
 * so the key must be "financialsAgencyFees".
 * If you are finding your tables are not displaying data when using model generator
 * query, the above is likely the reason.
 */
/**
 * NOTE: the items MUST return an id field, as this is how we determine the key of the items
 */
const entities = combineModels('entities', {
  auditLogs: auditLogsModel,
  financialsReconciliationsSuspendedTransactions: suspendedTransactionsModel,
  financialsPrepaymentBuckets: prepaymentBucketsModel,
  propertiesPropertyAreas: propertyAreasModel,
  propertiesPropertyAreaTypes: propertyAreaTypesModel,
  appliances: appliancesModel,
  financialsUploadedBills: financialsUploadedBillsModel,
  tasks: tasksModel,
  tasksChecklistTemplates: checklistTemplatesModel,
  tasksChecklistItems: checklistItemsModel,
  tasksChecklists: checklistsModel,
  communicationMessages: messagesModel,
  communicationMessageTemplates: messageTemplatesModel,
  communicationChannelMessageRecipients: channelMessageRecipientsModel,
  communicationChannelMessages: channelMessagesModel,
  communicationMessageBatches: messageBatchesModel,
  notes: notesModel,
  properties: propertiesModel,
  propertyTenancies: propertyTenancyModel,
  propertyOwnerships: propertyOwnershipModel,
  ownerships: ownershipsModel,
  tenancies: tenancyModel,
  contacts: contactsModel,
  complianceEntries: complianceEntriesModel,
  financialsTrustAccounts: financialsTrustAccountsModel,
  financialsTrustLedgers: trustLedgerModel,
  financialsRentDiscounts: financialsRentDiscountsModel,
  financialsRentAdjustments: financialsRentAdjustmentsModel,
  financialsInvoices: financialsInvoicesModel,
  financialsRecurringInvoices: financialsRecurringInvoicesModel,
  financialsTaxTypes: financialsTaxTypesModel,
  financialsCashBookEntries: financialsCashBookEntriesModel,
  financialsTrustJournalEntries: trustJournalEntryModel,
  financialsTrustJournalEntryLineItems: trustJournalEntryLineItemModel,
  financialsReceiptFunds: financialsReceiptFundsModel,
  financialsBankDeposits: bankDepositModel,
  financialsBankDepositLineItems: bankDepositLineItemModel,
  financialsBankWithdrawals: bankWithdrawalModel,
  financialsBankWithdrawalLineItems: bankWithdrawalLineItemModel,
  financialsRecurringChargeEntries: financialsRecurringChargeEntriesModel,
  communicationLetterLayouts: communicationLetterLayoutsModel,
  communicationEmailLayouts: communicationEmailLayoutsModel,
  settingsSupplierComplianceCategories: settingsSupplierComplianceCategoriesModel,
  settingsComplianceTypes: settingsComplianceTypesModel,
  settingsSupplierCategoryRequirements: settingsSupplierCategoryRequirementsModel,
  settingsPropertyComplianceCategories: settingsPropertyComplianceCategoriesModel,
  settingsPropertyCategoryRequirements: settingsPropertyCategoryRequirementsModel,
  communicationTemplates: communicationTemplatesModel,
  documents: documentsModel,
  users: usersModel,
  roles: rolesModel,
  userInvites: actionsUserInvitesModel,
  financialsChartOfAccounts: chartOfAccountsAccountModel,
  portfolios: portfoliosModel,
  financialsChargeEntries: financialsChargeEntriesModel,
  financialsAccountingJournalEntries: accountingJournalEntryModel,
  financialsAccountingJournalEntryLineItems: accountingJournalEntryLineItemModel,
  financialsBankAccounts: bankAccountModel,
  financialsCreditNotes: financialsCreditNotesModel,
  financialsReconciliations: financialsReconciliationsModel,
  financialsReconciliationsBankDeposits: financialsReconciliationsBankDepositsModel,
  financialsReconciliationsBankWithdrawals: financialsReconciliationsBankWithdrawalsModel,
  financialsReconciliationsCheques: financialsReconciliationsChequesModel,
  financialsReconciliationsAdjustments: financialsReconciliationsAdjustmentsModel,
  financialsAgencyFees: agencyFeeModel,
  financialsPendingDisbursementsOwnerships: financialsPendingDisbursementsOwnershipsModel,
  financialsPendingDisbursementsContacts: financialsPendingDisbursementsContactsModel,
  financialsDisbursements: financialsDisbursementsModel,
  ownershipsFees: ownershipsFeesModel,
  ownershipsFeesInactiveTemplates: ownershipsFeesInactiveTemplatesModel,
  propertyOwnershipsFees: propertyOwnershipsFeesModel,
  propertyOwnershipsFeesInactiveTemplates: propertyOwnershipsFeesInactiveTemplatesModel,
  contactsPaymentMethods: contactsPaymentMethodsModel,
  financialsSecurityDepositTypes: financialsSecurityDepositTypesModel,
  financialsSecurityDeposits: financialsSecurityDepositsModel,
  financialsSecurityDepositRequests: financialsSecurityDepositRequestsModel,
  financialsSecurityDepositPayments: financialsSecurityDepositPaymentsModel,
  statements: statementsModel,
  financialsPaymentHistory: paymentHistoryModel,
  financialsBankStatementTransactions: bankStatementTransactionsModel,
  keySets: keySetsModel,
  portfolioMembersModel: portfolioMembersModel,
  inspectionRuns: inspectionRunsModel,
  accountCreditLogs: accountCreditLogsModel,
  ownershipTaxTransactions: ownershipTaxTransactionsModel,
  financialsTaxSummaries: taxSummariesModel,
  servicePackages: servicePackagesModel,
  financialsBankStatementTransactionImports: bankStatementTransactionImportsModel,
  folioCommissionTemplates: folioCommissionTemplatesModel
});

const reducers = combineReducers({
  session: persistSession,
  connection: connectionModel,
  whereabouts: persistWhereabouts,
  entities
});

const store = configureStore(reducers, [
  connectionMiddleware,
  whereaboutsMiddleware
]);

const persistor = persistStore(store, null, () => {
  store.dispatch(sessionModel.actionCreators.init());
});

if (__DEV__) {
  window.purgePersistedStore = persistor.purge;
}

export { store, persistor };
