import 'utils/analytics/init';
import 'utils/globals';
import 'utils/validator-rules';

import config from 'src/config';

import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import React, { Suspense, useEffect, useState } from 'react';
import { render } from 'react-dom';
import { Helmet } from 'react-helmet';
import { Provider } from 'react-redux';
import { RecoilRoot } from 'recoil';
import { PersistGate } from 'redux-persist/integration/react';

import { DialogProvider } from '@rexlabs/dialog';
import { ErrorBoundary } from '@rexlabs/error-boundary';
import { OverlayProvider } from '@rexlabs/overlay';
import { TextProvider } from '@rexlabs/text';

import { AlfredApp } from 'view/app';
import { ToastProvider } from 'view/components/@luna/notifications/toast';

import { persistor, store } from 'src/store';
import { api } from 'utils/api/api-client';

import mapboxgl from 'mapbox-gl';
import minMax from 'dayjs/plugin/minMax';
import { COMPONENTS } from 'theme/components';
import { init } from 'theme/init';
import { TOKENS } from 'theme/tokens';
import { FeatureFlagsProvider } from 'view/components/@luna/feature-flags';
import { TokenCustomiserProvider } from 'view/components/@luna/token-customiser/context';

import { I18nextProvider } from 'react-i18next';
import { QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import appleTouchIcon from 'src/assets/favicon/apple-touch-icon.png';
import favicon16 from 'src/assets/favicon/favicon-16x16.png';
import favicon32 from 'src/assets/favicon/favicon-32x32.png';
import { queryClient } from 'src/lib/react-query/query-client';
import { initialiseSprig } from 'utils/initialise-sprig';
import { AppWideFilterProvider } from 'src/modules/app-wide-filters/contexts/app-wide-filter-context';
import i18n from './modules/i18n/i18n';
import { DrawerProvider } from './modules/drawer/components/drawer-target';

if (process.env.FAKE_MODE) {
  const fakeServer = require('src/mocks');

  const server = fakeServer.worker();
  server.printHandlers();

  server.start();
}

// Enable dayjs plugins
dayjs.extend(minMax);
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(relativeTime);

// add mapBox API key to mapboxgl
mapboxgl.accessToken = config.MAPBOX_API_KEY;

window.app = {
  store,
  api,
  config
};

// Setup global parts of theme
init(TOKENS);

if (!__DEV__ && config.SPRIG_ENV_ID) {
  initialiseSprig(config.SPRIG_ENV_ID);
}

// We need to disable feature flag caching when running in cypress, otherwise it breaks our mocks
const isCypress = !!(window as any).Cypress;

function AlfredMount() {
  const [stableQueryClient] = useState(queryClient);

  useEffect(() => {
    window.document.body.classList.add('ready');
  }, []);

  return (
    <QueryClientProvider client={stableQueryClient}>
      <I18nextProvider i18n={i18n}>
        <RecoilRoot>
          <Provider store={store}>
            <ErrorBoundary>
              <PersistGate loading={null} persistor={persistor}>
                <Suspense fallback={null}>
                  <FeatureFlagsProvider
                    environmentId={config.FLAGSMITH_ENV_ID}
                    cacheFlags={!isCypress}
                  >
                    <ToastProvider>
                      <TokenCustomiserProvider
                        tokens={TOKENS}
                        components={COMPONENTS}
                        debug={__DEV__}
                      >
                        <TextProvider>
                          <OverlayProvider>
                            <AppWideFilterProvider>
                              <DialogProvider>
                                <DrawerProvider>
                                  <Helmet
                                    titleTemplate='%s | Rex Property Management'
                                    defaultTitle='Rex Property Management'
                                  >
                                    <link
                                      rel='apple-touch-icon'
                                      sizes='180x180'
                                      href={appleTouchIcon}
                                    />

                                    <link
                                      href='https://api.mapbox.com/mapbox-gl-js/v2.14.1/mapbox-gl.css'
                                      rel='stylesheet'
                                    />

                                    <link
                                      rel='icon'
                                      type='image/png'
                                      sizes='32x32'
                                      href={favicon32}
                                    />
                                    <link
                                      rel='icon'
                                      type='image/png'
                                      sizes='16x16'
                                      href={favicon16}
                                    />
                                  </Helmet>
                                  <AlfredApp />
                                </DrawerProvider>
                              </DialogProvider>
                            </AppWideFilterProvider>
                          </OverlayProvider>
                        </TextProvider>
                      </TokenCustomiserProvider>
                    </ToastProvider>
                  </FeatureFlagsProvider>
                </Suspense>
              </PersistGate>
            </ErrorBoundary>
          </Provider>
        </RecoilRoot>
        {__DEV__ && <ReactQueryDevtools initialIsOpen={false} />}
      </I18nextProvider>
    </QueryClientProvider>
  );
}

render(<AlfredMount />, document.getElementById('app'));
