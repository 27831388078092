import React from 'react';

import { Field } from '@rexlabs/form';
import { TextArea, TextInput } from '@rexlabs/text-input';

import { Grid } from 'view/components/@luna/form/grid';
import { CentAmountInput } from 'view/components/inputs/cent-amount';
import { DateInput } from 'view/components/@luna/inputs/date-input/date-input';
import { PreviewFileUploadInputWithErrorHandling } from 'view/components/inputs/file-upload-input/preview-file-upload-input-with-error-handling';

export function ReceivedQuoteDetailsForm() {
  return (
    <Grid columns={1}>
      <Grid columns={2}>
        <Field
          id='quoted_price'
          name='details.amount'
          label='Quoted price'
          Input={CentAmountInput}
          optional={false}
        />
        <Field name='details.number' label='Quote number' Input={TextInput} />
      </Grid>
      <Grid columns={1}>
        <Field name='details.notes' label='Notes' Input={TextArea} />
      </Grid>
      <Grid columns={2}>
        <Field
          name='details.available_start_date'
          label='Available start date'
          Input={DateInput}
          inputProps={{
            clearable: true
          }}
        />
      </Grid>
      <Grid columns={1}>
        <Field<typeof PreviewFileUploadInputWithErrorHandling>
          id='details.quote_attachments'
          name='details.quote_attachments'
          label='Upload quote'
          Input={PreviewFileUploadInputWithErrorHandling}
          inputProps={{
            multi: true,
            accept: '.jpeg, .png, .jpg, .pdf'
          }}
        />
      </Grid>
    </Grid>
  );
}
