import React from 'react';
import { Field } from '@rexlabs/form';

import { BlockConfig } from 'view/components/record-screen/types';
import { PropertySelect } from 'view/components/inputs/selects/v4-selects/property-select';
import { Grid } from 'view/components/@luna/form/grid';

export const addPropertiesBlock: BlockConfig = {
  id: 'addProperties',
  title: 'Add Properties',
  validate: {
    definitions: {
      properties: { rules: 'required' }
    }
  },
  Edit: () => (
    <Grid columns={1}>
      <Field
        name='properties'
        id='properties'
        label='Add properties'
        Input={PropertySelect}
        inputProps={{ multi: true }}
      />
    </Grid>
  )
};
