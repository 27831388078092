import React from 'react';

import Box from '@rexlabs/box';
import { FieldProps, Field } from '@rexlabs/form';
import { StyleSheet, useStyles, margin } from '@rexlabs/styling';

const defaultStyles = StyleSheet({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    ...margin.styles({
      top: 'l'
    }),

    height: ({ token }) => token('textInput.height', '4rem')
  },

  left: {
    justifyContent: 'flex-start'
  },

  hasLabel: {
    ...margin.styles({
      top: 'none'
    })
  }
});

interface CenterFieldProps extends FieldProps<any> {
  left?: boolean;
}

// This components is for any input component that needs to be aligned to the center of the standard text input component
// After careful consideration about best approach and place for this to go, it will be moved into luna
export function CenterField({ Input, left, ...props }: CenterFieldProps) {
  const s = useStyles(defaultStyles, 'CenterField');
  return (
    <Field
      {...props}
      optional={false}
      Input={(inputProps) => (
        <Box {...s('container', { left, hasLabel: !!props.label })}>
          <Input {...inputProps} />
        </Box>
      )}
    />
  );
}
