import { InspectionTask } from 'src/modules/tasks/inspections/types/InspectionTask';
import { parseUploadedFileMeta } from 'utils/files';
import { RoutineInspectionAreaFormData } from 'src/modules/tasks/inspections/types/RoutineInspectionAreaFormData';

export function mapTaskToRoutineAreasFormField(
  task: InspectionTask
): Record<string, RoutineInspectionAreaFormData> {
  return (task?.details?.areas?.data || []).reduce((acc, area) => {
    acc[area.id] = {
      comments: area.notes,
      condition: area.condition,
      images: parseUploadedFileMeta(area.images?.data || [])
    };
    return acc;
  }, {});
}
