import React, { ComponentType } from 'react';
import { CommunicationContextType } from 'src/modules/communications/common/types/communication-context-type';
import { RecordDialog } from 'view/components/record-screen/dialog/dialog';
import { DialogContentConfig } from 'view/components/record-screen/dialog/types';
import { RecordSubmitHandler } from 'view/components/record-screen/utils';
import { messageWithReasonBlock } from '../blocks/message-with-reason-block';
import { SplitSubmitButton } from '../components/split-submit-button';

const content: DialogContentConfig = [
  {
    id: 'message-with-reason',
    label: 'Message',
    blocks: [messageWithReasonBlock]
  }
];

export interface MessageWithReasonDialogProps {
  onClose?: () => void;
  title: string;
  submitLabel?: React.ReactNode;
  reasonLabel?: string;
  SubmitButton?: ComponentType<any>;
  handleSubmit: RecordSubmitHandler;
  messageContextType: CommunicationContextType;
  isSubmitting?: boolean;
  submitButtonType?: 'destructive';
}

export function MessageWithReasonDialog({
  onClose,
  title,
  submitLabel,
  submitButtonType,
  reasonLabel,
  handleSubmit,
  messageContextType,
  ...props
}: MessageWithReasonDialogProps) {
  return (
    <RecordDialog
      onClose={onClose}
      blockProps={{
        reasonLabel,
        messageContextType
      }}
      title={title}
      handleSubmit={handleSubmit}
      content={content}
      submitLabel={submitLabel}
      ButtonGroup={SplitSubmitButton}
      buttonGroupProps={{
        submitLabel: submitLabel || title,
        submitButtonType: submitButtonType,
        ...props
      }}
    />
  );
}
