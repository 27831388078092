import { Generator, GeneratorConfig } from 'data/models/generator';
import { Includes } from '@rexlabs/model-generator';
import { OwnershipTaxTransaction } from 'src/modules/tax/types/ownership-tax-transaction';
import { Ownership, ownershipsModel } from '../../ownerships';
import { BasePendingDisbursement } from '.';

export type PendingOwnershipDisbursement = BasePendingDisbursement & {
  object_type: 'ownership';
  object: Ownership;
  withheld_funds?: {
    amount: number;
    reason: string;
    total_amount_including_related_withheld_funds: number;
  };
  ownership_tax_transactions?: Includes<OwnershipTaxTransaction[]>;
};

const config: GeneratorConfig = {
  entities: {
    related: {
      payment_methods: {
        include: 'payment_methods'
      },
      last_disbursement: {
        include: 'last_disbursement'
      },
      disburse_summary: {
        include: 'disburse_summary'
      },
      financial_summary: {
        include: 'financial_summary'
      },
      withheld_funds: {
        include: 'withheld_funds'
      },
      disburseable_direct_debit_invoices: {
        include: 'disburseable_direct_debit_invoices'
      },
      disburseable_bpay_invoices: {
        include: 'disburseable_bpay_invoices'
      },
      other_disburseable_invoices: {
        include: 'other_disburseable_invoices'
      },
      trust_journal_entry_preview: {
        include: 'trust_journal_entry_preview.paid_to'
      },
      object: {
        include: 'object',
        model: ownershipsModel
      },
      general_invoices: {
        include: 'general_invoices'
      },
      ownership_tax_transactions: {
        include:
          'ownership_tax_transactions,ownership_tax_transactions.contact,ownership_tax_transactions.ownership'
      },
      tax_withholdings: {
        include: 'tax_withholdings'
      },
      disbursement_snapshot_summary: {
        include: 'disbursement_snapshot_summary'
      }
    }
  }
};

export const financialsPendingDisbursementsOwnershipsModel = new Generator<PendingOwnershipDisbursement>(
  'financials/pending-disbursements/ownerships',
  config
).createEntityModel();
