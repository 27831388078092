import { WorkOrderTask } from '../types/WorkOrderTask';

export function getWorkOrderStatusChangeTransformPayload(
  workOrderTask: WorkOrderTask
) {
  return (status) => {
    return {
      type: workOrderTask.type,
      details: {
        work_done_by_type: workOrderTask!.details!.work_done_by_type,
        status: {
          id: status
        }
      }
    };
  };
}
