import Box from '@rexlabs/box';
import {
  useToken,
  useStyles,
  StyleSheet,
  text,
  padding
} from '@rexlabs/styling';
import { Semibold } from '@rexlabs/text';
import React from 'react';
import { MessageTemplateSelect } from 'src/modules/communications/common/components/selects/message-template-select';
import InfoCircleIcon from 'view/components/icons/info';
import { Grid } from 'view/components/@luna/form/grid';
import { Field } from '@rexlabs/form';
import { ChecklistItemActionType } from '../types/ChecklistTemplate';
import { TaskType } from '../../common/types/TaskType';

const defaultStyles = StyleSheet({
  header: {
    ...text.styles({
      fallback: 'heading.3'
    }),
    ...padding.styles({
      top: 'l',
      bottom: 's'
    })
  },
  info: {
    fontSize: ({ token }) => token('typography.size.s')
  }
});

export function MessageTemplateContent({
  checklistItemActionType,
  taskType
}: {
  checklistItemActionType: ChecklistItemActionType;
  taskType: TaskType;
}) {
  const token = useToken();
  const s = useStyles(defaultStyles);

  const label = checklistItemActionType === 'send_email' ? 'Email' : 'SMS';

  return (
    <>
      <Box {...s('header')}>{`${label} details`}</Box>
      <Grid columns={1}>
        <Field
          id='message_template'
          name='template'
          label={`${label} template`}
          Input={MessageTemplateSelect}
          inputProps={{
            channelType:
              checklistItemActionType === 'send_email' ? 'email' : 'sms',
            placeholder: 'Select a template',
            contextType: taskType
          }}
        />
        <Semibold>
          <Box
            display='flex'
            flexDirection='row'
            alignItems='center'
            spacing={token('spacing.s')}
            {...s('info')}
          >
            <InfoCircleIcon />
            {`This subtask will be marked as complete after the ${
              checklistItemActionType === 'send_email' ? 'email' : 'SMS'
            } is sent`}
          </Box>
        </Semibold>
      </Grid>
    </>
  );
}
