import { Ownership } from 'data/models/entities/ownerships';
import Box from '@rexlabs/box';
import * as React from 'react';
import { Banner } from 'src/modules/common/components/banner';
import InfoCircleIcon from 'view/components/icons/info';

type Props = {
  ownership: Ownership;
};

export const PreviewStatementsBanner = ({ ownership }: Props) => {
  if (!ownership.owners || ownership.owners.length <= 1) {
    return <></>;
  }

  return (
    <Box>
      <Banner intent='warning' align='start' Icon={InfoCircleIcon}>
        <div>
          For an ownership with more than one owner, we will only show the first
          owners income statement. Once the statement is issued, there will be
          one statement per owner in the ownership.
        </div>
      </Banner>
    </Box>
  );
};
