import * as React from 'react';

import { DialogProps } from '@rexlabs/dialog';

import { RecordDialog } from 'view/components/record-screen/dialog/dialog';
import { RecordSubmitHandler } from 'view/components/record-screen/utils';

import {
  ImportPreviousReportBlockFormValues,
  importPreviousReportBlock
} from '../blocks/import-previous-report-block';
import { useGetPreviousInspectionReports } from '../hooks/use-get-previous-inspection-reports';
import { InspectionTask } from '../types/InspectionTask';

interface ImportPreviousReportDialogProps extends DialogProps {
  inspectionTask: InspectionTask;
  submitHandler: RecordSubmitHandler<ImportPreviousReportBlockFormValues>;
}

const content = [
  {
    id: 'import-previous-report',
    label: 'Import report data',
    blocks: [importPreviousReportBlock]
  }
];

export function ImportPreviousReportDialog({
  inspectionTask,
  onClose,
  submitHandler
}: ImportPreviousReportDialogProps) {
  const { data, isFetched } = useGetPreviousInspectionReports(inspectionTask);

  const handleSubmit: RecordSubmitHandler<ImportPreviousReportBlockFormValues> = (
    formValues
  ) => {
    submitHandler(formValues);

    onClose?.();
  };

  return (
    <RecordDialog
      size='s'
      isLoading={!isFetched}
      title={'Import report data'}
      data={data}
      initialValues={{
        previous_inspection: data?.[0].id
      }}
      content={content}
      onClose={onClose}
      handleSubmit={handleSubmit}
      submitLabel='Import'
    />
  );
}
