import { formatOrdinals } from 'src/modules/common/utils/format-ordinals';
import { DisbursementFrequency } from '../types/frequency';

function getTwiceMonthlyLabel(
  frequencyData: DisbursementFrequency<'twice_monthly'>
) {
  // validate that the required fields are here
  if (
    !frequencyData?.preset?.type?.id ||
    !frequencyData?.preset?.type?.label ||
    !frequencyData?.preset?.data?.first_day ||
    !frequencyData?.preset?.data?.second_day
  ) {
    return undefined;
  }

  return `${frequencyData?.preset?.type.label} on the ${formatOrdinals(
    frequencyData?.preset?.data?.first_day
  )} and ${formatOrdinals(frequencyData?.preset?.data?.second_day)}`;
}

export function getDisbursementPresetLabel(
  frequency: DisbursementFrequency | undefined
) {
  if (!frequency?.preset?.type?.id) return undefined;
  return frequency.preset?.type.id === 'twice_monthly'
    ? getTwiceMonthlyLabel(frequency as DisbursementFrequency<'twice_monthly'>)
    : frequency.preset?.type.label;
}
