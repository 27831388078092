import React, { Fragment, useEffect } from 'react';
import bugsnag from '@bugsnag/js';
import { ErrorDisplay } from '@rexlabs/error-boundary';

export function NotFoundScreen() {
  useEffect(() => {
    bugsnag.notify(new Error('Page Not Found'), (error) => {
      error.unhandled = false;
    });
  }, []);

  return (
    <ErrorDisplay
      status='404 — Page Not Found'
      title='You Look Lost!?'
      message={
        <>
          <p>
            The page you requested couldn’t be found, this could be because you
            followed an old link and it doesn’t exist anymore or you followed a
            link from a specific account.
          </p>
          <p>
            Return to the Dashboard or use the global search to find your way
            back to familiar territory.
          </p>
        </>
      }
    />
  );
}
