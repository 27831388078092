import { Ownership } from 'data/models/entities/ownerships';
import { Tenancy } from 'data/models/entities/tenancies';
import { Contact } from 'src/modules/contacts/types/contact-types';
import { useGetGenericWipAction } from 'view/hooks/actions/use-get-generic-wip-action';
import { useGetCreateInvoiceAction } from './use-get-create-invoice-action';
import { useGetCreateTrustLedgerAction } from './use-get-create-trust-ledger-action';
import {
  useGetReceiptFundsAction,
  GetReceiptFundsActionArgs
} from './use-get-receipt-funds-action';
import { useGetTransferFundsAction } from './use-get-transfer-funds-action';
import { useGetWithdrawFundsAction } from './use-get-withdraw-funds-action';

export type FinancialEntity = Ownership | Tenancy | Contact;

export interface GetFinancialEntityActionArgs {
  entity: FinancialEntity;
  recordTypeName: string;
  onCreate?: (args?: any) => any;
}

type GetFinancialEntityActionsArgs = {
  actionArgs: GetFinancialEntityActionArgs;
  receiptFundsActionArgs?: GetReceiptFundsActionArgs;
};

export function useGetFinancialEntityActionGroup() {
  const getCreateInvoiceAction = useGetCreateInvoiceAction();
  const getReceiptFundsAction = useGetReceiptFundsAction();
  const getTransferFundsAction = useGetTransferFundsAction();
  const getCreateTrustLedgerAction = useGetCreateTrustLedgerAction();
  const getWithdrawFundsAction = useGetWithdrawFundsAction({
    showBannerMessage: true
  });

  const getGenericWipAction = useGetGenericWipAction();

  return ({
    actionArgs,
    receiptFundsActionArgs
  }: GetFinancialEntityActionsArgs) => {
    const createBillAction = getGenericWipAction({
      actionName: 'Create bill',
      id: 'create-bill',
      group: 'financial'
    });

    return [
      getCreateInvoiceAction(actionArgs),
      createBillAction,
      getReceiptFundsAction({
        ...actionArgs,
        ...receiptFundsActionArgs
      }),
      getWithdrawFundsAction(actionArgs),
      getTransferFundsAction(actionArgs),
      getCreateTrustLedgerAction()
    ];
  };
}
