import Icons from '@rexlabs/icons';
import { BaseModelGeneratorModel } from '@rexlabs/model-generator';
import { BulkAction } from '@rexlabs/table/lib/types/bulk-actions/simple';
import { RecordType } from 'data/models/types';
import { useDrawer } from 'src/modules/drawer/hooks/use-drawer';
import { FLAGS } from 'utils/feature-flags';
import EditIcon from 'view/components/icons/edit';
import { useToast } from 'view/components/@luna/notifications/toast';
import { BulkEditDrawer } from '../drawers/bulk-edit-drawer';

type UseGetBulkEditActionOptions = {
  onSuccess?: () => void;
  defaultFields?: string[];
};

export function useGetBulkEditAction(
  recordType: RecordType,
  options: UseGetBulkEditActionOptions
) {
  const { addToast } = useToast();
  const { open } = useDrawer(BulkEditDrawer, {
    title: 'Bulk edit',
    Icon: EditIcon,
    recordType,
    defaultFields: options?.defaultFields ?? []
  });

  const onSuccess = () => {
    options?.onSuccess?.();
    addToast({
      description: 'Bulk edit successfully submitted',
      type: 'success'
    });
  };

  async function handleAction(selectedItems: BaseModelGeneratorModel[]) {
    open({
      selectedItems,
      onSuccess
    });
  }

  return (
    selectedItems: BaseModelGeneratorModel[]
  ): BulkAction & { flag: string } => ({
    label: 'Bulk Edit',
    handleAction: () => handleAction(selectedItems),
    type: 'secondary',
    Icon: Icons.Edit,
    flag: FLAGS.BULK_EDIT
  });
}
