import Box from '@rexlabs/box';
import { ButtonGroup, GhostButton, PrimaryButton } from '@rexlabs/button';
import { PrimaryHeading } from 'src/modules/auth/login/components/heading-primary';
import { Body, Bold } from '@rexlabs/text';
import React from 'react';
import { UserInvitation } from 'src/modules/auth/user-invitation/types/user-invitation';
import { getRecordLabel } from 'utils/records/get-record-label';
import { push } from '@rexlabs/whereabouts';
import { USER_INVITATION_ROUTES } from 'src/modules/auth/user-invitation/routes';
import { useApiClient } from 'src/lib/axios/hooks/use-api-client';
import ROUTES from 'routes/app';
import { useConfirmationDialog } from '@rexlabs/dialog';
import { useSessionState } from 'src/modules/common/hooks/session-state';
import config from 'config';
import { redirectToAuthServiceLogin } from 'utils/api/redirect-to';
import LOGIN_ROUTES from 'src/modules/auth/login/routes';

export function InvitationLoggedInCard({
  invitation,
  token
}: {
  invitation: UserInvitation;
  token: string;
}) {
  const sessionData = useSessionState();
  const axios = useApiClient();
  const confirmationDialog = useConfirmationDialog();

  const handleAccept = async (accept: boolean) => {
    const res = await axios(
      'post',
      'user-invites/complete/existing-user',
      {
        token,
        accept
      },
      {
        baseURL: invitation.silo.base_url, // Make sure this request is routed to the correct region
        headers: { 'X-App-Silo': invitation.silo.id }
      }
    );

    if (res !== false) {
      push(ROUTES.HOME);
    }
  };

  return (
    <>
      <PrimaryHeading level='display'>
        Welcome,{' '}
        {getRecordLabel({ type: 'universal_user', object: sessionData.user! })}
      </PrimaryHeading>

      <Box mt='2.4rem'>
        <Body>
          <Bold>
            {getRecordLabel({
              type: 'universal_user',
              object: invitation.invited_by
            })}
          </Bold>{' '}
          has invited you to join {invitation?.silo.label}.
        </Body>
      </Box>

      <Box>
        <ButtonGroup mt='1.6rem' end>
          <GhostButton
            onClick={() => {
              if (config.AUTHENTICATION_SERVICE_ENABLE_INTEGRATION) {
                redirectToAuthServiceLogin(
                  window.location.origin +
                    LOGIN_ROUTES.USER_INVITATION.config.path +
                    '?token=' +
                    token +
                    '&account_id=' +
                    invitation.silo.id
                );
              } else {
                push(USER_INVITATION_ROUTES.USER_INVITATION_LOGIN, {
                  query: { token }
                });
              }
            }}
          >
            Not you?
          </GhostButton>
          <GhostButton
            onClick={() =>
              confirmationDialog.open({
                title: `Are you sure you want to decline the invitation to ${invitation.silo.label}?`,
                message: 'Declining the invitation will invalidate this link.',
                onConfirm: () => handleAccept(false),
                confirmText: 'Decline',
                destructive: true
              })
            }
          >
            Decline
          </GhostButton>
          <PrimaryButton onClick={() => handleAccept(true)}>
            Accept
          </PrimaryButton>
        </ButtonGroup>
      </Box>
    </>
  );
}
