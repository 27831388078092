import { Tenancy } from 'data/models/entities/tenancies';

import { api } from 'utils/api/api-client';

export async function getTenancyRelatedItems(tenancySearchItem: Tenancy) {
  const relatedFields = [
    'tenants',
    'tenants.contact',
    'related_contacts',
    'related_contacts.contact'
  ];
  const tenancyUrl = `/tenancies/${
    tenancySearchItem.id
  }?include=${relatedFields.join(',')}`;

  const completeTenancy = !tenancySearchItem?.tenants
    ? await api.get<Tenancy>(tenancyUrl).then(({ data }) => data)
    : tenancySearchItem;

  const tenants = (completeTenancy?.tenants || []).map(
    (tenant) => tenant.contact
  );

  const relatedContacts = completeTenancy.related_contacts;

  return {
    tenants,
    relatedContacts
  };
}
