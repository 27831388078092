import { TrustJournalEntry } from 'src/modules/trust-journal-entries/types/trust-journal-entry-types';
import { SearchResultItem } from 'utils/api/get-search-results';
import { Contact } from 'src/modules/contacts/types/contact-types';
import { getSearchResultItemFromObjectAndModel } from 'src/modules/common/utils/search-result-items/get-search-result-items-from-object-and-model';
import { contactsModel } from 'src/modules/contacts/models/contacts';

export const getRecipientsFromTrustJournalEntry = (
  trustJournalEntry: TrustJournalEntry
): SearchResultItem<Contact>[] => {
  if (trustJournalEntry?.contacts?.data) {
    return trustJournalEntry.contacts.data.flatMap((contact) => {
      const searchContact = getSearchResultItemFromObjectAndModel<Contact>(
        contact,
        contactsModel
      );

      return searchContact ? [searchContact] : [];
    });
  }

  const contact = getSearchResultItemFromObjectAndModel<Contact>(
    trustJournalEntry?.contact,
    contactsModel
  );

  return contact ? [contact] : [];
};
