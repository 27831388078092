import * as React from 'react';
import { Columns, RecordTable } from 'view/components/table';
import { InspectionAreaItem } from 'src/modules/tasks/inspections/entry-exit/types/InspectionAreaItem';
import { ImagesWithBadgeIcon } from 'view/components/icons/images-with-badge';
import { ConditionHeader } from 'src/modules/tasks/inspections/entry-exit/components/condition-header';
import { MobileIconActionCell } from 'src/modules/common/components/cells/mobile-icon-action-cell';
import { CommentWithBadgeIcon } from 'view/components/icons/comment-with-badge';
import { MobileDescriptionCell } from 'view/components/cells/mobile-description-cell';
import Box from '@rexlabs/box';
import { Field } from '@rexlabs/form';
import { ConditionInput } from '../../components/condition-button-input';

export const getColumns = (
  setActiveTab: (tabName: string) => void
): Columns<InspectionAreaItem> => [
  {
    id: 'name',
    Header: 'Item',
    width: 120,
    Cell: ({ value }) => <MobileDescriptionCell text={value} />
  },
  {
    id: 'conditions',
    width: 150,
    Header: ConditionHeader,
    accessor: (item) => item,
    Cell: React.memo(({ value }) => {
      return (
        <Box flexDirection='row' gap={12} data-testid='condition-cell'>
          <div>
            <Field
              Input={ConditionInput}
              name={`area_items.${value.id}.conditions.clean`}
              optional={false}
            />
          </div>
          <div>
            <Field
              Input={ConditionInput}
              name={`area_items.${value.id}.conditions.working`}
              optional={false}
            />
          </div>
          <div>
            <Field
              Input={ConditionInput}
              name={`area_items.${value.id}.conditions.undamaged`}
              optional={false}
            />
          </div>
        </Box>
      );
    })
  },
  {
    id: 'comments',
    width: 30,
    Cell: ({ cell }) => (
      <MobileIconActionCell
        Icon={() => (
          <CommentWithBadgeIcon hasBadge={!!cell.row.original.notes} />
        )}
        onClick={() => setActiveTab('comments')}
        data-testid={'comments-icon'}
      />
    )
  },
  {
    id: 'images',
    width: 30,
    Cell: ({ cell }) => (
      <MobileIconActionCell
        Icon={() => (
          <ImagesWithBadgeIcon
            hasBadge={!!cell.row.original.images?.data?.length}
          />
        )}
        onClick={() => setActiveTab('images')}
        data-testid={'images-icon'}
      />
    )
  }
];

export interface InspectionAreaItemsMobileTableProps {
  items: InspectionAreaItem[];
  setActiveTab: (tabName: string) => void;
}

export function InspectionAreaItemsMobileTable({
  items,
  setActiveTab
}: InspectionAreaItemsMobileTableProps) {
  const columns = React.useMemo(() => getColumns(setActiveTab), []);

  return (
    <RecordTable
      id='inspection-area-items-mobile-table'
      columns={columns}
      isLoading={false}
      items={items}
    />
  );
}
