import { useDialog } from '@rexlabs/dialog';

import { SingleActionDeclaration } from 'src/modules/common/actions/types/action-declaration-types';
import { AddReceivedQuoteDialog } from 'src/modules/tasks/quotes/dialogs/add-received-quote-dialog';
import { useGetUpdateQuoteTaskSubmitHandler } from 'src/modules/tasks/quotes/hooks/use-update-quote-task-submit-handler';
import { QuoteTask } from 'src/modules/tasks/quotes/types/QuoteTask';

interface UseGetAddReceivedQuoteActionProps {
  onCreate?: (data: QuoteTask) => void;
}

export function useGetAddReceivedQuoteAction({
  onCreate
}: UseGetAddReceivedQuoteActionProps = {}) {
  const { open: openAddReceivedQuoteDialog } = useDialog(
    AddReceivedQuoteDialog
  );

  const getUpdateQuoteTaskSubmitHandler = useGetUpdateQuoteTaskSubmitHandler({
    onCreate
  });

  return (task: QuoteTask): SingleActionDeclaration => {
    const handleSubmit = getUpdateQuoteTaskSubmitHandler(task.id, 'received');

    return {
      id: 'add-quote',
      intent: 'primary',
      group: 'task-quote',
      label: 'Add quote',
      // TODO: Add action to open the add quote dialog once available
      handleAction: () => openAddReceivedQuoteDialog({ handleSubmit })
    };
  };
}
