import { useDialog } from '@rexlabs/dialog';
import { BaseModelGeneratorModel, EntityModel } from '@rexlabs/model-generator';
import { ActionDeclaration } from 'src/modules/common/actions/types/action-declaration-types';
import { getRecordObject } from 'src/modules/common/utils/Records/get-record-object';
import { FLAGS } from 'utils/feature-flags';
import { CommunicationContextType } from 'src/modules/communications/common/types/communication-context-type';
import { BulkCreateLetterRecordDialog } from '../../dialogs/bulk-create-letter-record-dialog';
import { getContextTypeFromModel } from '../../utils/get-context-type-from-model';
import { getContextTypeFromRecord } from '../../utils/get-context-type-from-record';

export function useGetBulkCreateLetterAction(model: EntityModel<any>) {
  const { open } = useDialog(BulkCreateLetterRecordDialog);

  return (recipients: BaseModelGeneratorModel[]): ActionDeclaration => {
    const recordObjects = recipients.map((recipient) => {
      return getRecordObject(recipient, model);
    });

    const contextType =
      recordObjects?.length > 0
        ? (getContextTypeFromRecord(
            recordObjects[0].object
          ) as CommunicationContextType)
        : getContextTypeFromModel(model);

    return {
      flag: FLAGS.MESSAGES,
      intent: 'primary',
      id: 'bulk-create-letters',
      group: 'messages',
      label: 'Create letters',
      handleAction: async () => {
        open({
          data: {
            recordObjects,
            contextType
          }
        });
      }
    };
  };
}
