import { Task } from '../types/Task';

export const closedDate = {
  id: 'closed_date',
  Header: 'Closed date',
  accessor: (item: Task<any>) => item.closed_at,
  type: 'date',
  width: 140
} as const;

export const managedBy = {
  id: 'managed_by',
  Header: 'Managed by',
  type: 'contact',
  accessor: (item: Task<any>) => item.managed_by
} as const;
