import React, { ReactNode } from 'react';

import { StyleSheet, useStyles } from '@rexlabs/styling';
import { Body, Bold } from '@rexlabs/text';
import Tooltip from '@rexlabs/tooltip';
import { Grid, Column } from '@rexlabs/grid';
import Box from '@rexlabs/box';

import {
  ColoredCard,
  ColouredCardIntent
} from 'view/components/cards/colored-card';
import { formatCurrency } from 'utils/formatters';

import { InfoCircleIcon } from '../icons/info';

export type ListSummaryItem = {
  label: ReactNode;
  amount: number;
  type: 'inbound' | 'outbound' | 'total';
  testId?: string;
};

type ListSummaryCardProps = {
  cardColor: ColouredCardIntent;
  title?: string;
  titleTooltip?: string;
  items: ListSummaryItem[];
};

const defaultStyles = StyleSheet({
  container: {
    borderRadius: ({ token }) => token('border.radius.l')
  },
  title: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: ({ token }) => token('spacing.m'),
    borderBottom: ({ token }) =>
      `.1rem solid ${token('color.border.container.static.medium')}`,
    fontSize: '12px',
    columnGap: ({ token }) => token('spacing.xs')
  },
  content: {
    paddingBottom: ({ token }) => token('spacing.m')
  },
  body: {
    padding: 0,
    a: {
      textDecoration: 'none',
      color: 'inherit',
      display: 'inline-flex',
      alignItems: 'center',
      svg: {
        marginLeft: '5px'
      }
    }
  },
  row: {
    padding: ({ token }) => token('spacing.m'),
    paddingBottom: 0
  },
  iconContainer: {
    width: 14,
    height: 14,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  }
});

export function ListSummaryCard({
  title,
  titleTooltip,
  cardColor,
  items
}: ListSummaryCardProps) {
  const s = useStyles(defaultStyles);

  return (
    <ColoredCard intent={cardColor} {...s('container')}>
      <Box {...s('title')}>
        {title && <Bold>{title.toUpperCase()}</Bold>}
        {titleTooltip && (
          <Tooltip Content={() => titleTooltip}>
            <Box {...s('iconContainer')}>
              <InfoCircleIcon size='s' />
            </Box>
          </Tooltip>
        )}
      </Box>

      <Box {...s('content')}>
        {items.map(({ label, amount, type, testId }, index) => {
          const Text = type === 'total' ? Bold : Body;
          const formattedAmount = formatCurrency(
            type === 'outbound' ? -Math.abs(amount) : amount
          );

          return (
            <Grid
              columns={2}
              {...s('row')}
              key={index}
              data-testid={`row-${index}`}
            >
              <Column width={1}>
                <Text {...s('body')}>{label}</Text>
              </Column>
              <Column
                width={1}
                style={{ textAlign: 'right' }}
                data-testid={testId}
              >
                <Text {...s('body')}>{formattedAmount}</Text>
              </Column>
            </Grid>
          );
        })}
      </Box>
    </ColoredCard>
  );
}
