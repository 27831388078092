import React, { useCallback } from 'react';

import { query } from '@rexlabs/model-generator';
import { DateCell, RecordCell } from '@rexlabs/table';

import ROUTES from 'routes/app';

import { transformActionDeclarationsToActionMenuItems } from 'utils/actions/transforms';

import { Columns, RecordTable } from 'view/components/table';

import { tasksModel } from '../../common/models/tasks-model';

import { QuoteTask } from '../types/QuoteTask';
import { useGetQuoteActions } from '../hooks/action-menu-items/use-get-quote-actions';
import { MaintenanceTask } from '../../maintenance/types/MaintenanceTask';
import { ProgressDropdown } from '../components/progress-dropdown';
import { QUOTE_ROUTES } from '../routes';
import { QuoteActionCell } from './quote-action-cell';
import { PriceTwoTierCell } from './price-two-tier-cell';
import { QuoteListEmptyState } from './quotes-list-empty-state';

const columns: Columns<QuoteTask> = [
  {
    id: 'details.supplier',
    width: 180,
    Header: 'Supplier',
    accessor: (item) => item.details?.supplier,
    Cell: RecordCell,
    cellProps: {
      labelKey: 'display_name',
      getCellLinkProps: (value) => {
        return {
          to: ROUTES.CONTACT,
          params: {
            contactId: value.id
          }
        };
      }
    }
  },
  {
    id: 'due_date',
    width: 130,
    Header: 'Due date',
    Cell: DateCell
  },
  {
    id: 'quotedPrice',
    Header: 'Quoted price',
    width: 130,
    Cell: PriceTwoTierCell,
    accessor: (item) => item
  },
  {
    id: 'details.status',
    Header: 'Status',
    width: 180,
    Cell: ProgressDropdown,
    accessor: (item) => item
  },
  {
    id: 'actionCell',
    Header: '',
    width: 180,
    Cell: QuoteActionCell,
    accessor: (item) => item
  }
];

const quoteQuery = query`{
    ${tasksModel} {
      id
      managed_by
      property {
        active_property_ownership {
          ownership {
            owners
          }
        }
        active_property_tenancy {
            tenancy
        }
      }
      parent_task
      quoteDetails
    }
  }`;

export function QuotesList({ relatedTask }: { relatedTask: MaintenanceTask }) {
  const getQuoteActions = useGetQuoteActions();

  const getActionMenuItems = React.useCallback(
    ({ item }) => {
      const actions = getQuoteActions(item);
      return transformActionDeclarationsToActionMenuItems(actions);
    },
    [getQuoteActions]
  );

  const EmptyState = useCallback(
    () => <QuoteListEmptyState data={relatedTask} />,
    [relatedTask]
  );

  const getRowLinkProps = ({ item }) => {
    return {
      to: QUOTE_ROUTES.QUOTE_DETAILS,
      params: { quoteId: item.id }
    };
  };

  return (
    <RecordTable
      id='quotes-list'
      columns={columns}
      getQuery={() => quoteQuery}
      Empty={EmptyState}
      getRowLinkProps={getRowLinkProps}
      emptyWithoutTable={true}
      getActionMenuItems={getActionMenuItems}
      forcedGlobalFilter={[
        { field: 'type_id', op: 'eq', value: 'task_quote' },
        {
          field: 'parent_task_id',
          op: 'eq',
          value: relatedTask.id
        }
      ]}
    />
  );
}
