import React from 'react';
import { formatDate } from 'utils/dates/format';
import { TwoTierCell } from 'view/components/cells/two-tier-cell';
import { CellProps } from '@rexlabs/table';
import { InspectionTask } from '../types/InspectionTask';
import { getFormattedStartAndEndTime } from '../utils/get-formatted-state-and-end-time';

export function InspectionDateCell({
  value
}: CellProps<unknown, InspectionTask>) {
  const scheduledAt = value?.details?.scheduled_at;

  return (
    <TwoTierCell
      text={scheduledAt ? formatDate(scheduledAt) : '-'}
      subtext={getFormattedStartAndEndTime(value)}
    ></TwoTierCell>
  );
}
