import { makeIconComponent } from '@rexlabs/icons';

import QuoteMediumSvg from 'src/assets/icons/m/quote.svg';
import QuoteLargeSvg from 'src/assets/icons/l/quote.svg';
import QuoteExtraLargeSvg from 'src/assets/icons/xl/quote.svg';

export const QuoteIcon = makeIconComponent({
  m: QuoteMediumSvg,
  l: QuoteLargeSvg,
  xl: QuoteExtraLargeSvg
});

export default QuoteIcon;
