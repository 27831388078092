import React from 'react';
import { BlockConfig } from 'view/components/record-screen/types';
import { InspectionTypeInput } from '../../common/components/inspection-type-input';

const validate = {
  definitions: {
    inspection_type: { rules: 'required' }
  }
};

export const inspectionTypeBlock: BlockConfig = {
  id: 'inspection_type',
  title: 'Inspection type',
  validate,
  Edit: () => {
    return <InspectionTypeInput />;
  }
};
