import React from 'react';

import { Select } from '@rexlabs/select';
import { InputProps } from '@rexlabs/form';

import { FinancialPeriod } from 'src/modules/statements/types';
import { useFinancialPeriods } from 'src/modules/financials/hooks/use-financial-periods';

import { normaliseFinancialPeriod } from 'utils/normaliser/normalise-financial-period';

export function useDefaultFinancialPeriod(includeCurrent?: boolean) {
  const { items: data } = useFinancialPeriodsSelectItems(includeCurrent);
  return data.length ? data[0] : undefined;
}

export function useFinancialPeriodsSelectItems(includeCurrent?: boolean) {
  const { data = [], isLoading } = useFinancialPeriods(includeCurrent);

  const items = data
    .map(({ start_date: startDate, end_date: endDate }) => ({
      startDate,
      endDate
    }))
    .sort((a, b) =>
      b.startDate.localeCompare(a.startDate)
    ) as FinancialPeriod[];
  return { items, isLoading };
}

export function FinancialPeriodSelect(
  props: Partial<InputProps> & {
    value?: FinancialPeriod;
    includeCurrent?: boolean;
  }
) {
  const { items } = useFinancialPeriodsSelectItems(props?.includeCurrent);

  return (
    <Select
      {...props}
      disabled={!items.length}
      items={items}
      normaliser={normaliseFinancialPeriod}
    />
  );
}
