import React from 'react';
import { BlockConfig } from 'view/components/record-screen/types';
import { Grid } from 'view/components/@luna/form/grid';
import { Field } from '@rexlabs/form';
import {
  DateInput,
  disableFutureDates
} from 'view/components/@luna/inputs/date-input/date-input';

export const bondReportBlock: BlockConfig = {
  id: 'bondReportBlock',
  title: 'Detail',
  validate: {
    definitions: {
      as_at_date: { rules: 'required' }
    }
  },

  Edit: () => {
    return (
      <Grid columns={2}>
        <Field
          label='As at date'
          name='as_at_date'
          Input={DateInput}
          inputProps={{
            isOutsideRange: disableFutureDates
          }}
        />
      </Grid>
    );
  }
};
