import { RecordType, ValueListValue } from 'data/models/types';
import { useQuery } from 'react-query';
import { api } from 'utils/api/api-client';
import { BulkEditFieldType } from '../components/bulk-edit-field';

export type BulkEditDescriber = {
  id: string;
  fields: BulkEditField[];
};

export type BulkEditField = {
  id: string;
  label: string;
  type: ValueListValue<BulkEditFieldType>;
  type_config: Record<string, any>;
};

export function useBulkEditFields(recordType: RecordType) {
  return useQuery(['bulk-edit-fields', recordType], {
    queryFn: async () => {
      return api.get<BulkEditDescriber>(`bulk-edit/describers/${recordType}`);
    },
    select: (data) => data.data,
    staleTime: Infinity
  });
}
