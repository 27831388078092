import { Ownership } from 'data/models/entities/ownerships';
import { SelectNormalised } from '@rexlabs/select-old';
import { RecordTypes } from 'data/models/types';

/**
 * @deprecated Use v4-select-normalisers/ownership instead
 */
export function normaliseOwnership(item: Ownership): SelectNormalised {
  return {
    value: item,
    label: item.display_name,
    type: RecordTypes.Ownership
  };
}
