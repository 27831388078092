import dayjs from 'dayjs';
import { ChecklistItem } from '../../common/types/Checklist';

export function getDueDateTagLabel(item: ChecklistItem): string {
  // show 'tomorrow', 'today', 'yesterday' if it's within 24 hours
  const dueDate = dayjs(item.due_date);
  const now = dayjs();

  if (dueDate.isSame(now, 'day')) {
    return 'Today';
  }

  if (dueDate.isSame(now.add(1, 'day'), 'day')) {
    return 'Tomorrow';
  }

  if (dueDate.isSame(now.subtract(1, 'day'), 'day')) {
    return 'Yesterday';
  }

  return dayjs(item.due_date).format('DD MMM YYYY');
}
