import React from 'react';
import { useTranslation } from 'react-i18next';
import { ActionButtons } from 'view/components/@luna/action-buttons';

import { BlockConfig } from 'view/components/record-screen/types';
import { Statement, Summary } from 'view/components/statement';

export const cashflowStatementBlock: BlockConfig = {
  id: 'cashflow-statement',
  title: 'Cashflow statement',
  View: () => {
    const { t } = useTranslation();

    return (
      <Statement>
        <Summary
          items={[
            {
              label: 'Opening balance',
              type: 'subtotal',
              amount: 0
            },

            {
              label: 'Receipt & transfers to',
              type: 'inbound',
              amount: 0
            },
            {
              label: 'Withdrawals & transfers from',
              type: 'outbound',
              amount: 0
            },
            {
              label: 'Trust balance',
              type: 'subtotal',
              amount: 0
            },

            {
              label: 'Uncleared amounts',
              type: 'outbound',
              amount: 0
            },
            {
              label: t(
                'financials.trust.available-trust-balance.label.singular'
              ),
              type: 'total',
              amount: 0
            }
          ]}
        />
      </Statement>
    );
  },
  Actions: () => (
    <ActionButtons
      actions={[{ label: 'Download', handleAction: () => alert('Download') }]}
    />
  ),
  workInProgress: true
};
