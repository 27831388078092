import { SearchResultItem } from 'utils/api/get-search-results';
import { get } from 'lodash';
import { Tenancy } from 'data/models/entities/tenancies';
import { Ownership } from 'data/models/entities/ownerships';

export function getFolio(forms) {
  const folio = get(
    forms,
    'receipt-funds-details.values.folio'
  ) as SearchResultItem<Tenancy | Ownership> | null;

  if (folio) return folio;

  const transferFundsFolio = get(
    forms,
    'transfer-funds-details.values.source.ledger.object'
  ) as SearchResultItem<Ownership> | SearchResultItem<Tenancy> | null;

  return transferFundsFolio;
}
