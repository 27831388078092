import React, { useMemo } from 'react';
import { toQuri } from '@rexlabs/quri';

import { query, useEntityListQuery } from '@rexlabs/model-generator';
import { ownershipsModel } from 'data/models/entities/ownerships';

import { OwnershipsListUI } from '../ui/ownerships-list-ui';

export const getOwnershipsQuery = ({ contactId }) => {
  const q = toQuri([
    {
      field: 'contact_id',
      op: '==',
      value: contactId
    }
  ]);

  return query`{
    ${ownershipsModel} (q: ${q}) {
      id
      name
      reference
    }
  }`;
};

interface OwnershipsListProps {
  contactId: string;
}

export function OwnershipsList({ contactId }: OwnershipsListProps) {
  const query = useMemo(() => getOwnershipsQuery({ contactId }), [contactId]);
  const { status, data } = useEntityListQuery(query);
  return (
    <OwnershipsListUI isLoading={status === 'loading'} items={data ?? []} />
  );
}
