import { FEATURES, FeatureTypes } from 'utils/analytics/features';
import {
  COMMON_ACTIONS,
  CommonActionTypes
} from 'utils/analytics/common-actions';
import { startCase } from 'lodash';

// This functions purpose is to create a naming convention for our event names,
// if you scenario doesn't fit this function just create it without this function if it is a once off
export function buildEventName(
  feature: FeatureTypes,
  action: CommonActionTypes
): string {
  return `${startCase(FEATURES[feature.toUpperCase()])} - ${startCase(
    COMMON_ACTIONS[action.toUpperCase()]
  )}`;
}
