import { Ownership } from 'data/models/entities/ownerships';

import { api } from 'utils/api/api-client';

const relatedFields = ['owners', 'owners.contact'];

export async function getOwnershipRelatedItems(ownershipSearchItem: Ownership) {
  const ownershipUrl = `/ownerships/${
    ownershipSearchItem.id
  }?include=${relatedFields.join(',')}`;

  const completeOwnership = !ownershipSearchItem?.owners
    ? await api.get<Ownership>(ownershipUrl).then(({ data }) => data)
    : ownershipSearchItem;

  const owners = completeOwnership?.owners!.map((owner) => owner.contact);

  return {
    owners
  };
}
