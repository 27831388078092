import { isObject } from 'lodash';
import React from 'react';
import { AlfredFile } from 'src/modules/common/types/file';

export function useResolveAttachments(attachedFiles) {
  const [resolveAttachedFiles, setResolveAttachedFiles] = React.useState<
    Array<AlfredFile>
  >([]);

  React.useEffect(() => {
    let isActive = true;

    if (!Array.isArray(attachedFiles)) return;

    async function setFiles() {
      const result = await Promise.all(
        attachedFiles!.map(async (attached) => {
          if (isPromise(attached)) {
            return (await attached).data;
          }

          return attached;
        })
      );

      if (isActive) {
        setResolveAttachedFiles(result);
      }
    }

    setFiles();

    return () => {
      isActive = false;
    };
  }, [attachedFiles]);

  return resolveAttachedFiles;
}

// Util

function isPromise(value: any) {
  return (
    value instanceof Promise ||
    (isObject(value) && 'then' in value && 'catch' in value)
  );
}
