import * as React from 'react';

import { DialogProps } from '@rexlabs/dialog';

import { RecordDialog } from 'view/components/record-screen/dialog/dialog';

import { detailsBlock } from '../blocks/details-block';
import { taskPriorityBlock } from '../../common/blocks/task-priority-block';
import { relatedImagesBlock } from '../../quotes/blocks/related-images-block';
import { workOrderDetailsBlock } from '../blocks/work-order-details-block';
import { useAddWorkOrderSubmitHandler } from '../hooks/use-add-work-order-submit-handler';
import { WorkOrderFormData } from '../types/WorkOrderFormData';
import { taskDateBlock } from '../blocks/task-date-block';
import { AddWorkOrderDialogButtonGroup } from '../components/add-work-order-dialog-button-group';
import { getCreateWorkOrderChangeHandlers } from '../utils/get-create-work-order-change-handlers';
import { checklistTemplateSelectBlock } from '../../common/blocks/checklist-template-select-block';
import { useTaskTypesWithCustomAddToRecord } from '../../settings/hooks/use-task-types-with-custom-add-to-record';

export interface IssueWorkOrderDialogProps extends DialogProps {
  initialValues?: Partial<WorkOrderFormData>;
  onCreate?: (data) => void;
}

export function IssueWorkOrderDialog({
  onClose,
  initialValues,
  target,
  onCreate
}: IssueWorkOrderDialogProps) {
  const { onHandleSubmit } = useAddWorkOrderSubmitHandler({ onCreate });
  const taskTypesWithCustomAddToRecord = useTaskTypesWithCustomAddToRecord();

  const content = [
    {
      id: 'issue-work-order',
      label: 'Issue work order',
      blocks: [
        { ...detailsBlock, title: '' },
        { ...relatedImagesBlock, title: 'Add image(s)' },
        workOrderDetailsBlock,
        ...(taskTypesWithCustomAddToRecord.includes('task_work_order')
          ? [checklistTemplateSelectBlock]
          : []),
        ...(!initialValues?.parent_task ? [taskPriorityBlock] : []),
        taskDateBlock
      ]
    }
  ];

  return (
    <RecordDialog
      title='Issue work order'
      data={{
        ...initialValues,
        priority: initialValues?.priority || { id: 'normal', label: 'Normal' },
        details: {
          ...initialValues?.details,
          images: initialValues?.details?.images || []
        }
      }}
      content={content}
      onClose={onClose}
      blockProps={{ taskTypeId: 'task_work_order' }}
      handleSubmit={onHandleSubmit}
      ButtonGroup={AddWorkOrderDialogButtonGroup}
      target={target}
      getHandlers={(forms) => getCreateWorkOrderChangeHandlers(forms)}
    />
  );
}
