import { RecordOption, Select, SelectProps } from '@rexlabs/select';
import * as React from 'react';
import { useApiValueList } from 'view/hooks/api/use-api-value-list';
import { ValueListValue } from 'data/models/types';
import { taskStatusMap as defaultTaskStatusMap } from '../maps/task-status-map';
import { CircleFilledIcon } from './circle-filled-icon';

type TaskStatusSelectProps = SelectProps<ValueListValue<string>> & {
  taskStatusMap?: Record<string, string>;
  valueListId?: string;
};

export function TaskStatusSelect({
  taskStatusMap,
  valueListId,
  ...props
}: TaskStatusSelectProps) {
  const { data } = useApiValueList(valueListId || 'task_status');
  const statusMap = taskStatusMap || defaultTaskStatusMap;

  const value = props.value;

  return (
    <Select
      items={data}
      normaliser={({ id, label }) => {
        return {
          id,
          label,
          Icon: () => <CircleFilledIcon id={id} taskStatusMap={statusMap} />
        };
      }}
      {...props}
      Option={RecordOption}
      ValuePrefix={
        isSingleValue(value) && value.id
          ? () => <CircleFilledIcon id={value.id} taskStatusMap={statusMap} />
          : undefined
      }
    />
  );
}

function isSingleValue(
  value?: ValueListValue<string> | ValueListValue<string>[] | null
): value is ValueListValue<string> {
  if (Array.isArray(value)) {
    return false;
  }
  if (!value) {
    return false;
  }
  return true;
}
