import * as React from 'react';
import Box from '@rexlabs/box';
import {
  StatusChangeActionArgs,
  useStatusChangeAction
} from 'src/modules/common/actions/status/use-status-change-action';
import { invokeActionDeclaration } from 'src/modules/common/actions/utils/invoke-action-declaration';
import { TaskStatusMenu } from 'src/modules/tasks/arrears/components/task-status-menu';
import { Task } from 'src/modules/tasks/common/types/Task';
import { tasksModel } from '../../common/models/tasks-model';

export function ProgressDropdown({
  value: task
}: {
  value?: Task<'task_arrears'>;
}) {
  const getStatusChangeAction = useStatusChangeAction(tasksModel);
  // note this one can't be changed to "resolved", so we don't need to handle our usual refresh-on-complete
  const handleStatusChange = task
    ? (newStatus) => {
        invokeActionDeclaration<StatusChangeActionArgs<typeof newStatus.id>[]>(
          getStatusChangeAction,
          {
            record: task,
            status: newStatus.id,
            dialogOptions: {
              type: 'none'
            },
            toastOptions: { recordLabel: 'Arrears task' },
            generalOptions: { avoidListRefresh: true },
            transformPayload: (status) => {
              return {
                type: { id: 'task_arrears' },
                details: {
                  status: {
                    id: status
                  }
                }
              };
            }
          }
        );
      }
    : undefined;

  return (
    <Box
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
      data-testid='arrears-status-tag'
    >
      <TaskStatusMenu
        task={task}
        initialValue={task?.details?.status}
        onChange={handleStatusChange}
      />
    </Box>
  );
}
