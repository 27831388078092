import React, { useMemo, useCallback } from 'react';

import { getContacts } from 'utils/api/get-contacts';
import { ContactSelect, ContactSelectProps } from './contact';

export function SecurityDepositAuthorityContactSelect({
  ...props
}: ContactSelectProps) {
  const getSuggestedItems = useCallback(async () => {
    return getContacts({ filterByRoles: ['security_deposit_authority'] });
  }, []);

  const dialogProps = useMemo(
    () => ({
      contact: {
        is_security_deposit_authority: true
      },
      showTypeBlock: false
    }),
    []
  );

  return (
    <ContactSelect
      {...props}
      dialogProps={dialogProps}
      filterByRoles={['security_deposit_authority']}
      getSuggestedItems={getSuggestedItems}
    />
  );
}
