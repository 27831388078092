import { RecordType } from 'data/models/types';
import { useMutation } from 'react-query';
import { api } from 'utils/api/api-client';
import { mapBulkEditDataToPayload } from 'src/modules/bulk-edit/mappers/map-bulk-edit-data-to-payload';

export function useBulkEdit(recordType: RecordType) {
  return useMutation(
    async ({
      selectedIds,
      data
    }: {
      selectedIds: string[];
      data: Record<string, any>;
    }) => {
      const response = await api.put(
        `/bulk-edit/${recordType}`,
        mapBulkEditDataToPayload(selectedIds, data)
      );

      return response.data;
    },
    {
      mutationKey: ['bulk-edit', recordType]
    }
  );
}
