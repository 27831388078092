import React from 'react';

import ROUTES from 'routes/app';

import { DialogProps } from '@rexlabs/dialog';
import { push } from '@rexlabs/whereabouts';

import { RecordSubmitHandler } from 'view/components/record-screen/utils';
import { RecordDialog } from 'view/components/record-screen/dialog/dialog';

import {
  CreateInspectionRunBlockFormValues,
  createInspectionRunBlock
} from '../blocks/create-inspection-run-block';
import { InspectionTask } from '../types/InspectionTask';

export interface CreateInspectionRunDialogProps extends DialogProps {
  submitHandler: RecordSubmitHandler<CreateInspectionRunBlockFormValues>;
  inspections: InspectionTask[];
}

const content = [
  {
    id: 'create-inspection-run',
    label: '',
    blocks: [createInspectionRunBlock]
  }
];

export function CreateInspectionRunDialog({
  onClose,
  submitHandler,
  inspections = []
}: CreateInspectionRunDialogProps) {
  return (
    <RecordDialog
      size='s'
      title='Create inspection run'
      content={content}
      onClose={onClose}
      handleSubmit={async (values) => {
        const data = await submitHandler(values);

        push(ROUTES.INSPECTION_RUN_DETAILS, {
          params: {
            inspectionRunId: data.id
          }
        });

        return data;
      }}
      submitLabel='Continue'
      data={{ inspections }}
    />
  );
}
