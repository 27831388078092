import React, { useEffect, useState } from 'react';

import Box from '@rexlabs/box';
import { border, StyleSheet, useStyles } from '@rexlabs/styling';
import { Breakpoints } from '@rexlabs/breakpoints';
import { Portal } from '@rexlabs/portal';
import { Observer } from '@rexlabs/scroll-shadow';

import {
  TitleBlock as LunaTitleBlock,
  TitleBlockProps as LunaTitleBlockProps
} from 'view/components/@luna/title-block';
import { StickyBanner } from 'view/components/@luna/title-block/sticky-banner';
import { StatBlocks, StatBlocksProps } from 'view/components/@luna/stat-block';
import { CardDivider } from 'view/components/@luna/card';

import { useWorkInProgress } from 'view/components/work-in-progress';

import { useStickyNavigation } from 'view/components/record-screen/sticky-navigation/context';
import { useScrollDirection } from 'view/components/cards/title/use-scroll-direction';

const defaultStyles = StyleSheet({
  divider: {
    ...border.styles({
      bottom: {
        color: 'container.static.light'
      }
    })
  }
});

export type TitleBlockProps = LunaTitleBlockProps &
  Partial<Pick<StatBlocksProps, 'stats'>> & {
    wrapStatBlocks?: boolean;
    banner?: React.ReactNode;
  };

export function TitleBlock({
  title,
  actions,
  stats,
  isCompact = false,
  hideViewAction = true,
  wrapStatBlocks = true,
  banner,
  Icon,
  ...props
}: TitleBlockProps) {
  const s = useStyles(defaultStyles);
  const { setActions, setHideViewAction } = useStickyNavigation();

  const [showWorkInProgress] = useWorkInProgress();

  const [intersecting, setIntersecting] = useState(true);

  const root = document.getElementById('sticky-banner-scroll-root');

  const scrollDirection = useScrollDirection({ root, disabled: isCompact });

  const onlyWipStats = stats?.every(({ workInProgress }) => !!workInProgress);
  const showStats = !!stats?.length && (showWorkInProgress || !onlyWipStats);

  useEffect(() => {
    setActions(actions!);
    setHideViewAction(hideViewAction);
  }, [actions, hideViewAction, setActions, setHideViewAction]);

  return (
    <>
      <Box>
        <LunaTitleBlock
          {...props}
          title={title}
          actions={actions}
          isCompact={isCompact}
          Icon={Icon}
        />
        {!isCompact && (
          <>
            <Breakpoints queries={{ maxWidth: 'm' }}>
              <Portal target='sticky-banner'>
                <StickyBanner
                  title={title}
                  active={!intersecting && scrollDirection === 'down'}
                  Icon={Icon}
                />
              </Portal>
            </Breakpoints>
            <Breakpoints queries={{ maxWidth: 'm' }}>
              <Observer
                root={root ?? undefined}
                setIntersecting={setIntersecting}
              />
            </Breakpoints>
          </>
        )}
      </Box>
      {showStats && (
        <>
          <CardDivider {...s('divider')} />
          <StatBlocks stats={stats} shouldWrap={wrapStatBlocks} />
        </>
      )}
      {banner}
    </>
  );
}
