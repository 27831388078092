import React, { useCallback } from 'react';
import { DialogProps } from '@rexlabs/dialog';
import { useModelActions } from '@rexlabs/model-generator';

import { RecordDialog } from 'view/components/record-screen/dialog/dialog';
import { DialogContentConfig } from 'view/components/record-screen/dialog/types';
import { RecordSubmitHandler } from 'view/components/record-screen/utils';
import { filterNullValues } from 'utils/filter-null-values';
import { useToast } from 'view/components/@luna/notifications/toast';
import {
  financialsSecurityDepositRequestsModel,
  SecurityDepositRequest
} from 'data/models/entities/financials/security-deposit-requests';
import {
  financialsSecurityDepositsModel,
  SecurityDeposit
} from 'data/models/entities/financials/security-deposits';
import { securityDepositRequestBlock } from '../blocks/security-deposit-request';

const content: DialogContentConfig = [
  {
    id: 'basics',
    label: 'Basics',
    blocks: [securityDepositRequestBlock]
  }
];

export interface CreateSecurityDepositRequestDialogProps extends DialogProps {
  securityDeposit: SecurityDeposit;
}

export function CreateSecurityDepositRequestDialog({
  securityDeposit,
  onClose
}: CreateSecurityDepositRequestDialogProps) {
  const { addToast } = useToast();

  const { refreshItem } = useModelActions(financialsSecurityDepositsModel);

  const { createItem, refreshLists } = useModelActions(
    financialsSecurityDepositRequestsModel
  );
  const handleSubmit: RecordSubmitHandler = useCallback(async ({ values }) => {
    const { data: securityDepositRequest } = await createItem({
      data: filterNullValues(values),
      args: {
        include: 'invoice'
      }
    });

    const typeLabel = (securityDepositRequest as SecurityDepositRequest).type
      .label;
    const invoiceReference = (securityDepositRequest as SecurityDepositRequest)
      ?.invoice?.record_reference;

    addToast({
      description: (
        <>
          {typeLabel} and Invoice {invoiceReference} created successfully
        </>
      )
    });

    await Promise.all([
      refreshLists(),
      refreshItem({ id: securityDeposit.id })
    ]);

    return securityDepositRequest;
  }, []);

  return (
    <RecordDialog
      onClose={onClose}
      data={{
        is_top_up: false,
        security_deposit: securityDeposit,
        type: {
          id: 'deposit'
        }
      }}
      title='Create deposit request'
      handleSubmit={handleSubmit}
      content={content}
      submitLabel='Create deposit request'
    />
  );
}
