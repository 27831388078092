import React, { PropsWithChildren, ReactNode } from 'react';
import Box from '@rexlabs/box';
import {
  StyleSheet,
  useStyles,
  border,
  padding,
  margin,
  mq
} from '@rexlabs/styling';
import { formatCurrency } from 'utils/formatters';
import { Heading } from '@rexlabs/text';
import { AddIcon } from 'view/components/icons/add';
import { SubtractIcon } from 'view/components/icons/subtract';

export type SummaryItem = {
  type: 'inbound' | 'outbound' | 'total' | 'subtotal';
  label: ReactNode;
  subLabel?: string;
  amount?: number;
};

interface SummaryProps {
  items: SummaryItem[];
  title?: string;
  subTitle?: string;
  startAmount?: number;
  isContrast?: boolean;
  includeDividingLines?: boolean;
  includePadding?: boolean;
}

const defaultStyles = StyleSheet({
  container: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    width: '100%',
    borderRadius: ({ token }) => token('border.radius.l')
  },

  containerContrast: {
    background: ({ token }) => token('color.container.static.contrast')
  },

  heading: {
    marginBottom: ({ token }) => token('spacing.s')
  },

  subText: {
    color: ({ token }) => token('color.textStyle.body.subtext'),
    fontSize: ({ token }) => token('typography.size.s'),
    fontWeight: ({ token }) => token('typography.weight.semibold')
  },

  item: {
    padding: '1.2rem 0'
  },

  itemSubTotal: {
    fontWeight: ({ token }) => token('typography.weight.semibold'),
    ...border.styles({
      top: {
        color: ({ token }) => token('color.border.container.static.medium'),
        width: 'thin'
      }
    })
  },

  itemTotal: {
    fontWeight: ({ token }) => token('typography.weight.bold'),
    ...border.styles({
      top: {
        color: ({ token }) => token('color.border.container.static.medium'),
        width: 'thin'
      },
      bottom: {
        color: ({ token }) => token('color.border.container.static.medium'),
        width: () => '.3rem',
        style: () => 'double'
      }
    })
  },

  itemFirst: {
    borderTop: '0 none'
  },

  statementItem: {
    ...border.styles({
      top: {
        color: ({ token }) => token('color.border.container.static.light'),
        width: 'thin'
      }
    })
  },

  icon: {
    marginRight: '.8rem',
    display: 'flex',
    color: ({ token }) => token('color.textStyle.body.hint')
  },

  label: {
    display: 'flex',
    flex: 1
  },

  amount: {
    fontVariantNumeric: 'tabular-nums',

    ...margin.styles({
      left: 'xs'
    })
  },

  line: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'space-between'
  },

  withPadding: {
    ...padding.styles({
      x: 'm'
    })
  }
});

export function Summary({
  title,
  subTitle,
  startAmount,
  items,
  isContrast,
  includeDividingLines,
  includePadding
}: SummaryProps) {
  const s = useStyles(defaultStyles);
  return (
    <Box
      {...s('container', {
        containerContrast: isContrast
      })}
    >
      {title && (
        <Heading
          {...s('line', {
            withPadding: includePadding
          })}
          level={4}
        >
          {title}
          {<div>{formatCurrency(startAmount)}</div>}
        </Heading>
      )}

      {subTitle && (
        <div
          {...s('subText', 'line', 'heading', {
            withPadding: includePadding
          })}
        >
          {subTitle}
        </div>
      )}
      {items.map(({ type, label, subLabel, amount }, index) => (
        <div
          key={index}
          {...s('item', {
            itemSubTotal: type === 'subtotal',
            itemTotal: type === 'total',
            itemFirst: index === 0,
            statementItem:
              includeDividingLines &&
              (type === 'inbound' || type === 'outbound')
          })}
        >
          <div
            {...s('line', {
              withPadding: includePadding
            })}
          >
            {type === 'inbound' ? (
              <div {...s('icon')}>
                <AddIcon />
              </div>
            ) : type === 'outbound' ? (
              <div {...s('icon')}>
                <SubtractIcon />
              </div>
            ) : null}
            <div {...s('label')}>{label}</div>
            <div {...s('amount')}>
              {amount !== undefined ? formatCurrency(amount) : null}
            </div>
          </div>
          {subLabel && (
            <div
              {...s('subText', 'line', {
                withPadding: includePadding
              })}
            >
              {subLabel}
            </div>
          )}
        </div>
      ))}
    </Box>
  );
}

const statementStyles = StyleSheet({
  container: {
    display: 'flex',
    gap: '4rem',

    ...mq.styles.combine([
      {
        queries: {
          maxWidth: 's'
        },
        styles: {
          flexDirection: 'column'
        }
      },
      {
        queries: {
          minWidth: 's'
        },
        styles: {
          flexDirection: 'row'
        }
      }
    ])
  }
});

export function Statement({ children }: PropsWithChildren<unknown>) {
  const s = useStyles(statementStyles);
  return <Box {...s('container')}>{children}</Box>;
}
