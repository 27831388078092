import { Generator } from 'data/models/generator';
import { SupplierCategoryRequirement } from 'data/models/entities/settings/supplier-category-requirements';
import { BaseFields, RecordTypes, TimestampAttributes } from '../../types';

export type SupplierComplianceCategory = BaseFields<
  typeof RecordTypes.SupplierComplianceCategory
> &
  TimestampAttributes & {
    label: string;
    description: string;
    category_requirements?: { data: SupplierCategoryRequirement[] };
  };

const config = {
  entities: {
    related: {
      category_requirements: {
        include: 'category_requirements, category_requirements.compliance_type'
      }
    }
  }
};

export const settingsSupplierComplianceCategoriesModel = new Generator<SupplierComplianceCategory>(
  'settings/supplier-compliance-categories',
  config
).createEntityModel();
