import React, { useCallback } from 'react';

import { RecordDialog } from 'view/components/record-screen/dialog/dialog';
import { DialogContentConfig } from 'view/components/record-screen/dialog/types';

import { RecordSubmitHandler } from 'view/components/record-screen/utils';
import { OwnershipFee } from 'data/models/entities/ownerships/fees/ownership-fees';
import { PropertyOwnershipFee } from 'data/models/entities/financials/property-ownerships/property-ownership-fee';
import { RecordTypes } from 'data/models/types';
import { Tag } from '@rexlabs/tag';
import { useTranslation } from 'react-i18next';
import { entityFeeBlock } from '../blocks/entity-fee';

const content: DialogContentConfig = [
  {
    id: 'basics',
    label: 'Basics',
    blocks: [entityFeeBlock]
  }
];

export type EntityFee = OwnershipFee | PropertyOwnershipFee;
export type EntityFeeType =
  | typeof RecordTypes.Ownership
  | typeof RecordTypes.PropertyOwnership;

export interface EditEntityDialogProps<Fee extends EntityFee> {
  onClose?: () => void;
  onSubmit: (item: Partial<Fee>) => Promise<void>;
  fee: Fee;
  feeType: EntityFeeType;
}

export function EditEntityDialog<Fee extends EntityFee>({
  onClose,
  onSubmit,
  fee,
  feeType
}: EditEntityDialogProps<Fee>) {
  const { t } = useTranslation();

  const handleSubmit: RecordSubmitHandler = useCallback(
    async ({ changedValues, values }) => {
      const actualChangesValues = changedValues;

      if ('is_tax_included' in actualChangesValues) {
        actualChangesValues.tax_type = values.tax_type;
      }

      delete actualChangesValues['agency_fee'];

      if ('fee_amount' in changedValues) {
        actualChangesValues['fee_amount'] = values['fee_amount'];
      }

      await onSubmit(actualChangesValues);

      return actualChangesValues;
    },
    [onSubmit]
  );

  const tileString = `Edit fee: ${fee.agency_fee!.name}`;

  return (
    <RecordDialog
      onClose={onClose}
      documentTitle={tileString}
      title={
        <>
          <span className='mr-xs'>{tileString}</span>
          <Tag>
            {feeType === 'ownership' && 'Ownership fee'}
            {feeType === 'property_ownership' &&
              t('property-ownerships.management-agreement-fees.label.singular')}
          </Tag>
        </>
      }
      data={{
        ...fee
      }}
      handleSubmit={handleSubmit}
      content={content}
      submitLabel='Edit fee'
    />
  );
}
