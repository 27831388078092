import React, { useCallback, useMemo } from 'react';
import { RecordTable, TabbedTable } from 'view/components/table';
import { getTableComplianceEntriesQuery } from 'src/modules/compliance/common/data/compliance-entries-query';
import {
  entryColumns,
  itemNameColumn
} from 'src/modules/compliance/utils/entry-columns';
import { GlobalFilter } from '@rexlabs/table/lib/types/context';
import { transformActionDeclarationsToActionMenuItems } from 'utils/actions/transforms';
import { useComplianceEntryActions } from '../hooks/use-compliance-entry-actions';

const columns = [itemNameColumn, ...entryColumns];

export interface ComplianceEntriesListProps {
  objectId: string;
  objectType: 'property' | 'supplier';
}

const filterActive: GlobalFilter = {
  field: 'status_id',
  op: 'neq',
  value: 'archived'
};

const filterArchived: GlobalFilter = {
  field: 'status_id',
  op: 'eq',
  value: 'archived'
};

const getFiltersForComplianceEntries = ({ objectId, objectType }) => {
  return [
    {
      field: 'object_id',
      op: '==',
      value: objectId
    },
    {
      field: 'object_type',
      op: '==',
      value: objectType
    }
  ] as GlobalFilter[];
};

export function ComplianceEntriesList({
  objectId,
  objectType
}: ComplianceEntriesListProps) {
  const getQuery = useCallback(() => getTableComplianceEntriesQuery(), []);

  const filter = useMemo(
    () =>
      getFiltersForComplianceEntries({
        objectId,
        objectType
      }),
    [objectId, objectType]
  );

  const actions = useComplianceEntryActions();

  const tabs = React.useMemo(() => {
    const commonProps = {
      id: 'compliance-entries',
      columns,
      getQuery,
      Table: RecordTable
    };
    return [
      {
        ...commonProps,
        label: 'Active',
        name: 'active',
        getActionMenuItems: ({ item }) =>
          transformActionDeclarationsToActionMenuItems(actions(item)),
        forcedGlobalFilter: [...filter, filterActive]
      },
      {
        ...commonProps,
        label: 'Archived',
        name: 'archived',
        forcedGlobalFilter: [...filter, filterArchived]
      }
    ];
  }, []);

  return <TabbedTable tabs={tabs} />;
}
