import { Quri } from '@rexlabs/quri';

import {
  RecordsQueryKey,
  useRecordsQuery
} from 'src/lib/react-query/hooks/use-records-query';
import { taskIncludes } from 'src/modules/tasks/common/models/tasks-model';

import { useFeatureFlags } from 'view/components/@luna/feature-flags';
import { FLAGS } from 'utils/feature-flags';
import { useGetIsEntryExit } from 'src/modules/tasks/inspections/entry-exit/hooks/use-get-is-entry-exit';
import { InspectionTask } from '../types/InspectionTask';

const getSharedFilters = (inspectionTask: InspectionTask): Quri[] => [
  {
    field: 'type_id',
    op: 'in',
    value: ['task_inspection']
  },
  ...(inspectionTask
    ? [
        {
          field: 'property_id',
          op: 'eq',
          value: inspectionTask.property!.id
        } as Quri,
        {
          field: 'id',
          op: 'neq',
          value: inspectionTask.id
        } as Quri
      ]
    : []),
  {
    field: 'task_inspection_status_id',
    op: 'in',
    value: ['report_completed,report_sent']
  }
];

function getPreviousRoutineInspectionReportsQuery(
  inspectionTask?: InspectionTask
): RecordsQueryKey {
  return [
    'tasks',
    {
      sort: ['closed_at', 'desc'],
      filter: [
        ...(inspectionTask ? getSharedFilters(inspectionTask) : []),
        {
          field: 'task_inspection_has_areas',
          op: 'eq',
          value: 'true'
        },
        {
          field: 'task_inspection_type_id',
          op: 'eq',
          value: 'routine_inspection'
        }
      ],
      includes: [taskIncludes.inspectionDetails]
    }
  ];
}

function getPreviousEntryExitInspectionReportsQuery(
  inspectionTask?: InspectionTask
): RecordsQueryKey {
  return [
    'tasks',
    {
      sort: ['closed_at', 'desc'],
      filter: [
        ...(inspectionTask ? getSharedFilters(inspectionTask) : []),
        {
          field: 'task_inspection_has_areas',
          op: 'eq',
          value: 'true'
        },
        {
          field: 'task_inspection_type_id',
          op: 'in',
          value: ['entry_inspection', 'exit_inspection']
        }
      ],
      includes: [taskIncludes.inspectionDetails]
    }
  ];
}

/**
 * @deprecated We are changing inspection reports to use InspectionAreas rather than Rooms
 */
function getPreviousInspectionReportsWithRoomsQuery(
  inspectionTask?: InspectionTask
): RecordsQueryKey {
  return [
    'tasks',
    {
      sort: ['closed_at', 'desc'],
      filter: [
        ...(inspectionTask ? getSharedFilters(inspectionTask) : []),
        {
          field: 'task_inspection_has_rooms',
          op: 'eq',
          value: 'true'
        }
      ],
      includes: [taskIncludes.inspectionDetails]
    }
  ];
}

function useGetPreviousInspectionReportsQuery(inspectionTask?: InspectionTask) {
  const { hasFeature } = useFeatureFlags();
  const getIsEntryExit = useGetIsEntryExit();
  if (getIsEntryExit(inspectionTask?.details?.type?.id)) {
    return getPreviousEntryExitInspectionReportsQuery(inspectionTask);
  }

  return hasFeature(FLAGS.ENTRY_EXIT_INSPECTION_REPORT)
    ? getPreviousRoutineInspectionReportsQuery(inspectionTask)
    : getPreviousInspectionReportsWithRoomsQuery(inspectionTask);
}

export function useGetPreviousInspectionReports(
  inspectionTask?: InspectionTask
) {
  const query = useGetPreviousInspectionReportsQuery(inspectionTask);

  return useRecordsQuery<InspectionTask>(query, {
    enabled: !!inspectionTask,
    staleTime: 60000,
    cacheTime: 0
  });
}
