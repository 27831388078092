export const FINANCIALS = {
  CHARGE_ENTRY_DETAILS: {
    config: {
      path: '/charge-entry/:chargeEntryId',
      Component: require('./screens/charge-entry-details')
        .ChargeEntryDetailsScreen
    }
  },

  RECURRING_CHARGE_ENTRY_DETAILS: {
    config: {
      path: '/recurring-charge-entry/:recurringChargeEntryId',
      Component: require('./screens/recurring-charge-entry-details')
        .RecurringChargeEntryDetailsScreen
    }
  }
};
