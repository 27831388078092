import React from 'react';
import { Portfolio } from 'src/modules/portfolios/models/portfolios-model';
import { RecordFlag } from 'view/components/record-flags/record-flag';
import { pluralize } from 'utils/formatters';
import { CircleFilledIcon } from 'view/components/icons/circle';
import Box from '@rexlabs/box';
import { StyleSheet, useStyles } from '@rexlabs/styling';
import { EntryList } from '../components/entry-list';
import { EntryLink } from '../components/entry-link';
import { EmptyPortfolios } from '../empty/empty-portfolios';

export interface PortfoliosSectionProps {
  portfolios: Portfolio[] | 'empty';
}

const defaultStyles = StyleSheet({
  dividerIcon: {
    height: ({ token }) => token('icon.size.xs'),
    width: 's'
  }
});

export function PortfoliosSection({ portfolios }: PortfoliosSectionProps) {
  const s = useStyles(defaultStyles);

  if (portfolios === 'empty' || portfolios.length === 0) {
    return <EmptyPortfolios />;
  }

  {
    return (
      <EntryList>
        {portfolios.map((portfolio) => {
          const portfolioSummary = portfolio.summary;

          return (
            <EntryLink key={portfolio.id} type='portfolio' id={portfolio.id}>
              <RecordFlag
                record={portfolio}
                recordType='portfolio'
                description={
                  portfolioSummary ? (
                    <Box flexDirection='row' alignItems={'center'}>
                      {`${pluralize(
                        'member',
                        portfolioSummary.users_count,
                        true
                      )}`}
                      <CircleFilledIcon
                        {...s('dividerIcon')}
                      ></CircleFilledIcon>
                      {`${pluralize(
                        'property',
                        portfolioSummary.properties_count,
                        true
                      )}`}
                    </Box>
                  ) : (
                    ''
                  )
                }
              />
            </EntryLink>
          );
        })}
      </EntryList>
    );
  }
}
