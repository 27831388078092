import { RecordType } from 'data/models/types';
import { getRecordLinkProps } from 'view/components/record-link/get-record-link-props';
import { push, RouteConfig, RouteDefinition } from '@rexlabs/whereabouts';
import { ActionDeclaration } from 'src/modules/common/actions/types/action-declaration-types';
import { getRecordTypeName } from 'utils/records/get-record-type-name';

interface AnyRecord {
  id: string;
}

export function getViewAction<
  ID extends string,
  Group extends string,
  Record extends AnyRecord
>(
  id: ID,
  group: Group,
  record: Record,
  recordType: RecordType,
  label?: string,
  parentId?: string
): ActionDeclaration {
  return {
    id,
    group,
    label: label ?? `View ${getRecordTypeName(recordType)}`,
    handleAction: async () => {
      const linkProps = getRecordLinkProps({
        id: record.id,
        ...(parentId && { parentId: parentId }),
        type: recordType
      });

      if (linkProps === null) {
        console.warn(`route can not be found for type ${recordType}`);
        return;
      }

      const { to, ...routeConfig } = linkProps;
      push(to as RouteDefinition, routeConfig as RouteConfig);
    }
  };
}
