import { query } from '@rexlabs/model-generator';
import { contactsModel } from 'src/modules/contacts/models/contacts';

export const getContactListQuery = () => query`{
  ${contactsModel}  {
    primary_address
    primary_email
    primary_phone
    primary_sms
    roles
    created_by
    updated_by
    trust_summary
    logo
  }
}`;
