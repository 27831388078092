import React from 'react';
import Box from '@rexlabs/box';
import { TenancyDialogLineItem } from 'src/modules/tenancies/components/tenancy-dialog-line-item';
import { RemoveIconButton } from 'src/modules/common/components/remove-icon-button';
import { Column, Grid } from 'view/components/@luna/form/grid';
import { Field, FieldArrayField } from '@rexlabs/form';
import { RadioGroupInput } from '@rexlabs/radio-input';
import { ContactSelect } from 'view/components/inputs/selects/contact';
import { NormalisedItem } from '@rexlabs/select';
import { filterNormalisedList } from 'utils/form/filter-normalised-list';
import { TextInput } from '@rexlabs/text-input';
import { get } from 'lodash';
import { Checkbox } from '@rexlabs/checkbox';
import { OutlineButton } from '@rexlabs/button';
import { StyleSheet, useStyles } from '@rexlabs/styling';
import AddIcon from 'view/components/icons/add';
import { Contact } from 'src/modules/contacts/types/contact-types';
import { TenantsForm } from 'data/models/entities/tenancies';

const defaultStyles = StyleSheet({
  grid: {
    columnGap: ({ token }) => token('spacing.xl'),
    rowGap: ({ token }) => token('spacing.m'),
    flex: 1
  }
});

export interface TenantFieldArrayContentProps {
  fields: FieldArrayField[];
  push: (value: any) => void;
  values?: TenantsForm;
}

export function TenantFieldArrayContent({
  fields,
  push,
  values
}: TenantFieldArrayContentProps) {
  const s = useStyles(defaultStyles);
  React.useEffect(() => {
    if (fields.length === 0) {
      push({ role: 'tenant' });
    }
  }, [fields.length, push]);

  const selectedTenants: Contact[] =
    values?.tenants?.map((tenantRow) => tenantRow.contact).filter(Boolean) ||
    [];

  return (
    <Box sy='2.4rem'>
      {fields.map(({ field, actions }) => {
        return (
          <TenancyDialogLineItem
            ActionButton={
              <RemoveIconButton
                isDisabled={fields.length === 1}
                onClick={() => actions.remove()}
              />
            }
            key={field.name}
          >
            <Grid columns={2} {...s('grid')}>
              <Column width={2}>
                <Field
                  Input={RadioGroupInput}
                  id={`${field.name}.role`}
                  name={`${field.name}.role`}
                  inputProps={{
                    options: [
                      { label: 'Tenant', value: 'tenant' },
                      { label: 'Occupier', value: 'occupier' }
                    ],
                    orientation: 'horizontal'
                  }}
                />
              </Column>
              <Field
                id={`${field.name}.contact`}
                name={`${field.name}.contact`}
                label='Contact'
                Input={ContactSelect}
                inputProps={{
                  filter: (normalisedItem: NormalisedItem) => {
                    return filterNormalisedList(
                      normalisedItem,
                      selectedTenants
                    );
                  }
                }}
              />
              <Field
                id={`${field.name}.payment_reference`}
                name={`${field.name}.payment_reference`}
                label='Tenant reference'
                Input={TextInput}
              />

              {get(values, `${field.name}.role`) === 'tenant' && (
                <Field
                  data-testid={`${field.name}.has_guarantor`}
                  Input={Checkbox}
                  name={`${field.name}.has_guarantor`}
                  inputProps={{
                    label: 'Tenant has a guarantor'
                  }}
                  optional={false}
                />
              )}
              <div />

              {get(values, `${field.name}.has_guarantor`) &&
                get(values, `${field.name}.role`) === 'tenant' && (
                  <Field
                    optional={false}
                    Input={ContactSelect}
                    name={`${field.name}.guarantors`}
                    id={`${field.name}.guarantors`}
                    inputProps={{ multi: true }}
                  />
                )}
            </Grid>
          </TenancyDialogLineItem>
        );
      })}
      <OutlineButton
        onClick={() =>
          push({
            is_primary: false,
            role: 'tenant'
          })
        }
        IconLeft={AddIcon}
      >
        Add another tenant
      </OutlineButton>
    </Box>
  );
}
