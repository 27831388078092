import { useModelActions } from '@rexlabs/model-generator';
import { pick } from 'lodash';
import { api } from 'utils/api/api-client';
import { RecordSubmitHandler } from 'view/components/record-screen/utils';
import { propertyAreasModel } from 'src/modules/property-areas/property-areas/models/property-areas-model';
import { PropertyAreaForm } from 'src/modules/property-areas/property-areas/types/PropertyAreaForm';
import { useRecordUpdatedToast } from 'src/modules/common/toasts/hooks/use-record-updated-toast';
import { PropertyArea } from 'src/modules/property-areas/property-areas/types/PropertyArea';
import { propertiesModel } from 'data/models/entities/properties';

export function useUpdatePropertyAreaSubmitHandler() {
  const { refreshItem } = useModelActions(propertiesModel);

  const addUpdatedToast = useRecordUpdatedToast(propertyAreasModel, {
    actions: []
  });

  return (area: PropertyArea, propertyId: string) => {
    const onSubmit: RecordSubmitHandler<PropertyAreaForm> = async ({
      changedValues
    }) => {
      const deleteIds = changedValues.delete_ids || [];

      const requests = transformPropertyAreaFormDataToPipelineRequest({
        values: changedValues,
        propertyAreaId: area.id,
        deleteIds
      });

      await api.post('request-pipelines', {
        requests
      });

      addUpdatedToast(area);
      await refreshItem({ id: propertyId });

      return true;
    };

    return onSubmit;
  };
}

function transformPropertyAreaFormDataToPipelineRequest({
  values,
  propertyAreaId,
  deleteIds
}: {
  values: Partial<PropertyAreaForm>;
  propertyAreaId: string;
  deleteIds: string[];
}) {
  const requests: Array<any> = [];

  /**
   *  This request is for updating name and included for new properties
   */
  if ('name' in values) {
    requests.push({
      id: 'property_areas',
      method: 'PATCH',
      path: `/api/v1/properties/property-areas/${propertyAreaId}`,
      json: pick(values, ['name'])
    });
  }

  if (Array.isArray(values.items)) {
    const itemRequests = values.items.map((item, index) => {
      return item.id
        ? getPatchRequest(item, index)
        : getPostRequest(item, index);
    });

    requests.push(itemRequests);
  }

  if (deleteIds.length) {
    const deleteRequests = deleteIds.map((id, index) => {
      return getDeleteRequest(id, index);
    });

    requests.push(deleteRequests);
  }

  /**
   *  This request is for updating the order of the items to maintain sort order
   */
  const itemOrderArray = values.items!.map((item, index) => {
    return item.id || `{{$.property_area_items_${index}.id}}`;
  });

  requests.push({
    id: 'property_areas_item_array',
    method: 'PATCH',
    path: `/api/v1/properties/property-areas/${propertyAreaId}`,
    json: {
      item_order: itemOrderArray
    }
  });

  return requests.flat();

  /**
   *  These requests are for any existing items which need to be updated
   */
  function getPatchRequest(item, index) {
    return {
      id: `property_area_items_${index}`,
      method: 'PATCH',
      path: `/api/v1/properties/property-area-items/${item.id}`,
      json: { name: item.name }
    };
  }

  /**
   *  These requests are for any new items added while editing the property area
   */
  function getPostRequest(item, index) {
    return {
      id: `property_area_items_${index}`,
      method: 'POST',
      path: `/api/v1/properties/property-area-items`,
      json: {
        name: item.name,
        property_area: {
          id: propertyAreaId
        }
      }
    };
  }

  /**
   *  These requests are for any items removed while editing the property area
   */
  function getDeleteRequest(id, index) {
    return {
      id: `property_area_items_delete_${index}`,
      method: 'DELETE',
      path: `/api/v1/properties/property-area-items/${id}`
    };
  }
}
