import React, { useCallback } from 'react';

import { DialogProps } from '@rexlabs/dialog';
import { useModelActions } from '@rexlabs/model-generator';

import { DialogContentConfig } from 'view/components/record-screen/dialog/types';
import {
  CreateRecordDialog,
  HandleRecordCreate
} from 'view/components/dialogs/create-record-dialog/create-record-dialog';

import { useRecordCreatedToast } from 'src/modules/common/toasts/hooks/use-record-created-toast';

import { applianceBlock } from 'src/modules/tasks/maintenance/blocks/maintenance-appliance-block';
import { tasksModel } from '../../common/models/tasks-model';
import { detailsBlock } from '../blocks/maintenance-details-block';
import { issuesReportedBlock } from '../blocks/issues-reported-block';
import { workDetailsCreateBlock } from '../blocks/work-details-create-block';
import { attachmentsBlock } from '../blocks/attachments-block';
import { taskPriorityBlock } from '../../common/blocks/task-priority-block';
import { MaintenanceFormData } from '../types/MaintenanceFormData';
import { mapMaintenanceFormDataToTaskCreateRequest } from '../mappers/map-maintenance-form-data-to-task-create-request';
import { taskDateBlock } from '../blocks/task-date-block';
import { createTaskAndLinkChecklists } from '../../common/utils/create-task-and-link-checklists';
import { checklistTemplateSelectBlock } from '../../common/blocks/checklist-template-select-block';
import { useTaskTypesWithCustomAddToRecord } from '../../settings/hooks/use-task-types-with-custom-add-to-record';

export const useContent: () => DialogContentConfig = () => {
  const taskTypesWithCustomAddToRecord = useTaskTypesWithCustomAddToRecord();
  return [
    {
      id: 'details',
      label: 'Details',
      blocks: [
        detailsBlock,
        applianceBlock,
        issuesReportedBlock,
        attachmentsBlock,
        ...(taskTypesWithCustomAddToRecord.includes('task_maintenance')
          ? [checklistTemplateSelectBlock]
          : []),
        taskPriorityBlock,
        taskDateBlock,
        workDetailsCreateBlock
      ]
    }
  ];
};

interface CreateMaintenanceDialogProps extends DialogProps {
  initialValues?: Partial<MaintenanceFormData>;
  onCreate?: (data) => void;
  isFixture?: boolean;
}

export function CreateMaintenanceDialog({
  initialValues,
  isFixture,
  onClose,
  onCreate,
  target
}: CreateMaintenanceDialogProps) {
  const { createItem, refreshLists } = useModelActions(tasksModel);
  const addToast = useRecordCreatedToast('task_maintenance');
  const content = useContent();

  const handleSubmit: HandleRecordCreate<any> = useCallback(
    async ({ values }) => {
      const maintenanceTask = await createTaskAndLinkChecklists<'task_maintenance'>(
        values,
        mapMaintenanceFormDataToTaskCreateRequest
      );

      onCreate?.(maintenanceTask);

      addToast(maintenanceTask);

      refreshLists();

      return maintenanceTask;
    },
    [addToast, createItem, onCreate, refreshLists]
  );

  const initialData = {
    ...(initialValues || {}),
    details: { ...initialValues?.details, issue_relates_to_appliance: false }
  };

  return (
    <CreateRecordDialog
      recordType='task_maintenance'
      data={initialData}
      content={content}
      blockProps={{ taskTypeId: 'task_maintenance', isCreating: true }}
      onClose={onClose}
      onSubmit={handleSubmit}
      size='l'
      target={target}
      isFixture={isFixture}
    />
  );
}
