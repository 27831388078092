import React from 'react';

import { TitleBlock } from 'view/components/cards/title/title-block';

import { RecordObject, RecordType } from 'data/models/types';
import { TrustAccount } from 'data/models/entities/financials/trust-accounts';
import { getRecordTitle } from 'utils/records/get-record-title';

const type: RecordType = 'trust_account';

type TrustAccountTitleBlockProps = {
  trustAccount: TrustAccount;
};

export function TrustAccountTitleBlock({
  trustAccount
}: TrustAccountTitleBlockProps) {
  const title = getRecordTitle({ type, object: trustAccount } as RecordObject);

  return <TitleBlock title={title} type={type} />;
}
