import { useFeatureFlags } from 'view/components/@luna/feature-flags';
import { FLAGS } from 'utils/feature-flags';
import { TaskType } from '../../common/types/TaskType';

/**
 * This hook needs to be removed, once the feature is live - once it has been removed,
 * delete the flag from flagsmith and remove the flag from the feature flags file.
 */
export function useTaskTypesWithCustomAddToRecord() {
  const { hasFeature } = useFeatureFlags();

  const taskTypesWithCustomAddToRecord: TaskType[] = [
    'todo',
    ...((hasFeature(FLAGS.CHECKLIST_ADD_CUSTOM_V2)
      ? [
          'task_quote',
          'task_arrears',
          'task_inspection',
          'task_lease_review',
          'task_maintenance',
          'task_move_in',
          'task_move_out',
          'task_property_compliance',
          'task_supplier_compliance',
          'task_work_order'
        ]
      : []) as TaskType[])
  ];

  return taskTypesWithCustomAddToRecord;
}
