import React from 'react';

import { useStyles, useToken, StyleSheet, text, mq } from '@rexlabs/styling';
import Box, { BoxStylingProps } from '@rexlabs/box';
import { ButtonGroup } from '@rexlabs/button';
import { Breakpoints } from '@rexlabs/breakpoints';

import {
  Card as LunaCard,
  CardContent,
  CardDivider
} from 'view/components/@luna/card';

import { ActionButtons } from 'view/components/@luna/action-buttons';
import { ActionDeclaration } from 'src/modules/common/actions/types/action-declaration-types';

const defaultStyles = StyleSheet({
  containerIntentError: {
    color: ({ token }) => token('color.textStyle.danger.idle.contrast'),
    backgroundColor: ({ token }) => token('color.danger.idle.default')
  },

  title: {
    ...text.styles({
      fallback: 'heading.4'
    }),

    ...mq.styles({
      queries: {
        minWidth: 's'
      },
      styles: {
        ...text.styles({
          fallback: 'heading.3'
        })
      }
    })
  }
});

export interface CardProps extends Omit<BoxStylingProps, 'flexShrink'> {
  hasPadding?: boolean;
  title?: React.ReactNode;
  actions?: ActionDeclaration[];
  children?: React.ReactNode;
  intent?: 'error';
}

export function Card({ title, actions, children, intent, ...rest }: CardProps) {
  const s = useStyles(defaultStyles);
  const token = useToken();
  const showTitle = !!title || !!actions;

  return (
    <LunaCard
      {...rest}
      {...s.with({
        containerIntentError: intent === 'error'
      })(rest)}
    >
      <CardContent>
        {showTitle && (
          <Box alignItems='center'>
            <Box flexDirection='row' alignItems='center' flex={1}>
              <span {...s('title')}>{title}</span>
            </Box>
            <ButtonGroup ml='2.4rem' sx={token('spacing.m')}>
              {!!actions?.length && <ActionButtons actions={actions} />}
            </ButtonGroup>
          </Box>
        )}
        {children && showTitle ? (
          <Breakpoints queries={{ maxWidth: 's' }}>
            <CardDivider />
          </Breakpoints>
        ) : null}
        {children}
      </CardContent>
    </LunaCard>
  );
}
