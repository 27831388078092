import React from 'react';
import { StyleSheet, useStyles } from '@rexlabs/styling';

const defaultStyles = StyleSheet({
  splitColumn: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridGap: 'inherit'
  }
});

interface SplitColumnProps {
  children: React.ReactNode;
}

export function SplitColumn(props: SplitColumnProps) {
  const s = useStyles(defaultStyles);
  return <div {...s('splitColumn')} {...props} />;
}
