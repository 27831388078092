import { useRecordScreenSubmitHandler } from 'view/hooks/use-record-screen-submit-handler';
import { mapTaskTypeAssignmentFormDataToPortfolioUpdateRequest } from '../mappers/map-task-type-assignment-form-data-to-portfolio-update-request';

export function usePortfolioSubmitHandler({ actions, data }) {
  return useRecordScreenSubmitHandler(async ({ changedValues, values }) => {
    await actions.updateItem({
      id: data?.id,
      data: {
        ...changedValues,
        ...(values?.task_type_assignments
          ? {
              task_type_assignments: mapTaskTypeAssignmentFormDataToPortfolioUpdateRequest(
                values.task_type_assignments
              )
            }
          : {})
      },
      args: {
        include:
          'members,members.user,members.user.contact,task_type_assignments.contact'
      }
    });
  });
}
