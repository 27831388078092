import * as React from 'react';
import Box from '@rexlabs/box';
import { FieldArray } from '@rexlabs/form';
import { BlockConfig } from 'view/components/record-screen/types';
import { EmptyCard } from 'view/components/record-screen/cards/empty-card';
import { Message } from 'view/components/@luna/message';
import EmptyTable from 'assets/illustrations/empty-table.svg';
import { RecordTable, Columns } from 'view/components/table';
import { NewSplitTransactionForm } from '../components/new-split-transaction-form';
import { RemoveCell } from '../components/remove-cell';
import { NewSplitTransactionFormValue } from '../types';

const transactionColumns: Columns = [
  {
    id: 'paid_date',
    Header: 'Date',
    accessor: (item) => item.paid_date,
    type: 'date',
    width: 120
  },
  {
    id: 'description',
    Header: 'Description',
    accessor: (item) => item.description,
    width: 250
  },
  {
    id: 'reference',
    Header: 'Reference',
    accessor: (item) => item.reference,
    width: 120
  },
  {
    id: 'amount',
    Header: 'Amount',
    accessor: (item) => item.amount,
    type: 'currency',
    width: 120
  }
];

export interface ChildTransactionsFormValues {
  subTransactions: Array<NewSplitTransactionFormValue>;
}

export const childTransactionBlock: BlockConfig<
  ChildTransactionsFormValues,
  null,
  ChildTransactionsFormValues
> = {
  id: 'split-child-transaction',
  title: 'Child transactions',

  Edit: ({ values }) => {
    const subTransactions = values!.subTransactions!;
    const hasSubTransactions = subTransactions.length > 0;

    const handleNewLineItem = React.useCallback((values, form, push) => {
      push({
        ...values,
        id: `new:${Date.now()}`
      });

      form.resetForm();
    }, []);

    const handleRemove = React.useCallback(
      (item, remove) => {
        const index = subTransactions.findIndex(
          (transaction) => transaction.id === item.id
        );

        remove(index);
      },
      [subTransactions]
    );

    return (
      <FieldArray name='subTransactions'>
        {({ push, remove }) => (
          <Box flexDirection='column' gap={24}>
            <NewSplitTransactionForm
              handleSubmit={(values, form) =>
                handleNewLineItem(values, form, push)
              }
            />

            {hasSubTransactions ? (
              <RecordTable
                id='child-transactions'
                columns={[
                  ...transactionColumns,
                  {
                    id: 'action',
                    Cell: RemoveCell,
                    accessor: (item) => {
                      return {
                        remove: () => handleRemove(item, remove)
                      };
                    },
                    width: 70,
                    sticky: 'right'
                  }
                ]}
                isLoading={false}
                items={subTransactions}
              />
            ) : (
              <EmptyCard hasGreyBackground>
                <Message
                  title={'No transactions added'}
                  Illustration={EmptyTable}
                >
                  Use the form above to add child transactions
                </Message>
              </EmptyCard>
            )}
          </Box>
        )}
      </FieldArray>
    );
  }
};
