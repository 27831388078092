import React, { PropsWithChildren, useContext } from 'react';
import { StyleSheet, useStyles } from '@rexlabs/styling';

import { FormGridContext } from './context';

export const MQ_SINGLE_WIDTH = '600px';

const defaultStyles = StyleSheet({
  double: {
    gridColumn: 'span 2',

    [`@media (max-width: ${MQ_SINGLE_WIDTH})`]: {
      gridColumn: 'span 1'
    }
  },
  triple: {
    gridColumn: 'span 3',

    [`@media (max-width: ${MQ_SINGLE_WIDTH})`]: {
      gridColumn: 'span 1'
    }
  },
  full: {
    gridColumn: '1/-1'
  }
});

interface ColumnProps {
  width: 2 | 3 | 4;
}

export function Column({ width, ...props }: PropsWithChildren<ColumnProps>) {
  const columns = useContext(FormGridContext);
  const s = useStyles(defaultStyles);

  const isWiderThanGrid = width > columns;

  if (isWiderThanGrid) {
    console.error(
      `Unsupported column width for given grid column count:\n\n` +
        `Grid columns: ${columns}\nColumn width: ${width}`
    );
    return null;
  }

  return (
    <div
      {...s({
        double: width === 2,
        triple: width === 3,
        full: width === columns
      })}
      {...props}
    />
  );
}
