import {
  StatusAverageTag,
  StatusBadTag,
  StatusGoodTag,
  Tag
} from '@rexlabs/tag';
import React from 'react';
import { ValueListItem } from 'utils/normaliser/value-list-item';
import _ from 'lodash';

export type StatusTagType = 'neutral' | 'good' | 'average' | 'bad';

export type StatusTagMap<T extends string> = {
  [index in T]: StatusTagType;
};

interface StatusTagProps<T extends string> {
  status: ValueListItem;
  map: StatusTagMap<T>;
}

export function StatusTag<T extends string>({
  status,
  map
}: StatusTagProps<T>) {
  const type = _.get(map, status?.id);

  if (!type) {
    console.warn(`there is no value for ${status?.id} in the status map`, map);
  }

  switch (type) {
    case 'neutral':
      return <Tag>{status?.label}</Tag>;
    case 'good':
      return <StatusGoodTag>{status?.label}</StatusGoodTag>;
    case 'bad':
      return <StatusBadTag>{status?.label}</StatusBadTag>;
    default:
      return <StatusAverageTag>{status?.label}</StatusAverageTag>;
  }
}

export function titleBlockStatusTag<T extends string>(
  status: ValueListItem,
  map: StatusTagMap<T>
) {
  const type = map[status.id];
  return {
    type,
    label: status.label
  };
}
