import * as React from 'react';
import { TabsProps } from '@rexlabs/tabs';
import TabBar from '@rexlabs/tabs/module/tab-bar';
import { startCase } from 'lodash';

type Item = Omit<TabsProps['items'][0], 'tabProps' | 'Tab'> & {
  Tab: React.ReactElement;
};

/**
 *  USAGE:
 *  Always default to Vivid Tab component but sometimes when we pass `Tab` property part of the `items` array prop,
 *  it causes re-render and page issues. So in this component, we use the internal TabBar component which vivid Tab
 *  uses to create a similar UI.
 */
export interface FakeTabsProps<I extends Readonly<Item>> {
  items: Array<I>;
}

export function FakeTabs<I extends Item>(props: FakeTabsProps<I>) {
  const { items } = props;

  const [activeTabName, setActiveTabName] = React.useState(items[0].name);

  const tabs = props.items.map((item) => {
    return {
      name: item.name,
      label: item?.label || startCase(item.name),
      IconStart: () => (
        <>
          {item?.IconStart ? (
            <item.IconStart isActive={item.name === activeTabName} />
          ) : null}
        </>
      ),
      IconEnd: () => (
        <>
          {item?.IconEnd ? (
            <item.IconEnd isActive={item.name === activeTabName} />
          ) : null}
        </>
      )
    };
  });

  const TabComponents = React.useMemo(
    () =>
      items.map((item) => (
        <div key={item.name}>
          {item.name === activeTabName ? item.Tab : null}
        </div>
      )),
    [items, activeTabName]
  );

  return (
    <>
      <TabBar
        activeTab={activeTabName}
        onChange={({ name }) => setActiveTabName(name)}
        items={tabs}
      />
      {TabComponents}
    </>
  );
}
