import { ActionDeclaration } from 'src/modules/common/actions/types/action-declaration-types';
import { useGetAddDocumentsAction } from 'src/modules/documents/actions/use-get-add-documents-action';
import { useGetAddNotesAction } from 'src/modules/tasks/common/actions/get-add-notes-action';
import { useGetPrimaryRecordActionGroup } from 'view/hooks/actions/use-get-primary-record-action-group';
import { useGetToggleClosedAction } from 'src/modules/tasks/common/hooks/action-declarations/use-get-toggle-closed-action';
import { useGetCreateEmailAction } from 'src/modules/communications/messages/hooks/action-declarations/use-get-create-email-action';
import { propertiesModel } from 'data/models/entities/properties';
import { useGetCreateSMSAction } from 'src/modules/communications/messages/hooks/action-declarations/use-get-create-sms-action';
import { MoveOut } from '../../types/MoveOut';

export function useGetMoveOutActions() {
  const getPrimaryRecordActionGroup = useGetPrimaryRecordActionGroup();
  const getCreateEmailAction = useGetCreateEmailAction(propertiesModel);
  const getCreateSMSAction = useGetCreateSMSAction(propertiesModel);
  const getAddDocumentsAction = useGetAddDocumentsAction();
  const getAddNotesAction = useGetAddNotesAction();
  const getToggleClosedAction = useGetToggleClosedAction();

  return (moveOut?: MoveOut): ActionDeclaration[] => {
    if (!moveOut) return [];

    return [
      ...getPrimaryRecordActionGroup('task_move_out', moveOut.id),
      getCreateEmailAction(moveOut.property),
      getCreateSMSAction(moveOut.property),
      getAddDocumentsAction({ data: moveOut }),
      getAddNotesAction({ data: moveOut }),
      getToggleClosedAction(moveOut)
    ];
  };
}
