import React, { useCallback } from 'react';

import {
  RecordDialog,
  RecordDialogProps
} from 'view/components/record-screen/dialog/dialog';
import { DialogContentConfig } from 'view/components/record-screen/dialog/types';

import { RecordSubmitHandler } from 'view/components/record-screen/utils';
import { useModelActions } from '@rexlabs/model-generator';
import {
  CreditNote,
  financialsCreditNotesModel
} from 'data/models/entities/financials/credit-notes';
import {
  Invoice,
  financialsInvoicesModel
} from 'data/models/entities/financials/invoices';
import { api } from 'utils/api/api-client';
import { useToast } from 'view/components/@luna/notifications/toast';
import { Bold } from '@rexlabs/text';
import { OutlineButton } from '@rexlabs/button';
import { push } from '@rexlabs/whereabouts';
import { createDetailsBlock } from '../blocks/create-details';
import { CreateCreditNoteDialogButtonGroup } from '../components/create-credit-note-dialog-button-group';
import { CREDIT_NOTES_ROUTES } from '../routes';

const content: DialogContentConfig = [
  {
    id: 'basics',
    label: 'Basics',
    blocks: [createDetailsBlock]
  }
];

interface CreateCreditNoteDialogProps extends Partial<RecordDialogProps> {
  updateListId?: string;
  onCreate?: (item?: CreditNote) => any;
  invoice?: Invoice;
}

export function CreateCreditNoteDialog({
  onClose,
  invoice,
  onCreate
}: CreateCreditNoteDialogProps) {
  const { addToast } = useToast();
  const { createItem, refreshLists } = useModelActions(
    financialsCreditNotesModel
  );
  const { refreshItem: refreshInvoice } = useModelActions(
    financialsInvoicesModel
  );

  const handleSubmit: RecordSubmitHandler = useCallback(
    async ({ values }) => {
      const creditNoteTemplateResponse = await api.post(
        '/financials/credit-note-templates',
        values
      );

      const creditNoteResponse = await createItem({
        data: creditNoteTemplateResponse.data
      });

      addToast({
        description: (
          <>
            A credit note has been successfully created for{' '}
            <Bold>{`${values.invoice.record_reference}`}</Bold>
          </>
        ),
        actions: [
          {
            label: 'View',
            onClick: () =>
              push(CREDIT_NOTES_ROUTES.CREDIT_NOTE, {
                params: { creditNoteId: creditNoteResponse.data.id }
              }),
            Button: OutlineButton
          }
        ]
      });

      await Promise.all([
        refreshLists(),
        invoice && refreshInvoice({ id: invoice?.id })
      ]);

      await Promise.resolve(onCreate?.(creditNoteResponse.data));

      return creditNoteResponse.data;
    },
    [createItem, addToast, refreshLists, invoice, refreshInvoice, onCreate]
  );

  return (
    <RecordDialog
      size='l'
      data={{
        invoice
      }}
      onClose={onClose}
      title='Issue credit note'
      handleSubmit={handleSubmit}
      content={content}
      ButtonGroup={CreateCreditNoteDialogButtonGroup}
    />
  );
}
