import * as React from 'react';
import { EmptyCard } from 'view/components/record-screen/cards/empty-card';
import { Message } from 'view/components/@luna/message';
import EmptyTable from 'assets/illustrations/empty-table.svg';

export const PropertyTaskTableEmptyState = ({ label }: { label: string }) => {
  return (
    <EmptyCard>
      <Message
        title={`${label} tasks`}
        Illustration={EmptyTable}
      >{`There are currently no ${label.toLowerCase()} tasks for this property`}</Message>
    </EmptyCard>
  );
};
