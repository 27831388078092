import React from 'react';
import { RecordDialog } from 'view/components/record-screen/dialog/dialog';
import { DialogContentConfig } from 'view/components/record-screen/dialog/types';
import { DialogProps } from '@rexlabs/dialog';
import { subtaskOutcomeBlock } from '../blocks/subtask-outcome-block';
import { ChecklistItemWithId } from '../../common/blocks/create-checklists-block';
import { useChecklistItemMutation } from '../hooks/use-checklist-item-mutation';

const content: DialogContentConfig = [
  {
    id: 'subtask-outcome',
    label: 'Subtask outcome',
    blocks: [subtaskOutcomeBlock]
  }
];

interface CaptureOutcomeDialogProps extends DialogProps {
  checklistItem: ChecklistItemWithId;
  onAfterSubmit?: () => void;
}

export const CaptureOutcomeDialog = ({
  checklistItem,
  onClose,
  onAfterSubmit
}: CaptureOutcomeDialogProps) => {
  const checklistItemMutation = useChecklistItemMutation();
  async function handleSubmit({ values }) {
    const result = await checklistItemMutation.mutateAsync({
      skip_triggered_actions: false, // in this case, we want to trigger the action when we complete the task
      item: checklistItem,
      outcome_value_id: values.outcome_value_id
    });
    await onAfterSubmit?.();
    return result;
  }

  const data = {
    outcome_options: checklistItem?.outcome_data?.outcome_options
  };

  async function handleClose() {
    await onAfterSubmit?.();
    await onClose?.();
  }

  return (
    <RecordDialog
      title='Subtask outcome'
      content={content}
      data={data}
      onClose={handleClose}
      handleSubmit={handleSubmit}
      submitLabel='Complete subtask with outcome'
    />
  );
};
