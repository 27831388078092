import { useSessionState } from 'src/modules/common/hooks/session-state';
import { getDefaultFilterKey } from 'src/modules/app-wide-filters/utils/get-default-filter-key';
import { getTemporaryFilterKey } from 'src/modules/app-wide-filters/utils/get-temporary-filter-key';

import {
  AppWideFilterState,
  GlobalFilterType
} from 'src/modules/app-wide-filters/types/app-wide-filter-state';
import { useAppWideFilterContext } from 'src/modules/app-wide-filters/contexts/app-wide-filter-context';
import { useMemo } from 'react';

/**
 * The app-wide filter stores 2 sets of filters in local storage:
 * 1. A default filter which is applied when the user logs in
 * 2. A temporary filter that is used instead if it is also set.
 * So this hook returns the temporary filter if set, and otherwise falls back to default.
 */
export function useGetAppWideFilter(): [AppWideFilterState, GlobalFilterType] {
  const sessionData = useSessionState();
  const userId = sessionData.user?.id;
  const siloId = sessionData.activeSilo?.id;
  const { appWideFilterKey } = useAppWideFilterContext();

  return useMemo(() => {
    const defaultFilterKey = getDefaultFilterKey({ userId, siloId });
    const temporaryFilterKey = getTemporaryFilterKey({ siloId, userId });

    const defaultFilter = JSON.parse(
      localStorage.getItem(defaultFilterKey) || '{}'
    ) as AppWideFilterState;

    const temporaryFilter = JSON.parse(
      localStorage.getItem(temporaryFilterKey) || '{}'
    ) as AppWideFilterState;

    if (temporaryFilter?.type) {
      return [temporaryFilter, 'temporary'];
    }

    if (defaultFilter?.type) {
      return [defaultFilter, 'default'];
    }

    return [{ type: 'filter_by_all' }, null];
  }, [appWideFilterKey, siloId, userId]);
}
