import { DialogProps, useDialog, useErrorDialog } from '@rexlabs/dialog';
import { Forms } from '@rexlabs/form';
import { useModelActions } from '@rexlabs/model-generator';
import invariant from 'invariant';
import * as React from 'react';
import { invokeActionDeclaration } from 'src/modules/common/actions/utils/invoke-action-declaration';
import { useRecordCreatedToast } from 'src/modules/common/toasts/hooks/use-record-created-toast';
import { extractValuesFromForms } from 'src/modules/common/utils/extract-values-from-forms';
import { SearchResultItem } from 'utils/api/get-search-results';
import { RecordDialog } from 'view/components/record-screen/dialog/dialog';
import { mapSelectedTemplatedAttachments } from 'src/modules/communications/messages/utils/map-selected-templated-attachments';
import { emptyMessageCreatorItem } from '../../common/data/empty-message-creator-item';
import { messageContentBlock } from '../blocks/message-content-block';
import { messageDetailsBlock } from '../blocks/message-details-block';
import {
  MessageCreateDialogButtonGroup,
  MessageCreateDialogButtonGroupProps
} from '../components/message-create-dialog-button-group';
import { useGetSendMessageAction } from '../hooks/action-declarations/use-get-send-message-action';
import { mapCreateMessageFormToRequest } from '../mappers/map-create-message-form-to-request';
import { CreateMessageFormData } from '../mappers/types/create-message-form-data';
import { CreateMessageRequest } from '../mappers/types/create-message-request';
import { messagesModel } from '../models/messages-model';
import { getChangeHandlers } from '../utils/get-change-handlers';
import { PreviewMessageFormDataDialog } from './preview-message-form-data-dialog';

export interface CreateEmailRecordDialogProps extends DialogProps {
  // NOTE: If you intend to pass in a template, you will need to pass in the content
  // and subject from the template, as well as the template itself.
  data: Partial<Omit<CreateMessageFormData, 'relates_to'>> & {
    relates_to: SearchResultItem | null;
  };
  onCreate?: (message: any) => any;
  hasButtonGroupActions?: boolean;
}

const content = [
  {
    id: 'add-message',
    label: 'Add Message',
    blocks: [messageDetailsBlock, messageContentBlock]
  }
];

const SEND_NOW = Symbol('SEND_NOW');
const SEND_LATER = Symbol('SEND_LATER');

export function CreateEmailRecordDialog({
  title = 'Send email now',
  hasButtonGroupActions,
  onClose,
  onCreate,
  data
}: CreateEmailRecordDialogProps) {
  const errorDialog = useErrorDialog();
  const { open: openPreviewFormDataDialog } = useDialog(
    PreviewMessageFormDataDialog
  );
  const [relatesTo, setRelatesTo] = React.useState<SearchResultItem | null>(
    data.relates_to
  );
  const { createItem, refreshLists } = useModelActions(messagesModel);
  const getSendMessageAction = useGetSendMessageAction();
  const addToast = useRecordCreatedToast(messagesModel, { actions: [] });

  // Define the change handlers here to avoid tightly coupling the blocks together
  const getHandlers = (forms?: Forms) => getChangeHandlers(setRelatesTo, forms);

  const clickedButtonRef = React.useRef<symbol | null>(null);

  const initialValues = {
    sent_from: emptyMessageCreatorItem,
    attachments: [],
    ...data,
    relates_to: relatesTo,
    include_generated_attachments: data.template?.templated_attachments
      ? mapSelectedTemplatedAttachments(data.template?.templated_attachments)
      : undefined
  };

  // To reduce duplication, we can extract common behavior into a function
  const createMessage = React.useCallback(
    async (data: CreateMessageRequest) => {
      const { data: message } = await createItem({
        data,
        args: {
          include: 'channels,template'
        }
      });
      Promise.all([refreshLists(), onCreate?.(message)]);
      return message;
    },
    [createItem, onCreate, refreshLists]
  );
  // Because the actual api calls are handled by the functions below, the submit handler will just be used for the built-in validation, and transforming the value to the desired DTO
  const handleSubmit = React.useCallback(
    async ({ values }) => {
      const type = clickedButtonRef.current;
      const data = await mapCreateMessageFormToRequest(values);

      switch (type) {
        // Send now will use the same logic for message creation, but will then invoke the send now action
        case SEND_NOW: {
          try {
            const message = await createMessage(data);
            await invokeActionDeclaration(getSendMessageAction, message);

            return message;
          } catch (error) {
            invariant(error instanceof Error, 'err should be Error');

            errorDialog.open({
              message: error.message
            });
          }

          break;
        }

        // Send later will basically just create the message and that is all
        case SEND_LATER: {
          try {
            const message = await createMessage(data);
            addToast(message);

            return true;
          } catch (error) {
            invariant(error instanceof Error, 'err should be Error');

            errorDialog.open({
              message: error.message
            });
          }

          break;
        }

        default: {
          return;
        }
      }
    },
    [createMessage, getSendMessageAction, errorDialog, addToast]
  );

  const handlePreview = React.useCallback(
    (forms) => {
      const formData: CreateMessageFormData = extractValuesFromForms(forms);
      openPreviewFormDataDialog({ data: { formData } });
    },
    [openPreviewFormDataDialog]
  );

  const handleSendLater = React.useCallback(() => {
    clickedButtonRef.current = SEND_LATER;
  }, []);

  const handleSendNow = React.useCallback(() => {
    clickedButtonRef.current = SEND_NOW;
  }, []);

  const getIsPreviewDisabled = React.useCallback((forms?: Forms) => {
    return !forms?.details?.values?.relates_to;
  }, []);

  return (
    <RecordDialog
      autofocus={data.relates_to === null}
      getHandlers={getHandlers}
      size='xl'
      title={title}
      data={initialValues}
      content={content}
      handleSubmit={handleSubmit}
      onClose={onClose}
      // need to pass different actions to the button group depending on which part of the split button is clicked
      ButtonGroup={MessageCreateDialogButtonGroup}
      blockProps={{
        messageType: 'email'
      }}
      buttonGroupProps={
        {
          hasButtonGroupActions,
          handlePreview,
          handleSendLater,
          handleSendNow,
          getIsPreviewDisabled
        } as MessageCreateDialogButtonGroupProps
      }
    />
  );
}
