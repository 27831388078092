import Box from '@rexlabs/box';
import * as React from 'react';
import {
  StatusChangeActionArgs,
  useStatusChangeAction
} from 'src/modules/common/actions/status/use-status-change-action';
import { invokeActionDeclaration } from 'src/modules/common/actions/utils/invoke-action-declaration';
import { useStatusDropdownRefresh } from 'src/modules/tasks/common/hooks/use-get-status-dropdown-refresh';
import { Task } from '../types/Task';
import { tasksModel } from '../models/tasks-model';
import { TaskStatusMenu } from './task-status-menu';

export function ProgressDropdown({
  value: task
}: {
  value?: Task<'todo' | 'task_move_in' | 'task_move_out'>;
}) {
  const refreshListsOnComplete = useStatusDropdownRefresh();
  const getStatusChangeAction = useStatusChangeAction(tasksModel);

  const handleStatusChange = task
    ? (newStatus) => {
        invokeActionDeclaration<StatusChangeActionArgs<typeof newStatus.id>[]>(
          getStatusChangeAction,
          {
            record: task,
            status: newStatus.id,
            dialogOptions: {
              type: 'none',
              afterAction: refreshListsOnComplete
            },

            // note we *only* want to refresh lists if the status is complete
            generalOptions: { avoidListRefresh: true }
          }
        );
      }
    : undefined;

  return (
    <Box
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
      data-testid='task-status-tag'
    >
      <TaskStatusMenu
        initialValue={task?.status}
        onChange={handleStatusChange}
      />
    </Box>
  );
}
