import { Body } from '@rexlabs/text';
import React from 'react';
import { SearchInput } from 'src/modules/communications/common/components/search-input';
import { formatCurrency } from 'utils/formatters';
import { Column, Grid } from 'view/components/@luna/form/grid';
import { BlockConfig } from 'view/components/record-screen/types';

export const searchReceiptsBlock: BlockConfig<any> = {
  id: 'searchForReceipts',
  Edit: ({ blockProps }) => {
    const { searchValue, setSearchValue } = blockProps;
    const helpText = blockProps.reference ? (
      <>
        Match the transaction with the reference number{' '}
        <b>{blockProps?.reference}</b> and the amount{' '}
        <b>{formatCurrency(blockProps?.amount)}</b>
      </>
    ) : (
      <>
        Match the transaction with the amount{' '}
        <b>{formatCurrency(blockProps?.amount)}</b>
      </>
    );

    return (
      <>
        <Body>{helpText}</Body>
        <Grid columns={4}>
          <Column width={2}>
            <SearchInput onSubmit={setSearchValue} initialValue={searchValue} />
          </Column>
        </Grid>
      </>
    );
  }
};
