import { RecordSubmitHandler } from 'view/components/record-screen/utils';
import { useEntityQuery } from '@rexlabs/model-generator';
import { useMemo } from 'react';
import { mapSupportingDocumentsFormDataToRequest } from '../mappers/map-supporting-documents-form-data-to-request';
import { getInspectionQuery } from '../../screens/inspection-details';

export function useGetSupportingDocumentsSubmitHandler(
  inspectionId: string
): RecordSubmitHandler {
  const query = useMemo(() => getInspectionQuery(inspectionId!), [
    inspectionId
  ]);

  const { actions } = useEntityQuery(query, {
    throwOnError: false
  });

  return async ({ changedValues }) => {
    const dataToSubmit = await mapSupportingDocumentsFormDataToRequest(
      changedValues
    );

    await actions.updateItem({
      id: inspectionId,
      data: dataToSubmit
    });

    return true;
  };
}
