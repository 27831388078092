import { LinkButton } from '@rexlabs/button';
import { StyleSheet, useStyles } from '@rexlabs/styling';
import React from 'react';
import { Ownership } from 'data/models/entities/ownerships';
import { CellProps } from '@rexlabs/table';
import { useIssueStatementConfirmationDialog } from 'src/modules/ownership-statements/common/dialogs/issue-statement-confirmation-dialog';
import { StatementType } from 'src/modules/statements/types';
import { issueStatementToastAction } from 'src/modules/ownership-statements/common/actions/issue-statement-toast-action';
import { useCreateStatements } from 'src/modules/statements/hooks/use-create-statements';
import { RecordTypes } from 'data/models/types';

export const styles = StyleSheet({
  actionLink: {
    color: ({ token }) => token('color.textStyle.secondary.idle.default')
  }
});

export type IssueStatementActionCellValue = {
  ownership: Ownership;
  statementStartDate?: string;
  statementEndDate: string;
  statementType: StatementType;
};

export const IssueStatementActionCell = (
  props: CellProps<any, IssueStatementActionCellValue>
) => {
  const issueStatementConfirmationDialog = useIssueStatementConfirmationDialog();

  const toastAction = issueStatementToastAction(props.value.statementType);
  const issueStatements = useCreateStatements();

  const s = useStyles(styles);

  const handleClick = () => {
    issueStatementConfirmationDialog({
      selectedItems: [props.value.ownership],
      statementType: props.value.statementType,
      statementEndDate: props.value.statementEndDate,
      statementStartDate: props.value.statementStartDate,
      recordType: RecordTypes.Ownership,
      handleConfirmation: issueStatements,
      toastAction
    });
  };

  return (
    <LinkButton {...s('actionLink')} onClick={handleClick}>
      Issue
    </LinkButton>
  );
};
