import React from 'react';
import Box from '@rexlabs/box';
import { Field } from '@rexlabs/form';
import { TextInput } from '@rexlabs/text-input';
import { Quri } from '@rexlabs/quri';
import { Grid, Column } from 'view/components/@luna/form/grid';
import { CreditAmountSplitInput } from 'view/components/inputs/credit-amount-split-input';
import { CreditInvoiceTemplateActions } from 'view/components/inputs/credit-invoice-template-actions';
import { ChartOfAccountsAccountSelect } from 'view/components/inputs/selects/v4-selects/chart-of-accounts-account';
import { BlockConfig } from 'view/components/record-screen/types';
import { HorizontalDivider } from 'view/components/ui/horizontal-divider';
import { SingleLineFormLayout } from 'view/components/layouts/form/single-line-form-layout';
import { InvoiceSelect } from 'view/components/inputs/selects/invoice-select';
import { CurrencyValue } from 'view/components/values/currency';
import '../utils/credit-note-validation-rules';

export const createDetailsBlock: BlockConfig = {
  id: 'details',
  validate: {
    definitions: {
      invoice: {
        rules:
          'required|invoiceStatusIsNotPaid|invoiceStatusIsNotDraft|invoiceStatusIsNotVoid|invoiceStatusIsNotAwaitingApproval'
      },
      template: { rules: 'required' },
      reason: { rules: 'requiredInTemplate:reason' },
      amount: {
        rules: 'requiredInTemplate:amount|creditAmountLessThanOrEqualToBalance'
      },
      'amount.value': {
        rules: 'required_with:amount'
      },
      'amount.type': {
        rules: 'required_with:amount'
      },
      payable_to_chart_of_accounts_account: {
        rules: 'requiredInTemplate:payable_to_chart_of_accounts_account'
      },
      payable_by_chart_of_accounts_account: {
        rules: 'requiredInTemplate:payable_by_chart_of_accounts_account'
      }
    }
  },
  Edit: function EditDetails({ values }) {
    const renderFields = () => {
      if (values.template) {
        const { inputs = [] } = values.template;

        return inputs?.map((input) => {
          const baseProps = {
            name: input.id,
            label: input.label,
            optional: false
          };
          switch (input.type.id) {
            case 'string':
              return <Field {...baseProps} Input={TextInput} />;
            case 'amount': {
              return <Field {...baseProps} Input={CreditAmountSplitInput} />;
            }
            case 'chart_of_accounts_account':
              return (
                <Field {...baseProps} Input={ChartOfAccountsAccountSelect} />
              );
            default:
              return null;
          }
        });
      }
      return null;
    };

    return (
      <Box>
        <Grid columns={3}>
          <Field
            label='Invoice'
            name='invoice'
            Input={InvoiceSelect}
            inputProps={{
              filter: [
                {
                  field: 'status_id',
                  op: 'nin',
                  value: ['paid', 'void']
                }
              ] as Quri[]
            }}
          />
          <SingleLineFormLayout>
            <CurrencyValue
              data-testid='amount_value'
              label='Amount'
              value={values.invoice?.amount_total_inc_tax || 0}
            />

            <CurrencyValue
              data-testid='balance_value'
              label='Balance'
              value={values.invoice?.amount_owing || 0}
            />
          </SingleLineFormLayout>
          <Box></Box>
          {values.invoice?.status.id === 'awaiting_payment' ? (
            <>
              <Column width={3}>
                <Box>
                  <HorizontalDivider />
                </Box>
              </Column>
              <Field
                label='Type of credit note'
                name='template'
                Input={CreditInvoiceTemplateActions}
                inputProps={{ invoice: values.invoice }}
              />
              {renderFields()}
            </>
          ) : null}
        </Grid>
      </Box>
    );
  }
};
