/*
 * This hook returns a function, that when tabbed table hash param keys are passed to it, it will return a hash query object
 * with the tab keys set to undefined. This can be used with whereabouts push to reset the active tab in tabbed tables.
 */
export function getResetTabbedTableActiveTabHashQuery(
  tabbedTableHashParamKeys: string[]
) {
  return tabbedTableHashParamKeys.reduce(
    (acc, id) => ({
      ...acc,
      [`${id}_tab`]: undefined
    }),
    {}
  );
}
