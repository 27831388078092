/**
 * TODO: revisit this, this is just a temporary implementation using an action menu
 * because overriding select styles still made dealing with spacing a bit dodgy
 */

import React, { forwardRef } from 'react';
import ActionMenu from '@rexlabs/action-menu';
import Box from '@rexlabs/box';
import { Body } from '@rexlabs/text';

import ArrowIcon from 'view/components/icons/arrow-down';

interface TaxIncludedInlineSelectProps {
  prefix?: string;
  value: boolean;
  onChange: (e: any) => void;
}

export function TaxIncludedInlineSelect({
  value,
  onChange,
  prefix
}: TaxIncludedInlineSelectProps) {
  const includeTaxLabel = prefix
    ? `${prefix} subtotal incl. tax`
    : 'Subtotal incl. tax';
  const excludeTaxLabel = prefix
    ? `${prefix} subtotal excl. tax`
    : 'Subtotal excl. tax';

  return (
    <ActionMenu
      items={[
        {
          label: includeTaxLabel,
          onClick: () => onChange({ target: { value: true } })
        },
        {
          label: excludeTaxLabel,
          onClick: () => onChange({ target: { value: false } })
        }
      ]}
      Button={forwardRef<HTMLDivElement, any>((props, ref) => (
        <Box
          flexDirection='row'
          sx='.4rem'
          alignItems='center'
          justifyContent='flex-end'
          style={{ cursor: 'pointer' }}
          ref={ref}
          {...props}
        >
          <Body is='span'>{value ? includeTaxLabel : excludeTaxLabel}</Body>
          <ArrowIcon style={{ height: '1.2rem', width: 'auto' }} />
        </Box>
      ))}
    />
  );
}
