import React from 'react';

import Box from '@rexlabs/box';
import { useToken } from '@rexlabs/styling';
import { Small } from '@rexlabs/text';
import { GhostIconButton } from '@rexlabs/button';
import { useMediaQuery } from '@rexlabs/breakpoints';

import { ColoredCard } from 'view/components/cards/colored-card';
import FinishIcon from 'view/components/icons/finish';
import StartIcon from 'view/components/icons/start';
import EditIcon from 'view/components/icons/edit';

import { InspectionRunResponsiveCard } from './inspection-run-responsive-card';
import { InspectionRunResponsiveIconContainer } from './inspection-run-responsive-icon-container';
import { InspectionRunResponsiveTitle } from './inspection-run-responsive-title';

interface InspectionRunBookEndCardProps {
  address: string;
  time: string;
  handleEdit?: () => void;
  isEditMode: boolean;
  isStart?: boolean;
}

export function InspectionRunBookEndCard({
  address,
  time,
  handleEdit,
  isEditMode,
  isStart = false
}: InspectionRunBookEndCardProps) {
  const token = useToken();

  const matchesMobile = useMediaQuery({ maxWidth: 's' });

  return (
    <InspectionRunResponsiveCard>
      <Box
        flexDirection={'row'}
        alignItems={'center'}
        columnGap={token('spacing.xs')}
      >
        <InspectionRunResponsiveIconContainer>
          <ColoredCard
            flex={0}
            flexDirection={'column'}
            alignItems={'center'}
            justifyContent={'center'}
            intent={'neutral'}
            padding={token('spacing.xs')}
          >
            {isStart ? (
              <StartIcon
                size={!matchesMobile ? 'l' : 'm'}
                data-testid={'start-icon'}
              />
            ) : (
              <FinishIcon
                size={!matchesMobile ? 'l' : 'm'}
                data-testid={'finish-icon'}
              />
            )}
          </ColoredCard>
        </InspectionRunResponsiveIconContainer>
        <Box flexDirection={'column'} rowGap={token('spacing.xxs')}>
          <InspectionRunResponsiveTitle>{address}</InspectionRunResponsiveTitle>
          <Box flexDirection={'row'} columnGap={token('spacing.xxs')}>
            <Small grey as='span'>
              {`${isStart ? 'Leave' : 'Tentative return'}:`}
            </Small>
            {/* TODO: we will need to add a new time select here. Pending design */}
            <Small semibold as='span'>
              {time}
            </Small>
          </Box>
        </Box>
        {isEditMode && handleEdit && (
          <InspectionRunResponsiveIconContainer
            flexDirection='row'
            alignItems='center'
            justifyContent='center'
            marginLeft='auto'
          >
            <GhostIconButton
              aria-label='edit location'
              Icon={() => <EditIcon size={!matchesMobile ? 'l' : 'm'} />}
              onClick={handleEdit}
            />
          </InspectionRunResponsiveIconContainer>
        )}
      </Box>
    </InspectionRunResponsiveCard>
  );
}
