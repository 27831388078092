import React from 'react';

import { Property } from 'src/modules/properties/types/property-types';

import { MessageIssue } from '../../common/types/message-issue';
import { addDocumentTypeToARecordBlock } from '../blocks/add-document-type-to-record-block';
import { useDocumentMessageIssueSubmitHandler } from '../hooks/use-document-message-issue-submit-handler';
import { MessageIssueDisclosureField } from './message-issue-disclosure-field';

export function DocumentMessageIssueDisclosureField({
  issue,
  fieldKey,
  setFieldValue
}: {
  fieldKey: string;
  issue: MessageIssue;
  setFieldValue?: (key, value) => void;
}) {
  const documentMessageIssueSubmitHandler = useDocumentMessageIssueSubmitHandler(
    issue
  );

  return (
    <MessageIssueDisclosureField
      block={addDocumentTypeToARecordBlock}
      issueRelatesTo={issue.message!.context as Property}
      issueRelatesToType={issue.message?.context.__record_type || ''}
      issueText={issue.issue.label}
      fieldKey={fieldKey}
      updateText={() => (
        <div>
          Upload a document of type <b>{issue!.missing_document_type!.label}</b>{' '}
          to this record.
        </div>
      )}
      setValue={(value) => {
        setFieldValue?.(fieldKey, value);
      }}
      onSubmit={documentMessageIssueSubmitHandler}
    />
  );
}
