import React from 'react';

import { Field } from '@rexlabs/form';

import { AddressItem } from 'src/lib/mapbox/types/AddressItem';

import { Grid } from 'view/components/@luna/form/grid';
import { AddressSelect } from 'view/components/inputs/selects/v4-selects/address-select';

export interface InspectionRunFinishFormValues {
  finish_address?: AddressItem;
}

export const inspectionRunFinishFormValidationConfig = {
  finish_address: { rules: 'required' }
};

export function InspectionRunFinishForm() {
  return (
    <Grid columns={1}>
      <Field
        Input={AddressSelect}
        name='finish_address'
        id='finish-address'
        label='Finish address'
        optional={false}
      />
    </Grid>
  );
}
