import React, { useMemo } from 'react';
import { useStyles, StyleSheet } from '@rexlabs/styling';
import { useModelActions } from '@rexlabs/model-generator';
import {
  TitleBlock,
  TitleBlockProps
} from 'view/components/cards/title/title-block';
import { RecordObject, RecordTypes } from 'data/models/types';
import { getRecordTitle } from 'utils/records/get-record-title';
import { TagConfig } from 'view/components/@luna/title-block';
import { ActionDeclaration } from 'src/modules/common/actions/types/action-declaration-types';
import { useGetPrimaryRecordActionGroup } from 'view/hooks/actions/use-get-primary-record-action-group';
import { formatDate } from 'utils/dates/format';
import { lowerCase } from 'lodash';
import CalendarIcon from 'view/components/icons/calendar';

import { useArrearsStatusTag } from 'src/modules/tasks/arrears/hooks/use-arrears-status-tag';
import { useGetAddDocumentsAction } from 'src/modules/documents/actions/use-get-add-documents-action';
import { useGetAddNotesAction } from 'src/modules/tasks/common/actions/get-add-notes-action';
import PropertyIcon from 'view/components/icons/property';
import { useGetReceiptFundsAction } from 'src/modules/financials/utils/financial-entity-action-group/use-get-receipt-funds-action';
import { formatCurrency } from 'utils/formatters';
import { useGetPaymentHistoryDownloadAction } from 'src/modules/tenancies/actions/use-get-payment-history-download-action';
import {
  getPaidToDateDescription,
  getPaidToPeriodDescription
} from 'src/modules/property-tenancies/utils/get-rent-position-description';
import { useGetCreateEmailAction } from 'src/modules/communications/messages/hooks/action-declarations/use-get-create-email-action';
import { useGetCreateSMSAction } from 'src/modules/communications/messages/hooks/action-declarations/use-get-create-sms-action';
import { ArrearsTask } from '../types/ArrearsTask';
import { tasksModel } from '../../common/models/tasks-model';
import { getTaskIncludes } from '../../common/utils/get-task-includes';

const type = RecordTypes.TaskArrears;

type ArrearsRecordObject = Extract<RecordObject, { type: 'arrears' }>;

type ArrearsTitleBlockProps = {
  arrears?: ArrearsTask;
  settings?: any;
  onStatusChange?: () => void;
};

const styles = StyleSheet({
  subText: {
    color: ({ token }) => token('color.textStyle.body.default'),
    fontWeight: ({ token }) => token('typography.weight.semibold')
  }
});

export function ArrearsTitleBlock({
  arrears,
  settings
}: ArrearsTitleBlockProps) {
  const s = useStyles(styles);

  const rentPaidToDateSetting =
    settings?.['rent-paid-to-date']?.id ||
    'effective_and_periodic_with_surplus';

  const { refreshItem } = useModelActions(tasksModel);

  const getPrimaryRecordActionGroup = useGetPrimaryRecordActionGroup();
  const getAddDocumentsAction = useGetAddDocumentsAction();
  const getAddNotesAction = useGetAddNotesAction();
  const getReceiptFundsAction = useGetReceiptFundsAction();
  const getPaymentHistoryDownloadAction = useGetPaymentHistoryDownloadAction();
  const getCreateEmailAction = useGetCreateEmailAction(tasksModel);
  const getCreateSMSAction = useGetCreateSMSAction(tasksModel);

  const statusTag: TagConfig = useArrearsStatusTag(arrears);

  const title = getRecordTitle({
    type,
    object: arrears
  } as ArrearsRecordObject);

  const { rent_arrears, rent_position } =
    arrears?.details?.property_tenancy || {};

  const stats = useMemo<TitleBlockProps['stats']>(
    () => [
      {
        label: 'Rent arrears',
        value: formatCurrency(rent_arrears?.amount),
        valueTag: `${rent_arrears?.days} days`,
        intent: 'danger'
      },

      ...([
        'effective_with_surplus',
        'effective_and_periodic_with_surplus'
      ].includes(rentPaidToDateSetting)
        ? [
            {
              label: 'Effective paid to date',
              value: getPaidToDateDescription({ rentPosition: rent_position }),
              intent: 'secondary' as const
            }
          ]
        : []),

      ...([
        'periodic_with_surplus',
        'effective_and_periodic_with_surplus'
      ].includes(rentPaidToDateSetting)
        ? [
            {
              label: 'Paid to period',
              value: getPaidToPeriodDescription({
                rentPosition: rent_position
              }),
              intent: 'secondary' as const
            }
          ]
        : [])
    ],
    [rent_arrears, rent_position]
  );

  if (!arrears) {
    return null;
  }

  const enableDownload =
    (arrears?.details?.property_tenancy?.rent_position
      ?.partially_paid_period_start_date ||
      arrears?.details?.property_tenancy?.rent_position
        ?.fully_paid_period_start_date) &&
    arrears?.details?.property_tenancy?.tenancy;

  const actions = [
    ...getPrimaryRecordActionGroup('task_arrears', arrears!.id),
    {
      label: 'Actions',
      actions: [
        getAddDocumentsAction({ data: arrears! }),
        getAddNotesAction({ data: arrears! }),
        getCreateEmailAction(arrears),
        getCreateSMSAction(arrears),

        getPaymentHistoryDownloadAction({
          tenancy: arrears?.details?.property_tenancy?.tenancy,
          propertyTenancy: arrears?.details?.property_tenancy,
          isDisabled: !enableDownload
        })
      ]
    },

    ...(arrears?.details?.property_tenancy?.tenancy
      ? [
          {
            ...getReceiptFundsAction({
              entity: arrears?.details?.property_tenancy?.tenancy,
              recordTypeName: 'Tenancy',
              receiptFrom: arrears?.details?.property_tenancy?.tenancy?.tenants?.map(
                (tenant) => tenant.contact
              ),
              // NOTE: after receipting funds, we want to refresh the task, so we get the
              // correct stats
              onCreate: () => {
                return refreshItem({
                  id: arrears.id,
                  args: {
                    include: getTaskIncludes(arrears.type.id)
                  }
                });
              }
            }),
            intent: 'primary'
          }
        ]
      : [])
  ];

  const subTitles: TitleBlockProps['subTitles'] = [
    {
      Icon: PropertyIcon,
      label: arrears?.details?.property_tenancy?.property?.address_string
    }
  ];

  const closed_at = arrears.closed_at;

  return (
    <TitleBlock
      title={title}
      subTitles={subTitles}
      type={'rent arrears'}
      actions={actions as ActionDeclaration[]}
      tags={closed_at ? undefined : [statusTag]}
      Icon={CalendarIcon}
      stats={stats}
      subText={
        closed_at ? (
          <div>
            <span>This arrears task has been closed with the status </span>
            <span {...s('subText')}>
              {lowerCase(arrears.status.label)}
            </span> on <span {...s('subText')}>{formatDate(closed_at)}</span>
          </div>
        ) : undefined
      }
    />
  );
}
