import { RecordData } from 'utils/types';
import { RecordType } from 'data/models/types';

/**
 * @deprecated use the __record_type field instead where possible
 **/
export function getRecordType(record: RecordData): RecordType | undefined {
  if ('__record_type' in record) {
    return record?.__record_type;
  }
  return undefined;
}
