import { useCallback } from 'react';

import { useDialog } from '@rexlabs/dialog';

import { SingleActionDeclaration } from 'src/modules/common/actions/types/action-declaration-types';
import { CreateEmailRecordDialog } from 'src/modules/communications/messages/dialogs/create-email-record-dialog';
import { useMessageTriggerSettings } from 'src/modules/communications/messages/settings/message-triggers/hooks/use-message-trigger-settings';
import { getSearchResultItemFromObjectAndModel } from 'src/modules/common/utils/search-result-items/get-search-result-items-from-object-and-model';
import { fetchTemplate } from 'src/modules/communications/message-templates/utils/fetch-template';

import { FLAGS } from 'utils/feature-flags';
import { useFeatureFlags } from 'view/components/@luna/feature-flags';
import { statementsModel } from 'src/modules/statements/models/statements';
import { Statement } from 'src/modules/statements/types';
import { SearchResultItem } from 'utils/api/get-search-results';
import { Contact } from 'src/modules/contacts/types/contact-types';
import { contactsModel } from 'src/modules/contacts/models/contacts';
import { MessageTriggerKey } from 'src/modules/communications/messages/settings/message-triggers/types/MessageTriggerKey';

export function useGetSendStatementAction() {
  const { open: openCreateEmailDialog } = useDialog(CreateEmailRecordDialog);
  const { hasFeature } = useFeatureFlags();
  const { messageTriggerSettings } = useMessageTriggerSettings();

  return useCallback((statement: Statement):
    | SingleActionDeclaration
    | undefined => {
    if (!hasFeature(FLAGS.MESSAGE_ATTACHMENTS)) {
      return;
    }

    return {
      id: 'send-statement',
      intent: 'primary',
      group: 'statement',
      label: 'Send statement',
      handleAction: async () => {
        const statementSearchItem = getSearchResultItemFromObjectAndModel<Statement>(
          statement,
          statementsModel
        );

        const owners =
          'owners' in statement?.object &&
          ((statement?.object?.owners || []).map((owner) =>
            getSearchResultItemFromObjectAndModel(owner.contact, contactsModel)
          ) as SearchResultItem<Contact>[]);

        const statementType = statement.type.id;

        const messageTriggerKey: MessageTriggerKey | undefined =
          statementType === 'periodic_ownership'
            ? 'periodic-ownership-statement-template'
            : statementType === 'yearly_ownership'
            ? 'yearly-ownership-statement-template'
            : undefined;

        const defaultTemplateId =
          messageTriggerSettings[messageTriggerKey || '']?.template_id;

        const template = await fetchTemplate(defaultTemplateId);

        openCreateEmailDialog({
          data: {
            relates_to: statementSearchItem || null,
            content: template?.channels?.[0]?.plain_text_body,
            subject: template?.channels?.[0]?.email_subject,
            attachments: [],
            template: template,
            recipients: owners || undefined
          }
        });
      }
    };
  }, []);
}
