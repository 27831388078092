import React, { useCallback } from 'react';
import { DialogProps } from '@rexlabs/dialog';
import { useModelActions } from '@rexlabs/model-generator';

import { Tenancy } from 'data/models/entities/tenancies';
import { propertyTenancyModel } from 'src/modules/property-tenancies/models/property-tenancy-model';
import { RecordSubmitHandler } from 'view/components/record-screen/utils';
import { filterNullValues } from 'utils/filter-null-values';
import { RecordDialog } from 'view/components/record-screen/dialog/dialog';
import { PropertyTenancy } from 'src/modules/property-tenancies/types/property-tenancy-types';
import { useRecordCreatedToast } from 'src/modules/common/toasts/hooks/use-record-created-toast';
import { Property } from 'src/modules/properties/types/property-types';
import { propertiesModel } from 'data/models/entities/properties';
import { push } from '@rexlabs/whereabouts';
import ROUTES from 'src/routes/app';
import { Contact } from 'src/modules/contacts/types/contact-types';
import { useSettings } from 'src/modules/settings/hooks/useSettings';
import { useTranslation } from 'react-i18next';
import { useCreatePropertyTenancyDialogContent } from '../data/use-create-property-tenancy-dialog-content';

export interface CreatePropertyTenancyDialogProps extends DialogProps {
  property?: Property;
  tenancy?: Tenancy;
  contact?: Contact;
  isFixture?: boolean;
  onCreate?: (propertyTenancy: PropertyTenancy) => void;
}

export function CreatePropertyTenancyDialog({
  property,
  tenancy,
  contact,
  onClose,
  onCreate
}: CreatePropertyTenancyDialogProps) {
  const { t } = useTranslation();
  const content = useCreatePropertyTenancyDialogContent();
  const settingsData = useSettings();
  const routineInspectionEnabled =
    settingsData['inspection-task-interval']?.routine_inspection?.is_active ??
    false;

  const { createItem: createPropertyTenancy } = useModelActions(
    propertyTenancyModel
  );

  const { refreshItem: refreshProperty } = useModelActions(propertiesModel);

  const addToast = useRecordCreatedToast(propertyTenancyModel, {
    actions: []
  });

  const initialValues = {
    property,
    tenancy,
    contact,
    // Need to use the "selected" keyname because that's how the block is built
    selected_property_tenancy: {
      lease_type: { id: 'fixed_term', label: 'Fixed term' }
    }
  };

  const handleSubmit: RecordSubmitHandler = useCallback(
    async ({ values }) => {
      // The details block has fields under the "selected_property_tenancy" key, so we need to merge those into the base values
      const {
        selected_property_tenancy: propertyTenancyDetails,
        ...baseValues
      } = values;

      // 'custom' isn't a valid term length option on the BE so if
      // it is selected we want to set it to null so the term_length
      // property is removed from the data to submit below
      if (propertyTenancyDetails?.term_length?.id === 'custom') {
        propertyTenancyDetails.term_length = null;
      }

      const dataToSubmit = {
        ...baseValues,
        ...propertyTenancyDetails,
        routine_inspection_enabled: routineInspectionEnabled
      };

      const { data: propertyTenancy } = await createPropertyTenancy({
        data: filterNullValues(dataToSubmit),
        args: {
          include:
            'tenancy.tenants,rent_schedule.line_items.payable_to_chart_of_accounts_account'
        }
      });

      onCreate?.(propertyTenancy);

      await addToast(propertyTenancy);

      // set the newly created propertyTenancy in the url params
      push(ROUTES.PROPERTY, {
        query: { tab: 'lease-agreements', leaseAgreement: propertyTenancy.id },
        params: { propertyId: values.property.id }
      });

      // HACK: There is a mysterious problem with the flattened properties queries where data is being clobbered. Reloading the page mitigates the problem.
      window.location.reload();

      return propertyTenancy;
    },
    [addToast, createPropertyTenancy, refreshProperty, property?.id]
  );

  return (
    <RecordDialog
      title={t('lease-agreements.create.singular.label') as string}
      content={content}
      data={initialValues}
      handleSubmit={handleSubmit}
      onClose={onClose}
    />
  );
}
