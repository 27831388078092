import React from 'react';
import Box from '@rexlabs/box';
import { StyleSheet, text, useStyles } from '@rexlabs/styling';
import { Truncate } from '@rexlabs/text';
import { formatCurrency } from 'utils/formatters';

interface SummaryProps {
  summary: string;
  amount: number;
  amountDisabled?: boolean;
}

const defaultStyles = StyleSheet({
  value: {
    ...text.styles({ fallback: 'normal.default' })
  },

  amountDisabled: {
    color: ({ token }) => token('color.textStyle.body.disabled')
  }
});

export function Summary({ summary, amount, amountDisabled }: SummaryProps) {
  const s = useStyles(defaultStyles);

  return (
    <Box flexDirection='row' justifyContent='space-between' {...s('value')}>
      <Truncate>{summary}</Truncate>
      <div {...s({ amountDisabled })}>{formatCurrency(amount)}</div>
    </Box>
  );
}
