import { useConfirmationDialog, useErrorDialog } from '@rexlabs/dialog';
import { BulkAction } from '@rexlabs/table/lib/types/bulk-actions/simple';
import {
  postRequestPipelineRequests,
  RequestPipelineRequestWithId
} from 'utils/api/post-request-pipeline';
import { useToast } from 'view/components/@luna/notifications/toast';
import { pluralize } from 'utils/formatters';
import { useModelActions } from '@rexlabs/model-generator';
import { Bill } from '../../types/Bill';
import { financialsUploadedBillsModel } from '../../models/uploaded-bills';

export function useGetDeleteBillAction() {
  const { open } = useConfirmationDialog();
  const errorDialog = useErrorDialog();
  const { addToast } = useToast();

  const { refreshLists } = useModelActions(financialsUploadedBillsModel);

  return (billsToBeProcessed: Bill[]): BulkAction => {
    return {
      label: 'Delete',
      handleAction: async () => {
        open({
          destructive: true,
          title: 'Delete bills',
          message: `You are deleting ${billsToBeProcessed.length} bill(s) from the bill processing queue. Are you sure you want to delete the bill(s)?`,
          confirmText: 'Delete bills',
          async onConfirm() {
            try {
              const requests: Array<RequestPipelineRequestWithId> = billsToBeProcessed.map(
                (bill) => {
                  return {
                    id: `bill-${bill.id}`,
                    path: `/api/v1/financials/uploaded-bills/${bill.id}`,
                    method: 'DELETE'
                  };
                }
              );

              await postRequestPipelineRequests(requests);

              addToast({
                type: 'error',
                description: `You have successfully deleted ${pluralize(
                  'bills',
                  billsToBeProcessed.length,
                  true
                )}`
              });

              await refreshLists();

              return true;
            } catch (error) {
              errorDialog.open(error);
            }
          }
        });
      }
    };
  };
}
