import React from 'react';

import { api } from 'utils/api/api-client';
import { Dropdown, DropdownProps } from '@rexlabs/select-old';
import { normaliseProperty } from 'utils/normaliser/property';
import { Quri } from '@rexlabs/quri';
import { useCachedItems } from 'view/hooks/api/cached-items';
import { Property } from 'src/modules/properties/types/property-types';

export interface PropertySelectProps
  extends Omit<DropdownProps, 'items' | 'filter'> {
  filter: Quri;
  cacheKey: string;
}

export const FilteredPropertySelect = ({
  filter,
  cacheKey = 'properties',
  ...props
}: PropertySelectProps) => {
  const { loadItems } = useCachedItems(cacheKey, async () => {
    const properties = await api.get('/properties', {
      q: filter,
      per_page: 30
    });

    return properties.data as Property[];
  });

  return (
    <Dropdown items={loadItems} normaliser={normaliseProperty} {...props} />
  );
};
