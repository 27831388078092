import React from 'react';
import { DialogButtonGroup } from 'view/components/dialogs/buttons/dialog-button-group';
import { ButtonGroupProps } from 'view/components/record-screen/dialog/types';
import { OutlineButton } from '@rexlabs/button';
import Info from 'view/components/icons/info';
import { useConfirmationDialog } from '@rexlabs/dialog';

interface PropertyAreaTypeDialogButtonGroup extends ButtonGroupProps {
  handleReset?: ({
    formReset
  }: {
    formReset: ButtonGroupProps['resetAll'];
  }) => void;
}

export function PropertyAreaTypeDialogButtonGroup({
  forms,
  handleSubmit,
  resetAll,
  onClose,
  isSubmitting,
  submitLabel,
  SubmitButton,
  handleReset
}: PropertyAreaTypeDialogButtonGroup) {
  const { open: openConfirmationDialog } = useConfirmationDialog();
  const handleResetClick = () => {
    openConfirmationDialog({
      title: 'Reset area type',
      TitleIcon: Info,
      message: (
        <div data-testid={'reset-confirmation-dialog'}>
          <p>
            Please note that by proceeding, all Entry/Exit items will be reset
            to the system default. This will only affect any new areas added to
            the property using this area type.
          </p>
          <p>Do you want to continue?</p>
        </div>
      ),
      onConfirm: () => handleReset?.({ formReset: resetAll }),
      confirmText: 'Yes, reset area type',
      cancelText: 'No, keep current configuration',
      destructive: false
    });
  };

  return (
    <DialogButtonGroup
      forms={forms}
      handleSubmit={handleSubmit}
      resetAll={resetAll}
      onClose={onClose}
      isSubmitting={isSubmitting}
      submitLabel={submitLabel}
      SubmitButton={SubmitButton}
      left={
        handleReset ? (
          <OutlineButton onClick={handleResetClick}>Reset items</OutlineButton>
        ) : null
      }
    />
  );
}
