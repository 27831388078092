import { useErrorDialog } from '@rexlabs/dialog';
import { useModelActions } from '@rexlabs/model-generator';

import { useSessionState } from 'src/modules/common/hooks/session-state';

import {
  postRequestPipelineRequests,
  RequestPipelineRequest
} from 'utils/api/post-request-pipeline';

import { tasksModel } from '../../common/models/tasks-model';
import { InspectionTask } from '../types/InspectionTask';
import { getTaskIncludes } from '../../common/utils/get-task-includes';

export function useGetUpdateManagedBy() {
  const { open: openErrorDialog } = useErrorDialog();
  const { refreshLists } = useModelActions(tasksModel);
  const { contact } = useSessionState();

  return async (inspectionsNotManagedByUser: InspectionTask[]) => {
    try {
      const requests: RequestPipelineRequest[] = (
        inspectionsNotManagedByUser || []
      ).map((inspection) => ({
        method: 'PATCH',
        path: `/api/v1/tasks/${inspection.id}`,
        json: {
          managed_by: contact,
          include: getTaskIncludes('task_inspection')
        }
      }));

      const response = await postRequestPipelineRequests<
        typeof requests,
        InspectionTask[]
      >(requests);
      await refreshLists();

      return response;
    } catch (error) {
      openErrorDialog(error);
    }
  };
}
