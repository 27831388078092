import { ContentConfig } from 'view/components/record-screen/types';
import { chargeEntryDetailsBlock } from '../blocks/charge-entry-details';

export const chargeEntryDetailsContent: ContentConfig = [
  {
    label: 'General',
    items: [
      {
        id: 'overview',
        label: 'Overview',
        blocks: [chargeEntryDetailsBlock]
      }
    ]
  }
];
