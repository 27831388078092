import { useDialog } from '@rexlabs/dialog';
import { ActionDeclaration } from 'src/modules/common/actions/types/action-declaration-types';
import { MessageWithReasonDialog } from '../../../common/dialogs/message-with-reason-dialog';
import { QuoteTask } from '../../types/QuoteTask';
import { useGetMessageWithReasonSubmitHandler } from '../../../common/hooks/use-get-message-with-reason-submit-handler';

export function useGetRejectQuoteAction() {
  const { open: openMessageWithReasonDialog } = useDialog(
    MessageWithReasonDialog
  );

  const getUpdateQuoteTaskSubmitHandler = useGetMessageWithReasonSubmitHandler();

  return (task: QuoteTask): ActionDeclaration => {
    const handleSubmit = getUpdateQuoteTaskSubmitHandler(task, 'rejected');

    return {
      id: 'reject-quote',
      group: 'status',
      label: 'Reject quote',
      handleAction: async () => {
        openMessageWithReasonDialog({
          title: 'Reject quote',
          submitButtonType: 'destructive',
          handleSubmit,
          messageContextType: 'task_quote'
        });

        return;
      }
    };
  };
}
