import { getUploadedFileMeta } from 'utils/files';
import { EntryExitInspectionFormData } from '../types/EntryExitInspectionFormData';

export async function mapSupportingDocumentsFormDataToRequest(
  formData: Partial<EntryExitInspectionFormData>
) {
  const supportingDocuments = formData?.supporting_documents;

  return {
    details: {
      supporting_documents: supportingDocuments?.length
        ? await getUploadedFileMeta(supportingDocuments)
        : []
    }
  };
}
