import React from 'react';

import { RecordObject } from 'data/models/types';
import { getRecordLabel } from 'utils/records/get-record-label';
import { RecordLink, RecordLinkProps } from './record-link';

interface FullRecordLinkProps
  extends Omit<RecordLinkProps, 'id' | 'type' | 'children'> {
  recordObject: RecordObject;
}

export function FullRecordLink({
  recordObject,
  ...recordLinkProps
}: FullRecordLinkProps) {
  const { id } = recordObject.object;
  return (
    <RecordLink {...recordLinkProps} id={id} type={recordObject.type}>
      {getRecordLabel(recordObject)}
    </RecordLink>
  );
}
