import { parseUploadedFileMeta } from 'utils/files';

import { getSearchResultItemFromObjectAndModel } from 'src/modules/common/utils/search-result-items/get-search-result-items-from-object-and-model';

import { MaintenanceTask } from '../../maintenance/types/MaintenanceTask';
import { tasksModel } from '../../common/models/tasks-model';
import { CreateQuoteFormData } from '../types/CreateQuoteFormData';

export function mapMaintenanceTaskToCreateQuoteForm(
  task: MaintenanceTask
): Partial<CreateQuoteFormData> {
  return {
    summary: `Quote for ${task.summary}`,
    description: task.description,
    parent_task: getSearchResultItemFromObjectAndModel(task, tasksModel),
    details: {
      access_provided_by: task.details?.access_provided_by,
      images: task?.details?.images
        ? parseUploadedFileMeta(task.details.images)
        : []
    },
    property: task?.property || null,
    managed_by: task?.managed_by ?? undefined,
    priority: task?.priority
  };
}
