import React from 'react';
import Box from '@rexlabs/box';
import { Heading } from '@rexlabs/text';
import { useToken } from '@rexlabs/styling';
import { ColoredCard } from 'view/components/cards/colored-card';
import { RemoveIconButton } from 'src/modules/common/components/remove-icon-button';

// NOTE: there are several other LineItem components, but they do not fit the style
// used in this dialog
export function ReceiptFundsLineItemContainer({
  onRemove,
  heading,
  children
}: {
  onRemove: () => void;
  heading: string;
  children: React.ReactNode;
}) {
  const token = useToken();
  return (
    <ColoredCard
      intent={'neutral'}
      p={token('spacing.xl')}
      pt={token('spacing.m')}
      mb={token('spacing.xl')}
      sy='1rem'
      width={'100%'}
    >
      <Box
        flexDirection='row'
        alignItems='center'
        justifyContent='space-between'
      >
        <Heading level={4}>{heading}</Heading>
        <Box ml='1em' flexDirection='row' alignItems='center'>
          <RemoveIconButton onClick={onRemove} />
        </Box>
      </Box>
      {children}
    </ColoredCard>
  );
}
