import React from 'react';

import { GhostButton, PrimaryButton } from '@rexlabs/button';
import { useDialog } from '@rexlabs/dialog';

import { ButtonGroupProps } from 'view/components/record-screen/dialog/types';
import { StandardDialogFooter } from 'view/components/dialogs/components/standard-dialog-footer';

import { EditKeySetDialog } from '../dialogs/edit-key-set-dialog';
import { useEditKeySetSubmitHandler } from '../hooks/use-edit-key-set-submit-handler';

export function ManageKeySetDialogButtonGroup({
  onClose,
  forms
}: ButtonGroupProps) {
  const { open: openEditKeySetDialog } = useDialog(EditKeySetDialog);
  const { onHandleSubmit } = useEditKeySetSubmitHandler();

  return (
    <StandardDialogFooter>
      <GhostButton
        role='button'
        onClick={() =>
          openEditKeySetDialog({
            keyId: forms!['check-out-key-set-block'].values.id,
            handleSubmit: onHandleSubmit
          })
        }
      >
        Edit
      </GhostButton>
      <PrimaryButton role='button' onClick={onClose}>
        Close
      </PrimaryButton>
    </StandardDialogFooter>
  );
}
