import { useConfirmationDialog, useDialog } from '@rexlabs/dialog';
import { FieldArrayPassthroughProps } from '@rexlabs/form';
import { DeleteChecklistItemDialog } from '../../../dialogs/delete-smart-checklist-item';
import {
  ChecklistTemplateItem,
  ChecklistTemplateItemGroup
} from '../../../types/ChecklistTemplate';
import { ChecklistTemplatesForm } from '../../../types/ChecklistTemplatesForm';

export function useDeleteChecklistItemAction() {
  const { open: openDeleteChecklistItemDialog } = useDialog(
    DeleteChecklistItemDialog
  );
  const confirmationDialog = useConfirmationDialog();

  const handleSubmit = (
    fieldArrayProps: FieldArrayPassthroughProps,
    index: number,
    checklistTemplateItem: ChecklistTemplateItem,
    setFieldValue: (field: string, value: any) => void,
    values: Partial<ChecklistTemplatesForm>
  ) => {
    return () => {
      const { remove } = fieldArrayProps;

      remove(index);

      const checklistTemplateItemId = checklistTemplateItem?.id;

      if (checklistTemplateItemId) {
        const ids = Array.isArray(values.delete_ids)
          ? values.delete_ids.concat(checklistTemplateItemId)
          : [checklistTemplateItemId];

        setFieldValue?.('delete_ids', ids);
      }

      return true;
    };
  };

  return ({
    index,
    fieldArrayProps,
    checklistTemplateItem,
    values,
    setFieldValue
  }: {
    fieldArrayProps: FieldArrayPassthroughProps;
    index: number;
    checklistTemplateItem: ChecklistTemplateItem;
    setFieldValue: (field: string, value: any) => void;
    values: Partial<ChecklistTemplatesForm>;
  }) => {
    return {
      label: 'Delete subtask',
      id: 'delete-checklist-item',
      intent: 'danger',
      handleAction: () => {
        if (
          checklistTemplateItem?.has_outcome &&
          hasDescendants(
            checklistTemplateItem,
            values.checklist_template_items ?? [],
            values.checklist_template_item_groups ?? []
          )
        ) {
          return confirmationDialog.open({
            title: 'Cannot delete subtask',
            message:
              'This subtask has dependent subtasks. Please delete them first.',
            confirmText: 'OK'
          });
        }

        openDeleteChecklistItemDialog({
          onDelete: handleSubmit(
            fieldArrayProps,
            index,
            checklistTemplateItem,
            setFieldValue,
            values
          )
        });
      }
    };
  };
}

function hasDescendants(
  checklistTemplateItem: ChecklistTemplateItem,
  checklistItems: ChecklistTemplateItem[],
  groups: ChecklistTemplateItemGroup[]
) {
  const outcomeIds =
    checklistTemplateItem.outcome_data?.outcome_options?.map(
      (outcome) => outcome.id
    ) ?? [];

  const groupsForThisItem = groups.filter(
    (group) =>
      group.triggered_by_checklist_template_item?.id ===
        checklistTemplateItem.id &&
      outcomeIds.includes(group.triggered_by_value)
  );

  const itemsForGroups = checklistItems.filter(
    (item) =>
      item.checklist_template_item_group?.id &&
      groupsForThisItem
        .map((group) => group.id)
        .includes(item.checklist_template_item_group.id)
  );

  return itemsForGroups.length > 0;
}
