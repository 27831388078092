import { Generator, GeneratorConfig } from 'data/models/generator';
import { BaseModelGeneratorModel } from '@rexlabs/model-generator';
import { ValueListValue } from 'data/models/types';
import { SecurityDeposit } from './security-deposits';
import { SecurityDepositType } from './security-deposit-types';
import { financialsInvoicesModel } from './invoices';

export type SecurityDepositPayment = BaseModelGeneratorModel & {
  type: ValueListValue<SecurityDepositType>;
  description: string;
  amount: number;
  effective_amount: number;
  security_deposit?: SecurityDeposit;
};

const config: GeneratorConfig = {
  entities: {
    related: {
      invoice: {
        include: 'invoice',
        model: financialsInvoicesModel
      }
    }
  }
};

export const financialsSecurityDepositPaymentsModel = new Generator<SecurityDepositPayment>(
  'financials/security-deposit-payments',
  config
).createEntityModel();
