import React from 'react';

import Box from '@rexlabs/box';
import { Field } from '@rexlabs/form';

import EmptyTable from 'assets/illustrations/empty-table.svg';

import { useDialog } from '@rexlabs/dialog';
import { SupplierAddNewEntryDialog } from 'src/modules/compliance/common/dialogs/supplier-add-new-entry-dialog';
import { Contact } from 'src/modules/contacts/types/contact-types';
import { Column, Grid } from 'view/components/@luna/form/grid';
import { Message } from 'view/components/@luna/message';
import { SupplierComplianceCategorySelect } from 'view/components/inputs/selects/v4-selects/supplier-compliance-category';
import { EmptyCard } from 'view/components/record-screen/cards/empty-card';
import { BlockConfig } from 'view/components/record-screen/types';
import { Value } from 'view/components/values';

type AddSupplierComplianceCategoryBlock = ({
  hasComplianceEntries
}: {
  hasComplianceEntries: boolean;
}) => BlockConfig<Contact | undefined>;

export const addSupplierComplianceCategoryBlock: AddSupplierComplianceCategoryBlock = ({
  hasComplianceEntries
}) => ({
  id: 'supplier-compliance-category-block',
  title: 'Supplier compliance',
  validate: {
    definitions: {
      compliance_categories: { rules: 'required', name: 'categories' }
    }
  },
  showEmpty: (data) => !data?.compliance_categories?.length,
  Empty: ({ onEditClick, data }) => {
    const supplierAddNewEntryDialog = useDialog(SupplierAddNewEntryDialog);

    const emptyCopy = hasComplianceEntries
      ? 'Add the compliance categories that apply to this supplier.'
      : 'Add the compliance categories that apply to this supplier or add requirements individually.';

    return (
      <EmptyCard>
        <Message
          title={
            hasComplianceEntries ? 'Services offered' : 'Insurance & compliance'
          }
          Illustration={EmptyTable}
          actions={[
            ...(!hasComplianceEntries
              ? [
                  {
                    label: 'Add requirement',
                    intent: 'secondary' as const,
                    handleAction: () =>
                      // TODO: add correct add license dialog
                      supplierAddNewEntryDialog.open({ contactId: data?.id })
                  }
                ]
              : []),
            {
              label: 'Add service offered',
              intent: 'primary',
              handleAction: onEditClick
            }
          ]}
        >
          <div>{emptyCopy}</div>
        </Message>
      </EmptyCard>
    );
  },
  Edit: () => {
    return (
      <Grid columns={1}>
        <Box flexDirection='row' alignItems='center'>
          <Field
            name='compliance_categories'
            id='categories'
            label='Services offered'
            Input={SupplierComplianceCategorySelect}
            inputProps={{ multi: true }}
          />
        </Box>
      </Grid>
    );
  },
  View: ({ data }) => {
    return (
      <Grid columns={2}>
        <Column width={2}>
          <Value
            label='Services offered'
            value={data?.compliance_categories
              ?.map((category) => category.label)
              .join(', ')}
          />
        </Column>
      </Grid>
    );
  }
});
