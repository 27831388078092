import React from 'react';
import { Value } from 'view/components/values';
import { WorkOrderTask } from '../types/WorkOrderTask';
import { OwnerBanner } from './owner-banner';

export function WorkDoneByField({ data }: { data?: WorkOrderTask }) {
  return data?.details?.work_done_by_type.id === 'supplier' ? (
    <Value label='Supplier' value={data?.details?.work_done_by?.display_name} />
  ) : (
    <OwnerBanner owner={data?.details?.work_done_by} />
  );
}
