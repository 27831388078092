import {
  financialsReconciliationsModel,
  Reconciliation,
  ReconciliationStatus
} from 'data/models/entities/financials/reconciliations';
import { toLower } from 'lodash';
import { useReportDownloadAction } from 'src/modules/common/actions/reporting/use-report-download-action';
import { useAsyncReportDownloadAction } from 'src/modules/common/actions/reporting/use-async-report-download-action';
import { useStatusChangeAction } from 'src/modules/common/actions/status/use-status-change-action';
import { formatDate } from 'utils/dates/format';
import {
  ActionDeclaration,
  SingleActionDeclaration
} from 'src/modules/common/actions/types/action-declaration-types';
import { useGetPrimaryRecordActionGroup } from 'view/hooks/actions/use-get-primary-record-action-group';
import { track } from '@rexlabs/analytics';
import { EVENTS } from 'utils/analytics';
import moment from 'moment-timezone';
import { useGetDeleteAction } from '../utils/reconciliation-actions/use-get-delete-action';
import { useGetEditReconciliationAction } from '../utils/reconciliation-actions/use-get-edit-action';
import { useGetFinishLaterAction } from '../utils/reconciliation-actions/use-get-finish-later-action';

type viewType = 'details' | 'list';

export function useReconciliationActions(view: viewType) {
  const getEditReconciliationAction = useGetEditReconciliationAction();
  const getFinishLaterAction = useGetFinishLaterAction();
  const getDeleteAction = useGetDeleteAction();
  const getDownloadReportAction = useReportDownloadAction();
  const getAsyncDownloadReportAction = useAsyncReportDownloadAction();
  const statusChangeAction = useStatusChangeAction(
    financialsReconciliationsModel
  );
  const getPrimaryRecordActionGroup = useGetPrimaryRecordActionGroup();

  return (reconciliation: Reconciliation): ActionDeclaration[] => {
    if (!reconciliation) return [];
    const reconciliationType = toLower(reconciliation.type.label);
    const reconciliationDate = formatDate(
      reconciliation.statement_date,
      reconciliation.type.id === 'end_of_month' ? 'MMMM YYYY' : 'D MMMM YYYY'
    );
    const canBeCompleted =
      reconciliation.reconciliation_summary.balance_variance_amount === 0;
    const isMonthlyReconciliation = reconciliation.type.id === 'end_of_month';
    const actions: Array<ActionDeclaration> = [];
    const listView = view === 'list';
    const detailsView = view === 'details';
    const editAction = getEditReconciliationAction(reconciliation);
    const deleteAction = getDeleteAction(reconciliation);

    const completeAction = statusChangeAction<ReconciliationStatus>({
      record: reconciliation,
      status: 'reconciled',
      dialogOptions: {
        type: 'confirmation',
        message: 'Are you sure you want to complete this reconciliation?',
        confirmText: 'Complete reconciliation',
        label: 'Complete reconciliation',
        title: 'Complete reconciliation',
        afterAction: () => {
          track({
            eventName: EVENTS.RECONCILIATIONS.COMPLETED,
            data: {
              reconciliationType: reconciliation.type.id,
              // reconciliation.reconciled_at is null and I don't want to re-fetch just for this event
              reconciliationPeriodCompletedDate: moment().format('YYYY-MM-DD'),
              reconciliationWithdrawalUnreconciledCount:
                reconciliation.reconciliation_summary
                  .bank_withdrawals_exc_cheques_unreconciled_count,
              reconciliationDepositsUnreconciledCount:
                reconciliation.reconciliation_summary
                  .bank_deposits_unreconciled_count,
              reconciliationUnpresentedChequesUnreconciledCount:
                reconciliation.reconciliation_summary
                  .trust_cheque_withdrawals_unreconciled_count,
              reconciliationAdjustmentsUnreconciledCount:
                reconciliation.reconciliation_summary
                  .bank_adjustments_unreconciled_count
            }
          });
        }
      }
    });

    listView &&
      actions.push(
        ...getPrimaryRecordActionGroup('reconciliation', reconciliation.id)
      );

    const downloadAction = (label = 'Download report') => {
      return getDownloadReportAction(
        label,
        { type: 'reconciliation', object: reconciliation },
        'pdf',
        'download_link',
        () =>
          track({
            eventName: EVENTS.RECONCILIATIONS.REPORT_DOWNLOADED,
            data: {
              reconciliationType: reconciliation.type.id,
              reconciliationStatus: reconciliation.status.id
            }
          })
      );
    };

    const downloadCsvAction = (label = 'Download CSV') =>
      getAsyncDownloadReportAction(
        label,
        `/financials/reconciliations/${reconciliation.id}/export`,
        { type: 'reconciliation', object: reconciliation },
        'CSV'
      );

    const finishLaterAction = getFinishLaterAction({
      type: reconciliation.type.id
    });

    const voidAction = statusChangeAction<ReconciliationStatus>({
      record: reconciliation,
      status: 'voided',
      dialogOptions: {
        label: 'Void reconciliation',
        title: `Voiding ${reconciliationType} reconciliation`,
        submitLabel: `Void ${reconciliationDate} reconciliation`,
        description: `Please confirm you want to void the ${reconciliationType} reconciliation for ${reconciliationDate}.`,
        reasonLabel: 'Reason for voiding reconciliation',
        onDialogOpen: () => {
          track({
            eventName: EVENTS.RECONCILIATIONS.VOID_MODAL_OPENED,
            data: { reconciliationType: reconciliation.type.id }
          });
        },
        afterAction: () => {
          track({
            eventName: EVENTS.RECONCILIATIONS.VOIDED,
            data: {
              reconciliationType: reconciliation.type.id,
              reconciliationPeriodVoided: reconciliation.statement_date,
              // reconciliation.voided_at is null and I don't want to re-fetch just for this event
              reconciliationPeriodVoidedDate: moment().format('YYYY-MM-DD')
            }
          });
        }
      }
    });

    const verifyAction = statusChangeAction<ReconciliationStatus>({
      record: reconciliation,
      status: 'verified',
      dialogOptions: {
        label: 'Verify reconciliation',
        type: 'confirmation',
        title: 'Verify reconciliation?',
        message:
          'Please confirm you wish to verify this monthly reconciliation. Once verified, you will not be able to change which items are reconciled',
        confirmText: 'Verify reconciliation',
        afterAction: () => {
          track({
            eventName: EVENTS.RECONCILIATIONS.VERIFIED,
            data: {
              reconciliationType: reconciliation.type.id
            }
          });
        }
      }
    });

    switch (reconciliation.status.id) {
      case 'active': {
        const activeActions: SingleActionDeclaration[] = [
          downloadAction('Download draft report'),
          downloadCsvAction(),
          editAction,
          deleteAction
        ];

        detailsView &&
          actions.push({
            label: 'Actions',
            actions: activeActions
          });

        listView && actions.push(...activeActions);

        actions.push({
          ...(canBeCompleted ? completeAction : finishLaterAction),
          intent: 'primary'
        });
        break;
      }
      case 'reconciled': {
        const reconciledActions: SingleActionDeclaration[] = [
          ...[downloadAction(), downloadCsvAction()],
          ...(isMonthlyReconciliation ? [voidAction, verifyAction] : [])
        ];

        detailsView &&
          actions.push({
            label: 'Actions',
            actions: reconciledActions
          });

        listView && actions.push(...reconciledActions);
        break;
      }
      case 'verified': {
        if (isMonthlyReconciliation) {
          const verifiedActions = [
            downloadAction(),
            downloadCsvAction(),
            voidAction
          ];

          detailsView &&
            actions.push({
              label: 'Actions',
              actions: verifiedActions
            });

          listView && actions.push(...verifiedActions);
        }
        break;
      }
      case 'voided': {
        if (isMonthlyReconciliation) {
          const voidedActions = [downloadAction(), downloadCsvAction()];

          detailsView &&
            actions.push({
              label: 'Actions',
              actions: voidedActions
            });

          listView && actions.push(...voidedActions);
        }
        break;
      }
    }

    return actions;
  };
}
