import React from 'react';
import { BlockConfig } from 'view/components/record-screen/types';
import { FormLayout } from 'view/components/layouts/form/form-layout';
import { Field } from '@rexlabs/form';
import { DateInput } from 'view/components/@luna/inputs/date-input/date-input';
import { TextArea } from '@rexlabs/text-input';

const validate = {
  definitions: {
    date_of: { rules: 'required' },
    reason: { rules: 'required' }
  }
};

export const reverseTrustJournalEntryBlock: BlockConfig = {
  id: 'reverseTrustJournalEntry',
  validate,
  Edit: () => (
    <FormLayout minColumnWidth='400px'>
      <Field name='date_of' label='Date' Input={DateInput} />
      <Field name='reason' label='Reason' Input={TextArea} />
    </FormLayout>
  )
};
