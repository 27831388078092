import React from 'react';
import { useDialog } from '@rexlabs/dialog';

import EmptyTable from 'src/assets/illustrations/empty-table.svg';

import { EmptyCard } from 'view/components/record-screen/cards/empty-card';
import { Message } from 'view/components/@luna/message';

import { CreatePropertyComplianceRequirementDialog } from '../dialogs/create-property-compliance-requirement-dialog';

export function PropertyComplianceRequirementTableEmptyState() {
  const { open: openCreatePropertyComplianceRequirementDialog } = useDialog(
    CreatePropertyComplianceRequirementDialog
  );
  return (
    <EmptyCard hasGreyBackground={false}>
      <Message
        title='Compliance requirements'
        Illustration={EmptyTable}
        actions={[
          {
            label: 'Create compliance requirement',
            intent: 'primary',
            handleAction: openCreatePropertyComplianceRequirementDialog
          }
        ]}
      >
        Add a requirement like a smoke alarm or pool certificate. You&apos;ll
        then be able to add these to a category.
      </Message>
    </EmptyCard>
  );
}
