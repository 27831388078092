import { margin, StyleSheet, useStyles } from '@rexlabs/styling';
import * as React from 'react';
import { Author } from 'src/modules/authors/types/author-types';
import { formatDateTime } from 'utils/dates/format';

const defaultStyles = StyleSheet({
  total: {
    fontSize: '1.2rem',
    color: ({ token }) => token('color.textStyle.body.subtext')
  },
  secondLine: {
    ...margin.styles({
      top: 'xxs'
    })
  }
});

export type NoteAuthorCellValue = {
  author?: Author;
  timestamp: Date | null;
};

export const NoteAuthorCell = ({ value }) => {
  const s = useStyles(defaultStyles);
  const { author, timestamp } = value as NoteAuthorCellValue;

  if (!timestamp) {
    return <div>-</div>;
  }

  return (
    <div>
      <div>{author?.name ?? '-'}</div>
      <div {...s('secondLine', 'total')}>{formatDateTime(timestamp)}</div>
    </div>
  );
};
