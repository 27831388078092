import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import getPendingTrustJournalEntryStatusColor from 'src/modules/trust-journal-entries/utils/pending-trust-journal-entry-status-color';
import { titleBlockStatusTag } from 'src/modules/common/components/status-tag';
import { ownershipStatusMap } from 'src/modules/ownerships/maps/ownership-status-map';

import { RecordObject, RecordType } from 'data/models/types';
import { Ownership } from 'data/models/entities/ownerships';

import { FLAGS } from 'utils/feature-flags';
import { formatCurrency } from 'utils/formatters';
import { getRecordTitle } from 'utils/records/get-record-title';

import {
  TitleBlock,
  TitleBlockProps
} from 'view/components/cards/title/title-block';
import { OwnershipsIcon } from 'view/components/icons/ownerships';
import { useFeatureFlags } from 'view/components/@luna/feature-flags';

import { useOwnershipActions } from '../actions/use-ownership-actions';

const type: RecordType = 'ownership';

type OwnershipTitleBlockProps = {
  ownership: Ownership;
};

export function OwnershipTitleBlock({ ownership }: OwnershipTitleBlockProps) {
  const { t } = useTranslation();

  const getOwnershipActions = useOwnershipActions();

  const { hasFeature } = useFeatureFlags();

  const {
    record_reference,
    property_ownerships,
    financial_summary,
    trust_summary
  } = ownership;

  const tags = useMemo(() => {
    const tags: TitleBlockProps['tags'] = [];

    const hasActive = property_ownerships?.data.some(
      (propertyOwnership) => propertyOwnership.status.id === 'active'
    );

    const hasIncoming = property_ownerships?.data.some(
      (propertyOwnership) => propertyOwnership.status.id === 'incoming'
    );

    if (hasActive) {
      tags.push({
        type: 'good',
        label: t(
          'property-ownerships.management-agreement.active.label.singular'
        )
      });
    } else if (hasIncoming) {
      tags.push({
        type: 'average',
        label: t(
          'property-ownerships.management-agreement.incoming.label.singular'
        )
      });
    }

    tags.push(titleBlockStatusTag(ownership.status, ownershipStatusMap));

    return tags;
  }, [property_ownerships]);

  const title = getRecordTitle({ type, object: ownership } as RecordObject);

  const actions = useMemo(() => getOwnershipActions(ownership), [ownership]);

  const {
    bills_and_fees_payable_total_amount_owing,
    bills_and_fees_payable_count,
    invoices_receivable_total_amount_owing,
    invoices_receivable_count
  } = financial_summary || {};

  const { available_balance_amount } = trust_summary || {};
  const withheldFundsAmount =
    ownership.disbursement_withheld_funds
      ?.total_amount_including_related_withheld_funds || 0;

  const stats = useMemo<TitleBlockProps['stats']>(
    () => [
      {
        label: 'Bills & fees payable',
        value: formatCurrency(bills_and_fees_payable_total_amount_owing),
        intent: getPendingTrustJournalEntryStatusColor(
          bills_and_fees_payable_count
        )
      },
      {
        label: 'Invoices receivable',
        value: formatCurrency(invoices_receivable_total_amount_owing),
        intent: getPendingTrustJournalEntryStatusColor(
          invoices_receivable_count
        )
      },
      {
        label: 'Net income this period',
        value: formatCurrency(0),
        intent: 'secondary',
        workInProgress: true
      },
      {
        label: t('financials.trust.available-trust-balance.label.singular'),
        value: formatCurrency(available_balance_amount),
        intent: 'secondary',
        ...(withheldFundsAmount > 0 && hasFeature(FLAGS.TASK_WITHHELD_FUNDS)
          ? {
              ToolTipContent: () => (
                <>
                  {t(
                    'withheld-funds.ownership-available-trust-balance-stat-block',
                    {
                      withheldFundsAmount: formatCurrency(withheldFundsAmount)
                    }
                  )}
                </>
              )
            }
          : {})
      },
      {
        label: 'Disburseable funds',
        value: formatCurrency(0),
        intent: 'secondary',
        workInProgress: true
      }
    ],
    [
      hasFeature,
      t,
      bills_and_fees_payable_count,
      bills_and_fees_payable_total_amount_owing,
      invoices_receivable_count,
      invoices_receivable_total_amount_owing,
      available_balance_amount,
      withheldFundsAmount
    ]
  );

  return (
    <TitleBlock
      title={title}
      type={type}
      tags={tags}
      reference={record_reference}
      actions={actions}
      Icon={OwnershipsIcon}
      stats={stats}
    />
  );
}
