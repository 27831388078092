import { TagCell } from '@rexlabs/table';
import { StatusAverageTag } from '@rexlabs/tag';
import dayjs from 'dayjs';
import React from 'react';
import { getTodayDateStringForActiveTz } from 'utils/dates/dates';

export function IncompletePeriodTagCell({
  periodEndDate
}: {
  periodEndDate: string;
}) {
  return periodEndDate &&
    dayjs(periodEndDate).isAfter(dayjs(getTodayDateStringForActiveTz())) ? (
    <TagCell>
      <StatusAverageTag>Incomplete period</StatusAverageTag>
    </TagCell>
  ) : null;
}
