import React from 'react';
import { StyleSheet, useStyles } from '@rexlabs/styling';
import { Card } from 'view/components/@luna/card';

export type ColouredCardIntent =
  | 'neutral'
  | 'secondary'
  | 'success'
  | 'danger'
  | 'warning'
  | 'information';

type ColoredCardProps = React.ComponentProps<typeof Card> & {
  intent: ColouredCardIntent;
};

const defaultStyles = StyleSheet({
  containerNeutral: {
    background: ({ token }) => token('palette.grey.200')
  },

  containerSecondary: {
    // same as palette.indigo.50
    background: ({ token }) => token('color.container.static.secondary.default')
  },

  containerSuccess: {
    // same as palette.green.100
    background: ({ token }) => token('color.container.static.success.default')
  },

  containerDanger: {
    // same as palette.red.100
    background: ({ token }) => token('color.container.static.danger.default')
  },

  containerWarning: {
    // same as palette.yellow.100
    background: ({ token }) => token('color.container.static.warning.default')
  },

  containerInformation: {
    // same as palette.blue.100
    background: ({ token }) =>
      token('color.container.static.information.default')
  }
});

/**
 * This component is a wrapper of the Vivid card component. You can pass in the intent
 * of the card, and it will return a card with the background color set to the default
 * of the intent color.
 * @param intent - The intent of the card which is used to determine the color of it
 * @returns A colored card
 */
export function ColoredCard({
  intent,
  style,
  className,
  ...rest
}: ColoredCardProps) {
  const s = useStyles(defaultStyles);
  return (
    <Card
      {...s.with({
        containerNeutral: intent === 'neutral',
        containerSecondary: intent == 'secondary',
        containerSuccess: intent === 'success',
        containerDanger: intent === 'danger',
        containerWarning: intent === 'warning',
        containerInformation: intent === 'information'
      })({ style, className })}
      {...rest}
    />
  );
}
