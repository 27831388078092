import React, { FC, useCallback } from 'react';
import {
  SelectProps,
  Select,
  useItems,
  UseItemsArgs,
  NormalisedItem
} from '@rexlabs/select';

import { normaliseContact } from 'utils/normaliser/contact';
import { getContacts } from 'utils/api/get-contacts';
import { Contact } from 'src/modules/contacts/types/contact-types';
import { ValueListValue } from 'data/models/types';
import { PortfolioRole } from 'src/modules/portfolios/models/portfolio-members-model';
import { useValueListQuery } from 'src/lib/react-query/hooks/use-value-list-query';

export type ContactOrPortfolioRole = Contact | ValueListValue<PortfolioRole>;

export type ChecklistAssigneeSelectProps<
  TItem = ContactOrPortfolioRole
> = SelectProps<TItem> & Pick<UseItemsArgs<TItem>, 'getSuggestedItems'>;

export const ChecklistAssigneeSelect: FC<ChecklistAssigneeSelectProps> = ({
  getSuggestedItems,
  ...props
}) => {
  const { data: portfolioRoles } = useValueListQuery('portfolio_role');
  const getItems = useCallback(
    async (searchTerm) => {
      const contacts = await getContacts({
        searchTerm
      });

      return [
        ...((portfolioRoles as ValueListValue<PortfolioRole>[]) ?? []),
        ...contacts
      ];
    },
    [portfolioRoles]
  );

  const {
    getSelectProps: useItemsGetSelectProps
  } = useItems<ContactOrPortfolioRole>({
    getItems,
    getSuggestedItems
  });

  const selectProps = useItemsGetSelectProps();

  return (
    <Select
      {...props}
      {...selectProps}
      normaliser={normaliseContactOrPortfolioRole}
    />
  );
};

export const normaliseContactOrPortfolioRole = (
  item: ContactOrPortfolioRole
): NormalisedItem => {
  if (itemIsContact(item)) {
    return normaliseContact(item);
  }

  return {
    id: item.id,
    label: item.label,
    type: 'portfolio_role'
  };
};

export function itemIsContact(item: ContactOrPortfolioRole): item is Contact {
  return '__record_type' in item && item.__record_type === 'contact';
}
