import React from 'react';
import { useDialog } from '@rexlabs/dialog';

import { BlockConfig } from 'view/components/record-screen/types';
import { ActionButtons } from 'view/components/@luna/action-buttons';

import { PropertyComplianceRequirementsSettingsTable } from '../components/property-compliance-requirement-settings-table';
import { CreatePropertyComplianceRequirementDialog } from '../dialogs/create-property-compliance-requirement-dialog';

export const propertyComplianceRequirementsSettingsBlock: BlockConfig = {
  id: 'property-compliance-requirements-settings',
  title: 'Compliance requirements',
  View: PropertyComplianceRequirementsSettingsTable,
  Actions: () => {
    const { open: openCreatePropertyComplianceRequirementDialog } = useDialog(
      CreatePropertyComplianceRequirementDialog
    );

    return (
      <ActionButtons
        actions={[
          {
            label: 'Add new',
            handleAction: openCreatePropertyComplianceRequirementDialog
          }
        ]}
      />
    );
  }
};
