import React from 'react';
import { Field, HiddenField } from '@rexlabs/form';

import { BlockConfig } from 'view/components/record-screen/types';
import { RecordLinkValue } from 'view/components/values/record-link';
import { OwnershipSelect } from 'view/components/inputs/selects/v4-selects/ownership-select';
import { Grid } from 'view/components/@luna/form/grid';
import { DateInput } from 'view/components/@luna/inputs/date-input/date-input';
import { DateValue } from 'view/components/values/date';
import { PropertySelect } from 'view/components/inputs/selects/property-select';
import { useFeatureFlags } from 'view/components/@luna/feature-flags';
import { FLAGS } from 'utils/feature-flags';
import { ServicePackageSelect } from 'view/components/inputs/selects/v4-selects/service-package-select';
import { Value } from 'view/components/values';
import { useTranslation } from 'react-i18next';

export const propertyOwnershipOverviewBlock: BlockConfig = {
  id: 'property-ownership-overview',
  title: 'Overview',
  validate: {
    definitions: {
      property: { rules: 'required' },
      ownership: { rules: 'required' }
    }
  },
  View: ({ data }) => {
    const { hasFeature } = useFeatureFlags();

    return (
      <Grid columns={2}>
        <RecordLinkValue
          label='Property'
          type='property'
          value={data.property.display_name}
          id={data.property.id}
        />
        <RecordLinkValue
          label='Ownership'
          type='ownership'
          value={data.ownership.display_name}
          id={data.ownership.id}
        />
        <DateValue
          label='Agreement start date'
          value={data.agreement_start_date}
        />
        <DateValue label='Agreement end date' value={data.agreement_end_date} />

        {hasFeature(FLAGS.SERVICE_PACKAGES) && (
          <Value label='Service package' value={data.service_package.name} />
        )}
      </Grid>
    );
  },
  Edit: () => {
    const { hasFeature } = useFeatureFlags();
    const { t } = useTranslation();

    const hasServicePackagesFeature = hasFeature(FLAGS.SERVICE_PACKAGES);

    return (
      <Grid columns={2}>
        <Field label='Property' name='property' Input={PropertySelect} />
        <Field label='Ownership' name='ownership' Input={OwnershipSelect} />
        <Field
          label='Agreement start date'
          name='agreement_start_date'
          Input={DateInput}
        />
        <Field
          label='Agreement end date'
          name='agreement_end_date'
          Input={DateInput}
        />
        {hasServicePackagesFeature && (
          <Field
            label='Service package'
            name='service_package'
            HelpTooltipContent={() => (
              <>
                {t(
                  'property-ownerships.management-agreement.service-package-tooltip.message'
                )}
              </>
            )}
            Input={ServicePackageSelect}
            inputProps={{
              deselectable: true
            }}
          />
        )}

        <HiddenField name='is_service_package_required' />
      </Grid>
    );
  }
};
