import React from 'react';
import { Helmet } from 'react-helmet';
import dayjs from 'dayjs';

import Box from '@rexlabs/box';
import { BREADCRUMBS, Breadcrumbs } from 'view/components/@luna/breadcrumbs';
import { RecordTitleCard } from 'view/components/cards/title';
import { PageLayout } from 'view/components/layouts/page-layout/page-layout';

import { TitleBlock } from 'view/components/cards/title/title-block';
import { formatCurrency, pluralize } from 'utils/formatters';
import { getFinancialYearId } from '../utils/get-financial-year-id';
import { getFinancialYearAbbreviationString } from '../utils/get-financial-year-abbreviation-string';
import { PaymentsToHmrcCard } from '../components/payments-to-hmrc-card';
import { useGetQuarterlySummaryFromFinancialYear } from '../hooks/use-get-quarterly-summary-from-financial-year';
import { useGetFinancialYearStartAndEnd } from '../hooks/use-get-financial-year-start-and-end';
import { getQuartersForFinancialYear } from '../utils/get-quarters-for-financial-year';

const dateFormat = 'D MMM YYYY';

export function HmrcReportsAndSubmissionsQuarterDetails({ id, quarterId }) {
  const getFinancialYearStartAndEnd = useGetFinancialYearStartAndEnd();
  const { startDate, endDate } = getFinancialYearStartAndEnd(id);

  const quartersForFinancialYear = getQuartersForFinancialYear(startDate);
  const { quarterStartDate, quarterEndDate } = quartersForFinancialYear[
    quarterId - 1
  ];

  const {
    data: quarterlySummaryData
  } = useGetQuarterlySummaryFromFinancialYear(dayjs(startDate).format('YYYY'));

  const quarterData = quarterlySummaryData.find(
    (quarter) => quarter.quarter_start_date === quarterStartDate
  );

  const breadcrumbs = [
    { type: BREADCRUMBS.HMRC_REPORTS_AND_SUBMISSIONS },
    {
      type: BREADCRUMBS.HMRC_REPORTS_AND_SUBMISSIONS_DETAILS,
      args: {
        id: getFinancialYearId(startDate),
        label: `FY ${getFinancialYearAbbreviationString(startDate, endDate)}`
      }
    }
  ];

  const title = `NRLQ ${quarterId} ${dayjs(startDate).format('YYYY')}-${dayjs(
    endDate
  ).format('YY')} payments`;

  const subTitle = `${dayjs(quarterStartDate).format(dateFormat)} - ${dayjs(
    quarterEndDate
  ).format(dateFormat)}`;

  return (
    <>
      <Helmet title={title} />
      <PageLayout>
        <Box flexDirection={'column'} rowGap={'2rem'}>
          <Breadcrumbs breadcrumbs={breadcrumbs} />
          <RecordTitleCard
            titleBlock={
              <TitleBlock
                subText={subTitle}
                title={title}
                stats={[
                  {
                    label: 'Payments not issued',
                    value: formatCurrency(quarterData?.payments_not_issued),
                    intent: 'warning',
                    valueTag: pluralize(
                      'payments',
                      quarterData
                        ? quarterData.number_of_payments_not_issued
                        : 0,
                      true
                    )
                  },
                  {
                    label: 'Payments issued',
                    value: formatCurrency(quarterData?.payments_issued),
                    intent: 'success',
                    valueTag: pluralize(
                      'payments',
                      quarterData ? quarterData.number_of_payments_issued : 0,
                      true
                    )
                  }
                ]}
                // NOTE: Hiding this for now as it's not implemented
                // actions={[
                //   {
                //     label: 'Download statement',
                //     handleAction: noop
                //   }
                // ]}
              />
            }
          />
          <PaymentsToHmrcCard
            quarterStartDate={quarterStartDate}
            quarterEndDate={quarterEndDate}
          />
        </Box>
      </PageLayout>
    </>
  );
}
