import React from 'react';

import { Columns, RecordTable } from 'view/components/table';
import { TrustJournalEntryLineItem } from 'src/modules/trust-journal-entry-line-items/types/trust-journal-entry-line-item-type';
import { RecordTableProps } from 'view/components/table/record';
import { trustJournalEntryLineItemModel } from 'src/modules/trust-journal-entry-line-items/models/trust-journal-entry-line-item-model';
import { TwoTierCell } from 'view/components/cells/two-tier-cell';
import { RecordLink } from 'view/components/record-link/record-link';
import { TrustLedger } from 'src/modules/trust-ledgers/types/trust-ledger-types';
import { RecordType } from 'data/models/types';

function LedgerCell({ trustLedger }: { trustLedger: TrustLedger }) {
  return (
    <TwoTierCell
      text={
        <RecordLink
          onClick={(e) => e.stopPropagation()}
          type={trustLedger.object.type.id as RecordType}
          id={trustLedger.object.id!}
        >
          {trustLedger.object.label}
        </RecordLink>
      }
      subtext={trustLedger.object.record_reference}
    />
  );
}

function SourceLedgerCell({ value }: { value: TrustJournalEntryLineItem }) {
  if (
    value.trust_ledger &&
    (value.type.id === 'transfer_source' ||
      value.type.id === 'invoice_withdrawal' ||
      value.type.id === 'withdrawal')
  )
    return <LedgerCell trustLedger={value.trust_ledger}></LedgerCell>;
  return <>-</>;
}

function DestinationLedgerCell({
  value
}: {
  value: TrustJournalEntryLineItem;
}) {
  if (
    value.trust_ledger &&
    (value.type.id === 'deposit' || value.type.id === 'invoice_payment')
  )
    return <LedgerCell trustLedger={value.trust_ledger}></LedgerCell>;
  return <>-</>;
}

const columns: Columns<TrustJournalEntryLineItem> = [
  {
    Header: 'Type',
    type: 'value',
    accessor: (item) => item.type,
    width: 140
  },
  {
    Header: 'Source ledger',
    accessor: (item) => item,
    Cell: SourceLedgerCell,
    width: 180
  },
  {
    Header: 'Destination ledger',
    accessor: (item) => item,
    Cell: DestinationLedgerCell,
    width: 180
  },
  {
    Header: 'Relates to',
    type: 'invoice',
    accessor: (item) => item.invoice,
    width: 180
  },
  {
    Header: 'Description',
    accessor: (item) => item.description
  },
  {
    Header: 'Amount',
    type: 'currency',
    accessor: (item) => item.amount ?? 0,
    cellProps: {
      align: 'right'
    },
    width: 120
  }
];

export function TrustJournalEntryLineItemTable(
  props: RecordTableProps<typeof trustJournalEntryLineItemModel>
) {
  return <RecordTable {...props} columns={columns} />;
}
