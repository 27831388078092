import React, { ReactNode } from 'react';

import { Body, Small, Truncate } from '@rexlabs/text';
import { useToken } from '@rexlabs/styling';
import Box from '@rexlabs/box';

interface TwoTierCellProps {
  text: ReactNode | null;
  subtext: ReactNode | null;
}

export function TwoTierCell({ text, subtext }: TwoTierCellProps) {
  const token = useToken();

  return (
    <Body as='div'>
      <Truncate>{text ?? '--'}</Truncate>
      {subtext && (
        <Box marginTop={token('spacing.xxs')}>
          <Truncate>
            <Small as='span' grey>
              {subtext}
            </Small>
          </Truncate>
        </Box>
      )}
    </Body>
  );
}
