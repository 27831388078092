import React from 'react';

import { Message } from '@rexlabs/notifications';

import EmptyTable from 'assets/illustrations/empty-table.svg';

import { EmptyCard } from 'view/components/record-screen/cards/empty-card';

import { pluralize } from 'utils/formatters';
import { FinancialRecordType } from 'utils/financials/financial-objects';

export function FinancialFoliosListEmptyState({
  recordType
}: {
  recordType: FinancialRecordType;
}) {
  return (
    <EmptyCard hasGreyBackground={true}>
      <Message title='No folios matching this search' Illustration={EmptyTable}>
        Any {pluralize(recordType, 2)} matching this search will be displayed
        here.
      </Message>
    </EmptyCard>
  );
}
