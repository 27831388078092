import { RecordTypes } from 'data/models/types';
import { Ownership } from 'data/models/entities/ownerships';
import { Tenancy } from 'data/models/entities/tenancies';
import { Contact } from 'src/modules/contacts/types/contact-types';

export const FinancialRecordTypes = {
  Ownership: RecordTypes.Ownership,
  Contact: RecordTypes.Contact,
  Tenancy: RecordTypes.Tenancy
} as const;

export type FinancialRecord = Ownership | Contact | Tenancy;

export type FinancialRecordType = typeof FinancialRecordTypes[keyof typeof FinancialRecordTypes];
