import { TaskTypeAssignment } from '../models/portfolios-model';
import { MappedTaskTypeAssignments } from '../types/portfolio-form-data';

export function mapTaskTypeAssignmentFormDataToPortfolioUpdateRequest(
  taskTypeAssignments: MappedTaskTypeAssignments
): Partial<TaskTypeAssignment>[] {
  const mappedTaskTypeAssignments = Object.keys(taskTypeAssignments).map(
    (key) => {
      return {
        task_type: { id: key },
        contact: taskTypeAssignments[key].contact
      };
    }
  ) as Partial<TaskTypeAssignment>[];

  return mappedTaskTypeAssignments.filter(
    (item) => item?.contact?.id !== 'do-not-automatically-assign'
  );
}
