import * as React from 'react';

import { Field } from '@rexlabs/form';
import { RadioGroupInput } from '@rexlabs/radio-input';
import { useTranslation } from 'react-i18next';

export function InspectionTypeInput({
  hideOptionalLabel,
  onBlur
}: {
  hideOptionalLabel?: boolean;
  onBlur?: () => void;
} = {}) {
  const { t } = useTranslation();

  const radioProps = {
    options: [
      {
        label: t('tasks.inspections.type.routine-inspection.label.singular'),
        value: 'routine_inspection'
      },
      {
        label: t('tasks.inspections.type.entry-inspection.label'),
        value: 'entry_inspection'
      },
      {
        label: t('tasks.inspections.type.exit-inspection.label'),
        value: 'exit_inspection'
      }
    ],
    orientation: 'horizontal'
  };

  return (
    <Field
      id='inspection_type'
      name='inspection_type'
      label='Inspection type'
      Input={RadioGroupInput}
      inputProps={radioProps}
      onBlur={onBlur}
      {...(hideOptionalLabel ? { optional: false } : {})}
    />
  );
}
