import { fragment, query } from '@rexlabs/model-generator';
import { financialsInvoicesModel } from 'data/models/entities/financials/invoices';

const financialsInvoicesModelFragment = fragment`
id
status
reference
description
amount_total_inc_tax
amount_total_exc_tax
amount_total_tax
amount_due
amount_paid
is_tax_included
due_date
payable_by
payable_by_contact{
  financial_contact
}
payable_by_ownership{
  financial_contact
}
payable_by_tenancy{
  financial_contact
}
payable_to
tax_summary
task
uploaded_bill {
  file
}
`;

export const getInvoicesQuery = () => query`{
  ${financialsInvoicesModel} {
    ${financialsInvoicesModelFragment}
  }
}`;

export const getInvoiceQuery = (filter: string) => query`{
  ${financialsInvoicesModel} (q: ${filter}) {
    ${financialsInvoicesModelFragment}
  }
}`;

export const invoiceIncludes = [
  'line_items.id',
  'line_items.payable_by_property',
  'line_items.payable_to_property',
  'line_items.description',
  'line_items.amount',
  'line_items.tax_type',
  'line_items.payable_by_chart_of_accounts_account',
  'line_items.payable_to_chart_of_accounts_account',
  'reimbursement_for_invoice',
  'reimbursed_by_invoices',
  'maintenance_job',
  'tax_summary',
  'property',
  'payments.journal_entry_line_item',
  'payments.invoice',
  'payments.source_record',
  'payable_by_ownership',
  'payable_by_ownership.financial_contact',
  'payable_to_ownership',
  'payable_by_contact',
  'payable_by_contact.financial_contact',
  'payable_to_contact',
  'payable_by_tenancy',
  'payable_by_tenancy.financial_contact',
  'payable_to_tenancy',
  'specific_disbursement_payment_method',
  'bank_account',
  'voided_by',
  'triggered_by_invoice_payment.invoice',
  'created_by',
  'updated_by'
].join(',');
