import { NormalisedItem } from '@rexlabs/select';
import Icons from '@rexlabs/icons';
import * as React from 'react';
import { StyleSheet, useStyles } from '@rexlabs/styling';
import { propertyTenancyStatusMap } from 'src/modules/property-tenancies/maps/property-tenancy-map';
import { PropertyTenancy } from 'src/modules/property-tenancies/types/property-tenancy-types';

export const styles = StyleSheet({
  average: {
    color: ({ token }) => token('palette.yellow.600')
  },
  good: {
    color: ({ token }) => token('palette.green.600')
  },
  bad: {
    color: ({ token }) => token('palette.red.600')
  }
});

const CircleFilledIcon = (status) => {
  const s = useStyles(styles);

  const type = propertyTenancyStatusMap[status.id];
  return <Icons.CircleFilled {...s(type)} />;
};

export function normalisePropertyTenancyForProperty(
  item: PropertyTenancy
): NormalisedItem {
  return {
    id: item?.id,
    label: item?.tenancy?.display_name,
    description: item?.record_reference,
    type: item?.status.label,
    Icon: () => CircleFilledIcon(item?.status)
  };
}

export function normalisePropertyTenancyForTenancy(
  item: PropertyTenancy
): NormalisedItem {
  return {
    id: item?.id,
    label: item?.property?.display_name,
    description: item?.record_reference,
    type: item?.status.label,
    Icon: () => CircleFilledIcon(item?.status)
  };
}
