import { AxiosResponse } from 'axios';
import { useQuery } from 'react-query';
import { api } from 'utils/api/api-client';
import { ValueListValue } from 'data/models/types';
import { CommunicationContextType } from '../types/communication-context-type';
import { MergeTag } from '../types/merge-tags';

export type MessageContext = ValueListValue<CommunicationContextType> & {
  extends?: (string | null)[]; // The null here is odd, but that's what the backend is giving us. Might be a bug
  merge_tags?: MergeTag[];
};

const fetcher = async (): Promise<AxiosResponse<MessageContext[]>> => {
  return api.get('/communications/contexts');
};

export function useMessageContexts() {
  return useQuery('messageContexts', fetcher, { select: ({ data }) => data });
}
