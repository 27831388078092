import { query } from '@rexlabs/model-generator';
import { ownershipsModel } from 'data/models/entities/ownerships';

export const getOwnershipListQuery = () => query`{
    ${ownershipsModel} {
      id
      reference
      name
      contacts {
        id
        contact {
          id
          name
        }
      }
      properties {
        id
        address
        name
      }
      financial_summary
      disbursement_withheld_funds
      disbursement_frequency
      trust_summary
      created_by{
        user
      }
      updated_by{
        user
      }
    }
  }`;
