import { ActionDeclaration } from 'src/modules/common/actions/types/action-declaration-types';
import { Bill } from '../../types/Bill';
import { useBillProcessingDeleteAction } from '../action-declarations/use-bill-processing-delete-action';
import { useGetProcessBillAction } from '../action-declarations/use-get-process-bill-action';

export function useGetBillProcessingActions() {
  const getProcessBillAction = useGetProcessBillAction();
  const getBillProcessingDeleteAction = useBillProcessingDeleteAction();

  return (billsToBeProcessed: Bill[]): ActionDeclaration[] => {
    const actions = [
      getProcessBillAction(billsToBeProcessed),
      getBillProcessingDeleteAction(billsToBeProcessed)
    ];

    return actions;
  };
}
