import { Columns } from 'src/view/components/table';
import { IncludeForNewPropertiesHeader } from 'src/modules/property-areas/property-area-types/components/included-for-new-properties-header';
import { PropertyAreaType } from 'src/modules/property-areas/property-area-types/types/PropertyAreaType';
import { IncludedForNewPropertiesCell } from 'src/modules/property-areas/property-area-types/components/included-for-new-properties-cell';

export const propertyAreaTypeColumns: Columns<PropertyAreaType> = [
  {
    id: 'name',
    Header: 'Name',
    width: '200px'
  },
  {
    id: 'is_created_on_new_properties',
    Header: IncludeForNewPropertiesHeader,
    width: '300px',
    Cell: IncludedForNewPropertiesCell
  },
  {
    id: 'created_by',
    Header: 'Created by',
    type: 'author',
    width: '150px'
  },
  {
    id: 'summary.item_count',
    Header: 'Items for Entry/Exit Inspections',
    cellProps: {
      align: 'right'
    },
    width: '200px'
  }
];
