import React from 'react';

import { getRecordLinkProps } from 'view/components/record-link/get-record-link-props';
import { Columns, RecordTable } from 'view/components/table';
import { Ownership } from 'data/models/entities/ownerships';

const columns: Columns<Ownership> = [
  {
    id: 'reference',
    Header: 'Reference',
    width: 140,
    accessor: (item) => item.record_reference
  },
  {
    id: 'name',
    Header: 'Name',
    accessor: (item) => item.display_name
  }
];

const getRowLinkProps = ({ item }) =>
  getRecordLinkProps({ type: 'ownership', id: item?.id });

interface OwnershipsListUI {
  items: Ownership[];
  isLoading: boolean;
}

export function OwnershipsListUI({ items, isLoading }: OwnershipsListUI) {
  return (
    <RecordTable
      id='ownerships-list'
      columns={columns}
      items={items}
      isLoading={isLoading}
      getRowLinkProps={getRowLinkProps}
      getRowId={(item) => item?.id}
    />
  );
}
