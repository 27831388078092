import { useFeatureFlags } from 'view/components/@luna/feature-flags';
import { FLAGS } from 'utils/feature-flags';
import { InspectionType } from '../../types/InspectionType';

export function useGetIsEntryExit() {
  const { hasFeature } = useFeatureFlags();

  return (inspectionType?: InspectionType) => {
    return (
      (inspectionType === 'entry_inspection' ||
        inspectionType === 'exit_inspection') &&
      hasFeature(FLAGS.ENTRY_EXIT_INSPECTION_REPORT)
    );
  };
}
