import { ActionDeclaration } from 'src/modules/common/actions/types/action-declaration-types';
import { TaskType } from 'src/modules/tasks/common/types/TaskType';
import { FieldArrayPassthroughProps } from '@rexlabs/form';
import { useFeatureFlags } from 'view/components/@luna/feature-flags';
import { FLAGS } from 'utils/feature-flags';
import { useEditChecklistItemAction } from '../action-declarations/checklist-item/use-edit-checklist-item-action';
import { useCreateChecklistItemAction } from '../action-declarations/checklist-item/use-create-checklist-item-action';
import { useAddTriggerAction } from '../action-declarations/checklist-item/use-add-trigger-action';
import { useDeleteChecklistItemAction } from '../action-declarations/checklist-item/use-delete-checklist-item-action';
import { ChecklistTemplateItem } from '../../types/ChecklistTemplate';
import { ChecklistTemplatesForm } from '../../types/ChecklistTemplatesForm';

export function useGetChecklistTemplateItemActions({
  taskType,
  index,
  fieldArrayProps,
  values,
  setFieldValue
}: {
  taskType: TaskType;
  index: number;
  fieldArrayProps: FieldArrayPassthroughProps;
  setFieldValue: (field: string, value: any) => void;
  values: Partial<ChecklistTemplatesForm>;
}) {
  const { hasFeature } = useFeatureFlags();
  const editChecklistItemAction = useEditChecklistItemAction(taskType);
  const createChecklistItemAction = useCreateChecklistItemAction(taskType);
  const addTriggerAction = useAddTriggerAction({
    index,
    fieldArrayProps,
    taskType
  });
  const deleteChecklistItemAction = useDeleteChecklistItemAction();

  return (
    checklistTemplateItem: ChecklistTemplateItem
  ): ActionDeclaration[] => {
    // TODO: remove this once feature flag is removed
    const triggerActions = hasFeature(FLAGS.SMART_CHECKLISTS_COMBINED_DIALOG)
      ? []
      : checklistTemplateItem?.trigger_type
      ? []
      : [addTriggerAction({ checklistTemplateItem })];

    return [
      editChecklistItemAction({
        checklistTemplateItem,
        index,
        fieldArrayProps,
        values,
        setFieldValue
      }),
      ...triggerActions,
      createChecklistItemAction({
        fieldArrayProps,
        index,
        checklistTemplateItemGroup:
          checklistTemplateItem.checklist_template_item_group ?? undefined
      }),
      deleteChecklistItemAction({
        fieldArrayProps,
        index,
        checklistTemplateItem,
        setFieldValue,
        values
      })
    ];
  };
}
