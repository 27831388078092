import React, { useRef, useEffect, useState, useCallback } from 'react';
import mapboxgl, { Map } from 'mapbox-gl';

import Box from '@rexlabs/box';

import { Coordinates } from 'src/lib/mapbox/types/Coordinates';

type MapConfig = {
  width?: number | string;
  height?: number | string;
};
/**
 * The purpose of this hook is to provide a mapbox map instance, a loading state, and a map reference that you can use
 * to make changes to the map.
 * In your component, add the Map component in the render function. From there, you can use the mapRef to manipulate the map,
 * but you must wait till the map is loaded before you can do so.
 * For more information on how to manipulate the map, see https://docs.mapbox.com/mapbox-gl-js/guides/ and also:
 *   - src/modules/tasks/inspections/components/directions-map.tsx for examples of adding layers and markers.
 *   - src/modules/properties/components/edit-location.tsx for example of adding a marker.
 */
export function useMapComponent({
  startingCenterCoordinates,
  staringZoom = 13
}: {
  startingCenterCoordinates: Coordinates;
  staringZoom?: number;
}): {
  mapObject: Map | null;
  isLoading: boolean;
  MapComponent: React.FC<MapConfig>;
} {
  // When we initially load the map, we need to wait till the styles are loaded, before we can
  // make any changes to the map. This is why we have the isLoading state.
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const mapRef = useRef<Map | null>(null);
  const mapContainer = useRef<any>('map');

  const [startingLng, startIngLat] = startingCenterCoordinates;

  const [centerLngLng, setCenterLngLat] = useState<Coordinates>([
    startingLng,
    startIngLat
  ]);
  const [zoom, setZoom] = useState<number>(staringZoom);

  // The following is to initialise the map and some starting settings.
  // This only happens on component mount
  useEffect(() => {
    mapRef.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: 'mapbox://styles/mapbox/streets-v12',
      center: centerLngLng,
      zoom
    });

    // Add the zoom control on the bottom right corner
    mapRef.current.addControl(
      new mapboxgl.NavigationControl({ showCompass: false }),
      'bottom-right'
    );

    // Add a listener to update the center coordinates on zoom & move
    mapRef.current.on('move', () => {
      if (mapRef.current) {
        setCenterLngLat([
          parseInt(mapRef.current.getCenter().lng.toFixed(4)),
          parseInt(mapRef.current.getCenter().lat.toFixed(4))
        ]);
        setZoom(parseInt(mapRef.current.getZoom().toFixed(2)));
      }
    });

    mapRef.current.on('load', () => {
      setIsLoading(false);
    });
  }, []);

  const Map = useCallback(({ height = 400, width = '100%' }) => {
    return (
      <Box
        display={isLoading ? 'none' : 'block'}
        id='map'
        ref={mapContainer}
        className='map-container'
        width={width}
        height={height}
      />
    );
  }, []);

  return {
    isLoading,
    mapObject: mapRef.current,
    MapComponent: Map
  };
}
