import { push } from '@rexlabs/whereabouts';
import ROUTES from 'routes/app';
import { ActionDeclaration } from 'src/modules/common/actions/types/action-declaration-types';
import { MessageTemplate } from '../../types/MessageTemplate';

export function useGetViewMessageTemplateAction() {
  return (messageTemplate: MessageTemplate): ActionDeclaration => {
    return {
      id: 'view-message-template',
      group: 'message-templates',
      label: 'View template',
      handleAction: async () => {
        push(ROUTES.MESSAGE_TEMPLATE_DETAILS, {
          params: { messageTemplateId: messageTemplate.id }
        });
      }
    };
  };
}
