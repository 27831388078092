import { fragment } from '@rexlabs/model-generator';

export const messageTemplateQueryFragment = fragment`
  id,
  channels,
  send_from_author,
  inline_attachments,
  document_types,
  compliance_types,
`;
