import { User } from 'src/modules/users/types/user-types';
import { SingleActionDeclaration } from 'src/modules/common/actions/types/action-declaration-types';
import { getUserRedirectUri } from 'src/modules/users/utils/get-user-redirect-uri';
import { useApiClient } from 'src/lib/axios/hooks/use-api-client';
import { useToast } from 'view/components/@luna/notifications/toast';
import { useModelActions } from '@rexlabs/model-generator';
import React from 'react';
import { usersModel } from '../models/users';

export function useResendInviteAction() {
  const axios = useApiClient();
  const { addToast } = useToast();
  const { refreshLists, refreshItem } = useModelActions(usersModel);

  return (user: User): SingleActionDeclaration => {
    return {
      id: 'resend-user-invitation',
      label:
        user.status.id === 'not_invited'
          ? 'Send invitation'
          : 'Resend invitation',
      group: 'user-invitation',
      handleAction: async () => {
        const res = await axios('post', `/user-invites/`, {
          redirect_uri: getUserRedirectUri(),
          user: {
            id: user.id
          }
        });

        if (res !== false) {
          await Promise.all([
            refreshItem({
              id: user.id
            }),
            refreshLists()
          ]);

          addToast({
            description: (
              <>
                Invitation sent to{' '}
                <b>{user.contact?.primary_email?.email_address}</b>
              </>
            )
          });
        }
      }
    };
  };
}
