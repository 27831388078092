// financials/reconciliations/
import { touchEtag } from '@rexlabs/model-generator';

import { Generator } from 'data/models/generator';
import { api } from 'utils/api/api-client';
import { usersModel } from 'src/modules/users/models/users';
import { Author } from 'src/modules/authors/types/author-types';
import {
  BaseFields,
  RecordTypes,
  TimestampAttributes,
  ValueListValue
} from '../../types';
import { BankAccount } from './bank-accounts';

export type ReconciliationStatus =
  | 'active'
  | 'reconciled'
  | 'verified'
  | 'unverified'
  | 'voided';

type TrustTrialBalanceBreakDown = {
  agency_amount: number;
  bond_authority_amount: number;
  contact_amount: number;
  ownership_amount: number;
  supplier_amount: number;
  tax_authority_amount: number;
  tenancy_amount: number;
};

export type ReconciliationSummary = {
  suspended_transaction_amount: number;
  suspended_transaction_count: number;
  bank_deposits_unreconciled_amount: number;
  bank_deposits_unreconciled_count: number;
  bank_withdrawals_exc_cheques_unreconciled_amount: number;
  bank_withdrawals_exc_cheques_unreconciled_count: number;
  bank_adjustments_unreconciled_amount: number;
  bank_adjustments_unreconciled_count: number;
  trust_receipts_not_yet_banked_amount: number;
  trust_receipts_not_yet_banked_count: number;
  trust_withdrawals_not_yet_banked_amount: number;
  trust_withdrawals_not_yet_banked_count: number;
  trust_cheque_withdrawals_unreconciled_amount: number;
  trust_cheque_withdrawals_unreconciled_count: number;
  trust_receipts_in_period_amount: number;
  trust_receipts_in_period_count: number;
  trust_withdrawals_in_period_amount: number;
  trust_withdrawals_in_period_count: number;
  adjusted_bank_balance_amount: number;
  cashbook_opening_balance_amount: number;
  cashbook_closing_balance_amount: number;
  trust_trial_balance_amount: number;
  balance_variance_amount: number;
  trust_trial_balance_breakdown: TrustTrialBalanceBreakDown;
};

export type ReconciliationType = 'daily' | 'end_of_month';

export interface Reconciliation
  extends BaseFields<typeof RecordTypes.Reconciliation>,
    TimestampAttributes {
  audit_number: string;
  start_date: string;
  statement_date: string;
  reconciled_at: Date | null;
  verified_at: Date | null;
  voided_at: Date | null;

  status: ValueListValue<ReconciliationStatus>;
  status_reason: string | null;

  statement_balance: number;
  reconciliation_summary: ReconciliationSummary;

  bank_account?: BankAccount;
  verified_by?: Author;
  reconciled_by?: Author;
  voided_by?: Author;
  record_reference: string;
  type: ValueListValue<ReconciliationType>;
  previous_reconciliation?: null | Omit<
    Reconciliation,
    'previous_reconciliation'
  >;
}

const config = {
  entities: {
    related: {
      bank_account: {
        include: 'bank_account'
      },
      created_by: {
        include: 'created_by',
        model: usersModel
      },
      updated_by: {
        include: 'updated_by',
        model: usersModel
      },
      verified_by: {
        include: 'verified_by',
        model: usersModel
      },
      voided_by: {
        include: 'voided_by',
        model: usersModel
      },
      reconciled_by: {
        include: 'reconciled_by',
        model: usersModel
      },
      reconciliation_summary: {
        include: 'reconciliation_summary'
      },
      previous_reconciliation: {
        include: 'previous_reconciliation'
      }
    }
  }
};

type CompleteReconciliationPayload = {
  id: string;
};

const actionCreators = {
  completeReconciliation: {
    request: (
      payload: CompleteReconciliationPayload,
      _actions,
      _dispatch,
      _getState
    ) =>
      api.put(`/financials/reconciliations/${payload.id}/status`, {
        status: {
          id: 'reconciled'
        }
      }),
    reduce: {
      success: (state, action) => {
        const originalId = action.meta.originalPayload.id;
        return {
          ...state,
          items: {
            ...state.items,
            [originalId]: touchEtag({
              ...state.items[originalId],
              data: {
                ...state.items[originalId].data,
                status: action.payload.data.status
              }
            })
          }
        };
      }
    }
  }
};

export const financialsReconciliationsModel = new Generator<
  Reconciliation,
  typeof actionCreators
>('financials/reconciliations', config).createEntityModel({ actionCreators });
