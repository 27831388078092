import React, { useMemo } from 'react';

import {
  TitleBlock,
  TitleBlockProps
} from 'view/components/cards/title/title-block';

import { RecordObject, RecordType } from 'data/models/types';
import { RecordLink } from 'view/components/record-link/record-link';
import ContactIcon from 'view/components/icons/contact';
import EmailIcon from 'view/components/icons/email';
import PhoneIcon from 'view/components/icons/phone';
import { formatCurrency } from 'utils/formatters';

import getPendingTrustJournalEntryStatusColor from 'src/modules/trust-journal-entries/utils/pending-trust-journal-entry-status-color';
import { getRecordTitle } from 'utils/records/get-record-title';
import { useContactActions } from 'src/modules/contacts/actions/use-contact-actions';
import { Contact } from 'src/modules/contacts/types/contact-types';
import { titleBlockStatusTag } from 'src/modules/common/components/status-tag';
import { contactStatusMap } from 'src/modules/contacts/maps/contact-status-map';
import { useTranslation } from 'react-i18next';

const type: RecordType = 'contact';

type ContactTitleBlockProps = {
  contact: Contact;
};

export function ContactTitleBlock({ contact }: ContactTitleBlockProps) {
  const { t } = useTranslation();

  const {
    id,
    record_reference,
    emails,
    phones,
    roles,
    compliance_categories
  } = contact;

  const getContactActions = useContactActions();

  const primaryEmail = emails?.find((email) => email.is_primary_email)
    ?.email_address;
  const primaryPhone = phones?.find((phone) => phone.is_primary_phone)
    ?.phone_number;

  const EmailLabel = useMemo(
    () =>
      function EmailLabel({ label }: { label?: string }) {
        return label ? (
          <>{label}</>
        ) : (
          <RecordLink id={id} type={type} tab={'overview'}>
            No email supplied
          </RecordLink>
        );
      },
    [id, type]
  );

  const PhoneLabel = useMemo(
    () =>
      function PhoneLabel({ label }: { label?: string }) {
        return label ? (
          <>{label}</>
        ) : (
          <RecordLink id={id} type={type} tab={'overview'}>
            No phone supplied
          </RecordLink>
        );
      },
    [id, type]
  );

  const subTitles = useMemo(
    () => [
      {
        label: primaryEmail,
        Icon: EmailIcon,
        Label: EmailLabel
      },
      {
        label: primaryPhone,
        Icon: PhoneIcon,
        Label: PhoneLabel
      }
    ],
    [type, primaryEmail, primaryPhone]
  );

  let tags: TitleBlockProps['tags'] = [];
  if (roles && roles.length > 0) {
    roles.forEach((role) => {
      tags?.push({
        type: 'good',
        label: `${role.label}`
      });
    });
  }

  if (compliance_categories && compliance_categories.length > 0) {
    tags = tags.concat(
      compliance_categories.map((serviceCategory) => ({
        type: 'neutral',
        label: serviceCategory.label
      }))
    );
  }

  if (contact.status.id !== 'active') {
    tags?.push(titleBlockStatusTag(contact.status, contactStatusMap));
  }

  const title = getRecordTitle({ type, object: contact } as RecordObject);

  const actions = useMemo(() => getContactActions(contact), [contact]);
  const financialSummary = contact.financial_summary!;
  const trust_summary = contact.trust_summary!;

  const stats = useMemo<TitleBlockProps['stats']>(
    () => [
      {
        label: 'Bills & fees payable',
        value: formatCurrency(
          financialSummary?.bills_and_fees_payable_total_amount_owing
        ),
        intent: getPendingTrustJournalEntryStatusColor(
          financialSummary?.bills_and_fees_payable_count
        )
      },
      {
        label: 'Invoices receivable',
        value: formatCurrency(
          financialSummary?.invoices_receivable_total_amount_owing
        ),
        intent: getPendingTrustJournalEntryStatusColor(
          financialSummary?.invoices_receivable_count
        )
      },
      {
        label: 'Net income this period',
        intent: 'secondary',
        value: formatCurrency(0),
        workInProgress: true
      },
      {
        label: t('financials.trust.available-trust-balance.label.singular'),
        intent: 'secondary',
        value: formatCurrency(trust_summary?.available_balance_amount)
      },
      {
        label: 'Disburseable funds',
        intent: 'secondary',
        value: formatCurrency(0),
        workInProgress: true
      }
    ],
    [
      financialSummary?.bills_and_fees_payable_count,
      financialSummary?.bills_and_fees_payable_total_amount_owing,
      financialSummary?.invoices_receivable_count,
      financialSummary?.invoices_receivable_total_amount_owing,
      trust_summary?.available_balance_amount
    ]
  );

  return (
    <TitleBlock
      title={title}
      type={type}
      reference={record_reference}
      subTitles={subTitles}
      tags={tags}
      Icon={ContactIcon}
      actions={actions}
      stats={stats}
    />
  );
}
