import { EmailPreviewProps } from '../../common/components/email/email-preview';
import { Message } from '../types/Message';
import { getRecipientText } from '../utils/get-recipient-text';

/**
 * Converts a message to an email preview props object.
 */
export function mapMessageToEmailPreviewProps(
  message?: Message
): EmailPreviewProps {
  const emailChannel = message?.channels.find(
    (channel) => channel.channel_type.id === 'email'
  );

  const attachedFiles =
    message?.inline_attachments
      .filter((attachment) => attachment.file)
      .map((attachment) => attachment.file) ?? [];

  return {
    subject: emailChannel?.resolved_email_subject ?? '',
    bodyContent: emailChannel?.resolved_text_body ?? '',
    recipients: getRecipientText(message?.recipients?.items ?? []),
    cc: getRecipientText(message?.cc_recipients?.items ?? []),
    bcc: getRecipientText(message?.bcc_recipients?.items ?? []),
    attachedFiles: attachedFiles.length > 0 ? attachedFiles : undefined
  };
}
