import dayjs from 'dayjs';

export function getQuartersForFinancialYear(startOfFinancialYear) {
  return [
    {
      quarterStartDate: dayjs(startOfFinancialYear).format('YYYY-MM-DD'),
      quarterEndDate: dayjs(startOfFinancialYear)
        .add(3, 'month')
        .subtract(1, 'day')
        .format('YYYY-MM-DD')
    },
    {
      quarterStartDate: dayjs(startOfFinancialYear)
        .add(3, 'month')
        .format('YYYY-MM-DD'),
      quarterEndDate: dayjs(startOfFinancialYear)
        .add(6, 'month')
        .subtract(1, 'day')
        .format('YYYY-MM-DD')
    },
    {
      quarterStartDate: dayjs(startOfFinancialYear)
        .add(6, 'month')
        .format('YYYY-MM-DD'),
      quarterEndDate: dayjs(startOfFinancialYear)
        .add(9, 'month')
        .subtract(1, 'day')
        .format('YYYY-MM-DD')
    },
    {
      quarterStartDate: dayjs(startOfFinancialYear)
        .add(9, 'month')
        .format('YYYY-MM-DD'),
      quarterEndDate: dayjs(startOfFinancialYear)
        .add(12, 'month')
        .subtract(1, 'day')
        .format('YYYY-MM-DD')
    }
  ];
}
