import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import Box from '@rexlabs/box';
import { Field } from '@rexlabs/form';
import { TextInput } from '@rexlabs/text-input';
import Tooltip from '@rexlabs/tooltip';

import { MaintenanceTask } from 'src/modules/tasks/maintenance/types/MaintenanceTask';

import { formatCurrency } from 'utils/formatters';

import { Grid } from 'view/components/@luna/form/grid';
import InfoCircleIcon from 'view/components/icons/info';
import { CentAmountInput } from 'view/components/inputs/cent-amount';
import { BlockConfig } from 'view/components/record-screen/types';
import { Value } from 'view/components/values';
import { CurrencyValue } from 'view/components/values/currency';

export const taskWithheldFundsBlock: BlockConfig<
  MaintenanceTask,
  any,
  MaintenanceTask
> = {
  id: 'task-withheld-funds',
  title: () => (
    <Trans i18nKey='withheld-funds.task-withheld-funds-block.title' />
  ),
  TitleTooltip: () => (
    <Tooltip
      Content={() => (
        <Trans i18nKey={'withheld-funds.task-withheld-funds-block.tooltip'} />
      )}
    >
      <Box flexDirection='row' alignItems='center' width={24} height={24}>
        <InfoCircleIcon
          size={'m'}
          data-testid='withheld-funds.task-withheld-funds-block.tooltip'
        />
      </Box>
    </Tooltip>
  ),
  View: ({ data }) => {
    const { t } = useTranslation();
    return (
      <Grid columns={1}>
        <Grid columns={2}>
          <CurrencyValue
            testId={'task-withheld-funds'}
            label={t('withheld-funds.task-withheld-funds.label.singular')}
            value={data?.details?.disbursement_withheld_funds?.amount}
          />
        </Grid>
        <Grid columns={2}>
          <Value
            label='Reason'
            value={data?.details?.disbursement_withheld_funds?.reason}
          />
        </Grid>
      </Grid>
    );
  },

  Edit: ({ values }) => {
    const { t } = useTranslation();
    return (
      <Grid columns={1}>
        <Grid columns={2}>
          <Field
            name='details.disbursement_withheld_funds.amount'
            id={'details.disbursement_withheld_funds.amount'}
            label={
              t('withheld-funds.task-withheld-funds.label.singular') as string
            }
            Input={CentAmountInput}
            description={
              t(
                'withheld-funds.task-withheld-funds-block.task-withheld-funds-field-helper',
                {
                  ownershipWithheldFunds: formatCurrency(
                    values?.property?.active_property_ownership?.ownership
                      .disbursement_withheld_funds?.amount || 0
                  )
                }
              ) as string
            }
            optional={false}
          />
        </Grid>
        <Grid columns={2}>
          <Field
            name='details.disbursement_withheld_funds.reason'
            id={'details.disbursement_withheld_funds.reason'}
            label='Reason'
            Input={TextInput}
          />
        </Grid>
      </Grid>
    );
  }
};
