import React, { useMemo, useState } from 'react';
import { useEntityQuery } from '@rexlabs/model-generator';

import { BREADCRUMBS } from 'view/components/@luna/breadcrumbs';
import { RecordScreen } from 'view/components/record-screen';

import { Tenancy } from 'data/models/entities/tenancies';
import { useMapTenancyFormToApi } from 'src/modules/tenancies/mappers/use-map-tenancy-form-to-api';
import { useMapApiToTenancyForm } from 'src/modules/tenancies/mappers/use-map-api-to-tenancy-form';
import { SelectedBankAccountProvider } from 'src/modules/tenancies/contexts/selected-bank-account-context';
import { useContent } from '../data/content';
import { getTenancyQuery } from '../data/queries';
import { TenancyTitleBlock } from '../components/tenancy-title-block';

interface TenancyDetailsScreenProps {
  tenancyId: string;
}

export const tenancyIncludes = [
  'tenants.contact.primary_phone',
  'tenants.guarantors',
  'rent_arrears',
  'available_funds',
  'property_tenancies.property',
  'financial_summary',
  'trust_summary',
  'related_contacts.contact.primary_phone',
  'created_by',
  'updated_by'
].join(',');

export function TenancyDetailsScreen({ tenancyId }: TenancyDetailsScreenProps) {
  const breadcrumbs = [{ type: BREADCRUMBS.TENANCY }];
  const [key, setKey] = useState(tenancyId);
  const query = useMemo(() => getTenancyQuery(tenancyId), [tenancyId]);
  const { status, data, actions } = useEntityQuery(query, {
    throwOnError: false
  });
  const mapTenancyFormToApi = useMapTenancyFormToApi();
  const mapApiToTenancyForm = useMapApiToTenancyForm();

  const content = useContent();

  const handleSubmit = async ({ values, changedValues }) => {
    const data = mapTenancyFormToApi({
      ...changedValues,
      tenants: values.tenants
    });

    await actions
      .updateItem({
        id: tenancyId,
        data,
        args: {
          include: tenancyIncludes
        }
      })
      .then(({ data }) => {
        // If we've changed the tenants we need to reset the form, as the banking reference number
        // is returned be backend, not a value we set. This means we get the id back, but it will not
        // appear in the form. Changing the key of the record screen forces a re-render and we have the
        // new reference number.
        if (changedValues?.tenants) {
          setKey(tenancyId + '-' + data.updated_at);
        }
      });
  };

  return (
    <SelectedBankAccountProvider>
      <RecordScreen
        key={key}
        isLoading={status === 'loading'}
        data={data}
        initialValues={mapApiToTenancyForm(data)}
        handleSubmit={handleSubmit}
        breadcrumbs={breadcrumbs}
        content={content}
        titleBlock={<TenancyTitleBlock tenancy={data as Tenancy} />}
      />
    </SelectedBankAccountProvider>
  );
}
