import React from 'react';
import { BlockConfig } from 'view/components/record-screen/types';
import { Field } from '@rexlabs/form';
import { Grid } from 'view/components/@luna/form/grid';
import { Select } from '@rexlabs/select';
import { RecordTypes } from 'data/models/types';
import { FLAGS } from 'utils/feature-flags';
import { useFeatureFlags } from 'view/components/@luna/feature-flags';
import { useTranslation } from 'react-i18next';

function useTaskTypes() {
  const { t } = useTranslation();
  return {
    [RecordTypes.Todo]: {
      id: RecordTypes.Todo,
      label: 'Todo',
      flag: FLAGS.TODOS
    },

    [RecordTypes.TaskMaintenance]: {
      id: RecordTypes.TaskMaintenance,
      label: 'Maintenance',
      flag: FLAGS.TASKS_MAINTENANCE
    },

    [RecordTypes.TaskInspection]: {
      id: RecordTypes.TaskInspection,
      label: 'Inspection',
      flag: FLAGS.INSPECTIONS
    },

    [RecordTypes.TaskLeaseReview]: {
      id: RecordTypes.TaskLeaseReview,
      label: t('tasks.lease-review.label.singular'),
      flag: FLAGS.LEASE_REVIEWS
    },

    [RecordTypes.TaskMoveOut]: {
      id: RecordTypes.TaskMoveOut,
      label: 'Move out',
      flag: FLAGS.MOVE_OUTS
    },

    [RecordTypes.TaskMoveIn]: {
      id: RecordTypes.TaskMoveIn,
      label: t('tasks.move-in.title'),
      flag: FLAGS.MOVE_INS
    },

    [RecordTypes.TaskQuote]: {
      id: RecordTypes.TaskQuote,
      label: 'Quotes',
      flag: FLAGS.QUOTES
    },

    [RecordTypes.TaskWorkOrder]: {
      id: RecordTypes.TaskWorkOrder,
      label: 'Work orders',
      flag: FLAGS.WORK_ORDERS
    }
  };
}

export const selectTaskTypeBlock: BlockConfig = {
  id: 'task-type',
  title: 'Linked Tasks',
  validate: {
    definitions: {
      task_type: { rules: 'required' }
    }
  },

  Edit: () => {
    const { hasFeature } = useFeatureFlags();
    const taskTypes = useTaskTypes();

    const taskInputProps = React.useMemo(() => {
      return {
        items: Object.values(taskTypes)
          .filter((task) => hasFeature(task.flag))
          .map((task) => task.id),

        normaliser: (item) => {
          return taskTypes[item];
        }
      };
    }, [hasFeature]);

    return (
      <Grid columns={3}>
        <Field
          id={'task_type'}
          name='task_type'
          label='Select task type'
          Input={Select}
          inputProps={taskInputProps}
          optional={false}
        />
      </Grid>
    );
  }
};
