import React from 'react';
import {
  SelectProps,
  Autocomplete,
  SelectNormalised
} from '@rexlabs/select-old';

import { api } from 'utils/api/api-client';

import { Supplier } from 'data/models/entities/ownerships';
import { Contact } from 'src/modules/contacts/types/contact-types';

const transformContact = (contact: Contact): Supplier => ({
  contact
});

const normaliseSupplier = (supplier: Supplier): SelectNormalised => ({
  value: supplier,
  label: supplier?.contact?.display_name,
  type: 'contact'
});

export function SupplierSelect(props: Omit<SelectProps, 'items'>) {
  const items = React.useCallback(async (term) => {
    const contacts = await api.get(`/contacts`, {
      search: term,
      per_page: 30,
      include: 'emails,service_categories'
    });
    return contacts.data.map(transformContact);
  }, []);

  return (
    <Autocomplete items={items} normaliser={normaliseSupplier} {...props} />
  );
}
