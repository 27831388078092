import { useModelActions } from '@rexlabs/model-generator';
import { useMemo } from 'react';
import { getInvoicesPayableByBlock } from 'src/modules/invoices/blocks/invoices-payable-by';
import { useInvoiceFilters } from 'src/modules/invoices/utils/use-invoice-filters';
import { contactsModel } from '../models/contacts';
import { contactIncludes } from '../screens/contact-details';

export const usePayableByInvoicesBlock = () => {
  const { refreshItem } = useModelActions(contactsModel);

  const {
    filters: invoiceFilters,
    actions: invoiceActions
  } = useInvoiceFilters({
    hiddenInvoiceFilterIds: [
      'rent_invoices',
      'agency_fees',
      'bond_requests',
      'owner_bills',
      'tenant_bills'
    ]
  });

  return useMemo(
    () =>
      getInvoicesPayableByBlock(({ data }) => {
        return {
          hashParamKey: 'payable_by',
          getForcedGlobalFilter: (contactId) => [
            {
              field: 'payable_by_object_id',
              op: '==',
              value: contactId
            },
            {
              field: 'payable_by_object_type',
              op: '==',
              value: 'contact'
            }
          ],
          initialValues: {
            payable_by: {
              object: {
                id: data.id,
                type: { id: 'contact', label: 'Contact' },
                label: data.display_name,
                record: data
              }
            }
          },
          invoiceFilters,
          invoiceActions,
          refreshRecord: async () =>
            await refreshItem({
              id: data.id,
              args: {
                include: contactIncludes
              }
            })
        };
      }),
    [invoiceFilters]
  );
};
