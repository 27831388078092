import { BaseModelGeneratorModel } from '@rexlabs/model-generator';
import { valueListFactory } from 'src/lib/testing/factories/value-list-factory';
import { SearchResultItem } from 'utils/api/get-search-results';
import { getRecordLabel } from 'utils/records/get-record-label';
import { RecordData } from 'utils/types';
import {
  RecordTypeIdentifier,
  getRecordObject
} from '../Records/get-record-object';

export function getSearchResultItemFromObjectAndModel<TRecord = RecordData>(
  object?: BaseModelGeneratorModel,
  model?: RecordTypeIdentifier
): SearchResultItem<TRecord> | null {
  if (model && object) {
    const recordObject = getRecordObject(object, model);
    return {
      id: recordObject.object?.id,
      label: getRecordLabel(recordObject),
      record: recordObject.object as TRecord,
      type: valueListFactory(recordObject.type)
    };
  }
  return null;
}
