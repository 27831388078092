import { Contact } from 'src/modules/contacts/types/contact-types';
import { DisbursementPaymentMethod } from '../types/types';

type DisbursementPaymentMethodArgs = Pick<
  DisbursementPaymentMethod,
  'split_amount'
> & {
  contact: Contact;
};

function getAmountDisbursementMethods(
  disbursementPaymentMethods: DisbursementPaymentMethodArgs[]
) {
  return disbursementPaymentMethods.filter((paymentMethod) => {
    return paymentMethod.split_amount.type.id === 'percent';
  });
}

export function getDoAllDisbursementPaymentMethodsHaveValues(
  disbursementPaymentMethods: DisbursementPaymentMethodArgs[]
) {
  const percentageBasedDisbursementPaymentMethods = getAmountDisbursementMethods(
    disbursementPaymentMethods
  );
  const allFieldsHaveValues = percentageBasedDisbursementPaymentMethods.every(
    (item) => item.split_amount.amount !== null
  );
  return allFieldsHaveValues;
}

export function getDisbursementPaymentMethodsPercentageTotal(
  disbursementPaymentMethods: DisbursementPaymentMethodArgs[]
) {
  const percentageBasedDisbursementPaymentMethods = getAmountDisbursementMethods(
    disbursementPaymentMethods
  );
  const percentageTotal = percentageBasedDisbursementPaymentMethods.reduce(
    (all, item) => all + (item.split_amount.amount || 0),
    0
  );

  return percentageTotal;
}
