import React, { useContext, useMemo } from 'react';

import Box from '@rexlabs/box';
import { padding, StyleSheet, useStyles, useToken } from '@rexlabs/styling';

import config from 'src/config';
import { Toast } from './core';

import { ToastDataContext } from './context';

const targetStyles = StyleSheet({
  container: {
    position: 'fixed',
    right: 0,
    left: 0,
    display: 'flex',
    alignItems: 'center',
    pointerEvents: 'none',

    ...padding.styles({
      target: 'toast.target',
      all: 'm'
    }),

    zIndex: ({ token }) =>
      token('toast.target.zIndex', parseInt(token('zIndex.base', 0)) + 100),

    '& > *': {
      pointerEvents: 'all'
    }
  },

  top: {
    top: 0,
    flexDirection: 'column-reverse'
  },

  bottom: {
    bottom: 0,
    flexDirection: 'column'
  },

  left: {
    alignItems: 'flex-start',

    '@media(max-width: 768px)': {
      alignItems: 'center'
    }
  },

  right: {
    alignItems: 'flex-end',

    '@media(max-width: 768px)': {
      alignItems: 'center'
    }
  }
});

type Position =
  | 'top-left'
  | 'top'
  | 'top-right'
  | 'bottom-left'
  | 'bottom'
  | 'bottom-right';

export type ToastTargetProps = {
  name?: string;
  position?: Position;
};

const defaultDuration = config.TOAST_DEFAULT_DURATION ?? 5000;

function ToastTarget({
  name = 'main',
  position = 'top-right'
}: ToastTargetProps) {
  const s = useStyles(targetStyles);
  const token = useToken();

  const { toasts: allToasts } = useContext(ToastDataContext);
  const toasts = useMemo(
    () => allToasts.filter((toast) => toast.target === name),
    [allToasts, name]
  );

  const top = position.includes('top');
  const bottom = position.includes('bottom');
  const left = position.includes('left');
  const right = position.includes('right');

  const spacing = token('toast.target.spacing', token('spacing.m'));

  return toasts.length ? (
    <Box
      {...s('container', {
        top,
        bottom,
        left,
        right
      })}
      sy={spacing}
    >
      {toasts.map((toast, index) => (
        <Toast
          key={toast.id}
          role='toast'
          {...toast}
          duration={toast.duration ?? defaultDuration}
          timerEnabled={index === 0}
        />
      ))}
    </Box>
  ) : null;
}

export default ToastTarget;
