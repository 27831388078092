import React from 'react';

import { StylesProvider, border } from '@rexlabs/styling';

import { Banner, BannerProps } from './core';

const overrides = {
  banner: {
    ...border.tokens({
      target: 'banner.warning',
      all: {
        color: () => 'transparent'
      }
    }),
    background: {
      color: ({ token }) =>
        token(
          'banner.warning.background.color',
          token('color.container.static.warning.default')
        )
    },
    strong: {
      ...border.tokens({
        target: 'banner.warning.strong',
        all: {
          color: () => 'transparent'
        }
      }),
      background: {
        color: ({ token }) =>
          token(
            'banner.warning.strong.background.color',
            token('color.container.static.warning.contrast')
          )
      }
    }
  }
};

export function WarningBanner(props: BannerProps) {
  return (
    <StylesProvider tokens={overrides}>
      <Banner {...props} />
    </StylesProvider>
  );
}

export default WarningBanner;
