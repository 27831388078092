import React from 'react';

import { DialogProps } from '@rexlabs/dialog';

import { RecordSubmitHandler } from 'view/components/record-screen/utils';
import { RecordDialog } from 'view/components/record-screen/dialog/dialog';

import {
  InspectionRunStartBlockFormValues,
  InspectionRunStartData,
  inspectionRunStartBlock
} from '../blocks/inspection-run-start-block';

export interface InspectionRunStartDialogProps extends DialogProps {
  initialValues: InspectionRunStartData;
  submitHandler: RecordSubmitHandler<InspectionRunStartBlockFormValues>;
}

const content = [
  {
    id: 'inspection-start',
    label: '',
    blocks: [inspectionRunStartBlock]
  }
];

export function EditInspectionRunStartDialog({
  onClose,
  initialValues,
  submitHandler
}: InspectionRunStartDialogProps) {
  return (
    <RecordDialog
      size='s'
      title='Inspection run start'
      content={content}
      data={initialValues}
      onClose={onClose}
      handleSubmit={submitHandler}
      submitLabel='Change inspection run start'
    />
  );
}
