import React, { useCallback } from 'react';
import {
  ConfirmationDialog,
  DialogProps,
  useErrorDialog
} from '@rexlabs/dialog';
import { useModelActions } from '@rexlabs/model-generator';
import { useToast } from 'view/components/@luna/notifications/toast';
import {
  PortfolioMember,
  portfolioMembersModel
} from '../models/portfolio-members-model';
import { portfoliosModel } from '../models/portfolios-model';

export interface RemoveMemberConfirmationDialog extends DialogProps {
  portfolioMember: PortfolioMember;
  portfolioId: string;
}

export function RemoveMemberConfirmationDialog({
  onClose,
  portfolioMember,
  portfolioId
}: RemoveMemberConfirmationDialog) {
  const { trashItem } = useModelActions(portfolioMembersModel);
  const { refreshItem: refreshPortfolio } = useModelActions(portfoliosModel);
  const errorDialog = useErrorDialog();

  const { addToast } = useToast();

  const handleSubmit = useCallback(async () => {
    try {
      await trashItem({ id: portfolioMember.id });
    } catch (error) {
      errorDialog.open(error);
      return;
    }

    addToast({
      type: 'success',
      description: 'Member successfully removed'
    });

    await refreshPortfolio({ id: portfolioId });
  }, [addToast, portfolioMember.id, trashItem]);

  return (
    <ConfirmationDialog
      title='Remove member'
      size='s'
      onClose={onClose}
      message={`Do you want to remove ${portfolioMember.user?.display_name} from this portfolio? They will remain assigned to any tasks that were previously assigned to them.`}
      confirmText='Yes, remove member'
      onConfirm={handleSubmit}
    />
  );
}
