import { BlockConfig } from 'view/components/record-screen/types';
import React from 'react';
import { InfoBanner } from 'view/components/@luna/notifications/banner';
import InfoCircleIcon from 'view/components/icons/info';
import { Value } from 'view/components/values';
import { ServicePackageSelect } from 'view/components/inputs/selects/v4-selects/service-package-select';
import { Field } from '@rexlabs/form';
import { Grid } from 'view/components/@luna/form/grid';
import { useTranslation } from 'react-i18next';

const DefaultServicePackageInfoBanner = () => {
  const { t } = useTranslation();

  return (
    <InfoBanner
      Icon={InfoCircleIcon}
      description={
        t(
          'property-ownerships.management-agreement.default-service-package-setting.info-banner'
        ) as string
      }
    ></InfoBanner>
  );
};

export const servicePackageSettingsBlock: BlockConfig = {
  id: 'service-package-settings',
  title: 'Service package settings',
  View: ({ data }) => {
    const { t } = useTranslation();

    return (
      <Grid columns={1}>
        <Grid columns={2}>
          <Value
            label={t(
              'property-ownerships.management-agreement.default-service-package-setting.field-label'
            )}
            value={
              data?.['default-service-package']?.default_service_package?.label
            }
          />
        </Grid>
        <DefaultServicePackageInfoBanner />
      </Grid>
    );
  },
  Edit: () => {
    const { t } = useTranslation();

    return (
      <Grid columns={1}>
        <Grid columns={2}>
          <Field
            name='default_service_package'
            Input={ServicePackageSelect}
            id='default_service_package'
            label={
              t(
                'property-ownerships.management-agreement.default-service-package-setting.field-label'
              ) as string
            }
            inputProps={{
              deselectable: true
            }}
          />
        </Grid>
        <DefaultServicePackageInfoBanner />
      </Grid>
    );
  }
};
