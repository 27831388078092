import React from 'react';
import { Field } from '@rexlabs/form';
import { Message } from 'view/components/@luna/message';
import { PortfolioSelect } from 'view/components/inputs/selects/portfolio';
import { BlockConfig } from 'view/components/record-screen/types';
import { Value } from 'view/components/values';
import { Grid } from 'view/components/@luna/form/grid';
import EmptyTable from 'src/assets/illustrations/empty-table.svg';
import { FlattenedProperty } from '../types/property-types';

export const portfolioBlock: BlockConfig<FlattenedProperty> = {
  id: 'portfolio',
  title: 'Portfolio',
  showEmpty: (data) => {
    return data?.portfolio == null;
  },
  Empty: ({ onEditClick }) => {
    return (
      <Message
        Illustration={EmptyTable}
        title='Portfolio'
        actions={[
          {
            label: 'Add to portfolio',
            intent: 'primary',
            handleAction: onEditClick
          }
        ]}
      >
        Select which portfolio this property is included in.
      </Message>
    );
  },
  View: ({ data }) => (
    <Grid columns={1}>
      <Value label='Portfolio' value={data?.portfolio?.label} />
    </Grid>
  ),
  Edit: () => (
    <Grid columns={1}>
      <Field
        name='portfolio'
        label='Portfolio'
        Input={PortfolioSelect}
        inputProps={{
          deselectable: true
        }}
      />
    </Grid>
  )
};
