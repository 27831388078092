import React, {
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useMemo,
  useState
} from 'react';

import { ActionDeclaration } from 'src/modules/common/actions/types/action-declaration-types';

import { noop } from 'lodash';

type ActiveMenuType = 'left' | 'right' | null;

export type StickyNavigationContextProps = {
  actions: ActionDeclaration[];
  activeMenu: ActiveMenuType;
  hideViewAction: boolean;
  setActions: Dispatch<SetStateAction<ActionDeclaration[]>>;
  setActiveMenu: Dispatch<SetStateAction<ActiveMenuType>>;
  setHideViewAction: Dispatch<SetStateAction<boolean>>;
};

const StickyNavigationContext = createContext<StickyNavigationContextProps>({
  actions: [],
  activeMenu: null,
  hideViewAction: false,
  setActions: noop,
  setActiveMenu: noop,
  setHideViewAction: noop
});

const { Provider } = StickyNavigationContext;

export function StickyNavigationProvider({ children }) {
  const [actions, setActions] = useState<ActionDeclaration[]>([]);
  const [activeMenu, setActiveMenu] = useState<ActiveMenuType>(null);
  const [hideViewAction, setHideViewAction] = useState<boolean>(false);

  const value = useMemo(
    () => ({
      actions,
      activeMenu,
      hideViewAction,
      setActions,
      setActiveMenu,
      setHideViewAction
    }),
    [actions, activeMenu, hideViewAction]
  );

  return <Provider value={value}>{children}</Provider>;
}

export function useStickyNavigation() {
  return useContext(StickyNavigationContext);
}
