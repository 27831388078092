import { propertyComplianceCategorySettingsBlock } from '../blocks/property-compliance-categories-settings-block';
import { propertyComplianceRequirementsSettingsBlock } from '../blocks/property-compliance-requirements-settings-block';

export const content = [
  {
    label: 'General',
    items: [
      {
        id: 'categories',
        label: 'Categories',
        blocks: [propertyComplianceCategorySettingsBlock]
      },
      {
        id: 'requirements',
        label: 'Requirements',
        blocks: [propertyComplianceRequirementsSettingsBlock]
      }
    ]
  }
];
