import { useDialog } from '@rexlabs/dialog';
import {
  getRenewDialogLabel,
  RenewCheckDialog
} from 'src/modules/compliance/common/dialogs/renew-check-dialog';
import { ActionDeclaration } from 'src/modules/common/actions/types/action-declaration-types';
import { useRenewFromTaskToast } from 'src/modules/compliance/common/hooks/use-renew-from-task-toast';
import { useModelActions } from '@rexlabs/model-generator';
import {
  tasksModel,
  taskIncludes,
  sharedIncludes
} from 'src/modules/tasks/common/models/tasks-model';
import { PropertyComplianceTask } from '../../types/PropertyComplianceTask';

export function useGetRenewCheckAction() {
  const renewCheckDialog = useDialog(RenewCheckDialog);
  const addToast = useRenewFromTaskToast();
  const actions = useModelActions(tasksModel);

  return (task?: PropertyComplianceTask): ActionDeclaration => {
    const complianceType =
      task?.details?.compliance_type ||
      task?.details?.compliance_entry?.compliance_type;
    const propertyId = task?.property?.id;
    const label = getRenewDialogLabel(complianceType);
    const handleSave = (data) => {
      addToast(data);
      return actions.refreshItem({
        id: task?.id,
        args: {
          include: [
            taskIncludes.propertyComplianceDetails,
            ...sharedIncludes
          ].join(',')
        }
      });
    };

    return {
      label,
      id: 'renew-check',
      handleAction: () => {
        return renewCheckDialog.open({
          onSave: handleSave,
          complianceType,
          propertyId
        });
      }
    };
  };
}
