import { useModelActions } from '@rexlabs/model-generator';
import { useErrorDialog } from '@rexlabs/dialog';

import { propertiesModel } from 'data/models/entities/properties';

import { RecordSubmitHandler } from 'view/components/record-screen/utils';

import { EditLocationBlockValues } from '../blocks/edit-location-block';
import { Property } from '../types/property-types';

export function useGetUpdatePropertyLocationSubmitHandler(): (
  property: Property,
  afterAction?: (props?: any) => any
) => RecordSubmitHandler<EditLocationBlockValues> {
  const { updateItem } = useModelActions(propertiesModel);
  const { open: openErrorDialog } = useErrorDialog();

  return (property, afterAction) => async ({ values }) => {
    try {
      const [longitude, latitude] = values.property_lng_lat!;

      const { data: updatedProperty } = await updateItem({
        id: property.id,
        data: {
          address: {
            ...property.address,
            longitude: longitude.toString(),
            latitude: latitude.toString()
          }
        },
        args: {
          include: 'address'
        }
      });

      await afterAction?.(updatedProperty);
      return updatedProperty;
    } catch (error) {
      openErrorDialog({ error });
    }
  };
}
