import * as React from 'react';
import { BlockConfig } from 'view/components/record-screen/types';
import { Grid } from 'view/components/@luna/form/grid';
import { Field } from '@rexlabs/form';
import { TextArea } from '@rexlabs/text-input';
import Box from '@rexlabs/box';
import { useGetAgencyContacts } from 'src/modules/contacts/hooks/use-get-agency-contacts';
import { Checkbox } from '@rexlabs/checkbox';
import { ContactSelect } from 'view/components/inputs/selects/contact';
import { BatchReceiptingItem } from '../types';
import { getTransactionLabel } from '../hooks/use-batch-receipting-button-config';

export const suspendTransactionDetailsBlock: BlockConfig<
  any,
  { batchReceiptingItem: BatchReceiptingItem }
> = {
  id: 'suspend-transaction-details',
  title: 'Suspend transaction',
  validate: {
    definitions: {
      branch: { rules: 'required_if_true:assign_to_branch' }
    }
  },
  Edit: ({ blockProps, values }) => {
    const { batchReceiptingItem } = blockProps!;

    const transactionLabel = getTransactionLabel(batchReceiptingItem);

    const agencyContacts = useGetAgencyContacts();

    return (
      <Grid columns={1}>
        <Box>
          Do you want to suspend the transaction for {transactionLabel}?
        </Box>
        <Field name='content' label='Note' Input={TextArea} />
        {agencyContacts?.length && agencyContacts.length > 1 && (
          <>
            <Field
              name='assign_to_branch'
              Input={Checkbox}
              inputProps={{ label: 'Assign to branch for investigation' }}
              optional={false}
            />
            {values?.assign_to_branch && (
              <Field
                name='branch'
                label='Select branch'
                Input={ContactSelect}
                inputProps={{ filterByRoles: ['agency'], disableFixture: true }}
                optional={false}
              />
            )}
          </>
        )}
      </Grid>
    );
  }
};
