import { useGetArchivableEntityActionGroup } from 'view/hooks/actions/archivable-entity-action-group/use-get-archivable-entity-action-group';
import {
  SecurityDepositType,
  financialsSecurityDepositTypesModel
} from 'data/models/entities/financials/security-deposit-types';
import React from 'react';
import { useModelActions } from '@rexlabs/model-generator';
import { useTranslation } from 'react-i18next';

export function useGetSecurityDepositTypeArchivableEntityActionGroup() {
  const { t } = useTranslation();
  const { archive, trashItem, refreshList } = useModelActions(
    financialsSecurityDepositTypesModel
  );
  const getArchivableEntityActionGroup = useGetArchivableEntityActionGroup();

  return (securityDepositType: SecurityDepositType, updateListId?: string) =>
    getArchivableEntityActionGroup({
      recordTypeName: 'security deposit type',
      recordName: securityDepositType.name,
      archiveArgs: {
        message: (
          <>
            {t(
              'bonds-and-deposits.security-deposit-type.archive.confirmation.singular.message'
            )}
          </>
        ),
        handleArchive: async () => {
          await archive({ depositTypeId: securityDepositType.id });

          if (updateListId) {
            await refreshList({ id: updateListId });
          }
        }
      },
      deleteArgs: {
        message: (
          <>
            {t(
              'bonds-and-deposits.security-deposit-type.delete.confirmation.singular.message'
            )}
          </>
        ),
        handleDelete: () => trashItem({ id: securityDepositType.id })
      }
    });
}
