import { omit } from 'lodash';

import { OptimisedRouteResponse } from 'src/lib/mapbox/types/OptimisedRouteResponse';

import { InspectionTask } from '../types/InspectionTask';

export function sortInspectionsFromOptimisedRoute(
  route: OptimisedRouteResponse,
  inspections: InspectionTask[]
): InspectionTask[] {
  const { waypoints } = route;

  const inspectionsSorted = inspections
    .map((inspection, index) => ({
      ...inspection,
      waypoint_index: waypoints?.[index + 1]?.waypoint_index || 0
    }))
    .sort((a, b) => a.waypoint_index - b.waypoint_index)
    .map((inspection) => omit(inspection, 'waypoint_index'));

  return inspectionsSorted;
}
