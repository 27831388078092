import { query } from '@rexlabs/model-generator';
import { trustLedgerModel } from 'src/modules/trust-ledgers/models/trust-ledger-model';
import { toQuri } from '@rexlabs/quri';

export const getFilterByTrustAccountId = (trustAccountId: string) => {
  return toQuri([
    {
      field: 'trust_account_id',
      op: '==',
      value: trustAccountId
    }
  ]);
};

export const getTrustLedgersListQuery = (filter: string) => query`{
          ${trustLedgerModel} (q: ${filter}) {
            id
            name
            object {
              id
              label
              type
            }
          }
        }`;
