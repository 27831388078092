import {
  AutomaticTaskCreationValues,
  Settings
} from 'src/modules/settings/hooks/useSettings';
import { pick } from 'lodash';
import { TaskSettingsFormValues } from 'src/modules/tasks/settings/screens/task-settings-detail';
import { ServicePackage } from 'src/modules/service-packages/types/service-package';

const TASK_SETTING_KEYS = [
  'property-compliance-task',
  'supplier-compliance-task',
  'inspection-task-interval',
  'lease-review-task-interval'
] as const;

export type TaskSettingKey = typeof TASK_SETTING_KEYS[number];

interface TaskSettingsData {
  key: keyof Settings;
  value: Settings[TaskSettingKey];
}

function getServicePackageIds(
  automaticTaskCreation: AutomaticTaskCreationValues,
  servicePackages: ServicePackage[]
): string[] {
  if (automaticTaskCreation === 'service_packages') {
    return servicePackages.map((sp) => sp.id);
  }

  return [];
}

function getInspectionValues(values: Partial<TaskSettingsFormValues>) {
  const servicePackageIds: string[] = getServicePackageIds(
    values?.inspection_automatic_task_creation ?? 'all_properties',
    values?.inspection_service_packages ?? []
  );

  if (values?.routine_inspection?.is_active) {
    return {
      ...pick(values, [
        'first_inspection',
        'routine_inspection',
        'notice_period'
      ]),
      automatic_task_creation: values?.inspection_automatic_task_creation,
      service_package_ids: servicePackageIds
    };
  }

  return {
    ...pick(values, ['first_inspection', 'notice_period']),
    automatic_task_creation: values?.inspection_automatic_task_creation,
    service_package_ids: servicePackageIds,
    routine_inspection: {
      is_active: false
    }
  };
}

function getLeaseReviewValues(values: Partial<TaskSettingsFormValues>) {
  return {
    ...pick(values, [
      'periodic_agreement_review',
      'periodic_agreement_notice',
      'fixed_agreement_notice'
    ]),
    automatic_task_creation: values?.lease_review_automatic_task_creation,
    service_package_ids: getServicePackageIds(
      values?.lease_review_automatic_task_creation ?? 'all_properties',
      values?.lease_review_service_packages ?? []
    )
  };
}

function getPropertyComplianceValues(
  values: TaskSettingsFormValues['property-compliance-task']
) {
  return {
    ...pick(values, ['automatic_task_creation', 'count', 'unit']),
    service_package_ids: getServicePackageIds(
      values?.automatic_task_creation ?? 'all_properties',
      values?.service_packages ?? []
    )
  };
}

export function mapTaskSettingsFormToApi(
  values: TaskSettingsFormValues,
  changedBlockIds: TaskSettingKey[]
): TaskSettingsData[] {
  return (
    changedBlockIds
      // here we only want the changed blocks that are relevant to the task settings
      .filter((blockId) =>
        TASK_SETTING_KEYS.includes(blockId as TaskSettingKey)
      )
      .map((blockId) => {
        let value;
        // each block is structured differently, so each one needs to be mapped slightly differently
        switch (blockId) {
          case 'inspection-task-interval': {
            value = getInspectionValues(values);
            break;
          }

          case 'lease-review-task-interval': {
            value = getLeaseReviewValues(values);
            break;
          }

          case 'property-compliance-task': {
            value = getPropertyComplianceValues(values[blockId]);
            break;
          }
          case 'supplier-compliance-task':
            {
              value = values[blockId];
            }
            break;
        }

        return value && { key: blockId, value };
      })
      .filter(Boolean)
  );
}
