import { Message } from '@rexlabs/notifications';
import React from 'react';

import EmptyBatchReceipting from 'src/assets/illustrations/empty-batch-receipting-list.svg';

import { Card } from 'view/components/@luna/card';

export function NotMatchedTabEmptyState() {
  return (
    <Card>
      <Message
        title='Transactions not matched'
        Illustration={EmptyBatchReceipting}
      >
        Transactions that have not yet been matched to a folio will be displayed
        here.
      </Message>
    </Card>
  );
}
