import React, { useEffect, useMemo } from 'react';
import {
  Dropdown as VividDropdown,
  SelectNormalised
} from '@rexlabs/select-old';

function getValue({
  item,
  isMulti
}: {
  isMulti: boolean;
  item?: SelectNormalised | SelectNormalised[] | null;
}) {
  if (!item) {
    return isMulti ? [] : null;
  }
  return item;
}

export function Dropdown({ options, op, value, ...props }) {
  const items = useMemo(
    () => options?.map(({ id, label }) => ({ value: id, label })),
    [options]
  );
  const isMulti = op.value === 'in' || op.value === 'nin';

  // The value needs to be an array only if the select is a multiselect
  useEffect(() => {
    if (!isMulti && Array.isArray(value)) {
      props.actions.setValue(value[0] || null);
    }
    if (isMulti && value && !Array.isArray(value)) {
      props.actions.setValue([value]);
    }
  }, [isMulti, value]);

  return (
    <VividDropdown
      {...props}
      items={items}
      value={getValue({ item: value, isMulti })}
      multi={isMulti}
      // @ts-ignore
      denormaliser={(item) => (item?.value?.value ? item.value : item)}
    />
  );
}
