import React, { useCallback } from 'react';
import { DialogProps } from '@rexlabs/dialog';
import { useModelActions } from '@rexlabs/model-generator';

import { api } from 'utils/api/api-client';

import { ComplianceType } from 'src/modules/compliance/common/models/compliance-types';

import { settingsPropertyComplianceCategoriesModel } from 'data/models/entities/settings/property-compliance-categories';
import { useToast } from 'view/components/@luna/notifications/toast';
import { RecordDialog } from 'view/components/record-screen/dialog/dialog';

import { propertyComplianceCategoryDetailsBlock } from '../blocks/property-compliance-categories-details-block';
import { ComplianceSettingsDialogButtonGroup } from '../../common/components/compliance-settings-dialog-button-group';

const content = [
  {
    id: 'details',
    label: 'Details',
    blocks: [propertyComplianceCategoryDetailsBlock]
  }
];

export function CreateComplianceCategoryDialog({ onClose }: DialogProps) {
  const { addToast } = useToast();
  const { refreshLists } = useModelActions(
    settingsPropertyComplianceCategoriesModel
  );

  const handleSubmit = useCallback(async ({ values }) => {
    const requirements = values.requirements || [];

    const { data } = await api.post('request-pipelines', {
      requests: [
        {
          method: 'POST',
          path: '/api/v1/settings/property-compliance-categories',
          json: { label: values.label, description: values.description }
        },
        ...requirements.map((requirement: ComplianceType) => ({
          method: 'POST',
          path: '/api/v1/settings/property-category-requirements',
          json: {
            property_compliance_category_id: `{{$.0.id}}`,
            compliance_type_id: requirement.id
          }
        }))
      ]
    });

    addToast({
      description: (
        <>
          Compliance category created - <b>{data[0].label}</b>
        </>
      )
    });
    await refreshLists();
    return data;
  }, []);

  return (
    <RecordDialog
      title='Create compliance category'
      handleSubmit={handleSubmit}
      onClose={onClose}
      content={content}
      ButtonGroup={ComplianceSettingsDialogButtonGroup}
      size={'s'}
    />
  );
}
