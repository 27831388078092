import React from 'react';

import {
  RecordDialog,
  RecordDialogProps
} from 'view/components/record-screen/dialog/dialog';
import { RecordSubmitHandler } from 'view/components/record-screen/utils';

import { taxAdjustmentBlock } from '../blocks/tax-adjustment-block';
import { TaxAdjustmentForm } from '../types/tax-adjustment-form';

type CreateTaxAdjustmentDialogProps = {
  initialValues: Partial<TaxAdjustmentForm>;
  onClose?: RecordDialogProps['onClose'];
  submitHandler: RecordSubmitHandler<TaxAdjustmentForm>;
};

const content = [
  {
    id: 'create-tax-adjustment',
    label: 'Create tax adjustment',
    blocks: [taxAdjustmentBlock]
  }
];

export function CreateTaxAdjustmentDialog({
  onClose,
  initialValues,
  submitHandler
}: CreateTaxAdjustmentDialogProps) {
  const [isReimbursement, setIsReimbursement] = React.useState(false);

  const toggleIsReimbursement = (newValue: boolean) =>
    setIsReimbursement(newValue);

  return (
    <RecordDialog
      blockProps={{ toggleIsReimbursement }}
      initialValues={initialValues}
      title='Create tax adjustment'
      submitLabel={
        isReimbursement ? 'Create adjustment & withdrawal' : 'Create adjustment'
      }
      onClose={onClose}
      content={content}
      handleSubmit={submitHandler}
    />
  );
}
