import React, { useMemo } from 'react';
import { BlockConfig } from 'view/components/record-screen/types';
import { Column, Columns, ListTable, TabbedTable } from 'view/components/table';
import { query, useModelActions } from '@rexlabs/model-generator';
import { financialsReconciliationsBankDepositsModel } from 'data/models/entities/financials/reconciliations/bank-deposits';
import {
  financialsReconciliationsModel,
  Reconciliation
} from 'data/models/entities/financials/reconciliations';
import { bankDepositModel } from 'src/modules/banking/bank-deposits/models/bank-deposit-model';
import { BankDeposit } from 'src/modules/banking/bank-deposits/types/bank-deposit-types';
import { RecordCell } from '@rexlabs/table';
import { useTableFilters } from 'view/hooks/use-table-filters';
import ROUTES from 'routes/app';
import { Tab } from 'view/components/table/tabbed';
import { getReconcileItemsPayload } from '../utils/get-reconcile-payload';
import { ResolutionActionButton } from '../components/resolution-action-button';
import { getReconciledInThisReconciliationFilters } from '../utils/get-reconciled-in-this-reconciliation-filters';

const CustomActionCell = ({ cell }) => {
  const { reconcileItems, unreconcileItems } = useModelActions(
    bankDepositModel
  );

  // TODO: kind of strange that the functionality is split between these models. I wonder if there is an opportunity to combine these somehow...
  const { refreshList } = useModelActions(
    financialsReconciliationsBankDepositsModel
  );

  const { refreshItem: refreshReconciliation } = useModelActions(
    financialsReconciliationsModel
  );

  const { value } = cell;
  const bankDeposit = value as BankDeposit & { reconciliationId: string };

  const isReconciled =
    bankDeposit.reconciliation_status?.status.id === 'reconciled';

  const handleClick = async () => {
    if (isReconciled) {
      await unreconcileItems(
        getReconcileItemsPayload([bankDeposit], 'bank_deposit')
      );
    } else {
      await reconcileItems(
        getReconcileItemsPayload([bankDeposit], 'bank_deposit')
      );
    }

    await refreshList({ id: 'reconciliation-bank-deposits' });
    await refreshReconciliation({ id: bankDeposit.reconciliationId });
  };

  return (
    <ResolutionActionButton isResolved={isReconciled} onClick={handleClick} />
  );
};

const bankDepositColumns: Columns = [
  {
    id: 'date_of',
    type: 'date',
    Header: 'Deposit date'
  },
  {
    id: 'record_reference',
    Header: 'Deposit reference',
    Cell: RecordCell,
    accessor: (item) => item,
    cellProps: {
      labelKey: 'record_reference',
      getCellLinkProps: (value) => {
        return {
          to: ROUTES.BANK_DEPOSIT_DETAILS,
          params: {
            bankDepositId: value.id
          }
        };
      }
    }
  },
  {
    id: 'type',
    type: 'value',
    Header: 'Type'
  },
  {
    id: 'totals.amount',
    type: 'currency',
    cellProps: {
      align: 'right'
    },
    Header: 'Amount',
    width: 100
  }
];

const toggleReconcileAction = (reconciliationId: string): Column => ({
  id: 'action',
  accessor: (item) => ({ ...item, reconciliationId }),
  Cell: CustomActionCell,
  width: 60,
  sticky: 'right'
});

const getReconciliationBankDeposits = (reconciliationId: string) => () => ({
  ...query`{
    ${financialsReconciliationsBankDepositsModel} (reconciliationId: ${reconciliationId}) {
      id
      totals
      reconciliation_status
    }
  }`,
  uuid: 'reconciliation-bank-deposits'
});

export const bankDepositsBlock: BlockConfig<Reconciliation> = {
  id: 'bank-deposits',
  title: 'Bank deposits',
  View: ({ data }) => {
    const { reconcileItems, unreconcileItems } = useModelActions(
      bankDepositModel
    );

    const { refreshList } = useModelActions(
      financialsReconciliationsBankDepositsModel
    );

    const { refreshItem: refreshReconciliation } = useModelActions(
      financialsReconciliationsModel
    );

    const { getFilters, getSort } = useTableFilters('bank-deposits');

    const isUnfinishedReconciliation = data?.status?.id !== 'reconciled';

    const tabs = useMemo<Tab[]>(() => {
      const commonProps: Partial<Tab> = {
        id: 'reconciliation-bank-deposits',
        Table: ListTable,
        columns: [
          ...bankDepositColumns,
          ...(isUnfinishedReconciliation
            ? [toggleReconcileAction(data!.id)]
            : [])
        ],
        getQuery: getReconciliationBankDeposits(data!.id),

        getSort,
        getFilters,
        suggestedFilters: ['date_of', 'type_id'],
        initialSortBy: [{ id: 'date_of', desc: true }]
      };

      return [
        {
          ...commonProps,
          forcedGlobalFilter: [
            {
              field: 'reconciliation_status_id',
              op: 'eq',
              value: 'unreconciled'
            }
          ],
          name: 'unreconciled-bank-deposits',
          label: 'Unreconciled',
          getBulkActions: isUnfinishedReconciliation
            ? ({ selectedItems, entity }) => [
                {
                  label: 'Reconcile deposits',
                  type: 'primary',
                  handleAction: async () => {
                    await reconcileItems(
                      getReconcileItemsPayload(selectedItems, 'bank_deposit')
                    );
                    await refreshList({ id: entity.uuid });
                    await refreshReconciliation({ id: data!.id });
                  }
                }
              ]
            : undefined
        },
        {
          ...commonProps,
          forcedGlobalFilter: [
            {
              field: 'reconciliation_status_id',
              op: 'eq',
              value: 'reconciled'
            },
            ...getReconciledInThisReconciliationFilters(data!)
          ],
          name: 'reconciled-bank-deposits-in-this-reconciliation',
          label: 'This reconciliation',
          getBulkActions: isUnfinishedReconciliation
            ? ({ selectedItems, entity }) => [
                {
                  label: 'Unreconcile deposits',
                  type: 'primary',
                  handleAction: async () => {
                    await unreconcileItems(
                      getReconcileItemsPayload(selectedItems, 'bank_deposit')
                    );
                    await refreshList({ id: entity.uuid });
                    await refreshReconciliation({ id: data!.id });
                  }
                }
              ]
            : undefined
        },
        {
          ...commonProps,
          forcedGlobalFilter: [
            {
              field: 'reconciliation_status_id',
              op: 'eq',
              value: 'reconciled'
            }
          ],
          name: 'reconciled-bank-deposits',
          label: 'Reconciled to date',
          getBulkActions: isUnfinishedReconciliation
            ? ({ selectedItems, entity }) => [
                {
                  label: 'Unreconcile deposits',
                  type: 'primary',
                  handleAction: async () => {
                    await unreconcileItems(
                      getReconcileItemsPayload(selectedItems, 'bank_deposit')
                    );
                    await refreshList({ id: entity.uuid });
                    await refreshReconciliation({ id: data!.id });
                  }
                }
              ]
            : undefined
        }
      ];
    }, [
      data,
      getFilters,
      getSort,
      isUnfinishedReconciliation,
      reconcileItems,
      refreshList,
      refreshReconciliation,
      unreconcileItems
    ]);

    return <TabbedTable tabs={tabs} />;
  }
};
