import { TodoTask } from 'src/modules/tasks/todos/types/TodoTask';
import { Task } from 'src/modules/tasks/common/types/Task';
import { Columns } from 'view/components/table';
import { LinkSummaryCell } from 'src/modules/tasks/common/components/linked-summary-cell';
import { TaskTypeIconCell } from 'src/modules/tasks/common/components/task-type-icon-cell';
import { AnyTaskProgressCell } from 'src/modules/tasks/common/components/any-task-progress-cell';

const type = {
  id: 'todo_type',
  Header: '',
  accessor: (item: Task<any>) => item?.type,
  Cell: TaskTypeIconCell,
  width: 60,
  toCsv: (item: Task<any>) => item?.type?.label
};

const followUpDate = {
  id: 'follow_up_date',
  Header: 'Follow up date',
  accessor: (item: Task<any>) => item.follow_up_date,
  type: 'date',
  width: 150
} as const;

const dueDate = {
  id: 'due_date',
  Header: 'Due date',
  type: 'date',
  width: 150
} as const;

const closedDate = {
  id: 'closed_date',
  Header: 'Closed date',
  accessor: (item: Task<any>) => item.closed_at,
  type: 'date',
  width: 150
} as const;

const status = {
  id: 'status',
  Header: 'Task progress',
  Cell: AnyTaskProgressCell,
  accessor: (item: TodoTask) => item,
  width: 200,
  toCsv: (item: Task<any>) =>
    item?.details?.status?.label || item?.status?.label
};

export const useLinkedTaskListColumns = ({
  parentRecordId,
  tab
}: {
  parentRecordId: string;
  tab?: string;
}): Columns => {
  const summary = {
    id: 'summary',
    Header: 'Task',
    Cell: LinkSummaryCell,
    accessor: (item) => ({ task: item, cellProps: { parentRecordId } }),
    width: 200,
    toCsv: (item: Task<any>) => item.summary
  };

  if (tab === 'closed') {
    return [type, summary, closedDate, dueDate];
  }

  return [type, summary, followUpDate, dueDate, status];
};
