import React from 'react';
import { Tenancy } from 'data/models/entities/tenancies';
import { EmptyTenancy } from '../empty/empty-tenancy';
import { useGetTenancyObjects } from '../utils';
import { TenancyEntries } from './tenancy-entries';

export interface TenancySectionProps {
  tenancy: Tenancy | 'empty';
  maxListLength: number;
}

export function TenancySection({
  tenancy,
  maxListLength
}: TenancySectionProps) {
  const getTenancyObjects = useGetTenancyObjects();

  if (tenancy === 'empty') {
    return <EmptyTenancy />;
  }

  const tenancyObjects = getTenancyObjects(tenancy);
  return <TenancyEntries {...tenancyObjects} maxListLength={maxListLength} />;
}
