import * as React from 'react';
import { StatusTagMap } from 'src/modules/common/components/status-tag';
import { TaskStatusSelect } from '../../common/components/task-status-select';
import { QuoteStatus } from '../types/QuoteStatus';

export const taskStatusMap: StatusTagMap<QuoteStatus> = {
  requested: 'neutral',
  received: 'average',
  sent_for_approval: 'average',
  approved: 'good',
  rejected: 'bad',
  cancelled: 'neutral'
};

export function QuoteStatusSelect(props) {
  return (
    <TaskStatusSelect
      taskStatusMap={taskStatusMap}
      valueListId={'quote_status'}
      {...props}
    />
  );
}
