import { Generator, GeneratorConfig } from 'data/models/generator';
import { PaymentHistory } from 'src/modules/payment-history/types/payment-history';

export const paymentHistoryModelConfig: GeneratorConfig = {
  entities: {
    related: {
      object: {
        include: 'object'
      }
    }
  }
};

export const paymentHistoryModel = new Generator<PaymentHistory>(
  'financials/payment-history',
  paymentHistoryModelConfig
).createEntityModel();
