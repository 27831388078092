import { Ownership } from 'data/models/entities/ownerships';
import { Contact } from 'src/modules/contacts/types/contact-types';
import { FinancialPeriod } from 'src/modules/statements/types';
import { TaxSummary } from 'src/modules/tax/models/tax-summaries-model';
import { useRecordsQuery } from 'src/lib/react-query/hooks/use-records-query';

export const taxSummaryQueryKey = 'financials/tax-summaries';
export const useGetOwnersTaxSummaryForFinancialYear = (
  owner: Contact,
  ownership: Ownership,
  financialYear: FinancialPeriod
) => {
  const result = useRecordsQuery<TaxSummary>([
    taxSummaryQueryKey,
    {
      filter: [
        {
          op: 'and',
          value: [
            {
              field: 'ownership_id',
              op: '==',
              value: ownership.id
            },
            {
              field: 'contact_id',
              op: '==',
              value: owner.id
            },
            {
              field: 'type_id',
              op: '==',
              value: 'yearly'
            },
            {
              field: 'start_date',
              op: '==',
              value: financialYear.startDate
            },
            {
              field: 'end_date',
              op: '==',
              value: financialYear.endDate
            }
          ]
        }
      ],
      includes: ['ownership', 'contact']
    }
  ]);

  return { ...result, data: result.data?.length ? result.data[0] : null };
};
