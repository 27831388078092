import { query } from '@rexlabs/model-generator';
import { checklistTemplatesModel } from '../models/checklist-templates-model';

export const getChecklistTemplateDetailsQuery = (checklistId: string) => {
  return query`{
    ${checklistTemplatesModel} (id: ${checklistId}) {
      id,
      items,
      groups
    }
  }`;
};
