import { InspectionRunFinishFormValues } from '../components/inspection-run-finish-form';
import { InspectionRun } from '../types/InspectionRun';

export function mapInspectionRunFinishFormToInspectionRun(
  values: InspectionRunFinishFormValues
): Pick<
  InspectionRun,
  'finish_address' | 'finish_latitude' | 'finish_longitude'
> {
  return {
    finish_address: values.finish_address!.label,
    finish_latitude: values.finish_address!.data.lngLat[1].toString(),
    finish_longitude: values.finish_address!.data.lngLat[0].toString()
  };
}
