import React from 'react';

import { useErrorDialog } from '@rexlabs/dialog';
import { useModelActions } from '@rexlabs/model-generator';

import { RecordSubmitHandler } from 'view/components/record-screen/utils';
import { useToast } from 'view/components/@luna/notifications/toast';
import InfoCircleIcon from 'view/components/icons/info';

import {
  DEPART_AT_DATE_FORMAT,
  fetchRoute
} from 'src/lib/mapbox/utils/fetch-route';
import { fetchOptimisedRoute } from 'src/lib/mapbox/utils/fetch-optimised-route';
import { Coordinates } from 'src/lib/mapbox/types/Coordinates';
import { getLngLatFromProperty } from 'src/modules/properties/utils/get-lng-lat-from-property';

import { CreateInspectionRunBlockFormValues } from '../blocks/create-inspection-run-block';
import { inspectionRunsModel } from '../models/inspection-run-model';
import { getStartAtFromDayAndTime } from '../utils/get-start-at-from-day-and-time';
import { sortInspectionsFromOptimisedRoute } from '../utils/sort-inspections-from-optimised-route';
import { mapRouteToInspectionTask } from '../mappers/map-route-to-inspection-task';
import { getEndTimeFromRoute } from '../utils/get-end-time-from-route';
import { INSPECTION_SAVE_REQUEST_DATE_FORMAT } from '../constants/InspectionSaveRequestDateFormat';
import { getDuration } from '../constants/DefaultInspectionDuration';

export function useCreateInspectionRunSubmitHandler(): RecordSubmitHandler<CreateInspectionRunBlockFormValues> {
  const { open: openErrorDialog } = useErrorDialog();
  const { createItem } = useModelActions(inspectionRunsModel);
  const { addToast } = useToast();

  return async ({
    values: {
      start_address,
      finish_address,
      leave_from_start_time,
      inspection_date,
      inspections
    }
  }) => {
    try {
      const startLngLat: Coordinates = [
        start_address!.data.lngLat[0],
        start_address!.data.lngLat[1]
      ];

      const finishLngLat: Coordinates = [
        finish_address!.data.lngLat[0],
        finish_address!.data.lngLat[1]
      ];

      const startAt = getStartAtFromDayAndTime(
        leave_from_start_time!,
        inspection_date!
      );

      // Get optimised route
      const optimisedRoute = await fetchOptimisedRoute([
        startLngLat,
        ...inspections
          .filter((inspection) => !!getLngLatFromProperty(inspection.property))
          .map((inspection) => getLngLatFromProperty(inspection.property!)!),
        finishLngLat
      ]);

      const inspectionsInOptimalOrder = sortInspectionsFromOptimisedRoute(
        optimisedRoute,
        inspections
      );

      // Get route from start time
      const route = await fetchRoute(
        [
          startLngLat,
          ...inspectionsInOptimalOrder
            .filter(
              (inspection) => !!getLngLatFromProperty(inspection.property)
            )
            .map((inspection) => getLngLatFromProperty(inspection.property!)!),
          finishLngLat
        ],
        startAt.format(DEPART_AT_DATE_FORMAT)
      );

      const mappedInspections = mapRouteToInspectionTask(
        route.routes[0].legs,
        inspectionsInOptimalOrder,
        getDuration,
        startAt
      );

      const estimatedFinishTime = getEndTimeFromRoute(
        route.routes[0].legs,
        mappedInspections,
        startAt
      );

      const { data } = await createItem({
        data: {
          finish_address: finish_address?.label,
          finish_latitude: startLngLat[1].toString(),
          finish_longitude: startLngLat[0].toString(),
          finish_at: estimatedFinishTime.format(
            INSPECTION_SAVE_REQUEST_DATE_FORMAT
          ),
          start_address: start_address?.label,
          start_latitude: finishLngLat[1].toString(),
          start_longitude: finishLngLat[0].toString(),
          start_at: startAt.format(INSPECTION_SAVE_REQUEST_DATE_FORMAT),
          status: {
            id: 'open',
            label: 'Open'
          },
          inspection_tasks: mappedInspections
        },
        args: {
          include: [
            'inspection_tasks',
            'inspection_tasks.property',
            'inspection_tasks.property.address',
            'inspection_tasks.details'
          ].join(',')
        }
      });

      addToast({
        Icon: InfoCircleIcon,
        description: (
          <>
            <b>{inspections.length}</b> inspections have been scheduled for{' '}
            <b>{startAt.format('DD MMM YYYY')}</b>
          </>
        )
      });

      return data;
    } catch (error) {
      openErrorDialog(error);
    }
  };
}
