import React from 'react';
import { Helmet } from 'react-helmet';

import { Breadcrumbs } from 'view/components/@luna/breadcrumbs';
import { ListTitleCard, ListTitleCardProps } from 'view/components/cards/title';

import { padding, StyleSheet, useStyles } from '@rexlabs/styling';
import Box from '@rexlabs/box';
import { ListLayout } from '../layouts/list-layout/list-layout';
import { CardErrorBoundary } from '../record-screen/cards/error-boundary';
import { BreadcrumbsProps } from '../@luna/breadcrumbs/breadcrumbs';

interface ListScreenComponentProps
  extends Omit<ListTitleCardProps, 'children'> {
  breadcrumbs?: BreadcrumbsProps['breadcrumbs'];
  children: React.ReactNode;
}

const defaultStyles = StyleSheet({
  breadcrumbs: {
    ...padding.styles({
      bottom: 'l'
    })
  }
});

export function ListScreen({
  children,
  breadcrumbs = [],
  title,
  ...headerProps
}: ListScreenComponentProps) {
  const s = useStyles(defaultStyles);

  return (
    <>
      <Helmet title={title} />
      <ListLayout
        header={
          <>
            <Box {...s('breadcrumbs')}>
              <Breadcrumbs breadcrumbs={breadcrumbs} />
            </Box>
            <ListTitleCard title={title} {...headerProps} />
          </>
        }
      >
        <CardErrorBoundary>{children}</CardErrorBoundary>
      </ListLayout>
    </>
  );
}
