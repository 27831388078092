import { makeIconComponent } from '@rexlabs/icons';

import SmallBankingAccountSvg from 'assets/icons/s/banking-account.svg';
import MediumBankingAccountSvg from 'assets/icons/m/banking-account.svg';
import LargeBankingAccountSvg from 'assets/icons/l/banking-account.svg';
import ExtraLargeBankingAccountSvg from 'assets/icons/xl/banking-account.svg';

export const BankingAccountIcon = makeIconComponent({
  s: SmallBankingAccountSvg,
  m: MediumBankingAccountSvg,
  l: LargeBankingAccountSvg,
  xl: ExtraLargeBankingAccountSvg
});

export default BankingAccountIcon;
