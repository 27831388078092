import { useDialog } from '@rexlabs/dialog';
import { useModelActions } from '@rexlabs/model-generator';

import { SingleActionDeclaration } from 'src/modules/common/actions/types/action-declaration-types';
import {
  sharedIncludes,
  taskIncludes,
  tasksModel
} from 'src/modules/tasks/common/models/tasks-model';

import { api } from 'utils/api/api-client';
import { useGetHasRoomsOrAreas } from 'src/modules/tasks/inspections/hooks/useGetHasRoomsOrAreas';
import { useFeatureFlags } from 'view/components/@luna/feature-flags';
import { FLAGS } from 'utils/feature-flags';
import { PropertyAreaRecordDialog } from 'src/modules/property-areas/property-areas/dialogs/property-area-dialog';
import { InspectionTask } from '../../types/InspectionTask';
import { isReportInProgressOrLater } from '../../utils/inspection-report-helpers';
import { useGetPreviousInspectionReports } from '../use-get-previous-inspection-reports';
import { createNewInspectionReport } from '../../utils/create-new-inspection-report';
import { useCreateInspectionAreaSubmitHandler } from '../use-create-inspection-area-submit-handler';
import { useAddRoomsToInspectionReportSubmitHandler } from '../use-add-rooms-to-inspection-report-submit-handler';
import { InspectionReportAddAreasDialog } from '../../dialogs/inspection-report-add-rooms';

export function useGetInspectionReportCreateNewAction(
  inspectionTask: InspectionTask
) {
  const { refreshItem } = useModelActions(tasksModel);
  const roomDialog = useDialog(InspectionReportAddAreasDialog);
  const areaDialog = useDialog(PropertyAreaRecordDialog);
  const getHasRoomsOrAreas = useGetHasRoomsOrAreas();
  const { hasFeature } = useFeatureFlags();

  const addRoomsToInspectionReportSubmitHandler = useAddRoomsToInspectionReportSubmitHandler(
    inspectionTask
  );
  const createInspectionAreaSubmitHandler = useCreateInspectionAreaSubmitHandler(
    inspectionTask
  );

  const { data: previousInspectionReports } = useGetPreviousInspectionReports(
    inspectionTask
  );

  return (): SingleActionDeclaration => {
    return {
      id: 'inspection-report-create-new',
      intent: 'primary',
      group: 'inspection',
      label: 'Create report',
      handleAction: async () => {
        // If the user has started the report, added rooms, but then changed the state to not started,
        // we just want to change the status to 'report_in_progress' and not create a new report.
        // We can see this by checking if it has existing rooms.
        const shouldUpdateStatus = !!getHasRoomsOrAreas(inspectionTask);
        // If the task has no rooms, and the report has not been started, we want to create a new report.
        // note, to align with BE: report not started is the same as any status earlier than report_in_progress
        // if the user moves the status to 'inspection_in_progress', we should still create a new report.
        const shouldCreateNewInspectionReport =
          !getHasRoomsOrAreas(inspectionTask) &&
          !isReportInProgressOrLater(inspectionTask);

        if (shouldUpdateStatus) {
          await api.patch(`/tasks/${inspectionTask.id}/status`, {
            type: {
              id: 'task_inspection'
            },
            details: {
              status: {
                id: 'report_in_progress'
              }
            }
          });
        }

        if (shouldCreateNewInspectionReport) {
          /** @deprecated BE imports areas from properties by default now so
           * we should pass an empty object for import_source */
          const lastInspectionThatHasRooms = previousInspectionReports?.[0];

          // If the flag is off, we're passing the above lastInspectionThatHasRooms.
          // As above, with the recent BE updates, we want to pass nothing here since BE will import areas from properties as long as
          // no import is provided.
          // when the flag is removed, we should also remove the previousReportId parameter from the function
          await createNewInspectionReport(
            inspectionTask,
            hasFeature(FLAGS.ENTRY_EXIT_INSPECTION_REPORT)
              ? undefined
              : lastInspectionThatHasRooms?.id
          );
        }

        const { data: refreshedInspection } = await refreshItem({
          id: inspectionTask.id,
          args: {
            include: [
              ...sharedIncludes,
              taskIncludes.inspectionDetails,
              'property.active_property_ownership.ownership.owners.contact'
            ].join(',')
          }
        });

        // Finally, if there are no areas, we want to open the dialog to add rooms.
        if (!getHasRoomsOrAreas(refreshedInspection)) {
          if (hasFeature(FLAGS.ENTRY_EXIT_INSPECTION_REPORT)) {
            areaDialog.open({
              title: 'Add areas',
              initialValues: {},
              onSubmit: createInspectionAreaSubmitHandler
            });
          } else {
            roomDialog.open({
              title: 'Add areas',
              submitHandler: addRoomsToInspectionReportSubmitHandler
            });
          }
        }
      }
    };
  };
}
