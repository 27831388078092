import React from 'react';
import { Card, CardContent } from 'view/components/@luna/card';
import { useFeatureFlags } from 'view/components/@luna/feature-flags';
import { FLAGS } from 'utils/feature-flags';
import { useUpdateChecklistTemplateAction } from '../hooks/action-declarations/checklist-template/use-update-checklist-template-action';
import { getChecklistItems } from '../hooks/action-menu-items/use-get-checklist-template-actions';
import { useGetIsSmartChecklist } from '../hooks/use-get-is-smart-checklist';
import { ChecklistTemplatesForm } from '../types/ChecklistTemplatesForm';
import { ChecklistTemplateItemsBlockCardHeader } from './checklist-template-items-block-card-header';
import { ChecklistTemplateItemsEdit } from './checklist-template-items-edit';
import { ChecklistTemplateSubtask } from './checklist-template-subtask';
import { ChecklistTemplateItemList } from './smart-checklists/checklist-template-item-list';

export const ChecklistTemplateItemsBlockCard = (props) => {
  const { hasFeature } = useFeatureFlags();
  const getChecklistUpdateSubmit = useUpdateChecklistTemplateAction({
    shouldRefreshChecklistTemplate: true
  });

  const { data } = props;

  const [isEditing, setIsEditing] = React.useState(false);
  const [isSaving, setIsSaving] = React.useState(false);
  const checklistItems = getChecklistItems(data?.items?.data || []);

  const getIsSmartChecklist = useGetIsSmartChecklist();

  const handleSubmit = async (values: ChecklistTemplatesForm) => {
    const onSubmit = getChecklistUpdateSubmit(
      data?.id,
      getIsSmartChecklist(data?.task_type?.id)
    );
    setIsSaving(true);

    await onSubmit({
      values: values,
      changedValues: values,
      changedBlockIds: ['checklist']
    });

    setIsSaving(false);
    setIsEditing(false);
    return true;
  };

  return (
    <Card id={'checklist-items-card'}>
      <CardContent>
        <ChecklistTemplateItemsBlockCardHeader
          setIsEditing={setIsEditing}
          isEditing={isEditing}
          isSaving={isSaving}
        />
        {hasFeature(FLAGS.SMART_CHECKLISTS_UI_OVERHAUL) ? (
          <ChecklistTemplateItemList
            key={`last-update-${data.updated_at}`}
            isEditing={isEditing}
            items={checklistItems}
            groups={data?.groups?.data || []}
            taskType={data?.task_type?.id}
            handleSubmit={handleSubmit}
          />
        ) : (
          <>
            {isEditing ? (
              <ChecklistTemplateItemsEdit
                data={checklistItems}
                taskType={data?.task_type?.id}
                handleSubmit={handleSubmit}
              ></ChecklistTemplateItemsEdit>
            ) : (
              <>
                {checklistItems.map((item, index) => (
                  <ChecklistTemplateSubtask
                    checklistTemplateItem={item}
                    key={index}
                    index={index}
                  />
                ))}
              </>
            )}
          </>
        )}
      </CardContent>
    </Card>
  );
};
