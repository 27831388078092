import React from 'react';
import { Column, Grid } from '@rexlabs/grid';
import { Field } from '@rexlabs/form';
import { DateInput } from 'view/components/@luna/inputs/date-input/date-input';
import { CentAmountInput } from 'view/components/inputs/cent-amount';
import { Reconciliation } from 'data/models/entities/financials/reconciliations';
import { BlockConfig } from 'view/components/record-screen/types';
import { DateValue } from 'view/components/values/date';

interface BlockProps {
  reconciliation?: Reconciliation;
}

export const startDailyReconciliationBlock: BlockConfig<
  Reconciliation,
  BlockProps
> = {
  id: 'start-daily-reconciliation',
  validate: {
    definitions: {
      statement_date: { rules: 'required' },
      statement_balance: { rules: 'required' }
    }
  },
  Edit: ({ data, blockProps }) => (
    <Grid>
      <Column width={6}>
        {blockProps?.reconciliation?.type.id === 'end_of_month' ? (
          <DateValue value={data?.statement_date} label='Bank statement date' />
        ) : (
          <Field
            name='statement_date'
            label='Bank statement date'
            Input={DateInput}
          />
        )}
      </Column>
      <Column width={6}>
        <Field
          name='statement_balance'
          label='Closing balance'
          Input={CentAmountInput}
        />
      </Column>
    </Grid>
  )
};
