import { api } from 'utils/api/api-client';
import { InspectionTask } from '../types/InspectionTask';

export async function createNewInspectionReport(
  inspectionTask: InspectionTask,
  /** @deprecated BE imports areas from properties by default now. we should remove this when the entry-exit flag is switched on*/
  previousReportId?: string
) {
  return api.post('tasks/inspection-reports/', {
    task: { id: inspectionTask.id },
    ...(previousReportId ? { import_source: { id: previousReportId } } : {})
  });
}
