import { SingleActionDeclaration } from 'src/modules/common/actions/types/action-declaration-types';
import { useDialog } from '@rexlabs/dialog';
import { AddIcon } from 'view/components/icons/add';
import { Property } from 'src/modules/properties/types/property-types';
import { CreateTaskDialog } from '../../dialogs/create-task-dialog';

export interface GetCreateTaskActionArgs {
  property?: Property;
}

export function useGetCreateTaskAction() {
  const { open } = useDialog(CreateTaskDialog);

  return ({
    property
  }: GetCreateTaskActionArgs = {}): SingleActionDeclaration => {
    return {
      id: 'create-task',
      intent: 'primary',
      group: 'task',
      label: 'Create task',
      Icon: AddIcon,
      handleAction: async () => {
        open(property ? { initialPropertyValue: property } : {});
      }
    };
  };
}
