import React, { useMemo } from 'react';
import { BlockConfig } from 'view/components/record-screen/types';

import { useMediaQuery } from '@rexlabs/breakpoints';
import { InspectionAreaMobileEditForm } from 'src/modules/tasks/inspections/entry-exit/components/inspection-area-mobile-edit-form';
import { RecordTable } from 'view/components/table';
import Box from '@rexlabs/box';
import { InspectionTask } from '../../types/InspectionTask';
import { InspectionFormData } from '../../types/InspectionFormData';
import { ConditionLabelKey } from '../components/condition-label-key';
import { inspectionAreaViewColumns } from '../utils/inspection-area-view-columns';
import { getInspectionAreaEditColumns } from '../utils/get-inspection-area-edit-columns';
import { EntryExitInspectionReportAreaTitle } from '../components/entry-exit-report-area-title';

export function getEntryExitInspectionAreaBlocks(
  data: InspectionTask
): BlockConfig<InspectionTask>[] {
  const inspectionReportRoomBlocks: BlockConfig<InspectionTask>[] = (
    data?.details?.areas?.data || []
  ).map((area) => ({
    ...getInspectionReportRoomBlock(area.id),
    title: ({ editMode }) => (
      <EntryExitInspectionReportAreaTitle editMode={editMode} area={area} />
    )
  }));

  return inspectionReportRoomBlocks;
}

export function getInspectionReportRoomBlock(
  areaId: string
): BlockConfig<InspectionTask, any, InspectionFormData> {
  return {
    id: `entry-exit-area-${areaId}`,
    isMobileForcedCollapsed: true,
    validate: {
      definitions: {}
    },
    isEditable: (data) =>
      !['report_completed', 'report_sent'].includes(
        data?.details?.status?.id || ''
      ),
    View: ({ data }) => {
      return (
        <>
          <RecordTable
            isLoading={false}
            items={
              data?.details?.areas?.data.find((area) => area.id === areaId)
                ?.items?.data || []
            }
            columns={inspectionAreaViewColumns}
          />
          <ConditionLabelKey />
        </>
      );
    },
    Edit: ({ data, setFieldValue }) => {
      const columns = useMemo(() => {
        return getInspectionAreaEditColumns({ setFieldValue });
      }, []);

      const matchesMobile = useMediaQuery({ maxWidth: 's' });
      const inspectionArea = data?.details?.areas?.data?.find?.(
        (area) => area.id === areaId
      );

      return (
        <DeferMount>
          {matchesMobile ? (
            <InspectionAreaMobileEditForm inspectionArea={inspectionArea} />
          ) : (
            <>
              <RecordTable
                isLoading={false}
                items={inspectionArea?.items?.data || []}
                columns={columns}
              />
              <ConditionLabelKey />
            </>
          )}
        </DeferMount>
      );
    }
  };
}

function DeferMount({ children }) {
  const [render, setRender] = React.useState(false);

  React.useEffect(() => {
    window.requestAnimationFrame(() => {
      window.requestAnimationFrame(() => setRender(true));
    });
  }, []);

  return render ? (
    <>{children}</>
  ) : (
    <Box marginTop={24} marginBottom={24} justifyContent='center'>
      Loading...
    </Box>
  );
}
