import React from 'react';

import { TitleBlock } from 'view/components/cards/title/title-block';

import { RecordObject, RecordType } from 'data/models/types';
import { ChargeEntry } from 'data/models/entities/financials/charge-entries';
import { getRecordTitle } from 'utils/records/get-record-title';

const type: RecordType = 'charge_entry';

type ChargeEntryTitleBlockProps = {
  chargeEntry: ChargeEntry;
};

export function ChargeEntryTitleBlock({
  chargeEntry
}: ChargeEntryTitleBlockProps) {
  const title = getRecordTitle({ type, object: chargeEntry } as RecordObject);

  return <TitleBlock title={title} type={type} />;
}
