import React from 'react';
import { EmptySection } from './base';

export function EmptyPropertyTenancy() {
  return (
    <EmptySection
      headline='Relate a tenancy to this record'
      buttonLabel='View tenancies'
      linkProps={{ query: { tab: 'lease-agreements' } }}
    >
      Choose from an existing tenancy or create a new tenancy for this record.
    </EmptySection>
  );
}
