import React from 'react';

import { TagCell } from '@rexlabs/table';
import { StatusAverageTag, StatusGoodTag } from '@rexlabs/tag';

function getStatusTag(value) {
  const { id } = value;

  switch (id) {
    case 'checked_out':
      return <StatusAverageTag>Checked out</StatusAverageTag>;
    default:
      return <StatusGoodTag>Checked in</StatusGoodTag>;
  }
}

export function StatusCell({ value }: { value: string }) {
  return <TagCell>{getStatusTag(value)}</TagCell>;
}
