import * as React from 'react';
import { BREADCRUMBS } from 'view/components/@luna/breadcrumbs';
import { RecordScreen } from 'view/components/record-screen';
import { ContentConfig } from 'view/components/record-screen/types';
import { RecordSubmitHandler } from 'view/components/record-screen/utils';
import { useEntityQuery } from '@rexlabs/model-generator';
import { useRecordScreenSubmitHandler } from 'view/hooks/use-record-screen-submit-handler';
import { ChecklistTemplateTitleBlock } from '../components/checklist-template-title-block';
import { getChecklistTemplateDetailsQuery } from '../queries/checklist-template-details-query';
import { checklistTemplateDetailsBlock } from '../blocks/checklist-template-details-block';
import { checklistTemplateItemsBlock } from '../blocks/checklist-template-items-block';
import { mapChecklistTemplateToChecklistTemplateFormData } from '../mappers/map-checklist-template-to-checklist-template-form-data';
import { mapEditChecklistTemplateFormToRequest } from '../mappers/map-edit-checklist-template-form-to-request';

const content: ContentConfig = [
  {
    label: 'Details',
    items: [
      {
        id: 'overview',
        label: 'overview',
        blocks: [checklistTemplateDetailsBlock, checklistTemplateItemsBlock]
      }
    ]
  }
];

interface ChecklistDetailsScreenProps {
  checklistId: string;
}

export function ChecklistTemplateDetail(props: ChecklistDetailsScreenProps) {
  const breadcrumbs = [{ type: BREADCRUMBS.TASK_SETTINGS }];
  const checklistId = props?.checklistId;

  const query = React.useMemo(
    () => getChecklistTemplateDetailsQuery(checklistId),
    [checklistId]
  );

  const { data, status, actions } = useEntityQuery(query);

  const handleSubmit: RecordSubmitHandler = useRecordScreenSubmitHandler(
    async ({ values }) => {
      const dataToSubmit = mapEditChecklistTemplateFormToRequest(values);

      await actions.updateItem({
        id: checklistId,
        data: dataToSubmit
      });
    }
  );

  const initialValues =
    data && mapChecklistTemplateToChecklistTemplateFormData(data);

  return (
    <RecordScreen
      isLoading={status === 'loading'}
      data={data}
      initialValues={initialValues}
      content={content}
      handleSubmit={handleSubmit}
      titleBlock={<ChecklistTemplateTitleBlock checklistTemplate={data} />}
      breadcrumbs={breadcrumbs}
    />
  );
}
