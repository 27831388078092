import React from 'react';
import { isEmpty } from 'lodash';

import { Field } from '@rexlabs/form';
import { Body, Bold } from '@rexlabs/text';

import EmptyTable from 'assets/illustrations/empty-table.svg';

import { pluralize } from 'utils/formatters';

import { Message } from 'view/components/@luna/message';
import { EmptyCard } from 'view/components/record-screen/cards/empty-card';
import { BlockConfig } from 'view/components/record-screen/types';
import { Column, Grid } from 'view/components/@luna/form/grid';
import { Value } from 'view/components/values';
import { TimePeriodInput } from 'view/components/inputs/time-period-input';

export const supplierComplianceTaskSettingsBlock: BlockConfig = {
  id: 'supplier-compliance-task',
  title: 'Supplier compliance',
  validate: {
    definitions: {
      'supplier-compliance-task.unit': {
        name: 'create task unit',
        rules: 'required'
      },
      'supplier-compliance-task.count': {
        name: 'create task count',
        rules: 'required|min:1'
      }
    }
  },
  showEmpty: (data) => isEmpty(data?.['supplier-compliance-task']),
  Empty: ({ onEditClick }) => {
    return (
      <EmptyCard>
        <Message
          title='Supplier compliance task creation'
          Illustration={EmptyTable}
          actions={[
            {
              label: 'Get started',
              intent: 'primary',
              handleAction: onEditClick
            }
          ]}
        >
          <div>
            How long before a requirement expires should the task be created?
          </div>
        </Message>
      </EmptyCard>
    );
  },
  Edit: () => {
    return (
      <Grid columns={1}>
        <Bold>Automatic task creation</Bold>

        <Field
          label='Create a supplier compliance task'
          name='supplier-compliance-task'
          Input={TimePeriodInput}
          inputProps={{
            testId: 'supplier-compliance-task',
            countName: 'supplier-compliance-task.count',
            unitName: 'supplier-compliance-task.unit',
            Suffix: <Body>before requirement expires</Body>
          }}
          optional={false}
        />
      </Grid>
    );
  },
  View: ({ data }) => {
    return (
      <Grid columns={1}>
        <Bold>Automatic task creation</Bold>
        <Grid columns={2}>
          <Column width={2}>
            <Value
              label='Create a supplier compliance task'
              value={`${pluralize(
                data?.['supplier-compliance-task'].unit,
                data?.['supplier-compliance-task'].count,
                true
              )} before requirement expires`}
            />
          </Column>
        </Grid>
      </Grid>
    );
  }
};
