import React from 'react';

import { useDialog } from '@rexlabs/dialog';
import { StyleSheet, text, useStyles } from '@rexlabs/styling';

import { SearchResultItem } from 'utils/api/get-search-results';

import { AttachDocumentsFromARecordDialog } from '../../../dialogs/attach-documents-from-a-record-dialog';
import { AttachmentItem } from '../../../mappers/types/create-message-form-data';
import { SUPPORTED_RECORD_TYPES_FOR_SELECT_RELATES_TO } from '../../../constants/SupportedRecordTypesForSelectRelatesTo';
import { DocumentRelatedContextObject } from '../../../types/attach-document-from-a-record';
import { UploadAttachments } from './upload-attachments';

const defaultStyles = StyleSheet({
  secondaryButtonLabel: {
    ...text.styles({
      target: 'fileUpload.simple.label',
      fallback: 'normal.semibold',
      textDecoration: () => 'underline'
    })
  }
});

export function UploadAttachmentsWithAttachRecordDocuments({
  setFieldValue,
  attachments,
  relatesTo
}) {
  return (
    <UploadAttachments
      secondaryButtonLabel={
        <SecondaryButtonLabel
          attachments={attachments}
          relatesTo={relatesTo}
          setFieldValue={setFieldValue}
        />
      }
    />
  );
}

function SecondaryButtonLabel({ setFieldValue, attachments, relatesTo }) {
  const s = useStyles(defaultStyles);

  const { open: openDocumentsFromRecordDialog } = useDialog(
    AttachDocumentsFromARecordDialog
  );

  return (
    <span>
      or,{' '}
      <span
        onClick={(event) => {
          event.preventDefault();
          openDocumentsFromRecordDialog({
            onSubmit: (selectedItems) => {
              const newInlineAttachments: AttachmentItem[] = selectedItems.map(
                (item) => ({
                  data: Promise.resolve({
                    data: item.file
                  }),
                  file: item.file,
                  uuid: item.id
                })
              );

              setFieldValue?.(
                'attachments',
                attachments.concat(newInlineAttachments)
              );
            },
            initialValues: {
              relates_to:
                relatesTo &&
                SUPPORTED_RECORD_TYPES_FOR_SELECT_RELATES_TO.includes(
                  relatesTo?.type.id
                )
                  ? (relatesTo as SearchResultItem<DocumentRelatedContextObject>)
                  : undefined
            }
          });
          return false;
        }}
        {...s('secondaryButtonLabel')}
      >
        attach documents from a record
      </span>
    </span>
  );
}
