import React, { useEffect, useState } from 'react';

import { useModelActions } from '@rexlabs/model-generator';
import { OutlineButton } from '@rexlabs/button';
import { useErrorDialog } from '@rexlabs/dialog';

import { AutoFocusDialogProps, Dialog } from 'view/components/@luna/dialog';
import { StandardDialogFooter } from 'view/components/dialogs/components/standard-dialog-footer';

import { auditLogsModel } from '../models/audit-logs-model';
import { AuditLogDetailsView } from '../components/audit-log-details-view';
import { AuditLog } from '../types/AuditLog';

type AuditLogDetailsDialogProps = AutoFocusDialogProps & {
  auditLogId: string;
};

export function AuditLogDetailsDialog({
  onClose,
  auditLogId
}: AuditLogDetailsDialogProps) {
  const [isFetching, setIsFetching] = useState(false);
  const [auditLog, setAuditLog] = useState<AuditLog | undefined>(undefined);

  const { open: openErrorDialog } = useErrorDialog();

  const { fetchItem } = useModelActions(auditLogsModel);

  // HACK: This is a workaround to make sure the app doesn't crash when we get a 403.
  // When attempting to use useEntityQuery, the error crashes the app.
  useEffect(() => {
    if (!auditLog && !isFetching) {
      const fetchAuditLog = async () => {
        try {
          setIsFetching(true);

          const response = await fetchItem({
            id: auditLogId,
            args: { include: 'changeset' }
          });

          setAuditLog(response.data);

          setIsFetching(false);
        } catch (error) {
          openErrorDialog(error);
          onClose?.();
        }
      };

      fetchAuditLog();
    }
  }, [auditLog, auditLogId, fetchItem, isFetching, onClose, openErrorDialog]);

  return (
    <Dialog title='Audit log details' isLoading={!auditLog} onClose={onClose}>
      <AuditLogDetailsView auditLog={auditLog} />
      <StandardDialogFooter>
        <OutlineButton onClick={onClose}>Okay</OutlineButton>
      </StandardDialogFooter>
    </Dialog>
  );
}
