import { InspectionTask } from 'src/modules/tasks/inspections/types/InspectionTask';
import { hasReportBeenCompleted } from 'src/modules/tasks/inspections/utils/inspection-report-helpers';
import { useGetHasRoomsOrAreas } from 'src/modules/tasks/inspections/hooks/useGetHasRoomsOrAreas';

export function useGetHasReportBeenCompletedWithoutRooms() {
  const getHasRoomsOrAreas = useGetHasRoomsOrAreas();
  return (inspectionTask: InspectionTask) =>
    hasReportBeenCompleted(inspectionTask) &&
    !getHasRoomsOrAreas(inspectionTask);
}
