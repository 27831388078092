import React from 'react';
import { Link } from '@rexlabs/whereabouts';
import { BreadcrumbArgs, BreadcrumbDeclaration } from './constants';

interface CrumbProps extends BreadcrumbDeclaration {
  args?: BreadcrumbArgs;
}

export function Crumb({ getLabel, getLink, args }: CrumbProps) {
  const label = getLabel(args ?? {});
  const linkProps = getLink(args ?? {});

  return <Link {...linkProps}>{label}</Link>;
}
