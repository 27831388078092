import { ValueListValue } from 'data/models/types';
import React, { useEffect } from 'react';
import {
  ValueListSelect as CoreValueListSelect,
  ValueListSelectProps
} from 'view/components/inputs/selects/value-list';

function injectValueProperty(input: ValueListValue<string>) {
  return { ...input, value: input.id };
}

export function ValueListSelect({
  op,
  value,
  onChange,
  onBlur,
  ...props
}: ValueListSelectProps & {
  op?: { value: string; label: string };
}) {
  const multi = !!op?.value && ['in', 'nin'].includes(op.value);

  // HACK: this resets the select when toggling between multi select and single select
  // based on the operation type
  const val = React.useMemo(() => {
    if (multi && !Array.isArray(value)) {
      return value ? [value] : [];
    }
    if (!multi && Array.isArray(value)) {
      return null;
    }
    return value || null;
  }, [multi, value]);

  useEffect(() => {
    if (val !== value) {
      // @ts-ignore
      onChange?.({ target: { value: val } });
      // @ts-ignore
      onBlur?.({ target: { value: val } });
    }
  }, [onBlur, onChange, val, value]);

  return (
    // @ts-ignore
    <CoreValueListSelect
      {...props}
      multi={multi}
      value={val}
      onBlur={(e) => {
        return onBlur?.({
          target: {
            value: Array.isArray(e.target.value)
              ? e?.target?.value.map((item) => injectValueProperty(item))
              : injectValueProperty(e?.target?.value)
          }
        });
      }}
      onChange={(e) => {
        return onChange?.({
          target: {
            value: Array.isArray(e.target.value)
              ? e?.target?.value.map((item) => injectValueProperty(item))
              : injectValueProperty(e?.target?.value)
          }
        });
      }}
    />
  );
}
