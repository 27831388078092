import React, { CSSProperties, PropsWithChildren } from 'react';
import { StyleSheet, useStyles } from '@rexlabs/styling';

import { Column, MQ_SINGLE_WIDTH } from './column';
import { FormGridContext } from './context';

const defaultStyles = StyleSheet({
  container: {
    width: 'auto',
    display: 'grid',
    gridTemplateColumns: `1fr`,
    columnGap: ({ token }) => token('spacing.xl'),
    rowGap: ({ token }) => token('spacing.xl')
  },

  container2: {
    gridTemplateColumns: `1fr 1fr`,

    [`@media (max-width: ${MQ_SINGLE_WIDTH})`]: {
      gridTemplateColumns: `1fr`
    }
  },

  container3: {
    gridTemplateColumns: `1fr 1fr 1fr`,

    '@media (max-width: 800px)': {
      gridTemplateColumns: `1fr`
    }
  },

  container4: {
    gridTemplateColumns: `1fr 1fr 1fr 1fr`,

    '@media (max-width: 900px)': {
      gridTemplateColumns: `1fr 1fr`
    },

    [`@media (max-width: ${MQ_SINGLE_WIDTH})`]: {
      gridTemplateColumns: `1fr`
    }
  },
  container6: {
    gridTemplateColumns: `1fr 1fr 1fr 1fr 1fr 1fr`,

    '@media (max-width: 900px)': {
      gridTemplateColumns: `1fr 1fr`
    },

    [`@media (max-width: ${MQ_SINGLE_WIDTH})`]: {
      gridTemplateColumns: `1fr`
    }
  }
});

export type GridColumns = 1 | 2 | 3 | 4 | 6;

export interface GridProps {
  style?: CSSProperties;
  className?: string;
  columns: GridColumns;
}

export function Grid({
  style,
  className,
  columns,
  ...props
}: PropsWithChildren<GridProps>) {
  const s = useStyles(defaultStyles);
  return (
    <FormGridContext.Provider value={columns}>
      <div
        {...s.with('container', `container${columns}`)({ style, className })}
        {...props}
      />
    </FormGridContext.Provider>
  );
}

export { Column };
