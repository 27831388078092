import React from 'react';

import Box from '@rexlabs/box';
import { Heading } from '@rexlabs/text';
import { TextCell } from '@rexlabs/table';

import ROUTES from 'routes/app';

import { Card, CardContent } from 'view/components/@luna/card';
import { Columns, RecordTable } from 'view/components/table';

import { getFinancialYearId } from '../utils/get-financial-year-id';
import { getFinancialYearAbbreviationString } from '../utils/get-financial-year-abbreviation-string';
import { QuarterlyPayments } from '../types/quarterly-payments';
import { IncompletePeriodTagCell } from './incomplete-period-tag-cell';

// NOTE: Uncomment this line when the financial year statement details page is implemented
// import { push } from '@rexlabs/whereabouts';
// import { ViewItemCell } from './view-item-cell';

const columns: Columns = [
  {
    id: 'financial_year',
    Header: 'Financial year',
    Cell: (props) => {
      const { year_start, year_end } = props.row.original;

      return (
        <TextCell
          {...props}
          value={`FY ${getFinancialYearAbbreviationString(
            year_start,
            year_end
          )}`}
        />
      );
    }
  },
  {
    id: 'status',
    accessor: (item) => item,
    Cell: (props) => {
      const endDate = props.value?.year_end;

      return <IncompletePeriodTagCell periodEndDate={endDate} />;
    },
    width: 200
  },
  { id: 'year_start', Header: 'Year start', type: 'date', width: 180 },
  { id: 'year_end', Header: 'Year end', type: 'date', width: 180 },
  {
    id: 'total_payments_issued',
    Header: 'Total payments issued',
    type: 'currency'
  }
  // NOTE: Hiding for now as it's not implemented
  // {
  //   Cell: ({ value }) => {
  //     const id = getFinancialYearId(value.year_start);

  //     return (
  //       <ViewItemCell
  //         handleClick={() =>
  //           push(
  //             ROUTES.HMRC_REPORTS_AND_SUBMISSIONS_FINANCIAL_YEAR_STATEMENTS_DETAILS,
  //             {
  //               params: { id }
  //             }
  //           )
  //         }
  //       />
  //     );
  //   },
  //   id: 'view',
  //   width: 80,
  //   accessor: (item) => item
  // }
];

export function FinancialYearStatementCard({
  financialStartDate,
  financialEndDate,
  data,
  isLoading
}: {
  financialStartDate: string;
  financialEndDate: string;
  data: QuarterlyPayments[];
  isLoading: boolean;
}) {
  const id = getFinancialYearId(financialStartDate);

  function getRowLinkProps() {
    return {
      to: ROUTES.HMRC_REPORTS_AND_SUBMISSIONS_FINANCIAL_YEAR_STATEMENTS_DETAILS,
      params: {
        id
      }
    };
  }

  return (
    <Card>
      <CardContent>
        <Box
          flexDirection='row'
          alignItems='center'
          justifyContent='space-between'
          sx='1.2rem'
          flex={1}
        >
          <Heading level={3}>Financial year statement</Heading>
        </Box>
        <RecordTable
          id='financial-year-statement'
          getRowLinkProps={getRowLinkProps}
          columns={columns}
          isLoading={isLoading}
          items={
            data.length
              ? [
                  {
                    id: id,
                    year_start: financialStartDate,
                    year_end: financialEndDate,
                    total_payments_issued: data.reduce(
                      (total, quarterlyPayment) =>
                        quarterlyPayment.payments_issued + total,
                      0
                    )
                  }
                ]
              : []
          }
        />
      </CardContent>
    </Card>
  );
}
