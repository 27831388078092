import React, { HTMLAttributes, PropsWithChildren, useCallback } from 'react';

import { Field, HiddenField } from '@rexlabs/form';

import { useInsertToTextInput } from 'src/modules/communications/common/hooks/use-insert-to-input';
import { CommunicationContextType } from 'src/modules/communications/common/types/communication-context-type';

import { FroalaEditorInput } from 'view/components/@luna/inputs/froala-editor/components/froala-editor-input';
import { useAddFilesForEditor } from 'view/components/@luna/inputs/froala-editor/hooks/use-add-files-for-editor';
import { useInsertToEditor } from 'view/components/@luna/inputs/froala-editor/hooks/use-insert-to-editor';
import { TextArea } from 'view/components/inputs/text-area/text-area';
import { EditMessageContentContainer } from './edit-message-content-container';

export interface BasicEditLetterFormProps {
  contextType?: CommunicationContextType;
  textContentFieldName: string;
  setFieldValue?: (field: string, value: any) => void;
}

/**
 * Basic form for editing letter content. It has two columns:
 *   - first column includes the letter address and content fields
 *   - second column includes the merge tag component
 */
export function BasicEditLetterForm({
  setFieldValue,
  contextType,
  // This can vary between template creation and letter creation
  textContentFieldName,
  children
}: PropsWithChildren<BasicEditLetterFormProps>) {
  const { setFileIdToAdd, setFileIdToRemove } = useAddFilesForEditor({
    fileField: 'linked_files'
  });

  const updateAddressValue = useCallback(
    (value: string) => {
      setFieldValue?.('letterAddress', value);
    },
    [setFieldValue]
  );

  const {
    inputRef,
    shouldInsertToInput,
    setShouldInsertToInput,
    insertToTextInput
  } = useInsertToTextInput<HTMLTextAreaElement>(updateAddressValue);

  const { editorRef, insertToEditor } = useInsertToEditor();

  return (
    <EditMessageContentContainer
      mergeTagsConfig={{
        channelType: 'letter',
        contextType,
        onMergeTagDoubleClick: shouldInsertToInput
          ? insertToTextInput
          : insertToEditor
      }}
    >
      {/* NOTE: this is because if you put the onClick on the textarea, it breaks the ability to add newlines for some reason. */}
      <div onClick={() => setShouldInsertToInput(true)}>
        <Field
          name='letterAddress'
          Input={TextArea}
          label='Letter Address'
          inputProps={
            {
              ref: inputRef
            } as HTMLAttributes<HTMLTextAreaElement>
          }
        />
      </div>

      <Field
        name={textContentFieldName}
        Input={FroalaEditorInput}
        inputProps={{
          onClick: () => setShouldInsertToInput(false),
          onImageAdded: setFileIdToAdd,
          onImageRemoved: setFileIdToRemove,
          ref: editorRef
        }}
      />

      {/*Images uploaded within the letter content input are stored in this field*/}
      <HiddenField name={'linked_files'} />

      {children}
    </EditMessageContentContainer>
  );
}
