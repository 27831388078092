import { ManualsCell } from 'src/modules/appliances/components/manuals-cell';
import { Columns } from 'src/view/components/table';
import { MakeAndModelCell } from '../components/make-and-model-cell';
import { NameCell } from '../components/name-cell';
import { ReceiptsCell } from '../components/receipts-cell';
import { WarrantyExpiryCell } from '../components/warranty-expiry-cell';
import { Appliance } from '../types/Appliance';

export const applianceColumns: Columns<Appliance> = [
  {
    id: 'name',
    Header: 'Name',
    width: 250,
    accessor: (item) => item,
    Cell: NameCell
  },
  {
    id: 'make-and-model',
    Header: 'Make and model',
    width: 160,
    accessor: (item) => item,
    Cell: MakeAndModelCell
  },
  {
    id: 'date-installed',
    Header: 'Date installed',
    width: 160,
    type: 'date',
    accessor: (item) => item.installed_date
  },
  {
    id: 'warranty-expires',
    Header: 'Warranty expires',
    width: 160,
    accessor: (item) => item,
    Cell: WarrantyExpiryCell
  },
  {
    id: 'receipt',
    Header: 'Receipt',
    width: 85,
    Cell: ReceiptsCell,
    accessor: (item) => item
  },
  {
    id: 'manual',
    Header: 'Manual',
    width: 85,
    Cell: ManualsCell,
    accessor: (item) => item
  }
];
