import React from 'react';
import { ActionButtons } from 'view/components/@luna/action-buttons';

import { BlockConfig } from 'view/components/record-screen/types';

import { useModelActions } from '@rexlabs/model-generator';
import { MaintenanceTask } from '../../maintenance/types/MaintenanceTask';
import { WorkOrdersList } from '../components/work-orders-list';
import { useGetCreateWorkOrderAction } from '../hooks/action-declarations/use-get-create-work-order-action';
import { tasksModel } from '../../common/models/tasks-model';
import { WorkOrderTask } from '../types/WorkOrderTask';

export const workOrdersListBlock: BlockConfig<MaintenanceTask> = {
  id: 'work-orders',
  title: 'Work orders',
  View: ({ data }) => {
    return <WorkOrdersList relatedTask={data!} />;
  },
  Actions: ({ data }) => {
    const { refreshItem } = useModelActions(tasksModel);

    const getCreateWorkOrderAction = useGetCreateWorkOrderAction({
      // refresh the parent to show changes to its status
      onCreate: (data: WorkOrderTask) => {
        data.parent_task && refreshItem({ id: data.parent_task.id });
      }
    });

    const { handleAction, label } = getCreateWorkOrderAction(data);

    return (
      <ActionButtons
        actions={[
          {
            label,
            handleAction
          }
        ]}
      />
    );
  }
};
