import Validator from '@rexlabs/validator';
import { DateValue } from 'view/components/inputs/checklist-item-due-date-input/checklist-item-due-date-input';

Validator.register(
  'fullDueDateIsRequired',
  function (this: any, value: DateValue) {
    if (value?.due_date_type === 'none') return true;

    if (value?.due_date_type === 'related_date' && value?.related_date)
      return true;

    if (
      value?.due_date_type === 'offset_related_date' &&
      value?.offset_related_date &&
      value?.offset_related_date?.count &&
      value?.offset_related_date?.unit &&
      value?.offset_related_date?.offset
    )
      return true;

    if (value?.due_date_type === 'specific_date' && value?.due_date)
      return true;

    return false;
  },
  'The subtask due date field is required.'
);

Validator.register(
  'cannotBeOnDueDateIfDueDateIsNone',
  function (this: any, value: any) {
    if (this.validator?.input?.due_date.due_date_type === 'none') {
      if (value?.trigger_type?.id === 'on_due_date') return false;
    }

    return true;
  },
  'The trigger type cannot be "On due date" if the due date is set to "None".'
);
