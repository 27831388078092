import dayjs from 'dayjs';
import { ChecklistTemplateItem } from '../types/ChecklistTemplate';
import { ChecklistTemplateItemTriggerEditFrom } from '../types/ChecklistTemplatesForm';

/**
 * Converts an checklist template item to an edit checklist template item trigger form data object.
 */
export function mapChecklistTemplateItemToChecklistTemplateItemTriggerFormData({
  checklistTemplateItem
}: {
  checklistTemplateItem: Partial<ChecklistTemplateItem>;
}): Partial<ChecklistTemplateItemTriggerEditFrom> {
  const timeHour = checklistTemplateItem.trigger_data?.time_hour;

  return {
    trigger: {
      trigger_type: checklistTemplateItem.trigger_type || null,
      time: timeHour
        ? {
            label: dayjs().hour(timeHour).minute(0).format('h:mma'),
            id: timeHour
          }
        : null
    },
    action: checklistTemplateItem.action_type || null,
    template: checklistTemplateItem.action_message_template || null,
    task_status: checklistTemplateItem.action_data?.task_status || null,
    message_channel_types:
      checklistTemplateItem.action_data?.message_channel_types || null
  };
}
