import React from 'react';

import { useStyles, StyleSheet } from '@rexlabs/styling';
import { Heading } from '@rexlabs/text';

const defaultStyles = StyleSheet({
  heading: {
    color: ({ token }) => token('color.textStyle.primary.idle.default')
  }
});

export function PrimaryHeading(props) {
  const s = useStyles(defaultStyles);
  return <Heading {...s.with('heading')(props)} {...props} />;
}
