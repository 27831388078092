import React from 'react';

import { Truncate } from '@rexlabs/text';
import { margin, StyleSheet, useStyles } from '@rexlabs/styling';
import { CellProps } from '@rexlabs/table';

const defaultStyles = StyleSheet({
  subText: {
    fontSize: '1.2rem',
    color: ({ token }) => token('color.textStyle.body.subtext')
  },

  secondLine: {
    ...margin.styles({
      top: 'xxs'
    })
  }
});

export function SummaryCell({ value }: CellProps) {
  const s = useStyles(defaultStyles);

  return (
    <div>
      <Truncate>{value?.summary ?? '--'}</Truncate>
      {value?.property && (
        <div {...s('secondLine')}>
          <Truncate {...s('subText')}>
            {value?.property?.address_string}
          </Truncate>
        </div>
      )}
    </div>
  );
}
