import { useMemo } from 'react';
import { map, reduce } from 'lodash';

import { useRecordsQuery } from 'src/lib/react-query/hooks/use-records-query';
import { MessageTemplate } from 'src/modules/communications/message-templates/types/MessageTemplate';

import { MessageTriggerData } from '../types/MessageTriggerData';
import { useMessageTriggerSettings } from '../hooks/use-message-trigger-settings';

export function useMapMessageTriggerSettingsToForm(): MessageTriggerData {
  const { messageTriggerSettings } = useMessageTriggerSettings();

  const messageTriggerTemplateIds = map(
    messageTriggerSettings,
    'template_id'
  ).filter(Boolean);

  const { data: templateData } = useRecordsQuery<MessageTemplate>(
    [
      'communication/message-templates',
      {
        filter: [
          {
            op: 'or',
            value: messageTriggerTemplateIds.map((id) => ({
              field: 'id',
              op: 'eq',
              value: id,
              logicalOperator: 'or'
            }))
          }
        ],
        includes: ['channels', 'attachments']
      }
    ],
    {
      enabled: !!messageTriggerTemplateIds.length,
      staleTime: 1 * 60,
      cacheTime: 1 * 60
    }
  );

  return useMemo(
    () =>
      reduce(
        messageTriggerSettings,
        (acc, value, key) => {
          const { template_id } = value;
          acc[key] = templateData?.length
            ? templateData.find((template) => template.id === template_id)
            : null;

          return acc;
        },
        {} as MessageTriggerData
      ),
    [messageTriggerSettings, templateData]
  );
}
