import React from 'react';

import { Tag } from '@rexlabs/tag';
import { Truncate } from '@rexlabs/text';
import { CellProps } from '@rexlabs/table';

import { ContactPaymentMethod } from 'data/models/entities/contacts/payment-methods';

type ContactPaymentMethodNameCellProps = CellProps<any, ContactPaymentMethod>;

export function ContactPaymentMethodNameCell({
  value
}: ContactPaymentMethodNameCellProps) {
  const isDefault = value.is_default;
  return (
    <div className='flex items-center'>
      <Truncate>{value.name}</Truncate>
      {isDefault && (
        <span className='ml-s'>
          <Tag>Default</Tag>
        </span>
      )}
    </div>
  );
}
