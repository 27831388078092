import React from 'react';

import { RecordType } from 'data/models/types';
import { RecordCell } from '@rexlabs/table';
import { getRecordLabel } from 'utils/records/get-record-label';

export function getAlfredRecordCell(type: RecordType) {
  return {
    toCsv: (item: any) => {
      return getRecordLabel({ type: type as any, object: item });
    },
    Cell: ({ value, ...props }) => (
      <RecordCell
        {...props}
        value={
          value
            ? getRecordLabel({ type: type as any, object: value })
            : undefined
        }
      />
    )
  };
}
