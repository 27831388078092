import React from 'react';

import { useConfirmationDialog } from '@rexlabs/dialog';
import { useModelActions } from '@rexlabs/model-generator';

import { ActionDeclaration } from 'src/modules/common/actions/types/action-declaration-types';

import { useToast } from 'view/components/@luna/notifications/toast';

import { inspectionRunsModel } from '../../models/inspection-run-model';
import { InspectionRun } from '../../types/InspectionRun';

export function useGetCancelInspectionRunAction() {
  const { open } = useConfirmationDialog();
  const { trashItem, refreshLists } = useModelActions(inspectionRunsModel);
  const { addToast } = useToast();

  return (inspectionRun: InspectionRun): ActionDeclaration => ({
    id: 'cancel-inspection-run',
    group: 'delete',
    label: 'Cancel inspection run',
    handleAction: () => {
      open({
        title: 'Cancel inspection run',
        message: (
          <>
            <div>
              By cancelling, this inspection plan will be discarded. Any
              inspections included in this plan will have the inspection date
              and time reset and status changed to created.
            </div>
            <div>Do you want to continue?</div>
          </>
        ),
        cancelText: 'No, keep this inspection plan',
        confirmText: 'Yes, cancel inspection plan',
        onConfirm: async () => {
          await trashItem({
            id: inspectionRun.id
          });

          await refreshLists();

          addToast({
            description: <>Inspection plan cancelled</>
          });
        }
      });
    }
  });
}
