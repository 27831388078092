import { useToast } from 'view/components/@luna/notifications/toast';
import { useErrorDialog } from '@rexlabs/dialog';
import { getRecordTitle } from 'utils/records/get-record-title';
import { SingleActionDeclaration } from 'src/modules/common/actions/types/action-declaration-types';
import { BaseModelGeneratorModel, EntityModel } from '@rexlabs/model-generator';
import { getRecordObject } from 'src/modules/common/utils/Records/get-record-object';

type UseModelDownloadActionArgs = {
  fetcher: any;
  label: string;
  record: BaseModelGeneratorModel;
  toastArgs?: {
    title?: string;
    description?: string;
    color?: string;
  };
} & SingleActionDeclaration;

export const useModelDownloadAction = (model: EntityModel<any>) => {
  const { addToast } = useToast();
  const { open } = useErrorDialog();

  return function ({
    fetcher,
    label,
    record,
    toastArgs,
    id = 'download',
    group = 'reporting',
    ...args
  }: UseModelDownloadActionArgs): SingleActionDeclaration {
    const recordObject = getRecordObject(record, model);

    return {
      ...args,
      id,
      group,
      label,
      handleAction: async () => {
        addToast({
          title: toastArgs?.title ?? getRecordTitle(recordObject),
          description: toastArgs?.description ?? 'Your file is being generated',
          color: toastArgs?.color ?? 'information'
        });
        try {
          const report = await fetcher();
          const url = report.data.link;
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `${getRecordTitle(recordObject)}.pdf`);
          document.body.appendChild(link);
          link.click();
          link.remove();
        } catch (e) {
          open(e);
        }
      }
    };
  };
};
