export const WORK_ORDERS_ROUTES = {
  WORK_ORDERS_LIST: {
    config: {
      path: '/work-orders',
      Component: require('../screens/work-orders-list.tsx').WorkOrdersList
    }
  },
  WORK_ORDER_DETAILS: {
    config: {
      path: '/work-order/:workOrderId',
      Component: require('../screens/work-order-details.tsx').WorkOrderDetails
    }
  }
};
