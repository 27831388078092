import * as React from 'react';
import { pick } from 'lodash';
import { useQueryClient } from 'react-query';
import { DialogProps, useDialog } from '@rexlabs/dialog';
import {
  OutlineButton,
  GhostButton,
  PrimaryButton,
  ButtonProps
} from '@rexlabs/button';
import Tooltip from '@rexlabs/tooltip';
import { useModelActions } from '@rexlabs/model-generator';
import ArrowUpCircleIcon from 'view/components/icons/arrow-up-circle';
import { RecordDialog } from 'view/components/record-screen/dialog/dialog';
import { RecordSubmitHandler } from 'view/components/record-screen/utils';
import {
  ButtonGroupProps,
  DialogContentConfig
} from 'view/components/record-screen/dialog/types';
import { formatCurrency } from 'utils/formatters';
import { StandardDialogFooter } from 'view/components/dialogs/components/standard-dialog-footer';
import InfoCircleIcon from 'view/components/icons/info';
import { SplitTransactionIcon } from 'view/components/icons/split-transaction';
import { bankStatementTransactionsQueryKey } from '../hooks/use-bank-statement-transactions-query';
import { bankStatementTransactionsModel } from '../models/bank-statement-transaction-model';
import { parentTransactionBlock } from '../blocks/parent-transaction';
import { childTransactionBlock } from '../blocks/child-transaction';
import { BatchReceiptingItem, NewSplitTransactionFormValue } from '../types';
import { SplitBankTransactionDialog } from './split-bank-transaction-dialog';

export interface SplitBankTransactionManualDialogProps extends DialogProps {
  batchReceiptingItem: BatchReceiptingItem;
}

const content: DialogContentConfig = [
  {
    id: 'split-manual-bank-transaction',
    label: 'Split Bank Transaction',
    blocks: [parentTransactionBlock, childTransactionBlock]
  }
];

export function SplitBankTransactionManualDialog({
  onClose,
  target,
  batchReceiptingItem
}: SplitBankTransactionManualDialogProps) {
  const queryClient = useQueryClient();
  const { createSubBankTransactions } = useModelActions(
    bankStatementTransactionsModel
  );

  const handleSubmit: RecordSubmitHandler<{
    subTransactions: Array<NewSplitTransactionFormValue>;
  }> = React.useCallback(
    async ({ values }) => {
      const subTransactions = values.subTransactions.map((transaction) =>
        pick(transaction, ['amount', 'description', 'reference', 'paid_date'])
      );

      const response = await createSubBankTransactions({
        subTransactions,
        bankStatementTransactionId: batchReceiptingItem.id
      });

      await queryClient.refetchQueries({
        queryKey: [bankStatementTransactionsQueryKey],
        refetchPage: () => true
      });

      return response;
    },
    [batchReceiptingItem.id]
  );

  return (
    <RecordDialog
      title='Split bank transaction'
      handleSubmit={handleSubmit}
      target={target}
      initialValues={{
        subTransactions: []
      }}
      blockProps={{
        batchReceiptingItem
      }}
      content={content}
      onClose={onClose}
      ButtonGroup={SplitManualTransactionButton}
      buttonGroupProps={{
        batchReceiptingItem
      }}
    />
  );
}

export function SplitManualTransactionButton({
  batchReceiptingItem,
  handleSubmit,
  onClose,
  forms
}: ButtonGroupProps & { batchReceiptingItem: BatchReceiptingItem }) {
  const childTransactions = forms!['split-child-transaction']!.values;

  const parentAmount = batchReceiptingItem.amount;
  const buttonProps = getButtonProps(childTransactions, parentAmount);

  const splitBankTransactionDialog = useDialog(SplitBankTransactionDialog, {
    target: 'nested-dialogs'
  });

  const handleSplitTransaction = () => {
    splitBankTransactionDialog.open({
      batchReceiptingItem
    });

    onClose?.();
  };

  const handleSplitManualTransaction = async () => {
    const response = await handleSubmit();

    if (!response) return;

    onClose?.();
  };

  return (
    <StandardDialogFooter
      left={
        <OutlineButton
          IconLeft={ArrowUpCircleIcon}
          onClick={handleSplitTransaction}
        >
          Upload transaction file
        </OutlineButton>
      }
    >
      <GhostButton onClick={onClose}>Cancel</GhostButton>

      <Tooltip
        isDisabled={!buttonProps.isDisabled}
        Content={() =>
          'The funds in the children transactions must equal the parent transaction. Once this is true, you will be able to split the parent transaction.'
        }
      >
        <PrimaryButton
          data-testid='split-manual-transaction-cta'
          onClick={handleSplitManualTransaction}
          {...buttonProps}
        />
      </Tooltip>
    </StandardDialogFooter>
  );
}

function getSubTransactionsResult(values) {
  const transactionsTotal = values.subTransactions.length;

  return [transactionsTotal > 1, transactionsTotal];
}

function getAmountResult(values, parentTotalAmount) {
  const childTotalAmount = values.subTransactions.reduce(
    (total, lineItem) => total + parseFloat(lineItem.amount),
    0
  );

  return [childTotalAmount === parentTotalAmount, childTotalAmount];
}

function getButtonProps(childTransactions, parentAmount): ButtonProps {
  // There should be minimum 2 line items for a split transaction
  const [hasMoreThanOneTransaction, lineItemsTotal] = getSubTransactionsResult(
    childTransactions
  );

  // Sum of child transactions should match parent transaction amount
  const [isAmountEqual, childTotalAmount] = getAmountResult(
    childTransactions,
    parentAmount
  );

  const isButtonDisabled = !(hasMoreThanOneTransaction && isAmountEqual);

  const label = isButtonDisabled
    ? `${formatCurrency(parentAmount - childTotalAmount)} left to allocate`
    : `Split into ${lineItemsTotal} transactions`;

  return {
    isDisabled: isButtonDisabled,
    children: label,
    IconLeft: isButtonDisabled ? InfoCircleIcon : SplitTransactionIcon
  };
}
