import { useModelActions } from '@rexlabs/model-generator';
import { propertiesModel } from 'data/models/entities/properties';
import { useDeleteAction } from 'src/modules/common/actions/delete/use-delete-action';
import { ActionDeclaration } from 'src/modules/common/actions/types/action-declaration-types';
import { getRecordLabel } from 'utils/records/get-record-label';
import { appliancesModel } from '../../models/appliances-model';
import { Appliance } from '../../types/Appliance';
import { useGetEditApplianceAction } from '../action-declarations/use-get-edit-appliance-action';

export function useGetApplianceActions() {
  const getEditApplianceAction = useGetEditApplianceAction();
  const getDeleteApplianceAction = useDeleteAction(appliancesModel);
  const { refreshItem: refreshProperty } = useModelActions(propertiesModel);

  return (appliance?: Appliance): ActionDeclaration[] => {
    if (!appliance) return [];

    const actions = [
      getEditApplianceAction(appliance),
      getDeleteApplianceAction(appliance, {
        label: 'Remove appliance',
        title: 'Remove appliance',
        message:
          'Do you want to remove this appliance? This action cannot be undone.',
        confirmText: 'Remove',
        toastText: `${
          appliance.type
        } has been removed as an appliance from ${getRecordLabel({
          type: 'property',
          object: appliance.property!
        })}`,
        afterAction: async () =>
          await refreshProperty({
            id: appliance.property!.id
          })
      })
    ];

    return actions;
  };
}
