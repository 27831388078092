import Box from '@rexlabs/box';
import { OutlineButton } from '@rexlabs/button';
import { useDialog } from '@rexlabs/dialog';
import { Field } from '@rexlabs/form';
import { TextInput } from '@rexlabs/text-input';
import { Tenancy } from 'data/models/entities/tenancies';
import { ValueListValue } from 'data/models/types';
import invariant from 'invariant';
import { get } from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { MatchStatusId } from 'src/modules/banking/batch-receipting/types';
import { Grid } from 'view/components/@luna/form/grid';
import {
  ErrorBanner,
  InfoBanner,
  SuccessBanner,
  WarningBanner
} from 'view/components/@luna/notifications/banner';
import CheckCircleIcon from 'view/components/icons/check-circle';
import CommentIcon from 'view/components/icons/comment';
import InfoCircleIcon from 'view/components/icons/info';
import { ReceiptPaymentMethodSelect } from 'view/components/inputs/selects/receipt-payment-method';
import { BlockConfig } from 'view/components/record-screen/types';
import { Value } from 'view/components/values';
import { ReceiptPaymentMethods } from 'src/modules/trust-journal-entries/types/trust-journal-entry-types';
import { Contact } from 'src/modules/contacts/types/contact-types';
import { UpdateTenantReferenceNumberDialog } from '../dialogs/update-tenant-reference-number';
import { getFolio } from '../utils/get-folio';

type IncomingPaymentType =
  | 'cash'
  | 'cheque'
  | 'bank_cheque'
  | 'card'
  | 'eft'
  | 'money_order';

export type ReceiptFundsPaymentDetailsBlockProps = {
  payment_information: {
    method: ValueListValue<ReceiptPaymentMethods>;
    reference?: string;
    drawer?: string;
    issuer?: string;
    branch_code?: string;
  };
  matchStatus: MatchStatusId;
};
export const receiptFundsPaymentDetailsBlock: BlockConfig<
  any,
  {
    allowUpdateTenantReference: boolean;
    renderBatchReceiptingDialog?: boolean;
  },
  ReceiptFundsPaymentDetailsBlockProps
> = {
  id: 'receipt-funds-payment-details',
  title: 'Payment details',
  validate: {
    definitions: {
      'payment_information.method': { rules: 'required' }
    }
  },
  Edit: ({ values, data, forms, blockProps }) => {
    const { t } = useTranslation();

    const allowUpdateTenantReference = blockProps?.allowUpdateTenantReference;

    const [hasUpdatedReference, setHasUpdatedReference] = React.useState(false);

    const updateReferenceNumberDialog = useDialog(
      UpdateTenantReferenceNumberDialog
    );

    const paymentId = get(values, 'payment_information.method.id') as
      | IncomingPaymentType
      | undefined;

    const isReferenceNeeded =
      paymentId === 'card' ||
      paymentId === 'cash' ||
      paymentId === 'eft' ||
      paymentId === 'money_order';

    const isDrawerNeeded =
      paymentId === 'bank_cheque' || paymentId === 'cheque';
    const isCheckNumberNeeded =
      paymentId === 'bank_cheque' || paymentId === 'cheque';
    const isBankNeeded = paymentId === 'bank_cheque' || paymentId === 'cheque';
    const isBranchCodeNeeded =
      paymentId === 'bank_cheque' || paymentId === 'cheque';
    const isMoneyOrderNumberNeeded = paymentId === 'money_order';

    const folio = getFolio(forms);

    const receiptingInstruction =
      folio?.type.id === 'tenancy'
        ? (folio?.record as Tenancy)?.receipting_instruction
        : null;

    const isMatched = ['matched_allocate', 'matched_receipt'].includes(
      values!.matchStatus
    );

    let receiptFrom = get(
      forms,
      'receipt-funds-details.values.contacts'
    ) as Array<Contact>;
    receiptFrom = Array.isArray(receiptFrom) ? receiptFrom : [];

    const tenants = get(forms, 'receipt-funds-details.values.tenants');

    const isReceiptFromSame = receiptFrom.some(
      (contact) => contact.id === data?.contact?.id
    );

    const tenantName = receiptFrom
      .map((contact) => contact.display_name)
      .join(', ');

    const tenantReferenceNumbers = receiptFrom
      .filter((contact) => contact.payment_reference != null)
      .map((contact) => contact.payment_reference);

    const tenantReferenceNumberDisplay = tenantReferenceNumbers.join(', ');

    const newReferenceNumber = values?.payment_information?.reference;

    const hasSameReferenceNumber =
      tenantReferenceNumbers.length &&
      tenantReferenceNumbers.every(
        (referenceNumber) => referenceNumber === newReferenceNumber
      );
    const isTenancy = folio?.type.id === 'tenancy';

    const showUpdateBanner =
      allowUpdateTenantReference &&
      !hasSameReferenceNumber &&
      receiptFrom.length &&
      isTenancy &&
      !isMatched &&
      !hasUpdatedReference;

    const handleUpdateReferenceNumber = React.useCallback(() => {
      invariant(folio?.id, `folio.id can't be null`);

      updateReferenceNumberDialog.open({
        folioId: folio?.id,
        tenantIds: receiptFrom.map((contact) => contact.id),
        tenants,
        initialValues: {
          tenantName,
          tenantReferenceNumbers: tenantReferenceNumberDisplay,
          newReferenceNumber
        },
        onSave() {
          setHasUpdatedReference(true);
        }
      });
    }, [
      updateReferenceNumberDialog,
      tenantName,
      tenantReferenceNumberDisplay,
      newReferenceNumber,
      folio?.id,
      receiptFrom,
      tenants
    ]);

    return (
      <Box>
        <Grid columns={3}>
          <Field
            name='payment_information.method'
            label='Payment method'
            Input={ReceiptPaymentMethodSelect}
          />
          {isReferenceNeeded &&
            (blockProps?.renderBatchReceiptingDialog ? (
              <Value
                value={values?.payment_information?.reference}
                label='Reference'
              />
            ) : (
              <Field
                name='payment_information.reference'
                label='Reference'
                Input={TextInput}
              />
            ))}
          {isDrawerNeeded && (
            <Field
              name='payment_information.drawer'
              label='Drawer'
              Input={TextInput}
            />
          )}
          {isCheckNumberNeeded && (
            <Field
              name='payment_information.reference'
              label='Cheque number'
              Input={TextInput}
            />
          )}
          {isBankNeeded && (
            <Field
              name='payment_information.issuer'
              label='Bank'
              Input={TextInput}
            />
          )}
          {isBranchCodeNeeded && (
            <Field
              name='payment_information.branch_code'
              label={t('bank-accounts.branch-code.label.singular') as string}
              Input={TextInput}
            />
          )}
          {isMoneyOrderNumberNeeded && (
            <Field
              name='payment_information.issuer'
              label='Money order number'
              Input={TextInput}
            />
          )}
        </Grid>
        {receiptingInstruction && (
          <Box paddingTop='2rem'>
            <InfoBanner
              data-testid='receipting-instructions-banner'
              Icon={CommentIcon}
              description={<>{receiptingInstruction}</>}
            />
          </Box>
        )}

        {showUpdateBanner && (
          <Box paddingTop='2rem'>
            <WarningBanner
              data-testid='warning-banner'
              Icon={InfoCircleIcon}
              description={
                <Box gap={12}>
                  <div>
                    The reference number for this payment does not match the{' '}
                    <b>tenant reference number</b> for <b>{tenantName}</b>. To
                    ensure future payments will automatically match, you can
                    update the tenant reference number for this tenant.
                  </div>

                  <OutlineButton onClick={handleUpdateReferenceNumber}>
                    Update tenant reference number
                  </OutlineButton>
                </Box>
              }
            />
          </Box>
        )}

        {hasUpdatedReference && (
          <Box paddingTop='2rem'>
            <SuccessBanner
              data-testid='success-banner'
              Icon={CheckCircleIcon}
              description={
                <div>
                  The tenant reference number has been updated successfully. All
                  future payments using this reference number will be
                  automatically matched to this tenant.
                </div>
              }
            />
          </Box>
        )}

        {isMatched && !isReceiptFromSame && (
          <Box paddingTop='2rem'>
            <ErrorBanner
              data-testid='error-banner'
              Icon={InfoCircleIcon}
              description={
                <div>
                  The reference number for this payment does not match the
                  tenant reference number for <b>{tenantName}</b>. The reference
                  can not be updated as it is already in use by a different
                  tenant.
                </div>
              }
            />
          </Box>
        )}
      </Box>
    );
  }
};
