import { useDialog } from '@rexlabs/dialog';
import { ActionDeclaration } from 'src/modules/common/actions/types/action-declaration-types';
import { FLAGS } from 'utils/feature-flags';
import { CreateEmailRecordDialog } from '../../dialogs/create-email-record-dialog';
import { ChannelMessageRecipient } from '../../types/ChannelMessageRecipient';
import { mapMessageToEditFormData } from '../../mappers/map-message-to-edit-form-data';

export function useGetEditEmailAndResendAction() {
  const { open } = useDialog(CreateEmailRecordDialog);

  return (
    channelMessageRecipient: ChannelMessageRecipient
  ): ActionDeclaration => {
    const message = channelMessageRecipient.channel_message!.message;
    const messageData = mapMessageToEditFormData({
      message,
      type: 'email',
      resolveMergeTags: true
    });

    return {
      flag: FLAGS.MESSAGES,
      intent: 'primary',
      id: 'edit-email-and-resend',
      group: 'messages',
      label: 'Edit message and resend',
      handleAction: async () => {
        open({
          data: messageData
        });
      }
    };
  };
}
