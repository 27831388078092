import React, { useCallback } from 'react';

import { RecordDialog } from 'view/components/record-screen/dialog/dialog';
import { DialogContentConfig } from 'view/components/record-screen/dialog/types';

import { RecordSubmitHandler } from 'view/components/record-screen/utils';
import { useModelActions } from '@rexlabs/model-generator';
import { trustLedgerModel } from 'src/modules/trust-ledgers/models/trust-ledger-model';
import { TrustLedger } from 'src/modules/trust-ledgers/types/trust-ledger-types';
import { useTranslation } from 'react-i18next';
import { createTrustLedgerBlock } from '../blocks/create-trust-ledger';

const content: DialogContentConfig = [
  {
    id: 'basics',
    label: 'Basics',
    blocks: [createTrustLedgerBlock]
  }
];

interface EditTrustLedgerDialogProps {
  onClose?: () => void;
  onCreate?: (item: TrustLedger) => void;
  trustLedger: TrustLedger;
}

export function EditTrustLedgerDialog({
  onClose,
  trustLedger
}: EditTrustLedgerDialogProps) {
  const { t } = useTranslation();

  const { updateItem } = useModelActions(trustLedgerModel);

  const handleSubmit: RecordSubmitHandler = useCallback(
    async ({ changedValues }) => {
      const response = await updateItem({
        id: trustLedger.id,
        data: changedValues
      });
      return response.data;
    },
    [updateItem]
  );

  return (
    <RecordDialog
      onClose={onClose}
      title={t('financials.trust.trust-ledgers.update.singular')}
      data={trustLedger}
      handleSubmit={handleSubmit}
      content={content}
    />
  );
}
