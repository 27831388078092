import React from 'react';

import { BlockConfig } from 'view/components/record-screen/types';
import Box from '@rexlabs/box';
import { Field } from '@rexlabs/form';
import { Grid } from 'view/components/@luna/form/grid';
import { DateInput } from 'view/components/@luna/inputs/date-input/date-input';
import { DateValue } from 'view/components/values/date';
import { Trans, useTranslation } from 'react-i18next';
import { Task } from '../../common/types/Task';
import { MoveInFormData } from '../types/MoveInFormData';

export const taskDateBlock: BlockConfig<
  Task<'task_move_in'>,
  any,
  MoveInFormData
> = {
  id: 'dates',
  title: (
    <Trans i18nKey={'tasks.move-in.follow-up-and-move-in-date'}>
      Follow up and move in date
    </Trans>
  ),
  View: ({ data }) => {
    const { t } = useTranslation();
    return (
      <Grid columns={2}>
        <DateValue label='Next follow up date' value={data?.follow_up_date} />
        <DateValue
          label={t('tasks.move-in.move-in-date', 'Move in date') as string}
          value={data?.details?.move_in_date}
        />
      </Grid>
    );
  },
  Edit: () => {
    const { t } = useTranslation();
    return (
      <Box sy='2rem'>
        <Grid columns={4}>
          <Field
            id='follow_up_date'
            name='follow_up_date'
            label='Next follow up date'
            Input={DateInput}
            inputProps={{
              clearable: true
            }}
          />
          <Field
            id='move_in_date'
            name='details.move_in_date'
            label={t('tasks.move-in.move-in-date', 'Move in date') as string}
            Input={DateInput}
            inputProps={{
              clearable: true
            }}
          />
        </Grid>
      </Box>
    );
  }
};
