import React from 'react';

import { ValueListValue } from 'data/models/types';
import {
  PaymentSourceRecord,
  TaxSummaryItem
} from 'data/models/entities/financials/invoices';
import { formatDate } from 'utils/dates/format';
import {
  CurrencySummary,
  CurrencySummaryRow
} from 'view/components/currency-summary';
import { push } from '@rexlabs/whereabouts';
import ROUTES from 'routes/app';
import { getTaxSummaryRows } from '../utils/get-tax-summary-rows';

function getTotalRows(
  totalAmount: number,
  amountOwing?: number
): CurrencySummaryRow[] {
  if (amountOwing === undefined) {
    return [
      {
        isDivider: true
      },
      {
        id: 'amount-credited',
        label: 'Amount credited',
        bold: true,
        large: true,
        value: totalAmount
      }
    ];
  }

  return [
    {
      id: 'total',
      label: 'Total',
      bold: true,
      value: totalAmount
    }
  ];
}

function getAmountOwingRows(amountOwing?: number): CurrencySummaryRow[] {
  if (amountOwing === undefined) return [];

  return [
    {
      isDivider: true
    },
    {
      id: 'amount-owing',
      label: 'Amount owing',
      bold: true,
      large: true,
      value: amountOwing
    }
  ];
}

export type SummaryObject = {
  amount_total_inc_tax: number;
  amount_total_exc_tax: number;
  amount_owing?: number;
};

export type SummaryPaymentLineEntry = {
  id: string;
  created_at?: string;
  type: ValueListValue<string>;
  amount: number;
  source_record?: PaymentSourceRecord;
};

interface TaxSummaryProps<T, Payment> {
  data: T;
  payments?: Payment[];
  taxSummary?: TaxSummaryItem[];
}

export function TaxSummary<
  T extends SummaryObject,
  Payment extends SummaryPaymentLineEntry
>({ data, payments, taxSummary }: TaxSummaryProps<T, Payment>) {
  let paymentRows: CurrencySummaryRow[] = [];

  if (payments && payments.length) {
    paymentRows = payments.map((payment, index) => ({
      id: `payment-${index}`,
      label: `${payment.type.label} ${
        payment.created_at
          ? `on ${formatDate(
              payment.source_record?.date ?? payment.created_at
            )}`
          : ''
      }`,
      value: payment.amount,
      // Credit notes might not have a source record, so make sure we fall back gracefully
      onClick: !payment.source_record
        ? undefined
        : () => {
            const isCredit = payment.source_record!.type.id === 'credit_note';
            const route = isCredit
              ? ROUTES.CREDIT_NOTE
              : ROUTES.TRUST_JOURNAL_ENTRY;
            const keyName = isCredit ? 'creditNoteId' : 'trustJournalEntryId';
            push(route, {
              params: { [keyName]: payment.source_record?.id }
            });
          }
    }));
  }

  let taxSummaryRows: CurrencySummaryRow[] = [];
  if (taxSummary && taxSummary.length) {
    taxSummaryRows = getTaxSummaryRows(taxSummary);
  }

  const rows: CurrencySummaryRow[] = [
    {
      id: 'subtotal',
      label: 'Subtotal excl. tax',
      value: data?.amount_total_exc_tax
    },
    ...taxSummaryRows,
    ...getTotalRows(data.amount_total_inc_tax, data?.amount_owing),
    ...paymentRows,
    ...getAmountOwingRows(data?.amount_owing)
  ];

  return <CurrencySummary rows={rows} />;
}
