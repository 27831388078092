import React, { useMemo } from 'react';
import { BlockConfig } from 'view/components/record-screen/types';
import { Column, ListTable, TabbedTable } from 'view/components/table';
import { query, useModelActions } from '@rexlabs/model-generator';
import {
  financialsReconciliationsModel,
  Reconciliation
} from 'data/models/entities/financials/reconciliations';
import { financialsReconciliationsBankWithdrawalsModel } from 'data/models/entities/financials/reconciliations/bank-withdrawals';
import { RecordCell } from '@rexlabs/table';
import ROUTES from 'routes/app';
import { BankWithdrawal } from 'src/modules/banking/bank-withdrawals/types/bank-withdrawal-types';
import { useTableFilters } from 'view/hooks/use-table-filters';
import { Tab } from 'view/components/table/tabbed';
import { getReconcileItemsPayload } from '../utils/get-reconcile-payload';
import { ResolutionActionButton } from '../components/resolution-action-button';
import { getReconciledInThisReconciliationFilters } from '../utils/get-reconciled-in-this-reconciliation-filters';

const CustomActionCell = ({ cell }) => {
  const { refreshList, reconcileItems, unreconcileItems } = useModelActions(
    financialsReconciliationsBankWithdrawalsModel
  );

  const { refreshItem: refreshReconciliation } = useModelActions(
    financialsReconciliationsModel
  );

  const { value } = cell;
  const bankWithdrawal = value as BankWithdrawal & { reconciliationId: string };

  const isReconciled =
    bankWithdrawal.reconciliation_status?.status.id === 'reconciled';

  const handleClick = async () => {
    if (isReconciled) {
      await unreconcileItems(
        getReconcileItemsPayload([bankWithdrawal], 'bank_withdrawal')
      );
    } else {
      await reconcileItems(
        getReconcileItemsPayload([bankWithdrawal], 'bank_withdrawal')
      );
    }

    await refreshList({ id: 'reconciliation-bank-withdrawals' });
    await refreshReconciliation({ id: bankWithdrawal.reconciliationId });
  };

  return (
    <ResolutionActionButton isResolved={isReconciled} onClick={handleClick} />
  );
};

const bankWithdrawalsColumns = [
  {
    id: 'date_of',
    type: 'date',
    Header: 'Withdrawal date'
  },
  {
    id: 'record_reference',
    Header: 'Withdrawal reference',
    accessor: (item) => item,
    Cell: RecordCell,
    cellProps: {
      labelKey: 'record_reference',
      getCellLinkProps: (value) => {
        return {
          to: ROUTES.BANK_WITHDRAWAL,
          params: {
            bankWithdrawalId: value.id
          }
        };
      }
    }
  },
  {
    id: 'type',
    type: 'value',
    Header: 'Type'
  },
  {
    id: 'totals.amount',
    type: 'currency',
    cellProps: {
      align: 'right'
    },
    Header: 'Amount',
    width: 100
  }
];

const toggleReconciledAction = (reconciliationId: string): Column => ({
  id: 'action',
  accessor: (item) => ({ ...item, reconciliationId: reconciliationId }),
  Cell: CustomActionCell,
  width: 60,
  sticky: 'right'
});

const getReconciliationBankWithdrawals = (reconciliationId: string) => () => ({
  ...query`{
  ${financialsReconciliationsBankWithdrawalsModel} (reconciliationId: ${reconciliationId}) {
    id,
    totals,
    reconciliation_status
  }
}`,
  uuid: 'reconciliation-bank-withdrawals'
});

export const bankWithdrawalsBlock: BlockConfig<Reconciliation> = {
  id: 'reconciliation-bank-withdrawals',
  title: 'Bank withdrawals',
  View: ({ data }) => {
    const { reconcileItems, unreconcileItems, refreshList } = useModelActions(
      financialsReconciliationsBankWithdrawalsModel
    );

    const { refreshItem: refreshReconciliation } = useModelActions(
      financialsReconciliationsModel
    );

    const { getFilters, getSort } = useTableFilters('bank-withdrawals');
    const isUnfinishedReconciliation = data?.status?.id !== 'reconciled';

    const tabs = useMemo<Tab[]>(() => {
      const commonProps = {
        id: 'reconciliation-bank-withdrawals',
        Table: ListTable,
        columns: [
          ...bankWithdrawalsColumns,
          ...(isUnfinishedReconciliation
            ? [toggleReconciledAction(data!.id)]
            : [])
        ],
        getQuery: getReconciliationBankWithdrawals(data!.id),
        getFilters,
        getSort,
        suggestedFilters: ['date_of', 'type_id'],
        initialSortBy: [{ id: 'date_of', desc: true }]
      };

      return [
        {
          ...commonProps,
          forcedGlobalFilter: [
            {
              field: 'reconciliation_status_id',
              op: 'eq',
              value: 'unreconciled'
            }
          ],
          name: 'unreconciled-bank-withdrawals',
          label: 'Unreconciled',
          getBulkActions: isUnfinishedReconciliation
            ? ({ selectedItems, entity }) => [
                {
                  label: 'Reconcile withdrawals',
                  type: 'primary',
                  handleAction: async () => {
                    await reconcileItems(
                      getReconcileItemsPayload(selectedItems, 'bank_withdrawal')
                    );
                    await refreshList({ id: entity.uuid });
                    await refreshReconciliation({ id: data!.id });
                  }
                }
              ]
            : undefined
        },
        {
          ...commonProps,
          forcedGlobalFilter: [
            {
              field: 'reconciliation_status_id',
              op: 'eq',
              value: 'reconciled'
            },
            ...getReconciledInThisReconciliationFilters(data!)
          ],
          name: 'reconciled-bank-withdrawals-in-this-reconciliation',
          label: 'This reconciliation',
          getBulkActions: isUnfinishedReconciliation
            ? ({ selectedItems, entity }) => [
                {
                  label: 'Unreconcile withdrawals',
                  type: 'primary',
                  handleAction: async () => {
                    await unreconcileItems(
                      getReconcileItemsPayload(selectedItems, 'bank_withdrawal')
                    );
                    await refreshList({ id: entity.uuid });
                    await refreshReconciliation({ id: data!.id });
                  }
                }
              ]
            : undefined
        },
        {
          ...commonProps,
          forcedGlobalFilter: [
            {
              field: 'reconciliation_status_id',
              op: 'eq',
              value: 'reconciled'
            }
          ],
          name: 'reconciled-bank-withdrawals',
          label: 'Reconciled to date',
          getBulkActions: isUnfinishedReconciliation
            ? ({ selectedItems, entity }) => [
                {
                  label: 'Unreconcile withdrawals',
                  type: 'primary',
                  handleAction: async () => {
                    await unreconcileItems(
                      getReconcileItemsPayload(selectedItems, 'bank_withdrawal')
                    );
                    await refreshList({ id: entity.uuid });
                    await refreshReconciliation({ id: data!.id });
                  }
                }
              ]
            : undefined
        }
      ];
    }, [
      data,
      getFilters,
      getSort,
      isUnfinishedReconciliation,
      reconcileItems,
      refreshList,
      refreshReconciliation,
      unreconcileItems
    ]);

    return <TabbedTable tabs={tabs} />;
  }
};
