import React from 'react';

import { BlockConfig } from 'view/components/record-screen/types';
import Box from '@rexlabs/box';
import { Field } from '@rexlabs/form';
import { Grid } from 'view/components/@luna/form/grid';
import { DateInput } from 'view/components/@luna/inputs/date-input/date-input';
import { DateValue } from 'view/components/values/date';
import { Task } from '../../common/types/Task';
import { MoveOutFormData } from '../types/MoveOutFormData';

export const taskDateBlock: BlockConfig<
  Task<'task_move_out'>,
  any,
  MoveOutFormData
> = {
  id: 'dates',
  title: 'Follow up and move out date',
  View: ({ data }) => {
    return (
      <Grid columns={2}>
        <DateValue label='Next follow up date' value={data?.follow_up_date} />
        <DateValue label='Move out date' value={data?.details?.move_out_date} />
      </Grid>
    );
  },
  Edit: () => {
    return (
      <Box sy='2rem'>
        <Grid columns={4}>
          <Field
            id='follow_up_date'
            name='follow_up_date'
            label='Next follow up date'
            Input={DateInput}
            inputProps={{
              clearable: true
            }}
          />
          <Field
            id='move_out_date'
            name='details.move_out_date'
            label='Move out date'
            Input={DateInput}
            inputProps={{
              clearable: true
            }}
          />
        </Grid>
      </Box>
    );
  }
};
