import { Generator, GeneratorConfig } from 'data/models/generator';
import { BaseFields, RecordTypes, ValueListValue } from 'data/models/types';
import { AlfredFile } from 'src/modules/common/types/file';

export type PageSize = 'A4' | 'letter' | 'legal';

export type OffsetUnit = 'mm' | 'in';

export const SCALE_FACTOR = 3; // Factor by which to downscale all previews. This could be use rconfigurable later.

export interface PrintOffsets {
  unit: ValueListValue<OffsetUnit>;
  address_top: number;
  address_left: number;
  address_width: number;
  content_top_first_page: number;
  content_top_subsequent_pages: number;
  content_bottom: number;
  content_left: number;
  content_right: number;
}

export interface LetterLayout
  extends BaseFields<typeof RecordTypes.LetterLayout> {
  name: string;
  page_size: ValueListValue<PageSize>;
  print_offsets: PrintOffsets;
  letterhead_first_page_file: AlfredFile;
  letterhead_subsequent_pages_file?: AlfredFile;
}

const config: GeneratorConfig = {
  entities: {
    related: {
      print_offsets: {
        include: 'print_offsets'
      },
      letterhead_first_page_file: {
        include: 'letterhead_first_page_file'
      },
      letterhead_subsequent_pages_file: {
        include: 'letterhead_subsequent_pages_file'
      }
    }
  }
};

export const communicationLetterLayoutsModel = new Generator<LetterLayout>(
  'communication/letter-layouts',
  config
).createEntityModel();
