import { ActionDeclaration } from 'src/modules/common/actions/types/action-declaration-types';
import { useDialog } from '@rexlabs/dialog';
import { UpdatePrepaymentBucketDialog } from 'src/modules/prepayments/dialogs/update-prepayment-bucket-dialog';
import { PrepaymentBucket } from 'src/modules/prepayments/types/prepayment-bucket';
import { UpdateRentPrepaymentBucketDialog } from 'src/modules/prepayments/dialogs/update-rent-prepayment-dialog';

export function useGetUpdatePrepaymentBucketAction() {
  const { open: openEditPrepayment } = useDialog(UpdatePrepaymentBucketDialog);
  const { open: openEditRentPrepayment } = useDialog(
    UpdateRentPrepaymentBucketDialog
  );
  return (prepaymentBucket: PrepaymentBucket): ActionDeclaration => {
    return {
      id: 'prepayments',
      group: 'prepayments',
      label: 'Edit',
      handleAction: () => {
        if (prepaymentBucket.system_purpose?.id === 'rent_prepayment') {
          return openEditRentPrepayment({ prepaymentBucket });
        }
        return openEditPrepayment({ prepaymentBucket });
      }
    };
  };
}
