import { useInfiniteQuery } from 'react-query';
import { api } from 'utils/api/api-client';
import {
  filtersToQuri,
  mergeWithArray,
  sortByToQuri
} from '@rexlabs/table/module/utils';
import React from 'react';
import { ReactTableContext } from '@rexlabs/table';
import { mapQuriFiltersToTableFilter } from 'src/modules/common/utils/map-quri-filters-to-table-filter';
import { AxiosResponsePagination } from 'view/components/table/use-table-query';
import { getQueryString } from 'view/components/table/list-table-react-query';
import { useGetBankStatementTransactionFilter } from './use-get-bank-statement-transaction-filter';

export const bankStatementTransactionIncludes = [
  'bank_account',
  'prioritised_match',
  'prioritised_match.contacts',
  'prioritised_match.folio.active_property_tenancy',
  'bank_statement_transaction_import',
  'bank_statement_transaction_import.file'
].join(',');

export const bankStatementTransactionsQueryKey = 'bankStatementTransactions';

export type BankStatementTransactionTab =
  | 'ACTIVE'
  | 'NOT_MATCHED'
  | 'MATCHED'
  | 'SUSPENDED'
  | 'REMOVED';

export async function fetchBankStatementTransactions(queryString: string) {
  const response: AxiosResponsePagination = await api.get(
    `/financials/bank-statement-transactions?${queryString}`
  );

  return { data: response.data, pagination: response.pagination };
}

export function useBankStatementTransactionsQuery(
  bankStatementTransactionTab: BankStatementTransactionTab
) {
  const reactContext = React.useContext(ReactTableContext);

  const sortBy = reactContext.state.sortBy;
  const filters = reactContext.state.globalFilter;
  const search = reactContext.state.search;

  const getBankStatementTransactionFilter = useGetBankStatementTransactionFilter();

  const tabFilter = mapQuriFiltersToTableFilter(
    getBankStatementTransactionFilter(bankStatementTransactionTab)
  );

  const filter = React.useMemo(
    () => filtersToQuri(mergeWithArray({}, filters, tabFilter)),
    [filters, tabFilter]
  );

  const sort = React.useMemo(
    () =>
      encodeURIComponent(
        sortByToQuri(
          mergeWithArray(
            [],
            [{ id: 'paid_date', desc: true }],
            Array.isArray(sortBy) ? sortBy : []
          )
        )
      ),
    [sortBy]
  );

  return useInfiniteQuery(
    [bankStatementTransactionsQueryKey, filter, sort, search],
    async ({ pageParam = 1 }) => {
      const queryString = getQueryString({
        page: pageParam,
        sort,
        filter,
        search,
        include: bankStatementTransactionIncludes
      });

      return fetchBankStatementTransactions(queryString);
    },
    {
      cacheTime: 0,
      getPreviousPageParam: ({ pagination }) => {
        if (pagination.current_page === 1) {
          return undefined;
        }
        if (pagination.total_pages > pagination.current_page) {
          return pagination.current_page - 1;
        }
      },
      getNextPageParam: ({ pagination }) => {
        if (pagination.current_page === pagination.total_pages) {
          return undefined;
        }
        return pagination.current_page + 1;
      },
      staleTime: Infinity
    }
  );
}
