import {
  BankAccount,
  bankAccountModel
} from 'data/models/entities/financials/bank-accounts';

import {
  ActionDeclaration,
  GroupedActionDeclaration
} from 'src/modules/common/actions/types/action-declaration-types';
import { useMakeBankAccountDefaultAction } from 'src/modules/bank-accounts/actions/use-make-bank-account-default-action';
import { useDeleteAction } from 'src/modules/common/actions/delete/use-delete-action';

export function useGetBankAccountActions() {
  const makeDefault = useMakeBankAccountDefaultAction();
  const deleteAction = useDeleteAction(bankAccountModel);

  return (bankAccount: BankAccount): ActionDeclaration[] => {
    if (!bankAccount) {
      return [];
    }

    const groupedActions: GroupedActionDeclaration = {
      label: 'Actions',
      actions: [deleteAction(bankAccount)]
    };

    return [
      ...(!bankAccount.is_default ? [makeDefault(bankAccount)] : []),
      groupedActions
    ];
  };
}
