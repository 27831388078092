import { RecordTypes } from 'data/models/types';
import { SelectNormalised } from '@rexlabs/select-old';
import { PropertyTenancy } from 'src/modules/property-tenancies/types/property-tenancy-types';

export function normalisePropertyTenancy(
  item: PropertyTenancy
): SelectNormalised {
  return {
    value: item,
    label: `${item?.tenancy?.display_name} - ${item?.property?.display_name}`,
    type: RecordTypes.PropertyTenancy
  };
}
