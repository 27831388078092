import * as React from 'react';
import { noop } from 'lodash';
import { BankAccount } from 'data/models/entities/financials/bank-accounts';
import { useBankAccounts } from 'src/modules/bank-accounts/hooks/use-bank-accounts';

const SelectedBankAccountContext = React.createContext<{
  setBankAccount: (bankAccount: BankAccount) => void;
  bankAccount: BankAccount | null;
}>({
  setBankAccount: noop,
  bankAccount: null
});

export function useSelectedBankAccountContext() {
  return React.useContext(SelectedBankAccountContext);
}

export function SelectedBankAccountProvider({
  children
}: {
  children: React.ReactNode;
}) {
  const [bankAccount, setBankAccount] = React.useState<BankAccount | null>(
    null
  );
  const { defaultBankAccount, isLoading } = useBankAccounts();

  React.useEffect(() => {
    if (!bankAccount && defaultBankAccount && !isLoading) {
      setBankAccount(defaultBankAccount);
    }
  }, [isLoading]);

  return (
    <SelectedBankAccountContext.Provider
      value={{ bankAccount, setBankAccount }}
    >
      {children}
    </SelectedBankAccountContext.Provider>
  );
}
