import { BaseModelGeneratorModel, EntityModel } from '@rexlabs/model-generator';

import { ActionDeclaration } from 'src/modules/common/actions/types/action-declaration-types';

import { useGetBulkCreateEmailAction } from './use-get-bulk-create-email-action';
import { useGetBulkCreateSmsAction } from './use-get-bulk-create-sms-action';
import { useGetBulkCreateLetterAction } from './use-get-bulk-create-letter-action';

export function useGetBulkMessageActions(model: EntityModel<any>) {
  const getBulkCreateEmailAction = useGetBulkCreateEmailAction(model);
  const getBulkCreateSmsAction = useGetBulkCreateSmsAction(model);
  const getBulkCreateLetterAction = useGetBulkCreateLetterAction(model);

  return (recipients: BaseModelGeneratorModel[]): ActionDeclaration[] => {
    return [
      getBulkCreateEmailAction(recipients),
      getBulkCreateSmsAction(recipients),
      getBulkCreateLetterAction(recipients)
    ];
  };
}
