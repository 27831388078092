import { Generator } from 'data/models/generator';
import { BaseModelGeneratorModel } from '@rexlabs/model-generator';
import { ValueListValue } from 'data/models/types';
import _ from 'lodash';
import { api } from 'utils/api/api-client';

export type ContactPaymentMethodMethod =
  | 'bpay'
  | 'cheque'
  | 'direct_debit'
  | 'eft'
  | 'eft_iban'
  | 'eft_swift'
  | 'eft_bacs'
  | 'eft_fps';

type ContactPaymentMethodStatus = 'active' | 'archived';

export type ContactPaymentMethod = BaseModelGeneratorModel & {
  name: string;
  payment_method: ValueListValue<ContactPaymentMethodMethod>;
  account_number: string | null;
  branch_code: string | null;
  reference: string | null;
  status: ValueListValue<ContactPaymentMethodStatus>;
  is_default: boolean;
};

const config = {
  entities: {
    api: {
      fetchList: (type, { contactId, ...args }) => {
        return api.get(`/contacts/${contactId}/payment-methods`, args);
      },
      createItem: (type, { contactId, ...args }) =>
        api.post(`/contacts/${contactId}/payment-methods`, args),
      trashItem: (type, { contactId }, id) => {
        return api.delete(`/contacts/${contactId}/payment-methods/${id}`);
      },
      updateItem: (type, { contactId, ...args }, id) => {
        return api.patch(`/contacts/${contactId}/payment-methods/${id}`, args);
      }
    }
  }
};

type ArchivePayload = {
  contactId: string;
  paymentMethodId: string;
};

const actionCreators = {
  archive: {
    request: (
      { contactId, paymentMethodId }: ArchivePayload,
      _actions,
      _dispatch,
      _getState
    ) =>
      api.put(
        `/contacts/${contactId}/payment-methods/${paymentMethodId}/status`,
        {
          status: {
            id: 'archived'
          }
        }
      ),
    reduce: {
      initial: _.identity,
      success: _.identity,
      failure: _.identity
    }
  }
};

export const contactsPaymentMethodsModel = new Generator<
  ContactPaymentMethod,
  typeof actionCreators
>(
  'contacts/payment-methods',
  //@ts-ignore
  config
).createEntityModel({ actionCreators });
