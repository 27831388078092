import React, { useState } from 'react';
import { BlockConfig } from 'view/components/record-screen/types';
import { TrustJournalEntryLineItemTable } from 'src/modules/trust-journal-entry-line-items/components/trust-journal-entry-line-item-table';
import { BankAccountSelect } from 'src/modules/bank-accounts/components/bank-account-select';
import LoadingState from 'view/components/states/compact/loading';
import { useBankAccounts } from 'src/modules/bank-accounts/hooks/use-bank-accounts';
import { useGetTrustLedgerForFolioAndBankAccount } from 'src/modules/trust-ledgers/hooks/use-get-trust-ledger-for-folio-and-bank-account';
import { toQuri } from '@rexlabs/quri';
import { query } from '@rexlabs/model-generator';
import { trustJournalEntryLineItemModel } from 'src/modules/trust-journal-entry-line-items/models/trust-journal-entry-line-item-model';
import { InfoBanner } from '@rexlabs/notifications';
import { useGetCreateLedgerSummaryDialogAction } from 'src/modules/trust-ledgers/hooks/use-get-create-ledger-summary-dialog-action';
import { OutlineButton } from '@rexlabs/button';
import { Trans } from 'react-i18next';

export const trustTransactionsWithBankAccountSelectBlock: BlockConfig = {
  id: 'trust-transactions',
  title: () => (
    <Trans i18nKey='financials.trust.trust-transactions.label.plural' />
  ),
  View: ({ data }) => {
    const [selectedBankAccount, setSelectedBankAccount] = useState(
      data.operating_bank_account ?? null
    );

    const { bankAccounts, defaultBankAccount } = useBankAccounts();

    React.useEffect(() => {
      if (!data.operating_bank_account) {
        setSelectedBankAccount(defaultBankAccount);
      }
    }, [bankAccounts]);

    const { trustLedger, isLoading } = useGetTrustLedgerForFolioAndBankAccount(
      data,
      selectedBankAccount
    );

    const filter = React.useMemo(() => {
      if (trustLedger) {
        return toQuri([
          {
            field: 'trust_ledger_id',
            op: '==',
            value: trustLedger.id
          }
        ]);
      }

      return null;
    }, [trustLedger]);

    const trustJournalEntryLineItemQuery = React.useMemo(() => {
      if (filter) {
        return query`{
        ${trustJournalEntryLineItemModel} (q: ${filter}, o: "created_at+desc"){
          id
          trust_journal_entry
          trust_ledger
        }
      }`;
      }
      return null;
    }, [filter]);

    const getCreateLedgerActivitySummaryDialogAction = useGetCreateLedgerSummaryDialogAction();
    const actionDeclaration = getCreateLedgerActivitySummaryDialogAction(
      trustLedger,
      selectedBankAccount
    );

    if (!selectedBankAccount || isLoading) {
      return <LoadingState />;
    }

    const showButton =
      'handleAction' in actionDeclaration && trustJournalEntryLineItemQuery;

    return (
      <>
        {showButton && (
          <OutlineButton
            onClick={() => {
              actionDeclaration.handleAction?.();
            }}
          >
            {actionDeclaration.label}
          </OutlineButton>
        )}
        <BankAccountSelect
          value={selectedBankAccount}
          onChange={(e) => setSelectedBankAccount(e.target.value)}
        />
        {trustJournalEntryLineItemQuery ? (
          <TrustJournalEntryLineItemTable
            showBalance={true}
            getQuery={() => trustJournalEntryLineItemQuery}
          />
        ) : (
          <InfoBanner
            description={`There is no transaction history for ${data.display_name} in ${selectedBankAccount.name}`}
          />
        )}
      </>
    );
  }
};
