import React from 'react';

import { ValueListItem } from 'utils/normaliser/value-list-item';
import {
  BasicEditEmailForm,
  BasicEditEmailFormProps
} from './basic-forms/basic-edit-email-form';
import { TemplatedAttachments } from './parts/templated-attachments';
import { UploadAttachments } from './parts/upload-attachments';

interface EditEmailTemplateFormProps extends BasicEditEmailFormProps {
  templatedAttachments?: ValueListItem[];
}

export function EditBulkEmailContentForm({
  contextType,
  templatedAttachments,
  setFieldValue
}: EditEmailTemplateFormProps) {
  return (
    <BasicEditEmailForm contextType={contextType} setFieldValue={setFieldValue}>
      <UploadAttachments />

      <TemplatedAttachments templatedAttachments={templatedAttachments} />
    </BasicEditEmailForm>
  );
}
