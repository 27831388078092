import React, { useCallback } from 'react';

import { RecordDialog } from 'view/components/record-screen/dialog/dialog';
import { DialogContentConfig } from 'view/components/record-screen/dialog/types';

import { RecordSubmitHandler } from 'view/components/record-screen/utils';
import { useModelActions } from '@rexlabs/model-generator';
import { filterNullValues } from 'utils/filter-null-values';
import { agencyFeeModel } from 'src/modules/agency-fees/models/agency-fee';
import { useToast } from 'view/components/@luna/notifications/toast';
import { Tag } from '@rexlabs/tag';
import { AgencyFee } from 'src/modules/agency-fees/types/agency-fee-types';
import { useTranslation } from 'react-i18next';
import { agencyFeeBlock } from '../blocks/agency-fee';
import { useHasServicePackagesFeature } from '../hooks/use-has-service-packages-feature';
import { EntityFeeType } from './edit-entity-fee';

const content: DialogContentConfig = [
  {
    id: 'agency-fee',
    label: '',
    blocks: [agencyFeeBlock]
  }
];

interface CreateNewAgencyFeeDialogProps {
  isFixture?: boolean;
  agencyFee?: Partial<AgencyFee>;
  entityFeeType?: EntityFeeType;
  onCreate?: (agencyFee: AgencyFee) => void;
  onClose?: () => void;
}

export function CreateNewAgencyFeeDialog({
  agencyFee = {},
  entityFeeType,
  onCreate,
  onClose,
  isFixture
}: CreateNewAgencyFeeDialogProps) {
  const { createItem, refreshLists } = useModelActions(agencyFeeModel);
  const { addToast } = useToast();
  const { t } = useTranslation();
  const hasServicePackagesFeature = useHasServicePackagesFeature();

  const handleSubmit: RecordSubmitHandler = useCallback(
    async ({ values }) => {
      const newAgencyFee = {
        ...filterNullValues(values),
        entity_type: {
          id: values.entity_type
        }
      };

      const response = await createItem({ data: newAgencyFee });

      if (!isFixture) {
        addToast({
          description: (
            <>
              Fee template <b>{(values as AgencyFee).name}</b> has been
              successfully created and{' '}
              {(values as AgencyFee).is_default_for_new
                ? 'will be'
                : 'will not be'}{' '}
              added to new agreements
            </>
          )
        });
      }

      refreshLists();

      onCreate?.(response.data as AgencyFee);

      return response.data;
    },
    [createItem, refreshLists]
  );

  let initialData = {
    ...agencyFee,
    entity_type: entityFeeType ?? agencyFee.entity_type?.id ?? undefined,
    is_tax_included: true
  };

  if (agencyFee?.fee_amount === undefined) {
    initialData = {
      ...initialData,
      fee_amount: {
        type: {
          label: 'Fixed amount',
          id: 'amount'
        },
        amount: NaN
      }
    };
  }

  if (agencyFee?.is_default_for_new === undefined) {
    initialData = {
      ...initialData,
      is_default_for_new: hasServicePackagesFeature ? false : true
    };
  }

  const titleString = hasServicePackagesFeature
    ? 'Create fee template'
    : 'Create agency fee template';
  const entityTypeIsPredefined = !!entityFeeType;

  return (
    <RecordDialog
      onClose={onClose}
      data={initialData}
      documentTitle={titleString}
      title={
        <>
          <span className={`${entityTypeIsPredefined && 'mr-xs'}`}>
            {titleString}
          </span>
          {entityTypeIsPredefined && (
            <Tag>
              {initialData.entity_type === 'ownership' && 'Ownership fee'}
              {initialData.entity_type === 'property_ownership' &&
                t(
                  'property-ownerships.management-agreement-fees.label.singular'
                )}
            </Tag>
          )}
        </>
      }
      submitLabel={titleString}
      handleSubmit={handleSubmit}
      content={content}
      blockProps={{
        entityTypeIsPredefined
      }}
    />
  );
}
