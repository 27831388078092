import { Dialog, DialogProps } from '@rexlabs/dialog';
import React from 'react';
import { PrimaryButton } from '@rexlabs/button';
import Box from '@rexlabs/box';
import { noop } from 'lodash';
import { InfoCircleIcon } from '../icons/info';
import { StandardDialogFooter } from './components/standard-dialog-footer';

interface AccessDeniedDialogProps extends DialogProps {
  role: string;
}

export function AccessDeniedDialog(props: AccessDeniedDialogProps) {
  const { role, onClose = noop } = props;

  return (
    <Dialog title='Request denied' TitleIcon={InfoCircleIcon}>
      <Box>
        You are currently a {role} user and do not have the necessary
        permissions to complete this action. Please contact an admin user to
        request access.
      </Box>
      <StandardDialogFooter>
        <PrimaryButton onClick={onClose}>Ok, I understand</PrimaryButton>
      </StandardDialogFooter>
    </Dialog>
  );
}
