import React from 'react';

import { ActionButtons } from 'view/components/@luna/action-buttons';
import {
  ActionDeclaration,
  ActionIntent
} from 'src/modules/common/actions/types/action-declaration-types';

import { omit } from 'lodash';
import { useFilteredActions } from 'utils/actions/use-filtered-actions';

const DEFAULT_AMOUNT_DISPLAYED_AS_BUTTON = 2;

export interface ActionsProps {
  actions: ActionDeclaration[];
  hideViewAction?: boolean;
  dropdownActionIntent?: ActionIntent;
  amountDisplayedAsButtons?: number;
}

export function Actions({
  actions,
  hideViewAction = true,
  dropdownActionIntent = 'secondary',
  amountDisplayedAsButtons = DEFAULT_AMOUNT_DISPLAYED_AS_BUTTON
}: ActionsProps) {
  const filteredActions = useFilteredActions({
    hideViewAction,
    actions: actions
  });

  // Displaying of actions need to be more refined
  // for now we filter all action which have an icon and removing the label to display them as icon buttons
  // the rest of them will be shown in an action dropdown list if there is more than 1 action, if there is 1 then we display them as an action button with the label and icon if available
  const isIconAction = (action: ActionDeclaration) =>
    'Icon' in action && action.label === '';

  const iconActions = filteredActions.filter(isIconAction);
  const regularActions = filteredActions.filter(
    (action) => !isIconAction(action)
  );

  return (
    <>
      {!!iconActions.length && (
        <ActionButtons
          actions={iconActions.map((action) => omit(action, 'label'))}
          flexWrap='nowrap'
          flexDirection='row-reverse'
          justifyContent='flex-end'
          alignItems='flex-start'
        />
      )}
      <>
        {regularActions.length <= amountDisplayedAsButtons && (
          <ActionButtons actions={regularActions} alignItems='flex-start' />
        )}
        {regularActions.length > amountDisplayedAsButtons && (
          <ActionButtons
            placement='bottom-end'
            actions={[
              {
                label: 'Actions',
                intent:
                  dropdownActionIntent === 'danger' ? 'danger' : undefined,
                actions: regularActions
              }
            ]}
            alignItems='flex-start'
          />
        )}
      </>
    </>
  );
}
