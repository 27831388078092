import React from 'react';

import { Field } from '@rexlabs/form';
import Box from '@rexlabs/box';

import EmptyTable from 'assets/illustrations/empty-table.svg';

import { Message } from 'view/components/@luna/message';
import { EmptyCard } from 'view/components/record-screen/cards/empty-card';
import { BlockConfig } from 'view/components/record-screen/types';
import { Column, Grid } from 'view/components/@luna/form/grid';
import { Value } from 'view/components/values';
import { PropertyComplianceCategorySelect } from 'view/components/inputs/selects/v4-selects/property-compliance-category';
import { AddNewCheckDialog } from 'src/modules/compliance/common/dialogs/add-new-check-dialog';
import { useDialog } from '@rexlabs/dialog';
import { FlattenedProperty } from 'src/modules/properties/types/property-types';

export const addPropertyComplianceCategoryBlock: BlockConfig<FlattenedProperty> = {
  id: 'property-compliance-category-block',
  title: 'Property compliance',
  validate: {
    definitions: {
      compliance_categories: { rules: 'required', name: 'categories' }
    }
  },
  showEmpty: (data) => !data?.compliance_categories?.length,
  Empty: ({ onEditClick, data }) => {
    const hasComplianceEntries = !!data?.compliance_entries?.length;

    const addNewCheckDialog = useDialog(AddNewCheckDialog);

    const emptyCopy = hasComplianceEntries
      ? 'Add the compliance categories that apply to this property.'
      : 'Add the compliance categories that apply to this property or add requirements individually.';

    return (
      <EmptyCard>
        <Message
          title={hasComplianceEntries ? 'Categories' : 'Insurance & compliance'}
          Illustration={EmptyTable}
          actions={[
            ...(!hasComplianceEntries
              ? [
                  {
                    label: 'Add requirement',
                    intent: 'secondary' as const,
                    handleAction: () =>
                      addNewCheckDialog.open({ propertyId: data?.id })
                  }
                ]
              : []),
            {
              label: 'Add category',
              intent: 'primary',
              handleAction: onEditClick
            }
          ]}
        >
          <div>{emptyCopy}</div>
        </Message>
      </EmptyCard>
    );
  },
  Edit: () => {
    return (
      <Grid columns={1}>
        <Box flexDirection='row' alignItems='center'>
          <Field
            name='compliance_categories'
            id='categories'
            label='Categories'
            Input={PropertyComplianceCategorySelect}
            inputProps={{ multi: true }}
          />
        </Box>
      </Grid>
    );
  },
  View: ({ data }) => {
    return (
      <Grid columns={2}>
        <Column width={2}>
          <Value
            label='Categories'
            value={data?.compliance_categories
              ?.map((category) => category.label)
              .join(', ')}
          />
        </Column>
      </Grid>
    );
  }
};
