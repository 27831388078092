import { ValueListValue } from 'data/models/types';
import {
  DisbursementFrequency,
  FrequencyType
} from 'src/modules/disbursements/types/frequency';
import { DisbursementFrequencyFormData } from '../types/DisbursementFrequencyFormData';

export function mapDisbursementFrequencyFormDataToDisbursementFrequency(
  formData: Partial<DisbursementFrequencyFormData>
): DisbursementFrequency {
  const data = {
    ...formData,
    on_frequency_count:
      formData?.disbursement_frequency_count_and_unit?.on_frequency_count,
    on_frequency_unit:
      formData?.disbursement_frequency_count_and_unit?.on_frequency_unit
  };

  function setValue(
    field: string,
    value: null | number | ValueListValue<FrequencyType>
  ) {
    data[field] = value;
  }

  if (formData.preset) {
    switch (formData.preset?.type?.id) {
      case 'daily':
        setValue('on_frequency_count', 1);
        setValue('on_frequency_unit', {
          id: 'days',
          label: 'Days'
        });
        break;
      case 'weekly':
        setValue('on_frequency_count', 1);
        setValue('on_frequency_unit', {
          id: 'weeks',
          label: 'Weeks'
        });
        break;
      case 'fortnightly':
        setValue('on_frequency_count', 2);
        setValue('on_frequency_unit', {
          id: 'weeks',
          label: 'Weeks'
        });
        break;
      case 'monthly':
        setValue('on_frequency_count', 1);
        setValue('on_frequency_unit', {
          id: 'months',
          label: 'Months'
        });
        break;
      // TODO: change this so that it maps to whatever data we need for the new format
      case 'twice_monthly':
        setValue('on_frequency_count', null);
        setValue('on_frequency_unit', null);
        break;
    }
  }

  delete data['disbursement_frequency_count_and_unit'];

  // Remove any null or undefined values
  Object.keys(data).forEach((key) => {
    if (data[key] === null || data[key] === undefined) {
      delete data[key];
    }
  });

  if (formData.preset?.type?.id === 'twice_monthly') {
    data['on_frequency_count'] = null;
    data['on_frequency_unit'] = null;
  }

  if (!formData.preset?.type?.id) {
    data['preset'] = undefined;
  }

  return data as Exclude<DisbursementFrequency, null>;
}
