import React, { useCallback } from 'react';

import { DialogProps, useErrorDialog } from '@rexlabs/dialog';
import { useModelActions } from '@rexlabs/model-generator';

import { useToast } from 'view/components/@luna/notifications/toast';
import { RecordDialog } from 'view/components/record-screen/dialog/dialog';
import { RecordSubmitHandler } from 'view/components/record-screen/utils';
import { portfoliosModel } from 'src/modules/portfolios/models/portfolios-model';
import { useRecordCreatedToast } from 'src/modules/common/toasts/hooks/use-record-created-toast';
import { detailsBlock } from '../blocks/details';
import { mapPortfolioFormDataToPortfolioCreateRequest } from '../mappers/map-portfolio-form-data-to-portfolio-create-request';

export function CreatePortfolioDialog({ onClose }: DialogProps) {
  const { addToast } = useToast();
  const addRecordCreatedToast = useRecordCreatedToast('portfolio');

  const errorDialog = useErrorDialog();

  const { createItem, refreshLists } = useModelActions(portfoliosModel);

  const content = [
    {
      id: 'create-portfolio',
      label: 'Create portfolio',
      blocks: [detailsBlock]
    }
  ];

  const handleSubmit: RecordSubmitHandler = useCallback(
    async ({ values }) => {
      try {
        const response = await createItem({
          data: mapPortfolioFormDataToPortfolioCreateRequest(values)
        });

        addRecordCreatedToast(response.data);
        await refreshLists();
        return response.data;
      } catch (error) {
        errorDialog.open(error);
      }
    },
    [createItem, addToast, errorDialog.open]
  );

  return (
    <RecordDialog
      title='Create portfolio'
      onClose={onClose}
      content={content}
      handleSubmit={handleSubmit}
      submitLabel='Create'
    />
  );
}
