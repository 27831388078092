import React from 'react';

import { Heading, Truncate } from '@rexlabs/text';
import { Grid } from 'view/components/@luna/form/grid';

interface InspectionRunResponsiveTitleProps {
  children: React.ReactNode;
}

export function InspectionRunResponsiveTitle({
  children
}: InspectionRunResponsiveTitleProps) {
  return (
    <Heading level={4} as={'span'}>
      <Grid columns={1}>
        <Truncate>{children}</Truncate>
      </Grid>
    </Heading>
  );
}
