import Box from '@rexlabs/box';
import { OutlineButton } from '@rexlabs/button';
import { FieldArray, HiddenField } from '@rexlabs/form';
import { StyleSheet, border, margin, useStyles } from '@rexlabs/styling';
import { Bold } from '@rexlabs/text';
import { Invoice } from 'data/models/entities/financials/invoices';
import React from 'react';
import ReceiptsTransfersWithdrawalsIllustration from 'src/assets/illustrations/receipts-transfers-withdrawals.svg';
import { DialogLineItem } from 'src/modules/common/components/dialog-line-item';
import { SearchResultItem } from 'utils/api/get-search-results';
import { useTaxPreview } from 'utils/tax-preview/use-tax-preview';
import { GridColumns } from 'view/components/@luna/form/grid/grid';
import { Message } from 'view/components/@luna/message';
import { AddIcon } from 'view/components/icons/add';
import { BlockViewProps } from 'view/components/record-screen/types';
import { useFormValueEffect } from 'view/hooks/@luna/use-form-value-effect';
import { InvoiceLineItem } from './invoice-line-item';
import { TaxPreview } from './tax-preview';

const defaultStyles = StyleSheet({
  hr: {
    ...margin.styles({
      y: 'l'
    }),
    ...border.styles({
      top: {
        width: 'thin',
        color: 'container.static.medium'
      },
      right: {
        width: 'none'
      },
      bottom: {
        width: 'none'
      },
      left: {
        width: 'none'
      }
    })
  }
});

interface InvoiceLineItemsProps
  extends Pick<BlockViewProps<Invoice>, 'values' | 'setFieldValue'> {
  payableBy: SearchResultItem;
  payableTo: SearchResultItem;
  invoiceLineItemColumns?: GridColumns;
  TaxWrapper?: React.ComponentType;
  taxWrapperProps?: any;
  prefix?: string;
  blockId: string;
  lineItemsFieldName?: string;
  isTaxIncludedFieldName?: string;
  handleRemoveReimbursement?: () => void;
}

export function InvoiceLineItemsForm({
  setFieldValue,
  values,
  payableBy,
  payableTo,
  prefix = 'Invoice',
  invoiceLineItemColumns,
  lineItemsFieldName = 'line_items',
  isTaxIncludedFieldName = 'is_tax_included',
  TaxWrapper = React.Fragment,
  taxWrapperProps = {},
  blockId,
  handleRemoveReimbursement
}: InvoiceLineItemsProps) {
  const s = useStyles(defaultStyles);
  const taxPreviewFieldName = `${prefix.toLowerCase()}_tax_preview`;

  const { isLoading, taxPreview, updateTaxPreviews } = useTaxPreview();

  const formDeps = [
    `${lineItemsFieldName}.*.tax_type`,
    `${lineItemsFieldName}.*.amount`,
    isTaxIncludedFieldName
  ];

  useFormValueEffect(
    blockId,
    ({ values }) => {
      const isTaxIncluded = !!values?.[isTaxIncludedFieldName];

      const lineItems = values[lineItemsFieldName];

      if (lineItems?.length) {
        const validItems = lineItems?.filter(
          (item) => item?.amount && item?.tax_type?.id
        );

        if (!validItems.length) return;

        updateTaxPreviews(isTaxIncluded, validItems);
      }
    },
    formDeps
  );

  React.useEffect(() => {
    setFieldValue?.(taxPreviewFieldName, taxPreview);
  }, [taxPreview, taxPreviewFieldName]);

  const difference =
    (taxWrapperProps?.invoiceTotal || 0) - (taxPreview?.amount_inc_tax || 0);

  return (
    <>
      <HiddenField name={taxPreviewFieldName} />

      <FieldArray name={lineItemsFieldName}>
        {function LineItem({ fields, push }) {
          if (!payableBy || !payableTo) {
            return (
              <Message
                Illustration={ReceiptsTransfersWithdrawalsIllustration}
                title='Select the relevant folios'
                grey={true}
              >
                Before adding in line items, please select the folio you wish to
                invoice from and bill to.
              </Message>
            );
          }
          return (
            <>
              <Box>
                {fields.map(({ field, actions }, index) => (
                  <DialogLineItem
                    key={field.name}
                    heading={<Bold>Invoice Line Item {index + 1}</Bold>}
                    onRemove={fields.length > 1 ? actions.remove : undefined}
                  >
                    <InvoiceLineItem
                      lineItemName={field.name}
                      payableBy={payableBy}
                      payableTo={payableTo}
                      testId={field.name}
                      columns={invoiceLineItemColumns}
                      setFieldValue={setFieldValue}
                    />
                  </DialogLineItem>
                ))}
              </Box>
              <Box mt='2rem' flexDirection='row' gap={12}>
                <OutlineButton
                  onClick={() => {
                    push({});
                  }}
                  IconLeft={AddIcon}
                >
                  Add line item
                </OutlineButton>

                {handleRemoveReimbursement && (
                  <OutlineButton onClick={handleRemoveReimbursement}>
                    Remove reimbursement invoice
                  </OutlineButton>
                )}
              </Box>
              <hr {...s('hr')} />
              <Box>
                <TaxWrapper {...taxWrapperProps} difference={difference}>
                  <TaxPreview
                    isTaxIncludedFieldName={isTaxIncludedFieldName}
                    prefix={prefix}
                    isLoading={isLoading}
                    taxPreview={taxPreview}
                    isTaxIncluded={values?.[isTaxIncludedFieldName]}
                    setFieldValue={setFieldValue}
                  />
                </TaxWrapper>
              </Box>
            </>
          );
        }}
      </FieldArray>
    </>
  );
}
