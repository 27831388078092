import React, { SFC } from 'react';
import { InputProps } from '@rexlabs/form';

import { Dropdown } from '@rexlabs/select-old';
import { PAYMENT_FREQUENCY_VALUE_LIST } from 'utils/static-value-lists/payment-frequency';
import {
  normaliseValueListItem,
  denormaliseValueListItem
} from 'utils/normaliser/value-list-item';

const items = [...PAYMENT_FREQUENCY_VALUE_LIST];

export const PaymentFrequencySelect: SFC<InputProps> = (props) => (
  <Dropdown
    {...props}
    items={items}
    normaliser={normaliseValueListItem}
    denormaliser={denormaliseValueListItem}
  />
);
