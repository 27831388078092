import { reduce } from 'lodash';
import { valueListFactory } from 'src/lib/testing/factories/value-list-factory';

import { parseUploadedFileMeta } from 'utils/files';
import { get } from 'utils/get';

import { SendImmediatelySelectOption } from '../../common/components/selects/send-immediately-select';
import { emptyMessageCreatorItem } from '../../common/data/empty-message-creator-item';
import { MessageTemplate } from '../types/MessageTemplate';
import { CreateTemplateFormData } from '../types/create-template-form-data';

export function mapTemplateToCreateMessageTemplateFormData(
  data: MessageTemplate
): CreateTemplateFormData {
  const attachments = data.inline_attachments || [];

  const emailChannel = data.channels?.find(
    (channel) => channel.channel_type.id === 'email'
  );

  const smsChannel = data.channels?.find(
    (channel) => channel.channel_type.id === 'sms'
  );

  const letterChannel = data.channels?.find(
    (channel) => channel.channel_type.id === 'letter'
  );

  return {
    name: data.name,
    context_type: data.context_type,
    recipient_group: {
      name: data.default_recipient_group
    },
    send_from_author: get(data, 'send_from_author') || emptyMessageCreatorItem,
    send_immediately: data?.send_immediately
      ? valueListFactory<SendImmediatelySelectOption>('send_immediately')
      : valueListFactory<SendImmediatelySelectOption>(
          'send_to_outbox_for_review'
        ),
    subject: emailChannel?.email_subject ?? '',
    content: emailChannel?.plain_text_body ?? '',
    smsContent: smsChannel?.plain_text_body ?? '',
    letterContent: letterChannel?.plain_text_body ?? '',
    letterAddress: letterChannel?.letter_address ?? '',
    attachments: parseUploadedFileMeta(attachments),
    linked_files: data.linked_files || [],
    include_generated_attachments: reduce(
      data.templated_attachments,
      (acc, val) => {
        return {
          ...acc,
          [val]: true
        };
      },
      {} as Record<string, boolean>
    ),
    document_types: data.document_types || [],
    compliance_types: data.compliance_types || [],
    send_if_no_documents_found_with_related_types: !!data.send_if_no_documents_found_with_related_types,
    send_if_no_compliance_entries_found_with_related_types: !!data.send_if_no_compliance_entries_found_with_related_types
  };
}
