import {
  BaseFields,
  RecordTypes,
  TimestampAttributes,
  ValueListValue
} from 'data/models/types';

export const Roles = {
  Admin: 'admin',
  Standard: 'standard',
  Financial: 'financial'
} as const;

type R = keyof typeof Roles;
export type RoleNames = typeof Roles[R];

export type Role = BaseFields<typeof RecordTypes.Role> &
  TimestampAttributes & {
    name: string;
    description: string;
    system_purpose: ValueListValue<RoleNames> | null;
  };
