import { get } from 'lodash';
import { BankAccount } from 'data/models/entities/financials/bank-accounts';

export function getBankAccount(forms) {
  const bankAccount = get(
    forms,
    'receipt-funds-details.values.bank_account'
  ) as BankAccount | null;

  if (bankAccount) return bankAccount;

  const transferFundsBankAccount = get(
    forms,
    'transfer-funds-details.values.bank_account'
  ) as BankAccount | null;

  return transferFundsBankAccount;
}
