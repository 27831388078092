import React, { FC, useCallback } from 'react';
import { InputProps } from '@rexlabs/form';
import { Autocomplete } from '@rexlabs/select-old';

import { api } from 'utils/api/api-client';
import { normalisePropertyOwnership } from 'utils/normaliser/property-ownership';
import { PropertyOwnership } from 'src/modules/property-ownerships/types/property-ownership-types';

export const PropertyOwnershipSelect: FC<InputProps> = (props) => {
  const items = useCallback(async (term) => {
    const propertyOwnerships = await api.get<PropertyOwnership[]>(
      `/property-ownerships`,
      {
        per_page: 30,
        include: 'property,ownership',
        search: term
      }
    );
    // HACK: Manually normalise this the first time with a different label because once a value
    // is set, the normalise function will only have access to `id` and `name` properties.
    // referencing the other data here would fail.
    return propertyOwnerships.data;
  }, []);

  return (
    <Autocomplete
      items={items}
      normaliser={normalisePropertyOwnership}
      {...props}
    />
  );
};
