import { Iframe } from 'view/components/iframe/iframe';
import * as React from 'react';
import { useToken } from '@rexlabs/styling';

export function PreviewStatementsIframe({
  zoom,
  html
}: {
  zoom: number;
  html: string;
}) {
  const token = useToken();
  return (
    <Iframe
      css={`
        html {
          zoom: ${Number(zoom) ?? 1};
          padding: 0 25px 0 25px !important;
        }

        body,
        html {
          height: auto;
          margin: 0;
          padding: 0;
          background-color: ${token('palette.grey.200')};
        }

        body > div {
          width: 100%;
          max-width: 1024px;
          margin: 30px auto 30px auto !important;
          padding: 30px !important;
          box-shadow: 0 3px 12px rgba(0, 0, 0, 0.15);
          background-color: white;
          border-radius: 6px;
        }
      `}
      html={html}
      style={{ flex: 1 }}
    />
  );
}
