import { valueListFactory } from 'src/lib/testing/factories/value-list-factory';
import { mapTaskFormDataToTaskCreateRequest } from '../../common/mappers/map-task-form-data-to-task-create-request';
import { TaskCreateRequest } from '../../common/types/TaskCreateRequest';
import { LeaseReviewFormData } from '../types/LeaseReviewFormData';

export function mapLeaseReviewFormDataToTaskCreateRequest(
  formData: Partial<LeaseReviewFormData>
): Partial<TaskCreateRequest> {
  // map the base values for the task
  const base = mapTaskFormDataToTaskCreateRequest(formData);

  const details = formData.details! ?? {};

  // Remove any null values
  Object.keys(details).forEach((key) => {
    if (details[key] === null) {
      delete details[key];
    }
  });

  return {
    ...base,
    type: valueListFactory('task_lease_review'),
    details
  };
}
