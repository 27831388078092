import React, { useCallback, useMemo } from 'react';
import { BREADCRUMBS } from 'view/components/@luna/breadcrumbs';

import { Card } from 'view/components/card';
import { ListScreen } from 'view/components/list-screen/list-screen';
import { ListTable, TabbedTable } from 'view/components/table';
import { ListTableProps } from 'view/components/table/list-table';
import { Tab } from 'view/components/table/tabbed';
import { query } from '@rexlabs/model-generator';
import { useBankAccountSelectActions } from 'src/modules/bank-accounts/hooks/use-bank-account-select-actions';
import ROUTES from 'routes/app';
import RenderLoading from 'view/components/@luna/render-loading';
import LoadingState from 'view/components/states/compact/loading';
import { useTableFilters } from 'view/hooks/use-table-filters';
import { bankStatementTransactionImportsModel } from '../models/bank-statement-transaction-imports-model';
import { useUploadedBankStatementActions } from '../hooks/use-uploaded-bank-statement-actions';
import { uploadedBankStatementTransactionsColumns } from '../utils/uploaded-bank-statement-transactions-columns';

const getBankStatementTransactionImportsQuery = () => query`{
  ${bankStatementTransactionImportsModel} {
    id
    file
  }
}`;

export const UploadedBankStatementsList = () => {
  const {
    isLoading: isLoadingBankAccounts,
    bankAccount,
    actions
  } = useBankAccountSelectActions(ROUTES.BATCH_RECEIPTING_STATEMENTS);

  const { getSort, getFilters } = useTableFilters(
    'bank-statement-transaction-imports'
  );

  const uploadedStatementActions = useUploadedBankStatementActions();

  const getActionMenuItems = useCallback(
    ({ item }) => {
      return uploadedStatementActions(item, bankAccount?.id);
    },
    [uploadedStatementActions, bankAccount?.id]
  );

  const commonTabProps = useMemo<Partial<Tab<ListTableProps>>>(() => {
    return {
      columns: uploadedBankStatementTransactionsColumns,
      id: 'statements',
      getQuery: getBankStatementTransactionImportsQuery,
      Table: ListTable,
      getSort,
      getFilters,
      suggestedFilters: ['transactions_date_range', 'status_id'],
      getActionMenuItems
    };
  }, [getFilters, getSort, getActionMenuItems]);

  const bankAccountId = bankAccount?.id;
  const tabs = useMemo<Tab[]>(() => {
    const defaultForcedFilters = [
      {
        field: 'bank_account_id',
        op: 'eq',
        value: bankAccountId
      }
    ];

    return [
      {
        ...commonTabProps,
        name: 'all',
        label: 'All',
        forcedGlobalFilter: defaultForcedFilters
      },
      {
        ...commonTabProps,
        name: 'in_progress',
        label: 'In Progress',
        forcedGlobalFilter: [
          ...defaultForcedFilters,
          {
            field: 'status_id',
            op: 'eq',
            value: { value: 'in_progress', label: 'In Progress' }
          }
        ]
      },
      {
        ...commonTabProps,
        name: 'completed',
        label: 'Completed',
        forcedGlobalFilter: [
          ...defaultForcedFilters,
          {
            field: 'status_id',
            op: 'eq',
            value: { value: 'completed', label: 'Completed' }
          }
        ]
      }
    ];
  }, [bankAccountId, commonTabProps]);

  const breadcrumbs = [
    {
      type: BREADCRUMBS.BATCH_RECEIPTING,
      args: {
        bankAccountId: bankAccount?.id
      }
    }
  ];

  return (
    <RenderLoading isLoading={isLoadingBankAccounts} LoadingView={LoadingState}>
      <ListScreen
        title='Uploaded bank statements'
        breadcrumbs={breadcrumbs}
        actions={actions}
      >
        <Card>
          <TabbedTable tabs={tabs} />
        </Card>
      </ListScreen>
    </RenderLoading>
  );
};
