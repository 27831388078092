import React from 'react';
import { RecordFlag } from 'view/components/record-flags/record-flag';
import { PropertyTenancy } from 'src/modules/property-tenancies/types/property-tenancy-types';
import { EmptyProperties } from '../empty/empty-properties';
import { EntryList } from '../components/entry-list';
import { EntryLink } from '../components/entry-link';
import { cap, hasMoreThan } from '../utils';
import { PropertyTenancyEntryLink } from '../components/property-tenancy-entry-link';
import { ViewLink } from '../components/view-link';

export interface PropertyTenanciesSectionProps {
  propertyTenancies: PropertyTenancy[] | 'empty';
  maxListLength: number;
}

export function PropertyTenanciesSection({
  propertyTenancies,
  maxListLength
}: PropertyTenanciesSectionProps) {
  if (propertyTenancies === 'empty' || propertyTenancies.length === 0) {
    return <EmptyProperties />;
  }

  const maxPropertyTenancies = Math.round(maxListLength / 2);

  return (
    <EntryList>
      {cap(propertyTenancies, maxPropertyTenancies).map((propertyTenancy) => (
        <>
          {propertyTenancy.property && (
            <EntryLink
              key={propertyTenancy.property.id}
              type='property'
              id={propertyTenancy.property.id}
            >
              <RecordFlag
                record={propertyTenancy.property}
                recordType='property'
              />
            </EntryLink>
          )}
          <PropertyTenancyEntryLink propertyTenancy={propertyTenancy} />
        </>
      ))}
      {hasMoreThan(propertyTenancies, maxPropertyTenancies) && (
        <ViewLink query={{ tab: 'overview' }}>
          View all {propertyTenancies.length} properties
        </ViewLink>
      )}
    </EntryList>
  );
}
