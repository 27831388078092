import { makeIconComponent } from '@rexlabs/icons';

import SmallManagementAgreementSvg from 'assets/icons/s/management-agreement.svg';
import MediumManagementAgreementSvg from 'assets/icons/m/management-agreement.svg';
import LargeManagementAgreementSvg from 'assets/icons/l/management-agreement.svg';
import ExtraLargeManagementAgreementSvg from 'assets/icons/xl/management-agreement.svg';

export const ManagementAgreementIcon = makeIconComponent({
  s: SmallManagementAgreementSvg,
  m: MediumManagementAgreementSvg,
  l: LargeManagementAgreementSvg,
  xl: ExtraLargeManagementAgreementSvg
});

export default ManagementAgreementIcon;
