import { StyleSheet, useStyles } from '@rexlabs/styling';
import { useSessionState } from 'src/modules/common/hooks/session-state';
import { Grid } from 'view/components/@luna/form/grid';
import { Body } from '@rexlabs/text';
import { InfoBanner } from 'view/components/@luna/notifications/banner';
import InfoCircleIcon from 'view/components/icons/info';
import React from 'react';
import { Accordion } from 'view/components/@luna/accordion/accordion';
import { GridColumns } from 'view/components/@luna/form/grid/grid';
import { formatCurrency } from 'utils/formatters';

const styles = StyleSheet({
  list: {
    listStyleType: 'disc',
    paddingLeft: 12,
    marginLeft: 12
  },
  grid: {
    rowGap: 0
  },
  intro_text: {
    paddingTop: 0
  }
});

type InfoProps = {
  showExampleByDefault: boolean;
  maxColumns: GridColumns;
};

const ConsolidatedInfo = ({ showExampleByDefault, maxColumns }: InfoProps) => {
  const s = useStyles(styles);
  const sessionData = useSessionState();
  const siloName = sessionData.activeSilo!.label;

  return (
    <Grid columns={1} {...s('grid')}>
      <Grid columns={maxColumns}>
        <div>
          <Body {...s('intro_text')}>
            The {siloName} account has consolidated payments enabled. This means
            that if multiple owners use the same bank account, only one
            transaction will be made.
          </Body>
          <Body>Example: John Doe is part of two ownerships.</Body>
        </div>
      </Grid>
      <Accordion headline={'Example:'} initialOpen={showExampleByDefault}>
        <Body>John Doe is part of two ownerships.</Body>
        <Grid columns={maxColumns}>
          <Body>
            <strong>Ownership 1</strong> <br />
            Owners: John Doe (100%) <br />
            Payment: {formatCurrency(100_00)} <br />
            Payment Method: <br />
            <ul {...s('list')}>
              <li>John&apos;s Account Details: 123456</li>
            </ul>
          </Body>
          <Body>
            <strong>Ownership 2</strong> <br />
            Owners: John Doe (50%), Frederic Doberman (50%) <br />
            Payment: {formatCurrency(100_00)} <br />
            Payment Methods:
            <ul {...s('list')}>
              <li>John&apos;s Account Details: 123456</li>
              <li>Frederic&apos;s Account Details: 222222</li>
            </ul>
          </Body>
        </Grid>
        <Grid columns={maxColumns}>
          <div>
            <Body>
              At the end of the month, the agency processes the payments for
              both ownerships. The agency combines the withdrawals for Ownership
              1 and Ownership 2 into a single bank withdrawal. The export file
              will include the following payments: <br />
            </Body>
            <Body>
              Payment 1: {formatCurrency(150_00)} to Account Details: 123456
              (John Doe&apos;s account)
              <br />
              Payment 3: {formatCurrency(50_00)} to Account Details: 222222
              (Frederic Doberman&apos;s account)
            </Body>
          </div>
        </Grid>
      </Accordion>
      <Grid columns={maxColumns}>
        <Body>
          If you have any questions, please contact{' '}
          <a href={'mailto:support@rexpm.zendesk.com'}>Rex PM Support</a>.
        </Body>
      </Grid>
    </Grid>
  );
};

const UnConsolidatedInfo = ({
  showExampleByDefault,
  maxColumns
}: InfoProps) => {
  const s = useStyles(styles);
  const sessionData = useSessionState();
  const siloName = sessionData.activeSilo!.label;

  return (
    <Grid columns={1} {...s('grid')}>
      <Grid columns={maxColumns}>
        <div>
          <Body {...s('intro_text')}>
            The {siloName} account has consolidated payments disabled. This
            means that if multiple owners use the same bank account, only one
            transaction will be made for each withdrawal.
          </Body>
        </div>
      </Grid>
      <Accordion headline={'Example: '} initialOpen={showExampleByDefault}>
        <Body>John Doe is part of two ownerships.</Body>
        <Grid columns={maxColumns}>
          <Body>
            <strong>Ownership 1</strong> <br />
            Owners: John Doe (100%) <br />
            Payment: {formatCurrency(100_00)} <br />
            Payment Method: <br />
            <ul {...s('list')}>
              <li>John&apos;s Account Details: 123456</li>
            </ul>
          </Body>
          <Body>
            <strong>Ownership 2</strong> <br />
            Owners: John Doe (50%), Frederic Doberman (50%) <br />
            Payment: {formatCurrency(100_00)} <br />
            Payment Methods:
            <ul {...s('list')}>
              <li>John&apos;s Account Details: 123456</li>
              <li>Frederic&apos;s Account Details: 222222</li>
            </ul>
          </Body>
        </Grid>
        <Grid columns={maxColumns}>
          <div>
            <Body>
              At the end of the month, the agency processes the payments for
              both ownerships. The agency does not combines the withdrawals for
              Ownership 1 and Ownership 2 into a single bank withdrawal. The
              export file will include the following payments: <br />
            </Body>
            <Body>
              Payment 1: {formatCurrency(100_00)} to Account Details: 123456
              (John Doe&apos;s account)
              <br />
              Payment 2: {formatCurrency(50_00)} to Account Details: 123456
              (John Doe&apos;s account)
              <br />
              Payment 3: {formatCurrency(50_00)} to Account Details: 222222
              (Frederic Doberman&apos;s account)
            </Body>
          </div>
        </Grid>
      </Accordion>
      <Grid columns={maxColumns}>
        <Body>
          If you have any questions, please contact{' '}
          <a href={'mailto:support@rexpm.zendesk.com'}>Rex PM Support</a>.
        </Body>
      </Grid>
    </Grid>
  );
};

type Props = {
  enabled: boolean;
  showExampleByDefault?: boolean;
  maxColumns?: GridColumns;
};

export const ConsolidateWithdrawalsInfoBanner = ({
  enabled,
  showExampleByDefault = true,
  maxColumns = 2
}: Props) => (
  <InfoBanner
    Icon={InfoCircleIcon}
    description={
      enabled ? (
        <ConsolidatedInfo
          showExampleByDefault={showExampleByDefault}
          maxColumns={maxColumns}
        />
      ) : (
        <UnConsolidatedInfo
          showExampleByDefault={showExampleByDefault}
          maxColumns={maxColumns}
        />
      )
    }
  />
);
