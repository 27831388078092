import React from 'react';
import { BlockConfig } from 'view/components/record-screen/types';
import { Field } from '@rexlabs/form';
import { ReconciliationAdjustmentTypeSelect } from 'view/components/inputs/selects/reconciliation-adjustment-type';
import { DateInput } from 'view/components/@luna/inputs/date-input/date-input';
import { TextArea } from '@rexlabs/text-input';
import { CentAmountInput } from 'view/components/inputs/cent-amount';
import { Column } from '@rexlabs/grid';
import { FormGrid } from 'view/components/@luna/grid/form';
import { SingleLineFormLayout } from 'view/components/layouts/form/single-line-form-layout';
import dayjs from 'dayjs';

export const reconciliationAdjustmentBlock: BlockConfig = {
  id: 'reconciliation-adjustment',
  title: 'Adjustment',
  validate: {
    definitions: {
      date_of: { rules: 'required' },
      type: { rules: 'required' },
      amount: { rules: 'required' },
      reason: { rules: 'required' }
    }
  },
  Edit: ({ blockProps: { statementDate } }) => {
    const isOutsideRange = (momentDate: moment.Moment) => {
      const date = dayjs(momentDate.toDate());
      return date.isAfter(dayjs(statementDate));
    };

    return (
      <FormGrid>
        <Column width={6}>
          <Field
            name='date_of'
            label='Adjustment date'
            Input={DateInput}
            inputProps={{
              isOutsideRange,
              defaultValue: statementDate
            }}
          />
        </Column>
        <Column width={12}>
          <SingleLineFormLayout>
            <Field
              name='type'
              label='Type'
              Input={ReconciliationAdjustmentTypeSelect}
            />
            <Field name='amount' label='Amount' Input={CentAmountInput} />
          </SingleLineFormLayout>
        </Column>
        <Column width={12}>
          <Field name='reason' label='Reason' Input={TextArea} />
        </Column>
      </FormGrid>
    );
  }
};
