import React from 'react';

import { Select, SelectProps } from '@rexlabs/select';

import { ContactPaymentMethodMethod } from 'data/models/entities/contacts/payment-methods';
import { useTrustWithdrawalPaymentMethods } from 'src/modules/financials/hooks/use-withdrawal-payment-methods';

export function WithdrawalPaymentMethodSelect(
  props: SelectProps<any> & { excludeIds?: ContactPaymentMethodMethod[] }
) {
  const items = useTrustWithdrawalPaymentMethods(props?.excludeIds);
  return <Select {...props} items={items} normaliser={(val) => val} />;
}
