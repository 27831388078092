import React from 'react';
import { useConfirmationDialog } from '@rexlabs/dialog';
import { FLAGS } from 'utils/feature-flags';
import { ActionDeclaration } from '../../../modules/common/actions/types/action-declaration-types';

export interface GetGenericWipActionArgs<
  ID extends string,
  Group extends string
> {
  id: ID;
  group: Group;
  actionName: string;
  label?: string;
  message?: React.ReactNode;
}

export function useGetGenericWipAction() {
  const { open: openConfirmationDialog } = useConfirmationDialog();

  return <ID extends string, Group extends string>({
    id,
    group,
    actionName,
    label,
    message
  }: GetGenericWipActionArgs<ID, Group>): ActionDeclaration => {
    return {
      id,
      group,
      label: label ?? actionName,
      handleAction: () =>
        openConfirmationDialog({
          title: actionName,
          message: message ?? 'This action is not yet implemented',
          confirmText: 'OK'
        }),
      workInProgress: true,
      flag: FLAGS.WIP_ACTIONS
    };
  };
}
