import { DASHBOARD_ROUTES } from 'src/modules/dashboard/routes';
import { CONTACT_ROUTES } from 'src/modules/contacts/routes';
import { SETTINGS_ROUTES } from 'src/modules/settings/routes';
import { PROPERTIES_ROUTES } from 'src/modules/properties/routes';
import { TENANCIES_ROUTES } from 'src/modules/tenancies/routes';
import { OWNERSHIPS_ROUTES } from 'src/modules/ownerships/routes';
import { INVOICES_ROUTES } from 'src/modules/invoices/routes';
import { TRUST_ACCOUNT_ROUTES } from 'src/modules/trust-accounts/routes';
import { TRUST_LEDGERS_ROUTES } from 'src/modules/trust-ledgers/routes';
import { BANK_DEPOSITS_ROUTES } from 'src/modules/banking/bank-deposits/routes';
import { USER_ROUTES } from 'src/modules/users/routes';
import { FINANCIALS } from 'src/modules/financials/routes';
import { PORTFOLIOS_ROUTES } from 'src/modules/portfolios/routes';
import { CREDIT_NOTES_ROUTES } from 'src/modules/credit-notes/routes';
import { TRUST_JOURNAL_ROUTES } from 'src/modules/trust-journal-entries/routes';
import { BANK_WITHDRAWALS_ROUTES } from 'src/modules/banking/bank-withdrawals/routes';
import { RECONCILIATIONS_ROUTES } from 'src/modules/reconciliations/routes';
import { AGENCY_FEES } from 'src/modules/agency-fees/routes';
import { DISBURSEMENT_ROUTES } from 'src/modules/disbursements/routes';
import { SECURITY_DEPOSITS } from 'src/modules/security-deposits/routes';
import { BANK_ACCOUNT_ROUTES } from 'src/modules/bank-accounts/routes';
import { CHART_OF_ACCOUNTS_ROUTES } from 'src/modules/chart-of-accounts/routes';
import { PERIODIC_STATEMENTS_ROUTES } from 'src/modules/ownership-statements/periodic-statements/routes';
import { ACCOUNTING_JOURNAL_ENTRY_ROUTES } from 'src/modules/account-journal-entries/routes';
import { EOY_STATEMENTS_ROUTES } from 'src/modules/ownership-statements/eoy-statements/routes';
// INJECT: Import routes here
import { AUDIT_LOG_ROUTES } from 'src/modules/audit-logs/routes';
import { PREPAYMENTS_ROUTES } from 'src/modules/prepayments/routes';
import { PROPERTY_AREA_TYPE_ROUTES } from 'src/modules/property-areas/property-area-types/routes';
import { WORK_ORDERS_ROUTES } from 'src/modules/tasks/work-orders/routes';
import { QUOTE_ROUTES } from 'src/modules/tasks/quotes/routes';
import { MOVE_OUT_ROUTES } from 'src/modules/tasks/move-out/routes';
import { BILL_PROCESSING_ROUTES } from 'src/modules/bill-processing/routes';
import { MOVE_IN_ROUTES } from 'src/modules/tasks/move-in/routes';
import { LEASE_REVIEW_ROUTES } from 'src/modules/tasks/lease-review/routes';
import { TODO_ROUTES } from 'src/modules/tasks/todos/routes';
import { TASK_SETTINGS_ROUTES } from 'src/modules/tasks/settings/routes';
import { REPORTING_ROUTES } from 'src/modules/reporting/routes';
import { MESSAGE_ROUTES } from 'src/modules/communications/messages/routes';
import { MESSAGE_TEMPLATE_ROUTES } from 'src/modules/communications/message-templates/routes';
import { MAINTENANCE_TASKS_ROUTES } from 'src/modules/tasks/maintenance/routes';
import { BATCH_RECEIPTING_ROUTES } from 'src/modules/banking/batch-receipting/routes';
import { INSPECTION_TASK_ROUTES } from 'src/modules/tasks/inspections/routes';
import { ARREARS_ROUTES } from 'src/modules/tasks/arrears/routes';
import { PROPERTY_COMPLIANCE_ROUTES } from 'src/modules/compliance/property-compliance/routes';
import { SUPPLIER_COMPLIANCE_ROUTES } from 'src/modules/compliance/supplier-compliance/routes';
import { PROPERTY_COMPLIANCE_TASK_ROUTES } from 'src/modules/tasks/property-compliance/routes';
import { SUPPLIER_COMPLIANCE_TASK_ROUTES } from 'src/modules/tasks/supplier-compliance/routes';
import { LETTER_LAYOUT_ROUTES } from 'src/modules/communications/letter-layouts/routes';
import { SUBSCRIPTIONS_ROUTES } from 'src/modules/settings/subscriptions/routes';
import { TASK_ROUTES } from 'src/modules/tasks/common/routes';
import { TAX_ROUTES } from 'src/modules/tax/routes';
import { SERVICE_PACKAGES_ROUTES } from 'src/modules/service-packages/routes';
import { SUPPLIER_COMMISSION_ROUTES } from 'src/modules/supplier-commission/routes';

const ROUTES = {
  ...SUPPLIER_COMMISSION_ROUTES,
  ...AUDIT_LOG_ROUTES,
  ...PREPAYMENTS_ROUTES,
  ...PROPERTY_AREA_TYPE_ROUTES,
  ...WORK_ORDERS_ROUTES,
  ...QUOTE_ROUTES,
  ...PROPERTY_COMPLIANCE_TASK_ROUTES,
  ...SUPPLIER_COMPLIANCE_TASK_ROUTES,
  ...LETTER_LAYOUT_ROUTES,
  ...ARREARS_ROUTES,
  ...TASK_SETTINGS_ROUTES,
  ...INSPECTION_TASK_ROUTES,
  ...MOVE_OUT_ROUTES,
  ...BILL_PROCESSING_ROUTES,
  ...MOVE_IN_ROUTES,
  ...LEASE_REVIEW_ROUTES,
  ...MAINTENANCE_TASKS_ROUTES,
  ...TODO_ROUTES,
  ...MESSAGE_ROUTES,
  ...REPORTING_ROUTES,
  ...MESSAGE_TEMPLATE_ROUTES,
  ...ACCOUNTING_JOURNAL_ENTRY_ROUTES,
  ...DASHBOARD_ROUTES,
  ...CONTACT_ROUTES,
  ...PROPERTIES_ROUTES,
  ...OWNERSHIPS_ROUTES,
  ...TRUST_ACCOUNT_ROUTES,
  ...INVOICES_ROUTES,
  ...TENANCIES_ROUTES,
  ...SETTINGS_ROUTES,
  ...TRUST_LEDGERS_ROUTES,
  ...BANK_DEPOSITS_ROUTES,
  ...USER_ROUTES,
  ...FINANCIALS,
  ...PORTFOLIOS_ROUTES,
  ...CREDIT_NOTES_ROUTES,
  ...TRUST_JOURNAL_ROUTES,
  ...BANK_WITHDRAWALS_ROUTES,
  ...RECONCILIATIONS_ROUTES,
  ...AGENCY_FEES,
  ...DISBURSEMENT_ROUTES,
  ...SECURITY_DEPOSITS,
  ...BANK_ACCOUNT_ROUTES,
  ...CHART_OF_ACCOUNTS_ROUTES,
  ...PERIODIC_STATEMENTS_ROUTES,
  ...EOY_STATEMENTS_ROUTES,
  ...BATCH_RECEIPTING_ROUTES,
  ...PROPERTY_COMPLIANCE_ROUTES,
  ...SUPPLIER_COMPLIANCE_ROUTES,
  ...SUBSCRIPTIONS_ROUTES,
  ...TASK_ROUTES,
  ...TAX_ROUTES,
  ...SERVICE_PACKAGES_ROUTES,
  ...PERIODIC_STATEMENTS_ROUTES,
  ...EOY_STATEMENTS_ROUTES,

  GLOBAL_ADD_TENANCY: {
    config: {
      path: '(.*)',
      hash: 'create-tenancy',
      Component: require('../modules/tenancies/dialogs/create-tenancy-dialog')
        .CreateTenancyDialog
    }
  },

  GLOBAL_ADD_OWNERSHIP: {
    config: {
      path: '(.*)',
      hash: 'create-ownership',
      Component: require('../modules/ownerships/dialogs/create-ownership-dialog')
        .CreateOwnershipDialog
    }
  },

  GLOBAL_ADD_CONTACT: {
    config: {
      path: '(.*)',
      hash: 'create-contact',
      Component: require('../modules/contacts/dialogs/create-contact-dialog')
        .CreateContactDialog
    }
  },

  GLOBAL_ADD_INVOICE: {
    config: {
      path: '/(.*)',
      hash: 'create-invoice',
      Component: require('../modules/invoices/dialogs/create-invoice-dialog')
        .CreateInvoiceDialog
    }
  },

  GLOBAL_ADD_PROPERTY: {
    config: {
      path: '/(.*)',
      hash: 'create-property',
      Component: require('../modules/properties/dialogs/create-property-dialog')
        .CreatePropertyDialog
    }
  },

  // Redirects
  LOGIN: {
    config: {
      path: '/login',
      redirect: {
        path: '/dashboard'
      }
    }
  },

  HOME: {
    config: {
      path: '/',
      exact: true,
      redirect: {
        path: '/dashboard'
      }
    }
  }
} as const;

export default ROUTES;
