import { useModelActions } from '@rexlabs/model-generator';
import React, { useCallback } from 'react';

import { useBankAccounts } from 'src/modules/bank-accounts/hooks/use-bank-accounts';
import { trustJournalEntryModel } from 'src/modules/trust-journal-entries/models/trust-journal-entries';
import { filterNullValues } from 'utils/filter-null-values';
import { RecordDialog } from 'view/components/record-screen/dialog/dialog';
import { DialogContentConfig } from 'view/components/record-screen/dialog/types';
import { RecordSubmitHandler } from 'view/components/record-screen/utils';

import { BankAccount } from 'data/models/entities/financials/bank-accounts';
import { withdrawFundsPaymentDetailsBlock } from 'src/modules/trust-journal-entries/blocks/withdrawal-payment-details';

import { getSearchResultItemFromObjectAndModel } from 'src/modules/common/utils/search-result-items/get-search-result-items-from-object-and-model';
import { FinancialEntity } from 'src/modules/financials/utils/financial-entity-action-group/use-get-financial-entity-actions';
import { withdrawalDetailsBlock } from '../blocks/withdrawal-details';
import { withdrawalInfoBlock } from '../blocks/withdrawal-info';
import { getCreateWithdrawalChangeHandlers } from '../utils/get-create-withdrawal-change-handlers';

const content: DialogContentConfig = [
  {
    id: 'details',
    label: 'Details',
    blocks: [
      withdrawalInfoBlock,
      withdrawalDetailsBlock,
      withdrawFundsPaymentDetailsBlock
    ]
  }
];

interface WithdrawFundsDialogProps {
  onClose?: () => void;
  onCreate?: () => void;
  initialValues?: { bankAccount?: BankAccount; folio?: FinancialEntity };
  showBannerMessage?: boolean;
}

export function WithdrawFundsDialog({
  onClose,
  onCreate,
  initialValues,
  showBannerMessage = false
}: WithdrawFundsDialogProps) {
  const { defaultBankAccount, isLoading } = useBankAccounts();
  const { createItem, refreshLists } = useModelActions(trustJournalEntryModel);
  const handleSubmit: RecordSubmitHandler = useCallback(
    async ({ values }) => {
      const response = await createItem({
        data: { withdrawal: filterNullValues(values) }
      });
      await refreshLists();
      await onCreate?.();
      return response.data;
    },
    [createItem, refreshLists]
  );

  const initialData = {
    bank_account: initialValues?.bankAccount || defaultBankAccount,
    source: {
      ledger: {
        object:
          getSearchResultItemFromObjectAndModel(
            initialValues?.folio,
            initialValues?.folio?.__record_type
          ) || null
      }
    }
  };

  return (
    <RecordDialog
      onClose={onClose}
      isLoading={isLoading}
      data={initialData}
      title='Withdraw funds'
      handleSubmit={handleSubmit}
      content={content}
      blockProps={{ showBannerMessage }}
      submitLabel='Withdraw now'
      getHandlers={(forms) => getCreateWithdrawalChangeHandlers(forms)}
    />
  );
}
