import React, { useCallback } from 'react';

import { BlockConfig } from 'view/components/record-screen/types';
import { Field, HiddenField } from '@rexlabs/form';
import { TextArea } from '@rexlabs/text-input';
import { Column, Grid } from 'view/components/@luna/form/grid';
import { AttachableEntitySelect } from 'view/components/inputs/selects/v4-selects/attachable-entity-select';
import { ValueListSelect } from 'view/components/inputs/selects/value-list';
import { Note } from '../types/Note';

const validate = {
  definitions: {
    content: {
      name: 'note',
      rules: 'required'
    },
    note_type: {
      name: 'note type',
      rules: 'required'
    },
    phone_call_outcome: {
      name: 'outcome',
      rules: 'required_if:note_type.label,Phone call'
    },
    attached_to: {
      name: 'add note to',
      rules: 'required|array|min:1'
    }
  }
};

export const noteDetailsBlock: BlockConfig<Note> = {
  id: 'details',
  title: 'Details',
  validate,
  Edit: ({ values, setFieldValue, blockProps }) => {
    const handleNoteTypeChange = useCallback(() => {
      setFieldValue?.('phone_call_outcome', null);
    }, [setFieldValue]);
    return (
      <Grid columns={2}>
        <Field
          name='note_type'
          id='note_type'
          label='Note type'
          onChange={handleNoteTypeChange}
          Input={ValueListSelect}
          inputProps={{
            placeholder: 'Select from list...',
            type: 'note_type'
          }}
        />
        <Field
          name='phone_call_outcome'
          id='phone_call_outcome'
          label='Outcome'
          optional={false}
          Input={ValueListSelect}
          inputProps={{
            placeholder: 'Select from list...',
            type: 'phone_call_outcome',
            // These are db-managed value lists, so their id is an actual uuid.
            // (hopefully!) the label is reliable enough to use here.
            disabled: values.note_type?.label !== 'Phone call'
          }}
        />
        <Column width={2}>
          <Field name='content' label='Note' Input={TextArea} />
        </Column>
        {blockProps?.hideAddNoteToField ? (
          <HiddenField name='attached_to' />
        ) : (
          <Column width={2}>
            <Field
              name='attached_to'
              label='Add note to'
              Input={AttachableEntitySelect}
            />
          </Column>
        )}
      </Grid>
    );
  }
};
