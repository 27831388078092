import { EntityModel } from '@rexlabs/model-generator';

export function getAllIncludesStringFromModel(model: EntityModel<any>) {
  return Object.keys(model.config.related)
    .reduce<string[]>((acc, key) => {
      const includesForKey = model.config.related[key].include;
      return [...acc, ...(includesForKey ? includesForKey.split(',') : [])];
    }, [])
    .join(',');
}
