import React, { HTMLAttributes, PropsWithChildren } from 'react';

import { Field } from '@rexlabs/form';

import { useInsertToTextInput } from 'src/modules/communications/common/hooks/use-insert-to-input';
import { CommunicationContextType } from 'src/modules/communications/common/types/communication-context-type';

import { TextArea } from 'view/components/inputs/text-area/text-area';

import { EditMessageContentContainer } from './edit-message-content-container';

interface BasicEditSMSFormProps {
  contextType?: CommunicationContextType;
  setFieldValue?: (field: string, value: any) => void;
}

/**
 * Basic form for editing SMS content. It has two columns:
 *   - first column includes the content fields
 *   - second column includes the merge tag component
 */
export function BasicEditSMSForm({
  contextType,
  setFieldValue
}: PropsWithChildren<BasicEditSMSFormProps>) {
  const updateSMS = React.useCallback(
    (value: string) => {
      setFieldValue?.('smsContent', value);
    },
    [setFieldValue]
  );

  const {
    inputRef,
    setShouldInsertToInput,
    insertToTextInput
  } = useInsertToTextInput(updateSMS);

  return (
    <EditMessageContentContainer
      mergeTagsConfig={{
        channelType: 'sms',
        contextType,
        onMergeTagDoubleClick: insertToTextInput
      }}
    >
      {/* NOTE: this is because if you put the onClick on the textarea, it breaks the ability to add newlines for some reason. */}
      <div onClick={() => setShouldInsertToInput(true)}>
        <Field
          label='Content'
          name='smsContent'
          Input={TextArea}
          optional={false}
          inputProps={
            {
              ref: inputRef,
              rows: 15
            } as HTMLAttributes<HTMLTextAreaElement>
          }
        />
      </div>
    </EditMessageContentContainer>
  );
}
