import React from 'react';

import { Field } from '@rexlabs/form';

import { BlockConfig } from 'view/components/record-screen/types';
import { Column, Grid } from 'view/components/@luna/form/grid';
import { DateInput } from 'view/components/@luna/inputs/date-input/date-input';
import { PreviewFileUploadInputWithErrorHandling } from 'view/components/inputs/file-upload-input/preview-file-upload-input-with-error-handling';

const validate = {
  definitions: {
    issued_date: { rules: 'required' },
    expiry_date: { rules: 'required' },
    file: { rules: 'required', name: 'upload document' }
  }
};

export const renewCheckDetailsBlock: BlockConfig = {
  id: 'renew-check-details',
  validate,
  Edit: () => {
    return (
      <Grid columns={4}>
        <Field
          id='issued_date'
          name='issued_date'
          label='Date issued'
          Input={DateInput}
          inputProps={{
            // use a shorter date format so that it fits in the input
            displayFormat: 'DD MMM YYYY',
            clearable: true
          }}
        />
        <Field
          id='expiry_date'
          name='expiry_date'
          label='Expiry date'
          Input={DateInput}
          inputProps={{
            // use a shorter date format so that it fits in the input
            displayFormat: 'DD MMM YYYY',
            clearable: true
          }}
        />

        <Column width={4}>
          <Field<typeof PreviewFileUploadInputWithErrorHandling>
            id='file'
            name='file'
            label={'Upload document'}
            Input={PreviewFileUploadInputWithErrorHandling}
            optional={false}
          />
        </Column>
      </Grid>
    );
  }
};
