import React from 'react';

import { useModelActions } from '@rexlabs/model-generator';

import {
  StatusChangeActionArgs,
  useStatusChangeAction
} from 'src/modules/common/actions/status/use-status-change-action';
import { invokeActionDeclaration } from 'src/modules/common/actions/utils/invoke-action-declaration';

import ArrowBackIcon from 'view/components/icons/arrow-back';

import { keySetsModel, KeySet } from '../data/key-set-model';

export function useGetArchiveKeySetAction() {
  const { refreshLists } = useModelActions(keySetsModel);

  const getStatusChangeAction = useStatusChangeAction(keySetsModel);

  return (item: KeySet) => {
    return {
      label: 'Archive',
      Icon: ArrowBackIcon,
      onClick: async () => {
        await invokeActionDeclaration<StatusChangeActionArgs<'archived'>[]>(
          getStatusChangeAction,
          {
            record: item,
            status: 'archived',
            dialogOptions: {
              title: 'Archive key set',
              description: (
                <>
                  Do you want to archive <b>{item.name} key set</b>?
                </>
              ),
              afterAction: async () => {
                await refreshLists?.();
              }
            },
            toastOptions: {
              description: (
                <>
                  <b>{item.name} key set</b> successfully archived
                </>
              )
            }
          }
        );
      }
    };
  };
}
