import faker from '@faker-js/faker';
import { Author } from '../types/author-types';

export const authorFactory = (state?: Partial<Author>): Author => {
  const createdDate = faker.datatype.datetime();
  return {
    id: faker.datatype.uuid(),
    universal_user_id: faker.datatype.uuid(),
    name: faker.name.firstName(),
    email: faker.internet.exampleEmail(),
    created_at: createdDate,
    updated_at: createdDate,
    created_by: {} as Author, // TODO: might need to make this more robust later, but we can't have the factory be self-referential
    ...state
  };
};
