import { ValueListValue } from 'data/models/types';
import { PropertyAreaTypeSystemPurpose } from 'src/modules/property-areas/property-area-types/types/PropertyAreaTypeSystemPurpose';
import { Box } from '@rexlabs/box';
import { Banner } from 'src/modules/common/components/banner';
import InfoIcon from 'view/components/icons/info';
import React from 'react';

export function PropertyDetailsBanner({
  systemPurpose
}: {
  systemPurpose: ValueListValue<PropertyAreaTypeSystemPurpose>;
}) {
  const roomLabel =
    systemPurpose?.id === 'ensuite'
      ? 'bedroom and ensuite'
      : systemPurpose.label.toLowerCase();

  return (
    <Box marginTop={20}>
      <Banner intent={'information'} align='start' Icon={InfoIcon}>
        This area will be included for each {roomLabel} specified when creating
        a property.
      </Banner>
    </Box>
  );
}
