import { Generator } from 'data/models/generator';
import { auditableConfig } from 'src/modules/common/auditable/auditable-config';
import { ChecklistItem } from '../../common/types/Checklist';
import { checklistsModel } from './checklists-model';

const config = {
  entities: {
    related: {
      ...auditableConfig,
      checklist: {
        include: 'checklist',
        model: checklistsModel
      }
    }
  }
};

export const checklistItemsModel = new Generator<ChecklistItem>(
  'tasks/checklist-items',
  config
).createEntityModel();
