import * as React from 'react';
import { ConfirmationDialog, DialogProps } from '@rexlabs/dialog';
import { useModelActions } from '@rexlabs/model-generator';
import { useRecordUpdatedToast } from 'src/modules/common/toasts/hooks/use-record-updated-toast';
import { Task } from 'src/modules/tasks/common/types/Task';
import { tasksModel } from 'src/modules/tasks/common/models/tasks-model';
import { useTranslation } from 'react-i18next';
import { MaintenanceTask } from 'src/modules/tasks/maintenance/types/MaintenanceTask';

export interface RemoveTaskWithheldFundsDialogProps extends DialogProps {
  task: Task<'task_maintenance'>;
  onSave?: (updatedTask: MaintenanceTask) => any;
}

export function RemoveTaskWithheldFundsDialog({
  onClose,
  onSave,
  task
}: RemoveTaskWithheldFundsDialogProps) {
  const { updateItem, refreshLists } = useModelActions(tasksModel);
  const addToast = useRecordUpdatedToast(tasksModel, { actions: [] });
  const { t } = useTranslation();

  const handleSubmit = async () => {
    const { data: updatedTask } = await updateItem({
      id: task.id,
      data: {
        details: {
          disbursement_withheld_funds: {
            amount: 0,
            reason: null
          }
        }
      }
    });

    addToast(updatedTask);

    await Promise.all([refreshLists(), onSave?.(updatedTask)]);

    return updatedTask;
  };

  return (
    <ConfirmationDialog
      title={t('withheld-funds.remove-task-withheld-funds-dialog.title')}
      size='s'
      onClose={onClose}
      message={
        <div>
          {t('withheld-funds.remove-task-withheld-funds-dialog.body', {
            summary: task.summary
          })}
        </div>
      }
      confirmText={t(
        'withheld-funds.remove-task-withheld-funds-dialog.confirmation'
      )}
      onConfirm={handleSubmit}
      destructive={true}
    />
  );
}
