import React, { useCallback, useMemo } from 'react';

import { DialogProps } from '@rexlabs/dialog';
import { query, useEntityQuery } from '@rexlabs/model-generator';

import { useToast } from 'view/components/@luna/notifications/toast';
import {
  AttachedToType,
  Document,
  documentsModel
} from 'data/models/entities/documents';
import { RecordSubmitHandler } from 'view/components/record-screen/utils';
import { RecordDialog } from 'view/components/record-screen/dialog/dialog';

import { tasksModel } from 'src/modules/tasks/common/models/tasks-model';
import { TaskType } from 'src/modules/tasks/common/types/TaskType';

import { documentDetailsBlock } from '../blocks/document-details';

interface EditDocumentDialogProps extends DialogProps {
  documentId: string;
}

const getDocumentQuery = (id: string) => query`{
  ${documentsModel} (id: ${id}) {
    id
    name
    file
    attached_to
  }
}`;

const getTaskQuery = (id: string) => query`{
  ${tasksModel} (id: ${id}) {
    id
    details
  }
}`;

const transformFile = (document: Document) => ({
  uuid: document.file.id,
  file: document.file,
  data: Promise.resolve({ data: document.file }),
  preview: Promise.resolve(document.file.url)
});

const content = [
  {
    id: 'details',
    label: 'details',
    blocks: [documentDetailsBlock]
  }
];

// NOTE: in order to use the suggested document type, we need to get the parent record type.
// We can mostly get this from the document's attached_to field, but if the document is attached to a task,
// we need to fetch the task to get the sub task type.
const useGetParentRecordType = (documentData?: Document) => {
  const attachedTo = documentData?.attached_to?.[0];

  const isTask = attachedTo?.type.id === 'task';

  const taskQuery = isTask ? getTaskQuery(attachedTo.id) : undefined;

  const { data: taskData, status: taskModelStatus } = useEntityQuery(taskQuery);

  const parentRecordType: TaskType | AttachedToType | undefined = isTask
    ? taskData?.details?.__record_type || taskData?.type.id
    : attachedTo?.type.id;

  return {
    parentRecordType,
    isLoadingParentRecordType: taskModelStatus === 'loading'
  };
};

export function EditDocumentDialog({
  documentId,
  ...dialogProps
}: EditDocumentDialogProps) {
  const { onClose } = dialogProps;
  const { addToast } = useToast();

  const {
    status: documentModelStatus,
    data: documentData,
    actions
  } = useEntityQuery(getDocumentQuery(documentId));

  const {
    parentRecordType,
    isLoadingParentRecordType
  } = useGetParentRecordType(documentData);

  const initialValues = useMemo(
    () => ({
      ...documentData,
      record_type: parentRecordType,
      file: documentData ? transformFile(documentData) : undefined
    }),
    [documentData, parentRecordType]
  );

  const handleSubmit: RecordSubmitHandler = useCallback(async ({ values }) => {
    const fileData = await values.file.data;

    const { data } = await actions.updateItem({
      id: documentId,
      data: {
        ...values,
        file: {
          ...fileData.data
        }
      }
    });

    addToast({
      description: (
        <>
          Successfully updated document - <b>{data.name}</b>
        </>
      )
    });

    return data;
  }, []);

  return (
    <RecordDialog
      title='Update document'
      onClose={onClose}
      initialValues={initialValues}
      handleSubmit={handleSubmit}
      content={content}
      isLoading={documentModelStatus === 'loading' || isLoadingParentRecordType}
    />
  );
}
