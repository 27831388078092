import { buildEventName } from 'utils/analytics/build-event-name';
import { FEATURES as F } from 'utils/analytics/features';
import { COMMON_ACTIONS as A } from 'utils/analytics/common-actions';

export const RECONCILIATIONS_EVENTS = {
  MODAL_OPENED: buildEventName(F.RECONCILIATIONS, A.MODAL_OPENED),
  STARTED: buildEventName(F.RECONCILIATIONS, A.STARTED),
  CONTINUED: buildEventName(F.RECONCILIATIONS, A.CONTINUED),
  FINISH_LATER_CLICKED: buildEventName(
    F.RECONCILIATIONS,
    A.FINISH_LATER_CLICKED
  ),
  DETAILS_EDITED: buildEventName(F.RECONCILIATIONS, A.DETAILS_EDITED),
  DETAILS_UPDATED: buildEventName(F.RECONCILIATIONS, A.DETAILS_UPDATED),
  DETAILS_CANCELED: buildEventName(F.RECONCILIATIONS, A.DETAILS_CANCELED),
  REPORT_DOWNLOADED: buildEventName(F.RECONCILIATIONS, A.REPORT_DOWNLOADED),
  COMPLETED: buildEventName(F.RECONCILIATIONS, A.COMPLETED),
  VERIFIED: buildEventName(F.RECONCILIATIONS, A.VERIFIED),
  VOID_MODAL_OPENED: buildEventName(F.RECONCILIATIONS, A.VOID_MODAL_OPENED),
  VOIDED: buildEventName(F.RECONCILIATIONS, A.VOIDED)
};
