import React, { useCallback, useContext, useEffect } from 'react';
import { Query } from '@rexlabs/model-generator';
import { FileUploadContext } from '@rexlabs/file-upload-input';
import { Document, documentsModel } from 'data/models/entities/documents';
import { Columns, RecordTable } from 'view/components/table';
import { DocumentTypeIndicator } from 'view/components/ui/document-type-indicator/document-type-indicator';
import { transformActionDeclarationsToActionMenuItems } from 'utils/actions/transforms';
import { useDocumentActions } from 'src/modules/documents/actions/use-document-actions';
import { TwoTierCell } from 'view/components/cells/two-tier-cell';
import { DocumentsListEmptyState } from 'src/modules/documents/components/documents-list-empty-state';
import { AttachedTo } from '../../dialogs/create-document-dialog';
import { DocumentRelatedRecord } from '../connectors/document-list';

export const documentColumns: Columns<Document> = [
  {
    id: 'name',
    Header: 'Name',
    accessor: (item) => item,
    Cell: ({ value }) => (
      <TwoTierCell text={value?.name} subtext={value?.type?.label} />
    ),
    width: 260
  },
  {
    id: 'file_type',
    Header: 'File type',
    accessor: (item) => item?.file?.type,
    Cell: ({ value }) => <DocumentTypeIndicator type={value} />,
    width: 100
  },
  {
    id: 'uploaded_at',
    type: 'date',
    Header: 'Uploaded at',
    accessor: (item) => item?.file?.updated_at,
    width: 140
  },
  {
    id: 'file_size',
    Header: 'File size',
    accessor: (item) => item?.file?.size,
    type: 'fileSize',
    width: 100
  }
];

interface DocumentListProps {
  query: Query<typeof documentsModel>;
  onDrop: (files: any) => void;
  record: DocumentRelatedRecord;
  attachedTo: AttachedTo[];
}

export function DocumentsTable({
  query,
  onDrop,
  record,
  attachedTo
}: DocumentListProps) {
  const setOnDrop = useContext(FileUploadContext);
  const actions = useDocumentActions();

  useEffect(() => {
    setOnDrop?.(onDrop);
  }, [onDrop, setOnDrop]);

  const EmptyState = useCallback(
    () => <DocumentsListEmptyState record={record} attachedTo={attachedTo} />,
    [record]
  );

  return (
    <RecordTable
      withPagination={true}
      id='document-list'
      columns={documentColumns}
      getQuery={() => query}
      getActionMenuItems={({ item }) =>
        transformActionDeclarationsToActionMenuItems(actions(item))
      }
      Empty={EmptyState}
      emptyWithoutTable={true}
    />
  );
}
