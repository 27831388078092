import React from 'react';
import { StyleSheet, useStyles } from '@rexlabs/styling';
import { TwoTierCell } from 'view/components/cells/two-tier-cell';
import { formatCurrency, pluralize } from 'utils/formatters';
import { formatDate } from 'utils/dates/format';
import { Columns } from 'view/components/table';
import { auditableColumns } from 'src/modules/common/auditable/auditable-columns';
import { TagCell } from '@rexlabs/table';
import { StatusTag } from 'src/modules/common/components/status-tag';
import { propertyTenancyStatusMap } from 'src/modules/property-tenancies/maps/property-tenancy-map';
import dayjs from 'dayjs';
import { RentCell, rentToCsv } from '../components/rent-cell';

const styles = StyleSheet({
  red: {
    color: ({ token }) => token('color.textStyle.body.error')
  }
});

export const leaseAgreementColumns: Columns = [
  {
    id: 'record_reference',
    width: 140,
    Header: 'Reference'
  },
  {
    id: 'property.display_name',
    Header: 'Property'
  },
  {
    id: 'tenancy',
    type: 'tenancy',
    Header: 'Tenancy name'
  },
  {
    id: 'lease_type',
    type: 'value',
    Header: 'Lease type'
  },
  {
    id: 'agreement_start_date',
    type: 'date',
    width: 140,
    Header: 'Start date'
  },
  {
    id: 'agreement_end_date',
    width: 140,
    accessor: (item) => item.agreement_end_date,
    Header: 'End date',
    Cell({ value }) {
      const s = useStyles(styles);

      if (!value) return <>--</>;

      const isBefore = dayjs(value).isBefore(dayjs());

      return (
        <div
          {...s.with({
            red: isBefore
          })()}
        >
          {formatDate(value)}
        </div>
      );
    }
  },
  {
    id: 'rent_schedule',
    Cell: RentCell,
    width: 180,
    cellProps: {
      align: 'right'
    },
    Header: 'Current rent',
    toCsv: rentToCsv
  },
  {
    id: 'rent_position',
    Header: 'Rent position',
    accessor: (item) => item.rent_position,
    Cell: ({ value }) => {
      const rentPosition = getRentPosition(value);

      if (!rentPosition) return <>--</>;

      const [paidToDate, amount] = rentPosition;

      return (
        <>
          {paidToDate} + {amount}
        </>
      );
    },
    toCsv: (item) => {
      const rentPosition = getRentPosition(item);

      if (!rentPosition) return '--';

      return rentPosition.join(' + ');
    }
  },
  {
    id: 'arrears',
    Header: 'Arrears',
    accessor: (item) => item,
    Cell: ({ value }) => {
      const s = useStyles(styles);
      const [text, subtext, shouldDisplayInRed] = getArrears(value);

      const props = s.with({
        red: shouldDisplayInRed
      })();

      return (
        <TwoTierCell
          text={<div {...props}>{text}</div>}
          subtext={<div {...props}>{subtext}</div>}
        />
      );
    },
    toCsv: (item) => getArrears(item).slice(0, 2).join(' ')
  },
  {
    id: 'total_deposit',
    Header: 'Total deposit',
    type: 'currency',
    accessor: (item) => item.security_deposit?.amount_paid ?? 0
  },
  {
    id: 'status',
    Header: 'Status',
    toCsv: (item) => item.label,
    Cell: (table) => {
      const {
        cell: { value }
      } = table;
      return (
        <TagCell>
          <StatusTag status={value} map={propertyTenancyStatusMap} />
        </TagCell>
      );
    }
  },
  ...auditableColumns
];

// Helpers

function getArrears(item) {
  return [
    formatCurrency(item.rent_arrears?.amount ?? 0),
    pluralize('day', item.rent_arrears?.days ?? 0, true),
    item.rent_arrears?.amount > 0
  ] as const;
}

function getRentPosition(item) {
  const paidToDate = item?.paid_to_date;
  const amount = item?.paid_to_date_surplus ?? 0;

  if (!paidToDate) return;

  return [formatDate(paidToDate), formatCurrency(amount)] as const;
}
