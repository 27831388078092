import { ChecklistItemTagType } from '../components/checklist-item';

export function getTagColorForDueDate(
  dueDate: string | null
): ChecklistItemTagType {
  if (!dueDate) {
    return 'neutral';
  }

  const dueDateDate = new Date(dueDate);
  const now = new Date();

  switch (true) {
    // if due today, average
    case dueDateDate.toDateString() === now.toDateString():
      return 'average';
    // if overdue, bad
    case dueDateDate < now:
      return 'bad';
    // otherwise, neutral
    default:
      return 'neutral';
  }
}
