import React from 'react';
import { StyleSheet, useStyles } from '@rexlabs/styling';

import { useStickyNavigation } from 'view/components/record-screen/sticky-navigation/context';
import { RecordLink, RecordLinkProps } from '../../record-link/record-link';

const defaultStyles = StyleSheet({
  link: {
    overflow: 'hidden',
    textDecoration: 'unset',
    color: ({ token }) => token('color.textStyle.body.default'),

    '&:hover': {
      color: 'unset'
    }
  }
});

export function EntryLink({ children, ...props }: RecordLinkProps) {
  const s = useStyles(defaultStyles);
  const { setActiveMenu } = useStickyNavigation();

  return (
    <RecordLink {...props}>
      {({ onClick, target }) => (
        <a
          {...s('link')}
          href={target}
          onClick={(e) => {
            onClick(e);
            // Remove menu when clicking on a related record
            setActiveMenu(null);
          }}
        >
          {children}
        </a>
      )}
    </RecordLink>
  );
}
