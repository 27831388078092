import React from 'react';
import { StyleSheet, useStyles } from '@rexlabs/styling';
import { formatDate } from 'utils/dates/format';
import { Columns } from 'view/components/table';
import { auditableColumns } from 'src/modules/common/auditable/auditable-columns';
import { TagCell } from '@rexlabs/table';
import { StatusTag } from 'src/modules/common/components/status-tag';
import dayjs from 'dayjs';
import { LargeDescriptionCell } from 'view/components/cells/large-description-cell';
import { useTranslation } from 'react-i18next';
import { useFeatureFlags } from 'view/components/@luna/feature-flags';
import { FLAGS } from 'utils/feature-flags';
import { propertyOwnershipStatusMap } from '../maps/property-ownership-maps';

const styles = StyleSheet({
  red: {
    color: ({ token }) => token('color.textStyle.body.error')
  }
});

export function useManagementAgreementColumns(): Columns {
  const { t } = useTranslation();
  const { hasFeature } = useFeatureFlags();
  return [
    {
      id: 'record_reference',
      width: 140,
      Header: 'Reference'
    },
    {
      id: 'property.display_name',
      Header: 'Property'
    },
    ...(hasFeature(FLAGS.SERVICE_PACKAGES)
      ? [
          {
            id: 'service_package',
            Header: 'Service package',
            width: 200,
            accessor: (item) => item?.service_package?.name
          }
        ]
      : []),
    {
      id: 'ownership',
      type: 'ownership',
      width: 180,
      Header: 'Ownership name'
    },
    {
      id: 'agreement_start_date',
      type: 'date',
      width: 140,
      Header: 'Start date'
    },
    {
      id: 'agreement_end_date',
      width: 140,
      accessor: (item) => item.agreement_end_date,
      Header: 'End date',
      Cell({ value }) {
        const s = useStyles(styles);

        if (!value) return <>--</>;

        const isBefore = dayjs(value).isBefore(dayjs());

        return (
          <div
            {...s.with({
              red: isBefore
            })()}
          >
            {formatDate(value)}
          </div>
        );
      }
    },
    {
      id: 'last_disbursement',
      type: 'date',
      Header: 'Last disbursement',
      accessor: (item) => {
        return item.ownership?.last_disbursement?.created_at;
      }
    },
    {
      id: 'ownership.disbursement_due_date',
      type: 'date',
      Header: 'Next disbursement'
    },
    {
      id: 'management_fees',
      Header: t(
        'property-ownerships.management-agreement-fees.short-label.plural'
      ) as string,
      width: 250,
      accessor: (item) => item.entity_fees?.data,
      Cell: getFeeCell(),
      toCsv: (item) => getFeeNames(item, null)
    },
    {
      id: 'rent_receipts',
      Header: 'Rent receipts',
      width: 200,
      accessor: (item) => item.entity_fees?.data,
      Cell: getFeeCell([
        'rent_receipted',
        'rent_receipted_excluding_first_time',
        'rent_receipted_including_first_time'
      ]),
      toCsv: (item) =>
        getFeeNames(item, [
          'rent_receipted',
          'rent_receipted_excluding_first_time',
          'rent_receipted_including_first_time'
        ])
    },
    {
      id: 'task_status_change',
      Header: 'Task status change',
      width: 200,
      accessor: (item) => item.entity_fees?.data,
      Cell: getFeeCell(['task_status_changed']),
      toCsv: (item) => getFeeNames(item, ['task_status_changed'])
    },
    {
      id: 'date_trigger_fee',
      Header: 'Date trigger fee',
      width: 200,
      accessor: (item) => item.entity_fees?.data,
      Cell: getFeeCell(['day_of_month']),
      toCsv: (item) => getFeeNames(item, ['day_of_month'])
    },
    {
      id: 'manual_fee',
      Header: 'Manual fee',
      width: 200,
      accessor: (item) => item.entity_fees?.data,
      Cell: getFeeCell(['manual']),
      toCsv: (item) => getFeeNames(item, ['manual'])
    },
    {
      id: 'status',
      Header: 'Status',
      toCsv: (item) => item.label,
      Cell: ({ value }) => {
        return (
          <TagCell>
            <StatusTag status={value} map={propertyOwnershipStatusMap} />
          </TagCell>
        );
      }
    },
    ...auditableColumns
  ];
}

// Helpers

type TriggerTypes =
  | 'day_of_month'
  | 'rent_receipted'
  | 'rent_receipted_excluding_first_time'
  | 'rent_receipted_including_first_time'
  | 'first_rent_receipted'
  | 'task_status_changed'
  | 'manual';

function getFeeCell(triggerTypes?: Array<TriggerTypes>) {
  function FeeCell({ value }) {
    const feeNames = React.useMemo(() => getFeeNames(value, triggerTypes), [
      value
    ]);

    return <LargeDescriptionCell text={feeNames} lineClamp={3} />;
  }

  return FeeCell;
}

function getFeeNames(value, triggerTypes) {
  if (!Array.isArray(value)) return '--';

  const feeNames = value
    .filter((item) =>
      Array.isArray(triggerTypes)
        ? triggerTypes.includes(item.agency_fee.trigger_type.id)
        : true
    )
    .map((item) => item.agency_fee.name)
    .join(',');

  return feeNames;
}
