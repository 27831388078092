import { reduce } from 'lodash';
import { FileUpload } from 'src/modules/common/types/file';
import { ValueListValue } from 'data/models/types';
import { getUploadedFileMeta } from 'utils/files';
import { ChannelTypeId } from '../../common/types/channel';
import { CommunicationContextType } from '../../common/types/communication-context-type';
import { CreateTemplateFormData } from '../types/create-template-form-data';

export interface CreateTemplateRequest {
  name: string;
  context_type: {
    id: CommunicationContextType;
  };
  default_recipient_group?: {
    id: string;
  };
  send_from_author?: {
    id: string;
  };
  send_immediately?: boolean;
  inline_attachments?: FileUpload[];
  linked_files?: FileUpload[];
  channels?: Array<{
    channel_type: {
      id: ChannelTypeId;
    };
    email_subject?: string;
    letter_address?: string;
    plain_text_body: string;
  }>;
  templated_attachments?: string[];
  document_types?: ValueListValue<string>[];
  compliance_types?: ValueListValue<string>[];
  send_if_no_documents_found_with_related_types?: boolean;
  send_if_no_compliance_entries_found_with_related_types?: boolean;
}

export function useGetMapCreateTemplateFormDataToCreateTemplateRequest() {
  return async ({
    name,
    context_type,
    recipient_group,
    send_from_author,
    send_immediately,
    subject,
    content,
    letterAddress,
    smsContent,
    letterContent,
    attachments = [],
    linked_files = [],
    include_generated_attachments = {},
    document_types = [],
    compliance_types = [],
    send_if_no_documents_found_with_related_types = false,
    send_if_no_compliance_entries_found_with_related_types = false
  }: CreateTemplateFormData): Promise<CreateTemplateRequest> => {
    const inline_attachments = await getUploadedFileMeta(attachments);
    const templatedAttachments = reduce<
      typeof include_generated_attachments,
      string[]
    >(
      include_generated_attachments,
      (acc, val, key) => {
        return [...acc, ...(val ? [key] : [])];
      },
      []
    );

    const smsChannel = smsContent
      ? [
          {
            channel_type: {
              id: 'sms'
            },
            plain_text_body: smsContent ?? ''
          } as const
        ]
      : [];

    const emailChannel =
      subject || content
        ? [
            {
              channel_type: {
                id: 'email'
              },
              email_subject: subject || '',
              plain_text_body: content || ''
            } as const
          ]
        : [];

    const letterChannel = letterContent
      ? [
          {
            channel_type: {
              id: 'letter'
            },
            plain_text_body: letterContent,
            letter_address: letterAddress
          } as const
        ]
      : [];

    return {
      name,
      context_type,
      default_recipient_group: {
        id: recipient_group.name
      },
      send_from_author: send_from_author?.id ? send_from_author : undefined,
      send_immediately: send_immediately?.id === 'send_immediately',
      inline_attachments,
      linked_files,
      templated_attachments: templatedAttachments,
      channels: [...emailChannel, ...smsChannel, ...letterChannel],
      document_types,
      compliance_types,
      send_if_no_documents_found_with_related_types: !!send_if_no_documents_found_with_related_types,
      send_if_no_compliance_entries_found_with_related_types: !!send_if_no_compliance_entries_found_with_related_types
    };
  };
}
