import React from 'react';

import { ListScreen } from 'view/components/list-screen/list-screen';
import RenderLoading from 'view/components/@luna/render-loading';
import LoadingState from 'view/components/states/loading';
import { Card } from 'view/components/card';

import { useBankAccountSelectActions } from 'src/modules/bank-accounts/hooks/use-bank-account-select-actions';
import ROUTES from 'routes/app';
import { BankDepositsTable } from '../components/bank-deposits-table';
import { useBankDepositStats } from '../hooks/use-bank-deposit-stats';

export function BankDepositsListScreen() {
  const {
    isLoading: isBankAccountsLoading,
    bankAccount,
    actions
  } = useBankAccountSelectActions(ROUTES.BANK_DEPOSITS_LIST);

  const stats = useBankDepositStats(bankAccount);

  return (
    <RenderLoading isLoading={isBankAccountsLoading} LoadingView={LoadingState}>
      <ListScreen title='Bank deposits' actions={actions} stats={stats}>
        <Card title='Outstanding receipts to deposit'>
          {bankAccount && <BankDepositsTable bankAccountId={bankAccount!.id} />}
        </Card>
      </ListScreen>
    </RenderLoading>
  );
}
