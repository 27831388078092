import React from 'react';
import { BlockConfig } from 'view/components/record-screen/types';
import { Grid } from 'view/components/@luna/form/grid';
import { Field } from '@rexlabs/form';
import { RadioGroupInput } from '@rexlabs/radio-input';
import { TextInput } from '@rexlabs/text-input';

export interface RecordDetailsFormValues {
  use_system_reference: boolean;
  custom_record_reference: string | null;
  use_system_display_name: boolean;
  custom_display_name: string | null;
}

export const recordDetailsBlock = (
  hasCustomDisplayName = true,
  hasCustomRecordReference = true
): BlockConfig => {
  return {
    id: 'record-details',
    title: 'Edit record details',
    validate: {
      definitions: {
        ...(hasCustomRecordReference && {
          is_custom_record_reference: { rules: 'required' },
          custom_record_reference: {
            rules: 'required_if:is_custom_record_reference',
            name: 'custom reference'
          }
        }),
        ...(hasCustomDisplayName && {
          is_custom_display_name: { rules: 'required' },
          custom_display_name: {
            rules: 'required_if:is_custom_display_name',
            name: 'custom display name'
          }
        })
      }
    },
    Edit: (props) => {
      const { values } = props;
      return (
        <Grid columns={2}>
          {hasCustomRecordReference && (
            <div>
              <Field
                name='is_custom_record_reference'
                label='Reference'
                Input={RadioGroupInput}
                inputProps={{
                  options: [
                    { label: 'Use system reference', value: false },
                    { label: 'Use a custom reference', value: true }
                  ]
                }}
              />
              <Field
                name='custom_record_reference'
                label=''
                Input={TextInput}
                optional={false}
                inputProps={{
                  placeholder: 'Enter custom reference',
                  disabled: !values.is_custom_record_reference
                }}
              />
            </div>
          )}

          {hasCustomDisplayName && (
            <div>
              <Field
                name='is_custom_display_name'
                label='Display name'
                Input={RadioGroupInput}
                inputProps={{
                  options: [
                    { label: 'Use system name', value: false },
                    { label: 'Use a custom name', value: true }
                  ]
                }}
              />
              <Field
                name='custom_display_name'
                label=''
                Input={TextInput}
                optional={false}
                inputProps={{
                  placeholder: 'Enter custom name',
                  disabled: !values.is_custom_display_name,
                  'data-testid': 'custom-display-name'
                }}
              />
            </div>
          )}
        </Grid>
      );
    }
  };
};
