import * as React from 'react';
import { DialogProps, ConfirmationDialog } from '@rexlabs/dialog';
import { Body, Bold } from '@rexlabs/text';
import { ChecklistTemplatesItemForm } from '../types/ChecklistTemplatesForm';

export interface DeleteChecklistItemDialogProps extends DialogProps {
  checklistItem: ChecklistTemplatesItemForm;
  onDelete: () => void;
}

export function DeleteChecklistItemDialog({
  checklistItem,
  onDelete,
  onClose
}: DeleteChecklistItemDialogProps) {
  const handleConfirm = React.useCallback(() => {
    onDelete();

    return true;
  }, [onDelete]);

  return (
    <ConfirmationDialog
      destructive
      onClose={onClose}
      onConfirm={handleConfirm}
      confirmText='Yes, delete this item'
      title={`Delete ${checklistItem.label} checklist item`}
      message={
        <>
          <Body>
            Removing <Bold>{checklistItem.label}</Bold> checklist item will only
            affect checklists created in new tasks
          </Body>
          <Body as='div'>Do you want to continue?</Body>
        </>
      }
    />
  );
}
