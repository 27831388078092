import React from 'react';
import { Grid } from 'view/components/@luna/form/grid';
import { Field } from '@rexlabs/form';
import { EntitySelect } from 'view/components/inputs/selects/v4-selects/entity-select';
import { SUPPORTED_RECORD_TYPES_FOR_SELECT_RELATES_TO } from '../../constants/SupportedRecordTypesForSelectRelatesTo';

type Props = {
  onChange?: () => void;
};

export const SelectRelatesTo = ({ onChange }: Props) => (
  <Grid columns={1}>
    <Field
      id='relates_to'
      name='relates_to'
      label='Record'
      Input={EntitySelect}
      inputProps={{
        objectTypes: SUPPORTED_RECORD_TYPES_FOR_SELECT_RELATES_TO
      }}
      onChange={onChange}
    />
  </Grid>
);
