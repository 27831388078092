import React, { useMemo } from 'react';

import { TitleBlock } from 'view/components/cards/title/title-block';

import { RecordObject, RecordType } from 'data/models/types';
import { Portfolio } from 'src/modules/portfolios/models/portfolios-model';

import { getRecordTitle } from 'utils/records/get-record-title';
import { useGetPortfolioActions } from '../hooks/action-menu-items/use-get-portfolio-actions';

const type: RecordType = 'portfolio';

type PortfolioTitleBlockProps = {
  portfolio: Portfolio;
};

export function PortfolioTitleBlock({ portfolio }: PortfolioTitleBlockProps) {
  const getPortfolioActions = useGetPortfolioActions();

  const title = getRecordTitle({ type, object: portfolio } as RecordObject);
  const actions = useMemo(() => getPortfolioActions(portfolio), [portfolio]);

  return <TitleBlock title={title} type={type} actions={actions} />;
}
