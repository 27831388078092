import { StatusTagMap } from 'src/modules/common/components/status-tag';
import { UserStatus } from 'src/modules/users/types/user-types';

export const userStatusMap: StatusTagMap<UserStatus> = {
  draft: 'average',
  accepted: 'good',
  invited: 'average',
  not_invited: 'average',
  suspended: 'bad'
};
