import React from 'react';

import Box from '@rexlabs/box';
import { OutlineButton } from '@rexlabs/button';

import { Banner } from 'src/modules/common/components/banner';

import InfoCircleIcon from 'view/components/icons/info';
import { StyleSheet, mq, useStyles } from '@rexlabs/styling';
import LocationMarkerIcon from 'view/components/icons/location-marker';

const defaultStyles = StyleSheet({
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    ...mq.styles({
      queries: {
        maxWidth: 's'
      },
      styles: {
        flexDirection: 'column'
      }
    })
  },
  buttonGroup: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    ...mq.styles({
      queries: {
        maxWidth: 's'
      },
      styles: {
        padding: ({ token }) => token('spacing.s'),
        justifyContent: 'center'
      }
    })
  }
});

type MissingLocationWarningBannerProps = {
  onEditLocation: () => void;
};

export function MissingLocationWarningBanner({
  onEditLocation
}: MissingLocationWarningBannerProps) {
  const s = useStyles(defaultStyles);
  return (
    <Banner Icon={InfoCircleIcon} intent='warning' align='start'>
      <Box {...s('container')}>
        The property could not be accurately located on the map. To accurately
        plan inspections, please manually set the location.
        <Box {...s('buttonGroup')}>
          <OutlineButton IconLeft={LocationMarkerIcon} onClick={onEditLocation}>
            Edit location
          </OutlineButton>
        </Box>
      </Box>
    </Banner>
  );
}
