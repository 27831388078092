import { useDialog } from '@rexlabs/dialog';

import { KeySet } from '../data/key-set-model';
import { CheckOutKeySetDialog } from '../dialogs/check-out-key-set-dialog';
import { useCheckOutKeySetSubmitHandler } from './use-check-out-key-set-submit-handler';

export function useGetCheckOutKeySetAction() {
  const { open: openCheckOutKeyDialog } = useDialog(CheckOutKeySetDialog);

  const { onHandleSubmit } = useCheckOutKeySetSubmitHandler();

  return (key: KeySet) => {
    return {
      id: 'check-out',
      label: 'Check-out',
      intent: 'primary',
      onClick: () =>
        openCheckOutKeyDialog({
          keyId: key.id,
          handleSubmit: onHandleSubmit
        })
    };
  };
}
