import React from 'react';

import { Table, TableProps } from '@rexlabs/table';

import { EmptyState } from 'view/components/states/compact/empty';

function AlfredTable({ Empty = EmptyState, ...props }: TableProps) {
  return <Table {...props} Empty={Empty} />;
}

export { AlfredTable as Table };
