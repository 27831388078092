import React from 'react';
import { useTranslation } from 'react-i18next';

import { WarningBanner } from '@rexlabs/notifications';

import InfoCircleIcon from 'view/components/icons/info';

export function ShortfallWarningBanner() {
  const { t } = useTranslation();
  return (
    <WarningBanner
      Icon={InfoCircleIcon}
      description={t('disbursements.shortfall-warning-banner-description')}
      data-testid='shortfall-banner'
    />
  );
}
