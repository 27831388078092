import { query } from '@rexlabs/model-generator';
import { propertyTenancyModel } from 'src/modules/property-tenancies/models/property-tenancy-model';

export function propertyListLeaseAgreementQuery() {
  return query`{
    ${propertyTenancyModel} {
     id
     agreement_start_date
     agreement_end_date
     lease_type
     property
     tenancy
     rent_schedule
     rent_position
     security_deposit
     created_by{
       user
     }
     updated_by{
       user
     }
   }
  }`;
}
