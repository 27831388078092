import config from 'src/config';

/**
 * Redirects the user to other internal apps like Authentication Service App.
 * This helps users to be able to login in all rex applications through a central authentication service.
 */

export function redirectToAuthServiceLogin(redirectUrl?: string) {
  window.location.href =
    config.AUTHENTICATION_SERVICE_FRONTEND_URL +
    '?' +
    new URLSearchParams({
      // app_id identifies alfred inside the Auth Service app.
      app_id: config.AUTHENTICATION_SERVICE_APP_ID,
      // Set logout to 1 to logging out the user from auth service app after redirect.
      logout: '1',
      redirect_url: redirectUrl || window.location.href.split('?')[0]
    }).toString();
}

export function redirectToAuthServiceProfileInfo() {
  window.location.href =
    config.AUTHENTICATION_SERVICE_PROFILE_INFO_URL +
    '?' +
    new URLSearchParams({
      app_id: config.AUTHENTICATION_SERVICE_APP_ID
    }).toString();
}

export function redirectToAuthServiceProfileSecurity() {
  window.location.href =
    config.AUTHENTICATION_SERVICE_PROFILE_SECURITY_URL +
    '?' +
    new URLSearchParams({
      app_id: config.AUTHENTICATION_SERVICE_APP_ID
    }).toString();
}
