import { useDialog } from '@rexlabs/dialog';
import React from 'react';

import { ActionButtons } from 'view/components/@luna/action-buttons';
import { BlockConfig } from 'view/components/record-screen/types';

import { SupplierComplianceCategorySettingsTable } from '../components/supplier-compliance-category-settings-table';
import { CreateComplianceCategoryDialog } from '../dialogs/create-compliance-category-dialog';

export const supplierComplianceCategorySettingsBlock: BlockConfig = {
  id: 'supplier-compliance-category-settings',
  title: 'Services offered',
  View: SupplierComplianceCategorySettingsTable,
  Actions: () => {
    const { open: openCreateComplianceCategoryDialog } = useDialog(
      CreateComplianceCategoryDialog
    );

    return (
      <ActionButtons
        actions={[
          {
            label: 'Add new',
            handleAction: openCreateComplianceCategoryDialog
          }
        ]}
      />
    );
  }
};
