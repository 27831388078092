import { get, omit } from 'lodash';

import { DisbursementFrequency } from 'src/modules/disbursements/types/frequency';

import { DisbursementFrequencySettings } from '../../hooks/useSettings';

export function mapDisbursementToSettingsPayload(
  data: DisbursementFrequency<any>
): DisbursementFrequencySettings {
  return {
    ...omit(data, [
      'preset',
      'on_frequency_next_disbursement_date',
      'on_frequency_unit'
    ]),
    preset_data: get(
      data,
      'preset.data',
      {} as DisbursementFrequencySettings['preset_data']
    ),
    preset_type_id: get(data, 'preset.type.id'),
    on_frequency_unit_id: get(data, 'on_frequency_unit.id')
  };
}
