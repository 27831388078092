import { useEffect, useState } from 'react';
import { getContact } from 'utils/api/get-contact';

// The reason we need this hook is that if the related record is a contact
// we need its roles to check if supplier. I don't want the search to have to
// retrieve this info as it will affect performance of the search endpoint
export const useGetRelatedRecordWhenAttachingDocuments = (values) => {
  const [relatedRecord, setRelatedRecord] = useState(values.relates_to);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    const fetchData = async () => {
      if (!values.relates_to || values.relates_to.type.id !== 'contact') {
        setRelatedRecord(values.relates_to);
        setIsLoading(false);
        return;
      }

      const contact = await getContact({
        id: values.relates_to.id,
        includes: ['roles']
      });

      setRelatedRecord({ ...values.relates_to, record: contact });
      setIsLoading(false);
    };

    fetchData();
  }, [values]);

  return { isLoading, relatedRecord };
};
