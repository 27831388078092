import { messageTriggerSettingsMap } from '../../messages/settings/message-triggers/utils/field-map';
import { MessageTemplate } from '../types/MessageTemplate';

/**
 * This util is purely used to determine if we can use a template for a trigger - which
 * at this stage is just the banner that appears in the template details page.
 */
export function canTemplateBeUsedForTrigger(template?: MessageTemplate) {
  const templateContextTypeId = template?.context_type?.id;
  const templateReceiptGroup = template?.default_recipient_group;

  // First thing we want to do is get all of the triggers that use the same context type
  const triggerTemplatesUsingContextType = Object.keys(
    messageTriggerSettingsMap
  ).reduce((acc, key) => {
    const trigger = messageTriggerSettingsMap[key];

    if (trigger.context_type_id === templateContextTypeId) {
      return [
        ...acc,
        {
          trigger_key: key,
          context_type_id: trigger.context_type_id,
          default_recipient_group: trigger?.default_recipient_group
        }
      ];
    }

    return acc;
  }, [] as any[]);

  // Then we want to check if any of those triggers are further filtered by
  // the default recipient group.
  const hasTriggersUsingRecipientGroup = triggerTemplatesUsingContextType.filter(
    (trigger) => !!trigger?.default_recipient_group
  );

  // So if there are triggers that use the same context type, but none of them
  // have additional filtering by the default recipient group, then we can use the template
  if (
    hasTriggersUsingRecipientGroup.length !==
    triggerTemplatesUsingContextType.length
  ) {
    return !!triggerTemplatesUsingContextType.length;
  }

  // If there are triggers that have filtering by default recipient group, then we need to
  // check if the template we're looking at has the same default recipient group as any of
  // those triggers.
  return hasTriggersUsingRecipientGroup.some(
    (trigger) => trigger.default_recipient_group === templateReceiptGroup
  );
}
