import React, { useCallback } from 'react';
import { GhostButton, PrimaryButton } from '@rexlabs/button';
import { ButtonGroupProps } from 'view/components/record-screen/dialog/types';
import { RecordData } from 'utils/types';
import { StandardDialogFooter } from '../components/standard-dialog-footer';

export function DialogButtonGroup({
  CancelButton = GhostButton,
  SubmitButton = PrimaryButton,
  submitLabel = 'Submit',
  left,
  handleSubmit,
  resetAll,
  isSubmitting,
  onClose
}: ButtonGroupProps) {
  const handleSubmitAndClose = useCallback(async () => {
    const result = (await handleSubmit()) as RecordData | false;

    if (!result) return;

    onClose?.();
  }, [handleSubmit, onClose]);

  return (
    <StandardDialogFooter left={left}>
      {onClose && <CancelButton onClick={onClose}>Cancel</CancelButton>}
      {SubmitButton && (
        <SubmitButton
          data-testid='dialog-submit-button'
          type='submit'
          onClick={handleSubmitAndClose}
          resetAll={resetAll}
          isLoading={isSubmitting}
        >
          {submitLabel}
        </SubmitButton>
      )}
    </StandardDialogFooter>
  );
}
