import { makeIconComponent } from '@rexlabs/icons';

import WorkOrderMediumSvg from 'src/assets/icons/m/work-order.svg';
import WorkOrderLargeSvg from 'src/assets/icons/l/work-order.svg';
import WorkOrderExtraLargeSvg from 'src/assets/icons/xl/work-order.svg';

export const WorkOrderIcon = makeIconComponent({
  m: WorkOrderMediumSvg,
  l: WorkOrderLargeSvg,
  xl: WorkOrderExtraLargeSvg
});

export default WorkOrderIcon;
