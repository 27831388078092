/**
 * TODO: it would make sense to pull these out into actual model generator
 * model eventually instead of using the api client directly
 */

import { AxiosRequestConfig } from 'axios';
import { api } from 'utils/api/api-client';
import {
  RentAdjustmentPayload,
  RentDiscountPayload,
  TaxPreviewPayload,
  TaxPreviewResponse
} from '../types';

export const adjustRent = (payload: RentAdjustmentPayload) =>
  api.post<RentAdjustmentPayload, void>(`financials/rent/adjustments`, payload);

export const discountRent = (payload: RentDiscountPayload) =>
  api.post<RentDiscountPayload, void>(`financials/rent/discounts`, payload);

export interface TaxItem {
  amount_tax?: number;
  tax_type?: {
    id?: string;
    label?: string;
  };
}

export interface TaxPreview {
  amount_exc_tax?: number;
  amount_inc_tax?: number;
  amount_tax?: number;
  tax_summary?: TaxItem[];
}

export const getInvoiceTaxPreview = (
  payload: TaxPreviewPayload,
  options?: AxiosRequestConfig
) =>
  api
    .post<TaxPreviewPayload, { data: TaxPreviewResponse }>(
      `financials/preview-tax`,
      payload,
      options
    )
    .then(({ data }) => data);
