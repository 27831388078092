import React, { useCallback } from 'react';
import { ValueListSelectProps } from 'view/components/inputs/selects/value-list';
import { ValueListItem } from 'utils/normaliser/value-list-item';
import { getValueListItems } from 'utils/api/get-value-list-items';
import { RecordOption, Select, useValueList } from '@rexlabs/select';

export function RemoveTaskWithheldFundsSelect(props: ValueListSelectProps) {
  const type = 'remove_task_withheld_funds_type';
  const getItems = useCallback(() => getValueListItems(type), [type]);

  const { getSelectProps } = useValueList<ValueListItem>({
    getItems
  });

  const { items, ...selectProps } = getSelectProps();

  return (
    <Select
      {...selectProps}
      items={items}
      normaliser={itemNormaliser}
      Option={RecordOption}
      {...props}
    />
  );
}

const mapDesciptions = {
  last_bill_is_uploaded_or_the_task_is_closed:
    'The last bill is when the number of bills uploaded equals the number of work orders'
};

function itemNormaliser(item: ValueListItem) {
  const description = mapDesciptions[item.id] ?? '';

  return {
    ...item,
    description
  };
}
