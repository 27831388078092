import React from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { Moment } from 'moment';

import { BaseDateInput, BaseDateInputProps } from './index';

export type DateInputProps = Omit<BaseDateInputProps, 'format'>;

export function DateInput(props: BaseDateInputProps) {
  return <BaseDateInput {...props} format='YYYY-MM-DD' />;
}

// This will allow today and all past dates
export function disableFutureDates(momentDate: Moment) {
  const date = dayjs(momentDate.toDate());

  return date.isAfter(dayjs(), 'day');
}

// This will allow today and all future dates
export function disablePastDates(momentDate: Moment) {
  const date = dayjs(momentDate.toDate());

  return date.isBefore(dayjs(), 'day');
}

// This will allow dates within a specific date range
export function getDisableDatesOutsideOfRange(
  startDate: Dayjs,
  endDate: Dayjs
) {
  return (momentDate: Moment) => {
    const date = dayjs(momentDate.toDate());

    return (
      date.isBefore(dayjs(startDate.toDate()), 'day') ||
      date.isAfter(dayjs(endDate.toDate()), 'day')
    );
  };
}
