export const DRAFT_INVOICE_STATUS = { label: 'Draft', id: 'draft' } as const;
export const AWAITING_APPROVAL_INVOICE_STATUS = {
  label: 'Awaiting approval',
  id: 'awaiting_approval'
} as const;
export const AWAITING_PAYMENT_INVOICE_STATUS = {
  label: 'Awaiting payment',
  id: 'awaiting_payment'
} as const;
export const PAID_INVOICE_STATUS = { label: 'Paid', id: 'paid' } as const;
export const VOID_INVOICE_STATUS = { label: 'Void', id: 'void' } as const;

export const ALL_INVOICE_STATUS_VALUE_LIST = [
  DRAFT_INVOICE_STATUS,
  AWAITING_APPROVAL_INVOICE_STATUS,
  AWAITING_PAYMENT_INVOICE_STATUS,
  PAID_INVOICE_STATUS,
  VOID_INVOICE_STATUS
] as const;

export const CREATE_INVOICE_STATUS_VALUE_LIST = [
  DRAFT_INVOICE_STATUS,
  AWAITING_APPROVAL_INVOICE_STATUS,
  AWAITING_PAYMENT_INVOICE_STATUS
] as const;

export type InvoiceStatus = typeof ALL_INVOICE_STATUS_VALUE_LIST[number]['id'];
