import _ from 'lodash';

export function modelTypeToUrl(type: string) {
  /**
   *  TODO: We do not have access to the url via the EntityModel
   *
   *  There is a PR to add this functionality but for now we can manually translate the type
   *  https://github.com/rexlabsio/vivid-private/pull/434
   */

  const startCase = _.startCase(type);
  let kebabCase = _.kebabCase(startCase);

  // TODO: Probably better to pass this in as config if this grows too much more, otherwise it makes testing harder
  const toReplace = [
    { original: 'financials-', replacement: 'financials/' },
    { original: 'communication-', replacement: 'communication/' },
    { original: 'settings-', replacement: 'settings/' },
    { original: 'properties-', replacement: 'properties/' }
  ];

  toReplace.forEach(({ original, replacement }) => {
    if (kebabCase.startsWith(original)) {
      kebabCase = _.replace(kebabCase, original, replacement);
    }
  });

  return kebabCase;
}
