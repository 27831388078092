import { Payable } from 'utils/api/get-payables';
import { ReceiptingFundsLineItemEntry } from 'src/modules/financials/blocks/trust-journal-entry-line-items';

export function transformToReceiptingFundsLineItemEntries(payables: Payable[]) {
  return payables.map(
    (payable): ReceiptingFundsLineItemEntry => {
      return {
        category: 'invoice',
        invoice_payment: {
          invoice: payable.invoice
        }
      };
    }
  );
}
