import React from 'react';

import { BlockConfig } from 'view/components/record-screen/types';

import {
  InspectionRunFinishForm,
  InspectionRunFinishFormValues,
  inspectionRunFinishFormValidationConfig
} from '../components/inspection-run-finish-form';

export type InspectionRunFinishBlockFormValues = InspectionRunFinishFormValues;
export type InspectionRunFinishData = Required<InspectionRunFinishFormValues>;

export const inspectionRunFinishBlock: BlockConfig<
  InspectionRunFinishData,
  any,
  InspectionRunFinishBlockFormValues
> = {
  id: 'inspection-run-finish',
  title: 'Inspection run finish',
  validate: {
    definitions: {
      ...inspectionRunFinishFormValidationConfig
    }
  },
  Edit: () => {
    return <InspectionRunFinishForm />;
  }
};
