import React from 'react';
import { Dayjs } from 'dayjs';

import { formatDate, formatDateTime } from 'utils/dates/format';

import { Value, ValuedValueProps } from '.';

interface DateValueProps extends Omit<ValuedValueProps, 'value'> {
  value?: string | Date | Dayjs | null;
}

export function DateValue({ value, ...props }: DateValueProps) {
  return <Value {...props} value={value ? formatDate(value) : undefined} />;
}

export function DateTimeValue({ value, ...props }: DateValueProps) {
  return <Value {...props} value={value ? formatDateTime(value) : undefined} />;
}
