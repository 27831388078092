import { ValueListValue } from 'data/models/types';
import { ValueListItem } from 'utils/normaliser/value-list-item';
import { api } from './api-client';

type ValueListItems = ValueListValue<string> & { parent: null | string };

export async function getValueListItems(
  valueListId: string,
  filter?: (items: ValueListItem[]) => ValueListItem[]
): Promise<ValueListItems[]> {
  const valueList = await api.get(`/value-lists/${valueListId}`);
  const items = valueList.data?.items;

  return filter ? filter(items) : items;
}
