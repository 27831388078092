import * as React from 'react';
import { Card } from 'view/components/card';
import { ListScreen } from 'view/components/list-screen/list-screen';
import { transformActionDeclarationsToActionMenuItems } from 'utils/actions/transforms';
import { query } from '@rexlabs/model-generator';
import { ListTable, TabbedTable } from 'view/components/table';
import EmptyRecord from 'src/assets/illustrations/empty-record.svg';
import { Message } from '@rexlabs/notifications';
import { useCallback } from 'react';
import { BulkAction } from '@rexlabs/table/lib/types/bulk-actions/simple';
import { useTableFilters } from 'view/hooks/use-table-filters';
import { useGetBillProcessingActions } from '../hooks/action-menu-items/use-get-bill-processing-actions';
import { useGetUploadBillsAction } from '../hooks/action-declarations/use-get-upload-bills-action';
import { useBillProcessingColumns } from '../utils/bill-processing-columns';
import { financialsUploadedBillsModel } from '../models/uploaded-bills';
import { useGetProcessBillAction } from '../hooks/action-declarations/use-get-process-bill-action';
import { useGetDeleteBillAction } from '../hooks/action-declarations/use-get-delete-bill-action';

const getBillProcessingQuery = () => query`{
  ${financialsUploadedBillsModel} {
    id
    invoice
    file
  }
}`;

const EmptyState = () => {
  const uploadBillsAction = useGetUploadBillsAction();

  return (
    <Card>
      <Message
        title='Bills to process'
        Illustration={EmptyRecord}
        actions={[uploadBillsAction]}
      >
        Uploaded bills that have not yet been processed will be displayed here.
      </Message>
    </Card>
  );
};

export function BillProcessingList() {
  const { getFilters, getSort } = useTableFilters('uploaded-bills');

  const getBillProcessingActions = useGetBillProcessingActions();
  const uploadBillsAction = useGetUploadBillsAction();
  const getProcessBillAction = useGetProcessBillAction();
  const getDeleteBillAction = useGetDeleteBillAction();

  const getBulkActions = useCallback(
    (args) => {
      return [
        getProcessBillAction(args.selectedItems),
        getDeleteBillAction(args.selectedItems)
      ] as Array<BulkAction>;
    },
    [getProcessBillAction, getDeleteBillAction]
  );

  const getActionMenuItems = useCallback(
    ({ item }) => {
      const actions = getBillProcessingActions([item]);
      return transformActionDeclarationsToActionMenuItems(actions);
    },
    [getBillProcessingActions]
  );

  const billProcessingColumns = useBillProcessingColumns();
  const columnsString = JSON.stringify(billProcessingColumns);

  const tabs = React.useMemo(() => {
    const commonProps = {
      id: 'bill-processing',
      Table: ListTable,
      getQuery: getBillProcessingQuery,
      emptyWithoutTable: true,
      Empty: EmptyState,
      columns: billProcessingColumns,
      getSort,
      getFilters,
      initialSortBy: [{ id: 'created_at', desc: true }]
    };

    return [
      {
        ...commonProps,
        name: 'not-processed',
        label: 'Not processed',
        getActionMenuItems,
        forcedGlobalFilter: [
          {
            field: 'status_id',
            op: 'eq',
            value: 'not_processed'
          }
        ],
        getBulkActions
      },
      {
        ...commonProps,
        name: 'processed',
        label: 'Processed',
        forcedGlobalFilter: [
          {
            field: 'status_id',
            op: 'eq',
            value: 'processed'
          }
        ]
      }
    ];
  }, [columnsString]);

  return (
    <ListScreen title='Bill processing' actions={[uploadBillsAction]}>
      <Card>
        <TabbedTable tabs={tabs} />
      </Card>
    </ListScreen>
  );
}
