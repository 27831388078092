import React, { useMemo } from 'react';

import { Columns, RecordTable } from 'view/components/table';
import { TenancyPrepaymentBucket } from 'src/modules/prepayments/types/tenancy-prepayment-bucket';
import { TransferBucketsPopout } from 'src/modules/trust-journal-entries/popouts/transfer-buckets-popout';
import { Tenancy } from 'data/models/entities/tenancies';
import { BankAccount } from 'data/models/entities/financials/bank-accounts';
import { useTrustLedger } from 'src/modules/financials/utils/use-trust-ledger';
import { TrustLedger } from 'src/modules/trust-ledgers/types/trust-ledger-types';
import { useGetTenancyPrepaymentBuckets } from '../hooks/use-get-tenancy-prepayment-buckets';
import { useSelectedBankAccountContext } from '../contexts/selected-bank-account-context';

function getColumns({
  bankAccount,
  tenancy,
  trustLedger
}: {
  bankAccount: BankAccount;
  tenancy: Tenancy;
  trustLedger: TrustLedger | undefined;
}): Columns<TenancyPrepaymentBucket> {
  return [
    {
      id: 'name',
      Header: 'Bucket name'
    },
    {
      id: 'property',
      Header: 'Property',
      accessor: (item) => item.property?.display_name || 'All'
    },
    {
      id: 'amount',
      Header: 'Balance',
      type: 'currency'
    },
    {
      id: 'transfer_action',
      accessor: (item) => item,
      sticky: 'right',
      width: 60,
      Cell: ({ value }) => {
        return (
          <TransferBucketsPopout
            bankAccount={bankAccount}
            tenancy={tenancy}
            bucketToTransferFrom={value}
            ledger={trustLedger}
          />
        );
      }
    }
  ];
}

export function PrepaymentTrustAccountList({
  tenancy
}: {
  tenancy: Tenancy | undefined;
}) {
  const tenancyId = tenancy?.id;

  const { bankAccount } = useSelectedBankAccountContext();

  const {
    data,
    isLoading: isTenancyPrepaymentBucketsLoading
  } = useGetTenancyPrepaymentBuckets(tenancyId, bankAccount?.id);

  const { isLoading: isTrustLedgerLoading, trustLedger } = useTrustLedger(
    tenancy,
    bankAccount!
  );

  const columns = useMemo(() => {
    return bankAccount && tenancy
      ? getColumns({ bankAccount, tenancy, trustLedger })
      : [];
  }, [bankAccount, tenancy, trustLedger]);

  return (
    <RecordTable
      id='prepayment-trust-account-list'
      columns={columns}
      isLoading={isTenancyPrepaymentBucketsLoading || isTrustLedgerLoading}
      items={data}
      getRowId={(item: TenancyPrepaymentBucket) =>
        `${item?.bucket_id},${item.property?.id}`
      }
    />
  );
}
