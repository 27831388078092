import React, { useState } from 'react';
import { StyleSheet, StylesProvider, useStyles } from '@rexlabs/styling';
import Box from '@rexlabs/box';
import { Column, Grid } from '@rexlabs/grid';
import ActionMenu from '@rexlabs/action-menu';

import ContactIcon from 'view/components/icons/contact';
import ChevronDownIcon from 'view/components/icons/chevron-down';

import { BatchReceiptingButtonConfig } from 'src/modules/banking/batch-receipting/hooks/use-batch-receipting-button-config';
import { OutlineSplitButton } from '@rexlabs/button';
import {
  BatchReceiptingItem,
  BatchReceiptingListItem,
  BatchReceiptingListItemWithActions
} from '../types';
import { getReceiptDescription } from '../utils/get-receipt-description';
import { openSplitBankTransactionsDialog } from '../hooks/actions/use-get-split-transaction-view-edit-action';
import { BankTransactionCard } from './bank-transaction-card';
import { ReceiptCard } from './receipt-card';

const defaultStyles = StyleSheet({
  actionButton: {
    minWidth: '178px'
  }
});

function BatchReceiptingLineItemInner({
  SplitActionButton,
  actions,
  data,
  intent,
  primaryAction
}: BatchReceiptingListItemWithActions) {
  const receiptDescription = getReceiptDescription(
    data?.prioritised_match?.rent_invoice_count || 0,
    data?.prioritised_match?.non_rent_invoice_count || 0
  );

  const handleSplitManualTransactionAction = React.useCallback(
    () => openSplitBankTransactionsDialog(data),
    [data.parent_transaction_id]
  );

  return (
    <Grid columns={16}>
      <Column width={6}>
        <BankTransactionCard
          RecordIcon={ContactIcon}
          amount={data.amount}
          date={data.paid_date}
          intent={intent}
          summary={data.description}
          bankReferenceId={data.reference}
          isChild={data.parent_transaction_id != null}
          onSplitManualTransactionAction={handleSplitManualTransactionAction}
        />
      </Column>

      <Column width={4}>
        <Box
          flexDirection='row'
          alignItems='center'
          justifyContent='center'
          height='100%'
        >
          <BatchReceiptingLineItemAction
            SplitActionButton={SplitActionButton}
            primaryAction={primaryAction}
            actions={actions}
          />
        </Box>
      </Column>

      <Column width={6}>
        <ReceiptCard
          amount={data.amount}
          intent={intent}
          summary={data.prioritised_match?.folio?.display_name}
          invoicesDescription={receiptDescription}
          receiptingInstruction={data.prioritised_match?.receipting_instruction}
          status={
            data.status.label === 'New'
              ? data?.prioritised_match?.status.label
              : data.status.label
          }
        />
      </Column>
    </Grid>
  );
}

export const BatchReceiptingLineItem = React.memo(function LineItem({
  data,
  intent,
  SplitActionButton,
  getActions,
  key
}: BatchReceiptingListItem & {
  getActions: (item: BatchReceiptingItem) => BatchReceiptingButtonConfig;
  key: string;
}) {
  const { primaryAction, actions } = React.useMemo(() => {
    return getActions(data);
  }, [data, getActions]);

  return (
    <BatchReceiptingLineItemInner
      data={data}
      intent={intent}
      SplitActionButton={SplitActionButton}
      primaryAction={primaryAction}
      actions={actions}
      key={key}
    />
  );
});

const overrideStyles = StyleSheet({
  container: {
    borderLeftWidth: '0.1rem !important',
    borderRightWidth: '0.1rem !important',
    ':nth-of-type(2)': {
      marginLeft: '-2px'
    },

    ':hover': {
      zIndex: 2
    }
  }
});

export function BatchReceiptingLineItemAction({
  SplitActionButton,
  actions,
  primaryAction
}: Pick<
  BatchReceiptingListItemWithActions,
  'SplitActionButton' | 'primaryAction' | 'actions'
>) {
  const s = useStyles(defaultStyles);

  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async () => {
    setIsLoading(true);
    await primaryAction.onClick();
    setIsLoading(false);
  };

  const hasOverrides = Object.is(SplitActionButton, OutlineSplitButton);

  const action = (
    <SplitActionButton
      {...s('actionButton')}
      Left={({ Button }) => (
        <Button
          type='submit'
          IconLeft={primaryAction.Icon}
          onClick={handleSubmit}
          isLoading={isLoading}
          {...s('actionButton')}
        >
          {primaryAction.label}
        </Button>
      )}
      Right={({ IconButton }) => (
        <ActionMenu
          placement='bottom-end'
          items={actions}
          Button={React.forwardRef((props, ref) => (
            <IconButton
              {...props}
              ref={ref}
              isDisabled={isLoading}
              Icon={ChevronDownIcon}
              data-testid='split-action'
            />
          ))}
        />
      )}
    />
  );

  if (hasOverrides) {
    return (
      <StylesProvider
        tokens={{ 'button.split.divider.background.color': 'transparent' }}
        components={{ Button: overrideStyles }}
      >
        {action}
      </StylesProvider>
    );
  }

  return action;
}
