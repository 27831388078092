import * as React from 'react';
import { useCallback } from 'react';
import { useErrorDialog } from '@rexlabs/dialog';
import { useModelActions } from '@rexlabs/model-generator';
import { useToast } from 'view/components/@luna/notifications/toast';
import { Ownership, ownershipsModel } from 'data/models/entities/ownerships';
import { RecordTypes } from 'data/models/types';
import { statementsModel } from 'src/modules/statements/models/statements';
import { StatementStatus, StatementType } from 'src/modules/statements/types';
import { contactsModel } from 'src/modules/contacts/models/contacts';
import { Contact } from 'src/modules/contacts/types/contact-types';
import { lowerCase, upperFirst } from 'lodash';

interface YearlyStatementArgs {
  statementType: StatementType.YearlyOwnership | StatementType.YearlyAgency;
  statementStartDate: string;
}

interface PeriodicStatementArgs {
  statementType: StatementType.PeriodicOwnership | StatementType.PeriodicAgency;
  statementStartDate?: string;
}

interface CommonStatementArgs {
  selectedItems: Ownership[] | Contact[];
  statementEndDate: string;
  recordType: typeof RecordTypes.Ownership | typeof RecordTypes.Contact;
  status?: StatementStatus;
  status_reason?: string;
  ignore_agency_fees?: boolean;
}

export type CreateStatementsArgs =
  | (PeriodicStatementArgs & CommonStatementArgs)
  | (YearlyStatementArgs & CommonStatementArgs);

export const useCreateStatements = () => {
  const { createItem: createStatements } = useModelActions(statementsModel);
  const errorDialog = useErrorDialog();
  const { addToast } = useToast();
  const { refreshLists: refreshStatementLists } = useModelActions(
    statementsModel
  );
  const { refreshLists: refreshOwnershipLists } = useModelActions(
    ownershipsModel
  );
  const { refreshLists: refreshContactLists } = useModelActions(contactsModel);

  return useCallback(
    function actionStatements(issueStatementArgs: CreateStatementsArgs) {
      const handleConfirmation = async ({
        selectedItems,
        statementEndDate,
        statementStartDate,
        statementType,
        recordType,
        status_reason,
        status = StatementStatus.Issued
      }: CreateStatementsArgs) => {
        try {
          const statements = selectedItems.map((selectedItem) => ({
            statement_to: statementEndDate,
            ...(statementStartDate && {
              statement_from: statementStartDate
            }),
            type: {
              id: statementType
            },
            status: {
              id: status
            },
            status_reason,
            object: {
              id: selectedItem.id,
              type: { id: recordType }
            },
            ignore_agency_fees: issueStatementArgs.ignore_agency_fees
          }));

          await createStatements({
            data: { actions: { for_objects: statements } }
          });

          refreshStatementLists();
          recordType === RecordTypes.Ownership
            ? refreshOwnershipLists()
            : refreshContactLists();

          addToast({
            description: getToastText({
              statementLength: selectedItems.length,
              status
            }),
            color: 'information'
          });

          return true;
        } catch (error) {
          errorDialog.open(error);
        }
      };

      return handleConfirmation(issueStatementArgs);
    },
    [
      refreshStatementLists,
      refreshOwnershipLists,
      refreshContactLists,
      addToast,
      createStatements,
      errorDialog
    ]
  );
};

interface ToastTextArgs {
  statementLength: number;
  status: StatementStatus;
}

function getToastText({ statementLength, status }: ToastTextArgs) {
  if (status === StatementStatus.Issued) {
    return statementLength > 1
      ? `${statementLength} statements are currently being issued.`
      : `The statement is currently being issued.`;
  }

  return statementLength > 1 ? (
    `${statementLength} statements are currently being created.`
  ) : (
    <>
      The statement is currently being created with status{' '}
      <strong>{upperFirst(lowerCase(status))}</strong>.
    </>
  );
}
