import * as React from 'react';
import { DialogProps, ConfirmationDialog } from '@rexlabs/dialog';

export interface DeleteChecklistItemTriggerDialogProps extends DialogProps {
  onDelete: () => void;
}

export function DeleteChecklistItemTriggerDialog({
  onDelete,
  onClose
}: DeleteChecklistItemTriggerDialogProps) {
  const handleConfirm = React.useCallback(() => {
    onDelete();

    return true;
  }, [onDelete]);

  return (
    <ConfirmationDialog
      destructive
      onClose={onClose}
      onConfirm={handleConfirm}
      confirmText='Delete triggered action'
      title='Delete triggered action'
      message='Are you sure you want to delete the triggered action from this subtask? This will not affect any existing tasks that have already added this checklist.'
    />
  );
}
