/* eslint-disable max-lines */
import React, {
  useRef,
  useEffect,
  useState,
  ComponentType,
  Dispatch,
  SetStateAction,
  useCallback,
  Fragment
} from 'react';
import ReactDOM from 'react-dom';

import { StylesProvider } from '@rexlabs/styling';
import { HeadingProps } from '@rexlabs/text';
import { ReactForms, ReactFormsProps, Forms } from '@rexlabs/form';
import { FileUploadContext, useDropzone } from '@rexlabs/file-upload-input';
import { Breakpoints } from '@rexlabs/breakpoints';
import { TransitionSingle } from '@rexlabs/animation';
import { Overlay } from '@rexlabs/overlay';

import { formGridTokens } from 'view/components/@luna/grid/form';
import { WorkInProgress } from 'view/components/work-in-progress';

import { BlockConfig } from '../types';

import { InlineContent } from './content/inline';
import { FloatingEditContent } from './content/floating-edit';
import { FloatingViewContent } from './content/floating-view';

export type FormConfig = {
  id: string;
  validate?: ReactFormsProps<any, any>['validate'];
  fieldNames?: string[];
};

export interface CardProps extends BlockConfig {
  data?: any;
  forms?: Forms;
  initialValues?: any;
  isLoading?: boolean;
  blockEditModes?: { [key: string]: boolean };
  setBlockEditModes?: Dispatch<SetStateAction<{ [key: string]: boolean }>>;
  headingLevel?: HeadingProps['level'];
  Actions?: ComponentType<any>;
  item?: any;
  index?: number;
  setForms?: Dispatch<SetStateAction<FormConfig[]>>;
  alwaysRenderView?: boolean;
  isDropzone?: boolean;
  isCollapsible?: boolean;
  isMobileForcedCollapsed?: boolean;
  tags?: Array<JSX.Element>;
}

export function Card(props: CardProps) {
  const {
    id,
    data,
    forms,
    initialValues,
    isLoading,
    blockEditModes,
    setBlockEditModes,
    title,
    validateOnBlur,
    workInProgress,
    validate,
    setForms,
    fieldNames,
    isDropzone,
    isCollapsible,
    View,
    Edit,
    Card
  } = props;

  const contentRef = useRef<HTMLDivElement>(null);

  const [position, setPosition] = useState<{
    top: number;
    height: string;
  } | null>(null);

  const [showFloatingView, setShowFloatingView] = useState(false);

  const editMode = id ? blockEditModes?.[id] : false;

  const setEditMode = useCallback(
    (value) =>
      setBlockEditModes?.((state) => (id ? { ...state, [id]: value } : state)),
    [id, setBlockEditModes]
  );

  // Register the form if it doesn't already exist in the multiform,
  // this will make sure dynamic blocks will be properly handled on
  // submit
  useEffect(() => {
    if (!Card && id) {
      setForms?.((oldForms) =>
        oldForms.find((form) => form?.id === id)
          ? oldForms
          : oldForms.concat({ id, validate, fieldNames })
      );
    }
  }, [Card, id, setForms, validate, fieldNames]);

  // Drag and drop behaviour
  const dropHandler = useRef<(files: File[]) => void | Promise<any>>();
  const setOnDropContext = useCallback((fn) => {
    dropHandler.current = fn;
  }, []);

  const { isDragging, isDropping } = useDropzone({
    ref: contentRef,
    enabled: !!isDropzone,
    onDrop: (files) => {
      setEditMode?.(true);
      const promise = dropHandler.current?.(files);

      const uploadingEvent = (value) =>
        window.document.dispatchEvent(
          new CustomEvent('customUploading', { detail: value })
        );
      if (promise && promise?.then) {
        uploadingEvent(true);
        promise
          .then(() => {
            uploadingEvent(false);
          })
          .catch(() => {
            uploadingEvent(false);
          });
      }
    }
  });

  const [uploading, setUploading] = useState(false);
  // HACK: for whatever reason setting the state within `useDropzone` doesn't
  // work (= doesn't trigger the state change and the re-render), so we're temporarily
  // working around that by sending custom events
  useEffect(() => {
    const handleUploading = (e) => setUploading(e.detail);
    window.document.addEventListener('customUploading', handleUploading);
    return () =>
      window.document.removeEventListener('customUploading', handleUploading);
  }, []);

  const Wrapper = workInProgress ? WorkInProgress : Fragment;

  if (Card) {
    // If the block provides a custom card, we just render that instead
    return (
      <Wrapper>
        <Card
          id={id}
          data={data}
          // TODO: should custom card accept initialValues?
          isLoading={isLoading}
          blockEditModes={blockEditModes}
          setBlockEditModes={setBlockEditModes}
          setForms={setForms}
          title={title}
        />
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <FileUploadContext.Provider value={setOnDropContext}>
        <ReactForms
          id={id}
          names={fieldNames}
          validate={validate}
          initialValues={initialValues || data}
          validateOnBlur={validateOnBlur}
        >
          {({ resetForm, submitForm, ...form }) => {
            return (
              <>
                <InlineContent
                  {...form}
                  {...props}
                  ref={contentRef}
                  forms={forms}
                  editMode={editMode}
                  uploading={uploading}
                  isDragging={isDragging}
                  isDropping={isDropping}
                  isCollapsible={isCollapsible}
                  onEditClick={() => {
                    // reset the form so that old values don't persist after an update
                    resetForm(initialValues || data);

                    // there appears to be a race condition between resetting the form, and setting the edit mode
                    // so we want to wait momentarily before setting the edit mode
                    setTimeout(() => {
                      setEditMode?.(true);
                    }, 250);

                    const rect = contentRef.current?.getBoundingClientRect();
                    setPosition({
                      top: rect?.top ?? 0,
                      height: '100%'
                    });
                  }}
                  onCancelClick={() => {
                    resetForm(initialValues || data);

                    setEditMode?.(false);
                  }}
                  onSaveClick={() => {
                    submitForm();
                  }}
                  onTitleClick={() => {
                    const rect = contentRef.current?.getBoundingClientRect();
                    setPosition({
                      top: rect?.top ?? 0,
                      height: '100%'
                    });
                    setShowFloatingView(true);
                  }}
                />
                <Breakpoints queries={{ maxWidth: 's' }}>
                  {Edit &&
                    ReactDOM.createPortal(
                      <StylesProvider tokens={formGridTokens}>
                        <TransitionSingle
                          show={!!editMode}
                          configs={[
                            {
                              from: position,
                              enter: {
                                top: 0,
                                height: '100%'
                              },
                              leave: position
                            }
                          ]}
                        >
                          <Overlay>
                            <FloatingEditContent
                              {...form}
                              {...props}
                              key='floatingEdit'
                              onCancelClick={() => {
                                resetForm();
                                setEditMode?.(false);
                              }}
                              onSaveClick={() => {
                                submitForm();
                              }}
                              Edit={Edit}
                            />
                          </Overlay>
                        </TransitionSingle>
                      </StylesProvider>,
                      document.body
                    )}
                  {View &&
                    ReactDOM.createPortal(
                      <StylesProvider tokens={formGridTokens}>
                        <TransitionSingle
                          show={!!showFloatingView}
                          configs={[
                            {
                              from: position,
                              enter: {
                                top: 0,
                                height: '100%'
                              },
                              leave: position
                            }
                          ]}
                        >
                          <Overlay>
                            <FloatingViewContent
                              {...form}
                              {...props}
                              key='floatingView'
                              onCancelClick={() => {
                                setShowFloatingView(false);
                              }}
                              onSaveClick={() => {
                                submitForm();
                              }}
                              View={View}
                            />
                          </Overlay>
                        </TransitionSingle>
                      </StylesProvider>,
                      document.body
                    )}
                </Breakpoints>
              </>
            );
          }}
        </ReactForms>
      </FileUploadContext.Provider>
    </Wrapper>
  );
}
