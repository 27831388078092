import * as React from 'react';

import { DialogProps, Dialog } from '@rexlabs/dialog';
import { StyleSheet, useStyles } from '@rexlabs/styling';
import Box from '@rexlabs/box';
import { OutlineButton, PrimaryButton } from '@rexlabs/button';

import { StandardDialogFooter } from 'view/components/dialogs/components/standard-dialog-footer';

import { Note } from '../types/Note';

const defaultStyles = StyleSheet({
  content: {
    whiteSpace: 'pre-wrap'
  }
});

export interface ViewNoteRecordDialogProps extends DialogProps {
  note: Note;
  onConfirm?: () => void;
}

export function ViewNoteRecordDialog({
  onClose,
  note,
  onConfirm
}: ViewNoteRecordDialogProps) {
  const s = useStyles(defaultStyles);

  const phoneCallOutcomeLabel = note.phone_call_outcome?.label;

  return (
    <Dialog
      title={`${note.note_type?.label}${
        phoneCallOutcomeLabel ? ` - ${phoneCallOutcomeLabel}` : ''
      }`}
      onClose={onClose}
    >
      <Box flexDirection='column'>
        <div {...s('content')}>{note.content}</div>
        <StandardDialogFooter>
          <OutlineButton onClick={onClose}>Close</OutlineButton>
          {onConfirm && (
            <PrimaryButton onClick={onConfirm}>Edit note</PrimaryButton>
          )}
        </StandardDialogFooter>
      </Box>
    </Dialog>
  );
}
