import React, { FC } from 'react';

import { Quri } from '@rexlabs/quri';
import { Select, SelectProps } from '@rexlabs/select';
import { useSettingsListQuery } from 'src/modules/settings/hooks/use-settings-list-query';
import { ValueListItem } from 'utils/normaliser/value-list-item';

const supportedTypes = ['supplier', 'property'] as const;

type SupportedType = typeof supportedTypes[number];

type ComplianceSelectProps = SelectProps<ValueListItem> & {
  objectType?: SupportedType;
};

export const ComplianceTypeSelect: FC<ComplianceSelectProps> = ({
  objectType,
  ...props
}) => {
  const filter: Quri[] = [
    ...((objectType
      ? [{ field: 'applicable_to_object_type_id', op: '==', value: objectType }]
      : []) as Quri[])
  ];

  const { data = [] } = useSettingsListQuery('compliance-types', filter);

  return <Select normaliser={(item) => item} items={data} {...props} />;
};
