import { useDialog } from '@rexlabs/dialog';
import { BaseModelGeneratorModel } from '@rexlabs/model-generator';
import { SingleActionDeclaration } from 'src/modules/common/actions/types/action-declaration-types';
import { RecordTypeIdentifier } from 'src/modules/common/utils/Records/get-record-object';
import { CreateNoteRecordDialog } from '../dialogs/create-note-record-dialog';
import { getAttachedTo } from '../utils/get-attached-to';

export function useGetCreateNoteAction(
  recordTypeIdentifier?: RecordTypeIdentifier
) {
  const { open } = useDialog(CreateNoteRecordDialog);

  return ({
    attached,
    hideAddNoteToField
  }: {
    attached?: BaseModelGeneratorModel;
    hideAddNoteToField?: boolean;
  } = {}): SingleActionDeclaration => {
    const attachedTo = getAttachedTo(attached, recordTypeIdentifier);

    return {
      id: 'create-note',
      group: 'notes',
      label: 'Create note',
      handleAction: async () => {
        open({
          initialValues: {
            attached_to: attachedTo
          },
          blockProps: { hideAddNoteToField: !!hideAddNoteToField }
        });
      }
    };
  };
}
