import React from 'react';
import { TitleBlock } from 'view/components/cards/title/title-block';
import { RecordTypes } from 'data/models/types';
import { getRecordTitle } from 'utils/records/get-record-title';
import { ChecklistTemplate } from '../types/ChecklistTemplate';
import { useGetChecklistTemplateActions } from '../hooks/action-menu-items/use-get-checklist-template-actions';

const type = RecordTypes.ChecklistTemplate;

type ChecklistTemplateTitleBlockProps = {
  checklistTemplate?: ChecklistTemplate;
};

export function ChecklistTemplateTitleBlock({
  checklistTemplate
}: ChecklistTemplateTitleBlockProps) {
  const getChecklistTemplateActions = useGetChecklistTemplateActions();

  if (!checklistTemplate) {
    return null;
  }

  const title = getRecordTitle({ type, object: checklistTemplate });

  return (
    <TitleBlock
      title={title}
      type={checklistTemplate.task_type.id}
      actions={getChecklistTemplateActions(checklistTemplate)}
    />
  );
}
