import { SelectNormalised } from '@rexlabs/select-old';
import { Author } from 'src/modules/authors/types/author-types';
import { RecordTypes } from 'data/models/types';

export function authorNormaliser(item: Author): SelectNormalised {
  return {
    value: item,
    label: item.name,
    type: RecordTypes.Author
  };
}
