import * as React from 'react';

import { RecordOption, Select } from '@rexlabs/select';
import { StyleSheet, useStyles } from '@rexlabs/styling';

import { useApiValueList } from 'view/hooks/api/use-api-value-list';

import { TaskPriority } from '../types/TaskPriority';
import { TaskPriorityIcon } from './task-priority-icon';

type TaskPriorityItem = { id: TaskPriority; label: string };

export const styles = StyleSheet({
  spacing: {
    margin: ({ token }) => token('spacing.xs'),
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
    display: 'flex'
  }
});

const IconWithSpacing = (id: TaskPriority) => {
  const s = useStyles(styles);
  return (
    <span {...s('spacing')}>
      <TaskPriorityIcon priority={id} />
    </span>
  );
};

export function TaskPrioritySelect(props) {
  const { data } = useApiValueList('task_priority');

  return (
    <Select
      items={data}
      normaliser={({ id, label }: TaskPriorityItem) => ({
        id,
        label,
        Icon: () => IconWithSpacing(id)
      })}
      Option={RecordOption}
      ValuePrefix={() => IconWithSpacing(props.value.id)}
      {...props}
    />
  );
}
