import React, { useCallback } from 'react';

import { RecordDialog } from 'view/components/record-screen/dialog/dialog';
import { DialogContentConfig } from 'view/components/record-screen/dialog/types';

import { RecordSubmitHandler } from 'view/components/record-screen/utils';
import { useModelActions } from '@rexlabs/model-generator';
import { filterNullValues } from 'utils/filter-null-values';
import {
  financialsReconciliationsModel,
  Reconciliation
} from 'data/models/entities/financials/reconciliations';
import { formatDate } from 'utils/dates/format';
import { trackStartReconciliation } from 'src/modules/reconciliations/utils/analytics';
import { startMonthlyReconciliation } from '../blocks/start-monthly-reconciliation';

const content: DialogContentConfig = [
  {
    id: 'basics',
    label: 'Basics',
    blocks: [startMonthlyReconciliation]
  }
];

interface StartMonthlyReconciliationDialogProps {
  bankAccountId: string;
  period: { start_date: string; end_date: string };
  updateListId?: string;
  onClose?: () => void;
  onCreate?: (item: Reconciliation) => void;
}

export function StartMonthlyReconciliationDialog({
  bankAccountId,
  period,
  updateListId,
  onClose,
  onCreate
}: StartMonthlyReconciliationDialogProps) {
  const { createItem, refreshList } = useModelActions(
    financialsReconciliationsModel
  );

  const handleSubmit: RecordSubmitHandler = useCallback(
    async ({ values }) => {
      const { data } = await createItem({
        data: filterNullValues({
          ...values,
          type: {
            id: 'end_of_month'
          }
        }),
        args: {
          include: 'reconciliation_summary'
        }
      });

      onCreate?.(data);

      trackStartReconciliation(data);

      return data;
    },
    [createItem, refreshList, updateListId]
  );

  const data = {
    bank_account: {
      id: bankAccountId
    },
    start_date: period.start_date,
    statement_date: period.end_date,
    reconciliation_period: `${formatDate(period.start_date)} - ${formatDate(
      period.end_date
    )}`
  };

  return (
    <RecordDialog
      onClose={onClose}
      data={data}
      title='Start monthly reconciliation'
      handleSubmit={handleSubmit}
      content={content}
      submitLabel='Start reconciling'
    />
  );
}
