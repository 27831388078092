import { QuoteTask } from '../types/QuoteTask';

export function isQuotePriceLessThanSpendLimit(quoteTask: QuoteTask): boolean {
  const spendLimit =
    quoteTask?.property?.active_property_ownership?.ownership
      .maintenance_instructions.spend_limit;
  const quotePrice = quoteTask?.details?.amount;

  return !spendLimit || !quotePrice || spendLimit > quotePrice;
}
