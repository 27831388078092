import React from 'react';

import { DialogProps } from '@rexlabs/dialog';

import { Contact } from 'src/modules/contacts/types/contact-types';
import { createManualAccountingJournalEntryBlock } from 'src/modules/account-journal-entries/blocks/create-manual-accounting-journal-entry';

import { Ownership } from 'data/models/entities/ownerships';

import { getToday } from 'utils/dates/dates';

import { RecordDialog } from 'view/components/record-screen/dialog/dialog';

const content = [
  {
    id: 'create-manual-supplier-commission-entry',
    label: 'Details',
    blocks: [createManualAccountingJournalEntryBlock]
  }
];

interface CreateManualSupplierCommissionEntryDialogProps extends DialogProps {
  folio: Ownership | Contact;
  submitHandler: any;
}

export function CreateManualSupplierCommissionEntryDialog({
  folio,
  submitHandler,
  ...dialogProps
}: CreateManualSupplierCommissionEntryDialogProps) {
  return (
    <RecordDialog
      {...dialogProps}
      title='Create commission entry'
      submitLabel='createEntry'
      content={content}
      handleSubmit={submitHandler}
      initialValues={{ folio, date_of: getToday(), is_tax_included: true }}
    />
  );
}
