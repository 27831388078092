import * as React from 'react';
import Box from '@rexlabs/box';
import { PrimaryIconButton } from '@rexlabs/button';
import { border, StylesProvider } from '@rexlabs/styling';
import { TextInput } from '@rexlabs/text-input';
import SearchIcon from 'view/components/icons/search';

const tokens = {
  textInput: {
    ...border.tokens({
      target: 'searchInput.textInput',
      right: {
        width: 'none',
        radius: 'none'
      }
    }),
    disabled: {
      ...border.tokens({
        target: 'searchInput.textInput.disabled',
        right: {
          width: 'none',
          radius: 'none'
        }
      })
    }
  },
  button: {
    ...border.tokens({
      target: 'searchInput.button',
      left: {
        radius: 'none'
      }
    }),
    disabled: {
      ...border.tokens({
        target: 'searchInput.button.disabled',
        left: {
          radius: 'none'
        }
      })
    }
  },
  field: {
    margin: {
      bottom: 0
    },
    last: {
      margin: {
        bottom: 0
      }
    }
  }
};

export interface SearchInputProps {
  onSubmit?: (value: string) => void;
  onChange?: (value: string) => void;
  placeholder?: string;
  initialValue?: string;
}

/**
 * This is a generic component that implements the same UI as the vivid search component
 * the main difference is that this one doesn't have a form built into it, so it can be used for more specialized scenarios
 */
export function SearchInput({
  onSubmit,
  onChange,
  placeholder,
  initialValue
}: SearchInputProps) {
  const [value, setValue] = React.useState(initialValue || '');

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
    onChange?.(e.target.value);
  };

  return (
    <StylesProvider tokens={tokens}>
      <Box flexDirection='row' alignItems='center' justifyContent='center'>
        <TextInput
          value={value}
          onChange={handleChange}
          placeholder={placeholder}
        />
        <PrimaryIconButton
          role='button'
          type='submit'
          Icon={SearchIcon}
          onClick={(e) => {
            e.preventDefault();
            onSubmit?.(value);
          }}
        />
      </Box>
    </StylesProvider>
  );
}
