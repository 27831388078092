import { transformActionDeclarationsToActionMenuItems } from 'utils/actions/transforms';

import { Note } from '../types/Note';
import { useGetDeleteNoteAction } from './action-declarations/use-get-delete-note-action';
import { useGetEditNoteAction } from './action-declarations/use-get-edit-note-action';
import { useGetViewNoteAction } from './action-declarations/use-get-view-note-action';

export function useGetNoteActions() {
  const getDeleteNoteAction = useGetDeleteNoteAction();
  const getEditNoteAction = useGetEditNoteAction();
  const getViewNoteAction = useGetViewNoteAction();

  return (note?: Note, hideAddNoteToField = false) => {
    if (!note) return [];
    const deleteNoteAction = getDeleteNoteAction(note);
    const editNoteAction = getEditNoteAction(note, hideAddNoteToField);
    const viewNoteAction = getViewNoteAction(note, hideAddNoteToField);

    return [
      ...transformActionDeclarationsToActionMenuItems([
        editNoteAction,
        viewNoteAction,
        deleteNoteAction
      ])
    ];
  };
}
