import { InvoiceLineItem } from 'data/models/entities/financials/invoices';

export function hasPayableByChartOfAccountsAccount(
  lineItems: InvoiceLineItem[]
) {
  return lineItems.some(
    (lineItem) => !!lineItem.payable_by_chart_of_accounts_account
  );
}

export function hasPayableToChartOfAccountsAccount(
  lineItems: InvoiceLineItem[]
) {
  return lineItems.some(
    (lineItem) => !!lineItem.payable_to_chart_of_accounts_account
  );
}
