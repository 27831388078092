export const BANK_DEPOSITS_ROUTES = {
  BANK_DEPOSITS_LIST: {
    config: {
      path: '/bank-deposits',
      Component: require('./screens/bank-deposits-list').BankDepositsListScreen
    }
  },
  BANK_DEPOSIT_DETAILS: {
    config: {
      path: '/bank-deposit/:bankDepositId',
      Component: require('./screens/bank-deposit-details').BankDepositDetails
    }
  }
};
