import React from 'react';

import Box from '@rexlabs/box';
import { useToken } from '@rexlabs/styling';
import { useMediaQuery } from '@rexlabs/breakpoints';

import ImagesIcon from 'view/components/icons/images';
import CommentIcon from 'view/components/icons/comment';

import { InspectionReportRoom } from '../types/InspectionReportRoom';
import { RoomConditionTag } from './room-condition-tag';

/**@deprecated We are changing inspection reports to use InspectionAreas rather than Rooms*/
export function InspectionReportRoomTitle({
  room
}: {
  room: InspectionReportRoom;
}) {
  const matchesMobile = useMediaQuery({ maxWidth: 's' });

  const token = useToken();

  if (!matchesMobile) {
    return <span>{room.name}</span>;
  }

  return (
    <Box flexDirection='row' alignItems='center' columnGap={token('spacing.s')}>
      <span>{room.name}</span>

      {room.room_condition && room.room_condition?.id !== 'none' && (
        <RoomConditionTag condition={room.room_condition} />
      )}

      {(room.notes || []).length > 0 && (
        <Box
          flexDirection='row'
          alignItems='center'
          data-testid={'comments.icon'}
        >
          <CommentIcon color={token('color.textStyle.link.idle.default')} />
        </Box>
      )}

      {(room.images.data || []).length > 0 && (
        <Box
          flexDirection='row'
          alignItems='center'
          data-testid={'images.icon'}
        >
          <ImagesIcon color={token('color.textStyle.link.idle.default')} />
        </Box>
      )}
    </Box>
  );
}
