import React from 'react';
import { CellProps, DateCell } from '@rexlabs/table';
import { TwoTierCell } from 'view/components/cells/two-tier-cell';
import { StyleSheet, useStyles } from '@rexlabs/styling';
import { Appliance } from '../types/Appliance';
import { getDaysUntilExpiry } from '../utils/get-days-until-expiry';

const defaultStyles = StyleSheet({
  expired: {
    color: ({ token }) => token('color.textStyle.danger.idle.default')
  }
});

export function WarrantyExpiryCell({
  value,
  column
}: CellProps<unknown, Appliance>) {
  const s = useStyles(defaultStyles);

  const daysUntilExpiry = value?.warranty_expires_date
    ? getDaysUntilExpiry(value.warranty_expires_date)
    : '--';

  return (
    <TwoTierCell
      text={
        <DateCell
          value={value?.warranty_expires_date}
          column={column}
        ></DateCell>
      }
      subtext={
        <div {...s({ expired: daysUntilExpiry === 'Expired' })}>
          {daysUntilExpiry}
        </div>
      }
    />
  );
}
