import {
  HotkeyedActionDeclaration,
  SingleActionDeclaration
} from 'src/modules/common/actions/types/action-declaration-types';
import {
  Statement,
  StatementStatus,
  StatementType
} from 'src/modules/statements/types';
import { RecordTypes } from 'data/models/types';
import { Ownership } from 'data/models/entities/ownerships';
import { useGenericSetStatementStatus } from 'src/modules/statements/hooks/use-generic-set-statement-status';
import { invokeActionDeclaration } from 'src/modules/common/actions/utils/invoke-action-declaration';
import { useState } from 'react';

type SharedArgs = {
  onComplete: () => void;
};

type StatementExistArgs = {
  statement: Statement;
};

type PendingStatementArgs = {
  ownership: Ownership;
  statementType: StatementType;
  statementStartDate?: string;
  statementEndDate: string;
};

type Args =
  | (StatementExistArgs & SharedArgs)
  | (PendingStatementArgs & SharedArgs);

export const useGetStatementHotkeyActions = () => {
  const [isActionLoading, setIsActionLoading] = useState(false);

  const runAction = async (
    action: SingleActionDeclaration,
    onComplete: () => void
  ) => {
    if (isActionLoading) {
      return;
    }

    setIsActionLoading(true);

    await invokeActionDeclaration(() => action).then(() => {
      onComplete();
      setIsActionLoading(false);
    });
  };

  const setStatementStatus = useGenericSetStatementStatus();

  return (args: Args): HotkeyedActionDeclaration[] => {
    const sharedActionArgs =
      'statement' in args
        ? {
            statement: args.statement,
            recordType: RecordTypes.Statement
          }
        : {
            recordType: RecordTypes.Ownership,
            ownership: args.ownership,
            statementType: args.statementType,
            statementEndDate: args.statementEndDate,
            statementStartDate: args.statementStartDate
          };

    return [
      {
        id: 'approve',
        label: 'Approve',
        hotkey: 'A',
        handleAction: async () =>
          await runAction(
            setStatementStatus({
              status: StatementStatus.Approved,
              label: 'Approved',
              ...sharedActionArgs
            }),
            args.onComplete
          )
      },
      {
        id: 'needs_further_review',
        label: 'Needs further review',
        hotkey: 'S',
        handleAction: async () =>
          await runAction(
            setStatementStatus({
              status: StatementStatus.NeedsFurtherReview,
              label: 'Needs further review',
              ...sharedActionArgs
            }),
            args.onComplete
          )
      },
      {
        id: 'exclude_statement',
        label: 'Exclude statement',
        hotkey: 'D',
        handleAction: async () =>
          await runAction(
            setStatementStatus({
              status: StatementStatus.Excluded,
              label: 'Excluded',
              ...sharedActionArgs
            }),
            args.onComplete
          )
      },
      {
        id: 'issue_dont_charge',
        label: "Issue, don't charge",
        hotkey: 'F',
        handleAction: async () =>
          await runAction(
            setStatementStatus({
              status: StatementStatus.Issued,
              label: 'Issued',
              ignore_agency_fees: true,
              ...sharedActionArgs
            }),
            args.onComplete
          )
      },
      {
        id: 'issue_and_charge',
        label: 'Issue and charge',
        intent: 'primary',
        hotkey: 'G',
        handleAction: async () =>
          await runAction(
            setStatementStatus({
              status: StatementStatus.Issued,
              label: 'Issued',
              ...sharedActionArgs
            }),
            args.onComplete
          )
      }
    ];
  };
};
