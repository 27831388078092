import { Field } from '@rexlabs/form';
import { Column, Grid } from '@rexlabs/grid';
import { TextInput } from '@rexlabs/text-input';
import React from 'react';
import { BlockConfig } from 'view/components/record-screen/types';
import { Value } from 'view/components/values';
import { UserFormData } from 'src/modules/users/mappers/map-user-to-form-data';
import config from 'src/config';

const validate = {
  definitions: {
    email: { rules: 'required' },
    given_name: { rules: 'required' },
    family_name: { rules: 'required' }
  }
};

export const userDetailsBlock: BlockConfig<UserFormData> = {
  id: 'user-details',
  title: 'Details',
  validate,
  isEditable: (data) => {
    if (data?.status?.id !== 'accepted') {
      return true;
    } else {
      return !config.AUTHENTICATION_SERVICE_ENABLE_INTEGRATION;
    }
  },
  View: ({ values }) => {
    return (
      <Grid>
        <Column width={12}>
          <Value label='Email' value={values?.email} />
        </Column>
        <Column width={6}>
          <Value label='First name' value={values?.given_name} />
        </Column>
        <Column width={6}>
          <Value label='Last name' value={values?.family_name} />
        </Column>
      </Grid>
    );
  },
  Edit: () => (
    <Grid>
      <Column width={12}>
        <Field
          label='Email'
          name='email'
          Input={TextInput}
          inputProps={{ type: 'email' }}
        />
      </Column>
      <Column width={6}>
        <Field label='First name' name='given_name' Input={TextInput} />
      </Column>
      <Column width={6}>
        <Field label='Last name' name='family_name' Input={TextInput} />
      </Column>
    </Grid>
  )
};
