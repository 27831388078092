import React from 'react';
import { useDialog } from '@rexlabs/dialog';

import { BlockConfig } from 'view/components/record-screen/types';
import { ActionButtons } from 'view/components/@luna/action-buttons';

import { SupplierComplianceRequirementsSettingsTable } from '../components/supplier-compliance-requirement-settings-table';
import { CreateSupplierComplianceRequirementDialog } from '../dialogs/create-supplier-compliance-requirement-dialog';

export const supplierComplianceRequirementsSettingsBlock: BlockConfig = {
  id: 'supplier-compliance-requirements-settings',
  title: 'Compliance requirements',
  View: SupplierComplianceRequirementsSettingsTable,
  Actions: () => {
    const { open: openCreateSupplierComplianceRequirementDialog } = useDialog(
      CreateSupplierComplianceRequirementDialog
    );

    return (
      <ActionButtons
        actions={[
          {
            label: 'Add new',
            handleAction: openCreateSupplierComplianceRequirementDialog
          }
        ]}
      />
    );
  }
};
