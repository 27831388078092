import { buildQueryString, push, useWhereabouts } from '@rexlabs/whereabouts';
import { getResetTabbedTableActiveTabHashQuery } from '../utils/get-reset-tabbed-table-active-tab-hash-query';
import { getResetTablesPageNumberHashQuery } from '../utils/get-reset-tables-page-number-hash-query';

type GetResetTableUrlProps = {
  // The hashParamKey of the tabbed table - used to reset the active tab
  resetActiveTabTableKeys?: string[];
  // the hashParamKeys of the tables - used to reset the page number
  // This is the hashParamKey of a single table, or the ids of tabbed table tabs
  resetPageTableKeys?: string[];
  // NOTE FOR FUTURE DEV: The intention here is follow the above pattern of passing in the keys for the
  // param you want to reset. eg:
  // resetFilterTableKeys?: string[];
  // resetSortTableKeys?: string[];
  // This is just a suggestion though, so feel free to extend how you see fit
};

/**
 * This hook returns a function, that when called, clears the pagination params and/or the active tab param from the current url.
 *
 * The pagination toolbar in the table relies on reading and modifying params
 * in the url state to determine which page to show in the table and when the pagination
 * buttons should be enabled/disabled. The active tab in the tabbed table also relies on
 * reading and modifying params in the url state to determine the current active tab.
 *
 * In certain cases you might not want to persist these params in the current url.
 * For example when opening a table from inside a dialog you may want to
 * clear the param after the dialog is closed so that the next time it is opened the
 * pagination and active tab reset back to the first page and tab rather than
 * persisting the previous values.
 */
export function useGetResetTableUrlParams(): (
  props: GetResetTableUrlProps
) => void {
  const whereabouts = useWhereabouts();

  return ({ resetActiveTabTableKeys, resetPageTableKeys }) => {
    const updatedHashQuery = {
      ...whereabouts.hashQuery,
      ...(resetPageTableKeys?.length
        ? getResetTablesPageNumberHashQuery(resetPageTableKeys)
        : {}),
      ...(resetActiveTabTableKeys?.length
        ? getResetTabbedTableActiveTabHashQuery(resetActiveTabTableKeys)
        : {})
    };

    const updatedConfig = {
      ...whereabouts,
      hash: buildQueryString(updatedHashQuery),
      hashQuery: updatedHashQuery
    };

    push({ config: updatedConfig });
  };
}
