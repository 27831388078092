import React from 'react';
import dayjs from 'dayjs';

import { TextCell } from '@rexlabs/table';

import ROUTES from 'routes/app';

import { useFinancialPeriods } from 'src/modules/financials/hooks/use-financial-periods';
import { FinancialPeriodData } from 'src/modules/statements/types';

import { Card } from 'view/components/card';
import { Columns, RecordTable } from 'view/components/table';
import { ListScreen } from 'view/components/list-screen/list-screen';
import { LinkButtonCell } from 'view/components/cells/link-button-cell';

import { push } from '@rexlabs/whereabouts';
import { getFinancialYearId } from '../utils/get-financial-year-id';

const columns: Columns<FinancialPeriodData> = [
  {
    id: 'financial_year',
    Header: 'Financial year',
    Cell: (props) => {
      const {
        row: { original }
      } = props;
      return (
        <TextCell
          {...props}
          value={`FY ${dayjs(original.start_date).format('YYYY')}-${dayjs(
            original.end_date
          ).format('YY')}`}
        />
      );
    }
  },
  {
    id: 'start_date',
    Header: 'Year start',
    type: 'date'
  },
  {
    id: 'end_date',
    Header: 'Year end',
    type: 'date'
  },
  {
    Cell: ({ value }) => {
      const id = getFinancialYearId(value.start_date);

      return (
        <LinkButtonCell
          action={{
            label: 'View',
            handleAction: () =>
              push(ROUTES.HMRC_REPORTS_AND_SUBMISSIONS_DETAILS, {
                params: { id }
              })
          }}
        />
      );
    },
    id: 'view',
    width: 80,
    accessor: (item) => item
  }
];

function getRowLinkProps({ item }: { item: FinancialPeriodData }) {
  const id = getFinancialYearId(item.start_date);

  return {
    to: ROUTES.HMRC_REPORTS_AND_SUBMISSIONS_DETAILS,
    params: { id }
  };
}

export function HmrcReportsAndSubmissionsList() {
  const { data = [] } = useFinancialPeriods(true);
  const mappedData = data
    .map((item) => ({
      ...item,
      id: getFinancialYearId(item.start_date)
    }))
    .sort((a, b) => b.start_date.localeCompare(a.start_date));
  return (
    <ListScreen title='HMRC reports and submissions'>
      <Card>
        <RecordTable
          id='reports-and-submissions'
          columns={columns}
          items={mappedData}
          isLoading={data?.length === 0}
          getRowLinkProps={getRowLinkProps}
        />
      </Card>
    </ListScreen>
  );
}
