import { useMemo } from 'react';

import { getPayables } from 'utils/api/get-payables';
import { FinancialRecordType } from 'utils/financials/financial-objects';
import { SearchResultItem } from 'utils/api/get-search-results';
import { FLAGS } from 'utils/feature-flags';

import { useApi } from 'view/hooks/api/api';
import { useFeatureFlags } from 'view/components/@luna/feature-flags';
import { TenancyPrepaymentBucket } from 'src/modules/prepayments/types/tenancy-prepayment-bucket';

interface PayablesParams {
  folio: SearchResultItem | null;
  maxNumberOfPayables?: number;
  prepaymentBucket?: TenancyPrepaymentBucket | null;
}

export function usePayablesByFolio({
  folio,
  maxNumberOfPayables,
  prepaymentBucket
}: PayablesParams) {
  const { hasFeature } = useFeatureFlags();
  const { data: payables, isLoading: isPayablesLoading } = useApi(async () => {
    if (!folio) return;

    return getPayables({
      objectID: folio.id,
      objectType: folio.type.id as FinancialRecordType,
      prepaymentBucketId:
        folio.type.id === 'tenancy' ? prepaymentBucket?.bucket_id : undefined,
      prepaymentBucketPropertyId:
        folio.type.id === 'tenancy'
          ? prepaymentBucket?.property?.id
          : undefined,
      perPage: maxNumberOfPayables
    });
    // coalescing null and undefined below since there's no meaningful difference and otherwise we get multiple requests
  }, [
    folio?.id,
    prepaymentBucket?.bucket_id ?? null,
    prepaymentBucket?.property?.id
  ]);

  const filteredInvoices = useMemo(
    () =>
      payables?.filter((payable) => payable.invoice.type.id !== 'managed_rent'),
    [payables]
  );

  return {
    isPayablesLoading,
    payables: hasFeature(FLAGS.RENT_LINE_ITEM) ? filteredInvoices : payables
  };
}
