import { makeIconComponent } from '@rexlabs/icons';

import HouseMoveOutMediumSvg from 'src/assets/icons/m/house-move-out.svg';
import HouseMoveOutLargeSvg from 'src/assets/icons/l/house-move-out.svg';
import HouseMoveOutExtraLargeSvg from 'src/assets/icons/xl/house-move-out.svg';

export const MoveOutIcon = makeIconComponent({
  m: HouseMoveOutMediumSvg,
  l: HouseMoveOutLargeSvg,
  xl: HouseMoveOutExtraLargeSvg
});

export default MoveOutIcon;
