import React from 'react';
import ActionMenu from '@rexlabs/action-menu';
import { GhostButton, PrimarySplitButton } from '@rexlabs/button';

import { StandardDialogFooter } from 'view/components/dialogs/components/standard-dialog-footer';
import ChevronDownIcon from 'view/components/icons/chevron-down';
import { ButtonGroupProps } from 'view/components/record-screen/dialog/types';

export function ComplianceSettingsDialogButtonGroup({
  onClose,
  handleSubmit,
  resetAll,
  isSubmitting
}: ButtonGroupProps) {
  async function handleSubmitAndClose() {
    const data = await handleSubmit();

    if (!data) return;

    onClose?.();
  }

  async function handleSubmitAndAddAnother() {
    const data = await handleSubmit();

    if (!data) return;

    resetAll();
  }

  return (
    <StandardDialogFooter>
      <GhostButton onClick={onClose}>Cancel</GhostButton>
      <PrimarySplitButton
        Left={({ Button }) => (
          <Button
            data-testid='dialog-submit-button'
            type='submit'
            isLoading={isSubmitting}
            onClick={handleSubmitAndClose}
          >
            Create and close
          </Button>
        )}
        Right={({ IconButton }) => (
          <ActionMenu
            placement='bottom-end'
            items={[
              {
                label: 'Create and add another',
                onClick: handleSubmitAndAddAnother
              }
            ]}
            Button={React.forwardRef((props, ref) => (
              <IconButton {...props} ref={ref} Icon={ChevronDownIcon} />
            ))}
          />
        )}
      />
    </StandardDialogFooter>
  );
}
