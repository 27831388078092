import React, { useEffect } from 'react';
import { Grid, Column } from 'view/components/@luna/form/grid/grid';
import { BlockConfig } from 'view/components/record-screen/types';
import { Field } from '@rexlabs/form';
import { DateInput } from 'view/components/@luna/inputs/date-input/date-input';
import dayjs from 'dayjs';
import { Message } from 'view/components/@luna/message';
import EmptyTrustJournalEntriesIllustration from 'assets/illustrations/empty-trust-journal-entries-table.svg';
import { formatDate } from 'utils/dates/format';
import { StatementType } from 'src/modules/statements/types';
import { RadioGroupInput } from '@rexlabs/radio-input';
import {
  FinancialPeriodSelect,
  useDefaultFinancialPeriod
} from 'view/components/inputs/selects/v4-selects/financial-period-select';
import { RecordTypes } from 'data/models/types';
import { Trans } from 'react-i18next';
import { InfoBanner } from '@rexlabs/notifications';
import InfoCircleIcon from 'view/components/icons/info';

export const statementDetailsBlock: BlockConfig = {
  id: 'statementDetails',
  validate: {
    definitions: {
      statement_type: { name: 'statement type', rules: 'required' },
      statement_end_date: {
        name: 'statement end date',
        rules: `required_if:statement_type,${StatementType.PeriodicOwnership}|required_if:statement_type,${StatementType.PeriodicAgency}|endDateLessThanOrEqualToStartDate:statement_start_date`
      },
      statement_start_date: {
        name: 'statement start date',
        rules: `required_if:statement_type,${StatementType.PeriodicOwnership}|required_if:statement_type,${StatementType.PeriodicAgency}|startDateLessThanOrEqualToEndDate:statement_end_date`
      },
      financial_year: {
        name: 'financial year',
        rules: `required_if:statement_type,${StatementType.YearlyOwnership}|required_if:statement_type,${StatementType.YearlyAgency}`
      }
    },
    messages: {
      required_if: 'The :attribute field is required.'
    }
  },
  Edit: ({ blockProps, values, setFieldValue }) => {
    const defaultFinancialPeriod = useDefaultFinancialPeriod();

    const isOutsideRange = (momentDate: moment.Moment) => {
      const date = dayjs(momentDate.toDate());

      return !(
        date.isAfter(
          dayjs(blockProps?.earliestStatementDate).subtract(1, 'day'),
          'day'
        ) && date.isBefore(dayjs(), 'day')
      );
    };

    const canIssue =
      blockProps?.earliestStatementDate &&
      dayjs(blockProps?.earliestStatementDate).isBefore(dayjs(), 'day');

    const recordType = blockProps?.recordType;

    /**
     * This is here so we can set a default value for the financial year
     */
    useEffect(() => {
      if (defaultFinancialPeriod && values?.financial_year === null) {
        setFieldValue?.('financial_year', defaultFinancialPeriod);
      }
    }, [defaultFinancialPeriod, setFieldValue, values]);

    return (
      <>
        {canIssue ? (
          <Grid columns={2}>
            <Column width={2}>
              <Field
                name='statement_type'
                label='Statement type'
                Input={RadioGroupInput}
                inputProps={{
                  orientation: 'horizontal',
                  options: [
                    {
                      value:
                        recordType === RecordTypes.Ownership
                          ? StatementType.PeriodicOwnership
                          : StatementType.PeriodicAgency,
                      label: 'Periodic'
                    },
                    {
                      value:
                        recordType === RecordTypes.Ownership
                          ? StatementType.YearlyOwnership
                          : StatementType.YearlyAgency,
                      label: 'Financial year'
                    }
                  ]
                }}
              />
            </Column>
            {(values?.statement_type === StatementType.PeriodicOwnership ||
              values?.statement_type === StatementType.PeriodicAgency) && (
              <>
                <Field
                  label='Statement start date'
                  name='statement_start_date'
                  Input={DateInput}
                  inputProps={{ isOutsideRange }}
                  optional={false}
                />
                <Field
                  label='Statement end date'
                  name='statement_end_date'
                  Input={DateInput}
                  inputProps={{ isOutsideRange }}
                  optional={false}
                />
              </>
            )}
            {(values?.statement_type === StatementType.YearlyOwnership ||
              values?.statement_type === StatementType.YearlyAgency) &&
              (defaultFinancialPeriod ? (
                <Field
                  id='financial_year'
                  label='Financial year'
                  name='financial_year'
                  Input={FinancialPeriodSelect}
                  optional={false}
                />
              ) : (
                <Column width={2}>
                  <InfoBanner
                    data-testid='can-not-issue-financial-year-statement-info-banner'
                    Icon={InfoCircleIcon}
                    description='The earliest financial transactions in your account occurred in the current financial year. The data needed to issue End of Financial Year Statements will be available the day after the financial year closes.'
                  ></InfoBanner>
                </Column>
              ))}
          </Grid>
        ) : (
          <Message
            Illustration={EmptyTrustJournalEntriesIllustration}
            title='A income statement cannot be issued'
          >
            <Trans
              i18nKey='statements.income-statement-cannot-be-issued'
              values={{
                recordType:
                  recordType === RecordTypes.Ownership ? 'ownership' : 'agency',
                date: formatDate(dayjs())
              }}
            >
              An income statement cannot be issued for this{' '}
              {recordType === RecordTypes.Ownership ? 'ownership' : 'agency'} as
              there is no financial activity or active management agreements
              before {formatDate(dayjs())}
            </Trans>
          </Message>
        )}
      </>
    );
  }
};
