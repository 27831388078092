import React, { useCallback, useMemo } from 'react';

import { InputProps } from '@rexlabs/form';
import { Select, useItems } from '@rexlabs/select';
import { Quri } from '@rexlabs/quri';

import { api } from 'utils/api/api-client';

import { Task } from 'src/modules/tasks/common/types/Task';
import {
  getSearchQuery,
  useRecordsQuery
} from 'src/lib/react-query/hooks/use-records-query';

type TaskSelectProps = {
  filter?: Quri[];
  includes?: string[];
  getSuggestedItems?: () => Promise<Task<any>[]>;
  groupByTaskType?: boolean;
};

function useGetRecordsFromSearch(filter, includes, getSuggestedItems) {
  const searchQuery = useMemo(() => getSearchQuery({ filter, includes }), [
    filter
  ]);

  const getItems: (term: string) => Promise<Task<any>[]> = React.useCallback(
    async (term) => {
      const tasks = await api.get(
        `/tasks${searchQuery ? `?${searchQuery}` : ''}`,
        {
          search: term,
          per_page: 30
        }
      );

      return tasks.data;
    },
    [searchQuery]
  );

  const { getSelectProps } = useItems({
    getItems: getItems,
    getSuggestedItems: getSuggestedItems
  });

  return getSelectProps;
}

function useGetSelectProps({
  filter,
  includes,
  getSuggestedItems
}: TaskSelectProps) {
  const { data: suggestedItems } = useRecordsQuery<Task<any>>([
    'tasks',
    {
      filter,
      includes
    }
  ]);

  const getSelectProps = useGetRecordsFromSearch(
    filter,
    includes,
    useCallback(
      () => (getSuggestedItems ? getSuggestedItems?.() : suggestedItems),
      [getSuggestedItems, suggestedItems]
    )
  );

  return getSelectProps;
}

function normaliser(item: Task<any>, groupByTaskType: boolean | undefined) {
  return {
    id: item.id,
    label: item.summary,
    ...(groupByTaskType
      ? {
          type: item.type.id,
          heading: item.type.label
        }
      : {})
  };
}

export function TaskSelect({
  filter = [],
  includes = [],
  getSuggestedItems,
  groupByTaskType,
  ...props
}: TaskSelectProps & InputProps) {
  const getTaskSelectItems = useGetSelectProps({
    filter,
    includes,
    getSuggestedItems
  });

  return (
    <Select
      normaliser={useCallback((item) => normaliser(item, groupByTaskType), [
        groupByTaskType
      ])}
      {...getTaskSelectItems()}
      {...props}
    />
  );
}
