import React from 'react';
import { useStyles, StyleSheet } from '@rexlabs/styling';
import {
  TitleBlock,
  TitleBlockProps
} from 'view/components/cards/title/title-block';
import { RecordObject, RecordTypes } from 'data/models/types';
import { getRecordTitle } from 'utils/records/get-record-title';
import { TagConfig } from 'view/components/@luna/title-block';
import { ActionDeclaration } from 'src/modules/common/actions/types/action-declaration-types';
import { useGetPrimaryRecordActionGroup } from 'view/hooks/actions/use-get-primary-record-action-group';
import { formatDate } from 'utils/dates/format';
import { lowerCase } from 'lodash';
import { useComplianceStatusTag } from 'src/modules/tasks/common/hooks/use-compliance-status-tag';
import ComplianceIcon from 'view/components/icons/compliance';
import { useGetAddDocumentsAction } from 'src/modules/documents/actions/use-get-add-documents-action';
import { useGetAddNotesAction } from 'src/modules/tasks/common/actions/get-add-notes-action';
import { useGetCreateEmailAction } from 'src/modules/communications/messages/hooks/action-declarations/use-get-create-email-action';
import ContactIcon from 'view/components/icons/contact';
import { useGetToggleClosedAction } from 'src/modules/tasks/common/hooks/action-declarations/use-get-toggle-closed-action';
import { useGetRenewEntryAction } from 'src/modules/tasks/supplier-compliance/hooks/action-menu-items/use-get-renew-entry-action';
import { useGetCreateSMSAction } from 'src/modules/communications/messages/hooks/action-declarations/use-get-create-sms-action';
import { contactsModel } from 'src/modules/contacts/models/contacts';
import { SupplierComplianceTask } from '../types/SupplierComplianceTask';

const type = RecordTypes.TaskSupplierCompliance;

type SupplierComplianceRecordObject = Extract<
  RecordObject,
  { type: 'task_supplier_compliance' }
>;

type SupplierComplianceTitleBlockProps = {
  task?: SupplierComplianceTask;
  onStatusChange?: () => void;
};

const styles = StyleSheet({
  subText: {
    color: ({ token }) => token('color.textStyle.body.default'),
    fontWeight: ({ token }) => token('typography.weight.semibold')
  }
});

export function SupplierComplianceTitleBlock({
  task
}: SupplierComplianceTitleBlockProps) {
  const s = useStyles(styles);

  const getPrimaryRecordActionGroup = useGetPrimaryRecordActionGroup();
  const getAddDocumentsAction = useGetAddDocumentsAction();
  const getAddNotesAction = useGetAddNotesAction();
  const getCreateEmailAction = useGetCreateEmailAction(contactsModel);
  const getCreateSMSAction = useGetCreateSMSAction(contactsModel);
  const getRenewEntryAction = useGetRenewEntryAction();
  const getToggleClosedAction = useGetToggleClosedAction();

  const statusTag: TagConfig = useComplianceStatusTag(task);

  const title = getRecordTitle({
    type,
    object: task
  } as SupplierComplianceRecordObject);

  if (!task) {
    return null;
  }

  const actions = [
    ...getPrimaryRecordActionGroup('task', task.id),

    {
      label: 'Actions',
      actions: [
        getAddDocumentsAction({ data: task }),
        getAddNotesAction({ data: task }),
        getCreateEmailAction(task.details?.supplier),
        getCreateSMSAction(task.details?.supplier),
        getToggleClosedAction(task)
      ]
    },
    { ...getRenewEntryAction(task), intent: 'primary' }
  ];

  const closed_at = task.closed_at;

  const subTitles: TitleBlockProps['subTitles'] = [
    {
      Icon: ContactIcon,
      label:
        task?.details?.supplier?.display_name ||
        task?.details?.compliance_entry?.supplier?.display_name
    }
  ];

  return (
    <TitleBlock
      title={title}
      subTitles={subTitles}
      type={'Supplier Compliance'}
      actions={actions as ActionDeclaration[]}
      tags={closed_at ? undefined : [statusTag]}
      Icon={() => <ComplianceIcon size='xl' />}
      subText={
        closed_at ? (
          <div>
            <span>This task was closed with the status </span>
            <span {...s('subText')}>
              {lowerCase(task.status.label)}
            </span> on <span {...s('subText')}>{formatDate(closed_at)}</span>
          </div>
        ) : undefined
      }
    />
  );
}
