import * as React from 'react';
import Box from '@rexlabs/box';
import { useToken } from '@rexlabs/styling';
import {
  StatusChangeActionArgs,
  useStatusChangeAction
} from 'src/modules/common/actions/status/use-status-change-action';
import { invokeActionDeclaration } from 'src/modules/common/actions/utils/invoke-action-declaration';
import { Task } from 'src/modules/tasks/common/types/Task';
import { useStatusDropdownRefresh } from 'src/modules/tasks/common/hooks/use-get-status-dropdown-refresh';

import WarningCircleIcon from 'view/components/icons/warning-circle';

import { ValueListValue } from 'data/models/types';

import { ValueListItem } from 'utils/normaliser/value-list-item';

import { useGetHasRoomsOrAreas } from 'src/modules/tasks/inspections/hooks/useGetHasRoomsOrAreas';
import { TaskStatusMenu } from '../components/task-status-menu';
import { tasksModel } from '../../common/models/tasks-model';
import { InspectionStatus } from '../types/InspectionStatus';
import { InspectionTask } from '../types/InspectionTask';

export function ProgressDropdown({
  value: task
}: {
  value?: Task<'task_inspection'>;
}) {
  const previousStatus = React.useRef(task?.details?.status as ValueListItem);
  const [value, setValue] = React.useState(
    task?.details?.status as ValueListItem
  );

  const getHasRoomsOrAreas = useGetHasRoomsOrAreas();
  const getStatusChangeAction = useStatusChangeAction(tasksModel);

  const token = useToken();

  const refreshListsOnComplete = useStatusDropdownRefresh();

  function getDialogOptions(
    newStatus: ValueListValue<InspectionStatus>,
    inspectionTask: InspectionTask
  ): StatusChangeActionArgs<typeof newStatus.id>['dialogOptions'] {
    return !getHasRoomsOrAreas(inspectionTask) &&
      ['report_completed', 'report_sent'].includes(newStatus.id)
      ? {
          type: 'confirmation',
          title: 'No report created',
          message: (
            <Box flexDirection='column' rowGap={token('spacing.l')}>
              <div>There is no report created for this inspection task.</div>
              <div>{`Do you still want to set the task progress to ${newStatus.label.toLocaleLowerCase()}?`}</div>
            </Box>
          ),
          afterAction: refreshListsOnComplete,
          TitleIcon: WarningCircleIcon,
          confirmText: 'Yes, change status',
          // HACK: This is a hack to get around the state of the dropdown - because the dropdown is showing
          // the new status we selected, if we cancel, we want to reset that value to the previous one.
          // We could make changes to the dropdown to call the await the on change, before setting the value,
          // but this will do for now.
          onCancel: () => setValue(previousStatus.current)
        }
      : {
          type: 'none',
          afterAction: refreshListsOnComplete
        };
  }

  const handleStatusChange = task
    ? (newStatus) => {
        invokeActionDeclaration<StatusChangeActionArgs<typeof newStatus.id>[]>(
          getStatusChangeAction,
          {
            record: task,
            status: newStatus.id,
            dialogOptions: getDialogOptions(newStatus, task),
            generalOptions: { avoidListRefresh: true },
            transformPayload: (status) => {
              return {
                type: { id: 'task_inspection' },
                details: {
                  status: {
                    id: status
                  }
                }
              };
            }
          }
        );
      }
    : undefined;

  const onChange = (status: ValueListItem) => {
    previousStatus.current = value;
    setValue(status);
    handleStatusChange?.(status);
  };

  return (
    <Box
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
      data-testid='inspection-status-tag'
    >
      <TaskStatusMenu value={value} onChange={onChange} />
    </Box>
  );
}
