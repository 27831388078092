import { useGetPrimaryRecordActionGroup } from 'view/hooks/actions/use-get-primary-record-action-group';
import { TrustJournalEntry } from 'src/modules/trust-journal-entries/types/trust-journal-entry-types';
import { ActionDeclaration } from 'src/modules/common/actions/types/action-declaration-types';
import { useModelDownloadAction } from 'src/modules/common/actions/reporting/use-model-download-action';
import { trustJournalEntryModel } from 'src/modules/trust-journal-entries/models/trust-journal-entries';
import { useModelActions } from '@rexlabs/model-generator';
import { useGetReverseAction } from '../utils/trust-journal-entry-action/use-get-reverse-action';
import { useGetViewReversalEntryAction } from '../utils/trust-journal-entry-action/use-get-view-reversal-entry-action';
import { useGetAddNewBankDepositAction } from '../utils/trust-journal-entry-action/use-get-add-new-bank-deposit-action';
import { useGetViewBankDepositAction } from '../utils/trust-journal-entry-action/use-get-view-bank-deposit-action';
import { useGetReconcileAction } from '../utils/trust-journal-entry-action/use-get-reconcile-action';
import { useGetUnreconcileAction } from '../utils/trust-journal-entry-action/use-get-unreconcile-action';
import { useGetWithdrawAction } from '../utils/trust-journal-entry-action/use-get-withdraw-action';
import { useGetViewBankWithdrawalAction } from '../utils/trust-journal-entry-action/use-get-view-bank-withdrawal-action';
import { useGetSendTrustJournalEntryReceiptAction } from '../utils/trust-journal-entry-action/use-get-send-trust-receipt-entry-receipt-action';

export function useTrustJournalEntryActions() {
  const getPrimaryRecordActionGroup = useGetPrimaryRecordActionGroup();
  const getReverseAction = useGetReverseAction();
  const getViewReversalEntryAction = useGetViewReversalEntryAction();
  const getAddNewBankDepositAction = useGetAddNewBankDepositAction();
  const getViewBankDeposit = useGetViewBankDepositAction();
  const getReconcileAction = useGetReconcileAction();
  const getUnreconcileAction = useGetUnreconcileAction();
  const getWithdrawAction = useGetWithdrawAction();
  const getViewBankWithdrawalAction = useGetViewBankWithdrawalAction();
  const getModelDownloadAction = useModelDownloadAction(trustJournalEntryModel);
  const { downloadItem } = useModelActions(trustJournalEntryModel);
  const getSendTrustJournalEntryReceiptAction = useGetSendTrustJournalEntryReceiptAction();

  return (trustJournalEntry?: TrustJournalEntry) => {
    if (!trustJournalEntry) return [];

    const statusActions: ActionDeclaration[] = [];
    switch (trustJournalEntry.status.id) {
      case 'active':
        statusActions.push(getReverseAction(trustJournalEntry));
        break;
      case 'reversed':
        statusActions.push(getViewReversalEntryAction(trustJournalEntry));
        break;
      case 'reversing_entry':
        break;
    }

    const downloadAction = getModelDownloadAction({
      fetcher: () => {
        return downloadItem(trustJournalEntry.id);
      },
      label: 'Download payment receipt',
      record: trustJournalEntry
    });
    const sendTrustJournalEntryReceiptAction = getSendTrustJournalEntryReceiptAction(
      trustJournalEntry
    );

    const isReceipt = trustJournalEntry.type.id === 'receipt';
    return [
      ...getPrimaryRecordActionGroup(
        'trust_journal_entry',
        trustJournalEntry.id
      ),
      ...statusActions,
      ...(isReceipt ? [downloadAction] : []),
      ...(sendTrustJournalEntryReceiptAction
        ? [sendTrustJournalEntryReceiptAction]
        : []),
      // TODO: All of these actions are WIP 👇
      getAddNewBankDepositAction(),
      getViewBankDeposit(),
      getReconcileAction(),
      getUnreconcileAction(),
      getWithdrawAction(),
      getViewBankWithdrawalAction()
    ];
  };
}
