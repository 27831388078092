import * as React from 'react';
import { ReactNode } from 'react';
import { DialogProps } from '@rexlabs/dialog';
import { RecordDialog } from 'view/components/record-screen/dialog/dialog';
import { RecordSubmitHandler } from 'view/components/record-screen/utils';
import { propertyAreaDetailsBlock } from 'src/modules/property-areas/property-areas/blocks/property-area-details-block';
import { propertyAreaItemsBlock } from 'src/modules/property-areas/property-areas/blocks/property-area-items-block';
import { PropertyAreaForm } from 'src/modules/property-areas/property-areas/types/PropertyAreaForm';
import { Forms } from '@rexlabs/form';
import { PropertyAreaType } from 'src/modules/property-areas/property-area-types/types/PropertyAreaType';

export interface PropertyAreaDialogProps extends DialogProps {
  title: ReactNode;
  initialValues: Partial<PropertyAreaForm>;
  onSubmit: RecordSubmitHandler<PropertyAreaForm>;
  isEditMode?: boolean;
  submitLabel?: string;
}

const content = [
  {
    id: 'property-area-type',
    label: 'Add property area type',
    blocks: [propertyAreaDetailsBlock, propertyAreaItemsBlock]
  }
];

const getHandlers = (forms?: Forms) => ({
  handleAreaTypeChange: (event) => {
    if (!forms) {
      return;
    }
    const areaType: PropertyAreaType | null = event.target.value;

    const { setFieldValue: setItemsFieldValue } = forms['property-area-items'];

    const items = areaType?.items?.data?.map((item) => ({
      name: item.name
    }));
    setItemsFieldValue('items', items || []);

    const { setFieldValue: setDetailsFieldValue } = forms['details'];
    if (!forms['details']?.values?.name) {
      setDetailsFieldValue('name', areaType ? areaType.name : null);
    }
  }
});

export function PropertyAreaRecordDialog({
  onClose,
  initialValues,
  onSubmit,
  isEditMode,
  title,
  submitLabel
}: PropertyAreaDialogProps) {
  return (
    <RecordDialog
      title={title}
      data={initialValues}
      blockProps={{
        isEditMode
      }}
      content={content}
      onClose={onClose}
      handleSubmit={onSubmit}
      submitLabel={submitLabel}
      size={'s'}
      getHandlers={getHandlers}
    />
  );
}
