import { PrepaymentBucketForm } from 'src/modules/prepayments/types/prepayment-bucket-form';
import { PrepaymentBucketRequest } from 'src/modules/prepayments/types/prepayment-bucket-request';

export function mapUpdatePrepaymentBucketFormToApi(
  values: Partial<PrepaymentBucketForm>
): Partial<PrepaymentBucketRequest> {
  return {
    ...values,
    chart_of_accounts_accounts: values.chart_of_accounts_accounts?.map?.(
      (chart_of_accounts_account) => ({ id: chart_of_accounts_account.id })
    )
  };
}
