import { reduce } from 'lodash';

export function getSelectedTemplatedAttachments(
  include_generated_attachments: Record<string, boolean>
): string[] {
  return reduce<typeof include_generated_attachments, string[]>(
    include_generated_attachments,
    (acc, val, key) => {
      return [...acc, ...(val ? [key] : [])];
    },
    []
  );
}
