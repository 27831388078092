import React from 'react';

import { Field } from '@rexlabs/form';

import { Grid } from 'view/components/@luna/form/grid';
import { BlockConfig } from 'view/components/record-screen/types';
import { TaskSelect } from 'view/components/inputs/selects/v4-selects/task-select';

import { InspectionTask } from '../types/InspectionTask';

export interface AddInspectionsBlockProps {
  existingInspectionIds: string[];
}

export interface AddInspectionsBlockFormValues {
  inspections?: InspectionTask[];
}

export const addInspectionsBlock: BlockConfig<
  any,
  AddInspectionsBlockProps,
  AddInspectionsBlockFormValues
> = {
  id: 'add-inspections',
  title: 'Add inspection(s)',
  validate: {
    definitions: {
      inspections: { rules: 'required' }
    }
  },
  Edit: ({ blockProps = {} }) => {
    const { existingInspectionIds = [] } = blockProps;

    return (
      <Grid columns={1}>
        <Field
          optional={false}
          id='inspections'
          name='inspections'
          label='Select inspection(s)'
          description="Showing inspections with the task progress 'Created'."
          Input={TaskSelect}
          inputProps={{
            multi: true,
            label: 'Send report to owners',
            filter: [
              {
                field: 'type_id',
                op: 'in',
                value: ['task_inspection']
              },
              {
                field: 'task_inspection_scheduled_at',
                op: 'eq',
                value: 'null'
              },
              {
                op: 'and',
                value: existingInspectionIds.map((id) => ({
                  field: 'id',
                  op: 'neq',
                  value: id,
                  logicalOperator: 'and'
                }))
              },
              {
                field: 'task_inspection_status_id',
                op: 'neq',
                value: 'scheduled'
              },
              {
                field: 'closed_at',
                op: 'eq',
                value: 'null'
              }
            ],
            includes: ['property', 'property.address', 'details']
          }}
        />
      </Grid>
    );
  }
};
