import React from 'react';

import Box from '@rexlabs/box';

import { BlockConfig } from 'view/components/record-screen/types';
import { Grid } from 'view/components/@luna/form/grid';
import { CurrencyValue } from 'view/components/values/currency';
import { Value } from 'view/components/values';
import { ImagePreview } from 'view/components/image-preview';
import { DateValue } from 'view/components/values/date';

import { QuoteTask } from '../types/QuoteTask';
import { ReceivedQuoteEmptyState } from '../components/received-quote-empty-state';
import { ReceivedQuoteDetailsForm } from '../components/received-quote-details-form';
import { CreateQuoteFormDetailsData } from '../types/CreateQuoteFormData';

export type ReceivedQuoteForm = {
  details: Pick<
    CreateQuoteFormDetailsData,
    | 'notes'
    | 'amount'
    | 'available_start_date'
    | 'quote_attachments'
    | 'supplier'
  >;
};

export const receivedQuoteBlock: BlockConfig<
  QuoteTask,
  any,
  ReceivedQuoteForm
> = {
  id: 'received-quote',
  title: 'Received quote',
  validate: {
    definitions: {
      'details.amount': {
        name: 'quoted price',
        rules: 'required'
      }
    }
  },
  Empty: ({ data }) => {
    return <ReceivedQuoteEmptyState quoteTask={data} />;
  },
  showEmpty: (data) => !data?.details?.has_received_quote,
  View: ({ data }) => {
    return (
      <Grid columns={1}>
        <Grid columns={2}>
          <CurrencyValue label='Quoted price' value={data?.details?.amount} />
          <Value label='Quote number' value={data?.details?.number} />
        </Grid>
        <Grid columns={1}>
          <Value label='Notes' value={data?.details?.notes} />
        </Grid>
        <Grid columns={1}>
          <DateValue
            label='Available start date'
            value={data?.details?.available_start_date}
          />
        </Grid>
        <Grid columns={1}>
          <Value
            label='Quote file'
            value={
              data?.details?.quote_attachments?.length ? (
                <Box spacing='.8rem' flexDirection='row' flexWrap='wrap'>
                  {data!.details!.quote_attachments!.map((image, index) => (
                    <ImagePreview
                      src={image?.file.url}
                      key={image?.file.id || index}
                      downloadUrl={image?.file.download_url}
                    />
                  ))}
                </Box>
              ) : null
            }
          />
        </Grid>
      </Grid>
    );
  },
  Edit: ReceivedQuoteDetailsForm
};
