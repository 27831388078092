import { useDialog } from '@rexlabs/dialog';
import { FieldArrayPassthroughProps } from '@rexlabs/form';
import { useFeatureFlags } from 'view/components/@luna/feature-flags';
import { FLAGS } from 'utils/feature-flags';
import { DeleteChecklistItemTriggerDialog } from '../../../dialogs/delete-checklist-item-trigger';
import { ChecklistTemplateItem } from '../../../types/ChecklistTemplate';

export function useDeleteTriggerAction({
  index,
  fieldArrayProps,
  checklistTemplateItem
}: {
  index: number;
  fieldArrayProps: FieldArrayPassthroughProps;
  checklistTemplateItem: ChecklistTemplateItem;
}) {
  const { open: openDeleteChecklistItemTriggerDialog } = useDialog(
    DeleteChecklistItemTriggerDialog
  );

  const handleSubmit = () => {
    return () => {
      const { replace } = fieldArrayProps;

      replace(index, {
        ...checklistTemplateItem,
        trigger_data: null,
        trigger_type: null,
        action_data: null,
        action_message_type: null,
        action_type: null
      });

      return true;
    };
  };

  const { hasFeature } = useFeatureFlags();

  return () => {
    return {
      label: hasFeature(FLAGS.SMART_CHECKLISTS_UI_OVERHAUL)
        ? 'Delete automation'
        : 'Delete triggered action',
      id: 'delete-trigger',
      intent: 'danger',
      handleAction: () => {
        openDeleteChecklistItemTriggerDialog({
          onDelete: handleSubmit()
        });
      }
    };
  };
}
