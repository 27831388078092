import { useGetGenericWipAction } from 'view/hooks/actions/use-get-generic-wip-action';

export function useGetEditAction() {
  const getGenericWipAction = useGetGenericWipAction();

  return () =>
    getGenericWipAction({
      id: 'edit-name',
      group: 'edit',
      actionName: 'Edit contact name'
    });
}
