import React from 'react';
import { Trans } from 'react-i18next';

import Box from '@rexlabs/box';
import Tooltip from '@rexlabs/tooltip';

import RenderLoading from 'view/components/@luna/render-loading';
import { BlockConfig } from 'view/components/record-screen/types';
import InfoCircleIcon from 'view/components/icons/info';

import { Ownership } from 'data/models/entities/ownerships';

import { TaskWithheldFundsTable } from '../components/task-withheld-funds-table';

type RelatedTaskWithheldFundsData = Ownership;

export const relatedTaskWithheldFundsBlock: BlockConfig<
  RelatedTaskWithheldFundsData,
  any,
  RelatedTaskWithheldFundsData
> = {
  id: 'task-withheld-funds',
  title: () => (
    <Trans i18nKey='withheld-funds.related-task-withheld-funds-block.title' />
  ),
  TitleTooltip: () => (
    <Tooltip
      Content={() => (
        <Trans
          i18nKey={'withheld-funds.related-task-withheld-funds-block.tooltip'}
        />
      )}
    >
      <Box flexDirection='row' alignItems='center' width={24} height={24}>
        <InfoCircleIcon
          size={'m'}
          data-testid='withheld-funds.related-task-withheld-funds-block.tooltip'
        />
      </Box>
    </Tooltip>
  ),
  View: ({ data }) => {
    return (
      <RenderLoading isLoading={!data}>
        {data ? <TaskWithheldFundsTable relatedRecord={data} /> : null}
      </RenderLoading>
    );
  }
};
