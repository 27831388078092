import { SingleActionDeclaration } from 'src/modules/common/actions/types/action-declaration-types';

import DownloadIcon from 'view/components/icons/download';

import { downloadFile } from 'utils/files';

import { InspectionTask } from '../../types/InspectionTask';

export function useGetDownloadReportAction(inspectionTask: InspectionTask) {
  return (): SingleActionDeclaration => {
    return {
      id: 'download-inspection-report',
      intent: 'primary',
      group: 'inspection',
      Icon: DownloadIcon,
      label: 'Download report',
      handleAction: () =>
        inspectionTask?.details?.inspection_report?.file.download_url &&
        downloadFile(
          inspectionTask.details.inspection_report?.file.download_url
        )
    };
  };
}
