import React from 'react';
import { Field } from '@rexlabs/form';
import { TextInput } from '@rexlabs/text-input';

import { BlockConfig } from 'view/components/record-screen/types';
import { Column, Grid } from 'view/components/@luna/form/grid';
import { Value } from 'view/components/values';
import { useTranslation } from 'react-i18next';

export const depositAuthorityDetailsBlock: BlockConfig = {
  id: 'deposit-authority-details',
  title: '[DEPOSIT AUTHORITY] Deposit authority details',
  validate: {
    definitions: {
      company_name: {
        rules: 'required_if:type.id,deposit_authority',
        name: 'deposit authority name'
      }
    },
    messages: {
      required_if: 'The :attribute field is required.'
    }
  },
  View: ({ data }) => {
    const { t } = useTranslation();
    return (
      <Grid columns={4}>
        <Column width={2}>
          <Value label='Deposit authority name' value={data?.company_name} />
        </Column>
        <Value
          label={t(
            'contacts.australian-business-number.label.acronym.singular'
          )}
          value={data?.abn}
        />
        <Value
          label={t('contacts.australian-company-number.label.acronym.singular')}
          value={data?.acn}
        />
      </Grid>
    );
  },
  Edit: () => {
    const { t } = useTranslation();
    return (
      <Grid columns={4}>
        <Column width={2}>
          <Field
            name='company_name'
            label='Deposit authority name'
            Input={TextInput}
            optional={false}
          />
        </Column>
        <Field
          name='abn'
          label={
            t(
              'contacts.australian-business-number.label.acronym.singular'
            ) as string
          }
          Input={TextInput}
        />
        <Field
          name='acn'
          label={
            t(
              'contacts.australian-company-number.label.acronym.singular'
            ) as string
          }
          Input={TextInput}
        />
      </Grid>
    );
  },
  workInProgress: true
};
