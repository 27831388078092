import { Generator } from 'data/models/generator';
import { auditableConfig } from 'src/modules/common/auditable/auditable-config';
import { usersModel } from 'src/modules/users/models/users';
import { api } from 'utils/api/api-client';
import _ from 'lodash';
import { MessageTemplate } from '../types/MessageTemplate';

const config = {
  entities: {
    related: {
      // TODO: configure includes here
      ...auditableConfig,
      channels: {
        include: 'channels'
      },
      send_from_author: {
        include: 'send_from_author',
        model: usersModel
      },
      inline_attachments: {
        include: 'inline_attachments'
      },
      document_types: {
        include: 'document_types'
      },
      compliance_types: {
        include: 'compliance_types'
      }
    }
  }
};

const actionCreators = {
  duplicateItem: {
    request: ({ messageTemplate }: { messageTemplate: MessageTemplate }) =>
      api.post(
        `/communication/message-templates/${messageTemplate.id}/duplicate`,
        {
          message_template: messageTemplate
        }
      ),
    reduce: _.identity
  }
};

export const messageTemplatesModel = new Generator<
  MessageTemplate,
  typeof actionCreators
>('communication/message-templates', config).createEntityModel({
  actionCreators
});
