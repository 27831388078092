import { get } from 'lodash';
import { TenancyPrepaymentBucket } from 'src/modules/prepayments/types/tenancy-prepayment-bucket';

export function getBucket(forms): TenancyPrepaymentBucket | null {
  const bucket = get(
    forms,
    'transfer-funds-details.values.prepayment_bucket'
  ) as TenancyPrepaymentBucket;

  return bucket;
}
