import * as React from 'react';
import { TaskStatusSelect } from '../../common/components/task-status-select';
import { workOrderStatusMap } from '../maps/work-order-status-map';

export function WorkOrderStatusSelect(props) {
  return (
    <TaskStatusSelect
      taskStatusMap={workOrderStatusMap}
      valueListId={'work_order_status'}
      {...props}
    />
  );
}
