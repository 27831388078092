import { ActionMenuItem } from '@rexlabs/action-menu';

import { transformActionDeclarationsToActionMenuItems } from 'utils/actions/transforms';

import { Message } from '../../types/Message';
import { useGetDeleteMessageAction } from '../action-declarations/use-get-delete-message-action';
import { useGetEditMessageAction } from '../action-declarations/use-get-edit-message-action';
import { useGetPreviewMessageAction } from '../action-declarations/use-get-preview-message-action';
import { useGetSendMessageAction } from '../action-declarations/use-get-send-message-action';

export function useGetMessageOutboxActions() {
  const getPreviewMessageAction = useGetPreviewMessageAction();
  const getEditMessageAction = useGetEditMessageAction();
  const getSendMessageAction = useGetSendMessageAction();
  const getDeleteMessageAction = useGetDeleteMessageAction();

  return (message?: Message): ActionMenuItem[] => {
    if (!message) return [];

    return [
      ...transformActionDeclarationsToActionMenuItems([
        getEditMessageAction(message),
        getPreviewMessageAction(message),
        getSendMessageAction(message),
        getDeleteMessageAction(message)
      ])
    ];
  };
}
