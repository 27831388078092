import { useBulkModelDownloadAction } from 'src/modules/common/actions/reporting/use-bulk-model-download-action';
import { useModelActions } from '@rexlabs/model-generator';
import { appliancesModel } from 'src/modules/appliances/models/appliances-model';
import { invokeActionDeclaration } from 'src/modules/common/actions/utils/invoke-action-declaration';

interface UseGetDownloadApplianceWarrantiesParams {
  applianceId?: string;
  warranties?: any[];
}

export function useGetDownloadApplianceWarranties({
  applianceId,
  warranties
}: UseGetDownloadApplianceWarrantiesParams) {
  const modelBulkDownloadAction = useBulkModelDownloadAction();
  const { bulkDownloadWarranties } = useModelActions(appliancesModel);

  if (!warranties?.length || !applianceId) {
    return;
  }
  return () =>
    invokeActionDeclaration(modelBulkDownloadAction, {
      fetcher: () => {
        return bulkDownloadWarranties({
          data: warranties?.map((doc) => {
            return { id: doc.id, type: doc.type };
          }),
          applianceId: applianceId
        });
      },
      label: 'Download all'
    });
}
