import { FileUpload } from 'src/modules/common/types/file';

/**
 * Get metadata for the provided files and return a promise.
 */
export const getUploadedFileMeta = async (files: any[]) => {
  if (!files.length) return [];

  const dataArrayPromises = files.map(async (file) => {
    if (file.data) {
      const request = await file.data;
      return { file: request.data };
    }

    return file;
  });

  return Promise.all<FileUpload>(dataArrayPromises);
};

export const parseUploadedFileMeta = (
  files: FileUpload[] = [],
  includePreview = true
) =>
  files?.map((fileObject) => ({
    ...fileObject,
    uuid: fileObject.id || fileObject.file.id,
    file: fileObject.file,
    data: Promise.resolve({ data: fileObject.file }),
    ...(includePreview && { preview: Promise.resolve(fileObject.file?.url) })
  })) ?? [];

export const downloadFile = (url: string) => {
  const iframe = window.document.createElement('iframe');
  iframe.style.display = 'none';
  // TODO: we should look into sandboxing the iframe to prevent it from running anything
  // dodgy, but just sandboxing everything prevents the download from triggering, so need
  // to find out what we can sandbox
  // https://developer.mozilla.org/en-US/docs/Web/HTML/Element/iframe#attr-sandbox
  iframe.src = url;

  window.document.body.appendChild(iframe);
  // NOTE: we want to clean up the DOM node, but we need to make sure that the download
  // triggered before we do so, unfortunately there are no events fired from the iframe
  // for this, the 10s here are just arbitrary to cover the worst case scenario
  setTimeout(() => {
    window.document.body.removeChild(iframe);
  }, 10000);
};
