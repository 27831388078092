import { useDialog } from '@rexlabs/dialog';

import { SingleActionDeclaration } from 'src/modules/common/actions/types/action-declaration-types';
import { Property } from 'src/modules/properties/types/property-types';

import { AddKeySetDialog } from '../dialogs/add-key-set-dialog';

import { useAddKeySetSubmitHandler } from './use-add-key-set-submit-handler';

export function useAddKeySetAction(
  property: Property
): Required<
  Pick<SingleActionDeclaration, 'id' | 'label' | 'handleAction' | 'intent'>
> {
  const { open: openAddKeySetDialog } = useDialog(AddKeySetDialog);

  const { onHandleSubmit } = useAddKeySetSubmitHandler(property.id);

  return {
    id: 'add-key-set',
    label: 'Get started',
    intent: 'primary',
    handleAction: () => openAddKeySetDialog({ handleSubmit: onHandleSubmit })
  };
}
