import React from 'react';
import { CellProps } from '@rexlabs/table';
import { TwoTierCell } from 'view/components/cells/two-tier-cell';
import { User } from '../types/user-types';

export function NameAndEmailCell({ value }: CellProps<unknown, User>) {
  return (
    <TwoTierCell
      text={value?.display_name}
      subtext={value?.contact?.primary_email?.email_address || '--'}
    />
  );
}
