import React from 'react';
import { BlockConfig } from 'view/components/record-screen/types';
import { TrustJournalEntry } from 'src/modules/trust-journal-entries/types/trust-journal-entry-types';
import { Grid } from 'view/components/@luna/form/grid';
import { Value } from 'view/components/values';
import { DateValue } from 'view/components/values/date';
import { RecordLinkValue } from 'view/components/values/record-link';
import { SplitColumn } from 'view/components/@luna/form/grid/split-column';
import { CurrencyValue } from 'view/components/values/currency';
import { useTranslation } from 'react-i18next';
import { FullRecordLink } from 'view/components/record-link/full-record-link';

export const detailsBlock: BlockConfig<TrustJournalEntry> = {
  id: 'details',
  title: 'Details',
  View: ({ data }) => {
    const { t } = useTranslation();

    const tje = data as TrustJournalEntry;

    const paymentMethodRow = () => {
      return (
        <>
          <SplitColumn>
            <Value
              label='Payment Method'
              value={tje.payment_information?.method?.label}
            />
            {tje.payment_information?.drawer && (
              <RecordLinkValue
                label={'Drawer'}
                value={tje.payment_information.drawer}
              />
            )}
            {tje.payment_information?.reference && (
              <Value
                label={'Reference'}
                value={tje.payment_information?.reference}
              />
            )}
          </SplitColumn>

          <SplitColumn>
            {tje.payment_information?.account_number && (
              <Value
                label={'Account number'}
                value={tje.payment_information?.account_number}
              />
            )}
            {tje.payment_information?.issuer && (
              <Value label={'Issuer'} value={tje.payment_information?.issuer} />
            )}
            {tje.payment_information?.branch_code && (
              <Value
                label={t('bank-accounts.branch-code.label.singular')}
                value={tje.payment_information?.branch_code}
              />
            )}
          </SplitColumn>
        </>
      );
    };

    const transferFields = () => {
      return (
        <>
          <RecordLinkValue
            label={'Transfer source'}
            record={{
              type: 'trust_ledger',
              object: tje.transfer_source?.trust_ledger
            }}
          />
          <CurrencyValue label='Amount' value={tje.amount} />
        </>
      );
    };

    const receiptFields = () => {
      const contacts = data?.contacts?.data;

      return (
        <>
          <Value
            label={'Receipted from'}
            value={
              <>
                {contacts?.map((contact, index) => (
                  <>
                    <FullRecordLink
                      key={contact.id}
                      recordObject={{
                        type: 'contact',
                        object: contact
                      }}
                    />
                    {index < contacts.length - 1 && ' '}
                  </>
                ))}
              </>
            }
          />

          <CurrencyValue label='Amount' value={tje.amount} />
          {paymentMethodRow()}
          <Value label='Receipt #' value={tje.receipt_number} />
        </>
      );
    };

    const withdrawalFields = () => {
      return (
        <>
          <SplitColumn>
            <RecordLinkValue
              label={'Withdrawn from'}
              record={{
                type: 'trust_ledger',
                object: tje.withdrawal_source?.trust_ledger
              }}
            />
            <RecordLinkValue
              label='Paid to'
              record={{
                type: 'contact',
                object: data?.contact
              }}
            />
          </SplitColumn>
          <CurrencyValue label='Amount' value={tje.amount} />
          {paymentMethodRow()}
        </>
      );
    };

    return (
      <Grid columns={2}>
        <Value label='Bank Account' value={tje.bank_account?.name} />
        <DateValue label='Date' value={tje.date_of} />
        {(() => {
          switch (tje.type.id) {
            case 'receipt':
            case 'reverse_receipt':
              return receiptFields();
            case 'withdrawal':
              return withdrawalFields();
            case 'transfer':
              return transferFields();
          }
        })()}
        {
          // If there is a created by silo user, link to it,
          // otherwise, show the name for the universal user.
        }
        {tje.created_by.user ? (
          <RecordLinkValue
            label='Created by'
            record={{
              type: 'user',
              object: tje.created_by.user
            }}
          />
        ) : (
          <Value label='Created by' value={tje.created_by.name} />
        )}
        <Value label={'Audit #'} value={tje.audit_number} />
      </Grid>
    );
  }
};
