import { useModelActions } from '@rexlabs/model-generator';

import { api } from 'utils/api/api-client';
import { getUploadedFileMeta } from 'utils/files';

import { RecordSubmitHandler } from 'view/components/record-screen/utils';
import {
  sharedIncludes,
  taskIncludes,
  tasksModel
} from '../../common/models/tasks-model';

/**
 * @deprecated we are replacing rooms with areas
 */
export function useGetUpdateRoomSubmitHandler(
  inspectionId: string
): RecordSubmitHandler {
  const actions = useModelActions(tasksModel);

  const endpoint = 'rooms';

  return async ({ changedValues, values }) => {
    const roomId = Object.keys(changedValues.rooms)[0];
    const room = {
      ...values.rooms[roomId],
      ...changedValues.rooms[roomId]
    };

    if ('images' in room) {
      const { images = [] } = room;

      room.images = await getUploadedFileMeta(images);
    }

    const payload = {
      room_condition: {
        id: room.condition.id
      },
      notes: room.comments,
      images: room.images
    };

    await api.patch(`/${endpoint}/${roomId}`, payload, {
      params: {
        include: ['images'].join(',')
      }
    });

    await actions.refreshItem({
      id: inspectionId,
      args: {
        include: [
          ...sharedIncludes,
          taskIncludes.inspectionDetails,
          'property.active_property_ownership.ownership.owners.contact'
        ].join(',')
      }
    });
  };
}
