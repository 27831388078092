import { EntityModel } from '@rexlabs/model-generator';
import EmptyTable from 'assets/illustrations/empty-table.svg';
import { Ownership } from 'data/models/entities/ownerships';
import { Tenancy } from 'data/models/entities/tenancies';
import { RecordType } from 'data/models/types';
import * as React from 'react';
import { Contact } from 'src/modules/contacts/types/contact-types';
import { Property } from 'src/modules/properties/types/property-types';
import { Message } from 'view/components/@luna/message';
import { EmptyCard } from 'view/components/record-screen/cards/empty-card';
import { Task } from 'src/modules/tasks/common/types/Task';
import { TaskType } from 'src/modules/tasks/common/types/TaskType';
import { useGetCreateLetterAction } from '../../hooks/action-declarations/use-get-create-letter-action';

export function useLetterTableEmptyState(model?: EntityModel<RecordType>) {
  const getCreateLetterAction = useGetCreateLetterAction(model);

  return ({
    label,
    record
  }: {
    label: 'Outbox' | 'Processing' | 'Bounced' | 'Sent';
    record?: Contact | Property | Tenancy | Ownership | Task<TaskType>;
  }) => (
    <EmptyCard>
      <Message
        title={`${label} letters`}
        Illustration={EmptyTable}
        actions={[getCreateLetterAction(record)]}
      >{`There are currently no ${label.toLowerCase()} letters${
        record?.__record_type ? ` for this ${record.__record_type}` : ``
      }`}</Message>
    </EmptyCard>
  );
}
