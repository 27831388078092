import { NormalisedItem, Select } from '@rexlabs/select';
import * as React from 'react';
import { PropertyAreaType } from 'src/modules/property-areas/property-area-types/types/PropertyAreaType';
import { useAllPropertyAreaTypes } from 'src/modules/property-areas/property-area-types/hooks/use-all-property-area-types';

const normaliser = (item: PropertyAreaType): NormalisedItem => ({
  id: item.id,
  label: item.name
});

export function PropertyAreaTypeSelect(props) {
  const data = useAllPropertyAreaTypes();
  return (
    <Select
      normaliser={normaliser}
      deselectable
      items={data || []}
      {...props}
    />
  );
}
