import { Generator } from 'data/models/generator';
import { Property } from 'src/modules/properties/types/property-types';
import { ValueListValue, Payable, BaseFields, RecordTypes } from '../../types';

export type RecurringChargeEntry = BaseFields<
  typeof RecordTypes.RecurringChargeEntry
> & {
  next_charge_date: string | null;
  next_due_date: string;
  fixed_end_date: string | null;
  charge_frequency_unit: ValueListValue<string>;
  days_in_advance_to_post: number;
  description: string;
  is_tax_included: boolean;
  amount: number;
  amount_inc_tax: number;
  amount_exc_tax: number;
  amount_tax: number;
  payable_to: Payable;
  payable_by: Payable;
  property: Property | null;
  tax_type: ValueListValue<string>;
};

const config = {
  entities: {
    related: {
      property: {
        include: 'property'
      },
      tax_type: {
        include: 'tax_type'
      }
    }
  }
};

export const financialsRecurringChargeEntriesModel = new Generator<RecurringChargeEntry>(
  'financials/recurring-charge-entries',
  config
).createEntityModel();
