import React from 'react';

import { CellProps } from '@rexlabs/table';
import { useDialog } from '@rexlabs/dialog';

import { LinkButtonCell } from 'view/components/cells/link-button-cell';

import { AuditLogDetailsDialog } from '../dialogs/audit-log-details-dialog';
import { AuditLog } from '../types/AuditLog';

export function ViewDetailsCell(props: CellProps<unknown, AuditLog>) {
  const { open: openAuditLogDetailsDialog } = useDialog(AuditLogDetailsDialog);

  return (
    <LinkButtonCell
      action={{
        label: 'View details',
        handleAction: () =>
          openAuditLogDetailsDialog({ auditLogId: props.row.values.id })
      }}
    />
  );
}
