import { TOKENS as DEFAULTS } from '@rexlabs/theme-luna';
import { merge } from 'lodash';

import ActionMenuIcon from 'view/components/icons/action-menu';

export const TOKENS = merge(
  // Default luna theme
  DEFAULTS,

  // Alfred overrides
  // Some of these will eventually be replaced.
  {
    field: {
      maxWidth: '100%',
      margin: {
        // TODO: properly deal with spacing in vivid
        top: '0 !important',
        bottom: 0
      }
    },

    button: {
      group: {
        spacing: '1.2rem'
      }
    },

    actionMenu: {
      button: {
        icon: {
          svg: () => ActionMenuIcon
        }
      }
    },

    toolip: {
      delay: 200
    },

    dialog: {
      width: '96rem'
    },

    color: {
      pageBackground: {
        default: ({ token }) => token('palette.grey.200')
      },

      container: {
        static: {
          contrast: ({ token }) => token('palette.grey.200')
        }
      }
    }
  }
);
