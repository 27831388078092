import { useDialog } from '@rexlabs/dialog';

import { EditInspectionRunFinishDialog } from '../dialogs/edit-inspection-run-finish-dialog';
import { mapInspectionRunFinishFormToInspectionRun } from '../mappers/map-inspection-run-finish-form-to-inspection-run';
import { mapInspectionRunToEditInspectionRunFinishForm } from '../mappers/map-inspection-run-to-edit-inspection-run-finish-form';
import { useInspectionRunContext } from './use-inspection-run-context';

export function useGetEditInspectionRunFinish() {
  const { open: openEditInspectionRunFinishDialog } = useDialog(
    EditInspectionRunFinishDialog
  );

  const { inspectionRun, editInspectionRunDetails } = useInspectionRunContext();

  return () =>
    openEditInspectionRunFinishDialog({
      initialValues: mapInspectionRunToEditInspectionRunFinishForm(
        inspectionRun!
      ),
      submitHandler: ({ values }) => {
        const newInspectionRunDetails = mapInspectionRunFinishFormToInspectionRun(
          values
        );

        editInspectionRunDetails?.(newInspectionRunDetails);
        return newInspectionRunDetails;
      }
    });
}
