import React, { ComponentType } from 'react';
import EmailIcon from 'view/components/icons/email';
import { ActionMenuItem } from '@rexlabs/action-menu';
import TaskIcon from 'view/components/icons/task';
import NoteIcon from 'view/components/icons/note';
import FileIcon from 'view/components/icons/file';
import { ChecklistTemplatesItemForm } from '../types/ChecklistTemplatesForm';
import {
  ChecklistItemActionType,
  ChecklistTemplateItem as ChecklistTemplateItemType
} from '../types/ChecklistTemplate';
import { getTriggerHeader } from '../utils/get-trigger-header';
import { getTriggerDescription } from '../utils/get-trigger-description';
import { ChecklistTrigger } from './checklist-trigger';

export const iconMap: Record<ChecklistItemActionType, ComponentType<any>> = {
  send_email: EmailIcon,
  send_sms: EmailIcon,
  send_message: EmailIcon,
  close_task: TaskIcon,
  set_status: TaskIcon,
  create_note: NoteIcon,
  upload_document: FileIcon,
  add_linked_task: TaskIcon
};

export function ChecklistTemplateSubtaskTrigger({
  checklistTemplateItem,
  editMode,
  triggerActionMenuItems
}: {
  checklistTemplateItem: ChecklistTemplatesItemForm | ChecklistTemplateItemType;
  editMode: boolean;
  triggerActionMenuItems?: ActionMenuItem[];
}) {
  const editActionMenu = (
    <ChecklistTrigger.ActionMenu actionMenuItems={triggerActionMenuItems} />
  );

  const Icon =
    iconMap[checklistTemplateItem.action_type?.id as ChecklistItemActionType];

  return (
    <ChecklistTrigger>
      <ChecklistTrigger.Title>
        {getTriggerHeader(checklistTemplateItem)}
      </ChecklistTrigger.Title>
      <ChecklistTrigger.Trigger
        Icon={Icon}
        endSlot={editMode ? editActionMenu : undefined}
      >
        {getTriggerDescription(checklistTemplateItem)}
      </ChecklistTrigger.Trigger>
    </ChecklistTrigger>
  );
}
