import React, { useMemo } from 'react';
import dayjs from 'dayjs';

import Box from '@rexlabs/box';

import { BREADCRUMBS, Breadcrumbs } from 'view/components/@luna/breadcrumbs';
import { useWhereabouts } from '@rexlabs/whereabouts';

import {
  ActionDeclaration,
  ActionIntent
} from 'src/modules/common/actions/types/action-declaration-types';

import RenderLoading from 'view/components/@luna/render-loading';
import { PageLayout } from 'view/components/layouts/page-layout/page-layout';
import { RecordTitleCard } from 'view/components/cards/title';
import EditIcon from 'view/components/icons/edit';
import CheckIcon from 'view/components/icons/check';

import { InspectionRunTitleBlock } from '../components/inspection-run-title-block';
import { InspectionRunDetailsComponent } from '../components/inspection-run-details-component';
import {
  InspectionRunContextProvider,
  useInspectionRunContext
} from '../hooks/use-inspection-run-context';

function InspectionRunDetailsInner() {
  const breadcrumbs = [{ type: BREADCRUMBS.INSPECTION_TASKS }];
  const {
    isLoading,
    updateInspectionRun,
    resetInspectionRun,
    inspectionRun
  } = useInspectionRunContext();

  const { isEditMode, setIsEditMode } = useInspectionRunEditMode();

  const title = useMemo(
    () =>
      `Inspection run - ${dayjs(inspectionRun?.start_at)
        .tz()
        .format('DD MMM YYYY')}`,
    [inspectionRun?.start_at]
  );

  const actions: ActionDeclaration[] = useMemo(
    () => [
      ...(!isEditMode
        ? [
            {
              id: 'edit',
              label: 'Edit',
              Icon: EditIcon,
              handleAction: () => setIsEditMode(true)
            }
          ]
        : [
            {
              id: 'cancel',
              label: 'Cancel',
              handleAction: async () => {
                await resetInspectionRun?.();
                setIsEditMode(false);
              }
            },
            {
              id: 'update-inspection-run',
              label: 'Update inspection run',
              Icon: CheckIcon,
              intent: 'primary' as ActionIntent,
              handleAction: async () => {
                await updateInspectionRun?.();
                setIsEditMode(false);
              }
            }
          ])
    ],
    [isEditMode, updateInspectionRun]
  );

  return (
    <RenderLoading isLoading={isLoading}>
      <Box flexDirection={'column'} rowGap={'2rem'}>
        <Breadcrumbs breadcrumbs={breadcrumbs} />
        <RecordTitleCard
          titleBlock={
            <InspectionRunTitleBlock title={title} actions={actions} />
          }
        />

        <InspectionRunDetailsComponent isEditMode={isEditMode} />
      </Box>
    </RenderLoading>
  );
}

export function InspectionRunDetails({
  inspectionRunId
}: {
  inspectionRunId?: string;
}) {
  return (
    <InspectionRunContextProvider inspectionRunId={inspectionRunId}>
      <PageLayout>
        <InspectionRunDetailsInner />
      </PageLayout>
    </InspectionRunContextProvider>
  );
}

function useInspectionRunEditMode() {
  const { history }: any = useWhereabouts();
  const showEditModeOnInitialLoad = useMemo(() => {
    const lastLocation = history[history.length - 2];
    return !!(
      lastLocation?.path === '/inspection-tasks' &&
      lastLocation?.query?.tab !== 'inspection-runs'
    );
  }, [history]);

  const [isEditMode, setIsEditMode] = React.useState(showEditModeOnInitialLoad);

  return { isEditMode, setIsEditMode };
}
