import React from 'react';
import { Field, HiddenField, useReactFormsContext } from '@rexlabs/form';
import { TextInput } from '@rexlabs/text-input';

import { FinancialRecordTypes } from 'utils/financials/financial-objects';

import { CentAmountInput } from 'view/components/inputs/cent-amount';
import { FinancialEntitySelect } from 'view/components/inputs/selects/financial-entity-select';

import { Column, Grid } from 'view/components/@luna/form/grid';
import { useFeatureFlags } from 'view/components/@luna/feature-flags';
import { FLAGS } from 'utils/feature-flags';
import { PrepaymentBucketSelect } from 'view/components/inputs/selects/v4-selects/prepayment-bucket-select';
import { EntitySelect } from 'view/components/inputs/selects/v4-selects/entity-select';
import { get } from 'lodash';
import { useGetTenancyPrepaymentBuckets } from 'src/modules/tenancies/hooks/use-get-tenancy-prepayment-buckets';
import { BaseLineItemWithBankAccountProps } from '../blocks/trust-journal-entry-line-items';
import { ReceiptFundsLineItemContainer } from './receipt-funds-line-item-container';

type DepositLineItemProps = BaseLineItemWithBankAccountProps;

export function DepositLineItem({
  fieldName,
  onRemove,
  values,
  bankAccount
}: DepositLineItemProps) {
  const { hasFeature } = useFeatureFlags();

  return hasFeature(FLAGS.PREPAYMENTS) ? (
    <DepositLineItemV1
      fieldName={fieldName}
      onRemove={onRemove}
      values={values}
      bankAccount={bankAccount}
    />
  ) : (
    <DepositLineItemOld fieldName={fieldName} onRemove={onRemove} />
  );
}

function DepositLineItemOld({
  fieldName,
  onRemove
}: Omit<DepositLineItemProps, 'values' | 'bankAccount'>) {
  return (
    <ReceiptFundsLineItemContainer heading={'Deposit'} onRemove={onRemove}>
      <div style={{ display: 'none' }}>
        <Field
          name={`${fieldName}.category`}
          Input={HiddenField}
          optional={false}
        />
      </div>
      <Grid columns={4}>
        <Column width={2}>
          <Field
            name={`${fieldName}.description`}
            label='Description'
            Input={TextInput}
            optional={false}
          />
        </Column>

        <Field
          name={`${fieldName}.deposit.ledger.object`}
          label='Deposit to'
          Input={FinancialEntitySelect}
          inputProps={{
            objectTypes: Object.values(FinancialRecordTypes)
          }}
          optional={false}
        />

        <Field
          name={`${fieldName}.amount`}
          label='Amount allocated'
          Input={CentAmountInput}
          optional={false}
        />
      </Grid>
    </ReceiptFundsLineItemContainer>
  );
}

function DepositLineItemV1({
  fieldName,
  onRemove,
  values,
  bankAccount
}: DepositLineItemProps) {
  const toFolio = get(values, `${fieldName}.deposit.ledger.object`);
  const isTenancyFolio = toFolio ? toFolio.type.id === 'tenancy' : false;

  const { data } = useGetTenancyPrepaymentBuckets(
    isTenancyFolio ? toFolio.id : undefined,
    bankAccount?.id
  );

  const generalFunds = getDefaultBucket(data);

  const { resetField, setFieldValue } = useReactFormsContext();

  React.useEffect(() => {
    if (!isTenancyFolio) {
      resetField(`${fieldName}.deposit_into`);
    } else {
      setFieldValue(`${fieldName}.deposit_into`, generalFunds);
    }
  }, [isTenancyFolio, fieldName, generalFunds]);

  return (
    <ReceiptFundsLineItemContainer heading={'Deposit'} onRemove={onRemove}>
      <div style={{ display: 'none' }}>
        <Field
          name={`${fieldName}.category`}
          Input={HiddenField}
          optional={false}
        />
      </div>
      <Grid columns={4}>
        <Column width={2}>
          <Field
            name={`${fieldName}.deposit.ledger.object`}
            label='To folio'
            Input={EntitySelect}
            inputProps={{
              objectTypes: Object.values(FinancialRecordTypes)
            }}
            optional={false}
          />
        </Column>

        <Field<typeof PrepaymentBucketSelect>
          name={`${fieldName}.deposit_into`}
          label='Deposit into'
          Input={PrepaymentBucketSelect}
          inputProps={{
            disabled: !isTenancyFolio,
            tenancyId: isTenancyFolio ? toFolio.id : undefined,
            bankAccountId: bankAccount?.id
          }}
          optional={false}
        />

        <Field
          name={`${fieldName}.amount`}
          label='Amount allocated'
          Input={CentAmountInput}
          optional={false}
        />

        <Column width={4}>
          <Field
            name={`${fieldName}.description`}
            label='Description'
            Input={TextInput}
            optional={false}
          />
        </Column>
      </Grid>
    </ReceiptFundsLineItemContainer>
  );
}

function getDefaultBucket(buckets) {
  if (!Array.isArray(buckets)) return null;

  return buckets.find((bucket) => bucket.system_purpose.id === 'general_funds');
}
