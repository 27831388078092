export const AGENCY_FEES = {
  AGENCY_FEES_LIST: {
    config: {
      path: '/agency-fees',
      Component: require('./screens/agency-fees-list.tsx').AgencyFeesListScreen
    },

    CREATE: {
      config: {
        path: '/agency-fees/create',
        Component: require('./dialogs/create-new-agency-fee')
          .CreateNewAgencyFeeDialog
      }
    }
  }
};
