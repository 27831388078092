import React, { useCallback } from 'react';
import { useModelActions } from '@rexlabs/model-generator';
import { bankDepositModel } from 'src/modules/banking/bank-deposits/models/bank-deposit-model';
import { DialogProps } from '@rexlabs/dialog';

import { RecordSubmitHandler } from 'view/components/record-screen/utils';
import { RecordDialog } from 'view/components/record-screen/dialog/dialog';
import { DialogContentConfig } from 'view/components/record-screen/dialog/types';
import { summaryBlock } from '../blocks/summary';
import { editableCashBookEntriesBlock } from '../blocks/editable-cash-book-entries';

const content: DialogContentConfig = [
  {
    id: 'cash-book-entries',
    label: 'Cash book entries',
    blocks: [editableCashBookEntriesBlock, summaryBlock]
  }
];

interface CreateBankDepositDialogComponentProps extends DialogProps {
  trustAccountId: string;
  refreshListId?: string;
}

export function CreateBankDepositDialog({
  trustAccountId,
  refreshListId,
  ...dialogProps
}: CreateBankDepositDialogComponentProps) {
  const { createItem, refreshList } = useModelActions(bankDepositModel);

  const handleSubmit: RecordSubmitHandler = useCallback(
    async ({ values }) => {
      const response = await createItem({
        data: {
          status: {
            id: 'pending_deposit'
          },
          trust_account: {
            id: trustAccountId
          },
          cash_book_entries: values.cashBookEntries.map((cashBookEntry) => ({
            id: cashBookEntry?.id
          }))
        }
      });
      await refreshList({ id: refreshListId });
      return response.data;
    },
    [createItem, refreshList, refreshListId, trustAccountId]
  );

  return (
    <RecordDialog
      {...dialogProps}
      data={{ trustAccountId }}
      title='Create bank deposit'
      content={content}
      handleSubmit={handleSubmit}
    />
  );
}
