import * as React from 'react';
import { FieldArray } from '@rexlabs/form';
import { OutlineButton } from '@rexlabs/button';

import { BlockConfig } from 'view/components/record-screen/types';
import { FormLayout } from 'view/components/layouts/form/form-layout';
import AddIcon from 'view/components/icons/add';
import { getOwnerContacts } from 'src/modules/ownerships/utils/get-owner-contacts';
import { Ownership } from 'data/models/entities/ownerships';

import { Message } from 'view/components/@luna/message';
import BankAccountIllustration from 'assets/illustrations/bank-account.svg';
import { PaymentMethodDialogLineItem } from 'src/modules/disbursements/components/payment-method-dialog-line-item';
import { Trans, useTranslation } from 'react-i18next';
import { getDefaultLineItem } from '../utils/get-default-line-item';

export const createOwnershipDisbursementPaymentMethodsBlock: BlockConfig<Ownership> = {
  id: 'ownership-disbursement-preferences',
  title: () => <Trans i18nKey='disbursements.method.label' />,
  validate: {
    definitions: {
      'disbursement_payment_methods.*.contact_payment_method': {
        rules: 'required'
      },
      'disbursement_payment_methods.*.contact': { rules: 'required' },
      'disbursement_payment_methods.*.split_amount.type': {
        rules: 'required'
      },
      'disbursement_payment_methods.*.split_amount.amount': {
        rules: [
          'required',
          {
            key: 'check-100-percent',
            type: 'global',
            rule:
              'disbursementNotMoreThan100Percent|disbursementNotLessThan100Percent'
          }
        ]
      }
    }
  },
  Edit: ({ values, forms }) => {
    const { t } = useTranslation();
    return (
      <FormLayout>
        <FieldArray name='disbursement_payment_methods'>
          {function PaymentMethods({ fields, push }) {
            const ownerContacts = getOwnerContacts(
              forms?.owners?.values?.owners
            )?.filter(Boolean);

            if (forms?.owners && !ownerContacts?.length) {
              return (
                <Message
                  Illustration={BankAccountIllustration}
                  title={t('disbursements.no-method-selected')}
                  grey
                >
                  {t(
                    'disbursements.please-select-owner-before-defining-method'
                  )}
                </Message>
              );
            }

            return (
              <div>
                {fields.map(({ field, actions }, index) => (
                  <PaymentMethodDialogLineItem
                    key={field.name}
                    field={field}
                    actions={actions}
                    ownerContacts={ownerContacts}
                    index={index}
                    values={values}
                  />
                ))}
                <div>
                  <OutlineButton
                    IconLeft={AddIcon}
                    onClick={() => push(getDefaultLineItem())}
                  >
                    Add another payment method
                  </OutlineButton>
                </div>
              </div>
            );
          }}
        </FieldArray>
      </FormLayout>
    );
  }
};
