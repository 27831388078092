import { getSearchQuery } from 'src/lib/react-query/hooks/use-records-query';
import { Contact } from 'src/modules/contacts/types/contact-types';
import { Property } from 'src/modules/properties/types/property-types';
import { Task } from 'src/modules/tasks/common/types/Task';
import { FileUpload } from 'src/modules/common/types/file';

import { SearchResultItem } from 'utils/api/get-search-results';
import { api } from 'utils/api/api-client';

import { Tenancy } from 'data/models/entities/tenancies';
import { Ownership } from 'data/models/entities/ownerships';
import { SUPPORTED_RECORD_TYPES_FOR_DOCUMENT_TYPES } from '../constants/SupportedRecordTypesForDocumentTypeSelect';

type SupportedRelatesToType =
  | Contact
  | Property
  | Tenancy
  | Ownership
  | Task<any>;

export async function getDocumentsForRelatesTo<
  TRecord extends SupportedRelatesToType
>(relatesTo: SearchResultItem<TRecord>, documentTypes: string[]) {
  if (
    !relatesTo ||
    documentTypes.length === 0 ||
    !SUPPORTED_RECORD_TYPES_FOR_DOCUMENT_TYPES.includes(relatesTo.type.id)
  ) {
    return [];
  }

  const { id, __record_type } = relatesTo.record;

  const query = getSearchQuery({
    filter: [
      { field: `${__record_type}_id`, op: 'eq', value: id },
      { field: 'type_id', op: 'in', value: documentTypes }
    ],
    includes: ['file']
  });

  return api.get<FileUpload[]>(`/documents?${query}`).then((response) => {
    return response.data;
  });
}
