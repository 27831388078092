import * as React from 'react';
import { Select, SelectProps } from '@rexlabs/select';
import { useQuery } from 'react-query';
import { api } from 'utils/api/api-client';
import { Quri, toQuri } from '@rexlabs/quri';
import { BaseModelGeneratorModel } from '@rexlabs/model-generator';

interface AllRecordItemsProps {
  queryFilters?: Quri[];
  endpoint: string;
  id?: string;
}

/** Note that this select should be used with some caution -
 * it will fetch all records from the endpoint regardless of how many there are (which can take a long time)
 * and these will be cached for the length of the session (unless refetched manually)
 * This is useful for small, relatively static lists of records, such as for settings.
 * Use a regular search-select if there is likely to be a large number of records that change frequently
 * (e.g. it would be very silly to use this for contacts)
 */
export function AllRecordItemsSelect<T extends BaseModelGeneratorModel>({
  queryFilters,
  endpoint,
  helperText: propHelperText,
  id,
  ...props
}: AllRecordItemsProps & SelectProps<T>) {
  const { items, isLoading } = useAllRecordItems<T>({ endpoint, queryFilters });

  const normaliser = React.useCallback(
    (item) => ({
      id: item.id,
      label: item.name
    }),
    []
  );

  // The helper text is a string shown beneath the search input. The default behavior when using search changes
  // the helper to 'Loading...' when performing the search, 'No results found' when an empty array is
  // returned, and 'Select results', when we have some data in the results. Because not relying on the default behavior
  // and we're manually passing in the items, we need to mimic that behavior

  const helperText = isLoading
    ? 'Loading...'
    : items?.length === 0
    ? 'No results found'
    : propHelperText || 'Select result';

  return (
    <Select
      normaliser={normaliser}
      {...props}
      helperText={helperText}
      items={isLoading ? [] : items}
      id={id}
    />
  );
}

function useAllRecordItems<T extends BaseModelGeneratorModel>({
  endpoint,
  queryFilters
}: AllRecordItemsProps) {
  const records = useQuery(
    [`all-${endpoint}`],
    async () => {
      try {
        let items: Array<T> = [];

        const query = new URLSearchParams();
        query.set('per_page', '100');

        if (queryFilters) {
          const filterString = toQuri(queryFilters);
          query.set('q', filterString);
        }

        const queryString = decodeURIComponent(query.toString());

        const response = await api.get(`/${endpoint}?${queryString}`);
        items = items.concat(
          response.data.map((item) => {
            return {
              ...item,
              label: item.name,
              value: item.id
            };
          })
        );

        const pagination = response['pagination'];

        if (pagination.total_pages === 1) return items;

        const promises: Array<Promise<any>> = [];

        for (let page = 2; page <= pagination.total_pages; page++) {
          promises.push(
            api
              .get(`/${endpoint}?${queryString}&page=${page}`)
              .then((response) => {
                items = items.concat(
                  response.data.map((item) => {
                    return {
                      ...item,
                      label: item.name,
                      value: item.id
                    };
                  })
                );
              })
          );
        }

        await Promise.all(promises);

        return items;
      } catch (e) {
        console.error(e);
      }
    },
    {
      refetchOnWindowFocus: false,
      cacheTime: Infinity,
      staleTime: Infinity
    }
  );

  return {
    items: records.data!,
    isLoading: records.isLoading
  };
}
