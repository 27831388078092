import React, { ReactNode } from 'react';
import { RegularQuri } from '@rexlabs/quri';
import { Reconciliation } from 'data/models/entities/financials/reconciliations';
import { useTranslation } from 'react-i18next';
import { formatDate } from 'utils/dates/format';
import { SummaryItem } from 'view/components/statement';
import { Link, parseRouteToUrl } from '@rexlabs/whereabouts';
import ROUTES from 'routes/app';
import { createTableRouteConfig } from 'src/modules/common/utils/create-table-route-config';
import { useSessionState } from 'src/modules/common/hooks/session-state';

export function useGetBankStatementSummaryEntries() {
  const { t } = useTranslation();

  return (reconciliation: Reconciliation): SummaryItem[] => {
    const stats = reconciliation.reconciliation_summary;

    return [
      {
        type: 'inbound',
        label: 'Unreconciled deposits',
        amount: stats?.bank_deposits_unreconciled_amount
      },
      {
        type: 'inbound',
        label: t('reconciliations.trust-receipts-not-banked.label'),
        amount: stats?.trust_receipts_not_yet_banked_amount
      },
      {
        type: 'outbound',
        label: 'Unreconciled withdrawals',
        amount: stats?.bank_withdrawals_exc_cheques_unreconciled_amount
      },
      {
        type: 'outbound',
        label: t('reconciliations.trust-withdrawals-not-banked.label'),
        amount: stats?.trust_withdrawals_not_yet_banked_amount
      },
      {
        type: getSummaryType(stats?.bank_adjustments_unreconciled_amount),
        label: 'Adjustments',
        amount: Math.abs(stats?.bank_adjustments_unreconciled_amount)
      },
      {
        type: 'outbound',
        label: 'Suspended transactions',
        amount: stats?.suspended_transaction_amount
      },
      {
        type: 'total',
        label: 'Adjusted bank balance',
        amount: stats?.adjusted_bank_balance_amount
      }
    ];
  };
}

export function getCashbookSummaryEntries(
  reconciliation: Reconciliation
): SummaryItem[] {
  const stats = reconciliation.reconciliation_summary;

  return [
    {
      type: 'inbound',
      label: getConditionalLabel(
        'Receipted funds',
        !!stats?.trust_receipts_in_period_amount,
        parseRouteToUrl(getRouteConfig(reconciliation, 'receipts'))
      ),
      amount: stats?.trust_receipts_in_period_amount
    },
    {
      type: 'outbound',
      label: getConditionalLabel(
        'Withdrawn funds',
        !!stats?.trust_withdrawals_in_period_amount,
        parseRouteToUrl(getRouteConfig(reconciliation, 'withdrawals'))
      ),
      amount: stats?.trust_withdrawals_in_period_amount
    },
    {
      type: 'total',
      label: 'Closing balance ',
      subLabel: `On ${formatDate(reconciliation.statement_date)}`,
      amount: stats?.cashbook_closing_balance_amount
    }
  ];
}

export function useGetTrialBalanceSummaryEntries() {
  const sessionData = useSessionState();

  return (reconciliation: Reconciliation): SummaryItem[] => {
    const stats =
      reconciliation.reconciliation_summary.trust_trial_balance_breakdown;

    return [
      {
        type: getSummaryType(stats.tenancy_amount),
        label: 'Tenancies',
        amount: Math.abs(stats.tenancy_amount)
      },
      {
        type: getSummaryType(stats.ownership_amount),
        label: 'Ownerships',
        amount: Math.abs(stats.ownership_amount)
      },
      {
        type: getSummaryType(stats.contact_amount),
        label: 'Contacts',
        amount: Math.abs(stats.contact_amount)
      },
      {
        type: getSummaryType(stats.agency_amount),
        label: 'Agencies',
        amount: Math.abs(stats.agency_amount)
      },
      {
        type: getSummaryType(stats.supplier_amount),
        label: 'Suppliers',
        amount: Math.abs(stats.supplier_amount)
      },
      ...(sessionData.activeSilo?.country?.id === 'GBR'
        ? [
            {
              type: getSummaryType(stats.tax_authority_amount),
              label: 'Tax authorities',
              amount: Math.abs(stats.tax_authority_amount)
            } as SummaryItem
          ]
        : []),

      {
        type: getSummaryType(stats.bond_authority_amount),
        label: 'Bond authorities',
        amount: Math.abs(stats.bond_authority_amount)
      }
    ];
  };
}

function getRouteConfig(
  reconciliation: Reconciliation,
  tab: 'withdrawals' | 'receipts'
) {
  const isDailyREconciliation =
    reconciliation.start_date === reconciliation.statement_date;

  const filters: RegularQuri[] = [
    ...(isDailyREconciliation
      ? ([
          {
            field: 'date_of',
            op: 'eq',
            value: reconciliation.statement_date
          }
        ] as RegularQuri[])
      : ([
          {
            field: 'date_of',
            op: 'gte',
            value: reconciliation.start_date
          },
          {
            field: 'date_of',
            op: 'lte',
            value: reconciliation.statement_date
          }
        ] as RegularQuri[]))
  ];

  return createTableRouteConfig(filters, tab, {
    path: ROUTES.TRUST_JOURNAL_ENTRIES.config.path
  });
}

function getConditionalLabel(
  label: string,
  condition: boolean,
  route: string
): ReactNode {
  return condition ? <Link to={route}>{label}</Link> : label;
}

function getSummaryType(amount: number): 'inbound' | 'outbound' {
  return amount >= 0 ? 'inbound' : 'outbound';
}
