import { useConfirmationDialog } from '@rexlabs/dialog';
import {
  OwnershipFee,
  ownershipsFeesModel
} from 'data/models/entities/ownerships/fees/ownership-fees';
import { ActionDeclaration } from 'src/modules/common/actions/types/action-declaration-types';
import { useModelActions } from '@rexlabs/model-generator';
import { useToast } from 'view/components/@luna/notifications/toast';
import React from 'react';

export function useGetRemoveOwnershipFeeAction() {
  const { open } = useConfirmationDialog();
  const { trashItem } = useModelActions(ownershipsFeesModel);
  const { addToast } = useToast();

  return (
    ownershipFee: OwnershipFee,
    ownershipId: string
  ): ActionDeclaration => ({
    id: 'remove-ownership-fee',
    group: 'ownership-fee',
    label: 'Remove fee',
    handleAction: () => {
      open({
        title: 'Removing a fee',
        message:
          'Please confirm you wish to remove this fee from this ownership. You can add this fee back by clicking the “Add ownership fees” button.',
        confirmText: 'Remove fee',
        onConfirm: async () => {
          await trashItem({
            id: ownershipFee.id,
            args: {
              ownershipId
            }
          });

          addToast({
            type: 'warning',
            description: (
              <>
                <b>{ownershipFee.agency_fee?.name} fee</b> has been{' '}
                <b>removed</b> from this ownership
              </>
            )
          });
        }
      });
    }
  });
}
