export const BANK_WITHDRAWALS_ROUTES = {
  BANK_WITHDRAWALS_LIST: {
    config: {
      path: '/bank-withdrawals',
      Component: require('./screens/bank-withdrawals-list.tsx')
        .BankWithdrawalsListScreen
    }
  },
  BANK_WITHDRAWAL: {
    config: {
      path: '/bank-withdrawal/:bankWithdrawalId',
      Component: require('./screens/bank-withdrawal-details.tsx')
        .BankWithdrawalDetails
    }
  }
};
