import React from 'react';
import { BlockConfig } from 'view/components/record-screen/types';
import { Column, Grid } from '@rexlabs/grid';
import { Value } from 'view/components/values';
import { ChargeEntry } from 'data/models/entities/financials/charge-entries';
import { DateValue } from 'view/components/values/date';
import { CurrencyValue } from 'view/components/values/currency';
import { RecordLinkValue } from 'view/components/values/record-link';
import { Field } from '@rexlabs/form';
import { TextInput } from '@rexlabs/text-input';
import { DateInput } from 'view/components/@luna/inputs/date-input/date-input';
import { FinancialEntitySelect } from 'view/components/inputs/selects/financial-entity-select';
import { ParentChartOfAccountsAccountSelect } from 'view/components/inputs/selects/parent-chart-of-accounts-account';
import { CentAmountInput } from 'view/components/inputs/cent-amount';
import { TaxTypeSelect } from 'view/components/inputs/selects/tax-type';
import { Checkbox } from '@rexlabs/checkbox';

export const chargeEntryDetailsBlock: BlockConfig<ChargeEntry> = {
  id: 'chargeEntryDetails',
  title: 'Details',
  View: ({ data }) => (
    <Grid>
      <Column width={6}>
        <Value label='Description' value={data?.description} />
      </Column>
      <Column width={6}>
        <DateValue label='Due date' value={data?.due_date} />
      </Column>

      <Column width={6}>
        <RecordLinkValue
          label='Payable by'
          value={data!.payable_by.object.label}
          type={data!.payable_by.object.type}
          id={data!.payable_by.object.id}
        />
      </Column>
      <Column width={6}>
        <Value
          label='Account'
          value={data?.payable_by.chart_of_accounts_account?.label}
        />
      </Column>

      <Column width={6}>
        <RecordLinkValue
          label='Payable to'
          value={data!.payable_to.object.label}
          type={data!.payable_to.object.type}
          id={data!.payable_to.object.id}
        />
      </Column>
      <Column width={6}>
        <Value
          label='Account'
          value={data?.payable_to.chart_of_accounts_account?.label}
        />
      </Column>

      <Column width={6}>
        <CurrencyValue label='Amount (incl Tax)' value={data?.amount_inc_tax} />
      </Column>
      <Column width={6}>
        <Value label='Tax type' value={data?.tax_type.label} />
      </Column>
      <Column width={6}>
        <RecordLinkValue
          label='Property'
          value={data?.property?.label}
          type='property'
          id={data?.property?.id}
        />
      </Column>
    </Grid>
  ),
  Edit: ({ values }) => {
    const isPayableToAccountDisabled =
      values.payable_to?.object?.type === 'tenancy' ||
      values.payable_to?.object?.type === 'contact';
    const isPayableByAccountDisabled =
      values.payable_by?.object?.type === 'tenancy' ||
      values.payable_by?.object?.type === 'contact';

    return (
      <Grid>
        <Column width={6}>
          <Field name='description' label='Description' Input={TextInput} />
        </Column>
        <Column width={6}>
          <Field name='due_date' label='Due date' Input={DateInput} />
        </Column>

        <Column width={6}>
          <Field
            name='payable_to.object'
            label='Payable to'
            Input={FinancialEntitySelect}
            inputProps={{ objectTypes: ['ownership', 'tenancy', 'contact'] }}
          />
        </Column>
        <Column width={6}>
          <Field
            name='payable_to.chart_of_accounts_account'
            label='Account'
            Input={ParentChartOfAccountsAccountSelect}
            inputProps={{ disabled: isPayableToAccountDisabled }}
          />
        </Column>

        <Column width={6}>
          <Field
            name='payable_by.object'
            label='Payable by'
            Input={FinancialEntitySelect}
            inputProps={{ objectTypes: ['ownership', 'tenancy', 'contact'] }}
          />
        </Column>
        <Column width={6}>
          <Field
            name='payable_by.chart_of_accounts_account'
            label='Account'
            Input={ParentChartOfAccountsAccountSelect}
            inputProps={{ disabled: isPayableByAccountDisabled }}
          />
        </Column>

        <Column width={6}>
          <Field name='amount' label='Amount' Input={CentAmountInput} />
        </Column>
        <Column width={3}>
          <Field name='tax_type' label='Tax type' Input={TaxTypeSelect} />
        </Column>
        <Column width={3}>
          <Field
            name='is_tax_included'
            Input={Checkbox}
            inputProps={{ label: 'Tax included' }}
          />
        </Column>

        <Column width={12}>
          <Field
            name='property'
            label='Property'
            Input={FinancialEntitySelect}
            inputProps={{ objectTypes: ['property'] }}
          />
        </Column>
      </Grid>
    );
  }
};
