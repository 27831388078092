import { Generator, GeneratorConfig } from 'data/models/generator';
import {
  ValueListValue,
  RecordType,
  BaseFields,
  RecordTypes
} from 'data/models/types';

type Payable = {
  object: {
    type: RecordType;
    id: string;
    label?: string;
  };
  chart_of_accounts_account: ValueListValue<string> | null;
};

export interface ChargeEntry
  extends BaseFields<typeof RecordTypes.ChargeEntry> {
  amount: number;
  is_tax_included: boolean;
  tax_type: ValueListValue<string>;
  due_date: string;
  property: ValueListValue<string>;
  payable_to: Payable;
  payable_by: Payable;

  description: string | null;

  amount_inc_tax: number;
  amount_exc_tax: number;
  amount_tax: number;
  amount_balance: null | number;
}

const config: GeneratorConfig = {
  entities: {
    related: {
      property: {
        include: 'property'
      },
      tax_type: {
        include: 'tax_type'
      }
    }
  }
};

export const financialsChargeEntriesModel = new Generator<ChargeEntry>(
  'financials/charge-entries',
  config
).createEntityModel();
