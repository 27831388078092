import React, { createContext, useContext } from 'react';

import ROUTES from 'routes/app';

import { useBankAccountSelectActions } from 'src/modules/bank-accounts/hooks/use-bank-account-select-actions';

import LoadingState from 'view/components/states/loading';
import RenderLoading from 'view/components/@luna/render-loading';

import { BankAccount } from 'data/models/entities/financials/bank-accounts';

export interface BankAccountContext {
  bankAccount: BankAccount;
  isBankAccountsLoading?: boolean;
  actions?: any[];
}

const BankAccountContext = createContext<BankAccountContext>({
  bankAccount: {} as BankAccount
});

export function BankAccountProvider({
  children
}: {
  children: React.ReactNode;
}) {
  const {
    isLoading: isBankAccountsLoading,
    bankAccount,
    actions
  } = useBankAccountSelectActions(ROUTES.BATCH_RECEIPTING_LIST_SCREEN);

  return (
    <RenderLoading isLoading={isBankAccountsLoading} LoadingView={LoadingState}>
      {bankAccount && (
        <BankAccountContext.Provider
          value={{ bankAccount, isBankAccountsLoading, actions }}
        >
          {children}
        </BankAccountContext.Provider>
      )}
    </RenderLoading>
  );
}

export function useBankAccountState() {
  return useContext(BankAccountContext);
}
