import React from 'react';
import Box from '@rexlabs/box';
import { RightBar } from 'view/components/record-screen/right-bar';
import { ChecklistProgressWidget } from 'src/modules/tasks/common/components/checklist-progress-widget';
import { useFeatureFlags } from 'view/components/@luna/feature-flags';
import { FLAGS } from 'utils/feature-flags';

export function TaskRightBarOverride({ record, relatedRecordsSections }: any) {
  const { hasFeature } = useFeatureFlags();
  return (
    <Box sy='4.8rem'>
      {hasFeature(FLAGS.CHECKLIST_TEMPLATES) && (
        <ChecklistProgressWidget task={record} />
      )}

      <RightBar
        record={record}
        relatedRecordsSections={relatedRecordsSections}
      />
    </Box>
  );
}
