import React from 'react';
import { query, useEntityQuery } from '@rexlabs/model-generator';
import { RecordScreen } from 'view/components/record-screen';
import { accountingJournalEntryModel } from 'src/modules/account-journal-entries/model/accounting-journal-entry-model';
import { AccountingJournalEntryTitleBlock } from 'src/modules/account-journal-entries/components/accounting-journal-entry-title-block';
import { VoidedBanner } from 'src/modules/common/components/voided-banner';
import { useFeatureFlags } from 'view/components/@luna/feature-flags';
import { BREADCRUMBS } from 'view/components/@luna/breadcrumbs';
import { useAccountingJournalEntryRecordScreenContent } from '../hooks/use-accounting-journal-entries-record-screen-content';

interface AccountingJournalEntryRecordScreenProps {
  accountingJournalEntryId: string;
}

export function AccountingJournalEntryRecordScreen({
  accountingJournalEntryId
}: AccountingJournalEntryRecordScreenProps) {
  const { hasFeature } = useFeatureFlags();

  const hasManualAccountingJournalEntriesFeature = hasFeature(
    'manual_accounting_journal_entries'
  );

  const accountingJournalEntryQuery = query`{
   ${accountingJournalEntryModel} (id: ${accountingJournalEntryId}) {
      id
      line_items{
        property
      }
      created_by
      object {
        owners
      }
      source_object{
        trust_journal_entry
      }
      voided_by
    }
  }`;

  const { data, status } = useEntityQuery(accountingJournalEntryQuery);

  const content = useAccountingJournalEntryRecordScreenContent();

  const breadcrumbs = data?.object && [
    {
      type:
        data.object.__record_type === 'ownership'
          ? BREADCRUMBS.OWNERSHIP_RECORD
          : BREADCRUMBS.CONTACT_RECORD,
      args: {
        id: data.object.id,
        label: data.object.display_name
      }
    }
  ];

  return (
    <RecordScreen
      isLoading={status === 'loading'}
      data={data}
      content={content}
      titleBlock={
        <AccountingJournalEntryTitleBlock accountingJournalEntry={data!} />
      }
      banner={
        hasManualAccountingJournalEntriesFeature
          ? data?.status.id === 'void' &&
            data.type.id === 'manual' && <VoidedBanner record={data} />
          : undefined
      }
      breadcrumbs={
        hasManualAccountingJournalEntriesFeature ? breadcrumbs : undefined
      }
    />
  );
}
