import { OutlineButton } from '@rexlabs/button';
import { Field } from '@rexlabs/form';
import { Body } from '@rexlabs/text';
import { Link } from '@rexlabs/whereabouts';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import ROUTES from 'routes/app';
import { useSettings } from 'src/modules/settings/hooks/useSettings';
import { pluralize } from 'utils/formatters';
import { Column, Grid } from 'view/components/@luna/form/grid';
import {
  DateInput,
  disablePastDates
} from 'view/components/@luna/inputs/date-input/date-input';
import { InfoBanner } from 'view/components/@luna/notifications/banner';
import InfoCircleIcon from 'view/components/icons/info';
import { FrequencySplitInput } from 'view/components/inputs/frequency-input/frequency-split-input';
import { BlockConfig } from 'view/components/record-screen/types';
import { Value } from 'view/components/values';
import { DateValue } from 'view/components/values/date';

export const leaseReviewsBlock: BlockConfig = {
  id: 'lease-reviews',
  title: () => <Trans i18nKey='tasks.lease-review.label.plural' />,
  validate: {
    definitions: {
      'selected_property_tenancy.rent_review_frequency': {
        rules: 'required'
      }
    }
  },
  View: ({ data }) => {
    const { t } = useTranslation();

    const settingsData = useSettings();
    const leaseReviewData = settingsData['lease-review-task-interval'];

    const fixedAgreementNoticeCount =
      leaseReviewData?.fixed_agreement_notice?.count;
    const fixedAgreementNoticeUnit =
      leaseReviewData?.fixed_agreement_notice?.unit;

    const periodicAgreementNoticeCount =
      leaseReviewData?.periodic_agreement_notice?.count;
    const periodicAgreementNoticeUnit =
      leaseReviewData?.periodic_agreement_notice?.unit;

    const status = data?.selected_property_tenancy?.status?.id;

    const isDraft = status === 'draft';
    const isActive = status === 'active';

    const rentReviewDate = data.selected_property_tenancy?.rent_review_date;

    const leaseType = data?.selected_property_tenancy?.lease_type?.id;

    const agreementFragment = React.useMemo(() => {
      switch (leaseType) {
        case 'periodic': {
          return (
            <>
              For periodic term agreements the <b>Lease renewal due date</b> is
              set to{' '}
              <b>
                {periodicAgreementNoticeCount} {periodicAgreementNoticeUnit}{' '}
                before the lease agreement expires.
              </b>
            </>
          );
        }

        case 'fixed_term':
        case 'fixed_term_then_periodic': {
          return (
            <>
              For fixed term agreements the <b>Lease renewal due date</b> is set
              to{' '}
              <b>
                {fixedAgreementNoticeCount} {fixedAgreementNoticeUnit} before
                the lease agreement expires.
              </b>
            </>
          );
        }
      }
    }, [
      leaseType,
      periodicAgreementNoticeCount,
      periodicAgreementNoticeUnit,
      fixedAgreementNoticeCount,
      fixedAgreementNoticeUnit
    ]);

    return (
      <Grid columns={2}>
        <DateValue
          label={t('tasks.lease-review.setup.next-review-due.label')}
          value={rentReviewDate}
        />

        <Value
          label={t('tasks.lease-review.setup.schedule-frequency.label')}
          value={
            data?.selected_property_tenancy?.rent_review_frequency?.count
              ? pluralize(
                  data?.selected_property_tenancy?.rent_review_frequency?.unit
                    ?.id,
                  data?.selected_property_tenancy?.rent_review_frequency?.count,
                  true
                )
              : undefined
          }
        />

        {isDraft && !rentReviewDate ? (
          <Column width={2}>
            <InfoBanner
              Icon={InfoCircleIcon}
              description={
                <>
                  This lease agreement is in draft state. When the agreement is
                  set to active, the <b>Next renewal due date</b> will be
                  displayed. Read more about lease agreements states{' '}
                  <a
                    href='https://rexpm.zendesk.com/hc/en-au/articles/14924178825625-Create-and-Manage-a-Lease-Agreement'
                    target='_blank'
                    rel='noreferrer'
                  >
                    here
                  </a>
                  . You can change default lease renewal schedule for new
                  properties in{' '}
                  <Link
                    to={ROUTES.TASK_SETTINGS_DETAILS}
                    query={{ tab: 'lease-reviews' }}
                  >
                    Settings
                  </Link>
                  .
                </>
              }
            />
          </Column>
        ) : null}

        {isActive || rentReviewDate != null ? (
          <Column width={2}>
            <Body>
              {agreementFragment} A lease renewal task will be automatically
              created at this time. You can change this in{' '}
              <Link
                to={ROUTES.TASK_SETTINGS_DETAILS}
                query={{ tab: 'lease-reviews' }}
              >
                Settings
              </Link>
              .
            </Body>
          </Column>
        ) : null}
      </Grid>
    );
  },
  Edit: ({ values, setFieldValue }) => {
    const { t } = useTranslation();
    const settingsData = useSettings();
    const leaseReviewData = settingsData['lease-review-task-interval'];

    const isSameAsSettings = React.useMemo(() => {
      const hasSameCount =
        leaseReviewData?.periodic_agreement_review?.count ===
        values?.selected_property_tenancy?.rent_review_frequency?.count;

      const hasSameUnit =
        leaseReviewData?.periodic_agreement_review?.unit ===
        values?.selected_property_tenancy?.rent_review_frequency?.unit?.id;

      return hasSameCount && hasSameUnit;
    }, [leaseReviewData, values]);

    const handleReset = () => {
      setFieldValue?.(
        'selected_property_tenancy.rent_review_frequency.count',
        leaseReviewData?.periodic_agreement_review?.count
      );

      setFieldValue?.(
        'selected_property_tenancy.rent_review_frequency.unit.id',
        leaseReviewData?.periodic_agreement_review?.unit
      );
    };

    return (
      <Grid columns={2}>
        <Field
          name='selected_property_tenancy.rent_review_date'
          label={t('tasks.lease-review.setup.next-review-due.label') as string}
          Input={DateInput}
          inputProps={{
            isOutsideRange: disablePastDates,
            clearable: true
          }}
          optional={false}
        />

        <div />

        <Field
          name='selected_property_tenancy.rent_review_frequency'
          label={
            t('tasks.lease-review.setup.schedule-frequency.label') as string
          }
          Input={FrequencySplitInput}
          optional={false}
        />

        <div />

        <Column width={2}>
          <OutlineButton isDisabled={isSameAsSettings} onClick={handleReset}>
            Reset to default frequency: Recurring every{' '}
            {pluralize(
              leaseReviewData?.periodic_agreement_review?.unit,
              leaseReviewData?.periodic_agreement_review?.count,
              true
            )}
          </OutlineButton>
        </Column>
      </Grid>
    );
  }
};
