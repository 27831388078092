import * as React from 'react';
import Box from '@rexlabs/box';
import {
  StatusChangeActionArgs,
  useStatusChangeAction
} from 'src/modules/common/actions/status/use-status-change-action';
import { invokeActionDeclaration } from 'src/modules/common/actions/utils/invoke-action-declaration';
import { Task } from 'src/modules/tasks/common/types/Task';
import { useStatusDropdownRefresh } from 'src/modules/tasks/common/hooks/use-get-status-dropdown-refresh';
import { useModelActions } from '@rexlabs/model-generator';
import { TaskStatusMenu } from '../components/task-status-menu';
import { tasksModel } from '../../common/models/tasks-model';

export function ProgressDropdown({
  value: task
}: {
  value: Task<'task_quote'>;
}) {
  const refreshListsOnComplete = useStatusDropdownRefresh();
  const { refreshItem } = useModelActions(tasksModel);
  const getStatusChangeAction = useStatusChangeAction(tasksModel);

  const handleStatusChange = (newStatus) => {
    invokeActionDeclaration<StatusChangeActionArgs<typeof newStatus.id>[]>(
      getStatusChangeAction,
      {
        record: task,
        status: newStatus.id,
        dialogOptions: {
          type: 'none',
          afterAction: () => {
            refreshListsOnComplete();
            task.parent_task && refreshItem({ id: task.parent_task?.id });
          }
        },
        transformPayload: (status) => {
          return {
            type: { id: 'task_quote' },
            details: {
              ...(status === 'received'
                ? { amount: task.details?.amount || 0 }
                : {}),
              status: {
                id: status
              }
            }
          };
        }
      }
    );
  };

  return (
    <Box
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
      data-testid='quote-status-tag'
    >
      <TaskStatusMenu
        initialValue={task.details?.status}
        onChange={handleStatusChange}
      />
    </Box>
  );
}
