// @ts-nocheck
export function initialiseSprig(environmentId: string) {
  (function (l, e, a, p) {
    window.Sprig = function (...args) {
      S._queue.push(args);
    };
    const S = window.Sprig;
    S.appId = a;
    S._queue = [];
    window.UserLeap = S;
    a = l.createElement('script');
    a.async = 1;
    a.src = e + '?id=' + S.appId;
    p = l.getElementsByTagName('script')[0];
    p.parentNode.insertBefore(a, p);
  })(document, 'https://cdn.sprig.com/shim.js', environmentId);
}
