import { Invoice } from 'data/models/entities/financials/invoices';
import { Ownership } from 'data/models/entities/ownerships';
import { Tenancy } from 'data/models/entities/tenancies';
import React from 'react';
import { SearchResultItem } from 'utils/api/get-search-results';
import { formatCurrency } from 'utils/formatters';
import { BlockConfig } from 'view/components/record-screen/types';
import { Summary } from 'view/components/summary';
import { InvoiceLineItemFormValues } from '../components/invoice-line-item';
import { InvoiceLineItems } from '../components/invoice-line-items';
import { InvoiceLineItemsForm } from '../components/invoice-line-items-form';
import '../utils/invoice-validation-rules';

export type ReimbursementInvoiceLineItemsFormValues = {
  reimbursed_line_items: InvoiceLineItemFormValues[];
};

export const validate = {
  definitions: {
    'reimbursed_line_items.*.description': {
      name: 'description',
      rules: 'required'
    },
    'reimbursed_line_items.*.tax_type': { name: 'tax type', rules: 'required' },
    'reimbursed_line_items.*.amount': { name: 'amount', rules: 'required' },
    'reimbursed_line_items.*.payable_to_chart_of_accounts_account': {
      rules: 'required_if_true:is_payable_to_COA_needed',
      name: 'invoice from account code'
    },
    'reimbursed_line_items.*.payable_to_property': {
      rules: 'required_if_true:is_payable_to_property_needed',
      name: 'invoice from property'
    },
    'reimbursed_line_items.*.payable_by_chart_of_accounts_account': {
      rules: 'required_if_true:is_payable_by_COA_needed',
      name: 'bill to account code'
    },
    'reimbursed_line_items.*.payable_by_property': {
      rules: 'required_if_true:is_payable_by_property_needed',
      name: 'bill to property'
    }
  }
};

export const reimbursementLineItemsBlock: BlockConfig<
  Invoice,
  {
    activeTab?: 'invoice' | 'reimbursement';
    hasBillProcessingFeature?: number;
    isReimbursementEmptyStateVisible?: boolean;
    handleReimbursementEmptyState?: (state: boolean) => void;
    isInvoiceDialog?: boolean;
    suggestions?: {
      payableTo?: Array<SearchResultItem<Ownership | Tenancy>>;
      payableBy?: Array<SearchResultItem<Ownership | Tenancy>>;
    };
  }
> = {
  id: 'reimbursement-line-items',
  validate,
  View: ({ data }) => {
    return <InvoiceLineItems data={data} />;
  },

  Edit: function ({ forms, blockProps, ...props }) {
    const isReimbursementTab = blockProps?.activeTab === 'reimbursement';

    if (blockProps?.isReimbursementEmptyStateVisible) return null;

    const lineItemsForm = forms?.['line-items'];

    const invoiceTotal =
      lineItemsForm != null
        ? lineItemsForm?.values?.invoice_tax_preview?.amount_inc_tax
        : props.values?.invoice_tax_preview?.amount_inc_tax;

    const handleRemoveReimbursement = () => {
      forms?.['reimbursement-details']?.resetForm();
      forms?.['reimbursement-line-items']?.resetForm();

      forms?.['reimbursement-line-items']?.setFieldValue(
        'reimbursed_line_items',
        []
      );

      blockProps?.handleReimbursementEmptyState?.(true);
    };

    return (
      <div
        style={{
          display: isReimbursementTab ? 'block' : 'none'
        }}
      >
        <InvoiceLineItemsForm
          {...props}
          blockId='reimbursement-line-items'
          lineItemsFieldName='reimbursed_line_items'
          isTaxIncludedFieldName='reimbursed_is_tax_included'
          prefix='Reimbursement'
          payableBy={
            forms?.['reimbursement-details']?.values.reimbursed_by?.object
          }
          payableTo={
            forms?.['reimbursement-details']?.values.reimbursed_to?.object
          }
          TaxWrapper={TaxWrapper}
          taxWrapperProps={{
            invoiceTotal
          }}
          invoiceLineItemColumns={
            blockProps?.hasBillProcessingFeature ? 2 : undefined
          }
          handleRemoveReimbursement={
            blockProps?.isInvoiceDialog ? handleRemoveReimbursement : undefined
          }
        />
      </div>
    );
  }
};

interface TaxWrapperProps {
  children?: React.ReactNode;
  invoiceTotal?: number;
  difference?: number;
}

function TaxWrapper(props: TaxWrapperProps) {
  const { children, invoiceTotal = 0, difference = 0 } = props;

  return (
    <>
      <Summary
        rows={[
          {
            label: 'Invoice total',
            value: formatCurrency(invoiceTotal),

            bold: true
          },
          {
            isDivider: true
          }
        ]}
      />

      {children}

      <Summary
        rows={[
          { isDivider: true },
          {
            label: 'Difference',
            value: formatCurrency(difference),
            bold: true
          }
        ]}
      />
    </>
  );
}
