import * as React from 'react';
import { RecordDialog } from 'view/components/record-screen/dialog/dialog';
import { DialogProps } from '@rexlabs/dialog';
import { RecordSubmitHandler } from 'view/components/record-screen/utils';
import invariant from 'invariant';
import { Forms } from '@rexlabs/form';
import { ChecklistItemType } from 'src/modules/tasks/common/types/ChecklistItemType';
import { TaskType } from '../../../common/types/TaskType';
import { getOffsetRelatedDatesFromRelatedDatesData } from '../../utils/get-offset-related-dates-from-related-dates-data';
import { useChecklistItemRelatedDatesQuery } from '../../hooks/use-checklist-item-related-dates-query';
import { checklistTemplateItemSubtaskTypeBlock } from '../../blocks/smart-checklists/subtask-type-block';
import { checklistTemplateItemSubtaskDetailsBlock } from '../../blocks/smart-checklists/checklist-template-item-details-block';
import { checklistTemplateItemMessageDetailsBlock } from '../../blocks/smart-checklists/checklist-template-item-message-details-block';
import { checklistTemplateItemOutcomesBlock } from '../../blocks/smart-checklists/checklist-template-item-outcomes-block';
import { checklistTemplateItemTriggerActionsBlock } from '../../blocks/smart-checklists/checklist-template-item-trigger-action-block';
import { checklistTemplateItemAdditionalDetailsBlock } from '../../blocks/smart-checklists/checklist-template-item-additional-details-block';

export function useCombinedChecklistTemplateItemContent(
  typeId: ChecklistItemType,
  disallowTypeChange = false
) {
  function getBlocksForSubtaskType(typeId: ChecklistItemType) {
    switch (typeId) {
      case 'send_message':
        return [checklistTemplateItemMessageDetailsBlock];
      case 'record_outcome':
        return [checklistTemplateItemOutcomesBlock];
      case 'trigger_action':
        return [checklistTemplateItemTriggerActionsBlock];
      case 'approval_request':
        return []; // No blocks here, it just changes the state of the default block
      default:
        return [];
    }
  }

  return React.useMemo(
    () => [
      {
        id: 'checklist-item',
        label: 'Add checklist item',
        blocks: [
          ...(disallowTypeChange
            ? []
            : [checklistTemplateItemSubtaskTypeBlock]),
          checklistTemplateItemSubtaskDetailsBlock,
          ...getBlocksForSubtaskType(typeId),
          checklistTemplateItemAdditionalDetailsBlock
        ]
      }
    ],
    [typeId]
  );
}

interface CreateChecklistItemDialogProps extends DialogProps {
  taskType: TaskType;
  handleSubmit: RecordSubmitHandler;
}

export function CreateChecklistItemDialog({
  onClose,
  taskType,
  handleSubmit
}: CreateChecklistItemDialogProps) {
  const [subtypeId, setSubtypeId] = React.useState<ChecklistItemType>(
    'default'
  );
  const [
    previousRestrictedToAssignee,
    setPreviousRestrictedToAssignee
  ] = React.useState(false);
  const content = useCombinedChecklistTemplateItemContent(subtypeId);

  const {
    data: relatedDatesData,
    isLoading
  } = useChecklistItemRelatedDatesQuery(taskType);

  const relatedDateOptions = relatedDatesData?.data?.related_dates;

  const offsetRelatedDates =
    relatedDatesData &&
    getOffsetRelatedDatesFromRelatedDatesData(relatedDatesData.data);

  const getHandlers = (forms?: Forms) => ({
    // If the type is an approval request, we want to set the restricted_to_assignee field to true, but be able to restore the previous value if they unselect that type.
    handleTypeChange: (e) => {
      const details = 'checklist-template-item-details';
      invariant(forms, 'Forms must be defined');
      const typeId: ChecklistItemType = e.target.value;
      if (typeId === 'approval_request') {
        setPreviousRestrictedToAssignee(
          forms[details]?.values?.restricted_to_assignee ?? false
        );
        forms[details].setFieldValue('restricted_to_assignee', true);
      } else {
        forms[details].setFieldValue(
          'restricted_to_assignee',
          previousRestrictedToAssignee
        );
      }
      if (typeId === 'record_outcome') {
        forms[details]?.setFieldValue?.('has_outcome', true);
      } else {
        forms[details]?.setFieldValue?.('has_outcome', false);
      }
    }
  });

  return (
    <RecordDialog
      title='Subtask'
      content={content}
      onClose={onClose}
      submitLabel='Okay'
      getHandlers={getHandlers}
      handleSubmit={handleSubmit}
      isLoading={isLoading}
      blockProps={{
        taskType,
        subtypeId,
        setSubtypeId
      }}
      initialValues={{
        type: {
          id: 'default'
        },
        due_date: {
          due_date_type: 'none',
          related_date: relatedDateOptions?.[0] || null,
          offset_related_date: {
            count: 1,
            unit: {
              id: 'days',
              label: 'Day(s)'
            },
            offset: offsetRelatedDates?.[0] || null
          }
        }
      }}
    />
  );
}
