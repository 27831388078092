import { Contact } from 'src/modules/contacts/types/contact-types';
import { EmailPreviewProps } from '../../common/components/email/email-preview';
import { getRecipientText } from '../utils/get-recipient-text';
import { BulkCreateEmailMessageFormData } from '../types/BulkCreateEmailMessageFormData';

export function mapBulkCreateMessageRequestToEmailPreviewProps(
  formData: BulkCreateEmailMessageFormData,
  recipients: Contact[] | undefined
): EmailPreviewProps {
  return {
    subject: formData?.subject ?? '',
    bodyContent: formData?.content ?? '',
    recipients: getRecipientText(recipients ?? [])
  };
}
