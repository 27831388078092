import dayjs from 'dayjs';
import { InspectionTask } from '../types/InspectionTask';
import { InspectionDetails } from '../types/InspectionDetails';
import { INSPECTION_SAVE_REQUEST_DATE_FORMAT } from '../constants/InspectionSaveRequestDateFormat';

export function mapInspectionToInspectionRunDetails(
  inspection: InspectionTask
): InspectionTask {
  return {
    ...inspection,
    details: {
      ...inspection.details,
      scheduled_at: dayjs(inspection.details!.scheduled_at)
        .tz()
        .format(INSPECTION_SAVE_REQUEST_DATE_FORMAT)
    } as InspectionDetails
  };
}
