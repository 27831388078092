import * as React from 'react';
import { isEqual } from 'lodash';

import { BaseModelGeneratorModel, EntityModel } from '@rexlabs/model-generator';
import { useWhereabouts } from '@rexlabs/whereabouts';
import { OutlineButton } from '@rexlabs/button';

import { useToast } from 'view/components/@luna/notifications/toast';
import { BannerAction } from 'view/components/@luna/notifications/banner/core';

import { getRecordLabel } from 'utils/records/get-record-label';
import { getRecordObject } from 'src/modules/common/utils/Records/get-record-object';
import {
  gotoRecord,
  getViewRecordRouteDetails
} from 'src/modules/common/utils/Records/get-record-routes';

interface Options {
  actions?: BannerAction[];
}

export function useRecordUpdatedToast(
  entityModel: EntityModel<any>,
  options: Options = {}
) {
  const { addToast } = useToast();
  const whereabouts = useWhereabouts();
  const currentPath = whereabouts.path;

  return (record: BaseModelGeneratorModel) => {
    const recordObject = getRecordObject(record, entityModel);

    const { query, path } = getViewRecordRouteDetails(recordObject);

    const isPathDifferent = path && path !== currentPath;
    const isQueryDifferent = !query || !isEqual(query, whereabouts.query);

    const shouldShowView = isPathDifferent && isQueryDifferent;

    const fallbackActions = shouldShowView
      ? [
          {
            label: 'View',
            Button: OutlineButton,
            onClick: () => gotoRecord(recordObject)
          }
        ]
      : [];

    addToast({
      type: 'info',
      description: (
        <>
          <b>{getRecordLabel(recordObject)}</b> has been updated
        </>
      ),
      actions: options.actions ?? fallbackActions
    });
  };
}
