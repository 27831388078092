export const SECURITY_DEPOSITS = {
  SECURITY_DEPOSIT_TYPES: {
    config: {
      path: '/security-deposit-types',
      Component: require('./screens/security-deposit-types-list')
        .SecurityDepositTypesList
    },

    CREATE: {
      config: {
        path: '/security-deposit-types/create',
        Component: require('./dialogs/create-security-deposit-type')
          .CreateSecurityDepositTypeDialog
      }
    }
  },
  SECURITY_DEPOSIT: {
    config: {
      path: '/security-deposit/:securityDepositId',
      Component: require('./screens/security-deposit-details')
        .SecurityDepositDetailsScreen
    }
  }
};
