import { Columns } from 'src/view/components/table';
import { RelatesToComplexCell } from 'src/modules/communications/messages/components/relates-to-complex-cell';
import { MessageBatch } from '../types/MessageBatch';
import { Message } from '../types/Message';

export const messageBatchColumns: Columns<MessageBatch> = [
  {
    id: 'time',
    Header: 'Time',
    type: 'datetime',
    accessor: (item) => item.downloaded_at ?? item.ready_at ?? item.created_at
  },
  {
    id: 'recipients_string',
    Header: 'Recipient(s)'
  },
  {
    id: 'relates_to',
    Header: 'Message relates to',
    Cell: RelatesToComplexCell,
    // Kind of heavy-handed, but it works for now
    accessor: (item) => {
      // if every item.messages has the same context, return that context
      // otherwise return null
      const contexts = item.messages?.items
        // in some edge cases, it seems this can be null
        .filter((item) => !!item)
        .map((message: Message) => ({
          type_id: message.context_type.id,
          id: 'id' in message.context ? message.context.id : null
        }));

      const uniqueContexts = new Set(
        contexts?.map((context) => JSON.stringify(context))
      );

      if (uniqueContexts.size === 1) {
        return item.messages?.items[0].context;
      }

      return null;
    }
  },
  {
    id: 'template',
    Header: 'Template',
    accessor: (item) => {
      // if all messages in the batch use the same template, return that template
      // otherwise return null
      const templates = item.messages?.items.map(
        (message: Message) => message.template?.name
      );

      const uniqueTemplates = new Set(templates);

      if (uniqueTemplates.size === 1) {
        return templates?.[0] ?? null;
      }

      return null;
    }
  }
];
