import { toQuri } from '@rexlabs/quri';
import { api } from 'utils/api/api-client';

export async function fetchInvoicesRelatedToWorkOrder(workOrderId) {
  const queryParams = new URLSearchParams();
  queryParams.set(
    'q',
    toQuri([
      {
        field: 'task_id',
        op: 'eq',
        value: workOrderId
      }
    ])
  );

  const invoicesResponse = await api.get(`/financials/invoices`, queryParams);

  return invoicesResponse.data ?? [];
}
