import React, { useMemo } from 'react';
import { query } from '@rexlabs/model-generator';
import { useWhereabouts } from '@rexlabs/whereabouts';

import { BlockConfig } from 'view/components/record-screen/types';

import { RecordTaskTabbedTable } from 'src/modules/tasks/common/components/record-task-tabbed-table';
import { tasksModel } from 'src/modules/tasks/common/models/tasks-model';

import { Property } from 'src/modules/properties/types/property-types';
import { useTaskColumnsForSupplier } from 'src/modules/tasks/common/hooks/use-task-list-columns';
import { ActionButtons } from 'view/components/action-buttons';
import { useDialog } from '@rexlabs/dialog';
import { CreateTaskDialog } from 'src/modules/tasks/common/dialogs/create-task-dialog';
import { toQuri } from '@rexlabs/quri';

export const getFilterBySupplierId = (supplierId: string) => {
  return [
    {
      field: 'supplier',
      op: 'or',
      logicalOperator: 'or' as const,
      value: [
        {
          field: 'task_quote_supplier_id',
          op: 'eq',
          value: supplierId
        },
        {
          field: 'task_work_order_work_done_by_id',
          op: 'eq',
          value: supplierId
        },
        {
          field: 'task_supplier_compliance_supplier_id',
          op: 'eq',
          value: supplierId
        }
      ]
    }
  ];
};

const getPropertyTasksQuery = () => query`{
  ${tasksModel} {
    id
    property {
      active_property_tenancy
    }
    managed_by
    details
    leaseReviewDetails
    maintenanceDetails
    inspectionDetails
    moveInDetails
    moveOutDetails
    arrearsDetails
    propertyComplianceDetails
    supplierComplianceDetails
    task_links
  }
}`;

export const assignedTasksBlock: BlockConfig<Property> = {
  id: 'assigned-tasks',
  title: 'Assigned tasks',

  View: ({ data }) => {
    const whereabouts = useWhereabouts();
    const tab = whereabouts.hashQuery?.tab;

    const filter = useMemo(() => getFilterBySupplierId(data!.id), [data!.id]);
    const columns = useTaskColumnsForSupplier();

    return (
      <RecordTaskTabbedTable
        key={tab}
        getQuery={getPropertyTasksQuery}
        columns={columns}
        filters={filter}
        normaliseGlobalFilter={normaliseGlobalFilter}
      />
    );
  },
  Actions: ({ data }) => {
    const { open: openCreateTaskDialog } = useDialog(CreateTaskDialog);

    if (!data) {
      return null;
    }

    return (
      <ActionButtons
        actions={[
          {
            label: 'Create task',
            onClick: () => {
              openCreateTaskDialog({});
            }
          }
        ]}
      />
    );
  }
};

function normaliseGlobalFilter(filters) {
  const flattenedFilter = Object.values(filters).flatMap(
    (filterArray: any) => filterArray[0]
  );

  return toQuri(flattenedFilter);
}
