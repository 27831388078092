import { Generator } from 'data/models/generator';
import {
  BaseFields,
  RecordTypes,
  TimestampAttributes,
  ValueListValue
} from 'data/models/types';
import { Contact } from 'src/modules/contacts/types/contact-types';
import { PropertyTenancy } from 'src/modules/property-tenancies/types/property-tenancy-types';
import { propertyTenancyModel } from 'src/modules/property-tenancies/models/property-tenancy-model';
import { contactsModel } from 'src/modules/contacts/models/contacts';
import { Ownership, ownershipsModel } from '../ownerships';
import {
  financialsSecurityDepositTypesModel,
  SecurityDepositType
} from './security-deposit-types';

export type SecurityDepositStatus = 'active' | 'archived';

export type SecurityDeposit = BaseFields<typeof RecordTypes.SecurityDeposit> &
  TimestampAttributes & {
    certificate_number: string;
    record_reference: string;
    amount_to_bond_authority: number | null;
    registration_number: string;
    amount_due: number;
    amount_due_and_not_invoiced_or_promised: number;
    amount_expected: number;
    amount_expected_as_weeks_of_rent: number | null;
    amount_owing_on_invoices_issued: number;
    amount_paid: number;
    amount_promised_in_external_payments: number;
    property_tenancy?: PropertyTenancy;
    insured_by_contact?: Contact;
    security_deposit_type?: SecurityDepositType;
    held_by_ownership?: Ownership;
    status: ValueListValue<SecurityDepositStatus>;
  };

const config = {
  entities: {
    related: {
      security_deposit_type: {
        include: 'security_deposit_type',
        model: financialsSecurityDepositTypesModel
      },
      held_by_ownership: {
        include: 'held_by_ownership',
        model: ownershipsModel
      },
      transaction_summary: {
        include: 'transaction_summary'
      },
      property_tenancy: {
        include: 'property_tenancy',
        model: propertyTenancyModel
      },
      insured_by_contact: {
        include: 'insured_by_contact',
        model: contactsModel
      }
    }
  }
};

export const financialsSecurityDepositsModel = new Generator<SecurityDeposit>(
  'financials/security-deposits',
  config
).createEntityModel();
