import { Task } from 'src/modules/tasks/common/types/Task';

import { api } from 'utils/api/api-client';

const relatedFields = [
  'managed_by',
  'details',
  'details.supplier',
  'details.access_provided_by'
];

export async function getQuoteTaskRelatedItems(
  quoteTaskSearchItem: Task<'task_quote'>
) {
  const quoteTaskUrl = `/tasks/${
    quoteTaskSearchItem.id
  }?include=${relatedFields.join(',')}`;

  const completeQuoteTask =
    !quoteTaskSearchItem?.details?.supplier ||
    !quoteTaskSearchItem?.details?.access_provided_by
      ? await api.get<Task<'task_quote'>>(quoteTaskUrl).then(({ data }) => data)
      : quoteTaskSearchItem;

  const supplier = completeQuoteTask?.details?.supplier;
  const accessProvidedBy = completeQuoteTask?.details?.access_provided_by;
  const managedBy = completeQuoteTask?.managed_by;

  return {
    supplier,
    accessProvidedBy,
    managedBy
  };
}
