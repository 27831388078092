import React from 'react';
import { BlockConfig } from 'view/components/record-screen/types';
import { Grid } from 'view/components/@luna/form/grid';
import { Field } from '@rexlabs/form';
import { InactiveOwnershipFees } from 'view/components/inputs/selects/inactive-ownership-fees';

export const inactiveOwnershipFeesBlock: BlockConfig = {
  id: 'inactive-ownership-fees',
  title: 'Inactive ownership fees',
  validate: {
    definitions: {
      inactive_ownership_fees: {
        rules: 'required'
      }
    }
  },
  Edit: ({ blockProps }) => (
    <Grid columns={1}>
      <Field
        name='inactive_ownership_fees'
        label='Ownership Fees'
        Input={InactiveOwnershipFees}
        inputProps={{
          ownershipId: blockProps.ownershipId,
          multi: true
        }}
      />
    </Grid>
  )
};
