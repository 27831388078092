import React, { ComponentType } from 'react';
import { Dayjs } from 'dayjs';

import Box from '@rexlabs/box';
import { useStyles, useToken, StyleSheet, mq } from '@rexlabs/styling';
import { Small } from '@rexlabs/text';
import { Breakpoints } from '@rexlabs/breakpoints';

import { formatDate } from 'utils/dates/format';

import BulletIcon from 'view/components/icons/bullet-separator';
import ChevronRightIcon from 'view/components/icons/chevron-right';

const defaultStyles = StyleSheet({
  content: {
    display: 'flex',

    ...mq.styles.combine([
      {
        queries: {
          maxWidth: 's'
        },
        styles: {
          flexDirection: 'column',
          alignItems: 'flex-start'
        }
      },
      {
        queries: {
          minWidth: 's'
        },
        styles: {
          flexDirection: 'row',
          alignItems: 'center'
        }
      }
    ])
  }
});

type LabelProps = {
  label?: string;
  isStacked?: boolean;
};

type Meta = {
  label?: string;
  Icon?: ComponentType<any>;
  Label?: ComponentType<LabelProps>;
};

type DateRange = {
  from: Dayjs;
  to: Dayjs;
};

type SubTitle = Meta | DateRange;

type SubTitleProps = Pick<SubTitlesProps, 'isStacked'> & {
  content: SubTitle;
  renderDivider?: boolean;
};

function isDateRange(subTitle: SubTitle): subTitle is DateRange {
  if ((subTitle as DateRange)?.to && (subTitle as DateRange)?.from) {
    return true;
  }

  return false;
}

function DefaultLabel({ label }: LabelProps) {
  return <>{label ?? '-'}</>;
}

function SubTitle({ content, renderDivider = true }: SubTitleProps) {
  const token = useToken();

  if (isDateRange(content)) {
    // if the dates are both the same, we do not want to show both of them
    const datesAreTheSame = formatDate(content.from) === formatDate(content.to);

    const renderDates = () => {
      if (datesAreTheSame) {
        return (
          <Small semibold as='span'>
            {formatDate(content.from)}
          </Small>
        );
      }
      return (
        <>
          <Small semibold as='span'>
            {formatDate(content.from)}
          </Small>
          <ChevronRightIcon size='s' />
          <Small semibold as='span'>
            {formatDate(content.to)}
          </Small>
        </>
      );
    };

    return (
      <Box flexDirection='row'>
        <Box sx={token('spacing.xs')} alignItems='center'>
          <Small semibold as='span'>
            Date:
          </Small>
          {renderDates()}
          <Breakpoints queries={{ minWidth: 's' }}>
            {renderDivider && <BulletIcon size='s' />}
          </Breakpoints>
        </Box>
      </Box>
    );
  }

  const { label, Icon, Label = DefaultLabel } = content;

  return (
    <Box flexDirection='row'>
      <Box sx={token('spacing.xs')} alignItems='center'>
        {Icon && <Icon size='s' />}
        <Small semibold as='span'>
          <Label label={label} />
        </Small>
        <Breakpoints queries={{ minWidth: 's' }}>
          {renderDivider && <BulletIcon size='s' />}
        </Breakpoints>
      </Box>
    </Box>
  );
}

export type SubTitlesProps = {
  isStacked?: boolean;
  dateRange?: DateRange;
  subTitles: SubTitle[];
};

export function SubTitles({
  dateRange,
  subTitles: propSubTitles
}: SubTitlesProps) {
  const s = useStyles(defaultStyles);
  const token = useToken();

  const firstSubTitle = propSubTitles[0];
  const subTitles = !dateRange
    ? propSubTitles
    : [dateRange, ...propSubTitles.slice(1)];

  return (
    <Box flexDirection='column' spacing={token('spacing.xs')}>
      {dateRange && <SubTitle content={firstSubTitle} renderDivider={false} />}
      <Box flexDirection='row'>
        <Box {...s('content')} spacing={token('spacing.xs')}>
          {subTitles.map((subTitle, index) => (
            <SubTitle
              key={index}
              content={subTitle}
              renderDivider={index !== subTitles.length - 1}
            />
          ))}
        </Box>
      </Box>
    </Box>
  );
}
