import { useDialog } from '@rexlabs/dialog';
import { Message } from '@rexlabs/notifications';
import React from 'react';
import { EmptyCard } from 'view/components/record-screen/cards/empty-card';
import EmptyTable from 'assets/illustrations/empty-table.svg';
import { FLAGS } from 'utils/feature-flags';
import { useFeatureFlags } from 'view/components/@luna/feature-flags';
import { CreateChecklistItemDialog as CombinedCreateChecklistItemDialog } from '../dialogs/smart-checklists/create-checklist-item';
import { CreateChecklistItemDialog } from '../dialogs/create-checklist-item';
import { useCreateChecklistItemSubmitHandler } from '../hooks/use-create-checklist-item-submit-handler';

export const ChecklistTemplateItemsBlockEmptyState = ({ data }) => {
  const { hasFeature } = useFeatureFlags();
  const { open: openCreateChecklistItemDialog } = useDialog(
    CreateChecklistItemDialog
  );

  // New feature flagged version
  const combinedCreateChecklistItemDialog = useDialog(
    CombinedCreateChecklistItemDialog
  );
  const hasCombinedDialog = hasFeature(FLAGS.SMART_CHECKLISTS_COMBINED_DIALOG);

  const { onHandleSubmit } = useCreateChecklistItemSubmitHandler(
    data.id,
    data.task_type.id
  );

  return (
    <EmptyCard>
      <Message
        title='No subtasks added'
        Illustration={EmptyTable}
        actions={[
          {
            label: 'Edit checklist',
            type: 'primary',
            handleAction: () => {
              if (hasCombinedDialog) {
                combinedCreateChecklistItemDialog.open({
                  taskType: data.task_type.id,
                  handleSubmit: onHandleSubmit
                });
              } else {
                openCreateChecklistItemDialog({
                  taskType: data.task_type.id,
                  handleSubmit: onHandleSubmit
                });
              }
            }
          }
        ]}
      >
        <div>Edit this checklist to add your first subtask</div>
      </Message>
    </EmptyCard>
  );
};
