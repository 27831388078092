import React, { ComponentType } from 'react';
import Box from '@rexlabs/box';
import { StyleSheet, useStyles } from '@rexlabs/styling';
import Tooltip from '@rexlabs/tooltip';

const defaultStyles = StyleSheet({
  iconContainer: {
    width: 40,
    height: 40,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: ({ token }) => token('palette.grey.200'),
    borderRadius: '0.4rem'
  }
});

interface IconCellParams {
  label: string;
  Icon: ComponentType;
}

export function IconCell({ label, Icon }: IconCellParams) {
  const s = useStyles(defaultStyles);

  return (
    <Box display='flex' justifyContent='center' alignItems='center'>
      <Tooltip Content={() => label}>
        <div {...s('iconContainer')}>
          <Icon />
        </div>
      </Tooltip>
    </Box>
  );
}
