import React from 'react';

import {
  AttachmentItem,
  RelatesTo
} from 'src/modules/communications/messages/mappers/types/create-message-form-data';
import { CommunicationContextType } from 'src/modules/communications/common/types/communication-context-type';

import { ValueListItem } from 'utils/normaliser/value-list-item';
import { FLAGS } from 'utils/feature-flags';

import { useFeatureFlags } from 'view/components/@luna/feature-flags';

import {
  BasicEditEmailForm,
  BasicEditEmailFormProps
} from './basic-forms/basic-edit-email-form';
import { UploadAttachments } from './parts/upload-attachments';
import { UploadAttachmentsWithAttachRecordDocuments } from './parts/upload-attachments-with-attach-record-documents';
import { TemplatedAttachments } from './parts/templated-attachments';

interface EditEmailSingleSendFormProps extends BasicEditEmailFormProps {
  contextType?: CommunicationContextType;
  attachments?: AttachmentItem[];
  templatedAttachments?: ValueListItem[];
  setFieldValue?: (field: string, value: any) => void;
  relatesTo?: RelatesTo;
}

/**
 * Complex form for editing email content. It has two columns:
 *   - first column includes the subject, content, attachments with attach record docs, and templated attachments fields
 *   - second column includes the merge tag component
 */
export function EditEmailSingleSendContentForm({
  contextType,
  setFieldValue,
  attachments = [],
  relatesTo,
  templatedAttachments
}: EditEmailSingleSendFormProps) {
  const { hasFeature } = useFeatureFlags();

  return (
    <BasicEditEmailForm contextType={contextType} setFieldValue={setFieldValue}>
      {hasFeature(FLAGS.ATTACH_DOCUMENTS_FROM_A_RECORD) ? (
        <UploadAttachmentsWithAttachRecordDocuments
          attachments={attachments}
          relatesTo={relatesTo}
          setFieldValue={setFieldValue}
        />
      ) : (
        <UploadAttachments />
      )}

      <TemplatedAttachments templatedAttachments={templatedAttachments} />
    </BasicEditEmailForm>
  );
}
