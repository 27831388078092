import React from 'react';
import { ConfirmationDialog, DialogProps } from '@rexlabs/dialog';
import { Body, Bold } from '@rexlabs/text';
import InfoCircleIcon from 'view/components/icons/info';
import { pluralize } from 'utils/formatters';
import { useBulkSend } from 'src/modules/communications/messages/hooks/use-bulk-send';
import { Message } from '../types/Message';

export interface SendMessagesConfirmationDialog extends DialogProps {
  emailMessages: Message[];
}

export function InsufficientSmsCreditDialogSMSAndEmail({
  onClose,
  emailMessages
}: SendMessagesConfirmationDialog) {
  const bulkSend = useBulkSend();
  return (
    <ConfirmationDialog
      title='Insufficient SMS credit'
      size='s'
      onClose={onClose}
      message={
        <>
          <Body>
            You do not have enough available credit to send these SMS messages.
            Please{' '}
            <a href='mailto:support@rexpm.zendesk.com'>contact support</a> to
            add credit to your account.
          </Body>
          <Body>
            You can still send{' '}
            <Bold>{pluralize('emails', emailMessages.length, true)}</Bold>.
          </Body>
          <Body>Do you want to continue?</Body>
        </>
      }
      confirmText='Yes, send emails now'
      cancelText='No, cancel and send all messages later'
      TitleIcon={InfoCircleIcon}
      onConfirm={() => bulkSend(emailMessages)}
    />
  );
}
