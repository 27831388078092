import React from 'react';

import { Field, HiddenField } from '@rexlabs/form';
import { CheckboxGroup } from '@rexlabs/checkbox';
import { ValueListItem } from 'utils/normaliser/value-list-item';

type Props = {
  templatedAttachments?: ValueListItem[];
};

export function TemplatedAttachments({ templatedAttachments }: Props) {
  return (
    <>
      {!!templatedAttachments?.length && (
        <Field
          id='include_generated_attachments'
          name='include_generated_attachments'
          label='Include generated attachments'
          Input={CheckboxGroup}
          inputProps={{
            options: templatedAttachments.map(({ id, label }) => ({
              value: id,
              label
            }))
          }}
        />
      )}
      <HiddenField name={'include_generated_attachments'} />
    </>
  );
}
