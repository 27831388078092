import { Link, LinkProps } from '@rexlabs/whereabouts';

import React from 'react';

import { RecordType } from 'data/models/types';
import { getRecordLinkProps } from './get-record-link-props';

export interface RecordLinkProps extends LinkProps {
  id?: string;
  type: RecordType;
  tab?: string;
  parentId?: string;
  children: React.ReactNode;
}

export function RecordLink({
  type,
  id,
  parentId,
  children,
  tab,
  ...props
}: RecordLinkProps) {
  if (!id) {
    return <>{children}</>;
  }

  return (
    <Link
      {...props}
      {...getRecordLinkProps({ id, type, parentId })}
      {...(tab ? { query: { tab } } : {})}
    >
      {children}
    </Link>
  );
}
