import { useGetPrimaryRecordActionGroup } from 'view/hooks/actions/use-get-primary-record-action-group';
import { PendingOwnershipDisbursement } from 'data/models/entities/financials/pending-disbursements/pending-ownership-disbursement';
import { RecordTypes } from 'data/models/types';
import { useTranslation } from 'react-i18next';
import { useGetDisburseFundsAction } from '../utils/disbursement-actions/use-get-disburse-action';

export function useGetPendingOwnershipDisbursementActions() {
  const { t } = useTranslation();
  const getPrimaryRecordActionGroup = useGetPrimaryRecordActionGroup();
  const getOwnershipPrimaryRecordActionGroup = useGetPrimaryRecordActionGroup();

  const getDisburseFundsAction = useGetDisburseFundsAction();

  return (pendingOwnershipDisbursement?: PendingOwnershipDisbursement) => {
    if (!pendingOwnershipDisbursement) return [];

    return [
      ...getPrimaryRecordActionGroup(
        'pending_ownership_disbursement',
        pendingOwnershipDisbursement.id,
        t('disbursements.actions.preview.label') as string
      ),
      ...getOwnershipPrimaryRecordActionGroup(
        'ownership',
        pendingOwnershipDisbursement.object!.id,
        'View ownership'
      ),
      getDisburseFundsAction({
        recordType: RecordTypes.Ownership,
        pendingDisbursement: pendingOwnershipDisbursement
      })
    ];
  };
}
