import React, { ComponentProps } from 'react';
import { StyleSheet, useStyles } from '@rexlabs/styling';

const defaultStyles = StyleSheet({
  layout: {
    '& > * + *': {
      marginTop: ({ token }) => token('spacing.xl')
    }
  }
});

export function VerticalFormLayout({
  children,
  ...props
}: ComponentProps<'div'>) {
  const s = useStyles(defaultStyles);

  return (
    <div {...props} {...s('layout')}>
      {children}
    </div>
  );
}
