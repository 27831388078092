import { useQuery } from 'react-query';
import { Contact } from 'src/modules/contacts/types/contact-types';
import { api } from 'utils/api/api-client';
import { CommunicationContextType } from '../../common/types/communication-context-type';

export function useRecipientGroupContacts(
  contextType: CommunicationContextType,
  contextId: string,
  recipientGroupName?: string
) {
  const { data, isLoading } = useQuery<Contact[]>(
    [contextType, contextId, recipientGroupName],
    async () => {
      return api
        .get(
          `/communications/contexts/${contextType}/${contextId}/${recipientGroupName}`
        )
        .then((res) => res.data);
    },
    {
      enabled: !!(contextType && contextId && recipientGroupName),
      initialData: []
    }
  );

  return { data, isLoading };
}
