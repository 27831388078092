import { BlockConfig } from 'view/components/record-screen/types';

import { InspectionFormData } from '../../types/InspectionFormData';
import { InspectionTask } from '../../types/InspectionTask';

export const entryExitPropertyDetailsHeaderBlock: BlockConfig<
  InspectionTask,
  any,
  InspectionFormData
> = {
  id: 'entry-exit-property-details-header',
  // Style the header so that it effectively splits the blocks
  title: 'Property details'
};
