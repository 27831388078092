import React from 'react';

import { Card } from 'view/components/card';
import ROUTES from 'routes/app';
import { ListScreen } from 'view/components/list-screen/list-screen';
import { usePropertyFilters } from '../hooks/use-property-filters';
import { PropertyTable } from '../components/property-table';

export const PropertiesList = () => {
  const { actions, activeFilter } = usePropertyFilters();

  return (
    <ListScreen
      title='Properties'
      recordType='property'
      createLabel='Create property'
      createLinkProps={{ to: ROUTES.PROPERTIES_LIST.CREATE }}
      actions={actions}
    >
      <Card>
        <PropertyTable activeFilter={activeFilter} />
      </Card>
    </ListScreen>
  );
};
