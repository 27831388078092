import * as React from 'react';
import { ActionDeclaration } from 'src/modules/common/actions/types/action-declaration-types';
import { RecordTypes } from 'data/models/types';
import { getRecordTitle } from 'utils/records/get-record-title';
import { useToast } from 'view/components/@luna/notifications/toast';
import { useModelActions } from '@rexlabs/model-generator';
import { servicePackagesModel } from 'src/modules/service-packages/models/service-packages-model';
import { ServicePackage } from 'src/modules/service-packages/types/service-package';

export function useGetDuplicateServicePackageAction() {
  const { addToast } = useToast();
  const { refreshLists, duplicateItem } = useModelActions(servicePackagesModel);

  return (servicePackage: ServicePackage): ActionDeclaration => {
    return {
      id: 'duplicate-service-package',
      group: 'service-packages',
      label: 'Duplicate',
      handleAction: async () => {
        const servicePackageDuplicateResponse = await duplicateItem({
          servicePackage: servicePackage
        });

        if (servicePackageDuplicateResponse !== false) {
          addToast({
            type: 'success',
            description: (
              <>
                <b>
                  {getRecordTitle({
                    type: RecordTypes.ServicePackage,
                    object: servicePackage
                  })}
                </b>{' '}
                has been <b>successfully</b> duplicated
              </>
            )
          });

          refreshLists();
        }
      }
    };
  };
}
