import React, { useMemo } from 'react';
import {
  margin,
  padding,
  StyleSheet,
  useStyles,
  useToken
} from '@rexlabs/styling';
import Box from '@rexlabs/box';
import { Field, Form, ReactForms } from '@rexlabs/form';

import { formatCurrency } from 'utils/formatters';
import { StandardDialogFooter } from 'view/components/dialogs/components/standard-dialog-footer';
import { GhostButton, PrimaryButton } from '@rexlabs/button';
import { PrepaymentBucketSelect } from 'view/components/inputs/selects/v4-selects/prepayment-bucket-select';
import { BankAccount } from 'data/models/entities/financials/bank-accounts';
import { Tenancy } from 'data/models/entities/tenancies';
import { TextArea } from 'view/components/inputs/text-area/text-area';
import { TenancyPrepaymentBucket } from 'src/modules/prepayments/types/tenancy-prepayment-bucket';
import { Grid } from 'view/components/@luna/form/grid';
import { Heading } from '@rexlabs/text';
import { CentAmountInput } from 'view/components/inputs/cent-amount';

const styles = StyleSheet({
  container: {
    width: '376px'
  },
  footerContainer: {
    ...margin.styles({
      x: ({ token }) => `-${token('spacing.m')}`,
      bottom: ({ token }) => `-${token('spacing.m')}`,
      top: 'm'
    }),
    footer: {
      ...padding.styles({
        all: 'm'
      })
    }
  }
});

export interface TransferBucketsPopoutFormValues {
  amount: number;
  reason: string;
  from: TenancyPrepaymentBucket;
  to: TenancyPrepaymentBucket;
}

export interface TransferBucketsPopoutFormProps {
  onClose: () => void;
  onSubmit: (formValues: TransferBucketsPopoutFormValues) => void;
  bankAccount?: BankAccount;
  tenancy?: Tenancy;
  bucketToTransferTo?: TenancyPrepaymentBucket;
  bucketToTransferFrom?: TenancyPrepaymentBucket;
}

const validate = {
  definitions: {
    amount: {
      name: 'amount',
      rules: 'required|less_than_or_equal_to_field:from.amount'
    },
    reason: { name: 'reason', rules: 'required|string' },
    from: { name: 'from bucket', rules: 'required' },
    to: { name: 'to bucket', rules: 'required' }
  },
  messages: {
    less_than_or_equal_to_field:
      'Amount must not exceed amount available in bucket.'
  }
};

export function TransferBucketsPopoutForm({
  onClose,
  onSubmit,
  bankAccount,
  tenancy,
  bucketToTransferTo,
  bucketToTransferFrom
}: TransferBucketsPopoutFormProps) {
  const s = useStyles(styles);
  const token = useToken();

  const initialValues = useMemo(
    () => ({
      to: bucketToTransferTo,
      from: bucketToTransferFrom
    }),
    []
  );

  return (
    <Box p={token('spacing.xs')} {...s('container')}>
      <ReactForms<TransferBucketsPopoutFormValues, any>
        validate={validate}
        handleSubmit={onSubmit}
        initialValues={initialValues}
      >
        {({ submitForm, isSubmitting, values }) => {
          const handleSubmitAndClose = async () => {
            const result = await submitForm();

            if (!result) return;

            onClose?.();
          };

          return (
            <Form>
              <Grid columns={1}>
                <Heading level={2}>Move funds</Heading>

                <Field
                  id={'from_bucket'}
                  name={'from'}
                  label={'From bucket'}
                  Input={PrepaymentBucketSelect}
                  description={
                    values.from &&
                    `${formatCurrency(values.from?.amount || '0')} available`
                  }
                  inputProps={{
                    tenancyId: tenancy?.id,
                    bankAccountId: bankAccount?.id
                  }}
                />

                <Field
                  id={'to_bucket'}
                  name={'to'}
                  label={'To bucket'}
                  Input={PrepaymentBucketSelect}
                  inputProps={{
                    tenancyId: tenancy?.id,
                    bankAccountId: bankAccount?.id
                  }}
                />

                <Field
                  name={'amount'}
                  label={'Amount'}
                  Input={CentAmountInput}
                />

                <Field
                  name='reason'
                  Input={TextArea}
                  label={'Reason for moving funds'}
                />
              </Grid>
              <div {...s('footerContainer')}>
                {/* when this popout is placed inside another form (e.g. in a dialog),
                 the popout's submit button triggers the dialog validation, unless I provide a button
                  which is not marked as submit.*/}
                <StandardDialogFooter>
                  <GhostButton onClick={onClose}>Cancel</GhostButton>

                  <PrimaryButton
                    data-testid='popout-submit-button'
                    onClick={handleSubmitAndClose}
                    isLoading={isSubmitting}
                  >
                    Move funds
                  </PrimaryButton>
                </StandardDialogFooter>
              </div>
            </Form>
          );
        }}
      </ReactForms>
    </Box>
  );
}
