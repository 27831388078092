import * as React from 'react';
import { noop } from 'lodash';

const AppWideFilterContext = React.createContext({
  updateAppWideFilterKey: noop,
  appWideFilterKey: 0
});

export function useAppWideFilterContext() {
  return React.useContext(AppWideFilterContext);
}

export function AppWideFilterProvider({
  children
}: {
  children: React.ReactNode;
}) {
  const [appWideFilterKey, setAppWideFilterKey] = React.useState(0);
  const updateAppWideFilterKey = React.useCallback(() => {
    setAppWideFilterKey((prev) => prev + 1);
  }, [setAppWideFilterKey]);

  return (
    <AppWideFilterContext.Provider
      value={{ appWideFilterKey, updateAppWideFilterKey }}
    >
      {children}
    </AppWideFilterContext.Provider>
  );
}
