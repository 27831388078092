import React from 'react';
import { BlockConfig } from 'view/components/record-screen/types';
import { Column, Grid } from '@rexlabs/grid';
import { Field } from '@rexlabs/form';
import { PasswordInput } from '@rexlabs/password-input';

export const changePasswordBlock: BlockConfig = {
  id: 'changePassword',
  title: 'Password',
  validate: {
    definitions: {
      password: { rules: 'required' },
      new_password: { rules: 'required|min:6' },
      new_password_confirmation: { rules: 'required|min:6' }
    }
  },
  // new_password and new_password_confirmation are switched here because of validation flow
  Edit: () => (
    <Grid>
      <Column width={12}>
        <Field name='password' label='Current password' Input={PasswordInput} />
      </Column>

      <Column width={12}>
        <Field
          name='new_password_confirmation'
          label='New password'
          Input={PasswordInput}
        />
      </Column>
      <Column width={12}>
        <Field
          name='new_password'
          label='Confirm password'
          Input={PasswordInput}
        />
      </Column>
    </Grid>
  )
};
