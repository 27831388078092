import React from 'react';
import { StyleSheet, text, useStyles } from '@rexlabs/styling';

// Styles pulled straight from vivid
const styles = StyleSheet({
  row: {
    // This let's us use any html element, including anchor tags, as table rows
    // Has full browser support: https://caniuse.com/#feat=mdn-css_properties_display_table_values
    display: 'table-row',

    ...text.styles({
      target: 'table.row',
      color: () => 'inherit',
      textDecoration: () => 'none'
    }),

    '&:hover': {
      ...text.styles({
        target: 'table.row.hover',
        color: () => 'inherit'
      })
    }
  }
});

export function Row({
  children,
  ...rest
}: React.HTMLAttributes<HTMLTableRowElement>) {
  const s = useStyles(styles);

  return (
    <tr {...s('row')} {...rest}>
      {children}
    </tr>
  );
}
