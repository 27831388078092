import React from 'react';
import { reduce } from 'lodash';

import { Field } from '@rexlabs/form';
import { RadioGroupInput } from '@rexlabs/radio-input';

import { pluralize } from 'utils/formatters';
import { formatDate } from 'utils/dates/format';

import { BlockConfig } from 'view/components/record-screen/types';
import { useFeatureFlags } from 'view/components/@luna/feature-flags';
import { FLAGS } from 'utils/feature-flags';
import { InspectionReportRoom } from 'src/modules/tasks/inspections/types/InspectionReportRoom';
import { InspectionArea } from 'src/modules/tasks/inspections/entry-exit/types/InspectionArea';
import { useGetIsEntryExit } from 'src/modules/tasks/inspections/entry-exit/hooks/use-get-is-entry-exit';
import { InspectionTask } from '../types/InspectionTask';

export type ImportPreviousReportBlockFormValues = {
  previous_inspection: string;
};

function getTotalAreaItemsWithNotesCount(areas: InspectionArea[]) {
  return areas
    .flatMap((area) => area.items?.data)
    .filter((item) => !!item?.notes).length;
}

function getTotalImageCountForItems(areas: InspectionArea[]) {
  return areas
    .flatMap((area) => area.items?.data)
    .reduce((sum, item) => sum + (item?.images.data || []).length, 0);
}

function getTotalAreasWithNotesCount(
  areas: (InspectionReportRoom | InspectionArea)[]
) {
  return areas.filter((area) => area.notes).length;
}

function getTotalImageCount(areas: (InspectionReportRoom | InspectionArea)[]) {
  return reduce(areas, (sum, area) => sum + (area.images.data || []).length, 0);
}

function useGetSelectItem() {
  const { hasFeature } = useFeatureFlags();
  const getIsEntryExit = useGetIsEntryExit();

  return (previousReport: InspectionTask) => {
    const isEntryExit = getIsEntryExit(previousReport.details?.type.id);
    const closedAt = previousReport.closed_at
      ? formatDate(previousReport.closed_at)
      : '--';

    let comments = '';
    let images = '';

    if (hasFeature(FLAGS.ENTRY_EXIT_INSPECTION_REPORT)) {
      const data = previousReport.details?.areas?.data;
      const notesCount = isEntryExit
        ? getTotalAreaItemsWithNotesCount(data || [])
        : getTotalAreasWithNotesCount(data || []);

      comments = pluralize('comments', notesCount || 0, true);

      const imagesCount = isEntryExit
        ? getTotalImageCountForItems(data || [])
        : getTotalImageCount(data || []);
      images = pluralize('images', imagesCount || 0, true);
    } else {
      const data = previousReport.details?.rooms?.data;

      const notesCount = getTotalAreasWithNotesCount(data || []);
      comments = pluralize('comments', notesCount || 0, true);

      const imagesCount = getTotalImageCount(data || []);
      images = pluralize('images', imagesCount || 0, true);
    }

    const description = [closedAt, comments, images].join(' • ');

    return {
      value: previousReport.id,
      label: previousReport.summary,
      props: {
        description
      }
    };
  };
}

export const importPreviousReportBlock: BlockConfig<
  InspectionTask[],
  any,
  ImportPreviousReportBlockFormValues
> = {
  id: 'import-report-data',
  title: 'Import report data',
  Edit: ({ data }) => {
    const getSelectItem = useGetSelectItem();
    const items = data?.map(getSelectItem);

    return (
      <Field
        name='previous_inspection'
        optional={false}
        Input={RadioGroupInput}
        inputProps={{ options: items, orientation: 'vertical' }}
      />
    );
  }
};
