import React from 'react';
import { Field } from '@rexlabs/form';
import { BlockConfig } from 'view/components/record-screen/types';
import { Grid } from 'view/components/@luna/form/grid';
import { PropertyComplianceCategorySelect } from 'view/components/inputs/selects/v4-selects/property-compliance-category';
import { Property } from '../types/property-types';

export const propertyComplianceBlock: BlockConfig<Property> = {
  id: 'property-compliance',
  title: 'Property compliance',
  Edit() {
    return (
      <Grid columns={1}>
        <Field
          name='compliance_categories'
          label='Categories'
          Input={PropertyComplianceCategorySelect}
          inputProps={{ multi: true }}
        />
      </Grid>
    );
  }
};
