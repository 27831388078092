export const MESSAGES_FLAGS = {
  MESSAGES: 'messages',
  BULK_MESSAGES: 'bulk_messages',
  MESSAGE_ATTACHMENTS: 'message_attachments',
  EMAIL_SIGNATURE: 'email_signature',
  LETTERS: 'communication_letters',
  // bulk_message_action is an action in the message outbox to select a number of existing messages to send
  // This is different from bulk messages, which involves selecting a number of entities to send a message to
  BULK_MESSAGE_ACTION: 'bulk_message_action',
  ATTACH_DOCUMENTS_FROM_A_RECORD: 'attach_documents_from_a_record',
  ATTACH_FILES_FROM_A_RECORD: 'attach_files_from_a_record'
};
