import React, { useMemo } from 'react';
import { DocumentListProps } from 'src/modules/documents/components/connectors/document-list';
import { SearchResultItem } from 'utils/api/get-search-results';
import { DocumentRelatedContextObject } from 'src/modules/communications/messages/types/attach-document-from-a-record';
import { ActionButtons } from 'view/components/action-buttons';
import {
  transformActionDeclarationsToActionMenuItems,
  transformSingleActionsToRegularActionMenuItems
} from 'utils/actions/transforms';
import { useGetAddDocumentsAction } from 'src/modules/documents/actions/use-get-add-documents-action';
import Box from '@rexlabs/box';
import { Heading } from '@rexlabs/text';
import { Document } from 'data/models/entities/documents';
import { ListTable } from 'view/components/table';
import { documentColumns } from 'src/modules/documents/components/ui/documents-table';
import { useDocumentListQuery } from 'src/modules/documents/hooks/use-get-document-list-query';
import { useDocumentActions } from 'src/modules/documents/actions/use-document-actions';

const mapAttributes = (
  relatedRecord: SearchResultItem<DocumentRelatedContextObject>
): Omit<DocumentListProps, 'record'> => {
  return {
    attachedTo: [
      {
        id: relatedRecord.id,
        label: relatedRecord.label,
        type: { id: relatedRecord.type.id }
      }
    ],
    [`${relatedRecord.type.id}Id`]: relatedRecord.id
  };
};

type Props = {
  relatedRecord: SearchResultItem<DocumentRelatedContextObject>;
  onSelectionChange?: (selectedItems: Document[]) => void;
};

export const DocumentsFromRelatedRecord = ({
  relatedRecord,
  onSelectionChange
}: Props) => {
  const {
    propertyId,
    contactId,
    ownershipId,
    tenancyId,
    taskId,
    attachedTo
  } = useMemo(() => mapAttributes(relatedRecord), [relatedRecord]);

  const getAddDocumentAction = useGetAddDocumentsAction();
  const actions = useDocumentActions();

  const query = useDocumentListQuery({
    propertyId,
    contactId,
    ownershipId,
    tenancyId,
    taskId
  });

  return (
    <>
      <Box
        flexDirection='row'
        alignItems='center'
        justifyContent='space-between'
        sx='1.2rem'
        flex={1}
      >
        <Box>
          <Heading level={3}>Documents</Heading>
        </Box>
        <Box ml='2.4rem'>
          <ActionButtons
            actions={transformSingleActionsToRegularActionMenuItems([
              getAddDocumentAction({
                data: relatedRecord.record,
                attachedTo,
                label: 'Add document to this record'
              })
            ])}
          />
        </Box>
      </Box>
      <ListTable
        id='documents-list'
        hideToolbar={true}
        hasColumnSelection={true}
        onSelectionChange={onSelectionChange}
        hidePagination={true}
        columns={documentColumns}
        getQuery={() => query}
        Empty={() => <span>No documents found on this record.</span>}
        emptyWithoutTable={true}
        getActionMenuItems={({ item }) =>
          transformActionDeclarationsToActionMenuItems(actions(item))
        }
      />
    </>
  );
};
