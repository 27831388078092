import React from 'react';
import {
  SimplePagination,
  TableProps,
  ToolbarProps,
  UseUrlStateArgs
} from '@rexlabs/table';
import invariant from 'invariant';
import { TableProvider, Columns, TableProviderProps } from './provider';
import { Table } from './table';
import { Toolbar } from './toolbar';
import { useListTableActions } from './hooks/use-list-table-actions';

export interface ListTableCommonProps<T extends any>
  extends ToolbarProps,
    Omit<TableProps, 'children'>,
    Omit<UseUrlStateArgs, 'initialGlobalFilter' | 'initialSortBy'>,
    Partial<Omit<TableProviderProps<T>, 'columns'>> {
  columns?: Columns;
  hideToolbar?: boolean;
  hidePagination?: boolean;
  hideBulkActions?: boolean;
  alwaysExpandSearch?: boolean;
  emptyWithoutTable?: boolean;
  shouldPersistTableSettings?: boolean;
  shouldUseAppWideFilter?: boolean;
  blacklistTableSettingKeys?: Array<string>;
}

export function ListTableInner<T>({
  emptyWithoutTable = false,
  hashParamKey,
  Empty,
  initialHiddenColumns,
  useControlledState,
  Loading,
  tableStateProps,
  urlStateProps,
  entity,
  columns,
  forcedGlobalFilter,
  hideToolbar,
  hidePagination,
  hideBulkActions,
  droppableId,
  striped,
  suggestedFilters,
  alwaysExpandSearch = false,
  getBulkActions: propGetBulkActions,
  getActionMenuItems: propGetActionMenuItems,
  ...props
}: ListTableCommonProps<T> & {
  entity: any;
  tableStateProps: any;
  urlStateProps: any;
}) {
  const { getActionMenuItems, getBulkActions } = useListTableActions({
    entity,
    columns,
    getBulkActions: propGetBulkActions,
    getActionMenuItems: propGetActionMenuItems
  });

  const showLoadingWithoutTable =
    tableStateProps.isLoading && Loading != undefined && emptyWithoutTable;
  const showEmptyWithoutTable =
    !tableStateProps.isLoading &&
    !tableStateProps.items.length &&
    Empty != undefined &&
    emptyWithoutTable;

  const hiddenFilters =
    forcedGlobalFilter?.map((forcedFilter) => forcedFilter.field) ?? [];

  return (
    <TableProvider
      {...props}
      {...urlStateProps}
      {...(props.items
        ? {
            allItems: props.items
          }
        : tableStateProps)}
      hashParamKey={hashParamKey}
      columns={columns}
      forcedGlobalFilter={forcedGlobalFilter}
      initialHiddenColumns={initialHiddenColumns}
      useControlledState={useControlledState}
      getBulkActions={hideBulkActions ? undefined : getBulkActions}
      getActionMenuItems={
        propGetActionMenuItems ? getActionMenuItems : undefined
      }
    >
      <ReplaceTableStates
        showLoadingWithoutTable={showLoadingWithoutTable}
        showEmptyWithoutTable={showEmptyWithoutTable}
        Loading={Loading}
        Empty={Empty}
      >
        {!hideToolbar && (
          <Toolbar
            alwaysExpandSearch={alwaysExpandSearch}
            suggestedFilters={suggestedFilters}
            hiddenFilters={hiddenFilters}
          />
        )}
        <Table
          droppableId={droppableId}
          Empty={Empty}
          Loading={Loading}
          striped={striped}
        />
        {!hidePagination && <SimplePagination />}
      </ReplaceTableStates>
    </TableProvider>
  );
}

export const PAGE_SIZES = [20, 50, 100];

interface ReplaceTableStatesProps {
  children: React.ReactNode;
  showLoadingWithoutTable: boolean;
  showEmptyWithoutTable: boolean;
  Loading: TableProps['Loading'];
  Empty: TableProps['Empty'];
}

function ReplaceTableStates(props: ReplaceTableStatesProps) {
  const {
    children,
    showLoadingWithoutTable,
    showEmptyWithoutTable,
    Loading,
    Empty
  } = props;

  if (showLoadingWithoutTable) {
    invariant(Loading, `Loading prop can't be undefined`);
    return <Loading />;
  }

  if (showEmptyWithoutTable) {
    invariant(Empty, `Empty prop can't be undefined`);
    return <Empty />;
  }

  return <>{children}</>;
}
