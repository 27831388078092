import React, { useCallback } from 'react';
import { DialogProps } from '@rexlabs/dialog';
import { useModelActions } from '@rexlabs/model-generator';

import { Ownership } from 'data/models/entities/ownerships';
import { propertyOwnershipModel } from 'src/modules/property-ownerships/models/property-ownership-model';
import { RecordSubmitHandler } from 'view/components/record-screen/utils';

import { RecordDialog } from 'view/components/record-screen/dialog/dialog';
import { Property } from 'src/modules/properties/types/property-types';
import { PropertyOwnership } from 'src/modules/property-ownerships/types/property-ownership-types';
import { useRecordCreatedToast } from 'src/modules/common/toasts/hooks/use-record-created-toast';
import ROUTES from 'routes/app';
import { push } from '@rexlabs/whereabouts';
import { useTranslation } from 'react-i18next';
import { useSettings } from 'src/modules/settings/hooks/useSettings';
import { propertyOwnershipOverviewBlock } from '../blocks/property-ownership-overview';

export interface CreatePropertyOwnershipDialogProps extends DialogProps {
  property?: Property;
  ownership?: Ownership;
  isFixture?: boolean;
  onCreate?: (propertyOwnership: PropertyOwnership) => void;
}

const content = [
  {
    id: 'overview',
    label: 'Overview',
    blocks: [propertyOwnershipOverviewBlock]
  }
];

export function CreatePropertyOwnershipDialog({
  property,
  ownership,
  onClose,
  onCreate
}: CreatePropertyOwnershipDialogProps) {
  const { t } = useTranslation();

  const settingsData = useSettings();
  const defaultServicePackage =
    settingsData['default-service-package'].default_service_package;

  const { createItem: createPropertyOwnership } = useModelActions(
    propertyOwnershipModel
  );

  const addToast = useRecordCreatedToast(propertyOwnershipModel, {
    actions: []
  });

  const initialValues = {
    property,
    ownership,
    service_package:
      defaultServicePackage?.id !== null
        ? {
            id: defaultServicePackage.id,
            name: defaultServicePackage.label
          }
        : null
  };

  const handleSubmit: RecordSubmitHandler = useCallback(
    async ({ values }) => {
      const { ...otherValues } = values;

      const {
        data: propertyOwnershipData,
        // @ts-ignore - the `args` property is present even though the type says it is not
        args
      } = await createPropertyOwnership({
        data: {
          ...otherValues
        },
        args: {
          include: 'maintenance_instructions'
        }
      });

      const propertyOwnership: PropertyOwnership = {
        ...propertyOwnershipData,
        ownership: args.ownership
      };

      onCreate?.(propertyOwnership);

      await addToast(propertyOwnership);

      // set the newly created propertyTenancy in the url params
      push(ROUTES.PROPERTY, {
        query: {
          tab: 'management-agreements',
          managementAgreement: propertyOwnership.id
        },
        params: { propertyId: values.property.id }
      });

      return propertyOwnership;
    },
    [createPropertyOwnership, onCreate, addToast]
  );

  return (
    <RecordDialog
      title={t(
        'property-ownerships.management-agreement.create.label.singular'
      )}
      content={content}
      data={initialValues}
      handleSubmit={handleSubmit}
      onClose={onClose}
    />
  );
}
