import React from 'react';

import { RecordDialog } from 'view/components/record-screen/dialog/dialog';
import { DialogContentConfig } from 'view/components/record-screen/dialog/types';

import { keySetDetailsBlock } from '../blocks/key-set-details-block';
import { AddKeySetDialogButtonGroup } from '../components/add-key-set-dialog-button-group';

const content: DialogContentConfig = [
  {
    id: 'key-details',
    label: 'Key Details',
    blocks: [keySetDetailsBlock]
  }
];

export function AddKeySetDialog({
  onClose,
  handleSubmit
}: Pick<
  React.ComponentProps<typeof RecordDialog>,
  'onClose' | 'handleSubmit'
>) {
  return (
    <RecordDialog
      title='Add key set'
      data={{ images: [] }}
      onClose={onClose}
      content={content}
      handleSubmit={handleSubmit}
      ButtonGroup={AddKeySetDialogButtonGroup}
    />
  );
}
