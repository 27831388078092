import React, { ComponentProps } from 'react';
import { useQuery } from 'react-query';

import { getSearchQuery } from 'src/lib/react-query/hooks/use-records-query';

import { api } from 'utils/api/api-client';

import { NumberBadge } from 'view/components/badge';

async function fetchRecords({ queryKey }) {
  const [path, query] = queryKey;

  const htmlSearchParams = getSearchQuery(query);

  return api.get(`${path}?${htmlSearchParams}`);
}

export function RecordCountBadge({
  queryKey,
  search,
  isActive
}: Omit<ComponentProps<typeof NumberBadge>, 'number'> & {
  queryKey: 'tenancies' | 'ownerships' | 'contacts';
  search: string;
}) {
  const { data } = useQuery(
    [
      queryKey,
      {
        search,
        // Just looking for the total count, so record count doesn't matter
        perPage: 1
      }
    ],
    fetchRecords,
    {
      refetchOnWindowFocus: false
    }
  );

  // This doesn't exist in the types for AxiosResponse, but is there in the API response
  const total = data?.['pagination']?.total;

  return total ? <NumberBadge number={total} isActive={isActive} /> : null;
}
