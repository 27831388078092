import { push } from '@rexlabs/whereabouts';
import React from 'react';
import ROUTES from 'routes/app';
import Box from '@rexlabs/box';
import { LinkButton } from '@rexlabs/button';
import { Message } from 'view/components/@luna/message';
import PasswordErrorSvg from 'assets/illustrations/password-error.svg';
import icons from '@rexlabs/icons';

const { ChevronLeft } = icons;

export const UserInvitationExpiredScreen = () => {
  return (
    <>
      <Box mb='2rem' data-testid='forgot-password-success'>
        <LinkButton onClick={() => push(ROUTES.LOGIN)} IconLeft={ChevronLeft}>
          Back to login
        </LinkButton>
      </Box>
      <Message
        grey
        title='Invitation has expired'
        Illustration={PasswordErrorSvg}
      >
        This invite token has expired. Please contact your account administrator
        to issue a new invitation link.
      </Message>
    </>
  );
};
