import React, { PropsWithChildren } from 'react';
import { useFeatureFlags } from '../@luna/feature-flags';

const DEVELOPER_FEATURE_NAME = 'developer';

export function useIsDeveloper() {
  const { hasFeature } = useFeatureFlags();
  return hasFeature(DEVELOPER_FEATURE_NAME);
}

export function IsDeveloperSwitch({ children }: PropsWithChildren<any>) {
  const isDeveloper = useIsDeveloper();

  if (!isDeveloper) return null;
  return <>{children}</>;
}
