import React from 'react';
import {
  ButtonGroupInput,
  ButtonGroupItem
} from 'view/components/@luna/button-group-input';
import { TaskPriorityIcon } from 'src/modules/tasks/common/components/task-priority-icon';
import { TaskPriority } from 'src/modules/tasks/common/types/TaskPriority';
import Box from '@rexlabs/box';

type TaskPriorityItem = { id: TaskPriority; label: string };

const items: TaskPriorityItem[] = [
  { id: 'preventative', label: 'Preventative' },
  { id: 'normal', label: 'Normal' },
  { id: 'urgent', label: 'Urgent' },
  { id: 'emergency', label: 'Emergency' }
];

// Normaliser to map the dev's items to the components internal structure
const normaliser = (
  item: TaskPriorityItem
): ButtonGroupItem<TaskPriorityItem> => ({
  value: item,
  label: item.label,
  Icon: () => <TaskPriorityIcon priority={item.id} />
});

export function TaskPriorityButtonGroup(props) {
  return (
    <Box width={'560px'}>
      <ButtonGroupInput items={items} normaliser={normaliser} {...props} />
    </Box>
  );
}
