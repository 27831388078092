import React, { useCallback } from 'react';

import { DialogProps } from '@rexlabs/dialog';
import { useModelActions } from '@rexlabs/model-generator';

import { useToast } from 'view/components/@luna/notifications/toast';
import { RecordDialog } from 'view/components/record-screen/dialog/dialog';
import { RecordSubmitHandler } from 'view/components/record-screen/utils';
import { propertiesModel } from 'data/models/entities/properties';

import { addPropertiesBlock } from '../blocks/add-properties';

const content = [
  {
    id: 'add-property',
    label: 'Add property',
    blocks: [addPropertiesBlock]
  }
];

interface AddPropertiesDialogProps extends DialogProps {
  portfolioId: string;
}

export function AddPropertiesDialog({
  onClose,
  portfolioId
}: AddPropertiesDialogProps) {
  const { addToast } = useToast();
  const { updateItem, refreshLists } = useModelActions(propertiesModel);

  const handleSubmit: RecordSubmitHandler = useCallback(
    async ({ values }) => {
      const responses = await Promise.all(
        values.properties?.map?.((property) =>
          updateItem({
            id: property.id,
            data: { portfolio: { id: portfolioId } }
          })
        )
      );

      addToast({ description: 'Added properties to portfolio' });
      await refreshLists();
      return responses;
    },
    [updateItem, refreshLists, addToast]
  );

  return (
    <RecordDialog
      title='Add properties'
      onClose={onClose}
      content={content}
      handleSubmit={handleSubmit}
    />
  );
}
