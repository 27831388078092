import React from 'react';

import { useConfirmationDialog } from '@rexlabs/dialog';

import { SingleActionDeclaration } from 'src/modules/common/actions/types/action-declaration-types';

import { getFinancialYearAbbreviationString } from '../utils/get-financial-year-abbreviation-string';
import { TaxSummary } from '../models/tax-summaries-model';
import { getQuarterNumberFromQuarterStart } from '../utils/get-quarter-number-from-quarter-start';
import { useGetFinancialYearStartAndEnd } from './use-get-financial-year-start-and-end';
import { useGetCreateTaxPaymentSubmitHandler } from './use-get-create-tax-payment-submit-handler';

export function useGetCreateTaxPaymentAction() {
  const { open } = useConfirmationDialog();
  const getCreateTaxPaymentSubmitHandler = useGetCreateTaxPaymentSubmitHandler();
  const getFinancialYearStartAndEnd = useGetFinancialYearStartAndEnd();

  return (taxSummary: TaxSummary): SingleActionDeclaration => {
    const { startDate, endDate } = getFinancialYearStartAndEnd(
      taxSummary.start_date
    );

    const currentQuarter = getQuarterNumberFromQuarterStart(
      startDate,
      taxSummary.start_date
    );

    const submitHandler = getCreateTaxPaymentSubmitHandler([taxSummary]);

    const message = (
      <>
        <p>
          <>
            Please confirm that you want to issue payments to HMRC for NRLQ{' '}
            {currentQuarter}{' '}
            {getFinancialYearAbbreviationString(startDate, endDate)} for{' '}
            <b>{taxSummary.contact.display_name}</b> for the ownership:{' '}
            <b>{taxSummary.ownership.display_name}</b>.
          </>
        </p>
        <p>
          This will create a withdrawal trust journal entry for the tax amount
          due, from the funds that are being withheld for this ownership.
        </p>
      </>
    );
    return {
      label: 'Issue payment',
      handleAction: () =>
        open({
          confirmText: 'Issue payment',
          title: 'Issue payment to HMRC',
          onConfirm: () => submitHandler(),
          message
        })
    };
  };
}
