import { noop } from 'lodash';

import { useDialog } from '@rexlabs/dialog';

import { SingleActionDeclaration } from 'src/modules/common/actions/types/action-declaration-types';
import { Contact } from 'src/modules/contacts/types/contact-types';

import { CreateManualSupplierCommissionEntryDialog } from '../dialogs/create-manual-supplier-commission-entry-dialog';

export function useGetCreateManualSupplierCommissionEntryAction() {
  const { open: openCreateDialog } = useDialog(
    CreateManualSupplierCommissionEntryDialog
  );

  return (folio: Contact): SingleActionDeclaration => ({
    label: 'Create entry',
    handleAction: () => {
      openCreateDialog({
        folio,
        submitHandler: noop
      });
    }
  });
}
