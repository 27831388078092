import React, { ComponentProps } from 'react';
import { StyleSheet, useStyles, padding } from '@rexlabs/styling';
import { ButtonProps, OutlineButton } from '@rexlabs/button';
import { Body } from '@rexlabs/text';
import { LinkProps } from '@rexlabs/whereabouts';
import { ViewLink } from '../components/view-link';

const defaultStyles = StyleSheet({
  container: {
    textAlign: 'center',

    ...padding.styles({
      bottom: 'm'
    })
  },
  content: {
    ...padding.styles({
      top: 'none',
      bottom: 'l'
    })
  }
});

interface EmptySection extends ComponentProps<'div'> {
  headline: React.ReactNode;
  buttonLabel: React.ReactNode;
  buttonProps?: ButtonProps;
  linkProps?: LinkProps;
}

export function EmptySection({
  children,
  buttonLabel,
  buttonProps,
  linkProps,
  ...props
}: EmptySection) {
  const s = useStyles(defaultStyles);
  return (
    <div {...props} {...s('container')}>
      {/* Quick design decision by Chris to remove the headline, he might decide to put them back in so ill leave that here */}
      {/* <Heading level={5}>{headline}</Heading> */}
      <Body {...s('content')} grey>
        {children}
      </Body>
      {buttonProps && (
        <OutlineButton {...linkProps}>{buttonLabel}</OutlineButton>
      )}
      {linkProps && (
        <ViewLink keepBreadcrumbs {...linkProps}>
          {buttonLabel}
        </ViewLink>
      )}
    </div>
  );
}
