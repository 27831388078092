import React, { useMemo } from 'react';
import { query } from '@rexlabs/model-generator';
import { Columns, ListTable, TabbedTable } from 'view/components/table';
import { agencyFeeModel } from 'src/modules/agency-fees/models/agency-fee';
import { transformActionDeclarationsToActionMenuItems } from 'utils/actions/transforms';
import { useGetAgencyFeesActions } from 'src/modules/agency-fees/actions/use-get-agency-fee-actions';
import { Tab } from 'view/components/table/tabbed';
import { LargeDescriptionCell } from 'view/components/cells/large-description-cell';
import { useTableFilters } from 'view/hooks/use-table-filters';
import { useHasServicePackagesFeature } from '../hooks/use-has-service-packages-feature';
import { formatDynamicAmount } from '../utils/format-dynamic-amount';
import { DynamicAmountTableCell } from './dynamic-amount-table-cell';
import { AmountTableCell } from './amount-table-cell';

const getColumns = (hasServicePackagesFeature = false): Columns => {
  return [
    {
      id: 'name',
      Header: 'Fee template name'
    },
    ...(hasServicePackagesFeature
      ? ([
          { id: 'entity_type', type: 'value', Header: 'Applies to' }
        ] as Columns)
      : []),
    {
      id: 'is_default_for_new',
      accessor: (item) => {
        if (item.entity_type?.id === 'ownership') {
          return item.is_default_for_new ? 'Yes' : 'No';
        }
        return '--';
      },
      Header: 'Apply to new ownerships',
      width: 210
    },
    {
      id: 'trigger_type',
      type: 'value',
      Header: 'Incurred when'
    },
    {
      id: 'agency_chart_of_accounts_account',
      type: 'chart_of_accounts_account',
      Header: 'Agency account'
    },
    {
      id: 'ownership_chart_of_accounts_account',
      type: 'chart_of_accounts_account',
      Header: 'Ownership account'
    },
    ...(!hasServicePackagesFeature
      ? ([
          {
            id: 'tax_type',
            type: 'value',
            Header: 'Tax type'
          },
          {
            id: 'is_default_for_new',
            accessor: (item) => (item.is_default_for_new ? 'Yes' : 'No'),
            Header: 'Default for new'
          },
          {
            id: 'fee_amount',
            Cell: DynamicAmountTableCell,
            cellProps: {
              align: 'right'
            },
            Header: 'Default rate'
          }
        ] as Columns)
      : [
          {
            accessor: (item) => item,
            Cell: (props) => (
              <AmountTableCell
                taxType={props.value?.tax_type}
                feeAmount={props.value?.fee_amount}
              ></AmountTableCell>
            ),
            cellProps: {
              align: 'right'
            },
            Header: 'Amount',
            toCsv: (item) =>
              `${formatDynamicAmount(item.fee_amount)} ${item.tax_type.label}`
          },
          {
            accessor: (item) => item,
            Header: 'Service package(s)',
            Cell: (props) => {
              const servicePackages = props.value?.service_packages?.data;

              return (
                <LargeDescriptionCell
                  lineClamp={2}
                  text={
                    servicePackages?.length > 0
                      ? servicePackages
                          .map((servicePackage) => servicePackage.name)
                          .join(', ')
                      : '--'
                  }
                ></LargeDescriptionCell>
              );
            },
            toCsv: (item) => {
              const servicePackages = item?.service_packages?.data;
              return servicePackages?.length > 0
                ? servicePackages
                    .map((servicePackage) => servicePackage.name)
                    .join(', ')
                : '--';
            }
          }
        ])
  ];
};

const getAgencyFeesQuery = () => query`{
  ${agencyFeeModel} {
    id
    name
    tax_type
    agency_chart_of_accounts_account
    ownership_chart_of_accounts_account
    service_packages
  }
}`;

export function AgencyFeesTabbedTable() {
  const { getFilters, getSort } = useTableFilters('agency-fees');
  const hasServicePackagesFeature = useHasServicePackagesFeature();
  const getAgencyFeesActions = useGetAgencyFeesActions();

  const columns = getColumns(hasServicePackagesFeature);

  const common = useMemo(
    () => ({
      columns,
      getFilters,
      getSort,
      getQuery: getAgencyFeesQuery,
      id: 'agency-fees',
      Table: ListTable,
      initialHiddenColumns: hasServicePackagesFeature
        ? [
            'agency_chart_of_accounts_account',
            'ownership_chart_of_accounts_account'
          ]
        : []
    }),
    [hasServicePackagesFeature]
  );

  const tabs = useMemo<Tab[]>(
    () => [
      {
        ...common,
        id: 'active-agency-fees',
        name: 'active',
        label: 'Active',
        forcedGlobalFilter: [
          {
            field: 'status_id',
            op: 'eq',
            value: 'active'
          }
        ],
        getActionMenuItems: ({ item }) =>
          transformActionDeclarationsToActionMenuItems(
            getAgencyFeesActions(item)
          )
      },
      {
        ...common,
        id: 'archived-agency-fees',
        name: 'archived',
        label: 'Archived',
        forcedGlobalFilter: [
          {
            field: 'status_id',
            op: 'eq',
            value: 'archived'
          }
        ],
        getActionMenuItems: ({ item }) =>
          transformActionDeclarationsToActionMenuItems(
            getAgencyFeesActions(item)
          )
      }
    ],
    [common]
  );

  return <TabbedTable tabs={tabs} />;
}
