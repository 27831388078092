import React from 'react';
import { BlockConfig } from 'view/components/record-screen/types';
import {
  ContactPaymentMethodForm,
  getPaymentMethodFormValidation
} from '../components/contact-payment-method-form';

export const createContactPaymentMethodBlock: BlockConfig = {
  id: 'create-contact-payment-method',
  title: 'Add payment method',
  validate: {
    definitions: {
      ...getPaymentMethodFormValidation()
    },
    messages: {
      required_if: 'The :attribute field is required.'
    }
  },
  Edit: ({ data, values, setFieldValue, blockProps }) => (
    <ContactPaymentMethodForm
      data={data}
      values={values}
      setFieldValue={setFieldValue}
      blockProps={blockProps}
    />
  )
};
