import faker from '@faker-js/faker';
import { ValueListValue } from 'data/models/types';
import { valueListFactory } from 'src/lib/testing/factories/value-list-factory';
import { ownershipFactory } from 'src/modules/ownerships/factories/ownership-factory';
import {
  AccessDetails,
  Property,
  PropertyManagementStatus,
  PropertyVacancyStatus
} from '../types/property-types';

export function propertyFactory(state: Partial<Property> = {}): Property {
  const address = faker.address.streetAddress();
  return {
    __record_type: 'property',
    address: null, // TODO: add this
    id: faker.datatype.uuid(),
    display_name: address,
    status: valueListFactory('active'),
    type: valueListFactory('residential'),
    created_at: new Date(),
    updated_at: new Date(),
    deleted_at: new Date(),
    access_details: {} as AccessDetails,
    ownership: ownershipFactory(),
    custom_record_reference: '',
    record_reference: '',
    system_record_reference: '',
    management_status: {} as ValueListValue<PropertyManagementStatus>,
    vacancy_status: {} as ValueListValue<PropertyVacancyStatus>,
    address_string: address,
    address_line_1: '',
    area_order: [],
    city: '',
    state: '',
    postcode: '',
    bathrooms_count: 2,
    bedrooms_count: 3,
    ensuites_count: 1,
    ...state
  };
}
