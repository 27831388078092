import { Columns } from 'src/view/components/table';
import { RelatesToComplexCell } from 'src/modules/communications/messages/components/relates-to-complex-cell';
import { Message } from '../types/Message';
import { getRecipientText } from './get-recipient-text';

export const messageColumns: Columns<Message> = [
  {
    id: 'updated_at',
    Header: 'Last updated at',
    type: 'datetime',
    accessor: (item) => item.updated_at
  },
  {
    id: 'recipient',
    Header: 'Recipient(s)',
    accessor: (item) => {
      return getRecipientText(item.recipients?.items || []);
    }
  },
  {
    id: 'context',
    Header: 'Message relates to',
    Cell: RelatesToComplexCell
  },
  {
    id: 'subject',
    Header: 'Subject',
    accessor: (item) => item.channels?.[0].resolved_email_subject
  },
  {
    id: 'message_format',
    Header: 'Message format',
    type: 'value',
    accessor: (item) => item.channels?.[0].channel_type
  },
  {
    id: 'send_from_author',
    type: 'author',
    Header: 'Sent from'
  }
];
