import React from 'react';
import { BlockConfig } from 'view/components/record-screen/types';
import { Column, Grid } from '@rexlabs/grid';
import { Value } from 'view/components/values';
import { RecordLinkValue } from 'view/components/values/record-link';
import { Field } from '@rexlabs/form';
import { TextInput } from '@rexlabs/text-input';
import { TrustLedger } from 'src/modules/trust-ledgers/types/trust-ledger-types';

export const detailsBlock: BlockConfig<TrustLedger> = {
  id: 'details',
  title: 'Details',
  validate: {
    definitions: {
      name: { rules: 'required' }
    }
  },
  View: ({ data }) => (
    <Grid>
      <Column width={6}>
        <Value label='Name' value={data?.name} />
      </Column>

      <Column width={6}>
        <Value
          label='Is default ledger'
          value={data!.is_default ? 'Yes' : 'No'}
        />
      </Column>

      <Column width={12}>
        <Value label='Description' value={data!.sub_name} />
      </Column>

      <Column width={12}>
        <RecordLinkValue
          label='Attached to'
          value={data!.object.label}
          type={data!.object.type.id}
          id={data!.object.id!}
        />
      </Column>

      <Column width={12}>
        <RecordLinkValue
          label='Bank account'
          record={{ type: 'bank_account', object: data?.bank_account }}
        />
      </Column>
    </Grid>
  ),
  Edit: ({ data }) => (
    <Grid>
      <Column width={6}>
        <Field name='name' label='Name' Input={TextInput} optional={false} />
      </Column>
      <Column width={12}>
        <Field name='sub_name' label='Description' Input={TextInput} />
      </Column>
      <Column width={12}>
        <Value label='Attached to' value={data?.object.label} />
      </Column>
      <Column width={12}>
        <Value label='Bank account' value={data?.bank_account?.name} />
      </Column>
    </Grid>
  )
};
