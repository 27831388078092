export function getNextPage({
  currentItemIndex,
  itemCount,
  manyFormConfigs,
  completedPageIds,
  setCurrentItemIndex
}) {
  let focusedPage = currentItemIndex;

  // Find the next page that isn't completed
  while (focusedPage >= 0 && focusedPage < itemCount) {
    // If the focusedPage is the last one in the list,
    // set the focusedPage back to the first page that is not completed.
    // Otherwise increase the focused page by one
    if (focusedPage === itemCount - 1) {
      focusedPage = 0;
    } else {
      focusedPage += 1;
    }

    const page = manyFormConfigs[focusedPage];

    if (page && !completedPageIds.includes(page.id)) {
      break;
    }
  }

  if (focusedPage >= 0 && focusedPage < itemCount) {
    setCurrentItemIndex(focusedPage);
  }
}
