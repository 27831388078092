import { Field } from '@rexlabs/form';
import { TextArea } from '@rexlabs/text-input';
import React from 'react';

export function EditCommentCell({ value }) {
  return (
    <Field
      id={`area_items.${value.id}.notes`}
      name={`area_items.${value.id}.notes`}
      Input={TextArea}
      inputProps={{
        placeholder: 'Enter comment...',
        'data-testid': `area_items.${value.id}.notes`
      }}
    />
  );
}
