/**
 * The record types that are supported when attaching documents from a record to a message
 */
export const SUPPORTED_RECORD_TYPES_FOR_SELECT_RELATES_TO = [
  'property',
  'contact',
  'ownership',
  'tenancy',
  'task'
] as const;
