import { useModelActions } from '@rexlabs/model-generator';
import { push } from '@rexlabs/whereabouts';
import ROUTES from 'routes/app';
import { useRecordCreatedToast } from 'src/modules/common/toasts/hooks/use-record-created-toast';
import { TaskType } from 'src/modules/tasks/common/types/TaskType';
import { RecordSubmitHandler } from 'view/components/record-screen/utils';
import { checklistTemplatesModel } from '../models/checklist-templates-model';
import { ChecklistTemplatesForm } from '../types/ChecklistTemplatesForm';

export function useCreateChecklistTemplate(type: TaskType) {
  const { refreshLists, createItem } = useModelActions(checklistTemplatesModel);

  const addCreatedToast = useRecordCreatedToast(checklistTemplatesModel, {
    actions: []
  });

  const onSubmit: RecordSubmitHandler<ChecklistTemplatesForm> = async ({
    values
  }) => {
    const isInspectionTaskChecklist = type === 'task_inspection';

    const response = await createItem({
      data: {
        label: values.label,
        description: values.description,
        is_default: false,
        task_type: {
          id: type
        },
        discriminator_field: isInspectionTaskChecklist
          ? 'details.type_id'
          : null,
        discriminator_value: isInspectionTaskChecklist
          ? values.inspection_type
          : null
      }
    });

    addCreatedToast(response.data);
    await refreshLists();

    push(ROUTES.CHECKLIST_DETAILS, {
      params: {
        checklistId: response.data.id
      }
    });

    return response.data;
  };

  return onSubmit;
}
