import * as React from 'react';
import { useStyles, StyleSheet } from '@rexlabs/styling';
import Box from '@rexlabs/box';
import { Intent } from 'src/modules/banking/batch-receipting/components/batch-receipting-card-layout';
import { ColoredCard } from 'view/components/cards/colored-card';
// This is a fairly reusable pattern but doesn't use the vivid component. Not sure where to put it.
const styles = StyleSheet({
  banner: {
    padding: 20,
    gap: 12
  },

  subtle: {
    color: ({ token }) => token('palette.grey.700')
  }
});

export interface BannerProps {
  intent?: Intent;
  align?: 'center' | 'start';
  color?: 'subtle' | 'body';
  Icon?: React.ComponentType;
  role?: string;
}

export function Banner(props: React.PropsWithChildren<BannerProps>) {
  const {
    children,
    intent = 'neutral',
    align = 'center',
    color = 'body',
    role = 'status',
    Icon
  } = props;

  const s = useStyles(styles);

  return (
    <ColoredCard intent={intent} role={role}>
      <Box
        alignItems='center'
        justifyContent={align}
        {...s({
          banner: true,
          subtle: color === 'subtle'
        })}
      >
        {Icon && <Icon />}
        {children}
      </Box>
    </ColoredCard>
  );
}
