import * as React from 'react';

import { query } from '@rexlabs/model-generator';

import { ListTable, TabbedTable } from 'view/components/table';
import { Tab } from 'view/components/table/tabbed';
import { useTableFilters } from 'view/hooks/use-table-filters';

import { transformActionDeclarationsToActionMenuItems } from 'utils/actions/transforms';

import { useAppWideFilterContext } from 'src/modules/app-wide-filters/contexts/app-wide-filter-context';
import { INSPECTION_TASK_ROUTES } from '../routes';
import { useInspectionColumns } from '../hooks/use-inspection-columns';
import { useGetInspectionActions } from '../hooks/action-menu-items/use-get-inspection-actions';
import { getInspectionTabbedTableTabs } from '../utils/get-inspection-tabbed-table-tabs';
import { tasksModel } from '../../common/models/tasks-model';
import { TaskType } from '../../common/types/TaskType';
import { useGetCreateInspectionRunBulkAction } from '../hooks/action-declarations/use-get-create-inspection-run-bulk-action';

const getInspectionsQuery = () => query`{
  ${tasksModel} {
    details 
    inspectionDetails,
    property {
      address
      active_property_ownership {
            ownership
        }
    }
    managed_by
  }
}`;

export function InspectionTable() {
  const { appWideFilterKey } = useAppWideFilterContext();

  const { getSort, getFilters } = useTableFilters('tasks', {
    type_id: 'task_inspection' as TaskType
  });

  const getInspectionActions = useGetInspectionActions();
  const getCreateInspectionRunBulkAction = useGetCreateInspectionRunBulkAction();

  const getRowLinkProps = ({ item }) => {
    return {
      to: INSPECTION_TASK_ROUTES.INSPECTION_TASK_DETAILS,
      params: { inspectionId: item.id }
    };
  };

  const getActionMenuItems = React.useCallback(
    ({ item }) =>
      transformActionDeclarationsToActionMenuItems(getInspectionActions(item)),
    [getInspectionActions]
  );

  const inspectionColumns = useInspectionColumns();
  const columnDependency = JSON.stringify(inspectionColumns);

  const commonTabProps = React.useMemo(
    () => ({
      id: 'inspections',
      columns: inspectionColumns,
      getQuery: getInspectionsQuery,
      Table: ListTable,
      getRowLinkProps,
      getActionMenuItems,
      getBulkActions: getCreateInspectionRunBulkAction,
      forcedGlobalFilter: [
        {
          field: 'type_id',
          op: 'eq',
          value: { value: 'task_inspection', label: 'inspection' }
        }
      ],
      getFilters,
      getSort,
      shouldUseAppWideFilter: true
    }),
    [columnDependency, getFilters]
  );

  const tabs = React.useMemo<Tab[]>(
    () => getInspectionTabbedTableTabs(commonTabProps),
    [commonTabProps]
  );

  return <TabbedTable tabs={tabs} key={appWideFilterKey} />;
}
