import { useGetPrimaryRecordActionGroup } from 'view/hooks/actions/use-get-primary-record-action-group';
import {
  Disbursement,
  financialsDisbursementsModel
} from 'data/models/entities/financials/disbursements/disbursements';
import { useModelDownloadAction } from 'src/modules/common/actions/reporting/use-model-download-action';
import { useModelActions } from '@rexlabs/model-generator';
import { useTranslation } from 'react-i18next';
import { useDialog } from '@rexlabs/dialog';
import { ViewDocumentDialog } from 'src/modules/documents/dialogs/view-document-dialog';
import { getViewDocumentPropsFromPdfLink } from 'src/modules/documents/actions/use-get-view-documents-action';
import { useGetVoidDisbursementAction } from './use-get-void-disbursement-action';
import { useSendDisbursementAction } from './use-get-send-disbursement-action';

export function useGetDisbursementActions() {
  const { t } = useTranslation();

  const viewDocumentDialog = useDialog(ViewDocumentDialog);

  const getPrimaryRecordActionGroup = useGetPrimaryRecordActionGroup();
  const getVoidDisbursementAction = useGetVoidDisbursementAction();
  const getTypePrimaryRecordActionGroup = useGetPrimaryRecordActionGroup();
  const getSendDisbursementAction = useSendDisbursementAction();
  const getDownloadAction = useModelDownloadAction(
    financialsDisbursementsModel
  );
  const { downloadItem, viewItem } = useModelActions(
    financialsDisbursementsModel
  );

  return (disbursement?: Disbursement) => {
    if (!disbursement) return [];

    const type = disbursement.object.type.id;
    const voidDisbursementAction = getVoidDisbursementAction(disbursement);
    const downloadAction = getDownloadAction({
      fetcher: () => downloadItem(disbursement.id),
      record: disbursement,
      label: 'Download'
    });
    const sendDisbursementAction = getSendDisbursementAction(disbursement);

    return [
      ...getPrimaryRecordActionGroup(
        'disbursement',
        disbursement.id,
        t('disbursements.actions.view.label') as string
      ),
      ...getTypePrimaryRecordActionGroup(
        type,
        disbursement.object!.id,
        `View ${type}`
      ),
      {
        id: 'download',
        group: 'reporting',
        label: 'View statement',
        async handleAction() {
          viewDocumentDialog.open({
            isLoading: true
          });

          const response = await viewItem(disbursement.id);

          viewDocumentDialog.update(
            getViewDocumentPropsFromPdfLink({
              link: response.data.link
            })
          );
        }
      },
      downloadAction,
      ...(sendDisbursementAction ? [sendDisbursementAction] : []),
      ...(voidDisbursementAction ? [voidDisbursementAction] : [])
    ];
  };
}
