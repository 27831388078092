import React from 'react';
import dayjs from 'dayjs';

import { useConfirmationDialog, useErrorDialog } from '@rexlabs/dialog';
import { useModelActions } from '@rexlabs/model-generator';
import { Body } from '@rexlabs/text';

import { ActionDeclaration } from 'src/modules/common/actions/types/action-declaration-types';
import { FinancialPeriod } from 'src/modules/statements/types';

import { formatCurrency } from 'utils/formatters';
import { getAllIncludesStringFromModel } from 'utils/api/get-all-includes-string-from-model';

import { ownershipsModel } from 'data/models/entities/ownerships';

import { useQueryClient } from 'react-query';
import { taxSummaryQueryKey } from 'src/modules/tax/hooks/use-get-ownership-tax-summaries-for-financial-year';
import { ownershipTaxTransactionsModel } from '../models/ownership-tax-transactions-model';
import { OwnershipTaxTransaction } from '../types/ownership-tax-transaction';
import { useGetFinancialYearStartAndEnd } from './use-get-financial-year-start-and-end';

function getTaxAdjustmentDescriptionSummary(
  taxAdjustment: OwnershipTaxTransaction
) {
  switch (taxAdjustment.manual_transaction_type?.id) {
    case 'decrease_tax_owed':
      return `Decrease tax owed for ${taxAdjustment.contact.display_name}`;
    case 'increase_tax_owed':
      return `Increase tax owed for ${taxAdjustment.contact.display_name}`;
    case 'reimburse_withheld_tax_to_owner':
      return `Reimburse withheld to ${taxAdjustment.contact.display_name}`;
    case 'withhold_available_funds_from_ownership':
      return `Withhold available funds from ${taxAdjustment.contact.display_name}`;
    default:
      return;
  }
}

function getTaxAdjustmentTransactionSummary(
  taxAdjustment: OwnershipTaxTransaction,
  financialPeriod: FinancialPeriod
) {
  switch (taxAdjustment.manual_transaction_type?.id) {
    case 'decrease_tax_owed':
      return `(${formatCurrency(taxAdjustment.amount)}) effective ${dayjs(
        financialPeriod.startDate
      ).format('D MMM YYYY')}`;
    case 'increase_tax_owed':
      return `${formatCurrency(taxAdjustment.amount)} effective ${dayjs(
        financialPeriod.startDate
      ).format('D MMM YYYY')}`;
    case 'reimburse_withheld_tax_to_owner':
      return `${formatCurrency(taxAdjustment.amount)} reimbursed on ${dayjs(
        taxAdjustment.date_of
      ).format('D MMM YYYY')}`;
    case 'withhold_available_funds_from_ownership':
      return `${formatCurrency(taxAdjustment.amount)} withheld on ${dayjs(
        taxAdjustment.date_of
      ).format('D MMM YYYY')}`;
  }
}

export function useGetVoidTaxAdjustmentAction() {
  const getCurrentFinancialPeriod = useGetFinancialYearStartAndEnd();
  const currentFinancialPeriod = getCurrentFinancialPeriod();

  const {
    open: openVoidTaxAdjustmentConfirmationDialog
  } = useConfirmationDialog();
  const { open: openErrorDialog } = useErrorDialog();
  const { refreshLists, updateItem } = useModelActions(
    ownershipTaxTransactionsModel
  );
  const { refreshItem } = useModelActions(ownershipsModel);
  const queryClient = useQueryClient();

  return (taxAdjustment: OwnershipTaxTransaction): ActionDeclaration => {
    const taxAdjustmentTransactionSummary = getTaxAdjustmentTransactionSummary(
      taxAdjustment,
      currentFinancialPeriod
    );

    const taxAdjustmentDescriptionSummary = getTaxAdjustmentDescriptionSummary(
      taxAdjustment
    );
    const transactionIsInCurrentFinancialYear =
      dayjs(taxAdjustment.date_of).isBefore(currentFinancialPeriod.endDate) &&
      dayjs(taxAdjustment.date_of).isAfter(currentFinancialPeriod.startDate);
    return {
      id: `void-tax-adjustment-${taxAdjustment.id}`,
      label: 'Void adjustment',
      intent: 'danger',
      handleAction: () =>
        transactionIsInCurrentFinancialYear
          ? openVoidTaxAdjustmentConfirmationDialog({
              title: 'Void adjustment',
              message: (
                <Body>
                  <p>Do you want to void the adjustment?</p>
                  <p>
                    {taxAdjustmentDescriptionSummary}
                    <br />
                    {taxAdjustmentTransactionSummary}
                  </p>
                  <p>{`"${taxAdjustment.description}"`}</p>
                </Body>
              ),
              onConfirm: async () => {
                const voidedItem = await updateItem({
                  id: taxAdjustment.id,
                  data: { voided_at: dayjs().toISOString() }
                });

                await refreshLists();
                await refreshItem({
                  id: taxAdjustment.ownership.id,
                  args: {
                    include: getAllIncludesStringFromModel(ownershipsModel)
                  }
                });
                queryClient.invalidateQueries([taxSummaryQueryKey]);

                return voidedItem;
              }
            })
          : openErrorDialog({
              message:
                'You can only void adjustments made in the current financial year.'
            })
    };
  };
}
