import { AxiosResponse } from 'axios';
import { useQuery } from 'react-query';
import { api } from 'utils/api/api-client';
import { ValueListValue } from 'data/models/types';
import { TaskType } from '../../common/types/TaskType';

export type RelatedDatesData = {
  related_dates: ValueListValue<string>[];
  offset_direction_excluded_values: {
    before: ValueListValue<string>[];
    after: ValueListValue<string>[];
  };
};

async function fetchChecklistItemRelatedDates(
  taskType: TaskType
): Promise<AxiosResponse<RelatedDatesData>> {
  return api.get(`/tasks/checklist-template-items/related-dates/${taskType}`);
}

export function useChecklistItemRelatedDatesQuery(taskType: TaskType) {
  return useQuery(
    ['checklist-item-related-dates', taskType],
    () => fetchChecklistItemRelatedDates(taskType),
    {
      cacheTime: Infinity,
      staleTime: Infinity
    }
  );
}
