import React, {
  ComponentType,
  ReactNode,
  useCallback,
  useMemo,
  useState
} from 'react';
import flatten from 'flat';
import { get, set } from 'lodash';
import { useErrorDialog } from '@rexlabs/dialog';
import { MultiForm } from '@rexlabs/form';
import Box from '@rexlabs/box';
import { StyleSheet, useStyles } from '@rexlabs/styling';

import RenderLoading from 'view/components/@luna/render-loading';
import { AutoFocusDialogProps } from 'view/components/@luna/dialog';
import LoadingState from 'view/components/states/compact/loading';

import {
  FormContent,
  FormContentProps
} from 'view/components/record-screen/dialog/content-dialog';
import { DialogContentConfig } from 'view/components/record-screen/dialog/types';
import {
  RecordSubmitHandler,
  submitAllForms
} from 'view/components/record-screen/utils';
import { GetHandlers } from 'view/components/record-screen/types';
import { Drawer } from './drawer';

export interface RecordDrawerProps
  extends AutoFocusDialogProps,
    Pick<
      FormContentProps,
      | 'ButtonGroup'
      | 'buttonGroupProps'
      | 'data'
      | 'initialValues'
      | 'submitLabel'
    > {
  title: ReactNode;
  content: DialogContentConfig;
  handleSubmit?: RecordSubmitHandler;
  isLoading?: boolean;
  LoadingView?: ComponentType<any>;
  blockProps?: any;
  getHandlers?: GetHandlers;
  SubmitButton?: ComponentType<any>;
  Icon?: ComponentType<any>;
}

const defaultStyles = StyleSheet({
  container: {
    position: 'relative',
    height: '100%',
    width: '100%'
  },

  content: {
    height: '100%',
    width: '100%'
  }
});
export function RecordDrawer({
  content,
  data,
  initialValues,
  handleSubmit,
  isLoading,
  LoadingView = LoadingState,
  title,
  submitLabel = title,
  SubmitButton,
  onClose,
  Icon,
  ...props
}: RecordDrawerProps) {
  const s = useStyles(defaultStyles);
  const errorDialog = useErrorDialog();

  const [isSubmitting, setSubmitting] = useState(false);
  // Submit individual blocks in the dialog, which will just pass the changed
  // values up to the dialogs submit handler, this way we can validate all
  // forms at the same time and handle errors appropriately
  const handleBlockSubmit = useCallback((values, { isChanged, id }) => {
    const changedValues = Object.keys(flatten(isChanged)).reduce(
      (acc, name) =>
        // HACK: we want to send the field up as changed if it is changed
        // or if it is part of a field array with any changes is it
        get(isChanged, name) || name.match(/\.[0-9]+\./)
          ? set(acc, name, get(values, name))
          : acc,
      {}
    );

    return { values, changedValues, id };
  }, []);

  // Submit all forms, on validation error stay on the current step if it
  // contains an error, otherwise switch to the first step that does
  const handleDialogSubmit = useCallback(
    ({ validateAll, submitAll }) => {
      return submitAllForms({
        validateAll,
        submitAll,
        handleSubmit,
        setSubmitting
      });
    },
    [content, handleSubmit]
  );

  // Initialise all forms so we can validate and submit them, without them
  // even needing to be rendered
  const formConfigs = useMemo(() => {
    return content.reduce((acc, { blocks, leftBlocks }) => {
      const allBlocks = [...blocks, ...(leftBlocks ? leftBlocks : [])];

      allBlocks?.forEach((block) => {
        if (block.id) {
          acc[block.id] = {
            names: block.fieldNames,
            initialValues: initialValues || data,
            validate: block.validate ?? {},
            handleSubmit: handleBlockSubmit
          };
        }
      });
      return acc;
    }, {});
  }, [content, initialValues, data, handleBlockSubmit]);

  return (
    <Drawer title={title} onClose={onClose} Icon={Icon}>
      <RenderLoading LoadingView={LoadingView} isLoading={isLoading}>
        <MultiForm formConfigs={formConfigs}>
          {({ validateAll, submitAll, resetAll, forms }) => (
            <Box flexDirection='row' {...s('container')}>
              <Box {...s('content')} flex={1}>
                <FormContent
                  {...props}
                  submitLabel={submitLabel}
                  onClose={onClose}
                  SubmitButton={SubmitButton}
                  step={content[0]}
                  data={data}
                  initialValues={initialValues}
                  forms={forms}
                  resetAll={resetAll}
                  isSubmitting={isSubmitting}
                  handleSubmit={() =>
                    handleDialogSubmit({ validateAll, submitAll }).catch(
                      (e) => {
                        console.error(e);
                        errorDialog.open(e);
                      }
                    )
                  }
                />
              </Box>
            </Box>
          )}
        </MultiForm>
      </RenderLoading>
    </Drawer>
  );
}
