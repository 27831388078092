import * as React from 'react';

import { Body, Bold } from '@rexlabs/text';

import WarningCircleIcon from 'view/components/icons/warning-circle';
import { getRecordLabel } from 'utils/records/get-record-label';
import { formatDateTime } from 'utils/dates/format';

import { TrustJournalEntry } from 'src/modules/trust-journal-entries/types/trust-journal-entry-types';
import { RecordLink } from 'view/components/record-link/record-link';
import { WarningBanner } from 'view/components/@luna/notifications/banner';
import { useTranslation } from 'react-i18next';

type ReversingEntryBannerProps = {
  record: TrustJournalEntry;
};

export function ReversingEntryBanner({ record }: ReversingEntryBannerProps) {
  const { t } = useTranslation();

  if (!record.reverses) {
    return null;
  }

  const statusReason = record.reverses.status_reason;

  return (
    <WarningBanner
      title={
        t(
          'trust-journal-entries.reverse-trust-journal-entry.warning.title'
        ) as string
      }
      description={
        <Body>
          <div data-testid='reversing-entry-details'>
            {t('trust-journal-entries.label.singular')}{' '}
            <RecordLink
              id={record.reverses?.id}
              type={record.reverses?.__record_type}
            >
              {record.reverses?.record_reference}
            </RecordLink>{' '}
            was reversed by{' '}
            <Bold>
              {getRecordLabel({
                object: record?.created_by,
                type: 'author'
              })}
            </Bold>{' '}
            on <Bold> {formatDateTime(record.created_at)}.</Bold>
          </div>

          {statusReason && (
            <div data-testid='reversing-entry-status-reason'>
              <Bold>Reason: </Bold>
              {statusReason}
            </div>
          )}
        </Body>
      }
      Icon={WarningCircleIcon}
      strong={true}
    />
  );
}
