import { BaseModelGeneratorModel } from '@rexlabs/model-generator';

import { Generator } from 'data/models/generator';
import { ComplianceType } from 'src/modules/compliance/common/models/compliance-types';
import { SupplierComplianceCategory } from 'data/models/entities/settings/supplier-compliance-categories';
import { TimestampAttributes } from '../../types';

export type SupplierCategoryRequirement = BaseModelGeneratorModel &
  TimestampAttributes & {
    compliance_type?: ComplianceType;
    category?: SupplierComplianceCategory;
  };

export type CreateSupplierCategoryRequirement = {
  supplier_service_category_id: string;
  compliance_type_id: string;
};

const config = {
  entities: {
    related: {
      category: {
        include: 'category'
      },
      compliance_type: {
        include: 'compliance_type'
      }
    }
  }
};

export const settingsSupplierCategoryRequirementsModel = new Generator<SupplierCategoryRequirement>(
  'settings/supplier-category-requirements',
  config
).createEntityModel();
