import { useEffect, useState } from 'react';
import { useReactFormsContext } from '@rexlabs/form';

interface UseAddFilesForEditorParams {
  fileField?: string;
}

// Typically when adding images inline using the froala editor, we also need to save them to the relevant record (via a hidden field)
// examples of relevant records are Message (for emails) or User (for email signatures)
// If we don't do this, the file uploaded by the editor will be cleaned up on BE and the image will be broken.
export function useAddFilesForEditor(params?: UseAddFilesForEditorParams) {
  const { fileField = 'linked_files' } = params || {};
  const { values, setFieldValue } = useReactFormsContext();

  // These listeners allow froala to record its inline images in the relevant file field
  // listeners are required instead of event handlers here because the froala config event functions don't update
  // when the form values change, so they always overwrite any new files added to the form.
  const [fileIdToAdd, setFileIdToAdd] = useState<string>();
  const [fileIdToRemove, setFileIdToRemove] = useState<string>();
  const files = values?.[fileField] || [];

  useEffect(() => {
    if (fileIdToAdd && !files.find((file) => file.file.id === fileIdToAdd)) {
      // clear the remove id so that we can repeatedly undo/redo adding and removing the same file
      setFileIdToRemove(undefined);

      setFieldValue?.(fileField, [...files, { file: { id: fileIdToAdd } }]);
    }
  }, [fileIdToAdd]);

  useEffect(() => {
    if (fileIdToRemove) {
      // clear the add id so that we can repeatedly undo/redo adding and removing the same file
      setFileIdToAdd(undefined);
      const newFiles = files.filter((file) => file.file.id !== fileIdToRemove);
      setFieldValue?.(fileField, newFiles);
    }
  }, [fileIdToRemove]);

  return { setFileIdToAdd, setFileIdToRemove };
}
