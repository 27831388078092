import { Disbursement } from 'data/models/entities/financials/disbursements/disbursements';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Grid } from 'view/components/@luna/form/grid';
import { BlockConfig } from 'view/components/record-screen/types';
import { Value } from 'view/components/values';
import { DateValue } from 'view/components/values/date';

export const disbursementDetailsBlock: BlockConfig<Disbursement> = {
  id: 'pendingDisbursementDetails',
  title: () => <Trans i18nKey='disbursements.details.label' />,
  View: ({ data, testId }) => {
    const { t } = useTranslation();
    return (
      <Grid columns={4}>
        <Value
          data-testid={testId ? `${testId}-disbursed-to` : undefined}
          label={t('disbursements.disbursed-to.label')}
          value={data?.object.label}
        />
        <Value
          data-testid={testId ? `${testId}-payment-method` : undefined}
          label='Payment method'
          value={data?.payment_methods
            ?.map((paymentMethod) => paymentMethod.label)
            .join(', ')}
        ></Value>
        <DateValue
          data-testid={testId ? `${testId}-disbursement-date` : undefined}
          label={t('disbursements.disbursement-date.label')}
          value={data?.created_at}
        ></DateValue>
        <Value
          data-testid={testId ? `${testId}-disbursed-by` : undefined}
          label={t('disbursements.disbursed-by')}
          value={data?.created_by?.name}
        ></Value>
      </Grid>
    );
  }
};
