import { useDeleteAction } from 'src/modules/common/actions/delete/use-delete-action';
import { ActionDeclaration } from 'src/modules/common/actions/types/action-declaration-types';
import { financialsUploadedBillsModel } from '../../models/uploaded-bills';
import { Bill } from '../../types/Bill';

export function useBillProcessingDeleteAction() {
  const getDeleteAction = useDeleteAction(financialsUploadedBillsModel);

  return (billsToBeProcessed: Bill[]): ActionDeclaration => {
    const [bill] = billsToBeProcessed;

    const deleteAction = getDeleteAction(bill);

    return deleteAction;
  };
}
