import React, { useRef, useCallback, useEffect, useState } from 'react';

import Box from '@rexlabs/box';
import { useStyles, StyleSheet, border } from '@rexlabs/styling';

import {
  NeutralBanner,
  InfoBanner,
  WarningBanner,
  ErrorBanner,
  SuccessBanner,
  BannerProps
} from '../banner';

const defaultStyles = StyleSheet({
  container: {
    position: 'relative',
    overflow: 'hidden',

    ...border.styles({
      target: ['banner', 'toast.indicator'],
      bottom: {
        radius: 'l'
      }
    }),

    maxWidth: ({ token }) => token('toast.maxWidth', '60.8rem'),
    boxShadow: ({ token }) => token('toast.boxShadow', token('shadow.heavy')),

    '@media(max-width: 768px)': {
      width: '100%',
      maxWidth: ({ token }) => token('toast.mobile.maxWidth', '100%')
    }
  },

  indicator: {
    position: 'absolute',
    bottom: 0,
    height: '.3rem',
    width: '100%',
    transformOrigin: 'left'
  },

  remove: {
    display: 'none'
  },

  neutral: {
    backgroundColor: ({ token }) => token('color.neutral.idle.default')
  },

  info: {
    backgroundColor: ({ token }) => token('color.information.idle.default')
  },

  warning: {
    backgroundColor: ({ token }) => token('color.warning.idle.default')
  },

  error: {
    backgroundColor: ({ token }) => token('color.danger.idle.default')
  },

  success: {
    backgroundColor: ({ token }) => token('color.success.idle.default')
  }
});

export type ToastType = 'neutral' | 'info' | 'warning' | 'error' | 'success';

export type ToastProps = Omit<BannerProps, 'strong'> & {
  id: string;
  type?: ToastType;
  duration?: number;
  clearable?: boolean;
  timerEnabled?: boolean;
  remove: () => void;
};

export function Toast({
  id,
  type = 'success',
  duration = 5000,
  remove,
  clearable = true,
  timerEnabled = true,
  ...props
}: ToastProps) {
  const s = useStyles(defaultStyles);

  const timer = useRef<NodeJS.Timeout>();

  // HACK: the progress stuff to be able to render a progress bar on the toast
  // is pretty hacky, definitely worth thinking about a more efficiient approach
  // that doesn't involve state changes every couple of milliseconds :/
  const [progress, setProgress] = useState(100);

  const startInterval = useCallback(() => {
    if (timerEnabled && duration && !timer.current) {
      timer.current = setInterval(() => {
        setProgress((pct) => pct - 1);
      }, duration / 100);
    }
  }, [id, timerEnabled, duration]);

  const pauseInterval = useCallback(() => {
    if (timer.current) {
      clearInterval((timer.current as unknown) as number);
      timer.current = undefined;
    }
  }, []);

  const clearToast = useCallback(() => {
    pauseInterval();
    remove();
  }, [id, duration, remove]);

  useEffect(
    () => {
      if (timerEnabled) {
        startInterval();
      } else {
        pauseInterval();
      }
    },
    // eslint-disable-next-line
    [timerEnabled]
  );

  useEffect(() => {
    if (progress <= 0) {
      clearToast();
    }
  }, [progress, clearToast]);

  let Banner = NeutralBanner;

  switch (type) {
    case 'info':
      Banner = InfoBanner;
      break;
    case 'warning':
      Banner = WarningBanner;
      break;
    case 'error':
      Banner = ErrorBanner;
      break;
    case 'success':
      Banner = SuccessBanner;
      break;
  }

  return (
    <Box
      {...s('container')}
      flexDirection='column'
      onMouseOver={() => {
        pauseInterval();
      }}
      onMouseLeave={() => {
        startInterval();
      }}
      data-testid={'toast'}
    >
      <Banner
        {...props}
        strong
        key={id}
        handleClose={clearable ? clearToast : undefined}
      />
      <div
        {...s('indicator', type, { remove: !duration })}
        style={{
          transform: `scaleX(${progress / 100})`
        }}
      />
    </Box>
  );
}

export default Toast;
