import { useDialog } from '@rexlabs/dialog';
import { StatusChangeActionArgs } from 'src/modules/common/actions/status/use-status-change-action';
import { SingleActionDeclaration } from 'src/modules/common/actions/types/action-declaration-types';
import { getRecordLabel } from 'utils/records/get-record-label';
import { getRecordObject } from 'src/modules/common/utils/Records/get-record-object';
import { propertyOwnershipModel } from 'src/modules/property-ownerships/models/property-ownership-model';
import { useApiClient } from 'src/lib/axios/hooks/use-api-client';
import { useToast } from '@rexlabs/notifications';
import { capitalize } from 'lodash';
import { ReasonForChangeDialog } from '../dialogs/reason-for-change-dialog';

export function usePropertyOwnershipStatusAction() {
  const apiClient = useApiClient();
  const changeReasonDialog = useDialog(ReasonForChangeDialog);
  const { addToast } = useToast();

  return <TStatus extends string>(
    args: StatusChangeActionArgs<TStatus> & { label: string }
  ): SingleActionDeclaration => {
    const { status, dialogOptions, record, label } = args;
    const recordObject = getRecordObject(record, propertyOwnershipModel);

    return {
      id: `${status}`,
      group: 'status',
      label,
      handleAction() {
        changeReasonDialog.open({
          title: `Set ${getRecordLabel(recordObject)} as ${status}`,
          submitLabel: capitalize(label),
          handleClose() {
            return;
          },
          async handleSubmit({ values }) {
            const payload = {
              ...values,
              status: {
                id: status
              }
            };

            const response = await apiClient(
              'patch',
              `property-ownerships/${record.id}/status`,
              payload
            );

            addToast({
              description: `${getRecordLabel(
                recordObject
              )} status changed to ${status}`,
              type: 'info'
            });

            if (dialogOptions?.afterAction) {
              await dialogOptions.afterAction(status);
            }

            return response;
          }
        });
      }
    };
  };
}
