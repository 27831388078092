import { SingleActionDeclaration } from 'src/modules/common/actions/types/action-declaration-types';
import { useGetToggleClosedAction } from 'src/modules/tasks/common/hooks/action-declarations/use-get-toggle-closed-action';

import { WorkOrderTask } from '../types/WorkOrderTask';
import { useGetAcceptWorkOrderAction } from './action-declarations/use-get-accept-work-order-action';
import { useGetCompleteWorkOrderAction } from './action-declarations/use-get-complete-work-order-action';
import { useGetRevokeWorkOrderAction } from './action-declarations/use-get-revoke-work-order-action';
import { useGetAddBillAction } from './action-declarations/use-get-add-bill-action';

export function useGetWorkOrderActionsFromStatus() {
  const getToggleClosedAction = useGetToggleClosedAction();
  const getRevokeWorkOrderAction = useGetRevokeWorkOrderAction();
  const getAcceptWorkOrderAction = useGetAcceptWorkOrderAction();
  const getCompleteWorkOrderAction = useGetCompleteWorkOrderAction();
  const getAddBillAction = useGetAddBillAction();

  return (workOrder: WorkOrderTask) => {
    const revokeWorkOrderAction = getRevokeWorkOrderAction(workOrder);
    const acceptWorkOrderAction = getAcceptWorkOrderAction(workOrder);
    const completeWorkOrderAction = getCompleteWorkOrderAction(workOrder);
    const addBillAction = getAddBillAction(workOrder);

    const statusId = workOrder.details?.status?.id;

    // the primary actions, to be displayed outside
    // of the actions dropdown if displayed in a title block
    const primaryActions: SingleActionDeclaration[] = [];
    // the secondary actions, to be displayed in
    // the actions dropdown if displayed in a title block
    const secondaryActions: SingleActionDeclaration[] = [];

    if (!workOrder.closed_at) {
      switch (statusId) {
        case 'issued':
          primaryActions.push(acceptWorkOrderAction);
          secondaryActions.push(revokeWorkOrderAction);
          break;
        case 'accepted':
          primaryActions.push(completeWorkOrderAction);
          secondaryActions.push(revokeWorkOrderAction);
          break;
        case 'completed':
          if (workOrder.details?.work_done_by_type?.id === 'supplier') {
            primaryActions.push(addBillAction);
          }
      }
    }

    secondaryActions.push(getToggleClosedAction(workOrder));

    return { primaryActions, secondaryActions };
  };
}
