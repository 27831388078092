import { TrustJournalEntry } from 'src/modules/trust-journal-entries/types/trust-journal-entry-types';
import { api } from 'utils/api/api-client';

const relatedFields = ['contact'];

export async function getTrustJournalEntryRelatedItems(
  trustJournalEntrySearchItem: TrustJournalEntry
) {
  const trustJournalEntryUrl = `/financials/trust-journal-entries/${
    trustJournalEntrySearchItem.id
  }?include=${relatedFields.join(',')}`;

  const completeTrustJournalEntry = !trustJournalEntrySearchItem?.contact
    ? await api
        .get<TrustJournalEntry>(trustJournalEntryUrl)
        .then(({ data }) => data)
    : trustJournalEntrySearchItem;

  const contact = completeTrustJournalEntry.contact;

  return {
    contact
  };
}
