import { ContentConfig } from 'view/components/record-screen/types';
import { detailsBlock } from '../blocks/bank-deposit-details';
import { trustJournalEntriesBlock } from '../blocks/bank-deposit-trust-journal-entries';

export const content: ContentConfig = [
  {
    label: 'General',
    items: [
      {
        id: 'overview',
        label: 'Overview',
        blocks: [detailsBlock, trustJournalEntriesBlock]
      }
    ]
  }
];
