import { KeySet } from '../data/key-set-model';
import { useGetArchiveKeySetAction } from './use-get-archive-key-set-action';
import { useGetEditKeySetAction } from './use-get-edit-add-key-set-action';
import { useGetRestoreKeySetAction } from './use-get-restore-key-set-action';
import { useGetCheckOutKeySetAction } from './use-get-check-out-key-set-action';
import { useGetCheckInKeySetAction } from './use-get-check-in-key-set-action';
import { useGetManageKeySetAction } from './use-get-manage-key-set-action';

export function useGetTableRowActions() {
  const getEditAction = useGetEditKeySetAction();
  const getArchiveKeyAction = useGetArchiveKeySetAction();
  const getUnarchiveKeyAction = useGetRestoreKeySetAction();
  const getCheckOutKeyAction = useGetCheckOutKeySetAction();
  const getCheckInKeyAction = useGetCheckInKeySetAction();
  const getManageKeySetAction = useGetManageKeySetAction();

  return ({ item }: { item: KeySet }) => {
    const editAction = getEditAction(item);
    const archiveAction = getArchiveKeyAction(item);
    const activateAction = getUnarchiveKeyAction(item);
    const checkOutAction = getCheckOutKeyAction(item);
    const checkInAction = getCheckInKeyAction(item);
    const manageKeySetAction = getManageKeySetAction(item);

    switch (item?.status?.id) {
      case 'checked_out':
        return [editAction, manageKeySetAction, checkInAction];
      case 'checked_in':
      case 'active':
        return [editAction, manageKeySetAction, checkOutAction, archiveAction];
      case 'archived':
        return [activateAction];
      default:
        return [];
    }
  };
}
