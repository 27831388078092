import React from 'react';
import { useToast } from 'view/components/@luna/notifications/toast';
import { DialogProps } from '@rexlabs/dialog';
import { useModelActions } from '@rexlabs/model-generator';

import { RecordDialog } from 'view/components/record-screen/dialog/dialog';
import { DialogContentConfig } from 'view/components/record-screen/dialog/types';

import { AddLinkBlock } from 'src/modules/tasks/common/blocks/add-link-block';
import { tasksModel } from 'src/modules/tasks/common/models/tasks-model';
import { Task } from 'src/modules/tasks/common/types/Task';
import { mapCreateLinkTaskFormToTaskApi } from 'src/modules/tasks/common/mappers/map-link-task-form-to-task-api';
import { mapTaskApiToLinkTaskForm } from 'src/modules/tasks/common/mappers/map-task-api-to-link-task-form';
import { getSearchResultItemFromObjectAndModel } from 'src/modules/common/utils/search-result-items/get-search-result-items-from-object-and-model';

const content: DialogContentConfig = [
  {
    id: 'link-tasks',
    label: 'Link Task',
    blocks: [AddLinkBlock]
  }
];

interface LinkTasksDialogProps extends DialogProps {
  task: Task<any>;
  linkedTask?: Task<any>;
}

export function LinkTasksDialog({
  onClose,
  task,
  linkedTask
}: LinkTasksDialogProps) {
  const { updateItem, refreshLists } = useModelActions(tasksModel);
  const { addToast } = useToast();

  const handleSubmit = async ({ values }) => {
    // mapping for update requires the parent task, previous child task and the updated link
    // seems cleaner to do it here where we have all the data instead of moving it to a util function
    const updateLink = (taskLink) => {
      if (taskLink?.child_task?.id === linkedTask?.id) {
        return {
          child_task: values.linked_task,
          relation: values.relation
        };
      }
      return {
        child_task: taskLink.child_task,
        relation: taskLink.relation
      };
    };

    const data = linkedTask
      ? { task_links: task?.task_links?.data?.map(updateLink) }
      : mapCreateLinkTaskFormToTaskApi({ values, parentTask: task });

    const { data: updatedTask } = await updateItem({
      id: task.id,
      data
    });

    /**
     * HACK: There is an issue when we show the empty state for the table, add a task link, and then
     * refresh the lists - we fetch the task lists, but the filters have been dropped, so it shows all of the
     * tasks. This only happens when we first add a task link, and as the empty state is only shown when there
     * are no task links, when we add a new link it does the correct fetch request.
     *
     * This bug had an alternative fix, which was to refreshItem after refreshLists, but this issue was still
     * happening intermittently in our E2E tests.
     *
     * The below hack is improvement on the last one, as we're only making the refreshList request if we need to
     * (i.e. if there are task links), and we're not performing the refreshItem, since the updateItem request
     * does that for us.
     */
    if (task.task_links?.data?.length) {
      await refreshLists();
    }

    addToast({
      description: (
        <>
          Task link <b>successfully</b> added to {values.linked_task.label}
        </>
      )
    });

    return updatedTask;
  };

  const data = linkedTask
    ? mapTaskApiToLinkTaskForm({
        linkedTask,
        parentTask: task,
        thisTask: getSearchResultItemFromObjectAndModel(task, tasksModel)
      })
    : {
        this_task: getSearchResultItemFromObjectAndModel(task, tasksModel)
      };

  return (
    <RecordDialog
      data={data}
      title='Link task'
      onClose={onClose}
      content={content}
      submitLabel='Link task'
      handleSubmit={handleSubmit}
    />
  );
}
