import { useModelActions } from '@rexlabs/model-generator';
import { tasksModel } from 'src/modules/tasks/common/models/tasks-model';

const taskCompleteStatuses = [
  'completed',
  'report_sent',
  'agreement_updated',
  'work_finished',
  'approved',
  'rejected',
  'resolved'
];

export function useStatusDropdownRefresh() {
  const { refreshLists } = useModelActions(tasksModel);

  return (status?: string) => {
    if (status && taskCompleteStatuses.includes(status)) {
      return refreshLists();
    }
  };
}
