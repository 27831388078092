import React from 'react';

import { query, useEntityQuery } from '@rexlabs/model-generator';

import { FLAGS } from 'utils/feature-flags';

import { contactsModel } from 'src/modules/contacts/models/contacts';
import { useTrustWithdrawalPaymentMethods } from 'src/modules/financials/hooks/use-withdrawal-payment-methods';

import { mapDefaultDisbursementSettingsToDisbursementFrequencyForm } from '../account-settings/mappers/map-default-disbursement-settings-to-disbursement-frequency-form';
import {
  DEFAULT_DISBURSEMENT_FREQUENCY_SETTING_KEY,
  TAX_PAYMENT_ACCOUNT_DETAILS_SETTING_KEY
} from './useSettings';

const getContactQuery = (contactId: string) => query`{
    ${contactsModel} (id: ${contactId}) {
      id
      name
    }
  }`;

export function useMapAccountSettingsDataToForm(settingsData) {
  const contactQuery = React.useMemo(
    () =>
      settingsData?.[TAX_PAYMENT_ACCOUNT_DETAILS_SETTING_KEY]?.payee_id &&
      getContactQuery(
        settingsData?.[TAX_PAYMENT_ACCOUNT_DETAILS_SETTING_KEY]?.payee_id
      ),
    [settingsData]
  );

  const { data: payeeData } = useEntityQuery(contactQuery, {
    throwOnError: false
  });

  const withdrawalPaymentMethods = useTrustWithdrawalPaymentMethods();

  return {
    ...settingsData,
    //TODO: We can remove this once the feature is connected to the backend
    ...(FLAGS.RENT_PAID_TO_DATE
      ? {
          'rent-paid-to-date': settingsData?.['rent-paid-to-date']?.id,
          'rent-arrears-paid-to-date':
            settingsData?.['rent-arrears-paid-to-date']?.id,
          'rent-arrears-calculation':
            settingsData?.['rent-arrears-calculation']?.id
        }
      : {}),
    'batch-receipting-match-setting':
      settingsData?.['batch-receipting-match-setting']?.id,
    'batch-receipting-excess-match-setting':
      settingsData?.['batch-receipting-excess-match-setting']?.id,
    [TAX_PAYMENT_ACCOUNT_DETAILS_SETTING_KEY]: {
      ...settingsData?.[TAX_PAYMENT_ACCOUNT_DETAILS_SETTING_KEY],
      ...(settingsData?.[TAX_PAYMENT_ACCOUNT_DETAILS_SETTING_KEY]
        ?.payment_method_id
        ? {
            method: withdrawalPaymentMethods.find(
              (method) =>
                method.id ===
                settingsData?.[TAX_PAYMENT_ACCOUNT_DETAILS_SETTING_KEY]
                  ?.payment_method_id
            )
          }
        : {}),
      ...(settingsData?.[TAX_PAYMENT_ACCOUNT_DETAILS_SETTING_KEY]?.payee_id
        ? {
            payee: payeeData
          }
        : {})
    },
    ...mapDefaultDisbursementSettingsToDisbursementFrequencyForm(
      settingsData[DEFAULT_DISBURSEMENT_FREQUENCY_SETTING_KEY]
    )
  };
}
