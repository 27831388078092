import * as React from 'react';
import { StyleSheet, useStyles } from '@rexlabs/styling';
import { Field } from '@rexlabs/form';
import { NumberInput } from '@rexlabs/text-input';
import { Select } from '@rexlabs/select';

export interface TimePeriodInputProps {
  Suffix?: React.ReactNode;
  countName?: string;
  unitName?: string;
}

const styles = StyleSheet({
  root: {
    display: 'grid',
    gridTemplateColumns: '150px 150px 1fr',
    gap: 12
  }
});

const DURATION = {
  days: {
    id: 'days',
    label: 'Days'
  },

  weeks: {
    id: 'weeks',
    label: 'Weeks'
  },

  months: {
    id: 'months',
    label: 'Months'
  },

  years: {
    id: 'years',
    label: 'Years'
  }
};

const durationInputProps = {
  items: Object.keys(DURATION),
  normaliser: (item) => {
    return DURATION[item];
  }
};

export function TimePeriodInput(props: TimePeriodInputProps) {
  const { unitName = 'unit', countName = 'count', Suffix } = props;
  const s = useStyles(styles);

  return (
    <div {...s('root')}>
      <Field
        id={countName}
        name={countName}
        Input={NumberInput}
        inputProps={{
          'data-testid': countName
        }}
        optional={false}
      />

      <Field
        id={unitName}
        name={unitName}
        Input={Select}
        inputProps={durationInputProps}
        optional={false}
      />

      {Suffix}
    </div>
  );
}
