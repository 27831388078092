import { query } from '@rexlabs/model-generator';
import { keySetsModel } from '../data/key-set-model';

export const getKeySetListQuery = () => query`{
  ${keySetsModel}  {
    name
    notes
    number_of_keys,
    status
    images
    object
    property
  }
}`;
