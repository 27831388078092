import { ActionDeclaration } from 'src/modules/common/actions/types/action-declaration-types';
import { useGetUpdatePrepaymentBucketAction } from 'src/modules/prepayments/hooks/action-declarations/use-get-update-prepayment-bucket-action';
import { PrepaymentBucket } from 'src/modules/prepayments/types/prepayment-bucket';

export function useGetPrepaymentsActions() {
  const getUpdatePrepaymentsAction = useGetUpdatePrepaymentBucketAction();

  return (prepayment?: PrepaymentBucket): ActionDeclaration[] => {
    if (!prepayment) return [];

    const actions = [getUpdatePrepaymentsAction(prepayment)];

    return actions;
  };
}
