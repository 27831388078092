import { useDialog } from '@rexlabs/dialog';

import { SecurityDeposit } from 'data/models/entities/financials/security-deposits';
import { SingleActionDeclaration } from 'src/modules/common/actions/types/action-declaration-types';
import { EditSecurityDepositDetailsDialog } from '../../dialogs/edit-security-deposit-details';

export function useGetEditSecurityDepositDetailsAction() {
  const { open: openEditSecurityDepositDetailsDialog } = useDialog(
    EditSecurityDepositDetailsDialog
  );

  return (securityDeposit: SecurityDeposit): SingleActionDeclaration => {
    return {
      id: 'edit',
      group: 'edit',
      label: 'Edit',
      handleAction: () =>
        openEditSecurityDepositDetailsDialog({
          securityDeposit
        })
    };
  };
}
