import React from 'react';
import { BlockConfig } from 'view/components/record-screen/types';
import { Field } from '@rexlabs/form';
import { Grid } from 'view/components/@luna/form/grid';
import { Ownership } from 'data/models/entities/ownerships';
import { RadioGroupInput } from '@rexlabs/radio-input';
import InfoCircleIcon from 'view/components/icons/info';
import { InfoBanner } from '@rexlabs/notifications';
import { Value } from 'view/components/values';

export const statementPreferencesBlock: BlockConfig<
  Ownership,
  // an undefined value here has to be equivalent to false
  // because blockProps doesn't exist in the record screen props
  { isCreating?: boolean }
> = {
  id: 'statement-preferences-block',
  title: 'Statement preferences',
  validate: {
    definitions: {
      eofy_statement_enabled: {
        rules: 'required',
        name: 'financial year statement preference'
      }
    }
  },
  View: ({ data }) => {
    return (
      <Grid columns={3}>
        <Value
          label={'Financial year statements'}
          value={data?.eofy_statement_enabled ? 'Enabled' : 'Disabled'}
        />
      </Grid>
    );
  },
  Edit: ({ values, blockProps = {} }) => {
    const { isCreating } = blockProps;
    return (
      <Grid columns={1}>
        <Field
          name='eofy_statement_enabled'
          label='Financial year statements'
          Input={RadioGroupInput}
          inputProps={{
            orientation: 'horizontal',
            options: [
              { label: 'Enabled', value: true },
              { label: 'Disabled', value: false }
            ]
          }}
        />
        <EOFYPreferenceBanner
          isCreating={isCreating ?? false}
          statementEnabled={values?.eofy_statement_enabled}
        />
      </Grid>
    );
  }
};

interface EOFYPreferenceBannerProps {
  statementEnabled: boolean;
  isCreating: boolean;
}

// Possibly can be extracted but the context is very specific to this block
function EOFYPreferenceBanner({
  statementEnabled,
  isCreating
}: EOFYPreferenceBannerProps) {
  const manualStatementLocation = isCreating
    ? 'in the statements section of the ownership record'
    : 'below';

  const description = statementEnabled
    ? 'By enabling the statement preferences, this ownership will appear in the EOFY statement list. Once listed, you can choose to issue the statement or exclude it for that financial year.'
    : `By disabling the statement preferences, this ownership will NOT appear in the EOFY statement list. However, you can still manually issue an EOFY statement to the ownership by clicking "Issue Statement" ${manualStatementLocation}.`;

  return (
    <InfoBanner
      Icon={InfoCircleIcon}
      description={description}
      data-testid={`eofy-statements-${
        statementEnabled ? 'enabled' : 'disabled'
      }-banner`}
    />
  );
}
