import { ValueListValue } from 'data/models/types';
import { isObject } from 'lodash';

type Options = {
  preferLabel?: boolean;
  defaultValue?: string;
};

export function getValueFromStringOrValueListValue(
  value: string | ValueListValue<string> | undefined,
  { preferLabel = false, defaultValue = '' }: Options = {}
): string {
  return isObject(value)
    ? preferLabel
      ? value.label
      : value.id
    : value || defaultValue;
}
