import { RecordObject, ValueListValue } from 'data/models/types';
import { Contact } from 'src/modules/contacts/types/contact-types';
import { SearchResultItem } from 'utils/api/get-search-results';
import { getRecordLabel } from 'utils/records/get-record-label';
import { CommunicationContextType } from '../../common/types/communication-context-type';
import { Message } from '../types/Message';
import { Channel, ChannelTypeId } from '../../common/types/channel';
import {
  AttachmentItem,
  CoreCommunicationContextObject,
  CreateMessageFormData
} from './types/create-message-form-data';

function getDisplayNameForContextObject(
  object: CoreCommunicationContextObject
): string {
  if ('display_name' in object) {
    return object.display_name || '';
  }

  if ('summary' in object) {
    return object.summary;
  }

  return getRecordLabel({ type: object.__record_type, object } as RecordObject);
}

export const getRelatesToSearchResultItem = (
  context: CoreCommunicationContextObject,
  contextType: ValueListValue<CommunicationContextType>
): SearchResultItem<CoreCommunicationContextObject> => {
  const item = {
    id: context.id,
    label: getDisplayNameForContextObject(context),
    type: contextType,
    record: context
  };

  return item;
};

export const getRecipientSearchResultItem = (
  contact: Contact
): SearchResultItem<Contact> => {
  const item = {
    id: contact.id,
    label: contact.display_name,
    type: {
      id: 'contact',
      label: 'Contact'
    },
    record: contact
  };

  return item;
};

/**
 * Converts a message to an edit message form data object.
 */
export function mapMessageToEditFormData({
  message,
  type,
  resolveMergeTags = false
}: {
  message: Message;
  type: ChannelTypeId;
  resolveMergeTags?: boolean;
}): CreateMessageFormData {
  const emailChannel = message?.channels.find(
    (channel) => channel.channel_type.id === 'email'
  );

  const smsChannel = message?.channels.find(
    (channel) => channel.channel_type.id === 'sms'
  );

  const letterChannel = message?.channels.find(
    (channel) => channel.channel_type.id === 'letter'
  );

  const channelMap: Record<ChannelTypeId, Channel | undefined> = {
    email: emailChannel,
    sms: smsChannel,
    letter: letterChannel
  };

  const channelToUse = channelMap[type];

  const common = {
    subject:
      (resolveMergeTags
        ? emailChannel?.resolved_email_subject
        : emailChannel?.email_subject) ?? '',
    content:
      (resolveMergeTags
        ? channelToUse?.resolved_text_body
        : channelToUse?.text_body) ?? '',
    // todo: this one already had its own property, so will leave it for now
    smsContent:
      (resolveMergeTags
        ? smsChannel?.resolved_text_body
        : smsChannel?.text_body) ?? '',
    letterAddress: resolveMergeTags
      ? letterChannel?.resolved_letter_address
      : letterChannel?.letter_address ?? '',
    relates_to: getRelatesToSearchResultItem(
      message.context as CoreCommunicationContextObject,
      message.context_type
    ),
    ...(message?.template ? { template: message?.template } : {}),
    ...(message?.send_from_author
      ? { sent_from: message?.send_from_author }
      : {}),
    recipients: message.recipients.items.map((item) =>
      getRecipientSearchResultItem(item)
    )
  };

  if (type === 'sms') {
    return {
      ...common,
      linked_files: []
    };
  }

  const attachments: AttachmentItem[] = message?.inline_attachments.map(
    (attachment) => {
      return {
        data: Promise.resolve({ data: attachment.file }),
        file: attachment.file,
        uuid: attachment.id
      };
    }
  );

  if (type === 'letter') {
    return {
      ...common,
      ...(message?.inline_attachments
        ? {
            attachments: attachments
          }
        : {}),
      linked_files: message.linked_files || []
    };
  }

  return {
    ...common,
    ...(message?.cc_recipients?.items
      ? {
          cc_recipients: message?.cc_recipients?.items.map((item) =>
            getRecipientSearchResultItem(item)
          )
        }
      : {}),
    ...(message?.bcc_recipients?.items
      ? {
          bcc_recipients: message?.bcc_recipients?.items.map((item) =>
            getRecipientSearchResultItem(item)
          )
        }
      : {}),
    ...(message?.inline_attachments
      ? {
          attachments: attachments
        }
      : {}),
    linked_files: message.linked_files || []
  };
}
