import * as React from 'react';
import { DialogProps } from '@rexlabs/dialog';
import { RecordDialog } from 'view/components/record-screen/dialog/dialog';
import { useModelActions } from '@rexlabs/model-generator';
import { useRecordUpdatedToast } from 'src/modules/common/toasts/hooks/use-record-updated-toast';
import { noteDetailsBlock } from '../blocks/note-details-block';
import { Note } from '../types/Note';
import { notesModel } from '../models/notes-model';

export interface EditNoteRecordDialogProps extends DialogProps {
  note: Note;
  hideAddNoteToField?: boolean;
}

const content = [
  {
    id: 'edit-note',
    label: 'Save Note',
    blocks: [noteDetailsBlock]
  }
];

export function EditNoteRecordDialog({
  onClose,
  hideAddNoteToField = false,
  note
}: EditNoteRecordDialogProps) {
  const { updateItem, refreshLists } = useModelActions(notesModel);
  const addToast = useRecordUpdatedToast(notesModel, { actions: [] });

  const handleSubmit = async ({ changedValues }) => {
    const attached_to = changedValues?.attached_to?.map(({ id, type }) => {
      return { id, type };
    });

    const dataToSubmit = { ...changedValues, attached_to };

    const { data: updatedNote } = await updateItem({
      id: note.id,
      data: dataToSubmit
    });

    addToast(updatedNote);

    return await refreshLists();
  };

  return (
    <RecordDialog
      title='Edit Note'
      content={content}
      onClose={onClose}
      handleSubmit={handleSubmit}
      data={note}
      submitLabel='Save'
      blockProps={{ hideAddNoteToField }}
      // NOTE: Attempt to autofocus on the content field
      autofocusIndex={note.phone_call_outcome ? 2 : 1}
    />
  );
}
