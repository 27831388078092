import { RadioGroupInputProps, RadioGroupInput } from '@rexlabs/radio-input';
import React from 'react';

export interface BooleanRadiosProps
  extends Omit<RadioGroupInputProps, 'options'> {
  trueLabel?: string;
  falseLabel?: string;
}

export function BooleanRadios({
  trueLabel = 'Enable',
  falseLabel = 'Disable',
  orientation = 'horizontal',
  value,
  ...props
}: BooleanRadiosProps) {
  return (
    <RadioGroupInput
      {...props}
      value={value}
      orientation={orientation}
      options={[
        //@ts-ignore
        { label: trueLabel, value: true },
        //@ts-ignore
        { label: falseLabel, value: false }
      ]}
    />
  );
}
