import { DEFAULT_DISBURSEMENT_FREQUENCY_SETTING_KEY } from '../../hooks/useSettings';
import { mapDefaultDisbursementSettingsToDisbursementFrequencyView } from '../mappers/map-default-disbursement-settings-to-disbursement-frequency-view';

/**
 * Normalises the settings data in case the data is not in the correct shape
 */
export function normaliseSettingsData(settingsData) {
  const data = {
    ...settingsData,
    // Because we're reusing the existing disbursement frequency block we want the data to just match what the block is expecting.
    ...mapDefaultDisbursementSettingsToDisbursementFrequencyView(
      settingsData[DEFAULT_DISBURSEMENT_FREQUENCY_SETTING_KEY]
    )
  };

  delete data[DEFAULT_DISBURSEMENT_FREQUENCY_SETTING_KEY];

  return data;
}
