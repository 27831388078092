import { User } from 'src/modules/users/types/user-types';
import { SingleActionDeclaration } from 'src/modules/common/actions/types/action-declaration-types';
import { useToast } from 'view/components/@luna/notifications/toast';
import { useModelActions } from '@rexlabs/model-generator';
import React from 'react';
import { api } from 'utils/api/api-client';
import { usersModel } from '../models/users';

const determineErrorMessage = (reason: Error, action: string, user: User) => {
  if (reason.toString().includes('unauthorized')) {
    return (
      <>
        Invalid access to {action} <b>{user.display_name}</b>
      </>
    );
  }

  return (
    <>
      Unable to {action} <b>{user.display_name}</b>
    </>
  );
};

export function useToggleSuspendAction() {
  const { addToast } = useToast();
  const { refreshLists, refreshItem } = useModelActions(usersModel);

  return (user: User): SingleActionDeclaration => {
    const action = user.status.id === 'suspended' ? 'unsuspend' : 'suspend';

    return {
      id: action,
      label: action.charAt(0).toUpperCase() + action.slice(1),
      handleAction: async () => {
        await api
          .post(`/users/${user.id}/${action}`)
          .then(async () => {
            await Promise.all([refreshItem({ id: user.id }), refreshLists()]);

            addToast({
              description: (
                <>
                  <b>{user.display_name}</b> has been {action}ed
                </>
              )
            });
          })
          .catch((reason) => {
            addToast({
              type: 'error',
              description: determineErrorMessage(reason, action, user)
            });
          });
      }
    };
  };
}
