import { RentScheduleEntry } from 'src/modules/property-tenancies/types/property-tenancy-types';

export function getRentScheduleEntryStatus(
  startDateString: string | null,
  endDateString: string | null
): RentScheduleEntry['status'] {
  const now = new Date().getTime();

  if (startDateString && endDateString) {
    const startDate = new Date(startDateString).getTime();
    const endDate = new Date(endDateString).getTime();

    if (startDate < now && endDate > now) {
      return {
        id: 'current',
        label: 'Current'
      };
    }

    if (startDate > now) {
      return {
        id: 'future',
        label: 'Future'
      };
    }

    return {
      id: 'past',
      label: 'Past'
    };
  }

  if (startDateString === null && endDateString !== null) {
    const endDate = new Date(endDateString).getTime();
    if (endDate > now) {
      return {
        id: 'current',
        label: 'Current'
      };
    }

    return {
      id: 'past',
      label: 'Past'
    };
  }

  if (startDateString !== null && endDateString === null) {
    const startDate = new Date(startDateString).getTime();
    if (startDate < now) {
      return {
        id: 'current',
        label: 'Current'
      };
    }

    return {
      id: 'future',
      label: 'Future'
    };
  }

  throw Error('something is wrong');
}
