import React from 'react';

import { Truncate } from '@rexlabs/text';
import { CellProps } from '@rexlabs/table';

import { formatHumanReadableFileSize } from 'utils/dates/format';

export function FileSize({ value }: CellProps) {
  return (
    <Truncate>{value ? formatHumanReadableFileSize(value) : null}</Truncate>
  );
}
