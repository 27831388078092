import React from 'react';
import { get } from 'lodash';
import { useTranslation } from 'react-i18next';

import { Field } from '@rexlabs/form';
import { TextInput } from '@rexlabs/text-input';

import { ContactPaymentMethodMethod } from 'data/models/entities/contacts/payment-methods';

import { WithdrawalPaymentMethodSelect } from 'view/components/inputs/selects/v4-selects/withdrawal-payment-method';

import { getWithDrawalDetailsConfigFromMethodId } from '../utils/get-withdrawal-details-config';

function getFieldId(field: string, prefix = '') {
  return prefix ? prefix.concat('.', field) : field;
}

interface WithdrawalPaymentDetailsFormProps {
  fieldPrefix?: string;
  values: any;
  excludePaymentMethodIds?: ContactPaymentMethodMethod[];
}

/**
 * Use this component to generate the fields for the withdrawal payment details form.
 * If the fields are nested, you can pass a prefix to the fieldPrefix prop.
 */
export function WithdrawalPaymentDetailsForm({
  fieldPrefix = '',
  values,
  excludePaymentMethodIds = []
}: WithdrawalPaymentDetailsFormProps) {
  const { t } = useTranslation();

  const paymentId = get(values, `${getFieldId('method.id', fieldPrefix)}`) as
    | ContactPaymentMethodMethod
    | undefined;

  const {
    isReferenceNeeded,
    isBranchCodeNeeded,
    isAccountCodeNeeded,
    isDrawerNeeded,
    isCheckNumberNeeded,
    isBankNeeded
  } = getWithDrawalDetailsConfigFromMethodId(paymentId);

  return (
    <>
      <Field<typeof WithdrawalPaymentMethodSelect>
        id={getFieldId('method', fieldPrefix)}
        name={getFieldId('method', fieldPrefix)}
        label='Payment method'
        Input={WithdrawalPaymentMethodSelect}
        optional={false}
        inputProps={{
          excludeIds: excludePaymentMethodIds
        }}
      />
      {isDrawerNeeded && (
        <Field
          name={getFieldId('drawer', fieldPrefix)}
          label='Drawer'
          Input={TextInput}
          optional={false}
        />
      )}
      {isCheckNumberNeeded && (
        <Field
          name={getFieldId('reference', fieldPrefix)}
          label='Check number'
          Input={TextInput}
          optional={false}
        />
      )}
      {isBankNeeded && (
        <Field
          name={getFieldId('issuer', fieldPrefix)}
          label='Bank'
          Input={TextInput}
          optional={false}
        />
      )}
      {isBranchCodeNeeded && (
        <Field
          name={getFieldId('branch_code', fieldPrefix)}
          label={t('bank-accounts.branch-code.label.singular') as string}
          Input={TextInput}
          optional={false}
        />
      )}
      {isAccountCodeNeeded && (
        <Field
          name={getFieldId('account_number', fieldPrefix)}
          label='Account number'
          Input={TextInput}
          optional={false}
        />
      )}
      {isReferenceNeeded && (
        <Field
          name={getFieldId('reference', fieldPrefix)}
          label='Reference'
          Input={TextInput}
          optional={false}
        />
      )}
    </>
  );
}
