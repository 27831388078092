import { ParentRecordDataType } from '../types/ParentRecordDataType';

export function getNoteQueryFilterTypeId(data: ParentRecordDataType) {
  switch (data.__record_type) {
    case 'trust_journal_entry':
      return 'bank_statement_transaction_id';
    default:
      return `${data.__record_type}_id`;
  }
}
