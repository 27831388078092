import { useModelActions } from '@rexlabs/model-generator';
import { pick } from 'lodash';
import { api } from 'utils/api/api-client';
import { RecordSubmitHandler } from 'view/components/record-screen/utils';
import { propertyAreaTypesModel } from 'src/modules/property-areas/property-area-types/models/property-area-types-model';
import { PropertyAreaTypeForm } from 'src/modules/property-areas/property-area-types/types/PropertyAreaTypeForm';
import { PropertyAreaType } from 'src/modules/property-areas/property-area-types/types/PropertyAreaType';
import { useRecordUpdatedToast } from 'src/modules/common/toasts/hooks/use-record-updated-toast';

export function useUpdatePropertyAreaTypeSubmitHandler() {
  const { refreshLists } = useModelActions(propertyAreaTypesModel);
  const addUpdatedToast = useRecordUpdatedToast(propertyAreaTypesModel, {
    actions: []
  });

  return (propertyAreaType: PropertyAreaType) => {
    const onSubmit: RecordSubmitHandler<PropertyAreaTypeForm> = async ({
      changedValues
    }) => {
      const deleteIds = changedValues.delete_ids || [];

      const requests = transformPropertyAreaTypeFormDataToPipelineRequest({
        values: changedValues,
        propertyAreaTypeId: propertyAreaType.id,
        deleteIds
      });

      await api.post('request-pipelines', {
        requests
      });
      addUpdatedToast(propertyAreaType);
      await refreshLists();

      return true;
    };

    return onSubmit;
  };
}

function transformPropertyAreaTypeFormDataToPipelineRequest({
  values,
  propertyAreaTypeId,
  deleteIds
}: {
  values: Partial<PropertyAreaTypeForm>;
  propertyAreaTypeId: string;
  deleteIds: string[];
}) {
  const requests: Array<any> = [];

  /**
   *  This request is for updating name and included for new properties
   */
  if ('name' in values || 'is_created_on_new_properties' in values) {
    requests.push({
      id: 'property_area_types',
      method: 'PATCH',
      path: `/api/v1/properties/property-area-types/${propertyAreaTypeId}`,
      json: pick(values, ['name', 'is_created_on_new_properties'])
    });
  }

  if (Array.isArray(values.items)) {
    const itemRequests = values.items.map((item, index) => {
      return item.id
        ? getPatchRequest(item, index)
        : getPostRequest(item, index);
    });

    requests.push(itemRequests);
  }

  if (deleteIds.length) {
    const deleteRequests = deleteIds.map((id, index) => {
      return getDeleteRequest(id, index);
    });

    requests.push(deleteRequests);
  }

  /**
   *  This request is for updating the order of the items to maintain sort order
   */
  const itemOrderArray = values.items!.map((item, index) => {
    return item.id || `{{$.property_area_type_items_${index}.id}}`;
  });

  requests.push({
    id: 'property_area_types_item_array',
    method: 'PATCH',
    path: `/api/v1/properties/property-area-types/${propertyAreaTypeId}`,
    json: {
      item_order: itemOrderArray
    }
  });

  return requests.flat();

  /**
   *  These requests are for any existing items which need to be updated
   */
  function getPatchRequest(item, index) {
    return {
      id: `property_area_type_items_${index}`,
      method: 'PATCH',
      path: `/api/v1/properties/property-area-type-items/${item.id}`,
      json: { name: item.name }
    };
  }

  /**
   *  These requests are for any new items added while editing the property area type
   */
  function getPostRequest(item, index) {
    return {
      id: `property_area_type_items_${index}`,
      method: 'POST',
      path: `/api/v1/properties/property-area-type-items`,
      json: {
        name: item.name,
        property_area_type: {
          id: propertyAreaTypeId
        }
      }
    };
  }

  /**
   *  These requests are for any items removed while editing the property area type
   */
  function getDeleteRequest(id, index) {
    return {
      id: `property_area_type_items_delete_${index}`,
      method: 'DELETE',
      path: `/api/v1/properties/property-area-type-items/${id}`
    };
  }
}
