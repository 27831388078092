import React from 'react';

import { Field } from '@rexlabs/form';

import {
  MessageIssueDisclosure,
  MessageIssueDisclosureProps
} from './message-issue-disclosure';

type MessageIssueDisclosureFieldProps = MessageIssueDisclosureProps & {
  fieldKey: string;
};
export function MessageIssueDisclosureField({
  fieldKey,
  ...props
}: MessageIssueDisclosureFieldProps) {
  return (
    <Field name={fieldKey} Input={MessageIssueDisclosure} inputProps={props} />
  );
}
