import React from 'react';

import { Truncate } from '@rexlabs/text';
import { FeeAmount } from 'src/modules/agency-fees/types/agency-fee-types';
import { TaxType } from 'data/models/entities/financials/tax-types';
import { formatDynamicAmount } from '../utils/format-dynamic-amount';

export function AmountTableCell({
  feeAmount,
  taxType
}: {
  feeAmount: FeeAmount;
  taxType: TaxType;
}) {
  return (
    <Truncate>{`${formatDynamicAmount(feeAmount)} ${taxType.label}`}</Truncate>
  );
}
