import { useDialog } from '@rexlabs/dialog';
import { SecurityDeposit } from 'data/models/entities/financials/security-deposits';
import { SingleActionDeclaration } from 'src/modules/common/actions/types/action-declaration-types';
import { CreateSecurityDepositReturnRequestDialog } from '../../dialogs/create-security-deposit-return-request';

export function useGetCreateSecurityDepositReturnRequestAction() {
  const { open: openCreateSecurityDepositReturnRequestDeposit } = useDialog(
    CreateSecurityDepositReturnRequestDialog
  );

  return (securityDeposit: SecurityDeposit): SingleActionDeclaration => {
    return {
      id: 'create-request',
      group: 'create',
      label: 'Create deposit return',
      handleAction: () =>
        openCreateSecurityDepositReturnRequestDeposit({
          securityDeposit
        })
    };
  };
}
