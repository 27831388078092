import React from 'react';
import { useToast } from 'view/components/@luna/notifications/toast';
import { RecordObject } from 'data/models/types';
import { useErrorDialog } from '@rexlabs/dialog';
import { getRecordTitle } from 'utils/records/get-record-title';
import { api } from 'utils/api/api-client';
import _ from 'lodash';
import { SingleActionDeclaration } from 'src/modules/common/actions/types/action-declaration-types';

export interface DownloadLinkActionArgs {
  id: string;
  group: string;
  actionName: string;
  label?: string;
  message?: React.ReactNode;
}

export type ReportFormat = 'aba' | 'bpay' | 'pdf' | 'sage' | 'bacs';

/**
 * @deprecated we should move the actual download api calls to model-generator actions that get passed in to the new hook
 * @see useModelDownloadAction
 */
export const useReportDownloadAction = () => {
  const { addToast } = useToast();
  const { open } = useErrorDialog();

  return function (
    label: string,
    record: RecordObject,
    format: ReportFormat,
    disposition = 'download_link',
    onComplete?: () => void
  ): SingleActionDeclaration {
    return {
      id: format,
      group: 'reporting',
      label,
      handleAction: async () => {
        const description =
          `Your ${format} file is being generated` +
          (disposition === 'asynchronous_link'
            ? ', and you will receive the download link in a notification'
            : '');

        addToast({
          title: getRecordTitle(record),
          description,
          color: 'information'
        });
        try {
          // noinspection TypeScriptValidateJSTypes
          const report = await api.post(
            `/reporting/${_.kebabCase(record.type)}`,
            {
              id: record.object.id,
              format: format,
              disposition
            }
          );

          if (disposition === 'asynchronous_link') {
            onComplete?.();
            return;
          }
          const url = report.data.link;
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `${getRecordTitle(record)}.pdf`);
          document.body.appendChild(link);
          link.click();
          link.remove();
          onComplete?.();
        } catch (e) {
          open(e);
        }
      }
    };
  };
};
