import { Checkbox } from '@rexlabs/checkbox';
import { useModelActions } from '@rexlabs/model-generator';
import { padding, StylesProvider } from '@rexlabs/styling';
import { CellProps } from '@rexlabs/table';
import {
  financialsInvoicesModel,
  Invoice
} from 'data/models/entities/financials/invoices';
import { PendingOwnershipDisbursement } from 'data/models/entities/financials/pending-disbursements/pending-ownership-disbursement';
import React, { useCallback, useState } from 'react';
import LoadingSpinner from '@rexlabs/loading-spinner';
import { useInvoiceActions } from 'src/modules/invoices/actions/use-invoice-actions';
import { transformActionDeclarationsToActionMenuItems } from 'utils/actions/transforms';
import { PendingContactDisbursement } from 'data/models/entities/financials/pending-disbursements/pending-contact-disbursement';
import { createStaticTableBlock } from 'view/components/record-screen/cards/create-static-table-block';
import { Message } from 'view/components/@luna/message';
import EmptyTrustJournalEntriesIllustration from 'assets/illustrations/empty-trust-journal-entries-table.svg';
import { useRecord } from 'src/lib/react-query/hooks/use-record';
import { disbursementInvoiceColumns } from '../utils/disbursement-invoice-columns';

const tokens = {
  checkbox: {
    container: {
      ...padding.tokens({
        y: 'none'
      })
    }
  }
};

function IncludeInPayoutCell({
  value
}: CellProps<unknown, { invoice: Invoice }>) {
  const { invoice } = value;

  const [isLoading, setIsLoading] = useState(false);
  const { updateItem } = useModelActions(financialsInvoicesModel);

  const { refetch } = useRecord<PendingContactDisbursement>();

  const handleChange = async (release_amount_pending_disbursement: boolean) => {
    setIsLoading(true);
    await updateItem({
      id: invoice.id,
      data: {
        release_amount_pending_disbursement
      }
    });

    await refetch();

    setIsLoading(false);
  };

  if (isLoading) return <LoadingSpinner size={16} strokeWidth={2} />;

  return (
    <StylesProvider tokens={tokens}>
      <Checkbox
        label='Include'
        value={invoice.release_amount_pending_disbursement}
        onChange={() =>
          handleChange(!invoice.release_amount_pending_disbursement)
        }
      />
    </StylesProvider>
  );
}

export const useDirectDebitInvoicesBlock = () => {
  const getInvoiceActions = useInvoiceActions();

  const getActionMenuItems = useCallback(({ item }) => {
    return transformActionDeclarationsToActionMenuItems(
      getInvoiceActions(item)
    );
  }, []);

  return createStaticTableBlock<
    PendingOwnershipDisbursement | PendingContactDisbursement,
    Invoice
  >({
    id: 'directDebitInvoices',
    title: 'Direct debit invoices',
    getTableProps: ({ data }) => {
      return {
        id: 'direct-debit-invoices',
        items: data?.disburseable_direct_debit_invoices?.data ?? [],
        columns: [
          ...disbursementInvoiceColumns,
          {
            id: 'include-in-payout',
            accessor: (item) => {
              return {
                invoice: item,
                contactId: data.id
              };
            },
            Header: 'Include in payout?',
            Cell: IncludeInPayoutCell
          }
        ],
        getActionMenuItems: getActionMenuItems
      };
    },
    Empty: () => (
      <Message
        Illustration={EmptyTrustJournalEntriesIllustration}
        title='Direct debit invoices'
      >
        There are no direct debit invoices at this moment
      </Message>
    )
  });
};
