export const CHART_OF_ACCOUNTS_ROUTES = {
  CHART_OF_ACCOUNTS: {
    config: {
      path: '/chart-of-accounts',
      Component: require('../chart-of-accounts/screens/chart-of-accounts')
        .ChartOfAccountsScreen
    },
    CREATE: {
      config: {
        path: '/chart-of-accounts/create',
        Component: require('./dialogs/create-chart-of-accounts-account-dialog')
          .CreateChartOfAccountsAccountDialog
      }
    }
  }
};
