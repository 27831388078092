import { useDialog } from '@rexlabs/dialog';
import SearchIcon from 'view/components/icons/search';
import { SuggestMatchDialog } from '../../dialogs/suggest-match-dialog';
import { BatchReceiptingItem } from '../../types';

export function useGetSuggestMatchAction() {
  const { open: openSuggestMatchDialog } = useDialog(SuggestMatchDialog);

  return (item: BatchReceiptingItem) => {
    return {
      Icon: SearchIcon,
      label: 'Suggest match',
      onClick: () => openSuggestMatchDialog({ batchReceiptingItem: item })
    };
  };
}
