import * as React from 'react';
import dayjs from 'dayjs';

import { ListTableProps } from 'view/components/table/list-table';
import { Tab } from 'view/components/table/tabbed';
import { TaskTableEmptyState } from 'src/modules/tasks/common/components/task-table-empty-state';

const now = dayjs().format('YYYY-MM-DD');

const filterOpen = {
  field: 'closed_at',
  op: 'eq',
  value: 'null'
};

const filterClosed = {
  field: 'closed_at',
  op: 'neq',
  value: 'null'
};

const filterFollowUp = {
  field: 'follow_up_date',
  op: 'lte',
  value: now
};

const filterDue = {
  field: 'due_date',
  op: 'eq',
  value: now
};

const filterOverdue = {
  field: 'due_date',
  op: 'lt',
  value: now
};

const taskTableTabsBaseConfigMap = {
  open: {
    name: 'open',
    label: 'Open',
    Empty: () => <TaskTableEmptyState label='Open' />,
    forcedGlobalFilter: [filterOpen]
  },

  follow_up: {
    name: 'follow_up',
    label: 'Follow up',
    Empty: () => <TaskTableEmptyState label='Follow up' />,
    forcedGlobalFilter: [filterOpen, filterFollowUp]
  },
  due: {
    name: 'due',
    label: 'Due',
    Empty: () => <TaskTableEmptyState label='Due' />,
    forcedGlobalFilter: [filterOpen, filterDue]
  },
  overdue: {
    name: 'overdue',
    label: 'Overdue',
    Empty: () => <TaskTableEmptyState label='Overdue' />,
    forcedGlobalFilter: [filterOpen, filterOverdue]
  },
  closed: {
    name: 'closed',
    label: 'Closed',
    Empty: () => <TaskTableEmptyState label='Closed' />,
    forcedGlobalFilter: [filterClosed]
  }
};

function getTabWithCommonTabProps(
  commonTabProps: ListTableProps,
  tabConfig: ListTableProps
): Tab {
  return {
    ...commonTabProps,
    ...tabConfig,
    forcedGlobalFilter: [
      ...(commonTabProps.forcedGlobalFilter || []),
      ...(tabConfig.forcedGlobalFilter || [])
    ]
  };
}

type tabNames = keyof typeof taskTableTabsBaseConfigMap;

export function getTaskTabbedTableTabs(
  commonTabProps: Partial<Tab<ListTableProps>>,
  tabs: tabNames[] = Object.keys(taskTableTabsBaseConfigMap) as tabNames[]
) {
  return tabs.map((tabName) =>
    getTabWithCommonTabProps(
      commonTabProps,
      taskTableTabsBaseConfigMap[tabName]
    )
  );
}
