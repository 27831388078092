import { useCallback } from 'react';
import flagsmith from 'flagsmith';
import { copyToClipboardBulkAction } from 'utils/actions/export-table/copy-to-clipboard-bulk-action';
import { BulkAction } from '@rexlabs/table/lib/types/bulk-actions/simple';
import { useWorkInProgress } from '../../work-in-progress';

export function useListTableActions({
  entity,

  columns,
  getBulkActions: propGetBulkActions,
  getActionMenuItems: propGetActionMenuItems
}) {
  const [showWorkInProgress] = useWorkInProgress();

  const getActionMenuItems = useCallback(
    ({ item }) => {
      let prevAction;
      const items = propGetActionMenuItems?.({ entity, item })?.filter?.(
        (action) => {
          // NOTE: disabling TS here because `workInProgress` does not exist on the vivid
          // action menu item type, but we're using it in Alfred to hide any WIP features
          // eslint-disable-next-line
          // @ts-ignore
          const hide = action.workInProgress && !showWorkInProgress;
          if (hide) {
            return false;
          }

          // HACK: after we potentially filtered out all work in progress actions, we want to
          // filter out all dividers that are now not having any actions in between them
          // eslint-disable-next-line
          // @ts-ignore
          const isDuplicateDivider = action.isDivider && prevAction.isDivider;
          prevAction = action;
          return !isDuplicateDivider;
        }
      );

      // After all the filtering above, if the last item in the items array is a divider
      // we also want to get rid of it
      // eslint-disable-next-line
      // @ts-ignore
      if (items?.[items.length]?.isDivider) {
        items.pop();
      }

      return items;
    },
    [entity, propGetActionMenuItems, showWorkInProgress]
  );

  const getBulkActions = useCallback(
    (args) => {
      return (propGetBulkActions?.({ ...args, entity }) || [])
        .concat(
          copyToClipboardBulkAction({
            ...args,
            entity,
            columns
          })
        )
        .filter(filterEnabledActions);
    },
    [entity, propGetBulkActions, columns]
  );

  return {
    getActionMenuItems,
    getBulkActions
  };
}

function filterEnabledActions(action: BulkAction & { flag?: string }) {
  if (action.flag) {
    return flagsmith.hasFeature(action.flag);
  }

  return true;
}
