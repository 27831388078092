import React from 'react';
import { CellProps, TagCell } from '@rexlabs/table';
import { StatusAverageTag, StatusBadTag, NeutralTag } from '@rexlabs/tag';

export function DaysInArrearsCell({ value }: CellProps) {
  const TagComponent =
    value >= 8 ? StatusBadTag : value > 0 ? StatusAverageTag : NeutralTag;
  const text = value > 0 ? `${value} days` : 'n/a';

  return (
    <TagCell>
      <TagComponent>{text}</TagComponent>
    </TagCell>
  );
}
