const contactMessageTriggerKeys = [
  'contact-disbursement-template',
  'contact-payment-receipt-template'
] as const;
export type ContactMessageTriggerKey = typeof contactMessageTriggerKeys[number];

const ownershipMessageTriggerKeys = [
  'inspection-template',
  'ownership-disbursement-template',
  'periodic-ownership-statement-template',
  'yearly-ownership-statement-template',
  'ownership-invoice-template'
] as const;
export type OwnershipMessageTriggerKey = typeof ownershipMessageTriggerKeys[number];

const tenancyMessageTriggerKeys = ['tenancy-invoice-template'] as const;
export type TenancyMessageTriggerKey = typeof tenancyMessageTriggerKeys[number];

export const messageTriggerKeys = [
  ...contactMessageTriggerKeys,
  ...ownershipMessageTriggerKeys,
  ...tenancyMessageTriggerKeys
] as const;
export type MessageTriggerKey = typeof messageTriggerKeys[number];
