import { BaseModelGeneratorModel } from '@rexlabs/model-generator';
import { Generator } from 'data/models/generator';
import { api } from 'utils/api/api-client';

export type SuspendedTransactions = BaseModelGeneratorModel;

const config = {
  entities: {
    related: {
      bank_statement_transaction_import: {
        include: 'bank_statement_transaction_import'
      }
    },
    api: {
      fetchList: (_type, args) => {
        const { reconciliationId, ...rest } = args;

        return api.get(
          `/financials/reconciliations/${reconciliationId}/suspended-transactions`,
          rest
        );
      }
    }
  }
};

export const suspendedTransactionsModel = new Generator<SuspendedTransactions>(
  'financials/reconciliations/suspended-transactions',
  config
).createEntityModel();
