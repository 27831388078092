import { useStyles, StyleSheet } from '@rexlabs/styling';
import { TitleBlock } from 'view/components/cards/title/title-block';
import { RecordObject, RecordTypes } from 'data/models/types';
import { getRecordTitle } from 'utils/records/get-record-title';
import { TagConfig } from 'view/components/@luna/title-block';
import { ActionDeclaration } from 'src/modules/common/actions/types/action-declaration-types';
import { useGetPrimaryRecordActionGroup } from 'view/hooks/actions/use-get-primary-record-action-group';
import { useGetToggleClosedAction } from 'src/modules/tasks/common/hooks/action-declarations/use-get-toggle-closed-action';
import { formatDate } from 'utils/dates/format';
import { lowerCase } from 'lodash';
import React from 'react';
import { useTodoStatusTag } from 'src/modules/tasks/todos/hooks/use-todo-status-tag';
import TaskIcon from 'view/components/icons/task';
import { tasksModel } from 'src/modules/tasks/common/models/tasks-model';
import { useGetCreateSMSAction } from 'src/modules/communications/messages/hooks/action-declarations/use-get-create-sms-action';
import { useGetCreateEmailAction } from 'src/modules/communications/messages/hooks/action-declarations/use-get-create-email-action';
import { TodoTask } from '../types/TodoTask';

const type = RecordTypes.Todo;

type TodoRecordObject = Extract<RecordObject, { type: 'todo' }>;

type TodoTitleBlockProps = {
  todo?: TodoTask;
  onStatusChange?: () => void;
};

const styles = StyleSheet({
  subText: {
    color: ({ token }) => token('color.textStyle.body.default'),
    fontWeight: ({ token }) => token('typography.weight.semibold')
  }
});

export function TodoTitleBlock({ todo }: TodoTitleBlockProps) {
  const s = useStyles(styles);

  const getPrimaryRecordActionGroup = useGetPrimaryRecordActionGroup();
  const getToggleClosedAction = useGetToggleClosedAction();
  const getCreateEmailAction = useGetCreateEmailAction(tasksModel);
  const getCreateSMSAction = useGetCreateSMSAction(tasksModel);

  const statusTag: TagConfig = useTodoStatusTag(todo);

  const title = getRecordTitle({ type, object: todo } as TodoRecordObject);
  if (!todo) {
    return null;
  }

  const actions = [
    ...getPrimaryRecordActionGroup('todo', todo.id),
    getToggleClosedAction(todo),
    getCreateEmailAction(todo),
    getCreateSMSAction(todo)
  ];

  const closed_at = todo.closed_at;

  return (
    <TitleBlock
      title={title}
      type={'to-do'}
      actions={actions as ActionDeclaration[]}
      tags={closed_at ? undefined : [statusTag]}
      Icon={() => <TaskIcon size='l' />}
      subText={
        closed_at ? (
          <div>
            <span>This to-do task has been closed with the status </span>
            <span {...s('subText')}>
              {lowerCase(todo.status.label)}
            </span> on <span {...s('subText')}>{formatDate(closed_at)}</span>
          </div>
        ) : undefined
      }
      // TODO: add reference once it has been added to the backend
      //reference={todo?.record_reference}
    />
  );
}
