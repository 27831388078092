import { ServicePackage } from 'src/modules/service-packages/types/service-package';
import { ActionDeclaration } from 'src/modules/common/actions/types/action-declaration-types';
import { UpdateServicePackageDialog } from 'src/modules/service-packages/dialogs/update-service-package-dialog';
import { useDialog } from '@rexlabs/dialog';

export function useGetUpdateServicePackageAction() {
  const { open } = useDialog(UpdateServicePackageDialog);

  return (servicePackage: ServicePackage): ActionDeclaration => ({
    id: 'update-service-package',
    group: 'service-packages',
    label: 'Edit',
    handleAction: () => open({ servicePackage })
  });
}
