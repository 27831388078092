import { useTableFilters } from 'view/hooks/use-table-filters';
import { useGetDuplicateServicePackageAction } from 'src/modules/service-packages/hooks/action-declarations/use-get-duplicate-service-package-action';
import { ListTable } from 'view/components/table';
import { servicePackagesModel } from 'src/modules/service-packages/models/service-packages-model';
import { transformActionDeclarationsToActionMenuItems } from 'utils/actions/transforms';
import React from 'react';
import { useDialog } from '@rexlabs/dialog';
import { CreateServicePackageDialog } from 'src/modules/service-packages/dialogs/create-service-package-dialog';
import { EmptyCard } from 'view/components/record-screen/cards/empty-card';
import { Message } from '@rexlabs/notifications';
import EmptyTable from 'assets/illustrations/empty-table.svg';
import { Includes, query } from '@rexlabs/model-generator';
import { AgencyFee } from 'src/modules/agency-fees/types/agency-fee-types';
import { AlfredColumnConfig } from 'view/components/table/columns/column-types';
import { ServicePackage } from 'src/modules/service-packages/types/service-package';
import { Truncate } from '@rexlabs/text';
import { useGetUpdateServicePackageAction } from 'src/modules/service-packages/hooks/action-declarations/use-get-update-service-package-action';

const getServicePackagesQuery = () => {
  return query`{
  ${servicePackagesModel} {
    id
    name
    agency_fees
  }
}`;
};

function getAgencyFeeDescription(agencyFees?: AgencyFee[]) {
  return agencyFees?.map((agencyFee) => agencyFee.name).join(', ') || '--';
}

const columns: AlfredColumnConfig<ServicePackage>[] = [
  { id: 'name', Header: 'Service package name' },
  {
    id: 'agency_fees',
    Header: 'Default fee(s)',
    width: '50%',
    Cell: ({ value }: { value?: Includes<AgencyFee[]> }) => {
      return <Truncate>{getAgencyFeeDescription(value?.data)}</Truncate>;
    },
    toCsv: (item?: Includes<AgencyFee[]>) => {
      return getAgencyFeeDescription(item?.data);
    }
  }
];

export function ServicePackagesTable() {
  const { getSort, getFilters } = useTableFilters('service-packages');
  const getDuplicateServicePackageAction = useGetDuplicateServicePackageAction();
  const getUpdateServicePackageAction = useGetUpdateServicePackageAction();
  return (
    <ListTable<typeof servicePackagesModel>
      columns={columns}
      getSort={getSort}
      getFilters={getFilters}
      id='service-packages-table'
      getQuery={getServicePackagesQuery}
      getActionMenuItems={({ item }) =>
        transformActionDeclarationsToActionMenuItems([
          getUpdateServicePackageAction(item),
          getDuplicateServicePackageAction(item)
        ])
      }
      Empty={ServicePackagesTableEmptyState}
    />
  );
}

function ServicePackagesTableEmptyState() {
  const { open } = useDialog(CreateServicePackageDialog);

  return (
    <EmptyCard>
      <Message
        title='Service packages'
        Illustration={EmptyTable}
        actions={[
          {
            label: 'Add service package',
            handleAction: () => open(),
            type: 'primary'
          }
        ]}
      >
        Fees can be grouped together under a service package
      </Message>
    </EmptyCard>
  );
}
