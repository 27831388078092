import React, { useMemo, SetStateAction, Dispatch } from 'react';
import Box from '@rexlabs/box';
import { Forms } from '@rexlabs/form';
import { StyleSheet, useStyles } from '@rexlabs/styling';
import { useWhereabouts } from '@rexlabs/whereabouts';
import invariant from 'invariant';

import { Card, FormConfig } from './cards/core';
import { TabConfig, ContentConfig } from './types';
import { CardErrorBoundary } from './cards/error-boundary';

const defaultStyles = StyleSheet({
  group: {
    display: 'flex',
    flexDirection: 'column'
  }
});

interface ContentProps {
  content: ContentConfig;
  data?: any;
  forms?: Forms;
  initialValues?: any;
  isLoading?: boolean;
  blockEditModes?: { [key: string]: boolean };
  setBlockEditModes?: Dispatch<SetStateAction<{ [key: string]: boolean }>>;
  setForms?: Dispatch<SetStateAction<FormConfig[]>>;
}

export function Content({
  content,
  data,
  initialValues,
  isLoading,
  blockEditModes,
  setBlockEditModes,
  setForms,
  forms
}: ContentProps) {
  const s = useStyles(defaultStyles);
  const whereabouts = useWhereabouts();
  const currentTab = whereabouts.query?.tab;

  const items = useMemo(
    () =>
      content.reduce<TabConfig[]>((all, group) => all.concat(group.items), []),
    [content]
  );

  // Get current menu item from URL
  const currentId =
    items.find((item) => item.id === currentTab)?.id || items[0]?.id;

  const item = items.find((item) => item.id === currentId);
  invariant(item, `Content block item can't be null or undefined`);

  return (
    <div id='content-cards'>
      <Box
        id={`content-card-${item.id}`}
        key={item.id}
        flexDirection='column'
        sy='2.4rem'
        {...s('group')}
      >
        {item?.blocks?.map((block, index) => {
          // HACK: using the edit mode in the key here, which might not be ideal, but
          // without it, things like useLayoutEffect (i.e. where we do the measuring
          // for the responsive grids) don't work correctly when toggling between edit
          // and view modes
          const key = `${item.id}//${block?.id || index}`;

          return (
            <CardErrorBoundary key={key} title={block.title}>
              <Card
                {...block}
                data={data}
                initialValues={initialValues}
                isLoading={isLoading}
                blockEditModes={blockEditModes}
                setBlockEditModes={setBlockEditModes}
                setForms={setForms}
                forms={forms}
              />
            </CardErrorBoundary>
          );
        })}
      </Box>
    </div>
  );
}
