import { Options, useCachedItems } from 'view/hooks/api/cached-items';
import { api } from 'utils/api/api-client';
import { SearchResultItem } from 'utils/api/get-search-results';
import { getSearchResultItemFromObjectAndModel } from 'src/modules/common/utils/search-result-items/get-search-result-items-from-object-and-model';
import { tenancyModel } from 'data/models/entities/tenancies';
import { ownershipsModel } from 'data/models/entities/ownerships';
import { RecordData } from 'utils/types';
import { PropertyTenancy } from 'src/modules/property-tenancies/types/property-tenancy-types';

export type ObjectType = 'contact' | 'property' | 'tenancy' | 'ownership';

export interface UsePropertyRelatedItemsParams
  extends Partial<Options<SearchResultItem<RecordData<any>>>> {
  objectTypes: ObjectType[];
  propertyId?: string;
  tenancyTypes: Array<'incoming' | 'active' | 'outgoing'>;
  existingItems?: SearchResultItem[];
  includePortfolioMemberContacts?: boolean;
}

const hasObjectType = (array: ObjectType[], type: ObjectType) => {
  if (!Array.isArray(array)) return false;

  return array.includes(type);
};

export function usePropertyRelatedItems({
  propertyId,
  existingItems,
  objectTypes,
  tenancyTypes,
  includePortfolioMemberContacts,
  ...options
}: UsePropertyRelatedItemsParams) {
  const includes = [
    'active_property_ownership',
    'active_property_ownership.ownership',
    'active_property_tenancy',
    'active_property_tenancy.tenancy',
    'incoming_property_tenancy',
    'incoming_property_tenancy.tenancy',
    'property_tenancies.tenancy',
    'portfolio.members.user.contact'
  ].join(',');

  const disabled = !propertyId;
  const propertyUrl = `/properties/${propertyId}?include=${includes}`;

  return useCachedItems(
    [propertyUrl, ...tenancyTypes].join('-'),
    async () => {
      if (existingItems) {
        return existingItems;
      }

      const propertyRequest = await api.get(propertyUrl);

      const results: SearchResultItem[] = [];

      const activeTenancy = getSearchResultItemFromObjectAndModel(
        propertyRequest.data.active_property_tenancy?.tenancy,
        tenancyModel
      );

      const incomingTenancy = getSearchResultItemFromObjectAndModel(
        propertyRequest.data.incoming_property_tenancy?.tenancy,
        tenancyModel
      );

      const outgoingTenancy = getSearchResultItemFromObjectAndModel(
        propertyRequest.data.property_tenancies?.data?.find(
          (leaseAgreement: PropertyTenancy) =>
            leaseAgreement?.status?.id === 'outgoing'
        )?.tenancy,
        tenancyModel
      );

      const activeOwnership = getSearchResultItemFromObjectAndModel(
        propertyRequest.data.active_property_ownership?.ownership,
        ownershipsModel
      );

      const hasTenancyObjectType = hasObjectType(objectTypes, 'tenancy');
      const hasActiveTenancy = activeTenancy != null;
      const allowActiveTenancy = tenancyTypes.includes('active');

      if (hasActiveTenancy && hasTenancyObjectType && allowActiveTenancy) {
        results.push(activeTenancy);
      }

      const hasIncomingTenancy = incomingTenancy != null;
      const allowIncomingTenancy = tenancyTypes.includes('incoming');

      if (hasIncomingTenancy && hasTenancyObjectType && allowIncomingTenancy) {
        results.push(incomingTenancy);
      }

      const hasOutgoingTenancy = outgoingTenancy != null;
      const allowOutgoingTenancy = tenancyTypes.includes('outgoing');

      if (hasOutgoingTenancy && hasTenancyObjectType && allowOutgoingTenancy) {
        results.push(outgoingTenancy);
      }

      const hasOwnershipObjectType = hasObjectType(objectTypes, 'ownership');
      const hasActiveOwnership = activeOwnership != null;

      if (hasActiveOwnership && hasOwnershipObjectType) {
        results.push(activeOwnership);
      }

      if (includePortfolioMemberContacts) {
        const portfolioMembers =
          propertyRequest.data.portfolio?.members?.data || [];
        const portfolioMemberContacts = portfolioMembers.map(
          (portfolioMember) => portfolioMember.user.contact
        );
        const portfolioMemberContactSearchResultItems = portfolioMemberContacts.map(
          (contact) => getSearchResultItemFromObjectAndModel(contact, 'contact')
        );

        portfolioMemberContactSearchResultItems.length > 0 &&
          portfolioMemberContactSearchResultItems.forEach((item) =>
            results.push(item)
          );
      }

      return results as SearchResultItem[];
    },
    { disabled, ...options }
  );
}
