import React from 'react';

import { BlockConfig } from 'view/components/record-screen/types';
import { Invoice } from 'data/models/entities/financials/invoices';
import Box from '@rexlabs/box';
import { InvoiceDetails } from '../components/invoice-details';
import { InvoiceLineItems } from '../components/invoice-line-items';

const validate = {
  definitions: {
    'payable_to.object': { name: 'payable to', rules: 'required' },
    'payable_by.object': { name: 'payable by', rules: 'required' },
    description: { rules: 'required' },
    due_date: { rules: 'required' },
    is_tax_included: { rules: 'required' },
    bank_account: { rules: 'required' }
  }
};

export const invoiceDetailsBlock: BlockConfig<Invoice> = {
  id: 'details',
  title: 'Details',
  validate,
  View: ({ data }) => {
    return (
      <Box>
        <InvoiceDetails invoice={data} />

        <Box marginTop={24}>
          <InvoiceLineItems data={data} />
        </Box>
      </Box>
    );
  }
};
