import React from 'react';
import { Property } from 'src/modules/properties/types/property-types';

import { Tab } from 'view/components/table/tabbed';

import { KeySetTableEmptyState } from '../components/key-set-table-empty-state';
import { useActiveColumns } from '../data/key-set-table-columns';
import { useCommonTabProps } from './use-common-tab-props';

export function useActiveTabProps(property: Property): Tab {
  const commonTabProps = useCommonTabProps();
  const activeColumns = useActiveColumns();

  return {
    ...commonTabProps,
    columns: activeColumns,
    emptyWithoutTable: true,
    Empty: () => <KeySetTableEmptyState property={property} />,
    forcedGlobalFilter: [
      {
        field: 'property_id',
        op: 'eq',
        value: property.id
      },
      {
        field: 'status_id',
        op: 'neq',
        value: 'archived'
      }
    ],
    name: 'active',
    label: 'Active'
  };
}
