import { ActionDeclaration } from 'src/modules/common/actions/types/action-declaration-types';
import { useDialog } from '@rexlabs/dialog';
import AddIcon from 'view/components/icons/add';
import { PropertyAreaTypeRecordDialog } from 'src/modules/property-areas/property-area-types/dialogs/property-area-type-dialog';
import { useCreatePropertyAreaTypeSubmitHandler } from 'src/modules/property-areas/property-area-types/hooks/use-create-property-area-type-submit-handler';

export function useGetCreatePropertyAreaTypeAction() {
  const { open } = useDialog(PropertyAreaTypeRecordDialog);
  const getPropertyAreaTypeCreateSubmit = useCreatePropertyAreaTypeSubmitHandler();

  return (): ActionDeclaration => {
    return {
      id: 'create-property-area-type',
      group: 'property-area-type',
      label: 'Add area type',
      Icon: AddIcon,
      intent: 'primary',
      handleAction: async () => {
        return open({
          title: 'Create area type',
          onSubmit: getPropertyAreaTypeCreateSubmit,
          initialValues: {},
          submitLabel: 'Create area'
        });
      }
    };
  };
}
