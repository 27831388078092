import * as React from 'react';

export function CommentWithBadgeIcon(props: { hasBadge?: boolean }) {
  const { hasBadge = false } = props;

  return (
    <svg
      width='16'
      height='16'
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      overflow='visible'
    >
      <path
        d='m5 15 4-3m-4 0v3m0-3c-2.147 0-4-1.76-4-3.929V4.93C1 2.759 2.741 1 4.889 1h6.222C13.26 1 15 2.76 15 4.929V8.07c0 2.171-1.741 3.93-3.889 3.93H9'
        stroke='currentColor'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      {hasBadge ? <circle fill='red' cx='15' cy='3' r='4' /> : null}
    </svg>
  );
}
