import React from 'react';
import { Field } from '@rexlabs/form';
import { TextInput } from '@rexlabs/text-input';
import { ChartOfAccountsAccountSelect } from 'view/components/inputs/selects/v4-selects/chart-of-accounts-account';
import { TaxTypeSelect } from 'view/components/inputs/selects/tax-type';
import { CentAmountInput } from 'view/components/inputs/cent-amount';
import { CurrencyValue } from 'view/components/values/currency';
import { DoubleWidth } from 'view/components/layouts/form/column';
import { FormLayout } from 'view/components/layouts/form/form-layout';

interface RentScheduleEntryLineItemsFormProps {
  name: string;
  amountInclTaxLoading: boolean;
  amountInclTax?: number;
  setFieldValue?: (field: string, value: any) => void;
}

export function RentScheduleEntryLineItemsForm({
  name,
  amountInclTax,
  amountInclTaxLoading,
  setFieldValue
}: RentScheduleEntryLineItemsFormProps) {
  return (
    <FormLayout>
      <DoubleWidth>
        <Field
          name={`${name}.description`}
          label='Description'
          Input={TextInput}
        />
      </DoubleWidth>

      <Field
        name={`${name}.payable_to_chart_of_accounts_account`}
        label='Account'
        Input={ChartOfAccountsAccountSelect}
        onChange={(e: any) =>
          setFieldValue?.(`${name}.tax_type`, e.target.value.default_tax_type)
        }
      />
      <Field name={`${name}.tax_type`} label='Tax type' Input={TaxTypeSelect} />
      <Field name={`${name}.amount`} label='Amount' Input={CentAmountInput} />
      <CurrencyValue
        label='Amount incl. tax'
        value={amountInclTax}
        isLoading={amountInclTaxLoading}
      />
    </FormLayout>
  );
}
