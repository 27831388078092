import React from 'react';
import { BlockConfig } from 'view/components/record-screen/types';
import { StatBlocks } from 'view/components/@luna/stat-block';
import { formatCurrency } from 'utils/formatters';
import { Stat } from 'view/components/@luna/stat-block/stat-blocks';
import { Body } from '@rexlabs/text';
import Box from '@rexlabs/box';
import { useStyles, StyleSheet, useToken } from '@rexlabs/styling';

const defaultStyles = StyleSheet({
  subtext: {
    color: ({ token }) => token('color.textStyle.body.subtext')
  }
});

export const smsStatBlock: BlockConfig = {
  id: 'sms-stat-block',
  title: 'SMS',
  View: ({ data }) => {
    const s = useStyles(defaultStyles);
    const token = useToken();

    const stats: Stat[] = [
      {
        id: 'available-credit',
        label: `Available credit (Today)`,
        value: formatCurrency(data.availableCredits),
        intent: 'secondary'
      },
      {
        id: 'sms-sent',
        label: `SMS sent (${data.dateLabel})`,
        value: `${data.smsSent}`,
        intent: 'secondary'
      },
      {
        id: 'credit-used',
        label: `Credit used (${data.dateLabel})`,
        value: formatCurrency(data.creditUsed),
        intent: 'secondary'
      },
      {
        id: 'top-ups',
        label: `Top-ups (${data.dateLabel})`,
        value: formatCurrency(data.topUpAmount),
        intent: 'secondary'
      }
    ];

    return (
      <Box>
        <StatBlocks stats={stats} />
        <Box mt={token('spacing.xl')}>
          <Body {...s('subtext')} as={'span'}>
            To add SMS credit to your account, please contact{' '}
          </Body>{' '}
          <a href={'mailto:support@rexpm.zendesk.com'}>Rex PM Support</a>
          <Body {...s('subtext')} as={'span'}>
            .
          </Body>
        </Box>
      </Box>
    );
  }
};
