import React from 'react';
import { BlockConfig } from 'view/components/record-screen/types';
import { RecordTable } from 'view/components/table';
import { trustJournalEntryColumns } from 'src/modules/trust-ledgers/components/trust-journal-entries-table';
import { BankDeposit } from 'src/modules/banking/bank-deposits/types/bank-deposit-types';
import { Trans } from 'react-i18next';

export const trustJournalEntriesBlock: BlockConfig<BankDeposit> = {
  id: 'trust-journal-entries',
  title: () => <Trans i18nKey='trust-journal-entries.label.plural' />,
  View: ({ data }) => {
    return (
      <RecordTable
        isLoading={false}
        withToolbar={false}
        withPagination={false}
        items={data?.line_items?.items.map((el) => {
          return el.trust_journal_entry;
        })}
        id='trust-journal-entries'
        columns={trustJournalEntryColumns}
      />
    );
  }
};
