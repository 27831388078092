import React, { FC } from 'react';

import { StylesProvider } from '@rexlabs/styling';
import {
  OutlineButton,
  OutlineIconButton,
  GhostIconButton
} from '@rexlabs/button';
import ActionMenu, { RegularActionMenuItem } from '@rexlabs/action-menu';
import { Breakpoints } from '@rexlabs/breakpoints';
import Icons from '@rexlabs/icons';

const { Kebab } = Icons;

const overrides = {
  actionMenu: {
    button: {
      icon: {
        svg: () => Kebab
      }
    }
  }
};

export interface ActionButtonsProps {
  actions: RegularActionMenuItem[];
  maxActionButtons?: number;
}

export const ActionButtons: FC<ActionButtonsProps> = ({
  actions,
  maxActionButtons
}) => {
  return (
    <StylesProvider tokens={overrides}>
      <Breakpoints queries={{ maxWidth: 's' }}>
        <ActionMenu items={actions} Button={GhostIconButton} />
      </Breakpoints>
      <Breakpoints queries={{ minWidth: 's' }}>
        <Desktop actions={actions} maxActionButtons={maxActionButtons} />
      </Breakpoints>
    </StylesProvider>
  );
};

function Desktop({ actions, maxActionButtons = 2 }: ActionButtonsProps) {
  const actionsCopy = [...actions];

  if (actions?.length <= maxActionButtons) {
    return (
      <>
        {actions.map((action, idx) => (
          <OutlineButton key={idx} onClick={action.onClick}>
            {action.label}
          </OutlineButton>
        ))}
      </>
    );
  }

  return (
    <>
      <ActionMenu items={actionsCopy.slice(1)} Button={OutlineIconButton} />
      <OutlineButton onClick={actions[0].onClick}>
        {actions[0]?.label}
      </OutlineButton>
    </>
  );
}
