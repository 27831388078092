import { useWhereabouts } from '@rexlabs/whereabouts';
import { Columns } from 'view/components/table';
import { SummaryCell } from '../../common/components/summary-cell';
import { QuoteTask } from '../types/QuoteTask';
import { closedDate, managedBy } from '../../common/utils/common-columns';
import { AnyTaskSummaryCell } from '../../common/components/any-task-summary-cell';
import { Task } from '../../common/types/Task';
import { ProgressDropdown } from '../components/progress-dropdown';

const priority = {
  id: 'priority_id',
  type: 'value',
  Header: 'Priority',
  accessor: (item: QuoteTask) => item.priority,
  toCsv: (item: QuoteTask) => item?.priority?.label
} as const;

const summary = {
  id: 'summary',
  Header: 'Summary',
  Cell: SummaryCell,
  accessor: (item: QuoteTask) => item,
  width: 380,
  toCsv: (item: QuoteTask) => item.summary
};

const status = {
  id: 'status',
  Header: 'Task progress',
  Cell: ProgressDropdown,
  accessor: (item: QuoteTask) => item,
  width: 200,
  toCsv: (item: QuoteTask) => item.details?.status?.label ?? ''
};

const followUpDate = {
  id: 'follow_up_date',
  Header: 'Follow up date',
  accessor: (item: QuoteTask) => item.follow_up_date,
  type: 'date',
  width: 140
} as const;

const dueDate = {
  id: 'due_date',
  Header: 'Due date',
  accessor: (item: QuoteTask) => item.due_date,
  type: 'date',
  width: 140
} as const;

const quotedPrice = {
  id: 'quoted_price',
  type: 'currency',
  Header: 'Quoted price',
  accessor: (item: QuoteTask) => item.details?.amount || null,
  width: 140
} as const;

const relatesToTask = {
  id: 'parent_task',
  Header: 'Relates to task',
  Cell: AnyTaskSummaryCell,
  accessor: (item: QuoteTask) => item.parent_task,
  width: 200,
  toCsv: (item: Task<any>) => item?.summary || '-'
};

export function useQuoteColumns(): Columns<QuoteTask> {
  const whereabouts = useWhereabouts();
  const tab = whereabouts.hashQuery?.tab;

  if (tab === 'closed') {
    return [
      summary,
      relatesToTask,
      closedDate,
      dueDate,
      quotedPrice,
      managedBy,
      priority
    ];
  }

  return [
    summary,
    relatesToTask,
    followUpDate,
    dueDate,
    quotedPrice,
    status,
    managedBy,
    priority
  ];
}
