import { PendingContactDisbursement } from 'data/models/entities/financials/pending-disbursements/pending-contact-disbursement';
import { PendingOwnershipDisbursement } from 'data/models/entities/financials/pending-disbursements/pending-ownership-disbursement';
import { ActionDeclaration } from 'src/modules/common/actions/types/action-declaration-types';
import { useTranslation } from 'react-i18next';
import { useDisburseSelected } from '../../hooks/use-disburse-selected';

export interface GetDisburseFundsActionArgs {
  recordType: 'contact' | 'ownership';
  pendingDisbursement:
    | PendingContactDisbursement
    | PendingOwnershipDisbursement;
  refreshBillsAndFees?: boolean;
}

export function useGetDisburseFundsAction() {
  const { t } = useTranslation();
  const disburseSelected = useDisburseSelected();

  return ({
    pendingDisbursement,
    refreshBillsAndFees = false
  }: GetDisburseFundsActionArgs): ActionDeclaration => ({
    id: 'disburse-funds',
    group: 'disbursement',
    label: t('disbursements.disburse-now') as string,
    handleAction: () =>
      disburseSelected([pendingDisbursement], refreshBillsAndFees)
  });
}
