export const MOVE_OUT_ROUTES = {
  MOVE_OUT_LIST: {
    config: {
      path: '/move-outs',
      Component: require('./screens/move-out-list.tsx').MoveOutList
    }
  },
  MOVE_OUT_DETAILS: {
    config: {
      path: '/move-out/:moveOutId',
      Component: require('./screens/move-out-details.tsx').MoveOutDetails
    }
  }
};
