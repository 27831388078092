import React, { PropsWithChildren, useCallback } from 'react';

import { Field, HiddenField } from '@rexlabs/form';
import { TextInput } from '@rexlabs/text-input';

import { useInsertToTextInput } from 'src/modules/communications/common/hooks/use-insert-to-input';

import { CommunicationContextType } from 'src/modules/communications/common/types/communication-context-type';

import { FroalaEditorInput } from 'view/components/@luna/inputs/froala-editor/components/froala-editor-input';
import { useAddFilesForEditor } from 'view/components/@luna/inputs/froala-editor/hooks/use-add-files-for-editor';
import { useInsertToEditor } from 'view/components/@luna/inputs/froala-editor/hooks/use-insert-to-editor';
import { EditMessageContentContainer } from './edit-message-content-container';

export interface BasicEditEmailFormProps {
  contextType?: CommunicationContextType;
  setFieldValue?: (field: string, value: any) => void;
}

export const validateEditEmailForm = {
  definitions: {
    subject: { name: 'subject', rules: 'required' },
    content: { name: 'content', rules: 'required' }
  }
};

/**
 * Basic form for editing email content. It has two columns:
 *   - first column includes the subject and content fields
 *   - second column includes the merge tag component
 */
export function BasicEditEmailForm({
  setFieldValue,
  contextType,
  children
}: PropsWithChildren<BasicEditEmailFormProps>) {
  const { setFileIdToAdd, setFileIdToRemove } = useAddFilesForEditor({
    fileField: 'linked_files'
  });

  const updateSubjectValue = useCallback(
    (value: string) => {
      setFieldValue?.('subject', value);
    },
    [setFieldValue]
  );

  const {
    inputRef,
    shouldInsertToInput,
    setShouldInsertToInput,
    insertToTextInput
  } = useInsertToTextInput(updateSubjectValue);

  const { editorRef, insertToEditor } = useInsertToEditor();

  return (
    <EditMessageContentContainer
      mergeTagsConfig={{
        channelType: 'email',
        contextType,
        onMergeTagDoubleClick: shouldInsertToInput
          ? insertToTextInput
          : insertToEditor
      }}
    >
      <Field
        id='subject'
        name='subject'
        label='Subject'
        Input={TextInput}
        inputProps={{
          onClick: () => setShouldInsertToInput(true),
          ref: inputRef
        }}
      />
      <Field
        name='content'
        Input={FroalaEditorInput}
        inputProps={{
          onClick: () => setShouldInsertToInput(false),
          onImageAdded: setFileIdToAdd,
          onImageRemoved: setFileIdToRemove,
          ref: editorRef
        }}
      />
      {/*Images uploaded within the email content input are stored in this field*/}
      <HiddenField name={'linked_files'} />

      {children}
    </EditMessageContentContainer>
  );
}
