import React from 'react';
import { useChecklistItemState } from '../hooks/use-checklist-item-state';
import { ChecklistItemWithId } from '../../common/blocks/create-checklists-block';
import { ChecklistItem } from './checklist-item';

export function ChecklistItemList({ data }: { data: ChecklistItemWithId[] }) {
  return (
    <ul>
      {data.map((item) => {
        return (
          <li key={item.id}>
            <ChecklistItemWrapper item={item} />
          </li>
        );
      })}
    </ul>
  );
}

const ChecklistItemWrapper = ({ item }: { item: ChecklistItemWithId }) => {
  // Create a wrapper for the items so that we can call this hook.
  const state = useChecklistItemState(item);
  return <ChecklistItem {...state} />;
};
