import { useQuery } from 'react-query';
import { useSessionState } from 'src/modules/common/hooks/session-state';
import { api } from 'utils/api/api-client';
import { PortfolioMember } from '../models/portfolio-members-model';

export function useUserPortfolioMembers() {
  const { user } = useSessionState();
  return useQuery({
    queryKey: ['user-portfolio-members', user?.id],
    queryFn: () => {
      return api.get<PortfolioMember[]>(
        `/portfolio-members?q=universal_user_id.eq(${user!.id})`
      );
    },
    enabled: !!user?.id,
    select: (data) => data.data
  });
}
