export function getReceiptDescription(
  rentInvoicesCount: number,
  otherInvoiceCount: number
) {
  const rentString = rentInvoicesCount ? 'Rent' : '';
  const invoiceString = otherInvoiceCount
    ? otherInvoiceCount === 1
      ? `${otherInvoiceCount} invoice`
      : `${otherInvoiceCount} invoices`
    : '';

  return rentString + (rentString && invoiceString ? ', ' : '') + invoiceString;
}
