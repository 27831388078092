import React from 'react';

import Box from '@rexlabs/box';
import { Field } from '@rexlabs/form';
import { TextArea } from '@rexlabs/text-input';

import { Grid } from 'view/components/@luna/form/grid';
import { Value } from 'view/components/values';
import { BlockConfig } from 'view/components/record-screen/types';
import { ImagePreview } from 'view/components/image-preview';
import { PreviewFileUploadInputWithErrorHandling } from 'view/components/inputs/file-upload-input/preview-file-upload-input-with-error-handling';
import { RoutineInspectionReportAreaTitle } from 'src/modules/tasks/inspections/components/routine-inspection-report-area-title';
import { InspectionTask } from '../types/InspectionTask';
import { InspectionFormData } from '../types/InspectionFormData';
import { RoomConditionButtonGroup } from '../components/room-condition-button-group';

export function getRoutineInspectionReportAreaBlocks(
  data: InspectionTask
): BlockConfig<InspectionTask>[] {
  const routineInspectionReportAreaBlocks: BlockConfig<InspectionTask>[] = (
    data?.details?.areas?.data || []
  ).map((area) => ({
    ...getRoutineInspectionReportAreaBlock(area.id),
    title: () => <RoutineInspectionReportAreaTitle area={area} />
  }));

  return routineInspectionReportAreaBlocks;
}

export function getRoutineInspectionReportAreaBlock(
  areaId: string
): BlockConfig<InspectionTask, any, InspectionFormData> {
  return {
    id: `routine-inspection-report-area-${areaId}`,
    isMobileForcedCollapsed: true,
    validate: {
      definitions: {
        [`routine_areas.${areaId}.condition`]: {
          name: 'condition',
          rules: 'required'
        }
      }
    },
    isEditable: (data) =>
      !['report_completed', 'report_sent'].includes(
        data?.details?.status?.id || ''
      ),
    View: ({ data }) => {
      const area = data!.details!.areas!.data.find(
        (area) => area.id === areaId
      );
      const images = area?.images?.data || [];

      return (
        <Grid columns={1}>
          <Value label='Condition' value={area?.condition?.label} />

          <Value label='Comments' value={area?.notes} />
          <Value
            label='Images'
            value={
              images.length ? (
                <>
                  <Box spacing='.8rem' flexDirection='row' flexWrap='wrap'>
                    {images!.map((image, index) => {
                      return (
                        <ImagePreview
                          src={image?.file.url}
                          key={image?.file.id || index}
                          downloadUrl={image?.file.download_url}
                        />
                      );
                    })}
                  </Box>
                </>
              ) : null
            }
          />
        </Grid>
      );
    },
    Edit: () => {
      return (
        <Grid columns={1}>
          <Field
            id={`routine_areas.${areaId}.condition`}
            name={`routine_areas.${areaId}.condition`}
            label='Condition'
            Input={RoomConditionButtonGroup}
            optional={false}
          />
          <Field
            id={`routine_areas.${areaId}.comments`}
            name={`routine_areas.${areaId}.comments`}
            label='Comments'
            Input={TextArea}
          />
          <Field<typeof PreviewFileUploadInputWithErrorHandling>
            id={`routine_areas.${areaId}.images`}
            name={`routine_areas.${areaId}.images`}
            label='Images'
            Input={PreviewFileUploadInputWithErrorHandling}
            inputProps={{
              multi: true,
              accept: '.jpeg, .png, .jpg'
            }}
          />
        </Grid>
      );
    }
  };
}
