import { TrustJournalEntry } from 'src/modules/trust-journal-entries/types/trust-journal-entry-types';
import { Columns } from 'view/components/table';

export const receiptColumns: Columns<TrustJournalEntry> = [
  {
    id: 'date',
    type: 'date',
    Header: 'Date',
    width: 120,
    accessor: (item) => item.date_of
  },
  {
    id: 'type_id',
    type: 'value',
    Header: 'Type',
    width: 120,
    accessor: (item) => item.type
  },
  {
    id: 'payment_method_id',
    type: 'value',
    Header: 'Payment Method',
    accessor: (item) => item.payment_information?.method
  },
  {
    id: 'reference',
    Header: 'Reference',
    accessor: (item) => item.payment_information?.reference
  },
  {
    id: 'contacts',
    type: 'value',
    Header: 'Received from',
    accessor: (item) =>
      item.contacts?.data.map((contact) => contact.display_name).join(', ')
  },
  {
    id: 'amount',
    type: 'currency',
    Header: 'Amount',
    cellProps: {
      align: 'right'
    },
    width: 120,
    accessor: (item) => item.amount
  }
];
