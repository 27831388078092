import * as React from 'react';
import { DialogProps } from '@rexlabs/dialog';
import { Dialog } from 'view/components/@luna/dialog';
import { Message } from '../types/Message';
import {
  SMSPhonePreview,
  SMSPhonePreviewContainer
} from '../../common/components/sms-phone-preview';
import { mapMessageToSMSPreviewProps } from '../mappers/map-message-to-sms-preview-props';

export interface ViewSMSRecordDialogProps extends DialogProps {
  data: {
    message?: Message;
  };
}

export function ViewSMSRecordDialog({
  onClose,
  data
}: ViewSMSRecordDialogProps) {
  const smsProps = mapMessageToSMSPreviewProps(data.message);

  return (
    <Dialog title='View sent SMS' onClose={onClose}>
      <SMSPhonePreviewContainer>
        <SMSPhonePreview>{smsProps.bodyContent}</SMSPhonePreview>
      </SMSPhonePreviewContainer>
    </Dialog>
  );
}
