import { StandardDialogFooter } from 'view/components/dialogs/components/standard-dialog-footer';
import ActionMenu from '@rexlabs/action-menu';
import ChevronDownIcon from 'view/components/icons/chevron-down';
import React from 'react';
import {
  GhostButton,
  OutlineButton,
  PrimaryButton,
  PrimarySplitButton
} from '@rexlabs/button';
import { ButtonGroupProps } from 'view/components/record-screen/dialog/types';
import ArrowRightCircleIcon from 'view/components/icons/arrow-right-circle';
import RemoveIcon from 'view/components/icons/remove';
import { useStyles, StyleSheet } from '@rexlabs/styling';

const defaultStyles = StyleSheet({
  removeButton: {
    marginRight: ({ token }) => token('spacing.xs')
  }
});

export interface MultiItemDialogButtonGroupProps extends ButtonGroupProps {
  itemCount?: number;
  handleNext?: () => void;
  nextButtonLabel?: string;
  removeButtonLabel?: string;
  id?: number;
  markPageAsDone?: (id) => void;
  completedPageIds?: string[];
}

export function MultiItemDialogButtonGroup({
  handleSubmit,
  itemCount,
  onClose,
  handleNext,
  nextButtonLabel,
  removeButtonLabel,
  id,
  isSubmitting,
  completedPageIds,
  markPageAsDone
}: MultiItemDialogButtonGroupProps) {
  const s = useStyles(defaultStyles);

  const moreThanOneItem =
    itemCount && completedPageIds && itemCount - completedPageIds.length > 1;
  const handleCreateAndClose = async () => {
    const result = await handleSubmit();
    if (!result) {
      // validation failed, don't proceed
      return;
    }

    onClose?.();
  };

  const handleCreateAndNext = async () => {
    const result = await handleSubmit();
    if (!result) {
      // validation failed, don't proceed
      return;
    }

    markPageAsDone?.(id);

    // Move to the next page in the dialog if there are
    // remaining pages, otherwise close the dialog
    if (completedPageIds?.length !== itemCount! - 1) {
      handleNext?.();
    } else {
      onClose?.();
    }
  };

  const handleRemove = () => {
    markPageAsDone?.(id);
    handleNext?.();
  };

  return (
    <StandardDialogFooter
      left={
        moreThanOneItem ? (
          <>
            <OutlineButton
              {...s('removeButton')}
              IconLeft={RemoveIcon}
              onClick={handleRemove}
            >
              {removeButtonLabel || 'Remove'}
            </OutlineButton>
            <OutlineButton IconLeft={ArrowRightCircleIcon} onClick={handleNext}>
              {nextButtonLabel || 'Next'}
            </OutlineButton>
          </>
        ) : null
      }
    >
      <GhostButton onClick={onClose}>Cancel</GhostButton>
      {moreThanOneItem ? (
        <PrimarySplitButton
          Left={({ Button }) => (
            <Button
              type='submit'
              isLoading={isSubmitting}
              onClick={handleCreateAndNext}
            >
              {'Create & next'}
            </Button>
          )}
          Right={({ IconButton }) => (
            <ActionMenu
              placement='bottom-end'
              items={[
                {
                  label: 'Create & finish later',
                  onClick: handleCreateAndClose
                }
              ]}
              Button={React.forwardRef((props, ref) => (
                <IconButton {...props} ref={ref} Icon={ChevronDownIcon} />
              ))}
            />
          )}
        />
      ) : (
        <PrimaryButton type='submit' onClick={handleCreateAndClose}>
          {'Create & close'}
        </PrimaryButton>
      )}
    </StandardDialogFooter>
  );
}
