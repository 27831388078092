import React from 'react';
import { BlockConfig } from 'view/components/record-screen/types';
import { Column, Grid } from '@rexlabs/grid';
import { BooleanValue } from 'view/components/values/boolean';
import { BankAccount } from 'data/models/entities/financials/bank-accounts';
import { Field } from '@rexlabs/form';
import { BooleanRadios } from 'view/components/inputs/boolean-radios';
import { Value } from 'view/components/values';

export const sageSettingsBlock: BlockConfig<BankAccount> = {
  id: 'bankAccountSageSettings',
  title: 'Sage upload details',
  View: ({ data }) => (
    <Grid>
      <Column width={4}>
        <BooleanValue label='Sage payments' value={data?.sage?.is_enabled} />
      </Column>
      <Column width={4}>
        <Value
          label='Amounts in pence?'
          value={data?.sage?.is_amount_in_pence ? 'Yes' : 'No'}
        />
      </Column>
    </Grid>
  ),
  Edit: () => {
    return (
      <Grid>
        <Column width={4}>
          <Field
            name='sage.is_enabled'
            label='Sage payments'
            Input={BooleanRadios}
            optional={false}
          />
        </Column>
        <Column width={4}>
          <Field
            name='sage.is_amount_in_pence'
            label='Amounts in pence?'
            Input={BooleanRadios}
            optional={false}
          />
        </Column>
      </Grid>
    );
  }
};
