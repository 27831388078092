export const TRUST_JOURNAL_ROUTES = {
  TRUST_JOURNAL_ENTRIES: {
    config: {
      path: '/trust-journal-entries',
      Component: require('./screens/trust-journal-entries-list')
        .TrustJournalEntriesListScreen
    }
  },

  TRUST_JOURNAL_ENTRY: {
    config: {
      path: '/trust-journal-entry/:trustJournalEntryId',
      Component: require('./screens/trust-journal-entry-details')
        .TrustJournalEntryDetailsScreen
    }
  }
};
