import { Ownership, Owner } from 'data/models/entities/ownerships';
import { Tenancy, TenancyRelatedContact } from 'data/models/entities/tenancies';
import { PropertyOwnership } from 'src/modules/property-ownerships/types/property-ownership-types';
import { PropertyTenancy } from 'src/modules/property-tenancies/types/property-tenancy-types';
import { FLAGS } from 'utils/feature-flags';
import { useFeatureFlags } from '../@luna/feature-flags';

export const cap = <T>(array: T[], capNumber: number) => {
  if (capNumber < 0) return [];

  return array.slice(0, capNumber);
};
export const hasMoreThan = (array: any[], maxNumber: number) => {
  return array.length > maxNumber;
};

type OwnershipObjects = {
  propertyOwnership?: PropertyOwnership;
  ownership?: Ownership;
  owners: Owner[];
};

export function getOwnershipObjects(
  data: PropertyOwnership | Ownership | Owner[]
): OwnershipObjects {
  if ('ownership' in data) {
    return {
      propertyOwnership: data,
      ownership: data.ownership,
      owners: data.ownership!.owners!
    };
  }

  if ('owners' in data) {
    return {
      ownership: data,
      owners: data.owners!
    };
  }

  if (Array.isArray(data)) {
    return { owners: data };
  }

  // TODO: just logging instead of throwing an error for now, we still need to fix
  // the core issue. I believe there is a problem with list refreshes before redirecting
  // to the record detail screen
  console.error(
    'missing fields (ownership, owners) on data object or data is not array of owners'
  );
  return { owners: [] };
}

type TenancyObjects = {
  propertyTenancy?: PropertyTenancy;
  tenancy?: Tenancy;
  tenancyRelatedContacts: TenancyRelatedContact[];
};

export function useGetTenancyObjects() {
  const { hasFeature } = useFeatureFlags();

  const hasGuarantorAndOccupierUpdates = hasFeature(
    FLAGS.GUARANTOR_AND_OCCUPIER_UPDATES
  );

  return (data) => getTenancyObjects(data, hasGuarantorAndOccupierUpdates);
}

// This function is called with different data depending on which section it is called from.
// For example, if it is called from the e.g. active_property_tenancy section,
// it will be called with data = propertyTenancy.
// Don't pass in e.g. {tenancy: tenancy} as the data.
// The util is equipped to deal with data = Tenancy for the tenancy section.
// tbh I think it should be refactored into multiple utils that only do one thing.
// [ALFIE-1109]
export function getTenancyObjects(
  data: PropertyTenancy | Tenancy | TenancyRelatedContact[],
  hasGuarantorAndOccupierUpdates: boolean
): TenancyObjects {
  // checking to see if data is a propertyTenancy
  if ('tenancy' in data) {
    return {
      propertyTenancy: data,
      tenancy: data.tenancy,
      tenancyRelatedContacts: hasGuarantorAndOccupierUpdates
        ? data.tenancy!.related_contacts?.data || []
        : data.tenancy!.tenants!
    };
  }
  // checking to see if data is a tenancy
  if ('tenants' in data) {
    return {
      tenancy: data,
      tenancyRelatedContacts: hasGuarantorAndOccupierUpdates
        ? data.related_contacts?.data || []
        : data.tenants!
    };
  }
  // checking to see if data is an array of tenants
  if (Array.isArray(data)) {
    return { tenancyRelatedContacts: data };
  }

  console.error(
    'missing fields (tenancy, tenants) on data object or data is not array of tenants'
  );

  return { tenancyRelatedContacts: [] };
}
