import React from 'react';
import { Message } from '@rexlabs/notifications';
import { BlockConfig } from 'view/components/record-screen/types';
import { Value } from 'view/components/values';
import { Grid } from 'view/components/@luna/form/grid';
import { Field } from '@rexlabs/form';
import { TextInput } from '@rexlabs/text-input';
import { Tenancy } from 'data/models/entities/tenancies';

import { EmptyCard } from 'view/components/record-screen/cards/empty-card';
import EmptyRecord from '../../../assets/illustrations/empty-record.svg';

export const receiptingInstructionsBlock: BlockConfig<Tenancy> = {
  id: 'receipting-instructions',
  title: 'Receipting instructions',
  showEmpty: (data) => !data.receipting_instruction,
  Empty: ({ onEditClick }) => {
    return (
      <EmptyCard>
        <Message
          title='Receipting instructions'
          Illustration={EmptyRecord}
          actions={[
            {
              label: 'Add instructions',
              type: 'primary',
              handleAction: onEditClick
            }
          ]}
        >
          Add tenancy specific instructions for receipting
        </Message>
      </EmptyCard>
    );
  },
  View: ({ data }) => {
    return (
      <Grid columns={2}>
        <Value
          label='Receipting instructions'
          value={data?.receipting_instruction}
        />
      </Grid>
    );
  },
  Edit: () => {
    return (
      <Grid columns={1}>
        <Field
          id='receipting-instructions'
          name='receipting_instruction'
          label='Receipting instructions'
          Input={TextInput}
        />
      </Grid>
    );
  }
};
