import React, { useMemo } from 'react';
import { PendingOwnershipDisbursement } from 'data/models/entities/financials/pending-disbursements/pending-ownership-disbursement';
import { createTableBlock } from 'view/components/record-screen/cards/create-table-block';
import { Message } from 'view/components/@luna/message';
import { toQuri } from '@rexlabs/quri';
import { getInvoiceQuery } from 'src/modules/invoices/data/queries';
import { financialsInvoicesModel } from 'data/models/entities/financials/invoices';
import { ActionButtons } from 'view/components/@luna/action-buttons';
import { InvoiceStatus } from 'utils/static-value-lists/invoice-status';
import EmptyTrustJournalEntriesIllustration from 'assets/illustrations/empty-trust-journal-entries-table.svg';
import { useTranslation } from 'react-i18next';
import { getTodayDateStringForActiveTz } from 'utils/dates/dates';
import { billsAndFeesColumns } from '../utils/bills-and-fees-columns';
import { useGetPayBillsAction } from '../hooks/use-get-pay-bills-action';

function getFilter(ownershipId: string, statusId: InvoiceStatus) {
  return toQuri([
    {
      field: 'payable_by_object_id',
      op: '==',
      value: ownershipId
    },
    {
      field: 'payable_by_object_type',
      op: '==',
      value: 'ownership'
    },
    {
      field: 'status_id',
      op: '==',
      value: statusId
    },
    {
      field: 'do_not_pay_before_date',
      op: 'lte',
      value: getTodayDateStringForActiveTz()
    }
  ]);
}

const BILLS_AND_FEES_LIST_ID = 'bills-and-fees-invoices-list';

export const ownershipBillsAndFeesBlock = createTableBlock<
  PendingOwnershipDisbursement,
  typeof financialsInvoicesModel
>({
  title: 'Bills & fees payable',
  Empty: () => {
    const { t } = useTranslation();
    return (
      <Message
        Illustration={EmptyTrustJournalEntriesIllustration}
        title='All bills & fees paid!'
      >
        {t('disbursements.all-bills-and-fees-paid')}
      </Message>
    );
  },
  getTableProps: ({ data }) => ({
    columns: billsAndFeesColumns,
    getQuery: () => ({
      ...getInvoiceQuery(getFilter(data.object!.id, 'awaiting_payment')),
      uuid: BILLS_AND_FEES_LIST_ID
    }),
    id: BILLS_AND_FEES_LIST_ID
  }),
  withPagination: true,
  hashParamKey: 'ownership-bills-and-fees',
  Actions: ({ data }) => {
    const getPayBillsAndFeesAction = useGetPayBillsAction();
    const payBillsAndFeesAction = useMemo(
      () =>
        getPayBillsAndFeesAction({
          financialFolio: data!.object!,
          updateListId: BILLS_AND_FEES_LIST_ID,
          pendingDisbursementId: data!.id
        }),
      [data]
    );

    return <ActionButtons actions={[payBillsAndFeesAction]} />;
  }
});
