import Validator from '@rexlabs/validator';

Validator.registerImplicit(
  'bucketRequiredIfTenancy',
  function (this: any): boolean {
    return !(
      this.validator.input['source']?.ledger?.object?.type?.id === 'tenancy' &&
      !this.validator.input['prepayment_bucket']
    );
  },
  'The bucket field is required.'
);
