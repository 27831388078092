import { ActionButtons } from 'view/components/@luna/action-buttons';
import React from 'react';
import { BlockConfig } from 'view/components/record-screen/types';
import { useDialog } from '@rexlabs/dialog';
import { RecordTypes } from 'data/models/types';
import { StatementsTable } from '../../statements/components/statements-table';
import { IssueStatementDialog } from '../../statements/dialogs/issue-statement-dialog';

export const ownershipStatementsBlock: BlockConfig = {
  id: 'ownership-statements',
  title: 'Ownership statements',
  Actions: ({ data }) => {
    const { open } = useDialog(IssueStatementDialog);

    return (
      <ActionButtons
        actions={[
          {
            label: 'Issue statement',
            handleAction: () =>
              open({ record: data, recordType: RecordTypes.Ownership })
          }
        ]}
      />
    );
  },
  View: ({ data }) => (
    <StatementsTable recordId={data.id} recordType={RecordTypes.Ownership} />
  )
};
