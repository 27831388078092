import React, { useCallback } from 'react';
import { useModelActions } from '@rexlabs/model-generator';

import { RecordDialog } from 'view/components/record-screen/dialog/dialog';
import { DialogContentConfig } from 'view/components/record-screen/dialog/types';
import { RecordSubmitHandler } from 'view/components/record-screen/utils';
import { filterNullValues } from 'utils/filter-null-values';
import {
  financialsSecurityDepositTypesModel,
  SecurityDepositType
} from 'data/models/entities/financials/security-deposit-types';
import { useToast } from 'view/components/@luna/notifications/toast';

import { useTranslation } from 'react-i18next';
import { securityDepositTypeBlock } from '../blocks/security-deposit-type';

const content: DialogContentConfig = [
  {
    id: 'basics',
    label: 'Basics',
    blocks: [securityDepositTypeBlock]
  }
];

interface EditSecurityDepositTypeDialogProps {
  securityDepositType: SecurityDepositType;
  onSave?: (securityDepositType: SecurityDepositType) => void;
  onClose?: () => void;
}

export function EditSecurityDepositTypeDialog({
  onSave,
  securityDepositType,
  onClose
}: EditSecurityDepositTypeDialogProps) {
  const { t } = useTranslation();
  const { updateItem } = useModelActions(financialsSecurityDepositTypesModel);
  const { addToast } = useToast();

  const handleSubmit: RecordSubmitHandler = useCallback(
    async ({ values }) => {
      const response = await updateItem({
        id: securityDepositType.id,
        data: filterNullValues(values)
      });

      await onSave?.(response.data);
      addToast({
        description: (
          <>
            <b>{response.data.name}</b> has been updated
          </>
        )
      });

      return response.data;
    },
    [updateItem]
  );

  return (
    <RecordDialog
      onClose={onClose}
      data={securityDepositType}
      title={t('bonds-and-deposits.update-type')}
      handleSubmit={handleSubmit}
      content={content}
    />
  );
}
