import { isEmpty } from 'lodash';

import { useErrorDialog } from '@rexlabs/dialog';
import { useModelActions } from '@rexlabs/model-generator';

import { valueListFactory } from 'src/lib/testing/factories/value-list-factory';

import { useToast } from 'view/components/@luna/notifications/toast';
import { RecordSubmitHandler } from 'view/components/record-screen/utils';

import { tasksModel } from '../../common/models/tasks-model';
import { QuoteTask } from '../types/QuoteTask';
import { QuoteDetailsForm } from '../blocks/quote-details-block';
import { QuoteStatus } from '../types/QuoteStatus';
import { mapFormToPayload } from '../mappers/map-form-to-payload';

interface UseGetUpdateQuoteTaskSubmitHandlerProps {
  onCreate?: (quoteTask: QuoteTask) => void;
}

export function useGetUpdateQuoteTaskSubmitHandler({
  onCreate
}: UseGetUpdateQuoteTaskSubmitHandlerProps = {}) {
  const {
    refreshLists: refreshQuotesTasks,
    updateItem: updateQuoteTask
  } = useModelActions(tasksModel);
  const { open: openErrorDialog } = useErrorDialog();

  const { addToast } = useToast();

  return (
    quoteTaskId: QuoteTask['id'],
    status?: QuoteStatus
  ): RecordSubmitHandler<Partial<QuoteDetailsForm>> => async ({
    changedValues
  }) => {
    try {
      const { basePayload, detailsPayload } = await mapFormToPayload(
        changedValues
      );

      const statusReceived = status === 'received';

      const { data: quoteTask } = await updateQuoteTask({
        id: quoteTaskId,
        data: {
          ...basePayload,
          ...(!isEmpty(detailsPayload) || status
            ? {
                details: {
                  ...detailsPayload,
                  ...(status ? { status: valueListFactory(status) } : {}),
                  ...(statusReceived ? { has_received_quote: true } : {})
                }
              }
            : {})
        }
      });

      onCreate?.(quoteTask);

      addToast({
        description: 'You have successfully updated the quote'
      });

      refreshQuotesTasks();

      return quoteTask;
    } catch (error) {
      openErrorDialog(error);
    }
  };
}
