import { makeIconComponent } from '@rexlabs/icons';

import FinishMediumSvg from 'src/assets/icons/m/finish.svg';
import FinishLargeSvg from 'src/assets/icons/l/finish.svg';

export const FinishIcon = makeIconComponent({
  m: FinishMediumSvg,
  l: FinishLargeSvg
});

export default FinishIcon;
