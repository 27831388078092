import React from 'react';
import { BlockConfig } from 'view/components/record-screen/types';
import { Field } from '@rexlabs/form';
import { TextInput } from '@rexlabs/text-input';
import { Grid, Column } from '@rexlabs/grid';
import { get } from 'lodash';
import { StyleSheet, useStyles } from '@rexlabs/styling';
import { SimplePercentageInput } from 'view/components/inputs/simple-percentage-input';
import { CentAmountInput } from 'view/components/inputs/cent-amount';
import { SecurityDepositTypeAmountType } from 'view/components/inputs/selects/security-deposit-type-amount-type';
import { SecurityDepositHeldBy } from 'view/components/inputs/radios/security-deposit-held-by';
import { EmptyState } from 'view/components/states/compact/empty';
import { Heading } from '@rexlabs/text';
import { HorizontalDivider } from 'view/components/ui/horizontal-divider';
import { WeeksInput } from 'view/components/inputs/weeks-input';
import { useTranslation } from 'react-i18next';
import { HeldByBondAuthorityForm } from '../components/held-by-bond-authority-form';
import { HeldInTrustForm } from '../components/held-in-trust-form';

const defaultStyles = StyleSheet({
  rightColumn: {
    background: ({ token }) => token('color.container.static.contrast'),
    padding: ({ token }) => token('spacing.xl'),
    borderRadius: ({ token }) => token('border.radius.l'),
    minHeight: '100%',
    display: 'flex',
    justifyContent: 'center'
  }
});

export const securityDepositTypeBlock: BlockConfig = {
  id: 'security-deposit-type',
  title: 'Security deposit type',
  validate: {
    definitions: {
      name: { name: 'name', rules: 'required' },
      'default_amount.type': { name: 'default amount type', rules: 'required' },
      'default_amount.amount': { name: 'default amount', rules: 'required' },
      'held_by.id': { name: 'held by type', rules: 'required' },
      held_by_contact: {
        name: 'held by',
        rules: 'required_unless:held_by.id,held_by_ownership'
      },
      initial_payment_method: {
        name: 'initial lodgement default payment method',
        rules: 'required_if:held_by.id,held_by_deposit_authority'
      },
      top_up_payment_method: {
        name: 'top up lodgement default payment method',
        rules: 'required_if:held_by.id,held_by_deposit_authority'
      },
      held_by_bank_account: {
        name: 'held by bank account',
        rules: 'required_if:held_by.id,held_by_agency'
      }
    },
    messages: {
      required_if: 'The :attribute field is required.',
      required_unless: 'The :attribute field is required.'
    }
  },
  Edit: function EditSecurityDepositTypes({ values, setFieldValue, data }) {
    const { t } = useTranslation();
    const s = useStyles(defaultStyles);

    const heldByIsDisabled = data?.security_deposits_count > 0;

    return (
      <Grid>
        <Column width={6}>
          <Grid>
            <Column width={12}>
              <Field
                name='name'
                label={t('bonds-and-deposits.name-of') as string}
                Input={TextInput}
              />
            </Column>
            <Column width={6}>
              <Field
                label='Ownership Rate'
                name='default_amount.type'
                Input={SecurityDepositTypeAmountType}
                onChange={() => {
                  setFieldValue?.('default_amount.amount', null);
                }}
              />
            </Column>
            <Column width={6}>
              {get(values, 'default_amount.type.id') === 'percent' && (
                <Field
                  name='default_amount.amount'
                  label='&nbsp;'
                  Input={SimplePercentageInput}
                />
              )}
              {get(values, 'default_amount.type.id') === 'amount' && (
                <Field
                  name='default_amount.amount'
                  label='&nbsp;'
                  Input={CentAmountInput}
                />
              )}
              {get(values, 'default_amount.type.id') === 'weeks_of_rent' && (
                <Field
                  name='default_amount.amount'
                  label='&nbsp;'
                  Input={WeeksInput}
                />
              )}
            </Column>
            <Column>
              <Field
                name='held_by.id'
                label='How will the bond be held'
                Input={SecurityDepositHeldBy}
                inputProps={{ disabled: heldByIsDisabled }}
              />
            </Column>
          </Grid>
        </Column>
        <Column width={6}>
          <div {...s('rightColumn')}>
            {values.held_by?.id === null && (
              <EmptyState noPadding title='Setting up a deposit'>
                Please provide a unique name, a default amount and how the
                deposit will be held
              </EmptyState>
            )}
            {values.held_by?.id === 'held_by_deposit_authority' && (
              <div>
                <Heading level={4}>Bond Authority details</Heading>
                <div className='mt-m'>
                  <HorizontalDivider />
                </div>
                <div className='mt-xl'>
                  <HeldByBondAuthorityForm values={values} />
                </div>
              </div>
            )}
            {values.held_by?.id === 'held_by_agency' && (
              <div>
                <Heading level={4}>Trust details</Heading>
                <div className='mt-m'>
                  <HorizontalDivider />
                </div>
                <div className='mt-xl'>
                  <HeldInTrustForm />
                </div>
              </div>
            )}
            {values.held_by?.id === 'held_by_ownership' && (
              <EmptyState noPadding title='Ownership held deposits'>
                {t(
                  'bonds-and-deposits.security-deposit-type.held-by-ownership.empty.message'
                )}
              </EmptyState>
            )}
          </div>
        </Column>
      </Grid>
    );
  }
};
