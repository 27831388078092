import { Quri } from '@rexlabs/quri';
import * as React from 'react';

import { getToday } from 'utils/dates/dates';
import { ListTableProps } from 'view/components/table/list-table';
import { Tab } from 'view/components/table/tabbed';

import { InspectionRunsTableEmptyState } from '../components/inspection-runs-table-empty-state';

function getTabWithCommonTabProps(
  commonTabProps: ListTableProps,
  tabConfig: ListTableProps
): Tab {
  return {
    ...commonTabProps,
    ...tabConfig,
    forcedGlobalFilter: [
      ...(commonTabProps.forcedGlobalFilter || []),
      ...(tabConfig?.forcedGlobalFilter || [])
    ]
  };
}

type TabNames = 'open' | 'completed' | 'overdue';

export function getInspectionRunsTabbedTableTabs(
  commonTabProps: Partial<Tab<ListTableProps>>,
  tabs: TabNames[] = ['open', 'overdue', 'completed']
) {
  const now = getToday();

  const filterOpen: Quri = {
    field: 'status_id',
    op: 'eq',
    value: 'open'
  };

  const filterClosed: Quri = {
    field: 'status_id',
    op: 'eq',
    value: 'closed'
  };

  const filterIsOverdue: Quri = {
    field: 'start_at',
    op: 'lt',
    value: now
  };

  const filterNotOverdue: Quri = {
    field: 'start_at',
    op: 'gte',
    value: now
  };

  const inspectionRunsTableTabsBaseConfigMap = {
    open: {
      name: 'open',
      label: 'Open',
      Empty: () => <InspectionRunsTableEmptyState status='Open' />,
      forcedGlobalFilter: [filterOpen, filterNotOverdue]
    },
    overdue: {
      name: 'overdue',
      label: 'Overdue',
      Empty: () => <InspectionRunsTableEmptyState status='Overdue' />,
      forcedGlobalFilter: [filterOpen, filterIsOverdue]
    },
    completed: {
      name: 'completed',
      label: 'Completed',
      Empty: () => <InspectionRunsTableEmptyState status='Completed' />,
      forcedGlobalFilter: [filterClosed]
    }
  };

  return tabs.map((tabName) =>
    getTabWithCommonTabProps(
      commonTabProps,
      inspectionRunsTableTabsBaseConfigMap[tabName]
    )
  );
}
