import React from 'react';

import { LinkProps, useWhereabouts } from '@rexlabs/whereabouts';

import { useGetViewNoteAction } from './action-declarations/use-get-view-note-action';

export function useGetNoteListLinkProps(showEdit = true) {
  const getViewNoteAction = useGetViewNoteAction();
  const whereabouts = useWhereabouts();
  const getRowLinkProps = React.useCallback(
    ({ item }): LinkProps => {
      const viewNoteAction = getViewNoteAction(item, false, showEdit);

      return {
        // This action is triggered from clicking on a row in the table, and the default
        // behavior for these actions is to route somewhere. Because we're opening a dialog
        // instead, we need to prevent the default behavior, so we're just passing back the
        // current url params
        ...(whereabouts.query ? { query: whereabouts.query } : {}),
        ...(whereabouts.hash ? { hash: whereabouts.hash } : {}),

        onClick: () => viewNoteAction.handleAction?.()
      };
    },
    [getViewNoteAction, showEdit, whereabouts]
  );

  return getRowLinkProps;
}
