import { query, fragment, Query } from '@rexlabs/model-generator';
import { toQuri } from '@rexlabs/quri';

import { settingsSupplierComplianceCategoriesModel } from 'data/models/entities/settings/supplier-compliance-categories';
import { settingsSupplierCategoryRequirementsModel } from 'data/models/entities/settings/supplier-category-requirements';
import { settingsComplianceTypesModel } from 'src/modules/compliance/common/models/compliance-types';
import { GetQueryIdFn } from 'src/types';
import { settingsPropertyCategoryRequirementsModel } from 'data/models/entities/settings/property-category-requirements';
import { settingsPropertyComplianceCategoriesModel } from 'data/models/entities/settings/property-compliance-categories';
import { complianceEntriesModel } from 'src/modules/compliance/common/models/compliance-entries';

export const complianceTypeFragment = fragment`
  id
  label
  description
  category
  applicable_to_object_type
  property_compliance_categories
  supplier_compliance_categories
`;

export const getComplianceTypesQuery = (filter) => {
  const filterQuery = toQuri(filter);
  return query`{
    ${settingsComplianceTypesModel} (q: ${filterQuery}){
      ${complianceTypeFragment}
    }
  }`;
};

export const supplierComplianceCategoriesQuery: Query<
  typeof settingsSupplierComplianceCategoriesModel
> = {
  ...query`{
    ${settingsSupplierComplianceCategoriesModel} {
      id
      label
      description
      category_requirements
    }
  }`,
  uuid: 'supplierComplianceCategoriesList'
};

export const getSupplierCategoryRequirementsByCategoryIdQuery = (
  getIdFn: GetQueryIdFn
) => {
  const filter = (props) =>
    toQuri([
      {
        field: 'supplier_service_category_id',
        op: '==',
        value: getIdFn(props)
      }
    ]);

  return query`{
    ${settingsSupplierCategoryRequirementsModel} (q: ${filter}) {
      id
      category
      compliance_type
    }
  }`;
};

export const getPropertyComplianceCategoryRequirementsByCategoryIdQuery = (
  getIdFn: GetQueryIdFn
) => {
  const filter = (props) =>
    toQuri([
      {
        field: 'property_compliance_category_id',
        op: '==',
        value: getIdFn(props)
      }
    ]);

  return {
    ...query`{
      ${settingsPropertyCategoryRequirementsModel} (q: ${filter}) {
        id
        category
        label
        description
        compliance_type
      }
    }`,
    uuid: 'propertyComplianceCategoryRequirementsList'
  };
};

type PropsFn = (props: any) => any;
type Id = PropsFn | string;
export const getPropertyComplianceCategoryQuery = (id: Id) => query`{
  ${settingsPropertyComplianceCategoriesModel} (id: ${id}) {
    id
    label
    description
  }
}`;

export const propertyComplianceCategoriesQuery = query`{
  ${settingsPropertyComplianceCategoriesModel} {
    id
    label
    description
    category_requirements
  }
}`;

export const getComplianceEntryQuery = (getIdFn: GetQueryIdFn) => {
  return query`{
    ${complianceEntriesModel} (id: ${getIdFn}) {
      id
      details
      issued_at
      expires_at
      object_type
      object_id
      setting_compliance_type_id
      created_at
      updated_at
      compliance_type
    }
  }`;
};

const getFiltersForComplianceEntries = ({ objectId, objectType }) => {
  return toQuri([
    {
      field: 'object_id',
      op: '==',
      value: objectId
    },
    {
      op: 'and',
      value: [
        {
          field: 'object_type',
          op: '==',
          value: objectType
        }
      ]
    }
  ]);
};

export const complianceEntriesByIdAndTypeQuery = {
  ...query`{
    ${complianceEntriesModel} (q: ${getFiltersForComplianceEntries}) {
      id
      details
      issued_at
      expires_at
      object_type
      object_id
      setting_compliance_type_id
      created_at
      updated_at
      compliance_type
    }
  }`,
  uuid: 'compliance-entries'
};

export const getComplianceEntriesByIdAndTypeQuery = ({
  objectId,
  objectType
}) => {
  const f = () => getFiltersForComplianceEntries({ objectId, objectType });

  const q = query`{
      ${complianceEntriesModel} (q: ${f()}) {
        id
        details
        issued_at
        expires_at
        object_type
        object_id
        setting_compliance_type_id
        created_at
        updated_at
        compliance_type
      }
    }`;

  q.uuid = 'compliance-entries';

  return q;
};
