import * as React from 'react';

import { DialogProps } from '@rexlabs/dialog';
import { useModelActions } from '@rexlabs/model-generator';

import { useRecordCreatedToast } from 'src/modules/common/toasts/hooks/use-record-created-toast';
import { tasksModel } from 'src/modules/tasks/common/models/tasks-model';

import {
  CreateRecordDialog,
  HandleRecordCreate
} from 'view/components/dialogs/create-record-dialog/create-record-dialog';

import { taskDateBlock } from '../blocks/task-date-block';
import { taskPriorityBlock } from '../../common/blocks/task-priority-block';
import { moveOutDetailsBlock } from '../blocks/move-out-details-block';
import { MoveOutFormData } from '../types/MoveOutFormData';
import { mapMoveOutFormDataToTaskCreateRequest } from '../mappers/map-move-out-form-data-to-task-create-request';
import { createTaskAndLinkChecklists } from '../../common/utils/create-task-and-link-checklists';
import { checklistTemplateSelectBlock } from '../../common/blocks/checklist-template-select-block';
import { useTaskTypesWithCustomAddToRecord } from '../../settings/hooks/use-task-types-with-custom-add-to-record';

export interface CreateMoveOutRecordDialogProps extends DialogProps {
  initialValues: Partial<MoveOutFormData>;
  onCreate?: (data) => void;
  isFixture?: boolean;
}

const useContent = () => {
  const taskTypesWithCustomAddToRecord = useTaskTypesWithCustomAddToRecord();

  return [
    {
      id: 'add-move-out',
      label: 'Add move-out',
      blocks: [
        moveOutDetailsBlock,
        ...(taskTypesWithCustomAddToRecord.includes('task_move_out')
          ? [checklistTemplateSelectBlock]
          : []),
        taskPriorityBlock,
        taskDateBlock
      ]
    }
  ];
};

export function CreateMoveOutRecordDialog({
  onClose,
  isFixture,
  initialValues,
  onCreate,
  target
}: CreateMoveOutRecordDialogProps) {
  const { refreshLists } = useModelActions(tasksModel);
  const addToast = useRecordCreatedToast('task_move_out');
  const content = useContent();

  const handleSubmit: HandleRecordCreate<any> = async ({ values }) => {
    const moveOutTask = await createTaskAndLinkChecklists<'task_move_out'>(
      values,
      mapMoveOutFormDataToTaskCreateRequest
    );

    onCreate?.(moveOutTask);

    addToast(moveOutTask);

    await refreshLists();

    return moveOutTask;
  };

  return (
    <CreateRecordDialog
      recordType='task_move_out'
      data={initialValues}
      content={content}
      onClose={onClose}
      blockProps={{ taskTypeId: 'task_move_out' }}
      onSubmit={handleSubmit}
      target={target}
      isFixture={isFixture}
    />
  );
}
