import { RegularQuri } from '@rexlabs/quri';
import { RouteConfig } from '@rexlabs/whereabouts';
import { mapQuriFiltersToTableFilter } from './map-quri-filters-to-table-filter';

/**
 * Use this function to create a RouteConfig for a table with filters. You can then use it
 * to create a link to a table with the filters applied.
 *
 * example using whereabout's push function:
 *
 * push(ROUTES.CONTACTS, createTableRouteConfig(filters, 'owners'));
 *
 * example using whereabouts Link component with parseRouteToUrl:
 *
 *   <Link
 *     to={parseRouteToUrl(
 *       createTableRouteConfig(filters, 'owners', {
 *         path: 'contacts'
 *       })
 *     )}
 *   >
 *   View filtered contacts
 *   </Link>
 *
 * @param filters option RegularQuri array
 * @param tab optional string to set tab, and set the filters to
 * @param routeConfig optional RouteConfig to merge with the new RouteConfig
 * @returns {RouteConfig}
 */
export function createTableRouteConfig(
  filters: RegularQuri[] = [],
  tab?: string,
  routeConfig: RouteConfig = {}
): RouteConfig {
  const mappedFilters =
    filters?.length && JSON.stringify(mapQuriFiltersToTableFilter(filters));

  const filtersKey = tab ? `${tab}_filters` : 'filters';

  return {
    ...routeConfig,
    hashQuery: {
      ...(routeConfig?.hashQuery || {}),
      ...(filters?.length ? { [filtersKey]: mappedFilters } : {}),
      ...(tab ? { tab } : {})
    }
  };
}
