import React from 'react';

import { Field } from '@rexlabs/form';
import { TextInput } from '@rexlabs/text-input';

import { BlockConfig } from 'view/components/record-screen/types';
import { Grid } from 'view/components/@luna/form/grid';
import { PreviewFileUploadInputWithErrorHandling } from 'view/components/inputs/file-upload-input/preview-file-upload-input-with-error-handling';
import { AlfredFile } from 'src/modules/common/types/file';

export type AddDocumentTypeToARecordBlockValues = {
  file: AlfredFile;
  name: string;
};

const validate = {
  definitions: {
    file: { rules: 'required' },
    name: { rules: 'required' }
  }
};

export const addDocumentTypeToARecordBlock: BlockConfig<
  any,
  any,
  AddDocumentTypeToARecordBlockValues
> = {
  id: 'upload-document-type-to-record',
  validate,
  Edit: ({ data: { key }, setFieldValue, values }) => {
    return (
      <Grid columns={1}>
        <Field<typeof PreviewFileUploadInputWithErrorHandling>
          id={key + '.file'}
          name='file'
          Input={PreviewFileUploadInputWithErrorHandling}
          inputProps={{
            onFileSelectCallback: (file) => {
              if (!values?.name) {
                setFieldValue?.('name', file?.name || '');
              }
            }
          }}
          optional={false}
        />

        <Field name='name' label='Name' Input={TextInput} optional={false} />
      </Grid>
    );
  }
};
