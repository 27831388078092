import { Column, Columns } from 'view/components/table';
import { TagCell } from '@rexlabs/table';
import {
  StatusTag,
  StatusTagMap
} from 'src/modules/common/components/status-tag';
import { DownloadCell } from 'src/modules/compliance/common/components/download-cell';
import React from 'react';
import {
  ComplianceEntry,
  ComplianceEntryStatus
} from 'src/modules/compliance/common/models/compliance-entries';

const statusMap: StatusTagMap<ComplianceEntryStatus> = {
  archived: 'neutral',
  valid: 'good',
  expires_soon: 'average',
  expired: 'bad'
};

export const itemNameColumn: Column<ComplianceEntry> = {
  id: 'compliance_type',
  width: '225px',
  type: 'value',
  Header: 'Item name'
};

export const entryColumns: Columns<ComplianceEntry> = [
  {
    id: 'issued_at',
    Header: 'Date issued',
    type: 'date'
  },
  {
    id: 'expires_at',
    Header: 'Expiry date',
    type: 'date'
  },
  {
    id: 'status',
    Header: 'Status',
    Cell: ({ value }) => {
      return (
        <TagCell>
          <StatusTag status={value} map={statusMap} />
        </TagCell>
      );
    }
  },
  {
    width: '50px',
    id: 'download',
    Cell: DownloadCell,
    accessor: (item) => item
  }
];
