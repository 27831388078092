import { useMemo } from 'react';
import { ContentConfig } from 'view/components/record-screen/types';

import { FLAGS } from 'utils/feature-flags';

import { noteTableBlock } from 'src/modules/note/blocks/note-table-block';
import { documentsBlock } from 'src/modules/tasks/common/blocks/documents';
import { linkedTasksBlock } from 'src/modules/tasks/common/blocks/linked-tasks-block';
import { inspectionDateBlock } from 'src/modules/tasks/inspections/blocks/inspection-date-block';

import { useFeatureFlags } from 'view/components/@luna/feature-flags';

import { InspectionTask } from 'src/modules/tasks/inspections/types/InspectionTask';
import { getEntryExitReportHeaderBlock } from 'src/modules/tasks/inspections/entry-exit/blocks/entry-exit-report-block';
import { getEntryExitInspectionAreaBlocks } from 'src/modules/tasks/inspections/entry-exit/blocks/entry-exit-inspection-areas-block';
import { entryExitWaterAndGasBlock } from 'src/modules/tasks/inspections/entry-exit/blocks/entry-exit-water-and-gas-block';
import { entryExitMaintenanceAndHealthBlock } from 'src/modules/tasks/inspections/entry-exit/blocks/entry-exit-maintenance-and-health-block';
import { entryExitCommunicationsBlock } from 'src/modules/tasks/inspections/entry-exit/blocks/entry-exit-communications-block';
import { entryExitSupportingDocumentsBlock } from 'src/modules/tasks/inspections/entry-exit/blocks/entry-exit-supporting-documents-block';
import { entryExitPropertyDetailsHeaderBlock } from 'src/modules/tasks/inspections/entry-exit/blocks/entry-exit-property-details-header-block';
import { useGetShowInspectionReportEmptyState } from 'src/modules/tasks/inspections/hooks/use-get-show-inspection-report-empty-state';
import { getRoutineInspectionReportAreaBlocks } from 'src/modules/tasks/inspections/blocks/routine-inspection-report-areas-block';
import { useGetReportName } from 'src/modules/tasks/inspections/entry-exit/hooks/use-get-report-name';
import { taskPriorityBlock } from '../../common/blocks/task-priority-block';
import { inspectionDetailsBlock } from '../blocks/inspection-details-block';
import { checklistsBlock } from '../../common/blocks/create-checklists-block';
import { inspectionReportEmptyBlock } from '../blocks/inspection-report-empty-block';
import { getInspectionReportRoomBlocks } from '../blocks/inspection-report-room-block';
import { inspectionReportBlock } from '../blocks/inspection-report-block';
import { inspectionReportLinkedTasksBlock } from '../blocks/inspection-report-linked-tasks-block';
import { inspectionDateTimeAndDurationBlock } from '../blocks/inspection-date-time-and-duration-block';
import { useGetIsEntryExit } from '../entry-exit/hooks/use-get-is-entry-exit';
import { inspectionReportCoverDetailsBlock } from '../blocks/inspection-report-cover-details-block';

export const useInspectionDetailsContent = (
  data?: InspectionTask
): ContentConfig => {
  const { hasFeature } = useFeatureFlags();
  const getInspectionReportName = useGetReportName();

  const shouldUseAreas = hasFeature(FLAGS.ENTRY_EXIT_INSPECTION_REPORT);

  const inspectionReportRoomBlocks = useMemo(
    () => getInspectionReportRoomBlocks(data!),
    [data]
  );
  const routineInspectionReportAreaBlocks = useMemo(
    () => getRoutineInspectionReportAreaBlocks(data!),
    [data]
  );
  const entryExitInspectionAreaBlocks = useMemo(
    () => getEntryExitInspectionAreaBlocks(data!),
    [data]
  );

  const entryExitHeaderBlock = useMemo(
    () => getEntryExitReportHeaderBlock(data),
    [data]
  );

  const getIsEntryExit = useGetIsEntryExit();
  const getShowInspectionReportEmptyState = useGetShowInspectionReportEmptyState();

  const showEntryExitReport = getIsEntryExit(data?.details?.type.id);

  return [
    {
      label: 'General',
      items: [
        {
          id: 'overview',
          label: 'Overview',
          blocks: [
            inspectionDetailsBlock,
            taskPriorityBlock,
            inspectionDateBlock,
            ...(hasFeature(FLAGS.INSPECTION_SCHEDULE)
              ? [inspectionDateTimeAndDurationBlock]
              : [])
          ]
        },
        {
          id: 'notes',
          label: 'Notes',
          blocks: [noteTableBlock]
        },
        {
          id: 'documents',
          label: 'Documents',
          blocks: [documentsBlock]
        },
        {
          id: 'checklist',
          label: 'Checklist',
          blocks: [checklistsBlock],
          flag: FLAGS.CHECKLIST_TEMPLATES
        }
      ]
    },
    {
      label: 'Manage',
      items: [
        showEntryExitReport
          ? {
              id: 'entry-exit-report',
              label: getInspectionReportName(data!),
              blocks: [
                ...(getShowInspectionReportEmptyState(data!)
                  ? [inspectionReportEmptyBlock]
                  : [
                      entryExitHeaderBlock,
                      inspectionReportCoverDetailsBlock,
                      ...entryExitInspectionAreaBlocks,
                      entryExitPropertyDetailsHeaderBlock,
                      entryExitWaterAndGasBlock,
                      entryExitMaintenanceAndHealthBlock,
                      entryExitCommunicationsBlock,
                      entryExitSupportingDocumentsBlock,
                      inspectionReportLinkedTasksBlock
                    ])
              ]
            }
          : {
              id: 'inspection-report',
              label: 'Inspection report',
              blocks: [
                ...(getShowInspectionReportEmptyState(data!)
                  ? [inspectionReportEmptyBlock]
                  : [
                      inspectionReportBlock,
                      ...(shouldUseAreas
                        ? routineInspectionReportAreaBlocks
                        : inspectionReportRoomBlocks),
                      inspectionReportLinkedTasksBlock
                    ])
              ]
            },

        {
          id: 'linked-tasks',
          label: 'Linked tasks',
          blocks: [linkedTasksBlock],
          flag: FLAGS.LINKED_TASKS
        }
      ]
    }
  ];
};
