import * as React from 'react';
import { ActionDeclaration } from 'src/modules/common/actions/types/action-declaration-types';
import { RecordTypes } from 'data/models/types';
import { getRecordTitle } from 'utils/records/get-record-title';
import { useToast } from 'view/components/@luna/notifications/toast';
import { useModelActions } from '@rexlabs/model-generator';
import { propertyAreaTypesModel } from 'src/modules/property-areas/property-area-types/models/property-area-types-model';
import { PropertyAreaType } from 'src/modules/property-areas/property-area-types/types/PropertyAreaType';
import { useErrorDialog } from '@rexlabs/dialog';

export function useGetDuplicatePropertyAreaTypeAction() {
  const { addToast } = useToast();
  const { refreshLists, duplicateItem } = useModelActions(
    propertyAreaTypesModel
  );
  const { open: openErrorDialog } = useErrorDialog();

  return (areaType: PropertyAreaType): ActionDeclaration => {
    return {
      id: 'duplicate-property-area-type',
      group: 'property-area-type',
      label: 'Duplicate',
      handleAction: async () => {
        try {
          await duplicateItem({
            propertyAreaTypeId: areaType.id
          });

          addToast({
            type: 'success',
            description: (
              <>
                <b>
                  {getRecordTitle({
                    type: RecordTypes.PropertyAreaType,
                    object: areaType
                  })}
                </b>{' '}
                has been <b>successfully</b> duplicated
              </>
            )
          });

          refreshLists();
        } catch (e) {
          openErrorDialog(e);
        }
      }
    };
  };
}
