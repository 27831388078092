import { StatusTagMap } from 'src/modules/common/components/status-tag';
import { WorkOrderStatus } from '../types/WorkOrderStatus';

export const workOrderStatusMap: StatusTagMap<WorkOrderStatus> = {
  issued: 'neutral',
  accepted: 'average',
  completed: 'average',
  bill_added: 'good',
  revoked: 'bad'
};
