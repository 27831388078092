import { useGetPrimaryRecordActionGroup } from 'view/hooks/actions/use-get-primary-record-action-group';
import { PendingContactDisbursement } from 'data/models/entities/financials/pending-disbursements/pending-contact-disbursement';
import { RecordTypes } from 'data/models/types';
import { useTranslation } from 'react-i18next';
import { useGetDisburseFundsAction } from '../utils/disbursement-actions/use-get-disburse-action';

export function useGetPendingContactDisbursementActions() {
  const { t } = useTranslation();
  const getPrimaryRecordActionGroup = useGetPrimaryRecordActionGroup();
  const getContactPrimaryRecordActionGroup = useGetPrimaryRecordActionGroup();

  const getDisburseFundsAction = useGetDisburseFundsAction();

  return (pendingContactDisbursement?: PendingContactDisbursement) => {
    if (!pendingContactDisbursement) return [];

    return [
      ...getPrimaryRecordActionGroup(
        'pending_contact_disbursement',
        pendingContactDisbursement.id,
        t('disbursements.actions.preview.label') as string
      ),
      ...getContactPrimaryRecordActionGroup(
        'contact',
        pendingContactDisbursement.object!.id,
        'View contact'
      ),
      getDisburseFundsAction({
        recordType: RecordTypes.Contact,
        pendingDisbursement: pendingContactDisbursement
      })
    ];
  };
}
