import { SingleActionDeclaration } from 'src/modules/common/actions/types/action-declaration-types';
import { RecordTypes } from 'data/models/types';
import { useGenericSetStatementStatus } from 'src/modules/statements/hooks/use-generic-set-statement-status';
import { useIssueStatementConfirmationDialog } from 'src/modules/ownership-statements/common/dialogs/issue-statement-confirmation-dialog';
import { invokeActionDeclaration } from 'src/modules/common/actions/utils/invoke-action-declaration';
import {
  Statement,
  StatementStatus,
  StatementType
} from 'src/modules/statements/types';
import { useCreateStatements } from 'src/modules/statements/hooks/use-create-statements';
import { Ownership } from 'data/models/entities/ownerships';

export interface IssueExistingStatementArgs {
  selectedItems: Statement[];
  recordType: typeof RecordTypes.Statement;
  statementType?: StatementType;
  ignore_agency_fees?: boolean;
  label?: string;
}

interface CreateStatementArgs {
  recordType: typeof RecordTypes.Ownership;
  ownership: Ownership;
  statementType: StatementType;
  statementStartDate: string;
  statementEndDate: string;
  label?: string;
}

interface UpdateStatementArgs {
  recordType: typeof RecordTypes.Statement;
  statement: Statement;
  statementType?: StatementType;
  label?: string;
}

export type IssueStatementArgs = CreateStatementArgs | UpdateStatementArgs;

export function useIssueSingleStatementAction() {
  const setStatementStatus = useGenericSetStatementStatus();
  const openIssueStatementConfirmation = useIssueStatementConfirmationDialog();
  const createStatements = useCreateStatements();

  return (args: IssueStatementArgs): SingleActionDeclaration => {
    const onConfirm = ({
      selectedItems,
      recordType
    }: IssueExistingStatementArgs) => {
      return invokeActionDeclaration(setStatementStatus, {
        recordType,
        statement: selectedItems[0],
        status: StatementStatus.Issued,
        label: 'Issue statement' // unused but required
      });
    };

    const confirmationProps =
      args.recordType === RecordTypes.Ownership
        ? {
            selectedItems: [args.ownership],
            handleConfirmation: createStatements,
            recordType: RecordTypes.Ownership,
            status: StatementStatus.Issued,
            statementType: args.statementType,
            statementEndDate: args.statementEndDate,
            statementStartDate: args.statementStartDate
          }
        : {
            selectedItems: [args.statement],
            handleConfirmation: onConfirm,
            recordType: RecordTypes.Statement,
            status: StatementStatus.Issued,
            statementType: args.statementType || StatementType.YearlyOwnership
          };

    return {
      id: 'issue_statement',
      group: 'status',
      label: args.label ?? 'Issue statement',
      handleAction: () => openIssueStatementConfirmation(confirmationProps)
    };
  };
}
