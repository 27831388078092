import { useGetHasReportBeenCompletedWithoutRooms } from 'src/modules/tasks/inspections/hooks/use-get-has-report-been-completed-without-rooms';
import { ActionMenuItem } from '@rexlabs/action-menu';
import { ButtonProps } from '@rexlabs/button';
import { ComponentType } from 'react';
import { useGetInspectionReportCreateNewAction } from './action-declarations/use-get-inspection-report-create-new-action';
import { useGetPreviousInspectionReports } from './use-get-previous-inspection-reports';
import { useGetImportReportAction } from './action-declarations/use-get-import-report-action';
import { useGetInspectionReportResetReportAction } from './action-declarations/use-get-inspection-report-reset-report-action';

// Copied from the vivid message component
type MessageAction = {
  label?: string;
  type?: 'primary' | 'secondary' | 'tertiary';
  actions?: ActionMenuItem[];
  handleAction?: ButtonProps['onClick'];
  Icon?: ComponentType<any>;
  ActionTrigger?: ComponentType<any>;
};

export function useEmptyStateActions(inspectionTask) {
  const getInspectionReportCreateNewAction = useGetInspectionReportCreateNewAction(
    inspectionTask
  );
  const getHasReportBeenCompletedWithoutRooms = useGetHasReportBeenCompletedWithoutRooms();

  const getImportReportAction = useGetImportReportAction(inspectionTask);

  const getInspectionReportResetReportAction = useGetInspectionReportResetReportAction(
    inspectionTask
  );

  const {
    isFetched,
    data: previousInspectionReports
  } = useGetPreviousInspectionReports(inspectionTask);

  const actions: MessageAction[] = [];

  if (!isFetched) {
    return actions;
  }

  if (getHasReportBeenCompletedWithoutRooms(inspectionTask)) {
    actions.push(getInspectionReportResetReportAction());
  } else if (previousInspectionReports?.length) {
    actions.push(
      { ...getImportReportAction(), label: 'Import report' },
      {
        ...getInspectionReportCreateNewAction(),
        label: 'Create new',
        type: 'primary'
      }
    );
  } else {
    actions.push({
      ...getInspectionReportCreateNewAction(),
      type: 'primary'
    });
  }

  return actions;
}
