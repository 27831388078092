import React, { useCallback, useMemo } from 'react';

import { Query } from '@rexlabs/model-generator';
import { Action } from '@rexlabs/notifications/lib/types/message/action-buttons';
import { TagCell } from '@rexlabs/table';
import { Message } from '@rexlabs/notifications';

import TenancyIllustration from 'src/assets/illustrations/tenancy.svg';

import { Ownership } from 'data/models/entities/ownerships';
import { Property } from 'src/modules/properties/types/property-types';
import { StatusTag } from 'src/modules/common/components/status-tag';

import { Card } from 'view/components/@luna/card';
import { Columns, RecordTable } from 'view/components/table';
import { transformActionDeclarationsToActionMenuItems } from 'utils/actions/transforms';
import { usePropertyOwnershipActions } from 'src/modules/property-ownerships/actions/use-property-ownership-actions';
import { propertyOwnershipStatusMap } from 'src/modules/property-ownerships/maps/property-ownership-maps';
import { PropertyOwnership } from 'src/modules/property-ownerships/types/property-ownership-types';
import ROUTES from 'routes/app';
import { useTranslation } from 'react-i18next';
import { propertyOwnershipModel } from '../models/property-ownership-model';
import { useGetCreatePropertyOwnershipAction } from '../actions/use-get-create-property-ownership-action';

interface PropertyOwnershipsListUIProps {
  query: Query<typeof propertyOwnershipModel>;
  property?: Property;
  ownership?: Ownership;
}

const getColumns = ({
  propertyId
}: {
  propertyId?: string;
}): Columns<PropertyOwnership> => {
  return [
    {
      id: 'record_reference',
      Header: 'Reference',
      accessor: (item) => item.record_reference,
      width: 120
    },
    {
      id: 'status',
      Header: 'Status',
      Cell: ({ value }) => (
        <TagCell>
          <StatusTag status={value} map={propertyOwnershipStatusMap} />
        </TagCell>
      ),
      width: 120
    },
    propertyId
      ? {
          id: 'ownership',
          type: 'ownership',
          Header: 'Ownership'
        }
      : {
          id: 'property',
          type: 'property',
          Header: 'Property'
        },
    {
      id: 'agreement_start_date',
      accessor: (item) => item.agreement_start_date,
      type: 'date',
      Header: 'Start date',
      width: 140
    },
    {
      id: 'agreement_end_date',
      accessor: (item) => item.agreement_end_date,
      type: 'date',
      Header: 'End date',
      width: 140
    },
    {
      id: 'created_at',
      type: 'date',
      Header: 'Created at',
      width: 140
    },
    {
      id: 'updated_at',
      type: 'date',
      Header: 'Updated at',
      width: 140
    }
  ];
};

const getRowLinkProps = ({ item }) => {
  return {
    to: ROUTES.PROPERTY,
    query: { tab: 'management-agreements', managementAgreement: item.id },
    params: { propertyId: item.property.id }
  };
};

export function PropertyOwnershipsListUI({
  ownership,
  property,
  query
}: PropertyOwnershipsListUIProps) {
  const propertyId = property?.id;

  const columns = useMemo(() => getColumns({ propertyId }), [propertyId]);

  const getCreatePropertyOwnershipAction = useGetCreatePropertyOwnershipAction();

  const getPropertyOwnershipActions = usePropertyOwnershipActions();

  const getActionMenuItems = useCallback(
    ({ item }) =>
      transformActionDeclarationsToActionMenuItems(
        getPropertyOwnershipActions(item as PropertyOwnership)
      ),
    []
  );

  const { t } = useTranslation();

  return (
    <RecordTable
      withPagination={true}
      id='property-ownerships'
      columns={columns}
      getQuery={() => query}
      alwaysExpandSearch={true}
      getRowLinkProps={getRowLinkProps}
      getActionMenuItems={getActionMenuItems}
      Empty={() => (
        <Card>
          <Message
            title={t(
              'property-ownerships.management-agreement.setup-new.title'
            )}
            Illustration={TenancyIllustration}
            actions={[
              {
                ...getCreatePropertyOwnershipAction({ ownership, property }),
                type: 'primary'
              } as Action
            ]}
          >
            {t(
              'property-ownerships.management-agreement.setup-new.description'
            )}
          </Message>
        </Card>
      )}
      emptyWithoutTable
    />
  );
}
