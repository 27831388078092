import React from 'react';
import { Field } from '@rexlabs/form';
import { TextArea } from '@rexlabs/text-input';
import { Checkbox } from '@rexlabs/checkbox';
import { BlockConfig } from 'view/components/record-screen/types';
import { Column, Grid } from 'view/components/@luna/form/grid';
import { Value } from 'view/components/values';
import { CurrencyValue } from 'view/components/values/currency';
import { CentAmountInput } from 'view/components/inputs/cent-amount';
import { FlattenedProperty } from '../../properties/types/property-types';

export const propertyOwnershipMaintenanceInstructionsBlock: BlockConfig<FlattenedProperty> = {
  id: 'maintenance-instructions',
  title: 'Maintenance instructions',
  View: ({ data }) => {
    const maintenanceInstructions =
      data?.selected_property_ownership?.maintenance_instructions;

    return (
      <Grid columns={2}>
        {maintenanceInstructions?.is_inherited_from_ownership !== undefined && (
          <Value
            label='Is inherited from Ownership'
            value={
              maintenanceInstructions?.is_inherited_from_ownership
                ? 'Yes'
                : 'No'
            }
          />
        )}
        <CurrencyValue
          label='Spend limit'
          value={maintenanceInstructions?.spend_limit}
        />
        <Column width={2}>
          <Value label='Notes' value={maintenanceInstructions?.notes} />
        </Column>
      </Grid>
    );
  },
  Edit: ({ values }) => {
    const isPropertyOwnership =
      values?.selected_property_ownership?.maintenance_instructions
        ?.is_inherited_from_ownership !== undefined;
    const isDisabled =
      isPropertyOwnership &&
      values?.selected_property_ownership?.maintenance_instructions
        ?.is_inherited_from_ownership;

    return (
      <Grid columns={2}>
        {isPropertyOwnership && (
          <Field
            label='Inherit'
            name='selected_property_ownership.maintenance_instructions.is_inherited_from_ownership'
            Input={Checkbox}
            inputProps={{ label: 'Inherit from ownership' }}
          />
        )}
        <Field
          name='selected_property_ownership.maintenance_instructions.spend_limit'
          label='Spend limit'
          Input={CentAmountInput}
          inputProps={{ disabled: isDisabled }}
        />
        <Column width={2}>
          <Field
            name='selected_property_ownership.maintenance_instructions.notes'
            label='Notes'
            Input={TextArea}
            inputProps={{ disabled: isDisabled }}
          />
        </Column>
      </Grid>
    );
  }
};
