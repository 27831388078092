import React from 'react';

import { BlockConfig } from 'view/components/record-screen/types';
import Box from '@rexlabs/box';
import { Message } from '../types/Message';
import { EmailPreview } from '../../common/components/email/email-preview';
import { mapMessageToEmailPreviewProps } from '../mappers/map-message-to-email-preview-props';

// TODO: it seems like a bit of a hack to use the edit property here, but it is the only way to display "view" data in a record dialog
// TODO: might be worth creating a dedicated "view" dialog in the future if this becomes a common use case
export const messagePreviewBlock: BlockConfig<Message> = {
  id: 'preview',
  title: 'Preview message',
  Edit: ({ data }) => {
    return (
      <Box sy='6rem'>
        <EmailPreview {...mapMessageToEmailPreviewProps(data)} />
      </Box>
    );
  }
};
