import { api } from 'utils/api/api-client';
import { TaskType } from '../types/TaskType';

export async function getDefaultTaskManagerContact({
  propertyId,
  taskType
}: {
  propertyId: string | undefined;
  taskType: TaskType;
}) {
  const includes = 'portfolio.task_type_assignments.contact';

  const { data: propertyData } = await api.get(
    `/properties/${propertyId}?include=${includes}`
  );

  const taskTypeAssignments =
    propertyData?.portfolio?.task_type_assignments?.data;
  const defaultTaskMangerContact =
    taskTypeAssignments &&
    taskTypeAssignments.find(
      (taskTypeAssignment) => taskTypeAssignment.task_type.id === taskType
    )?.contact;

  return defaultTaskMangerContact;
}
