import dayjs from 'dayjs';

import { Columns } from 'src/view/components/table';

import { formatDate } from 'utils/dates/format';

import { InspectionRun } from '../types/InspectionRun';
import { InspectionRunSummaryCell } from '../components/inspection-run-summary-cell';

export const inspectionRunsColumns: Columns<InspectionRun> = [
  {
    id: 'summary',
    Header: 'Summary',
    Cell: InspectionRunSummaryCell,
    accessor: (item: InspectionRun) => item,
    toCsv: (item: InspectionRun) =>
      `${formatDate(item?.start_at, 'DD MMM YYYY')} - ${
        item?.inspection_tasks?.data?.length
      }`
  },

  {
    id: 'start_time',
    Header: 'Start time',
    accessor: (item: InspectionRun) =>
      dayjs(item.start_at).tz().format('HH:mm a'),
    width: 140
  },

  {
    id: 'finish_time',
    Header: 'Finish time',
    accessor: (item: InspectionRun) =>
      dayjs(item.finish_at).tz().format('HH:mm a'),
    width: 140
  }
];
