import { ContentConfig, TabConfig } from '../types';

export function hasNavigation(content: ContentConfig) {
  const items = content.reduce<TabConfig[]>(
    (all, group) => all.concat(group.items),
    []
  );

  return items?.length > 1;
}
