import React from 'react';
import { ConfirmationDialog, DialogProps } from '@rexlabs/dialog';
import { Body, Bold } from '@rexlabs/text';
import InfoCircleIcon from 'view/components/icons/info';
import { pluralize } from 'utils/formatters';
import { useBulkSend } from 'src/modules/communications/messages/hooks/use-bulk-send';
import { Message } from '../types/Message';
import { getMessageCountByChannelType } from '../utils/get-message-count-by-channel-type';

export interface SendMessagesConfirmationDialog extends DialogProps {
  messages: Message[];
}

export function SendMessagesConfirmationDialog({
  onClose,
  messages = []
}: SendMessagesConfirmationDialog) {
  const bulkSend = useBulkSend();
  const emailMessagesCount = getMessageCountByChannelType(messages, 'email');
  const smsMessagesCount = getMessageCountByChannelType(messages, 'sms');

  const emailSubString = pluralize('emails', emailMessagesCount, true);
  const smsSubstring = `${smsMessagesCount} SMS ${pluralize(
    'messages',
    smsMessagesCount,
    false
  )}`;
  const includesAtLeastOneEmail = emailMessagesCount > 0;
  const includesAtLeastOneSms = smsMessagesCount > 0;

  return (
    <ConfirmationDialog
      title='Send multiple messages?'
      size='s'
      onClose={onClose}
      message={
        <>
          <Body>
            {' You are about to send '}
            {includesAtLeastOneEmail && <Bold>{emailSubString}</Bold>}
            {includesAtLeastOneEmail && includesAtLeastOneSms ? ' and ' : ''}
            {includesAtLeastOneSms && <Bold>{smsSubstring}</Bold>}.
          </Body>
          <Body>Do you want to continue?</Body>
        </>
      }
      confirmText='Yes, send now'
      cancelText='Cancel'
      TitleIcon={InfoCircleIcon}
      onConfirm={() => bulkSend(messages)}
    />
  );
}
