import { useWhereabouts } from '@rexlabs/whereabouts';
import { Columns } from 'src/view/components/table';
import { SummaryCell } from 'src/modules/tasks/common/components/summary-cell';
import { ProgressDropdown } from 'src/modules/tasks/inspections/components/progress-dropdown';
import { InspectionTask } from '../types/InspectionTask';
import { closedDate, managedBy } from '../../common/utils/common-columns';
import { InspectionDateCell } from '../components/inspection-date-cell';
import { getInspectionDateToCsv } from '../utils/get-inspection-date-to-csv';

const priority = {
  id: 'priority_id',
  type: 'value',
  Header: 'Priority',
  accessor: (item: InspectionTask) => item.priority,
  toCsv: (item: InspectionTask) => item?.priority?.label
} as const;

const taskInspectionStatusId = {
  id: 'task_inspection_status_id',
  Header: 'Task progress',
  Cell: ProgressDropdown,
  accessor: (item: InspectionTask) => item,
  width: 200,
  toCsv: (item: InspectionTask) => item?.details?.status?.label || '-'
};

const dueDate = {
  id: 'due_date',
  Header: 'Task due date',
  accessor: (item: InspectionTask) => item.due_date,
  type: 'date',
  width: 140
} as const;

const inspectionDate = {
  id: 'inspection_date',
  Header: 'Inspection date',
  accessor: (item: InspectionTask) => item,
  width: 150,
  Cell: InspectionDateCell,
  toCsv: (item: InspectionTask) => getInspectionDateToCsv(item)
} as const;

const duration = {
  id: 'duration',
  Header: 'Duration',
  accessor: (item: InspectionTask) =>
    item.details?.scheduled_duration
      ? `${item.details?.scheduled_duration} min`
      : '-',
  width: 95
} as const;

const followUpDate = {
  id: 'follow_up_date',
  Header: 'Follow up date',
  accessor: (item: InspectionTask) => item.follow_up_date,
  type: 'date',
  width: 140
} as const;

const ownership = {
  id: 'ownership',
  Header: 'Ownership',
  accessor: (item: InspectionTask) =>
    item.property?.active_property_ownership?.ownership,
  type: 'ownership'
} as const;

// This is the typical approach for record cells
// it also supplies toCsv
const tenancy = {
  id: 'tenancy',
  Header: 'Tenancy',
  accessor: (item: InspectionTask) => item.details?.property_tenancy?.tenancy,
  type: 'tenancy'
} as const;

const summary = {
  id: 'summary',
  Header: 'Summary',
  Cell: SummaryCell,
  accessor: (item) => item,
  width: 380,
  toCsv: (item: InspectionTask) => item.summary
};

export function useInspectionColumns(): Columns {
  const whereabouts = useWhereabouts();
  const tab = whereabouts.hashQuery?.tab;

  if (tab === 'closed') {
    return [
      summary,
      tenancy,
      ownership,
      closedDate,
      inspectionDate,
      duration,
      managedBy,
      priority
    ];
  }

  return [
    summary,
    tenancy,
    ownership,
    followUpDate,
    dueDate,
    inspectionDate,
    duration,
    taskInspectionStatusId,
    managedBy,
    priority
  ];
}
