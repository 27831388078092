import React from 'react';
import { RecordFlag } from 'view/components/record-flags/record-flag';
import { Property } from 'src/modules/properties/types/property-types';
import { EmptyProperties } from '../empty/empty-properties';
import { EntryList } from '../components/entry-list';
import { EntryLink } from '../components/entry-link';

export interface PropertiesSectionProps {
  properties: Property[] | 'empty';
  maxListLength: number;
}

export function PropertiesSection({ properties }: PropertiesSectionProps) {
  if (properties === 'empty' || !properties?.length) {
    return <EmptyProperties />;
  }

  return (
    <EntryList>
      {properties.map((property) => (
        <EntryLink key={property.id} type='property' id={property.id}>
          <RecordFlag record={property} recordType='property' />
        </EntryLink>
      ))}
    </EntryList>
  );
}
