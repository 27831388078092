import React from 'react';

import { Field } from '@rexlabs/form';

import { AlfredFile } from 'src/modules/common/types/file';

import { BlockConfig } from 'view/components/record-screen/types';
import { Grid } from 'view/components/@luna/form/grid';
import { PreviewFileUploadInputWithErrorHandling } from 'view/components/inputs/file-upload-input/preview-file-upload-input-with-error-handling';
import { DateInput } from 'view/components/@luna/inputs/date-input/date-input';

export type AddComplianceEntryToARecordBlockValues = {
  issued_date: string;
  expiry_date: string;
  file: AlfredFile;
};

const validate = {
  definitions: {
    issued_date: { rules: 'required' },
    expiry_date: { rules: 'required' },
    file: { rules: 'required', name: 'upload document' }
  }
};

export const addComplianceEntryToARecordBlock: BlockConfig<
  any,
  any,
  AddComplianceEntryToARecordBlockValues
> = {
  id: 'add-compliance-entry-to-record',
  validate,
  Edit: ({ data: { key } }) => {
    return (
      <Grid columns={1}>
        <Grid columns={4}>
          <Field
            id='issued_date'
            name='issued_date'
            label='Date issued'
            Input={DateInput}
            // use a shorter date format so that it fits in the input
            inputProps={{
              displayFormat: 'DD MMM YYYY',
              clearable: true
            }}
          />
          <Field
            id='expiry_date'
            name='expiry_date'
            label='Expiry date'
            Input={DateInput}
            // use a shorter date format so that it fits in the input
            inputProps={{
              displayFormat: 'DD MMM YYYY',
              clearable: true
            }}
          />
        </Grid>

        <Field<typeof PreviewFileUploadInputWithErrorHandling>
          id={key + '.file'}
          name='file'
          label={'Upload document'}
          Input={PreviewFileUploadInputWithErrorHandling}
          optional={false}
        />
      </Grid>
    );
  }
};
