import React, { useCallback } from 'react';

interface Editor {
  selection: {
    save: () => void;
    restore: () => void;
  };
  html: {
    insert: (value: string) => void;
  };
}

export function useInsertToEditor() {
  const editorRef = React.useRef<{ editor: Editor }>(null);

  const insertToEditor = useCallback(
    (value: string) => {
      if (editorRef.current?.editor && editorRef.current.editor.selection) {
        const editor = editorRef.current.editor;

        // Note we need to save and then restore the selection to ensure that the
        // inserted value is inserted at the cursor position. See:
        // https://stackoverflow.com/questions/44312436/froala-editor-insert-into-caret-position-when-clicking-a-div/56736452#56736452
        editor.selection.save();

        editor.selection.restore();
        editor.html.insert(value);
      }
    },
    [editorRef]
  );

  return {
    editorRef,
    insertToEditor
  };
}
