import Box from '@rexlabs/box';
import { useErrorDialog } from '@rexlabs/dialog';
import {
  FileSelectEvent,
  PreviewFileUploadInput
} from '@rexlabs/file-upload-input';
import { Field } from '@rexlabs/form';
import { useModelActions } from '@rexlabs/model-generator';
import { Body, Semibold } from '@rexlabs/text';
import { AxiosResponse } from 'axios';
import * as React from 'react';
import { formatCurrency } from 'utils/formatters';
import { onFileSelect } from 'utils/on-file-select';
import { InfoBanner } from 'view/components/@luna/notifications/banner';
import InfoCircleIcon from 'view/components/icons/info';
import { BlockConfig } from 'view/components/record-screen/types';
import { HelpBanner } from '../components/help-banner';
import { bankStatementTransactionsModel } from '../models/bank-statement-transaction-model';
import {
  BankTransactionFilePreview,
  UploadBankSubTransactionsFormValues
} from '../types';

export const splitTransactionBlock: BlockConfig<
  any,
  { bankReferenceId?: string; amount: number },
  UploadBankSubTransactionsFormValues
> = {
  id: 'split-transaction',
  Edit: ({ blockProps, values, setValues }) => {
    const { amount, bankReferenceId } = blockProps!;

    const [preview, setPreview] = React.useState<BankTransactionFilePreview>();

    const { open: openErrorDialog } = useErrorDialog();
    const { previewBankUpload } = useModelActions(
      bankStatementTransactionsModel
    );

    const handleFileSelect = React.useCallback(
      async ({ file }: FileSelectEvent) => {
        try {
          const uploadedFile = await onFileSelect({ file });

          const filePreview: AxiosResponse<BankTransactionFilePreview> = await previewBankUpload(
            uploadedFile.data.id
          );

          setPreview(filePreview.data);

          return uploadedFile;
        } catch (error) {
          console.error(error);
          openErrorDialog(error);

          setValues?.({
            sub_bank_transactions_file: ''
          });
          setPreview(undefined);
        }
      },
      []
    );

    const handleFileChange = React.useCallback((event) => {
      if (!event.target.value) {
        setPreview(undefined);
      }
    }, []);

    return (
      <div>
        <Box marginBottom={24}>
          <HelpBanner />
        </Box>

        <Body>
          To split this transaction, upload a file that matches{' '}
          {bankReferenceId ? (
            <>
              the reference number <Semibold>{bankReferenceId}</Semibold> and{' '}
            </>
          ) : (
            ''
          )}
          the amount <Semibold>{formatCurrency(amount)}</Semibold>.
        </Body>

        <Field
          id='sub_bank_transactions_file'
          name='sub_bank_transactions_file'
          label='Attachments'
          optional={false}
          onChange={handleFileChange}
          Input={PreviewFileUploadInput}
          inputProps={{
            onFileSelect: handleFileSelect,
            accept: '.qif, .csv'
          }}
        />

        <Body>Supported file formats: QIF, CSV.</Body>

        {preview && (
          <InfoBanner
            Icon={InfoCircleIcon}
            description={getInfoBannerDescription({
              fileName: values?.sub_bank_transactions_file?.file?.name,
              amount: preview?.amount,
              count: preview?.count
            })}
          />
        )}
      </div>
    );
  }
};

function getInfoBannerDescription({ fileName, count, amount }) {
  const fileNameString = <b>{`'${fileName}'`}</b>;
  const countString = <b>{count} transactions</b>;
  const amountString = <b>{formatCurrency(amount)}</b>;
  return (
    <>
      Uploaded {fileNameString} containing {countString} for a total of{' '}
      {amountString}.
    </>
  );
}
