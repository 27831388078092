import React from 'react';
import Box from '@rexlabs/box';
import { styled, text, StyleSheet, StylesProps } from '@rexlabs/styling';
import { BlockConfig } from 'view/components/record-screen/types';
import { Forms } from '@rexlabs/form';

const defaultStyles = StyleSheet({
  steps: {
    width: '18rem',
    position: 'sticky',
    alignSelf: 'flex-start',
    top: 0
  },

  step: {
    ...text.styles({ fallback: 'normal.default' }),
    paddingLeft: '1.8rem',
    paddingBottom: '1rem',
    position: 'relative',

    '&:before': {
      content: '" "',
      position: 'absolute',
      left: 0,
      top: '.6rem',
      height: '.6rem',
      width: '.6rem',
      borderRadius: '50%',
      background: ({ token }) => token('palette.neutral.400'),
      transition: 'background .2s, transform .2s'
    },

    '&:after': {
      content: '" "',
      position: 'absolute',
      top: '1.8rem',
      bottom: 0,
      left: '.25rem',
      width: '.1rem',
      background: ({ token }) => token('palette.neutral.400'),
      transition: 'background .2s'
    },

    '&:last-child:after': {
      display: 'none'
    }
  },

  stepComplete: {
    '&:before': {
      background: ({ token }) => token('color.primary.idle.default')
    }
  },

  stepError: {
    '&:before': {
      background: ({ token }) => token('palette.red.500')
    }
  },

  stepActive: {
    ...text.styles({ fallback: 'normal.bold' }),

    '&:before': {
      background: ({ token }) => token('color.primary.idle.default'),
      transform: 'scale(1.2)'
    }
  }
});

export type Step = {
  id?: string;
  label: string;
  Content: React.ReactType;
  blocks?: BlockConfig[];
};

export interface StepsProps {
  steps: Step[];
  currentStep: number;
  forms?: Forms;
}

export const Steps = styled(defaultStyles)(
  ({
    steps,
    currentStep,
    forms,
    styles: s
  }: StepsProps & StylesProps<typeof defaultStyles>) => {
    return (
      <Box {...s('steps')} flexDirection='column'>
        {steps.map((step, index) => {
          const isValid = step?.blocks?.every?.(
            (block) => block.id && forms?.[block.id]?.isValid
          );
          return (
            <Box
              key={index}
              {...s('step', {
                stepActive: index === currentStep,
                stepComplete: index < currentStep,
                stepError: !isValid
              })}
            >
              {step.label}
            </Box>
          );
        })}
      </Box>
    );
  }
);
