import React from 'react';
import { Field } from '@rexlabs/form';

import { BlockConfig } from 'view/components/record-screen/types';
import { Grid } from 'view/components/@luna/form/grid';
import { ValueListSelect } from 'view/components/inputs/selects/value-list';
import { Value } from 'view/components/values';
import { Property } from '../types/property-types';

const validate = {
  definitions: {
    type: { name: 'property type', rules: 'required' }
  }
};

type DetailsBlockFormValues = Partial<Pick<Property, 'type'>>;

export const propertyTypeBlock: BlockConfig<
  Property,
  any,
  DetailsBlockFormValues
> = {
  id: 'property-type',
  title: 'Type',
  validate,
  View: ({ data }) => {
    return (
      <Grid columns={2}>
        <Value value={data?.type?.label} label='Property type' />
      </Grid>
    );
  },
  Edit: () => {
    return (
      <Grid columns={2}>
        <Field
          name='type'
          label='Property type'
          Input={ValueListSelect}
          inputProps={{
            type: 'property_type'
          }}
          optional={false}
        />
      </Grid>
    );
  }
};
