import { useQuery } from 'react-query';

import { ChartOfAccountsAccount } from 'src/modules/chart-of-accounts/types/chart-of-account-types';

import { api } from 'utils/api/api-client';

export const chartOfAccountsQueryKey = ['chart-of-accounts'];

/**
 * This function creates a promise that fetches all chart of accounts accounts. It does this by fetching the
 * first page of data, then fetching the remaining pages of data, if there are more than 1 pages.
 * While primarily used for the useChartOfAccountsAccounts hook, it can be used for other purposes - such as
 * pre-fetching the data for react query to store in the cache.
 * @returns {Promise<ChartOfAccountsAccount>}
 */
export async function fetchAllChartOfAccountsAccounts(): Promise<
  ChartOfAccountsAccount[]
> {
  const query = new URLSearchParams();
  query.set('per_page', '100');
  query.set('q', 'status_id.neq(archived)');

  const queryString = decodeURIComponent(query.toString());

  const res = await api.get(`/financials/chart-of-accounts?${queryString}`);

  const pagination = res['pagination'];

  const data = res.data;

  if (pagination.total_pages > 1) {
    const remainingPages = await Promise.all(
      Array.from({
        length: pagination.total_pages - 1
      }).map((_, index) =>
        api.get(
          `/financials/chart-of-accounts?${queryString}&page=${index + 2}`
        )
      )
    );

    remainingPages.forEach((page) => data.push(...page.data));
  }

  return data;
}

/**
 * Use this hook to fetch all chart of accounts accounts that are either already stored in the cache or fetches
 * them from the server if they are not. The reason for this, is so the user does not have to perform a search
 * request, and we can save them time by storing them for the next time they need to be used.
 * We're using this on the basis that an agency will not have a large number of chart of accounts accounts, but
 * we will need to revisit this, if this assumption is incorrect.
 * @returns {ChartOfAccountsAccount[]}
 */
export function useChartOfAccountsAccounts() {
  const { data } = useQuery(
    chartOfAccountsQueryKey,
    fetchAllChartOfAccountsAccounts,
    {
      refetchOnWindowFocus: false,
      cacheTime: Infinity,
      staleTime: Infinity
    }
  );

  return data;
}
