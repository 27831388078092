import React from 'react';
import { Field } from '@rexlabs/form';

import { BlockConfig } from 'view/components/record-screen/types';
import { Grid } from 'view/components/@luna/form/grid';
import { Value } from 'view/components/values';

import { ServiceCategorySelect } from 'view/components/inputs/selects/service-category';
import { MaintenanceTask } from '../types/MaintenanceTask';
import { MaintenanceFormData } from '../types/MaintenanceFormData';

export const workDetailsMaintenanceTypeBlock: BlockConfig<
  MaintenanceTask,
  any,
  MaintenanceFormData
> = {
  id: 'work-details-type',
  title: 'Work details',
  View: ({ data }) => {
    return (
      <Grid columns={2}>
        <Value label='Maintenance type' value={data?.details?.type?.label} />
      </Grid>
    );
  },
  Edit: () => {
    return (
      <Grid columns={2}>
        <Field
          name='details.type'
          label='Maintenance type'
          Input={ServiceCategorySelect}
        />
      </Grid>
    );
  }
};
