import React from 'react';
import { RecordDialog } from 'view/components/record-screen/dialog/dialog';
import { FileUpload } from 'src/modules/common/types/file';
import { Dialog } from '@rexlabs/dialog';
import { Value } from 'view/components/values';
import { Box } from '@rexlabs/box';
import { ImagePreview } from 'view/components/image-preview';
import { useToken } from '@rexlabs/styling';

interface PreviewImagesDialogProps
  extends Pick<React.ComponentProps<typeof RecordDialog>, 'onClose'> {
  images: FileUpload[];
  areaItemName: string;
}

export function PreviewImagesDialog({
  onClose,
  images,
  areaItemName
}: PreviewImagesDialogProps) {
  const token = useToken();

  return (
    <Dialog title={`Images - ${areaItemName}`} onClose={onClose}>
      {images.length ? (
        <Value
          value={
            <>
              <Box
                spacing={token('spacing.l')}
                flexDirection='row'
                flexWrap='wrap'
              >
                {images!.map((image, index) => {
                  return (
                    <ImagePreview
                      src={image?.file.url}
                      key={image?.file.id || index}
                      downloadUrl={image?.file.download_url}
                    />
                  );
                })}
              </Box>
            </>
          }
        />
      ) : null}
    </Dialog>
  );
}
