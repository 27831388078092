import { getSearchResultItemFromObjectAndModel } from 'src/modules/common/utils/search-result-items/get-search-result-items-from-object-and-model';
import { WorkOrderTask } from '../types/WorkOrderTask';

export function getCreateInvoiceInitialValues(data?: WorkOrderTask) {
  const payableByOwnership = getSearchResultItemFromObjectAndModel(
    data?.property?.active_property_ownership?.ownership,
    'ownership'
  );
  const payable_by = payableByOwnership
    ? {
        object: payableByOwnership
      }
    : undefined;

  const workAssignedTo = getSearchResultItemFromObjectAndModel(
    data?.details?.work_done_by,
    'contact'
  );

  const payable_to = workAssignedTo
    ? {
        object: workAssignedTo
      }
    : undefined;

  return {
    payable_by,
    payable_to,
    description: data?.description || undefined,
    line_items: [
      {
        description: data?.description || undefined
      }
    ],
    task: data
  };
}
