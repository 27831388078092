import * as React from 'react';
import Icons from '@rexlabs/icons';
import { RecordDialog } from 'view/components/record-screen/dialog/dialog';
import { DialogContentConfig } from 'view/components/record-screen/dialog/types';
import { DialogProps } from '@rexlabs/dialog';
import { PendingDisbursement } from 'data/models/entities/financials/pending-disbursements';
import { push } from '@rexlabs/whereabouts';
import { BANK_WITHDRAWALS_ROUTES } from 'src/modules/banking/bank-withdrawals/routes';
import { RecordSubmitHandler } from 'view/components/record-screen/utils';
import { useTranslation } from 'react-i18next';
import { disburseFundsDetailsBlock } from '../blocks/disburse-funds-details-block';
import { DisburseFundsConfirmationDialogButtonGroup } from '../components/disburse-funds-confirmation-dialog-button-group';

export const content: DialogContentConfig = [
  {
    id: 'details',
    label: 'Details',
    blocks: [disburseFundsDetailsBlock]
  }
];

interface DisburseFundsConfirmationDialogProps extends DialogProps {
  entitiesToDisburse: PendingDisbursement[];
  handleConfirmation: (
    entitiesToDisburse: PendingDisbursement[]
  ) => Promise<void>;
}

export const DisburseFundsConfirmationDialog = ({
  onClose: onCancel,
  entitiesToDisburse,
  handleConfirmation
}: DisburseFundsConfirmationDialogProps) => {
  const { t } = useTranslation();
  const recordType = entitiesToDisburse[0].object_type;
  const redirectToWithdrawals = () => {
    push(BANK_WITHDRAWALS_ROUTES.BANK_WITHDRAWALS_LIST);
  };

  const handleSubmit: RecordSubmitHandler = React.useCallback(async () => {
    await handleConfirmation(entitiesToDisburse);
    return true;
  }, []);

  return (
    <RecordDialog
      ButtonGroup={DisburseFundsConfirmationDialogButtonGroup}
      onClose={onCancel}
      TitleIcon={Icons.InfoCircle}
      title={t('disbursements.confirm-for-record-type', { recordType })}
      submitLabel={t('disbursements.disburse-now')}
      content={content}
      handleSubmit={handleSubmit}
      blockProps={{
        entitiesToDisburse
      }}
      buttonGroupProps={{ redirectToWithdrawals }}
    />
  );
};
