import { createContext } from 'react';

export type SmartChecklistContext = {
  isEditing: boolean;
};

export const smartChecklistContext = createContext<SmartChecklistContext>({
  isEditing: false
});

export const SmartChecklistContextProvider = smartChecklistContext.Provider;
