import * as React from 'react';
import { api } from 'utils/api/api-client';
import { useToast } from 'view/components/@luna/notifications/toast';
import { RecordDialog } from 'view/components/record-screen/dialog/dialog';
import { RecordSubmitHandler } from 'view/components/record-screen/utils';
import { useTranslation } from 'react-i18next';
import { lowerCase } from 'lodash';
import { useDefaultBankAccountQuery } from '../hooks/use-default-bank-account-query';
import { trustJournalBlock } from '../blocks/trust-journal-block';
import { downloadFile } from '../utils/download-file';

const content = [
  {
    id: 'create-trust-journal',
    label: 'Detail',
    blocks: [trustJournalBlock]
  }
];

export function CreateTrustJournalReport({ onClose }) {
  const { t } = useTranslation();
  const { addToast } = useToast();
  const [bankAccounts, defaultAccount] = useDefaultBankAccountQuery();

  const onSubmit: RecordSubmitHandler = React.useCallback(
    async ({ values }) => {
      const payload = {
        format: 'pdf',
        ...values
      };

      try {
        const response = await api.post(`/reporting/trust-journal`, payload, {
          responseType: 'blob'
        });

        downloadFile(
          response,
          `trust_journal_${values.start_date}_${values.end_date}`
        );

        addToast({
          description: (
            <>
              The{' '}
              <b>
                {lowerCase(
                  t(
                    'reporting.trust-reports.trust-journal-report.label.singular'
                  ) as string
                )}
              </b>{' '}
              has been generated and downloaded
            </>
          )
        });

        return true;
      } catch (error) {
        addToast({
          description: (
            <>
              There was some error in generating the report. Please try again
              later
            </>
          ),
          type: 'error'
        });
      }
    },
    [addToast]
  );

  const initialValues = React.useMemo(() => {
    return {
      date_type: {
        id: 'creation_date'
      },
      bank_account: defaultAccount
    };
  }, [defaultAccount]);

  return (
    <RecordDialog
      isLoading={bankAccounts.isLoading}
      title={t(
        'reporting.trust-reports.trust-journal-report.generate.label.singular'
      )}
      initialValues={initialValues}
      content={content}
      onClose={onClose}
      submitLabel='Generate & download'
      handleSubmit={onSubmit}
    />
  );
}
