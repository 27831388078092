import { useGetGenericWipAction } from 'view/hooks/actions/use-get-generic-wip-action';

export function useGetWithdrawAction() {
  const getGenericWipAction = useGetGenericWipAction();

  return () =>
    getGenericWipAction({
      id: 'withdrawal',
      group: 'trust-journal-entry',
      actionName: 'Withdraw from bank'
    });
}
