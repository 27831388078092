import { SelectNormalised } from '@rexlabs/select-old';
import { RecordTypes } from 'data/models/types';
import { AgencyFee } from 'src/modules/agency-fees/types/agency-fee-types';

export function normaliseAgencyFee(item: AgencyFee): SelectNormalised {
  return {
    value: item,
    label: item.name,
    type: RecordTypes.AgencyFee
  };
}
