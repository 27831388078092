import React from 'react';
import { Field } from '@rexlabs/form';

import { BlockConfig } from 'view/components/record-screen/types';
import { Checkbox } from '@rexlabs/checkbox';
import { TextInput } from '@rexlabs/text-input';
import { Body, Bold } from '@rexlabs/text';
import {
  ExcludeStatementForm,
  StatementDisplayData
} from 'src/modules/statements/dialogs/exclude-eofy-statement-dialog';
import dayjs from 'dayjs';
import { formatDate } from 'utils/dates/format';
import Box from '@rexlabs/box';
import { useToken } from '@rexlabs/styling';

export const excludeEofyStatementBlock: BlockConfig<
  StatementDisplayData,
  any,
  ExcludeStatementForm
> = {
  id: 'bank-account-details-block',
  title: 'Account details',
  validate: {
    definitions: {
      reason: { rules: 'required' }
    }
  },

  Edit: ({ data }) => {
    const token = useToken();
    // Note that for an eofy statement, these dates will always be defined.
    // TODO: ensure this gets updated with Jay's new version of dayjs
    const startDate = data?.startDate ? dayjs(data?.startDate) : undefined;
    const endDate = data?.endDate ? dayjs(data?.endDate) : undefined;
    const formattedStartDate = startDate && formatDate(startDate);
    const formattedEndDate = endDate && formatDate(endDate);

    const ownershipName = data?.ownershipName;

    return (
      <>
        <Body>
          You are about to exclude the following financial year statement:
        </Body>
        <Body>
          <Bold>
            FY {startDate?.format?.('YY')}/{endDate?.format?.('YY')} (
            {formattedStartDate} - {formattedEndDate})
          </Bold>{' '}
          for <Bold>{ownershipName}</Bold>
        </Body>
        <Body>
          If you do not wish for financial year statements to be created for
          this ownership, please change the setting in the ownership record or
          tick the checkbox below.
        </Body>
        <Box mt={token('spacing.s')} mb={token('spacing.s')}>
          <Field label='Reason for excluding' name='reason' Input={TextInput} />
        </Box>
        <Field
          name={'should_exclude_on_ownership'}
          optional={false}
          Input={Checkbox}
          inputProps={{
            label:
              'Do not create financial year statements for this ownership in the future'
          }}
        />
      </>
    );
  }
};
