import { NormalisedItem, Select } from '@rexlabs/select';
import * as React from 'react';
import { useRecordsQuery } from 'src/lib/react-query/hooks/use-records-query';
import { Role } from 'src/modules/roles/types/role-types';

export function useUserRoleQuery() {
  return useRecordsQuery<Role>(['roles'], {
    staleTime: 60 * 1000
  });
}

const normaliser = (item: Role): NormalisedItem => ({
  id: item.id,
  label: item.name
});

export function UserRoleSelect(props) {
  const { data: roles } = useUserRoleQuery();

  return <Select normaliser={normaliser} items={roles} {...props} />;
}
