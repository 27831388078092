import Validator from '@rexlabs/validator';

Validator.register(
  'itemNameMustBeUnique',
  function (this: any, value: string) {
    const items = (this.validator?.input?.items || [])
      .map((item) => item.name)
      .filter((label) => label === value);

    return items.length <= 1;
  },
  'Item names must be unique.'
);
