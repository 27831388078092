/**
 * Takes a number to split by 100 and returns an array of numbers that add up to 100. Not all numbers can
 * be split evenly so the remainder is spread across the array.
 * @param numberToSplitBy100 number
 * @returns number[]
 */
export function getEvenSharePercentage(numberToSplitBy100: number): number[] {
  // First get the share rounds down to the nearest 1 decimal place
  const share = Math.floor((100 / numberToSplitBy100) * 100) / 100;
  // Work out the remainder and round to the nearest 1 decimal place
  const remainder = Math.round((100 - share * numberToSplitBy100) * 100) / 100;

  const shares: number[] = [];

  for (let i = 0; i < numberToSplitBy100; i++) {
    // While the index is less than the remainder * 10 (to make it a whole number), add 0.1 to the share
    const remainderShare = i < remainder * 100 ? 0.01 : 0;
    shares.push(Math.round((share + remainderShare) * 100) / 100);
  }

  return shares;
}
