import { useDialog } from '@rexlabs/dialog';
import { ActionDeclaration } from 'src/modules/common/actions/types/action-declaration-types';
import { Contact } from 'src/modules/contacts/types/contact-types';

import { Ownership } from 'data/models/entities/ownerships';

import { CreateTaxAdjustmentDialog } from '../dialogs/create-tax-adjustment-dialog';
import { useGetCreateTaxAdjustmentSubmitHandler } from './use-get-create-tax-adjustment-submit-handler';

export function useGetCreateTaxAdjustmentAction() {
  const { open: openCreateTaxAdjustmentDialog } = useDialog(
    CreateTaxAdjustmentDialog
  );
  const getCreateTaxAdjustmentSubmitHandler = useGetCreateTaxAdjustmentSubmitHandler();

  return (ownership: Ownership, contact: Contact): ActionDeclaration => {
    const submitHandler = getCreateTaxAdjustmentSubmitHandler(ownership);

    return {
      label: 'Create tax adjustment',
      handleAction: () =>
        openCreateTaxAdjustmentDialog({
          submitHandler,
          initialValues: { contact }
        })
    };
  };
}
