import React from 'react';
import { Owner } from 'data/models/entities/ownerships';
import { EmptyOwners } from '../empty/empty-owners';
import { OwnershipEntries } from './ownership-entries';

export interface OwnersSectionProps {
  owners: Owner[] | 'empty';
  maxListLength: number;
}

export function OwnersSection({ owners, maxListLength }: OwnersSectionProps) {
  if (owners === 'empty') {
    return <EmptyOwners />;
  }

  return <OwnershipEntries owners={owners} maxListLength={maxListLength} />;
}
