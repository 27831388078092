import React from 'react';
import { TextInput } from '@rexlabs/text-input';
import { Field } from '@rexlabs/form';
import { BlockConfig } from 'view/components/record-screen/types';
import { Grid } from 'view/components/@luna/form/grid';
import { Value } from 'view/components/values';
import { PropertyArea } from 'src/modules/property-areas/property-areas/types/PropertyArea';
import { PropertyAreaTypeSelect } from 'view/components/inputs/selects/v4-selects/property-area-type-select';

const validate = {
  definitions: {
    name: { rules: 'required' }
  }
};

export const propertyAreaDetailsBlock: BlockConfig<PropertyArea> = {
  id: 'details',
  validate,
  Edit: ({ data, blockProps, forms, getHandlers }) => {
    const handlers = getHandlers?.(forms);

    return (
      <Grid columns={2}>
        {blockProps?.isEditMode ? (
          <Value label={'Area type'} value={data?.property_area_type?.name} />
        ) : (
          <Field
            name='property_area_type'
            id='area-type-select'
            label='Area type'
            Input={PropertyAreaTypeSelect}
            onChange={handlers?.handleAreaTypeChange}
          />
        )}

        <Field name='name' label='Display name' Input={TextInput} />
      </Grid>
    );
  }
};
