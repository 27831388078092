import React from 'react';

import { SearchResultItem } from 'utils/api/get-search-results';

import { BlockConfig } from 'view/components/record-screen/types';

import {
  CoreCommunicationContextObject,
  CreateMessageFormData
} from '../mappers/types/create-message-form-data';
import { Message } from '../types/Message';
import { getContextTypeFromRecord } from '../utils/get-context-type-from-record';
import { useMessageTriggerSettings } from '../settings/message-triggers/hooks/use-message-trigger-settings';
import { EditEmailSingleSendContentForm } from '../components/message-content-forms/edit-email-single-send-content-form';
import { validateEditEmailForm } from '../components/message-content-forms/basic-forms/basic-edit-email-form';

export const messageContentBlock: BlockConfig<
  Message & { relates_to: SearchResultItem<CoreCommunicationContextObject> },
  any,
  CreateMessageFormData
> = {
  id: 'content',
  title: 'Message Content',
  validate: validateEditEmailForm,
  Edit: ({ data, setFieldValue, values }) => {
    const {
      getTemplatedAttachmentForContextType
    } = useMessageTriggerSettings();
    return (
      <EditEmailSingleSendContentForm
        attachments={values?.attachments || []}
        templatedAttachments={getTemplatedAttachmentForContextType(
          getContextTypeFromRecord(data?.relates_to?.record)
        )}
        contextType={getContextTypeFromRecord(data?.relates_to?.record)}
        setFieldValue={setFieldValue}
        relatesTo={data?.relates_to}
      />
    );
  }
};
