import { query, useModelActions } from '@rexlabs/model-generator';
import { TagCell } from '@rexlabs/table';
import { Ownership, ownershipsModel } from 'data/models/entities/ownerships';
import dayjs from 'dayjs';
import React, { useCallback, useMemo } from 'react';
import {
  ActionDeclaration,
  SingleActionDeclaration
} from 'src/modules/common/actions/types/action-declaration-types';
import { StatusTag } from 'src/modules/common/components/status-tag';
import { statementsModel } from 'src/modules/statements/models/statements';
import {
  FinancialPeriod,
  Statement,
  StatementStatus,
  StatementType
} from 'src/modules/statements/types';
import { transformActionDeclarationsToActionMenuItems } from 'utils/actions/transforms';
import { formatDateIso } from 'utils/dates/format';
import { Columns, ListTable, TabbedTable } from 'view/components/table';
import { useTableFilters } from 'view/hooks/use-table-filters';
import { useBulkModelDownloadAction } from 'src/modules/common/actions/reporting/use-bulk-model-download-action';
import { useStatementActions } from 'src/modules/ownership-statements/common/actions/use-statement-actions';
import { Tab } from 'view/components/table/tabbed';
import { RecordTypes } from 'data/models/types';
import { useDialog } from '@rexlabs/dialog';
import { PreviewStatementsDialog } from 'src/modules/ownership-statements/common/dialogs/preview-statements-dialog';
import { useFeatureFlags } from 'view/components/@luna/feature-flags';
import { FLAGS } from 'utils/feature-flags';
import { useConfirmExcludeEofyStatementAction } from 'src/modules/statements/hooks/use-confirm-exclude-eofy-statement-action';
import { useBulkIssueStatementAction } from 'src/modules/statements/hooks/use-bulk-issue-statement-action';
import { useCreateStatements } from '../../../statements/hooks/use-create-statements';
import { statementStatusMap } from '../maps/statementStatusMap';
import { useIssueStatementConfirmationDialog } from '../dialogs/issue-statement-confirmation-dialog';
import { issueStatementToastAction } from '../actions/issue-statement-toast-action';
import {
  IssueStatementActionCell,
  IssueStatementActionCellValue
} from './issue-statement-action-cell';

const getOwnershipsQuery = () => query`{
  ${ownershipsModel} {
    id,
    owners
  }
}`;

const getStatementsQuery = () => query`{
  ${statementsModel} {
    id
    ownership {
      owners
    }
    recipients
  }
}`;

interface PeriodicStatementConfig {
  statementEndDate: string;
  numberOfDays: number;
}

interface YearlyStatementConfig {
  statementPeriod: FinancialPeriod;
}

export type OwnershipStatementsTableProps =
  | ({
      statementType: StatementType.PeriodicOwnership;
    } & PeriodicStatementConfig)
  | ({
      statementType: StatementType.YearlyOwnership;
    } & YearlyStatementConfig);

export function OwnershipStatementsTable(props: OwnershipStatementsTableProps) {
  const { hasFeature } = useFeatureFlags();
  const ownershipsQuery = useMemo(() => getOwnershipsQuery(), []);
  const statementsQuery = useMemo(() => getStatementsQuery(), []);
  const { bulkDownloadItems } = useModelActions(statementsModel);
  const statementActions = useStatementActions();

  const {
    getSort: getOwnershipsSort,
    getFilters: getOwnershipsFilters
  } = useTableFilters('ownerships');

  const {
    getSort: getStatementsSort,
    getFilters: getStatementsFilters
  } = useTableFilters('statements');

  const { open: openPreviewStatementsDialog } = useDialog(
    PreviewStatementsDialog
  );

  const issueStatements = useCreateStatements();
  const issueStatementConfirmationDialog = useIssueStatementConfirmationDialog();
  const getConfirmExcludeStatementAction = useConfirmExcludeEofyStatementAction();

  const getBulkIssueStatementAction = useBulkIssueStatementAction();

  const modelBulkDownloadAction = useBulkModelDownloadAction();
  const shouldShowStatementPreview =
    hasFeature(FLAGS.STATEMENT_PREVIEW) &&
    props.statementType === StatementType.YearlyOwnership;

  const statementEndDate =
    props.statementType === StatementType.PeriodicOwnership
      ? props.statementEndDate
      : props.statementPeriod.endDate;

  const unissuedOwnershipStatementColumns: Columns<Ownership> = [
    {
      Header: 'Ownership',
      accessor: (item) => item.display_name
    },
    props.statementType === StatementType.PeriodicOwnership
      ? {
          Header: 'Last statement date',
          type: 'date',
          accessor: (item) => item.last_statement_date
        }
      : {
          Header: 'Statement period',
          type: 'date_range',
          accessor: () => {
            return {
              from: props.statementPeriod.startDate,
              to: props.statementPeriod.endDate
            };
          }
        },
    {
      id: 'action',
      accessor: (item): IssueStatementActionCellValue => {
        return {
          statementEndDate,
          ...(props.statementType === StatementType.YearlyOwnership && {
            statementStartDate: props.statementPeriod.startDate
          }),
          ownership: item,
          statementType: props.statementType
        };
      },
      Cell: IssueStatementActionCell,
      width: 70,
      sticky: 'right'
    }
  ];

  // strictly, issued or voided
  const issuedStatementColumns: Columns<Statement> = [
    {
      Header: 'Ownership',
      accessor: (item) => item.object.display_name
    },
    {
      Header: 'Statement period',
      type: 'date_range',
      accessor: (item) => {
        return { from: item.statement_from, to: item.statement_to };
      }
    },
    {
      accessor: (item) => item.status,
      Header: 'Status',
      Cell: (table) => {
        const {
          cell: { value }
        } = table;
        return (
          <TagCell>
            <StatusTag status={value} map={statementStatusMap} />
          </TagCell>
        );
      }
    }
  ];

  const notIssuedStatementColumns: Columns<Statement> = [
    {
      Header: 'Ownership',
      accessor: (item) => item.object.display_name
    },
    {
      Header: 'Statement period',
      type: 'date_range',
      accessor: (item) => {
        return { from: item.statement_from, to: item.statement_to };
      }
    }
  ];

  const getNotIssuedBulkActionsForOwnerships = useCallback(
    ({ selectedItems }: { selectedItems: Ownership[] }) => {
      return [
        {
          label: 'Issue statements',
          type: 'primary',
          handleAction: () => {
            issueStatementConfirmationDialog({
              selectedItems,
              statementType: props.statementType,
              statementEndDate,
              recordType: RecordTypes.Ownership,
              ...(props.statementType === StatementType.YearlyOwnership && {
                statementStartDate: props.statementPeriod.startDate
              }),
              handleConfirmation: issueStatements,
              toastAction: issueStatementToastAction(props.statementType)
            });
          }
        } as ActionDeclaration,
        ...(shouldShowStatementPreview
          ? [
              {
                label: 'Preview statements',
                type: 'secondary',
                handleAction: () =>
                  openPreviewStatementsDialog({
                    statementsData: selectedItems.map((selectedItem) => ({
                      ownership: selectedItem,
                      statementType: props.statementType,
                      statementEndDate,
                      ...(props.statementType ===
                        StatementType.YearlyOwnership && {
                        statementStartDate: props.statementPeriod.startDate
                      })
                    }))
                  })
              }
            ]
          : [])
      ];
    },
    [
      issueStatementConfirmationDialog,
      issueStatements,
      openPreviewStatementsDialog,
      props.statementType,
      statementEndDate
    ]
  );

  const getNotIssuedBulkActionsForNotIssuedStatements = useCallback(
    ({ selectedItems }: { selectedItems: Statement[] }) => {
      return [
        ...(shouldShowStatementPreview
          ? [
              {
                ...getBulkIssueStatementAction({
                  statements: selectedItems,
                  recordType: RecordTypes.Statement,
                  statementType: props.statementType
                }),
                type: 'primary'
              },
              {
                label: 'Preview statements',
                type: 'secondary',
                handleAction: () => {
                  openPreviewStatementsDialog({
                    statementsData: selectedItems.map((selectedItem) => ({
                      statement: selectedItem
                    }))
                  });
                }
              } as ActionDeclaration
            ]
          : [])
      ];
    },
    [
      issueStatementConfirmationDialog,
      openPreviewStatementsDialog,
      props.statementType,
      statementEndDate
    ]
  );

  const getIssuedBulkActions = ({
    selectedItems
  }: {
    selectedItems: Statement[];
  }): ActionDeclaration[] => {
    const data = selectedItems
      .map((statement) => {
        return statement.recipients.items.map((contact) => {
          return {
            statement: {
              id: statement.id
            },
            recipient: {
              id: contact.id
            }
          };
        });
      })
      .flat();

    const bulkDownloadAction = modelBulkDownloadAction({
      fetcher: () => {
        return bulkDownloadItems({
          data
        });
      },
      label: 'Download all'
    });

    return [
      {
        label: 'Download statements',
        type: 'primary',
        handleAction: () => {
          return bulkDownloadAction.handleAction!();
        }
      } as SingleActionDeclaration
    ];
  };

  const commonTabProps = {
    id: 'periodic-ownership-statements',
    Table: ListTable,
    suggestedFilters: ['locked'],
    initialGlobalFilter: [
      {
        field: 'locked',
        op: 'eq',
        value: false
      }
    ]
  };

  const commonStatementTabProps = {
    getQuery: () => statementsQuery,
    columns: notIssuedStatementColumns,
    initialSortBy: [{ id: 'created_at', label: 'Created at', desc: true }],
    getSort: getStatementsSort,
    getFilters: getStatementsFilters,
    getActionMenuItems: ({ item }) =>
      transformActionDeclarationsToActionMenuItems(statementActions(item))
  };

  const tabs: Tab[] = [
    {
      ...commonTabProps,
      name: 'not-reviewed',
      label: shouldShowStatementPreview ? 'Not reviewed' : 'Not Issued',
      columns: unissuedOwnershipStatementColumns,
      getQuery: () => ownershipsQuery,

      forcedGlobalFilter: [
        ...(props.statementType === StatementType.PeriodicOwnership
          ? [
              {
                field: 'last_statement_date',
                op: 'lte',
                value: formatDateIso(
                  dayjs(props.statementEndDate).subtract(
                    props.numberOfDays,
                    'days'
                  )
                )
              }
            ]
          : [
              {
                field: 'due_for_eoy_statement',
                op: 'eq',
                value: props.statementPeriod.startDate
              },
              {
                field: 'eofy_statement_enabled',
                op: 'neq',
                value: false
              }
            ])
      ],
      getSort: getOwnershipsSort,
      getFilters: getOwnershipsFilters,
      getBulkActions: getNotIssuedBulkActionsForOwnerships,
      // temp, for manual testing.
      getActionMenuItems: ({ item }: { item: Ownership }) => {
        if (!shouldShowStatementPreview) {
          return [];
        }
        return transformActionDeclarationsToActionMenuItems([
          getConfirmExcludeStatementAction({
            recordType: RecordTypes.Ownership,
            ownership: item,
            statementStartDate: props.statementPeriod.startDate,
            statementEndDate: props.statementPeriod.endDate,
            statementType: props.statementType
          })
        ]);
      }
    },
    ...(shouldShowStatementPreview
      ? [
          {
            ...commonTabProps,
            ...commonStatementTabProps,
            name: 'approved',
            label: 'Approved',
            forcedGlobalFilter: [
              {
                field: 'statement_to',
                op: 'eq',
                value: props.statementPeriod.endDate
              },
              {
                field: 'type_id',
                op: 'eq',
                value: props.statementType
              },
              {
                field: 'status_id',
                op: 'eq',
                value: StatementStatus.Approved
              },
              {
                field: 'eofy_statement_enabled',
                op: 'eq',
                value: true
              }
            ],
            getBulkActions: getNotIssuedBulkActionsForNotIssuedStatements
          },
          {
            ...commonTabProps,
            // note this provides a shared starting point for the tab but columns are overwritten
            ...commonStatementTabProps,
            name: 'needs-further-review',
            label: 'Needs further review',
            forcedGlobalFilter: [
              {
                field: 'statement_to',
                op: 'eq',
                value: props.statementPeriod.endDate
              },
              {
                field: 'type_id',
                op: 'eq',
                value: props.statementType
              },
              {
                field: 'status_id',
                op: 'eq',
                value: StatementStatus.NeedsFurtherReview
              },
              {
                field: 'eofy_statement_enabled',
                op: 'eq',
                value: true
              }
            ],
            getBulkActions: getNotIssuedBulkActionsForNotIssuedStatements
          }
        ]
      : []),
    {
      ...commonTabProps,
      ...commonStatementTabProps,
      name: 'issued',
      label: 'Issued',
      columns: issuedStatementColumns,
      forcedGlobalFilter: [
        {
          field: 'statement_to',
          op: 'eq',
          value:
            props.statementType === StatementType.PeriodicOwnership
              ? props.statementEndDate
              : props.statementPeriod.endDate
        },
        {
          field: 'type_id',
          op: 'eq',
          value: props.statementType
        },
        {
          field: 'status_id',
          op: 'in',
          value: [StatementStatus.Issued, StatementStatus.Void]
        }
      ],
      getBulkActions: getIssuedBulkActions
    },
    ...(shouldShowStatementPreview
      ? [
          {
            ...commonTabProps,
            ...commonStatementTabProps,
            name: 'excluded',
            label: 'Excluded',
            forcedGlobalFilter: [
              {
                field: 'statement_to',
                op: 'eq',
                value: props.statementPeriod.endDate
              },
              {
                field: 'type_id',
                op: 'eq',
                value: props.statementType
              },
              {
                field: 'status_id',
                op: 'eq',
                value: StatementStatus.Excluded
              },
              {
                field: 'eofy_statement_enabled',
                op: 'eq',
                value: true
              }
            ],
            getBulkActions: getNotIssuedBulkActionsForNotIssuedStatements
          }
        ]
      : [])
  ];

  return <TabbedTable tabs={tabs} />;
}
