import { Generator } from 'data/models/generator';
import { ValueListValue } from 'data/models/types';
import { Invoice } from './invoices';

export type RecurringInvoice = Invoice & {
  next_invoice_date: string | null;
  fixed_end_date: string | null;
  next_due_date: string;
  invoice_frequency_unit: ValueListValue<string>;
  days_in_advance_to_post: number;
  type: ValueListValue<string>;
};

export const config = {
  entities: {
    related: {
      line_items: {
        include:
          'line_items.id,line_items.payable_by_property,line_items.payable_to_property,line_items.description,line_items.amount,line_items.tax_type,line_items.payable_by_chart_of_accounts_account,line_items.payable_to_chart_of_accounts_account'
      },
      reimbursement_for_invoice: {
        include: 'reimbursement_for_invoice'
      },
      reimbursed_by_invoices: {
        include: 'reimbursed_by_invoices'
      },
      maintenance_job: {
        include: 'maintenance_job'
      },
      tax_summary: {
        include: 'tax_summary'
      },
      property: {
        include: 'property'
      },
      payments: {
        include: 'payments.journal_entry_line_item,payments.invoice'
      },
      payable_by_ownership: {
        include: 'payable_by_ownership'
      },
      payable_to_ownership: {
        include: 'payable_to_ownership'
      },
      payable_by_contact: {
        include: 'payable_by_contact'
      },
      payable_to_contact: {
        include: 'payable_to_contact'
      },
      payable_by_tenancy: {
        include: 'payable_by_tenancy'
      },
      payable_to_tenancy: {
        include: 'payable_to_tenancy'
      }
    }
  }
};

export const financialsRecurringInvoicesModel = new Generator<RecurringInvoice>(
  'financials/recurring-invoices',
  config
).createEntityModel();
