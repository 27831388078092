import * as React from 'react';
import { DialogProps } from '@rexlabs/dialog';
import { RecordDialog } from 'view/components/record-screen/dialog/dialog';
import { RecordSubmitHandler } from 'view/components/record-screen/utils';
import { useApiValueList } from 'view/hooks/api/use-api-value-list';
import { upsertChecklistDetailsBlock } from '../blocks/upsert-checklist-template-details-block';
import { upsertChecklistItemsBlock } from '../blocks/upsert-checklist-template-items-block';
import { ChecklistTemplatesForm } from '../types/ChecklistTemplatesForm';
import { TaskType } from '../../common/types/TaskType';
import { useTaskTypesWithCustomAddToRecord } from '../hooks/use-task-types-with-custom-add-to-record';

export interface ChecklistTemplateDialogProps extends DialogProps {
  title: string;
  initialValues: Partial<ChecklistTemplatesForm>;
  onSubmit: RecordSubmitHandler<ChecklistTemplatesForm>;
  isEditMode?: boolean;
  taskTypeId: TaskType;
}

const content = [
  {
    id: 'checklist-template',
    label: 'Add checklist template',
    blocks: [upsertChecklistDetailsBlock, upsertChecklistItemsBlock]
  }
];

export function ChecklistTemplateDialog({
  onClose,
  initialValues,
  onSubmit,
  isEditMode,
  title,
  taskTypeId
}: ChecklistTemplateDialogProps) {
  const taskTypesWithCustomAddToRecord = useTaskTypesWithCustomAddToRecord();
  const { data: taskTypes, isFetched } = useApiValueList('task_type');

  return (
    <RecordDialog
      isLoading={
        taskTypesWithCustomAddToRecord.includes(taskTypeId) && !isFetched
      }
      title={title}
      data={initialValues}
      blockProps={{
        isEditMode,
        taskType: taskTypes?.find(
          (taskTypeValueItem) => taskTypeValueItem.id === taskTypeId
        )
      }}
      content={content}
      onClose={onClose}
      handleSubmit={onSubmit}
    />
  );
}
