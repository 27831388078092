export const REPORTING_ROUTES = {
  REPORTING_LIST: {
    config: {
      path: '/reporting',
      Component: require('./screens/reporting-list').ReportingListScreen
    },

    CREATE_LEDGER_ACTIVITY_SUMMARY: {
      config: {
        path: '/reporting/create-ledger-activity-summary',
        Component: require('./dialogs/create-ledger-activity-summary')
          .CreateLedgerActivitySummary
      }
    },

    CREATE_TRUST_JOURNAL_REPORT: {
      config: {
        path: '/reporting/create-trust-journal-report',
        Component: require('./dialogs/create-trust-journal-report')
          .CreateTrustJournalReport
      }
    },

    CREATE_BOND_REPORT: {
      config: {
        path: '/reporting/create-bond-report',
        Component: require('./dialogs/create-bond-report').CreateBondReport
      }
    }
  },
  TABLE_REPORT: {
    config: {
      path: '/table-reports/:reportId',
      Component: require('./screens/table-report-record').TableReportRecord
    }
  }
};
