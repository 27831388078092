import { useMemo } from 'react';
import getPendingTrustJournalEntryStatusColor from 'src/modules/trust-journal-entries/utils/pending-trust-journal-entry-status-color';
import { getDaysFromNow } from 'utils/dates/dates';

import { formatCurrency } from 'utils/formatters';
import { Stat } from 'view/components/@luna/stat-block/stat-blocks';

export function useBankDepositStats(bankAccount) {
  const stats = bankAccount?.trust_receipts_not_yet_banked?.by_type;
  return useMemo<Stat[] | undefined>(() => {
    if (!stats) {
      return;
    }

    const cashAmount = stats?.cash?.amount ?? 0;
    const cashCount = stats?.cash?.count ?? 0;
    const chequeAmount = stats?.cheque?.amount ?? 0;
    const chequeCount = stats?.cheque?.count ?? 0;

    const cardsAmount = stats?.card?.amount ?? 0;
    const cardsCount = stats?.card?.count ?? 0;

    return [
      {
        label: 'Last deposit',
        value: getDaysFromNow(bankAccount.financial_summary?.last_deposit_at),
        intent: 'secondary'
      },
      {
        label: 'Cash / Cheques',
        value: formatCurrency(cashAmount + chequeAmount),
        valueTag:
          cashCount + chequeCount === 1
            ? `1 deposit`
            : `${cashCount + chequeCount} deposits`,
        intent: getPendingTrustJournalEntryStatusColor(cashCount + chequeCount)
      },
      {
        label: 'Cards',
        value: formatCurrency(cardsAmount),
        valueTag: cardsCount === 1 ? `1 deposit` : `${cardsCount} deposits`,
        intent: getPendingTrustJournalEntryStatusColor(cardsCount)
      }
    ];
  }, [stats]);
}
