import * as React from 'react';
import { ListTable } from 'view/components/table';
import { Card } from 'view/components/card';
import { ListScreen } from 'view/components/list-screen/list-screen';
import { query } from '@rexlabs/model-generator';
import { useTableFilters } from 'view/hooks/use-table-filters';
import { transformActionDeclarationsToActionMenuItems } from 'utils/actions/transforms';

import { useGetCreatePropertyAreaTypeAction } from 'src/modules/property-areas/property-area-types/hooks/action-declarations/use-get-create-property-area-type-action';
import { propertyAreaTypeColumns } from '../utils/property-area-type-columns';
import { useGetPropertyAreaTypeActions } from '../hooks/action-menu-items/use-get-property-area-type-actions';
import { propertyAreaTypesModel } from '../models/property-area-types-model';

const propertyAreaTypeQuery = query`{
  ${propertyAreaTypesModel} {
    id,
    name,
    is_created_on_new_properties,
    system_purpose,
    item_order,
    created_by,
    is_property_details_type,
    summary,
    items
  }
}`;

export function PropertyAreaTypeList() {
  const getPropertyAreaTypeActions = useGetPropertyAreaTypeActions();

  const { getSort, getFilters } = useTableFilters('property-area-types');

  const getActionMenuItems = React.useCallback(
    ({ item }) => {
      const actions = getPropertyAreaTypeActions(item);
      return transformActionDeclarationsToActionMenuItems(actions);
    },
    [getPropertyAreaTypeActions]
  );

  const getCreatePropertyAreaTypeAction = useGetCreatePropertyAreaTypeAction();

  return (
    <ListScreen
      title='Property area types'
      actions={[getCreatePropertyAreaTypeAction()]}
    >
      <Card>
        <ListTable
          columns={propertyAreaTypeColumns}
          getQuery={() => propertyAreaTypeQuery}
          getActionMenuItems={getActionMenuItems}
          getFilters={getFilters}
          getSort={getSort}
          initialPageSize={20}
        />
      </Card>
    </ListScreen>
  );
}
