import { BlockConfig } from 'view/components/record-screen/types';
import React from 'react';
import { Grid } from 'view/components/@luna/form/grid';
import { TextInput } from '@rexlabs/text-input';
import { Field } from '@rexlabs/form';
import { InfoBanner } from 'view/components/@luna/notifications/banner';
import { ServicePackage } from 'src/modules/service-packages/types/service-package';
import { ServicePackageForm } from 'src/modules/service-packages/types/service-package-form';
import { useTranslation } from 'react-i18next';
import InfoCircleIcon from 'view/components/icons/info';
import { ManagementAgencyFeeSelect } from 'view/components/inputs/selects/v4-selects/management-agency-fee-select';

export const servicePackageDetailsBlock: BlockConfig<
  ServicePackage,
  { isCreating?: boolean },
  ServicePackageForm
> = {
  id: 'service-package',
  title: 'Service package',
  validate: {
    definitions: {
      name: { name: 'service package name', rules: 'required' }
    }
  },
  Edit: ({ blockProps }) => {
    const { t } = useTranslation();

    const infoBannerDescription = blockProps?.isCreating
      ? t(
          'property-ownerships.management-agreement.service-package.create-message'
        )
      : t(
          'property-ownerships.management-agreement.service-package.update-message'
        );
    return (
      <Grid columns={1}>
        <Field Input={TextInput} label='Service package name' name='name' />
        <Field
          Input={ManagementAgencyFeeSelect}
          label='Default fee(s)'
          name='agency_fees'
          id={'agency_fee_select'}
          inputProps={{ multi: true }}
        />
        <InfoBanner Icon={InfoCircleIcon} description={infoBannerDescription} />
      </Grid>
    );
  }
};
