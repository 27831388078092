import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { Bold } from '@rexlabs/text';
import { InfoBanner } from '@rexlabs/notifications';
import { Field } from '@rexlabs/form';

import { Select } from '@rexlabs/select';

import { BooleanValue } from 'view/components/values/boolean';
import { DateValue } from 'view/components/values/date';
import { BlockConfig } from 'view/components/record-screen/types';
import { Value } from 'view/components/values';
import { CurrencyValue } from 'view/components/values/currency';
import { Grid } from 'view/components/@luna/form/grid';
import { NumberSelect } from 'view/components/inputs/selects/number-select';
import { DisbursementFrequencySplitInput } from 'view/components/inputs/disbursement-frequency-split-input';
import { DateInput } from 'view/components/@luna/inputs/date-input/date-input';
import InfoCircleIcon from 'view/components/icons/info';
import { BooleanRadios } from 'view/components/inputs/boolean-radios';
import { CentAmountInput } from 'view/components/inputs/cent-amount';

import {
  DisbursementFrequency,
  GenericRecordWithDisbursementsFrequencyPreferences as FrequencyPreferences
} from '../types/frequency';
import { getFrequencyLabel } from '../utils/get-frequency-label';
import { getDisbursementPresetLabel } from '../utils/get-disbursement-preset-label';

const MAX_DAY_NUMBER = 31;

type DisbursementFrequencyCountAndUnit = Pick<
  DisbursementFrequency,
  'on_frequency_count' | 'on_frequency_unit'
> | null;

export type DisbursementFrequencyBlockFormData = {
  disbursement_frequency?: Omit<
    DisbursementFrequency,
    'on_frequency_count' | 'on_frequency_unit'
  > & {
    disbursement_frequency_count_and_unit?: DisbursementFrequencyCountAndUnit;
  };
};

export type DisbursementsFrequencyBlockProps = BlockConfig<
  FrequencyPreferences,
  unknown,
  DisbursementFrequencyBlockFormData
>;

export const useDisbursementsFrequencyBlock: (
  useNextDisbursementDate?: boolean
) => DisbursementsFrequencyBlockProps = (
  includeNextDisbursementDate = true
) => {
  const { t } = useTranslation();
  return {
    id: 'disbursements-frequency',
    title: () => <Trans i18nKey='disbursements.frequency-block.title' />,
    validateOnBlur: false,
    validate: {
      definitions: {
        'disbursement_frequency.is_disbursed_on_balance_amount': {
          rules: 'required'
        },
        'disbursement_frequency.on_balance_amount': {
          name: 'balance amount',
          rules:
            'required_if_true:disbursement_frequency.is_disbursed_on_balance_amount'
        },
        'disbursement_frequency.is_disbursed_on_frequency': {
          rules: 'required'
        },
        ...(includeNextDisbursementDate
          ? {
              'disbursement_frequency.on_frequency_next_disbursement_date': {
                name: t('disbursements.next-date') as string,
                rules:
                  'required_if_true:disbursement_frequency.is_disbursed_on_frequency'
              }
            }
          : {}),
        'disbursement_frequency.disbursement_frequency_count_and_unit': {
          name: t('disbursements.frequency') as string,
          rules: 'required_if:disbursement_frequency.preset.type.id,custom'
        },
        // first day must be less than second day
        'disbursement_frequency.preset.data.first_day': {
          name: 'first day',
          rules: [
            'required_if:disbursement_frequency.preset.type.id,twice_monthly',
            'less_than_field:disbursement_frequency.preset.data.second_day'
          ].join('|')
        },
        // second day must be greater than first day
        'disbursement_frequency.preset.data.second_day': {
          name: 'second day',
          rules: [
            'required_if:disbursement_frequency.preset.type.id,twice_monthly',
            'greater_than_field:disbursement_frequency.preset.data.first_day'
          ].join('|')
        },
        'disbursement_frequency.preset.type': {
          name: t('disbursements.frequency-preset') as string,
          rules:
            'required_if_true:disbursement_frequency.is_disbursed_on_frequency'
        }
      },
      messages: {
        less_than_field: 'First day must be before second day.',
        greater_than_field: 'Second day must be after first day.'
      }
    },
    View: ({ data }) => {
      const { t } = useTranslation();
      const isCustomFrequency =
        data?.disbursement_frequency?.preset?.type?.id === 'custom';
      const customFrequencyValue =
        data?.disbursement_frequency?.on_frequency_count &&
        data?.disbursement_frequency?.on_frequency_unit?.id
          ? getFrequencyLabel(
              data?.disbursement_frequency?.on_frequency_count,
              data?.disbursement_frequency?.on_frequency_unit?.id
            )
          : undefined;

      // if preset is custom, use existing logic, otherwise use preset name
      const frequencyValue = isCustomFrequency
        ? customFrequencyValue
        : getDisbursementPresetLabel(data?.disbursement_frequency);

      return (
        <Grid columns={1}>
          <Bold>{t('disbursements.by-balance.label')}</Bold>
          <Grid columns={3}>
            <BooleanValue
              label={t('disbursements.by-balance.toggle.label')}
              value={
                data?.disbursement_frequency?.is_disbursed_on_balance_amount
              }
            />
            {data?.disbursement_frequency?.is_disbursed_on_balance_amount && (
              <CurrencyValue
                label='When balance is greater than or equal to'
                value={data?.disbursement_frequency?.on_balance_amount}
              />
            )}
          </Grid>
          <Bold>{t('disbursements.by-date.label')}</Bold>
          <Grid columns={3}>
            <BooleanValue
              label={t('disbursements.by-date.toggle.label')}
              value={data?.disbursement_frequency?.is_disbursed_on_frequency}
            />
            {data?.disbursement_frequency?.is_disbursed_on_frequency && (
              <>
                {includeNextDisbursementDate && (
                  <DateValue
                    label={t('disbursements.by-date.date-of-next')}
                    value={
                      data?.disbursement_frequency
                        ?.on_frequency_next_disbursement_date
                    }
                  />
                )}
                <Value
                  label={t('disbursements.by-date.frequency.label', {
                    endSlot: isCustomFrequency ? ' every' : ''
                  })}
                  value={frequencyValue}
                />
              </>
            )}
          </Grid>
        </Grid>
      );
    },
    Edit: ({ values }) => {
      const { t } = useTranslation();

      return (
        <Grid columns={1}>
          <Bold>{t('disbursements.by-balance.label')}</Bold>
          <Field
            label={t('disbursements.by-balance.field.label') as string}
            name='disbursement_frequency.is_disbursed_on_balance_amount'
            Input={BooleanRadios}
          />
          {values?.disbursement_frequency?.is_disbursed_on_balance_amount && (
            <Grid columns={3}>
              <Field
                label='When balance is greater than or equal to'
                name='disbursement_frequency.on_balance_amount'
                Input={CentAmountInput}
                optional={false}
              />
            </Grid>
          )}
          <Bold>{t('disbursements.by-date.label')}</Bold>
          <Field
            label={t('disbursements.by-date.field.label') as string}
            name='disbursement_frequency.is_disbursed_on_frequency'
            Input={BooleanRadios}
          />

          {values?.disbursement_frequency?.is_disbursed_on_frequency && (
            <>
              {includeNextDisbursementDate && (
                <InfoBanner
                  description={t('disbursements.by-date.frequency.banner')}
                  Icon={InfoCircleIcon}
                />
              )}
              <Grid columns={3}>
                {includeNextDisbursementDate && (
                  <Field
                    label={t('disbursements.by-date.date-of-next') as string}
                    name='disbursement_frequency.on_frequency_next_disbursement_date'
                    Input={DateInput}
                    optional={false}
                  />
                )}
                <Field
                  optional={false}
                  label={
                    t('disbursements.by-date.frequency.label', {
                      endSlot: ''
                    }) as string
                  }
                  name='disbursement_frequency.preset.type'
                  id='disbursement_frequency.preset.type'
                  Input={Select}
                  inputProps={{
                    items: [
                      { label: 'Daily', id: 'daily' },
                      { label: 'Weekly', id: 'weekly' },
                      { label: 'Fortnightly', id: 'fortnightly' },
                      { label: 'Monthly', id: 'monthly' },
                      { label: 'Twice monthly', id: 'twice_monthly' },
                      { label: 'Custom', id: 'custom' }
                    ],
                    normaliser: (item) => item
                  }}
                />
                {values?.disbursement_frequency.preset?.type?.id ===
                  'twice_monthly' && (
                  <Grid columns={2}>
                    <Field<typeof NumberSelect>
                      optional={false}
                      label='First day'
                      name='disbursement_frequency.preset.data.first_day'
                      Input={NumberSelect}
                      inputProps={{ maxNumber: MAX_DAY_NUMBER }}
                    />
                    <Field<typeof NumberSelect>
                      optional={false}
                      label='Second day'
                      name='disbursement_frequency.preset.data.second_day'
                      Input={NumberSelect}
                      inputProps={{ maxNumber: MAX_DAY_NUMBER }}
                    />
                  </Grid>
                )}
                {values?.disbursement_frequency.preset?.type?.id ===
                  'custom' && (
                  <Field
                    label={
                      t('disbursements.by-date.frequency.label', {
                        endSlot: ' every'
                      }) as string
                    }
                    name='disbursement_frequency.disbursement_frequency_count_and_unit'
                    Input={DisbursementFrequencySplitInput}
                    inputProps={{ testId: 'disbursement-frequency' }}
                    optional={false}
                  />
                )}
              </Grid>
            </>
          )}
        </Grid>
      );
    }
  };
};
