import React from 'react';
import { BlockConfig } from 'view/components/record-screen/types';
import { AgencyFeesTabbedTable } from 'src/modules/agency-fees/components/agency-fees-tabbed-table';
import { CreateNewAgencyFeeDialog } from 'src/modules/agency-fees/dialogs/create-new-agency-fee';
import { useDialog } from '@rexlabs/dialog';
import { ActionButtons } from 'view/components/action-buttons';

export const agencyFeesTableBlock: BlockConfig = {
  id: 'agency-fees-table',
  title: 'Fee templates',
  View: () => <AgencyFeesTabbedTable />,
  Actions: () => {
    const { open } = useDialog(CreateNewAgencyFeeDialog);

    return (
      <ActionButtons
        actions={[
          {
            label: 'Create fee template',
            onClick: open
          }
        ]}
      />
    );
  }
};
