import { TextInput } from '@rexlabs/text-input';
import { AddressStateSelect } from 'view/components/inputs/selects/v4-selects/address-state-select';
import { AddressAreaType } from '../types/property-types';

export function getInputForAddressArea(area: AddressAreaType) {
  switch (area) {
    case 'state':
      return AddressStateSelect;
    default:
      return TextInput;
  }
}
