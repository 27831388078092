import { Generator, GeneratorConfig } from 'data/models/generator';
import {
  ChangeStatusPayload,
  PropertyTenancy
} from 'src/modules/property-tenancies/types/property-tenancy-types';
import { auditableConfig } from 'src/modules/common/auditable/auditable-config';
import { CustomAction, touchEtag } from '@rexlabs/model-generator';
import { produce } from 'immer';
import { identity } from 'lodash';
import { api } from 'utils/api/api-client';
import { tenancyModel } from 'data/models/entities/tenancies';

const config: GeneratorConfig = {
  entities: {
    related: {
      property: {
        include: 'property'
      },
      tenancy: {
        include: 'tenancy',
        model: tenancyModel
      },
      rent_schedule: {
        include: 'rent_schedule.line_items.payable_to_chart_of_accounts_account'
      },
      rent_position: {
        include: 'rent_position'
      },
      rent_arrears: {
        include: 'rent_arrears'
      },
      summary: {
        include: 'summary'
      },
      security_deposit: {
        include: 'security_deposit'
      },
      ...auditableConfig
    }
  }
};

const actionCreators = {
  changeStatus: {
    request: (
      { id, status }: ChangeStatusPayload,
      _actions,
      _dispatch,
      _getState
    ) =>
      api.put(`/property-tenancies/${id}/status`, {
        status: {
          id: status
        }
      }),
    reduce: {
      initial: identity,
      success: (state: any, action: any) => {
        const propertyTenancyId = action.payload.data.id;
        return produce(state, (newState) => {
          newState.items[propertyTenancyId].data.status =
            action.payload.data.status;
          newState.items[propertyTenancyId] = touchEtag(
            newState.items[propertyTenancyId]
          );
        });
      },
      failure: identity
    }
  } as CustomAction<ChangeStatusPayload, any>,
  fetchRentPosition: {
    request: ({
      id,
      supposedPaymentAmount
    }: {
      id: string;
      supposedPaymentAmount: number;
    }) =>
      api.post(`/property-tenancies/${id}/rent-position`, {
        supposed_payment_amount: supposedPaymentAmount
      }),
    reduce: {
      initial: identity,
      success: identity,
      failure: identity
    }
  }
};

export const propertyTenancyModel = new Generator<
  PropertyTenancy,
  typeof actionCreators
>('property-tenancies', config).createEntityModel({ actionCreators });
