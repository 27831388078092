import { LineItemsColumns } from 'view/components/table';
import {
  Invoice,
  InvoiceLineItem
} from 'data/models/entities/financials/invoices';
import { StyleSheet, useStyles } from '@rexlabs/styling';
import React, { useMemo } from 'react';
import { LineItemsView } from 'view/components/line-items/view';
import { CreditNote } from 'data/models/entities/financials/credit-notes';
import {
  hasPayableByChartOfAccountsAccount,
  hasPayableToChartOfAccountsAccount
} from '../utils/has-chart-of-accounts-account';
import {
  hasPayableByProperties,
  hasPayableToProperties
} from '../utils/has-properties';
import { SummaryPaymentLineEntry, TaxSummary } from './tax-summary';

const defaultStyles = StyleSheet({
  summary: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    marginLeft: 'calc(100% - 50rem)'
  }
});

const lineItemColumns: LineItemsColumns<InvoiceLineItem> = [
  {
    id: 'description',
    label: 'Description',
    width: '30%',
    name: 'description'
  },
  {
    id: 'payable_to_chart_of_accounts_account',
    label: 'Invoice from account code',
    name: 'payable_to_chart_of_accounts_account.name'
  },
  {
    id: 'payable_to_property',
    label: 'Invoice from property',
    name: 'payable_to_property',
    type: 'property'
  },
  {
    id: 'payable_by_chart_of_accounts_account',
    label: 'Bill to account code',
    name: 'payable_by_chart_of_accounts_account.name'
  },
  {
    id: 'payable_by_property',
    label: 'Bill to property',
    name: 'payable_by_property',
    type: 'property'
  },
  {
    id: 'tax_type',
    type: 'record',
    label: 'Tax Type',
    name: 'tax_type',
    width: '15%'
  },
  {
    id: 'amount',
    label: 'Amount',
    type: 'currency',
    name: 'amount',
    align: 'right',
    width: '10%'
  }
];

function getInvoiceLineItemColumns(lineItems: InvoiceLineItem[]) {
  let acutalColumns = lineItemColumns;

  const lineItemsHavePayableByProperties = hasPayableByProperties(lineItems);

  if (!lineItemsHavePayableByProperties) {
    acutalColumns = acutalColumns.filter(
      ({ id }) => id !== 'payable_by_property'
    );
  }

  const lineItemsHavePayableByChartOfAccountsAccounts = hasPayableByChartOfAccountsAccount(
    lineItems
  );

  if (!lineItemsHavePayableByChartOfAccountsAccounts) {
    acutalColumns = acutalColumns.filter(
      ({ id }) => id !== 'payable_by_chart_of_accounts_account'
    );
  }

  const lineItemsHavePayableToProperties = hasPayableToProperties(lineItems);

  if (!lineItemsHavePayableToProperties) {
    acutalColumns = acutalColumns.filter(
      ({ id }) => id !== 'payable_to_property'
    );
  }

  const lineItemsHavePayableToChartOfAccountsAccounts = hasPayableToChartOfAccountsAccount(
    lineItems
  );

  if (!lineItemsHavePayableToChartOfAccountsAccounts) {
    acutalColumns = acutalColumns.filter(
      ({ id }) => id !== 'payable_to_chart_of_accounts_account'
    );
  }

  return acutalColumns;
}

interface InvoiceLineItemsProps {
  data?: Invoice | CreditNote;
}

export function InvoiceLineItems({ data }: InvoiceLineItemsProps) {
  const s = useStyles(defaultStyles);
  const items = data?.line_items ?? [];

  const columns = useMemo(
    () => (data?.line_items ? getInvoiceLineItemColumns(data.line_items) : []),
    [data]
  );

  let summaryPaymentsEntries: SummaryPaymentLineEntry[] = [];

  if (data && 'payments' in data) {
    summaryPaymentsEntries = data.payments?.data ?? [];
  } else if (data && 'applications' in data) {
    // TODO: this path seems to never be invoked. should we remove it?
    summaryPaymentsEntries = data.applications?.data ?? [];
  }

  return (
    <LineItemsView
      items={items}
      columns={columns}
      summary={
        <div {...s('summary')}>
          {data && (
            <TaxSummary
              data={data}
              taxSummary={data?.tax_summary}
              payments={summaryPaymentsEntries}
            />
          )}
        </div>
      }
    />
  );
}
