import React from 'react';

import { BlockConfig } from 'view/components/record-screen/types';
import Box from '@rexlabs/box';
import { Field } from '@rexlabs/form';
import { Grid } from 'view/components/@luna/form/grid';
import { AuthorSelect } from 'view/components/inputs/selects/v4-selects/author-select';
import { InfoBanner } from 'view/components/@luna/notifications/banner';
import InfoCircleIcon from 'view/components/icons/info';
import { pluralize } from 'utils/formatters';
import { useToken } from '@rexlabs/styling';
import { MessageTemplateSelect } from '../../common/components/selects/message-template-select';
import { BulkCreateSmsMessageFormData } from '../types/BulkCreateSmsMessageFormData';
import { BulkCreateEmailMessageFormData } from '../types/BulkCreateEmailMessageFormData';
import { emptyMessageCreatorItem } from '../../common/data/empty-message-creator-item';
import { RecipientGroupSelect } from '../../common/components/selects/recipient-group-select';

const validate = {
  definitions: {
    recipient_group: { name: 'recipient group', rules: 'required' }
  }
};

export const bulkMessageDetailsBlock: BlockConfig<
  BulkCreateEmailMessageFormData | BulkCreateSmsMessageFormData
> = {
  id: 'details',
  title: 'Message Details',
  validate,
  Edit: ({
    forms,
    getHandlers,
    data,
    blockProps: { recordObjects = [], contextType, messageType }
  }) => {
    const handlers = getHandlers?.(forms);
    const token = useToken();

    return (
      <Box sy='2rem'>
        <Grid columns={2}>
          <Box
            sy='2rem'
            gap={token('spacing.l')}
            display='flex'
            flexDirection='column'
          >
            <Field
              id='template'
              name='template'
              label='Template'
              Input={MessageTemplateSelect}
              onChange={handlers?.handleTemplateChange}
              inputProps={{
                contextType: data?.context_type,
                channelType: messageType
              }}
            />
            <Field
              id='recipient_group'
              name='recipient_group'
              label='Recipient group'
              Input={RecipientGroupSelect}
              inputProps={{
                contextType: data?.context_type
              }}
            />
          </Box>

          <Box
            sy='2rem'
            gap={token('spacing.l')}
            display='flex'
            flexDirection='column'
          >
            <Field
              id='sent_from'
              name='sent_from'
              label='Sent from'
              Input={AuthorSelect}
              inputProps={{ staticItems: [emptyMessageCreatorItem] }}
            />
          </Box>
        </Grid>
        <Box paddingTop='2rem'>
          <InfoBanner
            Icon={InfoCircleIcon}
            description={
              <>
                You will send this message to{' '}
                <b>{pluralize(contextType, recordObjects.length, true)}</b>
              </>
            }
          />
        </Box>
      </Box>
    );
  }
};
