import React from 'react';
import { BlockConfig } from 'view/components/record-screen/types';
import { Field } from '@rexlabs/form';
import { Grid } from 'view/components/@luna/form/grid';
import { PreviewFileUploadInputWithErrorHandling } from 'view/components/inputs/file-upload-input/preview-file-upload-input-with-error-handling';

export const imagesBlock: BlockConfig = {
  id: 'images',
  title: 'Images',
  Edit: () => {
    return (
      <Grid columns={1}>
        <Field
          id='images'
          name='images'
          Input={PreviewFileUploadInputWithErrorHandling}
          optional={false}
          inputProps={{
            multi: true,
            accept: '.jpeg, .png, .jpg'
          }}
        />
      </Grid>
    );
  }
};
