import { Checkbox } from '@rexlabs/checkbox';
import { Field } from '@rexlabs/form';
import React from 'react';

import { Column, Grid } from '@rexlabs/grid';

import { BlockConfig } from 'view/components/record-screen/types';

import { User } from 'src/modules/users/types/user-types';

export type InviteUserBlockForm = {
  invite?: boolean;
};
export const inviteUserBlock: BlockConfig<User, any, InviteUserBlockForm> = {
  id: 'invitation-email',
  title: 'Invitation email',
  Edit: () => (
    <Grid>
      <Column width={6}>
        <Field
          label=''
          name='invite'
          Input={Checkbox}
          inputProps={{
            label: 'Send invitation email to user'
          }}
          optional={false}
        />
      </Column>
    </Grid>
  )
};
