/**
 * TODO: move this into vivid
 */

import React, { useEffect, useState } from 'react';
import Box from '@rexlabs/box';
import { StyleSheet, useStyles, border } from '@rexlabs/styling';
import { PrimaryIconButton } from '@rexlabs/button';
import { downloadFile } from 'utils/files';
import DownloadIcon from 'view/components/icons/download';

const defaultStyles = StyleSheet({
  container: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'hidden',
    transition: 'background-color .35s ease-out',
    isolation: 'isolate',

    ...border.styles({
      target: 'fileUpload.preview.image',
      all: {
        radius: 'm'
      }
    }),

    height: ({ token }) => token('fileUpload.preview.height', '10.4rem'),
    width: ({ token }) => token('fileUpload.preview.height', '10.4rem'),
    backgroundColor: ({ token }) => token('color.neutral.idle.contrast'),

    '&:hover > button': {
      opacity: 1
    }
  },

  image: {
    position: 'absolute',
    zIndex: 2,
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    objectFit: 'cover',
    width: '100%',
    height: '100%'
  },

  downloadButton: {
    position: 'absolute',
    zIndex: 3,
    top: 4,
    right: 4,
    opacity: 0,
    transition: '0.2s ease-in'
  }
});

interface ImagePreviewProps {
  src: string | Promise<string>;
  downloadUrl?: string;
}

export function ImagePreview({ src, downloadUrl }: ImagePreviewProps) {
  const s = useStyles(defaultStyles);

  const [preview, setPreview] = useState(
    typeof src === 'string' ? src : undefined
  );

  useEffect(() => {
    if (typeof src === 'string') {
      setPreview(src);
    } else if (src?.then) {
      src.then(setPreview);
    }
  }, [src]);

  return (
    <Box {...s('container')}>
      {downloadUrl ? (
        <PrimaryIconButton
          {...s('downloadButton')}
          Icon={DownloadIcon}
          aria-label='Download attachment'
          onClick={() => {
            downloadFile(downloadUrl);
          }}
        />
      ) : null}

      {!!preview && <img src={preview} {...s('image')} />}
    </Box>
  );
}
