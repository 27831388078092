import React from 'react';

import { Field } from '@rexlabs/form';

import { BlockConfig } from 'view/components/record-screen/types';
import { Grid } from 'view/components/@luna/form/grid';
import { PreviewFileUploadInputWithErrorHandling } from 'view/components/inputs/file-upload-input/preview-file-upload-input-with-error-handling';

import { Appliance } from '../types/Appliance';

export const applianceManualsBlock: BlockConfig<Appliance> = {
  id: 'appliance-manuals',
  title: 'Manuals',
  Edit: () => {
    return (
      <Grid columns={1}>
        <Field<typeof PreviewFileUploadInputWithErrorHandling>
          name='manuals'
          id='manuals'
          Input={PreviewFileUploadInputWithErrorHandling}
          optional={false}
          inputProps={{
            multi: true,
            accept: 'application/pdf,.jpeg, .png, .jpg'
          }}
        />
      </Grid>
    );
  }
};
