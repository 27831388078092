import { parseUploadedFileMeta } from 'utils/files';
import { Appliance } from '../types/Appliance';
import { EditApplianceFormData } from './types/edit-appliance-form-data';

/**
 * Converts an appliance to an edit appliance form data object.
 */
export function mapApplianceToEditFormData(
  appliance: Appliance
): EditApplianceFormData {
  const manuals = parseUploadedFileMeta(appliance?.manuals?.data, false);
  const warranties = parseUploadedFileMeta(appliance?.warranties?.data, false);

  return {
    id: appliance.id,
    type: appliance.type,
    property: appliance.property ?? null,
    make: appliance.make ?? null,
    model: appliance.model ?? null,
    warranty_expires_date: appliance.warranty_expires_date ?? null,
    installed_date: appliance.installed_date ?? null,
    location: appliance.location ?? null,
    serial_number: appliance.serial_number ?? null,
    manuals: manuals,
    warranties: warranties
  };
}
