import { User } from 'src/modules/users/types/user-types';

import { api } from 'utils/api/api-client';

const relatedFields = ['contact'];

export async function getUserRelatedItems(userSearchItem: User) {
  const userUrl = `/users/${userSearchItem.id}?include=${relatedFields.join(
    ','
  )}`;

  const completeTenancy = !userSearchItem?.contact
    ? await api.get<User>(userUrl).then(({ data }) => data)
    : userSearchItem;

  const contact = completeTenancy?.contact;

  return {
    contact
  };
}
