import { TextInput, TextInputProps } from '@rexlabs/text-input';
import InfoCircleIcon from 'view/components/icons/info';
import { Grid } from 'view/components/@luna/form/grid';
import { Field, HiddenField } from '@rexlabs/form';
import React, { useCallback } from 'react';
import { InfoBanner } from 'view/components/@luna/notifications/banner';
import { TenancyPrepaymentBucket } from 'src/modules/prepayments/types/tenancy-prepayment-bucket';

interface CrossBucketReasonBannerFieldProps
  extends Omit<TextInputProps, 'value'> {
  fieldPrefix: string;
  prepaymentBucket?: TenancyPrepaymentBucket;
  value?: {
    reason?: string;
    needs_reason: boolean;
  };
}

// - note we need to upgrade our typescript to 5.4 to have the correct types for this :/
// @ts-ignore
const formatter = new Intl.ListFormat('en', {
  style: 'long',
  type: 'conjunction'
});

// a banner with a reason form field inside
// see frontend/src/modules/financials/utils/validate-needs-reason.tsx
export function CrossBucketReasonBannerField({
  fieldPrefix,
  prepaymentBucket,
  onChange,
  ...props
}: CrossBucketReasonBannerFieldProps) {
  const onTextInputChange = useCallback(
    (e) => {
      const reason = e.target.value;
      onChange?.({ target: { ...(props.value || {}), reason } });
    },
    [onChange]
  );

  const chartOfAccountsText =
    prepaymentBucket?.system_purpose?.id === 'rent_prepayment'
      ? 'rent'
      : formatter.format(
          prepaymentBucket?.chart_of_accounts_accounts?.data.map(
            (chartOfAccount) => chartOfAccount.name
          )
        );

  return (
    <InfoBanner
      Icon={InfoCircleIcon}
      description={
        <Grid columns={1}>
          <>
            This bill includes one or more Chart of Accounts which is not
            authorised for payment from the selected prepayment bucket. The
            selected bucket supports payment for {chartOfAccountsText} only. To
            complete the transaction, please include a reason below.
          </>
          <HiddenField name={`${fieldPrefix}.needs_reason`} />
          <Field
            label={'Reason for paying using this bucket'}
            Input={TextInput}
            name={`${fieldPrefix}.reason`}
            optional={false}
            onChange={onTextInputChange}
          />
        </Grid>
      }
    />
  );
}
