import { PropertyAreaType } from 'src/modules/property-areas/property-area-types/types/PropertyAreaType';
import { PropertyAreaTypeForm } from 'src/modules/property-areas/property-area-types/types/PropertyAreaTypeForm';

export function mapAreaTypeDataToForm(
  data: PropertyAreaType
): PropertyAreaTypeForm {
  return {
    ...data,
    items: data.items?.data?.map((item) => ({ name: item.name, id: item.id }))
  };
}
