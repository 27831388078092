import React from 'react';

import { RecordScreen } from 'view/components/record-screen';
import { TitleBlock } from 'view/components/@luna/title-block';
import { supplierCommissionSettingsBlock } from '../blocks/supplier-commission-settings';
import { supplierCommissionTemplatesBlock } from '../blocks/supplier-commission-templates';

const content = [
  {
    label: 'General',
    items: [
      {
        id: 'settings',
        label: 'Settings',
        blocks: [supplierCommissionSettingsBlock]
      },
      {
        id: 'templates',
        label: 'Templates',
        blocks: [supplierCommissionTemplatesBlock]
      }
    ]
  }
];

export function SupplierCommissionSettings() {
  const handleSubmit = React.useCallback(() => {
    //
  }, []);

  return (
    <RecordScreen
      titleBlock={<TitleBlock title='Supplier commission' />}
      content={content}
      handleSubmit={handleSubmit}
      data={{}}
    />
  );
}
