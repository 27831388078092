import { camelCase } from 'lodash';
import { InspectionRunFinishData } from '../blocks/inspection-run-finish-block';
import { InspectionRun } from '../types/InspectionRun';

export function mapInspectionRunToEditInspectionRunFinishForm(
  inspectionRun: InspectionRun
): InspectionRunFinishData {
  return {
    finish_address: {
      id: camelCase(inspectionRun!.finish_address)!,
      label: inspectionRun!.finish_address!,
      data: {
        lngLat: [
          parseFloat(inspectionRun!.finish_longitude),
          parseFloat(inspectionRun!.finish_latitude)
        ]
      }
    }
  };
}
