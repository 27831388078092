import { useTranslation } from 'react-i18next';
import { useDisbursementInvoiceWithdrawalsBlock } from '../blocks/disbursement-invoice-withdrawals';
import { disbursementDetailsBlock } from '../blocks/disbursement-details-block';
import { useDisbursementBillAndFeeTransfersBlock } from '../blocks/use-disbursement-bill-and-fee-transfers-block';
import { trustTransfersBlock } from '../blocks/trust-transfers';
import { useDisbursementGeneralPayoutsBlock } from '../blocks/use-disbursement-general-payouts-block';
import { completedDisbursementSummaryBlock } from '../blocks/completed-disbursement-summary-block';

export const useCompletedDisbursementContent = (hasDisbursementSnapShot) => {
  const { t } = useTranslation();

  const generalPayouts = useDisbursementGeneralPayoutsBlock();
  const billsAndFeeTransfers = useDisbursementBillAndFeeTransfersBlock();
  const invoiceWithdrawalsBlock = useDisbursementInvoiceWithdrawalsBlock();

  return [
    {
      label: 'General',
      items: [
        {
          id: 'overview',
          label: 'Overview',
          blocks: [
            ...(hasDisbursementSnapShot
              ? [completedDisbursementSummaryBlock]
              : []),
            disbursementDetailsBlock
          ]
        },
        {
          id: 'trust-journal-entries',
          label: t('trust-journal-entries.label.plural'),
          blocks: [trustTransfersBlock]
        }
      ]
    },
    {
      label: 'Transfers & Withdrawals',
      items: [
        {
          id: 'bill-and-fee transfers',
          label: 'Bill & fee transfers',
          blocks: [billsAndFeeTransfers]
        },
        {
          id: 'invoice-withdrawals',
          label: 'Invoice withdrawals',
          blocks: [invoiceWithdrawalsBlock]
        },
        {
          id: 'general-payouts',
          label: 'General payouts',
          blocks: [generalPayouts]
        }
      ]
    }
  ];
};
