import React from 'react';
import { BlockConfig } from 'view/components/record-screen/types';
import { Column, Grid } from '@rexlabs/grid';
import { BooleanValue } from 'view/components/values/boolean';
import { BankAccount } from 'data/models/entities/financials/bank-accounts';
import { Field } from '@rexlabs/form';
import { BooleanRadios } from 'view/components/inputs/boolean-radios';
import { Value } from 'view/components/values';
import { TextInput } from '@rexlabs/text-input';

export const bacsSettingsBlock: BlockConfig<BankAccount> = {
  id: 'bankAccountBacsSettings',
  title: 'BACS upload details',
  View: ({ data }) => (
    <Grid>
      <Column width={4}>
        <BooleanValue label='BACS payments' value={data?.bacs?.is_enabled} />
      </Column>
      <Column width={4}>
        <Value
          label='Service User Number (SUN)'
          value={data?.bacs?.service_user_number}
        />
      </Column>
      <Column width={4}>
        <Value label='Customer Name' value={data?.bacs?.customer_name} />
      </Column>
    </Grid>
  ),
  Edit: () => {
    return (
      <Grid>
        <Column width={4}>
          <Field
            name='bacs.is_enabled'
            label='BACS payments'
            Input={BooleanRadios}
            optional={false}
          />
        </Column>
        <Column width={4}>
          <Field
            name='bacs.service_user_number'
            label='Service User Number (SUN)'
            Input={TextInput}
            optional={false}
          />
        </Column>
        <Column width={4}>
          <Field
            name='bacs.customer_name'
            label='Customer Name'
            Input={TextInput}
            optional={true}
          />
        </Column>
      </Grid>
    );
  }
};
