import React, { useMemo } from 'react';
import dayjs from 'dayjs';

import { Field } from '@rexlabs/form';

import { AddressItem } from 'src/lib/mapbox/types/AddressItem';

import { Grid } from 'view/components/@luna/form/grid';
import {
  DateInput,
  disablePastDates
} from 'view/components/@luna/inputs/date-input/date-input';
import { TimeInput } from 'view/components/@luna/inputs/time-input';
import { BlockViewProps } from 'view/components/record-screen/types';
import { AddressSelect } from 'view/components/inputs/selects/v4-selects/address-select';
import { useQuery } from 'react-query';
import { api } from 'utils/api/api-client';
import { fetchAddressFromString } from 'src/lib/mapbox/utils/fetch-address-from-string';

export interface InspectionRunStartFormValues {
  inspection_date?: string;
  leave_from_start_time?: string;
  start_address?: AddressItem;
}

export const inspectionRunStartFormValidationConfig = {
  inspection_date: { rules: 'required' },
  leave_from_start_time: { rules: 'required' },
  start_address: { rules: 'required' }
};

export function InspectionRunStartForm({
  values
}: BlockViewProps<any, InspectionRunStartFormValues, any, any>) {
  const helperText = useMemo(() => {
    return values?.inspection_date
      ? `The earliest inspection task due date is ${dayjs(
          values.inspection_date
        ).format('DD MMM YYYY')}`
      : undefined;
  }, [values?.inspection_date]);

  const { data, isFetched } = useQuery(
    ['agency-contact'],
    async () => {
      try {
        const agencyResponse = await api.get(
          `/contacts?include=addresses&q=is_agency.eq(true)`
        );

        const addresses = agencyResponse.data[0].addresses.map(
          (item) => item.address
        );

        const suggestions = await Promise.all(
          addresses.map((address) => fetchAddressFromString(address))
        );

        return suggestions.flat();
      } catch (err) {
        return [];
      }
    },
    {
      refetchOnWindowFocus: false,
      staleTime: Infinity
    }
  );

  return (
    <Grid columns={1}>
      <Grid columns={2}>
        <Field<typeof DateInput>
          Input={DateInput}
          name='inspection_date'
          id='inspection-date'
          label='Inspection date'
          description={helperText}
          optional={false}
          inputProps={{
            isOutsideRange: disablePastDates
          }}
        />
        <Field
          Input={TimeInput}
          name='leave_from_start_time'
          id='leave-from-start-time'
          label='Leave from start time'
          optional={false}
        />
      </Grid>
      <Grid columns={1}>
        <Field
          Input={AddressSelect}
          name='start_address'
          id='start-address'
          label='Start address'
          optional={false}
          inputProps={{
            staticItems: isFetched ? data : []
          }}
        />
      </Grid>
    </Grid>
  );
}
