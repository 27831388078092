import React, { useCallback, useEffect, useRef } from 'react';
import { HotkeyedActionDeclaration } from 'src/modules/common/actions/types/action-declaration-types';
import Box from '@rexlabs/box';
import { StyleSheet, useStyles, useToken } from '@rexlabs/styling';
import { useToast } from 'view/components/@luna/notifications/toast';
import CheckCircleIcon from 'view/components/icons/check-circle';
import {
  PagedReviewer,
  PagedReviewerProps
} from 'view/components/paged-reviewer/paged-reviewer';
import LoadingSpinner from '@rexlabs/loading-spinner';

/**
 * A component that wraps the PagedReviewer component and provides a way to load records in batches.
 * Useful for datasets that need to be loaded/generated incrementally (e.g. PDFs).
 *
 * It also supports the concepts of "view modes" and separate loading state for each individual view mode.
 *
 */
export interface RecordLoadingPagedReviewerProps<
  ViewModes extends { id: string; label: string }[] = any
> extends Omit<
    PagedReviewerProps,
    | 'renderRecord'
    | 'onNextClick'
    | 'onPreviousClick'
    | 'onAction'
    | 'recordIndex'
    | 'recordCount'
  > {
  /**
   * Whether to show a toast when the user navigates to a new record
   */
  showToast?: boolean;
  /**
   * The records to display in the reviewer
   */
  records: RecordLoadingPagedReviewerRecord<ViewModes>[];
  /**
   * The ID of the current record
   */
  currentRecordId: string;
  /**
   * A callback that is called when the user goes forwards or back
   */
  onRecordChange: (record: RecordLoadingPagedReviewerRecord<ViewModes>) => void;
  /**
   * Array of possible 'view modes' for the reviewer
   * Loading state is stored for each view mode independently
   */
  viewModes?: ViewModes;
  /**
   * ID of the current view mode. You might set this from e.g.
   * a button in your renderHeader function.
   */
  currentViewMode: ViewModes[number]['id'];
  /**
   * Called when the user actions the final record in the set
   */
  onFinish?: () => void;
  /**
   * Called when the component needs to load more records
   * @param records Records that have been requested to be loaded
   * @param viewMode Which view mode to load the records into
   */
  loadMoreRecords: (
    records: RecordLoadingPagedReviewerRecord<ViewModes>[],
    viewMode?: ViewModes[number]['id']
  ) => Promise<void>;
  /**
   * Max amount of records to load at once
   */
  autoLoadAmount?: number;
  renderHeader?: () => React.ReactNode;
  renderRecord: (
    record: RecordLoadingPagedReviewerRecord<ViewModes>
  ) => React.ReactNode;
  renderFooter?: () => React.ReactNode;
  renderBanner?: () => React.ReactNode;
}

export type RecordLoadingPagedReviewerRecord<
  ViewModes extends { id: string; label: string }[],
  Data = any
> = {
  id: string;
  views: {
    [k in ViewModes[number]['id']]?: {
      status?: 'loaded' | 'loading';
      data?: Data;
    };
  };
};

const defaultStyles = StyleSheet({
  container: {
    width: '100%',
    flex: 1,
    borderTopLeftRadius: ({ token }) => token('border.radius.l'),
    borderTopRightRadius: ({ token }) => token('border.radius.l'),
    display: 'flex',
    flexDirection: 'column',
    borderColor: ({ token }) => token('palette.grey.300'),
    borderStyle: 'solid',
    borderWidth: ({ token }) => token('border.width.thin'),
    marginBottom: ({ token }) => '-' + token('spacing.xl'),
    borderBottomWidth: 0
  },
  recordRenderer: {
    position: 'relative',
    display: 'flex',
    flex: 1
  },
  loading: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: ({ token }) => token('palette.grey.200')
  }
});

export function RecordLoadingPagedReviewer({
  actions,
  onRecordChange,
  viewModes,
  currentViewMode,
  currentRecordId,
  records,
  renderRecord,
  renderHeader,
  renderFooter,
  renderBanner,
  autoLoadAmount = 3,
  showToast = true,
  onFinish,
  loadMoreRecords
}: RecordLoadingPagedReviewerProps) {
  const token = useToken();
  const s = useStyles(defaultStyles);
  const { addToast, clearToasts } = useToast();

  const currentIndex = records.findIndex(
    (record) => record.id === currentRecordId
  );
  const initialLoadDone = useRef(false);
  const currentRecord = records[currentIndex];
  const prevRecord:
    | RecordLoadingPagedReviewerRecord<typeof viewModes>
    | undefined = records[currentIndex - 1];
  const nextRecord:
    | RecordLoadingPagedReviewerRecord<typeof viewModes>
    | undefined = records[currentIndex + 1];

  const getRecordsToLoad = useCallback(
    ({
      viewMode,
      currentIndex
    }: {
      viewMode: typeof currentViewMode;
      currentIndex: number;
    }): RecordLoadingPagedReviewerRecord<any>[] => {
      if (!initialLoadDone.current && records.length > 0) {
        return records.slice(0, autoLoadAmount);
      }

      return records
        .slice(currentIndex, currentIndex + autoLoadAmount)
        .filter((r) => r.views[viewMode]?.status !== 'loaded');
    },
    [autoLoadAmount, records]
  );

  const maybeLoadMoreRecords = useCallback(
    async ({
      viewMode,
      currentIndex
    }: {
      viewMode: typeof currentViewMode;
      currentIndex: number;
    }) => {
      const recordsToLoad = getRecordsToLoad({ viewMode, currentIndex });
      if (recordsToLoad.length === 0) return;
      // TODO: Add some idiomatic Rex PM error handling here
      await loadMoreRecords(recordsToLoad, viewMode);
    },
    [getRecordsToLoad, loadMoreRecords]
  );

  useEffect(() => {
    maybeLoadMoreRecords({
      viewMode: currentViewMode,
      currentIndex: currentIndex
    });
  }, [currentViewMode]);

  const handlePrevious = useCallback(() => {
    if (currentIndex > 0) {
      onRecordChange(prevRecord);
    }
    maybeLoadMoreRecords({
      viewMode: currentViewMode,
      currentIndex: currentIndex - 1
    });
  }, [
    currentIndex,
    currentViewMode,
    maybeLoadMoreRecords,
    onRecordChange,
    prevRecord?.id
  ]);

  const handleNext = useCallback(() => {
    if (!nextRecord) return;
    onRecordChange(nextRecord);
    maybeLoadMoreRecords({
      viewMode: currentViewMode,
      currentIndex: currentIndex + 1
    });
  }, [
    currentIndex,
    currentViewMode,
    maybeLoadMoreRecords,
    nextRecord,
    onRecordChange
  ]);

  const onAction = useCallback(
    (action: HotkeyedActionDeclaration) => {
      if (nextRecord) {
        handleNext();
      } else {
        onFinish?.();
      }
      if (showToast) {
        clearToasts();
        addToast({
          title: action.label,
          description: 'Action completed',
          type: 'success',
          clearable: false,
          duration: 1250,
          Icon: CheckCircleIcon
        });
      }
    },
    [nextRecord, showToast, handleNext, onFinish, clearToasts, addToast]
  );

  useEffect(() => {
    if (!initialLoadDone.current) {
      initialLoadDone.current = true;
      loadMoreRecords(
        getRecordsToLoad({ viewMode: currentViewMode, currentIndex }),
        currentViewMode
      );
    }
  }, [getRecordsToLoad, loadMoreRecords, currentViewMode, currentIndex]);

  function isRecordLoaded(recordId: string) {
    return (
      records?.find((r) => r.id === recordId)?.views[currentViewMode]
        ?.status === 'loaded'
    );
  }

  return (
    <PagedReviewer
      actions={actions}
      onNextClick={handleNext}
      onPreviousClick={handlePrevious}
      onAction={onAction}
      recordIndex={currentIndex}
      recordCount={records.length}
      renderHeader={renderHeader}
      renderFooter={renderFooter}
      renderBanner={renderBanner}
      renderRecord={() => {
        return !isRecordLoaded(currentRecordId) ? (
          <>
            {prevRecord ? renderRecord(prevRecord) : null}
            <Box {...s('loading')} data-testid={'loading-spinner'}>
              <LoadingSpinner
                size={32}
                strokeWidth={4}
                colors={[token('palette.grey.600')]}
              />
            </Box>
          </>
        ) : currentRecord ? (
          renderRecord(currentRecord)
        ) : null;
      }}
    />
  );
}
