import { StyleSheet, useStyles } from '@rexlabs/styling';
import { Body, Bold } from '@rexlabs/text';
import { InfoBanner } from 'view/components/@luna/notifications/banner';
import InfoCircleIcon from 'view/components/icons/info';
import React from 'react';
import { formatCurrency } from 'utils/formatters';

const styles = StyleSheet({
  list: {
    listStyleType: 'disc',
    paddingLeft: 12,
    marginLeft: 12
  }
});

export function TieredTemplateBanner() {
  const s = useStyles(styles);

  return (
    <InfoBanner
      Icon={InfoCircleIcon}
      description={
        <>
          <Body as='div'>
            <Bold>Example: </Bold>
            <ul {...s('list')}>
              <li>
                Invoices from {formatCurrency(0, 0)} to{' '}
                {formatCurrency(1000_00, 0)} will have 20% commission applied.
              </li>
              <li>
                Invoices from {formatCurrency(1000_01)} to{' '}
                {formatCurrency(2000_00, 0)} will have 15% commission applied.
              </li>
            </ul>
          </Body>
          <Body>
            <Bold>If the invoice total is: </Bold>
            <ul {...s('list')}>
              <li>
                {formatCurrency(1000_00, 0)}: The entire amount is within the
                first tier a commission of {formatCurrency(200_00, 0)} is
                applied.
              </li>
              <li>
                {formatCurrency(2000_00, 0)}: The entire amount falls into the
                second tier so a commission of {formatCurrency(300_00, 0)} is
                applied.
              </li>
            </ul>
          </Body>
        </>
      }
    />
  );
}
