import { query, useEntityQuery } from '@rexlabs/model-generator';
import { Body, Bold } from '@rexlabs/text';
import { StyleSheet, useStyles, keyframes } from '@rexlabs/styling';
import {
  BankAccount,
  bankAccountModel
} from 'data/models/entities/financials/bank-accounts';
import * as React from 'react';
import { formatDate } from 'utils/dates/format';
import {
  ErrorBanner,
  WarningBanner,
  BannerProps
} from '@rexlabs/notifications';
import { useGetBannerActions } from '../utils/reconciliation-actions/use-get-banner-actions';

const PERIOD_DATE_FORMAT = 'MMM YYYY';

enum ReconciliationDueStatus {
  Due,
  Overdue,
  UpToDate
}

interface DueData {
  start_date: string;
  end_date: string;
}

const getReconciliationDueStatus = (
  data: DueData[]
): ReconciliationDueStatus => {
  switch (data.length) {
    case 0:
      return ReconciliationDueStatus.UpToDate;
    case 1:
      return ReconciliationDueStatus.Due;
    default:
      return ReconciliationDueStatus.Overdue;
  }
};

const getBankAccountQuery = (bankAccountId: string) => query`{
    ${bankAccountModel} (id: ${bankAccountId}) {
        end_of_month_periods_due
        active_reconciliation {
          reconciliation_summary
        }
    }
  }`;

const fadeIn = keyframes({
  '0%': {
    maxHeight: 0,
    opacity: 0
  },

  '100%': {
    maxHeight: 333,
    opacity: 1
  }
});

const styles = StyleSheet({
  container: {
    display: 'block',
    animation: `${fadeIn} 400ms cubic-bezier(0.46, 0.03, 0.52, 0.96)`
  }
});

export interface ReconciliationBannerProps {
  bankAccount: BankAccount;
}

export function ReconciliationBanner({
  bankAccount
}: ReconciliationBannerProps) {
  const s = useStyles(styles);

  const query = getBankAccountQuery(bankAccount.id);
  const { data: bankAccountData } = useEntityQuery(query);

  const getBannerActions = useGetBannerActions();

  if (!bankAccountData?.end_of_month_periods_due?.data) {
    return null;
  }

  const status = getReconciliationDueStatus(
    bankAccountData!.end_of_month_periods_due!.data
  );

  const earliestPeriod = bankAccountData.end_of_month_periods_due.data[0];
  const activeReconciliation = bankAccountData.active_reconciliation;

  const actions: BannerProps['actions'] = getBannerActions(
    activeReconciliation,
    bankAccountData
  );

  if (status === ReconciliationDueStatus.Due) {
    return (
      <div {...s('container')}>
        <WarningBanner
          title='Monthly reconciliation due'
          description={
            <Body>
              Your bank account requires that you complete your reconciliation
              for the period{' '}
              <Bold>
                {formatDate(earliestPeriod.start_date)} to{' '}
                {formatDate(earliestPeriod.end_date)}
              </Bold>
            </Body>
          }
          actions={actions}
        />
      </div>
    );
  }

  if (status === ReconciliationDueStatus.Overdue) {
    const periods = bankAccountData.end_of_month_periods_due.data;

    // TODO: we might want to truncate this if there are a lot of overdue periods, i.e. "Jan 2021, Feb 2021 & 4 more periods"
    const periodString = periods
      .map((period) => formatDate(period.start_date, PERIOD_DATE_FORMAT))
      .join(', ')
      .replace(/,(?=[^,]*$)/, ' &'); // replace final comma with ampersand

    return (
      <div {...s('container')}>
        <ErrorBanner
          title='Monthly reconciliation(s) overdue'
          description={
            <Body>
              You need to complete your monthly reconciliation for the period(s)
              <Bold> {periodString}</Bold>. Please complete the required
              reconciliation(s) as soon as possible.
            </Body>
          }
          actions={actions}
        />
      </div>
    );
  }

  return null;
}
