import React from 'react';

import Box from '@rexlabs/box';
import { useToken } from '@rexlabs/styling';
import { Body } from '@rexlabs/text';
import Tooltip from '@rexlabs/tooltip';

import InfoCircleIcon from 'view/components/icons/info';
import { useTranslation } from 'react-i18next';

export function DisburseBasedOnOwnerRadioLabel() {
  const token = useToken();
  const { t } = useTranslation();

  return (
    <Box
      flexDirection='row'
      alignItems='center'
      columnGap={token('spacing.xs')}
    >
      <Body as='span'>{`${t(
        'disbursements.disburse'
      )} based on owner income attribution`}</Body>
      <Tooltip Content={() => 'Set under “Overview” on this ownership'}>
        <Box flexDirection='row' alignItems='center' width={20} height={20}>
          <InfoCircleIcon size={'s'} />
        </Box>
      </Tooltip>
    </Box>
  );
}
