import React from 'react';
import { ContactFlag } from 'view/components/record-flags/contact-flag';
import { User } from 'src/modules/users/types/user-types';
import { EmptyPortfolioManager } from '../empty/empty-portfolio-manager';
import { EntryList } from '../components/entry-list';
import { EntryLink } from '../components/entry-link';

export interface PortfolioManagersSectionProps {
  portfolioManagers: User[] | 'empty';
}

export function PortfolioManagersSection({
  portfolioManagers
}: PortfolioManagersSectionProps) {
  if (portfolioManagers === 'empty') {
    return <EmptyPortfolioManager />;
  }

  return (
    <EntryList>
      {portfolioManagers.map((portfolioManager) => (
        <EntryLink
          key={portfolioManager.id}
          type='user'
          id={portfolioManager.id}
        >
          <ContactFlag
            contact={portfolioManager.contact!}
            description='Portfolio Manager'
          />
        </EntryLink>
      ))}
    </EntryList>
  );
}
