import React, { useCallback } from 'react';
import { useModelActions } from '@rexlabs/model-generator';

import { RecordSubmitHandler } from 'view/components/record-screen/utils';

import { RecordDialog } from 'view/components/record-screen/dialog/dialog';
import { complianceEntriesModel } from 'src/modules/compliance/common/models/compliance-entries';
import { ComplianceType } from 'src/modules/compliance/common/models/compliance-types';
import { renewCheckDetailsBlock } from '../blocks/renew-check-details';

const content = [
  {
    id: 'renew-check',
    label: 'Renew check',
    blocks: [renewCheckDetailsBlock]
  }
];

export function getRenewDialogLabel(complianceType?: ComplianceType) {
  if (!complianceType) {
    return 'Add check';
  }

  const entryType = complianceType?.category?.id;
  const entryTypeLabel =
    entryType === 'insurance' ? 'insurance details' : entryType?.toLowerCase();

  return `Add ${entryTypeLabel}`;
}

export function RenewCheckDialog({
  onSave,
  onClose,
  complianceType,
  propertyId
}: {
  onClose?: any;
  onSave: any;
  complianceType?: ComplianceType;
  propertyId?: string;
}) {
  const { createItem, refreshLists } = useModelActions(complianceEntriesModel);

  const handleSubmit: RecordSubmitHandler = useCallback(async ({ values }) => {
    const uploadedFileRequest = await values.file.data;
    const uploadedFileId = uploadedFileRequest.data.id;

    const { data } = await createItem({
      data: {
        issued_at: values.issued_date,
        expires_at: values.expiry_date,
        object_type: 'property',
        object_id: propertyId,
        // BE requires this but it should be optional since it's not shown in the UI
        details: complianceType?.label,
        compliance_type_id: complianceType?.id,
        file: { id: uploadedFileId }
      }
    });

    onSave(data);

    await refreshLists();

    return data;
  }, []);

  return (
    <RecordDialog
      title={getRenewDialogLabel(complianceType)}
      submitLabel={'Add item'}
      handleSubmit={handleSubmit}
      onClose={onClose}
      content={content}
    />
  );
}
