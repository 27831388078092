import { DialogProps } from '@rexlabs/dialog';
import { useQueryClient } from 'react-query';
import { useModelActions } from '@rexlabs/model-generator';
import * as React from 'react';
import { useRecordCreatedToast } from 'src/modules/common/toasts/hooks/use-record-created-toast';
import { RecordDialog } from 'view/components/record-screen/dialog/dialog';
import { noteDetailsBlock } from '../blocks/note-details-block';
import { notesModel } from '../models/notes-model';

export interface CreateNoteRecordDialogProps extends DialogProps {
  initialValues: {
    attached_to: any[];
  };
  blockProps?: { hideAddNoteToField: boolean };
}

const content = [
  {
    id: 'add-note',
    label: 'Add Note',
    blocks: [noteDetailsBlock]
  }
];

export function CreateNoteRecordDialog({
  onClose,
  initialValues,
  blockProps
}: CreateNoteRecordDialogProps) {
  const { createItem, refreshLists } = useModelActions(notesModel);
  const addToast = useRecordCreatedToast(notesModel, { actions: [] });
  const queryClient = useQueryClient();

  const handleSubmit = async ({ values }) => {
    const attached_to = values.attached_to.map(({ id, type }) => {
      return { id, type };
    });

    const dataToSubmit = { ...values, attached_to };

    const { data: note } = await createItem({ data: dataToSubmit });

    queryClient.invalidateQueries(['notes']);

    addToast(note);

    return refreshLists();
  };

  return (
    <RecordDialog
      title='Create Note'
      data={initialValues}
      content={content}
      onClose={onClose}
      handleSubmit={handleSubmit}
      blockProps={blockProps}
    />
  );
}
