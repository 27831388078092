import React from 'react';
import { useQueryClient } from 'react-query';

import { api } from 'utils/api/api-client';
import { WarningCircleIcon } from 'view/components/icons/warning-circle';
import { useToast } from 'view/components/@luna/notifications/toast';
import { useConfirmationDialog } from '@rexlabs/dialog';

export const useDeleteChecklist = () => {
  const queryClient = useQueryClient();
  const { open: openConfirmationDialog } = useConfirmationDialog();
  const { addToast } = useToast();

  return React.useCallback((checklistId, checklistLabel) => {
    openConfirmationDialog({
      TitleIcon: WarningCircleIcon,
      destructive: true,
      title: `Remove "${checklistLabel}" checklist`,
      confirmText: `Remove checklist`,
      message: `Are you sure you want to remove the record for ${checklistLabel}?`,
      onConfirm: async () => {
        await api.delete(`/tasks/checklists/${checklistId}`);

        await Promise.all([
          queryClient.invalidateQueries('checklists-widget'),
          queryClient.invalidateQueries(['checklists'])
        ]);

        addToast({
          description: 'Checklist deleted successfully.'
        });
      }
    });
  }, []);
};
