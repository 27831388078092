import { query } from '@rexlabs/model-generator';
import { propertyOwnershipModel } from 'src/modules/property-ownerships/models/property-ownership-model';

export function propertyListManagementAgreementQuery() {
  return query`{
    ${propertyOwnershipModel} {
     id
     agreement_start_date
     agreement_end_date
     property
     entity_fees
     service_package
     ownership {
       last_disbursement
     }
     created_by{
       user
     }
     updated_by{
       user
     }
   }
  }`;
}
