import { LinkButton } from '@rexlabs/button';
import { CellProps } from '@rexlabs/table';
import {
  financialsInvoicesModel,
  Invoice
} from 'data/models/entities/financials/invoices';
import React, { useCallback, useState } from 'react';
import AddIcon from 'view/components/icons/add';
import Popout from '@rexlabs/popout';
import { Field, Form, ReactForms } from '@rexlabs/form';
import { TextInput } from '@rexlabs/text-input';
import { useModelActions } from '@rexlabs/model-generator';
import { EditIcon } from 'view/components/icons/edit';
import LoadingSpinner from '@rexlabs/loading-spinner';
import { DialogButtonGroup } from 'view/components/dialogs/buttons/dialog-button-group';
import {
  margin,
  padding,
  StyleSheet,
  StylesProvider,
  useStyles
} from '@rexlabs/styling';
import { useRecord } from 'src/lib/react-query/hooks/use-record';

const validate = {
  definitions: {}
};
const styles = StyleSheet({
  content: {
    width: '350px'
  },
  footerContainer: {
    ...margin.styles({
      x: ({ token }) => `-${token('spacing.m')}`,
      bottom: ({ token }) => `-${token('spacing.m')}`,
      top: 'm'
    }),
    footer: {
      ...padding.styles({
        all: 'm'
      })
    }
  }
});

interface BpayReferenceNumberFormProps {
  initialValues?: {
    bill_reference?: string;
  };
  onClose: () => void;
  onSubmit: (formValues: { bill_reference: string }) => Promise<void>;
}

function BpayReferenceNumberForm({
  initialValues,
  onClose,
  onSubmit
}: BpayReferenceNumberFormProps) {
  const s = useStyles(styles);
  return (
    <ReactForms
      validate={validate}
      handleSubmit={onSubmit}
      initialValues={initialValues}
    >
      {({ submitForm, isSubmitting, resetForm }) => (
        <Form>
          <div {...s('content')}>
            <Field
              name='bill_reference'
              label='BPAY Customer Reference Number'
              Input={TextInput}
            />
          </div>
          <div {...s('footerContainer')}>
            <DialogButtonGroup
              submitLabel='Add number'
              resetAll={resetForm}
              isSubmitting={isSubmitting}
              handleSubmit={() => {
                submitForm();
              }}
              onClose={onClose}
            />
          </div>
        </Form>
      )}
    </ReactForms>
  );
}
const overrideToken = {
  popout: {
    content: {
      overflow: 'visible'
    }
  }
};

export function BpayCustomerReferenceNumberCell({
  value
}: CellProps<unknown, Invoice>) {
  const [isLoading, setIsLoading] = useState(false);
  const { updateItem } = useModelActions(financialsInvoicesModel);
  const [cachedValue, setCachedValue] = useState(value.bill_reference);

  const { refetch } = useRecord();

  const handleSubmit = async (formValues: { bill_reference: string }) => {
    setIsLoading(true);
    await updateItem({
      id: value.id,
      data: formValues
    });
    await refetch();
    setCachedValue(formValues.bill_reference);

    setIsLoading(false);
  };

  const PopoutContent = useCallback(
    ({ toggle }) => (
      <BpayReferenceNumberForm
        onSubmit={handleSubmit}
        initialValues={{ bill_reference: cachedValue }}
        onClose={toggle}
      />
    ),
    [cachedValue]
  );

  if (isLoading) return <LoadingSpinner size={16} strokeWidth={2} />;

  return (
    <StylesProvider tokens={overrideToken}>
      <Popout Content={PopoutContent}>
        {cachedValue ? (
          <div className='flex justify-between'>
            <div>{cachedValue}</div>
            <EditIcon />
          </div>
        ) : (
          <LinkButton IconLeft={AddIcon}>Add reference number</LinkButton>
        )}
      </Popout>
    </StylesProvider>
  );
}
