import { Generator, GeneratorConfig } from 'data/models/generator';
import { trustJournalEntryModel } from 'src/modules/trust-journal-entries/models/trust-journal-entries';
import { BankDepositLineItem } from '../types/bank-deposit-line-item';

export const bankDepositLineItemConfig: GeneratorConfig = {
  entities: {
    related: {
      trust_journal_entry: {
        include: 'trust_journal_entry',
        model: trustJournalEntryModel
      }
    }
  }
};

export const bankDepositLineItemModel = new Generator<BankDepositLineItem>(
  'financials/bank-deposit-line-items',
  bankDepositLineItemConfig
).createEntityModel();
