import React, { useCallback } from 'react';

import {
  useValueList,
  Select,
  RecordOption,
  SelectProps
} from '@rexlabs/select';

import { ValueListItem } from 'utils/normaliser/value-list-item';
import { getValueListItems } from 'utils/api/get-value-list-items';

export const NegativeCurrencySelect = (props: SelectProps<ValueListItem>) => {
  const type = 'negative_currency_format';
  const getItems = useCallback(() => getValueListItems(type), [type]);

  const { getSelectProps } = useValueList<ValueListItem>({
    getItems
  });

  const { items, ...selectProps } = getSelectProps();

  return (
    <Select
      {...selectProps}
      items={items}
      normaliser={itemNormaliser}
      Option={RecordOption}
      {...props}
    />
  );
};

const BRACKETED_DESCRIPTION = 'e.g. ($10.00)';
const DASHED_DESCRIPTION = 'e.g. -$10.00';

const mapDesciptions = {
  dash_prefixed: DASHED_DESCRIPTION,
  bracketed: BRACKETED_DESCRIPTION
};

function itemNormaliser(item: ValueListItem) {
  const description = mapDesciptions[item.id] ?? '';

  return {
    ...item,
    description
  };
}
