import { FinancialEntity } from 'src/modules/financials/utils/financial-entity-action-group/use-get-financial-entity-actions';
import { Contact } from 'src/modules/contacts/types/contact-types';

export const tryGetReceiptFromForPayableBy = (
  payableBy: FinancialEntity
): Contact[] | undefined => {
  if (payableBy.__record_type === 'tenancy' && payableBy.tenants) {
    return payableBy.tenants
      .sort((a, b) =>
        a.contact.display_name.localeCompare(b.contact.display_name)
      )
      .map((tenant) => tenant.contact);
  }

  if (payableBy?.financial_contact) {
    return [payableBy.financial_contact];
  }

  return undefined;
};
