import {
  MoveFundsBucketPayload,
  MoveFundsPayload
} from 'src/modules/prepayments/models/prepayment-buckets-model';
import { TransferBucketsPopoutFormValues } from 'src/modules/trust-journal-entries/popouts/transfer-buckets-popout-form';
import { TrustLedger } from 'src/modules/trust-ledgers/types/trust-ledger-types';
import { TenancyPrepaymentBucket } from 'src/modules/prepayments/types/tenancy-prepayment-bucket';

interface MapMoveBucketsFormToApiParams {
  values: TransferBucketsPopoutFormValues;
  ledger: TrustLedger;
}

export function mapMoveBucketsFormToApi({
  values,
  ledger
}: MapMoveBucketsFormToApiParams): MoveFundsPayload {
  const fromBucket = makeBucketPayload(values.from);
  const toBucket = makeBucketPayload(values.to);

  const payload: MoveFundsPayload = {
    amount_to_move: values.amount,
    reason: values.reason,
    ledger: { id: ledger?.id },
    to: toBucket,
    from: fromBucket
  };

  return payload;
}

function makeBucketPayload(
  bucket: TenancyPrepaymentBucket
): MoveFundsBucketPayload {
  if (
    bucket.system_purpose?.id === 'general_funds' ||
    bucket.bucket_id === null
  ) {
    return { prepayment_bucket: null };
  }

  return {
    prepayment_bucket: { id: bucket.bucket_id },
    property: bucket.property ? { id: bucket.property.id } : undefined
  };
}
