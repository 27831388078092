import { useDialog } from '@rexlabs/dialog';
import { useModelActions } from '@rexlabs/model-generator';
import { financialsDisbursementsModel } from 'data/models/entities/financials/disbursements/disbursements';
import { PendingDisbursement } from 'data/models/entities/financials/pending-disbursements';
import { financialsPendingDisbursementsContactsModel } from 'data/models/entities/financials/pending-disbursements/pending-contact-disbursement';
import { financialsPendingDisbursementsOwnershipsModel } from 'data/models/entities/financials/pending-disbursements/pending-ownership-disbursement';
import { RecordTypes } from 'data/models/types';
import * as React from 'react';
import { useToast } from 'view/components/@luna/notifications/toast';
import { getRecordTypeNamePluralised } from 'utils/records/get-record-type-name';
import { DisburseIndividualWithFeesDialog } from 'src/modules/disbursements/dialogs/disburse-funds-individual-with-bills-dialog';
import { BulkDisburseFundsDialog } from 'src/modules/disbursements/dialogs';
import { Trans } from 'react-i18next';
import { useMessageTriggerSettings } from 'src/modules/communications/messages/settings/message-triggers/hooks/use-message-trigger-settings';
import { fetchTemplate } from 'src/modules/communications/message-templates/utils/fetch-template';
import { useWhereabouts, push } from '@rexlabs/whereabouts';
import { DisburseFundsConfirmationDialog } from '../dialogs/disburse-funds-confirmation-dialog';
import { DISBURSEMENT_ROUTES } from '../routes';
import { useDisbursementSummary } from './use-disbursement-summary';

export function useDisburseSelected() {
  const { open: openMultiDisbursementSelection } = useDialog(
    BulkDisburseFundsDialog
  );
  const { open: openPayFeesDialog } = useDialog(
    DisburseIndividualWithFeesDialog
  );

  const { open: openDisburseFundsConfirmationDialog } = useDialog(
    DisburseFundsConfirmationDialog
  );
  const handleSubmit = useDisbursementHandleSubmit();

  return (
    selectedItems: PendingDisbursement[],
    refreshBillsAndFees?: boolean
  ) => {
    if (selectedItems.length < 1) {
      return;
    }

    const isSingle = selectedItems.length === 1;
    const recordType = selectedItems[0].object_type;

    //If we're one contact with bills
    if (
      isSingle &&
      recordType === 'contact' &&
      selectedItems[0].financial_summary!.bills_and_fees_payable_count > 0
    ) {
      return openPayFeesDialog({
        recordType,
        pendingDisbursement: selectedItems[0],
        handleConfirmation: handleSubmit,
        refreshBillsAndFees: !!refreshBillsAndFees
      });

      return;
    }

    const entitiesWithBills = selectedItems.filter(
      (item) =>
        Number(item.financial_summary?.bills_and_fees_payable_count) !== 0
    );

    const entiesWithoutBills = selectedItems.filter((item) =>
      Number(item.financial_summary?.bills_and_fees_payable_count === 0)
    );

    //Contacts, some with bills
    if (recordType === 'contact' && entitiesWithBills.length > 0) {
      return openMultiDisbursementSelection({
        recordType,
        contactsWithBills: entitiesWithBills,
        contactsWithoutBills: entiesWithoutBills,
        handleConfirmation: handleSubmit
      });
    }

    //Otherwise, we must be contacts without bills OR ownerships...
    return openDisburseFundsConfirmationDialog({
      entitiesToDisburse: selectedItems,
      handleConfirmation: handleSubmit
    });
  };
}

function useDisbursementHandleSubmit() {
  const { messageTriggerSettings } = useMessageTriggerSettings();
  const { createItem: createDisbursementItem } = useModelActions(
    financialsDisbursementsModel
  );
  const {
    refreshLists: refreshPendingContactDisbursementLists
  } = useModelActions(financialsPendingDisbursementsContactsModel);
  const {
    refreshLists: refreshPendingOwnershipDisbursementLists
  } = useModelActions(financialsPendingDisbursementsOwnershipsModel);
  const { addToast } = useToast();
  const { loadItems: reloadDisbursementStats } = useDisbursementSummary();
  const { path } = useWhereabouts();

  return async (entitiesToDisburse: PendingDisbursement[]) => {
    const recordType = entitiesToDisburse[0].object_type;
    const isSingle = entitiesToDisburse.length === 1;

    const objects = entitiesToDisburse.map((entity) => ({
      id: entity.id,
      type: {
        id: entity.object_type
      }
    }));

    const defaultTemplateId =
      messageTriggerSettings[`${recordType}_disbursement`]?.template_id;

    await Promise.all([
      createDisbursementItem({ data: { objects } }),
      fetchTemplate(defaultTemplateId)
    ]);

    recordType === RecordTypes.Ownership
      ? refreshPendingOwnershipDisbursementLists()
      : refreshPendingContactDisbursementLists();

    const toastMessageSubject = `${
      entitiesToDisburse.length
    } ${getRecordTypeNamePluralised(
      'disbursement',
      entitiesToDisburse.length
    )}`;

    addToast({
      description: (
        <Trans
          i18nKey='disbursements.general.disbursements-in-progress'
          values={{ subject: toastMessageSubject }}
        >
          {toastMessageSubject} in progress
        </Trans>
      )
    });

    // if individual, and disbursing from pending details page
    if (isSingle) {
      const pathsToRedirect = new RegExp(
        '/pending-(contacts|ownership)-disbursement/[a-z0-9-]+'
      );
      if (pathsToRedirect.test(path as string)) {
        push(DISBURSEMENT_ROUTES.DISBURSEMENT_LIST);
      }
    } else {
      await reloadDisbursementStats();
    }
  };
}
