import React from 'react';
import { RadioGroupInput, RadioGroupInputProps } from '@rexlabs/radio-input';
import { useApiValueList } from 'view/hooks/api/use-api-value-list';
import LoadingSpinner from '@rexlabs/loading-spinner';

export interface ValueListRadiosProps
  extends Omit<RadioGroupInputProps, 'meta'> {
  valueListId: string;
}

export function ValueListRadios({
  valueListId,
  ...props
}: ValueListRadiosProps) {
  const { isLoading, data } = useApiValueList(valueListId);

  if (isLoading || !data)
    return (
      <div className='flex items-center' style={{ height: '4rem' }}>
        <LoadingSpinner size={16} strokeWidth={3} />
      </div>
    );

  const options = data?.map((valueListValue) => ({
    value: valueListValue.id,
    label: valueListValue.label!
  }));

  return <RadioGroupInput {...props} options={options} />;
}
