import React from 'react';
import Box from '@rexlabs/box';
import { IconProps } from '@rexlabs/icons';
import { StyleSheet, text, useStyles, useToken } from '@rexlabs/styling';
import { Truncate } from '@rexlabs/text';

interface IconValueProps {
  value: string;
  Icon: React.ComponentType<IconProps>;
}

const defaultStyles = StyleSheet({
  subtext: {
    ...text.styles({ fallback: 'small.default' }),
    color: ({ token }) => token('color.textStyle.body.subtext'),
    minWidth: ({ token }) => token('spacing.xl')
  }
});

export function IconValue({ value, Icon }: IconValueProps) {
  const s = useStyles(defaultStyles);
  const token = useToken();

  return (
    <Box gap={token('spacing.xxs')} {...s('subtext')}>
      <Icon width={12} />
      <Truncate>
        <Box>{value}</Box>
      </Truncate>
    </Box>
  );
}
