import React from 'react';

import Box from '@rexlabs/box';
import { StyleSheet, useStyles } from '@rexlabs/styling';
import { Small, Tiny } from '@rexlabs/text';

/**
 * NOTE: Normally we would add SVGs to our assets folder and import them as React components, but
 * in this case, they are so specific to this component, that it makes sense to keep them here. This
 * design is very custom and not likely to be reused.
 */

export type TimeAndDistance = {
  time: number;
  distance: number;
};
export interface InspectionRunTimeAndDistanceProps {
  timeAndDistance: TimeAndDistance[];
}

const defaultStyles = StyleSheet({
  dot: {
    height: '1.6rem',
    width: '1.6rem',
    backgroundColor: '#000',
    borderRadius: '50%',
    display: 'flex',
    color: 'white',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center'
  },
  dotText: {
    col: '#fff'
  },
  container: {
    minWidth: '6.2rem'
  }
});

function Dot({ number }: { number?: number }) {
  const s = useStyles(defaultStyles);
  return (
    <div {...s('dot')}>
      <Small as='span' {...s('dotText')}>
        {number}
      </Small>
    </div>
  );
}

// See comment above about SVGs
function LineWithGradient() {
  return (
    <Box height={41} paddingBottom={2} paddingTop={4}>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='2'
        height='36'
        viewBox='0 0 2 36'
        fill='none'
      >
        <path
          d='M1.5 1C1.5 0.723858 1.27614 0.5 1 0.5C0.723858 0.5 0.5 0.723858 0.5 1H1.5ZM1.5 36L1.5 1H0.5L0.5 36H1.5Z'
          fill='url(#paint0_linear_2777_73492)'
        />
        <defs>
          <linearGradient
            id='paint0_linear_2777_73492'
            x1='1'
            y1='20.9102'
            x2='1'
            y2='34.5329'
            gradientUnits='userSpaceOnUse'
          >
            <stop stopColor='#94979B' />
            <stop offset='1' stopColor='#94979B' stopOpacity='0' />
          </linearGradient>
        </defs>
      </svg>
    </Box>
  );
}

// See comment above about SVGs
function ArrowWithGradient() {
  return (
    <Box height={41} paddingTop={2} paddingBottom={4}>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='8'
        height='35'
        viewBox='0 0 8 37'
        fill='none'
      >
        <path
          d='M3.64645 36.3536C3.84171 36.5488 4.15829 36.5488 4.35355 36.3536L7.53553 33.1716C7.7308 32.9763 7.7308 32.6597 7.53553 32.4645C7.34027 32.2692 7.02369 32.2692 6.82843 32.4645L4 35.2929L1.17157 32.4645C0.976311 32.2692 0.659728 32.2692 0.464466 32.4645C0.269204 32.6597 0.269204 32.9763 0.464466 33.1716L3.64645 36.3536ZM4.5 1C4.5 0.723858 4.27614 0.5 4 0.5C3.72386 0.5 3.5 0.723858 3.5 1H4.5ZM4.5 36L4.5 1H3.5L3.5 36H4.5Z'
          fill='url(#paint0_linear_2777_73487)'
        />
        <defs>
          <linearGradient
            id='paint0_linear_2777_73487'
            x1='4'
            y1='12.1077'
            x2='4'
            y2='1.62869'
            gradientUnits='userSpaceOnUse'
          >
            <stop stopColor='#94979B' />
            <stop offset='1' stopColor='#94979B' stopOpacity='0' />
          </linearGradient>
        </defs>
      </svg>
    </Box>
  );
}

// See comment above about SVGs
function LongArrow() {
  return (
    <Box height={104} marginTop={4} marginBottom={4}>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='8'
        height='104'
        viewBox='0 0 8 104'
        fill='none'
      >
        <path
          d='M3.64645 105.354C3.84171 105.549 4.15829 105.549 4.35355 105.354L7.53553 102.172C7.7308 101.976 7.7308 101.66 7.53553 101.464C7.34027 101.269 7.02369 101.269 6.82843 101.464L4 104.293L1.17157 101.464C0.976311 101.269 0.659728 101.269 0.464466 101.464C0.269204 101.66 0.269204 101.976 0.464466 102.172L3.64645 105.354ZM4.5 1C4.5 0.723854 4.27614 0.5 4 0.5C3.72386 0.5 3.5 0.723854 3.5 1H4.5ZM4.5 105L4.5 1H3.5L3.5 105H4.5Z'
          fill='url(#paint0_linear_2777_45310)'
        />
        <defs>
          <linearGradient
            id='paint0_linear_2777_45310'
            x1='4'
            y1='34.0058'
            x2='4'
            y2='2.86811'
            gradientUnits='userSpaceOnUse'
          >
            <stop stopColor='#94979B' />
            <stop offset='1' stopColor='#94979B' stopOpacity='0' />
          </linearGradient>
        </defs>
      </svg>
    </Box>
  );
}

/**
 * This components styling is very very coupled with the design of the inspection run cards. Any
 * changes to the styling of this component will likely require changes to the styling of the
 * inspection run cards.
 */
export function InspectionRunTimeAndDistance({
  timeAndDistance = []
}: InspectionRunTimeAndDistanceProps) {
  // TODO: check settings to determine if we should use miles or km
  const unit = {
    id: 'km',
    label: 'km'
  };
  const s = useStyles(defaultStyles);

  return (
    <Box
      flexDirection={'column'}
      alignItems={'center'}
      paddingTop='3.6rem'
      paddingBottom='3.6rem'
      {...s('container')}
    >
      {timeAndDistance.length > 0 ? (
        timeAndDistance.map(({ time, distance }, index) => {
          const isStart = index === 0;
          const isFinish = timeAndDistance.length === index + 1;
          return (
            <>
              <Dot number={!isStart ? index : undefined} />

              <LineWithGradient />

              <Box
                key={index}
                flexDirection='column'
                alignItems='center'
                justifyContent='center'
                height='3rem'
              >
                <Small as='div'>{`${time} min`}</Small>
                <Tiny as='span'>{`(${distance} ${unit.label})`}</Tiny>
              </Box>

              <ArrowWithGradient />

              {isFinish && <Dot />}
            </>
          );
        })
      ) : (
        <>
          <Dot />

          <LongArrow />

          <Dot />
        </>
      )}
    </Box>
  );
}
