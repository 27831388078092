import { CustomAction, ListData, touchEtag } from '@rexlabs/model-generator';

import { Generator, GeneratorConfig } from 'data/models/generator';
import { BaseFields, RecordTypes, ValueListValue } from 'data/models/types';
import { api } from 'utils/api/api-client';
import { trustJournalEntryLineItemModel } from 'src/modules/trust-journal-entry-line-items/models/trust-journal-entry-line-item-model';
import { TrustJournalEntry } from 'src/modules/trust-journal-entries/types/trust-journal-entry-types';
import { trustJournalEntryModel } from 'src/modules/trust-journal-entries/models/trust-journal-entries';
import { usersModel } from 'src/modules/users/models/users';
import { contactsModel } from 'src/modules/contacts/models/contacts';
import { Contact } from 'src/modules/contacts/types/contact-types';
import { TrustJournalEntryLineItem } from 'src/modules/trust-journal-entry-line-items/types/trust-journal-entry-line-item-type';
import { Author } from 'src/modules/authors/types/author-types';
import { SnapshotSummary } from 'src/modules/disbursements/types/SnapshotSummary';
import _ from 'lodash';
import { Ownership, ownershipsModel } from '../../ownerships';
import { Invoice } from '../invoices';

export type DisbursementStatusType = 'disbursed' | 'voided' | 'processing_void';

export type DisbursementObject = {
  id: string;
  label: string;
  type: ValueListValue<
    typeof RecordTypes.Contact | typeof RecordTypes.Ownership
  >;
};

export interface Disbursement
  extends BaseFields<typeof RecordTypes.Disbursement> {
  display_name: string; // TODO: change this to label
  created_at: string;
  status: ValueListValue<DisbursementStatusType>;
  status_reason: string;
  summary_total_transferred: number;
  summary_total_transferred_count: number;
  summary_total_withdrawn: number;
  summary_total_withdrawn_count: number;
  summary_total_disbursed: number;
  record_reference: string;
  object: DisbursementObject;

  payment_methods?: ValueListValue<string>[];
  ownership?: Ownership;
  contact?: Contact;
  invoice_withdrawal_line_items?: ListData<TrustJournalEntryLineItem>;
  bill_payment_line_items?: ListData<TrustJournalEntryLineItem>;
  general_payout_line_items?: ListData<TrustJournalEntryLineItem>;
  trust_journal_entries?: ListData<TrustJournalEntry>;
  created_by?: Author;
  invoice?: Invoice;

  voided_at: Date | null;
  voided_by?: Author;
  disbursement_snapshot_summary: SnapshotSummary | null;
}

const config: GeneratorConfig = {
  entities: {
    related: {
      ownership: {
        include: 'ownership',
        model: ownershipsModel
      },
      contact: {
        include: 'contact',
        model: contactsModel
      },
      trust_journal_entries: {
        include: 'trust_journal_entries',
        model: [trustJournalEntryModel]
      },
      bill_payment_line_items: {
        include: 'bill_payment_line_items',
        model: [trustJournalEntryLineItemModel]
      },
      invoice_withdrawal_line_items: {
        include: 'invoice_withdrawal_line_items',
        model: [trustJournalEntryLineItemModel]
      },
      general_payout_line_items: {
        include: 'general_payout_line_items',
        model: [trustJournalEntryLineItemModel]
      },
      payment_methods: {
        include: 'payment_methods'
      },
      created_by: {
        include: 'created_by.contact',
        model: usersModel
      },
      voided_by: {
        include: 'voided_by',
        model: usersModel
      },
      disbursement_snapshot_summary: {
        include: 'disbursement_snapshot_summary'
      }
    }
  }
};

type VoidDisbursementPayload = {
  id: string;
  reason: string;
};

const actionCreators = {
  voidDisbursement: {
    request: (
      payload: VoidDisbursementPayload,
      _actions,
      _dispatch,
      _getState
    ) =>
      api.patch(`financials/disbursements/${payload.id}/void`, {
        reason: payload.reason
      }),
    reduce: {
      success: (state, action) => {
        const originalId = action.meta.originalPayload.id;

        return {
          ...state,
          items: {
            ...state.items,
            [originalId]: touchEtag({
              ...state.items[originalId],
              data: {
                ...state.items[originalId].data,
                ...action.payload.data
              }
            })
          }
        };
      }
    }
  } as CustomAction<VoidDisbursementPayload, any>,
  downloadItem: {
    request: (disbursementId: string) =>
      api.post(`/reporting/disbursement-statement`, {
        disbursement: {
          id: disbursementId
        },
        format: 'pdf',
        disposition: 'download_link',
        merge_files: true
      }),
    reduce: _.identity
  },
  viewItem: {
    request: (disbursementId: string) =>
      api.post(`/reporting/disbursement-statement`, {
        disbursement: {
          id: disbursementId
        },
        format: 'pdf',
        disposition: 'inline_link',
        merge_files: true
      }),
    reduce: _.identity
  }
};

export const financialsDisbursementsModel = new Generator<
  Disbursement,
  typeof actionCreators
>('financials/disbursements', config).createEntityModel({ actionCreators });
