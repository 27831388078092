import React from 'react';

import { BatchReceiptingItem } from '../types';
import { useMapBatchReceiptingItems } from '../hooks/use-map-batch-receipting-items';
import { useBankStatementTransactionsQuery } from '../hooks/use-bank-statement-transactions-query';
import { BatchReceiptingTable } from './batch-receipting-table';
import { NotMatchedTabEmptyState } from './empty-states/not-matched-tab-empty-state';
import { withTableProvider } from './table-provider';

export const NotMatchedTab = withTableProvider({
  Component: () => {
    const {
      data,
      isLoading,
      fetchNextPage,
      isFetchingNextPage
    } = useBankStatementTransactionsQuery('NOT_MATCHED');

    const items: BatchReceiptingItem[] =
      data?.pages.map((page) => page.data).flat() || [];

    const mappedItems = useMapBatchReceiptingItems(items);

    return (
      <BatchReceiptingTable
        isLoading={isLoading}
        items={mappedItems}
        EmptyState={NotMatchedTabEmptyState}
        fetchNextPage={fetchNextPage}
        isFetchingNextPage={isFetchingNextPage}
        testId='not-matched-card'
      />
    );
  },
  hashParamKey: 'not_match'
});
