import React from 'react';
import { Field } from '@rexlabs/form';
import { TextInput } from '@rexlabs/text-input';

import { BlockConfig } from 'view/components/record-screen/types';
import { Column, Grid } from 'view/components/@luna/form/grid';
import { Value } from 'view/components/values';

export const createTrustLedgerBlock: BlockConfig = {
  id: 'createTrustLedger',
  validate: {
    definitions: {
      name: { rules: 'required' }
    }
  },
  Edit: ({ data }) => (
    <Grid columns={2}>
      <Field name='name' label='Name' Input={TextInput} optional={false} />
      <Value label='Attached to' value={data.object.label} />
      <Value label='Bank account' value={data.bank_account.name} />
      <Column width={2}>
        <Field name='sub_name' label='Description' Input={TextInput} />
      </Column>
    </Grid>
  )
};
