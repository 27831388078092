import { useDialog } from '@rexlabs/dialog';
import { FieldArrayPassthroughProps } from '@rexlabs/form';
import { TaskType } from 'src/modules/tasks/common/types/TaskType';
import { useFeatureFlags } from 'view/components/@luna/feature-flags';
import { FLAGS } from 'utils/feature-flags';
import { CreateChecklistItemTriggerDialog } from '../../../dialogs/create-checklist-item-trigger';
import { ChecklistTemplateItem } from '../../../types/ChecklistTemplate';

export function useAddTriggerAction({
  index,
  fieldArrayProps,
  taskType
}: {
  fieldArrayProps: FieldArrayPassthroughProps;
  index: number;
  taskType: TaskType;
}) {
  const { hasFeature } = useFeatureFlags();
  const { open: openCreateChecklistItemTriggerDialog } = useDialog(
    CreateChecklistItemTriggerDialog
  );

  return ({
    checklistTemplateItem
  }: {
    checklistTemplateItem: ChecklistTemplateItem;
  }) => {
    const isDisabledDueToOutcome = checklistTemplateItem?.has_outcome;
    return {
      label: hasFeature(FLAGS.SMART_CHECKLISTS_UI_OVERHAUL)
        ? 'Add automation'
        : 'Add triggered action',
      id: 'add-triggered-action',
      disabled: isDisabledDueToOutcome,
      handleAction: () => {
        openCreateChecklistItemTriggerDialog({
          index,
          fieldArrayProps,
          checklistTemplateItem,
          taskType
        });
      }
    };
  };
}
