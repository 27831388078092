import { useDialog } from '@rexlabs/dialog';

import { ActionDeclaration } from 'src/modules/common/actions/types/action-declaration-types';

import { CreateOwnershipDialog } from '../dialogs/create-ownership-dialog';

export function useGetCreateOwnershipAction() {
  const createOwnershipDialog = useDialog(CreateOwnershipDialog);
  return (): ActionDeclaration => ({
    id: 'create-ownership',
    group: 'ownership',
    label: 'Create ownership',
    handleAction: () => createOwnershipDialog.open()
  });
}
