import ActionMenu from '@rexlabs/action-menu';
import { GhostButton, PrimarySplitButton } from '@rexlabs/button';
import * as React from 'react';
import { StandardDialogFooter } from 'view/components/dialogs/components/standard-dialog-footer';
import ChevronDownIcon from 'view/components/icons/chevron-down';
import { ButtonGroupProps } from 'view/components/record-screen/dialog/types';

export interface UploadBillsDialogButtonGroupProps extends ButtonGroupProps {
  handleProcessNow?: () => void;
  handleProcessLater?: () => void;
}

export function UploadBillsDialogButtonGroup({
  onClose,
  handleSubmit,
  isSubmitting,
  handleProcessNow,
  handleProcessLater
}: UploadBillsDialogButtonGroupProps) {
  const handleSubmitAndClose = async () => {
    handleProcessLater?.();

    const handleSubmitResponse = await handleSubmit();

    // If validation fails, don't proceed
    if (!handleSubmitResponse) {
      return;
    }
    onClose?.();
  };

  const handleSubmitAndProcessNow = async () => {
    handleProcessNow?.();

    const handleSubmitResponse = await handleSubmit();

    // If validation fails, don't proceed
    if (!handleSubmitResponse) {
      return;
    }
    onClose?.();
  };

  return (
    <StandardDialogFooter>
      <GhostButton onClick={onClose}>Cancel</GhostButton>
      <PrimarySplitButton
        Left={({ Button }) => (
          <Button
            type='submit'
            isLoading={isSubmitting}
            onClick={handleSubmitAndProcessNow}
          >
            {'Process now'}
          </Button>
        )}
        Right={({ IconButton }) => (
          <ActionMenu
            placement='bottom-end'
            items={[
              {
                label: 'Process later',
                onClick: handleSubmitAndClose
              }
            ]}
            Button={React.forwardRef((props, ref) => (
              <IconButton {...props} ref={ref} Icon={ChevronDownIcon} />
            ))}
          />
        )}
      />
    </StandardDialogFooter>
  );
}
