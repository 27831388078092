import { Property } from 'src/modules/properties/types/property-types';

export function getOwnerSuggestedItems(property?: Property | null) {
  return () =>
    property?.active_property_ownership?.ownership?.owners
      ? property.active_property_ownership.ownership.owners.map((owner) => {
          const contact = owner.contact;
          return {
            ...contact,
            value: contact.id,
            label: contact.display_name
          };
        })
      : [];
}
