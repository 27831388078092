import { SingleActionDeclaration } from 'src/modules/common/actions/types/action-declaration-types';
import { useDialog } from '@rexlabs/dialog';
import { noop } from 'lodash';
import AddIcon from 'view/components/icons/add';
import { CreateMoveOutRecordDialog } from '../../dialogs/create-move-out-record-dialog';
import { MoveOutFormData } from '../../types/MoveOutFormData';

interface UseGetCreateMoveOutActionProps {
  onCreate?: (data) => void;
  isNested?: boolean;
  isFixture?: boolean;
  initialValues: Partial<MoveOutFormData>;
}

export function useGetCreateMoveOutAction(
  {
    onCreate,
    isNested,
    initialValues,
    isFixture
  }: UseGetCreateMoveOutActionProps = {
    isNested: false,
    isFixture: false,
    onCreate: noop,
    initialValues: {}
  }
) {
  const { open } = useDialog(CreateMoveOutRecordDialog, {
    target: isNested ? 'nested-dialogs' : 'dialogs'
  });

  return (): SingleActionDeclaration => {
    return {
      id: 'create-move-out',
      intent: 'primary',
      group: 'todo',
      label: 'Create move out',
      Icon: AddIcon,
      handleAction: async () => {
        open({
          isFixture,
          onCreate,
          initialValues: {
            ...initialValues,
            priority: {
              id: 'normal',
              label: 'Normal'
            },
            status: {
              id: 'not_started',
              label: 'Not started'
            }
          }
        });
      }
    };
  };
}
