import { api } from 'utils/api/api-client';
import { useCachedItems } from 'view/hooks/api/cached-items';

export interface SummaryData {
  total_contact_payout_amount: number;
  total_ownership_payout_amount: number;
  total_ownership_bill_owing_amount: number;
}

export function useDisbursementSummary() {
  const { items, loadItems } = useCachedItems<SummaryData>(
    'pending-disbursement-stats',
    async () => {
      const summaryData = await api.get<SummaryData>(
        `/financials/pending-disbursements/summary`
      );

      // have to pretend it is an array for the cache items function, sadly
      return [summaryData.data];
    },
    { prefetch: true }
  );

  return { items, loadItems };
}
