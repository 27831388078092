import React, {
  useMemo,
  useCallback,
  forwardRef,
  ReactNode,
  ComponentType
} from 'react';

import Box from '@rexlabs/box';
import {
  useStyles,
  StyleSheet,
  StylesProvider,
  padding,
  border
} from '@rexlabs/styling';
import { ActionMenu } from '@rexlabs/action-menu';
import { Breakpoints } from '@rexlabs/breakpoints';
import { PrimaryCircleButton } from '@rexlabs/button';
import Icons from '@rexlabs/icons';

import { ActionButtonContainer } from 'view/components/record-screen/sticky-navigation/action-button-container';

import { useFilteredActions } from 'utils/actions/use-filtered-actions';
import { transformActionDeclarationsToActionMenuItems } from 'utils/actions/transforms';

import { RecordNavigationIcon } from 'view/components/icons/record-navigation';
import { RelatedRecordsIcon } from 'view/components/icons/related-records';

import { MenuButton } from './menu-button';
import { useStickyNavigation } from './context';

const { CrossLarge, Kebab } = Icons;

const defaultStyles = StyleSheet({
  container: {
    position: 'sticky',
    bottom: 0,
    ...padding.styles({
      x: 'xs',
      bottom: 'm'
    })
  },

  wrapper: {
    position: 'relative',
    height: '5.6rem',
    maxWidth: '39.8rem',
    boxShadow:
      '0px 0px 3px rgba(47, 53, 61, 0.06), ' +
      '0px 0px 9px rgba(47, 53, 61, 0.08), ' +
      '0px 0px 15px rgba(47, 53, 61, 0.06)',

    ...border.styles({
      all: {
        width: 'none',
        radius: () => '1.2rem'
      }
    }),

    backgroundColor: ({ token }) => token('color.pageBackground.alternate')
  },

  content: {
    ...padding.styles({
      y: 'm'
    })
  },

  closeButton: {
    height: '8.4rem',
    transform: 'translateY(0px)'
  }
});

const overrides = {
  button: {
    height: '6rem',
    minWidth: '6rem',

    icon: {
      size: 'l',
      m: {
        height: '6rem',
        minWidth: '6rem'
      }
    },

    svg: {
      size: 'l'
    }
  }
};

type StickyNavButtonsProps = {
  leftBarTitle?: string;
  rightBarTitle?: string;
  leftBar?: ReactNode;
  rightBar?: ReactNode;
  LeftBarIcon?: ComponentType<any>;
  RightBarIcon?: ComponentType<any>;
};

export function StickyNavButtons({
  leftBarTitle,
  rightBarTitle,
  leftBar,
  rightBar,
  LeftBarIcon = RecordNavigationIcon,
  RightBarIcon = RelatedRecordsIcon
}: StickyNavButtonsProps) {
  const s = useStyles(defaultStyles);

  const {
    actions,
    activeMenu,
    hideViewAction,
    setActiveMenu
  } = useStickyNavigation();

  const filteredActions = useFilteredActions({ actions, hideViewAction });
  const transformedActions = transformActionDeclarationsToActionMenuItems(
    filteredActions
  );

  const leftBarItems = useMemo(
    () => [
      {
        isCustomContent: true,
        Content: forwardRef<HTMLDivElement>((props, ref) => {
          return (
            <Box {...s('content')} {...props} ref={ref}>
              {leftBar}
            </Box>
          );
        })
      }
    ],
    [leftBar]
  );

  const rightBarItems = useMemo(
    () => [
      {
        isCustomContent: true,
        Content: forwardRef<HTMLDivElement>((props, ref) => (
          <div {...props} ref={ref}>
            {rightBar}
          </div>
        ))
      }
    ],
    [rightBar]
  );

  const toggleMenuState = useCallback(
    (newActive) => {
      if (activeMenu === newActive) {
        setActiveMenu(null);
      } else {
        setActiveMenu(newActive);
      }
    },
    [activeMenu]
  );

  return (
    <Box
      {...s('container')}
      flex={1}
      alignItems='flex-end'
      justifyContent='center'
    >
      <Box
        {...s('wrapper')}
        flex={1}
        alignItems='center'
        justifyContent='center'
      >
        <Breakpoints queries={{ maxWidth: 's' }}>
          <ActionMenu
            title={leftBarTitle}
            items={leftBarItems}
            Button={forwardRef<HTMLElement>((props, ref) => (
              <MenuButton
                {...props}
                ref={ref}
                disabled={!leftBar}
                Icon={LeftBarIcon}
              />
            ))}
            TitleIcon={LeftBarIcon}
          />
        </Breakpoints>
        <Breakpoints queries={{ minWidth: 's', maxWidth: 'm' }}>
          <MenuButton
            disabled={!leftBar}
            onClick={() => {
              toggleMenuState('left');
            }}
            Icon={LeftBarIcon}
          />
        </Breakpoints>
        <StylesProvider tokens={overrides}>
          <ActionMenu
            items={transformedActions}
            Button={forwardRef<HTMLElement>((props, ref) => (
              <ActionButtonContainer>
                <PrimaryCircleButton {...props} ref={ref} Icon={Kebab} />
              </ActionButtonContainer>
            ))}
            CloseButton={(props) => {
              return (
                <ActionButtonContainer {...s('closeButton')}>
                  <PrimaryCircleButton {...props} Icon={CrossLarge} />
                </ActionButtonContainer>
              );
            }}
          />
        </StylesProvider>
        <Breakpoints queries={{ maxWidth: 's' }}>
          <ActionMenu
            title={rightBarTitle}
            items={rightBarItems}
            Button={forwardRef<HTMLElement>((props, ref) => (
              <MenuButton
                {...props}
                ref={ref}
                disabled={!rightBar}
                Icon={RightBarIcon}
              />
            ))}
            TitleIcon={RightBarIcon}
          />
        </Breakpoints>
        <Breakpoints queries={{ minWidth: 's', maxWidth: 'm' }}>
          <MenuButton
            disabled={!rightBar}
            onClick={() => {
              toggleMenuState('right');
            }}
            Icon={RightBarIcon}
          />
        </Breakpoints>
      </Box>
    </Box>
  );
}
