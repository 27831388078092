import { RecordType } from 'data/models/types';
import { getRecordIdFromUrlPath } from 'utils/records/get-record-id-from-url-path';
import { getRecordTypeFromUrlPath } from 'utils/records/get-record-type-from-url-path';
import {
  AnyModel,
  useModelActions,
  useModelState
} from '@rexlabs/model-generator';
import { financialsPendingDisbursementsContactsModel } from 'data/models/entities/financials/pending-disbursements/pending-contact-disbursement';
import { useState } from 'react';
import { financialsPendingDisbursementsOwnershipsModel } from 'data/models/entities/financials/pending-disbursements/pending-ownership-disbursement';

export type UseRecordParams = {
  id?: string;
  recordType?: RecordType;
};

export function useRecord<Data = any>({
  id,
  recordType
}: UseRecordParams = {}): {
  data: Data;
  refetch: () => any;
} {
  const [derivedId] = useState(() => {
    return id ?? getRecordIdFromUrlPath();
  });

  const [derivedRecordType] = useState(() => {
    return recordType ?? getRecordTypeFromUrlPath();
  });

  const model = mapRecordTypeToModel(derivedRecordType);

  const data = useModelState(model);

  const actions = useModelActions(model);

  return {
    data,
    refetch: () => {
      return actions.refreshItem({
        id: derivedId
      });
    }
  };
}

export function mapRecordTypeToModel(type: string): AnyModel<any, any> {
  switch (type) {
    case 'pending_contact_disbursement':
      return financialsPendingDisbursementsContactsModel;

    case 'pending_ownership_disbursement':
      return financialsPendingDisbursementsOwnershipsModel;

    default:
      throw Error(`${type} has no mapping to a model`);
  }
}
