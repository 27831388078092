import React from 'react';
import { ContentConfig } from 'view/components/record-screen/types';
import { FLAGS } from 'utils/feature-flags';
import { useFeatureFlags } from 'view/components/@luna/feature-flags';

import { leaseReviewTriggerBlock } from 'src/modules/tasks/lease-review/blocks/lease-review-task-trigger-block';
import { inspectionsTriggerBlock } from 'src/modules/tasks/inspections/blocks/inspections-task-trigger-block';

import { createPropertyComplianceTaskSettingsBlock } from 'src/modules/compliance/property-compliance/components/property-compliance-task-settings-block';
import { supplierComplianceTaskSettingsBlock } from 'src/modules/compliance/supplier-compliance/components/supplier-compliance-task-settings-block';
import { useTranslation } from 'react-i18next';
import { createChecklistTemplateBlock } from '../blocks/checklist-template-block';
import { TaskType } from '../../common/types/TaskType';

const TODO = 'To-do';
const MAINTENANCE = 'Maintenance';
const INSPECTIONS = 'Inspections';
const LEASE_REVIEWS = 'Lease review';
const RENT_ARREARS = 'Rent arrears';
const PROPERTY_COMPLIANCE = 'Property compliance';
const SUPPLIER_COMPLIANCE = 'Supplier compliance';
const MOVE_IN = 'Move in';
const MOVE_OUT = 'Move out';
const QUOTES = 'Quotes';
const WORK_ORDERS = 'Work orders';

export const taskTypeTabIdMap: { [index in TaskType]: string } = {
  todo: 'to-do',
  task_maintenance: 'maintenance',
  task_inspection: 'inspections',
  task_lease_review: 'lease-reviews',
  task_arrears: 'rent-arrears',
  task_move_in: 'move-in',
  task_move_out: 'move-out',
  task_property_compliance: 'property-compliance',
  task_supplier_compliance: 'supplier-compliance',
  task_quote: 'quotes',
  task_work_order: 'work-orders'
};

export const useContent = () => {
  const { hasFeature } = useFeatureFlags();
  const { t } = useTranslation();

  return React.useMemo<ContentConfig>(
    () => [
      {
        label: 'Routine',
        items: [
          {
            id: taskTypeTabIdMap.todo,
            label: TODO,
            blocks: [
              createChecklistTemplateBlock({ type: 'todo', label: TODO })
            ]
          },
          {
            id: taskTypeTabIdMap.task_maintenance,
            label: MAINTENANCE,
            blocks: [
              createChecklistTemplateBlock({
                type: 'task_maintenance',
                label: MAINTENANCE
              })
            ]
          },
          {
            id: taskTypeTabIdMap.task_inspection,
            label: INSPECTIONS,
            blocks: [
              ...(hasFeature(FLAGS.TASK_TRIGGERS)
                ? [inspectionsTriggerBlock]
                : []),
              createChecklistTemplateBlock({
                type: 'task_inspection',
                label: INSPECTIONS
              })
            ]
          },
          {
            id: taskTypeTabIdMap.task_lease_review,
            label: t('tasks.lease-review.label.singular', LEASE_REVIEWS),
            blocks: [
              ...(hasFeature(FLAGS.TASK_TRIGGERS)
                ? [leaseReviewTriggerBlock]
                : []),
              createChecklistTemplateBlock({
                type: 'task_lease_review',
                label: t('tasks.lease-review.label.singular', LEASE_REVIEWS)
              })
            ]
          },
          {
            id: taskTypeTabIdMap.task_arrears,
            label: RENT_ARREARS,
            blocks: [
              createChecklistTemplateBlock({
                type: 'task_arrears',
                label: RENT_ARREARS
              })
            ]
          },
          {
            id: taskTypeTabIdMap.task_quote,
            label: QUOTES,
            flag: FLAGS.QUOTES,
            blocks: [
              createChecklistTemplateBlock({
                type: 'task_quote',
                label: QUOTES
              })
            ]
          },
          {
            id: taskTypeTabIdMap.task_work_order,
            label: WORK_ORDERS,
            flag: FLAGS.WORK_ORDERS,
            blocks: [
              createChecklistTemplateBlock({
                type: 'task_work_order',
                label: WORK_ORDERS
              })
            ]
          }
        ]
      },
      {
        label: 'Compliance',
        flag: FLAGS.CHECKLIST_COMPLIANCES,
        items: [
          {
            id: taskTypeTabIdMap.task_property_compliance,
            label: PROPERTY_COMPLIANCE,
            flag: FLAGS.PROPERTY_COMPLIANCE_SETTINGS,
            blocks: [
              createPropertyComplianceTaskSettingsBlock,
              createChecklistTemplateBlock({
                type: 'task_property_compliance',
                label: PROPERTY_COMPLIANCE
              })
            ]
          },
          {
            id: taskTypeTabIdMap.task_supplier_compliance,
            label: SUPPLIER_COMPLIANCE,
            flag: FLAGS.SUPPLIER_COMPLIANCE_SETTINGS,
            blocks: [
              supplierComplianceTaskSettingsBlock,
              createChecklistTemplateBlock({
                type: 'task_supplier_compliance',
                label: SUPPLIER_COMPLIANCE
              })
            ]
          }
        ]
      },
      {
        label: 'Moving',
        items: [
          {
            id: taskTypeTabIdMap.task_move_in,
            label: t('tasks.move-in.title', MOVE_IN),
            blocks: [
              createChecklistTemplateBlock({
                type: 'task_move_in',
                label: t('tasks.move-in.title', MOVE_IN)
              })
            ]
          },
          {
            id: taskTypeTabIdMap.task_move_out,
            label: MOVE_OUT,
            blocks: [
              createChecklistTemplateBlock({
                type: 'task_move_out',
                label: MOVE_OUT
              })
            ]
          }
        ]
      }
    ],
    []
  );
};
