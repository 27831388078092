import dayjs, { Dayjs } from 'dayjs';

import { Leg } from 'src/lib/mapbox/types/Leg';

import { roundNumberUpToNearestInterval } from 'utils/round-number-up-to-nearest-interval';

import { InspectionTask } from '../types/InspectionTask';

export function getEndTimeFromRoute(
  route: Leg[],
  inspections: InspectionTask[],
  fallbackTime: Dayjs
): Dayjs {
  const lastInspection = inspections[inspections.length - 1];

  if (!lastInspection) {
    return fallbackTime;
  }

  const lastInspectionTime = dayjs(lastInspection.details!.scheduled_at).tz();

  const lastInspectionDuration = lastInspection.details!.scheduled_duration!;

  return lastInspectionTime
    .add(
      // the route duration is in seconds, so we need to convert it to minutes, and round it up to the nearest 5 minutes
      roundNumberUpToNearestInterval(route[route.length - 1].duration / 60, 5),
      'minutes'
    )
    .add(lastInspectionDuration, 'minute');
}
