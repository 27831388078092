import { useDialog } from '@rexlabs/dialog';
import React from 'react';
import ArrowBackIcon from 'view/components/icons/arrow-back';
import SplitIcon from 'view/components/icons/split';
import { AccessDeniedDialog } from 'view/components/dialogs/access-denied-dialog';
import { usePermission } from 'src/modules/roles/hooks/use-permission';
import { SplitBankTransactionDialog } from '../../dialogs/split-bank-transaction-dialog';
import { BatchReceiptingItem } from '../../types';
import { LabelWithIcon } from '../use-batch-receipting-button-config';

export function useGetSplitTransactionAction() {
  const { isStandardUser, roleName } = usePermission();

  const splitTransactionDialog = useDialog(SplitBankTransactionDialog);
  const accessDeniedDialog = useDialog(AccessDeniedDialog);

  return (item: BatchReceiptingItem) => {
    return {
      label: LabelWithIcon('Split transaction', <SplitIcon />),
      Icon: ArrowBackIcon,
      group: 'split-transaction',
      onClick: () => {
        if (isStandardUser) {
          return accessDeniedDialog.open({
            role: roleName
          });
        }

        splitTransactionDialog.open({
          batchReceiptingItem: item
        });
      }
    };
  };
}
