import React, { useMemo } from 'react';
import Icons from '@rexlabs/icons';
import { useSettings } from 'src/modules/settings/hooks/useSettings';
import { useSessionState } from 'src/modules/common/hooks/session-state';
import LoadingSpinner from '@rexlabs/loading-spinner';
import { useUserPortfolioMembers } from 'src/modules/portfolios/hooks/use-user-portfolio-memebers';
import { PortfolioRole } from 'src/modules/portfolios/models/portfolio-members-model';
import ArrowBranchIcon from 'view/components/icons/arrow-branch';
import { ChecklistItemWithId } from '../../common/blocks/create-checklists-block';
import { getDueDateTagLabel } from '../utils/get-due-date-tag-label';
import { generateSubtextForItem } from '../utils/generate-subtext-for-item';
import { getTagColorForDueDate } from '../utils/get-tag-color-for-due-date';
import { useGetOnChangeHandlerForItem } from '../hooks/use-get-on-change-handler-for-item';
import {
  ChecklistItemProps,
  ChecklistItemTag
} from '../components/checklist-item';
import { iconMap } from '../../settings/components/checklist-template-subtask-trigger';
import { ActionStyle } from '../components/checklist-action-toggle';
import { useSubtextOnClickHandlerForItem } from './use-get-subtext-on-click-handler-for-item';

export function useChecklistItemState(
  item: ChecklistItemWithId
): ChecklistItemProps {
  const { timezone: displayTimezone } = useSettings();
  const { contact } = useSessionState();
  const { data: portfolioMembers } = useUserPortfolioMembers();

  // If the user has a contact, we capture the ID so we can show 'Me' instead of the user's name
  const myId = contact?.id;
  const myPortfolioRoleIds =
    portfolioMembers?.map((member) => member.role.id) || [];

  const [submittingAt, setSubmittingAt] = React.useState<Date | null>(null);
  const [subtext, setSubtext] = React.useState<React.ReactNode | null>(null);
  const [showSpinner, setShowSpinner] = React.useState<boolean>(false);
  const onChange = useGetOnChangeHandlerForItem(item, setSubmittingAt);
  const subtextOnClickHandler = useSubtextOnClickHandlerForItem(item);

  const tags = generateChecklistItemTags(item, myId, myPortfolioRoleIds);

  React.useEffect(() => {
    // We only want to show the loading text on slowish connections, otherwise it makes the UI jump too much
    if (submittingAt) {
      // if it has been submitting for more than 100ms show loading text
      const timeout = setTimeout(() => {
        setSubtext(
          !item.completed_at ? 'Completing...' : 'Changing to incomplete...'
        );
        setShowSpinner(true);
      }, 100);
      return () => clearTimeout(timeout);
    } else {
      const text = generateSubtextForItem(
        item,
        subtextOnClickHandler,
        displayTimezone
      );
      setSubtext(text);
      setShowSpinner(false);
    }
    // Don't include the subtextOnClickHandler in the dependencies, as it causes a render loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [displayTimezone, item, submittingAt]);

  const blockingData = getBlockingData(item, myId, myPortfolioRoleIds);

  const ActionIcon = getActionIcon(item);

  const actionStyle = getActionStyle(item, ActionIcon);

  const disabled = useMemo(
    () => Object.values(blockingData).some((blocked) => blocked),
    [blockingData]
  );

  const tooltip = getTooltip(item, blockingData);

  const subtextIcon = getSubtextIcon(item);

  return React.useMemo(
    () => ({
      title: item.label,
      ActionIcon,
      actionStyle,
      onChange,
      checked: item.completed_at !== null,
      tags,
      subtext: subtext,
      SubtextIcon: showSpinner
        ? () => <LoadingSpinner size={10} strokeWidth={2} />
        : subtextIcon,
      hidePipe: subtext === null,
      disabled,
      item,
      tooltip
    }),
    [
      item,
      ActionIcon,
      actionStyle,
      onChange,
      tags,
      subtext,
      showSpinner,
      subtextIcon,
      disabled,
      tooltip
    ]
  );
}

type BlockingData = {
  isBlockedByItems: boolean;
  isBlockedByAssignee: boolean;
  isBlockedByPortfolioRole: boolean;
};
function generateChecklistItemTags(
  item: ChecklistItemWithId,
  myId: string | undefined,
  myPortfolioRoleIds: PortfolioRole[]
) {
  const assignedToTag = item.assigned_to
    ? {
        label:
          item.assigned_to.id === myId ? 'Me' : item.assigned_to.display_name,
        Icon: Icons.Person,
        type: item.assigned_to.id === myId ? 'good' : 'neutral'
      }
    : null;

  const assignedToRoleTag = item.assigned_to_portfolio_role
    ? {
        label: item.assigned_to_portfolio_role.label,
        Icon: Icons.Person,
        type: myPortfolioRoleIds.includes(item.assigned_to_portfolio_role.id)
          ? 'good'
          : 'neutral'
      }
    : null;

  const dueDateTag = item.due_date
    ? {
        label: getDueDateTagLabel(item),
        Icon: Icons.Calendar,
        type: getTagColorForDueDate(item.due_date)
      }
    : null;

  const tags = [dueDateTag, assignedToTag, assignedToRoleTag].filter(
    Boolean
  ) as ChecklistItemTag[];
  return tags;
}

function getBlockingData(
  item: ChecklistItemWithId,
  myId: string | undefined,
  myPortfolioRoleIds: string[]
): BlockingData {
  const isBlockedByItems = item.completed_at === null && item.is_blocked;

  const isBlockedByAssignee = !!(
    item.restricted_to_assignee &&
    item.assigned_to?.id &&
    item.assigned_to.id !== myId
  );
  const isBlockedByPortfolioRole = !!(
    item.restricted_to_assignee &&
    item.assigned_to_portfolio_role?.id &&
    !myPortfolioRoleIds.includes(item.assigned_to_portfolio_role.id)
  );

  return { isBlockedByItems, isBlockedByAssignee, isBlockedByPortfolioRole };
}

function getTooltip(item: ChecklistItemWithId, blockingData: BlockingData) {
  const {
    isBlockedByAssignee,
    isBlockedByItems,
    isBlockedByPortfolioRole
  } = blockingData;

  return isBlockedByAssignee || isBlockedByPortfolioRole
    ? 'Only the assignee can complete this subtask'
    : isBlockedByItems
    ? 'Previous subtasks must be completed'
    : undefined;
}

function getActionIcon(item: ChecklistItemWithId) {
  if (item.has_outcome) {
    return () => <ArrowBranchIcon size='s' color='#C3E8D4' />;
  }

  if (item.triggered_at) {
    // if they complete and then un-complete the item, it is no longer a shortcut
    return undefined;
  }

  return item.trigger_type?.id === 'on_completion' && !!item.action_type?.id
    ? iconMap[item.action_type?.id]
    : item.trigger_type?.id === 'on_due_date'
    ? Icons.Calendar
    : undefined;
}

function getActionStyle(item, ActionIcon): ActionStyle | undefined {
  if (ActionIcon) {
    if (item.has_outcome) {
      return 'solid';
    }

    if (item.trigger_type?.id === 'on_completion') {
      return 'primary';
    } else if (item.trigger_type?.id === 'on_due_date') {
      return 'dotted';
    }
  }
  return undefined;
}

function getSubtextIcon(item: ChecklistItemWithId) {
  if (item.has_outcome) {
    return ArrowBranchIcon;
  }

  // TODO: can support other icons here

  return undefined;
}
