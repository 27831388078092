import React from 'react';

import Box from '@rexlabs/box';
import { Field } from '@rexlabs/form';
import { TextArea } from '@rexlabs/text-input';

import { Grid } from 'view/components/@luna/form/grid';
import { Value } from 'view/components/values';
import { BlockConfig } from 'view/components/record-screen/types';
import { ImagePreview } from 'view/components/image-preview';
import { PreviewFileUploadInputWithErrorHandling } from 'view/components/inputs/file-upload-input/preview-file-upload-input-with-error-handling';
import { InspectionTask } from '../types/InspectionTask';
import { InspectionFormData } from '../types/InspectionFormData';
import { RoomConditionButtonGroup } from '../components/room-condition-button-group';
import { InspectionReportRoomTitle } from '../components/inspection-report-room-title';

/**
 * @deprecated We are changing inspection reports to use InspectionAreas rather than Rooms
 * Delete this component when we turn the flag on in prod.
 * */
export function getInspectionReportRoomBlocks(
  data: InspectionTask
): BlockConfig<InspectionTask>[] {
  const inspectionReportRoomBlocks: BlockConfig<InspectionTask>[] = (
    data?.details?.rooms?.data || []
  ).map((room) => ({
    ...getInspectionReportRoomBlock(room.id),
    title: () => <InspectionReportRoomTitle room={room} />
  }));

  return inspectionReportRoomBlocks;
}

export function getInspectionReportRoomBlock(
  roomId: string
): BlockConfig<InspectionTask, any, InspectionFormData> {
  return {
    id: `inspection-report-room-${roomId}`,
    isMobileForcedCollapsed: true,
    validate: {
      definitions: {
        [`rooms.${roomId}.condition`]: {
          name: 'condition',
          rules: 'required'
        }
      }
    },
    isEditable: (data) =>
      !['report_completed', 'report_sent'].includes(
        data?.details?.status?.id || ''
      ),
    View: ({ data }) => {
      const room = data!.details!.rooms!.data.find(
        (room) => room.id === roomId
      );

      const images = room?.images?.data || [];

      return (
        <Grid columns={1}>
          <Value label='Condition' value={room?.room_condition?.label} />

          <Value label='Comments' value={room?.notes} />
          <Value
            label='Images'
            value={
              images.length ? (
                <>
                  <Box spacing='.8rem' flexDirection='row' flexWrap='wrap'>
                    {images!.map((image, index) => {
                      return (
                        <ImagePreview
                          src={image?.file.url}
                          key={image?.file.id || index}
                          downloadUrl={image?.file.download_url}
                        />
                      );
                    })}
                  </Box>
                </>
              ) : null
            }
          />
        </Grid>
      );
    },
    Edit: () => {
      return (
        <Grid columns={1}>
          <Field
            id={`rooms.${roomId}.condition`}
            name={`rooms.${roomId}.condition`}
            label='Condition'
            Input={RoomConditionButtonGroup}
            optional={false}
          />
          <Field
            id={`rooms.${roomId}.comments`}
            name={`rooms.${roomId}.comments`}
            label='Comments'
            Input={TextArea}
          />
          <Field<typeof PreviewFileUploadInputWithErrorHandling>
            id={`rooms.${roomId}.images`}
            name={`rooms.${roomId}.images`}
            label='Images'
            Input={PreviewFileUploadInputWithErrorHandling}
            inputProps={{
              multi: true,
              accept: '.jpeg, .png, .jpg'
            }}
          />
        </Grid>
      );
    }
  };
}
