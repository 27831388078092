import React from 'react';
import { StatusGoodTag, StatusAverageTag, StatusBadTag } from '@rexlabs/tag';
import { Invoice } from 'data/models/entities/financials/invoices';

export class InvalidStatusError extends Error {
  constructor(statusID: never) {
    super(`Invalid invoice status: ${statusID}`);
  }
}

export interface InvoiceStatusTagProps {
  status: Invoice['status'];
}

export function InvoiceStatusTag({
  status: { id, label }
}: InvoiceStatusTagProps) {
  if (id === 'paid') {
    return <StatusGoodTag>{label}</StatusGoodTag>;
  }

  if (id === 'void') {
    return <StatusBadTag>{label}</StatusBadTag>;
  }

  if (
    id === 'draft' ||
    id === 'awaiting_approval' ||
    id === 'awaiting_payment'
  ) {
    return <StatusAverageTag>{label}</StatusAverageTag>;
  }

  throw new InvalidStatusError(id);
}
