import { useToast } from 'view/components/@luna/notifications/toast';
import { useErrorDialog } from '@rexlabs/dialog';
import { SingleActionDeclaration } from 'src/modules/common/actions/types/action-declaration-types';
import dayjs from 'dayjs';

export type UseModelDownloadActionArgs = {
  fetcher: any;
  label: string;
  toastArgs?: {
    title?: string;
    description?: string;
    color?: string;
  };
} & SingleActionDeclaration;

export const useBulkModelDownloadAction = () => {
  const { addToast } = useToast();
  const { open } = useErrorDialog();

  return function ({
    fetcher,
    label,
    toastArgs,
    id = 'bulk-download',
    group = 'bulk-download',
    ...props
  }: UseModelDownloadActionArgs): SingleActionDeclaration {
    return {
      ...props,
      id,
      group,
      label,
      handleAction: async () => {
        addToast({
          title: toastArgs?.title,
          description:
            toastArgs?.description ?? 'Your zip file is being generated',
          color: toastArgs?.color ?? 'information'
        });
        try {
          const report = await fetcher();
          const url = report.data.link;
          const link = document.createElement('a');
          link.setAttribute('href', url);
          link.setAttribute('download', `${dayjs().toISOString()}.zip`);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        } catch (e) {
          console.error(e);
          open(e);
        }
      }
    };
  };
};
