import Validator from '@rexlabs/validator';
import { Invoice } from 'data/models/entities/financials/invoices';
import { InvoiceStatus } from 'utils/static-value-lists/invoice-status';
import { AmountValue } from 'view/components/inputs/credit-amount-split-input';

function getInvoiceStatusErrorMessage(
  status: Exclude<InvoiceStatus, 'awaiting_payment'>
): string {
  switch (status) {
    case 'paid':
      return 'The invoice you have chosen is paid, you cannot issue a credit note to an invoice that has already been paid.';
    case 'awaiting_approval':
      return 'The invoice you have chosen is awaiting approval, to issue a credit note you must change the invoice to "Awaiting payment".';
    case 'void':
      return 'The invoice you have chosen is void, you cannot issue a credit note to an invoice that has been voided.';
    case 'draft':
      return 'The invoice you have chosen is in "Draft", to issue a credit note you must change the invoice to "Awaiting payment".';
  }
}

function invoiceStatusValidationCallback(
  status: Exclude<InvoiceStatus, 'awaiting_payment'>
) {
  return function (this: any, value: Invoice) {
    if (!value) return true;

    const invoiceStatus = value.status.id;
    if (invoiceStatus === status) return false;

    return true;
  };
}

Validator.register(
  'invoiceStatusIsNotDraft',
  invoiceStatusValidationCallback('draft'),
  getInvoiceStatusErrorMessage('draft')
);

Validator.register(
  'invoiceStatusIsNotPaid',
  invoiceStatusValidationCallback('paid'),
  getInvoiceStatusErrorMessage('paid')
);

Validator.register(
  'invoiceStatusIsNotVoid',
  invoiceStatusValidationCallback('void'),
  getInvoiceStatusErrorMessage('void')
);

Validator.register(
  'invoiceStatusIsNotAwaitingApproval',
  invoiceStatusValidationCallback('awaiting_approval'),
  getInvoiceStatusErrorMessage('awaiting_approval')
);

Validator.register(
  'creditAmountLessThanOrEqualToBalance',
  function (this: any, value: AmountValue) {
    const amountType = value.type?.id;
    const amountValue = value.value;

    const invoice = this.validator.input.invoice;
    if (!invoice) return true;

    const balance = invoice.amount_owing;
    const total = invoice.amount_total_inc_tax;

    if (!amountValue || !amountType) return true;

    switch (amountType) {
      case 'percent_balance':
        return (amountValue / 100) * balance <= balance;
      case 'percent_total':
        return (amountValue / 100) * total <= balance;
      case 'fixed_amount':
        return amountValue <= balance;
      default:
        return true;
    }
  },
  'Credit amount must be less than the remaining balance'
);

Validator.registerImplicit(
  'requiredInTemplate',
  function (this: any, value: any, fieldId: string) {
    const template = this.validator.input.template;
    if (!template) return true;

    const requiredFields = template.inputs;
    const required = requiredFields.some((field) => field.id === fieldId);

    if (required) {
      return !!value;
    }

    return true;
  },
  'The :attribute field is required'
);
