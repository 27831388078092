import { ActionMenuItem } from '@rexlabs/action-menu';
import { transformActionDeclarationsToActionMenuItems } from 'utils/actions/transforms';
import { useGetDownloadLetterAction } from '../action-declarations/use-get-download-letter-action';
import { MessageBatch } from '../../types/MessageBatch';

export function useGetLetterReadyActions() {
  const getDownloadLetterAction = useGetDownloadLetterAction();

  return (messageBatch?: MessageBatch): ActionMenuItem[] => {
    if (!messageBatch) return [];

    const result = [getDownloadLetterAction(messageBatch)];

    return transformActionDeclarationsToActionMenuItems(result);
  };
}
