import React from 'react';
import invariant from 'invariant';

import { Field } from '@rexlabs/form';

import { BlockConfig } from 'view/components/record-screen/types';
import { Grid } from 'view/components/@luna/form/grid';
import { ChecklistTemplateSelect } from 'view/components/inputs/selects/v4-selects/checklist-template-select';
import { FLAGS } from 'utils/feature-flags';

import { Quri } from '@rexlabs/quri';
import { useFeatureFlags } from 'view/components/@luna/feature-flags';
import { ChecklistTemplate } from '../../settings/types/ChecklistTemplate';
import { Task } from '../types/Task';
import { TaskType } from '../types/TaskType';
import { InspectionType } from '../../inspections/types/InspectionType';

export type ChecklistTemplateSelectBlockFormValues = {
  checklists?: ChecklistTemplate[];
  inspection_type?: string;
};

/**
 * NOTE: please note, you MUST provide a taskTypeId through the blockProps for this block to work
 */
export const checklistTemplateSelectBlock: BlockConfig<
  Task<TaskType>,
  { taskTypeId: TaskType; inspectionType?: InspectionType },
  ChecklistTemplateSelectBlockFormValues
> = {
  id: 'checklist',
  title: 'Checklist',
  Edit: ({ blockProps, forms }) => {
    const { hasFeature } = useFeatureFlags();

    invariant(
      blockProps?.taskTypeId,
      'You must provide a taskTypeId to the checklist block'
    );

    const taskTypeId = blockProps.taskTypeId;
    const isInspection = taskTypeId === 'task_inspection';
    const inspectionType =
      blockProps?.inspectionType || forms?.['details']?.values?.inspection_type;

    let inspectionFilters: Quri[] = [];

    if (isInspection && inspectionType != null) {
      inspectionFilters = [
        {
          field: 'discriminator_field',
          op: 'in',
          value: ['details.type_id']
        },
        {
          op: 'or',
          value: [
            {
              field: 'discriminator_value',
              op: 'eq',
              value: inspectionType
            },
            {
              field: 'discriminator_value',
              op: 'eq',
              value: 'null'
            }
          ]
        }
      ];
    }

    return (
      <Grid columns={2}>
        <Field<typeof ChecklistTemplateSelect>
          Input={ChecklistTemplateSelect}
          id='checklists'
          name='checklists'
          label='Select checklist(s)'
          optional={true}
          inputProps={{
            dialogProps: {
              taskTypeId: blockProps!.taskTypeId
            },
            disableFixture: hasFeature(FLAGS.SMART_CHECKLISTS_SETTINGS)
              ? true
              : false,
            multi: true,
            searchFilter: [
              {
                field: 'task_type_id',
                op: 'eq',
                value: taskTypeId
              },
              {
                field: 'is_default',
                op: 'neq',
                value: 'true'
              },
              ...inspectionFilters
            ]
          }}
        />
      </Grid>
    );
  }
};
