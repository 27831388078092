import { ActionDeclaration } from 'src/modules/common/actions/types/action-declaration-types';
import { FieldArrayPassthroughProps } from '@rexlabs/form';
import { TaskType } from 'src/modules/tasks/common/types/TaskType';
import { useEditTriggerAction } from '../action-declarations/checklist-item-trigger/use-edit-trigger-action';
import { useDeleteTriggerAction } from '../action-declarations/checklist-item-trigger/use-delete-trigger-action';
import { ChecklistTemplateItem } from '../../types/ChecklistTemplate';

export function useTriggerActions({
  index,
  fieldArrayProps,
  checklistTemplateItem,
  taskType
}: {
  index: number;
  fieldArrayProps: FieldArrayPassthroughProps;
  checklistTemplateItem: ChecklistTemplateItem;
  taskType: TaskType;
}): ActionDeclaration[] {
  const editTriggerAction = useEditTriggerAction({
    checklistTemplateItem,
    fieldArrayProps,
    index,
    taskType
  });
  const deleteTriggerAction = useDeleteTriggerAction({
    index,
    fieldArrayProps,
    checklistTemplateItem
  });

  return [editTriggerAction, deleteTriggerAction()];
}
