import React from 'react';

import { Field } from '@rexlabs/form';

import { BlockConfig } from 'view/components/record-screen/types';
import { Grid } from 'view/components/@luna/form/grid';
import 'src/modules/tasks/settings/utils/checklist-item-validation-rules.ts';

import { TextInput } from '@rexlabs/text-input';
import { ContactSelect } from 'view/components/inputs/selects/contact';
import { ChecklistItemDueDateInput } from 'view/components/inputs/checklist-item-due-date-input/checklist-item-due-date-input';
import { Task } from '../types/Task';
import { TaskFormData } from '../types/TaskFormData';
import { TaskType } from '../types/TaskType';

const validate = {
  definitions: {
    label: { rules: 'required' },
    subtask_due_date: {
      rules: 'required_if_true:isSmartChecklist|fullDueDateIsRequired'
    }
  },
  messages: {
    required_if: 'The :attribute field is required.'
  }
};

export const subtaskFieldsBlock: BlockConfig<
  Task<any>,
  { fieldNamePrefix: string; taskType: TaskType; isSmartChecklist: boolean },
  TaskFormData
> = {
  id: 'subtaskFields',
  title: 'Details',
  validate,
  Edit: ({ blockProps }) => {
    const isSmartChecklist = blockProps!.isSmartChecklist;

    return (
      <Grid columns={1}>
        <Field name='label' label='Subtask name' Input={TextInput} />

        <Field
          id='assigned_to'
          name='assigned_to'
          label='Assigned to'
          Input={ContactSelect}
          inputProps={{ disableFixture: true }}
        />

        {isSmartChecklist ? (
          <Field
            name='subtask_due_date'
            label='Subtask due date'
            Input={ChecklistItemDueDateInput}
            inputProps={{
              taskType: blockProps!.taskType,
              allowSpecificDate: true
            }}
            optional={false}
          />
        ) : null}
      </Grid>
    );
  }
};
