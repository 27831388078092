import React, { useEffect } from 'react';
import { BlockConfig } from 'view/components/record-screen/types';
import Box from '@rexlabs/box';
import { Field, FieldArray } from '@rexlabs/form';
import { TextInput } from '@rexlabs/text-input';
import { DateValue } from 'view/components/values/date';
import { get } from 'lodash';
import { Value } from 'view/components/values';
import { CurrencyValue } from 'view/components/values/currency';
import { SingleLineFormLayout } from 'view/components/layouts/form/single-line-form-layout';
import { VerticalFormLayout } from 'view/components/layouts/form/vertical-form-layout';
import { RadioGroupInput } from '@rexlabs/radio-input';
import { RecordLinkValue } from 'view/components/values/record-link';
import { BankWithdrawal } from 'src/modules/banking/bank-withdrawals/types/bank-withdrawal-types';
import { LineItemRemoveButton } from 'view/components/line-items/line-item-remove-button';
import { TrustJournalEntry } from 'src/modules/trust-journal-entries/types/trust-journal-entry-types';
import { getSequentialChequeNumbers } from '../utils/get-sequential-cheque-numbers';

export type TrustJournalEntryWithChequeNumbers = TrustJournalEntry & {
  cheque_number: string;
};

const CHEQUE_NUMBER_FIELD = 'cheque_number';

export const chequeBankWithdrawalDetailsBlock: BlockConfig<BankWithdrawal> = {
  id: 'chequeBankWithdrawalDetails',
  validate: {
    definitions: {
      first_cheque_number: {
        name: 'first cheque number',
        rules: 'required_with:automatic_cheque_numbering'
      },
      automatic_cheque_numbering: {
        name: 'automatic cheque numbering',
        rules: 'required'
      },
      'line_items.*.cheque_number': {
        name: 'cheque number',
        rules: 'required_without:automatic_cheque_numbering'
      }
    },
    messages: {
      required_with: 'The :attribute field is required when :field is enabled.',
      required_without:
        'The :attribute field is required when :field is disabled.'
    }
  },
  Edit: function EditChequeWithdrawalDetails({ values, setFieldValue }) {
    useEffect(() => {
      const firstChequeNumber = values['first_cheque_number'];
      if (!firstChequeNumber) return;

      const chequeNumberSequence = getSequentialChequeNumbers(
        firstChequeNumber
      );

      const newLineItems = values.line_items.map((lineItem) => ({
        ...lineItem,
        [CHEQUE_NUMBER_FIELD]: chequeNumberSequence.next().value
      }));

      setFieldValue?.('line_items', newLineItems);
    }, [values['first_cheque_number']]);
    return (
      <VerticalFormLayout>
        <Box flexDirection='row'>
          <Field
            label='Automatic cheque numbering'
            name='automatic_cheque_numbering'
            Input={RadioGroupInput}
            inputProps={{
              options: [
                { label: 'Enable', value: true },
                { label: 'Disable', value: false }
              ],
              orientation: 'horizontal'
            }}
          />

          <Field
            label='First cheque number'
            name='first_cheque_number'
            Input={TextInput}
            optional={false}
            inputProps={{
              disabled: !values['automatic_cheque_numbering']
            }}
          />
        </Box>

        <FieldArray name='line_items'>
          {function LineItem({ fields }) {
            return (
              <>
                {fields.map(({ field, actions }) => (
                  <SingleLineFormLayout key={field.name}>
                    {!values['automatic_cheque_numbering'] && (
                      <Field
                        name={`${field.name}.${CHEQUE_NUMBER_FIELD}`}
                        label='Cheque number'
                        optional={false}
                        Input={TextInput}
                      />
                    )}
                    {values['automatic_cheque_numbering'] && (
                      <Value
                        label='Cheque number'
                        value={get(
                          values,
                          `${field.name}.${CHEQUE_NUMBER_FIELD}`
                        )}
                      />
                    )}
                    <DateValue
                      label='Date of'
                      value={get(values, `${field.name}.date_of`)}
                    />
                    <Value
                      label='Audit #'
                      value={get(values, `${field.name}.audit_number`)}
                    />
                    <RecordLinkValue
                      label='Payee'
                      record={{
                        type: 'contact',
                        object: get(values, `${field.name}.contact`)
                      }}
                    />
                    <CurrencyValue
                      label='Amount'
                      value={get(values, `${field.name}.amount`)}
                    />
                    <Box flex='0 0 auto' alignSelf='center'>
                      <LineItemRemoveButton onClick={actions.remove} />
                    </Box>
                  </SingleLineFormLayout>
                ))}
              </>
            );
          }}
        </FieldArray>
      </VerticalFormLayout>
    );
  }
};
