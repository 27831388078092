import React from 'react';

import Box from '@rexlabs/box';
import { TagCell } from '@rexlabs/table';
import { useDialog } from '@rexlabs/dialog';

import ROUTES from 'routes/app';
import { BlockConfig } from 'view/components/record-screen/types';
import { RecordTable, Columns } from 'view/components/table';
import { EmptyState } from 'view/components/states/compact/empty';
import { BREADCRUMBS } from 'view/components/@luna/breadcrumbs';
import { ActionButtons } from 'view/components/action-buttons';
import { InvoiceStatusTag } from 'view/components/tags/invoice-status';

import { CreateReimbursementInvoiceDialog } from '../dialogs/create-reimbursement-invoice-dialog';

const ReimbursementInvoicesEmptyState = () => (
  <EmptyState>No reimbursements created yet.</EmptyState>
);

const columns: Columns = [
  {
    id: 'reference',
    Header: 'Invoice #'
  },
  {
    id: 'payable_by.object',
    type: 'record',
    Header: 'Payable by'
  },
  {
    id: 'payable_to.object',
    type: 'record',
    Header: 'Payable to'
  },
  {
    id: 'due_date',
    type: 'date',
    Header: 'Due date'
  },
  {
    id: 'status',
    width: 128,
    Header: 'Status',
    Cell: (table) => {
      const {
        cell: { value }
      } = table;
      return (
        <TagCell>
          <InvoiceStatusTag status={value} />
        </TagCell>
      );
    }
  },
  {
    id: 'amount_total_inc_tax',
    type: 'currency',
    cellProps: { align: 'right' },
    Header: 'Amount (incl. tax)'
  }
];

function getRowLinkProps({ item }) {
  return {
    to: ROUTES.INVOICE,
    params: { invoiceId: item.id },
    addBreadcrumb: {
      type: BREADCRUMBS.INVOICE,
      id: item.id,
      relation: 'Reimbursement Invoice'
    }
  };
}

export const reimbursementInvoicesBlock: BlockConfig = {
  id: 'reimbursement-invoices',
  title: 'Reimbursement invoices',
  View: ({ data, isLoading }) => {
    return (
      <Box mt='.8rem'>
        <RecordTable
          columns={columns}
          items={data?.reimbursed_by_invoices?.data}
          isLoading={isLoading}
          getRowLinkProps={getRowLinkProps}
          Empty={ReimbursementInvoicesEmptyState}
        />
      </Box>
    );
  },
  Actions: ({ data }) => {
    const { open } = useDialog(CreateReimbursementInvoiceDialog);

    return (
      <ActionButtons
        actions={[
          {
            label: 'Create reimbursement invoice',
            onClick: () =>
              open({
                reimbursedInvoice: data
              })
          }
        ]}
      />
    );
  }
};
