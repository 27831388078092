function getHasUserManuallyChanged(disbursementValues) {
  // Easiest way to figure out if the user has touched the split amount
  // is just to check if the value has changed from our automatic calculation
  const prevSplit = 100 / disbursementValues?.length;
  return disbursementValues.find(
    (disbursement) =>
      disbursement.split_amount.amount !== prevSplit ||
      disbursement.split_amount.type.id !== 'percent'
  );
}

// When updating owners in the ownership create dialog, we are automatically
// setting disbursement methods for each one. This function will return the
// new disbursement method values based on the new owner.
export function getNewDisbursements({
  newContact,
  prevContact,
  prevDisbursements
}) {
  const disbursementsWithoutPrevContact = prevDisbursements.filter(
    (disbursement) => disbursement?.contact?.id !== prevContact?.id
  );

  const newDisbursementsValue = [
    ...(disbursementsWithoutPrevContact || []),
    {
      contact: newContact,
      split_amount: {
        type: { id: 'percent', label: 'Percent' }
      }
    }
  ];

  if (getHasUserManuallyChanged(disbursementsWithoutPrevContact)) {
    return newDisbursementsValue;
  }

  // If the user hasn't adjusted the split amount on the other owners
  // then we're good to update it to keep an equal split for all owners
  const equalSplit = 100 / newDisbursementsValue.length;
  return newDisbursementsValue.map((disbursement) => ({
    ...disbursement,
    split_amount: {
      ...disbursement.split_amount,
      amount: equalSplit
    }
  }));
}
