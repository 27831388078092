import React from 'react';
import { ConfirmationDialog } from '@rexlabs/dialog';

interface EmptyRentScheduleWarningProps {
  onConfirm: () => void;
  onClose?: () => void;
  onReject?: () => void;
}

export function EmptyRentScheduleWarning({
  onConfirm,
  onClose,
  onReject
}: EmptyRentScheduleWarningProps) {
  return (
    <ConfirmationDialog
      onClose={() => {
        onReject?.();
        onClose?.();
      }}
      onConfirm={onConfirm}
      confirmText='Save & continue'
      title='No schedule item'
      message={
        <>
          A current schedule item is required in order to start charging rent.
          The information you have provided so far will be saved for next time.
        </>
      }
    />
  );
}
