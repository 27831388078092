import * as React from 'react';
import { ValueListItem } from 'utils/normaliser/value-list-item';
import { Dropdown, DropdownProps } from 'view/components/@luna/dropdown';
import { valueListFactory } from 'src/lib/testing/factories/value-list-factory';

const inspectionDuration = [5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60];

export type InspectionDuration = number;

const items = inspectionDuration.map((duration) =>
  valueListFactory(duration.toString())
);

type InspectionDurationProps = Omit<
  DropdownProps<ValueListItem>,
  'normaliser' | 'items'
>;

export function InspectionDurationMenu(props: InspectionDurationProps) {
  return (
    <Dropdown<ValueListItem>
      items={items}
      normaliser={({ id, label }) => ({ id, label: `${label} min` })}
      {...props}
    />
  );
}
