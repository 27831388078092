import { Statement } from 'src/modules/statements/types/index';
import { Ownership } from 'data/models/entities/ownerships';
import Box from '@rexlabs/box';
import { Body } from '@rexlabs/text';
import * as React from 'react';
import { useToken } from '@rexlabs/styling';
import { OutlineSplitButton } from '@rexlabs/button';
import { determineColoredTagFromStatement } from 'src/modules/ownership-statements/common/utils/determine-colored-tag-from-statement';

export function PreviewStatementsHeader<
  ViewModes extends { id: string; label: string }[]
>({
  statement,
  ownership,
  viewMode,
  viewModes,
  setViewMode
}: {
  statement: Statement | undefined;
  ownership: Ownership;
  viewMode: string;
  viewModes: ViewModes;
  setViewMode: (viewMode: string) => void;
}) {
  const token = useToken();
  const tag = determineColoredTagFromStatement(statement);

  return (
    <Box
      display={'flex'}
      flexDirection={'row'}
      justifyContent={'space-between'}
      flex={1}
    >
      <Box display={'flex'} flexDirection='column' spacing={token('spacing.s')}>
        <div>
          <Body grey as='span'>
            Ownership:{' '}
          </Body>
          <Body as='span'>{ownership?.display_name}</Body>
        </div>
        {tag && <Box alignSelf={'flex-start'}>{tag}</Box>}
      </Box>
      <Box flexDirection={'column'} justifyContent={'center'}>
        <OutlineSplitButton
          ml={'auto'}
          Left={({ Button }) => (
            <Button
              isSelected={viewMode === viewModes[0].id}
              onClick={() => setViewMode(viewModes[0].id)}
            >
              {viewModes[0].label}
            </Button>
          )}
          Right={({ Button }) => (
            <Button
              isSelected={viewMode === viewModes[1].id}
              onClick={() => setViewMode(viewModes[1].id)}
            >
              {viewModes[1].label}
            </Button>
          )}
        />
      </Box>
    </Box>
  );
}
