import { useTrustLedger } from 'src/modules/financials/utils/use-trust-ledger';
import { useFeatureFlags } from 'view/components/@luna/feature-flags';
import { useEffect } from 'react';
import { FLAGS } from 'utils/feature-flags';
import { SearchResultItem } from 'utils/api/get-search-results';
import { FinancialRecord } from 'utils/financials/financial-objects';
import { BankAccount } from 'data/models/entities/financials/bank-accounts';
import { TenancyPrepaymentBucket } from 'src/modules/prepayments/types/tenancy-prepayment-bucket';

interface UseSetAvailableAmountProps {
  values: {
    source?: {
      ledger?: {
        object?: SearchResultItem<FinancialRecord>;
      };
    };
    bank_account?: BankAccount;
    prepayment_bucket?: TenancyPrepaymentBucket;
    available_amount?: number;
  };
  setFieldValue?: (field: string, value: any) => void;
}

// This hook is used to set the available amount for a transfer funds form
//  - either to the amount in the folio, or in the case of tenancies, to the amount in the currently selected bucket
export function useSetAvailableAmount({
  values,
  setFieldValue
}: UseSetAvailableAmountProps) {
  const folio = values.source?.ledger?.object ?? undefined;
  const bankAccount = values.bank_account ?? undefined;
  const { trustLedger } = useTrustLedger(folio?.record, bankAccount);
  const { hasFeature } = useFeatureFlags();

  const bucketAmount = values.prepayment_bucket?.amount || 0;

  useEffect(() => {
    const currentValue = values.available_amount;
    const availableAmount =
      folio?.type?.id === 'tenancy' && hasFeature(FLAGS.PREPAYMENTS)
        ? bucketAmount || 0
        : trustLedger?.financial_summary?.available_balance_amount || 0;

    if (currentValue !== availableAmount) {
      setFieldValue?.('available_amount', availableAmount);
    }
  }, [trustLedger, bucketAmount]);
}
