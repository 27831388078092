import * as React from 'react';
import { Value } from 'view/components/values';
import { Grid } from 'view/components/@luna/form/grid';
import Box from '@rexlabs/box';
import { StyleSheet, useStyles, useToken } from '@rexlabs/styling';
import FileIcon from 'view/components/icons/file';
import { Heading } from '@rexlabs/text';
import { LinkButton } from '@rexlabs/button';
import { downloadFile } from 'src/modules/reporting/utils/download-file';

const styles = StyleSheet({
  root: {
    borderRadius: ({ token }) => token('border.radius.l'),
    padding: ({ token }) => token('spacing.xl'),
    gap: ({ token }) => token('spacing.m'),
    background: ({ token }) => token('color.container.static.contrast'),
    display: 'flex',
    flexDirection: 'column'
  }
});

interface TransactionFileDetailsProps {
  description?: string;
  reference?: string;
  fileName?: string;
  fileDownloadUrl?: string;
}

export function TransactionFileDetails(props: TransactionFileDetailsProps) {
  const { description, reference, fileName, fileDownloadUrl } = props;
  const s = useStyles(styles);
  const token = useToken();

  return (
    <Box {...s('root')}>
      <Heading level={4}>Transaction file details</Heading>
      <Grid columns={3}>
        <Value label='Description' value={description} />
        <Value label='Reference' value={reference} />
        <Value
          label='Included in transaction file'
          value={
            fileName ? (
              <LinkButton
                onClick={() => {
                  downloadFile(fileDownloadUrl, fileName);
                }}
              >
                <Box
                  flexDirection='row'
                  alignItems='center'
                  gap={token('spacing.xs')}
                >
                  <FileIcon />
                  <Box>{fileName}</Box>
                </Box>
              </LinkButton>
            ) : null
          }
        />
      </Grid>
    </Box>
  );
}
