import React, { FC } from 'react';
import { InputProps } from '@rexlabs/form';

import { Dropdown } from '@rexlabs/select-old';
import { normaliseTaxType } from 'utils/normaliser/tax-type';
import { useTaxTypes } from 'view/hooks/api/use-tax-types';

export const TaxTypeSelect: FC<InputProps> = (props) => {
  const { loadItems } = useTaxTypes();

  return (
    <Dropdown items={loadItems} normaliser={normaliseTaxType} {...props} />
  );
};
