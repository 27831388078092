import { PropertyDetails } from '../types/PropertyDetails';

export function mapPropertyDetailsFormDataToRequest(
  formData: Partial<PropertyDetails>
): Partial<PropertyDetails> {
  const {
    communication_data,
    gas_data,
    maintenance_data,
    water_data,
    health_issues_data
  } = formData;

  return {
    communication_data,
    maintenance_data,
    ...{
      water_data: water_data?.is_individually_metered
        ? water_data
        : {
            is_individually_metered: false,
            is_water_efficient: false,
            meter_readings: null
          }
    },
    ...{
      gas_data: gas_data?.gas_is_supplied
        ? gas_data
        : {
            gas_is_supplied: false,
            meter_readings: null,
            meter_unit: { id: 'cubic_meter', label: 'm3' }
          }
    },
    health_issues_data
  };
}
