import React from 'react';

import { StylesProvider } from '@rexlabs/styling';
import { OutlineButton } from '@rexlabs/button';

import { SingleActionDeclaration } from 'src/modules/common/actions/types/action-declaration-types';

export function ActionButtonCell({
  action
}: {
  action: SingleActionDeclaration;
}) {
  const styleOverrides = {
    button: {
      width: '100%'
    }
  };

  return (
    <StylesProvider tokens={styleOverrides}>
      <OutlineButton
        size='s'
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          action.handleAction?.();
        }}
      >
        {action.label}
      </OutlineButton>
    </StylesProvider>
  );
}
