import React from 'react';
import { useTranslation } from 'react-i18next';

import { ErrorBanner } from '@rexlabs/notifications';
import { useWhereabouts, push } from '@rexlabs/whereabouts';

import InfoCircleIcon from 'view/components/icons/info';

export function DisbursementMethodMissingBanner({
  disbursementType
}: {
  disbursementType: string;
}) {
  const { t } = useTranslation();

  const { path } = useWhereabouts();

  return (
    <ErrorBanner
      Icon={InfoCircleIcon}
      description={t(
        'disbursements.disbursement-method-is-missing-banner-description',
        {
          disbursementType
        }
      )}
      actions={[
        {
          label: t('disbursements.add-method'),
          onClick: () =>
            push({
              config: {
                path,
                query: {
                  tab: 'disbursement-method'
                }
              }
            })
        }
      ]}
      data-testid='disbursement-method-missing-banner'
    />
  );
}
