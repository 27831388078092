import React from 'react';
import { InputProps } from '@rexlabs/form';
import { ValueListSelect } from 'view/components/table/toolbar/inputs/value-list-select';
import Box from '@rexlabs/box';
import { useToken, StyleSheet, useStyles, text } from '@rexlabs/styling';
import { Small } from '@rexlabs/text';
import { ValueListValue } from 'data/models/types';
import { ChecklistItemTriggerType } from 'src/modules/tasks/settings/types/ChecklistTemplate';
import { HourSelect } from '../hour-select';

export type TriggerValue = {
  trigger_type: ValueListValue<ChecklistItemTriggerType> | null;
  time: string | null;
};

interface TriggerInputProps
  extends Omit<InputProps, 'value' | 'onChange' | 'onBlur'> {
  value?: TriggerValue;
  onChange?: (e: { target: { value: TriggerValue } }) => void;
  onBlur?: (e: { target: { value: TriggerValue } }) => void;
}

const defaultTriggerValue = {
  trigger_type: null,
  time: null
};

const defaultStyles = StyleSheet({
  text: {
    ...text.styles({
      color: ({ token }) => token('color.textStyle.body.subtext')
    })
  }
});

export function ChecklistItemTriggerInput({
  value,
  onChange,
  onBlur,
  ...props
}: TriggerInputProps) {
  const token = useToken();
  const s = useStyles(defaultStyles);

  const handleChange = (newValue: Partial<TriggerValue>) => {
    onChange?.({
      target: {
        value: {
          ...(value || defaultTriggerValue),
          ...newValue
        }
      }
    });
  };

  const handleBlur = (newValue: Partial<TriggerValue>) => {
    onBlur?.({
      target: {
        value: {
          ...(value || defaultTriggerValue),
          ...newValue
        }
      }
    });
  };

  const handleTriggerTypeChange = ({ target }) => {
    handleChange({
      trigger_type: target.value
    });
  };

  const handleTriggerTypeBlur = ({ target }) =>
    handleBlur({
      trigger_type: target.value
    });

  const handleTimeChange = ({ target }) =>
    handleChange({
      time: target.value
    });

  const handleTimeBlur = ({ target }) =>
    handleBlur({
      time: target.value
    });

  return (
    <>
      <Box
        width={'auto'}
        display='flex'
        flexDirection={'row'}
        columnGap={token('spacing.xs')}
      >
        <ValueListSelect
          {...props}
          id='trigger_type'
          type='checklist_item_trigger_type'
          searchable={false}
          placeholder='Select a trigger'
          onChange={handleTriggerTypeChange}
          onBlur={handleTriggerTypeBlur}
          value={value?.trigger_type}
        ></ValueListSelect>
        {value?.trigger_type?.id === 'on_due_date' && (
          <Box
            display='flex'
            flexDirection={'row'}
            alignItems={'center'}
            width={'60%'}
          >
            <Small {...s('text')}>{'at'}</Small>
            <Box width={'100%'} paddingLeft={token('spacing.xs')}>
              <HourSelect
                {...props}
                id={'time'}
                onChange={handleTimeChange}
                onBlur={handleTimeBlur}
                value={value?.time}
              ></HourSelect>
            </Box>
          </Box>
        )}
      </Box>
    </>
  );
}
