import React, { useCallback, useMemo } from 'react';
import { DialogProps } from '@rexlabs/dialog';
import {
  query,
  useEntityListQuery,
  useEntityQuery,
  useModelActions
} from '@rexlabs/model-generator';
import { toQuri } from '@rexlabs/quri';

import { settingsPropertyComplianceCategoriesModel } from 'data/models/entities/settings/property-compliance-categories';
import { settingsPropertyCategoryRequirementsModel } from 'data/models/entities/settings/property-category-requirements';

import { useToast } from 'view/components/@luna/notifications/toast';
import { RecordDialog } from 'view/components/record-screen/dialog/dialog';
import { DialogButtonGroup } from 'view/components/dialogs/buttons/dialog-button-group';

import { propertyComplianceCategoryDetailsBlock } from '../blocks/property-compliance-categories-details-block';
import { useRequirementsForCategory } from '../../common/hooks/use-requirements-for-category';
import { useUpdateComplianceCategory } from '../hooks/use-update-compliance-category';

interface EditComplianceCategoryDialogProps extends DialogProps {
  categoryId: string;
}

const content = [
  {
    id: 'details',
    label: 'Details',
    blocks: [propertyComplianceCategoryDetailsBlock]
  }
];

const queryFilter = (id: string) =>
  toQuri([
    {
      field: 'property_compliance_category_id',
      op: '==',
      value: id
    }
  ]);

function getCategoryRequirementsQuery(id: string) {
  return query`{
    ${settingsPropertyCategoryRequirementsModel} (q: ${queryFilter(id)}) {
      id
      category
      label
      description
      compliance_type
    }
  }`;
}

function getComplianceCategoryQuery(id: string) {
  return query`{
    ${settingsPropertyComplianceCategoriesModel} (id: ${id}) {
      id
      label
      description
      category
      applicable_to_object_type
    }
  }`;
}

export function EditComplianceCategoryDialog({
  onClose,
  categoryId
}: EditComplianceCategoryDialogProps) {
  const { addToast } = useToast();

  const categoryRequirementsQuery = useMemo(
    () => getCategoryRequirementsQuery(categoryId),
    [categoryId]
  );

  const { data: category, status: categoryLoadingStatus } = useEntityQuery(
    getComplianceCategoryQuery(categoryId)
  );

  const {
    data: categoryRequirements,
    status: categoryRequirementsLoadingStatus
  } = useEntityListQuery(categoryRequirementsQuery);

  const { refreshLists: refreshCategoryLists } = useModelActions(
    settingsPropertyComplianceCategoriesModel
  );

  const updateComplianceCategory = useUpdateComplianceCategory({
    categoryId,
    categoryRequirements
  });

  const { requirementsList } = useRequirementsForCategory({
    categoryRequirements
  });

  const handleSubmit = useCallback(
    async ({ values }) => {
      const { data } = await updateComplianceCategory({
        values
      });

      addToast({
        description: (
          <>
            Compliance category updated - <b>{data?.[0]?.label}</b>
          </>
        )
      });
      await refreshCategoryLists();
      return data;
    },
    [addToast, refreshCategoryLists, updateComplianceCategory]
  );

  return (
    <RecordDialog
      title='Edit category'
      data={{ ...category, requirements: requirementsList }}
      isLoading={
        categoryLoadingStatus !== 'loaded' ||
        categoryRequirementsLoadingStatus !== 'loaded'
      }
      handleSubmit={handleSubmit}
      onClose={onClose}
      content={content}
      size={'s'}
      submitLabel='Save'
      ButtonGroup={DialogButtonGroup}
    />
  );
}
