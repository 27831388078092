import React from 'react';
import { lowerCase } from 'lodash';

import { useStyles, StyleSheet } from '@rexlabs/styling';

import { FLAGS } from 'utils/feature-flags';
import { getRecordTitle } from 'utils/records/get-record-title';
import { formatDate } from 'utils/dates/format';

import { RecordObject, RecordTypes } from 'data/models/types';

import { ActionDeclaration } from 'src/modules/common/actions/types/action-declaration-types';
import { useInspectionStatusTag } from 'src/modules/tasks/inspections/hooks/use-inspection-status-tag';
import { MissingLocationWarningBanner } from 'src/modules/properties/components/missing-location-warning-banner';
import { getLngLatFromProperty } from 'src/modules/properties/utils/get-lng-lat-from-property';
import { useGetEditLocationAction } from 'src/modules/properties/utils/property-actions/use-get-edit-location-action';
import { invokeActionDeclaration } from 'src/modules/common/actions/utils/invoke-action-declaration';

import {
  TitleBlock,
  TitleBlockProps
} from 'view/components/cards/title/title-block';
import { TagConfig } from 'view/components/@luna/title-block';
import InspectionsIcon from 'view/components/icons/inspections';
import PropertyIcon from 'view/components/icons/property';
import TenancyIcon from 'view/components/icons/tenancy';
import { Grid } from 'view/components/@luna/form/grid';
import { HorizontalDivider } from 'view/components/ui/horizontal-divider';
import { useFeatureFlags } from 'view/components/@luna/feature-flags';

import { InspectionTask } from '../types/InspectionTask';
import { useGetInspectionActions } from '../hooks/action-menu-items/use-get-inspection-actions';

const type = RecordTypes.TaskInspection;

type InspectionRecordObject = Extract<
  RecordObject,
  { type: 'task_inspection' }
>;

type InspectionTitleBlockProps = {
  inspection?: InspectionTask;
  onStatusChange?: () => void;
};

const styles = StyleSheet({
  subText: {
    color: ({ token }) => token('color.textStyle.body.default'),
    fontWeight: ({ token }) => token('typography.weight.semibold')
  }
});

export function InspectionTitleBlock({
  inspection
}: InspectionTitleBlockProps) {
  const s = useStyles(styles);
  const { hasFeature } = useFeatureFlags();
  const getInspectionActions = useGetInspectionActions();
  const getEditLocationAction = useGetEditLocationAction();

  const statusTag: TagConfig = useInspectionStatusTag(inspection);

  const title = getRecordTitle({
    type,
    object: inspection
  } as InspectionRecordObject);

  if (!inspection) {
    return null;
  }

  const subTitles: TitleBlockProps['subTitles'] = [
    {
      Icon: TenancyIcon,
      label: inspection?.details?.property_tenancy?.tenancy?.display_name
    },
    {
      Icon: PropertyIcon,
      label: inspection?.property?.address_string
    }
  ];
  const actions = getInspectionActions(inspection);

  const closed_at = inspection.closed_at;

  const lngLat = getLngLatFromProperty(inspection.property!);

  return (
    <Grid columns={1}>
      <TitleBlock
        title={title}
        subTitles={subTitles}
        type={'inspection'}
        actions={actions as ActionDeclaration[]}
        tags={closed_at ? undefined : [statusTag]}
        Icon={() => <InspectionsIcon size='xl' />}
        subText={
          closed_at ? (
            <div>
              <span>This inspection task has been closed with the status </span>
              <span {...s('subText')}>
                {lowerCase(inspection?.details?.status?.label)}
              </span>{' '}
              on <span {...s('subText')}>{formatDate(closed_at)}</span>
            </div>
          ) : undefined
        }
      />
      {hasFeature(FLAGS.INSPECTION_SCHEDULE) && !lngLat && (
        <>
          <HorizontalDivider />
          <MissingLocationWarningBanner
            onEditLocation={() => {
              return invokeActionDeclaration(getEditLocationAction, {
                property: inspection.property!,
                shouldCheckForInspectionRun: true
              });
            }}
          />
        </>
      )}
    </Grid>
  );
}
