import dayjs from 'dayjs';
import { camelCase } from 'lodash';

import { InspectionRunStartData } from '../blocks/inspection-run-start-block';
import { InspectionRun } from '../types/InspectionRun';

export function mapInspectionRunToEditInspectionRunStartForm(
  inspectionRun: InspectionRun
): InspectionRunStartData {
  return {
    inspection_date: dayjs(inspectionRun!.start_at!).tz().format('YYYY-MM-DD'),
    leave_from_start_time: dayjs(inspectionRun!.start_at!)
      .tz()
      .format('HH:mm:ss'),
    start_address: {
      id: camelCase(inspectionRun!.start_address!),
      label: inspectionRun!.start_address!,
      data: {
        lngLat: [
          parseFloat(inspectionRun!.start_longitude),
          parseFloat(inspectionRun!.start_latitude)
        ]
      }
    }
  };
}
