import React from 'react';
import EmailIcon from 'view/components/icons/email';
import { ActionMenuItem } from '@rexlabs/action-menu';
import { getChecklistTemplateItemSubtext } from '../utils/get-checklist-template-item-subtext';
import { ChecklistTemplateItem as ChecklistTemplateItemType } from '../types/ChecklistTemplate';
import { ChecklistTemplateItem } from './checklist-template-item';
import { ChecklistTemplateSubtaskTrigger } from './checklist-template-subtask-trigger';

export function ChecklistTemplateSubtask({
  index,
  checklistTemplateItem,
  editMode = false,
  actionMenuItems,
  triggerActionMenuItems
}: {
  index: number;
  checklistTemplateItem: ChecklistTemplateItemType;
  editMode?: boolean;
  actionMenuItems?: ActionMenuItem[];
  triggerActionMenuItems?: ActionMenuItem[];
}) {
  const itemTitlePrefix = index ? `${index + 1}.` : '1.';

  const title = `${itemTitlePrefix} ${checklistTemplateItem.label}`;

  return (
    <ChecklistTemplateItem
      title={title}
      subtext={getChecklistTemplateItemSubtext(checklistTemplateItem)}
      Icon={EmailIcon}
      actionMenuItems={actionMenuItems}
      editMode={editMode}
      Trigger={
        checklistTemplateItem.trigger_type ? (
          <ChecklistTemplateSubtaskTrigger
            editMode={editMode}
            checklistTemplateItem={checklistTemplateItem}
            triggerActionMenuItems={triggerActionMenuItems}
          ></ChecklistTemplateSubtaskTrigger>
        ) : undefined
      }
    ></ChecklistTemplateItem>
  );
}
