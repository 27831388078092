import { isString } from 'lodash';
import { ContentConfig } from 'view/components/record-screen/types';

function filter(str: string, whiteList: string[], blackList?: string[]) {
  const isWhiteListLabel = whiteList.some((whiteListLabel) =>
    str.includes(whiteListLabel)
  );
  if (isWhiteListLabel) return true;

  if (blackList) {
    const isBlackListLabel = blackList.some((blackListLabel) =>
      str.includes(blackListLabel)
    );

    if (isBlackListLabel) return false;
  }

  return true;
}

export function filterContentBy(
  content: ContentConfig,
  whiteList: string[],
  blackList?: string[]
): ContentConfig {
  return content
    .filter((content) => {
      return filter(content.label, whiteList, blackList);
    })
    .map((content) => {
      return {
        ...content,
        label: content.label.replace(/\[.*?\]/, '').trim(),
        items: content.items.map((items) => ({
          ...items,
          blocks: items.blocks
            .filter((block) =>
              typeof block.title === 'string'
                ? filter(block.title, whiteList, blackList)
                : true
            )
            .map((block) => ({
              ...block,
              title: isString(block.title)
                ? block.title.replace(/\[.*?\]/, '').trim()
                : block.title
            }))
        }))
      };
    });
}
