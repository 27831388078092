import { Generator } from 'data/models/generator';
import { auditableConfig } from 'src/modules/common/auditable/auditable-config';
import { channelMessageRecipientsModel } from 'src/modules/communications/messages/models/channel-message-recipients-model';

const config = {
  entities: {
    related: {
      ...auditableConfig,
      recipient_contact: {
        include: 'recipient_contact'
      },
      channel_message_recipient: {
        include: 'channel_message_recipient',
        model: channelMessageRecipientsModel
      }
    }
  }
};

export const accountCreditLogsModel = new Generator(
  'account-credit-logs',
  config
).createEntityModel();
