import React, { IframeHTMLAttributes, useState } from 'react';
import { createPortal } from 'react-dom';

type IframeProps = IframeHTMLAttributes<HTMLIFrameElement> & {
  html: string;
  css?: string;
};

export function Iframe({ html, css, ...props }: IframeProps) {
  const [contentRef, setContentRef] = useState<HTMLIFrameElement | null>(null);

  const mountNode = contentRef?.contentWindow?.document?.body;

  return (
    <iframe
      {...props}
      ref={setContentRef}
      sandbox={'allow-same-origin allow-popups allow-popups-to-escape-sandbox'}
      referrerPolicy='origin'
    >
      {mountNode &&
        createPortal(
          <>
            <style type={'text/css'}>{css}</style>
            <base target='_blank' />
            <div dangerouslySetInnerHTML={{ __html: html }} />
          </>,
          mountNode
        )}
    </iframe>
  );
}
