import React from 'react';

import { StyleSheet, useStyles } from '@rexlabs/styling';
import { PageLayout } from '../page-layout/page-layout';

const defaultStyles = StyleSheet({
  header: {
    marginBottom: '24px'
  }
});

interface ListLayoutProps extends React.AllHTMLAttributes<HTMLDivElement> {
  header: React.ReactNode;
}

export function ListLayout({ header, children }: ListLayoutProps) {
  const s = useStyles(defaultStyles);
  return (
    <PageLayout>
      <header {...s('header')}>{header}</header>
      <section>{children}</section>
    </PageLayout>
  );
}
