import { EmptyCard } from 'view/components/record-screen/cards/empty-card';
import EmptyTable from 'src/assets/illustrations/empty-table.svg';
import { Message } from '@rexlabs/notifications';
import React from 'react';
import { useGetAddDocumentsAction } from '../actions/use-get-add-documents-action';
import { AttachedTo } from '../dialogs/create-document-dialog';
import { DocumentRelatedRecord } from './connectors/document-list';

export function DocumentsListEmptyState({
  record,
  attachedTo
}: {
  record: DocumentRelatedRecord;
  attachedTo: AttachedTo[];
}) {
  const getAddDocumentsAction = useGetAddDocumentsAction();

  return (
    <EmptyCard>
      <Message
        title='Documents'
        Illustration={EmptyTable}
        actions={[
          {
            ...getAddDocumentsAction({ data: record, attachedTo }),
            type: 'primary'
          }
        ]}
      >
        Add documents to this record
      </Message>
    </EmptyCard>
  );
}
