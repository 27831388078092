import { Generator } from 'data/models/generator';
import { auditableConfig } from 'src/modules/common/auditable/auditable-config';

import { api } from 'utils/api/api-client';
import _ from 'lodash';

import { Appliance } from '../types/Appliance';

const config = {
  entities: {
    related: {
      ...auditableConfig,
      manuals: {
        include: 'manuals'
      },
      warranties: {
        include: 'warranties'
      },
      property: {
        include: 'property'
      }
    }
  }
};

const actionCreators = {
  bulkDownloadManuals: {
    request: ({
      data,
      format = 'pdf',
      applianceId
    }: {
      data: any;
      format?: string;
      applianceId: string;
    }) =>
      api.get(`/appliances/bulk/${applianceId}/manual`, {
        data,
        format,
        disposition: 'download_link'
      }),
    reduce: _.identity
  },
  bulkDownloadWarranties: {
    request: ({
      data,
      format = 'pdf',
      applianceId
    }: {
      data: any;
      format?: string;
      applianceId: string;
    }) =>
      api.get(`/appliances/bulk/${applianceId}/warranty`, {
        data,
        format,
        disposition: 'download_link'
      }),
    reduce: _.identity
  }
};

export const appliancesModel = new Generator<Appliance, typeof actionCreators>(
  'appliances',
  config
).createEntityModel({ actionCreators });
