import { useCallback } from 'react';
import { useQuery } from 'react-query';
import { pick } from 'lodash';

import { ValueListItem } from 'utils/normaliser/value-list-item';
import { api } from 'utils/api/api-client';

import { useSettings } from 'src/modules/settings/hooks/useSettings';
import { CommunicationContextType } from 'src/modules/communications/common/types/communication-context-type';
import { messageTriggerKeys } from '../types/MessageTriggerKey';
import { MessageTriggerSettings } from '../types/MessageTriggerSettings';
import { TemplatedAttachmentId } from '../../../types/TemplatedAttachmentId';

function getTemplatedAttachmentIdForContextType(
  contextTypeId?: CommunicationContextType
): TemplatedAttachmentId[] {
  const TemplatedAttachmentIds: TemplatedAttachmentId[] = [];
  switch (contextTypeId) {
    case 'contact_disbursement':
    case 'ownership_disbursement':
      TemplatedAttachmentIds.push('disbursement_statement');
      break;
    case 'contact_payment_receipt':
      TemplatedAttachmentIds.push('contact_payment_receipt');
      break;
    case 'task_inspection':
      TemplatedAttachmentIds.push('inspection_report');
      break;
    case 'periodic_ownership_statement':
      TemplatedAttachmentIds.push('periodic_ownership_statement');
      TemplatedAttachmentIds.push('periodic_ownership_summary_statement');
      break;
    case 'yearly_ownership_statement':
      TemplatedAttachmentIds.push('yearly_ownership_statement');
      TemplatedAttachmentIds.push('yearly_ownership_summary_statement');
      break;
    case 'task_quote':
    case 'task_work_order':
      TemplatedAttachmentIds.push('images');
      break;
    case 'invoice':
      TemplatedAttachmentIds.push('invoice');
      TemplatedAttachmentIds.push('uploaded_bill');
      break;
    default:
      break;
  }
  return TemplatedAttachmentIds;
}

interface MappedMessageTriggerSettingsReturnValues {
  messageTriggerSettings: MessageTriggerSettings;
  getTemplatedAttachmentForContextType: (
    contextTypeId?: CommunicationContextType
  ) => ValueListItem[];
}
/**
 * Filters the settings data to only include the message trigger settings,
 * then fetches the message templates for those settings, as only the id is
 * stored in the settings data.
 * @returns {MessageTriggerSettings}
 */
export function useMessageTriggerSettings(): MappedMessageTriggerSettingsReturnValues {
  const settingsData = useSettings();
  const messageTriggerSettings = pick(settingsData, messageTriggerKeys);

  const { data: fileNameValueList } = useQuery<ValueListItem[]>(
    ['value-lists/templated_attachment'],
    async () => {
      return await api
        .get('value-lists/templated_attachment')
        .then((res) => res.data.items);
    },
    { cacheTime: Infinity, staleTime: Infinity }
  );

  const getTemplatedAttachmentForContextType = useCallback(
    (contextTypeId?: CommunicationContextType) => {
      const attachmentIds = getTemplatedAttachmentIdForContextType(
        contextTypeId
      );

      return (fileNameValueList || []).filter((file) =>
        attachmentIds.find((attachmentId) => file.id === attachmentId)
      );
    },
    [fileNameValueList]
  );

  return {
    messageTriggerSettings,
    getTemplatedAttachmentForContextType
  };
}
