import React from 'react';

import { Field } from '@rexlabs/form';
import { TextArea } from '@rexlabs/text-input';
import { Body } from '@rexlabs/text';

import { Grid } from 'view/components/@luna/form/grid';
import { BlockConfig } from 'view/components/record-screen/types';
import {
  DateInput,
  disableFutureDates
} from 'view/components/@luna/inputs/date-input/date-input';

import { KeySet } from '../data/key-set-model';

export type CheckInKeySetFormValues = Pick<
  KeySet,
  'id' | 'checked_in_date' | 'status_reason' | 'name'
>;

export const checkInKeySetBlock: BlockConfig<
  CheckInKeySetFormValues,
  any,
  CheckInKeySetFormValues
> = {
  id: 'check-out-key-set-block',
  Edit: ({ values }) => {
    return (
      <Grid columns={1}>
        <Body>
          <>
            You are checking in <b>{values?.name} key set.</b>
          </>
        </Body>
        <Grid columns={2}>
          <Field
            name='checked_in_date'
            label='Date checked-in'
            Input={DateInput}
            optional={false}
            inputProps={{
              isOutsideRange: disableFutureDates
            }}
          />
        </Grid>
        <Field name='status_reason' label='Notes' Input={TextArea} />
      </Grid>
    );
  },
  validate: {
    definitions: {
      checked_in_date: { name: 'date checked-in', rules: 'required' }
    }
  }
};
