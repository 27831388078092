import React, { useMemo } from 'react';
import { Quri } from '@rexlabs/quri';
import { BankDepositsListUI } from '../components/bank-deposits-list';
import { getBankDepositsQuery } from '../data/bank-deposits';

interface BankDepositsListProps {
  filters?: Quri[];
}

export function BankDepositsList({ filters }: BankDepositsListProps) {
  const query = useMemo(() => getBankDepositsQuery(), []);

  return <BankDepositsListUI query={query} filters={filters} />;
}
