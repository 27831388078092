import dayjs from 'dayjs';
import { pluralize } from 'utils/formatters';

export function getDaysUntilExpiry(date: string) {
  const daysUntilExpiry = dayjs(date).diff(dayjs(), 'day', true);

  return daysUntilExpiry > 0
    ? `${pluralize('day', Math.ceil(daysUntilExpiry), true)} left`
    : 'Expired';
}
