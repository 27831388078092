import React from 'react';

import { api } from 'utils/api/api-client';
import { Autocomplete } from '@rexlabs/select-old';
import { authorNormaliser } from 'src/modules/authors/utils/author-normaliser';
import { Author } from 'src/modules/authors/types/author-types';

export const AuthorSelect = (props) => {
  const items = React.useCallback(async (term) => {
    const authors = await api.get('/authors/unique', {
      search: term,
      per_page: 30
    });

    return authors.data as Author[];
  }, []);

  return (
    <Autocomplete items={items} normaliser={authorNormaliser} {...props} />
  );
};
