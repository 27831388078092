import { ActionMenuItem, RegularActionMenuItem } from '@rexlabs/action-menu';
import {
  ActionDeclaration,
  SingleActionDeclaration
} from 'src/modules/common/actions/types/action-declaration-types';
import _, { noop } from 'lodash';
import { GroupHeadingActionMenuItem } from '@rexlabs/action-menu/lib/types/types';
import flagsmith from 'flagsmith';

/*
  WARNING you are about to enter Groupception®, continue reading on your own risk
  the groupsOfGroups argument takes a list of list of group id to group multiple groups together 🙃
  eg. if you have an action menu which is supposed to look like this

  Action (group: foo)
  Action (group: foo)
  Action (group: foo)
  ----------
  Create inspection (group: inspection)
  Create maintenance job (group: maintenance-job)

  but the "Create inspection" and "Create maintenance job" belong to different groups
  your would provide [['inspection', 'maintenance-job']] to group the two groups together
*/
export function groupByGroups(
  actions: SingleActionDeclaration[],
  groupsOfGroups: string[][]
) {
  return _.groupBy(actions, (action) => {
    if (!action?.group) {
      return 'NO-GROUP';
    }

    const combinedGroup = groupsOfGroups.find((group) =>
      group.includes(action.group!)
    );

    if (combinedGroup) {
      return combinedGroup?.join('-and-');
    }

    return action.group;
  });
}

/**
 * Takes an array of action declarations and converts
 * it into an array of action menu items
 * @param actions An array of action declarations
 * @param groupsOfGroups An array of arrays of group ids used group multiple groups together
 */
export function transformActionDeclarationsToActionMenuItems(
  actions: ActionDeclaration[],
  groupsOfGroups?: string[][]
): ActionMenuItem[] {
  // This lets us include actions that might be feature flagged
  const filteredActions = actions?.filter((action) => {
    if (action.flag) {
      return flagsmith.hasFeature(action.flag);
    }

    return true;
  });

  const combinedActions = transformActionsToSingleActions(filteredActions);
  const groupedActions = groupByGroups(combinedActions, groupsOfGroups ?? [[]]);

  return Object.entries(groupedActions).flatMap(
    ([_, actionGroup], index): ActionMenuItem[] => {
      const actionWithGroupSubtitle = actionGroup.find(
        (action) => action.groupSubtitle
      );

      return [
        ...(index !== 0 ? [{ isDivider: true }] : []),
        ...(actionWithGroupSubtitle
          ? [
              {
                label: actionWithGroupSubtitle.groupSubtitle,
                isGroupHeading: true
              } as GroupHeadingActionMenuItem
            ]
          : []),
        ...transformSingleActionsToRegularActionMenuItems(actionGroup)
      ];
    }
  );
}

/**
 * Takes an array of action declarations and converts
 * it into an array of single action declarations
 * @param actions An array of action declarations
 * @returns An array of single action declarations
 */
export function transformActionsToSingleActions(
  actions: ActionDeclaration[]
): SingleActionDeclaration[] {
  let combinedActions: SingleActionDeclaration[] = [];
  actions?.forEach((action) => {
    if ('actions' in action) {
      combinedActions = combinedActions.concat(action.actions);
    } else {
      combinedActions.push(action);
    }
  });

  return combinedActions;
}

/**
 * Use this for mobile action menu items.
 * Takes an array of single action declarations and converts
 * it into an array of regular action menu items
 * @param actions An array of single action declarations
 * @returns An array of regular action menu items
 */
export function transformSingleActionsToRegularActionMenuItems(
  actions: SingleActionDeclaration[]
): RegularActionMenuItem[] {
  return actions
    ?.filter((action) => action.handleAction != null)
    .map((action) => transformSingleActionToRegularActionMenuItem(action));
}

export function transformSingleActionToRegularActionMenuItem(
  action: SingleActionDeclaration
): RegularActionMenuItem {
  return {
    //@ts-ignore We are setting `id` here but `id` doesn't exist on the
    // `RegularActionMenuItem` type. Needs further investigation into
    // whether `id` is a valid property
    id: action.id,
    label: action.label,
    intent: action.intent === 'danger' ? 'danger' : undefined,
    onClick: action.handleAction ? action.handleAction : noop,
    disabled: action.disabled
  };
}
