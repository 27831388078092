import { useDialog } from '@rexlabs/dialog';
import EditIcon from 'view/components/icons/edit';
import { EditMatchDialog } from '../../dialogs/edit-match-dialog';
import { BatchReceiptingItem } from '../../types';

export function useGetEditMatchAction() {
  const { open: openEditMatchDialog } = useDialog(EditMatchDialog);

  return (item: BatchReceiptingItem) => {
    return {
      Icon: EditIcon,
      label: 'Edit match',
      onClick: () => openEditMatchDialog({ batchReceiptingItem: item })
    };
  };
}
