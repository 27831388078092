import React from 'react';
import { formatCurrency } from 'utils/formatters';
import { Summary, SummaryRow } from './summary';

export type CurrencySummaryRow = SummaryRow<number>;

export interface CurrencySummary {
  rows: CurrencySummaryRow[];
}

function transformCurrencySummaryRow(
  row: CurrencySummaryRow
): SummaryRow<React.ReactNode> {
  if ('value' in row)
    return {
      ...row,
      value: formatCurrency(row.value || 0)
    };

  return row;
}

export function CurrencySummary({ rows }: CurrencySummary) {
  return <Summary rows={rows.map(transformCurrencySummaryRow)} />;
}
