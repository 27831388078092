import React, { useMemo } from 'react';
import { startCase } from 'lodash';

import { InspectionRunPlanner } from '../components/inspection-run-planner';
import { InspectionRunMap } from '../components/inspection-run-map';

const INSPECTION_PLANNER_TAB_NAME = 'inspection-planner';
const INSPECTION_MAP_TAB_NAME = 'inspection-map';

export function useInspectionRunTabs(isEditMode: boolean) {
  const [activeTab, setActiveTab] = React.useState(INSPECTION_PLANNER_TAB_NAME);

  const tabs = useMemo(() => {
    return [
      {
        name: INSPECTION_PLANNER_TAB_NAME,
        label: startCase(INSPECTION_PLANNER_TAB_NAME),
        Tab: () => <InspectionRunPlanner isEditMode={isEditMode} />
      },

      {
        name: INSPECTION_MAP_TAB_NAME,
        label: startCase(INSPECTION_MAP_TAB_NAME),
        Tab: InspectionRunMap
      }
    ];
  }, [isEditMode]);

  return {
    tabs,
    activeTab,
    onTabChange: ({ name }) => setActiveTab(name)
  };
}
