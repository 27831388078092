import React from 'react';
import { DialogProps } from '@rexlabs/dialog';
import { RecordDialog } from 'view/components/record-screen/dialog/dialog';
import { DialogContentConfig } from 'view/components/record-screen/dialog/types';
import { api } from 'utils/api/api-client';
import { useToast } from 'view/components/@luna/notifications/toast';
import { changePasswordBlock } from '../blocks/change-password';

export const content: DialogContentConfig = [
  {
    id: 'change-password',
    label: 'Change password',
    blocks: [changePasswordBlock]
  }
];

export function ChangePasswordDialog({ onClose }: DialogProps) {
  const { addToast } = useToast();

  const handleSubmit = async ({ values }) => {
    try {
      const { data } = await api.post('/me/change-password', {
        current_password: values.password,
        new_password: values.new_password
      });

      addToast({ description: 'Password has been changed' });

      onClose?.();
      return data;
    } catch (e) {
      addToast({
        description: 'Changing password was unsuccessful',
        type: 'error'
      });
    }
  };

  return (
    <RecordDialog
      onClose={onClose}
      title='Change password'
      content={content}
      handleSubmit={handleSubmit}
    />
  );
}
