import {
  CWUCondition,
  InspectionConditionItem
} from 'src/modules/tasks/inspections/entry-exit/types/InspectionCondition';
import { CONDITION_VALUES } from 'src/modules/tasks/inspections/components/condition-button-input';

export function getConditionValue(
  items: InspectionConditionItem[] | undefined,
  name: CWUCondition
) {
  if (!Array.isArray(items)) return false;

  return (
    items.find((item) => item.inspection_condition.name === name)?.value ??
    CONDITION_VALUES.NO_VALUE
  );
}
