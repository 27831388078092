import { RentScheduleEntry } from 'src/modules/property-tenancies/types/property-tenancy-types';

export function nullFirstEffectiveFromDate<T extends RentScheduleEntry>(
  rentSchedule: T[]
) {
  return rentSchedule.map((rentScheduleEntry, index) => {
    if (index === 0)
      return {
        ...rentScheduleEntry,
        effective_from_date: null
      };

    return rentScheduleEntry;
  });
}
