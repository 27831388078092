export const MESSAGE_TEMPLATE_ROUTES = {
  MESSAGE_TEMPLATE_LIST: {
    config: {
      path: '/message-templates',
      Component: require('./screens/message-template-list.tsx')
        .MessageTemplateList
    }
  },
  MESSAGE_TEMPLATE_DETAILS: {
    config: {
      path: '/message-template/:messageTemplateId',
      Component: require('./screens/message-template-details.tsx')
        .MessageTemplateDetails
    }
  }
};
