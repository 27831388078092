import { StyleSheet, padding, useStyles } from '@rexlabs/styling';
import { Card } from 'view/components/@luna/card';
import React from 'react';
import { Heading } from '@rexlabs/text';
import Box from '@rexlabs/box';

const defaultStyles = StyleSheet({
  wrapTitle: {
    ...padding.styles({
      target: 'recordCard.title',
      x: 'xl',
      top: 'm',
      bottom: 'xl'
    }),

    height: ({ token }) => token('recordCard.title.height', '8rem')
  },
  content: {
    width: '100%',
    ...padding.styles({
      target: 'recordCard',
      all: 'xl',
      top: 'none'
    })
  }
});

export interface BlockLayoutProps {
  id: string;
  headline?: React.ReactNode;
  actions?: React.ReactNode;
  children: React.ReactNode;
}

export function BlockLayout({
  children,
  headline,
  actions,
  ...props
}: BlockLayoutProps) {
  const s = useStyles(defaultStyles);

  const hadHeadline = headline || actions;
  return (
    <Card {...props}>
      {hadHeadline && (
        <Box
          flexDirection='row'
          alignItems='center'
          justifyContent='space-between'
          {...s('wrapTitle')}
        >
          <Heading level={3}>{headline}</Heading>
          {actions && <Box ml='2.4rem'>{actions}</Box>}
        </Box>
      )}

      <div {...s('content')}>{children}</div>
    </Card>
  );
}
