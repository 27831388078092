import React from 'react';

import { DialogProps } from '@rexlabs/dialog';

import { RecordSubmitHandler } from 'view/components/record-screen/utils';
import { RecordDialog } from 'view/components/record-screen/dialog/dialog';

import {
  InspectionRunFinishBlockFormValues,
  InspectionRunFinishData,
  inspectionRunFinishBlock
} from '../blocks/inspection-run-finish-block';

export interface EditInspectionRunFinishDialogProps extends DialogProps {
  initialValues: InspectionRunFinishData;
  submitHandler: RecordSubmitHandler<InspectionRunFinishBlockFormValues>;
}

const content = [
  {
    id: 'inspection-finish',
    label: '',
    blocks: [inspectionRunFinishBlock]
  }
];

export function EditInspectionRunFinishDialog({
  onClose,
  initialValues,
  submitHandler
}: EditInspectionRunFinishDialogProps) {
  return (
    <RecordDialog
      size='s'
      title='Inspection run finish'
      content={content}
      data={initialValues}
      onClose={onClose}
      handleSubmit={submitHandler}
      submitLabel='Change inspection run finish'
    />
  );
}
