import React, { useCallback } from 'react';
import { NormalisedItem, Select, useItems } from '@rexlabs/select';
import { getContacts } from 'utils/api/get-contacts';
import { Contact } from 'src/modules/contacts/types/contact-types';

export type TaskTypeAssignmentCustomItem = {
  id: string;
  label: string;
};

const normaliseItem = (
  item: TaskTypeAssignmentCustomItem | Contact
): NormalisedItem => {
  if ('__record_type' in item) {
    return { id: item.id, label: item.display_name, type: 'contact' };
  }

  return item;
};

export function TaskTypeAssignmentSelect({ getSuggestedItems, ...props }) {
  const additionalItem: TaskTypeAssignmentCustomItem = {
    id: 'do-not-automatically-assign',
    label: 'Do not automatically assign'
  };

  const getItems: (
    searchTerm: any
  ) => Promise<Array<TaskTypeAssignmentCustomItem | Contact>> = useCallback(
    async (searchTerm) => {
      const contacts = await getContacts({
        searchTerm,
        filterByRoles: ['user']
      });

      return [additionalItem, ...contacts];
    },
    []
  );

  const getAllSuggestedItems = useCallback(() => {
    return [additionalItem, ...getSuggestedItems()];
  }, []);

  const { getSelectProps: useItemsGetSelectProps } = useItems<
    TaskTypeAssignmentCustomItem | Contact
  >({
    getItems,
    getSuggestedItems: getAllSuggestedItems
  });

  const selectProps = useItemsGetSelectProps();

  return <Select {...props} {...selectProps} normaliser={normaliseItem} />;
}
