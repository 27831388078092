import { omit } from 'lodash';

import { Ownership } from 'data/models/entities/ownerships';

import { TaxAdjustmentForm } from '../types/tax-adjustment-form';

export function createTaxAdjustmentPayloadData(
  formData: TaxAdjustmentForm,
  ownership?: Ownership
) {
  const values = omit(formData, ['bank_account', 'payment_information']);

  return {
    ...values,
    // NOTE: this is always a manual tax adjustment
    type: { id: 'manual_tax_adjustment' },
    ownership
  };
}
