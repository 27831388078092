import React from 'react';

import { Field, HiddenField } from '@rexlabs/form';

import { User } from 'src/modules/users/types/user-types';

import { Grid } from 'view/components/@luna/form/grid';
import { BlockConfig } from 'view/components/record-screen/types';
import { FroalaReadOnly } from 'view/components/@luna/inputs/froala-editor/components/froala-read-only';
import { FroalaEditorInput } from 'view/components/@luna/inputs/froala-editor/components/froala-editor-input';
import { EmptyCard } from 'view/components/record-screen/cards/empty-card';
import { Message } from 'view/components/@luna/message';
import EmptyRecord from 'assets/illustrations/empty-record.svg';
import { useAddFilesForEditor } from 'view/components/@luna/inputs/froala-editor/hooks/use-add-files-for-editor';
import { CreateFile } from 'src/types';

type EmailSignatureBlockForm = {
  email_signature?: string;
  linked_files?: CreateFile[];
};

export const emailSignatureBlock: BlockConfig<
  User,
  any,
  EmailSignatureBlockForm
> = {
  id: 'email-signature',
  title: 'Email signature',
  showEmpty: ({ email_signature }) => !email_signature,
  Empty: ({ onEditClick }) => {
    return (
      <EmptyCard>
        <Message
          title='Add email signature'
          Illustration={EmptyRecord}
          actions={[
            {
              label: 'Add signature',
              intent: 'primary',
              handleAction: onEditClick
            }
          ]}
        >
          <div>Personalise your communication with a signature.</div>
        </Message>
      </EmptyCard>
    );
  },
  View: ({ data }) => {
    return (
      <Grid columns={1}>
        <FroalaReadOnly value={data?.email_signature || ''} />
      </Grid>
    );
  },
  Edit: () => {
    const { setFileIdToAdd, setFileIdToRemove } = useAddFilesForEditor();

    return (
      <Grid columns={1}>
        <Field
          name='email_signature'
          Input={FroalaEditorInput}
          optional={false}
          inputProps={{
            onImageAdded: setFileIdToAdd,
            onImageRemoved: setFileIdToRemove,
            isSignature: true
          }}
        />
        {/*Images uploaded within the input are stored in this field*/}
        <HiddenField name={'linked_files'} />
      </Grid>
    );
  }
};
