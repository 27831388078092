import React from 'react';

import { BlockConfig } from 'view/components/record-screen/types';

import { SMSActivityTable } from 'src/modules/settings/subscriptions/components/sms-activity-table';

export const smsActivityBlock: BlockConfig = {
  id: 'sms-activity-block',
  title: 'SMS Activity',
  View: () => <SMSActivityTable />
};
