import { InfoBanner } from 'view/components/@luna/notifications/banner';
import InfoCircleIcon from 'view/components/icons/info';
import React from 'react';

export function tenantEditBanner() {
  return {
    infoBanner: (
      <InfoBanner
        Icon={InfoCircleIcon}
        description={
          <span>
            You can add a custom tenancy reference that your tenants use when
            sending payments via transfer. If not, Rex PM will create and you
            can, at any time, change the reference assigned to a tenant.
            <br />
            <br />
            You can use the same tenancy reference for multiple or all tenants.
            This will result in those tenants being automatically selected in
            the ‘Received from’ field when matching a transaction in the Batch
            Receipting workflow against that reference number. For more
            information, see the help article{' '}
            <a
              href='https://rexpm.zendesk.com/hc/en-au/articles/15419609793945-Batch-Receipting'
              target='_blank'
              rel='noreferrer'
            >
              here
            </a>
            .
          </span>
        }
      />
    )
  };
}
