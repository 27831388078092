import * as React from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { StyleSheet, useStyles } from '@rexlabs/styling';
import { api } from 'utils/api/api-client';
import { useConfirmationDialog } from '@rexlabs/dialog';
import dayjs from 'dayjs';

const styles = StyleSheet({
  visuallyHidden: {
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: '1px',
    overflow: 'hidden',
    position: 'absolute',
    whiteSpace: 'nowrap',
    width: '1px'
  },

  checkbox: {
    '&:checked + div': {
      background: ({ token }) => token('palette.green.900'),
      borderColor: ({ token }) => token('palette.green.900'),

      '&:hover': {
        borderColor: ({ token }) => token('palette.green.900')
      },

      '&:focus': {
        borderColor: ({ token }) => token('palette.green.900')
      }
    },

    '&:disabled + div': {
      opacity: 0.5
    },

    '& + div': {
      position: 'relative',
      height: 24,
      width: 24,
      borderRadius: '50%',
      borderStyle: 'solid',
      borderWidth: 1,
      borderColor: ({ token }) => token('color.border.input.idle'),

      '&:hover': {
        borderColor: ({ token }) => token('color.border.input.hover')
      },

      '&:focus': {
        borderColor: ({ token }) => token('color.border.input.focus')
      },

      '&:before': {
        content: "''",
        width: 12,
        height: 7,
        position: 'absolute',
        top: 6,
        left: 5,
        border: '2px solid #fff',
        borderTop: 'none',
        borderRight: 'none',
        background: 'transparent',
        transform: 'rotate(-45deg)',
        zIndex: 1
      }
    }
  }
});

const cancelText = `Complete subtask and discard message`;
const confirmText = `Complete subtask and send message`;

const scheduledTitle = 'Send scheduled message';
const automatedTitle = 'Send automated message';

const scheduledMessage = (template, triggerAt) => {
  const triggerDate = dayjs(triggerAt).tz();
  const time = triggerDate.format('h:mma');
  const date = triggerDate.format('DD MMM YYYY');

  return `This subtask has an automated message “${template}” that is scheduled to send at ${time} on ${date}. If you complete the task manually you must either discard the message or send it now.`;
};

const automatedMessage = (template) =>
  `This subtask has an automated message “${template}”. Would you like to discard the message or send it now?`;

export function CheckboxItemCheckbox({ value }) {
  const s = useStyles(styles);
  const queryClient = useQueryClient();
  const confirmationDialog = useConfirmationDialog();

  const { completed_at, triggered_at, trigger_type, id } = value;

  const isChecked = Boolean(completed_at);
  const wasCompleted = Boolean(triggered_at);

  const endpoint = isChecked ? 'incomplete' : 'complete';

  const checklistItemMutation = useMutation(
    (payload: { skip_triggered_actions?: boolean }) => {
      return api.patch(`/tasks/checklist-items/${id}/${endpoint}`, payload);
    },
    {
      onSettled: () => {
        queryClient.invalidateQueries(['checklist', value.checklistId]);
        queryClient.invalidateQueries('checklists-widget');
      }
    }
  );

  const onChange = async () => {
    const onCancel = () => {
      return checklistItemMutation.mutateAsync({
        skip_triggered_actions: true // in this case, we are skipping and discarding the action
      });
    };

    const onConfirm = () => {
      return checklistItemMutation.mutateAsync({
        skip_triggered_actions: false // in this case, we want to trigger the action when we complete the task
      });
    };

    const isDueDateTrigger = trigger_type?.id === 'on_due_date';
    const isCompletionTrigger = trigger_type?.id === 'on_completion';

    const template = value.action_message_template?.name;

    if (isDueDateTrigger && !wasCompleted && !isChecked) {
      const triggerAt = value.trigger_at;

      return confirmationDialog.open({
        title: scheduledTitle,
        message: scheduledMessage(template, triggerAt),
        cancelText,
        confirmText,
        onCancel,
        onConfirm
      });
    }

    if (isCompletionTrigger && !wasCompleted && !isChecked) {
      return confirmationDialog.open({
        title: automatedTitle,
        message: automatedMessage(template),
        cancelText,
        confirmText,
        onCancel,
        onConfirm
      });
    }

    return checklistItemMutation.mutateAsync({});
  };

  return (
    <label>
      <input
        type='checkbox'
        disabled={checklistItemMutation.isLoading}
        onChange={onChange}
        checked={isChecked}
        aria-label='Checklist item checkbox'
        {...s('visuallyHidden', 'checkbox')}
      />
      <div />
    </label>
  );
}
