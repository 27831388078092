import React from 'react';

import { Value, ValuedValueProps } from './';

interface BooleanValueProps extends Omit<ValuedValueProps, 'value'> {
  value?: boolean | null;
  trueLabel?: string;
  falseLabel?: string;
}

export function BooleanValue({
  value,
  trueLabel = 'Enabled',
  falseLabel = 'Disabled',
  ...props
}: BooleanValueProps) {
  return <Value {...props} value={value ? trueLabel : falseLabel} />;
}
