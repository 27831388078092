import { Generator } from 'data/models/generator';
import { auditableConfig } from 'src/modules/common/auditable/auditable-config';
import { Note } from '../types/Note';

const config = {
  entities: {
    related: {
      // TODO: configure includes here
      ...auditableConfig,
      attached_to: {
        include: 'attached_to'
      }
    }
  }
};

export const notesModel = new Generator<Note>(
  'notes',
  config
).createEntityModel();
