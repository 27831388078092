import { ValueListValue } from 'data/models/types';
import {
  Address,
  AddressArea,
  AddressAreaType,
  FlattenedProperty
} from '../types/property-types';
import { getValueFromStringOrValueListValue } from './get-value-from-string-or-value-list-value';

// This version has the areas flat mapped onto the address object for easy of access
export type PropertyFormData = Partial<
  Omit<FlattenedProperty, 'address'> & {
    address: Omit<Address, 'areas'> &
      { [K in AddressAreaType]: string | ValueListValue<string> };
    previous_service_package_name?: string;
  }
>;

export function getAddressFromPropertyFormValues(values: PropertyFormData) {
  // The fields got flat mapped in the details block, so we need to unflatten them
  const address = (values.address || {}) as Partial<
    Address & { [K in AddressAreaType]?: string | ValueListValue<string> }
  >;

  // TODO: without a way to call the spec endpoint inside this function, we need to hardcode the fields
  const fields: AddressAreaType[] = [
    'locality',
    'suburb',
    'state',
    'post_town'
  ];

  const areas: AddressArea[] = fields
    .filter((area) => area in address) // Filter out any fields that aren't in the address
    .filter((area) => address[area]) // Filter out any fields that are empty
    .map((field) => {
      return {
        type: {
          id: field,
          label: field
        },
        value: getValueFromStringOrValueListValue(address[field])
      };
    });

  return { ...address, areas };
}
