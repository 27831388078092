import { Generator, GeneratorConfig } from 'data/models/generator';
import {
  contactModelConfig,
  contactsModel
} from 'src/modules/contacts/models/contacts';
import { Contact } from 'src/modules/contacts/types/contact-types';
import { BasePendingDisbursement } from '.';

export type PendingContactDisbursement = BasePendingDisbursement & {
  object_type: 'contact';
  object: Contact;
};

const config: GeneratorConfig = {
  entities: {
    related: {
      ...contactModelConfig.entities.related,
      payment_methods: {
        include: 'payment_methods'
      },
      last_disbursement: {
        include: 'last_disbursement'
      },
      disburse_summary: {
        include: 'disburse_summary'
      },
      financial_summary: {
        include: 'financial_summary'
      },
      disburseable_bpay_invoices: {
        include: 'disburseable_bpay_invoices'
      },
      other_disburseable_invoices: {
        include: 'other_disburseable_invoices'
      },
      object: {
        include: 'object',
        model: contactsModel
      },
      disburseable_direct_debit_invoices: {
        include: 'disburseable_direct_debit_invoices'
      },
      trust_journal_entry_preview: {
        include: 'trust_journal_entry_preview.paid_to'
      },
      general_invoices: {
        include: 'general_invoices'
      },
      disbursement_snapshot_summary: {
        include: 'disbursement_snapshot_summary'
      }
    }
  }
};

export const financialsPendingDisbursementsContactsModel = new Generator<PendingContactDisbursement>(
  'financials/pending-disbursements/contacts',
  config
).createEntityModel();
