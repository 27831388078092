import React from 'react';

import { Field } from '@rexlabs/form';

import {
  SimpleFileUploadInputWithErrorHandling,
  SimpleFileUploadInputWithErrorHandlingProps
} from 'view/components/inputs/file-upload-input/simple-file-upload-input-with-error-handling';

export function UploadAttachments({
  label = 'Attachments',
  ...rest
}: SimpleFileUploadInputWithErrorHandlingProps & { label?: string }) {
  return (
    <Field<typeof SimpleFileUploadInputWithErrorHandling>
      id='attachments'
      name='attachments'
      label={label}
      Input={SimpleFileUploadInputWithErrorHandling}
      inputProps={{
        multi: true,
        ...rest
      }}
    />
  );
}
