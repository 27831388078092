import { ValueListValue } from 'data/models/types';
import { lowerCase } from 'lodash';
import { RelatedDatesData } from '../hooks/use-checklist-item-related-dates-query';

export function getOffsetRelatedDatesFromRelatedDatesData(
  relatedDatesData: RelatedDatesData
): ValueListValue<string>[] {
  const relatedDates = relatedDatesData?.related_dates || [];
  const offsetDirectionExcludedValues =
    relatedDatesData?.offset_direction_excluded_values;

  return relatedDates.reduce((result, current) => {
    if (
      !offsetDirectionExcludedValues.before.some(
        (item) => item.id === current.id
      )
    ) {
      result.push({
        id: `before_${current.id}`,
        label: `Before ${lowerCase(current.label)}`
      });
    }
    if (
      !offsetDirectionExcludedValues.after.some(
        (item) => item.id === current.id
      )
    ) {
      result.push({
        id: `after_${current.id}`,
        label: `After ${lowerCase(current.label)}`
      });
    }

    return result;
  }, [] as ValueListValue<string>[]);
}
