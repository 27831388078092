import React from 'react';
import { ChecklistTemplatesItemForm } from '../../types/ChecklistTemplatesForm';

// create a context
export const ChecklistTemplateItemIdentifierContext = React.createContext<{
  getIdentifierForItem: (item: any) => string;
}>({
  getIdentifierForItem: (_item) => ''
});

const { Provider } = ChecklistTemplateItemIdentifierContext;

type Props = {
  getIdentifierForItem: (item: ChecklistTemplatesItemForm) => string;
};

// create a provider
export const ChecklistTemplateItemIdentifierProvider: React.FC<Props> = ({
  children,
  getIdentifierForItem
}) => {
  return <Provider value={{ getIdentifierForItem }}>{children}</Provider>;
};
