import { useTranslation } from 'react-i18next';
import { InspectionTask } from 'src/modules/tasks/inspections/types/InspectionTask';
import { useFeatureFlags } from 'view/components/@luna/feature-flags';
import { FLAGS } from 'utils/feature-flags';

export function useGetReportName() {
  const { t } = useTranslation();
  const { hasFeature } = useFeatureFlags();

  return (inspectionTask: InspectionTask) => {
    const typeId = inspectionTask?.details?.type.id;

    if (
      !hasFeature(FLAGS.ENTRY_EXIT_INSPECTION_REPORT) ||
      typeId === 'routine_inspection'
    ) {
      return 'Inspection report';
    }

    return typeId === 'entry_inspection'
      ? t('tasks.inspections.report.entry-inspection-report.label')
      : t('tasks.inspections.report.exit-inspection-report.label');
  };
}
