import { useGetGenericWipAction } from 'view/hooks/actions/use-get-generic-wip-action';

export function useGetEditAddress() {
  const getGenericWipAction = useGetGenericWipAction();

  return () =>
    getGenericWipAction({
      id: 'edit-address',
      group: 'edit',
      actionName: 'Edit address details'
    });
}
