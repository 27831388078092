import { RecordTypes } from 'data/models/types';
import { ActionDeclaration } from 'src/modules/common/actions/types/action-declaration-types';
import { Statement, StatementStatus } from 'src/modules/statements/types';
import { useConfirmExcludeEofyStatementAction } from 'src/modules/statements/hooks/use-confirm-exclude-eofy-statement-action';
import { useIssueSingleStatementAction } from 'src/modules/statements/hooks/use-issue-single-statement-action';
import { useGetStatementViewActions } from 'src/modules/ownership-statements/common/actions/use-get-statement-view-actions';

export function useGetEofyStatementActions(): (
  statement: Statement
) => ActionDeclaration[] {
  const getConfirmExcludeStatementAction = useConfirmExcludeEofyStatementAction();
  const getIssueAction = useIssueSingleStatementAction();

  const getStatementViewActions = useGetStatementViewActions();

  return function (statement: Statement) {
    const { summaryViewActions, detailedViewActions } = getStatementViewActions(
      statement
    );

    const actions: ActionDeclaration[] = [];

    // for not-yet-issued statements, add view actions for single-preview
    if (
      ![StatementStatus.Void, StatementStatus.Issued].includes(
        statement.status.id
      )
    ) {
      actions.push(...summaryViewActions, ...detailedViewActions);
    }

    if (
      ![StatementStatus.Issued, StatementStatus.Excluded].includes(
        statement.status.id
      )
    ) {
      actions.push(
        getConfirmExcludeStatementAction({
          statement,
          recordType: RecordTypes.Statement
        })
      );
    }

    if (statement.status.id === StatementStatus.Excluded) {
      actions.push(
        getIssueAction({
          statement,
          recordType: RecordTypes.Statement,
          label: 'Restore and issue'
        })
      );
    }

    return actions;
  };
}
