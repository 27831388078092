import { TwoTierCell } from 'view/components/cells/two-tier-cell';
import React from 'react';

export function NoteSummaryCell({ value }) {
  return (
    <TwoTierCell
      text={value.note_type?.label}
      subtext={value.phone_call_outcome?.label || ''}
    />
  );
}
