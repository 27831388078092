import { useDialog } from '@rexlabs/dialog';

import { SingleActionDeclaration } from 'src/modules/common/actions/types/action-declaration-types';
import { MaintenanceTask } from 'src/modules/tasks/maintenance/types/MaintenanceTask';
import { QuoteTask } from 'src/modules/tasks/quotes/types/QuoteTask';
import { noop } from 'lodash';
import AddIcon from 'view/components/icons/add';

import { ownershipsModel } from 'data/models/entities/ownerships';
import { getSearchResultItemFromObjectAndModel } from 'src/modules/common/utils/search-result-items/get-search-result-items-from-object-and-model';
import { IssueWorkOrderDialog } from '../../dialogs/create-work-order-dialog';
import { mapRelatedTaskToCreateWorkOrderForm } from '../../mappers/map-related-task-to-create-work-order-form';
import { WorkOrderFormData } from '../../types/WorkOrderFormData';
import { SelectWorkOrderTypeDialog } from '../../dialogs/select-work-order-type-dialog';

interface UseGetCreateWorkOrderActionProps {
  onCreate?: (data) => void;
  isNested?: boolean;
  initialValues?: Partial<WorkOrderFormData>;
}

function getInitialFormValues(initialValues) {
  const ownership =
    initialValues?.property?.active_property_ownership?.ownership;

  return {
    ...initialValues,
    details: {
      ...initialValues?.details,
      work_done_by_type: 'supplier',
      ...(ownership
        ? {
            bill_to: getSearchResultItemFromObjectAndModel(
              ownership,
              ownershipsModel
            )
          }
        : {})
    }
  };
}

export function useGetCreateWorkOrderAction(
  { onCreate, isNested, initialValues }: UseGetCreateWorkOrderActionProps = {
    isNested: false,
    onCreate: noop,
    initialValues: {}
  }
) {
  const { open: openIssueWorkOrderDialog } = useDialog(IssueWorkOrderDialog, {
    target: isNested ? 'nested-dialogs' : 'dialogs'
  });
  const { open: openSelectWorkOrderTypeDialog } = useDialog(
    SelectWorkOrderTypeDialog
  );

  return (
    relatedTask?: MaintenanceTask | QuoteTask
  ): SingleActionDeclaration => {
    return {
      id: 'create-task-work-order',
      intent: 'primary',
      group: 'task-work-order',
      label: 'Issue work order',
      Icon: AddIcon,
      handleAction: async () => {
        relatedTask?.type.id === 'task_quote'
          ? openIssueWorkOrderDialog({
              onCreate,
              initialValues: await mapRelatedTaskToCreateWorkOrderForm(
                relatedTask
              )
            })
          : openSelectWorkOrderTypeDialog({
              onCreate,
              initialValues: relatedTask
                ? await mapRelatedTaskToCreateWorkOrderForm(relatedTask)
                : getInitialFormValues(initialValues)
            });
      }
    };
  };
}
