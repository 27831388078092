import { InspectionTask } from 'src/modules/tasks/inspections/types/InspectionTask';
import { hasReportBeenStarted } from 'src/modules/tasks/inspections/utils/inspection-report-helpers';
import { useGetHasRoomsOrAreas } from 'src/modules/tasks/inspections/hooks/useGetHasRoomsOrAreas';

export function useGetShowInspectionReportEmptyState() {
  const getHasRoomsOrAreas = useGetHasRoomsOrAreas();
  return (inspectionTask: InspectionTask) => {
    return (
      !hasReportBeenStarted(inspectionTask) ||
      !getHasRoomsOrAreas(inspectionTask)
    );
  };
}
