import { Box } from '@rexlabs/box';
import { reduce } from 'lodash';
import React, { ReactNode } from 'react';
import { FLAGS } from 'utils/feature-flags';
import { BlockConfig } from 'view/components/record-screen/types';

import { useFeatureFlags } from 'view/components/@luna/feature-flags';
import { AlfredFile } from 'src/modules/common/types/file';
import { FakeTabs } from 'view/components/fake-tabs';
import { EmailPreview } from '../../common/components/email/email-preview';
import {
  SMSPhonePreview,
  SMSPhonePreviewContainer
} from '../../common/components/sms-phone-preview';
import { BasicEditSMSForm } from '../../messages/components/message-content-forms/basic-forms/basic-edit-sms-form';
import { useMessageTriggerSettings } from '../../messages/settings/message-triggers/hooks/use-message-trigger-settings';
import { MessageTemplate } from '../types/MessageTemplate';
import { CreateTemplateFormData } from '../types/create-template-form-data';
import { LetterPreview } from '../../common/components/letter-preview';
import { EditLetterContentForm } from '../../messages/components/message-content-forms/edit-letter-content-form';
import { EditEmailTemplateContentForm } from '../../messages/components/message-content-forms/edit-email-template-content-form';
import { mapTemplateToCreateMessageTemplateFormData } from '../mappers/map-template-to-create-template-form-data';

enum TabNames {
  Email = 'email',
  Sms = 'sms',
  Letter = 'letter'
}

export const messageTemplateContentBlock: BlockConfig<
  MessageTemplate,
  any,
  CreateTemplateFormData
> = {
  id: 'content',
  title: 'Message Content',
  isEditable: (template) => !template?.system_purpose?.id,
  View: ({ data }) => {
    const values = mapTemplateToCreateMessageTemplateFormData(data!);

    const { hasFeature } = useFeatureFlags();
    const {
      getTemplatedAttachmentForContextType
    } = useMessageTriggerSettings();
    const content = values?.content || '';
    const subject = values?.subject || '';

    const templatedAttachmentsForContextType = getTemplatedAttachmentForContextType(
      data?.context_type?.id
    );

    const includedTemplatedAttachments =
      values?.include_generated_attachments || {};

    const templatedAttachments = reduce<
      typeof includedTemplatedAttachments,
      Array<AlfredFile>
    >(
      includedTemplatedAttachments,
      (acc, val, key) => {
        const templatedAttachment = templatedAttachmentsForContextType.find(
          (attachment) => attachment.id === key && val
        );

        return [
          ...acc,
          ...(templatedAttachment
            ? [
                {
                  name: templatedAttachment.label,
                  type: 'application/system-pdf'
                } as AlfredFile
              ]
            : [])
        ];
      },
      []
    );

    const complianceTypes = (values?.compliance_types || []).map(
      (complianceType) =>
        ({
          name: `${complianceType.label} certificates from the ${data?.context_type?.label} record will be attached`,
          type: 'application/system-pdf'
        } as AlfredFile)
    );

    const documentTypes = (values?.document_types || []).map(
      (documentType) =>
        ({
          name: `${documentType.label} documents from the ${data?.context_type?.label} record will be attached`,
          type: 'application/system-pdf'
        } as AlfredFile)
    );

    const attachments = React.useMemo(() => {
      return [
        ...documentTypes,
        ...complianceTypes,
        ...templatedAttachments,
        ...(values?.attachments || []).map((attachment) => attachment.file)
      ];
    }, [
      complianceTypes,
      documentTypes,
      templatedAttachments,
      values?.attachments
    ]);

    const tabItems = [
      {
        label: 'Email',
        name: TabNames.Email,
        Tab: (
          <TabContainer>
            <EmailPreview
              bodyContent={content}
              subject={subject}
              recipients='recipients'
              attachedFiles={attachments}
            />
          </TabContainer>
        )
      },
      ...(hasFeature(FLAGS.SMS)
        ? [
            {
              label: 'SMS',
              name: TabNames.Sms,
              Tab: (
                <TabContainer>
                  <SMSPhonePreviewContainer>
                    <SMSPhonePreview>{values?.smsContent}</SMSPhonePreview>
                  </SMSPhonePreviewContainer>
                </TabContainer>
              )
            }
          ]
        : []),
      ...(hasFeature(FLAGS.LETTERS)
        ? [
            {
              label: 'Letter',
              name: TabNames.Letter,
              Tab: (
                <TabContainer>
                  <LetterPreview
                    bodyContent={values?.letterContent ?? ''}
                    address={values?.letterAddress ?? ''}
                    attachedFiles={attachments}
                  />
                </TabContainer>
              )
            }
          ]
        : [])
    ];

    return <FakeTabs items={tabItems} />;
  },
  Edit: ({ values, setFieldValue }) => {
    const { hasFeature } = useFeatureFlags();
    const {
      getTemplatedAttachmentForContextType
    } = useMessageTriggerSettings();

    const contextType = values?.context_type?.id;

    const tabItems = [
      {
        label: 'Email',
        name: TabNames.Email,
        Tab: (
          <TabContainer>
            <EditEmailTemplateContentForm
              contextType={contextType}
              setFieldValue={setFieldValue}
              templatedAttachments={getTemplatedAttachmentForContextType(
                values?.context_type?.id
              )}
            />
          </TabContainer>
        )
      },
      ...(hasFeature(FLAGS.SMS)
        ? [
            {
              label: 'SMS',
              name: TabNames.Sms,
              Tab: (
                <TabContainer>
                  <BasicEditSMSForm
                    contextType={contextType}
                    setFieldValue={setFieldValue}
                  />
                </TabContainer>
              )
            }
          ]
        : []),
      ...(hasFeature(FLAGS.LETTERS)
        ? [
            {
              label: 'Letter',
              name: TabNames.Letter,
              Tab: (
                <TabContainer>
                  <EditLetterContentForm
                    textContentFieldName='letterContent'
                    contextType={contextType}
                    templatedAttachments={getTemplatedAttachmentForContextType(
                      values?.context_type?.id
                    )}
                    setFieldValue={setFieldValue}
                  />
                </TabContainer>
              )
            }
          ]
        : [])
    ];

    return <FakeTabs items={tabItems} />;
  }
};

function TabContainer({ children }: { children: ReactNode }) {
  return <Box marginTop={24}>{children}</Box>;
}
