import React from 'react';
import { useTranslation } from 'react-i18next';

import { BlockConfig } from 'view/components/record-screen/types';
import { Statement, Summary } from 'view/components/statement';

export const ownershipStatementSummaryBlock: BlockConfig = {
  id: 'ownership-statement-summary',
  title: 'Statement summary',
  View: () => {
    const { t } = useTranslation();

    return (
      <Statement>
        <Summary
          items={[
            {
              label: 'Opening balance',
              type: 'subtotal',
              amount: 0
            },

            {
              label: 'Receipt & transfers to',
              type: 'inbound',
              amount: 0
            },
            {
              label: 'Withdrawals & transfers from',
              type: 'outbound',
              amount: 0
            },
            {
              label: 'Trust balance',
              type: 'subtotal',
              amount: 0
            },

            {
              label: 'Uncleared amounts',
              type: 'outbound',
              amount: 0
            },
            {
              label: t(
                'financials.trust.available-trust-balance.label.singular'
              ),
              type: 'total',
              amount: 0
            }
          ]}
        />
        <Summary
          isContrast
          items={[
            {
              label: 'Income & expenditure',
              type: 'subtotal'
            },
            {
              label: 'Income',
              type: 'inbound',
              amount: 0
            },
            {
              label: 'Invoices receivable',
              type: 'inbound',
              amount: 0
            },
            {
              label: 'Expenses',
              type: 'outbound',
              amount: 0
            },

            {
              label: 'Bills & feess payable',
              type: 'outbound',
              amount: 0
            },
            {
              label: 'Net income',
              type: 'total',
              amount: 0
            }
          ]}
        />
      </Statement>
    );
  },
  workInProgress: true
};
