import React from 'react';

import { BlockConfig } from 'view/components/record-screen/types';
import Box from '@rexlabs/box';
import { Value } from 'view/components/values';
import { Field } from '@rexlabs/form';
import { useToken } from '@rexlabs/styling';
import { Column, Grid } from 'view/components/@luna/form/grid';
import { ContactSelect } from 'view/components/inputs/selects/contact';
import { PropertyComplianceTask } from '../types/PropertyComplianceTask';

const validate = {
  definitions: {
    status: { name: 'progress', rules: 'required' },
    managed_by: { name: 'managed by', rules: 'required' }
  }
};

export const propertyComplianceDetailsBlock: BlockConfig<PropertyComplianceTask> = {
  id: 'details',
  title: 'Details',
  validate,
  View: ({ data }) => {
    const complianceTypeName =
      data?.details?.compliance_type?.label ||
      data?.details?.compliance_entry?.compliance_type?.label;
    return (
      <Grid columns={2}>
        <Value label='Compliance requirement' value={complianceTypeName} />
        <Column width={2}>
          <Value label='Description' value={data?.description} />
        </Column>

        <Value label='Property' value={data?.property?.address_string} />
        {/* div supplies the content for the column next to property */}
        <div />
        <Value label='Managed by' value={data?.managed_by?.display_name} />
      </Grid>
    );
  },
  Edit: ({ data, editMode }) => {
    const token = useToken();
    const complianceTypeName =
      data?.details?.compliance_type?.label ||
      data?.details?.compliance_entry?.compliance_type?.label;
    return (
      <Box sy={token('spacing.xxl')}>
        <Grid columns={2}>
          <Value
            label='Compliance requirement'
            value={complianceTypeName || null}
          />

          <Column width={2}>
            <Value label='Description' value={data?.description} />
          </Column>
        </Grid>
        <Grid columns={2}>
          <Value label='Property' value={data?.property?.address_string} />
          <div />
          <Field
            id='managed_by'
            name='managed_by'
            label='Managed by'
            Input={ContactSelect}
            inputProps={{
              // NOTE: we need to disable the fixture when editing the block, as it causes the app to
              // become unresponsive. https://rexsoftware.atlassian.net/browse/ALFIE-2066
              disableFixture: !!editMode
            }}
          />
        </Grid>
      </Box>
    );
  }
};
