import React, { useEffect } from 'react';

import { Field } from '@rexlabs/form';
import { TextArea } from '@rexlabs/text-input';
import { toQuri } from '@rexlabs/quri';

import { BlockConfig } from 'view/components/record-screen/types';
import { Value } from 'view/components/values';
import { Grid, Column } from 'view/components/@luna/form/grid';
import { PropertySelect } from 'view/components/inputs/selects/property-select';
import { ContactSelect } from 'view/components/inputs/selects/contact';

import { Property } from 'src/modules/properties/types/property-types';

import { LeaseReviewProgressSelect } from '../components/progress-select';
import { LeaseReview } from '../types/LeaseReview';
import { LeaseReviewFormData } from '../types/LeaseReviewFormData';
import { onPropertyChangeHandler } from '../../common/utils/on-property-change-handler';

const validate = {
  definitions: {
    property: { rules: 'required' },
    managed_by: { rules: 'required' },
    'details.status': { rules: 'required', name: 'status' }
  }
};

export const leaseReviewDetailsBlock: BlockConfig<
  LeaseReview,
  any,
  LeaseReviewFormData
> = {
  id: 'details',
  title: 'Details',
  validate,

  View: ({ data }) => {
    return (
      <Grid columns={2}>
        <Column width={2}>
          <Value label='Description' value={data?.description} />
        </Column>

        <Value label='Property' value={data?.property?.address_string} />

        <Value
          label='Tenancy'
          value={data?.details?.property_tenancy?.tenancy?.display_name}
        />

        <Value label='Managed by' value={data?.managed_by?.display_name} />
      </Grid>
    );
  },

  Edit: ({ values, setFieldValue, editMode }) => {
    useEffect(() => {
      onPropertyChangeHandler({
        values,
        setFieldValue,
        taskType: 'task_lease_review'
      });
    }, [values?.property]);

    const setPropertyTenancyAndSummaryFromProperty = React.useCallback(
      (property?: Property) => {
        if (property != null) {
          setFieldValue?.(
            'details.property_tenancy',
            property.active_property_tenancy
          );
          setFieldValue?.(
            'summary',
            property.active_property_tenancy?.tenancy?.display_name
          );
        }
      },
      []
    );

    // Set property tenancy and summary values on init if initial property provided
    useEffect(() => {
      if (!editMode && values?.property) {
        setPropertyTenancyAndSummaryFromProperty(values?.property);
      }
    }, []);

    return (
      <Grid columns={2}>
        <Column width={2}>
          <Field name='description' label='Description' Input={TextArea} />
        </Column>

        {editMode ? (
          <Value label='Property' value={values?.property?.address_string} />
        ) : (
          <Field
            name='property'
            label='Property'
            onBlur={(event) =>
              setPropertyTenancyAndSummaryFromProperty(event?.target?.value)
            }
            Input={PropertySelect}
            inputProps={{
              includes: [
                'active_property_tenancy',
                'active_property_tenancy.tenancy'
              ],
              filters: toQuri([
                {
                  field: 'active_property_tenancy_id',
                  op: 'neq',
                  value: 'null'
                }
              ])
            }}
          />
        )}

        <Value
          label='Tenancy'
          value={values?.details?.property_tenancy?.tenancy?.display_name}
        />

        <Grid columns={2}>
          <Field
            id='managed_by'
            name='managed_by'
            label='Managed by'
            Input={ContactSelect}
            inputProps={{
              // NOTE: we need to disable the fixture when editing the block, as it causes the app to
              // become unresponsive. https://rexsoftware.atlassian.net/browse/ALFIE-2066
              disableFixture: !!editMode
            }}
          />
          {!editMode && (
            <Field
              id='status'
              name='details.status'
              label='Progress'
              Input={LeaseReviewProgressSelect}
            />
          )}
        </Grid>
      </Grid>
    );
  }
};
