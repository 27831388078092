import React from 'react';

import { RecordFlag } from 'view/components/record-flags/record-flag';
import { Task } from 'src/modules/tasks/common/types/Task';
import { TaskType } from 'src/modules/tasks/common/types/TaskType';

import { getRecordLabel } from 'utils/records/get-record-label';

import { cap, hasMoreThan } from '../utils';

import { EntryList } from '../components/entry-list';
import { EntryLink } from '../components/entry-link';
import { ViewLink } from '../components/view-link';

export interface LinkedTasksProps {
  linkedTasks: Task<TaskType>[];
  maxListLength: number;
}

const MAX_RELATED_RECORDS = 3;

function getDescription(task: Task<TaskType>): string {
  const status = task.status.label;
  const managedBy = task.managed_by;

  let description = status;

  if (managedBy) {
    description += ` • ${getRecordLabel({
      object: managedBy,
      type: 'contact'
    })}`;
  }

  return description;
}

export function LinkedTasksSection({ linkedTasks }: LinkedTasksProps) {
  return (
    <EntryList>
      {cap(linkedTasks, MAX_RELATED_RECORDS).map((task) => (
        <EntryLink key={task.id} type={task.type.id} id={task.id}>
          <RecordFlag
            record={task}
            recordType={task.type.id}
            description={getDescription(task)}
          />
        </EntryLink>
      ))}
      {hasMoreThan(linkedTasks, MAX_RELATED_RECORDS) && (
        <ViewLink query={{ tab: 'linked-tasks' }}>
          {`View all linked tasks (${linkedTasks.length})`}
        </ViewLink>
      )}
    </EntryList>
  );
}
