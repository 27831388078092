import { SingleActionDeclaration } from 'src/modules/common/actions/types/action-declaration-types';
import { RecordTypes } from 'data/models/types';
import { useIssueStatementConfirmationDialog } from 'src/modules/ownership-statements/common/dialogs/issue-statement-confirmation-dialog';
import {
  Statement,
  StatementStatus,
  StatementType
} from 'src/modules/statements/types';
import { useCreateStatements } from 'src/modules/statements/hooks/use-create-statements';
import { Ownership } from 'data/models/entities/ownerships';
import { useModelActions } from '@rexlabs/model-generator';
import { statementsModel } from 'src/modules/statements/models/statements';
import { useErrorDialog } from '@rexlabs/dialog';
import { useToast } from 'view/components/@luna/notifications/toast';
import { issueStatementToastAction } from 'src/modules/ownership-statements/common/actions/issue-statement-toast-action';
import { useBulkEditStatement } from 'src/modules/statements/hooks/use-bulk-edit-statement';

export interface IssueExistingStatementArgs {
  selectedItems: Statement[];
  recordType: typeof RecordTypes.Statement;
  ignore_agency_fees?: boolean;
}

interface CreateStatementArgs {
  recordType: typeof RecordTypes.Ownership;
  ownerships: Ownership[];
  statementType: StatementType;
  statementStartDate: string;
  statementEndDate: string;
}

interface UpdateStatementArgs {
  recordType: typeof RecordTypes.Statement;
  statements: Statement[];
  statementType?: StatementType;
}

export type IssueStatementArgs = CreateStatementArgs | UpdateStatementArgs;

export function useBulkIssueStatementAction() {
  const openIssueStatementConfirmation = useIssueStatementConfirmationDialog();
  const createStatements = useCreateStatements();
  const errorDialog = useErrorDialog();
  const { addToast } = useToast();
  const { mutateAsync } = useBulkEditStatement();
  const { refreshLists: refreshStatementLists } = useModelActions(
    statementsModel
  );

  return (args: IssueStatementArgs): SingleActionDeclaration => {
    const toastAction = issueStatementToastAction(
      args.statementType || StatementType.YearlyOwnership
    );
    const onConfirm = async ({ selectedItems }: IssueExistingStatementArgs) => {
      try {
        await mutateAsync({
          selectedIds: selectedItems.map((item) => item.id),
          data: {
            status: {
              label: 'Issued',
              id: StatementStatus.Issued
            }
          }
        });

        await refreshStatementLists();

        addToast({
          description: 'The requested statements are being issued.',
          color: 'information',
          actions: [toastAction]
        });

        return true;
      } catch (error) {
        errorDialog.open(error);
      }
    };

    const confirmationProps =
      args.recordType === RecordTypes.Ownership
        ? {
            selectedItems: args.ownerships,
            handleConfirmation: createStatements,
            recordType: RecordTypes.Ownership,
            status: StatementStatus.Issued,
            statementType: args.statementType,
            statementEndDate: args.statementEndDate,
            statementStartDate: args.statementStartDate
          }
        : {
            selectedItems: args.statements,
            handleConfirmation: onConfirm,
            recordType: RecordTypes.Statement,
            status: StatementStatus.Issued,
            statementType: args.statementType || StatementType.YearlyOwnership
          };

    return {
      id: 'issue_statement',
      group: 'status',
      label: 'Issue statements',
      handleAction: () => openIssueStatementConfirmation(confirmationProps)
    };
  };
}
