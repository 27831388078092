import * as React from 'react';

import { usePutSettingsMutation } from 'src/modules/settings/hooks/usePutSettingsMutation';
import {
  AutomaticTaskCreationValues,
  useSettings
} from 'src/modules/settings/hooks/useSettings';

import { TitleBlock } from 'view/components/@luna/title-block';
import { RecordScreen } from 'view/components/record-screen';
import { RecordSubmitHandler } from 'view/components/record-screen/utils';

import {
  mapTaskSettingsFormToApi,
  TaskSettingKey
} from 'src/modules/tasks/settings/utils/map-task-settings-form-to-api';
import { ServicePackage } from 'src/modules/service-packages/types/service-package';
import { useMapInspectionSettings } from 'src/modules/tasks/settings/mappers/use-map-inspection-settings';
import { useMapLeaseReviewSettings } from 'src/modules/tasks/settings/mappers/use-map-lease-review-settings';
import { useMapPropertyComplianceSettings } from 'src/modules/tasks/settings/mappers/use-map-property-compliance-settings';
import { useContent } from '../data/content';

type Count = number | null;

export type BaseTaskSettingFormValue = {
  count: Count;
  unit: string;
};

export interface TaskSettingsFormValues {
  'property-compliance-task': BaseTaskSettingFormValue & {
    automatic_task_creation: AutomaticTaskCreationValues;
    service_packages: ServicePackage[];
  };
  'supplier-compliance-task': BaseTaskSettingFormValue;
  first_inspection: BaseTaskSettingFormValue;
  routine_inspection: {
    count: Count;
    unit: string;
    is_active: boolean;
  };
  notice_period: BaseTaskSettingFormValue;
  periodic_agreement_review: BaseTaskSettingFormValue;
  periodic_agreement_notice: BaseTaskSettingFormValue;
  fixed_agreement_notice: BaseTaskSettingFormValue;
  inspection_automatic_task_creation: AutomaticTaskCreationValues;
  inspection_service_packages: ServicePackage[];
  lease_review_automatic_task_creation: AutomaticTaskCreationValues;
  lease_review_service_packages: ServicePackage[];
}

export function TaskSettingsDetail() {
  const content = useContent();
  const putSettingsMutation = usePutSettingsMutation();

  const settings = useSettings();
  const {
    isLoading: isLoadingPropertyCompliance,
    propertyComplianceSettings
  } = useMapPropertyComplianceSettings(settings['property-compliance-task']);
  const supplierComplianceSettings = settings['supplier-compliance-task'];
  const {
    isLoading: isLoadingInspection,
    inspectionSettings
  } = useMapInspectionSettings(settings['inspection-task-interval']);
  const {
    isLoading: isLoadingLeaseReview,
    leaseReviewSettings
  } = useMapLeaseReviewSettings(settings['lease-review-task-interval']);

  const handleSubmit: RecordSubmitHandler = async ({
    values,
    changedBlockIds
  }: {
    values: TaskSettingsFormValues;
    changedBlockIds: string[];
  }) => {
    const mappedValues = mapTaskSettingsFormToApi(
      values,
      changedBlockIds as TaskSettingKey[]
    );

    await putSettingsMutation.mutateAsync(mappedValues);
  };

  return (
    <RecordScreen
      data={{
        'property-compliance-task': propertyComplianceSettings,
        'supplier-compliance-task': supplierComplianceSettings,
        ...inspectionSettings,
        ...leaseReviewSettings
      }}
      isLoading={
        isLoadingInspection &&
        isLoadingLeaseReview &&
        isLoadingPropertyCompliance
      }
      content={content}
      handleSubmit={handleSubmit}
      titleBlock={<TitleBlock title='Task settings' />}
    />
  );
}
