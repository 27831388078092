import { fragment } from '@rexlabs/model-generator';

export const workOrderQueryIncludesFragment = fragment`
  id
  managed_by
  parent_task
  property {
    active_property_ownership {
      ownership {
        owners
      }
    }
    active_property_tenancy {
        tenancy
    }
  }
  invoices_summary
  workOrderDetails
`;
