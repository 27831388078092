import React from 'react';
import { Field } from '@rexlabs/form';

import { BlockConfig } from 'view/components/record-screen/types';
import { Column, Grid } from 'view/components/@luna/form/grid';
import { Value } from 'view/components/values';
import { BankAccountSelect } from 'src/modules/bank-accounts/components/bank-account-select';
import { JobPrioritySelect } from 'view/components/inputs/selects/job-priority';
import { StatBlocks } from 'view/components/@luna/stat-block';
import { SupplierComplianceCategorySelect } from 'view/components/inputs/selects/v4-selects/supplier-compliance-category';
import { useFeatureFlags } from 'view/components/@luna/feature-flags';
import { CONTACTS_FLAGS } from 'src/modules/contacts/feature-flags';

export const supplierInfoBlock: BlockConfig = {
  id: 'supplier',
  title: '[SUPPLIER] Supplier details',
  validate: {
    definitions: {
      compliance_categories: { rules: 'required', name: 'services offered' }
    }
  },
  View: ({ data }) => {
    const { hasFeature } = useFeatureFlags();
    const showContactMaintenanceDetails = hasFeature(
      CONTACTS_FLAGS.CONTACT_MAINTENANCE_DETAILS
    );
    return (
      <>
        {showContactMaintenanceDetails && (
          <StatBlocks
            stats={[
              {
                label: 'Maintenance jobs assigned',
                value: '0 jobs',
                intent: 'neutral',
                workInProgress: true
              },
              {
                label: 'Quote requests open',
                value: '0 quotes',
                intent: 'neutral',
                workInProgress: true
              }
            ]}
          />
        )}
        <Grid columns={2}>
          <Column width={2}>
            <Value
              label='Services offered'
              value={data?.compliance_categories
                ?.map?.(({ label }) => label)
                ?.join?.(', ')}
            />
          </Column>
          {showContactMaintenanceDetails && (
            <>
              <Value
                label='Default account'
                value={data?.default_account?.name}
              />
              <Value
                label='Default Bill priority'
                value={data?.default_bill_priority?.label}
              />
            </>
          )}
        </Grid>
      </>
    );
  },
  Edit: ({ data }) => {
    const { hasFeature } = useFeatureFlags();
    const showContactMaintenanceDetails = hasFeature(
      CONTACTS_FLAGS.CONTACT_MAINTENANCE_DETAILS
    );
    return (
      <Grid columns={2}>
        <Column width={2}>
          <Field
            name='compliance_categories'
            id='categories'
            label='Services offered'
            Input={SupplierComplianceCategorySelect}
            inputProps={{ multi: true }}
          />
        </Column>
        {showContactMaintenanceDetails && (
          <>
            <Field
              name='default_account'
              label='Default account'
              Input={BankAccountSelect}
              inputProps={{ contactId: data?.id }}
            />
            <Field
              name='default_bill_priority'
              label='Default Bill priority'
              Input={JobPrioritySelect}
            />
          </>
        )}
      </Grid>
    );
  }
};
