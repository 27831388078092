import React from 'react';
import { Field } from '@rexlabs/form';
import { TextInput } from '@rexlabs/text-input';

import { BlockConfig } from 'view/components/record-screen/types';
import { Grid } from 'view/components/@luna/form/grid';
import { Value } from 'view/components/values';
import { WorkInProgress } from 'view/components/work-in-progress';
import { DateValue } from 'view/components/values/date';
import { DateInput } from 'view/components/@luna/inputs/date-input/date-input';
import { StyleSheet, useStyles } from '@rexlabs/styling';
import { Link } from '@rexlabs/whereabouts';
import { isContactAUser } from 'src/modules/contacts/utils/is-contact-a-user';

const defaultStyles = StyleSheet({
  link: {
    color: ({ token }) => token('color.textStyle.link.idle.contrast'),
    '&:hover': {
      color: ({ token }) => token('color.textStyle.link.hover.contrast')
    },
    '&:active': {
      color: ({ token }) => token('color.textStyle.link.active.contrast')
    }
  }
});

export const ProfileSettingsTooltip = () => {
  const s = useStyles(defaultStyles);
  return (
    <>
      This field can be updated through the{' '}
      <Link {...s('link')} to={'/profile-settings'}>
        profile settings
      </Link>
    </>
  );
};

export const detailsBlock: BlockConfig = {
  id: 'details',
  title: ({ blockProps, data }) => {
    const type = blockProps?.type || data?.type?.id;
    return type === 'business' ? 'Primary contact' : 'Contact details';
  },
  validate: {
    definitions: {
      given_name: {
        rules: 'required_if:type.id,individual',
        name: 'first name'
      },
      family_name: {
        rules: 'required_if:type.id,individual',
        name: 'last name'
      }
    },
    messages: {
      required_if: 'The :attribute field is required.'
    }
  },
  View: ({ values }) => (
    <Grid columns={4}>
      <Value label='First name' value={values?.given_name} />
      <Value label='Last name' value={values?.family_name} />
      <WorkInProgress>
        <Value label='Salutation' value={values?.salutation_name} />
      </WorkInProgress>
      <DateValue label='Date of birth' value={values?.date_of_birth} />
    </Grid>
  ),
  Edit: ({ data, values, blockProps }) => {
    const isIndividual = blockProps
      ? blockProps?.type === 'individual'
      : !data?.is_business;

    return (
      <Grid columns={4}>
        <Field name='title' label='Title' Input={TextInput} />

        {isContactAUser(data) ? (
          <Value
            label='First name'
            value={values?.given_name}
            HelpTooltipContent={ProfileSettingsTooltip}
          />
        ) : (
          <Field
            name='given_name'
            label='First name'
            Input={TextInput}
            optional={!isIndividual}
          />
        )}

        <Field name='middle_name' label='Middle name' Input={TextInput} />

        {isContactAUser(data) ? (
          <Value
            label='Last name'
            value={values?.family_name}
            HelpTooltipContent={ProfileSettingsTooltip}
          />
        ) : (
          <Field
            name='family_name'
            label='Last name'
            Input={TextInput}
            optional={!isIndividual}
          />
        )}

        {!!data?.id && (
          <>
            <WorkInProgress>
              <Field
                name='salutation_name'
                label='Salutation'
                Input={TextInput}
              />
            </WorkInProgress>
            <WorkInProgress>
              <Field name='addresse_name' label='Addresse' Input={TextInput} />
            </WorkInProgress>
            <WorkInProgress>
              <Field name='legal_name' label='Legal' Input={TextInput} />
            </WorkInProgress>
          </>
        )}
        <Field name='date_of_birth' label='Date of birth' Input={DateInput} />
      </Grid>
    );
  }
};
