import React, { forwardRef } from 'react';

import { StylesProvider, text } from '@rexlabs/styling';
import Avatar, { FramedAvatar, AvatarProps } from '@rexlabs/avatar';

const overrides = {
  avatar: {
    ...text.tokens({
      target: 'avatar.user',
      color: ({ token }) => token('color.textStyle.body.default')
    }),
    background: {
      color: ({ token }) => token('color.container.static.secondary.contrast')
    }
  }
};

interface UserAvaterProps extends AvatarProps {
  framed?: boolean;
}

export const UserAvatar = forwardRef<HTMLDivElement, UserAvaterProps>(
  function UserAvatar({ framed, ...props }, ref) {
    return (
      <StylesProvider tokens={overrides}>
        {framed ? (
          <FramedAvatar {...props} ref={ref} />
        ) : (
          <Avatar {...props} ref={ref} />
        )}
      </StylesProvider>
    );
  }
);
