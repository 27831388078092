import { InvoiceLineItem } from 'data/models/entities/financials/invoices';
import { Property } from 'src/modules/properties/types/property-types';

export function getPayableByProperties(lineItems: InvoiceLineItem[]) {
  return lineItems
    ?.map((lineItem) => lineItem.payable_by_property)
    .filter(Boolean) as Property[];
}

export function getPayableToProperties(lineItems: InvoiceLineItem[]) {
  return lineItems
    ?.map((lineItem) => lineItem.payable_to_property)
    .filter(Boolean) as Property[];
}
