import { IconProps } from '@rexlabs/icons';
import React, { ComponentType } from 'react';

import SmileyGoodIcon from 'view/components/icons/smiley-good';
import SmileyFairIcon from 'view/components/icons/smiley-fair';
import SmileyPoorIcon from 'view/components/icons/smiley-poor';

import { InspectionRoomCondition } from '../types/InspectionRoomCondition';

export type InspectionRoomConditionExcludingNone = Exclude<
  InspectionRoomCondition,
  'none'
>;

type RoomConditionMap = Record<
  InspectionRoomConditionExcludingNone,
  ComponentType<IconProps>
>;

const roomConditionMap: RoomConditionMap = {
  good: SmileyGoodIcon,
  fair: SmileyFairIcon,
  poor: SmileyPoorIcon
};

interface RoomConditionIconProps {
  roomCondition: InspectionRoomConditionExcludingNone;
  size: 's' | 'm';
}

export function RoomConditionIcon({
  roomCondition,
  size
}: RoomConditionIconProps) {
  const Icon = roomConditionMap[roomCondition];

  return <Icon size={size} />;
}
