import React from 'react';
import { BlockConfig } from 'view/components/record-screen/types';
import { Grid } from 'view/components/@luna/form/grid';
import { Body } from '@rexlabs/text';
import { Field } from '@rexlabs/form';
import { TextInput } from '@rexlabs/text-input';

export const archiveBlock: BlockConfig = {
  id: 'archive',
  Edit: ({ blockProps }) => (
    <Grid columns={1}>
      <Body as='span'>{blockProps.message}</Body>
    </Grid>
  )
};

export const archiveWithReasonBlock: BlockConfig = {
  id: 'archive',
  validate: {
    definitions: {
      reason: { rules: 'required' }
    }
  },
  Edit: ({ blockProps }) => (
    <Grid columns={1}>
      <Body as='span'>{blockProps.message}</Body>
      <Field name='reason' label='Reason' Input={TextInput} />
    </Grid>
  )
};
