import { useErrorDialog } from '@rexlabs/dialog';
import { useModelActions } from '@rexlabs/model-generator';

import {
  RequestPipeLineRequests,
  RequestPipelineMethod,
  postRequestPipelineRequests
} from 'utils/api/post-request-pipeline';

import { RecordSubmitHandler } from 'view/components/record-screen/utils';

import { InspectionTask } from '../types/InspectionTask';
import { tasksModel } from '../../common/models/tasks-model';
import {
  InspectionReportManageRoomsFormProps,
  InspectionReportManageRoomObj
} from '../blocks/manage-areas-block';
import { getDeleteInspectionRoomRequests } from './get-delete-inspection-room-requests';

function getAddAndUpdateRoomsPipelineRequest(
  room: InspectionReportManageRoomObj,
  inspectionId: InspectionTask['id'],
  index: number
) {
  const method: RequestPipelineMethod = room.id ? 'PATCH' : 'POST';
  const path = room.id ? `/api/v1/rooms/${room.id}` : '/api/v1/rooms';

  return {
    id: `room-${index}`,
    method,
    path,
    json: {
      name: room.label,
      ...(!room?.id
        ? {
            object: {
              id: inspectionId,
              type: {
                id: 'task'
              }
            }
          }
        : {})
    }
  };
}

export function useAddRoomsToInspectionReportSubmitHandler(
  inspectionTask: InspectionTask
): RecordSubmitHandler<InspectionReportManageRoomsFormProps> {
  const { open: openErrorDialog } = useErrorDialog();
  const { refreshItem, refreshLists } = useModelActions(tasksModel);

  return async ({ values }) => {
    try {
      const updatedRooms = values.inspection_report_rooms || [];
      const roomsPayloads = updatedRooms.map((room, index) =>
        getAddAndUpdateRoomsPipelineRequest(room, inspectionTask.id, index)
      );

      const inspectionTaskMethod: RequestPipelineMethod = 'PATCH';

      // HACK: The request pipeline endpoint doesn't support deleting for some reason,
      // so doing this outside of the pipeline for now.
      const deletePromises = getDeleteInspectionRoomRequests(
        inspectionTask,
        updatedRooms
      );
      await Promise.all(deletePromises);

      const requestsPayload: RequestPipeLineRequests = [
        ...roomsPayloads,
        // NOTE: if the report is not in progress, then we need to change it to in progress first.
        // Otherwise, we can't create update the report
        ...(inspectionTask.details?.status?.id !== 'report_in_progress'
          ? [
              {
                id: 'change-report-status-to-in-progress',
                method: inspectionTaskMethod,
                path: `/api/v1/tasks/${inspectionTask.id}`,
                json: {
                  details: {
                    status: { id: 'report_in_progress' }
                  }
                }
              }
            ]
          : []),
        {
          id: 'update-inspection-report',
          method: inspectionTaskMethod,
          path: `/api/v1/tasks/inspection-reports/${inspectionTask.id}`,
          json: {
            details: {
              status: { id: 'report_in_progress' }
            },
            room_order: roomsPayloads.map(
              (room, index) => `{{$.room-${index}.id}}`
            )
          }
        }
      ];

      const { data } = await postRequestPipelineRequests(requestsPayload);

      await refreshItem({ id: inspectionTask.id });

      refreshLists();

      return data;
    } catch (error) {
      openErrorDialog(error);
    }
  };
}
