import {
  padding,
  StyleSheet,
  StylesProvider,
  useStyles
} from '@rexlabs/styling';
import { TextInput } from '@rexlabs/text-input';
import * as React from 'react';

type TextAreaInputProps = Omit<
  React.ComponentPropsWithRef<'textarea'>,
  'prefix'
>;

const defaultStyles = StyleSheet({
  textArea: {
    minHeight: ({ token }) => token('textArea.minHeight', '8rem'),
    maxHeight: ({ token }) => token('textArea.maxHeight', '80rem'),
    resize: 'none'
  }
});

function TextAreaInput({
  style,
  className,
  ref,
  ...props
}: TextAreaInputProps) {
  const s = useStyles(defaultStyles, 'TextAreaInput');

  return (
    <textarea
      {...s.with('textArea')({ style, className })}
      {...props}
      ref={ref}
    />
  );
}

const tokens = {
  textInput: {
    height: '100%',
    ...padding.tokens({
      target: 'textArea',
      // Spacing for the outer container
      // so that the drag handle is not
      // flush with the border
      all: 'xxxs'
    }),
    input: {
      ...padding.tokens({
        target: 'textArea.input',
        // Calculate spacing so that the total
        // spacing from the container border
        // to the text is 'spacing.s`
        all: ({ token }) =>
          `calc(${token('spacing.s')} - ${token('spacing.xxxs')})`
      })
    }
  }
};

export type TextAreaProps = React.HTMLAttributes<HTMLTextAreaElement>;

export const TextArea = React.forwardRef<HTMLTextAreaElement, TextAreaProps>(
  function TextArea(props, ref) {
    return (
      <StylesProvider tokens={tokens}>
        <TextInput ref={ref} {...props} style={{ height: '100%' }}>
          {TextAreaInput}
        </TextInput>
      </StylesProvider>
    );
  }
);
