import { RecordDialog } from 'view/components/record-screen/dialog/dialog';
import React, { useCallback } from 'react';
import { DialogProps, useErrorDialog } from '@rexlabs/dialog';
import { api } from 'utils/api/api-client';
import { Invoice } from 'data/models/entities/financials/invoices';
import { useQuery } from 'react-query';
import { RecordSubmitHandler } from 'view/components/record-screen/utils';
import { useToast } from 'view/components/@luna/notifications/toast';
import { DialogContentConfig } from 'view/components/record-screen/dialog/types';
import { bulkTransferInvoicesBlock } from 'src/modules/invoices/blocks/bulk-transfer-invoices';
import WarningCircleIcon from 'view/components/icons/warning-circle';
import { BulkTransferEstimation } from '../types/bulk-transfer-estimation';

interface BulkTransferDialogProps extends DialogProps {
  invoices: Invoice[];
}

const content: DialogContentConfig = [
  {
    id: 'bulk-transfer',
    label: 'Bulk transfer',
    blocks: [bulkTransferInvoicesBlock]
  }
];

const estimateInvoicesTransfer = async (
  invoices: Array<Invoice>
): Promise<BulkTransferEstimation> => {
  const { data } = await api.post('/financials/bulk-transfers/estimate', {
    invoices: invoices.map((invoice) => {
      return {
        id: invoice.id
      };
    })
  });

  return data;
};

const submitBulkTransfer = async (
  allowPartialPayment: boolean,
  invoices: Array<Invoice>
): Promise<BulkTransferEstimation> => {
  const { data } = await api.post('/financials/bulk-transfers', {
    allow_partial_payment: allowPartialPayment,
    invoices: invoices.map((invoice) => {
      return {
        id: invoice.id
      };
    })
  });

  return data;
};

export function ConfirmBulkTransferDialog({
  invoices,
  onClose
}: BulkTransferDialogProps) {
  const { addToast } = useToast();
  const { open: openErrorDialog } = useErrorDialog();

  const itemQuery = useQuery(['bulk-transfer', 'estimate'], () =>
    estimateInvoicesTransfer(invoices)
  );

  const handleSubmit: RecordSubmitHandler = useCallback(
    async ({ values }) => {
      try {
        await submitBulkTransfer(values.allow_partial_payment, invoices);

        addToast({
          description: 'The transfer is being processed.',
          type: 'info'
        });

        return true;
      } catch (e) {
        openErrorDialog(e);
      }
    },
    [addToast, openErrorDialog, invoices]
  );

  return (
    <RecordDialog
      initialValues={{ allow_partial_payment: false }}
      onClose={onClose}
      title='Confirm transfer'
      handleSubmit={handleSubmit}
      data={itemQuery.data}
      isLoading={itemQuery.isLoading}
      content={content}
      TitleIcon={WarningCircleIcon}
      submitLabel='Transfer & pay invoices'
    />
  );
}
