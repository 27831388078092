import { useDialog } from '@rexlabs/dialog';
import { getRenewDialogLabel } from 'src/modules/compliance/common/dialogs/renew-check-dialog';
import { ActionDeclaration } from 'src/modules/common/actions/types/action-declaration-types';
import { useRenewFromTaskToast } from 'src/modules/compliance/common/hooks/use-renew-from-task-toast';
import { useModelActions } from '@rexlabs/model-generator';
import {
  tasksModel,
  taskIncludes,
  sharedIncludes
} from 'src/modules/tasks/common/models/tasks-model';
import { SupplierRenewEntryDialog } from 'src/modules/compliance/common/dialogs/supplier-renew-entry-dialog';
import { SupplierComplianceTask } from 'src/modules/tasks/supplier-compliance/types/SupplierComplianceTask';

export function useGetRenewEntryAction() {
  const renewEntryDialog = useDialog(SupplierRenewEntryDialog);
  const addToast = useRenewFromTaskToast();
  const actions = useModelActions(tasksModel);

  return (task?: SupplierComplianceTask): ActionDeclaration => {
    const complianceType =
      task?.details?.compliance_type ||
      task?.details?.compliance_entry?.compliance_type;

    const contactId =
      task?.details?.supplier?.id ||
      task?.details?.compliance_entry?.supplier?.id;
    const label = getRenewDialogLabel(complianceType);
    const handleSave = (data) => {
      addToast(data);
      actions.refreshItem({
        id: task?.id,
        args: {
          include: [
            taskIncludes.supplierComplianceDetails,
            ...sharedIncludes
          ].join(',')
        }
      });
      return actions.refreshLists();
    };

    return {
      label,
      id: 'renew-check',
      handleAction: () => {
        return renewEntryDialog.open({
          onSave: handleSave,
          complianceType,
          contactId
        });
      }
    };
  };
}
