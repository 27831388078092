import React from 'react';
import { BlockConfig } from 'view/components/record-screen/types';
import { Grid } from 'view/components/@luna/form/grid';
import { Field } from '@rexlabs/form';
import { InactivePropertyOwnershipFees } from 'view/components/inputs/selects/inactive-property-ownership-fees';
import { useTranslation } from 'react-i18next';

export const inactivePropertyOwnershipFeesBlock: BlockConfig = {
  id: 'inactive-ownership-fees',
  title: 'Inactive ownership fees',
  validate: {
    definitions: {
      inactive_property_ownership_fees: {
        rules: 'required'
      }
    }
  },
  Edit: ({ blockProps }) => {
    const { t } = useTranslation();

    return (
      <Grid columns={1}>
        <Field
          name='inactive_property_ownership_fees'
          label={
            t(
              'property-ownerships.management-agreement-fees.label.plural'
            ) as string
          }
          Input={InactivePropertyOwnershipFees}
          inputProps={{
            propertyOwnershipId: blockProps.propertyOwnershipId,
            multi: true
          }}
        />
      </Grid>
    );
  }
};
