import React, { useRef, forwardRef, CSSProperties } from 'react';
import { get } from 'lodash';
import mergeRefs from 'react-merge-refs';

import { useStyles, StyleSheet, StylesProvider, text } from '@rexlabs/styling';
import Box from '@rexlabs/box';
import { Form, FormPassthroughProps } from '@rexlabs/form';
import { PrimaryButton, OutlineButton } from '@rexlabs/button';
import { StatusAverageTag, StatusBadTag } from '@rexlabs/tag';
import Icons from '@rexlabs/icons';

import { formGridTokens } from 'view/components/@luna/grid/form';
import {
  Card as LunaCard,
  CardContent,
  CardDivider
} from 'view/components/@luna/card';

import { pluralize } from 'utils/formatters';
import { CardProps } from '../core';

const { CrossSmall } = Icons;

const defaultStyles = StyleSheet({
  container: {
    position: 'relative',
    width: '100%'
  },

  wrapper: {
    overflow: 'hidden'
  },

  heading: {
    ...text.styles({
      fallback: 'normal.bold'
    })
  },

  closeButton: {
    cursor: 'pointer'
  },

  content: {
    overflow: 'auto'
  },

  form: {
    flex: 1
  },

  buttons: {
    gap: ({ token }) => token('spacing.m')
  },

  button: {
    flex: 1
  }
});

type FloatingContentProps = Pick<
  CardProps,
  'data' | 'isLoading' | 'id' | 'title' | 'Actions' | 'item' | 'index'
> &
  Omit<FormPassthroughProps<any, any>, 'resetForm' | 'submitForm'> & {
    style?: CSSProperties;
    className?: string;

    onCancelClick: any;
    onSaveClick: any;
    Edit: NonNullable<CardProps['Edit']>;
  };

export const FloatingEditContent = forwardRef<
  HTMLDivElement,
  FloatingContentProps
>(function FloatingContent(
  {
    style,
    className,

    data,
    isLoading,
    id,
    title,
    item,
    index,

    names,
    values,
    setValues,
    setFieldValue,
    fieldIsValid,
    isDirty,
    isValid,
    isSubmitting,

    onCancelClick,
    onSaveClick,

    Edit
  },
  ref
) {
  const s = useStyles(defaultStyles);

  const containerRef = useRef<HTMLDivElement>(null);

  const numberInvalidFields = names.filter((name) => !get(fieldIsValid, name))
    .length;

  return (
    <LunaCard
      id={`block-${id}`}
      ref={mergeRefs([ref, containerRef])}
      {...s.with('container')({ style, className })}
    >
      <CardContent {...s('wrapper')} flex={1}>
        <Box
          flexDirection='row'
          alignItems='center'
          justifyContent='space-between'
        >
          <Box flexDirection='row' alignItems='center' sx='1.2rem' flex={1}>
            <span {...s('heading')}>
              {typeof title === 'function'
                ? title({ editMode: true, data, values, item })
                : title}
            </span>

            {!isValid && (
              <StatusBadTag>
                {numberInvalidFields} {pluralize('Error', numberInvalidFields)}
              </StatusBadTag>
            )}

            {isValid && isDirty && (
              <StatusAverageTag>Unsaved changes</StatusAverageTag>
            )}

            <Box
              {...s('closeButton')}
              flex={1}
              justifyContent='flex-end'
              onClick={onCancelClick}
            >
              <CrossSmall size='m' />
            </Box>
          </Box>
        </Box>
        <CardDivider />
        <Box {...s('content')} flex={1}>
          <StylesProvider tokens={formGridTokens}>
            <Form {...s('form')}>
              <Edit
                id={id}
                values={values}
                setValues={setValues}
                setFieldValue={setFieldValue}
                data={data}
                item={item}
                index={index}
                isLoading={isLoading}
                editMode={true}
              />
            </Form>
          </StylesProvider>
        </Box>
        <CardDivider />
        <Box {...s('buttons')} flexDirection='row' width='100%'>
          <OutlineButton
            {...s('button')}
            onClick={() => {
              onCancelClick?.();
            }}
          >
            Cancel
          </OutlineButton>
          <PrimaryButton
            {...s('button')}
            isLoading={isSubmitting}
            onClick={() => {
              onSaveClick?.();
            }}
          >
            Save
          </PrimaryButton>
        </Box>
      </CardContent>
    </LunaCard>
  );
});
