import { ActionMenuItem } from '@rexlabs/action-menu';
import { useDialog } from '@rexlabs/dialog';
import { useModelActions } from '@rexlabs/model-generator';
import { RemoveTaskWithheldFundsDialog } from 'src/modules/withheld-funds/dialogs/remove-task-withheld-funds-dialog';
import { Task } from 'src/modules/tasks/common/types/Task';
import { useTranslation } from 'react-i18next';
import { ownershipsModel } from 'data/models/entities/ownerships';
import { getAllIncludesStringFromModel } from 'utils/api/get-all-includes-string-from-model';

export function useGetWithheldFundsActions() {
  const { open: openDeleteDialog } = useDialog(RemoveTaskWithheldFundsDialog);
  const { t } = useTranslation();
  const { refreshItem } = useModelActions(ownershipsModel);

  return (task?: Task<'task_maintenance'>) => {
    if (!task) return [];

    const ownershipId = task.property?.active_property_ownership?.ownership_id;
    const actions: ActionMenuItem[] = [
      {
        label: t('withheld-funds.remove-task-withheld-funds-action.label'),
        onClick: () => {
          openDeleteDialog({
            task,
            onSave: ownershipId
              ? () =>
                  refreshItem({
                    id: ownershipId,
                    args: {
                      include: getAllIncludesStringFromModel(ownershipsModel)
                    }
                  })
              : undefined
          });
        }
      }
    ];

    return actions;
  };
}
