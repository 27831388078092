import React from 'react';

import { Link, LinkProps } from '@rexlabs/whereabouts';
import { StyleSheet, useStyles } from '@rexlabs/styling';
import {
  RecordLink,
  RecordLinkProps
} from 'view/components/record-link/record-link';

const defaultStyles = StyleSheet({
  container: {
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'center'
  },
  link: {
    fontWeight: '600',
    textDecoration: 'none'
  }
});

export function ViewLink({ children, ...props }: LinkProps) {
  const s = useStyles(defaultStyles);
  return (
    <div {...s('container')}>
      <Link {...s('link')} {...props}>
        {children}
      </Link>
    </div>
  );
}

export function ViewRecordLink({ children, ...props }: RecordLinkProps) {
  const s = useStyles(defaultStyles);

  return (
    <div {...s('container')}>
      <RecordLink {...s('link')} {...props}>
        {children}
      </RecordLink>
    </div>
  );
}
