import { BaseModelGeneratorModel } from '@rexlabs/model-generator';

import { Generator, GeneratorConfig } from 'data/models/generator';
import { TimestampAttributes } from '../../types';

export type PaymentMethod = 'cheque' | 'cash' | 'eft';

export interface CashBookEntry
  extends BaseModelGeneratorModel,
    TimestampAttributes {
  type: string;
  amount: number;
  receipt_number: number;
  payment_method: PaymentMethod;
  is_reversed: boolean;
  payment_reference?: string;
  date_of: string;
  received_by_id?: string;
}

const config: GeneratorConfig = {
  entities: {
    related: {
      line_items: {
        include: 'line_items'
      },
      cash_book_entry: {
        include: 'line_items.cash_book_entry'
      },
      trust_ledger_entry: {
        include: 'line_items.trust_ledger_entry'
      },
      rent_payment_entry: {
        include: 'line_items.rent_payment_entry'
      },
      invoice_payment_entry: {
        include: 'line_items.invoice_payment_entry'
      }
    }
  }
};

export const financialsCashBookEntriesModel = new Generator<CashBookEntry>(
  'financials/cash-book-entries',
  config
).createEntityModel();
