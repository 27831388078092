import Box from '@rexlabs/box';
import { ButtonGroup, GhostButton, PrimaryButton } from '@rexlabs/button';
import { PrimaryHeading } from 'src/modules/auth/login/components/heading-primary';
import { Body, Bold } from '@rexlabs/text';
import React from 'react';
import { UserInvitation } from 'src/modules/auth/user-invitation/types/user-invitation';
import { push } from '@rexlabs/whereabouts';
import { USER_INVITATION_ROUTES } from 'src/modules/auth/user-invitation/routes';
import { redirectToAuthServiceLogin } from 'utils/api/redirect-to';
import config from 'src/config';
import ROUTES from 'src/modules/auth/login/routes';

export function InvitationLoggedOutCard({
  invitation,
  token
}: {
  invitation: UserInvitation;
  token: string;
}) {
  const HandleClick = () => {
    push(USER_INVITATION_ROUTES.USER_INVITATION_CREATE, {
      query: { token }
    });
  };

  // every part of the invitation should exist except that we've had
  // a few cases where the info hasn't come through so I'm being extra careful
  // even though it shouldn't happen again
  return (
    <>
      <PrimaryHeading level='display'>
        Welcome
        {invitation?.user?.contact?.display_name &&
          `, ${invitation.user.contact.display_name}`}
      </PrimaryHeading>

      <Box mt='2.4rem'>
        <Body>
          {invitation.invited_by ? (
            <Bold>
              {invitation?.invited_by?.given_name}{' '}
              {invitation?.invited_by?.family_name}
            </Bold>
          ) : (
            'A user'
          )}{' '}
          has invited you to join {invitation?.silo?.label || 'Rex PM'}.
        </Body>
      </Box>

      <ButtonGroup mt='1.6rem' end>
        <GhostButton
          onClick={() => {
            if (config.AUTHENTICATION_SERVICE_ENABLE_INTEGRATION) {
              redirectToAuthServiceLogin(
                window.location.origin +
                  ROUTES.USER_INVITATION.config.path +
                  '?token=' +
                  token +
                  '&account_id=' +
                  invitation.silo.id
              );
            } else {
              push(USER_INVITATION_ROUTES.USER_INVITATION_LOGIN, {
                query: { token }
              });
            }
          }}
        >
          Already have an account?
        </GhostButton>
        <PrimaryButton onClick={HandleClick}>Create account</PrimaryButton>
      </ButtonGroup>
    </>
  );
}
