import React from 'react';

import { BlockConfig } from 'view/components/record-screen/types';
import { ActionButtons } from 'view/components/@luna/action-buttons';

import { useFeatureFlags } from 'view/components/@luna/feature-flags';
import { FLAGS } from 'utils/feature-flags';
import { InspectionFormData } from '../types/InspectionFormData';
import { useGetManageInspectionReportRoomsAction } from '../hooks/action-declarations/use-get-modify-inspection-report-rooms-action';
import { InspectionTask } from '../types/InspectionTask';
import { useGetImportReportAction } from '../hooks/action-declarations/use-get-import-report-action';
import { useGetCompleteInspectionReportAction } from '../hooks/action-declarations/use-get-complete-inspection-report-action';
import { useGetDownloadReportAction } from '../hooks/action-declarations/use-get-download-report-action';
import { useGetPreviousInspectionReports } from '../hooks/use-get-previous-inspection-reports';
import { useGetSendInspectionReportAction } from '../hooks/action-declarations/use-get-send-inspection-report-action';
import { useGetManageInspectionReportAreasAction } from '../hooks/action-declarations/use-get-modify-inspection-report-areas-action';

export const inspectionReportBlock: BlockConfig<
  InspectionTask,
  any,
  InspectionFormData
> = {
  id: 'inspection-report',
  title: 'Inspection report',
  isMobileForcedCollapsed: true,
  Actions: ({ data }) => {
    const getManageInspectionReportRoomsAction = useGetManageInspectionReportRoomsAction(
      data!
    );
    const getManageInspectionReportAreasAction = useGetManageInspectionReportAreasAction(
      data!
    );

    const { hasFeature } = useFeatureFlags();

    const getImportReportAction = useGetImportReportAction(data);
    const getCompleteInspectionReportAction = useGetCompleteInspectionReportAction();
    const getSendInspectionReportAction = useGetSendInspectionReportAction();
    const getDownloadReportAction = useGetDownloadReportAction(data!);

    const isCompleteOrSentWithReportFile =
      ['report_completed', 'report_sent'].includes(
        data?.details?.status?.id || ''
      ) && data?.details?.inspection_report?.file?.download_url;

    const { data: previousInspectionReports } = useGetPreviousInspectionReports(
      data
    );

    const sendInspectionReportAction = getSendInspectionReportAction(data!);

    return (
      <ActionButtons
        data-testid='inspection-report-actions'
        actions={[
          {
            label: 'Actions',
            actions: [
              ...(isCompleteOrSentWithReportFile
                ? [
                    getDownloadReportAction(),
                    ...(sendInspectionReportAction
                      ? [sendInspectionReportAction]
                      : [])
                  ]
                : [
                    hasFeature(FLAGS.ENTRY_EXIT_INSPECTION_REPORT)
                      ? getManageInspectionReportAreasAction()
                      : getManageInspectionReportRoomsAction(),

                    {
                      ...getImportReportAction(),
                      disabled: !(
                        previousInspectionReports &&
                        previousInspectionReports?.length
                      )
                    },

                    getCompleteInspectionReportAction(data!)
                  ])
            ]
          }
        ]}
      />
    );
  }
};
