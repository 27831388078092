import { BaseModelGeneratorModel, EntityModel } from '@rexlabs/model-generator';
import { useToast } from 'view/components/@luna/notifications/toast';
import { getRecordLabel } from 'utils/records/get-record-label';
import React from 'react';
import { getRecordObject } from 'src/modules/common/utils/Records/get-record-object';
import { BannerAction } from 'view/components/@luna/notifications/banner/core';

interface Options {
  actions?: BannerAction[];
}

export function useRecordDeletedToast(
  entityModel: EntityModel<any>,
  options: Options = {}
) {
  const { addToast } = useToast();

  return (record: BaseModelGeneratorModel) => {
    const recordObject = getRecordObject(record, entityModel);

    addToast({
      type: 'error',
      description: (
        <>
          <b>{getRecordLabel(recordObject)}</b> has been deleted
        </>
      ),
      actions: options.actions ?? []
    });
  };
}
