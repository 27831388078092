import React from 'react';
import { Select } from '@rexlabs/select';
import { normaliseValueListItem } from 'utils/normaliser/value-list-item';
import { TaskType } from 'src/modules/tasks/common/types/TaskType';
import { InputProps } from '@rexlabs/form';
import { useChecklistItemRelatedDatesQuery } from 'src/modules/tasks/settings/hooks/use-checklist-item-related-dates-query';
import { getOffsetRelatedDatesFromRelatedDatesData } from 'src/modules/tasks/settings/utils/get-offset-related-dates-from-related-dates-data';

export interface ChecklistItemOffsetRelatedDateSelectProps extends InputProps {
  taskType: TaskType;
}

export function ChecklistItemOffsetRelatedDateSelect(
  props: ChecklistItemOffsetRelatedDateSelectProps
) {
  const { taskType } = props;

  const { data } = useChecklistItemRelatedDatesQuery(taskType);

  const offsetRelatedDates =
    data?.data && getOffsetRelatedDatesFromRelatedDatesData(data.data);

  return (
    <Select
      items={offsetRelatedDates || []}
      {...props}
      normaliser={normaliseValueListItem}
      searchable={false}
    />
  );
}
