import React, { useState, useEffect } from 'react';
import { RouteProps, Link } from '@rexlabs/whereabouts';
import { Heading } from '@rexlabs/text';
import { Card } from 'view/components/card';
import LoadingState from 'view/components/states/compact/loading';
import Box from '@rexlabs/box';
import { ErrorBanner } from 'view/components/@luna/notifications/banner';
import { api } from 'utils/api/api-client';
import { ButtonGroup, PrimaryButton } from '@rexlabs/button';
import ROUTES from 'routes/app';
import { useModelActions } from '@rexlabs/model-generator';
import { sessionModel } from 'data/models/custom/session';

export function ConfirmEmailScreen({ match }: RouteProps) {
  const emailToken = match?.query?.token as string;

  const [status, setStatus] = useState<'loading' | 'error' | 'success'>(
    'loading'
  );
  const { update } = useModelActions(sessionModel);

  useEffect(() => {
    const confirmEmail = async (token: string) => {
      try {
        await api.post('/me/change-email-complete', {
          token
        });
      } catch (e) {
        return setStatus('error');
      }

      await update();

      setStatus('success');
    };

    confirmEmail(emailToken);
  }, [emailToken]);

  if (status === 'error') {
    return <ErrorBanner description='Somthing went wrong' />;
  }

  if (status === 'loading') {
    return <LoadingState>Verifying email</LoadingState>;
  }

  return (
    <Box justifyContent='center' width='100%'>
      <Card width='50rem'>
        <Heading>Your email has been verified</Heading>
        <Box mt='2rem' justifyContent='flex-end'>
          <ButtonGroup>
            <Link to={ROUTES.DASHBOARD}>
              <PrimaryButton>Go to dashboard</PrimaryButton>
            </Link>
          </ButtonGroup>
        </Box>
      </Card>
    </Box>
  );
}
