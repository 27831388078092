import React from 'react';
import { useDialog } from '@rexlabs/dialog';

import EmptyTable from 'src/assets/illustrations/empty-table.svg';

import { EmptyCard } from 'view/components/record-screen/cards/empty-card';
import { Message } from 'view/components/@luna/message';

import { CreateSupplierComplianceRequirementDialog } from '../dialogs/create-supplier-compliance-requirement-dialog';

export function SupplierComplianceRequirementTableEmptyState() {
  const { open: openCreateSupplierComplianceRequirementDialog } = useDialog(
    CreateSupplierComplianceRequirementDialog
  );
  return (
    <EmptyCard hasGreyBackground={false}>
      <Message
        title='Compliance requirements'
        Illustration={EmptyTable}
        actions={[
          {
            label: 'Create compliance requirement',
            intent: 'primary',
            handleAction: openCreateSupplierComplianceRequirementDialog
          }
        ]}
      >
        Add a requirement such as an electrical license. You&apos;ll then be
        able to add this to a category.
      </Message>
    </EmptyCard>
  );
}
