import { omit } from 'lodash';

import { TaxAdjustmentForm } from '../types/tax-adjustment-form';
import { OwnershipTaxTransaction } from '../types/ownership-tax-transaction';

export function mapTaxAdjustmentToFormValues(
  taxAdjustment: OwnershipTaxTransaction
): Partial<TaxAdjustmentForm> {
  return {
    // remove any fields that are not needed in the form
    ...omit(taxAdjustment, [
      'id',
      'created_at',
      'updated_at',
      'voided_at',
      'source',
      'source_type',
      'type'
    ]),
    bank_account: taxAdjustment.source?.bank_account,
    payment_information: taxAdjustment.source?.payment_information ?? undefined
  };
}
