import React from 'react';

import { padding, StyleSheet, useStyles } from '@rexlabs/styling';
import { NumberInput, NumberInputProps } from '@rexlabs/text-input';
import Box from '@rexlabs/box';
import { Body } from '@rexlabs/text';
import { DisbursementFrequencyUnitSelect } from '../selects/disbursement-frequency-unit';

export type FrequencyValue = {
  on_frequency_count?: number | null;
  on_frequency_unit?: {
    id: string;
    label: string;
  } | null;
};

interface FrequencyInputProps
  extends Omit<NumberInputProps, 'value' | 'onChange' | 'onBlur'> {
  value?: FrequencyValue;
  onChange?: (e: { target: { value: FrequencyValue } }) => void;
  onBlur?: (e: { target: { value: FrequencyValue } }) => void;
  testId?: string;
}

const defaultFrequencyValue: FrequencyValue = {
  on_frequency_count: null,
  on_frequency_unit: null
};

const defaultStyles = StyleSheet({
  divider: {
    ...padding.styles({
      x: 'xs'
    })
  },
  splitGrid: {
    display: 'grid',
    gridTemplateColumns: '50px 22px minmax(0, 1fr)'
  }
});

export function DisbursementFrequencySplitInput({
  value,
  onChange,
  onBlur,
  testId,
  ...props
}: FrequencyInputProps) {
  const s = useStyles(defaultStyles);

  const handleChange = (newValue: Partial<FrequencyValue>) => {
    onChange?.({
      target: {
        value: {
          ...(value || defaultFrequencyValue),
          ...newValue
        }
      }
    });
  };

  const handleBlur = (newValue: Partial<FrequencyValue>) => {
    onBlur?.({
      target: {
        value: {
          ...(value || defaultFrequencyValue),
          ...newValue
        }
      }
    });
  };

  const handleCountChange = ({ target }) =>
    handleChange({
      on_frequency_count: parseInt(target.value)
    });

  const handleCountBlur = ({ target }) =>
    handleBlur({
      on_frequency_count: parseInt(target.value)
    });

  const handleUnitChange = ({ target }) =>
    handleChange({
      on_frequency_unit: target.value
    });

  const handleUnitBlur = ({ target }) =>
    handleBlur({
      on_frequency_unit: target.value
    });

  return (
    <div {...s('splitGrid')}>
      <NumberInput
        {...props}
        value={value?.on_frequency_count}
        onChange={handleCountChange}
        onBlur={handleCountBlur}
        style={{ textAlign: 'right' }}
        data-testid={testId && `${testId}-number`}
      />
      <Box alignItems='center' justifyContent='center'>
        <Body {...s('divider')}>/</Body>
      </Box>
      <DisbursementFrequencyUnitSelect
        {...props}
        id={testId && `${testId}-unit`}
        value={value?.on_frequency_unit}
        onChange={handleUnitChange}
        onBlur={handleUnitBlur}
      />
    </div>
  );
}
