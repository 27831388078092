import Box from '@rexlabs/box';
import { LinkButton } from '@rexlabs/button';
import { CellProps } from '@rexlabs/table';
import React from 'react';
import { useDialog } from '@rexlabs/dialog';
import { PreviewImagesDialog } from '../dialogs/preview-images-dialog';

export function PreviewImagesCell({ value }: CellProps) {
  const images = value.images?.data || [];
  const { open } = useDialog(PreviewImagesDialog);

  return (
    <Box display='flex' justifyContent='center' width='100%'>
      <LinkButton
        onClick={() => {
          open({ areaItemName: value.name, images });
        }}
      >
        {images.length}
      </LinkButton>
    </Box>
  );
}
