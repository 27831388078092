import { useFeatureFlags } from 'view/components/@luna/feature-flags';
import { Columns } from 'view/components/table';
import { AccountingJournalEntryLineItem } from '../types/account-journal-entry-types';
import { ChartOfAccountAccount } from '../utils/get-accounting-journal-entry-line-item-columns';

const descriptionColumn = {
  id: 'description',
  Header: 'Description',
  accessor: (item) => item.description
};

const amountColumn = {
  id: 'amount',
  type: 'currency',
  accessor: (item) => item.amount,
  Header: 'Amount',
  width: 100
};

const taxTypeColumn = {
  id: 'tax',
  accessor: (item) => item.tax_type.label,
  Header: 'Tax type'
};

const netRevenueExcludingTaxColumn = {
  id: 'revenue',
  type: 'currency',
  accessor: (item) => item.net_revenue_amount_exc_tax,
  Header: 'Net revenue (excl. tax)'
};

export function useAccountingJournalEntryLineItemColumns(): Columns<AccountingJournalEntryLineItem> {
  const { hasFeature } = useFeatureFlags();

  const hasManualAccountingJournalEntriesFeature = hasFeature(
    'manual_accounting_journal_entries'
  );

  return hasManualAccountingJournalEntriesFeature
    ? [
        descriptionColumn,
        {
          id: 'property',
          type: 'property',
          Header: 'Property',
          accessor: (item) => item.property
        },
        {
          id: 'account',
          accessor: (item) => item,
          Header: 'Account',
          Cell: ChartOfAccountAccount
        },
        { ...taxTypeColumn, width: 140 },
        {
          id: 'debit_or_credit',
          Header: 'DR/CR',
          accessor: (item) => item.debit_or_credit.label,
          width: 90
        },
        { ...netRevenueExcludingTaxColumn, width: 200 },
        amountColumn
      ]
    : [
        descriptionColumn,
        {
          id: 'debit_or_credit',
          Header: 'Debit or Credit',
          accessor: (item) => item.debit_or_credit.label
        },
        {
          id: 'type',
          Header: 'Type',
          accessor: (item) =>
            item.chart_of_accounts_account.account_category.label
        },
        {
          id: 'Account',
          accessor: (item) => item.chart_of_accounts_account.name,
          Header: 'Chart of Account'
        },
        {
          Header: 'Property',
          accessor: (item) => item?.property?.record_reference
        },
        amountColumn,
        netRevenueExcludingTaxColumn,
        taxTypeColumn
      ];
}
