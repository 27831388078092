import { useModelActions } from '@rexlabs/model-generator';

import { SingleActionDeclaration } from 'src/modules/common/actions/types/action-declaration-types';
import {
  sharedIncludes,
  taskIncludes,
  tasksModel
} from 'src/modules/tasks/common/models/tasks-model';

import { api } from 'utils/api/api-client';

import { InspectionTask } from '../../types/InspectionTask';

export function useGetInspectionReportResetReportAction(
  inspectionTask: InspectionTask
) {
  const { refreshItem } = useModelActions(tasksModel);

  return (): SingleActionDeclaration => {
    return {
      id: 'inspection-report-create-new',
      intent: 'primary',
      group: 'inspection',
      label: 'Reset report status',
      handleAction: async () => {
        // If resetting the status, we want to open the task first
        if (inspectionTask.status.id === 'completed') {
          await api.patch(`tasks/${inspectionTask.id}/open`);
        }

        // // then reset the status to not started
        await api.patch(`tasks/${inspectionTask.id}/status`, {
          type: {
            id: 'task_inspection'
          },
          details: {
            status: {
              id: 'report_in_progress'
            }
          }
        });

        await refreshItem({
          id: inspectionTask.id,
          args: {
            include: [
              ...sharedIncludes,
              taskIncludes.inspectionDetails,
              'property.active_property_ownership.ownership.owners.contact'
            ].join(',')
          }
        });
      }
    };
  };
}
