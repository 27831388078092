import React, { useMemo } from 'react';

import {
  TitleBlock,
  TitleBlockProps
} from 'view/components/cards/title/title-block';

import { RecordType } from 'data/models/types';
import { CreditNote } from 'data/models/entities/financials/credit-notes';

import InvoicesIcon from 'view/components/icons/invoices';
import { titleBlockStatusTag } from 'src/modules/common/components/status-tag';
import { useCreditNotesActions } from '../actions/use-credit-note-actions';
import { creditNoteStatusMap } from '../maps/credit-note-map';
import { getCreditNoteTitle } from '../utils/get-credit-note-title';

const type: RecordType = 'credit_note';

type CreditNoteTitleBlockProps = {
  creditNote: CreditNote;
  refreshItem?: () => Promise<void>;
};

export function CreditNoteTitleBlock({
  creditNote
}: CreditNoteTitleBlockProps) {
  const getCreditNoteActions = useCreditNotesActions();

  const title = getCreditNoteTitle(creditNote);

  const actions = useMemo(() => getCreditNoteActions(creditNote), [
    creditNote,
    getCreditNoteActions
  ]);

  const { record_reference, status } = creditNote;

  const tags: TitleBlockProps['tags'] = [
    titleBlockStatusTag(status, creditNoteStatusMap)
  ];

  return (
    <TitleBlock
      title={title}
      type={type}
      actions={actions}
      Icon={InvoicesIcon}
      reference={record_reference}
      tags={tags}
    />
  );
}
