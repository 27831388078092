import React from 'react';

import { useDialog } from '@rexlabs/dialog';
import { ReceiptFundsDialog } from 'src/modules/trust-journal-entries/dialogs/receipt-funds-dialog';
import RenderLoading from 'view/components/@luna/render-loading';
import { Card } from 'view/components/card';
import { ListScreen } from 'view/components/list-screen/list-screen';
import LoadingState from 'view/components/states/compact/loading';

import ROUTES from 'routes/app';
import { useBankAccountSelectActions } from 'src/modules/bank-accounts/hooks/use-bank-account-select-actions';
import { TrustJournalEntriesTable } from '../../trust-ledgers/components/trust-journal-entries-table';
import { TransferFundsDialog } from '../dialogs/transfer-funds-dialog';
import { WithdrawFundsDialog } from '../dialogs/withdraw-funds-dialog';

export function TrustJournalEntriesListScreen() {
  const {
    isLoading: isLoadingBankAccounts,
    bankAccount,
    actions
  } = useBankAccountSelectActions(ROUTES.TRUST_JOURNAL_ENTRIES);

  const { open: openReceiptFundsDialog } = useDialog(ReceiptFundsDialog);
  const { open: openWithdrawFundsDialog } = useDialog(WithdrawFundsDialog);
  const { open: openTransferFundsDialog } = useDialog(TransferFundsDialog);

  return (
    <RenderLoading isLoading={isLoadingBankAccounts} LoadingView={LoadingState}>
      <ListScreen title='Journal entries' actions={actions}>
        <Card
          title='Journal entries'
          actions={[
            {
              label: 'Transfer funds',
              handleAction: () =>
                openTransferFundsDialog({
                  bankAccount
                })
            },
            {
              label: 'Withdraw funds',
              handleAction: () =>
                openWithdrawFundsDialog({
                  initialValues: { bankAccount }
                })
            },
            {
              label: 'Receipt funds',
              handleAction: () =>
                openReceiptFundsDialog({
                  bankAccount,
                  showCreateAnotherButton: true,
                  allowUpdateTenantReference: false
                })
            }
          ]}
        >
          {bankAccount && (
            <TrustJournalEntriesTable bankAccountId={bankAccount.id} />
          )}
        </Card>
      </ListScreen>
    </RenderLoading>
  );
}
