import { propertiesModel } from 'data/models/entities/properties';

import { useGetPrimaryRecordActionGroup } from 'view/hooks/actions/use-get-primary-record-action-group';
import { useFeatureFlags } from 'view/components/@luna/feature-flags';

import { FLAGS } from 'utils/feature-flags';

import { useStatusChangeAction } from 'src/modules/common/actions/status/use-status-change-action';
import { useDeleteAction } from 'src/modules/common/actions/delete/use-delete-action';
import { ActionDeclaration } from 'src/modules/common/actions/types/action-declaration-types';
import { Property } from 'src/modules/properties/types/property-types';
import { useEditRecordDetailsAction } from 'src/modules/common/actions/record-details/hooks/use-edit-record-details-action';
import { useGetCreateNoteAction } from 'src/modules/note/hooks/use-get-create-note-action';
import { useGetCreateEmailAction } from 'src/modules/communications/messages/hooks/action-declarations/use-get-create-email-action';
import { useGetCreateTaskAction } from 'src/modules/tasks/common/hooks/action-declarations/use-get-create-task-action';

import { useGetCreateSMSAction } from 'src/modules/communications/messages/hooks/action-declarations/use-get-create-sms-action';
import { useGetEditAddress } from '../utils/property-actions/use-get-edit-address';
import { useGetCreatePropertyTenancyAction } from '../../property-tenancies/actions/use-get-create-property-tenancy-action';
import { useGetCreatePropertyOwnershipAction } from '../../property-ownerships/actions/use-get-create-property-ownership-action';
import { useGetEditLocationAction } from '../utils/property-actions/use-get-edit-location-action';
import { useGetCreateInvoiceFromProperty } from './use-create-invoice-action';

export function usePropertyActions() {
  const { hasFeature } = useFeatureFlags();

  const getEditDetailsAction = useEditRecordDetailsAction(propertiesModel);
  const getPrimaryRecordActionGroup = useGetPrimaryRecordActionGroup();
  const getCreatePropertyTenancyAction = useGetCreatePropertyTenancyAction();
  const getCreatePropertyOwnershipAction = useGetCreatePropertyOwnershipAction();
  const getCreateInvoiceFromProperty = useGetCreateInvoiceFromProperty();
  const getEditAddress = useGetEditAddress();
  const setStatusAction = useStatusChangeAction(propertiesModel);
  const deleteAction = useDeleteAction(propertiesModel);
  const getCreateNoteAction = useGetCreateNoteAction(propertiesModel);
  const getCreateEmailAction = useGetCreateEmailAction(propertiesModel);
  const getCreateSMSAction = useGetCreateSMSAction(propertiesModel);
  const getCreateTaskAction = useGetCreateTaskAction();
  const getEditLocationInMap = useGetEditLocationAction();

  return (property?: Property): ActionDeclaration[] => {
    if (!property) return [];
    const statusChangeActions: ActionDeclaration[] = [];

    switch (property.status.id) {
      case 'active':
        statusChangeActions.push(
          setStatusAction({
            record: property,
            status: 'archived',
            dialogOptions: { label: 'Archive' }
          })
        );
        break;

      case 'archived':
        statusChangeActions.push(
          setStatusAction({
            record: property,
            status: 'active',
            dialogOptions: { label: 'Activate' }
          })
        );
        break;
    }

    return [
      ...getPrimaryRecordActionGroup('property', property.id),
      getEditDetailsAction(property),
      ...(hasFeature(FLAGS.INSPECTION_SCHEDULE)
        ? [
            getEditLocationInMap({
              property,
              shouldCheckForInspectionRun: true
            })
          ]
        : []),
      getCreateNoteAction({ attached: property }),
      getCreateEmailAction(property),
      getCreateSMSAction(property),
      getCreateInvoiceFromProperty(property),
      getCreatePropertyTenancyAction({ property }),
      getCreatePropertyOwnershipAction({ property }),
      getCreateTaskAction({ property }),
      getEditAddress(),
      ...statusChangeActions,
      deleteAction(property)
    ];
  };
}
