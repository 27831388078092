import { useGetGenericDeleteAction } from 'view/hooks/actions/use-get-generic-delete-action';
import {
  financialsReconciliationsModel,
  Reconciliation
} from 'data/models/entities/financials/reconciliations';
import { useModelActions } from '@rexlabs/model-generator';
import { push } from '@rexlabs/whereabouts';
import ROUTES from 'routes/app';
import { getRecordTitle } from 'utils/records/get-record-title';
import { RecordObject } from 'data/models/types';
import { SingleActionDeclaration } from 'src/modules/common/actions/types/action-declaration-types';

export function useGetDeleteAction() {
  const { trashItem } = useModelActions(financialsReconciliationsModel);
  const getGenericDeleteAction = useGetGenericDeleteAction();

  return (reconciliation: Reconciliation): SingleActionDeclaration =>
    getGenericDeleteAction({
      id: 'delete',
      group: 'reconciliation-delete',
      recordTypeName: 'reconciliation',
      recordName: getRecordTitle({
        type: 'reconciliation',
        object: reconciliation
      } as RecordObject),
      label: 'Abandon reconciliation',
      title: 'Abandon active reconciliation?',
      message: `To start a new reconciliation, please confirm you want to abandon your active ${reconciliation.type.label.toLowerCase()} reconciliation.`,
      confirmText: 'Abandon active reconciliation',
      handleDelete: async () => {
        await trashItem({ id: reconciliation.id });
        push(ROUTES.RECONCILIATIONS);
      },
      actionConducted: 'abandoned'
    });
}
