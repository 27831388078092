import React from 'react';

import { Truncate } from '@rexlabs/text';
import { CellProps } from '@rexlabs/table';
import { getRecordLabel } from 'utils/records/get-record-label';
import { Disbursement } from 'data/models/entities/financials/disbursements/disbursements';

import { margin, StyleSheet, useStyles } from '@rexlabs/styling';
import { useTranslation } from 'react-i18next';

const defaultStyles = StyleSheet({
  paymentMethod: {
    fontSize: ({ token }) => token('typography.size.s'),
    color: ({ token }) => token('color.textStyle.body.subtext')
  },
  missingPaymentMethods: {
    color: ({ token }) => token('color.textStyle.danger.idle.default'),
    fontWeight: ({ token }) => token('typography.weight.bold')
  },
  missingPaymentLabel: {
    ...margin.styles({
      left: 'xs'
    })
  },
  secondLine: {
    ...margin.styles({
      top: 'xxs'
    })
  }
});

export function DisbursedToCell({ value }: CellProps<unknown, Disbursement>) {
  const { t } = useTranslation();
  const s = useStyles(defaultStyles);

  const hasPaymentMethods = (value.payment_methods?.length ?? 0) > 0;

  return (
    <div>
      <Truncate>
        {getRecordLabel({ type: 'disbursement', object: value! })}
      </Truncate>
      <div {...s('secondLine')}>
        <Truncate {...s('paymentMethod')}>
          {hasPaymentMethods
            ? value.payment_methods
                ?.map((paymentMethod) => paymentMethod.label)
                .join(', ')
            : t('disbursements.general.no-funds-disbursed')}
        </Truncate>
      </div>
    </div>
  );
}
