import * as React from 'react';

import { DialogProps } from '@rexlabs/dialog';

import { RecordDialog } from 'view/components/record-screen/dialog/dialog';
import { RecordSubmitHandler } from 'view/components/record-screen/utils';

import { QuoteDetailsForm } from '../blocks/quote-details-block';
import { receivedQuoteBlock } from '../blocks/received-quote-block';

export interface CreateQuoteDialogProps extends DialogProps {
  handleSubmit: RecordSubmitHandler<Partial<AddReceivedQuoteDialogForm>>;
}

export type AddReceivedQuoteDialogForm = QuoteDetailsForm;

export function AddReceivedQuoteDialog({
  onClose,
  handleSubmit
}: CreateQuoteDialogProps) {
  const content = React.useMemo(
    () => [
      {
        id: 'add-received-quote',
        label: 'Add received quote',
        blocks: [receivedQuoteBlock]
      }
    ],
    []
  );

  return (
    <RecordDialog
      title='Add received quote'
      content={content}
      initialValues={{
        details: {
          quote_attachments: []
        }
      }}
      submitLabel='Add quote'
      onClose={onClose}
      handleSubmit={handleSubmit}
    />
  );
}
