import { PendingOwnershipDisbursement } from 'data/models/entities/financials/pending-disbursements/pending-ownership-disbursement';
import React from 'react';
import { Columns, RecordTable } from 'view/components/table';
import { Message } from 'view/components/@luna/message';
import EmptyTrustJournalEntriesIllustration from 'assets/illustrations/empty-trust-journal-entries-table.svg';
import { BlockConfig } from 'view/components/record-screen/types';

const ownershipTaxTransactionColumns: Columns = [
  {
    id: 'contact',
    type: 'contact',
    Header: 'Contact',
    width: 200
  },
  {
    id: 'amount',
    type: 'currency',
    Header: 'Amount',
    cellProps: {
      align: 'right'
    },
    width: 140
  },
  {
    id: 'description',
    Header: 'Description',
    width: 300
  }
];

export const ownershipTaxTransactionsBlock: BlockConfig<PendingOwnershipDisbursement> = {
  id: 'ownership-tax-transactions',
  title: 'Tax transactions',
  View: ({ data }) => {
    const transactions = data?.ownership_tax_transactions?.data ?? [];

    return (
      <RecordTable
        isLoading={false}
        items={transactions.map((transaction, index) => {
          if (transaction.id) {
            return transaction;
          }

          return { ...transaction, id: index.toString() }; // pending transactions don't have id's
        })}
        id='ownership-tax-transactions'
        columns={ownershipTaxTransactionColumns}
      />
    );
  },
  showEmpty: (data) => !data?.ownership_tax_transactions?.data?.length,
  Empty: () => (
    <Message
      Illustration={EmptyTrustJournalEntriesIllustration}
      title='Tax transactions'
    >
      No tax transactions exist at the moment
    </Message>
  )
};
