import { useMemo } from 'react';
import { formatCurrency } from 'utils/formatters';
import { Stat } from 'view/components/@luna/stat-block/stat-blocks';
import { useDisbursementSummary } from './use-disbursement-summary';

export function useDisbursementStats() {
  const { items } = useDisbursementSummary();

  const summaryData = items?.[0];

  return useMemo<Stat[] | undefined>(() => {
    return [
      {
        label: 'Ownerships due',
        value: formatCurrency(summaryData?.total_ownership_payout_amount || 0),
        intent: 'secondary'
      },
      {
        label: 'Bills & fees payable',
        value: formatCurrency(
          summaryData?.total_ownership_bill_owing_amount || 0
        ),
        intent: 'secondary'
      },
      {
        label: 'Contacts due',
        value: formatCurrency(summaryData?.total_contact_payout_amount || 0),
        intent: 'secondary'
      }
    ];
  }, [
    summaryData?.total_contact_payout_amount,
    summaryData?.total_ownership_bill_owing_amount,
    summaryData?.total_ownership_payout_amount
  ]);
}
