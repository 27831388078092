import React from 'react';

import { Field } from '@rexlabs/form';

import { BlockConfig } from 'view/components/record-screen/types';
import { Grid } from 'view/components/@luna/form/grid';
import 'src/modules/tasks/settings/utils/checklist-item-validation-rules.ts';

import { Select } from '@rexlabs/select';
import { ValueListValue } from 'data/models/types';

const validate = {
  definitions: {
    outcome_value_id: {
      rules: 'required'
    }
  }
};

export const subtaskOutcomeBlock: BlockConfig<{
  outcome_options?: ValueListValue<string>[];
}> = {
  id: 'subtask-outcome',
  title: 'Outcome',
  validate,
  Edit: ({ data }) => {
    return (
      <Grid columns={1}>
        <Field
          name='outcome_value_id'
          label='Outcome'
          Input={Select}
          inputProps={{
            // by using just the id as the outcome value, we can avoid needing to map this value later.
            items: data?.outcome_options?.map((option) => option.id) ?? [],
            normaliser: (outcomeId: string) => {
              const item = data?.outcome_options?.find(
                (option) => option.id === outcomeId
              );

              return {
                id: outcomeId,
                label: item?.label ?? outcomeId
              };
            }
          }}
        />
      </Grid>
    );
  }
};
