import React, { useEffect, useRef, useState } from 'react';
import { Field, Form, ReactForms } from '@rexlabs/form';
import { EmailInput } from '@rexlabs/text-input';
import { ButtonGroup, LinkButton, PrimaryButton } from '@rexlabs/button';
import { Column } from '@rexlabs/grid';
import { Bold } from '@rexlabs/text';
import { push, useWhereabouts } from '@rexlabs/whereabouts';
import icons from '@rexlabs/icons';

import { FormGrid } from 'view/components/@luna/grid/form';
import { Message } from 'view/components/@luna/message';
import { api } from 'utils/api/api-client';
import ROUTES from 'routes/auth';

import EmailSuccessSvg from 'assets/illustrations/email-success.svg';
import Box from '@rexlabs/box';
import { PrimaryHeading } from '../components/heading-primary';

const { ChevronLeft } = icons;

const validate = {
  definitions: {
    email: { rules: 'required|email' }
  }
};

export function ForgotPasswordScreen() {
  const whereabouts = useWhereabouts();
  const [isSubmitted, setIsSubmitted] = useState(false);

  const inputRef = useRef<HTMLInputElement>();
  useEffect(() => {
    inputRef?.current?.select?.();
  }, []);

  const email = whereabouts?.query?.email;

  const handleSubmit = async (values) => {
    await api.post(`/auth/reset-password`, {
      email: values.email,
      redirect_uri: window.location.origin + '/reset-password'
    });

    setIsSubmitted(values.email);
  };

  if (isSubmitted) {
    return (
      <>
        <Box mb='2rem' data-testid='forgot-password-success'>
          <LinkButton
            onClick={() =>
              push(ROUTES.LOGIN, { query: { email: isSubmitted } })
            }
            IconLeft={ChevronLeft}
          >
            Back to login
          </LinkButton>
        </Box>
        <Message
          grey
          title='Email successfully sent!'
          Illustration={EmailSuccessSvg}
          actions={[
            {
              intent: 'primary',
              label: 'Return to login',
              handleAction: () =>
                push(ROUTES.LOGIN, { query: { email: isSubmitted } })
            }
          ]}
        >
          Instructions to reset your password have been sent to{' '}
          <Bold>{isSubmitted}</Bold>.
        </Message>
      </>
    );
  }

  return (
    <ReactForms
      handleSubmit={handleSubmit}
      initialValues={{ email }}
      validate={validate}
    >
      {({ submitForm, isSubmitting }) => {
        return (
          <Form name='forgot-password'>
            <Box mb='2rem'>
              <LinkButton
                onClick={() =>
                  push(ROUTES.LOGIN, email ? { query: { email } } : {})
                }
                IconLeft={ChevronLeft}
              >
                Back to login
              </LinkButton>
            </Box>
            <PrimaryHeading level='display'>
              Forgotten password? No problem.
            </PrimaryHeading>

            <FormGrid mt='1.6rem'>
              <Column width={12}>
                <Field
                  name='email'
                  label='Email'
                  Input={EmailInput}
                  inputProps={{ ref: inputRef }}
                />
              </Column>

              <Column width={12} mt='1.6rem'>
                <ButtonGroup end>
                  <PrimaryButton
                    isLoading={isSubmitting}
                    onClick={() => {
                      submitForm();
                    }}
                  >
                    Reset password
                  </PrimaryButton>
                </ButtonGroup>
              </Column>
            </FormGrid>
          </Form>
        );
      }}
    </ReactForms>
  );
}
