import { useTaskAssignmentTypes } from '../hooks/use-task-assignment-types';
import { Portfolio } from '../models/portfolios-model';
import {
  MappedTaskTypeAssignments,
  PortfolioFormData
} from '../types/portfolio-form-data';

export function useMapPortfolioToPortfolioFormData() {
  const taskAssignmentTypes = useTaskAssignmentTypes();

  return (data: Portfolio): PortfolioFormData => {
    const mappedTaskTypeAssignments: Partial<MappedTaskTypeAssignments> = taskAssignmentTypes.reduce(
      (previous, current) => ({
        ...previous,
        [current.id]: {
          contact: data.task_type_assignments?.data.find(
            (taskTypeAssignment) =>
              taskTypeAssignment.task_type.id === current.id
          )?.contact || {
            id: 'do-not-automatically-assign',
            label: 'Do not automatically assign'
          }
        }
      }),
      {}
    );

    return {
      ...data,
      task_type_assignments: mappedTaskTypeAssignments
    };
  };
}
