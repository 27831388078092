import { ChecklistTemplateItem } from '../types/ChecklistTemplate';
import { ChecklistTemplatesItemForm } from '../types/ChecklistTemplatesForm';

export function getTriggerHeader(
  checklistTemplateItem: ChecklistTemplatesItemForm | ChecklistTemplateItem
) {
  return checklistTemplateItem.trigger_type?.id === 'on_due_date'
    ? 'ON THE DUE DATE:'
    : 'WHEN THIS SUBTASK IS COMPLETED:';
}
