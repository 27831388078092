import { EntityModel } from '@rexlabs/model-generator';
import { pluralize } from 'utils/formatters';
import { CommunicationContextType } from '../../common/types/communication-context-type';

/**
 * @deprecated Use getContextTypeFromRecord instead, as this doesn't handle task types with specific contexts
 */
export function getContextTypeFromModel(
  model: EntityModel<any, any>
): CommunicationContextType {
  // For the ones we currently need to do, it is just a case of singularizing the model name. This might change in the future
  return pluralize(model.modelName, 1) as CommunicationContextType;
}
