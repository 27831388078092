import { mq, StyleSheet, text, useStyles } from '@rexlabs/styling';
import React from 'react';

const defaultStyles = StyleSheet({
  title: {
    wordBreak: 'break-word',

    ...mq.styles.combine([
      {
        queries: {
          maxWidth: 's'
        },
        styles: {
          ...text.styles({
            target: 'titleBlock.heading',
            fallback: 'heading.4'
          })
        }
      },
      {
        queries: {
          minWidth: 's'
        },
        styles: {
          ...text.styles({
            target: 'titleBlock.heading',
            fallback: 'heading.3'
          })
        }
      }
    ])
  }
});

export const CustomTitleHeader = (props) => {
  const s = useStyles(defaultStyles);
  return <span {...s('title')}>{props.children}</span>;
};
