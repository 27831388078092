import React, { useCallback } from 'react';
import { ConfirmationDialog, DialogProps } from '@rexlabs/dialog';
import { useModelActions } from '@rexlabs/model-generator';
import { useToast } from 'view/components/@luna/notifications/toast';
import { Property } from 'src/modules/properties/types/property-types';
import { propertiesModel } from 'data/models/entities/properties';
import { portfoliosModel } from '../models/portfolios-model';

export interface RemovePropertyFromPortfolioDialogProps extends DialogProps {
  property: Property;
  portfolioId: string;
}

export function RemovePropertyFromPortfolioDialog({
  onClose,
  property,
  portfolioId
}: RemovePropertyFromPortfolioDialogProps) {
  const {
    updateItem: updateProperty,
    refreshLists: refreshProperties
  } = useModelActions(propertiesModel);
  const { refreshItem: refreshPortfolio } = useModelActions(portfoliosModel);

  const { addToast } = useToast();

  const handleSubmit = useCallback(async () => {
    await updateProperty({ id: property.id, data: { portfolio: null } });

    addToast({
      type: 'success',
      description: 'Successfully removed property'
    });

    await refreshPortfolio({ id: portfolioId });
    await refreshProperties();
  }, [addToast, property.id, updateProperty]);

  return (
    <ConfirmationDialog
      title='Remove property from portfolio'
      size='s'
      onClose={onClose}
      message={
        <>
          <div>
            Any tasks relating to the property will continue to be assigned to
            the existing user.
          </div>
          <br />
          <div>Do you want to continue?</div>
        </>
      }
      confirmText='Yes, remove property'
      onConfirm={handleSubmit}
      destructive={true}
    />
  );
}
