import {
  ChecklistItem,
  ChecklistWithIncludes
} from 'src/modules/tasks/common/types/Checklist';
import { filterActiveGroups } from './get-checklist-view-items';

export function getCompletedCount(
  items: ChecklistItem[],
  itemOrder: string[],
  checklist: ChecklistWithIncludes
): number {
  return items
    .filter(
      (item) =>
        item.completed_at != null &&
        item.status?.id === 'active' &&
        itemOrder.includes(item.id)
    )
    .filter((item) => filterActiveGroups(item, checklist, items)).length;
}
