import { Field } from '@rexlabs/form';
import { TextArea, TextInput } from '@rexlabs/text-input';
import React from 'react';
import { Column, Grid } from 'view/components/@luna/form/grid';
import { BlockConfig } from 'view/components/record-screen/types';

const validate = {
  definitions: {
    description: { rules: 'required' },
    label: { rules: 'required' }
  }
};

export const upsertChecklistDetailsBlockV2: BlockConfig = {
  id: 'details',
  title: 'Details',
  validate,
  Edit: () => {
    return (
      <>
        <Grid columns={2}>
          <Field name='label' label='Checklist name' Input={TextInput} />

          <Column width={2}>
            <Field
              name='description'
              label='Description'
              Input={TextArea}
              inputProps={{
                placeholder: 'Describe when this checklist should be used...'
              }}
            />
          </Column>
        </Grid>
      </>
    );
  }
};
