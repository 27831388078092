import React, { useCallback } from 'react';

import { DialogProps } from '@rexlabs/dialog';
import { useModelActions } from '@rexlabs/model-generator';
import { push } from '@rexlabs/whereabouts';
import { OutlineButton } from '@rexlabs/button';

import ROUTES from 'routes/app';

import { usersModel } from 'src/modules/users/models/users';
import { getUserRedirectUri } from 'src/modules/users/utils/get-user-redirect-uri';

import { RecordDialog } from 'view/components/record-screen/dialog/dialog';
import { DialogContentConfig } from 'view/components/record-screen/dialog/types';
import { RecordSubmitHandler } from 'view/components/record-screen/utils';
import { useToast } from 'view/components/@luna/notifications/toast';
import { useUserRoleQuery } from 'view/components/inputs/selects/v4-selects/user-role-select';

import { inviteUserBlock } from '../blocks/invite-user';
import { userPermissionsBlock } from '../blocks/user-permissions-block';
import { userDetailsBlock } from '../blocks/user-details';

// NOTE: Using this for now as it's the easiest way to find the default role
const DEFAULT_ROLE_NAME = 'Standard';

export const content: DialogContentConfig = [
  {
    id: 'details',
    label: 'Details',
    blocks: [userDetailsBlock, userPermissionsBlock, inviteUserBlock]
  }
];

export function InviteUserDialog({ onClose }: DialogProps) {
  const { addToast } = useToast();
  const { createItem, refreshLists } = useModelActions(usersModel);
  const userRoleQuery = useUserRoleQuery();

  const handleSubmit: RecordSubmitHandler = useCallback(async ({ values }) => {
    const item = await createItem({
      data: {
        ...values,
        invite: values.invite
          ? { redirect_uri: getUserRedirectUri() }
          : undefined
      }
    });

    addToast({
      description: 'Invited new user',
      actions: [
        {
          label: 'View',
          onClick: () =>
            push(ROUTES.USER, { params: { userId: item.data.id } }),
          Button: OutlineButton
        }
      ]
    });
    refreshLists();
    return true;
  }, []);

  const initialData = {
    invite: true,
    role: userRoleQuery.data?.find((role) => role.name === DEFAULT_ROLE_NAME)
  };

  return (
    <RecordDialog
      isLoading={!userRoleQuery.isFetched}
      onClose={onClose}
      title='Invite new user'
      data={initialData}
      content={content}
      handleSubmit={handleSubmit}
    />
  );
}
