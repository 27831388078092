import { getRecordLabel } from 'utils/records/get-record-label';
import { RecordObject } from 'data/models/types';
import { SearchResultItem } from './api/get-search-results';
import { getRecordTypeName } from './records/get-record-type-name';

export function transformToSearchEntry(
  recordObject: RecordObject
): SearchResultItem {
  return {
    id: recordObject.object.id,
    label: getRecordLabel(recordObject),
    record: recordObject.object,
    type: {
      label: getRecordTypeName(recordObject.type),
      id: recordObject.type
    }
  };
}
