import React from 'react';
import { BlockConfig } from 'view/components/record-screen/types';
import { TrustJournalEntryLineItemTable } from 'src/modules/trust-journal-entry-line-items/components/trust-journal-entry-line-item-table';
import LoadingState from 'view/components/states/compact/loading';
import { toQuri } from '@rexlabs/quri';
import { query } from '@rexlabs/model-generator';
import { trustJournalEntryLineItemModel } from 'src/modules/trust-journal-entry-line-items/models/trust-journal-entry-line-item-model';
import { InfoBanner } from '@rexlabs/notifications';
import { useGetCreateLedgerSummaryDialogAction } from 'src/modules/trust-ledgers/hooks/use-get-create-ledger-summary-dialog-action';
import { useSelectedBankAccountContext } from 'src/modules/tenancies/contexts/selected-bank-account-context';
import { OutlineButton } from '@rexlabs/button';
import { useTrustLedger } from 'src/modules/financials/utils/use-trust-ledger';

const trustJournalEntryLineItemQuery = (filter) => {
  return query`{
        ${trustJournalEntryLineItemModel} (q: ${filter}, o: "created_at+desc"){
          id
          trust_journal_entry
          trust_ledger
        }
      }`;
};

export const tenancyTrustTransactionsBlock: BlockConfig = {
  id: 'trust-transactions',
  title: 'Transactions',
  View: ({ data }) => {
    const {
      bankAccount: selectedBankAccount
    } = useSelectedBankAccountContext();

    const getCreateLedgerActivitySummaryDialogAction = useGetCreateLedgerSummaryDialogAction();

    const { trustLedger, isLoading } = useTrustLedger(
      data,
      selectedBankAccount!
    );

    const summaryAction = getCreateLedgerActivitySummaryDialogAction(
      trustLedger ? trustLedger : null,
      selectedBankAccount
    );

    const filter = React.useMemo(() => {
      if (trustLedger) {
        return toQuri([
          {
            field: 'trust_ledger_id',
            op: '==',
            value: trustLedger.id
          }
        ]);
      }

      return null;
    }, [trustLedger]);

    if (!selectedBankAccount || isLoading) {
      return <LoadingState />;
    }

    return trustLedger ? (
      <>
        <OutlineButton onClick={() => summaryAction.handleAction?.()}>
          {summaryAction.label}
        </OutlineButton>

        <TrustJournalEntryLineItemTable
          showBalance={true}
          getQuery={() => trustJournalEntryLineItemQuery(filter)}
        />
      </>
    ) : (
      <InfoBanner
        description={`There is no transaction history for ${data.display_name} in ${selectedBankAccount.name}`}
      />
    );
  }
};
