import React from 'react';
import { BlockConfig } from 'view/components/record-screen/types';
import { Grid, Column } from '@rexlabs/grid';
import { Value } from 'view/components/values';
import { Field } from '@rexlabs/form';
import { NegativeCurrencySelect } from 'src/modules/settings/account-settings/components/selects/negative-currency';
import { Settings } from '../../hooks/useSettings';

export const accountingSettingsBlock: BlockConfig<Settings> = {
  id: 'accounting-settings',
  title: 'Accounting settings',
  validate: {
    definitions: {
      'negative-currency-format': { rules: 'required' }
    }
  },
  View: ({ data }) => {
    return (
      <Grid>
        <Column width={4}>
          <Value
            label='Negative accounting formation'
            value={data?.['negative-currency-format']?.label}
          />
        </Column>
      </Grid>
    );
  },
  Edit: () => {
    return (
      <Grid>
        <Column width={4}>
          <Field
            id='negative-currency-format'
            label='Negative accounting formation'
            name='negative-currency-format'
            Input={NegativeCurrencySelect}
          />
        </Column>
      </Grid>
    );
  }
};
