import React from 'react';
import dayjs from 'dayjs';

import { query, useEntityQuery } from '@rexlabs/model-generator';

import { RecordDialog } from 'view/components/record-screen/dialog/dialog';
import { DialogContentConfig } from 'view/components/record-screen/dialog/types';

import { checkInKeySetBlock } from '../blocks/check-in-key-set-block';
import { keySetsModel } from '../data/key-set-model';

const content: DialogContentConfig = [
  {
    id: 'key-details',
    label: 'Key Details',
    blocks: [checkInKeySetBlock]
  }
];

function getKeySetQuery(id: string) {
  return query`{
    ${keySetsModel} (id: ${id}) {
      name
    }
  }`;
}

export function CheckInKeySetDialog({
  onClose,
  handleSubmit,
  keyId
}: Pick<
  React.ComponentProps<typeof RecordDialog>,
  'onClose' | 'handleSubmit'
> & { keyId: string }) {
  const { data: keyData, status } = useEntityQuery(getKeySetQuery(keyId));

  return (
    <RecordDialog
      title='Check-in key set'
      onClose={onClose}
      isLoading={status === 'loading'}
      initialValues={{
        id: keyId,
        name: keyData?.name || '',
        checked_in_date: dayjs().format('YYYY-MM-DD')
      }}
      submitLabel='Check-in'
      content={content}
      handleSubmit={handleSubmit}
      size='s'
    />
  );
}
