import React from 'react';

import Box from '@rexlabs/box';
import { useToken } from '@rexlabs/styling';

import { BlockConfig } from 'view/components/record-screen/types';
import { Card } from 'view/components/card';
import BPayIcon from 'view/components/icons/bpay';

import { BankWithdrawal } from '../types/bank-withdrawal-types';

export const bpayLogoBlock: BlockConfig<BankWithdrawal> = {
  id: 'details',
  Card: () => {
    const token = useToken();

    return (
      <Card>
        <Box
          flexDirection='row'
          alignItems='center'
          columnGap={token('spacing.s')}
        >
          <BPayIcon width='32px' height='auto' />
          <Box flexDirection='column'>
            <span>® Registered to BPAY Pty Ltd</span>
            <span>ABN 69 079 137 518</span>
          </Box>
        </Box>
      </Card>
    );
  }
};
