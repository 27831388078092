import { useStatusChangeAction } from 'src/modules/common/actions/status/use-status-change-action';
import {
  MessageTemplate,
  MessageTemplateStatus
} from '../../types/MessageTemplate';
import { messageTemplatesModel } from '../../models/message-templates-model';

export function useGetToggleArchivedMessageTemplateAction() {
  const getStatusChangeAction = useStatusChangeAction(messageTemplatesModel);

  return (messageTemplate: MessageTemplate) => {
    const newStatus: MessageTemplateStatus =
      messageTemplate.status.id === 'active' ? 'archived' : 'active';
    return getStatusChangeAction({
      record: messageTemplate,
      status: newStatus
    });
  };
}
