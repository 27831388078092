import { Disclosure } from '@headlessui/react';
import Box from '@rexlabs/box';
import { LinkButton } from '@rexlabs/button';
import { StyleSheet, useStyles, useToken } from '@rexlabs/styling';
import { Heading, Small } from '@rexlabs/text';
import Tooltip from '@rexlabs/tooltip';
import * as React from 'react';
import ChevronDownIcon from 'view/components/icons/chevron-down';
import ChevronRightIcon from 'view/components/icons/chevron-right';
import { slugToSentenceCase } from '../../messages/utils/slug-to-sentence-case';
import { useGlobalMessageContexts } from '../hooks/use-global-message-contexts';
import { useMessageContext } from '../hooks/use-message-context';
import { ChannelTypeId } from '../types/channel';
import { CommunicationContextType } from '../types/communication-context-type';
import { MergeTag } from '../types/merge-tags';
import { SearchInput } from './search-input';

const defaultStyles = StyleSheet({
  container: {
    background: ({ token }) => token('palette.grey.200'),
    height: '100%'
  },
  disclosureToggle: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  tagLink: {
    textDecoration: 'underline dashed',
    height: 'fit-content',
    marginBottom: ({ token }) => token('spacing.xxs')
  },
  tagLabel: {
    whiteSpace: 'normal',
    textAlign: 'left'
  }
});

const getFilterItems = (searchValue: string) => (item: MergeTag) => {
  return (
    item.label.toLowerCase().includes(searchValue.toLowerCase()) ||
    item.tag.toLowerCase().includes(searchValue.toLowerCase())
  );
};

export interface MergeTagsProps {
  channelType: ChannelTypeId;
  contextType?: CommunicationContextType;
  onMergeTagDoubleClick?: (string) => void;
}

export const copyTagToClipboard = ({ tag }: MergeTag) => {
  navigator.clipboard.writeText(`{{${tag}}}`);
};

export function MergeTags({
  channelType,
  contextType,
  onMergeTagDoubleClick
}: MergeTagsProps) {
  const s = useStyles(defaultStyles);
  const [searchValue, setSearchValue] = React.useState('');
  const [lastCopiedTag, setLastCopiedTag] = React.useState<MergeTag | null>(
    null
  );
  const token = useToken();
  const iconProps = {
    color: token('palette.grey.700'),
    size: 'l' as const
  };

  // TODO: might be nice to orchestrate the global merge tags from a single place when we have a better idea.
  const { globalMergeTags } = useGlobalMessageContexts(channelType);
  const { mergeTags: requestedMergeTags } = useMessageContext(
    channelType,
    contextType
  );
  // const { mergeTags: authorMergeTags } = useMessageContext('author');
  // const { mergeTags: recipientsMergeTags } = useMessageContext('recipients');

  // combine the requested merge tags with the global merge tags
  const mergeTags = React.useMemo(() => {
    return [...(globalMergeTags || []), ...(requestedMergeTags || [])];
  }, [requestedMergeTags, globalMergeTags]);

  // After we update the last copied tag, reset it back to null after the timeout has elapsed.
  // This is so that the tool tip will not get locked in a single state
  React.useEffect(() => {
    const timer = setTimeout(() => {
      setLastCopiedTag(null);
    }, 5000);
    return () => clearTimeout(timer);
  }, [lastCopiedTag]);

  const handleClick = ({ event, tag }: { event: any; tag: MergeTag }) => {
    event.preventDefault();
    if (event.detail === 2) {
      onMergeTagDoubleClick?.(`{{${tag.tag}}}`);
      return;
    }

    copyTagToClipboard(tag);
    setLastCopiedTag(tag);
  };

  return (
    <Box
      {...s('container')}
      padding={token('spacing.xl')}
      gap={token('spacing.xl')}
      display='flex'
      flexDirection='column'
    >
      <Box>
        <Heading level={3}>Merge tags</Heading>
      </Box>
      <Box>
        <SearchInput onChange={setSearchValue} placeholder='Search tags' />
      </Box>

      <Box>
        <ul>
          {mergeTags.map((mergeTagCategory, index) => (
            <Disclosure
              key={mergeTagCategory.id}
              as='li'
              defaultOpen={index === 0}
            >
              {({ open }) => (
                <>
                  <Disclosure.Button as='div' {...s('disclosureToggle')}>
                    {open ? (
                      <ChevronDownIcon {...iconProps} />
                    ) : (
                      <ChevronRightIcon {...iconProps} />
                    )}
                    <Small grey>
                      {slugToSentenceCase(mergeTagCategory.label).toUpperCase()}
                    </Small>
                  </Disclosure.Button>
                  <Disclosure.Panel>
                    <ul style={{ paddingLeft: 20 }}>
                      {mergeTagCategory.items
                        .filter?.(getFilterItems(searchValue))
                        .map((item) => (
                          <li key={item.tag}>
                            <Tooltip
                              Content={() =>
                                lastCopiedTag?.tag === item.tag
                                  ? 'Copied!'
                                  : 'Copy to clipboard'
                              }
                              placement='right'
                            >
                              <LinkButton
                                {...s('tagLink')}
                                onClick={(event) =>
                                  handleClick({ event, tag: item })
                                }
                              >
                                <div {...s('tagLabel')}>{item.label}</div>
                              </LinkButton>
                            </Tooltip>
                          </li>
                        ))}
                    </ul>
                  </Disclosure.Panel>
                </>
              )}
            </Disclosure>
          ))}
        </ul>
      </Box>
    </Box>
  );
}
