import { LinkButton } from '@rexlabs/button';
import { StyleSheet, useStyles } from '@rexlabs/styling';
import React from 'react';
import SubtractIcon from 'view/components/icons/subtract';

export const styles = StyleSheet({
  actionLink: {
    color: ({ token }) => token('color.textStyle.primary.idle.default')
  }
});

export const DeSelectTrustJournalEntryActionCell = (props) => {
  const s = useStyles(styles);

  const {
    setSelectedReceipts,
    selectedReceipts,
    setAvailableReceipts,
    availableReceipts,
    trustJournalEntry
  } = props.value;

  const handleClick = () => {
    setSelectedReceipts(
      selectedReceipts.filter(
        (selectedReceipt) => selectedReceipt.id != trustJournalEntry.id
      )
    );
    setAvailableReceipts(availableReceipts.concat(trustJournalEntry));
  };

  return (
    <LinkButton
      IconLeft={SubtractIcon}
      data-testId={'deselect-trust-journal-entry-action-button'}
      {...s('actionLink')}
      onClick={handleClick}
    />
  );
};
