import { BlockConfig } from 'view/components/record-screen/types';
import 'src/modules/property-areas/common/utils/property-area-validator-rules';
import { EditItemsForm } from 'src/modules/property-areas/common/components/edit-items-form';

const validate = {
  definitions: {
    'items.*.name': {
      name: 'name',
      rules: 'required|itemNameMustBeUnique'
    }
  }
};

export const propertyAreaTypeItemsBlock: BlockConfig = {
  id: 'property-area-type-items',
  title: 'Items for Entry/Exit Inspections',
  validate,
  Edit: EditItemsForm
};
