import { makeIconComponent } from '@rexlabs/icons';

import LeaseReviewMediumSvg from 'src/assets/icons/m/lease-review.svg';
import LeaseReviewLargeSvg from 'src/assets/icons/l/lease-review.svg';
import LeaseReviewExtraLargeSvg from 'src/assets/icons/xl/lease-review.svg';

export const LeaseReviewIcon = makeIconComponent({
  m: LeaseReviewMediumSvg,
  l: LeaseReviewLargeSvg,
  xl: LeaseReviewExtraLargeSvg
});
export default LeaseReviewIcon;
