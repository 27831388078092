import { Select } from '@rexlabs/select';
import React from 'react';
import { normaliseAddressState } from 'utils/normaliser/v4-select-normalisers/address-state';

// HACK: We don't have BE for this, so we're adding a temporary select options for the state
const cityStateSelectOptions = [
  {
    id: 'NSW',
    label: 'NSW'
  },
  {
    id: 'QLD',
    label: 'QLD'
  },
  {
    id: 'SA',
    label: 'SA'
  },
  {
    id: 'TAS',
    label: 'TAS'
  },
  {
    id: 'VIC',
    label: 'VIC'
  },
  {
    id: 'WA',
    label: 'WA'
  },
  {
    id: 'ACT',
    label: 'ACT'
  },
  {
    id: 'NT',
    label: 'NT'
  }
];

type AddressStateSelect = typeof Select;

export function AddressStateSelect(props: AddressStateSelect) {
  return (
    <Select
      {...props}
      id='address-state-select'
      items={cityStateSelectOptions}
      normaliser={normaliseAddressState}
      placeholder='Select from list'
    />
  );
}
