import { ContentConfig } from 'view/components/record-screen/types';
import { letterLayoutMarginsBlock } from '../blocks/page-block';

export const useContent = (): ContentConfig => {
  return [
    {
      label: 'Letter layout',
      items: [
        {
          id: 'letter-layout',
          label: 'Letter layout',
          blocks: [letterLayoutMarginsBlock]
        }
      ]
    }
  ];
};
