import { padding, StyleSheet, useStyles } from '@rexlabs/styling';
import { TimestampAttributes } from 'data/models/types';
import React from 'react';
import { formatDateTime } from 'utils/dates/format';
import { getRecordLabel } from 'utils/records/get-record-label';
import { Author } from 'src/modules/authors/types/author-types';

const defaultStyles = StyleSheet({
  content: {
    ...padding.styles({
      x: 'l'
    }),
    color: ({ token }) => token('color.textStyle.body.subtext'),
    fontSize: ({ token }) => token('typography.size.s'),
    fontWeight: ({ token }) => token('typography.weight.semibold')
  },
  author: {
    color: ({ token }) => token('color.textStyle.body.default')
  },
  recordChangeLogItem: {
    ...padding.styles({
      y: 'xxs'
    })
  }
});

export type RecordChangeLogItem = {
  label: React.ReactNode | string;
  author: Author | null;
  timestamp: Date | null;
};

type RecordChangeLogProps = {
  items?: RecordChangeLogItem[];
  record?: TimestampAttributes;
};

export function RecordChangeLog({ items, record }: RecordChangeLogProps) {
  const s = useStyles(defaultStyles);

  let data: RecordChangeLogItem[] = [];

  if (record) {
    data = [
      {
        label: 'Created by',
        author: record.created_by!,
        timestamp: record.created_at!
      }
    ];

    if (record.updated_by && record.updated_at) {
      data = [
        ...data,
        {
          label: 'Updated by',
          author: record.updated_by!,
          timestamp: record.updated_at!
        }
      ];
    }
  }

  if (items) {
    items.forEach((item) => {
      if (item.timestamp && item.author) {
        data.push(item);
      }
    });
  }

  return (
    <div {...s('content')}>
      {data.map(({ label, author, timestamp }, index) => {
        if (!author) {
          console.warn(`There is no ${label} author`);
          return;
        }

        return (
          <div {...s('recordChangeLogItem')} key={index}>
            <span>{label} </span>
            <span {...s('author')}>
              {getRecordLabel({ object: author!, type: 'author' })}
            </span>
            <span> on {formatDateTime(timestamp!)}</span>
          </div>
        );
      })}
    </div>
  );
}
