import Box from '@rexlabs/box';
import { TableProvider, useUrlState } from '@rexlabs/table';
import React, { ReactElement } from 'react';
import { useTableFilters } from 'view/hooks/use-table-filters';
import { Toolbar } from 'view/components/table/toolbar';
import { useToken } from '@rexlabs/styling';
import { tableSuggestedFilters } from '../utils/bank-statement-filters';

export function withTableProvider({
  Component,
  hashParamKey
}: {
  Component: () => ReactElement;
  hashParamKey: string;
}) {
  return (props) => {
    const token = useToken();
    const spacing = token('spacing.m');

    const { getSort, getFilters } = useTableFilters(
      'bank-statement-transactions'
    );

    const { getTableProps: getUrlStateProps } = useUrlState({
      hashParamKey
    });

    const urlStateProps = getUrlStateProps();

    return (
      <TableProvider
        getFilters={getFilters}
        getSort={getSort}
        {...urlStateProps}
      >
        <Box paddingTop={spacing}>
          <Toolbar
            hasColumnSelection={false}
            suggestedFilters={tableSuggestedFilters}
          />
        </Box>

        <Component {...props} />
      </TableProvider>
    );
  };
}
