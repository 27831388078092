import React from 'react';
import { get } from 'lodash';
import Box from '@rexlabs/box';
import { Body } from '@rexlabs/text';
import { Field, FieldArray } from '@rexlabs/form';
import { OutlineButton } from '@rexlabs/button';
import { Tag, TagGroup } from '@rexlabs/tag';

import { BlockConfig } from 'view/components/record-screen/types';
import { Grid } from 'view/components/@luna/form/grid';
import { Value } from 'view/components/values';
import { ActionButtons } from 'view/components/@luna/action-buttons';
import { AddIcon } from 'view/components/icons/add';
import { AddressTypeSelect } from 'view/components/inputs/selects/address-type';
import { Contact } from 'src/modules/contacts/types/contact-types';
import { SingleActionDeclaration } from 'src/modules/common/actions/types/action-declaration-types';
import LocationMarkerIcon from 'view/components/icons/location-marker';
import { TextArea } from 'view/components/inputs/text-area/text-area';

export const addressesBlock: BlockConfig<Contact> = {
  id: 'addresses',
  title: 'Addresses',
  validate: {
    definitions: {
      'addresses.*.description': { name: 'address type', rules: 'required' },
      'addresses.*.address': { name: 'address', rules: 'required' }
    }
  },
  View: ({ data }) => {
    if (!data?.addresses?.length) {
      // TODO: replace with empty state
      return (
        <Body grey>
          No address found. You need to provide an address in order for this
          recipient to receive letters.
        </Body>
      );
    }

    return (
      <Grid columns={2}>
        {data.addresses.map((address, index) => {
          const value = (
            <Box flexDirection='row' sx='1.6rem' alignItems='center'>
              <Box>{address.address || '--'}</Box>
              <TagGroup>{!!address.is_primary && <Tag>Primary</Tag>}</TagGroup>
            </Box>
          );
          return (
            <Value key={index} label={address.description} value={value} />
          );
        })}
      </Grid>
    );
  },
  Edit: ({ values, setFieldValue }) => (
    <FieldArray name='addresses'>
      {function LineItem({ fields, push }) {
        return (
          <Box sy='2.4rem'>
            {!fields?.length && (
              <Body grey>
                No address found. You need to provide an address in order for
                this recipient to receive letters.
              </Body>
            )}
            {fields.map(({ field, actions }, fieldIndex) => {
              const isPrimary = get(values, `${field.name}.is_primary`);

              let rowActions: SingleActionDeclaration[] = [];
              if (!isPrimary) {
                rowActions = rowActions.concat([
                  {
                    label: 'Set as primary address',
                    handleAction: () => {
                      const newAddresses = values.addresses.map(
                        (item, index) => ({
                          ...item,
                          is_primary: index === fieldIndex
                        })
                      );
                      setFieldValue?.('addresses', newAddresses);
                    }
                  }
                ]);
              }
              rowActions = rowActions.concat([
                {
                  label: 'Delete',
                  intent: 'danger',
                  handleAction: () => actions.remove()
                }
              ]);

              return (
                <Box
                  key={field.name}
                  flexDirection='row'
                  alignItems='flex-start'
                  sx='2.4rem'
                  data-testid='address'
                >
                  <Box width='17rem' flexShrink={0}>
                    <Field
                      name={`${field.name}.description`}
                      label='Address type'
                      Input={AddressTypeSelect}
                    />
                  </Box>
                  <Field
                    name={`${field.name}.address`}
                    label='Address'
                    Input={TextArea}
                    inputProps={{
                      prefix: LocationMarkerIcon,
                      suffix: (
                        <Box sx='.4rem' mr='.8rem' flexDirection='row'>
                          {isPrimary && <Tag>Primary</Tag>}
                        </Box>
                      )
                    }}
                  />

                  <div>
                    <ActionButtons
                      mt='1.3rem'
                      actions={[
                        {
                          label: 'Actions',
                          actions: rowActions
                        }
                      ]}
                      placement='bottom-end'
                    />
                  </div>
                </Box>
              );
            })}

            <OutlineButton onClick={() => push({})} IconLeft={AddIcon}>
              Add another address
            </OutlineButton>
          </Box>
        );
      }}
    </FieldArray>
  )
};
