import { Generator } from 'data/models/generator';
import { auditableConfig } from 'src/modules/common/auditable/auditable-config';
import { contactsModel } from 'src/modules/contacts/models/contacts';
import _ from 'lodash';
import { api } from 'utils/api/api-client';
import { ChannelMessageRecipient } from '../types/ChannelMessageRecipient';
import { channelMessagesModel } from './channel-messages-model';

const config = {
  entities: {
    related: {
      // TODO: configure includes here
      ...auditableConfig,
      recipient_contact: {
        include: 'recipient_contact',
        model: contactsModel
      },
      channel_message: {
        include: 'channel_message',
        model: channelMessagesModel
      }
    }
  }
};

const actionCreators = {
  resendItem: {
    request: ({
      channelMessageRecipient
    }: {
      channelMessageRecipient: ChannelMessageRecipient;
    }) =>
      api.post(`/communication/outbox/resend`, {
        channel_message_recipient: { id: channelMessageRecipient.id }
      }),
    reduce: _.identity
  }
};

export const channelMessageRecipientsModel = new Generator<
  ChannelMessageRecipient,
  typeof actionCreators
>('communication/channel-message-recipients', config).createEntityModel({
  actionCreators
});
