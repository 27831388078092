import * as React from 'react';
import { DialogProps, useDialog } from '@rexlabs/dialog';
import { OutlineButton } from '@rexlabs/button';
import { RecordDialog } from 'view/components/record-screen/dialog/dialog';
import { DialogContentConfig } from 'view/components/record-screen/dialog/types';
import { splitTransactionBlock } from '../blocks/split-transaction';
import { SplitBankTransactionManualDialog } from '../dialogs/split-bank-transaction-manual-dialog';
import { BatchReceiptingItem } from '../types';
import { useImportSubBankTransactions } from '../hooks/use-import-sub-bank-transaction';

export interface SplitBankTransactionDialogProps extends DialogProps {
  batchReceiptingItem: BatchReceiptingItem;
}

const content: DialogContentConfig = [
  {
    id: 'split-bank-transaction',
    label: 'Split Bank Transaction',
    blocks: [splitTransactionBlock]
  }
];

export function SplitBankTransactionDialog({
  onClose,
  batchReceiptingItem
}: SplitBankTransactionDialogProps) {
  const splitBankTransactionManualDialog = useDialog(
    SplitBankTransactionManualDialog
  );

  const { onHandleSubmit } = useImportSubBankTransactions(
    batchReceiptingItem.id
  );

  const handleSplitManualTransaction = () => {
    splitBankTransactionManualDialog.open({
      batchReceiptingItem,
      target: 'nested-dialogs'
    });

    onClose?.();
  };

  return (
    <RecordDialog
      title='Split bank transaction'
      content={content}
      onClose={onClose}
      handleSubmit={onHandleSubmit}
      footerLeft={
        <OutlineButton onClick={handleSplitManualTransaction}>
          Split manually
        </OutlineButton>
      }
      blockProps={{
        bankReferenceId: batchReceiptingItem.reference,
        amount: batchReceiptingItem.amount
      }}
    />
  );
}
