import React from 'react';

import EmptyTable from 'src/assets/illustrations/empty-table.svg';
import { Property } from 'src/modules/properties/types/property-types';

import { Message } from 'view/components/@luna/message';
import { EmptyCard } from 'view/components/record-screen/cards/empty-card';

import { useAddKeySetAction } from '../hooks/use-get-add-key-set-action';

export function KeySetTableEmptyState({ property }: { property: Property }) {
  const AddKeyAction = useAddKeySetAction(property);

  return (
    <EmptyCard hasGreyBackground={false}>
      <Message
        title='Keys'
        Illustration={EmptyTable}
        actions={[
          {
            ...AddKeyAction
          }
        ]}
      >
        Add and manage all the keys for this property
      </Message>
    </EmptyCard>
  );
}
