import React from 'react';

import { BlockConfig } from 'view/components/record-screen/types';
import Box from '@rexlabs/box';
import { Field } from '@rexlabs/form';

import { SimpleFileUploadInputWithErrorHandling } from 'view/components/inputs/file-upload-input/simple-file-upload-input-with-error-handling';

import { Bill } from '../types/Bill';

const validate = {
  definitions: {
    bills: {
      name: 'bill file',
      rules: 'required'
    }
  },
  messages: {
    required: 'Please upload a bill file.'
  }
};

export const uploadBillsBlock: BlockConfig<Bill> = {
  id: 'upload-bills',
  title: 'Upload bills',
  validate,
  Edit: () => {
    return (
      <Box flexDirection='column' gap={25}>
        <Field<typeof SimpleFileUploadInputWithErrorHandling>
          id='bills'
          name='bills'
          Input={SimpleFileUploadInputWithErrorHandling}
          inputProps={{
            multi: true,
            accept: 'application/pdf'
          }}
        />
        <div>Supported file formats: PDF.</div>
      </Box>
    );
  }
};
