import { ContactPaymentMethod } from 'data/models/entities/contacts/payment-methods';

export function getDefaultDisbursementOption(
  isOwnership: boolean,
  paymentMethods: Array<ContactPaymentMethod>
) {
  if (isOwnership) {
    return {
      isAccordionOpen: false,
      value: 'use_disbursement_preferences'
    } as const;
  }

  const hasOnlyBPayOrDirectDebit =
    paymentMethods.length === 1 &&
    ['bpay', 'direct_debit'].includes(paymentMethods[0].payment_method.id);

  if (hasOnlyBPayOrDirectDebit) {
    return {
      isAccordionOpen: true,
      value: paymentMethods[0]
    } as const;
  }

  const hasBPayOrDirectDebit = paymentMethods.some((paymentMethod) => {
    return ['bpay', 'direct_debit'].includes(paymentMethod.payment_method.id);
  });

  if (hasBPayOrDirectDebit) {
    return {
      isAccordionOpen: true,
      value: 'use_disbursement_preferences'
    } as const;
  }

  return {
    isAccordionOpen: false,
    value: 'use_disbursement_preferences'
  } as const;
}
