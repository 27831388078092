import { Quri, toQuri } from '@rexlabs/quri';
import { query } from '@rexlabs/model-generator';
import { propertyOwnershipModel } from 'src/modules/property-ownerships/models/property-ownership-model';
import { useMemo } from 'react';

export interface PropertyOwnershipQueryFilterArguments {
  propertyId?: string;
  ownershipId?: string;
  contactId?: string;
}

const getFilter = ({
  propertyId,
  ownershipId,
  contactId
}: PropertyOwnershipQueryFilterArguments) => {
  const queris: Quri[] = [];

  if (propertyId) {
    queris.push({
      field: 'property_id',
      op: '==',
      value: propertyId
    });
  }

  if (ownershipId) {
    queris.push({
      field: 'ownership_id',
      op: '==',
      value: ownershipId
    });
  }

  if (contactId) {
    queris.push({
      field: 'contact_id',
      op: '==',
      value: contactId
    });
  }

  return toQuri(queris);
};

const getPropertyOwnershipQuery = (filter: string) => query`{
  ${propertyOwnershipModel} (q: ${filter}) {
      id
      ownership
      status
      created_at
      property
  }
}`;

export function usePropertyOwnershipQuery({
  propertyId,
  ownershipId,
  contactId
}: PropertyOwnershipQueryFilterArguments) {
  const query = useMemo(
    () =>
      getPropertyOwnershipQuery(
        getFilter({ propertyId, ownershipId, contactId })
      ),
    [propertyId, ownershipId, contactId]
  );

  return query;
}
