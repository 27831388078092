import { Forms } from '@rexlabs/form';
import { SearchResultItem } from 'utils/api/get-search-results';
import { emptyMessageCreatorItem } from '../../common/data/empty-message-creator-item';
import { MessageTemplate } from '../../message-templates/types/MessageTemplate';
import { getRecipients } from './get-recipients';

export function getChangeHandlers(setRelatesTo, forms?: Forms) {
  return {
    // When the template changes, we want to update the subject and content in a different block
    handleTemplateChange: async (event) => {
      if (!forms) {
        return;
      }
      const messageTemplate: MessageTemplate = event.target.value;

      const channelToUse = messageTemplate.channels?.find(
        (channel) => channel.channel_type.id === 'sms'
      );
      const { setFieldValue: setDetailsFormFieldValue, values } = forms[
        'sms-details'
      ];

      setDetailsFormFieldValue(
        'sent_from',
        messageTemplate.send_from_author
          ? messageTemplate.send_from_author
          : emptyMessageCreatorItem
      );

      setDetailsFormFieldValue('smsContent', channelToUse?.plain_text_body);

      if (messageTemplate?.default_recipient_group) {
        const relatesTo = values.relates_to;
        const result = await getRecipients(messageTemplate, relatesTo);

        // set it into the form
        setDetailsFormFieldValue('recipients', result);
      }
    },
    // When the user changes the "relates_to" field, we need to reset everything else, as it is the basis of all the other fields
    handleRelatesToChange: (event) => {
      if (!forms) {
        return;
      }
      const newRelatesTo: SearchResultItem = event.target.value;
      const { resetField: resetDetailsFormField } = forms['sms-details'];

      // we need to do the details form field-by-field, so that we don't clobber the new relates_to value
      const fields = [
        { field: 'template', value: null },
        { field: 'sent_from', value: emptyMessageCreatorItem },
        { field: 'smsContent', value: null }
      ];
      fields.forEach(({ field, value }) => {
        resetDetailsFormField(field, value);
      });

      setRelatesTo(newRelatesTo);
    }
  };
}
