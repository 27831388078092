import { BaseModelGeneratorModel } from '@rexlabs/model-generator';

import { Generator } from 'data/models/generator';
import { api } from 'utils/api/api-client';
import { ValueListValue } from 'data/models/types';
import {
  AgencyFee,
  FeeAmount
} from 'src/modules/agency-fees/types/agency-fee-types';

export type PropertyOwnershipFee = BaseModelGeneratorModel & {
  tax_type?: ValueListValue<string>;
  agency_fee?: AgencyFee;
  fee_amount: FeeAmount;
  fee_amount_matches_agency_fee: boolean;
  is_tax_included: boolean;
};

const config = {
  entities: {
    related: {
      agency_fee: {
        include:
          'agency_fee,agency_fee.agency_chart_of_accounts_account,agency_fee.ownership_chart_of_accounts_account,agency_fee.tax_type'
      },
      tax_type: {
        include: 'tax_type'
      }
    },
    api: {
      fetchList: (type, { propertyOwnershipId, ...args }) => {
        return api.get(
          `/property-ownerships/${propertyOwnershipId}/fees`,
          args
        );
      },
      updateItem: (type, { propertyOwnershipId, ...args }, id) =>
        api.patch(
          `/property-ownerships/${propertyOwnershipId}/fees/${id}`,
          args
        ),
      trashItem: (type, { propertyOwnershipId }, id) => {
        return api.delete(
          `/property-ownerships/${propertyOwnershipId}/fees/${id}`
        );
      }
    }
  }
};

export const propertyOwnershipsFeesModel = new Generator<PropertyOwnershipFee>(
  'property-ownerships/fees',
  //@ts-ignore
  config
).createEntityModel();
