import React, { ComponentType } from 'react';
import { StyleSheet, keyframes, useStyles } from '@rexlabs/styling';
import ReactDOM from 'react-dom';
import { Portal, PortalTarget } from '@rexlabs/portal';
import { Dialog } from '@headlessui/react';
import { noop } from 'lodash';

import { DrawerProps } from 'src/modules/drawer/components/drawer';

export type DrawerItem = {
  id: number;
  open: boolean;
  Drawer: ComponentType<any>;
  props: Partial<DrawerProps>;
};

type DrawerContextType = {
  drawers: DrawerItem[];
  addDrawer: (drawer: DrawerItem) => void;
  updateDrawer: (drawer: Partial<DrawerItem>) => void;
};

const fadeIn = keyframes({
  '0%': {
    opacity: 0,
    transform: `translateX(100%)`
  },

  '100%': {
    opacity: 1,
    transform: `translateX(0)`
  }
});

const styles = StyleSheet({
  root: {
    position: 'fixed',
    right: 0,
    top: 0,
    width: 460,
    maxWidth: '100%',
    zIndex: 1,
    height: '100vh',
    background: ({ token }) => token('palette.white'),
    boxShadow: ({ token }) => token('shadow.menu'),
    animation: `${fadeIn} 400ms cubic-bezier(0.46, 0.03, 0.52, 0.96)`
  },
  overlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 460,
    zIndex: 11
  }
});

export const DrawerContext = React.createContext<DrawerContextType>({
  drawers: [],
  addDrawer: noop,
  updateDrawer: noop
});

export function DrawerProvider({ children }) {
  const s = useStyles(styles);
  const [drawers, setDrawers] = React.useState<DrawerItem[]>([]);

  function addDrawer(drawer: DrawerItem) {
    setDrawers((prev) => [...prev, drawer]);
  }

  function updateDrawer(drawer: Partial<DrawerItem>) {
    setDrawers((prev) =>
      prev.map((d) => (d.id === drawer.id ? { ...d, ...drawer } : d))
    );
  }

  return (
    <DrawerContext.Provider value={{ drawers, addDrawer, updateDrawer }}>
      {children}
      {drawers.map(({ id, open, Drawer, props }) => {
        const onClose = () => updateDrawer({ props, Drawer, id, open: false });
        return (
          <Portal target='drawer' key={id}>
            <Dialog open={open} onClose={onClose}>
              <Dialog.Overlay onClick={onClose} {...s('overlay')} />
              <Dialog.Panel {...s('root')}>
                <Drawer {...props} onClose={onClose} />
              </Dialog.Panel>
            </Dialog>
          </Portal>
        );
      })}
    </DrawerContext.Provider>
  );
}

export function DrawerTarget() {
  return ReactDOM.createPortal(
    <>
      <PortalTarget name='drawer'>
        {({ children }) => <>{children}</>}
      </PortalTarget>
    </>,
    document.body
  );
}
