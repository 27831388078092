import { EmptyCard } from 'view/components/record-screen/cards/empty-card';
import EmptyTable from 'src/assets/illustrations/empty-table.svg';
import { Message } from '@rexlabs/notifications';
import * as React from 'react';
import { RecordType } from 'data/models/types';
import { BaseModelGeneratorModel } from '@rexlabs/model-generator';
import { useGetCreateNoteAction } from '../hooks/use-get-create-note-action';

export function NotesListEmptyState({
  recordType,
  record,
  hideAddNoteToField
}: {
  recordType?: RecordType;
  record?: BaseModelGeneratorModel;
  hideAddNoteToField?: boolean;
}) {
  const getCreateNoteAction = useGetCreateNoteAction(recordType);

  return (
    <EmptyCard>
      <Message
        title='Notes'
        Illustration={EmptyTable}
        actions={[
          {
            ...getCreateNoteAction({ attached: record, hideAddNoteToField }),
            type: 'primary'
          }
        ]}
      >
        Add notes to this record
      </Message>
    </EmptyCard>
  );
}
