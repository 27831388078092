import { get } from 'lodash';
import dayjs from 'dayjs';

import Validator from '@rexlabs/validator';

function hasDateRangeValues(startDate: string, endDate: string) {
  if (startDate && endDate) {
    return true;
  }
}

function isStartDateGreaterThanEndDate(startDate: string, endDate: string) {
  return dayjs(startDate).isBefore(dayjs(endDate));
}

function isStartDateLessThanOrEqualToEndDate(
  startDate: string,
  endDate: string
) {
  return (
    dayjs(startDate).isSame(dayjs(endDate)) ||
    isStartDateGreaterThanEndDate(startDate, endDate)
  );
}

Validator.registerImplicit(
  'startDateLessThanEndDate',
  function (this: any, startDate: string, endDateFieldName: string) {
    const endDate: string = get(this.validator.input, endDateFieldName);

    if (!hasDateRangeValues(startDate, endDate)) return true;

    return isStartDateGreaterThanEndDate(startDate, endDate);
  },
  'Start date must be less than the end date'
);

Validator.register(
  'startDateLessThanOrEqualToEndDate',
  function (this: any, startDate: string, endDateFieldName: string) {
    const endDate: string = get(this.validator.input, endDateFieldName);

    if (!hasDateRangeValues(startDate, endDate)) return true;

    return isStartDateLessThanOrEqualToEndDate(startDate, endDate);
  },
  'Start date must be less than or equal to the end date'
);

Validator.registerImplicit(
  'endDateGreaterThanStartDate',
  function (this: any, endDate: string, startDateFieldName: string) {
    const startDate: string = get(this.validator.input, startDateFieldName);

    if (!hasDateRangeValues(startDate, endDate)) return true;

    return isStartDateGreaterThanEndDate(startDate, endDate);
  },
  'End date must be greater than the start date'
);

Validator.register(
  'endDateLessThanOrEqualToStartDate',
  function (this: any, endDate: string, startDateFieldName: string) {
    const startDate: string = get(this.validator.input, startDateFieldName);

    if (!hasDateRangeValues(startDate, endDate)) return true;

    return isStartDateLessThanOrEqualToEndDate(startDate, endDate);
  },
  'End date must be less than or equal to the start date'
);
