import { CreateManualAccountingJournalEntryFormData } from './types/create-manual-accounting-journal-entry-form-data';
import { ManualAccountingJournalEntryCreateRequest } from './types/manual-accounting-journal-entry-create-request';

export async function mapManualAccountingJournalEntryFormDataToCreateRequest(
  formData: CreateManualAccountingJournalEntryFormData
): Promise<ManualAccountingJournalEntryCreateRequest> {
  return {
    date_of: formData.date_of,
    is_tax_included: !!formData.is_tax_included,
    description: formData.description,
    object: {
      type: {
        id: formData.folio.__record_type
      },
      id: formData.folio.id
    },
    revenue_entry: {
      amount: formData.amount,
      tax_type: {
        id: formData.tax_type.id
      },
      chart_of_accounts_account: {
        id: formData.account.id
      },
      ...(formData.property?.id && {
        property: {
          id: formData.property.id
        }
      })
    }
  };
}
