import React, { ReactNode, PropsWithChildren } from 'react';

import { Card, CardContent } from 'view/components/@luna/card';

export type RecordTitleCardProps = PropsWithChildren<{
  titleBlock: ReactNode;
}>;

export function RecordTitleCard({
  children,
  titleBlock
}: RecordTitleCardProps) {
  return (
    <Card width='100%' Container='header'>
      <CardContent flexDirection='column'>
        {titleBlock}
        {children}
      </CardContent>
    </Card>
  );
}
