import React from 'react';
import ROUTES from 'routes/app';
import { EmptySection } from './base';

export function EmptyInvoice() {
  return (
    <EmptySection
      headline='There are no invoice details available'
      buttonLabel='View invoices'
      linkProps={{ to: ROUTES.INVOICES_LIST }}
    >
      View existing invoices
    </EmptySection>
  );
}
