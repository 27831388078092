import { Contact } from 'src/modules/contacts/types/contact-types';
import { getRecipientText } from '../utils/get-recipient-text';
import { BulkCreateSmsMessageFormData } from '../types/BulkCreateSmsMessageFormData';
import { SmsPreviewProps } from '../../common/components/sms-preview';

export function mapBulkCreateMessageRequestToSmsPreviewProps(
  formData: BulkCreateSmsMessageFormData,
  recipients: Contact[] | undefined
): SmsPreviewProps {
  return {
    bodyContent: formData?.smsContent ?? '',
    recipients: getRecipientText(recipients ?? [])
  };
}
