import React from 'react';

import Box from '@rexlabs/box';
import { useMediaQuery } from '@rexlabs/breakpoints';

import { Coordinates } from 'src/lib/mapbox/types/Coordinates';

import { CardContent } from 'view/components/@luna/card';
import RenderLoading from 'view/components/@luna/render-loading';

import { useInspectionRunContext } from '../hooks/use-inspection-run-context';
import { DirectionsMap } from './directions-map';

export function InspectionRunMap() {
  const matchesMobile = useMediaQuery({ maxWidth: 's' });

  const { inspectionRunInspections, inspectionRun } = useInspectionRunContext();

  const isLoading = !inspectionRunInspections && !inspectionRun;

  return (
    <RenderLoading isLoading={isLoading}>
      <Box>
        <CardContent
          flexDirection='column'
          width={'100%'}
          style={{ backgroundColor: 'white' }}
        >
          <DirectionsMap
            height={matchesMobile ? '50rem' : '60rem'}
            routes={[
              [
                parseFloat(inspectionRun!.start_longitude),
                parseFloat(inspectionRun!.start_latitude)
              ],
              ...inspectionRunInspections!.map((inspection) => {
                return [
                  parseFloat(inspection.property!.address!.longitude!),
                  parseFloat(inspection.property!.address!.latitude!)
                ] as Coordinates;
              }),
              [
                parseFloat(inspectionRun!.finish_longitude),
                parseFloat(inspectionRun!.finish_latitude)
              ]
            ]}
          />
        </CardContent>
      </Box>
    </RenderLoading>
  );
}
