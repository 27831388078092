import { useDialog } from '@rexlabs/dialog';
import { ActionDeclaration } from 'src/modules/common/actions/types/action-declaration-types';
import { ChannelTypeId } from 'src/modules/communications/common/types/channel';
import { ViewMessageRecordDialog } from '../../dialogs/view-message-record-dialog';
import { ChannelMessageRecipient } from '../../types/ChannelMessageRecipient';
import { ViewSMSRecordDialog } from '../../dialogs/view-sms-record-dialog';
import { Message } from '../../types/Message';

export function useGetViewMessageAction() {
  const messageViewDialog = useDialog(ViewMessageRecordDialog);
  const smsViewDialog = useDialog(ViewSMSRecordDialog);

  return ({
    channelType,
    message
  }: {
    channelType?: ChannelTypeId;
    message?: Message;
  }): ActionDeclaration => {
    return {
      intent: 'primary',
      id: 'view-message',
      group: 'messages',
      label: 'View message',
      handleAction: async () => {
        if (channelType === 'sms') {
          return smsViewDialog.open({
            data: { message }
          });
        }

        return messageViewDialog.open({
          data: { message }
        });
      }
    };
  };
}

export function getViewMessageActionArgs(
  channelMessageRecipient: ChannelMessageRecipient
) {
  const channelType = channelMessageRecipient.channel_message?.channel_type?.id;
  const message = channelMessageRecipient.channel_message?.message;

  return {
    channelType,
    message
  };
}
