import { DialogProps } from '@rexlabs/dialog';
import { useModelActions } from '@rexlabs/model-generator';
import { propertiesModel } from 'data/models/entities/properties';
import * as React from 'react';
import { Property } from 'src/modules/properties/types/property-types';
import { getUploadedFileMeta } from 'utils/files';
import { useToast } from 'view/components/@luna/notifications/toast';
import { RecordDialog } from 'view/components/record-screen/dialog/dialog';
import { applianceDetailsBlock } from '../blocks/appliance-details-block';
import { applianceManualsBlock } from '../blocks/appliance-manuals-block';
import { applianceReceiptsAndWarrantiesBlock } from '../blocks/appliance-receipts-and-warranties-block';
import { appliancesModel } from '../models/appliances-model';

export interface CreateApplianceRecordDialogProps extends DialogProps {
  initialValues: {
    type?: string;
    property: Property;
    manuals: [];
    warranties: [];
  };
  onCreate?: (data) => void;
}

const content = [
  {
    id: 'add-appliance',
    label: 'Add Appliance',
    blocks: [
      applianceDetailsBlock,
      applianceReceiptsAndWarrantiesBlock,
      applianceManualsBlock
    ]
  }
];

export function CreateApplianceRecordDialog({
  onClose,
  initialValues,
  onCreate
}: CreateApplianceRecordDialogProps) {
  const { createItem, refreshLists } = useModelActions(appliancesModel);
  const { refreshItem: refreshProperty } = useModelActions(propertiesModel);

  const { addToast } = useToast();

  const handleSubmit = async ({ values, changedValues }) => {
    const data = { ...values, ...changedValues };

    if ('manuals' in changedValues) {
      const { manuals = [] } = changedValues;
      data.manuals = await getUploadedFileMeta(manuals);
    }

    if ('warranties' in changedValues) {
      const { warranties = [] } = changedValues;
      data.warranties = await getUploadedFileMeta(warranties);
    }

    if ('location' in changedValues) {
      data.location = changedValues.location.name;
    }

    const { data: appliance } = await createItem({ data: data });

    addToast({
      description: `${appliance.type} has been added as an appliance to ${initialValues.property.display_name}`
    });

    await Promise.all([
      refreshLists(),
      refreshProperty({
        id: initialValues.property.id
      })
    ]);
    onCreate?.(appliance);

    return data;
  };

  return (
    <RecordDialog
      title='Add appliance'
      data={initialValues}
      content={content}
      onClose={onClose}
      handleSubmit={handleSubmit}
    />
  );
}
