import { useGetGenericWipAction } from 'view/hooks/actions/use-get-generic-wip-action';

export function useGetAccountingEntityActionGroup() {
  const getGenericWipAction = useGetGenericWipAction();

  return () => [
    getGenericWipAction({
      id: 'create-financial-journal-entry',
      group: 'accounting',
      actionName: 'Create financial journal entry'
    })
  ];
}
