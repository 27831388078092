import React, { useCallback, useEffect } from 'react';
import { Field, Form, ReactForms } from '@rexlabs/form';
import { EmailInput } from '@rexlabs/text-input';
import { PrimaryButton, TintIconButton } from '@rexlabs/button';
import Box from '@rexlabs/box';
import { useModelActions, useModelState } from '@rexlabs/model-generator';
import { Link, useWhereabouts } from '@rexlabs/whereabouts';
import { Body } from '@rexlabs/text';
import { PasswordInput } from '@rexlabs/password-input';
import { Checkbox } from '@rexlabs/checkbox';
import icons from '@rexlabs/icons';
import { Column } from '@rexlabs/grid';
import { StyleSheet, useStyles } from '@rexlabs/styling';

import config from 'config';
import ROUTES from 'routes/auth';
import { FormGrid } from 'view/components/@luna/grid/form';
import { sessionModel } from 'data/models/custom/session';
import { UserAvatar } from 'view/components/avatars/user';
import { Trans, useTranslation } from 'react-i18next';
import i18n from 'src/modules/i18n/i18n';
import { PrimaryHeading } from '../components/heading-primary';

const { CrossSmall } = icons;

const validate = {
  definitions: {
    email: {
      rules: 'required|email',
      name: i18n.t('form.general.email.label')
    },
    password: { rules: 'required', name: i18n.t('form.general.password.label') }
  }
};

const defaultStyles = StyleSheet({
  userTag: {
    width: '100%',
    height: '5.2rem',
    paddingLeft: ({ token }) => token('spacing.s'),
    paddingRight: ({ token }) => token('spacing.m'),
    background: ({ token }) => token('color.primary.idle.contrast'),
    borderRadius: ({ token }) => token('border.radius.l')
  }
});

export function LoginScreen() {
  const { t } = useTranslation();
  const whereabouts = useWhereabouts();
  const s = useStyles(defaultStyles);

  const { login, logout } = useModelActions(sessionModel);
  const { user, expired } = useModelState(sessionModel);

  // We want to log the user out if they e.g. entered the forgotten password
  // flow and entered a different email address
  useEffect(() => {
    if (
      user?.email &&
      whereabouts?.query?.email &&
      user.email !== whereabouts.query.email
    ) {
      logout();
    }
  }, [whereabouts?.query?.email, user?.email, logout]);

  const handleSubmit = useCallback(
    (values, { setServerError }) =>
      login({
        email: values.email,
        password: values.password,
        ttl: values.remember
          ? 3600 * 24 * 7 // 7 days
          : 3600 * 2 // 2 hours
      }).catch(setServerError),
    [login]
  );

  const renderGreeting = () => {
    if (expired) {
      return user?.given_name
        ? `Please log back in, ${user.given_name}`
        : 'Please log back in';
    }

    return user?.given_name ? (
      <Trans
        i18nKey='login.primary-headline'
        values={{ name: user.given_name }}
      >
        Nice to see you again, {user.given_name}!
      </Trans>
    ) : (
      <Trans i18nKey='login.primary-headline.without-name'>
        Hello! Let’s get you logged in.
      </Trans>
    );
  };

  return (
    <ReactForms
      validate={validate}
      handleSubmit={handleSubmit}
      initialValues={{
        email: whereabouts?.query?.email || user?.email || config.USER?.EMAIL,
        password: config?.USER?.PASSWORD
      }}
    >
      {({ isSubmitting, values }) => {
        return (
          <Box flexDirection='column'>
            <Box mb='2.4rem'>
              <Body grey as='span'>
                <Trans i18nKey='login.secondary-headline'>
                  Welcome to Rex Property Management
                </Trans>
              </Body>
            </Box>
            <Box mb='3.2rem'>
              <PrimaryHeading level='display'>
                {renderGreeting()}
              </PrimaryHeading>
            </Box>
            <Form name='login'>
              <FormGrid>
                <Column width={12}>
                  {user?.email ? (
                    <Box
                      {...s('userTag')}
                      alignItems='center'
                      justifyContent='space-between'
                    >
                      <Box sx='.8rem' flexDirection='row' alignItems='center'>
                        <UserAvatar name={user.given_name} size='s' />
                        <Body>{user.email}</Body>
                      </Box>
                      <TintIconButton
                        size='xs'
                        tabIndex={1}
                        onClick={() => logout()}
                        Icon={CrossSmall}
                      />
                    </Box>
                  ) : (
                    <Field
                      name='email'
                      label={t('form.general.email.label', 'Email') as string}
                      Input={EmailInput}
                      inputProps={{ tabIndex: 1 }}
                    />
                  )}
                </Column>
                <Column width={12}>
                  <Field
                    name='password'
                    label={
                      t('form.general.password.label', 'Password') as string
                    }
                    Input={PasswordInput}
                    inputProps={{ tabIndex: 2 }}
                    HelpContent={() => (
                      <Link
                        to={ROUTES.FORGOT_PASSWORD}
                        query={values.email ? { email: values.email } : {}}
                        // eslint-disable-next-line
                        // @ts-ignore
                        tabIndex={3}
                      >
                        <Trans i18nKey='login.form.password.forgot'>
                          Forgot your password?
                        </Trans>
                      </Link>
                    )}
                  />
                </Column>
                <Column width={12} mt='1.6rem'>
                  <Box
                    flexDirection='row'
                    alignItems='center'
                    justifyContent='space-between'
                  >
                    <Field
                      name='remember'
                      Input={Checkbox}
                      inputProps={{
                        label: t('login.form.remember-me.label'),
                        tabIndex: 4
                      }}
                      optional={false}
                    />
                    <PrimaryButton
                      type='submit'
                      isLoading={isSubmitting}
                      tabIndex={5}
                    >
                      <Trans i18nKey='login.form.submit.label'>
                        Log in to your account
                      </Trans>
                    </PrimaryButton>
                  </Box>
                </Column>
              </FormGrid>
            </Form>
          </Box>
        );
      }}
    </ReactForms>
  );
}
