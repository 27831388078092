export const OWNERSHIPS_ROUTES = {
  OWNERSHIPS_LIST: {
    config: {
      path: '/ownerships',
      Component: require('./screens/ownerships-list').OwnershipsList
    },

    CREATE: {
      config: {
        path: '/ownerships/create',
        Component: require('./dialogs/create-ownership-dialog')
          .CreateOwnershipDialog
      }
    }
  },

  OWNERSHIP: {
    config: {
      path: '/ownership/:ownershipId',
      Component: require('./screens/ownership-details').OwnershipDetailsScreen
    }
  }
};
