import Box from '@rexlabs/box';
import {
  useToken,
  useStyles,
  StyleSheet,
  text,
  padding
} from '@rexlabs/styling';
import { Semibold } from '@rexlabs/text';
import React from 'react';
import { MessageTemplateSelect } from 'src/modules/communications/common/components/selects/message-template-select';
import InfoCircleIcon from 'view/components/icons/info';
import { Grid } from 'view/components/@luna/form/grid';
import { Field } from '@rexlabs/form';
import { ChannelTypeId } from 'src/modules/communications/common/types/channel';
import { ValueListValue } from 'data/models/types';
import { TaskType } from '../../../common/types/TaskType';

const defaultStyles = StyleSheet({
  header: {
    ...text.styles({
      fallback: 'heading.3'
    }),
    ...padding.styles({
      top: 'l',
      bottom: 's'
    })
  },
  info: {
    fontSize: ({ token }) => token('typography.size.s')
  }
});

export function MessageTemplateContent({
  taskType,
  channelType
}: {
  taskType: TaskType;
  channelType?: ValueListValue<ChannelTypeId>;
}) {
  const token = useToken();
  const s = useStyles(defaultStyles);

  return (
    <>
      <Grid columns={1}>
        <Field
          id='message_template'
          name='template'
          label='Message template'
          Input={MessageTemplateSelect}
          inputProps={{
            disabled: !channelType,
            channelType: channelType?.id,
            placeholder: 'Select a template',
            contextType: taskType
          }}
        />
        <Semibold>
          <Box
            display='flex'
            flexDirection='row'
            alignItems='center'
            spacing={token('spacing.s')}
            {...s('info')}
          >
            <InfoCircleIcon />
            {`This subtask will be marked as complete after the message is sent`}
          </Box>
        </Semibold>
      </Grid>
    </>
  );
}
