import React, { useMemo } from 'react';
import { useDialog } from '@rexlabs/dialog';

import { BlockConfig } from 'view/components/record-screen/types';
import { ActionButtons } from 'view/components/@luna/action-buttons';

import { Trans } from 'react-i18next';
import { CreateSecurityDepositDialog } from '../dialogs/create-security-deposit';
import { SecurityDepositsList } from '../components/security-deposits-list';

type GetProps<Data = any> = (options: {
  data: Data;
}) => {
  propertyTenancyId?: string;
};

export function getSecurityDepositsBlock<Data = any>(
  getProps: GetProps<Data>
): BlockConfig {
  return {
    id: 'security-deposits',
    title: () => <Trans i18nKey='bonds-and-deposits.title' />,
    View: ({ data }) => {
      const { propertyTenancyId } = useMemo(() => {
        return getProps({ data });
      }, [data.id]);

      return <SecurityDepositsList propertyTenancyId={propertyTenancyId} />;
    },
    Actions: ({ data }) => {
      const { propertyTenancyId } = useMemo(() => getProps({ data }), [data]);
      const { open } = useDialog(CreateSecurityDepositDialog);
      if (!propertyTenancyId) {
        return null;
      }

      return (
        <ActionButtons
          actions={[
            {
              label: 'Add security deposit',
              handleAction: () => open({ propertyTenancyId })
            }
          ]}
        />
      );
    }
  };
}
