import { useModelActions } from '@rexlabs/model-generator';
import {
  financialsReconciliationsAdjustmentsModel,
  ReconciliationAdjustment
} from 'data/models/entities/financials/reconciliations/adjustments';
import { useDialog } from '@rexlabs/dialog';
import { CaptureAdjustmentStatusChangeReason } from 'src/modules/reconciliations/dialogs/resolve-adjustment';
import {
  financialsReconciliationsModel,
  Reconciliation
} from 'data/models/entities/financials/reconciliations';
import Box from '@rexlabs/box';
import { ResolutionActionButton } from 'src/modules/reconciliations/components/resolution-action-button';
import { DeleteActionButton } from 'src/modules/reconciliations/components/delete-action-button';
import React from 'react';

export const AdjustmentActionCell = (props) => {
  const reconciliationArgs = {
    include: 'reconciliation_summary'
  };
  const { resolve, unresolve, trashItem } = useModelActions(
    financialsReconciliationsAdjustmentsModel
  );

  const { open: openCaptureAdjustmentReason } = useDialog(
    CaptureAdjustmentStatusChangeReason
  );

  const { refreshItem: refreshReconciliation } = useModelActions(
    financialsReconciliationsModel
  );

  const value = props.cell.value as ReconciliationAdjustment & {
    reconciliation: Reconciliation;
  };

  const isResolved = value.status.id === 'resolved';
  const handleClick = async () => {
    openCaptureAdjustmentReason({
      reconciliation: value.reconciliation,
      adjustmentId: value.id,
      updateListId: 'reconciliation-adjustments',
      title: isResolved ? 'Unresolve adjustment' : 'Resolve adjustment',
      submitLabel: isResolved ? 'Unresolve' : 'Resolve',
      handleSubmit: async (statusChangeReason) => {
        const handlerFn = isResolved ? unresolve : resolve;
        await handlerFn(statusChangeReason);
        await refreshReconciliation({
          id: value.reconciliation.id,
          args: reconciliationArgs
        });
      }
    });
  };

  const canDelete = value.source_reconciliation?.status.id === 'active';
  const handleDelete = async () => {
    await trashItem({
      id: value.id,
      args: {
        id: value.id,
        reconciliationId: value.reconciliation.id
      }
    });
    await refreshReconciliation({
      id: value.reconciliation.id,
      args: reconciliationArgs
    });
  };

  return (
    <Box
      flexDirection='row'
      justifyContent='center'
      alignItems='center'
      style={{ gap: 24 }}
    >
      <ResolutionActionButton isResolved={isResolved} onClick={handleClick} />
      <DeleteActionButton canDelete={canDelete} onClick={handleDelete} />
    </Box>
  );
};
