import * as React from 'react';
import { GhostIconButton } from '@rexlabs/button';
import { RemoveIcon } from 'view/components/icons/remove';
import { useStyles, StyleSheet } from '@rexlabs/styling';

const defaultStyles = StyleSheet({
  removeIcon: {
    color: ({ token }) => token('color.textStyle.danger.idle.default')
  }
});

export function LineItemRemoveButton(
  props: Omit<
    React.ComponentProps<typeof GhostIconButton>,
    'Icon' | 'aria-label'
  >
) {
  const s = useStyles(defaultStyles);
  return (
    <GhostIconButton
      {...props}
      aria-label='delete'
      Icon={RemoveIcon}
      {...s('removeIcon')}
    />
  );
}
