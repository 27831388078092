import React from 'react';
import { useDialog } from '@rexlabs/dialog';

import EmptyTable from 'assets/illustrations/empty-table.svg';

import { EmptyCard } from 'view/components/record-screen/cards/empty-card';
import { Message } from 'view/components/@luna/message';

import { RenewCheckDialog } from 'src/modules/compliance/common/dialogs/renew-check-dialog';
import { useModelActions } from '@rexlabs/model-generator';
import {
  sharedIncludes,
  taskIncludes,
  tasksModel
} from 'src/modules/tasks/common/models/tasks-model';
import { useRenewFromTaskToast } from 'src/modules/compliance/common/hooks/use-renew-from-task-toast';
import { PropertyComplianceTask } from 'src/modules/tasks/property-compliance/types/PropertyComplianceTask';

interface PropertyComplianceEntryTableEmptyStateProps {
  task?: PropertyComplianceTask;
}

export function PropertyComplianceEntryTableEmptyState({
  task
}: PropertyComplianceEntryTableEmptyStateProps) {
  const { open: openPropertyRenewEntryDialog } = useDialog(RenewCheckDialog);
  const { refreshItem } = useModelActions(tasksModel);

  const addToast = useRenewFromTaskToast();
  const handleSave = (updatedData) => {
    addToast(updatedData);
    return refreshItem({
      id: task?.id,
      args: {
        include: [
          taskIncludes.propertyComplianceDetails,
          ...sharedIncludes
        ].join(',')
      }
    });
  };
  const complianceType =
    task?.details?.compliance_type ||
    task?.details?.compliance_entry?.compliance_type;
  const complianceTypeLabel = complianceType?.label;
  const complianceTypeCategoryLabel = complianceType?.category?.label;

  return (
    <EmptyCard hasGreyBackground={false}>
      <Message
        title={complianceTypeLabel}
        Illustration={EmptyTable}
        actions={[
          {
            label: `Add ${complianceTypeCategoryLabel}`,
            intent: 'primary',
            handleAction: () =>
              openPropertyRenewEntryDialog({
                onSave: handleSave,
                propertyId: task?.property?.id,
                complianceType
              })
          }
        ]}
      >
        No {complianceTypeLabel} added for this property.
      </Message>
    </EmptyCard>
  );
}
