import { useConfirmationDialog } from '@rexlabs/dialog';
import { useModelActions } from '@rexlabs/model-generator';

import { ActionDeclaration } from 'src/modules/common/actions/types/action-declaration-types';
import {
  ComplianceEntry,
  complianceEntriesModel
} from '../models/compliance-entries';

export function useGetDeleteComplianceEntryAction() {
  const { open: openConfirmationDialog } = useConfirmationDialog();
  const { trashItem } = useModelActions(complianceEntriesModel);
  return ({ data }: { data: ComplianceEntry }): ActionDeclaration => {
    return {
      label: 'Delete',
      id: 'delete-compliance-entry',
      intent: 'danger',
      handleAction: () => {
        openConfirmationDialog({
          title: 'Delete compliance entry',
          message:
            'Are you sure you wish to delete this compliance entry? If you want to archive this entry, you can upload a renewed entry.',
          onConfirm: () => {
            trashItem({ id: data.id });
          }
        });
      }
    };
  };
}
