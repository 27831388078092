import React from 'react';

import Box from '@rexlabs/box';
import { Heading } from '@rexlabs/text';

import { Contact } from 'src/modules/contacts/types/contact-types';
import { FinancialPeriod } from 'src/modules/statements/types';

import { Ownership } from 'data/models/entities/ownerships';

import { Card, CardContent } from 'view/components/@luna/card';
import { ActionButtons } from 'view/components/@luna/action-buttons';

import { isDateWithinRange } from '../utils/is-date-within-range';
import { useGetCreateTaxAdjustmentAction } from '../hooks/use-get-create-tax-adjustment-action';
import { TaxPaymentsAndWithholdingsTable } from './tax-payments-and-withholdings-table';

type TaxPaymentsAndWithholdingsProps = {
  owner: Contact;
  ownership: Ownership;
  financialYear: FinancialPeriod;
};

export function TaxPaymentsAndWithholdingsCard({
  owner,
  ownership,
  financialYear
}: TaxPaymentsAndWithholdingsProps) {
  const getCreateTaxAdjustmentAction = useGetCreateTaxAdjustmentAction();
  const createTaxAdjustmentAction = getCreateTaxAdjustmentAction(
    ownership,
    owner
  );
  const isWithinCurrentFinancialYear = isDateWithinRange({
    startDate: financialYear.startDate,
    endDate: financialYear.endDate
  });

  return (
    <Card>
      <CardContent>
        <Box
          flexDirection='row'
          alignItems='center'
          justifyContent='space-between'
          sx='1.2rem'
          flex={1}
        >
          <Heading level={3}>HMRC payments and withholdings</Heading>
          {isWithinCurrentFinancialYear && (
            <Box ml='2.4rem'>
              <ActionButtons actions={[createTaxAdjustmentAction]} />
            </Box>
          )}
        </Box>
        <TaxPaymentsAndWithholdingsTable
          contact={owner}
          financialYear={financialYear}
          ownership={ownership}
        />
      </CardContent>
    </Card>
  );
}
