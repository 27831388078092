import { useGetPrimaryRecordActionGroup } from 'view/hooks/actions/use-get-primary-record-action-group';
import { useReportDownloadAction } from 'src/modules/common/actions/reporting/use-report-download-action';
import {
  BankWithdrawal,
  BankWithdrawalStatus
} from 'src/modules/banking/bank-withdrawals/types/bank-withdrawal-types';
import { useStatusChangeAction } from 'src/modules/common/actions/status/use-status-change-action';
import { bankWithdrawalModel } from 'src/modules/banking/bank-withdrawals/models/bank-withdrawal-model';
import {
  ActionDeclaration,
  SingleActionDeclaration
} from 'src/modules/common/actions/types/action-declaration-types';
import { useSessionState } from 'src/modules/common/hooks/session-state';
import { useGetReconcileAction } from '../utils/bank-withdrawals-actions/use-get-reconcile-action';
import { useGetUnreconcileAction } from '../utils/bank-withdrawals-actions/use-get-unreconcile-action';

export function useBankWithdrawalActions(): (
  bankWithdrawal: BankWithdrawal
) => ActionDeclaration[] {
  const getPrimaryRecordActionGroup = useGetPrimaryRecordActionGroup();
  const getReconcileAction = useGetReconcileAction();
  const getUnreconcileAction = useGetUnreconcileAction();
  const getDownloadReport = useReportDownloadAction();
  const statusChangeAction = useStatusChangeAction(bankWithdrawalModel);
  const { activeSilo } = useSessionState();

  return function (bankWithdrawal: BankWithdrawal) {
    const bpayAction = getDownloadReport(
      'Download BPAY file',
      {
        type: 'bank_withdrawal',
        object: bankWithdrawal
      },
      'bpay'
    );
    const abaAction = getDownloadReport(
      'Download ABA file',
      {
        type: 'bank_withdrawal',
        object: bankWithdrawal
      },
      'aba'
    );

    const sageAction = getDownloadReport(
      'Download Sage file',
      {
        type: 'bank_withdrawal',
        object: bankWithdrawal
      },
      'sage',
      'asynchronous_link'
    );

    const bacsAction = getDownloadReport(
      'Download BACS file',
      {
        type: 'bank_withdrawal',
        object: bankWithdrawal
      },
      'bacs',
      'asynchronous_link'
    );

    const fpsAction = getDownloadReport(
      'Download Faster Payments file',
      {
        type: 'bank_withdrawal',
        object: bankWithdrawal
      },
      'bacs', // Faster Payments uses the BACS Standard 18 format with some minor changes. The backend generator takes care of it
      'asynchronous_link'
    );

    const downloadActions: SingleActionDeclaration[] = [];
    switch (bankWithdrawal.type.id) {
      case 'bpay':
        downloadActions.push(bpayAction);
        break;
      case 'eft':
        downloadActions.push(abaAction);
        break;
      case 'eft_bacs':
        downloadActions.push(bacsAction);
        break;
      case 'eft_fps':
        downloadActions.push(fpsAction);
        break;
    }

    if (activeSilo?.country.id === 'GBR') {
      downloadActions.push(sageAction);
    }

    const voidAction = statusChangeAction<BankWithdrawalStatus>({
      record: bankWithdrawal,
      status: 'void'
    });

    const statusChangeActions: ActionDeclaration[] = [];
    switch (bankWithdrawal.status.id) {
      case 'withdrawn':
      case 'pending_withdrawal':
        statusChangeActions.push(voidAction);
    }

    return [
      ...getPrimaryRecordActionGroup('bank_withdrawal', bankWithdrawal.id),
      getReconcileAction(),
      getUnreconcileAction(),
      ...downloadActions,
      ...statusChangeActions
    ];
  };
}
