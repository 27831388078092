import React from 'react';

import Box from '@rexlabs/box';
import { padding, StyleSheet, useStyles, useToken } from '@rexlabs/styling';
import { ColoredCard } from 'view/components/cards/colored-card';

export type Intent = React.ComponentProps<typeof ColoredCard>['intent'];

interface BatchReceiptingCardLayoutProps {
  intent: Intent;
  label: React.ReactNode;
  value: React.ReactNode;
}

const defaultStyles = StyleSheet({
  content: {
    ...padding.styles({ y: 's', x: 'xl' })
  }
});

export function BatchReceiptingCardLayout({
  intent,
  label,
  value
}: BatchReceiptingCardLayoutProps) {
  const s = useStyles(defaultStyles);
  const token = useToken();
  return (
    <ColoredCard width='100%' intent={intent}>
      <Box
        flexDirection='column'
        justifyContent='space-between'
        gap={token('spacing.xxs')}
        {...s('content')}
      >
        <Box flexDirection='column' height='4rem'>
          {label}
        </Box>
        <Box flexDirection='column' height='4rem' gap={token('spacing.xxs')}>
          {value}
        </Box>
      </Box>
    </ColoredCard>
  );
}
