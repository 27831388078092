import { bytesToKb } from './bytes-to-kb';

export function createAttachmentFileName({
  fileName,
  fileSize
}: {
  fileName: string;
  fileSize?: number;
}): string {
  return `${fileName}${fileSize ? ` (${bytesToKb(fileSize)})` : ''}`;
}
