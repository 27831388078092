import { SingleActionDeclaration } from 'src/modules/common/actions/types/action-declaration-types';
import { Statement, StatementStatus } from 'src/modules/statements/types';
import { Ownership } from 'data/models/entities/ownerships';
import { RecordTypes } from 'data/models/types';
import {
  CreateStatementsArgs,
  useCreateStatements
} from 'src/modules/statements/hooks/use-create-statements';
import { useUpdateStatement } from 'src/modules/statements/hooks/use-update-statement';

export type CreateStatementArgs = Omit<
  CreateStatementsArgs,
  'selectedItems'
> & {
  recordType: typeof RecordTypes.Ownership;
  ownership: Ownership;
};

export interface UpdateStatementArgs {
  recordType: typeof RecordTypes.Statement;
  statement: Statement;
  status: StatementStatus;
  status_reason?: string;
  ignore_agency_fees?: boolean;
}

export type StatementStatusActionArgs =
  | (CreateStatementArgs & SharedArgs)
  | (UpdateStatementArgs & SharedArgs);

interface SharedArgs {
  label: string;
}

export function useGenericSetStatementStatus() {
  const createStatements = useCreateStatements();
  const updateStatement = useUpdateStatement();

  return (args: StatementStatusActionArgs): SingleActionDeclaration => {
    if (args.recordType === RecordTypes.Statement) {
      return {
        id: `statement_${args.status}`,
        group: 'status',
        label: args.label,
        handleAction: () =>
          updateStatement({
            statement: args.statement,
            data: {
              status: { id: args.status },
              status_reason: args.status_reason,
              ignore_agency_fees: args.ignore_agency_fees
            }
          })
      };
    }

    return {
      id: `statement_${args.status}`,
      group: 'status',
      label: args.label,
      handleAction: () =>
        createStatements({
          selectedItems: [args.ownership],
          statementEndDate: args.statementEndDate,
          statementType: args.statementType,
          recordType: RecordTypes.Ownership,
          status: args.status,
          status_reason: args.status_reason,
          statementStartDate: args.statementStartDate!,
          ignore_agency_fees: args.ignore_agency_fees
        })
    };
  };
}
