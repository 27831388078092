import { useDialog } from '@rexlabs/dialog';
import { nullFirstEffectiveFromDate } from 'src/modules/property-tenancies/utils/null-first-effective-from-date';
import { EmptyRentScheduleWarning } from 'src/modules/rent/dialogs/empty-rent-schedule-warning';
import { PaidToDateChangeWarning } from 'src/modules/rent/dialogs/paid-to-date-change-warning';
interface handleSubmitValidationArgs {
  values: any;
  changedValues: any;
  prefix: string;
}

export const useHandleSubmitValidation = () => {
  const emptyRentScheduleWarning = useDialog(EmptyRentScheduleWarning);
  const changingPaidToDateWarning = useDialog(PaidToDateChangeWarning);

  return async function handleSubmitValidation({
    values,
    changedValues,
    prefix
  }: handleSubmitValidationArgs) {
    if (
      'agreement_first_start_date' in changedValues[prefix] &&
      values[prefix].rent_schedule &&
      values[prefix].rent_schedule.length === 0
    ) {
      await new Promise<void>((resolve, reject) =>
        emptyRentScheduleWarning.open({
          onConfirm: resolve,
          onReject: reject
        })
      );
    } else if ('agreement_first_start_date' in changedValues[prefix]) {
      await new Promise<void>((resolve, reject) =>
        changingPaidToDateWarning.open({
          onConfirm: resolve,
          onReject: reject
        })
      );
    }
    if ('rent_schedule' in changedValues[prefix]) {
      changedValues[prefix].rent_schedule = nullFirstEffectiveFromDate(
        changedValues[prefix].rent_schedule
      );
    }

    return changedValues;
  };
};
