import React from 'react';
import { prepaymentsColumns } from 'src/modules/prepayments/utils/prepayments-columns';
import { ListTable } from 'view/components/table';
import { useGetPrepaymentsActions } from 'src/modules/prepayments/hooks/action-menu-items/use-get-prepayments-actions';
import { useTableFilters } from 'view/hooks/use-table-filters';
import { transformActionDeclarationsToActionMenuItems } from 'utils/actions/transforms';
import { query } from '@rexlabs/model-generator';
import { prepaymentBucketsModel } from 'src/modules/prepayments/models/prepayment-buckets-model';

const prepaymentsQuery = query`{
  ${prepaymentBucketsModel} {
    id
    chart_of_accounts_accounts
  }
}`;

export function PrepaymentBucketsTable() {
  const getPrepaymentsActions = useGetPrepaymentsActions();

  const { getSort, getFilters } = useTableFilters('prepayment-buckets');

  const getActionMenuItems = React.useCallback(
    ({ item }) => {
      const actions = getPrepaymentsActions(item);
      return transformActionDeclarationsToActionMenuItems(actions);
    },
    [getPrepaymentsActions]
  );
  return (
    <ListTable
      id={'prepayments'}
      columns={prepaymentsColumns}
      getQuery={() => prepaymentsQuery}
      getActionMenuItems={getActionMenuItems}
      getFilters={getFilters}
      getSort={getSort}
    />
  );
}
