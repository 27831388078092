import React from 'react';
import {
  AccountingJournalEntrySourceObject,
  AccountingJournalEntrySourceObjectType
} from 'src/modules/account-journal-entries/types/account-journal-entry-types';
import { RecordFlag } from 'view/components/record-flags/record-flag';
import { useTranslation } from 'react-i18next';
import { EntryLink } from '../components/entry-link';
import { EntryList } from '../components/entry-list';

export interface AccountingJournalEntrySourceProps {
  accountingJournalEntrySource: AccountingJournalEntrySourceObject;
  accountingJournalEntrySourceObjectType: AccountingJournalEntrySourceObjectType;
}

function getSource(
  accountingJournalEntrySource,
  accountingJournalEntrySourceObjectType
) {
  if (
    accountingJournalEntrySourceObjectType === 'trust_journal_entry_line_item'
  ) {
    return accountingJournalEntrySource.trust_journal_entry;
  } else {
    return accountingJournalEntrySource;
  }
}

export function AccountingJournalEntrySourceSection({
  accountingJournalEntrySource,
  accountingJournalEntrySourceObjectType
}: AccountingJournalEntrySourceProps) {
  const { t } = useTranslation();

  const type =
    accountingJournalEntrySourceObjectType === 'trust_journal_entry_line_item'
      ? 'trust_journal_entry'
      : accountingJournalEntrySourceObjectType;

  const source = getSource(
    accountingJournalEntrySource,
    accountingJournalEntrySourceObjectType
  );

  let description = '';

  switch (accountingJournalEntrySourceObjectType) {
    case 'credit_note': {
      description = 'Credit Note';
      break;
    }
    case 'invoice': {
      description = 'Invoice';
      break;
    }
    case 'trust_journal_entry_line_item':
      description = t('trust-journal-entries.label.singular');
      break;
  }

  return (
    <EntryList>
      <EntryLink type={type} id={source?.id}>
        <RecordFlag
          record={source}
          recordType={type}
          description={description}
        />
      </EntryLink>
    </EntryList>
  );
}
