import React from 'react';

import { RecordScreen } from 'view/components/record-screen';
import { TitleBlock } from 'view/components/@luna/title-block';
import { content } from '../data/supplier-compliance-settings-content';

export function SupplierComplianceSettings() {
  return (
    <RecordScreen
      titleBlock={<TitleBlock title={'Supplier compliance'} />}
      content={content}
      data={{}}
    />
  );
}
