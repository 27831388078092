import { ContentConfig } from 'view/components/record-screen/types';
import { FLAGS } from 'utils/feature-flags';
import { noteTableBlock } from 'src/modules/note/blocks/note-table-block';
import { documentsBlock } from 'src/modules/tasks/common/blocks/documents';
import { linkedTasksBlock } from 'src/modules/tasks/common/blocks/linked-tasks-block';
import { complianceDateBlock } from 'src/modules/tasks/common/blocks/compliance-date-block';
import { Task } from 'src/modules/tasks/common/types/Task';
import {
  getRelatedComplianceEntriesTableBlock,
  getTitle
} from 'src/modules/compliance/common/blocks/task-compliance-entries-table-block';
import { taskPriorityBlock } from '../../common/blocks/task-priority-block';
import { checklistsBlock } from '../../common/blocks/create-checklists-block';
import { propertyComplianceDetailsBlock } from '../blocks/property-compliance-details-block';

export const getContent = (task: Task<'task_property_compliance'>) =>
  [
    {
      label: 'General',
      items: [
        {
          id: 'overview',
          label: 'Overview',
          blocks: [
            propertyComplianceDetailsBlock,
            taskPriorityBlock,
            complianceDateBlock
          ]
        },
        {
          id: 'notes',
          label: 'Notes',
          blocks: [noteTableBlock]
        },
        {
          id: 'documents',
          label: 'Documents',
          blocks: [documentsBlock]
        },
        {
          id: 'checklist',
          label: 'Checklist',
          blocks: [checklistsBlock],
          flag: FLAGS.CHECKLIST_TEMPLATES
        }
      ]
    },
    {
      label: 'Manage',
      flag: FLAGS.LINKED_TASKS,
      items: [
        {
          id: 'compliance_entries',
          label: getTitle(task),
          blocks: [getRelatedComplianceEntriesTableBlock(task)]
        },

        {
          id: 'linked-tasks',
          label: 'Linked tasks',
          blocks: [linkedTasksBlock],
          flag: FLAGS.LINKED_TASKS
        }
      ]
    }
  ] as ContentConfig;
