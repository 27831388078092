import React, { useMemo } from 'react';

import {
  TitleBlock,
  TitleBlockProps
} from 'view/components/cards/title/title-block';

import { RecordObject, RecordType } from 'data/models/types';
import { BankAccount } from 'data/models/entities/financials/bank-accounts';
import { BankingAccountIcon } from 'view/components/icons/banking-account';
import { formatCurrency } from 'utils/formatters';

import ROUTES from 'routes/app';
import { getDaysFromNow } from 'utils/dates/dates';
import { getRecordTitle } from 'utils/records/get-record-title';
import { useTranslation } from 'react-i18next';
import { useGetBankAccountActions } from '../actions/use-get-bank-account-actions';
import getReconciledAtStatusColor from '../utils/reconcilation-status-color';

const type: RecordType = 'bank_account';

type BankAccountTitleBlockProps = {
  bankAccount: BankAccount;
};

export function BankAccountTitleBlock({
  bankAccount
}: BankAccountTitleBlockProps) {
  const { t } = useTranslation();

  const {
    is_default,
    is_trust_account,
    account,
    record_reference
  } = bankAccount;

  const actions = useGetBankAccountActions();

  const subTitles = useMemo(
    () => [
      {
        label: `${t('bank-accounts.bank-state-branch.label.singular')}: ${
          account?.branch_code
        }`
      },
      { label: `Account number: ${account?.account_number}` }
    ],
    [account]
  );

  const tags = useMemo(() => {
    const tags: TitleBlockProps['tags'] = [];

    if (is_default) {
      tags.push({
        type: 'neutral',
        label: 'Default bank account'
      });
    }

    if (is_trust_account) {
      tags.push({
        type: 'neutral',
        label: t('bank-accounts.trust-account.label.singular')
      });
    }

    return tags;
  }, [is_default, is_trust_account]);

  const title = getRecordTitle({ type, object: bankAccount } as RecordObject);

  const deposits = bankAccount?.trust_receipts_not_yet_banked;
  const withdrawals = bankAccount?.trust_withdrawals_not_yet_banked;

  const stats = useMemo<TitleBlockProps['stats']>(() => {
    const lastReconciledAt = bankAccount.last_reconciliation
      ?.reconciled_at as Date;
    return [
      {
        label: 'Last reconciled',
        value: lastReconciledAt ? getDaysFromNow(lastReconciledAt) : 'Never',
        intent: getReconciledAtStatusColor(lastReconciledAt)
      },
      {
        label: 'Outstanding bank deposits',
        value: formatCurrency(deposits?.amount ?? 0),
        valueTag: deposits?.count
          ? deposits.count === 1
            ? `1 deposit`
            : `${deposits.count} deposits`
          : undefined,
        intent: 'secondary',
        to: ROUTES.BANK_DEPOSITS_LIST,
        query: { bankAccount: bankAccount.id }
      },
      {
        label: 'Outstanding bank withdrawals',
        value: formatCurrency(withdrawals?.amount ?? 0),
        valueTag: withdrawals?.count
          ? withdrawals.count === 1
            ? `1 withdrawal`
            : `${withdrawals.count} withdrawals`
          : undefined,
        intent: 'secondary',
        to: ROUTES.BANK_WITHDRAWALS_LIST,
        query: { bankAccount: bankAccount.id }
      }
    ];
  }, [deposits, withdrawals]);

  return (
    <TitleBlock
      title={title}
      type={type}
      subTitles={subTitles}
      tags={tags}
      actions={actions(bankAccount)}
      Icon={BankingAccountIcon}
      stats={stats}
      reference={record_reference}
    />
  );
}
