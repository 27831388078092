export function slugToSentenceCase(slug: string): string {
  if (typeof slug !== 'string') {
    return slug;
  }
  return slug
    .replace(/^./, (str) => {
      return str.toUpperCase();
    })
    .replace(/[_-]/g, ' ');
}
