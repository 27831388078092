/**
 * HACK: this is just a temporary hack to get the submit all logic working,
 * we will likely pull this further into `@rexlabs/form` to abstract away
 * as much of it as possible
 */

import { get, merge, reduce } from 'lodash';
import flatten from 'flat';

export type RecordSubmitHandler<ValuesType = any> = (args: {
  values: ValuesType;
  changedValues: Partial<ValuesType>;
  changedBlockIds: Array<string>;
}) => Promise<any> | any;

interface SubmitAllFormsArgs {
  submitAll: (args?: {
    shouldReset?: boolean;
    shouldValidate?: boolean;
  }) => Promise<any>;
  validateAll: () => Promise<{ [key: string]: string | null }>;
  resetAll?: (values: any) => void;
  handleSubmit?: RecordSubmitHandler;
  onValidationError?: (args: {
    allErrors: { [key: string]: any };
    erroredForms: string[];
  }) => void;
  setSubmitting?: (state: boolean) => void;
}

export async function submitAllForms({
  submitAll,
  validateAll,
  resetAll,
  handleSubmit,
  onValidationError,
  setSubmitting
}: SubmitAllFormsArgs) {
  try {
    setSubmitting?.(true);
    const allErrors = await validateAll();

    // Find all forms that have errors
    const erroredForms = Object.keys(allErrors).filter((formId) => {
      const errors = allErrors[formId];
      return Object.keys(flatten(errors)).find((fieldName) =>
        get(errors, fieldName)
      );
    });

    if (erroredForms.length) {
      // Stop here if there are any validation errors
      onValidationError?.({ erroredForms, allErrors });
      setSubmitting?.(false);
      return false;
    }

    // If there are no validation errors, submit all forms but prevent
    // validations from running again
    const submissions = await submitAll({
      shouldValidate: false,
      shouldReset: false
    });
    if (!submissions) {
      setSubmitting?.(false);
      return false;
    }

    const changedValues = reduce(
      submissions,
      (all, form) => merge(all, form?.changedValues),
      {}
    );

    // TODO: check with Aaron how to get all values
    const values = merge(
      reduce(submissions, (all, form) => merge(all, form?.values), {}),
      changedValues
    );

    const changedBlockIds = reduce(
      submissions,
      (all, form) => {
        if (form?.id) return all.concat(form?.id);

        return all;
      },
      []
    );

    const response = await Promise.resolve(
      handleSubmit?.({
        values,
        changedValues,
        changedBlockIds
      })
    );

    const resetValues = reduce(
      submissions,
      (all, form, key) => merge(all, { [key]: form?.values }),
      {}
    );

    resetAll?.(resetValues);
    setSubmitting?.(false);

    return response;
  } catch (e) {
    setSubmitting?.(false);
    throw e;
  }
}
