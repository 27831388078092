import React from 'react';
import { AxiosResponse } from 'axios';

import { useModelActions } from '@rexlabs/model-generator';

import { AlfredFile } from 'src/modules/common/types/file';
import {
  ComplianceEntry,
  complianceEntriesModel
} from 'src/modules/compliance/common/models/compliance-entries';

import {
  RequestPipelineRequest,
  postRequestPipelineRequests
} from 'utils/api/post-request-pipeline';

import { Attachment, Message } from '../types/Message';
import { fetchMessageWithAttachments } from '../utils/fetch-message-with-attachments';
import { MessageIssue } from '../../common/types/message-issue';
import { AddComplianceEntryToARecordBlockValues } from '../blocks/add-compliance-entry-to-record-block';

export function useComplianceEntryMessageIssueSubmitHandler(
  issue: MessageIssue
) {
  const [
    currentComplianceEntry,
    setCurrentComplianceEntry
  ] = React.useState<ComplianceEntry | null>(null);

  const { refreshLists } = useModelActions(complianceEntriesModel);

  return async (
    updatedValues: AddComplianceEntryToARecordBlockValues,
    values: AddComplianceEntryToARecordBlockValues
  ) => {
    const updateType = getUpdateType({
      changedValues: updatedValues,
      hasExistingFileOfType: Boolean(currentComplianceEntry)
    });

    switch (updateType) {
      case 'add': {
        const complianceEntry = await handleAddComplianceEntry({
          values,
          updatedValues,
          issue
        });

        setCurrentComplianceEntry(complianceEntry);

        break;
      }

      case 'update': {
        const complianceEntry = await handleUpdateComplianceEntry({
          values,
          updatedValues,
          issue,
          currentComplianceEntry: currentComplianceEntry!
        });

        setCurrentComplianceEntry(complianceEntry);

        break;
      }
    }
    refreshLists();
  };
}

const updateTypes = ['add', 'update'] as const;
type UpdateType = typeof updateTypes[number];

/**
 * This function is used to return the type of update we're performing
 */
function getUpdateType({
  changedValues,
  hasExistingFileOfType
}: {
  changedValues: AddComplianceEntryToARecordBlockValues;
  hasExistingFileOfType: boolean;
}): UpdateType {
  if (hasExistingFileOfType && changedValues.file) {
    return 'update';
  }

  return 'add';
}

/**
 * This function is used to handle adding a compliance entry to a record and a message, and returns the compliance entry
 * @returns ComplianceEntry
 */
async function handleAddComplianceEntry({
  values,
  updatedValues,
  issue
}: {
  values: AddComplianceEntryToARecordBlockValues;
  updatedValues: AddComplianceEntryToARecordBlockValues;
  issue: MessageIssue;
}): Promise<ComplianceEntry> {
  const file = await resolveFilePromise(updatedValues);

  const messageWithAttachments = await fetchMessageWithAttachments({
    messageId: issue.message!.id
  });

  const createComplianceEntryRequest = getCreateComplianceEntryPipelineRequest({
    issue,
    values,
    uploadedFileId: file.id
  });

  const updateMessageRequest = getUpdateMessagePipelineRequest({
    messageId: issue.message!.id,
    updatedMessage: {
      inline_attachments: [
        ...(messageWithAttachments?.inline_attachments || []),
        {
          file: {
            id: file.id
          }
        } as Attachment
      ]
    }
  });

  const { data } = await postRequestPipelineRequests<
    [typeof createComplianceEntryRequest, typeof updateMessageRequest],
    [ComplianceEntry, Message]
  >([createComplianceEntryRequest, updateMessageRequest]);

  return data[0];
}

/**
 * This function is used to replace the current compliance entry on a record and a message, with a new one
 * @returns ComplianceEntry
 */
async function handleUpdateComplianceEntry({
  values,
  updatedValues,
  issue,
  currentComplianceEntry
}: {
  currentComplianceEntry: ComplianceEntry;
  issue: MessageIssue;
  values: AddComplianceEntryToARecordBlockValues;
  updatedValues: AddComplianceEntryToARecordBlockValues;
}): Promise<ComplianceEntry> {
  const file = await resolveFilePromise(updatedValues);

  const { inline_attachments = [] } = await fetchMessageWithAttachments({
    messageId: issue.message!.id
  });

  const filteredInlineAttachments = inline_attachments.filter(
    (attachment) => attachment.file.id !== currentComplianceEntry!.file.id
  );

  const deleteComplianceEntryRequest = getDeleteComplianceEntryPipelineRequest({
    complianceEntryId: currentComplianceEntry!.id
  });

  const createComplianceEntryRequest = getCreateComplianceEntryPipelineRequest({
    issue,
    values,
    uploadedFileId: file.id
  });

  const updateMessageRequest = getUpdateMessagePipelineRequest({
    messageId: issue.message!.id,
    updatedMessage: {
      inline_attachments: [
        ...filteredInlineAttachments,
        {
          file: {
            id: file.id
          }
        } as Attachment
      ]
    }
  });

  const { data } = await postRequestPipelineRequests<
    [
      typeof deleteComplianceEntryRequest,
      typeof createComplianceEntryRequest,
      typeof updateMessageRequest
    ],
    [null, ComplianceEntry, Message]
  >([
    deleteComplianceEntryRequest,
    createComplianceEntryRequest,
    updateMessageRequest
  ]);

  return data[1];
}

function getUpdateMessagePipelineRequest({
  messageId,
  updatedMessage
}: {
  messageId: string;
  updatedMessage: Partial<Message>;
}): RequestPipelineRequest {
  return {
    method: 'PATCH',
    path: `/api/v1/communication/messages/${messageId}`,
    json: updatedMessage
  };
}

function getCreateComplianceEntryPipelineRequest({
  values,
  uploadedFileId,
  issue
}: {
  values: AddComplianceEntryToARecordBlockValues;
  uploadedFileId: string;
  issue: MessageIssue;
}): RequestPipelineRequest {
  return {
    method: 'POST',
    path: '/api/v1/compliance-entries',
    json: {
      issued_at: values.issued_date,
      expires_at: values.expiry_date,
      object_type: issue.message!.context.__record_type,
      object_id: issue.message!.context.id,
      // BE requires this but it should be optional since it's not shown in the UI
      details: issue.missing_compliance_type?.label,
      compliance_type_id: issue.missing_compliance_type?.id,
      file: { id: uploadedFileId }
    }
  };
}

function getDeleteComplianceEntryPipelineRequest({
  complianceEntryId
}: {
  complianceEntryId: string;
}): RequestPipelineRequest {
  return {
    method: 'DELETE',
    path: `/api/v1/compliance-entries/${complianceEntryId}`
  };
}

async function resolveFilePromise(updatedValues) {
  const { data: file }: AxiosResponse<AlfredFile> = await updatedValues.file
    .data;

  return file;
}
