import React, { useCallback } from 'react';
import { useModelActions } from '@rexlabs/model-generator';

import { RecordDialog } from 'view/components/record-screen/dialog/dialog';
import { DialogContentConfig } from 'view/components/record-screen/dialog/types';
import { RecordSubmitHandler } from 'view/components/record-screen/utils';
import { filterNullValues } from 'utils/filter-null-values';
import {
  financialsSecurityDepositsModel,
  SecurityDeposit
} from 'data/models/entities/financials/security-deposits';

import { useToast } from 'view/components/@luna/notifications/toast';
import { securityDepositDetailsBlock } from '../blocks/security-deposit-details';

const content: DialogContentConfig = [
  {
    id: 'basics',
    label: 'Basics',
    blocks: [securityDepositDetailsBlock]
  }
];

interface EditSecurityDepositDetailsDialogProps {
  securityDeposit: SecurityDeposit;
  onClose?: () => void;
}

export function EditSecurityDepositDetailsDialog({
  securityDeposit,
  onClose
}: EditSecurityDepositDetailsDialogProps) {
  const { addToast } = useToast();
  const { updateItem } = useModelActions(financialsSecurityDepositsModel);
  const handleSubmit: RecordSubmitHandler = useCallback(
    async ({ values }) => {
      const response = await updateItem({
        id: securityDeposit.id,
        data: { ...filterNullValues(values) }
      });

      addToast({
        description: (
          <>
            {securityDeposit.security_deposit_type?.name} successfully updated
          </>
        )
      });

      return response.data;
    },
    [updateItem]
  );

  return (
    <RecordDialog
      onClose={onClose}
      data={securityDeposit}
      title='Edit security deposit'
      submitLabel='Update security deposit'
      handleSubmit={handleSubmit}
      content={content}
    />
  );
}
