import { NormalisedItem, Select, useItems } from '@rexlabs/select';
import * as React from 'react';
import { Author } from 'src/modules/authors/types/author-types';
import { api } from 'utils/api/api-client';

const normaliser = (item: Author): NormalisedItem => ({
  id: item?.id ?? '',
  label: item?.name ?? 'Message Creator'
});

export interface AuthorSelectProps {
  staticItems?: Author[];
}

export function AuthorSelect(props: AuthorSelectProps) {
  const { getSelectProps } = useItems({
    getItems: React.useCallback(
      async (term) => {
        const authors = await api.get('/authors/unique', {
          search: term,
          per_page: 30
        });

        return [...(props.staticItems ?? []), ...authors.data] as Author[];
      },
      [props.staticItems]
    ),
    getSuggestedItems: React.useCallback(async () => {
      return props.staticItems ?? [];
    }, [props.staticItems])
  });

  return <Select {...props} {...getSelectProps()} normaliser={normaliser} />;
}
