import { valueListFactory } from 'src/lib/testing/factories/value-list-factory';
import { DisbursementFrequencyBlockFormData } from 'src/modules/disbursements/blocks/disbursement-frequency-block';
import { DisbursementFrequencySettings } from '../../hooks/useSettings';

/**
 * Used to map the default disbursement settings to the disbursement frequency form
 */
export function mapDefaultDisbursementSettingsToDisbursementFrequencyForm({
  is_disbursed_on_balance_amount,
  is_disbursed_on_frequency,
  on_balance_amount,
  on_frequency_unit_id,
  on_frequency_count,
  preset_data,
  preset_type_id
}: DisbursementFrequencySettings): DisbursementFrequencyBlockFormData {
  const onFrequencyUnit =
    on_frequency_unit_id && valueListFactory(on_frequency_unit_id);
  return {
    disbursement_frequency: {
      on_frequency_next_disbursement_date: '',
      is_disbursed_on_balance_amount,
      disbursement_frequency_count_and_unit:
        on_frequency_unit_id || on_frequency_count
          ? {
              on_frequency_unit: onFrequencyUnit,
              on_frequency_count
            }
          : null,
      is_disbursed_on_frequency,
      on_balance_amount,
      preset: {
        type: preset_type_id
          ? valueListFactory(preset_type_id)
          : on_frequency_unit_id && valueListFactory(on_frequency_unit_id),
        data: preset_data as any
      }
    }
  };
}
