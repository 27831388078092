import { api } from 'utils/api/api-client';

import { InspectionTask } from '../types/InspectionTask';
import { InspectionReportManageRoomObj } from '../blocks/manage-areas-block';

/**@deprecated we are replacing rooms with areas */
export function getDeleteInspectionRoomRequests(
  inspectionTask: InspectionTask,
  updatedRooms: InspectionReportManageRoomObj[]
) {
  const existingRooms = inspectionTask.details?.rooms?.data || [];

  const deletedRooms = existingRooms.filter(
    (room) => !updatedRooms.find((r) => r.id === room.id)
  );

  return deletedRooms.map(
    async (room) => await api.delete(`/rooms/${room.id}`)
  );
}
