import { useDialog, useErrorDialog } from '@rexlabs/dialog';
import { useToast } from 'view/components/@luna/notifications/toast';
import { useQueryClient } from 'react-query';

import { pluralize } from 'utils/formatters';

import ReceiptIcon from 'view/components/icons/receipt';
import { useSettings } from 'src/modules/settings/hooks/useSettings';
import { AccessDeniedDialog } from 'view/components/dialogs/access-denied-dialog';
import { usePermission } from 'src/modules/roles/hooks/use-permission';
import { useBankAccountState } from '../use-bank-account-state';
import { bankStatementTransactionsQueryKey } from '../use-bank-statement-transactions-query';
import { useBatchReceiptingStatsSummary } from '../use-batch-receipting-stats-summary';
import { useCreateTrustJournalEntryAndLinkTransaction } from '../use-create-trust-journal-entry-and-link-transaction';

export function useGetBulkReceiptToRentAction() {
  const { bankAccount } = useBankAccountState();
  const { isStandardUser, roleName } = usePermission();

  const { addToast } = useToast();

  const { refetch: refetchStatsSummary } = useBatchReceiptingStatsSummary(
    bankAccount?.id
  );

  const { open: openErrorDialog } = useErrorDialog();
  const accessDeniedDialog = useDialog(AccessDeniedDialog);

  const queryClient = useQueryClient();

  const {
    batchCreateTrustJournalEntriesAndLinkTransactions
  } = useCreateTrustJournalEntryAndLinkTransaction();

  const settings = useSettings();
  const batchReceiptingMatchSetting =
    settings?.['batch-receipting-match-setting'];

  return (matchedReceiptCount: number) => {
    return {
      Icon: ReceiptIcon,
      intent: 'primary' as const,
      label: `Receipt all ${
        batchReceiptingMatchSetting?.id === 'all_invoices'
          ? 'matched'
          : 'to rent'
      } (${matchedReceiptCount})`,
      handleAction: async () => {
        if (isStandardUser) {
          return accessDeniedDialog.open({
            role: roleName
          });
        }

        try {
          await batchCreateTrustJournalEntriesAndLinkTransactions();

          addToast({
            description: `Processing ${pluralize(
              'receipt',
              matchedReceiptCount,
              false
            )} for ${pluralize('transaction', matchedReceiptCount, true)}`
          });

          // refetch to exclude locked transactions
          await queryClient.refetchQueries({
            queryKey: [bankStatementTransactionsQueryKey],
            refetchPage: () => true
          });

          return refetchStatsSummary();
        } catch (error) {
          return openErrorDialog(error);
        }
      }
    };
  };
}
