export const INSPECTION_TASK_ROUTES = {
  INSPECTION_TASK_LIST: {
    config: {
      path: '/inspection-tasks',
      Component: require('./screens/inspection-list.tsx').InspectionList
    }
  },
  INSPECTION_TASK_DETAILS: {
    config: {
      path: '/inspection-task/:inspectionId',
      Component: require('./screens/inspection-details.tsx').InspectionDetails
    }
  },
  INSPECTION_RUN_DETAILS: {
    config: {
      path: '/inspection-run/:inspectionRunId',
      Component: require('./screens/inspection-run-details.tsx')
        .InspectionRunDetails
    }
  }
};
