import { Select } from '@rexlabs/select';
import Box from '@rexlabs/box';
import * as React from 'react';

export function PreviewStatementsFooter({
  zoom,
  setZoom
}: {
  zoom: number;
  setZoom: (zoom: number) => void;
}) {
  return (
    <Box width={'100px'} mr={'auto'}>
      <Select
        normaliser={(item) => {
          return {
            id: String(item.id),
            label: item.label
          };
        }}
        value={{ id: zoom, label: `${zoom * 100}%` }}
        items={[
          { id: '0.5', label: '50%' },
          { id: '0.75', label: '75%' },
          { id: '1', label: '100%' },
          { id: '1.25', label: '125%' },
          { id: '1.5', label: '150%' },
          { id: '1.75', label: '175%' },
          { id: '2', label: '200%' }
        ]}
        helperText={''}
        onChange={({ target }) => setZoom(target.value.id)}
      />
    </Box>
  );
}
