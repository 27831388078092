import * as React from 'react';
import { map } from 'lodash';

import { useErrorDialog } from '@rexlabs/dialog';

import { usePutSettingsMutation } from 'src/modules/settings/hooks/usePutSettingsMutation';

import { TitleBlock } from 'view/components/@luna/title-block';
import { RecordScreen } from 'view/components/record-screen';
import { RecordSubmitHandler } from 'view/components/record-screen/utils';

import { content } from '../data/content';
import { useMapMessageTriggerSettingsToForm } from '../mappers/map-message-trigger-settings-to-form';
import { MessageTriggerData } from '../types/MessageTriggerData';

function getMappedValues(changedValues: Partial<MessageTriggerData>) {
  return map(changedValues, (value, key) => {
    return {
      key,
      value: {
        template_id: value?.id || null
      }
    };
  });
}

export function MessageTriggerSettings() {
  const data = useMapMessageTriggerSettingsToForm();

  const putSettings = usePutSettingsMutation();
  const errorDialog = useErrorDialog();

  const handleSubmit: RecordSubmitHandler = async ({ changedValues }) => {
    const mappedValues = getMappedValues(changedValues);

    try {
      await putSettings.mutateAsync(mappedValues);
    } catch (e) {
      errorDialog.open(e);
    }
  };

  return (
    <RecordScreen
      isLoading={!data}
      data={data}
      initialValues={data}
      handleSubmit={handleSubmit}
      content={content}
      titleBlock={<TitleBlock title='Message trigger settings'></TitleBlock>}
    />
  );
}
