import { getDocumentsBlock } from 'src/modules/documents/blocks/documents';
import { Tenancy } from 'data/models/entities/tenancies';

export const documentsBlock = getDocumentsBlock(
  ({ data }: { data: Tenancy }) => ({
    attachedTo: [
      {
        id: data.id,
        label: data.display_name,
        type: {
          id: 'tenancy'
        }
      }
    ],
    tenancyId: data.id
  })
);
