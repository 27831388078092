import React from 'react';
import { useTranslation } from 'react-i18next';
import { lowerCase } from 'lodash';

import { Bold, Small } from '@rexlabs/text';

import { RecordObject } from 'data/models/types';
import { PendingOwnershipDisbursement } from 'data/models/entities/financials/pending-disbursements/pending-ownership-disbursement';
import { PendingContactDisbursement } from 'data/models/entities/financials/pending-disbursements/pending-contact-disbursement';
import { PendingDisbursement } from 'data/models/entities/financials/pending-disbursements';

import { getRecordTitle } from 'utils/records/get-record-title';
import { formatDate } from 'utils/dates/format';

import { TitleBlock } from 'view/components/cards/title/title-block';
import PropertyIcon from 'view/components/icons/property';

import { useGetDisburseFundsAction } from '../utils/disbursement-actions/use-get-disburse-action';
import { ShortfallWarningBanner } from './shortfall-warning-banner';
import { DisbursementMethodMissingBanner } from './disbursement-method-missing-banner';

type PendingDisbursementTitleBlockProps = {
  pendingDisbursement:
    | PendingOwnershipDisbursement
    | PendingContactDisbursement;
};

export function PendingDisbursementTitleBlock({
  pendingDisbursement
}: PendingDisbursementTitleBlockProps) {
  const { t } = useTranslation();
  const getDisburseAction = useGetDisburseFundsAction();

  const type = pendingDisbursement.object_type;

  if (!type) {
    throw new Error('There is no contact or ownership');
  }

  const title = getRecordTitle({
    type: `pending_${type}_disbursement`,
    object: pendingDisbursement
  } as RecordObject);

  const dueDateSubTitle =
    pendingDisbursement?.due_date &&
    `Due ${formatDate(pendingDisbursement.due_date)}`;
  const lastDisbursementSubTitle =
    pendingDisbursement?.last_disbursement?.created_at &&
    ` (${lowerCase(
      t('disbursements.last-disbursement.label') as string
    )} ${formatDate(pendingDisbursement?.last_disbursement?.created_at)})`;

  return (
    <>
      <TitleBlock
        title={title}
        type={type}
        subTitles={[
          ...(dueDateSubTitle || lastDisbursementSubTitle
            ? [
                {
                  Label: () => (
                    <Small as='span'>
                      <Bold>{dueDateSubTitle}</Bold>
                      {lastDisbursementSubTitle ? (
                        <Small grey as='span'>
                          {lastDisbursementSubTitle}
                        </Small>
                      ) : null}
                    </Small>
                  )
                }
              ]
            : [])
        ]}
        actions={[
          {
            ...getDisburseAction({
              recordType: type,
              pendingDisbursement: pendingDisbursement,
              refreshBillsAndFees: true
            }),
            intent: 'primary'
          }
        ]}
        tags={[
          {
            label: t('disbursements.tags.due-for-disbursement.label'),
            type: 'average'
          }
        ]}
        Icon={PropertyIcon}
      />
      {hasShortfall(pendingDisbursement!) && <ShortfallWarningBanner />}
      {pendingDisbursement?.payment_methods?.length === 0 && (
        <DisbursementMethodMissingBanner
          disbursementType={pendingDisbursement.object_type}
        />
      )}
    </>
  );
}

function hasShortfall(pendingDisbursement: PendingDisbursement) {
  return (pendingDisbursement?.ownership_tax_transactions?.data || []).some(
    (taxTransaction) =>
      taxTransaction?.type.id === 'tax_owed_during_shortfall_in_disbursement'
  );
}
