import React from 'react';
import { CellProps } from '@rexlabs/table';
import { TwoTierCell } from 'view/components/cells/two-tier-cell';

export function ArrearsSummaryCell({ value }: CellProps) {
  return (
    <TwoTierCell
      text={value?.details?.property_tenancy?.tenancy?.display_name}
      subtext={value?.details?.property_tenancy?.property?.address_string}
    />
  );
}
