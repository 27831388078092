import { RecordObject } from 'data/models/types';

import { BulkCreateSmsMessageFormData } from '../types/BulkCreateSmsMessageFormData';
import { BulkCreateMessageRequest } from './types/bulk-create-message-request';

export function mapBulkCreateSmsFormToRequest(
  formData: BulkCreateSmsMessageFormData,
  recordObjects: RecordObject[]
): BulkCreateMessageRequest {
  return {
    data: {
      context: {
        type: {
          id: formData.context_type
        }
      },
      context_type: {
        id: formData.context_type
      },
      recipient_group: formData.recipient_group?.name,
      forced_channel_type: {
        id: 'sms'
      },
      ...(formData.sent_from?.id
        ? { send_from_author: { id: formData.sent_from!.id } }
        : {}),
      channels: [
        {
          channel_type: {
            id: 'sms'
          },
          text_body: formData.smsContent
        }
      ],
      ...(formData.template
        ? {
            template: { id: formData.template?.id }
          }
        : {})
    },
    ids: recordObjects.map(({ object }) => object.id)
  };
}
