import React from 'react';
import { EmptySection } from './base';

export function EmptyTenant() {
  return (
    <EmptySection
      headline='To be defined'
      buttonLabel='View tenants'
      linkProps={{ query: { tab: 'tenants' } }}
    >
      Choose from an existing tenant or create a new tenant for this record.
    </EmptySection>
  );
}
