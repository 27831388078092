import { useMemo } from 'react';
import { query } from '@rexlabs/model-generator';
import { documentsModel } from 'data/models/entities/documents';
import { Quri, toQuri } from '@rexlabs/quri';
import { DocumentListProps } from 'src/modules/documents/components/connectors/document-list';

const getDocumentListQuery = (filter: string) => ({
  ...query`{
    ${documentsModel} (q: ${filter}) {
        attached_to
        file
    }
}`,
  uuid: 'document-list'
});

function getFilter(
  params: Pick<
    DocumentListProps,
    'propertyId' | 'contactId' | 'tenancyId' | 'ownershipId' | 'taskId'
  >
) {
  const quries: Quri[] = [];

  if (params.propertyId) {
    quries.push({
      field: 'property_id',
      op: '==',
      value: params.propertyId
    });
  }

  if (params.contactId) {
    quries.push({
      field: 'contact_id',
      op: '==',
      value: params.contactId
    });
  }

  if (params.ownershipId) {
    quries.push({
      field: 'ownership_id',
      op: '==',
      value: params.ownershipId
    });
  }

  if (params.tenancyId) {
    quries.push({
      field: 'tenancy_id',
      op: '==',
      value: params.tenancyId
    });
  }
  if (params.taskId) {
    quries.push({
      field: 'task_id',
      op: '==',
      value: params.taskId
    });
  }

  return toQuri(quries);
}

type Props = {
  propertyId?: string;
  contactId?: string;
  ownershipId?: string;
  tenancyId?: string;
  taskId?: string;
};

export const useDocumentListQuery = ({
  propertyId,
  contactId,
  ownershipId,
  tenancyId,
  taskId
}: Props) => {
  return useMemo(
    () =>
      getDocumentListQuery(
        getFilter({ propertyId, contactId, ownershipId, tenancyId, taskId })
      ),
    [contactId, ownershipId, propertyId, tenancyId, taskId]
  );
};
