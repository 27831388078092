import React from 'react';

import { ListSummaryCard } from 'view/components/cards/list-summary-card';

type TaxIncomeSummaryProps = {
  taxableIncome: number;
  deductibleExpenses: number;
};

export const TaxIncomeSummary = ({
  taxableIncome,
  deductibleExpenses
}: TaxIncomeSummaryProps) => (
  <ListSummaryCard
    title='income'
    cardColor='neutral'
    data-testid='tax-income-summary'
    items={[
      {
        label: 'Taxable income',
        amount: taxableIncome,
        type: 'inbound',
        testId: 'taxable-income'
      },
      {
        label: 'Deductible expenses',
        amount: deductibleExpenses,
        type: 'outbound',
        testId: 'deductible-expenses'
      },
      {
        label: 'Net taxable income',
        amount: taxableIncome - deductibleExpenses,
        type: 'total',
        testId: 'net-taxable-income'
      }
    ]}
  />
);
