import { useDialog } from '@rexlabs/dialog';

import { SingleActionDeclaration } from 'src/modules/common/actions/types/action-declaration-types';

import { EditNoteRecordDialog } from '../../dialogs/edit-note-record-dialog';
import { Note } from '../../types/Note';

export function useGetEditNoteAction() {
  const { open: openEditDialog } = useDialog(EditNoteRecordDialog);

  return (note: Note, hideAddNoteToField = false): SingleActionDeclaration => ({
    id: 'edit-note',
    label: 'Edit note',
    handleAction: () => {
      openEditDialog({ note, hideAddNoteToField });
    }
  });
}
