import React, { useCallback, useMemo } from 'react';
import { get } from 'lodash';

import { toQuri } from '@rexlabs/quri';
import { DialogProps } from '@rexlabs/dialog';
import {
  query,
  useEntityListQuery,
  useEntityQuery
} from '@rexlabs/model-generator';

import { settingsSupplierCategoryRequirementsModel } from 'data/models/entities/settings/supplier-category-requirements';

import { useToast } from 'view/components/@luna/notifications/toast';
import { RecordDialog } from 'view/components/record-screen/dialog/dialog';
import { settingsComplianceTypesModel } from 'src/modules/compliance/common/models/compliance-types';

import { supplierComplianceRequirementDetailsBlock } from '../blocks/supplier-compliance-requirement-details-block';
import { useUpdateComplianceRequirement } from '../hooks/use-update-compliance-requirement';

const content = [
  {
    id: 'details',
    label: 'Details',
    blocks: [supplierComplianceRequirementDetailsBlock]
  }
];

export interface EditSupplierComplianceRequirementDialogProps
  extends DialogProps {
  complianceRequirementId: string;
}

const queryFilter = (id: string) =>
  toQuri([
    {
      field: 'compliance_type_id',
      op: '==',
      value: id
    }
  ]);

// Note that the compliance type query can't request the requirement (the category/type pivot)
// as an include, so we're requesting requirements separately
function getCategoryRequirementsQuery(id: string) {
  return query`{
      ${settingsSupplierCategoryRequirementsModel} (q: ${queryFilter(id)}) {
        id
        category
        label
        description
        compliance_type
      }
    }`;
}

function getComplianceTypeQuery(id) {
  return query`{
    ${settingsComplianceTypesModel} (id: ${id}) {
      id
      label
      description
      category
      applicable_to_object_type
      supplier_compliance_categories
    }
  }`;
}

export function EditSupplierComplianceRequirementDialog({
  complianceRequirementId,
  onClose
}: EditSupplierComplianceRequirementDialogProps) {
  const { addToast } = useToast();

  const categoryRequirementsQuery = useMemo(
    () => getCategoryRequirementsQuery(complianceRequirementId),
    [complianceRequirementId]
  );

  const { data: complianceCategoryData, status, actions } = useEntityQuery(
    getComplianceTypeQuery(complianceRequirementId)
  );

  const {
    data: categoriesContainingRequirement,
    status: categoryRequirementsLoadingStatus
  } = useEntityListQuery(categoryRequirementsQuery);

  const { updateComplianceRequirement } = useUpdateComplianceRequirement();

  const handleSubmit = useCallback(
    async ({ values }) => {
      const { data } = await updateComplianceRequirement({
        values,
        categoriesContainingRequirement,
        existingCategories:
          complianceCategoryData?.supplier_compliance_categories?.data
      });

      const updatedRequirement = data.find((item) => {
        if ('label' in item) {
          return item;
        }
      });

      const label = get(updatedRequirement, 'label', '');

      addToast({
        description: (
          <>
            Compliance requirement updated - <b>{label}</b>
          </>
        )
      });

      await actions.refreshLists();

      return data;
    },
    [
      actions,
      addToast,
      categoriesContainingRequirement,
      complianceCategoryData?.supplier_compliance_categories?.data,
      updateComplianceRequirement
    ]
  );

  const initialData = {
    ...complianceCategoryData,
    include_in_categories:
      complianceCategoryData?.supplier_compliance_categories?.data
  };

  return (
    <RecordDialog
      title='Edit compliance requirement'
      data={initialData}
      isLoading={
        status !== 'loaded' || categoryRequirementsLoadingStatus !== 'loaded'
      }
      handleSubmit={handleSubmit}
      submitLabel='Save'
      onClose={onClose}
      content={content}
      size='s'
    />
  );
}
