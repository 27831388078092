import { formatCurrency } from 'utils/formatters';
import { ValueListValue } from 'data/models/types';

type GenericDynamicAmount = {
  amount: number;
  type: ValueListValue<string>;
};

export function formatDynamicAmount<Amount extends GenericDynamicAmount>(
  amount: Amount
) {
  if (amount.type.id === 'percent') {
    return `${amount.amount}%`;
  }

  if (amount.type.id === 'weeks_of_rent') {
    return `${amount.amount} week(s) of rent`;
  }
  return formatCurrency(amount.amount);
}
