import { api } from 'utils/api/api-client';
import { MessageTemplate } from '../types/MessageTemplate';

/**
 * Fetches a message template from the API. Using this as alternative to model generator fetchItem
 * as we need to use includes, and the model generator does not support this.
 */
export async function fetchTemplate(
  id?: string | null
): Promise<MessageTemplate | null> {
  if (!id) return null;

  const { data } = await api.get<MessageTemplate>(
    `/communication/message-templates/${id}?include=channels,send_from_author,inline_attachments`
  );

  return data;
}
