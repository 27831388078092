import { useTranslation } from 'react-i18next';
import { formatCurrency } from 'utils/formatters';

export function useTaskWithheldFundsInputConfig(
  parentTaskWithheldFunds = 0,
  spendLimit = 0,
  hasOtherWorkOrdersLinkedToParentTask = false
): {
  inputLabel: string;
  description: string;
  newWithheldFundsAmount?: number;
} {
  const { t } = useTranslation();

  if (parentTaskWithheldFunds > 0 && hasOtherWorkOrdersLinkedToParentTask) {
    return {
      inputLabel: t(
        'withheld-funds.work-order-details-block.task-withheld-funds-field.increase.input-label',
        { spendLimit: formatCurrency(spendLimit) }
      ),
      description: t(
        'withheld-funds.work-order-details-block.task-withheld-funds-field.increase.description',
        { parentTaskWithheldFunds: formatCurrency(parentTaskWithheldFunds) }
      ),
      newWithheldFundsAmount: spendLimit + parentTaskWithheldFunds
    };
  }

  if (parentTaskWithheldFunds > 0 && parentTaskWithheldFunds !== spendLimit) {
    return {
      inputLabel: t(
        'withheld-funds.work-order-details-block.task-withheld-funds-field.update.input-label',
        {
          spendLimit: formatCurrency(spendLimit),
          parentTaskWithheldFunds: formatCurrency(parentTaskWithheldFunds)
        }
      ),
      description: t(
        'withheld-funds.work-order-details-block.task-withheld-funds-field.update.description'
      ),
      newWithheldFundsAmount: spendLimit
    };
  }

  return {
    inputLabel: t(
      'withheld-funds.work-order-details-block.task-withheld-funds-field.set.input-label',
      { spendLimit: formatCurrency(spendLimit) }
    ),
    description: ' ',
    newWithheldFundsAmount: spendLimit
  };
}
