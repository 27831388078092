import { GhostButton } from '@rexlabs/button';
import React from 'react';
import AddIcon from 'view/components/icons/add';

export type AddSubtaskButtonProps = {
  label?: string;
  onClick?: () => void;
};

export const AddSubtaskButton: React.FC<AddSubtaskButtonProps> = ({
  label = 'Add subtask',
  onClick
}) => {
  return (
    <GhostButton IconLeft={AddIcon} onClick={onClick}>
      {label}
    </GhostButton>
  );
};
