import { ParentRecordDataType } from '../types/ParentRecordDataType';

export function getNoteQueryFilterId(data: ParentRecordDataType) {
  switch (data.__record_type) {
    case 'trust_journal_entry':
      return data.bank_statement_transaction_id;
    default:
      return data.id;
  }
}
