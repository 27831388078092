import React, { useEffect } from 'react';
import { get } from 'lodash';
import { Field, HiddenField } from '@rexlabs/form';

import { Invoice } from 'data/models/entities/financials/invoices';

import { InvoiceSelect } from 'view/components/inputs/selects/invoice-select';
import { CentAmountInput } from 'view/components/inputs/cent-amount';
import { CurrencyValue } from 'view/components/values/currency';
import { DateValue } from 'view/components/values/date';

import { Column, Grid } from 'view/components/@luna/form/grid';
import { Bold } from '@rexlabs/text';
import { formatDate } from 'utils/dates/format';
import InfoCircleIcon from 'view/components/icons/info';
import dayjs from 'dayjs';
import { getTodayDateStringForActiveTz } from 'utils/dates/dates';
import { TenancyPrepaymentBucket } from 'src/modules/prepayments/types/tenancy-prepayment-bucket';
import { InfoBanner } from '@rexlabs/notifications';
import { CrossBucketReasonBannerField } from 'src/modules/financials/components/cross-bucket-reason-banner-field';
import { useFeatureFlags } from 'view/components/@luna/feature-flags';
import { FLAGS } from 'utils/feature-flags';
import { Payable } from 'utils/api/get-payables';
import { AllocateFundsToggleButton } from '../components/allocate-funds-toggle-button';
import { ReceiptFundsLineItemContainer } from '../components/receipt-funds-line-item-container';
import { BaseLineItemWithAllocateProps } from '../blocks/trust-journal-entry-line-items';

function useNeedsReasonForCrossBucketPayment(payable?: Payable) {
  const { hasFeature } = useFeatureFlags();
  // This will only be false if we are passing a bucket to the payables endpoint (and the other logic is correct).
  return (
    payable?.can_be_paid_from_bucket === false && hasFeature(FLAGS.PREPAYMENTS)
  );
}

interface BillLineItemProps extends BaseLineItemWithAllocateProps {
  onInvoiceSelect: (selectState: any, fieldName: string) => void;
  onRemove: () => void;
  getSuggestedInvoices: () => Invoice[];
  prepaymentBucket?: TenancyPrepaymentBucket | null;
  setFieldValue?: (field: string, value: any) => void;
  originalPayables?: Payable[];
}

export function BillLineItem({
  onRemove,
  fieldName,
  getSuggestedInvoices,
  values,
  allocateToLineItem,
  unAllocateFromLineItem,
  showAllocateFunds: canShowAllocateFunds,
  showUnallocateFunds: canShowUnallocateFunds,
  onInvoiceSelect,
  prepaymentBucket,
  setFieldValue,
  originalPayables = []
}: BillLineItemProps) {
  const showAllocateFunds = canShowAllocateFunds({
    invoiceAmount: get(
      values,
      `${fieldName}.invoice_payment.invoice.amount_owing`
    ),
    allocatedToInvoiceAmount: get(values, `${fieldName}.amount`)
  });

  // A larger issue with the line items form is that it doesn't always update the line items
  // when the payables change, so changes of can_be_paid_from_bucket don't correctly propagate
  // when the bucket changes. This is a workaround for this issue
  const originalPayable = originalPayables.find(
    (payable) =>
      payable.invoice.id ===
      get(values, `${fieldName}.invoice_payment.invoice.id`)
  );

  const needsReasonForCrossBucketPayment = useNeedsReasonForCrossBucketPayment(
    originalPayable
  );

  const showUnallocateFunds = canShowUnallocateFunds({
    allocatedToInvoiceAmount: get(values, `${fieldName}.amount`)
  });

  const doNotPayBeforeDate = get(
    values,
    `${fieldName}.invoice_payment.invoice.do_not_pay_before_date`,
    null
  );

  // set so we can use this to validate whether we have a reason field
  useEffect(
    () =>
      setFieldValue?.(
        `${fieldName}.cross_bucket_payment_reason.needs_reason`,
        needsReasonForCrossBucketPayment
      ),
    [setFieldValue, needsReasonForCrossBucketPayment]
  );

  return (
    <ReceiptFundsLineItemContainer heading='Bill' onRemove={onRemove}>
      <div style={{ display: 'none' }}>
        <Field
          name={`${fieldName}.category`}
          Input={HiddenField}
          optional={false}
        />
      </div>

      <Grid columns={2}>
        <Grid columns={3}>
          <Column width={2}>
            <Field
              name={`${fieldName}.invoice_payment.invoice`}
              label='Invoice'
              Input={InvoiceSelect}
              optional={false}
              inputProps={{
                getSuggestedItems: getSuggestedInvoices
              }}
              onChange={(e) => onInvoiceSelect(e, fieldName)}
            />
          </Column>

          <DateValue
            label='Due date'
            value={get(values, `${fieldName}.invoice_payment.invoice.due_date`)}
          />
        </Grid>

        <Grid columns={2}>
          <CurrencyValue
            label='Amount due'
            value={get(
              values,
              `${fieldName}.invoice_payment.invoice.amount_owing`
            )}
            right={
              <div style={{ marginTop: '-.6rem' }}>
                {showAllocateFunds && (
                  <AllocateFundsToggleButton
                    intent='allocate'
                    onClick={() =>
                      allocateToLineItem({
                        lineItemName: fieldName,
                        invoiceAmountOwing: get(
                          values,
                          `${fieldName}.invoice_payment.invoice.amount_owing`
                        )
                      })
                    }
                  />
                )}
                {showUnallocateFunds && (
                  <AllocateFundsToggleButton
                    intent='unallocate'
                    onClick={() => unAllocateFromLineItem(fieldName)}
                  />
                )}
              </div>
            }
          />

          <Field
            name={`${fieldName}.amount`}
            label='Amount allocated'
            Input={CentAmountInput}
            optional={false}
          />
        </Grid>
        <Column width={2}>
          {needsReasonForCrossBucketPayment && (
            <Field
              name={`${fieldName}.cross_bucket_payment_reason`}
              optional={false}
              Input={CrossBucketReasonBannerField}
              inputProps={{
                fieldPrefix: `${fieldName}.cross_bucket_payment_reason`,
                prepaymentBucket
              }}
            />
          )}
        </Column>
      </Grid>

      {doNotPayBeforeDate && isDateInFuture(doNotPayBeforeDate) && (
        <InfoBanner
          Icon={InfoCircleIcon}
          description={
            <>
              This bill has been set to not be paid before{' '}
              <Bold>{formatDate(doNotPayBeforeDate)}</Bold>.
            </>
          }
        />
      )}
    </ReceiptFundsLineItemContainer>
  );
}

// we only want to show the banner if the date is yet to happen. if it is today or in the past, we want to hide. make a dayjs function to check if the date is upcoming
const isDateInFuture = (dateToCheck: string): boolean => {
  return dayjs(dateToCheck).isAfter(dayjs(getTodayDateStringForActiveTz()));
};
