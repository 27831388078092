import { getInvoicesPayableToBlock } from 'src/modules/invoices/blocks/invoices-payable-to';
import { ownershipsModel } from 'data/models/entities/ownerships';
import { useModelActions } from '@rexlabs/model-generator';
import { useMemo } from 'react';
import { useInvoiceFilters } from 'src/modules/invoices/utils/use-invoice-filters';
import { getAllIncludesStringFromModel } from 'utils/api/get-all-includes-string-from-model';

export const useReceivableInvoicesBlock = () => {
  const { refreshItem } = useModelActions(ownershipsModel);

  const {
    filters: invoiceFilters,
    actions: invoiceActions
  } = useInvoiceFilters({
    hiddenInvoiceFilterIds: [
      'agency_fees',
      'creditor',
      'bond_requests',
      'tenant_bills',
      'owner_bills'
    ]
  });

  return useMemo(
    () =>
      getInvoicesPayableToBlock(({ data }) => ({
        hashParamKey: 'payable_to',
        getForcedGlobalFilter: (ownershipId) => [
          {
            field: 'payable_to_object_id',
            op: '==',
            value: ownershipId
          },
          {
            field: 'payable_to_object_type',
            op: '==',
            value: 'ownership'
          }
        ],
        initialValues: {
          payable_to: {
            object: {
              id: data.id,
              type: { id: 'ownership', label: 'Ownership' },
              label: data.display_name,
              record: data
            }
          }
        },
        invoiceFilters,
        invoiceActions,
        refreshRecord: async () =>
          await refreshItem({
            id: data.id,
            args: {
              include: getAllIncludesStringFromModel(ownershipsModel)
            }
          })
      })),
    [invoiceFilters]
  );
};
