import React from 'react';
import { Field } from '@rexlabs/form';
import { BlockConfig } from 'view/components/record-screen/types';
import { Grid } from 'view/components/@luna/form/grid';
import { RadioGroupInput } from '@rexlabs/radio-input';
import { WorkOrderTask } from '../types/WorkOrderTask';

export const workOrderTypeBlock: BlockConfig<WorkOrderTask> = {
  id: 'work-order-type',
  title: 'Work done by',
  validate: {
    definitions: {
      'details.work_done_by_type': {
        name: 'work done by',
        rules: 'required'
      }
    }
  },
  Edit: () => {
    return (
      <Grid columns={1}>
        <Field
          name='details.work_done_by_type'
          Input={RadioGroupInput}
          label='Work done by'
          inputProps={{
            options: [
              { label: 'Supplier', value: 'supplier' },
              { label: 'Owner', value: 'owner' }
            ],
            orientation: 'horizontal'
          }}
        />
      </Grid>
    );
  }
};
