import { useConfirmationDialog } from '@rexlabs/dialog';
import { ActionDeclaration } from 'src/modules/common/actions/types/action-declaration-types';
import { useModelActions } from '@rexlabs/model-generator';
import {
  PropertyOwnershipFee,
  propertyOwnershipsFeesModel
} from 'data/models/entities/financials/property-ownerships/property-ownership-fee';
import { useToast } from 'view/components/@luna/notifications/toast';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useFeatureFlags } from 'view/components/@luna/feature-flags';
import { FLAGS } from 'utils/feature-flags';
import { propertiesModel } from 'data/models/entities/properties';

export function useGetRemovePropertyOwnershipFeeAction() {
  const { open } = useConfirmationDialog();
  const { trashItem } = useModelActions(propertyOwnershipsFeesModel);
  const { refreshItem } = useModelActions(propertiesModel);
  const { addToast } = useToast();
  const { t } = useTranslation();
  const { hasFeature } = useFeatureFlags();

  return (
    propertyOwnershipFee: PropertyOwnershipFee,
    propertyOwnershipId: string,
    propertyId?: string
  ): ActionDeclaration => ({
    id: 'remove-property-ownership-fee',
    group: 'property-ownership-fee',
    label: 'Remove fee',
    handleAction: () => {
      open({
        title: 'Remove fee',
        message: t(
          hasFeature(FLAGS.SERVICE_PACKAGES)
            ? 'property-ownerships.management-agreement-fees.remove.confirmation-message-service-package.singular'
            : 'property-ownerships.management-agreement-fees.remove.confirmation-message.singular'
        ),
        confirmText: 'Remove fee',
        onConfirm: async () => {
          await trashItem({
            id: propertyOwnershipFee.id,
            args: {
              propertyOwnershipId
            }
          });

          if (propertyId) {
            await refreshItem({ id: propertyId });
          }

          addToast({
            type: 'warning',
            description: (
              <Trans
                i18nKey='property-ownerships.management-agreement-fees.remove.fee-removed'
                values={{
                  agencyFeeName: propertyOwnershipFee.agency_fee?.name
                }}
              >
                <b>{propertyOwnershipFee.agency_fee?.name} fee</b> have been{' '}
                <b>removed</b> from this management agreement
              </Trans>
            )
          });
        }
      });
    }
  });
}
