import { makeIconComponent } from '@rexlabs/icons';

import SmallLeaseAgreementSvg from 'assets/icons/s/lease-agreement.svg';
import MediumLeaseAgreementSvg from 'assets/icons/m/lease-agreement.svg';
import ExtraLargeLeaseAgreementSvg from 'assets/icons/xl/lease-agreement.svg';

export const LeaseAgreementIcon = makeIconComponent({
  s: SmallLeaseAgreementSvg,
  m: MediumLeaseAgreementSvg,
  xl: ExtraLargeLeaseAgreementSvg
});

export default LeaseAgreementIcon;
