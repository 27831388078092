import { NormalisedItem } from '@rexlabs/select';
import { SearchResultItem } from 'utils/api/get-search-results';

export const normaliseSearchResultItems = (
  item: SearchResultItem
): NormalisedItem => ({
  id: item.id,
  label: item.label,
  type: item.type.id,
  heading: item.type.label
});
