import React from 'react';
import { NumberInput, NumberInputProps } from '@rexlabs/text-input';
import { StylesProvider } from '@rexlabs/styling';

const overrides = {
  textInput: {
    textAlign: 'right'
  }
};

const Suffix = () => <span>%</span>;

export function SimplePercentageInput({ ...props }: NumberInputProps) {
  return (
    <StylesProvider tokens={overrides}>
      <NumberInput Suffix={Suffix} {...props} />
    </StylesProvider>
  );
}
