import React from 'react';
import { Disclosure } from '@headlessui/react';

import Box from '@rexlabs/box';
import { StyleSheet, useStyles } from '@rexlabs/styling';

import { formatCurrency } from 'utils/formatters';

import ChevronDownIcon from 'view/components/icons/chevron-down';
import ChevronRightIcon from 'view/components/icons/chevron-right';

import { Cell } from './components/cell';
import { HeaderCell } from './components/header-cell';
import { Row } from './components/row';
import { Table } from './components/table';

const styles = StyleSheet({
  disclosureButton: {
    ':hover': {
      cursor: 'pointer'
    }
  }
});

export type NestedSummaryRow = {
  id: string;
  description: string;
  amount: number;
};

export type SummaryRow = NestedSummaryRow & {
  nested_data?: NestedSummaryRow[];
};

export function SummaryTable({
  header,
  data
}: {
  header: React.ReactNode;
  data: SummaryRow[];
}) {
  return (
    <Table>
      <colgroup>
        <col style={{ width: '32px' }} />
        <col />
        <col />
      </colgroup>
      <thead>
        <Row>
          <HeaderCell />
          <HeaderCell>
            <b>{header}</b>
          </HeaderCell>
          <HeaderCell />
        </Row>
      </thead>
      <tbody>
        {data.map((row) => {
          if ((row.nested_data || []).length > 0) {
            return (
              <TableRowWithNestedTable
                key={row.amount + row.description}
                data={row}
              />
            );
          }

          return <TableRow key={row.amount + row.description} data={row} />;
        })}
      </tbody>
    </Table>
  );
}

function NestedTableRow({
  showBorder,
  data
}: {
  key: string;
  showBorder: boolean;
  data: NestedSummaryRow;
}) {
  return (
    <Row>
      <Cell withBottomBorder={showBorder}>{data.description}</Cell>
      <Cell withBottomBorder={showBorder} withTextAlignRight>
        {formatCurrency(data.amount)}
      </Cell>
    </Row>
  );
}

function NestedTable({ data = [] }: { data?: NestedSummaryRow[] }) {
  return (
    <Table>
      <tbody>
        {data.map((row, index) => (
          <NestedTableRow
            key={row.amount + row.description}
            showBorder={index + 1 !== data?.length}
            data={row}
          />
        ))}
      </tbody>
    </Table>
  );
}

function TableRow({ data }: { data: NestedSummaryRow }) {
  return (
    <Row>
      <Cell />
      <Cell>{data.description}</Cell>
      <Cell withTextAlignRight>{formatCurrency(data.amount)}</Cell>
    </Row>
  );
}

function TableRowWithNestedTable({ data }: { data: SummaryRow }) {
  const s = useStyles(styles);
  return (
    <Disclosure>
      {({ open }) => {
        return (
          <>
            <Disclosure.Button as={Row} {...s('disclosureButton')}>
              <Cell withBottomBorder={!open}>
                <Box>{open ? <ChevronDownIcon /> : <ChevronRightIcon />}</Box>
              </Cell>
              <Cell>{data.description}</Cell>
              <Cell withTextAlignRight>{formatCurrency(data.amount)}</Cell>
            </Disclosure.Button>

            <Disclosure.Panel as='tr'>
              <Cell />
              <Cell colSpan={2} withCellStyles={false} withPaddingLeft>
                <NestedTable data={data.nested_data} />
              </Cell>
            </Disclosure.Panel>
          </>
        );
      }}
    </Disclosure>
  );
}
