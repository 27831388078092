import dayjs from 'dayjs';

export function isDateWithinRange(
  range: { startDate: string; endDate: string },
  date: string = dayjs().format('YYYY-MM-DD')
) {
  return (
    dayjs(date).isAfter(range.startDate) && dayjs(date).isBefore(range.endDate)
  );
}
