import { EntityModel } from '@rexlabs/model-generator';
import { RecordType } from 'data/models/types';

/**
 * Returns the RecordType from the EntityModel (modelName is the endpoint (in pascalCase) and NOT the RecordType)
 * @param model
 */
export function getRecordTypeFromModel(model: EntityModel<any>): RecordType {
  switch (model.modelName) {
    case 'communicationMessageTemplates':
      return 'message_template';
    case 'communicationMessages':
      return 'message';
    case 'communicationChannelMessageRecipients':
      return 'channel_message_recipient';
    case 'notes':
      return 'note';
    case 'contacts':
      return 'contact';
    case 'properties':
      return 'property';
    case 'tasks':
      return 'task';
    case 'propertyOwnerships':
      return 'property_ownership';

    case 'propertyTenancies':
      return 'property_tenancy';

    case 'financialsBankWithdrawals':
      return 'bank_withdrawal';

    case 'financialsBankDeposits':
      return 'bank_deposit';

    case 'financialsReconciliations':
      return 'reconciliation';

    case 'financialsInvoices':
      return 'invoice';

    case 'financialsSecurityDeposits':
      return 'security_deposit';

    case 'financialsUploadedBills':
      return 'uploaded_bill';

    case 'documents':
      return 'document';

    case 'ownerships':
      return 'ownership';

    case 'financialsBankAccounts':
      return 'bank_account';

    case 'tenancies':
      return 'tenancy';

    case 'financialsCreditNotes':
      return 'credit_note';

    case 'financialsChartOfAccounts':
      return 'chart_of_accounts_account';

    case 'statements':
      return 'statement';

    case 'financialsAgencyFees':
      return 'agency_fee';

    case 'financialsTrustJournalEntries':
      return 'trust_journal_entry';

    case 'financialsDisbursements':
      return 'disbursement';

    case 'financialsBankStatementTransactions':
      return 'bank_statement_transaction';

    case 'tasksChecklistTemplates':
      return 'checklist_template';

    case 'settingsPropertyComplianceCategories':
      return 'property_compliance_category';

    case 'settingsComplianceTypes':
      return 'property_compliance_type';

    case 'financialsAccountingJournalEntries':
      return 'accounting_journal_entry';

    case 'keySets':
      return 'key_set';

    case 'appliances':
      return 'appliance';

    case 'users':
      return 'user';

    case 'portfolios':
      return 'portfolio';

    case 'propertiesPropertyAreaTypes':
      return 'property_area_type';

    case 'propertiesPropertyAreas':
      return 'property_area';

    case 'servicePackages':
      return 'service_package';

    case 'financialsPrepaymentBuckets':
      return 'prepayment_bucket';

    default:
      throw new Error(`There is no record type for ${model.modelName}`);
  }
}
