import React from 'react';

import { ActionButtons } from 'view/components/@luna/action-buttons';
import { BlockConfig } from 'view/components/record-screen/types';

import { KeySetTabbedTable } from 'src/modules/key-sets/components/key-set-tabbed-table';
import { useAddKeySetAction } from 'src/modules/key-sets/hooks/use-get-add-key-set-action';

import { Property } from '../types/property-types';

export const keySetBlock: BlockConfig<Property> = {
  id: 'keys',
  title: 'Keys',
  View: ({ data }) => <KeySetTabbedTable property={data!} />,
  Actions: ({ data }) => {
    const { handleAction } = useAddKeySetAction(data!);

    return (
      <ActionButtons
        actions={[
          {
            label: 'Add key set',
            handleAction
          }
        ]}
      />
    );
  }
};
