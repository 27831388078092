import * as React from 'react';

import { query } from '@rexlabs/model-generator';

import { TabbedTable, ListTable } from 'view/components/table';
import { Tab } from 'view/components/table/tabbed';
import { useTableFilters } from 'view/hooks/use-table-filters';

import { INSPECTION_TASK_ROUTES } from '../routes';
import { inspectionRunsModel } from '../models/inspection-run-model';
import { inspectionRunsColumns } from '../hooks/use-inspection-runs-columns';
import { getInspectionRunsTabbedTableTabs } from '../utils/get-inspection-runs-tabbed-table-tabs';
import { useGetInspectionRunActions } from '../hooks/action-menu-items/use-get-inspection-run-actions';

const getInspectionRunsQuery = () => query`{
  ${inspectionRunsModel} {
    inspection_tasks {
      property
      details
    }
  }
}`;

export function InspectionRunsTable() {
  const { getSort, getFilters } = useTableFilters('inspection-runs');

  const getInspectionActions = useGetInspectionRunActions();

  const getRowLinkProps = ({ item }) => {
    return {
      to: INSPECTION_TASK_ROUTES.INSPECTION_RUN_DETAILS,
      params: { inspectionRunId: item.id }
    };
  };

  const getActionMenuItems = React.useCallback(
    ({ item }) => getInspectionActions(item),
    [getInspectionActions]
  );

  const commonTabProps = React.useMemo(
    () => ({
      id: 'inspection-runs',
      columns: inspectionRunsColumns,
      getQuery: getInspectionRunsQuery,
      Table: ListTable,
      getRowLinkProps,
      getActionMenuItems,
      getFilters,
      getSort
    }),
    [getFilters]
  );

  const tabs = React.useMemo<Tab[]>(
    () => getInspectionRunsTabbedTableTabs(commonTabProps),
    [commonTabProps]
  );

  return <TabbedTable tabs={tabs} />;
}
