import Box from '@rexlabs/box';
import { Body, Bold } from '@rexlabs/text';
import { PendingDisbursement } from 'data/models/entities/financials/pending-disbursements';
import { RecordType } from 'data/models/types';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { formatCurrency } from 'utils/formatters';
import { BlockConfig } from 'view/components/record-screen/types';

export interface IndividualWithBillsBlockData {
  recordType: RecordType;
  pendingDisbursement: PendingDisbursement;
}

export const disbursementIndividualBillsDialogBlock: BlockConfig<IndividualWithBillsBlockData> = {
  id: 'contact-disbursement',
  Edit: ({ data }) => {
    const { t } = useTranslation();
    // need a default because it could be falsey
    const amount =
      (data?.pendingDisbursement.financial_summary
        ?.bills_and_fees_payable_total_amount_owing ?? 0) -
      (data?.pendingDisbursement.financial_summary
        ?.bills_and_fees_not_yet_payable_total_amount_owing ?? 0);

    return (
      <Box>
        <Body>
          <Trans
            i18nKey='disbursements.blocks.individual-bills.before-disbursement'
            values={{ amount: formatCurrency(amount) }}
          >
            Before you disburse to this folio, there are still
            <Bold>{formatCurrency(amount)} bills &amp; fees</Bold> that need to
            be paid.
          </Trans>
        </Body>
        <Body>
          {t('disbursements.blocks.individual-bills.disburse-without-paying')}
        </Body>
      </Box>
    );
  }
};
