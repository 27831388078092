import { StyleSheet, useStyles } from '@rexlabs/styling';
import React from 'react';

const badgeStyles = StyleSheet({
  badge: {
    background: ({ token }) => token('palette.grey.400'),
    borderRadius: 8,
    paddingLeft: 8,
    paddingRight: 8
  },

  isActive: {
    background: ({ token }) => token('palette.indigo.200')
  }
});

export function NumberBadge({
  number,
  isActive = false
}: {
  number: number;
  isActive?: boolean;
}) {
  const s = useStyles(badgeStyles);

  return (
    <div
      {...s.with('badge', {
        isActive
      })()}
    >
      {number}
    </div>
  );
}
