import { Contact } from 'src/modules/contacts/types/contact-types';
import { Property } from 'src/modules/properties/types/property-types';
import { api } from 'utils/api/api-client';
import { useCachedItems } from 'view/hooks/api/cached-items';

export function useGetAccessProvidedBySuggestedContacts({
  property
}: {
  property?: Property | null;
}) {
  const includes = [
    'active_property_ownership.ownership.owners.contact',
    'active_property_tenancy.tenancy.tenants.contact',
    'portfolio.members.user.contact'
  ].join(',');

  const disabled = !property;
  const propertyUrl = `/properties/${property?.id}?include=${includes}`;

  return useCachedItems(
    propertyUrl,
    async () => {
      const propertyRequest = await api.get(propertyUrl);
      const propertyData = propertyRequest.data;

      const ownerContacts: Contact[] = (
        propertyData?.active_property_ownership?.ownership?.owners || []
      ).map((owner) => owner?.contact);

      const tenantContacts: Contact[] = (
        propertyData?.active_property_tenancy?.tenancy?.tenants || []
      ).map((tenant) => tenant?.contact);

      const portfolioMemberContacts: Contact[] = (
        propertyData?.portfolio?.members?.data || []
      )
        .map((portfolioMember) => portfolioMember.user?.contact)
        .filter((contact) => !!contact) as Contact[];

      return [...ownerContacts, ...tenantContacts, ...portfolioMemberContacts];
    },
    { disabled }
  );
}
