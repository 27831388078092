import React, { useState } from 'react';
import { ControlledTabs } from '@rexlabs/tabs';
import { InspectionAreaItemsMobileTable } from 'src/modules/tasks/inspections/entry-exit/components/inspection-area-items-mobile-table';
import { InspectionArea } from 'src/modules/tasks/inspections/entry-exit/types/InspectionArea';
import { ConditionLabelKey } from 'src/modules/tasks/inspections/entry-exit/components/condition-label-key';
import { Field, useReactFormsContext } from '@rexlabs/form';
import { TextArea } from '@rexlabs/text-input';
import Box from '@rexlabs/box';
import { StyleSheet, useStyles } from '@rexlabs/styling';
import { PreviewFileUploadInputWithErrorHandling } from 'view/components/inputs/file-upload-input/preview-file-upload-input-with-error-handling';
import { chain, isEmpty, isString } from 'lodash';
import { NumberBadge } from 'view/components/badge';

interface InspectionAreaMobileEditFormProps {
  inspectionArea?: InspectionArea;
}

const DataContext = React.createContext<any>(null);

const IMAGES = 'images';
const CONDITIONS = 'conditions';
const COMMENTS = 'comments';

const ImagesCount = React.memo(() => {
  const { activeTab } = React.useContext(DataContext);
  const { inspectionArea } = React.useContext(DataContext);
  const areaItems = inspectionArea?.items?.data ?? [];

  const areaItemIds = areaItems.map((item) => item.id);
  const { values } = useReactFormsContext();

  const count = React.useMemo(
    () =>
      chain(values.area_items)
        .filter((item) => areaItemIds.includes(item.id))
        .flatMap('images.data')
        .compact()
        .flatten()
        .value().length,
    [values.area_items, areaItemIds]
  );

  if (count === 0) return null;

  return <NumberBadge number={count} isActive={activeTab === IMAGES} />;
});

const CommentsCount = React.memo(() => {
  const { activeTab } = React.useContext(DataContext);
  const { inspectionArea } = React.useContext(DataContext);
  const areaItems = inspectionArea?.items?.data ?? [];

  const areaItemIds = areaItems.map((item) => item.id);

  const { values } = useReactFormsContext();

  const count = React.useMemo(
    () =>
      chain(values.area_items)
        .filter((item) => areaItemIds.includes(item.id))
        .map('notes')
        .filter((note) => isString(note) && !isEmpty(note))
        .value().length,
    [values.area_items, areaItemIds]
  );

  if (count === 0) return null;

  return <NumberBadge number={count} isActive={activeTab === COMMENTS} />;
});

const tabItems = [
  {
    label: 'Condition',
    name: CONDITIONS,
    Tab: ConditionsTab
  },
  {
    label: 'Images',
    name: IMAGES,
    Tab: ImagesTab,
    IconEnd: ImagesCount
  },
  {
    label: 'Comments',
    name: COMMENTS,
    Tab: CommentsTab,
    IconEnd: CommentsCount
  }
];

export function InspectionAreaMobileEditForm({
  inspectionArea
}: InspectionAreaMobileEditFormProps) {
  const [activeTab, setActiveTab] = useState(CONDITIONS);

  return (
    <DataContext.Provider value={{ inspectionArea, activeTab, setActiveTab }}>
      <ControlledTabs
        activeTab={activeTab}
        onChange={({ name }) => setActiveTab(name)}
        keepRenderedTabs={false}
        items={tabItems}
      />
    </DataContext.Provider>
  );
}

const styles = StyleSheet({
  root: {
    position: 'relative',
    display: 'block',
    height: 'calc(100vh - 220px)',
    overflow: 'hidden auto'
  }
});

function CommentsTab() {
  const { inspectionArea } = React.useContext(DataContext);
  const areaItems = inspectionArea?.items?.data ?? [];

  const s = useStyles(styles);

  return (
    <Box {...s('root')} data-testid='entry-exit-comments-tab'>
      {areaItems.map((areaItem) => {
        return (
          <Box key={areaItem.id} marginTop={16}>
            <Field
              Input={TextArea}
              name={`area_items.${areaItem.id}.notes`}
              label={areaItem.name}
              optional={false}
              inputProps={{ placeholder: 'Enter comment...' }}
            />
          </Box>
        );
      })}
    </Box>
  );
}

function ImagesTab() {
  const { inspectionArea } = React.useContext(DataContext);
  const areaItems = inspectionArea?.items?.data ?? [];

  const s = useStyles(styles);

  return (
    <Box {...s('root')} data-testid='entry-exit-images-tab'>
      {areaItems.map((areaItem) => {
        return (
          <Box key={areaItem.id} marginTop={16}>
            <Field
              id={`area_items.${areaItem.id}.images.data`}
              name={`area_items.${areaItem.id}.images.data`}
              label={areaItem.name}
              Input={PreviewFileUploadInputWithErrorHandling}
              optional={false}
              inputProps={{
                multi: true,
                accept: '.jpeg, .png, .jpg'
              }}
            />
          </Box>
        );
      })}
    </Box>
  );
}

function ConditionsTab() {
  const { inspectionArea, setActiveTab } = React.useContext(DataContext);

  return (
    <div data-testid='entry-exit-condition-tab'>
      <InspectionAreaItemsMobileTable
        items={inspectionArea?.items?.data ?? []}
        setActiveTab={setActiveTab}
      />

      <ConditionLabelKey />
    </div>
  );
}
