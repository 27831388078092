import * as React from 'react';

import { Card } from 'view/components/card';
import { ListScreen } from 'view/components/list-screen/list-screen';
import { useFeatureFlags } from 'view/components/@luna/feature-flags';
import { RecordScreen } from 'view/components/record-screen';
import { TitleBlock } from 'view/components/@luna/title-block';

import { FLAGS } from 'utils/feature-flags';

import { useGetCreateInspectionAction } from '../hooks/action-declarations/use-get-create-inspection-action';
import { InspectionTable } from '../components/inspections-table';
import { inspectionListContent as inspectionListContent } from '../data/inspection-list-content';

export function InspectionList() {
  const getCreateInspectionAction = useGetCreateInspectionAction();
  const { hasFeature } = useFeatureFlags();
  return (
    <>
      {/* NOTE: once the inspection schedule is released, delete this condition and leave
    the record screen as that is the new version */}
      {!hasFeature(FLAGS.INSPECTION_SCHEDULE) ? (
        <ListScreen title='Inspections' actions={[getCreateInspectionAction()]}>
          <Card>
            <InspectionTable />
          </Card>
        </ListScreen>
      ) : (
        <RecordScreen
          titleBlock={
            <TitleBlock
              title={'Inspections'}
              actions={[getCreateInspectionAction()]}
            />
          }
          content={inspectionListContent}
          data={{}}
        />
      )}
    </>
  );
}
