import Box from '@rexlabs/box';
import * as React from 'react';
import {
  ConfirmationDialogOptions,
  StatusChangeActionArgs,
  useStatusChangeAction
} from 'src/modules/common/actions/status/use-status-change-action';
import { invokeActionDeclaration } from 'src/modules/common/actions/utils/invoke-action-declaration';
import { tasksModel } from 'src/modules/tasks/common/models/tasks-model';
import { Task } from 'src/modules/tasks/common/types/Task';

import { ValueListItem } from 'utils/normaliser/value-list-item';
import { TaskStatus } from 'src/modules/tasks/common/types/TaskStatus';
import { useModelActions } from '@rexlabs/model-generator';
import { TaskStatusMenu } from 'src/modules/tasks/common/components/task-status-menu';

export function ComplianceProgressDropdown({
  value: task
}: {
  value?: Task<any>;
}) {
  const previousStatus = React.useRef(task?.status as ValueListItem);
  const [value, setValue] = React.useState(task?.status as ValueListItem);

  const { refreshLists } = useModelActions(tasksModel);
  const getStatusChangeAction = useStatusChangeAction(tasksModel);

  const complianceType =
    task?.details?.compliance_type ||
    task?.details?.compliance_entry?.compliance_type;
  const complianceTypeLabel = complianceType?.label?.toLowerCase();

  const dialogOptions: ConfirmationDialogOptions<TaskStatus> = {
    type: 'confirmation',
    title: 'Set task as completed',
    message: `The ${complianceTypeLabel} for this task has not been renewed. Do you still want to set this task as completed?`,
    confirmText: 'Yes, set as completed',
    onCancel: () => {
      setValue(previousStatus.current);
    },
    afterAction: () => refreshLists()
  };

  const handleStatusChange = task
    ? (newStatus) => {
        invokeActionDeclaration<StatusChangeActionArgs<typeof newStatus.id>[]>(
          getStatusChangeAction,
          {
            record: task,
            status: newStatus.id,
            dialogOptions:
              newStatus.id === 'completed' ? dialogOptions : { type: 'none' },
            generalOptions: { avoidListRefresh: true }
          }
        );
      }
    : undefined;

  const onChange = (status: ValueListItem) => {
    previousStatus.current = value;
    setValue(status);
    handleStatusChange?.(status);
  };

  return (
    <Box
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
      data-testid='task-status-tag'
    >
      <TaskStatusMenu value={value} onChange={onChange} />
    </Box>
  );
}
