import { SingleActionDeclaration } from 'src/modules/common/actions/types/action-declaration-types';
import { useDialog } from '@rexlabs/dialog';
import { noop } from 'lodash';
import { AddIcon } from 'view/components/icons/add';
import { CreateMaintenanceDialog } from '../../dialogs/create-maintenance-dialog';
import { MaintenanceFormData } from '../../types/MaintenanceFormData';

interface UseGetCreateMaintenanceActionProps {
  onCreate?: (data) => void;
  isNested?: boolean;
  isFixture?: boolean;
  initialValues?: Partial<MaintenanceFormData>;
}

export function useGetCreateMaintenanceAction(
  {
    onCreate,
    isNested,
    initialValues,
    isFixture
  }: UseGetCreateMaintenanceActionProps = {
    isNested: false,
    isFixture: false,
    onCreate: noop,
    initialValues: {}
  }
) {
  const { open } = useDialog(CreateMaintenanceDialog, {
    target: isNested ? 'nested-dialogs' : 'dialogs'
  });

  return (): SingleActionDeclaration => {
    return {
      id: 'create-task-maintenance',
      intent: 'primary',
      group: 'task-maintenance',
      label: 'Create maintenance task',
      Icon: AddIcon,
      handleAction: async () => {
        open({
          isFixture,
          onCreate,
          initialValues: {
            ...initialValues,
            priority: {
              id: 'normal',
              label: 'Normal'
            },
            details: {
              attachments: [],
              status: {
                id: 'job_logged',
                label: 'Job Logged'
              }
            }
          }
        });
      }
    };
  };
}
