import React from 'react';
import { get } from 'lodash';
import { Field, useReactFormsContext } from '@rexlabs/form';
import { StyleSheet, useStyles } from '@rexlabs/styling';
import { CentAmountInput } from 'view/components/inputs/cent-amount';
import { PercentageTotalSwitch } from 'view/components/inputs/percentage-total-switch';
import { PercentageInput } from 'view/components/inputs/percentage-input';
import { SingleLineFormLayout } from 'view/components/layouts/form/single-line-form-layout';
import { ContactPaymentMethod } from 'view/components/inputs/selects/contact-payment-method';
import { normaliseContact } from 'utils/normaliser/contact';
import { CenterToInputLine } from 'view/components/center-to-input-line/center-to-input-line';
import { Contact } from 'src/modules/contacts/types/contact-types';
import { Select } from '@rexlabs/select';
import { LineItemRemoveButton } from 'view/components/line-items/line-item-remove-button';

const defaultStyles = StyleSheet({
  nonStretchy: {
    flex: '0 !important'
  },
  amount: {
    flex: '0 0 12rem !important'
  }
});

export interface PaymentMethodLineItemProps {
  values: any;
  name: string;
  contacts?: Contact[];
  fixedContact?: Contact;
  onRemove?: () => void;
}

export function PaymentMethodLineItem({
  values,
  name,
  contacts,
  fixedContact,
  onRemove
}: PaymentMethodLineItemProps) {
  const s = useStyles(defaultStyles);

  const { setFieldValue } = useReactFormsContext();

  const contactName = `${name}.contact`;

  const splitType = get(values, `${name}.split_amount.type`);
  const contactId = fixedContact
    ? fixedContact.id
    : get(values, `${contactName}.id`);
  const isPercentage = splitType?.id === 'percent';

  const handleContactChange = () => {
    setFieldValue(`${name}.contact_payment_method`, null);
  };

  return (
    <div className='flex'>
      <SingleLineFormLayout>
        {!fixedContact && (
          <Field
            id={contactName}
            name={contactName}
            label='Owner'
            Input={Select}
            inputProps={{
              items: contacts,
              normaliser: normaliseContact,
              searchable: false
            }}
            onChange={handleContactChange}
            optional={false}
          />
        )}
        <Field
          id={`${name}.contact_payment_method`}
          name={`${name}.contact_payment_method`}
          label='Payment method'
          Input={ContactPaymentMethod}
          inputProps={{
            disabled: fixedContact ? false : !contactId,
            contactId: contactId,
            forDisbursements: true,
            shouldReselectClearSelection: false
          }}
          optional={false}
        />
        <div {...s('nonStretchy')}>
          <Field
            name={`${name}.split_amount.type`}
            label='Split'
            Input={PercentageTotalSwitch}
            optional={false}
          />
        </div>
        <div {...s('amount')}>
          {isPercentage ? (
            <Field
              name={`${name}.split_amount.amount`}
              label='Amount'
              Input={PercentageInput}
              optional={false}
            />
          ) : (
            <Field
              name={`${name}.split_amount.amount`}
              label='Amount'
              Input={CentAmountInput}
              optional={false}
            />
          )}
        </div>
      </SingleLineFormLayout>
      {onRemove && (
        <div className='ml-l'>
          <CenterToInputLine>
            <LineItemRemoveButton onClick={onRemove} />
          </CenterToInputLine>
        </div>
      )}
    </div>
  );
}
