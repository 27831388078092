import React, { useCallback } from 'react';
import { DialogProps } from '@rexlabs/dialog';
import { RecordDialog } from 'view/components/record-screen/dialog/dialog';
import { DialogContentConfig } from 'view/components/record-screen/dialog/types';
import { RecordSubmitHandler } from 'view/components/record-screen/utils';
import Icons from '@rexlabs/icons';
import { RecordType } from 'data/models/types';
import { PendingDisbursement } from 'data/models/entities/financials/pending-disbursements';
import { useTranslation } from 'react-i18next';
import {
  DisbursementBlockData,
  disbursementDialogBlock,
  DisbursementOptions
} from '../blocks/disbursement-dialog-block';

export const content: DialogContentConfig = [
  {
    id: 'details',
    label: 'Details',
    blocks: [disbursementDialogBlock]
  }
];

interface DisburseFundsDialogProps extends DialogProps {
  recordType: RecordType;
  contactsWithBills: PendingDisbursement[];
  contactsWithoutBills: PendingDisbursement[];
  handleConfirmation: (
    entitiesToDisburse: PendingDisbursement[]
  ) => Promise<void>;
}
interface Values extends DisbursementBlockData {
  disbursement_options: DisbursementOptions;
}

export function BulkDisburseFundsDialog({
  onClose,
  recordType,
  contactsWithBills,
  contactsWithoutBills,
  handleConfirmation
}: DisburseFundsDialogProps) {
  const { t } = useTranslation();
  const handleSubmit: RecordSubmitHandler<Values> = useCallback(
    async ({ values }) => {
      const contactsToDisburse =
        values.disbursement_options === DisbursementOptions.all
          ? [...values.contactsWithBills, ...values.contactsWithoutBills]
          : values.contactsWithoutBills;

      await handleConfirmation(contactsToDisburse);
      return true;
    },
    []
  );

  const initialValues = {
    recordType,
    contactsWithBills,
    contactsWithoutBills
  };

  return (
    <RecordDialog
      onClose={onClose}
      TitleIcon={Icons.InfoCircle}
      title={t('disbursements.confirm-for-record-type', { recordType })}
      submitLabel={t('disbursements.disburse-funds')}
      content={content}
      handleSubmit={handleSubmit}
      data={initialValues}
    />
  );
}
