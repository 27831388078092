import React from 'react';
import { BlockConfig } from 'view/components/record-screen/types';
import { Value } from 'view/components/values';
import { Field } from '@rexlabs/form';
import { BooleanSelect } from 'view/components/inputs/selects/boolean';
import { Grid } from 'view/components/@luna/form/grid';
import { ConsolidateWithdrawalsInfoBanner } from 'src/modules/settings/account-settings/components/consolidated-withdrawals-info-banner';
import { Settings } from '../../hooks/useSettings';

const isSettingEnabled = (settingValue: boolean | undefined): boolean => {
  if (settingValue === undefined) {
    return true; // setting is on by default. copying BE approach
  }

  return settingValue;
};

const CONSOLIDATE_WITHDRAWALS_SETTING =
  'consolidate-withdrawals-on-payment-files';

export const withdrawalsSettingsBlock: BlockConfig<Settings> = {
  id: 'withdrawals-settings',
  title: 'Withdrawals',
  validate: {
    definitions: {
      timezone: { rules: 'required' }
    }
  },
  View: ({ data }) => {
    const isEnabled = isSettingEnabled(data?.[CONSOLIDATE_WITHDRAWALS_SETTING]);

    return (
      <Grid columns={1}>
        <Grid columns={2}>
          <Value
            label='Consolidate withdrawals'
            value={isEnabled ? 'Enabled' : 'Disabled'}
          />
        </Grid>
        <ConsolidateWithdrawalsInfoBanner enabled={isEnabled} />
      </Grid>
    );
  },
  Edit: ({ values }) => {
    const isEnabled = isSettingEnabled(
      values?.[CONSOLIDATE_WITHDRAWALS_SETTING]
    );

    return (
      <Grid columns={1}>
        <Grid columns={2}>
          <Field<typeof BooleanSelect>
            id={`${CONSOLIDATE_WITHDRAWALS_SETTING}-select`}
            label='Consolidate withdrawals'
            name={CONSOLIDATE_WITHDRAWALS_SETTING}
            Input={BooleanSelect}
            inputProps={{
              trueLabel: 'Enabled',
              falseLabel: 'Disabled'
            }}
          />
        </Grid>
        <ConsolidateWithdrawalsInfoBanner enabled={isEnabled} />
      </Grid>
    );
  }
};
