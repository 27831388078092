import { push, useWhereabouts } from '@rexlabs/whereabouts';
import React from 'react';
import { useTranslation } from 'react-i18next';
import ROUTES from 'routes/app';

export enum PropertyFilters {
  PROPERTY_OVERVIEW = 'property_overview',
  LEASE_AGREEMENTS = 'lease_agreements',
  MANAGEMENT_AGREEMENTS = 'management_agreements'
}

export function usePropertyFilters() {
  const match = useWhereabouts();

  const [selectedOption, setSelectedOption] = React.useState(() => {
    return match.query?.view ?? PropertyFilters.PROPERTY_OVERVIEW;
  });

  const { t } = useTranslation();

  const options = React.useMemo(
    () => [
      {
        label: 'Property overview',
        id: PropertyFilters.PROPERTY_OVERVIEW,
        handleAction() {
          setSelectedOption(PropertyFilters.PROPERTY_OVERVIEW);
          push(ROUTES.PROPERTIES_LIST, {
            query: {
              view: PropertyFilters.PROPERTY_OVERVIEW
            }
          });
        }
      },
      {
        label: 'Lease agreements',
        id: PropertyFilters.LEASE_AGREEMENTS,
        handleAction() {
          setSelectedOption(PropertyFilters.LEASE_AGREEMENTS);
          push(ROUTES.PROPERTIES_LIST, {
            query: {
              view: PropertyFilters.LEASE_AGREEMENTS
            }
          });
        }
      },
      {
        label: t('property-ownerships.management-agreement.label.plural'),
        id: PropertyFilters.MANAGEMENT_AGREEMENTS,
        handleAction() {
          setSelectedOption(PropertyFilters.MANAGEMENT_AGREEMENTS);
          push(ROUTES.PROPERTIES_LIST, {
            query: {
              view: PropertyFilters.MANAGEMENT_AGREEMENTS
            }
          });
        }
      }
    ],
    []
  );

  return React.useMemo(() => {
    const result = options.find((option) => option.id === selectedOption)!;

    return {
      actions: [
        {
          label: result.label,
          actions: options
        }
      ],
      activeFilter: selectedOption
    };
  }, [selectedOption]);
}
