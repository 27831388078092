import { useDialog } from '@rexlabs/dialog';
import { ActionDeclaration } from 'src/modules/common/actions/types/action-declaration-types';
import {
  ReceiptFundsDialog,
  ReceiptFundsDialogProps
} from 'src/modules/trust-journal-entries/dialogs/receipt-funds-dialog';
import { GetFinancialEntityActionArgs } from './use-get-financial-entity-actions';

type AllOrNothing<T extends Record<string, any>> =
  | T
  | Partial<Record<keyof T, never>>;

export type GetReceiptFundsActionArgs = Omit<
  ReceiptFundsDialogProps,
  'allowUpdateTenantReference'
> &
  AllOrNothing<GetFinancialEntityActionArgs>;

export function useGetReceiptFundsAction() {
  const receiptFundsDialog = useDialog(ReceiptFundsDialog);

  return (args?: GetReceiptFundsActionArgs): ActionDeclaration => {
    const {
      recordTypeName,
      entity,
      receiptFrom,
      showCreateAnotherButton,
      onCreate
    } = args ?? {};

    return {
      id: 'receipt-funds',
      group: 'financial',
      label: 'Receipt funds',
      handleAction: async () =>
        receiptFundsDialog.open({
          title: recordTypeName ? `Receipt ${recordTypeName} funds` : undefined,
          folio: entity,
          receiptFrom,
          onCreate,
          showCreateAnotherButton,
          allowUpdateTenantReference: false
        })
    };
  };
}
