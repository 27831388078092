type MultiForm = {
  [id: string]: {
    values: any;
  };
};

export function getMergedMultiFormValues(multiForm: MultiForm) {
  return Object.values(multiForm)
    .map((form) => form.values)
    .reduce(
      (mergedFormValues, formsValues) => ({
        ...mergedFormValues,
        ...formsValues
      }),
      {}
    );
}
