import {
  AgencyFee,
  AgencyFeeStatus
} from 'src/modules/agency-fees/types/agency-fee-types';
import { ActionDeclaration } from 'src/modules/common/actions/types/action-declaration-types';
import { useStatusChangeAction } from 'src/modules/common/actions/status/use-status-change-action';
import { useDeleteAction } from 'src/modules/common/actions/delete/use-delete-action';
import { agencyFeeModel } from 'src/modules/agency-fees/models/agency-fee';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useGetEditAction } from './use-get-edit-action';
import { useGetDuplicateAction } from './use-get-duplicate-action';

export function useGetAgencyFeesActions() {
  const getEditAction = useGetEditAction();
  const getDuplicateAction = useGetDuplicateAction();
  const getDeleteAction = useDeleteAction(agencyFeeModel);
  const getStatusChangeAction = useStatusChangeAction(agencyFeeModel);

  const { t } = useTranslation();

  return function (agencyFee: AgencyFee): ActionDeclaration[] {
    const deleteAction = getDeleteAction(agencyFee, {
      message: (
        <>
          {t(
            'property-ownerships.management-agreement-fees.delete.confirmation-message.singular'
          )}
        </>
      )
    });
    const setAsActiveAction = getStatusChangeAction<AgencyFeeStatus>({
      record: agencyFee,
      status: 'active'
    });
    const setAsArchivedAction = getStatusChangeAction<AgencyFeeStatus>({
      record: agencyFee,
      status: 'archived',
      dialogOptions: {
        description: (
          <>
            Archiving this fee will prevent it being added to new or existing
            ownerships. Existing ownerships using this fee will continue to do
            so unless manually removed, or when the ownership agreement is
            reviewed. You can re-activate an archived fee whenever you want to
            charge this again.
          </>
        )
      }
    });

    const statusChangeActions: ActionDeclaration[] = [];

    switch (agencyFee.status.id) {
      case 'active':
        statusChangeActions.push(setAsArchivedAction);
        break;
      case 'archived':
        statusChangeActions.push(setAsActiveAction);
        break;
    }

    return [
      getEditAction(agencyFee),
      getDuplicateAction(agencyFee),
      ...statusChangeActions,
      deleteAction
    ];
  };
}
