import * as React from 'react';
import { ListTable, TabbedTable } from 'view/components/table';
import { Card } from 'view/components/card';
import { ListScreen } from 'view/components/list-screen/list-screen';
import { Tab } from 'view/components/table/tabbed';
import { query } from '@rexlabs/model-generator';
import { useTableFilters } from 'view/hooks/use-table-filters';
import { useAppWideFilterContext } from 'src/modules/app-wide-filters/contexts/app-wide-filter-context';
import { TODO_ROUTES } from '../routes';

import { useTodoColumns } from '../hooks/use-todo-columns';
import { useGetTodoActions } from '../hooks/action-menu-items/use-get-todo-actions';
import { useGetCreateTodoAction } from '../hooks/action-declarations/use-get-create-todo-action';
import { getTaskTabbedTableTabs } from '../../common/utils/get-task-tabbed-table-tabs';
import { tasksModel } from '../../common/models/tasks-model';
import { TaskType } from '../../common/types/TaskType';

const getTodosQuery = () => query`{
  ${tasksModel} {
    todoDetails,
    property,
    managed_by
  }
}`;

export function TodoList() {
  const { appWideFilterKey } = useAppWideFilterContext();

  const getCreateTodoAction = useGetCreateTodoAction();

  const { getSort, getFilters } = useTableFilters('tasks', {
    type_id: 'todo' as TaskType
  });

  const getTodoActions = useGetTodoActions();

  const getRowLinkProps = ({ item }) => {
    return {
      to: TODO_ROUTES.TODO_DETAILS,
      params: { todoId: item.id }
    };
  };

  const getActionMenuItems = React.useCallback(
    ({ item }) => getTodoActions(item),
    [getTodoActions]
  );

  const todoColumns = useTodoColumns();
  const columnDependency = JSON.stringify(todoColumns);

  const commonTabProps = React.useMemo(() => {
    return {
      id: 'todos',
      columns: todoColumns,
      getQuery: getTodosQuery,
      Table: ListTable,
      getRowLinkProps,
      getActionMenuItems,
      forcedGlobalFilter: [
        {
          field: 'type_id',
          op: 'eq',
          value: { value: 'todo', label: 'Todo' }
        }
      ],
      getFilters,
      getSort,
      shouldUseAppWideFilter: true
    };
  }, [columnDependency, getFilters]);

  const tabs = React.useMemo<Tab[]>(
    () => getTaskTabbedTableTabs(commonTabProps),
    [commonTabProps]
  );

  return (
    <ListScreen
      title='To-do'
      actions={[getCreateTodoAction()]}
      key={appWideFilterKey}
    >
      <Card>
        <TabbedTable tabs={tabs} />
      </Card>
    </ListScreen>
  );
}
