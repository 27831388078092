import { snakeCase } from 'lodash';
import { useTranslation } from 'react-i18next';

import { Task } from 'src/modules/tasks/common/types/Task';

import { SummaryRow } from '../../../view/components/summary-table';
import { AmountToWithholdInOwnership } from '../types/AmountToWithholdInOwnership';

export function useGetFloatSummaryItem() {
  const { t } = useTranslation();

  return (
    description: string,
    amount: number,
    data: AmountToWithholdInOwnership[],
    relatedTasks?: Task<any>[]
  ) => {
    const nestedData: SummaryRow[] = [];

    data.forEach((withheldItem) => {
      // NOTE: I'm only handling these two cases right now, as I'm fairly confident these are the only two.
      // If there are more, we will need to update accordingly.
      const isValidEntityRecordType = ['task', 'ownership'].includes(
        withheldItem.entity_record_type
      );
      if (!isValidEntityRecordType) return;

      const relatedTask =
        withheldItem.entity_record_type === 'task'
          ? relatedTasks?.find((task) => task.id === withheldItem.entity_id) ||
            null
          : null;

      return nestedData.push({
        description: relatedTask
          ? `${t(
              'withheld-funds.disbursement-summary.withheld-funds.sub-items.task-float.label'
            )} ${relatedTask.summary}`
          : t(
              'withheld-funds.disbursement-summary.withheld-funds.sub-items.ownership-float.label'
            ),
        amount: withheldItem.amount,
        id: `${snakeCase(description)}.${withheldItem.entity_id}`
      });
    });

    return {
      description,
      amount,
      id: snakeCase(description),
      nested_data: nestedData
    };
  };
}
