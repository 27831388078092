import { useDialog } from '@rexlabs/dialog';
import { CreateInvoiceDialog } from 'src/modules/invoices/dialogs/create-invoice-dialog';
import { ActionDeclaration } from 'src/modules/common/actions/types/action-declaration-types';
import { getRecordType } from 'utils/records/get-record-type';
import {
  FinancialRecordType,
  FinancialRecordTypes
} from 'utils/financials/financial-objects';
import { capitalize } from 'lodash';
import { getRecordTypeName } from 'utils/records/get-record-type-name';
import { GetFinancialEntityActionArgs } from './use-get-financial-entity-actions';

export function useGetCreateInvoiceAction() {
  const { open } = useDialog(CreateInvoiceDialog);

  return (
    financialEntityArgs: GetFinancialEntityActionArgs
  ): ActionDeclaration => ({
    id: 'create-invoice',
    group: 'financial',
    label: 'Create invoice',
    handleAction: async () => {
      const recordType = getRecordType(financialEntityArgs.entity);

      return recordType &&
        Object.values(FinancialRecordTypes).some(
          (financialRecordType) => financialRecordType === recordType
        )
        ? open({
            onSave: financialEntityArgs.onCreate,
            initialValues: {
              payable_to: {
                object: {
                  id: financialEntityArgs.entity.id,
                  label: financialEntityArgs.entity.display_name,
                  type: {
                    id: getRecordType(
                      financialEntityArgs.entity
                    ) as FinancialRecordType,
                    label: capitalize(getRecordTypeName(recordType))
                  },
                  record: financialEntityArgs.entity
                }
              }
            }
          })
        : open();
    }
  });
}
