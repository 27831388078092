import { DialogProps } from '@rexlabs/dialog';
import { useModelActions } from '@rexlabs/model-generator';
import * as React from 'react';
import { useRecordCreatedToast } from 'src/modules/common/toasts/hooks/use-record-created-toast';
import { RecordDialog } from 'view/components/record-screen/dialog/dialog';
import { prepaymentChartOfAccountsBlock } from 'src/modules/prepayments/blocks/prepayment-chart-of-accounts-block';
import { mapCreatePrepaymentBucketFormToApi } from 'src/modules/prepayments/mappers/map-create-prepayment-bucket-form-to-api';
import { prepaymentDetailsBlock } from '../blocks/prepayment-details-block';
import { prepaymentBucketsModel } from '../models/prepayment-buckets-model';

const content = [
  {
    id: 'add-prepayments',
    label: 'Add Prepayments',
    blocks: [prepaymentDetailsBlock, prepaymentChartOfAccountsBlock]
  }
];

export function CreatePrepaymentBucketDialog({ onClose }: DialogProps) {
  const { createItem, refreshLists } = useModelActions(prepaymentBucketsModel);
  const addToast = useRecordCreatedToast(prepaymentBucketsModel, {
    actions: []
  });

  const handleSubmit = async ({ values }) => {
    const dataToSubmit = mapCreatePrepaymentBucketFormToApi(values);

    const { data: prepayments } = await createItem({ data: dataToSubmit });

    addToast(prepayments);

    return refreshLists();
  };

  return (
    <RecordDialog
      title='Add prepayment bucket'
      submitLabel={'Add bucket'}
      content={content}
      onClose={onClose}
      handleSubmit={handleSubmit}
      blockProps={{ isCreating: true }}
    />
  );
}
