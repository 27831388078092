import React from 'react';
import { push } from '@rexlabs/whereabouts';
import ROUTES from 'routes/app';
import ArrowBackIcon from 'view/components/icons/arrow-back';
import EyeIcon from 'view/components/icons/eye-open';
import { BatchReceiptingItem } from '../../types';
import { LabelWithIcon } from '../use-batch-receipting-button-config';

export function openSplitBankTransactionsDialog(item: BatchReceiptingItem) {
  push(
    ROUTES.BATCH_RECEIPTING_LIST_SCREEN.BATCH_RECEIPTING_SUB_TRANSACTION_DIALOG,
    {
      params: {
        parentTransactionId: item.parent_transaction_id!
      }
    }
  );
}

export function useGetSplitTransactionViewEditAction() {
  return (item: BatchReceiptingItem) => {
    return {
      label: LabelWithIcon('View & edit match', <EyeIcon />),
      Icon: ArrowBackIcon,
      group: 'split-transaction-view-edit',
      onClick: () => openSplitBankTransactionsDialog(item)
    };
  };
}
