import { Disbursement } from 'data/models/entities/financials/disbursements/disbursements';
import { ErrorBanner } from 'view/components/@luna/notifications/banner';
import { Body } from '@rexlabs/text';
import Box from '@rexlabs/box';
import WarningCircleIcon from 'view/components/icons/warning-circle';
import { VoidedBanner } from 'src/modules/common/components/voided-banner';
import React from 'react';

type Props = {
  record: Disbursement;
};

export const DisbursementVoidedBanner = ({ record }: Props) => {
  if (record.status.id === 'processing_void') {
    return (
      <ErrorBanner
        title={`Voiding Disbursement`}
        description={
          <Body>
            <Box>We are currently processing voiding this disbursement.</Box>
          </Body>
        }
        Icon={WarningCircleIcon}
        strong={true}
      />
    );
  }

  if (record.status.id === 'voided') {
    return <VoidedBanner record={record} />;
  }

  return <></>;
};
