import { UserInvitationLoginScreen } from 'src/modules/auth/user-invitation/screens/user-invitation-login-screen';
import { UserInvitationScreen } from 'src/modules/auth/user-invitation/screens/user-invitation';
import { UserInvitationCreateUserScreen } from 'src/modules/auth/user-invitation/screens/user-invitation-create-user-screen';
import { UserInvitationErrorScreen } from 'src/modules/auth/user-invitation/screens/user-invitation-error-screen';
import { UserInvitationExpiredScreen } from 'src/modules/auth/user-invitation/screens/user-invitation-expired-screen';

export const USER_INVITATION_ROUTES = {
  USER_INVITATION: {
    config: {
      path: '/user-invitation',
      Component: UserInvitationScreen
    }
  },
  USER_INVITATION_LOGIN: {
    config: {
      //TODO: This pains me so much that we can't have nested routes 🤬
      path: '/user-invitation-login',
      Component: UserInvitationLoginScreen
    }
  },
  USER_INVITATION_CREATE: {
    config: {
      path: '/user-invitation-create',
      Component: UserInvitationCreateUserScreen
    }
  },
  USER_INVITATION_ERROR: {
    config: {
      path: '/user-invitation-error',
      Component: UserInvitationErrorScreen
    }
  },
  USER_INVITATION_EXPIRED: {
    config: {
      path: '/user-invitation-expired',
      Component: UserInvitationExpiredScreen
    }
  }
};
