import { useModelActions } from '@rexlabs/model-generator';
import {
  financialsInvoicesModel,
  Invoice
} from 'data/models/entities/financials/invoices';
import { BILL_PROCESSING_FLAGS } from 'src/modules/bill-processing/feature-flags';
import { useDeleteAction } from 'src/modules/common/actions/delete/use-delete-action';
import { useEditRecordDetailsAction } from 'src/modules/common/actions/record-details/hooks/use-edit-record-details-action';
import { useModelDownloadAction } from 'src/modules/common/actions/reporting/use-model-download-action';
import { useStatusChangeAction } from 'src/modules/common/actions/status/use-status-change-action';
import { ActionDeclaration } from 'src/modules/common/actions/types/action-declaration-types';
import { downloadFile } from 'utils/files';
import { InvoiceStatus } from 'utils/static-value-lists/invoice-status';
import { useGetPrimaryRecordActionGroup } from 'view/hooks/actions/use-get-primary-record-action-group';
import { useDialog } from '@rexlabs/dialog';
import { ViewDocumentDialog } from 'src/modules/documents/dialogs/view-document-dialog';
import { getViewDocumentPropsFromObject } from 'src/modules/documents/actions/use-get-view-documents-action';
import invariant from 'invariant';
import { Bill } from 'src/modules/bill-processing/types/Bill';
import { useCreditAction } from './use-credit-action';
import { useGetReceiptAction } from './use-receipt-invoice-action';
import { useGetRemoveOriginalBillAction } from './use-remove-original-bill-action';
import { useGetSendInvoiceAction } from './use-send-invoice-action';
import { useGetTransferFundsInvoiceAction } from './use-transfer-funds-invoice-action';
import { useGetUploadOriginalBillAction } from './use-upload-original-bill-action';

export type RefreshParentEntityType = (args?: any) => any;

export function useInvoiceActions(
  refreshParentEntityAction?: RefreshParentEntityType
) {
  const getPrimaryRecordActionGroup = useGetPrimaryRecordActionGroup();
  const getReceiptAction = useGetReceiptAction();
  const getTransferFundsAction = useGetTransferFundsInvoiceAction();
  const getCreditAction = useCreditAction();
  const getDeleteAction = useDeleteAction(financialsInvoicesModel);
  const statusChangeAction = useStatusChangeAction(financialsInvoicesModel);
  const getEditDetailAction = useEditRecordDetailsAction(
    financialsInvoicesModel,
    false,
    true
  );
  const getUploadOriginalBillAction = useGetUploadOriginalBillAction();
  const getRemoveOriginalBillAction = useGetRemoveOriginalBillAction();

  const getModelDownloadAction = useModelDownloadAction(
    financialsInvoicesModel
  );
  const getSendInvoiceAction = useGetSendInvoiceAction();

  const { downloadItem } = useModelActions(financialsInvoicesModel);
  const viewDocumentDialog = useDialog(ViewDocumentDialog);

  return (invoice: Invoice): ActionDeclaration[] => {
    const voidAction = statusChangeAction<InvoiceStatus>({
      record: invoice,
      status: 'void',
      dialogOptions: {
        afterAction: refreshParentEntityAction
      }
    });
    const deleteAction = getDeleteAction(invoice, {
      afterAction: refreshParentEntityAction
    });
    const editAction = getEditDetailAction(invoice);
    const receiptAction = getReceiptAction(invoice, refreshParentEntityAction);
    const transferFundsAction = getTransferFundsAction(
      invoice,
      refreshParentEntityAction
    );
    const creditAction = getCreditAction(invoice, refreshParentEntityAction);
    const awaitingPaymentAction = statusChangeAction<InvoiceStatus>({
      record: invoice,
      status: 'awaiting_payment',
      dialogOptions: {
        type: 'none'
      }
    });

    const downloadPdf = getModelDownloadAction({
      fetcher: () => {
        return downloadItem({
          invoiceId: invoice.id
        });
      },
      label: 'Download invoice (PDF)',
      group: 'bill',
      record: invoice
    });

    const uploadedBillFile = invoice?.uploaded_bill?.file;
    const downloadBillAction: ActionDeclaration = {
      label: 'Download bill',
      group: 'bill',
      flag: BILL_PROCESSING_FLAGS.BILL_PROCESSING,
      handleAction: () =>
        uploadedBillFile && downloadFile(uploadedBillFile?.download_url)
    };

    const viewBillAction: ActionDeclaration = {
      label: 'View bill',
      group: 'bill',
      flag: BILL_PROCESSING_FLAGS.BILL_PROCESSING,
      handleAction: () => {
        if (!uploadedBillFile) return;

        invariant(
          invoice.uploaded_bill?.file,
          `Invoice uploaded bill can't be empty`
        );

        viewDocumentDialog.open(
          getViewDocumentPropsFromObject(
            invoice.uploaded_bill as Required<Bill>
          )
        );
      }
    };

    const uploadedBillActions = uploadedBillFile
      ? [
          viewBillAction,
          downloadBillAction,
          getRemoveOriginalBillAction(invoice)
        ]
      : [getUploadOriginalBillAction(invoice)];

    const sendInvoice = getSendInvoiceAction(invoice);

    const statusDependantActions: ActionDeclaration[] = [];
    switch (invoice.status.id) {
      case 'void':
        break;
      case 'paid':
        statusDependantActions.push(downloadPdf, ...uploadedBillActions);
        break;
      case 'awaiting_payment':
        statusDependantActions.push(
          editAction,
          receiptAction,
          transferFundsAction,
          creditAction,
          downloadPdf,
          sendInvoice,
          ...uploadedBillActions,
          voidAction
        );
        break;
      case 'awaiting_approval':
        statusDependantActions.push(
          editAction,
          awaitingPaymentAction,
          deleteAction,
          ...uploadedBillActions
        );
        break;
      case 'draft':
        statusDependantActions.push(
          editAction,
          awaitingPaymentAction,
          deleteAction,
          ...uploadedBillActions
        );
        break;
    }

    return [
      ...getPrimaryRecordActionGroup('invoice', invoice.id),
      ...statusDependantActions
    ];
  };
}
