import React from 'react';
import Box from '@rexlabs/box';
import { useToken } from '@rexlabs/styling';
import { pluralize } from 'utils/formatters';
import {
  Ownership as OwnershipType,
  Owner as OwnerType
} from 'data/models/entities/ownerships';
import { PropertyOwnership as PropertyOwnershipType } from 'src/modules/property-ownerships/types/property-ownership-types';
import { RecordFlag } from '../../record-flags/record-flag';
import { ContactFlag } from '../../record-flags/contact-flag';

import { EntryLink } from '../components/entry-link';
import { cap, hasMoreThan } from '../utils';
import { EntryList, RecordStatus } from '../components/entry-list';
import { PropertyOwnershipEntryLink } from '../components/property-ownership-entry-link';
import { ViewLink, ViewRecordLink } from '../components/view-link';

interface OwnershipEntriesProps {
  maxListLength: number;
  status?: RecordStatus;
  headline?: React.ReactNode;
  propertyOwnership?: PropertyOwnershipType;
  ownership?: OwnershipType;
  owners: OwnerType[];
}

export function OwnershipEntries({
  headline,
  status,
  maxListLength,
  propertyOwnership,
  ownership,
  owners
}: OwnershipEntriesProps) {
  const token = useToken();

  const ownersListLength =
    maxListLength - Number(!!ownership) - Number(!!propertyOwnership);

  return (
    <EntryList status={status} headline={headline}>
      {ownership && (
        <EntryLink type='ownership' id={ownership.id}>
          <RecordFlag record={ownership} recordType='ownership' />
        </EntryLink>
      )}
      {propertyOwnership && (
        <PropertyOwnershipEntryLink propertyOwnership={propertyOwnership} />
      )}

      {cap(owners, ownersListLength).map((owner) => (
        <EntryLink key={owner.contact.id} type='contact' id={owner.contact.id}>
          <ContactFlag contact={owner.contact} description='Owner' />
        </EntryLink>
      ))}

      {hasMoreThan(owners, ownersListLength) && (
        <Box mt={token('spacing.xs')}>
          {ownership ? (
            <ViewRecordLink type='ownership' id={ownership?.id} tab='owners'>
              View {owners.length} {status} {pluralize('owner', owners.length)}
            </ViewRecordLink>
          ) : (
            <ViewLink query={{ tab: 'overview' }}>
              View {owners.length} {status} {pluralize('owner', owners.length)}
            </ViewLink>
          )}
        </Box>
      )}
    </EntryList>
  );
}
