import * as React from 'react';
import { ValueListItem } from 'utils/normaliser/value-list-item';
import { Dropdown, DropdownProps } from 'view/components/@luna/dropdown';
import { useApiValueList } from 'view/hooks/api/use-api-value-list';
import { ValueListValue } from 'data/models/types';
import { workOrderStatusMap } from '../maps/work-order-status-map';
import { WorkOrderTask } from '../types/WorkOrderTask';
import { WorkOrderStatus } from '../types/WorkOrderStatus';

type TaskStatusMenuProps = Omit<
  DropdownProps<ValueListItem>,
  'normaliser' | 'items'
> & {
  task?: WorkOrderTask;
};

const statusesToExcludeById: WorkOrderStatus[] = ['bill_added'];

export function TaskStatusMenu({ task, ...rest }: TaskStatusMenuProps) {
  const { data } = useApiValueList('work_order_status') as {
    data?: ValueListValue<WorkOrderStatus>[];
  };

  const statuses =
    task?.details?.work_done_by_type?.id === 'owner'
      ? data?.filter(
          ({ id }: { id: WorkOrderStatus }) =>
            !statusesToExcludeById.includes(id)
        )
      : data;

  return (
    <Dropdown<ValueListItem>
      items={statuses}
      normaliser={({ id, label }) => ({
        id,
        label,
        intent: workOrderStatusMap[id]
      })}
      {...rest}
    />
  );
}
