import React from 'react';
import { PropertyTenancy } from 'src/modules/property-tenancies/types/property-tenancy-types';
import { RecordFlag } from 'view/components/record-flags/record-flag';
import { EntryLink } from './entry-link';

interface PropertyTenancyEntryLinkProps {
  propertyTenancy: PropertyTenancy;
}

export function PropertyTenancyEntryLink({
  propertyTenancy
}: PropertyTenancyEntryLinkProps) {
  return (
    <EntryLink
      type='property_tenancy'
      id={propertyTenancy.id}
      parentId={propertyTenancy.property_id}
    >
      <RecordFlag record={propertyTenancy} recordType='property_tenancy' />
    </EntryLink>
  );
}
