import { useMemo } from 'react';
import { useModelActions } from '@rexlabs/model-generator';

import { useStatusChangeAction } from 'src/modules/common/actions/status/use-status-change-action';
import { getInvoicesPayableByBlock } from 'src/modules/invoices/blocks/invoices-payable-by';
import { getWorkOrderStatusChangeTransformPayload } from '../utils/get-work-order-status-change-transform-payload';
import { getCreateInvoiceInitialValues } from '../utils/get-create-invoice-initial-values';
import { WorkOrderTask } from '../types/WorkOrderTask';
import { tasksModel } from '../../common/models/tasks-model';
import { getInvoicePayableBySuggestedItems } from '../../common/utils/get-invoice-payable-by-suggested-items';

export const usePayableByInvoicesBlock = () => {
  const { refreshItem } = useModelActions(tasksModel);
  const getStatusChangeAction = useStatusChangeAction(tasksModel);

  return useMemo(
    () =>
      getInvoicesPayableByBlock<WorkOrderTask>(({ data }) => {
        const statusChangeTransformPayload = getWorkOrderStatusChangeTransformPayload(
          data!
        );

        const { handleAction: changeStatusAction } = getStatusChangeAction({
          record: data!,
          status: 'bill_added',
          dialogOptions: { type: 'none' },
          transformPayload: statusChangeTransformPayload
        });

        return {
          hashParamKey: 'payable_by',
          getForcedGlobalFilter: () => [
            {
              field: 'task_id',
              op: '==',
              value: data?.id
            }
          ],
          emptyMessage: 'Add a bill for this work order job',
          showEmpty: (data: WorkOrderTask) => {
            return !data?.invoices_summary?.count;
          },
          initialValues: getCreateInvoiceInitialValues(data),
          refreshRecord: async () => refreshItem({ id: data?.id }),
          onSave: changeStatusAction,
          suggestions: {
            payableBy: getInvoicePayableBySuggestedItems({
              property: data?.property
            })
          }
        };
      }),
    []
  );
};
