import { Quri } from '@rexlabs/quri';

import { Invoice } from 'data/models/entities/financials/invoices';

import { getSearchQuery } from 'src/lib/react-query/hooks/use-records-query';

import { api } from './api-client';

export interface GetInvoicesArgs {
  searchTerm?: string;
  perPage: number;
  filter?: Quri[];
  includes?: string[];
}

const defaultGetInvoicesArgs: GetInvoicesArgs = {
  searchTerm: undefined,
  perPage: 30
};

export async function getInvoices(args: Partial<GetInvoicesArgs> = {}) {
  const { searchTerm, perPage, filter, includes } = {
    ...defaultGetInvoicesArgs,
    ...args
  };
  const searchQuery = getSearchQuery({ filter, includes });

  const endpoint = `/financials/invoices${
    searchQuery ? `?${searchQuery}` : ''
  }`;

  const request = await api.get(endpoint, {
    search: searchTerm,
    per_page: perPage
  });

  return request.data as Invoice[];
}
