import { ContentConfig } from 'view/components/record-screen/types';
import { overviewBlock } from '../blocks/overview';
import { propertiesBlock } from '../blocks/properties';
import { membersBlock } from '../blocks/members';
import { taskAssignmentBlock } from '../blocks/task-assignment';

export const useContent = (): ContentConfig => {
  return [
    {
      label: 'General',
      items: [
        {
          id: 'overview',
          label: 'Overview',
          blocks: [
            overviewBlock,
            membersBlock,
            taskAssignmentBlock,
            propertiesBlock
          ]
        }
      ]
    }
  ];
};
