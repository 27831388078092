import React from 'react';

import Box from '@rexlabs/box';
import LoadingSpinner from '@rexlabs/loading-spinner';
import { ErrorBanner } from 'view/components/@luna/notifications/banner';
import { BlockConfig } from 'view/components/record-screen/types';
import {
  SMSPhonePreview,
  SMSPhonePreviewContainer
} from '../../common/components/sms-phone-preview';
import { useResolvedMessageTexts } from '../hooks/use-resolved-message-texts';
import { Message } from '../types/Message';
import { getContextTypeFromRecord } from '../utils/get-context-type-from-record';

export const smsPreviewBlock: BlockConfig<Message> = {
  id: 'preview',
  title: 'Preview message',
  Edit: ({ values }) => {
    const context = {
      type: { id: values?.relates_to?.record?.__record_type },
      record: { id: values?.relates_to?.record?.id }
    };

    const selectedRecipients = values?.recipients?.map((item) => ({
      id: item.id
    }));

    const {
      resolvedTexts: [content],
      invalidTags,
      isLoading,
      isError,
      error
    } = useResolvedMessageTexts(
      [values.smsContent ?? ''],
      context,
      getContextTypeFromRecord(values?.relates_to?.record),
      selectedRecipients,
      values?.sent_from,
      { id: 'sms' }
    );

    const errorMessage = React.useMemo(() => {
      if (invalidTags.length > 0) {
        return `This message contains invalid merge tags: ${invalidTags.join(
          ', '
        )}`;
      }

      if (isError) {
        return (error as Error).message;
      }
    }, [invalidTags, isError, error]);

    if (isLoading) {
      return (
        <Box
          alignItems='center'
          justifyContent='center'
          width='100%'
          padding={48}
        >
          <LoadingSpinner size={30} strokeWidth={2} />
        </Box>
      );
    }

    return (
      <>
        {errorMessage && (
          <Box marginTop={12}>
            <ErrorBanner description={errorMessage} />
          </Box>
        )}

        <SMSPhonePreviewContainer>
          <SMSPhonePreview>{content}</SMSPhonePreview>
        </SMSPhonePreviewContainer>
      </>
    );
  }
};
