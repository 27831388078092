import { FormActions } from '@rexlabs/form';
import { Contact } from 'src/modules/contacts/types/contact-types';
import { api } from 'utils/api/api-client';

async function getContactDefaultPaymentMethod({
  contactId
}: {
  contactId: string;
}) {
  const { data } = await api.get<Contact>(
    `/contacts/${contactId}?include=default_payment_method`
  );

  return data?.default_payment_method;
}

export async function setContactPaymentInformationFormValues(
  contact: Contact,
  fieldPrefix: string,
  setFieldHandler?: FormActions['setFieldValue']
) {
  const defaultPaymentMethod = await getContactDefaultPaymentMethod({
    contactId: contact.id
  });

  switch (defaultPaymentMethod?.payment_method?.id) {
    case 'eft':
    case 'eft_fps':
    case 'eft_bacs':
      setFieldHandler?.(
        `${fieldPrefix}.method`,
        defaultPaymentMethod.payment_method
      );
      setFieldHandler?.(
        `${fieldPrefix}.account_number`,
        defaultPaymentMethod?.account_number
      );
      setFieldHandler?.(
        `${fieldPrefix}.branch_code`,
        defaultPaymentMethod?.branch_code
      );
      break;
    case 'bpay':
      setFieldHandler?.(
        `${fieldPrefix}.method`,
        defaultPaymentMethod.payment_method
      );
      setFieldHandler?.(
        `${fieldPrefix}.account_number`,
        defaultPaymentMethod?.account_number
      );
      break;
    case 'eft_iban':
      setFieldHandler?.(
        `${fieldPrefix}.method`,
        defaultPaymentMethod.payment_method
      );
      setFieldHandler?.(
        `${fieldPrefix}.account_number`,
        defaultPaymentMethod?.account_number
      );
      break;
    case 'eft_swift':
      setFieldHandler?.(
        `${fieldPrefix}.method`,
        defaultPaymentMethod.payment_method
      );
      setFieldHandler?.(
        `${fieldPrefix}.account_number`,
        defaultPaymentMethod?.account_number
      );
      break;
    default:
      setFieldHandler?.(fieldPrefix, null);
      break;
  }
}
