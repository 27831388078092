import { useDialog } from '@rexlabs/dialog';
import { ActionButtons } from 'view/components/action-buttons';
import React from 'react';
import { BlockConfig } from 'view/components/record-screen/types';
import { Property } from 'src/modules/properties/types/property-types';

import EmptyTable from 'assets/illustrations/empty-table.svg';

import { Message } from 'view/components/@luna/message';
import { EmptyCard } from 'view/components/record-screen/cards/empty-card';

import { RecordTable } from 'view/components/table';

import { query } from '@rexlabs/model-generator';
import { toQuri } from '@rexlabs/quri';
import { transformActionDeclarationsToActionMenuItems } from 'utils/actions/transforms';
import { CreateApplianceRecordDialog } from '../dialogs/create-appliance-record-dialog';
import { applianceColumns } from '../utils/appliance-columns';
import { appliancesModel } from '../models/appliances-model';
import { useGetApplianceActions } from '../hooks/action-menu-items/use-get-appliance-actions';

const getAppliancesQuery = (propertyId: string) => {
  const filter = toQuri([
    {
      field: 'property_id',
      op: '==',
      value: propertyId
    }
  ]);

  return query`{
    ${appliancesModel} (q: ${filter}) {
      id
      warranties
      manuals
      property
    }
  }`;
};

export const applianceTableBlock: BlockConfig<Property> = {
  id: 'appliances',
  title: 'Appliances',
  View: ({ data }) => {
    const query = getAppliancesQuery(data!.id);
    const actions = useGetApplianceActions();

    return (
      <RecordTable
        withToolbar={false}
        getQuery={() => query}
        withPagination={true}
        id='appliances'
        columns={applianceColumns}
        getActionMenuItems={({ item }) =>
          transformActionDeclarationsToActionMenuItems(actions(item))
        }
      />
    );
  },
  showEmpty: (data) => {
    return !data?.appliances?.data?.length;
  },
  Empty: ({ data }) => {
    const { open: openCreateDialog } = useDialog(CreateApplianceRecordDialog);

    return (
      <EmptyCard>
        <Message
          title={'Appliances'}
          Illustration={EmptyTable}
          actions={[
            {
              label: 'Add appliance',
              intent: 'primary',
              handleAction: () =>
                openCreateDialog({
                  initialValues: {
                    property: data,
                    manuals: [],
                    warranties: []
                  }
                })
            }
          ]}
        >
          <div>
            Add any appliances for this property to save receipts and manuals.
          </div>
        </Message>
      </EmptyCard>
    );
  },
  Actions: ({ data }) => {
    const { open: openCreateDialog } = useDialog(CreateApplianceRecordDialog);

    if (!data) {
      return null;
    }

    return (
      <ActionButtons
        actions={[
          {
            label: 'Add appliance',
            onClick: () => {
              openCreateDialog({
                initialValues: {
                  property: data,
                  manuals: [],
                  warranties: []
                }
              });
            }
          }
        ]}
      />
    );
  }
};
