import React, { useCallback, useMemo } from 'react';

import { RecordDialog } from 'view/components/record-screen/dialog/dialog';
import { DialogContentConfig } from 'view/components/record-screen/dialog/types';

import { RecordSubmitHandler } from 'view/components/record-screen/utils';
import {
  query,
  useEntityListQuery,
  useModelActions
} from '@rexlabs/model-generator';
import { filterNullValues } from 'utils/filter-null-values';
import { trustLedgerModel } from 'src/modules/trust-ledgers/models/trust-ledger-model';
import { bankAccountModel } from 'data/models/entities/financials/bank-accounts';
import { RecordTypeObject } from 'data/models/types';
import { useToast } from 'view/components/@luna/notifications/toast';
import { TrustLedger } from 'src/modules/trust-ledgers/types/trust-ledger-types';
import { createTrustLedgerBlock } from '../blocks/create-trust-ledger';

const bankAccountsQuery = query`{
  ${bankAccountModel} {
    id
    name
  }
}`;

const content: DialogContentConfig = [
  {
    id: 'basics',
    label: 'Basics',
    blocks: [createTrustLedgerBlock]
  }
];

interface CreateTrustLedgerDialogProps {
  object?: {
    id: string;
    label: string;
    type: RecordTypeObject;
  };
  onClose?: () => void;
  onCreate?: (item: TrustLedger) => void;
}

export function CreateTrustLedgerDialog({
  object,
  onClose
}: CreateTrustLedgerDialogProps) {
  const { addToast } = useToast();

  const bankAccounts = useEntityListQuery(bankAccountsQuery);
  const { createItem, refreshLists } = useModelActions(trustLedgerModel);

  const handleSubmit: RecordSubmitHandler = useCallback(
    async ({ values }) => {
      const response = await createItem({ data: filterNullValues(values) });

      addToast({ description: 'Successfully added trust ledger' });
      await refreshLists();
      return response.data;
    },
    [createItem, refreshLists]
  );

  const initialData = useMemo(() => {
    if (bankAccounts.data) {
      const defaultBankAccount = bankAccounts.data.find(
        (bankAccount) => bankAccount.is_default
      );

      return {
        object,
        is_default: false,
        bank_account: defaultBankAccount
      };
    }

    return {
      object,
      is_default: false
    };
  }, [bankAccounts.data]);

  return (
    <RecordDialog
      onClose={onClose}
      title='Add trust ledger'
      isLoading={bankAccounts.status === 'loading'}
      data={initialData}
      handleSubmit={handleSubmit}
      content={content}
    />
  );
}
