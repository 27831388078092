import { LeaseReviewFormData } from '../types/LeaseReviewFormData';
import { LeaseReview } from '../types/LeaseReview';

const getDetailItems = (
  details: LeaseReview['details']
): LeaseReviewFormData['details'] => {
  return {
    property_tenancy: details?.property_tenancy || null,
    status: details?.status
  };
};

export function mapLeaseReviewTaskToLeaseReviewFormData(
  task: LeaseReview
): LeaseReviewFormData {
  const { details, property, managed_by, ...taskData } = task;

  return {
    ...taskData,
    property: property || null,
    managed_by: managed_by || null,
    details: {
      ...getDetailItems(details)
    }
  };
}
