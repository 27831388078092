import React, { useCallback } from 'react';

import { RecordDialog } from 'view/components/record-screen/dialog/dialog';
import { DialogContentConfig } from 'view/components/record-screen/dialog/types';
import { BlockConfig } from 'view/components/record-screen/types';
import { RecordSubmitHandler } from 'view/components/record-screen/utils';

const contentBlock: BlockConfig = {
  id: 'details',
  Edit: ({ blockProps }) => {
    return (
      <div>
        <p>
          You have changed the &apos;{blockProps.changedField}&apos; in this
          invoice. Because of this, the reimbursement invoice has been removed.
        </p>

        <p>Do you want to create a new reimbursement invoice?</p>
      </div>
    );
  }
};

const content: DialogContentConfig = [
  {
    id: 'content',
    label: 'content',
    blocks: [contentBlock]
  }
];

interface ReimbursementWarningDialogProps {
  changedField: string;
  onSubmit: () => void;
  onCancel: () => void;
  onClose?: () => void;
}

export function ReimbursementWarningDialog({
  changedField,
  onSubmit,
  onCancel,
  onClose
}: ReimbursementWarningDialogProps) {
  const handleSubmit: RecordSubmitHandler = useCallback(async () => {
    onSubmit();

    onClose?.();

    return false;
  }, [onSubmit, onClose]);

  const handleClose = React.useCallback(() => {
    onCancel?.();
    onClose?.();

    return true;
  }, [onCancel, onClose]);

  return (
    <RecordDialog
      onClose={handleClose}
      title={'Reimbursement invoice removed'}
      handleSubmit={handleSubmit}
      content={content}
      blockProps={{ changedField }}
      submitLabel='Yes, create a new reimbursement invoice'
    />
  );
}
