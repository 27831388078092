export const BANK_ACCOUNT_ROUTES = {
  BANK_ACCOUNTS: {
    config: {
      path: '/bank-accounts',
      Component: require('./screens/bank-accounts-list').BankAccountsListScreen
    },
    CREATE: {
      config: {
        path: '/bank-accounts/create',
        Component: require('./dialogs/create-bank-account-dialog')
          .CreateBankAccountDialog
      }
    }
  },
  BANK_ACCOUNT_DETAILS: {
    config: {
      path: '/bank-account/:bankAccountId',
      Component: require('./screens/bank-account-details')
        .BankAccountDetailsScreen
    }
  }
};
