import {
  ReconcileItem,
  ReconcileItemsPayload
} from 'src/modules/banking/bank-deposits/models/bank-deposit-model';

type GenericItem = {
  id: string;
};

export function getReconcileItemsPayload<Item extends GenericItem>(
  items: Item[],
  type: ReconcileItem['object']['type']
): ReconcileItemsPayload {
  return {
    items: items.map((item) => ({
      object: {
        type,
        id: item.id
      }
    }))
  };
}
