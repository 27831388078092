import { getSearchResultItemFromObjectAndModel } from 'src/modules/common/utils/search-result-items/get-search-result-items-from-object-and-model';
import { MaintenanceTask } from '../types/MaintenanceTask';

export function getCreateInvoiceInitialValues(data?: MaintenanceTask) {
  const payableByOwnership = getSearchResultItemFromObjectAndModel(
    data?.property?.active_property_ownership?.ownership,
    'ownership'
  );

  const payable_by = payableByOwnership
    ? { object: payableByOwnership }
    : undefined;

  return {
    payable_by,
    description: data?.description || undefined,
    line_items: [
      {
        description: data?.description || undefined
      }
    ],
    task: data
  };
}
