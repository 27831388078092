import { useDialog } from '@rexlabs/dialog';
import { ActionDeclaration } from 'src/modules/common/actions/types/action-declaration-types';
import { FixChannelMessageRecipientIssuesRecordDialog } from '../../dialogs/fix-channel-message-recipient-issues-record-dialog';
import { ValidationType } from '../../models/messages-model';
import { ChannelMessageRecipient } from '../../types/ChannelMessageRecipient';

export function useGetUpdateContactAndResendMessageAction() {
  const { open: openFixIssuesDialog } = useDialog(
    FixChannelMessageRecipientIssuesRecordDialog
  );
  return (
    channelMessageRecipient: ChannelMessageRecipient,
    type: ValidationType
  ): ActionDeclaration => {
    return {
      id: 'update-contact-and-resend-message',
      group: 'messages',
      label: 'Update contact and resend message',
      handleAction: async () => {
        openFixIssuesDialog({
          type,
          data: { channelMessageRecipient: channelMessageRecipient }
        });
      }
    };
  };
}
