import React, { useEffect, useMemo } from 'react';
import { get } from 'lodash';

import Box from '@rexlabs/box';
import { OutlineButton } from '@rexlabs/button';
import { Field, FieldArray } from '@rexlabs/form';
import { Heading } from '@rexlabs/text';
import { NormalisedItem } from '@rexlabs/select';
import { RadioGroupInput } from '@rexlabs/radio-input';
import { Tooltip } from '@rexlabs/tooltip';
import { useToken } from '@rexlabs/styling';

import { Contact } from 'src/modules/contacts/types/contact-types';
import { getOwnerContacts } from 'src/modules/ownerships/utils/get-owner-contacts';
import { getNewDisbursements } from 'src/modules/disbursements/utils/get-new-disbursements';
import { useSessionState } from 'src/modules/common/hooks/session-state';

import { Owner, Ownership } from 'data/models/entities/ownerships';

import { BlockConfig } from 'view/components/record-screen/types';
import { ContactSelect } from 'view/components/inputs/selects/contact';
import { AddIcon } from 'view/components/icons/add';
import { RecordTable, Columns } from 'view/components/table';
import { PercentageInput } from 'view/components/inputs/percentage-input';
import InfoCircleIcon from 'view/components/icons/info';
import { LineItemRemoveButton } from 'view/components/line-items/line-item-remove-button';
import { Value } from 'view/components/values';

import { filterNormalisedList } from 'utils/form/filter-normalised-list';

import { getEvenSharePercentage } from '../utils/get-even-share-percentage';

type OwnershipAndIncomeAttributionForm = Ownership & {
  set_attribution: 'default' | 'custom';
};

export const ownersAndIncomeAttributionBlock: BlockConfig<
  Ownership,
  any,
  OwnershipAndIncomeAttributionForm
> = {
  id: 'ownersAndIncomeAttribution',
  title: 'Owners & income attribution',
  validate: {
    definitions: {
      'owners.*.contact': { rules: 'required', name: 'contact' },
      'owners.*.share': {
        rules: [
          'required',
          {
            key: 'check-100-percent',
            type: 'global',
            rule:
              'notMoreThan100Percent:owners,share|notLessThan100Percent:owners,share'
          }
        ]
      }
    }
  },
  View: ({ data }) => {
    const { activeSilo } = useSessionState();

    const columns: Columns = useMemo(
      () => [
        {
          id: 'contact.display_name',
          Header: 'Name'
        },
        {
          id: 'share',
          Header: 'Income attribution',
          accessor: (item: Owner) => `${item.share}%`
        },
        ...(activeSilo?.country?.id === 'GBR'
          ? ([
              {
                id: 'contact.tax.non_resident_withhold_tax',
                Header: 'Withhold HMRC tax',
                type: 'boolean'
              }
            ] as Columns)
          : [])
      ],
      [activeSilo]
    );

    return (
      <RecordTable
        id='ownersAndIncomeAttribution'
        items={data?.owners || []}
        columns={columns}
        getRowId={(item) => item?.contact?.id}
        isLoading={false}
      />
    );
  },
  Edit: ({ values, forms, setFieldValue }) => {
    const token = useToken();
    const selectedOwners: Contact[] = getOwnerContacts(values?.owners);

    // Add a disbursement method for each owner as we add them
    function handleChangeOwner(
      e: React.ChangeEvent<HTMLInputElement>,
      index: number
    ) {
      const disbursementForm = forms?.['ownership-disbursement-preferences'];
      if (!disbursementForm) {
        return;
      }

      const newContact = e?.target?.value;
      const prevContact = values?.owners?.[index].contact;
      const prevDisbursements =
        disbursementForm.values?.disbursement_payment_methods || [];

      const newDisbursements = getNewDisbursements({
        newContact,
        prevContact,
        prevDisbursements
      });

      disbursementForm.setFieldValue(
        'disbursement_payment_methods',
        newDisbursements
      );
    }

    const setSharePercentage = () => {
      const owners = values?.owners || [];
      const evenSplit = getEvenSharePercentage(owners.length);

      setFieldValue?.(
        'owners',
        owners.map((owner, index) => ({
          ...owner,
          share: evenSplit[index]
        }))
      );
    };

    // On init, we need to work out whether the share value is the default (evenly split) or custom
    // Once we have that info, we need to set the 'set_attribution' field to the correct value.
    useEffect(() => {
      const owners = values?.owners || [];
      const shares = owners.map((owner) => owner.share);
      const evenShare = getEvenSharePercentage(owners.length);

      if (shares.every((share, index) => share === evenShare[index])) {
        setFieldValue?.('set_attribution', 'default');
      } else {
        setFieldValue?.('set_attribution', 'custom');
      }
    }, []);

    // We need to watch the owners field and set the share values when we add or remove owners
    useEffect(() => {
      if (
        values?.set_attribution === 'default' &&
        (values?.owners || []).length > 0
      ) {
        setSharePercentage();
      }
    }, [values?.owners?.length, values?.set_attribution]);

    return (
      <Box flexDirection='column' rowGap={token('spacing.l')}>
        <Box
          flexDirection='row'
          columnGap={token('spacing.xs')}
          alignItems='center'
        >
          <Heading level={3}>Income attribution</Heading>
          <Tooltip
            Content={() =>
              'Attribute income and expenses for tax purposes. Disbursement splits can be based on the attributed income or set independently.'
            }
          >
            <Box flexDirection='row' alignItems='center' width={24} height={24}>
              <InfoCircleIcon size={'m'} />
            </Box>
          </Tooltip>
        </Box>
        <Field<typeof RadioGroupInput>
          name='set_attribution'
          Input={RadioGroupInput}
          inputProps={{
            defaultValue: 'default',
            options: [
              {
                label: 'Attribute evenly between all of owners',
                value: 'default'
              },
              { label: 'Set custom attribution', value: 'custom' }
            ]
          }}
          optional={false}
        />

        <Heading level={3}>Owners</Heading>
        <FieldArray name='owners'>
          {function LineItem({ fields, push }) {
            useEffect(() => {
              if (fields.length === 0) {
                push({ is_primary_owner: true, share: 1 });
              }
            }, []);

            return (
              <Box sy='2.4rem'>
                {fields?.map?.(({ field, actions }, index) => {
                  return (
                    <Box
                      data-testid={`row-${index}`}
                      key={field.name}
                      flexDirection='row'
                      alignItems='flex-start'
                      sx='2.4rem'
                    >
                      <Field
                        id='owner'
                        name={`${field.name}.contact`}
                        label='Owner'
                        Input={ContactSelect}
                        onChange={(e) => handleChangeOwner(e, index)}
                        inputProps={{
                          filter: (normalisedItem: NormalisedItem) => {
                            return filterNormalisedList(
                              normalisedItem,
                              selectedOwners
                            );
                          }
                        }}
                      />
                      {values?.set_attribution === 'default' ? (
                        <Box width='100%'>
                          <Value
                            label='Income attribution'
                            value={`${get(get(values, field.name), 'share')}%`}
                          />
                        </Box>
                      ) : (
                        <Field
                          id='share'
                          name={`${field.name}.share`}
                          label='Income attribution'
                          Input={PercentageInput}
                        />
                      )}
                      <Box mt='1.6rem'>
                        <LineItemRemoveButton
                          onClick={actions.remove}
                          isDisabled={fields.length === 1}
                        />
                      </Box>
                    </Box>
                  );
                })}

                <OutlineButton
                  onClick={() => push({ is_primary_owner: false, share: 0 })}
                  IconLeft={AddIcon}
                >
                  Add another owner
                </OutlineButton>
              </Box>
            );
          }}
        </FieldArray>
      </Box>
    );
  }
};
