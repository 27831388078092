import dayjs from 'dayjs';

import { valueListFactory } from 'src/lib/testing/factories/value-list-factory';

import { mapTaskFormDataToTaskCreateRequest } from '../../common/mappers/map-task-form-data-to-task-create-request';
import { TaskCreateRequest } from '../../common/types/TaskCreateRequest';
import { InspectionFormData } from '../types/InspectionFormData';
import { INSPECTION_SAVE_REQUEST_DATE_FORMAT } from '../constants/InspectionSaveRequestDateFormat';

export function mapInspectionFormDataToInspectionCreateRequest(
  formData: Partial<InspectionFormData>
): Partial<TaskCreateRequest> {
  const { status, property_tenancy, inspection_type, ...rest } = formData;
  // map the base values for the task
  const base = mapTaskFormDataToTaskCreateRequest(rest);

  const type = inspection_type ? { type: { id: inspection_type } } : {};

  const [hours, minutes] = (formData.start_time || '').split(':');

  const details = {
    status,
    property_tenancy,
    scheduled_at: formData.inspection_date
      ? dayjs(formData.inspection_date)
          .tz()
          .add(parseInt(hours), 'hours')
          .add(parseInt(minutes), 'minutes')
          .format(INSPECTION_SAVE_REQUEST_DATE_FORMAT)
      : undefined,
    scheduled_duration: formData.duration?.id,

    ...type
  };
  // summary is a required field on BE but doesn't exist for inspections on FE
  const summary = inspection_type
    ? {
        summary: `${valueListFactory(inspection_type)?.label}: ${
          formData.property?.display_name
        }`
      }
    : {};

  return {
    ...summary,
    ...base,
    type: { id: 'task_inspection', label: 'Inspection' },
    details
  };
}
