import { useDialog } from '@rexlabs/dialog';
import { useToast } from 'view/components/@luna/notifications/toast';
import React from 'react';
import { SingleActionDeclaration } from 'src/modules/common/actions/types/action-declaration-types';
import { AgencyFee } from 'src/modules/agency-fees/types/agency-fee-types';
import { EditAgencyFeeDialog } from '../dialogs/edit-agency-fee';

export function useGetEditAction() {
  const { open: openEditAgencyFeeDialog } = useDialog(EditAgencyFeeDialog);
  const { addToast } = useToast();

  return (agencyFee: AgencyFee): SingleActionDeclaration => {
    return {
      id: 'agency_fee-edit',
      group: 'edit',
      label: 'Edit',
      handleAction: () => {
        openEditAgencyFeeDialog({
          agencyFee,
          onSubmit: (editedAgencyFee) =>
            addToast({
              description: (
                <>
                  <b>{editedAgencyFee.name}</b> has been updated
                </>
              )
            })
        });
      }
    };
  };
}
