import { useConfirmationDialog } from '@rexlabs/dialog';
import { ConfirmationDialogProps } from '@rexlabs/dialog/lib/types/dialogs/confirmation';
import {
  BaseModelGeneratorModel,
  EntityModel,
  useModelActions
} from '@rexlabs/model-generator';
import React from 'react';
import { useApiClient } from 'src/lib/axios/hooks/use-api-client';
import { SingleActionDeclaration } from 'src/modules/common/actions/types/action-declaration-types';
import { modelTypeToUrl } from 'src/modules/common/utils/ModelGenerator/model-type-to-url';
import { getRecordObject } from 'src/modules/common/utils/Records/get-record-object';
import { getRecordLabel } from 'utils/records/get-record-label';
import { useToast } from 'view/components/@luna/notifications/toast';
import WarningCircleIcon from 'view/components/icons/warning-circle';

export interface DeleteActionConfig
  extends Pick<ConfirmationDialogProps, 'confirmText' | 'title' | 'size'> {
  afterAction?: () => any;
  label?: string;
  message?: React.ReactNode;
  toastText?: string;
}

export const useDeleteAction = (model: EntityModel<any>) => {
  const axios = useApiClient();
  const { open: openConfirmationDialog } = useConfirmationDialog();
  const { refreshLists } = useModelActions(model);
  const { addToast } = useToast();

  return function (
    record: BaseModelGeneratorModel,
    config: DeleteActionConfig = {}
  ): SingleActionDeclaration {
    const recordObject = getRecordObject(record, model);
    return {
      id: `delete`,
      group: 'delete',
      label: config?.label ?? 'Delete',
      intent: 'danger',
      handleAction: async () => {
        openConfirmationDialog({
          TitleIcon: WarningCircleIcon,
          destructive: true,
          ...(config?.size ? { size: config?.size } : {}),
          title: config.title ?? `Delete ${getRecordLabel(recordObject)}`,
          confirmText:
            config.confirmText ?? `Delete ${getRecordLabel(recordObject)}`,
          message: config.message ?? (
            <div>
              Are you sure you want to delete the record for{' '}
              <strong>{getRecordLabel(recordObject)}</strong>?
            </div>
          ),
          onConfirm: async () => {
            const res = await axios(
              'delete',
              `${modelTypeToUrl(model.modelName)}/${record.id}`
            );
            if (res !== false) {
              addToast({
                description:
                  config.toastText || `${getRecordLabel(recordObject)} deleted`,
                type: 'error'
              });
              refreshLists();

              if (config.afterAction) {
                config.afterAction();
              }
            }
          }
        });
      }
    };
  };
};
