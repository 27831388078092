import * as React from 'react';
import { StatusTagMap } from 'src/modules/common/components/status-tag';
import { ArrearsStatus } from '../types/ArrearsStatus';
import { TaskStatusSelect } from '../../common/components/task-status-select';

const taskStatusMap: StatusTagMap<ArrearsStatus> = {
  not_started: 'neutral',
  in_progress: 'average',
  resolved: 'good'
};

export function ArrearsProgressSelect(props) {
  return (
    <TaskStatusSelect
      taskStatusMap={taskStatusMap}
      valueListId={'task_arrears_status'}
      {...props}
    />
  );
}
