import React, { ComponentProps } from 'react';
import { lowerCase } from 'lodash';

import { Field } from '@rexlabs/form';
import { Checkbox } from '@rexlabs/checkbox';
import Box from '@rexlabs/box';
import { useToken } from '@rexlabs/styling';
import { Body, Heading } from '@rexlabs/text';

import { DocumentTypeSelect } from 'view/components/inputs/selects/document-type';
import { ComplianceTypeSelect } from 'view/components/inputs/selects/v4-selects/compliance-type';
import { Grid } from 'view/components/@luna/form/grid';
import { SUPPORTED_RECORD_TYPES_FOR_CERTIFICATES_FROM_RECORD } from '../../../constants/SupportedRecordTypesForCertificates';

export function AttachFilesFromRecord(
  props: ComponentProps<typeof DocumentTypeSelect>
) {
  const { recordType } = props;
  const token = useToken();

  const objectType = SUPPORTED_RECORD_TYPES_FOR_CERTIFICATES_FROM_RECORD.find(
    (type) => type === recordType
  );

  return (
    <Box flexDirection='column' rowGap={token('spacing.xs')}>
      <div>
        <Heading level={3}>Attach files from records</Heading>
        <Body grey>
          Documents of the selected types from related records can be
          automatically attached to this template.
        </Body>
      </div>
      <Grid columns={1}>
        <Box flexDirection='column' rowGap={token('spacing.xs')}>
          <Field<typeof DocumentTypeSelect>
            id='document_types'
            name='document_types'
            label={`Documents from ${lowerCase(recordType)} record`}
            Input={DocumentTypeSelect}
            inputProps={{
              multi: true
            }}
          />
          <Field<typeof Checkbox>
            id='send_if_no_documents_found_with_related_types'
            name='send_if_no_documents_found_with_related_types'
            optional={false}
            Input={Checkbox}
            inputProps={
              {
                'data-testid': 'send_if_no_documents_found_with_related_types',
                label: 'Send even if no documents are found',
                description:
                  'If unchecked, at least one document of the selected type(s) must be found before the message will be sent. If none are found, the message will be diverted to the outbox.'
              } as any
            }
          />
        </Box>
        {objectType ? (
          <Box flexDirection='column' rowGap={token('spacing.xs')}>
            <Field<typeof ComplianceTypeSelect>
              id='compliance_types'
              name='compliance_types'
              label={`Certificates from ${lowerCase(objectType)} record`}
              Input={ComplianceTypeSelect}
              inputProps={{
                multi: true,
                objectType
              }}
              HelpTooltipContent={() => (
                <>Expired certificates will not be attached</>
              )}
            />
            <Field<typeof Checkbox>
              id='send_if_no_compliance_entries_found_with_related_types'
              name='send_if_no_compliance_entries_found_with_related_types'
              optional={false}
              Input={Checkbox}
              inputProps={
                {
                  'data-testid':
                    'send_if_no_compliance_entries_found_with_related_types',
                  label: 'Send even if no certificates are found',
                  description:
                    'If unchecked, at least one document of the selected type(s) must be found before the message will be sent. If none are found, the message will be diverted to the outbox.'
                } as any
              }
            />
          </Box>
        ) : null}
      </Grid>
    </Box>
  );
}
