import React from 'react';
import { RadioGroupInputProps, RadioGroupInput } from '@rexlabs/radio-input';
import { useApiValueList } from 'view/hooks/api/use-api-value-list';
import LoadingSpinner from '@rexlabs/loading-spinner';

export function ChecklistItemDueDateType(
  props: Omit<RadioGroupInputProps, 'meta'> & {
    allowSpecificDate: boolean;
  }
) {
  const { allowSpecificDate } = props;

  const { isLoading, data } = useApiValueList('checklist_item_due_date_type');

  if (isLoading || !data)
    return (
      <div className='flex items-center' style={{ height: '4rem' }}>
        <LoadingSpinner size={16} strokeWidth={3} />
      </div>
    );

  const options = data
    ?.map((valueListValue) => ({
      value: valueListValue.id,
      label: valueListValue.label!
    }))
    .filter((valueListValue) =>
      allowSpecificDate ? true : valueListValue.value !== 'specific_date'
    );

  return <RadioGroupInput {...props} options={options} />;
}
