import React from 'react';
import { BlockConfig } from 'view/components/record-screen/types';
import { ValueListValue } from 'data/models/types';
import { TaskType } from 'src/modules/tasks/common/types/TaskType';
import { Field } from '@rexlabs/form';
import invariant from 'invariant';
import { ChecklistItemTriggerInput } from 'view/components/inputs/checklist-item-trigger-input/checklist-item-trigger-input';
import { Grid } from 'view/components/@luna/form/grid';
import { Select } from '@rexlabs/select';
import { ChannelTypeId } from 'src/modules/communications/common/types/channel';
import { MessageTemplateContent } from '../../components/smart-checklists/message-template-content';
import { ChecklistTemplateItem } from '../../types/ChecklistTemplate';
import {
  ChecklistTemplatesForm,
  ChecklistTemplatesItemForm
} from '../../types/ChecklistTemplatesForm';
import '../../utils/checklist-item-validation-rules';

export const checklistTemplateItemMessageDetailsBlock: BlockConfig<
  ChecklistTemplateItem,
  {
    taskType: TaskType;
    mainValues: Partial<ChecklistTemplatesForm>;
  },
  ChecklistTemplatesItemForm & { new_outcome_option: string }
> = {
  id: 'checklist-template-item-message-details',
  validate: {
    definitions: {
      trigger: { rules: 'cannotBeOnDueDateIfDueDateIsNone' }
    }
  },
  title: 'Message details',
  Edit({ blockProps, values, setFieldValue }) {
    const channelType = values?.message_channel_types?.[0] ?? undefined;
    const triggerValue = values?.trigger;

    invariant(blockProps, 'blockProps is required');
    invariant(blockProps.taskType, 'taskType is required');

    return (
      <Grid columns={2}>
        <Field
          name='trigger'
          label='Send message'
          Input={ChecklistItemTriggerInput}
          optional={false}
          HelpTooltipContent={
            triggerValue?.trigger_type?.id === 'on_due_date'
              ? () =>
                  'If the subtask due date and time has already passed when the task is created, then the action will trigger immediately.'
              : undefined
          }
        />
        {/* TODO: in future when we support multi-channel sending, rework this to allow recipient channel preferences, and multiple channel sending */}
        <Field
          id='action'
          name='message_channel_types.0'
          label='Message type'
          Input={Select}
          onChange={() => {
            setFieldValue?.('template', null);
          }}
          inputProps={{
            searchable: false,
            placeholder: 'Select a message type',
            normaliser: (item) => item,
            items: [
              { id: 'email', label: 'Email' },
              { id: 'sms', label: 'SMS' },
              { id: 'letter', label: 'Letter' }
            ] as ValueListValue<ChannelTypeId>[],
            disabled: !(
              (triggerValue?.trigger_type?.id === 'on_due_date' &&
                triggerValue?.time) ||
              triggerValue?.trigger_type?.id === 'on_completion'
            )
          }}
        />
        <MessageTemplateContent
          channelType={channelType}
          taskType={blockProps.taskType}
        />
      </Grid>
    );
  }
};
