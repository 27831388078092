import { useCallback } from 'react';
import { useErrorDialog } from '@rexlabs/dialog';
import { useModelActions } from '@rexlabs/model-generator';
import { useToast } from 'view/components/@luna/notifications/toast';
import { statementsModel } from 'src/modules/statements/models/statements';
import { Statement, StatementStatus } from 'src/modules/statements/types';

export type UpdateStatementsArgs = {
  statement: Statement;
  data: {
    status?: {
      id: StatementStatus;
    };
    status_reason?: string;
    ignore_agency_fees?: boolean;
  };
};

export const useUpdateStatement = () => {
  const { updateItem } = useModelActions(statementsModel);
  const errorDialog = useErrorDialog();
  const { addToast } = useToast();
  const { refreshLists: refreshStatementLists } = useModelActions(
    statementsModel
  );

  return useCallback(
    function actionStatements(updateStatementsArgs: UpdateStatementsArgs) {
      const handleConfirmation = async ({
        statement,
        data
      }: UpdateStatementsArgs) => {
        try {
          await updateItem({ id: statement.id, data });

          refreshStatementLists();

          addToast({
            description: 'Statement has been updated',
            color: 'information'
          });

          return true;
        } catch (error) {
          errorDialog.open(error);
        }
      };

      return handleConfirmation(updateStatementsArgs);
    },
    [refreshStatementLists, addToast, updateItem, errorDialog]
  );
};
