import React from 'react';
import { StyleSheet, useStyles, margin, border } from '@rexlabs/styling';

const defaultStyles = StyleSheet({
  hr: {
    ...margin.styles({
      y: 'l'
    }),
    ...border.styles({
      top: {
        width: 'thin',
        color: 'container.static.light'
      },
      right: {
        width: 'none'
      },
      bottom: {
        width: 'none'
      },
      left: {
        width: 'none'
      }
    })
  }
});

export function Divider() {
  const s = useStyles(defaultStyles);
  return <hr {...s('hr')} />;
}
