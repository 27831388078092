import { ChecklistTemplateItem } from '../types/ChecklistTemplate';
import { ChecklistTemplateItemTriggerEditFrom } from '../types/ChecklistTemplatesForm';

/**
 * Converts an checklist template trigger item form data object to a checklist template item trigger.
 */
export function mapTriggerFormDataToTriggerItem({
  checklistTemplateTriggerFormData
}: {
  checklistTemplateTriggerFormData: Partial<ChecklistTemplateItemTriggerEditFrom>;
}): Partial<ChecklistTemplateItem> {
  const time = checklistTemplateTriggerFormData.trigger?.time;

  const messageTemplateId = checklistTemplateTriggerFormData.template?.id;
  const triggerType = checklistTemplateTriggerFormData.trigger?.trigger_type;

  const actionData: Partial<ChecklistTemplateItem['action_data']> = {};
  if (messageTemplateId) {
    actionData.message_template_id = messageTemplateId;
  }
  if (checklistTemplateTriggerFormData.task_status?.id) {
    actionData.task_status = checklistTemplateTriggerFormData.task_status;
  }
  if (checklistTemplateTriggerFormData.message_channel_types) {
    actionData.message_channel_types =
      checklistTemplateTriggerFormData.message_channel_types;
  }

  return {
    trigger_type: triggerType,
    action_type: checklistTemplateTriggerFormData.action,
    action_data: actionData,
    action_message_template: checklistTemplateTriggerFormData.template,
    trigger_data:
      time && triggerType?.id === 'on_due_date'
        ? {
            time_hour: time.id
          }
        : null
  };
}
