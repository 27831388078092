import { ActionDeclaration } from 'src/modules/common/actions/types/action-declaration-types';
import { useGetViewSupplierCommissionTemplateAction } from '../action-declarations/use-get-view-supplier-commission-template-action';
import { FolioCommissionTemplate } from '../../types/FolioCommissionTemplate';

export function useGetSupplierCommissionTemplateActions() {
  const getViewSupplierCommissionTemplateAction = useGetViewSupplierCommissionTemplateAction();

  return (template: FolioCommissionTemplate): ActionDeclaration[] => {
    return [getViewSupplierCommissionTemplateAction(template)];
  };
}
