import React, { useMemo } from 'react';
import {
  TitleBlock,
  TitleBlockProps
} from 'view/components/cards/title/title-block';
import { RecordObject, RecordTypes } from 'data/models/types';
import { getRecordTitle } from 'utils/records/get-record-title';

import ContactIcon from 'view/components/icons/contact';
import PropertyIcon from 'view/components/icons/property';
import { lowerCase } from 'lodash';
import { useStyles, StyleSheet } from '@rexlabs/styling';
import { formatDate } from 'utils/dates/format';
import WorkOrderIcon from 'view/components/icons/work-order';
import { TagConfig } from 'view/components/@luna/title-block';
import { useWorkOrderTaskStatusTag } from '../hooks/use-work-order-status-tag';
import { WorkOrderTask } from '../types/WorkOrderTask';
import { useGetWorkOrderActions } from '../hooks/action-menu-items/use-get-work-order-actions';

const type = RecordTypes.TaskWorkOrder;

type WorkOrderTitleBlockProps = {
  workOrder?: WorkOrderTask;
};

const styles = StyleSheet({
  subText: {
    color: ({ token }) => token('color.textStyle.body.default'),
    fontWeight: ({ token }) => token('typography.weight.semibold')
  }
});

export function WorkOrderTitleBlock({ workOrder }: WorkOrderTitleBlockProps) {
  const s = useStyles(styles);

  const getWorkOrderActions = useGetWorkOrderActions();
  const statusTag: TagConfig = useWorkOrderTaskStatusTag(workOrder);

  const title = getRecordTitle({ type, object: workOrder } as RecordObject);
  const actions = useMemo(() => getWorkOrderActions(workOrder), [
    getWorkOrderActions,
    workOrder
  ]);

  if (!workOrder) {
    return null;
  }

  const closed_at = workOrder.closed_at;

  const subTitles: TitleBlockProps['subTitles'] = [
    {
      Icon: PropertyIcon,
      label: workOrder.property?.display_name
    },
    {
      Icon: ContactIcon,
      label: workOrder.details?.work_done_by?.display_name
    }
  ];

  return (
    <TitleBlock
      title={title}
      subTitles={subTitles}
      type={'work-order'}
      actions={actions}
      tags={closed_at ? undefined : [statusTag]}
      Icon={() => <WorkOrderIcon size='xl' />}
      subText={
        closed_at ? (
          <div>
            <span>This work order task has been closed with the status </span>
            <span {...s('subText')}>
              {lowerCase(workOrder.status?.label)}
            </span>{' '}
            on <span {...s('subText')}>{formatDate(closed_at)}</span>
          </div>
        ) : undefined
      }
    />
  );
}
