import React from 'react';

import { Field, HiddenField } from '@rexlabs/form';
import { Body } from '@rexlabs/text';

import { BlockConfig } from 'view/components/record-screen/types';
import { Grid } from 'view/components/@luna/form/grid';

import { getLngLatFromProperty } from 'src/modules/properties/utils/get-lng-lat-from-property';

import {
  InspectionLocationIssueDisclosure,
  InspectionLocationIssueDisclosureProps
} from '../components/inspection-location-issue-disclosure';
import { InspectionTask } from '../types/InspectionTask';

export type InspectionLocationIssue = {
  issues: boolean[];
};

export type InspectionLocationIssues = Record<string, InspectionLocationIssue>;

export interface FixInspectionLocationIssuesBlockFormValues {
  issues: boolean[];
  inspections: InspectionTask[];
}

export const fixInspectionLocationIssuesBlock: BlockConfig<
  InspectionTask[],
  any,
  FixInspectionLocationIssuesBlockFormValues
> = {
  id: 'fix-contact-issues',
  validate: {
    definitions: {
      'issues.*': { rules: 'accepted' }
    },
    messages: {
      accepted: 'This location has issues that need fixing'
    }
  },
  Edit: ({ data, setFieldValue, values }) => {
    return (
      <Grid columns={1}>
        <Body>
          The location for one or more properties could not be found on the map.
          To accurately plan inspections, please manually set the location below
          before proceeding.
        </Body>

        {data?.map((inspection, index) => {
          const issueKey = `issues.${index}`;

          return (
            <>
              <Field
                key={'in'}
                name={issueKey}
                Input={InspectionLocationIssueDisclosure}
                optional={false}
                inputProps={
                  {
                    inspection: inspection,
                    inspectionIssueText: !getLngLatFromProperty(
                      inspection.property
                    )
                      ? 'Location missing'
                      : '',
                    setValue: ({ inspection, hasIssues }) => {
                      const inspectionsClone = [...values!.inspections];
                      inspectionsClone[index] = {
                        ...inspection
                      };

                      setFieldValue?.(issueKey, hasIssues);
                      setFieldValue?.('inspections', inspectionsClone);
                    }
                  } as InspectionLocationIssueDisclosureProps
                }
              />
            </>
          );
        })}

        {/* As we need to pass the updated inspections back out in the submit handler,
         the easiest way to do so is to store them in the form */}
        <HiddenField name={'inspections'} />
      </Grid>
    );
  }
};
