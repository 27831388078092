import React from 'react';
import { BlockConfig } from 'view/components/record-screen/types';
import { RecordTable, Columns } from 'view/components/table';
import { AddIcon } from 'view/components/icons/add';
import ROUTES from 'routes/app';
import { Trans, useTranslation } from 'react-i18next';

const trustReportsColumns: Columns = [
  {
    id: 'report_name',
    Header: 'Report Name'
  },
  {
    id: 'icon',
    width: 50,
    Cell: () => {
      return (
        <div>
          <AddIcon />
        </div>
      );
    }
  }
];

const useGetReportItems = () => {
  const { t } = useTranslation();
  return [
    {
      id: 'bond-report',
      report_name: t('reporting.bond-reports.label.singular')
    },
    {
      id: 'ledger-activity-report',
      report_name: 'Ledger activity report'
    },
    {
      id: 'trust-journal-report',
      report_name: t(
        'reporting.trust-reports.trust-journal-report.label.singular'
      )
    }
  ];
};

export const trustBlock: BlockConfig = {
  id: 'trust',
  title: () => <Trans i18nKey='reporting.trust-reports.label.plural' />,
  View: () => {
    const reportItems = useGetReportItems();

    const getRowLinkProps = ({ item }) => {
      if (item.id === 'trust-journal-report') {
        return {
          to: ROUTES.REPORTING_LIST.CREATE_TRUST_JOURNAL_REPORT
        };
      }

      if (item.id === 'ledger-activity-report') {
        return {
          to: ROUTES.REPORTING_LIST.CREATE_LEDGER_ACTIVITY_SUMMARY
        };
      }

      if (item.id === 'bond-report') {
        return {
          to: ROUTES.REPORTING_LIST.CREATE_BOND_REPORT
        };
      }

      return null;
    };

    return (
      <RecordTable
        withToolbar={false}
        withPagination={false}
        id='trust-reports'
        items={reportItems}
        isLoading={false}
        columns={trustReportsColumns}
        getRowLinkProps={getRowLinkProps}
      />
    );
  }
};
