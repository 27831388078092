import { RentScheduleEntry } from 'src/modules/property-tenancies/types/property-tenancy-types';
import { getRentPeriodShortForm } from 'src/modules/rent/utils/get-rent-and-frequency';
import { formatCurrency } from 'utils/formatters';

export function getRentPerPeriodString({
  rent,
  frequency
}: {
  rent: number;
  frequency: RentScheduleEntry['frequency'];
}) {
  return rent
    ? `${formatCurrency(rent)} / ${getRentPeriodShortForm(frequency)}`
    : 'empty';
}
