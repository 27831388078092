import { Column, Grid } from 'view/components/@luna/form/grid';
import { Field } from '@rexlabs/form';
import { BankAccountSelect } from 'src/modules/bank-accounts/components/bank-account-select';
import { FinancialRecordTypes } from 'utils/financials/financial-objects';
import {
  DateInput,
  disableFutureDates
} from 'view/components/@luna/inputs/date-input/date-input';
import { CurrencyValue } from 'view/components/values/currency';
import React from 'react';
import { StyleSheet, useStyles } from '@rexlabs/styling';
import { MQ_SINGLE_WIDTH } from 'view/components/@luna/form/grid/column';
import { EntitySelect } from 'view/components/inputs/selects/v4-selects/entity-select';

interface TransferFundsDetailsFormProps {
  runningAmount: number;
}

// mini internal grid so that the folio and date stay on the same row
const defaultStyles = StyleSheet({
  internalGrid: {
    display: 'grid',
    columnGap: ({ token }) => token('spacing.xl'),
    rowGap: ({ token }) => token('spacing.xl'),
    gridTemplateColumns: `1fr 1fr 1fr`,
    [`@media (max-width: ${MQ_SINGLE_WIDTH})`]: {
      gridTemplateColumns: `1fr`
    }
  },
  internalGridColumn: {
    gridColumn: 'span 2'
  }
});

export function TransferFundsDetailsForm({
  runningAmount
}: TransferFundsDetailsFormProps) {
  const s = useStyles(defaultStyles);
  return (
    <Grid columns={4}>
      <Column width={2}>
        <Field
          name='bank_account'
          label='Bank account'
          Input={BankAccountSelect}
        />
      </Column>
      <Column width={2} />

      <Column width={3}>
        <div {...s('internalGrid')}>
          <div {...s('internalGridColumn')}>
            <Field
              id='transfer-from-folio'
              name='source.ledger.object'
              label='Folio to transfer from'
              Input={EntitySelect}
              inputProps={{
                objectTypes: Object.values(FinancialRecordTypes),
                deselectable: true
              }}
            />
          </div>

          <Field
            name='date_of'
            label='Date'
            Input={DateInput}
            inputProps={{
              isOutsideRange: disableFutureDates
            }}
          />
        </div>
      </Column>

      <CurrencyValue label='Available' value={runningAmount} />
    </Grid>
  );
}
