import React from 'react';
import { Truncate } from '@rexlabs/text';
import { margin, StyleSheet, useStyles } from '@rexlabs/styling';
import { CellProps } from '@rexlabs/table';
import { formatDate } from 'utils/dates/format';

const defaultStyles = StyleSheet({
  subText: {
    fontSize: '1.2rem',
    color: ({ token }) => token('color.textStyle.body.subtext')
  },

  secondLine: {
    ...margin.styles({
      top: 'xxs'
    })
  }
});

export function ModifiedByCell({ value }: CellProps) {
  const s = useStyles(defaultStyles);

  return (
    <div>
      <Truncate>{value?.updated_by?.name ?? '--'}</Truncate>
      <div {...s('secondLine')}>
        <Truncate {...s('subText')}>{formatDate(value.updated_at)}</Truncate>
      </div>
    </div>
  );
}
