import React from 'react';

import { TitleBlock } from 'view/components/cards/title/title-block';

import { RecordObject, RecordType } from 'data/models/types';
import { RecurringChargeEntry } from 'data/models/entities/financials/recurring-charge-entries';
import { getRecordTitle } from 'utils/records/get-record-title';

const type: RecordType = 'recurring_charge_entry';

type RecurringChargeEntryTitleBlockProps = {
  recurringChargeEntry: RecurringChargeEntry;
};

export function RecurringChargeEntryTitleBlock({
  recurringChargeEntry
}: RecurringChargeEntryTitleBlockProps) {
  const title = getRecordTitle({
    type,
    object: recurringChargeEntry
  } as RecordObject);

  return <TitleBlock title={title} type={type} />;
}
