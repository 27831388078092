import _ from 'lodash';
import { ComplianceType } from 'src/modules/compliance/common/models/compliance-types';
import { ComplianceEntry } from 'src/modules/compliance/common/models/compliance-entries';

export function getUnfulfilledComplianceTypes({
  complianceTypes,
  entries
}: {
  complianceTypes?: (ComplianceType | undefined)[];
  entries?: ComplianceEntry[];
}) {
  if (!complianceTypes || !entries) {
    return [];
  }

  return _.compact(
    complianceTypes.filter((complianceType) => {
      const entry = entries.find(
        (entry) => entry.compliance_type.id === complianceType?.id
      );
      return !entry;
    })
  );
}
