import * as React from 'react';

export function ImagesWithBadgeIcon(props: { hasBadge?: boolean }) {
  const { hasBadge = false } = props;

  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='16'
      height='16'
      fill='currentColor'
      overflow='visible'
    >
      <g clipPath='url(#a)'>
        <path
          fill='currentColor'
          fillRule='evenodd'
          d='M2.4 2.7a.4.4 0 0 0-.4.4v9.801c0 .086.027.165.072.23l2.619-3.143a1.7 1.7 0 0 1 2.368-.24l1.074.86 2.148-2.685a1.7 1.7 0 0 1 2.39-.266l1.326 1.061V3.1a.4.4 0 0 0-.4-.4H2.4Zm13.597 8.03V3.1a2.4 2.4 0 0 0-2.4-2.4H2.4A2.4 2.4 0 0 0 0 3.1v9.801a2.4 2.4 0 0 0 2.41 2.4h11.187a2.4 2.4 0 0 0 2.4-2.4v-2.028a1.014 1.014 0 0 0 0-.143Zm-2 .55-2.342-1.874-2.148 2.685a1.7 1.7 0 0 1-2.389.266l-1.079-.863-1.506 1.807h9.064a.4.4 0 0 0 .4-.4V11.28ZM5.482 5.618a.4.4 0 1 0-.566.565.4.4 0 0 0 .566-.566Zm-1.98-1.415a2.4 2.4 0 1 1 3.394 3.395 2.4 2.4 0 0 1-3.394-3.395Z'
          clipRule='evenodd'
        />
      </g>
      <defs>
        <clipPath id='a'>
          <path fill='none' d='M0 .7h16v14.601H0z' />
        </clipPath>
      </defs>
      {hasBadge ? <circle fill='red' cx='15' cy='3' r='4' /> : null}
    </svg>
  );
}
