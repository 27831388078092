import { Reconciliation } from 'data/models/entities/financials/reconciliations';
import { track } from '@rexlabs/analytics';
import { EVENTS } from 'utils/analytics';
import { dateDifferenceInDays } from 'src/modules/common/utils/diff-in-days';
import { formatDateIso } from 'utils/dates/format';

export const trackStartReconciliation = (data: Reconciliation) => {
  track({
    eventName: EVENTS.RECONCILIATIONS.STARTED,
    data: {
      reconciliationType: data.type.id,
      reconciliationPeriodOpeningDate: data.start_date,
      reconciliationPeriodDaysToStart: dateDifferenceInDays(
        new Date(data.created_at),
        new Date(data.start_date)
      ),
      reconciliationPeriodStartDate: formatDateIso(data.created_at),
      reconciliationVarianceBalanceStart: data.statement_balance,
      reconciliationWithdrawalStartCount:
        data.reconciliation_summary
          .bank_withdrawals_exc_cheques_unreconciled_count,
      reconciliationDepositsStartCount:
        data.reconciliation_summary.bank_deposits_unreconciled_count,
      reconciliationUnpresentedChequesStartCount:
        data.reconciliation_summary.trust_cheque_withdrawals_unreconciled_count,
      reconciliationAdjustmentsStartCount:
        data.reconciliation_summary.bank_adjustments_unreconciled_count
    }
  });
};
