import { makeIconComponent } from '@rexlabs/icons';

import ArrowBranchSmallSvg from 'src/assets/icons/s/arrow-branch.svg';
import ArrowBranchMediumSvg from 'src/assets/icons/m/arrow-branch.svg';

export const ArrowBranchIcon = makeIconComponent({
  s: ArrowBranchSmallSvg,
  m: ArrowBranchMediumSvg
});

export default ArrowBranchIcon;
